import { makeStyles } from "@material-ui/core/styles";
import { DISABLED, ENABLED, MANUAL } from "api/slingshot/indexing_config/Types/ConfigType";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
    },
    oval: {
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: ({ status }) => {
            switch (status) {
                case ENABLED:
                    return theme.palette.success.main;
                case DISABLED:
                    return theme.palette.error.main;
                case MANUAL:
                    return theme.palette.grey[500];
                default:
                    return theme.palette.error.main;
            }
        },
    },
}));
