import { types } from "mobx-state-tree";

import NetBridge from "api/net/Types/NetBridge";

const BridgesArrayResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(NetBridge)
});

export default BridgesArrayResult;
