import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import Dialog from "components/Dialog";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import createFields from "./createFields";
import createForm from "utils/createForm";
import CreateEditJobForm from "../CreateEditJobForm";
import getSyncJobPauseDuration from "utils/getSyncJobPauseDuration";
import moment from "moment";
import {
    CREATE_JOB_DEFAULT_OPTIONS,
    REPORT_VARIANTS_OPTIONS,
    METHOD_OPTIONS,
    SOURCE_OPTIONS,
    ALIAS_SCHEMA_VALUE,
    DESTINATION_OPTIONS,
    SLINGSHOT_DATE_FORMAT_FOR_SERVER,
    SCHEDULE_PERIOD_OPTIONS,
} from "const/createJobConst";
import { SCHEMA_OPTIONS } from "const/slingshotAliasConst";
import EnableJobDialog from "../EnableJobDialog";
import sortByLocaleCompare from "utils/sortByLocaleCompare";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useCastSeconds } from "hooks/useCastSeconds";
import { SECONDARY_ADMIN, USER } from "const/userRolesConst";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";
import { SMB } from "const/shareRootAccessConst";
import { autorun } from "mobx";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";

const EDIT_JOB_MODAL_ID_PREFIX = `edit_job_modal`;

const EditJobModal = ({ open, onClose, viewOnly, afterSubmitCallback, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${EDIT_JOB_MODAL_ID_PREFIX}` : EDIT_JOB_MODAL_ID_PREFIX;
    const { t } = useTranslation();

    const {
        store: {
            syncJobsStore,
            slingshotStorageStore,
            evoSettingsStore,
            slingshotSettingsStore,
            authStore,
            emailConfigurationStore,
            indexingConfigStore,
        },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        isHided: false,
        localShares: [],
        extShares: [],
        isFormReady: false,
        excludedSourceShare: "",
        excludedDestinationShare: "",
        get isMediaProxyEnable() {
            const destinationShare = slingshotStorageStore.shares?.find(
                (share) => share.name === indexingConfigStore.destinationPath?.destinationShare
            );
            return !!destinationShare;
        },
    }));

    const { unsavedChangesModal, enableJobModal } = useModalCombine(["unsavedChangesModal", "enableJobModal"]);

    const castedMinAge = useCastSeconds(syncJobsStore.currentEditableJob?.min_age);

    const hideModal = () => {
        state.isHided = true;
    };
    const showModal = () => {
        state.isHided = false;
    };

    const getMinAge = () => {
        return state.form.$("transferFilesOlderThan").value
            ? moment
                  .duration(
                      Number(state.form.$("transferFilesOlderThanCount").value),
                      state.form.$("transferFilesOlderThanType").value
                  )
                  .as("seconds")
            : undefined;
    };

    const getSourceDecomposedUrl = () => {
        switch (state.form.$("source").value) {
            case SOURCE_OPTIONS.share:
            case SOURCE_OPTIONS.smb:
                return {
                    schema: SOURCE_OPTIONS.share,
                    host: "",
                    resource: state.form.$("sourceShare").value,
                    path: state.form.$("sourcePath").value,
                };
            case SOURCE_OPTIONS.amazon:
            case SOURCE_OPTIONS.azure:
            case SOURCE_OPTIONS.dropbox:
            case SOURCE_OPTIONS.ftp:
            case SOURCE_OPTIONS.gcs:
                return {
                    schema: ALIAS_SCHEMA_VALUE,
                    host: state.form.$("sourceAlias").value,
                    resource: "",
                    path: state.form.$("sourcePath").value,
                };
            case SOURCE_OPTIONS.logicalDisk: {
                const ld = slingshotStorageStore.logicalDisks.find(
                    (ld) => ld.fullName === state.form.$("sourceLogicalDisk").value
                );
                return {
                    schema: SOURCE_OPTIONS.logicalDisk,
                    resource: ld?.pool || "",
                    path: ld?.name || "",
                };
            }
            case SOURCE_OPTIONS.excludedShare:
                return {
                    schema: syncJobsStore.currentEditableJob.source?.decomposed_url?.schema,
                    host: "",
                    resource: syncJobsStore.currentEditableJob.source?.decomposed_url?.resource,
                    path: state.form.$("sourcePath").value,
                };
            default:
                return {};
        }
    };

    const setDefaultSource = () => {
        switch (syncJobsStore.currentEditableJob.source?.decomposed_url?.schema) {
            case ALIAS_SCHEMA_VALUE:
                {
                    const sourceAlias = slingshotSettingsStore.aliases?.find(
                        (alias) => alias.name === syncJobsStore.currentEditableJob.source.decomposed_url.host
                    );

                    const schema = sourceAlias?.decomposed_base_url.schema;

                    switch (schema) {
                        case SCHEMA_OPTIONS.s3:
                            state.form.$("source").set("default", SOURCE_OPTIONS.amazon);
                            break;
                        case SCHEMA_OPTIONS.azure:
                            state.form.$("source").set("default", SOURCE_OPTIONS.azure);
                            break;
                        case SCHEMA_OPTIONS.dropbox:
                            state.form.$("source").set("default", SOURCE_OPTIONS.dropbox);
                            break;
                        case SCHEMA_OPTIONS.ftp:
                            state.form.$("source").set("default", SOURCE_OPTIONS.ftp);
                            break;
                        case SCHEMA_OPTIONS.gcs:
                            state.form.$("source").set("default", SOURCE_OPTIONS.gcs);
                            break;
                        default:
                            break;
                    }

                    state.form.$("sourceAlias").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.host);
                    state.form.$("sourcePath").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.path);
                }
                break;
            case SOURCE_OPTIONS.share:
                {
                    const isRemote = state.extShares?.includes(syncJobsStore.currentEditableJob.source?.decomposed_url?.resource);
                    const isExcluded = !(
                        state.extShares?.includes(syncJobsStore.currentEditableJob.source?.decomposed_url?.resource) ||
                        state.localShares?.includes(syncJobsStore.currentEditableJob.source?.decomposed_url?.resource)
                    );
                    state.form
                        .$("source")
                        .set(
                            "default",
                            isExcluded ? SOURCE_OPTIONS.excludedShare : isRemote ? SOURCE_OPTIONS.smb : SOURCE_OPTIONS.share
                        );
                    state.form.$("sourceShare").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.resource);
                    state.form.$("sourcePath").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.path);
                }
                break;
            case SOURCE_OPTIONS.amazon:
                state.form.$("source").set("default", SOURCE_OPTIONS.amazon);
                state.form.$("sourceAlias").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.host);
                state.form.$("sourcePath").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.path);
                break;
            case SOURCE_OPTIONS.azure:
                state.form.$("source").set("default", SOURCE_OPTIONS.azure);
                state.form.$("sourceAlias").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.host);
                state.form.$("sourcePath").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.path);
                break;
            case SOURCE_OPTIONS.dropbox:
                state.form.$("source").set("default", SOURCE_OPTIONS.dropbox);
                state.form.$("sourceAlias").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.host);
                state.form.$("sourcePath").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.path);
                break;
            case SOURCE_OPTIONS.gcs:
                state.form.$("source").set("default", SOURCE_OPTIONS.gcs);
                state.form.$("sourceAlias").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.host);
                state.form.$("sourcePath").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.path);
                break;
            case SOURCE_OPTIONS.ftp:
                state.form.$("source").set("default", SOURCE_OPTIONS.ftp);
                state.form.$("sourceAlias").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.host);
                state.form.$("sourcePath").set("default", syncJobsStore.currentEditableJob.source.decomposed_url.path);
                break;
            case SOURCE_OPTIONS.logicalDisk:
                state.form.$("source").set("default", SOURCE_OPTIONS.logicalDisk);
                state.form
                    .$("sourceLogicalDisk")
                    .set(
                        "default",
                        `${syncJobsStore.currentEditableJob.source.decomposed_url.resource}/${syncJobsStore.currentEditableJob.source.decomposed_url.path}`
                    );
                state.form.$("sourcePath").set("default", "");
                break;
            default:
                break;
        }
    };

    const getDestinationDecomposedUrl = () => {
        switch (state.form.$("destination").value) {
            case DESTINATION_OPTIONS.share:
            case DESTINATION_OPTIONS.smb:
                return {
                    schema: DESTINATION_OPTIONS.share,
                    host: "",
                    resource: state.form.$("destinationShare").value,
                    path: state.form.$("destinationPath").value,
                };
            case DESTINATION_OPTIONS.amazon:
            case DESTINATION_OPTIONS.azure:
            case DESTINATION_OPTIONS.dropbox:
            case DESTINATION_OPTIONS.ftp:
            case DESTINATION_OPTIONS.gcs:
                return {
                    schema: ALIAS_SCHEMA_VALUE,
                    host: state.form.$("destinationAlias").value,
                    resource: "",
                    path: state.form.$("destinationPath").value,
                };
            case DESTINATION_OPTIONS.excludedShare:
                return {
                    schema: syncJobsStore.currentEditableJob.destination?.decomposed_url?.schema,
                    host: "",
                    resource: syncJobsStore.currentEditableJob.destination?.decomposed_url?.resource,
                    path: state.form.$("destinationPath").value,
                };
            default:
                return {};
        }
    };

    const setDefaultDestinationAlias = () => {
        const destinationAlias = slingshotSettingsStore.aliases?.find(
            (alias) => alias.name === syncJobsStore?.currentEditableJob?.destination?.decomposed_url?.host
        );

        const schema = destinationAlias?.decomposed_base_url.schema;

        switch (schema) {
            case SCHEMA_OPTIONS.s3:
                state.form.$("destination").set("default", DESTINATION_OPTIONS.amazon);
                break;
            case SCHEMA_OPTIONS.azure:
                state.form.$("destination").set("default", DESTINATION_OPTIONS.azure);
                break;
            case SCHEMA_OPTIONS.dropbox:
                state.form.$("destination").set("default", DESTINATION_OPTIONS.dropbox);
                break;
            case SCHEMA_OPTIONS.ftp:
                state.form.$("destination").set("default", DESTINATION_OPTIONS.ftp);
                break;
            case SCHEMA_OPTIONS.gcs:
                state.form.$("destination").set("default", DESTINATION_OPTIONS.gcs);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        autorun(() => {
            setDefaultDestinationAlias();
            state.form.$("destination").reset();
            setDefaultDestination();
        });
    }, []);

    const setDefaultDestination = () => {
        if (syncJobsStore.currentEditableJob?.destination?.decomposed_url?.schema === ALIAS_SCHEMA_VALUE) {
            setDefaultDestinationAlias();
        }
        switch (syncJobsStore.currentEditableJob?.destination?.decomposed_url?.schema) {
            case DESTINATION_OPTIONS.share:
                {
                    const isRemote = state.extShares?.includes(
                        syncJobsStore.currentEditableJob?.destination?.decomposed_url?.resource
                    );
                    const isExcluded = !(
                        state.extShares?.includes(syncJobsStore.currentEditableJob?.destination?.decomposed_url?.resource) ||
                        state.localShares?.includes(syncJobsStore.currentEditableJob?.destination?.decomposed_url?.resource)
                    );
                    state.form
                        .$("destination")
                        .set(
                            "default",
                            isExcluded
                                ? DESTINATION_OPTIONS.excludedShare
                                : isRemote
                                ? DESTINATION_OPTIONS.smb
                                : DESTINATION_OPTIONS.share
                        );
                    state.form
                        .$("destinationShare")
                        .set("default", syncJobsStore.currentEditableJob?.destination?.decomposed_url.resource);
                    state.form
                        .$("destinationPath")
                        .set("default", syncJobsStore.currentEditableJob?.destination?.decomposed_url.path);
                }
                break;
            case DESTINATION_OPTIONS.amazon:
            case DESTINATION_OPTIONS.azure:
            case DESTINATION_OPTIONS.dropbox:
            case DESTINATION_OPTIONS.ftp:
            case DESTINATION_OPTIONS.gcs:
            case ALIAS_SCHEMA_VALUE:
                state.form
                    .$("destinationAlias")
                    .set("default", syncJobsStore.currentEditableJob?.destination?.decomposed_url.host);
                state.form
                    .$("destinationPath")
                    .set("default", syncJobsStore.currentEditableJob?.destination?.decomposed_url.path);
                break;

            default:
                break;
        }
        state.form.$("destinationShare").reset();
        state.form.$("destinationPath").reset();
        state.form.$("destinationAlias").reset();
    };

    const getSchedule = () => {
        switch (state.form.$("schedulePeriod").value) {
            case SCHEDULE_PERIOD_OPTIONS.manual:
                return null;
            case SCHEDULE_PERIOD_OPTIONS.once:
                return {
                    minutes: -1,
                    date: moment(state.form.$("startAtDateTime").value).format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                    not_before: state.form.$("enableBegin").value
                        ? moment(state.form.$("beginDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                    not_after: state.form.$("enableEnd").value
                        ? moment(state.form.$("endDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                };
            case SCHEDULE_PERIOD_OPTIONS.hourly:
                return {
                    minutes: 60, //minutes in 1 hour
                    date: moment
                        .unix(evoSettingsStore.evoInfoTime)
                        .hour((state.form.$("enableEndAt").value && state.form.$("startAtTime").value) || 0)
                        .minute(0)
                        .second(0)
                        .format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                    not_before: state.form.$("enableBegin").value
                        ? moment(state.form.$("beginDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                    not_after: state.form.$("enableEnd").value
                        ? moment(state.form.$("endDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                };
            case SCHEDULE_PERIOD_OPTIONS.daily:
                return {
                    minutes: 1440, //minutes in 1 day
                    date: moment
                        .unix(evoSettingsStore.evoInfoTime)
                        .hour(state.form.$("startAtTime").value)
                        .minute(0)
                        .second(0)
                        .format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                    not_before: state.form.$("enableBegin").value
                        ? moment(state.form.$("beginDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                    not_after: state.form.$("enableEnd").value
                        ? moment(state.form.$("endDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                };
            case SCHEDULE_PERIOD_OPTIONS.weekly:
                return {
                    minutes: 10080, //minutes in 1 week
                    date: moment
                        .unix(evoSettingsStore.evoInfoTime)
                        .day(state.form.$("startAtDayOfWeek").value)
                        .hour(state.form.$("startAtTime").value)
                        .minute(0)
                        .second(0)
                        .format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                    not_before: state.form.$("enableBegin").value
                        ? moment(state.form.$("beginDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                    not_after: state.form.$("enableEnd").value
                        ? moment(state.form.$("endDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                };

            default:
                return {};
        }
    };

    const setDefaultSchedule = () => {
        if (syncJobsStore.currentEditableJob.schedule?.schedule?.not_before) {
            state.form.$("enableBegin").set("default", true);
            state.form.$("beginDate").set("default", moment(syncJobsStore.currentEditableJob.schedule?.schedule?.not_before));
        } else {
            state.form.$("enableBegin").set("default", false);
        }
        if (syncJobsStore.currentEditableJob.schedule?.schedule?.not_after) {
            state.form.$("enableEnd").set("default", true);
            state.form.$("endDate").set("default", moment(syncJobsStore.currentEditableJob.schedule?.schedule?.not_after));
        } else {
            state.form.$("enableEnd").set("default", false);
        }

        switch (syncJobsStore.currentEditableJob.schedule?.schedule?.minutes) {
            case -1:
                state.form.$("schedulePeriod").set("default", SCHEDULE_PERIOD_OPTIONS.once);
                state.form.$("startAtDateTime").set("default", moment(syncJobsStore.currentEditableJob.schedule?.schedule?.date));
                break;
            case 60: //hourly period
                state.form.$("schedulePeriod").set("default", SCHEDULE_PERIOD_OPTIONS.hourly);
                state.form.$("startAtDateTime").set("default", moment(syncJobsStore.currentEditableJob.schedule?.schedule?.date));
                state.form
                    .$("startAtTime")
                    .set("default", moment(syncJobsStore.currentEditableJob.schedule?.schedule?.date)?.hours());
                break;
            case 1440: //daily period
                state.form.$("schedulePeriod").set("default", SCHEDULE_PERIOD_OPTIONS.daily);
                state.form
                    .$("startAtTime")
                    .set("default", moment(syncJobsStore.currentEditableJob.schedule?.schedule?.date)?.hours());
                break;
            case 10080: //weekly period
                state.form.$("schedulePeriod").set("default", SCHEDULE_PERIOD_OPTIONS.weekly);
                state.form
                    .$("startAtTime")
                    .set("default", moment(syncJobsStore.currentEditableJob.schedule?.schedule?.date)?.hours());
                state.form
                    .$("startAtDayOfWeek")
                    .set("default", moment(syncJobsStore.currentEditableJob.schedule?.schedule?.date)?.day());
                break;
            default:
                state.form.$("schedulePeriod").set("default", SCHEDULE_PERIOD_OPTIONS.manual);
                break;
        }
    };

    const getPauseScheduleWithoutDuration = () => {
        switch (state.form.$("schedulePeriod").value) {
            case SCHEDULE_PERIOD_OPTIONS.once:
                return {
                    minutes: -1,
                    date: moment(state.form.$("endAtDateTime").value).format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                };
            case SCHEDULE_PERIOD_OPTIONS.hourly:
            case SCHEDULE_PERIOD_OPTIONS.daily:
                return {
                    minutes: 1440, //minutes in 1 day
                    date: moment
                        .unix(evoSettingsStore.evoInfoTime)
                        .hour(state.form.$("endAtTime").value)
                        .minute(0)
                        .second(0)
                        .format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                };
            case SCHEDULE_PERIOD_OPTIONS.weekly:
                return {
                    minutes: 10080, //minutes in 1 week
                    date: moment
                        .unix(evoSettingsStore.evoInfoTime)
                        .day(state.form.$("endAtDayOfWeek").value)
                        .hour(state.form.$("endAtTime").value)
                        .minute(0)
                        .second(0)
                        .format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                };

            default:
                return {};
        }
    };

    const setDefaultPause = () => {
        state.form.$("enableEndAt").set("default", !!syncJobsStore.currentEditableJob.pause?.enabled);
        if (syncJobsStore.currentEditableJob.pause?.schedule?.schedule?.minutes) {
            switch (syncJobsStore.currentEditableJob.pause.schedule.schedule.minutes) {
                case -1: // once
                    state.form
                        .$("endAtDateTime")
                        .set("default", moment(syncJobsStore.currentEditableJob.pause.schedule.schedule.date));
                    break;
                case 1440: // daily/hourly
                    state.form
                        .$("endAtTime")
                        .set("default", moment(syncJobsStore.currentEditableJob.pause.schedule.schedule.date)?.hour());
                    break;
                case 10080: // weekly
                    state.form
                        .$("endAtTime")
                        .set("default", moment(syncJobsStore.currentEditableJob.pause.schedule.schedule.date)?.hour());
                    state.form
                        .$("endAtDayOfWeek")
                        .set("default", moment(syncJobsStore.currentEditableJob.pause.schedule.schedule.date)?.day());
                    break;
                default:
                    break;
            }
        }
    };

    const closeWithFormReset = () => {
        onClose();
        state.isFormReady = false;
        state.form.clear();
        state.form.each((field) => field.resetValidation());
    };

    const addExcludedSourceShare = () => {
        state.excludedSourceShare = syncJobsStore.currentEditableJob.source?.decomposed_url?.resource;
        state.form.$("placeholderSourceShare").set("default", syncJobsStore.currentEditableJob.source?.decomposed_url?.resource);
    };
    const addExcludedDestinationShare = () => {
        state.excludedDestinationShare = syncJobsStore.currentEditableJob?.destination?.decomposed_url?.resource;
        state.form
            .$("placeholderDestinationShare")
            .set("default", syncJobsStore.currentEditableJob?.destination?.decomposed_url?.resource);
    };

    const onModalEnter = async () => {
        //set default values
        const localShares = slingshotStorageStore.shares.filter((share) => share.pool?.toLowerCase() !== SMB && share.active);

        const filterNFSSharesForSecondaryAdminAndUser = (share) => {
            if ([SECONDARY_ADMIN, USER].some((role) => role === authStore.role)) {
                return share.type.toLowerCase() !== "nfs";
            }

            return true;
        };

        state.localShares =
            (localShares.length &&
                sortByLocaleCompare(localShares.filter(filterNFSSharesForSecondaryAdminAndUser).map((share) => share.name))) ||
            [];
        const extShares = slingshotStorageStore.shares.filter((share) => share.pool?.toLowerCase() === SMB && share.active);
        state.extShares =
            (extShares.length &&
                sortByLocaleCompare(extShares.filter(filterNFSSharesForSecondaryAdminAndUser).map((share) => share.name))) ||
            [];

        const initDate = moment.unix(evoSettingsStore.evoInfoTime);
        state.form.$("source").set("default", CREATE_JOB_DEFAULT_OPTIONS.source);
        state.form.$("destination").set("default", CREATE_JOB_DEFAULT_OPTIONS.destination);
        state.form.$("schedulePeriod").set("default", CREATE_JOB_DEFAULT_OPTIONS.schedulePeriod);
        state.form.$("startAtDayOfWeek").set("default", CREATE_JOB_DEFAULT_OPTIONS.dayOfWeek);
        state.form.$("endAtDayOfWeek").set("default", CREATE_JOB_DEFAULT_OPTIONS.dayOfWeek);
        state.form.$("startAtTime").set("default", CREATE_JOB_DEFAULT_OPTIONS.startAt);
        state.form.$("endAtTime").set("default", CREATE_JOB_DEFAULT_OPTIONS.endAt);
        state.form.$("beginDate").set("default", initDate);
        state.form.$("endDate").set("default", initDate);
        state.form.$("startAtDateTime").set("default", initDate);
        state.form.$("endAtDateTime").set("default", initDate);
        state.form.$("skipNewerFiles").set("default", true);
        state.form.$("includeMetadata").set("default", true);
        state.form.$("transferFilesOlderThan").set("default", false);
        state.form.$("transferFilesOlderThanCount").set("default", 0);
        state.form.$("transferFilesOlderThanType").set("default", CREATE_JOB_DEFAULT_OPTIONS.datePeriod);
        state.form.$("verifyAfterTransferReport").set("default", CREATE_JOB_DEFAULT_OPTIONS.reportVariant);
        state.form.$("logLevel").set("default", CREATE_JOB_DEFAULT_OPTIONS.logLevel);
        state.form.$("includeExcludePriority").set("default", CREATE_JOB_DEFAULT_OPTIONS.includeExcludePriority);

        //update values by current job
        state.form.$("jobName").set("default", syncJobsStore.currentEditableJob.name);
        state.form.$("includeRecycleBin").set("default", syncJobsStore.currentEditableJob.include_recycle_bin);
        state.form.$("method").set("default", syncJobsStore.currentEditableJob.method);
        state.form
            .$("emailJobSummary")
            .set(
                "default",
                emailConfigurationStore.emailSettings?.emailsTo &&
                    emailConfigurationStore.emailSettings?.emailsTo[0] !== "" &&
                    syncJobsStore.currentEditableJob.email_summary
                    ? syncJobsStore.currentEditableJob.email_summary
                    : false
            );
        state.form.$("replicateInParallel").set("default", syncJobsStore.currentEditableJob.parallel_processing);
        state.form.$("removeDeletedFiles").set("default", syncJobsStore.currentEditableJob.remove_deleted_files_before_sync);

        if (syncJobsStore.currentEditableJob.min_age) {
            state.form.$("transferFilesOlderThanCount").set("default", castedMinAge.quantity);
            state.form.$("transferFilesOlderThanType").set("default", castedMinAge.format);

            state.form.$("transferFilesOlderThan").set("default", true);
        }

        if (syncJobsStore.currentEditableJob.include_name_mask) {
            state.form.$("enableIncludePattern").set("default", true);
            state.form.$("includePattern").set("default", syncJobsStore.currentEditableJob.include_name_mask);
        } else {
            state.form.$("enableIncludePattern").set("default", false);
            state.form.$("includePattern").set("default", "");
        }

        if (syncJobsStore.currentEditableJob.exclude_name_mask) {
            state.form.$("enableExcludePattern").set("default", true);
            state.form.$("excludePattern").set("default", syncJobsStore.currentEditableJob.exclude_name_mask);
        } else {
            state.form.$("enableExcludePattern").set("default", false);
            state.form.$("excludePattern").set("default", "");
        }

        if (syncJobsStore.currentEditableJob.include_name_mask || syncJobsStore.currentEditableJob.exclude_name_mask) {
            state.form
                .$("enableIncludePatternCaseInsensitive")
                .set("default", syncJobsStore.currentEditableJob.case_sensitive_name_mask);
        } else {
            state.form.$("enableIncludePatternCaseInsensitive").set("default", false);
        }

        if (syncJobsStore.currentEditableJob.include_exclude_priority) {
            state.form.$("includeExcludePriority").set("default", syncJobsStore.currentEditableJob.include_exclude_priority);
        }
        state.form.$("includeMetadata").set("default", syncJobsStore.currentEditableJob.include_metadata);
        state.form.$("includeMediaProxy").set("default", syncJobsStore.currentEditableJob.include_mediaproxy);
        state.form.$("skipNewerFiles").set("default", syncJobsStore.currentEditableJob.skip_newer);
        state.form.$("detectFileModification").set("default", syncJobsStore.currentEditableJob.pre_verify === "SizeAndHash");
        state.form.$("enableVerifyAfterTransfer").set("default", syncJobsStore.currentEditableJob.check_after_transfer);
        state.form
            .$("verifyAfterTransferReport")
            .set(
                "default",
                syncJobsStore.currentEditableJob.post_verify?.report_same
                    ? REPORT_VARIANTS_OPTIONS.all
                    : REPORT_VARIANTS_OPTIONS.diff
            );
        state.form.$("saveLogsAtDestination").set("default", syncJobsStore.currentEditableJob.copy_logs);
        state.form.$("logLevel").set("default", syncJobsStore.currentEditableJob.log_level);
        state.form.$("emailOnJobStart").set("default", !!syncJobsStore.currentEditableJob.properties?.emailOnStart);
        state.form.$("emailOnJobPause").set("default", !!syncJobsStore.currentEditableJob.properties?.emailOnPauseResume);

        setDefaultSource();
        setDefaultDestination();
        setDefaultSchedule();
        setDefaultPause();
        if (
            !state.extShares?.includes(syncJobsStore.currentEditableJob.source?.decomposed_url?.resource) &&
            !state.localShares?.includes(syncJobsStore.currentEditableJob.source?.decomposed_url?.resource)
        ) {
            addExcludedSourceShare();
        }
        if (
            !state.extShares?.includes(syncJobsStore.currentEditableJob?.destination?.decomposed_url?.resource) &&
            !state.localShares?.includes(syncJobsStore.currentEditableJob?.destination?.decomposed_url?.resource)
        ) {
            addExcludedDestinationShare();
        }
        state.form.reset();
        state.form.each((field) => field.resetValidation());
        unsavedChangesModal.close();
        state.isFormReady = true;
    };

    const onSave = async (e) => {
        await state.form.onSubmit(e);
        await state.form.validate();
        state.form.showErrors(true);
        if (state.form.$("schedulePeriod").value !== SCHEDULE_PERIOD_OPTIONS.once) {
            state.form.$("startAtDateTime").resetValidation();
        }
        if (!state.form.$("enableIncludePattern").value) {
            state.form.$("includePattern").resetValidation();
        }
        if (!state.form.$("enableExcludePattern").value) {
            state.form.$("excludePattern").resetValidation();
        }
        if (!state.form.$("transferFilesOlderThan").value) {
            state.form.$("transferFilesOlderThanCount").resetValidation();
        }

        if (!state.form.isValid) return;

        if (
            state.form.$("enableBegin").value &&
            state.form.$("enableEnd").value &&
            state.form.$("endDate").value < state.form.$("beginDate").value
        )
            return;
        const jobSchedule = getSchedule();
        const pauseScheduleWithoutDuration = getPauseScheduleWithoutDuration();
        const pauseSheduleDateDisabledEndAt =
            state.form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.hourly &&
            moment
                .unix(evoSettingsStore.evoInfoTime)
                .hour(24 - state.form.$("endAtTime").value + state.form.$("startAtTime").value)
                .minute(0)
                .second(0)
                .format(SLINGSHOT_DATE_FORMAT_FOR_SERVER);
        const res = await syncJobsStore.updateJob({
            id: syncJobsStore.currentEditableJob.id,
            name: state.form.$("jobName").value,
            include_recycle_bin: state.form.$("includeRecycleBin").value,
            method: state.form.$("method").value,
            email_summary: state.form.$("emailJobSummary").value,
            parallel_processing: state.form.$("replicateInParallel").value,
            remove_deleted_files_before_sync: state.form.$("removeDeletedFiles").value,
            min_age: getMinAge(),
            include_name_mask: state.form.$("enableIncludePattern").value ? state.form.$("includePattern").value : undefined,
            case_sensitive_name_mask:
                state.form.$("enableIncludePattern").value || state.form.$("enableExcludePattern").value
                    ? state.form.$("enableIncludePatternCaseInsensitive").value
                    : false,
            exclude_name_mask: state.form.$("enableExcludePattern").value ? state.form.$("excludePattern").value : undefined,
            include_exclude_priority:
                state.form.$("enableExcludePattern").value && state.form.$("enableIncludePattern").value
                    ? state.form.$("includeExcludePriority").value
                    : undefined,
            include_metadata: state.form.$("includeMetadata").value,
            include_mediaproxy: state.form.$("includeMediaProxy").value,
            copy_logs: state.form.$("saveLogsAtDestination").value,
            check_after_transfer: state.form.$("enableVerifyAfterTransfer").value,
            post_verify: {
                report_same: state.form.$("verifyAfterTransferReport").value === REPORT_VARIANTS_OPTIONS.all,
                one_way: state.form.$("method").value === METHOD_OPTIONS.copy,
            },
            skip_newer: state.form.$("skipNewerFiles").value,
            pre_verify: state.form.$("detectFileModification").value ? "SizeAndHash" : "SizeAndTime",
            logs_destination_folder: state.form.$("saveLogsAtDestination").value
                ? CREATE_JOB_DEFAULT_OPTIONS.logsDestinationFolder
                : undefined,
            log_level: state.form.$("logLevel").value,
            properties: {
                emailOnStart: state.form.$("emailOnJobStart").value,
                emailOnPauseResume: state.form.$("emailOnJobPause").disabled ? undefined : state.form.$("emailOnJobPause").value,
            },
            source: {
                decomposed_url: getSourceDecomposedUrl(),
            },
            destination: {
                decomposed_url: getDestinationDecomposedUrl(),
            },
            schedule: { schedule: jobSchedule },
            pause:
                state.form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.manual
                    ? undefined
                    : {
                          enabled: state.form.$("enableEndAt").value,
                          schedule: {
                              schedule: {
                                  ...pauseScheduleWithoutDuration,
                                  duration: getSyncJobPauseDuration({
                                      jobScheduleDate: jobSchedule.date || moment.unix(evoSettingsStore.evoInfoTime),
                                      pauseScheduleDate:
                                          (!state.form.$("enableEndAt").value
                                              ? pauseSheduleDateDisabledEndAt
                                              : pauseScheduleWithoutDuration.date) || moment.unix(evoSettingsStore.evoInfoTime),
                                      isForceEndChecked: state.form.$("enableEndAt").value,
                                      schedulePeriod: state.form.$("schedulePeriod").value,
                                  }),
                              },
                          },
                      },
            deleted: false,
            enabled: false,
        });

        if (res) {
            afterSubmitCallback();
            enableJobModal.open();
        }

        return res;
    };

    const onEnableJobDialogClose = () => {
        enableJobModal.close();
        closeWithFormReset();
    };

    const closeIfNeeded = (isSubmitted) => {
        if (state.form.isDefault || isSubmitted) {
            closeWithFormReset();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <>
            <Dialog
                onEnter={onModalEnter}
                maxWidth={"md"}
                open={open}
                onClose={closeIfNeeded}
                title={t("slingshot_page.tabs.replications.edit_job.title")}
                onSubmit={onSave}
                submitBtnLabel={t("common.button.save")}
                submitBtnId={`${idPrefix}_button_save`}
                disableSubmit={viewOnly}
                hide={state.isHided}
                withoutClose
                buttons={
                    <Button variant="contained" color="secondary" onClick={closeWithFormReset} id={`${idPrefix}_cancel`}>
                        {t("common.button.cancel")}
                    </Button>
                }
            >
                {slingshotStorageStore.inProgressGetShare ? (
                    <EmptyCardPlaceholder inProgress={slingshotStorageStore.inProgressGetShare} />
                ) : (
                    <CreateEditJobForm
                        hideParentModal={hideModal}
                        showParentModal={showModal}
                        disabled={viewOnly}
                        form={state.form}
                        localShares={state.localShares}
                        extShares={state.extShares}
                        isFormReady={state.isFormReady}
                        excludedSourceShare={state.excludedSourceShare}
                        excludedDestinationShare={state.excludedDestinationShare}
                        isMediaProxyEnable={state.isMediaProxyEnable}
                        parentId={idPrefix}
                    />
                )}
            </Dialog>
            <UnsavedChangesDialog
                onConfirm={closeWithFormReset}
                onClose={unsavedChangesModal.close}
                open={unsavedChangesModal.isOpen}
            />
            <EnableJobDialog
                open={enableJobModal.isOpen}
                onClose={onEnableJobDialogClose}
                id={syncJobsStore.currentEditableJob?.id}
                name={state.form.$("jobName").value}
                parentId={idPrefix}
            />
        </>
    ));
};

export default EditJobModal;
