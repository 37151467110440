import React, { useContext, useEffect } from "react";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import AcceptIcon from "assets/AcceptIcon";
import SideBulkBar from "components/SideBulkBar";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import QuadsActivationWarningDialog from "../QuadsActivationWarningDialog";
import { HARDWARE_ACTIVATION_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const QUADS_ACTIVATION_SIDE_BAR_ID_PREFIX = `${HARDWARE_ACTIVATION_PAGE_ID_PREFIX}_quads_activation_side_bar`;

const drawerName = "QuadsActivationSideBar";
const QuadsActivationSideBar = () => {
    const {
        store: { quadActivationStore, uiStore }
    } = useContext(Context);
    const { t } = useTranslation();
    const state = useLocalStore(() => ({
        isActivationQuadInProgress: false,
        isDeactivationQuadInProgress: false,
        isWarningDialogOpen: false
    }));

    useEffect(() => {
        if (quadActivationStore.checkedQuadsCount > 0) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, clearCheckboxes);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [quadActivationStore.checkedQuadsCount]);

    const clearCheckboxes = () => {
        quadActivationStore.clearCheckedQuads();
    };
    const isDeactivated = () => {
        return quadActivationStore.hasDeactivatedQuad;
    };

    const activateQuads = async soft => {
        const quads = quadActivationStore.deactivatedCheckedQuad.map(quad => quad);
        state.isActivationQuadInProgress = true;
        const res = await quadActivationStore.activateQuads({
            soft,
            quads
        });
        state.isActivationQuadInProgress = false;
        return res;
    };

    const deactivateQuads = async soft => {
        state.isDeactivationQuadInProgress = true;
        const quads = quadActivationStore.activatedCheckedQuad.map(quad => quad);
        const res = await quadActivationStore.deactivateQuads({
            soft,
            quads
        });
        state.isDeactivationQuadInProgress = false;
        return res;
    };

    const changeActivationStatus = () => {
        if (quadActivationStore.nondestructiveActivationEnabled) {
            openWarningDialog();
        } else {
            changeQuadsStucture(false);
        }
    };

    const changeQuadsStucture = async soft => {
        if (quadActivationStore.checkedQuadsCount > 0) {
            isDeactivated() ? await activateQuads(soft) : await deactivateQuads(soft);
        }
        clearCheckboxes();
    };

    const keepQuadsStructure = () => {
        changeQuadsStucture(true);
        closeWarningDialog();
    };

    const destroyQuadsStructure = () => {
        changeQuadsStucture(false);
        closeWarningDialog();
    };

    const openWarningDialog = () => {
        state.isWarningDialogOpen = true;
    };
    const closeWarningDialog = () => {
        state.isWarningDialogOpen = false;
    };

    return useObserver(() => (
        <SideBulkBar
            onClose={clearCheckboxes}
            itemsCount={quadActivationStore.checkedQuadsCount}
            isOpen={uiStore.openedDrawer === drawerName}
        >
            <BulkBarFlatButton
                inProgress={isDeactivated() ? state.isActivationQuadInProgress : state.isDeactivationQuadInProgress}
                onClick={changeActivationStatus}
                icon={<AcceptIcon />}
                id={`${QUADS_ACTIVATION_SIDE_BAR_ID_PREFIX}_change_activation_status`}
            >
                {isDeactivated()
                    ? t("hardware_activation.bulk_operations_bar.button.activate")
                    : t("hardware_activation.bulk_operations_bar.button.deactivate")}
            </BulkBarFlatButton>
            <QuadsActivationWarningDialog
                open={state.isWarningDialogOpen}
                onClose={closeWarningDialog}
                onKeep={keepQuadsStructure}
                onDestroy={destroyQuadsStructure}
            />
        </SideBulkBar>
    ));
};

export default QuadsActivationSideBar;
