import { makeStyles } from "@material-ui/core/styles";

import { GRAPH_INFO_HEIGHT } from "const/styleConst";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        justifyContent: "space-between",
    },
    svg: {
        "& .axis": {
            "& path": {
                color: ({ isDark }) => (isDark ? theme.palette.grey[300] : theme.palette.graphs.border),
                strokeWidth: 0.1,
            },
            "& text": {
                color: ({ isDark }) => (isDark ? theme.palette.grey[300] : theme.palette.graphs.text),
                opacity: 0.5,
                fontSize: 7,
                fontWeight: 100,
                letterSpacing: 1,
            },
            "& .tick": {
                color: ({ isDark }) => (isDark ? theme.palette.grey[700] : theme.palette.graphs.grid),
                strokeWidth: 0.2,
                opacity: 1,
            },
        },
        "& .y.axis": {
            textAnchor: "start",
        },
    },
    info: {
        paddingTop: 10,
        flexShrink: 0,
        height: GRAPH_INFO_HEIGHT,
    },
    name: {
        color: ({ isDark }) => (isDark ? theme.palette.common.white : theme.palette.text),
        fontWeight: "bold",
        fontSize: 12,
    },
    label: {
        color: ({ isDark }) => (isDark ? theme.palette.common.white : theme.palette.text),
        opacity: 0.4,
        fontSize: 12,
        fontWeight: 500,
    },
    graph: {
        flexShrink: 1,
    },
    legend: {
        height: GRAPH_INFO_HEIGHT - 40,
        overflowY: "auto",
        whiteSpace: "nowrap",
    },
    columnName: {
        width: 50,
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        verticalAlign: "middle",
    },
    columnValue: {
        display: "inline-block",
        verticalAlign: "middle",
    },
}));
