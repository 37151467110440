import { types } from "mobx-state-tree";

import Request from "api/Request";
import SetSecondaryAdminsArguments from "api/security/users/Types/SetSecondaryAdminsArguments";

const SetSecondaryAdmins = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Users",
    method: "set_secondary_admins",
    arguments: types.maybe(SetSecondaryAdminsArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetSecondaryAdmins;
