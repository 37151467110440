import React from "react";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Dialog from "components/Dialog";
import { useLocalStore, useObserver } from "mobx-react";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const DELETE_DISK_DIALOG_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_delete_disk_dialog`;

const DeleteDiskDialog = ({ open, diskName, onClose, onDeleteAction, inProgress }) => {
    const { t } = useTranslation();

    const state = useLocalStore(() => ({
        diskName: [],
    }));

    const onEnter = () => {
        state.diskName = diskName;
    };

    const renderContent = () => {
        if (Array.isArray(state.diskName) && state.diskName.length > 1) {
            return (
                <>
                    <Typography>{t("disks_and_pools.logical_disks.delete_dialog.content_delete_many_title")}</Typography>
                    <br />
                    <Typography>{`${state.diskName.join(",\n")}.`}</Typography>
                    <br />
                    <Typography>{t("disks_and_pools.logical_disks.delete_dialog.content_delete_many")}</Typography>
                </>
            );
        }
        return (
            <Typography>
                {t("disks_and_pools.logical_disks.delete_dialog.content_delete_one", { diskName: state.diskName })}
            </Typography>
        );
    };

    return useObserver(() => (
        <Dialog
            buttons={[
                <Button
                    key={"cancelBtn"}
                    variant={"contained"}
                    color={"secondary"}
                    id={`${DELETE_DISK_DIALOG_ID_PREFIX}_cancel`}
                    onClick={onClose}
                >
                    {t("common.button.cancel")}
                </Button>,
            ]}
            inProgress={inProgress}
            onEnter={onEnter}
            onClose={onClose}
            open={open}
            submitBtnLabel={t("common.button.ok_delete")}
            submitBtnId={`${DELETE_DISK_DIALOG_ID_PREFIX}_submit`}
            onSubmit={onDeleteAction}
            title={t("disks_and_pools.logical_disks.delete_dialog.title", {
                count: Array.isArray(state.diskName) ? state.diskName.length : 1,
            })}
            withoutIconClose
            withoutDividers
        >
            {renderContent()}
            <br />
            <Typography>{t("common.text.continue")}</Typography>
        </Dialog>
    ));
};

export default DeleteDiskDialog;
