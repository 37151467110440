import isFieldEmpty from "utils/isFieldEmptyRule";
import i18n from "i18n";
import createForm from "utils/createForm";

const createFields = () => {
    return createForm({
        fields: [
            {
                name: "username",
                default: "",
                validators: [isFieldEmpty(i18n.t("common.label.username"))]
            },
            {
                name: "password",
                default: "",
                type: "password",
                validators: [isFieldEmpty(i18n.t("common.label.password"))]
            },
            {
                name: "acceptEula",
                type: "checkbox",
                rules: "accepted"
            }
        ]
    });
};

export default createFields;
