/* eslint-disable no-console */
import { ADMIN, SECONDARY_ADMIN, USER } from "const/userRolesConst";
import { getParent } from "mobx-state-tree";

/**
 * @typedef EventBroadcastContext
 * @type {object}
 * @property {objestringct} sequenceId - individual sequence number.
 * @property {number} requestId - individual request number.
 */

/**
 * @typedef EventBroadcastData
 * @type {object}
 * @property {object} broadcast - data of broadcast.
 * @property {string} path - path of broadcast.
 * @property {string} type - type of broadcast.
 * @property {EventBroadcastContext} context - context of broadcast.
 */

/**
 * @typedef EventBroadcast
 * @type {object}
 * @property {EventBroadcastData} data - data of broadcast event.
 */

/**
 * Function for type matching with dependencies
 * @param {string[]} dependencies is an array of types that should be excluded from processing.
 * @param {string} event the event transmitted by the socket via broadcast.
 * @returns true or false, depending on the presence of type in the dependencies.
 * @description Needed to find suitable types for exclusion from the treatment
 */
const checkDependencies = (dependencies, event) => {
    /** @type {EventBroadcastData} */
    const { type } = JSON.parse(event.data);

    return dependencies && Array.isArray(dependencies) ? dependencies.some((depend) => type === depend) : false;
};

/**
 * Function to exclude broadcasts of a certain type.
 * @param {object} self essence of store.
 * @param {(event: string) => void} onBroadcastFn function performed when on broadcast.
 * @param {{dependenciesAdmin?: string[] | undefined, dependenciesSecondaryAdmin?: string[] | undefined, dependenciesUser?: string[] | undefined} | undefined} options is an object that includes properties:
 * 1. dependenciesAdmin -  optional array of types to be excluded from processing for the administrator role;
 * 2. dependenciesSecondaryAdmin -  optional array of types to be excluded from processing for the secondary administrator role;
 * 3. dependenciesUser -  optional array of types to be excluded from processing for the user role.
 * @description The function gets the stor entity, the executed function, the dependencies, and the broadcast event.
 * If the types received are the same as one of the dependencies and the user is the administrator,
 * then the executable on broadcast function is not called.
 */
const defendBroadcast = (self, onBroadcastFn, options) => {
    /**
     * @param {string} event the event transmitted by the socket via broadcast.
     */
    return (event) => {
        try {
            const { authStore } = getParent(self);
            /** @type {ADMIN | SECONDARY_ADMIN | USER} */
            const role = authStore.role;

            switch (role) {
                case ADMIN:
                    if (options && checkDependencies(options.dependenciesAdmin, event)) {
                        return;
                    }
                    break;
                case SECONDARY_ADMIN:
                    if (options && checkDependencies(options.dependenciesSecondaryAdmin, event)) {
                        return;
                    }
                    break;
                case USER:
                    if (options && checkDependencies(options.dependenciesUser, event)) {
                        return;
                    }
                    break;
                default:
                    break;
            }

            onBroadcastFn(event);
        } catch (error) {
            console.error(error);
        }
    };
};

export default defendBroadcast;
