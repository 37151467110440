import { types } from "mobx-state-tree";

import ActiveDirectoryStatus from "api/security/users/Types/ActiveDirectoryStatus";

const GetActiveDirectoryStatusResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(ActiveDirectoryStatus)
});

export default GetActiveDirectoryStatusResult;
