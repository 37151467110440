import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import CPUWidget from "components/graphs/CPUWidget";
import ThroughputWidget from "components/graphs/ThroughputWidget";
import StorageUsageWidget from "components/graphs/StorageUsageWidget";

import { Button, Grid } from "@material-ui/core";
import MobileStepper from "@material-ui/core/MobileStepper";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ChevronRightIcon from "assets/ChevronRightIcon";

import { useStyles } from "./GraphicsBar.style.js";
import { useStepper } from "hooks/useStepper.js";

const GraphicsBar = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const { goBack, goNext, activeStep, isLastStep, isFirstStep, steps } = useStepper();

    if (isDesktop) {
        return (
            <Grid container className={classes.container}>
                <Grid item xs={4} className={classes.graphicItem}>
                    <ThroughputWidget height={146} parentId="throughputGraphHeader" isDark />
                </Grid>
                <Grid item xs={4} className={classes.graphicItem}>
                    <StorageUsageWidget height={146} parentId="storageUsageGraphHeader" isDark />
                </Grid>
                <Grid item xs={4} className={classes.graphicItem}>
                    <CPUWidget height={146} parentId="cpuGraphHeader" isDark />
                </Grid>
            </Grid>
        );
    }

    const sliderComponents = [
        <ThroughputWidget parentId="throughputGraphHeader" isDark key={"step-1"} />,
        <StorageUsageWidget parentId="storageUsageGraphHeader" isDark key={"step-2"} />,
        <CPUWidget parentId="cpuGraphHeader" isDark key={"step-3"} />
    ];

    return (
        <Grid item className={classes.container}>
            <Grid className={classes.graphicItem}>{sliderComponents[activeStep]}</Grid>
            <Grid>
                <MobileStepper
                    steps={steps}
                    className={classes.stepper}
                    variant="dots"
                    activeStep={activeStep}
                    position="static"
                    nextButton={
                        <Button
                            size="small"
                            onClick={goNext}
                            disabled={isLastStep}
                            variant={"text"}
                            color={"primary"}
                            className={classes.buttonCustomDisabled}
                        >
                            {t("common.button.next")}
                            {theme.direction === "rtl" ? (
                                <ChevronRightIcon className={clsx(classes.chevron, classes.chevronLeft)} />
                            ) : (
                                <ChevronRightIcon className={classes.chevron} />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={goBack}
                            disabled={isFirstStep}
                            variant={"text"}
                            color={"primary"}
                            className={classes.buttonCustomDisabled}
                        >
                            {theme.direction === "rtl" ? (
                                <ChevronRightIcon className={classes.chevron} />
                            ) : (
                                <ChevronRightIcon className={clsx(classes.chevron, classes.chevronLeft)} />
                            )}
                            {t("common.button.previous")}
                        </Button>
                    }
                />
            </Grid>
        </Grid>
    );
};

export default GraphicsBar;
