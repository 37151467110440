import React from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { useLocalStore, useObserver } from "mobx-react-lite";
import createForm from "utils/createForm";
import createFields from "./createFields";
import Switch from "components/MobxForm/Switch";
import RowTextInput from "components/RowTextInput";
import Select from "components/MobxForm/Select";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import { SRT_MODE_OPTIONS } from "./EditUpsConfDialog.const";

const EditUpsConfDialog = ({ onClose, open }) => {
    const { t } = useTranslation();
    const {
        store: { powerAndUpsStore },
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields(powerAndUpsStore.upsSettings) }),
        isSubmitted: false,
    }));

    const unsavedChangesModal = useModal();

    const setupDialog = () => {
        state.form.$("automaticShutdownEnabled").set("default", powerAndUpsStore.upsSettings.automaticShutdownEnabled);
        state.form.$("criticalChargeLevel").set("default", powerAndUpsStore.upsSettings.criticalChargeLevel);
        state.form.$("srtMode").set("default", powerAndUpsStore.upsSettings.srtMode);
        state.form.reset();
        state.isSubmitted = false;
        unsavedChangesModal.close();
    };
    const onSubmit = async () => {
        const res = await powerAndUpsStore.setUpsSettings({
            apcSrtCompatibilityEnabled: false,
            criticalChargeLevel: state.form.$("criticalChargeLevel").value || 0,
            automaticShutdownEnabled: state.form.$("automaticShutdownEnabled").value,
            srtMode: state.form.$("srtMode").value,
        });
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    const getChargeLevel = () => {
        return [
            { label: "50%", value: 50 },
            { label: "75%", value: 75 },
            { label: "90%", value: 90 },
        ];
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            onEnter={setupDialog}
            onClose={closeIfNeeded}
            open={open}
            onSubmit={onSubmit}
            title={t("power_and_ups.ups_conf.edit_conf_dialog.title")}
            submitBtnLabel={t("common.button.save")}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary">
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <RowControlLabelSwitch
                control={<Switch field={state.form.$("automaticShutdownEnabled")} />}
                label={t("power_and_ups.ups_conf.edit_conf_dialog.auto_shutdown")}
            />
            <RowTextInput
                control={
                    <Select
                        disabled={!state.form.$("automaticShutdownEnabled").value}
                        field={state.form.$("criticalChargeLevel")}
                        options={getChargeLevel()}
                    />
                }
                label={t("power_and_ups.ups_conf.edit_conf_dialog.critical_level")}
            />
            <RowTextInput
                control={<Select field={state.form.$("srtMode")} options={SRT_MODE_OPTIONS} />}
                label={t("power_and_ups.ups_conf.edit_conf_dialog.srt_mode")}
            />
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default EditUpsConfDialog;
