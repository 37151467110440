import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

const PAPER_WIDTH = 520;
export const useStyles = makeStyles((theme) => ({
    popover: {
        "& .MuiPopover-paper": {
            backgroundColor: theme.palette.background.paper,
        },
    },
    paper: {
        width: PAPER_WIDTH,
        padding: theme.spacing(6),
    },
    statusText: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(2.5),
    },
    button: {
        borderRadius: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        height: theme.spacing(10),
        width: theme.spacing(10),
        position: "absolute",
        left: ({ isLoginPage }) => (isLoginPage ? 0 : -36),
    },
    buttonContainer: {
        marginTop: theme.spacing(8),
    },
    contentContainer: {
        paddingTop: theme.spacing(2),
    },
    certContainer: {
        padding: theme.spacing(3),
        borderRadius: theme.spacing(1),
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.1),
    },
    sshIcon: {
        marginRight: theme.spacing(2),
    },
    certNameText: {
        fontWeight: 500,
    },
    title: {
        opacity: 0.9,
        fontSize: 20,
        fontWeight: "bold",
        lineHeight: 1.2,
        letterSpacing: 0.2,
    },
}));
