import { types } from "mobx-state-tree";

import TimeSettings from "api/time/Types/TimeSettings";

const GetTimeSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(TimeSettings)
});

export default GetTimeSettingsResult;
