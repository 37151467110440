import { types } from "mobx-state-tree";

import MixedShare from "api/upgrade/migration/Types/MixedShare";

const GetMixedSharesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(MixedShare),
});

export default GetMixedSharesResult;
