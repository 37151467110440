import { types, flow, getParent } from "mobx-state-tree";
import MultipleEvosRootStore from "store/MultipleEvosRootStore";
import { getEvoPrefix } from "utils/helpers/getEvoPrefix";

const VolumeDrawerStore = types
    .model({})
    .volatile(() => ({
        poolIp: null,
    }))
    .views((self) => {
        const { volumeStore, poolsStore } = getParent(self);
        const multipleStores = MultipleEvosRootStore.stores;
        return {
            get allCheckedVolumes() {
                const { ip, name, evoSettingsStore } = getParent(self);

                const prefix = getEvoPrefix({
                    ip: ip,
                    evoName: name,
                    hostname: evoSettingsStore?.evoInfo?.hostname,
                    store: self,
                });

                let volumes = [...volumeStore.checkedVolumes].map((volume) => `${prefix}${volume.pool}/${volume.volume}`);

                multipleStores.forEach((store) => {
                    const { evoSettingsStore } = getParent(self);

                    const prefix = getEvoPrefix({
                        ip: store.ip,
                        evoName: store.name,
                        hostname: evoSettingsStore?.evoInfo?.hostname,
                        store: self,
                    });

                    const checkedQueueVolumes = store.volumeStore.checkedVolumes.map(
                        (volume) => `${prefix}${volume.pool}/${volume.volume}`
                    );

                    volumes = [...volumes, ...checkedQueueVolumes];
                });

                return volumes;
            },
            get allCheckedQueueVolumes() {
                const { ip, name, evoSettingsStore } = getParent(self);

                const prefix = getEvoPrefix({
                    ip: ip,
                    evoName: name,
                    hostname: evoSettingsStore?.evoInfo?.hostname,
                    store: self,
                });

                let volumes = [...volumeStore.checkedQueueVolumes].map((volume) => `${prefix}${volume.pool}/${volume.volume}`);

                multipleStores.forEach((store) => {
                    const { evoSettingsStore } = getParent(self);

                    const prefix = getEvoPrefix({
                        ip: store.ip,
                        evoName: store.name,
                        hostname: evoSettingsStore?.evoInfo?.hostname,
                        store: self,
                    });

                    const checkedQueueVolumes = store.volumeStore.checkedQueueVolumes.map(
                        (volume) => `${prefix}${volume.pool}/${volume.volume}`
                    );

                    volumes = [...volumes, ...checkedQueueVolumes];
                });

                return volumes;
            },
            get allCheckedVolumesCount() {
                let count = volumeStore.checkedVolumesCount;
                multipleStores.forEach((store) => (count += store.volumeStore.checkedVolumesCount));
                return count;
            },
            get someNotOffline() {
                return (
                    volumeStore.someNotOffline ||
                    [...multipleStores].map(([, value]) => value).some((store) => store.volumeStore.someNotOffline)
                );
            },
            get currentPort() {
                return self.currentPortStore?.currentPort;
            },
            get currentPortWithPrefix() {
                const { evoSettingsStore } = getParent(self);

                const prefix = getEvoPrefix({
                    ip: self.currentPortStoreIp,
                    evoName: self.currentPortStoreName,
                    hostname: evoSettingsStore?.evoInfo?.hostname,
                    store: self,
                });

                return self.currentPort?.volume
                    ? `${prefix}${self.currentPort?.volume.volumeId.pool}/${self.currentPort?.volume.volumeId.volume}`
                    : null;
            },
            get currentPortStore() {
                const volumeStoreContainCurrentPort = volumeStore.currentPort;
                return (
                    [...multipleStores].map(([, value]) => value).find((store) => store.volumeStore.currentPort)?.volumeStore ||
                    (volumeStoreContainCurrentPort && volumeStore)
                );
            },
            get currentPortStoreIp() {
                return self.currentPortStore && getParent(self.currentPortStore)?.ip;
            },
            get currentPortStoreName() {
                return self.currentPortStore && getParent(self.currentPortStore)?.name;
            },
            get currentPoolName() {
                return self.currentPoolStore?.currentPoolName;
            },
            get currentPoolNameWithPrefix() {
                const { evoSettingsStore } = getParent(self);
                const evo = self.poolIp ? MultipleEvosRootStore.getStore(self.poolIp) : getParent(self);
                const prefix = getEvoPrefix({
                    ip: self.poolIp,
                    evoName: evo?.name ?? "",
                    hostname: evoSettingsStore?.evoInfo?.hostname,
                    store: self,
                });

                return `${prefix}${self.currentPoolStore?.currentPoolName}`;
            },
            get currentPoolStore() {
                const poolsStoreContainCurrentPool = poolsStore.currentPoolName;

                return (
                    [...multipleStores].map(([, value]) => value).find((store) => store.poolsStore.currentPoolName)?.poolsStore ||
                    (poolsStoreContainCurrentPool && poolsStore)
                );
            },
            get currentPoolStoreIp() {
                return self.currentPoolStore && getParent(self.currentPoolStore)?.ip;
            },
            get storesWithCheckedVolumes() {
                const stores = [];
                if (volumeStore.checkedVolumes.length > 0) {
                    stores.push(getParent(self));
                }
                [...multipleStores]
                    .map(([, value]) => value)
                    .forEach((store) => store.volumeStore.checkedVolumes.length > 0 && stores.push(store));
                return stores;
            },
            get storesWithCheckedQueueVolumes() {
                const stores = [];
                if (volumeStore.checkedQueueVolumes.length > 0) {
                    stores.push(getParent(self));
                }
                [...multipleStores]
                    .map(([, value]) => value)
                    .forEach((store) => store.volumeStore.checkedQueueVolumes.length > 0 && stores.push(store));
                return stores;
            },
        };
    })
    .actions((self) => {
        const { volumeStore, poolsStore } = getParent(self);
        const multipleStores = MultipleEvosRootStore.stores;
        return {
            setVolumesOnline: flow(function* () {
                const { processingStore } = getParent(self);
                try {
                    processingStore.setLoading(true);

                    yield Promise.all(
                        self.storesWithCheckedVolumes.map((store) =>
                            store.volumeStore.setVolumesOnline({
                                volumeIds: store.volumeStore.checkedVolumes.map((volume) => {
                                    return { pool: volume.pool, volume: volume.volume };
                                }),
                                online: !self.someNotOffline,
                            })
                        )
                    );
                    return true;
                } catch (e) {
                    //handled in shareStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            removeVolumes: flow(function* () {
                const { processingStore } = getParent(self);
                try {
                    processingStore.setLoading(true);

                    yield Promise.all([
                        ...self.storesWithCheckedVolumes.map((store) =>
                            store.volumeStore.removeVolumes({
                                volumeIds: store.volumeStore.checkedVolumes.map((volume) => {
                                    return { pool: volume.pool, volume: volume.volume };
                                }),
                            })
                        ),
                        ...self.storesWithCheckedQueueVolumes.map((store) =>
                            store.volumeStore.dequeueVolumes({
                                volumeIds: store.volumeStore.checkedQueueVolumes.map((volume) => {
                                    return { pool: volume.pool, volume: volume.volume };
                                }),
                            })
                        ),
                    ]);
                    return true;
                } catch (e) {
                    //handled in shareStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            dropOthersCurrentPorts: (ip) => {
                if (ip) {
                    volumeStore.dropCurrentPortName();
                    multipleStores.forEach((store) => store.ip !== ip && store.volumeStore.dropCurrentPortName());
                } else {
                    multipleStores.forEach((store) => store.volumeStore.dropCurrentPortName());
                }
            },
            dropAllCurrentPorts: () => {
                volumeStore.dropCurrentPortName();
                multipleStores.forEach((store) => store.volumeStore.dropCurrentPortName());
            },
            clearCheckedVolumes: () => {
                volumeStore.clearCheckedVolumes();
                multipleStores.forEach((store) => store.volumeStore.clearCheckedVolumes());
            },
            clearCheckedQueueVolumes: () => {
                volumeStore.clearCheckedQueueVolumes();
                multipleStores.forEach((store) => store.volumeStore.clearCheckedQueueVolumes());
            },
            dropOthersCurrentPools: (ip) => {
                if (ip) {
                    poolsStore.setCurrentPoolName(null);
                    multipleStores.forEach((store) => store.ip !== ip && store.poolsStore.poolsStore.setCurrentPoolName(null));
                } else {
                    multipleStores.forEach((store) => store.poolsStore.setCurrentPoolName(null));
                }
            },
            dropAllCurrentPools: () => {
                poolsStore.setCurrentPoolName(null);
                multipleStores.forEach((store) => store.poolsStore.setCurrentPoolName(null));
            },
            setCurrentPoolIp: (ip) => {
                self.poolIp = ip;
            },
        };
    });

export default VolumeDrawerStore;
