import React from "react";

const LoginIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                d="M19.5 4c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1h-2c-.552 0-1-.448-1-1s.448-1 1-1h1V6h-1c-.552 0-1-.448-1-1s.448-1 1-1zm-8.6 3.2c.442-.331 1.069-.242 1.4.2l3.02 4.02c.257.351.257.829 0 1.18l-2.82 4c-.187.262-.488.419-.81.42l-.124-.007c-.163-.019-.32-.078-.456-.173l-.004-.003c-.453-.323-.557-.948-.236-1.397L12.59 13H4.5c-.552 0-1-.448-1-1s.448-1 1-1h8l-1.8-2.4c-.331-.442-.242-1.069.2-1.4z"
            />
        </svg>
    );
};

export default LoginIcon;
