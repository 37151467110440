import React, { useContext, useEffect } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import DataCard from "components/DataCard";
import { useStyles } from "./InstalledComponentsTableCard.styles";
import MoreMenu from "components/MoreMenu";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { INSTALLED_COMPONENTS_TABLE_CARD } from "const/widgetNameConst";
import { Context } from "store";
import ChevronRightIcon from "assets/ChevronRightIcon";
import TableRowControlButton from "components/TableRowControlButton";
import SystemComponentDrawer from "./components/SystemComponentDrawer";

const InstalledComponentsTableCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { systemComponentsStore }
    } = useContext(Context);
    const state = useLocalStore(() => ({
        get components() {
            return (
                (systemComponentsStore.systemComponents?.length &&
                    systemComponentsStore.systemComponents
                        .slice()
                        .sort((a, b) => a.componentName.localeCompare(b.componentName))) ||
                []
            );
        }
    }));

    const toggleDrawer = componentName => () => {
        systemComponentsStore.setCurrentComponentName(
            systemComponentsStore.currentComponentName !== componentName ? componentName : null
        );
    };

    useEffect(() => {
        systemComponentsStore.fetchSystemComponents();
    }, []);

    return useObserver(() => (
        <DataCard
            headerDivider
            headerControl={
                <MoreMenu className={classes.headerMoreMenu}>
                    <HomeCardActionMenuItem widget={INSTALLED_COMPONENTS_TABLE_CARD} />
                </MoreMenu>
            }
            title={t("support.start.installed_components.title")}
            emptyPadding
        >
            <TableContainer component={Paper} elevation={0}>
                <SystemComponentDrawer />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("support.start.installed_components.table.name")}</TableCell>
                            <TableCell>{t("support.start.installed_components.table.version")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.components.map(component => (
                            <TableRow key={component.componentName} onClick={toggleDrawer(component.componentName)}>
                                <TableCell>{component.componentName}</TableCell>
                                <TableCell>{component.componentVersion}</TableCell>
                                <TableCell align={"right"}>
                                    <TableRowControlButton icon={<ChevronRightIcon />} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DataCard>
    ));
};

export default InstalledComponentsTableCard;
