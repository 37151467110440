import { types } from "mobx-state-tree";

import BatchTask from "api/task/Types/BatchTask";

const BatchTaskPage = types.model({
    data: types.array(BatchTask),
    total: types.number
});

export default BatchTaskPage;
