import React from "react";
import { useObserver } from "mobx-react";
import { useStyles } from "./QuadItem.styles";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import Checkbox from "components/Checkbox";
import { useTranslation } from "react-i18next";

import ActivatedQuadIcon from "assets/QuadActivatedIcon";
import DeactivatedQuadIcon from "assets/QuadDeactivatedIcon";
import { ACTIVATED, DEACTIVATED, DEACTIVATING, ACTIVATING } from "const/quadActivationStatuses";

const QuadItem = ({ quad, checked, onClick, id }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const onItemClick = e => {
        !quad.inProgress && onClick(e);
    };

    const getStatus = () => {
        switch (quad.quadState) {
            case ACTIVATED:
                return t("hardware_activation.common.activated");
            case DEACTIVATED:
                return t("hardware_activation.common.deactivated");
            case ACTIVATING:
                return t("hardware_activation.common.activating");
            case DEACTIVATING:
                return t("hardware_activation.common.deactivating");
            default:
                return t("hardware_activation.common.deactivated");
        }
    };

    return useObserver(() => (
        <Grid container wrap={"nowrap"} alignItems={"center"} justify={"space-between"} className={classes.quadItemContainer}>
            <Grid item container alignItems={"center"} wrap={"nowrap"} onClick={onItemClick}>
                <Grid item>
                    <Checkbox disabled={quad.inProgress} checked={checked} id={`${id}_checkbox`} />
                </Grid>
                <Grid item container xs={6} alignItems={"center"} spacing={4} wrap={"nowrap"}>
                    <Grid item className={classes.icon}>
                        {quad.activated ? <ActivatedQuadIcon /> : <DeactivatedQuadIcon />}
                    </Grid>
                    <Grid item>
                        <Typography noWrap>{t("hardware_activation.common.quad", { quad: quad.quad })}</Typography>
                    </Grid>
                </Grid>
                <Grid className={classes.label} item>
                    <Grid container spacing={2} alignItems={"center"} wrap={"nowrap"}>
                        <Grid item>
                            <Typography variant={"body2"}>{getStatus()}</Typography>
                        </Grid>
                        {quad.inProgress && (
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <CircularProgress size={16} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    ));
};

export default QuadItem;
