import React from "react";

const BrowserIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="nonzero"
                d="M18 3a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6l.005-.176A3 3 0 0 1 6 3zm1 8H5v7l.007.117A1 1 0 0 0 6 19h12a1 1 0 0 0 1-1v-7zm-1-6H6a1 1 0 0 0-1 1v3h14V6a1 1 0 0 0-1-1zM8.707 6.323a1 1 0 1 1-1.414 1.414 1 1 0 0 1 1.414-1.414zm4 0a1 1 0 1 1-1.414 1.414 1 1 0 0 1 1.414-1.414z"
            />
        </svg>
    );
};

export default BrowserIcon;
