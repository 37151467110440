import { types } from "mobx-state-tree";
import ProcessedFileType from "./ProcessedFileType";

const ProcessedFilesResult = types.model({
    json: types.maybe(
        types.model({
            total: types.maybe(types.number),
            automation_id: types.maybe(types.number),
            results: types.maybe(types.array(ProcessedFileType)),
            limit: types.maybe(types.number),
            offset: types.maybe(types.number)
        })
    )
});

export default ProcessedFilesResult;
