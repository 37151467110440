import { types } from "mobx-state-tree";

const BatchTask = types.model("BatchTask", {
    id: types.number,
    startTime: types.string,
    type: types.string,
    text: types.string,
    failedText: types.string,
    total: types.number,
    succeeded: types.number,
    failed: types.number,
    status: types.string,
    sequenceId: types.optional(types.number, 0) // to avoid wrong broadcast order, sets from 'context'
});

export default BatchTask;
