import React from "react";
import { useLocalStore, observer } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GridRowsExpand from "components/GridRowsExpand";
import DataReadonlyCard from "components/DataReadonlyCard";
import SetupExtensionExclusionsDialog from "../SetupExtensionExclusionsDialog";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { useStore } from "hooks/useStore";
import { useLauncher } from "hooks/useLauncher";
import { useStyles } from "./DefaultExtensionExclusionsCard.styles";

const DEFAULT_EXTENSION_EXCLUSIONS_CARD_ID_PREFIX = `default_extension_excludions_card`;
const HIDDEN_ROWS_PER_CARD_INDEX = 6;

const DefaultExtensionExclusionsCard = ({ parentId }) => {
    const idPrefix = parentId
        ? `${parentId}_${DEFAULT_EXTENSION_EXCLUSIONS_CARD_ID_PREFIX}`
        : DEFAULT_EXTENSION_EXCLUSIONS_CARD_ID_PREFIX;
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: { indexingConfigStore },
    } = useStore();

    const state = useLocalStore(() => ({
        openEditDialog: false,
        isLoading: true,
        isExpanded: false,
        get slicedIndex() {
            if (this.isExpanded) {
                return indexingConfigStore.mediaProxy.extensionBlackList.length;
            } else {
                return HIDDEN_ROWS_PER_CARD_INDEX;
            }
        },
        beginLoading() {
            this.isLoading = true;
        },
        endLoading() {
            this.isLoading = false;
        },
    }));

    useLauncher([state.beginLoading, indexingConfigStore.fetchConfig, state.endLoading], {
        ignoreErrors: false,
        dependencies: [indexingConfigStore.isErrorConnectedDBus],
    });

    const closeDialog = () => {
        state.openEditDialog = false;
    };
    const openDialog = () => {
        state.openEditDialog = true;
    };

    const toggleExpandExtensionBlackList = (e) => {
        e.stopPropagation();
        state.isExpanded ? (state.isExpanded = false) : (state.isExpanded = true);
    };

    return (
        <>
            <SetupExtensionExclusionsDialog open={state.openEditDialog} closeDialog={closeDialog} parentId={idPrefix} />
            <DataReadonlyCard
                title={t("slingshot.index_proxy.extension_exclusions.title")}
                autoHeight
                emptyPadding
                headerDivider
                disabled={indexingConfigStore.isErrorConnectedDBus}
                onClick={openDialog}
            >
                <Grid>
                    {indexingConfigStore.mediaProxy?.extensionBlackList?.length ? (
                        <>
                            <Grid className={classes.container} container direction={"column"} spacing={8}>
                                {indexingConfigStore.mediaProxy.extensionBlackList.slice(0, state.slicedIndex).map((row) => (
                                    <Grid item key={Math.floor(Math.random() * Date.now())}>
                                        <Typography>{row}</Typography>
                                    </Grid>
                                ))}
                            </Grid>

                            {indexingConfigStore.mediaProxy.extensionBlackList.length > HIDDEN_ROWS_PER_CARD_INDEX && (
                                <GridRowsExpand onClick={toggleExpandExtensionBlackList}>
                                    {state.isExpanded
                                        ? t("slingshot.index_proxy.extension_exclusions.show_all_rows.hide")
                                        : t("slingshot.index_proxy.extension_exclusions.show_all_rows.show", {
                                              count: indexingConfigStore.mediaProxy.extensionBlackList.length,
                                          })}
                                </GridRowsExpand>
                            )}
                        </>
                    ) : (
                        <EmptyCardPlaceholder
                            inProgress={indexingConfigStore.isErrorConnectedDBus || state.isLoading}
                            message={t("slingshot.index_proxy.extension_exclusions.placeholder")}
                        />
                    )}
                </Grid>
            </DataReadonlyCard>
        </>
    );
};

export default observer(DefaultExtensionExclusionsCard);
