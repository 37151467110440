import React, { useMemo } from "react";
import { Card as MuiCard, CardMedia, CardContent, Typography, CardActions, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useStyles } from "./Card.style";
import { useTranslation } from "react-i18next";
import { URL_SHAREBROWSER } from "routes";

const Card = props => {
    const { img: imgUrl, title, description, url, ImgComponent, replace, forPlugin, fakeCard, onClick, ...rest } = props;
    const classes = useStyles({ forPlugin, fakeCard });
    const history = useHistory();
    const { t } = useTranslation();

    const handleClick = () => {
        if (onClick) {
            onClick();
            return;
        }

        if (url === URL_SHAREBROWSER) return (window.location.href = URL_SHAREBROWSER);

        if (replace) return (window.location.href = url);

        history.push(url);
        history.go(0);
    };

    const imageExists = useMemo(() => {
        if (!imgUrl) return false;
        var http = new XMLHttpRequest();

        http.open("HEAD", imgUrl, false);
        http.send();

        return http.status !== 404;
    }, [imgUrl]);

    if (fakeCard) return <MuiCard {...rest} className={classes.container}></MuiCard>;

    return (
        <MuiCard {...rest} className={classes.container}>
            {ImgComponent ? (
                <ImgComponent />
            ) : (
                <CardMedia className={classes.header} height={237} component={"img"} src={imageExists ? imgUrl : undefined} />
            )}

            <CardContent className={classes.content}>
                <Typography
                    className={classes.contentTitle}
                    variant={forPlugin ? "h4" : "h1"}
                    component={"h2"}
                    color={"textPrimary"}
                    gutterBottom
                >
                    {title}
                </Typography>
                <Typography gutterBottom className={classes.contentText}>
                    {description}
                </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
                <Button
                    color={"primary"}
                    variant={"contained"}
                    size={forPlugin ? "small" : "large"}
                    value={url}
                    onClick={handleClick}
                    className={classes.actionButton}
                >
                    {t("login.label.login")}
                </Button>
            </CardActions>
        </MuiCard>
    );
};

export default Card;
