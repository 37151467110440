import { types } from "mobx-state-tree";
import InstanceType from "./InstanceType";

const InstancesResult = types.model({
    json: types.maybe(
        types.model({
            total: types.maybe(types.number),
            offset: types.maybe(types.number),
            limit: types.maybe(types.number),
            instances: types.maybe(types.array(InstanceType))
        })
    )
});

export default InstancesResult;
