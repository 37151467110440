import { types } from "mobx-state-tree";

import Request from "api/Request";
import BackupSettings from "api/system_components/backup_schedule/Types/BackupSettings";

const SetBackupSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/SystemComponentsBackups",
    method: "set_backup_settings",
    arguments: types.maybe(BackupSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetBackupSettings;
