import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        height: 10,
        display: "flex",
        alignItems: "center",
        marginTop: ({ hoverPart }) => (hoverPart === 1 ? theme.spacing(-1.25) : theme.spacing(1.75)),
        marginBottom: ({ hoverPart }) => (hoverPart === 1 ? theme.spacing(1.75) : theme.spacing(-1.25))
    },
    oval: {
        borderRadius: "50%",
        width: 10,
        height: 10,
        backgroundColor: theme.palette.primary.main
    },
    line: {
        width: "100%",
        height: 4,
        backgroundColor: theme.palette.primary.main
    }
}));
