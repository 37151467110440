import React from "react";

const NotistackAlertIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#FFF"
                fillRule="evenodd"
                d="M14.342 3.555l7.188 12.442c1.19 2.057.024 4.003-2.398 4.003H4.87c-2.424 0-3.59-1.941-2.4-4.003L9.651 3.555c1.197-2.072 3.492-2.075 4.69 0zm-1.744.956c-.433-.75-.867-.604-1.203 0l-7.18 12.441c-.342.652-.239 1.1.655 1.1h14.262c.655 0 1.094-.34.655-1.1L12.598 4.511zm-.602 12.566c-.552 0-1-.436-1-.974s.448-.975 1-.975c.553 0 1 .437 1 .975s-.447.974-1 .974zm-1-8.766c0-.54.444-.977 1-.977.553 0 1 .437 1 .977v4.866c0 .54-.444.977-1 .977-.552 0-1-.437-1-.977V8.311z"
            ></path>
        </svg>
    );
};

export default NotistackAlertIcon;
