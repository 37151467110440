import { types } from "mobx-state-tree";

import Ups from "api/power/Types/Ups";

const UpsArrayResultData = types.model({
    arr: types.array(Ups),
    total: types.number
});

export default UpsArrayResultData;
