import React from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { observer, useLocalStore } from "mobx-react";
import SavedInputIndicator from "components/SavedInputIndicator";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import MoreMenu from "components/MoreMenu";
import SetPauseModal from "./components/SetPauseModal";
import moment from "moment";
import { SLINGSHOT_PROXY_PAUSE_CARD } from "const/widgetNameConst";
import { useStore } from "hooks/useStore";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import { useLauncher } from "hooks/useLauncher";

const INDEX_PAUSE_CARD_ID_PREFIX = `index_pause_card`;

const IndexPauseCard = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${INDEX_PAUSE_CARD_ID_PREFIX}` : INDEX_PAUSE_CARD_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { indexingConfigStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isEditModalOpen: false,
        isLoading: true,
        beginLoading() {
            this.isLoading = true;
        },
        endLoading() {
            this.isLoading = false;
        },
    }));

    useLauncher([state.beginLoading, indexingConfigStore.fetchConfig, state.endLoading], {
        ignoreErrors: false,
        dependencies: [indexingConfigStore.isErrorConnectedDBus],
    });

    const openEditModal = () => {
        state.isEditModalOpen = true;
    };
    const closeEditModal = () => {
        state.isEditModalOpen = false;
    };

    return (
        <>
            <DataReadonlyCard
                headerDivider
                autoHeight
                title={t("slingshot.index_proxy.pause.title")}
                onClick={openEditModal}
                disabled={indexingConfigStore.isErrorConnectedDBus}
                control={
                    <MoreMenu>
                        <HomeCardActionMenuItem
                            widget={SLINGSHOT_PROXY_PAUSE_CARD}
                            homeCardActionMenuItemId={`${idPrefix}_add_to_home`}
                        />
                    </MoreMenu>
                }
            >
                {indexingConfigStore.isConfigLoaded ? (
                    <Grid container direction="column" spacing={6} wrap="nowrap">
                        <Grid item container wrap="nowrap" alignItems="baseline">
                            <Grid item xs={6}>
                                <Typography>{t("slingshot.index_proxy.pause.label.spin_down")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <SavedInputIndicator enabled={indexingConfigStore.pauseEnabled} />
                            </Grid>
                        </Grid>
                        {indexingConfigStore.pauseEnabled && (
                            <>
                                <Grid item container wrap="nowrap" alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography>{t("slingshot.index_proxy.pause.label.pause")}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h6">
                                            {moment(indexingConfigStore.pauseStartTime).format("HH:mm")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container wrap="nowrap" alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography>{t("slingshot.index_proxy.pause.label.resume")}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h6">
                                            {moment(indexingConfigStore.pauseEndTime).format("HH:mm")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container wrap="nowrap" alignItems="baseline">
                                    <Grid item xs={6}>
                                        <Typography>{t("slingshot.index_proxy.pause.label.hoc_generation")}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SavedInputIndicator enabled={indexingConfigStore.adHocRequestWithoutPause} />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder inProgress={indexingConfigStore.isErrorConnectedDBus || state.isLoading} />
                )}
            </DataReadonlyCard>
            <SetPauseModal open={state.isEditModalOpen} onClose={closeEditModal} parentId={idPrefix} />
        </>
    );
};

export default observer(IndexPauseCard);
