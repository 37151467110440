import React, { useContext } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import InfoCard from "components/InfoCard";
import PicLogsIcon from "assets/PicLogsIcon";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { useStyles } from "./SystemLogs.styles";
import { SYSTEM_LOGS } from "const/widgetNameConst";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";

const SystemLogs = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { supportInfoStore }
    } = useContext(Context);
    const state = useLocalStore(() => ({
        inProgress: false
    }));

    const downloadSystemLogs = async () => {
        state.inProgress = true;
        await supportInfoStore.getSystemLogs();
        state.inProgress = false;
    };

    return useObserver(() => (
        <InfoCard
            widget={SYSTEM_LOGS}
            flag
            className={classes.systemLogsContainer}
            icon={<PicLogsIcon />}
            title={t("support.system_logs.title")}
            content={t("support.system_logs.content")}
            button={
                <AnimatedSubmitButton
                    inProgress={state.inProgress}
                    submit={downloadSystemLogs}
                    disabled={state.inProgress}
                    label={t("common.button.download")}
                />
            }
        />
    ));
};

export default SystemLogs;
