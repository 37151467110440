import { types } from "mobx-state-tree";

import Request from "api/Request";
import RemoveJobArguments from "api/slingshot/sync_jobs/Types/RemoveJobArguments";

const RemoveJob = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/SyncJobs1",
    method: "remove_job",
    arguments: types.maybe(
        types.snapshotProcessor(RemoveJobArguments, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json)
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default RemoveJob;
