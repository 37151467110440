import i18n from "i18n";

export const getLanguageDateFormat = (renderFormat) => {
    switch (i18n.languages[1]) {
        case "en":
            return renderFormat.en;
        case "zh":
            return renderFormat.zh;
        default:
            return renderFormat.default;
    }
};
