import { useEffect, useState } from "react";

/**
 * @typedef {{ignoreErrors: boolean; onDone: function; onError: (err: Error) => void; dependencies: any[]}} Options
 */
/**
 * Hook for running functions in order with the expectation that the promise will be fulfilled.
 * @param {function[]} arrayFn
 * @param {Options} options
 * @returns
 *
 * @example
 *  useLauncher([
 *       syncJobsStore.fetchJobs,
 *       () => (state.isJobsLoaded = true),
 *       syncJobsStore.getServiceState,
 *       emailConfigurationStore.getEmailSettings
 *   ]);
 *
 *  const { isError } = useLauncher(
 *    [
 *      state.beginLoading,
 *      () => isCorrectTableType && automationsStore.fetchAutomations(state.form.$("tableType").value),
 *      state.saveLastSearchRequest,
 *      state.endLoading,
 *      () => isCorrectTableType && automationsStore.setCurrentAutomationListType(state.form.$("tableType").value),
 *      emailConfigurationStore.getEmailSettings
 *    ],
 *    { ignoreErrors: false, dependencies: [state.form.$("tableType").value] }
 *  );
 */
export const useLauncher = (arrayFn, options = { ignoreErrors: true }) => {
    const [errors, setErrors] = useState([]);
    const [isDone, toggleIsDone] = useState(false);
    const handleError = error => {
        setErrors(prevErrors => [...prevErrors, error]);
    };
    const resetErrors = () => {
        setErrors([]);
    };
    const handleDone = () => {
        toggleIsDone(true);
    };

    useEffect(() => {
        toggleIsDone(false);
        resetErrors();
        execute(createGenerator(arrayFn), {
            onError: handleError,
            onDone: handleDone,
            ...options
        });

        return () => {
            if (options.unmountFunction) {
                options.unmountFunction();
            }
        };
    }, options.dependencies ?? []);

    const isError = Boolean(errors.length);

    return {
        errors,
        isError,
        isDone
    };
};

/**
 * Function generator
 * @param {function[]} arrayFn
 * @returns
 */
function* createGenerator(arrayFn) {
    for (let fn of arrayFn) {
        yield fn();
    }
}
/**
 *
 * @param {Generator<any, void, unknown>} generator
 * @param {Options} options
 */
async function execute(generator, options) {
    let next = generator.next();

    if (!next.done) {
        if (next.value instanceof Promise) {
            const response = await next.value;
            const isError = Array.isArray(response) ? response.some(el => el instanceof Error) : response instanceof Error;

            if (isError && options.onError) {
                options.onError(response);
            }

            if ((isError && options.ignoreErrors) || !isError) {
                execute(generator, options);
            }
        } else {
            execute(generator, options);
        }
    } else {
        options.onDone();
    }
}
