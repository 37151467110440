import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    header: {
        padding: theme.spacing(5, 6)
    },
    icon: {
        padding: theme.spacing(4, 2),
        boxSizing: "content-box"
    },
    table: {
        marginBottom: theme.spacing(4)
    }
}));
