import React from "react";

const InfinityIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#BFD1E1"
                fillRule="evenodd"
                d="M16.8 7c2.761 0 5 2.239 5 5s-2.239 5-5 5c-1.892 0-3.813-1-4.9-2.457C10.813 16 8.892 17 7 17c-2.761 0-5-2.239-5-5s2.239-5 5-5c1.892 0 3.813 1 4.9 2.457C12.987 8 14.908 7 16.8 7zM7 9c-1.657 0-3 1.343-3 3s1.343 3 3 3c1.856 0 3.8-1.524 3.8-3S8.856 9 7 9zm9.8 0c-1.856 0-3.8 1.524-3.8 3s1.944 3 3.8 3c1.657 0 3-1.343 3-3s-1.343-3-3-3z"
            />
        </svg>
    );
};

export default InfinityIcon;
