import React from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { ButtonBase, Menu } from "@material-ui/core";
import MultipleEvoIcon from "assets/MultipleEvoIcon";
import { useStyles } from "./EvoMenu.styles";

const EvoMenu = ({ menuProps, children, ...rest }) => {
    const state = useLocalStore(() => ({
        anchorEl: null
    }));
    const classes = useStyles({ isOpen: state.anchorEl !== null });
    const handleClick = e => {
        state.anchorEl = e.currentTarget;
        e.stopPropagation();
    };
    const handleClose = e => {
        state.anchorEl = null;
        e.stopPropagation();
    };
    return useObserver(() => (
        <>
            <ButtonBase onClick={handleClick} className={classes.evoMenuBtn} {...rest}>
                <MultipleEvoIcon />
            </ButtonBase>
            <Menu
                className={classes.menuPaper}
                anchorOrigin={{
                    vertical: 71,
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                getContentAnchorEl={null}
                anchorEl={state.anchorEl}
                open={!!state.anchorEl}
                onClose={handleClose}
                {...menuProps}
            >
                {children}
            </Menu>
        </>
    ));
};

export default EvoMenu;
