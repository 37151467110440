import React from "react";
import {
    AD_CREDENTIALS_STEP,
    LDAP_CREDENTIALS_STEP,
    MAIN_STEP,
    SHARE_TYPES_STEP,
} from "const/migrationConst";
import MixedSharesStep from "../MixedSharesStep";
import ExternalUserCredentialsStep from "../ExternalUserCredentialsStep";
import MainStep from "../MainStep";
import { observer } from "mobx-react-lite";
import { useStore } from "hooks/useStore";
import SuccessfulMigration from "../SuccessfulMigration";

const ContentStep = () => {
    const {
        store: { migrationStore },
    } = useStore();

    if (migrationStore.isSuccessMigration) return <SuccessfulMigration />;

    switch (migrationStore.typeActiveStep) {
        case SHARE_TYPES_STEP:
            return <MixedSharesStep />;
        case AD_CREDENTIALS_STEP:
            return <ExternalUserCredentialsStep />;
        case LDAP_CREDENTIALS_STEP:
            return <ExternalUserCredentialsStep ldap />;
        case MAIN_STEP:
            return <MainStep />;
        default:
            return null;
    }
};

export default observer(ContentStep);
