import { types } from "mobx-state-tree";

const GetSharesType = types.model({
    json: types.maybe(
        types.model({
            includeBrick: types.maybe(types.boolean),
            includeInternal: types.maybe(types.boolean),
            includeExternal: types.maybe(types.boolean)
        })
    )
});
export default GetSharesType;
