import { types } from "mobx-state-tree";

import ExternalShare from "api/external_shares/Types/ExternalShare";

const ListExternalSharesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(ExternalShare)
});

export default ListExternalSharesResult;
