import * as React from "react";

const GrowPoolIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <g fill="#1E90FF" fillRule="nonzero">
                <g>
                    <g>
                        <g>
                            <path
                                d="M9 13c1.054 0 1.918.816 1.995 1.85L11 15v4c0 1.054-.816 1.918-1.85 1.995L9 21H5c-1.054 0-1.918-.816-1.995-1.85L3 19v-4c0-1.054.816-1.918 1.85-1.995L5 13h4zm10 0c1.054 0 1.918.816 1.995 1.85L21 15v4c0 1.054-.816 1.918-1.85 1.995L19 21h-4c-1.054 0-1.918-.816-1.995-1.85L13 19v-4c0-1.054.816-1.918 1.85-1.995L15 13h4zM9 15H5v4h4v-4zm10 0h-4v4h4v-4zM9 3c1.054 0 1.918.816 1.995 1.85L11 5v4c0 1.054-.816 1.918-1.85 1.995L9 11H5c-1.054 0-1.918-.816-1.995-1.85L3 9V5c0-1.054.816-1.918 1.85-1.995L5 3h4zm8 0c.552 0 1 .448 1 1v2h2c.552 0 1 .448 1 1s-.448 1-1 1h-2v2c0 .552-.448 1-1 1s-1-.448-1-1V8h-2c-.552 0-1-.448-1-1s.448-1 1-1h2V4c0-.552.448-1 1-1zM9 5H5v4h4V5z"
                                transform="translate(-1137 -197) translate(604 72) translate(0 109) translate(533 16)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default GrowPoolIcon;
