import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetConnectionsResult from "api/vpn/Responses/GetConnectionsResult";
import RemoveConnection from "api/vpn/Requests/RemoveConnection";
import SetConnectionOnline from "api/vpn/Requests/SetConnectionOnline";
import SetConnectionAutostart from "api/vpn/Requests/SetConnectionAutostart";
import GetConnections from "api/vpn/Requests/GetConnections";
import ImportConnection from "api/vpn/Requests/ImportConnection";
import { FILE_UPLOAD_URL } from "api/restRoutes";
import axios from "api/AxiosCommonRequest";

const WireguardStore = types
    .model({
        connectionsStore: types.maybe(GetConnectionsResult),
        currentConnectionName: types.maybeNull(types.string)
    })
    .views(self => ({
        get connections() {
            return self.connectionsStore && self.connectionsStore.data;
        },
        get currentConnection() {
            return (
                self.currentConnectionName !== null &&
                self.connections.find(connection => connection.connectionName === self.currentConnectionName)
            );
        }
    }))
    .actions(self => ({
        fetchConnections: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetConnections.create().init();
                const res = yield Socket.send(req);
                self.connectionsStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setCurrentConnectionName: connectionName => {
            self.currentConnectionName = connectionName;
            self.connections.find(connection => connection.connectionName === self.currentConnectionName);
        },
        setAutostartEnabled: flow(function*(connection, enabled) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetConnectionAutostart.create().init({
                    connectionName: connection.connectionName,
                    autostart: enabled
                });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        deleteConnection: flow(function*(connection) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RemoveConnection.create().init({ connectionName: connection.connectionName });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setActivated: flow(function*(connection, activated) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetConnectionOnline.create().init({ connectionName: connection.connectionName, online: activated });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        callAddConnectionApi: flow(function*(newConnectionName, fileName) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ImportConnection.create().init({ connectionName: newConnectionName, configFileName: fileName });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        addConnection: flow(function*(connectionName, file) {
            const { processingStore } = getParent(self);
            const formData = new FormData();
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            try {
                formData.append("file", file);
                processingStore.setLoading(true);
                yield axios.post(FILE_UPLOAD_URL, formData, config);
                return self.callAddConnectionApi(connectionName, file.name);
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        updateConnectionInStore: connectionInfo => {
            if (self.connectionsStore) {
                const index = self.connectionsStore.data.findIndex(el => el.connectionName === connectionInfo.connectionName);
                if (index < 0) {
                    self.connectionsStore.data.push(connectionInfo);
                } else {
                    self.connectionsStore.data[index] = connectionInfo;
                }
            }
        },
        removeConnectionFromStore: connectionName => {
            if (self.connectionsStore && self.connectionsStore.data.length !== 0) {
                self.connectionsStore.data = self.connectionsStore.data.filter(
                    connection => connection.connectionName !== connectionName
                );
            }
        }
    }));

export default WireguardStore;
