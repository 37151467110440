import React, { useContext, useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Context } from "store";
import SetConfigDialog from "./components/SetConfigDialog";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { ADMIN_NETWORKING_SETTINGS_CARD } from "const/widgetNameConst";
import MoreMenu from "components/MoreMenu";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import SavedInputIndicator from "components/SavedInputIndicator";

const NETWORKING_SETTINGS_CARD_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_networking_settings_card`;

const NetworkingSettingsCard = () => {
    const { t } = useTranslation();
    const {
        store: { webserverStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isDialogOpen: false
    }));

    useEffect(() => {
        webserverStore.fetchConfigInfo();
    }, []);

    const openDialog = () => {
        state.isDialogOpen = true;
    };
    const closeDialog = () => {
        state.isDialogOpen = false;
    };

    return useObserver(() => (
        <>
            <DataReadonlyCard
                autoHeight
                headerDivider
                onClick={openDialog}
                title={t("admin_settings.networking_settings.title")}
                control={
                    <MoreMenu id={`${NETWORKING_SETTINGS_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={ADMIN_NETWORKING_SETTINGS_CARD} />
                    </MoreMenu>
                }
            >
                {webserverStore.configInfo && (
                    <Grid container direction={"column"} spacing={4} wrap={"nowrap"}>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={6}>
                                <Typography>{t("admin_settings.networking_settings.label.http_port")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h6"}>{webserverStore.configInfo.httpPort}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("admin_settings.networking_settings.label.enable.https")}</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                <SavedInputIndicator enabled={webserverStore.configInfo?.sslEnabled} />
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={6}>
                                <Typography>{t("admin_settings.networking_settings.label.https_port")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h6"}>{webserverStore.configInfo.httpsPort}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </DataReadonlyCard>
            {webserverStore.configInfo && <SetConfigDialog open={state.isDialogOpen} onClose={closeDialog} />}
        </>
    ));
};

export default NetworkingSettingsCard;
