import { types } from "mobx-state-tree";

import Brick from "api/cluster/Types/Brick";
import RemoveBricksNode from "api/cluster/Types/RemoveBricksNode";
import RemoveBricksAggregate from "api/cluster/Types/RemoveBricksAggregate";

const RemoveBricksTask = types.model({
    volumeName: types.string,
    status: types.string,
    bricks: types.array(Brick),
    nodes: types.array(RemoveBricksNode),
    aggregate: types.maybe(RemoveBricksAggregate)
});

export default RemoveBricksTask;
