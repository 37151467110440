import Request from "api/Request";

const DownloadAuthenticationLog = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Audit",
    method: "download_authentication_log"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default DownloadAuthenticationLog;
