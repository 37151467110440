import React from "react";
import { useTranslation } from "react-i18next";
import createFields from "./createFields";
import { useLocalStore } from "mobx-react";
import createForm from "utils/createForm";
import { Button, Grid } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import { useObserver } from "mobx-react-lite";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import Select from "components/MobxForm/Select";
import Switch from "components/MobxForm/Switch";
import { FREQUENCY_OPTIONS } from "const/pushDiagnosticsConst";
import AnimatedSubmitButton from "components/AnimatedSubmitButton/AnimatedSubmitButton";
import ConfirmationDialog from "../ConfirmationDialog";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";

const EditModal = ({ onClose, open }) => {
    const { t } = useTranslation();
    const {
        store: { supportInfoStore }
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields() }),
        inProgress: false,
        isSubmitted: false,
        get isIdDefault() {
            return this.form.$("serialNumber").isDefault && this.form.$("registrationId").isDefault;
        },
        get isScheduleDefault() {
            return this.form.$("diagnostic").isDefault && this.form.$("frequency").isDefault;
        }
    }));

    const { unsavedChangesModal, confirmationModal } = useModalCombine(["unsavedChangesModal", "confirmationModal"]);

    const onEnter = () => {
        (async () => {
            await supportInfoStore.fetchIdentity();
            await supportInfoStore.fetchSchedule();
            state.form.$("serialNumber").set("default", supportInfoStore.identity?.serialNumber);
            state.form.$("registrationId").set("default", supportInfoStore.identity?.registrationId);
            state.form.$("diagnostic").set("default", !!supportInfoStore.schedule?.sendReports);
            state.form.$("frequency").set("default", supportInfoStore.schedule?.frequency || FREQUENCY_OPTIONS.never);
            state.form.reset();

            state.isSubmitted = false;
            state.inProgress = false;
            confirmationModal.close();
            unsavedChangesModal.close();
        })();
    };

    const openConfirmationDialogIfNeeded = () => {
        if (
            (!state.form.$("serialNumber").isDefault && !supportInfoStore.identity?.serialNumber) ||
            (!state.form.$("registrationId").isDefault && !supportInfoStore.identity?.registrationId)
        ) {
            confirmationModal.open();
            return;
        }
        submit();
    };

    const submit = async () => {
        if (!state.isIdDefault && !state.isScheduleDefault) {
            state.inProgress = true;
            try {
                await supportInfoStore.setSchedule({
                    sendReports: state.form.$("diagnostic").value,
                    frequency: state.form.$("frequency").value
                });
                await supportInfoStore.setIdentity({
                    serialNumber: state.form.$("serialNumber").value,
                    registrationId: state.form.$("registrationId").value
                });
                state.inProgress = false;
                state.isSubmitted = true;
                setTimeout(onClose, 1000);
            } catch (e) {
                state.isSubmitted = false;
            }
            return;
        }
        if (!state.isIdDefault) {
            state.inProgress = true;
            try {
                await supportInfoStore.setIdentity({
                    serialNumber: state.form.$("serialNumber").value,
                    registrationId: state.form.$("registrationId").value
                });
                state.inProgress = false;
                state.isSubmitted = true;
                setTimeout(onClose, 1000);
            } catch (e) {
                state.isSubmitted = false;
            }
            return;
        }
        if (!state.isScheduleDefault) {
            state.inProgress = true;
            try {
                await supportInfoStore.setSchedule({
                    sendReports: state.form.$("diagnostic").value,
                    frequency: state.form.$("frequency").value
                });
                state.inProgress = false;
                state.isSubmitted = true;
                setTimeout(onClose, 1000);
            } catch (e) {
                state.isSubmitted = false;
            }
            return;
        }
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            title={t("support.push_diagnostics.edit_push_diagnostics.title")}
            open={open}
            onEnter={onEnter}
            onClose={closeIfNeeded}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary">
                    {t("common.button.cancel")}
                </Button>
            }
            submitBtn={
                <AnimatedSubmitButton
                    submit={openConfirmationDialogIfNeeded}
                    inProgress={state.inProgress}
                    isSubmitted={state.isSubmitted}
                    disabled={state.inProgress || state.isSubmitted}
                    label={t("common.button.save")}
                />
            }
        >
            <Grid container direction={"column"}>
                {(!supportInfoStore.identity?.serialNumber || !supportInfoStore.identity?.registrationId) && (
                    <>
                        <RowTextInput
                            label={t("support.push_diagnostics.control.serial_number")}
                            control={
                                <TextInput
                                    disabled={!!supportInfoStore.identity?.serialNumber}
                                    nonEditable={supportInfoStore.identity?.serialNumber}
                                    field={state.form.$("serialNumber")}
                                />
                            }
                        />
                        <RowTextInput
                            label={t("support.push_diagnostics.control.registration_id")}
                            control={
                                <TextInput
                                    disabled={!!supportInfoStore.identity?.registrationId}
                                    nonEditable={supportInfoStore.identity?.registrationId}
                                    field={state.form.$("registrationId")}
                                />
                            }
                        />
                    </>
                )}
                <RowControlLabelSwitch
                    control={<Switch field={state.form.$("diagnostic")} />}
                    label={t("support.push_diagnostics.control.diagnostics")}
                />
                <RowTextInput
                    label={t("support.push_diagnostics.control.frequency")}
                    control={
                        <Select
                            disabled={!state.form.$("diagnostic").value}
                            options={Object.values(FREQUENCY_OPTIONS)}
                            field={state.form.$("frequency")}
                        />
                    }
                />
                <ConfirmationDialog
                    open={confirmationModal.isOpen}
                    onClose={confirmationModal.close}
                    onConfirm={submit}
                    serialNumber={state.form.$("serialNumber").value}
                    registrationId={state.form.$("registrationId").value}
                />
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default EditModal;
