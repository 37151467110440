import React, { useContext, useMemo } from "react";
import DataCard from "components/DataCard";
import { Button, Grid, Typography } from "@material-ui/core";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { PING_TEST_CARD } from "const/widgetNameConst";
import { useTranslation } from "react-i18next";
import { Context } from "store";
import createFields from "./createFields";
import RowSwitch from "components/RowSwitch";
import Switch from "components/MobxForm/Switch";
import TextInput from "components/MobxForm/TextInput";
import createForm from "utils/createForm";
import hostNameRule from "utils/hostNameRule";
import { useLocalStore, useObserver } from "mobx-react";
import PingLastResultsDialog from "../PingLastResultsDialog";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import { useStyles } from "./PingTestCard.styles";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";

const PingTestCard = () => {
    const { t } = useTranslation();
    const {
        store: { pingStore }
    } = useContext(Context);
    const classes = useStyles();
    const form = useMemo(() => {
        const form = createForm({ fields: createFields(), rules: hostNameRule });
        form.$("custom_test").observe(({ form, field }) => {
            form.$("address").set("disabled", !field.checked);
        });
        form.reset();
        return form;
    }, []);
    const state = useLocalStore(() => ({
        isUpdating: false,
        isDialogOpen: false
    }));
    const runPingTest = async () => {
        state.isUpdating = true;
        await pingStore.runPingTest({
            standardTest: form.$("standard_test").checked,
            address: form.$("custom_test").checked ? form.$("address").value : ""
        });
        state.isUpdating = false;
    };
    const openDialog = () => {
        pingStore.getLastPingTestResult();
        state.isDialogOpen = true;
    };
    const closeDialog = () => {
        state.isDialogOpen = false;
    };
    return useObserver(() => (
        <DataCard
            title={t("network.ping_test.title")}
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={PING_TEST_CARD} />
                </MoreMenu>
            }
        >
            <Grid container spacing={4} direction={"column"}>
                <RowControlLabelSwitch
                    control={<Switch field={form.$("standard_test")} />}
                    label={t("network.ping_test.standard_test")}
                />
                <RowControlLabelSwitch
                    control={<Switch field={form.$("custom_test")} />}
                    label={t("network.ping_test.custom_test")}
                />
                <RowSwitch control={<TextInput field={form.$("address")} />} label={t("network.ping_test.custom_address")} />
            </Grid>
            <Grid item container spacing={4}>
                <Grid item>
                    <AnimatedSubmitButton
                        disabled={!form.$("custom_test").checked && !form.$("standard_test").checked}
                        inProgress={state.isUpdating}
                        submit={runPingTest}
                        label={t("common.button.start")}
                    />
                </Grid>
                <Grid item className={classes.lastResultBtnContainer}>
                    {state.isUpdating ? (
                        <Typography>{t("network.ping_test.in_progress")}</Typography>
                    ) : (
                        <Button onClick={openDialog} variant={"contained"} color={"secondary"}>
                            {t("common.button.last_results")}
                        </Button>
                    )}
                </Grid>
            </Grid>
            <PingLastResultsDialog onClose={closeDialog} open={state.isDialogOpen} />
        </DataCard>
    ));
};

export default PingTestCard;
