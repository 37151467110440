import * as React from "react";

const ClusterVolumeIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="ekgnwugl2b" x1="43.708%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A770FF" />
                    <stop offset="100%" stopColor="#85ADFF" />
                </linearGradient>
                <linearGradient id="5eho088yca" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#E19EFF" stopOpacity=".771" />
                    <stop offset="100%" stopColor="#293CFF" stopOpacity=".6" />
                </linearGradient>
                <linearGradient id="w33fyrivoc" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <path
                                fill="url(#ekgnwugl2b)"
                                stroke="url(#5eho088yca)"
                                d="M10.5.512V11.35c3.953 1.446 7.398 2.556 10.336 3.333-.725 1.935-2.005 3.6-3.646 4.8C15.454 20.75 13.315 21.5 11 21.5c-2.9 0-5.524-1.175-7.425-3.075C1.675 16.525.5 13.899.5 11c0-2.9 1.175-5.524 3.075-7.425C5.365 1.785 7.8.638 10.5.512h0z"
                                transform="translate(-221.000000, -1175.000000) translate(221.000000, 1175.000000) translate(1.000000, 1.000000)"
                            />
                            <path
                                fill="#85C3FF"
                                stroke="url(#w33fyrivoc)"
                                d="M12.6.595c3.003.588 5.312 2.015 6.918 4.297 1.603 2.277 2.216 4.867 1.851 7.769h0L12.6 9.82z"
                                transform="translate(-221.000000, -1175.000000) translate(221.000000, 1175.000000) translate(1.000000, 1.000000)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ClusterVolumeIcon;
