import i18n from "i18n";
import isFieldEmpty from "utils/isFieldEmptyRule";

const createFields = ({ httpPort, httpsPort, sslEnabled }) => {
    return [
        {
            name: "httpPort",
            default: httpPort.toString(),
            rules: "portValue",
            validators: [isFieldEmpty(i18n.t("rule.field.empty.common_field"))],
            output: value => (value.trim && value.trim() === "" ? value : Number(value))
        },
        {
            name: "sslEnabled",
            default: sslEnabled,
            type: "checkbox"
        },
        {
            name: "httpsPort",
            default: httpsPort.toString(),
            rules: "portValue",
            validators: [isFieldEmpty(i18n.t("rule.field.empty.common_field"))],
            output: value => (value.trim && value.trim() === "" ? value : Number(value))
        }
    ];
};

export default createFields;
