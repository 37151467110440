import { types } from "mobx-state-tree";

import RaidController from "api/evo_info/Types/RaidController";

const RaidControllersResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(RaidController)
});

export default RaidControllersResult;
