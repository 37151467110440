import { types } from "mobx-state-tree";

import GetBricksPathsResultData from "api/cluster/Types/GetBricksPathsResultData";

const GetBricksPathsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(GetBricksPathsResultData)
});

export default GetBricksPathsResult;
