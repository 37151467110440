import React, { useEffect, Fragment, createContext, useContext, useState } from "react";
import { ThemeProvider } from "@material-ui/core";
import { DARK_MODE_THEME, LIGHT_MODE_THEME } from "const";
import muiLightTheme from "./muiLightTheme";
import muiDarkTheme from "./muiDarkTheme";

const STORAGE_KEY = "userTheme";

export const ThemeContext = createContext({});

export const useThemeContext = () => useContext(ThemeContext);

const WrapperThemeContext = ({ children }) => {
    const [theme, setTheme] = useState(DARK_MODE_THEME);

    useEffect(() => {
        const valueStorageTheme = localStorage.getItem(STORAGE_KEY);

        if (valueStorageTheme) {
            const preTheme = JSON.parse(valueStorageTheme);
            setTheme(preTheme);
        }
    }, []);

    return (
        <Fragment>
            <ThemeContext.Provider value={{ theme, setTheme }}>
                <ThemeProvider theme={theme === LIGHT_MODE_THEME ? muiLightTheme : muiDarkTheme}>{children}</ThemeProvider>
            </ThemeContext.Provider>
        </Fragment>
    );
};

export const useDarkTheme = () => {
    const { theme, setTheme } = useThemeContext();

    const handleToggle = () => {
        setTheme((prevPreTheme) => (prevPreTheme === LIGHT_MODE_THEME ? DARK_MODE_THEME : LIGHT_MODE_THEME));
    };

    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(theme));
    }, [theme]);

    return { isSwitched: theme === DARK_MODE_THEME, toggle: handleToggle };
};

export default WrapperThemeContext;
