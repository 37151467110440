import { types } from "mobx-state-tree";

const DriveStatus = types.model({
    drive: types.number,
    status: types.string,
    driveType: types.string,
    driveModel: types.string,
    driveSerial: types.string,
    active: types.boolean,
});

export default DriveStatus;
