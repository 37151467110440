import { types } from "mobx-state-tree";

import SystemComponentVersion from "api/system_components/Types/SystemComponentVersion";

const GetSystemComponentsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(SystemComponentVersion)
});

export default GetSystemComponentsResult;
