import React, { useEffect } from "react";
import { observer, useObserver, useLocalStore } from "mobx-react";
import { Grid, Typography, InputAdornment, ButtonBase, FormHelperText } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import UpgradeIcon from "assets/UpgradeIcon";
import DragAndDropFile from "components/DragAndDropFile";
import FileProgressUpload from "components/FileProgressUpload";
import InputErrorIcon from "assets/InputErrorIcon";
import clsx from "clsx";
import { useStyles } from "./FileUpload.styles";
import { useToggle } from "hooks/useToggle";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import CloseIcon from "assets/CloseIcon";

const FileUpload = ({
    icon: Icon,
    file,
    id,
    disabled,
    loading,
    handleFile,
    removeFile,
    regExpExtension = /\.[0-9a-z]+$/i,
    inputProps,
    title,
    sizeLimit,
    noEmpty,
    needShowEmptyError,
    onValid,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [showErrorText, toggleShowErrorText] = useToggle();

    const state = useLocalStore(() => ({
        file: null,
        errors: {
            isEmptyError: false,
            isSizeError: false,
            isExtensionMatchError: false,
        },
        handleExtension(isMatch) {
            this.errors.isExtensionMatchError = !isMatch;
        },
    }));

    const handleValid = () => {
        const isValid = !state.errors.isSizeError && !state.errors.isEmptyError && !state.errors.isExtensionMatchError;
        const errors = Object.keys(state.errors).map((name) => ({ name: name, value: state.errors[name] }));
        onValid({ isValid: isValid, errors: errors });
    };

    useEffect(() => {
        if (!file) state.errors.isEmptyError = true;
        handleValid();
    }, [file?.name]);

    const getErrorMessage = () => {
        if (state.errors.isExtensionMatchError) return t("rule.file_name");
        if (state.errors.isSizeError) return t("rule.file_size", { sizeString: sizeLimit[1] });
        return t("rule.file_name");
    };
    const handleFiles = async (files) => {
        file = files[0];

        const isMatch = Boolean(file?.name.match(regExpExtension));
        state.handleExtension(isMatch);

        if (file.size && sizeLimit && file.size > sizeLimit[0]) {
            state.errors.isSizeError = true;
        }
        state.errors.isEmptyError = false;
        await handleFile(file);
    };

    const remove = () => {
        if (disabled) return;

        state.errors.isSizeError = false;
        state.errors.isExtensionMatchError = false;
        if (noEmpty) {
            state.errors.isEmptyError = true;
        }
        removeFile();
    };

    return useObserver(() => (
        <Grid container className={clsx(classes.contentContainer, disabled && classes.disabled)}>
            {loading && <FileProgressUpload />}
            {file && !loading && (
                <Grid
                    container
                    wrap={"nowrap"}
                    alignItems={"center"}
                    className={clsx(
                        classes.fileContainer,
                        (state.errors.isSizeError || state.errors.isExtensionMatchError) &&
                            !disabled &&
                            classes.fileContainerError
                    )}
                >
                    <Grid item className={classes.icon}>
                        {Icon ? <Icon /> : <UpgradeIcon />}
                    </Grid>
                    <Grid item container justify={"flex-start"} alignItems={"center"} className={classes.text}>
                        <Typography className={classes.text}>{file.name}</Typography>
                    </Grid>
                    {removeFile && (
                        <CardHeaderControlButton isContainer={false} onClick={remove} disabled={disabled}>
                            <ButtonBase disabled={disabled}>
                                <CloseIcon />
                            </ButtonBase>
                        </CardHeaderControlButton>
                    )}
                    {(state.errors.isSizeError || state.errors.isExtensionMatchError) && !disabled && (
                        <React.Fragment>
                            <InputAdornment position={"end"}>
                                <ButtonBase onClick={toggleShowErrorText}>
                                    <InputErrorIcon />
                                </ButtonBase>
                            </InputAdornment>
                            <FormHelperText className={clsx(!showErrorText && classes.helperTextHidden, classes.helperText)}>
                                {getErrorMessage()}
                            </FormHelperText>
                        </React.Fragment>
                    )}
                </Grid>
            )}
            {!file && !loading && (
                <Grid item container>
                    <DragAndDropFile
                        isEmptyError={needShowEmptyError && state.errors.isEmptyError}
                        handleFiles={handleFiles}
                        title={title}
                        inputProps={inputProps}
                        id={id}
                        disabled={disabled}
                    />
                </Grid>
            )}
        </Grid>
    ));
};

export default observer(FileUpload);
