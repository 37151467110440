import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Link, Grid } from "@material-ui/core";
import Select from "components/Select";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import { useStyles } from "./PluginInstallDialog.styles";
import { useTranslation, Trans } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import { DETAILS, LICENSE, INSTALLING, FAILED, SUCCEED } from "const/installPluginConst";
import Dialog from "components/Dialog";
import FileUploadDialog from "components/FileUploadDialog";
import { useStore } from "hooks/useStore";
const UPGRADE_SYSTEM_DESTINATION = "lun";

const PluginInstallDialog = ({ open, handleClose }) => {
    const {
        store: { pluginsStore, shareStore, uploadStore },
    } = useStore();

    const classes = useStyles();
    const { t } = useTranslation();
    const state = useLocalStore(() => ({
        dataLocation: "",
        dataLocations: [],
        backupLocation: "",
        backupLocations: [],
        file: null,
        isUploadDialogOpen: false,
        uploading: false,
        selectedFileName: "",
        uploadedFileName: null,
        availableArchives: [],
        sharesLoaded: false,
    }));

    const [onArchivesLoaded, setArchivesLoaded] = useState(0);
    const [onArchiveUnpacked, setArchiveUnpacked] = useState(0);
    const [onSharesLoaded, setSharesLoaded] = useState(0);

    const onSubmit = async (file) => {
        const uploadedFile = await uploadStore.upload(file, UPGRADE_SYSTEM_DESTINATION);

        if (uploadedFile) {
            closeUploadDialog(uploadedFile);
        }

        return uploadedFile;
    };

    const onClose = () => {
        handleClose();
        if (pluginsStore.installationStep !== INSTALLING) {
            pluginsStore.gotoSelectArchiveStep();
            state.selectedFileName = "";
            state.dataLocation = "";
            state.backupLocation = "";
        }
    };

    const onFileNameChanged = (event) => {
        state.selectedFileName = event.target.value;
    };

    const onDataLocationChanged = (event) => {
        state.dataLocation = event.target.value;
    };
    const onBackupLocationChanged = (event) => {
        state.backupLocation = event.target.value;
    };

    const onUnpackArchive = () => {
        pluginsStore.unpackArchive(state.selectedFileName, () => {
            setArchiveUnpacked(onArchiveUnpacked + 1);
        });
    };

    const onShowLicense = () => {
        pluginsStore.gotoShowLicenseStep();
    };

    const onBackToSelectArchive = () => {
        pluginsStore.gotoSelectArchiveStep();
    };

    const onDecline = () => {
        onCancel();
    };

    const onCancel = () => {
        onClose();
    };

    const onInstall = () => {
        const metadata = pluginsStore.unpackedMetadata;
        pluginsStore.installPlugin(
            metadata.pluginId,
            metadata.hasDataStore ? state.dataLocation : "",
            metadata.hasBackupsCapability ? state.backupLocation : ""
        );
    };

    const openUploadDialog = () => {
        state.isUploadDialogOpen = true;
    };

    const closeUploadDialog = async (uploadedFile) => {
        state.isUploadDialogOpen = false;
        if (uploadedFile) {
            state.uploadedFileName = uploadedFile.name;
            fetchAvailableArchives();
        }
    };

    const selectArchivePage = () => {
        const archives = state.availableArchives;
        const pageBody =
            archives && archives.length > 0 ? (
                <>
                    {pluginsStore.installationStep === FAILED && (
                        <Box className={classes.error} spacing={8}>
                            <Typography>
                                {t("support.start.plugin_install.failed", { reason: pluginsStore.installationFailureReason })}
                            </Typography>
                        </Box>
                    )}
                    <Typography component="div">
                        <Trans
                            i18nKey={"support.start.plugin_install.select_archive"}
                            components={[
                                <Select
                                    key={`plugin_install-select-1`}
                                    value={state.selectedFileName}
                                    options={archives}
                                    onChange={onFileNameChanged}
                                    className={classes.select}
                                    disabled={pluginsStore.isInstalling || pluginsStore.unpackedMetadata !== null}
                                />,
                                <Link
                                    key={`plugin_install-link-1`}
                                    to={"#"}
                                    className={classes.link}
                                    onClick={openUploadDialog}
                                    disabled={pluginsStore.isInstalling || state.isUploadDialogOpen}
                                />,
                            ]}
                        />
                    </Typography>
                </>
            ) : (
                <Typography>
                    <Trans
                        i18nKey={"support.start.plugin_install.no_archives"}
                        components={
                            <Link
                                to={"#"}
                                className={classes.link}
                                onClick={openUploadDialog}
                                disabled={pluginsStore.isInstalling || state.isUploadDialogOpen}
                            />
                        }
                    />
                </Typography>
            );

        const submitButton = (
            <AnimatedSubmitButton
                key={"unpackBtn"}
                label={t("support.start.plugin_install.button.next")}
                submit={onUnpackArchive}
                isSubmitted={pluginsStore.unpackedMetadata !== null}
                disabled={state.selectedFileName === "" || state.isUploadDialogOpen}
            />
        );

        const buttons = [
            <Button key={"cancelBtn"} onClick={onCancel} variant={"contained"} color="secondary">
                {t("support.start.plugin_install.button.cancel")}
            </Button>,
        ];

        return { submitButton, buttons, pageBody, title: t("support.start.plugin_install.select_page_title") };
    };

    const detailsPage = () => {
        const metadata = pluginsStore.unpackedMetadata;
        if (metadata === null) return null;

        const pageBody = (
            <>
                <Grid container direction={"column"} wrap={"nowrap"} className={classes.detailsContainer} spacing={4}>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("support.start.installed_plugins.details.label.name")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>{metadata.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"}>
                        <Grid item xs={6}>
                            <Typography>{t("support.start.installed_plugins.details.label.version")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>{metadata.pluginVersion}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"}>
                        <Grid item xs={6}>
                            <Typography>{t("support.start.installed_plugins.details.label.description")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>{metadata.description}</Typography>
                        </Grid>
                    </Grid>
                    {metadata.hasDataStore && (
                        <Grid item container alignItems={"baseline"}>
                            <Grid item xs={6}>
                                <Typography>{t("support.start.installed_plugins.details.label.data_location")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {(state.dataLocations && state.dataLocations.length > 0) || !state.sharesLoaded ? (
                                    <Select
                                        value={state.dataLocation}
                                        options={state.dataLocations}
                                        onChange={onDataLocationChanged}
                                        disabled={!state.sharesLoaded}
                                        className={classes.select}
                                    />
                                ) : (
                                    <Typography component="div">
                                        <Trans
                                            i18nKey={"support.start.installed_plugins.details.no_data_locations"}
                                            components={<Link href={"/#/shares"} className={classes.link} />}
                                        />
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    {metadata.hasBackupsCapability && (
                        <Grid item container alignItems={"baseline"}>
                            <Grid item xs={6}>
                                <Typography>{t("support.start.installed_plugins.details.label.backup_location")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {(state.backupLocations && state.backupLocations.length > 0) || !state.sharesLoaded ? (
                                    <Select
                                        value={state.backupLocation}
                                        options={state.backupLocations}
                                        onChange={onBackupLocationChanged}
                                        className={classes.select}
                                    />
                                ) : (
                                    <Typography component="div">
                                        <Trans
                                            i18nKey={"support.start.installed_plugins.details.no_backup_locations"}
                                            components={<Link href={"/#/shares"} className={classes.link} />}
                                        />
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </>
        );

        const submitButton = (
            <AnimatedSubmitButton
                key={"nextBtn"}
                label={t("support.start.plugin_install.button.next")}
                submit={onShowLicense}
                isSubmitted={false}
                disabled={
                    pluginsStore.unpackedMetadata === null ||
                    (metadata.hasDataStore && state.dataLocation === "") ||
                    (metadata.hasBackupsCapability && state.backupLocation === "")
                }
            />
        );

        const buttons = [
            <Button key={"backBtn"} onClick={onBackToSelectArchive} variant={"contained"} color="secondary">
                {t("support.start.plugin_install.button.back")}
            </Button>,
        ];
        return { submitButton, buttons, pageBody, title: t("support.start.plugin_install.details_page_title") };
    };

    const licensePage = () => {
        const plugin = pluginsStore.unpackedMetadata;
        const pageBody = (
            <React.Fragment>
                <Typography className={classes.contentTitle} variant={"h2"}>
                    {t("support.start.plugin_install.license_agreement")}
                </Typography>
                {plugin ? <Typography className={classes.license} dangerouslySetInnerHTML={{ __html: plugin.eula }} /> : null}
            </React.Fragment>
        );

        const submitButton = (
            <AnimatedSubmitButton
                key={"acceptBtn"}
                label={t("support.start.plugin_install.button.accept")}
                submit={onInstall}
                isSubmitted={pluginsStore.installationStep === INSTALLING}
                disabled={pluginsStore.unpackedMetadata === null}
            />
        );

        const buttons = [
            <Button
                key={"declineBtn"}
                onClick={onDecline}
                disabled={pluginsStore.installationStep === INSTALLING}
                variant={"contained"}
                color="secondary"
            >
                {t("support.start.plugin_install.button.decline")}
            </Button>,
        ];
        return { submitButton, buttons, pageBody, title: t("support.start.plugin_install.license_page_title") };
    };

    useEffect(() => {
        const step = pluginsStore.installationStep;
        if (step === SUCCEED || step === FAILED) {
            onClose();
            pluginsStore.gotoSelectArchiveStep();
        }
    }, [pluginsStore.installationStep]);

    useEffect(() => {
        const archives = pluginsStore.availableArchives;

        state.availableArchives = archives && archives.map((arc) => arc.archiveName).sort();
        if (state.availableArchives && state.availableArchives.length === 1) {
            state.selectedFileName = state.availableArchives[0];
        } else if (state.uploadedFileName) {
            const found = state.availableArchives.find((archiveName) => archiveName === state.uploadedFileName);
            state.selectedFileName = found || "";
        }
        state.uploadedFileName = null;
    }, [onArchivesLoaded]);

    useEffect(() => {
        const metadata = pluginsStore.unpackedMetadata;
        if (metadata && (metadata.hasDataStore || metadata.hasBackupsCapability)) {
            state.sharesLoaded = false;
            const fetchShares = async () => {
                await shareStore.fetchSharesTable();
                setSharesLoaded(onSharesLoaded + 1);
            };
            fetchShares();
        }
    }, [onArchiveUnpacked]);

    useEffect(() => {
        const shareNames =
            shareStore.internalShares && shareStore.internalShares.map((share) => share.attributes.shareName).sort();

        state.dataLocations = shareNames;
        if (state.dataLocations && state.dataLocations.length === 1) {
            state.dataLocation = state.dataLocations[0];
        }

        state.backupLocations = shareNames;
        if (state.backupLocations && state.backupLocations.length === 1) {
            state.backupLocation = state.backupLocations[0];
        }
        state.sharesLoaded = true;
    }, [onSharesLoaded]);

    const fetchAvailableArchives = () => {
        pluginsStore.fetchAvailableArchives(() => {
            setArchivesLoaded(onArchivesLoaded + 1);
        });
    };

    useEffect(() => {
        if (open) {
            fetchAvailableArchives();
        }
    }, [open]);

    return useObserver(() => {
        if (!open) {
            return null;
        }
        const step = pluginsStore.installationStep;
        const page =
            INSTALLING === step
                ? licensePage()
                : LICENSE === step
                ? licensePage()
                : DETAILS === step
                ? detailsPage()
                : selectArchivePage();

        return (
            page && (
                <>
                    <Dialog title={page.title} buttons={page.buttons} open={open} onClose={onClose} submitBtn={page.submitButton}>
                        {page.pageBody}
                    </Dialog>
                    <FileUploadDialog
                        onClose={closeUploadDialog}
                        open={state.isUploadDialogOpen}
                        onSubmit={onSubmit}
                        regExpExtension={/\.gpg/}
                    />
                </>
            )
        );
    });
};

export default PluginInstallDialog;
