import { makeStyles } from "@material-ui/core/styles";
import { FOOTER_FONT } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    footer: {
        position: "relative",
        padding: theme.spacing(8, 0),
        "&& a": {
            color: theme.palette.grey[500]
        }
    },
    font: FOOTER_FONT
}));
