import React from "react";

function FolderIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="evenodd"
                d="M19.5 7.05h-7L9.87 3.87a.999.999 0 00-.77-.37H4.5A2.47 2.47 0 002 5.93v12.14a2.47 2.47 0 002.5 2.43h15a2.47 2.47 0 002.5-2.43V9.48a2.47 2.47 0 00-2.5-2.43zm.5 11a.46.46 0 01-.487.431l-.013-.001h-15a.46.46 0 01-.499-.417L4 18.05V5.93a.46.46 0 01.487-.431L4.5 5.5h4.13l2.6 3.18c.188.232.47.368.77.37h7.5a.46.46 0 01.499.417L20 9.48v8.57z"
            />
        </svg>
    );
}

export default FolderIcon;
