import { types } from "mobx-state-tree";

import Request from "api/Request";
import PerformJobCommandArguments from "api/slingshot/sync_jobs/Types/PerformJobCommandArguments";

const PerformJobCommand = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/SyncJobs1",
    method: "perform_job_command",
    arguments: types.maybe(
        types.snapshotProcessor(PerformJobCommandArguments, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json)
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default PerformJobCommand;
