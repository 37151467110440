import React, { useRef, Fragment, useContext } from "react";
import FolderIcon from "assets/FolderIcon";
import DocIcon from "assets/DocIcon";
import { equalsIgnoreCase } from "utils/equalsIgnoreCase";
import FilesRow from "../FilesRow/FilesRow";
import { observer } from "mobx-react";
import { CircularProgress, Grid, TableBody, TableCell, TableRow } from "@material-ui/core";
import { Context } from "store";
import { useStyles } from "./FilesTableBody.styles";

const FilesTableBody = ({ openFolder, trashFiles, classes }) => {
    const classesLocal = useStyles();
    const {
        store: { recycleBinStore },
    } = useContext(Context);
    const renderTypeIcon = useRef((type, name) => {
        if (equalsIgnoreCase(type, "directory")) {
            return <FolderIcon className={classes.fileIcon} onClick={openFolder(name)} />;
        } else if (equalsIgnoreCase(type, "file")) {
            return <DocIcon className={classes.fileIcon} />;
        }
        return <DocIcon />;
    });

    if (recycleBinStore.isLoading)
        return (
            <TableBody>
                <TableRow className={classesLocal.row}>
                    <TableCell colSpan={25} rowSpan={6}>
                        <Grid container justifyContent="center" className={classesLocal.container}>
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            </TableBody>
        );

    return (
        <TableBody>
            {trashFiles?.map((file) => (
                <Fragment key={file.name}>
                    <FilesRow file={file} renderTypeIcon={renderTypeIcon.current} />
                </Fragment>
            ))}
        </TableBody>
    );
};

export default observer(FilesTableBody);
