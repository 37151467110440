import React, { useEffect } from "react";
import DataCard from "components/DataCard";
import { Grid, Table, TableHead, TableBody, TableRow, TableCell, Typography, Box } from "@material-ui/core";
import { useLocalStore, useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import Select from "components/Select";
import StatusLabel from "components/StatusLabel";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import { useStyles } from "./AffectedPoolsCard.styles";
import clsx from "clsx";
import { useStore } from "hooks/useStore";
import { checkDefaultStatus, toUiStatus } from "./AffectedPoolsCard.utils";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { toJS } from "mobx";

const AffectedPoolsCard = ({ selectedPool }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { driveReplaceStore }
    } = useStore();
    const state = useLocalStore(() => ({
        selectedPool: null,
        isLoading: false,
        selectedReplacementDrives: [],
        get titleOptions() {
            return driveReplaceStore.affectedPools.pools?.map(pool => pool.poolName);
        },
        onChangeDrive(e, index) {
            this.selectedPool.drives[index].replacementDrive = e.target.value;
        }
    }));
    useEffect(() => {
        state.selectedPool = { ...toJS(driveReplaceStore.affectedPools?.pools[0]) };
        driveReplaceStore.onReplaceComplete(() => {
            state.selectedPool = null;
            state.selectedReplacementDrives.fill(null);
        });
        state.selectedPool.drives.map(drive => {
            return { ...drive, replacementDrive: null };
        });
        fillReplacementDrives();
    }, [selectedPool]);

    const toUiDriveId = driveId => {
        return t("troubleshooting.drive_title", { quad: driveId.quad, drive: driveId.drive });
    };

    const fillReplacementDrives = () => {
        const freeDrives = driveReplaceStore.affectedPools?.freeDrives || [];
        state.selectedPool.drives.forEach(drive => {
            if (drive.status !== "online") {
                drive.replacementDrive =
                    JSON.stringify(
                        freeDrives.find(freeDrive => freeDrive.quad === drive.drive.quad && freeDrive.drive === drive.drive.drive)
                    ) ||
                    JSON.stringify(
                        freeDrives.find(
                            freeDrive =>
                                !state.selectedPool?.drives.find(
                                    drive => drive.drive.quad === freeDrive.quad && drive.drive.drive === freeDrive.drive
                                )
                        )
                    );
            }
        });
    };

    const renderTitle = () => {
        return (
            <Grid container wrap={"nowrap"} alignItems={"center"} spacing={4}>
                <Grid item>
                    <Typography variant={"h2"}>{t("troubleshooting.affected_pools_table.title")}</Typography>
                </Grid>
                <Grid item>
                    {state.selectedPool && (
                        <>
                            <Select
                                className={clsx(classes.select, classes.selectTitle)}
                                options={state.titleOptions}
                                value={state.selectedPool && state.selectedPool.poolName}
                                onChange={onPoolChange}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        );
    };
    const onPoolChange = e => {
        state.selectedPool = driveReplaceStore.affectedPools.pools?.find(p => p.poolName === e.target.value);
    };

    const replaceDrives = () => {
        let drives = [];
        state.selectedPool.drives.forEach((driveId, index) => {
            driveId.replacementDrive && drives.push({ drive: JSON.parse(driveId.replacementDrive), index: index });
        });
        if (!drives.length) {
            return;
        }
        driveReplaceStore.repairPool({
            poolName: state.selectedPool.poolName,
            drives: drives
        });
    };

    const drivesOptions = driveStatus => {
        const freeDrives = driveReplaceStore.affectedPools?.freeDrives || [];
        const selectedPool = driveReplaceStore.affectedPools?.pools[0];
        const sameDriveIndex = freeDrives?.findIndex(
            drive => !!drive && drive.quad === driveStatus.quad && drive.drive === driveStatus.drive
        );
        if (sameDriveIndex >= 0) {
            return [{ label: toUiDriveId(freeDrives[sameDriveIndex]), value: JSON.stringify(freeDrives[sameDriveIndex]) }];
        }
        const outerFreeDrives = freeDrives.filter(
            freeDrive =>
                !selectedPool?.drives.find(drive => drive.drive.quad === freeDrive.quad && drive.drive.drive === freeDrive.drive)
        );
        return outerFreeDrives.map(driveId => {
            return { label: toUiDriveId(driveId), value: JSON.stringify(driveId) };
        });
    };

    return useObserver(() => (
        <>
            <DataCard title={renderTitle()} headerDivider autoHeight emptyPadding>
                {driveReplaceStore.replaceState.status !== "replace" ? (
                    <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant={"subtitle1"}>
                                            {t("troubleshooting.affected_pools_table.index")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle1"}>
                                            {t("troubleshooting.affected_pools_table.drive_used")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle1"}>
                                            {t("troubleshooting.affected_pools_table.replacement_drive")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle1"}>
                                            {t("troubleshooting.affected_pools_table.state")}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.selectedPool?.drives.map((driveStatus, index) => (
                                    <TableRow
                                        key={state.selectedPool.poolName + driveStatus.drive.drive + driveStatus.drive.quad}
                                    >
                                        <TableCell>{index}</TableCell>
                                        <TableCell>{toUiDriveId(driveStatus.drive)}</TableCell>
                                        <TableCell>
                                            {driveStatus.status !== "online" &&
                                                !!driveReplaceStore.affectedPools.freeDrives.length && (
                                                    <Grid container>
                                                        <Grid item className={classes.selectContainer}>
                                                            <Select
                                                                className={clsx(classes.select, classes.selectTable)}
                                                                options={drivesOptions(driveStatus.drive)}
                                                                value={driveStatus.replacementDrive}
                                                                onChange={e => state.onChangeDrive(e, index)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                        </TableCell>
                                        <TableCell>
                                            <StatusLabel
                                                text={checkDefaultStatus(driveStatus.status)}
                                                status={toUiStatus(driveStatus.status)}
                                                justify={"flex-start"}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Box pt={4} px={6} pb={6}>
                            <Grid container direction={"row"} alignItems={"center"} spacing={6}>
                                <Grid item>
                                    <AnimatedSubmitButton
                                        label={t("troubleshooting.affected_pools.commit")}
                                        submit={replaceDrives}
                                        disabled={
                                            driveReplaceStore.replaceState.status !== "idle" ||
                                            driveReplaceStore.fetchingAffectedPools
                                        }
                                        inProgress={driveReplaceStore.replaceState.status === "replace"}
                                    />
                                </Grid>
                                <Grid item>
                                    {driveReplaceStore.replaceState.lastError && (
                                        <StatusLabel
                                            text={driveReplaceStore.replaceState.lastError}
                                            status={"error"}
                                            justify={"flex-start"}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                ) : (
                    <EmptyCardPlaceholder
                        inProgress
                        inProgressMessage={t("troubleshooting.affected_pools.commit_progress_message")}
                    />
                )}
            </DataCard>
        </>
    ));
};

export default AffectedPoolsCard;
