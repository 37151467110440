import React, { useEffect } from "react";
import { Box, Divider, Paper, Table, TableBody, TableCell, TableHead, TableRow, Grid, Typography } from "@material-ui/core";
import TablePagination from "components/TablePagination";
import { useStyles } from "./UsersTable.styles";
import { observer, useLocalStore } from "mobx-react";
import LocalGroupIcon from "assets/LocalGroupIcon";
import LocalUserIcon from "assets/LocalUserIcon";
import ExternalGroupIcon from "assets/ExternalGroupIcon";
import TableCellWithIcon from "components/TableCellWithIcon";
import CardHeader from "components/CardHeader";
import EmptyTablePlaceholder from "../EmptyTablePlaceholder";
import { useTranslation } from "react-i18next";
import { SEARCH_DEFAULTS, LOCAL_USERS } from "const/userSearchVariants";
import Checkbox from "components/Checkbox";
import ChevronRightIcon from "assets/ChevronRightIcon";
import TableRowControlButton from "components/TableRowControlButton";
import { autorun, toJS } from "mobx";
import { useStore } from "hooks/useStore";

const USER_TABLE_ID_PREFIX = "user_table";

const UsersTable = ({ parentId }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const idPrefix = parentId ? `${parentId}_${USER_TABLE_ID_PREFIX}` : USER_TABLE_ID_PREFIX;

    const {
        store: { userStore },
    } = useStore();

    const state = useLocalStore(() => ({
        get mainCheckboxStatus() {
            if (userStore.searchResult.data.length === userStore.checkedUsers.length && userStore.checkedUsers.length > 0) {
                return "checked";
            } else if (0 < userStore.checkedUsers.length && userStore.checkedUsers.length < userStore.searchResult.data.length) {
                return "indeterminated";
            }
            return null;
        },
    }));

    const toggleDrawer = (entity) => () => {
        if (
            userStore.currentEntity &&
            entity.id.name === userStore.currentEntity.id.name &&
            entity.id.domain === userStore.currentEntity.id.domain
        ) {
            userStore.dropCurrentEntity();
        } else {
            const parsedEntity = Object.fromEntries(Object.entries(entity).map(([key, value]) => [key, toJS(value)]));
            userStore.checkedUsers.length === 0 && userStore.setCurrentEntity(parsedEntity);
        }
    };

    const handleChangePage = (newPage) => {
        userStore.updateCurrentSearchRequest("page", +newPage);
        userStore.searchOnUsersPage();
    };
    const handleChangeRowsPerPage = (e) => {
        userStore.updateCurrentSearchRequest("limit", +e.target.value);
        userStore.updateCurrentSearchRequest("page", SEARCH_DEFAULTS.page);
        userStore.searchOnUsersPage();
    };
    useEffect(() => {
        autorun(() => {
            if (!userStore.sortedSearchResult.length && userStore.currentSearchRequest.page > 0) {
                userStore.updateCurrentSearchRequest("page", userStore.currentSearchRequest.page - 1);
                userStore.searchOnUsersPage();
            }
        });
    }, []);

    const getType = (isExternal, isGroup) => {
        const getIcon = () => {
            if (isGroup) {
                return isExternal ? <ExternalGroupIcon /> : <LocalGroupIcon />;
            } else {
                return <LocalUserIcon />;
            }
        };
        const getLabel = () => {
            if (isGroup) {
                return isExternal ? t("users.common.external_group") : t("users.common.local_group");
            } else {
                return isExternal ? t("users.common.external_user") : t("users.common.local_user");
            }
        };

        return {
            typeIcon: getIcon(),
            typeLabel: getLabel(),
        };
    };

    const handleMainCheckbox = () => {
        switch (state.mainCheckboxStatus) {
            case "checked":
            case "indeterminated":
                userStore.clearCheckedUsers();
                break;
            default:
                userStore.searchResult.data.forEach((user) => userStore.addCheckedUser({ ...user.id }));
                break;
        }
    };

    const onCheck = (user) => () => {
        const userId = user.id;

        isUserChecked(userId) ? userStore.removeCheckedUser({ ...userId }) : userStore.addCheckedUser({ ...userId });

        if (userStore.checkedUsers.length === 1) {
            const curEntity = userStore.searchResult.data.find((user) => user.id.name === userStore.checkedUsers[0].name);
            userStore.setCurrentEntitySingleBar(curEntity);
        } else {
            userStore.dropCurrentEntitySingleBar();
        }
    };

    const isUserChecked = (userId) => {
        return userStore.checkedUsers.some((user) => user.name === userId.name && user.domain === userId.domain);
    };

    return (
        <Paper className={classes.cardContainer}>
            <CardHeader
                content={
                    <Grid container alignItems={"baseline"}>
                        <Typography component={"span"} variant={"h2"}>
                            {t("users.search_results.title", { count: userStore.searchResult.total })}
                        </Typography>
                    </Grid>
                }
            />
            <Divider />
            {userStore.searchResult.total > 0 ? (
                <Grid className={classes.tableContainer} container direction={"column"} justify={"space-between"}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {[LOCAL_USERS].includes(userStore.lastSearch?.type) && (
                                    <TableCell>
                                        <Checkbox
                                            indeterminate={state.mainCheckboxStatus === "indeterminated"}
                                            checked={state.mainCheckboxStatus === "checked"}
                                            onChange={handleMainCheckbox}
                                            id={`${idPrefix}_main_checkbox`}
                                        />
                                    </TableCell>
                                )}
                                <TableCell>{t("users.search_results.table.column.name")}</TableCell>
                                <TableCell>{t("users.search_results.table.column.type")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userStore.sortedSearchResult
                                ? userStore.sortedSearchResult?.map((entity) => {
                                      const { typeIcon, typeLabel } = getType(entity.isExternal, entity.isGroup);
                                      const name = entity.isExternal ? `${entity.id.name}@${entity.id.domain}` : entity.id.name;
                                      return (
                                          <TableRow
                                              selected={
                                                  userStore?.currentEntity?.id?.name === entity.id.name &&
                                                  userStore?.currentEntity?.id?.domain === entity.id.domain
                                              }
                                              key={`${name}_${typeLabel}`}
                                          >
                                              {[LOCAL_USERS].some((entity) => entity === userStore.lastSearch?.type) && (
                                                  <TableCell>
                                                      <Checkbox
                                                          checked={isUserChecked(entity.id)}
                                                          onChange={onCheck(entity)}
                                                          id={`${idPrefix}_${name}_checkbox`}
                                                      />
                                                  </TableCell>
                                              )}
                                              <TableCell onClick={toggleDrawer(entity)} className={classes.tableCell}>
                                                  {name}
                                              </TableCell>
                                              <TableCellWithIcon
                                                  onClick={toggleDrawer(entity)}
                                                  className={classes.tableCell}
                                                  align="left"
                                                  icon={typeIcon}
                                              >
                                                  {typeLabel}
                                              </TableCellWithIcon>
                                              <TableCell align={"right"}>
                                                  <TableRowControlButton
                                                      icon={<ChevronRightIcon />}
                                                      onClick={toggleDrawer(entity)}
                                                      id={`${idPrefix}_${name}_toggle_drawer`}
                                                  />
                                              </TableCell>
                                          </TableRow>
                                      );
                                  })
                                : null}
                        </TableBody>
                    </Table>
                    <Grid>
                        <Divider />
                        <Box className={classes.paginationContainer}>
                            <TablePagination
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                onChangePage={handleChangePage}
                                count={userStore.searchResult.total}
                                rowsPerPage={userStore.currentSearchRequest.limit}
                                page={userStore.currentSearchRequest.page}
                                parentId={idPrefix}
                            />
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <EmptyTablePlaceholder requestType={userStore.lastSearch && userStore.lastSearch?.type} />
            )}
        </Paper>
    );
};

export default observer(UsersTable);
