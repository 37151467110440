import { types } from "mobx-state-tree";
import CreateJobScheduleType from "./CreateJobScheduleType";
import DecomposedUrl from "./DecomposedUrl";

const METHOD_ENUM_ARRAY = ["Copy", "Sync"];
const INCLUDE_PRIORITY_ENUM_ARRAY = ["include", "exclude"];
const PRE_VERIFY_ENUM_ARRAY = ["SizeAndTime", "SizeAndHash"];
const LOG_LEVEL_ENUM_ARRAY = ["NO_LOGS", "STANDARD", "FULL"];

const EditableJobType = types.model("EditableJobType", {
    id: types.number,
    name: types.maybe(types.string),
    source: types.maybe(DecomposedUrl),
    destination: types.maybe(DecomposedUrl),
    target_folder: types.maybe(types.boolean),
    include_recycle_bin: types.maybe(types.boolean),
    method: types.maybe(types.enumeration(METHOD_ENUM_ARRAY)),
    schedule: types.maybe(
        types.snapshotProcessor(CreateJobScheduleType, {
            postProcessor(snapshot) {
                return snapshot?.schedule && { schedule: JSON.stringify(snapshot.schedule) };
            },
        })
    ),
    pause: types.maybe(
        types.model({
            enabled: types.maybe(types.boolean),
            schedule: types.maybe(
                types.snapshotProcessor(CreateJobScheduleType, {
                    postProcessor(snapshot) {
                        return snapshot?.schedule && { schedule: JSON.stringify(snapshot.schedule) };
                    },
                })
            ),
        })
    ),
    email_summary: types.maybe(types.boolean),
    parallel_processing: types.maybe(types.boolean),
    min_age: types.maybe(types.number),
    case_sensitive_name_mask: types.maybe(types.boolean),
    include_name_mask: types.maybe(types.string),
    exclude_name_mask: types.maybe(types.string),
    include_exclude_priority: types.maybe(types.enumeration(INCLUDE_PRIORITY_ENUM_ARRAY)),
    include_metadata: types.maybe(types.boolean),
    include_mediaproxy: types.maybe(types.boolean),
    copy_logs: types.maybe(types.boolean),
    remove_deleted_files_before_sync: types.maybe(types.boolean),
    logs_destination_folder: types.maybe(types.string),
    check_after_transfer: types.maybe(types.boolean),
    skip_newer: types.maybe(types.boolean),
    pre_verify: types.maybe(types.enumeration(PRE_VERIFY_ENUM_ARRAY)),
    post_verify: types.maybe(
        types.model({
            report_same: types.maybe(types.boolean),
            one_way: types.maybe(types.boolean),
        })
    ),
    log_level: types.maybe(types.enumeration(LOG_LEVEL_ENUM_ARRAY)),
    properties: types.maybe(
        types.model({
            emailOnStart: types.maybe(types.boolean),
            emailOnPauseResume: types.maybe(types.boolean),
        })
    ),
    deleted: types.maybe(types.boolean),
    enabled: types.maybe(types.boolean),
});

export default EditableJobType;
