import { types } from "mobx-state-tree";

import Peer from "api/cluster/Types/Peer";

const GetPeersResultData = types.model({
    arr: types.array(Peer),
    total: types.number
});

export default GetPeersResultData;
