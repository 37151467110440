import { types } from "mobx-state-tree";

import Request from "api/Request";
import EditPeerArguments from "api/cluster/Types/EditPeerArguments";

const EditPeer = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Cluster",
    method: "edit_peer",
    arguments: types.maybe(EditPeerArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default EditPeer
