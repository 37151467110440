import React from "react";
import { useStyles } from "components/DrawerHeader/DrawerHeader.styles";
import { Grid, Typography } from "@material-ui/core";
import CloseIcon from "assets/CloseIcon";
import clsx from "clsx";
import TableRowControlButton from "components/TableRowControlButton";

const DrawerHeader = ({ children, icon, closeDrawer, noWrap = true }) => {
    const classes = useStyles();
    return (
        <Grid className={classes.headerContainer} justify={"space-between"} container wrap={"nowrap"}>
            <Grid item className={classes.headerContainerInner}>
                <Grid container spacing={4} alignItems={"center"} wrap={"nowrap"}>
                    {icon && (
                        <Grid item>
                            <Grid container alignItems={"center"}>
                                {icon}
                            </Grid>
                        </Grid>
                    )}
                    <Grid item className={clsx(icon ? classes.headerInfoContainerIcon : classes.headerInfoContainer)}>
                        <Typography variant={"h2"} noWrap={noWrap}>
                            {children}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <TableRowControlButton
                    className={classes.headerButton}
                    onClick={closeDrawer}
                    icon={<CloseIcon className={classes.headerCloseButton} />}
                />
            </Grid>
        </Grid>
    );
};

export default DrawerHeader;
