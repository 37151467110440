import { makeStyles } from "@material-ui/core/styles";
import { NOTISTACK_WIDTH } from "const/styleConst";
export const useStyles = makeStyles(theme => ({
    notistack: {
        "& .MuiPaper-root": {
            width: NOTISTACK_WIDTH,
            maxWidth: NOTISTACK_WIDTH,
            padding: theme.spacing(0, 2),
            "& .MuiSnackbarContent-message": {
                width: "100%",
                wordBreak: "break-word"
            }
        },
        "& span": {
            whiteSpace: "pre-line",
            flexWrap: "noWrap",
            color: theme.palette.common.white,
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.25,
            letterSpacing: 0.2,
            "& svg": {
                alignSelf: "flex-start",
                flexShrink: 0,
                marginRight: theme.spacing(2)
            }
        }
    },
    error: {
        backgroundColor: theme.palette.error.main
    },
    success: {
        backgroundColor: theme.palette.success.main
    }
}));
