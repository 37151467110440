import { types } from "mobx-state-tree";

import DomainObjectId from "api/security/Types/DomainObjectId";

const SetAllowedUsersGroupsArguments = types.model({
    shareName: types.string,
    allowed: types.array(DomainObjectId)
});

export default SetAllowedUsersGroupsArguments;
