import { types } from "mobx-state-tree";

import ShareStatusInfo from "api/share/Types/ShareStatusInfo";

const ShareTableCard = types.model({
    pool: types.string,
    volume: types.string,
    partition: types.number,
    fileSystem: types.string,
    raid: types.number,
    size: types.number,
    used: types.number,
    shares: types.array(ShareStatusInfo)
});

export default ShareTableCard;
