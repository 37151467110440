import { ADMIN } from "const/userRolesConst";
import { URL_SUPPORT, URL_SUPPORT_SELF_TESTS_TAB, URL_SUPPORT_OS_DATA_TAB, URL_TROUBLESHOOTING } from "routes";

const disableByRole = (accessRoles = []) => currentRole => !accessRoles.includes(currentRole);

export default [
    {
        value: URL_SUPPORT,
        label: "support.tab.start"
    },
    {
        value: URL_SUPPORT_OS_DATA_TAB,
        label: "support.tab.os_data",
        disableByRole: disableByRole(ADMIN)
    },
    {
        value: URL_TROUBLESHOOTING,
        label: "support.tab.replace_disk",
        disableByRole: disableByRole(ADMIN)
    },
    {
        value: URL_SUPPORT_SELF_TESTS_TAB,
        label: "support.tab.self_tests",
        disableByRole: disableByRole(ADMIN)
    }
];
