import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => ({
    disksContainer: {
        margin: 0,
        marginTop: theme.spacing(6),
    },
    diskItem: {
        margin: 0,
    },
    container: {
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.06),
        borderRadius: theme.spacing(1),
        padding: theme.spacing(6, 10, 6, 4),
        cursor: "pointer",
    },
}));
