import React from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { observer, useLocalStore } from "mobx-react";
import SavedInputIndicator from "components/SavedInputIndicator/";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import MoreMenu from "components/MoreMenu";
import SetPostMapHostModal from "./components/SetPostMapHostModal";
import { SLINGSHOT_EVO_ENVIRONMENT_CARD } from "const/widgetNameConst";
import { useStore } from "hooks/useStore";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import { useLauncher } from "hooks/useLauncher";

const MULTIPLE_EVO_ENVIRONMENT_CARD_ID_PREFIX = `multiple_evo_environment_card`;

const MultipleEvoEnvironmentCard = ({ parentId }) => {
    const idPrefix = parentId
        ? `${parentId}_${MULTIPLE_EVO_ENVIRONMENT_CARD_ID_PREFIX}`
        : MULTIPLE_EVO_ENVIRONMENT_CARD_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { indexingConfigStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isEditModalOpen: false,
        isLoading: true,
        beginLoading() {
            this.isLoading = true;
        },
        endLoading() {
            this.isLoading = false;
        },
    }));

    const { isError } = useLauncher([state.beginLoading, indexingConfigStore.fetchConfig, state.endLoading], {
        ignoreErrors: false,
        dependencies: [indexingConfigStore.isErrorConnectedDBus],
    });

    const openEditModal = () => {
        state.isEditModalOpen = true;
    };
    const closeEditModal = () => {
        state.isEditModalOpen = false;
    };

    return (
        <>
            <DataReadonlyCard
                headerDivider
                title={t("slingshot.index_proxy.multiple_evo.title")}
                onClick={openEditModal}
                disabled={isError}
                control={
                    <MoreMenu>
                        <HomeCardActionMenuItem
                            widget={SLINGSHOT_EVO_ENVIRONMENT_CARD}
                            homeCardActionMenuItemId={`${idPrefix}_add_to_home`}
                        />
                    </MoreMenu>
                }
            >
                {indexingConfigStore.isConfigLoaded ? (
                    <Grid container direction="column" spacing={6} wrap="nowrap">
                        <Grid item container wrap="nowrap" alignItems="center">
                            <Grid item xs={6}>
                                <Typography>{t("slingshot.index_proxy.multiple_evo.label.push_metadata")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <SavedInputIndicator enabled={indexingConfigStore.isPushMetadataEnabled} />
                            </Grid>
                        </Grid>
                        {indexingConfigStore.isPushMetadataEnabled && (
                            <Grid item container wrap="nowrap" alignItems="center">
                                <Grid item xs={6}>
                                    <Typography>{t("slingshot.index_proxy.multiple_evo.label.central_ip")}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">{indexingConfigStore.host}</Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder inProgress={indexingConfigStore.isErrorConnectedDBus || state.isLoading} />
                )}
            </DataReadonlyCard>
            <SetPostMapHostModal open={state.isEditModalOpen} onClose={closeEditModal} parentId={idPrefix} />
        </>
    );
};

export default observer(MultipleEvoEnvironmentCard);
