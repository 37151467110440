import React from "react";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import TextInput from "components/MobxForm/TextInput";
import ChevronDownIcon from "assets/ChevronDownIcon";
import { useObserver } from "mobx-react";
import { mapFieldToProps } from "utils/mapFieldToProps";
import ListItemText from "@material-ui/core/ListItemText";
import CheckSmallIcon from "assets/CheckSmallIcon";

const Autocomplete = props => {
    const { field, ...rest } = props;

    const renderInput = params => {
        return <TextInput {...params} field={field} />;
    };

    const renderItemByType = optionName => {
        const selected = field.value === optionName;
        return (
            <>
                <ListItemText primary={optionName} />
                {selected && <CheckSmallIcon />}
            </>
        );
    };

    field.onChange = (_, value) => {
        if (value) {
            field.set(value);
        }
    };

    return useObserver(() => (
        <MuiAutocomplete
            popupIcon={<ChevronDownIcon />}
            {...mapFieldToProps(field)}
            renderInput={renderInput}
            renderOption={renderItemByType}
            {...rest}
        />
    ));
};

export default Autocomplete;
