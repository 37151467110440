import React from "react";
import { ListItem as MuiListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useStyles } from "./ListItem.style";

const ListItem = props => {
    const classes = useStyles();
    const { children, ...rest } = props;

    return (
        <MuiListItem {...rest}>
            <ListItemIcon className={classes.dotContainer}>
                <div className={classes.dot} />
            </ListItemIcon>
            <ListItemText>{children}</ListItemText>
        </MuiListItem>
    );
};

export default ListItem;
