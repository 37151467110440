import React, { useContext } from "react";
import { useObserver } from "mobx-react";
import { Box, Fade, Divider, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MenuTabs from "./components/MenuTabs";
import SideMenuButton from "./components/SideMenuButton";
import SystemMenu from "./components/SystemMenu";
import { Context } from "store";
import { useStyles } from "./SideMenu.style";
import DndTrash from "components/DndTrash/DndTrash";
import { SHORTCUT } from "const/dragAndDropTypes";
import { ADMIN } from "const/userRolesConst";
import { HASH_INSTALLED_PLUGINS_TABLE_CARD, URL_SUPPORT_OS_DATA_TAB } from "routes";
import { useHistory } from "react-router-dom";

const SideMenu = ({ closeSideMenu, isSideMenuPinned, toggleSystemMenu, isSystemMenuOpen }) => {
    const {
        store: { authStore, uiStore }
    } = useContext(Context);
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();

    const afterMenuTabsClick = () => {
        isSystemMenuOpen && toggleSystemMenu();
    };
    const afterSystemMenuClick = () => {
        toggleSystemMenu();
        !isSideMenuPinned && closeSideMenu();
    };
    const handleNavDrop = item => {
        uiStore.removeShortcut(item.name);
    };
    const navigateToPluginsTableCard = () => {
        history.push(`${URL_SUPPORT_OS_DATA_TAB}#${HASH_INSTALLED_PLUGINS_TABLE_CARD}`);
    };

    return useObserver(() => (
        <Box className={classes.container}>
            <Paper square className={classes.sideMenu}>
                <MenuTabs afterClickCallback={afterMenuTabsClick} />
                {authStore.isAuthorized && authStore.role === ADMIN && (
                    <>
                        <Divider />
                        <SideMenuButton
                            title={t("side_menu.menu_tabs.system")}
                            onClick={toggleSystemMenu}
                            isSelected={isSystemMenuOpen}
                        />
                        <SideMenuButton
                            title={t("side_menu.menu_tabs.plugins")}
                            onClick={navigateToPluginsTableCard}
                            withMenuToggle={false}
                        />
                        <DndTrash className={classes.shortcutRemove} accept={SHORTCUT} onDrop={handleNavDrop} />
                    </>
                )}
            </Paper>
            <Fade in={isSystemMenuOpen} timeout={250}>
                <Box className={classes.systemMenu}>
                    <SystemMenu afterClickCallback={afterSystemMenuClick} />
                </Box>
            </Fade>
        </Box>
    ));
};

export default SideMenu;
