import { types } from "mobx-state-tree";

import BaseBrick from "api/cluster/Types/BaseBrick";

const VolumeRemoveBricksArguments = types.model({
    volumeName: types.string,
    bricks: types.array(BaseBrick)
});

export default VolumeRemoveBricksArguments;
