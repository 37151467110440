import { types } from "mobx-state-tree";

import Ace from "api/security/acl/Types/Ace";

const SetAclArguments = types.model({
    shareName: types.string,
    path: types.string,
    replaceChildrenAcl: types.boolean,
    useInheritedPermissions: types.boolean,
    aces: types.array(Ace)
});

export default SetAclArguments;
