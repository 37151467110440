import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    rowDeleteButtonColumn: {
        "&&": {
            lineHeight: "unset"
        },
        width: theme.spacing(12),
        "& path": {
            fill: theme.palette.grey[300]
        }
    },
    checkboxCell: {
        opacity: 1,
        "&&": {
            lineHeight: "unset"
        }
    },
    loadingCell: {
        "&&": {
            padding: theme.spacing(0, 2)
        }
    }
}));
