import { types } from "mobx-state-tree";

import GetVolumesResultData from "api/cluster/Types/GetVolumesResultData";

const GetVolumesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(GetVolumesResultData)
});

export default GetVolumesResult;
