import Request from "api/Request";

const StopNetworkSelfTest = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Troubleshooting",
    method: "stop_network_self_test"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default StopNetworkSelfTest;
