import React from "react";
import { Grid } from "@material-ui/core";
import MemoryTestCard from "../MemoryTestCard";
import LoopbackTestCard from "../LoopbackTestCard";
import NasDiskTestCard from "../NasDiskTestCard";
import { useStyles } from "./SelfTestsTabComponent.style";
import TestResultDialog from "pages/Support/components/TestResultDialog";
import EvoTestTools from "pages/Support/components/EvoTestTools";
import NetworkGraphCard from "pages/Dashboard/components/NetworkGraphCard";
import ThroughputGraphCard from "pages/Dashboard/components/ThroughputGraphCard";

const SelfTestsTabComponent = () => {
    const classes = useStyles();
    return (
        <Grid container spacing={6} className={classes.container}>
            <TestResultDialog open={false} />
            <Grid item xs={12} lg={8}>
                <EvoTestTools />
            </Grid>
            <Grid item xs={12} lg={4}>
                <MemoryTestCard />
            </Grid>

            <Grid item xs={6}>
                <LoopbackTestCard />
            </Grid>
            <Grid item xs={6}>
                <NetworkGraphCard />
            </Grid>
            <Grid item xs={6}>
                <NasDiskTestCard />
            </Grid>
            <Grid item xs={6}>
                <ThroughputGraphCard />
            </Grid>
        </Grid>
    );
};

export default SelfTestsTabComponent;
