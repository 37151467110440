import { types, flow, getParent } from "mobx-state-tree";
import DnsType from "api/net/Responses/DnsServersResult";
import Socket from "websocket";
import GetDnsServers from "api/net/Requests/GetDnsServers";
import SetDnsServers from "api/net/Requests/SetDnsServers";

const DnsStore = types
    .model({ dnsServers: types.maybe(DnsType) })
    .views(self => ({
        get primary() {
            return self.dnsServers && self.dnsServers.data.primary;
        },
        get secondary() {
            return self.dnsServers && self.dnsServers.data.secondary;
        }
    }))
    .actions(self => ({
        fetchDnsServers: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetDnsServers.create().init();
                const res = yield Socket.send(req);
                self.dnsServers = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setDnsServers: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetDnsServers.create().init(data);
                yield Socket.send(req);
                self.dnsServers.data.primary = data.primary;
                self.dnsServers.data.secondary = data.secondary;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
            return data;
        })
    }));

export default DnsStore;
