import React from "react";

const SmallGearIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
            <path
                fill="#BFD1E1"
                fillRule="evenodd"
                d="M8 1c-.588 0-1.1.4-1.243.97L6.285 3.86l-1.67-1.001c-.504-.303-1.15-.223-1.565.192-.415.416-.495 1.06-.192 1.565l1.003 1.67-1.89.472C1.4 6.9 1 7.412 1 8c0 .588.4 1.1.97 1.243l1.89.472-1.002 1.67c-.303.504-.223 1.15.192 1.565.416.415 1.06.495 1.565.192l1.67-1.002.472 1.89c.132.526.579.907 1.109.963L8 15c.542 0 1.02-.34 1.203-.842l.04-.128.472-1.891 1.67 1.003c.465.28 1.05.233 1.465-.102l.1-.09c.383-.384.48-.963.255-1.446l-.063-.119-1.001-1.67 1.889-.472c.526-.132.907-.579.963-1.109L15 8c0-.542-.34-1.02-.842-1.203l-.128-.04-1.89-.472 1.002-1.67c.28-.465.233-1.05-.102-1.465l-.09-.1c-.384-.383-.963-.48-1.446-.255l-.119.063-1.67 1.002-.472-1.89C9.1 1.4 8.588 1 8 1zm0 5c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
            />
        </svg>
    );
};

export default SmallGearIcon;
