import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    details: {
        fontSize: 12,
    },
    summary: {
        color: theme.palette.grey[400],
        textTransform: "uppercase",
        fontSize: 12,
    },
    accordion: {
        flex: 1,
        "& .MuiAccordionSummary-content": { margin: 0 },
        "& .MuiAccordionSummary-root": { minHeight: "initial" },
        "&.MuiPaper-elevation1": { boxShadow: "none" },
    },
}));
