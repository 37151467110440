import React from "react";

const AcceptIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-2.207-4.293l-3-3a1 1 0 011.414-1.414L10.5 13.6l4.793-4.807a1 1 0 011.414 1.414l-5.5 5.5a1 1 0 01-1.414 0z"
            />
        </svg>
    );
};

export default AcceptIcon;
