export const NasConfigurationConstants = {
    SmbType: {
        SMB2: "smb2",
        SMB3: "smb3"
    },
    FtpType: {
        SFTP: "sftp",
        FTP: "ftp",
        FXP: "fxp"
    }
};
