import { types } from "mobx-state-tree";

import DiskTestStatusData from "api/troubleshooting/Types/DiskTestStatusData";

const DiskTestStatusResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(DiskTestStatusData)
});

export default DiskTestStatusResult;
