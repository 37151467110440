import { types } from "mobx-state-tree";

import NotificationEventsPage from "api/event/Types/NotificationEventsPage";

const GetNotificationPageResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(NotificationEventsPage)
});

export default GetNotificationPageResult;
