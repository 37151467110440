import { SLINGSHOT, SLINGSHOT_AUTOMATIONS, SLINGSHOT_INDEX, SLINGSHOT_SETTINGS } from "const/shortcutNameConst";
import { URL_SLINGSHOT, URL_SLINGSHOT_INDEX, URL_SLINGSHOT_AUTOMATIONS, URL_SLINGSHOT_SETTINGS_TAB } from "routes";

export const getShortcutName = (pathname) => {
    switch (pathname) {
        case URL_SLINGSHOT:
            return SLINGSHOT;
        case URL_SLINGSHOT_INDEX:
            return SLINGSHOT_INDEX;
        case URL_SLINGSHOT_AUTOMATIONS:
            return SLINGSHOT_AUTOMATIONS;
        case URL_SLINGSHOT_SETTINGS_TAB:
            return SLINGSHOT_SETTINGS;
        default:
            return SLINGSHOT;
    }
};
