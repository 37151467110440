import { types } from "mobx-state-tree";
import JobScheduleType from "api/slingshot/sync_jobs/Types/JobScheduleType";
import TaskType from "api/slingshot/automations/Types/TaskType";

const AutomationType = types.model({
    deleted: types.maybe(types.boolean),
    enabled: types.maybe(types.boolean),
    id: types.maybe(types.number),
    instances: types.map(types.maybe(types.number)),
    last_instance: types.maybe(
        types.model({
            id: types.number,
            result: types.string,
        })
    ),
    internal: types.maybe(types.boolean),
    name: types.maybe(types.string),
    owner: types.maybe(types.string),
    paused: types.maybe(types.boolean),
    otf: types.maybe(types.boolean),
    paused_by: types.maybe(types.string),
    schedule: types.maybe(
        types.model({
            schedule: types.maybe(
                types.snapshotProcessor(JobScheduleType, {
                    preProcessor(snapshot) {
                        return snapshot && JSON.parse(snapshot);
                    },
                })
            ),
        })
    ),
    tasks: types.maybe(types.array(TaskType)),
    filter: types.maybe(types.frozen()),
    running_tasks: types.maybe(types.number),
});

export default AutomationType;
