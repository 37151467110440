import React from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import RowTextInput from "components/RowTextInput";
import TextInput from "components/MobxForm/TextInput";
import createForm from "utils/createForm";
import createFields from "./createFields";
import { Button } from "@material-ui/core";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useModal } from "hooks/useModal";

const NEW_FOLDER_DIALOG_ID_PREFIX = `new_folder_dialog`;

const NewFolderDialog = ({ open, onClose, newFolder, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${NEW_FOLDER_DIALOG_ID_PREFIX}` : NEW_FOLDER_DIALOG_ID_PREFIX;
    const { t } = useTranslation();

    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields() }),
        isSubmitted: false,
    }));

    const unsavedChangesModal = useModal();

    const UpdateForm = () => {
        state.form.reset();
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const createFolder = () => {
        state.isSubmitted = true;
        newFolder(state.form.$("newFolder").value);
        onClose();

        return true;
    };

    return useObserver(() => (
        <Dialog
            onClose={closeIfNeeded}
            open={open}
            title={t("slingshot_page.tabs.settings.replication_logs.new_option_dialog.title")}
            submitBtnLabel={t("common.button.create")}
            submitBtnId={`${idPrefix}_create`}
            onSubmit={createFolder}
            onEnter={UpdateForm}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <RowTextInput
                control={<TextInput field={state.form.$("newFolder")} id={`${idPrefix}_new_folder`} />}
                label={t("slingshot_page.tabs.settings.replication_logs.new_option_dialog.label")}
            />
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default NewFolderDialog;
