import React from "react";

const ChevronDownIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#BFD1E1"
                fillRule="evenodd"
                d="M15.29 9.316L12 12.626l-3.3-3.18c-.39-.387-1.02-.387-1.41 0-.392.39-.395 1.022-.006 1.415l.006.005 4 3.86c.389.381 1.011.381 1.4 0l4-4c.392-.389.395-1.022.006-1.414l-.006-.006c-.185-.178-.433-.279-.69-.28-.266-.001-.521.103-.71.29z"
            />
        </svg>
    );
};

export default ChevronDownIcon;
