import React, { useEffect, useContext, useCallback } from "react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import DataCard from "components/DataCard";
import { Grid, Typography } from "@material-ui/core";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import ErrorButton from "components/ErrorButton";
import DeinitializeWarningDialog from "../DeinitializeWarningDialog";

const DeinitializationCard = () => {
    const { t } = useTranslation();
    const {
        store: { postgresStore, syncJobsStore, automationsStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isDatabaseLoadInProgress: false,
        isDialogOpen: false
    }));

    useEffect(() => {
        (async () => {
            state.isDatabaseLoadInProgress = true;
            await postgresStore.getDatabaseLocation();
            state.isDatabaseLoadInProgress = false;
        })();
    }, []);

    const openDialog = useCallback(() => {
        state.isDialogOpen = true;
    });

    const closeDialog = useCallback(() => {
        state.isDialogOpen = false;
    });

    const deinitialize = async () => {
        closeDialog();
        state.inProgress = true;
        const res = await postgresStore.setDatabaseLocation("");
        syncJobsStore.resetReplications();
        automationsStore.resetRAutomations();
        state.inProgress = false;
        return res;
    };

    return useObserver(() => (
        <DataCard autoHeight title={t("master_reset.deinitialization_card.title")}>
            {postgresStore.databaseLocation?.share ? (
                <Grid container spacing={5} direction={"column"}>
                    <Grid container item>
                        <Typography variant={"body2"}>{t("master_reset.deinitialization_card.content")}</Typography>
                    </Grid>
                    <Grid container item>
                        <ErrorButton
                            inProgress={state.inProgress}
                            submit={openDialog}
                            label={t("master_reset.deinitialization_card.deinitialize_button")}
                        />
                    </Grid>
                </Grid>
            ) : (
                <EmptyCardPlaceholder
                    inProgress={state.isDatabaseLoadInProgress}
                    message={t("master_reset.deinitialization_card.placeholder")}
                />
            )}
            <DeinitializeWarningDialog onClose={closeDialog} open={state.isDialogOpen} submit={deinitialize} />
        </DataCard>
    ));
};

export default DeinitializationCard;
