import { types } from "mobx-state-tree";

import Request from "api/Request";
import ListFilesArguments from "api/file/Types/ListFilesArguments";

const ListFiles = Request.props({
    path: "/com/studionetworksolutions/evo/Files",
    method: "list_files",
    arguments: types.maybe(ListFilesArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ListFiles;
