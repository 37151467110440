import { types } from "mobx-state-tree";

const ProgressTask = types.model({
    id: types.number,
    percentage: types.number,
    text: types.string,
    startTime: types.string,
    timeLeft: types.string,
    type: types.string,
    status: types.string,
    sequenceId: types.optional(types.number, 0) // to avoid wrong broadcast order, sets from 'context'.
});

export default ProgressTask;
