import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import Dialog from "components/Dialog";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import createFields from "./createFields";
import createForm from "utils/createForm";
import CreateEditJobForm from "../CreateEditJobForm";
import getSyncJobPauseDuration from "utils/getSyncJobPauseDuration";
import moment from "moment";
import {
    CREATE_JOB_DEFAULT_OPTIONS,
    REPORT_VARIANTS_OPTIONS,
    METHOD_OPTIONS,
    SOURCE_OPTIONS,
    ALIAS_SCHEMA_VALUE,
    DESTINATION_OPTIONS,
    SLINGSHOT_DATE_FORMAT_FOR_SERVER,
    SCHEDULE_PERIOD_OPTIONS,
} from "const/createJobConst";
import EnableJobDialog from "../EnableJobDialog";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import sortByLocaleCompare from "utils/sortByLocaleCompare";
import { SECONDARY_ADMIN, USER } from "const/userRolesConst";
import { useModalCombine } from "hooks/useModalCombine";
import { useStore } from "hooks/useStore";
import { useCreateJobModal } from "hooks/modals";
import { SMB } from "const/shareRootAccessConst";

import { useAutotunEffect } from "hooks/useAutorunEffect";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";

const CREATE_JOB_MODAL_ID_PREFIX = `create_job_modal`;

const CreateJobModal = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${CREATE_JOB_MODAL_ID_PREFIX}` : CREATE_JOB_MODAL_ID_PREFIX;
    const { t } = useTranslation();

    const {
        store: {
            syncJobsStore,
            slingshotStorageStore,
            evoSettingsStore,
            emailConfigurationStore,
            authStore,
            indexingConfigStore,
        },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        isHided: false,
        createdJobId: null,
        localShares: [],
        extShares: [],
        get isMediaProxyEnable() {
            const destinationShare = slingshotStorageStore.shares?.find(
                (share) => share.name === indexingConfigStore.destinationPath?.destinationShare
            );
            return !!destinationShare;
        },
    }));

    const { unsavedChangesModal, enableJobModal } = useModalCombine(["unsavedChangesModal", "enableJobModal"]);
    const createJobModal = useCreateJobModal();

    const hideModal = () => {
        state.isHided = true;
    };
    const showModal = () => {
        state.isHided = false;
    };
    const getMinAge = () => {
        return state.form.$("transferFilesOlderThan").value
            ? moment
                  .duration(
                      Number(state.form.$("transferFilesOlderThanCount").value),
                      state.form.$("transferFilesOlderThanType").value
                  )
                  .as("seconds")
            : undefined;
    };

    const getSourceDecomposedUrl = () => {
        switch (state.form.$("source").value) {
            case SOURCE_OPTIONS.share:
            case SOURCE_OPTIONS.smb:
                return {
                    schema: SOURCE_OPTIONS.share,
                    host: "",
                    resource: state.form.$("sourceShare").value,
                    path: state.form.$("sourcePath").value,
                };
            case SOURCE_OPTIONS.amazon:
            case SOURCE_OPTIONS.azure:
            case SOURCE_OPTIONS.dropbox:
            case SOURCE_OPTIONS.ftp:
            case SOURCE_OPTIONS.gcs:
                return {
                    schema: ALIAS_SCHEMA_VALUE,
                    host: state.form.$("sourceAlias").value,
                    resource: "",
                    path: state.form.$("sourcePath").value,
                };
            case SOURCE_OPTIONS.logicalDisk: {
                const ld = slingshotStorageStore.logicalDisks.find(
                    (ld) => ld.fullName === state.form.$("sourceLogicalDisk").value
                );
                return {
                    schema: SOURCE_OPTIONS.logicalDisk,
                    resource: ld?.pool || "",
                    path: ld?.name || "",
                };
            }
            default:
                return {};
        }
    };

    const getDestinationDecomposedUrl = () => {
        switch (state.form.$("destination").value) {
            case DESTINATION_OPTIONS.share:
            case DESTINATION_OPTIONS.smb:
                return {
                    schema: DESTINATION_OPTIONS.share,
                    host: "",
                    resource: state.form.$("destinationShare").value,
                    path: state.form.$("destinationPath").value,
                };
            case DESTINATION_OPTIONS.amazon:
            case DESTINATION_OPTIONS.azure:
            case DESTINATION_OPTIONS.dropbox:
            case DESTINATION_OPTIONS.ftp:
            case DESTINATION_OPTIONS.gcs:
                return {
                    schema: ALIAS_SCHEMA_VALUE,
                    host: state.form.$("destinationAlias").value,
                    resource: "",
                    path: state.form.$("destinationPath").value,
                };
            default:
                return {};
        }
    };

    const getSchedule = () => {
        switch (state.form.$("schedulePeriod").value) {
            case SCHEDULE_PERIOD_OPTIONS.manual:
                return null;
            case SCHEDULE_PERIOD_OPTIONS.once:
                return {
                    minutes: -1,
                    date: moment(state.form.$("startAtDateTime").value).format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                    not_before: state.form.$("enableBegin").value
                        ? moment(state.form.$("beginDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                    not_after: state.form.$("enableEnd").value
                        ? moment(state.form.$("endDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                };
            case SCHEDULE_PERIOD_OPTIONS.hourly:
                return {
                    minutes: 60, //minutes in 1 hour
                    date: moment
                        .unix(evoSettingsStore.evoInfoTime)
                        .hour((state.form.$("enableEndAt").value && state.form.$("startAtTime").value) || 0)
                        .minute(0)
                        .second(0)
                        .format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                    not_before: state.form.$("enableBegin").value
                        ? moment(state.form.$("beginDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                    not_after: state.form.$("enableEnd").value
                        ? moment(state.form.$("endDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                };
            case SCHEDULE_PERIOD_OPTIONS.daily:
                return {
                    minutes: 1440, //minutes in 1 day
                    date: moment
                        .unix(evoSettingsStore.evoInfoTime)
                        .hour(state.form.$("startAtTime").value)
                        .minute(0)
                        .second(0)
                        .format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                    not_before: state.form.$("enableBegin").value
                        ? moment(state.form.$("beginDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                    not_after: state.form.$("enableEnd").value
                        ? moment(state.form.$("endDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                };
            case SCHEDULE_PERIOD_OPTIONS.weekly:
                return {
                    minutes: 10080, //minutes in 1 week
                    date: moment
                        .unix(evoSettingsStore.evoInfoTime)
                        .day(state.form.$("startAtDayOfWeek").value)
                        .hour(state.form.$("startAtTime").value)
                        .minute(0)
                        .second(0)
                        .format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                    not_before: state.form.$("enableBegin").value
                        ? moment(state.form.$("beginDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                    not_after: state.form.$("enableEnd").value
                        ? moment(state.form.$("endDate").value).startOf("date").format(SLINGSHOT_DATE_FORMAT_FOR_SERVER)
                        : undefined,
                };

            default:
                return {};
        }
    };

    const getPauseScheduleWithoutDuration = () => {
        switch (state.form.$("schedulePeriod").value) {
            case SCHEDULE_PERIOD_OPTIONS.once:
                return {
                    minutes: -1,
                    date: moment(state.form.$("endAtDateTime").value).format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                };
            case SCHEDULE_PERIOD_OPTIONS.hourly:
            case SCHEDULE_PERIOD_OPTIONS.daily:
                return {
                    minutes: 1440, //minutes in 1 day
                    date: moment
                        .unix(evoSettingsStore.evoInfoTime)
                        .hour(state.form.$("endAtTime").value)
                        .minute(0)
                        .second(0)
                        .format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                };
            case SCHEDULE_PERIOD_OPTIONS.weekly:
                return {
                    minutes: 10080, //minutes in 1 week
                    date: moment
                        .unix(evoSettingsStore.evoInfoTime)
                        .day(state.form.$("endAtDayOfWeek").value)
                        .hour(state.form.$("endAtTime").value)
                        .minute(0)
                        .second(0)
                        .format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                };

            default:
                return {};
        }
    };

    const onModalEnter = async () => {
        await slingshotStorageStore.fetchShares();

        unsavedChangesModal.close();

        state.createdJobId = null;
        state.form
            .$("emailJobSummary")
            .set(
                "default",
                emailConfigurationStore.emailSettings?.emailsTo && emailConfigurationStore.emailSettings?.emailsTo[0] === ""
                    ? false
                    : true
            );
        state.form.$("includeMediaProxy").set("default", state.isMediaProxyEnable);

        state.form.$("source").set("default", CREATE_JOB_DEFAULT_OPTIONS.source);
        state.form.$("method").set("default", CREATE_JOB_DEFAULT_OPTIONS.method);
        state.form.$("destination").set("default", CREATE_JOB_DEFAULT_OPTIONS.destination);
        state.form.$("schedulePeriod").set("default", CREATE_JOB_DEFAULT_OPTIONS.schedulePeriod);
        state.form.$("startAtDayOfWeek").set("default", CREATE_JOB_DEFAULT_OPTIONS.dayOfWeek);
        state.form.$("endAtDayOfWeek").set("default", CREATE_JOB_DEFAULT_OPTIONS.dayOfWeek);
        state.form.$("startAtTime").set("default", CREATE_JOB_DEFAULT_OPTIONS.startAt);
        state.form.$("endAtTime").set("default", CREATE_JOB_DEFAULT_OPTIONS.endAt);
        state.form.$("skipNewerFiles").set("default", true);
        state.form.$("includeMetadata").set("default", true);
        state.form.$("replicateInParallel").set("default", true);
        state.form.$("transferFilesOlderThanType").set("default", CREATE_JOB_DEFAULT_OPTIONS.datePeriod);
        state.form.$("verifyAfterTransferReport").set("default", CREATE_JOB_DEFAULT_OPTIONS.reportVariant);
        state.form.$("logLevel").set("default", CREATE_JOB_DEFAULT_OPTIONS.logLevel);
        state.form.$("includeExcludePriority").set("default", CREATE_JOB_DEFAULT_OPTIONS.includeExcludePriority);

        state.form.reset();
        state.form.each((field) => field.resetValidation());
    };

    useAutotunEffect(() => {
        const localShares = slingshotStorageStore.shares.filter((share) => share.pool?.toLowerCase() !== SMB && share.active);

        const filterNFSSharesForSecondaryAdminAndUser = (share) => {
            if ([SECONDARY_ADMIN, USER].some((role) => role === authStore.role)) {
                return share.type.toLowerCase() !== "nfs";
            }

            return true;
        };

        state.localShares =
            (localShares.length &&
                sortByLocaleCompare(localShares.filter(filterNFSSharesForSecondaryAdminAndUser).map((share) => share.name))) ||
            [];
        const extShares = slingshotStorageStore.shares.filter((share) => share.pool?.toLowerCase() === SMB && share.active);
        state.extShares =
            (extShares.length &&
                sortByLocaleCompare(extShares.filter(filterNFSSharesForSecondaryAdminAndUser).map((share) => share.name))) ||
            [];
    });

    useAutotunEffect(() => {
        const initDate = moment.unix(evoSettingsStore.evoInfoTime);

        state.form.$("beginDate").set("default", initDate);
        state.form.$("endDate").set("default", initDate);
        state.form.$("startAtDateTime").set("default", initDate);
        state.form.$("endAtDateTime").set("default", initDate);
    });

    const onSave = async (e) => {
        await state.form.onSubmit(e);
        await state.form.validate();
        state.form.showErrors(true);

        if (!state.form.$("enableIncludePattern").value) {
            state.form.$("includePattern").resetValidation();
        }
        if (!state.form.$("enableExcludePattern").value) {
            state.form.$("excludePattern").resetValidation();
        }
        if (!state.form.$("transferFilesOlderThan").value) {
            state.form.$("transferFilesOlderThanCount").resetValidation();
        }

        if (!state.form.isValid) return;
        if (
            state.form.$("enableBegin").value &&
            state.form.$("enableEnd").value &&
            state.form.$("endDate").value < state.form.$("beginDate").value
        )
            return;
        const jobSchedule = getSchedule();
        const pauseScheduleWithoutDuration = getPauseScheduleWithoutDuration();
        const pauseSheduleDateDisabledEndAt =
            state.form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.hourly &&
            moment
                .unix(evoSettingsStore.evoInfoTime)
                .hour(24 - state.form.$("endAtTime").value + state.form.$("startAtTime").value)
                .minute(0)
                .second(0)
                .format(SLINGSHOT_DATE_FORMAT_FOR_SERVER);
        const res = await syncJobsStore.createJob({
            name: state.form.$("jobName").value,
            include_recycle_bin: state.form.$("includeRecycleBin").value,
            method: state.form.$("method").value,
            email_summary: state.form.$("emailJobSummary").value,
            parallel_processing: state.form.$("replicateInParallel").value,
            remove_deleted_files_before_sync: state.form.$("removeDeletedFiles").value,
            min_age: getMinAge(),
            include_name_mask: state.form.$("enableIncludePattern").value ? state.form.$("includePattern").value : undefined,
            case_sensitive_name_mask:
                state.form.$("enableIncludePattern").value || state.form.$("enableExcludePattern").value
                    ? state.form.$("enableIncludePatternCaseInsensitive").value
                    : false,
            exclude_name_mask: state.form.$("enableExcludePattern").value ? state.form.$("excludePattern").value : undefined,
            include_exclude_priority:
                state.form.$("enableExcludePattern").value && state.form.$("enableIncludePattern").value
                    ? state.form.$("includeExcludePriority").value
                    : undefined,
            include_metadata: state.form.$("includeMetadata").value,
            include_mediaproxy: state.form.$("includeMediaProxy").value,
            copy_logs: state.form.$("saveLogsAtDestination").value,
            check_after_transfer: state.form.$("enableVerifyAfterTransfer").value,
            post_verify: {
                report_same: state.form.$("verifyAfterTransferReport").value === REPORT_VARIANTS_OPTIONS.all,
                one_way: state.form.$("method").value === METHOD_OPTIONS.copy,
            },
            skip_newer: state.form.$("skipNewerFiles").value,
            pre_verify: state.form.$("detectFileModification").value ? "SizeAndHash" : "SizeAndTime",
            logs_destination_folder: state.form.$("saveLogsAtDestination").value
                ? CREATE_JOB_DEFAULT_OPTIONS.logsDestinationFolder
                : undefined,
            log_level: state.form.$("logLevel").value,
            properties: {
                emailOnStart: state.form.$("emailOnJobStart").value,
                emailOnPauseResume: state.form.$("emailOnJobPause").disabled ? undefined : state.form.$("emailOnJobPause").value,
            },
            source: {
                decomposed_url: getSourceDecomposedUrl(),
            },
            destination: {
                decomposed_url: getDestinationDecomposedUrl(),
            },
            schedule: { schedule: jobSchedule },
            pause:
                state.form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.manual
                    ? undefined
                    : {
                          enabled: state.form.$("enableEndAt").value,
                          schedule: {
                              schedule: {
                                  ...pauseScheduleWithoutDuration,
                                  duration: getSyncJobPauseDuration({
                                      jobScheduleDate: jobSchedule.date || moment.unix(evoSettingsStore.evoInfoTime),
                                      pauseScheduleDate:
                                          (!state.form.$("enableEndAt").value
                                              ? pauseSheduleDateDisabledEndAt
                                              : pauseScheduleWithoutDuration.date) || moment.unix(evoSettingsStore.evoInfoTime),
                                      isForceEndChecked: state.form.$("enableEndAt").value,
                                      schedulePeriod: state.form.$("schedulePeriod").value,
                                  }),
                              },
                          },
                      },
            deleted: false,
            enabled: false,
        });

        if (res) {
            state.createdJobId = res.id;
            state.form.reset();
            enableJobModal.open();
        }

        state.form.each((field) => field.resetValidation());

        return res;
    };

    const closeWithFormReset = () => {
        createJobModal.close();
        state.form.clear();
        state.form.each((field) => field.resetValidation());
    };

    const onEnableJobDialogClose = () => {
        enableJobModal.close();
        closeWithFormReset();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault) {
            closeWithFormReset();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            onEnter={onModalEnter}
            maxWidth={"md"}
            open={createJobModal.isOpen}
            onClose={closeIfNeeded}
            title={t("slingshot_page.tabs.replications.create_job.title")}
            onSubmit={onSave}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_button_save`}
            hide={state.isHided}
            withoutClose
            buttons={
                <Button variant="contained" color="secondary" onClick={closeWithFormReset} id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <UnsavedChangesDialog
                onConfirm={closeWithFormReset}
                onClose={unsavedChangesModal.close}
                open={unsavedChangesModal.isOpen}
            />
            {slingshotStorageStore.inProgressGetShare ? (
                <EmptyCardPlaceholder inProgress={slingshotStorageStore.inProgressGetShare} />
            ) : (
                <CreateEditJobForm
                    hideParentModal={hideModal}
                    showParentModal={showModal}
                    form={state.form}
                    createNew
                    localShares={state.localShares}
                    extShares={state.extShares}
                    isMediaProxyEnable={state.isMediaProxyEnable}
                    parentId={idPrefix}
                />
            )}

            <EnableJobDialog
                open={enableJobModal.isOpen}
                onClose={onEnableJobDialogClose}
                id={state.createdJobId}
                name={state.form.$("jobName").value}
                parentId={idPrefix}
            />
        </Dialog>
    ));
};

export default CreateJobModal;
