import React from "react";
import { HashRouter as Router } from "react-router-dom";
import { useLocalStore, useObserver } from "mobx-react";
import store, { Context } from "store";
import multipleEvosRootStore from "store/MultipleEvosRootStore";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SnackbarProvider } from "notistack";
import NotistackAlertIcon from "assets/NotistackAlertIcon";
import NotistackSuccessIcon from "assets/NotistackSuccessIcon";
import { useStyles } from "components/Notistack/Notistack.styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Favicon from "react-favicon";
import { Helmet } from "react-helmet";
import { URL_SPLASH_PAGE } from "routes";
import i18n from "i18n";
import AppBody from "./AppBody";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { PIN_SIDE_MENU } from "const";

export default function App() {
    const classes = useStyles();

    const state = useLocalStore(() => ({
        isLoaded: false,
        get title() {
            if (window.location.hash !== `#${URL_SPLASH_PAGE}`)
                return i18n.t("evo.title", {
                    hostname: store.evoSettingsStore.evoHostname ? `(${store.evoSettingsStore.evoHostname})` : ""
                });
            return i18n.t("splash_page.label.select_product");
        }
    }));

    useAsyncEffect(async () => {
        if (!localStorage.getItem("notistackAutoHideDuration")) localStorage.setItem("notistackAutoHideDuration", 4500); //temporary ability to change notistack auto hide duration
        if (!localStorage.getItem(PIN_SIDE_MENU)) localStorage.setItem(PIN_SIDE_MENU, true);
        await store.bootProgressStore.getBootProgress();
        state.isLoaded = true;
        store.bootProgressStore.startBootProgressPolling();
    }, []);

    return useObserver(() => (
        <SnackbarProvider
            className={classes.notistack}
            classes={{
                variantSuccess: classes.success,
                variantError: classes.error
            }}
            autoHideDuration={Number(localStorage.getItem("notistackAutoHideDuration"))} //temporary ability to change notistack auto hide duration
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            iconVariant={{ error: <NotistackAlertIcon />, success: <NotistackSuccessIcon /> }}
        >
            <Favicon url={window.location.hash !== `#${URL_SPLASH_PAGE}` ? "evo_e.png" : "favicon.png"} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>{state.title}</title>
            </Helmet>
            <Context.Provider value={{ store, multipleEvosRootStore }}>
                <DndProvider backend={HTML5Backend}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Router>
                            <AppBody isLoaded={state.isLoaded} />
                        </Router>
                    </MuiPickersUtilsProvider>
                </DndProvider>
            </Context.Provider>
        </SnackbarProvider>
    ));
}
