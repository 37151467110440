import { types } from "mobx-state-tree";

import Request from "api/Request";
import UsedSpaceEventsSettings from "api/events/Types/UsedSpaceEventsSettings";

const SetUsedSpaceEventsSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Events",
    method: "set_used_space_events_settings",
    arguments: types.maybe(UsedSpaceEventsSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetUsedSpaceEventsSettings;
