import { types, flow, getParent } from "mobx-state-tree";
import MainSocket from "websocket";
import ListRootDirectoriesType from "api/file/Responses/ListRootDirectoriesResult";
import ListRootDirectories from "api/file/Requests/ListFreeRootDirectories";
import ListFiles from "api/file/Requests/ListFiles";
import ListFilesResult from "api/file/Responses/ListFilesResult";
import PageQuery from "api/general/Types/PageQuery";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import { ASC, DESC, FILE_NAVIGATOR_NAME } from "const/sortColumnConst";
import DeleteFile from "api/file/Requests/DeleteFile";
import { getEvoPrefix } from "../../utils/helpers/getEvoPrefix";

const FileStore = types
    .model({
        listRootDirectoriesStore: types.maybe(ListRootDirectoriesType),
        listFilesStore: types.maybe(ListFilesResult),
        currentFileName: types.maybeNull(types.string),
        currentFilePath: types.maybeNull(types.string),
        currentShareName: types.maybeNull(types.string),
    })
    .volatile(() => ({
        orderBy: FILE_NAVIGATOR_NAME,
        order: ASC,
        queryLimit: 1000,
        chunckedFiles: [],
    }))
    .views((self) => ({
        get listRootDirectories() {
            return self.listRootDirectoriesStore && self.listRootDirectoriesStore.data;
        },
        get totalFiles() {
            return self.listFilesStore?.data.total;
        },
        get files() {
            const filesWithTimestamp =
                self.listFilesStore?.data.data.length &&
                self.listFilesStore?.data.data.map((entry) => {
                    return { ...entry, time: new Date(entry.modifiedTime).getTime() };
                });
            return filesWithTimestamp || [];
        },
        get currentFile() {
            return self.files.find((file) => file.name === self.currentFileName);
        },
        get sortedFiles() {
            return stableSort(this.files, getComparator(this.order, this.orderBy));
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
        get chunckedSortedFiles() {
            return (this.chunckedFiles && stableSort(this.chunckedFiles, getComparator(this.order, this.orderBy))) || [];
        },
        get currentFileNameWithPrefix() {
            const { ip, name, evoSettingsStore } = getParent(self);

            const prefix = getEvoPrefix({ ip, evoName: name, hostname: evoSettingsStore?.evoInfo?.hostname, store: self });

            return self.currentFileName ? `${prefix}${self.currentFileName}` : null;
        },
    }))
    .actions((self) => ({
        fetchListRootDirectories: flow(function* (data) {
            try {
                const req = ListRootDirectories.create().init(data);
                const res = yield self.socket.send(req);
                self.listRootDirectoriesStore = res;
            } catch (e) {
                const { processingStore } = getParent(self);
                processingStore.setError(e);
            }
        }),
        listFiles: flow(function* (data) {
            try {
                const query = PageQuery.create({ offset: 0, limit: self.queryLimit });
                const payload = { ...data, query };
                const req = ListFiles.create().init(payload);
                const res = yield self.socket.send(req);

                self.listFilesStore = res;
                return true;
            } catch (e) {
                const { processingStore } = getParent(self);
                processingStore.setError(e);
            }
            return null;
        }),
        deleteFiles: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DeleteFile.create().init(data);
                const res = yield self.socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        changeSorting(column) {
            if (column === self.orderBy) {
                self.order = self.order === DESC ? ASC : DESC;
            } else {
                self.order = ASC;
                self.orderBy = column;
            }
        },
        setCurrentFileName: (name) => {
            self.currentFileName = name;
        },
        setCurrentFilePath: (path) => {
            self.currentFilePath = path;
        },
        setCurrentShareName: (name) => {
            self.currentShareName = name;
        },
        setChunckedFiles: (chunckedFiles) => {
            self.chunckedFiles = chunckedFiles;
        },
        removeCurrentFile() {
            self.setCurrentFileName(null);
            self.setCurrentFilePath(null);
            self.setCurrentShareName(null);
        },
    }));

export default FileStore;
