import { LOCAL_USERS } from "const/userSearchVariants";

const createFields = () => {
    return [
        {
            name: "type",
            default: LOCAL_USERS
        },
        {
            name: "name",
            default: "",
            rules: "required"
        },
        {
            name: "domain",
            default: ""
        },
        {
            name: "recursive",
            type: "checkbox",
            default: false
        }
    ];
};

export default createFields;
