import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    errorColor: {
        cursor: "pointer",
        color: theme.palette.error.main,
        "& path": {
            fill: theme.palette.error.main
        }
    },
    disabled: {
        "& path": {
            fill: theme.palette.grey["300"]
        }
    }
}));
