import { types } from "mobx-state-tree";

const MissingQuad = types
    .model({
        quadId: types.number,
        quadNumber: types.number,
        serials: types.array(types.string)
    })
    .views(self => ({
        get serialsField() {
            return self.serials.join(" ");
        }
    }));

export default MissingQuad;
