import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    detailsContainer: {
        padding: theme.spacing(2, 4),
        margin: 0,
        width: "100%"
    },

    textInfo: {
        fontWeight: 500,
        paddingLeft: theme.spacing(2),
        wordBreak: "break-word"
    }
}));
