export const COUNTRY_CODES = [
    { value: "AX", label: "[AX] Åland Islands" },
    { value: "AD", label: "[AD] Andorra" },
    { value: "AE", label: "[AE] United Arab Emirates" },
    { value: "AF", label: "[AF] Afghanistan" },
    { value: "AG", label: "[AG] Antigua and Barbuda" },
    { value: "AI", label: "[AI] Anguilla" },
    { value: "AL", label: "[AL] Albania" },
    { value: "AM", label: "[AM] Armenia" },
    { value: "AN", label: "[AN] Netherlands Antilles" },
    { value: "AO", label: "[AO] Angola" },
    { value: "AQ", label: "[AQ] Antarctica" },
    { value: "AR", label: "[AR] Argentina" },
    { value: "AS", label: "[AS] American Samoa" },
    { value: "AT", label: "[AT] Austria" },
    { value: "AU", label: "[AU] Australia" },
    { value: "AW", label: "[AW] Aruba" },
    { value: "AZ", label: "[AZ] Azerbaijan" },
    { value: "BA", label: "[BA] Bosnia and Herzegovina" },
    { value: "BB", label: "[BB] Barbados" },
    { value: "BD", label: "[BD] Bangladesh" },
    { value: "BE", label: "[BE] Belgium" },
    { value: "BF", label: "[BF] Burkina Faso" },
    { value: "BG", label: "[BG] Bulgaria" },
    { value: "BH", label: "[BH] Bahrain" },
    { value: "BI", label: "[BI] Burundi" },
    { value: "BJ", label: "[BJ] Benin" },
    { value: "BM", label: "[BM] Bermuda" },
    { value: "BN", label: "[BN] Brunei Darussalam" },
    { value: "BO", label: "[BO] Bolivia" },
    { value: "BR", label: "[BR] Brazil" },
    { value: "BS", label: "[BS] Bahamas" },
    { value: "BT", label: "[BT] Bhutan" },
    { value: "BV", label: "[BV] Bouvet Island" },
    { value: "BW", label: "[BW] Botswana" },
    { value: "BZ", label: "[BZ] Belize" },
    { value: "CA", label: "[CA] Canada" },
    { value: "CC", label: "[CC] Cocos (Keeling) Islands" },
    { value: "CF", label: "[CF] Central African Republic" },
    { value: "CH", label: "[CH] Switzerland" },
    { value: "CI", label: "[CI] Cote D'Ivoire (Ivory Coast)" },
    { value: "CK", label: "[CK] Cook Islands" },
    { value: "CL", label: "[CL] Chile" },
    { value: "CM", label: "[CM] Cameroon" },
    { value: "CN", label: "[CN] China" },
    { value: "CO", label: "[CO] Colombia" },
    { value: "CR", label: "[CR] Costa Rica" },
    { value: "CS", label: "[CS] Czechoslovakia (former)" },
    { value: "CV", label: "[CV] Cape Verde" },
    { value: "CX", label: "[CX] Christmas Island" },
    { value: "CY", label: "[CY] Cyprus" },
    { value: "CZ", label: "[CZ] Czech Republic" },
    { value: "DE", label: "[DE] Germany" },
    { value: "DJ", label: "[DJ] Djibouti" },
    { value: "DK", label: "[DK] Denmark" },
    { value: "DM", label: "[DM] Dominica" },
    { value: "DO", label: "[DO] Dominican Republic" },
    { value: "DZ", label: "[DZ] Algeria" },
    { value: "EC", label: "[EC] Ecuador" },
    { value: "EE", label: "[EE] Estonia" },
    { value: "EG", label: "[EG] Egypt" },
    { value: "EH", label: "[EH] Western Sahara" },
    { value: "ER", label: "[ER] Eritrea" },
    { value: "ES", label: "[ES] Spain" },
    { value: "ET", label: "[ET] Ethiopia" },
    { value: "FI", label: "[FI] Finland" },
    { value: "FJ", label: "[FJ] Fiji" },
    { value: "FK", label: "[FK] Falkland Islands (Malvinas)" },
    { value: "FM", label: "[FM] Micronesia" },
    { value: "FO", label: "[FO] Faroe Islands" },
    { value: "FR", label: "[FR] France" },
    { value: "FX", label: "[FX] France, Metropolitan" },
    { value: "GA", label: "[GA] Gabon" },
    { value: "GB", label: "[GB] Great Britain (UK)" },
    { value: "GD", label: "[GD] Grenada" },
    { value: "GE", label: "[GE] Georgia" },
    { value: "GF", label: "[GF] French Guiana" },
    { value: "GG", label: "[GG] Guernsey" },
    { value: "GH", label: "[GH] Ghana" },
    { value: "GI", label: "[GI] Gibraltar" },
    { value: "GL", label: "[GL] Greenland" },
    { value: "GM", label: "[GM] Gambia" },
    { value: "GN", label: "[GN] Guinea" },
    { value: "GP", label: "[GP] Guadeloupe" },
    { value: "GQ", label: "[GQ] Equatorial Guinea" },
    { value: "GR", label: "[GR] Greece" },
    { value: "GS", label: "[GS] S. Georgia and S. Sandwich Isls." },
    { value: "GT", label: "[GT] Guatemala" },
    { value: "GU", label: "[GU] Guam" },
    { value: "GW", label: "[GW] Guinea-Bissau" },
    { value: "GY", label: "[GY] Guyana" },
    { value: "HK", label: "[HK] Hong Kong" },
    { value: "HM", label: "[HM] Heard and McDonald Islands" },
    { value: "HN", label: "[HN] Honduras" },
    { value: "HR", label: "[HR] Croatia (Hrvatska)" },
    { value: "HT", label: "[HT] Haiti" },
    { value: "HU", label: "[HU] Hungary" },
    { value: "ID", label: "[ID] Indonesia" },
    { value: "IE", label: "[IE] Ireland" },
    { value: "IL", label: "[IL] Israel" },
    { value: "IM", label: "[IM] Isle of Man" },
    { value: "IN", label: "[IN] India" },
    { value: "IO", label: "[IO] British Indian Ocean Territory" },
    { value: "IS", label: "[IS] Iceland" },
    { value: "IT", label: "[IT] Italy" },
    { value: "JE", label: "[JE] Jersey" },
    { value: "JM", label: "[JM] Jamaica" },
    { value: "JO", label: "[JO] Jordan" },
    { value: "JP", label: "[JP] Japan" },
    { value: "KE", label: "[KE] Kenya" },
    { value: "KG", label: "[KG] Kyrgyzstan" },
    { value: "KH", label: "[KH] Cambodia" },
    { value: "KI", label: "[KI] Kiribati" },
    { value: "KM", label: "[KM] Comoros" },
    { value: "KN", label: "[KN] Saint Kitts and Nevis" },
    { value: "KR", label: "[KR] Korea (South)" },
    { value: "KW", label: "[KW] Kuwait" },
    { value: "KY", label: "[KY] Cayman Islands" },
    { value: "KZ", label: "[KZ] Kazakhstan" },
    { value: "LA", label: "[LA] Laos" },
    { value: "LC", label: "[LC] Saint Lucia" },
    { value: "LI", label: "[LI] Liechtenstein" },
    { value: "LK", label: "[LK] Sri Lanka" },
    { value: "LS", label: "[LS] Lesotho" },
    { value: "LT", label: "[LT] Lithuania" },
    { value: "LU", label: "[LU] Luxembourg" },
    { value: "LV", label: "[LV] Latvia" },
    { value: "LY", label: "[LY] Libya" },
    { value: "MA", label: "[MA] Morocco" },
    { value: "MC", label: "[MC] Monaco" },
    { value: "MD", label: "[MD] Moldova" },
    { value: "ME", label: "[ME] Montenegro" },
    { value: "MG", label: "[MG] Madagascar" },
    { value: "MH", label: "[MH] Marshall Islands" },
    { value: "MK", label: "[MK] Macedonia" },
    { value: "ML", label: "[ML] Mali" },
    { value: "MM", label: "[MM] Myanmar" },
    { value: "MN", label: "[MN] Mongolia" },
    { value: "MO", label: "[MO] Macau" },
    { value: "MP", label: "[MP] Northern Mariana Islands" },
    { value: "MQ", label: "[MQ] Martinique" },
    { value: "MR", label: "[MR] Mauritania" },
    { value: "MS", label: "[MS] Montserrat" },
    { value: "MT", label: "[MT] Malta" },
    { value: "MU", label: "[MU] Mauritius" },
    { value: "MV", label: "[MV] Maldives" },
    { value: "MW", label: "[MW] Malawi" },
    { value: "MX", label: "[MX] Mexico" },
    { value: "MY", label: "[MY] Malaysia" },
    { value: "MZ", label: "[MZ] Mozambique" },
    { value: "NA", label: "[NA] Namibia" },
    { value: "NC", label: "[NC] New Caledonia" },
    { value: "NE", label: "[NE] Niger" },
    { value: "NF", label: "[NF] Norfolk Island" },
    { value: "NG", label: "[NG] Nigeria" },
    { value: "NI", label: "[NI] Nicaragua" },
    { value: "NL", label: "[NL] Netherlands" },
    { value: "NO", label: "[NO] Norway" },
    { value: "NP", label: "[NP] Nepal" },
    { value: "NR", label: "[NR] Nauru" },
    { value: "NT", label: "[NT] Neutral Zone" },
    { value: "NU", label: "[NU] Niue" },
    { value: "NZ", label: "[NZ] New Zealand (Aotearoa)" },
    { value: "OM", label: "[OM] Oman" },
    { value: "PA", label: "[PA] Panama" },
    { value: "PE", label: "[PE] Peru" },
    { value: "PF", label: "[PF] French Polynesia" },
    { value: "PG", label: "[PG] Papua New Guinea" },
    { value: "PH", label: "[PH] Philippines" },
    { value: "PK", label: "[PK] Pakistan" },
    { value: "PL", label: "[PL] Poland" },
    { value: "PM", label: "[PM] St. Pierre and Miquelon" },
    { value: "PN", label: "[PN] Pitcairn" },
    { value: "PR", label: "[PR] Puerto Rico" },
    { value: "PS", label: "[PS] Palestinian Territory" },
    { value: "PT", label: "[PT] Portugal" },
    { value: "PW", label: "[PW] Palau" },
    { value: "PY", label: "[PY] Paraguay" },
    { value: "QA", label: "[QA] Qatar" },
    { value: "RE", label: "[RE] Reunion" },
    { value: "RO", label: "[RO] Romania" },
    { value: "RS", label: "[RS] Serbia" },
    { value: "RU", label: "[RU] Russian Federation" },
    { value: "RW", label: "[RW] Rwanda" },
    { value: "SA", label: "[SA] Saudi Arabia" },
    { value: "SB", label: "[SB] Solomon Islands" },
    { value: "SC", label: "[SC] Seychelles" },
    { value: "SE", label: "[SE] Sweden" },
    { value: "SG", label: "[SG] Singapore" },
    { value: "SH", label: "[SH] St. Helena" },
    { value: "SI", label: "[SI] Slovenia" },
    { value: "SJ", label: "[SJ] Svalbard and Jan Mayen Islands" },
    { value: "SK", label: "[SK] Slovak Republic" },
    { value: "SL", label: "[SL] Sierra Leone" },
    { value: "SM", label: "[SM] San Marino" },
    { value: "SN", label: "[SN] Senegal" },
    { value: "SR", label: "[SR] Suriname" },
    { value: "ST", label: "[ST] Sao Tome and Principe" },
    { value: "SU", label: "[SU] USSR (former)" },
    { value: "SV", label: "[SV] El Salvador" },
    { value: "SZ", label: "[SZ] Swaziland" },
    { value: "TC", label: "[TC] Turks and Caicos Islands" },
    { value: "TD", label: "[TD] Chad" },
    { value: "TF", label: "[TF] French Southern Territories" },
    { value: "TG", label: "[TG] Togo" },
    { value: "TH", label: "[TH] Thailand" },
    { value: "TJ", label: "[TJ] Tajikistan" },
    { value: "TK", label: "[TK] Tokelau" },
    { value: "TM", label: "[TM] Turkmenistan" },
    { value: "TN", label: "[TN] Tunisia" },
    { value: "TO", label: "[TO] Tonga" },
    { value: "TP", label: "[TP] East Timor" },
    { value: "TR", label: "[TR] Turkey" },
    { value: "TT", label: "[TT] Trinidad and Tobago" },
    { value: "TV", label: "[TV] Tuvalu" },
    { value: "TW", label: "[TW] Taiwan" },
    { value: "TZ", label: "[TZ] Tanzania" },
    { value: "UA", label: "[UA] Ukraine" },
    { value: "UG", label: "[UG] Uganda" },
    { value: "UM", label: "[UM] US Minor Outlying Islands" },
    { value: "US", label: "[US] United States of America" },
    { value: "UY", label: "[UY] Uruguay" },
    { value: "UZ", label: "[UZ] Uzbekistan" },
    { value: "VA", label: "[VA] Vatican City State (Holy See)" },
    { value: "VC", label: "[VC] Saint Vincent and the Grenadines" },
    { value: "VE", label: "[VE] Venezuela" },
    { value: "VG", label: "[VG] Virgin Islands (British)" },
    { value: "VI", label: "[VI] Virgin Islands (U.S.)" },
    { value: "VN", label: "[VN] Viet Nam" },
    { value: "VU", label: "[VU] Vanuatu" },
    { value: "WF", label: "[WF] Wallis and Futuna Islands" },
    { value: "WS", label: "[WS] Samoa" },
    { value: "YE", label: "[YE] Yemen" },
    { value: "YT", label: "[YT] Mayotte" },
    { value: "ZA", label: "[ZA] South Africa" },
    { value: "ZM", label: "[ZM] Zambia" }
];

export const COUNTRY_CODES_DEFAULT = "US";
