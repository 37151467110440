import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => ({
    menu: {
        "&>svg": {
            backgroundColor: ({ isOpen }) => isOpen && hexToRgba(theme.palette.primary.main, 0.15),
            "&>path": {
                fill: ({ isOpen }) => isOpen && theme.palette.primary.main
            }
        }
    }
}));
