import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => ({
    label: {
        opacity: 0.8,
        fontSize: 16,
        fontWeight: "normal",
    },
    contentRoot: {
        paddingBottom: theme.spacing(3),
    },
    expansionPanel: {
        "&&": {
            boxShadow: "none",
            border: `${theme.palette.expandPanel.border.color} 1px solid`,
            borderRadius: theme.spacing(2),
            padding: theme.spacing(3),
            margin: theme.spacing(0, -3),
        },
    },
    expansionIconClose: {
        transform: "rotate(180deg)",
    },
    expansionIcon: {
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.1),
        borderRadius: "50%",
    },
    expansionLabel: {
        fontSize: 16,
        fontWeight: 500,
        opacity: 0.8,
    },
    expansionPanelContent: {
        "&&": {
            display: "flex",
            minHeight: 0,
            margin: 0,
            padding: 0,
            justifyContent: "space-between",
        },
    },
    detailsRoot: {
        padding: theme.spacing(3, 0, 2),
    },
    dialogActionsContainer: {
        padding: theme.spacing(4, 6),
    },
    cancelButton: {
        marginRight: theme.spacing(4),
        boxShadow: "none",
    },
    firstItem: {
        "&&": {
            paddingRight: theme.spacing(2),
        },
    },
    caption: {
        marginTop: theme.spacing(-2),
        marginBottom: theme.spacing(3.5),
        opacity: 0.4,
    },
    multipleCheckboxContainer: {
        marginRight: "auto",
    },
}));
