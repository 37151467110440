import { types } from "mobx-state-tree";

import DriveStatus from "api/drive_replace/Types/DriveStatus";

const AffectedPool = types.model({
    poolName: types.string,
    drives: types.array(DriveStatus)
});

export default AffectedPool;
