import { types } from "mobx-state-tree";

import DomainObjectId from "api/security/Types/DomainObjectId";

const GetOwnerResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(DomainObjectId)
});

export default GetOwnerResult;
