import { types } from "mobx-state-tree";

import Request from "api/Request";

const CreateFolder = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Storage1",
    method: "create_folder",
    // TODO: corrected arguments type
    arguments: types.frozen(null)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default CreateFolder;
