import Request from "api/Request";

const GetTrustedDomains = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Users",
    method: "get_trusted_domains"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetTrustedDomains;
