import { types } from "mobx-state-tree";

import ClusterSettingsData from "api/cluster/Types/ClusterSettingsData";

const ClusterSettings = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(ClusterSettingsData)
});

export default ClusterSettings;
