import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./RowTextInput.styles";
import clsx from "clsx";

const RowTextInput = ({ label, control, className, leftXs, rightXs, hide }) => {
    const classes = useStyles();
    if (hide) {
        return null;
    }

    return (
        <Grid className={clsx(classes.container, className)} item container alignItems={"center"}>
            <Grid xs={leftXs || 6} item>
                <Typography>{label}</Typography>
            </Grid>
            <Grid xs={rightXs || 6} item>
                {control}
            </Grid>
        </Grid>
    );
};

export default RowTextInput;
