import Request from "api/Request";

const OwnDevice = Request.props({
    path: "/com/studionetworksolutions/evo/services/Backup",
    method: "own_device"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default OwnDevice;
