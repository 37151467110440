import React from "react";
import { Button, Typography, Divider, Grid, CircularProgress, InputAdornment, ButtonBase, Popover } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { observer, useLocalStore } from "mobx-react";
import { useStyles } from "./AddToGroupDialog.styles";
import TextInput from "components/MobxForm/TextInput";
import { LOCAL_GROUPS_SEARCH_DEFAULTS } from "const/userSearchVariants";
import CloseIcon from "assets/CloseIcon";
import ChevronDownIcon from "assets/ChevronDownIcon";
import LocalGroupIcon from "assets/LocalGroupIcon";
import createFields from "./createFields";
import createForm from "utils/createForm";
import TablePagination from "components/TablePagination";
import { USERS_SEARCH_SELECT_RELATIVE_POSITION_TOP } from "const/styleConst";
import Dialog from "components/Dialog";
import clsx from "clsx";
import QuickCreate from "components/QuickCreate/QuickCreate";
import AddGroupDialog from "components/AddGroupDialog/AddGroupDialog";
import { useStore } from "hooks/useStore";
import { toJS } from "mobx";

const ADD_TO_GROUP_DIALOG_ID_PREFIX = "add_to_group_dialog";

const AddToGroupDialog = ({ open, onClose, parentId }) => {
    const { t } = useTranslation();
    const idPrefix = parentId ? `${parentId}_${ADD_TO_GROUP_DIALOG_ID_PREFIX}` : ADD_TO_GROUP_DIALOG_ID_PREFIX;
    const fields = createFields();

    const {
        store: { userStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isSearchInProgress: false,
        form: createForm({
            fields,
            options: {
                validateOnInit: false,
                validateOnBlur: false,
                showErrorsOnReset: false,
            },
        }),
        anchorEl: null,
        isSelectOpen: false,
        isSubmitted: false,
    }));

    const classes = useStyles({ isSelectOpen: state.isSelectOpen });

    const onEnter = () => {
        state.isSearchInProgress = false;
        resetNameField();
        userStore.resetSearchByTypeResult();
        state.isSubmitted = false;
    };

    const closeModal = () => {
        userStore.resetSearchByTypeResult();
        onClose();
    };

    const closeSelect = () => {
        state.isSelectOpen = false;
    };

    const search = async () => {
        state.isSearchInProgress = true;
        userStore.resetSearchByTypeResult();
        userStore.updateSearchByTypeRequest("type", LOCAL_GROUPS_SEARCH_DEFAULTS.type);
        userStore.updateSearchByTypeRequest("page", LOCAL_GROUPS_SEARCH_DEFAULTS.page);
        userStore.updateSearchByTypeRequest("name", state.form.$("groupName").value);
        await userStore.searchByType();
        state.isSearchInProgress = false;
        state.isSelectOpen = true;
    };

    const keyDownHandler = async (e) => {
        state.anchorEl = e.currentTarget;
        e.key === "Enter" && search();
    };

    const onInputClick = (e) => {
        !state.form.$("groupName").isValid && state.form.$("groupName").resetValidation();
        state.anchorEl = e.currentTarget;
    };

    const resetNameField = () => {
        state.form.$("groupName").reset();
        userStore.resetSearchByTypeResult();
    };
    const addGroup = async (groupName) => {
        userStore.updateSearchByTypeRequest("name", groupName);

        const checkedUsers = toJS(userStore.checkedUsers);
        const currentEntity = toJS(userStore.currentEntity);
        const users = userStore.checkedUsers.length ? checkedUsers : [currentEntity.id];

        const res = await userStore.addGroupMembers(groupName, users);

        if (res) {
            userStore.clearCheckedUsers();
            state.isSubmitted = true;
        }
    };
    const addToGroup = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        addGroup(state.form.$("groupName").value);

        return true;
    };

    const onAdd = async (groups) => {
        state.isSelectOpen = false;
        await addGroup(groups.pop());
        state.isSearchInProgress = false;
    };

    const handleChangePage = async (newPage) => {
        state.isSearchInProgress = true;
        userStore.updateSearchByTypeRequest("page", +newPage);
        await userStore.searchByType();
        state.isSearchInProgress = false;
    };

    const onOptionClick = (groupName) => () => {
        state.form.$("groupName").set("value", groupName);
        closeSelect();
    };

    const getInputAdornment = () => {
        return (
            <InputAdornment position="end">
                <Grid container wrap={"nowrap"} alignItems={"center"} justify={"flex-end"} spacing={2}>
                    {!state.form.$("groupName").isEmpty ? (
                        <Grid item>
                            <ButtonBase className={classes.closeIcon} onClick={resetNameField} id={`${idPrefix}_reset_name`}>
                                <CloseIcon />
                            </ButtonBase>
                        </Grid>
                    ) : null}

                    <Grid item>
                        <Grid container alignItems={"center"} className={classes.searchIconContainer}>
                            {state.isSearchInProgress ? (
                                <CircularProgress size={22} />
                            ) : (
                                <ButtonBase
                                    className={clsx(classes.primaryColorIcon, classes.dropdownIcon)}
                                    onClick={search}
                                    id={`${idPrefix}_open_result`}
                                >
                                    <ChevronDownIcon />
                                </ButtonBase>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </InputAdornment>
        );
    };

    return (
        <Dialog
            buttons={
                <Button onClick={closeModal} variant={"contained"} color={"secondary"} id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
            submitBtnLabel={t("users.add_users_to_group.button.add_to_group")}
            onSubmit={addToGroup}
            open={open}
            onClose={closeModal}
            onEnter={onEnter}
            title={t("users.add_users_to_group.title", { count: userStore.checkedUsers })}
        >
            <Grid container direction={"column"}>
                <Grid item container alignItems={"center"} wrap={"nowrap"}>
                    <Grid xs={5} item container alignItems={"center"} justify={"flex-start"}>
                        <Typography>{t("users.add_users_to_group.input.label")}</Typography>
                    </Grid>
                    <Grid className={classes.inputContainer} item>
                        <TextInput
                            autoFocus
                            className={classes.input}
                            onClick={onInputClick}
                            placeholder={t("users.add_users_to_group.input.placeholder")}
                            field={state.form.$("groupName")}
                            InputProps={{
                                endAdornment: getInputAdornment(),
                                id: `${idPrefix}_group_name`,
                            }}
                            onKeyDown={keyDownHandler}
                        />
                        <Popover
                            PaperProps={{ className: classes.selectPaper }}
                            open={state.isSelectOpen}
                            anchorEl={state.anchorEl}
                            onClose={closeSelect}
                            anchorOrigin={{ vertical: USERS_SEARCH_SELECT_RELATIVE_POSITION_TOP, horizontal: "center" }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            {userStore.searchByTypeResult?.total > 0 ? (
                                <React.Fragment>
                                    {userStore.searchByTypeResult?.data.map((group) => (
                                        <Grid
                                            container
                                            className={classes.selectItem}
                                            onClick={onOptionClick(group.id.name)}
                                            key={group.id.name}
                                            alignItems={"center"}
                                            wrap={"nowrap"}
                                        >
                                            <LocalGroupIcon className={classes.selectIcon} />
                                            <Typography noWrap>{group.id.name}</Typography>
                                        </Grid>
                                    ))}
                                </React.Fragment>
                            ) : (
                                <Grid className={classes.emptyResponse} container alignItems={"center"} justify={"center"}>
                                    <Typography className={classes.emptyResponseText}>
                                        {t("users.search_results.empty_table_placeholder", {
                                            type: t("users.common.local_groups"),
                                        })}
                                    </Typography>

                                    <QuickCreate handleClose={search} parentId={idPrefix}>
                                        {(props) => (
                                            <AddGroupDialog
                                                open={props.isOpen}
                                                onClose={props.close}
                                                initialValues={{ name: state.form.$("groupName").value }}
                                                onAdd={onAdd}
                                                parentId={idPrefix}
                                            />
                                        )}
                                    </QuickCreate>
                                </Grid>
                            )}

                            {userStore.searchByTypeResult &&
                            userStore.searchByTypeResult?.total > LOCAL_GROUPS_SEARCH_DEFAULTS.limit ? (
                                <React.Fragment>
                                    <Divider />
                                    <TablePagination
                                        className={classes.pagination}
                                        onChangePage={handleChangePage}
                                        count={userStore.searchByTypeResult?.total}
                                        page={userStore.searchByTypeRequest?.page}
                                        rowsPerPage={userStore.searchByTypeRequest?.limit}
                                        rowsPerPageOptions={[10]}
                                        labelDisplayedRows={() => {}}
                                        parentId={idPrefix}
                                    />
                                </React.Fragment>
                            ) : null}
                        </Popover>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default observer(AddToGroupDialog);
