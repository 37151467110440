import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./FileMasksTable.styles";
import { Grid, Table, TableHead, TableCell, TableBody, TableRow, ButtonBase, Typography } from "@material-ui/core";
import TextInput from "components/TextInput/TextInput";
import MobxFormTextInput from "components/MobxForm/TextInput";
import TrashIcon from "assets/TrashIcon";
import { useTranslation } from "react-i18next";
import { useExternalDefaultMask } from "./FileMasksTable.hooks";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import PlusIcon from "assets/PlusIcon";
import useStoreByIp from "hooks/useStoreByIp";

const FILE_MASKS_TABLE_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_file_masks_table`;

const FileMasksTable = ({ isEditable, form, ip }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { shareStore } = useStoreByIp({ ip });

    const shareMasks = shareStore.currentEditableShare && shareStore.currentEditableShare.masks;
    const commonDefaultMask = useExternalDefaultMask(ip);

    const state = useLocalStore(() => ({
        get isImpossibleToAddMask() {
            const values = form && form.values() && Object.values(form.values());
            return values && values.includes("");
        }
    }));

    useEffect(() => {
        (async () => {
            await shareStore.fetchDefaultsMasks();
            form && form.each(field => form.del(field.key));
            shareMasks && shareMasks.forEach(mask => createField(mask));
            form && form.reset();
        })();
    }, []);

    const createField = mask => {
        if (form) {
            const name = getUniqueName();
            form.add({ name, default: mask ? mask : "" });
            form.$(name).reset();
        }
    };

    const onAddMaskClick = () => {
        createField();
    };

    const getUniqueName = () => {
        return `field_${Math.floor(Math.random() * Date.now())}`;
    };

    const removeMask = key => () => {
        form && form.del(key);
    };

    return useObserver(() => (
        <Grid container direction={"column"} className={classes.masksContainer}>
            <Grid item>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow className={classes.headRow}>
                            <TableCell>{t("share.edit_share.table_header.file_mask")}</TableCell>
                            <TableCell>{t("share.edit_share.table_header.type")}</TableCell>
                            <TableCell align={"right"}>
                                {isEditable ? (
                                    <ButtonBase
                                        disabled={state.isImpossibleToAddMask}
                                        onClick={onAddMaskClick}
                                        id={`${FILE_MASKS_TABLE_ID_PREFIX}_add_mask`}
                                    >
                                        <PlusIcon />
                                    </ButtonBase>
                                ) : null}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {commonDefaultMask.isList ? (
                            <React.Fragment>
                                {commonDefaultMask.list?.map(mask => (
                                    <TableRow className={classes.row} key={mask}>
                                        <TableCell>
                                            <TextInput value={mask} disabled />
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{t("share.edit_share.table.mask_type.global")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {isEditable && !form.size ? (
                                    <TableRow className={classes.row}>
                                        <TableCell colSpan={3} align="center">
                                            <Typography>{t("share.edit_share.list_mask.empty")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : null}
                            </React.Fragment>
                        )}
                        {isEditable && form ? (
                            <React.Fragment>
                                {form?.map(field => (
                                    <TableRow className={classes.row} key={field.key}>
                                        <TableCell>
                                            <MobxFormTextInput
                                                field={field}
                                                id={`${FILE_MASKS_TABLE_ID_PREFIX}_mask_${field.key}_value`}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{t("share.edit_share.table.mask_type.local")}</Typography>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <ButtonBase
                                                onClick={removeMask(field.key)}
                                                className={classes.deleteButtonContainer}
                                                id={`${FILE_MASKS_TABLE_ID_PREFIX}_mask_${field.key}_remove`}
                                            >
                                                <TrashIcon />
                                            </ButtonBase>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ) : null}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    ));
};

export default FileMasksTable;
