import { useStore } from "hooks/useStore";

export const useFileNavigatorStoreByIp = ip => {
    const {
        store: { fileNavigatorStore }
    } = useStore();

    return fileNavigatorStore.getEvo(ip);
};

export const useFileNavigatorStoreCommon = () => {
    const {
        store: { fileNavigatorStore }
    } = useStore();
    return fileNavigatorStore;
};
