import config from "config";

export const getChartAPIUrl = ({
    chart,
    points = 40,
    after = -600,
    group = "average",
    format = "json",
    path = config.path,
    protocol = config.protocol
}) =>
    `${protocol}://${path}:${config.netdataPort}/gateway/netdata/api/v1/data?chart=${chart}&after=${after}&points=${points}&group=${group}&format=${format}&options=seconds&options=jsonwrap&options=nonzero&options=null2zero`;
