import React from "react";

const MultipleEvoIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill="#FFF" fillRule="nonzero">
                    <g>
                        <path
                            d="M18 1c.552 0 1 .448 1 1v2h2.1c1.025 0 1.824.836 1.895 1.853L23 6v15c0 1.033-.752 1.916-1.755 1.994L21.1 23H4.9c-1.025 0-1.824-.836-1.895-1.853L3 21v-1.968h2V21h16V6h-2v12c0 .552-.448 1-1 1H2c-.552 0-1-.448-1-1V2c0-.552.448-1 1-1h16zm-1 10H3v6h14v-6zM6 13c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zM17 3H3v6h14V3zM6 5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
                            transform="translate(-420.000000, -296.000000) translate(420.000000, 296.000000)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default MultipleEvoIcon;
