import { types } from "mobx-state-tree";

const AutotaggingMachineConfig = types.model({
    json: types.maybe(
        types.model({
            defaults: types.model({
                confidence: types.maybe(types.number),
                limit: types.maybe(types.number),
                s3_alias: types.maybe(types.string),
                transcoding: types.maybe(types.boolean),
                transcoding_folder: types.maybe(types.string),
                transcoding_folder_expiration: types.maybe(types.number),
                transcoding_preset: types.maybe(types.number)
            }),
            service_endpoint: types.maybe(
                types.model({
                    region: types.maybe(types.string),
                    user: types.maybe(types.string),
                    metadata: types.maybe(types.string)
                })
            )
        })
    )
});
export default AutotaggingMachineConfig;
