import React from "react";

const ShareCheckedIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(1 2)">
                    <path
                        fill="#6CB6FF"
                        stroke="#A4E1FF"
                        d="M20 .5h-5.764c-.284 0-.556.08-.789.224-.232.144-.426.351-.553.605L11.31 4.5H2c-.414 0-.79.168-1.06.44C.667 5.21.5 5.585.5 6v12c0 .414.168.79.44 1.06.27.272.646.44 1.06.44h18c.414 0 .79-.168 1.06-.44.272-.27.44-.646.44-1.06V2c0-.414-.168-.79-.44-1.06C20.79.667 20.415.5 20 .5z"
                    />
                    <rect width="21" height="15" x=".5" y="4.5" fill="#85C3FF" stroke="#B2E1FE" rx="2" />
                    <path
                        fill="#1E90FF"
                        fillRule="nonzero"
                        d="M11 6c1.105 0 2 .895 2 2s-.895 2-2 2c-.562 0-1.07-.232-1.432-.604L5.93 11.474c.046.168.07.344.07.526 0 .182-.024.36-.07.527l3.637 2.078C9.93 14.232 10.437 14 11 14c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2c0-.182.024-.358.07-.526l-3.637-2.08C5.07 13.769 4.563 14 4 14c-1.105 0-2-.895-2-2s.895-2 2-2c.562 0 1.07.232 1.433.605L9.07 8.527C9.024 8.359 9 8.182 9 8c0-1.105.895-2 2-2zm0 9c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm-7-4c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm7-4c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
                    />
                </g>
                <g transform="translate(12 12)">
                    <circle cx="6" cy="6" r="5.5" fill="#21C97E" stroke="#3BEB9C" />
                    <path
                        fill="#FFF"
                        fillRule="nonzero"
                        d="M7.762 3.908c.39-.39 1.023-.39 1.414 0 .36.361.388.928.083 1.32l-.083.095-3 3c-.36.36-.928.388-1.32.083l-.094-.083-1.5-1.5c-.39-.39-.39-1.024 0-1.415.36-.36.927-.388 1.32-.083l.094.083.792.793 2.294-2.293z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default ShareCheckedIcon;
