import React from "react";
import Dialog from "components/Dialog";
import { Typography, Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react";
import useStoreByIp from "hooks/useStoreByIp";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const QUESTION_ASSIGN_MODAL_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_question_assign_modal`;

const QuestionAssignModal = ({ open, onClose, onConfirm, ip, dropLastCreatedShareType, type }) => {
    const { t } = useTranslation();

    const { shareStore } = useStoreByIp({ ip });

    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    const handleCancel = () => {
        dropLastCreatedShareType();
        onClose();
    };

    const shareName = shareStore.currentShareNameWithPrefix || shareStore.currentCheckedShareNameWithPrefix || "";

    return useObserver(() => (
        <Dialog
            title={
                type !== "ftp"
                    ? t("share.question_modal.assign_users_and_groups.title")
                    : t("share.question_modal.assign_users.title")
            }
            withoutIconClose
            withoutDividers
            open={open}
            onClose={handleCancel}
            submitBtn={
                <Button
                    onClick={handleConfirm}
                    variant={"contained"}
                    color={"primary"}
                    id={`${QUESTION_ASSIGN_MODAL_ID_PREFIX}_yes`}
                >
                    {t("common.button.yes")}
                </Button>
            }
            buttons={
                <Button
                    onClick={handleCancel}
                    variant={"contained"}
                    color={"secondary"}
                    id={`${QUESTION_ASSIGN_MODAL_ID_PREFIX}_no`}
                >
                    {t("common.button.no")}
                </Button>
            }
        >
            <Grid container direction={"column"} spacing={6}>
                <Grid item>
                    <Typography>
                        {t("share.question_modal.assign_users_and_groups.text", {
                            shareName: shareName,
                        })}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography>
                        {type !== "ftp"
                            ? t("share.question_modal.assign_users_and_groups.question")
                            : t("share.question_modal.assign_users.question")}
                    </Typography>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default QuestionAssignModal;
