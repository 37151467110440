import React from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./CredentialsCard.styles";
import { useTranslation } from "react-i18next";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { WEB_ACCESS_CREDENTIALS_CARD } from "const/widgetNameConst";
import { useObserver, useLocalStore } from "mobx-react";
import AccessCredentialsDialog from "./components/AccessCredentialsDialog";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const CREDENTIALS_CARD_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_credentials_card`;

const CredentialsCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const state = useLocalStore(() => ({
        isAccessCredentialsDialogOpen: false
    }));

    const openAccessCredentialsDialog = () => {
        state.isAccessCredentialsDialogOpen = true;
    };

    const closeAccessCredentialsDialog = () => {
        state.isAccessCredentialsDialogOpen = false;
    };

    return useObserver(() => (
        <>
            <DataReadonlyCard
                title={t("admin_settings.web_access_credentials.title")}
                headerDivider
                onClick={openAccessCredentialsDialog}
                control={
                    <MoreMenu id={`${CREDENTIALS_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={WEB_ACCESS_CREDENTIALS_CARD} />
                    </MoreMenu>
                }
            >
                <Grid container direction={"column"} spacing={4}>
                    <Grid item container alignItems={"center"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("admin_settings.web_access_credentials.label.current_login")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldText}>*****</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("admin_settings.web_access_credentials.label.current_password")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldText}>********</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DataReadonlyCard>
            <AccessCredentialsDialog open={state.isAccessCredentialsDialogOpen} onClose={closeAccessCredentialsDialog} />
        </>
    ));
};

export default CredentialsCard;
