import { types } from "mobx-state-tree";

const TransportingConfig = types.model({
    json: types.maybe(
        types.model({
            queuePollInterval: types.maybe(types.number),
            smartTransferPollInterval: types.maybe(types.number),
            retryLimit: types.maybe(types.number),
            smbEnabled: types.maybe(types.boolean),
            smbMaxProto: types.maybe(types.string),
            modifyWindow: types.maybe(types.number),
            logStorage: types.model({
                share: types.maybe(types.string),
                folder: types.maybe(types.string),
                expired: types.maybe(types.number),
                archived: types.maybe(types.number)
            }),
            pipeCloseTimeout: types.maybe(types.number)
        })
    )
});
export default TransportingConfig;
