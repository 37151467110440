import { types } from "mobx-state-tree";

import Request from "api/Request";
import UnregisterPortArguments from "api/activation/interfaces/Types/UnregisterPortArguments";

const UnregisterPort = Request.props({
    path: "/com/studionetworksolutions/evo/activation/NetInterfaces",
    method: "unregister_port",
    arguments: types.maybe(UnregisterPortArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default UnregisterPort;
