import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { useStyles } from "./LogicalDisks.style";
import {
    Paper,
    Divider,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    ButtonBase,
    Button,
    Typography,
} from "@material-ui/core";
import Checkbox from "components/Checkbox";
import CardHeader from "components/CardHeader";
import MoreMenu from "components/MoreMenu";
import AddDiskDialog from "../AddDiskDialog";
import { useTranslation } from "react-i18next";
import NoDiskGroupIcon from "assets/NoDiskGroupIcon";
import NoContentContainer from "../NoContentContainer";
import LogicalDisksRow from "./LogicalDisksRow";
import TableSortLabel from "components/TableSortLabel";
import { DISKS_VOLUME_NAME, DISKS_RAID, DISKS_SIZE, DISKS_STATUS_HEALTH, DISKS_STATUS_STRING } from "const/sortColumnConst";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import WarningDialog from "components/WarningDialog";
import { LOGICAL_DISKS } from "const/widgetNameConst";
import useStoreByIp from "hooks/useStoreByIp";
import useEvoPrefix from "hooks/useEvoPrefix";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import HideOverlay from "components/HideOverlay/HideOverlay";
import DestructiveMenuItem from "components/DestructiveMenuItem/DestructiveMenuItem";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import PauseIcon from "assets/PauseIcon";
import PlayIcon from "assets/PlayIcon";
import PlusIcon from "assets/PlusIcon";
import { autorun } from "mobx";

const LOGICAL_DISKS_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_logical_disks`;

const LogicalDisks = ({ ip }) => {
    const { t } = useTranslation();
    const evoPrefix = useEvoPrefix({ ip });
    const {
        store: { volumeDrawerStore },
    } = useStore();
    const { volumeStore, poolsStore, bcacheStore } = useStoreByIp({ ip });

    const state = useLocalStore(() => ({
        volumes: [],
        queue: [],
        isAddDiskDialogOpen: false,
        get isPaused() {
            return volumeStore.queue && volumeStore.queue.paused;
        },
        get nonEmptyQueue() {
            return volumeStore.queueVolumes && volumeStore.queueVolumes.length !== 0;
        },
        get mainCheckboxStatus() {
            if (
                (volumeStore.volumes && volumeStore.volumes.length) +
                    (volumeStore.queueVolumes && volumeStore.queueVolumes.length) ===
                volumeStore.checkedVolumes.length + volumeStore.checkedQueueVolumes.length
            ) {
                return "checked";
            } else if (
                (volumeStore.volumes && volumeStore.volumes.length) +
                    (volumeStore.queueVolumes && volumeStore.queueVolumes.length) >
                    volumeStore.checkedVolumes.length + volumeStore.checkedQueueVolumes.length &&
                (volumeStore.checkedVolumes.length > 0 || volumeStore.checkedQueueVolumes.length > 0)
            ) {
                return "indeterminated";
            }
            return null;
        },
    }));

    const warningModal = useModal();
    const classes = useStyles({ isPaused: state.isPaused });

    useEffect(() => {
        (async () => {
            await volumeStore.fetchVolumes();
            volumeStore.volumes &&
                (state.volumes = volumeStore.volumes.map((volume) => {
                    return { checked: false, pool: volume.volumeId.pool, volume: volume.volumeId.volume };
                }));
        })();
        (async () => {
            await volumeStore.fetchQueue();
            volumeStore.queueVolumes &&
                (state.queue = volumeStore.queueVolumes.map((queueVolume) => {
                    return { checked: false, pool: queueVolume.volumeId.pool, volume: queueVolume.volumeId.volume };
                }));
        })();
        (() => {
            bcacheStore.fetchEnabled();
        })();
        (() => {
            bcacheStore.fetchDrives();
        })();
        (() => {
            volumeStore.fetchDefragEnabled();
        })();
    }, []);

    const openAddDiskDialog = () => {
        state.isAddDiskDialogOpen = true;
    };
    const closeAddDiskDialog = () => {
        state.isAddDiskDialogOpen = false;
    };
    const setupPortName = (pool, volume) => () => {
        if (volumeStore.checkedVolumes.length > 0 || volumeStore.checkedQueueVolumes.length > 0) return;
        volumeDrawerStore.dropOthersCurrentPorts(ip);
        volumeStore.setCurrentPortName(pool, volume);
    };

    const startQueue = () => {
        volumeStore.startQueue({ notifyOnCompletion: false });
    };

    const isCheckedVolume = (pool, volume) => {
        return volumeStore.checkedVolumes.some((volumeId) => volumeId.pool === pool && volumeId.volume === volume);
    };
    const isCheckedQueueVolume = (pool, volume) => {
        return volumeStore.checkedQueueVolumes.some((volumeId) => volumeId.pool === pool && volumeId.volume === volume);
    };

    const onVolumeCheck = (pool, volume) => (e) => {
        e.stopPropagation();

        const isChecked = isCheckedVolume(pool, volume);
        isChecked ? volumeStore.removeCheckedVolume({ pool, volume }) : volumeStore.addCheckedVolume({ pool, volume });
    };

    const onQueueVolumeCheck = (pool, volume) => (e) => {
        e.stopPropagation();

        const isChecked = isCheckedQueueVolume(pool, volume);
        isChecked ? volumeStore.removeCheckedQueueVolume({ pool, volume }) : volumeStore.addCheckedQueueVolume({ pool, volume });
    };

    const handleMainCheckbox = () => {
        switch (state.mainCheckboxStatus) {
            case "checked":
            case "indeterminated":
                volumeStore.clearCheckedVolumes();
                volumeStore.clearCheckedQueueVolumes();
                break;
            default:
                volumeStore.queueVolumes.forEach((volume) =>
                    volumeStore.addCheckedQueueVolume({ pool: volume.volumeId.pool, volume: volume.volumeId.volume })
                );
                volumeStore.volumes.forEach((volume) =>
                    volumeStore.addCheckedVolume({ pool: volume.volumeId.pool, volume: volume.volumeId.volume })
                );
                break;
        }
    };
    const clearQueue = async () => {
        volumeStore.clearQueue();
        warningModal.close();
    };
    const getRestMenuItems = () => {
        if (!poolsStore.sortedPools?.length) return [];
        const menuItemArray = [];

        if (state.nonEmptyQueue) {
            menuItemArray.push(
                <DestructiveMenuItem key={"clearQueue"} onClick={warningModal.open} id={`${LOGICAL_DISKS_ID_PREFIX}_clear_queue`}>
                    {t("disks_and_pools.logical_disks.main_context_menu.clear_queue")}
                </DestructiveMenuItem>
            );
        }

        return menuItemArray;
    };

    const restMenuItems = getRestMenuItems();

    const createMenu = () => {
        return [
            state.nonEmptyQueue && (
                <Button
                    className={classes.button}
                    id={state.isPaused ? `${LOGICAL_DISKS_ID_PREFIX}_start_queue` : `${LOGICAL_DISKS_ID_PREFIX}_pause_queue`}
                    startIcon={state.isPaused ? <PlayIcon /> : <PauseIcon />}
                    variant="contained"
                    color={state.isPaused ? "primary" : "secondary"}
                    onClick={state.isPaused ? startQueue : volumeStore.pauseQueue}
                    key={`logical_disks-control-1`}
                >
                    {state.isPaused
                        ? t("disks_and_pools.logical_disks.main_context_menu.start_queue")
                        : t("disks_and_pools.logical_disks.main_context_menu.pause_queue")}
                </Button>
            ),
            <HideOverlay show={!ip} key={`logical_disks-control-2`}>
                <MoreMenu id={`${LOGICAL_DISKS_ID_PREFIX}_more_menu`}>
                    <HideOverlay show={!ip}>
                        <HomeCardActionMenuItem
                            homeCardActionMenuItemId={`${LOGICAL_DISKS_ID_PREFIX}_add_to_home`}
                            widget={LOGICAL_DISKS}
                        />
                    </HideOverlay>
                    {restMenuItems}
                </MoreMenu>
            </HideOverlay>,
            <ButtonBase
                id={`${LOGICAL_DISKS_ID_PREFIX}_open_add_disk_dialog`}
                onClick={openAddDiskDialog}
                disabled={!poolsStore.sortedPools?.length}
                key={`logical_disks-control-3`}
            >
                <PlusIcon />
            </ButtonBase>,
        ];
    };
    const changeSorting = (column) => () => volumeStore.changeSorting(column);

    useEffect(() => {
        autorun(() => {
            volumeStore.checkedQueueVolumes.forEach(({ volume, pool }) => {
                const candidate = volumeStore.sortedVolumes.find(
                    ({ volumeId }) => volumeId.volume === volume && volumeId.pool === pool
                );

                if (!candidate) {
                    return;
                }

                const isChecked = isCheckedQueueVolume(pool, volume);

                if (!candidate.isQueue && isChecked) {
                    volumeStore.removeCheckedQueueVolume({ pool, volume });
                    volumeStore.addCheckedVolume({ pool, volume });
                }
            });
        });
    }, []);

    return (
        <Paper className={classes.container}>
            <CardHeader title={`${evoPrefix}${t("disks_and_pools.logical_disks.title")}`} control={createMenu()} />
            <Divider />
            {(volumeStore.volumes && volumeStore.volumes.length !== 0) || state.nonEmptyQueue ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <Checkbox
                                    id={`${LOGICAL_DISKS_ID_PREFIX}_table_header_main_checkbox`}
                                    indeterminate={state.mainCheckboxStatus === "indeterminated"}
                                    checked={state.mainCheckboxStatus === "checked"}
                                    onChange={handleMainCheckbox}
                                />
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    id={`${LOGICAL_DISKS_ID_PREFIX}_table_header_sort_name`}
                                    direction={volumeStore.order}
                                    onClick={changeSorting(DISKS_VOLUME_NAME)}
                                    active={volumeStore.orderBy === DISKS_VOLUME_NAME}
                                >
                                    {t("disks_and_pools.logical_disks.table_header.name")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    id={`${LOGICAL_DISKS_ID_PREFIX}_table_header_sort_size`}
                                    direction={volumeStore.order}
                                    onClick={changeSorting(DISKS_SIZE)}
                                    active={volumeStore.orderBy === DISKS_SIZE}
                                >
                                    {t("disks_and_pools.logical_disks.table_header.size")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    id={`${LOGICAL_DISKS_ID_PREFIX}_table_header_sort_order`}
                                    direction={volumeStore.order}
                                    onClick={changeSorting(DISKS_RAID)}
                                    active={volumeStore.orderBy === DISKS_RAID}
                                >
                                    {t("disks_and_pools.logical_disks.table_header.raid")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    id={`${LOGICAL_DISKS_ID_PREFIX}_table_header_sort_health`}
                                    direction={volumeStore.order}
                                    onClick={changeSorting(DISKS_STATUS_HEALTH)}
                                    active={volumeStore.orderBy === DISKS_STATUS_HEALTH}
                                >
                                    {t("disks_and_pools.logical_disks.table_header.health")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    id={`${LOGICAL_DISKS_ID_PREFIX}_table_header_sort_status`}
                                    direction={volumeStore.order}
                                    onClick={changeSorting(DISKS_STATUS_STRING)}
                                    active={volumeStore.orderBy === DISKS_STATUS_STRING}
                                >
                                    {t("disks_and_pools.logical_disks.table_header.status")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {volumeStore.sortedVolumes.map((volume) => {
                            if (volume.isQueue) {
                                return (
                                    <LogicalDisksRow
                                        id={`${LOGICAL_DISKS_ID_PREFIX}_volume_${volume.volumeId.volume}`}
                                        key={volume.volumeName}
                                        volume={volume}
                                        onCheck={onQueueVolumeCheck(volume.volumeId.pool, volume.volumeId.volume)}
                                        checked={isCheckedQueueVolume(volume.volumeId.pool, volume.volumeId.volume)}
                                        setupPortName={setupPortName(volume.volumeId.pool, volume.volumeId.volume)}
                                        dropPortName={volumeStore.dropCurrentPortName}
                                        currentPortName={volumeStore.currentPortName}
                                        isStartingQueue={!state.isPaused}
                                    />
                                );
                            }
                            return (
                                <LogicalDisksRow
                                    id={`${LOGICAL_DISKS_ID_PREFIX}_volume_${volume.volumeId.volume}`}
                                    key={volume.volumeName}
                                    volume={volume}
                                    onCheck={onVolumeCheck(volume.volumeId.pool, volume.volumeId.volume)}
                                    checked={isCheckedVolume(volume.volumeId.pool, volume.volumeId.volume)}
                                    setupPortName={setupPortName(volume.volumeId.pool, volume.volumeId.volume)}
                                    dropPortName={volumeStore.dropCurrentPortName}
                                    currentPortName={volumeStore.currentPortName}
                                    isStartingQueue={!state.isPaused}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <NoContentContainer
                    id={`${LOGICAL_DISKS_ID_PREFIX}_no_content`}
                    title={t("disks_and_pools.no_content.disk_not_created")}
                    content={
                        poolsStore.sortedPools && poolsStore.sortedPools.length
                            ? t("disks_and_pools.no_content.create_first_disk")
                            : t("disks_and_pools.no_content.create_pool_first")
                    }
                    hideButton={!(poolsStore.sortedPools && poolsStore.sortedPools.length)}
                    buttonText={t("disks_and_pools.no_content.button.add_disk")}
                    icon={<NoDiskGroupIcon />}
                    onClick={openAddDiskDialog}
                />
            )}
            <WarningDialog
                title={t("common.warning_dialog.title")}
                open={warningModal.isOpen}
                submitBtnLabel={t("disks_and_pools.logical_disks.warning_dialog.clear_queue.button.confirm")}
                cancelBtnLabel={t("disks_and_pools.logical_disks.warning_dialog.clear_queue.button.cancel")}
                onClose={warningModal.close}
                submit={clearQueue}
                content={
                    <>
                        <Typography>{t("disks_and_pools.logical_disks.warning_dialog.clear_queue.text")}</Typography>
                        <Typography>{t("disks_and_pools.logical_disks.warning_dialog.clear_queue.question")}</Typography>
                    </>
                }
            />
            <AddDiskDialog open={state.isAddDiskDialogOpen} handleClose={closeAddDiskDialog} ip={ip} />
        </Paper>
    );
};

export default observer(LogicalDisks);
