import i18n from "i18n";
import { NOT_ONLY_NUMBERS, VALID_HOSTNAME_REGEX, REGEX_IPV4 } from "const";

const isSingleIpHostRule = {
    isSingleIpHost: {
        function: (value) => {
            return (value.match(VALID_HOSTNAME_REGEX) && value.match(NOT_ONLY_NUMBERS)) || value.match(REGEX_IPV4);
        },
        message: i18n.t("rule.single_host_or_ip.format"),
    },
};

export default isSingleIpHostRule;
