import React from "react";
import ReactDOM from "react-dom";
import "typeface-roboto";
import CssBaseline from "@material-ui/core/CssBaseline";
import App from "./entry/App";
import byteSize from "byte-size";
import config from "config";
import "./i18n";
import { IEC_STANDARD, METRIC_STANDARD } from "const/diskSizeConst";
import WrapperThemeContext from "theme/themeContext";
byteSize.defaultOptions({
    units: config.decimalSizeUnits ? METRIC_STANDARD : IEC_STANDARD,
});
ReactDOM.render(
    <WrapperThemeContext>
        <CssBaseline />
        <App />
    </WrapperThemeContext>,
    document.getElementById("root")
);
