const createFields = () => {
    return [
        {
            name: "path"
        },
        { name: "brickName" }
    ];
};

export default createFields;
