export const localeEnglish = {
    label: "English",
    value: "en-US"
};

export const localeMandarin = {
    label: "简体中文",
    value: "zh-CN"
};

export const localeOptions = [localeEnglish, localeMandarin];
