import React from "react";

const CheckboxDisabledIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#D9E4ED"
                fillRule="nonzero"
                d="M20 0c2.21 0 4 1.79 4 4v16c0 2.21-1.79 4-4 4H4c-2.21 0-4-1.79-4-4V4c0-2.21 1.79-4 4-4h16z"
                opacity="0.4"
            />
        </svg>
    );
};

export default CheckboxDisabledIcon;
