import React, { useContext, useEffect } from "react";
import { useObserver } from "mobx-react";
import { ButtonBase, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import DataCard from "components/DataCard";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import MoreMenu from "components/MoreMenu";
import TableSortLabel from "components/TableSortLabel";
import ResetIcon from "assets/ResetIcon";
import { SENSORS_CARD } from "const/widgetNameConst";
import { HARDWARE_PROFILE_SENSORS_NAME, HARDWARE_PROFILE_SENSORS_VALUE } from "const/sortColumnConst";

const SensorsCard = () => {
    const { t } = useTranslation();

    const {
        store: { hardwareProfileStore }
    } = useContext(Context);
    useEffect(() => {
        hardwareProfileStore.fetchSensorsInfo();
    }, []);
    const changeSorting = column => () => hardwareProfileStore.changeSortingSensor(column);

    const refreshRequest = () => {
        hardwareProfileStore.fetchSensorsInfo();
    };

    return useObserver(() => (
        <DataCard
            title={t("hardware_profile.sensors_card.title")}
            autoHeight
            emptyPadding
            headerDivider
            headerControl={[
                <MoreMenu key={`data_card-header_control-control-1`}>
                    <HomeCardActionMenuItem widget={SENSORS_CARD} />
                </MoreMenu>,
                <ButtonBase key={`data_card-header_control-control-2`}>
                    <ResetIcon onClick={refreshRequest} />
                </ButtonBase>
            ]}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                onClick={changeSorting(HARDWARE_PROFILE_SENSORS_NAME)}
                                direction={hardwareProfileStore.orderSensor}
                                active={hardwareProfileStore.orderBySensor === HARDWARE_PROFILE_SENSORS_NAME}
                            >
                                {t("hardware_profile.sensors.header.name")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell width={"20%"}>
                            <TableSortLabel
                                onClick={changeSorting(HARDWARE_PROFILE_SENSORS_VALUE)}
                                direction={hardwareProfileStore.orderSensor}
                                active={hardwareProfileStore.orderBySensor === HARDWARE_PROFILE_SENSORS_VALUE}
                            >
                                {t("hardware_profile.sensors.header.value")}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {hardwareProfileStore.sortedSensors.map(row => {
                        return (
                            <TableRow key={Math.random()}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                    {row.speed || row.speed === 0 ? `${row.speed}RPM` : `${row.temperature}\xB0C`}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </DataCard>
    ));
};

export default SensorsCard;
