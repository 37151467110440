import React from "react";
import { useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./SearchResultDrawer.styles";
import { Grid, Typography } from "@material-ui/core";
import TrashIcon from "assets/TrashIcon";
import LocalGroupIcon from "assets/LocalGroupIcon";
import LocalUserIcon from "assets/LocalUserIcon";
import ExternalGroupIcon from "assets/ExternalGroupIcon";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import ResetPasswordDialog from "../ResetPasswordDialog";
import UserMembershipModal from "../UserMembershipModal";
import AssignSharesModal from "../AssignSharesModal";
import CustomizeSettingsModal from "../CustomizeSettingsModal";
import EditLocalGroupMembersModal from "../EditLocalGroupMembersModal";
import EditUsersGroupsModal from "../EditUsersGroupsModal";
import ResetIcon from "assets/ResetIcon";
import BookIcon from "assets/BookIcon";
import SharingIcon from "assets/SharingIcon";
import GroupMembersModal from "../GroupMembersModal";
import DetailActionsDrawer from "components/DetailsActionsDrawer";
import EditIcon from "assets/EditIcon";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";
import ConfirmationDialog from "components/ConfirmationDialog";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import { observer } from "mobx-react-lite";
import PlusIcon from "assets/PlusIcon";
import AddToGroupDialog from "../AddToGroupDialog/AddToGroupDialog";

const SEARCH_RESULT_DRAWER_ID_PREFIX = "search_result_drawer";
const drawerName = "SearchResultDrawer";

const SearchResultDrawer = ({ parentId }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const idPrefix = parentId ? `${parentId}_${SEARCH_RESULT_DRAWER_ID_PREFIX}` : SEARCH_RESULT_DRAWER_ID_PREFIX;
    const {
        store: { userStore, uiStore, multiLdapStore },
    } = useStore();

    const modalsList = [
        "resetPasswordModal",
        "userMembershipModal",
        "assignSharesModal",
        "groupMembersModal",
        "customizeSettingsModal",
        "editLocalGroupMembersModal",
        "editUsersGroupsModal",
        "deleteUserModal",
        "deleteGroupModal",
        "addToGroupModal",
    ];

    const {
        resetPasswordModal,
        userMembershipModal,
        assignSharesModal,
        groupMembersModal,
        customizeSettingsModal,
        editLocalGroupMembersModal,
        editUsersGroupsModal,
        deleteUserModal,
        deleteGroupModal,
        closeModalAll,
        addToGroupModal,
    } = useModalCombine(modalsList);

    const state = useLocalStore(() => ({
        deleteUserInProgress: false,
        deleteGroupInProgress: false,
    }));

    useDrawerOpenHandler({ drawerName, targetDep: userStore.currentEntity?.id?.name + userStore.currentEntity?.id?.domain });

    const onClose = () => {
        closeModalAll();
        userStore.dropCurrentEntity();
    };

    const openCustomizeSettingsModal = ({ shareName, ip }) => {
        assignSharesModal.close();
        multiLdapStore.setCurrentShareName({ shareName, ip });
        multiLdapStore.dropOthersCurrentShareNames(ip);
        customizeSettingsModal.open();
    };

    const closeCustomizeSettingsModal = () => {
        customizeSettingsModal.close();
        multiLdapStore.dropAllCurrentShareNames();
    };

    const onCustomizeSettingsModalBack = () => {
        closeCustomizeSettingsModal();
        assignSharesModal.open();
    };

    const refreshRequest = () => {
        userStore.searchOnUsersPage();
    };

    const deleteUser = async () => {
        deleteUserModal.close();
        state.deleteUserInProgress = true;
        const result = await userStore.deleteUser({
            name: userStore.currentEntity.id.name,
            force: true,
        });
        state.deleteUserInProgress = false;

        if (result) {
            userStore.dropCurrentEntitySingleBar();
            userStore.dropCurrentEntity();
            uiStore.closeDrawer();
        }

        refreshRequest();
    };

    const deleteGroup = async () => {
        deleteGroupModal.close();
        state.deleteGroupInProgress = true;
        const result = await userStore.deleteGroup({
            name: userStore.currentEntity.id.name,
            force: true,
        });

        state.deleteGroupInProgress = false;

        if (result) {
            userStore.dropCurrentEntitySingleBar();
            userStore.dropCurrentEntity();
            uiStore.closeDrawer();
        }

        refreshRequest();
    };

    const getIcon = () => {
        if (userStore.currentEntity?.isGroup) {
            return userStore.currentEntity?.isExternal ? <ExternalGroupIcon /> : <LocalGroupIcon />;
        } else {
            return <LocalUserIcon />;
        }
    };

    const renderActions = () => {
        if (!userStore.currentEntity) return null;

        if (userStore.currentEntity.isGroup) {
            return userStore.currentEntity.isExternal ? (
                <>
                    <Grid item>
                        <BulkBarFlatButton onClick={groupMembersModal.open} icon={<BookIcon />}>
                            {t("users.side_menu.actions.button.list_members")}
                        </BulkBarFlatButton>
                    </Grid>
                    <Grid item>
                        <BulkBarFlatButton onClick={assignSharesModal.open} icon={<SharingIcon />}>
                            {t("users.side_menu.actions.button.assign_shares")}
                        </BulkBarFlatButton>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item>
                        <BulkBarFlatButton
                            onClick={deleteGroupModal.open}
                            errorColor
                            icon={<TrashIcon />}
                            inProgress={state.deleteGroupInProgress}
                            id={`${idPrefix}_open_delete_group_modal`}
                        >
                            {t("common.button.delete")}
                        </BulkBarFlatButton>
                    </Grid>
                    <Grid item>
                        <BulkBarFlatButton
                            onClick={editLocalGroupMembersModal.open}
                            icon={<EditIcon />}
                            id={`${idPrefix}_open_edit_local_group_members_modal`}
                        >
                            {t("users.side_menu.actions.button.edit_members")}
                        </BulkBarFlatButton>
                    </Grid>
                    <Grid item>
                        <BulkBarFlatButton
                            onClick={assignSharesModal.open}
                            icon={<SharingIcon />}
                            id={`${idPrefix}_open_assign_shares_modal`}
                        >
                            {t("users.side_menu.actions.button.assign_shares")}
                        </BulkBarFlatButton>
                    </Grid>
                </>
            );
        } else {
            return userStore.currentEntity.isExternal ? (
                <>
                    <Grid item>
                        <BulkBarFlatButton
                            onClick={userMembershipModal.open}
                            icon={<BookIcon />}
                            id={`${idPrefix}_open_user_membership_modal`}
                        >
                            {t("users.side_menu.actions.button.list_groups")}
                        </BulkBarFlatButton>
                    </Grid>
                    <Grid item>
                        <BulkBarFlatButton
                            onClick={assignSharesModal.open}
                            icon={<SharingIcon />}
                            id={`${idPrefix}_open_assign_shares_modal`}
                        >
                            {t("users.side_menu.actions.button.assign_shares")}
                        </BulkBarFlatButton>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item>
                        <BulkBarFlatButton
                            onClick={deleteUserModal.open}
                            errorColor
                            icon={<TrashIcon />}
                            inProgress={state.deleteUserInProgress}
                            id={`${idPrefix}_open_delete_user_modal`}
                        >
                            {t("common.button.delete")}
                        </BulkBarFlatButton>
                    </Grid>
                    <Grid item>
                        <BulkBarFlatButton
                            onClick={resetPasswordModal.open}
                            icon={<ResetIcon />}
                            id={`${idPrefix}_open_reset_password_modal`}
                        >
                            {t("users.reset_password.button.reset_password")}
                        </BulkBarFlatButton>
                    </Grid>
                    <Grid item>
                        <BulkBarFlatButton
                            onClick={editUsersGroupsModal.open}
                            icon={<EditIcon />}
                            id={`${idPrefix}_open_edit_users_group_modal`}
                        >
                            {t("users.side_menu.actions.button.edit_users_groups")}
                        </BulkBarFlatButton>
                    </Grid>
                    <Grid item>
                        <BulkBarFlatButton
                            onClick={assignSharesModal.open}
                            icon={<SharingIcon />}
                            id={`${idPrefix}_open_assign_shares_modal`}
                        >
                            {t("users.side_menu.actions.button.assign_shares")}
                        </BulkBarFlatButton>
                    </Grid>
                    <Grid item>
                        <BulkBarFlatButton
                            onClick={addToGroupModal.open}
                            icon={<PlusIcon />}
                            id={`${idPrefix}_open_add_to_group_modal`}
                        >
                            {t("users.bulk_operations_bar.button.add_to_group")}
                        </BulkBarFlatButton>
                    </Grid>
                </>
            );
        }
    };
    const renderDetails = () => {
        if (!userStore.currentEntity) return null;
        return (
            <Grid container direction={"column"} wrap={"nowrap"} className={classes.detailsContainer} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("users.side_menu.details.label.name")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{userStore.currentEntity.id.name}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"}>
                    <Grid item xs={6}>
                        <Typography>{t("users.side_menu.details.label.type")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{userStore.currentEntity.typeLabel}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    const drawerCloseHandler = () => {
        if (uiStore.openedDrawer !== drawerName) {
            state.showDetails = true;
        }
    };

    return (
        <DetailActionsDrawer
            drawerName={drawerName}
            onAnimationEnd={drawerCloseHandler}
            title={userStore.currentEntity?.id.name}
            icon={getIcon()}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={onClose}
            id={`${idPrefix}_detail_actions_drawer`}
            modals={
                userStore.currentEntity && (
                    <>
                        <UserMembershipModal
                            open={userMembershipModal.isOpen}
                            onClose={userMembershipModal.close}
                            parentId={idPrefix}
                        />
                        <ResetPasswordDialog
                            open={resetPasswordModal.isOpen}
                            onClose={resetPasswordModal.close}
                            parentId={idPrefix}
                        />
                        <AssignSharesModal
                            open={assignSharesModal.isOpen}
                            onClose={assignSharesModal.close}
                            openCustom={openCustomizeSettingsModal}
                            parentId={idPrefix}
                        />
                        <GroupMembersModal
                            open={groupMembersModal.isOpen}
                            onClose={groupMembersModal.close}
                            parentId={idPrefix}
                        />
                        <CustomizeSettingsModal
                            open={customizeSettingsModal.isOpen}
                            onClose={closeCustomizeSettingsModal}
                            onBack={onCustomizeSettingsModalBack}
                            parentId={idPrefix}
                        />
                        <EditLocalGroupMembersModal
                            open={editLocalGroupMembersModal.isOpen}
                            onClose={editLocalGroupMembersModal.close}
                            parentId={idPrefix}
                        />
                        <EditUsersGroupsModal
                            open={editUsersGroupsModal.isOpen}
                            onClose={editUsersGroupsModal.close}
                            parentId={idPrefix}
                        />
                        <AddToGroupDialog open={addToGroupModal.isOpen} onClose={addToGroupModal.close} parentId={idPrefix} />

                        <ConfirmationDialog
                            title={t("common.warning_dialog.title")}
                            open={deleteUserModal.isOpen}
                            onClose={deleteUserModal.close}
                            onConfirm={deleteUser}
                            confirmLabel={t("common.button.proceed")}
                            cancelLabel={t("common.button.cancel")}
                            fullWidth={true}
                            id={`${idPrefix}_delete_user_modal`}
                        >
                            {t("users.side_menu.confirm_delete_user")}
                        </ConfirmationDialog>
                        <ConfirmationDialog
                            title={t("common.warning_dialog.title")}
                            open={deleteGroupModal.isOpen}
                            onClose={deleteGroupModal.close}
                            onConfirm={deleteGroup}
                            confirmLabel={t("common.button.proceed")}
                            cancelLabel={t("common.button.cancel")}
                            fullWidth={true}
                            id={`${idPrefix}_delete_group_modal`}
                        >
                            {t("users.side_menu.confirm_delete_group")}
                        </ConfirmationDialog>
                    </>
                )
            }
        />
    );
};

export default observer(SearchResultDrawer);
