import React, { useContext, useEffect } from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { useTranslation } from "react-i18next";
import { Context } from "store";
import { useLocalStore, useObserver } from "mobx-react-lite";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { SNMP_SERVICE_CONFIGURATION_CARD } from "const/widgetNameConst";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SavedInputPreview from "components/SavedInputPreview";
import SnmpServiceConfigurationDialog from "../SnmpServiceConfigurationDialog";
import { SECURITY_LEVEL } from "const/alertsConst";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const SNMP_SERVICE_CONFIGURATION_CARD_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_snmp_service_configuration_card`;

const SnmpServiceConfigurationCard = () => {
    const { t } = useTranslation();
    const {
        store: { alertsStore }
    } = useContext(Context);
    useEffect(() => {
        refreshData();
    }, []);
    const state = useLocalStore(() => ({
        inProgress: false,
        dialogOpen: false
    }));
    const refreshData = async () => {
        state.inProgress = true;
        await alertsStore.fetchSnmpServerSettings();
        state.inProgress = false;
    };
    const openEditDialog = () => {
        state.dialogOpen = true;
    };
    const closeEditDialog = () => {
        state.dialogOpen = false;
    };
    const securityNameKey = () => {
        return alertsStore.snmpServerSettings.requiredSecurityLevel === SECURITY_LEVEL.AUTH_NO_PRIV ||
            alertsStore.snmpServerSettings.requiredSecurityLevel === SECURITY_LEVEL.AUTH_PRIV
            ? "alerts.snmp_service_configuration_card.security_name"
            : "alerts.snmp_service_configuration_card.comunity_security_name";
    };
    return useObserver(() => (
        <>
            <SnmpServiceConfigurationDialog open={state.dialogOpen && !state.inProgress} onClose={closeEditDialog} />
            <DataReadonlyCard
                onClick={openEditDialog}
                title={t("alerts.snmp_service_configuration_card.title")}
                headerDivider
                control={
                    <MoreMenu id={`${SNMP_SERVICE_CONFIGURATION_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={SNMP_SERVICE_CONFIGURATION_CARD} />
                    </MoreMenu>
                }
            >
                {state.inProgress ? (
                    <EmptyCardPlaceholder inProgress={state.inProgress} />
                ) : (
                    <Grid container direction={"column"} spacing={5}>
                        <Grid item container alignItems={"center"} justify={"space-between"}>
                            <Grid xs={6} item>
                                <Typography>{t("alerts.snmp_service_configuration_card.port")}</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                <SavedInputPreview>{alertsStore.snmpServerSettings.port}</SavedInputPreview>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"} justify={"space-between"}>
                            <Grid xs={6} item>
                                <Typography>{t("alerts.snmp_service_configuration_card.transport")}</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                <SavedInputPreview>{alertsStore.snmpServerTransport}</SavedInputPreview>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"} justify={"space-between"}>
                            <Grid xs={6} item>
                                <Typography>{t("alerts.snmp_service_configuration_card.security_level")}</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                <SavedInputPreview>{alertsStore.snmpServerSettings.requiredSecurityLevel}</SavedInputPreview>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"} justify={"space-between"}>
                            <Grid xs={6} item>
                                <Typography>{t(securityNameKey())}</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                <SavedInputPreview>{alertsStore.snmpServerSettings.securityName}</SavedInputPreview>
                            </Grid>
                        </Grid>
                        {(alertsStore.snmpServerSettings.requiredSecurityLevel === SECURITY_LEVEL.AUTH_NO_PRIV ||
                            alertsStore.snmpServerSettings.requiredSecurityLevel === SECURITY_LEVEL.AUTH_PRIV) && (
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("alerts.snmp_service_configuration_card.auth_protocol")}</Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <SavedInputPreview>{alertsStore.snmpServerSettings.authProtocol}</SavedInputPreview>
                                </Grid>
                            </Grid>
                        )}
                        {alertsStore.snmpServerSettings.requiredSecurityLevel === SECURITY_LEVEL.AUTH_PRIV && (
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("alerts.snmp_service_configuration_card.privacy_protocol")}</Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <SavedInputPreview>{alertsStore.snmpServerSettings.privProtocol}</SavedInputPreview>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                )}
            </DataReadonlyCard>
        </>
    ));
};

export default SnmpServiceConfigurationCard;
