import React, { useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { useStyles } from "./LogicalDisks.style";
import { Grid, Table, TableHead, TableBody, TableRow, TableCell, Typography } from "@material-ui/core";
import Checkbox from "components/Checkbox";
import DataCard from "components/DataCard";
import { useTranslation } from "react-i18next";
import LogicalDisksRow from "./LogicalDisksRow";
import TableSortLabel from "components/TableSortLabel";
import { DISKS_VOLUME_NAME, DISKS_RAID, DISKS_SIZE, DISKS_STATUS_HEALTH, DISKS_STATUS_STRING } from "const/sortColumnConst";
import Select from "components/Select";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { useStore } from "hooks/useStore";
import { autorun } from "mobx";

const LogicalDisks = () => {
    const { t } = useTranslation();
    const {
        store: { volumeStore, driveReplaceStore },
    } = useStore();
    const classes = useStyles();

    const state = useLocalStore(() => ({
        volumes: [],
        queue: [],
        notifyOnCompletion: true,
        selectedPool: "",
        get pools() {
            const affectedPoolNames = driveReplaceStore.affectedPools?.pools?.map((pool) => pool.poolName);

            const resultPoolNames = affectedPoolNames || [];

            return resultPoolNames.filter((poolName) => {
                const hasVolumesInPool = volumeStore.affectedVolumes.find((volume) => volume.volumeId.pool === poolName);
                return hasVolumesInPool;
            });
        },
        get currentPoolDisks() {
            return volumeStore.affectedVolumes?.filter((volume) => volume.volumeId.pool === state.selectedPool) || [];
        },
        get mainCheckboxStatus() {
            if (state.currentPoolDisks?.length === volumeStore.checkedVolumes.length) {
                return "checked";
            } else if (
                state.currentPoolDisks?.length > volumeStore.checkedVolumes.length &&
                volumeStore.checkedVolumes.length > 0
            ) {
                return "indeterminated";
            }
            return null;
        },
    }));

    useEffect(() => {
        volumeStore.fetchVolumes();
    }, []);

    useEffect(() => {
        autorun(() => {
            volumeStore.affectedVolumes &&
                (state.affectedVolumes = volumeStore.affectedVolumes.map((volume) => {
                    return { checked: false, pool: volume.volumeId.pool, volume: volume.volumeId.volume };
                }));

            if (!state.selectedPool) {
                state.selectedPool = state.pools[0];
            }

            volumeStore.affectedVolumes && volumeStore.setCurrentPoolAffectedDisks(state.currentPoolDisks);
        });
    }, [volumeStore.affectedVolumes, state.currentPoolDisks, driveReplaceStore.affectedPools]);

    useEffect(() => {
        const hasSelectedPool = state.pools.find((poolName) => poolName === state.selectedPool);

        if (!hasSelectedPool) {
            state.selectedPool = state.pools[0];
        }
    }, [state.pools]);

    const setupPortName = (pool, volume) => () => {
        if (volumeStore.checkedVolumes.length > 0) return;
        volumeStore.setCurrentPortName(pool, volume);
    };

    const isCheckedVolume = (pool, volume) => {
        return volumeStore.checkedVolumes.some((volumeId) => volumeId.pool === pool && volumeId.volume === volume);
    };

    const onVolumeCheck = (pool, volume) => () => {
        const isChecked = isCheckedVolume(pool, volume);
        isChecked ? volumeStore.removeCheckedVolume({ pool, volume }) : volumeStore.addCheckedVolume({ pool, volume });
    };

    const handleMainCheckbox = () => {
        switch (state.mainCheckboxStatus) {
            case "checked":
            case "indeterminated":
                volumeStore.clearCheckedVolumes();
                break;
            default:
                volumeStore.affectedVolumes
                    ?.filter((volume) => volume.volumeId.pool === state.selectedPool)
                    ?.forEach((volume) =>
                        volumeStore.addCheckedVolume({ pool: volume.volumeId.pool, volume: volume.volumeId.volume })
                    );
                break;
        }
    };

    const changeSorting = (column) => () => volumeStore.changeSorting(column);

    const onPoolChange = (e) => {
        state.selectedPool = state.pools?.find((pool) => pool === e.target.value);
        volumeStore.clearCheckedVolumes();
        volumeStore.dropCurrentPortName();
        volumeStore.setCurrentPoolAffectedDisks(state.currentPoolDisks);
    };

    return useObserver(() => (
        <DataCard
            emptyPadding
            headerDivider
            autoHeight
            headerContent={
                <Grid container wrap={"nowrap"} alignItems={"center"} spacing={4}>
                    <Grid item>
                        <Typography variant={"h2"}>{t("troubleshooting.affected_logical_disks.title")}</Typography>
                    </Grid>
                    {state.pools.length !== 0 && (
                        <Grid item>
                            <Select
                                className={classes.select}
                                onChange={onPoolChange}
                                value={state.selectedPool}
                                options={state.pools}
                            />
                        </Grid>
                    )}
                </Grid>
            }
        >
            <>
                {state.currentPoolDisks.length !== 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding={"checkbox"}>
                                    <Checkbox
                                        indeterminate={state.mainCheckboxStatus === "indeterminated"}
                                        checked={state.mainCheckboxStatus === "checked"}
                                        onChange={handleMainCheckbox}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={volumeStore.order}
                                        onClick={changeSorting(DISKS_VOLUME_NAME)}
                                        active={volumeStore.orderBy === DISKS_VOLUME_NAME}
                                    >
                                        {t("disks_and_pools.logical_disks.table_header.name")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={volumeStore.order}
                                        onClick={changeSorting(DISKS_SIZE)}
                                        active={volumeStore.orderBy === DISKS_SIZE}
                                    >
                                        {t("disks_and_pools.logical_disks.table_header.size")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={volumeStore.order}
                                        onClick={changeSorting(DISKS_RAID)}
                                        active={volumeStore.orderBy === DISKS_RAID}
                                    >
                                        {t("disks_and_pools.logical_disks.table_header.raid")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={volumeStore.order}
                                        onClick={changeSorting(DISKS_STATUS_HEALTH)}
                                        active={volumeStore.orderBy === DISKS_STATUS_HEALTH}
                                    >
                                        {t("disks_and_pools.logical_disks.table_header.health")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={volumeStore.order}
                                        onClick={changeSorting(DISKS_STATUS_STRING)}
                                        active={volumeStore.orderBy === DISKS_STATUS_STRING}
                                    >
                                        {t("disks_and_pools.logical_disks.table_header.status")}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {volumeStore.sortedAffectedVolumes?.map((volume) => {
                                return (
                                    <LogicalDisksRow
                                        key={`${volume.volumeId.pool}/${volume.volumeId.volume}`}
                                        volume={volume}
                                        onCheck={onVolumeCheck(volume.volumeId.pool, volume.volumeId.volume)}
                                        checked={isCheckedVolume(volume.volumeId.pool, volume.volumeId.volume)}
                                        setupPortName={setupPortName(volume.volumeId.pool, volume.volumeId.volume)}
                                        dropPortName={volumeStore.dropCurrentPortName}
                                        currentPortName={volumeStore.currentPortName}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <EmptyCardPlaceholder message={t("troubleshooting.affected_logical_disks.placeholder")} />
                )}
            </>
        </DataCard>
    ));
};

export default LogicalDisks;
