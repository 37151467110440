import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    buttonError: {
        backgroundColor: theme.palette.error.main,
        "&:hover": {
            backgroundColor: theme.palette.error.light
        },
        "&:active": {
            color: theme.palette.common.white,
            border: "solid 1px transparent",
            backgroundColor: theme.palette.error.main
        }
    }
}));
