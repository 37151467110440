import React from "react";
import { observer } from "mobx-react";
import SwitchBase from "../Switch";
import { mapFieldToProps } from "utils/mapFieldToProps";

const Switch = props => {
    const { field, ...rest } = props;

    return <SwitchBase {...mapFieldToProps(field)} {...rest} />;
};

export default observer(Switch);
