import minMaxSizeValidator from "utils/minMaxSizeValidator";
import isFieldEmpty from "utils/isFieldEmptyRule";
import {
    MIN,
    MIN_DURATION,
    MIN_HEIGHT,
    MAX_CRF,
    MAX_HEIGHT,
    MAX_START_POSITION,
    MAX_AUDIO_CHANNELS,
    MAX_PASS,
} from "const/slingshotPresetConst";
import i18n from "i18n";

const createFields = () => {
    return [
        { name: "name", validators: [isFieldEmpty(i18n.t("common.label.field"))] },
        { name: "preset" },
        {
            name: "crf",
            type: "number",
            input: (input) => Number(input),
            validators: [minMaxSizeValidator({ min: MIN, max: MAX_CRF })],
        },
        { name: "height", type: "number", validators: [minMaxSizeValidator({ min: MIN_HEIGHT, max: MAX_HEIGHT })] },
        { name: "acodec" },
        { name: "vcodec" },
        { name: "pix_fmt" },
        { name: "container" },
        { name: "bv" },
        { name: "start", type: "number", validators: [minMaxSizeValidator({ min: MIN, max: MAX_START_POSITION })] },
        { name: "duration", type: "number" },
        { name: "ac", type: "number", validators: [minMaxSizeValidator({ min: MIN, max: MAX_AUDIO_CHANNELS })] },
        {
            name: "pass", type: "number", validators: [
                isFieldEmpty(i18n.t("slingshot_page.tabs.settings.presets.pass")),
                minMaxSizeValidator({ min: MIN_DURATION, max: MAX_PASS })
            ]
        },
        { name: "burnt_timecode", type: "checkbox" },
        { name: "timecode_position" },
        { name: "watermark_id" },
        { name: "watermark_position" },
        { name: "watermark_scale" },
    ];
};

export default createFields;
