import { REGEX_EMAIL } from "const";

export const validateEmailList = (raw, separator = ";") => {
    if (!raw) return false;

    const emails = raw.replace(/\s/g, "").split(separator);
    let valid = true;

    for (const value of emails) {
        if (value === "" || REGEX_EMAIL.test(value)) continue;
        valid = false;
    }

    return valid;
};
