import React from "react";
import { useStyles } from "./EditRuleOrderDialog.styles";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { Button, Grid, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import { useLocalStore, useObserver } from "mobx-react-lite";
import ChevronUpIcon from "assets/ChevronUpIcon";
import ChevronDownIcon from "assets/ChevronDownIcon";
import ButtonBase from "@material-ui/core/ButtonBase";
import clsx from "clsx";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import SavedInputIndicator from "components/SavedInputIndicator";
import { toJS } from "mobx";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";

const EditRuleOrderDialog = ({ open, onClose, onCloseDrawer, changeProgressState }) => {
    const classes = useStyles();
    const {
        store: { firewallStore }
    } = useStore();
    const { t } = useTranslation();

    const state = useLocalStore(() => ({
        currentIfaceRules: [],
        isSubmitted: false,
        inProgress: false,
        get interfacesRules() {
            return toJS(firewallStore.firewallInterfacesRules) || [];
        }
    }));

    const unsavedChangesModal = useModal();

    const onOpen = async () => {
        state.currentIfaceRules = firewallStore.interfacesRulesList.filter(
            el => el.iface === firewallStore.currentIfaceName
        )[0]?.rules;
        unsavedChangesModal.close();
        state.isSubmitted = true;
    };
    const moveUp = index => () => {
        [state.currentIfaceRules[index], state.currentIfaceRules[index - 1]] = [
            state.currentIfaceRules[index - 1],
            state.currentIfaceRules[index]
        ];
        state.isSubmitted = false;
    };
    const moveDown = index => () => {
        [state.currentIfaceRules[index], state.currentIfaceRules[index + 1]] = [
            state.currentIfaceRules[index + 1],
            state.currentIfaceRules[index]
        ];
        state.isSubmitted = false;
    };
    const onSubmit = async () => {
        state.inProgress = true;
        changeProgressState(true);

        let interfacesRules = firewallStore.interfacesRulesList;
        const ifaceIndex = interfacesRules.findIndex(el => el.iface === firewallStore.currentIfaceName);
        interfacesRules[ifaceIndex] = { ...interfacesRules[ifaceIndex], rules: toJS(state.currentIfaceRules) };
        const res = firewallStore.setFirewallRules({ interfacesRules: interfacesRules });
        if (res) {
            firewallStore.getRollbackTime();
            firewallStore.fetchFirewallRules();
            onCloseDrawer();
            state.isSubmitted = true;
        }
        state.inProgress = false;
        changeProgressState(false);
        return res;
    };

    const closeIfNeeded = () => {
        if (state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            withoutPaddings
            onEnter={onOpen}
            open={open}
            onClose={closeIfNeeded}
            onSubmit={onSubmit}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary">
                    {t("common.button.cancel")}
                </Button>
            }
            submitBtnLabel={t("common.button.save")}
            title={t("firewall.change_order_rules.title")}
            maxWidth={"lg"}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>{t("firewall.rules.rule_status")}</TableCell>
                        <TableCell>{t("firewall.rules.protocol")}</TableCell>
                        <TableCell>{t("firewall.rules.ports")}</TableCell>
                        <TableCell>{t("firewall.rules.address")}</TableCell>
                        <TableCell>{t("firewall.rules.action")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.currentIfaceRules?.map((rule, index) => (
                        // TODO  Do not use Array index in keys
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={`"rule"${index}`}>
                            <TableCell className={classes.buttonContainer}>
                                <Grid direction={"column"} container>
                                    <Grid container item>
                                        <ButtonBase
                                            disabled={!index}
                                            className={clsx(!index && classes.disabled)}
                                            onClick={moveUp(index)}
                                        >
                                            <ChevronUpIcon />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid container item>
                                        <ButtonBase
                                            disabled={index === state.currentIfaceRules.length - 1}
                                            className={clsx(
                                                classes.downIcon,
                                                index === state.currentIfaceRules.length - 1 && classes.disabled
                                            )}
                                            onClick={moveDown(index)}
                                        >
                                            <ChevronDownIcon />
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell>
                                <Grid item className={classes.indicator}>
                                    <SavedInputIndicator enabled={rule.enabled} />
                                </Grid>
                            </TableCell>
                            <TableCell>{firewallStore.protocolView(rule.protocol)}</TableCell>
                            <TableCell>{rule.portsRange.map(port => firewallStore.portsView(port)).join(", ")}</TableCell>
                            <TableCell>
                                {rule.addressesRange.map(address => firewallStore.addressView(address)).join(", ")}
                            </TableCell>
                            <TableCell>{firewallStore.actionView(rule.policy)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default EditRuleOrderDialog;
