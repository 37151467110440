import React from "react";
import DataCard from "components/DataCard";
import StorageUsageWidget from "components/graphs/StorageUsageWidget";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { STORAGE_USAGE_GRAPH_CARD } from "const/widgetNameConst";
import { useTranslation } from "react-i18next";
import HideOverlay from "components/HideOverlay/HideOverlay";

const StorageUsageGraphCard = ({ parentId = "storageUsageDashboardCard", ip }) => {
    const { t } = useTranslation();

    return (
        <DataCard
            autoHeight
            headerDivider
            title={t("dashboard.storage_usage.title")}
            headerControl={
                <HideOverlay show={!ip}>
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={STORAGE_USAGE_GRAPH_CARD} />
                    </MoreMenu>
                </HideOverlay>
            }
        >
            <StorageUsageWidget parentId={parentId} ip={ip} />
        </DataCard>
    );
};

export default StorageUsageGraphCard;
