import React, { useContext, useEffect } from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { Context } from "store";
import { useLocalStore, useObserver } from "mobx-react";
import createForm from "utils/createForm";
import createFields from "./createFields";
import { Button, Grid } from "@material-ui/core";
import RowTextInput from "components/RowTextInput";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import Switch from "components/MobxForm/Switch";
import Select from "components/MobxForm/Select";
import { LOG_STORAGE_LOCATION_EMPTY } from "const/logSorageLocationConst";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import sortByLocaleCompare from "utils/sortByLocaleCompare";
import { useModal } from "hooks/useModal";
import { AUDIT_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const EDIT_AUDIT_SETTINGS_DIALOG_ID_PREFIX = `${AUDIT_PAGE_ID_PREFIX}_edit_audit_settings_dialog`;

const EditAuditSettingsDialog = ({ open, closeDialog }) => {
    const { t } = useTranslation();

    const {
        store: { auditStore, shareStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields() }),
        inProgress: false,
        isSubmitted: false,
        shares: []
    }));

    const unsavedChangesModal = useModal();

    useEffect(() => {
        shareStore.fetchSharesTable();
    }, []);

    const getFormValues = () => ({
        logStorageLocation:
            state.form.$("logStorageLocation").value !== LOG_STORAGE_LOCATION_EMPTY
                ? state.form.$("logStorageLocation").value
                : "",
        authentication: state.form.$("authentication").value,
        fileAccess: state.form.$("fileAccess").value,
        logOperationsWithAds: state.form.$("logOperationsWithAds").value,
        enabled: true
    });

    const onSubmit = async e => {
        state.inProgress = true;
        state.form.onSubmit(e);
        const res = await auditStore.setAuditSettings(getFormValues());
        state.inProgress = false;
        res && (state.isSubmitted = true);
        return res;
    };

    const onEnter = () => {
        state.inProgress = false;
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.shares = [LOG_STORAGE_LOCATION_EMPTY];
        const sharesTable =
            (shareStore.sharesTable.length &&
                sortByLocaleCompare(
                    shareStore?.sharesTable
                        ?.flatMap(share => share.shares.filter(s => s.isAuditLogStorageLocation))
                        ?.map(share => share.shareName)
                )) ||
            [];
        state.shares = [...state.shares, ...sharesTable];
        const disableSettings = !auditStore.auditSettingsList?.logStorageLocation.length;

        state.form
            .$("logStorageLocation")
            .set("default", disableSettings ? LOG_STORAGE_LOCATION_EMPTY : auditStore.auditSettingsList?.logStorageLocation);
        state.form.$("authentication").set("default", disableSettings ? false : auditStore.auditSettingsList?.authentication);
        state.form.$("fileAccess").set("default", disableSettings ? false : auditStore.auditSettingsList?.fileAccess);
        state.form
            .$("logOperationsWithAds")
            .set("default", disableSettings ? false : auditStore.auditSettingsList.logOperationsWithAds);
        state.form.reset();
    };

    const closeIfNeeded = () => {
        state.form.isDefault || state.isSubmitted ? closeDialog() : unsavedChangesModal.open();
    };

    const handleChange = e => {
        state.form.$("logStorageLocation").set("value", e.target.value);
        if (e.target.value === LOG_STORAGE_LOCATION_EMPTY) {
            state.form.$("authentication").set("value", false);
            state.form.$("fileAccess").set("value", false);
            state.form.$("logOperationsWithAds").set("value", false);
        }
    };

    return useObserver(() => (
        <Dialog
            open={open}
            onEnter={onEnter}
            onSubmit={onSubmit}
            onClose={closeIfNeeded}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${EDIT_AUDIT_SETTINGS_DIALOG_ID_PREFIX}_save`}
            title={t("audit.edit_audit_settings.title")}
            buttons={
                <Button
                    onClick={closeDialog}
                    variant={"contained"}
                    color="secondary"
                    id={`${EDIT_AUDIT_SETTINGS_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container spacing={4} direction={"column"}>
                <RowTextInput
                    label={t("audit.audit_settings.log_storage_location")}
                    control={
                        <Select
                            options={state.shares}
                            field={state.form.$("logStorageLocation")}
                            onChange={handleChange}
                            id={`${EDIT_AUDIT_SETTINGS_DIALOG_ID_PREFIX}_log_storage_location`}
                        />
                    }
                />
                <RowControlLabelSwitch
                    control={
                        <Switch
                            disabled={state.form.$("logStorageLocation").value === LOG_STORAGE_LOCATION_EMPTY}
                            field={state.form.$("authentication")}
                            id={`${EDIT_AUDIT_SETTINGS_DIALOG_ID_PREFIX}_audit_authentication`}
                        />
                    }
                    label={t("audit.audit_settings.audit_authentication")}
                />
                <RowControlLabelSwitch
                    control={
                        <Switch
                            disabled={state.form.$("logStorageLocation").value === LOG_STORAGE_LOCATION_EMPTY}
                            field={state.form.$("fileAccess")}
                            id={`${EDIT_AUDIT_SETTINGS_DIALOG_ID_PREFIX}_audit_file_access`}
                        />
                    }
                    label={t("audit.audit_settings.audit_file_access")}
                />
                <RowControlLabelSwitch
                    control={
                        <Switch
                            disabled={state.form.$("logStorageLocation").value === LOG_STORAGE_LOCATION_EMPTY}
                            field={state.form.$("logOperationsWithAds")}
                            id={`${EDIT_AUDIT_SETTINGS_DIALOG_ID_PREFIX}_logging_operations`}
                        />
                    }
                    label={t("audit.audit_settings.logging_operations")}
                />
            </Grid>
            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default EditAuditSettingsDialog;
