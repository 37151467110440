import { types } from "mobx-state-tree";

import VolumeId from "api/volume/Types/VolumeId";

const GetDefragmentationStateArguments = types.model({
    volumeId: types.maybe(VolumeId),
    refresh: types.boolean
});

export default GetDefragmentationStateArguments;
