import Request from "api/Request";

const GetSensorsInfo = Request.props({
    path: "/com/studionetworksolutions/evo/status/Info",
    method: "get_sensors_info"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetSensorsInfo;
