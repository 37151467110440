import { types } from "mobx-state-tree";

const AddExternalShareArguments = types.model({
    name: types.string,
    maxProtocol: types.string,
    security: types.string,
    host: types.string,
    user: types.string,
    password: types.string,
    share: types.string
});

export default AddExternalShareArguments;
