import { types } from "mobx-state-tree";

const StageStateEnum = ["not_started", "warn", "error", "finished"];

const MigrationStage = types.model({
    name: types.string,
    stageState: types.enumeration(StageStateEnum),
    errors: types.array(types.string),
});

export default MigrationStage;
