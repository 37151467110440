import React, { useContext } from "react";
import { useObserver } from "mobx-react";
import { Grid } from "@material-ui/core";
import MainTemplate from "components/MainTemplate";
import WelcomeCard from "./components/WelcomeCard";
import BasicSetupCard from "./components/BasicSetupCard";
import MoreHelpCard from "./components/MoreHelpCard";
import TipsCard from "./components/TipsCard";
import QuickStartIcon from "assets/QuickStartIcon";
import HeaderTitle from "components/HeaderTitle";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { QUICK_START } from "const/shortcutNameConst";
import DontShowAgainCard from "./components/DontShowAgainCard";

const QuickStart = () => {
    const {
        store: { authStore }
    } = useContext(Context);
    const { t } = useTranslation();
    const hideHeaderButton = !authStore.isAuthorized;

    return useObserver(() => (
        <MainTemplate
            headerTitle={<HeaderTitle icon={<QuickStartIcon />} title={t("quick_start.title")} shortcutName={QUICK_START} />}
            hideHeaderButton={hideHeaderButton}
        >
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <Grid container direction={"column"} spacing={6}>
                        <Grid item>
                            <WelcomeCard />
                        </Grid>
                        {authStore.isAuthorized && (
                            <Grid item>
                                <DontShowAgainCard />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction={"column"} spacing={6}>
                        <Grid item>
                            <MoreHelpCard />
                        </Grid>
                        <Grid item>
                            <TipsCard />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item>
                        <BasicSetupCard />
                    </Grid>
                </Grid>
            </Grid>
        </MainTemplate>
    ));
};

export default QuickStart;
