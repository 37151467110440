import React from "react";
import { observer } from "mobx-react";

import CheckboxBase from "../Checkbox/Checkbox";
import { mapFieldToProps } from "utils/mapFieldToProps";

const Checkbox = ({ field, valid, ...rest }) => {
    return <CheckboxBase {...mapFieldToProps(field)} valid={valid} {...rest} />;
};

export default observer(Checkbox);
