import React, { useContext, useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { AUDIO_ALARM_MANAGE_CARD } from "const/widgetNameConst";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import DataCard from "components/DataCard/DataCard";
import MoreMenu from "components/MoreMenu/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem/HomeCardActionMenuItem";
import { Grid, Typography } from "@material-ui/core";
import Select from "components/MobxForm/Select";
import RowTextInput from "components/RowTextInput/RowTextInput";
import createForm from "utils/createForm";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const AUDIO_ALARM_MANAGER_CARD_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_audio_alarm_manager_card`;

const AudioAlarmManagerCard = () => {
    const { t } = useTranslation();
    const {
        store: { alertsStore }
    } = useContext(Context);
    const state = useLocalStore(() => ({
        form: createForm({ fields: [{ name: "mute_duration" }] }),
        inProgress: false,
        get MUTE_DURATION_VALUES() {
            return [
                { label: t("alerts.audio_alarm_manager.mute_for.minutes", { count: 15 }), value: 15 },
                { label: t("alerts.audio_alarm_manager.mute_for.minutes", { count: 30 }), value: 30 },
                { label: t("alerts.audio_alarm_manager.mute_for.hours", { count: 1 }), value: 60 },
                { label: t("alerts.audio_alarm_manager.mute_for.hours", { count: 2 }), value: 60 * 2 },
                { label: t("alerts.audio_alarm_manager.mute_for.hours", { count: 3 }), value: 60 * 3 },
                { label: t("alerts.audio_alarm_manager.mute_for.hours", { count: 4 }), value: 60 * 4 },
                { label: t("alerts.audio_alarm_manager.mute_for.days", { count: 1 }), value: 60 * 24 }
            ];
        }
    }));

    useEffect(() => {
        alertsStore.fetchMuteState();
        state.form.$("mute_duration").set("default", 15);
        state.form.reset();
    }, []);

    const muteBeep = async () => {
        state.inProgress = true;
        const res = await alertsStore.muteBeepFor({ delayMin: state.form.$("mute_duration").value });
        state.inProgress = false;
        return res;
    };
    const unmuteBeep = async () => {
        state.inProgress = true;
        const res = await alertsStore.unmuteBeep();
        state.inProgress = false;
        return res;
    };

    return useObserver(() => (
        <DataCard
            title={t("alerts.audio_alarm_manager.title")}
            headerControl={
                <MoreMenu id={`${AUDIO_ALARM_MANAGER_CARD_ID_PREFIX}_more_menu`}>
                    <HomeCardActionMenuItem widget={AUDIO_ALARM_MANAGE_CARD} />
                </MoreMenu>
            }
        >
            {alertsStore?.beepMuteState.enabled ? (
                <Grid item container spacing={4}>
                    <Grid container item spacing={1}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.audio_alarm_manager.mute_until.label")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <Typography variant={"h6"}>{alertsStore.beepMuteState?.untilTimeStr}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <AnimatedSubmitButton
                            disabled={false}
                            inProgress={state.inProgress}
                            submit={unmuteBeep}
                            label={t("alerts.audio_alarm_manager.btn.unmute")}
                            id={`${AUDIO_ALARM_MANAGER_CARD_ID_PREFIX}_unmute_beep`}
                        />
                    </Grid>
                </Grid>
            ) : (
                <Grid item container spacing={4}>
                    <RowTextInput
                        control={
                            <Select
                                options={state.MUTE_DURATION_VALUES}
                                field={state.form.$("mute_duration")}
                                id={`${AUDIO_ALARM_MANAGER_CARD_ID_PREFIX}_mute_duration`}
                            />
                        }
                        label={t("alerts.audio_alarm_manager.mute_for.label")}
                    />
                    <Grid item>
                        <AnimatedSubmitButton
                            disabled={false}
                            inProgress={state.inProgress}
                            submit={muteBeep}
                            label={t("alerts.audio_alarm_manager.btn.mute")}
                            id={`${AUDIO_ALARM_MANAGER_CARD_ID_PREFIX}_mute_beep`}
                        />
                    </Grid>
                </Grid>
            )}
        </DataCard>
    ));
};

export default AudioAlarmManagerCard;
