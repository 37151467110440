import React from "react";
import { useObserver } from "mobx-react";
import MainTemplate from "components/MainTemplate";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SpeedIcon from "assets/SpeedIcon";
import HeaderTitle from "components/HeaderTitle";
import { HARDWARE_PROFILE } from "const/shortcutNameConst";
import CPUCard from "./components/CPUCard";
import SystemMemoryCard from "./components/SystemMemoryCard";
import SensorsCard from "./components/SensorsCard";
import RAIDControllersCard from "./components/RAIDControllersCard";
import EthernetCard from "./components/EthernetCard";

const HardwareProfile = () => {
    const { t } = useTranslation();

    return useObserver(() => (
        <MainTemplate
            headerTitle={<HeaderTitle icon={<SpeedIcon />} title={t("hardware_profile.title")} shortcutName={HARDWARE_PROFILE} />}
        >
            <Grid container spacing={6}>
                <Grid item container direction={"column"} spacing={6} xs={12} lg={6}>
                    <Grid item>
                        <CPUCard />
                    </Grid>
                    <Grid item>
                        <SystemMemoryCard />
                    </Grid>
                    <Grid item>
                        <SensorsCard />
                    </Grid>
                </Grid>

                <Grid item container direction={"column"} spacing={6} xs={12} lg={6}>
                    <Grid item>
                        <RAIDControllersCard />
                    </Grid>
                    <Grid item>
                        <EthernetCard />
                    </Grid>
                </Grid>
            </Grid>
        </MainTemplate>
    ));
};

export default HardwareProfile;
