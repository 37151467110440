import { types } from "mobx-state-tree";

import Request from "api/Request";
import TrashFilesArguments from "api/trash/Types/TrashFilesArguments";

const EraseTrashFiles = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Trash",
    method: "erase_trash_files",
    arguments: types.maybe(TrashFilesArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default EraseTrashFiles;
