import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    column: {
        height: "100%",
        marginTop: theme.spacing(-6),
        marginBottom: theme.spacing(-6),
        "& #expandable": {
            maxWidth: "100%",
            flexBasis: "100%"
        },
        "& #halfWidthExpandable": {
            maxWidth: "50%",
            flexBasis: "50%"
        }
    },
    trashDndExtender: {
        position: "sticky",
        paddingRight: theme.spacing(5.5),
        bottom: theme.spacing(8),
        marginTop: -theme.spacing(16),
        width: "auto",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        alignSelf: "flex-end",
        marginLeft: "auto"
    }
}));
