import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: "none",
        color: theme.palette.primary.main,
        cursor: "pointer",
        display: "inline-flex",
        verticalAlign: "top",

        "&:hover": {
            textDecoration: "underline"
        }
    }
}));
