import { types } from "mobx-state-tree";

import Request from "api/Request";

const GetPresetConstraints = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Transcoding1",
    method: "get_preset_constraints",
    arguments: types.maybe(
        types.snapshotProcessor(
            types.model({
                json: types.model({
                    presetId: types.maybe(types.number),
                }),
            }),
            {
                postProcessor(snapshot) {
                    return {
                        json: snapshot?.json && JSON.stringify(snapshot.json),
                    };
                },
            }
        )
    ),
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default GetPresetConstraints;
