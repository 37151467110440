import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./StatusLabel.style";
import { VOLUME_STATUS_OPTIONS_ARRAY } from "const/clusterConst";

const StatusLabel = ({ status }) => {
    const classes = useStyles({ status });

    const getVolumeStatus = status => {
        return VOLUME_STATUS_OPTIONS_ARRAY.find(entry => entry.value === status)?.label || "";
    };

    return (
        <>
            <Grid justify={"flex-start"} container>
                <Grid item>
                    <Grid container alignItems={"center"} className={classes.label}>
                        <Grid item>
                            <Typography component={"span"} className={classes.labelText}>
                                {getVolumeStatus(status)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default StatusLabel;
