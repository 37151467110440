import { types } from "mobx-state-tree";

import Request from "api/Request";
import AddExternalShareArguments from "api/external_shares/Types/AddExternalShareArguments";

const AddExternalShare = Request.props({
    path: "/com/studionetworksolutions/evo/ExternalShares",
    method: "add_external_share",
    arguments: types.maybe(AddExternalShareArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default AddExternalShare;
