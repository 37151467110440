import React from "react";

const SwitchIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <defs>
            <linearGradient id="b58dc588-4b68-4787-831e-5c8fe7d3f03b" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0%" stopColor="#A5E3FF" />
                <stop offset="100%" stopColor="#99CDFF" />
            </linearGradient>
            <linearGradient id="b48b60e9-6870-47f0-89b9-eafe28d2f19c" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0%" stopColor="#A5E3FF" />
                <stop offset="100%" stopColor="#99CDFF" />
            </linearGradient>
            <linearGradient id="b3a67119-8f2d-42f7-b209-5ecc0e8c3427" x1="31.267%" x2="100%" y1="21.875%" y2="78.125%">
                <stop offset="0%" stopColor="#1E90FF" />
                <stop offset="100%" stopColor="#651EFF" stopOpacity="0.91" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#52A2F0"
                stroke="url(#b58dc588-4b68-4787-831e-5c8fe7d3f03b)"
                d="M19.146.5H4.854a2.502 2.502 0 00-2.236 1.382L.809 5.5h22.382l-1.809-3.618A2.499 2.499 0 0019.146.5z"
            />
            <rect
                width="23"
                height="19"
                x="0.5"
                y="4.5"
                fill="#85C3FF"
                stroke="url(#b48b60e9-6870-47f0-89b9-eafe28d2f19c)"
                rx="2"
            />
            <path
                fill="url(#b3a67119-8f2d-42f7-b209-5ecc0e8c3427)"
                d="M7 4v1H6v1H2V5H1V4H0V.413C0 .185.185 0 .412 0h7.176C7.815 0 8 .185 8 .412V4H7z"
                transform="translate(3 7)"
            />
            <path fill="#FFCD8C" d="M5 7h1v1.5a.5.5 0 01-1 0V7zm1.5 0h1v1.5a.5.5 0 11-1 0V7zM8 7h1v1.5a.5.5 0 01-1 0V7z" />
            <path
                fill="url(#b3a67119-8f2d-42f7-b209-5ecc0e8c3427)"
                d="M7 4v1H6v1H2V5H1V4H0V.413C0 .185.185 0 .412 0h7.176C7.815 0 8 .185 8 .412V4H7z"
                transform="translate(13 7)"
            />
            <path fill="#FFCD8C" d="M15 7h1v1.5a.5.5 0 01-1 0V7zm1.5 0h1v1.5a.5.5 0 11-1 0V7zM18 7h1v1.5a.5.5 0 01-1 0V7z" />
            <g>
                <path
                    fill="url(#b3a67119-8f2d-42f7-b209-5ecc0e8c3427)"
                    d="M7 4v1H6v1H2V5H1V4H0V.413C0 .185.185 0 .412 0h7.176C7.815 0 8 .185 8 .412V4H7z"
                    transform="translate(3 15)"
                />
                <path
                    fill="#FFCD8C"
                    d="M5 15h1v1.5a.5.5 0 01-1 0V15zm1.5 0h1v1.5a.5.5 0 11-1 0V15zM8 15h1v1.5a.5.5 0 01-1 0V15z"
                />
                <path
                    fill="url(#b3a67119-8f2d-42f7-b209-5ecc0e8c3427)"
                    d="M7 4v1H6v1H2V5H1V4H0V.413C0 .185.185 0 .412 0h7.176C7.815 0 8 .185 8 .412V4H7z"
                    transform="translate(13 15)"
                />
                <path
                    fill="#FFCD8C"
                    d="M15 15h1v1.5a.5.5 0 01-1 0V15zm1.5 0h1v1.5a.5.5 0 11-1 0V15zm1.5 0h1v1.5a.5.5 0 01-1 0V15z"
                />
            </g>
        </g>
    </svg>
);

export default SwitchIcon;
