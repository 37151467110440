import React from "react";
import Card from "./components/Card";
import EvoGui2 from "assets/EvoGui2";
import ShareBrowser2 from "assets/ShareBrowser2";
import SnsLogo from "assets/SnsLogo";
import { Grid, Link } from "@material-ui/core";
import { useStyles } from "./SplashPage.style";
import { useTranslation } from "react-i18next";
import { URL_LOGIN, URL_SHAREBROWSER } from "routes";
import { SNS_WEBSITE } from "const";
import PluginList from "./components/PluginList/PluginList";

const SplashPage = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const ShareBrowser = () => ShareBrowser2({
        underBackgroundClassName: classes.underBackgroundShareBrowser,
        backgroundClassName: classes.backgroundShareBrowser
    });

    const EvoGui = () => EvoGui2({
        underBackgroundClassName: classes.underBackgroundEvoGui,
        backgroundClassName: classes.backgroundEvoGui
    });

    return (
        <Grid
            container
            direction={"column"}
            className={classes.container}
            wrap={"nowrap"}
            justify={"center"}
            alignItems={"center"}
        >
            <Grid item container justify={"center"} className={classes.logoContainer}>
                <Link rel="noopener" target="_blank" href={SNS_WEBSITE} variant="button">
                    <SnsLogo />
                </Link>
            </Grid>
            <Grid item container spacing={6} justify={"center"} alignItems={"flex-start"}>
                <Grid item container spacing={6} justify={"center"} className={classes.cardsContainer}>
                    <Grid item>
                        <Card
                            title={t("splash_page.card.share_browser.title")}
                            description={t("splash_page.card.share_browser.description")}
                            ImgComponent={ShareBrowser}
                            url={URL_SHAREBROWSER}
                        />
                    </Grid>
                    <Grid item>
                        <Card
                            title={t("splash_page.card.evo_admin.title")}
                            description={t("splash_page.card.evo_admin.description")}
                            ImgComponent={EvoGui}
                            url={URL_LOGIN}
                        />
                    </Grid>
                </Grid>
                <PluginList />
            </Grid>
        </Grid>
    );
};

export default SplashPage;
