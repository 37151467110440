import { types, getParent } from "mobx-state-tree";
import { ADMIN } from "const/userRolesConst";

const MultipleEvoEventStore = types
    .model({})

    .actions(self => ({
        listen() {
            const {
                shareStore,
                externalSharesStore,
                authStore,
                volumeStore,
                poolsStore,
                userStore,
                driveStore,
                bondsStore,
                bridgeStore,
                ethernetPortsStore,
                socket
            } = getParent(self);
            socket.onbroadcast = e => {
                const { broadcast, path, type } = JSON.parse(e.data);
                switch (path) {
                    case "/com/studionetworksolutions/evo/Nas":
                        switch (type) {
                            case "SharesRemovedEvent":
                                shareStore.removeShareFromStore(broadcast);
                                break;
                            case "ShareStatusChangedEvent":
                                shareStore.changeShareStatus(broadcast);
                                break;
                            case "DefaultMasksBroadcast":
                                shareStore.fetchDefaultsMasks();
                                break;
                            case "NasConfiguration":
                                shareStore.updateNasConfigurationState(broadcast);
                                break;
                            default:
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/ExternalShares":
                        switch (type) {
                            case "ExternalSharesRemovedEvent":
                                externalSharesStore.removeShareFromStore(broadcast);
                                break;
                            case "ExternalShareStatusChangedEvent":
                                externalSharesStore.changeShareStatus(broadcast.share);
                                break;
                            default:
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Volume":
                        switch (type) {
                            case "VolumeStatusChangedEvent":
                                if (authStore.role === ADMIN) {
                                    volumeStore.changeVolumeStatus(broadcast);
                                    poolsStore.fetchPoolsByIds([broadcast.volumeId]);
                                }
                                break;
                            case "BuildQueue":
                                volumeStore.changeQueueState(broadcast);
                                break;
                            case "VolumesRemovedEvent":
                                volumeStore.removeVolumesFromStore(broadcast);
                                authStore.role === ADMIN && poolsStore.fetchPoolsByIds(broadcast.volumeIds);
                                break;
                            case "DefragState":
                                volumeStore.updateDefragState(broadcast);
                                break;
                            case "DeferredParityCalculation":
                                volumeStore.updateDeferredParityCalculationStore(broadcast);
                                break;
                            default:
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Pool":
                        switch (type) {
                            case "Pool":
                                poolsStore.updatePoolInStore(broadcast);
                                break;
                            case "RemovePoolArguments":
                                poolsStore.removePoolFromStore(broadcast);
                                break;
                            default:
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Users":
                        switch (type) {
                            case "ActiveDirectoryStatus":
                                userStore.updateActiveDirectoryStatus(broadcast);
                                break;
                            case "LdapStatus":
                                userStore.updateLdapStatus(broadcast);
                                break;
                            case "SecondaryAdminsListChangedEvent":
                                userStore.updateSecondaryAdmins(broadcast);
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                    case "/com/studionetworksolutions/evo/Drives":
                        switch (type) {
                            case "IdentifyStatusData":
                                driveStore.getIdentifyStatus();
                                break;
                            case "DrivesStatusChangedEvent":
                                driveStore.updateEnclosuresState(broadcast.enclosures);
                                break;
                            default:
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/Net":
                        switch (type) {
                            case "NetBond":
                                bondsStore.updateBondInStore(broadcast);
                                break;
                            case "NetBridge":
                                bridgeStore.updateBridgeInStore(broadcast);
                                break;
                            case "NetInterface":
                                ethernetPortsStore.updateInterfaceInStore(broadcast);
                                break;
                            case "RemovedBond":
                                bondsStore.removeBondFromStore(broadcast);
                                break;
                            case "RemovedBridge":
                                bridgeStore.removeBridgeFromStore(broadcast);
                                break;
                            case "RemovedInterface":
                                ethernetPortsStore.removeInterfaceFromStore(broadcast);
                                break;
                            default:
                                break;
                        }
                        break;
                }
            };
        }
    }));

export default MultipleEvoEventStore;
