import GetDriveSmartReport from "api/system_drives/Requests/GetDriveSmartReport";
import GetDriveStatus from "api/system_drives/Requests/GetDriveStatus";
import GetIdentifyStatus from "api/system_drives/Requests/GetIdentifyStatus";
import IdentifyOff from "api/system_drives/Requests/IdentifyOff";
import IdentifyOn from "api/system_drives/Requests/IdentifyOn";
import DriveStatusResult from "api/system_drives/Responses/DriveStatusResult";
import SmartReportResult from "api/system_drives/Responses/SmartReportResult";
import IdentifyStatusData from "api/system_drives/Types/IdentifyStatusData";
import { flow, getParent, types } from "mobx-state-tree";
import Socket from "websocket/WebSocket";

export default types
    .model("SystemDriveStore", {
        driveStatusResult: types.maybe(DriveStatusResult),
        smartReportResult: types.maybe(SmartReportResult),
        identifyStatusResult: types.maybe(IdentifyStatusData),
    })
    .volatile(() => ({
        currentSelectDrive: null,
    }))
    .views((self) => ({
        get drivesStatus() {
            return self.driveStatusResult?.data?.drives;
        },
        get selectedDrive() {
            return self.currentSelectDrive;
        },
        get identifyStatus() {
            return self.identifyStatusResult?.drive;
        },
        get identifyStatusSelectedDrive() {
            return self.currentSelectDrive?.drive === self.identifyStatusResult?.drive;
        },
        get smartReport() {
            return self.smartReportResult?.data;
        },
    }))
    .actions((self) => ({
        selectDrive(drive) {
            if (self.currentSelectDrive && self.currentSelectDrive.drive === drive.drive) {
                self.clearSelectedDrive();
                return;
            }
            self.currentSelectDrive = drive;
        },
        clearSelectedDrive() {
            self.currentSelectDrive = null;
        },
        updateidentifyStatus(identifyStatus) {
            if (!identifyStatus) return null;

            self.identifyStatusResult = identifyStatus;
        },
        clearSmartReport() {
            self.smartReportResult = undefined;
        },
    }))
    .actions((self) => ({
        turnIdentifyOn: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = IdentifyOn.create().init({ drive: self.currentSelectDrive.drive });
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return false;
        }),
    }))
    .actions((self) => ({
        turnIdentifyOff: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = IdentifyOff.create().init();
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return false;
        }),
    }))
    .actions((self) => ({
        fetchDriveStatus: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetDriveStatus.create().init();
                const res = yield Socket.send(req);
                self.driveStatusResult = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return false;
        }),
    }))
    .actions((self) => ({
        fetchIdentifyStatus: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetIdentifyStatus.create().init();
                const res = yield Socket.send(req);
                self.updateidentifyStatus(res.data);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return false;
        }),
    }))
    .actions((self) => ({
        fetchDriveSmartReport: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetDriveSmartReport.create().init({ drive: self.currentSelectDrive.drive });
                const res = yield Socket.send(req);
                self.smartReportResult = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return false;
        }),
    }));
