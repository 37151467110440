import React from "react";
import MainTemplate from "components/MainTemplate";
import HeaderTitle from "components/HeaderTitle";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ShieldIcon from "assets/ShieldIcon";
import TechnicalSupportCard from "./components/TechnicalSupportCard";
import DeferredParityCalculationCard from "./components/DeferredParityCalculationCard/DeferredParityCalculationCard";

const TechnicalSupport = () => {
    const { t } = useTranslation();

    return (
        <MainTemplate headerTitle={<HeaderTitle icon={<ShieldIcon />} title={t("technical_support.title")} />}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <TechnicalSupportCard />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DeferredParityCalculationCard />
                </Grid>
            </Grid>
        </MainTemplate>
    );
};

export default TechnicalSupport;
