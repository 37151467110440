import { types } from "mobx-state-tree";

import DriveId from "api/drive_replace/Types/DriveId";

const DRIVE_TYPE_ARRAY = ["hdd", "ssd", "unknown"];
const DRIVE_STATUS_ARRAY = ["failed", "absent", "replaced", "warning", "online", "not_committed", "unknown", "not_activated"];
const DriveStatus = types.model({
    drive: types.maybe(DriveId),
    type: types.enumeration(DRIVE_TYPE_ARRAY),
    status: types.enumeration(DRIVE_STATUS_ARRAY),
});

export default DriveStatus;
