import React from "react";
import { Stepper as MuiStepper, Step, StepLabel } from "@material-ui/core";
import { useStyles } from "./Stepper.styles";

const Stepper = ({ steps, activeStep }) => {
    const classes = useStyles();
    return (
        <MuiStepper activeStep={activeStep} connector={null} className={classes.stepper}>
            {steps.map(label => (
                <Step key={label} className={classes.step}>
                    <StepLabel className={classes.label}>{label}</StepLabel>
                </Step>
            ))}
        </MuiStepper>
    );
};

export default Stepper;
