import { useLocalStore } from "mobx-react";
import createFields from "./createFields";
import createForm from "utils/createForm";
import isIpv4Rule from "utils/isIpv4Rule";
import hostNameRule from "utils/hostNameRule";
import { useStore } from "hooks/useStore";
import isIpHostRule from "utils/isIpHostRule";
import i18next from "i18next";

export const useMuptipleEvoConfigurationState = () => {
    const {
        store: { multipleEvosStore },
    } = useStore();

    return useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
            rules: { ...isIpv4Rule, ...hostNameRule, ...isIpHostRule },
            options: {
                validateOnInit: false,
                validateOnBlur: false,
                showErrorsOnReset: false,
            },
        }),
        inProgress: false,
        startProgress() {
            this.inProgress = true;
        },
        stopProgress() {
            this.inProgress = false;
        },
        multipleEvos: [],
        connectedEvos: [],
        resetValidationFields() {
            this.form.$("name").resetValidation();
            this.form.$("ip").resetValidation();
        },
        /** @type {Evo | null} */
        removalCandidate: null,
        /** @param {Evo} evo */
        removeEvoFromList: async function (evo) {
            const idxEvoByIp = this.multipleEvos.findIndex((multipleEvo) => multipleEvo.ip === evo.ip);
            this.multipleEvos.splice(idxEvoByIp, 1);

            const idxEvoConnectByIp = this.connectedEvos.findIndex((multipleEvo) => multipleEvo.ip === evo.ip);
            this.connectedEvos.splice(idxEvoConnectByIp, 1);

            return await multipleEvosStore.removeMultipleEvo(evo.ip);
        },
        addEvoToList(event) {
            this.resetValidationFields();
            this.form.onSubmit(event);

            const ip = this.form.$("ip").value.trim();
            const name = this.form.$("name").value.trim();
            const hasRepeatName = this.multipleEvos.some((evo) => evo.name === name);
            const hasRepeatIp = this.multipleEvos.some((evo) => evo.ip === ip);
            if (hasRepeatName) this.form.$("name").invalidate(i18next.t("rule.field.already_used_evo_name"));
            if (hasRepeatIp) this.form.$("ip").invalidate(i18next.t("rule.field.already_used_evo_ip"));
            if (!this.form.isValid || hasRepeatName || hasRepeatIp) {
                this.stopProgress();
                return null;
            }

            this.multipleEvos.push({
                needToConnect: false,
                name: name,
                ip: ip,
            });

            this.form.reset();
            this.resetValidationFields();

            return this.multipleEvos;
        },
    }));
};
