import { types } from "mobx-state-tree";

const ShareLocationInfo = types.model({
    pool: types.string,
    volume: types.string,
    partition: types.number,
    directory: types.string
});

export default ShareLocationInfo;
