import { types } from "mobx-state-tree";

import Uptime from "api/power/Types/Uptime";

const UptimeResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(Uptime)
});

export default UptimeResult;
