import React from "react";
import { useObserver } from "mobx-react";
import { Grid } from "@material-ui/core";
import PoolsShareContainer from "../PoolsShareContainer";
import CurrentSession from "../CurrentSession";
import MultipleEvoTitle from "components/MultipleEvoTitle";
import { useStore } from "hooks/useStore";

const SharesTabContent = () => {
    const { multipleEvosRootStore } = useStore();

    return useObserver(() => (
        <>
            <Grid container item direction="column" spacing={6}>
                {multipleEvosRootStore.storesLength > 0 && (
                    <Grid item>
                        <MultipleEvoTitle />
                    </Grid>
                )}
                <Grid item>
                    <PoolsShareContainer />
                </Grid>
                <Grid item>
                    <CurrentSession />
                </Grid>
            </Grid>
            {multipleEvosRootStore.storesIps.map(ip => (
                <Grid key={ip} container item direction="column" spacing={6}>
                    <Grid item>
                        <MultipleEvoTitle ip={ip} />
                    </Grid>
                    <Grid item>
                        <PoolsShareContainer ip={ip} />
                    </Grid>
                    <Grid item>
                        <CurrentSession ip={ip} />
                    </Grid>
                </Grid>
            ))}
        </>
    ));
};

export default SharesTabContent;
