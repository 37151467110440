import Request from "api/Request";

const GetNasSessions = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Sessions",
    method: "get_nas_sessions"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetNasSessions;
