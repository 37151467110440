import React, { useRef } from "react";
import { useStyles } from "./DragLayer.styles";
import { useDragLayer } from "react-dnd";
import PreviewHeader from "./component/PreviewHeader";
import { Grid } from "@material-ui/core";

const DragLayer = ({ children, previewTitle }) => {
    const { isDragging, currentOffset } = useDragLayer(monitor => ({
        currentOffset: monitor.getClientOffset(),
        isDragging: monitor.isDragging()
    }));

    const layerRef = useRef();
    const previewRef = useRef();
    const containerOffset = {
        x: layerRef?.current?.offsetParent ? layerRef.current.offsetParent.offsetLeft : 0,
        y: layerRef?.current?.offsetParent ? layerRef.current.offsetParent.offsetTop : 0
    };

    const classes = useStyles({
        currentOffset,
        containerOffset,
        isDragging,
        childRect: previewRef.current?.getBoundingClientRect()
    });
    return (
        <Grid ref={layerRef} container className={classes.dragLayer}>
            <PreviewHeader ref={previewRef} className={classes.preview} title={previewTitle} />
            {children}
        </Grid>
    );
};

export default DragLayer;
