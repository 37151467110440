import React from "react";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid } from "@material-ui/core";
import TrashIcon from "assets/TrashIcon";

const BulkContent = ({ revokeTokenModal }) => {
    const { t } = useTranslation();
    return (
        <Grid direction={"column"} container>
            <BulkBarFlatButton onClick={revokeTokenModal.open} errorColor icon={<TrashIcon />}>
                {t("admin_settings.long_lived_tokens_card.bulk_bar.button.revoke_token")}
            </BulkBarFlatButton>
        </Grid>
    );
};

export default BulkContent;
