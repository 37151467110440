import React from "react";
import { observer } from "mobx-react";
import SelectBase from "../Select";
import { mapFieldToProps } from "utils/mapFieldToProps";

const Select = props => {
    const { field, id, ...rest } = props;

    return <SelectBase {...mapFieldToProps(field)} error={Boolean(field.error)} helperText={field.error} id={id} {...rest} />;
};

export default observer(Select);
