import React from "react";
import { useStore } from "hooks/useStore";
import createFields from "./createFields";
import createForm from "utils/createForm";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Grid, Button, Typography } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import RadioGroup from "components/RadioGroup";
import Radio from "components/Radio";
import { PEER_LOOKUP_MANUAL, PEER_LOOKUP_AUTOMATIC } from "const/clusterConst";
import { useStyles } from "./AddPeerDialog.styles";
import { useModal } from "hooks/useModal";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";

const AddPeerDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const fields = createFields();
    const unsavedChangesModal = useModal();
    const state = useLocalStore(() => ({
        inProgress: false,
        isSubmitted: false,
        radioValue: PEER_LOOKUP_MANUAL,
        form: createForm({
            fields
        })
    }));
    const {
        store: { clusterStore }
    } = useStore();

    const onDialogOpen = () => {
        state.inProgress = false;
        state.isSubmitted = false;
        unsavedChangesModal.close();
        state.form.reset();
    };

    const closeModal = () => {
        state.form.reset();
        onClose();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            closeModal();
            return;
        }
        unsavedChangesModal.open();
    };

    const handleRadioChange = async e => {
        const inputValue = e.target.value;
        if (inputValue === PEER_LOOKUP_AUTOMATIC) {
            state.form.$("hostName").value && (await clusterStore.fetchServices());
            if (clusterStore.services?.length > 0) {
                state.radioValue = inputValue;
                state.form.$("hostName").set("value", clusterStore.services[0].alias);
                state.form.$("ip").set("value", clusterStore.services[0].ip);
                return;
            }
        }
        state.radioValue = PEER_LOOKUP_MANUAL;
    };

    const onSubmit = async () => {
        state.inProgress = true;
        const isSubmitted = await clusterStore.addPeer({
            ip: state.form.$("ip").value
        });
        state.inProgress = false;

        if (isSubmitted) {
            state.isSubmitted = true;
        }

        return isSubmitted;
    };

    return useObserver(() => (
        <Dialog
            onEnter={onDialogOpen}
            title={t("cluster.add_peer.title")}
            open={open}
            onClose={closeIfNeeded}
            onSubmit={onSubmit}
            isDataChanged={!state.form.isDefault}
            submitBtnLabel={t("common.button.create")}
            inProgress={state.inProgress}
            buttons={[
                <Button key={"cancel_button"} onClick={closeIfNeeded} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>
            ]}
        >
            <UnsavedChangesDialog onConfirm={closeModal} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            <Grid direction={"column"} container>
                <Grid item container alignItems={"center"}>
                    <Grid xs={6} item>
                        <Typography>{t("cluster.add_peer.label.lookup")}</Typography>
                    </Grid>
                    <Grid xs={6} item container>
                        <RadioGroup value={state.radioValue} onChange={handleRadioChange} className={classes.radioGroup}>
                            <Radio value={PEER_LOOKUP_MANUAL} label={t("cluster.add_peer.label.lookup.manual")} />
                            <Radio value={PEER_LOOKUP_AUTOMATIC} label={t("cluster.add_peer.label.lookup.automatic")} />
                        </RadioGroup>
                    </Grid>
                </Grid>

                {state.radioValue === PEER_LOOKUP_AUTOMATIC && (
                    <RowTextInput
                        label={t("cluster.add_peer.label.host_name")}
                        control={
                            <TextInput
                                disabled
                                inputProps={{
                                    autoComplete: "new-name"
                                }}
                                field={state.form.$("hostName")}
                            />
                        }
                    />
                )}
                <RowTextInput
                    label={t("cluster.add_peer.label.ip")}
                    control={
                        <TextInput
                            inputProps={{
                                autoComplete: "new-name"
                            }}
                            field={state.form.$("ip")}
                        />
                    }
                />
            </Grid>
        </Dialog>
    ));
};

export default AddPeerDialog;
