import React from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { useTranslation } from "react-i18next";
import { useLocalStore, observer } from "mobx-react-lite";
import { Grid, Typography } from "@material-ui/core";
import SavedInputPreview from "components/SavedInputPreview";
import SavedInputIndicator from "components/SavedInputIndicator";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { SLINGSHOT_AUTOTAGGING_MACHINE_CARD } from "const/widgetNameConst";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import SetupAutotaggingMachineDialog from "../SetupAutotaggingMachineDialog";
import { useStore } from "hooks/useStore";
import { useLauncher } from "hooks/useLauncher";

const AUTOTAGGING_MACHINE_CARD_ID_PREFIX = `autotagging_machine_card`;

const AutotaggingMachineCard = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${AUTOTAGGING_MACHINE_CARD_ID_PREFIX}` : AUTOTAGGING_MACHINE_CARD_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { slingshotSettingsStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isDialogOpen: false,
        inProgress: false,
        beginProgress() {
            this.inProgress = true;
        },
        endProgress() {
            this.inProgress = false;
        },
    }));

    const { isError } = useLauncher(
        [state.beginProgress, slingshotSettingsStore.fetchAutotaggingMachineSettings, state.endProgress],
        {
            ignoreErrors: false,
            dependencies: [slingshotSettingsStore.isErrorConnectedDBus],
        }
    );

    const closeDialog = () => {
        state.isDialogOpen = false;
    };

    const openDialog = () => {
        state.isDialogOpen = true;
    };

    return (
        <>
            <SetupAutotaggingMachineDialog open={state.isDialogOpen} closeDialog={closeDialog} parentId={idPrefix} />
            <DataReadonlyCard
                control={
                    <MoreMenu>
                        <HomeCardActionMenuItem
                            widget={SLINGSHOT_AUTOTAGGING_MACHINE_CARD}
                            homeCardActionMenuItemId={`${idPrefix}_add_to_home`}
                        />
                    </MoreMenu>
                }
                headerDivider
                onClick={openDialog}
                disabled={isError}
                autoHeight
                title={t("slingshot_page.tabs.settings.machine.autotagging.title")}
            >
                {slingshotSettingsStore.autotaggingMachineSettingsData ? (
                    <Grid container direction={"column"} spacing={5}>
                        <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                            <Grid xs={6} item>
                                <Typography>{t("slingshot_page.tabs.settings.machine.region")}</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                <SavedInputPreview>
                                    {slingshotSettingsStore.autotaggingMachineSettingsData.service_endpoint?.region}
                                </SavedInputPreview>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                            <Grid xs={6} item>
                                <Typography>{t("slingshot_page.tabs.settings.machine.bucket")}</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                <SavedInputPreview>
                                    {slingshotSettingsStore.autotaggingAlias?.decomposed_base_url?.resource}
                                </SavedInputPreview>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                            <Grid xs={6} item>
                                <Typography>{t("slingshot_page.tabs.settings.machine.access_key")}</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                <SavedInputPreview>
                                    {slingshotSettingsStore.autotaggingMachineSettingsData.service_endpoint?.user}
                                </SavedInputPreview>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                            <Grid xs={6} item>
                                <Typography>{t("slingshot_page.tabs.settings.machine.path")}</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                <SavedInputPreview>
                                    {slingshotSettingsStore.autotaggingAlias?.decomposed_base_url?.path}
                                </SavedInputPreview>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                            <Grid xs={6} item>
                                <Typography>{t("slingshot_page.tabs.settings.machine.transcoding")}</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                <SavedInputIndicator
                                    enabled={slingshotSettingsStore.autotaggingMachineSettingsData.defaults?.transcoding}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                            <Grid xs={6} item>
                                <Typography>{t("slingshot_page.tabs.settings.machine.preset")}</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                <SavedInputPreview>{slingshotSettingsStore.autotaggingPreset?.name}</SavedInputPreview>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                            <Grid xs={6} item>
                                <Typography>{t("slingshot_page.tabs.settings.machine.temporary_folder")}</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                <SavedInputPreview>
                                    {(slingshotSettingsStore.autotaggingMachineSettingsData.defaults?.transcoding_folder &&
                                        decodeURIComponent(
                                            slingshotSettingsStore.autotaggingMachineSettingsData.defaults?.transcoding_folder
                                        )) ||
                                        ""}
                                </SavedInputPreview>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder
                        inProgress={state.inProgress}
                        message={t("slingshot_page.tabs.settings.machine.autotagging.placeholder")}
                    />
                )}
            </DataReadonlyCard>
        </>
    );
};

export default observer(AutotaggingMachineCard);
