import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./StatusLabel.style";

const StatusLabel = ({ status }) => {
    const classes = useStyles({ status });

    return (
        <>
            <Grid justify={"flex-start"} container>
                <Grid item>
                    <Grid container alignItems={"center"} className={classes.label}>
                        <Grid item>
                            <Typography component={"span"} className={classes.labelText}>
                                {status}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default StatusLabel;
