import React from "react";

const CalendarIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="evenodd"
                d="M16 2a1 1 0 011 1v1h1a3 3 0 013 3v12a3 3 0 01-3 3H6a3 3 0 01-3-3V7l.005-.176A3 3 0 016 4h1V3a1 1 0 012 0v1h6V3a1 1 0 011-1zm3 11H5v6l.007.117A1 1 0 006 20h12a1 1 0 001-1v-6zm-3 2a1 1 0 010 2h-4a1 1 0 010-2zm-7.293.293a1 1 0 11-1.416 1.414 1 1 0 011.416-1.414zM7 6H6a1 1 0 00-1 1v4h14V7a1 1 0 00-1-1h-1v1a1 1 0 01-2 0V6H9v1a1 1 0 01-2 0V6z"
                opacity="0.4"
            ></path>
        </svg>
    );
};

export default CalendarIcon;
