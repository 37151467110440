const createFields = () => {
    return [
        {
            name: "groupName",
            default: "",
            rules: "required"
        }
    ];
};

export default createFields;
