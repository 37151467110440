import { types } from "mobx-state-tree";

import Request from "api/Request";
import NetBondSettings from "api/net/Types/NetBondSettings";

const SaveNetBondSettings = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "save_net_bond_settings",
    arguments: types.maybe(NetBondSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SaveNetBondSettings;
