import isFieldHasOnlySpaces from "utils/isFieldHasOnlySpacesRule";
import i18n from "i18n";

const createFields = entries => {
    return entries
        .map((entry, index) => [
            {
                name: `client${index}`,
                default: entry.host,
                validators: [isFieldHasOnlySpaces(i18n.t("rule.field_has_only_spaces"))]
            },
            {
                name: `access${index}`,
                default: entry.access
            }
        ])
        .flat(1);
};

export default createFields;
