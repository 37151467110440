import React from "react";

function CheckIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" {...props}>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#21C97E" transform="translate(-670 -385)">
                    <g transform="translate(400 330)">
                        <g transform="translate(270 55)">
                            <circle cx="50" cy="50" r="50" opacity="0.15" />
                            <path
                                fillRule="nonzero"
                                d="M63.883 37.374a3 3 0 014.369 4.108l-.135.144-22.096 22a3 3 0 01-4.135.093l-.138-.133-9.904-10.242a3 3 0 014.17-4.308l.142.137 7.789 8.053 19.938-19.852z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default CheckIcon;
