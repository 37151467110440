import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    subtext: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(15),
        opacity: 0.4,
        fontSize: 14,
        lineHeight: 1.29,
        letterSpacing: "normal"
    },
    masksContainer: {
        padding: theme.spacing(4, 0, 4, 8)
    },
    editMasksButton: {
        paddingLeft: theme.spacing(6),
        "& a": {
            fontSize: 14,
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.29,
            letterSpacing: 0.5
        }
    }
}));
