import { flow, getParent, types } from "mobx-state-tree";
import GetBridges from "api/net/Requests/GetBridges";
import MainSocket from "websocket";
import BridgesArrayResult from "api/net/Responses/BridgesArrayResult";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import { ASC, DESC, NET_SWITCH_INTERFACES, NET_SWITCH_PORT } from "const/sortColumnConst";
import { DHCP_CLIENT_ROLE, DHCP_SERVER_ROLE, STATIC_IP_ROLE } from "const/rolesConst";
import CreateBridge from "api/net/Requests/CreateBridge";
import RemoveBridge from "api/net/Requests/RemoveBridge";
import SaveNetBridgeSettings from "api/net/Requests/SaveNetBridgeSettings";
import RenewDhcpLease from "api/net/Requests/RenewDhcpLease";

const BridgeStore = types
    .model({
        bridgesArrayResult: types.maybe(BridgesArrayResult),
        currentBridgeName: types.maybe(types.string),
    })
    .volatile(() => ({
        orderBy: NET_SWITCH_INTERFACES,
        order: ASC,
        orderByDialog: NET_SWITCH_PORT,
        orderDialog: ASC,
    }))
    .views((self) => ({
        get bridgesArray() {
            return self.bridgesArrayResult?.data || [];
        },
        get sortedBridgesArray() {
            return stableSort(self.bridgesArray, getComparator(self.order, self.orderBy));
        },
        get sortedAllFreeBridgesForAggregation() {
            return stableSort(self.allBridgesForAggregation, getComparator(self.orderDialog, self.orderByDialog));
        },
        get currentBridge() {
            return this.bridgesArray.find((bridge) => bridge.port === self.currentBridgeName);
        },
        get roleOfCurrentPort() {
            if (!this.currentBridge) {
                return "";
            }
            if (this.currentBridge.dhcpServer.enabled) {
                return DHCP_SERVER_ROLE;
            }
            if (this.currentBridge.dhcpClient.enabled) {
                return DHCP_CLIENT_ROLE;
            }
            return STATIC_IP_ROLE;
        },
        get isDhcpClientRole() {
            return self.roleOfCurrentPort === DHCP_CLIENT_ROLE;
        },
        get isDhcpServerRole() {
            return self.roleOfCurrentPort === DHCP_SERVER_ROLE;
        },
        get isStaticIpRole() {
            return self.roleOfCurrentPort === STATIC_IP_ROLE;
        },
        get allBridgesForAggregation() {
            const { ethernetPortsStore } = getParent(self);
            return ethernetPortsStore.allNetIfaces.ifaces.filter(
                (swch) =>
                    !ethernetPortsStore.allNetIfaces.bonds.some((bond) => bond.interfaces.includes(swch.port)) &&
                    !ethernetPortsStore.allNetIfaces.bridges.some((bridge) => bridge.interfaces.includes(swch.port))
            );
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
    }))
    .actions((self) => ({
        fetchBridgesArrayResult: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetBridges.create().init();
                const res = yield self.socket.send(req);
                self.bridgesArrayResult = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        renewDhcpLease: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RenewDhcpLease.create().init(data);
                yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        removeBridge: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RemoveBridge.create().init(data);
                return yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        saveNetBridgeSettings: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SaveNetBridgeSettings.create().init(data);
                const resp = yield self.socket.send(req);
                Object.keys(self.currentBridge).forEach((key) => {
                    if (data[key]) {
                        self.currentBridge[key] = data[key];
                    }
                });
                return resp;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        createBridge: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = CreateBridge.create().init(data);
                yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
            return data;
        }),
        changeSorting(column) {
            if (column === self.orderBy) {
                self.order = self.order === DESC ? ASC : DESC;
            } else {
                self.order = ASC;
                self.orderBy = column;
            }
        },
        changeSortingDialog(column) {
            if (column === self.orderByDialog) {
                self.orderDialog = self.orderDialog === DESC ? ASC : DESC;
            } else {
                self.orderDialog = ASC;
                self.orderByDialog = column;
            }
        },
        setCurrentBridge(bridge) {
            self.currentBridgeName = self.currentBridgeName === bridge ? "" : bridge;
        },
        updateBridgeInStore: (netBridge) => {
            const { ethernetPortsStore } = getParent(self);
            if (self.bridgesArrayResult?.data) {
                const index = self.bridgesArrayResult.data.findIndex((el) => el.port === netBridge.port);
                if (index < 0) {
                    self.bridgesArrayResult.data.push(netBridge);
                } else {
                    self.bridgesArrayResult.data[index] = netBridge;
                }
            }
            ethernetPortsStore.updateBridgeInAllIfacesResult(netBridge);
        },
        removeBridgeFromStore: (removedBridge) => {
            const { ethernetPortsStore } = getParent(self);
            if (self.bridgesArrayResult?.data.length !== 0) {
                self.bridgesArrayResult.data = self.bridgesArrayResult.data.filter((iface) => iface.port !== removedBridge.port);
            }
            ethernetPortsStore.removeBridgeinAllIfacesResult(removedBridge);
        },
    }));

export default BridgeStore;
