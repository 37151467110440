export const DEFAULT_HOST = "127.0.0.1";
export const PAUSE_MINUTES_DEFAULT = 1440;

export const DEFAULT_START_HOUR = "07";
export const DEFAULT_START_MINUTE = "00";
export const DEFAULT_END_HOUR = "22";
export const DEFAULT_END_MINUTE = "00";

export const HOURS_OPTIONS = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23"
];

export const MINUTES_OPTIONS = ["00", "10", "20", "30", "40", "50"];
