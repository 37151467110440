import React, { useEffect } from "react";
import DataCard from "components/DataCard";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react";
import MoreMenu from "components/MoreMenu";
import { MenuItem, Grid, Typography } from "@material-ui/core";
import ShieldWithKeyIcon from "assets/ShieldWithKeyIcon";
import ShieldWithKeyGreyIcon from "assets/ShieldWithKeyGreyIcon";
import { useStyles } from "./ServerCertificateCard.styles";
import CreateCertificateModal from "./components/CreateCertificateModal";
import CreateSelfSignedCertificate from "./components/CreateSelfSignedCertificate";
import ImportCertificateModal from "./components/ImportCertificateModal";
import SigningRequestCard from "./components/SigningRequestCard";
import RenewCertificateCard from "./components/RenewCertificateCard";
import { SELF_SIGNED_CERTIFICATE, CERTIFICATE_SIGNED_REQUEST, RENEW_CERTIFICATE } from "const/createCertificateVariants";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { SERVER_CERTIFICATE_CARD } from "const/widgetNameConst";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { useStore } from "hooks/useStore";
import PromptToDownloadCertificateModal from "./components/PromptToDownloadCertificateModal/PromptToDownloadCertificateModal";
import { useModalCombine } from "hooks/useModalCombine";
import ImportSignedCSR from "./components/ImportSignedCSR/ImportSignedCSR";
import ImportUnsignedCSR from "./components/ImportUnsignedCSR/ImportUnsignedCSR";

const SERVER_CERTIFICATE_CARD_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_server_certificate_card`;

export const IMPORT_SIGNED_CERT = "IMPORT_SIGNED_CERT";
export const IMPORT_UNSIGNED_CERT = "IMPORT_UNSIGNED_CERT";

const ServerCertificateCard = () => {
    const { t } = useTranslation();
    const {
        store: { certificateStore },
    } = useStore();
    useEffect(() => {
        certificateStore.fetchInstalledCertificateInfo();
    }, []);

    const classes = useStyles({ hasInstalledCertificate: certificateStore.installedCertificateInfo });

    const {
        promptToDownloadCertificateModal,
        createCertificateModal,
        createSelfSignedCertificateModal,
        importCertificateModal,
        certificateSignedRequestModal,
        renewModal,
        importSignedCSR,
        importUnsignedCSR,
    } = useModalCombine([
        "promptToDownloadCertificateModal",
        "createCertificateModal",
        "createSelfSignedCertificateModal",
        "importCertificateModal",
        "importSignedCSR",
        "importUnsignedCSR",
        "certificateSignedRequestModal",
        "renewModal",
    ]);

    const onChooseCreateCertificateVariant = (variant) => {
        createCertificateModal.close();

        switch (variant) {
            case SELF_SIGNED_CERTIFICATE:
                createSelfSignedCertificateModal.open();
                break;
            case CERTIFICATE_SIGNED_REQUEST:
                certificateSignedRequestModal.open();
                break;
            case RENEW_CERTIFICATE:
                renewModal.open();
                break;
            default:
                break;
        }
    };

    const onChooseImportCertificateVariant = (variant) => {
        importCertificateModal.close();

        switch (variant) {
            case IMPORT_SIGNED_CERT:
                importSignedCSR.open();
                break;
            case IMPORT_UNSIGNED_CERT:
                importUnsignedCSR.open();
                break;
            default:
                break;
        }
    };

    const exportCertificate = () => {
        certificateStore.exportCertificate();
    };

    const onBackClick = () => {
        certificateSignedRequestModal.close();
        createSelfSignedCertificateModal.close();
        renewModal.close();

        createCertificateModal.open();
    };

    const onBackImportCertificateClick = () => {
        importSignedCSR.close();
        importUnsignedCSR.close();

        importCertificateModal.open();
    };

    const renderMoreMenu = () => {
        return (
            <MoreMenu id={`${SERVER_CERTIFICATE_CARD_ID_PREFIX}_more_menu`}>
                <HomeCardActionMenuItem widget={SERVER_CERTIFICATE_CARD} />
                <MenuItem onClick={createCertificateModal.open} id={`${SERVER_CERTIFICATE_CARD_ID_PREFIX}_create_certificate`}>
                    {t("admin_settings.server_certificate.context_menu.create_certificate")}
                </MenuItem>
                <MenuItem onClick={importCertificateModal.open} id={`${SERVER_CERTIFICATE_CARD_ID_PREFIX}_import_certificate`}>
                    {t("admin_settings.server_certificate.context_menu.import_certificate")}
                </MenuItem>
                {certificateStore.installedCertificateInfo && (
                    <MenuItem onClick={exportCertificate} id={`${SERVER_CERTIFICATE_CARD_ID_PREFIX}_export_certificate`}>
                        {t("admin_settings.server_certificate.context_menu.export_certificate")}
                    </MenuItem>
                )}
            </MoreMenu>
        );
    };

    return useObserver(() => (
        <DataCard headerDivider title={t("admin_settings.server_certificate.title")} headerControl={renderMoreMenu()}>
            <Grid container wrap={"nowrap"} className={classes.certContainer}>
                <Grid item className={classes.sshIcon}>
                    {certificateStore.installedCertificateInfo ? <ShieldWithKeyIcon /> : <ShieldWithKeyGreyIcon />}
                </Grid>
                <Grid item container direction={"column"} alignItems={"flex-start"}>
                    <Typography className={classes.certNameText}>
                        {certificateStore.installedCertificateInfo
                            ? certificateStore.installedCertificateInfo.name
                            : t("admin_settings.server_certificate.placeholder")}
                    </Typography>
                    {certificateStore.installedCertificateInfo && (
                        <Typography>
                            {t("admin_settings.server_certificate.certificate.expiry_date", {
                                date: certificateStore.installedCertificateInfo.expirationDate,
                            })}
                        </Typography>
                    )}
                </Grid>
            </Grid>

            <CreateCertificateModal
                open={createCertificateModal.isOpen}
                onClose={createCertificateModal.close}
                onApply={onChooseCreateCertificateVariant}
            />
            <CreateSelfSignedCertificate
                open={createSelfSignedCertificateModal.isOpen}
                onClose={createSelfSignedCertificateModal.close}
                handleEnableHttps={promptToDownloadCertificateModal.open}
                onBack={onBackClick}
            />
            <PromptToDownloadCertificateModal
                open={promptToDownloadCertificateModal.isOpen}
                onClose={promptToDownloadCertificateModal.close}
            />
            <SigningRequestCard
                open={certificateSignedRequestModal.isOpen}
                onClose={certificateSignedRequestModal.close}
                onBack={onBackClick}
            />
            <RenewCertificateCard open={renewModal.isOpen} onClose={renewModal.close} onBack={onBackClick} />
            <ImportCertificateModal
                open={importCertificateModal.isOpen}
                onClose={importCertificateModal.close}
                onApply={onChooseImportCertificateVariant}
                onBack={onBackImportCertificateClick}
            />
            <ImportSignedCSR
                open={importSignedCSR.isOpen}
                onClose={importSignedCSR.close}
                onBack={onBackImportCertificateClick}
            />
            <ImportUnsignedCSR
                open={importUnsignedCSR.isOpen}
                onClose={importUnsignedCSR.close}
                onBack={onBackImportCertificateClick}
            />
        </DataCard>
    ));
};

export default ServerCertificateCard;
