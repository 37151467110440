import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        borderRadius: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        position: "relative",
        "&& > *": {
            opacity: 0.7
        }
    },
    fullHeight: {
        height: "100%"
    },
    overlay: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
    title: {
        fontSize: 60
    },
    hideButton: {
        position: "absolute",
        bottom: theme.spacing(3),
        right: theme.spacing(3)
    }
}));
