import React from "react";

const NotistackSuccessIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#FFF"
                fillRule="evenodd"
                d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 100 16 8 8 0 000-16zm2.941 5.086a1 1 0 011.414 1.414l-4.412 4.403a1 1 0 01-1.414 0l-2.348-2.45a1 1 0 011.414-1.414l1.641 1.743z"
            ></path>
        </svg>
    );
};

export default NotistackSuccessIcon;
