import { ADMIN } from "const/userRolesConst";
import { URL_SHARES, URL_SHARES_REMOTE_TAB } from "routes";

const disableByRole = (accessRoles = []) => currentRole => !accessRoles.includes(currentRole);

export default [
    {
        value: URL_SHARES,
        label: "share.tabs.shares"
    },
    {
        value: URL_SHARES_REMOTE_TAB,
        label: "share.tabs.remote_shares",
        disableByRole: disableByRole(ADMIN)
    }
];
