import { types } from "mobx-state-tree";

import HostsAce from "api/security/Types/HostsAce";

const SetAllowedHostsArguments = types.model({
    shareName: types.string,
    acl: types.array(HostsAce)
});

export default SetAllowedHostsArguments;
