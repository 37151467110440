import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetArchivedInstances from "api/slingshot/archived_instances/Requests/GetArchivedInstances";
import GetArchivedInstancesResult from "api/slingshot/archived_instances/Responses/GetArchivedInstancesResult";
import GetArchivedInstancesArguments from "api/slingshot/archived_instances/Types/GetArchivedInstancesArguments";
import DeleteInstance from "api/slingshot/archived_instances/Requests/DeleteInstance";
import { ARCHIVED_INSTANCES_ID, ASC, DESC } from "const/sortColumnConst";

const GET_ARCHIVED_INSTANCES_ARGUMENTS_DEFAULT = {
    sort: ARCHIVED_INSTANCES_ID,
    sort_dir: ASC.toUpperCase(),
    page: 0,
    limit: 10,
    stats: false
};

const SearchType = types.model({
    sort: types.string,
    sort_dir: types.string,
    page: types.number,
    limit: types.number,
    stats: types.boolean
});

const ArchivedInstancesStore = types
    .model({
        archivedInstancesStore: types.maybe(GetArchivedInstancesResult),
        getArchivedInstancesArguments: types.optional(SearchType, GET_ARCHIVED_INSTANCES_ARGUMENTS_DEFAULT),
        currentInstanceId: types.maybeNull(types.number)
    })
    .views(self => ({
        get instances() {
            return self.archivedInstancesStore?.data?.json?.instances || [];
        },
        get instancesCount() {
            return self.archivedInstancesStore?.data?.json?.total || 0;
        },
        get currentInstance() {
            return self.currentInstanceId && self.instances.find(inst => inst.id === self.currentInstanceId);
        }
    }))
    .actions(self => ({
        fetchArchivedInstances: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const payload = GetArchivedInstancesArguments.create({
                    sort: self.getArchivedInstancesArguments.sort,
                    sort_dir: self.getArchivedInstancesArguments.sort_dir,
                    offset: self.getArchivedInstancesArguments.page * self.getArchivedInstancesArguments.limit,
                    limit: self.getArchivedInstancesArguments.limit,
                    stats: self.getArchivedInstancesArguments.stats
                });
                const req = GetArchivedInstances.create().init({ ...payload });
                self.archivedInstancesStore = yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        deleteInstance: flow(function*(instanceId) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DeleteInstance.create().init({ json: { instanceId } });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        changeSorting(column) {
            if (column === self.getArchivedInstancesArguments.sort) {
                self.getArchivedInstancesArguments.sort_dir =
                    self.getArchivedInstancesArguments.sort_dir === DESC.toUpperCase() ? ASC.toUpperCase() : DESC.toUpperCase();
            } else {
                self.getArchivedInstancesArguments.sort_dir = ASC.toUpperCase();
                self.getArchivedInstancesArguments.sort = column;
            }
            self.fetchArchivedInstances();
        },
        updateLimit(limit) {
            self.getArchivedInstancesArguments.limit = limit;
        },
        updatePage(page) {
            self.getArchivedInstancesArguments.page = page;
            self.fetchArchivedInstances();
        },
        setCurrentInstanceId(id) {
            self.currentInstanceId = id;
        }
    }));

export default ArchivedInstancesStore;
