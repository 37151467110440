import { types } from "mobx-state-tree";

import ProgressTask from "api/task/Types/ProgressTask";

const ProgressTaskPage = types.model({
    data: types.array(ProgressTask),
    total: types.number
});

export default ProgressTaskPage;
