import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import DataCard from "components/DataCard";
import { useTranslation } from "react-i18next";
import ShieldWithKeyIcon from "assets/ShieldWithKeyIcon";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import FileUpload from "components/FileUpload/FileUpload";
import { useStore } from "hooks/useStore";

const TechnicalSupportCard = () => {
    const { t } = useTranslation();
    const {
        store: { sshAccessStore },
    } = useStore();

    const state = useLocalStore(() => ({
        currentFile: null,
        inProgress: false,
        fileValidationInfo: false,
        setFileValidationInfo(fileValidationInfo) {
            state.fileValidationInfo = fileValidationInfo;
        },
    }));

    useEffect(() => {
        sshAccessStore.fetchExpirationDate();
    }, []);

    const onFileSelect = (file) => {
        state.currentFile = file;
    };

    const uploadFile = async () => {
        state.inProgress = true;
        const res = await sshAccessStore.uploadFile(state.currentFile);
        if (res) {
            state.currentFile = null;
        }
        state.inProgress = false;
    };

    return useObserver(() => (
        <DataCard autoHeight title={t("technical_support.title")} headerDivider>
            <Grid container direction={"column"} spacing={4}>
                {sshAccessStore.expirationDate && (
                    <Grid item>
                        <Typography>{t("technical_support.card.ssh_date", { date: sshAccessStore.expirationDate })}</Typography>
                    </Grid>
                )}
                <Grid item>
                    <FileUpload
                        file={state.currentFile}
                        loading={state.inProgress}
                        handleFile={onFileSelect}
                        icon={state.currentFile ? ShieldWithKeyIcon : undefined}
                        regExpExtension={/\.gpg/}
                        inputProps=".gpg"
                        noEmpty
                        onValid={state.setFileValidationInfo}
                    />
                </Grid>
                <Grid item container justify={"flex-end"}>
                    <AnimatedSubmitButton
                        inProgress={state.inProgress}
                        disabled={state.inProgress || !state.fileValidationInfo.isValid}
                        label={t("common.button.upload")}
                        submit={uploadFile}
                        isSubmitted={state.isSubmitted}
                    />
                </Grid>
            </Grid>
        </DataCard>
    ));
};

export default TechnicalSupportCard;
