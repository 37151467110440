import React from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./ButtonContainer.styles";

const ButtonContainer = props => {
    const classes = useStyles();
    return (
        <Grid className={classes.buttonContainer} container>
            {props.children}
        </Grid>
    );
};

export default ButtonContainer;
