import { types } from "mobx-state-tree";

const RemoveBricksNode = types.model({
    files: types.number,
    size: types.number,
    scanned: types.number,
    failures: types.number,
    skipped: types.number,
    status: types.number,
    runtime: types.number,
    statusStr: types.string,
    peerName: types.string,
    peerAlias: types.string
});

export default RemoveBricksNode;
