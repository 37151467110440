import { types } from "mobx-state-tree";

import VolumeInfo from "api/pool/Types/VolumeInfo";

const GetExpandableVolumesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(VolumeInfo)
});

export default GetExpandableVolumesResult;
