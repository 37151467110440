import { types } from "mobx-state-tree";

const TransportingConfigType = types.model({
    json: types.model({
        queuePollInterval: types.maybe(types.number),
        smartTransferPollInterval: types.maybe(types.number),
        retryLimit: types.maybe(types.number),
        smbEnabled: types.maybe(types.boolean),
        smbMaxProto: types.maybe(types.string),
        modifyWindow: types.maybe(types.number),
        pipeCloseTimeout: types.maybe(types.number),
        defaultSpeedLimitBytes: types.maybe(types.number),
        logStorage: types.model({
            expired: types.maybe(types.number),
            archived: types.maybe(types.number),
            share: types.maybe(types.string),
            folder: types.maybe(types.string)
        })
    })
});

export default TransportingConfigType;
