import React from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { useStore } from "hooks/useStore";
import { useTranslation } from "react-i18next";
import { useStyles } from "./VolumeBrick.styles";
import { Grid, Typography, Box, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ClusterBrickIcon from "assets/ClusterBrickIcon";
import ChevronUpIcon from "assets/ChevronUpIcon";
import clsx from "clsx";
import SavedInputIndicator from "components/SavedInputIndicator";

const VolumeBrick = ({ brick }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const state = useLocalStore(() => ({
        advancedExpanded: false
    }));
    const {
        store: { clusterStore }
    } = useStore();

    const peerAlias = peerName => {
        return clusterStore.peers.find(peer => peer.name === peerName)?.alias || "";
    };

    return useObserver(() => (
        <Grid item className={classes.brickContainer}>
            <Accordion
                className={classes.expansionPanel}
                square
                expanded={state.advancedExpanded}
                onChange={(event, newExpanded) => {
                    state.advancedExpanded = newExpanded;
                }}
            >
                <AccordionSummary className={classes.expansionPanelContent}>
                    <Grid item container wrap={"nowrap"} alignItems={"center"}>
                        <Box mr={2} mb={-1}>
                            <ClusterBrickIcon />
                        </Box>
                        <Typography variant={"subtitle2"}>{brick.name}</Typography>
                    </Grid>
                    <Box className={classes.indicator} display="flex" flexWrap="nowrap" mr={1}>
                        <SavedInputIndicator enabled={brick.isOnline} hideLabel />
                        <Typography>{brick.isOnline ? t("cluster.volume.online") : t("cluster.volume.offline")}</Typography>
                    </Box>
                    <ChevronUpIcon
                        className={clsx(!state.advancedExpanded && classes.expansionIconClose, classes.expansionIcon)}
                    />
                </AccordionSummary>
                <AccordionDetails className={classes.detailsRoot}>
                    <Grid direction={"column"} container spacing={4}>
                        <Grid container item direction={"column"} spacing={1}>
                            <Grid item>
                                <Typography variant={"body2"}>{t("cluster.volumes.brick.header.host_name")}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"subtitle2"}>{peerAlias(brick.peer)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction={"column"} spacing={1}>
                            <Grid item>
                                <Typography variant={"body2"}>{t("cluster.volumes.brick.header.path")}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"subtitle2"}>{brick.path}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction={"column"} spacing={1}>
                            <Grid item>
                                <Typography variant={"body2"}>{t("cluster.volumes.brick.header.path")}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"subtitle2"} className={classes.type}>
                                    {brick.type}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    ));
};

export default VolumeBrick;
