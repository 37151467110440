import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./GridRowsExpand.styles";

const GridRowsExpand = ({ onClick, children, icon, iconPosition = "left", hideDivider }) => {
    const classes = useStyles({ iconPosition, hideDivider });
    return (
        <Grid className={classes.footerCell} colSpan={"100%"}>
            <Grid container className={classes.container} onClick={onClick}>
                {icon && (
                    <Grid item>
                        <span className={classes.iconContainer}>{icon}</span>
                    </Grid>
                )}
                <Grid item>
                    <Typography color={"primary"} variant={"button"}>
                        {children}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GridRowsExpand;
