import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => ({
    content: {
        marginLeft: theme.spacing(3),
        marginTop: "auto",
        marginBottom: "auto",
        flexGrow: 1,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        wordBreak: "break-word",
    },
    popover: {
        pointerEvents: "none",
    },
    paper: {
        padding: theme.spacing(1),
    },
    shadow: {
        boxShadow: `0px 0 8px 8px ${hexToRgba(theme.palette.buttonLine.background.textShadow, 1)};`,
        height: "100%",
        width: "0px",
    },
    container: {
        flexWrap: "nowrap",
    },
    containerOut: {
        height: 56,
        padding: theme.spacing(4),
        borderRadius: 4,
        backgroundImage: `linear-gradient(
            95deg, 
            ${hexToRgba(theme.palette.buttonLine.background.gradient1, 1)}, 
            ${hexToRgba(theme.palette.buttonLine.background.gradient2, 1)} 
            106%);`,
        flexWrap: "nowrap",
        cursor: "pointer",
    },
}));
