import { REGION_ENUM } from "const/regionConst";
import { types } from "mobx-state-tree";

const SCHEME_TYPE_ARRAY = ["alias", "ld", "evo", "afp", "aiml", "azure", "dropbox", "ftp", "s3", "smb", "tricaster", "gcs"];

const StartRegistrationType = types.model({
    json: types.model({
        volume_name: types.maybeNull(types.string),
        decomposed_url: types.maybe(
            types.model({
                schema: types.maybe(types.enumeration(SCHEME_TYPE_ARRAY)),
                host: types.maybeNull(types.string),
                region: types.maybeNull(types.enumeration(Object.values(REGION_ENUM))),
                resource: types.maybe(types.string),
                path: types.maybe(types.string)
            })
        ),
        primary_alias: types.maybe(
            types.model({
                id: types.maybe(types.number)
            })
        ),
        engine: types.maybe(types.string),
        secret: types.maybeNull(
            types.model({
                password: types.maybeNull(types.string),
                user: types.maybe(types.string)
            })
        )
    })
});

export default StartRegistrationType;
