import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const FORMAT_VOLUME_DIALOG_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_format_volume_dialog`;

const FormatVolumeDialog = ({ pool, volume, onClose, onAccept, open }) => {
    const { t } = useTranslation();

    return (
        <Dialog maxWidth={"sm"} fullWidth onClose={onClose} open={open}>
            <DialogTitle>{t("share.format_volume_dialog.title")}</DialogTitle>
            <DialogContent dividers>
                <Typography>{t("share.format_volume_dialog.main_text", { pool: pool, volume: volume })}</Typography>
                <p />
                <Typography>{t("share.format_volume_dialog.confirmation_question")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={onClose}
                    id={`${FORMAT_VOLUME_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
                <Button
                    color={"primary"}
                    variant={"contained"}
                    onClick={onAccept}
                    id={`${FORMAT_VOLUME_DIALOG_ID_PREFIX}_confirm`}
                >
                    {t("share.format_volume_dialog.button.confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FormatVolumeDialog;
