import { types } from "mobx-state-tree";

import Request from "api/Request";
import ImportKeyArguments from "api/sshAccess/Types/ImportKeyArguments";

const ImportKey = Request.props({
    path: "/com/studionetworksolutions/evo/access/Ssh",
    method: "import_key",
    arguments: types.maybe(ImportKeyArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ImportKey;
