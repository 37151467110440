import React, { useEffect, useRef } from "react";
import { Button, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./AddUserDialog.styles";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import createFields from "./createFields";
import createForm from "utils/createForm";
import toLowerCaseAndLimitedLength from "utils/toLowerCaseAndLimitedLength";
import userNameRule from "utils/userNameRule";
import passwordRule from "utils/passwordRule";
import { USER_NAME_LENGTH } from "const/validationConst";
import TextInput from "components/MobxForm/TextInput";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import Checkbox from "components/Checkbox";
import AnimatedSubmitButton from "components/AnimatedSubmitButton/AnimatedSubmitButton";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";

/**
 *
 * @typedef {string[]} User
 */
/**
 * @param {{open: boolean, onClose: function, initialValues: AddUserDialog.InitialValue, onAddUser: (param: User) => void | undefined}} param0
 */

const ADD_USER_DIALOG_ID_PREFIX = "add_user_dialog";

const AddUserDialog = ({ open, onClose, initialValues, onAdd, parentId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const idPrefix = parentId ? `${parentId}_${ADD_USER_DIALOG_ID_PREFIX}` : ADD_USER_DIALOG_ID_PREFIX;

    const fields = createFields();
    const {
        store: { userStore },
    } = useStore();

    const users = useRef([]);

    const handleClose = () => {
        onClose();
        state.form.reset();
        state.form.$("name").resetValidation();
        state.form.$("password").resetValidation();
        userStore.searchOnUsersPage();
        state.reset();
        if (onAdd && typeof onAdd === "function" && users.current.length) {
            onAdd(users.current);
        }
        users.current = [];
    };

    const firstFieldRef = useRef({});

    const state = useLocalStore(() => ({
        form: createForm({
            fields,
            rules: { ...userNameRule, ...passwordRule },
            initialValues,
        }),
        isSubmitted: false,
        addMultiple: false,
        reset() {
            this.isSubmitted = false;
            this.addMultiple = false;
        },
    }));

    const unsavedChangesModal = useModal();

    const onSubmit = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        const payload = {
            name: state.form.$("name").value,
            password: state.form.$("password").value,
        };
        state.isSubmitted = !!(await userStore.addUser(payload));
        if (state.isSubmitted) {
            if (state.addMultiple) {
                state.form.reset();

                // @TODO: need to refactor for using lib features
                const innerTextInput = firstFieldRef.current?.querySelector?.("input");

                if (innerTextInput) {
                    innerTextInput.focus();
                }

                state.form.$("name").resetValidation();
                state.form.$("password").resetValidation();
                setTimeout(() => (state.isSubmitted = false), 1000);
            } else {
                setTimeout(handleClose, 1000);
            }
            users.current.push(payload.name);
        }
    };

    const onChange = (e) => {
        state.form.$("name").value = e.target.value && toLowerCaseAndLimitedLength(e.target.value, USER_NAME_LENGTH);
    };

    const onEnter = () => {
        state.form.$("name").set("default", "");
        state.form.$("name").resetValidation();
        state.isSubmitted = false;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            handleClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const handleMultipleCheck = (e) => {
        state.addMultiple = e.target.checked;
    };

    const keyDownHandler = (e) => {
        e.key === "Enter" && onSubmit(e);
        e.key === "Escape" && closeIfNeeded();
    };

    useEffect(() => {
        // @TODO: need to refactor for using lib features
        const innerTextInput = firstFieldRef.current?.querySelector?.("input");

        if (innerTextInput) {
            innerTextInput.focus();
        }
    }, [state.addMultiple]);

    return useObserver(() => (
        <Dialog
            title={t("users.add_user.title")}
            open={open}
            onClose={closeIfNeeded}
            submitBtn={
                <AnimatedSubmitButton
                    label={t("users.add_user.button.add")}
                    isSubmitted={state.isSubmitted}
                    submit={onSubmit}
                    id={`${idPrefix}_add_user`}
                />
            }
            onEnter={onEnter}
            onKeyDown={keyDownHandler}
            buttons={[
                <FormControlLabel
                    key={"multipleCheckboxContainer"}
                    className={classes.multipleCheckboxContainer}
                    control={
                        <Checkbox
                            tabIndex={"-1"}
                            value={state.addMultiple}
                            onChange={handleMultipleCheck}
                            id={`${idPrefix}_add_multiple`}
                        />
                    }
                    label={t("disks_and_pools.add_disk_dialog.add_multiple")}
                />,
                <Button
                    key={"cancel_button"}
                    onClick={handleClose}
                    variant={"contained"}
                    color={"secondary"}
                    id={`${idPrefix}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>,
            ]}
        >
            <Grid container direction={"column"}>
                <RowTextInput
                    label={t("users.add_user.label.name")}
                    control={
                        <TextInput
                            tabIndex={"-1"}
                            autoFocus
                            innerRef={firstFieldRef}
                            inputProps={{
                                autoComplete: "new-name",
                            }}
                            field={state.form.$("name")}
                            onChange={onChange}
                            id={`${idPrefix}_name`}
                        />
                    }
                />
                <Grid container alignContent={"center"} xs={6} item>
                    <Typography variant={"body2"} className={classes.helper}>
                        {t("users.add_user.label.name_hint")}
                    </Typography>
                </Grid>

                <RowTextInput
                    label={t("users.add_user.label.password")}
                    control={
                        <TextInput
                            tabIndex={"-1"}
                            inputProps={{
                                autoComplete: "new-password",
                            }}
                            field={state.form.$("password")}
                            id={`${idPrefix}_password`}
                        />
                    }
                />
                <RowTextInput
                    label={t("users.add_user.label.confirm_password")}
                    control={<TextInput field={state.form.$("confirmPassword")} id={`${idPrefix}_confirm_password`} />}
                />
            </Grid>
            <UnsavedChangesDialog onConfirm={handleClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default AddUserDialog;
