import { types } from "mobx-state-tree";

import EvoIdentity from "api/support_info/Types/EvoIdentity";

const GetIdentityResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(EvoIdentity)
});

export default GetIdentityResult;
