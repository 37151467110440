import i18n from "i18n";

const shouldBePasswordsEqual = target => {
    return ({ field, form }) => {
        const fieldsAreEquals = form.$(target).value === field.value;
        return [fieldsAreEquals, i18n.t("rule.equals.password_not_equal")];
    };
};

export default shouldBePasswordsEqual;
