import Request from "api/Request";

const GetShortcuts = Request.props({
    path: "/com/studionetworksolutions/evo/manage/UiSettings",
    method: "get_shortcuts"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetShortcuts;
