/* eslint-disable no-console */
import { types } from "mobx-state-tree";
import ServerError from "./models/ServerError";

const ProcessingStore = types
    .model({
        errorsList: types.array(ServerError),
        displayedErrors: types.array(types.number),
        successesList: types.array(ServerError),
        displayedSuccesses: types.array(types.number),
        warningsList: types.array(ServerError),
        displayedWarnings: types.array(types.union(types.string, types.number)),
        loading: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        setError(message, options) {
            if (message instanceof TypeError || message?.message?.match(/\[mobx-state-tree\]/)) {
                console.error(message); //TODO: add logger
            }

            self.errorsList.push({
                key: new Date().getTime() + Math.random(),
                message: message.message || message,
                options: { variant: "error", ...options },
            });
        },
        setDisplayedError(key) {
            self.displayedErrors.push(key);
        },
        removeError(key) {
            self.errorsList.remove(key);
        },
        setSuccess(message) {
            self.successesList.push({
                key: new Date().getTime() + Math.random(),
                message: message.message || message,
                options: { variant: "success" },
            });
        },
        setDisplayedSuccesses(key) {
            self.displayedSuccesses.push(key);
        },
        removeSuccess(key) {
            self.successesList.remove(key);
        },
        setWarning(message, key, options = {}) {
            self.warningsList.push({
                key: key || new Date().getTime() + Math.random(),
                message: message.message || message,
                options: { variant: "warning", ...options },
            });
        },
        removeWarning(key) {
            self.warningsList.remove(key);
        },
        removeDisplayedWarnings(key) {
            self.displayedWarnings = self.displayedWarnings.filter((k) => k !== key);
        },
        setDisplayedWarnings(key) {
            self.displayedWarnings.push(key);
        },
        setLoading(loading) {
            self.loading = loading;
        },
    }));

export default ProcessingStore;
