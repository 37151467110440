import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => ({
    container: {
        "&& button:not([disabled])>svg, span>svg": {
            borderRadius: theme.spacing(1),
            "& path": {
                fill: theme.palette.primary.main
            },
            "&:hover": {
                cursor: "pointer",
                border: `1px solid ${hexToRgba(theme.palette.primary.main, 0.15)}`
            },
            "&:active": {
                backgroundColor: hexToRgba(theme.palette.primary.main, 0.15)
            }
        },
        "& path": {
            fill: theme.palette.grey["300"]
        }
    }
}));
