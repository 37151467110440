import React from "react";
import { useTranslation } from "react-i18next";
import createFields from "./createFields";
import { useLocalStore } from "mobx-react";
import createForm from "utils/createForm";
import { Button, Grid } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import { useObserver } from "mobx-react-lite";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import Select from "components/MobxForm/Select";
import FolderBrowser from "../../../FolderBrowser";
import sortByLocaleCompare from "utils/sortByLocaleCompare";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";

const SET_DESTINATION_MODAL_ID_PREFIX = `set_proxy_destination_modal`;

const SetProxyDestinationModal = ({ onClose, open, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${SET_DESTINATION_MODAL_ID_PREFIX}` : SET_DESTINATION_MODAL_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { indexingConfigStore, slingshotStorageStore },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields() }),
        isSubmitted: false,
        get destination() {
            if (this.form.$("share").value.length === 0) return "";
            if (this.form.$("path").value.length === 0) return this.form.$("share").value;
            if (
                this.form.$("path").value.length === 1 &&
                (this.form.$("path").value[0] === "/" || this.form.$("path").value[0] === ".")
            )
                return this.form.$("share").value;
            if (this.form.$("path").value[0] === "/" || this.form.$("path").value[0] === ".") {
                return [this.form.$("share").value, this.form.$("path").value.substr(1)].join("/");
            }
            return [this.form.$("share").value, this.form.$("path").value].join("/");
        },
        get shares() {
            return (
                (slingshotStorageStore.shares.length &&
                    sortByLocaleCompare(slingshotStorageStore.shares.map((share) => share.name))) ||
                []
            );
        },
    }));

    const { unsavedChangesModal, folderBrowserModal } = useModalCombine(["unsavedChangesModal", "folderBrowserModal"]);

    const updateForm = () => {
        slingshotStorageStore.fetchShares();
        state.form.$("share").set("default", indexingConfigStore.destinationPath.destinationShare);
        state.form.$("path").set("default", indexingConfigStore.destinationPath.destinationRelPath);
        state.form.reset();
        state.isSubmitted = false;
        unsavedChangesModal.close();
    };
    const submit = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        const isSubmitted = await indexingConfigStore.setProxyDestination(state.destination);
        isSubmitted && (state.isSubmitted = true);
        return isSubmitted;
    };

    const onSelectChange = (e) => {
        state.form.$("share").set("value", e.target.value);
        state.form.$("path").clear();
    };

    const setPath = (path) => {
        state.form.$("path").set("value", path);
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            maxWidth="md"
            title={t("slingshot.index_proxy.proxy_destination.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            open={open}
            onSubmit={submit}
            onEnter={updateForm}
            onClose={closeIfNeeded}
            hide={folderBrowserModal.isOpen}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"}>
                <RowTextInput
                    label={t("slingshot.index_proxy.proxy_destination.label.share_to_store")}
                    control={
                        <Select
                            onChange={onSelectChange}
                            options={state.shares}
                            field={state.form.$("share")}
                            id={`${idPrefix}_share`}
                        />
                    }
                />
                <RowTextInput
                    label={t("slingshot.index_proxy.proxy_destination.label.relative_path")}
                    control={
                        <Grid container wrap="nowrap" alignItems="center" spacing={2}>
                            <Grid item container>
                                <TextInput field={state.form.$("path")} id={`${idPrefix}_path`} />
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={!state.form.$("share").value}
                                    variant="contained"
                                    color="primary"
                                    onClick={folderBrowserModal.open}
                                    id={`${idPrefix}_open_folder_browser`}
                                >
                                    {t("common.button.browse")}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                />
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            <FolderBrowser
                parentName={t("slingshot.index_proxy.proxy_destination.title")}
                open={folderBrowserModal.isOpen}
                onClose={folderBrowserModal.close}
                share={state.form.$("share").value}
                onChoose={setPath}
                pathValue={state.form.$("path").value}
                parentId={idPrefix}
            />
        </Dialog>
    ));
};

export default SetProxyDestinationModal;
