import React, { useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { DNS_CARD } from "const/widgetNameConst";
import DataReadonlyCard from "components/DataReadonlyCard";
import SavedInputPreview from "components/SavedInputPreview";
import SetupDnsDialog from "../SetupDnsDialog";
import { useStore } from "hooks/useStore";

const DnsCard = () => {
    const { t } = useTranslation();
    const {
        store: { dnsStore, authStore }
    } = useStore();
    const state = useLocalStore(() => ({
        openEditDialog: false
    }));

    useEffect(() => {
        dnsStore.fetchDnsServers();
    }, []);

    const closeDialog = () => {
        state.openEditDialog = false;
    };
    const openDialog = () => {
        state.openEditDialog = true;
    };
    return useObserver(() => (
        <>
            <SetupDnsDialog closeDialog={closeDialog} open={state.openEditDialog} />
            <DataReadonlyCard
                title={t("network.dns.title")}
                headerDivider
                onClick={openDialog}
                disabled={!authStore.isAuthorized}
                control={
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={DNS_CARD} />
                    </MoreMenu>
                }
            >
                <Grid container direction={"column"} spacing={5}>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("network.dns.label.primary")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{dnsStore.primary}</SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("network.dns.label.secondary")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{dnsStore.secondary}</SavedInputPreview>
                        </Grid>
                    </Grid>
                </Grid>
            </DataReadonlyCard>
        </>
    ));
};

export default DnsCard;
