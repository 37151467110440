import minSizeValidator from "utils/minSizeValidator";

const createFields = () => {
    return [
        { name: "subdirectory", default: "" },
        { name: "shareName", default: "" },
        { name: "shareComment", default: "" },
        {
            name: "quotaValue",
            default: 0,
            type: "number",
            input: (input) => (input.length ? Number(input) : ""),
            placeholder: "0",
            validators: [minSizeValidator({ min: 0 })],
        },
        { name: "quotaMetric", input: (input) => Number(input) },
        { name: "recycleBinEnabled", default: false, type: "checkbox" },
        { name: "browseable", default: false, type: "checkbox" },
        { name: "protocolValue", default: "smb", type: "radio" },
        { name: "smbAndAfp", default: "smb_only", type: "radio" },
        { name: "accessType", default: "rw", type: "radio" },
        { name: "mode", default: "locked", type: "radio" },
        { name: "projectSharingEnabled", default: false, type: "checkbox" },
    ];
};
export default createFields;
