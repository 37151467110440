import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        display: ({ title }) => (title ? "inherit" : "none"),
        background: theme.palette.background.paper,
        width: 340,
        borderRadius: theme.spacing(2),
        pointerEvents: "none"
    }
}));
