import React, { Fragment, useCallback, useEffect } from "react";
import DataCard from "components/DataCard";
import { Grid, Table, TableCell, TableHead, TableRow } from "@material-ui/core";
import Checkbox from "components/Checkbox";
import { useStyles } from "./FilesTable.styles";
import TableSortLabel from "components/TableSortLabel";
import { MiB_IEC } from "const/diskSizeConst";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useLocalStore } from "mobx-react-lite";
import Select from "components/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import ChevronRightIcon from "assets/ChevronRightIcon";
import {
    FILE_NAVIGATOR_MODIFIED_TIME,
    FILE_NAVIGATOR_NAME,
    FILE_NAVIGATOR_SIZE,
    FILE_NAVIGATOR_TYPE
} from "const/sortColumnConst";
import sortByLocaleCompare from "utils/sortByLocaleCompare";
import useStoreByIp from "hooks/useStoreByIp";
import { useFileNavigatorStoreByIp, useFileNavigatorStoreCommon } from "pages/FileNavigator/FileNavigator.hooks";
import { useTranslation } from "react-i18next";
import RebootIcon from "assets/RebootIcon";
import { observer } from "mobx-react";
import FileTableBody from "./components/FilesTableBody";
import MoreMenu from "components/MoreMenu";
import { URL_FILE_NAVIGATOR_LOG } from "routes";
import { useHistory } from "react-router-dom";
import ViewByRole from "components/ViewByRole";
import { ADMIN } from "const/userRolesConst";

const FilesTable = ({ ip }) => {
    const fileNavigatorStoreCommon = useFileNavigatorStoreCommon();
    const fileNavigator = useFileNavigatorStoreByIp(ip);

    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { shareStore, fileStore } = useStoreByIp({ ip });

    const state = useLocalStore(() => ({
        selectedFiles: [],
        dirs: [],
        shareRoot: "",
        isLoading: false,
        isDeletionInProgress: false,
        step: 0,
        get mainCheckboxStatus() {
            if (fileNavigator.selectedFilesLength && fileNavigator.selectedFilesLength === fileStore.files.length) {
                return "checked";
            } else if (fileNavigator.selectedFilesLength > 0) {
                return "indeterminate";
            }
            return null;
        },
        get shares() {
            return (
                (shareStore.smbShares.length &&
                    sortByLocaleCompare(
                        shareStore.smbShares
                            .filter(share => share.location.pool !== "USB")
                            .map(share => share.attributes.shareName)
                    )) ||
                []
            );
        }
    }));

    useEffect(() => {
        (async () => {
            state.isLoading = true;
            await shareStore.fetchSharesTable();
            state.isLoading = false;
            if (shareStore.smbShares[0]) {
                fileNavigator.setShareRoot(shareStore.smbShares[0].attributes.shareName);
            }
        })();

        return () => {
            fileNavigatorStoreCommon.removeEvo(ip);
        };
    }, []);

    useEffect(() => {
        resetRoot();
    }, [fileNavigator.shareRoot]);

    const openFolderByIndex = index => async () => {
        fileStore.setCurrentFileName(null);
        if (index === 0) {
            resetRoot();
            return;
        }
        state.isLoading = true;
        fileNavigator.backStepDirs(index);
        await fileStore.listFiles({
            shareName: fileNavigator.shareRoot,
            dirOnShare: fileNavigator.getDirOnShare
        });
        state.isLoading = false;
        fileNavigator.clearSelectedFiles();
    };
    const openFolder = useCallback(
        folderName => async e => {
            if (fileNavigator.selectedFilesLength) return;
            fileStore.setCurrentFileName(null);
            e.stopPropagation();
            fileNavigator.addDir(folderName);
            state.isLoading = true;
            const res = await fileStore.listFiles({
                shareName: fileNavigator.shareRoot,
                dirOnShare: fileNavigator.getDirOnShare
            });
            if (!res) {
                fileNavigator.deleteLastDir();
            }
            state.isLoading = false;
            fileNavigator.clearSelectedFiles();
        },
        [fileNavigator.selectedFilesLength]
    );
    const onChangeShareRoot = event => {
        fileNavigator.setShareRoot(event.target.value);
    };
    const resetRoot = async () => {
        fileStore.setCurrentFileName(null);
        if (!fileNavigator.shareRoot) {
            return;
        }
        state.isLoading = true;
        fileNavigator.clearDirs();
        await fileStore.listFiles({
            shareName: fileNavigator.shareRoot,
            dirOnShare: ""
        });
        state.isLoading = false;
        fileNavigator.clearSelectedFiles();
    };

    const handleMainCheckbox = () => {
        switch (state.mainCheckboxStatus) {
            case "checked":
            case "indeterminate":
                fileNavigator.clearSelectedFiles();
                break;
            default: {
                const fileNames = fileStore.files.map(file => file.name);
                fileNavigator.setSelectedFiles(fileNames);
                break;
            }
        }
    };

    const changeSorting = column => () => fileStore.changeSorting(column);

    const navigateToLog = () => {
        history.push(URL_FILE_NAVIGATOR_LOG);
    };

    const renderTitle = () => {
        if (shareStore.smbShares.length === 0) {
            return t("file_navigator.title");
        }
        return (
            <Grid spacing={1} container alignItems={"center"} alignContent={"center"}>
                {fileNavigator.lengthDirs ? (
                    [fileNavigator.shareRoot, ...fileNavigator.dirs].map((dir, index) => (
                        // TODO need to refactor the logic so that it does not depend on the index!!!!
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={`${dir}${index}-${ip}`}>
                            {index !== 0 && (
                                <Grid item>
                                    <Grid container>
                                        <ChevronRightIcon />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid onClick={openFolderByIndex(index)} item>
                                <Typography className={classes.titleDirName} variant={"h2"}>
                                    {dir}
                                </Typography>
                            </Grid>
                        </Fragment>
                    ))
                ) : (
                    <Grid item>
                        <Select
                            className={classes.rootSelect}
                            onChange={onChangeShareRoot}
                            value={fileNavigator.shareRoot}
                            options={state.shares}
                        />
                    </Grid>
                )}
            </Grid>
        );
    };

    return (
        <DataCard
            headerDivider
            emptyPadding
            title={renderTitle()}
            headerControl={[
                <ViewByRole roles={ADMIN} key="file_navigator_more_menu">
                    <MoreMenu id={"file_navigator_more_menu"}>
                        <ButtonBase onClick={navigateToLog}>
                            <Typography className={classes.menuItem}>{t("recycle_bin.menu.file_navigator_log")}</Typography>
                        </ButtonBase>
                    </MoreMenu>
                </ViewByRole>,
                <ButtonBase key="file_navigator_rebut_btn" onClick={openFolderByIndex(fileNavigator.lengthDirs)}>
                    <RebootIcon />
                </ButtonBase>
            ]}
        >
            {state.isLoading ? (
                <Grid className={classes.helperContainer} container>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            ) : (
                <>
                    {fileStore.files.length ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding={"checkbox"}>
                                        <Checkbox
                                            indeterminate={state.mainCheckboxStatus === "indeterminate"}
                                            checked={state.mainCheckboxStatus === "checked"}
                                            onChange={handleMainCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            direction={fileStore.order}
                                            onClick={changeSorting(FILE_NAVIGATOR_NAME)}
                                            active={fileStore.orderBy === FILE_NAVIGATOR_NAME}
                                        >
                                            {t("file_navigator.table.header.label.name")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            direction={fileStore.order}
                                            onClick={changeSorting(FILE_NAVIGATOR_MODIFIED_TIME)}
                                            active={fileStore.orderBy === FILE_NAVIGATOR_MODIFIED_TIME}
                                        >
                                            {t("file_navigator.table.header.label.date_modified")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            direction={fileStore.order}
                                            onClick={changeSorting(FILE_NAVIGATOR_TYPE)}
                                            active={fileStore.orderBy === FILE_NAVIGATOR_TYPE}
                                        >
                                            {t("file_navigator.table.header.label.kind")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align={"right"}>
                                        <TableSortLabel
                                            direction={fileStore.order}
                                            onClick={changeSorting(FILE_NAVIGATOR_SIZE)}
                                            active={fileStore.orderBy === FILE_NAVIGATOR_SIZE}
                                        >
                                            {t("file_navigator.table.header.label.size", { unit: MiB_IEC.unit })}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <FileTableBody
                                ip={ip}
                                openFolder={openFolder}
                                fileNavigator={fileNavigator}
                                fileStore={fileStore}
                                classes={classes}
                            />
                        </Table>
                    ) : (
                        <Grid className={classes.helperContainer} container>
                            <Grid item>
                                <Typography variant={"h2"}>
                                    {shareStore.smbShares.length
                                        ? t("file_navigator.no_files.placeholder")
                                        : t("file_navigator.no_shares.placeholder")}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </DataCard>
    );
};

export default observer(FilesTable);
