import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./RenderDetails.styles";
import { MiB_IEC } from "const/diskSizeConst";
import { RECYCLE_BIN_DATE_FORMAT, SERVER_RECYCLE_BIN_DATE_FORMAT } from "const";
import moment from "moment";
import { equalsIgnoreCase } from "utils/equalsIgnoreCase";
import { useStore } from "hooks/useStore";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const RenderDetails = () => {
    const {
        store: { recycleBinStore },
    } = useStore();
    const classes = useStyles();
    const { t } = useTranslation();

    const getTypeTranslation = (type) => {
        if (equalsIgnoreCase(type, "directory")) return t("recycle_bin.type.folder");
        if (equalsIgnoreCase(type, "file")) return t("recycle_bin.type.file");

        return t("recycle_bin.type.file");
    };

    return (
        <Grid container direction={"column"} className={classes.detailsContainer} spacing={4}>
            {recycleBinStore.currentFile && (
                <>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("recycle_bin.bulk_bar.label.name")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>{recycleBinStore.currentFile.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("recycle_bin.bulk_bar.label.modified")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>
                                {recycleBinStore.currentFile.modifiedTime &&
                                    moment(recycleBinStore.currentFile.modifiedTime, SERVER_RECYCLE_BIN_DATE_FORMAT)
                                        .utc(true)
                                        .format(getLanguageDateFormat(RECYCLE_BIN_DATE_FORMAT))}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("recycle_bin.bulk_bar.label.deleted")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>
                                {recycleBinStore.currentFile.deleteTime &&
                                    moment(recycleBinStore.currentFile.deleteTime, SERVER_RECYCLE_BIN_DATE_FORMAT)
                                        .utc(true)
                                        .format(getLanguageDateFormat(RECYCLE_BIN_DATE_FORMAT))}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("recycle_bin.bulk_bar.label.kind")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>
                                {getTypeTranslation(recycleBinStore.currentFile.type)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("recycle_bin.bulk_bar.label.size")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>{`${(
                                recycleBinStore.currentFile.size / MiB_IEC.value
                            ).toFixed(1)} ${MiB_IEC.unit}`}</Typography>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default RenderDetails;
