import { types } from "mobx-state-tree";
import Request from "api/Request";
import UpdatePresetType from "../Types/UpdatePresetType";

const UpdatePreset = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Transcoding1",
    method: "update_preset",
    arguments: types.maybe(
        types.snapshotProcessor(UpdatePresetType, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json)
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default UpdatePreset;
