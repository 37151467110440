import React from "react";

const CheckThinIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
            <path
                fill="#1E90FF"
                d="M13.236 4.286c.412-.39 1.07-.38 1.47.022.37.37.39.946.066 1.34l-.088.094L7.011 13 2.335 8.802c-.422-.38-.45-1.021-.06-1.433.36-.38.947-.432 1.368-.14l.102.081 3.228 2.9 6.263-5.924z"
            />
        </svg>
    );
};

export default CheckThinIcon;
