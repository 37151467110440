import React, { useCallback, useContext, useEffect } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import DataCard from "components/DataCard";
import { useStyles } from "./NasDiskTestCard.styles";
import { useTranslation } from "react-i18next";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { NAS_DISK_TEST_CARD } from "const/widgetNameConst";
import { Context } from "store";
import { useLocalStore, useObserver } from "mobx-react-lite";
import AnimatedSubmitButton from "components/AnimatedSubmitButton/AnimatedSubmitButton";
import createForm from "utils/createForm";
import createFields from "./createFields";
import Select from "components/MobxForm/Select";
import RowTextInput from "components/RowTextInput/RowTextInput";
import { TestStatus } from "const/selfTestsConst";
import DiskTestLastResultsDialog from "../DiskTestLastResultsDialog";
import { GiB_IEC } from "const/diskSizeConst";
import sortByLocaleCompare from "utils/sortByLocaleCompare";
import { STATUS_OFFLINE } from "const/shareStatusConst";
import { BLOCK_SIZE_OPTIONS } from "const/NASDiskTest/NASDiskTest";

const NasDiskTestCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        store: { supportInfoStore, shareStore },
    } = useContext(Context);

    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields({}) }),
        isDialogOpen: false,
        selectedShares: [],
        get isStartButtonShown() {
            return (
                supportInfoStore.diskSelfTestResult?.status === TestStatus.FINISHED ||
                supportInfoStore.diskSelfTestResult?.status === TestStatus.FAILED ||
                supportInfoStore.diskSelfTestResult?.status === TestStatus.NOTSTARTED
            );
        },

        get isInProgressShown() {
            return (
                supportInfoStore.diskSelfTestResult?.status === TestStatus.STARTED ||
                supportInfoStore.diskSelfTestResult?.status === TestStatus.RUNNING
            );
        },
        get isTestFinished() {
            return supportInfoStore.diskSelfTestResult?.status === TestStatus.FINISHED;
        },
        get LOOP_COUNT_VALUES() {
            return Array.from({ length: 10 }, (_, i) => i + 1);
        },
        get THREADS_COUNT_VALUES() {
            return Array.from({ length: 10 }, (_, i) => i + 1);
        },
        get FILE_SIZES_IN_GB() {
            return Array.from({ length: 10 }, (_, i) => Math.pow(2, i));
        },
        get shares() {
            const allShares = shareStore.allShares
                ?.filter((share) => share.status.status !== STATUS_OFFLINE)
                ?.map((share) => share.shareName);
            return (allShares?.length && sortByLocaleCompare(allShares)) || [];
        },
    }));

    const fileSiZeUnits = GiB_IEC;

    useEffect(() => {
        (async () => {
            await shareStore.fetchSharesTable();
            state.shares?.length && state.selectedShares.push(state.shares[0]);
        })();

        supportInfoStore.fetchDiskTestSettings();
        supportInfoStore.fetchDiskTestResult();
        state.form.$("file_size").set("default", 64);
        state.form.$("block_size").set("default", 1048576);
        state.form.$("loop").set("default", 1);
        state.form.$("threads").set("default", 1);
        state.form.reset();
    }, []);

    const openDialog = useCallback(() => {
        state.isDialogOpen = true;
    });
    const closeDialog = useCallback(() => {
        state.isDialogOpen = false;
    });

    const handleChange = useCallback((event) => {
        state.selectedShares = event.target.value;
    });
    // Callbacks
    const startDiskTest = useCallback(() => {
        const res = supportInfoStore.startDiskTest({
            shares: state.selectedShares,
            fileSize: state.form.$("file_size").value * fileSiZeUnits.value,
            blockSize: state.form.$("block_size").value,
            repetitionCount: state.form.$("loop").value,
            workloadMaxThreads: state.form.$("threads").value,
        });
        res && (state.isSubmitted = true);
        return res;
    });

    const stopDiskTest = useCallback(() => {
        const res = supportInfoStore.stopDiskTest();
        res && (state.isSubmitted = true);
        return res;
    });

    return useObserver(() => (
        <DataCard
            title={t("support.self_test.disk.title")}
            headerDivider
            autoHeight
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={NAS_DISK_TEST_CARD} />
                </MoreMenu>
            }
        >
            <Grid container spacing={4} direction={"column"}>
                <RowTextInput
                    leftXs={4}
                    rightXs={8}
                    control={
                        <Select
                            multiple
                            onChange={handleChange}
                            options={state.shares}
                            field={state.form.$("share")}
                            value={state.selectedShares}
                            renderValue={(selected) => selected.join(", ")}
                        />
                    }
                    label={t("support.self_test.disk.share")}
                />
                <RowTextInput
                    leftXs={4}
                    rightXs={8}
                    control={<Select options={state.FILE_SIZES_IN_GB} field={state.form.$("file_size")} />}
                    label={t("support.self_test.disk.file_size", { units: fileSiZeUnits.unit })}
                />
                {supportInfoStore.diskSelfTestSettings?.advancedModeEnabled && (
                    <RowTextInput
                        leftXs={4}
                        rightXs={8}
                        control={<Select options={BLOCK_SIZE_OPTIONS} field={state.form.$("block_size")} />}
                        label={t("support.self_test.disk.block_size", { units: fileSiZeUnits.unit })}
                    />
                )}
                <RowTextInput
                    leftXs={4}
                    rightXs={8}
                    control={<Select options={state.LOOP_COUNT_VALUES} field={state.form.$("loop")} />}
                    label={t("support.self_test.disk.loop")}
                />
                {supportInfoStore.diskSelfTestSettings?.advancedModeEnabled && (
                    <RowTextInput
                        leftXs={4}
                        rightXs={8}
                        control={<Select options={state.THREADS_COUNT_VALUES} field={state.form.$("threads")} />}
                        label={t("support.self_test.disk.threads")}
                    />
                )}
            </Grid>
            <Grid item container spacing={4}>
                {state.isStartButtonShown ? (
                    <Grid item>
                        <AnimatedSubmitButton submit={startDiskTest} label={t("common.button.start")} />
                    </Grid>
                ) : (
                    <Grid item>
                        <AnimatedSubmitButton submit={stopDiskTest} label={t("common.button.stop")} />
                    </Grid>
                )}
                {state.isInProgressShown && (
                    <Grid item className={classes.lastResultBtnContainer}>
                        <Typography>{t("support.self_test.net.in_progress")}</Typography>
                    </Grid>
                )}
                {state.isTestFinished && (
                    <Grid item className={classes.lastResultBtnContainer}>
                        <Button onClick={openDialog} variant={"contained"} color={"secondary"}>
                            {t("common.button.last_results")}
                        </Button>
                    </Grid>
                )}
            </Grid>
            <DiskTestLastResultsDialog onClose={closeDialog} open={state.isDialogOpen} />
        </DataCard>
    ));
};

export default NasDiskTestCard;
