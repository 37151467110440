import React from "react";
import moment from "moment";
import { Grid, Typography, LinearProgress, Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
    STARTED,
    FINISHED,
    FAILED,
    VOLUME_BUILDING,
    VOLUME_GROW,
    VOLUME_REMOVE,
    VOLUME_PUT_ONLINE,
    VOLUME_PUT_OFFLINE,
    SHARE_REMOVE,
    SHARE_UNMAP,
} from "const/taskConst";
import DiskPlusIcon from "assets/DiskPlusIcon";
import DisksPlusIcon from "assets/DisksPlusIcon";
import DiskCheckedIcon from "assets/DiskCheckedIcon";
import DisksCheckedIcon from "assets/DisksCheckedIcon";
import DiskErrorIcon from "assets/DiskErrorIcon";
import DiskProcessIcon from "assets/DiskProcessIcon";
import DisksProcessIcon from "assets/DisksProcessIcon";
import DisksWarningIcon from "assets/DisksWarningIcon";
import ShareMinusIcon from "assets/ShareMinusIcon";
import SharesMinusIcon from "assets/SharesMinusIcon";
import ShareCheckedIcon from "assets/ShareCheckedIcon";
import SharesCheckedIcon from "assets/SharesCheckedIcon";
import ShareErrorIcon from "assets/ShareErrorIcon";
import SharesWarningIcon from "assets/SharesWarningIcon";
import ShareProcessIcon from "assets/ShareProcessIcon";
import SharesProcessIcon from "assets/SharesProcessIcon";
import { useStyles } from "./TaskItem.style";
import { SERVER_DATE_FORMAT_EN, NOTIFICATION_DATE_FORMAT } from "const";
import i18n from "i18n";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const renderIcon = (type, status, isBatch) => {
    switch (type) {
        case VOLUME_BUILDING:
            switch (status) {
                case STARTED:
                    return isBatch ? <DisksPlusIcon /> : <DiskPlusIcon />;
                case FINISHED:
                    return isBatch ? <DisksCheckedIcon /> : <DiskCheckedIcon />;
                case FAILED:
                    return isBatch ? <DisksWarningIcon /> : <DiskErrorIcon />;
                default:
                    return null;
            }
        case VOLUME_GROW:
            switch (status) {
                case STARTED:
                    return isBatch ? <DisksProcessIcon /> : <DiskProcessIcon />;
                case FINISHED:
                    return isBatch ? <DisksCheckedIcon /> : <DiskCheckedIcon />;
                case FAILED:
                    return isBatch ? <DisksWarningIcon /> : <DiskErrorIcon />;
                default:
                    return null;
            }
        case VOLUME_REMOVE:
            switch (status) {
                case STARTED:
                    return isBatch ? <DisksProcessIcon /> : <DiskProcessIcon />;
                case FINISHED:
                    return isBatch ? <DisksCheckedIcon /> : <DiskCheckedIcon />;
                case FAILED:
                    return isBatch ? <DisksWarningIcon /> : <DiskErrorIcon />;
                default:
                    return null;
            }
        case VOLUME_PUT_ONLINE:
        case VOLUME_PUT_OFFLINE:
            switch (status) {
                case STARTED:
                    return isBatch ? <DisksProcessIcon /> : <DiskProcessIcon />;
                case FINISHED:
                    return isBatch ? <DisksCheckedIcon /> : <DiskCheckedIcon />;
                case FAILED:
                    return isBatch ? <DisksWarningIcon /> : <DiskErrorIcon />;
                default:
                    return null;
            }
        case SHARE_REMOVE:
            switch (status) {
                case STARTED:
                    return isBatch ? <SharesMinusIcon /> : <ShareMinusIcon />;
                case FINISHED:
                    return isBatch ? <SharesCheckedIcon /> : <ShareCheckedIcon />;
                case FAILED:
                    return isBatch ? <SharesWarningIcon /> : <ShareErrorIcon />;
                default:
                    return null;
            }
        case SHARE_UNMAP:
            switch (status) {
                case STARTED:
                    return isBatch ? <SharesProcessIcon /> : <ShareProcessIcon />;
                case FINISHED:
                    return isBatch ? <SharesCheckedIcon /> : <ShareCheckedIcon />;
                case FAILED:
                    return isBatch ? <SharesWarningIcon /> : <ShareErrorIcon />;
                default:
                    return null;
            }
        default:
            return null;
    }
};

const renderMessage = (isBatch, text, total, succeeded, isTotalUnknown, failed) => {
    if (isBatch) {
        return (
            <Grid item container justify="space-between" wrap={"nowrap"}>
                <Grid item>
                    <Typography variant="body2">{text} </Typography>
                </Grid>
                <Grid item>
                    {isTotalUnknown ? (
                        <Box ml={6}>
                            <Typography variant="body2">
                                {i18n.t("notifier.common.processed")}
                                {succeeded + failed}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography variant="body2">{`${succeeded + failed}/${total}`}</Typography>
                    )}
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid item>
            <Typography>{text}</Typography>
        </Grid>
    );
};

const renderProgress = (isBatch, progress, timeLeft, classes) => {
    return (
        <Grid item container justify="space-between" alignItems="center">
            <Grid item className={classes.progressValue}>
                <Typography variant="body2">{`${progress}%`}</Typography>
            </Grid>
            <Grid item className={classes.progress}>
                <LinearProgress variant="determinate" value={progress} />
            </Grid>
            {!isBatch && (
                <Grid item className={classes.timeLeft}>
                    <Typography variant="body2">{timeLeft}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

const TaskItem = ({
    task: { percentage = 0, text, failedText, startTime, timeLeft, type, status, total, succeeded, failed },
}) => {
    const formattedTime = moment(startTime, SERVER_DATE_FORMAT_EN).format(getLanguageDateFormat(NOTIFICATION_DATE_FORMAT));
    const isBatch = total !== undefined && succeeded !== undefined;
    const progress = isBatch ? parseInt(((succeeded + failed) * 100) / total) : percentage;
    const classes = useStyles({ status, isBatch });
    const isTotalUnknown = total === -1;

    return (
        <Grid container direction={"column"} spacing={2} className={classes.container}>
            <Grid item container spacing={2} alignItems={"center"}>
                <Grid item>{renderIcon(type, status, isBatch)}</Grid>
                <Grid item>
                    <Typography className={classes.time}>{formattedTime}</Typography>
                </Grid>
                <Grid item className={classes.spacing} />
            </Grid>
            {renderMessage(isBatch, text, total, succeeded, isTotalUnknown, failed)}
            {!isTotalUnknown && renderProgress(isBatch, progress, timeLeft, classes)}
            {failedText && (
                <Grid item>
                    <Alert className={classes.alert} icon={false}>
                        <Typography variant="body2">{failedText}</Typography>
                    </Alert>
                </Grid>
            )}
        </Grid>
    );
};

export default TaskItem;
