import { types } from "mobx-state-tree";

import MigrationState from "api/upgrade/migration/Types/MigrationState";

const GetMigrationStateResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(MigrationState),
});

export default GetMigrationStateResult;
