import { types } from "mobx-state-tree";

import AdMigrationInfo from "api/upgrade/migration/Types/AdMigrationInfo";

const GetAdMigrationInfoResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(AdMigrationInfo),
});

export default GetAdMigrationInfoResult;
