import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => ({
    expansionPanel: {
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.06),
        borderRadius: theme.spacing(1),
        padding: 0
    },
    expansionPanelContent: {
        "&&": {
            minHeight: 0,
            margin: 0,
            padding: theme.spacing(3)
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
            display: "flex",
            alignItems: "center"
        }
    },
    expansionIcon: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        "& path": {
            fill: theme.palette.primary.main
        }
    },
    expansionIconClose: {
        transform: "rotate(180deg)",
        "& path": {
            fill: theme.palette.grey["300"]
        }
    },
    detailsRoot: {
        borderTop: `1px solid ${theme.palette.grey["TABLE_DIVIDER_GRAY"]}`,
        padding: theme.spacing(3)
    },
    type: {
        textTransform: "capitalize"
    },
    indicator: {
        "& div": {
            margin: 0,
            marginBottom: theme.spacing(-1)
        }
    }
}));
