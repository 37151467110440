import { types } from "mobx-state-tree";

import Shortcut from "api/ui_settings/Types/Shortcut";

const GetShortcutsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(Shortcut)
});

export default GetShortcutsResult;
