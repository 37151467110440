import { makeStyles } from "@material-ui/core/styles";
import { GRAPHICS_BAR_HEIGHT, GRAPHICS_BAR_HEIGHT_MOBILE } from "const/styleConst";
import { HEADER_DIVIDER } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    container: {
        height: GRAPHICS_BAR_HEIGHT_MOBILE,
        justifyContent: "flex-end",
        padding: "0 10px 10px",
        [theme.breakpoints.up("md")]: {
            height: GRAPHICS_BAR_HEIGHT
        }
    },
    graphicItem: {
        [theme.breakpoints.up("md")]: {
            height: "100%",
            "&:not(:first-child)": {
                paddingLeft: theme.spacing(3)
            }
        },
        paddingTop: "10px",
        height: "150px",
        borderTop: HEADER_DIVIDER
    },
    stepper: {
        backgroundColor: "transparent"
    },
    chevron: {
        fill: theme.palette.primary.main
    },
    chevronLeft: {
        transform: "rotate(180deg)"
    },
    buttonCustomDisabled: {
        "&:disabled": {
            visibility: "hidden"
        }
    }
}));
