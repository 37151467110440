import { types } from "mobx-state-tree";

import NetInterfacesInfo from "api/net/Types/NetInterfacesInfo";

const AllIfacesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(NetInterfacesInfo)
});

export default AllIfacesResult;
