const createFields = store => {
    return [
        { name: "timezone", default: store.timezone },
        { name: "time", default: store.time, rules: "time" },
        { name: "server", default: store.server, rules: "host|hostLength" },
        { name: "enableService", default: store.enableServer, type: "checkbox" },
        { name: "enableClient", default: store.enableClient, type: "checkbox" }
    ];
};

export default createFields;
