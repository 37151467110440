import { useEffect } from "react";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import useStoreByIp from "hooks/useStoreByIp";

const Notifier = ({ ip }) => {
    const { processingStore, authStore, name } = useStoreByIp({ ip });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        autorun(() => {
            processingStore.errorsList.forEach((error) => {
                if (processingStore.displayedErrors.includes(error.key)) return;
                enqueueSnackbar(name ? `${name}: ${error.message}` : error.message, error.options);
                processingStore.setDisplayedError(error.key);
                processingStore.removeError(error.key);
            });
        });
        autorun(() => {
            processingStore.successesList.forEach((error) => {
                if (processingStore.displayedSuccesses.includes(error.key)) return;
                enqueueSnackbar(name ? `${name}: ${error.message}` : error.message, error.options);
                processingStore.setDisplayedSuccesses(error.key);
                processingStore.removeSuccess(error.key);
            });
        });
        autorun(() => {
            processingStore.warningsList.forEach((error) => {
                if (processingStore.displayedWarnings.includes(error.key)) return;

                enqueueSnackbar(name ? `${name}: ${error.message}` : error.message, error.options);
                processingStore.setDisplayedWarnings(error.key);
                processingStore.removeWarning(error.key);
            });
        });
    }, [name]);

    useEffect(() => {
        if (!Object.prototype.hasOwnProperty.call(authStore, "needToShowLostConnectionNotifier")) return;
        if (!authStore.needToShowLostConnectionNotifier) {
            closeSnackbar();
        }
    }, [authStore.needToShowLostConnectionNotifier]);

    return null;
};

export default observer(Notifier);
