import React from "react";

const ClusterBrickIcon = props => {
    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient x1="43.4030733%" y1="43.3884298%" x2="50%" y2="56.6115702%" id="clusterBrickIcon-1">
                    <stop stopColor="#A770FF" offset="0%"></stop>
                    <stop stopColor="#85ADFF" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="clusterBrickIcon-2">
                    <stop stopColor="#E19EFF" stopOpacity="0.770596591" offset="0%"></stop>
                    <stop stopColor="#293CFF" stopOpacity="0.6" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="43.4030733%" y1="41.6322314%" x2="50%" y2="58.3677686%" id="clusterBrickIcon-3">
                    <stop stopColor="#A770FF" offset="0%"></stop>
                    <stop stopColor="#85ADFF" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="clusterBrickIcon-4">
                    <stop stopColor="#E19EFF" stopOpacity="0.770596591" offset="0%"></stop>
                    <stop stopColor="#293CFF" stopOpacity="0.6" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="clusterBrickIcon-5">
                    <stop stopColor="#E19EFF" stopOpacity="0.770596591" offset="0%"></stop>
                    <stop stopColor="#295AFF" stopOpacity="0.6" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g i transform="translate(-181.000000, -1175.000000)">
                    <g transform="translate(181.000000, 1175.000000)">
                        <path
                            d="M21,1.5 C21.4142136,1.5 21.7892136,1.66789322 22.0606602,1.93933983 C22.3321068,2.21078644 22.5,2.58578644 22.5,3 L22.5,3 L22.5,8 L2,8.5 L1.5,3 C1.5,2.58578644 1.66789322,2.21078644 1.93933983,1.93933983 C2.21078644,1.66789322 2.58578644,1.5 3,1.5 L3,1.5 Z"
                            id="Rectangle"
                            stroke="url(#clusterBrickIcon-2)"
                            fill="url(#clusterBrickIcon-1)"
                        ></path>
                        <rect
                            id="Rectangle"
                            stroke="url(#clusterBrickIcon-4)"
                            fill="url(#clusterBrickIcon-3)"
                            x="1.5"
                            y="8.5"
                            width="21"
                            height="8"
                        ></rect>
                        <path
                            d="M22.5,15.5 L22.5,21 C22.5,21.4142136 22.3321068,21.7892136 22.0606602,22.0606602 C21.7892136,22.3321068 21.4142136,22.5 21,22.5 L21,22.5 L3,22.5 C2.58578644,22.5 2.21078644,22.3321068 1.93933983,22.0606602 C1.66789322,21.7892136 1.5,21.4142136 1.5,21 L1.5,21 L1.5,15.5 L22.5,15.5 Z"
                            id="Rectangle"
                            stroke="url(#clusterBrickIcon-5)"
                            fill="url(#clusterBrickIcon-1)"
                        ></path>
                        <circle id="Oval" fill="#FFCD8C" cx="5" cy="19" r="2"></circle>
                        <circle id="Oval" fill="#FFCD8C" cx="5" cy="12" r="2"></circle>
                        <circle id="Oval" fill="#FFCD8C" cx="5" cy="5" r="2"></circle>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ClusterBrickIcon;
