import React from "react";

const ExternalGroupIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
            <path
                fill="#1E90FF"
                fillRule="nonzero"
                d="M8 0a3 3 0 011 5.83l-.001 2.689 2.417 1.933a2.977 2.977 0 011.408-.447L13 10a3 3 0 11-2.834 2.013L8.013 10.29l-2.155 1.797c.075.234.122.481.137.737L6 13a3 3 0 11-1.354-2.509L7 8.53V5.829A3 3 0 018 0z"
            ></path>
        </svg>
    );
};

export default ExternalGroupIcon;
