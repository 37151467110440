import React from "react";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import createFields from "./createFields";
import createForm from "utils/createForm";
import TextInput from "components/MobxForm/TextInput";
import Select from "components/MobxForm/Select";
import RadioGroup from "components/MobxForm/RadioGroup";
import Dialog from "components/Dialog";
import { Button, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { PROTOCOL_OPTIONS, SECURITY_OPTIONS } from "const/addExternalShareConst";
import Radio from "components/Radio/Radio";
import { useStyles } from "./AddExternalShareDialog.styles";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import useStoreByIp from "hooks/useStoreByIp";
import sortByLocaleCompare from "utils/sortByLocaleCompare";
import { useModal } from "hooks/useModal";

const AddExternalShareDialog = ({ open, onClose, ip }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { externalSharesStore } = useStoreByIp({ ip });

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        shareOptions: [],
        get isShareDisabled() {
            return this.shareOptions.length === 0;
        },
        isSubmitted: false,
        inProgress: false,
    }));

    const unsavedChangesModal = useModal();

    const onModalEnter = () => {
        state.form.reset();
        state.shareOptions = [];
        state.inProgress = false;
        state.isSubmitted = false;
        state.form.each((field) => field.resetValidation());
    };

    const onRefresh = async () => {
        const payload = {
            host: state.form.$("host").value,
            user: state.form.$("connectAs").value === "registered" ? state.form.$("user").value : "",
            password: state.form.$("connectAs").value === "registered" ? state.form.$("password").value : "",
        };
        state.inProgress = true;
        const res = await externalSharesStore.listSharesOnRemoteHost(payload);
        if (res) {
            state.form.$("share").reset();
            if (res.length > 0) {
                state.shareOptions = res.length && sortByLocaleCompare(res);
                state.form.$("share").set("value", state.shareOptions[0]);
            } else {
                state.shareOptions.length > 0 && (state.shareOptions = []);
            }
            state.isSubmitted = true;
        }
        state.inProgress = false;
    };

    const onAddShare = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        const payload = {
            name: state.form.$("name").value,
            maxProtocol: state.form.$("protocol").value,
            security: state.form.$("security").value,
            host: state.form.$("host").value,
            user: state.form.$("connectAs").value === "registered" ? state.form.$("user").value : "",
            password: state.form.$("connectAs").value === "registered" ? state.form.$("password").value : "",
            share: state.form.$("share").value,
        };
        state.inProgress = true;
        const res = await externalSharesStore.addExternalShare(payload);
        state.inProgress = false;
        return res;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted === true) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            maxWidth={"lg"}
            open={open}
            onEnter={onModalEnter}
            onClose={closeIfNeeded}
            submitBtnLabel={t("common.button.add")}
            onSubmit={onAddShare}
            title={t("share.remote_shares.add_share.title")}
            inProgress={state.inProgress}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary">
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <UnsavedChangesDialog open={unsavedChangesModal.isOpen} onConfirm={onClose} onClose={unsavedChangesModal.close} />
            <Grid className={classes.inputRow} container alignItems={"center"} wrap={"nowrap"} justify={"space-between"}>
                <Grid item xs={3}>
                    <Typography>{t("share.remote_shares.add_share.label.name")}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextInput field={state.form.$("name")} inputProps={{ spellCheck: "false" }} />
                </Grid>
            </Grid>
            <Grid className={classes.inputRow} container alignItems={"center"} wrap={"nowrap"} justify={"space-between"}>
                <Grid item xs={3}>
                    <Typography>{t("share.remote_shares.add_share.label.protocol")}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Select field={state.form.$("protocol")} options={PROTOCOL_OPTIONS} />
                </Grid>
            </Grid>
            <Grid className={classes.inputRow} container alignItems={"center"} wrap={"nowrap"} justify={"space-between"}>
                <Grid item xs={3}>
                    <Typography>{t("share.remote_shares.add_share.label.security")}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Select field={state.form.$("security")} options={SECURITY_OPTIONS} />
                </Grid>
            </Grid>
            <Grid className={classes.inputRow} container alignItems={"center"} wrap={"nowrap"} justify={"space-between"}>
                <Grid item xs={3}>
                    <Typography>{t("share.remote_shares.add_share.label.host")}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextInput field={state.form.$("host")} />
                </Grid>
            </Grid>
            <Grid container wrap={"nowrap"} className={classes.radioContainer}>
                <Grid item xs={3}>
                    <Typography>{t("share.remote_shares.add_share.label.connect_as")}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <RadioGroup field={state.form.$("connectAs")}>
                        <FormControlLabel
                            className={classes.controlLabel}
                            control={<Radio value={"guest"} />}
                            label={t("share.remote_shares.add_share.label.connect_as.guest")}
                        />
                        <FormControlLabel
                            className={classes.controlLabel}
                            control={<Radio value={"registered"} />}
                            label={t("share.remote_shares.add_share.label.connect_as.registered")}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            {state.form.$("connectAs").value === "registered" && (
                <>
                    <Grid className={classes.inputRow} container alignItems={"center"} wrap={"nowrap"} justify={"space-between"}>
                        <Grid item xs={3}>
                            <Typography>{t("share.remote_shares.add_share.label.user")}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextInput field={state.form.$("user")} />
                        </Grid>
                    </Grid>
                    <Grid className={classes.inputRow} container alignItems={"center"} wrap={"nowrap"} justify={"space-between"}>
                        <Grid item xs={3}>
                            <Typography>{t("share.remote_shares.add_share.label.password")}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextInput field={state.form.$("password")} />
                        </Grid>
                    </Grid>
                </>
            )}
            <Grid container wrap={"nowrap"} className={classes.radioContainer}>
                <Grid item xs={3}>
                    <Typography>{t("share.remote_shares.add_share.select_share")}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <RadioGroup field={state.form.$("connectionMethod")}>
                        <FormControlLabel
                            className={classes.controlLabel}
                            control={<Radio value={"list"} />}
                            label={t("share.remote_shares.add_share.automatic_lookup")}
                        />
                        <FormControlLabel
                            className={classes.controlLabel}
                            control={<Radio value={t("manual")} />}
                            label={t("share.remote_shares.add_share.manual")}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Grid container wrap={"nowrap"} alignItems={"center"} className={classes.shareRow}>
                <Grid item xs={3}>
                    <Typography>{t("share.remote_shares.add_share.label.share")}</Typography>
                </Grid>
                {state.form.$("connectionMethod").value === "list" ? (
                    <>
                        <Grid item className={classes.shareSelectContainer}>
                            <Select disabled={state.isShareDisabled} field={state.form.$("share")} options={state.shareOptions} />
                        </Grid>
                        <Grid item>
                            <Button variant={"contained"} color={"primary"} onClick={onRefresh}>
                                {t("common.button.refresh")}
                            </Button>
                        </Grid>
                    </>
                ) : (
                    <Grid item className={classes.shareInputContainer}>
                        <TextInput field={state.form.$("share")} />
                    </Grid>
                )}
            </Grid>
        </Dialog>
    ));
};

export default AddExternalShareDialog;
