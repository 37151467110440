import { makeStyles } from "@material-ui/core/styles";
import {
    OPENED_SIDE_MENU_WIDTH,
    CLOSED_SIDE_MENU_WIDTH,
    SYSTEM_MENU_WIDTH,
    HEADER_ZINDEX,
    FOOTER_ZINDEX,
    SIDE_MENU_ZINDEX,
    NOTIFICATION_MENU_WIDTH,
    NOTIFICATION_MENU_ZINDEX,
    PIN_BUTTON_ZINDEX,
    HEADER_HEIGHT,
    HEADER_DIVIDER,
    CONTENT_MIN_WIDTH,
    CONTENT_MAX_WIDTH,
    HOME_PAGE_CONTENT_MAX_WIDTH,
    UPGRADE_MESSAGE_CONTAINER_HEIGHT,
    UPGRADE_MESSAGE_ZINDEX,
    PIN_NOTIFICATION_MENU_ZINDEX,
    HEADER_HEIGHT_MOBILE,
    OPENED_HEADER_HEIGHT_MOBILE,
    OPENED_HEADER_HEIGHT,
} from "const/styleConst";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
    },
    bodyContainer: {
        height: "100%",
        overflowY: "auto",
        [theme.breakpoints.up("md")]: {
            marginTop: ({ showUpgradeMessage }) =>
                showUpgradeMessage ? UPGRADE_MESSAGE_CONTAINER_HEIGHT + HEADER_HEIGHT : HEADER_HEIGHT,
        },
        marginTop: ({ showUpgradeMessage }) =>
            showUpgradeMessage ? UPGRADE_MESSAGE_CONTAINER_HEIGHT + HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_MOBILE,
        transition: "margin-top ease-out 0.1s",
        flex: 1,
    },
    bodyContentContainer: {
        minHeight: "100%",
        paddingLeft: (props) => (props.isSideMenuPinned ? 0 : theme.spacing(4)),
    },
    bodyContainerWithWidgetsBar: {
        "&": {
            [theme.breakpoints.up("md")]: {
                marginTop: ({ showUpgradeMessage }) =>
                    showUpgradeMessage ? UPGRADE_MESSAGE_CONTAINER_HEIGHT + OPENED_HEADER_HEIGHT : OPENED_HEADER_HEIGHT,
            },
            marginTop: ({ showUpgradeMessage }) =>
                showUpgradeMessage ? UPGRADE_MESSAGE_CONTAINER_HEIGHT + OPENED_HEADER_HEIGHT_MOBILE : OPENED_HEADER_HEIGHT_MOBILE,
        },
    },
    header: {
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        zIndex: HEADER_ZINDEX,
        "&:hover > .MuiButtonBase-root": {
            opacity: 1,
        },
    },
    bodyPage: {
        "&&": {
            minWidth: CONTENT_MIN_WIDTH,
            maxWidth: (props) => (props.largeWidthContent ? HOME_PAGE_CONTENT_MAX_WIDTH : CONTENT_MAX_WIDTH),
            width: "100%",
            height: "inherit",
            marginLeft: theme.spacing(6),
            marginRight: (props) => (props.isNotificationMenuPinned ? theme.spacing(2) : theme.spacing(6)),
            paddingTop: (props) => theme.spacing(props.denseTopPadding ? 0 : 8),
            boxSizing: "content-box",
        },
    },
    childrenContainer: {
        position: "relative",
        display: "flex",
        flex: 1,
    },
    footer: {
        zIndex: FOOTER_ZINDEX,
    },
    drawer: {
        zIndex: SIDE_MENU_ZINDEX,
        position: "fixed",
        [theme.breakpoints.up("md")]: {
            height: ({ isWidgetsBarOpen, isAuthorized }) => {
                if (!isAuthorized) {
                    return "100%";
                }

                return isWidgetsBarOpen ? `calc(100% - ${OPENED_HEADER_HEIGHT}px)` : `calc(100% - ${HEADER_HEIGHT}px)`;
            },
        },
        height: ({ isWidgetsBarOpen, isAuthorized }) => {
            if (!isAuthorized) {
                return "100%";
            }

            return isWidgetsBarOpen ? `calc(100% - ${OPENED_HEADER_HEIGHT_MOBILE}px)` : `calc(100% - ${HEADER_HEIGHT_MOBILE}px)`;
        },
        overflowX: "hidden",
        paddingRight: theme.spacing(5),
        boxSizing: "content-box",
        transition: "top ease-in-out 0.2s",
    },
    openedDrawer: {
        width: OPENED_SIDE_MENU_WIDTH,
        transition: "width ease-in-out 0.2s",
        "& .MuiPaper-root": {
            opacity: 1,
        },
        "& > .MuiButtonBase-root": {
            opacity: 0,
        },
        "&:hover > .MuiButtonBase-root": {
            opacity: 1,
        },
        "& .MuiBox-root": {
            boxShadow: theme.palette.sideMenu.shadow,
        },
    },
    closedDrawer: {
        transition: "width ease-in-out 0.2s",
        width: CLOSED_SIDE_MENU_WIDTH,
        height: "100%",
        "& .MuiPaper-root": {
            opacity: 0,
            transitionProperty: "opacity",
            transitionDelay: "0.1s",
        },
        "& .MuiBox-root": {
            boxShadow: "0 2px 4px 0 rgba(69, 74, 160, 0.12)",
        },
    },
    drawerWithSysMenu: {
        width: OPENED_SIDE_MENU_WIDTH + SYSTEM_MENU_WIDTH,
    },
    muiDrawerPaper: {
        top: "inherit",
    },
    sideSpacingPaper: {
        position: "fixed",
        width: CLOSED_SIDE_MENU_WIDTH,
        top: 0,
        bottom: 0,
        left: 0,
    },
    pinnedSideSpacing: {
        minWidth: OPENED_SIDE_MENU_WIDTH,
    },
    notificationMenu: {
        position: "fixed",
        right: 0,
        zIndex: NOTIFICATION_MENU_ZINDEX,
    },
    notificationSpacing: {
        width: NOTIFICATION_MENU_WIDTH,
    },
    toggleButton: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        border: "solid 1px #e5ecf2",
        borderRadius: "50%",
        backgroundColor: theme.palette.background.paper,
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: 1,
        top: theme.spacing(11),
        zIndex: PIN_BUTTON_ZINDEX,
        "& svg": {
            transition: "transform ease-in-out 0.3s",
        },
        "&:hover": {
            borderColor: theme.palette.primary.main,
        },
    },
    pinnedToggleButton: {
        "& svg": {
            transform: "rotate(0.5turn)",
        },
    },
    headerButton: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        border: HEADER_DIVIDER,
        borderRadius: "50%",
        backgroundColor: theme.palette.common.black,
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        right: `calc(50% - ${theme.spacing(4)}px)`,
        [theme.breakpoints.up("md")]: {
            top: HEADER_HEIGHT - theme.spacing(4),
        },
        top: HEADER_HEIGHT_MOBILE - theme.spacing(4),
        transition: "top ease-in-out 0.1s",
        zIndex: PIN_BUTTON_ZINDEX,
        "& svg": {
            transition: "transform ease-in-out 0.3s",
        },
        "&:hover": {
            borderColor: theme.palette.primary.main,
        },
    },
    openedHeaderButton: {
        opacity: 0,
        [theme.breakpoints.up("md")]: {
            top: OPENED_HEADER_HEIGHT - theme.spacing(4),
        },
        top: OPENED_HEADER_HEIGHT_MOBILE - theme.spacing(4),
        "& svg": {
            transform: "rotate(0.5turn)",
        },
    },
    upgradeMessage: {
        marginTop: "auto",
        marginBottom: "auto",
        opacity: 0.9,
        fontWeight: "bold",
        letterSpacing: 0.14,
        color: theme.palette.common.white,
        zIndex: 11,
    },
    upgradeMessageContainer: {
        zIndex: UPGRADE_MESSAGE_ZINDEX,
        position: "relative",
        marginRight: -CLOSED_SIDE_MENU_WIDTH,
        padding: theme.spacing(1, 6),
        justifyContent: "space-between",
        alignContent: "center",
        height: UPGRADE_MESSAGE_CONTAINER_HEIGHT,
        backgroundColor: theme.palette.primary.main,
    },
    button: {
        opacity: 0.9,
        fontWeight: "bold",
        letterSpacing: 0.14,
        fontSize: 14,
        color: theme.palette.common.white,
        textTransform: "uppercase",
        textDecoration: "none",
    },
    link: {
        textDecoration: "none",
        color: theme.palette.primary.main,
    },
    icon: {
        position: "absolute",
    },
    pinnedNotificationsMenu: {
        zIndex: PIN_NOTIFICATION_MENU_ZINDEX,
    },
    openNotificationsMenu: {
        zIndex: NOTIFICATION_MENU_ZINDEX,
    },
    hidedNotificationsMenu: {
        opacity: 0,
        zIndex: NOTIFICATION_MENU_ZINDEX,
    },
}));
