import React from "react";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid } from "@material-ui/core";
import TimeIcon from "assets/TimeIcon";
import TrashIcon from "assets/TrashIcon";

const BulkContent = ({ restoreFile, eraseFileModal }) => {
    const { t } = useTranslation();
    return (
        <Grid direction={"column"} container>
            <BulkBarFlatButton onClick={restoreFile} icon={<TimeIcon />}>
                {t("common.button.restore")}
            </BulkBarFlatButton>
            <BulkBarFlatButton onClick={eraseFileModal.open} errorColor icon={<TrashIcon />}>
                {t("recycle_bin.table.head_control.delete")}
            </BulkBarFlatButton>
        </Grid>
    );
};

export default BulkContent;
