import { types } from "mobx-state-tree";

import ShareRootAccess from "api/security/acl/Types/ShareRootAccess";

const GetShareRootAccessResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(ShareRootAccess)
});

export default GetShareRootAccessResult;
