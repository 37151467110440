import { types } from "mobx-state-tree";

import PluginMetadata from "api/plugins/Types/PluginMetadata";

const UnpackPluginResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: PluginMetadata
});

export default UnpackPluginResult;
