import React from "react";

const ReportIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="evenodd"
                d="M17.44 2l.166.003c1.211.065 2.2.982 2.369 2.167l.018.164L20 4.5v15l-.007.167a2.531 2.531 0 01-2.387 2.33l-.166.004H6.56l-.167-.004a2.531 2.531 0 01-2.386-2.33L4 19.5v-15l.007-.166A2.532 2.532 0 016.23 2.017l.163-.014L6.56 2h10.88zm.002 2H6.56l-.096.003a.531.531 0 00-.45.4L6 4.499v15.004a.53.53 0 00.464.495L6.56 20h10.882c.26.015.486-.16.544-.405L18 19.5v-15l-.014-.094a.53.53 0 00-.449-.403L17.442 4zM12 15a1 1 0 010 2H9a1 1 0 010-2h3zm3-4a1 1 0 010 2H9a1 1 0 010-2h6zm0-4a1 1 0 010 2H9a1 1 0 010-2h6z"
            ></path>
        </svg>
    );
};

export default ReportIcon;
