import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetShares from "api/slingshot/storage/Requests/GetShares";
import GetSharesResult from "api/slingshot/storage/Responses/GetSharesResult";
import GetFolders from "api/slingshot/storage/Requests/GetFolders";
import GetFoldersResult from "api/slingshot/storage/Responses/GetFoldersResult";
import GetLogicalDisks from "api/slingshot/storage/Requests/GetLogicalDisks";
import GetLogicalDisksResult from "api/slingshot/storage/Responses/GetLogicalDisksResult";

const SlingshotStorageStore = types
    .model({
        sharesStore: types.maybe(GetSharesResult),
        currentShareName: types.maybeNull(types.string),
        foldersStore: types.maybe(GetFoldersResult),
        logicalDisksStore: types.maybe(GetLogicalDisksResult),
        checkedSharesNames: types.optional(types.array(types.string), []),
        errorConnectedDBus: types.optional(types.boolean, false)
    })
    .volatile(() => ({
        progressGetShare: false
    }))
    .views(self => ({
        get shares() {
            return self.sharesStore?.data?.json?.shares || [];
        },
        get logicalDisks() {
            return self.logicalDisksStore?.data?.json?.disks || [];
        },
        get currentShare() {
            return self.currentShareName && self.shares.find(share => share.name === self.currentShareName);
        },
        get folders() {
            return self.foldersStore?.data?.json?.list || [];
        },
        get isErrorConnectedDBus() {
            return self.errorConnectedDBus;
        },
        get inProgressGetShare() {
            return self.progressGetShare;
        }
    }))
    .actions(self => ({
        fetchShares: flow(function*(args) {
            self.progressGetShare = true;
            const { processingStore } = getParent(self);
            try {
                const payload = args || {
                    json: {
                        includeBrick: false,
                        includeInternal: false,
                        includeExternal: true
                    }
                };
                processingStore.setLoading(true);
                const req = GetShares.create().init(payload);
                self.sharesStore = yield Socket.send(req);
                return true;
            } catch (e) {
                switch (e.code) {
                    case 404:
                        self.errorConnectedDBus = true;
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
                self.progressGetShare = false;
            }
        }),
        fetchFolders: flow(function*({ share, path }) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetFolders.create().init({
                    json: { share, path, skipFiles: true, skipFolders: false }
                });
                const res = yield Socket.send(req);
                self.foldersStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),

        fetchLogicalDisks: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetLogicalDisks.create().init({ json: "{}" });
                self.logicalDisksStore = yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setCurrentShareName(name) {
            self.currentShareName = name;
        },
        addCheckedShare: shareName => {
            const foundShare = self.checkedSharesNames.some(share => share === shareName);
            !foundShare && self.checkedSharesNames.push(shareName);
        },
        removeCheckedShare: shareName => {
            const foundShare = self.checkedSharesNames.some(share => share === shareName);
            foundShare && self.checkedSharesNames.remove(shareName);
        },
        clearCheckedShares: () => {
            self.checkedSharesNames = [];
        },
        resetErrors() {
            self.errorConnectedDBus = false;
        },
        enableErrorConnectedDBus() {
            self.errorConnectedDBus = true;
        }
    }));

export default SlingshotStorageStore;
