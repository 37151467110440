import React from "react";
import { useStyles } from "./BasicSetupCard.style";
import { Paper, Grid } from "@material-ui/core";
import CardHeader from "components/CardHeader";
import StepCard from "./StepCard";
import { useTranslation } from "react-i18next";
import LocalizationTypography from "components/LocalizationTypography/LocalizationTypography";
import localizations from "./BasicSetupCard.localizations";

const BasicSetupCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper>
            <CardHeader title={t(localizations.basicsSetup.titleKey)} />
            <Grid container direction={"column"} className={classes.container}>
                <Grid item>
                    <LocalizationTypography paragraphs={localizations.basicsSetup.paragraphs} />
                </Grid>
                <Grid item>
                    <LocalizationTypography paragraphs={localizations.phoneAssistedInstallation.title} />
                </Grid>
                <Grid item>
                    <LocalizationTypography paragraphs={localizations.phoneAssistedInstallation.paragraphs} />
                </Grid>

                <Grid item>
                    <Grid container className={classes.steps} direction={"column"} spacing={2}>
                        {localizations.setupSteps.map((step, stepIndex) => (
                            <Grid item key={step.titleKey}>
                                <StepCard
                                    count={Number(stepIndex) + 1}
                                    title={t(step.titleKey)}
                                    sideComponent={step.sideComponent}
                                    cardsOnlyAuthorized={step.cardsOnlyAuthorized}
                                >
                                    <LocalizationTypography paragraphs={step.paragraphs} />
                                </StepCard>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default BasicSetupCard;
