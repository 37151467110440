import { types } from "mobx-state-tree";

import ProxySettingsResultType from "api/slingshot/proxy_settings/Types/ProxySettingsResultType";

const GetProxySettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(ProxySettingsResultType)
});

export default GetProxySettingsResult;
