import { types } from "mobx-state-tree";

import Request from "api/Request";
import ImportArguments from "api/system_components/slingshot/Types/ImportArguments";

const ImportDatabaseAndConfig = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Slingshot",
    method: "import_database_and_config",
    arguments: types.maybe(ImportArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ImportDatabaseAndConfig;
