import { types } from "mobx-state-tree";

import Request from "api/Request";
import EvoDescriptionArguments from "api/evo_info/Types/EvoDescriptionArguments";

const SetEvoDescription = Request.props({
    path: "/com/studionetworksolutions/evo/status/Info",
    method: "set_evo_description",
    arguments: types.maybe(EvoDescriptionArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetEvoDescription;
