const createFields = locale => {
    return [
        {
            name: "locale",
            default: locale ? locale : ""
        }
    ];
};

export default createFields;
