import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        overflowX: "auto",
        overflowY: "hidden",
    },
    contentContainer: {
        padding: theme.spacing(4, 6),
    },
    subtitle: {
        lineHeight: 1.5,
        letterSpacing: 0.1,
    },
    chip: { minWidth: 40 },
}));
