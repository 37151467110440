import { types } from "mobx-state-tree";

import Request from "api/Request";
import ClusterSettingsData from "api/cluster/Types/ClusterSettingsData";

const SetSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Cluster",
    method: "set_settings",
    arguments: types.maybe(ClusterSettingsData)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetSettings
