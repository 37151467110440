export const EMPTY_BCACHE_DRIVE_NAME = "-";
export const EMPTY_DRIVE_ID = { quad: -1, drive: -1 };
export const getBcacheDriveName = (q, d) => {
    if (q === -1 && d === -1) {
        return EMPTY_BCACHE_DRIVE_NAME;
    } else if (q < 0) return "NVME-" + (-1 - q) + "-" + d;
    return "QUAD-" + q + "-" + d;
};

export const parseDriveId = (value) => {
    if (value) {
        const match = value.match(/^QUAD-(-?\d+)-(\d+)$/);
        if (match != null) {
            return {
                quad: parseInt(match[1]),
                drive: parseInt(match[2]),
            };
        }
        const matchNvme = value.match(/^NVME-(\d+)-(\d+)$/);
        if (matchNvme != null) {
            return {
                quad: -1 - parseInt(matchNvme[1]),
                drive: parseInt(matchNvme[2]),
            };
        }
    }
    return EMPTY_DRIVE_ID;
};
