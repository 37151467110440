import React, { useEffect } from "react";
import { Button, Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TextInput from "components/MobxForm/TextInput";
import Select from "components/MobxForm/Select";
import { useLocalStore, observer } from "mobx-react";
import { useStyles } from "./CreateShareDialog.styles";
import Subdirectory from "../Subdirectory";
import ProtocolController from "../ProtocolController";
import AllowSmbAndAfpController from "../AllowSmbAndAfpController";
import AccessTypeController from "../AccessTypeController";
import ModeController from "../ModeController";
import createForm from "utils/createForm";
import config from "config";
import clsx from "clsx";
import ChevronUpIcon from "assets/ChevronUpIcon";
import FormatVolumeDialog from "../FormatVolumeDialog";
import { GB_METRIC, GiB_IEC, PB_METRIC, PiB_IEC, TB_METRIC, TiB_IEC } from "const/diskSizeConst";
import Dialog from "components/Dialog";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import HideOverlay from "components/HideOverlay";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import Switch from "components/MobxForm/Switch";
import useStoreByIp from "hooks/useStoreByIp";
import { useClearFormFields } from "hooks/useClearFormFields";
import { FTP, NFS, RESERVED, SMB } from "const/shareRootAccessConst";
import { useResetFormField } from "hooks/useResetFormFields";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";
import { createFields } from "./createFields";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const CREATE_SHARE_DIALOG_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_create_share_dialog`;

const CreateShareDialog = ({ open, onClose, onEnter, pool, volume, partition, fileSystem, ip }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { shareStore, fileStore } = useStoreByIp({ ip });
    const {
        store: { rootShareStore },
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        advancedExpanded: false,
        isSubmitted: false,
        createShareInProgress: false,
    }));

    const { unsavedChangesModal, formatVolumeModal } = useModalCombine(["unsavedChangesModal", "formatVolumeModal"]);

    useEffect(() => {
        state.form.$("quotaMetric").set("default", config.decimalSizeUnits ? GB_METRIC.value : GiB_IEC.value);
        state.form.reset();

        state.form.$("shareName").observe(({ form, field }) => form.$("newSubdirectory").set(field.value)); // autofill for a newSubdirectory field

        state.form.$("variant").set("default", "create");
        state.form.$("variant").reset();

        return () => state.form.dispose();
    }, []);

    const getSubdirectory = () => {
        switch (state.form.$("variant").value) {
            case "create":
                return state.form.$("newSubdirectory").value;
            case "existing":
                return state.form.$("existSubdirectory").value;
            default:
                return "default";
        }
    };

    const getQuotaUnitsOptions = () => {
        return config.decimalSizeUnits
            ? [
                { label: GB_METRIC.unit, value: GB_METRIC.value },
                { label: TB_METRIC.unit, value: TB_METRIC.value },
                { label: PB_METRIC.unit, value: PB_METRIC.value },
            ]
            : [
                { label: GiB_IEC.unit, value: GiB_IEC.value },
                { label: TiB_IEC.unit, value: TiB_IEC.value },
                { label: PiB_IEC.unit, value: PiB_IEC.value },
            ];
    };

    const openDialogIfNeeded = async (e) => {
        await state.form.validate();

        if ((fileSystem === "none" || fileSystem === "iscsi") && state.form.isValid) {
            formatVolumeModal.open();
        } else await submit(e);
    };

    const submit = async (e) => {
        await state.form.onSubmit(e);

        if (!state.form.isValid) return;
        if (formatVolumeModal.isOpen) {
            formatVolumeModal.close();
        }

        rootShareStore.clearAfterCreateShareData();

        state.createShareInProgress = true;
        state.isSubmitted = !!(await shareStore.createShare({
            location: {
                pool: pool,
                volume: volume,
                partition: partition,
                directory: getSubdirectory(),
            },
            attributes: {
                shareName: state.form.$("shareName").value,
                comment: state.form.$("shareComment").value,
                quota: Math.floor(state.form.$("quotaValue").value * state.form.$("quotaMetric").value),
                shareType: state.form.$("protocolValue").value,
                accessType: state.form.$("accessType").value,
                locking: state.form.$("mode").value,
                trashEnabled: state.form.$("recycleBinEnabled").value,
                browseable: state.form.$("browseable").value,
            },
            force: fileSystem === "none" || fileSystem === "iscsi",
        }));
        state.createShareInProgress = false;

        if (!state.isSubmitted) {
            return;
        }

        setTimeout(() => {
            state.isSubmitted = false;
        }, 1000);
        setTimeout(onClose, 1000);
    };

    const handleEnter = () => {
        state.form.reset();
        state.advancedExpanded = false;
        formatVolumeModal.close();
        state.isSubmitted = false;
        unsavedChangesModal.close();
        onEnter();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    useClearFormFields(
        {
            recycleBinEnabled: state.form.$("protocolValue").value !== SMB,
            smbAndAfp: state.form.$("protocolValue").value !== SMB,
            browseable: state.form.$("protocolValue").value !== SMB,
        },
        state.form
    );

    useResetFormField(state.form, {
        recycleBinEnabled: state.form.$("protocolValue").value === SMB,
    });

    const handleProtocolValueChange = (e) => {
        const protocolValue = e.target.value;
        state.form.$("protocolValue").set("value", e.target.value);

        if (protocolValue === FTP || protocolValue === RESERVED) {
            state.form.$("accessType").set("value", "rw");
        }
        if (protocolValue === NFS) {
            if (state.form.$("accessType").get("value") === "rw_rd") {
                state.form.$("accessType").set("value", "rw");
            }
        }
    }

    return (
        <Dialog
            buttons={
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={onClose}
                    id={`${CREATE_SHARE_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
            title={t("share.create_share.title")}
            open={open}
            onClose={closeIfNeeded}
            submitBtn={
                <AnimatedSubmitButton
                    label={t("common.button.create")}
                    isSubmitted={state.isSubmitted}
                    submit={openDialogIfNeeded}
                    inProgress={state.createShareInProgress}
                    id={`${CREATE_SHARE_DIALOG_ID_PREFIX}_create`}
                />
            }
            onEnter={handleEnter}
            maxWidth={"md"}
        >
            <Grid className={classes.row} alignItems={"center"} container>
                <Grid className={classes.rowLabel} item xs={4}>
                    <Typography>{t("share.create_share.name")}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextInput autoFocus field={state.form.$("shareName")} id={`${CREATE_SHARE_DIALOG_ID_PREFIX}_name`} />
                </Grid>
            </Grid>
            <Grid className={classes.row} alignItems={"center"} container>
                <Grid className={classes.rowLabel} item xs={4}>
                    <Typography>{t("share.create_share.comment")}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextInput field={state.form.$("shareComment")} id={`${CREATE_SHARE_DIALOG_ID_PREFIX}_comment`} />
                </Grid>
            </Grid>
            {state.form.$("protocolValue").value === SMB && (
                <Grid className={classes.row} alignItems={"center"} container>
                    <RowControlLabelSwitch
                        control={
                            <Switch
                                field={state.form.$("recycleBinEnabled")}
                                id={`${CREATE_SHARE_DIALOG_ID_PREFIX}_enable_bin`}
                            />
                        }
                        label={t("share.create_share.enable_bin")}
                        lastColumnWidth={"100%"}
                    />
                </Grid>
            )}
            <Grid xs={12} item className={classes.expansionPanelContainer}>
                <Accordion
                    className={classes.expansionPanel}
                    square
                    expanded={state.advancedExpanded}
                    onChange={(event, newExpanded) => {
                        state.advancedExpanded = newExpanded;
                    }}
                >
                    <AccordionSummary
                        className={classes.expansionPanelContent}
                        classes={{ content: classes.expansionPanelContent, expanded: classes.expansionPanelContent }}
                    >
                        <Typography className={classes.expansionLabel}>
                            {t("disks_and_pools.add_disk_dialog.label.advanced")}
                        </Typography>
                        <ChevronUpIcon
                            className={clsx(!state.advancedExpanded && classes.expansionIconClose, classes.expansionIcon)}
                        />
                    </AccordionSummary>
                    <AccordionDetails className={classes.detailsRoot}>
                        <Grid container direction={"column"}>
                            <Subdirectory
                                variantField={state.form.$("variant")}
                                newSubdirectory={state.form.$("newSubdirectory")}
                                existSubdirectory={state.form.$("existSubdirectory")}
                                existingOptions={fileStore.listRootDirectories}
                            />
                            <Grid className={classes.row} alignItems={"center"} container>
                                <Grid className={classes.rowLabel} item xs={4}>
                                    <Typography>{t("share.create_share.quota")}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid spacing={2} container item>
                                        <Grid xs={8} item>
                                            <TextInput
                                                field={state.form.$("quotaValue")}
                                                id={`${CREATE_SHARE_DIALOG_ID_PREFIX}_quota_value`}
                                            />
                                        </Grid>
                                        <Grid xs={4} item>
                                            <Select
                                                options={getQuotaUnitsOptions()}
                                                field={state.form.$("quotaMetric")}
                                                id={`${CREATE_SHARE_DIALOG_ID_PREFIX}_quota_metric`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <ProtocolController
                                field={state.form.$("protocolValue")}
                                onChange={handleProtocolValueChange}
                            />
                            {state.form.$("protocolValue").value === SMB && (
                                <HideOverlay>
                                    <AllowSmbAndAfpController
                                        className={classes.allowContainer}
                                        field={state.form.$("smbAndAfp")}
                                        disableAfpOnly={state.form.$("protocolValue").value === SMB}
                                        disableMixing={state.form.$("protocolValue").value === SMB}
                                    />
                                </HideOverlay>
                            )}
                            {state.form.$("protocolValue").value === SMB && (
                                <Grid alignItems={"center"} className={classes.row} container>
                                    <RowControlLabelSwitch
                                        control={
                                            <Switch
                                                field={state.form.$("browseable")}
                                                id={`${CREATE_SHARE_DIALOG_ID_PREFIX}_browseable`}
                                            />
                                        }
                                        label={t("share.create_share.browseable")}
                                        lastColumnWidth={"33.333333%"}
                                    />
                                </Grid>
                            )}
                            {state.form.$("protocolValue").value !== RESERVED && (
                                <AccessTypeController
                                    field={state.form.$("accessType")}
                                    rwDisabled={
                                        state.form.$("protocolValue").value === NFS ||
                                        state.form.$("protocolValue").value === FTP
                                    }
                                    rwrdDisabled={state.form.$("protocolValue").value === FTP}

                                />
                            )}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {state.form.$("protocolValue").value !== RESERVED && (
                <ModeController
                    ip={ip}
                    isMasksEditable={false}
                    mode={state.form.$("mode")}
                    protocol={state.form.$("protocolValue")}
                    projectSharingEnabled={state.form.$("projectSharingEnabled")}
                    pool={pool}
                />
            )}
            <FormatVolumeDialog
                pool={pool}
                volume={volume}
                onClose={formatVolumeModal.close}
                onAccept={submit}
                open={formatVolumeModal.isOpen}
            />
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    );
};

export default observer(CreateShareDialog);
