import React, { useEffect } from "react";
import { useObserver } from "mobx-react";
import { Box, Typography, Grid, Divider, Paper, ButtonBase } from "@material-ui/core";
import NotificationItem from "./components/NotificationItem";
import TaskItem from "./components/TaskItem";
import PinIcon from "assets/PinIcon";
import SmallGearIcon from "assets/SmallGearIcon";
import clsx from "clsx";
import { useStyles } from "./NotificationMenu.style";
import NotificationsPlaceholder from "assets/NotificationsPlaceholder";
import { useTranslation } from "react-i18next";
import { FilterNotificationsDialog } from "./components/FilterNotificationsDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { ADMIN } from "const/userRolesConst";
import ChevronRightIcon from "assets/ChevronRightIcon";

const NotificationMenu = ({ isNotificationMenuPinned, togglePinNotifications, closeNotificationMenu }) => {
    const classes = useStyles();

    const {
        store: { notificationStore, taskStore, authStore },
    } = useStore();

    const filterNotificationsModal = useModal();

    const { t } = useTranslation();

    useEffect(() => {
        taskStore.fetchTasks();
        notificationStore.fetchNotifications();
    }, []);

    return useObserver(() => {
        return (
            <Box className={classes.container}>
                <Paper square className={classes.notificationMenu}>
                    <ButtonBase
                        className={clsx(classes.button, classes.pinButton, isNotificationMenuPinned && classes.activeButton)}
                        onClick={togglePinNotifications}
                    >
                        <PinIcon />
                    </ButtonBase>
                    {authStore.role === ADMIN && (
                        <ButtonBase
                            className={clsx(classes.button, classes.settingsButton)}
                            onClick={filterNotificationsModal.open}
                        >
                            <SmallGearIcon />
                        </ButtonBase>
                    )}
                    <ButtonBase onClick={closeNotificationMenu} className={clsx(classes.button, classes.closeButton)}>
                        <ChevronRightIcon />
                    </ButtonBase>
                    <Box className={classes.notificationsContainer}>
                        {taskStore.tasks.map((task) => (
                            <React.Fragment key={`task-${task.id}-${task.startTime}`}>
                                <TaskItem task={task} />
                                <Divider />
                            </React.Fragment>
                        ))}
                        {notificationStore.notifications.map((notification) => (
                            <React.Fragment key={`notification-${notification.id}-${notification.time}`}>
                                <NotificationItem notification={notification} />
                                <Divider />
                            </React.Fragment>
                        ))}
                        {taskStore.tasks.length === 0 && notificationStore.notifications.length === 0 && (
                            <Grid spacing={6} className={classes.emptyNotificationsContainer} container>
                                <Grid item>
                                    <NotificationsPlaceholder />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.message}>{t("notifications.no_notifications")}</Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </Paper>

                <FilterNotificationsDialog onClose={filterNotificationsModal.close} open={filterNotificationsModal.isOpen} />
            </Box>
        );
    });
};

export default NotificationMenu;
