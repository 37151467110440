import { types } from "mobx-state-tree";

import NetSysctlSettings from "api/net/Types/NetSysctlSettings";

const NetSysctlSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(NetSysctlSettings)
});

export default NetSysctlSettingsResult;
