import { makeStyles } from "@material-ui/core/styles";
import {
    DRAWER_WIDTH,
    HEADER_HEIGHT,
    GRAPHICS_BAR_HEIGHT,
    STATE_BAR_HEIGHT,
    HEADER_HEIGHT_MOBILE,
    GRAPHICS_BAR_HEIGHT_MOBILE,
    STATE_BAR_HEIGHT_MOBILE
} from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        overflow: "hidden"
    },
    menuCheckboxContainer: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginLeft: theme.spacing(4),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    toggleNotifyOnCompletionItem: {
        opacity: 1
    },
    disabledMenuItem: {
        opacity: 0.3
    },
    drawer: {
        "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            [theme.breakpoints.up("md")]: {
                top: HEADER_HEIGHT
            },
            top: HEADER_HEIGHT_MOBILE,
            bottom: 0,
            height: "initial",
            overflowY: "initial"
        }
    },
    drawerOpenHeader: {
        "& .MuiDrawer-paper": {
            [theme.breakpoints.up("md")]: {
                top: HEADER_HEIGHT + GRAPHICS_BAR_HEIGHT + STATE_BAR_HEIGHT
            },
            top: HEADER_HEIGHT_MOBILE + GRAPHICS_BAR_HEIGHT_MOBILE + STATE_BAR_HEIGHT_MOBILE
        }
    },
    checkboxCell: {
        opacity: 1,
        "&&&": {
            lineHeight: "unset"
        }
    },
    select: {
        "& .MuiInputBase-root": {
            margin: theme.spacing(-2, -3.25),
            "&:not(.Mui-focused)": {
                background: "none"
            }
        },
        "&& span": {
            opacity: 0.9,
            fontSize: 20,
            fontWeight: "bold",
            lineHeight: 1.2
        }
    }
}));
