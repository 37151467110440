import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    iconEnable: {
        "&& svg path": {
            fill: theme.palette.success.main
        }
    },
    iconDisable: {
        "&& svg path": {
            fill: theme.palette.error.main
        }
    }
}));
