import config from "config/config";
import { useStore } from "./useStore";

const useStoreByIp = ({ ip }) => {
    const { store, multipleEvosRootStore } = useStore();

    if (ip && ip !== config.path) {
        const multipleStore = multipleEvosRootStore.getStore(ip);
        return multipleStore ?? store;
    }

    return store;
};

export default useStoreByIp;
