import { types } from "mobx-state-tree";

import Volume from "api/cluster/Types/Volume";
import BaseBrick from "api/cluster/Types/BaseBrick";

const AddVolumeArguments = types.model({
    volume: types.maybe(Volume),
    bricks: types.array(BaseBrick)
});

export default AddVolumeArguments;
