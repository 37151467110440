import React from "react";
import moment from "moment";
import { Grid, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
    INFO,
    WARNING,
    ERROR,
    POOL_CREATED,
    VOLUME_CREATED,
    VOLUME_REMOVED,
    VOLUME_PUT_ONLINE,
    VOLUME_PUT_OFFLINE,
    SHARE_REMOVED,
    SHARE_UNMAPPED,
    FAILED_DRIVE,
} from "const/notificationConst";
import NotificationIcon from "assets/NotificationIcon";
import BrickCheckedIcon from "assets/BrickCheckedIcon";
import BrickErrorIcon from "assets/BrickErrorIcon";
import BrickWarningIcon from "assets/BrickWarningIcon";
import DiskCheckedIcon from "assets/DiskCheckedIcon";
import DisksCheckedIcon from "assets/DisksCheckedIcon";
import DisksWarningIcon from "assets/DisksWarningIcon";
import DiskErrorIcon from "assets/DiskErrorIcon";
import DiskProcessIcon from "assets/DiskProcessIcon";
import DisksProcessIcon from "assets/DisksProcessIcon";
import ShareProcessIcon from "assets/ShareProcessIcon";
import SharesProcessIcon from "assets/SharesProcessIcon";
import ShareErrorIcon from "assets/ShareErrorIcon";
import SharesWarningIcon from "assets/SharesWarningIcon";
import { useStyles } from "./NotificationItem.style";
import { SERVER_DATE_FORMAT_EN, NOTIFICATION_DATE_FORMAT } from "const";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const renderIcon = (eventType, eventLevel, isBatch, className) => {
    switch (eventType) {
        case POOL_CREATED:
            switch (eventLevel) {
                case INFO:
                    return <BrickCheckedIcon />;
                case ERROR:
                    return <BrickErrorIcon />;
                case WARNING:
                    return <BrickWarningIcon />;
                default:
                    return null;
            }
        case VOLUME_CREATED:
        case VOLUME_REMOVED:
            switch (eventLevel) {
                case INFO:
                    return isBatch ? <DisksCheckedIcon /> : <DiskCheckedIcon />;
                case ERROR:
                    return isBatch ? <DisksWarningIcon /> : <DiskErrorIcon />;
                case WARNING:
                    return <DisksWarningIcon />;
                default:
                    return null;
            }
        case VOLUME_PUT_ONLINE:
        case VOLUME_PUT_OFFLINE:
            switch (eventLevel) {
                case INFO:
                    return isBatch ? <DisksProcessIcon /> : <DiskProcessIcon />;
                case ERROR:
                    return isBatch ? <DisksWarningIcon /> : <DiskErrorIcon />;
                case WARNING:
                    return <DisksWarningIcon />;
                default:
                    return null;
            }
        case SHARE_REMOVED:
        case SHARE_UNMAPPED:
            switch (eventLevel) {
                case INFO:
                    return isBatch ? <SharesProcessIcon /> : <ShareProcessIcon />;
                case ERROR:
                    return isBatch ? <SharesWarningIcon /> : <ShareErrorIcon />;
                case WARNING:
                    return <SharesWarningIcon />;
                default:
                    return null;
            }
        case FAILED_DRIVE:
            return <DiskErrorIcon />;
        default:
            return <NotificationIcon className={className} />;
    }
};

const renderMessage = (isBatch, message, failedText, total, complete, classes) => {
    if (isBatch) {
        return (
            <>
                <Grid item container wrap="nowrap" justify="space-between">
                    <Grid item>
                        <Typography variant="body2">{message}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{`${complete}/${total}`}</Typography>
                    </Grid>
                </Grid>
                {failedText && (
                    <Grid item>
                        <Alert className={classes.alert} icon={false}>
                            <Typography variant="body2">{failedText}</Typography>
                        </Alert>
                    </Grid>
                )}
            </>
        );
    }

    return (
        <Grid item>
            <Typography variant="body2">{message}</Typography>
        </Grid>
    );
};

const NotificationItem = ({ notification: { time, eventLevel, eventType, message, failedText, total, complete } }) => {
    const formattedTime = moment(time, SERVER_DATE_FORMAT_EN).format(getLanguageDateFormat(NOTIFICATION_DATE_FORMAT));
    const isBatch = total !== undefined && complete !== undefined;
    const classes = useStyles({ eventLevel, isBatch });

    return (
        <Grid container direction={"column"} spacing={2} className={classes.container}>
            <Grid item container spacing={2} alignItems={"center"}>
                <Grid item>{renderIcon(eventType, eventLevel, isBatch, classes.icon)}</Grid>
                <Grid item>
                    <Typography className={classes.time}>{formattedTime}</Typography>
                </Grid>
                <Grid item className={classes.spacing} />
            </Grid>
            {renderMessage(isBatch, message, failedText, total, complete, classes)}
        </Grid>
    );
};

export default NotificationItem;
