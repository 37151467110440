export const FILE_UPLOAD_URL = "/upload?destination=";
export const FILE_DOWNLOAD_URL = "/download";
export const LOGIN_URL = "/login";
export const LOGIN_WITH_LONG_LIVE_TOKEN_URL = "/login?long-lived=true";
export const LOGOUT_URL = "/logout";
export const WEBSOCKET_TOKEN_URL = "/websocket_token";
export const SYSTEM_INFO = "/system_info ";
export const BOOT_PROGRESS_URL = "/boot_progress";
export const SSH_ACCESS_URL = "/ssh_access";
export const EULA_URL = "/eula";
