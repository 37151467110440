import React from "react";
import { observer } from "mobx-react";
import HeaderTabs from "components/HeaderTabs";
import { useStore } from "hooks/useStore";

const SlingshotHeaderTabs = (props) => {
    const {
        store: { systemComponentsStore },
    } = useStore();

    const getServicesState = () => {
        systemComponentsStore.fetchSystemComponentsServicesState();
    };

    return <HeaderTabs onClick={getServicesState} {...props} />;
};

export default observer(SlingshotHeaderTabs);
