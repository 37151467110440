import { flow, getParent, types } from "mobx-state-tree";
import MasterReset from "api/troubleshooting/Requests/MasterReset";
import Socket from "websocket";

const MasterResetStore = types.model().actions(self => ({
    masterReset: flow(function*(data) {
        const { processingStore } = getParent(self);
        try {
            processingStore.setLoading(true);
            const req = MasterReset.create().init(data);
            const res = yield Socket.send(req);
            return res;
        } catch (e) {
            processingStore.setError(e);
        } finally {
            processingStore.setLoading(false);
        }
        return null;
    })
}));

export default MasterResetStore;
