import React from "react";

const NotificationIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="#FFA026" fillRule="nonzero">
                <path
                    fillOpacity=".2"
                    d="M14.342 4.555l7.188 12.442c1.19 2.057.024 4.003-2.398 4.003H4.87c-2.424 0-3.59-1.941-2.4-4.003L9.651 4.555c1.197-2.072 3.492-2.075 4.69 0z"
                />
                <path d="M12 17.077c-.552 0-1-.436-1-.974a.988.988 0 0 1 1-.975c.553 0 1 .437 1 .975a.988.988 0 0 1-1 .974zm-1-7.766c0-.54.444-.977 1-.977.553 0 1 .437 1 .977v3.866c0 .54-.443.977-1 .977-.552 0-1-.437-1-.977V9.311z" />
            </g>
        </svg>
    );
};

export default NotificationIcon;
