import { types } from "mobx-state-tree";

import Request from "api/Request";
import RemoveShareArguments from "api/share/Types/RemoveShareArguments";

const RemoveShare = Request.props({
    path: "/com/studionetworksolutions/evo/Nas",
    method: "remove_share",
    arguments: types.maybe(RemoveShareArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default RemoveShare;
