import { types } from "mobx-state-tree";

import Request from "api/Request";
import PageQuery from "api/general/Types/PageQuery";

const GetPeers = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Cluster",
    method: "get_peers",
    arguments: types.maybe(PageQuery)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetPeers
