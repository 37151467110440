import { makeStyles } from "@material-ui/core/styles";
import { QUAD_STATUS_DOT_GREY_COLOR } from "const/styleConst";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => {
    const getColor = status => {
        switch (status) {
            case "online":
                return theme.palette.success.main;
            case "absent":
                return QUAD_STATUS_DOT_GREY_COLOR;
            case "replaced":
                return theme.palette.primary.main;
            case "warning":
                return theme.palette.warning.main;
            case "failed":
                return theme.palette.error.main;
            case "not_activated":
                return theme.palette.warning.dark;
            case "unknown":
                return theme.palette.error.main;
            default:
                return theme.palette.error.main;
        }
    };
    return {
        statusDot: {
            width: theme.spacing(2.5),
            height: theme.spacing(2.5),
            borderRadius: "50%",
            margin: theme.spacing(0, 2.5),
            backgroundColor: props => getColor(props.status)
        },
        driveContainer: {
            padding: theme.spacing(1),
            borderRadius: theme.spacing(1),
            "&:hover": {
                backgroundColor: hexToRgba(theme.palette.primary.main, 0.1),
                cursor: "pointer"
            }
        }
    };
});
