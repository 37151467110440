import Request from "api/Request";

const GetUptime = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Power",
    method: "get_uptime"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetUptime;
