import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    lastCell: {
        width: "5%"
    },
    formControlLabel: {
        alignItems: "flex-start",
        "& .MuiCheckbox-root": {
            top: -9,
            marginRight: theme.spacing(1.5)
        }
    },
    cellWithIcon: {
        "& svg path": {
            fill: theme.palette.grey[300]
        }
    }
}));
