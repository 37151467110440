import React from "react";

const DisksIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#BFD1E1"
                fillRule="nonzero"
                d="M12 2c5.586 0 9.827.918 9.995 2.879L22 5v14c0 2.045-4.3 3-10 3l-.677-.005c-5.249-.072-9.157-.993-9.318-2.874L2 19V5c0-1.922 3.8-2.881 8.99-2.99l.333-.005L12 2zM4 13.987l-.001 4.804c.043.03.101.064.173.102.362.19.94.38 1.681.545 1.6.356 3.796.562 6.147.562 2.35 0 4.547-.206 6.147-.562.741-.165 1.319-.355 1.68-.545.073-.038.131-.073.175-.103v-4.804C18.206 14.671 15.343 15 12 15l-.677-.005c-3.052-.041-5.65-.37-7.323-1.008zm0-7l-.001 4.804c.043.03.101.064.173.102.362.19.94.38 1.681.545 1.6.356 3.796.562 6.147.562 2.35 0 4.547-.206 6.147-.562.741-.165 1.319-.355 1.68-.545.073-.038.131-.073.175-.103V6.986c-1.59.607-4.015.934-6.87 1.001l-.455.008L12 8c-3.344 0-6.206-.329-8-1.013zM12 4c-2.35 0-4.547.206-6.147.562-.494.11-.916.23-1.249.356l-.159.062-.046.02.239.095c.217.08.47.158.755.232l.219.055.241.056C7.453 5.794 9.649 6 12 6c2.35 0 4.547-.206 6.147-.562.494-.11.916-.23 1.249-.356l.159-.062L19.6 5l-.045-.02c-.243-.1-.543-.2-.894-.293l-.273-.07-.241-.055C16.547 4.206 14.351 4 12 4z"
            />
        </svg>
    );
};

export default DisksIcon;
