import { types } from "mobx-state-tree";

const ShareAttributes = types.model({
    shareName: types.string,
    comment: types.string,
    quota: types.number,
    shareType: types.string,
    accessType: types.string,
    locking: types.string,
    trashEnabled: types.boolean,
    browseable: types.boolean
});

export default ShareAttributes;
