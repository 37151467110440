import React from "react";

const NotificationsPlaceholder = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="124" height="124" viewBox="0 0 124 124">
            <defs>
                <linearGradient id="krogsg44fa" x1="50%" x2="78.292%" y1="0%" y2="91.047%">
                    <stop offset="0%" stopColor="#A2CDEA" />
                    <stop offset="100%" stopColor="#70A0EB" />
                </linearGradient>
                <linearGradient id="cr9qzbta2b" x1="18.044%" x2="91.77%" y1="33.476%" y2="55.865%">
                    <stop offset="0%" stopColor="#A2CDEA" />
                    <stop offset="100%" stopColor="#70A0EB" />
                </linearGradient>
                <linearGradient id="hzcylv6frc" x1="50%" x2="74.735%" y1="29.188%" y2="70.812%">
                    <stop offset="0%" stopColor="#A2CDEA" />
                    <stop offset="100%" stopColor="#70A0EB" />
                </linearGradient>
                <linearGradient id="q70kyhcsod" x1="16.034%" x2="95.364%" y1="46.875%" y2="53.125%">
                    <stop offset="0%" stopColor="#A2CDEA" />
                    <stop offset="100%" stopColor="#70A0EB" />
                </linearGradient>
                <linearGradient id="tzoe0avrje" x1="50%" x2="95.364%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#AAA2EA" />
                    <stop offset="100%" stopColor="#7082EB" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path fill="url(#krogsg44fa)" d="M62.5 2C68.851 2 74 7.149 74 13.5V26H51V13.5C51 7.149 56.149 2 62.5 2z" />
                <path fill="url(#krogsg44fa)" d="M62.5 2C68.851 2 74 7.149 74 13.5V26H51V13.5C51 7.149 56.149 2 62.5 2z" />
                <path
                    fill="#FFF"
                    fillOpacity="0.2"
                    fillRule="nonzero"
                    d="M62.5 2C68.851 2 74 7.149 74 13.5V26H51V13.5C51 7.149 56.149 2 62.5 2zm0 4a7.5 7.5 0 00-7.496 7.257L55 13.5V22h15v-8.5a7.5 7.5 0 00-7.257-7.496L62.5 6z"
                />
                <path
                    fill="url(#cr9qzbta2b)"
                    d="M62.5 15C84.315 15 102 32.685 102 54.5V93H23V54.5C23 32.685 40.685 15 62.5 15z"
                />
                <path
                    fill="#FFF"
                    fillOpacity="0.2"
                    fillRule="nonzero"
                    d="M62.5 15C84.315 15 102 32.685 102 54.5V93H23V54.5C23 32.685 40.685 15 62.5 15zM27 73v16h71V73H27zm0-4h71V54.5c0-19.41-15.578-35.182-34.913-35.495L62.5 19c-19.41 0-35.182 15.578-35.495 34.913L27 54.5V69z"
                />
                <path
                    fill="url(#hzcylv6frc)"
                    d="M62.5 102c8.56 0 15.5 6.94 15.5 15.5v4.5H47v-4.5c0-8.56 6.94-15.5 15.5-15.5z"
                    transform="matrix(1 0 0 -1 0 224)"
                />
                <path
                    fill="#FFF"
                    fillOpacity="0.2"
                    fillRule="nonzero"
                    d="M62.5 122c8.56 0 15.5-6.94 15.5-15.5V102H47v4.5c0 8.56 6.94 15.5 15.5 15.5zm0-4c-6.254 0-11.341-4.992-11.496-11.208L51 106.5v-.5h23v.5c0 6.254-4.992 11.341-11.208 11.496L62.5 118z"
                />
                <path
                    fill="url(#q70kyhcsod)"
                    d="M24.105 82h77.81l6.805 6.313a4 4 0 011.28 2.932V102c0 2.21-1.79 4-4 4H18c-2.21 0-4-1.79-4-4V91.513a4 4 0 011.616-3.212L24.106 82z"
                />
                <path
                    fill="#FFF"
                    fillOpacity="0.2"
                    fillRule="nonzero"
                    d="M101.916 82l6.804 6.313a4 4 0 011.28 2.932V102c0 2.21-1.79 4-4 4H18c-2.21 0-4-1.79-4-4V91.513a4 4 0 011.616-3.212L24.106 82h77.81zm-1.57 4H25.427L18 91.513V102h88V91.245L100.346 86z"
                />
                <circle cx="95" cy="31" r="21" fill="url(#tzoe0avrje)" />
                <path
                    fill="#FFF"
                    fillOpacity="0.25"
                    fillRule="nonzero"
                    d="M95 10c11.598 0 21 9.402 21 21s-9.402 21-21 21-21-9.402-21-21 9.402-21 21-21zm0 4c-9.389 0-17 7.611-17 17s7.611 17 17 17 17-7.611 17-17-7.611-17-17-17z"
                />
                <circle cx="87" cy="31" r="3" fill="#FFF" opacity="0.4" />
                <circle cx="96" cy="31" r="3" fill="#FFF" opacity="0.4" />
                <circle cx="105" cy="31" r="3" fill="#FFF" opacity="0.4" />
            </g>
        </svg>
    );
};

export default NotificationsPlaceholder;
