import React from "react";
import { useStyles } from "./DraggingCardDivider.styles";
import { Box } from "@material-ui/core";

const DraggingCardDivider = ({ hoverPart }) => {
    const classes = useStyles({ hoverPart });
    return (
        <Box className={classes.container}>
            <Box className={classes.oval} />
            <Box className={classes.line} />
            <Box className={classes.oval} />
        </Box>
    );
};

export default DraggingCardDivider;
