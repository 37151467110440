import { types } from "mobx-state-tree";

import RestrictUiSettings from "api/webserver/Types/RestrictUiSettings";

const RestrictUiSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(RestrictUiSettings)
});

export default RestrictUiSettingsResult;
