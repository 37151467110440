import { types } from "mobx-state-tree";

import BeepMuteState from "api/events/Types/BeepMuteState";

const BeepMuteStateResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(BeepMuteState)
});

export default BeepMuteStateResult;
