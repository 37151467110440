import { types } from "mobx-state-tree";

import DriveId from "api/bcache/Types/DriveId";

const GetDrivesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(DriveId)
});

export default GetDrivesResult;
