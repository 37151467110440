import Request from "api/Request";

const GetDevices = Request.props({
    path: "/com/studionetworksolutions/evo/services/Backup",
    method: "get_devices"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetDevices;
