import { types } from "mobx-state-tree";

import VolumeId from "api/volume/Types/VolumeId";
import TaskStatus from "api/volume/Types/TaskStatus";
import DriveId from "api/volume/Types/DriveId";

const BuildTaskState = types
    .model({
        volumeId: types.maybe(VolumeId),
        raid: types.number,
        size: types.number,
        chunkSize: types.number,
        iSCSIBlockSize: types.number,
        ioProfile: types.string,
        status: types.maybe(TaskStatus),
        cache: types.maybe(DriveId),
        cacheSupport: types.boolean
    })
    .views(self => ({
        get volumeName() {
            return `${self.volumeId.pool}/${self.volumeId.volume}`;
        },
        get statusHealth() {
            return self.status.health;
        },
        get statusString() {
            return self.status.status;
        }
    }));

export default BuildTaskState;
