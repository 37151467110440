import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStyles } from "./ExternalUserCredentialsStep.styles";
import { Box, ButtonBase, Grid, InputAdornment, Typography } from "@material-ui/core";
import TextInputMobX from "components/MobxForm/TextInput";
import ViewIcon from "assets/ViewIcon";
import InputErrorIcon from "assets/InputErrorIcon";
import clsx from "clsx";
import i18next from "i18next";
import { useToggle } from "hooks/useToggle";
import ButtonNext from "../ButtonNext/ButtonNext";
import { useStore } from "hooks/useStore";
import TextInput from "components/TextInput/TextInput";
import WarningDialog from "./components/WarningDialog";
import { useModal } from "hooks/useModal";
import { useTranslation } from "react-i18next";

const ExternalUserCredentialsStep = ({ ldap }) => {
    const classes = useStyles();
    const {
        store: { migrationStore },
    } = useStore();

    const { t } = useTranslation();

    useEffect(() => {
        if (ldap) {
            migrationStore.getLdapMigrationInfo();
        } else {
            migrationStore.getAdMigrationInfo();
        }
    }, [ldap]);

    const [showPassword, toggleShowPassword] = useToggle();
    const [showErrorPassword, toggleShowErrorPassword] = useToggle();

    const handleClickNext = async () => {
        const res = await migrationStore.runExternalUsersStep();
        if (res) migrationStore.nextStep();
    };

    const warningModal = useModal();

    return (
        <React.Fragment>
            <Box p={6}>
                <Grid container spacing={2}>
                    <Grid container item spacing={6} alignItems="center">
                        <Grid item xs={3}>
                            <Typography className={clsx(classes.label, classes.usernameLabel)}>
                                {i18next.t(
                                    ldap ? "migrator.credential_step.label.host" : "migrator.credential_step.label.domain"
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={9} className={classes.inputItem}>
                            <TextInput
                                value={ldap ? migrationStore.ldapMigrationInfo?.host : migrationStore.adMigrationInfo?.domain}
                                className={classes.input}
                                InputProps={{ readOnly: true, disableUnderline: true }}
                                disabled
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={6} alignItems="center">
                        <Grid item xs={3}>
                            <Typography className={clsx(classes.label, classes.usernameLabel)}>
                                {i18next.t(ldap ? "migrator.credential_step.label.username" : "common.label.username")}
                            </Typography>
                        </Grid>
                        <Grid item xs={9} className={classes.inputItem}>
                            <TextInputMobX
                                field={migrationStore.externalCredentialsForm.$("name")}
                                className={classes.input}
                                autoFocus
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={6} alignItems="center">
                        <Grid item xs={3}>
                            <Typography className={clsx(classes.label, classes.passwordLabel)}>
                                {i18next.t(ldap ? "migrator.credential_step.label.password" : "common.label.password")}
                            </Typography>
                        </Grid>
                        <Grid item xs={9} className={classes.inputItem}>
                            <TextInputMobX
                                field={migrationStore.externalCredentialsForm.$("password")}
                                type={showPassword ? "text" : "password"}
                                className={classes.input}
                                showErrorText={showErrorPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {migrationStore.externalCredentialsForm.$("password").value ? (
                                                <ButtonBase onMouseDown={toggleShowPassword} onMouseUp={toggleShowPassword}>
                                                    <ViewIcon />
                                                </ButtonBase>
                                            ) : null}
                                            {migrationStore.externalCredentialsForm.$("password").error ? (
                                                <ButtonBase onClick={toggleShowErrorPassword}>
                                                    <InputErrorIcon />
                                                </ButtonBase>
                                            ) : null}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Box pt={8}>
                    <Grid container spacing={6} justifyContent="flex-end">
                        <Grid item>
                            <ButtonNext text={t("migration.button.skip")} onClick={warningModal.open} />
                        </Grid>
                        <Grid item>
                            <ButtonNext onClick={handleClickNext} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <WarningDialog
                open={warningModal.isOpen}
                onClose={warningModal.close}
                submit={migrationStore.skipExternalUsersStep}
            />
        </React.Fragment>
    );
};

export default observer(ExternalUserCredentialsStep);
