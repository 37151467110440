import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    textWithOpacity: {
        opacity: 0.4
    },
    rowWithSelect: {
        padding: theme.spacing(6, 0, 8)
    }
}));
