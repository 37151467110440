import { types } from "mobx-state-tree";

import SnmpAgentSettings from "api/events/Types/SnmpAgentSettings";

const SnmpAgentSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(SnmpAgentSettings)
});

export default SnmpAgentSettingsResult;
