import { types } from "mobx-state-tree";
import NasConfiguration from "api/share/Types/NasConfiguration";

const GetNasConfigurationResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(NasConfiguration)
});

export default GetNasConfigurationResult;
