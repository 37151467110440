import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        paddingBottom: theme.spacing(8)
    },
    boldText: {
        fontWeight: 500
    }
}));
