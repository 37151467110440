import i18n from "i18n";

const minMaxSizeValidator = ({ min, max }) => {
    return ({ field }) => {
        return [
            !field.value.length || (field.value >= min && field.value <= max),
            i18n.t("validator.min_max.size", { minValue: min, maxValue: max })
        ];
    };
};

export default minMaxSizeValidator;
