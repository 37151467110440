import { types } from "mobx-state-tree";

import LdapMigrationInfo from "api/upgrade/migration/Types/LdapMigrationInfo";

const GetLdapMigrationInfoResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(LdapMigrationInfo),
});

export default GetLdapMigrationInfoResult;
