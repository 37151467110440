const getPermissionApplyToDefault = applyTo => {
    if (applyTo.directory && !applyTo.subDirectories && !applyTo.subFiles) return "folderOnly";
    if (applyTo.directory && applyTo.subDirectories && applyTo.subFiles) return "folderSubfoldersFiles";
    if (applyTo.directory && applyTo.subDirectories && !applyTo.subFiles) return "folderSubfolders";
    if (!applyTo.directory && applyTo.subDirectories && applyTo.subFiles) return "subfoldersFiles";
    if (!applyTo.directory && applyTo.subDirectories && !applyTo.subFiles) return "subfolders";
    if (!applyTo.directory && !applyTo.subDirectories && applyTo.subFiles) return "files";
    if (applyTo.directory && !applyTo.subDirectories && applyTo.subFiles) return "folderFiles";
    return null;
};

export default getPermissionApplyToDefault;
