import { types } from "mobx-state-tree";

const ArgumentsTaskType = types.model({
    request: types.maybe(
        types.model({
            decomposed_to: types.model({
                schema: types.maybe(types.string),
                host: types.maybe(types.string),
                path: types.maybe(types.string),
                region: types.maybe(types.string),
                resource: types.maybe(types.string)
            }),
            operation: types.maybe(types.string),
            replace: types.maybe(types.boolean),
            resume: types.maybe(types.boolean),
            rename_pattern: types.maybe(types.string),
            rename_pattern_max_n: types.maybe(types.number),
            speed_limit: types.maybe(types.union(types.string, types.number)),
            create_folder: types.maybe(types.boolean),
            multi_threads: types.maybe(types.boolean),
            engine: types.maybe(types.string),
            log_filename: types.maybe(types.string),
            use_destination_filter: types.maybe(types.boolean)
        })
    ),
    destination: types.maybe(
        types.union(
            types.string,
            types.model({
                user: types.maybe(types.string),
                password: types.maybe(types.string),
                metadata: types.maybe(
                    types.model({
                        ftp_user_dir_is_root: types.maybe(types.boolean),
                        ftp_passive: types.maybe(types.boolean)
                    })
                )
            })
        )
    ),
    destination_mode: types.maybe(types.string),
    log_level: types.maybe(types.string),
    unknownStreamMode: types.maybe(types.string),
    preset: types.maybe(types.union(types.number, types.string)),
    s3: types.maybe(
        types.model({
            replace: types.maybe(types.boolean),
            url: types.maybe(types.string)
        })
    ),
    preserve_access_code: types.maybe(types.boolean),
    subject: types.maybe(types.string),
    recipients: types.maybe(types.string),
    body: types.maybe(types.string),
    pathBasedProxy: types.maybe(types.boolean),
    destinationName: types.maybe(types.string),
    destinationExt: types.maybe(types.string),
    automation_vol_uuid: types.maybe(types.string),
    automation_vol_indexing: types.maybe(types.boolean),
    indexing_mode: types.maybe(types.string),
    access_code: types.maybe(types.string),
    confidence: types.maybeNull(types.string),
    limit: types.maybeNull(types.number)
});

export default ArgumentsTaskType;
