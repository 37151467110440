/* eslint-disable no-console */
import React from "react";
import i18n from "i18n";
import {
    URL_ADMIN_SETTINGS,
    URL_ADVANCED,
    URL_ALERTS,
    URL_DISKS,
    URL_FILE_NAVIGATOR,
    URL_HARDWARE_ACTIVATION,
    URL_NETWORK,
    URL_POWER_AND_UPS,
    URL_QUICK_START,
    URL_RECYCLE_BIN,
    URL_SHARES,
    URL_SHARES_REMOTE_TAB,
    URL_SUPPORT,
    URL_SUPPORT_SELF_TESTS_TAB,
    URL_SUPPORT_OS_DATA_TAB,
    URL_TIME_SETTINGS,
    URL_USERS,
    URL_SLINGSHOT,
    URL_SLINGSHOT_INDEX,
    URL_SLINGSHOT_AUTOMATIONS,
    URL_SLINGSHOT_SETTINGS_TAB,
    URL_SHAREBROWSER,
    URL_SHORTCUT_WHATS_THIS,
    URL_HARDWARE_PROFILE,
    URL_AUDIT,
    URL_FIREWALL,
    URL_WIREGUARD,
    URL_TROUBLESHOOTING,
    URL_CENTRAL_STATUS,
    URL_CLUSTER,
    URL_FILE_NAVIGATOR_LOG,
} from "routes";
import {
    ADMIN_SETTINGS,
    ADVANCED,
    ALERTS,
    DISKS_AND_POOLS,
    FILE_NAVIGATOR,
    HARDWARE_ACTIVATION,
    NETWORK,
    POWER_AND_UPS,
    QUICK_START,
    RECYCLE_BIN,
    SHARES,
    REMOTE_SHARES,
    SUPPORT,
    SUPPORT_SELF_TESTS,
    SUPPORT_OS_DATA,
    TIME_SETTINGS,
    USERS,
    SLINGSHOT,
    SLINGSHOT_INDEX,
    SLINGSHOT_AUTOMATIONS,
    SLINGSHOT_SETTINGS,
    SHAREBROWSER,
    WHATS_THIS,
    HARDWARE_PROFILE,
    AUDIT,
    FIREWALL,
    WIREGUARD,
    TROUBLESHOOTING,
    CENTRAL_STATUS,
    CLUSTER,
    FILE_NAVIGATOR_LOG,
} from "const/shortcutNameConst";
import { SHORTCUT_WIDGET_NOT_PROVIDER } from "const/errorConst";
import OptionsIcon from "assets/OptionsIcon";
import OptionsAdminIcon from "assets/OptionsAdminIcon";
import AdvancedIcon from "assets/AdvancedIcon";
import SpeakerIcon from "assets/SpeakerIcon";
import DisksIcon from "assets/DisksIcon";
import DocIcon from "assets/DocIcon";
import WorldIcon from "assets/WorldIcon";
import QuickStartIcon from "assets/QuickStartIcon";
import TrashIcon from "assets/TrashIcon";
import SharingIcon from "assets/SharingIcon";
import ShieldIcon from "assets/ShieldIcon";
import TimeIcon from "assets/TimeIcon";
import UserIcon from "assets/UserIcon";
import GiftIcon from "assets/GiftIcon";
import BrowserIcon from "assets/BrowserIcon";
import SlingshotIcon from "assets/SlingshotIcon";
import SpeedIcon from "assets/SpeedIcon";
import OffIcon from "assets/OffIcon";
import ConnectIcon from "assets/ConnectIcon";
import SupportIcon from "assets/SupportIcon";
import SupportHeaderIcon from "assets/SupportHeaderIcon";
import ChartIcon from "assets/ChartIcon";
import FilesIcon from "assets/FilesIcon";

const shortcutFactory = (shortcut) => {
    if (!shortcut) {
        throw new Error(SHORTCUT_WIDGET_NOT_PROVIDER);
    }
    switch (shortcut) {
        case WHATS_THIS: {
            return {
                icon: <GiftIcon />,
                label: i18n.t("side_menu.menu_tabs.whats_this"),
                url: URL_SHORTCUT_WHATS_THIS,
            };
        }
        case ADMIN_SETTINGS: {
            return {
                icon: <OptionsAdminIcon />,
                label: i18n.t("side_menu.system_menu.admin_settings"),
                url: URL_ADMIN_SETTINGS,
            };
        }
        case ADVANCED: {
            return {
                icon: <AdvancedIcon />,
                label: i18n.t("side_menu.system_menu.advanced"),
                url: URL_ADVANCED,
            };
        }
        case ALERTS: {
            return {
                icon: <SpeakerIcon />,
                label: i18n.t("side_menu.menu_tabs.alerts"),
                url: URL_ALERTS,
            };
        }
        case DISKS_AND_POOLS: {
            return {
                icon: <DisksIcon />,
                label: i18n.t("side_menu.menu_tabs.disk_pools"),
                url: URL_DISKS,
            };
        }
        case FILE_NAVIGATOR: {
            return {
                icon: <DocIcon />,
                label: i18n.t("side_menu.menu_tabs.file_navigator"),
                url: URL_FILE_NAVIGATOR,
            };
        }
        case NETWORK: {
            return {
                icon: <WorldIcon />,
                label: i18n.t("side_menu.menu_tabs.network"),
                url: URL_NETWORK,
            };
        }
        case POWER_AND_UPS: {
            return {
                icon: <OffIcon />,
                label: i18n.t("side_menu.menu_tabs.power_and_ups"),
                url: URL_POWER_AND_UPS,
            };
        }
        case QUICK_START: {
            return {
                icon: <QuickStartIcon />,
                label: i18n.t("side_menu.menu_tabs.quick_start"),
                url: URL_QUICK_START,
            };
        }
        case RECYCLE_BIN: {
            return {
                icon: <TrashIcon />,
                label: i18n.t("side_menu.menu_tabs.recycle_bin"),
                url: URL_RECYCLE_BIN,
            };
        }
        case SHARES: {
            return {
                icon: <SharingIcon />,
                label: i18n.t("side_menu.menu_tabs.shares"),
                url: URL_SHARES,
            };
        }
        case SHAREBROWSER: {
            return {
                icon: <BrowserIcon />,
                label: i18n.t("sharebrowser_page.title"),
                url: URL_SHAREBROWSER,
            };
        }
        case SLINGSHOT: {
            return {
                icon: <SlingshotIcon />,
                label: i18n.t("slingshot_page.tabs.label.replications"),
                url: URL_SLINGSHOT,
            };
        }
        case SLINGSHOT_INDEX: {
            return {
                icon: <SlingshotIcon />,
                label: i18n.t("slingshot_page.tabs.label.index"),
                url: URL_SLINGSHOT_INDEX,
            };
        }
        case SLINGSHOT_AUTOMATIONS: {
            return {
                icon: <SlingshotIcon />,
                label: i18n.t("slingshot_page.tabs.label.automations"),
                url: URL_SLINGSHOT_AUTOMATIONS,
            };
        }
        case SLINGSHOT_SETTINGS: {
            return {
                icon: <SlingshotIcon />,
                label: i18n.t("slingshot_page.tabs.label.settings"),
                url: URL_SLINGSHOT_SETTINGS_TAB,
            };
        }
        case REMOTE_SHARES: {
            return {
                icon: <SharingIcon />,
                label: i18n.t("share.tabs.remote_shares"),
                url: URL_SHARES_REMOTE_TAB,
            };
        }
        case SUPPORT: {
            return {
                icon: <SupportHeaderIcon />,
                label: i18n.t("support.title"),
                url: URL_SUPPORT,
            };
        }
        case SUPPORT_SELF_TESTS: {
            return {
                icon: <SupportHeaderIcon />,
                label: i18n.t("support.tab.self_tests"),
                url: URL_SUPPORT_SELF_TESTS_TAB,
            };
        }
        case SUPPORT_OS_DATA: {
            return {
                icon: <SupportHeaderIcon />,
                label: i18n.t("support.tab.os_data"),
                url: URL_SUPPORT_OS_DATA_TAB,
            };
        }
        case TIME_SETTINGS: {
            return {
                icon: <TimeIcon />,
                label: i18n.t("side_menu.system_menu.time_settings"),
                url: URL_TIME_SETTINGS,
            };
        }
        case TROUBLESHOOTING: {
            return {
                icon: <SupportIcon />,
                label: i18n.t("side_menu.system_menu.troubleshooting"),
                url: URL_TROUBLESHOOTING,
            };
        }
        case USERS: {
            return {
                icon: <UserIcon />,
                label: i18n.t("side_menu.menu_tabs.user"),
                url: URL_USERS,
            };
        }
        case HARDWARE_ACTIVATION: {
            return {
                icon: <OptionsIcon />,
                label: i18n.t("side_menu.system_menu.hardware_activation"),
                url: URL_HARDWARE_ACTIVATION,
            };
        }
        case HARDWARE_PROFILE: {
            return {
                icon: <SpeedIcon />,
                label: i18n.t("side_menu.system_menu.hardware_profile"),
                url: URL_HARDWARE_PROFILE,
            };
        }
        case FIREWALL: {
            return {
                icon: <ConnectIcon />,
                label: i18n.t("side_menu.system_menu.firewall"),
                url: URL_FIREWALL,
            };
        }
        case AUDIT: {
            return {
                icon: <OptionsAdminIcon />,
                label: i18n.t("side_menu.system_menu.user_auditing"),
                url: URL_AUDIT,
            };
        }
        case WIREGUARD: {
            return {
                icon: <ShieldIcon />,
                label: i18n.t("side_menu.menu_tabs.wireguard"),
                url: URL_WIREGUARD,
            };
        }
        case CENTRAL_STATUS: {
            return {
                icon: <SpeedIcon />,
                label: i18n.t("central_status.title"),
                url: URL_CENTRAL_STATUS,
            };
        }
        case CLUSTER: {
            return {
                icon: <ChartIcon />,
                label: i18n.t("cluster.title"),
                url: URL_CLUSTER,
            };
        }
        case FILE_NAVIGATOR_LOG: {
            return {
                icon: <FilesIcon />,
                label: i18n.t("file_navigator_log.title_short"),
                url: URL_FILE_NAVIGATOR_LOG,
            };
        }
        default: {
            console.log(`No shortcut with value ${shortcut}`);
        }
    }
};

export default shortcutFactory;
