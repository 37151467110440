import { types } from "mobx-state-tree";

const DiskTest = types.model({
    readBandwidth: types.number,
    writeBandwidth: types.number,
    readIops: types.number,
    writeIops: types.number,
    systemCpu: types.number,
    stage: types.string
});

export default DiskTest;
