import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    masksContainer: {
        borderRadius: theme.spacing(2),
        border: `solid 1px ${theme.palette.divider}`,
        backgroundColor: theme.palette.fileMasksTable.background,
        paddingBottom: theme.spacing(4),
        "&& .MuiTableCell-root": {
            padding: theme.spacing(1, 4)
        }
    },
    row: {
        "&:first-child .MuiTableCell-root": {
            paddingTop: theme.spacing(4)
        },
        "&.MuiTableRow-root:not(.MuiTableRow-head):hover": {
            backgroundColor: "initial"
        }
    },
    headRow: {
        "&& .MuiTableCell-root": {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            opacity: 0.8
        }
    },
    addButton: {
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(3)
    },
    deleteButtonContainer: {
        "& svg path": {
            fill: theme.palette.grey[300]
        }
    }
}));
