import { types, flow, getParent } from "mobx-state-tree";

import Socket from "websocket";
import GetNotificationPage from "api/event/Requests/GetNotificationPage";
import GetBatchPage from "api/event/Requests/GetBatchPage";
import GetProgressPage from "api/event/Requests/GetProgressPage";
import PageQuery from "api/event/Types/PageQuery";
import GetNotificationPageResult from "api/event/Types/GetNotificationPageResult";
import GetBatchPageResult from "api/event/Types/GetBatchPageResult";
import GetProgressPageResult from "api/event/Types/GetProgressPageResult";
import NotificationEvent from "api/event/Types/NotificationEvent";
import { USER } from "const/userRolesConst";

const DEFAULT_LIMIT = 30;

const NotificationStore = types
    .model({
        notificationPageResult: types.optional(GetNotificationPageResult, { data: { total: 0, data: [] } }),
        batchPageResult: types.optional(GetBatchPageResult, { data: { total: 0, data: [] } }),
        progressPageResult: types.optional(GetProgressPageResult, { data: { total: 0, data: [] } }),
        newNotificationsCount: types.optional(types.number, 0),
        newNotifications: types.optional(types.array(NotificationEvent), []),
    })
    .views((self) => ({
        get notifications() {
            const { uiStore, authStore } = getParent(self);

            const notifications =
                [
                    ...self.notificationPageResult.data.data,
                    ...self.batchPageResult.data.data,
                    ...self.progressPageResult.data.data,
                ].sort((a, b) => b.orderId - a.orderId) || [];

            // TODO: refactor filtration
            const filteredNotifications = uiStore.parameters?.notificationsFilterSettings
                ? notifications.filter(({ eventLevel }) => {
                      const key = eventLevel === "warn" ? "warning" : eventLevel;
                      return uiStore.parameters?.notificationsFilterSettings[key];
                  })
                : notifications;
            return filteredNotifications
                .filter(({ eventType }) => {
                    if (authStore.role === USER) {
                        return eventType.includes("slingshot");
                    }

                    return true;
                })
                .reduce((acc, el) => {
                    const duplicate = acc.find((accEl) => accEl.id === el.id && accEl.eventType === el.eventType);

                    if (duplicate) {
                        return acc;
                    }

                    return [...acc, el];
                }, []);
        },
        get filteredNewNotificationsCount() {
            const { uiStore, authStore } = getParent(self);

            return self.newNotifications
                .filter(({ eventLevel }) => {
                    const key = eventLevel === "warn" ? "warning" : eventLevel;
                    const hasFlag =
                        typeof uiStore.parameters?.notificationsFilterSettings?.[key] === "boolean"
                            ? uiStore.parameters?.notificationsFilterSettings?.[key]
                            : true;

                    return hasFlag && key !== "info";
                })
                .filter(({ eventType }) => {
                    if (authStore.role === USER) {
                        return eventType.includes("slingshot");
                    }

                    return true;
                }).length;
        },
        isEventForProgressTaskExist(taskId) {
            return self.progressPageResult.data.data.some((event) => event.taskId === taskId);
        },
        isEventForBatchTaskExist(taskId) {
            return self.batchPageResult.data.data.some((event) => event.taskId === taskId);
        },
    }))
    .actions((self) => ({
        fetchNotifications: flow(function* (
            notificationRequestParams = PageQuery.create({ offset: 0, limit: DEFAULT_LIMIT }),
            batchNotificationRequestParams = PageQuery.create({ offset: 0, limit: DEFAULT_LIMIT }),
            progressNotificationRequestParams = PageQuery.create({ offset: 0, limit: DEFAULT_LIMIT })
        ) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const notificationReq = GetNotificationPage.create().init(notificationRequestParams);
                const batchNotificationReq = GetBatchPage.create().init(batchNotificationRequestParams);
                const progressNotificationReq = GetProgressPage.create().init(progressNotificationRequestParams);
                const [notificationRes, batchNotificationRes, progressNotificationRes] = yield Promise.all([
                    Socket.send(notificationReq),
                    Socket.send(batchNotificationReq),
                    Socket.send(progressNotificationReq),
                ]);

                self.notificationPageResult = notificationRes;
                self.batchPageResult = batchNotificationRes;
                self.progressPageResult = progressNotificationRes;

                self.setNewNotificationsCount(0);
                self.newNotifications = [];
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        addNotification(data) {
            const { uiStore } = getParent(self);
            self.notificationPageResult.data.data.push(data);

            if (!uiStore.isNotificationMenuOpen) {
                self.newNotificationsCount += 1;
                self.newNotifications.push(data);
            }
        },
        addBatchNotification(data) {
            const { taskStore } = getParent(self);
            self.batchPageResult.data.data.push(data);
            taskStore.removeBatchTask(data.taskId);
        },
        addProgressNotification(data) {
            const { taskStore } = getParent(self);
            self.progressPageResult.data.data.push(data);
            taskStore.removeTask(data.taskId);
        },
        setNewNotificationsCount(count) {
            self.newNotificationsCount = count;
        },
    }));

export default NotificationStore;
