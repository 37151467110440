import { makeStyles } from "@material-ui/core/styles";

const BREADCRUMBS_FONT = {
    fontSize: 16,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "0.2px"
};

export const useStyles = makeStyles(theme => ({
    trashIcon: {
        "& svg path": {
            fill: theme.palette.error.main
        }
    },
    parentBreadcrumbs: {
        paddingTop: theme.spacing(4)
    },
    parentName: {
        "&& ": {
            ...BREADCRUMBS_FONT,
            color: theme.palette.primary.main,
            opacity: 0.4
        }
    },
    tasksLabel: {
        "&& ": {
            ...BREADCRUMBS_FONT,
            color: theme.palette.primary.main,
            opacity: 1
        }
    },
    selectLabel: {
        "&&": {
            fontWeight: "normal",
            fontSize: 14,
            opacity: 0.8
        }
    },
    select: {
        "& > .MuiInputBase-root": {
            backgroundColor: "initial"
        },
        "&& span": {
            fontSize: 14
        }
    }
}));
