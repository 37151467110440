import Request from "api/Request";

const GetSettings = Request.props({
    path: "/com/studionetworksolutions/evo/Auth",
    method: "get_settings",
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default GetSettings;
