import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetAliases from "api/slingshot/settings/Requests/GetAliases";
import GetPresets from "api/slingshot/settings/Requests/GetPresets";
import GetPresetConstraints from "api/slingshot/settings/Requests/GetPresetConstraints";
import GetAliasesResult from "api/slingshot/settings/Responses/GetAliasesResult";
import GetPresetConstraintsResult from "api/slingshot/settings/Responses/GetPresetConstraintsResult";
import CreateAlias from "api/slingshot/settings/Requests/CreateAlias";
import UpdateAlias from "api/slingshot/settings/Requests/UpdateAlias";
import DeleteAlias from "api/slingshot/settings/Requests/DeleteAlias";
import GetPresetsResult from "api/slingshot/settings/Responses/GetPresetsResult";
import GetAutotaggingMachineSettings from "api/slingshot/settings/Requests/GetAutotaggingMachineSettings";
import GetAutotaggingMachineSettingsResult from "api/slingshot/settings/Responses/GetAutotaggingMachineSettingsResult";
import GetTransportingConfig from "api/slingshot/settings/Requests/GetTransportingConfig";
import GetTransportingConfigResult from "api/slingshot/settings/Responses/GetTransportingConfigResult";
import SetTransportingConfig from "api/slingshot/settings/Requests/SetTransportingConfig";
import GetShares from "api/slingshot/storage/Requests/GetShares";
import GetSharesResult from "api/slingshot/storage/Responses/GetSharesResult";
import GetWatermarkImageResult from "api/slingshot/settings/Responses/GetWatermarkImageResult";
import GetFolders from "api/slingshot/storage/Requests/GetFolders";
import GetFoldersResult from "api/slingshot/storage/Responses/GetFoldersResult";
import CreatePreset from "api/slingshot/settings/Requests/CreatePreset";
import UpdatePreset from "api/slingshot/settings/Requests/UpdatePreset";
import SetAutotaggingConfig from "api/slingshot/settings/Requests/SetAutotaggingConfig";
import ResetAutotaggingConfig from "api/slingshot/settings/Requests/ResetAutotaggingConfig";
import DeletePreset from "api/slingshot/settings/Requests/DeletePreset";
import StartRegistration from "api/slingshot/settings/Requests/StartRegistration";
import GetRegistrations from "api/slingshot/settings/Requests/GetRegistrations";
import GetRegistrationResult from "api/slingshot/storage/Responses/GetRegistrationResult";
import DeleteRegistration from "api/slingshot/settings/Requests/DeleteRegistration";
import { ASC, DESC, SLINGSHOT_ALIAS_NAME, SLINGSHOT_PRESET_NAME, SLINGSHOT_WATERMARK_CREATED } from "const/sortColumnConst";
import { VCODEC_ARRAY, CONTAINER_ARRAY, THUMBNAIL_PRESET_NAME } from "const/slingshotPresetConst";
import GetWatermarks from "api/slingshot/settings/Requests/GetWatermarks.js";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import CreateFolder from "../../../api/slingshot/storage/Requests/CreateFolder";
import DeleteWatermark from "api/slingshot/settings/Requests/DeleteWatermark";
import CreateWatermark from "api/slingshot/settings/Requests/CreateWatermark";
import UpdateWatermark from "api/slingshot/settings/Requests/UpdateWatermark";
import GetWatermark from "api/slingshot/settings/Requests/GetWatermark";
import GetWatermarkImage from "api/slingshot/settings/Requests/GetWatermarkImage";
import UploadWatermarkImage from "api/slingshot/settings/Requests/UploadWatermarkImage";
import WatermarksResult from "api/slingshot/settings/Types/WatermarksResult";
import axios from "api/AxiosCommonRequest";
import { FILE_DOWNLOAD_URL } from "api/restRoutes";

const SlingshotSettingsStore = types
    .model({
        aliasesStore: types.maybe(GetAliasesResult),
        presetsStore: types.maybe(GetPresetsResult),
        watermarkImageStore: types.maybe(GetWatermarkImageResult),
        presetConstraintsStore: types.maybe(GetPresetConstraintsResult),
        currentAliasName: types.maybeNull(types.string),
        currentPresetName: types.maybeNull(types.string),
        configPresetName: types.maybeNull(types.string),
        autotaggingMachineSettings: types.maybe(GetAutotaggingMachineSettingsResult),
        transportingConfig: types.maybe(GetTransportingConfigResult),
        sharesStore: types.maybe(GetSharesResult),
        foldersStore: types.maybe(GetFoldersResult),
        primaryAliasId: types.maybe(types.number),
        registrationsStore: types.maybe(GetRegistrationResult),
        errorConnectedDBus: types.optional(types.boolean, false),
        watermarksResult: types.maybe(WatermarksResult),
        currentWatermarkName: types.maybeNull(types.string),
        watermarkImage: types.maybeNull(types.string),
        isWatermarkImageError: types.optional(types.boolean, false),
    })
    .volatile(() => ({
        orderByAlias: SLINGSHOT_ALIAS_NAME,
        orderAlias: ASC,
        orderByWatermark: SLINGSHOT_WATERMARK_CREATED,
        orderWatermark: ASC,
        orderByPreset: SLINGSHOT_PRESET_NAME,
        orderPreset: ASC,
    }))
    .views((self) => ({
        get aliases() {
            return self.aliasesStore?.data?.json?.aliases || [];
        },
        get sortedAliases() {
            return self.aliases.length && stableSort(self.aliases, getComparator(self.orderAlias, self.orderByAlias));
        },
        get presets() {
            return self.presetsStore?.data?.json?.presets || [];
        },
        get sortedPresets() {
            const presets = self.presets
                ?.filter((preset) => !preset.name.includes(THUMBNAIL_PRESET_NAME))
                ?.map((preset) => {
                    return {
                        ...preset,
                        ...preset.arguments,
                        vcodec: !preset.arguments?.vcodec
                            ? VCODEC_ARRAY[1].value
                            : !preset.arguments?.vcodec?.value
                            ? VCODEC_ARRAY[0].value
                            : preset.arguments?.vcodec?.value,
                        container: preset.arguments?.container?.value || CONTAINER_ARRAY[0].value,
                    };
                });
            return presets.length && stableSort(presets, getComparator(self.orderPreset, self.orderByPreset));
        },
        get currentAlias() {
            return (self.currentAliasName && self.aliases.find((alias) => alias.name === self.currentAliasName)) || null;
        },
        get currentPreset() {
            return (
                (self.currentPresetName && self.sortedPresets.find((preset) => preset.name === self.currentPresetName)) || null
            );
        },
        get currentPresetConstraints() {
            return self.presetConstraintsStore?.data?.json?.constraints
                ? self.presetConstraintsStore?.data?.json?.constraints.reduce((acc, constraint) => {
                      switch (constraint.name) {
                          case "burnt_timecode":
                              return [
                                  ...acc,
                                  {
                                      name: "burnt_timecode",
                                      defaultValue: constraint.defaultValue?.disabled ? constraint.defaultValue.disabled : null,
                                  },
                                  {
                                      name: "timecode_position",
                                      defaultValue: constraint.defaultValue?.position ? constraint.defaultValue.position : null,
                                  },
                              ];
                          case "watermark":
                              return [
                                  ...acc,
                                  {
                                      name: "watermark_position",
                                      defaultValue: constraint.defaultValue?.position ? constraint.defaultValue.position : null,
                                  },
                                  {
                                      name: "watermark_scale",
                                      defaultValue: constraint.defaultValue?.scale ? constraint.defaultValue.scale : null,
                                  },
                              ];
                          default:
                              return [
                                  ...acc,
                                  {
                                      name: constraint.name,
                                      defaultValue: constraint.defaultValue ? constraint.defaultValue : null,
                                  },
                              ];
                      }
                  }, [])
                : [];
        },
        get configAutotaggingPreset() {
            return (self.configPresetName && self.sortedPresets.find((preset) => preset.name === self.configPresetName)) || null;
        },
        get autotaggingMachineSettingsData() {
            return self.autotaggingMachineSettings?.data?.json;
        },
        get autotaggingPreset() {
            return (
                self.presets.length &&
                self.autotaggingMachineSettingsData &&
                self.presets.find((preset) => preset.id === self.autotaggingMachineSettingsData.defaults?.transcoding_preset)
            );
        },
        get autotaggingAlias() {
            return self.aliases.find((alias) => alias.name === self.autotaggingMachineSettingsData.defaults?.s3_alias);
        },
        get replicationLogStorage() {
            return self.transportingConfig?.data?.json?.logStorage;
        },
        get shares() {
            return (self.sharesStore?.data?.json?.shares || []).filter((share) => share.active);
        },
        get foldersList() {
            return self.foldersStore?.data?.json?.list || [];
        },
        get isErrorConnectedDBus() {
            return self.errorConnectedDBus;
        },
        get watermarks() {
            return self.watermarksResult?.data?.json.list ?? [];
        },
        get currentWatermark() {
            return (
                (self.currentWatermarkName &&
                    self.sortedWatermarks.find((watermark) => watermark.name === self.currentWatermarkName)) ||
                null
            );
        },
        get sortedWatermarks() {
            return self.watermarks.length
                ? stableSort(self.watermarks, getComparator(self.orderWatermark, self.orderByWatermark))
                : [];
        },
    }))
    .actions((self) => ({
        fetchAliases: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetAliases.create().init({ json: "{}" });
                self.aliasesStore = yield Socket.send(req);
                return true;
            } catch (e) {
                switch (e.code) {
                    case 404:
                        self.errorConnectedDBus = true;
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
            }
        }),
        createAlias: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = CreateAlias.create().init({
                    json: data,
                });
                const res = yield Socket.send(req);
                const resData = JSON.parse(res.data.json);
                self.primaryAliasId = resData && resData.id;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateAlias: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = UpdateAlias.create().init({
                    json: data,
                });
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        deleteAlias: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DeleteAlias.create().init({
                    json: data,
                });
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        registrationAlias: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const primaryAliasId = self.currentAlias?.id || self.primaryAliasId;
                const payload = { ...data, primary_alias: { id: primaryAliasId } };
                const req = StartRegistration.create().init({
                    json: payload,
                });
                const res = yield Socket.send(req);
                if (res) {
                    const status = JSON.parse(res.data?.json);
                    res && self.defineAliasStatus({ primaryAliasId: primaryAliasId, status: status.status });
                }
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getRegistrations: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetRegistrations.create().init({ json: "{}" });
                self.registrationsStore = yield Socket.send(req);
                self.registrationsStore.data?.json?.items?.length && self.deleteRegistrations();
                return true;
            } catch (e) {
                switch (e.code) {
                    case 404:
                        self.errorConnectedDBus = true;
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
            }
        }),
        removeRegistration: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DeleteRegistration.create().init({
                    json: data,
                });
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchPresets: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetPresets.create().init({ json: "{}" });
                const res = yield Socket.send(req);
                self.presetsStore = res;
                return true;
            } catch (e) {
                switch (e.code) {
                    case 404:
                        self.errorConnectedDBus = true;
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchPresetConstraints: flow(function* (presetId) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetPresetConstraints.create().init({ json: { presetId: presetId ? presetId : 0 } });
                const res = yield Socket.send(req);
                self.presetConstraintsStore = res;
                return true;
            } catch (e) {
                switch (e.code) {
                    case 404:
                        self.errorConnectedDBus = true;
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchAutotaggingMachineSettings: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetAutotaggingMachineSettings.create().init({ json: "{}" });
                self.autotaggingMachineSettings = yield Socket.send(req);
                return true;
            } catch (e) {
                switch (e.code) {
                    case 404:
                        self.errorConnectedDBus = true;
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setAutotaggingConfig: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetAutotaggingConfig.create().init({
                    json: data,
                });
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        resetAutotaggingConfig: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ResetAutotaggingConfig.create().init({
                    json: "{}",
                });
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchTransportingConfig: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetTransportingConfig.create().init({ json: "{}" });
                const config = yield Socket.send(req);

                self.transportingConfig = config;
                return true;
            } catch (e) {
                switch (e.code) {
                    case 404:
                        self.errorConnectedDBus = true;
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
            }
        }),
        createPreset: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = CreatePreset.create().init({
                    json: data,
                });
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updatePreset: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = UpdatePreset.create().init({
                    json: data,
                });
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setReplicationLogSettings: flow(function* (data) {
            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);

                const req = SetTransportingConfig.create().init({
                    json: data,
                });

                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        createFolder: flow(function* (data) {
            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);

                const req = CreateFolder.create().init({
                    json: JSON.stringify(data),
                });

                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        deletePreset: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DeletePreset.create().init({
                    json: data,
                });
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchShares: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                const payload = {
                    json: {
                        includeBrick: false,
                        includeInternal: false,
                        includeExternal: false,
                    },
                };
                processingStore.setLoading(true);
                const req = GetShares.create().init(payload);
                self.sharesStore = yield Socket.send(req);
                return true;
            } catch (e) {
                switch (e.code) {
                    case 404:
                        self.errorConnectedDBus = true;
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchFoldersByShare: flow(function* (shareName) {
            const { processingStore } = getParent(self);
            try {
                const payload = {
                    json: {
                        share: shareName,
                        path: "/",
                        skipFiles: true,
                        skipFolders: false,
                    },
                };
                processingStore.setLoading(true);
                const req = GetFolders.create().init(payload);

                const folders = yield Socket.send(req);
                self.foldersStore = folders;

                return true;
            } catch (e) {
                self.foldersStore = undefined;
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        changeSortingAliases(column) {
            if (column === self.orderByAlias) {
                self.orderAlias = self.orderAlias === DESC ? ASC : DESC;
            } else {
                self.orderAlias = ASC;
                self.orderByAlias = column;
            }
        },
        changeSortingPresets(column) {
            if (column === self.orderByPreset) {
                self.orderPreset = self.orderPreset === DESC ? ASC : DESC;
            } else {
                self.orderPreset = ASC;
                self.orderByPreset = column;
            }
        },
        setCurrentAliasName: (aliasName) => {
            self.currentAliasName = aliasName || null;
        },
        setCurrentPresetName: (presetName) => {
            self.currentPresetName = presetName || null;
        },
        setConfigPresetName: (presetName) => {
            self.configPresetName = presetName || null;
        },
        defineAliasStatus: (data) => {
            const foundAlias = self.aliases.find((alias) => alias.id === data.primaryAliasId);
            if (foundAlias) {
                foundAlias.status = data.status;
                foundAlias.extvolume_uuid = data.volumeUuid;
            }
            //after receiving the status need to delete the registration
            data?.registrationId && self.removeRegistration({ registration_id: data.registrationId });
        },
        deleteRegistrations() {
            const items =
                self.registrationsStore.data && self.registrationsStore.data.json && self.registrationsStore.data.json.items;
            items.length && items.forEach((registration) => self.removeRegistration({ registration_id: registration.id }));
        },
        resetErrors() {
            self.errorConnectedDBus = false;
        },
        enableErrorConnectedDBus() {
            self.errorConnectedDBus = true;
        },
    }))
    .actions((self) => ({
        getWatermarks: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);

                const req = GetWatermarks.create().init({
                    json: JSON.stringify({
                        sort: "NAME",
                        sort_dir: "ASC",
                        offset: 0,
                        size: 10,
                    }),
                });
                const watermarks = yield Socket.send(req);
                self.watermarksResult = watermarks;
            } catch (error) {
                processingStore.setError(error);
            }
            processingStore.setLoading(false);
        }),
        uploadWatermarkImage: flow(function* (id, file) {
            const { processingStore, uploadStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                if (!file) return;
                yield uploadStore.upload(file);
                const req = UploadWatermarkImage.create().init({
                    json: JSON.stringify({
                        watermarkId: id,
                        uploadPath: file.name,
                    }),
                });
                const res = yield Socket.send(req);
                const watermark = JSON.parse(res.data.json);

                const idxWatermark = self.watermarks.findIndex(({ id }) => id === watermark.id);
                self.watermarksResult.data.json.list.splice(idxWatermark, 1, watermark);
            } catch (error) {
                processingStore.setError(error);
            }
            processingStore.setLoading(false);
        }),
        getWatermarkImage: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                self.setIsWatermarkImageError(false);
                const req = GetWatermarkImage.create().init({
                    json: data,
                });
                self.watermarkImageStore = yield Socket.send(req);
                if (!self.watermarkImageStore?.data?.json?.exist) {
                    self.watermarkImage = null;
                    return;
                }
                const config = { responseType: "blob", params: { file: self.watermarkImageStore?.data?.json?.downloadPath } };
                const fileResponse = yield axios.get(FILE_DOWNLOAD_URL, config);
                const file = URL.createObjectURL(fileResponse.data);
                self.setWatermarkImage(file);
            } catch (e) {
                self.setIsWatermarkImageError(true);
                self.watermarkImage = null;
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setWatermarkImage: (file) => {
            self.watermarkImage = file;
        },
        setIsWatermarkImageError: (isWatermarkImageError) => {
            self.isWatermarkImageError = isWatermarkImageError;
        },
        resetWatermarkImage: () => {
            self.setWatermarkImage(null);
        },
        createWatermarks: flow(function* (payload, file) {
            const { processingStore, uploadStore } = getParent(self);
            try {
                processingStore.setLoading(true);

                if (!file) return;
                yield uploadStore.upload(file);
                const req = CreateWatermark.create().init({
                    json: JSON.stringify({
                        ...payload,

                        uploadPath: file.name,
                        personal: false,
                        disabled: false,
                    }),
                });
                const res = yield Socket.send(req);
                const watermark = JSON.parse(res.data.json);
                self.watermarksResult.data.json.list.push(watermark);
            } catch (error) {
                processingStore.setError(error);
            }
            processingStore.setLoading(false);
        }),
        deleteWatermark: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);

                const req = DeleteWatermark.create().init({
                    json: JSON.stringify({
                        watermarkId: self.currentWatermark.id,
                    }),
                });
                yield Socket.send(req);
                self.watermarksResult.data.json.list = self.watermarksResult.data.json.list.filter(
                    (watermark) => watermark.id !== self.currentWatermark.id
                );
                self.setCurrentWatermarkName(null);
            } catch (error) {
                processingStore.setError(error);
            }
            processingStore.setLoading(false);
        }),
        updateWatermark: flow(function* (editedWatermark) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);

                const req = UpdateWatermark.create().init({
                    json: JSON.stringify({
                        ...self.currentWatermark,
                        ...editedWatermark,
                    }),
                });
                yield Socket.send(req);

                const reqUpdatedWatermark = GetWatermark.create().init({
                    json: JSON.stringify({
                        watermarkId: self.currentWatermark.id,
                    }),
                });
                const res = yield Socket.send(reqUpdatedWatermark);
                const updatedWatermark = JSON.parse(res.data?.json);
                const idxWatermark = self.watermarks.findIndex((watermark) => watermark.id === updatedWatermark.id);
                self.watermarksResult.data.json.list.splice(idxWatermark, 1, updatedWatermark);

                self.setCurrentWatermarkName(null);
            } catch (error) {
                processingStore.setError(error);
            }
            processingStore.setLoading(false);
        }),
        setCurrentWatermarkName: (watermarkName) => {
            self.currentWatermarkName = watermarkName || null;
        },
        changeSortingWatermarks(column) {
            if (column === self.orderByWatermark) {
                self.orderWatermark = self.orderWatermark === DESC ? ASC : DESC;
            } else {
                self.orderWatermark = ASC;
                self.orderByWatermark = column;
            }
        },
    }));

export default SlingshotSettingsStore;
