import { types } from "mobx-state-tree";

import Request from "api/Request";
import ShareNameArgument from "api/audit/Types/ShareNameArgument";

const ClearLog = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Audit",
    method: "clear_log",
    arguments: types.maybe(ShareNameArgument)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ClearLog;
