import React from "react";

const PauseIcon = props => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h3zm9 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h3zM9 6H6v12h3V6zm9 0h-3v12h3V6z"
                fill="#1E90FF"
                fillRule="nonzero"
            />
        </svg>
    );
};

export default PauseIcon;
