import { types } from "mobx-state-tree";

import Request from "api/Request";
import SetVolumesOnlineArguments from "api/volume/Types/SetVolumesOnlineArguments";

const SetVolumesOnline = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Volume",
    method: "set_volumes_online",
    arguments: types.maybe(SetVolumesOnlineArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetVolumesOnline;
