import { types } from "mobx-state-tree";

const PageQuery = types.model({
    offset: types.number,
    limit: types.number,
    filter: types.map(types.string),
    sort: types.map(types.string)
});

export default PageQuery;
