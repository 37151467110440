import React, { useContext, useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import SideBulkBar from "components/SideBulkBar";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import WorldIcon from "assets/WorldIcon";
import WorldOffIcon from "assets/WorldOffIcon";
import TrashIcon from "assets/TrashIcon";
import WarningDialog from "../WarningDialog";
import { Grid, Typography } from "@material-ui/core";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const REMOTE_SHARES_BULK_BAR_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_remote_shares_bulk_bar`;
const drawerName = "RemoteSharesBulkBar";

const RemoteSharesBulkBar = () => {
    const { t } = useTranslation();
    const {
        store: { externalShareDrawerStore, uiStore },
    } = useContext(Context);
    const state = useLocalStore(() => ({
        isWarningDialogOpen: false,
        get sharesList() {
            return externalShareDrawerStore.allCheckedSharesNames.map((share) => share).join(",\n");
        },
    }));

    useEffect(() => {
        if (externalShareDrawerStore.checkedSharesCount > 0) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, onClose);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
            uiStore.changeDrawer();
        }
    }, [externalShareDrawerStore.checkedSharesCount]);

    const setSharesOnline = () => {
        externalShareDrawerStore.setCheckedSharesOnline();
    };

    const removeShares = async () => {
        await externalShareDrawerStore.removeCheckedShares();

        setTimeout(() => {
            closeWarningDialog();
        }, 1000);

        uiStore.closeDrawer();
    };

    const onClose = () => {
        externalShareDrawerStore.dropAllCheckedShares();
    };

    const openWarningDialog = () => {
        state.isWarningDialogOpen = true;
    };
    const closeWarningDialog = () => {
        state.isWarningDialogOpen = false;
    };

    return useObserver(() => (
        <SideBulkBar
            itemsCount={externalShareDrawerStore.checkedSharesCount}
            onClose={onClose}
            isOpen={uiStore.openedDrawer === drawerName}
        >
            <BulkBarFlatButton
                onClick={setSharesOnline}
                icon={externalShareDrawerStore.needToSetSharesOffline ? <WorldOffIcon /> : <WorldIcon />}
                id={`${REMOTE_SHARES_BULK_BAR_ID_PREFIX}_online_status`}
            >
                {externalShareDrawerStore.needToSetSharesOffline
                    ? t("share.remote_shares.side_menu.actions.button.set_offline")
                    : t("share.remote_shares.side_menu.actions.button.set_online")}
            </BulkBarFlatButton>
            <BulkBarFlatButton
                errorColor
                onClick={openWarningDialog}
                icon={<TrashIcon />}
                id={`${REMOTE_SHARES_BULK_BAR_ID_PREFIX}_remove`}
            >
                {t("share.remote_shares.side_menu.actions.button.remove")}
            </BulkBarFlatButton>
            <WarningDialog
                title={t("share.remote_shares.warning_dialog.title", { count: externalShareDrawerStore.checkedSharesCount })}
                open={state.isWarningDialogOpen}
                onClose={closeWarningDialog}
                itemCount={externalShareDrawerStore.checkedSharesCount}
                submit={removeShares}
                submitBtnLabel={t("share.remote_shares.warning_dialog.ok_button")}
                submitBtnId={`${REMOTE_SHARES_BULK_BAR_ID_PREFIX}_ok_button`}
                question={
                    externalShareDrawerStore.checkedSharesCount > 1 ? (
                        <Grid container spacing={3}>
                            <Grid item container>
                                <Typography>{t("share.remote_shares.checked_shares.warning_dialog.content")}</Typography>
                            </Grid>
                            <Grid item container>
                                <Typography>{state.sharesList}.</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <Typography>
                                {t("share.remote_shares.warning_dialog.content", {
                                    shareName: externalShareDrawerStore.allCheckedSharesNames,
                                })}
                            </Typography>
                        </>
                    )
                }
            />
        </SideBulkBar>
    ));
};

export default RemoteSharesBulkBar;
