import React, { useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import config from "config";
import {
    Button,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControlLabel,
    Grid,
    Typography,
    Box
} from "@material-ui/core";
import { useStyles } from "./AddDiskDialog.styles";
import TextInput from "components/MobxForm/TextInput";
import ChevronUpIcon from "assets/ChevronUpIcon";
import { useTranslation } from "react-i18next";
import Select from "components/MobxForm/Select";
import createFields from "./createFields";
import createForm from "utils/createForm";
import clsx from "clsx";
import diskRule from "utils/diskRule";
import { GB_METRIC, GiB_IEC, KiB_IEC, PB_METRIC, PiB_IEC, TABLE_UNIT_VALUE, TB_METRIC, TiB_IEC } from "const/diskSizeConst";
import WarningDialog from "../WarningDialog";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import Checkbox from "components/Checkbox";
import Switch from "components/MobxForm/Switch";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch/RowControlLabelSwitch";
import { EMPTY_BCACHE_DRIVE_NAME, EMPTY_DRIVE_ID, getBcacheDriveName, parseDriveId } from "const/bcacheConst";
import useStoreByIp from "hooks/useStoreByIp";
import { useModalCombine } from "hooks/useModalCombine";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { getMaxVolumeSizeString } from "./utils/getMaxVolumeSizeString";

const ADD_DISK_DIALOG_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_add_disk_dialog`;

const AddDiskDialog = ({ open, handleClose, ip }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const fields = createFields(config.decimalSizeUnits);
    const { volumeStore, poolsStore, bcacheStore } = useStoreByIp({ ip });
    const state = useLocalStore(() => ({
        advancedExpanded: false,
        form: createForm({
            fields,
            rules: diskRule
        }),
        maxSizeResponse: {},
        isSubmitted: false,
        addMultiple: false,
        get maxVolumeSize() {
            return this.maxSizeResponse?.available?.[`raid${state.form.$("raid").value}Size`];
        },
        get reservedVolumeSize() {
            return this.maxSizeResponse?.reserved?.[`raid${state.form.$("raid").value}Size`];
        },
        get maxVolumeSizeString() {
            return getMaxVolumeSizeString(this.maxVolumeSize, this.form.$("sizeUnitValue").value);
        },
        isAddDiskInProgress: false
    }));

    const { unsavedChangesModal, warningModal } = useModalCombine(["unsavedChangesModal", "warningModal"]);

    useEffect(() => {
        state.form.$("sizeUnitValue").set("default", config.decimalSizeUnits ? GB_METRIC.unit : GiB_IEC.unit);
        state.form.$("stripeSize").set("default", KiB_IEC.value);
        switch (poolsStore.pools?.length) {
            case 1:
                state.form.$("poolName").set("default", poolsStore.pools[0].pool);
                break;
            default:
                state.form.$("poolName").set("default", "");
                break;
        }
        state.form.$("bcacheEnabled").set("default", false);
        state.form.$("bcacheDrive").set("default", EMPTY_BCACHE_DRIVE_NAME);
        state.form.reset();
    }, [poolsStore.pools?.length]);
    useEffect(() => {
        state.form.$("raid").reset();
        state.maxSizeResponse = {};
        setupMaxSize();
    }, [state.form.$("poolName").value]);

    const onDialogOpen = () => {
        state.form.reset();
        state.form.each(field => field.resetValidation());
        state.maxSizeResponse = {};
        state.isSubmitted = false;
        state.advancedExpanded = false;
        warningModal.close();
        state.addMultiple = false;
        setupMaxSize();
    };

    const setupMaxSize = async () => {
        if (state.form.$("poolName").value !== "") {
            const res = await poolsStore.getMaxVolumeSize({ pool: state.form.$("poolName").value });

            if (res) {
                state.form.$("__maxSizeResponse").set("value", res.data);
                state.maxSizeResponse = res.data;
            }
        }
    };

    const getSizeUnitsOptions = () => {
        return config.decimalSizeUnits
            ? [GB_METRIC.unit, TB_METRIC.unit, PB_METRIC.unit]
            : [GiB_IEC.unit, TiB_IEC.unit, PiB_IEC.unit];
    };

    const getRaidOptions = () => {
        let options = [];
        const currentPool = poolsStore.pools?.find(pool => pool.pool === state.form.$("poolName").get("value"));

        if (currentPool) {
            options = currentPool.raids;

            const getDefault = () => {
                const count = currentPool.drives.length;
                if (count <= 2) return 0;
                if (count > 2 && count <= 8) return 5;
                if (count > 8) return 6;
            };
            state.form.$("raid").set("default", getDefault());
        }

        return options;
    };

    const getStripeSizeOptions = () => {
        return [8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096];
    };

    const submitIfNeeded = async e => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        if (
            state.maxVolumeSize - state.form.$("sizeInput").value * TABLE_UNIT_VALUE[state.form.$("sizeUnitValue").value] <
            state.reservedVolumeSize
        ) {
            warningModal.open();
        } else {
            await submit({ force: false, withSpaceReserve: false });
        }
    };

    const submit = async ({ force, withSpaceReserve }) => {
        const getSize = () => {
            return withSpaceReserve && state.reservedVolumeSize
                ? state.maxVolumeSize - state.reservedVolumeSize
                : state.form.$("sizeInput").value * TABLE_UNIT_VALUE[state.form.$("sizeUnitValue").value];
        };

        warningModal.close();
        const ISCSI = 512;

        let driveId = EMPTY_DRIVE_ID;
        let cacheSupported = false;
        if (bcacheStore.enabled) {
            driveId = parseDriveId(state.form.$("bcacheDrive").value);
            cacheSupported = state.form.$("bcacheEnabled").value;
        }

        state.isAddDiskInProgress = true;
        state.isSubmitted = !!(await volumeStore.enqueueVolumes({
            tasks: [
                {
                    pool: state.form.$("poolName").value,
                    volume: state.form.$("diskName").value,
                    raid: state.form.$("raid").value,
                    size: getSize(),
                    chunkSize: state.form.$("stripeSize").value * 1024,
                    iSCSIBlockSize: ISCSI,
                    ioProfile: state.form.$("profile").isDefault ? "" : state.form.$("profile").value,
                    cache: {
                        quad: driveId.quad,
                        drive: driveId.drive
                    },
                    cacheSupport: cacheSupported
                }
            ],
            force
        }));
        state.isAddDiskInProgress = false;

        if (state.isSubmitted) {
            setupMaxSize();
            if (state.addMultiple) {
                state.form.$("diskName").reset();
                state.form.$("sizeInput").reset();
                state.form.$("diskName").resetValidation();
                state.form.$("sizeInput").resetValidation();

                setTimeout(() => (state.isSubmitted = false), 1000);
            } else {
                setTimeout(handleClose, 1000);
            }
        }
    };

    const handleMultipleCheck = e => {
        state.addMultiple = e.target.checked;
    };

    const closeIfNeed = () => {
        if (state.form.isDefault) {
            handleClose();
            return;
        }
        unsavedChangesModal.open();
    };
    return useObserver(() => (
        <Dialog
            onEnter={onDialogOpen}
            title={t("disks_and_pools.add_disk_dialog.title")}
            open={open}
            onClose={closeIfNeed}
            isDataChanged={!state.form.isDefault}
            submitBtn={
                <AnimatedSubmitButton
                    label={t("disks_and_pools.add_disk_dialog.button.add")}
                    disabled={state.isSubmitDisabled}
                    isSubmitted={state.isSubmitted}
                    submit={submitIfNeeded}
                    inProgress={state.isAddDiskInProgress}
                    id={`${ADD_DISK_DIALOG_ID_PREFIX}_submit`}
                />
            }
            buttons={[
                <FormControlLabel
                    key={"multipleCheckboxContainer"}
                    className={classes.multipleCheckboxContainer}
                    control={
                        <Checkbox
                            value={state.addMultiple}
                            onChange={handleMultipleCheck}
                            id={`${ADD_DISK_DIALOG_ID_PREFIX}_multiple_check`}
                        />
                    }
                    label={t("disks_and_pools.add_disk_dialog.add_multiple")}
                />,
                <Button
                    id={`${ADD_DISK_DIALOG_ID_PREFIX}_cancel`}
                    key="cancelBtn"
                    onClick={handleClose}
                    variant={"contained"}
                    color="secondary"
                >
                    {t("common.button.cancel")}
                </Button>
            ]}
        >
            <Grid direction={"column"} container>
                <RowTextInput
                    label={t("disks_and_pools.add_disk_dialog.label.pool_name")}
                    control={
                        <Select
                            field={state.form.$("poolName")}
                            options={poolsStore.sortedPoolsNames}
                            id={`${ADD_DISK_DIALOG_ID_PREFIX}_pool_name`}
                        />
                    }
                />
                <RowTextInput
                    label={t("disks_and_pools.add_disk_dialog.label.disk_name")}
                    control={<TextInput field={state.form.$("diskName")} id={`${ADD_DISK_DIALOG_ID_PREFIX}_disk_name`} />}
                />
                <RowTextInput
                    label={t("disks_and_pools.add_disk_dialog.label.disk_size")}
                    control={
                        <>
                            <Grid container>
                                <Grid className={classes.firstItem} item xs={6}>
                                    <TextInput
                                        field={state.form.$("sizeInput")}
                                        disabled={!state.maxVolumeSize}
                                        id={`${ADD_DISK_DIALOG_ID_PREFIX}_disk_size`}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        field={state.form.$("sizeUnitValue")}
                                        options={getSizeUnitsOptions()}
                                        id={`${ADD_DISK_DIALOG_ID_PREFIX}_disk_size_unit`}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                />
                <Grid container alignContent={"center"} xs={6} item>
                    <Typography variant={"body2"} className={classes.caption}>
                        {t("disks_and_pools.add_disk_dialog.label.max_disk_size", {
                            size: state.maxVolumeSizeString
                        })}
                    </Typography>
                </Grid>
                <RowTextInput
                    label={t("disks_and_pools.add_disk_dialog.label.disk_level")}
                    control={
                        <Select
                            field={state.form.$("raid")}
                            options={getRaidOptions()}
                            id={`${ADD_DISK_DIALOG_ID_PREFIX}_disk_level`}
                        />
                    }
                />
            </Grid>
            <Box pt={6}>
                <Accordion
                    className={classes.expansionPanel}
                    square
                    expanded={state.advancedExpanded}
                    onChange={(event, newExpanded) => {
                        state.advancedExpanded = newExpanded;
                    }}
                >
                    <AccordionSummary
                        className={classes.expansionPanelContent}
                        classes={{ content: classes.expansionPanelContent, expanded: classes.expansionPanelContent }}
                    >
                        <Typography className={classes.expansionLabel}>
                            {t("disks_and_pools.add_disk_dialog.label.advanced")}
                        </Typography>
                        <ChevronUpIcon
                            className={clsx(!state.advancedExpanded && classes.expansionIconClose, classes.expansionIcon)}
                        />
                    </AccordionSummary>
                    <AccordionDetails className={classes.detailsRoot}>
                        <Grid direction={"column"} container>
                            <RowTextInput
                                label={t("disks_and_pools.add_disk_dialog.label.disk_stripe_size")}
                                control={
                                    <Select
                                        field={state.form.$("stripeSize")}
                                        options={getStripeSizeOptions()}
                                        id={`${ADD_DISK_DIALOG_ID_PREFIX}_disk_stripe_size`}
                                    />
                                }
                            />
                            <RowTextInput
                                label={t("disks_and_pools.add_disk_dialog.label.profile")}
                                control={
                                    <Select
                                        field={state.form.$("profile")}
                                        options={[
                                            {
                                                value: 0,
                                                label: t("disks_and_pools.add_disk_dialog.use_default")
                                            }
                                        ]}
                                        id={`${ADD_DISK_DIALOG_ID_PREFIX}_profile`}
                                    />
                                }
                            />
                            {bcacheStore.enabled && (
                                <>
                                    <RowControlLabelSwitch
                                        control={
                                            <Switch
                                                field={state.form.$("bcacheEnabled")}
                                                id={`${ADD_DISK_DIALOG_ID_PREFIX}_bcache_support`}
                                            />
                                        }
                                        label={t("disks_and_pools.add_disk_dialog.bcache_support")}
                                    />
                                    <RowTextInput
                                        label={t("disks_and_pools.add_disk_dialog.cache_device")}
                                        control={
                                            <Select
                                                options={[EMPTY_BCACHE_DRIVE_NAME].concat(
                                                    Object.values(bcacheStore.drives).map(drive =>
                                                        getBcacheDriveName(drive.quad, drive.drive)
                                                    )
                                                )}
                                                field={state.form.$("bcacheDrive")}
                                                disabled={!state.form.$("bcacheEnabled").value}
                                                id={`${ADD_DISK_DIALOG_ID_PREFIX}_cache_device`}
                                            />
                                        }
                                    />
                                </>
                            )}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <WarningDialog
                open={warningModal.isOpen}
                onClose={warningModal.close}
                availableSize={state.maxVolumeSize}
                reservedSize={state.reservedVolumeSize}
                requestedSize={state.form.$("sizeInput").value}
                requestedUnit={TABLE_UNIT_VALUE[state.form.$("sizeUnitValue").value]}
                submit={submit}
                zeroReservedSize={state.maxSizeResponse.reserved?.raid0Size}
            />
            <UnsavedChangesDialog onConfirm={handleClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default AddDiskDialog;
