import { types } from "mobx-state-tree";

const JOB_COMMANDS_ARRAY = ["run", "pause", "resume", "cancel", "cancel_pause", "cancel_disable", "enable", "clear"];

const PerformJobCommandArguments = types.model({
    json: types.maybe(
        types.model({
            cmd: types.enumeration(JOB_COMMANDS_ARRAY),
            jobId: types.number
        })
    )
});

export default PerformJobCommandArguments;
