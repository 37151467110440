import { types } from "mobx-state-tree";

import UiSettings from "api/ui_settings/Types/UiSettings";

const GetUiSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(UiSettings)
});

export default GetUiSettingsResult;
