import React from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { observer } from "mobx-react";
import PresetsIcon from "assets/PresetsIcon";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import { useStore } from "hooks/useStore";
import RenderDetails from "../RenderDetails";
import RenderActions from "../RenderActions/RenderActions";
import EditWatermarkDialog from "../EditWatermarkDialog/EditWatermarkDialog";
import UploadWatermarkImageDialog from "../UploadWatermarkImageDialog/UploadWatermarkImageDialog";

const WATERMARK_DRAWER_ID_PREFIX = `watermark_drawer`;
const drawerName = "watermarkDrawer";

const WatermarkDrawer = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${WATERMARK_DRAWER_ID_PREFIX}` : WATERMARK_DRAWER_ID_PREFIX;
    const {
        store: { slingshotSettingsStore },
    } = useStore();

    const closeDrawer = () => {
        slingshotSettingsStore.setCurrentWatermarkName(null);
    };

    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: slingshotSettingsStore.currentWatermarkName });

    return (
        <DetailsActionsDrawer
            title={slingshotSettingsStore.currentWatermarkName}
            renderActions={<RenderActions parentId={idPrefix} />}
            renderDetails={<RenderDetails />}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            icon={<PresetsIcon />}
            modals={[
                <EditWatermarkDialog key={1} parentId={idPrefix} />,
                <UploadWatermarkImageDialog key={2} parentId={idPrefix} />,
            ]}
            id={`${idPrefix}_drawer`}
        />
    );
};

export default observer(WatermarkDrawer);
