import { useEffect } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { useStore } from "./useStore";

/**
 * Hook to handle the status of the any pinnable menu.
 * @param {{localStorageName: string, open: () => void, close: () => void, display?: undefined, pin: () => void, unpin: () => void, isOpen: boolean, isPinned: boolean, isHide?: boolean}}
//  * @returns {{open: () => void, close: () => void, display: () => void, pin: () => void, unpin: () => void, isOpen: boolean, isPinned: boolean, isHide: boolean}}
 * @return open - method to open a menu
 * @return close - method to close a menu
 * @return display? - method to show a menu after hidden
 * @return pin - method to pin a menu
 * @return unpin - method to unpin a menu
 * @return isOpen - menu open status
 * @return isPinned - menu pin status
 * @return isHide? - menu hide status
 */
export const usePinnableMenu = ({ localStorageName, open, close, display, pin, unpin, isOpen, isPinned, isHide }) => {
    const {
        store: { authStore }
    } = useStore();

    const [isPin, toggleIsPin] = useLocalStorage(localStorageName, isPinned);

    useEffect(() => {
        if (isPin) {
            open();
            pin();
        } else {
            !isOpen && close();
            unpin();
        }
    }, []);

    useEffect(() => {
        const isPinned = localStorage.getItem(localStorageName);

        if (isPinned === "true") {
            open();
            pin();
        } else {
            !isOpen && close();
            unpin();
        }
    }, [authStore.isAuthorized]);

    const handlePin = () => {
        toggleIsPin(true);
        pin();
    };
    const handleUnpin = () => {
        unpin();
        toggleIsPin(false);
    };

    return {
        open,
        close,
        display,
        pin: handlePin,
        unpin: handleUnpin,
        isOpen,
        isPinned,
        isHide
    };
};
