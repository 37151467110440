import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    helperText: {
        width: "100%",
        opacity: 0.5,
        fontSize: 14,
        fontWeight: "normal",
        color: theme.palette.text.primary,
        lineHeight: 1.29,
        letterSpacing: "normal",
        marginLeft: 31,
        marginTop: -5
    },
    disabledHelperText: {
        opacity: 0.2
    }
}));
