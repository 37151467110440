import { types } from "mobx-state-tree";

import BatchEventsPage from "api/event/Types/BatchEventsPage";

const GetBatchPageResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(BatchEventsPage)
});

export default GetBatchPageResult;
