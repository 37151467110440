import isFieldEmpty from "utils/isFieldEmptyRule";
import i18n from "i18n";

const createFields = () => {
    return [
        {
            name: "commonName",
            placeholder: "Your company CA",
            validators: [
                isFieldEmpty(i18n.t("admin_settings.server_certificate.create_self_signed_certificate.input.label.common_name"))
            ]
        },
        {
            name: "email",
            placeholder: "example@mail.com"
        },
        {
            name: "country"
        },
        {
            name: "state"
        },
        {
            name: "city"
        },
        {
            name: "organization",
            placeholder: "Your company"
        },
        {
            name: "department",
            placeholder: "Marketing"
        }
    ];
};

export default createFields;
