const resetFormToDefault = (fields, form) => {
    fields.forEach(field => {
        if (field.default !== undefined) {
            form.$(field.name).set("default", field.default);
        }
    });
    form.reset();
};

export default resetFormToDefault;
