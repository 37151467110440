import React from "react";

const LogoutIcon = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                d="M17.58 9.1773C17.494 9.09137 17.4257 8.98914 17.379 8.87648C17.3323 8.76381 17.3081 8.64293 17.3077 8.52078C17.3074 8.39863 17.331 8.27761 17.3771 8.16469C17.4232 8.05176 17.4909 7.94915 17.5764 7.86275C17.9355 7.49911 18.5196 7.49729 18.8814 7.85911L20.7273 9.7073C20.8133 9.79316 20.8817 9.89535 20.9285 10.008C20.9753 10.1206 20.9996 10.2415 21 10.3636C21.0004 10.4858 20.9769 10.6068 20.9309 10.7198C20.8849 10.8327 20.8172 10.9354 20.7318 11.0218L18.885 12.8909C18.5259 13.2546 17.9418 13.2573 17.58 12.8946C17.494 12.8087 17.4257 12.7064 17.379 12.5938C17.3323 12.4811 17.3081 12.3602 17.3077 12.2381C17.3074 12.1159 17.331 11.9949 17.3771 11.882C17.4232 11.769 17.4909 11.6664 17.5764 11.58L17.8554 11.2946H13.152C12.9129 11.287 12.6861 11.1858 12.5196 11.0123C12.3532 10.8388 12.2601 10.6066 12.2601 10.365C12.2601 10.1234 12.3532 9.89128 12.5196 9.71778C12.6861 9.54428 12.9129 9.44303 13.152 9.43548H17.8401L17.5809 9.17639L17.58 9.1773ZM10.3845 18.7319V20.791C10.3845 21.7728 9.42059 22.3192 8.5863 21.8001L3.828 18.8364C3.3411 18.5337 3 17.9155 3 17.3419V3.39092C3 3.09364 3.0882 2.83637 3.234 2.63637C3.24723 2.60203 3.26543 2.56986 3.288 2.54092C3.41795 2.37202 3.58448 2.23544 3.77482 2.14168C3.96516 2.04791 4.17423 1.99945 4.386 2H14.538C15.3012 2 15.9231 2.62455 15.9231 3.39092V5.7182C15.9231 6.23184 15.5091 6.6482 14.9997 6.6482C14.8787 6.64832 14.7588 6.62436 14.6469 6.57769C14.5351 6.53101 14.4334 6.46254 14.3478 6.37619C14.2621 6.28983 14.1941 6.18727 14.1477 6.07437C14.1013 5.96147 14.0773 5.84045 14.0772 5.7182V3.8591L7.1652 3.85546L9.55649 5.34547C10.0425 5.6482 10.3845 6.26638 10.3845 6.84002V16.8737H14.0763V15.0146C14.0763 14.5009 14.4894 14.0846 14.9988 14.0846C15.5091 14.0846 15.9222 14.5009 15.9222 15.0146V17.3419C15.9222 18.1119 15.3012 18.7328 14.5371 18.7328H10.3845V18.7319ZM8.544 6.92638L4.8459 4.61274V17.33L8.5431 19.5973V6.92547L8.544 6.92638Z"
            />
        </svg>
    );
};

export default LogoutIcon;
