import { types } from "mobx-state-tree";

import UpsArrayResultData from "api/power/Types/UpsArrayResultData";

const UpsArrayResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(UpsArrayResultData)
});

export default UpsArrayResult;
