import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

const BREADCRUMBS_FONT = {
    fontSize: 16,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "0.2px",
};

export const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        paddingBottom: theme.spacing(3),
    },
    parentName: {
        "&& ": {
            ...BREADCRUMBS_FONT,
            color: theme.palette.primary.main,
            opacity: 0.4,
        },
    },
    folderBrowserLabel: {
        "&& ": {
            ...BREADCRUMBS_FONT,
            color: theme.palette.primary.main,
            opacity: 1,
        },
    },
    parentBreadcrumbs: {
        paddingTop: theme.spacing(4),
    },
    switchLabel: {
        marginLeft: 0,
        "& > span:last-child": {
            marginLeft: theme.spacing(4),
        },
    },
    expansionPanel: {
        margin: theme.spacing(0, -2),
        "&&": {
            boxShadow: "none",
            border: `${theme.palette.expandPanel.border.color} 1px solid`,
            borderRadius: theme.spacing(2),
        },
        "&&.MuiAccordion-root.Mui-expanded": {
            margin: theme.spacing(0, -2),
            paddingBottom: theme.spacing(5),
        },
    },
    expansionIconClose: {
        transform: "rotate(180deg)",
    },
    expansionIcon: {
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.1),
        borderRadius: "50%",
    },
    expansionPanelContent: {
        "&&": {
            display: "flex",
            justifyContent: "space-between",
        },
    },
    speedLimit: {
        maxWidth: theme.spacing(25),
    },
}));
