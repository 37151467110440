/**
 config: {
    requiredFieldsForValidating: string[],
    incompatibleFieldsForValidation: string[]
    getRequiredCondition: (form) => boolean
 }
 */
const mustHaveDifferentFieldValues = (
    target,
    msg,
    {
        requiredFieldsForValidating = [],
        incompatibleFieldsForValidation = [],
        getRequiredCondition = () => true,
        customValidation
    } = {}
) => {
    return ({ field, form }) => {
        const hasRequiredFieldsForValidating = requiredFieldsForValidating.length
            ? requiredFieldsForValidating.every(field => form.$(field).value)
            : true;

        const hasIncompatibleFieldsForValidation = incompatibleFieldsForValidation.length
            ? incompatibleFieldsForValidation.some(field => form.$(field).value)
            : false;

        const isFieldsNotEqual =
            !hasIncompatibleFieldsForValidation && hasRequiredFieldsForValidating && field.value && getRequiredCondition(form)
                ? customValidation
                    ? customValidation(form)
                    : form.$(target).value !== field.value
                : true;

        return [isFieldsNotEqual, msg];
    };
};

export default mustHaveDifferentFieldValues;
