import { types } from "mobx-state-tree";

import FilesEvent from "api/audit/Types/FilesEvent";

const GetFilesLogResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(FilesEvent)
});

export default GetFilesLogResult;
