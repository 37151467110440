import { makeStyles } from "@material-ui/core/styles";
import { STATE_BAR_HEIGHT } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    container: {
        height: STATE_BAR_HEIGHT
    },
    stateBarItem: {
        height: "100%",
        flex: 1
    },
    stepper: {
        backgroundColor: "transparent"
    },
    chevron: {
        fill: theme.palette.primary.main
    },
    chevronLeft: {
        transform: "rotate(180deg)"
    },
    buttonCustomDisabled: {
        "&:disabled": {
            visibility: "hidden"
        }
    }
}));
