import { flow, getParent, types } from "mobx-state-tree";
import Socket from "websocket";
import GetEmailSettings from "api/events/Requests/GetEmailSettings";
import EmailSettingsResult from "api/events/Responses/EmailSettingsResult";
import SendTestEmail from "api/events/Requests/SendTestEmail";
import SetEmailSettings from "api/events/Requests/SetEmailSettings";

const EmailConfigurationStore = types
    .model({
        emailSettingsResult: types.maybe(EmailSettingsResult)
    })
    .views(self => ({
        get emailSettings() {
            return self.emailSettingsResult?.data || {};
        }
    }))
    .actions(self => ({
        getEmailSettings: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetEmailSettings.create().init();
                const res = yield Socket.send(req);
                self.emailSettingsResult = res;
                return res;
            } catch (e) {
                switch (e.code) {
                    case 404:
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setEmailSettings: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetEmailSettings.create().init(data);
                const res = yield Socket.send(req);
                self.emailSettingsResult.data = data;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        sendTestEmail: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SendTestEmail.create().init(data);
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        })
    }));

export default EmailConfigurationStore;
