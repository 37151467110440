import React from "react";
import HeaderToolbar from "./components/HeaderToolbar";
import WidgetsBar from "./components/WidgetsBar";
import { useStyles } from "./Header.style";
import clsx from "clsx";
import { Box, Grid } from "@material-ui/core";

const Header = props => {
    const classes = useStyles();
    const { isOpen, title } = props;
    return (
        <Box className={classes.mainContainer}>
            <Grid className={classes.muiContainer}>
                <Grid
                    container
                    direction={"column"}
                    justify={"flex-start"}
                    className={clsx(classes.contentContainer, isOpen && classes.openContentContainer)}
                >
                    <Grid item>
                        <HeaderToolbar title={title} />
                    </Grid>
                    <Grid item className={clsx(classes.widgetsBar, isOpen && classes.openedWidgetsBar)}>
                        {isOpen && <WidgetsBar />}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Header;
