import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => ({
    mainPicture: {
        height: 88,
        width: 88,
    },
    titleContainer: {
        marginLeft: theme.spacing(4),
        flex: 1,
    },
    progressBar: {
        width: "100%",
    },
    successText: {
        color: theme.palette.success.main,
    },
    sizeItem: {
        marginRight: theme.spacing(2),
        "& span": {
            fontSize: 14,
        },
    },
    icon: {
        "& svg": {
            borderRadius: theme.spacing(1),
            "& path": {
                fill: theme.palette.grey["300"],
            },
            "&:hover": {
                border: `1px solid ${hexToRgba(theme.palette.primary.main, 0.15)}`,
                cursor: "pointer",
                "& path": {
                    fill: theme.palette.primary.main,
                },
            },
            "&:active": {
                backgroundColor: hexToRgba(theme.palette.primary.main, 0.15),
            },
        },
    },
}));
