import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./DownloadCsrLayout.styles";
import { useTranslation } from "react-i18next";

const DownloadCsrLayout = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Grid className={classes.container}>
            <Typography>{t("admin_settings.server_certificate.create_certificate_signing_request.download_csr")}</Typography>
        </Grid>
    );
};

export default DownloadCsrLayout;
