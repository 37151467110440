import { types } from "mobx-state-tree";
import Request from "api/Request";
import CreatePresetType from "../Types/CreatePresetType";

const CreatePreset = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Transcoding1",
    method: "create_preset",
    arguments: types.maybe(
        types.snapshotProcessor(CreatePresetType, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json)
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default CreatePreset;
