import { DISABLED, ENABLED, MANUAL } from "api/slingshot/indexing_config/Types/ConfigType";

export const SHARE_BROWSER_OPTIONS = [
    { value: false, label: "Disabled" },
    { value: true, label: "Enabled" },
];
export const EDIT_PROXY_OPTIONS = [
    { value: DISABLED, label: "Disabled" },
    { value: ENABLED, label: "Enabled" },
    { value: MANUAL, label: "Manual" },
];
export const FIELDS = [{ name: "shareBrowser" }, { name: "editProxy" }];
