import i18n from "i18n";
import { REGEX_DOMAIN, REGEX_IP } from "const";

const isIpHostRule = {
    isIpHost: {
        function: (value) => {
            return value.match(REGEX_DOMAIN) || value.match(REGEX_IP);
        },
        message: i18n.t("rule.host_or_ip.format"),
    },
};

export default isIpHostRule;
