import { types } from "mobx-state-tree";
const SORT_ENUM_ARRAY = ["Status", "Job", "Source", "Destination", "Method", "Schedule", "LastJobSummary"];
const SORT_DIR_ENUM_ARRAY = ["ASC", "DESC"];

const GetJobsArgumentsTypes = types.model({
    sort: types.maybe(types.enumeration(SORT_ENUM_ARRAY)),
    sort_dir: types.maybe(types.enumeration(SORT_DIR_ENUM_ARRAY)),
    offset: types.maybe(types.number),
    limit: types.maybe(types.number)
});

export default GetJobsArgumentsTypes;
