import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetJobsArguments from "../Types/GetJobsArguments";

const GetJobs = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/SyncJobs1",
    method: "get_jobs",
    arguments: types.maybe(
        types.snapshotProcessor(GetJobsArguments, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json)
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetJobs;
