import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    ButtonBase,
    DialogTitle,
    Typography,
    Grid,
    FormControlLabel,
    Box,
} from "@material-ui/core";
import Dialog from "components/Dialog";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import ThinArrowRightIcon from "assets/ThinArrowRightIcon";
import CloseIcon from "assets/CloseIcon";
import ChevronUpIcon from "assets/ChevronUpIcon";
import EditIcon from "assets/EditIcon";
import { useStyles } from "./CreateEditTaskModal.styles";
import Select from "components/MobxForm/Select";
import createFields from "./createFields";
import createForm from "utils/createForm";
import {
    TASKS_OPTIONS,
    TASKS_OPTIONS_SINGLE,
    TASKS_OPTIONS_GROUP,
    TASKS_SOURCE,
    LOG_LEVEL,
    SCHEMA_OPTIONS,
    SCHEMA_OPTIONS_ARRAY,
    TRANSCODE_REACTION,
    TASK_TYPE,
    OPERATION_VALUE,
} from "const/automationTaskConst";
import { SCHEMA_OPTIONS as ALIAS_SCHEMA_OPTIONS } from "const/slingshotAliasConst";
import { S3_CUSTOM_REGION, S3_SCHEMA, S3_CUSTOM_SCHEMA } from "const/slingshotAliasConst";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import TextInput from "components/MobxForm/TextInput";
import Switch from "components/MobxForm/Switch";
import clsx from "clsx";
import FolderBrowser from "../FolderBrowser/FolderBrowser";
import sortByLocaleCompare from "utils/sortByLocaleCompare";
import CreateEditPresetDialog from "../CreateEditPresetDialog";
import CreateEditAliasDialog from "../CreateEditAliasDialog";
import { useModalCombine } from "hooks/useModalCombine";
import { useStore } from "hooks/useStore";

const CREATE_EDIT_TASK_MODAL_ID_PREFIX = `create_edit_task_modal`;

const CreateEditTaskModal = ({ open, onClose, isCreate, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${CREATE_EDIT_TASK_MODAL_ID_PREFIX}` : CREATE_EDIT_TASK_MODAL_ID_PREFIX;
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { slingshotSettingsStore, slingshotStorageStore, automationTasksStore, automationsStore },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        accordionExpanded: false,
        schemaOptions: [],
        get presets() {
            return slingshotSettingsStore.presets?.map((preset) => ({ value: preset.id, label: preset.name })) || [];
        },
        get tricasterAliases() {
            return (
                slingshotSettingsStore.aliases?.filter(
                    (alias) => alias.decomposed_base_url.schema === ALIAS_SCHEMA_OPTIONS.tricaster
                ) || []
            );
        },
        get tricasterAliasesOptions() {
            return state.tricasterAliases?.map((alias) => ({ value: alias.id, label: alias.name })) || [];
        },
        get notTricasterAliases() {
            return (
                slingshotSettingsStore.aliases?.filter(
                    (alias) => alias.decomposed_base_url.schema !== ALIAS_SCHEMA_OPTIONS.tricaster
                ) || []
            );
        },
        get notTricasterAliasesOptions() {
            return state.notTricasterAliases?.map((alias) => ({ value: alias.id, label: alias.name })) || [];
        },
        get shares() {
            return (
                (slingshotStorageStore.shares?.length &&
                    sortByLocaleCompare(slingshotStorageStore.shares.map((share) => share.name))) ||
                []
            );
        },
    }));

    const { unsavedChangesModal, folderBrowserModal, editPresetModal, editAliasModal } = useModalCombine([
        "unsavedChangesModal",
        "folderBrowserModal",
        "editPresetModal",
        "editAliasModal",
    ]);

    const closeModalAndResetForm = () => {
        onClose();
        state.form.clear();
    };

    const onShareSelectChange = (e) => {
        state.form.$("resource").set("value", e.target.value);
        state.form.$("path").clear();
    };

    const openEditPresetDialog = () => {
        const currentPresetName = state.presets.find((el) => el.value === Number(state.form.$("preset").value))?.label;
        slingshotSettingsStore.setCurrentPresetName(currentPresetName || state.presets[0].label);
        editPresetModal.open();
    };

    const openEditAliasDialog = async () => {
        const currentAliasName = getAliasName(state.form.$("alias").value);
        await slingshotSettingsStore.setCurrentAliasName(currentAliasName);
        editAliasModal.open();
    };
    const onCloseDrawer = () => { };
    const changeProgressState = () => { };

    const setPath = (path) => {
        state.form.$("path").set("value", path);
    };

    const onEnter = async () => {
        unsavedChangesModal.close();

        await slingshotSettingsStore.fetchAliases();
        await slingshotSettingsStore.fetchPresets();
        state.accordionExpanded = false;
        state.isSubmitted = false;
        
        if (isCreate) {
            state.form.clear();

            state.form.$("task").set("default", TASKS_OPTIONS.move);
            state.form.$("source").set("default", TASKS_SOURCE[0].value);
            state.form.$("logLevel").set("default", LOG_LEVEL[0].value);
            state.form.$("schema").set("default", SCHEMA_OPTIONS.evo);
            state.form.$("unknownStreamMode").set("default", TRANSCODE_REACTION[0].value);
            state.form.$("createFolder").set("default", true);
            state.form.$("resource").set("default", "");
            state.form.$("path").set("default", "");
        } else {
            const currentTask = automationTasksStore.currentTask;

            const destinationSections = decodeURIComponent(currentTask?.arguments?.destination)?.split?.("/");

            const resource = destinationSections?.[0];
            const path = destinationSections?.splice?.(1)?.join?.("/");

            if (currentTask) {
                state.form.$("task").set("default", currentTask.action?.action || currentTask.group_action);
                state.form.$("task").set("value", currentTask.action?.action || currentTask.group_action);
                const aliasId =
                    ((currentTask.arguments?.request?.decomposed_to?.schema === SCHEMA_OPTIONS.evo ||
                        currentTask.arguments?.request?.decomposed_to?.schema === SCHEMA_OPTIONS.alias) &&
                        getAliasId(currentTask.arguments?.request?.decomposed_to?.host)) ||
                    "";
                state.form.$("source").set("default", currentTask.datasets[0]?.dataset_type);
                state.form.$("logLevel").set("default", currentTask.arguments?.log_level || LOG_LEVEL[0].value);
                state.form.$("user").set("default", currentTask.arguments?.destination?.user);
                state.form
                    .$("move")
                    .set("default", (currentTask.arguments?.request?.operation === OPERATION_VALUE.move && true) || false);
                state.form.$("replace").set("default", currentTask.arguments?.request?.replace || false);
                state.form.$("schema").set("default", currentTask.arguments?.request?.decomposed_to?.schema || SCHEMA_OPTIONS.evo);
                state.form.$("createFolder").set("default", currentTask.arguments?.request?.create_folder);
                state.form.$("transferResume").set("default", currentTask.arguments?.request?.resume);
                state.form.$("speedLimitAllow").set("default", !!currentTask.arguments?.request?.speed_limit);
                state.form.$("speedLimit").set("default", currentTask.arguments?.request?.speed_limit);
                state.form.$("resource").set("default", currentTask.arguments?.request?.decomposed_to?.resource ?? resource);
                state.form.$("path").set("default", currentTask.arguments?.request?.decomposed_to?.path ?? path);
                state.form.$("regionChoose").set("default", !!currentTask.arguments?.request?.decomposed_to?.region);
                state.form.$("region").set("default", currentTask.arguments?.request?.decomposed_to?.region);
                state.form.$("alias").set("default", aliasId);
                state.form.$("preset").set("default", currentTask.arguments?.preset || "");
                state.form.$("unknownStreamMode").set("default", currentTask.arguments?.unknownStreamMode);
            }
        }

        state.form.reset();
        state.form.showErrors(false);
    };

    const onSubmit = (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;

        let argumentsValue = {};
        switch (state.form.$("task").value) {
            case TASKS_OPTIONS.autotagging: {
                argumentsValue = {
                    s3: { replace: true, url: "alias://aimlservices02" },
                    access_code: state.form.$("password").value,
                    confidence: null,
                    limit: null,
                    preserve_access_code: false,
                    log_level: state.form.$("logLevel").value,
                };
                break;
            }
            case TASKS_OPTIONS.transcode: {
                argumentsValue = {
                    unknownStreamMode: state.form.$("unknownStreamMode").value,
                    destination: encodeURIComponent(
                        `${state.form.$("resource").value}${state.form.$("path").value && "/"}${state.form.$("path").value}`
                    ),
                    preset: state.form.$("preset").value.toString(),
                    log_level: state.form.$("logLevel").value,
                };
                break;
            }
            case TASKS_OPTIONS.delete || TASKS_OPTIONS.move || TASKS_OPTIONS.automation: {
                argumentsValue = {
                    log_level: state.form.$("logLevel").value,
                };
                break;
            }
            case TASKS_OPTIONS.tricaster: {
                argumentsValue = {
                    request: {
                        decomposed_to: {
                            schema: state.form.$("schema").value,
                            host: getAliasName(state.form.$("alias").value),
                        },
                    },
                    destination: {
                        user: "",
                        password: "",
                    },
                };
                break;
            }
            default: {
                argumentsValue = {
                    request: {
                        decomposed_to: {
                            schema: state.form.$("schema").value,
                            host: getAliasName(state.form.$("alias").value),
                            path: state.form.$("path").value,
                            region: state.form.$("region").value,
                            resource: state.form.$("resource").value,
                        },
                        operation: state.form.$("move").value ? OPERATION_VALUE.move : OPERATION_VALUE.copy,
                        replace: state.form.$("replace").value,
                        resume: state.form.$("transferResume").value,
                        speed_limit: state.form.$("speedLimitAllow").value ? state.form.$("speedLimit").value : undefined,
                        create_folder: state.form.$("createFolder").value,
                        multi_threads: false,
                        engine: "engine2",
                        log_filename: "automation-" + automationsStore.currentAutomationId,
                        use_destination_filter: true,
                    },
                    destination: {
                        user: state.form.$("user").value,
                        password: state.form.$("password").value,
                        metadata: {
                            ftp_user_dir_is_root: state.form.$("ftpUserDirIsRoot").value,
                            ftp_passive: state.form.$("ftpPassive").value,
                        },
                    },
                    log_level: state.form.$("logLevel").value,
                };
            }
        }

        let data = {
            automation_id: automationsStore.currentAutomationId,
            arguments: argumentsValue,
            datasets: [{ dataset_no: 0, dataset_type: state.form.$("source").value, destination_no: 0 }],
            deleted: false,
            enabled: !isCreate ? automationTasksStore.currentTask.enabled : true,
            event_object_types: null,
            fail_automation_if_failed: true,
            id: !isCreate ? automationTasksStore.currentTask.id : null,
            mandatory: false,
            restart_if_failed: true,
            sequence: !isCreate ? automationTasksStore.currentTask.sequence : automationTasksStore.availableTasks.length,
        };

        if (TASKS_OPTIONS_SINGLE.find((option) => option.value === state.form.$("task").value)) {
            data = {
                ...data,
                action: { action: state.form.$("task").value },
                group_action: null,
                type: TASK_TYPE,
            };
        } else if (TASKS_OPTIONS_GROUP.find((option) => option.value === state.form.$("task").value)) {
            data = {
                ...data,
                action: null,
                group_action: state.form.$("task").value,
            };
        }

        const res = isCreate ? automationTasksStore.addTask(data) : automationTasksStore.updateCurrentTask(data);
        if (res) {
            state.isSubmitted = true;
            return res;
        }

    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            closeModalAndResetForm();
            return;
        }
        unsavedChangesModal.open();
    };

    const onChangeSchema = (e) => {
        state.form.$("schema").value = e.target.value;
        state.form.$("resource").clear();
        state.form.$("user").clear();
        state.form.$("regionChoose").clear();
        state.form.$("region").clear();
        state.form.$("password").clear();
        state.form.$("password").resetValidation();
        state.form.$("path").clear();
        state.form.$("ftpPassive").clear();
        state.form.$("ftpUserDirIsRoot").clear();
        state.form.$("alias").clear();
    };

    const getAliasName = (aliasId) => {
        if (state.form.$("task").value === TASKS_OPTIONS.tricaster) {
            return state.tricasterAliases.find((alias) => alias.id === aliasId)?.name || "";
        } else {
            return state.notTricasterAliases.find((alias) => alias.id === aliasId)?.name || "";
        }
    };

    const getAliasId = (aliasName) => {
        if (state.form.$("task").value === TASKS_OPTIONS.tricaster) {
            return state.tricasterAliases.find((alias) => alias.name === aliasName)?.id || "";
        } else {
            return state.notTricasterAliases.find((alias) => alias.name === aliasName)?.id || "";
        }
    };

    const getTricasterAliasUrl = (aliasId) => {
        const currentAlias = slingshotSettingsStore.aliases.find((alias) => alias.id === aliasId)?.decomposed_base_url;
        let divider = ":/";
        if (
            currentAlias.schema === SCHEMA_OPTIONS.smb ||
            currentAlias.schema === SCHEMA_OPTIONS.ftp ||
            currentAlias.schema === SCHEMA_OPTIONS.afp
        ) {
            divider = divider + "/";
        }
        if (currentAlias.schema === SCHEMA_OPTIONS.s3 && currentAlias.region) {
            if (currentAlias.region !== S3_CUSTOM_REGION) {
                divider = divider + S3_SCHEMA;
            } else {
                divider = divider + S3_CUSTOM_SCHEMA;
            }
        } else if (currentAlias.schema === SCHEMA_OPTIONS.s3 && !currentAlias.region) {
            divider = divider + "/";
        }
        const newBaseUrl = [];
        currentAlias.host && newBaseUrl.push(currentAlias.host);
        currentAlias.resource && newBaseUrl.push(currentAlias.resource);
        currentAlias.path && newBaseUrl.push(currentAlias.path);
        return currentAlias.schema + divider + newBaseUrl.map((el) => el.replace(/^\/|\/$/g, "")).join("/");
    };

    const getHeader = () => (
        <DialogTitle className={classes.dialogTitle}>
            <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container wrap={"nowrap"}>
                <Grid item className={classes.titleText}>
                    {!isCreate ? t("slingshot.automation.task_edit.title") : t("slingshot.automation.task_new.title")}
                </Grid>
                <Grid item>
                    <CardHeaderControlButton onClick={closeIfNeeded}>
                        <ButtonBase id={`${idPrefix}_close_icon`}>
                            <CloseIcon />
                        </ButtonBase>
                    </CardHeaderControlButton>
                </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2} className={classes.parentBreadcrumbs}>
                <Grid item>
                    <Typography variant="h6" className={classes.parentName}>
                        {t("slingshot.automations.edit_automation.title")}
                    </Typography>
                </Grid>
                <Grid item>
                    <ThinArrowRightIcon />
                </Grid>
                <Grid item>
                    <Typography variant="h6" className={classes.folderBrowserLabel}>
                        {!isCreate ? t("slingshot.automation.task_edit.title") : t("slingshot.automation.task_new.title")}
                    </Typography>
                </Grid>
            </Grid>
        </DialogTitle>
    );

    const onChangeTask = (e) => {
        state.form.$("task").value = e.target.value;
        state.form.$("path").clear();

        if (e.target.value === TASKS_OPTIONS.transcode) {
            state.form.$("preset").set("value", 0);
        }

        if (e.target.value === TASKS_OPTIONS.tricaster) {
            state.form.$("schema").set("value", SCHEMA_OPTIONS.alias);
        } else {
            state.form.$("schema").set("value", SCHEMA_OPTIONS.evo);
        }
        if (!getAliasId(state.form.$("alias").value)) {
            state.form.$("alias").set("value", "");
        }
        state.form.$("schema").resetValidation();
    };

    return (
        <Dialog
            maxWidth="md"
            onEnter={onEnter}
            header={getHeader()}
            open={open}
            onClose={closeIfNeeded}
            onSubmit={onSubmit}
            isSubmitted={state.isSubmitted}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            buttons={
                <Button variant="contained" color="secondary" onClick={closeModalAndResetForm} id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
            hide={editPresetModal.isOpen || editAliasModal.isOpen}
        >
            <Grid container spacing={2}>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot.automation.task.label.task")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            onChange={onChangeTask}
                            field={state.form.$("task")}
                            options={TASKS_OPTIONS_SINGLE.concat(TASKS_OPTIONS_GROUP)}
                            id={`${idPrefix}_task`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot.automation.task.label.source")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Select field={state.form.$("source")} options={TASKS_SOURCE} id={`${idPrefix}_source`} />
                    </Grid>
                </Grid>
                <Grid item container>
                    <Box pb={5} width="100%">
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography>{t("slingshot.automation.task.label.log_level")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select field={state.form.$("logLevel")} options={LOG_LEVEL} id={`${idPrefix}_source`} />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {state.form.$("task").value === TASKS_OPTIONS.move && (
                    <Grid item container alignItems={"center"}>
                        <Typography>{t("slingshot.automation.task.move_helper_text")}</Typography>
                    </Grid>
                )}
                {state.form.$("task").value === TASKS_OPTIONS.automation && (
                    <Grid item container alignItems={"center"}>
                        <Typography>{t("slingshot.automation.task.append_helper_text")}</Typography>
                    </Grid>
                )}
                {(state.form.$("task").value === TASKS_OPTIONS.autotagging) && (
                    <Grid item container alignItems={"center"}>
                        <Grid item xs={4}>
                            <Typography>{t("slingshot.automation.task.label.access_code")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextInput field={state.form.$("password")} id={`${idPrefix}_password`} />
                        </Grid>
                    </Grid>
                )}
                {state.form.$("task").value === TASKS_OPTIONS.copy && (
                    <>
                        <Grid item container alignItems={"flex-start"}>
                            <Grid item xs={4}>
                                <Box pt={1}>
                                    <Typography>{t("slingshot.automation.task.label.options")}</Typography>
                                </Box>
                            </Grid>
                            <Grid container item xs={8} spacing={4}>
                                <Grid container item>
                                    <FormControlLabel
                                        className={classes.switchLabel}
                                        control={<Switch field={state.form.$("move")} id={`${idPrefix}_move`} />}
                                        label={t("slingshot.automation.task.label.move")}
                                    />
                                </Grid>
                                <Grid container item>
                                    <FormControlLabel
                                        className={classes.switchLabel}
                                        control={<Switch field={state.form.$("replace")} id={`${idPrefix}_replace`} />}
                                        label={t("slingshot.automation.task.label.replace")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"}>
                            <Box pt={8} width="100%">
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("slingshot.automation.task.label.scheme")}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            onChange={onChangeSchema}
                                            field={state.form.$("schema")}
                                            options={SCHEMA_OPTIONS_ARRAY}
                                            id={`${idPrefix}_schema`}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        {state.form.$("schema").value !== SCHEMA_OPTIONS.alias && (
                            <Grid item container alignItems={"center"} className={classes.user}>
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.settings.aliases.user")}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextInput
                                        field={state.form.$("user")}
                                        inputProps={{
                                            autoComplete: "new-name",
                                        }}
                                        id={`${idPrefix}_user`}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {state.form.$("schema").value !== SCHEMA_OPTIONS.evo &&
                            state.form.$("schema").value !== SCHEMA_OPTIONS.alias && (
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("slingshot_page.tabs.settings.aliases.password")}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextInput
                                            field={state.form.$("password")}
                                            inputProps={{
                                                autoComplete: "new-password",
                                            }}
                                            id={`${idPrefix}_password`}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        {state.form.$("schema").value === SCHEMA_OPTIONS.evo && (
                            <Grid item container alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.settings.aliases.share")}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        onChange={onShareSelectChange}
                                        field={state.form.$("resource")}
                                        options={state.shares}
                                        id={`${idPrefix}_resource`}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {state.form.$("schema").value === SCHEMA_OPTIONS.alias && (
                            <>
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("slingshot.automation.task.label.alias")}</Typography>
                                    </Grid>
                                    <Grid item container wrap={"nowrap"} xs={8}>
                                        <Select
                                            field={state.form.$("alias")}
                                            options={state.notTricasterAliasesOptions}
                                            id={`${idPrefix}_alias`}
                                        />
                                        <Box pl={4} pt={1}>
                                            <ButtonBase
                                                onClick={openEditAliasDialog}
                                                disabled={!state.form.$("alias").value}
                                                id={`${idPrefix}_open_edit_alias_dialog`}
                                            >
                                                <EditIcon />
                                            </ButtonBase>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4} />
                                    <Grid item xs={8}>
                                        <Box py={2}>
                                            {!state.form.$("alias").value ? (
                                                <Typography>{t("slingshot.automation.task.alias_helper_text")}</Typography>
                                            ) : (
                                                <Typography>{getTricasterAliasUrl(state.form.$("alias").value)}</Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid item container>
                            <Box pb={8} width={"100%"}>
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("slingshot_page.tabs.settings.aliases.path")}</Typography>
                                    </Grid>
                                    <Grid item xs={8} wrap={"nowrap"} container>
                                        <Grid item container>
                                            <TextInput field={state.form.$("path")} id={`${idPrefix}_path`} />
                                        </Grid>
                                        {state.form.$("schema").value === SCHEMA_OPTIONS.evo && (
                                            <Grid item>
                                                <Box pl={4}>
                                                    <Button
                                                        disabled={!state.form.$("resource").value}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={folderBrowserModal.open}
                                                        id={`${idPrefix}_open_folder_browser_modal`}
                                                    >
                                                        {t("common.button.browse")}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item container xs={12} alignItems={"center"}>
                            <Accordion
                                className={classes.expansionPanel}
                                square
                                expanded={state.accordionExpanded}
                                onChange={(event, newExpanded) => {
                                    state.accordionExpanded = newExpanded;
                                }}
                                id={`${idPrefix}_accordion`}
                            >
                                <AccordionSummary
                                    className={classes.expansionPanelContent}
                                    classes={{ content: classes.expansionPanelContent, expanded: classes.expansionPanelContent }}
                                    id={`${idPrefix}_accordion_summary`}
                                >
                                    <Typography variant={"h6"} className={classes.expansionLabel}>
                                        {t("slingshot.automation.task.label.advanced")}
                                    </Typography>
                                    <ChevronUpIcon
                                        className={clsx(
                                            !state.accordionExpanded && classes.expansionIconClose,
                                            classes.expansionIcon
                                        )}
                                    />
                                </AccordionSummary>
                                <AccordionDetails id={`${idPrefix}_accordion_details`}>
                                    <Grid container spacing={2}>
                                        <Grid item container alignItems={"center"}>
                                            <Grid item xs={4}>
                                                <Typography>{t("slingshot.automation.task.label.create_folder")}</Typography>
                                            </Grid>
                                            <Grid container item xs={8}>
                                                <FormControlLabel
                                                    className={classes.switchLabel}
                                                    control={
                                                        <Switch
                                                            field={state.form.$("createFolder")}
                                                            id={`${idPrefix}_create_folder`}
                                                        />
                                                    }
                                                    label={t("slingshot.automation.task.label.create_target_folder")}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container alignItems={"center"}>
                                            <Grid item xs={4}>
                                                <Typography>{t("slingshot.automation.task.label.speed_limit")}</Typography>
                                            </Grid>
                                            <Grid container item alignItems={"center"} xs={8} wrap={"nowrap"}>
                                                <FormControlLabel
                                                    className={classes.switchLabel}
                                                    control={
                                                        <Switch
                                                            field={state.form.$("speedLimitAllow")}
                                                            id={`${idPrefix}_speed_limit_allow`}
                                                        />
                                                    }
                                                    label={t("slingshot.automation.task.label.speed_limit_transfer")}
                                                />
                                                <TextInput
                                                    className={classes.speedLimit}
                                                    field={state.form.$("speedLimit")}
                                                    inputProps={{ min: 1, step: "1" }}
                                                    id={`${idPrefix}_speed_limit`}
                                                />
                                                <Box pl={2}>
                                                    <Typography>{t("slingshot.automation.task.label.unit")}</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </>
                )}
                {state.form.$("task").value === TASKS_OPTIONS.transcode && (
                    <>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography>{t("slingshot.automation.task.label.preset")}</Typography>
                            </Grid>
                            <Grid item container wrap={"nowrap"} xs={8}>
                                <Select field={state.form.$("preset")} options={state.presets} id={`${idPrefix}_preset`} />
                                <Box pl={4} pt={1}>
                                    <ButtonBase onClick={openEditPresetDialog} id={`${idPrefix}_open_edit_preset_dialog`}>
                                        <EditIcon />
                                    </ButtonBase>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography>{t("slingshot.automation.task.label.unknown_stream_mode")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    field={state.form.$("unknownStreamMode")}
                                    options={TRANSCODE_REACTION}
                                    id={`${idPrefix}_unknown_stream_mode`}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography>{t("slingshot_page.tabs.settings.aliases.share")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    onChange={onShareSelectChange}
                                    field={state.form.$("resource")}
                                    options={state.shares}
                                    id={`${idPrefix}_resource`}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography>{t("slingshot.automation.task.label.destination_path")}</Typography>
                            </Grid>
                            <Grid item xs={8} wrap={"nowrap"} container>
                                <Grid item container>
                                    <TextInput field={state.form.$("path")} id={`${idPrefix}_path`} />
                                </Grid>
                                <Grid item>
                                    <Box pl={4}>
                                        <Button
                                            disabled={!state.form.$("resource").value}
                                            variant="contained"
                                            color="primary"
                                            onClick={folderBrowserModal.open}
                                            id={`${idPrefix}_open_folder_browser_modal`}
                                        >
                                            {t("common.button.browse")}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}

                {state.form.$("task").value === TASKS_OPTIONS.tricaster && (
                    <>
                        <Grid item container alignItems={"center"}>
                            <Box pt={8} width="100%">
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("slingshot.automation.task.label.scheme")}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            disabled
                                            field={state.form.$("schema")}
                                            options={[SCHEMA_OPTIONS_ARRAY[0]]}
                                            id={`${idPrefix}_schema`}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography>{t("slingshot.automation.task.label.alias")}</Typography>
                            </Grid>
                            <Grid item container wrap={"nowrap"} xs={8}>
                                <Select
                                    field={state.form.$("alias")}
                                    options={state.tricasterAliasesOptions}
                                    id={`${idPrefix}_alias`}
                                />
                                <Box pl={4} pt={1}>
                                    <ButtonBase
                                        onClick={openEditAliasDialog}
                                        disabled={!state.form.$("alias").value}
                                        id={`${idPrefix}_open_edit_alias_dialog`}
                                    >
                                        <EditIcon />
                                    </ButtonBase>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={4} />
                            <Grid item xs={8}>
                                <Box py={2}>
                                    {!state.form.$("alias").value ? (
                                        <Typography>{t("slingshot.automation.task.alias_helper_text")}</Typography>
                                    ) : (
                                        <Typography>{getTricasterAliasUrl(state.form.$("alias").value)}</Typography>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
            <UnsavedChangesDialog
                onConfirm={closeModalAndResetForm}
                onClose={unsavedChangesModal.close}
                open={unsavedChangesModal.isOpen}
            />
            <FolderBrowser
                open={folderBrowserModal.isOpen}
                onClose={folderBrowserModal.close}
                share={state.form.$("resource").value}
                parentName={
                    automationTasksStore.currentTaskId
                        ? t("slingshot.automation.task_edit.title")
                        : t("slingshot.automation.task_new.title")
                }
                onChoose={setPath}
                pathValue={state.form.$("path").value}
                parentId={idPrefix}
            />
            <CreateEditPresetDialog
                open={editPresetModal.isOpen}
                onClose={editPresetModal.close}
                onCloseDrawer={onCloseDrawer}
                changeProgressState={changeProgressState}
                parentId={idPrefix}
            />
            <CreateEditAliasDialog
                open={editAliasModal.isOpen}
                onClose={editAliasModal.close}
                onCloseDrawer={onCloseDrawer}
                changeProgressState={changeProgressState}
                parentId={idPrefix}
            />
        </Dialog>
    );
};

export default observer(CreateEditTaskModal);
