import i18n from "i18n";

const poolNameRule = {
    poolName: {
        function: value => {
            return /^[A-Za-z0-9]+$/.test(value);
        },
        message: i18n.t("rule.pool.name")
    }
};

export default poolNameRule;
