import React from "react";

const InputErrorIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
            <g fill="none" fillRule="evenodd">
                <circle cx="8" cy="8" r="8" fill="#E90F3F" />
                <rect width="2" height="5" x="7" y="4" fill="#FFF" rx="1" />
                <rect width="2" height="2" x="7" y="10" fill="#FFF" rx="1" />
            </g>
        </svg>
    );
};

export default InputErrorIcon;
