import { types } from "mobx-state-tree";

import Request from "api/Request";
import DatabaseLocation from "api/system_components/postgres/Types/SetDatabaseLocation";

const SetDatabaseLocation = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Postgres",
    method: "set_database_location",
    arguments: types.maybe(DatabaseLocation)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetDatabaseLocation;
