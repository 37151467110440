import React from "react";

const UsersIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="g1pi2a2u5b" x1="46.768%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A770FF" />
                    <stop offset="100%" stopColor="#85ADFF" />
                </linearGradient>
                <linearGradient id="ta1tbydmda" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#E19EFF" stopOpacity=".771" />
                    <stop offset="100%" stopColor="#293CFF" stopOpacity=".6" />
                </linearGradient>
                <linearGradient id="h5p62s3zgc" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <path
                                fill="url(#g1pi2a2u5b)"
                                stroke="url(#ta1tbydmda)"
                                d="M8 2.5c1.243 0 2.368.504 3.182 1.318.814.814 1.318 1.94 1.318 3.182 0 1.369-.611 2.595-1.575 3.42-.211.18-.438.341-.678.48.263.097.52.212.77.343 1.053.552 1.936 1.385 2.55 2.399.592.98.933 2.129.933 3.358h0v4.5h-13V17c0-1.229.34-2.378.934-3.358.613-1.015 1.496-1.848 2.549-2.4.25-.132.507-.247.77-.344-.24-.139-.467-.3-.678-.48C4.11 9.595 3.5 8.369 3.5 7c0-1.243.504-2.368 1.318-3.182C5.632 3.004 6.758 2.5 8 2.5z"
                                transform="translate(-1083 -92) translate(1060 72) translate(23 20)"
                            />
                            <path
                                fill="#85C3FF"
                                stroke="url(#h5p62s3zgc)"
                                d="M17 7.5c.966 0 1.841.392 2.475 1.025.633.634 1.025 1.509 1.025 2.475 0 .932-.364 1.778-.958 2.405-.164.174-.346.331-.544.469.228.089.45.193.665.312.86.478 1.58 1.18 2.08 2.026.48.817.757 1.77.757 2.788h0v2.5h-11V19c0-1.018.276-1.97.758-2.788.5-.847 1.218-1.548 2.08-2.026.214-.119.437-.223.664-.313-.197-.137-.38-.294-.544-.468-.594-.627-.958-1.473-.958-2.405 0-.966.392-1.841 1.025-2.475C15.16 7.892 16.034 7.5 17 7.5h0z"
                                transform="translate(-1083 -92) translate(1060 72) translate(23 20)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default UsersIcon;
