/* eslint-disable no-console */
import EthernetPortsCard from "pages/Network/components/EthernetPortsCard";
import React from "react";
import NetworkServiceAdvertising from "pages/Network/components/NetworkServiceAdvertising";
import DnsCard from "pages/Network/components/DnsCard";
import HelpDocsCard from "pages/Support/components/HelpDocsCard";
import PushDiagnostics from "pages/Support/components/PushDiagnostics";
import LogicalDisks from "pages/DisksAndPools/components/LogicalDisks";
import CurrentSession from "pages/Shares/components/CurrentSession";
import TimeSettingsCard from "pages/TimeSettings/components/TimeSettingsCard";
import i18n from "i18n";
import { MESSAGE_WIDGET_NOT_PROVIDER } from "const/errorConst";
import {
    TIME_SETTINGS_CARD,
    POOLS_CARD,
    AGGREGATED_INTERFACES_CARD,
    DNS_CARD,
    ETHERNET_PORTS_CARD,
    NETWORK_SERVICE_ADVERTISING,
    PING_TEST_CARD,
    VIRTUAL_SWITCH_CARD,
    BACKUPS_TABLE_CARD,
    HELP_DOCS_CARD,
    INSTALLED_COMPONENTS_TABLE_CARD,
    INSTALLED_PLUGINS_TABLE_CARD,
    LOOPBACK_TEST_CARD,
    NAS_DISK_TEST_CARD,
    PUSH_DIAGNOSTICS,
    UPGRADE_SYSTEM,
    PARTITION_TABLE,
    LOGICAL_DISKS,
    EXPANSION_QUAD_CARD,
    IFACES_CARD,
    ACTIVATED_KEYS_CARD,
    ACTIVATION_KEYS_CARD,
    POOL_SHARE,
    SHARES_CURRENT_SESSION,
    SERVER_CERTIFICATE_CARD,
    CONTACT_SUPPORT,
    SYSTEM_LOGS,
    ADMIN_NETWORKING_SETTINGS_CARD,
    REMOTE_SHARE,
    CPU_GRAPH_CARD,
    CPU_DETAILED_GRAPH_CARD,
    THROUGHPUT_GRAPH_CARD,
    STORAGE_USAGE_GRAPH_CARD,
    WEB_ACCESS_CREDENTIALS_CARD,
    UPS_CONF,
    POWER,
    EMAIL_CONFIGURATION_CARD,
    MANAGE_EVENTS_CARD,
    SNMP_SERVICE_CONFIGURATION_CARD,
    ALERTS_CARD,
    SECONDARY_ADMINS_CARD,
    RESTRICT_ACCESS_CARD,
    LOGGING_LEVEL,
    CPU_CARD,
    RAID_CONTROLLERS_CARD,
    SYSTEM_MEMORY_CARD,
    SENSORS_CARD,
    ETHERNET_CARD,
    PHYSICAL_DISKS_CARD,
    AGENT_CONFIGURATION_CARD,
    AUDIT_SETTINGS_CARD,
    MANAGE_INFORMATION_BASE_CARD,
    SLINGSHOT_ALIAS_CARD,
    SLINGSHOT_PRESET_CARD,
    SLINGSHOT_WATERMARKS_CARD,
    SLINGSHOT_AUTOTAGGING_MACHINE_CARD,
    SLINGSHOT_REPLICATION_LOGS_CARD,
    NAS_CONFIGURATION_CARD,
    NETWORKING_SETTINGS_CARD,
    NAS_RECYCLE_BIN_CARD,
    DISK_USAGE_THRESHOLD_CARD,
    ADVANCED_MODE_FIREWALL,
    SLINGSHOT_INDEXER_CARD,
    SLINGSHOT_REPLICATIONS_CARD,
    SLINGSHOT_EVO_ENVIRONMENT_CARD,
    SLINGSHOT_PROXY_DESTINATION_CARD,
    SLINGSHOT_PROXY_SETTINGS_CARD,
    SLINGSHOT_PROXY_GENERATION_CARD,
    SLINGSHOT_PROXY_PAUSE_CARD,
    SLINGSHOT_AUTOMATIONS_CARD,
    WIREGUARD_CARD,
    MISSING_QUADS_CARD,
    NETWORK_GRAPH_CARD,
    GRAPHS_SETTINGS_CARD,
    AUDIO_ALARM_MANAGE_CARD,
    SCHEDULED_BACKUP_CARD,
    DEFERRED_PARITY_CALCULATION_CARD,
    ADMIN_DISPLAY_LANGUAGE_CARD,
    LOCAL_BRICKS_CARD,
    PEERS_CARD,
    VOLUMES_CARD,
    SHARE_BROWSER_CARD,
    FILE_NAVIGATOR_LOG_CARD,
    SYSTEM_DRIVE_STATUS,
    LONG_LIVED_TOKENS_CARD,
} from "const/widgetNameConst";
import AggregatedInterfacesCard from "pages/Network/components/AggregatedInterfacesCard";
import PingTestCard from "pages/Network/components/PingTestCard";
import VirtualSwitchCard from "pages/Network/components/VirtualSwitchCard";
import BackupsTableCard from "pages/Support/components/BackupsTableCard";
import InstalledComponentsTableCard from "pages/Support/components/InstalledComponentsTableCard";
import InstalledPluginsTableCard from "pages/Support/components/InstalledPluginsTableCard";
import LoopbackTestCard from "pages/Support/components/LoopbackTestCard";
import NasDiskTestCard from "pages/Support/components/NasDiskTestCard";
import PartitionTable from "pages/Support/components/OsDataTabComponent/PartitionTable";
import ExpansionQuadCard from "pages/HardwareActivation/components/ExpansionQuadCard";
import IfacesCard from "pages/HardwareActivation/components/IfacesCard";
import ActivatedKeysCard from "pages/HardwareActivation/components/ActivatedKeysCard";
import ActivationKeysCard from "pages/HardwareActivation/components/ActivationKeysCard";
import ServerCertificateCard from "pages/AdminSettings/components/ServerCertificateCard";
import Pools from "pages/DisksAndPools/components/Pools";
import UpgradeSystemCard from "pages/Support/components/OsDataTabComponent/UpgradeSystemCard";
import ContactSupport from "pages/Support/components/ContactSupport";
import SystemLogs from "pages/Support/components/SystemLogs";
import SoloPoolShare from "pages/Shares/components/SoloPoolShare";
import NetworkingSettingsCard from "pages/AdminSettings/components/NetworkingSettingsCard";
import CredentialsCard from "pages/AdminSettings/components/CredentialsCard";
import RemoteShare from "pages/Shares/components/RemoteShare";
import CpuGraphCard from "pages/Dashboard/components/CpuGraphCard";
import CPUDetailedGraphCard from "pages/Dashboard/components/CPUDetailedGraphCard";
import ThroughputGraphCard from "pages/Dashboard/components/ThroughputGraphCard";
import StorageUsageGraphCard from "pages/Dashboard/components/StorageUsageGraphCard";
import NetworkGraphCard from "pages/Dashboard/components/NetworkGraphCard";
import UpsConf from "pages/PowerAndUps/components/UpsConf";
import Power from "pages/PowerAndUps/components/Power";
import EmailConfigurationCard from "pages/Alerts/components/EmailConfigurationCard";
import ManageEventsCard from "pages/Alerts/components/ManageEventsCard";
import SnmpServiceConfigurationCard from "pages/Alerts/components/SnmpServiceConfigurationCard";
import AlertsCard from "pages/Alerts/components/AlertsCard";
import SecondaryAdminsCard from "pages/AdminSettings/components/SecondaryAdminsCard";
import RestrictAccessCard from "pages/AdminSettings/components/RestrictAccessCard";
import LongLivedTokensCard from "pages/AdminSettings/components/LongLivedTokensCard";
import LoggingLevelCard from "pages/Advanced/components/LoggingLevelCard";
import CPUCard from "pages/HardwareProfile/components/CPUCard";
import EthernetCard from "pages/HardwareProfile/components/EthernetCard";
import RAIDControllersCard from "pages/HardwareProfile/components/RAIDControllersCard";
import SensorsCard from "pages/HardwareProfile/components/SensorsCard";
import SystemMemoryCard from "pages/HardwareProfile/components/SystemMemoryCard";
import PhysicalDisks from "pages/Dashboard/components/PhysicalDisks";
import AgentConfigurationCard from "pages/Alerts/components/AgentConfigurationCard";
import AuditSettingsCard from "pages/Audit/components/AuditSettingsCard";
import ManagementInformationBaseCard from "pages/Alerts/components/ManagementInformationBaseCard";
import Aliases from "pages/Slingshot/components/Aliases";
import Presets from "pages/Slingshot/components/Presets";
import Watermarks from "pages/Slingshot/components/Watermarks";
import AutotaggingMachineCard from "pages/Slingshot/components/AutotaggingMachineCard";
import ReplicationLogsCard from "pages/Slingshot/components/ReplicationLogsCard";
import NasConfigurationCard from "pages/Advanced/components/NASConfiguration/NasConfigurationCard";
import NasRecycleBinCard from "pages/Advanced/components/NASRecycleBin/NasRecycleBinCard/NasRecycleBinCard";
import DiskUsageThresholdCard from "pages/Alerts/components/DiskUsageThreshold/DiskUsageThresholdCard/DiskUsageThresholdCard";
import AdvancedModeCard from "pages/Firewall/components/AdvancedModeCard";
import IndexerNameCard from "pages/Slingshot/components/IndexerNameCard";
import MultipleEvoEnvironmentCard from "pages/Slingshot/components/MultipleEvoEnvironmentCard/MultipleEvoEnvironmentCard";
import Replications from "pages/Slingshot/components/Replications/Replications";
import AutomationsTabContent from "pages/Slingshot/components/AutomationsTabContent/AutomationsTabContent";
import IndexPauseCard from "pages/Slingshot/components/IndexPauseCard/IndexPauseCard";
import ProxyGenerationCard from "pages/Slingshot/components/ProxyGenerationCard/ProxyGenerationCard";
import PreviewProxySettingsCard from "pages/Slingshot/components/PreviewProxySettingsCard/PreviewProxySettingsCard";
import ProxyDestinationCard from "pages/Slingshot/components/ProxyDestinationCard/ProxyDestinationCard";
import WireguardTable from "pages/Wireguard/components/WireguardTable/WireguardTable";
import MissingQuadsTable from "pages/HardwareActivation/components/MissingQuadsTable/MissingQuadsTable";
import GraphsSettingsCard from "pages/Advanced/components/GraphsSettingsCard";
import AudioAlarmManagerCard from "pages/Alerts/components/AudioAlarmManagerCard/AudioAlarmManagerCard";
import BackupCard from "pages/Support/components/BackupCard";
import DeferredParityCalculationCard from "pages/TechnicalSupport/components/DeferredParityCalculationCard/DeferredParityCalculationCard";
import { DisplayLanguageCard } from "../pages/AdminSettings/components/DisplayLanguageCard/DisplayLanguageCard";
import LocalBricksCard from "pages/Cluster/components/LocalBricksCard";
import PeersCard from "pages/Cluster/components/PeersCard";
import VolumesCard from "pages/Cluster/components/VolumesCard";
import ShareBrowserSettings from "pages/ShareBrowser/components/ShareBrowserSettings/ShareBrowserSettings";
import FileNavigatorLogCard from "pages/FileNavigatorLog/components/FileNavigatorLogCard";
import SystemDriveStatus from "pages/Support/components/SystemDriveStatus/SystemDriveStatus";
import AdvancedNetworkingSettingsCard from "../pages/Advanced/components/NetworkingSettingsCard/NetworkingSettingsCard/NetworkingSettingsCard";

const cardFactory = (widget, contentId) => {
    if (!widget) {
        throw new Error(MESSAGE_WIDGET_NOT_PROVIDER);
    }
    switch (widget) {
        case NETWORK_SERVICE_ADVERTISING: {
            return {
                component: <NetworkServiceAdvertising />,
                previewTitle: i18n.t("network.netServiceAdvertising.title"),
            };
        }
        case PHYSICAL_DISKS_CARD: {
            return {
                component: <PhysicalDisks />,
                previewTitle: i18n.t("dashboard.physical_disks.title"),
            };
        }
        case DNS_CARD: {
            return {
                component: <DnsCard />,
                previewTitle: i18n.t("network.dns.title"),
            };
        }
        case ETHERNET_PORTS_CARD: {
            return {
                component: <EthernetPortsCard />,
                previewTitle: i18n.t("network.ethernet_ports.title"),
            };
        }
        case HELP_DOCS_CARD: {
            return {
                component: <HelpDocsCard />,
                previewTitle: i18n.t("support.start.help.title"),
            };
        }
        case PUSH_DIAGNOSTICS: {
            return { component: <PushDiagnostics />, previewTitle: i18n.t("support.push_diagnostics.title") };
        }
        case LOGICAL_DISKS: {
            return { component: <LogicalDisks />, previewTitle: i18n.t("disks_and_pools.logical_disks.title") };
        }
        case SHARES_CURRENT_SESSION: {
            return { component: <CurrentSession />, previewTitle: i18n.t("share.current_session.title") };
        }
        case TIME_SETTINGS_CARD: {
            return { component: <TimeSettingsCard />, previewTitle: i18n.t("time_settings.card_title") };
        }
        case POOLS_CARD: {
            return {
                component: <Pools />,
                previewTitle: i18n.t("disks_and_pools.pools_card.title"),
            };
        }
        case AGGREGATED_INTERFACES_CARD: {
            return {
                component: <AggregatedInterfacesCard />,
                previewTitle: i18n.t("network.aggregated_interfaces.title"),
            };
        }
        case VIRTUAL_SWITCH_CARD: {
            return {
                component: <VirtualSwitchCard />,
                previewTitle: i18n.t("network.virtual_switch.title"),
            };
        }
        case PING_TEST_CARD: {
            return {
                component: <PingTestCard />,
                previewTitle: i18n.t("network.ping_test.title"),
            };
        }
        case BACKUPS_TABLE_CARD: {
            return {
                component: <BackupsTableCard />,
                previewTitle: i18n.t("support.os_data.backups.title"),
            };
        }
        case INSTALLED_COMPONENTS_TABLE_CARD: {
            return {
                component: <InstalledComponentsTableCard />,
                previewTitle: i18n.t("support.start.installed_components.title"),
            };
        }
        case INSTALLED_PLUGINS_TABLE_CARD: {
            return {
                component: <InstalledPluginsTableCard />,
                previewTitle: i18n.t("support.start.installed_plugins.title"),
            };
        }
        case LOOPBACK_TEST_CARD: {
            return {
                component: <LoopbackTestCard />,
                previewTitle: i18n.t("support.self_test.loopback.title"),
            };
        }
        case NAS_DISK_TEST_CARD: {
            return {
                component: <NasDiskTestCard />,
                previewTitle: i18n.t("support.self_test.disk.title"),
            };
        }
        case UPGRADE_SYSTEM: {
            return {
                component: <UpgradeSystemCard />,
                previewTitle: i18n.t("upgrades.upgrade_system.title"),
            };
        }
        case PARTITION_TABLE: {
            return {
                component: <PartitionTable />,
                previewTitle: i18n.t("upgrades.partition_status.title"),
            };
        }
        case EXPANSION_QUAD_CARD: {
            return {
                component: <ExpansionQuadCard />,
                previewTitle: i18n.t("hardware_activation.expansion_quad_licensing_card.title"),
            };
        }
        case IFACES_CARD: {
            return {
                component: <IfacesCard />,
                previewTitle: i18n.t("hardware_activation.ifaces_card.title"),
            };
        }
        case ACTIVATED_KEYS_CARD: {
            return {
                component: <ActivatedKeysCard />,
                previewTitle: i18n.t("hardware_activation.activated_keys.title"),
            };
        }
        case ACTIVATION_KEYS_CARD: {
            return {
                component: <ActivationKeysCard />,
                previewTitle: i18n.t("hardware_activation.activation_keys_card.title"),
            };
        }
        case POOL_SHARE: {
            return {
                component: <SoloPoolShare poolKey={contentId} />,
                previewTitle: contentId,
            };
        }
        case SERVER_CERTIFICATE_CARD: {
            return {
                component: <ServerCertificateCard />,
                previewTitle: i18n.t("admin_settings.server_certificate.title"),
            };
        }
        case WEB_ACCESS_CREDENTIALS_CARD: {
            return {
                component: <CredentialsCard />,
                previewTitle: i18n.t("admin_settings.web_access_credentials.title"),
            };
        }
        case CONTACT_SUPPORT: {
            return {
                component: <ContactSupport />,
                previewTitle: i18n.t("support.start.contact.title"),
            };
        }
        case SYSTEM_LOGS: {
            return {
                component: <SystemLogs />,
                previewTitle: i18n.t("support.system_logs.title"),
            };
        }
        case ADMIN_NETWORKING_SETTINGS_CARD: {
            return {
                component: <NetworkingSettingsCard />,
                previewTitle: i18n.t("admin_settings.networking_settings.title"),
            };
        }
        case NETWORKING_SETTINGS_CARD: {
            return {
                component: <AdvancedNetworkingSettingsCard />,
                previewTitle: i18n.t("advanced.networking_settings.title"),
            };
        }
        case ADMIN_DISPLAY_LANGUAGE_CARD: {
            return {
                component: <DisplayLanguageCard />,
                previewTitle: i18n.t("admin_settings.display_language_card.title"),
            };
        }
        case REMOTE_SHARE: {
            return {
                component: <RemoteShare />,
                previewTitle: i18n.t("share.remote_shares.title"),
            };
        }
        case CPU_GRAPH_CARD: {
            return {
                component: <CpuGraphCard />,
                previewTitle: i18n.t("dashboard.cpu_widget.title"),
            };
        }
        case CPU_DETAILED_GRAPH_CARD: {
            return {
                component: <CPUDetailedGraphCard />,
                previewTitle: i18n.t("dashboard.cpu_detailed_widget.title"),
            };
        }
        case THROUGHPUT_GRAPH_CARD: {
            return {
                component: <ThroughputGraphCard />,
                previewTitle: i18n.t("dashboard.throughput_widget.title"),
            };
        }
        case STORAGE_USAGE_GRAPH_CARD: {
            return {
                component: <StorageUsageGraphCard />,
                previewTitle: i18n.t("dashboard.storage_usage.title"),
            };
        }
        case NETWORK_GRAPH_CARD: {
            return {
                component: <NetworkGraphCard />,
                previewTitle: i18n.t("dashboard.network_widget.title"),
            };
        }
        case UPS_CONF: {
            return {
                component: <UpsConf />,
                previewTitle: i18n.t("power_and_ups.ups_conf.title"),
            };
        }
        case POWER: {
            return {
                component: <Power />,
                previewTitle: i18n.t("power_and_ups.power.title"),
            };
        }
        case EMAIL_CONFIGURATION_CARD: {
            return {
                component: <EmailConfigurationCard />,
                previewTitle: i18n.t("alerts.email_configuration_card.title"),
            };
        }
        case MANAGE_EVENTS_CARD: {
            return {
                component: <ManageEventsCard />,
                previewTitle: i18n.t("alerts.manage_events_card.title"),
            };
        }
        case SNMP_SERVICE_CONFIGURATION_CARD: {
            return {
                component: <SnmpServiceConfigurationCard />,
                previewTitle: i18n.t("alerts.snmp_service_configuration_card.title"),
            };
        }
        case ALERTS_CARD: {
            return {
                component: <AlertsCard />,
                previewTitle: i18n.t("alerts.title"),
            };
        }
        case SECONDARY_ADMINS_CARD: {
            return {
                component: <SecondaryAdminsCard />,
                previewTitle: i18n.t("admin_settings.secondary_admin.title"),
            };
        }
        case RESTRICT_ACCESS_CARD: {
            return {
                component: <RestrictAccessCard />,
                previewTitle: i18n.t("admin_settings.restrict_access.title"),
            };
        }
        case LONG_LIVED_TOKENS_CARD: {
            return {
                component: <LongLivedTokensCard />,
                previewTitle: i18n.t("admin_settings.long_lived_tokens_card.title"),
            };
        }
        case LOGGING_LEVEL: {
            return {
                component: <LoggingLevelCard />,
                previewTitle: i18n.t("advanced.logging_level.title"),
            };
        }
        case CPU_CARD: {
            return {
                component: <CPUCard />,
                previewTitle: i18n.t("hardware_profile.cpu_card.title"),
            };
        }
        case RAID_CONTROLLERS_CARD: {
            return {
                component: <RAIDControllersCard />,
                previewTitle: i18n.t("hardware_profile.raid_controllers_card.title"),
            };
        }
        case SYSTEM_MEMORY_CARD: {
            return {
                component: <SystemMemoryCard />,
                previewTitle: i18n.t("hardware_profile.system_memory_card.title"),
            };
        }
        case SENSORS_CARD: {
            return {
                component: <SensorsCard />,
                previewTitle: i18n.t("hardware_profile.sensors_card.title"),
            };
        }
        case ETHERNET_CARD: {
            return {
                component: <EthernetCard />,
                previewTitle: i18n.t("hardware_profile.ethernet_card.title"),
            };
        }
        case ADVANCED_MODE_FIREWALL: {
            return {
                component: <AdvancedModeCard />,
                previewTitle: i18n.t("firewall.title"),
            };
        }
        case AGENT_CONFIGURATION_CARD: {
            return {
                component: <AgentConfigurationCard />,
                previewTitle: i18n.t("alerts.agent_configuration_card.title"),
            };
        }
        case AUDIT_SETTINGS_CARD: {
            return {
                component: <AuditSettingsCard />,
                previewTitle: i18n.t("audit.audit_settings.title"),
            };
        }
        case MANAGE_INFORMATION_BASE_CARD: {
            return {
                component: <ManagementInformationBaseCard />,
                previewTitle: i18n.t("alerts.manage_information_base_card.title"),
            };
        }
        case SLINGSHOT_ALIAS_CARD: {
            return {
                component: <Aliases />,
                previewTitle: i18n.t("slingshot_page.tabs.settings.aliases.title"),
            };
        }
        case SLINGSHOT_PRESET_CARD: {
            return {
                component: <Presets />,
                previewTitle: i18n.t("slingshot_page.tabs.settings.presets.title"),
            };
        }
        case SLINGSHOT_WATERMARKS_CARD: {
            return {
                component: <Watermarks />,
                previewTitle: i18n.t("slingshot_page.tabs.settings.watermarks.title"),
            };
        }
        case SLINGSHOT_AUTOTAGGING_MACHINE_CARD: {
            return {
                component: <AutotaggingMachineCard />,
                previewTitle: i18n.t("slingshot_page.tabs.settings.machine.autotagging.title"),
            };
        }
        case SLINGSHOT_REPLICATION_LOGS_CARD: {
            return {
                component: <ReplicationLogsCard />,
                previewTitle: i18n.t("slingshot_page.tabs.settings.replication_logs.title"),
            };
        }
        case NAS_CONFIGURATION_CARD: {
            return {
                component: <NasConfigurationCard />,
                previewTitle: i18n.t("advanced.nas_configuration.title"),
            };
        }
        case NAS_RECYCLE_BIN_CARD: {
            return {
                component: <NasRecycleBinCard />,
                previewTitle: i18n.t("advanced.nas_recycle_bin.title"),
            };
        }
        case DISK_USAGE_THRESHOLD_CARD: {
            return {
                component: <DiskUsageThresholdCard />,
                previewTitle: i18n.t("alerts.disk_usage_threshold.title"),
            };
        }
        case SLINGSHOT_INDEXER_CARD: {
            return {
                component: <IndexerNameCard />,
                previewTitle: i18n.t("slingshot.index_proxy.indexer_name.title"),
            };
        }
        case SLINGSHOT_EVO_ENVIRONMENT_CARD: {
            return {
                component: <MultipleEvoEnvironmentCard />,
                previewTitle: i18n.t("slingshot.index_proxy.multiple_evo.title"),
            };
        }
        case SLINGSHOT_REPLICATIONS_CARD: {
            return {
                component: <Replications />,
                previewTitle: i18n.t("slingshot_page.tabs.replications.title"),
            };
        }
        case SLINGSHOT_AUTOMATIONS_CARD: {
            return {
                component: <AutomationsTabContent />,
                previewTitle: i18n.t("slingshot_page.tabs.label.automations"),
            };
        }
        case SLINGSHOT_PROXY_PAUSE_CARD: {
            return {
                component: <IndexPauseCard />,
                previewTitle: i18n.t("slingshot.index_proxy.pause.title"),
            };
        }
        case SLINGSHOT_PROXY_GENERATION_CARD: {
            return {
                component: <ProxyGenerationCard />,
                previewTitle: i18n.t("slingshot.index_proxy.proxy_generation.title"),
            };
        }
        case SLINGSHOT_PROXY_SETTINGS_CARD: {
            return {
                component: <PreviewProxySettingsCard />,
                previewTitle: i18n.t("slingshot.index_proxy.preview_settings.title"),
            };
        }
        case SLINGSHOT_PROXY_DESTINATION_CARD: {
            return {
                component: <ProxyDestinationCard />,
                previewTitle: i18n.t("slingshot.index_proxy.proxy_destination.title"),
            };
        }
        case WIREGUARD_CARD: {
            return {
                component: <WireguardTable />,
                previewTitle: i18n.t("wireguard.table.title"),
            };
        }
        case MISSING_QUADS_CARD: {
            return {
                component: <MissingQuadsTable />,
                previewTitle: i18n.t("missing_quads.table.title"),
            };
        }
        case GRAPHS_SETTINGS_CARD: {
            return {
                component: <GraphsSettingsCard />,
                previewTitle: i18n.t("advanced.graphs_settings_card.title"),
            };
        }
        case AUDIO_ALARM_MANAGE_CARD: {
            return {
                component: <AudioAlarmManagerCard />,
                previewTitle: i18n.t("alerts.audio_alarm_manager.title"),
            };
        }
        case SCHEDULED_BACKUP_CARD: {
            return {
                component: <BackupCard />,
                previewTitle: i18n.t("support.os_data.backup_card.title"),
            };
        }
        case DEFERRED_PARITY_CALCULATION_CARD: {
            return {
                component: <DeferredParityCalculationCard />,
                previewTitle: i18n.t("technical_support.deferred_parity_calculation.title"),
            };
        }
        case LOCAL_BRICKS_CARD: {
            return {
                component: <LocalBricksCard />,
                previewTitle: i18n.t("cluster.local_bricks.title"),
            };
        }
        case PEERS_CARD: {
            return {
                component: <PeersCard />,
                previewTitle: i18n.t("cluster.peers.title"),
            };
        }
        case VOLUMES_CARD: {
            return {
                component: <VolumesCard />,
                previewTitle: i18n.t("cluster.volumes.title"),
            };
        }
        case SHARE_BROWSER_CARD: {
            return {
                component: <ShareBrowserSettings />,
                previewTitle: i18n.t("sharebrowser_page.card.title"),
            };
        }
        case FILE_NAVIGATOR_LOG_CARD: {
            return {
                component: <FileNavigatorLogCard />,
                previewTitle: i18n.t("file_navigator_log.card.title"),
            };
        }
        case SYSTEM_DRIVE_STATUS: {
            return {
                component: <SystemDriveStatus />,
                previewTitle: i18n.t("system_drive_status.title"),
            };
        }
        default: {
            console.log(`No widget with value ${widget}`);
            // todo temporary solution, change to normal component
            return {
                component: <div>Incorrect widget {widget}</div>,
                previewTitle: widget,
            };
        }
    }
};

export default cardFactory;
