import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import DataReadonlyCard from "components/DataReadonlyCard";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { SHARE_BROWSER_CARD } from "const/widgetNameConst";
import SavedInputIndicator from "components/SavedInputIndicator";
import VolumeIcon from "assets/VolumeIcon";
import { URL_SLINGSHOT_INDEX, URL_SLINGSHOT_SETTINGS_TAB, URL_SUPPORT_OS_DATA_TAB } from "routes";
import { useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { observer } from "mobx-react";
import ButtonLine from "components/ButtonLine/ButtonLine";
import { useStore } from "hooks/useStore";

const ShareBrowserSettings = ({ openSettings }) => {
    const { t } = useTranslation();

    const {
        store: { postgresStore, sharebrowserStore, backupSettingsStore }
    } = useStore();

    const state = useLocalStore(() => ({
        isSettingsDialogIsOpen: false,
        isDatabaseLoaded: false
    }));

    useEffect(() => {
        (async () => {
            await backupSettingsStore.fetchBackup();
            await postgresStore.getDatabaseLocation();
            sharebrowserStore.getSharebrowserServerVersion();
            sharebrowserStore.getSharebrowserServerStatus();
            state.isDatabaseLoaded = true;
        })();
    }, []);

    return (
        <DataReadonlyCard
            headerDivider
            autoHeight
            title={t("sharebrowser_page.card.title")}
            onClick={openSettings}
            control={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={SHARE_BROWSER_CARD} />
                </MoreMenu>
            }
        >
            <React.Fragment>
                {!state.isDatabaseLoaded ||
                (postgresStore.databaseLocation?.share && backupSettingsStore.backup?.location?.share) ? (
                    <Grid container direction="column" spacing={6}>
                        <Grid item container wrap="nowrap">
                            <Grid item xs={6}>
                                <Typography>{t("sharebrowser_page.card.label.version")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6">{sharebrowserStore.serverVersion}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container wrap="nowrap">
                            <Grid item xs={6}>
                                <Typography>{t("sharebrowser_page.card.label.server_enabled")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <SavedInputIndicator enabled={sharebrowserStore.serverStatus} />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2}>
                            <Grid container item>
                                <ButtonLine link={URL_SLINGSHOT_INDEX} icon={VolumeIcon}>
                                    {t("sharebrowser_page.card.link.indexer_config")}
                                </ButtonLine>
                            </Grid>
                            <Grid container item>
                                <ButtonLine link={URL_SLINGSHOT_SETTINGS_TAB} icon={VolumeIcon}>
                                    {t("sharebrowser_page.card.link.aiml_config")}
                                </ButtonLine>
                            </Grid>
                            <Grid container item>
                                <ButtonLine link={URL_SUPPORT_OS_DATA_TAB} icon={VolumeIcon}>
                                    {t("sharebrowser_page.card.link.import_database_config")}
                                </ButtonLine>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder inProgress={state.isLoading} message={t("sharebrowser_page.card.placeholder")} />
                )}
            </React.Fragment>
        </DataReadonlyCard>
    );
};

export default observer(ShareBrowserSettings);
