import { useEffect } from "react";
import { useEventListener } from "./useEventListener";
import { useStore } from "./useStore";

const useDrawerOpenHandler = ({ drawerName, closeDrawer, targetDep }) => {
    const {
        store: { uiStore },
    } = useStore();

    useEffect(() => {
        if (targetDep) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, closeDrawer);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [targetDep]);

    useEventListener("keydown", function (e) {
        if (e.key !== "Escape") return null;
        if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    });
};
export default useDrawerOpenHandler;
