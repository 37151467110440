import { types } from "mobx-state-tree";

import SnmpSettings from "api/events/Types/SnmpSettings";

const SnmpSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(SnmpSettings)
});

export default SnmpSettingsResult;
