import React from "react";
import { useObserver } from "mobx-react";
import { useStyles } from "./VolumeStatusItem.style";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const VolumeStatusItem = ({ variant, data }) => {
    const classes = useStyles({ variant, data });
    const { t } = useTranslation();

    const getBuildTime = () => {
        if (variant === "health" && data.buildTime && data.health === ("building" || "grow") && !data.buildPaused) {
            return (
                <Grid item>
                    <Typography component={"span"} noWrap className={classes.labelText}>
                        {`(${data.buildTime})`}
                    </Typography>
                </Grid>
            );
        }
    };

    const getLabel = () => {
        if (variant === "health" && data.buildPaused) return t("disks_and_pools.logical_disks.row.cell.health.paused");
        switch (variant) {
            case "health":
                return data.health;
            case "status":
                return data.status;
            default:
                break;
        }
    };

    const getCircular = () => {
        if (variant === "health" && data.buildPercentage && data.health === ("building" || "grow")) {
            return (
                <Grid item className={classes.circularContainer}>
                    <CircularProgress thickness={4} size={16} variant={"determinate"} value={data.buildPercentage} />
                </Grid>
            );
        }
    };
    return useObserver(() => (
        <Grid container>
            <Grid item>
                <Grid container spacing={2} alignItems={"center"} direction={"row"} className={classes.label}>
                    <Grid container spacing={2} wrap={"nowrap"}>
                        {getCircular()}
                        <Grid item>
                            <Typography component={"span"} className={classes.labelCapitalize}>
                                {getLabel()}
                            </Typography>
                        </Grid>
                        {getBuildTime()}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    ));
};

export default VolumeStatusItem;
