import React from "react";

function UpgradeIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="UpgradeIcon-a" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
                <linearGradient id="UpgradeIcon-b" x1="50%" x2="95.116%" y1="46.057%" y2="100%">
                    <stop offset="0%" stopColor="#A4E2FF" />
                    <stop offset="100%" stopColor="#C2E1FF" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#85C3FF"
                    stroke="url(#UpgradeIcon-a)"
                    d="M19 1.5h-7.793L3.5 9.207V21c0 .414.168.79.44 1.06.27.272.646.44 1.06.44h14c.414 0 .79-.168 1.06-.44.272-.27.44-.646.44-1.06V3c0-.414-.168-.79-.44-1.06A1.49 1.49 0 0019 1.5z"
                />
                <path
                    fill="#C8E3FF"
                    fillRule="nonzero"
                    d="M12.764 10.286a1 1 0 00-1.4 0l-3 2.9a1 1 0 101.38 1.44l1.31-1.27v8.64h2v-8.59l1.29 1.3a1 1 0 001.414.006c.398-.395.4-1.028.011-1.42l-.005-.006-3-3z"
                />
                <path fill="url(#UpgradeIcon-b)" d="M11 1v6a2 2 0 01-2 2H3l8-8z" />
            </g>
        </svg>
    );
}

export default UpgradeIcon;
