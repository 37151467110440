import React, { useContext, useEffect } from "react";
import { useObserver } from "mobx-react";
import DataCard from "components/DataCard";
import { Grid, Typography } from "@material-ui/core";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import MoreMenu from "components/MoreMenu";
import { SYSTEM_MEMORY_CARD } from "const/widgetNameConst";
import { MiB_IEC } from "const/diskSizeConst";
import { useStyles } from "./SystemMemoryCard.styles";

const SystemMemoryCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { hardwareProfileStore }
    } = useContext(Context);
    useEffect(() => {
        hardwareProfileStore.fetchSystemMemoryInfo();
    }, []);

    return useObserver(() => (
        <DataCard
            title={t("hardware_profile.system_memory_card.title")}
            autoHeight
            emptyPadding
            headerDivider
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={SYSTEM_MEMORY_CARD} />
                </MoreMenu>
            }
        >
            <Grid container>
                <Grid item className={classes.item}>
                    <Typography>
                        {t("hardware_profile.system_memory_card.label", {
                            total: `${(hardwareProfileStore.memoryDeviceTotal / MiB_IEC.value).toFixed(0)} ${MiB_IEC.unit}`,
                            modules: hardwareProfileStore.memoryDeviceModules
                                .map(row => `${(row.name / MiB_IEC.value).toFixed(0)} ${MiB_IEC.unit} x ${row.value}`)
                                .join(", ")
                        })}
                    </Typography>
                </Grid>
            </Grid>
        </DataCard>
    ));
};

export default SystemMemoryCard;
