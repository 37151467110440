import React, { useContext } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import InfoCard from "components/InfoCard";
import PicLogsIcon from "assets/PicLogsIcon";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { MANAGE_INFORMATION_BASE_CARD } from "const/widgetNameConst";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const MANAGEMENT_INFORMATION_BASE_CARD_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_management_information_base_card`;

const ManagementInformationBaseCard = () => {
    const { t } = useTranslation();
    const {
        store: { alertsStore }
    } = useContext(Context);
    const state = useLocalStore(() => ({
        inProgress: false
    }));

    const downloadEvoMibFile = async () => {
        state.inProgress = true;
        await alertsStore.getEvoMibFile();
        state.inProgress = false;
    };

    return useObserver(() => (
        <InfoCard
            widget={MANAGE_INFORMATION_BASE_CARD}
            flag
            icon={<PicLogsIcon />}
            title={t("alerts.manage_information_base_card.title")}
            content={t("alerts.manage_information_base_card.content")}
            button={
                <AnimatedSubmitButton
                    inProgress={state.inProgress}
                    submit={downloadEvoMibFile}
                    disabled={state.inProgress}
                    label={t("common.button.download")}
                    id={`${MANAGEMENT_INFORMATION_BASE_CARD_ID_PREFIX}_download`}
                />
            }
        />
    ));
};

export default ManagementInformationBaseCard;
