import React, { useEffect } from "react";
import { useLocalStore, observer } from "mobx-react";
import MainTemplate from "components/MainTemplate";
import HeaderTitle from "components/HeaderTitle";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SlingshotIcon from "assets/SlingshotIcon";
import DatabaseLocationCard from "components/DatabaseLocationCard";
import {
    URL_SLINGSHOT,
    URL_SLINGSHOT_INDEX,
    URL_SLINGSHOT_AUTOMATIONS,
    URL_SLINGSHOT_ARCHIVED,
    URL_SLINGSHOT_SETTINGS_TAB,
} from "routes";
import ReplicationsTabContent from "./components/ReplicationsTabContent";
import SettingsTabContent from "./components/SettingsTabContent";
import { useStyles } from "./Slingshot.styles";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import IndexTabContent from "./components/IndexTabContent";
import AutomationsTabContent from "./components/AutomationsTabContent";
import ArchivedTabContent from "./components/ArchivedTabContent";
import useUrlUserPermission from "hooks/useUrlUserPermission";
import { ADMIN } from "const/userRolesConst";
import BackupSettingsWarningCard from "components/BackupSettingsWarningCard";
import TABS from "./Slingshot.tabs";
import { useStore } from "hooks/useStore";
import SlingshotHeaderTabs from "./components/SlingshotHeaderTabs";
import SystemServiceWarningDialog from "./components/SystemServiceWarningDialog/SystemServiceWarningDialog";
import { getShortcutName } from "./Slingshot.utils";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import ViewByRole from "components/ViewByRole";
import { SLINGSHOT_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const Slingshot = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { postgresStore, backupSettingsStore, authStore, systemComponentsStore },
    } = useStore();

    const location = useLocation();
    const state = useLocalStore(() => ({
        isDatabaseLoaded: false,
        isDatabaseLocationLoaded: false,
        fetchData: async () => {
            const backup = await backupSettingsStore.fetchBackup();
            const databaseLocation = await postgresStore.getDatabaseLocation();
            const isBackup = Boolean(backup?.data?.location?.share);
            const isDatabaseLocation = Boolean(databaseLocation?.data?.share);
            if (isBackup && isDatabaseLocation) await systemComponentsStore.fetchSystemComponentsServicesState();

            state.isDatabaseLocationLoaded = isBackup && isDatabaseLocation;
            state.isDatabaseLoaded = true;
        },
    }));

    const { urlToRedirect } = useUrlUserPermission();
    const { push } = useHistory();

    useEffect(() => {
        const includeUrl = TABS.filter((tab) => (tab.disableByRole ? !tab.disableByRole(authStore.role) : true)).map(
            (tab) => tab.value
        );

        const path = includeUrl.find((url) => url === location.pathname);

        if ((location.pathname === URL_SLINGSHOT_ARCHIVED && authStore.role === ADMIN) || path) return;

        push(urlToRedirect);
    }, [location.pathname]);

    useEffect(() => {
        state.fetchData();
    }, []);

    const hasBackupShare = backupSettingsStore.backup?.location?.share;
    const hasServiceFailed = systemComponentsStore.isComponentsServiceFailed;
    const hasDatabaseShare = postgresStore.databaseLocation?.share;

    const showBackupSettingsWarning = !hasBackupShare;
    const showDatabaseLocation = !hasDatabaseShare && hasBackupShare && !hasServiceFailed;
    const showSystemServiceWarning = state.isDatabaseLocationLoaded && hasServiceFailed;
    const showSlingshot = hasDatabaseShare && hasBackupShare && !hasServiceFailed;

    const shortcutName = location.pathname !== URL_SLINGSHOT_ARCHIVED ? getShortcutName(location.pathname) : null;

    return (
        <MainTemplate
            tabIndex={0}
            headerTitle={<HeaderTitle icon={<SlingshotIcon />} title={t("slingshot_page.title")} shortcutName={shortcutName} />}
        >
            {state.isDatabaseLoaded ? (
                <React.Fragment>
                    {showBackupSettingsWarning ? (
                        <Grid item xs={6}>
                            <BackupSettingsWarningCard />
                        </Grid>
                    ) : null}
                    {showDatabaseLocation ? (
                        <Grid item xs={6}>
                            <DatabaseLocationCard />
                        </Grid>
                    ) : null}
                    {showSlingshot ? (
                        <Grid container direction={"column"} justify={"flex-start"} alignContent={"flex-start"}>
                            <SlingshotHeaderTabs
                                className={classes.headerTabs}
                                tabs={TABS.map((tab) => ({ ...tab, label: t(tab.label) }))}
                            />
                            <Grid item container>
                                <Switch>
                                    <Route exact path={URL_SLINGSHOT}>
                                        <ReplicationsTabContent parentId={SLINGSHOT_PAGE_ID_PREFIX} />
                                    </Route>
                                    <Route exact path={URL_SLINGSHOT_AUTOMATIONS}>
                                        <AutomationsTabContent parentId={SLINGSHOT_PAGE_ID_PREFIX} />
                                    </Route>
                                    <Route path={URL_SLINGSHOT_SETTINGS_TAB}>
                                        <SettingsTabContent parentId={SLINGSHOT_PAGE_ID_PREFIX} />
                                    </Route>
                                    <ViewByRole roles={ADMIN}>
                                        <Route exact path={URL_SLINGSHOT_INDEX}>
                                            <IndexTabContent parentId={SLINGSHOT_PAGE_ID_PREFIX} />
                                        </Route>
                                        <Route exact path={URL_SLINGSHOT_ARCHIVED}>
                                            <ArchivedTabContent parentId={SLINGSHOT_PAGE_ID_PREFIX} />
                                        </Route>
                                    </ViewByRole>
                                </Switch>
                            </Grid>
                        </Grid>
                    ) : null}
                    {showSystemServiceWarning ? (
                        <Grid item xs={6}>
                            <SystemServiceWarningDialog />
                        </Grid>
                    ) : null}
                </React.Fragment>
            ) : (
                <EmptyCardPlaceholder inProgress={true} />
            )}
        </MainTemplate>
    );
};

export default observer(Slingshot);
