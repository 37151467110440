import { types } from "mobx-state-tree";

import Request from "api/Request";
import DisableLdapArguments from "api/security/users/Types/DisableLdapArguments";

const DisableLdap = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Users",
    method: "disable_ldap",
    arguments: types.maybe(DisableLdapArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default DisableLdap;
