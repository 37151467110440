import React, { useContext, useEffect } from "react";
import { Context } from "store";
import { useLocalStore, useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, CircularProgress } from "@material-ui/core";
import MainTemplate from "components/MainTemplate";
import HeaderTitle from "components/HeaderTitle";
import OptionsAdminIcon from "assets/OptionsAdminIcon";
import { AUDIT } from "const/shortcutNameConst";
import AuditSettingsCard from "./components/AuditSettingsCard";
import AuditPerShareSettingsCard from "./components/AuditPerShareSettingsCard";
import LimitationCard from "./components/LimitationCard";
import EmptyCard from "./components/EmptyCard";

const Audit = () => {
    const { t } = useTranslation();
    const {
        store: { auditStore, shareStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        inProgress: true
    }));

    useEffect(() => {
        state.inProgress = true;
        (async () => {
            await shareStore.fetchSharesTable();
            await auditStore.fetchAuditSettings();
            state.inProgress = false;
        })();
    }, []);

    return useObserver(() => (
        <MainTemplate headerTitle={<HeaderTitle icon={<OptionsAdminIcon />} title={t("audit.title")} shortcutName={AUDIT} />}>
            {state.inProgress ? (
                <Grid container alignItems={"center"} justify={"center"}>
                    <Grid item>
                        <CircularProgress size={40} />
                    </Grid>
                </Grid>
            ) : (
                <>
                    {auditStore.auditSettingsList && auditStore.auditSettingsList.enabled ? (
                        <Grid container spacing={6}>
                            <Grid item container direction={"column"} spacing={6} xs={12} lg={6}>
                                <Grid item>
                                    <AuditSettingsCard />
                                </Grid>
                                <Grid item>
                                    <AuditPerShareSettingsCard />
                                </Grid>
                            </Grid>
                            <Grid item container direction={"column"} spacing={6} xs={12} lg={6}>
                                <Grid item>
                                    <LimitationCard />
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container direction={"column"} spacing={6}>
                            <Grid item>
                                <EmptyCard inProgress={state.inProgress} />
                            </Grid>
                            <Grid item>
                                <LimitationCard />
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </MainTemplate>
    ));
};
export default Audit;
