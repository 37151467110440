export const removeExtraSlashesFromFolderName = (name) => {
    const slashes = ["/", "\\"];

    const res = name
        .split("")
        .reduce((acc, symbol, idx) => {
            if (
                (slashes.some((slash) => slash === symbol) && slashes.some((slash) => slash === acc[acc.length - 1])) ||
                ((idx === name.length - 1 || idx === 0) && slashes.some((slash) => slash === symbol))
            ) {
                return acc;
            }

            return [...acc, symbol];
        }, [])
        .filter((symbol, idx, arr) => {
            if ([0, arr.length - 1].some((index) => index === idx) && slashes.some((slash) => slash === symbol)) {
                return false;
            }

            return true;
        })
        .join("");

    return res;
};
