import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import Select from "components/MobxForm/Select";
import createForm from "utils/createForm";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowTextInput from "components/RowTextInput";
import { TrashSettingsConstants, clearIntervalToTrKey } from "const/trashSettingsConst";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import { ADVANCED_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const NAS_RECYCLE_BIN_DIALOG_ID_PREFIX = `${ADVANCED_PAGE_ID_PREFIX}_nas_recycle_bin_dialog`;

const NasRecycleBinDialog = ({ open, closeDialog }) => {
    const { t } = useTranslation();
    const {
        store: { recycleBinStore }
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields(recycleBinStore.trashSettings) }),
        isSubmitted: false
    }));
    const unsavedChangesModal = useModal();

    const updateForm = () => {
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.form.$("clearInterval").set("default", recycleBinStore.trashSettings?.clearInterval || "");
        state.form.reset();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted === true) {
            closeDialog();
            return;
        }
        unsavedChangesModal.open();
    };

    const submit = async () => {
        const res = await recycleBinStore.saveTrashSettings(state.form.values());

        if (res) {
            state.isSubmitted = true;
        }

        return res;
    };

    return useObserver(() => (
        <Dialog
            isDataChanged={!state.form.isDefault}
            onEnter={updateForm}
            title={t("advanced.nas_recycle_bin.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${NAS_RECYCLE_BIN_DIALOG_ID_PREFIX}_save`}
            open={open}
            onSubmit={submit}
            onClose={closeIfNeeded}
            buttons={
                <Button
                    onClick={closeDialog}
                    variant={"contained"}
                    color="secondary"
                    id={`${NAS_RECYCLE_BIN_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <RowTextInput
                label={t("advanced.nas_recycle_bin.label.purge")}
                control={
                    <Select
                        options={Object.values(TrashSettingsConstants.ClearInterval).map(val => {
                            return { value: val, label: t(clearIntervalToTrKey(val)) };
                        })}
                        field={state.form.$("clearInterval")}
                        id={`${NAS_RECYCLE_BIN_DIALOG_ID_PREFIX}_purge`}
                    />
                }
            />

            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default NasRecycleBinDialog;
