import React from "react";
import { observer, useLocalStore } from "mobx-react";
import Dialog from "components/Dialog";
import { Button, Grid, Typography, Paper, CardMedia } from "@material-ui/core";
import i18next from "i18next";
import FileUpload from "components/FileUpload/FileUpload";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import { useUploadWatermarkImageModal } from "hooks/modals";
import { useStore } from "hooks/useStore";
import { useStyles } from "./UploadWatermarkImageDialog.styles";

const SIZE_LIMIT_MIB = 20;
const SIZE_LIMIT = SIZE_LIMIT_MIB * 1024 * 1024;
const SIZE_LIMIT_STRING = `${SIZE_LIMIT_MIB} MiB`;

const UPLOAD_WATERMARK_IMAGE_DIALOG_ID_PREFIX = `upload_watermark_image_dialog`;

const UploadWatermarkImageDialog = ({ parentId }) => {
    const idPrefix = parentId
        ? `${parentId}_${UPLOAD_WATERMARK_IMAGE_DIALOG_ID_PREFIX}`
        : UPLOAD_WATERMARK_IMAGE_DIALOG_ID_PREFIX;
    const classes = useStyles();
    const modal = useUploadWatermarkImageModal();
    const {
        store: { slingshotSettingsStore, authStore },
    } = useStore();

    const state = useLocalStore(() => ({
        file: null,
        src: null,
        isLoadImageError: false,
        fileValidationInfo: false,
        needShowEmptyError: false,
        loading: false,
        inProgressGetImage: false,
        setFileValidationInfo(fileValidationInfo) {
            state.fileValidationInfo = fileValidationInfo;
        },
    }));

    const handleFile = async (file) => {
        state.isLoadImageError = false;
        state.file = file;
        state.src = URL.createObjectURL(file);
    };

    const onSubmit = async () => {
        state.needShowEmptyError = true;
        if (!state.fileValidationInfo.isValid) return;
        const id = slingshotSettingsStore.currentWatermark.id;
        await slingshotSettingsStore.uploadWatermarkImage(id, state.file);
    };

    const removeFileUpload = () => {
        state.isLoadImageError = false;
        state.file = null;
        state.src = null;
    };

    const onEnter = async () => {
        const id = slingshotSettingsStore.currentWatermark.id;
        state.file = null;
        state.src = null;
        state.isLoadImageError = false;
        slingshotSettingsStore.resetWatermarkImage();
        state.inProgressGetImage = true;
        state.needShowEmptyError = false;
        await slingshotSettingsStore.getWatermarkImage({ watermarkId: id });
    };

    const onLoad = () => {
        state.inProgressGetImage = false;
    };

    const onError = () => {
        state.inProgressGetImage = false;
        state.isLoadImageError = true;
        state.needShowEmptyError = false;
    };

    const getPlaceholderText = () => {
        if (state.isLoadImageError) {
            return i18next.t("slingshot_page.tabs.settings.watermarks.upload_image__dialog.image_card.error");
        }
        if (slingshotSettingsStore.isWatermarkImageError) {
            return i18next.t("slingshot_page.tabs.settings.watermarks.upload_image__dialog.image_card.could_not_load");
        }
        return i18next.t("slingshot_page.tabs.settings.watermarks.upload_image__dialog.image_card.empty");
    };

    const hasImageExistsAndLoaded = () => {
        return (
            ((slingshotSettingsStore.watermarkImage && !slingshotSettingsStore.isWatermarkImageError) || state.src) &&
            !state.isLoadImageError
        );
    };

    return (
        <Dialog
            onClose={modal.close}
            open={modal.isOpen}
            title={i18next.t("slingshot_page.tabs.settings.watermarks.upload_image__dialog.title", {
                name: slingshotSettingsStore.currentWatermarkName,
            })}
            submitBtnLabel={i18next.t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            onSubmit={onSubmit}
            maxWidth={"md"}
            onEnter={onEnter}
            buttons={
                <Button onClick={modal.close} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {i18next.t("common.button.cancel")}
                </Button>
            }
            omitSubmit={!authStore.isAdmin}
        >
            <Grid container spacing={2}>
                {authStore.isAdmin && (
                    <Grid item container alignItems={"center"}>
                        <Grid item xs={4}>
                            <Typography>
                                {i18next.t("slingshot_page.tabs.settings.watermarks.upload_image__dialog.file_load")}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FileUpload
                                file={state.file}
                                loading={state.loading}
                                handleFile={handleFile}
                                inputProps=".png"
                                regExpExtension={/\.png/}
                                sizeLimit={[SIZE_LIMIT, SIZE_LIMIT_STRING]}
                                noEmpty
                                needShowEmptyError={state.needShowEmptyError}
                                title={i18next.t("slingshot_page.tabs.settings.watermarks.upload_image__dialog.drag_grop")}
                                removeFile={removeFileUpload}
                                onValid={state.setFileValidationInfo}
                                id={`${idPrefix}_file_upload`}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        {state.src
                            ? i18next.t("slingshot_page.tabs.settings.watermarks.upload_image__dialog.new_image")
                            : i18next.t("slingshot_page.tabs.settings.watermarks.upload_image__dialog.current_image")}
                    </Grid>
                    <Grid item xs={8}>
                        <Paper className={hasImageExistsAndLoaded() && classes.previewContainer} elevation={2}>
                            {hasImageExistsAndLoaded() ? (
                                <CardMedia
                                    onLoad={onLoad}
                                    onError={onError}
                                    className={classes.watermarkImage}
                                    height={200}
                                    component={"img"}
                                    src={state.src ? state.src : slingshotSettingsStore.watermarkImage}
                                />
                            ) : (
                                <EmptyCardPlaceholder
                                    inProgress={state.inProgressGetImage && !slingshotSettingsStore.isWatermarkImageError}
                                    height={200}
                                    message={getPlaceholderText()}
                                />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default observer(UploadWatermarkImageDialog);
