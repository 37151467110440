import { useModalStore } from "hooks/useModalStore";

const MUPTIPLE_EVO_CONFIGURATION = "MUPTIPLE_EVO_CONFIGURATION";
export const useMuptipleEvoConfigurationModal = () => useModalStore(MUPTIPLE_EVO_CONFIGURATION);

const LOGIN_TO_NEW_EVO_MODAL = "LOGIN_TO_NEW_EVO_MODAL";
export const useLoginToNewEvoModal = () => useModalStore(LOGIN_TO_NEW_EVO_MODAL);

const CREATE_JOB_MODAL = "CREATE_JOB_MODAL";
export const useCreateJobModal = () => useModalStore(CREATE_JOB_MODAL);

const QUICK_CREATE = "QUICK_CREATE";
export const useQuickCreateModal = (options) => useModalStore(QUICK_CREATE, options);

const WARNING_DIALOG = "WARNING_DIALOG";
export const useWarningDialog = () => useModalStore(WARNING_DIALOG);

const CHANGE_OWNER_MODAL = "CHANGE_OWNER_MODAL";
export const useChangeOwnerModal = () => useModalStore(CHANGE_OWNER_MODAL);

const EDIT_ACL_MODAL = "EDIT_ACL_MODAL";
export const useEditAclModal = () => useModalStore(EDIT_ACL_MODAL);

const ASSIGN_SHARES = "ASSIGN_SHARES";
export const useAssignSharesModal = (options) => useModalStore(ASSIGN_SHARES, options);

const PROXY_GENERATION_SETTINGS = "PROXY_GENERATION_SETTINGS";
export const useProxyGenerationSettings = (options) => useModalStore(PROXY_GENERATION_SETTINGS, options);

const INSTANCES_MODAL = "INSTANCES_MODAL";
export const useInstancesModal = (options) => useModalStore(INSTANCES_MODAL, options);

const INSTANCES_PROCESSED_FILES_MODAL = "INSTANCES_PROCESSED_FILES_MODAL";
export const useProcessedFilesModal = (options) => useModalStore(INSTANCES_PROCESSED_FILES_MODAL, options);

const INSTANCES_PROCESSED_TASKS_MODAL = "INSTANCES_PROCESSED_TASKS_MODAL";
export const useProcessedTasksModal = (options) => useModalStore(INSTANCES_PROCESSED_TASKS_MODAL, options);

const ADD_WATERMARK_MODAL = "ADD_WATERMARK_MODAL";
export const useAddWatermarkModal = (options) => useModalStore(ADD_WATERMARK_MODAL, options);

const EDIT_WATERMARK_MODAL = "EDIT_WATERMARK_MODAL";
export const useEditWatermarkModal = (options) => useModalStore(EDIT_WATERMARK_MODAL, options);

const UPLOAD_WATERMARK_IMAGE_MODAL = "UPLOAD_WATERMARK_IMAGE_MODAL";
export const useUploadWatermarkImageModal = (options) => useModalStore(UPLOAD_WATERMARK_IMAGE_MODAL, options);
