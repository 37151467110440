import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    inputRow: {
        padding: theme.spacing(4, 5),
        width: "100%",
        margin: 0
    },
    checkboxCell: {
        opacity: 1,
        "&&": {
            lineHeight: "unset"
        }
    },
    controls: {
        padding: theme.spacing(2),
        width: "100%",
        margin: 0
    }
}));
