import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetAutomations from "api/slingshot/automations/Requests/GetAutomations";
import GetAutomationsResult from "api/slingshot/automations/Responses/GetAutomationsResult";
import CreateAutomation from "api/slingshot/automations/Requests/CreateAutomation";
import UpdateAutomation from "api/slingshot/automations/Requests/UpdateAutomation";
import { SLINGSHOT_AUTOMATION_NAME, ASC, DESC } from "const/sortColumnConst";
import { AUTOMATION_REQUEST_BY_TYPE } from "const/slingshotAutomationsConst";
import PerformAutomationCommand from "api/slingshot/automations/Requests/PerformAutomationCommand";
import DeleteAutomation from "api/slingshot/automations/Requests/DeleteAutomation";

const GET_AUTOMATIONS_ARGUMENTS_DEFAULT = {
    sort: SLINGSHOT_AUTOMATION_NAME,
    sort_dir: ASC.toUpperCase(),
    page: 0,
    limit: 10,
    stats: true,
    last_instance: true
};

const SearchType = types.model({
    sort: types.string,
    sort_dir: types.string,
    page: types.number,
    limit: types.number,
    stats: types.boolean,
    last_instance: types.boolean
});

const AutomationsStore = types
    .model({
        automationsStore: types.maybe(GetAutomationsResult),
        getAutomationsArguments: types.optional(SearchType, GET_AUTOMATIONS_ARGUMENTS_DEFAULT),
        currentAutomationId: types.maybeNull(types.number),
        currentAutomationListType: types.maybe(types.string),
        errorConnectedDBus: types.optional(types.boolean, false)
    })
    .views(self => ({
        get automations() {
            return self.automationsStore?.data?.json?.automations?.filter(automation => !automation.deleted) || [];
        },
        get automationsCount() {
            return self.automationsStore?.data?.json?.total || 0;
        },
        get currentAutomation() {
            if (self.currentAutomationId) return self.automations.find(automation => automation.id === self.currentAutomationId);
            return null;
        },
        get isErrorConnectedDBus() {
            return self.errorConnectedDBus;
        }
    }))
    .actions(self => ({
        fetchAutomations: flow(function*(automationType) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const payload = {
                    sort: self.getAutomationsArguments.sort,
                    sort_dir: self.getAutomationsArguments.sort_dir,
                    offset: self.getAutomationsArguments.page * self.getAutomationsArguments.limit,
                    limit: self.getAutomationsArguments.limit,
                    stats: self.getAutomationsArguments.stats,
                    last_instance: self.getAutomationsArguments.last_instance,
                    ...AUTOMATION_REQUEST_BY_TYPE.get(automationType)
                };
                const req = GetAutomations.create().init(payload);
                const res = yield Socket.send(req);
                self.automationsStore = res;
                return true;
            } catch (e) {
                switch (e.code) {
                    case 404:
                        self.errorConnectedDBus = true;
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
            }
        }),
        createAutomation: flow(function*(payload) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = CreateAutomation.create().init(payload);
                yield Socket.send(req);
                self.fetchAutomations(self.currentAutomationListType);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateAutomation: flow(function*(payload) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = UpdateAutomation.create().init({ automation: payload });
                yield Socket.send(req);
                self.fetchAutomations(self.currentAutomationListType);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        performAutomationCommand: flow(function*({ automationId, cmd }) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = PerformAutomationCommand.create().init({ json: { automationId, cmd } });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        removeAutomation: flow(function*(automationId) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DeleteAutomation.create().init({ json: { automationId } });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        changeSorting(column) {
            if (column === self.getAutomationsArguments.sort) {
                self.getAutomationsArguments.sort_dir =
                    self.getAutomationsArguments.sort_dir === DESC.toUpperCase() ? ASC.toUpperCase() : DESC.toUpperCase();
            } else {
                self.getAutomationsArguments.sort_dir = ASC.toUpperCase();
                self.getAutomationsArguments.sort = column;
            }
        },
        updateLimit(limit) {
            self.getAutomationsArguments.limit = limit;
        },
        updatePage(page) {
            self.getAutomationsArguments.page = page;
        },
        setCurrentAutomationId(id) {
            self.currentAutomationId = id;
        },
        updateAutomationLastInstance(data) {
            const foundAutomation = self.automations.find(automation => automation.id === data.automationId);
            if (foundAutomation && foundAutomation.last_instance?.result !== data.result) {
                foundAutomation.last_instance = { id: foundAutomation?.last_instance?.id ?? 0, result: data?.result ?? "" };
            }
        },
        updateAutomationStatus(status) {
            const foundAutomation = self.automations.find(automation => automation.id === status.id);
            if (foundAutomation) {
                foundAutomation.name = status.name;
                if (status.schedule) {
                    foundAutomation.schedule = { schedule: status.schedule };
                } else {
                    delete foundAutomation.schedule;
                }
                switch (status.status) {
                    case "DELETED":
                        foundAutomation.deleted = true;
                        break;
                    case "DISABLED":
                        foundAutomation.enabled = false;
                        break;
                    case "PAUSED":
                        foundAutomation.enabled = true;
                        foundAutomation.paused = true;
                        break;
                    case "IDLE":
                        foundAutomation.enabled = true;
                        foundAutomation.paused = false;
                        foundAutomation.instances.set("IN PROGRESS", 0);
                        break;
                    case "RUNNING":
                        foundAutomation.enabled = true;
                        foundAutomation.paused = false;
                        foundAutomation.instances.set("IN PROGRESS", 1);
                        break;
                    default:
                        break;
                }
            }
        },
        setCurrentAutomationListType(type) {
            self.currentAutomationListType = type;
        },
        resetRAutomations() {
            self.automationsStore && (self.automationsStore.data = undefined);
        },
        resetErrors() {
            self.errorConnectedDBus = false;
        },
        enableErrorConnectedDBus() {
            self.errorConnectedDBus = true;
        }
    }));

export default AutomationsStore;
