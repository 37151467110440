import React from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { getSnapshot } from "mobx-state-tree";
import { useLocalStore, useObserver } from "mobx-react-lite";
import createForm from "utils/createForm";
import createFields from "./createFields";
import RowTextInput from "components/RowTextInput";
import Select from "components/MobxForm/Select";
import TextInput from "components/MobxForm/TextInput";
import { Button } from "@material-ui/core";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import SnmpServerSettings from "api/events/Types/SnmpServerSettings";
import { SECURITY_LEVEL } from "const/alertsConst";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const SNMP_SERVICE_CONFIGURATION_DIALOG_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_snmp_service_configuration_dialog`;

const SnmpServiceConfigurationDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const {
        store: { alertsStore }
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields()
        }),
        inProgress: false,
        isSubmitted: false
    }));

    const unsavedChangesModal = useModal();

    const updateForm = () => {
        state.form.$("port").set("default", alertsStore.snmpServerSettings?.port);
        state.form.$("transport").set("default", alertsStore.snmpServerTransport);
        state.form.$("securityLevel").set("default", alertsStore.snmpServerSettings?.requiredSecurityLevel);
        state.form.$("securityName").set("default", alertsStore.snmpServerSettings?.securityName);
        state.form.$("authProtocol").set("default", alertsStore.snmpServerSettings?.authProtocol);
        state.form.$("authPassword").set("default", alertsStore.snmpServerSettings?.authPassword);
        state.form.$("privacyProtocol").set("default", alertsStore.snmpServerSettings?.privProtocol);
        state.form.$("privacyPassword").set("default", alertsStore.snmpServerSettings?.privPassword);
        state.form.reset();
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };

    const onSubmit = async e => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        state.inProgress = true;
        const settings = SnmpServerSettings.create({
            port: parseInt(state.form.$("port").value),
            tcpEnabled: state.form.$("transport").value.includes(t("alerts.snmp_service_configuration_card.transport.tcp")),
            udpEnabled: state.form.$("transport").value.includes(t("alerts.snmp_service_configuration_card.transport.udp")),
            securityEnabled: true,
            securityName: state.form.$("securityName").value,
            authProtocol: state.form.$("authProtocol").value,
            authPassword: state.form.$("authPassword").value,
            privProtocol: state.form.$("privacyProtocol").value,
            privPassword: state.form.$("privacyPassword").value,
            requiredSecurityLevel: state.form.$("securityLevel").value
        });
        const res = await alertsStore.setSnmpServerSettings(getSnapshot(settings));
        state.inProgress = false;
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    const resetAuthPrivValidation = () => {
        let clearPriv = false;
        let clearAuth = false;
        if (state.form.$("securityLevel").value === SECURITY_LEVEL.AUTH_NO_PRIV) {
            clearPriv = true;
        }
        if (state.form.$("securityLevel").value === SECURITY_LEVEL.NO_AUTH_NO_PRIV) {
            clearPriv = true;
            clearAuth = true;
        }
        if (clearPriv || clearAuth) {
            if (clearPriv) {
                state.form.$("privacyPassword").set(alertsStore.snmpServerSettings?.privPassword);
                state.form.$("privacyPassword").resetValidation();
            }
            if (clearAuth) {
                state.form.$("authPassword").set(alertsStore.snmpServerSettings?.authPassword);
                state.form.$("authPassword").resetValidation();
            }
        }
    };

    resetAuthPrivValidation();

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const geTransportOptions = () => {
        return [
            t("alerts.snmp_service_configuration_card.transport.tcp_udp"),
            t("alerts.snmp_service_configuration_card.transport.udp"),
            t("alerts.snmp_service_configuration_card.transport.tcp")
        ];
    };

    const getSecurityLevelOptions = () => {
        return [SECURITY_LEVEL.NO_AUTH_NO_PRIV, SECURITY_LEVEL.AUTH_NO_PRIV, SECURITY_LEVEL.AUTH_PRIV];
    };

    const getAuthProtocolOptions = () => {
        return ["MD5", "SHA-1", "SHA-224", "SHA-256", "SHA-384", "SHA-512"];
    };

    const getPrivayProtocolOptions = () => {
        return ["DES", "AES-128", "AES-192", "AES-256"];
    };

    const securityNameKey = () => {
        return state.form.$("securityLevel").value === SECURITY_LEVEL.AUTH_NO_PRIV ||
            state.form.$("securityLevel").value === SECURITY_LEVEL.AUTH_PRIV
            ? "alerts.snmp_service_configuration_card.security_name"
            : "alerts.snmp_service_configuration_card.comunity_security_name";
    };

    return useObserver(() => (
        <Dialog
            onClose={closeIfNeeded}
            open={open}
            title={t("alerts.snmp_service_configuration_dialog.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${SNMP_SERVICE_CONFIGURATION_DIALOG_ID_PREFIX}_save`}
            inProgress={state.inProgress}
            onSubmit={onSubmit}
            onEnter={updateForm}
            buttons={
                <Button
                    onClick={onClose}
                    variant={"contained"}
                    color="secondary"
                    id={`${SNMP_SERVICE_CONFIGURATION_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <RowTextInput
                label={t("alerts.snmp_service_configuration_card.port")}
                control={<TextInput field={state.form.$("port")} id={`${SNMP_SERVICE_CONFIGURATION_DIALOG_ID_PREFIX}_port`} />}
            />
            <RowTextInput
                label={t("alerts.snmp_service_configuration_card.transport")}
                control={
                    <Select
                        field={state.form.$("transport")}
                        options={geTransportOptions()}
                        id={`${SNMP_SERVICE_CONFIGURATION_DIALOG_ID_PREFIX}_transport`}
                    />
                }
            />
            <RowTextInput
                label={t("alerts.snmp_service_configuration_card.security_level")}
                control={
                    <Select
                        field={state.form.$("securityLevel")}
                        options={getSecurityLevelOptions()}
                        id={`${SNMP_SERVICE_CONFIGURATION_DIALOG_ID_PREFIX}_security_level`}
                    />
                }
            />
            <RowTextInput
                label={t(securityNameKey())}
                control={
                    <TextInput
                        field={state.form.$("securityName")}
                        id={`${SNMP_SERVICE_CONFIGURATION_DIALOG_ID_PREFIX}_security_name`}
                    />
                }
            />
            {(state.form.$("securityLevel").value === SECURITY_LEVEL.AUTH_PRIV ||
                state.form.$("securityLevel").value === SECURITY_LEVEL.AUTH_NO_PRIV) && (
                <>
                    <RowTextInput
                        label={t("alerts.snmp_service_configuration_card.auth_protocol")}
                        control={
                            <Select
                                field={state.form.$("authProtocol")}
                                options={getAuthProtocolOptions()}
                                id={`${SNMP_SERVICE_CONFIGURATION_DIALOG_ID_PREFIX}_auth_protocol`}
                            />
                        }
                    />

                    <RowTextInput
                        label={t("alerts.snmp_service_configuration_card.auth_password")}
                        control={
                            <TextInput
                                field={state.form.$("authPassword")}
                                id={`${SNMP_SERVICE_CONFIGURATION_DIALOG_ID_PREFIX}_auth_password`}
                            />
                        }
                    />
                </>
            )}
            {state.form.$("securityLevel").value === SECURITY_LEVEL.AUTH_PRIV && (
                <>
                    <RowTextInput
                        label={t("alerts.snmp_service_configuration_card.privacy_protocol")}
                        control={
                            <Select
                                field={state.form.$("privacyProtocol")}
                                options={getPrivayProtocolOptions()}
                                id={`${SNMP_SERVICE_CONFIGURATION_DIALOG_ID_PREFIX}_privacy_protocol`}
                            />
                        }
                    />
                    <RowTextInput
                        label={t("alerts.snmp_service_configuration_card.privacy_password")}
                        control={
                            <TextInput
                                field={state.form.$("privacyPassword")}
                                id={`${SNMP_SERVICE_CONFIGURATION_DIALOG_ID_PREFIX}_privacy_password`}
                            />
                        }
                    />
                </>
            )}
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};
export default SnmpServiceConfigurationDialog;
