import { getParent } from "mobx-state-tree";

export const getEvoPrefix = ({ ip, evoName, hostname, store }) => {
    const { multipleEvosStore } = store ? getParent(store) : {};

    if (ip) {
        return evoName ? `${evoName}: ` : "";
    }

    if (hostname && multipleEvosStore && multipleEvosStore?.authorizedConnectionsCount) {
        return hostname ? `${hostname}: ` : "";
    }

    return "";
};
