import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => ({
    row: {
        marginBottom: theme.spacing(2),
        flexWrap: "nowrap",
    },
    rowLabel: {
        paddingRight: theme.spacing(10.5),
    },
    checkbox: {
        marginLeft: "auto",
    },
    expansionPanelContainer: {
        margin: theme.spacing(4, 0),
    },
    expansionPanel: {
        "&&": {
            boxShadow: "none",
            border: `${theme.palette.expandPanel.border.color} 1px solid`,
            borderRadius: theme.spacing(2),
            padding: theme.spacing(3),
            margin: theme.spacing(0, -3),
        },
    },
    expansionIconClose: {
        transform: "rotate(180deg)",
    },
    expansionIcon: {
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.1),
        borderRadius: "50%",
    },
    expansionLabel: {
        fontSize: 16,
        fontWeight: 500,
        opacity: 0.8,
    },
    expansionPanelContent: {
        "&&": {
            display: "flex",
            minHeight: 0,
            margin: 0,
            padding: 0,
            justifyContent: "space-between",
        },
    },
    detailsRoot: {
        padding: theme.spacing(3, 0, 2),
    },
    allowContainer: {
        paddingBottom: theme.spacing(7),
    },
}));
