import { types } from "mobx-state-tree";
const SORT_ENUM_ARRAY = ["ID", "STARTED", "FINISHED", "STATUS"];
const SORT_DIR_ENUM_ARRAY = ["ASC", "DESC"];

const GetArchivedInstancesArguments = types.model({
    sort: types.maybe(types.enumeration(SORT_ENUM_ARRAY)),
    sort_dir: types.maybe(types.enumeration(SORT_DIR_ENUM_ARRAY)),
    offset: types.maybe(types.number),
    limit: types.maybe(types.number),
    stats: types.maybe(types.boolean)
});

export default GetArchivedInstancesArguments;
