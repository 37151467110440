import { types } from "mobx-state-tree";

import ShareEditInfo from "api/share/Types/ShareEditInfo";

const GetShareInfoResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(ShareEditInfo)
});

export default GetShareInfoResult;
