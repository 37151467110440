import { types } from "mobx-state-tree";

import Request from "api/Request";
import IdentifyOnArguments from "api/system_drives/Types/IdentifyOnArguments";

const IdentifyOn = Request.props({
    path: "/com/studionetworksolutions/evo/SystemDrives",
    method: "identify_on",
    arguments: types.maybe(IdentifyOnArguments),
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default IdentifyOn;
