import isFieldEmpty from "utils/isFieldEmptyRule";
import i18n from "i18n";

const createFields = () => {
    return [
        {
            name: "username",
            validators: [isFieldEmpty(i18n.t("common.label.username"))],
        },
        {
            name: "password",
            type: "password",
            validators: [isFieldEmpty(i18n.t("common.label.password"))],
        },
        {
            name: "ip",
            validators: [isFieldEmpty(i18n.t("common.label.field"))],
        },
    ];
};

export default createFields;
