import React from "react";
import { useObserver } from "mobx-react";
import Dialog from "components/Dialog";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const EDIT_WARNING_DIALOG_ID_PREFIX = `edit_warning_dialog`;

const EditWarningDialog = ({ open, onClose, onDisableAndEdit, onViewOnly, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${EDIT_WARNING_DIALOG_ID_PREFIX}` : EDIT_WARNING_DIALOG_ID_PREFIX;
    const { t } = useTranslation();

    return useObserver(() => (
        <Dialog
            title={t("slingshot_page.tabs.replications.edit_job_confirmation.title")}
            open={open}
            onClose={onClose}
            withoutIconClose
            withoutDividers
            submitBtn={
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onDisableAndEdit}
                    id={`${idPrefix}_button_disable_and_edit_job`}
                >
                    {t("slingshot_page.tabs.replications.edit_job_confirmation.button.disable_and_edit")}
                </Button>
            }
            buttons={
                <>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClose}
                        id={`${idPrefix}_button_cancel_on_edit_job_confirmation_dialog`}
                    >
                        {t("common.button.cancel")}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onViewOnly} id={`${idPrefix}_button_view_only_job`}>
                        {t("slingshot_page.tabs.replications.edit_job_confirmation.button.view_only")}
                    </Button>
                </>
            }
        >
            <Typography>{t("slingshot_page.tabs.replications.edit_job_confirmation.message")}</Typography>
        </Dialog>
    ));
};

export default EditWarningDialog;
