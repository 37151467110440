import React from "react";

const UpsIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#FFF"
                fillRule="evenodd"
                d="M11.634 3.843l-4.373 9.185c-.178.374-.02.821.354 1 .101.047.211.072.323.072h3.166c.414 0 .75.336.75.75 0 .044-.004.088-.011.132l-.973 5.443c-.036.204.1.399.304.435.155.028.31-.044.39-.18l5.027-8.602c.209-.357.088-.817-.27-1.026-.114-.067-.245-.102-.378-.102h-3.815c-.414 0-.75-.336-.75-.75 0-.04.004-.079.01-.118l.955-6.019c.033-.204-.107-.397-.311-.429-.165-.026-.326.06-.398.21z"
            />
        </svg>
    );
};

export default UpsIcon;
