import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";
import { OPENED_SIDE_MENU_WIDTH, SIDE_MENU_TAB_WIDTH, SIDE_MENU_TAB_HEIGHT } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    container: {
        "&&": {
            width: OPENED_SIDE_MENU_WIDTH,
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            padding: theme.spacing(4, 4, 2, 4),
            height: "initial",
            boxSizing: "border-box"
        }
    },
    tab: {
        textAlign: "center",
        "&&": {
            width: SIDE_MENU_TAB_WIDTH,
            height: SIDE_MENU_TAB_HEIGHT,
            borderRadius: theme.spacing(1),
            color: theme.palette.text.primary,
            padding: 0,
            marginBottom: theme.spacing(2),
            flex: "initial",
            "& .MuiBottomNavigationAction-wrapper": {
                height: "inherit",
                justifyContent: "space-evenly"
            },
            "& svg path": {
                fill: theme.palette.grey["300"]
            },
            "&.Mui-selected": {
                color: theme.palette.primary.main,
                backgroundColor: hexToRgba(theme.palette.primary.main, 0.05),
                padding: 0,
                "& svg path": {
                    fill: theme.palette.primary.main
                }
            },
            "& .MuiBottomNavigationAction-label": {
                fontSize: "12px",
                fontWeight: 500,
                width: "100%",
                boxSizing: "border-box",
                padding: theme.spacing(0, 1),
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                opacity: 0.8,
                "&.Mui-selected": {
                    opacity: 1
                }
            },
            "&:hover": {
                "& .MuiBottomNavigationAction-label": {
                    opacity: 1
                }
            }
        }
    },
    homeTab: {
        "&& svg path": {
            fill: theme.palette.success.main
        }
    },
    addShortcutTab: {
        "&& svg path": {
            fill: theme.palette.primary.main
        }
    }
}));
