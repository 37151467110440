import { types } from "mobx-state-tree";

const ConnectionInfo = types.model({
    connectionName: types.string,
    protocol: types.string,
    endpointLink: types.string,
    endpoint: types.string,
    localIP: types.string,
    connectionState: types.string,
    autostart: types.boolean
});

export default ConnectionInfo;
