import React from "react";
import { useStore } from "hooks/useStore";
import createFields from "./createFields";
import createForm from "utils/createForm";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import { PEER_LOOKUP_MANUAL } from "const/clusterConst";
import { useModal } from "hooks/useModal";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";

const EditPeerDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const {
        store: { clusterStore }
    } = useStore();

    const unsavedChangesModal = useModal();

    const fields = createFields();
    const state = useLocalStore(() => ({
        inProgress: false,
        radioValue: PEER_LOOKUP_MANUAL,
        form: createForm({
            fields
        })
    }));

    const onDialogOpen = () => {
        state.inProgress = false;
        unsavedChangesModal.close();
        state.form.$("peerName").set("default", clusterStore.currentPeer?.alias);
        state.form.$("ip").set("default", clusterStore.currentPeer?.ip);
        state.form.reset();
    };

    const closeModal = () => {
        state.form.reset();
        onClose();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            closeModal();
            return;
        }
        unsavedChangesModal.open();
    };

    const onSubmit = async () => {
        state.inProgress = true;
        const isSubmitted = await clusterStore.editPeer({
            name: state.form.$("peerName").value,
            ip: state.form.$("ip").value
        });
        state.inProgress = false;

        if (isSubmitted) {
            state.isSubmitted = true;
        }

        return isSubmitted;
    };

    return useObserver(() => (
        <Dialog
            onEnter={onDialogOpen}
            title={t("cluster.edit_peer.title")}
            open={open}
            onSubmit={onSubmit}
            onClose={closeIfNeeded}
            isDataChanged={!state.form.isDefault}
            submitBtnLabel={t("common.button.edit")}
            inProgress={state.inProgress}
            buttons={[
                <Button key={"cancel_button"} onClick={closeIfNeeded} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>
            ]}
        >
            <UnsavedChangesDialog onConfirm={closeModal} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            <Grid direction={"column"} container>
                <RowTextInput
                    label={t("cluster.add_peer.label.host_name")}
                    control={<TextInput disabled field={state.form.$("peerName")} />}
                />
                <RowTextInput label={t("cluster.add_peer.label.ip")} control={<TextInput field={state.form.$("ip")} />} />
            </Grid>
        </Dialog>
    ));
};

export default EditPeerDialog;
