const descendingComparator = (prevObj, nextObj, orderBy) => {
    const getValue = (obj, path) => {
        const keys = path.split(".");

        if (!keys.length) return obj;
        let lastObj = obj;
        for (var key of keys) {
            lastObj = lastObj[key];
        }

        return lastObj ?? "";
    };
    const prevVal = getValue(prevObj, orderBy);
    const nextVal = getValue(nextObj, orderBy);

    if (typeof prevVal === "string" || typeof nextVal === "string") {
        return nextVal?.localeCompare(prevVal, undefined, { numeric: true, sensitivity: "base" });
    }
    if (nextVal < prevVal) {
        return -1;
    }
    if (nextVal > prevVal) {
        return 1;
    }
    return 0;
};

export default descendingComparator;
