import Request from "api/Request";

const GetRaidControllersInfo = Request.props({
    path: "/com/studionetworksolutions/evo/status/Info",
    method: "get_raid_controllers_info"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetRaidControllersInfo;
