import { types } from "mobx-state-tree";

const Peer = types.model({
    name: types.string,
    alias: types.string,
    ip: types.string,
    extendedStatus: types.string,
    status: types.string
});

export default Peer;
