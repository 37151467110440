import { types } from "mobx-state-tree";

import Request from "api/Request";
import DeleteUserArguments from "api/security/users/Types/DeleteUserArguments";

const DeleteUser = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Users",
    method: "delete_user",
    arguments: types.maybe(DeleteUserArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default DeleteUser;
