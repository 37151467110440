import React from "react";

const ChevronUpIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                d="M15.29 14.68L12 11.37l-3.3 3.18c-.39.388-1.02.388-1.41 0-.392-.389-.395-1.022-.006-1.414l.006-.006 4-3.86c.389-.381 1.011-.381 1.4 0l4 4c.392.389.395 1.022.006 1.414l-.006.006c-.185.179-.433.28-.69.28-.266.002-.521-.103-.71-.29z"
            />
        </svg>
    );
};

export default ChevronUpIcon;
