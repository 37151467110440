import { types, flow, getParent } from "mobx-state-tree";
import MultipleEvosRootStore from "store/MultipleEvosRootStore";

const ExternalShareDrawerStore = types
    .model({})
    .views((self) => {
        const { externalSharesStore } = getParent(self);
        const multipleStores = MultipleEvosRootStore.stores;
        return {
            get allCheckedSharesNames() {
                let shares = [...externalSharesStore.checkedSharesNamesWithPrefix];
                multipleStores.forEach(
                    (store) => (shares = [...shares, ...store.externalSharesStore.checkedSharesNamesWithPrefix])
                );
                return shares;
            },
            get _allCheckedSharesNames() {
                let shares = [...externalSharesStore.checkedSharesNames];
                multipleStores.forEach((store) => (shares = [...shares, ...store.externalSharesStore.checkedSharesNames]));
                return shares;
            },
            get checkedSharesCount() {
                return self.allCheckedSharesNames.length;
            },
            get currentShareName() {
                const storeWithCurrentShareName = [...multipleStores]
                    .map(([, value]) => value)
                    .find((store) => store.externalSharesStore.currentShareName);

                return (
                    externalSharesStore.currentShareName ||
                    storeWithCurrentShareName?.externalSharesStore.currentShareName ||
                    null
                );
            },
            get currentShareNameWithPrefix() {
                const storeWithCurrentShareName = [...multipleStores]
                    .map(([, value]) => value)
                    .find((store) => store.externalSharesStore.currentShareNameWithPrefix);

                return (
                    externalSharesStore.currentShareNameWithPrefix ||
                    storeWithCurrentShareName?.externalSharesStore.currentShareNameWithPrefix ||
                    null
                );
            },
            get currentStoreWithShare() {
                const externalSharesStoreContainCurrentShare =
                    externalSharesStore.currentShareNameWithPrefix === self.currentShareNameWithPrefix;
                return (
                    [...multipleStores]
                        .map(([, value]) => value)
                        .find((store) => store.externalSharesStore.currentShareNameWithPrefix)?.externalSharesStore ||
                    (externalSharesStoreContainCurrentShare && externalSharesStore)
                );
            },
            get externalSharesStoresWithCheckedShares() {
                const stores = [];
                if (externalSharesStore.checkedSharesCount > 0) {
                    stores.push(externalSharesStore);
                }
                [...multipleStores]
                    .map(([, value]) => value)
                    .forEach(
                        (store) => store.externalSharesStore.checkedSharesCount > 0 && stores.push(store.externalSharesStore)
                    );
                return stores;
            },
            get needToSetSharesOffline() {
                return self.externalSharesStoresWithCheckedShares.some((store) => store.needToSetSharesOffline);
            },
        };
    })
    .actions((self) => {
        const { externalSharesStore } = getParent(self);
        const multipleStores = MultipleEvosRootStore.stores;
        return {
            setCheckedSharesOnline: flow(function* () {
                const { processingStore } = getParent(self);
                try {
                    processingStore.setLoading(true);

                    yield Promise.all(
                        self.externalSharesStoresWithCheckedShares.map((store) =>
                            store.setCheckedSharesOnline(self.needToSetSharesOffline)
                        )
                    );
                    return true;
                } catch (e) {
                    //handled in externalSharesStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            removeCheckedShares: flow(function* () {
                const { processingStore } = getParent(self);
                try {
                    processingStore.setLoading(true);

                    yield Promise.all(self.externalSharesStoresWithCheckedShares.map((store) => store.removeCheckedShares()));
                    return true;
                } catch (e) {
                    //handled in externalSharesStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            dropOthersCurrentShareNames: (ip) => {
                if (ip) {
                    externalSharesStore.setCurrentShareName(null);
                    multipleStores.forEach((store) => store.ip !== ip && store.externalSharesStore.setCurrentShareName(null));
                } else {
                    multipleStores.forEach((store) => store.externalSharesStore.setCurrentShareName(null));
                }
            },
            dropAllCurrentShareNames: () => {
                externalSharesStore.setCurrentShareName(null);
                multipleStores.forEach((store) => store.externalSharesStore.setCurrentShareName(null));
            },
            dropAllCheckedShares: () => {
                externalSharesStore.clearCheckedShares();
                multipleStores.forEach((store) => store.externalSharesStore.clearCheckedShares());
            },
        };
    });

export default ExternalShareDrawerStore;
