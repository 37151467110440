/* eslint-disable no-console */
import {
    EXTERNAL_SHARE_STATUS_CHANGED_EVENT,
    SECONDARY_ADMINS_LIST_CHANGED_EVENT,
    EXCLUDE_TYPES_SECONDARY_ADMIN,
    EXCLUDE_TYPES_USER,
    SHARES_TABLE_LOADED_EVENT,
    NAME_OWNER_CHANGED_EVENT,
    ACL_MIGRATION_PROGRESS_CHANGED_EVENT,
    MAIN_STEP_PROGRESS_CHANGE_EVENT,
} from "const/typesBroadcast";
import { ADMIN } from "const/userRolesConst";
import { types, getParent } from "mobx-state-tree";
import defender from "utils/defendBroadcast";

import Socket from "websocket";

const EventStore = types.model({}).actions((self) => ({
    listen() {
        Socket.onbroadcast = defender(
            self,
            (e) => {
                const {
                    broadcast,
                    path,
                    type,
                    context: { sequenceId },
                } = JSON.parse(e.data);
                /** @type {Store} */
                const {
                    notificationStore,
                    taskStore,
                    volumeStore,
                    poolsStore,
                    shareStore,
                    networkingSettingsStore,
                    quadActivationStore,
                    interfaceActivationStore,
                    userStore,
                    externalSharesStore,
                    supportInfoStore,
                    uiStore,
                    postgresStore,
                    driveStore,
                    systemDriveStore,
                    automationsStore,
                    timeSettingsStore,
                    evoSettingsStore,
                    alertsStore,
                    recycleBinStore,
                    wireguardStore,
                    driveReplaceStore,
                    syncJobsStore,
                    auditStore,
                    sharebrowserStore,
                    powerAndUpsStore,
                    firewallStore,
                    upgradesStore,
                    authStore,
                    systemComponentsStore,
                    pluginsStore,
                    ethernetPortsStore,
                    bondsStore,
                    bridgeStore,
                    multipleEvosStore,
                    slingshotSettingsStore,
                    indexingConfigStore,
                    advancedStore,
                    slingshotStorageStore,
                    clusterStore,
                    migrationStore,
                    longLivedTokensStore,
                } = getParent(self);
                switch (path) {
                    case "/com/studionetworksolutions/evo":
                        switch (type) {
                            case NAME_OWNER_CHANGED_EVENT:
                                Array.from(
                                    [
                                        syncJobsStore,
                                        indexingConfigStore,
                                        slingshotSettingsStore,
                                        slingshotStorageStore,
                                        automationsStore,
                                        externalSharesStore,
                                    ],
                                    (store) => (broadcast.newOwner ? store.resetErrors() : store.enableErrorConnectedDBus())
                                );
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/status/Event":
                        switch (type) {
                            case "NotificationEvent":
                                notificationStore.addNotification(broadcast);
                                if (
                                    broadcast.eventType === "reset_firewall_settings" ||
                                    broadcast.eventType === "rollback_firewall_settings" ||
                                    broadcast.eventType === "commit_firewall_settings"
                                ) {
                                    if (broadcast.eventType === "reset_firewall_settings") {
                                        firewallStore.firewallSettingsWereReset();
                                    }
                                    firewallStore.rollbackWasDisabled();
                                }
                                break;
                            case "BatchEvent":
                                notificationStore.addBatchNotification(broadcast);
                                break;
                            case "ProgressEvent":
                                notificationStore.addProgressNotification(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/status/Task":
                        switch (type) {
                            case "ProgressTask":
                                taskStore.addOrUpdateTask(broadcast, sequenceId);
                                break;
                            case "BatchTask":
                                taskStore.addOrUpdateBatchTask(broadcast, sequenceId);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Volume":
                        switch (type) {
                            case "VolumeStatusChangedEvent":
                                if (authStore.role === ADMIN) {
                                    volumeStore.changeVolumeStatus(broadcast);
                                    poolsStore.fetchPoolsByIds([broadcast.volumeId]);
                                }
                                break;
                            case "VolumeChangedEvent":
                                if (authStore.role === ADMIN) {
                                    volumeStore.changeVolume(broadcast);
                                }
                                break;
                            case "BuildQueue":
                                volumeStore.changeQueueState(broadcast);
                                break;
                            case "VolumesRemovedEvent":
                                volumeStore.removeVolumesFromStore(broadcast);
                                authStore.role === ADMIN && poolsStore.fetchPoolsByIds(broadcast.volumeIds);
                                shareStore.fetchSharesTable();
                                break;
                            case "DefragState":
                                volumeStore.updateDefragState(broadcast);
                                break;
                            case "DeferredParityCalculation":
                                volumeStore.updateDeferredParityCalculationStore(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/Nas":
                        switch (type) {
                            case "SharesRemovedEvent":
                                shareStore.removeShareFromStore(broadcast);
                                shareStore.fetchSharesTable();
                                break;
                            case "ShareStatusChangedEvent":
                                shareStore.changeShareStatus(broadcast);
                                break;
                            case "DefaultMasksBroadcast":
                                shareStore.fetchDefaultsMasks();
                                break;
                            case "NasConfiguration":
                                shareStore.updateNasConfigurationState(broadcast);
                                break;
                            case SHARES_TABLE_LOADED_EVENT:
                                shareStore.updateSharesTableStore(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/ExternalShares":
                        switch (type) {
                            case "ExternalSharesRemovedEvent":
                                externalSharesStore.removeShareFromStore(broadcast);
                                break;
                            case EXTERNAL_SHARE_STATUS_CHANGED_EVENT:
                                externalSharesStore.changeShareStatus(broadcast.share);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/activation/Quad":
                        switch (type) {
                            case "QuadActivation":
                                quadActivationStore.updateQuadState(broadcast);
                                quadActivationStore.fetchQuadActivationsCount();
                                break;
                            case "MissingQuadDeactivatedInfo":
                                quadActivationStore.removeMissingQuadFromStore(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/activation/NetInterfaces":
                        switch (type) {
                            case "ActivationChangedEvent":
                                interfaceActivationStore.clearLoadingInterfaces();
                                interfaceActivationStore.fetchActivationInterfaces();
                                interfaceActivationStore.fetchAvailableActivationsCount();
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/SupportInfo":
                        switch (type) {
                            case "EvoIdentity":
                                supportInfoStore.fetchIdentity();
                                break;
                            case "Schedule":
                                supportInfoStore.fetchSchedule();
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Users":
                        switch (type) {
                            case "ActiveDirectoryStatus":
                                userStore.updateActiveDirectoryStatus(broadcast);
                                userStore.searchOnUsersPage();
                                break;
                            case "LdapStatus":
                                userStore.updateLdapStatus(broadcast);
                                userStore.searchOnUsersPage();
                                break;
                            case SECONDARY_ADMINS_LIST_CHANGED_EVENT:
                                userStore.updateSecondaryAdmins(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/UiSettings":
                        switch (type) {
                            case "ShortcutsChangedEvent":
                                uiStore.updateShortcuts(broadcast.shortcuts);
                                break;
                            case "UiParameters":
                                uiStore.fetchUiParameters(broadcast);
                                multipleEvosStore.mapUiParametersEvoToLocalStorage();
                                break;
                            case "UiSettings":
                                uiStore.fetchUiSettings(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Postgres":
                        switch (type) {
                            case "DatabaseLocationChangedEvent":
                                postgresStore.updateDatabaseLocation(broadcast.location);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/Drives":
                        switch (type) {
                            case "IdentifyStatusData":
                                driveStore.getIdentifyStatus();
                                break;
                            case "DrivesStatusChangedEvent":
                                driveStore.updateEnclosuresState(broadcast.enclosures);
                                driveReplaceStore.fetchAffectedPools();
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/SystemDrives":
                        switch (type) {
                            case "IdentifyStatusData":
                                systemDriveStore.updateidentifyStatus(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/slingshot/Automations1":
                        switch (type) {
                            case "AutomationStatusEvent": {
                                const status = JSON.parse(broadcast.json);
                                status && automationsStore.updateAutomationStatus(status);
                                break;
                            }
                            case "InstanceFinishedEvent":
                                automationsStore.updateAutomationLastInstance(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/slingshot/SyncJobs1":
                        switch (type) {
                            case "SyncJobUpdatedEvent":
                            case "SyncJobFinishedEvent":
                            case "SyncJobStateChangedEvent":
                                syncJobsStore.fetchJobs();
                                break;
                            case "SyncJobProgressEvent":
                                syncJobsStore.updateJobStatus(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/slingshot/ExtVolumes1":
                        switch (type) {
                            case "ExtVolumeRegistrationFinishedEvent":
                                slingshotSettingsStore.defineAliasStatus(broadcast);
                                slingshotSettingsStore.fetchAliases();
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Time":
                        switch (type) {
                            case "TimeSettings":
                                timeSettingsStore.fetchTimeSettings();
                                timeSettingsStore.startTimerFetch();
                                evoSettingsStore.fetchEvoInfo();
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Events":
                        switch (type) {
                            case "SnmpServerSettings":
                                alertsStore.updateSnmpServerSettings(broadcast);
                                break;
                            case "UsedSpaceEventsSettings":
                                alertsStore.updateUsedSpaceEventsSettings(broadcast);
                                break;
                            case "BeepMuteState":
                                alertsStore.updateMuteState(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Trash":
                        switch (type) {
                            case "TrashSettings":
                                recycleBinStore.updateTrashSettingsStore(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/DriveReplace":
                        switch (type) {
                            case "ReplaceState":
                                driveReplaceStore.updateReplaceState(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Cache":
                        switch (type) {
                            case "UseDrivesArguments":
                                poolsStore.fetchFreeDrives();
                                break;
                            case "DetachArguments":
                                volumeStore.fetchVolumes();
                                break;
                            case "AttachArguments":
                                volumeStore.fetchVolumes();
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/VPN":
                        switch (type) {
                            case "ConnectionInfo":
                                wireguardStore.updateConnectionInStore(broadcast);
                                break;
                            case "RemoveConnectionArguments":
                                wireguardStore.removeConnectionFromStore(broadcast.connectionName);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Audit":
                        switch (type) {
                            case "AuditSettings":
                                auditStore.fetchAuditSettings();
                                break;
                            case "AuditSharesBroadCast":
                                auditStore.fetchAuditPerShareSettings();
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Troubleshooting":
                        switch (type) {
                            case "SelfTestStatusData":
                                supportInfoStore.updateNetworkTestStatus(broadcast);
                                break;
                            case "DiskTestStatusData":
                                supportInfoStore.updateDiskTestStatus(broadcast);
                                break;
                            case "LogSettings":
                                advancedStore.updateLogSettings(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/SharebrowserServer":
                        switch (type) {
                            case "SharebrowserStartedEvent":
                                sharebrowserStore.getSharebrowserServerStatus(broadcast);
                                break;
                            case "SystemComponentUpdatedEvent":
                                sharebrowserStore.getSharebrowserServerVersion(broadcast);
                                systemComponentsStore.updateSystemComponent(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/SharebrowserWebClient":
                        switch (type) {
                            case "SystemComponentUpdatedEvent":
                                systemComponentsStore.updateSystemComponent(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Power":
                        switch (type) {
                            case "PowerStatusAllEvents":
                                powerAndUpsStore.updatePowerStatusAllEvents(broadcast);
                                break;
                            case "PowerStatus":
                                powerAndUpsStore.updatePowerStatus(broadcast);
                                break;
                            case "UpsSettings":
                                powerAndUpsStore.updateUpsSettings(broadcast);
                                break;
                            case "UpsArrayResultData":
                                powerAndUpsStore.updateUpsArrayResultData(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Firewall":
                        switch (type) {
                            case "RollbackTime":
                                firewallStore.getRollbackTime(broadcast);
                                break;
                            case "FirewallAdvancedInfo":
                                firewallStore.updateAdvancedInfoInStore(broadcast);
                                break;
                            case "FirewallRules":
                                firewallStore.updateFirewallRulesInStore(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Upgrade":
                        switch (type) {
                            case "UpgradeStep":
                                upgradesStore.fetchUpdateStatus();
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Plugins":
                        switch (type) {
                            case "PluginListChangedEvent":
                                pluginsStore.updatePlugins(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Slingshot":
                        switch (type) {
                            case "SystemComponentUpdatedEvent":
                                systemComponentsStore.updateSystemComponent(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/WebUi":
                        switch (type) {
                            case "SystemComponentUpdatedEvent":
                                systemComponentsStore.updateSystemComponent(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/Net":
                        switch (type) {
                            case "NetBond":
                                bondsStore.updateBondInStore(broadcast);
                                break;
                            case "NetBridge":
                                bridgeStore.updateBridgeInStore(broadcast);
                                break;
                            case "NetInterface":
                                ethernetPortsStore.updateInterfaceInStore(broadcast);
                                break;
                            case "RemovedBond":
                                bondsStore.removeBondFromStore(broadcast);
                                break;
                            case "RemovedBridge":
                                bridgeStore.removeBridgeFromStore(broadcast);
                                break;
                            case "RemovedInterface":
                                ethernetPortsStore.removeInterfaceFromStore(broadcast);
                                break;
                            case "NetServicesAdvertising":
                                evoSettingsStore.fetchEvoInfo();
                                break;
                            case "NetSysctlSettings":
                                networkingSettingsStore.changeTcpScaling(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Pool":
                        switch (type) {
                            case "Pool":
                                poolsStore.updatePoolInStore(broadcast);
                                break;
                            case "RemovePoolArguments":
                                poolsStore.removePoolFromStore(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Cluster":
                        switch (type) {
                            case "Brick":
                                clusterStore.fetchBricks();
                                break;
                            case "Peer":
                                clusterStore.fetchPeers();
                                break;
                            case "Volume":
                                clusterStore.fetchVolumes();
                                break;
                            case "ClusterSettingsData":
                                clusterStore.fetchSettings();
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/manage/Migration":
                        switch (type) {
                            case MAIN_STEP_PROGRESS_CHANGE_EVENT:
                                migrationStore.updateStatusMigration(broadcast);
                                break;
                            case ACL_MIGRATION_PROGRESS_CHANGED_EVENT:
                                migrationStore.updateAclProgress(broadcast);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    case "/com/studionetworksolutions/evo/Auth":
                        switch (type) {
                            case "TokensRevokedEvent":
                                longLivedTokensStore.removeTokens(broadcast.tokens);
                                if (broadcast.tokens.includes(longLivedTokensStore.currentToken)) {
                                    longLivedTokensStore.removeCurrentToken(null);
                                }
                                break;
                            case "TokenCreatedEvent":
                                longLivedTokensStore.addToken(broadcast.token);
                                break;
                            default:
                                console.log(`Unknown event type: ${type} on the path: ${path}`);
                                break;
                        }
                        break;
                    default:
                        console.log(`Unknown event path: ${path}`);
                        console.log(e.data);
                }
            },
            {
                dependenciesSecondaryAdmin: EXCLUDE_TYPES_SECONDARY_ADMIN,
                dependenciesUser: EXCLUDE_TYPES_USER,
            }
        );
    },
}));

export default EventStore;
