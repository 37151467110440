import React from "react";

const DiskProcessIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="diskProcessIcon_a" x1="31.267%" x2="100%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#3C1EFF" />
                    <stop offset="100%" stopColor="#3A98FF" stopOpacity=".91" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(2 1)">
                    <circle cx="15" cy="5" r="1" fill="#D8D8D8" stroke="#979797" />
                    <rect width="19" height="21" x=".5" y=".5" fill="#85C3FF" stroke="#B2E1FE" rx="2" />
                    <path fill="#B2E1FE" d="M1 17H19V18H1z" />
                    <circle cx="10" cy="9" r="6.5" fill="url(#diskProcessIcon_a)" stroke="#A4E1FF" />
                    <circle cx="10" cy="9" r="2" fill="#A4E1FF" />
                    <g>
                        <path
                            fill="#FFF"
                            d="M6.5 1c.68.453.863 1.37.41 2.05-.054.08-.116.156-.185.225l-4.11 4.11c-.235.235-.615.235-.85 0-.182-.182-.227-.461-.111-.692l2.54-5.08C4.587.825 5.543.507 6.329.9c.059.03.116.062.171.099z"
                            transform="rotate(180 5 9.5)"
                        />
                        <path
                            fill="#1E90FF"
                            d="M5.5 3.5c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z"
                            transform="rotate(180 5 9.5)"
                        />
                    </g>
                    <circle cx="3" cy="3" r="1" fill="#1E90FF" />
                </g>
                <g transform="translate(12 12)">
                    <circle cx="6" cy="6" r="5.5" fill="#A279FF" stroke="#CA8EFF" />
                    <path
                        fill="#FFF"
                        d="M5.983 2.25l.079.004c.128.02.241.102.299.222l.027.076.37 1.478.016.098.083-.057 1.307-.783c.126-.076.284-.076.411 0 .166.1.234.3.172.475l-.035.073-.783 1.307-.058.081.1.019 1.477.369c.143.036.255.148.29.291.048.188-.046.377-.214.458l-.076.027-1.478.37-.1.016.059.083.783 1.307c.076.126.076.284 0 .411-.1.166-.3.234-.475.172l-.073-.035-1.307-.783-.083-.058c-.003.037-.009.068-.017.1l-.369 1.477c-.04.16-.172.274-.326.298l-.079.004c-.026 0-.053-.004-.08-.011-.143-.036-.255-.148-.291-.291l-.37-1.478-.018-.099-.04.031-1.348.81c-.189.114-.435.053-.548-.137-.076-.127-.076-.285 0-.411l.783-1.307.057-.083-.098-.017-1.478-.369c-.214-.054-.344-.27-.29-.485.035-.143.147-.255.29-.291l1.478-.37.098-.018-.03-.04-.81-1.348c-.114-.189-.053-.435.137-.548.127-.076.285-.076.411 0l1.307.783.081.057.019-.098.369-1.478c.036-.143.148-.255.291-.29.027-.008.054-.011.08-.012zM6 5c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default DiskProcessIcon;
