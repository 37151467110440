import { types } from "mobx-state-tree";

const HEALTH_STATUS_ARRAY = ["queued", "building", "grow", "good", "corrupt", "degraded", "removing", "unknown"];
const Status = types.model({
    status: types.string,
    statusDetails: types.string,
    health: types.enumeration(HEALTH_STATUS_ARRAY),
    healthDetails: types.string,
    buildPercentage: types.number,
    buildTime: types.string,
    buildPaused: types.boolean
});

export default Status;
