import { types } from "mobx-state-tree";

import Credentials from "api/webserver/Types/Credentials";

const WebAccessCredentials = types.model({
    currentAdminCredentials: types.maybe(Credentials),
    newAdminCredentials: types.maybe(Credentials)
});

export default WebAccessCredentials;
