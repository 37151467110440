import React from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./RadioContainer.styles";
import RadioGroup from "components/MobxForm/RadioGroup";

const RadioContainer = ({ label, className, ...rest }) => {
    const classes = useStyles();

    return (
        <Grid className={className} item container wrap={"nowrap"}>
            <Grid className={classes.titleContainer} item xs={4}>
                {label}
            </Grid>
            <Grid className={classes.radioContainer} container item xs={8}>
                <RadioGroup className={classes.radioGroup} {...rest} />
            </Grid>
        </Grid>
    );
};

export default RadioContainer;
