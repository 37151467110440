import React, { useEffect, useContext } from "react";
import { Context } from "store";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { useLocalStore, useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useStyles } from "./RemoteShareDrawer.styles";
import { GiB_IEC } from "const/diskSizeConst";
import WorldIcon from "assets/WorldIcon";
import WorldOffIcon from "assets/WorldOffIcon";
import TrashIcon from "assets/TrashIcon";
import LabelWithIndicator from "components/LabelWithIndicator";
import clsx from "clsx";
import { DETAILS_PROTOCOL_OPTIONS } from "const/addExternalShareConst";
import WarningDialog from "../WarningDialog";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const REMOTE_SHARE_DRAWER_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_remote_share_drawer`;
const drawerName = "remoteShareDrawer";

const RemoteShareDrawer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const {
        store: { externalShareDrawerStore, uiStore },
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isWarningDialogOpen: false,
        get currentShare() {
            return externalShareDrawerStore.currentStoreWithShare?.currentShare;
        },
    }));

    useEffect(() => {
        if (externalShareDrawerStore.currentShareNameWithPrefix) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, closeDrawer);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [externalShareDrawerStore.currentShareNameWithPrefix]);

    const closeDrawer = () => {
        externalShareDrawerStore.dropAllCurrentShareNames();
    };

    const getIndicatorColor = () => {
        switch (state.currentShare?.status) {
            case "online":
                return theme.palette.success.main;
            default:
                return theme.palette.error.main;
        }
    };

    const removeShare = async () => {
        await externalShareDrawerStore.currentStoreWithShare.removeCurrentShare();
        setTimeout(() => {
            closeWarningDialog();
        }, 1000);

        closeDrawer();
    };

    const setShareOnline = () => {
        externalShareDrawerStore.currentStoreWithShare.setCurrentShareOnline();
    };

    const openWarningDialog = () => {
        state.isWarningDialogOpen = true;
    };
    const closeWarningDialog = () => {
        state.isWarningDialogOpen = false;
    };

    const getProtocolLabel = () => {
        const foundProtocol = DETAILS_PROTOCOL_OPTIONS.find((protocol) => protocol.value === state.currentShare?.protocol);
        return foundProtocol?.label;
    };

    const renderDetails = () => {
        return state.currentShare ? (
            <Grid container direction={"column"} className={classes.detailsContainer} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("share.remote_shares.side_menu.details.label.name")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{state.currentShare?.name}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("share.remote_shares.side_menu.details.label.host")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{state.currentShare?.host}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("share.remote_shares.side_menu.details.label.protocol")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{getProtocolLabel()}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("share.remote_shares.side_menu.details.label.user")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{state.currentShare?.user}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("share.remote_shares.side_menu.details.label.share")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{state.currentShare?.share}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("share.remote_shares.side_menu.details.label.status")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelWithIndicator
                            color={getIndicatorColor()}
                            label={
                                <Typography className={clsx(classes.textInfo, classes.statusText)}>
                                    {state.currentShare?.status}
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("share.remote_shares.side_menu.details.label.used")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{`${(state.currentShare?.used / GiB_IEC.value).toFixed(1)} ${
                            GiB_IEC.unit
                        }`}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("share.remote_shares.side_menu.details.label.size")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{`${(state.currentShare?.size / GiB_IEC.value).toFixed(1)} ${
                            GiB_IEC.unit
                        }`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        ) : null;
    };
    const renderActions = () => {
        return state.currentShare ? (
            <Grid direction={"column"} container>
                <BulkBarFlatButton
                    onClick={setShareOnline}
                    icon={
                        state.currentShare?.status === "online" || state.currentShare?.status === "error" ? (
                            <WorldOffIcon />
                        ) : (
                            <WorldIcon />
                        )
                    }
                    id={`${REMOTE_SHARE_DRAWER_ID_PREFIX}_online_status`}
                >
                    {state.currentShare?.status === "online" || state.currentShare?.status === "error"
                        ? t("share.remote_shares.side_menu.actions.button.set_offline")
                        : t("share.remote_shares.side_menu.actions.button.set_online")}
                </BulkBarFlatButton>
                <BulkBarFlatButton
                    errorColor
                    onClick={openWarningDialog}
                    icon={<TrashIcon />}
                    id={`${REMOTE_SHARE_DRAWER_ID_PREFIX}_remove`}
                >
                    {t("share.remote_shares.side_menu.actions.button.remove")}
                </BulkBarFlatButton>
            </Grid>
        ) : null;
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            noWrapHeader={false}
            title={externalShareDrawerStore.currentStoreWithShare?.currentShareNameWithPrefix}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            modals={
                <>
                    <WarningDialog
                        title={t("share.remote_shares.warning_dialog.title")}
                        open={state.isWarningDialogOpen}
                        onClose={closeWarningDialog}
                        submit={removeShare}
                        submitBtnLabel={t("share.remote_shares.warning_dialog.ok_button")}
                        submitBtnId={`${REMOTE_SHARE_DRAWER_ID_PREFIX}_ok_button`}
                        question={
                            <Typography>
                                {t("share.remote_shares.warning_dialog.content", {
                                    shareName: externalShareDrawerStore.currentShareNameWithPrefix,
                                })}
                            </Typography>
                        }
                    />
                </>
            }
        />
    ));
};

export default RemoteShareDrawer;
