import { types } from "mobx-state-tree";

let ID = 0;

const RequestId = types
    .model({
        requestId: types.maybe(types.string),
    })
    .named("RequestId");

const Request = types
    .model({
        path: types.string,
        method: types.string,
        arguments: types.frozen(null),
        context: types.maybe(RequestId),
    })
    .actions((self) => ({
        setArguments(args) {
            self.arguments = args;
        },
        setContext(context) {
            self.context = { requestId: `${context}${++ID}` };
        },
        init(args = undefined, context = self.method) {
            if (typeof args !== "undefined") {
                self.setArguments(args);
            }
            self.setContext(context);
            return self;
        },
    }))
    .named("Request");

export default Request;
