import { types } from "mobx-state-tree";

import WebServerConfig from "api/webserver/Types/WebServerConfig";

const GetConfigResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(WebServerConfig)
});

export default GetConfigResult;
