import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useObserver } from "mobx-react";
import { URL_SPLASH_PAGE } from "routes";
import useUrlUserPermission from "hooks/useUrlUserPermission";

const PrivateRoute = ({ component: Component, isAuthorized, path, ...rest }) => {
    const { hasPermission, urlToRedirect } = useUrlUserPermission(path);
    return useObserver(() => (
        <Route
            {...rest}
            path={path}
            render={props => {
                if (!isAuthorized) return <Redirect to={URL_SPLASH_PAGE} />;
                if (!hasPermission) return <Redirect to={urlToRedirect} />;
                return <Component {...props} />;
            }}
        />
    ));
};

export default PrivateRoute;
