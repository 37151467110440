import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        margin: 0,
        width: "100%"
    },
    paper: {
        height: ({ autoHeight }) => (autoHeight ? "auto" : "100%"),
        cursor: ({ disabled }) => !disabled && "pointer",
        "&:hover": {
            boxShadow: ({ disabled }) => !disabled && "0 8px 24px 0 rgba(69, 74, 160, 0.12)"
        }
    },
    childrenContainer: {
        padding: props => {
            if (props.emptyPadding) {
                return 0;
            } else if (props.denseBottom) {
                return theme.spacing(6, 6, 0);
            }
            return theme.spacing(6, 6, 8.5);
        }
    }
}));
