import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: "#34373e",
        minHeight: "100vh",
        padding: theme.spacing(6)
    },
    title: {
        fontSize: 28,
        fontWeight: 500
    },
    cardsContainer: {
        width: "100%",
        margin: 0
    },
    logoContainer: {
        marginBottom: 32
    },
    underBackgroundShareBrowser: {
        fill: theme.palette.splashPageCards.background,
    },
    backgroundShareBrowser: {
        fill: theme.palette.splashPageCards.background,
    },
    underBackgroundEvoGui: {
        fill: theme.palette.splashPageCards.background,
    },
    backgroundEvoGui: {
        fill: theme.palette.splashPageCards.background,
    }
}));
