import Request from "api/Request";

const SkipExternalUsersStep = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Migration",
    method: "skip_external_users_step",
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default SkipExternalUsersStep;
