import { types } from "mobx-state-tree";

import SelfTestStatusData from "api/troubleshooting/Types/SelfTestStatusData";

const SelfTestStatus = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(SelfTestStatusData)
});

export default SelfTestStatus;
