import { types } from "mobx-state-tree";

import Request from "api/Request";
import PageQuery from "api/event/Types/PageQuery";

const GetBatchPage = Request.props({
    path: "/com/studionetworksolutions/evo/status/Event",
    method: "get_batch_page",
    arguments: types.maybe(PageQuery)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetBatchPage;
