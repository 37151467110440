import React from "react";
import { ButtonBase, Grid } from "@material-ui/core";
import { useStyles } from "./TableRowControlButton.style";
import clsx from "clsx";

const TableRowControlButton = ({ icon, className, ...rest }) => {
    const classes = useStyles();
    return (
        <Grid container alignItems={"center"} justify={"flex-end"} className={clsx(classes.container, className)}>
            <ButtonBase {...rest}>{icon}</ButtonBase>
        </Grid>
    );
};

export default TableRowControlButton;
