import React from "react";

const EthernetIconDisabled = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="79384ec4-b5ee-11ea-b3de-0242ac130004" x1="-31.054%" x2="89.626%" y1="15.105%" y2="76.446%">
                    <stop offset="0%" stopColor="#D4E2F1"></stop>
                    <stop offset="100%" stopColor="#A9B7D4" stopOpacity="0.91"></stop>
                </linearGradient>
                <linearGradient id="79384bd6-b5ee-11ea-b3de-0242ac130004" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.4"></stop>
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.4"></stop>
                </linearGradient>
                <linearGradient id="793851e4-b5ee-11ea-b3de-0242ac130004" x1="-31.054%" x2="89.626%" y1="45.877%" y2="53.125%">
                    <stop offset="0%" stopColor="#D4E2F1"></stop>
                    <stop offset="100%" stopColor="#A9B7D4" stopOpacity="0.91"></stop>
                </linearGradient>
                <linearGradient id="7938507c-b5ee-11ea-b3de-0242ac130004" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.4"></stop>
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.4"></stop>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <rect
                    width="21"
                    height="15"
                    x="1.5"
                    y="7.5"
                    fill="url(#79384ec4-b5ee-11ea-b3de-0242ac130004)"
                    stroke="url(#79384bd6-b5ee-11ea-b3de-0242ac130004)"
                    rx="2"
                ></rect>
                <rect width="16" height="10" x="4" y="10" fill="#000" opacity="0.1" rx="1"></rect>
                <rect
                    width="15"
                    height="3"
                    x="4.5"
                    y="1.5"
                    fill="url(#793851e4-b5ee-11ea-b3de-0242ac130004)"
                    stroke="url(#7938507c-b5ee-11ea-b3de-0242ac130004)"
                    rx="1.5"
                ></rect>
            </g>
        </svg>
    );
};

export default EthernetIconDisabled;
