import { types } from "mobx-state-tree";

import BatchTaskPage from "api/task/Types/BatchTaskPage";

const GetBatchTaskPageResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(BatchTaskPage)
});

export default GetBatchTaskPageResult;
