import React, { useContext } from "react";
import { useObserver } from "mobx-react";
import { useStyles } from "./SideBulkBar.styles";
import { Drawer, Grid, Typography, ButtonBase, Divider } from "@material-ui/core";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import CloseIcon from "assets/CloseIcon";
import clsx from "clsx";

const SideBulkBar = ({ itemsCount, onClose, isOpen, children }) => {
    const { t } = useTranslation();
    const {
        store: { uiStore }
    } = useContext(Context);

    const classes = useStyles();
    return useObserver(() => (
        <Drawer
            variant={"persistent"}
            className={clsx(classes.drawer, uiStore.isWidgetsBarOpen && classes.drawerOpenHeader)}
            anchor="right"
            open={isOpen}
        >
            <Grid className={classes.container} container direction={"column"}>
                <Grid item>
                    <Grid className={classes.header} container direction={"row"} justify={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <Typography variant={"h2"}>
                                {t("bulk_operations_bar.items_counter", { count: itemsCount })}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.headerCloseButton}>
                            <ButtonBase onClick={onClose}>
                                <CloseIcon />
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <Grid item container className={classes.body} direction={"column"}>
                    {children}
                </Grid>
            </Grid>
        </Drawer>
    ));
};

export default SideBulkBar;
