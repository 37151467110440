import { types } from "mobx-state-tree";

const DriveInfo = types
    .model({
        quad: types.number,
        drive: types.number,
        driveModel: types.string,
        driveType: types.string,
        size: types.number
    })
    .views(self => ({
        get driveName() {
            return `${self.quad}-${self.drive}`;
        }
    }));

export default DriveInfo;
