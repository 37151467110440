import { flow, getParent, types } from "mobx-state-tree";
import Socket from "websocket";
import GetDatabaseLocation from "api/system_components/postgres/Requests/GetDatabaseLocation";
import GetDatabaseLocationResult from "api/system_components/postgres/Responses/GetDatabaseLocationResult";
import SetDatabaseLocation from "api/system_components/postgres/Requests/SetDatabaseLocation";

const PostgresStore = types
    .model({
        databaseLocationStore: types.maybe(GetDatabaseLocationResult),
    })
    .views((self) => ({
        get databaseLocation() {
            return self.databaseLocationStore && self.databaseLocationStore.data;
        },
        get isDatabaseInitialize() {
            return (
                self.databaseLocationStore && self.databaseLocationStore.data && Boolean(self.databaseLocationStore.data.share)
            );
        },
    }))
    .actions((self) => ({
        getDatabaseLocation: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetDatabaseLocation.create().init();
                const res = yield Socket.send(req);
                self.databaseLocationStore = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setDatabaseLocation: flow(function* (share) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetDatabaseLocation.create().init({ share, force: true });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateDatabaseLocation: (location) => {
            self.databaseLocationStore && (self.databaseLocationStore.data = location);
        },
    }));

export default PostgresStore;
