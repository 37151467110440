import { types } from "mobx-state-tree";

import UsbAce from "api/security/acl/Types/UsbAce";

const SetUsbAclArguments = types.model({
    acl: types.array(UsbAce),
    type: types.string
});

export default SetUsbAclArguments;
