import { types } from "mobx-state-tree";

import SnmpServerSettings from "api/events/Types/SnmpServerSettings";

const SnmpServerSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(SnmpServerSettings)
});

export default SnmpServerSettingsResult;
