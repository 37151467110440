const createFields = () => {
    return [
        {
            name: "hostname"
        },
        {
            name: "description"
        },
        {
            name: "winsEnabled",
            type: "checkbox"
        },
        {
            name: "avahiEnabled",
            type: "checkbox"
        }
    ];
};

export default createFields;
