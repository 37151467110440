import { makeStyles } from "@material-ui/core/styles";
import { OPENED_SIDE_MENU_WIDTH, SIDE_MENU_ZINDEX, SYSTEM_MENU_ZINDEX } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "row"
    },
    sideMenu: {
        width: OPENED_SIDE_MENU_WIDTH,
        height: "100%",
        overflowY: "auto",
        zIndex: SIDE_MENU_ZINDEX
    },
    systemMenu: {
        height: "100%",
        zIndex: SYSTEM_MENU_ZINDEX,
        position: "absolute",
        right: theme.spacing(5)
    },
    shortcutRemove: {
        marginTop: theme.spacing(4),
        justifyContent: "center"
    }
}));
