import { types } from "mobx-state-tree";

import UserRootAccessPage from "api/security/acl/Types/UserRootAccessPage";

const GetUserAccessResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(UserRootAccessPage)
});

export default GetUserAccessResult;
