const createFields = () => {
    return [
        {
            name: "deferred_parity_calculation_enabled",
            type: "checkbox"
        }
    ];
};

export default createFields;
