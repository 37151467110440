import React from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import { useStore } from "hooks/useStore";
import SideBulkBar from "components/SideBulkBar";
import { observer } from "mobx-react-lite";
import RenderDetails from "./components/RenderDetails";
import RenderActions from "./components/RenderActions";

const PROXY_GENERATION_DRAWER_ID_PREFIX = `proxy_generation_drawer`;

const drawerName = "proxyGenerationDrawer";
const ProxyGenerationDrawer = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${PROXY_GENERATION_DRAWER_ID_PREFIX}` : PROXY_GENERATION_DRAWER_ID_PREFIX;
    const {
        store: { slingshotStorageStore, uiStore },
    } = useStore();

    const closeDrawer = () => {
        slingshotStorageStore.setCurrentShareName(null);
        slingshotStorageStore.clearCheckedShares();
    };
    const currentShareName = slingshotStorageStore.checkedSharesNames[0] ?? slingshotStorageStore.currentShareName;

    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: currentShareName });

    if (slingshotStorageStore.checkedSharesNames.length)
        return (
            <SideBulkBar
                itemsCount={slingshotStorageStore.checkedSharesNames.length}
                onClose={closeDrawer}
                isOpen={uiStore.openedDrawer === drawerName}
            >
                <RenderActions
                    bulkBar={slingshotStorageStore.checkedSharesNames.length > 1}
                    onClose={closeDrawer}
                    parentId={idPrefix}
                />
            </SideBulkBar>
        );

    return (
        <DetailsActionsDrawer
            title={slingshotStorageStore.currentShare?.name}
            renderActions={<RenderActions onClose={closeDrawer} parentId={idPrefix} />}
            renderDetails={<RenderDetails />}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            id={`${idPrefix}_drawer`}
        />
    );
};

export default observer(ProxyGenerationDrawer);
