import i18n from "i18n";

const automationRule = {
    automationName: {
        function: value => {
            return /^\w[\w-]*$/.test(value);
        },
        message: i18n.t("rule.automation.name")
    }
};

export default automationRule;
