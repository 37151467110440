import { types } from "mobx-state-tree";

import MigrationStage from "api/upgrade/migration/Types/MigrationStage";

const GetMainStepProgressResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(MigrationStage),
});

export default GetMainStepProgressResult;
