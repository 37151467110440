import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import createForm from "utils/createForm";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch/RowControlLabelSwitch";
import Switch from "components/MobxForm/Switch";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";

const DeferredParityCalculationDialog = ({ open, closeDialog }) => {
    const { t } = useTranslation();
    const {
        store: { volumeStore }
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields(volumeStore.deferredParityCalculation) }),
        isSubmitted: false
    }));

    const unsavedChangesModal = useModal();

    const updateForm = () => {
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.form.$("deferred_parity_calculation_enabled").set("default", volumeStore.deferredParityCalculation?.enabled);
        state.form.reset();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted === true) {
            closeDialog();
            return;
        }
        unsavedChangesModal.open();
    };

    const submit = async () => {
        const res = await volumeStore.setDeferredParityCalculation({
            enabled: state.form.$("deferred_parity_calculation_enabled").value
        });
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    return useObserver(() => (
        <Dialog
            isDataChanged={!state.form.isDefault}
            onEnter={updateForm}
            title={t("technical_support.deferred_parity_calculation.title")}
            submitBtnLabel={t("common.button.save")}
            open={open}
            onSubmit={submit}
            onClose={closeIfNeeded}
            buttons={
                <Button onClick={closeDialog} variant={"contained"} color="secondary">
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <RowControlLabelSwitch
                control={<Switch field={state.form.$("deferred_parity_calculation_enabled")} />}
                label={t("technical_support.deferred_parity_calculation.label")}
            />
            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default DeferredParityCalculationDialog;
