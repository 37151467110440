/**
 * @param {AddUserDialog.Fields} key
 * @returns {(initialValues: AddUserDialog.InitialValue) => string}
 * */
const getValueByKey = key => initialValues => initialValues[key];

const createFields = () => {
    return [
        {
            name: "name",
            default: "",
            getValue: getValueByKey("name")
        }
    ];
};

export default createFields;
