import { types } from "mobx-state-tree";

import Request from "api/Request";
import AddGroupArguments from "api/security/users/Types/AddGroupArguments";

const AddGroup = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Users",
    method: "add_group",
    arguments: types.maybe(AddGroupArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default AddGroup;
