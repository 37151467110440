import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => ({
    enclosure: {
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.06),
        borderRadius: theme.spacing(1)
    },
    enclosureTitle: {
        fontSize: 18,
        fontWeight: "bold",
        opacity: 0.9,
        lineHeight: 1.33,
        letterSpacing: 0.4
    }
}));
