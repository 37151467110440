import { types } from "mobx-state-tree";

const DiskTestArguments = types.model({
    shares: types.array(types.string),
    fileSize: types.number,
    blockSize: types.number,
    repetitionCount: types.number,
    workloadMaxThreads: types.number,
});

export default DiskTestArguments;
