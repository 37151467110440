import React from "react";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import InfinityIcon from "assets/InfinityIcon";

const UsedCellItem = ({ used, quota }) => {
    const getPercentage = () => {
        return (used / quota) * 100;
    };

    return (
        <Grid container spacing={2} justify={"flex-end"} alignItems={"center"}>
            <Grid item>
                <Typography>{`${used === 0 ? 0 : used.toFixed(1)} / ${quota === 0 ? 0 : quota.toFixed(1)}`}</Typography>
            </Grid>
            <Grid item>
                {quota === 0 ? (
                    <Grid container alignItems={"center"} justify={"flex-end"}>
                        <InfinityIcon />
                    </Grid>
                ) : (
                    <Grid container alignItems={"center"} justify={"flex-end"}>
                        {getPercentage() > 10 && (
                            <CircularProgress
                                color={"primary"}
                                thickness={8}
                                size={20}
                                variant={"determinate"}
                                value={getPercentage()}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default UsedCellItem;
