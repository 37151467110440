import { types } from "mobx-state-tree";

import LocalTimeSettings from "api/time/Types/LocalTimeSettings";
import NtpSettings from "api/time/Types/NtpSettings";

const TimeSettings = types.model({
    local: types.maybe(LocalTimeSettings),
    ntp: types.maybe(NtpSettings)
});

export default TimeSettings;
