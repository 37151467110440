import { types } from "mobx-state-tree";

import Request from "api/Request";
import VolumeId from "api/volume/Types/VolumeId";

const StartDefragmentation = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Volume",
    method: "start_defragmentation",
    arguments: types.maybe(VolumeId)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default StartDefragmentation;
