import React from "react";

function ClearIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill="#1E90FF" fillRule="nonzero">
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M14.027 3.124l.094.083 6.071 6.071c.36.36.389.928.084 1.32l-.084.094L11.885 19 20.07 19c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L20.07 21H9.742c-.254 0-.486-.095-.662-.25-.036-.023-.072-.048-.107-.075l-.094-.083-6.071-6.071c-.36-.36-.389-.928-.084-1.32l.084-.094 9.9-9.9c.36-.36.927-.388 1.32-.083zM7.05 11.692L4.93 13.814l4.657 4.657 2.12-2.122-4.656-4.657zm6.364-6.364l-4.95 4.95 4.657 4.656 4.95-4.949-4.657-4.657z"
                                    transform="translate(-1084 -272) translate(1060 72) translate(0 184) translate(24 16)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default ClearIcon;
