import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography, Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const UNMAP_CONFIRMATION_DIALOG_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_unmap_confirmation_dialog`;

const UnmapConfirmationDialog = ({ open, onClose, itemCount, submit, content }) => {
    const { t } = useTranslation();

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={t("share.unmap_confirmation_dialog.title", { count: itemCount })}
            withoutIconClose
            withoutDividers
            buttons={
                <Button
                    onClick={onClose}
                    variant={"contained"}
                    color={"secondary"}
                    id={`${UNMAP_CONFIRMATION_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
            submitBtn={
                <Button
                    onClick={submit}
                    variant={"contained"}
                    color={"primary"}
                    id={`${UNMAP_CONFIRMATION_DIALOG_ID_PREFIX}_ok_button`}
                >
                    {t("share.shares.unmap_confirmation_dialog.ok_button")}
                </Button>
            }
        >
            <Grid container direction={"column"} spacing={5}>
                <Grid item>{content}</Grid>
                <Grid item>
                    <Typography>{t("share.warning_dialog.question")}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default UnmapConfirmationDialog;
