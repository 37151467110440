import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Context } from "store";
import SavedInputPreview from "components/SavedInputPreview";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography } from "@material-ui/core";
import SavedInputIndicator from "components/SavedInputIndicator";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import NASConfigurationDialog from "../NASConfigurationDialog";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { NAS_CONFIGURATION_CARD } from "const/widgetNameConst";
import { ADVANCED_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const NAS_CONFIGURATION_CARD_ID_PREFIX = `${ADVANCED_PAGE_ID_PREFIX}_nas_configuration_card`;

const NasConfigurationCard = () => {
    const { t } = useTranslation();

    const {
        store: { shareStore }
    } = useContext(Context);

    useEffect(() => {
        refreshStatuses();
    }, []);

    const refreshStatuses = async () => {
        state.inProgress = true;
        await shareStore.fetchNasConfiguration();
        state.inProgress = false;
    };

    const state = useLocalStore(() => ({
        isEditDialogOpened: false,
        inProgress: false
    }));

    // Callbacks
    const openDialog = useCallback(() => {
        state.isEditDialogOpened = true;
    });

    const closeDialog = useCallback(() => {
        state.isEditDialogOpened = false;
    });

    return useObserver(() => (
        <>
            <NASConfigurationDialog closeDialog={closeDialog} open={state.isEditDialogOpened} />
            <DataReadonlyCard
                headerDivider
                title={t("advanced.nas_configuration.title")}
                control={
                    <MoreMenu id={`${NAS_CONFIGURATION_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={NAS_CONFIGURATION_CARD} />
                    </MoreMenu>
                }
                onClick={openDialog}
            >
                {shareStore.nasConfiguration ? (
                    <>
                        <Grid container direction={"column"} spacing={5}>
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("advanced.nas_configuration.label.max_xmit")}</Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <SavedInputPreview>{shareStore.nasConfiguration?.smbMaxXmit}</SavedInputPreview>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={5}>
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("advanced.nas_configuration.label.max_credits")}</Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <SavedInputPreview>{shareStore.nasConfiguration?.smbMaxCredits}</SavedInputPreview>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={5}>
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("advanced.nas_configuration.label.workgroup")}</Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <SavedInputPreview>{shareStore.nasConfiguration?.smbWorkgroup}</SavedInputPreview>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={5}>
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("advanced.nas_configuration.label.smb_protocol")}</Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <SavedInputPreview>
                                        {shareStore.nasConfiguration?.smbProtocol.toUpperCase()}
                                    </SavedInputPreview>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={5}>
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("advanced.nas_configuration.label.smb_multichannel")}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <SavedInputIndicator enabled={shareStore.nasConfiguration?.smbMultichannelEnabled} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={5}>
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("advanced.nas_configuration.label.smb_async")}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <SavedInputIndicator enabled={shareStore.nasConfiguration?.smbAsyncEnabled} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={5}>
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("advanced.nas_configuration.label.use_sendfile")}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <SavedInputIndicator enabled={shareStore.nasConfiguration?.useSendfileEnabled} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={5}>
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("advanced.nas_configuration.label.ftp_type")}</Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <SavedInputPreview>{shareStore.nasConfiguration?.ftpType.toUpperCase()}</SavedInputPreview>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} spacing={5}>
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("advanced.nas_configuration.label.smb_fruit")}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <SavedInputIndicator enabled={shareStore.nasConfiguration?.smbFruit} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <EmptyCardPlaceholder inProgress={state.inProgress} />
                )}
            </DataReadonlyCard>
        </>
    ));
};
export default NasConfigurationCard;
