export const FREQUENCY_OPTIONS = [
    { label: "min", value: "min" },
    { label: "hour", value: "hour" },
    { label: "day", value: "day" }
];

export const RADIO_VALUE_EXTENSION = "ext";
export const RADIO_VALUE_REGEXP = "reg";

export const MIN_DEPTH = 1;

export const getCreateAutomationEmailTask = (rec, automationId, sequence) => ({
    action: {
        action: "Email"
    },
    arguments: {
        // eslint-disable-next-line no-template-curly-in-string
        subject: "[EVO] ${hostname}:[SLINGSHOT] ${automation.id}:${automation.name}:${instance.status}",
        recipients: rec,
        body:
            `This is an automatically generated notification from: \${hostname}

                Event: Automation ID: \${automation.id} \${instance.status}
                
                Starting Instance ID: \${instance.id} at \${instance.startedAt?string["yyyy:MM:dd:HH:mm:ss"]}
                Ending Instance ID: \${instance.id} at \${instance.finishedAt?string["yyyy:MM:dd:HH:mm:ss"]}
                
                Processed files: \${instance.files}
                <#if instance.folders != 0>Processed folders: \${instance.folders}
                </#if>
                Tasks results:
                <#if instance.taskResults?size == 0>` +
            ` no finished tasks
                <#else>` +
            "<#list instance.taskResults as task>" +
            ` \${task.sequence}) \${task.name}
                       <#list task.results as status, cnt>
                       \${status} - \${cnt}
                       </#list>
                </#list>
                </#if>
                Next scheduled instance: <#if automation.scheduledAt??>\${automation.scheduledAt?string["yyyy:MM:dd:HH:mm:ss"]}<#else>none</#if>
                
                More details available at the following addresses:
                \${addresses!" none"}
                
                Please do not reply to this message.`
    },
    automation_id: automationId ? automationId : null,
    enabled: true,
    event_object_types: ["E"],
    fail_automation_if_failed: true,
    group_action: null,
    mandatory: true,
    restart_if_failed: false,
    sequence: sequence
});
