import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    container: {
        overflowX: "auto",
    },
    title: {
        width: "clamp(280px, calc(100vw - 800px - var(--notification-width) - var(--sidebar-width)), 390px)",
    },
}));
