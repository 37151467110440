import React, { useContext, useCallback } from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { useLocalStore, useObserver } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./RulesDrawer.styles";
import SavedInputIndicator from "components/SavedInputIndicator";
import EditIcon from "assets/EditIcon";
import StatusIcon from "assets/StatusIcon";
import TrashIcon from "assets/TrashIcon";
import IscsiIcon from "assets/IscsiIcon";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import CreateEditRuleDialog from "../CreateEditRuleDialog";
import EditRuleOrderDialog from "../EditRuleOrderDialog";
import { useModal } from "hooks/useModal";
import ConfirmationDialog from "components/ConfirmationDialog";

const drawerName = "rulesDrawer";
const RulesDrawer = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: { firewallStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isChangeStatusRuleInProgress: false,
        isDeleteInProgress: false,
        isUpdateRuleInProgress: false,
        isEditRuleDialogOpen: false,
        isEditRuleOrderDialogOpen: false,
        isEditRuleOrderInProgress: false,
        firewallInterfacesRules: []
    }));

    const deleteRuleModal = useModal();

    const closeDrawer = useCallback(() => {
        firewallStore.setCurrentRuleIndex(null);
    });

    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: firewallStore.currentRuleIndex });

    const openEditRuleDialog = useCallback(() => {
        state.isEditRuleDialogOpen = true;
    });

    const closeEditRuleDialog = useCallback(() => {
        state.isEditRuleDialogOpen = false;
    });

    const onChangeStatusRule = useCallback(status => () => {
        state.isChangeStatusRuleInProgress = true;
        let interfacesRules = firewallStore.interfacesRulesList;
        interfacesRules.forEach(iface => {
            if (iface.iface === firewallStore.currentRuleIface) {
                iface.rules.forEach((rule, ruleIndex) => {
                    if (ruleIndex === firewallStore.currentRuleByIfaceIndex) rule.enabled = status;
                });
            }
        });
        const res = firewallStore.setFirewallRules({ interfacesRules: interfacesRules });
        if (res) {
            firewallStore.getRollbackTime();
            firewallStore.fetchFirewallRules();
            closeDrawer();
        }
        state.isChangeStatusRuleInProgress = false;
    });

    const deleteRule = useCallback(() => {
        deleteRuleModal.close();
        state.isDeleteInProgress = true;

        let interfacesRules = firewallStore.interfacesRulesList;
        const indexIface = firewallStore.firewallInterfacesRules?.findIndex(el => el.iface === firewallStore.currentRuleIface);

        interfacesRules[indexIface].rules.splice(firewallStore.currentRuleByIfaceIndex, 1);
        const res = firewallStore.setFirewallRules({ interfacesRules: interfacesRules });
        if (res) {
            firewallStore.getRollbackTime();
            firewallStore.fetchFirewallRules();
            closeDrawer();
        }
        state.isDeleteInProgress = false;
    });

    const openEditOrderRuleDialog = useCallback(() => {
        state.isEditRuleOrderDialogOpen = true;
    });

    const closeEditOrderRuleDialog = useCallback(() => {
        state.isEditRuleOrderDialogOpen = false;
    });

    const updateRuleProgress = useCallback(progress => {
        state.isUpdateRuleInProgress = progress;
    });

    const updateRuleOrderProgress = progress => {
        state.isEditRuleOrderInProgress = progress;
    };

    const renderDetails = () => {
        return (
            firewallStore.currentRule && (
                <Grid container direction={"column"} className={classes.detailsContainer} spacing={4}>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("firewall.rules.rule_status")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SavedInputIndicator enabled={firewallStore.currentRule.enabled} />
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("firewall.rules.protocol")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>
                                {firewallStore.protocolView(firewallStore.currentRule.protocol)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("firewall.rules.ports")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>
                                {firewallStore.currentRule?.portsRange?.map(port => firewallStore.portsView(port)).join(", ")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("firewall.rules.address")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>
                                {firewallStore.currentRule?.addressesRange
                                    ?.map(address => firewallStore.addressView(address))
                                    .join(", ")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("firewall.rules.action")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>
                                {firewallStore.actionView(firewallStore.currentRule?.policy)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )
        );
    };

    const renderActions = () => {
        return (
            firewallStore.currentRule && (
                <Grid direction={"column"} container>
                    <BulkBarFlatButton inProgress={state.isUpdateRuleInProgress} onClick={openEditRuleDialog} icon={<EditIcon />}>
                        {t("firewall.rules.action.edit")}
                    </BulkBarFlatButton>
                    {firewallStore.currentRule?.enabled ? (
                        <BulkBarFlatButton
                            inProgress={state.isChangeStatusRuleInProgress}
                            className={classes.iconDisable}
                            onClick={onChangeStatusRule(false)}
                            icon={<StatusIcon />}
                        >
                            {t("firewall.rules.action.disable_rule")}
                        </BulkBarFlatButton>
                    ) : (
                        <BulkBarFlatButton
                            inProgress={state.isChangeStatusRuleInProgress}
                            className={classes.iconEnable}
                            onClick={onChangeStatusRule(true)}
                            icon={<StatusIcon />}
                        >
                            {t("firewall.rules.action.enable_rule")}
                        </BulkBarFlatButton>
                    )}
                    <BulkBarFlatButton
                        inProgress={state.isEditRuleOrderInProgress}
                        onClick={openEditOrderRuleDialog}
                        icon={<IscsiIcon />}
                    >
                        {t("firewall.rules.action.change_order")}
                    </BulkBarFlatButton>
                    <BulkBarFlatButton
                        errorColor
                        inProgress={state.isDeleteInProgress}
                        onClick={deleteRuleModal.open}
                        icon={<TrashIcon />}
                    >
                        {t("firewall.rules.action.delete")}
                    </BulkBarFlatButton>
                </Grid>
            )
        );
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            title={t("firewall.rules.rule_title", {
                iface: firewallStore.currentIfaceTitle + ": ",
                index: firewallStore.currentRuleByIfaceIndex || 0
            })}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            modals={
                <>
                    <CreateEditRuleDialog
                        open={state.isEditRuleDialogOpen}
                        onClose={closeEditRuleDialog}
                        onCloseDrawer={closeDrawer}
                        changeProgressState={updateRuleProgress}
                    />
                    <EditRuleOrderDialog
                        open={state.isEditRuleOrderDialogOpen}
                        onClose={closeEditOrderRuleDialog}
                        onCloseDrawer={closeDrawer}
                        changeProgressState={updateRuleOrderProgress}
                    />
                    <ConfirmationDialog
                        title={t("common.warning_dialog.title")}
                        open={deleteRuleModal.isOpen}
                        onClose={deleteRuleModal.close}
                        onConfirm={deleteRule}
                        confirmLabel={t("common.button.proceed")}
                        cancelLabel={t("common.button.cancel")}
                        fullWidth={true}
                    >
                        {t("firewall.rules.confirm_delete")}
                    </ConfirmationDialog>
                </>
            }
        />
    ));
};

export default RulesDrawer;
