import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    textInfo: {
        fontWeight: 500,
        wordBreak: "break-word"
    },
    detailsContainer: {
        padding: theme.spacing(4),
        margin: 0,
        width: "100%"
    },
    disableIconButton: {
        "& svg path": {
            fill: theme.palette.error.main
        },
        "&:hover svg path": {
            fill: theme.palette.error.light
        }
    },
    enableIconButton: {
        "& svg path": {
            fill: theme.palette.success.main
        },
        "&:hover svg path": {
            fill: theme.palette.success.light
        }
    }
}));
