import { types } from "mobx-state-tree";

import Request from "api/Request";
import WebAccessCredentials from "api/webserver/Types/WebAccessCredentials";

const ChangeWebAccessCredentials = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Webserver",
    method: "change_web_access_credentials",
    arguments: types.maybe(WebAccessCredentials)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ChangeWebAccessCredentials;
