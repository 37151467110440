import React from "react";

const NoPoolGroupIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="152" height="152" viewBox="0 0 152 152" {...props}>
            <defs>
                <linearGradient id="b4109664-bbb6-11ea-b3de-0242ac130004" x1="-8.968%" x2="78.828%" y1="-15.973%" y2="100%">
                    <stop offset="0%" stopColor="#D4E2F1" />
                    <stop offset="100%" stopColor="#A9B7D4" stopOpacity="0.91" />
                </linearGradient>
                <linearGradient id="dd4ccb7e-bbb6-11ea-b3de-0242ac130004" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.4" />
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.4" />
                </linearGradient>
                <linearGradient id="28f422d7-ad86-4735-92bd-21bd7b8ace57" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" />
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="fac1108e-bbb6-11ea-b3de-0242ac130004" x1="-31.054%" x2="89.626%" y1="-15.973%" y2="100%">
                    <stop offset="0%" stopColor="#D4E2F1" />
                    <stop offset="100%" stopColor="#A9B7D4" stopOpacity="0.91" />
                </linearGradient>
                <linearGradient id="9ba97eaf-5e6d-4aec-9178-f2a897758d73" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.4" />
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.4" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <circle cx="76" cy="76" r="76" fill="#F2F6F9" />
                <g transform="translate(32 32)">
                    <g transform="translate(9.6 3.2)">
                        <path
                            fill="url(#b4109664-bbb6-11ea-b3de-0242ac130004)"
                            stroke="url(#dd4ccb7e-bbb6-11ea-b3de-0242ac130004)"
                            strokeWidth="3.2"
                            d="M16.134 1.6a4.8 4.8 0 00-4.305 2.679l-9.57 19.429a6.39 6.39 0 00-.659 2.827V76.8c0 .884.358 1.684.937 2.263.58.579 1.38.937 2.263.937h60a3.19 3.19 0 002.263-.937c.579-.58.937-1.38.937-2.263V26.535c0-.98-.225-1.948-.659-2.827l-9.57-19.429a4.792 4.792 0 00-1.77-1.955c-.975-.606-2.245-.724-39.867-.724z"
                        />
                        <path
                            fill="#DDE5F0"
                            fillRule="nonzero"
                            d="M53.466 0c1.219 0 2.385.346 3.38.966a6.382 6.382 0 012.241 2.375l.12.231 9.57 19.429c.404.82.663 1.702.769 2.606.026.197.044.397.05.6l.004.193h-.003l.003.135V76.8a4.785 4.785 0 01-1.406 3.394 4.775 4.775 0 01-3.161 1.4l-.233.006h-60a4.785 4.785 0 01-3.394-1.406 4.775 4.775 0 01-1.4-3.161L0 76.8V26.535l.003-.135H0c0-.27.019-.534.056-.793a7.955 7.955 0 01.637-2.33l.13-.276 9.57-19.429a6.401 6.401 0 015.48-3.566L16.135 0h37.332zM64 24H5.6a2.4 2.4 0 00-2.377 2.065c-.007.075-.013.15-.017.226l-.006.244V76.8c0 .433.168.831.469 1.131.266.267.61.43.988.463l.143.006h60a1.58 1.58 0 001.131-.469c.267-.266.43-.61.463-.988l.006-.143V26.535c0-.162-.008-.324-.025-.484a2.402 2.402 0 00-2.234-2.047L64 24zM53.466 3.2H16.134a3.192 3.192 0 00-2.777 1.61l-.093.176-7.791 15.816.127-.002H64l.126.002-7.79-15.816a3.186 3.186 0 00-1.18-1.303c-.45-.28-.96-.444-1.49-.477l-.2-.006z"
                        />
                        <circle
                            cx="16.8"
                            cy="58.4"
                            r="2"
                            fill="#FFF"
                            fillOpacity="0.56"
                            stroke="url(#28f422d7-ad86-4735-92bd-21bd7b8ace57)"
                            strokeWidth="0.8"
                            opacity="0.4"
                        />
                        <circle
                            cx="8.8"
                            cy="30.4"
                            r="2"
                            fill="#FFF"
                            fillOpacity="0.56"
                            stroke="#FFF"
                            strokeOpacity="0.9"
                            strokeWidth="0.8"
                        />
                        <circle
                            cx="16.8"
                            cy="30.4"
                            r="2"
                            fill="#FFF"
                            fillOpacity="0.56"
                            stroke="url(#28f422d7-ad86-4735-92bd-21bd7b8ace57)"
                            strokeWidth="0.8"
                            opacity="0.4"
                        />
                        <circle
                            cx="8.8"
                            cy="44"
                            r="2"
                            fill="#FFF"
                            fillOpacity="0.56"
                            stroke="#FFF"
                            strokeOpacity="0.9"
                            strokeWidth="0.8"
                        />
                        <circle
                            cx="8.8"
                            cy="58.4"
                            r="2"
                            fill="#FFF"
                            fillOpacity="0.56"
                            stroke="#FFF"
                            strokeOpacity="0.9"
                            strokeWidth="0.8"
                        />
                        <circle
                            cx="8.8"
                            cy="72"
                            r="2"
                            fill="#FFF"
                            fillOpacity="0.56"
                            stroke="#FFF"
                            strokeOpacity="0.9"
                            strokeWidth="0.8"
                        />
                        <path fill="#FFF" d="M3.2 36h63.2v1.6H3.2zm0 14.4h63.2V52H3.2zm0 14.4h63.2v1.6H3.2z" opacity="0.25" />
                    </g>
                    <rect width="19.2" height="3.2" x="52" y="31.2" fill="#FFF" fillOpacity="0.56" opacity="0.6" rx="1.6" />
                    <rect width="35.2" height="3.2" x="36" y="45.6" fill="#FFF" fillOpacity="0.56" opacity="0.5" rx="1.6" />
                    <rect width="25.6" height="3.2" x="45.6" y="60" fill="#FFF" fillOpacity="0.56" opacity="0.4" rx="1.6" />
                    <rect width="30.4" height="3.2" x="40.8" y="74.4" fill="#FFF" fillOpacity="0.56" opacity="0.3" rx="1.6" />
                    <g transform="translate(55 58)">
                        <circle
                            cx="16"
                            cy="16"
                            r="15"
                            fill="url(#fac1108e-bbb6-11ea-b3de-0242ac130004)"
                            stroke="url(#9ba97eaf-5e6d-4aec-9178-f2a897758d73)"
                            strokeWidth="2"
                        />
                        <path
                            fill="#FFF"
                            d="M21.657 10.343c.78.781.78 2.048 0 2.829l-2.828 2.827 2.828 2.83c.78.78.78 2.047 0 2.828-.781.78-2.048.78-2.829 0L16 18.828l-2.828 2.829c-.781.78-2.048.78-2.829 0a2.003 2.003 0 010-2.829L13.172 16l-2.829-2.828a2.003 2.003 0 010-2.829 2.003 2.003 0 012.829 0L16 13.172l2.828-2.829a2.003 2.003 0 012.829 0z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default NoPoolGroupIcon;
