const sortByLocaleCompare = arr => {
    return arr.sort((a, b) =>
        a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: "base"
        })
    );
};

export default sortByLocaleCompare;
