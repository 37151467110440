const { useState } = require("react");

export const useStepper = options => {
    const [activeStep, setActiveStep] = useState(0);

    const endStep = options && options.endStep ? options.endStep : 2;
    const startStep = options && options.startStep ? options.startStep : 0;

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    return {
        goBack: handleBack,
        goNext: handleNext,
        steps: endStep - startStep + 1,
        activeStep,
        isLastStep: activeStep === endStep,
        isFirstStep: activeStep === startStep
    };
};
