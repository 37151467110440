import { types } from "mobx-state-tree";

import Schedule from "api/support_info/Types/Schedule";

const GetScheduleResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(Schedule)
});

export default GetScheduleResult;
