import React from "react";
import { Typography, Divider, Grid, CircularProgress, InputAdornment, ButtonBase, Popover } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore, observer } from "mobx-react";
import { useStyles } from "./SearchLine.styles";
import TextInput from "components/MobxForm/TextInput";
import { SEARCH_DEFAULTS, LOCAL_USERS } from "const/userSearchVariants";
import CloseIcon from "assets/CloseIcon";
import ChevronDownIcon from "assets/ChevronDownIcon";
import TablePagination from "components/TablePagination";
import { USERS_SEARCH_SELECT_RELATIVE_POSITION_TOP } from "const/styleConst";
import CheckSmallIcon from "assets/CheckSmallIcon";
import clsx from "clsx";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";

const SearchLine = ({ form, onSelect, isOptionChecked, id }) => {
    const { t } = useTranslation();

    const {
        store: { userStore }
    } = useStore();

    const state = useLocalStore(() => ({
        isSearchInProgress: false,
        anchorEl: null
    }));

    const selectResult = useModal();

    const classes = useStyles({ isSelectOpen: selectResult.isOpen });

    const searchByType = async () => {
        userStore.resetSearchByTypeResult();
        userStore.updateSearchByTypeRequest("type", LOCAL_USERS);
        userStore.updateSearchByTypeRequest("page", SEARCH_DEFAULTS.page);
        userStore.updateSearchByTypeRequest("name", form.$("name").value);
        await userStore.searchByType();
    };

    const search = async () => {
        state.isSearchInProgress = true;
        await searchByType();
        state.isSearchInProgress = false;
        selectResult.open();
    };

    const keyDownHandler = async e => {
        if (!state.anchorEl) {
            state.anchorEl = e.currentTarget;
        }
        e.key === "Enter" && search();
    };

    const onInputClick = e => {
        if (!state.anchorEl) {
            state.anchorEl = e.currentTarget;
        }
    };

    const resetNameField = () => {
        form.$("name").reset();
        userStore.resetSearchByTypeResult();
    };

    const handleChangePage = async newPage => {
        state.isSearchInProgress = true;
        userStore.updateSearchByTypeRequest("page", +newPage);
        await userStore.searchByType();
        state.isSearchInProgress = false;
    };

    const onOptionClick = (name, domain) => () => {
        onSelect(name, domain);
        userStore.searchByTypeResult?.total === 1 && selectResult.close();
    };

    const getInputAdornment = () => {
        return (
            <InputAdornment position="end">
                <Grid container wrap={"nowrap"} alignItems={"center"} justify={"flex-end"} spacing={2}>
                    {!form.$("name").isEmpty ? (
                        <Grid item>
                            <ButtonBase className={classes.closeIcon} onClick={resetNameField} id={`${id}_close`}>
                                <CloseIcon />
                            </ButtonBase>
                        </Grid>
                    ) : null}

                    <Grid item>
                        <Grid container alignItems={"center"} className={classes.searchIconContainer}>
                            {state.isSearchInProgress ? (
                                <CircularProgress size={22} />
                            ) : (
                                <ButtonBase
                                    className={clsx(classes.primaryColorIcon, classes.dropdownIcon)}
                                    onClick={search}
                                    id={`${id}_search`}
                                >
                                    <ChevronDownIcon />
                                </ButtonBase>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </InputAdornment>
        );
    };

    return useObserver(() => (
        <>
            <TextInput
                autoFocus
                className={classes.input}
                onClick={onInputClick}
                placeholder={t("share.edit_permissions.input.name_placeholder")}
                field={form.$("name")}
                InputProps={{
                    endAdornment: getInputAdornment()
                }}
                onKeyDown={keyDownHandler}
                id={`${id}_name_input`}
            />
            <Popover
                PaperProps={{ className: classes.selectPaper }}
                open={selectResult.isOpen}
                anchorEl={state.anchorEl}
                onClose={selectResult.close}
                anchorOrigin={{ vertical: USERS_SEARCH_SELECT_RELATIVE_POSITION_TOP, horizontal: "center" }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                id={`${id}_popover`}
            >
                {userStore.searchByTypeResult?.total > 0 ? (
                    <React.Fragment>
                        {userStore.searchByTypeResult?.data.map(entity => (
                            <Grid
                                container
                                className={classes.selectItem}
                                onClick={onOptionClick(entity.name, entity.domain)}
                                key={entity.name + entity.domain}
                                alignItems={"center"}
                                wrap={"nowrap"}
                                justify={"space-between"}
                            >
                                <Typography noWrap>{entity.name}</Typography>
                                {isOptionChecked(entity.name) && <CheckSmallIcon />}
                            </Grid>
                        ))}
                    </React.Fragment>
                ) : (
                    <Grid className={classes.emptyResponse} container alignItems={"center"} justify={"center"}>
                        <Typography className={classes.emptyResponseText}>
                            {t("users.search_results.empty_table_placeholder", { type: t("users.common.local_users") })}
                        </Typography>
                    </Grid>
                )}

                {userStore.searchByTypeResult && userStore.searchByTypeResult.total > SEARCH_DEFAULTS.limit ? (
                    <React.Fragment>
                        <Divider />
                        <TablePagination
                            className={classes.pagination}
                            onChangePage={handleChangePage}
                            count={userStore.searchByTypeResult?.total}
                            page={userStore.searchByTypeRequest?.page}
                            rowsPerPage={userStore.searchByTypeRequest?.limit}
                            rowsPerPageOptions={[10]}
                            labelDisplayedRows={() => {}}
                        />
                    </React.Fragment>
                ) : null}
            </Popover>
        </>
    ));
};

export default observer(SearchLine);
