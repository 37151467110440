import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => ({
    container: {
        "& svg": {
            borderRadius: theme.spacing(1),
            "& path": {
                fill: theme.palette.grey["300"]
            },
            "&:hover": {
                border: `1px solid ${hexToRgba(theme.palette.primary.main, 0.15)}`,
                cursor: "pointer",
                "& path": {
                    fill: theme.palette.primary.main
                }
            },
            "&:active": {
                backgroundColor: hexToRgba(theme.palette.primary.main, 0.15)
            }
        },
        "& button:disabled path": {
            fill: theme.palette.grey["300"]
        }
    }
}));
