import { types } from "mobx-state-tree";

import DiskTest from "api/troubleshooting/Types/DiskTest";

const DiskTestShareResult = types.model({
    raid: types.string,
    pool: types.string,
    volume: types.string,
    share: types.string,
    fileSize: types.number,
    stripeSize: types.number,
    blockSize: types.number,
    tests: types.array(DiskTest),
});

export default DiskTestShareResult;
