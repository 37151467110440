import React, { useEffect } from "react";
import { useStore } from "hooks/useStore";
import { useObserver, useLocalStore } from "mobx-react";
import DataCard from "components/DataCard";
import { Table, TableCell, TableHead, TableRow, TableBody } from "@material-ui/core";
import TableSortLabel from "components/TableSortLabel";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { useTranslation } from "react-i18next";
import { FILE_NAVIGATOR_LOG_CARD } from "const/widgetNameConst";
import {
    ASC,
    DESC,
    FILE_NAVIGATOR_LOG_TIMESTAMP,
    FILE_NAVIGATOR_LOG_SHARE_NAME,
    FILE_NAVIGATOR_LOG_USER,
    FILE_NAVIGATOR_LOG_ACTION,
    FILE_NAVIGATOR_LOG_PATH,
} from "const/sortColumnConst";
import { FILE_NAVIGATOR_LOG_TIMESTAMP_FORMAT, SERVER_FILE_NAVIGATOR_LOG_TIMESTAMP_FORMAT } from "const";
import moment from "moment";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const FileNavigatorLogCard = () => {
    const { t } = useTranslation();

    const {
        store: { filesLogStore },
    } = useStore();
    const state = useLocalStore(() => ({
        isLoading: false,
        orderBy: FILE_NAVIGATOR_LOG_TIMESTAMP,
        order: ASC,
        get sortedLogs() {
            return filesLogStore.fullFilesLog && stableSort(filesLogStore.fullFilesLog, getComparator(this.order, this.orderBy));
        },
    }));

    useEffect(() => {
        (async () => {
            state.isLoading = true;
            await filesLogStore.fetchFullFilesLog();
            state.isLoading = false;
        })();
    }, []);

    const changeSorting = (column) => () => {
        if (column === state.orderBy) {
            state.order = state.order === DESC ? ASC : DESC;
        } else {
            state.order = ASC;
            state.orderBy = column;
        }
    };

    return useObserver(() => (
        <DataCard
            emptyPadding
            title={t("file_navigator_log.card.title")}
            headerDivider
            headerControl={[
                <MoreMenu key={`data_card-header_control-control-1`}>
                    <HomeCardActionMenuItem widget={FILE_NAVIGATOR_LOG_CARD} />
                </MoreMenu>,
            ]}
        >
            {filesLogStore.fullFilesLog?.length ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    direction={state.order}
                                    onClick={changeSorting(FILE_NAVIGATOR_LOG_TIMESTAMP)}
                                    active={state.orderBy === FILE_NAVIGATOR_LOG_TIMESTAMP}
                                    id={"file_navogator_log_timestamp_sort"}
                                >
                                    {t("file_navigator_log.table.timestamp")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={state.order}
                                    onClick={changeSorting(FILE_NAVIGATOR_LOG_SHARE_NAME)}
                                    active={state.orderBy === FILE_NAVIGATOR_LOG_SHARE_NAME}
                                    id={"file_navogator_log_share_name_sort"}
                                >
                                    {t("file_navigator_log.table.share_name")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={state.order}
                                    onClick={changeSorting(FILE_NAVIGATOR_LOG_USER)}
                                    active={state.orderBy === FILE_NAVIGATOR_LOG_USER}
                                    id={"file_navogator_log_user_name_sort"}
                                >
                                    {t("file_navigator_log.table.user")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={state.order}
                                    onClick={changeSorting(FILE_NAVIGATOR_LOG_ACTION)}
                                    active={state.orderBy === FILE_NAVIGATOR_LOG_ACTION}
                                    id={"file_navogator_log_action_sort"}
                                >
                                    {t("file_navigator_log.table.action")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={state.order}
                                    onClick={changeSorting(FILE_NAVIGATOR_LOG_PATH)}
                                    active={state.orderBy === FILE_NAVIGATOR_LOG_PATH}
                                    id={"file_navogator_log_path_sort"}
                                >
                                    {t("file_navigator_log.table.path")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.sortedLogs.map((file) => (
                            <TableRow key={file.id}>
                                <TableCell>
                                    {moment(file.timeStamp, SERVER_FILE_NAVIGATOR_LOG_TIMESTAMP_FORMAT).format(
                                        getLanguageDateFormat(FILE_NAVIGATOR_LOG_TIMESTAMP_FORMAT)
                                    )}
                                </TableCell>
                                <TableCell>{file.shareName}</TableCell>
                                <TableCell>{file.user}</TableCell>
                                <TableCell>{file.action}</TableCell>
                                <TableCell>{file.path}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyCardPlaceholder inProgress={state.isLoading} message={t("file_navigator_logs.no_files.placeholder")} />
            )}
        </DataCard>
    ));
};

export default FileNavigatorLogCard;
