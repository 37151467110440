import { types } from "mobx-state-tree";
import Request from "api/Request";
import StartRegistrationType from "../Types/StartRegistrationType";

const StartRegistration = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/ExtVolumes1",
    method: "start_registration",
    arguments: types.maybe(
        types.snapshotProcessor(StartRegistrationType, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json)
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default StartRegistration;
