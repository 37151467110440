import { useObserver } from "mobx-react";
import { useRef, useState } from "react";

/**
 * Hook for observe changes in the form field.
 * @param {*} field - object of type a field from mobx-react-form
 * @returns
 * hasChange - property that returns true if the observed field has been changed.
 *
 * reset - method resets the hasChange value to false.
 *
 * captureChange - method sets the hasChange value to true.
 *
 * @example
 * const { hasChange, captureChange, reset } = useObserveChangeField(state.form.$("name"));
 */
export const useObserveChangeField = field => {
    const [hasChange, toggleHasChange] = useState(true);
    const selectedName = useRef(field.value);

    const handleReset = () => {
        toggleHasChange(false);
        selectedName.current = field.value;
    };

    const captureChange = () => {
        toggleHasChange(true);
    };

    useObserver(() => {
        if (field.value !== selectedName.current) {
            field.value ? captureChange() : toggleHasChange(false);
            selectedName.current = field.value;
        }
    });

    return {
        hasChange,
        captureChange,
        reset: handleReset
    };
};
