import { types } from "mobx-state-tree";

import ServiceStateResult from "../Types/ServiceStateJson";

const GetServiceStateResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(ServiceStateResult, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            }
        })
    )
});

export default GetServiceStateResult;
