import React from "react";
import { useObserver } from "mobx-react";
import DataCard from "components/DataCard";
import { useTranslation } from "react-i18next";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";

const EmptyCard = ({ inProgress }) => {
    const { t } = useTranslation();

    return useObserver(() => (
        <DataCard title={t("audit.empty_card.title")} emptyPadding headerDivider autoHeight>
            <EmptyCardPlaceholder message={t("audit.empty_card.content")} inProgress={inProgress} />
        </DataCard>
    ));
};

export default EmptyCard;
