import React, { useContext, useEffect } from "react";
import DataCard from "components/DataCard";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { PARTITION_TABLE } from "const/widgetNameConst";
import { useObserver } from "mobx-react-lite";
import { Context } from "store";
import { useStyles } from "./PartitionTable.styles";

const PartitionTable = () => {
    const { t } = useTranslation();
    const {
        store: { upgradesStore },
    } = useContext(Context);

    const classes = useStyles();

    useEffect(() => {
        upgradesStore.fetchPartitions();
    }, []);

    return useObserver(() => (
        <DataCard
            title={t("upgrades.partition_status.title")}
            autoHeight
            emptyPadding
            headerDivider
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={PARTITION_TABLE} />
                </MoreMenu>
            }
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width={"30%"}>{t("upgrades.partition_status.partition")}</TableCell>
                        <TableCell width={"20%"}>{t("upgrades.partition_status.status")}</TableCell>
                        <TableCell width={"50%"}>{t("upgrades.partition_status.version")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {upgradesStore.partitions?.map((partition) => (
                        <TableRow key={partition.name}>
                            <TableCell>{partition.name}</TableCell>
                            <TableCell className={classes.status}>{partition.status}</TableCell>
                            <TableCell>{partition.partitionVersion}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </DataCard>
    ));
};

export default PartitionTable;
