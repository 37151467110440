import Request from "api/Request";

const DownloadSystemLogs = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Troubleshooting",
    method: "download_system_logs"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default DownloadSystemLogs;
