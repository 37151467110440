import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { Table, TableBody, TableCell, TableRow, TableHead, Paper, Divider } from "@material-ui/core";
import CardHeader from "components/CardHeader";
import TableSortLabel from "components/TableSortLabel";
import { NAS_SESSION_SHARE_NAME, NAS_SESSION_PROTOCOL, NAS_SESSION_HOST, NAS_SESSION_SPEED } from "const/sortColumnConst";
import { useTranslation } from "react-i18next";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import TableExpand from "components/TableExpand";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { SHARES_CURRENT_SESSION } from "const/widgetNameConst";
import useStoreByIp from "hooks/useStoreByIp";
import useEvoPrefix from "hooks/useEvoPrefix";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import HideOverlay from "components/HideOverlay/HideOverlay";

const CURRENT_SESSION_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_current_session`;

const CurrentSession = ({ ip }) => {
    const { t } = useTranslation();
    const evoPrefix = useEvoPrefix({ ip });
    const HIDDEN_ROWS_PER_CARD_INDEX = 6;

    const { sessionStore } = useStoreByIp({ ip });

    const state = useLocalStore(() => ({
        isExpanded: false,
        get slicedIndex() {
            if (sessionStore.nasSessions) {
                return this.isExpanded ? sessionStore.nasSessions.length : HIDDEN_ROWS_PER_CARD_INDEX;
            }
            return null;
        }
    }));

    useEffect(() => {
        sessionStore.fetchNasSessions();
    }, []);

    const toggleExpandNasSessions = () => {
        state.isExpanded = !state.isExpanded;
    };

    const changeSorting = column => () => sessionStore.changeSorting(column);

    return useObserver(() => (
        <Paper>
            <CardHeader
                title={`${evoPrefix}${t("share.current_session.title")}`}
                control={
                    <HideOverlay show={!ip}>
                        <MoreMenu id={`${CURRENT_SESSION_ID_PREFIX}_more_menu`}>
                            <HomeCardActionMenuItem widget={SHARES_CURRENT_SESSION} />
                        </MoreMenu>
                    </HideOverlay>
                }
            />
            <Divider />
            {sessionStore.nasSessions && sessionStore.nasSessions.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    direction={sessionStore.order}
                                    onClick={changeSorting(NAS_SESSION_SHARE_NAME)}
                                    active={sessionStore.orderBy === NAS_SESSION_SHARE_NAME}
                                    id={`${CURRENT_SESSION_ID_PREFIX}_name_sort`}
                                >
                                    {t("share.current_session.table.header.name")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={sessionStore.order}
                                    onClick={changeSorting(NAS_SESSION_PROTOCOL)}
                                    active={sessionStore.orderBy === NAS_SESSION_PROTOCOL}
                                    id={`${CURRENT_SESSION_ID_PREFIX}_protocol_sort`}
                                >
                                    {t("share.current_session.table.header.protocol")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={sessionStore.order}
                                    onClick={changeSorting(NAS_SESSION_HOST)}
                                    active={sessionStore.orderBy === NAS_SESSION_HOST}
                                    id={`${CURRENT_SESSION_ID_PREFIX}_remote_sort`}
                                >
                                    {t("share.current_session.table.header.remote")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={sessionStore.order}
                                    onClick={changeSorting(NAS_SESSION_SPEED)}
                                    active={sessionStore.orderBy === NAS_SESSION_SPEED}
                                    id={`${CURRENT_SESSION_ID_PREFIX}_speed_sort`}
                                >
                                    {t("share.current_session.table.header.speed")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sessionStore.sortedNasSessions &&
                            sessionStore.sortedNasSessions.slice(0, state.slicedIndex).map(session => (
                                <TableRow key={session.shareName}>
                                    <TableCell>{session.shareName}</TableCell>
                                    <TableCell>{session.protocol}</TableCell>
                                    <TableCell>{session.remote}</TableCell>
                                    <TableCell>{session.speed}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                    {sessionStore.nasSessions.length > HIDDEN_ROWS_PER_CARD_INDEX && (
                        <TableExpand hideDivider onClick={toggleExpandNasSessions}>
                            {state.isExpanded
                                ? t("share.shared_pool_card.show_all_table_caption.hide")
                                : t("share.shared_pool_card.show_all_table_caption.show_all", {
                                      count: sessionStore.nasSessions.length
                                  })}
                        </TableExpand>
                    )}
                </Table>
            ) : (
                <EmptyCardPlaceholder message={t("share.current_session.table.placeholder")} />
            )}
        </Paper>
    ));
};

export default CurrentSession;
