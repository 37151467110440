import React, { useContext, useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Context } from "store";
import MainTemplate from "components/MainTemplate";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ConnectIcon from "assets/ConnectIcon";
import HeaderTitle from "components/HeaderTitle";
import { FIREWALL } from "const/shortcutNameConst";
import { BASIC_MODE, ADVANCED_MODE } from "const/firewallConst";
import Rules from "./components/Rules";
import AdvancedModeCard from "./components/AdvancedModeCard";

const Firewall = () => {
    const { t } = useTranslation();
    const {
        store: { firewallStore }
    } = useContext(Context);

    useEffect(() => {
        firewallStore.fetchFirewallMode();
    }, []);

    const state = useLocalStore(() => ({
        get headerTitle() {
            switch (firewallStore.firewallMode) {
                case ADVANCED_MODE:
                    return t("firewall.title_advanced");
                case BASIC_MODE:
                    return t("firewall.title_basic");
                default:
                    return t("firewall.title");
            }
        }
    }));

    return useObserver(() => (
        <MainTemplate headerTitle={<HeaderTitle icon={<ConnectIcon />} title={state.headerTitle} shortcutName={FIREWALL} />}>
            <>
                {firewallStore.firewallMode === BASIC_MODE && (
                    <Grid container direction={"column"}>
                        <Rules />
                    </Grid>
                )}
                {firewallStore.firewallMode === ADVANCED_MODE && (
                    <Grid container>
                        <Grid item xs={12}>
                            <AdvancedModeCard />
                        </Grid>
                    </Grid>
                )}
            </>
        </MainTemplate>
    ));
};

export default Firewall;
