import config from "config";

export const port = 8090;
export const httpPort = 80;
export const httpsPort = 443;
export const restApiPort = 8091;
export const SBS_DEFAULT_PORT = 8577;
export const HTTPS = "https";
export const HTTP = "http";
export const PROTOCOLS = [HTTPS, HTTP];

export const WEBSOCKET_URL = `${config.wsProtocol}://${config.path}:${port}/api`;

export const SERVER_DATE_FORMAT_EN = "HH:mm:ss MM/DD/YYYY";
export const SERVER_RECYCLE_BIN_DATE_FORMAT = "ddd MMM DD HH:mm:ss UTC YYYY";
export const SERVER_LONG_LIVED_TOKENS_FORMAT = "HH:mm:ss MM/DD/YYYY";
export const SERVER_FILE_NAVIGATOR_LOG_TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const SERVER_FILE_NAVIGATOR_DATE_FORMAT = "HH:mm:ss MM DD YYYY";

export const CLUSTER_SECONDS_FORMAT = "HH:mm:ss";
export const CLIENT_DATE_FORMAT_ONLY_TIME = "HH:mm:ss";

export const CLIENT_DATE_FORMAT = {
    en: "MMMM DD, YYYY HH:mm:ss",
    zh: "YYYY/MM/DD HH:mm:ss",
    default: "DD MMMM, YYYY HH:mm:ss",
};

export const CLIENT_DATE_FORMAT_ONLY_DATE = {
    en: "MMMM DD, YYYY",
    zh: "YYYY/MM/DD",
    default: "DD MMMM , YYYY",
};

export const LONG_LIVED_TOKENS_FORMAT = {
    en: "HH:mm:ss MM/DD/YYYY",
    zh: "HH:mm:ss YYYY/MM/DD",
    default: "HH:mm:ss MM/DD/YYYY",
};
export const STORAGE_USAGE_GRAPH_DATE_FORMAT = {
    en: "MM/DD/YYYY",
    zh: "YYYY/MM/DD",
    default: "MM/DD/YYYY",
};

export const RECYCLE_BIN_DATE_FORMAT = {
    en: "MMM-DD-YYYY HH:mm",
    zh: "YYYY/MM/DD HH:mm",
    default: "DD-MMM-YYYY HH:mm",
};

export const NOTIFICATION_DATE_FORMAT = {
    en: "MMM-DD-YYYY HH:mm:ss",
    zh: "YYYY/MM/DD HH:mm:ss",
    default: "DD-MMM-YYYY HH:mm:ss",
};

export const SLINGSHOT_DATE_FORMAT = {
    en: "MM/DD/YYYY",
    zh: "YYYY/MM/DD",
    default: "DD/MM/YYYY",
};

export const SLINGSHOT_DATE_HOURS_FORMAT = {
    en: "MM/DD/YYYY HH:mm",
    zh: "YYYY/MM/DD HH:mm",
    default: "DD/MM/YYYY HH:mm",
};

export const SLINGSHOT_DATE_FULL_HOURS_FORMAT = {
    en: "MM/DD/YYYY HH:mm:ss",
    zh: "YYYY/MM/DD HH:mm:ss",
    default: "DD/MM/YYYY HH:mm:ss",
};

export const FILE_NAVIGATOR_LOG_TIMESTAMP_FORMAT = {
    en: "YYYY-MM-DD HH:mm:ss",
    zh: "YYYY/MM/DD HH:mm:ss",
    default: "YYYY-MM-DD HH:mm:ss",
};

export const FILE_NAVIGATOR_DATE_FORMAT = {
    en: "MMM-DD-YYYY HH:mm",
    zh: "YYYY/MM/DD HH:mm",
    default: "DD-MMM-YYYY HH:mm",
};

export const REGEX_DOMAIN = /^((?!-)[a-z0-9-]{0,63}[a-z0-9]\.)+[a-z]{2,63}$/;
export const VALID_HOSTNAME_REGEX = /^([A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]\.)*[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9]$/;
export const VALID_HOSTNAME_REGEX_CHINA = /^[0-9\u2E80-\u9FFF.-_]+$/;
export const NOT_ONLY_NUMBERS = /(?!^\d+$)^.+$/;
export const SNMP_DEFAULT_PORT = 162;
export const BOOT_PROGRESS_POLLING_INTERVAL = 5000;
export const REGEX_IP = /^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/;
export const REGEX_IPV4 = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/gm;
export const REGEX_IP_SUBNET = /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[0-9]|1[0-9]|2[0-4]))$/;
export const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const SNS_WEBSITE = "https://www.studionetworksolutions.com/";
export const SNS_ADD_SHARE_CURRENT_RECOMMENDATIONS = "https://support.studionetworksolutions.com/hc/en-us/articles/115004117846";

export const DARK_MODE_THEME = "DARK_MODE_THEME";
export const LIGHT_MODE_THEME = "LIGHT_MODE_THEME";
export const PIN_SIDE_MENU = "pinSideMenu";

export const MAXIMUM_POOL_SIZE = 16;
