import React, { useEffect } from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";

import { Grid, Button, Typography, Box, MenuItem } from "@material-ui/core";
import createForm from "utils/createForm";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowTextInput from "components/RowTextInput";
import Select from "components/MobxForm/Select";
import NewFolderDialog from "./NewFolderDialog";
import { DAYS_OF_WEEK_ARRAY, DAY_OF_WEEKS_OPTIONS, HOURS, MUNUTES, DELETE_BACKUPS } from "const/setupBackupConst";
import { useStyles } from "./SetupBackupDialog.styles";
import sortByLocaleCompare from "utils/sortByLocaleCompare";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";
import { SUPPORT_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const SETUP_BACKUP_DIALOG_ID_PREFIX = `${SUPPORT_PAGE_ID_PREFIX}_setup_backup_dialog`;

const SetupBackupDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const ADDITIONAL_START_ITEMS = [{ label: "<root>", value: "/" }];
    const ADDITIONAL_END_ITEMS = [{ label: "EVO-Database-Backups", value: "EVO-Database-Backups" }];

    const {
        store: { backupSettingsStore, shareStore, fileStore, postgresStore },
    } = useStore();

    const { unsavedChangesModal, createFolderModal } = useModalCombine(["unsavedChangesModal", "createFolderModal"]);

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        isSubmitted: false,
        inProgress: false,

        get shares() {
            const shares = shareStore.sharesTable?.flatMap((share) =>
                share.shares
                    .filter((s) => s.attributes.shareType !== "internal" && s.location.pool !== "USB")
                    .filter((s) => s.attributes.shareType !== "sftp")
                    ?.map((share) => share.attributes.shareName)
            );
            if (!postgresStore.databaseLocation?.share) {
                shares.push("");
            }
            return sortByLocaleCompare(shares);
        },
        dayValue: [],
        get shareFolderList() {
            const folders = fileStore.files
                ?.filter((el) => el.type === "directory")
                ?.map((folder) => {
                    return (folder = { label: folder.name, value: folder.name });
                });
            return (
                folders?.filter((folder) => {
                    return ADDITIONAL_END_ITEMS.findIndex((el) => el.label === folder.label) < 0;
                }) || []
            );
        },
    }));

    useEffect(() => {
        shareStore.fetchSharesTable();
    }, []);

    const updateForm = () => {
        state.form.$("share").set("default", backupSettingsStore.backup.location?.share);
        state.form.$("path").set("default", backupSettingsStore.backup.location?.dir || "/");
        state.form.$("minute").set("default", backupSettingsStore.backup.schedule[0]?.minute);
        state.form.$("hour").set("default", backupSettingsStore.backup.schedule[0]?.hour);
        state.form.$("deleteBackups").set("default", backupSettingsStore.backup.deletePeriodSec || DELETE_BACKUPS[0].value);
        const daysArray =
            (backupSettingsStore.backup &&
                !!backupSettingsStore.backup.schedule.length &&
                Object.keys(backupSettingsStore.backup.schedule[0]).filter(
                    (key) => backupSettingsStore.backup.schedule[0][key] === true
                )) ||
            [];
        state.dayValue = daysArray.map((day) => {
            const newDay = day[0].toUpperCase() + day.slice(1);
            return newDay;
        });
        state.form.reset();
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };

    const onSubmit = async () => {
        state.inProgress = true;

        let data = {
            deletePeriodSec: state.form.$("deleteBackups").value,
            schedule: [
                {
                    hour: state.form.$("hour").value,
                    minute: state.form.$("minute").value,
                    monday: !!state.dayValue.find((day) => day === DAY_OF_WEEKS_OPTIONS.monday),
                    tuesday: !!state.dayValue.find((day) => day === DAY_OF_WEEKS_OPTIONS.tuesday),
                    wednesday: !!state.dayValue.find((day) => day === DAY_OF_WEEKS_OPTIONS.wednesday),
                    thursday: !!state.dayValue.find((day) => day === DAY_OF_WEEKS_OPTIONS.thursday),
                    friday: !!state.dayValue.find((day) => day === DAY_OF_WEEKS_OPTIONS.friday),
                    saturday: !!state.dayValue.find((day) => day === DAY_OF_WEEKS_OPTIONS.saturday),
                    sunday: !!state.dayValue.find((day) => day === DAY_OF_WEEKS_OPTIONS.sunday),
                },
            ],
        };
        data = {
            ...data,
            location: {
                share: state.form.$("share").value,
                dir: state.form.$("path").value === ADDITIONAL_START_ITEMS[0].value ? "" : state.form.$("path").value,
            },
        };

        const res = await backupSettingsStore.updateBackup(data);
        state.inProgress = false;

        if (res) {
            state.isSubmitted = true;
            backupSettingsStore.fetchBackup();
        }

        return res;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const handleChange = (event) => {
        state.dayValue = event.target.value;
    };

    const addNewFolder = (newFolder) => {
        state.shareFolderList.push({ label: newFolder, value: newFolder });
        state.form.$("path").set("value", newFolder);
    };

    const onShareChange = (e) => {
        state.form.$("share").set("value", e.target.value);
        e.target.value &&
            fileStore.listFiles({
                shareName: e.target.value,
                dirOnShare: "",
            });
        state.form.$("path").set("value", ADDITIONAL_END_ITEMS[0].value);
    };

    return useObserver(() => (
        <Dialog
            onClose={closeIfNeeded}
            open={open}
            title={t("support.os_data.backup_card.settings.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${SETUP_BACKUP_DIALOG_ID_PREFIX}_save`}
            inProgress={state.inProgress}
            onSubmit={onSubmit}
            onEnter={updateForm}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary" id={`${SETUP_BACKUP_DIALOG_ID_PREFIX}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"}>
                <RowTextInput
                    label={t("support.os_data.backup_card.label.share_to_backup")}
                    control={
                        <Select
                            field={state.form.$("share")}
                            options={state.shares}
                            onChange={onShareChange}
                            id={`${SETUP_BACKUP_DIALOG_ID_PREFIX}_share_to_backup`}
                        />
                    }
                />
                <RowTextInput
                    label={t("support.os_data.backup_card.label.path_to_backup")}
                    control={
                        <Select
                            field={state.form.$("path")}
                            options={[...state.shareFolderList, ...ADDITIONAL_END_ITEMS]}
                            additionalStartOptions={ADDITIONAL_START_ITEMS}
                            actionOption={
                                <MenuItem
                                    key={"action-option"}
                                    onClick={createFolderModal.open}
                                    value={[]}
                                    id={`${SETUP_BACKUP_DIALOG_ID_PREFIX}_path_to_backup_create_new`}
                                >
                                    <Typography>{t("common.option.create_new")}</Typography>
                                </MenuItem>
                            }
                            id={`${SETUP_BACKUP_DIALOG_ID_PREFIX}_path_to_backup`}
                        />
                    }
                />
                <Box pt={1} pb={2} className={classes.schedule}>
                    <Grid item container wrap="nowrap" alignItems="center" spacing={4}>
                        <Grid item xs={6}>
                            <Typography>{t("support.os_data.backup_card.label.schedule")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item container spacing={2}>
                                <RowTextInput
                                    label={t("support.os_data.backup_card.label.day_of_week")}
                                    control={
                                        <Select
                                            multiple
                                            field={state.form.$("day")}
                                            options={DAYS_OF_WEEK_ARRAY}
                                            value={state.dayValue}
                                            onChange={handleChange}
                                            renderValue={(selected) => selected.join(", ")}
                                            id={`${SETUP_BACKUP_DIALOG_ID_PREFIX}_day_of_week`}
                                        />
                                    }
                                />
                                <RowTextInput
                                    label={t("support.os_data.backup_card.label.hour")}
                                    control={
                                        <Select
                                            field={state.form.$("hour")}
                                            options={HOURS}
                                            id={`${SETUP_BACKUP_DIALOG_ID_PREFIX}_hour`}
                                        />
                                    }
                                />
                                <RowTextInput
                                    label={t("support.os_data.backup_card.label.minute")}
                                    control={
                                        <Select
                                            field={state.form.$("minute")}
                                            options={MUNUTES}
                                            id={`${SETUP_BACKUP_DIALOG_ID_PREFIX}_minute`}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <RowTextInput
                    label={t("support.os_data.backup_card.label.delete_backups")}
                    control={
                        <Select
                            field={state.form.$("deleteBackups")}
                            options={DELETE_BACKUPS}
                            id={`${SETUP_BACKUP_DIALOG_ID_PREFIX}_delete_backups`}
                        />
                    }
                />
            </Grid>
            <NewFolderDialog open={createFolderModal.isOpen} onClose={createFolderModal.close} newFolder={addNewFolder} />
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default SetupBackupDialog;
