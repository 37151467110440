import React, { useContext, useMemo } from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { observer, useObserver, useLocalStore } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Box, Grid, Typography } from "@material-ui/core";
import EditIcon from "assets/EditIcon";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import EditAutomationModal from "../EditAutomationModal";
import StatusLabel from "../AutomationsTabContent/components/StatusLabel";
import TimeToStart from "./components/TimeToStart";
import moment from "moment";
import { SINGLE_FILE_TRANSFER_ACTION_NAME, TABLE_TYPE_USER } from "const/slingshotAutomationsConst";
import parseFilterRules from "../EditAutomationModal/utils/parseFilterRules";
import StatusIcon from "assets/StatusIcon";
import RunIcon from "assets/RunIcon";
import ViewIcon from "assets/ViewIcon";
import TrashIcon from "assets/TrashIcon";
import TimeIcon from "assets/TimeIcon";
import CloseIcon from "assets/CloseIcon";
import JobInstancesModal from "../JobInstancesModal";
import { useStyles } from "./AutomationsDrawer.styles";
import { useModal } from "hooks/useModal";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useInstancesModal } from "hooks/modals";

const AUTOMATIONS_DRAWER_ID_PREFIX = `automations_drawer`;
const drawerName = "automationsDrawer";

const AutomationsDrawer = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${AUTOMATIONS_DRAWER_ID_PREFIX}` : AUTOMATIONS_DRAWER_ID_PREFIX;
    const { t } = useTranslation();
    const instancesModal = useInstancesModal();
    const classes = useStyles();

    const state = useLocalStore(() => ({
        isEditAutomationModalOpen: false,
        isEnableDisableInProgress: false,
        isGetInstancesInProgress: false,
        isRemoveInProgress: false,
        isRunAutomationInProgress: false,
        isPauseAutomationInProgress: false,
        isResumeAutomationInProgress: false,
        isStopRunningAutomationInProgress: false,
    }));

    const {
        store: { automationsStore, syncJobsStore },
    } = useContext(Context);

    const deleteAutomationModal = useModal();

    const closeDrawer = () => {
        automationsStore.setCurrentAutomationId(null);
    };
    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: automationsStore.currentAutomationId });

    const openEditAutomationModal = async () => {
        state.isEditAutomationModalOpen = true;
    };

    const closeEditAutomationModal = () => {
        state.isEditAutomationModalOpen = false;
    };

    const runningInstancesCount = useObserver(() => {
        return (
            automationsStore.currentAutomation?.instances?.get("IN PROGRESS") ||
            automationsStore.currentAutomation?.last_instance?.result === "IN PROGRESS" ||
            0
        );
    });

    const status = useMemo(() => {
        let status = automationsStore.currentAutomation?.deleted
            ? t("slingshot.automations.table.label.status.deleted")
            : automationsStore.currentAutomation?.enabled
            ? automationsStore.currentAutomation?.paused
                ? t("slingshot.automations.table.label.status.paused")
                : runningInstancesCount > 0
                ? t("slingshot.automations.table.label.status.running")
                : t("slingshot.automations.table.label.status.waiting")
            : "Disabled";
        if (automationsStore.currentAutomation?.schedule?.schedule && !automationsStore.currentAutomation?.deleted) {
            const now = moment();
            let scheduleDate = moment();
            try {
                scheduleDate = moment(automationsStore.currentAutomation?.schedule.schedule.date);
            } catch (e) {
                // TODO Implement handling error
            }
            const diff = scheduleDate.diff(now);
            diff > 0 &&
                automationsStore.currentAutomation?.enabled &&
                runningInstancesCount === 0 &&
                !automationsStore.currentAutomation?.paused &&
                (status = t("slingshot.automations.table.label.status.waiting"));
        }
        return status;
    });

    const isOneShot = useObserver(() => {
        return (
            automationsStore.currentAutomation?.internal &&
            automationsStore.currentAutomation?.tasks?.some((task) => task.action?.action === SINGLE_FILE_TRANSFER_ACTION_NAME)
        );
    });

    const automationType = useMemo(() => {
        if (isOneShot) {
            return t("slingshot.automations.table.label.automation_type.one_shot");
        } else if (automationsStore.currentAutomation?.schedule?.schedule) {
            return t("slingshot.automations.table.label.automation_type.scheduled");
        } else {
            return t("slingshot.automations.table.label.automation_type.manual");
        }
    });

    const folderToWatch = (currentAutomation) => {
        const { url } = currentAutomation && parseFilterRules(currentAutomation.filter);
        return url || "";
    };

    const tasksList = (currentAutomation) => {
        return currentAutomation?.tasks?.map((task) => task.action?.action || task.group_action).join(", ");
    };

    const disableAutomation = async () => {
        state.isEnableDisableInProgress = true;
        const res = await automationsStore.performAutomationCommand({
            automationId: automationsStore.currentAutomationId,
            cmd: "cancel_disable",
        });
        state.isEnableDisableInProgress = false;

        res && closeDrawer();
    };

    const enableAutomation = async () => {
        state.isEnableDisableInProgress = true;
        const res = await automationsStore.performAutomationCommand({
            automationId: automationsStore.currentAutomationId,
            cmd: "enable",
        });
        state.isEnableDisableInProgress = false;

        res && closeDrawer();
    };

    const runAutomation = async () => {
        state.isRunAutomationInProgress = true;
        const res = await automationsStore.performAutomationCommand({
            automationId: automationsStore.currentAutomationId,
            cmd: "run",
        });
        state.isRunAutomationInProgress = false;

        res && closeDrawer();
    };

    const pauseAutomation = async () => {
        state.isPauseAutomationInProgress = true;
        await automationsStore.performAutomationCommand({ automationId: automationsStore.currentAutomationId, cmd: "pause" });
        state.isPauseAutomationInProgress = false;
    };

    const resumeAutomation = async () => {
        state.isResumeAutomationInProgress = true;
        await automationsStore.performAutomationCommand({ automationId: automationsStore.currentAutomationId, cmd: "resume" });
        state.isResumeAutomationInProgress = false;
    };

    const stopRunningAutomation = async () => {
        state.isStopRunningAutomationInProgress = true;
        const res = await automationsStore.performAutomationCommand({
            automationId: automationsStore.currentAutomationId,
            cmd: "cancel",
        });
        state.isStopRunningAutomationInProgress = false;

        res && closeDrawer();
    };

    const removeAutomation = async () => {
        deleteAutomationModal.close();
        state.isRemoveInProgress = true;
        await automationsStore.removeAutomation(automationsStore.currentAutomationId);
        state.isRemoveInProgress = false;
        closeDrawer();
        automationsStore.fetchAutomations(automationsStore.currentAutomationListType);
    };

    const getInstances = async () => {
        state.isGetInstancesInprogress = true;
        const res = await syncJobsStore.getInstances(automationsStore.currentAutomationId);
        state.isGetInstancesInprogress = false;

        res && instancesModal.open();
    };

    const renderActions = observer(() => {
        return automationsStore.currentAutomation ? (
            <Grid direction={"column"} container>
                {automationsStore.currentAutomationListType === TABLE_TYPE_USER && (
                    <>
                        {automationsStore.currentAutomation.enabled ? (
                            <BulkBarFlatButton
                                onClick={disableAutomation}
                                inProgress={state.isEnableDisableInProgress}
                                className={classes.iconDisable}
                                icon={<StatusIcon />}
                                id={`${idPrefix}_disable_automation_button`}
                            >
                                {t("slingshot.automations.drawer.actions.button.disable")}
                            </BulkBarFlatButton>
                        ) : (
                            <BulkBarFlatButton
                                onClick={enableAutomation}
                                inProgress={state.isEnableDisableInProgress}
                                className={classes.iconEnable}
                                icon={<StatusIcon />}
                                id={`${idPrefix}_enable_automation_button`}
                            >
                                {t("slingshot.automations.drawer.actions.button.enable")}
                            </BulkBarFlatButton>
                        )}
                        <BulkBarFlatButton
                            icon={<EditIcon />}
                            onClick={openEditAutomationModal}
                            id={`${idPrefix}_edit_automation_button`}
                        >
                            {t("slingshot.automations.drawer.actions.button.edit")}
                        </BulkBarFlatButton>
                    </>
                )}
                {status === t("slingshot.automations.table.label.status.waiting") && (
                    <>
                        <BulkBarFlatButton
                            icon={<RunIcon />}
                            onClick={runAutomation}
                            inProgress={state.isRunAutomationInProgress}
                            id={`${idPrefix}_run_automation_button`}
                        >
                            {t("slingshot.automations.drawer.actions.button.run_now")}
                        </BulkBarFlatButton>
                        <BulkBarFlatButton
                            icon={<TimeIcon />}
                            onClick={pauseAutomation}
                            inProgress={state.isPauseAutomationInProgress}
                            id={`${idPrefix}_pause_automation_button`}
                        >
                            {t("slingshot.automations.drawer.actions.button.pause")}
                        </BulkBarFlatButton>
                    </>
                )}
                {status === t("slingshot.automations.table.label.status.paused") && (
                    <BulkBarFlatButton
                        icon={<RunIcon />}
                        onClick={resumeAutomation}
                        inProgress={state.isResumeAutomationInProgress}
                        id={`${idPrefix}_resume_automation_button`}
                    >
                        {t("slingshot.automations.drawer.actions.button.resume")}
                    </BulkBarFlatButton>
                )}
                {status === t("slingshot.automations.table.label.status.running") && (
                    <>
                        <BulkBarFlatButton
                            icon={<CloseIcon />}
                            onClick={stopRunningAutomation}
                            inProgress={state.isStopRunningAutomationInProgress}
                            id={`${idPrefix}_stop_running_automation_button`}
                        >
                            {t("slingshot.automations.drawer.actions.button.stop_run")}
                        </BulkBarFlatButton>
                        <BulkBarFlatButton
                            icon={<TimeIcon />}
                            onClick={pauseAutomation}
                            inProgress={state.isPauseAutomationInProgress}
                            id={`${idPrefix}_pause_automation_button`}
                        >
                            {t("slingshot.automations.drawer.actions.button.pause")}
                        </BulkBarFlatButton>
                    </>
                )}
                <BulkBarFlatButton
                    icon={<ViewIcon />}
                    onClick={getInstances}
                    inProgress={state.isGetInstancesInProgress}
                    id={`${idPrefix}_get_automation_instances_button`}
                >
                    {t("slingshot_page.tabs.replications.drawer.actions.button.instances")}
                </BulkBarFlatButton>

                {automationsStore.currentAutomationListType === TABLE_TYPE_USER && (
                    <BulkBarFlatButton
                        icon={<TrashIcon />}
                        onClick={deleteAutomationModal.open}
                        inProgress={state.isRemoveInProgress}
                        errorColor
                        id={`${idPrefix}_delete_automation_button`}
                    >
                        {t("common.button.delete")}
                    </BulkBarFlatButton>
                )}
            </Grid>
        ) : null;
    });

    const renderDetails = observer(() => {
        return automationsStore.currentAutomation ? (
            <Box width="100%" p={6}>
                <Grid container direction={"column"} spacing={4}>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("slingshot.automations.drawer.details.label.name")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>{automationsStore.currentAutomation.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("slingshot.automations.drawer.details.label.id")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>{automationsStore.currentAutomation.id}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("slingshot.automations.drawer.details.label.state")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <StatusLabel status={status} />
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("slingshot.automations.drawer.details.label.launch_type")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>{automationType}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("slingshot.automations.drawer.details.label.time_to_start")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TimeToStart status={status} schedule={automationsStore.currentAutomation.schedule} />
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("slingshot.automations.drawer.details.label.active_tasks")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>{automationsStore.currentAutomation.running_tasks || 0}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("slingshot.automations.drawer.details.label.owner")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>{automationsStore.currentAutomation.owner}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("slingshot.automations.drawer.details.label.tasks_number")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>{automationsStore.currentAutomation.tasks.length || 0}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("slingshot.automations.drawer.details.label.folder")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>{folderToWatch(automationsStore.currentAutomation)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("slingshot.automations.drawer.details.label.tasks_list")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>{tasksList(automationsStore.currentAutomation)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        ) : null;
    });

    return (
        <DetailsActionsDrawer
            title={automationsStore.currentAutomation?.name}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            modals={
                <>
                    <EditAutomationModal
                        open={state.isEditAutomationModalOpen}
                        afterSubmitCallback={closeDrawer}
                        onClose={closeEditAutomationModal}
                        parentId={idPrefix}
                    />
                    <JobInstancesModal open={instancesModal.isOpen} onClose={instancesModal.close} parentId={idPrefix} />
                    <ConfirmationDialog
                        title={t("common.warning_dialog.title")}
                        open={deleteAutomationModal.isOpen}
                        onClose={deleteAutomationModal.close}
                        onConfirm={removeAutomation}
                        confirmLabel={t("common.button.proceed")}
                        cancelLabel={t("common.button.cancel")}
                        fullWidth
                        id={`${idPrefix}_confirmation_dialog`}
                    >
                        {t("slingshot.automations.drawer.confirm_delete")}
                    </ConfirmationDialog>
                </>
            }
        />
    );
};

export default observer(AutomationsDrawer);
