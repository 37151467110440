import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useStore } from "hooks/useStore";
import { useTranslation } from "react-i18next";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography, Box } from "@material-ui/core";
import TrashIcon from "assets/TrashIcon";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import StatusLabel from "../ArchivedTabContent/components/StatusLabel";
import moment from "moment";

const ARCHIVED_INSTANCES_DRAWER_ID_PREFIX = `archived_instances_drawer`;

const drawerName = "archivedInstancesDrawer";
const ArchivedInstancesDrawer = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${ARCHIVED_INSTANCES_DRAWER_ID_PREFIX}` : ARCHIVED_INSTANCES_DRAWER_ID_PREFIX;
    const { t } = useTranslation();

    const {
        store: { archivedInstancesStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isDeleteInstanceInProgress: false,
    }));

    const closeDrawer = () => {
        archivedInstancesStore.setCurrentInstanceId(null);
    };

    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: archivedInstancesStore.currentInstanceId });

    const removeInstance = async () => {
        state.isDeleteInstanceInProgress = true;
        const res = await archivedInstancesStore.deleteInstance(archivedInstancesStore.currentInstanceId);
        state.isDeleteInstanceInProgress = false;
        if (res) {
            closeDrawer();
            archivedInstancesStore.fetchArchivedInstances();
        }
    };

    const renderDetails = () => {
        return (
            archivedInstancesStore.currentInstanceId && (
                <Box py={4} px={6}>
                    <Grid container direction={"column"} spacing={4}>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"} spacing={2}>
                            <Grid item xs={6}>
                                <Typography>{t("slingshot.archived_instances.table.header.automation_name")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h6"}>
                                    {`${archivedInstancesStore.currentInstance.automation_name}(${archivedInstancesStore.currentInstance.automation_id})`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"} spacing={2}>
                            <Grid item xs={6}>
                                <Typography>{t("slingshot.archived_instances.table.header.status")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <StatusLabel status={archivedInstancesStore.currentInstance.result} />
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"} spacing={2}>
                            <Grid item xs={6}>
                                <Typography>{t("slingshot.archived_instances.table.header.started")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h6"}>
                                    {moment(archivedInstancesStore.currentInstance.started).toISOString()}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"} spacing={2}>
                            <Grid item xs={6}>
                                <Typography>{t("slingshot.archived_instances.table.header.finished")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h6"}>
                                    {moment(archivedInstancesStore.currentInstance.finished).toISOString()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )
        );
    };

    const renderActions = () => {
        return (
            archivedInstancesStore.currentInstanceId && (
                <Grid direction={"column"} container>
                    <BulkBarFlatButton
                        errorColor
                        icon={<TrashIcon />}
                        onClick={removeInstance}
                        inProgress={state.isDeleteInstanceInProgress}
                        id={`${idPrefix}_remove_instance`}
                    >
                        {t("common.button.delete")}
                    </BulkBarFlatButton>
                </Grid>
            )
        );
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            title={
                t("slingshot_page.tabs.replications.processed_files_modal.title", {
                    id: archivedInstancesStore.currentInstanceId,
                }) || ""
            }
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            id={`${idPrefix}_drawer`}
        />
    ));
};

export default ArchivedInstancesDrawer;
