import { types } from "mobx-state-tree";

import Request from "api/Request";
import SnmpServerSettings from "api/events/Types/SnmpServerSettings";

const SetSnmpServerSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Events",
    method: "set_snmp_server_settings",
    arguments: types.maybe(SnmpServerSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetSnmpServerSettings;
