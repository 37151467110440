import { types } from "mobx-state-tree";

import Rule from "api/firewall/Types/Rule";

const InterfaceRules = types.model({
    iface: types.string,
    policy: types.string,
    rules: types.array(Rule)
});

export default InterfaceRules;
