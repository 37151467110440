import React, { useContext, useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { autorun } from "mobx";

import { Context } from "store";
import { FAILED_NEED_FORCE } from "const/updatesConst";
import NeedForceUpgradeDialog from "../NeedForceUpgradeDialog";
import { ADMIN } from "const/userRolesConst";

const UpgradeBar = () => {
    const {
        store: { upgradesStore, authStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isNeedForceUpgradeDialogOpen: false
    }));

    useEffect(() => {
        if (upgradesStore.upgradeStepStatus === FAILED_NEED_FORCE && authStore.role === ADMIN) {
            state.isNeedForceUpgradeDialogOpen = true;
        }
    }, [upgradesStore.upgradeStepStatus]);

    useEffect(() => {
        autorun(() => {
            if (upgradesStore.isStartUpgradingError && authStore.role === ADMIN) {
                state.isNeedForceUpgradeDialogOpen = true;
            }
        });
    }, [upgradesStore.isStartUpgradingError]);

    const closeNeedForceUpgradeDialog = () => {
        state.isNeedForceUpgradeDialogOpen = false;
        upgradesStore.clearIsStartUpgradingError();
    };

    return useObserver(() => (
        <>
            <NeedForceUpgradeDialog onClose={closeNeedForceUpgradeDialog} open={state.isNeedForceUpgradeDialogOpen} />
        </>
    ));
};

export default UpgradeBar;
