import React from "react";
import MainTemplate from "components/MainTemplate";
import Grid from "@material-ui/core/Grid";
import NetworkServiceAdvertising from "./components/NetworkServiceAdvertising";
import DnsCard from "./components/DnsCard";
import EthernetPortsCard from "./components/EthernetPortsCard";
import AggregatedInterfacesCard from "./components/AggregatedInterfacesCard";
import VirtualSwitchCard from "./components/VirtualSwitchCard";
import PingTestCard from "./components/PingTestCard";
import WorldIcon from "assets/WorldIcon";
import HeaderTitle from "components/HeaderTitle";
import { NETWORK } from "const/shortcutNameConst";
import NetworkGraphCard from "pages/Dashboard/components/NetworkGraphCard";
import { useTranslation } from "react-i18next";

const Network = () => {
    const { t } = useTranslation();
    return (
        <MainTemplate
            tabIndex={1}
            headerTitle={
                <HeaderTitle
                    icon={<WorldIcon />}
                    title={t("network.title")}
                    shortcutName={NETWORK}
                />
            }
        >
            <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                    <NetworkServiceAdvertising />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <DnsCard />
                </Grid>
                <Grid item xs={12}>
                    <EthernetPortsCard />
                </Grid>
                <Grid item xs={12}>
                    <AggregatedInterfacesCard />
                </Grid>
                <Grid item xs={12}>
                    <VirtualSwitchCard />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <PingTestCard />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <NetworkGraphCard />
                </Grid>
            </Grid>
        </MainTemplate>
    );
};

export default Network;
