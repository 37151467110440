import { makeStyles } from "@material-ui/core/styles";
import { SPLASH_PAGE_CARD_CONTENT_HEIGHT, SPLASH_PAGE_CARD_HEIGHT, SPLASH_PAGE_CARD_WIDTH } from "const/styleConst";

export const useStyles = makeStyles((theme) => ({
    container: ({ forPlugin, fakeCard }) => ({
        height: forPlugin ? SPLASH_PAGE_CARD_HEIGHT / 2 - theme.spacing(3) : SPLASH_PAGE_CARD_HEIGHT,
        width: forPlugin ? SPLASH_PAGE_CARD_WIDTH / 2 - theme.spacing(3) : SPLASH_PAGE_CARD_WIDTH,
        display: "flex",
        flexDirection: "column",
        backgroundColor: fakeCard ? "transparent" : theme.palette.splashCard.background,
        border: fakeCard ? `1px dashed ${theme.palette.grey[600]}` : undefined,
    }),
    content: ({ forPlugin }) => ({
        height: forPlugin ? SPLASH_PAGE_CARD_CONTENT_HEIGHT / 2 - theme.spacing(3) : SPLASH_PAGE_CARD_CONTENT_HEIGHT,
        overflow: "hidden",
        padding: forPlugin ? theme.spacing(4, 10, 0, 5) : theme.spacing(8, 20, 0, 10),
        flex: 1,
    }),
    contentText: ({ forPlugin }) => ({
        fontSize: forPlugin ? ".8em" : undefined,
        overflow: "hidden",
        textOverflow: "ellipsis",
    }),
    contentTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    actions: ({ forPlugin }) => ({
        paddingBottom: forPlugin ? theme.spacing(2) : theme.spacing(12),
        alignItems: "flex-end",
        justifyContent: "center",
    }),
    header: ({ forPlugin }) => ({
        height: forPlugin ? "119px" : undefined,
        backgroundColor: theme.palette.background.default,
    }),
    actionButton: ({ forPlugin }) => ({
        fontSize: forPlugin ? ".8em" : "15px",
    }),
}));
