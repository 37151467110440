import { types } from "mobx-state-tree";

const NasSession = types.model({
    protocol: types.string,
    shareName: types.string,
    remote: types.string,
    speed: types.string
});

export default NasSession;
