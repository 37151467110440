import React from "react";
import {
    DESTROY,
    DESTROY_RESPONSE_STATUS_SUCCESS,
    RECOVERY,
    RECOVERY_FORCE,
    RECOVERY_RESPONSE_STATUS_ERROR,
    RECOVERY_RESPONSE_STATUS_NEED_FORCE
} from "const/backupConst";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import { useToggle } from "hooks/useToggle";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getCurrentBackupName } from "./BackupsTableDrawer.utils";
import localizations from "./BackupsTableDrawer.localizations";
import LocalizationTypography from "components/LocalizationTypography/LocalizationTypography";

export const useConfirmationDialogBackupTableDrawer = () => {
    const { t } = useTranslation();
    const {
        store: { backupSettingsStore }
    } = useStore();
    const confirmationModal = useModal();

    const [isRecoveryProgress, toggleRecoveryProgress] = useToggle();
    const [isDestroyProgress, toggleDestroyProgress] = useToggle();

    async function destroyBackup() {
        confirmationModal.close();
        toggleDestroyProgress(true);
        const repsonseDestroy = await backupSettingsStore.destroyBackup();
        await backupSettingsStore.fetchEvoId();
        await backupSettingsStore.fetchDevices();
        toggleDestroyProgress(false);

        switch (repsonseDestroy) {
            case DESTROY_RESPONSE_STATUS_SUCCESS:
                closeDrawer();
                return;
            default:
                return;
        }
    }
    async function recoveryBackup() {
        confirmationModal.close();
        toggleRecoveryProgress(true);

        const responseRecovery = await backupSettingsStore.recoverBackup();

        switch (responseRecovery) {
            case RECOVERY_RESPONSE_STATUS_NEED_FORCE:
                toggleRecoveryProgress(false);
                setConfirmContext(contextConfirms[RECOVERY_FORCE]);
                confirmationModal.open();
                return;
            case RECOVERY_RESPONSE_STATUS_ERROR:
                toggleRecoveryProgress(false);
                return;
            default:
                return;
        }
    }
    async function recoveryBackupForce() {
        confirmationModal.close();
        toggleRecoveryProgress(true);

        const responseRecovery = await backupSettingsStore.recoverBackup(true);

        switch (responseRecovery) {
            case RECOVERY_RESPONSE_STATUS_ERROR:
                toggleRecoveryProgress(false);
                return;
            default:
                return;
        }
    }
    function closeDrawer() {
        backupSettingsStore.setCurrentBackupPath(null);
    }

    /** @type {[(ContextConfirm|null), function]} */
    const [confirmContext, setConfirmContext] = useState(null);

    /**
@typedef {{
text: string | JSX.Element,
confirmLabel: string,
onConfirm: function
}} ContextConfirm
*/

    /**
@type{{
[RECOVERY]: ContextConfirm,
[DESTROY]: ContextConfirm,
}}
*/
    const contextConfirms = {
        [RECOVERY]: {
            title: t("common.dialog_title.warning"),
            text: t("support.os_data.backups.recovery.confirm_dialog.text"),
            confirmLabel: t("support.os_data.backups.recovery.confirm_dialog.confirm_label"),
            onConfirm: recoveryBackup
        },
        [RECOVERY_FORCE]: {
            title: t("common.warning_dialog.title"),
            text: <LocalizationTypography paragraphs={localizations.confirmDialogTextForce.paragraphs} />,
            confirmLabel: t("support.os_data.backups.recovery.confirm_dialog.confirm_label_force"),
            onConfirm: recoveryBackupForce
        },
        [DESTROY]: {
            title: t("common.dialog_title.warning"),
            text: t("support.os_data.backups.destroy.confirm_dialog.text", {
                backupname: getCurrentBackupName(backupSettingsStore)
            }),
            confirmLabel: t("support.os_data.backups.destroy.confirm_dialog.confirm_label"),
            onConfirm: destroyBackup
        }
    };

    /** Handle open confirmation dialog
     * @param {(RECOVERY|DESTROY)} method
     */
    const onOpen = method => () => {
        confirmationModal.open();
        setConfirmContext(contextConfirms[method]);
    };

    return {
        context: confirmContext,
        isRecoveryProgress: isRecoveryProgress,
        isDestroyProgress: isDestroyProgress,
        open: {
            [DESTROY]: onOpen(DESTROY),
            [RECOVERY]: onOpen(RECOVERY)
        },
        isOpen: confirmationModal.isOpen,
        close: confirmationModal.close
    };
};
