import React from "react";
import { observer } from "mobx-react";
import { Grid, Paper, Typography, Box, Link } from "@material-ui/core";
import SnsLogo from "assets/SnsLogo";
import { useStyles } from "./BootLoadPage.style";
import { useTranslation } from "react-i18next";
import SupportButton from "components/SupportButton";
import { useStore } from "hooks/useStore";
import { useLocation } from "react-router-dom";
import { BOOT_LOAD_PAGE_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { SNS_WEBSITE } from "const";

const BootLoadPage = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();

    const {
        store: { bootProgressStore },
    } = useStore();

    const hasSupportQuery = location.search === "?support=true";

    return (
        <Grid container direction={"column"} className={classes.container} wrap={"nowrap"}>
            <Grid item container justify={"center"} className={classes.logoContainer} alignItems={"center"}>
                <Link rel="noopener" target="_blank" href={SNS_WEBSITE} variant="button">
                    <SnsLogo />
                </Link>
            </Grid>
            <Grid item container justify={"center"}>
                <Paper className={classes.card}>
                    <Box p={10}>
                        <Typography align={"center"} className={classes.title}>
                            {bootProgressStore.getTitle}
                        </Typography>
                        {bootProgressStore.message && bootProgressStore.status && !bootProgressStore.isError && (
                            <Typography align={"center"} className={classes.message}>
                                {t("boot_load.message", { status: bootProgressStore.status, message: bootProgressStore.message })}
                            </Typography>
                        )}
                        {bootProgressStore.progress && !bootProgressStore.isError && (
                            <Typography align={"center"} className={classes.progress} variant={"h6"}>
                                {bootProgressStore.progress}
                            </Typography>
                        )}
                    </Box>
                </Paper>
            </Grid>
            {hasSupportQuery && (
                <Grid className={classes.supportButtonContainer}>
                    <SupportButton id={BOOT_LOAD_PAGE_PAGE_ID_PREFIX} />
                </Grid>
            )}
        </Grid>
    );
};

export default observer(BootLoadPage);
