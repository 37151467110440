import React from "react";
import { useObserver } from "mobx-react";
import Dialog from "components/Dialog";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const DeleteVPNConnectionDialog = ({ open, onClose, connectionName, onAccept, inProgress }) => {
    const { t } = useTranslation();

    const onKeyDown = (e) => {
        e.key === "Escape" && onClose();
    };

    const deleteTitleText = t("wireguard.table.drawer.dialog.title.delete");
    const deleteBodyText = t("wireguard.table.drawer.dialog.text.delete");

    const title = `${deleteTitleText} ${connectionName || ""}`;
    const text = `${deleteBodyText} ${connectionName || ""}?`;

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={title}
            onKeyDown={onKeyDown}
            submitBtnLabel={deleteTitleText}
            onSubmit={onAccept}
            inProgress={inProgress}
            buttons={[
                <Button key="cancelBtn" onClick={onClose} variant={"contained"} color="secondary">
                    {t("common.button.cancel")}
                </Button>,
            ]}
            withoutIconClose
            withoutDividers
        >
            {text}
        </Dialog>
    ));
};

export { DeleteVPNConnectionDialog };
