import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    dialogContent: {
        padding: ({ withoutPaddings }) => (withoutPaddings ? 0 : theme.spacing(4, 6, 8)),
        "& > .MuiGrid-item.MuiGrid-container:last-child": {
            paddingBottom: 0
        },
        "& > .MuiGrid-item.MuiGrid-container:first-child": {
            paddingTop: 0
        }
    },
    titleText: {
        wordBreak: "break-all"
    },
    hideDialog: {
        display: "none"
    }
}));
