import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    userRow: {
        "& svg path": {
            fill: theme.palette.grey[300]
        }
    },
    trashIconCell: {
        cursor: "pointer"
    }
}));
