import { types } from "mobx-state-tree";

import Request from "api/Request";
import NetRenameArguments from "api/net/Types/NetRenameArguments";

const RenameIfaces = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "rename_ifaces",
    arguments: types.maybe(NetRenameArguments),
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default RenameIfaces;
