import React from "react";
import { observer } from "mobx-react";
import { useStore } from "hooks/useStore";
import { Grid, Typography } from "@material-ui/core";
import i18next from "i18next";
import { useStyles } from "./RenderDetails.styles";

const RenderDetails = () => {
    const classes = useStyles();
    const {
        store: { slingshotSettingsStore },
    } = useStore();

    if (!slingshotSettingsStore.currentWatermark) return null;

    return (
        <Grid item container className={classes.body} direction={"column"} spacing={4}>
            <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                <Grid item xs={6}>
                    <Typography>{i18next.t("slingshot_page.tabs.settings.watermarks.column.name")}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.textInfo}>{slingshotSettingsStore.currentWatermark.name}</Typography>
                </Grid>
            </Grid>

            {/*[SLN-379] Hide Builtin, Personal, Disabled, Image loaded, Owner */}

            <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                <Grid item xs={6}>
                    <Typography>{i18next.t("slingshot_page.tabs.settings.watermarks.column.created")}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.textInfo}>{slingshotSettingsStore.currentWatermark.created}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default observer(RenderDetails);
