import React, { useEffect } from "react";
import DataCard from "components/DataCard";
import { ButtonBase, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import ChevronRightIcon from "assets/ChevronRightIcon";
import PlusIcon from "assets/PlusIcon";
import TableRowControlButton from "components/TableRowControlButton";
import TableCellWithIcon from "components/TableCellWithIcon";
import { useTranslation } from "react-i18next";
import CreateBridgeDialog from "../CreateBridgeDialog";
import { useLocalStore, useObserver } from "mobx-react-lite";
import SwitchIcon from "assets/SwitchIcon";
import TableSortLabel from "components/TableSortLabel";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { VIRTUAL_SWITCH_CARD } from "const/widgetNameConst";
import {
    NET_SWITCH_GATEWAY,
    NET_SWITCH_INTERFACES,
    NET_SWITCH_IP,
    NET_SWITCH_STATUS,
    NET_SWITCH_SUBNET
} from "const/sortColumnConst";
import { useStyles } from "./VirtualSwitchCard.styles";
import StatusTranslation from "../StatusTranslation";
import BridgeDrawer from "../BridgeDrawer";
import useStoreByIp from "hooks/useStoreByIp";
import HideOverlay from "components/HideOverlay/HideOverlay";

const VirtualSwitchCard = ({ ip }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { bridgeStore } = useStoreByIp({ ip });
    const state = useLocalStore(() => ({
        isDialogOpen: false
    }));
    useEffect(() => {
        bridgeStore.fetchBridgesArrayResult();
    }, []);

    const openDialog = () => {
        state.isDialogOpen = true;
    };
    const closeDialog = () => {
        state.isDialogOpen = false;
    };
    const changeSorting = column => () => bridgeStore.changeSorting(column);

    const selectBridge = port => () => bridgeStore.setCurrentBridge(port);

    return useObserver(() => (
        <DataCard
            title={t("network.virtual_switch.title")}
            headerDivider
            emptyPadding
            headerControl={[
                <HideOverlay show={!ip} key={`data_card-header_control-control-1`}>
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={VIRTUAL_SWITCH_CARD} />
                    </MoreMenu>
                </HideOverlay>,
                <ButtonBase onClick={openDialog} key={`data_card-header_control-control-2`}>
                    <PlusIcon onClick={openDialog} />
                </ButtonBase>
            ]}
        >
            <BridgeDrawer ip={ip} />
            <CreateBridgeDialog ip={ip} onClose={closeDialog} open={state.isDialogOpen} />
            {bridgeStore.sortedBridgesArray.length ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    direction={bridgeStore.order}
                                    onClick={changeSorting(NET_SWITCH_INTERFACES)}
                                    active={bridgeStore.orderBy === NET_SWITCH_INTERFACES}
                                >
                                    {t("network.table_header.interface")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={bridgeStore.order}
                                    onClick={changeSorting(NET_SWITCH_IP)}
                                    active={bridgeStore.orderBy === NET_SWITCH_IP}
                                >
                                    {t("network.table_header.ip_address")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={bridgeStore.order}
                                    onClick={changeSorting(NET_SWITCH_SUBNET)}
                                    active={bridgeStore.orderBy === NET_SWITCH_SUBNET}
                                >
                                    {t("network.table_header.subnet")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={bridgeStore.order}
                                    onClick={changeSorting(NET_SWITCH_GATEWAY)}
                                    active={bridgeStore.orderBy === NET_SWITCH_GATEWAY}
                                >
                                    {t("network.table_header.gateway")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={bridgeStore.order}
                                    onClick={changeSorting(NET_SWITCH_STATUS)}
                                    active={bridgeStore.orderBy === NET_SWITCH_STATUS}
                                >
                                    {t("network.table_header.status")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bridgeStore.sortedBridgesArray.map(row => (
                            <TableRow key={row.interfaces.join(", ")} onClick={selectBridge(row.port)}>
                                <TableCellWithIcon icon={<SwitchIcon />} spacing={4}>
                                    {row.port}
                                </TableCellWithIcon>
                                <TableCell>{row.ip}</TableCell>
                                <TableCell>{row.netmask}</TableCell>
                                <TableCell>{row.gateway}</TableCell>
                                <TableCell>
                                    <StatusTranslation label={row.status} />
                                </TableCell>
                                <TableCell align={"right"}>
                                    <TableRowControlButton icon={<ChevronRightIcon />} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Grid container className={classes.noMessageContainer}>
                    <Grid item>
                        <Typography>{t("network.virtual_switch.no_content")}</Typography>
                    </Grid>
                </Grid>
            )}
        </DataCard>
    ));
};

export default VirtualSwitchCard;
