import { types } from "mobx-state-tree";

const RESULT_ENUM_ARRAY = ["IN PROGRESS", "SUCCEED", "CANCELED", "FAILED", "PAUSED", "WAITED FOR RESTART", "PARTIALLY FAILED"];

const InstanceType = types.model({
    automation_id: types.maybe(types.number),
    automation_name: types.maybe(types.string),
    id: types.maybe(types.number),
    started: types.maybe(types.string),
    finished: types.maybe(types.string),
    result: types.maybe(types.enumeration(RESULT_ENUM_ARRAY))
});

export default InstanceType;
