import Request from "api/Request";

const ExportFirewallRules = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Firewall",
    method: "export_firewall_rules"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ExportFirewallRules;
