import React, { useContext } from "react";
import { useObserver } from "mobx-react";
import { Grid, Box, Typography, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Context } from "store";
import { convertBytesToSizeUnit } from "utils/convertBytesToSizeUnit";
import { useStyles } from "./StorageStatus.style";

const StorageStatus = ({ thickness = 4, size = 48 }) => {
    const { t } = useTranslation();
    const {
        store: { storageUsageGraphStore }
    } = useContext(Context);
    const classes = useStyles({ value: storageUsageGraphStore.lastValue });

    return useObserver(() => {
        const total = convertBytesToSizeUnit({ bytes: storageUsageGraphStore.lastTotal, exponent: 3 });
        const used = convertBytesToSizeUnit({
            bytes: storageUsageGraphStore.lastUsed,
            exponent: 3,
            output: "object"
        });

        return (
            <Grid container alignItems={"center"} className={classes.container} justify={"flex-end"} spacing={3} wrap={"nowrap"}>
                <Grid item className={classes.circularContainer}>
                    <Box className={classes.circularBase}>
                        <Typography color={"textSecondary"} className={classes.progressText}>
                            {storageUsageGraphStore.lastValue || 0}%
                        </Typography>
                        <CircularProgress
                            className={classes.circularProgressBottom}
                            variant={"determinate"}
                            value={100}
                            thickness={thickness}
                            size={size}
                        />
                        <CircularProgress
                            className={classes.circularProgress}
                            variant={"determinate"}
                            value={storageUsageGraphStore.lastValue}
                            thickness={thickness}
                            size={size}
                        />
                    </Box>
                </Grid>

                <Grid item className={classes.dropDownContainer}>
                    <Grid container spacing={2} direction={"column"}>
                        <Box>
                            <Typography color="textSecondary" className={classes.title}>
                                {t("header.storage")}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography className={classes.storageText}>{`${used.value}/${total}`}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        );
    });
};

export default StorageStatus;
