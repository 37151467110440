// type
export const USER = "user";
export const VOLUME_BUILDING = "volume_building";
export const VOLUME_GROW = "volume_grow";
export const VOLUME_REMOVE = "volume_remove";
export const VOLUME_PUT_ONLINE = "volume_online";
export const VOLUME_PUT_OFFLINE = "volume_offline";
export const SHARE_REMOVE = "share_remove";
export const SHARE_UNMAP = "share_unmap";

// status
export const STARTED = "started";
export const FINISHED = "finished";
export const FAILED = "failed";
