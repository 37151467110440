import React from "react";
import { Typography, Divider, Grid, CircularProgress, InputAdornment, ButtonBase, Popover } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./SearchLine.styles";
import TextInput from "components/MobxForm/TextInput";
import { SEARCH_DEFAULTS, LOCAL_USERS, EXTERNAL_USERS, LOCAL_GROUPS, EXTERNAL_GROUPS } from "const/userSearchVariants";
import CloseIcon from "assets/CloseIcon";
import ChevronDownIcon from "assets/ChevronDownIcon";
import LocalGroupIcon from "assets/LocalGroupIcon";
import TablePagination from "components/TablePagination";
import { USERS_SEARCH_SELECT_RELATIVE_POSITION_TOP } from "const/styleConst";
import clsx from "clsx";
import useStoreByIp from "hooks/useStoreByIp";

const SearchLine = ({ form, onSelectUser, ip }) => {
    const { t } = useTranslation();

    const { userStore } = useStoreByIp({ ip });

    const state = useLocalStore(() => ({
        isSearchInProgress: false,
        anchorEl: null,
        isSelectOpen: false
    }));
    const classes = useStyles({ isSelectOpen: state.isSelectOpen });

    const closeSelect = () => {
        state.isSelectOpen = false;
    };

    const search = async () => {
        state.isSearchInProgress = true;
        userStore.resetSearchByTypeResult();
        userStore.updateSearchByTypeRequest("type", form.$("type").value);
        userStore.updateSearchByTypeRequest("page", SEARCH_DEFAULTS.page);
        userStore.updateSearchByTypeRequest("name", form.$("name").value);
        await userStore.searchByType();
        state.isSearchInProgress = false;
        state.isSelectOpen = true;
    };

    const keyDownHandler = async e => {
        if (!state.anchorEl) {
            state.anchorEl = e.currentTarget;
        }
        e.key === "Enter" && search();
    };

    const onInputClick = e => {
        !form.$("name").isValid && form.$("name").resetValidation();
        if (!state.anchorEl) {
            state.anchorEl = e.currentTarget;
        }
    };

    const resetNameField = () => {
        form.$("name").reset();
        form.$("domain").reset();
        userStore.resetSearchByTypeResult();
    };

    const handleChangePage = async newPage => {
        state.isSearchInProgress = true;
        userStore.updateSearchByTypeRequest("page", +newPage);
        await userStore.searchByType();
        state.isSearchInProgress = false;
    };

    const onOptionClick = (name, domain) => () => {
        form.$("name").set("value", name);
        form.$("domain").set("value", domain);
        closeSelect();
        if (onSelectUser) {
            onSelectUser();
        }
    };

    const getTypeLabel = () => {
        switch (form.$("type").value) {
            case LOCAL_USERS:
                return t("users.common.local_users");
            case EXTERNAL_USERS:
                return t("users.common.external_users");
            case LOCAL_GROUPS:
                return t("users.common.local_groups");
            case EXTERNAL_GROUPS:
                return t("users.common.external_groups");
            default:
                return t("users.common.local_users");
        }
    };

    const getInputAdornment = () => {
        return (
            <InputAdornment position="end">
                <Grid container wrap={"nowrap"} alignItems={"center"} justify={"flex-end"} spacing={2}>
                    {!form.$("name").isEmpty ? (
                        <Grid item>
                            <ButtonBase className={classes.closeIcon} onClick={resetNameField}>
                                <CloseIcon />
                            </ButtonBase>
                        </Grid>
                    ) : null}

                    <Grid item>
                        <Grid container alignItems={"center"} className={classes.searchIconContainer}>
                            {state.isSearchInProgress ? (
                                <CircularProgress size={22} />
                            ) : (
                                <ButtonBase className={clsx(classes.primaryColorIcon, classes.dropdownIcon)} onClick={search}>
                                    <ChevronDownIcon />
                                </ButtonBase>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </InputAdornment>
        );
    };

    return useObserver(() => (
        <React.Fragment>
            <TextInput
                autoFocus
                className={classes.input}
                onClick={onInputClick}
                placeholder={
                    ((form.$("type").value === LOCAL_USERS || form.$("type").value === EXTERNAL_USERS) &&
                        t("share.edit_permissions.input.name_placeholder")) ||
                    t("share.edit_permissions.input.group_placeholder")
                }
                field={form.$("name")}
                showErrorText={Boolean(form.$("name").error)}
                helperText={form.$("name").error}
                InputProps={{
                    endAdornment: getInputAdornment()
                }}
                onKeyDown={keyDownHandler}
            />
            <Popover
                PaperProps={{ className: classes.selectPaper }}
                open={state.isSelectOpen}
                anchorEl={state.anchorEl}
                onClose={closeSelect}
                anchorOrigin={{ vertical: USERS_SEARCH_SELECT_RELATIVE_POSITION_TOP, horizontal: "center" }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                {userStore.searchByTypeResult?.total > 0 ? (
                    <React.Fragment>
                        {userStore.searchByTypeResult?.data.map(entity => (
                            <Grid
                                container
                                className={classes.selectItem}
                                onClick={onOptionClick(entity.name, entity.domain)}
                                key={entity.name + entity.domain}
                                alignItems={"center"}
                                wrap={"nowrap"}
                            >
                                <LocalGroupIcon className={classes.selectIcon} />
                                <Typography noWrap>
                                    {entity.isExternal ? `${entity.name}@${entity.domain}` : entity.name}
                                </Typography>
                            </Grid>
                        ))}
                    </React.Fragment>
                ) : (
                    <Grid className={classes.emptyResponse} container alignItems={"center"} justify={"center"}>
                        <Typography className={classes.emptyResponseText}>
                            {t("users.search_results.empty_table_placeholder", { type: getTypeLabel() })}
                        </Typography>
                    </Grid>
                )}

                {userStore.searchByTypeResult && userStore.searchByTypeResult.total > SEARCH_DEFAULTS.limit ? (
                    <React.Fragment>
                        <Divider />
                        <TablePagination
                            className={classes.pagination}
                            onChangePage={handleChangePage}
                            count={userStore.searchByTypeResult?.total}
                            page={userStore.searchByTypeRequest?.page}
                            rowsPerPage={userStore.searchByTypeRequest?.limit}
                            rowsPerPageOptions={[10]}
                            labelDisplayedRows={() => {}}
                        />
                    </React.Fragment>
                ) : null}
            </Popover>
        </React.Fragment>
    ));
};

export default SearchLine;
