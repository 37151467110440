import { Grid, Typography } from "@material-ui/core";
import HddIcon from "assets/HddIcon";
import SsdIcon from "assets/SsdIcon";
import UnknownDiskIcon from "assets/UnknownDiskIcon";
import StatusLabel from "components/StatusLabel";
import React from "react";
import { useTranslation } from "react-i18next";

const RenderDisk = ({ drive, quad, type, status }) => {
    const { t } = useTranslation();

    const toUiStatus = status => {
        switch (status) {
            case "online":
                return "good";
            case "failed":
                return "error";
            default:
                return "warning";
        }
    };

    const title = t("troubleshooting.drive_title", {
        quad,
        drive
    });
    const UiStatus = toUiStatus(status);
    const statusText = t(status);

    return (
        <Grid item container direction={"row"} alignItems={"center"} spacing={6} xs={12}>
            <Grid item>
                <RenderIcon type={type} />
            </Grid>
            <Grid item>
                <Typography>{title}</Typography>
            </Grid>
            <Grid item>
                <StatusLabel text={statusText} status={UiStatus} />
            </Grid>
        </Grid>
    );
};

const RenderIcon = ({ type }) => {
    switch (type) {
        case "ssd":
            return <SsdIcon />;
        case "hdd":
            return <HddIcon />;
        default:
            return <UnknownDiskIcon />;
    }
};

export default RenderDisk;
