import { types } from "mobx-state-tree";

import Request from "api/Request";
import UpdateArguments from "api/system_components/sharebrowser_server/Types/UpdateArguments";

const UpdateSharebrowserServer = Request.props({
    path: "/com/studionetworksolutions/evo/manage/SharebrowserServer",
    method: "update_sharebrowser_server",
    arguments: types.maybe(UpdateArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default UpdateSharebrowserServer;
