import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import createFields from "./createFields";
import createForm from "utils/createForm";
import TextInput from "components/MobxForm/TextInput";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import { URL_LOGIN } from "routes";
import { useStyles } from "./AccessCredentialsDialog.styles";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const ACCESS_CREDENTIALS_DIALOG_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_access_credentials_dialog`;

const AccessCredentialsDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const {
        store: { webserverStore },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
            options: { validateOnInit: false, validateOnBlur: false },
        }),
        isSubmitted: false,
    }));

    const unsavedChangesModal = useModal();

    const onSubmit = async (e) => {
        state.form.onSubmit(e);

        if (!state.form.isValid) {
            return;
        }

        const res = await webserverStore.changeCredentials({
            currentAdminCredentials: {
                login: state.form.$("currentLogin").value,
                password: state.form.$("currentPassword").value,
            },
            newAdminCredentials: { login: state.form.$("newLogin").value, password: state.form.$("newPassword").value },
        });

        if (res) {
            history.push(URL_LOGIN);
            state.isSubmitted = true;
            return res;
        }
    };

    const closeModal = () => {
        state.form.reset();
        onClose();
    };

    const onEnter = () => {
        state.isSubmitted = false;
        unsavedChangesModal.close();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            closeModal();
            return;
        }

        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            title={t("admin_settings.web_access_credentials.title")}
            onSubmit={onSubmit}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${ACCESS_CREDENTIALS_DIALOG_ID_PREFIX}_save`}
            open={open}
            onClose={closeIfNeeded}
            onEnter={onEnter}
            buttons={
                <Button
                    onClick={closeModal}
                    variant={"contained"}
                    color={"secondary"}
                    id={`${ACCESS_CREDENTIALS_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"}>
                <RowTextInput
                    label={t("admin_settings.web_access_credentials.label.current_login")}
                    control={
                        <TextInput
                            field={state.form.$("currentLogin")}
                            id={`${ACCESS_CREDENTIALS_DIALOG_ID_PREFIX}_current_login`}
                        />
                    }
                />
                <RowTextInput
                    className={classes.rowWithSpacing}
                    label={t("admin_settings.web_access_credentials.label.current_password")}
                    control={
                        <TextInput
                            field={state.form.$("currentPassword")}
                            id={`${ACCESS_CREDENTIALS_DIALOG_ID_PREFIX}_current_password`}
                        />
                    }
                />
                <RowTextInput
                    label={t("admin_settings.web_access_credentials.label.new_login")}
                    control={
                        <TextInput field={state.form.$("newLogin")} id={`${ACCESS_CREDENTIALS_DIALOG_ID_PREFIX}_new_login`} />
                    }
                />
                <RowTextInput
                    label={t("admin_settings.web_access_credentials.label.new_password")}
                    control={
                        <TextInput
                            field={state.form.$("newPassword")}
                            id={`${ACCESS_CREDENTIALS_DIALOG_ID_PREFIX}_new_password`}
                        />
                    }
                />
                <RowTextInput
                    label={t("admin_settings.web_access_credentials.label.confirm_password")}
                    control={
                        <TextInput
                            field={state.form.$("confirmPassword")}
                            id={`${ACCESS_CREDENTIALS_DIALOG_ID_PREFIX}_confirm_password`}
                        />
                    }
                />
            </Grid>
            <UnsavedChangesDialog onConfirm={closeModal} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default AccessCredentialsDialog;
