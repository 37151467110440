import config from "config/config";
import { destroy, types, isAlive, isStateTreeNode } from "mobx-state-tree";

const checkMapEvos = self => {
    if (!self.evos) {
        throw new Error(`Property of model the evos is not implemented in the  ${self.$treenode.type.name} type`);
    }
};
const checkActionCreateStore = self => {
    if (typeof self.createStore !== "function") {
        throw new Error(`The createStore action is not implemented in the ${self.$treenode.type.name} type`);
    }
};
export const CommonStoreModel = types.model("CommonStoreModel", {}).actions(self => ({
    getEvo(ip = config.path) {
        checkMapEvos(self);
        if (self.evos.has(ip)) {
            const evo = self.evos.get(ip);

            return isAlive(evo) ? evo : self.addEvo(ip);
        }

        return self.addEvo(ip);
    },
    addEvo(ip = config.path) {
        checkActionCreateStore(self);
        checkMapEvos(self);

        const newStore = self.createStore(ip);
        self.evos.set(ip, newStore);
        return newStore;
    },
    removeEvo(ip = config.path) {
        checkMapEvos(self);

        const evo = self.evos.get(ip);

        if (isStateTreeNode(evo) && isAlive(evo)) {
            destroy(evo);
        }
    },
    destroy() {
        destroy(self);
    }
}));
