import React from "react";

const CloseIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="nonzero"
                d="M17.657 6.343a1 1 0 0 1 0 1.414L13.415 12l4.242 4.244a1 1 0 0 1-1.414 1.414L12 13.414l-4.243 4.243a1 1 0 0 1-1.414-1.414L10.586 12 6.343 7.757a1 1 0 0 1 1.414-1.414L12 10.586l4.243-4.243a1 1 0 0 1 1.414 0z"
            />
        </svg>
    );
};

export default CloseIcon;
