import Request from "api/Request";

const GetConfig = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Indexing1",
    method: "get_config"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetConfig;
