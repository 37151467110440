import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: ({ isDesktop }) => ({
        display: "grid",
        gridTemplateAreas: isDesktop ? "'card card card card'" : "'card card'",
        width: "944px",
        justifyContent: "center"
    }),
    card: {
        padding: theme.spacing(3),
        grid: "card"
    }
}));
