import React from "react";
import MainTemplate from "components/MainTemplate";
import HeaderTitle from "components/HeaderTitle";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TimeIcon from "assets/TimeIcon";
import TimeSettingsCard from "./components/TimeSettingsCard";
import { TIME_SETTINGS } from "const/shortcutNameConst";

const TimeSettings = () => {
    const { t } = useTranslation();

    return (
        <MainTemplate
            headerTitle={<HeaderTitle icon={<TimeIcon />} title={t("time_settings.title")} shortcutName={TIME_SETTINGS} />}
        >
            <Grid container spacing={6}>
                <Grid item lg={6}>
                    <TimeSettingsCard />
                </Grid>
            </Grid>
        </MainTemplate>
    );
};

export default TimeSettings;
