import React, { useEffect, useContext, useCallback } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import SideBulkBar from "components/SideBulkBar";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import TrashIcon from "assets/TrashIcon";
import { useStyles } from "./RulesBulkBar.styles";
import IscsiIcon from "assets/IscsiIcon";
import StatusIcon from "assets/StatusIcon";
import EditRuleOrderDialog from "../EditRuleOrderDialog";
import CreateEditRuleDialog from "../CreateEditRuleDialog";
import EditIcon from "assets/EditIcon";
import { useModal } from "hooks/useModal";
import ConfirmationDialog from "components/ConfirmationDialog";

const drawerName = "rulesBulkBar";
const RulesBulkBar = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: { firewallStore, uiStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isDeleteInProgress: false,
        isChangeStatusRulesInProgress: false,
        isEditRuleOrderDialogOpen: false,
        isEditRuleDialogOpen: false,
        isUpdateRuleInProgress: false,
        isEditRuleOrderInProgress: false,
        get currentRules() {
            const currentIfaceRules = firewallStore.firewallInterfacesRules?.find(
                iface => iface.iface === firewallStore.currentIfaceName
            )?.rules;
            return currentIfaceRules?.filter((rule, index) => firewallStore.checkedRules.includes(index)) || [];
        },
        get hasDisabledStatus() {
            return state.currentRules?.find(el => !el.enabled);
        }
    }));

    useEffect(() => {
        if (firewallStore.checkedRules.length > 0) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, onClose);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
            uiStore.changeDrawer();
        }
    }, [firewallStore.checkedRules.length]);

    const deleteRulesModal = useModal();

    const onClose = useCallback(() => {
        firewallStore.clearCheckedRules();
    });

    const onChangeStatusRules = useCallback(status => () => {
        state.isChangeStatusRulesInProgress = true;

        let interfacesRules = firewallStore.interfacesRulesList;
        const ifaceIndex = interfacesRules.findIndex(el => el.iface === firewallStore.currentIfaceName);
        const interfacesRulesList = interfacesRules?.find(iface => iface.iface === firewallStore.currentIfaceName)?.rules;
        interfacesRulesList.map((el, index) => {
            if (firewallStore.checkedRules.includes(index)) el.enabled = status;
            return el;
        });
        interfacesRules[ifaceIndex] = { ...interfacesRules[ifaceIndex], rules: interfacesRulesList };

        const res = firewallStore.setFirewallRules({ interfacesRules: interfacesRules });
        if (res) {
            firewallStore.getRollbackTime();
            firewallStore.fetchFirewallRules();
            onClose();
        }
        state.isChangeStatusRulesInProgress = false;
    });

    const deleteRules = useCallback(() => {
        deleteRulesModal.close();
        state.isDeleteInProgress = true;
        let interfacesRules = firewallStore.interfacesRulesList;
        const ifaceIndex = interfacesRules.findIndex(el => el.iface === firewallStore.currentIfaceName);
        const interfacesRulesList = interfacesRules?.find(iface => iface.iface === firewallStore.currentIfaceName)?.rules;
        const newRulesList = interfacesRulesList?.filter((rule, index) => {
            if (!firewallStore.checkedRules.includes(index)) return rule;
        });
        interfacesRules[ifaceIndex] = { ...interfacesRules[ifaceIndex], rules: newRulesList };

        const res = firewallStore.setFirewallRules({ interfacesRules: interfacesRules });
        if (res) {
            firewallStore.getRollbackTime();
            firewallStore.fetchFirewallRules();
            onClose();
        }
        state.isDeleteInProgress = false;
    });

    const openEditOrderRuleDialog = useCallback(() => {
        state.isEditRuleOrderDialogOpen = true;
    });

    const closeEditOrderRuleDialog = useCallback(() => {
        state.isEditRuleOrderDialogOpen = false;
    });

    const openEditRuleDialog = () => {
        state.isEditRuleDialogOpen = true;
    };

    const closeEditRuleDialog = () => {
        state.isEditRuleDialogOpen = false;
    };

    const updateRuleProgress = useCallback(progress => {
        state.isUpdateRuleInProgress = progress;
    });

    const updateRuleOrderProgress = progress => {
        state.isEditRuleOrderInProgress = progress;
    };

    return useObserver(() => (
        <>
            <SideBulkBar
                itemsCount={firewallStore.checkedRules.length}
                onClose={onClose}
                isOpen={uiStore.openedDrawer === drawerName}
            >
                {firewallStore.checkedRules.length === 1 && (
                    <BulkBarFlatButton inProgress={state.isUpdateRuleInProgress} onClick={openEditRuleDialog} icon={<EditIcon />}>
                        {t("firewall.rules.action.edit")}
                    </BulkBarFlatButton>
                )}
                {state.hasDisabledStatus ? (
                    <BulkBarFlatButton
                        inProgress={state.isChangeStatusRulesInProgress}
                        className={classes.iconEnable}
                        onClick={onChangeStatusRules(true)}
                        icon={<StatusIcon />}
                    >
                        {t("firewall.rules.action.enable_rule")}
                    </BulkBarFlatButton>
                ) : (
                    <BulkBarFlatButton
                        inProgress={state.isChangeStatusRulesInProgress}
                        className={classes.iconDisable}
                        onClick={onChangeStatusRules(false)}
                        icon={<StatusIcon />}
                    >
                        {t("firewall.rules.action.disable_rule")}
                    </BulkBarFlatButton>
                )}
                {firewallStore.checkedRules.length === 1 && (
                    <BulkBarFlatButton
                        inProgress={state.isDeleteInProgress}
                        onClick={openEditOrderRuleDialog}
                        icon={<IscsiIcon />}
                    >
                        {t("firewall.rules.action.change_order")}
                    </BulkBarFlatButton>
                )}
                <BulkBarFlatButton
                    errorColor
                    inProgress={state.isDeleteInProgress}
                    onClick={deleteRulesModal.open}
                    icon={<TrashIcon />}
                >
                    {t("firewall.rules.action.delete")}
                </BulkBarFlatButton>
            </SideBulkBar>
            <EditRuleOrderDialog
                key="firewall-edit-order-rule"
                open={state.isEditRuleOrderDialogOpen}
                onClose={closeEditOrderRuleDialog}
                onCloseDrawer={onClose}
                changeProgressState={updateRuleOrderProgress}
            />
            <CreateEditRuleDialog
                key="firewall-edit-rule"
                open={state.isEditRuleDialogOpen}
                onClose={closeEditRuleDialog}
                onCloseDrawer={onClose}
                changeProgressState={updateRuleProgress}
            />
            <ConfirmationDialog
                title={t("common.warning_dialog.title")}
                open={deleteRulesModal.isOpen}
                onClose={deleteRulesModal.close}
                onConfirm={deleteRules}
                confirmLabel={t("common.button.proceed")}
                cancelLabel={t("common.button.cancel")}
                fullWidth={true}
            >
                {t("firewall.rules.confirm_delete", { count: firewallStore.checkedRules.length })}
            </ConfirmationDialog>
        </>
    ));
};

export default RulesBulkBar;
