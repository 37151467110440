import React from "react";
import { useObserver } from "mobx-react";
import { TableRow as MuiRow, TableCell } from "@material-ui/core";
import StatusLabel from "../StatusLabel";
import { useStyles } from "./TableRow.styles";

const TableRow = ({ task, onClick }) => {
    const classes = useStyles();

    return useObserver(() => (
        <MuiRow onClick={onClick}>
            <TableCell>{task.id}</TableCell>
            <TableCell>
                <StatusLabel status={task.result} />
            </TableCell>
            <TableCell>{task.progress}</TableCell>
            <TableCell>{task.action}</TableCell>
            <TableCell className={classes.fileName}>{task.location || ""}</TableCell>
            <TableCell>—</TableCell>
            <TableCell>{task.message || "—"}</TableCell>
        </MuiRow>
    ));
};

export default TableRow;
