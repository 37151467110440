import Request from "api/Request";

const ListPlugins = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Plugins",
    method: "list_plugins"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ListPlugins;
