import React from "react";
import { useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import Dialog from "components/Dialog";
import { Button, Typography } from "@material-ui/core";
import AddCategory from "assets/AddCategory";
import { useStyles } from "./WhatsThisModal.styles";

const WhatsThisModal = ({ open, onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={t("side_menu.menu_tabs.whats_this")}
            submitBtn={
                <Button variant={"contained"} color={"primary"} onClick={onClose}>
                    {t("common.button.close")}
                </Button>
            }
            withoutIconClose
            withoutDividers
        >
            <Typography>
                {t("whats_this.text.part_one")}
                <AddCategory className={classes.icon} />
                {t("whats_this.text.part_two")}
            </Typography>
        </Dialog>
    ));
};

export default WhatsThisModal;
