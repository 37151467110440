import { flow, getParent, types } from "mobx-state-tree";
import config from "config/config";
import MultipleEvosRootStore from "store/MultipleEvosRootStore";
import MainStore from "store";
import { CommonStoreModel } from "utils/multipleHandler";

export const FileNavigatorMultipleEvo = types
    .model("FileNavigatorMultipleEvo", {
        ip: types.string,
        selectedFiles: types.array(types.maybe(types.string)),
        dirs: types.array(types.maybe(types.string)),
        shareRoot: types.string,
    })
    .views((self) => ({
        //** Selected Files Views */
        get selectedFilesLength() {
            return self.selectedFiles.length;
        },
        includesSelectedFiles(name) {
            return self.selectedFiles.includes(name);
        },

        //** Dirs Views */
        get getDirOnShare() {
            return self.dirs.join("/");
        },
        get lengthDirs() {
            return self.dirs.length;
        },

        /** Common Views */
        get files() {
            const pathDir = self.dirs.length ? self.dirs.map((dir) => dir).join("/") + "/" : "";
            return self.selectedFiles.map((file) => pathDir + file);
        },
    }))
    .actions((self) => ({
        //** Selected Files Actions */
        selectFile(fileName) {
            const highParent = getParent(getParent(self));
            const index = self.selectedFiles.indexOf(fileName);

            highParent.setCurrentEvoIp(self.ip);

            if (index === -1) {
                self.selectedFiles.push(fileName);
            } else {
                self.selectedFiles.splice(index, 1);
            }
        },
        chooseFile(name) {
            const highParent = getParent(getParent(self));

            highParent.setCurrentEvoIp(self.ip);

            [...MultipleEvosRootStore.stores, [config.path, MainStore]].forEach(([ip, store]) => {
                if (ip !== self.ip) {
                    store.fileStore.setCurrentFileName(null);
                }
            });

            if (highParent.selectedFilesLength === 0) {
                self.setCurrentFile(name);
            }
        },
        setCurrentFile(name) {
            const { fileStore } = self.ip === config.path ? MainStore : MultipleEvosRootStore.getStore(self.ip);
            const dirs = [...self.dirs, name];

            if (fileStore.currentFileName === name) {
                fileStore.removeCurrentFile();
                return;
            }
            fileStore.setCurrentFileName(name);
            fileStore.setCurrentFilePath(dirs.join("/"));
            fileStore.setCurrentShareName(self.shareRoot);
        },
        setCurrentFileByIndex(index) {
            self.setCurrentFile(self.selectedFiles[index]);
        },
        setSelectedFiles(array) {
            self.selectedFiles = array;
        },
        clearSelectedFiles() {
            self.selectedFiles.clear();
        },

        //** Dirs Actions */
        addDir(dir) {
            self.dirs.push(dir);
        },
        deleteLastDir() {
            self.dirs.pop();
        },
        backStepDirs(index) {
            self.dirs = self.dirs.slice(0, index);
        },
        clearDirs() {
            self.dirs.clear();
        },
        beforeDestroy() {
            const highParent = getParent(getParent(self));
            highParent.setCurrentEvoIp(undefined);
            MainStore.uiStore.closeDrawer();
        },
        /** Share Root Actions */
        setShareRoot(string) {
            self.shareRoot = string;
        },
    }));

const RootFileNavigatorStore = types.compose(
    "RootFileNavigatorStore",
    CommonStoreModel,
    types
        .model({ evos: types.map(FileNavigatorMultipleEvo), currentEvoIp: types.maybe(types.string) })
        .views((self) => ({
            get selectedFilesLength() {
                const length = [...self.evos].reduce((sum, [_, evo]) => {
                    return sum + evo.selectedFilesLength;
                }, 0);

                return length;
            },
        }))
        .actions((self) => ({
            createStore: (ip) => FileNavigatorMultipleEvo.create({ ip, selectedFiles: [], dirs: [], shareRoot: "" }),
            deleteFiles: flow(function* () {
                const { fileStore } = getParent(self);
                try {
                    for (const value of self.evos.values()) {
                        const multipleStore = MultipleEvosRootStore.getStore(value.ip);

                        const multipleFileStore = multipleStore ? multipleStore.fileStore : fileStore;

                        const response = yield multipleFileStore.deleteFiles({
                            shareName: value.shareRoot,
                            filesOnShare: value.files,
                        });

                        if (response !== null) {
                            multipleFileStore.listFiles({
                                shareName: value.shareRoot,
                                dirOnShare: value.getDirOnShare,
                            });
                            value.clearSelectedFiles();
                        }
                    }
                    return true;
                } catch (e) {
                    return false;
                }
            }),
            clearSelected() {
                for (const value of self.evos.values()) {
                    value.clearSelectedFiles();
                }
            },
            setCurrentEvoIp(ip) {
                self.currentEvoIp = ip;
            },
        }))
);

export default RootFileNavigatorStore;
