import React, { useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Button, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import Checkbox from "components/Checkbox";
import SsdIcon from "assets/SsdIcon";
import HddIcon from "assets/HddIcon";
import { useStyles } from "./CreatePoolDialog.style";
import createFields from "./createFields";
import createForm from "utils/createForm";
import { useTranslation } from "react-i18next";
import { FREE_DRIVES_MODEL, FREE_DRIVES_NAME, FREE_DRIVES_SIZE } from "const/sortColumnConst";
import TableSortLabel from "components/TableSortLabel";
import poolNameRule from "utils/poolNameRule";
import TableCellWithIcon from "components/TableCellWithIcon";
import { convertBytesToSizeUnit } from "utils/convertBytesToSizeUnit";
import Dialog from "components/Dialog";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import useStoreByIp from "hooks/useStoreByIp";
import { useModalCombine } from "hooks/useModalCombine";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { MAXIMUM_POOL_SIZE } from "const";
import OverlimitPoolWarningDialog from "../OverlimitPoolWarningDialog";

const CREATE_POOL_DIALOG_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_create_pool_dialog`;

const CreatePoolDialog = ({ closeModal, open, ip }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { poolsStore } = useStoreByIp({ ip });

    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields(), rules: poolNameRule }),
        drives: [],
        get mainCheckboxStatus() {
            if (this.drives.length && this.drives.every((drive) => drive.checked)) {
                return "checked";
            }
            if (this.drives.length
                && this.drives.some((drive) => drive.checked)
                && this.drives.some((drive) => drive.checked).length !== this.drives.length
            ) {
                return "indeterminated";
            }
            return null;
        },
        isSubmitted: false,
        createPoolInProgress: false,
    }));
    const { unsavedChangesModal, overlimitPoolWarningModal } = useModalCombine([
        "unsavedChangesModal",
        "overlimitPoolWarningModal",
    ]);

    useEffect(() => {
        if (!open && state.drives.length) {
            return;
        }
        state.drives = poolsStore.sortedDrives.map((drive) => {
            return {
                id: `${drive.quad}-${drive.drive}`,
                checked: false,
                quad: drive.quad,
                drive: drive.drive,
                size: drive.size,
                driveModel: drive.driveModel,
            };
        });
        const countOfInitialChecked = 8;
        for (let i = 0; i < Math.min(state.drives.length, countOfInitialChecked); i++) {
            state.drives[i].checked = true;
        }
    }, [open, poolsStore.sortedDrives.length]);

    const checkOverlimitPool = (e) => {
        const checkedDrives = state.drives.filter((drive) => drive.checked);
        if (checkedDrives.length > MAXIMUM_POOL_SIZE) {
            overlimitPoolWarningModal.open();
            return;
        }
        const isSubmitted = submit(e);
        return isSubmitted;
    };

    const submit = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        const checkedDrives = state.drives.filter((drive) => drive.checked);
        if (!state.form.isValid && checkedDrives.length === 0) return;

        state.createPoolInProgress = true;
        const isSubmitted = !!(await poolsStore.addPool({
            pool: state.form.$("poolName").value,
            drives: checkedDrives.map((drive) => {
                return { quad: drive.quad, drive: drive.drive };
            }),
        }));
        state.createPoolInProgress = false;

        if (!isSubmitted) {
            return;
        }

        state.isSubmitted = true;
        if (overlimitPoolWarningModal.isOpen) {
            setTimeout(() => {
                closeModal();
                overlimitPoolWarningModal.close();
            }, 1000);
        }

        return isSubmitted;
    };
    const changeSorting = (column) => () => poolsStore.changeSortingFreeDrives(column);
    const handleMainCheckbox = () => {
        switch (state.mainCheckboxStatus) {
            case "checked":
            case "indeterminated":
                state.drives.forEach((drive) => (drive.checked = false));
                break;
            default:
                state.drives.forEach((drive) => (drive.checked = true));
                break;
        }
    };
    const getDisk = (name, type) => {
        const renderIcon = (type) => {
            switch (type) {
                case "ssd":
                    return <SsdIcon />;
                case "hdd":
                    return <HddIcon />;
                default:
                    return <SsdIcon />;
            }
        };
        return { diskIcon: renderIcon(type), diskTitle: t("disks_and_pools.pools_card.pool_item.drive_title", { name }) };
    };
    const onEnter = async () => {
        await poolsStore.fetchFreeDrives();
        state.form.reset();
        state.isSubmitted = false;
        unsavedChangesModal.close();
    };

    const closeIfNeeded = () => {
        if ((state.form.isDefault && !state.drives.find((drive) => !drive.checked)) || state.isSubmitted) {
            closeModal();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            withoutPaddings
            open={open}
            onClose={closeIfNeeded}
            onEnter={onEnter}
            buttons={
                <Button
                    id={`${CREATE_POOL_DIALOG_ID_PREFIX}_cancel`}
                    key={"cancelBtn"}
                    onClick={closeModal}
                    variant={"contained"}
                    color={"secondary"}
                >
                    {t("common.button.cancel")}
                </Button>
            }
            onSubmit={checkOverlimitPool}
            disableSubmit={!state.mainCheckboxStatus}
            submitBtnLabel={t("disks_and_pools.add_pool.table_head.button.create_pool")}
            submitBtnId={`${CREATE_POOL_DIALOG_ID_PREFIX}_submit`}
            title={t("disks_and_pools.add_pool.title")}
            inProgress={state.createPoolInProgress}
            isSubmitted={state.isSubmitted}
        >
            <Grid container alignItems={"center"} wrap={"nowrap"} spacing={2} className={classes.inputRow}>
                <Grid item>
                    <Typography noWrap>{t("disks_and_pools.add_pool.input_label")}</Typography>
                </Grid>
                <Grid item xs>
                    <TextInput id="poolName_input" field={state.form.$("poolName")} />
                </Grid>
            </Grid>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell padding={"checkbox"}>
                            <Checkbox
                                indeterminate={state.mainCheckboxStatus === "indeterminated"}
                                checked={state.mainCheckboxStatus === "checked"}
                                onChange={handleMainCheckbox}
                                id={`${CREATE_POOL_DIALOG_ID_PREFIX}_all_check`}
                            />
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                direction={poolsStore.orderFreeDrives}
                                onClick={changeSorting(FREE_DRIVES_NAME)}
                                active={poolsStore.orderByFreeDrives === FREE_DRIVES_NAME}
                                id={`${CREATE_POOL_DIALOG_ID_PREFIX}_disk_sort`}
                            >
                                {t("disks_and_pools.add_pool.table_head.disk")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                direction={poolsStore.orderFreeDrives}
                                onClick={changeSorting(FREE_DRIVES_MODEL)}
                                active={poolsStore.orderByFreeDrives === FREE_DRIVES_MODEL}
                                id={`${CREATE_POOL_DIALOG_ID_PREFIX}_model_sort`}
                            >
                                {t("disks_and_pools.add_pool.table_head.model")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align={"right"}>
                            <TableSortLabel
                                direction={poolsStore.orderFreeDrives}
                                onClick={changeSorting(FREE_DRIVES_SIZE)}
                                active={poolsStore.orderByFreeDrives === FREE_DRIVES_SIZE}
                                id={`${CREATE_POOL_DIALOG_ID_PREFIX}_size_sort`}
                            >
                                {t("disks_and_pools.add_pool.table_head.size")}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.drives.map((drive) => {
                        const id = `${drive.quad}-${drive.drive}`;
                        const disk = state.drives.find((drive) => drive.id === id);
                        const onCheckboxToggle = () => {
                            disk.checked = !disk.checked;
                        };
                        const { diskIcon, diskTitle } = getDisk(id, drive.driveType);
                        return (
                            <TableRow key={id}>
                                <TableCell className={classes.checkboxCell}>
                                    <Checkbox
                                        name={id}
                                        onChange={onCheckboxToggle}
                                        checked={disk?.checked}
                                        id={`${CREATE_POOL_DIALOG_ID_PREFIX}_${id}_check`}
                                    />
                                </TableCell>
                                <TableCellWithIcon icon={diskIcon}>{diskTitle}</TableCellWithIcon>
                                <TableCell>{drive.driveModel}</TableCell>
                                <TableCell align={"right"}>
                                    {
                                        convertBytesToSizeUnit({
                                            bytes: drive.size,
                                            exponent: 3,
                                            base: 2,
                                            output: "object",
                                        }).value
                                    }
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <UnsavedChangesDialog onConfirm={closeModal} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            <OverlimitPoolWarningDialog
                onSubmit={submit}
                onClose={overlimitPoolWarningModal.close}
                open={overlimitPoolWarningModal.isOpen}
                isSubmitted={state.isSubmitted}
            />
        </Dialog>
    ));
};

export default CreatePoolDialog;
