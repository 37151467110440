import { types } from "mobx-state-tree";

import ActivationKeyData from "api/activation/keys/Types/ActivationKeyData";

const ActivationKeyDataResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(ActivationKeyData)
});

export default ActivationKeyDataResult;
