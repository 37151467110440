import React from "react";
import { Button, Grid, MobileStepper, useMediaQuery, useTheme } from "@material-ui/core";
import { useStyles } from "./StateBar.style";
import UptimeBar from "../UptimeBar";
import UpsBar from "../UpsBar";
import StorageStatus from "../StorageStatus";
import QuadsStatus from "../QuadsStatus";
import { useTranslation } from "react-i18next";
import { useStepper } from "hooks/useStepper";

import ChevronRightIcon from "assets/ChevronRightIcon";
import clsx from "clsx";

const StateBar = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const { goBack, goNext, activeStep, isLastStep, isFirstStep, steps } = useStepper({ endStep: 1 });

    if (isDesktop) {
        return (
            <Grid container className={classes.container} wrap={"nowrap"}>
                <Grid item className={classes.stateBarItem}>
                    <UptimeBar />
                </Grid>
                <Grid item className={classes.stateBarItem}>
                    <UpsBar />
                </Grid>
                <Grid item className={classes.stateBarItem}>
                    <StorageStatus />
                </Grid>
                <Grid item className={classes.stateBarItem}>
                    <Grid container wrap={"nowrap"} alignItems={"center"} justify={"center"} className={classes.stateBarItem}>
                        <QuadsStatus />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const sliderComponents = [
        <Grid container className={classes.container} wrap={"nowrap"} key={"step-1"}>
            <Grid item className={classes.stateBarItem}>
                <UptimeBar />
            </Grid>
            <Grid item className={classes.stateBarItem}>
                <UpsBar />
            </Grid>
        </Grid>,
        <Grid container className={classes.container} wrap={"nowrap"} key={"step-1"}>
            <Grid item className={classes.stateBarItem}>
                <StorageStatus />
            </Grid>
            <Grid item className={classes.stateBarItem}>
                <Grid container wrap={"nowrap"} alignItems={"center"} justify={"center"} className={classes.stateBarItem}>
                    <QuadsStatus />
                </Grid>
            </Grid>
        </Grid>
    ];

    return (
        <Grid item className={classes.container}>
            <Grid className={classes.graphicItem}>{sliderComponents[activeStep]}</Grid>
            <Grid>
                <MobileStepper
                    steps={steps}
                    className={classes.stepper}
                    variant="dots"
                    activeStep={activeStep}
                    position="static"
                    nextButton={
                        <Button
                            size="small"
                            onClick={goNext}
                            disabled={isLastStep}
                            variant={"text"}
                            color={"primary"}
                            className={classes.buttonCustomDisabled}
                        >
                            {t("common.button.next")}
                            {theme.direction === "rtl" ? (
                                <ChevronRightIcon className={clsx(classes.chevron, classes.chevronLeft)} />
                            ) : (
                                <ChevronRightIcon className={classes.chevron} />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={goBack}
                            disabled={isFirstStep}
                            variant={"text"}
                            color={"primary"}
                            className={classes.buttonCustomDisabled}
                        >
                            {theme.direction === "rtl" ? (
                                <ChevronRightIcon className={classes.chevron} />
                            ) : (
                                <ChevronRightIcon className={clsx(classes.chevron, classes.chevronLeft)} />
                            )}
                            {t("common.button.previous")}
                        </Button>
                    }
                />
            </Grid>
        </Grid>
    );
};

export default StateBar;
