import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./EditUsbPermissionModal.styles";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import TextInput from "components/TextInput";
import Select from "components/MobxForm/Select";
import { DialogTitle, ButtonBase, Button, Typography, Divider, Grid } from "@material-ui/core";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import CloseIcon from "assets/CloseIcon";
import ThinArrowRightIcon from "assets/ThinArrowRightIcon";
import clsx from "clsx";
import { getUserTypeTrId, ALLOW, DENY, LOCAL_GROUPS, EXTERNAL_GROUPS } from "const/userSearchVariants";
import createForm from "utils/createForm";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import SearchLine from "../EditPermissionModal/components/SearchLine/SearchLine";
import useStoreByIp from "hooks/useStoreByIp";
import { useModal } from "hooks/useModal";
import { useObserveChangeField } from "hooks/useObserveChangeField";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const EDIT_USB_PERMISSION_MODAL_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_edit_usb_permission_modal`;

const EditUsbPermissionModal = ({ open, onClose, isCreate, userType, ip }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { aclStore, userStore } = useStoreByIp({ ip });

    const state = useLocalStore(() => ({
        form: createForm({
            fields: [{ name: "name", rules: "required" }, { name: "allow" }, { name: "type" }, { name: "domain" }],
            options: {
                validateOnInit: false,
                validateOnBlur: false,
                showErrorsOnReset: false,
            },
        }),
    }));
    const observerFieldName = useObserveChangeField(state.form.$("name"));

    const unsavedChangesModal = useModal();

    const isGroup = () => {
        return userType === LOCAL_GROUPS || userType === EXTERNAL_GROUPS;
    };

    const onSave = (e) => {
        state.form.onSubmit(e);
        if (observerFieldName.hasChange && isCreate) {
            state.form.$("name").invalidate(t("common.edit_permission_modal.select.error"));
            return;
        }
        if (!state.form.isValid) return;
        if (isCreate) {
            aclStore.usbAcl &&
                aclStore.createUsbAce({
                    access: state.form.$("allow").value,
                    trustee: {
                        name: state.form.$("name").value,
                        domain: state.form.$("domain").value,
                    },
                });
        } else {
            if (aclStore.usbAcl) {
                state.form.isDefault && onClose();
                aclStore.updateCurrentUsbAce("access", state.form.$("allow").value);
            }
        }
        onClose();
    };

    const onEnter = () => {
        if (isCreate) {
            state.form.$("name").set("default", "");
            state.form.$("allow").set("default", ALLOW);
            userStore.resetSearchByTypeResult();
        } else {
            state.form.$("name").set("default", aclStore.currentUsbAce.trustee.name);
            state.form.$("allow").set("default", aclStore.currentUsbAce.access);
        }
        state.form.$("type").set("default", userType);
        state.form.reset();
        observerFieldName.reset();
        unsavedChangesModal.close();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const getHeader = () => {
        return (
            <>
                <DialogTitle className={classes.dialogTitle}>
                    <Grid container direction={"column"} spacing={4}>
                        <Grid
                            justify={"space-between"}
                            alignContent={"center"}
                            alignItems={"center"}
                            container
                            item
                            wrap={"nowrap"}
                        >
                            <Grid item>
                                {isCreate
                                    ? t("users.edit_create.usb_permission.modal.title.create", {
                                          shareName: aclStore.currentShareNameWithPrefix,
                                      })
                                    : t("users.edit_create.usb_permission.modal.title.edit", {
                                          shareName: aclStore.currentShareNameWithPrefix,
                                      })}
                            </Grid>
                            <Grid item>
                                <CardHeaderControlButton onClick={closeIfNeeded}>
                                    <ButtonBase id={`${EDIT_USB_PERMISSION_MODAL_ID_PREFIX}_close_if_needed`}>
                                        <CloseIcon />
                                    </ButtonBase>
                                </CardHeaderControlButton>
                            </Grid>
                        </Grid>
                        <Grid item container wrap={"nowrap"} spacing={2} alignItems={"center"}>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <Typography variant={"body1"} className={classes.step} color={"primary"}>
                                        {t("users.customize_settings_modal.steps.customize_settings")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <ThinArrowRightIcon />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <Typography
                                        variant={"body1"}
                                        className={clsx(classes.step, classes.activeStep)}
                                        color={"primary"}
                                    >
                                        {isCreate
                                            ? t("file_navigator.advanced_security_modal.step.create_usb_permissions")
                                            : t("file_navigator.advanced_security_modal.step.edit_usb_permissions")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogTitle className={classes.inputsContainer}>
                    {isCreate ? (
                        <Grid
                            className={classes.typeSelectRowContainer}
                            container
                            wrap={"nowrap"}
                            alignItems={"center"}
                            justify={"space-between"}
                        >
                            <Grid xs={6} item>
                                <Typography>{t(getUserTypeTrId(userType))}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <SearchLine
                                    ip={ip}
                                    form={state.form}
                                    onSelectUser={observerFieldName.reset}
                                    id={`${EDIT_USB_PERMISSION_MODAL_ID_PREFIX}_search_line`}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <RowTextInput
                            control={
                                <TextInput
                                    disabled
                                    value={aclStore.currentUsbAce !== null && aclStore.currentUsbAce.trustee.name}
                                    id={`${EDIT_USB_PERMISSION_MODAL_ID_PREFIX}_name`}
                                />
                            }
                            label={
                                isGroup()
                                    ? t("users.edit_create.permission.modal.input.label.group")
                                    : t("users.edit_create.permission.modal.input.label.user")
                            }
                        />
                    )}

                    <RowTextInput
                        control={
                            <Select
                                field={state.form.$("allow")}
                                options={[
                                    { value: ALLOW, label: t("users.edit_create.permission.modal.type_select.variant.allow") },
                                    { value: DENY, label: t("users.edit_create.permission.modal.type_select.variant.deny") },
                                ]}
                                id={`${EDIT_USB_PERMISSION_MODAL_ID_PREFIX}_type`}
                            />
                        }
                        label={t("users.edit_create.permission.modal.input.label.type")}
                    />
                </DialogTitle>
            </>
        );
    };

    return useObserver(() => (
        <Dialog
            PaperProps={{ className: classes.dialog }}
            open={open}
            onClose={closeIfNeeded}
            withoutPaddings
            onEnter={onEnter}
            header={getHeader()}
            submitBtn={
                <Button variant={"contained"} color={"primary"} onClick={onSave} id={`${EDIT_USB_PERMISSION_MODAL_ID_PREFIX}_ok`}>
                    {t("common.button.ok")}
                </Button>
            }
            buttons={
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={closeIfNeeded}
                    id={`${EDIT_USB_PERMISSION_MODAL_ID_PREFIX}_back`}
                >
                    {t("common.button.back")}
                </Button>
            }
        >
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default EditUsbPermissionModal;
