import { types } from "mobx-state-tree";

import LogicalDiskType from "./LogicalDiskType";

const GetLogicalDisksType = types.model({
    json: types.maybe(
        types.model({
            disks: types.maybe(types.array(LogicalDiskType)),
            total: types.number
        })
    )
});
export default GetLogicalDisksType;
