import { types } from "mobx-state-tree";

import Acl from "api/security/acl/Types/Acl";

const GetAclResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(Acl)
});

export default GetAclResult;
