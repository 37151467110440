import { validationRecipientList } from "utils/validators/validationRecipientList";

const createFields = emailSettings => {
    return [
        {
            name: "smtpUser",
            placeholder: "sender@example.com",
            default: emailSettings.smtpUser
        },
        {
            name: "smtpPassword",
            default: emailSettings.smtpPassword,
            type: "password"
        },
        {
            name: "smtpServer",
            placeholder: "smtp.gmail.com:465",
            default: emailSettings.smtpServer
        },
        {
            name: "isSslEnabled",
            default: emailSettings.isSslEnabled,
            type: "checkbox"
        },
        {
            name: "emailFrom",
            placeholder: "sender@example.com",
            default: emailSettings.emailFrom
        },
        {
            name: "emailsTo",
            placeholder: "recip1@example.com; recip2@example.com",
            default: emailSettings.emailsTo?.join("; "),
            validators: [validationRecipientList()]
        }
    ];
};

export default createFields;
