import React from "react";
import DataCard from "components/DataCard";
import CPUDetailedWidget from "components/graphs/CPUDetailedWidget";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { CPU_DETAILED_GRAPH_CARD } from "const/widgetNameConst";
import { useTranslation } from "react-i18next";

const CPUDetailedGraphCard = ({ parentId = "cpuDetailedDashboardCard" }) => {
    const { t } = useTranslation();

    return (
        <DataCard
            autoHeight
            headerDivider
            title={t("dashboard.cpu_detailed_widget.title")}
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={CPU_DETAILED_GRAPH_CARD} />
                </MoreMenu>
            }
        >
            <CPUDetailedWidget parentId={parentId} />
        </DataCard>
    );
};

export default CPUDetailedGraphCard;
