import React from "react";
import { observer } from "mobx-react";
import { usePluginList } from "./PluginList.hooks";
import { useMediaQuery } from "@material-ui/core";
import Card from "../Card/Card";
import { useStyles } from "./PluginList.style";
import { RoutingPlugin } from "./PluginList.hoc";

const PluginList = () => {
    const pluginsList = usePluginList();
    const isDesktop = useMediaQuery("(min-width:992px)");
    const classes = useStyles({ isDesktop });

    const isEnoughPlugins = pluginsList.length % 4 ? false : true;
    const countFakeCard = 4 - (pluginsList.length % 4);
    const fakeArrayCard = Array.from(new Array(countFakeCard), (_, idx) => ({ id: idx }));

    return (
        <div className={classes.container}>
            {pluginsList.map(plugin => (
                <div key={plugin.name + plugin.alias} className={classes.card}>
                    <RoutingPlugin plugin={plugin}>
                        <Card />
                    </RoutingPlugin>
                </div>
            ))}

            {isEnoughPlugins
                ? null
                : fakeArrayCard.map(el => (
                      <div key={el.id} className={classes.card}>
                          <Card fakeCard forPlugin />
                      </div>
                  ))}
        </div>
    );
};

export default observer(PluginList);
