import { types } from "mobx-state-tree";

const RemoveJobArguments = types.model({
    json: types.maybe(
        types.model({
            jobId: types.number
        })
    )
});

export default RemoveJobArguments;
