import React, { useEffect } from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./SecondaryAdminsCard.styles";
import { useTranslation } from "react-i18next";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import GridRowsExpand from "components/GridRowsExpand";
import { SECONDARY_ADMINS_CARD } from "const/widgetNameConst";
import { useLocalStore, observer } from "mobx-react";
import EditSecondaryAdminsModal from "./components/EditSecondaryAdminsModal";
import LocalUserIcon from "assets/LocalUserIcon";
import ExternalGroupIcon from "assets/ExternalGroupIcon";
import LocalGroupIcon from "assets/LocalGroupIcon";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { useToggle } from "hooks/useToggle";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const CREDENTIALS_CARD_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_credentials_card`;
const HIDDEN_ROWS_PER_CARD_INDEX = 6;

const CredentialsCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: { userStore },
    } = useStore();
    const state = useLocalStore(() => ({
        isExpanded: false,
        get slicedIndex() {
            if (this.isExpanded) {
                return userStore.secondaryAdmins.length;
            } else {
                return HIDDEN_ROWS_PER_CARD_INDEX;
            }
        },
    }));

    const [inProgress, toggleInProgress] = useToggle();

    const editSecondaryAdminsModal = useModal();

    useEffect(() => {
        (async () => {
            toggleInProgress(true);
            await userStore.fetchSecondaryAdmins();
            toggleInProgress(false);
        })();
    }, []);

    const getIcon = (domainObject) => {
        if (domainObject.isGroup) {
            return domainObject.isExternal ? <ExternalGroupIcon /> : <LocalGroupIcon />;
        } else {
            return <LocalUserIcon />;
        }
    };

    const getName = (domainObject) => {
        return domainObject.isExternal ? `${domainObject.id.domain}@${domainObject.id.name}` : domainObject.id.name;
    };

    const toggleExpandSecondaryAdmins = (e) => {
        e.stopPropagation();
        state.isExpanded ? (state.isExpanded = false) : (state.isExpanded = true);
    };

    return (
        <>
            <DataReadonlyCard
                title={t("admin_settings.secondary_admin.title")}
                headerDivider
                autoHeight
                emptyPadding
                onClick={editSecondaryAdminsModal.open}
                control={
                    <MoreMenu id={`${CREDENTIALS_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={SECONDARY_ADMINS_CARD} />
                    </MoreMenu>
                }
            >
                {userStore.secondaryAdmins?.length > 0 ? (
                    <>
                        <Grid container direction={"column"} spacing={8} className={classes.container}>
                            {userStore.secondaryAdmins.slice(0, state.slicedIndex).map((adminObj) => (
                                <Grid key={adminObj.domainName} item container spacing={2} wrap={"nowrap"} alignItems={"center"}>
                                    <Grid item className={classes.withIcon}>
                                        <Grid container alignItems={"center"}>
                                            {getIcon(adminObj)}
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Typography>{getName(adminObj)}</Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>

                        {userStore.secondaryAdmins.length > HIDDEN_ROWS_PER_CARD_INDEX && (
                            <GridRowsExpand onClick={toggleExpandSecondaryAdmins}>
                                {state.isExpanded
                                    ? t("admin_settings.secondary_admin.show_all_rows.hide")
                                    : t("admin_settings.secondary_admin.show_all_rows.show", {
                                          count: userStore.secondaryAdmins.length,
                                      })}
                            </GridRowsExpand>
                        )}
                    </>
                ) : (
                    <EmptyCardPlaceholder inProgress={inProgress} message={t("admin_settings.secondary_admin.placeholder")} />
                )}
            </DataReadonlyCard>
            <EditSecondaryAdminsModal open={editSecondaryAdminsModal.isOpen} onClose={editSecondaryAdminsModal.close} />
        </>
    );
};

export default observer(CredentialsCard);
