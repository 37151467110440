import React, { useEffect, useContext } from "react";
import { Context } from "store";
import { useObserver } from "mobx-react";
import { useStyles } from "./ExpansionQuadCard.styles";
import { Paper, Grid, Typography, Chip, Divider } from "@material-ui/core";
import CardHeader from "components/CardHeader";
import QuadItem from "./QuadItem";
import { useTranslation } from "react-i18next";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { EXPANSION_QUAD_CARD } from "const/widgetNameConst";
import { HARDWARE_ACTIVATION_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const EXPANSION_QUAD_CARD_ID_PREFIX = `${HARDWARE_ACTIVATION_PAGE_ID_PREFIX}_expansion_quad_card`;

const ExpansionQuadCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { quadActivationStore },
    } = useContext(Context);

    useEffect(() => {
        quadActivationStore.fetchQuadActivations();
        quadActivationStore.fetchQuadActivationsCount();
    }, []);

    const isQuadChecked = (quadName) => {
        return quadActivationStore.checkedQuads.includes(quadName);
    };
    const onCheck = (quadName) => () => {
        isQuadChecked(quadName) ? quadActivationStore.removeCheckedQuad(quadName) : quadActivationStore.addCheckedQuad(quadName);
    };

    const getCardHeader = () => {
        return (
            <CardHeader
                control={
                    <MoreMenu id={`${EXPANSION_QUAD_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={EXPANSION_QUAD_CARD} />
                    </MoreMenu>
                }
                content={
                    <Grid container alignItems={"baseline"} wrap={"nowrap"} spacing={6}>
                        <Grid item>
                            <Typography variant={"h2"} noWrap>
                                {t("hardware_activation.expansion_quad_licensing_card.title")}
                            </Typography>
                        </Grid>

                        {quadActivationStore.quadActivationsCount && (
                            <Grid item container alignItems={"center"} wrap={"nowrap"} spacing={2}>
                                <Grid item>
                                    <Typography>
                                        {t("hardware_activation.expansion_quad_licensing_card.title.activations_available")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Chip
                                        className={classes.chip}
                                        label={quadActivationStore.quadActivationsCount}
                                        color={"primary"}
                                        size={"small"}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                }
            />
        );
    };
    return useObserver(() => (
        <Paper className={classes.container}>
            {getCardHeader()}
            <Divider />
            <Grid container className={classes.contentContainer}>
                <Grid container direction={"column"} spacing={6}>
                    {quadActivationStore.quadActivationsArr &&
                        quadActivationStore.quadActivationsArr.map((enclosureActivation) => (
                            <Grid key={enclosureActivation.enclosure} item container direction={"column"} spacing={4}>
                                <Grid item>
                                    <Typography className={classes.subtitle} variant={"subtitle2"}>
                                        {enclosureActivation.enclosure}
                                    </Typography>
                                </Grid>
                                <Grid item container spacing={4}>
                                    {enclosureActivation.quadActivations.map((quadActivation) => (
                                        <Grid
                                            id="halfWidthExpandable"
                                            key={enclosureActivation.enclosure + quadActivation.quad}
                                            item
                                            xs={12}
                                            sm={6}
                                            lg={4}
                                            xl={3}
                                        >
                                            <QuadItem
                                                checked={isQuadChecked(quadActivation.quad)}
                                                onClick={onCheck(quadActivation.quad)}
                                                quad={quadActivation}
                                                id={`${EXPANSION_QUAD_CARD_ID_PREFIX}_quad_${quadActivation.quad}`}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        ))}
                </Grid>
            </Grid>
        </Paper>
    ));
};

export default ExpansionQuadCard;
