import React, { useEffect, useRef } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Grid, Typography, Box } from "@material-ui/core";
import * as d3 from "d3";
import { withFauxDOM } from "react-faux-dom";

import { timeRegExp, DEFAULT_START_PERIOD, TICK_X_COUNT, CPU_GRAPH_COLORS, TICK_Y_COUNT } from "const/chartConst";
import { useStyles } from "./CPUDetailedGraph.style";

const marginLeft = 30;
const marginRight = 40;
const marginTop = 10;
const marginBottom = 20;
const textMargin = 12;
const textHeight = 18;

const CPUDetailedGraph = ({
    parentId = "cpuDetailedWidget",
    height = 150,
    animDuration = 300,
    chartInfo,
    columns,
    startFetching,
    stopFetching,
    lastTime,
    lastValues,
    connectFauxDOM,
    animateFauxDOM,
    isDark,
    graphStartPeriod = DEFAULT_START_PERIOD,
    graphAutoscaling,
    ...props
}) => {
    const parenfRef = useRef();
    const classes = useStyles({ isDark });
    // This allows to find the closest X index of the mouse
    const bisect = d3.bisector(d => d.date);
    const maxY = d3.max(
        chartInfo,
        i =>
            Math.round(
                parseFloat(
                    Object.keys(i)
                        .map(key => i[key])
                        .slice(1)
                        .reduce((sum, val) => sum + val, 0)
                ) * 10
            ) / 10
    );
    const maxYbound = graphAutoscaling ? maxY + parseFloat((maxY * 0.2).toFixed(2)) : 100;
    const chartId = `chart-${parentId}`;
    const ticksX = d3.timeMinute.every(Math.round(graphStartPeriod / 60 / TICK_X_COUNT));
    const stackedData = d3.stack().keys(columns)(chartInfo);
    const state = useLocalStore(() => ({
        svg: null,
        svgCoorditates: { x: 0, y: 0 },
        xScale: null,
        yScale: null,
        xaxis: null,
        yaxis: null,
        points: [],
        area: null,
        selectedTime: "",
        selectedValues: null,
        isChartCreated: false,
        setSelectedTime(time) {
            this.selectedTime = time;
        },
        setSelectedValues(values) {
            this.selectedValues = values;
        }
    }));

    useEffect(() => {
        state.isChartCreated ? updateChart() : createChart();
    }, [chartInfo]);

    function mouseover() {
        state.points.forEach(point => point.style("display", null));
        state.svgCoorditates = parenfRef.current.getBoundingClientRect();
        stopFetching && stopFetching(true);
    }

    function mousemove(event) {
        if (chartInfo.length === 0) return;

        const xCoord = event.x - state.svgCoorditates.x;
        const x0 = state.xScale.invert(xCoord);
        const i = bisect.left(chartInfo, x0);
        const selectedData = chartInfo[i] ? chartInfo[i] : chartInfo[i - 1];

        state.points.forEach((point, i) => {
            point.attr(
                "transform",
                `translate(${state.xScale(selectedData.date)}, ${state.yScale(
                    selectedData[columns[i]] +
                        Math.round(
                            parseFloat(
                                columns
                                    .slice(0, i)
                                    .reduce((previousValue, currentValue) => previousValue + selectedData[currentValue], 0)
                            ) * 10
                        ) /
                            10
                )})`
            );
        });
        animateFauxDOM(animDuration);

        state.setSelectedTime(d3.timeFormat(timeRegExp)(selectedData.date));
        state.setSelectedValues(selectedData);
    }

    function mouseout() {
        state.points.forEach(point => point.style("display", "none"));
        state.setSelectedTime("");
        state.setSelectedValues(null);
        startFetching && startFetching(true);
    }

    function createChart() {
        state.isChartCreated = true;
        const faux = connectFauxDOM("g", chartId);
        const wrapper = d3.select(`#${parentId}`);
        const color = d3
            .scaleOrdinal()
            .domain(columns)
            .range(CPU_GRAPH_COLORS);

        wrapper.select("svg").attr("width", parenfRef.current.offsetWidth);
        state.svg = d3.select(faux);
        state.svg.attr("width", parenfRef.current.offsetWidth);
        state.xScale = d3
            .scaleTime()
            .domain(d3.extent(chartInfo, d => d.date))
            .range([marginLeft, parenfRef.current.offsetWidth - marginRight]);
        state.yScale = d3
            .scaleLinear()
            .domain([0, maxYbound])
            .range([height - marginBottom, marginTop]);
        state.xaxis = d3
            .axisTop()
            .scale(state.xScale)
            .tickFormat(d3.timeFormat(timeRegExp))
            .tickSize(height - marginTop - marginBottom)
            .ticks(ticksX);
        state.yaxis = d3
            .axisLeft()
            .scale(state.yScale)
            .tickSize(parenfRef.current.offsetWidth - marginLeft - marginRight);
        graphAutoscaling ? state.yaxis.ticks(TICK_Y_COUNT) : state.yaxis.tickValues([0, 25, 50, 75, 100]);
        state.svg
            .append("g")
            .attr("transform", `translate(0, ${height - marginBottom})`)
            .attr("class", "x axis")
            .call(state.xaxis)
            .call(g =>
                g.selectAll(".tick text").attr("transform", `translate(0, ${height - marginTop - marginBottom + textHeight})`)
            );
        state.svg
            .append("g")
            .attr("class", "y axis")
            .attr("transform", `translate(${parenfRef.current.offsetWidth - marginRight}, 0)`)
            .call(state.yaxis)
            .call(g =>
                g
                    .selectAll(".tick text")
                    .attr("transform", `translate(${parenfRef.current.offsetWidth - marginRight - marginLeft + textMargin}, 0)`)
            );
        state.svg
            .append("defs")
            .append("svg:clipPath")
            .attr("id", "clip")
            .append("svg:rect")
            .attr("width", parenfRef.current.offsetWidth - marginRight)
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);
        const areaChart = state.svg.append("g").attr("clip-path", "url(#clip)");
        state.area = d3
            .area()
            .x(function(d) {
                return state.xScale(d.data.date);
            })
            .y0(function(d) {
                return state.yScale(d[0]);
            })
            .y1(function(d) {
                return state.yScale(d[1]);
            });
        areaChart
            .selectAll("mylayers")
            .data(stackedData)
            .join("path")
            .attr("class", function(d) {
                return "myArea " + d.key;
            })
            .style("fill", function(d) {
                return color(d.key);
            })
            .attr("d", state.area);
        columns.forEach((_, i) => {
            state.points.push(
                state.svg
                    .append("g")
                    .append("circle")
                    .attr("r", 3)
                    .style("fill", CPU_GRAPH_COLORS[i])
                    .style("display", "none")
            );
        });
        state.svg
            .append("rect")
            .style("fill", "none")
            .style("pointer-events", "all")
            .attr("class", "rect")
            .attr("width", parenfRef.current.offsetWidth - marginRight - marginLeft)
            .attr("height", height - marginBottom - marginTop)
            .attr("transform", `translate(${marginRight}, ${marginTop})`)
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseout", mouseout);
    }

    function updateChart() {
        const wrapper = d3.select(`#${parentId}`);

        wrapper.select("svg").attr("width", parenfRef.current.offsetWidth);
        state.svg.attr("width", parenfRef.current.offsetWidth);
        state.xScale.domain(d3.extent(chartInfo, d => d.date)).range([marginLeft, parenfRef.current.offsetWidth - marginRight]);
        state.yScale.domain([0, maxYbound]);
        state.svg
            .select(".x.axis")
            .call(state.xaxis)
            .call(g =>
                g.selectAll(".tick text").attr("transform", `translate(0, ${height - marginTop - marginBottom + textHeight})`)
            );
        state.svg
            .select(".y.axis")
            .call(state.yaxis)
            .call(g =>
                g
                    .selectAll(".tick text")
                    .attr("transform", `translate(${parenfRef.current.offsetWidth - marginRight - marginLeft + textMargin}, 0)`)
            );
        state.svg
            .select(".rect")
            .attr("width", parenfRef.current.offsetWidth - marginRight - marginLeft)
            .on("mousemove", mousemove);
        state.svg
            .selectAll(".myArea")
            .data(stackedData)
            .transition()
            .duration(animDuration)
            .attr("d", state.area);

        animateFauxDOM(animDuration);
    }

    return useObserver(() => {
        const val = state.selectedValues !== null ? state.selectedValues : lastValues;

        return (
            <Grid container className={classes.container}>
                <Grid container item xs={2} direction="column" justify="space-between" className={classes.info}>
                    <Grid item className={classes.legend}>
                        {columns.map((column, i) => (
                            <Box key={column}>
                                <Typography className={classes.columnName} style={{ color: CPU_GRAPH_COLORS[i] }}>
                                    {column}
                                </Typography>
                                <Typography
                                    className={classes.columnValue}
                                    style={{ color: CPU_GRAPH_COLORS[i] }}
                                >{`${val[column]}%`}</Typography>
                            </Box>
                        ))}
                    </Grid>
                    <Grid item>
                        <Typography className={classes.label}>{state.selectedTime || lastTime}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={10} id={parentId} ref={parenfRef}>
                    <svg height={height} className={classes.svg}>
                        {props[chartId]}
                    </svg>
                </Grid>
            </Grid>
        );
    });
};

export default withFauxDOM(CPUDetailedGraph);
