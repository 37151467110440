import React from "react";
import { Table, TableCell, TableHead, TableRow, TableBody } from "@material-ui/core";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import TrashIcon from "assets/TrashIcon";
import { useTranslation } from "react-i18next";
import Switch from "components/Switch";
import { useStyles } from "./MuptipleEvoConfigurationTable.styles";
import { observer } from "mobx-react";

const MuptipleEvoConfigurationTable = ({ onRemove, onAutoconnect, list }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    if (list.length > 0)
        return (
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("multiple_evo.configure.table.autoconnect")}</TableCell>
                        <TableCell>{t("multiple_evo.configure.table.name")}</TableCell>
                        <TableCell>{t("multiple_evo.configure.table.host")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list?.map((entry) => {
                        return (
                            <TableRow key={entry.ip}>
                                <TableCell width={"20%"}>
                                    <Switch onChange={onAutoconnect(entry)} checked={entry.needToConnect} />
                                </TableCell>
                                <TableCell width={"35%"}>{entry.name}</TableCell>
                                <TableCell>{entry.ip}</TableCell>
                                <TableCell width={"10%"} onClick={onRemove(entry)} className={classes.tableDelete}>
                                    <TrashIcon />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );

    return <EmptyCardPlaceholder message={t("share.shared_pool_card.edit_nfc_acl.table.placeholder")} />;
};

export default observer(MuptipleEvoConfigurationTable);
