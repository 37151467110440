import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetAffectedPools from "api/drive_replace/Requests/GetAffectedPools";
import GetAffectedPoolsResult from "api/drive_replace/Responses/GetAffectedPoolsResult";
import RepairPool from "api/drive_replace/Requests/RepairPool";
import Rescan from "api/drive_replace/Requests/Rescan";
import GetReplaceState from "api/drive_replace/Requests/GetReplaceState";
import GetReplaceStateResult from "api/drive_replace/Responses/GetReplaceStateResult";
import HasAffectedPools from "api/drive_replace/Requests/HasAffectedPools";

const DriveReplaceStore = types
    .model({
        affectedPoolsResult: types.maybe(GetAffectedPoolsResult),
        replaceStateResult: types.maybe(GetReplaceStateResult),
        fetchingAffectedPools: false,
        responseHasAffectedPools: types.optional(types.boolean, false),
    })
    .views((self) => ({
        get hasAffectedPools() {
            return self.responseHasAffectedPools;
        },
        get replaceState() {
            return self.replaceStateResult?.data || {};
        },
        get affectedPools() {
            return self.affectedPoolsResult?.data || {};
        },
        get replaceableDrives() {
            let result = [];
            let processed = new Set();
            if (self.affectedPoolsResult) {
                self.affectedPools.pools.forEach((pool) => {
                    pool.drives.forEach((driveStatus) => {
                        let driveKey = driveStatus.drive.drive + "-" + driveStatus.drive.quad;
                        if (driveStatus.status !== "online" && !processed.has(driveKey)) {
                            result.push(driveStatus);
                            processed.add(driveKey);
                        }
                    });
                });
            }
            return result;
        },
    }))
    .actions((self) => ({
        fetchHasAffectedPools: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const requestHasAffectedPools = HasAffectedPools.create().init();
                const res = yield Socket.send(requestHasAffectedPools);
                self.responseHasAffectedPools = res.data;
                return self.responseHasAffectedPools;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchAffectedPools: flow(function* () {
            const { processingStore, driveStore } = getParent(self);
            try {
                self.fetchingAffectedPools = true;
                processingStore.setLoading(true);
                const req = GetAffectedPools.create().init();
                const res = yield Socket.send(req);

                if (res.data.quadsStatus) {
                    driveStore.updateEnclosuresState(res.data.quadsStatus.enclosures);
                    delete res.data.quadsStatus;
                }

                self.affectedPoolsResult = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
                self.fetchingAffectedPools = false;
            }
            return null;
        }),
        repairPool: flow(function* (args) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RepairPool.create().init(args);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        rescan: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = Rescan.create().init();
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchReplaceState: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetReplaceState.create().init();
                const res = yield Socket.send(req);
                self.replaceStateResult = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateReplaceState: (state) => {
            if (self.replaceStateResult?.data) {
                if (self.replaceStateResult.data.status === "replace" && state.status === "idle") {
                    self.affectedPoolsResult = undefined;
                    self.onReplaceCompleteCallback && self.onReplaceCompleteCallback();
                }
                if (self.replaceStateResult.data.status !== "idle" && state.status === "idle") {
                    self.fetchAffectedPools();
                }
                self.replaceStateResult.data = state;
            }
        },
        onReplaceComplete: (callback) => {
            self.onReplaceCompleteCallback = callback;
        },
    }));

export default DriveReplaceStore;
