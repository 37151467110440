import React from "react";

const RunIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="nonzero"
                d="M4.738 8.878l-.116.057a1 1 0 00-.072 1.688l4.027 2.805-6.219 6.67c-.733.786.123 2.018 1.115 1.605l15.633-6.5.115-.055a1.002 1.002 0 00.072-1.689l-4.027-2.806 6.219-6.667c.734-.788-.122-2.02-1.115-1.606L4.738 8.877zM16.87 6l-3.853 4.133-.076.092a1 1 0 00.236 1.41l3.475 2.421-9.68 4.025 3.854-4.132.076-.091a1 1 0 00-.236-1.41L7.19 10.024 16.87 6z"
            ></path>
        </svg>
    );
};

export default RunIcon;
