const createFields = () => {
    return [
        {
            name: "smbMaxXmit",
            type: "number"
        },
        {
            name: "smbMaxCredits",
            type: "number"
        },
        {
            name: "smbWorkgroup"
        },
        {
            name: "smbProtocol"
        },
        {
            name: "smbMultichannelEnabled",
            type: "checkbox"
        },
        {
            name: "smbAsyncEnabled",
            type: "checkbox"
        },
        {
            name: "useSendfileEnabled",
            type: "checkbox"
        },
        {
            name: "ftpType"
        },
        {
            name: "smbFruit",
            type: "checkbox"
        }
    ];
};

export default createFields;
