import React, { useEffect, useContext, useRef } from "react";
import { Context } from "store";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "./HeaderTabs.styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const HeaderTabs = ({ tabs, classNameTabsContainer, classNameTab, pageId, ...restProps }) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const {
        store: { uiStore, authStore }
    } = useContext(Context);
    const classes = useStyles();

    const changeTab = (_, value) => {
        history.push(value);
        uiStore.closeDrawer();
    };
    const actionRef = useRef();

    useEffect(() => {
        const timeout = setTimeout(() => {
            actionRef.current.updateIndicator();
        }, 0);

        return () => {
            clearTimeout(timeout);
        };
    }, [uiStore.isSideMenuOpen]);

    return (
        <AppBar position="sticky" elevation={0} color={"inherit"} {...restProps}>
            <Tabs
                action={actionRef}
                value={location.pathname}
                centered
                onChange={changeTab}
                className={clsx(classNameTabsContainer)}
            >
                {tabs.map(tab => (
                    <Tab
                        key={tab.value}
                        className={clsx(
                            classes.tabWithDisable,
                            classNameTab,
                            tab.disableByRole?.(authStore.role) && classes.disabled
                        )}
                        disabled={tab.disableByRole?.(authStore.role)}
                        value={tab.value}
                        label={t(tab.label)}
                        id={`${pageId ? pageId + "_" : ""}header_tab_${t(tab.label)
                            .toLowerCase()
                            .replace(" ", "_")}`}
                    />
                ))}
            </Tabs>
        </AppBar>
    );
};

export default observer(HeaderTabs);
