import { usePinnableMenu } from "hooks/usePinnableMenu.js";
import { useStore } from "hooks/useStore.js";

export const useNotificationMenu = () => {
    const PIN_NOTIFICATION_MENU = "pinNotificationMenu";
    const {
        store: { uiStore }
    } = useStore();

    return usePinnableMenu({
        localStorageName: PIN_NOTIFICATION_MENU,
        open: uiStore.openNotificationMenu,
        close: uiStore.closeNotificationMenu,
        display: uiStore.displayNotificationMenu,
        pin: uiStore.pinNotificationMenu,
        unpin: uiStore.unpinNotificationMenu,
        isOpen: uiStore.isNotificationMenuOpen,
        isPinned: uiStore.isNotificationMenuPinned,
        isHide: uiStore.isNotificationMenuHide
    });
};
