import i18n from "i18n";

export const TASKS_OPTIONS = {
    copy: "Copy",
    transcode: "Transcode",
    delete: "Delete",
    tricaster: "Streaming",
    move: "Move",
    automation: "Rename",
    autotagging: "Autotagging",
};

export const TASKS_OPTIONS_SINGLE = [
    { value: TASKS_OPTIONS.copy, flag: "single", label: i18n.t("slingshot.automation.task.tasks_options.copy") },
    { value: TASKS_OPTIONS.transcode, flag: "single", label: i18n.t("slingshot.automation.task.tasks_options.transcode") },
    { value: TASKS_OPTIONS.delete, flag: "single", label: i18n.t("slingshot.automation.task.tasks_options.delete") },
    {
        value: TASKS_OPTIONS.tricaster,
        flag: "single",
        label: i18n.t("slingshot.automation.task.tasks_options.tricaster_streaming"),
    },
];

export const TASKS_OPTIONS_GROUP = [
    { value: TASKS_OPTIONS.move, flag: "group", label: i18n.t("slingshot.automation.task.tasks_options.move") },
    {
        value: TASKS_OPTIONS.automation,
        flag: "group",
        label: i18n.t("slingshot.automation.task.tasks_options.append_automation"),
    },
    {
        value: TASKS_OPTIONS.autotagging,
        flag: "group",
        label: i18n.t("slingshot.automation.task.tasks_options.aiml_autotagging"),
    },
];

export const TASKS_SOURCE = [
    { value: "EVENT", label: i18n.t("slingshot.automation.task.source_options.original") },
    { value: "PREV_TASK", label: i18n.t("slingshot.automation.task.source_options.previous_task") },
];

export const LOG_LEVEL = [
    { value: "NO_LOGS", label: i18n.t("slingshot.automation.task.log_level_options.no_logs") },
    { value: "STANDARD", label: i18n.t("slingshot.automation.task.log_level_options.standard") },
    { value: "FULL", label: i18n.t("slingshot.automation.task.log_level_options.full") },
];

export const PATTERN_PLACEHOLDER = "%f%-%n%";
export const PATTERN_VALUE = "%s% - %n%%e";

export const SCHEMA_OPTIONS = {
    afp: "afp",
    alias: "alias",
    s3: "s3",
    azure: "azure",
    dropbox: "dropbox",
    ftp: "ftp",
    gcs: "gcs",
    evo: "evo",
    smb: "smb",
};

export const SCHEMA_OPTIONS_ARRAY = [
    { value: SCHEMA_OPTIONS.alias, label: "Alias" },
    { value: SCHEMA_OPTIONS.evo, label: "Local" },
];

export const TRANSCODE_REACTION = [
    {
        value: "ERROR",
        label: i18n.t("slingshot.automation.task.transcode_reaction.fail"),
    },
    {
        value: "IGNORE",
        label: i18n.t("slingshot.automation.task.transcode_reaction.ignore"),
    },
    {
        value: "COPY",
        label: i18n.t("slingshot.automation.task.transcode_reaction.copy"),
    },
];

export const TASK_TYPE = "groupTaskView";

export const SELECT_PLACEHOLDER = i18n.t("slingshot.automation.task.alias.placeholder");

export const OPERATION_VALUE = { copy: "COPY", move: "MOVE" };

export const EMAIL_TASK = "Email";
