import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PlusIcon from "assets/PlusIcon";
import { useStyles } from "./NoContentContainer.styles";

const NoContentContainer = ({ icon, onClick, title, content, buttonText, hideButton, id }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.noDiskContainer}>
            <Grid item className={classes.iconContainer}>
                {icon}
            </Grid>
            <Grid item>
                <Typography className={classes.label} variant={"subtitle1"}>
                    {title}
                </Typography>
            </Grid>
            <Grid item className={classes.caption}>
                <Typography variant={"body2"}>{content}</Typography>
            </Grid>
            {!hideButton && (
                <Grid item>
                    <Button id={id} variant={"contained"} color={"secondary"} startIcon={<PlusIcon />} onClick={onClick}>
                        {buttonText}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default NoContentContainer;
