import { makeStyles } from "@material-ui/core";
import { HEADER_HEIGHT, OPENED_HEADER_HEIGHT, OPENED_HEADER_HEIGHT_MOBILE } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    conteiner: {
        [theme.breakpoints.up("md")]: {
            height: ({ isWidgetsBarOpen }) =>
                isWidgetsBarOpen ? `calc(100vh - ${OPENED_HEADER_HEIGHT}px)` : `calc(100vh - ${HEADER_HEIGHT}px)`
        },
        height: ({ isWidgetsBarOpen }) =>
            isWidgetsBarOpen ? `calc(100vh - ${OPENED_HEADER_HEIGHT_MOBILE}px)` : `calc(100vh - ${HEADER_HEIGHT}px)`
    }
}));
