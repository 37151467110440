import { SCHEDULE_PERIOD_OPTIONS } from "const/createJobConst";
import moment from "moment";

const getSyncJobPauseDuration = ({ jobScheduleDate, pauseScheduleDate, isForceEndChecked, schedulePeriod }) => {
    const isOnceChecked = schedulePeriod === SCHEDULE_PERIOD_OPTIONS.once;
    const isHourlyChecked = schedulePeriod === SCHEDULE_PERIOD_OPTIONS.hourly;
    const isDailyChecked = schedulePeriod === SCHEDULE_PERIOD_OPTIONS.daily;
    const isWeeklyChecked = schedulePeriod === SCHEDULE_PERIOD_OPTIONS.weekly;

    const startHour = isHourlyChecked && !isForceEndChecked ? 0 : moment(jobScheduleDate).hour();
    const startDate = moment(jobScheduleDate)
        .hour(startHour)
        .minute(0)
        .second(0);
    const pauseDate = moment(pauseScheduleDate)
        .minute(0)
        .second(0);
    let duration = 0;

    if (isOnceChecked) {
        duration = 60 * 24 * 365 * 1000; // forever
    } else if (isHourlyChecked || isDailyChecked) {
        const diff = pauseDate.diff(startDate, "minutes");

        if (diff >= 0) {
            duration = startDate.add(24, "hours").diff(pauseDate, "minutes");
        } else {
            duration = Math.abs(diff);
        }
    } else if (isWeeklyChecked) {
        const diff = pauseDate.diff(startDate, "minutes");

        if (diff >= 0) {
            duration = startDate.add(7, "days").diff(pauseDate, "minutes");
        } else {
            duration = Math.abs(diff);
        }
    }

    return duration;
};

export default getSyncJobPauseDuration;
