export default {
    needForceDialogConfirm: {
        paragraphs: [
            {
                textKey: "upgrades.upgrade_system.need_force_dialog.message",
                gutterBottom: true
            },
            {
                textKey: "common.text.continue"
            }
        ]
    }
};
