import { types } from "mobx-state-tree";

import RollbackTime from "api/firewall/Types/RollbackTime";

const RollbackTimeResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(RollbackTime)
});

export default RollbackTimeResult;
