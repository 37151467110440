import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./EditAclModal.styles";
import Dialog from "components/Dialog";
import MoreMenu from "components/MoreMenu";
import Checkbox from "components/Checkbox";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    ButtonBase,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    MenuItem,
    FormControlLabel,
    Grid,
} from "@material-ui/core";
import LocalUserIcon from "assets/LocalUserIcon";
import ExternalGroupIcon from "assets/ExternalGroupIcon";
import LocalGroupIcon from "assets/LocalGroupIcon";
import PlusIcon from "assets/PlusIcon";
import TableCellWithIcon from "components/TableCellWithIcon/TableCellWithIcon";
import EditPermissionModal from "../EditPermissionModal";
import getPermissionLabel from "utils/getPermissionLabel";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import InheritedPermissionsDialog from "./components/InheritedPermissionsDialog";
import clsx from "clsx";
import ChevronUpIcon from "assets/ChevronUpIcon";
import { useModalCombine } from "hooks/useModalCombine";
import useStoreByIp from "hooks/useStoreByIp";
import { useFileNavigatorStoreByIp, useFileNavigatorStoreCommon } from "pages/FileNavigator/FileNavigator.hooks";
import DestructiveMenuItem from "components/DestructiveMenuItem/DestructiveMenuItem";

const EditAclModal = ({ open, onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const fileNavigatorStoreCommon = useFileNavigatorStoreCommon();
    const { aclStore, fileStore } = useStoreByIp({ ip: fileNavigatorStoreCommon.currentEvoIp });
    const fileNavigator = useFileNavigatorStoreByIp(fileNavigatorStoreCommon.currentEvoIp);

    const state = useLocalStore(() => ({
        replaceChildrenAcl: false,
        useInheritedPermissions: false,
        saveInProgress: false,
        isSubmitted: false,
        needToCreateNewAce: false,
        needToViewAce: false,
        accordionExpanded: false,
        defaultAcl: [],
    }));

    const { inheritedPermissionsModal, unsavedChangesModal, editPermissionModal } = useModalCombine([
        "inheritedPermissionsModal",
        "unsavedChangesModal",
        "editPermissionModal",
    ]);

    const onDialogEnter = async () => {
        state.saveInProgress = false;
        state.isSubmitted = false;
        unsavedChangesModal.close();
        state.replaceChildrenAcl = false;
        state.accordionExpanded = false;
        aclStore.updateIsPermissionsDefault(true);
        await aclStore.getAcl({ shareName: fileStore.currentShareName, path: fileStore.currentFilePath });
        state.defaultAcl = aclStore.acl?.aces.map((acl) => acl);
    };

    const onSave = async () => {
        state.saveInProgress = true;

        const res = await aclStore.setAcl({
            path: fileStore.currentFilePath,
            replaceChildrenAcl: state.replaceChildrenAcl,
            shareName: fileStore.currentShareName,
        });

        if (res) {
            state.isSubmitted = true;

            setTimeout(() => {
                fileStore.removeCurrentFile();
                fileNavigator.clearSelectedFiles();
                onClose();
            }, 1000);
        }

        state.saveInProgress = false;
        aclStore.updateIsPermissionsDefault(true);

        return res;
    };

    const onReplaceChildrenAcl = () => {
        state.replaceChildrenAcl = !state.replaceChildrenAcl;
        aclStore.updateIsPermissionsDefault(false);
    };

    const removeAce = (ace) => () => {
        aclStore.removeAce(ace);
    };

    const closeIfNeeded = () => {
        if (
            (aclStore.acl?.aces.join() === state.defaultAcl.join() || aclStore.isPermissionsDefault || state.isSubmitted) &&
            !(state.replaceChildrenAcl && !state.isSubmitted)
        ) {
            onClose();
            return;
        }

        unsavedChangesModal.open();
    };

    const editAce = (ace) => () => {
        state.needToCreateNewAce = false;
        state.needToViewAce = false;
        aclStore.setCurrentAce(ace);
        editPermissionModal.open();
        aclStore.updateIsPermissionsDefault(false);
    };
    const createAce = () => {
        state.needToCreateNewAce = true;
        state.needToViewAce = false;
        aclStore.setCurrentAce(null);
        editPermissionModal.open();
        aclStore.updateIsPermissionsDefault(false);
    };

    const viewAce = (ace) => () => {
        state.needToCreateNewAce = false;
        state.needToViewAce = true;
        aclStore.setCurrentAce(ace);
        editPermissionModal.open();
    };

    const convertInheritedAcesIntoExplicit = () => {
        aclStore.convertInheritedAcesIntoExplicit();
        disableInheritance();
        inheritedPermissionsModal.close();
    };

    const removeInheritedAces = () => {
        aclStore.removeInheritedAces();
        disableInheritance();
        inheritedPermissionsModal.close();
    };

    const disableInheritance = () => {
        aclStore.updateAclUseInheritedPermission(false);
    };
    const enableInheritance = () => {
        aclStore.updateAclUseInheritedPermission(true);
    };

    const openInheritedPermissionsDialogIfNeeded = () => {
        if (aclStore.acl?.aces.some((ace) => ace.isInherited === true)) {
            inheritedPermissionsModal.open();
            return;
        }
        disableInheritance();
    };

    const getIcon = (domainObject) => {
        if (domainObject.isGroup) {
            return domainObject.isExternal ? <ExternalGroupIcon /> : <LocalGroupIcon />;
        } else {
            return <LocalUserIcon />;
        }
    };

    const getName = (domainObject) => {
        return domainObject.isExternal ? `${domainObject.id.name}@${domainObject.id.domain}` : domainObject.id.name;
    };

    const getType = (allow) => {
        return allow ? t("users.customize_settings_modal.table.type.allow") : t("users.customize_settings_modal.table.type.deny");
    };

    return useObserver(() => (
        <Dialog
            maxWidth={"md"}
            onEnter={onDialogEnter}
            open={open}
            onClose={closeIfNeeded}
            submitBtnLabel={t("common.button.save")}
            onSubmit={onSave}
            withoutPaddings
            title={t("file_navigator.advanced_security_modal.title", { name: fileStore.currentFileNameWithPrefix })}
            inProgress={state.saveInProgress}
            withoutClose
            additionalActions={
                <Grid xs={12} item className={classes.expansionPanelContainer}>
                    <Accordion
                        className={classes.expansionPanel}
                        square
                        expanded={state.accordionExpanded}
                        onChange={(event, newExpanded) => {
                            state.accordionExpanded = newExpanded;
                        }}
                    >
                        <AccordionSummary
                            className={classes.expansionPanelContent}
                            classes={{ content: classes.expansionPanelContent, expanded: classes.expansionPanelContent }}
                        >
                            <Typography variant={"h2"} className={classes.expansionLabel}>
                                {t("file_navigator.advanced_security_modal.button.inheritance")}
                            </Typography>
                            <ChevronUpIcon
                                className={clsx(!state.accordionExpanded && classes.expansionIconClose, classes.expansionIcon)}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction={"column"} spacing={2}>
                                <Grid item>
                                    <FormControlLabel
                                        className={classes.formControlLabel}
                                        control={<Checkbox onChange={onReplaceChildrenAcl} checked={state.replaceChildrenAcl} />}
                                        label={t("users.customize_settings_modal.checkbox_label.replace_all")}
                                    />
                                </Grid>
                                <Grid item>
                                    {aclStore.acl?.useInheritedPermissions ? (
                                        <Button
                                            onClick={openInheritedPermissionsDialogIfNeeded}
                                            color={"primary"}
                                            variant={"contained"}
                                        >
                                            {t("file_navigator.advanced_security_modal.button.disable_inheritance")}
                                        </Button>
                                    ) : (
                                        <Button onClick={enableInheritance} color={"primary"} variant={"contained"}>
                                            {t("file_navigator.advanced_security_modal.button.enable_inheritance")}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }
            buttons={
                <Button variant={"contained"} color={"secondary"} onClick={onClose}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("users.customize_settings_modal.table.header.user_or_group")}</TableCell>
                        <TableCell>{t("users.customize_settings_modal.table.header.type")}</TableCell>
                        <TableCell>{t("users.customize_settings_modal.table.header.permission")}</TableCell>
                        <TableCell width={"10%"} align="right">
                            <ButtonBase disabled={!aclStore.acl} onClick={createAce}>
                                <PlusIcon className={clsx(!aclStore.acl && classes.disabled)} />
                            </ButtonBase>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {aclStore.acl?.aces.map((ace, index) => (
                        // TODO  Do not use Array index in keys
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={index}>
                            <TableCellWithIcon
                                className={clsx(classes.cellWithIcon, ace.isInherited && classes.disabledColor)}
                                icon={getIcon(ace.domainObject)}
                            >
                                {getName(ace.domainObject)}
                            </TableCellWithIcon>
                            <TableCell className={clsx(ace.isInherited && classes.disabledColor)}>{getType(ace.allow)}</TableCell>
                            <TableCell className={clsx(ace.isInherited && classes.disabledColor)}>
                                {getPermissionLabel(ace, fileStore.currentFile?.type === "directory")}
                            </TableCell>
                            <TableCell>
                                {ace.isInherited ? (
                                    <MoreMenu>
                                        <MenuItem onClick={viewAce(ace)}>
                                            {t("file_navigator.advanced_security_modal.menu_item.view")}
                                        </MenuItem>
                                    </MoreMenu>
                                ) : (
                                    <MoreMenu>
                                        <MenuItem onClick={editAce(ace)}>{t("common.button.edit")}</MenuItem>
                                        <DestructiveMenuItem onClick={removeAce(ace)}>
                                            {t("common.button.delete")}
                                        </DestructiveMenuItem>
                                    </MoreMenu>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <InheritedPermissionsDialog
                open={inheritedPermissionsModal.isOpen}
                onClose={inheritedPermissionsModal.close}
                convert={convertInheritedAcesIntoExplicit}
                remove={removeInheritedAces}
            />
            <EditPermissionModal
                open={editPermissionModal.isOpen}
                onClose={editPermissionModal.close}
                isCreate={state.needToCreateNewAce}
                viewOnly={state.needToViewAce}
            />
            <UnsavedChangesDialog open={unsavedChangesModal.isOpen} onClose={unsavedChangesModal.close} onConfirm={onClose} />
        </Dialog>
    ));
};

export default EditAclModal;
