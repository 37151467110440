import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./RowSwitch.styles";

const RowSwitch = ({ label, control }) => {
    const classes = useStyles();
    return (
        <Grid item container alignContent={"center"} alignItems={"center"} className={classes.container} spacing={2}>
            <Grid xs={6} item>
                <Typography>{label}</Typography>
            </Grid>
            <Grid xs={6} item>
                {control}
            </Grid>
        </Grid>
    );
};

export default RowSwitch;
