import Request from "api/Request";

const GetConnections = Request.props({
    path: "/com/studionetworksolutions/evo/manage/VPN",
    method: "get_connections"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetConnections;
