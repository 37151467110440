import { types } from "mobx-state-tree";

import EmailSettings from "api/events/Types/EmailSettings";

const EmailSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(EmailSettings)
});

export default EmailSettingsResult;
