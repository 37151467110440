import React from "react";
import { types, addMiddleware } from "mobx-state-tree";
import SuccessMessageHandling from "middlewares/SuccessMessageHandling.middleware";

import EthernetPortsStore from "store/EthernetPortsStore";
import NetServiceAdvertisingStore from "store/NetServiceAdvertisingStore";
import NetworkingSettingsStore from "store/NetworkingSettingsStore";
import DnsStore from "store/DnsStore";
import UiStore from "store/UiStore";
import ProcessingStore from "store/ProcessingStore";
import EventStore from "store/EventStore";
import NotificationStore from "store/NotificationStore";
import PoolsStore from "store/PoolsStore";
import TaskStore from "store/TaskStore";
import VolumeStore from "store/VolumeStore";
import ShareStore from "store/ShareStore";
import TimeSettingsStore from "store/TimeSettingsStore";
import FileStore from "store/FileStore";
import ActivatedKeysStore from "store/ActivatedKeysStore";
import QuadActivationStore from "store/QuadActivationStore";
import InterfaceActivationStore from "store/InterfaceActivationStore";
import DriveStore from "store/DriveStore";
import SshAccessStore from "store/SshAccessStore";
import AuthStore from "store/AuthStore";
import SessionStore from "store/SessionStore";
import EvoSettingsStore from "store/EvoSettingsStore";
import CertificateStore from "store/CertificateStore";
import WebserverStore from "store/WebserverStore";
import BondsStore from "store/BondsStore";
import UserStore from "store/UserStore";
import BridgeStore from "store/BridgeStore";
import RecycleBinStore from "store/RecycleBinStore";
import AlertsStore from "store/AlertsStore";
import AclStore from "store/AclStore";
import CPUGraphStore from "store/graphs/CPUGraphStore";
import ThroughputGraphStore from "store/graphs/ThroughputGraphStore";
import StorageUsageGraphStore from "store/graphs/StorageUsageGraphStore";
import NetworkGraphStore from "store/graphs/NetworkGraphStore";
import PowerAndUpsStore from "store/PowerAndUpsStore";
import EmailConfigurationStore from "store/EmailConfigurationStore/EmailConfigurationStore";
import SnmpStore from "store/SnmpStore";
import SupportInfoStore from "store/SupportInfoStore";
import ExternalSharesStore from "store/ExternalSharesStore";
import PingStore from "store/PingStore";
import BootProgressStore from "store/BootProgressStore";
import PostgresStore from "store/PostgresStore";
import SyncJobsStore from "store/Slingshot/SyncJobsStore";
import SlingshotSettingsStore from "store/Slingshot/SlingshotSettingsStore";
import AdvancedStore from "store/AdvancedStore";
import HardwareProfileStore from "store/HardwareProfileStore";
import MasterResetStore from "store/MasterResetStore";
import AuditStore from "store/AuditStore";
import UploadStore from "store/UploadStore";
import DriveReplaceStore from "store/DriveReplaceStore";
import ArchivedInstancesStore from "store/Slingshot/ArchivedInstancesStore";
import SlingshotStorageStore from "store/Slingshot/SlingshotStorageStore";
import IndexingConfigStore from "store/Slingshot/IndexingConfigStore";
import AutomationsStore from "store/Slingshot/AutomationsStore";
import BackupSettingsStore from "store/BackupSettingsStore";
import BcacheStore from "./BcacheStore/BcacheStore";
import WireguardStore from "./WireguardStore/WireguardStore";
import SharebrowserStore from "store/SharebrowserStore";
import UpgradesStore from "store/UpgradesStore";
import SystemComponentsStore from "store/SystemComponentsStore";
import PluginsStore from "store/PluginsStore";
import FirewallStore from "store/FirewallStore";
import MultipleEvosStore from "store/MultipleEvosStore";
import AutomationTasksStore from "store/Slingshot/AutomationTasksStore";
import ExternalShareDrawerStore from "store/ExternalShareDrawerStore";
import VolumeDrawerStore from "store/VolumeDrawerStore";
import MultiLdapStore from "store/MultiLdapStore";
import ClusterStore from "store/ClusterStore";
import ModalStore from "./ModalStore/ModalStore";
import FileNavigatorStore from "store/FileNavigator";
import RootShareStore from "./RootShareStore/RootShareStore";
import FilesLogStore from "store/FilesLogStore";
import SystemDriveStore from "store/SystemDriveStore";
import MigrationStore from "./MigrationStore/MigrationStore";
import { autorun } from "mobx";
import LongLivedTokensStore from "store/LongLivedTokensStore";

const store = types
    .model({
        ethernetPortsStore: EthernetPortsStore,
        netServiceAdvertisingStore: NetServiceAdvertisingStore,
        networkingSettingsStore: NetworkingSettingsStore,
        dnsStore: DnsStore,
        bondsStore: BondsStore,
        uiStore: UiStore,
        evoSettingsStore: EvoSettingsStore,
        processingStore: ProcessingStore,
        eventStore: EventStore,
        notificationStore: NotificationStore,
        poolsStore: PoolsStore,
        taskStore: TaskStore,
        volumeStore: VolumeStore,
        shareStore: ShareStore,
        timeSettingsStore: TimeSettingsStore,
        fileStore: FileStore,
        activatedKeysStore: ActivatedKeysStore,
        quadActivationStore: QuadActivationStore,
        interfaceActivationStore: InterfaceActivationStore,
        driveStore: DriveStore,
        sshAccessStore: SshAccessStore,
        authStore: AuthStore,
        sessionStore: SessionStore,
        certificateStore: CertificateStore,
        webserverStore: WebserverStore,
        userStore: UserStore,
        bridgeStore: BridgeStore,
        recycleBinStore: RecycleBinStore,
        powerAndUpsStore: PowerAndUpsStore,
        alertsStore: AlertsStore,
        emailConfigurationStore: EmailConfigurationStore,
        aclStore: AclStore,
        cpuGraphStore: CPUGraphStore,
        snmpStore: SnmpStore,
        throughputGraphStore: ThroughputGraphStore,
        storageUsageGraphStore: StorageUsageGraphStore,
        networkGraphStore: NetworkGraphStore,
        supportInfoStore: SupportInfoStore,
        externalSharesStore: ExternalSharesStore,
        pingStore: PingStore,
        bootProgressStore: BootProgressStore,
        postgresStore: PostgresStore,
        syncJobsStore: SyncJobsStore,
        slingshotSettingsStore: SlingshotSettingsStore,
        advancedStore: AdvancedStore,
        hardwareProfileStore: HardwareProfileStore,
        masterResetStore: MasterResetStore,
        auditStore: AuditStore,
        uploadStore: UploadStore,
        driveReplaceStore: DriveReplaceStore,
        archivedInstancesStore: ArchivedInstancesStore,
        slingshotStorageStore: SlingshotStorageStore,
        indexingConfigStore: IndexingConfigStore,
        automationsStore: AutomationsStore,
        backupSettingsStore: BackupSettingsStore,
        bcacheStore: BcacheStore,
        wireguardStore: WireguardStore,
        sharebrowserStore: SharebrowserStore,
        upgradesStore: UpgradesStore,
        systemComponentsStore: SystemComponentsStore,
        pluginsStore: PluginsStore,
        firewallStore: FirewallStore,
        multipleEvosStore: MultipleEvosStore,
        automationTasksStore: AutomationTasksStore,
        externalShareDrawerStore: ExternalShareDrawerStore,
        volumeDrawerStore: VolumeDrawerStore,
        multiLdapStore: MultiLdapStore,
        clusterStore: ClusterStore,
        modalStore: ModalStore,
        fileNavigatorStore: FileNavigatorStore,
        rootShareStore: RootShareStore,
        filesLogStore: FilesLogStore,
        systemDriveStore: SystemDriveStore,
        migrationStore: MigrationStore,
        longLivedTokensStore: LongLivedTokensStore,
    })
    .volatile(() => ({
        history: null,
    }))
    .actions((self) => ({
        afterCreate() {
            self.authStore.listen();
            self.eventStore.listen();
            autorun(() => {
                if (self.authStore.isAuthorized) {
                    self.migrationStore.getMigrationState();
                }
            });
        },
        initHistory(history) {
            self.history = history;
        },
    }))
    .create({
        ethernetPortsStore: {},
        netServiceAdvertisingStore: {},
        networkingSettingsStore: {},
        dnsStore: {},
        uiStore: {},
        processingStore: {},
        eventStore: {},
        notificationStore: {},
        poolsStore: {},
        taskStore: {},
        volumeStore: {},
        shareStore: {},
        timeSettingsStore: {},
        fileStore: {},
        activatedKeysStore: {},
        quadActivationStore: {},
        interfaceActivationStore: {},
        driveStore: {},
        sshAccessStore: {},
        authStore: {},
        sessionStore: {},
        evoSettingsStore: {},
        certificateStore: {},
        webserverStore: {},
        bondsStore: {},
        userStore: {},
        bridgeStore: {},
        recycleBinStore: {},
        aclStore: {},
        powerAndUpsStore: {},
        emailConfigurationStore: {},
        cpuGraphStore: {},
        snmpStore: {},
        throughputGraphStore: {},
        networkGraphStore: {},
        storageUsageGraphStore: {},
        alertsStore: {},
        supportInfoStore: {},
        externalSharesStore: {},
        pingStore: {},
        bootProgressStore: {},
        postgresStore: {},
        syncJobsStore: {},
        slingshotSettingsStore: {},
        advancedStore: {},
        hardwareProfileStore: {},
        masterResetStore: {},
        auditStore: {},
        uploadStore: {},
        driveReplaceStore: {},
        archivedInstancesStore: {},
        slingshotStorageStore: {},
        indexingConfigStore: {},
        automationsStore: {},
        backupSettingsStore: {},
        bcacheStore: {},
        wireguardStore: {},
        sharebrowserStore: {},
        upgradesStore: {},
        systemComponentsStore: {},
        pluginsStore: {},
        firewallStore: {},
        multipleEvosStore: {},
        automationTasksStore: {},
        externalShareDrawerStore: {},
        volumeDrawerStore: {},
        multiLdapStore: {},
        clusterStore: {},
        modalStore: { history: {} },
        fileNavigatorStore: {},
        rootShareStore: {},
        filesLogStore: {},
        systemDriveStore: {},
        migrationStore: {},
        longLivedTokensStore: {},
    });

export default store;

export const SuccessMessageHandlingMiddleware = addMiddleware(store, SuccessMessageHandling);

export const Context = React.createContext();
