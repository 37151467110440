import { SHUTDOWN } from "const/rebootAfterResetConst";
import isFieldEmpty from "utils/isFieldEmptyRule";
import i18n from "i18n";

const createFields = () => {
    return [
        {
            name: "rebootAfterReset",
            type: "radio",
            default: SHUTDOWN
        },
        {
            name: "keepActivations",
            type: "checkbox",
            default: false
        },
        {
            name: "password",
            type: "password",
            validators: [isFieldEmpty(i18n.t("master_reset.master_reset_card.label.password"))]
        }
    ];
};

export default createFields;
