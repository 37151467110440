const createFields = (notifications, names) => {
    return names
        .map(name => [
            {
                name: `${name}Email`,
                default: notifications[name]?.emailEnabled,
                disabled: notifications[name]?.emailReadonly,
                type: "checkbox"
            },
            {
                name: `${name}Audio`,
                default: notifications[name]?.audioEnabled,
                disabled: notifications[name]?.audioReadonly,
                type: "checkbox"
            },
            {
                name: `${name}Snmp`,
                default: notifications[name]?.snmpEnabled,
                disabled: notifications[name]?.snmpReadonly,
                type: "checkbox"
            }
        ])
        .flat(1);
};

export default createFields;
