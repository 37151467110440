import { types } from "mobx-state-tree";

import Request from "api/Request";
import DeactivateMissingQuadArguments from "api/activation/quad/Types/DeactivateMissingQuadArguments";

const DeactivateMissingQuad = Request.props({
    path: "/com/studionetworksolutions/evo/manage/activation/Quad",
    method: "deactivate_missing_quad",
    arguments: types.maybe(DeactivateMissingQuadArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default DeactivateMissingQuad
