import { useEventListener } from "hooks/useEventListener";
import { useRef } from "react";

/**
 * Hook that changes the height of an element by reference to it
 * @description The hook returns a link that should be hung on the item. By this reference the hook changes the height of the element depending on the change of the screen, minus the extra height passed to the hook as a parameter.
 * @param {number} extraHeight - extra height that will be subtracted from the screen height
 * @returns {MutableRefObject<any>} - reference for element
 * @example
 * const AnotherComponent = ({ children }) => {
 *   const refBox = useResizeHeight(351);
 *
 *   return (
 *     <div ref={refBox}>
 *       {children}
 *     </div>
 *   );
 * }
 */
export const useResizeHeight = (extraHeight = 0) => {
    const ref = useRef(null);

    const changeHeightElement = () => {
        if (ref.current) {
            ref.current.style.height = window.innerHeight - extraHeight;
        }
    };
    const eventListener = () => {
        requestAnimationFrame(changeHeightElement);
    };

    useEventListener("resize", eventListener);

    return ref;
};
