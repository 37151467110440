import { types } from "mobx-state-tree";

import Request from "api/Request";
import QuadActivationArguments from "api/activation/quad/Types/QuadActivationArguments";

const ActivateQuads = Request.props({
    path: "/com/studionetworksolutions/evo/manage/activation/Quad",
    method: "activate_quads",
    arguments: types.maybe(QuadActivationArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ActivateQuads;
