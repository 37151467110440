import React from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Grid, Button } from "@material-ui/core";
import createForm from "utils/createForm";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowTextInput from "components/RowTextInput";
import Select from "components/MobxForm/Select";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";

const SETUP_PREVIEW_PROXY_SETTINGS_DIALOG_ID_PREFIX = `setup_preview_proxy_settings_dialog`;

const SetupPreviewProxySettingsDialog = ({ open, onClose, parentId }) => {
    const idPrefix = parentId
        ? `${parentId}_${SETUP_PREVIEW_PROXY_SETTINGS_DIALOG_ID_PREFIX}`
        : SETUP_PREVIEW_PROXY_SETTINGS_DIALOG_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { slingshotSettingsStore, indexingConfigStore },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        isSubmitted: false,
        inProgress: false,
        get previewPreset() {
            return slingshotSettingsStore.presets?.find(
                (preset) => preset.id === indexingConfigStore.proxySettingsStore?.data?.previewProxySettings.presetId
            );
        },
        get editProxyPreset() {
            return slingshotSettingsStore.presets?.find(
                (preset) => preset.id === indexingConfigStore.proxySettingsStore?.data?.editProxySettings.presetId
            );
        },
    }));

    const unsavedChangesModal = useModal();

    const getPresetId = (presetName) => {
        return slingshotSettingsStore.presets?.find((preset) => preset.name === presetName)?.id;
    };

    const updateForm = () => {
        state.form.$("previewPreset").set("default", state.previewPreset?.name);
        state.form.$("editProxyPreset").set("default", state.editProxyPreset?.name);
        state.form.reset();
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };

    const onSubmit = async () => {
        state.inProgress = true;
        const res = await indexingConfigStore.setProxySettings({
            editProxySettings: {
                presetId: getPresetId(state.form.$("editProxyPreset").value),
                cpuLimitPercent: indexingConfigStore.proxySettingsStore?.data?.editProxySettings.cpuLimitPercent,
            },
            previewProxySettings: {
                presetId: getPresetId(state.form.$("previewPreset").value),
                cpuLimitPercent: indexingConfigStore.proxySettingsStore?.data?.previewProxySettings.cpuLimitPercent,
            },
        });
        state.inProgress = false;
        if (res) {
            state.isSubmitted = true;
            indexingConfigStore.fetchProxySettings();
        }
        return res;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            onClose={closeIfNeeded}
            open={open}
            title={t("slingshot.index_proxy.preview_settings.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            inProgress={state.inProgress}
            onSubmit={onSubmit}
            onEnter={updateForm}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"}>
                <RowTextInput
                    label={t("slingshot.index_proxy.preview_settings.label.sharebrowser_preview")}
                    control={
                        <Select
                            field={state.form.$("previewPreset")}
                            options={slingshotSettingsStore.presets.map((preset) => preset.name)}
                            id={`${idPrefix}_preview_preset`}
                        />
                    }
                />
                <RowTextInput
                    label={t("slingshot.index_proxy.preview_settings.label.edit_ready_format")}
                    control={
                        <Select
                            field={state.form.$("editProxyPreset")}
                            options={slingshotSettingsStore.presets.map((preset) => preset.name)}
                            id={`${idPrefix}_edit_proxy_preset`}
                        />
                    }
                />
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default SetupPreviewProxySettingsDialog;
