import React from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Link, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./WireguardInfoCard.styles";

const WireguardInfoCard = () => {
    const CLOUD_VPN_INFO_LINK = "https://www.studionetworksolutions.com/cloud-vpn/?utm=EVO";
    const styles = useStyles();

    const { t } = useTranslation();

    return (
        <DataReadonlyCard headerDivider title={t("wireguard.info_card.title")} onClick={() => {}} disabled>
            <Grid container>
                <Grid item container>
                    <Grid item>
                        <Typography className={styles.paragraphWithMargin}>{t("wireguard.info_card.text_first_part")}</Typography>
                        <Typography>
                            {t("wireguard.info_card.text_second_part")}{" "}
                            <Link target="_blank" href={CLOUD_VPN_INFO_LINK} rel="noreferrer">
                                this page
                            </Link>{" "}
                            {t("wireguard.info_card.text_third_part")}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </DataReadonlyCard>
    );
};

export default WireguardInfoCard;
