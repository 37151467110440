import { makeStyles } from "@material-ui/core/styles";
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE, NOTIFICATION_MENU_BUTTON_WIDTH } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    container: {
        [theme.breakpoints.up("md")]: {
            height: HEADER_HEIGHT
        },
        height: HEADER_HEIGHT_MOBILE,
        boxSizing: "border-box",
        "& .MuiTypography-body1": {
            opacity: 0.6,
            fontSize: 10,
            lineHeight: 0.8,
            whiteSpace: "nowrap"
        }
    },
    logoSection: {
        height: "100%",
        width: "100%",
        margin: `0 !important`
    },
    controlsContainer: {
        height: "100%"
    },
    rightControl: {
        height: "100%"
    },
    rightControlButton: {
        width: NOTIFICATION_MENU_BUTTON_WIDTH,
        height: "100%",
        "&:hover": {
            boxShadow: `0px -${theme.spacing(1)}px 0px 0px ${theme.palette.primary.main} inset`
        }
    },
    activeRightControl: {
        "& button": {
            backgroundColor: theme.palette.primary.main
        }
    },
    titleContainer: {
        height: "100%"
    },
    titleItem: {
        flex: 1,
        justifyContent: "center"
    },
    headerInfo: {
        "&&": {
            opacity: 0.5,
            fontWeight: 500,
            letterSpacing: "normal"
        }
    },
    evoMenu: {
        width: theme.spacing(16),
        height: "100%"
    },
    listMenu: {
        padding: 0
    },
    switchLabel: {
        padding: theme.spacing(3),
        paddingTop: "1px",
        paddingBottom: "1px",
        "& > span:last-child": {
            marginLeft: theme.spacing(2),
            opacity: 0.9
        }
    },
    containerLogoSection: {
        flex: 1
    }
}));
