import i18n from "i18n";
import { REGEX_IP_SUBNET } from "const";

const isIpWithCidrRule = {
    isIpWithCidr: {
        function: value => {
            return value.match(REGEX_IP_SUBNET);
        },
        message: i18n.t("rule.subnet.format")
    }
};

export default isIpWithCidrRule;
