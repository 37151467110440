const createFields = () => {
    return [
        {
            name: "enabled",
            type: "checkbox"
        },
        {
            name: "startHour"
        },
        {
            name: "startMinute"
        },
        {
            name: "endHour"
        },
        {
            name: "endMinute"
        },
        {
            name: "adHocRequestWithoutPause",
            type: "checkbox"
        }
    ];
};

export default createFields;
