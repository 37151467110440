import { types, flow, getParent } from "mobx-state-tree";
import MainSocket from "websocket";
import VolumesType from "api/volume/Responses/VolumesResult";
import QueueType from "api/volume/Responses/GetQueueResult";
import GetVolumes from "api/volume/Requests/GetVolumes";
import GetQueue from "api/volume/Requests/GetQueue";
import EnqueueVolumes from "api/volume/Requests/EnqueueVolumes";
import StartQueue from "api/volume/Requests/StartQueue";
import PauseQueue from "api/volume/Requests/PauseQueue";
import ClearQueue from "api/volume/Requests/ClearQueue";
import RemoveVolumes from "api/volume/Requests/RemoveVolumes";
import DequeueVolumes from "api/volume/Requests/DequeueVolumes";
import SetVolumesOnline from "api/volume/Requests/SetVolumesOnline";
import VolumeId from "api/volume/Types/VolumeId";
import { ASC, DESC, DISKS_VOLUME_NAME } from "const/sortColumnConst";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import DefragState from "api/volume/Types/DefragState";
import GetDefragEnabled from "api/volume/Requests/GetDefragEnabled";
import GetDefragmentationState from "api/volume/Requests/GetDefragmentationState";
import StopDefragmentation from "api/volume/Requests/StopDefragmentation";
import StartDefragmentation from "api/volume/Requests/StartDefragmentation";
import { HEALTH_CORRUPT, HEALTH_DEGRADED, HEALTH_UNKNOWN } from "const/logicalDisksConst";
import DeferredParityCalculationResult from "api/volume/Responses/DeferredParityCalculationResult";
import GetDeferredParityCalculation from "api/volume/Requests/GetDeferredParityCalculation";
import SetDeferredParityCalculation from "api/volume/Requests/SetDeferredParityCalculation";
import { actionGuardByRole } from "utils/actionGuardByRole";
import { getEvoPrefix } from "utils/helpers/getEvoPrefix";

const VolumeStore = types
    .model("VolumeStore", {
        volumesStore: types.maybe(VolumesType),
        queueStore: types.maybe(QueueType),
        checkedVolumes: types.optional(types.array(VolumeId), []),
        checkedQueueVolumes: types.optional(types.array(VolumeId), []),
        currentPortName: types.maybeNull(VolumeId),
        defragEnabled: types.optional(types.boolean, false),
        defragStateMap: types.map(DefragState),
        deferredParityCalculationStore: types.maybe(DeferredParityCalculationResult),
    })
    .volatile(() => ({
        orderBy: DISKS_VOLUME_NAME,
        order: ASC,
        currentPoolAffectedDisks: null,
    }))
    .views((self) => ({
        get volumes() {
            return (self.volumesStore && self.volumesStore.data) || [];
        },
        get affectedVolumes() {
            return (
                (self.volumesStore &&
                    self.volumesStore.data.filter(
                        (volume) =>
                            volume.status.health === HEALTH_CORRUPT ||
                            volume.status.health === HEALTH_DEGRADED ||
                            volume.status.health === HEALTH_UNKNOWN
                    )) ||
                []
            );
        },
        get queue() {
            return self.queueStore && self.queueStore.data;
        },
        get queueVolumes() {
            return (self.queueStore && self.queueStore.data && self.queueStore.data.volumes) || [];
        },
        get sortedVolumes() {
            const result =
                stableSort(
                    self.volumes.concat(
                        self.queueVolumes.map((v) => {
                            v.isQueue = true;
                            return v;
                        })
                    ),
                    getComparator(self.order, self.orderBy)
                ) || [];

            return result.reduce((acc, nextVolume) => {
                const dublicate = acc.find(
                    (volume) =>
                        volume.volumeId.pool === nextVolume.volumeId.pool && volume.volumeId.volume === nextVolume.volumeId.volume
                );

                if (dublicate) {
                    return acc;
                }

                return [...acc, nextVolume];
            }, []);
        },
        get sortedAffectedVolumes() {
            return (
                (self.currentPoolAffectedDisks &&
                    stableSort(self.currentPoolAffectedDisks, getComparator(self.order, self.orderBy))) ||
                []
            );
        },
        getDiskCountInPool: (poolName) => {
            return self.volumesStore ? self.volumesStore.data.filter((volume) => volume.volumeId.pool === poolName).length : 0;
        },
        get currentPort() {
            if (self.currentPortName) {
                const foundVolume =
                    self.volumes &&
                    self.volumes.find(
                        (volume) =>
                            volume.volumeId.pool === self.currentPortName.pool &&
                            volume.volumeId.volume === self.currentPortName.volume
                    );
                const foundQueueVolume =
                    self.queueVolumes &&
                    self.queueVolumes.find(
                        (volume) =>
                            volume.volumeId.pool === self.currentPortName.pool &&
                            volume.volumeId.volume === self.currentPortName.volume
                    );
                if (foundVolume) return { volume: foundVolume, isQueueVolume: false };
                if (foundQueueVolume) return { volume: foundQueueVolume, isQueueVolume: true };
            }

            return null;
        },
        getDefragState: (volumeId) => {
            if (!volumeId) return { fragmentation: -1 };

            return self.defragStateMap.get(volumeId.pool + "/" + volumeId.volume);
        },
        get deferredParityCalculation() {
            return self.deferredParityCalculationStore?.data;
        },
        get nonUniqueVolumes() {
            return self.volumes && self.queueVolumes
                ? self.volumes.filter((volume) =>
                      self.queueVolumes.some(
                          (queueVolume) =>
                              queueVolume.volumeId.pool === volume.volumeId.pool &&
                              queueVolume.volumeId.volume === volume.volumeId.volume
                      )
                  )
                : [];
        },
        get someNotOffline() {
            return (
                self.volumes &&
                self.checkedVolumes.some((checkedVolume) =>
                    self.volumes.some((volume) => {
                        return (
                            volume.volumeId.pool === checkedVolume.pool &&
                            volume.volumeId.volume === checkedVolume.volume &&
                            volume.status.status !== "offline"
                        );
                    })
                )
            );
        },
        get checkedVolumesCount() {
            return self.checkedVolumes.length + self.checkedQueueVolumes.length;
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
        get currentDiskNameWithPrefix() {
            const { ip, name, evoSettingsStore } = getParent(self);

            const prefix = getEvoPrefix({ ip, evoName: name, hostname: evoSettingsStore?.evoInfo?.hostname, store: self });

            return self.currentPortName ? `${prefix}${self.currentPortName.pool}/${self.currentPortName.volume}` : null;
        },
    }))
    .actions((self) => ({
        fetchVolumes: actionGuardByRole(
            flow(function* () {
                const { processingStore } = getParent(self);
                try {
                    processingStore.setLoading(true);
                    const req = GetVolumes.create().init();
                    const res = yield self.socket.send(req);
                    self.volumesStore = res;
                } catch (e) {
                    processingStore.setError(e);
                } finally {
                    processingStore.setLoading(false);
                }
            }),
            self
        ),
        fetchQueue: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetQueue.create().init();
                const res = yield self.socket.send(req);
                self.queueStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchDeferredParityCalculation: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetDeferredParityCalculation.create().init();
                const res = yield self.socket.send(req);
                self.deferredParityCalculationStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return false;
        }),
        enqueueVolumes: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                data.tasks = data.tasks.map((task) => ({ ...task, size: Math.floor(task.size) }));
                processingStore.setLoading(true);
                const req = EnqueueVolumes.create().init(data);
                yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
            return data;
        }),
        startQueue: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = StartQueue.create().init(data);
                yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        removeVolumes: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RemoveVolumes.create().init(data);
                yield self.socket.send(req);
                data.volumeIds.forEach((volumeId) => {
                    self.removeCheckedQueueVolume(volumeId);
                    self.removeCheckedVolume(volumeId);
                });
                self.fetchVolumes();
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        removeVolumesFromStore: (data) => {
            data.volumeIds.forEach((id) => {
                const foundVolume =
                    self.volumes &&
                    self.volumes.find((volume) => volume.volumeId.pool === id.pool && volume.volumeId.volume === id.volume);
                if (foundVolume) {
                    self.volumesStore.data.remove(foundVolume);
                }
            });
        },
        dequeueVolumes: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DequeueVolumes.create().init(data);
                yield self.socket.send(req);
                data.volumeIds.forEach((volumeId) => self.removeCheckedQueueVolume(volumeId));
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setVolumesOnline: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetVolumesOnline.create().init(data);
                yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        pauseQueue: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = PauseQueue.create().init();
                yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        clearQueue: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ClearQueue.create().init();
                yield self.socket.send(req);
                self.clearCheckedQueueVolumes();
                self.fetchQueue();
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        changeVolumeStatus: (data) => {
            const foundVolume =
                self.volumes &&
                self.volumes.find(
                    (volume) => volume.volumeId.pool === data.volumeId.pool && volume.volumeId.volume === data.volumeId.volume
                );
            if (foundVolume) {
                foundVolume.status = data.status;
            } else {
                self.fetchVolumes();
            }
        },
        changeVolume: (data) => {
            const foundVolume =
                self.volumes &&
                self.volumes.find(
                    (volume) => volume.volumeId.pool === data.volumeId.pool && volume.volumeId.volume === data.volumeId.volume
                );
            if (self?.volumesStore?.data) {
                if (foundVolume) {
                    self.volumesStore.data.remove(foundVolume);
                }
                self.volumesStore.data.push(data);
            }
        },
        changeQueueState: (data) => {
            self.queue && (self.queueStore.data = data);
        },
        addCheckedVolume: (data) => {
            const equalQueueVolume = self.checkedQueueVolumes.find((id) => id.pool === data.pool && id.volume === data.volume);
            self.checkedVolumes.push(data);
            if (equalQueueVolume) {
                self.removeCheckedQueueVolume(data);
                self.addCheckedQueueVolume(data);
            }
        },
        addCheckedQueueVolume: (data) => {
            self.checkedQueueVolumes.push(data);
        },
        removeCheckedVolume: (data) => {
            const equalQueueVolume = self.checkedQueueVolumes.find((id) => id.pool === data.pool && id.volume === data.volume);
            const volume = self.checkedVolumes.find((id) => id.pool === data.pool && id.volume === data.volume);
            self.checkedVolumes.remove(volume);
            equalQueueVolume && self.checkedQueueVolumes.remove(volume);
        },
        removeCheckedQueueVolume: (data) => {
            const volume = self.checkedQueueVolumes.find((id) => id.pool === data.pool && id.volume === data.volume);
            self.checkedQueueVolumes.remove(volume);
        },
        clearCheckedVolumes() {
            self.checkedVolumes = [];
        },
        clearCheckedQueueVolumes() {
            self.checkedQueueVolumes = [];
        },
        setCurrentPortName: (pool, volume) => {
            self.currentPortName = { pool, volume };
        },
        dropCurrentPortName: () => {
            self.currentPortName = null;
        },
        changeSorting(column) {
            if (column === self.orderBy) {
                self.order = self.order === DESC ? ASC : DESC;
            } else {
                self.order = ASC;
                self.orderBy = column;
            }
        },
        fetchDefragEnabled: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetDefragEnabled.create().init();
                const res = yield self.socket.send(req);
                self.defragEnabled = res.data;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchDefragState: flow(function* (volumeId, refresh) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetDefragmentationState.create().init({
                    volumeId: volumeId,
                    refresh: refresh,
                });
                const res = yield self.socket.send(req);
                self.defragStateMap.set(volumeId.pool + "/" + volumeId.volume, res.data);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        startDefrag: flow(function* (volumeId) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = StartDefragmentation.create().init(volumeId);
                yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        stopDefrag: flow(function* (volumeId) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = StopDefragmentation.create().init(volumeId);
                yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setDeferredParityCalculation: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetDeferredParityCalculation.create().init(data);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return false;
        }),
        updateDefragState: (state) => {
            self.defragStateMap.set(state.volumeId.pool + "/" + state.volumeId.volume, state);
        },
        setCurrentPoolAffectedDisks: (affectedDisks) => {
            self.currentPoolAffectedDisks = affectedDisks;
        },
        updateDeferredParityCalculationStore: (broadcast) => {
            if (self.deferredParityCalculationStore) {
                self.deferredParityCalculationStore.data = broadcast;
            }
        },
    }));

export default VolumeStore;
