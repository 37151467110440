import { useContext } from "react";
import { Context } from "store";
import { getEvoPrefix } from "../utils/helpers/getEvoPrefix";

const useEvoPrefix = ({ ip }) => {
    const {
        store: { evoSettingsStore },
        multipleEvosRootStore,
    } = useContext(Context);

    if (multipleEvosRootStore.storesLength === 0) return "";

    return getEvoPrefix({
        ip,
        evoName: multipleEvosRootStore?.getStore(ip)?.name,
        hostname: evoSettingsStore.evoInfo?.hostname,
    });
};

export default useEvoPrefix;
