import { types } from "mobx-state-tree";

const File = types.model({
    name: types.string,
    type: types.string,
    size: types.number,
    modifiedTime: types.string
});

export default File;
