import GetMainStepProgress from "api/upgrade/migration/Requests/GetMainStepProgress";
import GetMixedShares from "api/upgrade/migration/Requests/GetMixedShares";
import GetMigrationState from "api/upgrade/migration/Requests/GetMigrationState";
import RunExternalUsersStep from "api/upgrade/migration/Requests/RunExternalUsersStep";
import RunMainStep from "api/upgrade/migration/Requests/RunMainStep";
import RunShareTypesStep from "api/upgrade/migration/Requests/RunShareTypesStep";
import GetMainStepProgressResult from "api/upgrade/migration/Responses/GetMainStepProgressResult";
import GetMixedSharesResult from "api/upgrade/migration/Responses/GetMixedSharesResult";
import GetMigrationStateResult from "api/upgrade/migration/Responses/GetMigrationStateResult";
import { AD_CREDENTIALS_STEP, LDAP_CREDENTIALS_STEP, MAIN_STEP, SHARE_TYPES_STEP } from "const/migrationConst";
import { autorun } from "mobx";
import { types, flow, getParent, applySnapshot } from "mobx-state-tree";
import Socket from "websocket";
import { createCredentialsFrom, createMixedSharesFrom } from "./MigrationStore.utils";
import { debugDelay } from "utils/debugDelay";
import GetLdapMigrationInfoResult from "api/upgrade/migration/Responses/GetLdapMigrationInfoResult";
import GetLdapMigrationInfo from "api/upgrade/migration/Requests/GetLdapMigrationInfo";
import GetAdMigrationInfoResult from "api/upgrade/migration/Responses/GetAdMigrationInfoResult";
import GetAdMigrationInfo from "api/upgrade/migration/Requests/GetAdMigrationInfo";
import i18n from "i18n";
import SkipExternalUsersStep from "api/upgrade/migration/Requests/SkipExternalUsersStep";
import AclMigrationProgressChangedEvent from "api/upgrade/migration/Types/AclMigrationProgressChangedEvent";

function switchTitle(step) {
    switch (step) {
        case AD_CREDENTIALS_STEP:
            return "AD credentials";
        case LDAP_CREDENTIALS_STEP:
            return "LDAP credentials";
        case SHARE_TYPES_STEP:
            return "Select type for mixed share";
        case MAIN_STEP:
            return "Migration progress";
        default:
            return "";
    }
}

export default types
    .model("MigrationStore", {
        stepsResult: types.maybe(GetMigrationStateResult),
        mixedSharesResult: types.maybe(GetMixedSharesResult),
        mainStepProgressResult: types.maybe(GetMainStepProgressResult),
        ldapMigrationInfoResult: types.maybe(GetLdapMigrationInfoResult),
        adMigrationInfoResult: types.maybe(GetAdMigrationInfoResult),
        aclMigrationProgressChangedResult: types.maybe(AclMigrationProgressChangedEvent),
    })
    .volatile(() => ({
        _adminCredentialsForm: createCredentialsFrom(),
        _externalCredentialsForm: createCredentialsFrom(),
        _mixedSharesForm: createMixedSharesFrom(),
        _activeStep: 0,
        _startedProcceedMigration: false,
        _isFetchingMixedShares: false,
    }))
    .views((self) => ({
        get startedProcceedMigration() {
            return self._startedProcceedMigration;
        },
        get mainStepProgress() {
            return self.mainStepProgressResult?.data ?? [];
        },
        get typeActiveStep() {
            return self.steps[self.activeStep].type;
        },
        get activeStep() {
            return self._activeStep;
        },
        get isInitMixedShareForm() {
            return Boolean(self._mixedSharesForm.values());
        },
        get mixedSharesForm() {
            return self._mixedSharesForm;
        },
        get adminCredentialsForm() {
            return self._adminCredentialsForm;
        },
        get externalCredentialsForm() {
            return self._externalCredentialsForm;
        },
        get isMigrationProcess() {
            return Boolean(self.stepsResult?.data?.requiredSteps?.length);
        },
        get steps() {
            const listSteps = self.stepsResult?.data?.requiredSteps?.map((step, idx) => ({
                index: idx,
                name: switchTitle(step),
                type: step,
            }));

            return listSteps ?? [];
        },
        get mixedShares() {
            return self.mixedSharesResult?.data ?? [];
        },
        get isSuccessMigration() {
            const isAllStageSuccess = self.mainStepProgressResult?.data?.every(
                (el) => el.stageState === "finished" || el.stageState === "warn"
            );
            const isSuccessStateServer = self.stepsResult?.data?.migrationState === "success";

            return isAllStageSuccess || isSuccessStateServer;
        },
        get isFinishedMigration() {
            return self.stepsResult?.data?.migrationState === "finished";
        },
        get isRunningMigration() {
            return self.stepsResult?.data?.migrationState === "running";
        },
        get isIdleMigration() {
            return self.stepsResult?.data?.migrationState === "running";
        },
        get isFetchingMixedShares() {
            return self._isFetchingMixedShares;
        },
        get isFailedMigration() {
            return self.mainStepProgressResult?.data?.some((el) => el.stageState === "error");
        },
        get warningList() {
            return self.stepsResult?.data?.warnings ?? [];
        },
        get ldapMigrationInfo() {
            return self.ldapMigrationInfoResult?.data;
        },
        get adMigrationInfo() {
            return self.adMigrationInfoResult?.data;
        },
    }))
    .actions((self) => ({
        setStep(step) {
            self._activeStep = step;
        },
        nextStep() {
            self._activeStep++;
        },
        resetStore() {
            applySnapshot(self, {});
        },
        getMainStepProgress: flow(function* () {
            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);
                const req = GetMainStepProgress.create().init();
                const res = yield Socket.send(req);

                self.mainStepProgressResult = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }

            return null;
        }),
        getMigrationState: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);

                const req = GetMigrationState.create().init();
                const res = yield Socket.send(req);
                self.stepsResult = res;

                self._startedProcceedMigration = res?.data?.migrationState === "running";

                const idxCurrentStep = self._startedProcceedMigration
                    ? res?.data?.requiredSteps.length - 1
                    : res?.data?.requiredSteps?.findIndex((step) => step === res?.data?.currentStep);

                self.setStep(~idxCurrentStep ? idxCurrentStep : 0);

                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getMixedShares: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                self._isFetchingMixedShares = true;
                const req = GetMixedShares.create().init();
                const res = yield Socket.send(req);

                self.mixedSharesResult = yield debugDelay(res, 10000);

                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
                self._isFetchingMixedShares = false;
            }
            return null;
        }),
        updateStatusMigration(broadcast) {
            if (!self.mainStepProgressResult?.data) return;

            const mainStepProgress = [...self.mainStepProgressResult.data];
            const indexElement = mainStepProgress.findIndex((el) => el.name === broadcast.stage.name);
            mainStepProgress.splice(indexElement, 1, broadcast.stage);

            self.mainStepProgressResult.data = mainStepProgress;
        },
        runShareTypesStep: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const stateAfterSubmit = yield self._mixedSharesForm.submit();
                if (!stateAfterSubmit.isValid) return;

                const shareTypes = Object.entries(self._mixedSharesForm.values()).map(([share, type]) => ({
                    share,
                    type,
                }));

                const payload = {
                    shareTypes,
                };

                const req = RunShareTypesStep.create().init(payload);
                const res = yield Socket.send(req);

                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        runExternalUsersStep: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);

                const stateAfterSubmit = yield self._externalCredentialsForm.submit();
                if (!stateAfterSubmit.isValid) return;

                const payload = self._externalCredentialsForm.values();

                const req = RunExternalUsersStep.create().init(payload);
                const res = yield Socket.send(req);

                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        runMainStep: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);

                self._startedProcceedMigration = true;

                const req = RunMainStep.create().init();
                const res = yield Socket.send(req);

                return res;
            } catch (e) {
                processingStore.setError(e);
                self._startedProcceedMigration = false;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        getLdapMigrationInfo: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetLdapMigrationInfo.create().init();
                const res = yield Socket.send(req);
                self.ldapMigrationInfoResult = res;
                self._externalCredentialsForm.$("name").set(self.ldapMigrationInfoResult?.data?.bindDn);
                return res;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        getAdMigrationInfo: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetAdMigrationInfo.create().init();
                const res = yield Socket.send(req);
                self.adMigrationInfoResult = res;
                self._externalCredentialsForm.$("name").set(self.adMigrationInfoResult?.data?.user);
                return res;
            } finally {
                processingStore.setLoading(false);
            }
        }),
    }))
    .actions((self) => ({
        skipExternalUsersStep: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SkipExternalUsersStep.create().init();
                const res = yield Socket.send(req);
                self.nextStep();
                return res;
            } finally {
                processingStore.setLoading(false);
            }
        }),
    }))
    .actions((self) => ({
        updateAclProgress(broadcast) {
            self.aclMigrationProgressChangedResult = broadcast;
        },
    }))
    .actions((self) => ({
        afterCreate() {
            const { authStore, processingStore, bootProgressStore } = getParent(self);
            autorun(() => {
                if (self.isSuccessMigration) {
                    self.getMigrationState();
                }
            });

            autorun(() => {
                if (self.mixedShares && self.mixedShares.length && self._mixedSharesForm.values() === undefined) {
                    const obj = {};
                    Array.from(self.mixedShares, (share) => {
                        obj[share.share] = share.types[0];
                    });
                    self.mixedSharesForm.init(obj);
                }
            });

            autorun(() => {
                if (self.isMigrationProcess && !authStore.isAdmin && authStore.isAuthorized) {
                    processingStore.setWarning(i18n.t("migration.migration_is_not_completed.warning"));
                    authStore.logout();
                }
            });

            autorun(() => {
                if (bootProgressStore.isRebooting && self.isSuccessMigration) self.resetStore();
            });
        },
    }));
