import { flow, getParent, types } from "mobx-state-tree";
import Socket from "websocket";
import GetUpsSettings from "api/power/Requests/GetUpsSettings";
import GetUpsList from "api/power/Requests/GetUpsList";
import UpsArrayResult from "api/power/Responses/UpsArrayResult";
import SetUpsSettings from "api/power/Requests/SetUpsSettings";
import UpsSettingsResult from "api/power/Responses/UpsSettingsResult";
import Reboot from "api/power/Requests/Reboot";
import Shutdown from "api/power/Requests/Shutdown";
import GetPowerStatus from "api/power/Requests/GetPowerStatus";
import UptimeResult from "api/power/Responses/UptimeResult";
import GetUptime from "api/power/Requests/GetUptime";
import PowerStatusResult from "api/power/Responses/PowerStatusResult";

const PowerAndUpsStore = types
    .model({
        upsArrayResult: types.maybe(UpsArrayResult),
        upsSettingsResult: types.maybe(UpsSettingsResult),
        uptimeResult: types.maybe(UptimeResult),
        powerStatusResult: types.maybe(PowerStatusResult),
        isRebootLoading: types.maybe(types.boolean),
    })
    .volatile(() => ({
        interval: 0,
        _isRebooting: false,
    }))
    .views((self) => ({
        get isRebooting() {
            return self._isRebooting;
        },
        get isAnyUps() {
            return self.upsArrayResult?.data.total;
        },
        get ups() {
            return this.isAnyUps ? self.upsArrayResult?.data.arr[0] : {};
        },
        get upsSettings() {
            return self.upsSettingsResult?.data || {};
        },
        get powerStatusAllEvents() {
            return self.powerStatusResult?.data;
        },
        get powerSupply() {
            return this.powerStatusAllEvents?.status.powerSupply;
        },
        get chargeLevelPercent() {
            return this.powerStatusAllEvents?.status.chargeLevelPercent || 0;
        },
        get uptimeTotalSeconds() {
            return self.uptimeResult?.data.totalSeconds || 0;
        },
    }))
    .actions((self) => ({
        getPowerStatus: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetPowerStatus.create().init();
                const res = yield Socket.send(req);
                self.powerStatusResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        updatePowerStatusAllEvents: (broadcast) => {
            if (self.powerStatusResult) {
                self.powerStatusResult.data = broadcast;
            }
        },
        updatePowerStatus: (broadcast) => {
            if (self.powerStatusResult) {
                self.powerStatusResult.data.status = broadcast;
            }
        },
        updateUpsSettings: (broadcast) => {
            if (self.upsSettingsResult) {
                self.upsSettingsResult.data = broadcast;
            }
        },
        updateUpsArrayResultData: (broadcast) => {
            if (self.upsArrayResult) {
                self.upsArrayResult.data = broadcast;
            }
        },
        getUpsSettings: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetUpsSettings.create().init();
                const res = yield Socket.send(req);
                self.upsSettingsResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        getUpsList: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetUpsList.create().init();
                const res = yield Socket.send(req);
                self.upsArrayResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getUptime: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetUptime.create().init();
                const res = yield Socket.send(req);
                self.uptimeResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setUpsSettings: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetUpsSettings.create().init(data);
                const res = yield Socket.send(req);
                res && (self.upsSettingsResult.data = data);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        reboot: flow(function* () {
            const { processingStore } = getParent(self);
            self.isRebootLoading = true;
            self._isRebooting = true;

            try {
                processingStore.setLoading(true);
                const req = Reboot.create().init({ force: true });
                return yield Socket.send(req);
            } catch (e) {
                processingStore.setError(e);
                self._isRebooting = false;
            } finally {
                processingStore.setLoading(false);
                self.isRebootLoading = false;
            }
            return null;
        }),
        shutdown: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = Shutdown.create().init({ force: true });
                return yield Socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        startTimer: () => {
            if (self.interval) {
                clearInterval(self.interval);
            }
            if (self.uptimeTotalSeconds) {
                self.interval = setInterval(() => {
                    self.addSecToTime();
                }, 1000);
            }
        },
        addSecToTime: () => {
            self.uptimeResult.data.totalSeconds += 1;
        },
        stopTimer: () => {
            clearInterval(self.interval);
        },
    }));

export default PowerAndUpsStore;
