import React from "react";
import DataCard from "components/DataCard";
import ThroughputWidget from "components/graphs/ThroughputWidget";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { THROUGHPUT_GRAPH_CARD } from "const/widgetNameConst";
import { useTranslation } from "react-i18next";
import HideOverlay from "components/HideOverlay/HideOverlay";

const ThroughputGraphCard = ({ parentId = "throughputDashboardCard", ip }) => {
    const { t } = useTranslation();

    return (
        <DataCard
            autoHeight
            headerDivider
            title={t("dashboard.throughput_widget.title")}
            headerControl={
                <HideOverlay show={!ip}>
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={THROUGHPUT_GRAPH_CARD} />
                    </MoreMenu>
                </HideOverlay>
            }
        >
            <ThroughputWidget parentId={parentId} ip={ip} />
        </DataCard>
    );
};

export default ThroughputGraphCard;
