import Request from "api/Request";

const IsSharebrowserServerEnabled = Request.props({
    path: "/com/studionetworksolutions/evo/manage/SharebrowserServer",
    method: "is_sharebrowser_server_enabled"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default IsSharebrowserServerEnabled;
