import Request from "api/Request";

const GetId = Request.props({
    path: "/com/studionetworksolutions/evo/services/Backup",
    method: "get_id"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetId;
