import { flow, getParent, types } from "mobx-state-tree";
import GetSnmpSettings from "api/events/Requests/GetSnmpSettings";
import Socket from "websocket";
import SnmpSettingsResult from "api/events/Responses/SnmpSettingsResult";
import SetSnmpSettings from "api/events/Requests/SetSnmpSettings";

const SnmpStore = types
    .model({
        snmpSettingsResult: types.maybe(SnmpSettingsResult)
    })
    .views(self => ({
        get snmpSettings() {
            return self.snmpSettingsResult?.data || {};
        }
    }))
    .actions(self => ({
        getSnmpSettings: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetSnmpSettings.create().init();
                const res = yield Socket.send(req);
                self.snmpSettingsResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setSnmpSettings: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetSnmpSettings.create().init(data);
                const res = yield Socket.send(req);
                self.snmpSettings.enabled = data.enabled;
                self.snmpSettings.snmpHost = data.snmpHost;
                self.snmpSettings.snmpPort = data.snmpPort;
                return res;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        })
    }));

export default SnmpStore;
