import React from "react";
import { observer } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import i18next from "i18next";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useStyles } from "./Stage.styles";
import StatusLabel from "../StatusLabel";
import { useStore } from "hooks/useStore";

const Stage = ({ list, success }) => {
    const classes = useStyles();
    const {
        store: { migrationStore },
    } = useStore();

    const AnimationIcon = observer(({ stageState }) => {
        switch (stageState) {
            case "finished":
                return <StatusLabel status={stageState} />;
            case "error":
                return <StatusLabel status={stageState} />;
            case "warn":
                return <StatusLabel status={stageState} text="Warning" />;
            case "not_started":
            default:
                if (migrationStore.startedProcceedMigration || migrationStore.isRunningMigration)
                    return <StatusLabel text="Pending" />;
                return <StatusLabel text="Not started" />;
        }
    });

    if (!list?.length) return null;

    return (
        <Grid container spacing={2}>
            <Grid container item className={classes.row}>
                <Grid item xs={9} container alignContent="center">
                    <Typography variant="h6">{i18next.t("migrator.main_step.column.first.title")}</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="center" alignContent="center">
                    <Typography variant="h6">
                        {i18next.t(
                            success ? "migrator.main_step.column.second.title.status" : "migrator.main_step.column.second.title"
                        )}
                    </Typography>
                </Grid>
            </Grid>
            {list.map((el) => (
                <React.Fragment key={el.name}>
                    <Grid container item className={classes.row}>
                        <Grid item xs={9} container alignContent="center">
                            <Typography>{i18next.t(el.name)}</Typography>
                        </Grid>
                        <Grid item xs={3} container justifyContent="center" alignContent="center">
                            <AnimationIcon stageState={el.stageState} />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <ErrorMessage errors={el.errors} name={el.name} stageState={el.stageState} />
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
};

export default observer(Stage);
