const createFields = () => {
    return [
        { name: "evoVersionAvailable", type: "checkbox" },
        { name: "localTimeAvailable", type: "checkbox" },
        { name: "systemUptimeAvailable", type: "checkbox" },
        { name: "cpuUsageAvailable", type: "checkbox" },
        { name: "upsStatusAvailable", type: "checkbox" },
        { name: "blocksIoAvailable", type: "checkbox" },
        { name: "ioStatAvailable", type: "checkbox" },
        { name: "sessionsAvailable", type: "checkbox" },
        { name: "cachedVolumeAvailable", type: "checkbox" },
        { name: "detailedStatAvailable", type: "checkbox" }
    ];
};

export default createFields;
