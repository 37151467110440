import { makeStyles } from "@material-ui/core/styles";
import {
    DRAWER_WIDTH,
    GRAPHICS_BAR_HEIGHT,
    GRAPHICS_BAR_HEIGHT_MOBILE,
    HEADER_HEIGHT,
    HEADER_HEIGHT_MOBILE,
    STATE_BAR_HEIGHT,
    STATE_BAR_HEIGHT_MOBILE
} from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    drawer: {
        "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            [theme.breakpoints.up("md")]: {
                top: HEADER_HEIGHT
            },
            top: HEADER_HEIGHT_MOBILE,
            bottom: 0,
            height: "initial",
            overflowY: "initial",
            boxShadow: "-5px 0 29px 0 rgba(0, 0, 0, 0.1)"
        }
    },
    drawerOpenHeader: {
        "& .MuiDrawer-paper": {
            [theme.breakpoints.up("md")]: {
                top: HEADER_HEIGHT + GRAPHICS_BAR_HEIGHT + STATE_BAR_HEIGHT
            },
            top: HEADER_HEIGHT_MOBILE + GRAPHICS_BAR_HEIGHT_MOBILE + STATE_BAR_HEIGHT_MOBILE + theme.spacing(2)
        }
    },
    container: {
        height: "100%",
        flexWrap: "nowrap",
        overflowY: "auto",
        overflowX: "hidden"
    },
    actionContainer: {
        margin: theme.spacing(-6),
        width: `calc(100% + ${theme.spacing(12)}px)`
    },
    headerButtonGroup: {
        padding: theme.spacing(0, 6, 4),
        width: "100%",
        "& > button": {
            width: "50%",
            fontSize: 14,
            padding: theme.spacing(1.5)
        }
    },
    body: {
        margin: 0,
        width: "100%",
        flex: 1,
        padding: theme.spacing(4)
    },
    textInfo: {
        fontWeight: 500,
        paddingLeft: theme.spacing(2)
    }
}));
