import React from "react";
import Dialog from "components/Dialog";
import { useObserver } from "mobx-react";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const CONFIRMATION_DIALOG_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_confirmation_dialog`;

const ConfirmationDialog = ({ open, onClose, onConfirm, ifaceName }) => {
    const { t } = useTranslation();
    const confirm = () => {
        onConfirm();
        onClose();
    };

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={t("admin_settings.restrict_access.edit_restrict_modal.confirmation_dialog.title")}
            submitBtn={
                <Button variant={"contained"} color={"primary"} onClick={confirm} id={`${CONFIRMATION_DIALOG_ID_PREFIX}_proceed`}>
                    {t("common.button.proceed")}
                </Button>
            }
            buttons={
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={onClose}
                    id={`${CONFIRMATION_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
            withoutIconClose
            withoutDividers
        >
            <Typography gutterBottom>
                {t("admin_settings.restrict_access.edit_restrict_modal.confirmation_dialog.info_text", { name: ifaceName })}
            </Typography>
            <Typography>{t("admin_settings.restrict_access.edit_restrict_modal.confirmation_dialog.question")}</Typography>
        </Dialog>
    ));
};

export default ConfirmationDialog;
