const parseFilterRules = filter => {
    let extensions = "";
    let regexp = "";
    let useRegexp = false;
    let url = "";

    if (filter.rules) {
        for (let i = 0; i < filter.rules.length; i++) {
            let rule = filter.rules[i];
            if (rule.regexp) {
                if (regexp.length === 0) {
                    regexp = rule.name_mask;
                    useRegexp = true;
                }
            } else {
                extensions += rule.name_mask + " ";
            }
            if (url.length === 0 && !rule.black) {
                url = decodeURIComponent(rule.url);
            }
        }
    }

    return { useRegexp, extensions, regexp, url };
};
export default parseFilterRules;
