import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { isAlive } from "mobx-state-tree";
import { SizeMe } from "react-sizeme";

import { DEFAULT_START_PERIOD, DEFAULT_ANIM_DURATION } from "const/chartConst";
import ThroughputGraph from "./ThroughputGraph";
import useStoreByIp from "hooks/useStoreByIp";

const ThroughputWidget = ({ parentId, height = 150, animDuration = DEFAULT_ANIM_DURATION, isDark, ip }) => {
    const { throughputGraphStore, uiStore } = useStoreByIp({ ip });
    const state = useLocalStore(() => ({
        key: ""
    }));

    useEffect(() => {
        throughputGraphStore.registerGraph();
        throughputGraphStore.fetchData();
        return () => {
            if (!isAlive(throughputGraphStore)) return;
            throughputGraphStore.unregisterGraph();
            throughputGraphStore.stopFetching();
        };
    }, []);

    return useObserver(() => {
        if (throughputGraphStore.chartInfo.length === 0) return null;
        const graphStartPeriod = uiStore.parameters?.graphStartPeriod || DEFAULT_START_PERIOD;

        return (
            <SizeMe refreshRate={500}>
                {({ size }) => {
                    if (size.width) state.key = `${parentId}-${size.width}-${graphStartPeriod}`;
                    const uniqueParentId = ip
                        ? parentId +
                          String(ip)
                              .split(".")
                              .join("")
                        : parentId;
                    return (
                        <ThroughputGraph
                            {...{
                                key: state.key,
                                parentId: uniqueParentId,
                                height,
                                animDuration,
                                isDark,
                                chartInfo: throughputGraphStore.chartInfo,
                                startFetching: throughputGraphStore.fetchData,
                                stopFetching: throughputGraphStore.stopFetching,
                                lastTime: throughputGraphStore.lastTime,
                                lastInValue: throughputGraphStore.lastInValue,
                                lastOutValue: throughputGraphStore.lastOutValue,
                                graphStartPeriod
                            }}
                        />
                    );
                }}
            </SizeMe>
        );
    });
};

export default ThroughputWidget;
