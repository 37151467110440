import i18n from "i18n";

export const METHOD_OPTIONS = {
    copy: "Copy",
    sync: "Sync"
};
export const METHOD_OPTIONS_ARRAY = [
    { value: METHOD_OPTIONS.copy, label: i18n.t("slingshot_page.tabs.replications.create_job.method.option.copy") },
    { value: METHOD_OPTIONS.sync, label: i18n.t("slingshot_page.tabs.replications.create_job.method.option.sync") }
];

export const SOURCE_OPTIONS = {
    share: "evo",
    logicalDisk: "ld",
    smb: "smb",
    ftp: "ftp",
    amazon: "s3",
    azure: "azure",
    dropbox: "dropbox",
    gcs: "gcs",
    excludedShare: "deleted"
};
export const SOURCE_OPTIONS_ARRAY = [
    {
        value: SOURCE_OPTIONS.share,
        label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.this_evo_share")
    },
    {
        value: SOURCE_OPTIONS.logicalDisk,
        label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.this_evo_logical_disk")
    },
    { value: SOURCE_OPTIONS.smb, label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.smb") },
    { value: SOURCE_OPTIONS.ftp, label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.ftp") },
    { value: SOURCE_OPTIONS.amazon, label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.amazon") },
    { value: SOURCE_OPTIONS.azure, label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.microsoft") },
    { value: SOURCE_OPTIONS.dropbox, label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.dropbox") },
    { value: SOURCE_OPTIONS.gcs, label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.gcs") }
];

export const SOURCE_OPTION_EXCLUDED_SHARE = {
    value: SOURCE_OPTIONS.excludedShare,
    label: i18n.t("slingshot_page.tabs.replications.source_destination.placeholder")
};

export const DESTINATION_OPTIONS = {
    share: "evo",
    smb: "smb",
    ftp: "ftp",
    amazon: "s3",
    azure: "azure",
    dropbox: "dropbox",
    gcs: "gcs",
    excludedShare: "deleted"
};
export const DESTINATION_OPTIONS_ARRAY = [
    {
        value: DESTINATION_OPTIONS.share,
        label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.this_evo_share")
    },
    { value: DESTINATION_OPTIONS.smb, label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.smb") },
    { value: DESTINATION_OPTIONS.ftp, label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.ftp") },
    { value: DESTINATION_OPTIONS.amazon, label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.amazon") },
    { value: DESTINATION_OPTIONS.azure, label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.microsoft") },
    { value: DESTINATION_OPTIONS.dropbox, label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.dropbox") },
    { value: DESTINATION_OPTIONS.gcs, label: i18n.t("slingshot_page.tabs.replications.create_job.source.option.gcs") }
];

export const DESTINATION_OPTION_EXCLUDED_SHARE = {
    value: DESTINATION_OPTIONS.excludedShare,
    label: i18n.t("slingshot_page.tabs.replications.source_destination.placeholder")
};

export const SCHEDULE_PERIOD_OPTIONS = {
    manual: "manual",
    once: "once",
    hourly: "hourly",
    daily: "daily",
    weekly: "weekly"
};
export const SCHEDULE_PERIOD_OPTIONS_ARRAY = [
    {
        value: SCHEDULE_PERIOD_OPTIONS.manual,
        label: i18n.t("slingshot_page.tabs.replications.create_job.schedule.option.manual")
    },
    { value: SCHEDULE_PERIOD_OPTIONS.once, label: i18n.t("slingshot_page.tabs.replications.create_job.schedule.option.once") },
    {
        value: SCHEDULE_PERIOD_OPTIONS.hourly,
        label: i18n.t("slingshot_page.tabs.replications.create_job.schedule.option.hourly")
    },
    { value: SCHEDULE_PERIOD_OPTIONS.daily, label: i18n.t("slingshot_page.tabs.replications.create_job.schedule.option.daily") },
    { value: SCHEDULE_PERIOD_OPTIONS.weekly, label: i18n.t("slingshot_page.tabs.replications.create_job.schedule.option.weekly") }
];

export const DAY_OF_WEEKS_OPTIONS = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 0
};
export const DAY_OF_WEEKS_OPTIONS_ARRAY = [
    { value: DAY_OF_WEEKS_OPTIONS.monday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.monday") },
    { value: DAY_OF_WEEKS_OPTIONS.tuesday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.tuesday") },
    { value: DAY_OF_WEEKS_OPTIONS.wednesday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.wednesday") },
    { value: DAY_OF_WEEKS_OPTIONS.thursday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.thursday") },
    { value: DAY_OF_WEEKS_OPTIONS.friday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.friday") },
    { value: DAY_OF_WEEKS_OPTIONS.saturday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.saturday") },
    { value: DAY_OF_WEEKS_OPTIONS.sunday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.sunday") }
];

export const DATE_PERIODS_OPTIONS = {
    sec: "seconds",
    min: "minutes",
    hour: "hours",
    day: "days",
    week: "weeks",
    month: "months",
    year: "years"
};

export const DATE_PERIODS_OPTIONS_ARRAY = [
    { value: DATE_PERIODS_OPTIONS.sec, label: i18n.t("slingshot_page.tabs.replications.create_job.date_option.sec") },
    { value: DATE_PERIODS_OPTIONS.min, label: i18n.t("slingshot_page.tabs.replications.create_job.date_option.min") },
    { value: DATE_PERIODS_OPTIONS.hour, label: i18n.t("slingshot_page.tabs.replications.create_job.date_option.hour") },
    { value: DATE_PERIODS_OPTIONS.day, label: i18n.t("slingshot_page.tabs.replications.create_job.date_option.day") },
    { value: DATE_PERIODS_OPTIONS.week, label: i18n.t("slingshot_page.tabs.replications.create_job.date_option.week") },
    { value: DATE_PERIODS_OPTIONS.month, label: i18n.t("slingshot_page.tabs.replications.create_job.date_option.month") },
    { value: DATE_PERIODS_OPTIONS.year, label: i18n.t("slingshot_page.tabs.replications.create_job.date_option.year") }
];

export const DATE_PERIODS_OPTIONS_LIMITATION = {
    [DATE_PERIODS_OPTIONS.sec]: 2144448000,
    [DATE_PERIODS_OPTIONS.min]: 35740800,
    [DATE_PERIODS_OPTIONS.hour]: 595680,
    [DATE_PERIODS_OPTIONS.day]: 24820,
    [DATE_PERIODS_OPTIONS.week]: 3536,
    [DATE_PERIODS_OPTIONS.month]: 816,
    [DATE_PERIODS_OPTIONS.year]: 68
};

export const INCLUDE_EXCLUDE_PRIORITY_OPTIONS = {
    include: "include",
    exclude: "exclude"
};
export const INCLUDE_EXCLUDE_PRIORITY_OPTIONS_ARRAY = [
    {
        value: INCLUDE_EXCLUDE_PRIORITY_OPTIONS.include,
        label: i18n.t("slingshot_page.tabs.replications.create_job.include_priority_option.include")
    },
    {
        value: INCLUDE_EXCLUDE_PRIORITY_OPTIONS.exclude,
        label: i18n.t("slingshot_page.tabs.replications.create_job.include_priority_option.exclude")
    }
];

export const REPORT_VARIANTS_OPTIONS = {
    diff: "diff",
    all: "all"
};
export const REPORT_VARIANTS_OPTIONS_ARRAY = [
    { value: REPORT_VARIANTS_OPTIONS.diff, label: i18n.t("slingshot_page.tabs.replications.create_job.report_type_option.diff") },
    { value: REPORT_VARIANTS_OPTIONS.all, label: i18n.t("slingshot_page.tabs.replications.create_job.report_type_option.all") }
];

export const LOG_LEVEL_OPTIONS = {
    no: "NO_LOGS",
    standard: "STANDARD",
    full: "FULL"
};
export const LOG_LEVEL_OPTIONS_ARRAY = [
    { value: LOG_LEVEL_OPTIONS.no, label: i18n.t("slingshot_page.tabs.replications.create_job.log_level_option.no") },
    { value: LOG_LEVEL_OPTIONS.standard, label: i18n.t("slingshot_page.tabs.replications.create_job.log_level_option.standard") },
    { value: LOG_LEVEL_OPTIONS.full, label: i18n.t("slingshot_page.tabs.replications.create_job.log_level_option.full") }
];

export const HOURS_OPTIONS = [
    { value: 0, label: "00:00" },
    { value: 1, label: "01:00" },
    { value: 2, label: "02:00" },
    { value: 3, label: "03:00" },
    { value: 4, label: "04:00" },
    { value: 5, label: "05:00" },
    { value: 6, label: "06:00" },
    { value: 7, label: "07:00" },
    { value: 8, label: "08:00" },
    { value: 9, label: "09:00" },
    { value: 10, label: "10:00" },
    { value: 11, label: "11:00" },
    { value: 12, label: "12:00" },
    { value: 13, label: "13:00" },
    { value: 14, label: "14:00" },
    { value: 15, label: "15:00" },
    { value: 16, label: "16:00" },
    { value: 17, label: "17:00" },
    { value: 18, label: "18:00" },
    { value: 19, label: "19:00" },
    { value: 20, label: "20:00" },
    { value: 21, label: "21:00" },
    { value: 22, label: "22:00" },
    { value: 23, label: "23:00" }
];

export const CREATE_JOB_DEFAULT_OPTIONS = {
    method: METHOD_OPTIONS.copy,
    source: SOURCE_OPTIONS.share,
    destination: DESTINATION_OPTIONS.share,
    schedulePeriod: SCHEDULE_PERIOD_OPTIONS.daily,
    dayOfWeek: DAY_OF_WEEKS_OPTIONS.monday,
    datePeriod: DATE_PERIODS_OPTIONS.day,
    includeExcludePriority: INCLUDE_EXCLUDE_PRIORITY_OPTIONS.include,
    reportVariant: REPORT_VARIANTS_OPTIONS.diff,
    logLevel: LOG_LEVEL_OPTIONS.standard,
    startAt: 1,
    endAt: 2,
    logsDestinationFolder: "evo-replication-logs/"
};

export const ALIAS_SCHEMA_VALUE = "alias";
export const SLINGSHOT_DATE_FORMAT_FOR_SERVER = "YYYY-MM-DD HH:mm:ss";

export const TARGET_FOR_FOLDER_BROWSER = {
    source: "sourcePath",
    destination: "destinationPath"
};
