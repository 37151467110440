import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetConfig from "api/webserver/Requests/GetConfig";
import SetConfig from "api/webserver/Requests/SetConfig";
import GetConfigType from "api/webserver/Responses/GetConfigResult";
import ChangeWebAccessCredentials from "api/webserver/Requests/ChangeWebAccessCredentials";
import GetRestrictUiSettings from "api/webserver/Requests/GetRestrictUiSettings";
import RestrictUiSettingsResult from "api/webserver/Responses/RestrictUiSettingsResult";
import SetRestrictUiSettings from "api/webserver/Requests/SetRestrictUiSettings";

const WebserverStore = types
    .model({
        configInfoStore: types.maybe(GetConfigType),
        restrictUiSettingsStore: types.maybe(RestrictUiSettingsResult),
    })
    .views((self) => ({
        get configInfo() {
            return self.configInfoStore && self.configInfoStore.data;
        },
        get restrictSettings() {
            return self.restrictUiSettingsStore && self.restrictUiSettingsStore.data;
        },
    }))
    .actions((self) => ({
        fetchConfigInfo: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetConfig.create().init();
                const res = yield Socket.send(req);
                self.configInfoStore = res;

                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchRestrictSettings: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetRestrictUiSettings.create().init();
                const res = yield Socket.send(req);
                self.restrictUiSettingsStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setRestrictSettings: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetRestrictUiSettings.create().init(data);
                yield Socket.send(req);
                self.fetchRestrictSettings();
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setConfig: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetConfig.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        changeCredentials: flow(function* (data) {
            const { processingStore, authStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ChangeWebAccessCredentials.create().init(data);
                const res = yield Socket.send(req);
                authStore.clearAuthorization();
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
    }));

export default WebserverStore;
