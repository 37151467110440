import React, { useContext } from "react";
import { useObserver } from "mobx-react";
import { Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Dialog from "components/Dialog";
import { Context } from "store";
import localizations from "./NeedForceDialog.localizations";
import LocalizationTypography from "components/LocalizationTypography/LocalizationTypography";

const NeedForceDialog = ({ onClose, open }) => {
    const {
        store: { upgradesStore }
    } = useContext(Context);
    const { t } = useTranslation();

    const onSubmit = async () => {
        upgradesStore.upgrade({ filename: upgradesStore.chosenFileName, force: true });
        onClose();
    };

    return useObserver(() => (
        <Dialog
            buttons={[
                <Button key={"cancelBtn"} onClick={onClose} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>
            ]}
            open={open}
            onSubmit={onSubmit}
            submitBtnLabel={t("common.button.proceed")}
            title={t("upgrades.upgrade_system.need_force_dialog.title")}
            onClose={onClose}
            withoutIconClose
            withoutDividers
        >
            <Grid container>
                <Grid item>
                    <LocalizationTypography paragraphs={localizations.needForceDialogConfirm.paragraphs} />
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default NeedForceDialog;
