import { FAILED_DRIVE_STATUS } from "const/driveConst";
import { useStore } from "hooks/useStore";
import { autorun } from "mobx";
import { useEffect, useState } from "react";

/**
 @typedef {{drive: {quad: number; drive: number}; status: string; type: string}} AffectPoolDrive
 @typedef {{quad: number; drive: number; status: string; type: string}} Drive
*/

/**
 * Hook for processing information about failed drive.
 * @returns {{failedDrives: Array<Drive>, isFailedDrive: boolean }} the state of the failed drive. Where the first property is failedDrives an array failed drive, the second property is isFailedDrive a state of presence of failed drive.
 */
export const useFailedDrives = () => {
    const {
        store: { driveReplaceStore, driveStore }
    } = useStore();

    /** @type {[Array<Drive>, function]} */
    const [failedDrives, setFailedDrives] = useState(driveStore.failedDrives);

    useEffect(() => {
        driveStore.fetchQuadsStatus();

        autorun(() => {
            /** @type {Array<Drive>} */
            const failedDrives = [].concat(driveStore.failedDrives);

            if (driveReplaceStore.affectedPools.pools) {
                driveReplaceStore.affectedPools.pools.forEach(pool => {
                    /** @type {Array<AffectPoolDrive>} */
                    const affectedPoolsDrives = pool.drives;

                    affectedPoolsDrives.forEach(({ drive, status, type }) => {
                        if (!FAILED_DRIVE_STATUS.includes(status)) {
                            return;
                        }

                        const isFailedDrive = failedDrives.some(
                            failedDrive => failedDrive.drive === drive.drive && failedDrive.quad === drive.quad
                        );
                        if (isFailedDrive) {
                            return;
                        }

                        /** @type {Drive} */
                        const newFailedDrive = {
                            drive: drive.drive,
                            quad: drive.quad,
                            status,
                            type
                        };

                        failedDrives.push(newFailedDrive);
                    });
                });
            }

            setFailedDrives(failedDrives);
        });
    }, []);

    return { failedDrives, isFailedDrive: Boolean(failedDrives.length) };
};
