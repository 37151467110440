export const ISCSI_ADVANCED = "ISCSIAdvancedCard";
export const ISCSI_TARGETS = "ISCSItargetCard";
export const ISCSI_SESSION = "ISCSISessionCard";
export const TIME_SETTINGS_CARD = "TimeSettingsCard";
export const POOLS_CARD = "Pools";
export const AGGREGATED_INTERFACES_CARD = "AggregatedInterfacesCard";
export const DNS_CARD = "DnsCard";
export const ETHERNET_PORTS_CARD = "EthernetPortsCard";
export const NETWORK_SERVICE_ADVERTISING = "NetworkServiceAdvertising";
export const PING_TEST_CARD = "PingTestCard";
export const VIRTUAL_SWITCH_CARD = "VirtualSwitchCard";
export const BACKUPS_TABLE_CARD = "BackupsTableCard";
export const HELP_DOCS_CARD = "HelpDocsCard";
export const INSTALLED_COMPONENTS_TABLE_CARD = "InstalledComponentsTableCard";
export const INSTALLED_PLUGINS_TABLE_CARD = "InstalledPluginsTableCard";
export const LOOPBACK_TEST_CARD = "LoopbackTestCard";
export const NAS_DISK_TEST_CARD = "NasDiskTestCard";
export const PUSH_DIAGNOSTICS = "PushDiagnostics";
export const UPGRADE_SYSTEM = "UpgradeSystem";
export const PARTITION_TABLE = "PartitionTable";
export const LOGICAL_DISKS = "LogicalDisks";
export const EXPANSION_QUAD_CARD = "ExpansionQuadCard";
export const IFACES_CARD = "IfacesCard";
export const ACTIVATED_KEYS_CARD = "ActivatedKeysCard";
export const ACTIVATION_KEYS_CARD = "ActivationKeysCard";
export const POOL_SHARE = "PoolShare";
export const SHARES_CURRENT_SESSION = "SharesCurrentSession";
export const SERVER_CERTIFICATE_CARD = "ServerCertificateCard";
export const WEB_ACCESS_CREDENTIALS_CARD = "WebAccessCredentialsCard";
export const SECONDARY_ADMINS_CARD = "SecondaryAdminsCard";
export const RESTRICT_ACCESS_CARD = "RestrictAccessCard";
export const CONTACT_SUPPORT = "ContactSupport";
export const SYSTEM_LOGS = "SystemLogs";
export const ADMIN_NETWORKING_SETTINGS_CARD = "AdminNetworkingSettingsCard";
export const NETWORKING_SETTINGS_CARD = "NetworkingSettingsCard";
export const ADMIN_DISPLAY_LANGUAGE_CARD = "AdminDisplayLanguageCard";
export const REMOTE_SHARE = "RemoteShare";
export const CPU_GRAPH_CARD = "CpuGraphCard";
export const CPU_DETAILED_GRAPH_CARD = "CpuDetailedGraphCard";
export const THROUGHPUT_GRAPH_CARD = "ThroughputGraphCard";
export const STORAGE_USAGE_GRAPH_CARD = "StorageUsageGraphCard";
export const NETWORK_GRAPH_CARD = "NetworkGraphCard";
export const ALERTS_CARD = "AlertsCard";
export const UPS_CONF = "UpsConf";
export const POWER = "Power";
export const EMAIL_CONFIGURATION_CARD = "EmailConfigurationCard";
export const MANAGE_EVENTS_CARD = "ManageEventsCard";
export const SNMP_SERVICE_CONFIGURATION_CARD = "SnmpServiceConfigurationCard";
export const LOGGING_LEVEL = "LoggingLevel";
export const CPU_CARD = "CPUCard";
export const RAID_CONTROLLERS_CARD = "RAIDControllersCard";
export const SYSTEM_MEMORY_CARD = "SystemMemoryCard";
export const SENSORS_CARD = "SensorsCard";
export const ETHERNET_CARD = "EthernetCard";
export const PHYSICAL_DISKS_CARD = "PhysicalDisksCard";
export const AGENT_CONFIGURATION_CARD = "AgentConfigurationCard";
export const AUDIT_SETTINGS_CARD = "AuditSettingsCard";
export const MANAGE_INFORMATION_BASE_CARD = "ManagementInformationBaseCard";
export const SLINGSHOT_INDEXER_CARD = "Indexer";
export const SLINGSHOT_REPLICATIONS_CARD = "Replications";
export const SLINGSHOT_EVO_ENVIRONMENT_CARD = "EvoEnvironment";
export const SLINGSHOT_PROXY_DESTINATION_CARD = "ProxyDestination";
export const SLINGSHOT_PROXY_SETTINGS_CARD = "IndexerProxySettings";
export const SLINGSHOT_PROXY_GENERATION_CARD = "ProxyGeneration";
export const SLINGSHOT_PROXY_PAUSE_CARD = "ProxyPause";
export const SLINGSHOT_AUTOMATIONS_CARD = "Automations";
export const SLINGSHOT_ALIAS_CARD = "Aliases";
export const SLINGSHOT_PRESET_CARD = "Presets";
export const SLINGSHOT_WATERMARKS_CARD = "Watermarks";
export const SLINGSHOT_AUTOTAGGING_MACHINE_CARD = "AutotaggingMachineCard";
export const SLINGSHOT_REPLICATION_LOGS_CARD = "ReplicationLogsCard";
export const NAS_CONFIGURATION_CARD = "NasConfigurationCard";
export const NAS_RECYCLE_BIN_CARD = "NasRecycleBinCard";
export const DISK_USAGE_THRESHOLD_CARD = "DiskUsageThresholdCard";
export const ADVANCED_MODE_FIREWALL = "AdvancedModeCard";
export const WIREGUARD_CARD = "WireguardCard";
export const MISSING_QUADS_CARD = "MissingQuadsCard";
export const GRAPHS_SETTINGS_CARD = "GraphsSettingsCard";
export const AUDIO_ALARM_MANAGE_CARD = "AudioAlarmManagerCard";
export const SCHEDULED_BACKUP_CARD = "ScheduledBackupCard";
export const DEFERRED_PARITY_CALCULATION_CARD = "DeferredParityCalculationCard";
export const LOCAL_BRICKS_CARD = "LocalBricksCard";
export const PEERS_CARD = "PeersCard";
export const VOLUMES_CARD = "VolumesCard";
export const SHARE_BROWSER_CARD = "ShareBrowserCard";
export const FILE_NAVIGATOR_LOG_CARD = "FileNavigatorLogCard";
export const SYSTEM_DRIVE_STATUS = "SystemDriveStatus";
export const LONG_LIVED_TOKENS_CARD = "LongLivedTokensCard";
