import { types } from "mobx-state-tree";

import DriveId from "api/drive_replace/Types/DriveId";
import AffectedPool from "api/drive_replace/Types/AffectedPool";

const AffectedPools = types.model({
    freeDrives: types.array(DriveId),
    pools: types.array(AffectedPool)
});

export default AffectedPools;
