import React from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, observer } from "mobx-react";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import MoreMenu from "components/MoreMenu";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import SetupCPULimitDialog from "../SetupCPULimitDialog/SetupCPULimitDialog";
import { SLINGSHOT_PROXY_SETTINGS_CARD } from "const/widgetNameConst";
import { useStore } from "hooks/useStore";
import { useLauncher } from "hooks/useLauncher";

const CPU_LIMIT_CARD_ID_PREFIX = `cpu_limit_card`;

const CPULimitCard = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${CPU_LIMIT_CARD_ID_PREFIX}` : CPU_LIMIT_CARD_ID_PREFIX;
    const { t } = useTranslation();

    const {
        store: { indexingConfigStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isLoading: false,
        beginLoading() {
            this.isLoading = true;
        },
        endLoading() {
            this.isLoading = false;
        },
        isProxySettingsDialogOpen: false,
        isWarnningDeletionDialogIsOpen: false,
        get previewPresetCpuLimit() {
            return `${indexingConfigStore.proxySettingsStore?.data?.previewProxySettings?.cpuLimitPercent || 0}%`;
        },
        get editPresetCpuLimit() {
            return `${indexingConfigStore.proxySettingsStore?.data?.editProxySettings?.cpuLimitPercent || 0}%`;
        },
    }));

    const { isError } = useLauncher([state.beginLoading, indexingConfigStore.fetchProxySettings, state.endLoading], {
        ignoreErrors: false,
        dependencies: [indexingConfigStore.isErrorConnectedDBus],
    });

    const closeProxySettingsDialog = () => {
        state.isProxySettingsDialogOpen = false;
    };

    const openProxySettingsDialog = () => {
        state.isProxySettingsDialogOpen = true;
    };

    return (
        <>
            <SetupCPULimitDialog open={state.isProxySettingsDialogOpen} onClose={closeProxySettingsDialog} parentId={idPrefix} />
            <DataReadonlyCard
                headerDivider
                title={t("slingshot.index_proxy.cpu_limit_card.title")}
                onClick={openProxySettingsDialog}
                disabled={isError}
                control={
                    <MoreMenu>
                        <HomeCardActionMenuItem
                            widget={SLINGSHOT_PROXY_SETTINGS_CARD}
                            homeCardActionMenuItemId={`${idPrefix}_add_to_home`}
                        />
                    </MoreMenu>
                }
            >
                {!state.isLoading ? (
                    <Grid container direction="column" spacing={6} wrap="nowrap">
                        <Grid item container spacing={6}>
                            <Grid item container wrap="nowrap" alignItems="center">
                                <Grid item xs={6}>
                                    <Typography>{t("slingshot.index_proxy.preview_settings.label.cpu_usage")}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">{state.previewPresetCpuLimit}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container wrap="nowrap" alignItems="center">
                                <Grid item xs={6}>
                                    <Typography>{t("slingshot.index_proxy.edit_settings.label.cpu_usage")}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">{state.editPresetCpuLimit}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder inProgress={state.isLoading} />
                )}
            </DataReadonlyCard>
        </>
    );
};

export default observer(CPULimitCard);
