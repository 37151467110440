import React from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import IndexerNameCard from "../IndexerNameCard";
import MultipleEvoEnvironmentCard from "../MultipleEvoEnvironmentCard";
import ProxyDestinationCard from "../ProxyDestinationCard";
import IndexPauseCard from "../IndexPauseCard";
import PreviewProxySettingsCard from "../PreviewProxySettingsCard";
import ProxyGenerationCard from "../ProxyGenerationCard";
import DefaultExtensionExclusionsCard from "../DefaultExtensionExclusionsCard";
import CPULimitCard from "../CPULimitCard/CPULimitCard";

const INDEX_TAB_CONTENT_ID_PREFIX = `index_tab_content`;

const IndexTabContent = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${INDEX_TAB_CONTENT_ID_PREFIX}` : INDEX_TAB_CONTENT_ID_PREFIX;
    return (
        <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
                <IndexerNameCard parentId={idPrefix} />
            </Grid>
            <Grid item xs={12} md={6}>
                <MultipleEvoEnvironmentCard parentId={idPrefix} />
            </Grid>
            <Grid item xs={12} md={6}>
                <ProxyDestinationCard parentId={idPrefix} />
            </Grid>
            <Grid item xs={12} md={6}>
                <PreviewProxySettingsCard parentId={idPrefix} />
            </Grid>
            <Grid item xs={12} md={6}>
                <CPULimitCard parentId={idPrefix} />
            </Grid>
            <Grid item xs={12}>
                <ProxyGenerationCard parentId={idPrefix} />
            </Grid>
            <Grid item xs={12} md={6}>
                <IndexPauseCard parentId={idPrefix} />
            </Grid>
            <Grid item xs={12} md={6}>
                <DefaultExtensionExclusionsCard parentId={idPrefix} />
            </Grid>
        </Grid>
    );
};

export default observer(IndexTabContent);
