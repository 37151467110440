import { types } from "mobx-state-tree";

import AvailableQuadActivations from "api/activation/quad/Types/AvailableQuadActivations";

const GetActivationsCountResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(AvailableQuadActivations)
});

export default GetActivationsCountResult;
