import { types } from "mobx-state-tree";

import UserRootAccess from "api/security/acl/Types/UserRootAccess";

const UserRootAccessPage = types.model({
    data: types.array(UserRootAccess),
    total: types.number
});

export default UserRootAccessPage;
