import Request from "api/Request";

const InstallPlugins = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Plugins",
    method: "install_plugin"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default InstallPlugins;
