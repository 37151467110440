import { types } from "mobx-state-tree";

import BaseBrick from "api/cluster/Types/BaseBrick";

const VolumeReplaceBricksArguments = types.model({
    volume: types.string,
    fromBrick: types.maybe(BaseBrick),
    toBrick: types.maybe(BaseBrick)
});

export default VolumeReplaceBricksArguments;
