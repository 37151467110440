import { types } from "mobx-state-tree";

import DomainObjectId from "api/security/Types/DomainObjectId";

const UserRootAccess = types.model({
    user: types.maybe(DomainObjectId),
    access: types.string
});

export default UserRootAccess;
