import { types } from "mobx-state-tree";

import ShareLocationInfo from "api/share/Types/ShareLocationInfo";
import ShareAttributes from "api/share/Types/ShareAttributes";

const ShareEditInfo = types.model({
    location: types.maybe(ShareLocationInfo),
    attributes: types.maybe(ShareAttributes),
    masks: types.array(types.string)
});

export default ShareEditInfo;
