import React, { useContext } from "react";
import { useStyles } from "./Power.styles";
import DataCard from "components/DataCard";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { POWER } from "const/widgetNameConst";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import OffIcon from "assets/OffIcon";
import { Context } from "store";
import { useObserver } from "mobx-react-lite";
import RebootIcon from "assets/RebootIcon";
import { useModal } from "hooks/useModal";
import Dialog from "components/Dialog";

const Power = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { powerAndUpsStore },
    } = useContext(Context);

    const rebootDialog = useModal();
    const shutdownDialog = useModal();

    const reboot = async () => {
        const res = await powerAndUpsStore.reboot();
        return res;
    };

    const shutdown = async () => {
        const res = await powerAndUpsStore.shutdown();
        return res;
    };

    return useObserver(() => (
        <DataCard
            headerDivider
            autoHeight
            title={t("power_and_ups.power.title")}
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={POWER} />
                </MoreMenu>
            }
        >
            <Dialog
                title={t("common.dialog_title.warning")}
                open={rebootDialog.isOpen}
                onClose={rebootDialog.close}
                onSubmit={reboot}
                submitBtnLabel={t("common.button.proceed")}
                withoutIconClose
                withoutDividers
                disableClosingAfterStartLoading
                closeBtnLabel={t("common.button.cancel")}
            >
                {t("power_and_ups.power.dialog_reboot")}
            </Dialog>

            <Dialog
                title={t("common.dialog_title.warning")}
                open={shutdownDialog.isOpen}
                onClose={shutdownDialog.close}
                onSubmit={shutdown}
                submitBtnLabel={t("common.button.proceed")}
                withoutIconClose
                withoutDividers
                disableClosingAfterStartLoading
                closeBtnLabel={t("common.button.cancel")}
            >
                {t("power_and_ups.power.dialog_shutdown")}
            </Dialog>

            <Grid className={classes.alertContainer} spacing={4} direction={"column"} container>
                <Grid item>
                    <Typography>{t("power_and_ups.power.first_alert")}</Typography>
                </Grid>
                <Grid item>
                    <Typography>{t("power_and_ups.power.second_alert")}</Typography>
                </Grid>
            </Grid>
            <Grid spacing={2} container>
                <Grid item>
                    <Button variant={"contained"} color={"secondary"} startIcon={<RebootIcon />} onClick={rebootDialog.open}>
                        {t("common.button.reboot")}
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant={"contained"} color={"secondary"} startIcon={<OffIcon />} onClick={shutdownDialog.open}>
                        {t("power_and_ups.power.button.shutdown")}
                    </Button>
                </Grid>
            </Grid>
        </DataCard>
    ));
};

export default Power;
