import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import NetSysctlSettingsResult from "api/net/Responses/NetSysctlSettingsResult";
import GetNetSysctlSettings from "api/net/Requests/GetNetSysctlSettings";
import ResetNetSysctlSettings from "api/net/Requests/ResetNetSysctlSettings";
import SetNetSysctlSettings from "api/net/Requests/SetNetSysctlSettings";

const NetworkingSettingsStore = types
    .model({
        networkingSettingsStore: types.maybe(NetSysctlSettingsResult)
    })
    .views(self => ({
        get tcpScaling() {
            return self.networkingSettingsStore?.data?.tcpScaling || null;
        },
    }))
    .actions(self => ({
        fetchNetworkingSettings: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetNetSysctlSettings.create().init();
                const res = yield Socket.send(req);
                self.networkingSettingsStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setNetworkingSettings: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetNetSysctlSettings.create().init(data);
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        resetNetworkingSettings: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ResetNetSysctlSettings.create().init();
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
                return false;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        changeTcpScaling: (netSysctlSettingsResult) => {
            if (self.networkingSettingsStore?.data?.tcpScaling) {
                self.networkingSettingsStore.data.tcpScaling = netSysctlSettingsResult.tcpScaling;
            }
        },
    }));

export default NetworkingSettingsStore;
