import { types } from "mobx-state-tree";

import GetBricksResultData from "api/cluster/Types/GetBricksResultData";

const GetBricksResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(GetBricksResultData)
});

export default GetBricksResult;
