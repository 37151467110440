const defaultSwitchPriorityPriority = 32768;
const defaultForwardDelayPriority = 15;
const defaultHelloTimePriority = 2;
const defaultMaxAgePriority = 20;

const createFields = (storeInterface, store) => {
    return [
        {
            name: "ip",
            default: storeInterface.ip,
            disabled: store.isDhcpClientRole,
            rules: "required",
        },
        {
            name: "netmask",
            default: storeInterface.netmask,
            disabled: store.isDhcpClientRole,
            rules: "required",
        },
        {
            name: "gateway",
            default: storeInterface.gateway,
            disabled: store.isDhcpClientRole,
        },
        {
            name: "mtu",
            default: storeInterface.mtu,
            rules: "required",
        },
        {
            name: "startDhcpRange",
            default: storeInterface.dhcpServer.startDhcpRange,
            rules: ["required", "isIpv4"],
            disabled: !store.isDhcpServerRole,
        },
        {
            name: "endDhcpRange",
            default: storeInterface.dhcpServer.endDhcpRange,
            rules: ["required", "isIpv4"],
            disabled: !store.isDhcpServerRole,
        },

        {
            name: "overrideDns",
            default: !!storeInterface.dhcpClient.overrideDns,
            type: "checkbox",
        },
        {
            name: "flowcontrolEnforced",
            default: !!storeInterface.flowcontrolEnforced,
            type: "checkbox",
        },
        {
            name: "currentRole",
            default: store.roleOfCurrentPort,
        },
        {
            name: "stpEnabled",
            default: !!storeInterface.stp?.enabled,
            type: "checkbox",
        },
        {
            name: "switchPriority",
            default: storeInterface.stp?.switchPriority ?? defaultSwitchPriorityPriority,
            rules: "isSwitchPriority",
        },
        {
            name: "forwardDelay",
            default: storeInterface.stp?.forwardDelay ?? defaultForwardDelayPriority,
            rules: "isForwardDelay",
        },
        {
            name: "helloTime",
            default: storeInterface.stp?.helloTime ?? defaultHelloTimePriority,
            rules: "isHelloTime",
        },
        {
            name: "maxAge",
            default: storeInterface.stp?.maxAge ?? defaultMaxAgePriority,
            rules: "isMaxAge",
        },
    ];
};

export default createFields;
