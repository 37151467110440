import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { isAlive } from "mobx-state-tree";
import { SizeMe } from "react-sizeme";
import { DEFAULT_START_PERIOD, DEFAULT_ANIM_DURATION } from "const/chartConst";
import NetworkGraph from "./NetworkGraph";
import useStoreByIp from "hooks/useStoreByIp";

const NetworkWidget = ({ parentId, height = 150, animDuration = DEFAULT_ANIM_DURATION, isDark, iface, ip }) => {
    const { networkGraphStore, uiStore } = useStoreByIp({ ip });
    const state = useLocalStore(() => ({
        key: ""
    }));

    useEffect(() => {
        if (!iface || !iface.port || iface.status === "offline") return;
        networkGraphStore.registerGraph();
        networkGraphStore.fetchData(iface.port);
        return () => {
            if (!isAlive(networkGraphStore)) return;
            networkGraphStore.unregisterGraph();
            networkGraphStore.stopFetching();
        };
    }, [iface]);

    return useObserver(() => {
        if (!iface || networkGraphStore.chartInfo.length === 0) return null;
        const graphStartPeriod = uiStore.parameters?.graphStartPeriod || DEFAULT_START_PERIOD;

        return (
            <SizeMe refreshRate={500}>
                {({ size }) => {
                    if (size.width) state.key = `${parentId}-${size.width}-${(iface || {}).port}-${graphStartPeriod}`;
                    const uniqueParentId = ip
                        ? parentId +
                          String(ip)
                              .split(".")
                              .join("")
                        : parentId;
                    return (
                        <NetworkGraph
                            {...{
                                key: state.key,
                                parentId: uniqueParentId,
                                height,
                                animDuration,
                                isDark,
                                iface,
                                chartInfo: networkGraphStore.chartInfo,
                                startFetching: networkGraphStore.fetchData,
                                stopFetching: networkGraphStore.stopFetching,
                                lastTime: networkGraphStore.lastTime,
                                lastInValue: networkGraphStore.lastInValue,
                                lastOutValue: networkGraphStore.lastOutValue,
                                graphStartPeriod
                            }}
                        />
                    );
                }}
            </SizeMe>
        );
    });
};

export default NetworkWidget;
