import React, { useContext, useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Context } from "store";
import MainTemplate from "components/MainTemplate";
import HeaderTitle from "components/HeaderTitle";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import BrowserIcon from "assets/BrowserIcon";
import DatabaseLocationCard from "components/DatabaseLocationCard";
import { SHAREBROWSER } from "const/shortcutNameConst";
import SetupShareBrowserSettingsDialog from "./components/SetupShareBrowserSettingsDialog";
import BackupSettingsWarningCard from "components/BackupSettingsWarningCard";
import ShareBrowserSettings from "./components/ShareBrowserSettings/ShareBrowserSettings";
import { useModal } from "hooks/useModal";

const ShareBrowser = () => {
    const { t } = useTranslation();

    const {
        store: { postgresStore, sharebrowserStore, backupSettingsStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isDatabaseLoaded: false
    }));

    const setupShareBrowserSettingsModal = useModal();

    useEffect(() => {
        (async () => {
            await backupSettingsStore.fetchBackup();
            await postgresStore.getDatabaseLocation();
            sharebrowserStore.getSharebrowserServerVersion();
            sharebrowserStore.getSharebrowserServerStatus();
            state.isDatabaseLoaded = true;
        })();
    }, []);

    return useObserver(() => (
        <MainTemplate
            headerTitle={<HeaderTitle icon={<BrowserIcon />} title={t("sharebrowser_page.title")} shortcutName={SHAREBROWSER} />}
        >
            <Grid container spacing={6}>
                {state.isDatabaseLoaded && !backupSettingsStore.backup?.location?.share && (
                    <Grid item xs={6}>
                        <BackupSettingsWarningCard />
                    </Grid>
                )}
                {state.isDatabaseLoaded && !postgresStore.databaseLocation?.share && backupSettingsStore.backup?.location?.share && (
                    <Grid item xs={6}>
                        <DatabaseLocationCard />
                    </Grid>
                )}
                <Grid item xs={6}>
                    {state.isDatabaseLoaded &&
                        backupSettingsStore.backup?.location?.share &&
                        postgresStore.databaseLocation?.share && (
                            <ShareBrowserSettings openSettings={setupShareBrowserSettingsModal.open} />
                        )}
                    <SetupShareBrowserSettingsDialog
                        open={setupShareBrowserSettingsModal.isOpen}
                        onClose={setupShareBrowserSettingsModal.close}
                    />
                </Grid>
            </Grid>
        </MainTemplate>
    ));
};

export default ShareBrowser;
