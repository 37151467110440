import React, { useContext, useEffect } from "react";
import { Grid, Typography, Box, CircularProgress } from "@material-ui/core";
import UpsIcon from "assets/UpsIcon";
import { useStyles } from "./UpsBar.style";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";

const UpsBar = () => {
    const {
        store: { powerAndUpsStore }
    } = useContext(Context);
    const { t } = useTranslation();
    useEffect(() => {
        powerAndUpsStore.getPowerStatus();
    }, []);
    const state = useLocalStore(() => ({
        get isFound() {
            switch (powerAndUpsStore.powerSupply) {
                case "offline":
                    return false;
                case "ac":
                    return true;
                case "dc":
                    return true;
                default:
                    return false;
            }
        }
    }));

    const upsIcon = () => {
        if ((state.isFound && powerAndUpsStore.chargeLevelPercent < 100) || powerAndUpsStore.powerSupply === "dc") {
            return (
                <Typography color={"textSecondary"} className={classes.progressText}>
                    {Math.floor(powerAndUpsStore.chargeLevelPercent)}%
                </Typography>
            );
        }
        return <UpsIcon />;
    };

    const classes = useStyles({ isFound: state.isFound });
    return useObserver(() => (
        <Grid container className={classes.container} spacing={2} alignItems={"center"} justify={"center"} wrap={"nowrap"}>
            <Grid item>
                <Box className={classes.circularBase}>
                    {upsIcon()}
                    <CircularProgress
                        variant={"determinate"}
                        value={powerAndUpsStore.chargeLevelPercent}
                        thickness={4}
                        size={48}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Typography className={classes.title} color={"textSecondary"}>
                    {t("header.ups")}
                </Typography>
                <Typography className={classes.statusText}>
                    {state.isFound ? t("header.ups.found") : t("header.ups.not_found")}
                </Typography>
            </Grid>
        </Grid>
    ));
};

export default UpsBar;
