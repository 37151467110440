import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    fileName: {
        maxWidth: 210,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        direction: "rtl"
    }
}));
