import { types } from "mobx-state-tree";

const SOURCE_ARRAY = ["upload", "restore_location", "upg_inbox"];

const ImportDatabaseArguments = types.model({
    source: types.enumeration(SOURCE_ARRAY),
    fileName: types.string
});

export default ImportDatabaseArguments;
