import React from "react";
import { useObserver } from "mobx-react";
import { useStyles } from "./StatusDot.styles";
import { Grid } from "@material-ui/core";

const StatusDot = ({ status, large }) => {
    const classes = useStyles({ status, large });

    return useObserver(() => <Grid item className={classes.statusDot}></Grid>);
};

export default StatusDot;
