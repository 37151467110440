import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography, Link } from "@material-ui/core";
import { useStyles } from "./Footer.styles.js";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { URL_EULA, URL_LOGIN } from "routes";
import SupportButton from "components/SupportButton/SupportButton.js";

const Footer = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();

    const isLoginPage = location?.pathname === URL_LOGIN;
    const hasSupportQuery = location.search === "?support=true";

    return useObserver(() => (
        <Grid
            container
            wrap={"nowrap"}
            className={classes.footer}
            justify={!isLoginPage ? "space-between" : "flex-end"}
            alignItems={"center"}
        >
            {!isLoginPage && (
                <Grid item>
                    <Typography className={classes.font} noWrap>
                        <Link to={URL_EULA} target="_blank" rel="noopener" component={RouterLink}>
                            {t("footer.license")}
                        </Link>
                    </Typography>
                </Grid>
            )}
            {isLoginPage && hasSupportQuery && <SupportButton isLoginPage={isLoginPage} />}
            <Grid item>
                <Typography className={classes.font} noWrap>
                    {t("footer.copyright")}
                </Typography>
            </Grid>
        </Grid>
    ));
};

export default Footer;
