import { types } from "mobx-state-tree";

import Request from "api/Request";
import RestrictUiSettings from "api/webserver/Types/RestrictUiSettings";

const SetRestrictUiSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Webserver",
    method: "set_restrict_ui_settings",
    arguments: types.maybe(RestrictUiSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetRestrictUiSettings;
