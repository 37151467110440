import React, { useContext } from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import createForm from "utils/createForm";
import createFields from "./createFields";
import { Context } from "store";
import Switch from "components/MobxForm/Switch";
import RowTextInput from "components/RowTextInput";
import { useLocalStore, useObserver } from "mobx-react-lite";
import TextInput from "components/MobxForm/TextInput";
import hostNameRule from "utils/hostNameRule";
import portRule from "utils/portRule";
import { SNMP_DEFAULT_PORT } from "const";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import { useModal } from "hooks/useModal";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const MANAGE_EVENTS_DIALOG_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_manage_events_dialog`;

const ManageEventsDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const {
        store: { snmpStore, alertsStore }
    } = useContext(Context);
    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(snmpStore.snmpSettings),
            rules: { ...hostNameRule, ...portRule },
            validateDisabledFields: false
        }),
        isSubmitted: false
    }));

    const unsavedChangesModal = useModal();

    const resetForm = () => {
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.form.$("snmpEnabled").set("default", snmpStore.snmpSettings.enabled);
        state.form.$("host").set("default", snmpStore.snmpSettings.snmpHost);
        state.form.$("port").set("default", snmpStore.snmpSettings.snmpPort);

        state.form.$("snmpEnabled").observe(({ field }) => {
            if (field.checked) {
                state.form.$("host").set("disabled", false);
                state.form.$("port").set("disabled", false);
            } else {
                state.form.$("host").set("disabled", true);
                state.form.$("port").set("disabled", true);
                state.form.$("host").resetValidation();
                state.form.$("port").resetValidation();
            }
        });
        state.form.reset();
    };

    const onSubmit = async e => {
        state.form.onSubmit(e);
        if (!state.form.isValid) {
            return;
        }
        const res = await snmpStore.setSnmpSettings({
            enabled: state.form.$("snmpEnabled").value,
            snmpHost: state.form.$("host").value,
            snmpPort: state.form.$("port").value === "" ? SNMP_DEFAULT_PORT : +state.form.$("port").value
        });

        if (res) {
            resetForm();
            alertsStore.fetchNotifications();
            state.isSubmitted = true;
        }
        return res;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            onClose={closeIfNeeded}
            onEnter={resetForm}
            open={open}
            title={t("alerts.manage_events_dialog.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${MANAGE_EVENTS_DIALOG_ID_PREFIX}_save`}
            onSubmit={onSubmit}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary" id={`${MANAGE_EVENTS_DIALOG_ID_PREFIX}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <RowControlLabelSwitch
                control={<Switch field={state.form.$("snmpEnabled")} id={`${MANAGE_EVENTS_DIALOG_ID_PREFIX}_alerts`} />}
                label={t("alerts.manage_events_card.alerts")}
            />
            <RowTextInput
                label={t("alerts.manage_events_card.host")}
                control={<TextInput field={state.form.$("host")} id={`${MANAGE_EVENTS_DIALOG_ID_PREFIX}_host`} />}
            />
            <RowTextInput
                label={t("alerts.manage_events_card.port")}
                control={<TextInput field={state.form.$("port")} id={`${MANAGE_EVENTS_DIALOG_ID_PREFIX}_port`} />}
            />
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default ManageEventsDialog;
