import { useStore } from "hooks/useStore";
import { useObserver } from "mobx-react";
import { useEffect } from "react";
import { autorun } from "mobx";

/**
 *
 * @returns {[]}
 */
export const usePluginList = () => {
    const {
        store: { evoSettingsStore, bootProgressStore },
    } = useStore();

    useEffect(() => {
        autorun(() => {
            if (bootProgressStore.isRunning) {
                evoSettingsStore.restFetchSystemInfo();
            }
        });
    }, []);

    return useObserver(() => evoSettingsStore.evoPluginList);
};
