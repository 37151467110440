import { types } from "mobx-state-tree";

const ConfigType = types.model({
    json: types.model({
        access_code: types.maybe(types.string),
        defaults: types.model({
            confidence: types.maybe(types.number),
            limit: types.maybe(types.number),
            s3_alias: types.maybe(types.string),
            transcoding: types.maybe(types.boolean),
            transcoding_folder: types.maybe(types.string),
            transcoding_folder_expiration: types.maybe(types.number),
            transcoding_preset: types.maybe(types.number)
        }),
        service_endpoint: types.model({
            region: types.maybe(types.string),
            metadata: types.maybe(types.string),
            password: types.maybe(types.string),
            user: types.maybe(types.string)
        })
    })
});

export default ConfigType;
