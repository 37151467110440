import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    quadsContainer: {
        display: "flex",
        flex: 1,
        width: "100%",
        margin: 0
    },
    enclosureContainer: {
        width: "100%",
        margin: 0,
        height: "100%"
    }
}));
