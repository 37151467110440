import { types } from "mobx-state-tree";

import SystemComponentServiceState from "api/system_components/Types/SystemComponentServiceState";

const GetSystemComponentsServicesStateResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(SystemComponentServiceState)
});

export default GetSystemComponentsServicesStateResult;
