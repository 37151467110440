import { types } from "mobx-state-tree";

import Request from "api/Request";
import UpsSettings from "api/power/Types/UpsSettings";

const SetUpsSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Power",
    method: "set_ups_settings",
    arguments: types.maybe(UpsSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetUpsSettings;
