import React from "react";
import { useLocalStore, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import DataCard from "components/DataCard";
import {
    Grid,
    Typography,
    Box,
    ButtonBase,
    Table,
    TableCell,
    TableBody,
    TableRow,
    TableHead,
    Divider,
    FormControlLabel,
} from "@material-ui/core";
import Switch from "components/Switch/Switch";
import PlusIcon from "assets/PlusIcon";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import TableSortLabel from "components/TableSortLabel";

import TablePagination from "components/TablePagination";
import {
    REPLICATION_JOBS_JOB,
    REPLICATION_JOBS_STATUS,
    REPLICATION_JOBS_SOURCE,
    REPLICATION_JOBS_DESTINATION,
    REPLICATION_JOBS_LAST_JOB,
    REPLICATION_JOBS_SCHEDULE,
} from "const/sortColumnConst";
import { useStyles } from "./Replications.styles";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { SLINGSHOT_REPLICATIONS_CARD } from "const/widgetNameConst";
import { ADMIN } from "const/userRolesConst";
import ReplicationRow from "./components/ReplicationRow";
import { useStore } from "hooks/useStore";
import { useLauncher } from "hooks/useLauncher";
import { useCreateJobModal } from "hooks/modals";
import ReplicationJobsDrawer from "../ReplicationJobsDrawer";
import CreateJobModal from "../CreateJobModal";
import ConfirmationDialog from "components/ConfirmationDialog";

const REPLICATIONS_ID_PREFIX = `replications`;

const Replications = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${REPLICATIONS_ID_PREFIX}` : REPLICATIONS_ID_PREFIX;
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { syncJobsStore, authStore, emailConfigurationStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isJobsLoaded: false,
        isOpenWarning: false,
        enableJobsLoaded() {
            this.isJobsLoaded = true;
        },
        disabledJobsLoaded() {
            this.isJobsLoaded = false;
        },
        openWarning() {
            this.isOpenWarning = true;
        },
        closeWarning() {
            this.isOpenWarning = false;
        },
    }));

    const createJobModal = useCreateJobModal();

    const { isError } = useLauncher(
        [
            syncJobsStore.fetchJobs,
            state.enableJobsLoaded,
            syncJobsStore.getServiceState,
            emailConfigurationStore.getEmailSettings,
        ],
        {
            ignoreErrors: false,
            dependencies: [syncJobsStore.isErrorConnectedDBus],
        }
    );

    const onSwitchChange = (e) => {
        if (!e.target.checked) {
            return state.openWarning();
        }

        syncJobsStore.setServiceState("master_switch", e.target.checked);
    };

    const disableReplicationsAndCloseWarning = () => {
        syncJobsStore.setServiceState("master_switch", false);
        state.closeWarning();
    };

    const handleChangePage = (newPage) => {
        syncJobsStore.updatePage(+newPage);
    };
    const handleChangeRowsPerPage = (e) => {
        syncJobsStore.updateLimit(+e.target.value);
        syncJobsStore.updatePage(0);
    };

    const openDrawerIfNeeded = (id) => () => {
        if (syncJobsStore.currentJobId === id) {
            syncJobsStore.setCurrentJobId(null);
            return;
        }

        syncJobsStore.setCurrentJobId(id);
    };

    const changeSorting = (column) => () => syncJobsStore.changeSorting(column);

    return (
        <>
            <React.Suspense fallback={null}>
                <CreateJobModal parentId={idPrefix} />
                <ReplicationJobsDrawer parentId={idPrefix} />
            </React.Suspense>
            <DataCard
                emptyPadding
                headerDivider
                headerControl={[
                    <MoreMenu key={`data_card-header_control-control-1`} id={`${idPrefix}_more_menu`}>
                        <HomeCardActionMenuItem
                            widget={SLINGSHOT_REPLICATIONS_CARD}
                            homeCardActionMenuItemId={`${idPrefix}_add_to_home`}
                        />
                    </MoreMenu>,
                    !isError ? (
                        <ButtonBase
                            onClick={createJobModal.open}
                            key={`data_card-header_control-control-2`}
                            id={`${idPrefix}_open_create_job_modal`}
                        >
                            <PlusIcon />
                        </ButtonBase>
                    ) : null,
                ]}
                headerContent={
                    <Grid container justify={"flex-start"} alignItems={"center"} wrap={"nowrap"}>
                        <FormControlLabel
                            onChange={onSwitchChange}
                            className={classes.switchLabel}
                            labelPlacement={"start"}
                            disabled={authStore.role !== ADMIN || isError}
                            control={
                                <Switch
                                    checked={isError ? false : !!syncJobsStore.serviceState?.master_switch}
                                    id={`${idPrefix}_open_warning`}
                                />
                            }
                            label={<Typography variant={"h2"}>{t("slingshot_page.tabs.replications.title")}</Typography>}
                        />
                    </Grid>
                }
            >
                {syncJobsStore.jobsCount > 0 ? (
                    <Grid container direction={"column"} justify={"space-between"}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={changeSorting(REPLICATION_JOBS_JOB)}
                                            direction={syncJobsStore.getJobsArguments.sort_dir.toLowerCase()}
                                            active={syncJobsStore.getJobsArguments.sort === REPLICATION_JOBS_JOB}
                                            id={`${idPrefix}_job_sort`}
                                        >
                                            {t("slingshot_page.tabs.replications.table.header.label.job")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={changeSorting(REPLICATION_JOBS_STATUS)}
                                            direction={syncJobsStore.getJobsArguments.sort_dir.toLowerCase()}
                                            active={syncJobsStore.getJobsArguments.sort === REPLICATION_JOBS_STATUS}
                                            id={`${idPrefix}_status_sort`}
                                        >
                                            {t("slingshot_page.tabs.replications.table.header.label.status")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={changeSorting(REPLICATION_JOBS_SOURCE)}
                                            direction={syncJobsStore.getJobsArguments.sort_dir.toLowerCase()}
                                            active={syncJobsStore.getJobsArguments.sort === REPLICATION_JOBS_SOURCE}
                                            id={`${idPrefix}_source_sort`}
                                        >
                                            {t("slingshot_page.tabs.replications.table.header.label.source")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={changeSorting(REPLICATION_JOBS_DESTINATION)}
                                            direction={syncJobsStore.getJobsArguments.sort_dir.toLowerCase()}
                                            active={syncJobsStore.getJobsArguments.sort === REPLICATION_JOBS_DESTINATION}
                                            id={`${idPrefix}_destination_sort`}
                                        >
                                            {t("slingshot_page.tabs.replications.table.header.label.destination")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={changeSorting(REPLICATION_JOBS_SCHEDULE)}
                                            direction={syncJobsStore.getJobsArguments.sort_dir.toLowerCase()}
                                            active={syncJobsStore.getJobsArguments.sort === REPLICATION_JOBS_SCHEDULE}
                                            id={`${idPrefix}_shedule_sort`}
                                        >
                                            {t("slingshot_page.tabs.replications.table.header.label.schedule")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={changeSorting(REPLICATION_JOBS_LAST_JOB)}
                                            direction={syncJobsStore.getJobsArguments.sort_dir.toLowerCase()}
                                            active={syncJobsStore.getJobsArguments.sort === REPLICATION_JOBS_LAST_JOB}
                                            id={`${idPrefix}_last_job_sort`}
                                        >
                                            {t("slingshot_page.tabs.replications.table.header.label.last_job")}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {syncJobsStore?.jobs.map((job) => (
                                    <ReplicationRow
                                        job={job}
                                        key={job.id}
                                        onClick={openDrawerIfNeeded(job.id)}
                                        parentId={`$idPrefix}_${job.name}`}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                        <Grid>
                            <Divider />
                            <Box pl={6}>
                                <TablePagination
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    onChangePage={handleChangePage}
                                    count={syncJobsStore.jobsCount}
                                    rowsPerPage={syncJobsStore.getJobsArguments.limit}
                                    page={syncJobsStore.getJobsArguments.page}
                                    parentId={idPrefix}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder
                        inProgress={!state.isJobsLoaded}
                        message={t("slingshot_page.tabs.replications.table.placeholder")}
                    />
                )}
            </DataCard>
            <ConfirmationDialog
                title={t("common.warning_dialog.title")}
                open={state.isOpenWarning}
                onClose={state.closeWarning}
                onConfirm={disableReplicationsAndCloseWarning}
                confirmLabel={t("common.button.proceed")}
                cancelLabel={t("common.button.cancel")}
                fullWidth
                id={`${idPrefix}_warning_dialog`}
            >
                {t("slingshot.disable_replications_warning.text")}
            </ConfirmationDialog>
        </>
    );
};

export default observer(Replications);
