import React from "react";

const LockIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
            <path
                fill="#BFD1E1"
                fillRule="evenodd"
                d="M8 0c2.142 0 3.891 1.684 3.995 3.8L12 4v2h1c.552 0 1 .448 1 1v8c0 .552-.448 1-1 1H3c-.552 0-1-.448-1-1V7c0-.552.448-1 1-1h1V4l.005-.2C4.109 1.684 5.858 0 8 0zm0 10c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm0-8c-1.054 0-1.918.816-1.995 1.85L6 4v2h4V4l-.005-.15C9.918 2.817 9.055 2 8 2z"
            />
        </svg>
    );
};

export default LockIcon;
