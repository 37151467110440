import { types } from "mobx-state-tree";

import Request from "api/Request";
import LogSettings from "api/troubleshooting/Types/LogSettings";

const SetLogSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Troubleshooting",
    method: "set_log_settings",
    arguments: types.maybe(LogSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetLogSettings;
