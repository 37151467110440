import { types } from "mobx-state-tree";
import Request from "api/Request";
import DeleteRegistrationType from "../Types/DeleteRegistrationType";

const DeleteRegistration = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/ExtVolumes1",
    method: "delete_registration",
    arguments: types.maybe(
        types.snapshotProcessor(DeleteRegistrationType, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json)
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default DeleteRegistration;
