import React, { useContext, useEffect } from "react";
import { MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import DataCard from "components/DataCard";
import { useStyles } from "./InstalledPluginsTableCard.styles";
import MoreMenu from "components/MoreMenu";
import { useTranslation } from "react-i18next";
import PluginInstallDialog from "../PluginInstallDialog/PluginInstallDialog";
import { useLocalStore, useObserver } from "mobx-react";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import { INSTALLED_PLUGINS_TABLE_CARD } from "const/widgetNameConst";
import { Context } from "store";
import ChevronRightIcon from "assets/ChevronRightIcon";
import TableRowControlButton from "components/TableRowControlButton";
import PluginDrawer from "./components/PluginDrawer";

const InstalledPluginsTableCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const state = useLocalStore(() => ({
        isInstallDialogOpen: false
    }));
    const openInstallDialog = () => {
        state.isInstallDialogOpen = true;
    };
    const closeInstallDialog = () => {
        state.isInstallDialogOpen = false;
    };

    const toggleDrawer = pluginName => () => {
        pluginsStore.setCurrentPluginName(pluginsStore.currentPluginName !== pluginName ? pluginName : null);
    };

    const {
        store: { pluginsStore }
    } = useContext(Context);

    useEffect(() => {
        pluginsStore.fetchPlugins();
    }, []);

    return useObserver(() => (
        <DataCard
            headerDivider
            headerControl={
                <MoreMenu className={classes.headerMoreMenu}>
                    <HomeCardActionMenuItem widget={INSTALLED_PLUGINS_TABLE_CARD} />
                    <MenuItem onClick={openInstallDialog}>{t("support.start.installed_plugins.install_plugin")}</MenuItem>
                </MoreMenu>
            }
            title={t("support.start.installed_plugins.title")}
            emptyPadding
        >
            <PluginInstallDialog handleClose={closeInstallDialog} open={state.isInstallDialogOpen} />
            <TableContainer component={Paper} elevation={0}>
                {pluginsStore.plugins?.length > 0 ? (
                    <>
                        <PluginDrawer />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("support.start.installed_plugins.table.plugin")}</TableCell>
                                    <TableCell>{t("support.start.installed_plugins.table.version")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pluginsStore.plugins?.map(plugin => (
                                    <TableRow key={plugin.name} onClick={toggleDrawer(plugin.name)}>
                                        <TableCell>{plugin.name}</TableCell>
                                        <TableCell>{plugin.pluginVersion}</TableCell>
                                        <TableCell align={"right"}>
                                            <TableRowControlButton icon={<ChevronRightIcon />} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                ) : (
                    <EmptyCardPlaceholder message={t("support.start.installed_plugins.table.no_plugins")} />
                )}
            </TableContainer>
        </DataCard>
    ));
};

export default InstalledPluginsTableCard;
