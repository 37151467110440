import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import ConfirmationDialog from "components/ConfirmationDialog";
import createFields from "./createFields";
import { useLocalStore } from "mobx-react";
import createForm from "utils/createForm";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react-lite";
import Switch from "components/MobxForm/Switch";
import RowTextInput from "components/RowTextInput";
import Dialog from "components/Dialog";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";

const SetupNetworkServiceAdvertisingDialog = ({ closeDialog, open }) => {
    const {
        store: { netServiceAdvertisingStore, evoSettingsStore },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields() }),
        isAlertOpen: false,
        isSubmitted: false,
        inProgress: false,
    }));
    const unsavedChangesModal = useModal();
    const { t } = useTranslation();

    const updateForm = () => {
        state.form.$("hostname").set("default", netServiceAdvertisingStore.hostname);
        state.form.$("winsEnabled").set("default", netServiceAdvertisingStore.winsEnabled);
        state.form.$("avahiEnabled").set("default", netServiceAdvertisingStore.avahiEnabled);
        state.form.$("description").set("default", evoSettingsStore.evoDescription);
        state.form.reset();
        unsavedChangesModal.close();
    };
    const close = () => {
        closeAlert();
        closeDialog();
        state.isSubmitted = false;
    };
    const onSubmit = (autoClose) => async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        state.inProgress = true;
        const netRes = !!(await netServiceAdvertisingStore.setNetServiceAdvertising({
            hostname: state.form.$("hostname").value,
            winsEnabled: state.form.$("winsEnabled").value,
            avahiEnabled: state.form.$("avahiEnabled").value,
        }));
        const descRes = !!(await evoSettingsStore.setEvoDescription(state.form.$("description").value));
        state.inProgress = false;
        state.isSubmitted = netRes && descRes;

        if (autoClose) {
            setTimeout(close, 1000);
        }

        return state.isSubmitted;
    };

    const saveBtnClick = async (e) => {
        if (state.form.$("hostname").isDefault) {
            return await onSubmit(true)(e);
        } else {
            openAlert();
        }
    };

    const openAlert = () => {
        state.isAlertOpen = true;
    };
    const closeAlert = () => {
        state.form.$("hostname").reset();
        state.isAlertOpen = false;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault) {
            close();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <>
            <ConfirmationDialog
                title={t("common.dialog_title.warning")}
                open={state.isAlertOpen}
                onClose={closeAlert}
                onConfirm={onSubmit(true)}
                isSubmitted={state.isSubmitted}
            >
                <Grid container direction={"column"} spacing={5}>
                    <Grid item>
                        <Typography>{t("network.netServiceAdvertising.dialog.confirmation")}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>{t("network.netServiceAdvertising.dialog.question")}</Typography>
                    </Grid>
                </Grid>
            </ConfirmationDialog>
            <Dialog
                open={open}
                onEnter={updateForm}
                onClose={closeIfNeeded}
                onSubmit={saveBtnClick}
                submitBtnLabel={t("common.button.save")}
                title={t("network.netServiceAdvertising.dialog.title")}
                inProgress={state.inProgress}
                isSubmitted={state.isSubmitted}
                buttons={
                    <Button onClick={close} variant={"contained"} color="secondary">
                        {t("common.button.cancel")}
                    </Button>
                }
            >
                <Grid container direction={"column"}>
                    <RowTextInput
                        label={t("network.netServiceAdvertising.label.hostName")}
                        control={<TextInput field={state.form.$("hostname")} />}
                    />
                    <RowTextInput
                        label={t("network.netServiceAdvertising.label.description")}
                        control={<TextInput field={state.form.$("description")} />}
                    />
                    <RowControlLabelSwitch
                        control={<Switch field={state.form.$("winsEnabled")} />}
                        label={t("network.netServiceAdvertising.label.wins")}
                    />
                    <RowControlLabelSwitch
                        control={<Switch field={state.form.$("avahiEnabled")} />}
                        label={t("network.netServiceAdvertising.label.avahi")}
                    />
                </Grid>
                <UnsavedChangesDialog onConfirm={close} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            </Dialog>
        </>
    ));
};

export default SetupNetworkServiceAdvertisingDialog;
