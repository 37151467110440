import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    disability: ({ disabled }) => ({ opacity: disabled ? 0.5 : 1 }),
    pageNumber: {
        color: theme.palette.primary.main,
        width: 32,
        height: 32,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        marginTop: "auto",
        marginBottom: "auto",
    },
    currentPage: {
        color: theme.palette.text.primary,
    },
    pageControlsContainer: {
        justifyContent: "space-between",
    },
    iconButton: {
        "&:disabled": {
            opacity: 0.16,
        },
    },
}));
