import { types } from "mobx-state-tree";

const ErrorOptions = types.model({
    variant: types.optional(types.string, "error"),
    persist: types.maybe(types.boolean),
    preventDuplicate: types.maybe(types.boolean),
    key: types.maybe(types.string)
});

export default ErrorOptions;
