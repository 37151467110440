import { types } from "mobx-state-tree";

import ArchivedInstancesResult from "../Types/ArchivedInstancesResult";

const GetArchivedInstancesResult = types.model("GetArchivedInstancesResult", {
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(ArchivedInstancesResult, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            }
        })
    )
});

export default GetArchivedInstancesResult;
