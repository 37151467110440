import React, { forwardRef } from "react";
import { useStyles } from "./HideOverlay.styles";

const HideOverlay = forwardRef(({ children, show }, ref) => {
    const classes = useStyles({ show });
    return (
        <div className={classes.hideContainer} ref={ref}>
            {children}
        </div>
    );
});

export default HideOverlay;
