import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BottomNavigationAction, BottomNavigation, Divider, CircularProgress, Grid } from "@material-ui/core";

import WorldIcon from "assets/WorldIcon";
import UserIcon from "assets/UserIcon";
import HomeIcon from "assets/HomeIcon";
import SharingIcon from "assets/SharingIcon";
import TrashIcon from "assets/TrashIcon";
import BrowserIcon from "assets/BrowserIcon";
import SlingshotIcon from "assets/SlingshotIcon";
import LoginIcon from "assets/LoginIcon";
import QuickStartIcon from "assets/QuickStartIcon";
import HelpIcon from "assets/HelpIcon";
import DisksIcon from "assets/DisksIcon";
import SpeakerIcon from "assets/SpeakerIcon";
import {
    URL_ALERTS,
    URL_HOME,
    URL_LOGIN,
    URL_NETWORK,
    URL_QUICK_START,
    URL_RECYCLE_BIN,
    URL_SHARES,
    URL_USERS,
    URL_HELP,
    URL_DISKS,
    URL_SHAREBROWSER,
    URL_SLINGSHOT,
    URL_SHORTCUT_WHATS_THIS,
    URL_FILE_NAVIGATOR,
    URL_SHAREBROWSER_AUTH
} from "routes";
import { useStyles } from "./MenuTabs.style.js";
import CustomNavigationAction from "./CustomNavigationAction";
import shortcutFactory from "utils/shortcutFactory";
import WhatsThisModal from "./WhatsThisModal";
import { ADMIN, SECONDARY_ADMIN, USER } from "const/userRolesConst.js";
import DocIcon from "assets/DocIcon.js";
import { useStore } from "hooks/useStore.js";

const MenuTab = ({ afterClickCallback }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const {
        store: { authStore, uiStore }
    } = useStore();

    useEffect(() => {
        authStore.isAuthorized && authStore.role === ADMIN && uiStore.fetchShortcutLayout();
    }, []);

    const state = useLocalStore(() => ({
        bottomNavigationValue: history.location.pathname,
        isWhatsThisModalOpen: false
    }));

    const handleChange = (_, newValue) => {
        state.bottomNavigationValue = newValue;
        history.push(newValue);
        afterClickCallback();
    };
    const shortcutClick = value => () => {
        if (URL_SHORTCUT_WHATS_THIS === value) {
            state.isWhatsThisModalOpen = true;
        } else handleChange(null, value);
    };

    const closeWhatsThisModal = () => {
        state.isWhatsThisModalOpen = false;
    };

    return useObserver(() => (
        <React.Fragment>
            {!authStore.isAuthorized && (
                <BottomNavigation value={state.bottomNavigationValue} onChange={handleChange} className={classes.container}>
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.login")}
                        icon={<LoginIcon />}
                        value={URL_LOGIN}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.quick_start")}
                        icon={<QuickStartIcon />}
                        value={URL_QUICK_START}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.help")}
                        href={URL_HELP}
                        icon={<HelpIcon />}
                        className={classes.tab}
                        target="_blank"
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.share_browser")}
                        icon={<BrowserIcon />}
                        href={URL_SHAREBROWSER}
                        className={classes.tab}
                    />
                </BottomNavigation>
            )}
            {authStore.isAuthorized && authStore.role === ADMIN && (
                <BottomNavigation value={state.bottomNavigationValue} onChange={handleChange} className={classes.container}>
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.home")}
                        icon={<HomeIcon />}
                        value={URL_HOME}
                        className={clsx(classes.tab, classes.homeTab)}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.user")}
                        icon={<UserIcon />}
                        value={URL_USERS}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.shares")}
                        icon={<SharingIcon />}
                        value={URL_SHARES}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.recycle_bin")}
                        icon={<TrashIcon />}
                        value={URL_RECYCLE_BIN}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.share_browser.auth")}
                        icon={<BrowserIcon />}
                        value={URL_SHAREBROWSER_AUTH}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.slingshot")}
                        icon={<SlingshotIcon />}
                        value={URL_SLINGSHOT}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.network")}
                        icon={<WorldIcon />}
                        value={URL_NETWORK}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.alerts")}
                        icon={<SpeakerIcon />}
                        value={URL_ALERTS}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.disk_pools")}
                        icon={<DisksIcon />}
                        value={URL_DISKS}
                        className={classes.tab}
                    />
                </BottomNavigation>
            )}
            {authStore.isAuthorized && authStore.role === SECONDARY_ADMIN && (
                <BottomNavigation value={state.bottomNavigationValue} onChange={handleChange} className={classes.container}>
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.shares")}
                        icon={<SharingIcon />}
                        value={URL_SHARES}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.file_navigator")}
                        icon={<DocIcon />}
                        value={URL_FILE_NAVIGATOR}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.slingshot")}
                        icon={<SlingshotIcon />}
                        value={URL_SLINGSHOT}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.recycle_bin")}
                        icon={<TrashIcon />}
                        value={URL_RECYCLE_BIN}
                        className={classes.tab}
                    />
                </BottomNavigation>
            )}
            {authStore.isAuthorized && authStore.role === USER && (
                <BottomNavigation value={state.bottomNavigationValue} onChange={handleChange} className={classes.container}>
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.file_navigator")}
                        icon={<DocIcon />}
                        value={URL_FILE_NAVIGATOR}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.slingshot")}
                        icon={<SlingshotIcon />}
                        value={URL_SLINGSHOT}
                        className={classes.tab}
                    />
                    <BottomNavigationAction
                        showLabel
                        label={t("side_menu.menu_tabs.recycle_bin")}
                        icon={<TrashIcon />}
                        value={URL_RECYCLE_BIN}
                        className={classes.tab}
                    />
                </BottomNavigation>
            )}

            {authStore.isAuthorized && authStore.role === ADMIN && (
                <React.Fragment>
                    <WhatsThisModal open={state.isWhatsThisModalOpen} onClose={closeWhatsThisModal} />
                    <Divider />
                    {uiStore.shortcuts ? (
                        <React.Fragment>
                            <BottomNavigation
                                value={state.bottomNavigationValue}
                                onChange={handleChange}
                                className={classes.container}
                            >
                                {uiStore.shortcuts.map((shortcut, index) => {
                                    if (!shortcutFactory(shortcut.name)) return null;
                                    const { url, label, icon } = shortcutFactory(shortcut.name);
                                    return (
                                        <CustomNavigationAction
                                            key={shortcut.name}
                                            name={shortcut.name}
                                            index={index}
                                            className={classes.tab}
                                        >
                                            <BottomNavigationAction
                                                disableRipple
                                                showLabel
                                                label={label}
                                                icon={icon}
                                                className={classes.tab}
                                                selected={url === state.bottomNavigationValue}
                                                onClick={shortcutClick(url)}
                                            />
                                        </CustomNavigationAction>
                                    );
                                })}
                            </BottomNavigation>
                        </React.Fragment>
                    ) : (
                        <Grid container justify="center">
                            <Grid item>
                                <CircularProgress size={20} />
                            </Grid>
                        </Grid>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    ));
};

export default MenuTab;
