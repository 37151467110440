import { types } from "mobx-state-tree";

import SharesJsonType from "api/slingshot/storage/Types/SharesJsonType";

const GetSharesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(SharesJsonType, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            }
        })
    )
});

export default GetSharesResult;
