import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    textInfo: {
        fontWeight: 500,
        overflowX: "hidden",
        textOverflow: "ellipsis"
    },
    detailsContainer: {
        padding: theme.spacing(4),
        margin: 0,
        width: "100%"
    }
}));
