import Request from "api/Request";

const GetServiceAdvertising = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "get_service_advertising"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetServiceAdvertising;
