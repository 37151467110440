import React, { useContext, useEffect, useCallback } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { useStyles } from "./LogicalDisksDrawer.styles.js";
import DiskIcon from "assets/DiskIcon";
import { Button, ButtonGroup, Divider, Drawer, Grid, Typography } from "@material-ui/core";
import TrashIcon from "assets/TrashIcon";
import clsx from "clsx";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import DeleteDiskDialog from "../DeleteDiskDialog";
import { convertBytesToSizeUnit } from "utils/convertBytesToSizeUnit";
import { KiB_IEC } from "const/diskSizeConst";
import DrawerHeader from "components/DrawerHeader";
import WorldIcon from "assets/WorldIcon";
import WorldOffIcon from "assets/WorldOffIcon";
import { REPLACE_DISK_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const drawerName = "AffectedLogicalDisksDrawer";

const LOGICAL_DISKS_DRAWER_ID_PREFIX = `${REPLACE_DISK_PAGE_ID_PREFIX}_affected_logical_disks_drawer`;

const LogicalDisksDrawer = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { volumeStore, uiStore, volumeDrawerStore }
    } = useContext(Context);
    const state = useLocalStore(() => ({
        showDetails: false,
        isDeleteDialogOpened: false,
        isDeleteInProgress: false
    }));
    useEffect(() => {
        (async () => {
            if (volumeStore.currentPort) {
                if (uiStore.openedDrawer !== drawerName) {
                    uiStore.openDrawer(drawerName, onClose);
                } else {
                    uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
                }
            } else if (uiStore.openedDrawer === drawerName) {
                uiStore.closeDrawer();
            }
        })();
    }, [volumeStore.currentPort]);
    const volume = volumeStore.currentPort?.volume;

    const openDeleteDialog = useCallback(() => {
        state.isDeleteDialogOpened = true;
    });
    const onClose = useCallback(() => {
        volumeStore.dropCurrentPortName();
    });
    const deleteDisks = useCallback(async () => {
        state.isDeleteDialogOpened = false;
        state.isDeleteInProgress = true;
        const res = await volumeStore.removeVolumes({
            volumeIds: [{ pool: volume.volumeId.pool, volume: volume.volumeId.volume }]
        });
        state.isDeleteInProgress = false;
        res && onClose();
    });
    const closeDeleteDialog = useCallback(() => {
        state.isDeleteDialogOpened = false;
    });
    const showDetails = () => {
        state.showDetails = true;
    };
    const showAction = () => {
        state.showDetails = false;
    };
    const drawerCloseHandler = () => {
        if (uiStore.openedDrawer !== drawerName) {
            state.showDetails = true;
        }
    };

    const isOffline = volume?.status.status === "offline";

    const setVolumeOnline = () => {
        volumeDrawerStore.currentPortStore.setVolumesOnline({
            volumeIds: [{ pool: volume.volumeId.pool, volume: volume.volumeId.volume }],
            online: isOffline
        });
        onClose();
    };

    const isQueue = volumeDrawerStore.currentPort?.isQueueVolume;

    const renderActions = () => {
        return (
            <Grid direction={"column"} item container className={classes.actionContainer}>
                {!isQueue && (
                    <Grid item>
                        <BulkBarFlatButton
                            id={`${LOGICAL_DISKS_DRAWER_ID_PREFIX}_online_status`}
                            onClick={setVolumeOnline}
                            icon={isOffline ? <WorldIcon /> : <WorldOffIcon />}
                        >
                            {isOffline
                                ? t("disks_and_pools.logical_disks.row_context_menu.set_online")
                                : t("disks_and_pools.logical_disks.row_context_menu.set_offline")}
                        </BulkBarFlatButton>
                    </Grid>
                )}
                <Grid item>
                    <BulkBarFlatButton
                        errorColor
                        onClick={openDeleteDialog}
                        icon={<TrashIcon />}
                        inProgress={state.isDeleteInProgress}
                    >
                        {t("disks_and_pools.logical_disks.row_context_menu.delete")}
                    </BulkBarFlatButton>
                </Grid>
            </Grid>
        );
    };

    const renderDetails = () => {
        return (
            <>
                <Grid item container alignItems={"baseline"}>
                    <Grid item xs={6}>
                        <Typography>{t("disks_and_pools.logical_disks.side_menu.stripe_size")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {convertBytesToSizeUnit({ bytes: volume.chunkSize, unit: KiB_IEC })}
                        </Typography>
                    </Grid>
                </Grid>
            </>
        );
    };

    return useObserver(() => (
        <Drawer
            variant={"persistent"}
            className={clsx(classes.drawer, uiStore.isWidgetsBarOpen && classes.drawerOpenHeader)}
            anchor="right"
            open={uiStore.openedDrawer === drawerName}
            onAnimationEnd={drawerCloseHandler}
        >
            <DeleteDiskDialog
                onDeleteAction={deleteDisks}
                diskName={`${volume?.volumeId.pool}/${volume?.volumeId.volume}`}
                onClose={closeDeleteDialog}
                open={state.isDeleteDialogOpened}
                inProgress={state.isDeleteInProgress}
            />
            {volumeStore.currentPort && (
                <Grid className={classes.container} container direction={"column"}>
                    <Grid item>
                        <DrawerHeader
                            closeDrawer={onClose}
                            icon={<DiskIcon />}
                        >{`${volume.volumeId.pool}/${volume.volumeId.volume}`}</DrawerHeader>
                        <Grid item>
                            <ButtonGroup className={classes.headerButtonGroup}>
                                <Button
                                    size={"small"}
                                    onClick={showDetails}
                                    variant={state.showDetails ? "contained" : "outlined"}
                                    color={"primary"}
                                >
                                    {t("disks_and_pools.logical_disks.side_menu.details")}
                                </Button>
                                <Button
                                    size={"small"}
                                    onClick={showAction}
                                    variant={state.showDetails ? "outlined" : "contained"}
                                    color={"primary"}
                                >
                                    {t("disks_and_pools.logical_disks.side_menu.actions")}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid item container className={classes.body} direction={"column"} spacing={4}>
                        {state.showDetails ? renderDetails() : renderActions()}
                    </Grid>
                </Grid>
            )}
        </Drawer>
    ));
};

export default LogicalDisksDrawer;
