import { types } from "mobx-state-tree";

import Request from "api/Request";
import NetServicesAdvertising from "api/net/Types/NetServicesAdvertising";

const SetServiceAdvertising = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "set_service_advertising",
    arguments: types.maybe(NetServicesAdvertising)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetServiceAdvertising;
