import { types, flow, getParent, applySnapshot, isStateTreeNode } from "mobx-state-tree";
import i18n from "i18n";
import Socket from "websocket";
import axios, { AxiosSetAuthToken, AxiosCommonRequestNoCache } from "api/AxiosCommonRequest";
import { LOGIN_URL, LOGOUT_URL, WEBSOCKET_TOKEN_URL } from "api/restRoutes";
import { ADMIN, ROLES, USER, SECONDARY_ADMIN } from "const/userRolesConst";
import { URL_SPLASH_PAGE } from "routes";
import { PUBLIC_ROUTES } from "entry/AppRouter/routes";
import { autorun } from "mobx";
import MultipleEvosRootStore from "store/MultipleEvosRootStore";

export const TOKEN_IS_INVALID = "Token is invalid";

const AuthStore = types
    .model({
        role: types.maybeNull(types.enumeration(ROLES)),
        isAuthorized: types.optional(types.boolean, false),
        isAuthorizationChecked: types.optional(types.boolean, false),
        needToShowLostConnectionNotifier: types.optional(types.boolean, false),
        username: types.maybeNull(types.string),
    })
    .volatile(() => ({
        reconnectInterval: null,
        multipleEvoConnections: [], // {ip, name, socket, axios, isAuthorized},
        processPreparationSocket: false,
    }))
    .views((self) => ({
        getLoginHash(username, password) {
            const hash = btoa(`${username}:${password}`);
            return `Basic ${hash}`;
        },
        get isAdmin() {
            return self.role === ADMIN;
        },
        get isSecondaryAdmin() {
            return self.role === SECONDARY_ADMIN;
        },
        get isClearCredential() {
            return self.isAuthorized === false && self.role === null && self.username === null;
        },
    }))
    .actions((self) => ({
        login: flow(function* (username, password) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const res = yield axios.get(LOGIN_URL, { headers: { Authorization: self.getLoginHash(username, password) } });
                const { websocket_token, access_token, user } = res.data;

                yield Socket.start(websocket_token);

                localStorage.setItem("authToken", `Bearer ${access_token}`);
                AxiosSetAuthToken();
                localStorage.setItem("userName", user.name);

                self.username = user.name;
                self.role = user.role;
                self.isAuthorized = true;

                if (!localStorage.getItem("closeHeader") && self.role !== USER) {
                    localStorage.setItem("closeHeader", "false");
                }
                self.role === USER && localStorage.setItem("closeHeader", "true");

                clearInterval(self.reconnectInterval);
                self.updateNeedToShowLostConnectionNotifier(false);

                return res;
            } catch (e) {
                processingStore.setError(i18n.t("login.error"));
            } finally {
                processingStore.setLoading(false);
            }
        }),
        logout: function () {
            const store = getParent(self);
            const persistsStoreNames = ["bootProgressStore", "authStore"];

            Object.keys(store).forEach((subStoreName) => {
                const subStore = store[subStoreName];

                if (isStateTreeNode(subStore) && !persistsStoreNames.includes(subStoreName)) {
                    applySnapshot(subStore, {});
                }
            });

            self.clearAuthorization();

            axios.get(LOGOUT_URL);
        },
        listen() {
            Socket.onerror = () => {
                // self.clearCredential();
            };
            Socket.onclose = () => {
                if (self.isAuthorized) {
                    self.updateNeedToShowLostConnectionNotifier(true);
                    self.tryToReconnect();
                }
            };
        },
        closeSocket() {
            if (Socket.ws) {
                Socket.ws.close();
            }
        },
        checkAuthAndGetNewSocketToken: flow(function* () {
            clearInterval(self.reconnectInterval);
            self.processPreparationSocket = true;
            const { processingStore } = getParent(self);

            if (localStorage.getItem("authToken") === TOKEN_IS_INVALID) {
                self.isAuthorized = false;
            }

            if (!self.isAuthorized && self.reconnectInterval) {
                clearInterval(self.reconnectInterval);
                return;
            }

            try {
                AxiosSetAuthToken();
                const res = yield AxiosCommonRequestNoCache.get(WEBSOCKET_TOKEN_URL);

                const { websocket_token, user } = res.data;
                yield Socket.start(websocket_token);
                localStorage.setItem("userName", user.name);
                self.role = user.role;
                self.isAuthorized = true;

                clearInterval(self.reconnectInterval);
                self.updateNeedToShowLostConnectionNotifier(false);
                return res;
            } catch (e) {
                if (e?.response?.status === 401) {
                    self.clearAuthorization();
                    return;
                }
                self.tryToReconnect();
            } finally {
                processingStore.setLoading(false);
                self.isAuthorizationChecked = true;
                self.processPreparationSocket = false;
            }
            return null;
        }),
        clearCredential() {
            self.isAuthorized = false;
            self.role = null;
            self.username = null;
            localStorage.setItem("authToken", TOKEN_IS_INVALID);
            localStorage.setItem("userName", null);
            clearInterval(self.reconnectInterval);
            self.updateNeedToShowLostConnectionNotifier(false);
            MultipleEvosRootStore.removeAllStores();
        },
        clearAuthorization: function () {
            this.clearCredential();
            /** @type {Store} */
            const store = getParent(self);
            if (
                store.history?.location.pathname !== "/" &&
                PUBLIC_ROUTES.every(({ path }) => `#${path}` !== document.location.hash.split("?")[0])
            ) {
                store.history.replace(URL_SPLASH_PAGE);
            }
        },
        tryToReconnect: () => {
            if (self.processPreparationSocket) return null;
            if (self.reconnectInterval) clearInterval(self.reconnectInterval);
            self.reconnectInterval = setInterval(self.checkAuthAndGetNewSocketToken, 1000);
        },
        updateNeedToShowLostConnectionNotifier: (data) => {
            self.needToShowLostConnectionNotifier = data;
        },
        restoreUsernameFromLocalStorage: () => {
            const usernameFromLocalStorage = localStorage.getItem("userName");

            if (usernameFromLocalStorage && usernameFromLocalStorage !== "null") {
                self.username = usernameFromLocalStorage;
            }
        },
    }))
    .actions((self) => ({
        launchAuth: flow(function* () {
            const { uiStore, authStore, evoSettingsStore } = getParent(self);

            const resp = yield authStore.checkAuthAndGetNewSocketToken();
            if (!resp) return false;
            evoSettingsStore.startTimerFetch();
            yield uiStore.fetchUiParameters();
            yield uiStore.fetchUiSettings();
            yield uiStore.fetchDashboardLayout();
            uiStore.compareTimestamp();
            return true;
        }),
        watchAutoconnectMultipleEvo() {
            autorun(() => {
                const { multipleEvosStore, uiStore } = getParent(self);
                if (self.isAdmin && uiStore.parametersResult) {
                    multipleEvosStore.tryToConnectToMultipleEvos();
                }
            });
        },
    }))
    .actions((self) => ({
        afterCreate() {
            self.launchAuth();
            self.watchAutoconnectMultipleEvo();
        },
    }));

export default AuthStore;
