export const BLOCK_DIMENSIONS = {
    1048576: 1048576,
    4096: 4096,
    512: 512,
};

export const BLOCK_SIZE_UNIT_BY_SIZE = {
    [BLOCK_DIMENSIONS["1048576"]]: { unit: "MiB", size: 1 },
    [BLOCK_DIMENSIONS["4096"]]: { unit: "KiB", size: 4 },
    [BLOCK_DIMENSIONS["512"]]: { unit: "B", size: 512 },
};

export const BLOCK_SIZE_OPTIONS = [
    { label: "1MiB", value: BLOCK_DIMENSIONS["1048576"] },
    { label: "4KiB ", value: BLOCK_DIMENSIONS["4096"] },
    { label: "512B", value: BLOCK_DIMENSIONS["512"] },
];
