import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";
import { WHITE } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    fab: {
        "&&": {
            backgroundColor: theme.palette.error.main,
            boxShadow: ({ isOver }) =>
                `${theme.spacing(0, 0, 0, isOver ? 4 : 2)} ${hexToRgba(theme.palette.error.main, isOver ? 0.2 : 0.1)}`,
            "& svg > path": {
                fill: WHITE
            },
            "&:hover": {
                backgroundColor: theme.palette.error.main
            }
        }
    },
    fabContainer: {
        borderRadius: "50%",
        transition: "box-shadow 250ms",
        boxShadow: ({ isOver }) => `${theme.spacing(0, isOver ? 6 : 4, 2, 0)} ${hexToRgba(theme.palette.error.main, 0.15)}`
    },
    fabPlus: {
        "&&": {
            backgroundColor: theme.palette.primary.main,
            "& svg > path": {
                fill: WHITE
            },
            "&:hover": {
                backgroundColor: theme.palette.primary.light
            },
            "&:active": {
                backgroundColor: theme.palette.primary.main
            }
        }
    }
}));
