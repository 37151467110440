import React from "react";

const LocalUserIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                fill="#1E90FF"
                d="M3.957 8.317C4.877 9.861 6.367 11 8 11s3.123-1.139 4.043-2.681c1.287.54 2.249 1.724 2.457 3.181l.337 2.359c.078.546-.302 1.053-.849 1.13-.046.008-.094.011-.141.011H2.153c-.552 0-1-.448-1-1 0-.047.003-.095.01-.141L1.5 11.5c.208-1.457 1.17-2.642 2.457-3.183zM8 1c2.052 0 3 1.535 3 4 0 1.208-.63 2.497-1.46 3.275l-.048.045c-.031.028-.062.056-.094.082l-.05.042-.084.066-.078.058-.066.045c-.02.014-.04.028-.061.04l-.095.059-.056.031-.083.044-.075.036-.07.031c-.027.011-.053.022-.08.031-.03.012-.062.023-.093.033l-.047.014c-.04.012-.08.022-.12.03l-.033.007C8.204 8.99 8.102 9 8 9c-.102 0-.204-.01-.306-.03l-.034-.008c-.04-.008-.08-.018-.12-.03l-.047-.014c-.031-.01-.062-.02-.093-.033l-.08-.03-.07-.032c-.025-.011-.05-.023-.074-.036l-.085-.044-.054-.031-.096-.058-.06-.04c-.023-.015-.045-.03-.067-.047l-.078-.057-.084-.066-.05-.041-.094-.083-.048-.045C5.63 7.497 5 6.208 5 5c0-2.465.948-4 3-4z"
            />
        </svg>
    );
};

export default LocalUserIcon;
