import React from "react";
import SavedInputPreview from "components/SavedInputPreview";
import { useStyles } from "./SavedInputIndicator.styles";
import { useTranslation } from "react-i18next";

const SavedInputIndicator = ({ enabled, hideLabel }) => {
    const classes = useStyles({ enabled });
    const { t } = useTranslation();
    return (
        <SavedInputPreview className={classes.container} spacing={2} icon={<div className={classes.oval} />}>
            {!hideLabel && (enabled ? t("common.label.enabled") : t("common.label.disabled"))}
        </SavedInputPreview>
    );
};

export default SavedInputIndicator;
