import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetOwnerArguments from "api/security/acl/Types/GetOwnerArguments";

const GetOwner = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Acl",
    method: "get_owner",
    arguments: types.maybe(GetOwnerArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetOwner
