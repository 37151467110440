import React, { useContext } from "react";
import {
    Dialog,
    Button,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    Grid,
    DialogActions,
    DialogContent,
    ButtonBase,
} from "@material-ui/core";
import CardHeader from "components/CardHeader";
import TableCellWithIcon from "components/TableCellWithIcon";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./UserMembershipModal.styles";
import { Context } from "store";
import ExternalGroupIcon from "assets/ExternalGroupIcon";
import LocalGroupIcon from "assets/LocalGroupIcon";
import LocalUserIcon from "assets/LocalUserIcon";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import CloseIcon from "assets/CloseIcon";
import CardHeaderControlButton from "components/CardHeaderControlButton";

const USER_MEMBERSHIP_MODAL_ID_PREFIX = "user_membership_modal";

const UserMembershipModal = ({ open, onClose, parentId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const idPrefix = parentId ? `${parentId}_${USER_MEMBERSHIP_MODAL_ID_PREFIX}` : USER_MEMBERSHIP_MODAL_ID_PREFIX;
    const {
        store: { userStore },
    } = useContext(Context);

    const state = useLocalStore(() => ({
        groups: [],
        isSearchInProgress: false,
    }));

    const listMembers = async () => {
        state.isSearchInProgress = true;
        state.groups = await userStore.listUserMembership();
        state.isSearchInProgress = false;
    };

    const getType = (isExternal, isGroup) => {
        const getIcon = () => {
            if (isGroup) {
                return isExternal ? <ExternalGroupIcon /> : <LocalGroupIcon />;
            } else {
                return <LocalUserIcon />;
            }
        };
        const getLabel = () => {
            if (isGroup) {
                return isExternal ? t("users.common.external_group") : t("users.common.local_group");
            } else {
                return isExternal ? t("users.common.external_user") : t("users.common.local_user");
            }
        };

        return {
            typeIcon: getIcon(),
            typeLabel: getLabel(),
        };
    };

    const getName = () => {
        return userStore.currentEntity.isExternal
            ? `${userStore.currentEntity.id.name}@${userStore.currentEntity.id.domain}`
            : userStore.currentEntity.id.name;
    };

    return useObserver(() => (
        <Dialog maxWidth={"sm"} fullWidth open={open} onClose={onClose} onEnter={listMembers}>
            <CardHeader
                invisibleIcon
                title={t("users.side_menu.user_membership_modal.title", { name: getName() || "" })}
                control={
                    <CardHeaderControlButton onClick={onClose}>
                        <ButtonBase id={`${idPrefix}_close_icon`}>
                            <CloseIcon />
                        </ButtonBase>
                    </CardHeaderControlButton>
                }
            />
            {state.groups?.length > 0 ? (
                <DialogContent dividers className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("users.search_results.table.column.name")}</TableCell>
                                <TableCell>{t("users.search_results.table.column.type")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.groups.map((group) => {
                                const { typeIcon, typeLabel } = getType(group.isExternal, group.isGroup);
                                return (
                                    <TableRow key={group.id.name + group.id.domain}>
                                        <TableCell className={classes.tableCell}>{group.id.name}</TableCell>
                                        <TableCellWithIcon className={classes.tableCell} align="left" icon={typeIcon}>
                                            {typeLabel}
                                        </TableCellWithIcon>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
            ) : (
                <EmptyCardPlaceholder
                    inProgress={state.isSearchInProgress}
                    message={
                        !userStore.userMembershipError
                            ? t("users.side_menu.user_membership_modal.placeholder")
                            : t("users.side_menu.user_membership_modal.error")
                    }
                />
            )}

            <DialogActions className={classes.controlsContainer}>
                <Grid item>
                    <Button onClick={onClose} variant={"contained"} color={"primary"} id={`${idPrefix}_close`}>
                        {t("common.button.close")}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    ));
};

export default UserMembershipModal;
