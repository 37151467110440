import { EXTERNAL_URL_EVO_SHARED_STORAGE_SERVER_RECOMMENDATIONS } from "const/externalURLs";
import { URL_TROUBLESHOOTING } from "routes";

export default {
    perfomanceAndTuning: {
        titleKey: "quick_start.tips_card.perfomance_and_tuning.summary",
        paragraphs: [
            {
                textKey: "quick_start.tips_card.perfomance_and_tuning.paragraph_1",
                gutterBottom: true
            },
            {
                textKey: "quick_start.tips_card.perfomance_and_tuning.paragraph_2",
                gutterBottom: true
            },
            {
                textKey: "quick_start.tips_card.perfomance_and_tuning.paragraph_3",
                gutterBottom: true
            },
            {
                textKey: "quick_start.tips_card.perfomance_and_tuning.paragraph_4",
                components: [
                    {
                        type: "link",
                        path: EXTERNAL_URL_EVO_SHARED_STORAGE_SERVER_RECOMMENDATIONS,
                        inNewTab: true
                    }
                ]
            }
        ]
    },
    driveReplacement: {
        titleKey: "quick_start.tips_card.drive_replacement.summary",
        paragraphs: [
            {
                textKey: "quick_start.tips_card.drive_replacement.paragraph_1",
                components: [
                    {
                        type: "link",
                        path: URL_TROUBLESHOOTING,
                        inNewTab: true
                    }
                ]
            }
        ]
    },
    properShutdown: {
        titleKey: "quick_start.tips_card.proper_shutdown.summary",
        paragraphs: [
            {
                textKey: "quick_start.tips_card.proper_shutdown.paragraph_1",
                gutterBottom: true
            },
            {
                textKey: "quick_start.tips_card.proper_shutdown.paragraph_2"
            }
        ]
    }
};
