import React, { useContext, useCallback } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import DrawerHeader from "components/DrawerHeader";
import { Divider, Drawer, Grid } from "@material-ui/core";
import StatusIcon from "assets/StatusIcon";
import DownloadIcon from "assets/DownloadIcon";
import ClearIcon from "assets/ClearIcon";
import ShareFolderIcon from "assets/ShareFolderIcon";
import { useStyles } from "./ShareForAuditDrawer.styles";
import clsx from "clsx";
import WarningDialog from "../WarningDialog";
import ClearLogsWarningDialog from "../ClearLogsWarningDialog";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import { AUDIT_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const SHARE_FOR_AUDIT_BULK_BAR_ID_PREFIX = `${AUDIT_PAGE_ID_PREFIX}_share_for_audit_bulk_bar`;

const drawerName = "shareForAuditDrawer";
const ShareForAuditBulkBar = () => {
    const { t } = useTranslation();
    const classes = useStyles({});

    const {
        store: { auditStore, uiStore },
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isChangeStatusInProgress: false,
        isDownloadInProgress: false,
        isClearInProgress: false,
        isWarningDialogOpen: false,
        isClearLogsWarningDialogOpen: false,
    }));

    const closeDrawer = () => {
        auditStore.setCurrentShareName(null);
    };

    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: auditStore.currentShareName });

    const downloadShareLog = async () => {
        state.isDownloadInProgress = true;
        const res = await auditStore.downloadShareLog({ shareName: auditStore.currentShareName });
        state.isDownloadInProgress = false;
        res && closeDrawer();
    };

    const clearShareLog = async () => {
        closeClearLogsWarningDialog();
        state.isClearInProgress = true;
        const res = await auditStore.clearShareLog({ shareName: auditStore.currentShareName });
        state.isClearInProgress = false;
        res && closeDrawer();
    };

    const disableShare = async () => {
        closeWarningDialog();
        state.isChangeStatusInProgress = true;
        const res = await auditStore.disableAuditOnShare({ shares: [auditStore.currentShareName] });
        state.isChangeStatusInProgress = false;
        res && closeDrawer();
    };

    const enableShare = async () => {
        closeWarningDialog();
        state.isChangeStatusInProgress = true;
        const res = await auditStore.enableAuditOnShare({ shares: [auditStore.currentShareName] });
        state.isChangeStatusInProgress = false;
        res && closeDrawer();
    };

    const openWarningDialog = useCallback(() => {
        state.isWarningDialogOpen = true;
    });
    const closeWarningDialog = useCallback(() => {
        state.isWarningDialogOpen = false;
    });
    const openClearLogsWarningDialog = useCallback(() => {
        state.isClearLogsWarningDialogOpen = true;
    });
    const closeClearLogsWarningDialog = useCallback(() => {
        state.isClearLogsWarningDialogOpen = false;
    });

    return useObserver(() => (
        <Drawer
            variant={"persistent"}
            className={clsx(classes.drawer, uiStore.isWidgetsBarOpen && classes.drawerOpenHeader)}
            anchor="right"
            open={uiStore.openedDrawer === drawerName}
        >
            <WarningDialog
                open={state.isWarningDialogOpen}
                onClose={closeWarningDialog}
                submit={auditStore.currentShare?.enabled ? disableShare : enableShare}
            />
            <ClearLogsWarningDialog
                open={state.isClearLogsWarningDialogOpen}
                onClose={closeClearLogsWarningDialog}
                submit={clearShareLog}
                content={t("audit.audit_per_share_settings.clear_logs_warning_dialog.text", {
                    shareName: auditStore.currentShareName,
                })}
            />
            <Grid className={classes.container} container direction={"column"}>
                <Grid item container>
                    <DrawerHeader closeDrawer={closeDrawer} icon={<ShareFolderIcon />}>
                        {auditStore.currentShareName}
                    </DrawerHeader>
                </Grid>
                <Divider />
                <Grid item container className={classes.body} direction={"column"}>
                    <Grid item>
                        {auditStore.currentShare?.enabled ? (
                            <BulkBarFlatButton
                                inProgress={state.isChangeStatusInProgress}
                                className={classes.iconDisable}
                                onClick={openWarningDialog}
                                icon={<StatusIcon />}
                                id={`${SHARE_FOR_AUDIT_BULK_BAR_ID_PREFIX}_disable`}
                            >
                                {t("audit.audit_per_share_settings.side_menu.actions.button.disable")}
                            </BulkBarFlatButton>
                        ) : (
                            <BulkBarFlatButton
                                inProgress={state.isChangeStatusInProgress}
                                className={classes.iconEnable}
                                onClick={openWarningDialog}
                                icon={<StatusIcon />}
                                id={`${SHARE_FOR_AUDIT_BULK_BAR_ID_PREFIX}_enable`}
                            >
                                {t("audit.audit_per_share_settings.side_menu.actions.button.enable")}
                            </BulkBarFlatButton>
                        )}
                    </Grid>
                    <Grid item>
                        <BulkBarFlatButton
                            inProgress={state.isDownloadInProgress}
                            onClick={downloadShareLog}
                            icon={<DownloadIcon />}
                            id={`${SHARE_FOR_AUDIT_BULK_BAR_ID_PREFIX}_download`}
                        >
                            {t("audit.audit_per_share_settings.side_menu.actions.button.download")}
                        </BulkBarFlatButton>
                    </Grid>
                    <Grid item>
                        <BulkBarFlatButton
                            inProgress={state.isClearInProgress}
                            onClick={openClearLogsWarningDialog}
                            icon={<ClearIcon />}
                            id={`${SHARE_FOR_AUDIT_BULK_BAR_ID_PREFIX}_clear`}
                        >
                            {t("audit.audit_per_share_settings.side_menu.actions.button.clear")}
                        </BulkBarFlatButton>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    ));
};

export default ShareForAuditBulkBar;
