import { types } from "mobx-state-tree";

import FilesPage from "api/file/Types/FilesPage";

const ListFilesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(FilesPage)
});

export default ListFilesResult;
