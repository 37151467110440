import React from "react";
import { observer, useLocalStore } from "mobx-react";
import DataCard from "components/DataCard";
import { ButtonBase, Typography, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "assets/ChevronRightIcon";
import PlusIcon from "assets/PlusIcon";
import TableRowControlButton from "components/TableRowControlButton";
import TableSortLabel from "components/TableSortLabel";
import {
    SLINGSHOT_ALIAS_NAME,
    SLINGSHOT_ALIAS_URL,
    SLINGSHOT_ALIAS_CREATED,
    SLINGSHOT_ALIAS_STATUS,
    SLINGSHOT_ALIAS_VOLUME,
} from "const/sortColumnConst";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { SLINGSHOT_ALIAS_CARD } from "const/widgetNameConst";
import AliasesDrawer from "../AliasesDrawer";
import CreateEditAliasDialog from "../CreateEditAliasDialog";
import { SLINGSHOT_DATE_FORMAT } from "const";
import moment from "moment";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { useStore } from "hooks/useStore";
import { useLauncher } from "hooks/useLauncher";
import { getAliasUrl } from "./Aliases.utils";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const ALIASES_ID_PREFIX = `aliases`;

const Aliases = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${ALIASES_ID_PREFIX}` : ALIASES_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { slingshotSettingsStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isCreateAliasDialogOpen: false,
        inProgress: false,
        beginProgress() {
            this.inProgress = true;
        },
        endProgress() {
            this.inProgress = false;
        },
    }));

    const { isError } = useLauncher(
        [state.beginProgress, slingshotSettingsStore.fetchAliases, slingshotSettingsStore.getRegistrations, state.endProgress],
        { ignoreErrors: false, dependencies: [slingshotSettingsStore.isErrorConnectedDBus] }
    );

    const changeSorting = (column) => () => slingshotSettingsStore.changeSortingAliases(column);

    const openCreateAliasDialog = () => {
        state.isCreateAliasDialogOpen = true;
    };

    const closeCreateAliasDialog = () => {
        state.isCreateAliasDialogOpen = false;
    };

    const onRowClick = (aliasName) => () => {
        slingshotSettingsStore.setCurrentAliasName(slingshotSettingsStore.currentAliasName === aliasName ? null : aliasName);
    };

    const getStatus = (status) => {
        switch (status) {
            case "IN_PROGRESS":
                return t("slingshot_page.tabs.settings.aliases.status.in_progress");
            case "FAILED":
                return t("slingshot_page.tabs.settings.aliases.status.failed");
            case "SUCCEED":
                return t("slingshot_page.tabs.settings.aliases.status.succeed");
            case "CANCELED":
                return t("slingshot_page.tabs.settings.aliases.status.canceled");
            default:
                return "";
        }
    };

    return (
        <DataCard
            title={t("slingshot_page.tabs.settings.aliases.title")}
            headerDivider
            autoHeight
            emptyPadding
            headerControl={[
                <MoreMenu key={`data_card-header_control-control-1`}>
                    <HomeCardActionMenuItem widget={SLINGSHOT_ALIAS_CARD} homeCardActionMenuItemId={`${idPrefix}_add_to_home`} />
                </MoreMenu>,
                !isError && (
                    <ButtonBase
                        onClick={openCreateAliasDialog}
                        key={`data_card-header_control-control-2`}
                        id={`${idPrefix}_open_create_alias_dialog`}
                    >
                        <PlusIcon />
                    </ButtonBase>
                ),
            ]}
        >
            <AliasesDrawer parentId={idPrefix} />
            <CreateEditAliasDialog
                open={state.isCreateAliasDialogOpen}
                onClose={closeCreateAliasDialog}
                isCreate
                parentId={idPrefix}
            />
            {slingshotSettingsStore.sortedAliases.length ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(SLINGSHOT_ALIAS_NAME)}
                                    direction={slingshotSettingsStore.orderAlias}
                                    active={slingshotSettingsStore.orderByAlias === SLINGSHOT_ALIAS_NAME}
                                    id={`${idPrefix}_sort_name`}
                                >
                                    {t("slingshot_page.tabs.settings.aliases.name")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(SLINGSHOT_ALIAS_URL)}
                                    direction={slingshotSettingsStore.orderAlias}
                                    active={slingshotSettingsStore.orderByAlias === SLINGSHOT_ALIAS_URL}
                                    id={`${idPrefix}_sort_url`}
                                >
                                    {t("slingshot_page.tabs.settings.aliases.url")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(SLINGSHOT_ALIAS_CREATED)}
                                    direction={slingshotSettingsStore.orderAlias}
                                    active={slingshotSettingsStore.orderByAlias === SLINGSHOT_ALIAS_CREATED}
                                    id={`${idPrefix}_sort_created`}
                                >
                                    {t("slingshot_page.tabs.settings.aliases.created")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(SLINGSHOT_ALIAS_STATUS)}
                                    direction={slingshotSettingsStore.orderAlias}
                                    active={slingshotSettingsStore.orderByAlias === SLINGSHOT_ALIAS_STATUS}
                                    id={`${idPrefix}_sort_status`}
                                >
                                    {t("slingshot_page.tabs.settings.aliases.status")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(SLINGSHOT_ALIAS_VOLUME)}
                                    direction={slingshotSettingsStore.orderAlias}
                                    active={slingshotSettingsStore.orderByAlias === SLINGSHOT_ALIAS_VOLUME}
                                    id={`${idPrefix}_sort_volume`}
                                >
                                    {t("slingshot_page.tabs.settings.aliases.volume")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {slingshotSettingsStore.sortedAliases.map((alias) => {
                            return (
                                <TableRow
                                    selected={slingshotSettingsStore.currentAliasName === alias.name}
                                    key={alias.id}
                                    onClick={onRowClick(alias.name)}
                                >
                                    <TableCell width={"15%"}>{alias.name}</TableCell>
                                    <TableCell width={"30%"}>{getAliasUrl(alias.decomposed_base_url)}</TableCell>
                                    <TableCell width={"10%"}>
                                        {moment.utc(alias.created).format(getLanguageDateFormat(SLINGSHOT_DATE_FORMAT))}
                                    </TableCell>
                                    <TableCell width={"10%"}>{getStatus(alias.status)} </TableCell>
                                    <TableCell width={"35%"}>
                                        <Typography>{alias.extvolume_uuid ? alias.extvolume_uuid.toUpperCase() : ""}</Typography>
                                    </TableCell>
                                    <TableCell align={"right"}>
                                        <TableRowControlButton
                                            icon={<ChevronRightIcon />}
                                            id={`${idPrefix}_open_drawer_${alias.name}`}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <EmptyCardPlaceholder
                    inProgress={state.inProgress}
                    message={t("slingshot_page.tabs.settings.aliases.placeholder")}
                />
            )}
        </DataCard>
    );
};

export default observer(Aliases);
