import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";
import DashedBorderBgImage from "assets/DashedBorderBgImage.svg";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: 80,
        borderRadius: theme.spacing(2),
        backgroundImage: `url(${DashedBorderBgImage})`,
        backgroundColor: ({ isOver }) => hexToRgba(theme.palette.primary.main, isOver ? 0.2 : 0.1),
        cursor: ({ isOver }) => isOver && "grabbing",
        borderColor: ({ isOver }) => isOver && theme.palette.primary.main,
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(5, 6),
        position: "relative",
    },
    containerError: {
        border: `1px solid${theme.palette.error.main}`,

        backgroundImage: "none",
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    text: {
        marginRight: "auto",
    },
    helperText: {
        opacity: 1,
        fontSize: 11,
        position: "absolute",
        zIndex: 1,
        right: 4, // change this if the input size changes
        top: 52, // change this if the input size changes
        padding: "2px 8px",
        borderRadius: 2,
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
        margin: "4px 14px 0",
        "&::after": {
            content: "' '",
            display: "block",
            position: "absolute",
            right: 10,
            bottom: "100%",
            width: 0,
            height: 0,
            border: "4px solid transparent",
            borderBottomColor: theme.palette.error.main,
        },
    },
    helperTextHidden: {
        display: "none",
    },
}));
