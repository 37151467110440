import React, { useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import createForm from "utils/createForm";
import { useStyles } from "./SetupExtensionExclusionsDialog.styles";
import { Grid, Typography, Button, Divider, DialogTitle, ButtonBase } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import Dialog from "components/Dialog";
import TrashIcon from "assets/TrashIcon";
import CloseIcon from "assets/CloseIcon";
import { useTranslation } from "react-i18next";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";

const SETUP_EXTENSION_EXCLUSIONS_DIALOG_ID_PREFIX = `setup_extension_excludions_dialog`;

const SetupExtensionExclusionsDialog = ({ closeDialog, open, parentId }) => {
    const idPrefix = parentId
        ? `${parentId}_${SETUP_EXTENSION_EXCLUSIONS_DIALOG_ID_PREFIX}`
        : SETUP_EXTENSION_EXCLUSIONS_DIALOG_ID_PREFIX;
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { indexingConfigStore },
    } = useStore();

    const state = useLocalStore(() => ({
        inProgress: false,
        form: createForm({
            fields: createFields(),
            options: {
                validateOnBlur: false,
            },
        }),
        extensionsList: [],
        isSubmitted: false,
    }));

    const unsavedChangesModal = useModal();

    useEffect(() => {
        if (!indexingConfigStore.mediaProxy?.extensionBlackList) return;
        const extensionBlackList = indexingConfigStore.mediaProxy?.extensionBlackList;
        state.extensionsList = [...extensionBlackList];
    }, [indexingConfigStore.mediaProxy?.extensionBlackList]);

    const addExtension = (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        state.extensionsList.unshift(state.form.$("extension").value);
        state.form.clear();
    };

    const removeExtension = (index) => () => {
        state.extensionsList.splice(index, 1);
    };

    const onSubmit = async () => {
        state.inProgress = true;
        const res = await indexingConfigStore.setCommonExtensionExclusions(state.extensionsList);
        state.inProgress = false;
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    const onEnter = () => {
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.inProgress = false;
        const extensionBlackList = indexingConfigStore.mediaProxy?.extensionBlackList;
        state.extensionsList = [...extensionBlackList];
        state.form.clear();
    };

    const closeIfNeeded = () => {
        if (state.extensionsList.join() === indexingConfigStore.mediaProxy?.extensionBlackList.join() || state.isSubmitted) {
            closeDialog();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            title={t("slingshot.index_proxy.extension_exclusions.dialog.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            open={open}
            withoutPaddings
            onEnter={onEnter}
            onClose={closeIfNeeded}
            onSubmit={onSubmit}
            inProgress={state.inProgress}
            buttons={
                <Button onClick={closeDialog} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
            header={
                <>
                    <DialogTitle>
                        <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container>
                            <Grid item>{t("slingshot.index_proxy.extension_exclusions.dialog.title")}</Grid>
                            <Grid item>
                                <ButtonBase onClick={closeIfNeeded} id={`${idPrefix}_close_icon`}>
                                    <CloseIcon />
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Divider />
                    <DialogTitle>
                        <Grid container wrap={"nowrap"} spacing={2}>
                            <Grid item>
                                <Typography className={classes.labelText} noWrap>
                                    {t("slingshot.index_proxy.extension_exclusions.dialog.label")}
                                </Typography>
                            </Grid>
                            <Grid container item spacing={4} alignItems={"center"} wrap={"nowrap"} justify={"flex-end"}>
                                <Grid item>
                                    <TextInput field={state.form.$("extension")} id={`${idPrefix}_extension`} />
                                </Grid>

                                <Grid item>
                                    <Button
                                        onClick={addExtension}
                                        variant={"contained"}
                                        color={"primary"}
                                        id={`${idPrefix}_add_extension`}
                                    >
                                        {t("common.button.add")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                </>
            }
        >
            <Grid container>
                {state.extensionsList.map((item, index) => (
                    <Grid
                        container
                        wrap={"nowrap"}
                        alignItems={"center"}
                        key={Math.floor(Math.random() * Date.now())}
                        className={classes.itemContainer}
                    >
                        <Grid item xs={11}>
                            <Typography>{item}</Typography>
                        </Grid>
                        <Grid item container xs={1} alignItems={"center"} justify={"flex-end"}>
                            <ButtonBase onClick={removeExtension(index)} id={`${idPrefix}_remove_extension_${item}`}>
                                <TrashIcon />
                            </ButtonBase>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default SetupExtensionExclusionsDialog;
