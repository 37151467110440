import React, { useCallback, useContext, useEffect } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Grid } from "@material-ui/core";
import { useStyles } from "./MissingQuadsTable.styles";
import { useObserver, useLocalStore } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "assets/ChevronRightIcon";
import TableRowControlButton from "components/TableRowControlButton";
import {
    ASC,
    DESC,
    MISSING_QUADS_OLD_POSITION,
    WIREGUARD_CONNECTION_NAME,
    MISSING_QUADS_DRIVE_SERIALS
} from "const/sortColumnConst";
import DataCard from "components/DataCard/DataCard";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import TableExpand from "components/TableExpand/TableExpand";
import TableSortLabel from "components/TableSortLabel";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import MoreMenu from "components/MoreMenu";
import { MISSING_QUADS_CARD } from "const/widgetNameConst";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import MissingQuadsDrawer from "../MissingQuadsDrawer";
import { HARDWARE_ACTIVATION_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const MISSING_QUADS_TABLE_ID_PREFIX = `${HARDWARE_ACTIVATION_PAGE_ID_PREFIX}_missing_quads_table`;

const MissingQuadsTable = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: { quadActivationStore }
    } = useContext(Context);

    useEffect(() => {
        quadActivationStore.fetchMissingQuads();
    }, []);

    const HIDDEN_ROWS_PER_CARD_INDEX = 6;
    const state = useLocalStore(() => ({
        orderBy: WIREGUARD_CONNECTION_NAME,
        order: ASC,
        isExpanded: false,
        get slicedIndex() {
            if (this.isExpanded) {
                return this.connections.length;
            } else {
                return HIDDEN_ROWS_PER_CARD_INDEX;
            }
        },
        get connections() {
            return quadActivationStore.missingQuads;
        },
        get sortedConnections() {
            return this.connections && stableSort(this.connections, getComparator(this.order, this.orderBy));
        }
    }));

    const toggleExpandShares = useCallback(() => {
        state.isExpanded ? (state.isExpanded = false) : (state.isExpanded = true);
    });

    const onRowClick = useCallback(missingQuad => () => {
        quadActivationStore.setCurrentMissingQuadId(
            quadActivationStore.currentMissingQuadId === missingQuad.quadId ? null : missingQuad.quadId
        );
    });

    const changeSorting = useCallback(column => () => {
        if (column === state.orderBy) {
            state.order = state.order === DESC ? ASC : DESC;
        } else {
            state.order = ASC;
            state.orderBy = column;
        }
    });

    return useObserver(() => (
        <DataCard
            emptyPadding
            autoHeight
            title={t("missing_quads.table.title")}
            headerDivider
            headerControl={
                <MoreMenu id={`${MISSING_QUADS_TABLE_ID_PREFIX}_more_menu`}>
                    <HomeCardActionMenuItem widget={MISSING_QUADS_CARD} />
                </MoreMenu>
            }
        >
            <MissingQuadsDrawer />
            {quadActivationStore.missingQuads && quadActivationStore.missingQuads.length > 0 ? (
                <Grid className={classes.tableContainer} container direction={"column"} justify={"space-between"}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        direction={state.order}
                                        onClick={changeSorting(MISSING_QUADS_OLD_POSITION)}
                                        active={state.orderBy === MISSING_QUADS_OLD_POSITION}
                                        id={`${MISSING_QUADS_TABLE_ID_PREFIX}_old_position_sort`}
                                    >
                                        {t("missing_quads.table.old_position")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={state.order}
                                        onClick={changeSorting(MISSING_QUADS_DRIVE_SERIALS)}
                                        active={state.orderBy === MISSING_QUADS_DRIVE_SERIALS}
                                        id={`${MISSING_QUADS_TABLE_ID_PREFIX}_serials_sort`}
                                    >
                                        {t("missing_quads.table.drive_serials")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width={"5px"}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.sortedConnections?.slice(0, state.slicedIndex).map((missingQuad, i) => {
                                return (
                                    <TableRow
                                        selected={quadActivationStore.currentMissingQuadId === missingQuad}
                                        // TODO  Do not use Array index in keys
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={"missingQuad" + i}
                                        onClick={onRowClick(missingQuad)}
                                    >
                                        <TableCell>{t("missing_quads.table.quad", { number: missingQuad.quadNumber })}</TableCell>
                                        <TableCell>{missingQuad.serialsField}</TableCell>
                                        <TableCell>
                                            <TableRowControlButton
                                                icon={<ChevronRightIcon />}
                                                id={`${MISSING_QUADS_TABLE_ID_PREFIX}_quad_${i}_button`}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        {state.connections.length > HIDDEN_ROWS_PER_CARD_INDEX && (
                            <TableExpand onClick={toggleExpandShares}>
                                {state.isExpanded
                                    ? t("missing_quads.show_all_table_caption.hide")
                                    : t("missing_quads.show_all_table_caption.show_all", {
                                          count: state.connections.length
                                      })}
                            </TableExpand>
                        )}
                    </Table>
                </Grid>
            ) : (
                <EmptyCardPlaceholder message={t("missing_quads.table.placeholder")} />
            )}
        </DataCard>
    ));
};

export default MissingQuadsTable;
