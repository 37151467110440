import React from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@material-ui/core";
import createForm from "utils/createForm";
import createFields from "./createFields";
import Switch from "components/MobxForm/Switch";
import RowTextInput from "components/RowTextInput";
import { useLocalStore, useObserver } from "mobx-react-lite";
import TextInput from "components/MobxForm/TextInput";
import { useStyles } from "./EmailConfigurationDialog.styles";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const EMAIL_CONFIGURATION_DIALOG_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_email_configuration_dialog`;

const EmailConfigurationDialog = ({ onClose, open }) => {
    const { t } = useTranslation();
    const {
        store: { emailConfigurationStore }
    } = useStore();
    const classes = useStyles();
    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields(emailConfigurationStore.emailSettings) }),
        isTestMessageInProgress: false,
        isTestMessageSubmitted: false,
        inProgress: false,
        isSubmitted: false
    }));

    const unsavedChangesModal = useModal();

    const resetForm = () => {
        state.form.$("smtpUser").set("default", emailConfigurationStore.emailSettings.smtpUser);
        state.form.$("smtpPassword").set("default", emailConfigurationStore.emailSettings.smtpPassword);
        state.form.$("smtpServer").set("default", emailConfigurationStore.emailSettings.smtpServer);
        state.form.$("isSslEnabled").set("default", emailConfigurationStore.emailSettings.isSslEnabled);
        state.form.$("emailFrom").set("default", emailConfigurationStore.emailSettings.emailFrom);
        state.form.$("emailsTo").set("default", emailConfigurationStore.emailSettings.emailsTo?.join("; "));
        state.form.reset();
        state.isTestMessageSubmitted = false;
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };
    const getFormValues = () => ({
        smtpUser: state.form.$("smtpUser").value,
        smtpPassword: state.form.$("smtpPassword").value,
        smtpServer: state.form.$("smtpServer").value,
        isSslEnabled: state.form.$("isSslEnabled").value,
        emailFrom: state.form.$("emailFrom").value,
        emailsTo: (state.form.$("emailsTo").value && state.form.$("emailsTo").value.split("; ")) || []
    });
    const onSubmit = async e => {
        state.inProgress = true;
        state.form.onSubmit(e);
        if (!state.form.isValid) {
            state.inProgress = false;
            return;
        }
        const res = await emailConfigurationStore.setEmailSettings(getFormValues());
        state.inProgress = false;
        state.isSubmitted = true;
        return res;
    };
    const sendTestEmail = async () => {
        state.isTestMessageInProgress = true;
        state.isTestMessageSubmitted = await emailConfigurationStore.sendTestEmail(getFormValues());
        state.isTestMessageInProgress = false;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const handleFocusPassword = e => {
        const target = e.target;
        setTimeout(() => target.select(), 0);
    };

    return useObserver(() => (
        <Dialog
            onClose={closeIfNeeded}
            onEnter={resetForm}
            open={open}
            title={t("alerts.email_configuration_dialog.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${EMAIL_CONFIGURATION_DIALOG_ID_PREFIX}_save`}
            onSubmit={onSubmit}
            inProgress={state.inProgress}
            buttons={
                <Button
                    onClick={onClose}
                    variant={"contained"}
                    color="secondary"
                    id={`${EMAIL_CONFIGURATION_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <RowTextInput
                label={t("alerts.email_configuration_card.name")}
                control={<TextInput field={state.form.$("smtpUser")} id={`${EMAIL_CONFIGURATION_DIALOG_ID_PREFIX}_user_name`} />}
            />
            <RowTextInput
                label={t("alerts.email_configuration_card.password")}
                control={
                    <TextInput
                        field={state.form.$("smtpPassword")}
                        onFocus={handleFocusPassword}
                        id={`${EMAIL_CONFIGURATION_DIALOG_ID_PREFIX}_user_password`}
                    />
                }
            />
            <RowTextInput
                label={t("alerts.email_configuration_card.server")}
                control={<TextInput field={state.form.$("smtpServer")} id={`${EMAIL_CONFIGURATION_DIALOG_ID_PREFIX}_server`} />}
            />
            <RowControlLabelSwitch
                control={<Switch field={state.form.$("isSslEnabled")} id={`${EMAIL_CONFIGURATION_DIALOG_ID_PREFIX}_ssl`} />}
                label={t("alerts.email_configuration_card.ssl")}
            />
            <RowTextInput
                label={t("alerts.email_configuration_card.from")}
                control={
                    <TextInput field={state.form.$("emailFrom")} id={`${EMAIL_CONFIGURATION_DIALOG_ID_PREFIX}_email_from`} />
                }
            />
            <RowTextInput
                label={t("alerts.email_configuration_card.recipient_list")}
                control={<TextInput field={state.form.$("emailsTo")} id={`${EMAIL_CONFIGURATION_DIALOG_ID_PREFIX}_emails_to`} />}
            />
            <Grid className={classes.buttonContainer} item container>
                <Grid item xs={6} />
                <Grid item>
                    <AnimatedSubmitButton
                        submit={sendTestEmail}
                        inProgress={state.isTestMessageInProgress}
                        isSubmitted={state.isTestMessageSubmitted}
                        label={t("alerts.email_configuration_card.button.test_message")}
                        id={`${EMAIL_CONFIGURATION_DIALOG_ID_PREFIX}_test_message`}
                    />
                </Grid>
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default EmailConfigurationDialog;
