import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import DataCard from "components/DataCard";
import NetworkWidget from "components/graphs/NetworkWidget";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import Select from "components/Select";
import { NETWORK_GRAPH_CARD } from "const/widgetNameConst";
import { useStyles } from "./NetworkGraphCard.styles";
import useStoreByIp from "hooks/useStoreByIp";
import HideOverlay from "components/HideOverlay/HideOverlay";

const NetworkGraphCard = ({ ip, parentId = "networkDashboardCard" }) => {
    const { t } = useTranslation();
    const { ethernetPortsStore } = useStoreByIp({ ip });
    const getIfaces = () => {
        return ethernetPortsStore.sortedByMetricIfaces.filter(iface => "offline" !== iface.status);
    };

    const state = useLocalStore(() => ({
        selectedIface: null,

        onSelectChange(e) {
            this.selectedIface = getIfaces().find(iface => iface.port === e.target.value);
        }
    }));
    const classes = useStyles();

    useEffect(() => {
        (async function fetch() {
            await ethernetPortsStore.fetchAllIfaces();
            const ifaces = getIfaces();
            if (ifaces.length > 0) {
                state.selectedIface = ifaces[0];
            }
        })();
    }, []);

    const renderTitle = () => {
        return (
            <Grid spacing={1} container alignItems={"center"} alignContent={"center"}>
                <Grid item>{t("dashboard.network_widget.title")}</Grid>
                {ethernetPortsStore.sortedByMetricIfaces.length > 0 && (
                    <Grid item>
                        <Select
                            onChange={state.onSelectChange}
                            value={state.selectedIface?.port || ""}
                            options={getIfaces().map(iface => iface.port)}
                            className={classes.select}
                        />
                    </Grid>
                )}
            </Grid>
        );
    };

    return useObserver(() => (
        <DataCard
            autoHeight
            headerDivider
            title={renderTitle()}
            headerControl={
                <HideOverlay show={!ip}>
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={NETWORK_GRAPH_CARD} />
                    </MoreMenu>
                </HideOverlay>
            }
        >
            <NetworkWidget parentId={parentId} iface={state.selectedIface} ip={ip} />
        </DataCard>
    ));
};

export default NetworkGraphCard;
