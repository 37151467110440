import { types } from "mobx-state-tree";

import CpuInfo from "api/evo_info/Types/CpuInfo";

const CpuInfosResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(CpuInfo)
});

export default CpuInfosResult;
