import Request from "api/Request";

const GetTrashSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Trash",
    method: "get_trash_settings"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetTrashSettings;
