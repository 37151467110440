const createFields = () => {
    return [
        {
            name: "allow"
        },
        {
            name: "appliedTo"
        },
        {
            name: "changeOwner",
            type: "checkbox"
        },
        {
            name: "changePermissions",
            type: "checkbox"
        },
        {
            name: "traverseFoldersExecuteFile",
            type: "checkbox"
        },
        {
            name: "listFoldersReadData",
            type: "checkbox"
        },
        {
            name: "readAttributes",
            type: "checkbox"
        },
        {
            name: "readExtendedAttributes",
            type: "checkbox"
        },
        {
            name: "readPermissions",
            type: "checkbox"
        },
        {
            name: "createFilesWriteData",
            type: "checkbox"
        },
        {
            name: "createFoldersAppendData",
            type: "checkbox"
        },
        {
            name: "writeAttributes",
            type: "checkbox"
        },
        {
            name: "writeExtendedAttributes",
            type: "checkbox"
        },
        {
            name: "deleteSubdirsAndFiles",
            type: "checkbox"
        },
        {
            name: "delete",
            type: "checkbox"
        },
        {
            name: "onlyForTheFirstChildren",
            type: "checkbox"
        }
    ];
};

export default createFields;
