import { types, flow, getParent } from "mobx-state-tree";
import AuditSettingsType from "api/audit/Responses/AuditSettingsResult";
import AuditShareSettingsType from "api/audit/Responses/GetAuditPershareResult";
import Socket from "websocket";
import GetAuditSettings from "api/audit/Requests/GetAuditSettings";
import SetAuditSettings from "api/audit/Requests/SetAuditSettings";
import DownloadAuthentificationLog from "api/audit/Requests/DownloadAuthenticationLog";
import ClearAuthenticationLog from "api/audit/Requests/ClearAuthenticationLog";
import GetAuditPerShareSettings from "api/audit/Requests/GetAuditPerShareSettings";
import DownloadLog from "api/audit/Requests/DownloadLog";
import ClearLog from "api/audit/Requests/ClearLog";
import EnableAuditOnShares from "api/audit/Requests/EnableAuditOnShares";
import DisableAuditOnShares from "api/audit/Requests/DisableAuditOnShares";
import { ASC, DESC, AUDIT_SHARE_NAME } from "const/sortColumnConst";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import axios from "api/AxiosCommonRequest";
import { FILE_DOWNLOAD_URL } from "api/restRoutes";
import { saveAs } from "file-saver";

const AuditStore = types
    .model({
        auditSettings: types.maybe(AuditSettingsType),
        auditShareSettings: types.maybe(AuditShareSettingsType),
        currentShareName: types.maybeNull(types.string)
    })
    .views(self => ({
        get auditSettingsList() {
            return self.auditSettings?.data || {};
        },
        get auditShareSettingsList() {
            return self.auditShareSettings?.data || {};
        },
        get sortedShares() {
            return (
                (self.auditShareSettingsList.length &&
                    stableSort(self.auditShareSettingsList, getComparator(self.order, self.orderBy))) ||
                []
            );
        },
        get currentShare() {
            return (
                self.auditShareSettingsList?.length &&
                self.auditShareSettingsList.find(share => share.shareName === self.currentShareName)
            );
        }
    }))
    .volatile(() => ({
        orderBy: AUDIT_SHARE_NAME,
        order: ASC
    }))
    .actions(self => ({
        fetchAuditSettings: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetAuditSettings.create().init();
                const res = yield Socket.send(req);
                self.auditSettings = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setAuditSettings: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetAuditSettings.create().init(data);
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        downloadAuthentificationLog: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DownloadAuthentificationLog.create().init();
                const res = yield Socket.send(req);
                self.downloadLogByFilename(res.data.fileName);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        downloadLogByFilename: flow(function*(fileName) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const config = { responseType: "blob", params: { file: fileName } };
                const res = yield axios.get(FILE_DOWNLOAD_URL, config);
                saveAs(new Blob([res.data]), fileName);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        clearAuthentificationLog: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ClearAuthenticationLog.create().init();
                return yield Socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchAuditPerShareSettings: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetAuditPerShareSettings.create().init();
                const res = yield Socket.send(req);
                self.auditShareSettings = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        downloadShareLog: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DownloadLog.create().init(data);
                const res = yield Socket.send(req);
                self.downloadLogByFilename(res.data.fileName);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        clearShareLog: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ClearLog.create().init(data);
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        enableAuditOnShare: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = EnableAuditOnShares.create().init(data);
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        disableAuditOnShare: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DisableAuditOnShares.create().init(data);
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setCurrentShareName: shareName => {
            self.currentShareName = shareName;
        },
        changeSortingShare(column) {
            if (column === self.orderBy) {
                self.order = self.order === DESC ? ASC : DESC;
            } else {
                self.order = ASC;
                self.orderBy = column;
            }
        }
    }));

export default AuditStore;
