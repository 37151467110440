import React, { useEffect } from "react";
import DataCard from "components/DataCard";
import { Grid, ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import EnclosureItem from "./components/EnclosureItem";
import { PHYSICAL_DISKS_CARD } from "const/widgetNameConst";
import ResetIcon from "assets/ResetIcon";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { useStyles } from "./PhysicalDisks.styles.js";
import PhysicalDisksDrawer from "./components/PhysicalDisksDrawer";
import useStoreByIp from "hooks/useStoreByIp";
import HideOverlay from "components/HideOverlay/HideOverlay";

const PhysicalDisks = ({ ip }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { driveStore } = useStoreByIp({ ip });

    const state = useLocalStore(() => ({
        inProgress: false
    }));

    useEffect(() => {
        refreshStatuses();
    }, []);

    const refreshStatuses = async () => {
        state.inProgress = true;
        await driveStore.fetchQuadsStatus();
        state.inProgress = false;
    };

    return useObserver(() => (
        <DataCard
            className={classes.physicalDisksCard}
            title={t("dashboard.physical_disks.title")}
            headerDivider
            headerControl={[
                <HideOverlay show={!ip} key={`physical_disks-control-1`}>
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={PHYSICAL_DISKS_CARD} />
                    </MoreMenu>
                </HideOverlay>,
                <ButtonBase onClick={refreshStatuses} key={`physical_disks-control-2`}>
                    <ResetIcon />
                </ButtonBase>
            ]}
        >
            <>
                {!state.inProgress ? (
                    <Grid container direction="column" spacing={4} wrap="nowrap">
                        {driveStore.enclosures.map(enclosure => (
                            <Grid item key={`enclosure-${enclosure.enclosure}`}>
                                <EnclosureItem enclosure={enclosure} ip={ip} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder inProgress={state.inProgress} />
                )}
                <PhysicalDisksDrawer ip={ip} />
            </>
        </DataCard>
    ));
};

export default PhysicalDisks;
