import { DEFAULT_PROTOCOL, DEFAULT_SECURITY } from "const/addExternalShareConst";
import i18n from "i18n";
import isFieldEmpty from "utils/isFieldEmptyRule";
import isFieldHasOnlySpaces from "utils/isFieldHasOnlySpacesRule";
import minMaxLengthStrValidator from "utils/minMaxLengthStrValidator";
import { ADD_EXTERNAL_SHARE_MAX, ADD_EXTERNAL_SHARE_MIN } from "const/validationConst";
import regexValidator from "utils/regexValidator";

const createFields = () => {
    return [
        {
            name: "name",
            default: "",
            validators: [
                isFieldEmpty(i18n.t("share.remote_shares.add_share.label.name")),
                isFieldHasOnlySpaces(i18n.t("rule.field_has_only_spaces")),
                minMaxLengthStrValidator({ min: ADD_EXTERNAL_SHARE_MIN, max: ADD_EXTERNAL_SHARE_MAX }),
                regexValidator(/^[a-zA-Z0-9_-]*$/, i18n.t("rule.share.name")),
            ],
        },
        {
            name: "protocol",
            default: DEFAULT_PROTOCOL,
        },
        {
            name: "security",
            default: DEFAULT_SECURITY,
        },
        {
            name: "host",
            default: "",
            validators: [
                isFieldEmpty(i18n.t("share.remote_shares.add_share.label.host")),
                isFieldHasOnlySpaces(i18n.t("rule.field_has_only_spaces")),
            ],
        },
        {
            name: "connectAs",
            type: "radio",
            default: "registered",
        },
        {
            name: "connectionMethod",
            type: "radio",
            default: "list",
        },
        {
            name: "user",
            default: "",
            validators: [isFieldEmpty(i18n.t("share.remote_shares.add_share.label.user"))],
        },
        {
            name: "password",
            type: "password",
            default: "",
        },
        {
            name: "share",
            default: "",
        },
    ];
};

export default createFields;
