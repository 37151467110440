import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    buttonContainer: {
        marginTop: theme.spacing(4),
        marginLeft: "auto",
        width: "max-content",
        "&>div": {
            marginLeft: theme.spacing(4)
        }
    }
}));
