import { types } from "mobx-state-tree";

import JobJsonTypes from "../Types/JobJsonTypes";

const GetJobResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(JobJsonTypes, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            }
        })
    )
});

export default GetJobResult;
