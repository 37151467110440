import React from "react";

const SearchIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" {...props}>
            <path
                fill="#BFD1E1"
                fillRule="evenodd"
                d="M22.436 20.898l-3.683-3.673c1.188-1.514 1.833-3.384 1.83-5.308 0-4.787-3.88-8.667-8.666-8.667-4.787 0-8.667 3.88-8.667 8.667 0 4.786 3.88 8.666 8.667 8.666 1.924.003 3.794-.642 5.308-1.83l3.673 3.683c.42.425 1.107.427 1.532.006l.006-.006c.425-.421.427-1.107.006-1.532l-.006-.006zm-17.02-8.981c0-3.59 2.91-6.5 6.5-6.5s6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5-6.5-2.91-6.5-6.5z"
            />
        </svg>
    );
};

export default SearchIcon;
