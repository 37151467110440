import { types } from "mobx-state-tree";

const ActiveDirectorySettings = types.model({
    domain: types.string,
    user: types.string,
    password: types.string,
    enableTrustedDomains: types.boolean
});

export default ActiveDirectorySettings;
