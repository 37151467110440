export default {
    confirmDialogTextForce: {
        paragraphs: [
            {
                textKey: "support.os_data.backups.recovery.confirm_dialog.text_force.message",
                gutterBottom: true
            },
            {
                textKey: "support.os_data.backups.recovery.confirm_dialog.text_force.confirmation_question"
            }
        ]
    }
};
