import React from "react";

const PicLogsIcon = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="80"
            height="80"
            viewBox="0 0 178 168"
            {...props}
        >
            <defs>
                <linearGradient id="picLogsIcon-a" x1="-5.193%" x2="58.105%" y1="17.455%" y2="56.229%">
                    <stop offset="0%" stopColor="#00C5ED" />
                    <stop offset="100%" stopColor="#7080F8" />
                </linearGradient>
                <linearGradient id="picLogsIcon-b" x1="55.945%" x2="26.333%" y1="91.661%" y2="-4.699%">
                    <stop offset="0%" stopColor="#61BCFD" />
                    <stop offset="100%" stopColor="#4EA0FF" />
                </linearGradient>
                <linearGradient id="picLogsIcon-c" x1="65.087%" x2="33.646%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#91C6FF" />
                    <stop offset="100%" stopColor="#62C9FF" />
                </linearGradient>
                <linearGradient id="picLogsIcon-d" x1="13.173%" x2="50%" y1="26.066%" y2="72.222%">
                    <stop offset="0%" stopColor="#74D5FF" />
                    <stop offset="100%" stopColor="#9C7CFF" />
                </linearGradient>
                <linearGradient id="picLogsIcon-f" x1="73.13%" x2="73.13%" y1="-59.559%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.03" />
                    <stop offset="100%" stopColor="#D70FE9" />
                </linearGradient>
                <rect id="e" width="154" height="10" x="12" y="142" rx="5" />
                <linearGradient id="picLogsIcon-g" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#C0E2FF" />
                    <stop offset="100%" stopColor="#E0F4FF" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g>
                    <rect width="138" height="90" x="17" y="49" fill="url(#picLogsIcon-a)" rx="3" />
                    <path fill="#FFF" d="M23 52H156V64H23z" opacity="0.12" />
                    <rect width="118" height="60" x="30" y="71" fill="#FFF" opacity="0.1" rx="2" />
                    <path fill="url(#picLogsIcon-b)" d="M50 16h78a4 4 0 014 4v121H46V20a4 4 0 014-4z" />
                    <path
                        fill="url(#picLogsIcon-c)"
                        fillRule="nonzero"
                        d="M128 16a4 4 0 014 4v121H46V20a4 4 0 014-4h78zm0 4H50v108h78V20z"
                    />
                    <path
                        fill="url(#picLogsIcon-d)"
                        fillRule="nonzero"
                        d="M46 46v6H23v84h132V52h-23v-6h23a6 6 0 015.996 5.775L161 52v84a6 6 0 01-5.775 5.996L155 142H23a6 6 0 01-5.996-5.775L17 136V52a6 6 0 015.775-5.996L23 46h23z"
                    />
                    <rect width="54" height="6" x="58" y="28" fill="#E2FAFF" opacity="0.661" rx="3" />
                    <path
                        fill="#8DDDFF"
                        d="M85.5 114a1.5 1.5 0 010 3h-14a1.5 1.5 0 010-3h14zm17 0a1.5 1.5 0 010 3h-12a1.5 1.5 0 010-3h12zm-26-8a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3h5zm19 0a1.5 1.5 0 010 3h-14a1.5 1.5 0 010-3h14zm19 0a1.5 1.5 0 010 3h-14a1.5 1.5 0 010-3h14zm-21-8a1.5 1.5 0 010 3h-22a1.5 1.5 0 010-3h22zm20 0a1.5 1.5 0 010 3h-13a1.5 1.5 0 010-3h13zm-4-8a1.5 1.5 0 010 3h-38a1.5 1.5 0 010-3h38zm-9-8a1.5 1.5 0 010 3h-29a1.5 1.5 0 010-3h29zm-15-8a1.5 1.5 0 010 3h-14a1.5 1.5 0 010-3h14zm17 0a1.5 1.5 0 010 3h-12a1.5 1.5 0 010-3h12zm-26-8a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3h5zm19 0a1.5 1.5 0 010 3h-14a1.5 1.5 0 010-3h14zm19 0a1.5 1.5 0 010 3h-14a1.5 1.5 0 010-3h14zm-21-8a1.5 1.5 0 010 3h-22a1.5 1.5 0 010-3h22zm11 0a1.5 1.5 0 010 3h-6a1.5 1.5 0 010-3h6zm-21-8a1.5 1.5 0 010 3h-12a1.5 1.5 0 010-3h12zm35 0a1.5 1.5 0 010 3h-30a1.5 1.5 0 010-3h30zm-9-8a1.5 1.5 0 010 3h-38a1.5 1.5 0 010-3h38z"
                        opacity="0.7"
                    />
                    <path
                        fill="#8DDDFF"
                        d="M64.5 114a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3h5zm0-8a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3h5zm0-8a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3h5zm0-8a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3h5zm0-8a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3h5zm0-8a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3h5zm0-8a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3h5zm0-8a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3h5zm0-8a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3h5zm0-8a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3h5z"
                        opacity="0.4"
                    />
                    <g>
                        <use fill="#00D4FF" xlinkHref="#e" />
                        <use fill="url(#picLogsIcon-f)" fillOpacity="0.57" xlinkHref="#e" />
                    </g>
                    <circle cx="23" cy="18" r="6" stroke="url(#picLogsIcon-g)" strokeWidth="4" opacity="0.3" />
                    <circle cx="147" cy="26" r="4" fill="#C0E2FF" opacity="0.3" />
                </g>
            </g>
        </svg>
    );
};

export default PicLogsIcon;
