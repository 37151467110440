import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import { useStyles } from "./Subdirectory.styles";
import Select from "components/MobxForm/Select";
import { useTranslation } from "react-i18next";
import Radio from "components/Radio";
import RadioContainer from "../RadioContainer";
import clsx from "clsx";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const SUBDIRECTORY_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_subdirectory`;

const Subdirectory = ({ variantField, newSubdirectory, existSubdirectory, existingOptions }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isDisabled = value => {
        return value !== variantField.value;
    };

    return useObserver(() => (
        <RadioContainer
            label={<Typography>{t("share.create_share.subdirectory")}</Typography>}
            field={variantField}
            className={classes.container}
        >
            <Grid xs={12} item>
                <Radio
                    value={"create"}
                    label={t("share.create_share.create_subdirectory")}
                    id={`${SUBDIRECTORY_ID_PREFIX}_create_subdirectory`}
                />
            </Grid>
            <Grid className={classes.textInputRow} xs={12} item>
                <TextInput
                    className={classes.input}
                    field={newSubdirectory}
                    disabled={isDisabled("create")}
                    id={`${SUBDIRECTORY_ID_PREFIX}_new_subdirectory`}
                />
            </Grid>
            <Grid xs={12} item>
                <Radio
                    disabled={!existingOptions || existingOptions.length === 0}
                    value={"existing"}
                    label={t("share.create_share.use_subdirectory")}
                    id={`${SUBDIRECTORY_ID_PREFIX}_use_subdirectory`}
                />
            </Grid>
            <Grid className={clsx(classes.textInputRow, classes.lastRow)} xs={12} item>
                <Select
                    options={existingOptions}
                    field={existSubdirectory}
                    disabled={isDisabled("existing") || !existingOptions || existingOptions.length === 0}
                    id={`${SUBDIRECTORY_ID_PREFIX}_existing_subdirectory`}
                />
            </Grid>
        </RadioContainer>
    ));
};

export default Subdirectory;
