import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import createFields from "./createFields";
import { useLocalStore } from "mobx-react";
import createForm from "utils/createForm";
import { Button, Grid } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import isIpv4Rule from "utils/isIpv4Rule";
import { useObserver } from "mobx-react-lite";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";

const SetupDnsDialog = ({ closeDialog, open }) => {
    const { t } = useTranslation();
    const {
        store: { dnsStore }
    } = useStore();

    const fields = createFields(dnsStore);
    const state = useLocalStore(() => ({
        form: createForm({ fields, rules: isIpv4Rule }),
        isSubmitted: false
    }));

    const unsavedChangesModal = useModal();

    useEffect(() => {
        state.form.$("primary").set("default", dnsStore.primary);
        state.form.$("secondary").set("default", dnsStore.secondary);
        state.form.reset();
    }, []);

    const updateForm = () => {
        state.form.$("primary").set("default", dnsStore.primary);
        state.form.$("secondary").set("default", dnsStore.secondary);
        state.form.reset();
        state.isSubmitted = false;
        unsavedChangesModal.close();
    };
    const submit = async e => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        const isSubmitted = await dnsStore.setDnsServers({
            primary: state.form.$("primary").value,
            secondary: state.form.$("secondary").value
        });
        state.form.$("primary").set("default", state.form.$("primary").value);
        state.form.$("secondary").set("default", state.form.$("secondary").value);
        isSubmitted && (state.isSubmitted = true);
        return isSubmitted;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            closeDialog();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            title={t("network.dns.setup_dialog.title")}
            submitBtnLabel={t("common.button.save")}
            open={open}
            onSubmit={submit}
            onEnter={updateForm}
            onClose={closeIfNeeded}
            buttons={
                <Button onClick={closeDialog} variant={"contained"} color="secondary">
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"}>
                <RowTextInput label={t("network.dns.label.primary")} control={<TextInput field={state.form.$("primary")} />} />
                <RowTextInput
                    label={t("network.dns.label.secondary")}
                    control={<TextInput field={state.form.$("secondary")} />}
                />
            </Grid>
            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default SetupDnsDialog;
