import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    helper: {
        marginTop: theme.spacing(-2),
        marginBottom: theme.spacing(3.5),
        opacity: 0.4
    },
    multipleCheckboxContainer: {
        marginRight: "auto"
    }
}));
