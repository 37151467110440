import React from "react";

const TimeIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#BFD1E1"
                fillRule="evenodd"
                d="M12 2l.28.004C17.673 2.152 22 6.57 22 12l-.004.28C21.848 17.673 17.43 22 12 22 6.477 22 2 17.523 2 12S6.477 2 12 2zm0 2c-4.418 0-8 3.582-8 8s3.582 8 8 8l.25-.004C16.551 19.865 20 16.335 20 12l-.004-.25C19.865 7.449 16.335 4 12 4zm0 3c.552 0 1 .448 1 1v3h3c.552 0 1 .448 1 1s-.448 1-1 1h-4c-.552 0-1-.448-1-1V8c0-.552.448-1 1-1z"
            />
        </svg>
    );
};

export default TimeIcon;
