import React, { useContext } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Grid, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useStyles } from "./ComponentInstallDialog.styles";
import UpgradeIcon from "assets/UpgradeIcon";
import DragAndDropFile from "components/DragAndDropFile";
import Dialog from "components/Dialog";
import { Context } from "store";
import FileProgressUpload from "./components/FileProgressUpload/FileProgressUpload";

const UPGRADE_SYSTEM_DESTINATION = "lun";

const ComponentInstallDialog = ({ onClose, open }) => {
    const state = useLocalStore(() => ({
        file: null,
        loading: false,
    }));
    const {
        store: { uploadStore, systemComponentsStore },
    } = useContext(Context);
    const { t } = useTranslation();
    const classes = useStyles();

    const handleFiles = (files) => {
        state.file = files[0];
    };

    const onSubmit = async () => {
        state.loading = true;

        const uploadedFile = await uploadStore.upload(state.file, UPGRADE_SYSTEM_DESTINATION);

        if (uploadedFile) {
            const upgraded = await systemComponentsStore.upgradeSystemComponent(uploadedFile.name);
            if (upgraded) {
                onClose(upgraded);
            } else {
                state.file = null;
            }
        }

        state.loading = false;

        return uploadedFile;
    };

    const onEnter = () => {
        state.file = null;
    };

    const onCloseDialog = () => {
        !state.loading && onClose(false);
    };

    return useObserver(() => (
        <Dialog
            buttons={[
                <Button
                    key={"cancelBtn"}
                    onClick={onCloseDialog}
                    variant={"contained"}
                    color={"secondary"}
                    disabled={state.loading}
                >
                    {t("common.button.cancel")}
                </Button>,
            ]}
            open={open}
            onEnter={onEnter}
            onSubmit={onSubmit}
            disableSubmit={state.loading}
            submitBtnLabel={t("support.start.component_install.upload_file_dialog.button.upload")}
            title={t("support.start.component_install.upload_file_dialog.title", {
                component: systemComponentsStore.currentComponentName ? systemComponentsStore.currentComponentName : "",
            })}
            onClose={onCloseDialog}
            disableBackdropClick={state.loading}
            disableEscapeKeyDown={state.loading}
            withoutIconClose={state.loading}
        >
            <Grid container className={classes.contentContainer}>
                {state.loading && <FileProgressUpload />}
                {state.file && !state.loading && (
                    <Grid container wrap={"nowrap"} className={classes.fileContainer}>
                        <Grid item className={classes.icon}>
                            <UpgradeIcon />
                        </Grid>
                        <Grid item container justify={"flex-start"} alignItems={"center"}>
                            <Typography>{state.file.name}</Typography>
                        </Grid>
                    </Grid>
                )}
                {!state.file && !state.loading && (
                    <Grid item container>
                        <DragAndDropFile handleFiles={handleFiles} inputProps=".gpg" />
                    </Grid>
                )}
            </Grid>
        </Dialog>
    ));
};

export default ComponentInstallDialog;
