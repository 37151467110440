import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    outerContainer: {
        width: "100%",
        maxHeight: "65vh"
    },
    contentContainer: {
        padding: theme.spacing(6),
        maxHeight: "100%",
        overflowY: "auto",
        "& p": {
            maxWidth: "100%",
            whiteSpace: "pre-line"
        },
        "& pre": {
            margin: theme.spacing(0, 0, 6)
        }
    }
}));
