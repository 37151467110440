import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(4, 6, 6, 4),
        backgroundColor: hexToRgba(theme.palette.quickstartStepCard.background, 0.07),
        borderRadius: theme.spacing(1),
        border: theme.palette.quickstartStepCard.border,
    },
    titleContainer: {
        width: "100%",
        margin: 0
    },
    counter: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderRadius: "50%",
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.2),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: theme.spacing(2)
    },
    countText: {
        fontSize: 16,
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: 0.2,
        textAlign: "center"
    },
    cardBody: {
        "& p": {
            paddingLeft: theme.spacing(10)
        }
    }
}));
