import React, { useContext } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import { useStyles } from "./ImportSignedCSR.styles";
import DragAndDropFile from "components/DragAndDropFile";
import { Context } from "store";
import ShieldWithKeyIcon from "assets/ShieldWithKeyIcon";
import Dialog from "components/Dialog";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const IMPORT_SIGNED_CERTIFICATE_MODAL_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_import_signed_certificate_modal`;

const ImportCertificateModal = ({ open, onClose, onBack }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { certificateStore },
    } = useContext(Context);

    const state = useLocalStore(() => ({
        currentFile: null,
        isEnableHttpsDialogOpen: false,
        inProgress: false,
        isSubmitted: false,
    }));

    const onFilesSelect = (files) => {
        state.currentFile = files[0];
    };

    const resolveSubmitCondition = () => {
        state.isSubmitted = true;
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    };

    const onSubmit = async () => {
        state.inProgress = true;
        const res = await certificateStore.uploadFile(state.currentFile);
        state.inProgress = false;

        if (res) {
            await resolveSubmitCondition();
        } else {
            state.currentFile = null;
        }

        return res;
    };

    const onEnter = () => {
        state.currentFile = null;
        state.isEnableHttpsDialogOpen = false;
        state.isSubmitted = false;
        state.inProgress = false;
    };

    return useObserver(() => (
        <>
            <Dialog
                open={open}
                buttons={[
                    <Button
                        key={"cancelBtn"}
                        onClick={onBack}
                        variant={"contained"}
                        color={"secondary"}
                        id={`${IMPORT_SIGNED_CERTIFICATE_MODAL_ID_PREFIX}_cancel`}
                    >
                        {t("common.button.back")}
                    </Button>,
                ]}
                onEnter={onEnter}
                inProgress={state.inProgress}
                onSubmit={onSubmit}
                disableSubmit={!state.currentFile}
                submitBtnLabel={t("common.button.import")}
                submitBtnId={`${IMPORT_SIGNED_CERTIFICATE_MODAL_ID_PREFIX}_import`}
                title={t("admin_settings.server_certificate.import_signed_certificate.title")}
                onClose={onClose}
            >
                <Grid container className={classes.contentContainer}>
                    {state.currentFile ? (
                        <Grid container wrap={"nowrap"} className={classes.certContainer}>
                            <Grid item className={classes.sshIcon}>
                                <ShieldWithKeyIcon />
                            </Grid>
                            <Grid item container justify={"flex-start"} alignItems={"center"}>
                                <Typography className={classes.certNameText}>{state.currentFile.name}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item container>
                            <DragAndDropFile
                                title={t("admin_settings.server_certificate.import_certificate.drag_and_drop.placeholder")}
                                handleFiles={onFilesSelect}
                                id={`${IMPORT_SIGNED_CERTIFICATE_MODAL_ID_PREFIX}_drag_and_drop`}
                            />
                        </Grid>
                    )}
                </Grid>
            </Dialog>
        </>
    ));
};

export default ImportCertificateModal;
