import { types } from "mobx-state-tree";
import DecomposedBaseUrlType from "./DecomposedBaseUrlType";
import SecretType from "./SecretType";

const AliasType = types.model({
    id: types.maybe(types.number),
    name: types.maybe(types.string),
    decomposed_base_url: types.maybe(DecomposedBaseUrlType),
    secret: types.maybe(SecretType),
    created: types.maybe(types.number),
    modified: types.maybe(types.number),
    personal: types.maybe(types.boolean),
    internal: types.maybe(types.boolean),
    editable: types.maybe(types.boolean),
    removable: types.maybe(types.boolean),
    status: types.maybe(types.string),
    extvolume_uuid: types.maybe(types.string),
    share_mappings: types.maybe(
        types.model({
            list: types.maybe(
                types.array(
                    types.model({
                        share: types.maybe(types.string),
                        drive: types.maybe(types.string),
                    })
                )
            ),
        })
    ),
});
export default AliasType;
