import { makeStyles } from "@material-ui/core/styles";
import { SEARCH_PLACEHOLDER_COLOR, USERS_SEARCH_SELECT_WIDTH } from "const/styleConst";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => ({
    input: {
        "& .MuiOutlinedInput-adornedEnd": {
            paddingRight: theme.spacing(2),
        },
    },
    inputsGroup: {
        width: "100%",
        margin: 0,
        padding: theme.spacing(5, 5, 7, 5),
    },
    closeIcon: {
        "& svg path": {
            fill: hexToRgba(SEARCH_PLACEHOLDER_COLOR, 0.8),
        },
    },
    primaryColorIcon: {
        "& svg path": {
            fill: theme.palette.primary.main,
        },
    },
    searchIconContainer: {
        width: theme.spacing(7),
    },
    selectItem: {
        padding: theme.spacing(1, 3),
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.searchInlinePopover.hover,
        },
    },
    selectPaper: {
        width: USERS_SEARCH_SELECT_WIDTH,
        backgroundColor: theme.palette.searchInlinePopover.background,
        borderRadius: theme.spacing(1),
    },
    selectIcon: {
        flexShrink: 0,
        marginRight: theme.spacing(2),
    },
    inputContainer: {
        width: USERS_SEARCH_SELECT_WIDTH,
    },
    emptyResponse: {
        padding: theme.spacing(4, 3),
    },
    emptyResponseText: {
        opacity: 0.6,
    },
    pagination: {
        "& .MuiTablePagination-toolbar": {
            height: "initial",
            paddingRight: 0,
        },
    },
    dropdownIcon: {
        transform: ({ isSelectOpen }) => isSelectOpen && "rotate(180deg)",
    },
}));
