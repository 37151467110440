import { types } from "mobx-state-tree";

const FilesEvent = types.model({
    id: types.number,
    timeStamp: types.string,
    shareName: types.string,
    user: types.string,
    action: types.string,
    path: types.string
});

export default FilesEvent;
