import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    headerContainer: {
        padding: theme.spacing(5, 6, 0, 6)
    },
    controlsContainer: {
        width: "100%",
        margin: 0,
        padding: theme.spacing(2, 4)
    },
    stepperContainer: {
        padding: theme.spacing(4, 6, 6, 6)
    }
}));
