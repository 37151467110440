import React from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { useStyles } from "./NotReadyOverlay.styles";
import LockIcon from "assets/LockIcon";
import { Grid, Typography, ButtonBase } from "@material-ui/core";
import clsx from "clsx";

const NotReadyOverlay = ({ children, fullHeight, ...props }) => {
    const classes = useStyles();
    const state = useLocalStore(() => ({
        isHide: false
    }));
    const hideOverlay = () => {
        state.isHide = true;
    };
    return useObserver(() => {
        return !state.isHide ? (
            <Grid className={clsx(classes.container, fullHeight && classes.fullHeight)} {...props}>
                <Grid container className={classes.overlay} alignItems={"center"} justify={"center"} spacing={6}>
                    <Grid item>
                        <LockIcon width={50} height={50} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} variant={"h2"} color={"textSecondary"}>
                            NOT READY YET
                        </Typography>
                    </Grid>
                </Grid>
                {children}
                <Grid className={classes.hideButton}>
                    <ButtonBase onClick={hideOverlay}>
                        <Typography>Hide overlay</Typography>
                    </ButtonBase>
                </Grid>
            </Grid>
        ) : (
            children
        );
    });
};

export default NotReadyOverlay;
