import React, { useEffect, Suspense } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, ButtonBase } from "@material-ui/core";
import { useObserver, useLocalStore } from "mobx-react";
import Switch from "components/MobxForm/Switch";
import TextInput from "components/MobxForm/TextInput";
import Select from "components/MobxForm/Select";
import { useStyles } from "./SyncDialog.styles";
import RowTextInput from "components/RowTextInput";
import { useTranslation } from "react-i18next";
import createAdFields from "./createAdFields";
import createLdapFields from "./createLdapFields";
import createForm from "utils/createForm";
import { CONNECTED, CONNECTING, NOT_CONNECTED, LDAP, AD, ENCRYPTION_OPTIONS } from "const/ldapStatuses";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import CloseIcon from "assets/CloseIcon";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";

const ConfirmationDialog = React.lazy(() => import("components/ConfirmationDialog"));

const SYNC_AD_LDAP_DIALOG_ID_PREFIX = "sync_ad_ldap_dialog";

const SyncDialog = ({ open, onClose, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${SYNC_AD_LDAP_DIALOG_ID_PREFIX}` : SYNC_AD_LDAP_DIALOG_ID_PREFIX;
    const { t } = useTranslation();
    const adFields = createAdFields();
    const ldapFields = createLdapFields();
    const {
        store: { userStore },
    } = useStore();

    const state = useLocalStore(() => ({
        activeTab: AD,
        adForm: createForm({
            fields: adFields,
        }),
        ldapForm: createForm({
            fields: ldapFields,
        }),
        inProgress: false,
        isSubmitted: false,
        isWarningResetAD: false,
        advancedExpanded: false,
        activeIsWarningResetAD() {
            this.isWarningResetAD = true;
        },
        confirmResetAD() {
            this.isWarningResetAD = false;
        },
        notConfirmResetAD() {
            this.isWarningResetAD = false;
            this.adForm.$("resetIdMap").reset();
        },
        get disabledLdapFields() {
            return !state.ldapForm.$("enabled").value && !userStore.ldapStatus?.enabled;
        },
        get disabledAdFields() {
            return (
                (!state.adForm.$("enabled").value && !userStore.activeDirectoryStatus?.enabled) ||
                userStore.activeDirectoryStatus?.enabled
            );
        },
        get disabledSaveButton() {
            return (this.activeTab === LDAP && this.ldapForm.isDefault) || (this.activeTab === AD && this.adForm.isDefault);
        },
        get styleStatus() {
            return this.activeTab === AD ? userStore.activeDirectoryStatus?.status : userStore.ldapStatus?.status;
        },
    }));

    const unsavedChangesModal = useModal();

    const classes = useStyles({ status: state.styleStatus });
    useEffect(() => {
        state.adForm.reset();
        state.ldapForm.reset();
    }, []);

    const onModalEnter = () => {
        state.inProgress = false;
        state.isSubmitted = false;
        state.ldapForm.$("enabled").set("default", userStore.ldapStatus.enabled);
        state.ldapForm.$("host").set("default", userStore.ldapStatus.settings.host);
        state.ldapForm.$("baseDN").set("default", userStore.ldapStatus.settings.baseDN);
        state.ldapForm.$("encryption").set("default", userStore.ldapStatus.settings.encryption);
        state.ldapForm.$("sambaMode").set("default", userStore.ldapStatus.settings.sambaMode);
        state.ldapForm.$("sambaSID").set("default", userStore.ldapStatus.sambaSID);
        state.ldapForm.$("bindDN").set("default", userStore.ldapStatus.settings.bindDN);
        state.ldapForm.$("bindPassword").set("default", userStore.ldapStatus.settings.bindPassword);
        state.ldapForm.$("userSuffix").set("default", userStore.ldapStatus.settings.userSuffix);
        state.ldapForm.$("groupSuffix").set("default", userStore.ldapStatus.settings.groupSuffix);
        state.ldapForm
            .$("jumpcloudGroupsEnabled")
            .set("default", userStore.ldapStatus.enabled && userStore.ldapStatus.settings.jumpcloudGroupsEnabled);
        state.ldapForm.reset();

        state.adForm.$("enabled").set("default", userStore.activeDirectoryStatus.enabled);
        state.adForm.$("domain").set("default", userStore.activeDirectoryStatus.settings.domain);
        state.adForm.$("user").set("default", userStore.activeDirectoryStatus.settings.user);
        state.adForm.$("password").set("default", userStore.activeDirectoryStatus.settings.password);
        state.adForm.$("enableTrustedDomains").set("default", userStore.activeDirectoryStatus.settings.enableTrustedDomains);
        state.adForm.reset();
        setActiveTab(userStore.currentSyncMode?.mode || AD)();
        unsavedChangesModal.close();
    };

    const setActiveTab = (tab) => () => {
        state.activeTab = tab;
    };

    const tabVariant = (value) => {
        return value === state.activeTab ? "contained" : "outlined";
    };

    const enableLdap = async () => {
        const payload = {
            host: state.ldapForm.$("host").value,
            baseDN: state.ldapForm.$("baseDN").value,
            encryption: state.ldapForm.$("encryption").value,
            sambaMode: state.ldapForm.$("sambaMode").value,
            bindDN: state.ldapForm.$("bindDN").value,
            bindPassword: state.ldapForm.$("bindPassword").value,
            userSuffix: state.ldapForm.$("userSuffix").value,
            groupSuffix: state.ldapForm.$("groupSuffix").value,
            jumpcloudGroupsEnabled: state.ldapForm.$("jumpcloudGroupsEnabled").value,
        };
        state.inProgress = true;
        const res = await userStore.enableLdap(payload);
        if (res) {
            state.isSubmitted = true;
            setTimeout(onClose, 1000);
        }
        state.inProgress = false;
    };
    const enableAd = async () => {
        const payload = {
            domain: state.adForm.$("domain").value,
            user: state.adForm.$("user").value,
            password: state.adForm.$("password").value,
            enableTrustedDomains: state.adForm.$("enableTrustedDomains").value,
        };
        state.inProgress = true;
        const res = await userStore.enableActiveDirectory(payload);
        if (res) {
            state.isSubmitted = true;
            setTimeout(onClose, 1000);
        }
        state.inProgress = false;
    };

    const domainToUpperCase = (e) => {
        state.adForm.$("domain").set(e.target.value.toString().toUpperCase());
    };

    const disableLdap = async () => {
        const payload = {
            resetAcl: state.ldapForm.$("resetAcl").value,
        };
        state.inProgress = true;
        const res = await userStore.disableLdap(payload);
        if (res) {
            state.isSubmitted = true;
            setTimeout(onClose, 1000);
        }
        state.inProgress = false;
    };
    const disableAd = async () => {
        const payload = {
            resetIdMap: state.adForm.$("resetIdMap").value,
            resetAcl: state.adForm.$("resetAcl").value,
            force: state.adForm.$("force").value,
        };
        state.inProgress = true;
        const res = await userStore.disableActiveDirectory(payload);
        if (res) {
            state.isSubmitted = true;
            setTimeout(onClose, 1000);
        }
        state.inProgress = false;
    };

    const onSave = () => {
        if (state.activeTab === LDAP) {
            state.ldapForm.$("enabled").value ? enableLdap() : disableLdap();
        } else {
            state.adForm.$("enabled").value ? enableAd() : disableAd();
        }
    };

    const getStatusLabel = (status) => {
        switch (status) {
            case CONNECTED:
                return t("users.sync_modal.status.connected");
            case CONNECTING:
                return t("users.sync_modal.status.connecting");
            case NOT_CONNECTED:
                return t("users.sync_modal.status.not_connected");
            default:
                return t("users.sync_modal.status.unknown");
        }
    };

    const onKeyDown = (e) => {
        e.key === "Escape" && onClose();
        e.key === "Enter" && !state.disabledSaveButton && onSave();
    };

    const closeIfNeeded = () => {
        if (state.ldapForm.isDefault && state.adForm.isDefault) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog onKeyDown={onKeyDown} scroll={"body"} fullWidth onClose={closeIfNeeded} open={open} onEnter={onModalEnter}>
            <DialogTitle className={classes.titleContainer}>
                <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container>
                    <Grid item>{t("users.sync_modal.title")}</Grid>
                    <Grid item>
                        <CardHeaderControlButton onClick={closeIfNeeded}>
                            <ButtonBase>
                                <CloseIcon />
                            </ButtonBase>
                        </CardHeaderControlButton>
                    </Grid>
                </Grid>
                <Grid container className={classes.titleControls}>
                    <Grid item className={classes.controlsItem}>
                        <Button
                            onClick={setActiveTab(AD)}
                            color={"primary"}
                            variant={tabVariant(AD)}
                            size={"small"}
                            id={`${idPrefix}_tab_ad`}
                        >
                            {t("users.sync_modal.tab_name.ad")}
                        </Button>
                    </Grid>
                    <Grid item className={classes.controlsItem}>
                        <Button
                            onClick={setActiveTab(LDAP)}
                            color={"primary"}
                            variant={tabVariant(LDAP)}
                            size={"small"}
                            id={`${idPrefix}_tab_ldap`}
                        >
                            {t("users.sync_modal.tab_name.ldap")}
                        </Button>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers className={classes.contentContainer}>
                <Grid direction={"column"} container>
                    {state.activeTab === LDAP ? (
                        <>
                            <RowControlLabelSwitch
                                control={<Switch field={state.ldapForm.$("enabled")} id={`${idPrefix}_ldap_enabled_ldap`} />}
                                label={t("users.sync_modal.label.enable_ldap")}
                            />
                            <Grid
                                container
                                wrap={"nowrap"}
                                alignItems={"center"}
                                justify={"space-between"}
                                className={classes.statusRow}
                            >
                                <Grid item xs={6}>
                                    <Typography>{t("users.sync_modal.label.status")}</Typography>
                                </Grid>
                                <Grid item container xs={6} alignItems={"center"} justify={"flex-start"}>
                                    <Grid item className={classes.statusContainer}>
                                        <Typography className={classes.statusText}>
                                            {getStatusLabel(userStore.ldapStatus?.status)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <RowTextInput
                                control={
                                    <TextInput
                                        disabled={state.disabledLdapFields}
                                        field={state.ldapForm.$("host")}
                                        id={`${idPrefix}_ldap_host`}
                                    />
                                }
                                label={t("users.sync_modal.label.host")}
                            />
                            <RowTextInput
                                control={
                                    <TextInput
                                        disabled={state.disabledLdapFields}
                                        field={state.ldapForm.$("baseDN")}
                                        id={`${idPrefix}_ldap_base_dn`}
                                    />
                                }
                                label={t("users.sync_modal.label.base_dn")}
                            />
                            <RowTextInput
                                control={
                                    <Select
                                        disabled={state.disabledLdapFields}
                                        field={state.ldapForm.$("encryption")}
                                        options={Object.values(ENCRYPTION_OPTIONS)}
                                        id={`${idPrefix}_ldap_encryption`}
                                    />
                                }
                                label={t("users.sync_modal.label.encryption")}
                            />
                            <RowTextInput
                                control={
                                    <Select
                                        disabled={state.disabledLdapFields}
                                        field={state.ldapForm.$("sambaMode")}
                                        options={[{ value: "samba_schema", label: "Samba.schema" }]}
                                        id={`${idPrefix}_ldap_samba_mode`}
                                    />
                                }
                                label={t("users.sync_modal.label.samba_mode")}
                            />
                            <RowTextInput
                                control={
                                    <TextInput
                                        disabled
                                        nonEditable
                                        field={state.ldapForm.$("sambaSID")}
                                        id={`${idPrefix}_ldap_samba_sid`}
                                    />
                                }
                                label={t("users.sync_modal.label.samba_sid")}
                            />
                            <RowTextInput
                                control={
                                    <TextInput
                                        disabled={state.disabledLdapFields}
                                        field={state.ldapForm.$("bindDN")}
                                        id={`${idPrefix}_ldap_bind_dn`}
                                    />
                                }
                                label={t("users.sync_modal.label.bind_dn")}
                            />
                            <RowTextInput
                                control={
                                    <TextInput
                                        disabled={state.disabledLdapFields}
                                        field={state.ldapForm.$("bindPassword")}
                                        id={`${idPrefix}_ldap_bind_password`}
                                    />
                                }
                                label={t("users.sync_modal.label.bind_password")}
                            />
                            {!state.ldapForm.$("enabled").value && userStore.ldapStatus?.enabled && (
                                <>
                                    <RowControlLabelSwitch
                                        control={
                                            <Switch field={state.ldapForm.$("resetAcl")} id={`${idPrefix}_ldap_reset_acl`} />
                                        }
                                        label={t("users.sync_modal.label.reset_acl")}
                                    />
                                </>
                            )}
                            <RowControlLabelSwitch
                                control={<Switch field={state.ldapForm.$("jumpcloudGroupsEnabled")} />}
                                label={t("users.sync_modal.label.enable_support_for_jumpcloud_groups")}
                            />
                            <RowTextInput
                                label={t("users.sync.ldap_user_suffix")}
                                control={<TextInput field={state.ldapForm.$("userSuffix")} id={`${idPrefix}_ldap_user_suffix`} />}
                            />
                            <RowTextInput
                                label={t("users.sync.ldap_group_suffix")}
                                control={
                                    <TextInput field={state.ldapForm.$("groupSuffix")} id={`${idPrefix}_ldap_group_suffix`} />
                                }
                            />
                        </>
                    ) : (
                        <>
                            <RowControlLabelSwitch
                                control={<Switch field={state.adForm.$("enabled")} id={`${idPrefix}_ad_enabled_ad`} />}
                                label={t("users.sync_modal.label.enable_ad")}
                            />
                            <Grid
                                container
                                wrap={"nowrap"}
                                alignItems={"center"}
                                justify={"space-between"}
                                className={classes.statusRow}
                            >
                                <Grid item xs={6}>
                                    <Typography>{t("users.sync_modal.label.status")}</Typography>
                                </Grid>
                                <Grid item container xs={6} alignItems={"center"} justify={"flex-start"}>
                                    <Grid item className={classes.statusContainer}>
                                        <Typography className={classes.statusText}>
                                            {getStatusLabel(userStore.activeDirectoryStatus?.status)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <RowTextInput
                                control={
                                    <TextInput
                                        onChange={domainToUpperCase}
                                        disabled={state.disabledAdFields}
                                        field={state.adForm.$("domain")}
                                        id={`${idPrefix}_ad_domain`}
                                    />
                                }
                                label={t("users.sync_modal.label.domain")}
                            />
                            <RowTextInput
                                control={
                                    <TextInput
                                        disabled={state.disabledAdFields}
                                        field={state.adForm.$("user")}
                                        id={`${idPrefix}_ad_user`}
                                    />
                                }
                                label={t("users.sync_modal.label.user")}
                            />
                            <RowTextInput
                                control={
                                    <TextInput
                                        disabled={state.disabledAdFields}
                                        field={state.adForm.$("password")}
                                        id={`${idPrefix}_ad_password`}
                                    />
                                }
                                label={t("users.sync_modal.label.password")}
                            />
                            <RowControlLabelSwitch
                                control={
                                    <Switch
                                        disabled={state.disabledAdFields}
                                        field={state.adForm.$("enableTrustedDomains")}
                                        id={`${idPrefix}_ad_enable_trusted_domains`}
                                    />
                                }
                                label={t("users.sync_modal.label.enable_trusted_domains")}
                            />

                            {!state.adForm.$("enabled").value && userStore.activeDirectoryStatus?.enabled && (
                                <>
                                    <RowControlLabelSwitch
                                        control={<Switch field={state.adForm.$("resetAcl")} id={`${idPrefix}_ad_reset_acl`} />}
                                        label={t("users.sync_modal.label.reset_acl")}
                                    />
                                    <RowControlLabelSwitch
                                        control={
                                            <Switch
                                                field={state.adForm.$("resetIdMap")}
                                                onClick={() => {
                                                    if (!state.adForm.$("resetIdMap").value) state.activeIsWarningResetAD();
                                                    state.adForm
                                                        .$("resetIdMap")
                                                        .set("value", !state.adForm.$("resetIdMap").value);
                                                }}
                                                id={`${idPrefix}_ad_reset_id_map`}
                                            />
                                        }
                                        label={t("users.sync_modal.label.reset_ad_mapping")}
                                    />
                                    <RowControlLabelSwitch
                                        control={<Switch field={state.adForm.$("force")} id={`${idPrefix}_ad_forse`} />}
                                        label={t("users.sync_modal.label.force")}
                                    />
                                </>
                            )}
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="secondary" id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
                <AnimatedSubmitButton
                    label={t("common.button.save")}
                    submit={onSave}
                    disabled={state.disabledSaveButton}
                    isSubmitted={state.isSubmitted}
                    inProgress={state.inProgress}
                    id={`${idPrefix}_save`}
                />
            </DialogActions>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            <Suspense fallback={null}>
                <ConfirmationDialog
                    title={t("users.sync_modal.confirm_reset_ad.title")}
                    open={state.isWarningResetAD}
                    onClose={state.notConfirmResetAD}
                    onConfirm={state.confirmResetAD}
                    confirmLabel={t("users.sync_modal.confirm_reset_ad.confirm_button.label")}
                    id={`${idPrefix}_confirmation_dialog`}
                >
                    <Grid container direction={"column"} spacing={5}>
                        <Grid item>
                            <Typography>{t("users.sync_modal.confirm_reset_ad.text")}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{t("common.text.continue")}</Typography>
                        </Grid>
                    </Grid>
                </ConfirmationDialog>
            </Suspense>
        </Dialog>
    ));
};

export default SyncDialog;
