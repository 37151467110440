import React from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { ButtonBase, Menu, Box } from "@material-ui/core";
import { useStyles } from "./UserMenu.styles";
import ChevronDownIcon from "assets/ChevronDownIcon";

const UserMenu = ({ menuProps, title, children, ...rest }) => {
    const state = useLocalStore(() => ({
        anchorEl: null,
    }));
    const classes = useStyles({ isOpen: state.anchorEl !== null });
    const handleClick = (e) => {
        state.anchorEl = e.currentTarget;
        e.stopPropagation();
    };
    const handleClose = (e) => {
        state.anchorEl = null;
        e.stopPropagation();
    };
    return useObserver(() => (
        <>
            <ButtonBase onClick={handleClick} className={classes.menu} {...rest}>
                {title}
                <Box className={classes.iconContainer}>
                    <ChevronDownIcon />
                </Box>
            </ButtonBase>
            <Menu
                className={classes.menuPaper}
                onClick={handleClose}
                anchorOrigin={{
                    vertical: 40,
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                getContentAnchorEl={null}
                anchorEl={state.anchorEl}
                open={!!state.anchorEl}
                onClose={handleClose}
                {...menuProps}
            >
                {children}
            </Menu>
        </>
    ));
};

export default UserMenu;
