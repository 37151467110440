import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(4, 6),
        width: "100%",
        "&:hover": {
            backgroundColor: theme.palette.grey["100"],
            "& span": {
                color: props => (props.error ? theme.palette.error.light : theme.palette.primary.light)
            },
            "& svg path": {
                fill: props => (props.error ? theme.palette.error.light : theme.palette.primary.light)
            }
        }
    },
    icon: {
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(4),
        "& svg path": {
            fill: theme.palette.primary.main
        }
    },
    iconErrorColor: {
        "& svg path": {
            fill: theme.palette.error.main
        }
    },
    content: {
        display: "block",
        textAlign: "left"
    }
}));
