import { types, flow, getParent, getSnapshot } from "mobx-state-tree";
import MainSocket from "websocket";
import GetSharesRootAccess from "api/security/acl/Requests/GetSharesRootAccess";
import SetSharesRootAccess from "api/security/acl/Requests/SetSharesRootAccess";
import GetSharesRootAccessType from "api/security/acl/Responses/GetShareRootAccessResult";
import GetAcl from "api/security/acl/Requests/GetAcl";
import SetAcl from "api/security/acl/Requests/SetAcl";
import GetAclResult from "api/security/acl/Responses/GetAclResult";
import GetUsbAclResult from "api/security/acl/Responses/GetUsbAclResult";
import GetOwner from "api/security/acl/Requests/GetOwner";
import SetOwner from "api/security/acl/Requests/SetOwner";
import GetOwnerResult from "api/security/acl/Responses/GetOwnerResult";
import GetAllowedHosts from "api/security/acl/Requests/GetAllowedHosts";
import GetAllowedHostsResult from "api/security/acl/Responses/GetAllowedHostsResult";
import SetAllowedHosts from "api/security/acl/Requests/SetAllowedHosts";
import { ASC, DESC, SHARE_NAME, USERS_DOMAIN, USERS_LIST_GROUP_MEMBERS_NAME, USERS_NAME } from "const/sortColumnConst";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import GetUsbAcl from "api/security/acl/Requests/GetUsbAcl";
import SetUsbAcl from "api/security/acl/Requests/SetUsbAcl";
import GetAllowedUsersGroups from "api/security/acl/Requests/GetAllowedUsersGroups";
import SetAllowedUsersGroups from "api/security/acl/Requests/SetAllowedUsersGroups";
import GetAllowedUsersGroupsResult from "api/security/acl/Responses/GetAllowedUsersGroupsResult";
import UserRootAccess from "api/security/acl/Types/UserRootAccess";
import SetUserAccess from "api/security/acl/Requests/SetUserAccess";
import { LOCAL_USERS } from "../../const/userSearchVariants";
import { NONE } from "const/shareRootAccessConst";
import { getEvoPrefix } from "../../utils/helpers/getEvoPrefix";

const AclStore = types
    .model({
        sharesRootAccessStore: types.maybeNull(GetSharesRootAccessType),
        usersRootAccessArray: types.array(UserRootAccess),
        aclStore: types.maybeNull(GetAclResult),
        usbAclStore: types.maybe(GetUsbAclResult),
        initialUsbAclStore: types.maybe(GetUsbAclResult),
        ownerStore: types.maybe(GetOwnerResult),
        allowedHostsStore: types.maybe(GetAllowedHostsResult),
        allowedUsersGroupsStore: types.maybe(GetAllowedUsersGroupsResult),
        currentUserType: types.maybe(types.string),
    })
    .volatile(() => ({
        initialRootAccessSnapshot: null,
        currentShareName: null,
        isPermissionsDefault: true,
        currentAce: null,
        currentUsbAce: null,
        groupMembersOrderBy: USERS_LIST_GROUP_MEMBERS_NAME,
        groupMembersOrder: ASC,
        selectedAllowedUsersGroups: null,
    }))
    .views((self) => ({
        get sharesRootAccess() {
            return (
                (self.sharesRootAccessStore && stableSort(self.sharesRootAccessStore.data, getComparator(ASC, SHARE_NAME))) || []
            );
        },
        get acl() {
            return self.aclStore && self.aclStore.data;
        },
        get usbAcl() {
            return self.usbAclStore && self.usbAclStore.data;
        },
        get sortedUsbAcl() {
            return (
                (self.usbAcl &&
                    stableSort(
                        self.usbAcl,
                        getComparator(ASC, self.currentUserType === LOCAL_USERS ? USERS_NAME : USERS_DOMAIN)
                    )) ||
                []
            );
        },
        get isSharesRootAccessDefault() {
            return !self.initialRootAccessSnapshot?.some((initShare) =>
                self.sharesRootAccessStore?.data.some(
                    (share) => share.shareName === initShare.shareName && share.access !== initShare.access
                )
            );
        },
        get hasUsbAclChanges() {
            let hasChanges = false;
            let usbAclStore = self.usbAclStore?.data;
            let initialUsbAclStore = self.initialUsbAclStore?.data;

            if (usbAclStore && initialUsbAclStore) {
                if (usbAclStore.length !== initialUsbAclStore.length) {
                    hasChanges = true;
                } else {
                    hasChanges = usbAclStore.some(({ access }, idx) => access !== initialUsbAclStore[idx].access);
                }
            }

            return hasChanges;
        },
        get owner() {
            return self.ownerStore && self.ownerStore.data;
        },
        get allowedHosts() {
            return self.allowedHostsStore?.data || [];
        },
        get allowedUsersGroups() {
            return self.allowedUsersGroupsStore?.data || [];
        },
        get sortedAllowedUsersGroups() {
            return (
                self.selectedAllowedUsersGroups &&
                stableSort(self.selectedAllowedUsersGroups, getComparator(self.groupMembersOrder, self.groupMembersOrderBy))
            );
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
        get currentShareNameWithPrefix() {
            const { ip, name, evoSettingsStore } = getParent(self);

            const prefix = getEvoPrefix({ ip, evoName: name, hostname: evoSettingsStore?.evoInfo?.hostname, store: self });

            return self.currentShareName ? `${prefix}${self.currentShareName}` : null;
        },
    }))
    .actions((self) => ({
        fetchSharesRootAccess: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetSharesRootAccess.create().init(data);
                const res = yield self.socket.send(req);
                self.sharesRootAccessStore = res;
                self.initialRootAccessSnapshot = getSnapshot(self.sharesRootAccessStore.data);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        getAcl: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetAcl.create().init(data);
                const res = yield self.socket.send(req);
                self.aclStore = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        getUsbAcl: flow(function* (data) {
            self.currentUserType = data.userType;

            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);
                const req = GetUsbAcl.create().init(data);
                const res = yield self.socket.send(req);
                self.usbAclStore = res;
                self.initialUsbAclStore = getSnapshot(self.usbAclStore);
                return res;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setAcl: flow(function* ({ path, replaceChildrenAcl, useInheritedPermissions, shareName }) {
            const { processingStore } = getParent(self);
            try {
                const payload = {
                    shareName: shareName || self.currentShareName,
                    path,
                    replaceChildrenAcl,
                    useInheritedPermissions: useInheritedPermissions || self.acl.useInheritedPermissions,
                    aces: getSnapshot(self.acl.aces),
                };
                processingStore.setLoading(true);
                const req = SetAcl.create().init(payload);
                const res = yield self.socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setUsbAcl: flow(function* ({ type }) {
            const { processingStore } = getParent(self);
            try {
                const payload = {
                    type,
                    acl: getSnapshot(self.usbAcl),
                };
                processingStore.setLoading(true);
                const req = SetUsbAcl.create().init(payload);
                const res = yield self.socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setSharesRootAccess: flow(function* (domainObj) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const payload = {
                    user: domainObj,
                    permissions: getSnapshot(self.sharesRootAccessStore.data),
                };
                const req = SetSharesRootAccess.create().init(payload);
                yield self.socket.send(req);
                self.initialRootAccessSnapshot = getSnapshot(self.sharesRootAccessStore.data);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getOwner: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetOwner.create().init(data);
                const res = yield self.socket.send(req);
                self.ownerStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setOwner: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetOwner.create().init(data);
                yield self.socket.send(req);
                self.getOwner({ shareName: data.shareName, path: data.path });
                return true;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchAllowedHosts: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetAllowedHosts.create().init(data);
                const res = yield self.socket.send(req);
                self.allowedHostsStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setAllowedHosts: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetAllowedHosts.create().init(data);
                const res = yield self.socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchAllowedUsersGroups: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetAllowedUsersGroups.create().init(data);
                const res = yield self.socket.send(req);
                self.allowedUsersGroupsStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setAllowedUsersGroups: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetAllowedUsersGroups.create().init(data);
                const res = yield self.socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        modifyUsersOrGroupsAccess: flow(function* (data) {
            const { shareName, permissions } = data;
            const { processingStore } = getParent(self);

            const payload = {
                shareName,
                permissions,
            };

            try {
                processingStore.setLoading(true);
                const req = SetUserAccess.create().init(payload);
                const res = yield self.socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        resetSharesRootAccess() {
            self.sharesRootAccessStore = null;
        },
        setCurrentAce: (ace) => {
            self.currentAce = ace;
        },
        setCurrentUsbAce: (ace) => {
            self.currentUsbAce = ace;
        },
        removeAce: (ace) => {
            self.acl.aces.remove(ace);
            self.isPermissionsDefault = false;
        },
        removeUsbAce: (ace) => {
            self.usbAcl.remove(ace);
            self.isPermissionsDefault = false;
        },
        updateCurrentAce: (field, value) => {
            self.currentAce !== null && (self.currentAce[field] = value);
            self.isPermissionsDefault = false;
        },
        updateCurrentUsbAce: (field, value) => {
            self.currentUsbAce !== null && (self.currentUsbAce[field] = value);
            self.isPermissionsDefault = false;
        },
        updateAclUseInheritedPermission: (value) => {
            if (self.acl) {
                self.acl.useInheritedPermissions = value;
                self.isPermissionsDefault = false;
            }
        },
        convertInheritedAcesIntoExplicit: () => {
            if (self.acl) {
                self.acl.aces.forEach((ace) => (ace.isInherited = false));
                self.isPermissionsDefault = false;
            }
        },
        removeInheritedAces: () => {
            if (self.acl) {
                self.acl.aces.replace(self.acl.aces.filter((ace) => ace.isInherited === false));
                self.isPermissionsDefault = false;
            }
        },
        createAce: (ace) => {
            ace.allow ? self.acl.aces.push(ace) : self.acl.aces.unshift(ace);
            self.isPermissionsDefault = false;
        },
        createUsbAce: (ace) => {
            ace.allow ? self.usbAcl.push(ace) : self.usbAcl.unshift(ace);
            self.isPermissionsDefault = false;
        },
        updateIsPermissionsDefault: (value) => {
            self.isPermissionsDefault = value;
        },
        setCurrentShareName: (shareName) => {
            self.currentShareName = shareName;
        },
        setNewPermission: (shareName, access) => {
            const foundShare = self.sharesRootAccessStore.data.find((share) => share.shareName === shareName);
            foundShare && (foundShare.access = access);
        },
        setNewPermissionToAllShares: (access, options) => {
            self.sharesRootAccessStore.data.forEach((share) => {
                if (options.resetPermission) {
                    if (options.column === share.access || share.access === NONE) share.access = access;
                    return;
                }

                share.access = access;
            });
        },
        groupMembersChangeSorting(column) {
            if (column === self.groupMembersOrderBy) {
                self.groupMembersOrder = self.groupMembersOrder === DESC ? ASC : DESC;
            } else {
                self.groupMembersOrder = ASC;
                self.groupMembersOrderBy = column;
            }
        },
        setSelectedAllowedUsersGroups: (selectedUsers) => {
            self.selectedAllowedUsersGroups = selectedUsers;
        },
    }));

export default AclStore;
