import React from "react";

const FilesIcon = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-980.000000, -256.000000)" fill="#1E90FF">
                    <g transform="translate(980.000000, 256.000000)">
                        <path
                            d="M21,11.35 L21,11.3500003 C20.9765852,10.9707173 20.74022,10.6374803 20.39,10.4900003 L18.24,9.57000027 L20.5,8.27000027 L20.5000001,8.27000023 C20.8267321,8.08207123 21.0200371,7.72639023 21.0000001,7.35000023 L21.0000001,7.3500005 C20.9765853,6.9707175 20.7402201,6.6374805 20.3900001,6.4900005 L12.3900001,3.0800005 L12.39,3.08000047 C12.140718,2.97442047 11.859282,2.97442047 11.61,3.08000054 L3.61000001,6.49000054 L3.61000003,6.49000053 C3.25978103,6.63748053 3.02341503,6.97071753 3.00000003,7.35000053 L3.00000005,7.35000023 C2.97996275,7.72639023 3.17326805,8.08207123 3.50000005,8.27000023 L5.76000005,9.57000023 L3.61000005,10.4900002 L3.61000007,10.4900002 C3.25978107,10.6374802 3.02341507,10.9707172 3.00000007,11.3500002 L3.00000009,11.3499999 C2.97996279,11.7263899 3.17326809,12.0820709 3.50000009,12.2699999 L5.76000009,13.5699999 L3.61000009,14.4899999 L3.61000011,14.4899999 C3.25978111,14.6374799 3.02341511,14.9707169 3.00000011,15.3499999 L3.00000012,15.3499996 C2.97996282,15.7263896 3.17326812,16.0820706 3.50000012,16.2699996 L11.5000001,20.8699996 L11.5000001,20.8699996 C11.8094011,21.0486326 12.1905991,21.0486326 12.5000001,20.8699997 L20.5000001,16.2699997 L20.5000001,16.2699996 C20.8267321,16.0820706 21.0200371,15.7263896 21.0000001,15.3499996 L21.0000002,15.3499999 C20.9765854,14.9707169 20.7402202,14.6374799 20.3900002,14.4899999 L18.2400002,13.5699999 L20.5000002,12.2699999 L20.5000002,12.2699999 C20.8267322,12.0820709 21.0200372,11.7263899 21.0000002,11.3499999 L21,11.35 Z M12,5.09 L17.76,7.54 L12,10.85 L6.24,7.54 L12,5.09 Z M11.5,12.87 L11.4999999,12.87 C11.8094009,13.048633 12.1905989,13.048633 12.4999999,12.87 L16.0699999,10.87 L17.7599999,11.59 L11.9999999,14.85 L6.23999995,11.54 L7.92999995,10.82 L11.5,12.87 Z M17.76,15.54 L12,18.85 L6.24,15.54 L7.93,14.82 L11.5,16.87 L11.4999999,16.87 C11.8094009,17.048633 12.1905989,17.048633 12.4999999,16.87 L16.0699999,14.82 L17.76,15.54 Z"
                            id="Fill"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default FilesIcon;
