import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "store";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Grid, Typography } from "@material-ui/core";
import DataReadonlyCard from "components/DataReadonlyCard";
import SavedInputPreview from "components/SavedInputPreview";
import EthernetBondingParametersDialog from "../EthernetBondingParametersDialog";

const EthernetBondingParametersCard = () => {
    const { t } = useTranslation();
    const {
        store: { bondsStore }
    } = useContext(Context);
    const state = useLocalStore(() => ({
        dialog: false
    }));
    useEffect(() => {
        bondsStore.fetchBondingParameters();
    }, []);

    const hashPolicyOptions = value => {
        switch (value) {
            case "layer2":
                return t("advanced.ethernet_bonding_parameters.mac");
            case "layer2+3":
                return t("advanced.ethernet_bonding_parameters.mac_ip");
            default:
                return "";
        }
    };
    const openDialog = () => {
        state.dialog = true;
    };
    const closeDialog = () => {
        state.dialog = false;
    };
    return useObserver(() => (
        <>
            <EthernetBondingParametersDialog closeDialog={closeDialog} open={state.dialog} />
            <DataReadonlyCard
                autoHeight
                headerDivider
                title={t("advanced.ethernet_bonding_parameters.title")}
                onClick={openDialog}
            >
                <Grid container direction={"column"} spacing={5}>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("advanced.ethernet_bonding_parameters.bonding_mode")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{bondsStore.bondingParameters?.mode}</SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("advanced.ethernet_bonding_parameters.hash_policy")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>
                                {hashPolicyOptions(bondsStore.bondingParameters?.xmitHashPolicy)}
                            </SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("advanced.ethernet_bonding_parameters.lacp_rate")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{bondsStore.bondingParameters?.lacpRate}</SavedInputPreview>
                        </Grid>
                    </Grid>
                </Grid>
            </DataReadonlyCard>
        </>
    ));
};

export default EthernetBondingParametersCard;
