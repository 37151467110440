import { types } from "mobx-state-tree";

import DomainObjectPage from "api/security/users/Types/DomainObjectPage";

const SearchDomainObjectResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(DomainObjectPage)
});

export default SearchDomainObjectResult;
