import { useEffect } from "react";

/**
 * Custom hook to reset form values to default, in some cases.
 * @description
 * The first argument - pass the form objects.
 *
 * The second argument - pass a states object, where property name is field name in form and value of this property is state, if state is FALSE field will be reset to default state.
 *
 * The third argument - pass a boolean value, if it is FALSE the second argument will not be checked and reset will not be executed. If you don't pass it on, the hook will always run.
 *
 *
 * @param {object} form - form object of mobx-state-form.
 * @param {Object<string, boolean>} fieldCondition - object where each property name points to the name of the field in the form, and the value is responsible for the state.
 * @param {boolean} isFormReady - optional boolean value which, if set to false, blocks the process of resetting the field value. Only boolean.
 * @example
 *  useResetFormField(state.form, {
 *    ["accessType"]: state.form.$("protocolValue").value !== "internal"
 *  });
 */
export const useResetFormField = (form, fieldCondition, isFormReady) => {
    const conditions = Object.values(fieldCondition);

    useEffect(() => {
        if (typeof isFormReady === "boolean" && !isFormReady) return;

        Object.entries(fieldCondition).forEach(([field, condition]) => {
            if (!condition) {
                form.$(field).reset();
                form.$(field).resetValidation();
            }
        });
    }, conditions);
};
