import Request from "api/Request";

const CommitFirewallRules = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Firewall",
    method: "commit_firewall_rules"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default CommitFirewallRules;
