import { types } from "mobx-state-tree";

import UpgradeStep from "api/upgrade/Types/UpgradeStep";

const UpgradeStepResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(UpgradeStep)
});

export default UpgradeStepResult;
