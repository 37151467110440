import React from "react";
import { Grid } from "@material-ui/core";
import Replications from "../Replications";

const REPLICATIONS_TAB_CONTENT_ID_PREFIX = `replications_tab_content`;

const ReplicationsTabContent = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${REPLICATIONS_TAB_CONTENT_ID_PREFIX}` : REPLICATIONS_TAB_CONTENT_ID_PREFIX;

    return (
        <Grid item xs={12}>
            <Replications parentId={idPrefix} />
        </Grid>
    );
};

export default ReplicationsTabContent;
