import { types } from "mobx-state-tree";

import DomainObject from "api/security/Types/DomainObject";

const DomainObjectsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(DomainObject)
});

export default DomainObjectsResult;
