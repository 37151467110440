/**
 *
 * @param {*} response - any mock data for response
 * @param {*} timeout - time delay
 * @returns
 */
export const debugDelay = (response = true, timeout = 5000) =>
    new Promise((resolve, reject) =>
        setTimeout(() => {
            try {
                if (typeof response === "function") {
                    resolve(response());
                    return;
                }
                resolve(response);
            } catch (error) {
                reject(error);
            }
        }, timeout)
    );
