import React from "react";

const Logo = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="84" height="20" viewBox="0 0 84 20" {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M49.0981456,0 L46.5146992,4.86193018 L35.941,4.861 L40.849,15.027 L43.702,15.027 L50.8204432,0 L56.8484848,0 L47.6463087,20 L36.9523861,20 L27.7753754,0 L49.0981456,0 Z M26.3030303,7.5 L26.3030303,12.4107143 L4.822,12.41 L4.822,15.089 L26.3030303,15.0892857 L26.3030303,20 L0,20 L0,7.5 L26.3030303,7.5 Z M84,0 L84,20 L57.6969697,20 L57.6969697,0 L84,0 Z M79.177,5.185 L62.519,5.185 L62.519,14.981 L79.177,14.981 L79.177,5.185 Z M76.8166967,11.333333 C77.4607519,11.333333 77.9904833,11.8209775 78.0541839,12.4547346 L78.0606061,12.583333 C78.0606061,13.2736889 77.499086,13.833333 76.8166967,13.833333 L71.6681518,13.833333 C71.0240966,13.833333 70.4943652,13.3456885 70.4306646,12.7119314 L70.4242424,12.583333 C70.4242424,11.8929771 70.9857625,11.333333 71.6681518,11.333333 L76.8166967,11.333333 Z M26.3030303,0 L26.3030303,5 L0,5 L0,0 L26.3030303,0 Z"
                fill="#FFFFFF"
            ></path>
        </g>
    </svg>
);

export default Logo;
