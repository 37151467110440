import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react";
import TextInput from "components/MobxForm/TextInput";
import { useTranslation } from "react-i18next";
import Autocomplete from "components/MobxForm/Autocomplete";
import { COUNTRY_CODES } from "const/sslCertificateCountryCodesConst";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const FORM_LAYOUT_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_form_layout`;

const FormLayout = ({ form }) => {
    const { t } = useTranslation();

    return useObserver(() => (
        <Grid container direction={"column"} spacing={2}>
            <Grid item container alignItems={"center"} wrap={"nowrap"}>
                <Grid xs={5} item container alignItems={"center"} justify={"flex-start"}>
                    <Typography>
                        {t("admin_settings.server_certificate.create_self_signed_certificate.input.label.common_name")}
                    </Typography>
                </Grid>
                <Grid xs={7} item>
                    <TextInput field={form.$("commonName")} id={`${FORM_LAYOUT_ID_PREFIX}_common_name`} />
                </Grid>
            </Grid>
            <Grid item container alignItems={"center"} wrap={"nowrap"}>
                <Grid xs={5} item container alignItems={"center"} justify={"flex-start"}>
                    <Typography>
                        {t("admin_settings.server_certificate.create_self_signed_certificate.input.label.email")}
                    </Typography>
                </Grid>
                <Grid xs={7} item>
                    <TextInput field={form.$("email")} id={`${FORM_LAYOUT_ID_PREFIX}_email`} />
                </Grid>
            </Grid>
            <Grid item container alignItems={"center"} wrap={"nowrap"}>
                <Grid xs={5} item container alignItems={"center"} justify={"flex-start"}>
                    <Typography>
                        {t("admin_settings.server_certificate.create_self_signed_certificate.input.label.country")}
                    </Typography>
                </Grid>
                <Grid xs={7} item>
                    <Autocomplete
                        field={form.$("country")}
                        options={COUNTRY_CODES}
                        getOptionLabel={option => option.label || ""}
                        renderOption={option => option.label || ""}
                        id={`${FORM_LAYOUT_ID_PREFIX}_country`}
                    />
                </Grid>
            </Grid>
            <Grid item container alignItems={"center"} wrap={"nowrap"}>
                <Grid xs={5} item container alignItems={"center"} justify={"flex-start"}>
                    <Typography>
                        {t("admin_settings.server_certificate.create_self_signed_certificate.input.label.state")}
                    </Typography>
                </Grid>
                <Grid xs={7} item>
                    <TextInput field={form.$("state")} id={`${FORM_LAYOUT_ID_PREFIX}_state`} />
                </Grid>
            </Grid>
            <Grid item container alignItems={"center"} wrap={"nowrap"}>
                <Grid xs={5} item container alignItems={"center"} justify={"flex-start"}>
                    <Typography>
                        {t("admin_settings.server_certificate.create_self_signed_certificate.input.label.city")}
                    </Typography>
                </Grid>
                <Grid xs={7} item>
                    <TextInput field={form.$("city")} id={`${FORM_LAYOUT_ID_PREFIX}_city`} />
                </Grid>
            </Grid>
            <Grid item container alignItems={"center"} wrap={"nowrap"}>
                <Grid xs={5} item container alignItems={"center"} justify={"flex-start"}>
                    <Typography>
                        {t("admin_settings.server_certificate.create_self_signed_certificate.input.label.organization")}
                    </Typography>
                </Grid>
                <Grid xs={7} item>
                    <TextInput field={form.$("organization")} id={`${FORM_LAYOUT_ID_PREFIX}_organization`} />
                </Grid>
            </Grid>
            <Grid item container alignItems={"center"} wrap={"nowrap"}>
                <Grid xs={5} item container alignItems={"center"} justify={"flex-start"}>
                    <Typography>
                        {t("admin_settings.server_certificate.create_self_signed_certificate.input.label.department")}
                    </Typography>
                </Grid>
                <Grid xs={7} item>
                    <TextInput field={form.$("department")} id={`${FORM_LAYOUT_ID_PREFIX}_department`} />
                </Grid>
            </Grid>
        </Grid>
    ));
};

export default FormLayout;
