import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        paddingLeft: theme.spacing(2),
    },
    userPic: {
        marginRight: theme.spacing(4),
    },
    userName: {
        "&&": {
            fontSize: 16,
            opacity: 1,
            fontWeight: 500,
            lineHeight: 1.25,
            letterSpacing: 1,
            marginRight: theme.spacing(2),
        },
    },
    switchLabel: {
        marginLeft: 0,
        "&>.MuiFormControlLabel-label": {
            opacity: 1,
        },
    },
    divider: {
        margin: theme.spacing(1, 4),
    },
    linkItem: {
        textDecoration: "none",
    },
    textItem: {
        marginLeft: theme.spacing(2),
    },
    logoutIcon: {
        color: theme.palette.grey[300],
    },
    version: {
        fontSize: 12,
        textAlign: "center",
    },
}));
