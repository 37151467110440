import React from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./ReplicationJobsDrawer.styles";
import StatusLabel from "./components/StatusLabel";
import getSlingshotSchedule from "utils/getSlingshotSchedule";
import DisableIndicator from "assets/DisableIndicator";
import EditIcon from "assets/EditIcon";
import RunIcon from "assets/RunIcon";
import ViewIcon from "assets/ViewIcon";
import TrashIcon from "assets/TrashIcon";
import CloseIcon from "assets/CloseIcon";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import EditWarningDialog from "./components/EditWarningDialog";
import EditJobModal from "../EditJobModal";
import JobInstancesModal from "../JobInstancesModal";
import JobSummaryModal from "../JobSummaryModal";
import { JOB_STATUS } from "const/replicationsJobsConst";
import { useModal } from "hooks/useModal";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useStore } from "hooks/useStore";
import { useInstancesModal } from "hooks/modals";
import { useModalHistory } from "hooks/useModalHistory";

const REPLACTION_JOB_DRAWER_ID_PREFIX = `replication_job_drawer`;

const drawerName = "replicationJobsDrawer";
const ReplicationJobsDrawer = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${REPLACTION_JOB_DRAWER_ID_PREFIX}` : REPLACTION_JOB_DRAWER_ID_PREFIX;
    const {
        store: { slingshotSettingsStore, slingshotStorageStore },
    } = useStore();
    const { t } = useTranslation();
    const classes = useStyles();
    const modalHistory = useModalHistory();
    const instancesModal = useInstancesModal({ handleOpen: modalHistory.reset });

    const state = useLocalStore(() => ({
        isEnableDisableInProgress: false,
        isRemoveInProgress: false,
        isGetSummaryInProgress: false,
        isGetJobInProgress: false,
        isJobViewOnly: false,
        isEditWarningDialogOpen: false,
        isEditJobModalOpen: false,
        isEditJobModalOpenProgress: false,
        isGetInstancesInProgress: false,
        isJobSummaryModalOpen: false,
    }));

    const {
        store: { syncJobsStore, evoSettingsStore },
    } = useStore();
    const deleteJobModal = useModal();

    const closeDrawer = () => {
        syncJobsStore.setCurrentJobId(null);
    };

    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: syncJobsStore.currentJobId });

    const runNow = async () => {
        const enableRes = await syncJobsStore.performJobCommand({ jobId: syncJobsStore.currentJobId, cmd: "enable" });
        const runRes = await syncJobsStore.performJobCommand({ jobId: syncJobsStore.currentJobId, cmd: "run" });

        enableRes && runRes && closeDrawer();
    };

    const stopRun = async () => {
        const enableRes = await syncJobsStore.performJobCommand({ jobId: syncJobsStore.currentJobId, cmd: "enable" });
        const cancelRes = await syncJobsStore.performJobCommand({ jobId: syncJobsStore.currentJobId, cmd: "cancel" });

        enableRes && cancelRes && closeDrawer();
    };

    const enableJob = async () => {
        state.isEnableDisableInProgress = true;
        const res = await syncJobsStore.performJobCommand({ jobId: syncJobsStore.currentJobId, cmd: "enable" });
        state.isEnableDisableInProgress = false;

        res && closeDrawer();
    };
    const disableJob = async () => {
        state.isEnableDisableInProgress = true;
        const res = await syncJobsStore.performJobCommand({ jobId: syncJobsStore.currentJobId, cmd: "cancel_disable" });
        state.isEnableDisableInProgress = false;

        res && closeDrawer();
    };

    const removeJob = async () => {
        deleteJobModal.close();
        state.isRemoveInProgress = true;
        const res = await syncJobsStore.removeJob(syncJobsStore.currentJobId);
        res && closeDrawer();
        state.isRemoveInProgress = false;
    };

    const getJobSummary = async () => {
        state.isGetSummaryInProgress = true;
        await syncJobsStore.getJobSummary(syncJobsStore.currentJobId);
        state.isGetSummaryInProgress = false;
        openJobSummaryModal();
    };

    const getCurrentEditableJob = async () => {
        state.isGetJobInProgress = true;
        const res = await syncJobsStore.getCurrentEditableJob();
        state.isGetJobInProgress = false;
        return res;
    };

    const getInstances = async () => {
        state.isGetInstancesInProgress = true;
        const res = await syncJobsStore.getInstances(syncJobsStore.currentJobId);
        state.isGetInstancesInProgress = false;
        if (res) instancesModal.open();
    };

    const openEditJobModal = async () => {
        state.isEditJobModalOpenProgress = true;
        const res = await getCurrentEditableJob();
        await slingshotSettingsStore.fetchAliases();
        await slingshotStorageStore.fetchShares();
        state.isEditJobModalOpenProgress = false;

        if (res) state.isEditJobModalOpen = true;
    };

    const openEditWarningDialogIfNeeded = () => {
        if (syncJobsStore.currentJob?.enabled) {
            state.isEditWarningDialogOpen = true;
            return;
        }

        openEditJobModal();
    };

    const onDisableAndEditClick = async () => {
        disableJob();

        closeEditWarningDialog();

        openEditJobModal();
    };

    const openJobSummaryModal = () => {
        state.isJobSummaryModalOpen = true;
    };
    const closeJobSummaryModal = () => {
        state.isJobSummaryModalOpen = false;
    };

    const onViewOnlyClick = async () => {
        closeEditWarningDialog();
        state.isJobViewOnly = true;
        const res = await getCurrentEditableJob();
        if (res) {
            state.isEditJobModalOpen = true;
        }
    };

    const closeEditWarningDialog = () => {
        state.isEditWarningDialogOpen = false;
    };

    const closeEditJobModal = () => {
        state.isEditJobModalOpen = false;
        state.isJobViewOnly = false;
    };

    const renderDetails = () => {
        return syncJobsStore.currentJob ? (
            <Grid container direction={"column"} className={classes.detailsContainer} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("slingshot_page.tabs.replications.table.header.label.status")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {<StatusLabel status={syncJobsStore.currentJob.status} />}
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("slingshot_page.tabs.replications.table.header.label.source")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {decodeURIComponent(syncJobsStore.currentJob.source)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("slingshot_page.tabs.replications.table.header.label.owner")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {syncJobsStore.currentJob.owner || t("common.label.unknown")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("slingshot_page.tabs.replications.table.header.label.destination")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {decodeURIComponent(syncJobsStore.currentJob.destination)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("slingshot_page.tabs.replications.table.header.label.method")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{syncJobsStore.currentJob.method}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("slingshot_page.tabs.replications.table.header.label.schedule")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {getSlingshotSchedule(syncJobsStore.currentJob, evoSettingsStore.evoInfoTime)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        ) : null;
    };
    const renderActions = () => {
        return syncJobsStore.currentJob ? (
            <Grid direction={"column"} container>
                {syncJobsStore.currentJob.enabled ? (
                    <BulkBarFlatButton
                        className={classes.disableIconButton}
                        onClick={disableJob}
                        inProgress={state.isEnableDisableInProgress}
                        icon={<DisableIndicator />}
                        id={`${idPrefix}_disable_replication_job`}
                    >
                        {t("slingshot_page.tabs.replications.drawer.actions.button.disable")}
                    </BulkBarFlatButton>
                ) : (
                    <BulkBarFlatButton
                        className={classes.enableIconButton}
                        onClick={enableJob}
                        inProgress={state.isEnableDisableInProgress}
                        icon={<DisableIndicator />}
                        id={`${idPrefix}_enable_replication_job`}
                    >
                        {t("slingshot_page.tabs.replications.drawer.actions.button.enable")}
                    </BulkBarFlatButton>
                )}
                <BulkBarFlatButton
                    icon={<EditIcon />}
                    onClick={openEditWarningDialogIfNeeded}
                    inProgress={state.isGetJobInProgress || state.isEditJobModalOpenProgress}
                    id={`${idPrefix}_edit_replication_job`}
                >
                    {t("slingshot_page.tabs.replications.drawer.actions.button.edit")}
                </BulkBarFlatButton>
                {syncJobsStore.currentJob.status !== JOB_STATUS.active && syncJobsStore.serviceState?.master_switch && (
                    <BulkBarFlatButton icon={<RunIcon />} onClick={runNow} id={`${idPrefix}_run_replication_job`}>
                        {t("slingshot_page.tabs.replications.drawer.actions.button.run_now")}
                    </BulkBarFlatButton>
                )}
                {syncJobsStore.currentJob.status === JOB_STATUS.active && (
                    <BulkBarFlatButton icon={<CloseIcon />} onClick={stopRun} id={`${idPrefix}_stop_replication_job`}>
                        {t("slingshot_page.tabs.replications.drawer.actions.button.stop_run")}
                    </BulkBarFlatButton>
                )}
                <BulkBarFlatButton
                    icon={<ViewIcon />}
                    onClick={getJobSummary}
                    inProgress={state.isGetSummaryInProgress}
                    id={`${idPrefix}_get_replication_job_summary`}
                >
                    {t("slingshot_page.tabs.replications.drawer.actions.button.last_job_summary")}
                </BulkBarFlatButton>
                <BulkBarFlatButton
                    icon={<ViewIcon />}
                    onClick={getInstances}
                    inProgress={state.isGetInstancesInProgress}
                    id={`${idPrefix}_get_replication_job_instances`}
                >
                    {t("slingshot_page.tabs.replications.drawer.actions.button.instances")}
                </BulkBarFlatButton>
                <BulkBarFlatButton
                    icon={<TrashIcon />}
                    onClick={deleteJobModal.open}
                    inProgress={state.isRemoveInProgress}
                    errorColor
                    id={`${idPrefix}_delete_replication_job`}
                >
                    {t("common.button.delete")}
                </BulkBarFlatButton>
            </Grid>
        ) : null;
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            title={syncJobsStore.currentJob?.name}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            id={`${idPrefix}_drawer`}
            modals={
                <>
                    <EditWarningDialog
                        open={state.isEditWarningDialogOpen}
                        onClose={closeEditWarningDialog}
                        onDisableAndEdit={onDisableAndEditClick}
                        onViewOnly={onViewOnlyClick}
                        parentId={idPrefix}
                    />
                    <EditJobModal
                        afterSubmitCallback={closeDrawer}
                        open={state.isEditJobModalOpen}
                        onClose={closeEditJobModal}
                        viewOnly={state.isJobViewOnly}
                        parentId={idPrefix}
                    />
                    <JobSummaryModal open={state.isJobSummaryModalOpen} onClose={closeJobSummaryModal} parentId={idPrefix} />
                    <JobInstancesModal parentId={idPrefix} />
                    <ConfirmationDialog
                        title={t("common.warning_dialog.title")}
                        open={deleteJobModal.isOpen}
                        onClose={deleteJobModal.close}
                        onConfirm={removeJob}
                        confirmLabel={t("common.button.proceed")}
                        cancelLabel={t("common.button.cancel")}
                        fullWidth={true}
                        id={`${idPrefix}_confirmation_dialog`}
                    >
                        {t("slingshot_page.tabs.replications.drawer.confirm_delete")}
                    </ConfirmationDialog>
                </>
            }
        />
    ));
};

export default ReplicationJobsDrawer;
