import { types } from "mobx-state-tree";

import TemperatureSensor from "api/evo_info/Types/TemperatureSensor";
import FanSensor from "api/evo_info/Types/FanSensor";

const SersorsData = types.model({
    temperatureSensors: types.array(TemperatureSensor),
    fanSensors: types.array(FanSensor)
});

export default SersorsData;
