import React from "react";
import RadioContainer from "../RadioContainer";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Radio from "components/Radio";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const ACCESS_TYPE_CONTROLLER_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_access_type_controller`;

const AccessTypeController = ({ rwDisabled, rwrdDisabled, ...rest }) => {
    const { t } = useTranslation();

    return (
        <Grid item>
            <RadioContainer {...rest} label={<Typography>{t("share.create_share.access_type")}</Typography>}>
                <Radio
                    label={t("share.create_share.read_write")}
                    value="rw"
                    id={`${ACCESS_TYPE_CONTROLLER_ID_PREFIX}_rw`}
                />
                <Radio
                    disabled={rwDisabled}
                    label={`${t("share.create_share.read_write")}/${t("share.create_share.reject_delete")}`}
                    value={"rw_rd"}
                    helperText={t("share.create_share.smb_only")}
                    id={`${ACCESS_TYPE_CONTROLLER_ID_PREFIX}_rw_rd`}
                />
                <Radio
                    disabled={rwrdDisabled}
                    label={t("share.create_share.read_only")}
                    value="ro"
                    id={`${ACCESS_TYPE_CONTROLLER_ID_PREFIX}_ro`}
                />
            </RadioContainer>
        </Grid>
    );
};

export default AccessTypeController;
