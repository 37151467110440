import React from "react";

const AggregateIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="aggregateIcon" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <rect width="16" height="5" x="5.5" y="2.5" fill="#85C3FF" stroke="url(#aggregateIcon)" rx="1" />
                <rect width="16" height="5" x="5.5" y="9.5" fill="#85C3FF" stroke="url(#aggregateIcon)" rx="1" />
                <rect width="16" height="5" x="5.5" y="16.5" fill="#85C3FF" stroke="url(#aggregateIcon)" rx="1" />
                <path stroke="#4A54FF" d="M5 5H3v14h2M3 12h2" />
            </g>
        </svg>
    );
};

export default AggregateIcon;
