import React, { Fragment } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import createForm from "utils/createForm";
import { useStyles } from "./ExcludedFoldersDialog.styles";
import { Grid, Typography, Button, Divider, DialogTitle, ButtonBase, Box } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import Dialog from "components/Dialog";
import ErrorButton from "components/ErrorButton";
import CloseIcon from "assets/CloseIcon";
import { useTranslation } from "react-i18next";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import FolderBrowser from "../FolderBrowser";
import { useModalCombine } from "hooks/useModalCombine";

const EXCLUDED_FOLDERS_DIALOG_ID_PREFIX = `exclude_folders_dialog`;

const ExcludedFoldersDialog = ({ open, onClose, submit, shareName, excludedFoldersList = [], title, hintText, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${EXCLUDED_FOLDERS_DIALOG_ID_PREFIX}` : EXCLUDED_FOLDERS_DIALOG_ID_PREFIX;
    const classes = useStyles();
    const { t } = useTranslation();

    const state = useLocalStore(() => ({
        inProgress: false,
        form: createForm({
            fields: createFields(excludedFoldersList),
            options: {
                validateOnBlur: false,
            },
        }),
        foldersList: [],
        excludedFoldersList: [],
        path: [],
        index: "",
        isSubmitted: false,
        fieldsCounter: 0,
    }));

    const { unsavedChangesModal, folderBrowserModal } = useModalCombine(["unsavedChangesModal", "folderBrowserModal"]);

    const addFolder = () => {
        if (state.form.$("folder").value?.trim() === "") {
            state.form.$("folder").clear();
            return;
        }

        let hasValue = false;
        state.form.fields.forEach((e) => {
            if (e.name !== "folder" && e.value === state.form.$("folder").value) {
                hasValue = true;
            }
        });
        if (!hasValue) {
            state.foldersList.push({ value: state.form.$("folder").value, index: state.foldersList.length });
            state.form.add({ name: `folder${state.fieldsCounter}` });
            state.form.$(`folder${state.fieldsCounter}`).set("value", state.form.$("folder").value);
            ++state.fieldsCounter;
            state.form.$("folder").clear();
        }
    };

    const removeFolder = (index) => () => {
        const folderIndex = state.foldersList.findIndex((folder) => folder.index === index);
        state.foldersList[folderIndex] = { ...state.foldersList[folderIndex], hide: true };
    };

    const onSubmit = async () => {
        const resArray =
            state.foldersList.filter((folder) => folder.hide !== true && folder.value.length > 0)?.map((e) => e.value) || [];
        const res = await submit(resArray);
        res && (state.isSubmitted = true);
        return res;
    };

    const onEnter = () => {
        state.excludedFoldersList = excludedFoldersList;
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.inProgress = false;
        state.path = [];
        state.index = "";
        state.foldersList = [];
        state.fieldsCounter = 0;
        if (state.excludedFoldersList.length) {
            state.foldersList = state.excludedFoldersList.map((el, index) => {
                return (el = { value: el, index: index, hide: false });
            });
            state.fieldsCounter = state.foldersList.length;
        }
        state.foldersList.length >= 1 &&
            state.foldersList.forEach((el) => {
                state.form.add({ name: `folder${el.index}` });
                state.form.$(`folder${el.index}`).set("value", el.value);
            });
        state.form.$("folder").clear();
    };

    const onCloseModal = () => {
        state.form.fields.forEach((field) => field.clear());
        onClose();
    };

    const closeIfNeeded = () => {
        if (state.foldersList.map((el) => el.value).join() === state.excludedFoldersList.join() || state.isSubmitted) {
            onCloseModal();
            return;
        }
        unsavedChangesModal.open();
    };

    const openBrowser = (path, index = "") => () => {
        folderBrowserModal.open();
        state.path = path;
        state.index = index;
    };

    const setPath = (folder) => {
        if (state.index !== "") {
            state.form.$(`folder${state.index}`).set("value", folder);
            state.foldersList[state.index] = { ...state.foldersList[state.index], value: folder };
            return;
        }
        state.form.$(`folder`).set("value", folder);
        folder && addFolder();
    };

    const handleChangeFolder = (e) => {
        state.form.$(e.currentTarget.name).set("value", e.currentTarget.value);
        const folderIndex = state.foldersList.findIndex((el) => el.value === e.target.defaultValue);
        state.foldersList[folderIndex] = { ...state.foldersList[folderIndex], value: e.currentTarget.value };
    };

    return useObserver(() => (
        <Dialog
            title={title ? title : t("slingshot.index_proxy.excluded_folders.title", { shareName: shareName })}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            open={open}
            withoutPaddings
            onEnter={onEnter}
            onClose={closeIfNeeded}
            onSubmit={onSubmit}
            inProgress={state.inProgress}
            buttons={
                <Button onClick={onCloseModal} variant={"contained"} color="secondary" id={`${idPrefix}_close`}>
                    {t("common.button.cancel")}
                </Button>
            }
            header={
                <>
                    <DialogTitle>
                        <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container>
                            <Grid item>
                                {title
                                    ? title
                                    : t("slingshot.index_proxy.excluded_folders.title", {
                                          shareName: shareName,
                                      })}
                            </Grid>
                            <Grid item>
                                <ButtonBase onClick={closeIfNeeded} id={`${idPrefix}_icon_close`}>
                                    <CloseIcon />
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Divider />
                    <DialogTitle>
                        <Grid container wrap={"nowrap"} spacing={2}>
                            <Grid item>
                                <Typography className={classes.labelText} noWrap>
                                    {t("slingshot.index_proxy.excluded_folders.label")}
                                </Typography>
                            </Grid>
                            <Grid container item spacing={4} alignItems={"center"} wrap={"nowrap"} justify={"flex-end"}>
                                <Grid item>
                                    <TextInput field={state.form.$("folder")} id={`${idPrefix}_folder`} />
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={openBrowser(state.form.$("folder").value)}
                                        variant={"contained"}
                                        color={"primary"}
                                        id={`${idPrefix}_open_browser`}
                                    >
                                        {t("common.button.browse")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={addFolder}
                                        variant={"contained"}
                                        color={"primary"}
                                        id={`${idPrefix}_add_folder`}
                                    >
                                        {t("common.button.add")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                </>
            }
        >
            {!!state.foldersList.length && (
                <Box px={6} py={4}>
                    <Typography>{hintText ? hintText : t("slingshot.index_proxy.excluded_folders.caution")}</Typography>
                </Box>
            )}
            {state.foldersList.map((item) => (
                <Fragment key={item.index}>
                    {!item.hide && (
                        <Grid
                            container
                            wrap={"nowrap"}
                            alignItems={"center"}
                            justify={"flex-end"}
                            key={`folder${item.index}`}
                            className={classes.itemContainer}
                        >
                            <Grid item container wrap={"nowrap"} spacing={4}>
                                <Grid item container>
                                    <TextInput
                                        onChange={handleChangeFolder}
                                        field={state.form.$(`folder${item.index}`)}
                                        id={`${idPrefix}_folder_${item.index}`}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={openBrowser(state.form.$(`folder${item.index}`).value, item.index)}
                                        variant={"contained"}
                                        color={"primary"}
                                        id={`${idPrefix}_folder_${item.index}`}
                                    >
                                        {t("common.button.browse")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <ErrorButton
                                        submit={removeFolder(item.index)}
                                        label={t("common.button.remove")}
                                        id={`${idPrefix}_remove_folder_${item.index}`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Fragment>
            ))}
            <UnsavedChangesDialog
                onConfirm={onCloseModal}
                onClose={unsavedChangesModal.close}
                open={unsavedChangesModal.isOpen}
            />
            <FolderBrowser
                open={folderBrowserModal.isOpen}
                onClose={folderBrowserModal.close}
                share={shareName}
                parentName={t("slingshot.index_proxy.excluded_folders")}
                onChoose={setPath}
                pathValue={state.path}
                parentId={idPrefix}
            />
        </Dialog>
    ));
};

export default ExcludedFoldersDialog;
