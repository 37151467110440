import React, { useEffect } from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { useLocalStore, useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import RenderDetails from "./components/RenderDetails/RenderDetails";
import RenderActions from "./components/RenderActions/RenderActions";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";

const drawerName = "longLivedTokensDrawer";

const LongLivedTokensDrawer = () => {
    const { t } = useTranslation();
    const {
        store: { longLivedTokensStore },
    } = useStore();
    const state = useLocalStore(() => ({
        inProgress: false,
    }));
    const revokeTokenModal = useModal();

    const closeDrawer = longLivedTokensStore.removeCurrentToken;

    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: longLivedTokensStore.currentToken });
    useEffect(() => {
        if (!longLivedTokensStore.currentToken && !state.inProgress) {
            revokeTokenModal.close();
        }
    }, [longLivedTokensStore.currentToken, state.inProgress]);

    const revokeToken = async () => {
        state.inProgress = true;
        await longLivedTokensStore.revokeToken({ token: longLivedTokensStore.currentTokenInfo.token });
        revokeTokenModal.close();
        state.inProgress = false;
    };

    return useObserver(() => (
        <>
            <DetailsActionsDrawer
                title={longLivedTokensStore?.currentToken || ""}
                renderActions={() => <RenderActions revokeTokenModal={revokeTokenModal} />}
                renderDetails={() => <RenderDetails />}
                closeDrawer={closeDrawer}
                drawerName={drawerName}
                noWrapHeader={false}
                modals={
                    <>
                        <ConfirmationDialog
                            title={t("common.warning_dialog.title")}
                            open={revokeTokenModal.isOpen}
                            onClose={revokeTokenModal.close}
                            onConfirm={revokeToken}
                            confirmLabel={t("admin_settings.long_lived_tokens_card.revoke_token_dialog.confirm")}
                            cancelLabel={t("admin_settings.long_lived_tokens_card.revoke_token_dialog.cancel")}
                            fullWidth={true}
                        >
                            {t("admin_settings.long_lived_tokens_card.revoke_token_dialog.question", {
                                uuid: longLivedTokensStore?.currentTokenInfo ? longLivedTokensStore.currentTokenInfo.token : "",
                            })}
                        </ConfirmationDialog>
                    </>
                }
            />
        </>
    ));
};

export default LongLivedTokensDrawer;
