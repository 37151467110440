import React from "react";
import { useObserver } from "mobx-react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { Button, Table, TableBody, TableCell, TableHead, TableRow as MuiTableRow, Grid, Box, Divider } from "@material-ui/core";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import TableRow from "./components/TableRow";
import { INSTANCES_ID, INSTANCES_STARTED, INSTANCES_FINISHED, INSTANCES_STATUS } from "const/sortColumnConst";
import TableSortLabel from "components/TableSortLabel";
import TablePagination from "components/TablePagination";
import ProcessedFilesModal from "../ProcessedFilesModal";
import { useStore } from "hooks/useStore";
import { useInstancesModal, useProcessedFilesModal } from "hooks/modals";
import { useModalHistory } from "hooks/useModalHistory";

const JOB_INSTANCES_MODAL_ID_PREFIX = `job_instances_modal`;

const JobInstancesModal = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${JOB_INSTANCES_MODAL_ID_PREFIX}` : JOB_INSTANCES_MODAL_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { syncJobsStore, automationsStore },
    } = useStore();
    const modalHistory = useModalHistory();
    const processedFilesModal = useProcessedFilesModal();
    const instancesModal = useInstancesModal();

    const handleChangePage = (newPage) => {
        syncJobsStore.updateInstancesPage(+newPage);
    };
    const handleChangeRowsPerPage = (e) => {
        syncJobsStore.updateInstancesLimit(+e.target.value);
        syncJobsStore.updateInstancesPage(0);
    };

    const changeSorting = (column) => () => syncJobsStore.changeInstancesSorting(column);

    const openProcessedFilesModal = (instanceId) => async () => {
        syncJobsStore.setCurrentInstanceId(instanceId);
        modalHistory.toggleHidden();
        processedFilesModal.open();
    };
    const closeProcessedFilesModal = () => {
        processedFilesModal.closeAll();
        if (syncJobsStore.needToHideSucceedFiles) {
            syncJobsStore.resetNeedToHideSucceedFiles();
        }
        syncJobsStore.setCurrentInstanceId(null);
    };

    const onEnter = () => {
        modalHistory.toggleHidden(false);
    };

    return useObserver(() => (
        <Dialog
            maxWidth="xl"
            title={t("slingshot_page.tabs.replications.instances_modal.title", {
                name: syncJobsStore.currentJob?.name || automationsStore.currentAutomation?.name || "",
            })}
            submitBtn={[
                <Button
                    key="2"
                    variant="contained"
                    color="secondary"
                    onClick={instancesModal.close}
                    id={`${idPrefix}_close_replication_job_instances_modal_button`}
                >
                    {t("common.button.close")}
                </Button>,
            ]}
            withoutPaddings
            open={instancesModal.isOpen}
            onClose={instancesModal.close}
            hide={modalHistory.hidden}
            onEnter={onEnter}
        >
            {syncJobsStore.instances && syncJobsStore.instancesCount > 0 ? (
                <>
                    <Table stickyHeader>
                        <TableHead>
                            <MuiTableRow>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={changeSorting(INSTANCES_ID)}
                                        direction={syncJobsStore.getInstancesArguments.sort_dir.toLowerCase()}
                                        active={syncJobsStore.getInstancesArguments.sort === INSTANCES_ID}
                                        id={`${idPrefix}_sort_id`}
                                    >
                                        {t("slingshot_page.tabs.replications.instances_modal.table.id")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={changeSorting(INSTANCES_STATUS)}
                                        direction={syncJobsStore.getInstancesArguments.sort_dir.toLowerCase()}
                                        active={syncJobsStore.getInstancesArguments.sort === INSTANCES_STATUS}
                                        id={`${idPrefix}_sort_status`}
                                    >
                                        {t("slingshot_page.tabs.replications.instances_modal.table.status")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={changeSorting(INSTANCES_STARTED)}
                                        direction={syncJobsStore.getInstancesArguments.sort_dir.toLowerCase()}
                                        active={syncJobsStore.getInstancesArguments.sort === INSTANCES_STARTED}
                                        id={`${idPrefix}_sort_started`}
                                    >
                                        {t("slingshot_page.tabs.replications.instances_modal.table.started")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={changeSorting(INSTANCES_FINISHED)}
                                        direction={syncJobsStore.getInstancesArguments.sort_dir.toLowerCase()}
                                        active={syncJobsStore.getInstancesArguments.sort === INSTANCES_FINISHED}
                                        id={`${idPrefix}_sort_finished`}
                                    >
                                        {t("slingshot_page.tabs.replications.instances_modal.table.finished")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>{t("slingshot_page.tabs.replications.instances_modal.table.total")}</TableCell>
                                <TableCell>{t("slingshot_page.tabs.replications.instances_modal.table.succeed")}</TableCell>
                                <TableCell>{t("slingshot_page.tabs.replications.instances_modal.table.failed")}</TableCell>
                                <TableCell>{t("slingshot_page.tabs.replications.instances_modal.table.unprocessed")}</TableCell>
                                <TableCell />
                            </MuiTableRow>
                        </TableHead>
                        <TableBody>
                            {syncJobsStore.instances.map((instance) => (
                                <TableRow
                                    onClick={openProcessedFilesModal(instance.id)}
                                    instance={instance}
                                    key={instance.id}
                                    parentId={`${idPrefix}_${instance.id}`}
                                />
                            ))}
                        </TableBody>
                    </Table>
                    <Grid>
                        <Divider />
                        <Box pl={6}>
                            <TablePagination
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                onChangePage={handleChangePage}
                                count={syncJobsStore.instancesCount}
                                rowsPerPage={syncJobsStore.getInstancesArguments.limit}
                                page={syncJobsStore.getInstancesArguments.page}
                                parentId={idPrefix}
                            />
                        </Box>
                    </Grid>
                    <ProcessedFilesModal onBack={modalHistory.goBack} onClose={closeProcessedFilesModal} parentId={idPrefix} />
                </>
            ) : (
                <EmptyCardPlaceholder message={t("slingshot_page.tabs.replications.instances_modal.table.empty_placeholder")} />
            )}
        </Dialog>
    ));
};

export default JobInstancesModal;
