import { types } from "mobx-state-tree";

const PRESETS_ENUM_ARRAY = ["ultrafast", "superfast", "veryfast", "faster", "medium", "slow", "slower", "veryslow", "placebo"];
const ACODEC_ENUM_ARRAY = [
    "libmp3lame",
    "pcm_alaw",
    "pcm_mulaw",
    "pcm_f32be",
    "pcm_f32le",
    "pcm_f64be",
    "pcm_f64le",
    "pcm_s16be",
    "pcm_s16be_planar",
    "pcm_s16le",
    "pcm_s16le_planar",
    "pcm_s24be",
    "pcm_s24daud",
    "pcm_s24le",
    "pcm_s24le_planar",
    "pcm_s32be",
    "pcm_s32le",
    "pcm_s32le_planar",
    "pcm_s8",
    "pcm_s8_planar",
    "pcm_u16be",
    "pcm_u16le",
    "pcm_u24be",
    "pcm_u24le",
    "pcm_u32be",
    "pcm_u32le",
    "pcm_u8",
    "copy",
    "mp3",
];
const VCODEC_ENUM_ARRAY = [
    "h264",
    "mpeg4",
    "PRORES_4444XQ",
    "PRORES_4444",
    "PRORES_422HQ",
    "PRORES_422",
    "PRORES_422LT",
    "PRORES_422PROXY",
    "copy",
];

const CONTAINER_ENUM_ARRAY = ["mov", "avi", "mp4", "mp3", "mxf"];
const BITRATE_ENUM_ARRAY = [
    "36M",
    "42M",
    "45M",
    "60M",
    "63M",
    "75M",
    "80M",
    "84M",
    "90M",
    "100M",
    "110M",
    "115M",
    "120M",
    "145M",
    "175M",
    "180M",
    "185M",
    "220M",
    "240M",
    "290M",
    "350M",
    "365M",
    "390M",
    "440M",
    "730M",
    "880M",
];

const CreatedPresetArgumentsType = types.model({
    height: types.maybe(
        types.model({
            value: types.maybeNull(types.number),
            user_choice: types.maybe(types.boolean),
        })
    ),
    crf: types.maybe(
        types.model({
            value: types.maybeNull(types.number),
            user_choice: types.maybe(types.boolean),
        })
    ),
    preset: types.maybe(
        types.model({
            value: types.maybeNull(types.enumeration(PRESETS_ENUM_ARRAY)),
            user_choice: types.maybe(types.boolean),
        })
    ),
    acodec: types.maybe(
        types.model({
            value: types.maybeNull(types.enumeration(ACODEC_ENUM_ARRAY)),
            user_choice: types.maybe(types.boolean),
        })
    ),
    vcodec: types.maybe(
        types.model({
            value: types.maybeNull(types.enumeration(VCODEC_ENUM_ARRAY)),
            user_choice: types.maybe(types.boolean),
        })
    ),
    pix_fmt: types.maybe(
        types.model({
            value: types.maybeNull(types.string),
            user_choice: types.maybe(types.boolean),
        })
    ),
    profile: types.maybe(
        types.model({
            value: types.maybeNull(types.string),
            user_choice: types.maybe(types.boolean),
        })
    ),
    container: types.maybe(
        types.model({
            value: types.maybeNull(types.enumeration(CONTAINER_ENUM_ARRAY)),
            user_choice: types.maybe(types.boolean),
        })
    ),
    bv: types.maybe(
        types.model({
            value: types.maybeNull(types.enumeration(BITRATE_ENUM_ARRAY)),
            user_choice: types.maybe(types.boolean),
        })
    ),
    ac: types.maybe(
        types.model({
            value: types.maybeNull(types.number),
            user_choice: types.maybe(types.boolean),
        })
    ),
    start: types.maybe(
        types.model({
            value: types.maybe(types.string),
            user_choice: types.maybe(types.boolean),
        })
    ),
    pass: types.maybe(
        types.model({
            value: types.maybe(types.number),
            user_choice: types.maybe(types.boolean),
        })
    ),
    duration: types.maybe(
        types.model({
            value: types.maybeNull(types.string),
            user_choice: types.maybe(types.boolean),
        })
    ),
    burnt_timecode: types.maybe(
        types.model({
            value: types.maybeNull(
                types.model({
                    disabled: types.maybe(types.boolean),
                    position: types.maybe(types.string),
                    font: types.maybe(types.string),
                    font_size: types.maybe(types.number),
                })
            ),
            user_choice: types.maybe(types.boolean),
        })
    ),
    watermark: types.maybe(
        types.model({
            value: types.maybe(
                types.model({
                    position: types.maybe(types.string),
                    scale: types.maybe(types.string),
                })
            ),
            user_choice: types.maybe(types.boolean),
        })
    ),
});

export default CreatedPresetArgumentsType;
