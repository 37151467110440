import { types } from "mobx-state-tree";

import Request from "api/Request";
import SetConnectionOnlineArguments from "api/vpn/Types/SetConnectionOnlineArguments";

const SetConnectionOnline = Request.props({
    path: "/com/studionetworksolutions/evo/manage/VPN",
    method: "set_connection_online",
    arguments: types.maybe(SetConnectionOnlineArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetConnectionOnline;
