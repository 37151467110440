import { types } from "mobx-state-tree";

import BcacheEnabled from "api/bcache/Types/BcacheEnabled";

const GetEnabledResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(BcacheEnabled)
});

export default GetEnabledResult;
