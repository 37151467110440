import { types } from "mobx-state-tree";

import Request from "api/Request";
import VolumeRemoveBricksArguments from "api/cluster/Types/VolumeRemoveBricksArguments";

const VolumeRemoveBricksCommit = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Cluster",
    method: "volume_remove_bricks_commit",
    arguments: types.maybe(VolumeRemoveBricksArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default VolumeRemoveBricksCommit
