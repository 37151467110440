import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetMaxVolumeSizeArguments from "api/pool/Types/GetMaxVolumeSizeArguments";

const GetMaxVolumeSize = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Pool",
    method: "get_max_volume_size",
    arguments: types.maybe(GetMaxVolumeSizeArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetMaxVolumeSize;
