import React from "react";

const PresetsIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="noct4mdj3a" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
                <linearGradient id="gj7xwl4u8c" x1="50%" x2="50%" y1="-44.223%" y2="112.925%">
                    <stop offset="0%" stopColor="#A770FF" />
                    <stop offset="100%" stopColor="#85ADFF" />
                </linearGradient>
                <linearGradient id="letajy1qbb" x1="50%" x2="50%" y1="-59.681%" y2="138.042%">
                    <stop offset="0%" stopColor="#E19EFF" stopOpacity=".771" />
                    <stop offset="100%" stopColor="#293CFF" stopOpacity=".6" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g transform="translate(-1083 -92) translate(1060 72) translate(23 20)">
                            <rect width="1" height="19" x="5.5" y="2.5" fill="#85C3FF" stroke="url(#noct4mdj3a)" rx=".5" />
                            <rect width="1" height="19" x="11.5" y="2.5" fill="#85C3FF" stroke="url(#noct4mdj3a)" rx=".5" />
                            <rect width="1" height="19" x="17.5" y="2.5" fill="#85C3FF" stroke="url(#noct4mdj3a)" rx=".5" />
                            <circle cx="6" cy="17" r="2.5" fill="url(#gj7xwl4u8c)" stroke="url(#letajy1qbb)" />
                            <circle cx="18" cy="12" r="2.5" fill="url(#gj7xwl4u8c)" stroke="url(#letajy1qbb)" />
                            <circle cx="12" cy="5" r="2.5" fill="url(#gj7xwl4u8c)" stroke="url(#letajy1qbb)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default PresetsIcon;
