import { makeStyles } from "@material-ui/core/styles";
import { CONNECTED, CONNECTING, NOT_CONNECTED } from "const/ldapStatuses";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => {
    const getColor = (status) => {
        switch (status) {
            case CONNECTED:
                return theme.palette.success.main;
            case CONNECTING:
                return theme.palette.warning.main;
            case NOT_CONNECTED:
                return theme.palette.error.main;
            default:
                return theme.palette.error.main;
        }
    };

    return {
        titleContainer: {
            padding: theme.spacing(5, 6, 4, 6),
            boxSizing: "border-box",
        },
        title: {
            opacity: 0.9,
            fontSize: theme.spacing(5),
        },
        titleControls: {
            width: "100%",
            marginTop: 18,
        },
        controlsItem: {
            flex: "1",
            "& > button": {
                width: "100%",
                height: "100%",
            },
            "&:first-child > button": {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            },
            "&:last-child > button": {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            },
        },
        contentContainer: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(7),
        },
        statusRow: {
            padding: theme.spacing(3, 0),
        },
        statusContainer: {
            borderRadius: theme.spacing(1),
            backgroundColor: ({ status }) => hexToRgba(getColor(status), 0.1),
            padding: theme.spacing(0, 2),
        },
        statusText: {
            fontWeight: 500,
            color: ({ status }) => getColor(status),
        },
        expansionPanel: {
            "&&": {
                boxShadow: "none",
                border: `${theme.palette.expandPanel.border.color} 1px solid`,
                borderRadius: theme.spacing(2),
                padding: theme.spacing(3),
                margin: theme.spacing(0, -3),
            },
        },
        expansionPanelContent: {
            "&&": {
                display: "flex",
                minHeight: 0,
                margin: 0,
                padding: 0,
                justifyContent: "space-between",
            },
        },
        expansionLabel: {
            fontSize: 16,
            fontWeight: 500,
            opacity: 0.8,
        },
        expansionIconClose: {
            transform: "rotate(180deg)",
        },
        expansionIcon: {
            backgroundColor: hexToRgba(theme.palette.primary.main, 0.1),
            borderRadius: "50%",
        },
        detailsRoot: {
            padding: theme.spacing(3, 0, 2),
        },
    };
});
