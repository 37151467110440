import { types } from "mobx-state-tree";

const GetUserAccessQuery = types.model({
    offset: types.number,
    limit: types.number,
    shareName: types.string,
    type: types.string,
    search: types.string
});

export default GetUserAccessQuery;
