import { flow, getParent, types } from "mobx-state-tree";
import Socket from "websocket";
import GetFirewallMode from "api/firewall/Requests/GetFirewallMode";
import GetFirewallModeResult from "api/firewall/Responses/GetFirewallModeResult";
import GetFirewallRules from "api/firewall/Requests/GetFirewallRules";
import SetFirewallRules from "api/firewall/Requests/SetFirewallRules";
import GetFirewallRulesResult from "api/firewall/Responses/GetFirewallRulesResult";
import CommitFirewallRules from "api/firewall/Requests/CommitFirewallRules";
import RollbackFirewallRules from "api/firewall/Requests/RollbackFirewallRules";
import ExportFirewallRules from "api/firewall/Requests/ExportFirewallRules";
import ImportFirewallRules from "api/firewall/Requests/ImportFirewallRules";
import GetRollbackTime from "api/firewall/Requests/GetRollbackTime";
import RollbackTimeResult from "api/firewall/Responses/RollbackTimeResult";
import axios from "api/AxiosCommonRequest";
import { FILE_DOWNLOAD_URL, FILE_UPLOAD_URL } from "api/restRoutes";
import { saveAs } from "file-saver";
import { BASIC_MODE, ALL_ADDRESSES, ALL_PORTS, TCP, UDP, ACTION_ACCEPT, ACTION_DROP } from "const/firewallConst";
import { toJS } from "mobx";
import ResetFirewallRules from "api/firewall/Requests/ResetFirewallRules";
import FirewallAdvancedInfoResult from "api/firewall/Responses/FirewallAdvancedInfoResult";
import GetFirewallAdvancedInfo from "api/firewall/Requests/GetFirewallAdvancedInfo";
import { ALL_INTERFACES, ALL_INTERFACES_VALUE } from "const/firewallConst";

const FirewallStore = types
    .model({
        firewallModeResult: types.maybe(GetFirewallModeResult),
        firewallRulesResult: types.maybe(GetFirewallRulesResult),
        checkedRules: types.optional(types.array(types.number), []),
        currentRuleIndex: types.maybeNull(types.string),
        currentIfaceName: types.maybeNull(types.string),
        rollbackTimeResult: types.maybe(RollbackTimeResult),
        firewallAdvancedInfoStore: types.maybe(FirewallAdvancedInfoResult)
    })
    .volatile(() => ({
        interval: 0
    }))
    .views(self => ({
        get firewallMode() {
            return self.firewallModeResult?.data.mode || "";
        },
        get firewallRules() {
            return self.firewallRulesResult?.data;
        },
        get firewallInterfacesRules() {
            return self.firewallRulesResult?.data?.interfacesRules || [];
        },
        get interfacesRulesList() {
            return (self.firewallInterfacesRules && toJS(self.firewallInterfacesRules)) || [];
        },
        get firewallAdvancedInfo() {
            return self.firewallAdvancedInfoStore?.data;
        },
        get ifacesList() {
            const allIfaces = self.firewallRules && [...self.firewallRules.interfaces, ...self.firewallRules.bonds];
            return (
                (allIfaces &&
                    allIfaces
                        .map(iface => ({
                            value: iface.iface,
                            label: iface.slaves ? iface.slaves.join(", ") : iface.iface
                        }))
                        .sort()) ||
                []
            );
        },
        get rollbackTime() {
            return self.rollbackTimeResult?.data?.timeLeftSeconds || 0;
        },
        get currentRule() {
            return (
                self.currentRuleIndex &&
                self.firewallInterfacesRules.find(iface => iface.iface === self.currentRuleIndex.split("-")[0])?.rules[
                    self.currentRuleIndex.split("-")[1]
                ]
            );
        },
        get currentSingleCheckedRule() {
            return (
                self.firewallInterfacesRules?.find(iface => iface.iface === self.currentIfaceName)?.rules[self.checkedRules[0]] ||
                null
            );
        },
        get currentRuleIface() {
            return self.currentRuleIndex?.split("-")[0];
        },
        get currentRuleByIfaceIndex() {
            return +self.currentRuleIndex?.split("-")[1];
        },
        get currentIfaceTitle() {
            if (!self.currentIfaceName) return "";
            return self.currentIfaceName === ALL_INTERFACES_VALUE
                ? ALL_INTERFACES
                : self.ifacesList?.find(iface => iface.value === self.currentIfaceName)?.label || "";
        }
    }))
    .actions(self => ({
        fetchFirewallMode: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetFirewallMode.create().init();
                const res = yield Socket.send(req);
                self.firewallModeResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        resetFirewallRules: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ResetFirewallRules.create().init();
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchFirewallRules: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetFirewallRules.create().init();
                const res = yield Socket.send(req);
                self.firewallRulesResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchFirewallAdvancedInfo: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetFirewallAdvancedInfo.create().init();
                const res = yield Socket.send(req);
                self.firewallAdvancedInfoStore = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setFirewallRules: flow(function*(payload) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetFirewallRules.create().init(payload);
                const res = yield Socket.send(req);
                self.fetchFirewallRules();
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        commitFirewallRules: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = CommitFirewallRules.create().init();
                const res = yield Socket.send(req);
                res && self.clearTimer();
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        rollbackFirewallRules: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RollbackFirewallRules.create().init();
                const res = yield Socket.send(req);
                res && self.clearTimer();
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        getRollbackTime: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetRollbackTime.create().init();
                const res = yield Socket.send(req);
                self.rollbackTimeResult = res;
                res && self.startTimer();
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        getRules: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ExportFirewallRules.create().init();
                const res = yield Socket.send(req);
                yield self.downloadRules(res.data?.filename);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        downloadRules: flow(function*(fileName) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const config = { responseType: "blob", params: { file: fileName } };
                const res = yield axios.get(FILE_DOWNLOAD_URL, config);
                saveAs(new Blob([res.data]), fileName);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        uploadFileRules: flow(function*(file) {
            const { processingStore } = getParent(self);
            const formData = new FormData();
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            try {
                formData.append("file", file);
                processingStore.setLoading(true);
                yield axios.post(FILE_UPLOAD_URL, formData, config);
                yield self.importRules(file.name);
                return file;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        importRules: flow(function*(fileName) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ImportFirewallRules.create().init({
                    filename: fileName
                });
                yield Socket.send(req);
                self.getRollbackTime();
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        addCheckedRule: ruleIndex => {
            self.checkedRules.push(ruleIndex);
        },
        removeCheckedRule: ruleIndex => {
            self.checkedRules.remove(ruleIndex);
        },
        clearCheckedRules: () => {
            self.checkedRules = [];
        },
        setCurrentRuleIndex: ruleIndexName => {
            self.currentRuleIndex = ruleIndexName || null;
        },
        setCurrentIfaceName: ifaceName => {
            self.currentIfaceName = ifaceName;
        },
        portsView: port => {
            if (port.start === ALL_PORTS.start && port.end === ALL_PORTS.end) return ALL_PORTS.label;
            if (port.start === port.end) return port.start;
            if (port.start !== port.end) return `${port.start} - ${port.end}`;
        },
        addressView: address => {
            if (address.start === ALL_ADDRESSES.value && address.end === ALL_ADDRESSES.value) return ALL_ADDRESSES.label;
            if (address.start === address.end) return address.start;
            if (address.start !== address.end) return `${address.start} - ${address.end}`;
        },
        actionView: action => {
            switch (action) {
                case ACTION_ACCEPT.value:
                    return ACTION_ACCEPT.label;
                case ACTION_DROP.value:
                    return ACTION_DROP.label;
                default:
                    return "";
            }
        },
        protocolView: protocol => {
            switch (protocol) {
                case TCP.value:
                    return TCP.label;
                case UDP.value:
                    return UDP.label;
                default:
                    return "";
            }
        },
        startTimer: () => {
            if (self.interval) {
                clearInterval(self.interval);
            }
            if (self.rollbackTimeResult && self.rollbackTimeResult.data) {
                self.interval = setInterval(() => {
                    self.subtractSecToTime();
                }, 1000);
            }
        },
        subtractSecToTime: () => {
            if (self.rollbackTimeResult.data.timeLeftSeconds > 0) {
                self.rollbackTimeResult.data.timeLeftSeconds -= 1;
            } else {
                self.firewallMode === BASIC_MODE && self.fetchFirewallRules();
                clearInterval(self.interval);
            }
        },
        clearTimer: () => {
            self.rollbackTimeResult.data.timeLeftSeconds = 0;
        },
        rollbackWasDisabled: () => {
            if (self.rollbackTimeResult?.data) {
                self.rollbackTimeResult.data.timeLeftSeconds = 0;
            }
        },
        updateAdvancedInfoInStore: firewallAdvancedInfo => {
            if (self.firewallAdvancedInfoStore?.data) {
                self.firewallAdvancedInfoStore.data = firewallAdvancedInfo;
            }
        },
        firewallSettingsWereReset: () => {
            if (self.firewallRulesResult?.data?.interfacesRules) {
                self.firewallRulesResult.data.interfacesRules = [];
            }
        },
        updateFirewallRulesInStore: firewallRules => {
            if (self.firewallRulesResult?.data?.interfacesRules) {
                self.firewallRulesResult.data.interfacesRules = firewallRules.interfacesRules;
            }
        }
    }));

export default FirewallStore;
