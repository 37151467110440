import React from "react";
import DataCard from "components/DataCard";
import { Grid, ButtonBase, CircularProgress } from "@material-ui/core";
import { useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import ResetIcon from "assets/ResetIcon";
import { useStyles } from "./FailedDrivesCard.style";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { useStore } from "hooks/useStore";
import RenderDisk from "../RenderDisk";
import { useFailedDrives } from "./FailedDrivesCard.hook";

const FailedDrivesCard = () => {
    const { t } = useTranslation();
    const {
        store: { driveReplaceStore }
    } = useStore();

    const classes = useStyles();

    const { failedDrives, isFailedDrive } = useFailedDrives();

    const isFetching = driveReplaceStore.replaceState.status === "rescan" || driveReplaceStore.fetchingAffectedPools;

    return useObserver(() => (
        <DataCard
            headerControl={[
                isFetching ? (
                    <CircularProgress color={"primary"} size={16} />
                ) : (
                    <ButtonBase onClick={driveReplaceStore.rescan} disabled={driveReplaceStore.replaceState.status !== "idle"}>
                        <ResetIcon />
                    </ButtonBase>
                )
            ]}
            title={t("troubleshooting.failed_drives.card_title")}
            headerDivider
            autoHeight
        >
            {isFailedDrive ? (
                <Grid item container direction={"column"} spacing={2} alignItems={"center"} className={classes.container}>
                    {failedDrives.map(driveStatus => (
                        <RenderDisk {...driveStatus} key={"disk" + driveStatus.quad + driveStatus.drive} />
                    ))}
                </Grid>
            ) : (
                <EmptyCardPlaceholder message={t("troubleshooting.failed_drives.placeholder")} />
            )}
        </DataCard>
    ));
};

export default FailedDrivesCard;
