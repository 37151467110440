import Request from "api/Request";

const ListPluginUpdates = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Plugins",
    method: "list_plugin_updates"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ListPluginUpdates;
