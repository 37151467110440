import { types } from "mobx-state-tree";

import DashboardLayout from "api/ui_settings/Types/DashboardLayout";

const GetDashboardLayoutResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(DashboardLayout)
});

export default GetDashboardLayoutResult;
