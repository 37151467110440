import { makeStyles } from "@material-ui/core/styles";

const LOGO_TOP_PADDING = 160;
const PAPER_WIDTH = 520;
export const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: "#34373e",
        minHeight: "100vh"
    },
    logoContainer: {
        paddingTop: LOGO_TOP_PADDING,
        paddingBottom: theme.spacing(16)
    },
    title: {
        opacity: 0.9,
        fontSize: 20,
        fontWeight: "bold",
        lineHeight: 1.2,
        letterSpacing: 0.2
    },
    card: {
        width: PAPER_WIDTH
    },
    message: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
    },
    progress: {
        opacity: 1,
        whiteSpace: "pre-line",
        lineHeight: 2.3
    },
    supportButtonContainer: {
        margin: "auto auto 18px 160px",
        maxWidth: 1200,
        boxSizing: "content-box",
        position: "relative",
        height: 40,
        [theme.breakpoints.up("md")]: {
            minWidth: 800,
            margin: "auto auto 18px auto"
        }
    }
}));
