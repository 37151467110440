import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetUsbAclArguments from "api/security/acl/Types/GetUsbAclArguments";

const GetUsbAcl = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Acl",
    method: "get_usb_acl",
    arguments: types.maybe(GetUsbAclArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetUsbAcl
