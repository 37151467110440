import React, { useEffect, useContext } from "react";
import { Context } from "store";
import { useObserver, useLocalStore } from "mobx-react";
import DataCard from "components/DataCard";
import { Typography, Grid, Box, CircularProgress } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import Select from "components/MobxForm/Select";
import { Link } from "react-router-dom";
import { URL_SHARES } from "routes";
import { useStyles } from "./DatabaseLocationCard.styles";
import createForm from "utils/createForm";
import createFields from "./createFields";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import { ADMIN } from "const/userRolesConst";
import sortByLocaleCompare from "utils/sortByLocaleCompare";

const DatabaseLocationCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: { shareStore, postgresStore, authStore },
    } = useContext(Context);

    const state = useLocalStore(() => ({
        loadInProgress: true,
        form: createForm({
            fields: createFields(),
        }),
        isSubmitted: false,
        initializingInProgress: false,
        get options() {
            return (
                (shareStore.internalShares.length &&
                    sortByLocaleCompare(shareStore.internalShares.map((share) => share.attributes.shareName))) ||
                []
            );
        },
    }));

    useEffect(() => {
        (async () => {
            await shareStore.fetchSharesTable();
            state.loadInProgress = false;
            if (shareStore.internalShares.length === 1) {
                state.form.$("shares").set("default", shareStore.internalShares[0].attributes.shareName);
            }
            state.form.reset();
        })();
    }, []);

    const initialize = async () => {
        state.initializingInProgress = true;
        const res = await postgresStore.setDatabaseLocation(state.form.$("shares").value);
        state.initializingInProgress = false;
        res && (state.isSubmitted = true);
    };

    return useObserver(() => (
        <DataCard title={t("database_location_card.title")} headerDivider autoHeight emptyPadding>
            {authStore.role !== ADMIN || shareStore.internalShares.length > 0 ? (
                <Box pt={4} pr={6} pb={6} pl={6}>
                    <Grid container direction={"column"} wrap={"nowrap"}>
                        {authStore.role === ADMIN ? (
                            <>
                                <Typography variant={"body2"}>{t("database_location_card.message")}</Typography>
                                <Grid item container wrap={"nowrap"} alignItems={"center"} className={classes.rowWithSelect}>
                                    <Grid item xs={4}>
                                        <Typography>{t("database_location_card.select.label")}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select field={state.form.$("shares")} options={state.options} />
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <AnimatedSubmitButton
                                        isSubmitted={state.isSubmitted}
                                        inProgress={state.initializingInProgress}
                                        submit={initialize}
                                        disabled={state.initializingInProgress}
                                        label={t("database_location_card.button.initialize")}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <Box py={20} px={6}>
                                <Grid container direction={"column"} alignItems={"center"} justify={"center"}>
                                    <Typography className={classes.textWithOpacity}>
                                        {t("database_location_card.message_for_user_role")}
                                    </Typography>
                                </Grid>
                            </Box>
                        )}
                    </Grid>
                </Box>
            ) : (
                <Box pt={20} pb={20}>
                    <Grid container direction={"column"} alignItems={"center"} justify={"center"}>
                        {state.loadInProgress ? (
                            <CircularProgress size={40} />
                        ) : (
                            <>
                                <Typography variant={"h6"}>{t("database_location_card.placeholder.no_shares")}</Typography>
                                <Typography className={classes.textWithOpacity}>
                                    <Trans
                                        i18nKey={"database_location_card.placeholder.link_to_shares_page"}
                                        components={<Link className={classes.link} to={URL_SHARES} />}
                                    />
                                </Typography>
                            </>
                        )}
                    </Grid>
                </Box>
            )}
        </DataCard>
    ));
};

export default DatabaseLocationCard;
