import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetAllowedUsersGroupsArguments from "api/security/acl/Types/GetAllowedUsersGroupsArguments";

const GetAllowedUsersGroups = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Acl",
    method: "get_allowed_users_groups",
    arguments: types.maybe(GetAllowedUsersGroupsArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetAllowedUsersGroups
