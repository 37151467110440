import Request from "api/Request";

const ResetNasConfiguration = Request.props({
    path: "/com/studionetworksolutions/evo/Nas",
    method: "reset_nas_configuration"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ResetNasConfiguration;
