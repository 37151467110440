import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    list: {
        padding: 0,
        "& li": {
            padding: 0,
            alignItems: "baseline"
        },
        "& .MuiListItemIcon-root": {
            minWidth: theme.spacing(6)
        },
        "& .MuiListItemText-root": {
            margin: theme.spacing(2, 0)
        }
    }
}));
