import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => ({
    closeIcon: {
        position: "absolute",
        right: 16,
        top: 16,
    },
    contentContainer: {
        paddingTop: theme.spacing(2),
    },
    disabled: {
        opacity: 0.8,
    },
    fileContainer: {
        position: "relative",
        padding: theme.spacing(3),
        borderRadius: theme.spacing(1),
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.1),
    },
    fileContainerError: {
        outline: `1px solid ${theme.palette.error.main}`,
    },
    textContainer: {
        overflow: "hidden",
        marginRight: theme.spacing(2),
    },
    text: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    helperText: {
        opacity: 1,
        fontSize: 11,
        position: "absolute",
        zIndex: 1,
        right: -8, // change this if the input size changes
        top: 35, // change this if the input size changes
        padding: "2px 8px",
        borderRadius: 2,
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
        margin: "4px 14px 0",
        "&::after": {
            content: "' '",
            display: "block",
            position: "absolute",
            right: 10,
            bottom: "100%",
            width: 0,
            height: 0,
            border: "4px solid transparent",
            borderBottomColor: theme.palette.error.main,
        },
    },
    helperTextHidden: {
        display: "none",
    },
}));
