import React from "react";
import { Grid } from "@material-ui/core";
import MainTemplate from "components/MainTemplate";
import HeaderTabs from "components/HeaderTabs";
import SelfTestsTabComponent from "./components/SelfTestsTabComponent";
import StartTabComponent from "./components/StartTabComponent";
import OsDataTabComponent from "./components/OsDataTabComponent";
import SupportHeaderIcon from "assets/SupportHeaderIcon";
import HeaderTitle from "components/HeaderTitle";
import { useStyles } from "./Support.styles";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { URL_SUPPORT, URL_SUPPORT_SELF_TESTS_TAB, URL_SUPPORT_OS_DATA_TAB, URL_TROUBLESHOOTING } from "routes";
import { SUPPORT, SUPPORT_SELF_TESTS, SUPPORT_OS_DATA } from "const/shortcutNameConst";
import useUrlUserPermission from "hooks/useUrlUserPermission";
import TABS from "./Support.tabs";
import ViewByRole from "components/ViewByRole";
import { ADMIN } from "const/userRolesConst";
import ReplaceDisk from "./components/ReplaceDisk/ReplaceDisk";

const Support = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();
    const { urlToRedirect } = useUrlUserPermission();
    const getShortcutName = () => {
        if (location.pathname === URL_SUPPORT_SELF_TESTS_TAB) return SUPPORT_SELF_TESTS;
        if (location.pathname === URL_SUPPORT_OS_DATA_TAB) return SUPPORT_OS_DATA;
        return SUPPORT;
    };

    return useObserver(() => (
        <MainTemplate
            headerTitle={<HeaderTitle icon={<SupportHeaderIcon />} title={t("support.title")} shortcutName={getShortcutName()} />}
        >
            <Grid container direction="column">
                <HeaderTabs tabs={TABS} />
                <Grid className={classes.container} container>
                    <Switch>
                        <Route exact path={URL_SUPPORT} component={StartTabComponent} />
                        <Route exact path={URL_TROUBLESHOOTING} component={ReplaceDisk} />
                        <ViewByRole roles={ADMIN}>
                            <Route exact path={URL_SUPPORT_SELF_TESTS_TAB} component={SelfTestsTabComponent} />
                            <Route exact path={URL_SUPPORT_OS_DATA_TAB} component={OsDataTabComponent} />
                        </ViewByRole>
                        <Redirect to={urlToRedirect} />
                    </Switch>
                </Grid>
            </Grid>
        </MainTemplate>
    ));
};
export default Support;
