import React from "react";

const UnlockMediumIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="evenodd"
                d="M12 2c2.21 0 4 1.79 4 4l-.007.117A1 1 0 0114 6l-.005-.15A2.001 2.001 0 0010 6v2h7a3 3 0 013 3v8a3 3 0 01-3 3H7a3 3 0 01-3-3v-8l.005-.176A3 3 0 017 8h1V6c0-2.21 1.79-4 4-4zm5 8H7a1 1 0 00-1 1v8l.007.117A1 1 0 007 20h10a1 1 0 001-1v-8a1 1 0 00-1-1zm-5 2l.176.005A3 3 0 0115 15l-.005.176A3 3 0 1112 12zm0 2a1 1 0 000 2l.117-.007A1 1 0 0013 15l-.007-.117A1 1 0 0012 14z"
            ></path>
        </svg>
    );
};

export default UnlockMediumIcon;
