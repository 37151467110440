import i18n from "i18n";

export const FREQUENCY_OPTIONS = {
    never: { value: "never", label: i18n.t("support.push_diagnostics.frequency_option.never") },
    twice_per_day: { value: "twice_per_day", label: i18n.t("support.push_diagnostics.frequency_option.twice_per_day") },
    every_day: { value: "every_day", label: i18n.t("support.push_diagnostics.frequency_option.every_day") },
    every_2_days: { value: "every_2_days", label: i18n.t("support.push_diagnostics.frequency_option.every_2_days") },
    every_week: { value: "every_week", label: i18n.t("support.push_diagnostics.frequency_option.every_week") },
    every_2_weeks: { value: "every_2_weeks", label: i18n.t("support.push_diagnostics.frequency_option.every_2_weeks") },
    every_4_weeks: { value: "every_4_weeks", label: i18n.t("support.push_diagnostics.frequency_option.every_4_weeks") }
};
