import Request from "api/Request";

const ResetAutotaggingConfig = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Autotagging1",
    method: "reset_config"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ResetAutotaggingConfig;
