import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import PoolShare from "../PoolShare";
import useStoreByIp from "hooks/useStoreByIp";

const PoolsShareContainer = ({ ip }) => {
    const { shareStore } = useStoreByIp({ ip });

    useEffect(() => {
        shareStore.fetchSharesTable();
    }, []);

    return (
        <Grid container direction={"column"} spacing={6}>
            {shareStore.sharesTable &&
                shareStore.sharesTable.map(share => (
                    <Grid item key={share.pool + share.volume + share.partition}>
                        <PoolShare
                            ip={ip}
                            pool={share.pool}
                            volume={share.volume}
                            partition={share.partition}
                            fileSystem={share.fileSystem}
                            sharesTable={shareStore.sharesTable}
                        />
                    </Grid>
                ))}
        </Grid>
    );
};

export default observer(PoolsShareContainer);
