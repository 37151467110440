import { types } from "mobx-state-tree";

import PresetJsonType from "api/slingshot/settings/Types/PresetJsonType";

const GetPresetsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(PresetJsonType, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            }
        })
    )
});

export default GetPresetsResult;
