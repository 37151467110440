import Request from "api/Request";

const GetRegistrations = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/ExtVolumes1",
    method: "get_registrations"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetRegistrations;
