import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetActivatedKeys from "api/activation/keys/Requests/GetActivatedKeys";
import ActivationKeysResultType from "api/activation/keys/Responses/ActivationKeyDataResult";
import { ASC, DESC, ACTIVATED_KEYS_ID } from "const/sortColumnConst";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";

const ActivatedKeysStore = types
    .model({ activatedKeysStore: types.maybe(ActivationKeysResultType) })
    .volatile(() => ({
        orderBy: ACTIVATED_KEYS_ID,
        order: ASC
    }))
    .views(self => ({
        get activatedKeys() {
            return self.activatedKeysStore && self.activatedKeysStore.data.keys;
        },
        get sortedActivatedKeys() {
            return self.activatedKeys && stableSort(self.activatedKeys, getComparator(self.order, self.orderBy));
        }
    }))
    .actions(self => ({
        fetchActivatedKeys: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetActivatedKeys.create().init();
                const res = yield Socket.send(req);
                self.activatedKeysStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        changeSorting(column) {
            if (column === self.orderBy) {
                self.order = self.order === DESC ? ASC : DESC;
            } else {
                self.order = ASC;
                self.orderBy = column;
            }
        }
    }));

export default ActivatedKeysStore;
