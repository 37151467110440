import PageQuery from "api/general/Types/PageQuery";
import { types } from "mobx-state-tree";

const TrashDirArguments = types.model({
    shareName: types.string,
    dirOnShare: types.string,
    query: PageQuery,
});

export default TrashDirArguments;
