import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetInstalledCertificateInfo from "api/certificate/Requests/GetInstalledCertificateInfo";
import GetInstalledCertificateInfoType from "api/certificate/Responses/GetInstalledCertificateInfoResult";
import CreateSelfSignedCertificate from "api/certificate/Requests/CreateSelfSignedCertificate";
import CreateCertificateSigningRequest from "api/certificate/Requests/CreateCertificateSigningRequest";
import ExportCertificate from "api/certificate/Requests/ExportCertificate";
import ImportCertificate from "api/certificate/Requests/ImportCertificate";
import GenerateNewPrivateKey from "api/certificate/Requests/GenerateNewPrivateKey";
import axios from "api/AxiosCommonRequest";
import { FILE_DOWNLOAD_URL, FILE_UPLOAD_URL } from "api/restRoutes";
import { saveAs } from "file-saver";
import ImportCertificateAndKey from "../../api/certificate/Requests/ImportCertificateAndKey";

const CertificateStore = types
    .model({ installedCertificateInfoStore: types.maybe(GetInstalledCertificateInfoType) })
    .views((self) => ({
        get installedCertificateInfo() {
            return (
                self.installedCertificateInfoStore &&
                self.installedCertificateInfoStore.data.length > 0 &&
                self.installedCertificateInfoStore.data[0]
            );
        },
    }))
    .actions((self) => ({
        fetchInstalledCertificateInfo: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetInstalledCertificateInfo.create().init();
                const res = yield Socket.send(req);
                self.installedCertificateInfoStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        generateNewPrivateKey: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GenerateNewPrivateKey.create().init();
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        exportCertificate: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ExportCertificate.create().init();
                const res = yield Socket.send(req);
                self.downloadCertificate(res.data);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        downloadCertificate: flow(function* (fileName) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const config = { responseType: "blob", params: { file: fileName } };
                const res = yield axios.get(FILE_DOWNLOAD_URL, config);
                saveAs(new Blob([res.data]), fileName);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        createSelfSignedCertificate: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = CreateSelfSignedCertificate.create().init(data);
                const res = yield Socket.send(req);
                self.fetchInstalledCertificateInfo();
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        createCertificateSigningRequest: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = CreateCertificateSigningRequest.create().init(data);
                const res = yield Socket.send(req);
                self.fetchInstalledCertificateInfo();
                return res.data;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        importCertificate: flow(function* (fileName) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ImportCertificate.create().init({
                    certName: fileName,
                });
                yield Socket.send(req);
                self.fetchInstalledCertificateInfo();

                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        importCertificateAndKey: flow(function* ({ certName, keyName, intermediateCertName = "" }) {
            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);
                const req = ImportCertificateAndKey.create().init({
                    certName,
                    intermediateCertName,
                    keyName,
                });

                yield Socket.send(req);
                self.fetchInstalledCertificateInfo();
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        uploadFile: flow(function* (file) {
            const { processingStore } = getParent(self);
            const formData = new FormData();
            const config = { headers: { "Content-Type": "multipart/form-data" } };

            try {
                formData.append("file", file);
                processingStore.setLoading(true);
                yield axios.post(FILE_UPLOAD_URL, formData, config);

                yield self.importCertificate(file.name);

                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        uploadCertificateAndKey: flow(function* ({ cert, key, intermediateCert }) {
            const { processingStore } = getParent(self);
            const config = { headers: { "Content-Type": "multipart/form-data" } };

            try {
                processingStore.setLoading(true);

                const promises = [cert, key, intermediateCert].filter(Boolean).map((file) => {
                    const formData = new FormData();
                    formData.append("file", file);

                    return axios.post(FILE_UPLOAD_URL, formData, config);
                });

                yield Promise.all(promises);

                const certificate = yield self.importCertificateAndKey({
                    certName: cert.name,
                    keyName: key.name,
                    intermediateCertName: intermediateCert?.name,
                });

                return certificate;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
    }));

export default CertificateStore;
