import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    dotContainer: {
        minHeight: theme.spacing(6),
        alignItems: "center"
    },
    dot: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        borderRadius: "50%",
        backgroundColor: theme.palette.grey[200]
    }
}));
