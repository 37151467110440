import { types } from "mobx-state-tree";

import TrashSettings from "api/trash/Types/TrashSettings";

const TrashSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(TrashSettings)
});

export default TrashSettingsResult;
