import React from "react";

const PinIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" {...props}>
            <path
                fill="#BFD1E1"
                fillRule="evenodd"
                d="M2.343 6.172l.707-.708c1.172-1.171 3.071-1.171 4.243 0l4.243-4.242c.39-.39 1.023-.39 1.414 0l2.828 2.828c.39.39.39 1.024 0 1.414l-4.242 4.243c1.171 1.172 1.171 3.071 0 4.243l-.708.707-3.535-3.536-4.243 4.243c-.39.39-1.023.39-1.414 0-.39-.39-.39-1.024 0-1.414l4.243-4.243-3.536-3.535z"
            />
        </svg>
    );
};

export default PinIcon;
