import { types } from "mobx-state-tree";

import ConnectionInfo from "api/vpn/Types/ConnectionInfo";

const GetConnectionsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(ConnectionInfo)
});

export default GetConnectionsResult;
