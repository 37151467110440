import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography, Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import AnimatedSubmitButton from "../../../../components/AnimatedSubmitButton";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const WARNING_DIALOG_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_warning_dialog`;

const WarningDialog = ({ open, onClose, submit, submitBtnLabel, question, title }) => {
    const { t } = useTranslation();

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={title}
            withoutIconClose
            withoutDividers
            buttons={
                <Button onClick={onClose} variant={"contained"} color={"secondary"} id={`${WARNING_DIALOG_ID_PREFIX}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
            submitBtn={<AnimatedSubmitButton label={submitBtnLabel} submit={submit} id={`${WARNING_DIALOG_ID_PREFIX}_submit`} />}
        >
            <Grid container direction={"column"} spacing={5}>
                <Grid item>{question}</Grid>
                <Grid item>
                    <Typography>{t("share.warning_dialog.question")}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default WarningDialog;
