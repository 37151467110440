import { types } from "mobx-state-tree";

const Permissions = types.model({
    changeOwner: types.boolean,
    changePermissions: types.boolean,
    traverseFoldersExecuteFile: types.boolean,
    listFoldersReadData: types.boolean,
    readAttributes: types.boolean,
    readExtendedAttributes: types.boolean,
    readPermissions: types.boolean,
    createFilesWriteData: types.boolean,
    createFoldersAppendData: types.boolean,
    writeAttributes: types.boolean,
    writeExtendedAttributes: types.boolean,
    deleteSubdirsAndFiles: types.boolean,
    delete: types.boolean
});

export default Permissions;
