import { types } from "mobx-state-tree";

import GetFoldersJsonType from "api/slingshot/storage/Types/GetFoldersJsonType";

const GetFoldersResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(GetFoldersJsonType, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            }
        })
    )
});

export default GetFoldersResult;
