import React, { useContext, useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DataReadonlyCard from "components/DataReadonlyCard";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { LOGGING_LEVEL } from "const/widgetNameConst";
import { NO_LOGS, CRITICAL, STANDARD, FULL } from "const/loggingConst";
import { Context } from "store";
import i18n from "i18n";
import SetupLoggingLevelDialog from "../SetupLoggingLevelDialog/SetupLoggingLevelDialog";

const LoggingLevelCard = () => {
    const { t } = useTranslation();
    const {
        store: { advancedStore }
    } = useContext(Context);
    useEffect(() => {
        advancedStore.fetchLogSettings();
    }, []);
    const state = useLocalStore(() => ({
        openEditDialog: false
    }));
    const closeDialog = () => {
        state.openEditDialog = false;
    };
    const openDialog = () => {
        state.openEditDialog = true;
    };

    const getLogSettingsInfo = () => {
        switch (advancedStore.logSettings?.logLevel) {
            case NO_LOGS:
                return i18n.t("advanced.logging_level.edit_card.menu_item.no_logs");
            case CRITICAL:
                return i18n.t("advanced.logging_level.edit_card.menu_item.critical_logging");
            case STANDARD:
                return i18n.t("advanced.logging_level.edit_card.menu_item.standard_logging");
            case FULL:
                return i18n.t("advanced.logging_level.edit_card.menu_item.full_logging");
            default:
                return null;
        }
    };

    return useObserver(() => (
        <>
            <DataReadonlyCard
                control={<MoreMenu>{<HomeCardActionMenuItem widget={LOGGING_LEVEL} />}</MoreMenu>}
                title={t("advanced.logging_level.title")}
                onClick={openDialog}
                headerDivider
                autoHeight
            >
                <Grid container direction={"column"} spacing={4}>
                    <Grid item container>
                        <Grid item xs={6}>
                            <Typography>{t("advanced.logging_level.text")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>{getLogSettingsInfo()} </Typography>
                        </Grid>
                    </Grid>
                    {advancedStore.logSettings?.logLevel === FULL && (
                        <Grid item container>
                            <Grid item xs={6}>
                                <Typography>{t("advanced.logging_level.switch_to_default.text")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h6"}>
                                    {t("advanced.logging_level.days.text", {
                                        count: advancedStore.logSettings?.daysToReturnStandardLogs
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </DataReadonlyCard>
            <SetupLoggingLevelDialog open={state.openEditDialog} closeDialog={closeDialog} />
        </>
    ));
};

export default LoggingLevelCard;
