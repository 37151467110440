import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import createFields from "./createFields";
import { observer, useLocalStore } from "mobx-react";
import createForm from "utils/createForm";
import isIpv4Rule from "utils/isIpv4Rule";
import { Button, Grid } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { DEFAULT_HOST } from "const/slingshotIndexerConfigConst";
import RowSwitch from "components/RowSwitch/RowSwitch";
import Switch from "components/MobxForm/Switch";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import { PROGRESSIVE_VERSION_PATH } from "./SetPostMapHostModal.const";
import { httpPort, httpsPort, SBS_DEFAULT_PORT } from "const";

const SET_POST_MAP_HOST_MODAL_ID_PREFIX = `set_post_map_host_modal`;

const SetPostMapHostModal = ({ onClose, open, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${SET_POST_MAP_HOST_MODAL_ID_PREFIX}` : SET_POST_MAP_HOST_MODAL_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { indexingConfigStore },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields(), rules: { host: isIpv4Rule.isIpv4 } }),
        isSubmitted: false,
        get disabledPushMetadata() {
            return !state.form.$("enabled").value;
        },
    }));

    const unsavedChangesModal = useModal();
    const chooseDefaultValue = (isPushMetadataEnabled) => {
        const choosePort = (https) => {
            if (indexingConfigStore.postmap.port && indexingConfigStore.postmap.port !== SBS_DEFAULT_PORT) {
                return indexingConfigStore.postmap.port;
            }

            return https ? httpsPort : httpPort;
        };

        const defaultValue = isPushMetadataEnabled
            ? {
                  host: indexingConfigStore.postmap.host === "127.0.0.1" ? "" : indexingConfigStore.postmap.host,
                  https: indexingConfigStore.postmap.https,
                  port: choosePort(indexingConfigStore.postmap.https),
                  path: PROGRESSIVE_VERSION_PATH,
              }
            : {
                  host: "127.0.0.1",
                  https: false,
                  port: SBS_DEFAULT_PORT,
                  path: "",
              };

        state.form.$("host").set("default", defaultValue.host);
        state.form.$("https").set("default", defaultValue.https);
        state.form.$("port").set("default", defaultValue.port);
        state.form.$("path").set("default", defaultValue.path);
    };
    const updateForm = () => {
        const isPushMetadataEnabled = indexingConfigStore.isPushMetadataEnabled;
        state.form.$("enabled").set("default", indexingConfigStore.isPushMetadataEnabled);
        chooseDefaultValue(isPushMetadataEnabled);
        state.form.reset();
        state.isSubmitted = false;
        unsavedChangesModal.close();
    };
    const submit = async (e) => {
        state.form.onSubmit(e);
        if (state.form.$("enabled").value) {
            if (!state.form.isValid) return;
            if (state.form.$("host").value === DEFAULT_HOST) {
                state.form.$("host").invalidate(t("slingshot.index_proxy.multiple_evo.error.central_ip"));
                return;
            }
        }
        const postmat = {
            host: state.form.$("host").value,
            https: state.form.$("https").value,
            path: state.form.$("path").value,
            port: Number(state.form.$("port").value),
        };
        const isSubmitted = await indexingConfigStore.setPostMap(postmat);
        if (isSubmitted) {
            state.isSubmitted = true;
        }
        return isSubmitted;
    };

    const onSwitchChange = (e) => {
        e.target.checked && state.form.$("host").set("value", DEFAULT_HOST);
        state.form.$("enabled").set(e.target.checked);
    };

    const onSwitchHTTPS = (e) => {
        state.form.$("port").set(e.target.checked ? httpsPort : httpPort);
        state.form.$("https").set(e.target.checked);
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    useEffect(() => {
        chooseDefaultValue(state.form.$("enabled").value);
        state.form.$("host").reset();
        state.form.$("host").resetValidation();
        state.form.$("host").focus();
        state.form.$("https").reset();
        state.form.$("port").reset();
        state.form.$("path").reset();
    }, [state.form.$("enabled").value]);

    return (
        <Dialog
            title={t("slingshot.index_proxy.multiple_evo.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            open={open}
            onSubmit={submit}
            onEnter={updateForm}
            onClose={closeIfNeeded}
            buttons={
                <Button key="cancelBtn" onClick={onClose} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"}>
                <RowSwitch
                    label={t("slingshot.index_proxy.multiple_evo.label.push_metadata")}
                    control={<Switch onChange={onSwitchChange} field={state.form.$("enabled")} id={`${idPrefix}_enabled`} />}
                />
                <RowTextInput
                    hide={state.disabledPushMetadata}
                    label={t("slingshot.index_proxy.multiple_evo.label.https")}
                    control={<Switch onChange={onSwitchHTTPS} field={state.form.$("https")} id={`${idPrefix}_https`} />}
                />
                <RowTextInput
                    hide={state.disabledPushMetadata}
                    label={t("slingshot.index_proxy.multiple_evo.label.central_ip")}
                    control={
                        <TextInput
                            disabled={state.disabledPushMetadata}
                            field={state.form.$("host")}
                            error={!state.form.$("host").isValid}
                            id={`${idPrefix}_host`}
                        />
                    }
                />
                <RowTextInput
                    hide={state.disabledPushMetadata}
                    label={t("slingshot.index_proxy.multiple_evo.label.port")}
                    control={<TextInput field={state.form.$("port")} id={`${idPrefix}_port`} />}
                />
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    );
};

export default observer(SetPostMapHostModal);
