import { types } from "mobx-state-tree";

import Request from "api/Request";
import ImportConnectionArguments from "api/vpn/Types/ImportConnectionArguments";

const ImportConnection = Request.props({
    path: "/com/studionetworksolutions/evo/manage/VPN",
    method: "import_connection",
    arguments: types.maybe(ImportConnectionArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ImportConnection;
