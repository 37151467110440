import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./RenderDetails.styles";
import { useStore } from "hooks/useStore";
import { LONG_LIVED_TOKENS_FORMAT } from "const";
import moment from "moment";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const RenderDetails = () => {
    const {
        store: { longLivedTokensStore },
    } = useStore();
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid container direction={"column"} className={classes.detailsContainer} spacing={4}>
            {longLivedTokensStore.currentTokenInfo && (
                <>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("admin_settings.long_lived_tokens_card.bulk_bar.label.name")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>{longLivedTokensStore.currentTokenInfo.userName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("admin_settings.long_lived_tokens_card.bulk_bar.label.expiration_date")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>
                                {moment(longLivedTokensStore.currentTokenInfo.expirationDate).format(
                                    getLanguageDateFormat(LONG_LIVED_TOKENS_FORMAT)
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default RenderDetails;
