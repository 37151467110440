import React, { useContext } from "react";
import { useObserver } from "mobx-react";
import MainTemplate from "components/MainTemplate";
import { Grid } from "@material-ui/core";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import RebootIcon from "assets/RebootIcon";
import HeaderTitle from "components/HeaderTitle";
import MasterResetCard from "./components/MasterResetCard";
import DeinitializationCard from "./components/DeinitializationCard";

const MasterReset = () => {
    const { t } = useTranslation();
    const {
        store: { authStore }
    } = useContext(Context);
    const hideHeaderButton = !authStore.isAuthorized;

    return useObserver(() => (
        <MainTemplate
            headerTitle={
                <HeaderTitle icon={<RebootIcon />} title={t("master_reset.title")} hideHeaderButton={hideHeaderButton} />
            }
        >
            <Grid container alignContent={"flex-start"} spacing={6}>
                <Grid item xs={12} md={6}>
                    <MasterResetCard />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DeinitializationCard />
                </Grid>
            </Grid>
        </MainTemplate>
    ));
};

export default MasterReset;
