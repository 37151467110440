import React from "react";

const SignalIcon = props => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <g fill="#1E90FF" fillRule="nonzero">
                    <g>
                        <path
                            d="M12 18c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1s-1-.448-1-1v-2c0-.552.448-1 1-1zm-4.243-1.757c.39.39.39 1.023 0 1.414L6.343 19.07c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.024 0-1.414l1.414-1.414c.39-.39 1.024-.39 1.414 0zm9.9 0l1.414 1.414c.39.39.39 1.024 0 1.414-.39.39-1.024.39-1.414 0l-1.414-1.414c-.39-.39-.39-1.024 0-1.414.39-.39 1.023-.39 1.414 0zM12 8c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 2c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm9 1c.552 0 1 .448 1 1s-.448 1-1 1h-2c-.552 0-1-.448-1-1s.448-1 1-1h2zM5 11c.552 0 1 .448 1 1s-.448 1-1 1H3c-.552 0-1-.448-1-1s.448-1 1-1h2zm14.071-6.071c.39.39.39 1.024 0 1.414l-1.414 1.414c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.023 0-1.414l1.414-1.414c.39-.39 1.024-.39 1.414 0zm-12.728 0l1.414 1.414c.39.39.39 1.024 0 1.414-.39.39-1.023.39-1.414 0L4.93 6.343c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0zM12 2c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1s-1-.448-1-1V3c0-.552.448-1 1-1z"
                            transform="translate(-460.000000, -256.000000) translate(460.000000, 256.000000)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default SignalIcon;
