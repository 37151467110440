import React from "react";
import { useLocalStore, useObserver } from "mobx-react";
import Dialog from "components/Dialog";
import Switch from "components/MobxForm/Switch";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import { Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import createForm from "utils/createForm";
import createFields from "./createFields";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";

const SetupShareBrowserSettingsDialog = ({ open, onClose }) => {
    const { t } = useTranslation();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields()
        }),
        isSubmitted: false
    }));

    const {
        store: { sharebrowserStore }
    } = useStore();

    const unsavedChangesModal = useModal();

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const onSubmit = async () => {
        let res = false;
        if (state.form.$("enableServer").value) {
            res = await sharebrowserStore.startSharebrowserServer();
        } else {
            res = await sharebrowserStore.endSharebrowserServer();
        }
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    const updateForm = () => {
        state.isSubmitted = false;
        state.form.$("enableServer").set("default", sharebrowserStore.serverStatus);
        state.form.reset();
    };

    return useObserver(() => (
        <Dialog
            title={t("sharebrowser_page.edit_card.title")}
            submitBtnLabel={t("common.button.save")}
            open={open}
            onSubmit={onSubmit}
            onEnter={updateForm}
            onClose={closeIfNeeded}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary">
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"}>
                <RowControlLabelSwitch
                    control={<Switch field={state.form.$("enableServer")} />}
                    label={t("sharebrowser_page.card.label.server_disable")}
                />
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default SetupShareBrowserSettingsDialog;
