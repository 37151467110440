import React, { useContext, useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { Context } from "store";
import DataCard from "components/DataCard";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "assets/ChevronRightIcon";
import TableRowControlButton from "components/TableRowControlButton";
import TableSortLabel from "components/TableSortLabel";
import { AUDIT_SHARE_NAME, AUDIT_SHARE_ENABLED } from "const/sortColumnConst";
import SavedInputIndicator from "components/SavedInputIndicator";
import ShareForAuditDrawer from "../ShareForAuditDrawer/ShareForAuditDrawer";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { AUDIT_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const AUDIT_PER_SHARE_SETTINGS_CARD_ID_PREFIX = `${AUDIT_PAGE_ID_PREFIX}_audit_per_share_settings_card`;

const AuditPerShareSettingsCard = () => {
    const { t } = useTranslation();

    const {
        store: { auditStore },
    } = useContext(Context);
    const state = useLocalStore(() => ({
        inProgress: false,
    }));
    useEffect(() => {
        state.inProgress = true;
        auditStore.fetchAuditPerShareSettings();
        state.inProgress = false;
    }, []);
    const changeSorting = (column) => () => auditStore.changeSortingShare(column);

    const onRowClick = (shareName) => () => {
        auditStore.setCurrentShareName(auditStore.currentShareName !== shareName ? shareName : null);
    };

    return useObserver(() => (
        <DataCard title={t("audit.audit_per_share_settings.title")} headerDivider emptyPadding autoHeight>
            <ShareForAuditDrawer />
            {auditStore.sortedShares.length ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(AUDIT_SHARE_ENABLED)}
                                    direction={auditStore.order}
                                    active={auditStore.orderBy === AUDIT_SHARE_ENABLED}
                                    id={`${AUDIT_PER_SHARE_SETTINGS_CARD_ID_PREFIX}_audit_enabled_sort`}
                                >
                                    {t("audit.audit_per_share_settings.header.audit_enabled")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(AUDIT_SHARE_NAME)}
                                    direction={auditStore.order}
                                    active={auditStore.orderBy === AUDIT_SHARE_NAME}
                                    id={`${AUDIT_PER_SHARE_SETTINGS_CARD_ID_PREFIX}_share_name_sort`}
                                >
                                    {t("audit.audit_per_share_settings.header.share_name")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {auditStore.sortedShares.map((share) => {
                            return (
                                <TableRow
                                    selected={share.shareName === auditStore.currentShareName}
                                    key={Math.floor(Math.random() * Date.now())}
                                    onClick={onRowClick(share.shareName)}
                                >
                                    <TableCell width={"30%"}>
                                        <SavedInputIndicator enabled={share.enabled} />
                                    </TableCell>
                                    <TableCell>{share.shareName} </TableCell>
                                    <TableCell align={"right"}>
                                        <TableRowControlButton
                                            icon={<ChevronRightIcon />}
                                            id={`${AUDIT_PER_SHARE_SETTINGS_CARD_ID_PREFIX}_button`}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <EmptyCardPlaceholder inProgress={state.inProgress} message={t("audit.audit_per_share_settings.placeholder")} />
            )}
        </DataCard>
    ));
};

export default AuditPerShareSettingsCard;
