import React, { useCallback } from "react";
import { Table, TableCell, TableHead, TableRow, TableBody, Box, Grid, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./EditAclNfsShareModal.styles";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import Dialog from "components/Dialog";
import createFields from "./createFields";
import createInitialFields from "./createInitialFields";
import createForm from "utils/createForm";
import TrashIcon from "assets/TrashIcon";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import TextInput from "components/MobxForm/TextInput";
import Select from "components/MobxForm/Select";
import useStoreByIp from "hooks/useStoreByIp";
import { useModal } from "hooks/useModal";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const EDIT_ACL_NFS_SHARE_MODAL_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_edit_acl_nfs_share_modal`;

const EditAclNfsShareModal = ({ open, onClose, afterSubmitCallback, ip }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { aclStore, shareStore } = useStoreByIp({ ip });

    const ACCESS_OPTIONS = [
        { label: t("share.shared_pool_card.edit_nfc_acl.access_options.read"), value: "rw" },
        { label: t("share.shared_pool_card.edit_nfc_acl.access_options.read_only"), value: "ro" },
        { label: t("share.shared_pool_card.edit_nfc_acl.access_options.no_access"), value: "no" },
    ];

    const state = useLocalStore(() => ({
        isSearchInProgress: false,
        actionInProgress: false,
        form: createForm({
            fields: createInitialFields(),
        }),
        entriesList: [],
        isSubmitted: true,
        inProgress: false,
    }));

    const shareName = aclStore.currentShareNameWithPrefix || shareStore.currentCheckedShareName || shareStore.currentShareName;
    const shareNameWithPrefix =
        aclStore.currentShareNameWithPrefix ||
        shareStore.currentCheckedShareNameWithPrefix ||
        shareStore.currentShareNameWithPrefix;

    const onEnter = async () => {
        state.form.$("access").set("default", ACCESS_OPTIONS[0].value);
        state.entriesList = aclStore.allowedHosts.map((el, index) => {
            return (el = { ...el, index: index });
        });
        const fields = createFields(aclStore.allowedHosts);
        fields.forEach((f) => state.form.add(f));
        state.form.reset();
        state.isSubmitted = true;
        unsavedChangesModal.close();
    };

    const unsavedChangesModal = useModal();

    const onSubmit = useCallback(async () => {
        state.inProgress = true;
        let acl = [];
        state.entriesList.forEach((entry) => {
            acl.push({ host: state.form.$(`client${entry.index}`).value, access: state.form.$(`access${entry.index}`).value });
        });

        const res = await aclStore.setAllowedHosts({
            shareName,
            acl: acl,
        });

        state.inProgress = false;
        res && (state.isSubmitted = true);
        return res;
    });

    const addEntry = (e) => {
        state.form.onSubmit(e);
        if (state.form.$("client").value.trim() === "") return;
        const index = state.entriesList[state.entriesList.length - 1]?.index + 1 || 0;
        const newEntry = {
            index: index,
            host: state.form.$("client").value,
            access: state.form.$("access").value,
        };
        state.entriesList.push(newEntry);
        state.form.add({ name: `client${index}`, value: state.form.$("client").value });
        state.form.add({ name: `access${index}`, value: state.form.$("access").value });
        state.form.$("client").reset();
        state.form.$("access").reset();
        state.isSubmitted = false;
    };

    const removeEntry = (index) => () => {
        const entryIndex = state.entriesList.findIndex((entry) => entry.index === index);
        state.entriesList.splice(entryIndex, 1);
        state.isSubmitted = false;
    };

    const closeIfNeeded = () => {
        if (state.isSubmitted) {
            onClose();
            shareStore.clearAfterCreateShareData();
            return;
        }

        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            afterSubmitCallback={afterSubmitCallback}
            withoutPaddings
            title={t("share.shared_pool_card.edit_nfc_acl", {
                name: shareNameWithPrefix,
            })}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${EDIT_ACL_NFS_SHARE_MODAL_ID_PREFIX}_save`}
            onSubmit={onSubmit}
            open={open}
            onClose={closeIfNeeded}
            onEnter={onEnter}
            inProgress={state.inProgress}
            buttons={
                <Button
                    onClick={onClose}
                    variant={"contained"}
                    color={"secondary"}
                    id={`${EDIT_ACL_NFS_SHARE_MODAL_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
            additionalTitle={
                <Box pt={4} pb={4} pr={6} pl={6}>
                    <Grid container wrap={"nowrap"} alignItems={"center"} justify={"space-between"}>
                        <Grid item xs={2}>
                            <Typography>{t("share.shared_pool_card.edit_nfc_acl.client")}</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container item spacing={4} alignItems={"center"} wrap={"nowrap"} justify={"flex-end"}>
                                <Grid item>
                                    <TextInput field={state.form.$("client")} d={`${EDIT_ACL_NFS_SHARE_MODAL_ID_PREFIX}_name`} />
                                </Grid>
                                <Grid item>
                                    <Select
                                        className={classes.access}
                                        field={state.form.$("access")}
                                        options={ACCESS_OPTIONS}
                                        id={`${EDIT_ACL_NFS_SHARE_MODAL_ID_PREFIX}_access`}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={addEntry}
                                        variant={"contained"}
                                        color={"primary"}
                                        id={`${EDIT_ACL_NFS_SHARE_MODAL_ID_PREFIX}_add`}
                                    >
                                        {t("common.button.add")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            }
        >
            {state.entriesList.length > 0 ? (
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("share.shared_pool_card.edit_nfc_acl.table.column.client")}</TableCell>
                            <TableCell>{t("share.shared_pool_card.edit_nfc_acl.table.column.access")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.entriesList.map((entry) => {
                            return (
                                <TableRow key={entry.index}>
                                    <TableCell className={classes.tableCell}>
                                        <TextInput
                                            field={state.form.$(`client${entry.index}`)}
                                            id={`${EDIT_ACL_NFS_SHARE_MODAL_ID_PREFIX}_${entry.index}_name`}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Select
                                            className={classes.access}
                                            field={state.form.$(`access${entry.index}`)}
                                            options={ACCESS_OPTIONS}
                                            id={`${EDIT_ACL_NFS_SHARE_MODAL_ID_PREFIX}_${entry.index}_access`}
                                        />
                                    </TableCell>
                                    <TableCell width={"10%"} onClick={removeEntry(entry.index)}>
                                        <TrashIcon />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <EmptyCardPlaceholder
                    inProgress={state.isSearchInProgress}
                    message={t("share.shared_pool_card.edit_nfc_acl.table.placeholder")}
                />
            )}
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default EditAclNfsShareModal;
