import React from "react";

const UserIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="nonzero"
                d="M12 13l.24.004A7 7 0 0 1 19 20l-.007.117A1 1 0 0 1 17 20l-.005-.217A5 5 0 0 0 7 20l-.007.117A1 1 0 0 1 5 20l.004-.24A7 7 0 0 1 12 13zm0-10a4 4 0 1 1 0 8l-.2-.005A4 4 0 0 1 8 7l.005-.2A4 4 0 0 1 12 3zm0 2l-.15.005A2 2 0 0 0 10 7l.005.15A2 2 0 1 0 12 5z"
            />
        </svg>
    );
};

export default UserIcon;
