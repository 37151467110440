import moment from "moment";
import i18n from "i18n";
import { SLINGSHOT_DATE_HOURS_FORMAT } from "const";
import { getLanguageDateFormat } from "./getLanguageDateFormat";

const HOURLY_INTERVAL = 60;
const DAILY_INTERVAL = HOURLY_INTERVAL * 24;
const WEEKLY_INTERVAL = DAILY_INTERVAL * 7;
const ONLY_ONCE = -1;

const getSlingshotSchedule = (job, evoTime) => {
    if (!job.schedule) {
        return i18n.t("slingshot_page.tabs.replications.table.schedule.message.manual");
    }

    const scheduleObj = job.schedule;
    const { minutes, date, not_before, not_after } = scheduleObj;
    const localFormat = getLanguageDateFormat(SLINGSHOT_DATE_HOURS_FORMAT);
    if (!minutes) {
        return i18n.t("slingshot_page.tabs.replications.table.schedule.message.manual");
    }

    const now = moment.unix(evoTime);
    if (not_after && now.isAfter(moment(not_after))) {
        return i18n.t("slingshot_page.tabs.replications.table.schedule.message.expired");
    }
    if (not_before) {
        const notBefore = moment(not_before);
        if (now.isBefore(notBefore)) {
            return i18n.t("slingshot_page.tabs.replications.table.schedule.message.waiting_for", {
                date: notBefore.format(localFormat),
            });
        }
    }

    switch (minutes) {
        case HOURLY_INTERVAL:
            return i18n.t("slingshot_page.tabs.replications.table.schedule.message.hourly");
        case DAILY_INTERVAL:
            return i18n.t("slingshot_page.tabs.replications.table.schedule.message.daily", {
                date: moment.utc(date).format("HH:mm"),
            });
        case WEEKLY_INTERVAL: {
            const wi = moment.utc(date);
            return i18n.t("slingshot_page.tabs.replications.table.schedule.message.weekly", {
                date: `${wi.format("ddd")}\u00A0${wi.format("HH:mm")}`,
            });
        }
        case ONLY_ONCE:
            return i18n.t("slingshot_page.tabs.replications.table.schedule.message.once_at", {
                date: moment.utc(date).format(localFormat),
            });
        default:
            return i18n.t("slingshot_page.tabs.replications.table.schedule.message.other");
    }
};

export default getSlingshotSchedule;
