import { types } from "mobx-state-tree";

import NetInterface from "api/net/Types/NetInterface";

const IfacesArrayResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(NetInterface)
});

export default IfacesArrayResult;
