import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useStore } from "hooks/useStore";
import { useTranslation } from "react-i18next";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography, Box } from "@material-ui/core";
import TrashIcon from "assets/TrashIcon";
import ClearIcon from "assets/ClearIcon";
import ResetIcon from "assets/ResetIcon";
import { BRICK_STATUS_OPTIONS } from "const/clusterConst";
import config from "config";
import { GB_METRIC, GiB_IEC } from "const/diskSizeConst";
import StatusLabel from "../LocalBricksCard/components/StatusLabel";

import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";

const drawerName = "bricksDrawer";
const BricksDrawer = () => {
    const { t } = useTranslation();

    const {
        store: { clusterStore }
    } = useStore();

    const state = useLocalStore(() => ({
        isRemoveBrickInProgress: false,
        isClearBrickInProgress: false,
        isResetBrickInProgress: false
    }));

    const decimalSizeUnits = config.decimalSizeUnits ? GB_METRIC.value : GiB_IEC.value;

    const closeDrawer = () => {
        clusterStore.setCurrentBrickId(null);
    };

    const removeBrick = async () => {
        state.isRemoveBrickInProgress = true;
        const res = await clusterStore.removeBrick({
            path: clusterStore.currentBrick?.path,
            name: clusterStore.currentBrick?.name
        });
        state.isRemoveBrickInProgress = false;
        res && closeDrawer();
    };

    const clearBrick = async () => {
        state.isClearBrickInProgress = true;
        const res = await clusterStore.clearBrick({
            path: clusterStore.currentBrick?.path,
            name: clusterStore.currentBrick?.name
        });
        state.isClearBrickInProgress = false;
        res && closeDrawer();
    };

    const resetBrick = async () => {
        state.isResetBrickInProgress = true;
        const res = await clusterStore.resetBrick({
            path: clusterStore.currentBrick?.path,
            name: clusterStore.currentBrick?.name
        });
        state.isResetBrickInProgress = false;
        res && closeDrawer();
    };

    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: clusterStore.currentBrickId });

    const renderDetails = () => {
        return (
            clusterStore.currentBrick && (
                <Box py={4} px={6}>
                    <Grid container direction={"column"} spacing={4}>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("cluster.local_bricks.header.cluster_path_on_evo")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h6"}>{clusterStore.currentBrick?.path}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("cluster.local_bricks.header.brick_name")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h6"}>{clusterStore.currentBrick?.name}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("cluster.local_bricks.header.used")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h6"}>
                                    {(clusterStore.currentBrick?.size?.used / decimalSizeUnits).toFixed(2)}
                                    {" / "}
                                    {(clusterStore.currentBrick?.size?.total / decimalSizeUnits).toFixed(2)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("cluster.local_bricks.header.volume")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h6"}>{clusterStore.currentBrick?.volume || "-"}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("cluster.local_bricks.header.status")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <StatusLabel status={clusterStore.currentBrick?.status} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )
        );
    };

    const renderActions = () => {
        return (
            clusterStore.currentBrick && (
                <Grid direction={"column"} container>
                    {clusterStore.currentBrick?.status === BRICK_STATUS_OPTIONS.dirty && (
                        <BulkBarFlatButton onClick={clearBrick} inProgress={state.isClearBrickInProgress} icon={<ClearIcon />}>
                            {t("cluster.local_bricks.button.clear")}
                        </BulkBarFlatButton>
                    )}
                    {clusterStore.currentBrick?.status === BRICK_STATUS_OPTIONS.corrupt &&
                        clusterStore.currentBrick?.volume !== "" && (
                            <BulkBarFlatButton
                                onClick={resetBrick}
                                inProgress={state.isResetBrickInProgress}
                                icon={<ResetIcon />}
                            >
                                {t("common.button.reset")}
                            </BulkBarFlatButton>
                        )}
                    <BulkBarFlatButton
                        disabled={clusterStore.currentBrick?.status !== BRICK_STATUS_OPTIONS.notAllocated}
                        onClick={removeBrick}
                        inProgress={state.isRemoveBrickInProgress}
                        icon={<TrashIcon />}
                        errorColor
                    >
                        {t("common.button.delete")}
                    </BulkBarFlatButton>
                </Grid>
            )
        );
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            title={clusterStore.currentBrick?.name || ""}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
        />
    ));
};

export default BricksDrawer;
