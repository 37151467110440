import { flow, getParent, types } from "mobx-state-tree";
import Socket from "websocket";
import GetNotifications from "api/events/Requests/GetNotifications";
import NotificationsResult from "api/events/Responses/NotificationsResult";
import SetNotifications from "api/events/Requests/SetNotifications";
import GetSnmpAgentSettings from "api/events/Requests/GetSnmpAgentSettings";
import SnmpAgentSettingsResult from "api/events/Responses/SnmpAgentSettingsResult";
import SetSnmpAgentSettings from "api/events/Requests/SetSnmpAgentSettings";
import DownloadEvoMibFile from "api/events/Requests/DownloadEvoMibFile";
import GetSnmpServerSettings from "api/events/Requests/GetSnmpServerSettings";
import SnmpServerSettingsResult from "api/events/Responses/SnmpServerSettingsResult";
import SetSnmpServerSettings from "api/events/Requests/SetSnmpServerSettings";
import axios from "api/AxiosCommonRequest";
import { FILE_DOWNLOAD_URL } from "api/restRoutes";
import { saveAs } from "file-saver";
import i18n from "i18n";
import GetUsedSpaceEventsSettings from "api/events/Requests/GetUsedSpaceEventsSettings";
import UsedSpaceEventsSettingsResult from "api/events/Responses/UsedSpaceEventsSettingsResult";
import SetUsedSpaceEventsSettings from "api/events/Requests/SetUsedSpaceEventsSettings";
import GetBeepMuteState from "api/events/Requests/GetBeepMuteState";
import BeepUnmute from "api/events/Requests/BeepUnmute";
import BeepMute from "api/events/Requests/BeepMute";
import BeepMuteStateResult from "api/events/Responses/BeepMuteStateResult";

const AlertsStore = types
    .model({
        notificationResult: types.maybe(NotificationsResult),
        snmpAgentSettingsResult: types.maybe(SnmpAgentSettingsResult),
        snmpServerSettingsResult: types.maybe(SnmpServerSettingsResult),
        usedSpaceEventsSettingsStore: types.maybe(UsedSpaceEventsSettingsResult),
        beepMuteStateStore: types.maybe(BeepMuteStateResult)
    })
    .views(self => ({
        get notifications() {
            return self.notificationResult?.data || {};
        },
        get notificationKeys() {
            return Object.keys(this.notifications);
        },
        get snmpAgentSettings() {
            return self.snmpAgentSettingsResult?.data || {};
        },
        get snmpServerSettings() {
            return self.snmpServerSettingsResult?.data || {};
        },
        get snmpServerTransport() {
            let res = "";
            if (self.snmpServerSettings.udpEnabled) {
                if (self.snmpServerSettings.tcpEnabled) {
                    res = i18n.t("alerts.snmp_service_configuration_card.transport.tcp_udp");
                } else {
                    res = i18n.t("alerts.snmp_service_configuration_card.transport.udp");
                }
            } else if (self.snmpServerSettings.tcpEnabled) {
                res = i18n.t("alerts.snmp_service_configuration_card.transport.tcp");
            }
            return res;
        },
        get usedSpaceEventsSettings() {
            return self.usedSpaceEventsSettingsStore?.data || {};
        },
        get beepMuteState() {
            return self.beepMuteStateStore?.data || {};
        }
    }))
    .actions(self => ({
        fetchNotifications: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetNotifications.create().init();
                const res = yield Socket.send(req);
                self.notificationResult = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchUsageThreshold: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetUsedSpaceEventsSettings.create().init();
                const res = yield Socket.send(req);
                self.usedSpaceEventsSettingsStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setNotifications: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetNotifications.create().init(data);
                yield Socket.send(req);
                self.notificationResult.data = data;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
            return data;
        }),
        fetchSnmpAgentSettings: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetSnmpAgentSettings.create().init();
                const res = yield Socket.send(req);
                self.snmpAgentSettingsResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setSnmpAgentSettings: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetSnmpAgentSettings.create().init(data);
                const res = yield Socket.send(req);
                self.snmpAgentSettingsResult?.data && (self.snmpAgentSettingsResult.data = data);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchSnmpServerSettings: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetSnmpServerSettings.create().init();
                const res = yield Socket.send(req);
                self.snmpServerSettingsResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setSnmpServerSettings: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetSnmpServerSettings.create().init(data);
                const res = yield Socket.send(req);
                self.snmpServerSettingsResult?.data && (self.snmpServerSettingsResult.data = data);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateSnmpServerSettings: settings => {
            self.snmpServerSettingsResult?.data && (self.snmpServerSettingsResult.data = settings);
        },
        updateUsedSpaceEventsSettings: settings => {
            self.usedSpaceEventsSettingsStore?.data && (self.usedSpaceEventsSettingsStore.data = settings);
        },
        getEvoMibFile: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DownloadEvoMibFile.create().init();
                const res = yield Socket.send(req);
                yield self.downloadEvoMibFile(res.data?.filename);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        downloadEvoMibFile: flow(function*(fileName) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const config = { responseType: "blob", params: { file: fileName } };
                const res = yield axios.get(FILE_DOWNLOAD_URL, config);
                saveAs(new Blob([res.data]), fileName);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        saveUsageThreshold: flow(function*(threshold) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetUsedSpaceEventsSettings.create().init({
                    spaceThreshold: threshold,
                    systemCheckEnabled: true,
                    sharesCheckEnabled: true
                });
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchMuteState: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetBeepMuteState.create().init();
                const res = yield Socket.send(req);
                self.beepMuteStateStore = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        muteBeepFor: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = BeepMute.create().init(data);
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        unmuteBeep: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = BeepUnmute.create().init();
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateMuteState: broadcast => {
            self.beepMuteStateStore?.data && (self.beepMuteStateStore.data = broadcast);
        }
    }));

export default AlertsStore;
