import { types, flow, getParent } from "mobx-state-tree";
import MainSocket from "websocket";
import GetQuadsStatus from "api/drive/Requests/GetQuadsStatus";
import GetQuadsStatusType from "api/drive/Responses/QuadsStatusResult";
import GetDriveSmartReport from "api/drive/Requests/GetDriveSmartReport";
import GetIdentifyStatus from "api/drive/Requests/GetIdentifyStatus";
import IdentifyOn from "api/drive/Requests/IdentifyOn";
import IdentifyOff from "api/drive/Requests/IdentifyOff";
import GetIdentifyStatusResult from "api/drive/Responses/GetIdentifyStatusResult";
import SmartReportResult from "api/drive/Responses/SmartReportResult";
import { EVO_BASE_INDEX } from "const/headerQuadsStatusConst";

const DriveStore = types
    .model({
        quadsStatusStore: types.maybe(GetQuadsStatusType),
        driveSmartReportStore: types.maybe(SmartReportResult),
        identifyStore: types.maybe(GetIdentifyStatusResult),
        currentDriveName: types.maybeNull(
            types.model({ drive: types.number, quad: types.number }).views((self) => ({
                get name() {
                    return `${self.drive}-${self.quad}`;
                },
            }))
        ),
    })
    .views((self) => ({
        get enclosures() {
            return (self.quadsStatusStore && self.quadsStatusStore.data.enclosures) || [];
        },
        get enclosuresLength() {
            return (self.enclosures && self.enclosures.length) || 0;
        },
        get evoBase() {
            return self.enclosures && self.enclosures[EVO_BASE_INDEX];
        },
        get evoBaseDrivesLength() {
            let length = 0;
            if (self.evoBase) {
                self.evoBase.quadStatus.forEach((quad) => (length += quad.driveStatus.length));
            }
            return length;
        },
        get expansionsStatuses() {
            let statuses = [];
            if (self.enclosures) {
                self.enclosures.forEach((enclosure) => {
                    let enclosureData = { enclosureName: enclosure.enclosure, enclosureStatuses: [] };
                    enclosure.quadStatus.forEach((quad) =>
                        quad.driveStatus.forEach((drive) => enclosureData.enclosureStatuses.push(drive.status))
                    );
                    statuses.push(enclosureData);
                });
            }
            return statuses;
        },
        get driveSmartReport() {
            return (self.driveSmartReportStore && self.driveSmartReportStore.data) || "";
        },
        get identify() {
            return self.identifyStore && self.identifyStore.data;
        },
        get currentDrive() {
            if (!self.currentDriveName) return null;
            const foundEnclosure = this.enclosures.find((enclosure) =>
                enclosure.quadStatus.find((quad) => quad.quad === self.currentDriveName.quad)
            );
            const foundQuad = foundEnclosure?.quadStatus.find((quadStatus) => quadStatus.quad === self.currentDriveName.quad);

            return foundQuad?.driveStatus.find((status) => status.drive === self.currentDriveName.drive);
        },
        get failedDrives() {
            let failedQuads = [];
            self.enclosures.forEach((enclosure) => {
                enclosure.quadStatus.forEach((quad) =>
                    quad.driveStatus.forEach((drive) => {
                        if (drive.status === "failed" || drive.status === "replaced")
                            failedQuads.push({ quad: quad.quad, drive: drive.drive, status: drive.status, type: drive.type });
                    })
                );
            });
            return failedQuads;
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
    }))
    .actions((self) => ({
        fetchQuadsStatus: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetQuadsStatus.create().init();
                const res = yield self.socket.send(req);
                self.quadsStatusStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getDriveSmartReport: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetDriveSmartReport.create().init({
                    quad: self.currentDriveName?.quad,
                    drive: self.currentDriveName?.drive,
                });
                const res = yield self.socket.send(req);
                self.driveSmartReportStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getIdentifyStatus: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetIdentifyStatus.create().init();
                const res = yield self.socket.send(req);
                self.identifyStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        identifyOn: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = IdentifyOn.create().init(data);
                yield self.socket.send(req);
                self.getIdentifyStatus();
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        identifyOff: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = IdentifyOff.create().init();
                yield self.socket.send(req);
                self.getIdentifyStatus();
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setCurrentDriveName: (data) => {
            self.currentDriveName = data;
        },
        updateEnclosuresState: (data) => {
            self.quadsStatusStore && (self.quadsStatusStore.data.enclosures = data);
        },
    }));

export default DriveStore;
