import React from "react";
import { observer } from "mobx-react";
import { useStyles } from "./ErrorMessage.styles";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import { useToggle } from "hooks/useToggle";
import i18next from "i18next";
import { useStore } from "hooks/useStore";

const ACL_STAGE_NAME = "migrator.stage.acl_database";

const ErrorMessage = ({ errors = [], name, stageState }) => {
    const {
        store: { migrationStore },
    } = useStore();
    const classes = useStyles();

    const checkPedningState = (stageState) => {
        switch (stageState) {
            case "finished":
            case "error":
            case "warn":
                return false;
            case "not_started":
            default:
                if (migrationStore.startedProcceedMigration || migrationStore.isRunningMigration) return true;
                return false;
        }
    };

    const isPending = checkPedningState(stageState);
    const isAclProgress = isPending && name === ACL_STAGE_NAME;

    const aclProgressProcessed = migrationStore.aclMigrationProgressChangedResult?.processed ?? 0;
    const aclProgressTotal = migrationStore.aclMigrationProgressChangedResult?.total ?? 0;
    const aclProgressPrecent = (Number(aclProgressProcessed) / Number(aclProgressTotal)) * 100;

    const [isShow, toggleShow] = useToggle(isAclProgress);

    const isEmptyList = !errors.length || !errors.every((error) => Boolean(error));
    if ((isEmptyList && isAclProgress === false) || (isAclProgress === true && Number.isNaN(aclProgressPrecent))) return null;

    return (
        <React.Fragment>
            <Accordion className={classes.accordion} square expanded={isShow}>
                <AccordionSummary onClick={toggleShow}>
                    <Typography className={classes.summary}>
                        {i18next.t(isShow ? "migrator.main_step.details.button.hide" : "migrator.main_step.details.button.show")}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {isAclProgress ? (
                        <Typography className={classes.details}>
                            {`Progress: ${
                                Number.isNaN(aclProgressPrecent) ? 0 : aclProgressPrecent.toFixed(2)
                            }% (${aclProgressProcessed}/${aclProgressTotal})`}
                        </Typography>
                    ) : null}
                    {errors.map((error) => (
                        <Typography key={error} className={classes.details}>
                            {error}
                        </Typography>
                    ))}
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
};

export default observer(ErrorMessage);
