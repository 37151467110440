import { types } from "mobx-state-tree";

import Request from "api/Request";
import NetworkTestArguments from "api/troubleshooting/Types/NetworkTestArguments";

const StartNetworkSelfTest = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Troubleshooting",
    method: "start_network_self_test",
    arguments: types.maybe(NetworkTestArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default StartNetworkSelfTest;
