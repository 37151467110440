import React, { useEffect } from "react";
import { Button, Grid, Typography, Divider, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, observer } from "mobx-react-lite";
import Dialog from "components/Dialog";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import useStoreByIp from "hooks/useStoreByIp";
import { useModal } from "hooks/useModal";
import { useStyles } from "./ReorderEthernetPortsDialog.styles";
import DraggableContainer from "components/DraggableContainer/DraggableContainer";
import BurgerButtonIcon from "assets/BurgerButtonIcon";
import { ETH_PORTS } from "const/dragAndDropTypes";
import DragLayer from "components/DragLayer/DragLayer";
import SavedInputIndicator from "components/SavedInputIndicator/SavedInputIndicator";
import clsx from "clsx";
import { isEthernetPortUsedByBridgeOrBond } from "utils/isEthernetPortUsedByBridgeOrBond";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import ConfirmationDialog from "components/ConfirmationDialog";

const ReorderEthernetPortsDialog = ({ open, handleClose, ip, afterSubmitCallback }) => {
    const { ethernetPortsStore } = useStoreByIp({ ip });
    const { t } = useTranslation();
    const classes = useStyles({ status: "offline" });
    const unsavedChangesModal = useModal();
    const confirmationDialog = useModal();

    const state = useLocalStore(() => ({
        inProgress: false,
        isSubmitted: false,
        draggableElementTitle: null,
    }));

    const onOpen = () => {
        confirmationDialog.close();
        unsavedChangesModal.close();
        state.isSubmitted = false;
        if (ethernetPortsStore.ports.length) {
            ethernetPortsStore.resetPortsToDefault();
        }
    };

    useEffect(() => {
        ethernetPortsStore.resetPortsToDefault();
    }, [ethernetPortsStore.ports]);

    const handleDrop = (dragIndex, __, hoverIndex, _) => {
        ethernetPortsStore.changePortOrder(dragIndex, hoverIndex);
    };

    const onSubmit = async () => {
        state.inProgress = true;
        const res = await ethernetPortsStore.renameIfaces();
        state.inProgress = false;

        if (res) {
            state.isSubmitted = true;

            ethernetPortsStore.fetchEthernetPorts();

            setTimeout(() => {
                confirmationDialog.close();
                handleClose();
            }, 1000);
        }
    };

    const closeIfNeeded = () => {
        const byPort = (el) => el.port;
        const shadowPortsStore = ethernetPortsStore.shadowPorts.map(byPort).join();
        const shadowPortsCurrent = ethernetPortsStore.sortedPorts
            .filter((port) => !isEthernetPortUsedByBridgeOrBond(port, ethernetPortsStore))
            .filter((port) => !port.builtin)
            .map(byPort)
            .join();
        const equalShadowPorts = shadowPortsStore === shadowPortsCurrent;

        if (equalShadowPorts || state.isSubmitted) {
            handleClose();
            return;
        }

        unsavedChangesModal.open();
    };

    const setupPreview = () => {};
    const resetToDefault = () => {};

    return (
        <Dialog
            buttons={
                <Button onClick={closeIfNeeded} variant={"contained"} color="secondary">
                    {t("common.button.cancel")}
                </Button>
            }
            onEnter={onOpen}
            withoutPaddings
            onClose={closeIfNeeded}
            title={t("network.ethernet_ports.edit_ordering.title")}
            open={open}
            submitBtnLabel={t("common.button.save")}
            onSubmit={confirmationDialog.open}
            omitSubmit={ethernetPortsStore.shadowPorts.length === 0}
            afterSubmitCallback={afterSubmitCallback}
            maxWidth={"md"}
            fullWidth
        >
            <Grid className={classes.content}>
                {ethernetPortsStore.shadowPorts.length !== 0 ? (
                    <>
                        <Grid container>
                            <Grid container item className={clsx(classes.headerItem, classes.headerPort)} alignItems="center">
                                <Typography variant={"h6"}>
                                    {t("network.ethernet_ports.edit_ordering.table_title.port_name")}
                                </Typography>
                            </Grid>
                            <Grid container item className={clsx(classes.headerItem, classes.headerIp)} alignItems="center">
                                <Typography variant={"h6"}>{t("network.ethernet_ports.edit_ordering.table_title.ip")}</Typography>
                            </Grid>
                            <Grid container item className={clsx(classes.headerItem, classes.headerMac)} alignItems="center">
                                <Typography variant={"h6"}>{t("network.ethernet_ports_drawer.label.mac")}</Typography>
                            </Grid>
                            <Grid container item className={clsx(classes.headerItem, classes.headerSpeed)} alignItems="center">
                                <Typography variant={"h6"}>
                                    {t("network.ethernet_ports.edit_ordering.table_title.speed")}
                                </Typography>
                            </Grid>
                            <Grid container item className={clsx(classes.headerItem, classes.headerStatus)} alignItems="center">
                                <Typography variant={"h6"}>{t("network.ethernet_ports_drawer.label.status")}</Typography>
                            </Grid>
                            <Grid container item className={clsx(classes.headerItem, classes.headerButton)}></Grid>
                        </Grid>
                        <Divider />
                        <Box p={4}>
                            <DragLayer previewTitle={state.draggableElementTitle}>
                                <Grid container className={classes.container} wrap="nowrap">
                                    <Grid item className={classes.rowPort} spacing={6}>
                                        <Grid
                                            container
                                            direction="column"
                                            wrap="nowrap"
                                            justifyContent="center"
                                            className={clsx(classes.container)}
                                            item
                                        >
                                            {ethernetPortsStore.shadowPortsInstant?.map((row) => (
                                                <Grid
                                                    container
                                                    item
                                                    key={`${row.port}${row.ip}${row.mac}`}
                                                    className={classes.rowItem}
                                                    alignItems="center"
                                                >
                                                    <Typography>{row.port}</Typography>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.container} item>
                                        {ethernetPortsStore.shadowPorts?.map((row, index) => {
                                            return (
                                                <DraggableContainer
                                                    previewTitle={row.mac}
                                                    setupPreview={setupPreview}
                                                    id={row.ip}
                                                    column={0}
                                                    row={index}
                                                    key={`${row.port}${row.ip}${row.mac}`}
                                                    handleDrop={handleDrop}
                                                    type={ETH_PORTS}
                                                    resetToDefault={resetToDefault}
                                                    accept={ETH_PORTS}
                                                >
                                                    <Grid container item className={classes.rowDrag} spacing={6}>
                                                        <Grid
                                                            container
                                                            item
                                                            className={clsx(classes.rowItem, classes.rowIp)}
                                                            alignItems="center"
                                                        >
                                                            <Typography>{row.ip}</Typography>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            className={clsx(classes.rowItem, classes.rowMac)}
                                                            alignItems="center"
                                                        >
                                                            <Typography>{row.mac}</Typography>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            className={clsx(classes.rowItem, classes.rowSpeed)}
                                                            alignItems="center"
                                                        >
                                                            <Typography>{row.speed}</Typography>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            className={clsx(classes.rowItem, classes.rowStatus)}
                                                            alignItems="center"
                                                        >
                                                            <SavedInputIndicator
                                                                hideLabel
                                                                enabled={row.status === "offline" ? false : true}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            className={clsx(classes.rowItem, classes.rowButton)}
                                                            alignItems="center"
                                                            justify="center"
                                                        >
                                                            <Typography>
                                                                <BurgerButtonIcon className={classes.buger} />
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </DraggableContainer>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </DragLayer>
                        </Box>
                    </>
                ) : (
                    <EmptyCardPlaceholder message={t("network.ethernet_ports.edit_ordering.placeholder")} />
                )}
            </Grid>
            <UnsavedChangesDialog onConfirm={handleClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            <ConfirmationDialog
                onConfirm={onSubmit}
                onClose={confirmationDialog.close}
                open={confirmationDialog.isOpen}
                inProgress={state.inProgress}
                isSubmitted={state.isSubmitted}
                title={t("common.warning_dialog.title")}
                confirmLabel={t("common.button.proceed")}
                cancelLabel={t("common.button.cancel")}
                fullWidth={true}
            >
                <Grid container direction={"column"} spacing={5}>
                    <Grid item>
                        <Typography component={"span"} color={"textPrimary"}>
                            {t("network.ethernet_ports.reorder_ethernet_ports.confirmation.text")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component={"span"} color={"textPrimary"}>
                            {t("common.text.continue")}
                        </Typography>
                    </Grid>
                </Grid>
            </ConfirmationDialog>
        </Dialog>
    );
};

export default observer(ReorderEthernetPortsDialog);
