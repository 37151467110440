import React, { useContext, useEffect } from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import SavedInputIndicator from "components/SavedInputIndicator";
import { Context } from "store";
import { useLocalStore, useObserver } from "mobx-react-lite";
import SavedInputPreview from "components/SavedInputPreview";
import ManageEventsDialog from "../ManageEventsDialog";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { MANAGE_EVENTS_CARD } from "const/widgetNameConst";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const MANAGE_EVENTS_CARD_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_manage_events_card`;

const ManageEventsCard = () => {
    const {
        store: { snmpStore }
    } = useContext(Context);
    const { t } = useTranslation();
    useEffect(() => {
        snmpStore.getSnmpSettings();
    }, []);
    const state = useLocalStore(() => ({
        isDialogOpen: false
    }));
    const closeDialog = () => {
        state.isDialogOpen = false;
    };
    const openDialog = () => {
        state.isDialogOpen = true;
    };
    return useObserver(() => (
        <>
            <ManageEventsDialog open={state.isDialogOpen} onClose={closeDialog} />
            <DataReadonlyCard
                control={
                    <MoreMenu id={`${MANAGE_EVENTS_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={MANAGE_EVENTS_CARD} />
                    </MoreMenu>
                }
                headerDivider
                onClick={openDialog}
                autoHeight
                title={t("alerts.manage_events_card.title")}
            >
                <Grid container direction={"column"} spacing={5}>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.manage_events_card.alerts")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={snmpStore.snmpSettings.enabled} />
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.manage_events_card.host")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{snmpStore.snmpSettings.snmpHost}</SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.manage_events_card.port")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{snmpStore.snmpSettings.snmpPort}</SavedInputPreview>
                        </Grid>
                    </Grid>
                </Grid>
            </DataReadonlyCard>
        </>
    ));
};

export default ManageEventsCard;
