import { types } from "mobx-state-tree";

const AuditSettings = types.model({
    logStorageLocation: types.maybe(types.string),
    authentication: types.boolean,
    fileAccess: types.boolean,
    logOperationsWithAds: types.boolean,
    enabled: types.boolean
});

export default AuditSettings;
