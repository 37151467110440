import React, { useEffect, Suspense } from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { useObserver, useLocalStore, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useStyles } from "./ShareDrawer.styles";
import { convertBytesToSizeUnit } from "utils/convertBytesToSizeUnit";
import { GiB_IEC, GB_METRIC } from "const/diskSizeConst";
import LabelWithIndicator from "components/LabelWithIndicator";
import clsx from "clsx";
import EditShareDialog from "../EditShareDialog";
import EditAclModal from "../EditAclModal";
import SavedInputIndicator from "components/SavedInputIndicator";
import TrashIcon from "assets/TrashIcon";
import CloseIcon from "assets/CloseIcon";
import UnlockMediumIcon from "assets/UnlockMediumIcon";
import EditIcon from "assets/EditIcon";
import SharingIcon from "assets/SharingIcon";
import WarningDialog from "../WarningDialog";
import UnmapConfirmationDialog from "../UnmapConfirmationDialog/UnmapConfirmationDialog";
import config from "config";
import { equalsIgnoreCase } from "utils/equalsIgnoreCase";
import EditUsbAclModal from "../EditUsbAclModal";
import EditAclNfsShareModal from "../EditAclNfsShareModal";
import EditAclFtpShareModal from "../EditAclFtpShareModal";
import { AssignUsersOrGroupsModal } from "../AssignUsersOrGroupsModal/AssignUsersOrGroupsModal";
import CustomizeSettingsModal from "../AssignUsersOrGroupsModal/components/CustomizeSettingsModal";
import { toJS } from "mobx";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const ClearLockConfirmDialog = React.lazy(() => import("../ClearLockConfirmDialog/ClearLockConfirmDialog"));

const SHARE_DRAWER_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_share_drawer`;
const drawerName = "shareDrawer";

const ShareDrawer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();

    const {
        store: { uiStore, rootShareStore, storageUsageGraphStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isUnmapInProgress: false,
        isClearLocksInProgress: false,
        isDeleteInProgress: false,
        isGetShareInfoInProgress: false,
    }));

    const assignUsersOrGroupsModal = useModal();
    const customizeSettingsModalOpen = useModal();
    const editAclFtpModal = useModal();
    const editAclNfsModal = useModal();
    const editAclModal = useModal();
    const editShareModal = useModal();
    const clearLocksConfirmationModal = useModal();
    const warningModal = useModal();
    const unmapConfirmationModal = useModal();

    useEffect(() => {
        if (rootShareStore.currentShareNameWithPrefix) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, closeDrawer);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [rootShareStore.currentShareNameWithPrefix]);

    const closeDrawer = () => {
        rootShareStore.dropAllCurrentShareNames();
    };

    const openEditAclModal = async () => {
        rootShareStore.rootStoreWithCurrentShare &&
            rootShareStore.rootStoreWithCurrentShare.aclStore.setCurrentShareName(
                rootShareStore.currentShareStore.currentShareName
            );

        switch (rootShareStore.currentShareStore.currentShare.attributes.shareType) {
            case "smb":
                editAclModal.open();
                break;
            case "nfs":
                await rootShareStore.rootStoreWithCurrentShare?.aclStore.fetchAllowedHosts({
                    shareName: rootShareStore.rootStoreWithCurrentShare?.aclStore.currentShareName,
                });
                editAclNfsModal.open();
                break;
            case "ftp":
                editAclFtpModal.open();
                break;
            case "sftp":
                editAclFtpModal.open();
                break;
            case "fxp":
                editAclFtpModal.open();
                break;
            default:
                break;
        }
        return;
    };

    const getStatusColor = () => {
        switch (rootShareStore.currentShareStore.currentShare?.status.status.toLowerCase()) {
            case "online":
                return theme.palette.success.main;
            case "offline":
            case "error":
                return theme.palette.error.main;
            default:
                return theme.palette.primary.main;
        }
    };

    const getAccessType = () => {
        switch (rootShareStore.currentShareStore.currentShare?.attributes.accessType) {
            case "rw":
                return t("share.create_share.read_write");
            case "rw_rd":
                return `${t("share.create_share.read_write")}/${t("share.create_share.reject_delete")}`;
            case "ro":
                return t("share.create_share.read_only");
            default:
                return null;
        }
    };

    const clearLocks = async () => {
        clearLocksConfirmationModal.close();
        state.isClearLocksInProgress = true;
        const res = await rootShareStore.currentShareStore.clearLocks({
            shareNames: [rootShareStore.currentShareStore.currentShareName],
        });
        state.isClearLocksInProgress = false;
        res && closeDrawer();
    };

    const unmapEject = async () => {
        unmapConfirmationModal.close();
        state.isUnmapInProgress = true;
        const res = await rootShareStore.currentShareStore.removeShares({
            delete: false,
            shareNames: [rootShareStore.currentShareStore.currentShareName],
        });
        state.isUnmapInProgress = false;
        if (res && uiStore.isWidgetsBarOpen) {
            storageUsageGraphStore.fetchData();
        }
        closeDrawer();
    };

    const deleteShare = async () => {
        warningModal.close();
        state.isDeleteInProgress = true;

        const res = await rootShareStore.currentShareStore.removeShares({
            delete: true,
            shareNames: [rootShareStore.currentShareStore.currentShareName],
        });

        state.isDeleteInProgress = false;
        if (res && uiStore.isWidgetsBarOpen) {
            storageUsageGraphStore.fetchData();
        }
        closeDrawer();
    };

    const openEditShareModal = async () => {
        state.isGetShareInfoInProgress = true;
        const res = await rootShareStore.currentShareStore.getShareInfo({
            shareName: rootShareStore.currentShareStore.currentShareName,
        });
        state.isGetShareInfoInProgress = false;
        if (res) {
            editShareModal.open();
        }
    };

    const convertSize = (size) => {
        return convertBytesToSizeUnit({ bytes: size, exponent: 3, output: "object" });
    };

    const getSizeUnit = () => {
        return config.decimalSizeUnits ? GB_METRIC.unit : GiB_IEC.unit;
    };

    const isLocalSmbShare = () => {
        return (
            rootShareStore.currentShareStore.currentShare &&
            rootShareStore.currentShareStore.currentShare.attributes.shareType === "smb" &&
            rootShareStore.currentShareStore.currentShare.location.pool !== "CLUSTER"
        );
    };

    const closeCustomizeSettingsModal = () => {
        customizeSettingsModalOpen.close();
        rootShareStore.rootStoreWithCurrentShare && rootShareStore.rootStoreWithCurrentShare.aclStore.setCurrentShareName(null);
    };

    const onCustomizeSettingsModalBack = () => {
        closeCustomizeSettingsModal();
        assignUsersOrGroupsModal.open();
    };

    const openCustomizeSettingsDialog = (entity, openUnsavedChangesDialog, force) => () => {
        if (force) {
            assignUsersOrGroupsModal.close();
            rootShareStore.rootStoreWithCurrentShare &&
                rootShareStore.rootStoreWithCurrentShare.userStore.setCurrentUserForChangingAccess(entity);
            customizeSettingsModalOpen.open();
        }

        const preparedEntity = {
            id: toJS(entity.user),
            isExternal: Boolean(entity.isExternal),
            isGroup: Boolean(entity.isGroup),
        };

        if (rootShareStore.rootStoreWithCurrentShare?.userStore.hasAccessChanges && openUnsavedChangesDialog) {
            return openUnsavedChangesDialog(preparedEntity);
        }

        assignUsersOrGroupsModal.close();

        if (rootShareStore.rootStoreWithCurrentShare) {
            !force && rootShareStore.rootStoreWithCurrentShare.userStore.setCurrentUserForChangingAccess(preparedEntity);
        }

        customizeSettingsModalOpen.open();
    };

    const isProjectSharingEnabled = () => {
        return isLocalSmbShare() && rootShareStore.currentShareStore.currentShare.attributes.locking === "project";
    };

    const isProjectSharingConfigurable = () => {
        return isLocalSmbShare() && !equalsIgnoreCase(rootShareStore.currentShareStore.currentShare?.location.pool, "USB");
    };

    const renderDetails = () => {
        return rootShareStore.currentShareStore?.currentShare ? (
            <Grid container direction={"column"} className={classes.detailsContainer} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("share.shared_pool_card.details_drawer.label.name")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{rootShareStore.currentShareNameWithPrefix}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("share.shared_pool_card.details_drawer.label.protocol")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {rootShareStore.currentShareStore.currentShare.shareType}
                        </Typography>
                    </Grid>
                </Grid>
                {isProjectSharingConfigurable() && (
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("share.create_share.project_locking")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SavedInputIndicator enabled={isProjectSharingEnabled()} />
                        </Grid>
                    </Grid>
                )}
                {rootShareStore.currentShareStore.currentShare.attributes.shareType !== "internal" && (
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("share.shared_pool_card.details_drawer.label.access_type")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>{getAccessType()}</Typography>
                        </Grid>
                    </Grid>
                )}
                {rootShareStore.currentShareStore.currentShare.attributes.shareType === "smb" && (
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("share.shared_pool_card.details_drawer.label.recycle_bin")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SavedInputIndicator
                                enabled={rootShareStore.currentShareStore.currentShare.attributes.trashEnabled}
                            />
                        </Grid>
                    </Grid>
                )}

                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>
                            {rootShareStore.currentShareStore.needToShowQuotaOnCurrentShare
                                ? t("share.shared_pool_card.details_drawer.label.used_quota")
                                : t("share.shared_pool_card.details_drawer.label.used")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {rootShareStore.currentShareStore.needToShowQuotaOnCurrentShare
                                ? `${convertSize(rootShareStore.currentShareStore.currentShare.used).value}/${
                                      convertSize(rootShareStore.currentShareStore.currentShare.attributes.quota).value
                                  } ${getSizeUnit()}`
                                : `${convertSize(rootShareStore.currentShareStore.currentShare.used).value} ${getSizeUnit()}`}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("share.shared_pool_card.details_drawer.label.comment")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {rootShareStore.currentShareStore.currentShare.attributes.comment}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("share.shared_pool_card.details_drawer.label.status")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelWithIndicator
                            color={getStatusColor()}
                            label={
                                <Typography className={clsx(classes.textInfo, classes.statusText)}>
                                    {rootShareStore.currentShareStore.currentShare?.status.status}
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
                {rootShareStore.currentShareStore.currentShare.attributes.shareType === "smb" && !isUSBPool && (
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("share.shared_pool_card.details_drawer.label.browseable")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SavedInputIndicator enabled={rootShareStore.currentShareStore.currentShare.attributes.browseable} />
                        </Grid>
                    </Grid>
                )}
                {rootShareStore.currentShareStore.currentShare.status.errorDetails !== "" && (
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("share.shared_pool_card.details_drawer.label.error_details")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>
                                {rootShareStore.currentShareStore.currentShare.status.errorDetails}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {!!rootShareStore.currentShareStore.currentShare.usage.length && (
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("share.shared_pool_card.details_drawer.label.usage")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>
                                {rootShareStore.currentShareStore.currentShare.usage.map((item) => item).join(", ")}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        ) : null;
    };

    const isUSBPool = equalsIgnoreCase(rootShareStore.currentShareStore?.currentShare?.location.pool, "USB");

    const renderActions = () => {
        return rootShareStore.currentShareStore?.currentShare ? (
            <Grid direction={"column"} container>
                <BulkBarFlatButton
                    inProgress={state.isGetShareInfoInProgress}
                    icon={<EditIcon />}
                    onClick={openEditShareModal}
                    id={`${SHARE_DRAWER_ID_PREFIX}_edit`}
                >
                    {t("share.shared_pool_card.table.header.context_menu.edit")}
                </BulkBarFlatButton>
                <BulkBarFlatButton
                    inProgress={state.isUnmapInProgress}
                    icon={<CloseIcon />}
                    onClick={unmapConfirmationModal.open}
                    id={`${SHARE_DRAWER_ID_PREFIX}_unmap`}
                >
                    {t("share.shared_pool_card.table.header.context_menu.unmap")}
                </BulkBarFlatButton>
                {!isUSBPool && (
                    <BulkBarFlatButton
                        inProgress={state.isClearLocksInProgress}
                        icon={<UnlockMediumIcon />}
                        onClick={clearLocksConfirmationModal.open}
                        id={`${SHARE_DRAWER_ID_PREFIX}_clear_locks`}
                    >
                        {t("share.shared_pool_card.table.header.context_menu.clear_locks")}
                    </BulkBarFlatButton>
                )}
                {rootShareStore.currentShareStore.currentShare.attributes.shareType !== "internal" && (
                    <BulkBarFlatButton
                        icon={<SharingIcon />}
                        onClick={openEditAclModal}
                        id={`${SHARE_DRAWER_ID_PREFIX}_edit_acl`}
                    >
                        {t("share.shared_pool_card.table.header.context_menu.edit_acl")}
                    </BulkBarFlatButton>
                )}
                {!isUSBPool && rootShareStore.currentShareStore.currentShare.attributes.shareType === "smb" && (
                    <BulkBarFlatButton
                        icon={<SharingIcon />}
                        onClick={assignUsersOrGroupsModal.open}
                        id={`${SHARE_DRAWER_ID_PREFIX}_assign_users_or_groups`}
                    >
                        {t("share.shared_pool_card.table.header.context_menu.assign_users_or_groups")}
                    </BulkBarFlatButton>
                )}
                {!isUSBPool && (
                    <BulkBarFlatButton
                        errorColor
                        onClick={warningModal.open}
                        icon={<TrashIcon />}
                        inProgress={state.isDeleteInProgress}
                        id={`${SHARE_DRAWER_ID_PREFIX}_delete`}
                    >
                        {t("common.button.delete")}
                    </BulkBarFlatButton>
                )}
                <WarningDialog
                    title={t("share.shares.warning_dialog.title")}
                    open={warningModal.isOpen}
                    submitBtnLabel={t("share.shares.warning_dialog.ok_button")}
                    onClose={warningModal.close}
                    submit={deleteShare}
                    question={
                        <Typography>
                            {t("share.shares.warning_dialog.content", {
                                shareName: rootShareStore.currentShareNameWithPrefix,
                            })}
                        </Typography>
                    }
                />
                <UnmapConfirmationDialog
                    open={unmapConfirmationModal.isOpen}
                    onClose={unmapConfirmationModal.close}
                    submit={unmapEject}
                    content={
                        <Typography>
                            {t("share.shares.unmap_confirmation_dialog.content", {
                                shareName: rootShareStore.currentShareNameWithPrefix,
                            })}
                        </Typography>
                    }
                />
            </Grid>
        ) : null;
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            noWrapHeader={false}
            modals={
                <>
                    <Suspense fallback={null}>
                        <ClearLockConfirmDialog
                            open={clearLocksConfirmationModal.isOpen}
                            onClose={clearLocksConfirmationModal.close}
                            submit={clearLocks}
                            shareNames={rootShareStore.currentShareNameWithPrefix}
                        />
                    </Suspense>
                    <EditShareDialog
                        pool={rootShareStore.currentShareStore.currentShare?.location.pool}
                        afterSubmitCallback={closeDrawer}
                        open={editShareModal.isOpen}
                        onClose={editShareModal.close}
                        ip={rootShareStore.currentShareStoreIp}
                    />
                    {equalsIgnoreCase(rootShareStore.currentShareStore.currentShare?.location.pool, "USB") ? (
                        <EditUsbAclModal
                            ip={rootShareStore.currentShareStoreIp}
                            afterSubmitCallback={closeDrawer}
                            open={editAclModal.isOpen}
                            onClose={editAclModal.close}
                        />
                    ) : (
                        <EditAclModal
                            ip={rootShareStore.currentShareStoreIp}
                            afterSubmitCallback={closeDrawer}
                            open={editAclModal.isOpen}
                            onClose={editAclModal.close}
                        />
                    )}
                    <EditAclNfsShareModal
                        ip={rootShareStore.currentShareStoreIp}
                        afterSubmitCallback={closeDrawer}
                        open={editAclNfsModal.isOpen}
                        onClose={editAclNfsModal.close}
                    />
                    <EditAclFtpShareModal
                        ip={rootShareStore.currentShareStoreIp}
                        afterSubmitCallback={closeDrawer}
                        open={editAclFtpModal.isOpen}
                        onClose={editAclFtpModal.close}
                    />
                    <AssignUsersOrGroupsModal
                        ip={rootShareStore.currentShareStoreIp}
                        open={assignUsersOrGroupsModal.isOpen}
                        onClose={assignUsersOrGroupsModal.close}
                        openCustom={openCustomizeSettingsDialog}
                        afterSubmitCallback={closeDrawer}
                    />
                    <CustomizeSettingsModal
                        ip={rootShareStore.currentShareStoreIp}
                        open={customizeSettingsModalOpen.isOpen}
                        onClose={closeCustomizeSettingsModal}
                        onBack={onCustomizeSettingsModalBack}
                    />
                </>
            }
            title={rootShareStore.currentShareNameWithPrefix}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
        />
    ));
};

export default observer(ShareDrawer);
