import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { useStyles } from "./MemoryTestCard.styles";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";

const MemoryTestCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper className={classes.container}>
            <Grid spacing={4} container>
                <Grid item>
                    <Typography variant={"h2"}>{t("support.self_test.memory.title")}</Typography>
                </Grid>
                <Grid item>
                    <Typography>{t("support.self_test.memory.content")}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default MemoryTestCard;
