import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetSystemComponentsResult from "api/system_components/Responses/GetSystemComponentsResult";
import GetSystemComponents from "api/system_components/Requests/GetSystemComponents";
import UpdateSlingshot from "api/system_components/slingshot/Requests/UpdateSlingshot";
import UpdateSharebrowserServer from "api/system_components/sharebrowser_server/Requests/UpdateSharebrowserServer";
import UpdateSharebrowserWebclient from "api/system_components/sharebrowser_webclient/Requests/UpdateSharebrowserWebclient";
import UpdateWebUi from "api/system_components/web_ui/Requests/UpdateWebUi";
import GetSystemComponentsServicesState from "api/system_components/Requests/GetSystemComponentsServicesState";
import GetSystemComponentsServicesStateResult from "api/system_components/Responses/GetSystemComponentsServicesStateResult";
import {
    SLINGSHOT_SYSTEM_COMPONENT,
    SBS_SYSTEM_COMPONENT,
    SB_WEBCLIENT_SYSTEM_COMPONENT,
    WEB_UI_SYSTEM_COMPONENT,
    SERVICES_STATE_CRASHED,
    SERVICES_STATE_STOPPED,
    COMPONENT_SERVICE,
} from "const/systemComponentConst";

const SystemComponentsStore = types
    .model({
        systemComponentsStore: types.maybeNull(GetSystemComponentsResult),
        currentComponentName: types.maybeNull(types.string),
        systemComponentsServicesStateStore: types.maybeNull(GetSystemComponentsServicesStateResult),
    })
    .views((self) => ({
        get systemComponents() {
            return self.systemComponentsStore && self.systemComponentsStore.data;
        },
        get currentComponent() {
            return (
                self.currentComponentName &&
                self.systemComponents.find((component) => component.componentName === self.currentComponentName)
            );
        },
        get systemComponentsServicesState() {
            return self.systemComponentsServicesStateStore && self.systemComponentsServicesStateStore.data;
        },
        get isComponentsServiceFailed() {
            return (
                this.systemComponentsServicesState &&
                !!this.systemComponentsServicesState
                    .filter((service) => service.componentServiceId !== COMPONENT_SERVICE)
                    .find(
                        (service) =>
                            service.componentServiceState === SERVICES_STATE_CRASHED ||
                            service.componentServiceState === SERVICES_STATE_STOPPED
                    )
            );
        },
    }))
    .actions((self) => ({
        afterCreate() {
            self.systemComponentsStore = GetSystemComponentsResult.create({});
        },
        upgradeSystemComponent: (componentFile) => {
            switch (self.currentComponentName) {
                case SLINGSHOT_SYSTEM_COMPONENT:
                    return self.upgradeSlingshot(componentFile);
                case SBS_SYSTEM_COMPONENT:
                    return self.upgradeSharebrowserServer(componentFile);
                case SB_WEBCLIENT_SYSTEM_COMPONENT:
                    return self.upgradeSharebrowserWebclient(componentFile);
                case WEB_UI_SYSTEM_COMPONENT:
                    return self.upgradeWebUi(componentFile);
                default: {
                    const { processingStore } = getParent(self);
                    processingStore.setError(Error("This system component is not upgradable"));
                    break;
                }
            }
        },
        upgradeSlingshot: flow(function* upgradeSlingshot(componentFile) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = UpdateSlingshot.create().init({ updateName: componentFile });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        upgradeSharebrowserServer: flow(function* (componentFile) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = UpdateSharebrowserServer.create().init({ updateName: componentFile });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        upgradeSharebrowserWebclient: flow(function* (componentFile) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = UpdateSharebrowserWebclient.create().init({ updateName: componentFile });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        upgradeWebUi: flow(function* (componentFile) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = UpdateWebUi.create().init({ updateName: componentFile });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchSystemComponents: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetSystemComponents.create().init();
                const res = yield Socket.send(req);
                self.systemComponentsStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchSystemComponentsServicesState: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetSystemComponentsServicesState.create().init();
                const res = yield Socket.send(req);
                self.systemComponentsServicesStateStore = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateSystemComponent: () => {
            // TODO: replace an updated component definition without fetching everything from the backend
            return self.fetchSystemComponents();
        },
        setCurrentComponentName: (componentName) => {
            self.currentComponentName = componentName;
        },
    }));

export default SystemComponentsStore;
