import { S3_SCHEMA, SCHEMA_OPTIONS, S3_CUSTOM_REGION, S3_CUSTOM_SCHEMA } from "const/slingshotAliasConst";

export function getAliasUrl(baseUrl) {
    let divider = ":/";
    if (
        baseUrl.schema === SCHEMA_OPTIONS.smb ||
        baseUrl.schema === SCHEMA_OPTIONS.ftp ||
        baseUrl.schema === SCHEMA_OPTIONS.afp ||
        baseUrl.schema === SCHEMA_OPTIONS.tricaster
    ) {
        divider = divider + "/";
    }
    if (baseUrl.schema === SCHEMA_OPTIONS.s3 && baseUrl.region) {
        if (baseUrl.region !== S3_CUSTOM_REGION) {
            divider = divider + S3_SCHEMA;
        } else {
            divider = divider + S3_CUSTOM_SCHEMA;
        }
    } else if (baseUrl.schema === SCHEMA_OPTIONS.s3 && !baseUrl.region) {
        divider = divider + "/";
    }

    const newBaseUrl = [];
    baseUrl.host && newBaseUrl.push(baseUrl.host);
    baseUrl.resource && newBaseUrl.push(baseUrl.resource);
    baseUrl.path && newBaseUrl.push(baseUrl.path);

    return baseUrl.schema + divider + newBaseUrl.map(el => el.replace(/^\/|\/$/g, "")).join("/");
}
