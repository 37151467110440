import React, { useEffect } from "react";
import { useStore } from "hooks/useStore";
import { useObserver, useLocalStore } from "mobx-react";
import { ButtonBase, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import ChevronRightIcon from "assets/ChevronRightIcon";
import PlusIcon from "assets/PlusIcon";
import StatusLabel from "./components/StatusLabel";
import TableRowControlButton from "components/TableRowControlButton";
import TableSortLabel from "components/TableSortLabel";
import { useTranslation } from "react-i18next";
import DataCard from "components/DataCard/DataCard";
import { PEERS_CARD } from "const/widgetNameConst";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { CLUSTER_PEERS_HOST, CLUSTER_PEERS_IP, CLUSTER_PEERS_EXTENDED_STATUS, CLUSTER_PEERS_STATUS } from "const/sortColumnConst";
import { PEER_EXTENDED_STATUS_OPTIONS_ARRAY } from "const/clusterConst";
import AddPeerDialog from "../AddPeerDialog";
import { useModal } from "hooks/useModal";
import PeersDrawer from "../PeersDrawer";

const PeersCard = () => {
    const { t } = useTranslation();
    const {
        store: { clusterStore }
    } = useStore();
    const state = useLocalStore(() => ({
        isLoading: false
    }));

    const addPeerModal = useModal();

    useEffect(() => {
        (async () => {
            state.isLoading = true;
            await clusterStore.fetchPeers();
            state.isLoading = false;
        })();
    }, []);

    const changeSorting = column => () => clusterStore.changeSortingPeers(column);

    const getExtendedStatus = extendedStatus => {
        return PEER_EXTENDED_STATUS_OPTIONS_ARRAY.find(entry => entry.value === extendedStatus)?.label || "";
    };

    const onRowClick = peerName => () => {
        clusterStore.setCurrentPeerName(clusterStore.currentPeerName === peerName ? null : peerName);
    };

    return useObserver(() => (
        <DataCard
            emptyPadding
            title={t("cluster.peers.title")}
            headerDivider
            headerControl={[
                <MoreMenu key={`data_card-header_control-control-1`}>
                    <HomeCardActionMenuItem widget={PEERS_CARD} />
                </MoreMenu>,
                <ButtonBase onClick={addPeerModal.open} key={`data_card-header_control-control-2`}>
                    <PlusIcon />
                </ButtonBase>
            ]}
        >
            <PeersDrawer />
            <AddPeerDialog onClose={addPeerModal.close} open={addPeerModal.isOpen} />
            {clusterStore.peers?.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(CLUSTER_PEERS_HOST)}
                                    direction={clusterStore.order}
                                    active={clusterStore.peersOrderBy === CLUSTER_PEERS_HOST}
                                >
                                    {t("cluster.peers.header.name")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(CLUSTER_PEERS_IP)}
                                    direction={clusterStore.order}
                                    active={clusterStore.peersOrderBy === CLUSTER_PEERS_IP}
                                >
                                    {t("cluster.peers.header.ip")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(CLUSTER_PEERS_EXTENDED_STATUS)}
                                    direction={clusterStore.order}
                                    active={clusterStore.peersOrderBy === CLUSTER_PEERS_EXTENDED_STATUS}
                                >
                                    {t("cluster.peers.header.extended_status")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(CLUSTER_PEERS_STATUS)}
                                    direction={clusterStore.order}
                                    active={clusterStore.peersOrderBy === CLUSTER_PEERS_STATUS}
                                >
                                    {t("cluster.peers.header.status")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clusterStore.sortedPeers.map(peer => (
                            <TableRow key={Math.floor(Math.random() * Date.now())} onClick={onRowClick(peer.name)}>
                                <TableCell>{peer.alias}</TableCell>
                                <TableCell>{peer.ip}</TableCell>
                                <TableCell>{getExtendedStatus(peer.extendedStatus)}</TableCell>
                                <TableCell>
                                    <StatusLabel status={peer.status} />
                                </TableCell>
                                <TableCell align={"right"}>
                                    <TableRowControlButton icon={<ChevronRightIcon />} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyCardPlaceholder inProgress={state.isLoading} message={t("cluster.peers.table.placeholder")} />
            )}
        </DataCard>
    ));
};

export default PeersCard;
