import { useEffect } from "react";

export const useAsyncEffect = (fn, dependencies) => {
    useEffect(() => {
        var destroyFn = fn();

        return () => {
            if (typeof destroyFn === "function") destroyFn();
        };
    }, dependencies);
};
