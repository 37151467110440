import { types } from "mobx-state-tree";

import Request from "api/Request";
import SnmpAgentSettings from "api/events/Types/SnmpAgentSettings";

const SetSnmpAgentSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Events",
    method: "set_snmp_agent_settings",
    arguments: types.maybe(SnmpAgentSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetSnmpAgentSettings;
