import { types } from "mobx-state-tree";

import Request from "api/Request";
import PerformAutomationCommandArguments from "api/slingshot/automations/Types/PerformAutomationCommandArguments";

const PerformAutomationCommand = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Automations1",
    method: "perform_automation_command",
    arguments: types.maybe(
        types.snapshotProcessor(PerformAutomationCommandArguments, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json)
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default PerformAutomationCommand;
