const createFields = () => {
    return [
        { name: "share" },
        { name: "path" },
        { name: "deleteBackups" },
        { name: "schedule" },
        { name: "day" },
        { name: "hour" },
        { name: "minute" }
    ];
};

export default createFields;
