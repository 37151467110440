import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Divider, Grid, Paper, Step, StepLabel, Stepper, Link } from "@material-ui/core";
import SnsLogo from "assets/SnsLogo";
import { useStyles } from "./Migration.styles";
import { SNS_WEBSITE } from "const";
import { useStore } from "hooks/useStore";
import ContentAggregation from "./components/ContentAggregation";
import { observer } from "mobx-react";
import SupportButton from "components/SupportButton";

const MigrationPage = () => {
    const classes = useStyles();
    const {
        store: { migrationStore },
    } = useStore();
    const location = useLocation();

    useEffect(() => {
        if (migrationStore.steps.length === migrationStore.activeStep + 1) migrationStore.getMainStepProgress();
    }, [migrationStore.activeStep]);

    const hasSupportQuery = location.search === "?support=true";

    return (
        <Grid container direction="column" className={classes.container} wrap="nowrap">
            <Grid item container justify="center" className={classes.logoContainer} alignItems="center">
                <Link rel="noopener" target="_blank" href={SNS_WEBSITE} variant="button">
                    <SnsLogo />
                </Link>
            </Grid>
            <Grid item container justify="center">
                <Paper className={classes.card}>
                    <Stepper activeStep={migrationStore.activeStep} alternativeLabel nonLinear>
                        {migrationStore.steps.map((label) => (
                            <Step key={label.name}>
                                <StepLabel>{label.name}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Divider />
                    <ContentAggregation />
                </Paper>
            </Grid>
            {hasSupportQuery && (
                <Grid className={classes.supportButtonContainer}>
                    <SupportButton />
                </Grid>
            )}
        </Grid>
    );
};

export default observer(MigrationPage);
