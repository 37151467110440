import React, { useEffect } from "react";
import { observer } from "mobx-react";
import Header from "views/Header";
import SideMenu from "views/SideMenu/SideMenu";
import NotificationMenu from "views/NotificationMenu";
import { Box, ButtonBase, Paper, Grid, Slide, ClickAwayListener, Typography, Button, CircularProgress } from "@material-ui/core";
import { useStyles } from "./MainTemplate.style.js";
import { PropTypes } from "prop-types";
import ChevronRightIcon from "assets/ChevronRightIcon";
import ChevronDownIcon from "assets/ChevronDownIcon";
import Footer from "views/Footer";
import clsx from "clsx";
import { ADMIN, USER } from "const/userRolesConst.js";
import { FAILED } from "const/updatesConst";
import { Link } from "react-router-dom";
import { URL_SUPPORT_OS_DATA_TAB } from "routes";
import { useTranslation } from "react-i18next";
import { useStore } from "hooks/useStore.js";
import { useNotificationMenu } from "hooks/sideBars/useNotificationMenu.js";
import { useSideMenu } from "hooks/sideBars/useSideMenu.js";
import { useDebounce } from "hooks/useDebounce.js";
import NotificationMenuContainer from "./components/NotificationMenuContainer/NotificationMenuContainer";
import Dialog from "components/Dialog";
import { useModal } from "hooks/useModal";

const MainTemplate = ({ headerTitle, hideHeaderButton, children, denseTopPadding, largeWidthContent, className, ...rest }) => {
    const {
        store: { uiStore, authStore, upgradesStore, powerAndUpsStore },
    } = useStore();
    const { isSystemMenuOpen, openSystemMenu, closeSystemMenu, isWidgetsBarOpen, openWidgetsBar, closeWidgetsBar } = uiStore;

    const notificationMenu = useNotificationMenu();
    const sideMenu = useSideMenu();

    const classes = useStyles({
        denseTopPadding,
        isSideMenuPinned: sideMenu.isPinned,
        isNotificationMenuPinned: notificationMenu.isPinned,
        largeWidthContent,
        isWidgetsBarOpen,
        showUpgradeMessage: uiStore.upgradeMessage,
        isAuthorized: authStore.isAuthorized,
    });

    const { t } = useTranslation();

    const rebootDialog = useModal();

    useEffect(() => {
        uiStore.onClose();
        if (authStore.isAuthorized) {
            localStorage.getItem("closeHeader") === "false" && openWidgetsBar();
        }
    }, []);

    const togglePinnedSideMenu = () => {
        if (sideMenu.isPinned) {
            sideMenu.unpin();
            sideMenu.close();
        } else {
            !sideMenu.isOpen && sideMenu.open();
            sideMenu.pin();
        }
    };

    const clickAwaySideMenu = () => {
        !sideMenu.isPinned && sideMenu.isOpen && sideMenu.close();
    };

    const sideMenuOpening = useDebounce(sideMenu.open, 450);

    const closeSideMenuIfNeeded = () => {
        sideMenuOpening.stop();
        isSystemMenuOpen && closeSystemMenu();
        !sideMenu.isPinned && sideMenu.close();
    };

    const openSideMenuIfNeeded = () => {
        if (!sideMenu.isOpen && !sideMenu.isPinned) {
            sideMenuOpening.run();
        }
    };

    const onOpenSideMenuButtonEnter = () => {
        sideMenuOpening.stop();
    };

    const togglePinNotifications = () => {
        notificationMenu.isPinned ? notificationMenu.unpin() : notificationMenu.pin();
    };

    const clickAwayNotificationMenu = () => {
        !notificationMenu.isPinned && notificationMenu.close();
    };

    const toggleSystemMenu = () => {
        isSystemMenuOpen ? closeSystemMenu() : openSystemMenu();
    };

    const toggleWidgetsBar = () => {
        isWidgetsBarOpen ? closeWidgetsBar() : openWidgetsBar();
        localStorage.setItem("closeHeader", isWidgetsBarOpen ? "true" : "false");
    };

    const reboot = async () => {
        const res = await powerAndUpsStore.reboot();
        return res;
    };

    const handleEscapePressed = (e) => {
        if (e.key === "Escape") {
            uiStore.closeDrawer();
        }
    };

    return (
        <Box className={clsx(classes.container, className)} onKeyDown={handleEscapePressed} {...rest}>
            <Box className={classes.header}>
                <Header isOpen={authStore.isAuthorized ? isWidgetsBarOpen : false} title={headerTitle} />

                {!hideHeaderButton && authStore.role !== USER ? (
                    <ButtonBase
                        onClick={toggleWidgetsBar}
                        className={clsx(classes.headerButton, isWidgetsBarOpen && classes.openedHeaderButton)}
                    >
                        <ChevronDownIcon />
                    </ButtonBase>
                ) : null}

                {uiStore.isBlockUI ? (
                    <Grid container className={classes.upgradeMessageContainer}>
                        <Typography className={classes.upgradeMessage}>{uiStore.upgradeMessage}</Typography>

                        {uiStore.showRestart ? (
                            <Button
                                disabled={powerAndUpsStore.isRebootLoading}
                                onClick={rebootDialog.open}
                                className={classes.button}
                            >
                                {t("upgrades.button.restart")}
                                {powerAndUpsStore.isRebootLoading && (
                                    <Box className={classes.icon}>
                                        <CircularProgress color={"secondary"} size={16} />
                                    </Box>
                                )}
                            </Button>
                        ) : null}

                        {upgradesStore.upgradeStepStatus === FAILED && authStore.role === ADMIN ? (
                            <Link className={classes.button} to={URL_SUPPORT_OS_DATA_TAB}>
                                {t("common.button.try_again")}
                            </Link>
                        ) : null}
                    </Grid>
                ) : null}
            </Box>
            <div
                id={"bodyContainer"}
                className={clsx(
                    classes.bodyContainer,
                    authStore.isAuthorized && isWidgetsBarOpen && classes.bodyContainerWithWidgetsBar
                )}
            >
                <ClickAwayListener onClickAway={clickAwaySideMenu}>
                    <div
                        onMouseEnter={openSideMenuIfNeeded}
                        onMouseLeave={closeSideMenuIfNeeded}
                        className={clsx(
                            classes.drawer,
                            sideMenu.isOpen ? classes.openedDrawer : classes.closedDrawer,
                            isSystemMenuOpen && classes.drawerWithSysMenu
                        )}
                    >
                        {!isSystemMenuOpen ? (
                            <ButtonBase
                                onMouseEnter={onOpenSideMenuButtonEnter}
                                onMouseLeave={openSideMenuIfNeeded}
                                className={clsx(classes.toggleButton, sideMenu.isPinned && classes.pinnedToggleButton)}
                                onClick={togglePinnedSideMenu}
                            >
                                <ChevronRightIcon />
                            </ButtonBase>
                        ) : null}
                        <SideMenu
                            closeSideMenu={sideMenu.close}
                            isSideMenuPinned={sideMenu.isPinned}
                            isSystemMenuOpen={isSystemMenuOpen}
                            toggleSystemMenu={toggleSystemMenu}
                        />
                    </div>
                </ClickAwayListener>

                {authStore.role !== USER && (
                    <Slide
                        in={authStore.isAuthorized ? notificationMenu.isOpen : false}
                        direction={"left"}
                        mountOnEnter
                        unmountOnExit
                        timeout={{ enter: notificationMenu.isPinned ? 0 : 225, exit: 225 }}
                    >
                        <NotificationMenuContainer
                            className={clsx(
                                classes.notificationMenu,
                                notificationMenu.isPinned && classes.pinnedNotificationsMenu,
                                notificationMenu.isHide && classes.hidedNotificationsMenu,
                                notificationMenu.isOpen && classes.openNotificationsMenu
                            )}
                            isWidgetsBarOpen={isWidgetsBarOpen}
                        >
                            <ClickAwayListener onClickAway={clickAwayNotificationMenu}>
                                <>
                                    <NotificationMenu
                                        closeNotificationMenu={notificationMenu.close}
                                        isNotificationMenuPinned={notificationMenu.isPinned}
                                        togglePinNotifications={togglePinNotifications}
                                    />
                                </>
                            </ClickAwayListener>
                        </NotificationMenuContainer>
                    </Slide>
                )}

                <Grid container className={classes.bodyContentContainer} wrap={"nowrap"} justify={"space-between"}>
                    <Grid item>
                        {sideMenu.isPinned && sideMenu.isOpen ? <Grid className={classes.pinnedSideSpacing} /> : null}
                        <Paper square className={classes.sideSpacingPaper} />
                    </Grid>
                    <Grid item container className={classes.bodyPage} direction={"column"} justify={"space-between"}>
                        <Grid item className={classes.childrenContainer}>
                            {children}
                        </Grid>
                        <Grid item>
                            <Footer />
                        </Grid>
                    </Grid>
                    <Grid item>{notificationMenu.isPinned ? <Box className={classes.notificationSpacing} /> : null}</Grid>
                </Grid>
            </div>

            <Dialog
                title={t("common.dialog_title.warning")}
                open={rebootDialog.isOpen}
                onClose={rebootDialog.close}
                onSubmit={reboot}
                submitBtnLabel={t("common.button.proceed")}
                withoutIconClose
                withoutDividers
                disableClosingAfterStartLoading
                closeBtnLabel={t("common.button.cancel")}
            >
                {t("power_and_ups.power.dialog_reboot")}
            </Dialog>
        </Box>
    );
};

MainTemplate.propTypes = {
    children: PropTypes.element,
    headerTitle: PropTypes.element,
    hideHeaderButton: PropTypes.bool,
};

export default observer(MainTemplate);
