import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    textInfo: {
        fontWeight: 500,
        wordBreak: "break-word",
    },
    detailsContainer: {
        padding: theme.spacing(4),
        margin: 0,
        width: "100%",
    },
    statusText: {
        textTransform: "capitalize",
    },
    foldersInfo: {
        wordBreak: "break-all",
    },
    iconEnable: {
        "&& svg path": {
            fill: theme.palette.success.main,
        },
    },
    iconDisable: {
        "&& svg path": {
            fill: theme.palette.error.main,
        },
    },
}));
