import React from "react";
import { useTranslation } from "react-i18next";
import createFields from "./createFields";
import { useLocalStore } from "mobx-react";
import createForm from "utils/createForm";
import { Button, Grid } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import { useObserver } from "mobx-react-lite";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useModalCombine } from "hooks/useModalCombine";
import { useStore } from "hooks/useStore";
import ErrorButton from "components/ErrorButton";
import ConfirmationDialog from "components/ConfirmationDialog";

const SET_INDEXER_NAME_MODAL_ID_PREFIX = `set_indexer_name_modal`;

const SetIndexerNameModal = ({ onClose, open, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${SET_INDEXER_NAME_MODAL_ID_PREFIX}` : SET_INDEXER_NAME_MODAL_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { indexingConfigStore, evoSettingsStore },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields() }),
        isSubmitted: false,
    }));

    const { unsavedChangesModal, resetNameModal } = useModalCombine(["unsavedChangesModal", "resetNameModal"]);

    const updateForm = () => {
        state.form.$("name").set("default", indexingConfigStore.indexerName);
        state.form.reset();
        state.isSubmitted = false;
        unsavedChangesModal.close();
    };
    const submit = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        const isSubmitted = await indexingConfigStore.setIndexerName(state.form.$("name").value);
        if (isSubmitted) {
            state.isSubmitted = true;
        }
        return isSubmitted;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const resetIndexerName = async () => {
        resetNameModal.close();
        state.form.$("name").value = evoSettingsStore.evoHostname;
        if (!state.form.isValid) return;
        await indexingConfigStore.setIndexerName(state.form.$("name").value);
        onClose();
    };

    return useObserver(() => (
        <Dialog
            title={t("slingshot.index_proxy.indexer_name.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            open={open}
            onSubmit={submit}
            onEnter={updateForm}
            onClose={closeIfNeeded}
            buttons={
                <>
                    <ErrorButton submit={resetNameModal.open} label={t("common.button.reset")}></ErrorButton>
                    <Button onClick={onClose} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                        {t("common.button.cancel")}
                    </Button>
                </>
            }
        >
            <Grid container direction={"column"}>
                <RowTextInput
                    label={t("slingshot.index_proxy.indexer_name.label.name")}
                    control={
                        <TextInput field={state.form.$("name")} inputProps={{ spellCheck: "false" }} id={`${idPrefix}_name`} />
                    }
                />
            </Grid>
            <ConfirmationDialog
                title={t("common.warning_dialog.title")}
                open={resetNameModal.isOpen}
                onClose={resetNameModal.close}
                onConfirm={resetIndexerName}
                confirmLabel={t("common.button.proceed")}
                cancelLabel={t("common.button.cancel")}
                fullWidth={true}
                id={`${idPrefix}_confirmation_dialog`}
            >
                {t("slingshot.index_proxy.indexer_name.confirm_reset_name", {
                    indexerName: evoSettingsStore.evoHostname,
                })}
            </ConfirmationDialog>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default SetIndexerNameModal;
