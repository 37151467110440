import React from "react";
import { useStore } from "hooks/useStore";
import createFields from "./createFields";
import createForm from "utils/createForm";
import Dialog from "components/Dialog";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Button,
    Typography,
    ButtonBase,
    Box,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Divider,
} from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import Select from "components/MobxForm/Select";
import { default as MuiSelect } from "components/Select";
import Switch from "components/MobxForm/Switch";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import {
    VOLUME_TYPES_ARRAY,
    VOLUME_REPLICA_ARRAY,
    VOLUME_DISPERSE_ARRAY,
    VOLUME_REDUNDANCY_ARRAY,
    VOLUME_TYPE_OPTIONS,
} from "const/clusterConst";
import TrashIcon from "assets/TrashIcon";
import { useStyles } from "./CreateVolumeDialog.styles";
import { useModal } from "hooks/useModal";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import PlusIcon from "assets/PlusIcon";
import clsx from "clsx";

const CreateVolumeDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { clusterStore },
    } = useStore();
    const unsavedChangesModal = useModal();
    const fields = createFields();
    const state = useLocalStore(() => ({
        form: createForm({
            fields,
        }),
        setupManually: false,
        isAddBrickInProgress: false,
        freeBricks: [],
        volatileBricks: [],
        validationError: false,
        inProgress: false,
        isSubmitted: false,
        get freePeers() {
            const filteredBricks = [...clusterStore.freeBricks];
            return (
                filteredBricks
                    .filter((el, index, arr) => arr.findIndex((e) => e.peer === el.peer) === index)
                    ?.map((brick) => {
                        return { value: brick.peer, label: brick.peerAlias };
                    }) || []
            );
        },
        get redundancy() {
            const maxRedundancyLength = (this.form.$("disperse").value - 1) / 2;
            return VOLUME_REDUNDANCY_ARRAY.slice(0, maxRedundancyLength);
        },
    }));

    const onCheckValidation = () => {
        if (
            (state.form.$("type").value === VOLUME_TYPE_OPTIONS.simple && !state.volatileBricks.length) ||
            (state.form.$("type").value === VOLUME_TYPE_OPTIONS.mirror &&
                state.volatileBricks.length % state.form.$("replica").value !== 0) ||
            (state.form.$("type").value === VOLUME_TYPE_OPTIONS.grid &&
                state.volatileBricks.length % state.form.$("disperse").value !== 0)
        ) {
            state.validationError = true;
            return false;
        }
        return true;
    };

    const onSubmit = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        if (!onCheckValidation()) return;
        let bricks = [...state.volatileBricks];
        let mappedBricks = bricks.map((brick) => {
            return { peer: brick.peer, path: brick.path, name: brick.name };
        });
        let requestData = {
            volume: {
                name: state.form.$("name").value,
                type: state.form.$("type").value,
                status: "",
                transport: "tcp",
                replica: state.form.$("replica").value || 1,
                arbiter: state.form.$("arbiter").value ? 1 : 0,
                disperse: state.form.$("disperse").value || 3,
                redundancy: state.form.$("redundancy").value || 1,
            },
        };

        requestData = { ...requestData, bricks: mappedBricks };
        state.inProgress = true;
        const res = await clusterStore.addVolume({ ...requestData });
        state.inProgress = false;
        res && (state.isSubmitted = true);

        return res;
    };

    const onDialogOpen = () => {
        state.inProgress = false;
        state.isSubmitted = false;
        state.freeBricks = [];
        state.volatileBricks = [];
        state.validationError = false;
        state.freeBricks = [...clusterStore.freeBricks];
        state.form.$("type").set("default", VOLUME_TYPE_OPTIONS.simple);
        state.form.reset();
        state.form.$("name").resetValidation();
    };

    const onSetupManuallyChange = (e) => {
        state.form.$("setupManually").set("value", e.target.checked);
        !state.form.$("disperse").value && state.form.$("disperse").set("value", 3);
        !state.form.$("redundancy").value && state.form.$("redundancy").set("value", 1);
        state.validationError = false;
    };

    const onTypeChange = (e) => {
        state.form.$("type").set("value", e.target.value);
        !state.form.$("replica").value && state.form.$("replica").set("value", 2);
        state.validationError = false;
    };

    const addBrick = () => {
        state.validationError = false;
        if (state.freeBricks.length === 0) return;
        state.volatileBricks.push(state.freeBricks[0]);
        state.freeBricks.splice(0, 1);
    };

    const removeBrick = (brickId) => {
        const findIndex = state.volatileBricks.findIndex((brick) => brick.id === brickId);
        state.freeBricks.unshift(state.volatileBricks[findIndex]);
        state.volatileBricks.splice(findIndex, 1);
    };

    const getPath = () => (brickPeer) => {
        const filteredBricks = [...clusterStore.freeBricks];
        return (
            filteredBricks
                .filter((brick) => brick.peer === brickPeer)
                ?.filter((el, index, arr) => arr.findIndex((e) => e.path === el.path) === index)
                ?.map((brick) => brick.path) || []
        );
    };

    const getBrick = () => (brickPeer, brickPath) => {
        const filteredBricks = [...clusterStore.freeBricks];
        return (
            filteredBricks
                .filter((brick) => brick.peer === brickPeer && brick.path === brickPath)
                ?.filter((el, index, arr) => arr.findIndex((e) => e.name === el.name) === index)
                ?.map((brick) => brick.name) || []
        );
    };

    const changeVolatilePeer = (e, index) => {
        const newPeer = e.target.value;
        if (newPeer !== state.volatileBricks[index].peer) {
            state.freeBricks.unshift(state.volatileBricks[index]);
            let newBrickIndex = state.freeBricks.findIndex((brick) => brick.peer === e.target.value);
            if (newBrickIndex >= 0) {
                state.volatileBricks[index] = state.freeBricks[newBrickIndex];
                state.freeBricks.splice(newBrickIndex, 1);
            } else {
                newBrickIndex = state.volatileBricks.findIndex((brick) => brick.peer === newPeer);
                state.volatileBricks[index] = state.volatileBricks[newBrickIndex];
                state.volatileBricks[newBrickIndex] = state.freeBricks[0];
                state.freeBricks.splice(0, 1);
            }
        }
    };

    const changeVolatilePath = (e, index) => {
        const newPath = e.target.value;
        if (newPath !== state.volatileBricks[index].path) {
            const currentPeer = state.volatileBricks[index].peer;
            state.freeBricks.unshift(state.volatileBricks[index]);
            let newBrickIndex = state.freeBricks.findIndex((brick) => brick.peer === currentPeer && brick.path === newPath);
            if (newBrickIndex >= 0) {
                state.volatileBricks[index] = state.freeBricks[newBrickIndex];
                state.freeBricks.splice(newBrickIndex, 1);
            } else {
                newBrickIndex = state.volatileBricks.findIndex((brick) => brick.peer === currentPeer && brick.path === newPath);
                state.volatileBricks[index] = state.volatileBricks[newBrickIndex];
                state.volatileBricks[newBrickIndex] = state.freeBricks[0];
                state.freeBricks.splice(0, 1);
            }
        }
    };

    const changeVolatileBrick = (e, index) => {
        const newBrick = e.target.value;
        if (newBrick !== state.volatileBricks[index].name) {
            const currentPeer = state.volatileBricks[index].peer;
            const currentPath = state.volatileBricks[index].path;
            state.freeBricks.unshift(state.volatileBricks[index]);
            let newBrickIndex = state.freeBricks.findIndex(
                (brick) => brick.peer === currentPeer && brick.path === currentPath && brick.name === newBrick
            );
            if (newBrickIndex >= 0) {
                state.volatileBricks[index] = state.freeBricks[newBrickIndex];
                state.freeBricks.splice(newBrickIndex, 1);
            } else {
                newBrickIndex = state.volatileBricks.findIndex(
                    (brick) => brick.peer === currentPeer && brick.path === currentPath && brick.name === newBrick
                );
                state.volatileBricks[index] = state.volatileBricks[newBrickIndex];
                state.volatileBricks[newBrickIndex] = state.freeBricks[0];
                state.freeBricks.splice(0, 1);
            }
        }
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted || !state.volatileBricks.length) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            maxWidth="lg"
            onEnter={onDialogOpen}
            withoutPaddings
            title={t("cluster.volume.create_volume.title")}
            open={open}
            onSubmit={onSubmit}
            inProgress={state.inProgress}
            onClose={closeIfNeeded}
            submitBtnLabel={t("common.button.create")}
            buttons={[
                <Button key={"cancel_button"} onClick={closeIfNeeded} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>,
            ]}
        >
            <>
                <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
                <Box px={6} py={4} width={"100%"}>
                    <Grid container spacing={4}>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography>{t("cluster.volume.name")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextInput field={state.form.$("name")} inputProps={{ spellCheck: "false" }} />
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography>{t("cluster.volume.type")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select onChange={onTypeChange} field={state.form.$("type")} options={VOLUME_TYPES_ARRAY} />
                            </Grid>
                        </Grid>
                        {state.form.$("type").value === VOLUME_TYPE_OPTIONS.mirror && (
                            <>
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("cluster.volume.replica")}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select field={state.form.$("replica")} options={VOLUME_REPLICA_ARRAY} />
                                    </Grid>
                                </Grid>
                                {state.form.$("replica").value === +VOLUME_REPLICA_ARRAY[1] && (
                                    <Grid item container alignItems={"center"}>
                                        <RowControlLabelSwitch
                                            control={<Switch field={state.form.$("arbiter")} />}
                                            label={t("cluster.volume.arbiter")}
                                            lastColumnWidth={"33.3%"}
                                        />
                                    </Grid>
                                )}
                            </>
                        )}
                        {state.form.$("type").value === VOLUME_TYPE_OPTIONS.grid && (
                            <Grid item container alignItems={"center"}>
                                <RowControlLabelSwitch
                                    control={<Switch onChange={onSetupManuallyChange} field={state.form.$("setupManually")} />}
                                    label={t("cluster.volume.setup_manually")}
                                    lastColumnWidth={"33.3%"}
                                />
                            </Grid>
                        )}
                        {!!state.form.$("setupManually").value && (
                            <>
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("cluster.volume.disperse")}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select field={state.form.$("disperse")} options={VOLUME_DISPERSE_ARRAY} />
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("cluster.volume.redundant_bricks")}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select field={state.form.$("redundancy")} options={state.redundancy} />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid item container>
                            <Box width={"100%"} pt={4}>
                                <Typography variant={"subtitle1"}>{t("cluster.volume.button.bricks")}</Typography>
                                {state.form.$("type").value === VOLUME_TYPE_OPTIONS.simple ||
                                (state.form.$("type").value === VOLUME_TYPE_OPTIONS.grid &&
                                    !state.form.$("setupManually").value) ? (
                                    <Typography>
                                        {t("cluster.volume.helper_text.simple", {
                                            bricksNumber: state.form.$("type").value === VOLUME_TYPE_OPTIONS.simple ? "1" : "3",
                                        })}
                                    </Typography>
                                ) : (
                                    <Typography>
                                        {t("cluster.volume.helper_text.mirror", {
                                            bricksNumber:
                                                state.form.$("type").value === VOLUME_TYPE_OPTIONS.mirror
                                                    ? state.form.$("replica").value
                                                    : state.form.$("disperse").value,
                                        })}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Grid item container>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant={"subtitle1"}>{t("cluster.volumes.brick.header.host_name")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle1"}>{t("cluster.volumes.brick.header.path")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle1"}>{t("cluster.local_bricks.header.brick_name")}</Typography>
                                </TableCell>
                                <TableCell align={"center"}>
                                    {(state.form.$("type").value === VOLUME_TYPE_OPTIONS.mirror ||
                                        (state.form.$("type").value === VOLUME_TYPE_OPTIONS.grid &&
                                            state.form.$("disperse").value)) && (
                                        <Typography variant={"subtitle1"}>
                                            {t("cluster.volumes.brick.header.subvolume_number")}
                                        </Typography>
                                    )}
                                </TableCell>
                                <TableCell width={"10%"} align="right">
                                    <ButtonBase disabled={!state.freeBricks.length} onClick={addBrick}>
                                        <PlusIcon className={clsx(!state.freeBricks.length && classes.disabled)} />
                                    </ButtonBase>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!state.volatileBricks.length &&
                                state.volatileBricks.map((brick, index) => (
                                    <TableRow key={`volatileBrick-${brick.peer}-${brick.path}-${brick.name}}`}>
                                        <TableCell>
                                            <Grid container>
                                                <Grid item>
                                                    <MuiSelect
                                                        options={state.freePeers}
                                                        value={brick.peer}
                                                        onChange={(e) => changeVolatilePeer(e, index)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid container>
                                                <Grid item>
                                                    <MuiSelect
                                                        options={getPath()(brick.peer)}
                                                        value={brick.path}
                                                        onChange={(e) => changeVolatilePath(e, index)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid container>
                                                <Grid item>
                                                    <MuiSelect
                                                        options={getBrick()(brick.peer, brick.path)}
                                                        value={brick.name}
                                                        onChange={(e) => changeVolatileBrick(e, index)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {state.form.$("type").value === VOLUME_TYPE_OPTIONS.mirror && (
                                                <Typography>{Math.floor(index / state.form.$("replica").value)}</Typography>
                                            )}
                                            {state.form.$("type").value === VOLUME_TYPE_OPTIONS.grid &&
                                                state.form.$("disperse").value && (
                                                    <Typography>{Math.floor(index / state.form.$("disperse").value)}</Typography>
                                                )}
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <ButtonBase onClick={() => removeBrick(brick.id)}>
                                                <TrashIcon className={classes.errorColor} />
                                            </ButtonBase>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Grid>
                <Box px={6} py={4} width={"100%"}>
                    {state.validationError && (
                        <>
                            {state.form.$("type").value === VOLUME_TYPE_OPTIONS.simple ||
                            (state.form.$("type").value === VOLUME_TYPE_OPTIONS.grid && !state.form.$("setupManually").value) ? (
                                <Typography className={classes.errorColor}>
                                    {t("cluster.volume.helper_text.simple", {
                                        bricksNumber: state.form.$("type").value === VOLUME_TYPE_OPTIONS.simple ? "1" : "3",
                                    })}
                                </Typography>
                            ) : (
                                <Typography className={classes.errorColor}>
                                    {t("cluster.volume.helper_text.mirror", {
                                        bricksNumber:
                                            state.form.$("type").value === VOLUME_TYPE_OPTIONS.mirror
                                                ? state.form.$("replica").value
                                                : state.form.$("disperse").value,
                                    })}
                                </Typography>
                            )}
                        </>
                    )}
                </Box>
            </>
        </Dialog>
    ));
};

export default CreateVolumeDialog;
