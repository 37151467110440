import React from "react";
import { Dialog, Button, ButtonBase, Divider, Grid } from "@material-ui/core";
import CardHeader from "components/CardHeader";
import Radio from "components/Radio";
import RadioGroup from "components/RadioGroup";
import CloseIcon from "assets/CloseIcon";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./CreateCertificateModal.styles";
import { SELF_SIGNED_CERTIFICATE, CERTIFICATE_SIGNED_REQUEST, RENEW_CERTIFICATE } from "const/createCertificateVariants";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const CREATE_CERTIFICATE_MODAL_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_create_certificate_modal`;

const CreateCertificateModal = ({ open, onClose, onApply }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const state = useLocalStore(() => ({
        radioValue: SELF_SIGNED_CERTIFICATE
    }));

    const handleRadioChange = e => {
        state.radioValue = e.target.value;
    };

    const onNextClick = () => {
        onApply(state.radioValue);
    };
    const onEnter = () => {
        state.radioValue = SELF_SIGNED_CERTIFICATE;
    };
    return useObserver(() => (
        <Dialog onEnter={onEnter} maxWidth={"md"} fullWidth open={open} onClose={onClose}>
            <CardHeader
                invisibleIcon
                title={t("admin_settings.server_certificate.create_certificate.title")}
                control={
                    <CardHeaderControlButton onClick={onClose}>
                        <ButtonBase id={`${CREATE_CERTIFICATE_MODAL_ID_PREFIX}_close`}>
                            <CloseIcon />
                        </ButtonBase>
                    </CardHeaderControlButton>
                }
            />
            <Divider />
            <RadioGroup value={state.radioValue} onChange={handleRadioChange} className={classes.radioGroup}>
                <Radio
                    className={classes.radio}
                    value={SELF_SIGNED_CERTIFICATE}
                    label={t("admin_settings.server_certificate.create_certificate.self_signed_certificate.title")}
                    helperText={t("admin_settings.server_certificate.create_certificate.self_signed_certificate.description")}
                    id={`${CREATE_CERTIFICATE_MODAL_ID_PREFIX}_self_signed_certificate`}
                />
                <Radio
                    className={classes.radio}
                    value={CERTIFICATE_SIGNED_REQUEST}
                    label={t("admin_settings.server_certificate.create_certificate.certificate_signing_request.title")}
                    helperText={t("admin_settings.server_certificate.create_certificate.certificate_signing_request.description")}
                    id={`${CREATE_CERTIFICATE_MODAL_ID_PREFIX}_certificate_signing_request`}
                />
                <Radio
                    className={classes.radio}
                    value={RENEW_CERTIFICATE}
                    label={t("admin_settings.server_certificate.create_certificate.renew_certificate.title")}
                    helperText={t("admin_settings.server_certificate.create_certificate.renew_certificate.description")}
                    id={`${CREATE_CERTIFICATE_MODAL_ID_PREFIX}_renew_certificate`}
                />
            </RadioGroup>
            <Divider />
            <Grid container alignItems={"center"} justify={"flex-end"} spacing={4} className={classes.controlsContainer}>
                <Grid item>
                    <Button
                        onClick={onClose}
                        variant={"contained"}
                        color={"secondary"}
                        id={`${CREATE_CERTIFICATE_MODAL_ID_PREFIX}_cancel`}
                    >
                        {t("common.button.cancel")}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={onNextClick}
                        variant={"contained"}
                        color={"primary"}
                        id={`${CREATE_CERTIFICATE_MODAL_ID_PREFIX}_next`}
                    >
                        {t("common.button.next")}
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default CreateCertificateModal;
