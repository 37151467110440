import Request from "api/Request";

const GetUiSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/UiSettings",
    method: "get_ui_settings"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetUiSettings;
