import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    contentContainer: {
        padding: theme.spacing(0, 6, 6, 6)
    },
    link: {
        textDecoration: "none",
        color: theme.palette.primary.main
    },
}));
