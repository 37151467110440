import React from "react";

function UpgradeSupportIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g stroke="none" strokeWidth="1" fill="none" filRule="evenodd">
                <g transform="translate(-1260.000000, -256.000000)" fill="#1E90FF" fillRule="nonzero">
                    <g transform="translate(1260.000000, 256.000000)">
                        <path
                            d="M11.9999996,2 L11.9999996,2 C6.47714956,2 1.99999956,6.47715 1.99999956,12 C1.99999956,17.52285 6.47714956,22 11.9999996,22 C17.5228496,22 21.9999996,17.52285 21.9999996,12 L21.9999996,12 C21.9999996,6.47715044 17.5228496,2 11.9999996,2 L11.9999996,2 Z M11.9999997,20 L11.9999997,20 C7.58171965,20 3.99999965,16.41828 3.99999965,12 C3.99999965,7.58172 7.58171965,4 11.9999997,4 C16.4182797,4 19.9999997,7.58172 19.9999997,12 L19.9999997,12 C19.9999997,16.41828 16.4182797,20 11.9999997,20 C11.9999997,20 11.9999997,20 11.9999997,20 L11.9999997,20 Z"
                            id="Shape"
                        ></path>
                        <path
                            d="M12.71,7.28585725 L12.71,7.28585725 C12.321156,6.90471425 11.69884,6.90471425 11.31,7.28585725 L8.31,10.1858573 L8.31000003,10.1858572 C7.88401603,10.5373622 7.82363803,11.1676422 8.17514303,11.5936272 C8.52664803,12.0196112 9.15692803,12.0799892 9.58291303,11.7284842 C9.62110033,11.6969736 9.65689483,11.6626705 9.69000103,11.6258582 L11.000001,10.3558582 L11.000001,15.9958582 L11.000001,15.9958584 C11.0000011,16.5481434 11.447716,16.9958584 12.000001,16.9958584 C12.552286,16.9958584 13.000001,16.5481434 13.000001,15.9958584 L13.000001,10.4058584 L14.290001,11.7058584 L14.290001,11.7058583 C14.678921,12.0979803 15.312081,12.1005763 15.704201,11.7116567 C15.7061417,11.7097319 15.7080745,11.7077991 15.7099993,11.7058584 L15.7099994,11.7058584 C16.1021214,11.3169384 16.1047174,10.6837784 15.7157976,10.2916584 C15.7138728,10.2897177 15.71194,10.2877849 15.7099993,10.2858601 L12.71,7.28585725 Z"
                            id="Path"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default UpgradeSupportIcon;
