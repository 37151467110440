import { types } from "mobx-state-tree";

import Request from "api/Request";
import Schedule from "api/support_info/Types/Schedule";

const SetSchedule = Request.props({
    path: "/com/studionetworksolutions/evo/SupportInfo",
    method: "set_schedule",
    arguments: types.maybe(Schedule)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetSchedule;
