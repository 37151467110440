import Request from "api/Request";

const GetDnsServers = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "get_dns_servers"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetDnsServers;
