import shouldBePasswordsEqual from "utils/shouldBePasswordsEqual";

const createFields = () => {
    return [
        {
            name: "password",
            default: "",
            type: "password"
        },
        {
            name: "confirmPassword",
            default: "",
            type: "password",
            validators: [shouldBePasswordsEqual("password")]
        }
    ];
};

export default createFields;
