import { types } from "mobx-state-tree";
import PresetType from "./PresetType";

const PresetJsonType = types.model({
    json: types.maybe(
        types.model({
            presets: types.array(PresetType)
        })
    )
});

export default PresetJsonType;
