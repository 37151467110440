import { NOT_ONLY_NUMBERS, VALID_HOSTNAME_REGEX, VALID_HOSTNAME_REGEX_CHINA } from "const";
import Validator from "validatorjs";
import i18n from "i18n";

Validator.setMessages(Validator.getDefaultLang(), {
    ...Validator.getMessages(Validator.getDefaultLang()),
    host: i18n.t("rule.host.format"),
    hostLength: i18n.t("rule.host.length")
});
const hostNameRule = {
    host: {
        function: value => {
            return (VALID_HOSTNAME_REGEX.test(value) || VALID_HOSTNAME_REGEX_CHINA.test(value)) && NOT_ONLY_NUMBERS.test(value);
        }
    },
    hostLength: {
        function: value => value.length <= 60
    }
};

export default hostNameRule;
