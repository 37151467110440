import { types } from "mobx-state-tree";

import DhcpClientSettings from "api/net/Types/DhcpClientSettings";
import DhcpServerSettings from "api/net/Types/DhcpServerSettings";

const NetInterfaceSettings = types.model({
    port: types.string,
    ip: types.string,
    netmask: types.string,
    gateway: types.string,
    mtu: types.number,
    metric: types.number,
    dhcpClient: types.maybe(DhcpClientSettings),
    dhcpServer: types.maybe(DhcpServerSettings),
    flowcontrolEnforced: types.boolean,
    force: types.boolean
});

export default NetInterfaceSettings;
