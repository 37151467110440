import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => ({
    contentContainer: {
        paddingTop: theme.spacing(2),
    },
    certContainer: {
        padding: theme.spacing(3),
        borderRadius: theme.spacing(1),
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.1),
        marginBottom: theme.spacing(4),

        "&:last-child": {
            marginBottom: 0,
        },
    },
    dragAndDropContainer: {
        marginBottom: theme.spacing(4),
    },
    sshIcon: {
        marginRight: theme.spacing(2),
    },
    certNameText: {
        fontWeight: 500,
    },
    dragAndDropTitle: {
        fontWeight: 500,
        marginBottom: theme.spacing(2),
        fontSize: 16,
    },
}));
