import { types } from "mobx-state-tree";

import LogSettings from "api/troubleshooting/Types/LogSettings";

const LogSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(LogSettings)
});

export default LogSettingsResult;
