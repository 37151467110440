import { types } from "mobx-state-tree";

import { CommonStoreModel } from "utils/multipleHandler";
import config from "config/config";
import ShareStore from "store/ShareStore";
import ShareDrawerModel from "./models/ShareDrawerModel";

const RootShareStore = types.compose(
    "RootShareStore",
    CommonStoreModel,
    ShareDrawerModel,
    types.model({ evos: types.map(ShareStore) }).actions(() => ({
        createStore: (ip = config.path) => ShareStore.create({ ip })
    }))
);

export default RootShareStore;
