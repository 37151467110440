import { makeStyles } from "@material-ui/core/styles";

const MIN_HEIGHT = 184;

export const useStyles = makeStyles(() => ({
    container: {
        width: "100%"
    },
    contentContainer: {
        minHeight: MIN_HEIGHT
    },
    noKeysText: {
        opacity: 0.4
    }
}));
