import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    statusCell: {
        "&&": {
            opacity: 1,
            paddingLeft: 32,
            width: 64
        }
    },
    noOpacityCell: {
        opacity: 1
    },
    container: {
        width: "100%"
    }
}));
