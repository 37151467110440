import { types } from "mobx-state-tree";

import VolumeId from "api/volume/Types/VolumeId";
import Mapping from "api/volume/Types/Mapping";
import Status from "api/volume/Types/Status";
import DriveId from "api/bcache/Types/DriveId";

const Volume = types
    .model({
        volumeId: types.maybe(VolumeId),
        raid: types.number,
        size: types.number,
        chunkSize: types.number,
        iSCSIBlockSize: types.number,
        ioProfile: types.string,
        mapping: types.maybe(Mapping),
        status: types.maybe(Status),
        cacheSupport: types.boolean,
        cache: types.maybe(DriveId)
    })
    .views(self => ({
        get volumeName() {
            return `${self.volumeId.pool}/${self.volumeId.volume}`;
        },
        get statusHealth() {
            return self.status.health;
        },
        get statusString() {
            return self.status.status;
        }
    }));

export default Volume;
