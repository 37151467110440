const createFields = bondingParameters => {
    return [
        {
            name: "mode",
            default: bondingParameters?.mode || ""
        },
        {
            name: "xmitHashPolicy",
            default: bondingParameters?.xmitHashPolicy || ""
        },
        {
            name: "lacpRate",
            default: bondingParameters?.xmitHashPolicy || ""
        }
    ];
};

export default createFields;
