import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { Button, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Dialog from "components/Dialog";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import useStoreByIp from "hooks/useStoreByIp";
import { useStyles } from "./SmartReportModal.styles";

const SmartReportModal = ({ open, onClose, ip }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const state = useLocalStore(() => ({
        inProgress: false,
    }));
    const { driveStore } = useStoreByIp({ ip });

    return useObserver(() => (
        <Dialog
            title={
                driveStore.currentDriveName &&
                t("dashboard.physical_disks.smart_modal.title", {
                    quad: driveStore.currentDriveName.quad,
                    drive: driveStore.currentDriveName.drive,
                })
            }
            open={open}
            onClose={onClose}
            submitBtn={
                <Button variant={"contained"} color={"secondary"} onClick={onClose}>
                    {t("common.button.close")}
                </Button>
            }
            maxWidth={"lg"}
        >
            {state.inProgress ? (
                <EmptyCardPlaceholder inProgress={state.inProgress} />
            ) : (
                <Box whiteSpace={"pre-wrap"}>
                    <Typography className={classes.reportText}>{driveStore.driveSmartReport}</Typography>
                </Box>
            )}
        </Dialog>
    ));
};

export default SmartReportModal;
