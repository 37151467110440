import React from "react";
import Dialog from "components/Dialog";
import { useObserver } from "mobx-react";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ConfirmationDialog = ({ open, onClose, onConfirm, serialNumber, registrationId }) => {
    const { t } = useTranslation();
    const confirm = () => {
        onConfirm();
        onClose();
    };

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={t("support.push_diagnostics.edit_push_diagnostics.confirmation_dialog.title")}
            submitBtn={
                <Button variant={"contained"} color={"primary"} onClick={confirm}>
                    {t("common.button.proceed")}
                </Button>
            }
            buttons={
                <Button variant={"contained"} color={"secondary"} onClick={onClose}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Typography gutterBottom>{t("support.push_diagnostics.edit_push_diagnostics.confirmation_dialog.info")}</Typography>
            <Typography>
                {t("support.push_diagnostics.edit_push_diagnostics.confirmation_dialog.serial_number", { serialNumber })}
            </Typography>
            <Typography gutterBottom>
                {t("support.push_diagnostics.edit_push_diagnostics.confirmation_dialog.registration_id", { registrationId })}
            </Typography>
            <Typography>{t("support.push_diagnostics.edit_push_diagnostics.confirmation_dialog.confirm_question")}</Typography>
        </Dialog>
    ));
};

export default ConfirmationDialog;
