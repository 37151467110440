import { types } from "mobx-state-tree";

const NetworkTestArguments = types.model({
    senderIface: types.string,
    receiverIface: types.string,
    durationInSeconds: types.number,
    threadsCount: types.number
});

export default NetworkTestArguments;
