import { types } from "mobx-state-tree";

import Request from "api/Request";
import FirewallRules from "api/firewall/Types/FirewallRules";

const SetFirewallRules = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Firewall",
    method: "set_firewall_rules",
    arguments: types.maybe(FirewallRules)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetFirewallRules;
