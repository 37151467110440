import React, { useContext } from "react";
import { Context } from "store";
import { useObserver } from "mobx-react";
import Dialog from "components/Dialog";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ENABLE_JOB_DIALOG = `enable_job_dialog`;

const EnableJobDialog = ({ id, open, onClose, name, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${ENABLE_JOB_DIALOG}` : ENABLE_JOB_DIALOG;
    const { t } = useTranslation();

    const {
        store: { syncJobsStore },
    } = useContext(Context);

    const onSubmit = async () => {
        const res = await syncJobsStore.performJobCommand({ jobId: id, cmd: "enable" });
        return res;
    };

    return useObserver(() => (
        <Dialog
            title={t("slingshot_page.tabs.replications.enable_job_dialog.title")}
            open={open}
            onClose={onClose}
            withoutIconClose
            withoutDividers
            onSubmit={onSubmit}
            submitBtnLabel={t("slingshot_page.tabs.replications.enable_job_dialog.button.yes")}
            submitBtnId={`${idPrefix}_button_yes`}
            buttons={
                <Button variant="contained" color="secondary" onClick={onClose} id={`${idPrefix}_button_no`}>
                    {t("slingshot_page.tabs.replications.enable_job_dialog.button.no")}
                </Button>
            }
        >
            <Typography>{t("slingshot_page.tabs.replications.enable_job_dialog.message", { name })}</Typography>
        </Dialog>
    ));
};

export default EnableJobDialog;
