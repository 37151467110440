import React from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./BackupsTableDrawer.styles";
import TrashIcon from "assets/TrashIcon";
import EthernetIcon from "assets/EthernetIcon";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import ResetIcon from "assets/ResetIcon";
import { useStore } from "hooks/useStore";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import { getCurrentBackupName } from "./BackupsTableDrawer.utils";
import { useConfirmationDialogBackupTableDrawer } from "./BackupsTableDrawer.hooks";
import { DESTROY, RECOVERY } from "const/backupConst";

const drawerName = "backupsTableDrawer";
const BackupsTableDrawer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { backupSettingsStore }
    } = useStore();

    const state = useLocalStore(() => ({
        isDeactivateInProgress: false,
        isActivateInProgress: false,
        isEnableAutostartInProgress: false,
        isDisableAutostartInProgress: false,
        closeDrawer() {
            backupSettingsStore.setCurrentBackupPath(null);
        }
    }));

    useDrawerOpenHandler({ drawerName, closeDrawer: state.closeDrawer, targetDep: backupSettingsStore.currentBackupPath });

    const renderDetails = () => {
        const backup = backupSettingsStore.currentBackup;
        return backup ? (
            <Grid container direction={"column"} className={classes.detailsContainer} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("support.os_data.backups.name")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{getCurrentBackupName(backupSettingsStore)}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("support.os_data.backups.date")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{backup.timeString}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("support.os_data.backups.version")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{backup.evoVersion}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography component={"span"} className={classes.labelText}>
                            {t("support.os_data.backups.id")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{backup.id}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        ) : null;
    };

    const confirmationDialog = useConfirmationDialogBackupTableDrawer();

    const renderActions = () => {
        const currentConnection = backupSettingsStore.currentBackup;
        return currentConnection ? (
            <Grid direction={"column"} container>
                <BulkBarFlatButton
                    onClick={confirmationDialog.open[RECOVERY]}
                    icon={<ResetIcon />}
                    inProgress={confirmationDialog.isRecoveryProgress}
                >
                    {t("common.button.recover")}
                </BulkBarFlatButton>
                <BulkBarFlatButton
                    errorColor
                    onClick={confirmationDialog.open[DESTROY]}
                    icon={<TrashIcon />}
                    inProgress={confirmationDialog.isDestroyProgress}
                >
                    {t("common.button.destroy")}
                </BulkBarFlatButton>
            </Grid>
        ) : null;
    };

    return useObserver(() => (
        <>
            <DetailsActionsDrawer
                title={getCurrentBackupName(backupSettingsStore)}
                renderActions={renderActions}
                renderDetails={renderDetails}
                closeDrawer={state.closeDrawer}
                drawerName={drawerName}
                icon={<EthernetIcon />}
            />
            {confirmationDialog.context && (
                <ConfirmationDialog
                    open={confirmationDialog.isOpen}
                    title={confirmationDialog.context.title}
                    onClose={confirmationDialog.close}
                    confirmLabel={confirmationDialog.context.confirmLabel}
                    onConfirm={confirmationDialog.context.onConfirm}
                    fullWidth={true}
                    isSubmitted={false}
                    maxWidth={"sm"}
                >
                    {confirmationDialog.context.text}
                </ConfirmationDialog>
            )}
        </>
    ));
};

export default BackupsTableDrawer;
