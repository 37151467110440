import { makeStyles } from "@material-ui/core/styles";
import { EMPTY_CARD_PLACEHOLDER_HEIGHT } from "const/styleConst";

export const useStyles = makeStyles({
    placeholderContainer: {
        height: "100%",
        minHeight: ({ hasStaticHeight }) => (hasStaticHeight ? 400 : EMPTY_CARD_PLACEHOLDER_HEIGHT)
    },
    text: {
        opacity: 0.4
    }
});
