import moment from "moment";
import Validator from "validatorjs";
import { CLIENT_DATE_FORMAT } from "const";
import i18n from "i18n";
import { getLanguageDateFormat } from "./getLanguageDateFormat";

Validator.setMessages(Validator.getDefaultLang(), {
    ...Validator.getMessages(Validator.getDefaultLang()),
    time: i18n.t("rule.time.format"),
});

const timeSettingRule = {
    time: {
        function: (value) => {
            return moment(value, getLanguageDateFormat(CLIENT_DATE_FORMAT), true).isValid();
        },
    },
};

export default timeSettingRule;
