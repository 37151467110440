import React from "react";
import { Grid, Typography } from "@material-ui/core";
import RadioContainer from "../RadioContainer";
import { useTranslation } from "react-i18next";
import Radio from "components/Radio";
import { FTP, RESERVED, SMB } from "const/shareRootAccessConst";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const PROTOCOL_CONTROLLER_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_protocol_controller`;

const ProtocolController = ({ disabled, ...props }) => {
    const { t } = useTranslation();

    return (
        <Grid item>
            <RadioContainer {...props} label={<Typography>{t("share.create_share.protocol")}</Typography>}>
                <Radio
                    disabled={disabled}
                    value={SMB}
                    label={t("share.create_share.use_smb")}
                    id={`${PROTOCOL_CONTROLLER_ID_PREFIX}_use_smb`}
                />
                {/* Temporarily hidden interface in version 7.0 */}
                {/* <Radio
                    disabled={disabled}
                    value={"nfs"}
                    label={t("share.create_share.use_nfs")}
                    id={`${PROTOCOL_CONTROLLER_ID_PREFIX}_use_nfs`}
                /> */}
                <Radio
                    disabled={disabled}
                    value={FTP}
                    label={t("share.create_share.use_ftp")}
                    id={`${PROTOCOL_CONTROLLER_ID_PREFIX}_use_ftp`}
                />
                <Radio
                    disabled={disabled}
                    value={RESERVED}
                    label={t("share.create_share.reserved")}
                    id={`${PROTOCOL_CONTROLLER_ID_PREFIX}_reserved`}
                />
            </RadioContainer>
        </Grid>
    );
};

export default ProtocolController;
