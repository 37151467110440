import React, { useContext, useEffect } from "react";
import { useObserver } from "mobx-react";
import MainTemplate from "components/MainTemplate";
import UsersPageBody from "pages/Users/components/UsersPageBody";
import TableInlineControl from "pages/Users/components/TableInlineControl";
import UserIcon from "assets/UserIcon";
import HeaderTitle from "components/HeaderTitle";
import { Grid } from "@material-ui/core";
import { Context } from "store";
import { useStyles } from "./Users.styles";
import UsersBulkOperationsBar from "./components/UsersBulkOperationsBar";
import { USERS } from "const/shortcutNameConst";
import { USERS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const Users = () => {
    const classes = useStyles();

    const {
        store: { userStore },
    } = useContext(Context);

    useEffect(() => {
        userStore.resetCurrentSearchRequest();
        userStore.searchOnUsersPage();
        return () => {
            userStore.resetCurrentSearchRequest();
            userStore.searchOnUsersPage();
        };
    }, []);

    return useObserver(() => (
        <MainTemplate tabIndex={1} headerTitle={<HeaderTitle icon={<UserIcon />} title={"Users"} shortcutName={USERS} />}>
            <Grid container direction={"column"} wrap={"nowrap"} spacing={6}>
                <UsersBulkOperationsBar parentId={USERS_PAGE_ID_PREFIX} />
                <Grid item>
                    <TableInlineControl parentId={USERS_PAGE_ID_PREFIX} />
                </Grid>
                <Grid item className={classes.resultContainer}>
                    <UsersPageBody isEmpty={userStore.searchResult === null} parentId={USERS_PAGE_ID_PREFIX} />
                </Grid>
            </Grid>
        </MainTemplate>
    ));
};

export default Users;
