import Request from "api/Request";

const GetServiceState = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/SyncJobs1",
    method: "get_service_state"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetServiceState;
