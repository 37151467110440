import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    oval: {
        backgroundColor: ({ color }) => color,
        width: theme.spacing(2),
        height: theme.spacing(2),
        borderRadius: "50%",
        marginRight: theme.spacing(2)
    }
}));
