import React, { useEffect } from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./PoolDrawer.styles";
import DiskIcon from "assets/DiskIcon";
import GrowPoolIcon from "assets/GrowPoolIcon";
import TrashIcon from "assets/TrashIcon";
import { GiB_IEC } from "const/diskSizeConst";
import GrowPoolModal from "../GrowPoolModal";
import DeletePoolDialog from "../DeletePoolDialog";
import GrowPoolWarningDialog from "../GrowPoolWarningDialog";
import { useStore } from "hooks/useStore";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { MAXIMUM_POOL_SIZE } from "const";
import OverlimitPoolWarningDialog from "../OverlimitPoolWarningDialog";
import { useModalCombine } from "hooks/useModalCombine";

const POOL_DRAWER_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_pool_drawer`;

const drawerName = "poolDrawer";
const DRIVE_TYPE_HDD = "hdd";

const PoolDrawer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const state = useLocalStore(() => ({
        createPoolInProgress: false,
        isGrowPoolInProgress: false,
    }));

    const {
        store: { volumeDrawerStore, uiStore },
    } = useStore();
    const { growPoolModal, deletePoolDialog, growPoolWarningDialog, overlimitPoolWarningModal } = useModalCombine([
        "growPoolModal",
        "deletePoolDialog",
        "growPoolWarningDialog",
        "overlimitPoolWarningModal",
    ]);

    useEffect(() => {
        if (volumeDrawerStore.currentPoolName) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, closeDrawer);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
            uiStore.changeDrawer();
        }
    }, [volumeDrawerStore.currentPoolName]);

    const closeDrawer = () => {
        volumeDrawerStore.dropAllCurrentPools();
    };

    const openGrowPoolModal = async () => {
        const pool = volumeDrawerStore.currentPoolStore.currentPool.pool;
        await volumeDrawerStore.currentPoolStore.fetchExpandingDrives({ pool });
        await volumeDrawerStore.currentPoolStore.fetchExpandableVolumes({ pool });
        growPoolModal.open();
    };

    const openWarningDialog = () => {
        if (
            volumeDrawerStore.currentPoolStore?.checkedAvailableDisks?.length +
                volumeDrawerStore.currentPoolStore?.currentPool?.drives?.length >
            MAXIMUM_POOL_SIZE
        ) {
            overlimitPoolWarningModal.open();
        } else {
            growPoolWarningDialog.open();
        }
    };

    const expandPoolProcessed = () => {
        overlimitPoolWarningModal.close();
        growPoolWarningDialog.open();
    };

    const expandPool = async () => {
        state.isGrowPoolInProgress = true;
        growPoolWarningDialog.close();

        const res = await volumeDrawerStore.currentPoolStore.expandPool({
            pool: volumeDrawerStore.currentPoolStore.currentPoolName,
            drives: volumeDrawerStore.currentPoolStore.checkedAvailableDisks.map((disk) => ({
                quad: Number(disk.split("-")[0]),
                drive: Number(disk.split("-")[1]),
            })),
        });

        if (!res) {
            return;
        }

        volumeDrawerStore.currentPoolStore.clearCheckedAvailableDrives();

        growPoolModal.close();
        state.isGrowPoolInProgress = false;
        closeDrawer();

        return res;
    };

    const onDeletePool = async () => {
        state.createPoolInProgress = true;
        const res = await volumeDrawerStore.currentPoolStore.sendRemovePoolRequest({
            pool: volumeDrawerStore.currentPoolStore.currentPoolName,
        });
        state.createPoolInProgress = false;

        if (!res) {
            return;
        }
        state.isOpenDeleteDialog = false;
        closeDrawer();

        return res;
    };

    const renderDetails = () => {
        return volumeDrawerStore.currentPoolStore?.currentPool ? (
            <Grid container direction={"column"} className={classes.detailsContainer} spacing={8}>
                {volumeDrawerStore.currentPoolStore.currentPool.drives.map((drive) => (
                    <Grid item container alignItems={"center"} key={Math.random()} spacing={4}>
                        <Grid item container wrap={"nowrap"}>
                            <Grid item xs={3}>
                                <Typography>
                                    {drive.driveType === DRIVE_TYPE_HDD
                                        ? t("disks_and_pools.pools.pool_drawer.details.quad_label_hdd")
                                        : t("disks_and_pools.pools.pool_drawer.details.quad_label_ssd")}
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography className={classes.quad}>
                                    {t("disks_and_pools.pools_card.pool_item.drive_title", {
                                        name: drive.driveName,
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container wrap={"nowrap"}>
                            <Grid item xs={3}>
                                <Typography>{t("disks_and_pools.pools.pool_drawer.details.model_label")}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant={"h6"} className={classes.model}>
                                    {drive.driveModel}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container wrap={"nowrap"}>
                            <Grid item xs={3}>
                                <Typography>{t("disks_and_pools.pools.pool_drawer.details.size_label")}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant={"h6"}>
                                    {(drive.size / GiB_IEC.value).toFixed()} {GiB_IEC.unit}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        ) : null;
    };

    const renderActions = () => {
        return volumeDrawerStore.currentPoolStore?.currentPool ? (
            <Grid direction={"column"} container>
                <BulkBarFlatButton
                    icon={<GrowPoolIcon />}
                    onClick={openGrowPoolModal}
                    id={`${POOL_DRAWER_ID_PREFIX}_open_grow_pool_modal`}
                >
                    {t("disks_and_pools.pools.pool_drawer.actions.grow_label")}
                </BulkBarFlatButton>
                <BulkBarFlatButton
                    errorColor
                    icon={<TrashIcon />}
                    onClick={deletePoolDialog.open}
                    id={`${POOL_DRAWER_ID_PREFIX}_remove_pool`}
                >
                    {t("common.button.delete")}
                </BulkBarFlatButton>
            </Grid>
        ) : null;
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            modals={
                <>
                    <DeletePoolDialog
                        ip={volumeDrawerStore.currentPoolStoreIp}
                        onClose={deletePoolDialog.close}
                        open={deletePoolDialog.isOpen}
                        pool={volumeDrawerStore.currentPoolNameWithPrefix}
                        onSubmit={onDeletePool}
                        createPoolInProgress={state.createPoolInProgress}
                    />
                    <GrowPoolModal
                        ip={volumeDrawerStore.currentPoolStoreIp}
                        onOpen={growPoolModal.isOpen}
                        inProgress={state.isGrowPoolInProgress}
                        onClose={growPoolModal.close}
                        onSubmit={openWarningDialog}
                    />
                    <GrowPoolWarningDialog
                        open={growPoolWarningDialog.isOpen}
                        onClose={growPoolWarningDialog.close}
                        pool={volumeDrawerStore.currentPoolNameWithPrefix}
                        onSubmit={expandPool}
                    />
                    <OverlimitPoolWarningDialog
                        onSubmit={expandPoolProcessed}
                        onClose={overlimitPoolWarningModal.close}
                        open={overlimitPoolWarningModal.isOpen}
                    />
                </>
            }
            title={volumeDrawerStore.currentPoolNameWithPrefix}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            icon={<DiskIcon />}
        />
    ));
};

export default PoolDrawer;
