import { types, flow, getParent } from "mobx-state-tree";
import MainSocket from "websocket";
import GetDrivesResult from "api/bcache/Responses/GetDrivesResult";
import GetEnabledResult from "api/bcache/Responses/GetEnabledResult";
import GetDrivesRequest from "api/bcache/Requests/GetDrives";
import GetEnabledRequest from "api/bcache/Requests/GetEnabled";
import DetachDrive from "api/bcache/Requests/DetachDrive";
import AttachDrive from "api/bcache/Requests/AttachDrive";

const BcacheStore = types
    .model({
        drivesStore: types.maybe(GetDrivesResult),
        enabledStore: types.maybe(GetEnabledResult)
    })
    .views(self => ({
        get drives() {
            return self.drivesStore?.data || [];
        },
        get enabled() {
            return self.enabledStore?.data.enabled || false;
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        }
    }))
    .actions(self => ({
        fetchDrives: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetDrivesRequest.create().init();
                const res = yield self.socket.send(req);
                self.drivesStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchEnabled: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetEnabledRequest.create().init();
                const res = yield self.socket.send(req);
                self.enabledStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        detachDrive: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DetachDrive.create().init(data);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        attachDrive: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = AttachDrive.create().init(data);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        })
    }));

export default BcacheStore;
