import { types } from "mobx-state-tree";

import NetIface from "api/cluster/Types/NetIface";
import Bond from "api/cluster/Types/Bond";

const GetInterfacesResultData = types.model({
    interfaces: types.array(NetIface),
    bonds: types.array(Bond)
});

export default GetInterfacesResultData;
