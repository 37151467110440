import Request from "api/Request";

const GetActivatedKeys = Request.props({
    path: "/com/studionetworksolutions/evo/manage/activation/Keys",
    method: "get_activated_keys"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetActivatedKeys;
