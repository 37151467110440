import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetInterfaces from "api/activation/interfaces/Requests/GetActivation";
import ActivatePorts from "api/activation/interfaces/Requests/Activate";
import DeactivatePorts from "api/activation/interfaces/Requests/Deactivate";
import UnregisterPort from "api/activation/interfaces/Requests/UnregisterPort";
import GetAvailableActivationsCount from "api/activation/interfaces/Requests/GetActivationsCount";
import InterfacesType from "api/activation/interfaces/Responses/GetActivationResult";
import AvailableActivationsType from "api/activation/interfaces/Responses/GetActivationsCountResult";
import { ASC, DESC, ACTIVATION_IFACES_PORT } from "const/sortColumnConst";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";

const InterfaceActivationStore = types
    .model({
        activationInterfacesStore: types.maybe(InterfacesType),
        availableActivationsCountStore: types.maybe(AvailableActivationsType),
        checkedIfaces: types.optional(types.array(types.string), []),
        loadingIfaces: types.optional(types.array(types.string), [])
    })
    .volatile(() => ({
        orderBy: ACTIVATION_IFACES_PORT,
        order: ASC
    }))
    .views(self => ({
        get activationInterfaces() {
            return self.activationInterfacesStore && self.activationInterfacesStore.data;
        },
        get availableActivationsCount() {
            return self.availableActivationsCountStore && self.availableActivationsCountStore.data;
        },
        get sortedActivationInterfaces() {
            return self.activationInterfaces && stableSort(self.activationInterfaces, getComparator(self.order, self.orderBy));
        },
        get checkedIfacesCount() {
            return self.checkedIfaces.length;
        },
        get mainCheckboxStatus() {
            if (self.activationInterfaces) {
                if (self.checkedIfacesCount > 0 && self.checkedIfacesCount === self.activationInterfaces.length) {
                    return "checked";
                }
                if (self.checkedIfacesCount > 0 && self.checkedIfacesCount < self.activationInterfaces.length) {
                    return "indeterminated";
                }
            }
            return null;
        },
        get hasDeactivatedIface() {
            if (self.activationInterfaces) {
                return self.checkedIfaces.some(checkedIface => {
                    const foundIface = self.activationInterfaces.find(iface => iface.port === checkedIface);
                    return foundIface && !foundIface.activated;
                });
            }
            return null;
        },
        get deactivatedCheckedIface() {
            if (self.activationInterfaces) {
                return self.checkedIfaces.filter(
                    checkedIface => !self.activationInterfaces.find(iface => iface.port === checkedIface)?.activated
                );
            }
            return [];
        },
        get activatedCheckedIface() {
            if (self.activationInterfaces) {
                return self.checkedIfaces.filter(
                    checkedIface => self.activationInterfaces.find(iface => iface.port === checkedIface)?.activated
                );
            }
            return [];
        }
    }))
    .actions(self => ({
        fetchActivationInterfaces: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetInterfaces.create().init();
                const res = yield Socket.send(req);
                self.activationInterfacesStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchAvailableActivationsCount: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetAvailableActivationsCount.create().init();
                const res = yield Socket.send(req);
                self.availableActivationsCountStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        activatePorts: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ActivatePorts.create().init(data);
                yield Socket.send(req);
                self.clearCheckedIfaces();
            } catch (e) {
                self.clearLoadingInterfaces();
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        deactivatePorts: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DeactivatePorts.create().init(data);
                yield Socket.send(req);
                self.clearCheckedIfaces();
            } catch (e) {
                self.clearLoadingInterfaces();
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        unregisterPort: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = UnregisterPort.create().init(data);
                yield Socket.send(req);
                self.checkedIfaces.includes(data.port) && self.removeCheckedIface(data.port);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        changeSorting(column) {
            if (column === self.orderBy) {
                self.order = self.order === DESC ? ASC : DESC;
            } else {
                self.order = ASC;
                self.orderBy = column;
            }
        },
        addCheckedIface: ifacePort => {
            self.checkedIfaces.push(ifacePort);
        },
        concatLoadingIface: ifacePort => {
            self.loadingIfaces = self.loadingIfaces.slice().concat(ifacePort);
        },
        clearLoadingInterfaces: () => {
            self.loadingIfaces.clear();
        },
        removeCheckedIface: ifacePort => {
            self.checkedIfaces.remove(ifacePort);
        },
        clearCheckedIfaces: () => {
            self.checkedIfaces = [];
        },
        checkAllIfaces: () => {
            if (self.activationInterfaces) {
                self.checkedIfaces = self.activationInterfaces.filter(iface => iface.exists).map(iface => iface.port);
            }
        }
    }));

export default InterfaceActivationStore;
