import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from "localization/en-US.json";
import zn from "localization/zh-CN.json";

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            "en-US": {
                translation: en
            },
            "zh-CN": {
                translation: zn
            }
        },
        fallbackLng: "en-US",
        debug: true,
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true
        }
    });

export default i18n;
