import React from "react";
import config from "config";

export const RoutingPlugin = ({ children, plugin }) => {
    const isServerPort = plugin.serverPort && Boolean(~plugin.serverPort);

    const handleClick = () => {
        window.location.href = `${config.protocol}://${config.path}:${plugin.serverPort}/${plugin.alias}`;
    };

    return React.cloneElement(children, {
        title: plugin.name,
        description: plugin.description,
        url: plugin.alias,
        onClick: isServerPort ? handleClick : null,
        img: plugin.urlImgPlugin,
        replace: true,
        forPlugin: true,
    });
};
