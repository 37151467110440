import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    textWithOpacity: {
        opacity: 0.4,
        textAlign: "center"
    },
    link: {
        textDecoration: "underline",
        color: theme.palette.primary.main,
        cursor: "pointer"
    }
}));
