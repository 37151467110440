import React from "react";
import { Switch as MuiSwitch } from "@material-ui/core";
import { useStyles } from "./Switch.style";
import clsx from "clsx";

const Switch = ({ className, ...props }) => {
    const classes = useStyles();
    return <MuiSwitch className={clsx(classes.switch, className)} {...props} />;
};

export default Switch;
