import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./CardHeader.style";
import clsx from "clsx";
import CardHeaderControlButton from "components/CardHeaderControlButton";

const CardHeader = ({ title, content, control, invisibleIcon, className }) => {
    const classes = useStyles();
    return (
        <Grid
            className={clsx(classes.cardHeader, className)}
            container
            justify={"space-between"}
            alignItems={"center"}
            wrap={"nowrap"}
        >
            <Grid item className={classes.content}>
                <Grid container alignItems={"center"} wrap={"nowrap"}>
                    <Grid item className={clsx(classes.icon, invisibleIcon && classes.invisibleIcon)} />
                    <Grid item className={classes.content}>
                        {content ? content : <Typography variant={"h2"}>{title}</Typography>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid alignItems={"center"} container spacing={3}>
                    {React.Children.map(control, (component) => {
                        return (
                            <Grid key={component?.key} item>
                                <CardHeaderControlButton wrap={"nowrap"}>{component}</CardHeaderControlButton>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CardHeader;
