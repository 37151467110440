import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import { useStore } from "hooks/useStore";
import Dialog from "components/Dialog";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import RowTextInput from "components/RowTextInput";
import TextInput from "components/MobxForm/TextInput";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStyles } from "./SetTimeoutTokensModal.styles";
import { useModal } from "hooks/useModal";

const SET_TIMEOUT_TOKENS_MODAL_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_set_timeout_tokens_modal`;

const SetTimeoutTokensModal = ({ open, onClose, inProgress, form }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const unsavedChangesModal = useModal();
    const {
        store: { longLivedTokensStore },
    } = useStore();

    const state = useLocalStore(() => ({
        inProgress: false,
        isSubmitted: false,
    }));

    const onSubmit = async (e) => {
        form.onSubmit(e);
        if (!form.isValid) return;
        state.inProgress = true;
        await longLivedTokensStore.setExpiration({ expirationPeriod: parseInt(form.$("expirationDate").value) });

        state.isSubmitted = true;
        state.inProgress = false;
        unsavedChangesModal.close();
    };

    const onEnter = () => {
        state.inProgress = false;
        state.isSubmitted = false;
        unsavedChangesModal.close();
    };

    const closeIfNeeded = () => {
        if (form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }

        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <>
            <Dialog
                open={open}
                buttons={[
                    <Button
                        key={"cancelBtn"}
                        onClick={onClose}
                        variant={"contained"}
                        color={"secondary"}
                        id={`${SET_TIMEOUT_TOKENS_MODAL_ID_PREFIX}_cancel`}
                    >
                        {t("admin_settings.long_lived_tokens_card.set_timeout_tokens_dialog.cancel")}
                    </Button>,
                ]}
                onEnter={onEnter}
                inProgress={inProgress || state.inProgress}
                onSubmit={onSubmit}
                disableSubmit={inProgress || state.inProgress}
                submitBtnLabel={t("admin_settings.long_lived_tokens_card.set_timeout_tokens_dialog.confirm")}
                submitBtnId={`${SET_TIMEOUT_TOKENS_MODAL_ID_PREFIX}_set_timeout`}
                title={t("admin_settings.long_lived_tokens_card.set_timeout_tokens_dialog.title")}
                onClose={closeIfNeeded}
            >
                <RowTextInput
                    label={t("admin_settings.long_lived_tokens_card.set_timeout_tokens_dialog.label.expire")}
                    control={
                        <>
                            <Grid container alignItems={"center"} className={classes.content}>
                                <Grid item xs={8}>
                                    <TextInput
                                        field={form.$("expirationDate")}
                                        disabled={inProgress}
                                        id={`${SET_TIMEOUT_TOKENS_MODAL_ID_PREFIX}_expiration`}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography align={"center"}>
                                        {t("admin_settings.long_lived_tokens_card.set_timeout_tokens_dialog.label.expire.days")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    }
                    rightXs={6}
                    leftXs={6}
                />
            </Dialog>
            <UnsavedChangesDialog open={unsavedChangesModal.isOpen} onClose={unsavedChangesModal.close} onConfirm={onClose} />
        </>
    ));
};

export default SetTimeoutTokensModal;
