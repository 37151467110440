import React from "react";
import CardHeader from "components/CardHeader";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useStyles } from "./MoreHelpCard.style";
import { URL_SUPPORT } from "routes";
import { useTranslation } from "react-i18next";
import { SupportQuickStartIcon } from "assets/SupportQuickStartIcon";
import Link from "components/Link/Link";

const MoreHelpCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper>
            <CardHeader title={t("quick_start.more_help_card.title")} />
            <Grid container direction={"column"} spacing={2} className={classes.contentContainer}>
                <Grid item>
                    <Typography gutterBottom>
                        {t("quick_start.more_help_card.text.snippet_0", { count: 0 })}
                        <Link path={URL_SUPPORT} inNewTab>
                            <SupportQuickStartIcon />
                        </Link>
                        {t("quick_start.more_help_card.text.snippet_1", { count: 1 })}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default MoreHelpCard;
