import { types } from "mobx-state-tree";

import CertificateInfo from "api/certificate/Types/CertificateInfo";

const GetInstalledCertificateInfoResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(CertificateInfo)
});

export default GetInstalledCertificateInfoResult;
