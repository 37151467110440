import { makeStyles } from "@material-ui/core/styles";
import { VOLUME_STATUS_OPTIONS } from "const/clusterConst";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => {
    const getColor = status => {
        switch (status) {
            case VOLUME_STATUS_OPTIONS.started:
                return theme.palette.success.main;
            case VOLUME_STATUS_OPTIONS.degraded:
            case VOLUME_STATUS_OPTIONS.needCommit:
            case VOLUME_STATUS_OPTIONS.migrationInProgress:
                return theme.palette.warning.main;
            case VOLUME_STATUS_OPTIONS.incomplete:
            case VOLUME_STATUS_OPTIONS.incompleteCorrupt:
                return theme.palette.primary.dark;
            default:
                return theme.palette.error.main;
        }
    };

    return {
        label: {
            padding: theme.spacing(0, 2),
            borderRadius: theme.spacing(1),
            backgroundColor: props => hexToRgba(getColor(props.status), 0.1),
            "& span": {
                color: props => getColor(props.status)
            }
        }
    };
});
