import { types } from "mobx-state-tree";

const WatermarkType = types.model({
    json: types.maybe(
        types.model({
            list: types.array(
                types.model({
                    builtin: types.boolean,
                    created: types.string,
                    disabled: types.boolean,
                    id: types.number,
                    image_loaded: types.boolean,
                    name: types.string,
                    owner: types.string,
                    personal: types.boolean,
                })
            ),
            total: types.number,
            offset: types.number,
        })
    ),
});

export default WatermarkType;
