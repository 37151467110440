import { types } from "mobx-state-tree";

import Request from "api/Request";
import EditShareArguments from "api/share/Types/EditShareArguments";

const EditShare = Request.props({
    path: "/com/studionetworksolutions/evo/Nas",
    method: "edit_share",
    arguments: types.maybe(EditShareArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default EditShare;
