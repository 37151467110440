import React, { useContext } from "react";
import { Context } from "store";
import MainTemplate from "components/MainTemplate";
import { useTranslation } from "react-i18next";
import HeaderTitle from "components/HeaderTitle";
import SpeedIcon from "assets/SpeedIcon";
import { Grid } from "@material-ui/core";
import MultipleEvoTitle from "components/MultipleEvoTitle";
import NetworkGraphCard from "pages/Dashboard/components/NetworkGraphCard";
import { useObserver } from "mobx-react";
import PhysicalDisks from "pages/Dashboard/components/PhysicalDisks";
import StorageUsageGraphCard from "pages/Dashboard/components/StorageUsageGraphCard";
import CpuGraphCard from "pages/Dashboard/components/CpuGraphCard";
import ThroughputGraphCard from "pages/Dashboard/components/ThroughputGraphCard";
import { CENTRAL_STATUS } from "const/shortcutNameConst";

const CentralStatus = () => {
    const { t } = useTranslation();
    const { multipleEvosRootStore } = useContext(Context);

    return useObserver(() => (
        <MainTemplate
            largeWidthContent
            headerTitle={<HeaderTitle icon={<SpeedIcon />} shortcutName={CENTRAL_STATUS} title={t("central_status.title")} />}
        >
            <>
                <Grid container direction="column" spacing={6} wrap="nowrap">
                    <Grid container item direction="column" spacing={6}>
                        {multipleEvosRootStore.storesLength > 0 && (
                            <Grid item>
                                <MultipleEvoTitle />
                            </Grid>
                        )}
                        <Grid container item spacing={6}>
                            <Grid item container spacing={6} xs={12} lg={6} direction="column">
                                <Grid item container>
                                    <PhysicalDisks />
                                </Grid>
                                <Grid item container>
                                    <StorageUsageGraphCard />
                                </Grid>
                                <Grid item container></Grid>
                            </Grid>
                            <Grid item container spacing={6} xs={12} lg={6} direction="column">
                                <Grid item container>
                                    <CpuGraphCard />
                                </Grid>
                                <Grid item container>
                                    <ThroughputGraphCard />
                                </Grid>
                                <Grid item container>
                                    <NetworkGraphCard />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {multipleEvosRootStore.storesIps.map(ip => (
                        <Grid key={ip} container item direction="column" spacing={6}>
                            <Grid item>
                                <MultipleEvoTitle ip={ip} />
                            </Grid>
                            <Grid container item spacing={6}>
                                <Grid item container spacing={6} xs={12} lg={6} direction="column">
                                    <Grid item container>
                                        <PhysicalDisks ip={ip} />
                                    </Grid>
                                    <Grid item container>
                                        <StorageUsageGraphCard ip={ip} />
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={6} xs={12} lg={6} direction="column">
                                    <Grid item container>
                                        <CpuGraphCard ip={ip} />
                                    </Grid>
                                    <Grid item container>
                                        <ThroughputGraphCard ip={ip} />
                                    </Grid>
                                    <Grid item container>
                                        <NetworkGraphCard ip={ip} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </>
        </MainTemplate>
    ));
};

export default CentralStatus;
