import React, { forwardRef } from "react";
import { Box } from "@material-ui/core";
import { useResizeHeight } from "hooks/useResizeHeight";
import { useStyles } from "./NotificationMenuContainer.style";
import { HEADER_HEIGHT, OPENED_HEADER_HEIGHT } from "const/styleConst";

const NotificationMenuContainer = forwardRef(({ className, children, isWidgetsBarOpen }, ref) => {
    const extraHeight = isWidgetsBarOpen ? OPENED_HEADER_HEIGHT : HEADER_HEIGHT;
    const refBox = useResizeHeight(extraHeight);
    const classes = useStyles({ isWidgetsBarOpen });

    return (
        <Box ref={ref} className={className}>
            <div ref={refBox} className={classes.conteiner}>
                {children}
            </div>
        </Box>
    );
});

export default NotificationMenuContainer;
