import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import SideBulkBar from "components/SideBulkBar";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import PlusIcon from "assets/PlusIcon";
import AddToGroupDialog from "../AddToGroupDialog";
import TrashIcon from "assets/TrashIcon";
import ResetIcon from "assets/ResetIcon";
import EditIcon from "assets/EditIcon";
import SharingIcon from "assets/SharingIcon";
import ResetPasswordDialog from "../ResetPasswordDialog";
import AssignSharesModal from "../AssignSharesModal";
import EditUsersGroupsModal from "../EditUsersGroupsModal";
import CustomizeSettingsModal from "../CustomizeSettingsModal";
import { useStore } from "hooks/useStore";
import { EXTERNAL_USERS, LOCAL_USERS } from "const/userSearchVariants";
import { useModalCombine } from "hooks/useModalCombine";
import ConfirmationDialog from "components/ConfirmationDialog";

const drawerName = "UsersBulkOperationsBar";
const USERS_BULK_OPERATIONS_BAR_ID_PREFIX = "users_bulk_operations_bar";

const UsersBulkOperationsBar = ({ parentId }) => {
    const { t } = useTranslation();
    const idPrefix = parentId ? `${parentId}_${USERS_BULK_OPERATIONS_BAR_ID_PREFIX}` : USERS_BULK_OPERATIONS_BAR_ID_PREFIX;
    const {
        store: { userStore, uiStore, aclStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isResetPasswordInProgress: false,
        deleteUserInProgress: false,
    }));

    const {
        addToGroupModal,
        customizeSettingsModal,
        resetPasswordModal,
        editUsersGroupsModal,
        assignSharesModal,
        deleteUserModal,
    } = useModalCombine([
        "addToGroupModal",
        "customizeSettingsModal",
        "resetPasswordModal",
        "editUsersGroupsModal",
        "assignSharesModal",
        "deleteUserModal",
    ]);

    useEffect(() => {
        if (userStore.checkedUsers.length > 0) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, onClose);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
            uiStore.changeDrawer();
        }
    }, [userStore.checkedUsers.length]);

    const onClose = () => {
        addToGroupModal.close();
        userStore.clearCheckedUsers();
    };

    const deleteUser = async () => {
        deleteUserModal.close();
        state.deleteUserInProgress = true;
        const result = await userStore.deleteUser({
            name: userStore.currentEntitySingleBar.id.name,
            force: true,
        });
        state.deleteUserInProgress = false;

        if (result) {
            userStore.dropCurrentEntitySingleBar();
            userStore.dropCurrentEntity();
            uiStore.closeDrawer();
        }

        refreshRequest();
    };

    const refreshRequest = () => {
        userStore.searchOnUsersPage();
    };

    const openCustomizeSettingsModal = ({ shareName }) => {
        assignSharesModal.close();
        aclStore.setCurrentShareName(shareName);
        customizeSettingsModal.open();
    };

    const closeCustomizeSettingsModal = () => {
        customizeSettingsModal.close();
        aclStore.setCurrentShareName(null);
    };

    const onCustomizeSettingsModalBack = () => {
        closeCustomizeSettingsModal();
        assignSharesModal.open();
    };

    const isOpenAddCondition = userStore.currentSearchRequest.type === EXTERNAL_USERS ? userStore.checkedUsers.length : true;
    const isLocalCheckedUser = userStore.checkedUsers.length === 1 && userStore.currentSearchRequest.type === LOCAL_USERS;

    return useObserver(() => (
        <SideBulkBar
            itemsCount={userStore.checkedUsers.length}
            onClose={onClose}
            isOpen={uiStore.openedDrawer === drawerName && isOpenAddCondition}
        >
            {isLocalCheckedUser ? (
                <React.Fragment>
                    <BulkBarFlatButton
                        onClick={deleteUserModal.open}
                        errorColor
                        icon={<TrashIcon />}
                        inProgress={state.deleteUserInProgress}
                        id={`${idPrefix}_open_delete_user_modal`}
                    >
                        {t("common.button.delete")}
                    </BulkBarFlatButton>

                    <BulkBarFlatButton
                        onClick={resetPasswordModal.open}
                        icon={<ResetIcon />}
                        id={`${idPrefix}_open_reset_password_modal`}
                    >
                        {t("users.reset_password.button.reset_password")}
                    </BulkBarFlatButton>

                    <BulkBarFlatButton
                        onClick={editUsersGroupsModal.open}
                        icon={<EditIcon />}
                        id={`${idPrefix}_open_edit_users_group_modal`}
                    >
                        {t("users.side_menu.actions.button.edit_users_groups")}
                    </BulkBarFlatButton>

                    <BulkBarFlatButton
                        onClick={assignSharesModal.open}
                        icon={<SharingIcon />}
                        id={`${idPrefix}_open_assign_shares_modal`}
                    >
                        {t("users.side_menu.actions.button.assign_shares")}
                    </BulkBarFlatButton>
                </React.Fragment>
            ) : null}

            <BulkBarFlatButton onClick={addToGroupModal.open} icon={<PlusIcon />} id={`${idPrefix}_open_add_to_group_modal`}>
                {t("users.bulk_operations_bar.button.add_to_group")}
            </BulkBarFlatButton>

            <AddToGroupDialog open={addToGroupModal.isOpen} onClose={addToGroupModal.close} parentId={idPrefix} />
            <ResetPasswordDialog open={resetPasswordModal.isOpen} onClose={resetPasswordModal.close} parentId={idPrefix} />
            <EditUsersGroupsModal open={editUsersGroupsModal.isOpen} onClose={editUsersGroupsModal.close} parentId={idPrefix} />
            <AssignSharesModal
                open={assignSharesModal.isOpen}
                onClose={assignSharesModal.close}
                openCustom={openCustomizeSettingsModal}
                parentId={idPrefix}
            />
            <CustomizeSettingsModal
                open={customizeSettingsModal.isOpen}
                onClose={closeCustomizeSettingsModal}
                onBack={onCustomizeSettingsModalBack}
                parentId={idPrefix}
            />
            <ConfirmationDialog
                title={t("common.warning_dialog.title")}
                open={deleteUserModal.isOpen}
                onClose={deleteUserModal.close}
                onConfirm={deleteUser}
                confirmLabel={t("common.button.proceed")}
                cancelLabel={t("common.button.cancel")}
                fullWidth={true}
                id={`${idPrefix}_delete_user_modal`}
            >
                {t("users.side_menu.confirm_delete_user")}
            </ConfirmationDialog>
        </SideBulkBar>
    ));
};

export default UsersBulkOperationsBar;
