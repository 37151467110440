import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./CardHeaderControlButton.style";
import clsx from "clsx";

const CardHeaderControlButton = ({ children, className, isContainer, ...rest }) => {
    const classes = useStyles();
    const container = useMemo(() => (isContainer === "undefined" ? true : isContainer), [isContainer]);
    return (
        <Grid
            container={container}
            alignItems={"center"}
            justify={"flex-end"}
            className={clsx(classes.container, className)}
            {...rest}
        >
            {children}
        </Grid>
    );
};

export default CardHeaderControlButton;
