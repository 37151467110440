import { types } from "mobx-state-tree";

import HostsAce from "api/security/Types/HostsAce";

const GetAllowedHostsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(HostsAce)
});

export default GetAllowedHostsResult;
