import { types } from "mobx-state-tree";

import DriveStatus from "api/drive/Types/DriveStatus";

const QuadStatus = types.model({
    quad: types.number,
    driveStatus: types.array(DriveStatus)
});

export default QuadStatus;
