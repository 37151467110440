import React from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import SavedInputIndicator from "components/SavedInputIndicator";
import SavedInputPreview from "components/SavedInputPreview";
import GraphsSettingsDialog from "../GraphsSettingsDialog";
import { GRAPHS_SETTINGS_CARD } from "const/widgetNameConst";
import { ADVANCED_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { useStore } from "hooks/useStore";

const GRAPHS_SETTINGS_CARD_ID_PREFIX = `${ADVANCED_PAGE_ID_PREFIX}_graphs_settings_card`;

const GraphsSettingsCard = () => {
    const { t } = useTranslation();
    const {
        store: { uiStore }
    } = useStore();
    const state = useLocalStore(() => ({
        isLoading: false,
        openEditDialog: false
    }));

    useAsyncEffect(async () => {
        if (uiStore.parameters) return;
        state.isLoading = true;
        await uiStore.fetchUiParameters();
        state.isLoading = false;
    }, []);

    const closeDialog = () => {
        state.openEditDialog = false;
    };

    const openDialog = () => {
        state.openEditDialog = true;
    };

    const getShowLastValue = () => {
        switch (uiStore.parameters?.graphStartPeriod) {
            case 15 * 60:
                return t("advanced.graphs_settings_card.minutes_plural", { count: 15 });
            case 30 * 60:
                return t("advanced.graphs_settings_card.minutes_plural", { count: 30 });
            case 60 * 60:
                return t("advanced.graphs_settings_card.hour", { count: 1 });
            default:
                return "";
        }
    };

    return useObserver(() => (
        <>
            <GraphsSettingsDialog open={state.openEditDialog} onClose={closeDialog} />
            <DataReadonlyCard
                headerDivider
                title={t("advanced.graphs_settings_card.title")}
                control={
                    <MoreMenu id={`${GRAPHS_SETTINGS_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={GRAPHS_SETTINGS_CARD} />
                    </MoreMenu>
                }
                onClick={openDialog}
            >
                {!state.isLoading ? (
                    <Grid id="advanced-graphs-settings-card" container direction="column" spacing={5}>
                        <Grid container item alignItems="center" justify="space-between">
                            <Grid item xs={6}>
                                <Typography>{t("advanced.graphs_settings_card.label.show_last")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <SavedInputPreview>{getShowLastValue()}</SavedInputPreview>
                            </Grid>
                        </Grid>
                        <Grid container item alignItems="center" justify="space-between">
                            <Grid item xs={6}>
                                <Typography>{t("advanced.graphs_settings_card.label.autoscaling")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <SavedInputIndicator enabled={uiStore.parameters?.graphAutoscaling} />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder inProgress={state.isLoading} />
                )}
            </DataReadonlyCard>
        </>
    ));
};

export default GraphsSettingsCard;
