import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import { useStyles } from "./ImportRulesModal.styles";
import DragAndDropFile from "components/DragAndDropFile";
import Dialog from "components/Dialog";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";

const ImportRulesModal = ({ open, onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { firewallStore },
    } = useStore();

    const state = useLocalStore(() => ({
        currentFile: null,
    }));

    const unsavedChangesModal = useModal();

    const onFilesSelect = (files) => {
        state.currentFile = files[0];
    };

    const onSubmit = async () => {
        const res = await firewallStore.uploadFileRules(state.currentFile);

        if (res) {
            onClose();
            state.currentFile = null;
            return res;
        }
    };
    const onEnter = () => {
        state.currentFile = null;
        unsavedChangesModal.close();
    };

    const closeIfNeeded = () => {
        if (state.currentFile === null) {
            onClose();
            return;
        }
        unsavedChangesModal.opem();
    };

    return useObserver(() => (
        <Dialog
            open={open}
            buttons={
                <Button onClick={onClose} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>
            }
            onEnter={onEnter}
            onSubmit={onSubmit}
            disableSubmit={!state.currentFile}
            submitBtnLabel={t("common.button.import")}
            title={t("firewall.advanced_mode.import")}
            onClose={closeIfNeeded}
        >
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            <Grid container className={classes.contentContainer}>
                {state.currentFile ? (
                    <Grid container wrap={"nowrap"} className={classes.fileContainer}>
                        <Grid item container justify={"flex-start"} alignItems={"center"}>
                            <Typography className={classes.fileNameText}>{state.currentFile.name}</Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item container>
                        <DragAndDropFile
                            title={t("firewall.advanced_mode.import.drag_and_drop.placeholder")}
                            handleFiles={onFilesSelect}
                        />
                    </Grid>
                )}
            </Grid>
        </Dialog>
    ));
};

export default ImportRulesModal;
