import Request from "api/Request";

const ListTokens = Request.props({
    path: "/com/studionetworksolutions/evo/Auth",
    method: "list_tokens",
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default ListTokens;
