export const ADMIN_SETTINGS_PAGE_ID_PREFIX = "admin_settings";
export const ADVANCED_PAGE_ID_PREFIX = "advanced";
export const ALERTS_PAGE_ID_PREFIX = "alerts";
export const AUDIT_PAGE_ID_PREFIX = "audit";
export const BOOT_LOAD_PAGE_PAGE_ID_PREFIX = "boot_load_page";
export const SHARES_PAGE_ID_PREFIX = "shares";
export const SUPPORT_PAGE_ID_PREFIX = "support";
export const DISKS_AND_POOLS_PAGE_ID_PREFIX = "disks_and_pools";
export const REPLACE_DISK_PAGE_ID_PREFIX = "replace-disk";
export const HARDWARE_ACTIVATION_PAGE_ID_PREFIX = "hardware_activation";
export const SLINGSHOT_PAGE_ID_PREFIX = "slingshot";
export const USERS_PAGE_ID_PREFIX = "users";
export const MIGRATION_PAGE_ID_PREFIX = "migration";
