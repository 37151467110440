import { types, flow, getParent, getSnapshot } from "mobx-state-tree";
import { createMultipleEvoSocket } from "websocket";
import { getAxiosForMultipleEvo } from "api/AxiosCommonRequest";
import { WEBSOCKET_TOKEN_URL, LOGOUT_URL, SYSTEM_INFO, LOGIN_WITH_LONG_LIVE_TOKEN_URL } from "api/restRoutes";
import MultipleEvosRootStore from "store/MultipleEvosRootStore";
import { ADMIN } from "const/userRolesConst";
import { t } from "i18next";
import { TOKEN_IS_INVALID } from "store/AuthStore/AuthStore";
import { imTeapot } from "utils/imTeapot";
import { autorun } from "mobx";

const MultipleEvosStore = types
    .model({ isAutoconnectionCompleted: types.optional(types.boolean, false) })
    .volatile(() => ({
        multipleEvoConnections: [], // {ip, name, socket, axios, isAuthorized}
    }))
    .views((self) => ({
        getLoginHash(username, password) {
            const hash = btoa(`${username}:${password}`);
            return `Basic ${hash}`;
        },
        get multipleEvoLocalStorage() {
            const storage = localStorage.getItem("multipleEvo");
            try {
                return JSON.parse(storage) || [];
            } catch (e) {
                return [];
            }
        },
        get isMultipleEvoConnections() {
            return Boolean(self.multipleEvoConnections.length);
        },
        get authorizedConnections() {
            return self.multipleEvoConnections.filter((connection) => connection.isAuthorized);
        },
        get authorizedConnectionsCount() {
            return self.authorizedConnections.length;
        },
    }))
    .actions((self) => ({
        afterCreate() {
            autorun(() => {
                if (self.authorizedConnectionsCount) {
                    self.multipleEvoConnections.forEach((connection) => {
                        connection.setAxiosAuthToken();
                        connection.setAxiosNoCacheAuthToken();
                    });
                }
            });
        },
        setEvoToLocalStorage: ({ ip, name, authToken }) => {
            const copyStorage = localStorage.getItem("multipleEvo");
            const parseCopyStorage = (copyStorage && JSON.parse(copyStorage)) || [];
            const foundEvo = parseCopyStorage.find((evo) => evo.ip === ip);

            if (foundEvo) {
                name && (foundEvo.name = name);
                authToken && (foundEvo.authToken = authToken);
            } else {
                parseCopyStorage.push({ ip, name, authToken });
            }

            localStorage.setItem("multipleEvo", JSON.stringify(parseCopyStorage)); //TODO: do not overwrite if nothing has changed
        },
        deleteEvoFromLocalStorage: (ip) => {
            let copyStorage = [...self.multipleEvoLocalStorage];
            copyStorage = copyStorage.filter((evo) => evo.ip !== ip);
            localStorage.setItem("multipleEvo", JSON.stringify(copyStorage));
        },
        resetMultipleEvoLocalStorage: () => {
            localStorage.setItem("multipleEvo", "[]");
            MultipleEvosRootStore.removeAllStores();
        },
        loginToMultipleEvo: flow(function* ({ username, password, ip }, config = { userTryingConnect: false }) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);

                const foundConnection = self.multipleEvoConnections.find((connection) => connection.ip === ip);
                if (foundConnection) {
                    const sameVersions = yield self.compareEvoVersions(ip, config);
                    if (!sameVersions) return null;

                    const res = yield foundConnection.axios.get(LOGIN_WITH_LONG_LIVE_TOKEN_URL, {
                        headers: { Authorization: self.getLoginHash(username, password) },
                    });
                    const { websocket_token, access_token, user } = res.data;

                    if (user.role !== ADMIN) {
                        throw new Error(`${foundConnection.name}: ${t("login.multiple.error")}`);
                    }

                    self.setEvoToLocalStorage({ ip, authToken: `Bearer ${access_token}` });
                    foundConnection.axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
                    yield foundConnection.socket.start(websocket_token);

                    //create store after successful socket connection
                    MultipleEvosRootStore.addStore({
                        ip,
                        socket: foundConnection.socket,
                        name: foundConnection.name,
                        role: user.role || null,
                        axios: foundConnection.axios,
                    });

                    foundConnection.isAuthorized = true;
                    self.multipleEvoConnections = [...self.multipleEvoConnections];
                }
                return true;
            } catch (e) {
                const foundConnection = self.multipleEvoConnections.find((connection) => connection.ip === ip);
                if (e.response && e.response.data.code === 1000) {
                    processingStore.setError(
                        foundConnection
                            ? `${foundConnection.name}: ${t("login.multiple.error.credential")}`
                            : t("login.multiple.error.credential")
                    );
                } else {
                    processingStore.setError({ message: `${foundConnection?.name}: ${e}` });
                }

                MultipleEvosRootStore.removeStore(ip);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getAxiosNoCacheByIp: (ip) => {
            const foundConnection = self.multipleEvoConnections.find((connection) => connection.ip === ip);
            return foundConnection?.axiosNoCache;
        },
        closeConnection(ip) {
            const indexCurrecntConnection = self.multipleEvoConnections.findIndex((connection) => connection.ip === ip);
            let currentConnection = self.multipleEvoConnections[indexCurrecntConnection];

            if (currentConnection) {
                currentConnection.isAuthorized = false;
                self.multipleEvoConnections.splice(indexCurrecntConnection, 1, currentConnection);
                self.multipleEvoConnections = [...self.multipleEvoConnections];
            }

            MultipleEvosRootStore.removeStore(ip);

            return currentConnection;
        },
        logoutFromMultipleEvo: (ip) => {
            const connection = self.closeConnection(ip);
            connection.axios.get(LOGOUT_URL);
            self.setEvoToLocalStorage({ ip, name: connection.name, authToken: TOKEN_IS_INVALID });
        },
        cleanMultipleEvoStores: () => {
            MultipleEvosRootStore.removeAllStores();
            self.multipleEvoConnections = [];
        },
        parseLocalStorageEvos: () => {
            self.multipleEvoConnections = self.multipleEvoConnections.filter((evo) =>
                self.multipleEvoLocalStorage.some((storageEvo) => storageEvo.ip === evo.ip)
            );
            self.multipleEvoLocalStorage.forEach((evo) => {
                const foundConnection = self.multipleEvoConnections.find((connection) => connection.ip === evo.ip);
                if (foundConnection) {
                    foundConnection.name !== evo.name && (foundConnection.name = evo.name);
                } else {
                    const axiosOptions = {
                        onError(error) {
                            const foundConnection = self.multipleEvoConnections.find((connection) => connection.ip === evo.ip);

                            if (error?.response?.status === 401 && foundConnection.isAuthorized) {
                                foundConnection.isAuthorized = false;
                                self.logoutFromMultipleEvo(evo.ip);
                            }
                        },
                    };

                    const axiosNoCache = getAxiosForMultipleEvo(evo.ip, axiosOptions, true);
                    const axios = getAxiosForMultipleEvo(evo.ip, axiosOptions);

                    self.multipleEvoConnections.push({
                        ip: evo.ip,
                        name: evo.name,
                        socket: createMultipleEvoSocket(evo.ip),
                        axios,
                        axiosNoCache,
                        setAxiosAuthToken: axios.setAuthToken,
                        setAxiosNoCacheAuthToken: axiosNoCache.setAuthToken,
                        isAuthorized: false,
                    });
                }
            });
        },
        mapUiParametersEvoToLocalStorage: () => {
            const { uiStore } = getParent(self);
            if (uiStore.parameters?.multipleEvos?.length > 0) {
                uiStore.parameters.multipleEvos.forEach((evo) => {
                    self.setEvoToLocalStorage({ ip: evo.ip, name: evo.name });
                });
            } else {
                self.resetMultipleEvoLocalStorage();
            }
            self.parseLocalStorageEvos();
        },
        removeMultipleEvo: flow(function* (ip) {
            const { processingStore, uiStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const multipleEvos = getSnapshot(uiStore.parameters)?.multipleEvos;
                const multipleEvosSnapshot = [...multipleEvos];
                const foundEvoIndex = multipleEvosSnapshot?.findIndex((evo) => evo.ip === ip);
                if (foundEvoIndex > -1) {
                    multipleEvosSnapshot.splice(foundEvoIndex, 1);
                    yield uiStore.patchUiParameters({ multipleEvos: multipleEvosSnapshot });
                    self.multipleEvoConnections.splice(foundEvoIndex, 1);
                }
                MultipleEvosRootStore.removeStore(ip);
                self.deleteEvoFromLocalStorage(ip);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateMultipleEvo: flow(function* ({ ip, name, needToConnect }) {
            const { processingStore, uiStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                if (!uiStore.parameters) return null;
                const multipleEvosSnapshot = JSON.parse(JSON.stringify(getSnapshot(uiStore.parameters.multipleEvos)));
                const foundEvo = multipleEvosSnapshot?.find((evo) => evo.ip === ip);
                if (foundEvo) {
                    name !== undefined && (foundEvo.name = name);
                    needToConnect !== undefined && (foundEvo.needToConnect = needToConnect);
                    yield uiStore.patchUiParameters({ multipleEvos: multipleEvosSnapshot });
                    // self.mapUiParametersEvoToLocalStorage will be called after websocket broadcast
                }
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        addEvoToUiParameters: flow(function* ({ ip, name, needToConnect }) {
            const { processingStore, uiStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const evos = [...getSnapshot(uiStore.parameters).multipleEvos];
                if (evos.some((evo) => evo.ip === ip)) return;
                evos.push({ ip, name, needToConnect });
                const res = yield uiStore.patchUiParameters({
                    multipleEvos: evos,
                });
                uiStore.fetchUiParameters();
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        checkMultipleEvoAuthAndGetNewSocketToken: flow(function* (ip) {
            const { processingStore } = getParent(self);
            const currentEvo = self.multipleEvoConnections.find((evo) => evo.ip === ip);
            try {
                if (!currentEvo) return null; //TODO: handle error
                const token = self.multipleEvoLocalStorage.find((evo) => evo.ip === ip)?.authToken;
                if (!token || token === TOKEN_IS_INVALID) return null;
                currentEvo.axios.defaults.headers.common["Authorization"] = token;
                const sameVersions = yield self.compareEvoVersions(ip);
                if (!sameVersions) return null;

                const res = yield currentEvo.axios.get(WEBSOCKET_TOKEN_URL);

                const { websocket_token, user } = res.data;
                yield currentEvo.socket.start(websocket_token);

                //create store after successful socket connection
                MultipleEvosRootStore.addStore({
                    ip,
                    socket: currentEvo.socket,
                    name: currentEvo.name,
                    role: user.role || null,
                    axios: currentEvo.axios,
                });
                currentEvo.isAuthorized = true;
                self.multipleEvoConnections = [...self.multipleEvoConnections];
                self.isAutoconnectionCompleted = true;
                return true;
            } catch (e) {
                if (e?.response?.status === 401) {
                    currentEvo.isAuthorized = false;
                    self.logoutFromMultipleEvo(ip);
                }
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        compareEvoVersions: flow(function* (ip, config = { userTryingConnect: false }) {
            const { processingStore, evoSettingsStore } = getParent(self);
            const currentEvo = self.multipleEvoConnections.find((evo) => evo.ip === ip);

            try {
                if (!currentEvo) return false; //TODO: handle error
                const { data } = yield currentEvo.axios.get(SYSTEM_INFO);

                if (!data?.version) return false;

                if (data.version === evoSettingsStore.evoVersion) {
                    return true;
                } else {
                    processingStore.setError({ message: `${currentEvo.name}: ${t("login.multiple.error.versions")}` });
                    return false;
                }
            } catch (e) {
                if (!currentEvo.isAuthorized && !config.userTryingConnect) {
                    return;
                }

                if (imTeapot(e.response?.status)) {
                    processingStore.setError(t("login.multiple.error.versions"));
                } else {
                    const foundConnection = self.multipleEvoConnections.find((connection) => connection.ip === ip);
                    processingStore.setError(`${foundConnection?.name}: ${e}`);
                }

                return false;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        tryToConnectToMultipleEvos: () => {
            self.mapUiParametersEvoToLocalStorage();
            const { uiStore } = getParent(self);
            const multipleEvosWithNeedToConnect = uiStore?.parametersResult?.data?.multipleEvosWithNeedToConnect;
            if (!multipleEvosWithNeedToConnect) return;

            for (const evo of multipleEvosWithNeedToConnect) {
                self.checkMultipleEvoAuthAndGetNewSocketToken(evo.ip);
            }
        },
    }));

export default MultipleEvosStore;
