import React, { useContext, useEffect } from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { ADMIN_DISPLAY_LANGUAGE_CARD } from "const/widgetNameConst";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "pages/AdminSettings/components/DisplayLanguageCard/DisplayLanguageCard.styles";
import { useTranslation } from "react-i18next";
import { observer, useLocalStore, useObserver } from "mobx-react";
import SetDisplayLanguageDialog from "./components/SetDisplayLanguageDialog/SetDisplayLanguageDialog";
import { Context } from "store";
import { localeOptions } from "./constants/localeOptions";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const DISPLAY_LANGUAGE_CARD_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_display_language_card`;

const DisplayLanguageCard = observer(() => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        store: { uiStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isDialogOpen: false
    }));

    const openDialog = () => {
        state.isDialogOpen = true;
    };

    const closeDialog = () => {
        state.isDialogOpen = false;
    };

    useEffect(() => {
        uiStore.fetchUiSettings();
    }, []);

    const { label } = localeOptions.find(({ value }) => value === uiStore.settings?.locale) || {};

    return useObserver(() => (
        <>
            <DataReadonlyCard
                autoHeight
                denseBottom
                headerDivider
                onClick={openDialog}
                title={t("admin_settings.display_language_card.title")}
                control={
                    <MoreMenu id={`${DISPLAY_LANGUAGE_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={ADMIN_DISPLAY_LANGUAGE_CARD} />
                    </MoreMenu>
                }
            >
                <Grid className={classes.container} container direction={"column"} spacing={4} wrap={"nowrap"}>
                    <Grid item container alignItems={"center"}>
                        <Grid item xs={6}>
                            <Typography>{t("admin_settings.display_language_card.label")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldText}>{label}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DataReadonlyCard>
            <SetDisplayLanguageDialog open={state.isDialogOpen} onClose={closeDialog} />
        </>
    ));
});

export { DisplayLanguageCard };
