import React, { useCallback } from "react";

import { useLocalStore, useObserver } from "mobx-react";
import { Paper, FormControlLabel, Grid } from "@material-ui/core";
import CardHeader from "components/CardHeader";
import createForm from "utils/createForm";
import createFields from "./createFields";
import Switch from "components/MobxForm/Switch";
import { useStyles } from "./DontShowAgainCard.style";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { useStore } from "hooks/useStore";

const DontShowAgainCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields()
        })
    }));

    const {
        store: { uiStore, evoSettingsStore }
    } = useStore();

    useAsyncEffect(async () => {
        if (!uiStore.parameters) await uiStore.fetchUiParameters();
        state.form.$("redirect").set("value", uiStore.parameters?.notRedirectToTroubleshooting || false);
    }, []);

    const handleChange = useCallback(e => {
        let timestamp = null;
        if (e.target.checked) {
            timestamp = moment.unix(evoSettingsStore.evoInfoTime).add(24, "hours") / 1000;
        }
        state.form.$("redirect").set("value", e.target.checked);
        uiStore.patchUiParameters({
            notRedirectToTroubleshooting: e.target.checked,
            resetTimestamp: timestamp || null
        });
    });

    return useObserver(() => (
        <Paper>
            <CardHeader
                content={
                    <Grid container alignItems={"center"} justify={"space-between"} className={classes.contentContainer}>
                        <Grid item>
                            <FormControlLabel
                                className={classes.switchLabel}
                                control={<Switch onChange={handleChange} field={state.form.$("redirect")} />}
                                label={t("troubleshooting.redirect.label")}
                            />
                        </Grid>
                    </Grid>
                }
            />
        </Paper>
    ));
};

export default DontShowAgainCard;
