import { types } from "mobx-state-tree";

import Request from "api/Request";
import PartitionId from "api/volume/Types/PartitionId";

const ListFreeRootDirectories = Request.props({
    path: "/com/studionetworksolutions/evo/Files",
    method: "list_free_root_directories",
    arguments: types.maybe(PartitionId)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ListFreeRootDirectories;
