import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(4)
    },
    groupInput: {
        marginBottom: theme.spacing(2)
    },
    radioGroupContainer: {
        width: "100%",
        display: "flex"
    },
    radioGroup: {
        width: "100%"
    },
    radio: {
        "& label > span:first-child": {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        }
    }
}));
