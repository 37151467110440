import shouldBePasswordsEqual from "utils/shouldBePasswordsEqual";

const createFields = () => {
    return [
        {
            name: "currentLogin",
            default: ""
        },
        {
            name: "currentPassword",
            default: "",
            type: "password"
        },
        {
            name: "newLogin",
            default: ""
        },
        {
            name: "newPassword",
            default: "",
            type: "password"
        },
        {
            name: "confirmPassword",
            default: "",
            type: "password",
            validators: [shouldBePasswordsEqual("newPassword")]
        }
    ];
};

export default createFields;
