import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    textInputRow: {
        marginBottom: theme.spacing(4)
    },
    lastRow: {
        marginBottom: 0
    },
    container: {
        "&&": {
            margin: theme.spacing(0, 0, 8)
        }
    },
    input: {
        "& .MuiInputBase-input": {
            fontWeight: "400"
        }
    }
}));
