import { makeStyles } from "@material-ui/core/styles";
import { CLEAR_BLUE } from "const/styleConst";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => ({
    itemContainer: {
        padding: theme.spacing(3, 6),
        "& svg path": {
            fill: theme.palette.grey[300]
        },
        "&:hover": {
            backgroundColor: hexToRgba(CLEAR_BLUE, 0.05)
        }
    },
    labelText: {
        "&&": {
            fontSize: 16,
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 2.5,
            letterSpacing: 0.2
        }
    }
}));
