import Request from "api/Request";

const GetIdentity = Request.props({
    path: "/com/studionetworksolutions/evo/SupportInfo",
    method: "get_identity"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetIdentity;
