import { types } from "mobx-state-tree";
import ShareParamsType from "./ShareParamsType";
export const ENABLED = "ENABLED";
export const MANUAL = "MANUAL";
export const DISABLED = "DISABLED";
export const STATUS_EDIT_PROXY = [ENABLED, MANUAL, DISABLED];

const ConfigType = types.model("ConfigType", {
    // TODO: add properties from REST api as needed
    editProxy: types.maybe(
        types.model({
            shares: types.map(types.enumeration(STATUS_EDIT_PROXY)),
            blackList: types.map(types.maybe(types.array(types.string))),
            shareParams: types.maybe(types.map(ShareParamsType)),
            enabledDefault: types.maybe(types.boolean),
        })
    ),
    mediaProxy: types.maybe(
        types.model({
            adHocRequestWithoutPause: types.maybe(types.boolean),
            shares: types.map(types.boolean),
            destinationPath: types.maybe(types.string),
            shareParams: types.maybe(types.map(ShareParamsType)),
            blackList: types.map(types.maybe(types.array(types.string))),
            enabledDefault: types.maybe(types.boolean),
            extensionBlackList: types.maybe(types.array(types.string)),
        })
    ),
    indexerName: types.maybe(types.string),
    postmap: types.maybe(
        types.model({
            hierarchical: types.maybe(types.boolean),
            host: types.maybe(types.string),
            https: types.maybe(types.boolean),
            path: types.maybe(types.string),
            port: types.maybe(types.number),
            sessionTimeout: types.maybe(types.number),
            timeout: types.maybe(types.number),
        })
    ),
    slingshot: types.maybe(
        types.model({
            pauseDefaultSchedule: types.maybe(
                types.model({
                    date: types.string,
                    disabled: types.boolean,
                    duration: types.number,
                    minutes: types.number,
                })
            ),
        })
    ),
    otfDefault: types.maybe(types.boolean),
    folderBlackList: types.map(types.maybe(types.array(types.string))),
});
export default ConfigType;
