import { types } from "mobx-state-tree";

import Request from "api/Request";
import StartQueueArguments from "api/volume/Types/StartQueueArguments";

const StartQueue = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Volume",
    method: "start_queue",
    arguments: types.maybe(StartQueueArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default StartQueue;
