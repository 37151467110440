import React, { useEffect } from "react";
import { useStore } from "hooks/useStore";
import { useObserver, useLocalStore } from "mobx-react";
import { ButtonBase, Table, TableBody, TableCell, TableHead, TableRow, Grid, CircularProgress, Box } from "@material-ui/core";
import ChevronRightIcon from "assets/ChevronRightIcon";
import PlusIcon from "assets/PlusIcon";
import TableRowControlButton from "components/TableRowControlButton";
import TableSortLabel from "components/TableSortLabel";
import { useStyles } from "./LocalBricksCard.styles";
import { useTranslation } from "react-i18next";
import DataCard from "components/DataCard/DataCard";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import {
    CLUSTER_LOCAL_BRICK_PATH,
    CLUSTER_LOCAL_BRICK_NAME,
    CLUSTER_LOCAL_BRICK_USED,
    CLUSTER_LOCAL_BRICK_VOLUME,
    CLUSTER_LOCAL_BRICK_STATUS
} from "const/sortColumnConst";
import { LOCAL_BRICKS_CARD } from "const/widgetNameConst";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import AddBrickDialog from "../AddBrickDialog";
import { useModal } from "hooks/useModal";
import { GB_METRIC, GiB_IEC } from "const/diskSizeConst";
import StatusLabel from "./components/StatusLabel";
import clsx from "clsx";
import config from "config";
import BricksDrawer from "../BricksDrawer/BricksDrawer";

const LocalBricksCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { clusterStore }
    } = useStore();
    const state = useLocalStore(() => ({
        isLoading: false
    }));

    useEffect(() => {
        (async () => {
            state.isLoading = true;
            await clusterStore.fetchPeers();
            await clusterStore.fetchBricks();
            clusterStore.fetchBricksPaths();
            state.isLoading = false;
        })();
    }, []);

    const addBrickModal = useModal();
    const decimalSizeUnits = config.decimalSizeUnits ? GB_METRIC.value : GiB_IEC.value;

    const changeSorting = column => () => clusterStore.changeSortingBricks(column);

    const getUsedIcon = value => {
        return (
            <Box ml={2} className={classes.circularIconContainer}>
                <CircularProgress
                    variant="determinate"
                    className={classes.circularIconBackground}
                    size={20}
                    thickness={8}
                    value={100}
                />
                <CircularProgress
                    variant="determinate"
                    className={clsx(classes.circularIcon, value > 50 && classes.circularIconFull)}
                    size={20}
                    thickness={8}
                    value={value}
                />
            </Box>
        );
    };

    const addBrick = () => {
        if (clusterStore.bricksPaths.length > 0) {
            addBrickModal.open();
            return;
        }
    };

    const onRowClick = brickId => () => {
        clusterStore.setCurrentBrickId(clusterStore.currentBrickId === brickId ? null : brickId);
    };

    return useObserver(() => (
        <DataCard
            emptyPadding
            title={t("cluster.local_bricks.title")}
            headerDivider
            headerControl={[
                <MoreMenu key={`data_card-header_control-control-1`}>
                    <HomeCardActionMenuItem widget={LOCAL_BRICKS_CARD} />
                </MoreMenu>,
                <ButtonBase
                    key={`data_card-header_control-control-2`}
                    disabled={!clusterStore.bricksPaths.length}
                    onClick={addBrick}
                >
                    <PlusIcon />
                </ButtonBase>
            ]}
        >
            <BricksDrawer />
            <AddBrickDialog onClose={addBrickModal.close} open={addBrickModal.isOpen} />
            {clusterStore.sortedBricks?.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(CLUSTER_LOCAL_BRICK_PATH)}
                                    direction={clusterStore.order}
                                    active={clusterStore.bricksOrderBy === CLUSTER_LOCAL_BRICK_PATH}
                                >
                                    {t("cluster.local_bricks.header.cluster_path_on_evo")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(CLUSTER_LOCAL_BRICK_NAME)}
                                    direction={clusterStore.order}
                                    active={clusterStore.bricksOrderBy === CLUSTER_LOCAL_BRICK_NAME}
                                >
                                    {t("cluster.local_bricks.header.brick_name")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(CLUSTER_LOCAL_BRICK_USED)}
                                    direction={clusterStore.order}
                                    active={clusterStore.bricksOrderBy === CLUSTER_LOCAL_BRICK_USED}
                                >
                                    {t("cluster.local_bricks.header.used")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(CLUSTER_LOCAL_BRICK_VOLUME)}
                                    direction={clusterStore.order}
                                    active={clusterStore.bricksOrderBy === CLUSTER_LOCAL_BRICK_VOLUME}
                                >
                                    {t("cluster.local_bricks.header.volume")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(CLUSTER_LOCAL_BRICK_STATUS)}
                                    direction={clusterStore.order}
                                    active={clusterStore.bricksOrderBy === CLUSTER_LOCAL_BRICK_STATUS}
                                >
                                    {t("cluster.local_bricks.header.status")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clusterStore.sortedBricks.map(brick => (
                            <TableRow key={`localBrick-${brick.id}`} onClick={onRowClick(brick.id)}>
                                <TableCell>{brick.path}</TableCell>
                                <TableCell>{brick.name}</TableCell>
                                <TableCell>
                                    <Grid container wrap="nowrap">
                                        {(brick.size?.used / decimalSizeUnits).toFixed(2)}
                                        {" / "}
                                        {(brick.size?.total / decimalSizeUnits).toFixed(2)}
                                        {getUsedIcon(Math.round((brick.size?.used / brick.size?.total) * 100))}
                                    </Grid>
                                </TableCell>
                                <TableCell>{brick.volume || "-"}</TableCell>
                                <TableCell>
                                    <StatusLabel status={brick.status} />
                                </TableCell>
                                <TableCell align={"right"}>
                                    <TableRowControlButton icon={<ChevronRightIcon />} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyCardPlaceholder inProgress={state.isLoading} message={t("cluster.local_bricks.table.placeholder")} />
            )}
        </DataCard>
    ));
};

export default LocalBricksCard;
