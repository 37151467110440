import { types } from "mobx-state-tree";

import Request from "api/Request";
import TrashSettings from "api/trash/Types/TrashSettings";

const SetTrashSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Trash",
    method: "set_trash_settings",
    arguments: types.maybe(TrashSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetTrashSettings;
