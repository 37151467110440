import { types } from "mobx-state-tree";

const NetInterfaceActivation = types.model({
    port: types.string,
    mac: types.string,
    type: types.string,
    activated: types.boolean,
    exists: types.boolean,
    maxSpeedGbs: types.number
});

export default NetInterfaceActivation;
