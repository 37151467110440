import React from "react";

const SpeedIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill="#1E90FF" fillRule="nonzero">
                    <g>
                        <path
                            d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm1.001 2.062L13 5c0 .552-.448 1-1 1s-1-.448-1-1v-.938c-1.584.197-3.024.858-4.181 1.842l.242.242c.39.391.39 1.024 0 1.415-.39.39-1.024.39-1.415 0l-.179-.18C4.725 8.43 4.23 9.663 4.062 11H5c.552 0 1 .448 1 1s-.448 1-1 1l-.938.001C4.555 16.947 7.921 20 12 20c4.08 0 7.445-3.053 7.938-6.999L19 13c-.552 0-1-.448-1-1s.448-1 1-1h.938c-.167-1.337-.663-2.571-1.405-3.619l-.18.18c-.39.39-1.023.39-1.414 0-.39-.39-.39-1.024 0-1.415l.242-.242c-1.156-.984-2.596-1.644-4.18-1.842zm1.444 3.014c.459.228.666.762.497 1.233l-.046.107-1.23 2.478c.211.316.334.697.334 1.106 0 1.105-.895 2-2 2s-2-.895-2-2 .895-2 2-2l-.126.004 1.23-2.477c.246-.495.846-.697 1.34-.451z"
                            transform="translate(-500 -256) translate(500 256)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default SpeedIcon;
