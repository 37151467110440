import React from "react";
import { FormHelperText, RadioGroup as RadioGroupBase } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";

const RadioGroup = ({ helperText, error, className, ...rest }) => {
    return (
        <FormControl error={error} className={clsx(className)}>
            <RadioGroupBase {...rest} />
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

export default RadioGroup;
