import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        margin: "auto",
        padding: theme.spacing(20, 6),
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        height: "100%",
    },
    row: {
        height: 450,
    },
}));
