import { types } from "mobx-state-tree";

import Request from "api/Request";
import RepairPoolArguments from "api/drive_replace/Types/RepairPoolArguments";

const RepairPool = Request.props({
    path: "/com/studionetworksolutions/evo/manage/DriveReplace",
    method: "repair_pool",
    arguments: types.maybe(RepairPoolArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default RepairPool;
