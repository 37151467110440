import React from "react";

const VolumeIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="k1avskl4mb" x1="48.697%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A770FF" />
                    <stop offset="100%" stopColor="#85ADFF" />
                </linearGradient>
                <linearGradient id="pc9sv0ejca" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#E19EFF" stopOpacity="0.771" />
                    <stop offset="100%" stopColor="#293CFF" stopOpacity="0.6" />
                </linearGradient>
                <linearGradient id="cofrv3n4xc" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <rect width="7" height="17" x="3.5" y="3.5" fill="url(#k1avskl4mb)" stroke="url(#pc9sv0ejca)" rx="2" />
                <rect width="7" height="13" x="13.5" y="7.5" fill="#85C3FF" stroke="url(#cofrv3n4xc)" rx="2" />
            </g>
        </svg>
    );
};

export default VolumeIcon;
