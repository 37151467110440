import React from "react";

const BookIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                d="M20.62 4.22a1 1 0 00-.84-.2L12 5.77 4.22 4A1 1 0 003 5v12.2a1 1 0 00.78 1l8 1.8h.44l8-1.8a1 1 0 00.78-1V5a.999.999 0 00-.38-.78zM5 6.25l6 1.35v10.15L5 16.4V6.25zM19 16.4l-6 1.35V7.6l6-1.35V16.4z"
            ></path>
        </svg>
    );
};

export default BookIcon;
