import React, { useEffect, useRef } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import * as d3 from "d3";
import { withFauxDOM } from "react-faux-dom";

import { timeRegExp, DEFAULT_START_PERIOD, TICK_X_COUNT, TICK_Y_COUNT } from "const/chartConst";
import { useStyles } from "./CPUGraph.style";

const marginLeft = 30;
const marginRight = 40;
const marginTop = 10;
const marginBottom = 20;
const textMargin = 12;
const textHeight = 18;

const CPUGraph = ({
    parentId = "cpuGraph",
    height = 150,
    animDuration = 300,
    chartInfo,
    startFetching,
    stopFetching,
    lastTime,
    lastValue,
    connectFauxDOM,
    animateFauxDOM,
    isDark,
    graphStartPeriod = DEFAULT_START_PERIOD,
    graphAutoscaling,
    ...props
}) => {
    const parenfRef = useRef();
    const classes = useStyles({ isDark });
    const { t } = useTranslation();
    // This allows to find the closest X index of the mouse
    const bisect = d3.bisector(d => d.date);
    const max = d3.max(chartInfo, i => i.value);
    const maxYbound = graphAutoscaling ? max + parseFloat((max * 0.2).toFixed(2)) : 100;
    const chartId = `chart-${parentId}`;
    const ticksX = d3.timeMinute.every(Math.round(graphStartPeriod / 60 / TICK_X_COUNT));
    const state = useLocalStore(() => ({
        svg: null,
        svgCoorditates: { x: 0, y: 0 },
        xScale: null,
        yScale: null,
        xaxis: null,
        yaxis: null,
        line: null,
        point: null,
        selectedTime: "",
        selectedValue: null,
        isChartCreated: false,
        setSelectedTime(time) {
            this.selectedTime = time;
        },
        setSelectedValue(value) {
            this.selectedValue = value;
        }
    }));

    useEffect(() => {
        state.isChartCreated ? updateChart() : createChart();
    }, [chartInfo]);

    function mouseover() {
        state.point.style("display", null);
        state.svgCoorditates = parenfRef.current.getBoundingClientRect();
        stopFetching && stopFetching(true);
    }

    function mousemove(event) {
        if (chartInfo.length === 0) return;

        const xCoord = event.x - state.svgCoorditates.x;
        const x0 = state.xScale.invert(xCoord);
        const i = bisect.left(chartInfo, x0);
        const selectedData = chartInfo[i] ? chartInfo[i] : chartInfo[i - 1];

        state.point.attr("transform", `translate(${state.xScale(selectedData.date)}, ${state.yScale(selectedData.value)})`);
        animateFauxDOM(animDuration);

        state.setSelectedTime(d3.timeFormat(timeRegExp)(selectedData.date));
        state.setSelectedValue(selectedData.value);
    }

    function mouseout() {
        state.point.style("display", "none");
        state.setSelectedTime("");
        state.setSelectedValue(null);
        startFetching && startFetching(true);
    }

    function createChart() {
        state.isChartCreated = true;
        const faux = connectFauxDOM("g", chartId);
        const wrapper = d3.select(`#${parentId}`);

        wrapper.select("svg").attr("width", parenfRef.current.offsetWidth);
        state.svg = d3.select(faux);
        state.xScale = d3
            .scaleTime()
            .domain(d3.extent(chartInfo, d => d.date))
            .range([marginLeft, parenfRef.current.offsetWidth - marginRight]);
        state.yScale = d3
            .scaleLinear()
            .domain([0, maxYbound])
            .range([height - marginBottom, marginTop]);
        state.xaxis = d3
            .axisTop()
            .scale(state.xScale)
            .tickFormat(d3.timeFormat(timeRegExp))
            .tickSize(height - marginTop - marginBottom)
            .ticks(ticksX);
        state.yaxis = d3
            .axisLeft()
            .scale(state.yScale)
            .tickSize(parenfRef.current.offsetWidth - marginLeft - marginRight);
        graphAutoscaling ? state.yaxis.ticks(TICK_Y_COUNT) : state.yaxis.tickValues([0, 25, 50, 75, 100]);
        state.line = d3
            .line()
            .x(d => state.xScale(d.date))
            .y(d => state.yScale(d.value));
        state.point = state.svg
            .append("g")
            .append("circle")
            .attr("r", 3)
            .attr("class", "point")
            .style("display", "none");

        state.svg
            .append("g")
            .attr("transform", `translate(0, ${height - marginBottom})`)
            .attr("class", "x axis")
            .call(state.xaxis)
            .call(g =>
                g.selectAll(".tick text").attr("transform", `translate(0, ${height - marginTop - marginBottom + textHeight})`)
            );
        state.svg
            .append("g")
            .attr("class", "y axis")
            .attr("transform", `translate(${parenfRef.current.offsetWidth - marginRight}, 0)`)
            .call(state.yaxis)
            .call(g =>
                g
                    .selectAll(".tick text")
                    .attr("transform", `translate(${parenfRef.current.offsetWidth - marginRight - marginLeft + textMargin}, 0)`)
            );
        state.svg
            .append("g")
            .append("path")
            .attr("d", state.line(chartInfo))
            .attr("class", "graph");
        state.svg
            .append("rect")
            .style("fill", "none")
            .style("pointer-events", "all")
            .attr("class", "rect")
            .attr("width", parenfRef.current.offsetWidth - marginRight - marginLeft)
            .attr("height", height - marginBottom - marginTop)
            .attr("transform", `translate(${marginRight}, ${marginTop})`)
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseout", mouseout);
    }

    function updateChart() {
        const wrapper = d3.select(`#${parentId}`);

        wrapper.select("svg").attr("width", parenfRef.current.offsetWidth);
        state.svg.attr("width", parenfRef.current.offsetWidth);
        state.xScale.domain(d3.extent(chartInfo, d => d.date)).range([marginLeft, parenfRef.current.offsetWidth - marginRight]);
        state.yScale.domain([0, maxYbound]);
        state.svg
            .select(".x.axis")
            .call(state.xaxis)
            .call(g =>
                g.selectAll(".tick text").attr("transform", `translate(0, ${height - marginTop - marginBottom + textHeight})`)
            );
        state.svg
            .select(".y.axis")
            .call(state.yaxis)
            .call(g =>
                g
                    .selectAll(".tick text")
                    .attr("transform", `translate(${parenfRef.current.offsetWidth - marginRight - marginLeft + textMargin}, 0)`)
            );
        state.svg.select(".point").style("display", "none");
        state.svg
            .select(".rect")
            .attr("width", parenfRef.current.offsetWidth - marginRight - marginLeft)
            .on("mousemove", mousemove);
        state.svg
            .select(".graph")
            .transition()
            .duration(animDuration)
            .attr("d", state.line(chartInfo));

        animateFauxDOM(animDuration);
    }

    return useObserver(() => {
        const val = state.selectedValue !== null ? state.selectedValue : lastValue;

        return (
            <Grid container className={classes.container}>
                <Grid container item xs={2} direction="column" justify="space-between" className={classes.info}>
                    <Grid item>
                        <Typography className={classes.name}>{t("cpu_graph.title")}</Typography>
                        <Typography className={classes.label}>{t("cpu_graph.load")}</Typography>
                        <Typography className={classes.value}>{val}%</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.label}>{state.selectedTime || lastTime}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={10} id={parentId} ref={parenfRef}>
                    <svg height={height} className={classes.svg}>
                        {props[chartId]}
                    </svg>
                </Grid>
            </Grid>
        );
    });
};

export default withFauxDOM(CPUGraph);
