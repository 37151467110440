import { types } from "mobx-state-tree";

import GetServicesResultData from "api/cluster/Types/GetServicesResultData";

const GetServicesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(GetServicesResultData)
});

export default GetServicesResult;
