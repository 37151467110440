import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, List } from "@material-ui/core";
import ListItem from "components/ListItem";
import DataCard from "components/DataCard";

import { useStyles } from "./LimitationCard.styles";

const LimitationCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <DataCard title={t("audit.limitations.title")} autoHeight headerDivider>
            <Grid container spacing={1}>
                <Grid item container>
                    <Typography variant={"h6"}>{t("audit.limitation.intro")}</Typography>
                </Grid>
                <Grid item container>
                    <List className={classes.list}>
                        <ListItem>{t("audit.limitation.first_item")}</ListItem>
                        <ListItem>{t("audit.limitation.second_item")}</ListItem>
                        <ListItem>{t("audit.limitation.third_item")}</ListItem>
                        <ListItem>{t("audit.limitation.fourth_item")}</ListItem>
                        <ListItem>{t("audit.limitation.fifth_item")}</ListItem>
                        <ListItem>{t("audit.limitation.sixth_item")}</ListItem>
                        <ListItem>{t("audit.limitation.seventh_item")}</ListItem>
                    </List>
                </Grid>
            </Grid>
        </DataCard>
    );
};

export default LimitationCard;
