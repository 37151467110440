import { types } from "mobx-state-tree";

import Request from "api/Request";
import WebServerConfig from "api/webserver/Types/WebServerConfig";

const SetConfig = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Webserver",
    method: "set_config",
    arguments: types.maybe(WebServerConfig)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetConfig;
