import React from "react";
import { useObserver } from "mobx-react";
import { TableRow as MuiRow, TableCell, Box } from "@material-ui/core";
import StatusLabel from "../StatusLabel";
import TableRowControlButton from "components/TableRowControlButton/TableRowControlButton";
import ChevronRightIcon from "assets/ChevronRightIcon";
import { useStyles } from "./TableRow.styles";
import { KB_METRIC } from "const/diskSizeConst";
import { SLINGSHOT_DATE_FULL_HOURS_FORMAT } from "const";
import moment from "moment";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const TABLE_ROW_ID_PREFIX = `table_row`;

const TableRow = ({ file, onClick, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${TABLE_ROW_ID_PREFIX}` : TABLE_ROW_ID_PREFIX;
    const classes = useStyles();

    return useObserver(() => (
        <MuiRow onClick={onClick} id={`${idPrefix}_open`}>
            <TableCell>{file.id}</TableCell>
            <TableCell>
                <StatusLabel status={file.event?.result || file.result} />
            </TableCell>
            <TableCell>{file.task_results?.length || ""}</TableCell>
            <TableCell className={classes.fileName}>{file.event?.source || file.event?.destination || ""}</TableCell>
            <TableCell>{(file.event?.file_size || 0) / KB_METRIC.value}</TableCell>
            <TableCell>
                <Box whiteSpace="nowrap">
                    {file.event &&
                        moment.utc(file.event.processed).format(getLanguageDateFormat(SLINGSHOT_DATE_FULL_HOURS_FORMAT))}
                </Box>
            </TableCell>

            <TableCell>
                <TableRowControlButton icon={<ChevronRightIcon />} />
            </TableCell>
        </MuiRow>
    ));
};

export default TableRow;
