import React from "react";
import { Button, Table, TableCell, TableHead, TableRow, TableBody } from "@material-ui/core";
import TableCellWithIcon from "components/TableCellWithIcon";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./GroupMembersModal.styles";
import ExternalGroupIcon from "assets/ExternalGroupIcon";
import LocalGroupIcon from "assets/LocalGroupIcon";
import LocalUserIcon from "assets/LocalUserIcon";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import Dialog from "components/Dialog";
import { USERS_LIST_GROUP_MEMBERS_NAME, USERS_LIST_GROUP_MEMBERS_TYPE_LABEL } from "const/sortColumnConst";
import TableSortLabel from "components/TableSortLabel";
import { useStore } from "hooks/useStore";

const GROUP_MEMBERS_MODAL_ID_PREFIX = "group_members_modal";

const GroupMembersModal = ({ open, onClose, parentId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const idPrefix = parentId ? `${parentId}_${GROUP_MEMBERS_MODAL_ID_PREFIX}` : GROUP_MEMBERS_MODAL_ID_PREFIX;
    const {
        store: { userStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isSearchInProgress: false,
    }));

    const listMembers = async () => {
        state.isSearchInProgress = true;
        await userStore.listGroupMembers();
        state.isSearchInProgress = false;
    };

    const getType = (isExternal, isGroup) => {
        const getIcon = () => {
            if (isGroup) {
                return isExternal ? <ExternalGroupIcon /> : <LocalGroupIcon />;
            } else {
                return <LocalUserIcon />;
            }
        };
        const getLabel = () => {
            if (isGroup) {
                return isExternal ? t("users.common.external_group") : t("users.common.local_group");
            } else {
                return isExternal ? t("users.common.external_user") : t("users.common.local_user");
            }
        };

        return {
            typeIcon: getIcon(),
            typeLabel: getLabel(),
        };
    };

    const getName = () => {
        return userStore.currentEntity.isExternal
            ? `${userStore.currentEntity.id.name}@${userStore.currentEntity.id.domain}`
            : userStore.currentEntity.id.name;
    };

    const onKeyDown = (e) => {
        e.key === "Escape" && onClose();
    };

    const changeSorting = (column) => () => userStore.groupMembersChangeSorting(column);

    return useObserver(() => (
        <Dialog
            onKeyDown={onKeyDown}
            withoutPaddings
            open={open}
            onClose={onClose}
            onEnter={listMembers}
            title={t("users.side_menu.group_members_modal.title", { name: getName() })}
            submitBtn={
                <Button variant={"contained"} color={"primary"} onClick={onClose} id={`${idPrefix}_close`}>
                    {t("common.button.close")}
                </Button>
            }
        >
            {userStore.sortedGroupMembers.length > 0 ? (
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={userStore.groupMembersOrderBy === USERS_LIST_GROUP_MEMBERS_NAME}
                                    onClick={changeSorting(USERS_LIST_GROUP_MEMBERS_NAME)}
                                    direction={userStore.groupMembersOrder}
                                >
                                    {t("users.search_results.table.column.name")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={userStore.groupMembersOrderBy === USERS_LIST_GROUP_MEMBERS_TYPE_LABEL}
                                    onClick={changeSorting(USERS_LIST_GROUP_MEMBERS_TYPE_LABEL)}
                                    direction={userStore.groupMembersOrder}
                                >
                                    {t("users.search_results.table.column.type")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userStore.sortedGroupMembers.map((entity) => {
                            const { typeIcon, typeLabel } = getType(entity.isExternal, entity.isGroup);
                            const entityName = entity.isExternal ? `${entity.id.name}@${entity.id.domain}` : entity.id.name;

                            return (
                                <TableRow key={entity.id.name + entity.id.domain}>
                                    <TableCell className={classes.tableCell}>{entityName}</TableCell>
                                    <TableCellWithIcon className={classes.tableCell} align="left" icon={typeIcon}>
                                        {typeLabel}
                                    </TableCellWithIcon>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <EmptyCardPlaceholder
                    inProgress={state.isSearchInProgress}
                    message={t("users.side_menu.group_members_modal.placeholder")}
                />
            )}
        </Dialog>
    ));
};

export default GroupMembersModal;
