import React from "react";

const UnknownDiskIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40" {...props}>
            <defs>
                <linearGradient id="d1hi6puqaa" x1=".967%" x2="73.971%" y1="-15.973%" y2="100%">
                    <stop offset="0%" stopColor="#D4E2F1"></stop>
                    <stop offset="100%" stopColor="#A9B7D4" stopOpacity="0.91"></stop>
                </linearGradient>
                <linearGradient id="j5obwkrj5b" x1="50%" x2="50%" y1="-12.918%" y2="139.27%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.4"></stop>
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0"></stop>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <rect width="28" height="36" x="2" y="2" fill="url(#d1hi6puqaa)" rx="4"></rect>
                <path fill="#A9B7D4" d="M6 4h20a2 2 0 012 2v22H4V6a2 2 0 012-2z"></path>
                <path fill="#FFF" d="M12.5 32v1h-3v-1h3zm5 0v1h-3v-1h3zm5 0v1h-3v-1h3z"></path>
                <path
                    fill="url(#j5obwkrj5b)"
                    fillRule="nonzero"
                    d="M16.177 5.8c3.882 0 7.077 3.037 7.077 6.85 0 2.213-.737 3.645-2.573 5.326l-.235.211-.832.726-.079.077a.25.25 0 00-.027.031l-.038.052a.219.219 0 00-.018.046l-.005.046v.058c0 .71-.227 1.368-.612 1.904a3.27 3.27 0 11-5.315-.001 3.24 3.24 0 01-.612-1.903c0-1.39.285-2.475.94-3.503l.166-.247-.096.056c-.342.184-.72.309-1.12.362l-.243.022-.186.005A3.269 3.269 0 019.1 12.649c0-3.812 3.195-6.849 7.077-6.849zm0 15.962c-.394 0-.747.18-.98.461l-.073.098a1.271 1.271 0 00.924 1.972l.129.007.13-.007a1.27 1.27 0 00-.13-2.532zm0-13.962c-2.787 0-5.077 2.153-5.077 4.85 0 .656.5 1.197 1.14 1.262l.13.006.129-.006a1.27 1.27 0 001.133-1.133l.006-.13.005-.134c.073-1.148 1.077-2.095 2.364-2.171l.17-.006.169.005c1.338.08 2.37 1.098 2.37 2.306 0 .731-.205 1.144-.95 1.844l-.168.154-.543.476c-1.533 1.355-2.147 2.317-2.147 4.1a1.27 1.27 0 102.539 0c0-.86.266-1.294 1.277-2.187l.408-.356c1.516-1.34 2.122-2.283 2.122-4.03 0-2.697-2.29-4.85-5.077-4.85z"
                ></path>
            </g>
        </svg>
    );
};

export default UnknownDiskIcon;
