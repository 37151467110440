import { types } from "mobx-state-tree";

import Request from "api/Request";
import PoolDrivesArguments from "api/pool/Types/PoolDrivesArguments";

const AddPool = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Pool",
    method: "add_pool",
    arguments: types.maybe(PoolDrivesArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default AddPool;
