import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import createFields from "./createFields";
import { useLocalStore } from "mobx-react";
import createForm from "utils/createForm";
import { Box, Button, ButtonBase, Grid, Typography } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import { useObserver } from "mobx-react-lite";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import FormControlLabel from "components/FormControlLabel/FormControlLabel";
import Switch from "components/MobxForm/Switch";
import { useStyles } from "./CreateAutomationModal.styles";
import Select from "components/MobxForm/Select";
import {
    FREQUENCY_OPTIONS,
    RADIO_VALUE_EXTENSION,
    RADIO_VALUE_REGEXP,
    MIN_DEPTH,
    getCreateAutomationEmailTask,
} from "const/createAutomationsConst";
import CalendarIcon from "assets/CalendarIcon";
import MuiTextInput from "components/TextInput";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import RadioGroup from "components/MobxForm/RadioGroup";
import Radio from "components/Radio/Radio";
import { SLINGSHOT_DATE_FORMAT_FOR_SERVER } from "const/createJobConst";
import FolderBrowser from "../FolderBrowser";
import automationRule from "utils/automationRule";
import { URL_ALERTS } from "routes";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";
import { SLINGSHOT_DATE_HOURS_FORMAT } from "const";
import { ADMIN } from "const/userRolesConst";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const CREATE_AUTOMATION_MODAL_ID_PREFIX = `create_automation_modal`;

const CreateAutomationModal = ({ onClose, open, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${CREATE_AUTOMATION_MODAL_ID_PREFIX}` : CREATE_AUTOMATION_MODAL_ID_PREFIX;
    const { t } = useTranslation();
    const classes = useStyles();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
            rules: automationRule,
        }),
        isSubmitted: false,
        isPickerOpen: false,
        path: "",
    }));

    const {
        store: { slingshotStorageStore, automationsStore, authStore, emailConfigurationStore },
    } = useStore();

    const { unsavedChangesModal, folderBrowser } = useModalCombine(["unsavedChangesModal", "folderBrowser"]);

    useEffect(() => {
        slingshotStorageStore.fetchShares();
    }, []);

    const setInitValues = () => {
        const initDate = moment().add(1, "hour").minute(0);
        state.form.$("name").set("default", "");
        state.form.$("recipient").set("default", "");
        state.form.$("share").set("default", "");
        state.form.$("folder").set("default", "");
        state.form.$("notify").set("default", false);
        state.form.$("scheduled").set("default", true);
        state.form.$("frequencyUnit").set("default", FREQUENCY_OPTIONS[1].value || "");
        state.form.$("frequency").set("default", 1);
        state.form.$("startAt").set("default", initDate);
        state.form.$("query").set("default", RADIO_VALUE_EXTENSION);
        state.form.reset();
        state.isSubmitted = false;
        unsavedChangesModal.close();
        state.form.fields.forEach((field) => field.resetValidation());
    };

    const onSave = async (e) => {
        state.form.onSubmit(e);
        if (state.form.$("query").value === RADIO_VALUE_EXTENSION) {
            state.form.$("regularExpression").resetValidation();
        }
        if (state.form.$("query").value === RADIO_VALUE_REGEXP) {
            state.form.$("fileExtension").resetValidation();
        }
        if (!state.form.isValid) return;

        const encodedFilterURL = [state.form.$("share").value, encodeURIComponent(state.form.$("folder").value)].join("/");
        const automation = {
            id: null,
            name: state.form.$("name").value,
            internal: false,
            enabled: true,
            deleted: false,
            paused: false,
            otf: false,
        };

        if (state.form.$("scheduled").value) {
            let minutes;
            switch (state.form.$("frequencyUnit").value) {
                case FREQUENCY_OPTIONS[2].value: {
                    minutes = parseInt(state.form.$("frequency").value) * 60 * 24; //day
                    break;
                }
                case FREQUENCY_OPTIONS[1].value: {
                    minutes = parseInt(state.form.$("frequency").value) * 60; //hour
                    break;
                }
                case FREQUENCY_OPTIONS[0].value: {
                    minutes = parseInt(state.form.$("frequency").value);
                    break;
                }
                default:
                    minutes = 0;
            }

            automation.schedule = {
                id: null,
                schedule: {
                    minutes: minutes,
                    date: moment(state.form.$("startAt").value).format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                },
            };
        } else {
            automation.schedule = null;
        }

        /* Fill filters */
        const filter = {
            id: null,
            rules: [],
        };

        if (state.form.$("query").value === RADIO_VALUE_EXTENSION) {
            const extensionsArr = state.form.$("fileExtension").value.trim().split(/[ ,]+/);
            for (let i = 0; i < extensionsArr.length; i++) {
                const rule = {
                    file_type: "F",
                    regexp: false,
                    case_sensitive: false,
                    name_mask: extensionsArr[i],
                    black: false,
                    id: null,
                    depth: MIN_DEPTH,
                    priority: 0,
                    url: encodedFilterURL,
                    watch_mask: 0x00000fcc,
                };
                filter.rules.push(rule);
            }
        } else {
            const rule = {
                file_type: "F",
                regexp: true,
                case_sensitive: false,
                name_mask: state.form.$("regularExpression").value,
                black: false,
                id: null,
                depth: MIN_DEPTH,
                priority: 0,
                url: encodedFilterURL,
                watch_mask: 0x00000fcc,
            };
            filter.rules.push(rule);
        }

        automation.filter = filter;
        if (state.form.$("notify").value) {
            automation.tasks = [getCreateAutomationEmailTask(state.form.$("recipient").value)];
        }

        const res = await automationsStore.createAutomation(automation);
        res && (state.isSubmitted = true);
        return !!res;
    };

    const openFolderBrowser = () => {
        folderBrowser.open();
        state.path = state.form.$("folder").value;
    };

    const onFolderChoose = (value) => {
        state.form.$("folder").set("value", value);
    };

    const openPicker = () => {
        state.isPickerOpen = true;
    };
    const closePicker = () => {
        state.isPickerOpen = false;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            maxWidth="md"
            title={t("slingshot.automations.create_automation.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            open={open}
            onSubmit={onSave}
            onEnter={setInitValues}
            onClose={closeIfNeeded}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
            hide={folderBrowser.isOpen}
        >
            <Grid container direction={"column"} spacing={2}>
                <RowTextInput
                    leftXs={4}
                    rightXs={8}
                    label={t("slingshot.automations.create_automation.label.name")}
                    control={<TextInput field={state.form.$("name")} id={`${idPrefix}_name`} />}
                />
                <RowTextInput
                    leftXs={4}
                    rightXs={8}
                    label={t("slingshot.automations.create_automation.label.recipients")}
                    control={<TextInput field={state.form.$("recipient")} id={`${idPrefix}_recipient`} />}
                />
                <Grid item container justify="flex-end">
                    <Grid item container direction="column" xs={8}>
                        <Grid item>
                            <Box pt={2} pb={4}>
                                <FormControlLabel
                                    className={classes.formControlLabel}
                                    label={t("slingshot.automations.create_automation.label.notify")}
                                    control={
                                        <Switch
                                            disabled={
                                                emailConfigurationStore.emailSettings?.emailsTo &&
                                                emailConfigurationStore.emailSettings?.emailsTo[0] === ""
                                            }
                                            className={classes.switchControlLabel}
                                            field={state.form.$("notify")}
                                            id={`${idPrefix}_notify`}
                                        />
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid container item>
                            <Grid item>
                                {emailConfigurationStore.emailSettings?.emailsTo &&
                                emailConfigurationStore.emailSettings?.emailsTo[0] !== "" ? (
                                    authStore.role === ADMIN && (
                                        <Typography>
                                            <Trans
                                                i18nKey={"slingshot.automations.create_automation.label.configure_email.enabled"}
                                                components={<Link className={classes.link} to={URL_ALERTS} />}
                                            />
                                        </Typography>
                                    )
                                ) : authStore.role === ADMIN ? (
                                    <Typography>
                                        <Trans
                                            i18nKey={
                                                "slingshot.automations.create_automation.label.configure_email.disabled.admin"
                                            }
                                            components={<Link className={classes.link} to={URL_ALERTS} />}
                                        />
                                    </Typography>
                                ) : (
                                    <Typography>
                                        {t("slingshot.automations.create_automation.label.configure_email.disabled")}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <RowTextInput
                    leftXs={4}
                    rightXs={8}
                    label={t("slingshot.automations.create_automation.label.share")}
                    control={
                        <Select
                            field={state.form.$("share")}
                            options={slingshotStorageStore.shares.map((share) => share.name)}
                            id={`${idPrefix}_share`}
                        />
                    }
                />
                <Grid item container wrap="nowrap">
                    <Grid item xs={4}>
                        <Typography>{t("slingshot.automations.create_automation.label.folder")}</Typography>
                    </Grid>
                    <Grid item container xs={8} spacing={4} wrap="nowrap">
                        <Grid item container>
                            <TextInput field={state.form.$("folder")} id={`${idPrefix}_folder`} />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!state.form.$("share").value}
                                onClick={openFolderBrowser}
                                id={`${idPrefix}_open_folder_browser`}
                            >
                                {t("common.button.browse")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container wrap="nowrap">
                    <Grid item xs={4}>
                        <Typography>{t("slingshot.automations.create_automation.label.launch")}</Typography>
                    </Grid>
                    <Grid xs={8} item container direction="column">
                        <FormControlLabel
                            className={classes.formControlLabel}
                            label={t("slingshot.automations.create_automation.label.scheduled")}
                            control={
                                <Switch
                                    className={classes.switchControlLabel}
                                    field={state.form.$("scheduled")}
                                    id={`${idPrefix}_scheduled`}
                                />
                            }
                        />
                        <Box pt={4} pb={2} width="100%">
                            <Grid container wrap="nowrap" spacing={4}>
                                <Grid item container alignItems="center" xs={4}>
                                    <Typography>{t("slingshot.automations.create_automation.label.frequency")}</Typography>
                                </Grid>
                                <Grid item container xs={4}>
                                    <TextInput
                                        field={state.form.$("frequency")}
                                        disabled={!state.form.$("scheduled").value}
                                        id={`${idPrefix}_frequency`}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        field={state.form.$("frequencyUnit")}
                                        disabled={!state.form.$("scheduled").value}
                                        options={FREQUENCY_OPTIONS}
                                        id={`${idPrefix}_frequency_unit`}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box width="100%" pb={10}>
                            <Grid container wrap="nowrap">
                                <Grid item container xs={3} alignItems="center">
                                    <Typography>{t("slingshot.automations.create_automation.label.start_at")}</Typography>
                                </Grid>
                                <Grid item container justify="flex-end">
                                    <DateTimePicker
                                        className={classes.picker}
                                        open={state.isPickerOpen}
                                        onOpen={openPicker}
                                        onClose={closePicker}
                                        value={state.form.$("startAt").value}
                                        onChange={state.form.$("startAt").onChange}
                                        TextFieldComponent={MuiTextInput}
                                        inputVariant="outlined"
                                        format={getLanguageDateFormat(SLINGSHOT_DATE_HOURS_FORMAT)}
                                        variant="dialog"
                                        minutesStep={5}
                                        DialogProps={{
                                            className: classes.dateTimePickerDialogStyles,
                                        }}
                                        disabled={!state.form.$("scheduled").value}
                                        id={`${idPrefix}_start_at`}
                                    />
                                </Grid>
                                <Grid item>
                                    <Box pl={2} height="100%" display="flex" alignItems="center">
                                        <ButtonBase
                                            onClick={openPicker}
                                            disabled={!state.form.$("scheduled").value}
                                            id={`${idPrefix}_open_picker`}
                                        >
                                            <CalendarIcon />
                                        </ButtonBase>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item xs={4}>
                        <Typography gutterBottom>{t("slingshot.automations.create_automation.label.query")}</Typography>
                        <Typography className={classes.helper} gutterBottom>
                            {t("slingshot.automations.create_automation.label.query.helper")}
                        </Typography>
                    </Grid>
                    <Grid item container direction="column" xs={8}>
                        <RadioGroup field={state.form.$("query")}>
                            <Radio
                                value={RADIO_VALUE_EXTENSION}
                                label={t("slingshot.automations.create_automation.label.file_extensions")}
                                id={`${idPrefix}_radio_file_extensions`}
                            />
                            <TextInput
                                placeholder={t("slingshot.automations.create_automation.label.file_extensions.placeholder")}
                                field={state.form.$("fileExtension")}
                                disabled={state.form.$("query").value !== RADIO_VALUE_EXTENSION}
                                id={`${idPrefix}_file_extensions`}
                            />
                            <Radio
                                value={RADIO_VALUE_REGEXP}
                                label={t("slingshot.automations.create_automation.label.regular_expression")}
                                id={`${idPrefix}_radio_regular_expression`}
                            />
                            <TextInput
                                placeholder={t("slingshot.automations.create_automation.label.regular_expression.placeholder")}
                                field={state.form.$("regularExpression")}
                                disabled={state.form.$("query").value === RADIO_VALUE_EXTENSION}
                                id={`${idPrefix}_regular_expression`}
                            />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            <FolderBrowser
                open={folderBrowser.isOpen}
                onClose={folderBrowser.close}
                onChoose={onFolderChoose}
                share={state.form.$("share").value}
                parentName={t("slingshot.automations.create_automation.title")}
                pathValue={state.path}
                id={idPrefix}
            />
        </Dialog>
    ));
};

export default CreateAutomationModal;
