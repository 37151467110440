import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { ButtonBase, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Checkbox from "components/Checkbox";
import ChevronRightIcon from "assets/ChevronRightIcon";
import PlusIcon from "assets/PlusIcon";
import StatusLabel from "components/StatusLabel";
import UsedCellItem from "../UsedCellItem";
import TableRowControlButton from "components/TableRowControlButton";
import TableSortLabel from "components/TableSortLabel";
import { useStyles } from "./RemoteShare.style";
import { useTranslation } from "react-i18next";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { REMOTE_SHARE } from "const/widgetNameConst";
import DataCard from "components/DataCard/DataCard";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { GiB_IEC } from "const/diskSizeConst";
import { EXTERNAL_SHARE_NAME, EXTERNAL_SHARE_HOST, EXTERNAL_SHARE_USED, EXTERNAL_SHARE_STATUS } from "const/sortColumnConst";
import AddExternalShareDialog from "./components/AddExternalShareDialog";
import useStoreByIp from "hooks/useStoreByIp";
import useEvoPrefix from "hooks/useEvoPrefix";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import HideOverlay from "components/HideOverlay/HideOverlay";
import { useStore } from "hooks/useStore";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import RemoteShareDrawer from "../RemoteShareDrawer";
import RemoteSharesBulkBar from "../RemoteSharesBulkBar";

const REMOTE_SHARE_ID_PREFIX = `remote_share`;

const RemoteShare = ({ ip, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${REMOTE_SHARE_ID_PREFIX}` : `${SHARES_PAGE_ID_PREFIX}_${REMOTE_SHARE_ID_PREFIX}`;
    const classes = useStyles();
    const { t } = useTranslation();
    const evoPrefix = useEvoPrefix({ ip });

    const { externalSharesStore } = useStoreByIp({ ip });
    const {
        store: { externalShareDrawerStore },
    } = useStore();

    useAsyncEffect(async () => {
        state.isLoadingExternalShares = true;
        await externalSharesStore.fetchExternalShares();
        state.isLoadingExternalShares = false;

        externalSharesStore.clearCheckedShares();
    }, []);

    const changeSorting = (column) => () => externalSharesStore.changeSorting(column);

    const state = useLocalStore(() => ({
        isLoadingExternalShares: false,
        isAddExternalShareDialogOpen: false,
        get mainCheckboxStatus() {
            if (
                externalSharesStore.externalShares &&
                externalSharesStore.checkedSharesNamesWithPrefix.length > 0 &&
                externalSharesStore.checkedSharesNamesWithPrefix.length === externalSharesStore.externalShares.length
            ) {
                return "checked";
            } else if (
                externalSharesStore.externalShares &&
                externalSharesStore.checkedSharesNamesWithPrefix.length > 0 &&
                externalSharesStore.checkedSharesNamesWithPrefix.length < externalSharesStore.externalShares.length
            ) {
                return "indeterminate";
            }
            return null;
        },
    }));

    const onRowClick = (shareName) => () => {
        if (externalSharesStore.checkedSharesNamesWithPrefix.length > 0) return;
        externalSharesStore.setCurrentShareName(externalSharesStore.currentShareName === shareName ? null : shareName);
        externalShareDrawerStore.dropOthersCurrentShareNames(ip);
    };

    const isCheckedShare = (shareName) => {
        return externalSharesStore.checkedSharesNames.some((share) => share === shareName);
    };

    const onCheckboxChange = (shareName) => () => {
        isCheckedShare(shareName)
            ? externalSharesStore.removeCheckedShare(shareName)
            : externalSharesStore.addCheckedShare(shareName);
    };

    const handleMainCheckbox = () => {
        switch (state.mainCheckboxStatus) {
            case "checked":
            case "indeterminate":
                externalSharesStore.externalShares.forEach((share) => externalSharesStore.removeCheckedShare(share.name));
                break;
            default:
                externalSharesStore.externalShares.forEach((share) => externalSharesStore.addCheckedShare(share.name));
                break;
        }
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const openAddExternalShareDialog = () => {
        state.isAddExternalShareDialogOpen = true;
    };
    const closeAddExternalShareDialog = () => {
        state.isAddExternalShareDialogOpen = false;
    };

    return useObserver(() => (
        <DataCard
            emptyPadding
            title={`${evoPrefix}${t("share.remote_shares.title")}`}
            headerDivider
            headerControl={[
                <HideOverlay show={!ip} key={`data_card-header_control-control-1`}>
                    <MoreMenu id={`${idPrefix}_more_menu`}>
                        <HomeCardActionMenuItem widget={REMOTE_SHARE} />
                    </MoreMenu>
                </HideOverlay>,
                <ButtonBase
                    key={`data_card-header_control-control-2`}
                    onClick={openAddExternalShareDialog}
                    id={`${idPrefix}_add_external_share`}
                >
                    <PlusIcon />
                </ButtonBase>,
            ]}
        >
            <RemoteShareDrawer />
            <RemoteSharesBulkBar />
            <AddExternalShareDialog open={state.isAddExternalShareDialogOpen} onClose={closeAddExternalShareDialog} ip={ip} />
            {externalSharesStore.externalShares && externalSharesStore.externalShares.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.checkboxCell} padding={"checkbox"}>
                                <Checkbox
                                    indeterminate={state.mainCheckboxStatus === "indeterminate"}
                                    checked={state.mainCheckboxStatus === "checked"}
                                    onChange={handleMainCheckbox}
                                    id={`${idPrefix}_main_checkbox`}
                                />
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(EXTERNAL_SHARE_NAME)}
                                    direction={externalSharesStore.order}
                                    active={externalSharesStore.orderBy === EXTERNAL_SHARE_NAME}
                                    id={`${idPrefix}_name_sort`}
                                >
                                    {t("share.remote_shares.table.label.connection_name")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(EXTERNAL_SHARE_HOST)}
                                    direction={externalSharesStore.order}
                                    active={externalSharesStore.orderBy === EXTERNAL_SHARE_HOST}
                                    id={`${idPrefix}_host_sort`}
                                >
                                    {t("share.remote_shares.table.label.host")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align={"right"}>
                                <TableSortLabel
                                    onClick={changeSorting(EXTERNAL_SHARE_USED)}
                                    direction={externalSharesStore.order}
                                    active={externalSharesStore.orderBy === EXTERNAL_SHARE_USED}
                                    id={`${idPrefix}_used_sort`}
                                >
                                    {t("share.remote_shares.table.label.used/size")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align={"right"}>
                                <TableSortLabel
                                    onClick={changeSorting(EXTERNAL_SHARE_STATUS)}
                                    direction={externalSharesStore.order}
                                    active={externalSharesStore.orderBy === EXTERNAL_SHARE_STATUS}
                                    id={`${idPrefix}_status_sort`}
                                >
                                    {t("share.remote_shares.table.label.status")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {externalSharesStore.sortedExternalShares.map((share, index) => (
                            <TableRow
                                selected={externalSharesStore.currentShareName === share.name}
                                key={share.name + share.host + share.share}
                                onClick={onRowClick(share.name)}
                            >
                                <TableCell>
                                    <Checkbox
                                        onClick={stopPropagation}
                                        onChange={onCheckboxChange(share.name)}
                                        checked={isCheckedShare(share.name)}
                                        id={`${idPrefix}_share_${index}_checkbox`}
                                    />
                                </TableCell>
                                <TableCell>{share.name}</TableCell>
                                <TableCell>{share.host}</TableCell>
                                <TableCell>
                                    <UsedCellItem used={share.used / GiB_IEC.value} quota={share.size / GiB_IEC.value} />
                                </TableCell>
                                <TableCell className={classes.statusCell}>
                                    <StatusLabel status={share.status} />
                                </TableCell>
                                <TableCell align={"right"}>
                                    <TableRowControlButton icon={<ChevronRightIcon id={`${idPrefix}_share_${index}_button`} />} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyCardPlaceholder
                    message={t("share.remote_shares.table.placeholder")}
                    inProgress={state.isLoadingExternalShares}
                />
            )}
        </DataCard>
    ));
};

export default RemoteShare;
