import { useContext, useEffect, useMemo, useState } from "react";
import { Context } from "store";
import { toJS } from "mobx";

/**
 * Custom hook for getting global masks from the current EVO as well as from the connected one.
 * @returns {{list: string[], isList: boolean}} a string array consisting of masks, as well as masks from connected multi EVOs.
 * @example returns {list: [*.etd, *.dgf], isList: true}
 */
export const useExternalDefaultMask = (ip) => {
    const {
        store: { shareStore },
        multipleEvosRootStore,
    } = useContext(Context);
    /** @type Array<string> ip of external connected EVOs. */
    const storesIps = multipleEvosRootStore.storesIps;

    const [commonDefaultMask, setCommonDefaultMask] = useState([]);

    useEffect(() => {
        (async () => {
            /** @type {ShareStore.defaultMasksStore} response from external EVOs with default masks. */
            const externalDefaultMasks = await storesIps.map(async (ip) => {
                const { shareStore } = await multipleEvosRootStore.getStore(ip);
                await shareStore.fetchDefaultsMasks();

                return toJS(shareStore.defaultMasksStore);
            });
            if (!ip) return await shareStore.defaultMasksStore;
            await externalDefaultMasks.forEach((mask) => {
                mask.then(({ data }) => {
                    setCommonDefaultMask([...data]);
                });
            });
        })();

        return () => {
            setCommonDefaultMask([]);
        };
    }, [storesIps]);

    useEffect(() => {
        (async () => {
            if (!shareStore.defaultMasks) {
                await shareStore.fetchDefaultsMasks();
            }
            shareStore.defaultMasks && setCommonDefaultMask((prevState) => [...prevState, ...shareStore.defaultMasks]);
        })();
    }, []);

    /** @type {Array<string>} The string of items - default masks. */
    const uniqCommonDefaultMasks = useMemo(() => Array.from(new Set(commonDefaultMask)), [commonDefaultMask]);

    return { list: uniqCommonDefaultMasks, isList: Boolean(uniqCommonDefaultMasks.length) };
};
