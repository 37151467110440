import { types } from "mobx-state-tree";

import Request from "api/Request";
import UpdateArguments from "api/system_components/sharebrowser_webclient/Types/UpdateArguments";

const UpdateSharebrowserWebclient = Request.props({
    path: "/com/studionetworksolutions/evo/manage/SharebrowserWebClient",
    method: "update_sharebrowser_web_client",
    arguments: types.maybe(UpdateArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default UpdateSharebrowserWebclient;
