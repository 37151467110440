import React, { useEffect } from "react";
import { useLocalStore, observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { useTranslation } from "react-i18next";
import { useStyles } from "./LogicalDisksDrawer.styles.js";
import DiskIcon from "assets/DiskIcon";
import { Button, ButtonGroup, Divider, Drawer, Grid, Typography } from "@material-ui/core";
import WorldOffIcon from "assets/WorldOffIcon";
import TrashIcon from "assets/TrashIcon";
import WorldIcon from "assets/WorldIcon";
import DriveIcon from "assets/DriveIcon";
import clsx from "clsx";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import DeleteDiskDialog from "../DeleteDiskDialog";
import { convertBytesToSizeUnit } from "utils/convertBytesToSizeUnit";
import { KiB_IEC } from "const/diskSizeConst";
import DrawerHeader from "components/DrawerHeader";
import SavedInputIndicator from "components/SavedInputIndicator/SavedInputIndicator.js";
import { getBcacheDriveName } from "const/bcacheConst";
import ChangeCacheDeviceDialog from "../ChangeCacheDeviceDialog/ChangeCacheDeviceDialog.js";
import ResetIcon from "assets/ResetIcon";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { useStore } from "hooks/useStore.js";

const LOGICAL_DISKS_DRAWER_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_logical_disks_drawer`;

const drawerName = "LogicalDisksDrawer";
const LogicalDisksDrawer = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { uiStore, volumeDrawerStore },
    } = useStore();

    const state = useLocalStore(() => ({
        showDetails: false,
        isDeleteDialogOpened: false,
        isChangeCacheDeviceDialogOpened: false,
        deleteDiskInProgress: false,
    }));
    useEffect(() => {
        (async () => {
            if (volumeDrawerStore.currentPort) {
                if (uiStore.openedDrawer !== drawerName) {
                    uiStore.openDrawer(drawerName, onClose);
                    const currentPort = volumeDrawerStore.currentPort;
                    if (
                        volumeDrawerStore.currentPortStore.defragEnabled &&
                        !currentPort.isQueueVolume &&
                        !volumeDrawerStore.currentPortStore.getDefragState(currentPort.volume.volumeId)
                    ) {
                        const volumeId = getSnapshot(currentPort.volume.volumeId);
                        await volumeDrawerStore.currentPortStore.fetchDefragState(volumeId, false);
                    }
                } else {
                    uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
                }
            } else if (uiStore.openedDrawer === drawerName) {
                uiStore.closeDrawer();
            }
        })();
    }, [volumeDrawerStore.currentPort]);

    const volume = volumeDrawerStore.currentPort?.volume;
    const isQueue = volumeDrawerStore.currentPort?.isQueueVolume;
    const isOffline = volume?.status.status === "offline";
    const openDeleteDialog = () => {
        state.isDeleteDialogOpened = true;
    };
    const openChangeCacheDeviceDialog = () => {
        state.isChangeCacheDeviceDialogOpened = true;
    };

    const onClose = () => {
        volumeDrawerStore.dropAllCurrentPorts();
    };

    const deleteDisks = async () => {
        state.deleteDiskInProgress = true;
        if (isQueue) {
            await volumeDrawerStore.currentPortStore.dequeueVolumes({
                volumeIds: [{ pool: volume.volumeId.pool, volume: volume.volumeId.volume }],
            });
        } else {
            await volumeDrawerStore.currentPortStore.removeVolumes({
                volumeIds: [{ pool: volume.volumeId.pool, volume: volume.volumeId.volume }],
            });
        }
        state.deleteDiskInProgress = false;

        onClose();
        return true;
    };
    const closeDeleteDialog = () => {
        state.isDeleteDialogOpened = false;
    };

    const closeChangeCacheDeviceDialog = () => {
        state.isChangeCacheDeviceDialogOpened = false;
    };

    const setVolumeOnline = () => {
        volumeDrawerStore.currentPortStore.setVolumesOnline({
            volumeIds: [{ pool: volume.volumeId.pool, volume: volume.volumeId.volume }],
            online: isOffline,
        });
        onClose();
    };

    const showDetails = () => {
        state.showDetails = true;
    };
    const showAction = () => {
        state.showDetails = false;
    };
    const refreshDefragState = () => {
        volume && volumeDrawerStore.currentPortStore.fetchDefragState(getSnapshot(volume.volumeId), true);
    };
    const startDefrag = () => {
        volume && volumeDrawerStore.currentPortStore.startDefrag(getSnapshot(volume.volumeId));
    };
    const stopDefrag = () => {
        volume && volumeDrawerStore.currentPortStore.stopDefrag(getSnapshot(volume.volumeId));
    };

    const renderErrors = () => {
        if (isQueue) {
            if (volume.status.statusDetails) {
                return (
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("disks_and_pools.logical_disks.side_menu.error_comment")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>{volume.status.statusDetails}</Typography>
                        </Grid>
                    </Grid>
                );
            }
        } else {
            if (volume.status.healthDetails) {
                return (
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("disks_and_pools.logical_disks.side_menu.error_comment")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>{volume.status.healthDetails}</Typography>
                        </Grid>
                    </Grid>
                );
            }
        }
    };

    const getFragmentationValue = () => {
        const msg = t("disks_and_pools.logical_disks.defrag_status.unknown");
        if (!volume) return msg;

        const defragState = volumeDrawerStore.currentPortStore.getDefragState(volume.volumeId);

        if (!defragState || Number(defragState.fragmentation) === -1) return msg;

        return defragState.fragmentation.toFixed(2) + "%";
    };

    const fragmentationValue = getFragmentationValue();

    const renderActions = () => {
        return (
            <Grid direction={"column"} item container className={classes.actionContainer}>
                {!isQueue && (
                    <Grid item>
                        <BulkBarFlatButton
                            id={`${LOGICAL_DISKS_DRAWER_ID_PREFIX}_online_status`}
                            onClick={setVolumeOnline}
                            icon={isOffline ? <WorldIcon /> : <WorldOffIcon />}
                        >
                            {isOffline
                                ? t("disks_and_pools.logical_disks.row_context_menu.set_online")
                                : t("disks_and_pools.logical_disks.row_context_menu.set_offline")}
                        </BulkBarFlatButton>
                    </Grid>
                )}
                <Grid item>
                    <BulkBarFlatButton
                        id={`${LOGICAL_DISKS_DRAWER_ID_PREFIX}_open_delete_dialog`}
                        errorColor
                        onClick={openDeleteDialog}
                        icon={<TrashIcon />}
                    >
                        {t("disks_and_pools.logical_disks.row_context_menu.delete")}
                    </BulkBarFlatButton>
                </Grid>
                {!isQueue && volume?.cacheSupport && (
                    <Grid item>
                        <BulkBarFlatButton
                            onClick={openChangeCacheDeviceDialog}
                            icon={<DriveIcon />}
                            id={`${LOGICAL_DISKS_DRAWER_ID_PREFIX}_open_change_cache_device_dialog`}
                        >
                            {t("disks_and_pools.logical_disks.row_context_menu.change_cache_device")}
                        </BulkBarFlatButton>
                    </Grid>
                )}
                {!isQueue &&
                    volumeDrawerStore.currentPortStore.defragEnabled &&
                    volume?.mapping.hasShares &&
                    (volume && volumeDrawerStore.currentPortStore.getDefragState(volume.volumeId)?.status === "defrag" ? (
                        <Grid item>
                            <BulkBarFlatButton
                                onClick={stopDefrag}
                                icon={<DriveIcon />}
                                id={`${LOGICAL_DISKS_DRAWER_ID_PREFIX}_stop_defrag`}
                            >
                                {t("disks_and_pools.logical_disks.row_context_menu.stop_defrag")}
                            </BulkBarFlatButton>
                        </Grid>
                    ) : (
                        <Grid item>
                            <BulkBarFlatButton
                                onClick={startDefrag}
                                icon={<DriveIcon />}
                                id={`${LOGICAL_DISKS_DRAWER_ID_PREFIX}_start_defrag`}
                            >
                                {t("disks_and_pools.logical_disks.row_context_menu.start_defrag")}
                            </BulkBarFlatButton>
                        </Grid>
                    ))}
            </Grid>
        );
    };
    const renderDetails = () => {
        return (
            <>
                <Grid item container alignItems={"baseline"}>
                    <Grid item xs={6}>
                        <Typography>{t("disks_and_pools.logical_disks.side_menu.stripe_size")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {convertBytesToSizeUnit({ bytes: volume.chunkSize, unit: KiB_IEC })}
                        </Typography>
                    </Grid>
                </Grid>
                {volume?.cacheSupport && (
                    <>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("disks_and_pools.add_disk_dialog.bcache_support")}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <SavedInputIndicator enabled={volume.cacheSupport} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("disks_and_pools.add_disk_dialog.cache_device")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>
                                    {getBcacheDriveName(volume.cache.quad, volume.cache.drive)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                )}
                {!isQueue && volumeDrawerStore.currentPortStore.defragEnabled && volume.mapping.hasShares && (
                    <>
                        <Grid item container alignItems={"baseline"}>
                            <Grid item xs={6}>
                                <Typography>{t("disks_and_pools.logical_disks.side_menu.defrag_status")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>
                                    {volume && volumeDrawerStore.currentPortStore.getDefragState(volume.volumeId)
                                        ? t(volumeDrawerStore.currentPortStore.getDefragState(volume.volumeId).status)
                                        : t("idle")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"}>
                            <Grid item xs={6}>
                                <Typography>{t("disks_and_pools.logical_disks.side_menu.fragmentation")}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={classes.textInfo}>{fragmentationValue}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    onClick={refreshDefragState}
                                    id={`${LOGICAL_DISKS_DRAWER_ID_PREFIX}_refresh_defrag_state`}
                                >
                                    <ResetIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
                {renderErrors()}
            </>
        );
    };
    const drawerCloseHandler = () => {
        if (uiStore.openedDrawer !== drawerName) {
            state.showDetails = true;
        }
    };
    return (
        <Drawer
            variant={"persistent"}
            className={clsx(classes.drawer, uiStore.isWidgetsBarOpen && classes.drawerOpenHeader)}
            anchor="right"
            open={uiStore.openedDrawer === drawerName}
            onAnimationEnd={drawerCloseHandler}
        >
            <DeleteDiskDialog
                inProgress={state.deleteDiskInProgress}
                onDeleteAction={deleteDisks}
                diskName={volumeDrawerStore.currentPortWithPrefix}
                onClose={closeDeleteDialog}
                open={state.isDeleteDialogOpened}
            />
            <ChangeCacheDeviceDialog
                ip={volumeDrawerStore.currentPortStoreIp}
                volume={volume}
                onClose={closeChangeCacheDeviceDialog}
                open={state.isChangeCacheDeviceDialogOpened}
            />
            {volumeDrawerStore.currentPort && (
                <Grid className={classes.container} container direction={"column"}>
                    <Grid item>
                        <DrawerHeader noWrap={false} closeDrawer={onClose} icon={<DiskIcon />}>
                            {volumeDrawerStore.currentPortWithPrefix}
                        </DrawerHeader>
                        <Grid item>
                            <ButtonGroup className={classes.headerButtonGroup}>
                                <Button
                                    size={"small"}
                                    onClick={showDetails}
                                    id={`${LOGICAL_DISKS_DRAWER_ID_PREFIX}_show_details`}
                                    variant={state.showDetails ? "contained" : "outlined"}
                                    color={"primary"}
                                >
                                    {t("disks_and_pools.logical_disks.side_menu.details")}
                                </Button>
                                <Button
                                    size={"small"}
                                    onClick={showAction}
                                    id={`${LOGICAL_DISKS_DRAWER_ID_PREFIX}_show_action`}
                                    variant={state.showDetails ? "outlined" : "contained"}
                                    color={"primary"}
                                >
                                    {t("disks_and_pools.logical_disks.side_menu.actions")}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid item container className={classes.body} direction={"column"} spacing={4}>
                        {state.showDetails ? renderDetails() : renderActions()}
                    </Grid>
                </Grid>
            )}
        </Drawer>
    );
};

export default observer(LogicalDisksDrawer);
