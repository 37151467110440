import React from "react";
import { Grid, Link, Typography, ButtonBase } from "@material-ui/core";
import { useObserver } from "mobx-react";
import { useTranslation, Trans } from "react-i18next";
import { useStyles } from "./ModeController.styles";
import FileMasksTable from "./FileMasksTable";
import { URL_ADVANCED } from "routes";
import RowControlLabelSwitch from "components/RowControlLabelSwitch/RowControlLabelSwitch";
import Switch from "components/MobxForm/Switch";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { SNS_ADD_SHARE_CURRENT_RECOMMENDATIONS } from "const";
import { useStore } from "hooks/useStore";

const MODE_CONTROLLER_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_mode_controller`;

const ModeController = ({ pool, protocol, isMasksEditable, form, mode, projectSharingEnabled, ip }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { authStore },
    } = useStore();

    const isLocalSmbShare = () => {
        return protocol.value === "smb" && pool !== "CLUSTER";
    };

    if (isLocalSmbShare()) {
        mode.set(projectSharingEnabled.value ? "project" : "locked");
    } else if (protocol.value === "nfs") {
        mode.set("unlocked");
    } else {
        mode.set("locked");
    }

    const url = ip ? `https://${ip}/#${URL_ADVANCED}` : `/#${URL_ADVANCED}`;

    return useObserver(() => (
        <Grid>
            {isLocalSmbShare() && (
                <>
                    <Grid className={classes.row} alignItems={"center"} container>
                        <RowControlLabelSwitch
                            control={<Switch field={projectSharingEnabled} id={`${MODE_CONTROLLER_ID_PREFIX}_project_locking`} />}
                            label={t("share.create_share.project_locking")}
                            lastColumnWidth={"100%"}
                        />
                    </Grid>
                    <Grid container direction={"row"}>
                        <Grid item xs={4}>
                            <Typography className={classes.subtext}>
                                <Trans
                                    i18nKey={"share.create_share.mode_controller.check_kb_text_with_link"}
                                    components={
                                        <Link target="_blank" rel="noopener" href={SNS_ADD_SHARE_CURRENT_RECOMMENDATIONS} />
                                    }
                                />
                            </Typography>
                        </Grid>
                        {projectSharingEnabled.value && (
                            <Grid item xs={8}>
                                <Grid className={classes.masksContainer}>
                                    <FileMasksTable form={form} isEditable={isMasksEditable} ip={ip} />
                                </Grid>
                                <Grid className={classes.editMasksButton}>
                                    {authStore.isAdmin && (
                                        <ButtonBase id={`${MODE_CONTROLLER_ID_PREFIX}_edit_global_masks`}>
                                            <Link rel="noopener" target="_blank" href={url} variant={"button"}>
                                                {t("share.create_share.link.edit_global_masks")}
                                            </Link>
                                        </ButtonBase>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    ));
};

export default ModeController;
