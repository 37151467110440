import React, { useContext } from "react";
import { Context } from "store";
import { useObserver } from "mobx-react";
import { Box, Button, Typography } from "@material-ui/core";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";

const JOB_SUMMARY_MODAL_ID_PREFIX = `job_summary_modal`;

const JobSummaryModal = ({ open, onClose, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${JOB_SUMMARY_MODAL_ID_PREFIX}` : JOB_SUMMARY_MODAL_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { syncJobsStore },
    } = useContext(Context);

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={t("slingshot_page.tabs.replications.last_job_summary_modal.title")}
            submitBtn={
                <Button onClick={onClose} variant={"contained"} color={"primary"} id={`${idPrefix}_close`}>
                    {t("common.button.close")}
                </Button>
            }
        >
            {syncJobsStore.jobSummaryFile ? (
                <Box whiteSpace="initial">
                    <Typography dangerouslySetInnerHTML={{ __html: syncJobsStore.jobSummaryFile }} />
                </Box>
            ) : (
                <EmptyCardPlaceholder message={t("slingshot_page.tabs.replications.last_job_summary_modal.placeholder")} />
            )}
        </Dialog>
    ));
};

export default JobSummaryModal;
