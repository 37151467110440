import { types } from "mobx-state-tree";

import Request from "api/Request";
import IfaceNameArguments from "api/net/Types/IfaceNameArguments";

const RenewDhcpLease = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "renew_dhcp_lease",
    arguments: types.maybe(IfaceNameArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default RenewDhcpLease;
