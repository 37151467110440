import { types } from "mobx-state-tree";

import Request from "api/Request";
import TimeSettings from "api/time/Types/TimeSettings";

const SetTimeSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Time",
    method: "set_time_settings",
    arguments: types.maybe(TimeSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetTimeSettings;
