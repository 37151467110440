import { types } from "mobx-state-tree";

import GetLogicalDisksType from "../Types/GetLogicalDisksType";

const GetSharesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(GetLogicalDisksType, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            }
        })
    )
});

export default GetSharesResult;
