import Request from "api/Request";

const GetDeferredParityCalculation = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Volume",
    method: "get_deferred_parity_calculation",
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetDeferredParityCalculation
