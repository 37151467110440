import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    contentTitle: {
        marginBottom: theme.spacing(4)
    },
    license: {
        whiteSpace: "pre-line"
    },
    link: {
        cursor: "pointer"
    }
}));
