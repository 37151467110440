import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    messageContainer: {
        padding: theme.spacing(6)
    },
    icon: {
        marginRight: theme.spacing(4)
    }
}));
