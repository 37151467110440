import React from "react";

const Icon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="69cc3438-c1b4-11ea-b3de-0242ac130004" x1="31.267%" x2="100%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#3C1EFF"></stop>
                    <stop offset="100%" stopColor="#3A98FF" stopOpacity="0.91"></stop>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd" transform="translate(2 1)">
                <circle cx="15" cy="5" r="1" fill="#D8D8D8" stroke="#979797"></circle>
                <rect width="19" height="21" x="0.5" y="0.5" fill="#85C3FF" stroke="#B2E1FE" rx="2"></rect>
                <path fill="#B2E1FE" d="M1 17h18v1H1z"></path>
                <circle cx="10" cy="9" r="6.5" fill="url(#69cc3438-c1b4-11ea-b3de-0242ac130004)" stroke="#A4E1FF"></circle>
                <circle cx="10" cy="9" r="2" fill="#A4E1FF"></circle>
                <path
                    fill="#FFF"
                    d="M3.5 18a1.477 1.477 0 01-.225-2.275l4.11-4.11a.6.6 0 01.961.692l-2.54 5.08a1.59 1.59 0 01-2.306.614z"
                ></path>
                <path fill="#1E90FF" d="M4.5 15.5a1 1 0 100 2 1 1 0 000-2z"></path>
                <circle cx="3" cy="3" r="1" fill="#1E90FF"></circle>
            </g>
        </svg>
    );
};

export default Icon;
