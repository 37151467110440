export const URL_SUPPORT = "/support";
export const URL_SUPPORT_SELF_TESTS_TAB = "/support/self-tests";
export const URL_SUPPORT_OS_DATA_TAB = "/support/os-data";
export const URL_TROUBLESHOOTING = "/support/replace-disk";
export const URL_EXTERNAL_SUPPORT = "https://www.studionetworksolutions.com/support/";

export const URL_USERS = "/users";
export const URL_LOGIN = "/login";
export const URL_ADVANCED = "/advanced";
export const URL_RECYCLE_BIN = "/recycle-bin";
export const URL_DISKS = "/disks";
export const URL_NETWORK = "/network";

export const URL_SHARES = "/shares";
export const URL_SHARES_REMOTE_TAB = "/shares/remote-shares";

export const URL_HARDWARE_ACTIVATION = "/hardware-activation";
export const URL_HOME = "/home";
export const URL_QUICK_START = "/quickstart";
export const URL_SPLASH_PAGE = "/splash";
export const URL_TIME_SETTINGS = "/time-settings";
export const URL_TECHNICAL_SUPPORT = "/technical-support";
export const URL_ADMIN_SETTINGS = "/admin-settings";
export const URL_DASHBOARD = "/more-widgets";
export const URL_ALERTS = "/alerts";
export const URL_POWER_AND_UPS = "/power";
export const URL_HELP = "https://studio-network-solutions-evo-docs.readthedocs-hosted.com/en/latest/index.html";
export const URL_HELP_EVO = "https://studio-network-solutions-evo-docs.readthedocs-hosted.com/en/latest/";
export const URL_HELP_SHAREBROWSER = "https://studio-network-solutions-sharebrowser-docs.readthedocs-hosted.com/en/latest/";
export const URL_FILE_NAVIGATOR = "/file-navigator";

export const URL_SLINGSHOT = "/slingshot";
export const URL_SLINGSHOT_INDEX = "/slingshot/index-proxy";
export const URL_SLINGSHOT_AUTOMATIONS = "/slingshot/automations";
export const URL_SLINGSHOT_ARCHIVED = "/slingshot/archived-instances";
export const URL_SLINGSHOT_SETTINGS_TAB = "/slingshot/settings";
export const URL_SHAREBROWSER = `${document.location.protocol}//${document.location.hostname}${
    document.location.port ? `:${document.location.port}` : ""
}/sharebrowser`;
export const URL_SHAREBROWSER_AUTH = `/sharebrowser`;

export const URL_HARDWARE_PROFILE = "/hardware-profile";
export const URL_MASTER_RESET = "/master-reset";
export const URL_AUDIT = "/audit";
export const URL_EULA = "/eula";

export const URL_SHORTCUT_WHATS_THIS = "/whats-this";
export const URL_WIREGUARD = "/vpn";
export const URL_FIREWALL = "/firewall";
export const URL_CENTRAL_STATUS = "/federation-status";
export const URL_CLUSTER = "/cluster";

export const HASH_INSTALLED_PLUGINS_TABLE_CARD = "installed_plugins_table_card";
export const HASH_COMPONENT_DATABASES = "component_databases";

export const URL_FILE_NAVIGATOR_LOG = "/file-navigator-log";
