import React, { useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { Box, Grid, Typography } from "@material-ui/core";
import ButtonNext from "../ButtonNext/ButtonNext";
import { useStore } from "hooks/useStore";
import Select from "components/MobxForm/Select";
import { FTP, SMB } from "const/shareRootAccessConst";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import i18next from "i18next";

const MixedShares = () => {
    const {
        store: { migrationStore },
    } = useStore();

    useLayoutEffect(() => {
        migrationStore.getMixedShares();
    }, []);

    const handleClickNext = async () => {
        const res = await migrationStore.runShareTypesStep();
        if (res) migrationStore.nextStep();
    };

    if (!migrationStore.isInitMixedShareForm || migrationStore.isFetchingMixedShares)
        return (
            <Box p={6}>
                <Grid container item spacing={6} justifyContent="center">
                    <Grid item>
                        <EmptyCardPlaceholder
                            message={i18next.t("migrator.mixed_shares.empty")}
                            inProgress={migrationStore.isFetchingMixedShares}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={6} justifyContent="flex-end">
                    <Grid item>
                        <ButtonNext disabled />
                    </Grid>
                </Grid>
            </Box>
        );

    return (
        <Box p={6}>
            {migrationStore.mixedShares.map((el) => (
                <Grid container item key={el.share} spacing={6}>
                    <Grid item container wrap="nowrap" alignItems="center">
                        <Grid item xs={4}>
                            <Typography>{el.share}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                field={migrationStore.mixedSharesForm.$(el.share)}
                                options={[
                                    { value: FTP, label: FTP },
                                    { value: SMB, label: SMB },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ))}
            <Grid container spacing={6} justifyContent="flex-end">
                <Grid item>
                    <ButtonNext onClick={handleClickNext} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default observer(MixedShares);
