import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    label: {
        alignItems: "baseline",
        "&>.MuiFormControlLabel-label": {
            opacity: 0.8,
            position: "relative",
            bottom: 6
        },
        "&>.Mui-disabled": {
            opacity: 0.4
        }
    }
}));
