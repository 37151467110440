import React, { useEffect } from "react";
import { useObserver, useLocalStore, observer } from "mobx-react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { Table, TableCell, TableHead, TableRow, TableBody, Button, FormControlLabel, Typography } from "@material-ui/core";
import Checkbox from "components/Checkbox";
import { useStyles } from "./AssignSharesModal.styles";
import { NONE, RW, RO } from "const/shareRootAccessConst";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import SaveChangesDialog from "components/SaveChangesDialog";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import { useStore } from "hooks/useStore";
import AccessRow from "./AccessRow";
import { useModalCombine } from "hooks/useModalCombine";
import { useToggle } from "hooks/useToggle";

const ASSIGN_SHARES_MODAL_ID_PREFIX = "assign_shares_modal";

const sharesRootAccessSortByLocaleCompare = (arr) => {
    return arr.sort((a, b) =>
        a.shareName.localeCompare(b.shareName, undefined, {
            numeric: true,
            sensitivity: "base",
        })
    );
};

const AssignSharesModal = ({ open, onClose, openCustom, parentId }) => {
    const { t } = useTranslation();
    const idPrefix = parentId ? `${parentId}_${ASSIGN_SHARES_MODAL_ID_PREFIX}` : ASSIGN_SHARES_MODAL_ID_PREFIX;
    const classes = useStyles();

    const {
        store: { userStore, multiLdapStore },
    } = useStore();

    const [isFirstOpen, toggleIsFirstOpen] = useToggle();
    const { unsavedChangesModal, saveChangesModal } = useModalCombine(["unsavedChangesModal", "saveChangesModal"]);

    const state = useLocalStore(() => ({
        inProgress: false,
        saveInProgress: false,
        isSubmitted: false,
        currentShareNameWithPrefix: null,
        currentShareNameWithPrefixIp: undefined,
        get sharesRootAccess() {
            const currentEntity = userStore.currentEntity || userStore.currentEntitySingleBar;

            return currentEntity?.isExternal
                ? sharesRootAccessSortByLocaleCompare(multiLdapStore.allSharesRootAccess)
                : sharesRootAccessSortByLocaleCompare(multiLdapStore.currentShareAclStore.sharesRootAccess);
        },
    }));

    const currentEntity = userStore.currentEntity || userStore.currentEntitySingleBar;
    const currentEntityIdName = userStore.currentEntity && userStore.currentEntity.id.name;
    const currentEntityIdDomain = userStore.currentEntity && userStore.currentEntity.id.domain;
    const currentEntitySingleBarId = userStore.currentEntitySingleBar && userStore.currentEntitySingleBar.id.name;

    const onLoading = async () => {
        state.isSubmitted = false;
        state.inProgress = true;
        await multiLdapStore.getLdapStatus();
        await multiLdapStore.getAdStatus();
        if (currentEntity) {
            await multiLdapStore.fetchSharesRootAccess(currentEntity);
            state.inProgress = false;
        }
        return true;
    };

    useEffect(() => {
        if (open) {
            toggleIsFirstOpen(true);
        }
        if (isFirstOpen && currentEntity) {
            multiLdapStore.fetchSharesRootAccess(currentEntity);
        }
    }, [open, currentEntity]);

    useEffect(() => {
        onLoading();
    }, [currentEntityIdName, currentEntityIdDomain, currentEntitySingleBarId]);

    const handleClose = () => {
        toggleIsFirstOpen(false);
        onClose();

        if (currentEntity) {
            multiLdapStore.fetchSharesRootAccess(currentEntity);
        }

        state.isSubmitted = false;
    };

    const onSave = async () => {
        state.saveInProgress = true;
        const currentEntity = userStore.currentEntity || userStore.currentEntitySingleBar;
        const res = await multiLdapStore.setSharesRootAccess(currentEntity);
        if (res) {
            state.isSubmitted = true;
        }
        state.saveInProgress = false;

        if (!state.isSubmitted) {
            return;
        }

        setTimeout(handleClose, 1000);

        return res;
    };

    const onCheck = (access) => (e) => {
        multiLdapStore.setNewPermission({
            shareName: access.shareName,
            access: !e.target.checked ? NONE : e.target.value,
            ip: access.ip,
        });
    };

    const getIsChecked = (access, value) => {
        return multiLdapStore.allSharesRootAccess.some(
            (share) => share.shareName === access.shareName && share.access === value && access.ip === share.ip
        );
    };

    const onMainCheckboxClick = (column) => (e) => {
        const currentEntity = userStore.currentEntity || userStore.currentEntitySingleBar;

        if (isMainCheckboxIndeterminate(e.target.value) || isMainCheckboxChecked(e.target.value)) {
            multiLdapStore.setNewPermissionToAllShares(
                { access: NONE, currentEntity },
                { column, resetPermission: isMainCheckboxIndeterminate(column) }
            );
            return null;
        }

        multiLdapStore.setNewPermissionToAllShares(
            { access: e.target.value, currentEntity },
            { column, resetPermission: isMainCheckboxIndeterminate(column) }
        );
    };

    const isMainCheckboxChecked = (value) => {
        return !multiLdapStore.allSharesRootAccess.some((share) => share.access !== value); // TODO: adjust it for same share names
    };

    const isMainCheckboxIndeterminate = (value) => {
        const filterAccess = multiLdapStore.allSharesRootAccess.filter((share) => share.access === value);
        return (filterAccess.length && multiLdapStore.allSharesRootAccess.length !== filterAccess.length) || false; // TODO: adjust it for same share names
    };

    const openCustomIfNeeded = (access) => () => {
        state.currentShareNameWithPrefix = access.shareName;
        state.currentShareNameWithPrefixIp = access.ip;

        if (multiLdapStore.isSharesRootAccessDefault) {
            openCustom({ shareName: state.currentShareNameWithPrefix, ip: state.currentShareNameWithPrefixIp });
            return null;
        }
        saveChangesModal.open();
    };

    const closeIfNeeded = (isSubmitted) => {
        if (multiLdapStore.isSharesRootAccessDefault || isSubmitted === true) {
            handleClose();
            return null;
        }
        unsavedChangesModal.open();
    };

    const onSaveChangesDialogConfirm = async () => {
        const res = await onSave();
        saveChangesModal.close();
        if (res) {
            openCustom({ shareName: state.currentShareNameWithPrefix, ip: state.currentShareNameWithPrefixIp });
        }
    };

    const onSaveChangesDialogCancel = () => {
        saveChangesModal.close();
        openCustom({ shareName: state.currentShareNameWithPrefix, ip: state.currentShareNameWithPrefixIp });
    };

    const isAllSharesRootAccess = !!multiLdapStore.allSharesRootAccess.length;
    const title = userStore.currentEntity?.id.name || userStore.currentEntitySingleBar?.id.name || "";

    return useObserver(() => (
        <Dialog
            maxWidth={"lg"}
            open={open}
            onClose={closeIfNeeded}
            title={t("users.assign_shares_modal.title", {
                shareName: title,
            })}
            withoutPaddings
            submitBtn={
                isAllSharesRootAccess ? (
                    <AnimatedSubmitButton
                        label={t("common.button.save")}
                        disabled={state.inProgress}
                        isSubmitted={state.isSubmitted}
                        submit={onSave}
                        inProgress={state.saveInProgress}
                        id={`${idPrefix}_save`}
                    />
                ) : (
                    <Button onClick={handleClose} variant={"contained"} color={"primary"} id={`${idPrefix}_close`}>
                        {t("common.button.close")}
                    </Button>
                )
            }
            buttons={
                isAllSharesRootAccess ? (
                    <Button
                        disabled={state.inProgress}
                        variant={"contained"}
                        color={"secondary"}
                        onClick={handleClose}
                        id={`${idPrefix}_cancel`}
                    >
                        {t("common.button.cancel")}
                    </Button>
                ) : null
            }
        >
            {isAllSharesRootAccess && !state.inProgress ? (
                <Table size={"small"} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadLabel}>
                                <Typography noWrap>{t("users.assign_shares_modal.table.header.name")}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableHeadLabel}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={onMainCheckboxClick(RW)}
                                            checked={isMainCheckboxChecked(RW)}
                                            value={RW}
                                            indeterminate={isMainCheckboxIndeterminate(RW)}
                                            id={`${idPrefix}_main_checkbox_rw`}
                                        />
                                    }
                                    label={<Typography noWrap>{t("users.assign_shares_modal.table.header.rw")}</Typography>}
                                />
                            </TableCell>
                            <TableCell className={classes.tableHeadLabel}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={onMainCheckboxClick(RO)}
                                            checked={isMainCheckboxChecked(RO)}
                                            value={RO}
                                            indeterminate={isMainCheckboxIndeterminate(RO)}
                                            id={`${idPrefix}_main_checkbox_ro`}
                                        />
                                    }
                                    label={<Typography noWrap>{t("users.assign_shares_modal.table.header.ro")}</Typography>}
                                />
                            </TableCell>
                            <TableCell className={classes.tableHeadLabel}>
                                <Typography noWrap>{t("users.assign_shares_modal.table.header.custom")}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.sharesRootAccess.length ? (
                            <React.Fragment>
                                {state.sharesRootAccess.map((access) => {
                                    return (
                                        <AccessRow
                                            key={access.shareName + access.access + access.ip}
                                            access={access}
                                            getIsChecked={getIsChecked}
                                            onCheck={onCheck}
                                            openCustomIfNeeded={openCustomIfNeeded}
                                            parentId={idPrefix}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        ) : null}
                    </TableBody>
                </Table>
            ) : (
                <EmptyCardPlaceholder
                    inProgress={state.inProgress}
                    message={t("users.assign_shares_modal.no_shares.placeholder")}
                />
            )}

            <UnsavedChangesDialog open={unsavedChangesModal.isOpen} onClose={unsavedChangesModal.close} onConfirm={handleClose} />
            <SaveChangesDialog
                open={saveChangesModal.isOpen}
                onClose={saveChangesModal.close}
                onSave={onSaveChangesDialogConfirm}
                onCancel={onSaveChangesDialogCancel}
            />
        </Dialog>
    ));
};

export default observer(AssignSharesModal);
