import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => ({
    adminRow: {
        padding: theme.spacing(4, 6),
        width: "100%",
        margin: 0,
        "&:hover": {
            backgroundColor: hexToRgba(theme.palette.primary.main, 0.05)
        },
        "& svg path": {
            fill: theme.palette.grey[300]
        }
    },
    labelText: {
        "&&": {
            fontSize: 16,
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.5,
            letterSpacing: 0.2
        }
    },
    trashIconCell: {
        cursor: "pointer"
    }
}));
