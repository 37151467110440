import React from "react";
import { Table, TableCell, TableHead, TableRow, TableBody, Box, Grid, Typography, Button } from "@material-ui/core";
import TableCellWithIcon from "components/TableCellWithIcon";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./EditAclFtpShareModal.styles";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import Dialog from "components/Dialog";
import { USERS_LIST_GROUP_MEMBERS_NAME } from "const/sortColumnConst";
import TableSortLabel from "components/TableSortLabel";
import createFields from "./createFields";
import createForm from "utils/createForm";
import SearchLine from "./components/SearchLine";
import TrashIcon from "assets/TrashIcon";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import LocalUserIcon from "assets/LocalUserIcon";
import { toJS } from "mobx";
import useStoreByIp from "hooks/useStoreByIp";
import { useModal } from "hooks/useModal";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { useObserveChangeField } from "hooks/useObserveChangeField";
import { useStore } from "hooks/useStore";

const EDIT_ACL_FTP_SHARE_MODAL_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_edit_acl_ftp_share_modal`;

const EditAclFtpShareModal = ({ open, onClose, afterSubmitCallback, ip }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { userStore },
    } = useStore();
    const { aclStore, shareStore } = useStoreByIp({ ip });

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
            options: {
                validateOnInit: false,
                validateOnBlur: false,
                showErrorsOnReset: false,
            },
        }),
        selectedUsers: [],
        isSearchInProgress: false,
        isSubmitted: false,
    }));

    const unsavedChangesModal = useModal();
    const observerFieldName = useObserveChangeField(state.form.$("name"));

    const selectUser = (name, domain) => {
        if (isUserAlreadySelected(name)) {
            removeUser(name)();
        } else {
            state.selectedUsers.unshift({ domain, name });
            aclStore.setSelectedAllowedUsersGroups(state.selectedUsers);
            state.isSubmitted = false;
        }
    };

    const isUserAlreadySelected = (name) => {
        return state.selectedUsers.some((user) => user.name === name);
    };

    const removeUser = (name) => () => {
        const userIndex = state.selectedUsers.findIndex((user) => user.name === name);
        userIndex !== -1 && state.selectedUsers.splice(userIndex, 1);
        aclStore.setSelectedAllowedUsersGroups(state.selectedUsers);
        state.isSubmitted = false;
    };

    const shareName = aclStore.currentShareName || shareStore.currentCheckedShareName || "";
    const shareNameWithPrefix = aclStore.currentShareNameWithPrefix || shareStore.currentCheckedShareNameWithPrefix || "";

    const onEnter = async () => {
        state.form.reset();
        observerFieldName.reset();
        state.inProgress = false;
        state.isSubmitted = true;
        state.isSearchInProgress = true;
        await aclStore.fetchAllowedUsersGroups({ shareName });
        state.selectedUsers = aclStore.allowedUsersGroups?.map((user) => user.id);
        aclStore.setSelectedAllowedUsersGroups(state.selectedUsers);
        state.isSearchInProgress = false;
        unsavedChangesModal.close();
    };

    const onSubmit = async (e) => {
        state.form.onSubmit(e);

        if (observerFieldName.hasChange) {
            state.form.$("name").invalidate(t("common.edit_permission_modal.select.error"));
            return;
        }

        if (!state.form.isValid) return;

        state.inProgress = true;
        const users = toJS(state.selectedUsers);
        const res = await aclStore.setAllowedUsersGroups({
            shareName,
            allowed: users,
        });
        if (res) {
            state.isSubmitted = true;
            state.inProgress = false;
        }
        return res;
    };

    const onAdd = async (users) => {
        const trustedDomains = await userStore.getTustedDomains();
        const localDomain = trustedDomains.data[0];
        Array.from(users, (user) => state.selectedUsers.unshift({ domain: localDomain, name: user }));
        observerFieldName.reset();
        return true;
    };

    const onKeyDown = (e) => {
        e.key === "Escape" && onClose();
    };

    const closeIfNeeded = () => {
        if (state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const changeSorting = (column) => () => aclStore.groupMembersChangeSorting(column);

    return useObserver(() => (
        <Dialog
            afterSubmitCallback={afterSubmitCallback}
            maxWidth={"md"}
            onKeyDown={onKeyDown}
            withoutPaddings
            open={open}
            onClose={closeIfNeeded}
            onEnter={onEnter}
            onSubmit={onSubmit}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${EDIT_ACL_FTP_SHARE_MODAL_ID_PREFIX}_save`}
            title={t("share.shared_pool_card.edit_nfc_acl", {
                name: shareNameWithPrefix,
            })}
            buttons={
                <Button
                    onClick={onClose}
                    variant={"contained"}
                    color={"secondary"}
                    id={`${EDIT_ACL_FTP_SHARE_MODAL_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
            additionalTitle={
                <Box pt={4} pb={4} pr={6} pl={6}>
                    <Grid container wrap={"nowrap"} alignItems={"center"} justify={"space-between"}>
                        <Grid item xs={3}>
                            <Typography>{t("share.shared_pool_card.edit_ftp_acl.search_line.placeholder")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <SearchLine
                                onSelectUser={observerFieldName.reset}
                                ip={ip}
                                form={state.form}
                                isOptionChecked={isUserAlreadySelected}
                                onSelect={selectUser}
                                id={`${EDIT_ACL_FTP_SHARE_MODAL_ID_PREFIX}_search_line`}
                                onAdd={onAdd}
                            />
                        </Grid>
                    </Grid>
                </Box>
            }
        >
            {aclStore.sortedAllowedUsersGroups?.length > 0 ? (
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={aclStore.groupMembersOrderBy === USERS_LIST_GROUP_MEMBERS_NAME}
                                    onClick={changeSorting(USERS_LIST_GROUP_MEMBERS_NAME)}
                                    direction={aclStore.groupMembersOrder}
                                    id={`${EDIT_ACL_FTP_SHARE_MODAL_ID_PREFIX}_name_sort`}
                                >
                                    {t("users.search_results.table.column.name")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <Typography variant={"subtitle1"}>{t("users.search_results.table.column.type")}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {aclStore.sortedAllowedUsersGroups?.map((user) => {
                            return (
                                <TableRow className={classes.userRow} key={`${user.name} ${Math.random()}`}>
                                    <TableCell>{user.name}</TableCell>
                                    <TableCellWithIcon align="left" icon={<LocalUserIcon />}>
                                        {t("users.common.local_user")}
                                    </TableCellWithIcon>
                                    <TableCell
                                        onClick={removeUser(user.name, user.domain)}
                                        className={classes.trashIconCell}
                                        align={"right"}
                                        width={"10%"}
                                    >
                                        <TrashIcon />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <EmptyCardPlaceholder
                    inProgress={state.isSearchInProgress}
                    message={t("share.shared_pool_card.edit_ftp_acl.content.placeholder")}
                />
            )}
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default EditAclFtpShareModal;
