import React, { useEffect } from "react";
import { ButtonBase, Grid } from "@material-ui/core";
import { useLocalStore, useObserver } from "mobx-react";
import { useStyles } from "./Pools.style";
import PlusIcon from "assets/PlusIcon";
import Pool from "../Pool";
import CreatePoolDialog from "../CreatePoolDialog";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import NoContentContainer from "../NoContentContainer";
import NoPoolGroupIcon from "assets/NoPoolGroupIcon";
import DataCard from "components/DataCard";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { POOLS_CARD } from "const/widgetNameConst";
import MoreMenu from "components/MoreMenu";
import PoolDrawer from "../PoolDrawer";
import useStoreByIp from "hooks/useStoreByIp";
import useEvoPrefix from "hooks/useEvoPrefix";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import HideOverlay from "components/HideOverlay/HideOverlay";

const POOLS_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_pools`;

const Pools = ({ ip }) => {
    const classes = useStyles();
    const evoPrefix = useEvoPrefix({ ip });
    const { t } = useTranslation();
    const { poolsStore } = useStoreByIp({ ip });

    const state = useLocalStore(() => ({
        isModalOpen: false,
    }));

    useEffect(() => {
        poolsStore.fetchPools();
        poolsStore.fetchFreeDrives();
    }, []);

    const hasFreeDrives = () => {
        return poolsStore.freeDrives && poolsStore.freeDrives.length;
    };

    const openCreatePoolDialog = () => {
        state.isModalOpen = true;
    };

    const closeCreatePoolDialog = () => {
        state.isModalOpen = false;
    };

    return useObserver(() => (
        <DataCard
            headerControl={[
                <HideOverlay show={!ip} key={`data_card-header_control-control-1`}>
                    <MoreMenu id={`${POOLS_ID_PREFIX}_add_to_home`}>
                        <HomeCardActionMenuItem widget={POOLS_CARD} />
                    </MoreMenu>
                </HideOverlay>,
                <ButtonBase
                    id={`${POOLS_ID_PREFIX}_open_create_pool_dialog`}
                    disabled={!hasFreeDrives()}
                    onClick={openCreatePoolDialog}
                    key={`data_card-header_control-control-2`}
                >
                    <PlusIcon className={clsx(!hasFreeDrives() && classes.disabled)} />
                </ButtonBase>,
            ]}
            title={`${evoPrefix}${t("disks_and_pools.pools_card.title")}`}
            headerDivider
            autoHeight
        >
            <PoolDrawer />
            {poolsStore.sortedPools.length ? (
                <Grid container spacing={4}>
                    {poolsStore.sortedPools.map((pool) => (
                        <Grid item key={pool.pool} xs={6} id="expandable">
                            <Pool id={`${POOLS_ID_PREFIX}_pool_${pool.pool}`} pool={pool} ip={ip} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <NoContentContainer
                    title={t("disks_and_pools.no_content.pool_not_created")}
                    content={
                        hasFreeDrives()
                            ? t("disks_and_pools.no_content.create_first_pool")
                            : t("disks_and_pools.no_content.no_free_drives")
                    }
                    buttonText={t("disks_and_pools.no_content.button.create_pool")}
                    hideButton={!hasFreeDrives()}
                    icon={<NoPoolGroupIcon />}
                    onClick={openCreatePoolDialog}
                    id={`${POOLS_ID_PREFIX}_no_content`}
                />
            )}
            <CreatePoolDialog open={state.isModalOpen} closeModal={closeCreatePoolDialog} ip={ip} />
        </DataCard>
    ));
};

export default Pools;
