import React, { useContext, useEffect } from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import SavedInputIndicator from "components/SavedInputIndicator";
import { useTranslation } from "react-i18next";
import { Context } from "store";
import { useLocalStore, useObserver } from "mobx-react-lite";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { ALERTS_CARD } from "const/widgetNameConst";
import SetupAlertsDialog from "../SetupAlertsDialog";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { useStyles } from "./AlertsCard.styles";

const ALERTS_CARD_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_alerts_card`;

const AlertsCard = () => {
    const { t } = useTranslation();
    const {
        store: { alertsStore }
    } = useContext(Context);
    const classes = useStyles();
    useEffect(() => {
        alertsStore.fetchNotifications();
    }, []);
    const state = useLocalStore(() => ({
        dialogOpen: false
    }));
    const openEditDialog = () => {
        state.dialogOpen = true;
    };
    const closeEditDialog = () => {
        state.dialogOpen = false;
    };
    const renderRow = ({ name, emailEnabled, audioEnabled, snmpEnabled }) => {
        return (
            <TableRow key={name} className={classes.row}>
                <TableCell>{t(`alerts.table.${name}`)}</TableCell>
                <TableCell>
                    <SavedInputIndicator hideLabel enabled={emailEnabled} />
                </TableCell>
                <TableCell>
                    <SavedInputIndicator hideLabel enabled={audioEnabled} />
                </TableCell>
                <TableCell>
                    <SavedInputIndicator hideLabel enabled={snmpEnabled} />
                </TableCell>
            </TableRow>
        );
    };
    return useObserver(() => (
        <>
            <SetupAlertsDialog open={state.dialogOpen} onClose={closeEditDialog} />
            <DataReadonlyCard
                onClick={openEditDialog}
                title={t("alerts.title")}
                emptyPadding
                headerDivider
                control={
                    <MoreMenu id={`${ALERTS_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={ALERTS_CARD} />
                    </MoreMenu>
                }
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("alerts.table.title.option")}</TableCell>
                            <TableCell>{t("alerts.table.title.email")}</TableCell>
                            <TableCell>{t("alerts.table.title.audio")}</TableCell>
                            <TableCell>{t("alerts.table.title.snmp")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {alertsStore.notificationKeys
                            // the field is hidden for version 7.1
                            .filter(name => name !== "clusterCriticalEventsDetected")
                            .map(name => [
                                {
                                    name,
                                    emailEnabled: alertsStore.notifications[name]?.emailEnabled,
                                    audioEnabled: alertsStore.notifications[name]?.audioEnabled,
                                    snmpEnabled: alertsStore.notifications[name]?.snmpEnabled
                                }
                            ])
                            .flat(1)
                            .map(row => renderRow(row))}
                    </TableBody>
                </Table>
            </DataReadonlyCard>
        </>
    ));
};

export default AlertsCard;
