import React, { useEffect } from "react";
import { observer, useLocalStore, useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import SideBulkBar from "components/SideBulkBar";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import WorldIcon from "assets/WorldIcon";
import WorldOffIcon from "assets/WorldOffIcon";
import TrashIcon from "assets/TrashIcon";
import DeleteDiskDialog from "../DeleteDiskDialog";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { useStore } from "hooks/useStore";
import { useSyncDisksAndPools } from "pages/DisksAndPools/DisksAndPools.hooks";

const BULK_OPERATIONS_BAR_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_bulk_operations_bar`;

const drawerName = "DiskAndPoolsBulkOperationsBar";
const BulkOperationsBar = () => {
    const { t } = useTranslation();
    const {
        store: { volumeDrawerStore, uiStore },
    } = useStore();
    const state = useLocalStore(() => ({
        openDeleteDialog: false,
        deleteDiskInProgress: false,
        isRemoveVolumesInProgress: false,
    }));

    const onClose = () => {
        volumeDrawerStore.clearCheckedVolumes();
        volumeDrawerStore.clearCheckedQueueVolumes();
    };

    useEffect(() => {
        if (volumeDrawerStore.allCheckedVolumes.length > 0 || volumeDrawerStore.allCheckedQueueVolumes.length > 0) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, onClose);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
            uiStore.changeDrawer();
        }
    }, [volumeDrawerStore.allCheckedVolumes.length, volumeDrawerStore.allCheckedQueueVolumes.length]);

    const changeOnlineStatus = () => {
        volumeDrawerStore.setVolumesOnline();
        volumeDrawerStore.clearCheckedVolumes();
        volumeDrawerStore.clearCheckedQueueVolumes();
    };

    const sendMessage = useSyncDisksAndPools();

    const removeVolumes = async () => {
        state.isRemoveVolumesInProgress = true;
        await volumeDrawerStore.removeVolumes();
        state.isRemoveVolumesInProgress = false;
        sendMessage();
    };

    const openDeleteDiskDialog = () => {
        state.openDeleteDialog = true;
    };
    const closeDeleteDiskDialog = () => {
        state.openDeleteDialog = false;
    };
    const renderOnlineButton = () => {
        if (volumeDrawerStore.allCheckedVolumes.length !== 0) {
            return (
                <BulkBarFlatButton
                    id={`${BULK_OPERATIONS_BAR_ID_PREFIX}_change_online_status`}
                    onClick={changeOnlineStatus}
                    icon={volumeDrawerStore.someNotOffline ? <WorldOffIcon /> : <WorldIcon />}
                >
                    {volumeDrawerStore.someNotOffline
                        ? t("disks_and_pools.logical_disks.bulk_operations_bar.button.set_offline")
                        : t("disks_and_pools.logical_disks.bulk_operations_bar.button.set_online")}
                </BulkBarFlatButton>
            );
        }
        return null;
    };

    return useObserver(() => (
        <SideBulkBar
            itemsCount={volumeDrawerStore.allCheckedVolumesCount}
            onClose={onClose}
            isOpen={uiStore.openedDrawer === drawerName}
        >
            <DeleteDiskDialog
                onClose={closeDeleteDiskDialog}
                diskName={volumeDrawerStore.allCheckedVolumes.concat(volumeDrawerStore.allCheckedQueueVolumes)}
                open={state.openDeleteDialog}
                onDeleteAction={removeVolumes}
                inProgress={state.isRemoveVolumesInProgress}
            />
            {renderOnlineButton()}
            <BulkBarFlatButton
                id={`${BULK_OPERATIONS_BAR_ID_PREFIX}_delete`}
                onClick={openDeleteDiskDialog}
                icon={<TrashIcon />}
                errorColor
            >
                {t("common.button.delete")}
            </BulkBarFlatButton>
        </SideBulkBar>
    ));
};

export default observer(BulkOperationsBar);
