import { types } from "mobx-state-tree";

import AffectedPools from "api/drive_replace/Types/AffectedPools";

const GetAffectedPoolsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(AffectedPools)
});

export default GetAffectedPoolsResult;
