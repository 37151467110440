import React from "react";

const OptionsIcon = (props) => {
    return (
        <svg
            width="24"
            height="24"
            version="1.1"
            viewBox="0 0 650 550"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <path d="m463.68 264.88c0-3.3594-2.8008-6.1602-6.1602-6.1602h-86.238v-86.801c0-3.3594-2.8008-6.1602-6.1602-6.1602h-30.238c-3.3594 0-6.1602 2.8008-6.1602 6.1602v86.801h-86.801c-3.3594 0-6.1602 2.8008-6.1602 6.1602v30.238c0 3.3594 2.8008 6.1602 6.1602 6.1602h86.801v86.801c0 3.3594 2.8008 6.1602 6.1602 6.1602h30.238c3.3594 0 6.1602-2.8008 6.1602-6.1602v-86.797h86.801c3.3594 0 6.1602-2.8008 6.1602-6.1602z" />
            <path d="m561.12 67.199h-422.24c-30.238 0-54.879 24.641-54.879 54.879v316.4c0 30.238 24.641 54.879 54.879 54.879h422.8c30.238 0 54.879-24.641 54.879-54.879v-316.4c-0.55859-30.238-25.199-54.879-55.438-54.879zm12.32 370.72c0 6.7188-5.6016 12.32-12.32 12.32l-422.24-0.003907c-6.7188 0-12.32-5.6016-12.32-12.32v-315.84c0-6.7188 5.6016-12.32 12.32-12.32h422.8c6.7188 0 12.32 5.6016 12.32 12.32v315.84z" />
        </svg>
    );
};

export default OptionsIcon;
