import { types } from "mobx-state-tree";

import DatabaseLocation from "api/system_components/postgres/Types/DatabaseLocation";

const GetDatabaseLocationResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(DatabaseLocation)
});

export default GetDatabaseLocationResult;
