import React, { useContext } from "react";
import { useLocalStore, useObserver, observer } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { useStyles } from "./PluginDrawer.styles";
import { Grid, Typography } from "@material-ui/core";
import TrashIcon from "assets/TrashIcon";
import BrowserIcon from "assets/BrowserIcon";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import WarningDialog from "components/WarningDialog/WarningDialog";

const drawerName = "pluginDrawer";

const PluginDrawer = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { pluginsStore, uiStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isUninstallDialogOpen: false,
        uninstallInProgress: false
    }));

    const closeDrawer = () => {
        closeUninstallDialog();
        pluginsStore.setCurrentPluginName(null);
    };

    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: pluginsStore.currentPluginName });

    const openUninstallDialog = () => {
        state.isUninstallDialogOpen = true;
    };
    const closeUninstallDialog = () => {
        state.isUninstallDialogOpen = false;
    };

    const uninstallPlugin = async () => {
        state.isUninstallDialogOpen = false;

        state.uninstallInProgress = true;
        await pluginsStore.uninstallPlugin(pluginsStore.currentPlugin.id);
        state.uninstallInProgress = false;

        closeDrawer();
    };

    const renderActions = () => {
        return (
            <Grid item>
                <BulkBarFlatButton
                    errorColor
                    inProgress={state.uninstallInProgress}
                    onClick={openUninstallDialog}
                    icon={<TrashIcon />}
                    disabled={pluginsStore.isUninstallingPlugin}
                >
                    {t("support.start.installed_plugins.actions.button.uninstall")}
                </BulkBarFlatButton>
            </Grid>
        );
    };

    const renderDetails = () => {
        return (
            <Grid container direction={"column"} wrap={"nowrap"} className={classes.detailsContainer} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("support.start.installed_plugins.details.label.name")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{pluginsStore.currentPlugin?.name}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"}>
                    <Grid item xs={6}>
                        <Typography>{t("support.start.installed_plugins.details.label.version")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{pluginsStore.currentPlugin?.pluginVersion}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"}>
                    <Grid item xs={6}>
                        <Typography>{t("support.start.installed_plugins.details.label.description")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{pluginsStore.currentPlugin?.description}</Typography>
                    </Grid>
                </Grid>
                {pluginsStore.currentPlugin?.dataShare && (
                    <Grid item container alignItems={"baseline"}>
                        <Grid item xs={6}>
                            <Typography>{t("support.start.installed_plugins.details.label.data_location")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>{pluginsStore.currentPlugin?.dataShare}</Typography>
                        </Grid>
                    </Grid>
                )}
                {pluginsStore.currentPlugin?.backupShare && (
                    <Grid item container alignItems={"baseline"}>
                        <Grid item xs={6}>
                            <Typography>{t("support.start.installed_plugins.details.label.backup_location")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>{pluginsStore.currentPlugin?.backupShare}</Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    };

    const drawerCloseHandler = () => {
        if (uiStore.openedDrawer !== drawerName) {
            state.showDetails = true;
        }
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            drawerName={drawerName}
            onAnimationEnd={drawerCloseHandler}
            title={pluginsStore.currentPluginName}
            icon={<BrowserIcon />}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            modals={
                pluginsStore.currentPlugin && (
                    <WarningDialog
                        title={t("support.start.installed_plugins.actions.confirm_uninstall.title", {
                            name: pluginsStore.currentPluginName
                        })}
                        open={state.isUninstallDialogOpen}
                        submitBtnLabel={t("support.start.installed_plugins.actions.confirm_uninstall.yes")}
                        onClose={closeUninstallDialog}
                        submit={uninstallPlugin}
                        content={t("support.start.installed_plugins.actions.confirm_uninstall.confirm_question", {
                            name: pluginsStore.currentPluginName
                        })}
                        cancelBtnLabel={t("support.start.installed_plugins.actions.confirm_uninstall.no")}
                    />
                )
            }
        />
    ));
};

export default observer(PluginDrawer);
