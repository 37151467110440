import React from "react";
import SavedInputPreview from "components/SavedInputPreview";
import { useStyles } from "./EditProxyIndicator.styles";
import { useTranslation } from "react-i18next";
import { DISABLED, ENABLED, MANUAL } from "api/slingshot/indexing_config/Types/ConfigType";

const EditProxyIndicator = ({ status, hideLabel }) => {
    const classes = useStyles({ status });
    const { t } = useTranslation();
    const GenerateText = ({ status }) => {
        switch (status) {
            case ENABLED:
                return t("slingshot.index_proxy.edit_proxy_indicator.enabled");
            case DISABLED:
                return t("slingshot.index_proxy.edit_proxy_indicator.disabled");
            case MANUAL:
                return t("slingshot.index_proxy.edit_proxy_indicator.manual");
            default:
                return t("slingshot.index_proxy.edit_proxy_indicator.disabled");
        }
    };
    return (
        <SavedInputPreview className={classes.container} spacing={2} icon={<div className={classes.oval} />}>
            {!hideLabel ? <GenerateText status={status} /> : null}
        </SavedInputPreview>
    );
};

export default EditProxyIndicator;
