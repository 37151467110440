import Request from "api/Request";

const GetNetIfaces = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "get_net_ifaces"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetNetIfaces;
