import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import Select from "components/MobxForm/Select";
import createForm from "utils/createForm";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowTextInput from "components/RowTextInput";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import Switch from "components/MobxForm/Switch";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import { ADVANCED_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const GRAPHS_SETTINGS_DIALOG_ID_PREFIX = `${ADVANCED_PAGE_ID_PREFIX}_graphs_settings_dialog`;

const GraphsSettingsDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const {
        store: { uiStore }
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields() }),
        inProgress: false,
        isSubmitted: false,

        get startPeriodOptions() {
            return [
                { label: t("advanced.graphs_settings_card.minutes_plural", { count: 15 }), value: 15 * 60 },
                { label: t("advanced.graphs_settings_card.minutes_plural", { count: 30 }), value: 30 * 60 },
                { label: t("advanced.graphs_settings_card.hour", { count: 1 }), value: 1 * 60 * 60 }
            ];
        }
    }));

    const updateForm = () => {
        state.form.$("graphStartPeriod").set("default", uiStore.parameters?.graphStartPeriod || "");
        state.form.$("graphAutoscaling").set("default", uiStore.parameters?.graphAutoscaling || false);
        state.form.reset();
        state.isSubmitted = false;
    };

    const unsavedChangesModal = useModal();

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted === true) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const submit = async () => {
        state.inProgress = true;
        const res = await uiStore.patchUiParameters(state.form.values());
        state.inProgress = false;
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    return useObserver(() => (
        <Dialog
            isDataChanged={!state.form.isDefault}
            onEnter={updateForm}
            title={t("advanced.edit_graphs_settings_card.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${GRAPHS_SETTINGS_DIALOG_ID_PREFIX}_save`}
            open={open}
            onSubmit={submit}
            onClose={closeIfNeeded}
            inProgress={state.inProgress}
            buttons={
                <Button
                    key="cancelBtn"
                    onClick={onClose}
                    variant={"contained"}
                    color="secondary"
                    id={`${GRAPHS_SETTINGS_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <RowTextInput
                label={t("advanced.graphs_settings_card.label.show_last")}
                control={
                    <Select
                        options={state.startPeriodOptions}
                        field={state.form.$("graphStartPeriod")}
                        id={`${GRAPHS_SETTINGS_DIALOG_ID_PREFIX}_show_last`}
                    />
                }
            />
            <RowControlLabelSwitch
                label={t("advanced.graphs_settings_card.label.autoscaling")}
                control={
                    <Switch field={state.form.$("graphAutoscaling")} id={`${GRAPHS_SETTINGS_DIALOG_ID_PREFIX}_autoscaling`} />
                }
            />
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default GraphsSettingsDialog;
