import React, { useEffect } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { Grid, Typography } from "@material-ui/core";
import { CLIENT_DATE_FORMAT } from "const";
import { useStyles } from "../../HeaderToolbar.style";
import { autorun } from "mobx";
import { useStore } from "hooks/useStore";
import { useDebounce } from "hooks/useDebounce";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const EvoInfoTime = () => {
    const classes = useStyles();
    const {
        store: { evoSettingsStore, authStore, bootProgressStore },
    } = useStore();

    const handleSettingStore = async () => {
        const choiceFetchInfoFormat = async () => {
            if (authStore.isAuthorized) {
                await evoSettingsStore.fetchEvoInfo();
                evoSettingsStore.startTimerFetch();
                return;
            }
            if (bootProgressStore.isRunning) return await evoSettingsStore.restFetchSystemInfo();
            return null;
        };

        await choiceFetchInfoFormat();
    };

    const debounceSettingStore = useDebounce(handleSettingStore, 100, [bootProgressStore.isRunning, authStore.isAuthorized]);

    useEffect(() => {
        autorun(() => {
            debounceSettingStore.run();
        });

        return evoSettingsStore.stopTimer;
    }, []);

    return (
        <Grid container direction={"column"} alignItems={"flex-start"} justify={"center"} spacing={1}>
            <Grid item>
                <Typography className={classes.headerInfo} color={"textSecondary"}>
                    {moment.unix(evoSettingsStore.evoInfoTime).format(getLanguageDateFormat(CLIENT_DATE_FORMAT))}
                </Typography>
            </Grid>
            <Grid item>
                <Typography className={classes.headerInfo} color={"textSecondary"}>
                    {evoSettingsStore.evoVersion}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default observer(EvoInfoTime);
