import React, { useEffect } from "react";
import SetupTimeSettingsDialog from "../SetupTimeSettingsDialog";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography } from "@material-ui/core";
import SavedInputPreview from "components/SavedInputPreview";
import moment from "moment";
import { CLIENT_DATE_FORMAT_ONLY_DATE, CLIENT_DATE_FORMAT_ONLY_TIME } from "const";
import { useLocalStore, useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TIME_SETTINGS_CARD } from "const/widgetNameConst";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import SavedInputIndicator from "components/SavedInputIndicator";
import { useStore } from "hooks/useStore";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const TimeSettingsCard = () => {
    const { t } = useTranslation();
    const {
        store: { timeSettingsStore, authStore },
    } = useStore();
    useEffect(() => {
        (async () => {
            await timeSettingsStore.fetchTimeSettings();
            timeSettingsStore.fetchTimeZone();
            timeSettingsStore.startTimerFetch();
        })();
        return timeSettingsStore.stopTimer;
    }, []);
    const state = useLocalStore(() => ({
        openEditDialog: false,
    }));
    const closeDialog = () => {
        state.openEditDialog = false;
    };
    const openDialog = () => {
        state.openEditDialog = true;
    };
    return useObserver(() => (
        <>
            <SetupTimeSettingsDialog open={state.openEditDialog} closeDialog={closeDialog} />
            <DataReadonlyCard
                control={
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={TIME_SETTINGS_CARD} />
                    </MoreMenu>
                }
                title={t("time_settings.card_title")}
                disabled={!authStore.isAuthorized}
                onClick={openDialog}
                headerDivider
                autoHeight
            >
                <Grid container direction={"column"} spacing={4}>
                    <Grid item container>
                        <Grid xs={6} item>
                            <Typography>{t("time_settings.time_zone")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{timeSettingsStore.timezone}</SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid xs={6} item>
                            <Typography>{t("time_settings.time")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>
                                {moment
                                    .unix(timeSettingsStore.localTime)
                                    .format(getLanguageDateFormat(CLIENT_DATE_FORMAT_ONLY_DATE))}
                            </SavedInputPreview>
                            <SavedInputPreview>
                                {moment.unix(timeSettingsStore.localTime).format(CLIENT_DATE_FORMAT_ONLY_TIME)}
                            </SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid xs={6} item>
                            <Typography>{t("time_settings.label_client")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={timeSettingsStore.enableClient} />
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid xs={6} item>
                            <Typography>{t("time_settings.server")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{timeSettingsStore.server}</SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid xs={6} item>
                            <Typography>{t("time_settings.label_service")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={timeSettingsStore.enableServer} />
                        </Grid>
                    </Grid>
                </Grid>
            </DataReadonlyCard>
        </>
    ));
};

export default TimeSettingsCard;
