import { flow, getParent, types } from "mobx-state-tree";
import Socket from "websocket";
import GetJobs from "api/slingshot/sync_jobs/Requests/GetJobs";
import GetJobsResult from "api/slingshot/sync_jobs/Responses/GetJobsResult";
import GetJobsArgumentsTypes from "api/slingshot/sync_jobs/Types/GetJobsArgumentsTypes";
import { ASC, DESC, INSTANCES_ID, PROCESSED_FILE_ID, REPLICATION_JOBS_JOB } from "const/sortColumnConst";
import { JOB_STATUS } from "const/replicationsJobsConst";
import CreateJob from "api/slingshot/sync_jobs/Requests/CreateJob";
import PerformJobCommand from "api/slingshot/sync_jobs/Requests/PerformJobCommand";
import RemoveJob from "api/slingshot/sync_jobs/Requests/RemoveJob";
import GetJobSummary from "api/slingshot/sync_jobs/Requests/GetJobSummary";
import GetJobSummaryResult from "api/slingshot/sync_jobs/Responses/GetJobSummaryResult";
import GetServiceState from "api/slingshot/sync_jobs/Requests/GetServiceState";
import GetServiceStateResult from "api/slingshot/sync_jobs/Responses/GetServiceStateResult";
import SetServiceState from "api/slingshot/sync_jobs/Requests/SetServiceState";
import GetJob from "api/slingshot/sync_jobs/Requests/GetJob";
import UpdateJob from "api/slingshot/sync_jobs/Requests/UpdateJob";
import GetJobResult from "api/slingshot/sync_jobs/Responses/GetJobResult";
import GetInstances from "api/slingshot/automations/Requests/GetInstances";
import GetInstancesResult from "api/slingshot/automations/Responses/GetInstancesResult";
import GetInstancesArguments from "api/slingshot/automations/Types/GetInstancesArguments";
import GetProcessedFiles from "api/slingshot/automations/Requests/GetProcessedFiles";
import GetProcessedFilesResult from "api/slingshot/automations/Responses/GetProcessedFilesResult";
import axios from "api/AxiosCommonRequest";
import { FILE_DOWNLOAD_URL } from "api/restRoutes";

const GET_JOBS_ARGUMENTS_DEFAULT = {
    sort: REPLICATION_JOBS_JOB,
    sort_dir: ASC.toUpperCase(),
    page: 0,
    limit: 10
};

const GET_INSTANCES_ARGUMENTS_DEFAULT = {
    sort: INSTANCES_ID,
    sort_dir: ASC.toUpperCase(),
    page: 0,
    limit: 10,
    includeCtrlEvents: false
};

const GET_PROCESSED_FILES_ARGUMENTS_DEFAULT = {
    sort: PROCESSED_FILE_ID,
    sort_dir: ASC.toUpperCase(),
    page: 0,
    limit: 10,
    includeCtrlEvents: false
};

const SearchType = types.model({
    sort: types.string,
    sort_dir: types.string,
    page: types.number,
    limit: types.number
});

const SearchInstanceType = types.model({
    sort: types.string,
    sort_dir: types.string,
    page: types.number,
    limit: types.number,
    includeCtrlEvents: types.boolean
});

const SyncJobsStore = types
    .model({
        jobsStore: types.maybe(GetJobsResult),
        getJobsArguments: types.optional(SearchType, GET_JOBS_ARGUMENTS_DEFAULT),
        currentJobId: types.maybeNull(types.number),
        jobSummaryStore: types.maybe(GetJobSummaryResult),
        serviceStateStore: types.maybe(GetServiceStateResult),
        editableJobStore: types.maybe(GetJobResult),
        instancesStore: types.maybe(GetInstancesResult),
        getInstancesArguments: types.optional(SearchInstanceType, GET_INSTANCES_ARGUMENTS_DEFAULT),
        getProcessedFilesArguments: types.optional(SearchInstanceType, GET_PROCESSED_FILES_ARGUMENTS_DEFAULT),
        processedFilesStore: types.maybe(GetProcessedFilesResult),
        needToHideSucceedFiles: types.optional(types.boolean, false),
        errorConnectedDBus: types.optional(types.boolean, false)
    })
    .volatile(() => ({
        jobSummaryFile: null
    }))
    .views(self => ({
        get jobs() {
            return self.jobsStore?.data?.json?.jobs?.filter(job => !job.deleted) || [];
        },
        get jobsCount() {
            return self.jobsStore?.data?.json?.total || 0;
        },
        get currentJob() {
            return self.currentJobId && self.jobs.find(job => job.id === self.currentJobId);
        },
        get serviceState() {
            return self.serviceStateStore?.data?.json;
        },
        get currentEditableJob() {
            return self.editableJobStore?.data?.json;
        },
        get instances() {
            return self.instancesStore?.data?.json?.instances || [];
        },
        get instancesCount() {
            return self.instancesStore?.data?.json?.total || 0;
        },
        get processedFiles() {
            return self.processedFilesStore?.data?.json?.results || [];
        },
        get processedFilesCount() {
            return self.processedFilesStore?.data?.json?.total || 0;
        },
        get currentInstance() {
            return self.currentInstanceId && self.instances.find(ins => ins.id === self.currentInstanceId);
        },
        get walksForProcessedFiles() {
            const ids = [];
            if (self.currentInstance?.walks) {
                self.currentInstance.walks.forEach(walk => walk.type === "FILE_COLLECTION" && ids.push(walk.id));
            }
            return ids;
        },
        get isErrorConnectedDBus() {
            return self.errorConnectedDBus;
        }
    }))
    .actions(self => ({
        fetchJobs: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const payload = GetJobsArgumentsTypes.create({
                    sort: self.getJobsArguments.sort,
                    sort_dir: self.getJobsArguments.sort_dir,
                    offset: self.getJobsArguments.page * self.getJobsArguments.limit,
                    limit: self.getJobsArguments.limit
                });
                const req = GetJobs.create().init({ json: payload });
                self.jobsStore = yield Socket.send(req);
                return true;
            } catch (e) {
                switch (e.code) {
                    case 404:
                        self.errorConnectedDBus = true;
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
            }
        }),
        createJob: flow(function*(payload) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = CreateJob.create().init(payload);
                const res = yield Socket.send(req);
                return res?.data?.json && JSON.parse(res.data.json);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        performJobCommand: flow(function*({ jobId, cmd }) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = PerformJobCommand.create().init({ json: { jobId, cmd } });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        removeJob: flow(function*(jobId) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RemoveJob.create().init({ json: { jobId } });
                yield Socket.send(req);
                self.fetchJobs();
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getJobSummary: flow(function*(jobId) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetJobSummary.create().init({ json: { jobId } });
                self.jobSummaryStore = yield Socket.send(req);

                if (!self.jobSummaryStore?.data?.json?.exist) {
                    self.jobSummaryFile = null;
                    return false;
                }

                const config = { responseType: "html", params: { file: self.jobSummaryStore?.data?.json?.downloadPath } };
                const fileResponse = yield axios.get(FILE_DOWNLOAD_URL, config);
                self.jobSummaryFile = fileResponse?.data;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getServiceState: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetServiceState.create().init({ json: "{}" });
                self.serviceStateStore = yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setServiceState: flow(function*(option, value) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetServiceState.create().init({ json: { [option]: value } });
                self.serviceStateStore = yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getCurrentEditableJob: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetJob.create().init({ json: { jobId: self.currentJobId } });
                self.editableJobStore = yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateJob: flow(function*(job) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = UpdateJob.create().init(job);
                yield Socket.send(req);
                self.fetchJobs();
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getInstances: flow(function*(id) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const payload = GetInstancesArguments.create({
                    automationId: id,
                    sort: self.getInstancesArguments.sort,
                    sort_dir: self.getInstancesArguments.sort_dir,
                    offset: self.getInstancesArguments.page * self.getInstancesArguments.limit,
                    limit: self.getInstancesArguments.limit,
                    include_ctrl_events: self.getInstancesArguments.includeCtrlEvents
                });
                const req = GetInstances.create().init({ ...payload });
                self.instancesStore = yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getProcessedFiles: flow(function*(id) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const payload = {
                    automationId: id,
                    sort: self.getProcessedFilesArguments.sort,
                    sort_dir: self.getProcessedFilesArguments.sort_dir,
                    offset: self.getProcessedFilesArguments.page * self.getProcessedFilesArguments.limit,
                    limit: self.getProcessedFilesArguments.limit,
                    instanceId: self.currentInstanceId,
                    include_ctrl_events: self.getProcessedFilesArguments.includeCtrlEvents,
                    hide_succeed: self.needToHideSucceedFiles,
                    walk: self.walksForProcessedFiles
                };
                const req = GetProcessedFiles.create().init(payload);
                self.processedFilesStore = yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        changeSorting(column) {
            if (column === self.getJobsArguments.sort) {
                self.getJobsArguments.sort_dir =
                    self.getJobsArguments.sort_dir === DESC.toUpperCase() ? ASC.toUpperCase() : DESC.toUpperCase();
            } else {
                self.getJobsArguments.sort_dir = ASC.toUpperCase();
                self.getJobsArguments.sort = column;
            }
            self.fetchJobs();
        },
        updateLimit(limit) {
            self.getJobsArguments.limit = limit;
        },
        updatePage(page) {
            self.getJobsArguments.page = page;
            self.fetchJobs();
        },
        setCurrentJobId(id) {
            self.currentJobId = id;
        },
        setCurrentInstanceId(id) {
            self.currentInstanceId = id;
        },
        changeInstancesSorting(column) {
            const { automationsStore } = getParent(self);

            const instanceId = automationsStore.currentAutomationId || self.currentJobId || self.currentInstanceId;

            if (column === self.getInstancesArguments.sort) {
                self.getInstancesArguments.sort_dir =
                    self.getInstancesArguments.sort_dir === DESC.toUpperCase() ? ASC.toUpperCase() : DESC.toUpperCase();
            } else {
                self.getInstancesArguments.sort_dir = ASC.toUpperCase();
                self.getInstancesArguments.sort = column;
            }

            self.getInstances(instanceId);
        },
        updateInstancesLimit(limit) {
            self.getInstancesArguments.limit = limit;
        },
        updateInstancesPage(page) {
            const { automationsStore } = getParent(self);

            const instanceId = automationsStore.currentAutomationId || self.currentJobId || self.currentInstanceId;

            self.getInstancesArguments.page = page;
            self.getInstances(instanceId);
        },
        changeProcessedFilesSorting(column) {
            const { automationsStore } = getParent(self);
            const instanceId = automationsStore.currentAutomationId || self.currentJobId || self.currentInstanceId;

            if (column === self.getProcessedFilesArguments.sort) {
                self.getProcessedFilesArguments.sort_dir =
                    self.getProcessedFilesArguments.sort_dir === DESC.toUpperCase() ? ASC.toUpperCase() : DESC.toUpperCase();
            } else {
                self.getProcessedFilesArguments.sort_dir = ASC.toUpperCase();
                self.getProcessedFilesArguments.sort = column;
            }

            self.getProcessedFiles(instanceId);
        },
        updateProcessedFilesLimit(limit) {
            self.getProcessedFilesArguments.limit = limit;
        },
        updateProcessedFilesPage(page) {
            const { automationsStore } = getParent(self);
            const instanceId = automationsStore.currentAutomationId || self.currentJobId || self.currentInstanceId;

            self.getProcessedFilesArguments.page = page;
            self.getProcessedFiles(instanceId);
        },
        updateNeedToHideSucceedFiles: value => {
            const { automationsStore } = getParent(self);
            const instanceId = automationsStore.currentAutomationId || self.currentJobId || self.currentInstanceId;

            self.needToHideSucceedFiles = value;
            self.getProcessedFiles(instanceId);
        },
        resetNeedToHideSucceedFiles: () => {
            self.needToHideSucceedFiles = false;
        },
        updateJobStatus(data) {
            const updatedJob = self.jobs.find(job => job.id === data.jobId);
            updatedJob && (updatedJob.status = JOB_STATUS.active);
        },
        resetReplications() {
            self.jobsStore && (self.jobsStore.data = undefined);
        },
        resetErrors() {
            self.errorConnectedDBus = false;
        },
        enableErrorConnectedDBus() {
            self.errorConnectedDBus = true;
        }
    }));

export default SyncJobsStore;
