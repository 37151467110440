import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    accordionContainer: {
        padding: theme.spacing(4, 6)
    },
    expansionPanel: {
        padding: 0,
        margin: 0,
        boxShadow: "none",
        "&.Mui-expanded": {
            margin: 0
        }
    },
    expansionPanelContent: {
        "&&": {
            minHeight: 0,
            margin: 0,
            padding: theme.spacing(4, 6)
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
            display: "flex",
            alignItems: "center"
        }
    },
    expansionPanelExpanded: {
        padding: 0
    },
    expansionIcon: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        "& path": {
            fill: theme.palette.primary.main
        }
    },
    expansionIconClose: {
        transform: "rotate(180deg)",
        "& path": {
            fill: theme.palette.grey["300"]
        }
    },
    detailsRoot: {
        padding: 0
    },
    accordionButton: {
        padding: theme.spacing(4, 6, 4, 15)
    }
}));
