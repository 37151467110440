import { REGEX_IPV4 } from "const";
import i18n from "i18n";

const isIpv4Rule = {
    isIpv4: {
        function: (value) => value.match(REGEX_IPV4),
        message: i18n.t("rule.ipv4.format"),
    },
};

export default isIpv4Rule;
