import { types } from "mobx-state-tree";

import DhcpClientSettings from "api/net/Types/DhcpClientSettings";
import DhcpServerSettings from "api/net/Types/DhcpServerSettings";

const TYPES_ARRAY = ["nic_1", "nic10", "nic25"];

const NetInterface = types.model({
    port: types.string,
    ip: types.string,
    netmask: types.string,
    gateway: types.string,
    speed: types.string,
    status: types.string,
    mtu: types.number,
    metric: types.number,
    mac: types.string,
    dhcpClient: types.maybe(DhcpClientSettings),
    dhcpServer: types.maybe(DhcpServerSettings),
    flowcontrolEnforced: types.boolean,
    type: types.enumeration(TYPES_ARRAY),
    maxSpeedGbs: types.number,
    driver: types.string,
    hardwareDescription: types.string,
    builtin: types.boolean,
});

export default NetInterface;
