const createFields = () => {
    return [
        {
            name: "redirect",
            type: "checkbox",
            default: false
        }
    ];
};

export default createFields;
