import React from "react";

const BrickErrorIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="brickErrorIcon_a" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
                <linearGradient id="brickErrorIcon_b" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
                <linearGradient id="brickErrorIcon_c" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#6CB6FF"
                    stroke="url(#brickErrorIcon_a)"
                    d="M18.681 2.5H5.32c-.3 0-.586.09-.826.248s-.435.385-.553.661L1.758 8.5h20.484L20.06 3.41c-.118-.277-.313-.504-.553-.662-.24-.159-.526-.248-.826-.248z"
                />
                <path
                    fill="#85C3FF"
                    stroke="url(#brickErrorIcon_b)"
                    d="M21 7.5H3c-.414 0-.79.168-1.06.44-.272.27-.44.646-.44 1.06v6.5h21V9c0-.414-.168-.79-.44-1.06-.27-.272-.646-.44-1.06-.44z"
                />
                <path
                    fill="#85C3FF"
                    stroke="url(#brickErrorIcon_c)"
                    d="M22.5 14.5h-21V20c0 .414.168.79.44 1.06.27.272.646.44 1.06.44h18c.414 0 .79-.168 1.06-.44.272-.27.44-.646.44-1.06v-5.5z"
                />
                <circle cx="18" cy="18" r="5.5" fill="#E90F3F" stroke="#FF5E81" />
                <circle cx="5" cy="18" r="2" fill="#FFCD8C" />
                <circle cx="5" cy="11" r="2" fill="#FFCD8C" />
                <circle cx="9" cy="18" r="1" fill="#1E90FF" />
                <circle cx="9" cy="11" r="1" fill="#1E90FF" />
                <path
                    fill="#FFF"
                    d="M20.828 15.172c.391.39.391 1.023 0 1.414L19.414 18l1.414 1.414c.391.39.391 1.024 0 1.414-.39.391-1.023.391-1.414 0L18 19.414l-1.414 1.414c-.39.391-1.024.391-1.414 0-.391-.39-.391-1.023 0-1.414L16.586 18l-1.414-1.414c-.391-.39-.391-1.024 0-1.414.39-.391 1.023-.391 1.414 0L18 16.586l1.414-1.414c.39-.391 1.024-.391 1.414 0z"
                />
            </g>
        </svg>
    );
};

export default BrickErrorIcon;
