import React from "react";
import { useTranslation } from "react-i18next";
import { observer, useLocalStore } from "mobx-react";
import { useStore } from "hooks/useStore";
import { Grid } from "@material-ui/core";
import BulkBarFlatButton from "components/BulkBarFlatButton/BulkBarFlatButton";
import EditIcon from "assets/EditIcon";
import i18next from "i18next";
import TrashIcon from "assets/TrashIcon";
import UpgradeSupportIcon from "assets/UpgradeSupportIcon";
import ViewIcon from "assets/ViewIcon";
import { useEditWatermarkModal, useUploadWatermarkImageModal } from "hooks/modals";
import { useModal } from "hooks/useModal";
import WarningDeletionDialog from "../../../WarningDeletionDialog";

const ACTIONS_ID_PREFIX = `actions`;

const RenderActions = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${ACTIONS_ID_PREFIX}` : ACTIONS_ID_PREFIX;
    const {
        store: { slingshotSettingsStore, authStore },
    } = useStore();
    const { t } = useTranslation();

    const state = useLocalStore(() => ({
        isEditPresetInProgress: false,
    }));

    const uploadWatermarkImageModal = useUploadWatermarkImageModal();
    const editWatermarkModal = useEditWatermarkModal();
    const warningDeletionModal = useModal();

    if (!slingshotSettingsStore.currentWatermark) return null;
    return (
        <Grid direction={"column"} container>
            {authStore.isAdmin && (
                <BulkBarFlatButton
                    icon={<EditIcon />}
                    onClick={editWatermarkModal.open}
                    inProgress={state.isEditPresetInProgress}
                    id={`${idPrefix}_open_edit_watermark_modal`}
                >
                    {i18next.t("common.button.edit")}
                </BulkBarFlatButton>
            )}
            <BulkBarFlatButton
                icon={authStore.isAdmin ? <UpgradeSupportIcon /> : <ViewIcon />}
                onClick={uploadWatermarkImageModal.open}
                inProgress={state.isEditPresetInProgress}
                id={`${idPrefix}_open_upload_watermark_image_modal`}
            >
                {i18next.t("slingshot_page.tabs.settings.watermarks.drawer.actions.upgrade_image")}
            </BulkBarFlatButton>
            {authStore.isAdmin && (
                <BulkBarFlatButton
                    errorColor
                    icon={<TrashIcon />}
                    onClick={warningDeletionModal.open}
                    inProgress={state.isEditPresetInProgress}
                    id={`${idPrefix}_open_warning_deletion_modal`}
                >
                    {i18next.t("common.button.delete")}
                </BulkBarFlatButton>
            )}

            <WarningDeletionDialog
                key={"delete-watermark"}
                open={warningDeletionModal.isOpen}
                onClose={warningDeletionModal.close}
                title={t("slingshot_page.tabs.settings.watermarks.warning_deletion_modal.title")}
                content={t("slingshot_page.tabs.settings.watermarks.warning_deletion_modal.message", {
                    watermarkName: slingshotSettingsStore.currentWatermarkName || "",
                })}
                submit={slingshotSettingsStore.deleteWatermark}
                parentId={idPrefix}
            />
        </Grid>
    );
};

export default observer(RenderActions);
