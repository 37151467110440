import { types } from "mobx-state-tree";

import Request from "api/Request";
import IdentifyOnArguments from "api/drive/Types/IdentifyOnArguments";

const IdentifyOn = Request.props({
    path: "/com/studionetworksolutions/evo/Drives",
    method: "identify_on",
    arguments: types.maybe(IdentifyOnArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default IdentifyOn;
