import { flow, getParent, types } from "mobx-state-tree";
import MainSocket from "websocket";
import GetBonds from "api/net/Requests/GetBonds";
import BondsArrayResult from "api/net/Responses/BondsArrayResult";
import CreateBond from "api/net/Requests/CreateBond";
import { ASC, DESC, NET_IFACE_INTERFACES, NET_IFACE_PORT } from "const/sortColumnConst";
import { DHCP_CLIENT_ROLE, DHCP_SERVER_ROLE, STATIC_IP_ROLE } from "const/rolesConst";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import RemoveBond from "api/net/Requests/RemoveBond";
import SaveNetBondSettings from "api/net/Requests/SaveNetBondSettings";
import GetBondingParameters from "api/net/Requests/GetBondingParameters";
import BondingParameters from "api/net/Types/BondingParameters";
import RenewDhcpLease from "api/net/Requests/RenewDhcpLease";
import SetBondingParameters from "api/net/Requests/SetBondingParameters";

const BondsStore = types
    .model({
        bondsArrayResult: types.maybe(BondsArrayResult),
        bondingParameters: types.maybe(BondingParameters),
        currentBondName: types.maybe(types.string),
    })
    .volatile(() => ({
        orderBy: NET_IFACE_INTERFACES,
        order: ASC,
        orderByDialog: NET_IFACE_PORT,
        orderDialog: ASC,
    }))
    .views((self) => ({
        get bondsArray() {
            return self.bondsArrayResult?.data || [];
        },
        get sortedBondsArray() {
            return stableSort(self.bondsArray, getComparator(self.order, self.orderBy));
        },
        get allIfacesForAggregation() {
            const { ethernetPortsStore } = getParent(self);
            return ethernetPortsStore.allNetIfaces.ifaces.filter(
                (iface) =>
                    !ethernetPortsStore.allNetIfaces.bonds.some((bond) => bond.interfaces.includes(iface.port)) &&
                    !ethernetPortsStore.allNetIfaces.bridges.some((bridge) => bridge.interfaces.includes(iface.port))
            );
        },
        get sortedAllFreeIfacesForAggregation() {
            return stableSort(self.allIfacesForAggregation, getComparator(self.orderDialog, self.orderByDialog));
        },
        get currentBond() {
            return this.bondsArray.find((bond) => bond.port === self.currentBondName);
        },
        get roleOfCurrentPort() {
            if (!this.currentBond) {
                return "";
            }
            if (this.currentBond.dhcpServer.enabled) {
                return DHCP_SERVER_ROLE;
            }
            if (this.currentBond.dhcpClient.enabled) {
                return DHCP_CLIENT_ROLE;
            }
            return STATIC_IP_ROLE;
        },
        get isDhcpClientRole() {
            return self.roleOfCurrentPort === DHCP_CLIENT_ROLE;
        },
        get isDhcpServerRole() {
            return self.roleOfCurrentPort === DHCP_SERVER_ROLE;
        },
        get isStaticIpRole() {
            return self.roleOfCurrentPort === STATIC_IP_ROLE;
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
    }))
    .actions((self) => ({
        fetchBondsArrayResult: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetBonds.create().init();
                const res = yield self.socket.send(req);
                self.bondsArrayResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchBondingParameters: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetBondingParameters.create().init();
                const res = yield self.socket.send(req);
                self.bondingParameters = res.data;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setBondingParameters: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetBondingParameters.create().init(data);
                const res = yield self.socket.send(req);
                self.bondingParameters = data;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        createAggregatedIfaces: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = CreateBond.create().init(data);
                return yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        renewDhcpLease: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RenewDhcpLease.create().init(data);
                yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        removeBond: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RemoveBond.create().init(data);
                return yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        saveNetBondSettings: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SaveNetBondSettings.create().init(data);
                const resp = yield self.socket.send(req);
                Object.keys(self.currentBond).forEach((key) => {
                    if (data[key]) {
                        self.currentBond[key] = data[key];
                    }
                });
                return resp;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        changeSorting(column) {
            if (column === self.orderBy) {
                self.order = self.order === DESC ? ASC : DESC;
            } else {
                self.order = ASC;
                self.orderBy = column;
            }
        },
        changeSortingDialog(column) {
            if (column === self.orderByDialog) {
                self.orderDialog = self.orderDialog === DESC ? ASC : DESC;
            } else {
                self.orderDialog = ASC;
                self.orderByDialog = column;
            }
        },
        setCurrentBond(bond) {
            self.currentBondName = self.currentBondName === bond ? "" : bond;
        },
        updateBondInStore: (netBond) => {
            const { ethernetPortsStore } = getParent(self);
            if (self.bondsArrayResult?.data) {
                const index = self.bondsArrayResult.data.findIndex((el) => el.port === netBond.port);
                if (index < 0) {
                    self.bondsArrayResult.data.push(netBond);
                } else {
                    self.bondsArrayResult.data[index] = netBond;
                }
            }
            ethernetPortsStore.updateBondInAllIfacesResult(netBond);
        },
        removeBondFromStore: (removedBond) => {
            const { ethernetPortsStore } = getParent(self);
            if (self.bondsArrayResult?.data.length !== 0) {
                self.bondsArrayResult.data = self.bondsArrayResult.data.filter((iface) => iface.port !== removedBond.port);
            }
            ethernetPortsStore.removeBondinAllIfacesResult(removedBond);
        },
    }));

export default BondsStore;
