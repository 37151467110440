import React from "react";
import DataCard from "components/DataCard";
import { Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { observer, useLocalStore } from "mobx-react";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import MoreMenu from "components/MoreMenu";
import Checkbox from "components/Checkbox";
import SavedInputIndicator from "components/SavedInputIndicator";
import TableRowControlButton from "components/TableRowControlButton";
import ChevronRightIcon from "assets/ChevronRightIcon";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import ProxyGenerationDrawer from "../ProxyGenerationDrawer";
import { SLINGSHOT_PROXY_GENERATION_CARD } from "const/widgetNameConst";
import { useStore } from "hooks/useStore";
import { useLauncher } from "hooks/useLauncher";
import TableSortLabel from "components/TableSortLabel";
import {
    ASC,
    DESC,
    SLINGSHOT_PROXY_SHARE_NAME,
    SLINGSHOT_PROXY_LOGICAL_DISK,
    SLINGSHOT_PROXY_SHAREBROWSER_PREVIEW,
    SLINGSHOT_PROXY_EDIT_READY,
} from "const/sortColumnConst";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import EditProxyIndicator from "../EditProxyIndicator/EditProxyIndicator";
import { useProxyGenerationSettings } from "hooks/modals";
import ProxyGenerationSettingsModal from "./components/ProxyGenerationSettingsModal/ProxyGenerationSettingsModal";

const PROXY_GENERATION_CARD_ID_PREFIX = `proxy_generation_card`;

const ProxyGenerationCard = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${PROXY_GENERATION_CARD_ID_PREFIX}` : PROXY_GENERATION_CARD_ID_PREFIX;
    const { t } = useTranslation();

    const {
        store: { slingshotStorageStore, indexingConfigStore },
    } = useStore();

    const state = useLocalStore(() => ({
        orderBy: SLINGSHOT_PROXY_SHARE_NAME,
        order: ASC,
        isDataLoaded: false,
        get mainCheckboxStatus() {
            if (
                slingshotStorageStore.shares &&
                slingshotStorageStore.checkedSharesNames.length > 0 &&
                slingshotStorageStore.checkedSharesNames.length === slingshotStorageStore.shares.length
            ) {
                return "checked";
            } else if (
                slingshotStorageStore.shares &&
                slingshotStorageStore.checkedSharesNames.length > 0 &&
                slingshotStorageStore.checkedSharesNames.length < slingshotStorageStore.shares.length
            ) {
                return "indeterminate";
            }
            return null;
        },
        get shares() {
            return slingshotStorageStore.shares?.map((share) => {
                return {
                    ...share,
                    logicalDisk: `${share.pool}/${share.volume}`,
                    sharebrowserPreview: this.hasMediaProxy(share.name),
                    editReady: this.hasEditProxy(share.name),
                };
            });
        },
        get sortedShares() {
            return this.shares ? stableSort(this.shares, getComparator(this.order, this.orderBy)) : [];
        },
        enableDataLoaded() {
            this.isDataLoaded = false;
        },
        hasMediaProxy: (name) =>
            indexingConfigStore.mediaProxyShares.has(name)
                ? indexingConfigStore.mediaProxyShares?.get(name)
                : indexingConfigStore.mediaProxy.enabledDefault,
        hasEditProxy: (name) =>
            indexingConfigStore.editProxyShares.has(name)
                ? indexingConfigStore.editProxyShares?.get(name)
                : indexingConfigStore.editProxy.enabledDefault,
    }));

    useLauncher(
        [
            () =>
                Promise.all([
                    slingshotStorageStore.fetchShares(),
                    indexingConfigStore.fetchConfig(),
                    slingshotStorageStore.clearCheckedShares(),
                ]),
            state.enableDataLoaded,
        ],
        {
            ignoreErrors: false,
            dependencies: [indexingConfigStore.isErrorConnectedDBus, slingshotStorageStore.isErrorConnectedDBus],
        }
    );

    const changeSorting = (column) => () => {
        if (column === state.orderBy) {
            state.order = state.order === DESC ? ASC : DESC;
        } else {
            state.order = ASC;
            state.orderBy = column;
        }
    };
    const proxyGenerationSettingsModal = useProxyGenerationSettings();

    const onRowClick = (shareName) => () => {
        if (slingshotStorageStore.checkedSharesNames.length) {
            return;
        }

        slingshotStorageStore.setCurrentShareName(slingshotStorageStore.currentShareName === shareName ? null : shareName);
        proxyGenerationSettingsModal.setValue("title", shareName);
    };

    const isCheckedShare = (shareName) => {
        return slingshotStorageStore.checkedSharesNames.some((share) => share === shareName);
    };

    const onCheckboxChange = (shareName) => () => {
        isCheckedShare(shareName)
            ? slingshotStorageStore.removeCheckedShare(shareName)
            : slingshotStorageStore.addCheckedShare(shareName);

        slingshotStorageStore.setCurrentShareName(null);
    };

    const handleMainCheckbox = () => {
        slingshotStorageStore.setCurrentShareName(null);

        switch (state.mainCheckboxStatus) {
            case "checked":
            case "indeterminate":
                slingshotStorageStore.shares.forEach((share) => slingshotStorageStore.removeCheckedShare(share.name));
                break;
            default:
                slingshotStorageStore.shares.forEach((share) => slingshotStorageStore.addCheckedShare(share.name));
                break;
        }
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <DataCard
            headerDivider
            title={t("slingshot.index_proxy.proxy_generation.title")}
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem
                        widget={SLINGSHOT_PROXY_GENERATION_CARD}
                        homeCardActionMenuItemId={`${idPrefix}_add_to_home`}
                    />
                </MoreMenu>
            }
            onClick={() => {}}
            emptyPadding
        >
            <ProxyGenerationDrawer />
            <ProxyGenerationSettingsModal parentId={idPrefix} />
            {slingshotStorageStore.shares.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={state.mainCheckboxStatus === "indeterminate"}
                                    checked={state.mainCheckboxStatus === "checked"}
                                    onChange={handleMainCheckbox}
                                    id={`${idPrefix}_main_checkbox`}
                                />
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={state.order}
                                    onClick={changeSorting(SLINGSHOT_PROXY_SHARE_NAME)}
                                    active={state.orderBy === SLINGSHOT_PROXY_SHARE_NAME}
                                    id={`${idPrefix}_name_sort`}
                                >
                                    {t("slingshot.index_proxy.proxy_generation.table.header.share_name")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={state.order}
                                    onClick={changeSorting(SLINGSHOT_PROXY_LOGICAL_DISK)}
                                    active={state.orderBy === SLINGSHOT_PROXY_LOGICAL_DISK}
                                    id={`${idPrefix}_logical_disk_sort`}
                                >
                                    {t("slingshot.index_proxy.proxy_generation.table.header.logical_disk")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={state.order}
                                    onClick={changeSorting(SLINGSHOT_PROXY_SHAREBROWSER_PREVIEW)}
                                    active={state.orderBy === SLINGSHOT_PROXY_SHAREBROWSER_PREVIEW}
                                    id={`${idPrefix}_preview_proxies_sort`}
                                >
                                    {t("slingshot.index_proxy.proxy_generation.table.header.sharebrowser_preview")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={state.order}
                                    onClick={changeSorting(SLINGSHOT_PROXY_EDIT_READY)}
                                    active={state.orderBy === SLINGSHOT_PROXY_EDIT_READY}
                                    id={`${idPrefix}_edit_ready_proxies_sort`}
                                >
                                    {t("slingshot.index_proxy.proxy_generation.table.header.edit_ready_proxies")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.sortedShares?.map((share) => (
                            <TableRow
                                selected={slingshotStorageStore.currentShareName === share.name}
                                key={`share_${share.name}`}
                                onClick={onRowClick(share.name)}
                                id={`${idPrefix}_share_${share.name}`}
                            >
                                <TableCell>
                                    <Checkbox
                                        onClick={stopPropagation}
                                        onChange={onCheckboxChange(share.name)}
                                        checked={isCheckedShare(share.name)}
                                        id={`${idPrefix}_checkbox_${share.name}`}
                                    />
                                </TableCell>
                                <TableCell>{share.name}</TableCell>
                                <TableCell>{share.logicalDisk}</TableCell>
                                <TableCell>
                                    <SavedInputIndicator enabled={share.sharebrowserPreview} />
                                </TableCell>
                                <TableCell>
                                    <EditProxyIndicator status={share.editReady} />
                                </TableCell>
                                <TableCell>
                                    <TableRowControlButton onClick={() => {}} icon={<ChevronRightIcon />} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyCardPlaceholder
                    inProgress={indexingConfigStore.isErrorConnectedDBus || !state.isDataLoaded}
                    message={t("slingshot.index_proxy.proxy_generation.table.placeholder")}
                />
            )}
        </DataCard>
    );
};

export default observer(ProxyGenerationCard);
