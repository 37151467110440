import { useEffect, useState } from "react";
import moment from "moment";
import { DATE_PERIODS_OPTIONS } from "const/createJobConst";

const useCastSeconds = (initialSeconds = 0) => {
    const secondsInOneMinute = moment.duration(1, DATE_PERIODS_OPTIONS.min).as("seconds");
    const secondsInOneHour = moment.duration(1, DATE_PERIODS_OPTIONS.hour).as("seconds");
    const secondsInOneDay = moment.duration(1, DATE_PERIODS_OPTIONS.day).as("seconds");
    const secondsInOneWeek = moment.duration(1, DATE_PERIODS_OPTIONS.week).as("seconds");
    const secondsInOneYear = moment.duration(1, DATE_PERIODS_OPTIONS.year).as("seconds");

    const getMonthQuantity = seconds => {
        const secondsInSetOfMonths = Array(12)
            .fill(null)
            .map((el, idx) => {
                const monthQuantity = idx + 1;
                return moment.duration(monthQuantity, DATE_PERIODS_OPTIONS.month).as("seconds");
            });

        let secondsQuantity = seconds;
        let monthsQuantity = 0;
        let yearsQuantity = 0;
        // let remainder = 0;
        // let allowedRemainder = 0;

        if (secondsQuantity >= secondsInOneYear) {
            yearsQuantity = Math.floor(secondsQuantity / secondsInOneYear);
            monthsQuantity = yearsQuantity * 12;
            secondsQuantity = secondsQuantity - moment.duration(yearsQuantity, DATE_PERIODS_OPTIONS.year).as("seconds");
        }

        const monthIdx = secondsInSetOfMonths.findIndex(secs => secondsQuantity / secs === 1);

        if (monthIdx === -1) {
            return null;
        }

        const monthNumber = secondsQuantity ? monthIdx + 1 : 0;

        return {
            quantity: (secondsQuantity ? monthNumber : 0) + monthsQuantity,
            format: DATE_PERIODS_OPTIONS.month
        };
    };

    const getYearsQuantity = seconds => {
        let yearsQuantity = 0;
        let remainder = 0;
        let allowedRemainder = 0;

        const secondsWithFault = seconds + secondsInOneDay;

        if (secondsWithFault >= secondsInOneYear) {
            yearsQuantity = Math.floor(secondsWithFault / secondsInOneYear);
            remainder = secondsWithFault % secondsInOneYear;
            allowedRemainder = secondsInOneDay * (yearsQuantity / 3) + secondsInOneDay;

            if (remainder <= allowedRemainder) {
                return {
                    quantity: yearsQuantity,
                    format: DATE_PERIODS_OPTIONS.year
                };
            }

            return null;
        }

        return null;
    };

    const getWeeksQuantity = seconds => {
        if (seconds % secondsInOneWeek === 0) {
            return {
                quantity: seconds / secondsInOneWeek,
                format: DATE_PERIODS_OPTIONS.week
            };
        }

        return null;
    };

    const getDaysQuantity = seconds => {
        if (seconds % secondsInOneDay === 0) {
            return {
                quantity: seconds / secondsInOneDay,
                format: DATE_PERIODS_OPTIONS.day
            };
        }

        return null;
    };

    const getHoursQuantity = seconds => {
        if (seconds % secondsInOneHour === 0) {
            return {
                quantity: seconds / secondsInOneHour,
                format: DATE_PERIODS_OPTIONS.hour
            };
        }

        return null;
    };

    const getMinutesQuantity = seconds => {
        if (seconds % secondsInOneMinute === 0) {
            return {
                quantity: seconds / secondsInOneMinute,
                format: DATE_PERIODS_OPTIONS.min
            };
        }

        return null;
    };

    const getSecondsQuantity = seconds => {
        return {
            quantity: seconds,
            format: DATE_PERIODS_OPTIONS.sec
        };
    };

    const [castedTime, setCastedTime] = useState({
        quantity: 0,
        format: DATE_PERIODS_OPTIONS.sec
    });

    useEffect(() => {
        const castedTimeCandidate = [
            getYearsQuantity,
            getMonthQuantity,
            getWeeksQuantity,
            getDaysQuantity,
            getHoursQuantity,
            getMinutesQuantity,
            getSecondsQuantity
        ]
            .map(func => func(initialSeconds))
            .find(Boolean);

        setCastedTime(castedTimeCandidate);
    }, [initialSeconds]);

    return castedTime;
};

export { useCastSeconds };
