import { types } from "mobx-state-tree";

import NasSession from "api/session/Types/NasSession";

const GetNasSessionsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(NasSession)
});

export default GetNasSessionsResult;
