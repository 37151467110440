import i18n from "i18n";

export const isFieldNotEmpty = field => {
    switch (typeof field) {
        case "string":
            return field.trim();
        case "number":
            return !isNaN(field);
        case "boolean":
            return field;
        case "undefined":
        case "object":
            return false;
        default:
            return false;
    }
};

/**
 * dependencies = {
 *     field: string,
 *     values: string[]
 * }[]
 */
export const dynamicRequiredValidator = (dependencies = [], msg = i18n.t("rule.field.empty.common_field_empty")) => {
    return ({ field, form }) => {
        const hasValuesForRequiredValidation = dependencies.every(({ field, values = [] }) => {
            return values.find(value => value === form.$(field).value);
        });

        const hasRequiredError = hasValuesForRequiredValidation ? isFieldNotEmpty(field.value) : true;

        return [hasRequiredError, msg];
    };
};
