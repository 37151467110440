import { makeStyles } from "@material-ui/core/styles";
const PICKER_INPUT_WIDTH = 216;

export const useStyles = makeStyles(theme => ({
    switchControlLabel: {
        marginRight: theme.spacing(4)
    },
    link: {
        textDecoration: "none",
        color: theme.palette.primary.main
    },
    formControlLabel: {
        marginLeft: 0
    },
    picker: {
        width: PICKER_INPUT_WIDTH
    },
    dateTimePickerDialogStyles: {
        "& .MuiPickersToolbar-toolbar": {
            "& > div > .MuiGrid-align-items-xs-flex-end": {
                alignItems: "center"
            },
            alignItems: "flex-end"
        },
        "& .MuiTypography-h4": {
            fontSize: 24
        }
    },
    helper: {
        fontSize: 14,
        opacity: 0.4
    },
    errorBtn: {
        "& svg path": {
            fill: theme.palette.error.main
        }
    },
    downIcon: {
        "& svg path": {
            fill: theme.palette.primary.main
        }
    },
    disabled: {
        "& svg path": {
            fill: theme.palette.grey["300"]
        }
    }
}));
