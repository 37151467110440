import { types, flow, getParent, getSnapshot } from "mobx-state-tree";
import MainSocket from "websocket";
import ListExternalShares from "api/external_shares/Requests/ListExternalShares";
import ListExternalSharesResult from "api/external_shares/Responses/ListExternalSharesResult";
import RemoveExternalShares from "api/external_shares/Requests/RemoveExternalShares";
import SetExternalSharesOnline from "api/external_shares/Requests/SetExternalSharesOnline";
import ListSharesOnRemoteHost from "api/external_shares/Requests/ListSharesOnRemoteHost";
import AddExternalShare from "api/external_shares/Requests/AddExternalShare";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import { ASC, DESC, EXTERNAL_SHARE_NAME } from "const/sortColumnConst";
import { getEvoPrefix } from "../../utils/helpers/getEvoPrefix";

const ExternalSharesStore = types
    .model({
        listExternalSharesStore: types.maybe(ListExternalSharesResult),
        checkedSharesNames: types.optional(types.array(types.string), []),
        currentShareName: types.maybeNull(types.string),
        errorConnectedDBus: types.optional(types.boolean, false),
    })
    .volatile(() => ({
        orderBy: EXTERNAL_SHARE_NAME,
        order: ASC,
    }))
    .views((self) => ({
        get externalShares() {
            return self.listExternalSharesStore && self.listExternalSharesStore.data;
        },
        get sortedExternalShares() {
            return self.externalShares && stableSort(self.externalShares, getComparator(self.order, self.orderBy));
        },
        get currentShare() {
            return self.externalShares?.find((share) => share.name === self.currentShareName);
        },
        get needToSetSharesOffline() {
            return self.checkedSharesNames.some((name) =>
                self.externalShares?.some(
                    (share) => share.name === name && (share.status === "online" || share.status === "error")
                )
            );
        },
        get checkedSharesCount() {
            return self.checkedSharesNames.length;
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
        get isErrorConnectedDBus() {
            return self.errorConnectedDBus;
        },
        get currentShareNameWithPrefix() {
            const { ip, name, evoSettingsStore } = getParent(self);

            const prefix = getEvoPrefix({ ip, evoName: name, hostname: evoSettingsStore?.evoInfo?.hostname, store: self });

            return self.currentShareName ? `${prefix}${self.currentShareName}` : null;
        },
        get checkedSharesNamesWithPrefix() {
            const { ip, name, evoSettingsStore } = getParent(self);

            const prefix = getEvoPrefix({ ip, evoName: name, hostname: evoSettingsStore?.evoInfo?.hostname, store: self });

            const res = self.checkedSharesNames
                ? self.checkedSharesNames.map((shareName) => {
                      return `${prefix}${shareName}`;
                  })
                : [];

            return res;
        },
    }))
    .actions((self) => ({
        fetchExternalShares: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ListExternalShares.create().init();
                self.listExternalSharesStore = yield self.socket.send(req);
            } catch (e) {
                switch (e.code) {
                    case 404:
                        self.errorConnectedDBus = true;
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
            }
        }),
        removeCurrentShare: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                const payload = {
                    shareNames: [self.currentShare?.name],
                };
                processingStore.setLoading(true);
                const req = RemoveExternalShares.create().init(payload);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        removeCheckedShares: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                const payload = {
                    shareNames: getSnapshot(self.checkedSharesNames),
                };
                processingStore.setLoading(true);
                const req = RemoveExternalShares.create().init(payload);
                yield self.socket.send(req);
                self.clearCheckedShares();
                return true;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setCurrentShareOnline: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                const payload = {
                    names: [self.currentShare?.name],
                    online: !(self.currentShare.status === "online" || self.currentShare.status === "error"),
                };
                processingStore.setLoading(true);
                const req = SetExternalSharesOnline.create().init(payload);
                yield self.socket.send(req);
                self.currentShareName === payload.names[0] && self.setCurrentShareName(null);
                return true;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setCheckedSharesOnline: flow(function* (needToSetSharesOffline) {
            const { processingStore } = getParent(self);
            try {
                const payload = {
                    names: getSnapshot(self.checkedSharesNames),
                    online: !needToSetSharesOffline,
                };
                processingStore.setLoading(true);
                const req = SetExternalSharesOnline.create().init(payload);
                yield self.socket.send(req);
                self.clearCheckedShares();
                return true;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        listSharesOnRemoteHost: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ListSharesOnRemoteHost.create().init(data);
                const res = yield self.socket.send(req);
                return res.data;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        addExternalShare: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = AddExternalShare.create().init(data);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        addCheckedShare: (shareName) => {
            const foundShare = self.checkedSharesNames.some((share) => share === shareName);
            !foundShare && self.checkedSharesNames.push(shareName);
        },
        removeCheckedShare: (shareName) => {
            const foundShare = self.checkedSharesNames.some((share) => share === shareName);
            foundShare && self.checkedSharesNames.remove(shareName);
        },
        clearCheckedShares: () => {
            self.checkedSharesNames = [];
        },
        removeShareFromStore: (data) => {
            data.shareNames.forEach((name) => {
                const foundShare = self.externalShares?.find((share) => share.name === name);
                if (foundShare) {
                    self.removeCheckedShare(foundShare.name);
                    self.externalShares.remove(foundShare);
                    self.currentShareName === foundShare.name && self.setCurrentShareName(null);
                }
            });
        },
        changeShareStatus: (data) => {
            const foundShare = self.externalShares?.find((share) => share.name === data.name);
            foundShare ? (foundShare.status = data.status) : self.fetchExternalShares();
        },
        setCurrentShareName: (shareName) => {
            self.currentShareName = shareName;
        },
        changeSorting(column) {
            if (column === self.orderBy) {
                self.order = self.order === DESC ? ASC : DESC;
            } else {
                self.order = ASC;
                self.orderBy = column;
            }
        },
        resetErrors() {
            self.errorConnectedDBus = false;
        },
        enableErrorConnectedDBus() {
            self.errorConnectedDBus = true;
        },
    }));

export default ExternalSharesStore;
