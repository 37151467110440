import { types } from "mobx-state-tree";

import Request from "api/Request";
import SetAclArguments from "api/security/acl/Types/SetAclArguments";

const SetAcl = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Acl",
    method: "set_acl",
    arguments: types.maybe(SetAclArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetAcl
