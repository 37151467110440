import React from "react";

const ChartIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill="#1E90FF" fillRule="nonzero">
                    <g>
                        <path
                            d="M8.67 2.57c.522-.182 1.093.093 1.275.615.182.522-.093 1.093-.615 1.275-2.277.806-4.068 2.597-4.874 4.874-1.474 4.165.709 8.737 4.874 10.21 4.165 1.473 8.737-.709 10.21-4.874l.002-.005c.186-.52.758-.79 1.278-.605l.107.045c.447.22.67.743.503 1.225l-.113.303c-1.055 2.705-3.237 4.824-5.985 5.795-5.207 1.84-10.92-.89-12.76-6.098-1.84-5.207.89-10.92 6.098-12.76zM13 2l.265.004C18.113 2.144 22 6.118 22 11l-.007.117c-.057.497-.48.883-.993.883h-8c-.552 0-1-.448-1-1V3l.007-.117c.057-.497.48-.883.993-.883zm1 2.07V10h5.93c-.443-3.073-2.857-5.487-5.93-5.93z"
                            transform="translate(-500.000000, -216.000000) translate(500.000000, 216.000000)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ChartIcon;
