import Request from "api/Request";

const ExportDatabase = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Slingshot",
    method: "export_database_and_config"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ExportDatabase;
