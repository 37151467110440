import React from "react";

const HddIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40" {...props}>
            <defs>
                <linearGradient id="744b2974-1487-4b3d-9b24-1db3af3eee48" x1="27.878%" x2="73.971%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#97CCFF"></stop>
                    <stop offset="54.03%" stopColor="#8DC6FF"></stop>
                    <stop offset="100%" stopColor="#97CCFF"></stop>
                </linearGradient>
                <linearGradient id="ec70a1b5-c6a2-414a-a7c6-e32e324f36da" x1="0%" x2="89.626%" y1="5.187%" y2="100%">
                    <stop offset="0%" stopColor="#50A8FF"></stop>
                    <stop offset="54.677%" stopColor="#3D9FFF"></stop>
                    <stop offset="100%" stopColor="#2D97FF"></stop>
                </linearGradient>
                <linearGradient id="b1d1e88f-54f3-4eee-8994-7087c5c2f61e" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.3"></stop>
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0"></stop>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <rect width="28" height="36" x="2" y="2" fill="url(#744b2974-1487-4b3d-9b24-1db3af3eee48)" rx="4"></rect>
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M10.614 35v-1.781h1.514V35h1.023v-4.266h-1.023v1.694h-1.514v-1.694H9.585V35h1.029zm5.073 0c.285 0 .548-.05.788-.15.24-.099.448-.239.623-.418.174-.18.31-.395.408-.645.098-.25.147-.526.147-.829v-.179c0-.3-.049-.576-.147-.827a1.926 1.926 0 00-.408-.648 1.845 1.845 0 00-.626-.42c-.242-.1-.508-.15-.797-.15h-1.374V35h1.386zm0-.791h-.357v-2.68h.345c.303 0 .533.104.692.313.158.209.237.52.237.931v.185c0 .414-.08.726-.237.936-.159.21-.385.315-.68.315zm4.379.791c.285 0 .548-.05.788-.15.24-.099.448-.239.623-.418.174-.18.31-.395.408-.645.098-.25.147-.526.147-.829v-.179c0-.3-.05-.576-.147-.827a1.926 1.926 0 00-.408-.648 1.845 1.845 0 00-.626-.42c-.242-.1-.508-.15-.797-.15H18.68V35h1.386zm0-.791h-.358v-2.68h.346c.303 0 .533.104.692.313.158.209.237.52.237.931v.185c0 .414-.08.726-.237.936-.159.21-.385.315-.68.315z"
                ></path>
                <path fill="url(#ec70a1b5-c6a2-414a-a7c6-e32e324f36da)" d="M6 4h20a2 2 0 012 2v22H4V6a2 2 0 012-2z"></path>
                <circle
                    cx="16"
                    cy="16"
                    r="9"
                    fill="#D8D8D8"
                    fillOpacity="0.35"
                    stroke="url(#b1d1e88f-54f3-4eee-8994-7087c5c2f61e)"
                    strokeWidth="2"
                ></circle>
                <circle cx="16" cy="16" r="4.5" fill="#349BFF"></circle>
                <circle cx="16" cy="16" r="2.5" fill="#D8D8D8" fillOpacity="0.35"></circle>
            </g>
        </svg>
    );
};

export default HddIcon;
