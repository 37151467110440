import { types } from "mobx-state-tree";

import BuildTask from "api/volume/Types/BuildTask";

const EnqueueVolumesArguments = types.model({
    tasks: types.array(BuildTask),
    force: types.boolean
});

export default EnqueueVolumesArguments;
