import React from "react";
import { observer, useLocalStore } from "mobx-react";
import { useStyles } from "./RenderDetails.styles";
import { useStore } from "hooks/useStore";
import { Grid, Typography } from "@material-ui/core";
import i18next from "i18next";
import SavedInputIndicator from "components/SavedInputIndicator";
import EditProxyIndicator from "../../../EditProxyIndicator/EditProxyIndicator";

const RenderDetails = () => {
    const classes = useStyles();
    const {
        store: { slingshotStorageStore, indexingConfigStore },
    } = useStore();

    const state = useLocalStore(() => ({
        hasMediaProxy: (name) =>
            indexingConfigStore.mediaProxyShares.has(name)
                ? indexingConfigStore.mediaProxyShares?.get(name)
                : indexingConfigStore.mediaProxy.enabledDefault,
        hasEditProxy: (name) =>
            indexingConfigStore.editProxyShares.has(name)
                ? indexingConfigStore.editProxyShares?.get(name)
                : indexingConfigStore.editProxy.enabledDefault,
        getMediaExceptedFoldersList: (name) => indexingConfigStore.mediaProxy.blackList?.get(name),
        getEditExceptedFoldersList: (name) => indexingConfigStore.editProxy.blackList?.get(name),
        getMediaExceptedFilesList: (name) => indexingConfigStore.mediaProxyShareParams?.get(name)?.extensionBlackList,
        getEditExceptedFilesList: (name) => indexingConfigStore.editProxyShareParams?.get(name)?.extensionBlackList,
    }));

    if (!slingshotStorageStore.currentShare) return null;

    return (
        <Grid container direction={"column"} className={classes.detailsContainer} spacing={4}>
            <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                <Grid item xs={6}>
                    <Typography>{i18next.t("slingshot.index_proxy.proxy_generation.table.header.logical_disk")}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        className={classes.textInfo}
                    >{`${slingshotStorageStore.currentShare?.pool}/${slingshotStorageStore.currentShare?.volume}`}</Typography>
                </Grid>
            </Grid>
            <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                <Grid item xs={6}>
                    <Typography>
                        {i18next.t("slingshot.index_proxy.proxy_generation.table.header.sharebrowser_preview")}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <SavedInputIndicator enabled={state.hasMediaProxy(slingshotStorageStore.currentShare?.name)} />
                </Grid>
            </Grid>
            <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                <Grid item xs={6}>
                    <Typography>{i18next.t("slingshot.index_proxy.proxy_generation.table.header.edit_ready_proxies")}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <EditProxyIndicator status={state.hasEditProxy(slingshotStorageStore.currentShare?.name)} />
                </Grid>
            </Grid>
            <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                <Grid item xs={6}>
                    <Typography>{i18next.t("slingshot.index_proxy.proxy_generation.details.excluded_files")}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"h6"}>
                        {state.getMediaExceptedFilesList(slingshotStorageStore.currentShareName)?.join(", ") ||
                            state.getEditExceptedFilesList(slingshotStorageStore.currentShareName)?.join(", ")}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                <Grid item xs={6}>
                    <Typography>{i18next.t("slingshot.index_proxy.proxy_generation.details.excluded_folders")}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"h6"} className={classes.foldersInfo}>
                        {state.getMediaExceptedFoldersList(slingshotStorageStore.currentShareName)?.join(", ") ||
                            state.getEditExceptedFoldersList(slingshotStorageStore.currentShareName)?.join(", ")}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default observer(RenderDetails);
