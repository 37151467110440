export const timeRegExp = "%H:%M:%S";
export const shortDateRegExp = "%m/%d";

export const CHARTS = {
    SYSTEM_CPU: "system.cpu",
    SYSTEM_NET: "system.net",
    SYSTEM_IO: "system.io",
    NET: "net",
};

export const DEFAULT_START_PERIOD = 15 * 60; // seconds
export const DEFAULT_POINTS_COUNT = 120;
export const TICK_X_COUNT = 5;
export const TICK_Y_COUNT = 3;
export const DEFAULT_ANIM_DURATION = 60;

export const CPU_GRAPH_COLORS = [
    "#ff2b1a",
    "#ff731a",
    "#9fb30b",
    "#37da27",
    "#1cbf76",
    "#26bfc6",
    "#1e90ff",
    "#8725fd",
    "#f725fd",
    "#fd2563",
    "#a60c00",
    "#a3490f",
    "#5c6800",
    "#498343",
    "#8c8c8c",
    "#6fa18b",
    "#547da6",
    "#9063c5",
    "#79467b",
    "#b54968",
    "#ed9088",
    "#f9a570",
    "#9ba269",
    "#90cd8a",
    "#a397b0",
    "#65d3a3",
];
