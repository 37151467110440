import { types } from "mobx-state-tree";

import GetInterfacesResultData from "api/cluster/Types/GetInterfacesResultData";

const GetInterfacesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(GetInterfacesResultData)
});

export default GetInterfacesResult;
