import React from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { TableRow as MuiRow, TableCell, Box } from "@material-ui/core";
import StatusLabel from "../StatusLabel";
import TableRowControlButton from "components/TableRowControlButton/TableRowControlButton";
import ChevronRightIcon from "assets/ChevronRightIcon";
import { SLINGSHOT_DATE_FULL_HOURS_FORMAT } from "const";
import moment from "moment";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const TABLE_ROW_ID_PREFIX = `table_row`;

const TableRow = ({ instance, onClick, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${TABLE_ROW_ID_PREFIX}` : TABLE_ROW_ID_PREFIX;
    const state = useLocalStore(() => ({
        get fileCollectionWalksProcessedFiles() {
            const initValues = {
                event_count: 0,
                processed_event_count: 0,
                failed_event_count: 0,
                unprocessed_event_count: 0,
            };
            instance.walks &&
                instance.walks.forEach((walk) => {
                    if (walk.type === "FILE_COLLECTION") {
                        initValues.event_count += walk?.event_count;
                        initValues.processed_event_count += walk.processed_event_count;
                        initValues.unprocessed_event_count += walk.unprocessed_event_count;
                        initValues.failed_event_count += walk.failed_event_count;
                    }
                });
            return initValues;
        },
    }));

    return useObserver(() => (
        <MuiRow onClick={onClick} id={`${idPrefix}_open`}>
            <TableCell>{instance.id}</TableCell>
            <TableCell>
                <StatusLabel status={instance.result} />
            </TableCell>
            <TableCell>
                <Box whiteSpace="nowrap">
                    {moment.utc(instance.started).format(getLanguageDateFormat(SLINGSHOT_DATE_FULL_HOURS_FORMAT))}
                </Box>
            </TableCell>
            <TableCell>
                <Box whiteSpace="nowrap">
                    {instance.finished
                        ? moment.utc(instance.finished).format(getLanguageDateFormat(SLINGSHOT_DATE_FULL_HOURS_FORMAT))
                        : null}
                </Box>
            </TableCell>
            <TableCell>{state.fileCollectionWalksProcessedFiles.event_count}</TableCell>
            <TableCell>
                {state.fileCollectionWalksProcessedFiles.processed_event_count -
                    state.fileCollectionWalksProcessedFiles.failed_event_count}
            </TableCell>
            <TableCell>{state.fileCollectionWalksProcessedFiles.failed_event_count}</TableCell>
            <TableCell>{state.fileCollectionWalksProcessedFiles.unprocessed_event_count}</TableCell>
            <TableCell>
                <TableRowControlButton icon={<ChevronRightIcon />} />
            </TableCell>
        </MuiRow>
    ));
};

export default TableRow;
