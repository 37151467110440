import React, { useEffect } from "react";
import { useStore } from "hooks/useStore";
import { useObserver, useLocalStore } from "mobx-react";
import { ButtonBase, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DataCard from "components/DataCard/DataCard";
import { VOLUMES_CARD } from "const/widgetNameConst";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import Volume from "../Volume/Volume";
import VolumeDrawer from "../VolumeDrawer";
import PlusIcon from "assets/PlusIcon";
import { useModal } from "hooks/useModal";
import CreateVolumeDialog from "../CreateVolumeDialog";

const VolumesCard = () => {
    const { t } = useTranslation();
    const {
        store: { clusterStore }
    } = useStore();

    const state = useLocalStore(() => ({
        isLoading: false
    }));

    const createVolumeDialog = useModal();

    useEffect(() => {
        (async () => {
            state.isLoading = true;
            await clusterStore.fetchVolumes();
            state.isLoading = false;
        })();
    }, []);

    const addDisk = () => {
        createVolumeDialog.open();
    };

    return useObserver(() => (
        <DataCard
            title={t("cluster.volumes.title")}
            headerDivider
            autoHeight
            headerControl={[
                <MoreMenu key={`data_card-header_control-control-1`}>
                    <HomeCardActionMenuItem widget={VOLUMES_CARD} />
                </MoreMenu>,
                <ButtonBase onClick={addDisk} key={`data_card-header_control-control-2`}>
                    <PlusIcon />
                </ButtonBase>
            ]}
        >
            <VolumeDrawer />
            <CreateVolumeDialog isCreate onClose={createVolumeDialog.close} open={createVolumeDialog.isOpen} />
            {clusterStore.volumes?.length > 0 ? (
                <Grid container spacing={4}>
                    {clusterStore.volumes.map(volume => (
                        <Grid item key={volume.name} xs={6}>
                            <Volume name={volume.name} status={volume.status} type={volume.type} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <EmptyCardPlaceholder inProgress={state.isLoading} message={t("cluster.volumes.table.placeholder")} />
            )}
        </DataCard>
    ));
};

export default VolumesCard;
