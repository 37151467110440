import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

const PICKER_INPUT_WIDTH = 210;

export const useStyles = makeStyles((theme) => ({
    editButton: {
        "& svg path": {
            fill: theme.palette.primary.main,
        },
    },
    radioGroup: {
        marginTop: theme.spacing(-3),
    },
    datePickerDialogStyles: {
        "& .MuiPickersToolbar-toolbar": {
            justifyContent: "flex-end",
        },
        "& .MuiTypography-h4": {
            fontSize: 24,
        },
    },
    dateTimePickerDialogStyles: {
        "& .MuiPickersToolbar-toolbar": {
            "& > div > .MuiGrid-align-items-xs-flex-end": {
                alignItems: "center",
            },
            alignItems: "flex-end",
        },
        "& .MuiTypography-h4": {
            fontSize: 24,
        },
    },
    switchLabel: {
        whiteSpace: "nowrap",
        marginRight: theme.spacing(2),
        marginLeft: 0,
        "& .MuiFormControlLabel-label": {
            marginLeft: theme.spacing(4),
        },
    },
    switchLabelWithWrap: {
        whiteSpace: "pre-line",
        alignItems: "center",
    },

    picker: {
        width: PICKER_INPUT_WIDTH,
    },
    expansionPanel: {
        "&&": {
            boxShadow: "none",
            border: `${theme.palette.expandPanel.border.color} 1px solid`,
            borderRadius: theme.spacing(2),
            padding: theme.spacing(3),
            margin: theme.spacing(0, -3),
        },
    },
    inputsSection: {
        "&&": {
            border: `${theme.palette.expandPanel.border.color} 1px solid`,
            borderRadius: theme.spacing(2),
            padding: theme.spacing(3, 1),
            margin: theme.spacing(0, -3),
        },
    },
    expansionIconClose: {
        transform: "rotate(180deg)",
    },
    expansionIcon: {
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.1),
        borderRadius: "50%",
    },
    expansionLabel: {
        fontSize: 16,
        fontWeight: 500,
        opacity: 0.8,
    },
    expansionPanelContent: {
        "&&": {
            display: "flex",
            minHeight: 0,
            margin: 0,
            padding: 0,
            justifyContent: "space-between",
        },
    },
    detailsRoot: {
        padding: theme.spacing(5, 0, 2),
    },
    description: {
        whiteSpace: "pre-line",
    },
    italic: {
        fontStyle: "italic",
    },
    link: {
        textDecoration: "none",
        color: theme.palette.primary.main,
    },
}));
