import Request from "api/Request";

const DownloadMigrationSystemLogs = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Troubleshooting",
    method: "download_migration_system_logs",
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default DownloadMigrationSystemLogs;
