import { types } from "mobx-state-tree";

import Request from "api/Request";
import DnsServers from "api/net/Types/DnsServers";

const SetDnsServers = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "set_dns_servers",
    arguments: types.maybe(DnsServers)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetDnsServers;
