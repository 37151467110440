export default {
    recoverConfiguration: {
        titleKey: "support.os_data.recover_configuration.title",
        paragraphs: [
            {
                textKey: "support.os_data.recover_configuration.content.paragraph_1",
                gutterBottom: true
            },
            {
                textKey: "support.os_data.recover_configuration.content.paragraph_2"
            }
        ]
    }
};
