import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetProcessedFilesArguments from "../Types/GetProcessedFilesArguments";

const GetProcessedFiles = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Automations1",
    method: "get_instance_event_results",
    arguments: types.maybe(
        types.snapshotProcessor(GetProcessedFilesArguments, {
            postProcessor(snapshot) {
                return {
                    json: snapshot ? JSON.stringify(snapshot) : "{}"
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetProcessedFiles;
