import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetSmartReportArguments from "api/drive/Types/GetSmartReportArguments";

const GetDriveSmartReport = Request.props({
    path: "/com/studionetworksolutions/evo/Drives",
    method: "get_drive_smart_report",
    arguments: types.maybe(GetSmartReportArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetDriveSmartReport;
