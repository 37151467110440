import { types } from "mobx-state-tree";

import VolumeId from "api/bcache/Types/VolumeId";
import DriveId from "api/bcache/Types/DriveId";

const AttachArguments = types.model({
    volume: types.maybe(VolumeId),
    drive: types.maybe(DriveId)
});

export default AttachArguments;
