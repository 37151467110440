import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./StatusLabel.style";
import { useTranslation } from "react-i18next";

const StatusLabel = ({ status }) => {
    const classes = useStyles({ status });
    const { t } = useTranslation();
    const getLabel = () => {
        switch (status) {
            case "IN PROGRESS":
                return t("slingshot_page.tabs.replications.instances_modal.table.status.in_progress");
            case "SUCCEED":
                return t("slingshot_page.tabs.replications.instances_modal.table.status.succeed");
            case "CANCELED":
                return t("slingshot_page.tabs.replications.instances_modal.table.status.canceled");
            case "FAILED":
                return t("slingshot_page.tabs.replications.instances_modal.table.status.failed");
            case "PAUSED":
                return t("slingshot_page.tabs.replications.instances_modal.table.status.paused");
            case "WAITED FOR RESTART":
                return t("slingshot_page.tabs.replications.instances_modal.table.status.wait_restart");
            case "PARTIALLY FAILED":
                return t("slingshot_page.tabs.replications.instances_modal.table.status.partially_failed");
            default:
                return t("slingshot_page.tabs.replications.instances_modal.table.status.unknown");
        }
    };

    return (
        <Grid justify={"flex-start"} container>
            <Grid item>
                <Grid container alignItems={"center"} className={classes.label}>
                    <Grid item>
                        <Typography component={"span"} className={classes.labelText}>
                            {getLabel()}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default StatusLabel;
