import React, { useCallback } from "react";
import ButtonContainer from "../ButtonContainer";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import DataCard from "components/DataCard";
import DragAndDropFile from "components/DragAndDropFile";
import ShieldWithKeyIcon from "assets/ShieldWithKeyIcon";
import { useStyles } from "./Certificate.styles";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import { useStore } from "hooks/useStore";
import { ADVANCED_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const CERTIFICATE_ID_PREFIX = `${ADVANCED_PAGE_ID_PREFIX}_certificate`;

const Certificate = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { advancedStore }
    } = useStore();

    const state = useLocalStore(() => ({
        currentFile: null,
        inProgress: false,
        isSubmitted: false
    }));

    const onFilesSelect = files => {
        state.currentFile = files[0];
    };

    const onSubmit = useCallback(async () => {
        state.inProgress = true;
        const res = await advancedStore.uploadFile(state.currentFile);
        state.inProgress = false;
        res && (state.isSubmitted = true);
    });

    return useObserver(() => (
        <DataCard headerDivider autoHeight title={t("advanced.certificate.title")}>
            <Grid container className={classes.contentContainer}>
                {state.currentFile ? (
                    <Grid container wrap={"nowrap"} className={classes.certContainer}>
                        <Grid item className={classes.sshIcon}>
                            <ShieldWithKeyIcon />
                        </Grid>
                        <Grid item container justify={"flex-start"} alignItems={"center"}>
                            <Typography className={classes.certNameText}>{state.currentFile.name}</Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item container>
                        <DragAndDropFile
                            title={t("admin_settings.server_certificate.import_certificate.drag_and_drop.placeholder")}
                            handleFiles={onFilesSelect}
                            id={`${CERTIFICATE_ID_PREFIX}_drag_and_drop`}
                        />
                    </Grid>
                )}
            </Grid>
            <ButtonContainer>
                <Grid item>
                    <AnimatedSubmitButton
                        disabled={!state.currentFile}
                        label={t("common.button.import")}
                        submit={onSubmit}
                        isSubmitted={state.isSubmitted}
                        id={`${CERTIFICATE_ID_PREFIX}_import`}
                    />
                </Grid>
            </ButtonContainer>
        </DataCard>
    ));
};

export default Certificate;
