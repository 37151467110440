import React, { useContext } from "react";
import TableRowControlButton from "components/TableRowControlButton";
import ChevronRightIcon from "assets/ChevronRightIcon";
import { TableRow, TableCell } from "@material-ui/core";
import VolumeStatusItem from "../VolumeStatusItem";
import Checkbox from "components/Checkbox";
import { useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./LogicalDisks.style";
import { GiB_IEC } from "const/diskSizeConst";
import { Context } from "store";

const LogicalDisksRow = ({ volume, checked, onCheck, setupPortName, dropPortName, currentPortName }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { volumeStore }
    } = useContext(Context);

    const toggleDrawer = () => {
        if (
            currentPortName &&
            volume.volumeId.pool === currentPortName.pool &&
            volume.volumeId.volume === currentPortName.volume
        ) {
            dropPortName();
        } else {
            setupPortName();
        }
    };

    const isRowSelected =
        volumeStore.currentPortName?.volume === volume.volumeId.volume &&
        volumeStore.currentPortName?.pool === volume.volumeId.pool;

    return useObserver(() => (
        <TableRow selected={isRowSelected}>
            <TableCell className={classes.checkboxCell} onClick={onCheck}>
                <Checkbox checked={checked} />
            </TableCell>
            <TableCell onClick={toggleDrawer}>{volume.volumeName}</TableCell>
            <TableCell onClick={toggleDrawer}>{(volume.size / GiB_IEC.value).toFixed(1)}</TableCell>
            <TableCell onClick={toggleDrawer}>
                {t("disks_and_pools.logical_disks.row.cell.raid", { count: volume.raid })}
            </TableCell>
            <TableCell onClick={toggleDrawer}>
                <VolumeStatusItem variant={"health"} data={volume.status} />
            </TableCell>
            <TableCell onClick={toggleDrawer}>
                <VolumeStatusItem variant={"status"} data={volume.status} />
            </TableCell>
            <TableCell align={"right"} onClick={toggleDrawer}>
                <TableRowControlButton icon={<ChevronRightIcon />} onClick={toggleDrawer} />
            </TableCell>
        </TableRow>
    ));
};

export default LogicalDisksRow;
