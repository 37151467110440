import vjf from "mobx-react-form/lib/validators/VJF";
import validator from "validator";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";

export const defaultFormOptions = { validateOnInit: false };
export const defaultFormPlugins = {
    vjf: vjf(validator),
    dvr: dvr(validatorjs)
};
