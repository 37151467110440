import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography, Button, Link } from "@material-ui/core";
import Dialog from "components/Dialog";
import { useTranslation, Trans } from "react-i18next";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { EXTERNAL_URL_POOLS_HELP_MANUAL } from "const/externalURLs";

const OVERLIMIT_POOL_WARNING_DIALOG_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_overlimit_pool_warning_dialog`;

const OverlimitPoolWarningDialog = ({ open, onClose, onSubmit, isSubmitted = false }) => {
    const { t } = useTranslation();

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            withoutIconClose
            withoutDividers
            onSubmit={onSubmit}
            isSubmitted={isSubmitted}
            title={t("common.warning_dialog.title")}
            submitBtnLabel={t("common.button.proceed")}
            submitBtnId={`${OVERLIMIT_POOL_WARNING_DIALOG_ID_PREFIX}_submit`}
            buttons={
                <Button
                    id={`${OVERLIMIT_POOL_WARNING_DIALOG_ID_PREFIX}_cancel`}
                    onClick={onClose}
                    variant={"contained"}
                    color={"secondary"}
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"} spacing={5}>
                <Grid item>
                    <Typography>
                        <Trans
                            i18nKey={"disks_and_pools.overlimit_pool_warning_dialog.content"}
                            components={<Link href={EXTERNAL_URL_POOLS_HELP_MANUAL} target="_blank" rel="noopener" />}
                        />
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography>{t("disks_and_pools.overlimit_pool_warning_dialog.question")}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default OverlimitPoolWarningDialog;
