import React from "react";

const SlingshotIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="nonzero"
                d="M11.31 11.29a1 1 0 0 1 1.4 0l3.006 3.006a1 1 0 0 1-.006 1.414l-.003.003a1 1 0 0 1-1.417-.003L13 14.41V20a1 1 0 1 1-2 0v-5.64l-1.31 1.27a1 1 0 1 1-1.38-1.44zm-1.272-7.998A6 6 0 0 1 17.67 7l.255.038a5 5 0 0 1 2.825 8.292l-.086.087a1 1 0 0 1-.664.253l-.12-.007a1 1 0 0 1-.54-.243l-.005-.004A1.001 1.001 0 0 1 19.25 14l.142-.17A3 3 0 0 0 17 9h-.06a1 1 0 0 1-1-.8l-.047-.203A4 4 0 0 0 8.1 8.2l-.03.115A1 1 0 0 1 7.1 9H7a3 3 0 0 0-2.25 5l.091.084A1 1 0 1 1 3.25 15.27l-.174-.207A5 5 0 0 1 6.33 7l.091-.246a6 6 0 0 1 3.617-3.462z"
            />
        </svg>
    );
};

export default SlingshotIcon;
