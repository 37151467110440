import { makeStyles } from "@material-ui/core/styles";
import { SYSTEM_MENU_WIDTH } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    paper: {
        height: "100%"
    },
    container: {
        width: SYSTEM_MENU_WIDTH,
        height: "100%",
        overflowY: "auto"
    },
    buttonBase: {
        padding: theme.spacing(2, 6),
        width: "100%"
    },
    buttonTitle: {
        textTransform: "none",
        fontWeight: 500
    },
    iconlessButtonTitle: {
        opacity: 0.4,
        fontWeight: "normal"
    },
    buttonsSection: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(16)
    },
    icon: {
        "& svg path": {
            fill: theme.palette.grey[300]
        }
    },
    alignLeft: {
        textAlign: "left"
    }
}));
