import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    user: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8)
    },
    path: {
        marginBottom: theme.spacing(8)
    },
    region: {
        "&:not(:last-child)": {
            marginBottom: theme.spacing(4)
        }
    },
    caption: {
        marginTop: theme.spacing(-2),
        marginBottom: theme.spacing(3.5),
        opacity: 0.4
    }
}));
