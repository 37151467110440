import i18n from "i18n";

const userNameRule = {
    name: {
        function: value => {
            return /^[a-z][a-z0-9-_.]*$/.test(value);
        },
        message: i18n.t("rule.user.name")
    }
};

export default userNameRule;
