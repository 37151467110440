import React, { Fragment, useEffect } from "react";
import { useLocalStore, observer } from "mobx-react";
import { Table, TableBody, TableCell, TableRow, TableHead, Paper, Divider, Typography } from "@material-ui/core";
import Checkbox from "components/Checkbox";
import PoolShareHeader from "./PoolShareHeader";
import StatusLabel from "components/StatusLabel";
import UsedCellItem from "../UsedCellItem";
import TableSortLabel from "components/TableSortLabel";
import { useStyles } from "./PoolShare.style";
import config from "config";
import { ASC, DESC, SHARE_NAME, SHARE_USAGE, SHARE_USED, SHARE_STATUS } from "const/sortColumnConst";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import { useTranslation } from "react-i18next";
import { GB_METRIC, GiB_IEC } from "const/diskSizeConst";
import TableExpand from "components/TableExpand";
import PlusIcon from "assets/PlusIcon";
import ChevronRightIcon from "assets/ChevronRightIcon";
import TableRowControlButton from "components/TableRowControlButton";
import CreateShareDialog from "../CreateShareDialog";
import useStoreByIp from "hooks/useStoreByIp";
import useEvoPrefix from "hooks/useEvoPrefix";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { useStore } from "hooks/useStore";
import { SHARE_POOL_USB } from "const/shareRootAccessConst";
import { autorun } from "mobx";

const POOL_SHARE_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_pool_share`;

const PoolShare = ({ pool, volume, partition, fileSystem, ip, sharesTable }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const evoPrefix = useEvoPrefix({ ip });
    const { shareStore, fileStore } = useStoreByIp({ ip });
    const {
        store: { rootShareStore },
    } = useStore();

    const HIDDEN_ROWS_PER_CARD_INDEX = 6;
    const state = useLocalStore(() => ({
        isEditShareOpen: false,
        orderBy: SHARE_NAME,
        order: ASC,
        isExpanded: false,
        isCreateShareOpened: false,
        sharesTable: shareStore.sharesTable,
        get currentCard() {
            return this.sharesTable.find(
                (shareTable) => shareTable.pool === pool && shareTable.volume === volume && shareTable.partition === partition
            );
        },
        get sortedShares() {
            return this.currentCard && stableSort(this.currentCard.shares, getComparator(this.order, this.orderBy));
        },
        get slicedIndex() {
            if (this.isExpanded) {
                return this.currentCard.shares.length;
            } else {
                return HIDDEN_ROWS_PER_CARD_INDEX;
            }
        },
        get filteredSharesLength() {
            return shareStore.checkedSharesNames.filter((shareName) =>
                this.currentCard.shares.some((share) => share.attributes.shareName === shareName)
            ).length;
        },
        get mainCheckboxStatus() {
            if (this.filteredSharesLength === this.currentCard.shares.length && this.filteredSharesLength > 0) {
                return "checked";
            } else if (0 < this.filteredSharesLength && this.filteredSharesLength < this.currentCard.shares.length) {
                return "indeterminated";
            }
            return null;
        },
        get needToShowQuota() {
            return this.sortedShares?.some((share) => share.attributes.quota !== 0);
        },
    }));

    useEffect(() => {
        state.sharesTable = sharesTable;
    }, [sharesTable]);

    useEffect(() => {
        autorun(() => {
            state.sharesTable = shareStore.sharesTable;
        });
    });

    const changeSorting = (column) => () => {
        if (column === state.orderBy) {
            state.order = state.order === DESC ? ASC : DESC;
        } else {
            state.order = ASC;
            state.orderBy = column;
        }
    };

    const toggleExpandShares = () => {
        state.isExpanded ? (state.isExpanded = false) : (state.isExpanded = true);
    };

    const getSizeUnit = () => {
        return config.decimalSizeUnits ? GB_METRIC.unit : GiB_IEC.unit;
    };

    const onCreateShareModalOpen = () => {
        fileSystem !== "none" && fileStore.fetchListRootDirectories({ pool, volume, partition });
    };

    const handleMainCheckbox = () => {
        switch (state.mainCheckboxStatus) {
            case "checked":
            case "indeterminated":
                state.currentCard.shares.forEach((share) => shareStore.removeCheckedShare(share.attributes.shareName));
                break;
            default:
                state.currentCard.shares.forEach((share) => shareStore.addCheckedShare(share.attributes.shareName));
                break;
        }
    };

    const isCheckedShare = (targetShareName) => {
        return shareStore.checkedSharesNames.some((shareName) => shareName === targetShareName);
    };

    const closeCreateShareModal = () => {
        state.isCreateShareOpened = false;
    };
    const openCreateShareModal = () => {
        state.isCreateShareOpened = true;
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const onRowClick = (name) => () => {
        if (shareStore.checkedSharesCount > 0) return;
        shareStore.setCurrentShareName(name);
        rootShareStore.dropOthersCurrentShareNames(ip);
    };

    const notUsbPool = pool !== SHARE_POOL_USB;

    return (
        <Paper className={classes.container}>
            <CreateShareDialog
                ip={ip}
                onClose={closeCreateShareModal}
                openCreateShareModal={openCreateShareModal}
                open={state.isCreateShareOpened}
                pool={state.currentCard.pool}
                volume={state.currentCard.volume}
                partition={state.currentCard.partition}
                onEnter={onCreateShareModalOpen}
                fileSystem={state.currentCard.fileSystem}
            />
            <PoolShareHeader
                ip={ip}
                pool={state.currentCard.pool}
                volume={state.currentCard.volume}
                partition={state.currentCard.partition}
                fileSystem={state.currentCard.fileSystem}
                raid={state.currentCard.raid}
                size={state.currentCard.size}
                used={state.currentCard.used}
                openCreateShareModal={openCreateShareModal}
                evoPrefix={evoPrefix}
            />
            <Divider />
            <Table>
                {state.currentCard.shares.length > 0 ? (
                    <>
                        <TableHead>
                            <TableRow>
                                <TableCell padding={"checkbox"}>
                                    <Checkbox
                                        indeterminate={state.mainCheckboxStatus === "indeterminated"}
                                        checked={state.mainCheckboxStatus === "checked"}
                                        onChange={handleMainCheckbox}
                                        id={`${POOL_SHARE_ID_PREFIX}_main_checkbox`}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={state.order}
                                        onClick={changeSorting(SHARE_NAME)}
                                        active={state.orderBy === SHARE_NAME}
                                        id={`${POOL_SHARE_ID_PREFIX}_name_sort`}
                                    >
                                        {t("share.shared_pool_card.table.header.share_name")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={state.order}
                                        onClick={changeSorting(SHARE_USAGE)}
                                        active={state.orderBy === SHARE_USAGE}
                                        id={`${POOL_SHARE_ID_PREFIX}_protocol_sort`}
                                    >
                                        {t("share.shared_pool_card.table.header.protocol")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align={"right"}>
                                    <TableSortLabel
                                        direction={state.order}
                                        onClick={changeSorting(SHARE_USED)}
                                        active={state.orderBy === SHARE_USED}
                                        id={`${POOL_SHARE_ID_PREFIX}_used_sort`}
                                    >
                                        {state.needToShowQuota
                                            ? t("share.shared_pool_card.table.header.used_quota", { unit: getSizeUnit() })
                                            : t("share.shared_pool_card.table.header.used", { unit: getSizeUnit() })}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align={"right"}>
                                    <TableSortLabel
                                        direction={state.order}
                                        onClick={changeSorting(SHARE_STATUS)}
                                        active={state.orderBy === SHARE_STATUS}
                                        id={`${POOL_SHARE_ID_PREFIX}_status_sort`}
                                    >
                                        {t("share.shared_pool_card.table.header.status")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.sortedShares.slice(0, state.slicedIndex).map((share) => {
                                const onCheck = () => {
                                    const isChecked = isCheckedShare(share.attributes.shareName);
                                    isChecked
                                        ? shareStore.removeCheckedShare(share.attributes.shareName)
                                        : shareStore.addCheckedShare(share.attributes.shareName);
                                };

                                return (
                                    <TableRow
                                        selected={shareStore.currentShareName === share.attributes.shareName}
                                        onClick={onRowClick(share.attributes.shareName)}
                                        key={share.attributes.shareName}
                                    >
                                        <TableCell className={classes.noOpacityCell}>
                                            <Checkbox
                                                onClick={stopPropagation}
                                                checked={isCheckedShare(share.attributes.shareName)}
                                                onChange={onCheck}
                                                id={`${POOL_SHARE_ID_PREFIX}_${share.attributes.shareName}_checkbox`}
                                            />
                                        </TableCell>
                                        <TableCell>{share.shareName}</TableCell>
                                        <TableCell>{share.shareType}</TableCell>
                                        <TableCell align={"right"}>
                                            {state.needToShowQuota ? (
                                                <UsedCellItem used={share.shareUsed} quota={share.quota} />
                                            ) : (
                                                <Typography>{`${
                                                    share.shareUsed === 0 ? 0 : share.shareUsed.toFixed(1)
                                                }`}</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.statusCell}>
                                            <StatusLabel status={share.nestedStatus} />
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <TableRowControlButton
                                                icon={<ChevronRightIcon />}
                                                id={`${POOL_SHARE_ID_PREFIX}_${share.attributes.shareName}_button`}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        {state.currentCard.shares.length > HIDDEN_ROWS_PER_CARD_INDEX && (
                            <TableExpand onClick={toggleExpandShares}>
                                {state.isExpanded
                                    ? t("share.shared_pool_card.show_all_table_caption.hide")
                                    : t("share.shared_pool_card.show_all_table_caption.show_all", {
                                          count: state.currentCard.shares.length,
                                      })}
                            </TableExpand>
                        )}
                    </>
                ) : (
                    <Fragment>
                        {notUsbPool && (
                            <TableExpand hideDivider onClick={openCreateShareModal} icon={<PlusIcon />}>
                                {t("share.shared_pool_card.button.add_share")}
                            </TableExpand>
                        )}
                    </Fragment>
                )}
            </Table>
        </Paper>
    );
};

export default observer(PoolShare);
