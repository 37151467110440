import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3, 0),
        "& .MuiFormControlLabel-root": {
            margin: 0
        }
    }
}));
