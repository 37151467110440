import { makeStyles } from "@material-ui/core/styles";
import { DRAG_LAYER_ZINDEX } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    dragLayer: {
        position: "relative"
    },
    preview: {
        zIndex: DRAG_LAYER_ZINDEX + 1,
        pointerEvents: "none",
        position: "fixed",
        width: "min-content",
        display: ({ isDragging }) => {
            if (!isDragging) {
                return "none";
            }
            return "block";
        },
        transform: ({ currentOffset, childRect, containerOffset }) => {
            if (!currentOffset || !childRect) {
                return;
            }
            const halfRectWidth = childRect.width / 2;
            return `translate(${currentOffset.x - containerOffset.x - halfRectWidth}px,
                ${currentOffset.y - containerOffset.y + theme.spacing(3)}px)`;
        }
    }
}));
