import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        height: "100%",
        alignItems: "center",
        alignContent: "center",
        flexDirection: "column",
        marginLeft: ({ hoverPart }) => (hoverPart === 1 ? theme.spacing(-1) : theme.spacing(-1.5)),
        marginRight: ({ hoverPart }) => (hoverPart === 1 ? theme.spacing(-1.5) : 0)
    },
    oval: {
        borderRadius: "50%",
        width: 10,
        height: 10,
        backgroundColor: theme.palette.primary.main
    },
    line: {
        flex: 1,
        width: 4,
        backgroundColor: theme.palette.primary.main
    }
}));
