import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useStyles } from "./ReplaceDisk.styles";
import { Context } from "store";
import { Grid } from "@material-ui/core";
import FailedDrivesCard from "./components/FailedDrivesCard";
import InformationCard from "./components/InformationCard";
import DontShowAgainCard from "./components/DontShowAgainCard";
import LogicalDisks from "./components/LogicalDisks";
import AffectedPoolsCard from "./components/AffectedPoolsCard";
import BulkOperationsBar from "./components/BulkOperationsBar";
import LogicalDisksDrawer from "./components/LogicalDisksDrawer";

const ReplaceDisk = () => {
    const classes = useStyles();

    const {
        store: { driveReplaceStore }
    } = useContext(Context);

    useEffect(() => {
        driveReplaceStore.fetchReplaceState();
        driveReplaceStore.fetchAffectedPools();
    }, []);

    return (
        <Grid container spacing={6} className={classes.container}>
            <BulkOperationsBar />
            <LogicalDisksDrawer />
            <Grid item xs={12} md={6}>
                <FailedDrivesCard />
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <InformationCard />
                    </Grid>
                    <Grid item xs={12}>
                        <DontShowAgainCard />
                    </Grid>
                </Grid>
            </Grid>
            {driveReplaceStore.affectedPools?.pools?.length > 0 && (
                <>
                    <Grid item xs={12}>
                        <LogicalDisks />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AffectedPoolsCard
                            selectedPool={driveReplaceStore.affectedPools.pools && driveReplaceStore.affectedPools.pools[0]}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default observer(ReplaceDisk);
