export const CONTENT_MIN_WIDTH = 800;
export const CONTENT_MAX_WIDTH = 1200;
export const HOME_PAGE_CONTENT_MAX_WIDTH = 1800;

export const HEADER_HEIGHT = 71;
export const HEADER_HEIGHT_MOBILE = HEADER_HEIGHT + 9;
export const GRAPHICS_BAR_HEIGHT = 140;
export const GRAPHICS_BAR_HEIGHT_MOBILE = GRAPHICS_BAR_HEIGHT + 50;
export const STATE_BAR_HEIGHT = 140;
export const STATE_BAR_HEIGHT_MOBILE = STATE_BAR_HEIGHT + 50;
export const HEADER_DROPDOWN_POPOVER_MAXHEIGHT = 150;
export const UPGRADE_MESSAGE_CONTAINER_HEIGHT = 32;

export const OPENED_HEADER_HEIGHT = HEADER_HEIGHT + GRAPHICS_BAR_HEIGHT + STATE_BAR_HEIGHT;
export const OPENED_HEADER_HEIGHT_MOBILE = HEADER_HEIGHT_MOBILE + GRAPHICS_BAR_HEIGHT_MOBILE + STATE_BAR_HEIGHT_MOBILE;

export const OPENED_SIDE_MENU_WIDTH = 328;
export const CLOSED_SIDE_MENU_WIDTH = 16;
export const SIDE_MENU_TAB_WIDTH = 98;
export const SIDE_MENU_TAB_HEIGHT = 76;

export const FOOTER_HEIGHT = 68;
export const TAB_BAR_HEIGHT = 64;
export const CONTAINER_PADDING = 24;

export const DIALOG_WIDTH = 520;
export const DIALOG_WIDTH_MD = 640;
export const DIALOG_WIDTH_LG = 960;
export const DIALOG_WIDTH_XL = 1200;
export const NOTISTACK_WIDTH = 322;

export const HEADER_ZINDEX = 10;
export const UPGRADE_MESSAGE_ZINDEX = 9;
export const TAB_BAR_ZINDEX = 5;
export const POPOVER_ZINDEX = 6;
export const FOOTER_ZINDEX = 10;
export const SIDE_MENU_ZINDEX = 9;
export const SYSTEM_MENU_ZINDEX = 8;
export const PIN_BUTTON_ZINDEX = 10;
export const PIN_NOTIFICATION_MENU_ZINDEX = 1300;
export const NOTIFICATION_MENU_ZINDEX = 9;
export const DRAG_LAYER_ZINDEX = 10;

export const BUTTON_LINE_BG_GRADIENT_LIGHT_BLUE_1 = "#f0f8ff";
export const BUTTON_LINE_BG_GRADIENT_LIGHT_BLUE_2 = "#f4f8ff";
export const BUTTON_LINE_BG_TEXT_SHADOW_LIGHT_BLUE = "#f3f8ff";
export const BUTTON_LINE_BG_GRADIENT_DARK_BLUE_1 = "#31373e";
export const BUTTON_LINE_BG_GRADIENT_DARK_BLUE_2 = "#35383e";
export const BUTTON_LINE_BG_TEXT_SHADOW_DARK_BLUE = "#35383e";
export const INPUT_BACKGROUND_COLOR = "#edf2f6";
export const PRIMARY_BUTTON_BORDER_COLOR = "#0078ed";
export const TITLE_ELEMENT_COLOR = "#263344";
export const SEARCH_PLACEHOLDER_COLOR = "#89aac7";
export const GREY_BLUE = "#7fa3c2";
export const LIGHT_BLUE = "#1EB7FF";
export const CLEAR_BLUE = "#1e90ff";
export const DARK_BLUE = "#0F4780";
export const SECONDARY_BLUE = "#6B64FF";
export const RED = "#E90F3F";
export const LIGHT_RED = "#ff446d";
export const ORANGE = "#ffa026";
export const GREEN = "#21C97E";
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";
export const GREY_50 = "#F6F9FB";
export const GREY_100 = "#f2f6f9";
export const GREY_200 = "#d9e4ed";
export const GREY_300 = "#bfd1e1";
export const GREY_500 = "#8194a5";
export const GREY_600 = "#666666";
export const GREY_700 = "#404040";
export const GREY_800 = "#2d2d2d";
export const GREY_900 = "#222222";
export const GREY_A100 = "#edf2f6";
export const GREY_A400 = "#2c2c2c";
export const GREY_1000 = "#e5ecf2";

export const POPOVER_BACKGROUND_COLOR = "#F4F7FA";
export const EXPAND_PANEL_BORDER_COLOR = "#e5ecf2";
export const TABLE_DIVIDER_GRAY = "#ebf1f5";
export const QUAD_STATUS_DOT_GREY_COLOR = "#595959";

export const DRAWER_WIDTH = 328;
export const DRAWER_INPUT_WIDTH = 170;
export const SYSTEM_MENU_WIDTH = 300;
export const NOTIFICATION_MENU_WIDTH = 328;
export const BUTTON_SUBMIT_HEIGHT = 40;

export const SEARCH_LINE_SHADOW = "0 2px 28px 0 rgba(126, 166, 241, 0.25)";

export const HEADER_DIVIDER = "1px solid rgba(255, 255, 255, 0.1)";
export const HEADER_DROPDOWN_POPOVER_BACKGROUND = "#3d3d3d";
export const HEADER_QUAD_ITEM_BACKGROUND_COLOR = "#353535";
export const HEADER_QUAD_ITEM_DOTS_MAXWIDTH = 65;
export const HEADER_QUAD_ITEM_DOTS_MAXHEIGHT = 68;
export const HEADER_QUAD_ITEM_MAXWIDTH = 74;
export const NOTIFICATION_MENU_BUTTON_WIDTH = 56;

export const SPLASH_PAGE_CARD_HEIGHT = 509;
export const SPLASH_PAGE_CARD_WIDTH = 448;
export const SPLASH_PAGE_CARD_CONTENT_HEIGHT = 164;

export const EMPTY_CARD_PLACEHOLDER_HEIGHT = 184;

export const USERS_SEARCH_SELECT_WIDTH = 294;
export const USERS_SEARCH_SELECT_RELATIVE_POSITION_TOP = 44;

export const FOOTER_FONT = {
    fontSize: 12,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0.15,
    color: "#8194a5",
};

export const GRAPH_INFO_HEIGHT = 132;
