import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box } from "@material-ui/core";
import { useStyles } from "./DriveItem.styles";
import HddIcon from "assets/HddIcon";
import SsdIcon from "assets/SsdIcon";
import UnknownDiskIcon from "assets/UnknownDiskIcon";

const DriveItem = ({ drive, status, type, ...props }) => {
    const { t } = useTranslation();
    const classes = useStyles({ status });

    const state = useLocalStore(() => ({
        get icon() {
            switch (type) {
                case "hdd":
                    return <HddIcon />;
                case "ssd":
                    return <SsdIcon />;
                default:
                    return <UnknownDiskIcon />;
            }
        }
    }));

    return useObserver(() => (
        <Grid container alignItems={"center"} wrap={"nowrap"} className={classes.driveContainer} {...props}>
            <Grid item>
                <Box display="flex" alignItems="center">
                    {state.icon}
                </Box>
            </Grid>
            <Grid item>
                <Box display="flex" alignItems="center">
                    <Grid className={classes.statusDot} />
                </Box>
            </Grid>
            <Grid item>
                <Box display="flex" alignItems="center">
                    <Typography noWrap>{t("common.drive_name", { name: drive })}</Typography>
                </Box>
            </Grid>
        </Grid>
    ));
};

export default DriveItem;
