import { useEffect } from "react";

/**
 * The first argument Takes an object as input, where the key is the name of the field,
 * and the value is the condition under which the field appears in the form.
 * The second argument takes the mobx-form
 *
 * When using the IsFormReady Parameter - it must always be boolean for the hook to work correctly
 *
 * @description
 * The first argument - pass the form objects.
 *
 * The second argument - pass a states object, where property name is field name in form and value of this property is state, if state is FALSE field will be clear.
 *
 * The third argument - pass a boolean value, if it is FALSE the second argument will not be checked and clear will not be executed. If you don't pass it on, the hook will always run.
 *
 * @param {Object<string, boolean>} fieldConditionRecord - object where each property name points to the name of the field in the form, and the value is responsible for the state.
 * @param {object} form - form object of mobx-state-form.
 * @param {boolean} isFormReady - optional boolean value which, if set to false, blocks the process of clearing the field value. Only boolean.
 * @example
 *  useClearFormFields(
 *      {
 *          ["recycleBinEnabled"]: state.form.$("protocolValue").value === "smb",
 *          ["smbAndAfp"]: state.form.$("protocolValue").value === "smb",
 *          ["browseable"]: state.form.$("protocolValue").value === "smb",
 *          ["accessType"]: state.form.$("protocolValue").value !== "internal"
 *      },
 *      state.form
 * );
 */
export const useClearFormFields = (fieldConditionRecord, form, isFormReady) => {
    const conditions = Object.values(fieldConditionRecord);

    useEffect(() => {
        if (typeof isFormReady === "boolean" && !isFormReady) return;

        Object.entries(fieldConditionRecord).forEach(([field, condition]) => {
            if (condition) {
                form.$(field).clear();
                form.$(field).resetValidation();
            }
        });
    }, conditions);
};
