import { types } from "mobx-state-tree";

import QuadActivation from "api/activation/quad/Types/QuadActivation";

const EnclosureActivation = types.model({
    enclosure: types.string,
    quadActivations: types.array(QuadActivation)
});

export default EnclosureActivation;
