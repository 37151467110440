import {
    URL_ADMIN_SETTINGS,
    URL_ADVANCED,
    URL_ALERTS,
    URL_AUDIT,
    URL_CENTRAL_STATUS,
    URL_CLUSTER,
    URL_DASHBOARD,
    URL_DISKS,
    URL_EULA,
    URL_FILE_NAVIGATOR,
    URL_FILE_NAVIGATOR_LOG,
    URL_FIREWALL,
    URL_HARDWARE_ACTIVATION,
    URL_HARDWARE_PROFILE,
    URL_HOME,
    URL_LOGIN,
    URL_MASTER_RESET,
    URL_NETWORK,
    URL_POWER_AND_UPS,
    URL_QUICK_START,
    URL_RECYCLE_BIN,
    URL_SHAREBROWSER_AUTH,
    URL_SHARES,
    URL_SLINGSHOT,
    URL_SPLASH_PAGE,
    URL_SUPPORT,
    URL_TECHNICAL_SUPPORT,
    URL_TIME_SETTINGS,
    URL_USERS,
    URL_WIREGUARD
} from "routes";
import Slingshot from "pages/Slingshot";
import DisksAndPools from "pages/DisksAndPools";
import Network from "pages/Network/Network";
import Users from "pages/Users";
import Support from "pages/Support";
import Shares from "pages/Shares";
import Advanced from "pages/Advanced";
import RecycleBin from "pages/RecycleBin";
import TimeSettings from "pages/TimeSettings";
import HardwareActivation from "pages/HardwareActivation";
import Home from "pages/Home";
import TechnicalSupport from "pages/TechnicalSupport";
import AdminSettings from "pages/AdminSettings";
import Dashboard from "pages/Dashboard";
import Alerts from "pages/Alerts";
import PowerAndUps from "pages/PowerAndUps";
import FileNavigator from "pages/FileNavigator";
import HardwareProfile from "pages/HardwareProfile";
import MasterReset from "pages/MasterReset";
import Firewall from "pages/Firewall";
import Audit from "pages/Audit";
import Wireguard from "pages/Wireguard";
import ShareBrowser from "pages/ShareBrowser";
import Cluster from "pages/Cluster";
import FileNavigatorLog from "pages/FileNavigatorLog";
import Login from "pages/Login";
import Eula from "pages/Eula";
import SplashPage from "pages/SplashPage";
import QuickStart from "pages/QuickStart";
import CentralStatus from "pages/CentralStatus";

export const PRIVATE_ROUTES = [
    { path: URL_SLINGSHOT, Component: Slingshot, exact: false },
    { path: URL_DISKS, Component: DisksAndPools, exact: true },
    { path: URL_NETWORK, Component: Network, exact: true },
    { path: URL_USERS, Component: Users, exact: true },
    { path: URL_SUPPORT, Component: Support, exact: false },
    { path: URL_SHARES, Component: Shares, exact: false },
    { path: URL_ADVANCED, Component: Advanced, exact: true },
    { path: URL_RECYCLE_BIN, Component: RecycleBin, exact: true },
    { path: URL_TIME_SETTINGS, Component: TimeSettings, exact: true },
    { path: URL_HARDWARE_ACTIVATION, Component: HardwareActivation, exact: true },
    { path: URL_HOME, Component: Home, exact: true },
    { path: URL_TECHNICAL_SUPPORT, Component: TechnicalSupport, exact: true },
    { path: URL_ADMIN_SETTINGS, Component: AdminSettings, exact: true },
    { path: URL_DASHBOARD, Component: Dashboard, exact: true },
    { path: URL_ALERTS, Component: Alerts, exact: true },
    { path: URL_POWER_AND_UPS, Component: PowerAndUps, exact: true },
    { path: URL_FILE_NAVIGATOR, Component: FileNavigator, exact: true },
    { path: URL_HARDWARE_PROFILE, Component: HardwareProfile, exact: true },
    { path: URL_MASTER_RESET, Component: MasterReset, exact: true },
    { path: URL_FIREWALL, Component: Firewall, exact: true },
    { path: URL_AUDIT, Component: Audit, exact: true },
    { path: URL_WIREGUARD, Component: Wireguard, exact: true },
    { path: URL_SHAREBROWSER_AUTH, Component: ShareBrowser, exact: true },
    { path: URL_CLUSTER, Component: Cluster, exact: true },
    { path: URL_FILE_NAVIGATOR_LOG, Component: FileNavigatorLog, exact: true },
    { path: URL_CENTRAL_STATUS, Component: CentralStatus, exact: true }
];

export const PUBLIC_ROUTES = [
    { path: URL_LOGIN, Component: Login, exact: true },
    { path: URL_EULA, Component: Eula, exact: true },
    { path: URL_SPLASH_PAGE, Component: SplashPage, exact: true },
    { path: URL_QUICK_START, Component: QuickStart, exact: true }
];
