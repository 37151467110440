import { types } from "mobx-state-tree";

import Request from "api/Request";
import AuditSettings from "api/audit/Types/AuditSettings";

const SetAuditSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Audit",
    method: "set_audit_settings",
    arguments: types.maybe(AuditSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetAuditSettings;
