import React from "react";

const PlayIcon = props => {
    return (
        <svg
            {...props}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <filter x="-.5%" y="-1.1%" width="101%" height="103%" filterUnits="objectBoundingBox" id="playIcon-a">
                    <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0.270588235 0 0 0 0 0.290196078 0 0 0 0 0.62745098 0 0 0 0.12 0"
                        in="shadowBlurOuter1"
                    />
                </filter>
                <path
                    d="M10.256 0h1339.488c3.567 0 4.86.371 6.163 1.069a7.27 7.27 0 0 1 3.024 3.024c.698 1.303 1.069 2.596 1.069 6.163v445.488c0 3.567-.371 4.86-1.069 6.163a7.27 7.27 0 0 1-3.024 3.024c-1.303.698-2.596 1.069-6.163 1.069H10.256c-3.567 0-4.86-.371-6.163-1.069a7.27 7.27 0 0 1-3.024-3.024C.37 460.604 0 459.311 0 455.744V10.256c0-3.567.371-4.86 1.069-6.163a7.27 7.27 0 0 1 3.024-3.024C5.396.37 6.689 0 10.256 0z"
                    id="playIcon-b"
                />
            </defs>
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" d="M-1178-124H262V900h-1440z" />
                <path d="M-1178-124H262v1216h-1440z" fill="#EDF2F6" />
                <g transform="translate(-1138 -20)">
                    <use fill="#000" filter="url(#playIcon-a)" xlinkHref="#playIcon-b" />
                    <use fill="#FFF" xlinkHref="#playIcon-b" />
                </g>
                <rect width="166" height="40" rx="4" fill="#1E90FF" transform="translate(-8 -8)" />
                <path
                    d="m6 4.399.127.008a1 1 0 0 1 .359.118l11.881 6.6a.997.997 0 0 1 .389 1.36 1 1 0 0 1-.389.39l-11.881 6.6a.997.997 0 0 1-1.36-.388A1 1 0 0 1 5 18.6V5.399a.997.997 0 0 1 1-1z"
                    stroke="#FFF"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
};

export default PlayIcon;
