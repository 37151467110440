import { types } from "mobx-state-tree";

import Request from "api/Request";
import DeleteFileArguments from "../Types/DeleteFileArguments";

const DeleteFile = Request.props({
    path: "/com/studionetworksolutions/evo/Files",
    method: "delete_file",
    arguments: types.maybe(DeleteFileArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default DeleteFile;
