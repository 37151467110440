import { types } from "mobx-state-tree";

const ExternalShare = types.model({
    name: types.string,
    host: types.string,
    user: types.string,
    share: types.string,
    status: types.string,
    size: types.number,
    used: types.number,
    protocol: types.string
});

export default ExternalShare;
