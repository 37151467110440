import React, { useContext, useEffect } from "react";
import { useObserver } from "mobx-react";
import MainTemplate from "components/MainTemplate";
import HeaderTitle from "components/HeaderTitle";
import { Grid } from "@material-ui/core";
import { Context } from "store";
import { useStyles } from "./Wireguard.styles";
import { WIREGUARD } from "const/shortcutNameConst";
import { useTranslation } from "react-i18next";
import ShieldIcon from "assets/ShieldIcon";
import WireguardPageBody from "./components/WireguardPageBody";
import WireguardDrawer from "./components/WireguardDrawer/WireguardDrawer";

const Wireguard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        store: { userStore }
    } = useContext(Context);

    useEffect(() => {
        if (userStore.searchResult === null) {
            userStore.resetCurrentSearchRequest();
            userStore.searchOnUsersPage();
        } else {
            if (userStore.lastSearch) {
                userStore.applyLastSearchToRequest();
                userStore.searchOnUsersPage();
            }
        }
    }, []);

    return useObserver(() => (
        <MainTemplate
            tabIndex={1}
            headerTitle={<HeaderTitle icon={<ShieldIcon />} title={t("wireguard.title")} shortcutName={WIREGUARD} />}
        >
            <>
                <WireguardDrawer />
                <Grid container direction={"column"} wrap={"nowrap"} spacing={6}>
                    <Grid item className={classes.resultContainer}>
                        <WireguardPageBody />
                    </Grid>
                </Grid>
            </>
        </MainTemplate>
    ));
};

export default Wireguard;
