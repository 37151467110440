import { types } from "mobx-state-tree";

import SersorsData from "api/evo_info/Types/SersorsData";

const SersorsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(SersorsData)
});

export default SersorsResult;
