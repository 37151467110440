import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        height: "100%",
        padding: theme.spacing(4, 2, 4, 0),
        width: "100%",
        margin: 0,
        flexWrap: "nowrap"
    },
    opacityText: {
        fontSize: 14,
        opacity: 0.6
    },
    strongText: {
        fontSize: 14,
        fontWeight: 500,
        opacity: 1,
        margin: theme.spacing(0, 2)
    }
}));
