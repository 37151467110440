import React from "react";
import MainTemplate from "components/MainTemplate";
import HeaderTitle from "components/HeaderTitle";
import { Grid } from "@material-ui/core";
import NasConfigurationCard from "./components/NASConfiguration/NasConfigurationCard";
import EthernetBondingParametersCard from "./components/EthernetBondingParameters/EthernetBondingParametersCard";
import Certificate from "./components/Certificate";
import { useTranslation } from "react-i18next";
import AdvancedIcon from "assets/AdvancedIcon";
import GlobalMasksCard from "./components/GlobalMasksCard";
import { ADVANCED } from "const/shortcutNameConst";
import NasRecycleBinCard from "./components/NASRecycleBin/NasRecycleBinCard/NasRecycleBinCard";
import GraphsSettingsCard from "./components/GraphsSettingsCard";
import NetworkingSettingsCard from "./components/NetworkingSettingsCard/NetworkingSettingsCard/NetworkingSettingsCard";
//  hided cluster from Advanced page
//  import ClusterSettingsCard from "./components/ClusterSettingsCard";

const Advanced = () => {
    const { t } = useTranslation();
    return (
        <MainTemplate headerTitle={<HeaderTitle icon={<AdvancedIcon />} title={t("advanced.title")} shortcutName={ADVANCED} />}>
            <Grid container alignContent={"flex-start"} spacing={6}>
                <Grid item xs={12} md={6}>
                    <EthernetBondingParametersCard />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Certificate />
                </Grid>
                <Grid item xs={12} md={6}>
                    <NasRecycleBinCard />
                </Grid>
                <Grid item xs={12} md={6}>
                    <GraphsSettingsCard />
                </Grid>
                <Grid item xs={12} md={6}>
                    <GlobalMasksCard />
                </Grid>
                <Grid item xs={12} md={6}>
                    <NasConfigurationCard />
                </Grid>
                <Grid item xs={12} md={6}>
                    <NetworkingSettingsCard />
                </Grid>
                {/* hided cluster from Advanced page
                <Grid item xs={12} md={6}>
                    <ClusterSettingsCard />
                </Grid>  
                */}
            </Grid>
        </MainTemplate>
    );
};

export default Advanced;
