import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    switchLabel: {
        marginLeft: 0,
        "& > span:last-child": {
            marginLeft: theme.spacing(4)
        }
    }
}));
