import { types } from "mobx-state-tree";

import UpdateFile from "api/upgrade/Types/UpdateFile";

const ListUpdatesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(UpdateFile)
});

export default ListUpdatesResult;
