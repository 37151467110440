import { types } from "mobx-state-tree";

import FirewallAdvancedInfo from "api/firewall/Types/FirewallAdvancedInfo";

const FirewallAdvancedInfoResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(FirewallAdvancedInfo)
});

export default FirewallAdvancedInfoResult;
