import { types } from "mobx-state-tree";

const Notification = types.model({
    emailEnabled: types.boolean,
    emailReadonly: types.boolean,
    audioEnabled: types.boolean,
    audioReadonly: types.boolean,
    snmpEnabled: types.boolean,
    snmpReadonly: types.boolean
});

export default Notification;
