import { types } from "mobx-state-tree";

import PageQuery from "api/general/Types/PageQuery";

const ListFilesArguments = types.model({
    shareName: types.string,
    dirOnShare: types.string,
    query: types.maybe(PageQuery)
});

export default ListFilesArguments;
