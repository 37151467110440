import { getParent, isAlive } from "mobx-state-tree";

export default function SuccessMessageHandling(call, next) {
    if (call.type === "flow_resume") {
        //get store or multiEvo store by call.context
        if (!isAlive(call.context)) return next(call);
        const store = getParent(call.context);
        if (!store) return next(call);

        if (call.args[0]?.messages?.length > 0) {
            store.processingStore && store.processingStore.setSuccess(call.args[0].messages.join("\n"));
        }
        if (call.args[0]?.warnings?.length > 0) {
            store.processingStore && store.processingStore.setWarning(call.args[0].warnings.join("\n"));
        }
    }
    next(call);
}
