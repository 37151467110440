import rangeValidator from "utils/rangeValidator";
import { PORT_MAX, PORT_MIN } from "const/firewallConst";
import i18n from "i18n";

const createFields = () => {
    return [
        {
            name: "host",
            rules: "required|host"
        },
        {
            name: "enabled",
            type: "checkbox"
        },
        {
            name: "version"
        },
        {
            name: "https",
            type: "checkbox"
        },
        {
            name: "port",
            validators: [
                rangeValidator(
                    PORT_MIN,
                    PORT_MAX,
                    i18n.t("alerts.snmp_service_configuration_dialog.port_number.hint", { min: PORT_MIN, max: PORT_MAX })
                )
            ]
        },
        {
            name: "path"
        }
    ];
};

export default createFields;
