import React, { useEffect } from "react";
import DataCard from "components/DataCard";
import { Table, TableHead, TableBody, TableRow, TableCell, Grid, ButtonBase, Box, Divider } from "@material-ui/core";
import TablePagination from "components/TablePagination";
import { useTranslation } from "react-i18next";
import { observer, useLocalStore } from "mobx-react";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import PlusIcon from "assets/PlusIcon";
import MoreMenu from "components/MoreMenu";
import TableSortLabel from "components/TableSortLabel";
import { useStyles } from "./AutomationsTabContent.styles";
import Select from "components/MobxForm/Select";
import createFields from "./createFields";
import createForm from "utils/createForm";
import { TABLE_TYPE_USER, TABLE_TYPE_INDEXING, TABLE_TYPE_AIML } from "const/slingshotAutomationsConst";
import AutomationRow from "./components/AutomationRow";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import { SLINGSHOT_AUTOMATION_NAME, SLINGSHOT_AUTOMATION_ENABLED } from "const/sortColumnConst";
import { SLINGSHOT_AUTOMATIONS_CARD } from "const/widgetNameConst";
import CreateAutomationModal from "../CreateAutomationModal";
import AutomationsDrawer from "../AutomationsDrawer";
import { useStore } from "hooks/useStore";
import { useLauncher } from "hooks/useLauncher";

const AUTOMATIONS_TAB_CONTENT_ID_PREFIX = `automations_tab_content`;

const AutomationsTabContent = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${AUTOMATIONS_TAB_CONTENT_ID_PREFIX}` : AUTOMATIONS_TAB_CONTENT_ID_PREFIX;
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: { automationsStore, emailConfigurationStore, authStore },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        isLoading: true,
        beginLoading() {
            state.isLoading = true;
        },
        endLoading() {
            state.isLoading = false;
        },
        lastSearchRequest: null,
        saveLastSearchRequest() {
            this.lastSearchRequest = this.form.$("tableType").value;
        },
        isCreateAutomationModalOpen: false,
    }));

    useEffect(() => {
        state.form.reset();
    }, []);

    const openCreateAutomationModal = () => {
        state.isCreateAutomationModalOpen = true;
    };

    const closeCreateAutomationModal = () => {
        state.isCreateAutomationModalOpen = false;
    };

    const isCorrectTableType =
        state.form.$("tableType").value !== state.lastSearchRequest && state.form.$("tableType").value !== null;

    const { isError } = useLauncher(
        [
            state.beginLoading,
            () => isCorrectTableType && automationsStore.fetchAutomations(state.form.$("tableType").value),
            state.saveLastSearchRequest,
            state.endLoading,
            () => isCorrectTableType && automationsStore.setCurrentAutomationListType(state.form.$("tableType").value),
            emailConfigurationStore.getEmailSettings,
        ],
        { ignoreErrors: false, dependencies: [state.form.$("tableType").value, automationsStore.isErrorConnectedDBus] }
    );

    const handleChangePage = (newPage) => {
        automationsStore.updatePage(+newPage);
        automationsStore.fetchAutomations(state.form.$("tableType").value);
        automationsStore.setCurrentAutomationId(null);
    };
    const handleChangeRowsPerPage = (e) => {
        automationsStore.updateLimit(+e.target.value);
        automationsStore.updatePage(0);
        automationsStore.fetchAutomations(state.form.$("tableType").value);
    };
    const changeSorting = (column) => () => {
        automationsStore.changeSorting(column);
        automationsStore.fetchAutomations(state.form.$("tableType").value);
    };

    const openDrawerIfNeeded = (id) => () => {
        automationsStore.currentAutomationId === id
            ? automationsStore.setCurrentAutomationId(null)
            : automationsStore.setCurrentAutomationId(id);
    };

    const handleChangeSelect = (e) => {
        state.form.$("tableType").value = e.target.value;
        automationsStore.setCurrentAutomationId(null);
        automationsStore.updatePage(0);
        automationsStore.fetchAutomations(state.form.$("tableType").value);
        automationsStore.setCurrentAutomationListType(state.form.$("tableType").value);
    };

    const getTableTypeOptions = () => {
        const userOption = { value: TABLE_TYPE_USER, label: t("slingshot.automations.card_variant.user") };
        const indexingOption = { value: TABLE_TYPE_INDEXING, label: t("slingshot.automations.card_variant.tasks") };
        const AIMLOption = { value: TABLE_TYPE_AIML, label: t("slingshot.automations.card_variant.aiml") };

        if (authStore.isAdmin) return [userOption, indexingOption, AIMLOption];

        return [userOption, AIMLOption];
    };

    return (
        <Grid item container xs={12} wrap="nowrap">
            <DataCard
                autoHeight
                headerDivider
                title={
                    <Grid container>
                        <Grid item>
                            <Select
                                onChange={handleChangeSelect}
                                field={state.form.$("tableType")}
                                className={classes.rootSelect}
                                options={getTableTypeOptions()}
                                id={`${idPrefix}_table_type`}
                            />
                        </Grid>
                    </Grid>
                }
                headerControl={[
                    <MoreMenu key={`data_card-header_control-control-1`}>
                        <HomeCardActionMenuItem
                            widget={SLINGSHOT_AUTOMATIONS_CARD}
                            homeCardActionMenuItemId={`${idPrefix}_add_to_home`}
                        />
                    </MoreMenu>,
                    state.form.$("tableType").value === TABLE_TYPE_USER && !isError && (
                        <ButtonBase
                            onClick={openCreateAutomationModal}
                            key={`data_card-header_control-control-2`}
                            id={`${idPrefix}_open_create_automation_modal`}
                        >
                            <PlusIcon />
                        </ButtonBase>
                    ),
                ]}
                emptyPadding
            >
                {automationsStore.automationsCount > 0 && !state.isLoading ? (
                    <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={changeSorting(SLINGSHOT_AUTOMATION_NAME)}
                                            direction={automationsStore.getAutomationsArguments.sort_dir.toLowerCase()}
                                            active={automationsStore.getAutomationsArguments.sort === SLINGSHOT_AUTOMATION_NAME}
                                            id={`${idPrefix}_sort_name`}
                                        >
                                            {t("slingshot.automations.table.header.name")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t("slingshot.automations.table.header.launch")}</TableCell>
                                    <TableCell>{t("slingshot.automations.table.header.state")}</TableCell>
                                    <TableCell>{t("slingshot.automations.table.header.start")}</TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={changeSorting(SLINGSHOT_AUTOMATION_ENABLED)}
                                            direction={automationsStore.getAutomationsArguments.sort_dir.toLowerCase()}
                                            active={
                                                automationsStore.getAutomationsArguments.sort === SLINGSHOT_AUTOMATION_ENABLED
                                            }
                                            id={`${idPrefix}_sort_enabled`}
                                        >
                                            {t("slingshot.automations.table.header.enabled")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {automationsStore.automations.map((automation) => (
                                    <AutomationRow
                                        onClick={openDrawerIfNeeded(automation.id)}
                                        automationId={automation.id}
                                        key={automation.id}
                                        internal={automation.internal}
                                        tasks={automation.tasks}
                                        schedule={automation.schedule || "-"}
                                        deleted={automation.deleted}
                                        enabled={automation.enabled}
                                        paused={automation.paused}
                                        instances={automation.instances}
                                        lastInstance={automation.last_instance?.result}
                                        name={automation.name}
                                        id={`${idPrefix}_automation_row_${automation.id}`}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                        <Grid>
                            <Divider />
                            <Box className={classes.paginationContainer}>
                                <TablePagination
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    onChangePage={handleChangePage}
                                    count={automationsStore.automationsCount}
                                    rowsPerPage={automationsStore.getAutomationsArguments.limit}
                                    page={automationsStore.getAutomationsArguments.page}
                                    parentId={idPrefix}
                                />
                            </Box>
                        </Grid>
                    </>
                ) : (
                    <EmptyCardPlaceholder inProgress={state.isLoading} message={t("slingshot.automations.table.placeholder")} />
                )}
                <AutomationsDrawer parentId={idPrefix} />
                <CreateAutomationModal
                    open={state.isCreateAutomationModalOpen}
                    onClose={closeCreateAutomationModal}
                    parentId={idPrefix}
                />
            </DataCard>
        </Grid>
    );
};

export default observer(AutomationsTabContent);
