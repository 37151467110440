import { types } from "mobx-state-tree";

import DomainObjectId from "api/security/Types/DomainObjectId";
import ShareRootAccess from "api/security/acl/Types/ShareRootAccess";

const SetSharesRootAccessArguments = types.model({
    user: types.maybe(DomainObjectId),
    permissions: types.array(ShareRootAccess)
});

export default SetSharesRootAccessArguments;
