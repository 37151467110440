import React from "react";

import { Link as MuiLink } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import { useStyles } from "./Link.styles";
import { HashLink } from "react-router-hash-link";

const Link = ({ children, path = "", inNewTab, Icon, anchor }) => {
    const classes = useStyles();

    if (anchor) {
        return (
            <HashLink className={classes.link} smooth to={path}>
                {Icon ? <Icon /> : children}
            </HashLink>
        );
    }

    if (path.includes("http")) {
        const defaultInNewTab = true;
        const hasInNewTab = typeof inNewTab === "boolean";
        const isInNewTab = hasInNewTab ? inNewTab : defaultInNewTab;

        return (
            <MuiLink
                target={isInNewTab ? "_blank" : "_self"}
                className={classes.link}
                href={path}
                rel={inNewTab && "noopener noreferrer"}
            >
                {Icon ? <Icon /> : children}
            </MuiLink>
        );
    }

    return (
        <RouterLink
            className={classes.link}
            to={path}
            target={inNewTab ? "_blank" : "_self"}
            rel={inNewTab && "noopener noreferrer"}
        >
            {Icon ? <Icon /> : children}
        </RouterLink>
    );
};

export default Link;
