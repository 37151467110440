import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => ({
    lastCell: {
        width: "5%",
    },
    formControlLabel: {
        alignItems: "flex-start",
        "& .MuiCheckbox-root": {
            top: -9,
            marginRight: theme.spacing(1.5),
        },
    },
    cellWithIcon: {
        "& svg path": {
            fill: theme.palette.grey[300],
        },
    },
    disabled: {
        "& path": {
            fill: theme.palette.grey["300"],
        },
    },
    expansionPanel: {
        margin: theme.spacing(0, -2),
        "&&": {
            boxShadow: "none",
            border: `${theme.palette.expandPanel.border.color} 1px solid`,
            borderRadius: theme.spacing(2),
        },
        "&&.MuiAccordion-root.Mui-expanded": {
            margin: theme.spacing(0, -2),
            paddingBottom: theme.spacing(5),
        },
    },
    expansionIconClose: {
        transform: "rotate(180deg)",
    },
    expansionIcon: {
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.1),
        borderRadius: "50%",
    },
    expansionLabel: {
        fontSize: 16,
        fontWeight: 500,
        opacity: 0.8,
    },
    expansionPanelContent: {
        "&&": {
            display: "flex",
            justifyContent: "space-between",
        },
    },
}));
