import React from "react";
import { useObserver } from "mobx-react";
import DataCard from "components/DataCard";
import { Typography, Grid, Box } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { URL_ADVANCED } from "routes";
import { Link } from "react-router-dom";
import { useStyles } from "./EmptyCard.styles";

const EmptyCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return useObserver(() => (
        <DataCard title={t("cluster.title")} emptyPadding headerDivider autoHeight>
            <Box py={20} px={6}>
                <Grid container direction={"column"} alignItems={"center"} justify={"center"}>
                    <Typography>
                        <Trans
                            i18nKey={"cluster.empty_card.content"}
                            components={<Link className={classes.link} to={URL_ADVANCED} />}
                        />
                    </Typography>
                </Grid>
            </Box>
        </DataCard>
    ));
};

export default EmptyCard;
