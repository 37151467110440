import React, { useContext, useEffect, useCallback } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./BridgeDrawer.styles";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { observer, useLocalStore } from "mobx-react";
import StatusTranslation from "../StatusTranslation";
import SavedInputIndicator from "components/SavedInputIndicator";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import EditSettingsDialog from "../EditSettingsDialog";
import EthernetIcon from "assets/EthernetIcon";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import EditServiceOrderDialog from "../EditServiceOrderDialog";
import useStoreByIp from "hooks/useStoreByIp";
import EditIcon from "assets/EditIcon";
import FiberIcon from "assets/FibreIcon";
import CloseIcon from "assets/CloseIcon";
import RefreshIcon from "assets/RefreshIcon";

const BridgeDrawer = ({ ip }) => {
    const drawerName = ip ? `${ip}BridgeDrawer` : "BridgeDrawer";
    const {
        store: { uiStore },
    } = useContext(Context);
    const { bridgeStore, ethernetPortsStore } = useStoreByIp({ ip });
    const { t } = useTranslation();
    const classes = useStyles({ isWidgetOpen: uiStore.isWidgetsBarOpen });
    const currentBridge = bridgeStore.currentBridge || { dhcpServer: {}, dhcpClient: {}, stp: {} };
    const state = useLocalStore(() => ({
        settingsDialogOpen: false,
        serviceOrderDialogOpen: false,
        deactivateVirtualSwitchInProgress: false,
        renewInProgress: false,
    }));
    useEffect(() => {
        if (bridgeStore.currentBridgeName) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, closeDrawer);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [bridgeStore.currentBridgeName]);

    const closeDrawer = () => {
        bridgeStore.setCurrentBridge("");
    };
    const renderDetails = () => {
        return (
            <Grid className={classes.inputsContainer} container direction={"column"} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.bond_drawer.label.interfaces")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBridge.interfaces?.join(", ")}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.ip")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBridge.ip}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.subnet")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBridge.netmask}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.gateway")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBridge.gateway}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.speed")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBridge.speed}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.status")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            <StatusTranslation label={currentBridge.status} />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.mtu")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBridge.mtu}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.metric")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBridge.metric}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.mac")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBridge.mac}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.role")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            <StatusTranslation label={bridgeStore.roleOfCurrentPort} />
                        </Typography>
                    </Grid>
                </Grid>
                {bridgeStore.isDhcpClientRole && (
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("network.ethernet_ports_drawer.label.override_dns")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SavedInputIndicator enabled={currentBridge.dhcpClient.overrideDns} />
                        </Grid>
                    </Grid>
                )}
                {bridgeStore.isDhcpServerRole && (
                    <>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("network.ethernet_ports_drawer.label.dhcp_from")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>{currentBridge.dhcpServer.startDhcpRange}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("network.ethernet_ports_drawer.label.dhcp_to")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>{currentBridge.dhcpServer.endDhcpRange}</Typography>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.stp")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <SavedInputIndicator enabled={currentBridge.stp.enabled} />
                    </Grid>
                </Grid>
                {currentBridge.stp.enabled && (
                    <>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("network.ethernet_ports_drawer.label.switch_priority")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>{currentBridge.stp.switchPriority}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("network.ethernet_ports_drawer.label.forward_delay")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>{currentBridge.stp.forwardDelay}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("network.ethernet_ports_drawer.label.hello_time")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>{currentBridge.stp.helloTime}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("network.ethernet_ports_drawer.label.max_age")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>{currentBridge.stp.maxAge}</Typography>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    };
    const openSettingsDialog = () => {
        state.settingsDialogOpen = true;
    };
    const closeSettingsDialog = () => {
        state.settingsDialogOpen = false;
    };

    const renewDhcpLease = async () => {
        state.renewInProgress = true;
        await bridgeStore.renewDhcpLease({ port: bridgeStore.currentBridgeName });
        state.renewInProgress = false;
        ethernetPortsStore.fetchAllNetRequests();
    };

    const removeBridge = async () => {
        state.deactivateVirtualSwitchInProgress = true;
        await bridgeStore.removeBridge({ port: bridgeStore.currentBridgeName });
        state.deactivateVirtualSwitchInProgress = false;

        ethernetPortsStore.fetchAllNetRequests();
        closeDrawer();
    };
    const openServiceOrderDialog = useCallback(() => {
        state.serviceOrderDialogOpen = true;
    });
    const closeServiceOrderDialog = useCallback(() => {
        state.serviceOrderDialogOpen = false;
    });
    const renderActions = () => {
        return (
            <>
                <BulkBarFlatButton icon={<EditIcon />} onClick={openSettingsDialog}>
                    {t("network.ethernet_ports_drawer.button.edit_settings")}
                </BulkBarFlatButton>
                <BulkBarFlatButton icon={<FiberIcon />} onClick={openServiceOrderDialog}>
                    {t("network.ethernet_ports_drawer.button.edit_service_order")}
                </BulkBarFlatButton>
                {bridgeStore.isDhcpClientRole && (
                    <BulkBarFlatButton icon={<RefreshIcon />} inProgress={state.renewInProgress} onClick={renewDhcpLease}>
                        {t("network.ethernet_ports_drawer.button.renew_dhcp_lease")}
                    </BulkBarFlatButton>
                )}
                <BulkBarFlatButton
                    icon={<CloseIcon />}
                    onClick={removeBridge}
                    inProgress={state.deactivateVirtualSwitchInProgress}
                >
                    {t("network.ethernet_ports_drawer.button.deactivate")}
                </BulkBarFlatButton>
            </>
        );
    };
    const submitBridgeChanges = async (data) => {
        const res = await bridgeStore.saveNetBridgeSettings(data);
        ethernetPortsStore.fetchEthernetPorts();
        ethernetPortsStore.fetchAllNetRequests();
        return res;
    };
    return (
        <DetailsActionsDrawer
            title={currentBridge.port}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            icon={<EthernetIcon />}
            modals={[
                <EditServiceOrderDialog
                    ip={ip}
                    key={"BridgeEditServiceOrderDialog"}
                    open={state.serviceOrderDialogOpen}
                    handleClose={closeServiceOrderDialog}
                />,
                <EditSettingsDialog
                    key={"BridgeEditSettingsDialog"}
                    store={bridgeStore}
                    handleClose={closeSettingsDialog}
                    open={state.settingsDialogOpen}
                    title={t("network.ethernet_edit_settings_dialog.title", { port: currentBridge.port || "" })}
                    onSubmit={submitBridgeChanges}
                    currentPort={currentBridge}
                />,
            ]}
        />
    );
};

export default observer(BridgeDrawer);
