import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { isAlive } from "mobx-state-tree";
import { SizeMe } from "react-sizeme";

import { DEFAULT_ANIM_DURATION } from "const/chartConst";
import StorageUsageGraph from "./StorageUsageGraph";
import useStoreByIp from "hooks/useStoreByIp";

const StorageUsageWidget = ({ parentId, height = 150, animDuration = DEFAULT_ANIM_DURATION, isDark, ip }) => {
    const { storageUsageGraphStore, uiStore } = useStoreByIp({ ip });
    const state = useLocalStore(() => ({
        key: ""
    }));

    useEffect(() => {
        storageUsageGraphStore.registerGraph();
        storageUsageGraphStore.fetchData();
        return () => {
            if (!isAlive(storageUsageGraphStore)) return;
            storageUsageGraphStore.unregisterGraph();
            storageUsageGraphStore.stopFetching();
        };
    }, []);

    return useObserver(() => {
        if (storageUsageGraphStore.chartInfo.length === 0) return null;
        const graphAutoscaling = uiStore.parameters?.graphAutoscaling;

        return (
            <SizeMe refreshRate={500}>
                {({ size }) => {
                    if (size.width) state.key = `${parentId}-${size.width}-${graphAutoscaling}`;
                    const uniqueParentId = ip
                        ? parentId +
                          String(ip)
                              .split(".")
                              .join("")
                        : parentId;
                    return (
                        <StorageUsageGraph
                            {...{
                                key: state.key,
                                parentId: uniqueParentId,
                                height,
                                animDuration,
                                isDark,
                                chartInfo: storageUsageGraphStore.chartInfo,
                                startFetching: storageUsageGraphStore.fetchData,
                                stopFetching: storageUsageGraphStore.stopFetching,
                                lastTime: storageUsageGraphStore.lastTime,
                                lastValue: storageUsageGraphStore.lastValue,
                                graphAutoscaling
                            }}
                        />
                    );
                }}
            </SizeMe>
        );
    });
};

export default StorageUsageWidget;
