import React from "react";
import { useObserver } from "mobx-react";
import WireguardTable from "../WireguardTable/WireguardTable";
import WireguardInfoCard from "../WireguardInfoCard/WireguardInfoCard";
import { Grid } from "@material-ui/core";
import { useStyles } from "./WireguardPageBody.styles";

const WireguardPageBody = () => {
    const classes = useStyles();

    return useObserver(() => <Grid spacing={6}>
        <Grid item className={classes.infoCard}><WireguardInfoCard /></Grid>
        <Grid item><WireguardTable /></Grid>
        </Grid>);
};

export default WireguardPageBody;
