import React from "react";
import Dialog from "components/Dialog";
import { Button, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const InheritedPermissionsDialog = ({ open, onClose, convert, remove }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            maxWidth={"xl"}
            title={t("file_navigator.advanced_security_modal.inherited_permissions_modal.title")}
            onClose={onClose}
            open={open}
            withoutClose
            submitBtn={
                <Button onClick={remove} variant={"contained"} color={"primary"}>
                    {t("file_navigator.advanced_security_modal.inherited_permissions_modal.button.remove")}
                </Button>
            }
            buttons={
                <>
                    <Button variant={"contained"} color={"secondary"} onClick={onClose}>
                        {t("common.button.cancel")}
                    </Button>
                    <Button variant={"contained"} color={"primary"} onClick={convert}>
                        {t("file_navigator.advanced_security_modal.inherited_permissions_modal.button.convert")}
                    </Button>
                </>
            }
        >
            <Grid container direction={"column"} spacing={6}>
                <Grid item>
                    <Typography>{t("file_navigator.advanced_security_modal.inherited_permissions_modal.message")}</Typography>{" "}
                </Grid>
                <Grid item>
                    <Typography>{t("file_navigator.advanced_security_modal.inherited_permissions_modal.question")}</Typography>{" "}
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default InheritedPermissionsDialog;
