import { types } from "mobx-state-tree";

import Request from "api/Request";
import IfaceNamesArrayArguments from "api/net/Types/IfaceNamesArrayArguments";

const CreateBond = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "create_bond",
    arguments: types.maybe(IfaceNamesArrayArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default CreateBond;
