import { types } from "mobx-state-tree";

const TrashFile = types.model({
    type: types.string,
    versions: types.number,
    deleteTime: types.string,
    modifiedTime: types.string,
    size: types.number,
    name: types.string
});

export default TrashFile;
