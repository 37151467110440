import React, { useContext, useEffect } from "react";
import MainTemplate from "components/MainTemplate";
import HeaderTitle from "components/HeaderTitle";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./Home.styles";
import { Context } from "store";
import { useObserver } from "mobx-react";
import DraggableContainer from "components/DraggableContainer";
import cardFactory from "utils/cardFactory";
import { CARD, COLUMN } from "const/dragAndDropTypes";
import BulkOperationsBar from "pages/DisksAndPools/components/BulkOperationsBar";
import LogicalDisksDrawer from "pages/DisksAndPools/components/LogicalDisksDrawer";
import IfacesActivationSideBar from "pages/HardwareActivation/components/IfacesActivationSideBar";
import QuadsActivationSideBar from "pages/HardwareActivation/components/QuadsActivationSideBar";
import DragLayer from "components/DragLayer";
import { useLocalStore } from "mobx-react-lite";
import DndTrash from "components/DndTrash/DndTrash";
import RemoteShareDrawer from "pages/Shares/components/RemoteShareDrawer";
import RemoteSharesBulkBar from "pages/Shares/components/RemoteSharesBulkBar";
import WireguardDrawer from "../Wireguard/components/WireguardDrawer";

const Home = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { uiStore },
    } = useContext(Context);

    useEffect(() => {
        uiStore.fetchDashboardLayout();
    }, []);

    const handleDrop = (fromRow, fromColumn, toRow, toColumn) => {
        uiStore.changeLayoutColumn({ fromRow, fromColumn, toRow, toColumn });
        uiStore.setDashboardLayout();
    };
    const state = useLocalStore(() => ({
        draggableElementTitle: null,
    }));
    const setupPreview = (title) => {
        state.draggableElementTitle = title;
    };
    const resetToDefault = () => {
        state.draggableElementTitle = null;
    };
    const onTrashDrop = (item) => {
        uiStore.removeLayout(item.column, item.row);
    };
    return useObserver(() => (
        <MainTemplate tabIndex={1} largeWidthContent headerTitle={<HeaderTitle title={t("home.title")} />}>
            <DragLayer previewTitle={state.draggableElementTitle}>
                <Grid container spacing={6}>
                    <BulkOperationsBar />
                    <LogicalDisksDrawer />
                    <IfacesActivationSideBar />
                    <QuadsActivationSideBar />
                    <RemoteSharesBulkBar />
                    <RemoteShareDrawer />
                    <WireguardDrawer />
                    <Grid xs={4} item>
                        <DraggableContainer
                            className={classes.column}
                            notDraggable
                            type={COLUMN}
                            id={"leftColumn"}
                            column={0}
                            row={uiStore.leftColumn.length}
                            notGreedy={uiStore.leftColumn.length !== 0}
                            handleDrop={handleDrop}
                            resetToDefault={resetToDefault}
                            accept={CARD}
                        >
                            <Grid container>
                                {uiStore.leftColumn.map((layout, index) => {
                                    const { component, previewTitle } = cardFactory(layout.widget, layout.contentId);
                                    return (
                                        <Grid xs={12} key={layout.contentId} item>
                                            <DraggableContainer
                                                previewTitle={previewTitle}
                                                setupPreview={setupPreview}
                                                id={layout.contentId}
                                                column={0}
                                                row={index}
                                                handleDrop={handleDrop}
                                                type={CARD}
                                                resetToDefault={resetToDefault}
                                                accept={CARD}
                                            >
                                                {component}
                                            </DraggableContainer>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </DraggableContainer>
                    </Grid>
                    <Grid xs={8} item>
                        <DraggableContainer
                            className={classes.column}
                            notDraggable
                            type={COLUMN}
                            id={"rightColumn"}
                            column={1}
                            notGreedy={uiStore.rightColumn.length !== 0}
                            row={uiStore.rightColumn.length}
                            handleDrop={handleDrop}
                            resetToDefault={resetToDefault}
                            accept={CARD}
                        >
                            <Grid container>
                                {uiStore.rightColumn.map((layout, index) => {
                                    const { component, previewTitle } = cardFactory(layout.widget, layout.contentId);
                                    return (
                                        <Grid xs={12} key={layout.contentId} item>
                                            <DraggableContainer
                                                previewTitle={previewTitle}
                                                id={layout.contentId}
                                                column={1}
                                                row={index}
                                                setupPreview={setupPreview}
                                                handleDrop={handleDrop}
                                                type={CARD}
                                                resetToDefault={resetToDefault}
                                                accept={CARD}
                                            >
                                                {component}
                                            </DraggableContainer>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </DraggableContainer>
                    </Grid>
                    <DndTrash withPlusButton onDrop={onTrashDrop} accept={CARD} className={classes.trashDndExtender} />
                </Grid>
            </DragLayer>
        </MainTemplate>
    ));
};

export default Home;
