import React, { forwardRef } from "react";
import { MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ADMIN } from "const/userRolesConst";
import { useObserver } from "mobx-react";
import { useStore } from "hooks/useStore";
import { toJS } from "mobx";
import { useLocation } from "react-router-dom";
import { URL_HOME } from "routes";

const HomeCardActionMenuItem = forwardRef(({ widget, contentId, homeCardActionMenuItemId }, ref) => {
    const { t } = useTranslation();
    const location = useLocation();

    const {
        store: { uiStore, processingStore, authStore }
    } = useStore();

    const addToHome = async () => {
        try {
            await uiStore.addLayoutToHomePage(widget, contentId);
        } catch (error) {
            processingStore.setError(error);
        }
    };
    const removeFromHome = () => {
        uiStore.removeLayoutByWidgetAndContentId(widget, contentId);
    };

    const hasComponentInHome = [...toJS(uiStore.leftColumn), ...toJS(uiStore.rightColumn)].find(component => {
        return component.widget === widget && (!contentId || component.contentId === contentId);
    });

    return useObserver(() =>
        location?.pathname === URL_HOME || hasComponentInHome ? (
            <MenuItem onClick={removeFromHome} disabled={authStore.role !== ADMIN} id={homeCardActionMenuItemId} ref={ref}>
                {t("common.button.remove_from_home")}
            </MenuItem>
        ) : (
            <MenuItem onClick={addToHome} disabled={authStore.role !== ADMIN} id={homeCardActionMenuItemId} ref={ref}>
                {t("common.button.add_to_home")}
            </MenuItem>
        )
    );
});

export default HomeCardActionMenuItem;
