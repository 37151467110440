import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => ({
    container: {
        marginLeft: "2px",
        "&:hover:not(.Mui-checked) svg path": {
            fill: "#bfd1e1"
        }
    },
    error: {
        color: theme.palette.error.main,
        "& svg rect": {
            fill: theme.palette.error.main
        },
        "&:not(.Mui-checked) svg path": {
            fill: theme.palette.error.main
        },
        "&.Mui-checked": {
            color: theme.palette.error.main,
            "& svg rect": {
                fill: theme.palette.error.main
            }
        },
        "&:hover": {
            backgroundColor: hexToRgba(theme.palette.error.main, 0.04),
            "&:not(.Mui-checked) svg path": {
                fill: theme.palette.error.main
            }
        },
        "&.Mui-checked:hover": {
            backgroundColor: hexToRgba(theme.palette.error.main, 0.04)
        }
    },
    withoutPadding: {
        padding: 0
    }
}));
