import React, { useEffect, useContext } from "react";
import { useObserver } from "mobx-react";
import { Context } from "store";
import { Paper, Grid, Typography, Chip, Divider, Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";
import CardHeader from "components/CardHeader";
import TableSortLabel from "components/TableSortLabel";
import Checkbox from "components/Checkbox";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import IfaceTableRow from "../IfaceTableRow";
import {
    ACTIVATION_IFACES_PORT,
    ACTIVATION_IFACES_ACTIVATION,
    ACTIVATION_IFACES_MAC,
    ACTIVATION_IFACES_TYPE,
    ACTIVATION_IFACES_STATUS,
} from "const/sortColumnConst";
import { useTranslation } from "react-i18next";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { IFACES_CARD } from "const/widgetNameConst";
import { HARDWARE_ACTIVATION_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { useStyles } from "./IfacesCard.styles";

const IFACES_CARD_ID_PREFIX = `${HARDWARE_ACTIVATION_PAGE_ID_PREFIX}_ifaces_card`;

const IfacesCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { interfaceActivationStore },
    } = useContext(Context);

    useEffect(() => {
        interfaceActivationStore.fetchActivationInterfaces();
        interfaceActivationStore.fetchAvailableActivationsCount();
    }, []);

    const isIfaceChecked = (port) => {
        return interfaceActivationStore.checkedIfaces.includes(port);
    };
    const isIfaceLoading = (port) => {
        return interfaceActivationStore.loadingIfaces.includes(port);
    };
    const onCheck = (port) => () => {
        isIfaceChecked(port) ? interfaceActivationStore.removeCheckedIface(port) : interfaceActivationStore.addCheckedIface(port);
    };
    const changeSorting = (column) => () => interfaceActivationStore.changeSorting(column);

    const unregisterPort = (port) => () => interfaceActivationStore.unregisterPort({ port });

    const handleMainCheckbox = () => {
        switch (interfaceActivationStore.mainCheckboxStatus) {
            case "checked":
            case "indeterminated":
                interfaceActivationStore.clearCheckedIfaces();
                break;
            default:
                interfaceActivationStore.checkAllIfaces();
                break;
        }
    };

    const getCardHeader = () => {
        return (
            <CardHeader
                control={
                    <MoreMenu id={`${IFACES_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={IFACES_CARD} />
                    </MoreMenu>
                }
                content={
                    <Grid container alignItems={"baseline"} wrap={"nowrap"} spacing={6}>
                        <Grid item>
                            <Typography variant={"h2"} noWrap className={classes.title}>
                                {t("hardware_activation.ifaces_card.title")}
                            </Typography>
                        </Grid>
                        {interfaceActivationStore.availableActivationsCount && (
                            <Grid item container alignItems={"center"} wrap={"nowrap"} spacing={2}>
                                <Grid item>
                                    <Typography noWrap>
                                        {t("hardware_activation.expansion_quad_licensing_card.title.activations_available")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Chip
                                        label={`1 Gb/s: ${interfaceActivationStore.availableActivationsCount.count1gbs}`}
                                        color={"primary"}
                                        size={"small"}
                                    />
                                </Grid>
                                <Grid item>
                                    <Chip
                                        label={`10 Gb/s: ${interfaceActivationStore.availableActivationsCount.count10gbs}`}
                                        color={"primary"}
                                        size={"small"}
                                    />
                                </Grid>
                                <Grid item>
                                    <Chip
                                        label={`>10 Gb/s: ${interfaceActivationStore.availableActivationsCount.countMoreThan10gbs}`}
                                        color={"primary"}
                                        size={"small"}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                }
            />
        );
    };

    return useObserver(() => {
        const isMainCheckboxDisabled = interfaceActivationStore.sortedActivationInterfaces?.every((iface) => !iface.exists);

        return (
            <Paper className={classes.container}>
                {getCardHeader()}
                {interfaceActivationStore.activationInterfaces && interfaceActivationStore.activationInterfaces.length > 0 ? (
                    <>
                        <Divider />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding={"checkbox"}>
                                        <Checkbox
                                            indeterminate={interfaceActivationStore.mainCheckboxStatus === "indeterminated"}
                                            checked={interfaceActivationStore.mainCheckboxStatus === "checked"}
                                            onChange={handleMainCheckbox}
                                            id={`${IFACES_CARD_ID_PREFIX}_main_checkbox`}
                                            disabled={isMainCheckboxDisabled}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            direction={interfaceActivationStore.order}
                                            onClick={changeSorting(ACTIVATION_IFACES_PORT)}
                                            active={interfaceActivationStore.orderBy === ACTIVATION_IFACES_PORT}
                                            id={`${IFACES_CARD_ID_PREFIX}_port_sort`}
                                        >
                                            {t("hardware_activation.ifaces_card.table.header.port")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            direction={interfaceActivationStore.order}
                                            onClick={changeSorting(ACTIVATION_IFACES_ACTIVATION)}
                                            active={interfaceActivationStore.orderBy === ACTIVATION_IFACES_ACTIVATION}
                                        >
                                            {t("hardware_activation.ifaces_card.table.header.activation")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            direction={interfaceActivationStore.order}
                                            onClick={changeSorting(ACTIVATION_IFACES_MAC)}
                                            active={interfaceActivationStore.orderBy === ACTIVATION_IFACES_MAC}
                                            id={`${IFACES_CARD_ID_PREFIX}_mac_sort`}
                                        >
                                            {t("hardware_activation.ifaces_card.table.header.mac")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            direction={interfaceActivationStore.order}
                                            onClick={changeSorting(ACTIVATION_IFACES_TYPE)}
                                            active={interfaceActivationStore.orderBy === ACTIVATION_IFACES_TYPE}
                                            id={`${IFACES_CARD_ID_PREFIX}_type_sort`}
                                        >
                                            {t("hardware_activation.ifaces_card.table.header.type")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        <TableSortLabel
                                            direction={interfaceActivationStore.order}
                                            onClick={changeSorting(ACTIVATION_IFACES_STATUS)}
                                            active={interfaceActivationStore.orderBy === ACTIVATION_IFACES_STATUS}
                                            id={`${IFACES_CARD_ID_PREFIX}_status_sort`}
                                        >
                                            {t("hardware_activation.ifaces_card.table.header.status")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {interfaceActivationStore.sortedActivationInterfaces.map((iface) => (
                                    <IfaceTableRow
                                        isLoading={isIfaceLoading(iface.port)}
                                        checked={isIfaceChecked(iface.port)}
                                        onCheck={onCheck(iface.port)}
                                        onDelete={unregisterPort(iface.port)}
                                        key={iface.port}
                                        row={iface}
                                        id={`${IFACES_CARD_ID_PREFIX}_iface_${iface.port}`}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </>
                ) : (
                    <EmptyCardPlaceholder message={t("hardware_activation.ifaces_card.table.placeholder")} />
                )}
            </Paper>
        );
    });
};

export default IfacesCard;
