import { types } from "mobx-state-tree";
import JobScheduleType from "./JobScheduleType";

const STATUS_ENUM_ARRAY = ["Disabled", "Canceled", "Paused", "Idle", "Active", "Error"];
const PAUSED_ENUM_ARRAY = ["Non paused", "Paused manually", "Unpaused manually", "Paused by schedule"];

const JobType = types.model({
    id: types.maybe(types.number),
    status: types.maybe(types.enumeration(STATUS_ENUM_ARRAY)),
    active_job_started: types.maybe(types.string),
    name: types.maybe(types.string),
    enabled: types.maybe(types.boolean),
    deleted: types.maybe(types.boolean),
    paused: types.maybe(types.enumeration(PAUSED_ENUM_ARRAY)),
    source: types.maybe(types.string),
    owner: types.maybe(types.string),
    destination: types.maybe(types.string),
    last_job_finished: types.maybe(types.string),
    schedule: types.maybe(
        types.snapshotProcessor(JobScheduleType, {
            preProcessor(snapshot) {
                return snapshot && JSON.parse(snapshot);
            }
        })
    ),
    method: types.maybe(types.string)
});

export default JobType;
