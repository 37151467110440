import React, { useEffect } from "react";
import DataCard from "components/DataCard";
import { ButtonBase, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import ChevronRightIcon from "assets/ChevronRightIcon";
import PlusIcon from "assets/PlusIcon";
import TableRowControlButton from "components/TableRowControlButton";
import TableCellWithIcon from "components/TableCellWithIcon";
import { useTranslation } from "react-i18next";
import CreateBondDialog from "../CreateBondDialog";
import { useLocalStore, useObserver } from "mobx-react-lite";
import AggregateIcon from "assets/AggregateIcon";
import { NET_IFACE_GATEWAY, NET_IFACE_INTERFACES, NET_IFACE_IP, NET_IFACE_STATUS, NET_IFACE_SUBNET } from "const/sortColumnConst";
import TableSortLabel from "components/TableSortLabel";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { AGGREGATED_INTERFACES_CARD } from "const/widgetNameConst";
import { useStyles } from "./AggregatedInterfacesCard.styles";
import StatusTranslation from "../StatusTranslation";
import BondDrawer from "../BondDrawer";
import useStoreByIp from "hooks/useStoreByIp";
import HideOverlay from "components/HideOverlay/HideOverlay";

const AggregatedInterfacesCard = ({ ip }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { bondsStore } = useStoreByIp({ ip });
    const state = useLocalStore(() => ({
        isDialogOpen: false
    }));
    useEffect(() => {
        bondsStore.fetchBondsArrayResult();
    }, []);

    const openDialog = () => {
        state.isDialogOpen = true;
    };
    const closeDialog = () => {
        state.isDialogOpen = false;
    };
    const changeSorting = column => () => bondsStore.changeSorting(column);
    const selectBond = name => () => bondsStore.setCurrentBond(name);

    return useObserver(() => (
        <DataCard
            title={t("network.aggregated_interfaces.title")}
            headerDivider
            emptyPadding
            headerControl={[
                <HideOverlay show={!ip} key={`data_card-header_control-control-1`}>
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={AGGREGATED_INTERFACES_CARD} />
                    </MoreMenu>
                </HideOverlay>,
                <ButtonBase onClick={openDialog} key={`data_card-header_control-control-2`}>
                    <PlusIcon />
                </ButtonBase>
            ]}
        >
            <BondDrawer ip={ip} />
            <CreateBondDialog ip={ip} onClose={closeDialog} open={state.isDialogOpen} />
            {bondsStore.sortedBondsArray.length ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    direction={bondsStore.order}
                                    onClick={changeSorting(NET_IFACE_INTERFACES)}
                                    active={bondsStore.orderBy === NET_IFACE_INTERFACES}
                                >
                                    {t("network.table_header.interface")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={bondsStore.order}
                                    onClick={changeSorting(NET_IFACE_IP)}
                                    active={bondsStore.orderBy === NET_IFACE_IP}
                                >
                                    {t("network.table_header.ip_address")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={bondsStore.order}
                                    onClick={changeSorting(NET_IFACE_SUBNET)}
                                    active={bondsStore.orderBy === NET_IFACE_SUBNET}
                                >
                                    {t("network.table_header.subnet")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={bondsStore.order}
                                    onClick={changeSorting(NET_IFACE_GATEWAY)}
                                    active={bondsStore.orderBy === NET_IFACE_GATEWAY}
                                >
                                    {t("network.table_header.gateway")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={bondsStore.order}
                                    onClick={changeSorting(NET_IFACE_STATUS)}
                                    active={bondsStore.orderBy === NET_IFACE_STATUS}
                                >
                                    {t("network.table_header.status")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bondsStore.sortedBondsArray.map(bond => (
                            <TableRow
                                selected={bondsStore.currentBondName === bond.port}
                                key={bond.interfaces}
                                onClick={selectBond(bond.port)}
                            >
                                <TableCellWithIcon icon={<AggregateIcon />} spacing={4}>
                                    {bond.interfaces.join(", ")}
                                </TableCellWithIcon>
                                <TableCell>{bond.ip}</TableCell>
                                <TableCell>{bond.netmask}</TableCell>
                                <TableCell>{bond.gateway}</TableCell>
                                <TableCell>
                                    <StatusTranslation label={bond.status} />
                                </TableCell>
                                <TableCell align={"right"}>
                                    <TableRowControlButton icon={<ChevronRightIcon />} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Grid container className={classes.noMessageContainer}>
                    <Grid item>
                        <Typography>{t("network.aggregated_interfaces.no_content")}</Typography>
                    </Grid>
                </Grid>
            )}
        </DataCard>
    ));
};

export default AggregatedInterfacesCard;
