import { types } from "mobx-state-tree";

const AUTOMATION_COMMANDS_ARRAY = ["run", "pause", "resume", "cancel", "cancel_pause", "cancel_disable", "enable", "clear"];

const PerformAutomationCommandArguments = types.model({
    json: types.maybe(
        types.model({
            cmd: types.enumeration(AUTOMATION_COMMANDS_ARRAY),
            automationId: types.number
        })
    )
});

export default PerformAutomationCommandArguments;
