const createFields = () => {
    return [
        {
            name: "name",
            default: ""
        },
        {
            name: "type"
        }
    ];
};

export default createFields;
