// eventType
export const GENERAL_BATCH_COMPLETE = "general_batch_complete";
export const LOGIN = "login";
export const POOL_CREATED = "pool_created";
export const VOLUME_CREATED = "volume_created";
export const VOLUME_REMOVED = "volume_removed";
export const VOLUME_PUT_ONLINE = "volume_put_online";
export const VOLUME_PUT_OFFLINE = "volume_put_offline";
export const SHARE_REMOVED = "share_removed";
export const SHARE_UNMAPPED = "share_unmapped";
export const COMMIT_DRIVE_TO_POOL = "commit_drive_to_pool";
export const NTP_SYNCHRONIZATION_FAILED = "ntp_synchronization_failed";
export const TIME_SETTINGS_CHANGED = "time_settings_changed";
export const SERVICE_INCONSISTENT_STATE = "service_inconsistent_state";
export const BROADCAST_STORM_DETECTED = "broadcast_storm_detected";
export const POWER_MONITOR_EVENT = "power_monitor_event";
export const FAILED_DRIVE = "failed_drive";

// eventLevel
export const INFO = "info";
export const WARNING = "warn";
export const ERROR = "error";
