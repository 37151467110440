import Request from "api/Request";

const GetLastPingTestResult = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "get_last_ping_test_result"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetLastPingTestResult;
