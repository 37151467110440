import { types, flow, getParent, getSnapshot, applySnapshot } from "mobx-state-tree";
import MainSocket from "websocket";
import SearchDomainObjectResult from "api/security/users/Responses/SearchDomainObjectResult";
import AddUser from "api/security/users/Requests/AddUser";
import AddGroup from "api/security/users/Requests/AddGroup";
import SearchUsersAndGroups from "api/security/users/Requests/SearchUsersAndGroups";
import DomainObject from "api/security/Types/DomainObject";
import DeleteUser from "api/security/users/Requests/DeleteUser";
import DeleteGroup from "api/security/users/Requests/DeleteGroup";
import ResetUserPassword from "api/security/users/Requests/ResetUserPassword";
import ListUserMembership from "api/security/users/Requests/ListUserMembership";
import AddGroupMembers from "api/security/users/Requests/AddGroupMembers";
import GetActiveDirectoryStatus from "api/security/users/Requests/GetActiveDirectoryStatus";
import GetLdapStatus from "api/security/users/Requests/GetLdapStatus";
import GetActiveDirectoryStatusResult from "api/security/users/Responses/GetActiveDirectoryStatusResult";
import GetLdapStatusResult from "api/security/users/Responses/GetLdapStatusResult";
import EnableLdap from "api/security/users/Requests/EnableLdap";
import EnableActiveDirectory from "api/security/users/Requests/EnableActiveDirectory";
import DisableLdap from "api/security/users/Requests/DisableLdap";
import DisableActiveDirectory from "api/security/users/Requests/DisableActiveDirectory";
import ListGroupMembers from "api/security/users/Requests/ListGroupMembers";
import DomainObjectsResult from "api/security/users/Responses/DomainObjectsResult";
import GetSecondaryAdmins from "api/security/users/Requests/GetSecondaryAdmins";
import SetSecondaryAdmins from "api/security/users/Requests/SetSecondaryAdmins";
import DeleteGroupMembers from "api/security/users/Requests/DeleteGroupMembers";
import { SEARCH_DEFAULTS } from "const/userSearchVariants";
import { LDAP, AD } from "const/ldapStatuses";
import DomainObjectId from "api/security/Types/DomainObjectId";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import { ASC, DESC, USERS_LIST_GROUP_MEMBERS_NAME } from "const/sortColumnConst";
import GetUserAccess from "../../api/security/acl/Requests/GetUserAccess";
import GetUserAccessResult from "../../api/security/acl/Responses/GetUserAccessResult";
import { NONE } from "../../const/shareRootAccessConst";
import { toJS } from "mobx";
import GetTrustedDomainsResult from "api/security/users/Responses/GetTrustedDomainsResult";
import GetTrustedDomains from "api/security/users/Requests/GetTrustedDomains";

const SearchType = types.model({
    type: types.string,
    name: types.string,
    page: types.number,
    limit: types.number,
});

const UserStore = types
    .model({
        searchResultStore: types.maybeNull(SearchDomainObjectResult),
        getUserAccessResult: types.maybeNull(GetUserAccessResult),
        lastSearch: types.maybe(SearchType),
        lastSearchShare: types.maybe(SearchType),
        currentEntity: types.maybeNull(DomainObject),
        currentEntitySingleBar: types.maybeNull(DomainObject),
        currentSearchRequest: types.optional(SearchType, SEARCH_DEFAULTS),
        checkedUsers: types.optional(types.array(DomainObjectId), []),
        searchByTypeResultStore: types.maybeNull(SearchDomainObjectResult),
        searchByTypeRequest: types.optional(SearchType, SEARCH_DEFAULTS),
        ldapStatusStore: types.maybe(GetLdapStatusResult),
        activeDirectoryStatusStore: types.maybe(GetActiveDirectoryStatusResult),
        listGroupMembersStore: types.maybe(DomainObjectsResult),
        listUserMembershipStore: types.maybe(DomainObjectsResult),
        secondaryAdminsStore: types.maybe(DomainObjectsResult),
        initialUserAccessResult: types.maybeNull(GetUserAccessResult),
        currentUserForChangingAccess: types.maybe(types.frozen()),
        trustedDomains: types.maybe(GetTrustedDomainsResult),
        userMembershipError: types.maybe(types.boolean),
    })
    .volatile(() => ({
        groupMembersOrderBy: USERS_LIST_GROUP_MEMBERS_NAME,
        groupMembersOrder: ASC,
        userMembershipOrderBy: USERS_LIST_GROUP_MEMBERS_NAME,
        userMembershipOrder: ASC,
    }))
    .views((self) => ({
        get searchResult() {
            return self.searchResultStore && self.searchResultStore.data;
        },
        get sortedSearchResult() {
            const usersFlatArray =
                (self.searchResult.data.length &&
                    self.searchResult?.data.map((entity) => {
                        return { ...entity, ...entity.id };
                    })) ||
                [];
            return usersFlatArray.length && stableSort(usersFlatArray, getComparator(ASC, "name"));
        },
        get searchUserOrGroupsTotal() {
            return self.getUserAccessResult && self.getUserAccessResult.data.total;
        },
        get searchByTypeResult() {
            return self.searchByTypeResultStore && self.searchByTypeResultStore.data;
        },
        get ldapStatus() {
            return self.ldapStatusStore && self.ldapStatusStore.data;
        },
        get activeDirectoryStatus() {
            return self.activeDirectoryStatusStore && self.activeDirectoryStatusStore.data;
        },
        get groupMembers() {
            return self.listGroupMembersStore?.data || [];
        },
        get sortedGroupMembers() {
            return stableSort(self.groupMembers, getComparator(self.groupMembersOrder, self.groupMembersOrderBy));
        },
        get userMembership() {
            return self.listUserMembershipStore?.data || [];
        },
        get sortedUserMembership() {
            return stableSort(self.userMembership, getComparator(self.userMembershipOrder, self.userMembershipOrderBy));
        },
        get hasAccessChanges() {
            if (self.getUserAccessResult?.data?.data) {
                return !!self.getUserAccessResult.data.data.find(
                    ({ access }, idx) => access !== self.initialUserAccessResult.data.data[idx].access
                );
            }

            return false;
        },
        get currentSyncMode() {
            const result = { mode: "", status: "", enabled: false };
            if (self.ldapStatus?.enabled) {
                result.mode = LDAP;
                result.status = self.ldapStatus.status;
                result.enabled = true;
            }
            if (self.activeDirectoryStatus?.enabled) {
                result.mode = AD;
                result.status = self.activeDirectoryStatus.status;
                result.enabled = true;
            }
            return result;
        },
        get secondaryAdmins() {
            return self.secondaryAdminsStore && self.secondaryAdminsStore.data;
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
    }))
    .actions((self) => ({
        getTustedDomains: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetTrustedDomains.create().init();
                const res = yield self.socket.send(req);
                self.trustedDomains = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        addUser: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = AddUser.create().init(data);
                yield self.socket.send(req);
                return data;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        addGroup: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = AddGroup.create().init(data);
                yield self.socket.send(req);
                return data;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        searchOnUsersPage: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);

                const payload = {
                    type: self.currentSearchRequest.type,
                    name: self.currentSearchRequest.name,
                    offset: self.currentSearchRequest.page * self.currentSearchRequest.limit,
                    limit: self.currentSearchRequest.limit,
                };
                const req = SearchUsersAndGroups.create().init(payload);
                const res = yield self.socket.send(req);

                self.searchResultStore = res;
                self.lastSearch = getSnapshot(self.currentSearchRequest);
                self.userMembershipError = false;
                return payload;
            } catch (e) {
                processingStore.setError(e);
                self.userMembershipError = true;
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        searchOnAssignShares: flow(function* (data) {
            const { shareName } = data;
            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);

                const payload = {
                    offset: self.currentSearchRequest.page * self.currentSearchRequest.limit,
                    limit: self.currentSearchRequest.limit,
                    shareName,
                    type: self.currentSearchRequest.type,
                    search: self.currentSearchRequest.name,
                };

                const req = GetUserAccess.create().init(payload);
                const res = yield self.socket.send(req);

                self.getUserAccessResult = res;
                self.initialUserAccessResult = res;

                self.lastSearchShare = getSnapshot(self.currentSearchRequest);

                return payload;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        searchByType: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const payload = {
                    type: self.searchByTypeRequest.type,
                    name: self.searchByTypeRequest.name,
                    offset: self.searchByTypeRequest.page * self.searchByTypeRequest.limit,
                    limit: self.searchByTypeRequest.limit,
                };
                const req = SearchUsersAndGroups.create().init(payload);
                const res = yield self.socket.send(req);
                self.searchByTypeResultStore = res;
                return payload;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        searchUsers: flow(function* ({ type, name }) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const payload = {
                    type,
                    name,
                    offset: self.searchByTypeRequest.page * self.searchByTypeRequest.limit,
                    limit: self.searchByTypeRequest.limit,
                };
                const req = SearchUsersAndGroups.create().init(payload);
                const res = yield self.socket.send(req);
                self.searchByTypeResultStore = res;
                return payload;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        addGroupMembers: flow(function* (groupName, users) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const payload = {
                    groupName: groupName || self.searchByTypeRequest.name,
                    users:
                        users || (self.currentEntity && [getSnapshot(self.currentEntity.id)]) || getSnapshot(self.checkedUsers),
                };
                const req = AddGroupMembers.create().init(payload);
                yield self.socket.send(req);
                return payload;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        deleteGroupMembers: flow(function* (groupName, users) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const payload = {
                    groupName,
                    users,
                };
                const req = DeleteGroupMembers.create().init(payload);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        deleteUser: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DeleteUser.create().init(data);
                yield self.socket.send(req);
                return data;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        deleteGroup: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DeleteGroup.create().init(data);
                yield self.socket.send(req);
                return data;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        resetUserPassword: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ResetUserPassword.create().init(data);
                yield self.socket.send(req);
                return data;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        listUserMembership: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                const payload = {
                    name: (self.currentEntity && self.currentEntity?.id.name) || self.currentEntitySingleBar?.id.name,
                    domain: (self.currentEntity && self.currentEntity?.id.domain) || self.currentEntitySingleBar?.id.domain,
                };
                processingStore.setLoading(true);
                const req = ListUserMembership.create().init(payload);
                const res = yield self.socket.send(req);
                self.listUserMembershipStore = res;
                return res.data;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        listGroupMembers: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                const payload = {
                    name: (self.currentEntity && self.currentEntity?.id.name) || self.currentEntitySingleBar?.id.name,
                    domain: (self.currentEntity && self.currentEntity?.id.domain) || self.currentEntitySingleBar?.id.domain,
                };
                processingStore.setLoading(true);
                const req = ListGroupMembers.create().init(payload);
                const res = yield self.socket.send(req);
                self.listGroupMembersStore = res;
                return res.data;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getActiveDirectoryStatus: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetActiveDirectoryStatus.create().init();
                const res = yield self.socket.send(req);
                self.activeDirectoryStatusStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getLdapStatus: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetLdapStatus.create().init();
                const res = yield self.socket.send(req);
                self.ldapStatusStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        enableActiveDirectory: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = EnableActiveDirectory.create().init(data);
                yield self.socket.send(req);
                return data;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        enableLdap: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = EnableLdap.create().init(data);
                yield self.socket.send(req);
                return data;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        disableActiveDirectory: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DisableActiveDirectory.create().init(data);
                yield self.socket.send(req);
                return data;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        disableLdap: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DisableLdap.create().init(data);
                yield self.socket.send(req);
                return data;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchSecondaryAdmins: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetSecondaryAdmins.create().init();
                const res = yield self.socket.send(req);
                self.secondaryAdminsStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setSecondaryAdmins: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetSecondaryAdmins.create().init(data);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),

        updateLdapStatus: (data) => {
            self.ldapStatusStore && (self.ldapStatusStore.data = data);
        },
        updateActiveDirectoryStatus: (data) => {
            self.activeDirectoryStatusStore && (self.activeDirectoryStatusStore.data = data);
        },

        resetSearchByTypeResult: () => {
            self.searchByTypeResultStore = null;
        },
        updateSearchByTypeRequest: (name, value) => {
            self.searchByTypeRequest[name] = value;
        },
        setLastSearch: (data) => {
            self.lastSearch = data;
        },
        applyLastSearchToRequest: () => {
            if (self.lastSearch) {
                self.currentSearchRequest = getSnapshot(self.lastSearch);
            }
        },
        setCurrentEntity: (data) => {
            self.currentEntity = data;
        },
        setCurrentUserForChangingAccess: (user) => {
            self.currentUserForChangingAccess = user;
        },
        dropCurrentEntity: () => {
            self.currentEntity = null;
        },
        setCurrentEntitySingleBar: (data) => {
            const snap = getSnapshot(data);
            self.currentEntitySingleBar = snap;
        },
        dropCurrentEntitySingleBar: () => {
            self.currentEntitySingleBar = null;
        },
        resetCurrentSearchRequest: () => {
            self.currentSearchRequest = SEARCH_DEFAULTS;
        },
        updateCurrentSearchRequest: (name, value) => {
            self.currentSearchRequest[name] = value;
        },
        addCheckedUser: (userId) => {
            const foundUser = self.checkedUsers.some((user) => user.name === userId.name && user.domain === userId.domain);
            !foundUser && self.checkedUsers.push(userId);
        },
        removeCheckedUser: (userId) => {
            const foundUser = self.checkedUsers.find((user) => user.name === userId.name && user.domain === userId.domain);
            foundUser && self.checkedUsers.remove(foundUser);
        },
        clearCheckedUsers: () => {
            self.checkedUsers = [];
        },
        groupMembersChangeSorting(column) {
            if (column === self.groupMembersOrderBy) {
                self.groupMembersOrder = self.groupMembersOrder === DESC ? ASC : DESC;
            } else {
                self.groupMembersOrder = ASC;
                self.groupMembersOrderBy = column;
            }
        },
        userMembershipChangeSorting(column) {
            if (column === self.userMembershipOrderBy) {
                self.userMembershipOrder = self.userMembershipOrder === DESC ? ASC : DESC;
            } else {
                self.userMembershipOrder = ASC;
                self.userMembershipOrderBy = column;
            }
        },
        updateSecondaryAdmins: (data) => {
            self.secondaryAdmins ? (self.secondaryAdminsStore.data = data.data) : self.fetchSecondaryAdmins();
        },
        modifyUserAccess: (index, access) => {
            if (self.getUserAccessResult.data.data[index].access === access) {
                return (self.getUserAccessResult.data.data[index].access = NONE);
            }

            return (self.getUserAccessResult.data.data[index].access = access);
        },
        modifyAllUsersAccess: (access) => {
            self.getUserAccessResult.data.data = self.getUserAccessResult.data.data.map((el) => ({
                ...toJS(el),
                access,
            }));
        },
        setNoneAccessForUsersWithNextAccess: (access) => {
            self.getUserAccessResult.data.data = self.getUserAccessResult.data.data.map((el) => {
                return el.access === access ? { ...toJS(el), access: NONE } : toJS(el);
            });
        },
        resetUserAccessChanges: () => {
            applySnapshot(self.getUserAccessResult, getSnapshot(self.initialUserAccessResult));
        },
    }));

export default UserStore;
