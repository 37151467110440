import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    tableCell: {
        width: "50%"
    },
    tableCellWithIcon: {
        "& svg path": {
            fill: theme.palette.grey[300]
        }
    },
    tableDelete: {
        "&:hover": {
            cursor: "pointer"
        }
    },
    tableBody: {
        height: 400,
        overflowY: "scroll"
    },
    table: {
        height: "min-content"
    }
}));
