import { types } from "mobx-state-tree";

const LdapSettings = types.model({
    host: types.string,
    baseDN: types.string,
    encryption: types.string,
    sambaMode: types.string,
    bindDN: types.string,
    bindPassword: types.string,
    userSuffix: types.string,
    groupSuffix: types.string,
    jumpcloudGroupsEnabled: types.boolean,
});

export default LdapSettings;
