import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import Logo from "assets/Logo";
import { useStyles } from "./HeaderToolbar.style";
import NotificationsButtonIcon from "assets/NotificationsButtonIcon";
import {
    Grid,
    Badge,
    Link,
    Typography,
    ButtonBase,
    MenuItem,
    Box,
    FormControlLabel,
    CircularProgress,
    useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import UserSection from "./components/UserSection";
import SupportHeaderIcon from "assets/SupportHeaderIcon";
import { useHistory } from "react-router-dom";
import { URL_CENTRAL_STATUS, URL_SUPPORT } from "routes";
import { ADMIN, USER } from "const/userRolesConst";
import { SNS_WEBSITE } from "const";
import EvoMenu from "./components/EvoMenu";
import LoginToMultipleEvo from "./components/LoginToMultipleEvo";
import SettingsIcon from "assets/SettingsIcon";
import { useTranslation } from "react-i18next";
import Switch from "components/Switch/Switch";
import EvoInfoTime from "./components/EvoInfoTime";
import MuptipleEvoConfiguration from "./components/MuptipleEvoConfiguration";
import withStyles from "@material-ui/core/styles/withStyles";
import SpeedIcon from "assets/SpeedIcon";
import { useStore } from "hooks/useStore";
import { useNotificationMenu } from "hooks/sideBars/useNotificationMenu.js";
import { useMuptipleEvoConfigurationModal, useLoginToNewEvoModal } from "hooks/modals";
import { useMediaQuery } from "hooks/useMediaQuery";

const StyledBadge = withStyles(() => ({
    "@global": {
        "@keyframes myEffect": {
            "0%": {
                transform: "scale(1.0) translate(-50%, -50%)",
            },
            "100%": {
                transform: "scale(1.15) translate(-50%, -50%)",
            },
        },
    },
    badge: {
        animation: `myEffect 500ms linear infinite alternate 0s forwards`,
    },
}))(Badge);

const HeaderToolbar = ({ title }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const muptipleEvoConfigurationModal = useMuptipleEvoConfigurationModal();
    const loginToNewEvoModal = useLoginToNewEvoModal();

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const {
        store: { notificationStore, taskStore, authStore, multipleEvosStore },
    } = useStore();

    const notificationMenu = useNotificationMenu();

    const state = useLocalStore(() => ({
        needToConnectedIp: null,
        inProgress: [],
        get newNotificationsCount() {
            return notificationStore.filteredNewNotificationsCount + taskStore.filteredNewTasksCount;
        },
    }));

    const toggleNotificationsMenu = () => {
        notificationMenu.isOpen && !notificationMenu.isPinned ? notificationMenu.close() : notificationMenu.open();
        if (notificationMenu.isOpen && notificationMenu.iisPinned) {
            notificationMenu.isHide && notificationMenu.display();
        }
    };

    useEffect(() => {
        if (multipleEvosStore.multipleEvoConnections) {
            multipleEvosStore.multipleEvoConnections.forEach(() => {
                state.inProgress.push(false);
            });
        }
    }, [multipleEvosStore.multipleEvoConnections, multipleEvosStore.isAutoconnectionCompleted]);
    const history = useHistory();

    const openSupportPage = () => {
        history.push(URL_SUPPORT);
    };
    const openCentralStatus = () => {
        history.push(URL_CENTRAL_STATUS);
    };

    const onSwitchEvoConnection = (ip, isAuthorized, index) => async () => {
        state.isDisabled = true;
        state.inProgress[index] = true;
        if (!isAuthorized) {
            const res = await multipleEvosStore.checkMultipleEvoAuthAndGetNewSocketToken(ip);
            if (!res) {
                state.needToConnectedIp = ip;
                loginToNewEvoModal.open();
            }
        } else {
            state.inProgress[index] = false;
            await multipleEvosStore.logoutFromMultipleEvo(ip);
        }
        state.isDisabled = false;
        state.inProgress[index] = false;
    };

    const openMuptipleEvoConfigurationModal = () => {
        if (state.isDisabled) return;
        muptipleEvoConfigurationModal.open();
    };

    return (
        <Grid direction={"row"} className={classes.container} container justify={isDesktop ? "space-between" : "flex-end"}>
            <Grid item md={3} className={classes.containerLogoSection}>
                <Grid container spacing={4} alignItems={"center"} wrap={"nowrap"} className={classes.logoSection}>
                    <Grid item>
                        <Grid container direction={"column"} alignItems={"center"} justify={"center"}>
                            <Link rel="noopener" target="_blank" href={SNS_WEBSITE} variant="button">
                                <Logo />
                            </Link>
                        </Grid>
                    </Grid>
                    {isDesktop && (
                        <Grid item>
                            <EvoInfoTime />
                        </Grid>
                    )}
                    {authStore.isAuthorized && authStore.role === ADMIN && (
                        <Grid container className={classes.evoMenu}>
                            <Box ml={3} width="100%" height="100%">
                                <EvoMenu>
                                    {multipleEvosStore.multipleEvoConnections?.map((evo, index) => (
                                        <MenuItem key={evo.name} className={classes.listMenu}>
                                            {state.inProgress.length && state.inProgress[index] ? (
                                                <>
                                                    <Box pl={3} pr={4.25}>
                                                        <CircularProgress size={20} />
                                                    </Box>
                                                    <Box mr={4}>
                                                        <Typography>
                                                            {t("header.multiple_evo.list.name", { name: evo.name })}
                                                        </Typography>
                                                    </Box>
                                                </>
                                            ) : (
                                                <FormControlLabel
                                                    className={classes.switchLabel}
                                                    labelPlacement={"end"}
                                                    control={
                                                        <Switch
                                                            onClick={onSwitchEvoConnection(evo.ip, evo.isAuthorized, index)}
                                                            checked={evo.isAuthorized}
                                                            disabled={state.isDisabled}
                                                        />
                                                    }
                                                    label={
                                                        <Typography>
                                                            {t("header.multiple_evo.list.name", { name: evo.name })}
                                                        </Typography>
                                                    }
                                                />
                                            )}
                                        </MenuItem>
                                    ))}
                                    <MenuItem onClick={openCentralStatus} disabled={!multipleEvosStore.isMultipleEvoConnections}>
                                        <SpeedIcon />
                                        <Box pl={2}>
                                            <Typography>{t("central_status.title")}</Typography>
                                        </Box>
                                    </MenuItem>
                                    <MenuItem onClick={openMuptipleEvoConfigurationModal}>
                                        <SettingsIcon />
                                        <Box pl={2}>
                                            <Typography>{t("header.multiple_evo.list.configure")}</Typography>
                                        </Box>
                                    </MenuItem>
                                </EvoMenu>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item md={6} className={classes.titleItem}>
                <Grid container justify={"center"} alignItems={"center"} className={classes.titleContainer}>
                    {title}
                </Grid>
            </Grid>
            <Grid item md={3}>
                {authStore.isAuthorized && (
                    <Grid wrap={"nowrap"} container justify={"flex-end"} className={classes.controlsContainer}>
                        <Grid item className={classes.rightControl}>
                            <ButtonBase className={classes.rightControlButton} onClick={openSupportPage}>
                                <SupportHeaderIcon />
                            </ButtonBase>
                        </Grid>
                        {authStore.role !== USER && (
                            <Grid
                                item
                                className={clsx(classes.rightControl, notificationMenu.isOpen && classes.activeRightControl)}
                            >
                                <ButtonBase className={classes.rightControlButton} onClick={toggleNotificationsMenu}>
                                    {state.newNotificationsCount !== 0 ? (
                                        <StyledBadge
                                            color={"error"}
                                            badgeContent={state.newNotificationsCount}
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                            }}
                                        >
                                            <NotificationsButtonIcon />
                                        </StyledBadge>
                                    ) : (
                                        <NotificationsButtonIcon />
                                    )}
                                </ButtonBase>
                            </Grid>
                        )}
                        <Grid item className={classes.rightControl}>
                            <UserSection />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <LoginToMultipleEvo ip={state.needToConnectedIp} />
            <MuptipleEvoConfiguration />
        </Grid>
    );
};

export default observer(HeaderToolbar);
