import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./InfoCard.styles";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import CardHeaderControlButton from "components/CardHeaderControlButton";

const InfoCard = ({ icon, className, flag, iconMargin, title, content, button, widget }) => {
    const classes = useStyles({ flag, iconMargin, button });
    return (
        <Paper className={clsx(classes.box, className)}>
            <Grid justify={"space-between"} alignItems={"center"} container>
                <Grid item>
                    <div className={classes.flag} />
                </Grid>
                {widget && (
                    <Grid item className={classes.menu}>
                        <CardHeaderControlButton>
                            <MoreMenu>
                                <HomeCardActionMenuItem widget={widget} />
                            </MoreMenu>
                        </CardHeaderControlButton>
                    </Grid>
                )}
            </Grid>
            <Grid className={classes.container} container>
                <Grid className={classes.icon} item>
                    {icon}
                </Grid>
                <Grid className={classes.contentContainer} item>
                    <Typography variant={"h2"}>{title}</Typography>
                    <Typography className={classes.content}>{content}</Typography>
                    <>{button}</>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default InfoCard;
