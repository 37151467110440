import { getParent, types } from "mobx-state-tree";
import { flow } from "mobx-state-tree";
import moment from "moment";
import md5 from "js-md5";
import i18n from "i18n";

import axios from "api/AxiosCommonRequest";
import { FILE_UPLOAD_URL } from "api/restRoutes";
import fileSlice from "utils/fileSlice";

const CHUNK_SIZE = 30 * 1024 * 1024; // 30Mb
const ATTEMPT_COUNT = 3;
const MAX_ERRORS_COUNT = 3;
const THREAD_IN_PART = 5;
const config = { headers: { "Content-Type": "multipart/form-data" } };

const UploadedFile = types
    .model({
        id: types.string, // md5 hash of (file name + file size + timestamp)
        sentChunksCount: types.optional(types.number, 0), // successfully sent count
        notSentFormData: types.array(types.frozen()), // formData that wasn't sent
    })
    .actions((self) => ({
        addFormData: function (formData) {
            self.notSentFormData.push(formData);
        },
        clearNotSentFormData: function () {
            self.notSentFormData.clear();
        },
        increaseSentChunksCount: function () {
            self.sentChunksCount += 1;
        },
    }));

const UploadStore = types
    .model({
        uploadedFiles: types.map(UploadedFile),
    })
    .actions((self) => ({
        upload: flow(function* (file, destination) {
            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);

                if (!file) {
                    throw new Error(i18n.t("upgrades.upgrade_system.message.upload_no_file"));
                }

                const size = file.size;
                const name = file.name;
                const id = md5(`${name}${size}${moment().unix()}`);
                const chunkCount = Math.ceil(size / CHUNK_SIZE);
                const parts = Math.ceil(chunkCount / THREAD_IN_PART);

                self.uploadedFiles.set(id, { id });

                for (let i = 0; i < parts; i++) {
                    const start = i * CHUNK_SIZE * THREAD_IN_PART;
                    let end = start + CHUNK_SIZE * THREAD_IN_PART;

                    if (size - end < 0) {
                        end = size;
                    }

                    const slicedPart = fileSlice(file, start, end);
                    const promises = self.getPartPromises({ slicedPart, id, name, size, chunkCount, part: i, destination });
                    yield Promise.allSettled(promises);

                    const uploadedFile = self.uploadedFiles.get(id);
                    if (uploadedFile.notSentFormData.length >= MAX_ERRORS_COUNT) {
                        throw new Error(i18n.t("upgrades.upgrade_system.message.upload_error"));
                    }

                    // try to resend formData
                    yield Promise.all(uploadedFile.notSentFormData.map((formData) => self.retryUploadChunk(formData)));
                    uploadedFile.clearNotSentFormData();
                }

                return file;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getPartPromises: function ({ slicedPart, id, name, size, chunkCount, part, destination }) {
            const { processingStore } = getParent(self);

            const requestPromises = [];
            const sliceSize = slicedPart.size;
            let isTheLastChunk = false;
            for (let i = 0; i < THREAD_IN_PART; i++) {
                const currentChunkNumber = part * THREAD_IN_PART + i + 1;
                const formData = new FormData();
                const start = i * CHUNK_SIZE;
                const uploadedFile = self.uploadedFiles.get(id);
                let end = i * CHUNK_SIZE + CHUNK_SIZE;

                if (sliceSize - end < 0) {
                    end = sliceSize;
                    isTheLastChunk = true;
                }

                const slice = fileSlice(slicedPart, start, end);
                formData.append("fileName", name);
                formData.append("id", id);
                formData.append("size", size); // file size
                formData.append("chunkCount", chunkCount);
                formData.append("currentChunkNumber", currentChunkNumber);
                formData.append("file", slice);

                requestPromises.push(
                    axios
                        .post(`${FILE_UPLOAD_URL}${destination}`, formData, config)
                        .then(() => {
                            uploadedFile.increaseSentChunksCount();
                        })
                        .catch((err) => {
                            uploadedFile.addFormData(formData);

                            if (err?.response?.status === 507) {
                                return processingStore.setError(i18n.t("support.os_data.error_message.no_space_left_on_device"));
                            }

                            processingStore.setError(err);
                        })
                );

                if (isTheLastChunk) break;
            }

            return requestPromises;
        },
        // for testing purposes, use upload method instead
        sequentialUpload: flow(function* (file) {
            const { processingStore } = getParent(self);
            const id = md5(`${file.name}${file.size}${moment().unix()}`);
            const chunkCount = Math.ceil(file.size / CHUNK_SIZE);
            const size = file.size;

            self.uploadedFiles.set(id, { id });

            try {
                processingStore.setLoading(true);
                for (let i = 0; i < chunkCount; i++) {
                    const uploadedFile = self.uploadedFiles.get(id);
                    const formData = new FormData();
                    const start = i * CHUNK_SIZE;
                    let end = i * CHUNK_SIZE + CHUNK_SIZE;

                    if (size - end < 0) {
                        end = size;
                    }

                    const slicedPart = fileSlice(file, start, end);
                    formData.append("fileName", file.name);
                    formData.append("id", id);
                    formData.append("size", file.size);
                    formData.append("chunkCount", chunkCount);
                    formData.append("currentChunkNumber", uploadedFile.sentChunksCount + 1);
                    formData.append("file", slicedPart);

                    try {
                        yield axios.post(FILE_UPLOAD_URL, formData, config);
                        uploadedFile.increaseSentChunksCount();
                    } catch (e) {
                        yield self.retryUploadChunk(formData);
                    }
                }
                return file;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        retryUploadChunk: flow(function* (formData) {
            const id = formData.get("id");
            const uploadedFile = self.uploadedFiles.get(id);
            for (let i = 0; i < ATTEMPT_COUNT; i++) {
                try {
                    yield axios.post(FILE_UPLOAD_URL, formData, config);
                    uploadedFile.increaseSentChunksCount();
                    break;
                } catch (e) {
                    if (i === ATTEMPT_COUNT - 1) throw e;
                }
            }
        }),
    }));

export default UploadStore;
