import shouldBePasswordsEqual from "utils/shouldBePasswordsEqual";
import isFieldEmpty from "utils/isFieldEmptyRule";
import isFieldHasOnlySpaces from "utils/isFieldHasOnlySpacesRule";
import i18n from "i18n";

/**
 * @param {AddUserDialog.Fields} key
 * @returns {(initialValues: AddUserDialog.InitialValue) => string}
 * */
const getValueByKey = key => initialValues => initialValues[key];

const createFields = () => {
    return [
        {
            name: "name",
            rules: "name",
            validators: [isFieldEmpty(i18n.t("common.label.username")), isFieldHasOnlySpaces(i18n.t("rule.user.name"))],
            getValue: getValueByKey("name")
        },
        {
            name: "password",
            type: "password",
            rules: "password",
            validators: [isFieldEmpty(i18n.t("common.label.password")), isFieldHasOnlySpaces(i18n.t("rule.user.password"))],
            getValue: getValueByKey("password")
        },
        {
            name: "confirmPassword",
            type: "password",
            validators: [shouldBePasswordsEqual("password")],
            getValue: getValueByKey("confirmPassword")
        }
    ];
};

export default createFields;
