import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography, Button, Dialog, Divider } from "@material-ui/core";
import CardHeader from "components/CardHeader";
import { useStyles } from "./WarningDialog.styles";
import { useTranslation } from "react-i18next";
import { GB_METRIC, GiB_IEC, KB_METRIC, KiB_IEC, PB_METRIC, PiB_IEC, TB_METRIC, TiB_IEC } from "const/diskSizeConst";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const WARNING_DIALOG_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_warning_dialog`;

const WarningDialog = ({
    open,
    onClose,
    submit,
    availableSize = 0,
    reservedSize = 0,
    requestedSize = 0,
    requestedUnit = 0,
    zeroReservedSize = 0
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const getUnitLabel = () => {
        const units = [GB_METRIC, GiB_IEC, KB_METRIC, KiB_IEC, PB_METRIC, PiB_IEC, TB_METRIC, TiB_IEC];
        const foundUnit = units.find(unit => unit.value === requestedUnit);
        return foundUnit?.unit;
    };

    const reserveSpace = () => {
        submit({ force: false, withSpaceReserve: true });
        onClose();
    };

    const ignore = () => {
        submit({ force: true, withSpaceReserve: false });
        onClose();
    };

    const value = ((availableSize - reservedSize) / requestedUnit).toFixed(1);
    const isKeepingReservedSpace = availableSize > reservedSize && value >= 1;

    return useObserver(() => (
        <Dialog maxWidth={"lg"} fullWidth open={open} onClose={onClose}>
            <CardHeader invisibleIcon title={t("disks_and_pools.logical_disks.warning_dialog.title")} />
            <Divider />
            <Grid container direction={"column"} spacing={2} className={classes.contentContainer}>
                <Grid item>
                    <Typography>
                        {t("disks_and_pools.logical_disks.warning_dialog.main_message", {
                            size: (zeroReservedSize / GiB_IEC.value).toFixed(1)
                        })}
                    </Typography>
                </Grid>
                {isKeepingReservedSpace ? (
                    <Grid item>
                        <Typography>
                            {t("disks_and_pools.logical_disks.warning_dialog.secondary_message", {
                                reserved: (
                                    (reservedSize - (availableSize - requestedSize * requestedUnit)) /
                                    GiB_IEC.value
                                ).toFixed(1),
                                value: value,
                                unit: getUnitLabel()
                            })}
                        </Typography>
                    </Grid>
                ) : null}
            </Grid>
            <Divider />
            <Grid container alignItems={"center"} justify={"flex-end"} spacing={4} className={classes.controlsContainer}>
                <Grid item>
                    <Button id={`${WARNING_DIALOG_ID_PREFIX}_cancel`} onClick={onClose} variant={"contained"} color={"secondary"}>
                        {t("common.button.cancel")}
                    </Button>
                </Grid>
                <Grid item>
                    <Button id={`${WARNING_DIALOG_ID_PREFIX}_ignore`} onClick={ignore} variant={"contained"} color={"primary"}>
                        {t("disks_and_pools.logical_disks.warning_dialog.button.ignore")}
                    </Button>
                </Grid>
                {isKeepingReservedSpace ? (
                    <Grid item>
                        <Button
                            id={`${WARNING_DIALOG_ID_PREFIX}_reserve`}
                            onClick={reserveSpace}
                            variant={"contained"}
                            color={"primary"}
                        >
                            {t("disks_and_pools.logical_disks.warning_dialog.button.reserve")}
                        </Button>
                    </Grid>
                ) : null}
            </Grid>
        </Dialog>
    ));
};

export default WarningDialog;
