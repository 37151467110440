import { types } from "mobx-state-tree";

import Request from "api/Request";
import Credentials from "api/upgrade/migration/Types/Credentials";

const RunExternalUsersStep = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Migration",
    method: "run_external_users_step",
    arguments: types.maybe(Credentials),
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default RunExternalUsersStep;
