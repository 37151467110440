const createFields = store => {
    return [
        {
            name: "primary",
            value: store.primary,
            rules: "isIpv4"
        },
        {
            name: "secondary",
            value: store.secondary,
            rules: "isIpv4"
        }
    ];
};

export default createFields;
