import { types } from "mobx-state-tree";

import Request from "api/Request";
import AuthSettings from "api/auth/Types/AuthSettings";

const SetSettings = Request.props({
    path: "/com/studionetworksolutions/evo/Auth",
    method: "set_settings",
    arguments: types.maybe(AuthSettings),
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default SetSettings;
