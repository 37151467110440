import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography, Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";

const WarningDialog = ({ open, onClose, count, submit }) => {
    const { t } = useTranslation();

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={t("file_navigator.warning_dialog.header")}
            withoutIconClose
            withoutDividers
            submitBtnLabel={t("common.button.ok_delete")}
            onSubmit={submit}
            buttons={
                <Button onClick={onClose} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"} spacing={5}>
                <Grid item>
                    <Typography>
                        {t("file_navigator.warning_dialog.text", {
                            count: count
                        })}
                    </Typography>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default WarningDialog;
