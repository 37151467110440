import React, { useContext, useEffect } from "react";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import AcceptIcon from "assets/AcceptIcon";
import SideBulkBar from "components/SideBulkBar";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react";
import { HARDWARE_ACTIVATION_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const IFACES_ACTIVATION_SIDE_BAR_ID_PREFIX = `${HARDWARE_ACTIVATION_PAGE_ID_PREFIX}_ifaces_activation_side_bar`;

const drawerName = "IfacesActivationSideBar";
const IfacesActivationSideBar = () => {
    const {
        store: { interfaceActivationStore, uiStore }
    } = useContext(Context);
    const { t } = useTranslation();
    useEffect(() => {
        if (interfaceActivationStore.checkedIfacesCount > 0) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, clearCheckboxes);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [interfaceActivationStore.checkedIfacesCount]);

    const clearCheckboxes = () => {
        interfaceActivationStore.clearCheckedIfaces();
    };
    const isDeactivated = () => {
        return interfaceActivationStore.hasDeactivatedIface;
    };
    const activateInterface = () => {
        if (interfaceActivationStore.checkedIfacesCount > 0) {
            if (isDeactivated()) {
                const ports = interfaceActivationStore.deactivatedCheckedIface.map(iface => iface);
                interfaceActivationStore.concatLoadingIface(ports);
                interfaceActivationStore.activatePorts({
                    ports
                });
            } else {
                const ports = interfaceActivationStore.activatedCheckedIface.map(iface => iface);
                interfaceActivationStore.concatLoadingIface(ports);
                interfaceActivationStore.deactivatePorts({
                    ports
                });
            }
        }
        clearCheckboxes();
    };
    return useObserver(() => (
        <SideBulkBar
            onClose={clearCheckboxes}
            itemsCount={interfaceActivationStore.checkedIfacesCount}
            isOpen={uiStore.openedDrawer === drawerName}
        >
            <BulkBarFlatButton
                onClick={activateInterface}
                icon={<AcceptIcon id={`${IFACES_ACTIVATION_SIDE_BAR_ID_PREFIX}_activate_interface`} />}
            >
                {isDeactivated()
                    ? t("hardware_activation.bulk_operations_bar.button.activate")
                    : t("hardware_activation.bulk_operations_bar.button.deactivate")}
            </BulkBarFlatButton>
        </SideBulkBar>
    ));
};

export default IfacesActivationSideBar;
