const createFields = () => {
    return [
        {
            name: "diagnostic",
            type: "checkbox"
        },
        {
            name: "serialNumber"
        },
        {
            name: "registrationId"
        },
        {
            name: "frequency"
        }
    ];
};

export default createFields;
