import { types } from "mobx-state-tree";

const StpSettings = types.model({
    enabled: types.boolean,
    switchPriority: types.number,
    forwardDelay: types.number,
    helloTime: types.number,
    maxAge: types.number
});

export default StpSettings;
