import { types } from "mobx-state-tree";

import Request from "api/Request";
import UpdateArguments from "api/system_components/slingshot/Types/UpdateArguments";

const UpdateSlingshot = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Slingshot",
    method: "update_slingshot",
    arguments: types.maybe(UpdateArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default UpdateSlingshot;
