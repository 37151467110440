import React from "react";
import { useObserver } from "mobx-react";
import { equalsIgnoreCase } from "utils/equalsIgnoreCase";
import { TableCell, TableRow } from "@material-ui/core";
import { RECYCLE_BIN_DATE_FORMAT } from "const";
import moment from "moment";
import TableCellWithIcon from "components/TableCellWithIcon";
import { useTranslation } from "react-i18next";
import { convertBytesToSizeUnit } from "utils/convertBytesToSizeUnit";
import Checkbox from "components/Checkbox";
import { useStore } from "hooks/useStore";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const FilesRow = ({ file, renderTypeIcon }) => {
    const { name, modifiedTime, deleteTime, type, size } = file;
    const { t } = useTranslation();
    const {
        store: { recycleBinStore },
    } = useStore();

    const getTypeTranslation = (type) => {
        if (equalsIgnoreCase(type, "directory")) {
            return t("recycle_bin.type.folder");
        } else if (equalsIgnoreCase(type, "file")) {
            return t("recycle_bin.type.file");
        }
        return t("recycle_bin.type.file");
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const onRowClick = (fileName) => () => {
        if (recycleBinStore.selectedFilesLength) {
            return;
        }

        recycleBinStore.setCurrentFileName(recycleBinStore.currentFileName === fileName ? null : fileName);
    };

    const selectFile = (fileName) => () => {
        recycleBinStore.selectFile(fileName);
    };
    const renderIcon = renderTypeIcon(type, name);

    return useObserver(() => (
        <TableRow onClick={onRowClick(name)}>
            <TableCell>
                <Checkbox
                    onClick={stopPropagation}
                    onChange={selectFile(name)}
                    checked={recycleBinStore.selectedFiles.includes(name)}
                />
            </TableCell>
            <TableCellWithIcon icon={renderIcon}>{name}</TableCellWithIcon>
            <TableCell>
                {modifiedTime ? moment(modifiedTime).utc(true).format(getLanguageDateFormat(RECYCLE_BIN_DATE_FORMAT)) : null}
            </TableCell>
            <TableCell>
                {deleteTime ? moment(deleteTime).utc(true).format(getLanguageDateFormat(RECYCLE_BIN_DATE_FORMAT)) : null}
            </TableCell>
            <TableCell>{getTypeTranslation(type)}</TableCell>
            <TableCell align={"right"}>{convertBytesToSizeUnit({ bytes: size })}</TableCell>
        </TableRow>
    ));
};

export default FilesRow;
