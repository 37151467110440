import Request from "api/Request";

const GetDefaultMasks = Request.props({
    path: "/com/studionetworksolutions/evo/Nas",
    method: "get_default_masks"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetDefaultMasks;
