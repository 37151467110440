import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import ConfirmationDialog from "components/ConfirmationDialog";
import Select from "components/MobxForm/Select";
import createForm from "utils/createForm";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowTextInput from "components/RowTextInput";
import { useModalCombine } from "hooks/useModalCombine";
import { useStore } from "hooks/useStore";
import { ADVANCED_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import ErrorButton from "components/ErrorButton/ErrorButton";
import { getRange } from "utils/getRange";

const NETWORKING_SETTINGS_DIALOG_ID_PREFIX = `${ADVANCED_PAGE_ID_PREFIX}_networking_settings_dialog`;
const MIN_TCP_SCALING = 4;
const MAX_TCP_SCALING = 14;

const NetworkingSettingsDialog = ({ open, closeDialog }) => {
    const { t } = useTranslation();
    const {
        store: { networkingSettingsStore }
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields() }),
        isSubmitted: false,
        inProgress: false
    }));

    const { unsavedChangesModal, resetConfigModal } = useModalCombine([
        "unsavedChangesModal",
        "resetConfigModal"
    ]);

    const onEnter = () => {
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.inProgress = false;

        state.form.$("tcpScaling").set("default", networkingSettingsStore.tcpScaling || MIN_TCP_SCALING);

        state.form.reset();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted === true) {
            closeDialog();
            return;
        }
        unsavedChangesModal.open();
    };

    const onSubmit = async () => {
        const res = await networkingSettingsStore.setNetworkingSettings({
            tcpScaling: state.form.$("tcpScaling").value
        });
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    const resetConfig = async () => {
        resetConfigModal.close();
        state.inProgress = true;
        const res = await networkingSettingsStore.resetNetworkingSettings();
        if (res) {
            closeDialog();
        }
        state.inProgress = false;
    };

    return useObserver(() => (
        <Dialog
            isDataChanged={!state.form.isDefault}
            onEnter={onEnter}
            title={t("advanced.networking_settings.networking_settings_dialog.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${NETWORKING_SETTINGS_DIALOG_ID_PREFIX}_save`}
            open={open}
            onSubmit={onSubmit}
            onClose={closeIfNeeded}
            inProgress={state.inProgress}
            buttons={
                <>
                    <ErrorButton
                        submit={resetConfigModal.open}
                        disabled={state.inProgress}
                        label={t("common.button.reset")}
                        id={`${NETWORKING_SETTINGS_DIALOG_ID_PREFIX}_reset`}
                    ></ErrorButton>
                    <Button
                        onClick={closeDialog}
                        variant={"contained"}
                        color="secondary"
                        id={`${NETWORKING_SETTINGS_DIALOG_ID_PREFIX}_cancel`}
                    >
                        {t("common.button.cancel")}
                    </Button>
                </>
            }
        >

            <RowTextInput
                label={t("advanced.networking_settings.networking_settings_dialog.label.tcp_scaling")}
                control={
                    <Select
                        options={getRange(MIN_TCP_SCALING, MAX_TCP_SCALING)}
                        field={state.form.$("tcpScaling")}
                        id={`${NETWORKING_SETTINGS_DIALOG_ID_PREFIX}_tcp_scaling`}
                        displayInUpperCase={true}
                    />
                }
            />
            <ConfirmationDialog
                title={t("common.warning_dialog.title")}
                open={resetConfigModal.isOpen}
                onClose={resetConfigModal.close}
                onConfirm={resetConfig}
                confirmLabel={t("common.button.proceed")}
                cancelLabel={t("common.button.cancel")}
                fullWidth={true}
            >
                {t("advanced.networking_settings.reset_confirmation_dialog.title")}
            </ConfirmationDialog>

            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default NetworkingSettingsDialog;
