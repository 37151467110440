import { types } from "mobx-state-tree";

import BuildQueue from "api/volume/Types/BuildQueue";

const GetQueueResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(BuildQueue)
});

export default GetQueueResult;
