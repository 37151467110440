import { types } from "mobx-state-tree";

const DeviceTuple = types.model({
    path: types.string,
    id: types.string,
    ver: types.number,
    timestamp: types.number,
    timeString: types.string,
    evoVersion: types.string,
    comment: types.string
});

export default DeviceTuple;
