import { types } from "mobx-state-tree";

import Request from "api/Request";
import JsonPacketArgument from "api/slingshot/settings/Types/JsonPacketArgument.js";

const UpdateWatermark = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Transcoding1",
    method: "update_watermark",
    arguments: types.maybe(JsonPacketArgument),
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default UpdateWatermark;
