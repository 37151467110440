import React, { useEffect, useContext } from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { useObserver, useLocalStore } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./PresetsDrawer.styles";
import PresetsIcon from "assets/PresetsIcon";
import EditIcon from "assets/EditIcon";
import TrashIcon from "assets/TrashIcon";
import CreateEditPresetDialog from "../CreateEditPresetDialog";
import { VCODEC_ARRAY, CONTAINER_ARRAY, DEFAULT_VCODEC_PRESET } from "const/slingshotPresetConst";
import WarningDeletionDialog from "../WarningDeletionDialog";

const PRESETS_DRAWER_ID_PREFIX = `presets_drawer`;
const drawerName = "presetsDrawer";

const PresetsDrawer = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${PRESETS_DRAWER_ID_PREFIX}` : PRESETS_DRAWER_ID_PREFIX;
    const DEFAULT_VALUE = "default";
    const { t } = useTranslation();
    const classes = useStyles();
    const state = useLocalStore(() => ({
        isEditPresetDialogOpen: false,
        isEditPresetInProgress: false,
        isWarnningDeletionDialogIsOpen: false,
    }));

    const {
        store: { slingshotSettingsStore, uiStore },
    } = useContext(Context);

    useEffect(() => {
        if (slingshotSettingsStore.currentPresetName) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, closeDrawer);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [slingshotSettingsStore.currentPresetName]);

    const closeDrawer = () => {
        slingshotSettingsStore.setCurrentPresetName(null);
        state.isEditPresetInProgress = false;
    };

    const closeEditPresetDialog = () => {
        state.isEditPresetDialogOpen = false;
        state.isEditPresetInProgress = false;
    };

    const openEditPresetDialog = async () => {
        state.isEditPresetDialogOpen = true;
    };

    const updatePresetProgress = (progress) => {
        state.isEditPresetInProgress = progress;
    };

    const closeWarningDeletionDialog = () => {
        state.isWarnningDeletionDialogIsOpen = false;
    };

    const openWarningDeletionDialog = () => {
        state.isWarnningDeletionDialogIsOpen = true;
    };

    const onDeletePreset = async () => {
        closeWarningDeletionDialog();
        state.isDeleteInProgress = true;
        const res = await slingshotSettingsStore.deletePreset({ presetId: slingshotSettingsStore.currentPreset.id });
        state.isDeleteInProgress = false;
        res && closeDrawer();
        slingshotSettingsStore.fetchPresets();
    };

    const getVcodecLabel = (vcodecName) => {
        return (
            VCODEC_ARRAY.find((vcodec) => vcodec.value === vcodecName)?.label ||
            VCODEC_ARRAY.find((vcodec) => vcodec.value === DEFAULT_VCODEC_PRESET).label
        );
    };

    const getContainerLabel = (containerName) => {
        return (
            CONTAINER_ARRAY.find((container) => container.value === containerName)?.label ||
            CONTAINER_ARRAY.find((container) => container.value === DEFAULT_VALUE).label
        );
    };

    const renderDetails = () => {
        return slingshotSettingsStore.currentPreset ? (
            <Grid item container className={classes.body} direction={"column"} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.built_in")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {slingshotSettingsStore.currentPreset.builtin ? t("common.button.yes") : t("common.button.no")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"}>
                    <Grid item xs={6}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.video_codec")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {getVcodecLabel(slingshotSettingsStore.currentPreset.arguments.vcodec?.value)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.container")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {getContainerLabel(slingshotSettingsStore.currentPreset.arguments.container?.value)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        ) : null;
    };
    const renderActions = () => {
        return slingshotSettingsStore.currentPreset ? (
            <Grid direction={"column"} container>
                <BulkBarFlatButton
                    icon={<EditIcon />}
                    onClick={openEditPresetDialog}
                    inProgress={state.isEditPresetInProgress}
                    id={`${idPrefix}_open_edit_preset_dialog`}
                >
                    {t("common.button.edit")}
                </BulkBarFlatButton>
                {!slingshotSettingsStore.currentPreset.builtin && (
                    <BulkBarFlatButton
                        errorColor
                        icon={<TrashIcon />}
                        inProgress={state.isDeleteInProgress}
                        onClick={openWarningDeletionDialog}
                        id={`${idPrefix}_open_warning_deletion_dialog`}
                    >
                        {t("common.button.delete")}
                    </BulkBarFlatButton>
                )}
            </Grid>
        ) : null;
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            modals={[
                <CreateEditPresetDialog
                    key={"edit-preset"}
                    open={state.isEditPresetDialogOpen}
                    onClose={closeEditPresetDialog}
                    onCloseDrawer={closeDrawer}
                    changeProgressState={updatePresetProgress}
                    parentId={idPrefix}
                />,
                <WarningDeletionDialog
                    key={"delete-preset"}
                    open={state.isWarnningDeletionDialogIsOpen}
                    onClose={closeWarningDeletionDialog}
                    title={t("slingshot_page.tabs.settings.delete_preset.title")}
                    content={t("slingshot_page.tabs.settings.delete_preset", {
                        presetName: slingshotSettingsStore.currentPreset?.name || "",
                    })}
                    submit={onDeletePreset}
                    parentId={idPrefix}
                />,
            ]}
            title={slingshotSettingsStore.currentPresetName}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            icon={<PresetsIcon />}
            id={`${idPrefix}_drawer`}
        />
    ));
};

export default PresetsDrawer;
