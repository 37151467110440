import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetUserAccessQuery from "api/security/acl/Types/GetUserAccessQuery";

const GetUserAccess = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Acl",
    method: "get_user_access",
    arguments: types.maybe(GetUserAccessQuery)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetUserAccess
