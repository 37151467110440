import { types } from "mobx-state-tree";

import Request from "api/Request";
import NetSysctlSettings from "api/net/Types/NetSysctlSettings";

const SetNetSysctlSettings = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "set_net_sysctl_settings",
    arguments: types.maybe(NetSysctlSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetNetSysctlSettings;
