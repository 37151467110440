import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        width: "100%"
    },
    oval: {
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: ({ enabled }) => (enabled ? theme.palette.success.main : theme.palette.error.main)
    }
}));
