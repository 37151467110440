import isFieldEmpty from "utils/isFieldEmptyRule";
import isFieldHasOnlySpaces from "utils/isFieldHasOnlySpacesRule";
import i18n from "i18n";

const createFields = () => {
    return [
        {
            name: "name",
            validators: [isFieldEmpty(i18n.t("rule.field.empty.common_field")), isFieldHasOnlySpaces(i18n.t("rule.user.name"))]
        },
        {
            name: "type"
        },
        {
            name: "replica"
        },
        {
            name: "arbiter",
            type: "checkbox"
        },
        {
            name: "disperse"
        },
        {
            name: "redundancy"
        },
        {
            name: "setupManually",
            type: "checkbox"
        }
    ];
};

export default createFields;
