import React, { useEffect, useCallback } from "react";
import { observer, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import SideBulkBar from "components/SideBulkBar";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import TrashIcon from "assets/TrashIcon";
import CloseIcon from "assets/CloseIcon";
import UnlockMediumIcon from "assets/UnlockMediumIcon";
import WarningDialog from "../WarningDialog";
import UnmapConfirmationDialog from "../UnmapConfirmationDialog/UnmapConfirmationDialog";
import { Grid, Typography } from "@material-ui/core";
import EditIcon from "assets/EditIcon";
import EditShareDialog from "../EditShareDialog";
import EditAclModal from "../EditAclModal";
import EditUsbAclModal from "../EditUsbAclModal";
import SharingIcon from "assets/SharingIcon";
import { equalsIgnoreCase } from "utils/equalsIgnoreCase";
import EditAclNfsShareModal from "../EditAclNfsShareModal";
import EditAclFtpShareModal from "../EditAclFtpShareModal";
import { AssignUsersOrGroupsModal } from "../AssignUsersOrGroupsModal/AssignUsersOrGroupsModal";
import CustomizeSettingsModal from "../AssignUsersOrGroupsModal/components/CustomizeSettingsModal";
import { autorun, toJS } from "mobx";
import QuestionAssignModal from "../QuestionAssignModal";
import ClearLockConfirmDialog from "../ClearLockConfirmDialog/ClearLockConfirmDialog";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { useStore } from "hooks/useStore";

const SHARES_BULK_OPERATIONS_BAR_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_shares_bulk_operations_bar`;
const drawerName = "SharesBulkOperationsBar";

const SharesBulkOperationsBar = () => {
    const { t } = useTranslation();
    const {
        store: { aclStore, uiStore, userStore, rootShareStore, storageUsageGraphStore },
    } = useStore();
    const state = useLocalStore(() => ({
        isUnmapInProgress: false,
        isClearLocksInProgress: false,
        isDeleteInProgress: false,
        isWarningDialogOpen: false,
        isUnmapConfirmationDialogOpen: false,
        isEditShareOpen: false,
        isGetShareInfoInProgress: false,
        isEditAclModalOpen: false,
        isEditAclNfsModalOpen: false,
        isEditAclUsbModalOpen: false,
        isEditAclFtpModalOpen: false,
        isAssignUsersOrGroupsModalOpen: false,
        isCustomizeSettingsModalOpen: false,
        isQuestionAssignModalOpen: false,
        isClearLocksConfirmationDialogOpen: false,
        get sharesList() {
            return rootShareStore.allCheckedSharesNames.map((share) => share).join(",\n");
        },
        get currentShare() {
            const foundCard =
                rootShareStore.storeWithOnlyOneCheckedShare?.shareStore.sharesTableStore &&
                rootShareStore.storeWithOnlyOneCheckedShare?.shareStore.sharesTableStore.data.find((shareCard) =>
                    shareCard.shares.some((share) => share.attributes.shareName === rootShareStore._allCheckedSharesNames[0])
                );

            return (
                foundCard &&
                foundCard.shares.find((share) => share.attributes.shareName === rootShareStore._allCheckedSharesNames[0])
            );
        },
        get isSomeShareOnUSBPoolChecked() {
            return rootShareStore.storesWithCheckedShares?.some((store) =>
                store.shareStore.sharesTableStore.data.some((shareCard) =>
                    shareCard.shares.some((share) =>
                        rootShareStore._allCheckedSharesNames.some(
                            (shareName) =>
                                shareName === share.attributes.shareName && equalsIgnoreCase(share.location.pool, "USB")
                        )
                    )
                )
            );
        },
        get shareName() {
            return (
                rootShareStore.storeWithOnlyOneCheckedShare?.shareStore.currentShareName ||
                rootShareStore.storeWithOnlyOneCheckedShare?.shareStore.currentCheckedShareName ||
                rootShareStore.storeWithOnlyOneCheckedShare?.aclStore.currentShareName
            );
        },
        get isNewShareCreated() {
            return !!rootShareStore.lastCreatedShare.type;
        },
    }));

    useEffect(() => {
        if (rootShareStore.checkedSharesCount > 0) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, onClose);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
            uiStore.changeDrawer();
        }
    }, [rootShareStore.checkedSharesCount]);

    const unmapShares = async () => {
        closeUnmapConfirmationDialog();
        state.isUnmapInProgress = true;
        const res = await rootShareStore.unmapShares();
        state.isUnmapInProgress = false;
        if (res && uiStore.isWidgetsBarOpen) {
            storageUsageGraphStore.fetchData();
        }
        onClose();
    };
    const clearLocks = async () => {
        closeClearLocksConfirmationDialog();
        state.isClearLocksInProgress = true;
        const res = await rootShareStore.clearLocks();
        state.isClearLocksInProgress = false;
        res && onClose();
    };
    const deleteShares = async () => {
        closeWarningDialog();
        state.isDeleteInProgress = true;
        const res = await rootShareStore.deleteShares();
        state.isDeleteInProgress = false;
        if (res && uiStore.isWidgetsBarOpen) {
            storageUsageGraphStore.fetchData();
        }
        onClose();
    };

    const onClose = () => {
        rootShareStore.dropAllCheckedShares();
        rootShareStore.dropAllCurrentShareNames();
    };

    const openUnmapConfirmationDialog = () => {
        state.isUnmapConfirmationDialogOpen = true;
    };
    const closeUnmapConfirmationDialog = () => {
        state.isUnmapConfirmationDialogOpen = false;
    };

    const openWarningDialog = () => {
        state.isWarningDialogOpen = true;
    };
    const closeWarningDialog = () => {
        state.isWarningDialogOpen = false;
    };

    const openEditShareModal = async () => {
        state.isGetShareInfoInProgress = true;
        await rootShareStore.storeWithOnlyOneCheckedShare?.shareStore.getShareInfo({
            shareName: rootShareStore._allCheckedSharesNames[0],
        });
        state.isGetShareInfoInProgress = false;
        state.isEditShareOpen = true;
    };

    const closeEditShareModal = () => {
        state.isEditShareOpen = false;
    };

    const clearCreateShareType = () => {
        if (rootShareStore.lastCreatedShare.type) {
            rootShareStore.clearCreateShareType();
        }
    };

    const openAssignUsersOrGroupsModal = () => {
        state.isAssignUsersOrGroupsModalOpen = true;
    };

    const closeAssignUsersOrGroupsModal = () => {
        state.isAssignUsersOrGroupsModalOpen = false;
        clearCreateShareType();
    };

    const openQuestionAssignModal = () => {
        state.isQuestionAssignModalOpen = true;
    };

    const closeQuestionAssignModal = () => {
        state.isQuestionAssignModalOpen = false;
    };

    const openEditAclModal = async () => {
        if (!rootShareStore.storeWithOnlyOneCheckedShare) return;
        rootShareStore.storeWithOnlyOneCheckedShare.aclStore.setCurrentShareName(rootShareStore._allCheckedSharesNames[0]);

        if (equalsIgnoreCase(state.currentShare?.location.pool, "USB")) {
            state.isEditAclUsbModalOpen = true;
        } else {
            switch (state.currentShare?.attributes.shareType) {
                case "smb":
                    state.isEditAclModalOpen = true;
                    break;
                case "nfs":
                    await rootShareStore.storeWithOnlyOneCheckedShare.aclStore.fetchAllowedHosts({
                        shareName: state.shareName,
                    });
                    state.isEditAclNfsModalOpen = true;
                    break;
                case "ftp":
                    state.isEditAclFtpModalOpen = true;
                    break;
                case "sftp":
                    state.isEditAclFtpModalOpen = true;
                    break;
                case "fxp":
                    state.isEditAclFtpModalOpen = true;
                    break;
                default:
                    break;
            }
        }
    };

    const createModalAfterCreateShare = async () => {
        switch (rootShareStore.lastCreatedShare.type) {
            case "smb":
                openAssignUsersOrGroupsModal();
                break;
            case "ftp":
                rootShareStore.rootStoreWithCurrentShare &&
                    rootShareStore.rootStoreWithCurrentShare.aclStore.setCurrentShareName(
                        rootShareStore.currentShareStore.currentShareName
                    );
                state.isEditAclFtpModalOpen = true;
                break;
            case "nfs":
                await rootShareStore.storeWithLastCreatedShare?.aclStore.fetchAllowedHosts({
                    shareName: rootShareStore.storeWithLastCreatedShare?.shareStore.currentCheckedShareName,
                });
                state.isEditAclNfsModalOpen = true;
                break;
            default:
                rootShareStore.clearCreateShareType();
        }
    };

    useEffect(() => {
        autorun(() => {
            if (rootShareStore.lastCreatedShare.type && rootShareStore.lastCreatedShare.type !== "internal") {
                openQuestionAssignModal();
            }
        });
    }, [rootShareStore.lastCreatedShare.type]);

    const closeEditAclModal = () => {
        state.isEditAclModalOpen = false;
    };

    const closeEditAclNfsModal = useCallback(() => {
        state.isEditAclNfsModalOpen = false;
        clearCreateShareType();
    });

    const closeEditAclFtpModal = useCallback(() => {
        state.isEditAclFtpModalOpen = false;
        clearCreateShareType();
    });

    const closeEditAclUsbModal = useCallback(() => {
        state.isEditAclUsbModalOpen = false;
    });

    const closeCustomizeSettingsModal = () => {
        state.isCustomizeSettingsModalOpen = false;
        aclStore.setCurrentShareName(null);
    };

    const onCustomizeSettingsModalBack = () => {
        closeCustomizeSettingsModal();
        openAssignUsersOrGroupsModal();
    };

    const openCustomizeSettingsDialog = (entity, openUnsavedChangesDialog, force) => () => {
        if (force) {
            closeAssignUsersOrGroupsModal();
            userStore.setCurrentUserForChangingAccess(entity);
            state.isCustomizeSettingsModalOpen = true;
        }

        const preparedEntity = { id: toJS(entity.user), isExternal: false, isGroup: false };

        if (userStore.hasAccessChanges && openUnsavedChangesDialog) {
            return openUnsavedChangesDialog(preparedEntity);
        }

        closeAssignUsersOrGroupsModal();

        !force && userStore.setCurrentUserForChangingAccess(preparedEntity);
        state.isCustomizeSettingsModalOpen = true;
    };

    const isUSBPool = equalsIgnoreCase(state.currentShare?.location.pool, "USB");

    const openClearLocksConfirmationDialog = () => {
        state.isClearLocksConfirmationDialogOpen = true;
    };

    const closeClearLocksConfirmationDialog = () => {
        state.isClearLocksConfirmationDialogOpen = false;
    };

    return (
        <>
            <SideBulkBar
                itemsCount={rootShareStore.checkedSharesCount}
                onClose={onClose}
                isOpen={uiStore.openedDrawer === drawerName}
            >
                {rootShareStore.checkedSharesCount === 1 ? (
                    <BulkBarFlatButton
                        icon={<EditIcon />}
                        inProgress={state.isGetShareInfoInProgress}
                        onClick={openEditShareModal}
                        id={`${SHARES_BULK_OPERATIONS_BAR_ID_PREFIX}_edit`}
                    >
                        {t("share.shared_pool_card.table.header.context_menu.edit")}
                    </BulkBarFlatButton>
                ) : null}

                <BulkBarFlatButton
                    icon={<CloseIcon />}
                    inProgress={state.isUnmapInProgress}
                    onClick={openUnmapConfirmationDialog}
                    id={`${SHARES_BULK_OPERATIONS_BAR_ID_PREFIX}_unmap`}
                >
                    {t("share.bulk_operations_bar.button.unmap")}
                </BulkBarFlatButton>
                {!isUSBPool && !state.isSomeShareOnUSBPoolChecked ? (
                    <BulkBarFlatButton
                        icon={<UnlockMediumIcon />}
                        inProgress={state.isClearLocksInProgress}
                        onClick={openClearLocksConfirmationDialog}
                        id={`${SHARES_BULK_OPERATIONS_BAR_ID_PREFIX}_clear_locks`}
                    >
                        {t("share.bulk_operations_bar.button.clear_locks")}
                    </BulkBarFlatButton>
                ) : null}

                {rootShareStore.checkedSharesCount === 1 ? (
                    <React.Fragment>
                        {state.currentShare?.attributes.shareType !== "internal" ? (
                            <BulkBarFlatButton
                                icon={<SharingIcon />}
                                onClick={openEditAclModal}
                                id={`${SHARES_BULK_OPERATIONS_BAR_ID_PREFIX}_edit_acl`}
                            >
                                {t("share.shared_pool_card.table.header.context_menu.edit_acl")}
                            </BulkBarFlatButton>
                        ) : null}

                        {state.currentShare?.attributes.shareType === "smb" &&
                        !isUSBPool &&
                        !state.isSomeShareOnUSBPoolChecked ? (
                            <BulkBarFlatButton
                                icon={<SharingIcon />}
                                onClick={openAssignUsersOrGroupsModal}
                                id={`${SHARES_BULK_OPERATIONS_BAR_ID_PREFIX}_assign_users_or_groups`}
                            >
                                {t("share.shared_pool_card.table.header.context_menu.assign_users_or_groups")}
                            </BulkBarFlatButton>
                        ) : null}
                    </React.Fragment>
                ) : null}

                {!isUSBPool && !state.isSomeShareOnUSBPoolChecked ? (
                    <BulkBarFlatButton
                        icon={<TrashIcon />}
                        inProgress={state.isDeleteInProgress}
                        onClick={openWarningDialog}
                        errorColor
                        id={`${SHARES_BULK_OPERATIONS_BAR_ID_PREFIX}_delete`}
                    >
                        {t("share.bulk_operations_bar.button.delete")}
                    </BulkBarFlatButton>
                ) : null}
            </SideBulkBar>
            <WarningDialog
                title={t("share.shares.warning_dialog.title", { count: rootShareStore.checkedSharesCount })}
                open={state.isWarningDialogOpen}
                submitBtnLabel={t("share.shares.warning_dialog.ok_button")}
                submitBtnId={`${SHARES_BULK_OPERATIONS_BAR_ID_PREFIX}_ok_button`}
                onClose={closeWarningDialog}
                submit={deleteShares}
                question={
                    rootShareStore.checkedSharesCount > 1 ? (
                        <Grid container spacing={3}>
                            <Grid item container>
                                <Typography>{t("share.shares.checked_shares.warning_dialog.content_start")}</Typography>
                            </Grid>
                            <Grid item container>
                                <Typography>{state.sharesList}.</Typography>
                            </Grid>
                            <Grid item container>
                                <Typography>{t("share.shares.checked_shares.warning_dialog.content_end")}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <Typography>
                                {t("share.shares.warning_dialog.content", {
                                    shareName: rootShareStore.allCheckedSharesNames || "",
                                })}
                            </Typography>
                        </>
                    )
                }
            />
            <UnmapConfirmationDialog
                open={state.isUnmapConfirmationDialogOpen}
                onClose={closeUnmapConfirmationDialog}
                submit={unmapShares}
                itemCount={rootShareStore.checkedSharesCount}
                content={
                    rootShareStore.checkedSharesCount > 1 ? (
                        <Grid container spacing={3}>
                            <Grid item container>
                                <Typography>{t("share.shares.checked_shares.unmap_confirmation_dialog.content")}</Typography>
                            </Grid>
                            <Grid item container>
                                <Typography>{state.sharesList}.</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <Typography>
                                {t("share.shares.unmap_confirmation_dialog.content", {
                                    shareName: rootShareStore.allCheckedSharesNames,
                                })}
                            </Typography>
                        </>
                    )
                }
            />
            <ClearLockConfirmDialog
                open={state.isClearLocksConfirmationDialogOpen}
                onClose={closeClearLocksConfirmationDialog}
                submit={clearLocks}
                countShare={rootShareStore.checkedSharesCount}
                shareNames={state.sharesList}
            />
            <EditShareDialog
                pool={rootShareStore.storeWithOnlyOneCheckedShare?.shareStore.currentEditableShare?.location.pool}
                afterSubmitCallback={onClose}
                open={state.isEditShareOpen}
                onClose={closeEditShareModal}
                ip={rootShareStore.storeWithOnlyOneCheckedShare?.ip}
            />
            <EditUsbAclModal
                afterSubmitCallback={onClose}
                open={state.isEditAclUsbModalOpen}
                onClose={closeEditAclUsbModal}
                ip={rootShareStore.storeWithOnlyOneCheckedShare?.ip}
            />
            <EditAclModal
                afterSubmitCallback={onClose}
                open={state.isEditAclModalOpen}
                onClose={closeEditAclModal}
                ip={rootShareStore.storeWithOnlyOneCheckedShare?.ip}
            />
            <EditAclNfsShareModal
                ip={
                    state.isNewShareCreated ? rootShareStore.lastCreatedShare.ip : rootShareStore.storeWithOnlyOneCheckedShare?.ip
                }
                afterSubmitCallback={onClose}
                open={state.isEditAclNfsModalOpen}
                onClose={closeEditAclNfsModal}
            />
            <EditAclFtpShareModal
                ip={
                    state.isNewShareCreated ? rootShareStore.lastCreatedShare.ip : rootShareStore.storeWithOnlyOneCheckedShare?.ip
                }
                afterSubmitCallback={onClose}
                open={state.isEditAclFtpModalOpen}
                onClose={closeEditAclFtpModal}
            />
            <AssignUsersOrGroupsModal
                ip={
                    state.isNewShareCreated ? rootShareStore.lastCreatedShare.ip : rootShareStore.storeWithOnlyOneCheckedShare?.ip
                }
                open={state.isAssignUsersOrGroupsModalOpen}
                onClose={closeAssignUsersOrGroupsModal}
                openCustom={openCustomizeSettingsDialog}
                afterSubmitCallback={onClose}
            />
            <CustomizeSettingsModal
                ip={
                    state.isNewShareCreated ? rootShareStore.lastCreatedShare.ip : rootShareStore.storeWithOnlyOneCheckedShare?.ip
                }
                open={state.isCustomizeSettingsModalOpen}
                onClose={closeCustomizeSettingsModal}
                onBack={onCustomizeSettingsModalBack}
            />
            <QuestionAssignModal
                type={rootShareStore.lastCreatedShare.type}
                ip={rootShareStore.lastCreatedShare.ip}
                open={state.isQuestionAssignModalOpen}
                onClose={closeQuestionAssignModal}
                dropLastCreatedShareType={rootShareStore.clearCreateShareType}
                onConfirm={createModalAfterCreateShare}
            />
        </>
    );
};

export default observer(SharesBulkOperationsBar);
