import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    withIcon: {
        "& svg path": {
            fill: theme.palette.grey[300],
        },
    },
    container: {
        padding: theme.spacing(4, 6, 8),
    },
}));
