import React from "react";
import Dialog from "components/Dialog";
import { Typography, Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const SaveChangesDialog = ({ open, onClose, onCancel, onSave }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            title={t("common.unsaved_changes_dialog.title")}
            open={open}
            onClose={onClose}
            withoutIconClose
            withoutDividers
            submitBtn={
                <Button onClick={onSave} variant={"contained"} color={"primary"}>
                    {t("common.button.yes")}
                </Button>
            }
            buttons={
                <>
                    <Button onClick={onClose} variant={"contained"} color={"secondary"}>
                        {t("common.button.cancel")}
                    </Button>
                    <Button onClick={onCancel} variant={"contained"} color={"secondary"}>
                        {t("common.button.no")}
                    </Button>
                </>
            }
        >
            <Grid container direction={"column"} spacing={6}>
                <Grid item>
                    <Typography>{t("common.unsaved_changes_dialog.message")}</Typography>
                </Grid>
                <Grid item>
                    <Typography>{t("common.save_changes_dialog.question")}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default SaveChangesDialog;
