import { types } from "mobx-state-tree";

import DhcpClientSettings from "api/net/Types/DhcpClientSettings";
import DhcpServerSettings from "api/net/Types/DhcpServerSettings";
import StpSettings from "api/net/Types/StpSettings";

const NetBridge = types.model({
    port: types.string,
    ip: types.string,
    netmask: types.string,
    gateway: types.string,
    speed: types.string,
    status: types.string,
    mtu: types.number,
    metric: types.number,
    mac: types.string,
    dhcpClient: types.maybe(DhcpClientSettings),
    dhcpServer: types.maybe(DhcpServerSettings),
    interfaces: types.array(types.string),
    stp: types.maybe(StpSettings),
    builtin: types.boolean,
});

export default NetBridge;
