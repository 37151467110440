import { types } from "mobx-state-tree";

import ReplaceDrive from "api/drive_replace/Types/ReplaceDrive";

const RepairPoolArguments = types.model({
    poolName: types.string,
    drives: types.array(ReplaceDrive)
});

export default RepairPoolArguments;
