import { types, flow, getParent } from "mobx-state-tree";
import MultipleEvosRootStore from "store/MultipleEvosRootStore";

const ShareDrawerModel = types
    .model("ShareDrawer", {})
    .views((self) => {
        const { shareStore } = getParent(self);
        const multipleStores = MultipleEvosRootStore.stores;
        return {
            get _allCheckedSharesNames() {
                let sharesNames = [...shareStore.checkedSharesNames];
                multipleStores.forEach((store) => (sharesNames = [...sharesNames, ...store.shareStore.checkedSharesNames]));
                return sharesNames;
            },
            get allCheckedSharesNames() {
                let sharesNames = [...shareStore.checkedSharesNamesWithPrefix];
                multipleStores.forEach(
                    (store) => (sharesNames = [...sharesNames, ...store.shareStore.checkedSharesNamesWithPrefix])
                );
                return sharesNames;
            },
            get checkedSharesCount() {
                return self._allCheckedSharesNames.length;
            },
            get currentShareName() {
                const storeWithCurrentShareName = [...multipleStores]
                    .map(([, value]) => value)
                    .find((store) => store.shareStore.currentShareName);

                return shareStore.currentShareName || storeWithCurrentShareName?.shareStore.currentShareName || null;
            },
            get currentShareNameWithPrefix() {
                const storeWithCurrentShareName = [...multipleStores]
                    .map(([, value]) => value)
                    .find((store) => store.shareStore.currentShareNameWithPrefix);

                return (
                    shareStore.currentShareNameWithPrefix ||
                    storeWithCurrentShareName?.shareStore.currentShareNameWithPrefix ||
                    null
                );
            },
            get currentShareStore() {
                const shareStoreContainCurrentShare = shareStore.currentShareNameWithPrefix === self.currentShareNameWithPrefix;
                return (
                    [...multipleStores].map(([, value]) => value).find((store) => store.shareStore.currentShareNameWithPrefix)
                        ?.shareStore ||
                    (shareStoreContainCurrentShare && shareStore)
                );
            },
            get rootStoreWithCurrentShare() {
                return self.currentShareStore && getParent(self.currentShareStore);
            },
            get currentShareStoreIp() {
                return (self.currentShareStore && getParent(self.currentShareStore)?.ip) || null;
            },
            get storeWithOnlyOneCheckedShare() {
                if (self.checkedSharesCount !== 1) return null;

                const shareStoreContainCurrentCheckedShare = shareStore.checkedSharesNames[0] === self._allCheckedSharesNames[0];
                return (
                    [...multipleStores]
                        .map(([, value]) => value)
                        .find((store) => store.shareStore.checkedSharesNames[0] === self._allCheckedSharesNames[0]) ||
                    (shareStoreContainCurrentCheckedShare && getParent(self))
                );
            },
            get storesWithCheckedShares() {
                const stores = [];
                if (shareStore.checkedSharesCount > 0) {
                    stores.push(getParent(self));
                }
                [...multipleStores]
                    .map(([, value]) => value)
                    .forEach((store) => store.shareStore.checkedSharesCount > 0 && stores.push(store));
                return stores;
            },
            get storeWithLastCreatedShare() {
                const mainStore = shareStore.createdShareType ? getParent(self) : null;

                return (
                    mainStore || [...multipleStores].map(([, value]) => value).find((store) => store.shareStore.createdShareType)
                );
            },
            get lastCreatedShare() {
                const share = { type: null, ip: null };
                if (self.storeWithLastCreatedShare) {
                    share.ip = self.storeWithLastCreatedShare.ip;
                    share.type = self.storeWithLastCreatedShare.shareStore.createdShareType;
                }
                return share;
            },
        };
    })
    .actions((self) => {
        const { shareStore } = getParent(self);
        const multipleStores = MultipleEvosRootStore.stores;
        return {
            unmapShares: flow(function* () {
                const { processingStore } = getParent(self);
                try {
                    processingStore.setLoading(true);

                    yield Promise.all(
                        self.storesWithCheckedShares.map((store) =>
                            store.shareStore.removeShares({
                                delete: false,
                                shareNames: [...store.shareStore.checkedSharesNames],
                            })
                        )
                    );
                    return true;
                } catch (e) {
                    //handled in shareStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            clearLocks: flow(function* () {
                const { processingStore } = getParent(self);
                try {
                    processingStore.setLoading(true);

                    yield Promise.all(
                        self.storesWithCheckedShares.map((store) =>
                            store.shareStore.clearLocks({ shareNames: [...store.shareStore.checkedSharesNames] })
                        )
                    );
                    return true;
                } catch (e) {
                    //handled in shareStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            deleteShares: flow(function* () {
                const { processingStore } = getParent(self);
                try {
                    processingStore.setLoading(true);

                    yield Promise.all(
                        self.storesWithCheckedShares.map((store) =>
                            store.shareStore.removeShares({
                                delete: true,
                                shareNames: [...store.shareStore.checkedSharesNames],
                            })
                        )
                    );
                    return true;
                } catch (e) {
                    //handled in shareStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            dropOthersCurrentShareNames: (ip) => {
                if (ip) {
                    shareStore.setCurrentShareName(null);
                    multipleStores.forEach((store) => store.ip !== ip && store.shareStore.setCurrentShareName(null));
                } else {
                    multipleStores.forEach((store) => store.shareStore.setCurrentShareName(null));
                }
            },
            dropAllCurrentShareNames: () => {
                shareStore.setCurrentShareName(null);
                multipleStores.forEach((store) => store.shareStore.setCurrentShareName(null));
            },
            dropAllCheckedShares: () => {
                shareStore.clearCheckedShares();
                multipleStores.forEach((store) => store.shareStore.clearCheckedShares());
            },
            clearCreateShareType: () => {
                shareStore.clearCreateShareType();
                multipleStores.forEach((store) => store.shareStore.clearCreateShareType());
            },
            clearAfterCreateShareData: () => {
                shareStore.clearAfterCreateShareData();
                multipleStores.forEach((store) => store.shareStore.clearAfterCreateShareData());
            },
        };
    });

export default ShareDrawerModel;
