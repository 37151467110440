import React from "react";
import { observer, useLocalStore } from "mobx-react";
import DataCard from "components/DataCard";
import { ButtonBase, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import TableSortLabel from "components/TableSortLabel";
import PlusIcon from "assets/PlusIcon";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { SLINGSHOT_WATERMARKS_CARD } from "const/widgetNameConst";
import i18next from "i18next";
import { useStore } from "hooks/useStore";
import TableRowControlButton from "components/TableRowControlButton/TableRowControlButton";
import ChevronRightIcon from "assets/ChevronRightIcon";
import { useAddWatermarkModal } from "hooks/modals";
import AddWatermarkDialog from "./components/AddWatermarkDialog/AddWatermarkDialog";
import WatermarkDrawer from "./components/WatermarkDrawer/WatermarkDrawer";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { SLINGSHOT_WATERMARK_NAME } from "const/sortColumnConst";

const WATERMARKS_ID_PREFIX = `watermarks`;

const Watermarks = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${WATERMARKS_ID_PREFIX}` : WATERMARKS_ID_PREFIX;
    const {
        store: { slingshotSettingsStore, authStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isLoading: false,
        startLoading() {
            this.isLoading = true;
        },
        endLoading() {
            this.isLoading = false;
        },
    }));

    useAsyncEffect(async () => {
        state.startLoading();
        await slingshotSettingsStore.getWatermarks();
        state.endLoading();
    }, []);

    const addWatermarkModal = useAddWatermarkModal();

    const handleClickRow = (watermarkName) => () => {
        slingshotSettingsStore.setCurrentWatermarkName(watermarkName);
    };

    const changeSorting = (column) => () => slingshotSettingsStore.changeSortingWatermarks(column);

    return (
        <DataCard
            title={i18next.t("slingshot_page.tabs.settings.watermarks.title")}
            headerDivider
            emptyPadding
            headerControl={[
                <MoreMenu key={`data_card-header_control-control-1`}>
                    <HomeCardActionMenuItem
                        widget={SLINGSHOT_WATERMARKS_CARD}
                        homeCardActionMenuItemId={`${idPrefix}_add_to_home`}
                    />
                </MoreMenu>,
                authStore.isAdmin ? (
                    <ButtonBase
                        onClick={addWatermarkModal.open}
                        key={`data_card-header_control-control-2`}
                        id={`${idPrefix}_add_watermark_modal`}
                    >
                        <PlusIcon />
                    </ButtonBase>
                ) : null,
            ]}
        >
            {slingshotSettingsStore?.sortedWatermarks?.length ? (
                <React.Fragment>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={changeSorting(SLINGSHOT_WATERMARK_NAME)}
                                        direction={slingshotSettingsStore.orderWatermark}
                                        active={slingshotSettingsStore.orderByWatermark === SLINGSHOT_WATERMARK_NAME}
                                        id={`${idPrefix}_sort_name`}
                                    >
                                        {i18next.t("slingshot_page.tabs.settings.watermarks.column.name")}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {slingshotSettingsStore?.sortedWatermarks?.map((watermark) => (
                                <TableRow
                                    key={watermark.id}
                                    selected={slingshotSettingsStore.currentWatermarkName === watermark.name}
                                    onClick={handleClickRow(watermark.name)}
                                >
                                    <TableCell width="30%">{watermark.name}</TableCell>

                                    {/*[SLN-379] Hide Builtin, Personal, Disabled, Image loaded, Owner */}

                                    <TableCell align="right">
                                        <TableRowControlButton
                                            icon={<ChevronRightIcon />}
                                            id={`${idPrefix}_open_drawer_${watermark.name}`}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <WatermarkDrawer parentId={idPrefix} />
                </React.Fragment>
            ) : (
                <EmptyCardPlaceholder
                    inProgress={state.isLoading}
                    message={i18next.t("slingshot_page.tabs.settings.watermarks.is_empty")}
                />
            )}
            <AddWatermarkDialog parentId={idPrefix} />
        </DataCard>
    );
};

export default observer(Watermarks);
