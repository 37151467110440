import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./ErrorDialog.styles";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import { useObserver } from "mobx-react-lite";

const ErrorDialog = ({ open, error, onSubmit, onClose, afterSubmitCallback }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleSubmit = async e => {
        await onSubmit(e);

        setTimeout(() => {
            afterSubmitCallback();
            onClose();
        }, 1000);
    };

    return useObserver(() => (
        <Dialog open={open} fullWidth maxWidth={"sm"}>
            <DialogTitle>{t("common.dialog_title.warning")}</DialogTitle>
            <DialogContent>
                <Grid direction={"column"} container>
                    <Grid item>
                        <Typography className={classes.message}>{error}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.message}>{t("network.error_dialog.message")}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"secondary"} onClick={onClose}>
                    {t("common.button.cancel")}
                </Button>
                <AnimatedSubmitButton label={t("common.button.proceed")} submit={handleSubmit} />
            </DialogActions>
        </Dialog>
    ));
};

export default ErrorDialog;
