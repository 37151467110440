import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    quad: {
        fontSize: 14,
        fontWeight: "bold",
        wordBreak: "break-word",
        opacity: 1
    },
    detailsContainer: {
        padding: theme.spacing(2),
        margin: 0,
        width: "100%"
    },
    model: {
        wordBreak: "break-all"
    }
}));
