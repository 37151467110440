import Request from "api/Request";

const ResetNetSysctlSettings = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "reset_net_sysctl_settings"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ResetNetSysctlSettings;
