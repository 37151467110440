import React from "react";
import Grid from "@material-ui/core/Grid";
import GraphicsBar from "../GraphicsBar";
import StateBar from "../StateBar";

const WidgetsBar = () => {
    return (
        <Grid container direction={"column"}>
            <Grid item>
                <GraphicsBar />
            </Grid>
            <Grid item>
                <StateBar />
            </Grid>
        </Grid>
    );
};

export default WidgetsBar;
