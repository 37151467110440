import { createTheme } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";
import {
    TAB_BAR_ZINDEX,
    LIGHT_BLUE,
    CLEAR_BLUE,
    DARK_BLUE,
    SECONDARY_BLUE,
    RED,
    LIGHT_RED,
    ORANGE,
    GREEN,
    WHITE,
    BLACK,
    GREY_50,
    GREY_100,
    GREY_200,
    GREY_300,
    GREY_500,
    GREY_600,
    GREY_700,
    GREY_800,
    GREY_900,
    GREY_A100,
    GREY_A400,
    GREY_1000,
    TAB_BAR_HEIGHT,
    TABLE_DIVIDER_GRAY,
    PRIMARY_BUTTON_BORDER_COLOR,
    DIALOG_WIDTH,
    DIALOG_WIDTH_MD,
    DIALOG_WIDTH_LG,
    DIALOG_WIDTH_XL,
    POPOVER_BACKGROUND_COLOR,
    BUTTON_LINE_BG_GRADIENT_LIGHT_BLUE_1,
    BUTTON_LINE_BG_GRADIENT_LIGHT_BLUE_2,
    BUTTON_LINE_BG_TEXT_SHADOW_LIGHT_BLUE,
    EXPAND_PANEL_BORDER_COLOR,
} from "const/styleConst";

const muiLightTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1200,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            light: LIGHT_BLUE,
            main: CLEAR_BLUE,
            dark: DARK_BLUE,
        },
        secondary: {
            main: SECONDARY_BLUE,
        },
        error: {
            main: RED,
            light: LIGHT_RED,
        },
        warning: {
            main: ORANGE,
        },
        success: {
            main: GREEN,
        },
        grey: {
            50: GREY_50,
            100: GREY_100,
            200: GREY_200,
            300: GREY_300,
            500: GREY_500,
            600: GREY_600,
            700: GREY_700,
            800: GREY_800,
            900: GREY_900,
            1000: GREY_1000,
            A100: GREY_A100,
            A400: GREY_A400,
            POPOVER_BACKGROUND: POPOVER_BACKGROUND_COLOR,
            TABLE_DIVIDER_GRAY: TABLE_DIVIDER_GRAY,
        },
        background: {
            default: GREY_A100,
            paper: WHITE,
        },
        graphs: {
            grid: GREY_700,
            border: BLACK,
            text: BLACK,
        },
        splashCard: {
            background: WHITE,
        },
        searchInlinePopover: {
            background: POPOVER_BACKGROUND_COLOR,
            hover: EXPAND_PANEL_BORDER_COLOR,
        },
        buttonLine: {
            background: {
                gradient1: BUTTON_LINE_BG_GRADIENT_LIGHT_BLUE_1,
                gradient2: BUTTON_LINE_BG_GRADIENT_LIGHT_BLUE_2,
                textShadow: BUTTON_LINE_BG_TEXT_SHADOW_LIGHT_BLUE,
            },
        },
        expandPanel: {
            border: {
                color: EXPAND_PANEL_BORDER_COLOR,
            },
        },
        notificationMenu: {
            shadow: "0 -4px 46px 0 rgba(48, 49, 51, 0.12)",
        },
        sideMenu: {
            shadow: "8px 2px 25px 0 rgba(100, 106, 206, 0.08)",
        },
        splashPageCards: {
            background: "#d1e7ff",
        },
        fileMasksTable: {
            background: "#fafbfd",
        },
        quickstartStepCard: {
            background: CLEAR_BLUE,
            border: "none",
        },
        poolShareHeader: {
            background: "none",
        },
        text: {
            primary: GREY_A400,
            secondary: WHITE,
            disabled: hexToRgba(GREY_A400, 0.8),
        },
        divider: TABLE_DIVIDER_GRAY,
        common: {
            white: WHITE,
            black: GREY_900,
        },
    },
    overlay: {
        popover: {
            boxShadow: "0 7px 9px 0 rgba(20, 44, 90, 0.12), 0 0 3px 0 rgba(34, 19, 114, 0.05)",
        },
    },
    typography: {
        h1: {
            fontSize: 20,
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.2,
            letterSpacing: 0.2,
            color: WHITE,
        },
        h2: {
            fontSize: 20,
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.2,
            letterSpacing: 0.2,
            opacity: 0.9,
            color: GREY_A400,
        },
        h4: {
            fontSize: 12,
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.67,
            letterSpacing: 0.2,
            color: BLACK,
        },
        h6: {
            fontSize: 14,
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.5,
            letterSpacing: 0.2,
            opacity: 0.8,
            color: GREY_A400,
        },
        subtitle1: {
            fontSize: 16,
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.25,
            letterSpacing: 0.2,
            color: GREY_A400,
        },
        subtitle2: {
            fontSize: 16,
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.25,
            letterSpacing: 0.2,
            color: GREY_A400,
        },
        body1: {
            fontSize: 14,
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.5,
            letterSpacing: 0.2,
            opacity: 0.8,
            color: GREY_A400,
        },
        body2: {
            "&": {
                fontSize: 14,
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.29,
                letterSpacing: "normal",
                opacity: 0.8,
                color: GREY_A400,
            },
        },
        button: {
            fontSize: 16,
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: 1,
            color: WHITE,
        },
        caption: {
            opacity: 0.4,
            fontSize: 16,
            fontWeight: 500,
            color: GREY_500,
        },
    },
    spacing: 4,
    overrides: {
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: GREY_500,
            },
        },
        MuiSwitch: {
            root: {
                "& .MuiSwitch-switchBase": {
                    padding: 4,
                    color: WHITE,
                    "&.Mui-checked": {
                        transform: `translateX(16px)`,
                        color: GREY_100,
                        opacity: 1,
                        backgroundColor: CLEAR_BLUE,
                        "& + .MuiSwitch-track": {
                            opacity: 1,
                            backgroundColor: CLEAR_BLUE,
                        },
                        "&.Mui-disabled + .MuiSwitch-track": {
                            opacity: 0.5,
                            backgroundColor: CLEAR_BLUE,
                        },
                        "&.Mui-disabled": {
                            opacity: 1,
                            background: "none",
                        },
                    },
                    "&.Mui-disabled": {
                        opacity: 1,
                        color: WHITE,
                    },
                },
                "& .MuiSwitch-thumb": {
                    width: 16,
                    height: 16,
                    boxShadow: "none",
                },
                "& .MuiSwitch-track": {
                    borderRadius: 12,
                    opacity: 1,
                    backgroundColor: GREY_300,
                },
                "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                    backgroundColor: GREY_300,
                },
            },
        },
        MuiStepLabel: {
            label: { color: hexToRgba(GREY_A400, 0.8) },
        },
        MuiCssBaseline: {
            "@global": {
                body: {
                    opacity: `1 !important`,
                },
            },
        },
        MuiFormHelperText: {
            root: {
                "&.Mui-error": {
                    opacity: 1,
                    fontSize: 11,
                    position: "absolute",
                    zIndex: 3,
                    right: -8, // change this if the input size changes
                    top: 30, // change this if the input size changes
                    padding: "2px 8px",
                    borderRadius: 2,
                    backgroundColor: RED,
                    color: WHITE,
                    "&::after": {
                        content: "' '",
                        display: "block",
                        position: "absolute",
                        right: 10,
                        bottom: "100%",
                        width: 0,
                        height: 0,
                        border: "4px solid transparent",
                        borderBottomColor: RED,
                    },
                },
                "&.Mui-disabled": {
                    opacity: 0.2,
                },
            },
        },
        MuiTable: {
            root: {
                padding: 8,
                "& caption": {
                    padding: 0,
                },
            },
            stickyHeader: {
                borderCollapse: "collapse",
            },
        },
        MuiTableHead: {
            root: {
                borderBottom: `1px solid ${TABLE_DIVIDER_GRAY}`,
            },
        },
        MuiTableCell: {
            root: {
                padding: "16px 8px",
                "&&": {
                    fontSize: 14,
                    borderBottom: "none",
                    lineHeight: 1.5,
                    letterSpacing: 0.2,
                },
                "&&:first-child": {
                    paddingLeft: 24,
                    "& .MuiCheckbox-root": {
                        margin: -9,
                    },
                },
                "&&:last-child": {
                    paddingRight: 24,
                },
            },
            stickyHeader: {
                backgroundColor: WHITE,
            },
            head: {
                "&&": {
                    opacity: 1,
                    lineHeight: 1.25,
                },
            },
        },
        MuiTableSortLabel: {
            icon: {
                marginLeft: 0,
                marginRight: 0,
            },
            root: {
                flexDirection: "row",
                "& .MuiTypography-root": {
                    marginRight: 8,
                },
            },
        },
        MuiTableRow: {
            root: {
                "&:not(.MuiTableRow-head):hover": {
                    backgroundColor: hexToRgba(CLEAR_BLUE, 0.05),
                },
            },
        },
        MuiTablePagination: {
            toolbar: {
                height: 64,
                paddingLeft: 0,
                paddingRight: 24,
            },
            selectIcon: {
                "& path": {
                    fill: CLEAR_BLUE,
                },
            },
            spacer: {
                flex: 0,
            },
            caption: {
                order: 0,
                opacity: 0.8,
                "&~$caption": {
                    order: 1,
                    width: 100,
                },
            },
        },
        MuiButton: {
            root: {
                height: 40,
                padding: "10px 16px 10px",
            },
            label: {
                whiteSpace: "nowrap",
            },
            contained: {
                border: "solid 1px transparent",
                boxShadow: "none",
                "&.Mui-disabled": {
                    boxShadow: "none",
                    backgroundColor: GREY_100,
                    color: GREY_300,
                },
            },
            startIcon: {
                paddingLeft: 0,
                marginLeft: -8,
            },
            endIcon: {
                paddingRight: 0,
                marginRight: -8,
            },
            containedPrimary: {
                "&:hover": {
                    backgroundColor: LIGHT_BLUE,
                    boxShadow: "none",
                },
                "& svg path": {},
                "&:active": {
                    backgroundColor: CLEAR_BLUE,
                    borderColor: PRIMARY_BUTTON_BORDER_COLOR,
                },
            },
            containedSecondary: {
                backgroundColor: hexToRgba(CLEAR_BLUE, 0.12),
                color: CLEAR_BLUE,
                lineHeight: 1.25,
                boxShadow: `0 4px 8px 0 ${hexToRgba(CLEAR_BLUE, 0.0216)}`,
                "& svg path": {
                    fill: CLEAR_BLUE,
                },
                "&:hover": {
                    backgroundColor: hexToRgba(CLEAR_BLUE, 0.25),
                    boxShadow: `0 4px 8px 0 ${hexToRgba(CLEAR_BLUE, 0.045)}`,
                },
                "&:active": {
                    backgroundColor: hexToRgba(CLEAR_BLUE, 0.25),
                    boxShadow: `0 4px 8px 0 ${hexToRgba(CLEAR_BLUE, 0.0625)}`,
                    borderColor: hexToRgba(CLEAR_BLUE, 0.5),
                },
            },
            sizeLarge: {
                height: "initial",
                padding: "16px 16px",
                minWidth: 180,
            },
            sizeSmall: {
                height: "initial",
                padding: "6px 16px",
                minWidth: 80,
                fontSize: "initial",
            },
        },
        MuiAppBar: {
            root: {
                boxShadow: "0 6px 10px 0 rgba(37, 68, 130, 0.09)",
                zIndex: TAB_BAR_ZINDEX,
                height: TAB_BAR_HEIGHT,
                width: "100%",
                borderRadius: 8,
            },
        },
        MuiLinearProgress: {
            root: {
                borderRadius: 6,
                height: 8,
            },
            bar: {
                borderRadius: 6,
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: 8,
            },
            elevation1: {
                boxShadow: "0 2px 4px 0 rgba(69, 74, 160, 0.12)",
            },
        },
        MuiTabs: {
            root: {
                height: "100%",
                "& .MuiTab-root": {
                    opacity: 1,
                    minWidth: 0,
                    paddingLeft: 16,
                    paddingRight: 16,
                    marginLeft: 12,
                    marginRight: 12,
                },
                "& .MuiTab-wrapper": {
                    fontWeight: "bold",
                },
            },
            flexContainer: {
                height: "100%",
            },
        },
        MuiMenuItem: {
            root: {
                opacity: 1,
            },
        },
        PrivateTabIndicator: {
            root: {
                height: 6,
                borderTopLeftRadius: 2,
                borderTopRightRadius: 2,
            },
            colorSecondary: {
                backgroundColor: CLEAR_BLUE,
            },
        },
        MuiOutlinedInput: {
            root: {
                color: GREY_A400,
                fontSize: 14,
                fontWeight: "normal",
                lineHeight: 1.25,
                backgroundColor: GREY_A100,
                "&.Mui-disabled": {
                    backgroundColor: hexToRgba(GREY_A100, 0.4),
                    color: GREY_A400,
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                    },
                },
                "& fieldset": {
                    borderColor: "transparent",
                    "& legend span": {
                        padding: 0,
                    },
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderWidth: 1,
                },
                "&:hover:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error) fieldset": {
                    borderColor: GREY_200,
                },
                "&.Mui-error": {
                    "& .MuiInputAdornment-positionEnd": {
                        position: "absolute",
                        right: 12,
                    },
                },
            },
            input: {
                "&:-webkit-autofill:focus": {
                    "-webkit-transition-delay": "9999999s",
                    "-webkit-text-fill-color": hexToRgba(GREY_A400, 1),
                },
                "&:-webkit-autofill:hover": {
                    "-webkit-transition-delay": "9999999s",
                    "-webkit-text-fill-color": hexToRgba(GREY_A400, 1),
                },
                "&:-webkit-autofill": {
                    "-webkit-transition-delay": "9999999s",
                    "-webkit-text-fill-color": hexToRgba(GREY_A400, 1),
                },
                "&&": {
                    opacity: 0.8,
                },
                "&::placeholder": {
                    color: GREY_A400,
                    opacity: 0.5,
                },
            },
        },
        MuiRadio: {
            root: {
                color: GREY_200,
                "& .Mui-disabled": {
                    opacity: 0.4,
                },
            },
            colorPrimary: {
                "&.Mui-disabled": {
                    color: GREY_200,
                },
            },
        },
        MuiAutocomplete: {
            paper: {
                // This boxShadow is a copy of default behavior of MuiSelect material-ui v.4.12.3.
                // MuiSelect may have different behavior in newer versions.
                boxShadow:
                    "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
                opacity: 1,
                backgroundColor: POPOVER_BACKGROUND_COLOR,
            },
            root: {
                height: 40,
                width: "100%",
                "&& .MuiInputBase-root": {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                "& path": {
                    fill: CLEAR_BLUE,
                },
            },
            option: {
                '&[aria-selected="true"]': {
                    backgroundColor: "transparent",
                },
                '&[data-focus="true"]': {
                    backgroundColor: GREY_1000,
                },
            },
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: DIALOG_WIDTH,
            },
            paperWidthMd: {
                maxWidth: DIALOG_WIDTH_MD,
            },
            paperWidthLg: {
                maxWidth: DIALOG_WIDTH_LG,
            },
            paperWidthXl: {
                maxWidth: DIALOG_WIDTH_XL,
            },
        },
        MuiDialogActions: {
            root: {
                padding: "16px 24px",
            },
            spacing: {
                "& > :not(:first-child)": {
                    marginLeft: 16,
                },
            },
        },
        MuiDialogTitle: {
            root: {
                padding: "20px 24px",
                "& .MuiTypography-root": {
                    opacity: 0.9,
                    fontSize: 20,
                    fontWeight: "bold",
                    letterSpacing: 0.2,
                    lineHeight: 1.2,
                },
            },
        },
        MuiDialogContent: {
            dividers: {
                borderTop: `1px solid ${TABLE_DIVIDER_GRAY}`,
                borderBottom: `1px solid ${TABLE_DIVIDER_GRAY}`,
            },
            root: {
                whiteSpace: "pre-line",
                "&:not(.MuiDialogContent-dividers)": {
                    marginTop: -20,
                },
            },
        },
        MuiInputBase: {
            root: {
                opacity: 1,
                lineHeight: 1.25,
            },
        },
        MuiSelect: {
            root: {
                "&:focus": {
                    background: "transparent",
                },
                paddingLeft: 12,
            },
        },
        MuiCheckbox: {
            colorSecondary: {
                "&$checked": {
                    "&.Mui-focusVisible": {
                        color: hexToRgba(SECONDARY_BLUE, 0.4),
                    },
                },
                "&.Mui-focusVisible": {
                    color: hexToRgba(GREY_A400, 0.4),
                },
            },
        },
        MuiFormGroup: {
            root: {
                "&[type='radio']~.MuiFormHelperText-root": {
                    color: RED,
                    padding: 0,
                    position: "static",
                    fontSize: 12,
                    backgroundColor: "transparent",
                    "&:after": {
                        content: "none",
                    },
                },
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: TABLE_DIVIDER_GRAY,
            },
        },
        MuiChip: {
            root: {
                fontSize: 14,
                fontWeight: 500,
            },
        },
        MuiFab: {
            root: {
                width: 64,
                height: 64,
            },
        },
        MuiTypography: {
            gutterBottom: {
                marginBottom: "0.7em",
            },
        },
        MuiCollapse: { root: { width: "99%" }, hidden: { visibility: "initial" } },
    },
});

// const isDarkMode = localStorage.getItem("isDarkMode") ? "dark" : "light";
// const muiTheme = createMuiTheme(getThemeMode(isDarkMode));

export default muiLightTheme;
