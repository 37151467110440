import { cast, flow, getParent, types } from "mobx-state-tree";
import Socket from "websocket";
import EvoInfo from "api/evo_info/Types/EvoInfo";
import GetEvoInfo from "api/evo_info/Requests/GetEvoInfo";
import SetEvoDescription from "api/evo_info/Requests/SetEvoDescription";
import axios from "api/AxiosCommonRequest";
import { SYSTEM_INFO, EULA_URL } from "api/restRoutes";
import convertDateToTime from "utils/convertDateToTime";
import i18next from "i18next";
import { autorun } from "mobx";

const EvoSettingsStore = types
    .model({
        evoInfo: types.maybe(EvoInfo),
        eula: types.optional(types.string, ""),
        eulaAcceptedInfo: types.maybe(types.boolean),
        evoInfoTime: types.optional(types.number, 0),
    })
    .volatile(() => ({
        intervalFetch: 0,
        interval: 0,
        delta: 0,
    }))
    .views((self) => ({
        get evoVersion() {
            return self.evoInfo?.evoVersion || "";
        },
        get evoDescription() {
            return self.evoInfo?.description || "";
        },
        get evoHostname() {
            return (self.evoInfo && `${self.evoInfo?.hostname}`) || "";
        },
        get eulaAccepted() {
            return self.eulaAcceptedInfo || false;
        },
        get evoPluginList() {
            if (!self.evoInfo?.plugins) return [];

            function sortPluginByName(previusPlugin, nextPlugin) {
                if (previusPlugin.name.toLowerCase() < nextPlugin.name.toLowerCase()) return -1;
                if (previusPlugin.name.toLowerCase() > nextPlugin.name.toLowerCase()) return 1;
                return 0;
            }

            return self.evoInfo.plugins.sort(sortPluginByName);
        },
    }))
    .actions((self) => ({
        fetchEula: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const res = yield axios.get(EULA_URL);
                const { eula } = res.data;
                self.eula = eula;
                return eula;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setEvoDescription: flow(function* (description) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetEvoDescription.create().init({ description });
                const res = yield Socket.send(req);
                self.fetchEvoInfo();
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        startTimer: () => {
            if (self.interval) {
                self.stopTimer();
            }
            if (self.evoInfo) {
                self.interval = setInterval(() => {
                    self.addSecToTime();
                }, 1000);
            }
        },
        startTimerFetch: () => {
            if (self.intervalFetch) {
                self.stopTimerFetch();
            }
            self.intervalFetch = setInterval(() => {
                self.fetchEvoInfo();
            }, 10 * 60 * 1000);
        },
        addSecToTime: () => {
            self.evoInfoTime = Math.floor(Date.now() / 1000) - self.delta;
        },
        stopTimer: () => {
            clearInterval(self.interval);
        },
        stopTimerFetch: () => {
            clearInterval(self.intervalFetch);
        },
    }))
    .actions((self) => ({
        fetchEvoInfo: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetEvoInfo.create().init();
                const res = yield Socket.send(req);
                self.evoInfo = res.data;
                self.evoInfoTime = res.data && convertDateToTime(res.data.time);
                self.delta = Math.floor(Date.now() / 1000) - self.evoInfoTime;
                self.startTimer();
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        restFetchSystemInfo: flow(function* () {
            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);
                const res = yield axios.get(SYSTEM_INFO);

                const { version, unixTime, eulaAccepted, time, hostname, locale, plugins, sharebrowserWebClientUrl } = res.data;

                i18next.changeLanguage(locale);

                self.evoInfoTime = time && convertDateToTime(time);
                self.delta = Math.floor(Date.now() / 1000) - self.evoInfoTime;

                // not all data is received from server
                self.evoInfo = cast({
                    plugins,
                    evoVersion: version,
                    unixTime,
                    type: "",
                    description: "",
                    builtinPortsCount: 0,
                    time,
                    hostname,
                    sharebrowserWebClientUrl,
                });

                self.eulaAcceptedInfo = eulaAccepted;
                self.startTimer();
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
    }))
    .actions((self) => ({
        afterCreate() {
            const { authStore } = getParent(self);

            autorun(() => {
                if (authStore.isAuthorized) {
                    self.fetchEvoInfo();
                    self.startTimerFetch();
                }
            });
        },
    }));

export default EvoSettingsStore;
