import React from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "components/ConfirmationDialog";
import { equalsIgnoreCase } from "utils/equalsIgnoreCase";
import FolderIcon from "assets/FolderIcon";
import DocIcon from "assets/DocIcon";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import RenderDetails from "./components/RenderDetails/RenderDetails";
import BulkContent from "../BulkContent/BulkContent";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";

const drawerName = "filesDrawer";
const FilesDrawer = ({ setIsLoading }) => {
    const { t } = useTranslation();
    const {
        store: { recycleBinStore },
    } = useStore();
    const eraseFileModal = useModal();

    const closeDrawer = recycleBinStore.removeCurrentFileName;

    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: recycleBinStore.currentFileName });

    const openFolderByIndex = (index, isLoadindStarted) => async () => {
        recycleBinStore.setCurrentFileName(null);
        recycleBinStore.backStepDirs(index);
        !isLoadindStarted && setIsLoading(true);
        await recycleBinStore.fetchTrashFiles({
            shareName: recycleBinStore.shareRoot,
            dirOnShare: recycleBinStore.getDirOnShare,
        });
        !isLoadindStarted && setIsLoading(false);
    };

    const restoreFile = async () => {
        setIsLoading(true);
        await recycleBinStore.restoreTrashFiles({
            shareName: recycleBinStore.shareRoot,
            dirOnShare: recycleBinStore.getDirOnShare,
            fileNames: [recycleBinStore.currentFileName],
        });

        await openFolderByIndex(recycleBinStore.dirsLength, true)();
        setIsLoading(false);
    };

    const eraseFile = async () => {
        eraseFileModal.close();
        setIsLoading(true);
        await recycleBinStore.eraseTrashFiles({
            shareName: recycleBinStore.shareRoot,
            dirOnShare: recycleBinStore.getDirOnShare,
            fileNames: [recycleBinStore.currentFileName],
        });
        await openFolderByIndex(recycleBinStore.dirsLength, true)();
        setIsLoading(false);
    };

    const getFileIcon = () => {
        if (equalsIgnoreCase(recycleBinStore.currentFile?.type, "directory")) return <FolderIcon />;
        if (equalsIgnoreCase(recycleBinStore.currentFile?.type, "file")) return <DocIcon />;
        return <DocIcon />;
    };

    return useObserver(() => (
        <>
            <DetailsActionsDrawer
                title={recycleBinStore?.currentFileName || ""}
                icon={getFileIcon()}
                renderActions={() => <BulkContent restoreFile={restoreFile} eraseFileModal={eraseFileModal} />}
                renderDetails={() => <RenderDetails />}
                closeDrawer={closeDrawer}
                drawerName={drawerName}
                noWrapHeader={false}
                modals={
                    <>
                        <ConfirmationDialog
                            title={t("common.warning_dialog.title")}
                            open={eraseFileModal.isOpen}
                            onClose={eraseFileModal.close}
                            onConfirm={eraseFile}
                            confirmLabel={t("common.button.proceed")}
                            cancelLabel={t("common.button.cancel")}
                            fullWidth={true}
                        >
                            {t("recycle_bin.table.confirm_erase", {
                                count: recycleBinStore.selectedFiles?.length ? recycleBinStore.selectedFiles?.length : 1,
                            })}
                        </ConfirmationDialog>
                    </>
                }
            />
        </>
    ));
};

export default FilesDrawer;
