import React from "react";
import EmptyRequestPlaceholder from "../EmptyRequestPlaceholder";
import UsersTable from "pages/Users/components/UsersTable";
import SearchResultDrawer from "../SearchResultDrawer";

const USER_PAGE_BODY_ID_PREFIX = "user_page_body";

const UsersPageBody = ({ isEmpty, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${USER_PAGE_BODY_ID_PREFIX}` : USER_PAGE_BODY_ID_PREFIX;
    return (
        <>
            <SearchResultDrawer parentId={idPrefix} />
            {!isEmpty ? <UsersTable parentId={idPrefix} /> : <EmptyRequestPlaceholder />}
        </>
    );
};

export default UsersPageBody;
