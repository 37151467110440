import React, { useContext, useEffect, useCallback } from "react";
import { Context } from "store";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import DataCard from "components/DataCard";
import Checkbox from "components/Checkbox";
import MoreMenu from "components/MoreMenu";
import {
    Grid,
    Typography,
    Button,
    ButtonBase,
    Table,
    TableCell,
    TableBody,
    TableRow,
    TableHead,
    Divider,
    Box
} from "@material-ui/core";
import PlusIcon from "assets/PlusIcon";
import ChevronRightIcon from "assets/ChevronRightIcon";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import Select from "components/Select";
import { ACTION_ACCEPT, ACTION_DROP } from "const/firewallConst";
import TableRowControlButton from "components/TableRowControlButton";
import { useStyles } from "./Rules.styles";
import SavedInputIndicator from "components/SavedInputIndicator";
import RadioGroup from "components/RadioGroup";
import Radio from "components/Radio";
import CreateEditRuleDialog from "../CreateEditRuleDialog";
import clsx from "clsx";
import CheckThinIcon from "assets/CheckThinIcon";
import CloseIcon from "assets/CloseIcon";
import RulesDrawer from "../RulesDrawer";
import RulesBulkBar from "../RulesBulkBar";
import { getSnapshot } from "mobx-state-tree";
import { useModal } from "hooks/useModal";
import ConfirmationDialog from "components/ConfirmationDialog";
import DestructiveMenuItem from "components/DestructiveMenuItem/DestructiveMenuItem";

const Rules = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const ALL_INTERFACES = { label: t("firewall.header.interfaces_select"), value: "*" };

    const {
        store: { firewallStore }
    } = useContext(Context);

    const resetRulesModal = useModal();

    const state = useLocalStore(() => ({
        isGetRulesInProgress: true,
        isCreateRuleOpened: false,
        currentInterfaceLabel: ALL_INTERFACES.label,
        get currentInterface() {
            return firewallStore.ifacesList?.find(iface => iface.label === state.currentInterfaceLabel) || ALL_INTERFACES;
        },
        get interfaces() {
            return [ALL_INTERFACES.label, ...firewallStore.ifacesList.map(iface => iface.label)];
        },
        get currentInterfaceRules() {
            if (state.currentInterfaceLabel === ALL_INTERFACES.label) {
                return firewallStore?.firewallInterfacesRules?.find(rule => rule.iface === ALL_INTERFACES.value) || [];
            } else {
                if (!state.currentInterface) return [];
                return firewallStore?.firewallInterfacesRules?.find(rule => rule.iface === state.currentInterface.value);
            }
        },
        get mainCheckboxStatus() {
            if (
                state.currentInterfaceRules &&
                firewallStore.checkedRules.length > 0 &&
                firewallStore.checkedRules.length === state.currentInterfaceRules.rules.length
            ) {
                return "checked";
            } else if (
                state.currentInterfaceRules &&
                firewallStore.checkedRules.length > 0 &&
                firewallStore.checkedRules.length < state.currentInterfaceRules.rules.length
            ) {
                return "indeterminate";
            }
            return null;
        },
        get interfacesRules() {
            return getSnapshot(firewallStore.firewallInterfacesRules) || [];
        },
        get rollbackTime() {
            return firewallStore.rollbackTime || 0;
        }
    }));

    useEffect(() => {
        (async () => {
            await firewallStore.fetchFirewallRules();
            firewallStore.setCurrentIfaceName(ALL_INTERFACES.value);
            state.isGetRulesInProgress = false;
        })();
        firewallStore.fetchFirewallRules();
        firewallStore.getRollbackTime();
    }, []);

    const isCheckedRule = ruleIndex => {
        return firewallStore.checkedRules.includes(ruleIndex);
    };

    const onCheckboxChange = useCallback(ruleIndex => () => {
        isCheckedRule(ruleIndex) ? firewallStore.removeCheckedRule(ruleIndex) : firewallStore.addCheckedRule(ruleIndex);
    });

    const handleMainCheckbox = useCallback(() => {
        switch (state.mainCheckboxStatus) {
            case "checked":
            case "indeterminate":
                firewallStore.clearCheckedRules();
                break;
            default:
                state.currentInterfaceRules.rules.forEach((rule, ruleIndex) => {
                    firewallStore.addCheckedRule(ruleIndex);
                });
                break;
        }
    });

    const stopPropagation = e => {
        e.stopPropagation();
    };

    const onRuleClick = useCallback(ruleIndex => () => {
        if (firewallStore.checkedRules.length) {
            return;
        }

        const ruleIndexName = state.currentInterface.value + "-" + ruleIndex;
        firewallStore.setCurrentRuleIndex(firewallStore.currentRuleIndex === ruleIndexName ? null : ruleIndexName);
    });

    const resetRules = useCallback(() => {
        firewallStore.resetFirewallRules();
        resetRulesModal.close();
    });

    const onChangeInterface = event => {
        state.currentInterfaceLabel = event.target.value;
        state.currentInterface && firewallStore.setCurrentIfaceName(state.currentInterface.value);
        firewallStore.clearCheckedRules();
        firewallStore.setCurrentRuleIndex(null);
    };

    const closeCreateRuleModal = useCallback(() => {
        state.isCreateRuleOpened = false;
    });
    const openCreateRuleModal = useCallback(() => {
        state.currentInterfaceLabel !== ALL_INTERFACES && (state.isCreateRuleOpened = true);
    });

    const commitFirewallRules = useCallback(() => {
        firewallStore.commitFirewallRules();
    });

    const rollbackFirewallRules = () => {
        firewallStore.rollbackFirewallRules();
        firewallStore.setCurrentRuleIndex(null);
        firewallStore.clearCheckedRules();
    };

    const changeIfaceRulesPolicy = useCallback(policySatus => () => {
        const rules = firewallStore.interfacesRulesList;
        const rulesIndex = rules.findIndex(el => el.iface === state.currentInterface.value);
        rules[rulesIndex] = { ...rules[rulesIndex], policy: policySatus };
        firewallStore.setFirewallRules({ interfacesRules: rules });
        firewallStore.getRollbackTime();
    });

    return useObserver(() => (
        <DataCard
            emptyPadding
            headerDivider
            autoHeight
            headerControl={[
                <MoreMenu key={`data_card-header_control-control-1`}>
                    <DestructiveMenuItem onClick={resetRulesModal.open}>{t("firewall.header_menu.reset")}</DestructiveMenuItem>
                </MoreMenu>,
                <ButtonBase
                    key={`data_card-header_control-control-2`}
                    disabled={state.currentInterfaceLabel === ALL_INTERFACES}
                    onClick={openCreateRuleModal}
                >
                    <PlusIcon className={clsx(state.currentInterfaceLabel === ALL_INTERFACES && classes.disabled)} />
                </ButtonBase>
            ]}
            headerContent={
                <Grid
                    container
                    justify={"space-between"}
                    alignItems={"center"}
                    wrap={"nowrap"}
                    spacing={6}
                    className={classes.headerContent}
                >
                    <Grid item>
                        <Select
                            className={classes.select}
                            onChange={onChangeInterface}
                            value={state.currentInterfaceLabel}
                            options={state.interfaces}
                        />
                    </Grid>
                    {!!firewallStore.rollbackTime && (
                        <Grid item container justify={"flex-end"} alignItems={"center"} wrap={"nowrap"} spacing={6}>
                            <Grid item>
                                <Typography>{t("firewall.rules.commit_header", { sec: firewallStore.rollbackTime })}</Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={rollbackFirewallRules}
                                    variant={"contained"}
                                    color={"secondary"}
                                    startIcon={<CloseIcon />}
                                >
                                    {t("firewall.rules.commit_header.rollback_button")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={commitFirewallRules}
                                    variant={"contained"}
                                    color={"primary"}
                                    startIcon={<CheckThinIcon />}
                                >
                                    {t("firewall.rules.commit_header.commit_button")}
                                </Button>
                            </Grid>
                            <Box pr={6} />
                        </Grid>
                    )}
                </Grid>
            }
        >
            <>
                <RulesDrawer />
                <RulesBulkBar />
                {state.currentInterfaceRules?.rules?.length > 0 ? (
                    <Grid container direction={"column"} justify={"space-between"}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding={"checkbox"}>
                                        <Checkbox
                                            indeterminate={state.mainCheckboxStatus === "indeterminated"}
                                            checked={state.mainCheckboxStatus === "checked"}
                                            onChange={handleMainCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle1"}>{t("firewall.rules.rule_status")}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle1"}>{t("firewall.rules.protocol")}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle1"}>{t("firewall.rules.ports")}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle1"}>{t("firewall.rules.address")}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"subtitle1"}>{t("firewall.rules.action")}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.currentInterfaceRules?.rules.map((rule, index) => (
                                    <TableRow
                                        selected={firewallStore.currentRuleIndex === state.currentInterface.value + "-" + index}
                                        key={Math.floor(Math.random() * Date.now())}
                                        onClick={onRuleClick(index)}
                                    >
                                        <TableCell>
                                            <Checkbox
                                                onClick={stopPropagation}
                                                onChange={onCheckboxChange(index)}
                                                checked={isCheckedRule(index)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Grid item className={classes.indicator}>
                                                <SavedInputIndicator enabled={rule.enabled} />
                                            </Grid>
                                        </TableCell>
                                        <TableCell>{firewallStore.protocolView(rule.protocol)}</TableCell>
                                        <TableCell>
                                            {rule.portsRange.map(port => firewallStore.portsView(port)).join(", ")}
                                        </TableCell>
                                        <TableCell>
                                            {rule.addressesRange.map(address => firewallStore.addressView(address)).join(", ")}
                                        </TableCell>
                                        <TableCell>{firewallStore.actionView(rule.policy)}</TableCell>
                                        <TableCell align={"right"}>
                                            <TableRowControlButton icon={<ChevronRightIcon />} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Grid>
                            <Divider />
                            <Grid container className={classes.footer} alignItems={"center"} justify={"flex-start"}>
                                {state.currentInterfaceLabel === ALL_INTERFACES.label ? (
                                    <Grid item>
                                        <Typography>{t("firewall.rules.footer.all_interfaces")}</Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid item>
                                            <Typography>{t("firewall.rules.footer.interface")}</Typography>
                                        </Grid>
                                        <Box pl={6}>
                                            <RadioGroup
                                                value={state.currentInterfaceRules?.policy || ACTION_ACCEPT.value}
                                                className={classes.radioGroup}
                                            >
                                                <Radio
                                                    value={ACTION_ACCEPT.value}
                                                    onChange={changeIfaceRulesPolicy(ACTION_ACCEPT.value)}
                                                    label={t("firewall.rules.footer.policy.allow")}
                                                />
                                                <Radio
                                                    value={ACTION_DROP.value}
                                                    onChange={changeIfaceRulesPolicy(ACTION_DROP.value)}
                                                    label={t("firewall.rules.footer.policy.deny")}
                                                />
                                            </RadioGroup>
                                        </Box>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder
                        inProgress={state.isGetRulesInProgress}
                        message={t("firewall.rules.table.placeholder")}
                    />
                )}
            </>
            <ConfirmationDialog
                title={t("common.warning_dialog.title")}
                open={resetRulesModal.isOpen}
                onClose={resetRulesModal.close}
                onConfirm={resetRules}
                confirmLabel={t("common.button.proceed")}
                cancelLabel={t("common.button.cancel")}
                fullWidth={true}
            >
                {t("firewall.rules.confirm_reset")}
            </ConfirmationDialog>
            <CreateEditRuleDialog onClose={closeCreateRuleModal} open={state.isCreateRuleOpened} isCreate />
        </DataCard>
    ));
};

export default Rules;
