import React, { Fragment, useContext, useEffect } from "react";
import DataCard from "components/DataCard";
import {
    Grid,
    Divider,
    Box,
    Table,
    TableCell,
    TableHead,
    TableRow,
    ButtonBase,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import Checkbox from "components/Checkbox";
import { useStyles } from "./RecycleTable.styles";
import RebootIcon from "assets/RebootIcon";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import TableSortLabel from "components/TableSortLabel";
import { Context } from "store";
import { useLocalStore } from "mobx-react-lite";
import Select from "components/Select";
import ChevronRightIcon from "assets/ChevronRightIcon";
import SideBulkBar from "components/SideBulkBar";
import {
    RECYCLE_BIN_DELETE_TIME,
    RECYCLE_BIN_MODIFIED_TIME,
    RECYCLE_BIN_NAME,
    RECYCLE_BIN_SIZE,
    RECYCLE_BIN_TYPE,
} from "const/sortColumnConst";
import sortByLocaleCompare from "utils/sortByLocaleCompare";
import { useModal } from "hooks/useModal";
import ConfirmationDialog from "components/ConfirmationDialog";
import FilesTableBody from "../FilesTableBody";
import { getSnapshot } from "mobx-state-tree";
import MoreMenu from "components/MoreMenu";
import { URL_FILE_NAVIGATOR_LOG } from "routes";
import { useHistory } from "react-router-dom";
import ViewByRole from "components/ViewByRole";
import { ADMIN } from "const/userRolesConst";
import FilesDrawer from "../FilesDrawer/FilesDrawer";
import BulkContent from "../BulkContent/BulkContent";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import TablePagination from "components/TablePagination/TablePagination";

const drawerName = "RecycleBin";
const RecycleTable = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const {
        store: { shareStore, recycleBinStore },
    } = useContext(Context);
    const state = useLocalStore(() => ({
        selectedFiles: [],
        isLoading: false,
        get mainCheckboxStatus() {
            if (
                recycleBinStore.selectedFiles.length &&
                recycleBinStore.selectedFiles.length === recycleBinStore.trashFiles?.length
            ) {
                return "checked";
            } else if (recycleBinStore.selectedFiles.length > 0) {
                return "indeterminated";
            }
            return null;
        },
        get shares() {
            return (
                (shareStore.trashShares.length &&
                    sortByLocaleCompare(
                        shareStore.trashShares
                            .filter((share) => share.status.status !== "offline")
                            .map((share) => share.attributes.shareName)
                    )) ||
                []
            );
        },
        setIsLoading(isLoading) {
            state.isLoading = isLoading;
        },
    }));

    useEffect(() => {
        (async () => {
            state.setIsLoading(true);
            await shareStore.fetchSharesTable();
            if (state.shares[0]) {
                recycleBinStore.setShareRoot(state.shares[0]);
            }
            state.setIsLoading(false);
        })();
    }, []);

    useDrawerOpenHandler({ drawerName, targetDep: recycleBinStore.selectedFilesLength });

    useEffect(() => {
        resetRoot();
    }, [recycleBinStore.shareRoot]);

    const eraseFileModal = useModal();

    const clearSelected = () => {
        recycleBinStore.clearSelectedFiles();
    };
    const openFolderByIndex = (index, isLoadindStarted) => async () => {
        recycleBinStore.setCurrentFileName(null);
        if (index === 0) {
            await resetRoot(true);
            return;
        }
        recycleBinStore.backStepDirs(index);
        !isLoadindStarted && state.setIsLoading(true);
        await recycleBinStore.fetchTrashFiles({
            shareName: recycleBinStore.shareRoot,
            dirOnShare: recycleBinStore.getDirOnShare,
        });
        !isLoadindStarted && state.setIsLoading(false);
        clearSelected();
    };

    const openFolder = (folderName) => (e) => {
        e.stopPropagation();
        recycleBinStore.setCurrentFileName(null);
        recycleBinStore.addDir(folderName);
        state.setIsLoading(true);
        recycleBinStore.fetchTrashFiles({
            shareName: recycleBinStore.shareRoot,
            dirOnShare: recycleBinStore.getDirOnShare,
        });
        state.setIsLoading(false);
        clearSelected();
    };
    const onChangeShareRoot = (event) => {
        recycleBinStore.setShareRoot(event.target.value);
    };
    const resetRoot = async (isLoadindStarted) => {
        recycleBinStore.setCurrentFileName(null);
        if (!recycleBinStore.shareRoot) {
            return;
        }
        recycleBinStore.clearDirs();
        !isLoadindStarted && state.setIsLoading(true);
        await recycleBinStore.fetchTrashFiles({
            shareName: recycleBinStore.shareRoot,
            dirOnShare: "/",
        });
        !isLoadindStarted && state.setIsLoading(false);
        clearSelected();
    };

    const handleMainCheckbox = () => {
        switch (state.mainCheckboxStatus) {
            case "checked":
            case "indeterminated":
                clearSelected();
                break;
            default: {
                const selectedFiles = recycleBinStore.trashFiles?.map((file) => file.name);
                recycleBinStore.setSelectedFiles(selectedFiles);
                break;
            }
        }
    };
    const restoreFile = async () => {
        state.setIsLoading(true);
        await recycleBinStore.restoreTrashFiles({
            shareName: recycleBinStore.shareRoot,
            dirOnShare: recycleBinStore.getDirOnShare,
            fileNames: getSnapshot(recycleBinStore.selectedFiles),
        });
        await openFolderByIndex(recycleBinStore.dirsLength, true)();
        state.setIsLoading(false);
    };
    const eraseFile = async () => {
        eraseFileModal.close();
        state.setIsLoading(true);
        await recycleBinStore.eraseTrashFiles({
            shareName: recycleBinStore.shareRoot,
            dirOnShare: recycleBinStore.getDirOnShare,
            fileNames: getSnapshot(recycleBinStore.selectedFiles),
        });
        await openFolderByIndex(recycleBinStore.dirsLength, true)();
        state.setIsLoading(false);
    };

    const changeSorting = (column) => () => recycleBinStore.changeSorting(column);

    const navigateToLog = () => {
        history.push(URL_FILE_NAVIGATOR_LOG);
    };

    const renderTitle = () => {
        if (state.shares.length === 0) {
            return t("recycle_bin.title.no_shares");
        }
        return (
            <Grid spacing={1} container alignItems={"center"} alignContent={"center"}>
                {recycleBinStore.dirsLength ? (
                    [recycleBinStore.shareRoot, ...recycleBinStore.dirs].map((dir, index) => (
                        <Fragment key={`${dir}`}>
                            {index !== 0 && (
                                <Grid item>
                                    <Grid container>
                                        <ChevronRightIcon />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid onClick={openFolderByIndex(index)} item>
                                <Typography className={classes.titleDirName} variant={"h2"}>
                                    {dir}
                                </Typography>
                            </Grid>
                        </Fragment>
                    ))
                ) : (
                    <Grid item>
                        <Select
                            className={classes.rootSelect}
                            onChange={onChangeShareRoot}
                            value={recycleBinStore.shareRoot}
                            options={state.shares}
                        />
                    </Grid>
                )}
            </Grid>
        );
    };

    const Content = observer(() => {
        if (state.isLoading)
            return (
                <Grid className={classes.helperContainer} container>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            );

        if (recycleBinStore.trashFiles?.length || recycleBinStore.isLoading)
            return (
                <Grid container direction="column" justifyContent="space-between" className={classes.container}>
                    <Grid item>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding={"checkbox"}>
                                        <Checkbox
                                            disabled={recycleBinStore.isLoading}
                                            indeterminate={state.mainCheckboxStatus === "indeterminated"}
                                            checked={state.mainCheckboxStatus === "checked"}
                                            onChange={handleMainCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            disabled={recycleBinStore.isLoading}
                                            direction={recycleBinStore?.searchType.sort}
                                            onClick={changeSorting(RECYCLE_BIN_NAME)}
                                            active={recycleBinStore?.searchType.sort_dir === RECYCLE_BIN_NAME}
                                        >
                                            {t("recycle_bin.table.header.name")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            disabled={recycleBinStore.isLoading}
                                            direction={recycleBinStore?.searchType.sort}
                                            onClick={changeSorting(RECYCLE_BIN_MODIFIED_TIME)}
                                            active={recycleBinStore?.searchType.sort_dir === RECYCLE_BIN_MODIFIED_TIME}
                                        >
                                            {t("recycle_bin.table.header.modified")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            disabled={recycleBinStore.isLoading}
                                            direction={recycleBinStore?.searchType.sort}
                                            onClick={changeSorting(RECYCLE_BIN_DELETE_TIME)}
                                            active={recycleBinStore?.searchType.sort_dir === RECYCLE_BIN_DELETE_TIME}
                                        >
                                            {t("recycle_bin.table.header.deleted")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            disabled={recycleBinStore.isLoading}
                                            direction={recycleBinStore?.searchType.sort}
                                            onClick={changeSorting(RECYCLE_BIN_TYPE)}
                                            active={recycleBinStore?.searchType.sort_dir === RECYCLE_BIN_TYPE}
                                        >
                                            {t("recycle_bin.table.header.kind")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align={"right"}>
                                        <TableSortLabel
                                            disabled={recycleBinStore.isLoading}
                                            direction={recycleBinStore?.searchType.sort}
                                            onClick={changeSorting(RECYCLE_BIN_SIZE)}
                                            active={recycleBinStore?.searchType.sort_dir === RECYCLE_BIN_SIZE}
                                        >
                                            {t("recycle_bin.table.header.size")}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <FilesTableBody openFolder={openFolder} trashFiles={recycleBinStore.trashFiles} classes={classes} />
                        </Table>
                    </Grid>
                    <Grid item>
                        <Divider />
                        <Box pl={6}>
                            <TablePagination
                                disabled={recycleBinStore.isLoading}
                                onChangeRowsPerPage={recycleBinStore?.changeRowsPerPage}
                                onChangePage={recycleBinStore?.changePage}
                                count={recycleBinStore.searchType?.total ?? 0}
                                rowsPerPage={recycleBinStore?.searchType?.rowsPer ?? 25}
                                page={recycleBinStore?.searchType?.page ?? 0}
                            />
                        </Box>
                    </Grid>
                </Grid>
            );
        return (
            <Grid className={classes.helperContainer} container>
                <Grid item>
                    <Typography variant={"h2"}>
                        {state.shares.length ? t("recycle_bin.table.no_files") : t("recycle_bin.table.no_shares")}
                    </Typography>
                </Grid>
            </Grid>
        );
    });

    return (
        <DataCard
            headerDivider
            emptyPadding
            title={renderTitle()}
            stretch
            headerControl={[
                <ViewByRole key="recycle_bin_more_menu" roles={ADMIN}>
                    <MoreMenu id={"recycle_bin_more_menu"}>
                        <ButtonBase onClick={navigateToLog}>
                            <Typography className={classes.menuItem}>{t("recycle_bin.menu.file_navigator_log")}</Typography>
                        </ButtonBase>
                    </MoreMenu>
                </ViewByRole>,
                <ButtonBase
                    disabled={recycleBinStore.isLoading}
                    key="recycle_bin_rebut_btn"
                    onClick={openFolderByIndex(recycleBinStore.dirsLength)}
                >
                    <RebootIcon />
                </ButtonBase>,
            ]}
        >
            <Grid container direction="column" justifyContent="space-between">
                <Grid item xs={12}>
                    <SideBulkBar
                        isOpen={recycleBinStore.selectedFilesLength > 0}
                        onClose={recycleBinStore.clearSelectedFiles}
                        itemsCount={recycleBinStore.selectedFilesLength}
                    >
                        <BulkContent restoreFile={restoreFile} eraseFileModal={eraseFileModal} />
                    </SideBulkBar>
                    <FilesDrawer setIsLoading={state.setIsLoading} />
                    <Content />
                    <ConfirmationDialog
                        title={t("common.warning_dialog.title")}
                        open={eraseFileModal.isOpen}
                        onClose={eraseFileModal.close}
                        onConfirm={eraseFile}
                        confirmLabel={t("common.button.proceed")}
                        cancelLabel={t("common.button.cancel")}
                        fullWidth={true}
                    >
                        {t("recycle_bin.table.confirm_erase", {
                            count: recycleBinStore.selectedFiles?.length ? recycleBinStore.selectedFiles?.length : 1,
                        })}
                    </ConfirmationDialog>
                </Grid>
            </Grid>
        </DataCard>
    );
};

export default observer(RecycleTable);
