import Request from "api/Request";

const GetSystemComponentsServicesState = Request.props({
    path: "/com/studionetworksolutions/evo/manage/SystemComponents",
    method: "get_system_component_services_state"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetSystemComponentsServicesState;
