import { types } from "mobx-state-tree";

import Request from "api/Request";
import PingTestArguments from "api/net/Types/PingTestArguments";

const RunPingTest = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "run_ping_test",
    arguments: types.maybe(PingTestArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default RunPingTest;
