import React from "react";

const FiberIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                d="M17.9 12.2a1.001 1.001 0 011.319.104l.081.096 3.45 4.6-3.45 4.6-.081.096a1.002 1.002 0 01-1.214.173L17.9 21.8l-.096-.081a1.002 1.002 0 01-.173-1.214l.069-.105 1.799-2.4H5.5a1 1 0 01-.993-.883L4.5 17a1 1 0 01.883-.993L5.5 16h13.999L17.7 13.6l-.069-.105A1 1 0 0117.9 12.2zM7.1 2.2a1 1 0 01.269 1.295L7.3 3.6 5.5 6h14a1 1 0 010 2h-14l1.8 2.4c.306.408.253.973-.104 1.319L7.1 11.8a1.001 1.001 0 01-1.319-.104L5.7 11.6 2.25 7 5.7 2.4a1 1 0 011.4-.2z"
            />
        </svg>
    );
};

export default FiberIcon;
