import React from "react";
import MainTemplate from "components/MainTemplate";
import HeaderTitle from "components/HeaderTitle";
import OptionsAdminIcon from "assets/OptionsAdminIcon";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import ServerCertificateCard from "./components/ServerCertificateCard";
import NetworkingSettingsCard from "./components/NetworkingSettingsCard";
import CredentialsCard from "./components/CredentialsCard";
import SecondaryAdminsCard from "./components/SecondaryAdminsCard";
import RestrictAccessCard from "./components/RestrictAccessCard";
import LongLivedTokensCard from "./components/LongLivedTokensCard";
import { ADMIN_SETTINGS } from "const/shortcutNameConst";
import { DisplayLanguageCard } from "./components/DisplayLanguageCard/DisplayLanguageCard";
import { useObserver } from "mobx-react";

const AdminSettings = () => {
    const { t } = useTranslation();

    return useObserver(() => (
        <MainTemplate
            headerTitle={<HeaderTitle icon={<OptionsAdminIcon />} title={t("admin_settings.title")} shortcutName={ADMIN_SETTINGS} />}
        >
            <Grid container spacing={6} alignContent={"flex-start"}>
                <Grid item xs={6}>
                    <NetworkingSettingsCard />
                </Grid>
                <Grid item xs={6}>
                    <CredentialsCard />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <ServerCertificateCard />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <RestrictAccessCard />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SecondaryAdminsCard />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <DisplayLanguageCard />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <LongLivedTokensCard />
                </Grid>
            </Grid>
        </MainTemplate>
    ));
};

export default AdminSettings;
