import { types } from "mobx-state-tree";

const Plugins = types
    .model("Plugins", {
        name: types.string,
        description: types.string,
        version: types.maybe(types.string),
        alias: types.string,
        pluginId: types.maybe(types.string),
        serverPort: types.maybe(types.number)
    })
    .views(self => ({
        get urlImgPlugin() {
            if (!self.pluginId) return null;

            return `${document.location.protocol}//${document.location.hostname}${
                document.location.port ? `:${document.location.port}` : ""
            }/plugin/${self.pluginId}/logo.svg`;
        }
    }));

export default Plugins;
