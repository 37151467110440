import { types } from "mobx-state-tree";

import ShareTableCard from "api/share/Types/ShareTableCard";

const GetSharesTableResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(ShareTableCard)
});

export default GetSharesTableResult;
