import React from "react";

function DocIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="evenodd"
                d="M19.74 8.33l-5.44-6a1.001 1.001 0 00-.74-.33h-7A2.531 2.531 0 004 4.5v15A2.529 2.529 0 006.56 22h10.88A2.53 2.53 0 0020 19.5V9a.998.998 0 00-.26-.67zM17.65 9h-3.94a.79.79 0 01-.71-.85V4h.11l4.54 5zm-.21 11H6.56a.53.53 0 01-.56-.498V4.5a.53.53 0 01.558-.5H11v4.15A2.79 2.79 0 0013.71 11H18v8.5a.529.529 0 01-.558.5h-.002z"
            />
        </svg>
    );
}

export default DocIcon;
