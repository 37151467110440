import { types } from "mobx-state-tree";

import MigrationStage from "api/upgrade/migration/Types/MigrationStage";

const MigrationStateEnum = ["idle", "running", "success", "finished"];

const MigrationState = types.model({
    requiredSteps: types.array(types.string),
    currentStep: types.string,
    migrationState: types.enumeration(MigrationStateEnum),
    warnings: types.array(MigrationStage),
});

export default MigrationState;
