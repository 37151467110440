import { useObserver } from "mobx-react";
import { useStore } from "./useStore";
import { useEffect, useRef } from "react";
/**
 * Hook for get reference to modal store by name
 * @param {*} name - name(key) with which the modal will be save to the store in the map of modals object
 * @param {object} options - extra options
 * @param {function} options.handleClose - launch before close modal
 * @param {function} options.handleOpen - launch before open modal
 * @param {function} options.handleBeforeDestroy - launch before destroy modal
 * @description
 * To use it, you need to create a hook for a specific modal window in a file locate on the path ./evo/usr/src/evo-ui-web/src/hooks/modals.js
 * and from there export the hook you created to the desired component.
 * @example
 * const ANOTHER_MODAL = "ANOTHER_MODAL";
 * export const useAnotherModal = () => useModalStore(ANOTHER_MODAL);
 * // or
 * const ANOTHER_MODAL = "ANOTHER_MODAL";
 * export const useAnotherModal = (options) => useModalStore(ANOTHER_MODAL, options);
 */
export const useModalStore = (name, options = { handleClose: null, handleOpen: null, handleBeforeDestroy: null }) => {
    const {
        store: { modalStore },
    } = useStore();

    const modal = useRef(modalStore.getModal(name));

    useEffect(() => {
        if (!modal.current) {
            modal.current = modalStore.getModal(name);
        }
    }, []);

    useEffect(() => {
        if (options) {
            if (typeof options.handleClose === "function") {
                modal.current.setHandleClose(options.handleClose);
            }
            if (typeof options.handleOpen === "function") {
                modal.current.setHandleOpen(options.handleOpen);
            }
            if (typeof options.handleBeforeDestroy === "function") {
                modal.current.setHandleBeforeDestroy(options.handleBeforeDestroy);
            }
        }
    }, [options, modal.current]);

    return useObserver(() => ({
        /** @type {function} - action for open modal */
        open: modal.current.open,
        /** @type {function} - action for close modal */
        close: modal.current.close,
        /** @type {boolean} - modal opening state */
        isOpen: modal.current.isOpen,
        /** @type {function} - action for toggle a state of open all modals store to close */
        closeAll: modalStore.closeAllModals,
        value: modal.current.value,
        setValue: modal.current.setValue,
    }));
};
