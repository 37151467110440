import { types } from "mobx-state-tree";

import Request from "api/Request";
import VolumeNameArgument from "api/cluster/Types/VolumeNameArgument";

const RebalanceFixLayoutStart = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Cluster",
    method: "rebalance_fix_layout_start",
    arguments: types.maybe(VolumeNameArgument)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default RebalanceFixLayoutStart
