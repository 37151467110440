import Request from "api/Request";

const StatusNetworkSelfTest = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Troubleshooting",
    method: "status_network_self_test"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default StatusNetworkSelfTest;
