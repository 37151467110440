import { types } from "mobx-state-tree";

const JobScheduleType = types.model({
    minutes: types.maybe(types.number),
    date: types.maybe(types.string),
    not_before: types.maybe(types.string),
    not_after: types.maybe(types.string),
    duration: types.maybe(types.number),
});

export default JobScheduleType;
