import { types } from "mobx-state-tree";

import Request from "api/Request";
import UpgradeArguments from "api/upgrade/Types/UpgradeArguments";

const Upgrade = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Upgrade",
    method: "upgrade",
    arguments: types.maybe(UpgradeArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default Upgrade;
