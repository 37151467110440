const createFields = () => {
    return [
        {
            name: "poolName",
            rules: "poolName",
            default: ""
        }
    ];
};

export default createFields;
