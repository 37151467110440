import { useMessage } from "hooks/useMessage";
import { useStore } from "hooks/useStore";
import { useEffect } from "react";

export const useSyncDisksAndPools = () => {
    const { message, sendMessage } = useMessage("deleteDisks");

    const {
        store: { volumeDrawerStore }
    } = useStore();

    useEffect(() => {
        if (message.data) {
            volumeDrawerStore.clearCheckedVolumes();
            volumeDrawerStore.clearCheckedQueueVolumes();
        }
    }, [message.count]);

    return () => sendMessage("deleted");
};
