import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    placeholder: {
        marginTop: theme.spacing(4)
    },
    link: {
        cursor: "pointer"
    },
    select: {
        maxWidth: 200,
        verticalAlign: "middle",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    buttonContainer: {
        marginTop: theme.spacing(4)
    },
    button: {
        position: "relative"
    },
    icon: {
        position: "absolute"
    },
    message: {
        marginBottom: theme.spacing(4)
    }
}));
