import React, { useContext, useEffect } from "react";
import { Grid, Typography, ButtonBase } from "@material-ui/core";
import { useStyles } from "./UptimeBar.style";
import { useTranslation } from "react-i18next";
import { Context } from "store";
import { useHistory } from "react-router-dom";
import { URL_NETWORK, URL_POWER_AND_UPS, URL_SUPPORT } from "routes";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import formatDuration from "./formatDuration";

const UptimeBar = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { netServiceAdvertisingStore, supportInfoStore, powerAndUpsStore, authStore }
    } = useContext(Context);
    const history = useHistory();
    useEffect(() => {
        if (authStore.isAuthorized) {
            (async () => {
                await powerAndUpsStore.getUptime();
                powerAndUpsStore.startTimer();
            })();
            netServiceAdvertisingStore.fetchNetServiceAdvertising();
            supportInfoStore.fetchIdentity();
        }
        return powerAndUpsStore.stopTimer;
    }, [authStore.isAuthorized]);
    const openNetworkPage = () => {
        history.push(URL_NETWORK);
    };
    const openSupportPage = () => {
        history.push(URL_SUPPORT);
    };
    const openPowerPage = () => {
        history.push(URL_POWER_AND_UPS);
    };
    return useObserver(() => (
        <Grid container direction={"column"} spacing={1} className={classes.container} alignItems={"center"} justify={"center"}>
            <Grid container item>
                <ButtonBase onClick={openNetworkPage}>
                    <Typography color={"textSecondary"} className={classes.opacityText} component={"span"}>
                        {t("header.hostname")}
                    </Typography>
                    <Typography color={"textSecondary"} className={classes.strongText} component={"span"}>
                        {netServiceAdvertisingStore.hostname}
                    </Typography>
                </ButtonBase>
            </Grid>
            <Grid container item alignItems={"center"}>
                <ButtonBase onClick={openPowerPage}>
                    <Typography color={"textSecondary"} className={classes.opacityText} component={"span"}>
                        {t("header.uptime")}
                    </Typography>
                    <Typography color={"textSecondary"} className={classes.strongText} component={"span"}>
                        {formatDuration(moment.duration(powerAndUpsStore.uptimeTotalSeconds, "seconds"))}
                    </Typography>
                </ButtonBase>
            </Grid>
            <Grid container item>
                <ButtonBase onClick={openSupportPage}>
                    <Typography color={"textSecondary"} className={classes.opacityText} component={"span"}>
                        {t("header.serial_number")}
                    </Typography>
                    <Typography color={"textSecondary"} className={classes.strongText} component={"span"}>
                        {supportInfoStore.serialNumber || t("header.serial_number.not_applicable")}
                    </Typography>
                </ButtonBase>
            </Grid>
        </Grid>
    ));
};

export default UptimeBar;
