import React from "react";

const EthernetIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="a3cfe3b8-b5ee-11ea-b3de-0242ac130004" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
                <linearGradient id="a3cfe5de-b5ee-11ea-b3de-0242ac130004" x1="31.267%" y1="30.469%" y2="69.531%">
                    <stop offset="0%" stopColor="#3C1EFF" />
                    <stop offset="100%" stopColor="#3A98FF" stopOpacity=".91" />
                </linearGradient>
                <linearGradient id="a3cfe7d2-b5ee-11ea-b3de-0242ac130004" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <rect
                    width="21"
                    height="15"
                    x="1.5"
                    y="7.5"
                    fill="#85C3FF"
                    stroke="url(#a3cfe3b8-b5ee-11ea-b3de-0242ac130004)"
                    rx="2"
                />
                <rect width="16" height="10" x="4" y="10" fill="url(#a3cfe5de-b5ee-11ea-b3de-0242ac130004)" rx="1" />
                <rect
                    width="15"
                    height="3"
                    x="4.5"
                    y="1.5"
                    fill="#85C3FF"
                    stroke="url(#a3cfe7d2-b5ee-11ea-b3de-0242ac130004)"
                    rx="1.5"
                />
            </g>
        </svg>
    );
};

export default EthernetIcon;
