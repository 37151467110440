import React, { useEffect, useRef } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import * as d3 from "d3";
import { withFauxDOM } from "react-faux-dom";
import { useTranslation } from "react-i18next";
import { shortDateRegExp, TICK_Y_COUNT } from "const/chartConst";
import { useStyles } from "./StorageUsageGraph.style";
import { STORAGE_USAGE_GRAPH_DATE_FORMAT } from "const";
import moment from "moment";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const marginLeft = 30;
const marginRight = 40;
const marginTop = 10;
const marginBottom = 20;
const textMargin = 12;
const textHeight = 18;

const StorageUsageGraph = ({
    parentId = "storageUsageGraph",
    height = 150,
    animDuration = 300,
    chartInfo,
    startFetching,
    stopFetching,
    lastTime,
    lastValue,
    connectFauxDOM,
    animateFauxDOM,
    isDark,
    graphAutoscaling,
    ...props
}) => {
    const { t } = useTranslation();
    const parenfRef = useRef();
    const classes = useStyles({ isDark });
    const max = d3.max(chartInfo, (i) => i.value);
    const maxYbound = graphAutoscaling ? max + parseFloat((max * 0.2).toFixed(2)) : 100;
    const chartId = `chart-${parentId}`;
    const state = useLocalStore(() => ({
        svg: null,
        xScale: null,
        yScale: null,
        xaxis: null,
        yaxis: null,
        selectedTime: "",
        selectedValue: null,
        isChartCreated: false,
        timeoutId: null,
        setSelectedTime(time) {
            this.selectedTime = time;
        },
        setSelectedValue(value) {
            this.selectedValue = value;
        },
    }));
    const tickValuesForAxis = chartInfo
        .map((d, i) => {
            if (i % 2 === 0 || i === chartInfo.length - 1) return d.date;
            return null;
        })
        .filter((d) => d);

    useEffect(() => {
        state.isChartCreated ? updateChart() : createChart();
    }, [chartInfo]);

    function mouseover() {
        if (chartInfo.length === 0) return;

        const selected = d3.select(this).data()[0];

        state.setSelectedTime(selected.date);
        state.setSelectedValue(selected.value);

        stopFetching && stopFetching(true);
        clearTimeout(state.timeoutId);
    }

    function mouseout() {
        state.setSelectedTime("");
        state.setSelectedValue(null);
        if (startFetching) {
            // setTimeout is needed in order to avoid unnecessary fetching when switching between columns
            state.timeoutId = setTimeout(() => startFetching(true), 500);
        }
    }

    function createChart() {
        state.isChartCreated = true;
        const faux = connectFauxDOM("g", chartId);
        const wrapper = d3.select(`#${parentId}`);

        wrapper.select("svg").attr("width", parenfRef.current.offsetWidth);
        state.svg = d3.select(faux);
        state.xScale = d3
            .scaleBand()
            .domain(chartInfo.map((d) => d.date))
            .rangeRound([marginLeft, parenfRef.current.offsetWidth - marginRight])
            .padding(0.1);
        state.yScale = d3
            .scaleLinear()
            .domain([0, maxYbound])
            .range([height - marginBottom, marginTop]);
        state.xaxis = d3
            .axisTop()
            .scale(state.xScale)
            .tickSize(height - marginTop - marginBottom)
            .tickValues(tickValuesForAxis)
            .tickFormat((d, i) => {
                let r = "";
                if (i % 2 === 0 || i === chartInfo.length - 1) r = d3.timeFormat(shortDateRegExp)(d);
                return r;
            });
        state.yaxis = d3
            .axisLeft()
            .scale(state.yScale)
            .tickSize(parenfRef.current.offsetWidth - marginLeft - marginRight);
        graphAutoscaling ? state.yaxis.ticks(TICK_Y_COUNT) : state.yaxis.tickValues([0, 25, 50, 75, 100]);

        state.svg
            .append("g")
            .attr("transform", `translate(0, ${height - marginBottom})`)
            .attr("class", "x axis")
            .call(state.xaxis)
            .call((g) =>
                g.selectAll(".tick text").attr("transform", `translate(0, ${height - marginTop - marginBottom + textHeight})`)
            );
        state.svg
            .append("g")
            .attr("class", "y axis")
            .attr("transform", `translate(${parenfRef.current.offsetWidth - marginRight}, 0)`)
            .call(state.yaxis)
            .call((g) =>
                g
                    .selectAll(".tick text")
                    .attr("transform", `translate(${parenfRef.current.offsetWidth - marginRight - marginLeft + textMargin}, 0)`)
            );
        state.svg
            .append("g")
            .attr("class", "graph-wrapper")
            .selectAll("rect")
            .data(chartInfo)
            .join("rect")
            .attr("x", (d) => state.xScale(d.date))
            .attr("y", (d) => state.yScale(d.value))
            .attr("height", (d) => state.yScale(0) - state.yScale(d.value))
            .attr("width", state.xScale.bandwidth())
            .attr("class", "graph");

        state.svg.selectAll(".graph").on("mouseover", mouseover).on("mouseout", mouseout);
    }

    function updateChart() {
        const wrapper = d3.select(`#${parentId}`);

        wrapper.select("svg").attr("width", parenfRef.current.offsetWidth);
        state.svg.attr("width", parenfRef.current.offsetWidth);
        state.xScale.domain(chartInfo.map((d) => d.date));
        state.yScale.domain([0, maxYbound]);
        state.xaxis = d3
            .axisTop()
            .scale(state.xScale)
            .tickSize(height - marginTop - marginBottom)
            .tickValues(tickValuesForAxis)
            .tickFormat((d, i) => {
                let r = "";
                if (i % 2 === 0 || i === chartInfo.length - 1) r = d3.timeFormat(shortDateRegExp)(d);
                return r;
            });
        state.svg
            .select(".x.axis")
            .call(state.xaxis)
            .call((g) =>
                g.selectAll(".tick text").attr("transform", `translate(0, ${height - marginTop - marginBottom + textHeight})`)
            );
        state.svg
            .select(".y.axis")
            .call(state.yaxis)
            .call((g) =>
                g
                    .selectAll(".tick text")
                    .attr("transform", `translate(${parenfRef.current.offsetWidth - marginRight - marginLeft + textMargin}, 0)`)
            );

        state.svg
            .select(".graph-wrapper")
            .selectAll("rect")
            .data(chartInfo)
            .join("rect")
            .attr("x", (d) => state.xScale(d.date))
            .attr("y", (d) => state.yScale(d.value))
            .attr("height", (d) => state.yScale(0) - state.yScale(d.value))
            .attr("width", state.xScale.bandwidth())
            .attr("class", "graph");

        state.svg.selectAll(".graph").on("mouseover", mouseover).on("mouseout", mouseout);

        animateFauxDOM(animDuration);
    }

    return useObserver(() => {
        const val = state.selectedValue !== null ? state.selectedValue : lastValue;

        return (
            <Grid container className={classes.container}>
                <Grid container item xs={2} direction="column" justify="space-between" className={classes.info}>
                    <Grid item>
                        <Typography className={classes.name}>{t("header.storage")}</Typography>
                        <Typography className={classes.label}>{t("header.used")}</Typography>
                        <Typography className={classes.value}>{val || 0}%</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.label}>
                            {state.selectedTime
                                ? moment(state.selectedTime).format(getLanguageDateFormat(STORAGE_USAGE_GRAPH_DATE_FORMAT))
                                : moment(lastTime).format(getLanguageDateFormat(STORAGE_USAGE_GRAPH_DATE_FORMAT))}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={10} id={parentId} ref={parenfRef}>
                    <svg height={height} className={classes.svg}>
                        {props[chartId]}
                    </svg>
                </Grid>
            </Grid>
        );
    });
};

export default withFauxDOM(StorageUsageGraph);
