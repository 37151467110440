import React from "react";
import { useObserver } from "mobx-react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow as MuiTableRow,
    Grid,
    ButtonBase,
    Typography,
    DialogTitle,
} from "@material-ui/core";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import TableRow from "./components/TableRow";
import { useStyles } from "./ProcessedTasksModal.styles";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import CloseIcon from "assets/CloseIcon";
import ThinArrowRightIcon from "assets/ThinArrowRightIcon";
import { useProcessedTasksModal } from "hooks/modals";

const PROCESSED_TASKS_MODAL_ID_PREFIX = `processed_tasks_modal`;

const ProcessedTasksModal = ({ onClose, tasks, onBack, fileId, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${PROCESSED_TASKS_MODAL_ID_PREFIX}` : PROCESSED_TASKS_MODAL_ID_PREFIX;
    const classes = useStyles();
    const { t } = useTranslation();
    const processedTasksModal = useProcessedTasksModal();

    const getHeader = () => (
        <DialogTitle className={classes.dialogTitle}>
            <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container wrap={"nowrap"}>
                <Grid item className={classes.titleText}>
                    {t("slingshot_page.tabs.replications.processed_tasks_modal.title", {
                        id: fileId || 0,
                    })}
                </Grid>

                <Grid item>
                    <CardHeaderControlButton onClick={onClose}>
                        <ButtonBase id={`${idPrefix}_close`}>
                            <CloseIcon />
                        </ButtonBase>
                    </CardHeaderControlButton>
                </Grid>
            </Grid>
            <Grid container item xs={6} wrap="nowrap" spacing={2} className={classes.parentBreadcrumbs}>
                <Grid item>
                    <Typography variant="h6" className={classes.parentName}>
                        {t("slingshot_page.tabs.replications.processed_files_modal.header.nav.instances")}
                    </Typography>
                </Grid>
                <Grid item>
                    <ThinArrowRightIcon />
                </Grid>
                <Grid item>
                    <Typography variant="h6" className={classes.parentName}>
                        {t("slingshot_page.tabs.replications.processed_files_modal.header.nav.files")}
                    </Typography>
                </Grid>
                <Grid item>
                    <ThinArrowRightIcon />
                </Grid>
                <Grid item>
                    <Typography variant="h6" className={classes.tasksLabel}>
                        {t("slingshot_page.tabs.replications.processed_tasks_modal.header.nav.tasks")}
                    </Typography>
                </Grid>
            </Grid>
        </DialogTitle>
    );

    return useObserver(() => (
        <Dialog
            maxWidth="lg"
            submitBtn={[
                <Button
                    key="1"
                    variant="contained"
                    color="secondary"
                    onClick={onBack}
                    id={`${idPrefix}_replication_job_instances_modal_back_button`}
                >
                    {t("common.button.back")}
                </Button>,
                <Button
                    key="2"
                    variant="contained"
                    color="secondary"
                    onClick={onClose}
                    id={`${idPrefix}_replication_job_instances_modal_close_button`}
                >
                    {t("common.button.close")}
                </Button>,
            ]}
            header={getHeader()}
            withoutPaddings
            open={processedTasksModal.isOpen}
            onClose={onClose}
        >
            {tasks.length > 0 ? (
                <>
                    <Table stickyHeader>
                        <TableHead>
                            <MuiTableRow>
                                <TableCell>
                                    {t("slingshot_page.tabs.replications.processed_tasks_modal.table.header.id")}
                                </TableCell>
                                <TableCell>
                                    {t("slingshot_page.tabs.replications.processed_tasks_modal.table.header.status")}
                                </TableCell>
                                <TableCell>
                                    {t("slingshot_page.tabs.replications.processed_tasks_modal.table.header.progress")}
                                </TableCell>
                                <TableCell>
                                    {t("slingshot_page.tabs.replications.processed_tasks_modal.table.header.type")}
                                </TableCell>
                                <TableCell width="20%">
                                    {t("slingshot_page.tabs.replications.processed_tasks_modal.table.header.location")}
                                </TableCell>
                                <TableCell>
                                    {t("slingshot_page.tabs.replications.processed_tasks_modal.table.header.details")}
                                </TableCell>
                                <TableCell>
                                    {t("slingshot_page.tabs.replications.processed_tasks_modal.table.header.message")}
                                </TableCell>

                                <TableCell />
                            </MuiTableRow>
                        </TableHead>
                        <TableBody>
                            {tasks.map((task) => (
                                <TableRow task={task} key={task.id} />
                            ))}
                        </TableBody>
                    </Table>
                </>
            ) : (
                <EmptyCardPlaceholder message={t("slingshot_page.tabs.replications.processed_tasks_modal.placeholder")} />
            )}
        </Dialog>
    ));
};

export default ProcessedTasksModal;
