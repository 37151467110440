import React, { useEffect, useContext } from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { observer, useLocalStore } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./AliasesDrawer.styles";
import UsersIcon from "assets/UsersIcon";
import EditIcon from "assets/EditIcon";
import TrashIcon from "assets/TrashIcon";
import CreateEditAliasDialog from "../CreateEditAliasDialog";
import moment from "moment";
import { SLINGSHOT_DATE_FORMAT } from "const";
import WarningDeletionDialog from "../WarningDeletionDialog";
import { getAliasUrl } from "../Aliases/Aliases.utils";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const ALIASES_DRAWER_ID_PREFIX = `aliases_drawer`;

const drawerName = "aliasesDrawer";
const AliasesDrawer = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${ALIASES_DRAWER_ID_PREFIX}` : ALIASES_DRAWER_ID_PREFIX;
    const { t } = useTranslation();
    const classes = useStyles();

    const state = useLocalStore(() => ({
        isDeleteInProgress: false,
        isEditAliasDialogOpen: false,
        isEditAliasInProgress: false,
        isWarnningDeletionDialogIsOpen: false,
    }));

    const {
        store: { slingshotSettingsStore, uiStore },
    } = useContext(Context);

    useEffect(() => {
        if (slingshotSettingsStore.currentAliasName) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, closeDrawer);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [slingshotSettingsStore.currentAliasName]);

    const closeDrawer = () => {
        slingshotSettingsStore.setCurrentAliasName(null);
        state.isEditAliasInProgress = false;
    };

    const closeEditAliasDialog = () => {
        state.isEditAliasDialogOpen = false;
        state.isEditAliasInProgress = false;
    };

    const openEditAliasDialog = () => {
        state.isEditAliasDialogOpen = true;
    };

    const closeWarningDeletionDialog = () => {
        state.isWarnningDeletionDialogIsOpen = false;
    };

    const openWarningDeletionDialog = () => {
        state.isWarnningDeletionDialogIsOpen = true;
    };

    const onDeleteAlias = async () => {
        closeWarningDeletionDialog();
        state.isDeleteInProgress = true;
        const res = await slingshotSettingsStore.deleteAlias({ id: slingshotSettingsStore.currentAlias.id });
        state.isDeleteInProgress = false;
        res && closeDrawer();
        slingshotSettingsStore.fetchAliases();
    };

    const updateAliasProgress = (progress) => {
        state.isEditAliasInProgress = progress;
    };

    const getStatus = (status) => {
        switch (status) {
            case "IN_PROGRESS":
                return t("slingshot_page.tabs.settings.aliases.status.in_progress");
            case "FAILED":
                return t("slingshot_page.tabs.settings.aliases.status.failed");
            case "SUCCEED":
                return t("slingshot_page.tabs.settings.aliases.status.succeed");
            case "CANCELED":
                return t("slingshot_page.tabs.settings.aliases.status.canceled");
            default:
                return "";
        }
    };

    const renderDetails = () => {
        return slingshotSettingsStore.currentAlias ? (
            <Grid item container className={classes.body} direction={"column"} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("slingshot_page.tabs.settings.aliases.url")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {getAliasUrl(slingshotSettingsStore.currentAlias.decomposed_base_url)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"}>
                    <Grid item xs={6}>
                        <Typography>{t("slingshot_page.tabs.settings.aliases.created")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {moment
                                .utc(slingshotSettingsStore.currentAlias.created)
                                .format(getLanguageDateFormat(SLINGSHOT_DATE_FORMAT))}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("slingshot_page.tabs.settings.aliases.status")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {getStatus(slingshotSettingsStore.currentAlias.status)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("slingshot_page.tabs.settings.aliases.volume")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {slingshotSettingsStore.currentAlias.extvolume_uuid
                                ? slingshotSettingsStore.currentAlias.extvolume_uuid.toUpperCase()
                                : ""}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        ) : null;
    };

    const renderActions = () => {
        return slingshotSettingsStore.currentAlias ? (
            <Grid direction={"column"} container>
                {slingshotSettingsStore.currentAlias.editable && (
                    <BulkBarFlatButton
                        disabled={!slingshotSettingsStore.currentAlias.editable}
                        icon={<EditIcon />}
                        onClick={openEditAliasDialog}
                        inProgress={state.isEditAliasInProgress}
                        id={`${idPrefix}_open_edit_alias_dialog`}
                    >
                        {t("common.button.edit")}
                    </BulkBarFlatButton>
                )}
                {slingshotSettingsStore.currentAlias.removable && !slingshotSettingsStore.currentAlias.internal && (
                    <BulkBarFlatButton
                        errorColor
                        icon={<TrashIcon />}
                        inProgress={state.isDeleteInProgress}
                        onClick={openWarningDeletionDialog}
                        id={`${idPrefix}_open_deletion_dialog`}
                    >
                        {t("common.button.delete")}
                    </BulkBarFlatButton>
                )}
            </Grid>
        ) : null;
    };

    return (
        <DetailsActionsDrawer
            modals={[
                <CreateEditAliasDialog
                    key={"edit-alias"}
                    open={state.isEditAliasDialogOpen}
                    onClose={closeEditAliasDialog}
                    onCloseDrawer={closeDrawer}
                    changeProgressState={updateAliasProgress}
                    parentId={idPrefix}
                />,
                <WarningDeletionDialog
                    key={"delete-alias"}
                    open={state.isWarnningDeletionDialogIsOpen}
                    onClose={closeWarningDeletionDialog}
                    title={t("slingshot_page.tabs.settings.delete_alias.title")}
                    content={
                        slingshotSettingsStore.currentAlias?.extvolume_uuid?.length
                            ? t("slingshot_page.tabs.settings.delete_cloud_volume_alias", {
                                  aliasName: slingshotSettingsStore.currentAlias?.name || "",
                                  volumeId: slingshotSettingsStore.currentAlias?.extvolume_uuid,
                              })
                            : t("slingshot_page.tabs.settings.delete_alias", {
                                  aliasName: slingshotSettingsStore.currentAlias?.name || "",
                              })
                    }
                    submit={onDeleteAlias}
                    parentId={idPrefix}
                />,
            ]}
            title={slingshotSettingsStore.currentAliasName}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            icon={<UsersIcon />}
            id={`${idPrefix}_drawer`}
        />
    );
};

export default observer(AliasesDrawer);
