import React from "react";

const ChevronRightIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#BFD1E1" {...props}>
            <path
                fillRule="evenodd"
                d="M8.997 7.497a1 1 0 0 0 .29.71l3.31 3.29-3.18 3.3a1 1 0 1 0 1.42 1.41l3.86-4a1 1 0 0 0 0-1.4l-4-4a1 1 0 0 0-1.42 0 1 1 0 0 0-.28.69z"
            />
        </svg>
    );
};

export default ChevronRightIcon;
