import React from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { ButtonBase, Popover, Typography, Grid } from "@material-ui/core";
import { useStyles } from "./SupportButton.styles";
import SupportIcon from "assets/SupportIcon";
import ShieldWithKeyIcon from "assets/ShieldWithKeyIcon";
import { useTranslation } from "react-i18next";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import CloseIcon from "assets/CloseIcon";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import { useStore } from "hooks/useStore";
import FileUpload from "components/FileUpload/FileUpload";

const SupportButton = ({ id, isLoginPage }) => {
    const classes = useStyles({ isLoginPage });
    const { t } = useTranslation();

    const {
        store: { bootProgressStore },
    } = useStore();

    const state = useLocalStore(() => ({
        anchorEl: null,
        currentFile: null,
        isSubmitted: false,
        inProgress: false,
        fileValidationInfo: false,
        setFileValidationInfo(fileValidationInfo) {
            state.fileValidationInfo = fileValidationInfo;
        },
    }));

    const handleClick = (e) => {
        state.anchorEl = e.currentTarget;
        e.stopPropagation();
    };

    const handleClose = () => {
        state.anchorEl = null;
    };

    const onFilesSelect = (file) => {
        state.currentFile = file;
    };

    const onSubmit = async () => {
        state.inProgress = true;
        const res = await bootProgressStore.uploadFile(state.currentFile);
        state.inProgress = false;
        if (res) {
            state.isSubmitted = true;
            setTimeout(handleClose, 1000);
        }
        state.currentFile = null;
    };
    const onEnter = () => {
        bootProgressStore.getSshAccess();
        state.currentFile = null;
        state.isSubmitted = false;
        state.inProgress = false;
    };

    return useObserver(() => (
        <>
            <ButtonBase className={classes.button} onClick={handleClick} id={id}>
                <SupportIcon />
            </ButtonBase>
            <Popover
                onEnter={onEnter}
                open={!!state.anchorEl}
                anchorEl={state.anchorEl}
                onClose={handleClose}
                className={classes.popover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                PaperProps={{ className: classes.paper }}
                disableBackdropClick
            >
                <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container wrap={"nowrap"}>
                    <Grid item className={classes.title}>
                        {t("boot_load.support.title")}
                    </Grid>
                    <Grid item>
                        <CardHeaderControlButton onClick={handleClose}>
                            <ButtonBase id={`${id}_popover_close`}>
                                <CloseIcon />
                            </ButtonBase>
                        </CardHeaderControlButton>
                    </Grid>
                </Grid>
                {bootProgressStore.sshStatus && (
                    <Typography className={classes.statusText}>{bootProgressStore.sshStatus}</Typography>
                )}

                <FileUpload
                    id={`${id}_popover_drag_and_drop_file`}
                    file={state.currentFile}
                    handleFile={onFilesSelect}
                    icon={state.currentFile ? ShieldWithKeyIcon : undefined}
                    loading={state.inProgress}
                    regExpExtension={/\.gpg/}
                    inputProps=".gpg"
                    onValid={state.setFileValidationInfo}
                />

                <Grid container justify={"flex-end"} className={classes.buttonContainer}>
                    <AnimatedSubmitButton
                        label={t("common.button.upload")}
                        disabled={!state.currentFile || !state.fileValidationInfo.isValid}
                        isSubmitted={state.isSubmitted}
                        inProgress={state.inProgress}
                        submit={onSubmit}
                        id={`${id}_popover_upload`}
                    />
                </Grid>
            </Popover>
        </>
    ));
};

export default SupportButton;
