import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
import Select from "components/MobxForm/Select";
import TextInput from "components/MobxForm/TextInput";
import MuiTextInput from "components/TextInput";
import TableRowControlButton from "components/TableRowControlButton";
import { useStyles } from "./CreateEditJobForm.styles";
import Switch from "components/MobxForm/Switch";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import CalendarIcon from "assets/CalendarIcon";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import ChevronUpIcon from "assets/ChevronUpIcon";
import FolderBrowser from "../FolderBrowser";
import {
    METHOD_OPTIONS,
    METHOD_OPTIONS_ARRAY,
    SOURCE_OPTIONS,
    SOURCE_OPTIONS_ARRAY,
    DESTINATION_OPTIONS,
    DESTINATION_OPTIONS_ARRAY,
    SCHEDULE_PERIOD_OPTIONS_ARRAY,
    SCHEDULE_PERIOD_OPTIONS,
    DAY_OF_WEEKS_OPTIONS_ARRAY,
    HOURS_OPTIONS,
    DATE_PERIODS_OPTIONS_ARRAY,
    INCLUDE_EXCLUDE_PRIORITY_OPTIONS_ARRAY,
    REPORT_VARIANTS_OPTIONS_ARRAY,
    LOG_LEVEL_OPTIONS_ARRAY,
    TARGET_FOR_FOLDER_BROWSER,
    SOURCE_OPTION_EXCLUDED_SHARE,
    DESTINATION_OPTION_EXCLUDED_SHARE,
} from "const/createJobConst";
import { ADMIN } from "const/userRolesConst";
import { Link } from "react-router-dom";
import { URL_ALERTS } from "routes";
import { useClearFormFields } from "hooks/useClearFormFields";
import localizations from "./CreateEditJobForm.localizations";
import LocalizationTypography from "components/LocalizationTypography/LocalizationTypography";
import { SLINGSHOT_DATE_HOURS_FORMAT, SLINGSHOT_DATE_FORMAT } from "const";
import { autorun } from "mobx";
import { useStore } from "hooks/useStore";
import Checkbox from "components/Checkbox";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const CREATE_EDIT_JOB_FORM_ID_PREFIX = `create_edit_job_form`;

const CreateEditJobForm = ({
    form,
    disabled,
    createNew,
    hideParentModal,
    showParentModal,
    localShares,
    extShares,
    isFormReady,
    excludedSourceShare,
    excludedDestinationShare,
    isMediaProxyEnable,
    parentId,
}) => {
    const idPrefix = parentId ? `${parentId}_${CREATE_EDIT_JOB_FORM_ID_PREFIX}` : CREATE_EDIT_JOB_FORM_ID_PREFIX;
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: {
            indexingConfigStore,
            syncJobsStore,
            slingshotStorageStore,
            slingshotSettingsStore,
            authStore,
            emailConfigurationStore,
        },
    } = useStore();

    useEffect(() => {
        slingshotSettingsStore.fetchAliases();
        authStore.role === ADMIN && slingshotStorageStore.fetchLogicalDisks();
        if (!indexingConfigStore.isConfigLoading && !indexingConfigStore.isConfigLoaded) {
            indexingConfigStore.fetchConfig();
            slingshotStorageStore.fetchShares();
        }
        state.sourceOptions =
            authStore.role === ADMIN
                ? SOURCE_OPTIONS_ARRAY
                : SOURCE_OPTIONS_ARRAY.filter((option) =>
                      [SOURCE_OPTIONS.logicalDisk, SOURCE_OPTIONS.smb].every((sourceOption) => option.value !== sourceOption)
                  );

        state.destinationOptions =
            authStore.role === ADMIN
                ? DESTINATION_OPTIONS_ARRAY
                : DESTINATION_OPTIONS_ARRAY.filter((option) =>
                      [DESTINATION_OPTIONS.smb].every((destinationOption) => option.value !== destinationOption)
                  );
    }, []);
    useEffect(() => {
        if (excludedSourceShare) {
            state.sourceOptions = [SOURCE_OPTION_EXCLUDED_SHARE, ...state.sourceOptions];
        }
    }, [excludedSourceShare]);
    useEffect(() => {
        if (excludedDestinationShare) {
            state.destinationOptions = [DESTINATION_OPTION_EXCLUDED_SHARE, ...state.destinationOptions];
        }
    }, [excludedDestinationShare]);

    const state = useLocalStore(() => ({
        sourceOptions: SOURCE_OPTIONS_ARRAY,
        destinationOptions: DESTINATION_OPTIONS_ARRAY,
        isBeginPickerOpen: false,
        isEndPickerOpen: false,
        isStartAtDateTimePickerOpen: false,
        isEndAtDateTimePickerOpen: false,
        isFolderBrowserOpen: false,
        shareForFolderBrowser: "",
        targetForFolderBrowser: TARGET_FOR_FOLDER_BROWSER.source,
        advancedExpanded: false,
        path: "",
        get sourceAliases() {
            return slingshotSettingsStore.aliases
                .filter((alias) => alias.decomposed_base_url?.schema === form.$("source").value)
                .map((alias) => alias.name);
        },
        get destinationAliases() {
            return slingshotSettingsStore.aliases
                .filter((alias) => alias.decomposed_base_url?.schema === form.$("destination").value)
                .map((alias) => alias.name);
        },
        get ldNames() {
            return slingshotStorageStore.logicalDisks.map((ld) => ld.fullName);
        },
    }));

    const openBeginPicker = () => {
        state.isBeginPickerOpen = true;
    };
    const closeBeginPicker = () => {
        state.isBeginPickerOpen = false;
    };
    const openEndPicker = () => {
        state.isEndPickerOpen = true;
    };
    const closeEndPicker = () => {
        state.isEndPickerOpen = false;
    };
    const openStartAtDateTimePickerOpen = () => {
        state.isStartAtDateTimePickerOpen = true;
    };
    const closeStartAtDateTimePickerOpen = () => {
        state.isStartAtDateTimePickerOpen = false;
    };
    const openEndAtDateTimePickerOpen = () => {
        state.isEndAtDateTimePickerOpen = true;
    };
    const closeEndAtDateTimePickerOpen = () => {
        state.isEndAtDateTimePickerOpen = false;
    };
    const openSourceFolderBrowser = (share) => () => {
        state.shareForFolderBrowser = share;
        state.targetForFolderBrowser = TARGET_FOR_FOLDER_BROWSER.source;
        hideParentModal();
        state.isFolderBrowserOpen = true;
        state.path = form.$("sourcePath").value;
    };
    const openDestinationFolderBrowser = (share) => () => {
        state.shareForFolderBrowser = share;
        state.targetForFolderBrowser = TARGET_FOR_FOLDER_BROWSER.destination;
        hideParentModal();
        state.isFolderBrowserOpen = true;
        state.path = form.$("destinationPath").value;
    };
    const closeFolderBrowser = () => {
        state.isFolderBrowserOpen = false;
        showParentModal();
        state.shareForFolderBrowser = "";
    };

    const onFolderChoose = (path) => {
        form.$(state.targetForFolderBrowser).set("value", path);
    };

    const onSourceShareChange = (e) => {
        form.$("sourceShare").set("value", e.target.value);

        form.$("sourceAlias").clear();
        form.$("sourceAlias").resetValidation();

        form.$("sourcePath").clear();
        state.path = "";
    };

    const onDestinationShareChange = (e) => {
        form.$("destinationShare").set("value", e.target.value);
        form.$("destinationPath").clear();
        state.path = "";
    };

    const onChangeSource = (e) => {
        form.$("source").set("value", e.target.value);
        state.sourceOptions = state.sourceOptions.filter((option) => option.value !== SOURCE_OPTIONS.excludedShare);
        form.$("sourceShare").clear();
        form.$("sourceLogicalDisk").clear();
        form.$("sourceAlias").clear();
        form.$("sourcePath").clear();
    };

    const onChangeSourceAlias = (e) => {
        form.$("sourceAlias").set("value", e.target.value);

        form.$("sourceShare").clear();
        form.$("sourceShare").resetValidation();

        form.$("sourcePath").clear();
        state.path = "";
    };

    const onChangeDestination = (e) => {
        form.$("destination").set("value", e.target.value);
        state.destinationOptions = state.destinationOptions.filter(
            (option) => option.value !== DESTINATION_OPTIONS.excludedShare
        );
        form.$("destinationShare").clear();
        form.$("destinationAlias").clear();
        form.$("destinationPath").clear();
    };

    const onChangeSharaBrowserMetadata = (e) => {
        form.$("includeMetadata").set("value", e.target.checked);
        form.$("includeMediaProxy").clear();
    };

    const onChangeIncludePatternCaseInsensitive = (e) => {
        form.$("enableIncludePatternCaseInsensitive").set("value", e.target.checked);
    };

    const onChangeEnableIncludePattern = (e) => {
        form.$("enableIncludePattern").set("value", e.target.checked);
    };

    useEffect(() => {
        autorun(() => {
            form.$("skipNewerFiles").set("default", syncJobsStore.currentEditableJob?.skip_newer);
            form.$("skipNewerFiles").set("value", syncJobsStore.currentEditableJob?.skip_newer);
        });
    }, []);

    useClearFormFields(
        {
            skipNewerFiles: form.$("method").value !== METHOD_OPTIONS.sync,
            sourceShare: form.$("source").value !== SOURCE_OPTIONS.share && form.$("source").value !== SOURCE_OPTIONS.smb,
            sourceLogicalDisk: form.$("source").value !== SOURCE_OPTIONS.logicalDisk,
            sourceAlias:
                form.$("source").value === SOURCE_OPTIONS.share &&
                form.$("source").value === SOURCE_OPTIONS.logicalDisk &&
                form.$("source").value === SOURCE_OPTIONS.smb,
            destinationShare:
                form.$("destination").value !== DESTINATION_OPTIONS.share &&
                form.$("destination").value !== DESTINATION_OPTIONS.smb,
            destinationAlias:
                form.$("destination").value === DESTINATION_OPTIONS.share &&
                form.$("destination").value === DESTINATION_OPTIONS.smb,
            removeDeletedFiles: form.$("method").value !== METHOD_OPTIONS.sync,
        },
        form,
        isFormReady || false
    );

    const onChangePeriod = (e) => {
        form.$("schedulePeriod").value = e.target.value;
        if (form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.manual) {
            form.$("enableEndAt").clear();
            form.$("enableBegin").set("value", false);
            form.$("enableEnd").set("value", false);
        }
    };

    const onChangeMethod = (e) => {
        form.$("method").value = e.target.value;
        if (e.target.value === METHOD_OPTIONS.sync) {
            form.$("removeDeletedFiles").set("value", true);
            return;
        }
        form.$("removeDeletedFiles").set("value", false);
    };

    return useObserver(() => (
        <Grid container direction="column" wrap="nowrap">
            <Box pb={6} pt={4}>
                <Grid container item direction="column" wrap="nowrap" spacing={4}>
                    <Grid item container wrap="nowrap" alignItems="center">
                        <Grid item xs={4}>
                            <Typography>{t("slingshot_page.tabs.replications.create_job.label.job_name")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextInput autoFocus field={form.$("jobName")} disabled={disabled} id={`${idPrefix}_job_name`} />
                        </Grid>
                    </Grid>
                    <Grid item container wrap="nowrap" alignItems="center">
                        <Grid item xs={4}>
                            <Typography>{t("slingshot_page.tabs.replications.create_job.label.method")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                field={form.$("method")}
                                options={METHOD_OPTIONS_ARRAY}
                                disabled={disabled}
                                onChange={onChangeMethod}
                                id={`${idPrefix}_method`}
                            />
                        </Grid>
                    </Grid>
                    {form.$("method").value === METHOD_OPTIONS.sync && (
                        <Grid item container wrap="nowrap" alignItems="center">
                            <Grid item xs={4} />
                            <Grid item xs={8}>
                                <Box py={2}>
                                    <Typography>
                                        {t("slingshot_page.tabs.replications.create_job.helper_text.part_one")}
                                        <Typography component={"span"} className={classes.italic}>
                                            {t("slingshot_page.tabs.replications.create_job.helper_text.part_two")}
                                        </Typography>
                                        {t("slingshot_page.tabs.replications.create_job.helper_text.part_three")}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <Box pb={6}>
                <Grid className={classes.inputsSection} container item direction="column" wrap="nowrap" spacing={4}>
                    <Grid item container wrap="nowrap" alignItems="center">
                        <Grid item xs={4}>
                            <Typography>{t("slingshot_page.tabs.replications.create_job.label.source")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                onChange={onChangeSource}
                                field={form.$("source")}
                                options={state.sourceOptions}
                                disabled={disabled}
                                id={`${idPrefix}_source`}
                            />
                        </Grid>
                    </Grid>
                    {(form.$("source").value === SOURCE_OPTIONS.share || form.$("source").value === SOURCE_OPTIONS.smb) && (
                        <Grid item container wrap="nowrap" alignItems="center">
                            <Grid item xs={4}>
                                <Typography>{t("slingshot_page.tabs.replications.create_job.label.share")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    onChange={onSourceShareChange}
                                    field={form.$("sourceShare")}
                                    disabled={disabled}
                                    options={form.$("source").value === SOURCE_OPTIONS.share ? localShares : extShares}
                                    id={`${idPrefix}_share`}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {form.$("source").value === SOURCE_OPTIONS.excludedShare && (
                        <Grid item container wrap="nowrap" alignItems="center">
                            <Grid item xs={4}>
                                <Typography>{t("slingshot_page.tabs.replications.create_job.label.share")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    field={form.$("placeholderSourceShare")}
                                    disabled={true}
                                    options={[excludedSourceShare]}
                                    id={`${idPrefix}_source_share`}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {form.$("source").value === SOURCE_OPTIONS.logicalDisk && (
                        <Grid item container wrap="nowrap" alignItems="center">
                            <Grid item xs={4}>
                                <Typography>{t("slingshot_page.tabs.replications.create_job.label.logical_disk")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    field={form.$("sourceLogicalDisk")}
                                    options={state.ldNames}
                                    disabled={disabled}
                                    id={`${idPrefix}_logical_disk`}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {form.$("source").value !== SOURCE_OPTIONS.share &&
                        form.$("source").value !== SOURCE_OPTIONS.logicalDisk &&
                        form.$("source").value !== SOURCE_OPTIONS.smb &&
                        form.$("source").value !== SOURCE_OPTIONS.excludedShare && (
                            <Grid item container wrap="nowrap" alignItems="center">
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.replications.create_job.label.alias")}</Typography>
                                </Grid>
                                <Grid item container xs={8} wrap="nowrap">
                                    <Grid item container>
                                        <Select
                                            field={form.$("sourceAlias")}
                                            onChange={onChangeSourceAlias}
                                            options={state.sourceAliases}
                                            disabled={disabled}
                                            id={`${idPrefix}_source_alias`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    <Grid item container wrap="nowrap" alignItems="center">
                        <Grid item xs={4}>
                            <Typography>{t("slingshot_page.tabs.replications.create_job.label.path")}</Typography>
                        </Grid>
                        <Grid item container xs={8} wrap="nowrap">
                            <Grid item container>
                                <TextInput
                                    field={form.$("sourcePath")}
                                    disabled={form.$("source").value === SOURCE_OPTIONS.logicalDisk || disabled}
                                    id={`${idPrefix}_source_path`}
                                />
                            </Grid>
                            <Grid item>
                                <Box pl={4}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            (form.$("source").value !== SOURCE_OPTIONS.share &&
                                                form.$("source").value !== SOURCE_OPTIONS.smb) ||
                                            form.$("sourceShare").value === "" ||
                                            disabled
                                        }
                                        onClick={openSourceFolderBrowser(form.$("sourceShare").value)}
                                        id={`${idPrefix}_open_source_folder_browser`}
                                    >
                                        {t("common.button.browse")}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Box pb={6}>
                <Grid className={classes.inputsSection} container item direction="column" wrap="nowrap" spacing={4}>
                    <Grid item container wrap="nowrap" alignItems="center">
                        <Grid item xs={4}>
                            <Typography>{t("slingshot_page.tabs.replications.create_job.label.destination")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                onChange={onChangeDestination}
                                field={form.$("destination")}
                                options={state.destinationOptions}
                                disabled={disabled}
                                id={`${idPrefix}_destination`}
                            />
                        </Grid>
                    </Grid>
                    {(form.$("destination").value === DESTINATION_OPTIONS.share ||
                        form.$("destination").value === DESTINATION_OPTIONS.smb) && (
                        <Grid item container wrap="nowrap" alignItems="center">
                            <Grid item xs={4}>
                                <Typography>{t("slingshot_page.tabs.replications.create_job.label.share")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    onChange={onDestinationShareChange}
                                    field={form.$("destinationShare")}
                                    options={form.$("destination").value === DESTINATION_OPTIONS.share ? localShares : extShares}
                                    disabled={disabled}
                                    id={`${idPrefix}_destination_share`}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {form.$("destination").value === DESTINATION_OPTIONS.excludedShare && (
                        <Grid item container wrap="nowrap" alignItems="center">
                            <Grid item xs={4}>
                                <Typography>{t("slingshot_page.tabs.replications.create_job.label.share")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    field={form.$("placeholderDestinationShare")}
                                    disabled={true}
                                    options={[excludedDestinationShare]}
                                    id={`${idPrefix}_placeholder_destination_share`}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {form.$("destination").value !== DESTINATION_OPTIONS.share &&
                        form.$("destination").value !== DESTINATION_OPTIONS.smb &&
                        form.$("destination").value !== DESTINATION_OPTIONS.excludedShare && (
                            <Grid item container wrap="nowrap" alignItems="center">
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.replications.create_job.label.alias")}</Typography>
                                </Grid>
                                <Grid item container xs={8} wrap="nowrap">
                                    <Grid item container>
                                        <Select
                                            field={form.$("destinationAlias")}
                                            options={state.destinationAliases}
                                            disabled={disabled}
                                            id={`${idPrefix}_destinationALias`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    <Grid item container wrap="nowrap" alignItems="center">
                        <Grid item xs={4}>
                            <Typography>{t("slingshot_page.tabs.replications.create_job.label.path")}</Typography>
                        </Grid>
                        <Grid item container xs={8} wrap="nowrap">
                            <Grid item container>
                                <TextInput
                                    field={form.$("destinationPath")}
                                    disabled={disabled}
                                    id={`${idPrefix}_destination_path`}
                                />
                            </Grid>
                            <Grid item>
                                <Box pl={4}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            (form.$("destination").value !== DESTINATION_OPTIONS.share &&
                                                form.$("destination").value !== DESTINATION_OPTIONS.smb) ||
                                            form.$("destinationShare").value === "" ||
                                            disabled
                                        }
                                        onClick={openDestinationFolderBrowser(form.$("destinationShare").value)}
                                        id={`${idPrefix}_open_destination_folder_browser`}
                                    >
                                        {t("common.button.browse")}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Box pb={10}>
                <Grid container alignItems="baseline" wrap="nowrap">
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.replications.create_job.label.scheduling_controls")}</Typography>
                    </Grid>
                    <Grid item xs={8} container direction="column" wrap="nowrap">
                        <Box pb={6}>
                            <Grid container wrap="nowrap" alignItems="center" spacing={2}>
                                <Grid xs={4} item container alignItems="center">
                                    <Typography>Frequency</Typography>
                                </Grid>

                                <Grid item container xs={6}>
                                    <Select
                                        field={form.$("schedulePeriod")}
                                        options={SCHEDULE_PERIOD_OPTIONS_ARRAY}
                                        disabled={disabled}
                                        onChange={onChangePeriod}
                                        id={`${idPrefix}_sheduke_period`}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box pb={5}>
                            <Grid container wrap="nowrap">
                                <FormControlLabel
                                    className={classes.switchLabel}
                                    labelPlacement="end"
                                    label={t("slingshot_page.tabs.replications.create_job.label.begin")}
                                    control={
                                        <Switch
                                            disabled={
                                                form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.manual ||
                                                form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.once ||
                                                disabled
                                            }
                                            field={form.$("enableBegin")}
                                            id={`${idPrefix}_enable_begin`}
                                        />
                                    }
                                />
                                <Grid container justify="flex-end">
                                    <DatePicker
                                        className={classes.picker}
                                        disabled={
                                            !form.$("enableBegin").value ||
                                            form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.manual ||
                                            form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.once ||
                                            disabled
                                        }
                                        open={state.isBeginPickerOpen}
                                        onOpen={openBeginPicker}
                                        onClose={closeBeginPicker}
                                        value={form.$("beginDate").value}
                                        onChange={form.$("beginDate").onChange}
                                        TextFieldComponent={MuiTextInput}
                                        inputVariant="outlined"
                                        autoOk
                                        format={getLanguageDateFormat(SLINGSHOT_DATE_FORMAT)}
                                        variant="dialog"
                                        DialogProps={{
                                            className: classes.datePickerDialogStyles,
                                        }}
                                        maxDate={form.$("enableEnd").value ? form.$("endDate").value : undefined}
                                        id={`${idPrefix}_begin_date`}
                                    />
                                </Grid>

                                <Grid item>
                                    <Box height="100%" display="flex" alignItems="center" pl={2}>
                                        <TableRowControlButton
                                            className={classes.editButton}
                                            disabled={
                                                !form.$("enableBegin").value ||
                                                form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.manual ||
                                                form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.once ||
                                                disabled
                                            }
                                            icon={<CalendarIcon />}
                                            onClick={openBeginPicker}
                                            id={`${idPrefix}_open_begin_picker`}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box pb={5}>
                            <Grid container wrap="nowrap">
                                <FormControlLabel
                                    className={classes.switchLabel}
                                    labelPlacement="end"
                                    label={t("slingshot_page.tabs.replications.create_job.label.end")}
                                    control={
                                        <Switch
                                            disabled={
                                                form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.manual ||
                                                form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.once ||
                                                disabled
                                            }
                                            field={form.$("enableEnd")}
                                            id={`${idPrefix}_enable_end`}
                                        />
                                    }
                                />
                                <Grid container justify="flex-end">
                                    <DatePicker
                                        className={classes.picker}
                                        disabled={
                                            !form.$("enableEnd").value ||
                                            form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.manual ||
                                            form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.once ||
                                            disabled
                                        }
                                        open={state.isEndPickerOpen}
                                        onOpen={openEndPicker}
                                        onClose={closeEndPicker}
                                        value={form.$("endDate").value}
                                        onChange={form.$("endDate").onChange}
                                        TextFieldComponent={MuiTextInput}
                                        inputVariant="outlined"
                                        autoOk
                                        format={getLanguageDateFormat(SLINGSHOT_DATE_FORMAT)}
                                        variant="dialog"
                                        DialogProps={{
                                            className: classes.datePickerDialogStyles,
                                        }}
                                        minDate={form.$("enableBegin").value && form.$("beginDate").value}
                                        id={`${idPrefix}_end_date`}
                                    />
                                </Grid>

                                <Grid item>
                                    <Box height="100%" display="flex" alignItems="center" pl={2}>
                                        <TableRowControlButton
                                            className={classes.editButton}
                                            disabled={
                                                !form.$("enableEnd").value ||
                                                form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.manual ||
                                                form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.once ||
                                                disabled
                                            }
                                            icon={<CalendarIcon />}
                                            onClick={openEndPicker}
                                            id={`${idPrefix}_open_begin_picker`}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box pb={5} pl={14}>
                            <Grid container wrap="nowrap" alignItems="center">
                                <Grid item>
                                    <Typography noWrap>
                                        {t("slingshot_page.tabs.replications.create_job.label.start_at")}
                                    </Typography>
                                </Grid>
                                {form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.once ? (
                                    <>
                                        <Grid container justify="flex-end">
                                            <DateTimePicker
                                                maxDate={form.$("enableEndAt").value ? form.$("endAtDateTime").value : undefined}
                                                className={classes.picker}
                                                open={state.isStartAtDateTimePickerOpen}
                                                onOpen={openStartAtDateTimePickerOpen}
                                                onClose={closeStartAtDateTimePickerOpen}
                                                value={form.$("startAtDateTime").value}
                                                onChange={form.$("startAtDateTime").onChange}
                                                TextFieldComponent={MuiTextInput}
                                                inputVariant="outlined"
                                                format={getLanguageDateFormat(SLINGSHOT_DATE_HOURS_FORMAT)}
                                                variant="dialog"
                                                minutesStep={5}
                                                DialogProps={{
                                                    className: classes.dateTimePickerDialogStyles,
                                                }}
                                                disabled={disabled}
                                                error={form.$("startAtDateTime").error}
                                                id={`${idPrefix}_start_at_date_time`}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Box height="100%" display="flex" alignItems="center" pl={2}>
                                                <TableRowControlButton
                                                    className={classes.editButton}
                                                    icon={<CalendarIcon />}
                                                    onClick={openStartAtDateTimePickerOpen}
                                                    disabled={disabled}
                                                    id={`${idPrefix}_open_start_at_date_time`}
                                                />
                                            </Box>
                                        </Grid>
                                    </>
                                ) : (
                                    <Box pl={4} width="100%">
                                        <Grid container wrap="nowrap">
                                            <Grid item container>
                                                <Select
                                                    disabled={
                                                        form.$("schedulePeriod").value !== SCHEDULE_PERIOD_OPTIONS.weekly ||
                                                        disabled
                                                    }
                                                    field={form.$("startAtDayOfWeek")}
                                                    options={DAY_OF_WEEKS_OPTIONS_ARRAY}
                                                    id={`${idPrefix}_start_at_day_of_week`}
                                                />
                                            </Grid>
                                            <Grid item container>
                                                <Box pl={4} width="100%">
                                                    <Select
                                                        disabled={
                                                            (form.$("schedulePeriod").value !== SCHEDULE_PERIOD_OPTIONS.weekly &&
                                                                form.$("schedulePeriod").value !==
                                                                    SCHEDULE_PERIOD_OPTIONS.daily &&
                                                                form.$("schedulePeriod").value ===
                                                                    SCHEDULE_PERIOD_OPTIONS.hourly &&
                                                                !form.$("enableEndAt").value) ||
                                                            disabled
                                                        }
                                                        field={form.$("startAtTime")}
                                                        options={HOURS_OPTIONS}
                                                        id={`${idPrefix}_start_at_time`}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                            </Grid>
                        </Box>
                        <Box pb={10}>
                            <Grid container wrap="nowrap" alignItems="center">
                                <FormControlLabel
                                    className={classes.switchLabel}
                                    labelPlacement="end"
                                    label={t("slingshot_page.tabs.replications.create_job.label.end_at")}
                                    control={
                                        <Switch
                                            disabled={
                                                form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.manual || disabled
                                            }
                                            field={form.$("enableEndAt")}
                                            id={`${idPrefix}_enable_end_at`}
                                        />
                                    }
                                />
                                {form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.once ? (
                                    <>
                                        <Grid container justify="flex-end">
                                            <DateTimePicker
                                                minDate={form.$("startAtDateTime").value}
                                                disabled={!form.$("enableEndAt").value || disabled}
                                                className={classes.picker}
                                                open={state.isEndAtDateTimePickerOpen}
                                                onOpen={openEndAtDateTimePickerOpen}
                                                onClose={closeEndAtDateTimePickerOpen}
                                                value={form.$("endAtDateTime").value}
                                                onChange={form.$("endAtDateTime").onChange}
                                                TextFieldComponent={MuiTextInput}
                                                inputVariant="outlined"
                                                format={getLanguageDateFormat(SLINGSHOT_DATE_HOURS_FORMAT)}
                                                variant="dialog"
                                                minutesStep={5}
                                                DialogProps={{
                                                    className: classes.dateTimePickerDialogStyles,
                                                }}
                                                id={`${idPrefix}_end_at_date_time`}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Box height="100%" display="flex" alignItems="center" pl={2}>
                                                <TableRowControlButton
                                                    disabled={!form.$("enableEndAt").value || disabled}
                                                    className={classes.editButton}
                                                    icon={<CalendarIcon />}
                                                    onClick={openEndAtDateTimePickerOpen}
                                                    id={`${idPrefix}_open_end_at_date_time`}
                                                />
                                            </Box>
                                        </Grid>
                                    </>
                                ) : (
                                    <Box pl={4} width="100%">
                                        <Grid container wrap="nowrap">
                                            <Grid item container>
                                                <Select
                                                    disabled={
                                                        form.$("schedulePeriod").value !== SCHEDULE_PERIOD_OPTIONS.weekly ||
                                                        !form.$("enableEndAt").value ||
                                                        disabled
                                                    }
                                                    field={form.$("endAtDayOfWeek")}
                                                    options={DAY_OF_WEEKS_OPTIONS_ARRAY}
                                                    id={`${idPrefix}_end_at_day_of_week`}
                                                />
                                            </Grid>
                                            <Grid item container>
                                                <Box pl={4} width="100%">
                                                    <Select
                                                        disabled={
                                                            (form.$("schedulePeriod").value !== SCHEDULE_PERIOD_OPTIONS.weekly &&
                                                                form.$("schedulePeriod").value !==
                                                                    SCHEDULE_PERIOD_OPTIONS.daily &&
                                                                form.$("schedulePeriod").value !==
                                                                    SCHEDULE_PERIOD_OPTIONS.hourly) ||
                                                            !form.$("enableEndAt").value ||
                                                            disabled
                                                        }
                                                        field={form.$("endAtTime")}
                                                        options={HOURS_OPTIONS}
                                                        id={`${idPrefix}_end_at_time`}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                            </Grid>
                        </Box>
                        <Box pb={2}>
                            <Grid item>
                                {emailConfigurationStore.emailSettings?.emailsTo &&
                                emailConfigurationStore.emailSettings?.emailsTo[0] !== "" ? (
                                    authStore.role === ADMIN && (
                                        <Typography>
                                            <Trans
                                                i18nKey={
                                                    "slingshot_page.tabs.replications.create_edit_job.recipient_list.enabled.note"
                                                }
                                                components={
                                                    <Link
                                                        className={classes.link}
                                                        to={URL_ALERTS}
                                                        id={`${idPrefix}_alerts_link`}
                                                    />
                                                }
                                            />
                                        </Typography>
                                    )
                                ) : authStore.role === ADMIN ? (
                                    <Typography>
                                        <Trans
                                            i18nKey={
                                                "slingshot_page.tabs.replications.create_edit_job.recipient_list.disabled.note.admin"
                                            }
                                            components={
                                                <Link className={classes.link} to={URL_ALERTS} id={`${idPrefix}_alerts_link`} />
                                            }
                                        />
                                    </Typography>
                                ) : (
                                    <Typography>
                                        {t("slingshot_page.tabs.replications.create_edit_job.recipient_list.disabled.note")}
                                    </Typography>
                                )}
                            </Grid>
                        </Box>
                        <Box pb={4}>
                            <FormControlLabel
                                className={classes.switchLabel}
                                labelPlacement="end"
                                label={t("slingshot_page.tabs.replications.create_job.label.email_job_summary")}
                                control={
                                    <Switch
                                        disabled={
                                            disabled ||
                                            (emailConfigurationStore.emailSettings?.emailsTo &&
                                                emailConfigurationStore.emailSettings?.emailsTo[0] === "")
                                        }
                                        field={form.$("emailJobSummary")}
                                        id={`${idPrefix}_email_job_summary`}
                                    />
                                }
                            />
                        </Box>
                        <Box pb={4}>
                            <FormControlLabel
                                className={classes.switchLabel}
                                labelPlacement="end"
                                label={t("slingshot_page.tabs.replications.create_job.label.email_on_job_start")}
                                control={
                                    <Switch
                                        disabled={
                                            disabled ||
                                            (emailConfigurationStore.emailSettings?.emailsTo &&
                                                emailConfigurationStore.emailSettings?.emailsTo[0] === "")
                                        }
                                        field={form.$("emailOnJobStart")}
                                        id={`${idPrefix}_email_on_job_start`}
                                    />
                                }
                            />
                        </Box>
                        <FormControlLabel
                            className={classes.switchLabel}
                            labelPlacement="end"
                            label={t("slingshot_page.tabs.replications.create_job.label.email_on_job_pause")}
                            control={
                                <Switch
                                    disabled={
                                        form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.manual ||
                                        disabled ||
                                        (emailConfigurationStore.emailSettings?.emailsTo &&
                                            emailConfigurationStore.emailSettings?.emailsTo[0] === "")
                                    }
                                    field={form.$("emailOnJobPause")}
                                    id={`${idPrefix}_email_on_job_pause`}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box pb={6}>
                <Accordion
                    className={classes.expansionPanel}
                    square
                    expanded={state.advancedExpanded}
                    onChange={(event, newExpanded) => {
                        state.advancedExpanded = newExpanded;
                    }}
                    id={`${idPrefix}_accordion`}
                >
                    <AccordionSummary
                        className={classes.expansionPanelContent}
                        classes={{ content: classes.expansionPanelContent, expanded: classes.expansionPanelContent }}
                        id={`${idPrefix}_accordion_summary`}
                    >
                        <Typography className={classes.expansionLabel}>
                            {t("disks_and_pools.add_disk_dialog.label.advanced")}
                        </Typography>
                        <ChevronUpIcon
                            className={clsx(!state.advancedExpanded && classes.expansionIconClose, classes.expansionIcon)}
                        />
                    </AccordionSummary>
                    <AccordionDetails className={classes.detailsRoot} id={`${idPrefix}_accordion_details`}>
                        <Grid direction="column" container wrap="nowrap">
                            <Grid item container wrap="nowrap" alignItems={"flex-start"}>
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.replications.create_job.label.filtering")}</Typography>
                                </Grid>
                                <Grid item container xs={8} wrap="nowrap" direction="column">
                                    <Box pb={2}>
                                        <Grid container alignItems="center">
                                            <FormControlLabel
                                                className={classes.switchLabel}
                                                labelPlacement="end"
                                                label={t("slingshot_page.tabs.replications.create_job.label.old_files_transfer")}
                                                control={
                                                    <Switch
                                                        disabled={disabled}
                                                        field={form.$("transferFilesOlderThan")}
                                                        id={`${idPrefix}_transfer_files_older_than`}
                                                    />
                                                }
                                            />
                                            <Grid container wrap="nowrap">
                                                <Box pl={14} display="flex" flexWrap="nowrap">
                                                    <Box width="30%">
                                                        <TextInput
                                                            inputProps={{ min: "1", step: "1" }}
                                                            disabled={!form.$("transferFilesOlderThan").value || disabled}
                                                            field={form.$("transferFilesOlderThanCount")}
                                                            id={`${idPrefix}_transfer_files_older_than_count`}
                                                        />
                                                    </Box>
                                                    <Box pl={2}>
                                                        <Select
                                                            disabled={!form.$("transferFilesOlderThan").value || disabled}
                                                            field={form.$("transferFilesOlderThanType")}
                                                            options={DATE_PERIODS_OPTIONS_ARRAY}
                                                            id={`${idPrefix}_transfer_files_older_than_type`}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box pb={2}>
                                        <Grid container alignItems="center">
                                            <FormControlLabel
                                                className={classes.switchLabel}
                                                labelPlacement="end"
                                                label={t("slingshot_page.tabs.replications.create_job.label.include_files")}
                                                control={
                                                    <Switch
                                                        onChange={onChangeEnableIncludePattern}
                                                        field={form.$("enableIncludePattern")}
                                                        checked={form.$("enableIncludePattern").value}
                                                        disabled={disabled}
                                                        id={`${idPrefix}_enable_include_pattern`}
                                                    />
                                                }
                                            />
                                            <Box pl={14} width="100%">
                                                <TextInput
                                                    disabled={!form.$("enableIncludePattern").value || disabled}
                                                    field={form.$("includePattern")}
                                                    id={`${idPrefix}_include_pattern`}
                                                />
                                            </Box>
                                        </Grid>
                                    </Box>
                                    <Box pb={2}>
                                        <Grid container alignItems="center">
                                            <FormControlLabel
                                                className={classes.switchLabel}
                                                labelPlacement="end"
                                                label={t("slingshot_page.tabs.replications.create_job.label.exclude_files")}
                                                control={
                                                    <Switch
                                                        disabled={disabled}
                                                        field={form.$("enableExcludePattern")}
                                                        id={`${idPrefix}_enable_exclude_pattern`}
                                                    />
                                                }
                                            />
                                            <Box pl={14} width="100%">
                                                <TextInput
                                                    disabled={!form.$("enableExcludePattern").value || disabled}
                                                    field={form.$("excludePattern")}
                                                    id={`${idPrefix}_exclude_pattern`}
                                                />
                                            </Box>
                                        </Grid>
                                    </Box>
                                    <Grid width="100%">
                                        <FormControlLabel
                                            className={classes.switchLabel}
                                            disabled={
                                                (!form.$("enableIncludePattern").value &&
                                                    !form.$("enableExcludePattern").value) ||
                                                disabled
                                            }
                                            labelPlacement="end"
                                            label={t(
                                                "slingshot_page.tabs.replications.create_job.label.include_files.case_insensitive"
                                            )}
                                            control={
                                                <Checkbox
                                                    onChange={onChangeIncludePatternCaseInsensitive}
                                                    field={form.$("enableIncludePatternCaseInsensitive")}
                                                    checked={form.$("enableIncludePatternCaseInsensitive").value}
                                                    id={`${idPrefix}_enable_include_pattern_case_insensitive`}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Box pb={2} pl={14}>
                                        <Grid container wrap="nowrap" alignItems="center">
                                            <Typography>
                                                {t("slingshot_page.tabs.replications.create_job.label.include_exclude_priority")}
                                            </Typography>
                                            <Box pl={2}>
                                                <Select
                                                    disabled={
                                                        !form.$("enableExcludePattern").value ||
                                                        !form.$("enableIncludePattern").value ||
                                                        disabled
                                                    }
                                                    options={INCLUDE_EXCLUDE_PRIORITY_OPTIONS_ARRAY}
                                                    field={form.$("includeExcludePriority")}
                                                    id={`${idPrefix}_include_exclude_priority`}
                                                />
                                            </Box>
                                        </Grid>
                                    </Box>
                                    <Box pb={10}>
                                        <Grid container wrap="nowrap" alignItems="center">
                                            <FormControlLabel
                                                className={clsx(classes.switchLabel, classes.switchLabelWithWrap)}
                                                labelPlacement="end"
                                                label={t("slingshot_page.tabs.replications.create_job.label.include_recycle_bin")}
                                                control={
                                                    <Switch
                                                        disabled={disabled}
                                                        field={form.$("includeRecycleBin")}
                                                        id={`${idPrefix}_include_recycle_bin`}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid item container wrap="nowrap" alignItems={"flex-start"}>
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.replications.create_job.label.sharebrowser")}</Typography>
                                </Grid>
                                <Grid item container xs={8} wrap="nowrap" direction="column">
                                    <Box pb={4}>
                                        <Grid container wrap="nowrap" alignItems="center">
                                            <FormControlLabel
                                                className={classes.switchLabel}
                                                labelPlacement="end"
                                                label={t("slingshot_page.tabs.replications.create_job.label.include_metadata")}
                                                control={
                                                    <Switch
                                                        onChange={onChangeSharaBrowserMetadata}
                                                        disabled={disabled}
                                                        field={form.$("includeMetadata")}
                                                        id={`${idPrefix}_include_metadata`}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Box>
                                    <Box pb={10}>
                                        <Grid container wrap="nowrap" alignItems="center">
                                            <FormControlLabel
                                                className={classes.switchLabel}
                                                labelPlacement="end"
                                                label={t("slingshot_page.tabs.replications.create_job.label.include_media_proxy")}
                                                control={
                                                    <Switch
                                                        disabled={
                                                            disabled || !form.$("includeMetadata").value || !isMediaProxyEnable
                                                        }
                                                        field={form.$("includeMediaProxy")}
                                                        id={`${idPrefix}_include_media_proxy`}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid item container wrap="nowrap" alignItems={"flex-start"}>
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.replications.create_job.label.verification")}</Typography>
                                </Grid>
                                <Grid item container xs={8} wrap="nowrap" direction="column">
                                    {form.$("method").value !== METHOD_OPTIONS.sync && (
                                        <Box pb={4}>
                                            <Grid container wrap="nowrap" alignItems="center">
                                                <FormControlLabel
                                                    className={clsx(classes.switchLabel, classes.switchLabelWithWrap)}
                                                    labelPlacement="end"
                                                    label={t(
                                                        "slingshot_page.tabs.replications.create_job.label.skip_newer_files"
                                                    )}
                                                    control={
                                                        <Switch
                                                            disabled={disabled}
                                                            field={form.$("skipNewerFiles")}
                                                            id={`${idPrefix}_skip_newer_files`}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        </Box>
                                    )}
                                    <Box pb={4}>
                                        <Grid container wrap="nowrap" alignItems="center">
                                            <FormControlLabel
                                                className={clsx(classes.switchLabel, classes.switchLabelWithWrap)}
                                                labelPlacement="end"
                                                label={t(
                                                    "slingshot_page.tabs.replications.create_job.label.detect_file_modification"
                                                )}
                                                control={
                                                    <Switch
                                                        disabled={disabled}
                                                        field={form.$("detectFileModification")}
                                                        id={`${idPrefix}_detect_file_modification`}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Box>
                                    <Box pb={1}>
                                        <Grid container wrap="nowrap" alignItems="center">
                                            <FormControlLabel
                                                className={classes.switchLabel}
                                                labelPlacement="end"
                                                label={t(
                                                    "slingshot_page.tabs.replications.create_job.label.verify_after_transfer"
                                                )}
                                                control={
                                                    <Switch
                                                        disabled={disabled}
                                                        field={form.$("enableVerifyAfterTransfer")}
                                                        id={`${idPrefix}_enable_verify_after_transfer`}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Box>
                                    <Box pb={10} pl={14}>
                                        <Grid container wrap="nowrap" alignItems="center">
                                            <Typography>
                                                {t("slingshot_page.tabs.replications.create_job.label.verify_report")}
                                            </Typography>
                                            <Box pl={4}>
                                                <Select
                                                    disabled={!form.$("enableVerifyAfterTransfer").value || disabled}
                                                    field={form.$("verifyAfterTransferReport")}
                                                    options={REPORT_VARIANTS_OPTIONS_ARRAY}
                                                    id={`${idPrefix}_verify_after_transfer_report`}
                                                />
                                            </Box>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid item container wrap="nowrap" alignItems={"flex-start"}>
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.replications.create_job.label.job_handling")}</Typography>
                                </Grid>
                                <Grid item container xs={8} wrap="nowrap" direction="column">
                                    <Box pb={4}>
                                        <Grid container wrap="nowrap" alignItems="center">
                                            <FormControlLabel
                                                className={clsx(classes.switchLabel, classes.switchLabelWithWrap)}
                                                labelPlacement="end"
                                                label={t(
                                                    "slingshot_page.tabs.replications.create_job.label.replicate_in_parallel"
                                                )}
                                                control={
                                                    <Switch
                                                        disabled={disabled}
                                                        field={form.$("replicateInParallel")}
                                                        id={`${idPrefix}_replicate_in_parallel`}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Box>
                                    {form.$("method").value === METHOD_OPTIONS.sync && (
                                        <Box pb={4}>
                                            <Grid container wrap="nowrap" alignItems="center">
                                                <FormControlLabel
                                                    className={clsx(classes.switchLabel, classes.switchLabelWithWrap)}
                                                    labelPlacement="end"
                                                    label={t(
                                                        "slingshot_page.tabs.replications.create_job.label.remove_deleted_files"
                                                    )}
                                                    control={
                                                        <Switch
                                                            disabled={disabled}
                                                            field={form.$("removeDeletedFiles")}
                                                            id={`${idPrefix}_remove_deleted_files`}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        </Box>
                                    )}
                                    <Box pb={10}>
                                        <Grid container wrap="nowrap" alignItems="center">
                                            <FormControlLabel
                                                className={classes.switchLabel}
                                                labelPlacement="end"
                                                label={t("slingshot_page.tabs.replications.create_job.label.logs_at_destination")}
                                                control={
                                                    <Switch
                                                        disabled={disabled}
                                                        field={form.$("saveLogsAtDestination")}
                                                        id={`${idPrefix}_save_logs_at_destination`}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid item container wrap="nowrap" alignItems="center">
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.replications.create_job.label.log_level")}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        field={form.$("logLevel")}
                                        options={LOG_LEVEL_OPTIONS_ARRAY}
                                        disabled={disabled}
                                        id={`${idPrefix}_log_level`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <LocalizationTypography className={classes.description} paragraphs={localizations.description.paragraphs} />
            <FolderBrowser
                open={state.isFolderBrowserOpen}
                onClose={closeFolderBrowser}
                onChoose={onFolderChoose}
                share={state.shareForFolderBrowser}
                parentName={
                    createNew
                        ? t("slingshot_page.tabs.replications.create_job.title")
                        : t("slingshot_page.tabs.replications.edit_job.title")
                }
                pathValue={state.path}
                parentId={idPrefix}
            />
        </Grid>
    ));
};

export default CreateEditJobForm;
