import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import RowTextInput from "components/RowTextInput";
import Switch from "components/MobxForm/Switch";
import Select from "components/MobxForm/Select";
import createFields from "./createFields";
import createForm from "utils/createForm";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import ConfirmationDialog from "../ConfirmationDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const EDIT_RESTRICT_MODAL_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_edit_restrict_modal`;

const EditRestrictModal = ({ open, onClose }) => {
    const { t } = useTranslation();
    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields()
        }),
        isSubmitted: false,
        inProgress: false,
        allIfaces: { ifaces: [], bonds: [], bridges: [] },
        get ifacesOptions() {
            const bondsOptions = this.allIfaces.bonds.map(bond => ({ value: bond.port, label: bond.interfaces.join(",") }));
            const ifacesOptions = this.allIfaces.ifaces
                .filter(
                    iface =>
                        !this.allIfaces.bonds.some(bond => bond.interfaces.includes(iface.port)) &&
                        !this.allIfaces.bridges.some(bridge => bridge.interfaces.includes(iface.port))
                )
                .map(iface => iface.port);
            return [...ifacesOptions, ...bondsOptions, ...this.allIfaces.bridges.map(brige => brige.port)];
        }
    }));

    const { unsavedChangesModal, confirmationModal } = useModalCombine(["unsavedChangesModal", "confirmationModal"]);

    const {
        store: { ethernetPortsStore, webserverStore }
    } = useStore();

    const closeModal = () => {
        onClose();
    };

    const onSave = async () => {
        state.inProgress = true;
        const res = await webserverStore.setRestrictSettings(state.form.values());

        if (res) {
            state.isSubmitted = true;
        }

        state.inProgress = false;
        setTimeout(closeModal, 1000);
    };

    const onEnter = async () => {
        state.inProgress = false;
        state.isSubmitted = false;
        await webserverStore.fetchRestrictSettings();
        state.form.$("restrictEnabled").set("default", webserverStore.restrictSettings?.restrictEnabled ?? false);
        state.form.$("ifaceName").set("default", webserverStore.restrictSettings?.ifaceName ?? "");
        state.form.reset();
        unsavedChangesModal.close();

        await ethernetPortsStore.fetchAllIfaces();
        state.allIfaces.ifaces = ethernetPortsStore.allNetIfaces.ifaces;
        state.allIfaces.bridges = ethernetPortsStore.allNetIfaces.bridges;
        state.allIfaces.bonds = ethernetPortsStore.allNetIfaces.bonds;
    };

    const openConfirmationIfNeeded = () => {
        if (state.form.$("restrictEnabled").value) {
            confirmationModal.open();
            return;
        }
        onSave();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            closeModal();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <>
            <ConfirmationDialog
                open={confirmationModal.isOpen}
                onClose={confirmationModal.close}
                onConfirm={onSave}
                ifaceName={state.form.$("ifaceName").value}
            />
            <Dialog
                title={t("admin_settings.restrict_access.edit_restrict_modal.title")}
                onEnter={onEnter}
                open={open}
                onClose={closeIfNeeded}
                buttons={
                    <Button
                        onClick={closeModal}
                        variant={"contained"}
                        color={"secondary"}
                        id={`${EDIT_RESTRICT_MODAL_ID_PREFIX}_cancel`}
                    >
                        {t("common.button.cancel")}
                    </Button>
                }
                submitBtn={
                    <AnimatedSubmitButton
                        label={t("common.button.save")}
                        inProgress={state.inProgress}
                        isSubmitted={state.isSubmitted}
                        submit={openConfirmationIfNeeded}
                        id={`${EDIT_RESTRICT_MODAL_ID_PREFIX}_save`}
                    />
                }
            >
                <RowControlLabelSwitch
                    control={
                        <Switch field={state.form.$("restrictEnabled")} id={`${EDIT_RESTRICT_MODAL_ID_PREFIX}_restrict_access`} />
                    }
                    label={t("admin_settings.restrict_access.label.restrict_access")}
                />
                <RowTextInput
                    label={t("admin_settings.restrict_access.label.allowed_iface")}
                    control={
                        <Select
                            disabled={!state.form.$("restrictEnabled").value}
                            field={state.form.$("ifaceName")}
                            options={state.ifacesOptions}
                            id={`${EDIT_RESTRICT_MODAL_ID_PREFIX}_allowed_iface`}
                        />
                    }
                />
                <UnsavedChangesDialog
                    onConfirm={closeModal}
                    onClose={unsavedChangesModal.close}
                    open={unsavedChangesModal.isOpen}
                />
            </Dialog>
        </>
    ));
};

export default EditRestrictModal;
