import React, { useContext, useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Context } from "store";
import DataReadonlyCard from "components/DataReadonlyCard";
import SetupGlobalMasksDialog from "../SetupGlobalMasksDialog";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";

const GlobalMasksCard = () => {
    const { t } = useTranslation();

    const {
        store: { shareStore }
    } = useContext(Context);

    useEffect(() => {
        (async () => {
            state.inProgress = true;
            await shareStore.fetchDefaultsMasks();
            state.inProgress = false;
        })();
    }, []);

    const state = useLocalStore(() => ({
        openEditDialog: false
    }));
    const closeDialog = () => {
        state.openEditDialog = false;
    };
    const openDialog = () => {
        state.openEditDialog = true;
    };

    return useObserver(() => (
        <>
            <SetupGlobalMasksDialog open={state.openEditDialog} closeDialog={closeDialog} />
            <DataReadonlyCard title={t("advanced.global_masks.title")} autoHeight headerDivider onClick={openDialog}>
                <Grid container direction={"column"} spacing={8}>
                    {shareStore.defaultMasks?.length ? (
                        shareStore.defaultMasks.map(row => (
                            <Grid item key={Math.floor(Math.random() * Date.now())}>
                                <Typography>{row}</Typography>
                            </Grid>
                        ))
                    ) : (
                        <EmptyCardPlaceholder inProgress={state.inProgress} message={t("advanced.global_masks.placeholder")} />
                    )}
                </Grid>
            </DataReadonlyCard>
        </>
    ));
};

export default GlobalMasksCard;
