import { types } from "mobx-state-tree";

import EnclosureActivation from "api/activation/quad/Types/EnclosureActivation";

const EnclosureActivationData = types.model({
    arr: types.array(EnclosureActivation),
    nondestructiveActivationEnabled: types.boolean
});

export default EnclosureActivationData;
