import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./TextInput.style.js";
import { ButtonBase, InputAdornment, TextField } from "@material-ui/core";
import InputErrorIcon from "assets/InputErrorIcon.js";
import clsx from "clsx";

const TextInput = ({ className, nonEditable, error, helperText, showErrorText, ...rest }) => {
    const classes = useStyles();
    const state = useLocalStore(() => ({
        isNeedToShowErrorText: false
    }));

    const toggleErrorText = () => {
        state.isNeedToShowErrorText = !state.isNeedToShowErrorText;
    };

    return useObserver(() => (
        <TextField
            variant="outlined"
            size="small"
            className={clsx(classes.container, nonEditable && classes.nonEditable, className)}
            helperText={(showErrorText || state.isNeedToShowErrorText) && helperText}
            error={error}
            InputProps={
                error
                    ? {
                          endAdornment: (
                              <InputAdornment position={"end"}>
                                  <ButtonBase onClick={toggleErrorText}>
                                      <InputErrorIcon />
                                  </ButtonBase>
                              </InputAdornment>
                          )
                      }
                    : null
            }
            {...rest}
        />
    ));
};

export default TextInput;
