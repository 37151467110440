import { GiB_IEC, PiB_IEC, TABLE_UNIT_VALUE, TiB_IEC } from "const/diskSizeConst";
import i18n from "i18n";
import { getMaxVolumeSizeString } from "./getMaxVolumeSizeString";

const diskSizeValidator = () => {
    return ({ field, form }) => {
        const valid = IEC => {
            const isLessThanGib = IEC.value <= TABLE_UNIT_VALUE[form.$("sizeUnitValue").value] * field.value;
            return [isLessThanGib, i18n.t("rule.disk.size_min", { unit: IEC.unit })];
        };

        const minValidatorResult = {
            [GiB_IEC.unit]: valid(GiB_IEC),
            [TiB_IEC.unit]: valid(TiB_IEC),
            [PiB_IEC.unit]: valid(PiB_IEC)
        }[form.$("sizeUnitValue").value];

        const maxVolumeSize = form.$("__maxSizeResponse").value?.available?.[`raid${form.$("raid").value}Size`];

        const max = Math.floor(+(+maxVolumeSize / +TABLE_UNIT_VALUE[form.$("sizeUnitValue").value]).toFixed(2) * 10) / 10;
        const maxString = getMaxVolumeSizeString(maxVolumeSize, form.$("sizeUnitValue").value);

        const maxValidatorResult = [field.value <= max, i18n.t("validator.disk.size", { maxString })];

        const res = [minValidatorResult, maxValidatorResult].find(([isValid] = []) => (isValid === undefined ? true : !isValid));

        return res || [true, ""];
    };
};

export default diskSizeValidator;
