import Request from "api/Request";

const GetDriveStatus = Request.props({
    path: "/com/studionetworksolutions/evo/SystemDrives",
    method: "get_drive_status",
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default GetDriveStatus;
