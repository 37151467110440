import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import ConfirmationDialog from "components/ConfirmationDialog";
import Select from "components/MobxForm/Select";
import createForm from "utils/createForm";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowTextInput from "components/RowTextInput";
import TextInput from "components/MobxForm/TextInput";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import Switch from "components/MobxForm/Switch";
import { NasConfigurationConstants } from "const/nasConfigurationConst";
import { useModalCombine } from "hooks/useModalCombine";
import { useStore } from "hooks/useStore";
import { ADVANCED_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import localizations from "./NASConfigurationDialog.localizations";
import LocalizationTypography from "components/LocalizationTypography/LocalizationTypography";
import ErrorButton from "components/ErrorButton/ErrorButton";

const NAS_CONFIGURATION_DIALOG_ID_PREFIX = `${ADVANCED_PAGE_ID_PREFIX}_nas_configuration_dialog`;

const NASConfigurationDialog = ({ open, closeDialog }) => {
    const { t } = useTranslation();
    const {
        store: { shareStore },
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields(shareStore.nasConfiguration) }),
        isSubmitted: false,
        inProgress: false,
    }));

    const { confirmModal, unsavedChangesModal, resetConfigModal } = useModalCombine([
        "confirmModal",
        "unsavedChangesModal",
        "resetConfigModal",
    ]);

    const updateForm = () => {
        unsavedChangesModal.close();
        state.isSubmitted = false;

        state.form.$("smbMaxXmit").set("default", shareStore.nasConfiguration?.smbMaxXmit || "");
        state.form.$("smbMaxCredits").set("default", shareStore.nasConfiguration?.smbMaxCredits || "");
        state.form.$("smbWorkgroup").set("default", shareStore.nasConfiguration?.smbWorkgroup || "");
        state.form.$("smbProtocol").set("default", shareStore.nasConfiguration?.smbProtocol || "");
        state.form.$("smbMultichannelEnabled").set("default", shareStore.nasConfiguration?.smbMultichannelEnabled || false);
        state.form.$("smbAsyncEnabled").set("default", shareStore.nasConfiguration?.smbAsyncEnabled || false);
        state.form.$("useSendfileEnabled").set("default", shareStore.nasConfiguration?.useSendfileEnabled || false);
        state.form.$("ftpType").set("default", shareStore.nasConfiguration?.ftpType || "");
        state.form.$("smbFruit").set("default", shareStore.nasConfiguration?.smbFruit ?? true);

        state.form.reset();
    };

    const closeIfNeeded = () => {
        // type checking is required. EVENT IS PASSED BY DEFAULT
        if (state.form.isDefault || state.isSubmitted === true) {
            closeDialog();
            return;
        }
        unsavedChangesModal.open();
    };

    // See http://gitlab.mercury.office/SNS/EVO/-/blob/Battlefield-6.1.5/evo/var/www/localhost/htdocs/js/advanced.js#L60
    const onSubmitConfirm = async () => {
        const res = await shareStore.setNasConfiguration({
            smbMaxXmit: parseInt(state.form.$("smbMaxXmit").value),
            smbMaxCredits: parseInt(state.form.$("smbMaxCredits").value),
            smbWorkgroup: state.form.$("smbWorkgroup").value,
            smbProtocol: state.form.$("smbProtocol").value,
            smbMultichannelEnabled: state.form.$("smbMultichannelEnabled").value,
            smbAsyncEnabled: state.form.$("smbAsyncEnabled").value,
            useSendfileEnabled: state.form.$("useSendfileEnabled").value,
            ftpType: state.form.$("ftpType").value,
            smbFruit: state.form.$("smbFruit").value,
        });
        if (res) {
            state.isSubmitted = true;
        }

        setTimeout(() => {
            confirmModal.close();
            closeDialog();
        }, 1000);

        return res;
    };

    const resetConfig = async () => {
        resetConfigModal.close();
        state.inProgress = true;
        const res = await shareStore.resetNasConfiguration();
        if (res) {
            closeDialog();
        }
        state.inProgress = false;
    };

    return useObserver(() => (
        <Dialog
            isDataChanged={!state.form.isDefault}
            onEnter={updateForm}
            title={t("advanced.nas_configuration.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${NAS_CONFIGURATION_DIALOG_ID_PREFIX}_save`}
            open={open}
            onSubmit={confirmModal.open}
            onClose={closeIfNeeded}
            inProgress={state.inProgress}
            buttons={
                <>
                    <ErrorButton
                        submit={resetConfigModal.open}
                        disabled={state.inProgress}
                        label={t("common.button.reset")}
                        id={`${NAS_CONFIGURATION_DIALOG_ID_PREFIX}_reset`}
                    ></ErrorButton>
                    <Button
                        onClick={closeDialog}
                        variant={"contained"}
                        color="secondary"
                        id={`${NAS_CONFIGURATION_DIALOG_ID_PREFIX}_cancel`}
                    >
                        {t("common.button.cancel")}
                    </Button>
                </>
            }
        >
            <RowTextInput
                label={t("advanced.nas_configuration.label.max_xmit")}
                control={<TextInput field={state.form.$("smbMaxXmit")} id={`${NAS_CONFIGURATION_DIALOG_ID_PREFIX}_max_xmit`} />}
            />

            <RowTextInput
                label={t("advanced.nas_configuration.label.max_credits")}
                control={
                    <TextInput field={state.form.$("smbMaxCredits")} id={`${NAS_CONFIGURATION_DIALOG_ID_PREFIX}_max_credits`} />
                }
            />

            <RowTextInput
                label={t("advanced.nas_configuration.label.workgroup")}
                control={
                    <TextInput field={state.form.$("smbWorkgroup")} id={`${NAS_CONFIGURATION_DIALOG_ID_PREFIX}_workgroup`} />
                }
            />

            <RowTextInput
                label={t("advanced.nas_configuration.label.smb_protocol")}
                control={
                    <Select
                        options={Object.values(NasConfigurationConstants.SmbType)}
                        field={state.form.$("smbProtocol")}
                        id={`${NAS_CONFIGURATION_DIALOG_ID_PREFIX}_smb_protocol`}
                        displayInUpperCase={true}
                    />
                }
            />

            <RowControlLabelSwitch
                control={
                    <Switch
                        field={state.form.$("smbMultichannelEnabled")}
                        id={`${NAS_CONFIGURATION_DIALOG_ID_PREFIX}_smb_multichannel`}
                    />
                }
                label={t("advanced.nas_configuration.label.smb_multichannel")}
            />

            <RowControlLabelSwitch
                control={
                    <Switch field={state.form.$("smbAsyncEnabled")} id={`${NAS_CONFIGURATION_DIALOG_ID_PREFIX}_smb_async`} />
                }
                label={t("advanced.nas_configuration.label.smb_async")}
            />

            <RowControlLabelSwitch
                control={
                    <Switch
                        field={state.form.$("useSendfileEnabled")}
                        id={`${NAS_CONFIGURATION_DIALOG_ID_PREFIX}_use_sendfile`}
                    />
                }
                label={t("advanced.nas_configuration.label.use_sendfile")}
            />

            <RowTextInput
                label={t("advanced.nas_configuration.label.ftp_type")}
                control={
                    <Select
                        options={Object.values(NasConfigurationConstants.FtpType)}
                        field={state.form.$("ftpType")}
                        id={`${NAS_CONFIGURATION_DIALOG_ID_PREFIX}_ftp_type`}
                        displayInUpperCase={true}
                    />
                }
            />

            <RowControlLabelSwitch
                control={<Switch field={state.form.$("smbFruit")} id={`${NAS_CONFIGURATION_DIALOG_ID_PREFIX}_smb_fruit`} />}
                label={t("advanced.nas_configuration.label.smb_fruit")}
            />

            <ConfirmationDialog
                title={t("advanced.nas_configuration.confirm_title")}
                confirmLabel={t("advanced.nas_configuration.confirm_yes_proceed")}
                open={confirmModal.isOpen}
                onClose={confirmModal.close}
                onConfirm={onSubmitConfirm}
                isSubmitted={state.isSubmitted}
            >
                <LocalizationTypography
                    paragraphs={
                        !state.form.$("smbFruit").isDefault && !state.form.$("smbFruit").get("value")
                            ? localizations.saveSettingsConfirmExtension.paragraphs
                            : localizations.saveSettingsConfirm.paragraphs
                    }
                />
            </ConfirmationDialog>
            <ConfirmationDialog
                title={t("common.warning_dialog.title")}
                open={resetConfigModal.isOpen}
                onClose={resetConfigModal.close}
                onConfirm={resetConfig}
                confirmLabel={t("common.button.proceed")}
                cancelLabel={t("common.button.cancel")}
                fullWidth={true}
            >
                {t("advanced.nas_configuration.confirm_reset_title")}
            </ConfirmationDialog>

            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default NASConfigurationDialog;
