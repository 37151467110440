import { SupportQuickStartIcon } from "assets/SupportQuickStartIcon";
import {
    URL_NETWORK,
    URL_TIME_SETTINGS,
    URL_DISKS,
    URL_SHAREBROWSER,
    URL_ALERTS,
    URL_SHARES,
    URL_USERS,
    URL_SLINGSHOT_INDEX,
    URL_SLINGSHOT,
    URL_SUPPORT,
} from "routes";
import {
    EXTERNAL_URL_GETTING_STARTED_GUIDE,
    EXTERNAL_URL_RECOMMENDATIONS_UPS_SIZING,
    EXTERNAL_URL_REGISTER_EVO,
    EXTERNAL_URL_REMOTE_INSTALLATION_ASSISTANCE,
    EXTERNAL_URL_REMOTE_TRAINING,
} from "const/externalURLs";
import UpsConf from "pages/PowerAndUps/components/UpsConf";
import DnsCard from "pages/Network/components/DnsCard";
import TimeSettingsCard from "pages/TimeSettings/components/TimeSettingsCard/TimeSettingsCard";
import EmailConfigurationCard from "pages/Alerts/components/EmailConfigurationCard";

export default {
    basicsSetup: {
        titleKey: "quick_start.basic_setup_card.title",
        paragraphs: [
            {
                textKey: "quick_start.basic_setup_card.paragraph_1",
                components: [
                    {
                        type: "icon",
                        path: URL_SUPPORT,
                        icon: SupportQuickStartIcon,
                        inNewTab: true,
                    },
                ],
                gutterBottom: true,
            },
            {
                textKey: "quick_start.basic_setup_card.paragraph_2",
                components: [
                    {
                        type: "link",
                        path: EXTERNAL_URL_GETTING_STARTED_GUIDE,
                        inNewTab: true,
                    },
                ],
                gutterBottom: true,
            },
        ],
    },
    phoneAssistedInstallation: {
        title: [
            {
                textKey: "quick_start.phone_assisted_installation.title",
                component: "h2",
                variant: "h2",
                gutterBottom: true,
            },
        ],
        paragraphs: [
            {
                textKey: "quick_start.phone_assisted_installation.paragraph_1",
                gutterBottom: true,
            },
            {
                textKey: "quick_start.phone_assisted_installation.paragraph_2",
                components: [
                    {
                        type: "link",
                        path: EXTERNAL_URL_REMOTE_INSTALLATION_ASSISTANCE,
                        inNewTab: true,
                    },

                    {
                        type: "link",
                        path: EXTERNAL_URL_REMOTE_TRAINING,
                        inNewTab: true,
                    },
                ],
            },
        ],
    },
    setupSteps: [
        {
            titleKey: "quick_start.basic_setup_card.step_1.title",
            paragraphs: [
                {
                    textKey: "quick_start.basic_setup_card.step_1.paragraph_1",
                    components: [
                        {
                            type: "link",
                            path: EXTERNAL_URL_REGISTER_EVO,
                            inNewTab: true,
                        },
                    ],
                },
            ],
        },
        {
            titleKey: "quick_start.basic_setup_card.step_2.title",
            paragraphs: [
                {
                    textKey: "quick_start.basic_setup_card.step_2.paragraph_1",
                    gutterBottom: true,
                },
                {
                    textKey: "quick_start.basic_setup_card.step_2.paragraph_2",
                    components: [
                        {
                            type: "link",
                            path: EXTERNAL_URL_RECOMMENDATIONS_UPS_SIZING,
                            inNewTab: true,
                        },
                    ],
                },
            ],
            sideComponent: UpsConf,
            cardsOnlyAuthorized: true,
        },
        {
            titleKey: "quick_start.basic_setup_card.step_3.title",
            paragraphs: [
                {
                    textKey: "quick_start.basic_setup_card.step_3.paragraph_1",
                    gutterBottom: true,
                },
                {
                    textKey: "quick_start.basic_setup_card.step_3.paragraph_2",
                    components: [
                        {
                            type: "link",
                            path: URL_NETWORK,
                            inNewTab: true,
                        },
                    ],
                },
            ],
        },
        {
            titleKey: "quick_start.basic_setup_card.step_4.title",
            paragraphs: [
                {
                    textKey: "quick_start.basic_setup_card.step_4.paragraph_1",
                    components: [
                        {
                            type: "link",
                            path: URL_TIME_SETTINGS,
                            inNewTab: true,
                        },
                    ],
                },
            ],
            sideComponent: [
                { id: 1, component: DnsCard },
                { id: 2, component: TimeSettingsCard },
            ],
            cardsOnlyAuthorized: true,
        },
        {
            titleKey: "quick_start.basic_setup_card.step_5.title",
            paragraphs: [
                {
                    textKey: "quick_start.basic_setup_card.step_5.paragraph_1",
                    gutterBottom: true,
                },
                {
                    textKey: "quick_start.basic_setup_card.step_5.paragraph_2",
                    components: [
                        {
                            type: "link",
                            path: URL_ALERTS,
                            inNewTab: true,
                        },
                    ],
                },
            ],
            sideComponent: EmailConfigurationCard,
            cardsOnlyAuthorized: true,
        },
        {
            titleKey: "quick_start.basic_setup_card.step_6.title",
            paragraphs: [
                {
                    textKey: "quick_start.basic_setup_card.step_6.paragraph_1",
                    gutterBottom: true,
                },
                {
                    textKey: "quick_start.basic_setup_card.step_6.paragraph_2",
                    components: [
                        {
                            type: "link",
                            path: URL_USERS,
                            inNewTab: true,
                        },
                    ],
                },
            ],
        },
        {
            titleKey: "quick_start.basic_setup_card.step_7.title",
            paragraphs: [
                {
                    textKey: "quick_start.basic_setup_card.step_7.paragraph_1",
                    gutterBottom: true,
                },
                {
                    textKey: "quick_start.basic_setup_card.step_7.paragraph_2",
                    gutterBottom: true,
                    components: [
                        {
                            type: "link",
                            path: URL_DISKS,
                            inNewTab: true,
                        },
                    ],
                },
                {
                    textKey: "quick_start.basic_setup_card.step_7.paragraph_3",
                    components: [
                        {
                            type: "link",
                            path: URL_SHARES,
                            inNewTab: true,
                        },
                    ],
                },
            ],
        },
        {
            titleKey: "quick_start.basic_setup_card.step_8.title",
            paragraphs: [
                {
                    textKey: "quick_start.basic_setup_card.step_8.paragraph_1",
                    gutterBottom: true,
                },
                {
                    textKey: "quick_start.basic_setup_card.step_8.paragraph_2",
                    gutterBottom: true,
                    components: [
                        {
                            type: "link",
                            path: URL_SLINGSHOT_INDEX,
                            inNewTab: true,
                        },

                        {
                            type: "link",
                            path: URL_SLINGSHOT_INDEX,
                            inNewTab: true,
                        },
                    ],
                },
                {
                    textKey: "quick_start.basic_setup_card.step_8.paragraph_3",
                    components: [
                        {
                            type: "link",
                            path: URL_SHAREBROWSER,
                            inNewTab: true,
                        },
                    ],
                },
            ],
        },
        {
            titleKey: "quick_start.basic_setup_card.step_9.title",
            paragraphs: [
                {
                    textKey: "quick_start.basic_setup_card.step_9.paragraph_1",
                    gutterBottom: true,
                },
                {
                    textKey: "quick_start.basic_setup_card.step_9.paragraph_2",
                    gutterBottom: true,
                },
                {
                    textKey: "quick_start.basic_setup_card.step_9.paragraph_3",
                    gutterBottom: true,
                },
                {
                    textKey: "quick_start.basic_setup_card.step_9.paragraph_4",
                    components: [
                        {
                            type: "link",
                            path: URL_SLINGSHOT,
                            inNewTab: true,
                        },
                    ],
                },
            ],
        },
        {
            titleKey: "quick_start.basic_setup_card.step_10.title",
            paragraphs: [
                {
                    textKey: "quick_start.basic_setup_card.step_10.paragraph_1",
                    components: [
                        {
                            type: "icon",
                            path: URL_SUPPORT,
                            icon: SupportQuickStartIcon,
                            inNewTab: true,
                        },
                    ],
                },
            ],
        },
    ],
};
