import { types } from "mobx-state-tree";

import MissingQuad from "api/activation/quad/Types/MissingQuad";

const GetMissingQuadsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(MissingQuad)
});

export default GetMissingQuadsResult;
