import { types } from "mobx-state-tree";
import { ACTIVATED, ACTIVATING, DEACTIVATING } from "const/quadActivationStatuses";

const QuadActivation = types
    .model({
        quad: types.number,
        quadState: types.string
    })
    .views(self => ({
        get activated() {
            return self.quadState === ACTIVATED;
        },
        get inProgress() {
            return self.quadState === ACTIVATING || self.quadState === DEACTIVATING;
        }
    }));

export default QuadActivation;
