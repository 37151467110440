import Request from "api/Request";
import { types } from "mobx-state-tree";
import ServiceStateJson from "../Types/ServiceStateJson";

const SetServiceState = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/SyncJobs1",
    method: "set_service_state",
    arguments: types.maybe(
        types.snapshotProcessor(ServiceStateJson, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json)
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetServiceState;
