import React from "react";
import config from "config";
import { useObserver } from "mobx-react";
import { useStyles } from "./MuptipleEvoConfiguration.styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import FormControlLabel from "components/FormControlLabel/FormControlLabel";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { toJS } from "mobx";
import { getSnapshot } from "mobx-state-tree";
import { useStore } from "hooks/useStore";
import isSameEvoNameRule from "utils/isSameEvoNameRule";
import isSameEvoIpRule from "utils/isSameEvoIpRule";
import { useModal } from "hooks/useModal";
import { useMuptipleEvoConfigurationModal } from "hooks/modals";
import MuptipleEvoConfigurationTable from "./components/MuptipleEvoConfigurationTable/MuptipleEvoConfigurationTable";
import WarningDialog from "components/WarningDialog";
import { useMuptipleEvoConfigurationState } from "./MuptipleEvoConfiguration.hooks";

/**
 * @typedef {object} Evo
 * @property {string} ip
 * @property {string} name
 * @property {boolean} needToConnect
 */

const MuptipleEvoConfiguration = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { uiStore, multipleEvosStore, evoSettingsStore },
    } = useStore();

    const warningDialog = useModal();
    const unsavedChangesModal = useModal();
    const muptipleEvoConfigurationModal = useMuptipleEvoConfigurationModal();

    const state = useMuptipleEvoConfigurationState();

    const onEnter = () => {
        const multipleEvos = getSnapshot(uiStore.parameters)?.multipleEvos;
        state.multipleEvos = [...multipleEvos];
        const connectedEvos = multipleEvosStore.multipleEvoConnections?.filter((evo) => evo.isAuthorized === true);
        state.connectedEvos = [...connectedEvos];
        state.removalCandidate = null;
        state.form.$("name").set("validators", [isSameEvoNameRule(evoSettingsStore.evoInfo?.hostname || "")]);
        state.form.$("ip").set("validators", [isSameEvoIpRule(config.path)]);
        state.form.reset();
        state.resetValidationFields();
    };

    const handleAddEvo = async (e) => {
        state.startProgress();

        const multipleEvos = state.addEvoToList(e);
        if (multipleEvos === null) return;

        await uiStore.patchUiParameters({
            multipleEvos: toJS(multipleEvos),
        });

        state.stopProgress();
    };

    const handleClose = () => {
        if (state.form.$("name").value || state.form.$("ip").value) {
            unsavedChangesModal.open();
            return null;
        }

        muptipleEvoConfigurationModal.close();

        state.stopProgress();
    };

    const handleConfirmUnsaved = () => {
        muptipleEvoConfigurationModal.close();

        state.stopProgress();
    };

    const handleCloseWarningDialog = () => {
        warningDialog.close();
        state.removalCandidate = null;

        state.stopProgress();
    };

    const handleRemoveEvoWarning = async () => {
        state.startProgress();

        warningDialog.close();
        await state.removeEvoFromList(state.removalCandidate);
        multipleEvosStore.logoutFromMultipleEvo(state.removalCandidate.ip);

        state.stopProgress();
    };

    /** @param {Evo} evo */
    const handleRemoveEvo = (evo) => async () => {
        state.startProgress();

        const connectedEvo = state.connectedEvos.find((connectedEvo) => connectedEvo.ip === evo.ip);
        if (connectedEvo) {
            state.stopProgress();
            warningDialog.open();
            state.removalCandidate = evo;
            return;
        }

        await state.removeEvoFromList(evo);

        state.stopProgress();
    };

    /** @param {Evo} evo */
    const handleAutoconnect = (evo) => async () => {
        state.startProgress();

        const idxEvoByIp = state.multipleEvos.findIndex((multipleEvo) => multipleEvo.ip === evo.ip);
        const updatingEvo = { ...state.multipleEvos[idxEvoByIp], needToConnect: !evo.needToConnect };

        state.multipleEvos.splice(idxEvoByIp, 1, updatingEvo);
        await uiStore.patchUiParameters({ multipleEvos: toJS(state.multipleEvos) });

        state.stopProgress();
    };

    return useObserver(() => (
        <Dialog
            title={t("multiple_evo.configure.title")}
            submitBtnLabel={t("common.button.close")}
            maxWidth="md"
            open={muptipleEvoConfigurationModal.isOpen}
            withoutPaddings
            onEnter={onEnter}
            onClose={handleClose}
            onSubmit={handleClose}
            inProgress={state.inProgress}
            additionalTitle={
                <Box pt={4} pb={4} pr={6} pl={6}>
                    <Grid container spacing={4} alignItems={"center"} justify={"space-between"}>
                        <Grid container item alignItems={"center"} justify={"flex-end"} wrap={"nowrap"}>
                            <Grid item>
                                <Box pr={6}>
                                    <Typography variant={"h6"}>{t("multiple_evo.configure.label")}</Typography>
                                </Box>
                            </Grid>
                            <Grid item className={classes.formControl}>
                                <Box pr={6}>
                                    <Typography>{t("multiple_evo.configure.table.name")}:</Typography>
                                </Box>
                                <FormControlLabel control={<TextInput field={state.form.$("name")} />} />
                            </Grid>
                            <Grid item className={classes.formControl}>
                                <Box pr={6}>
                                    <Typography>{t("multiple_evo.configure.table.host")}:</Typography>
                                </Box>
                                <FormControlLabel control={<TextInput field={state.form.$("ip")} />} />
                            </Grid>
                            <Grid item>
                                <Button onClick={handleAddEvo} variant={"contained"} color={"primary"}>
                                    {t("common.button.add")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            }
        >
            <MuptipleEvoConfigurationTable
                onRemove={handleRemoveEvo}
                onAutoconnect={handleAutoconnect}
                list={state.multipleEvos}
            />
            <UnsavedChangesDialog
                onConfirm={handleConfirmUnsaved}
                onClose={unsavedChangesModal.close}
                open={unsavedChangesModal.isOpen}
            />
            <WarningDialog
                title={t("common.warning_dialog.title")}
                open={warningDialog.isOpen}
                submitBtnLabel={t("common.button.remove_agree")}
                onClose={handleCloseWarningDialog}
                submit={handleRemoveEvoWarning}
                content={t("multiple_evo.configure.table.warning.text", {
                    connectionName: state.removalCandidate?.name || "",
                })}
                cancelBtnLabel={t("common.button.cancel")}
            />
        </Dialog>
    ));
};

export default MuptipleEvoConfiguration;
