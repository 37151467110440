import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    evoTitle: {
        padding: theme.spacing(2, 0),
        fontSize: 32,
        fontWeight: 900,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 0.69,
        letterSpacing: 0.73,
        color: theme.palette.primary.main
    }
}));
