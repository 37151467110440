import { types } from "mobx-state-tree";
import ArgumentsTaskType from "api/slingshot/automations/Types/ArgumentsTaskType";

const UpdateTaskTypeManifest = types.model("UpdateTaskTypeManifest", {
    internal: types.boolean
});

const UpdateTaskTypeAction = types.model("UpdateTaskTypeAction", {
    action: types.string,
    id: types.maybe(types.number),
    installed: types.maybe(types.boolean),
    name: types.maybe(types.string),
    manifest: types.maybe(
        types.snapshotProcessor(UpdateTaskTypeManifest, {
            postProcessor: snapshot => (snapshot ? JSON.stringify(snapshot) : "{}")
        })
    )
});

const UpdateTaskTypeDatasets = types.model("datasets", {
    dataset_no: types.maybe(types.number),
    dataset_type: types.maybe(types.string),
    destination_no: types.maybe(types.number)
});

const UpdateTaskType = types.model("UpdateTaskType", {
    action: types.maybeNull(UpdateTaskTypeAction),
    arguments: types.snapshotProcessor(ArgumentsTaskType, {
        postProcessor: snapshot => (snapshot ? JSON.stringify(snapshot) : "{}")
    }),
    automation_id: types.maybe(types.number),
    datasets: types.maybe(types.array(UpdateTaskTypeDatasets)),
    deleted: types.maybe(types.boolean),
    enabled: types.maybe(types.boolean),
    event_object_types: types.maybeNull(types.array(types.string)),
    fail_automation_if_failed: types.maybe(types.boolean),
    group_action: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    mandatory: types.maybe(types.boolean),
    restart_if_failed: types.maybe(types.boolean),
    sequence: types.maybe(types.number),
    type: types.maybe(types.string),
    subtasks: types.maybe(types.array(types.reference(types.late(() => UpdateTaskType)))),
    walk_type: types.maybe(types.string)
});

export default UpdateTaskType;
