import * as React from "react";

const GiftIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
        <path
            d="M19.2 7h-.39c.12-.32.184-.658.19-1a3.08 3.08 0 00-3.14-3A4.46 4.46 0 0012 5.4 4.46 4.46 0 008.14 3 3.08 3.08 0 005 6c.006.342.07.68.19 1H4.8a2 2 0 00-1.802 2.18L3 9.2v3.6A2.08 2.08 0 004.5 15v4.37A1.75 1.75 0 006.31 21h11.38a1.75 1.75 0 001.81-1.67V15a2.08 2.08 0 001.5-2.2V9.2a2 2 0 00-1.78-2.198L19.2 7zM19 9.2v3.6a.56.56 0 010 .2h-6V9h6a.56.56 0 010 .2zM15.86 5A1.08 1.08 0 0117 6a1.08 1.08 0 01-1.14 1H13.4a2.93 2.93 0 012.46-2zM7 6a1.08 1.08 0 011.14-1 2.93 2.93 0 012.45 2H8.14A1.08 1.08 0 017 6zM5 9.2A.56.56 0 015 9h6v4H5a.56.56 0 010-.2V9.2zM6.5 15H11v4H6.5v-4zm6.5 4v-4h4.5v4H13z"
            fill="#1E90FF"
            fillRule="evenodd"
        />
    </svg>
);

export default GiftIcon;
