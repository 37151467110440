import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => ({
    quadItemContainer: {
        padding: theme.spacing(2, 6, 2, 0.75),
        borderRadius: theme.spacing(1),
        backgroundImage: `linear-gradient(
            95deg, 
            ${hexToRgba(theme.palette.buttonLine.background.gradient1, 1)}, 
            ${hexToRgba(theme.palette.buttonLine.background.gradient2, 1)} 
            106%);`,
        cursor: "pointer",
    },
    checkboxControl: {
        paddingRight: theme.spacing(1),
    },
    label: {
        marginLeft: "auto",
    },
    icon: {
        display: "flex",
        alignItems: "center",
    },
}));
