import { types } from "mobx-state-tree";

import Request from "api/Request";
import DisableActiveDirectoryArguments from "api/security/users/Types/DisableActiveDirectoryArguments";

const DisableActiveDirectory = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Users",
    method: "disable_active_directory",
    arguments: types.maybe(DisableActiveDirectoryArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default DisableActiveDirectory;
