import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./EnclosureItem.styles";
import { Grid, Box, Typography, Divider } from "@material-ui/core";
import QuadActivatedIcon from "assets/QuadActivatedIcon";
import QuadItem from "../QuadItem";

const EnclosureItem = ({ enclosure, ip, ...props }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const state = useLocalStore(() => ({
        get quadDirection() {
            return enclosure.quadStatus.length === 1 ? "row" : "column";
        }
    }));

    return useObserver(() => (
        <Grid container direction="column" wrap="nowrap" className={classes.enclosure} {...props}>
            <Box p={4}>
                <Typography variant="h2" className={classes.enclosureTitle}>
                    {enclosure.enclosure}
                </Typography>
            </Box>
            <Box flex={1} pl={4} pr={4} pb={3}>
                <Grid container wrap="nowrap" alignItems="center" spacing={2} justify="flex-start">
                    {enclosure.quadStatus.map(quadStatus => (
                        <Grid key={`quad${quadStatus.quad}`} item container spacing={1} wrap="nowrap" alignItems={"center"}>
                            <Grid item>
                                <Box display="flex" p={1} alignItems="center">
                                    <QuadActivatedIcon />
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box display="flex" alignItems="center">
                                    <Typography>{t("hardware_activation.common.quad", { quad: quadStatus.quad })}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Divider />
            <Box p={4} flex={1}>
                <Grid container wrap="nowrap" spacing={2} justify="flex-start">
                    {enclosure.quadStatus.map(quadStatus => (
                        <Grid container item key={`enclosure${enclosure.enclosure}quad${quadStatus.quad}`}>
                            <QuadItem
                                quad={quadStatus.quad}
                                driveStatuses={quadStatus.driveStatus}
                                direction={state.quadDirection}
                                ip={ip}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Grid>
    ));
};

export default EnclosureItem;
