import { types } from "mobx-state-tree";

import AuditShareSettings from "api/audit/Types/AuditShareSettings";

const GetAuditPershareResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(AuditShareSettings)
});

export default GetAuditPershareResult;
