import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => {
    const getColor = status => {
        switch (status) {
            case "IN PROGRESS":
                return theme.palette.primary.main;
            case "SUCCEED":
                return theme.palette.success.main;
            case "FAILED":
                return theme.palette.error.main;
            case "PAUSED":
            case "WAITED FOR RESTART":
                return theme.palette.warning.main;
            case "CANCELED":
            case "SKIPPED":
                return theme.palette.grey[300];
            default:
                return theme.palette.primary.main;
        }
    };

    return {
        label: {
            padding: theme.spacing(0, 2),
            borderRadius: theme.spacing(1),
            backgroundColor: props => hexToRgba(getColor(props.status), 0.1),
            "& span": {
                color: props => getColor(props.status)
            }
        }
    };
});
