import React, { useEffect } from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { useLocalStore, observer } from "mobx-react-lite";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import createForm from "utils/createForm";
import createFields from "./createFields";
import { Button } from "@material-ui/core";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { STORAGE_DISK, STORAGE_SHARE } from "const/replicationLogConst";
import Radio from "components/Radio";
import RadioGroup from "components/MobxForm/RadioGroup";
import Select from "components/MobxForm/Select";
import { useStyles } from "./SetupReplicationLogsDialog.styles";
import NewFolderDialog from "./NewFolderDialog";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";
import { addSlashToFolderName } from "../../utils/addSlashToFolderName";
import { removeExtraSlashesFromFolderName } from "../../utils/removeExtraSlashesFromFolderName";

const SETUP_REPLICATION_LOGS_DIALOG_ID_PREFIX = `setup_replication_logs_dialog`;

const SetupReplicationLogsDialog = ({ open, onClose, parentId }) => {
    const idPrefix = parentId
        ? `${parentId}_${SETUP_REPLICATION_LOGS_DIALOG_ID_PREFIX}`
        : SETUP_REPLICATION_LOGS_DIALOG_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { slingshotSettingsStore },
    } = useStore();
    const classes = useStyles();

    const EVO_REPLICATION_LOGS = "evo-replication-logs";

    const ADDITIONAL_START_ITEMS = [{ label: "<root>", value: "/" }];
    const ADDITIONAL_END_ITEMS = [{ label: EVO_REPLICATION_LOGS, value: EVO_REPLICATION_LOGS }];

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        inProgress: false,
        isSubmitted: false,
        get shareFolderList() {
            const folderList = slingshotSettingsStore.foldersList?.map((folder) => decodeURIComponent(folder.name)) || [];

            return folderList;
        },
    }));

    const { unsavedChangesModal, createFolderModal } = useModalCombine(["unsavedChangesModal", "createFolderModal"]);

    const updateForm = () => {
        state.form.reset();
        state.form.showErrors(false);
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };

    useEffect(() => {
        if (!slingshotSettingsStore.replicationLogStorage) {
            return;
        }

        state.form
            .$("storage")
            .set("default", slingshotSettingsStore.replicationLogStorage?.share ? STORAGE_SHARE.value : STORAGE_DISK.value);

        const { folder, share } = slingshotSettingsStore.replicationLogStorage || {};

        if (state.form.$("share").value && state.form.$("share").value !== share) {
            state.form.$("folder").set("value", EVO_REPLICATION_LOGS);
            return;
        }

        const decodedFolder = decodeURIComponent(folder || "");
        const hasFolderInAdditionalItems = ADDITIONAL_END_ITEMS.find(({ value }) => value === decodedFolder);
        const handledFolder = hasFolderInAdditionalItems ? decodedFolder : addSlashToFolderName(decodedFolder);
        state.form.$("share").set(share ?? "");
        state.form.$("folder").set("value", handledFolder);
    }, [slingshotSettingsStore.replicationLogStorage, state.form.$("share").value]);

    useEffect(() => {
        if (state.form.$("share").value) {
            slingshotSettingsStore.fetchFoldersByShare(state.form.$("share").value);
        }
    }, [state.form.$("share").value]);

    const onSubmit = async (e) => {
        state.form.onSubmit(e);
        if (state.form.$("storage").value === STORAGE_DISK.value) {
            state.form.$("share").resetValidation();
            state.form.$("folder").resetValidation();
        }

        if (!state.form.isValid) return;

        state.inProgress = true;
        let logStorage = {};

        if (state.form.$("storage").value === STORAGE_SHARE.value) {
            logStorage = {
                folder: removeExtraSlashesFromFolderName(state.form.$("folder").value),
                share: state.form.$("share").value,
            };
        }

        if (
            !state.shareFolderList.find(
                (folderFromList) =>
                    folderFromList?.slice(0, state.form.$("folder").value.length - 1) ===
                    decodeURIComponent(state.form.$("folder").value)
            ) &&
            state.form.$("share").value &&
            !ADDITIONAL_END_ITEMS.find(({ value }) => value === state.form.$("folder").value)
        ) {
            await slingshotSettingsStore.createFolder({
                share: state.form.$("share").value,
                path: removeExtraSlashesFromFolderName(state.form.$("folder").value),
                createParents: true,
            });
        }

        const res = await slingshotSettingsStore.setReplicationLogSettings({
            logStorage: logStorage,
        });

        slingshotSettingsStore.fetchTransportingConfig();

        state.inProgress = false;

        if (res) {
            state.isSubmitted = true;

            setTimeout(() => {
                onClose();
            }, 1000);
        }

        return res;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }

        unsavedChangesModal.open();
    };

    const onChangeShare = async (e) => {
        state.form.$("share").set("value", e.target.value);
        state.form.$("folder").clear();
    };

    const addNewFolder = (newFolder) => {
        state.shareFolderList.push(addSlashToFolderName(newFolder));
        state.form.$("folder").set("value", addSlashToFolderName(newFolder));
    };

    return (
        <Dialog
            onClose={closeIfNeeded}
            open={open}
            title={t("slingshot_page.tabs.settings.replication_logs.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            inProgress={state.inProgress}
            onSubmit={onSubmit}
            isSubmitted={state.isSubmitted}
            onEnter={updateForm}
            maxWidth={"md"}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container>
                <Grid item xs={4}>
                    <RadioGroup field={state.form.$("storage")}>
                        <Radio
                            label={t("slingshot_page.tabs.settings.replication_logs.storage_disk")}
                            value={STORAGE_DISK.value}
                            id={`${idPrefix}_radio_storage_disk`}
                        />
                        <Radio
                            label={t("slingshot_page.tabs.settings.replication_logs.storage_share")}
                            value={STORAGE_SHARE.value}
                            id={`${idPrefix}_radio_storage_share`}
                        />
                    </RadioGroup>
                </Grid>
                <Grid item xs={8}>
                    {state.form.$("storage").value === STORAGE_SHARE.value && (
                        <>
                            <Select
                                className={classes.select}
                                field={state.form.$("share")}
                                onChange={onChangeShare}
                                options={slingshotSettingsStore.shares.map((share) => share.name)}
                                id={`${idPrefix}_share`}
                            />
                            <Select
                                field={state.form.$("folder")}
                                options={[...state.shareFolderList, ...ADDITIONAL_END_ITEMS]}
                                additionalStartOptions={ADDITIONAL_START_ITEMS}
                                actionOption={
                                    <MenuItem key={"action-option"} onClick={createFolderModal.open} value={[]}>
                                        <Typography>{t("common.option.create_new")}</Typography>
                                    </MenuItem>
                                }
                                id={`${idPrefix}_folder`}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
            <NewFolderDialog
                open={createFolderModal.isOpen}
                onClose={createFolderModal.close}
                newFolder={addNewFolder}
                parentId={idPrefix}
            />
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    );
};

export default observer(SetupReplicationLogsDialog);
