import { LDAP } from "const/ldapStatuses";
import { types, flow, getParent } from "mobx-state-tree";
import MultipleEvosRootStore from "store/MultipleEvosRootStore";

const MultiLdapStore = types
    .model({})
    .views(self => {
        const { userStore, aclStore } = getParent(self);
        const multipleStores = MultipleEvosRootStore.stores;
        return {
            get currentShareName() {
                const storeWithCurrentShareName = [...multipleStores]
                    .map(([, value]) => value)
                    .find(store => store.aclStore.currentShareName);

                return aclStore.currentShareName || storeWithCurrentShareName?.aclStore.currentShareName || null;
            },
            get currentShareAclStore() {
                const aclStoreContainCurrentShare = aclStore.currentShareName === self.currentShareName;
                return (
                    [...multipleStores].map(([, value]) => value).find(store => store.aclStore.currentShareName)?.aclStore ||
                    (aclStoreContainCurrentShare && aclStore)
                );
            },
            get storesWithSameLdap() {
                const stores = [];
                if (!userStore.ldapStatus?.enabled) return stores;
                const mainStoreLdap = {
                    host: userStore.ldapStatus.settings.host,
                    baseDN: userStore.ldapStatus.settings.baseDN
                };

                self.mappedMultipleStores.forEach(store => {
                    if (
                        store.userStore.ldapStatus?.enabled &&
                        store.userStore.ldapStatus.settings.host === mainStoreLdap.host &&
                        store.userStore.ldapStatus.settings.baseDN === mainStoreLdap.baseDN
                    ) {
                        stores.push(store);
                    }
                });

                return stores;
            },
            get storesWithSameAd() {
                const stores = [];
                if (!userStore.activeDirectoryStatus?.enabled) return stores;
                const mainStoreAdDomain = userStore.activeDirectoryStatus.settings.domain;

                self.mappedMultipleStores.forEach(store => {
                    if (
                        store.userStore.activeDirectoryStatus?.enabled &&
                        store.userStore.activeDirectoryStatus.settings.domain === mainStoreAdDomain
                    ) {
                        stores.push(store);
                    }
                });

                return stores;
            },
            get mappedMultipleStores() {
                return [...multipleStores].map(([, value]) => value);
            },
            get allSharesRootAccess() {
                let access = [...aclStore.sharesRootAccess.map(access => ({ ...access, ip: undefined }))];
                if (userStore.currentSyncMode.enabled) {
                    userStore.currentSyncMode.mode === LDAP
                        ? self.storesWithSameLdap.forEach(store => {
                              access = [
                                  ...access,
                                  ...store.aclStore.sharesRootAccess.map(access => ({ ...access, ip: store.ip }))
                              ];
                          })
                        : self.storesWithSameAd.forEach(store => {
                              access = [
                                  ...access,
                                  ...store.aclStore.sharesRootAccess.map(access => ({ ...access, ip: store.ip }))
                              ];
                          });
                }

                return access;
            },
            get isSharesRootAccessDefault() {
                return ![aclStore, ...self.mappedMultipleStores.map(store => store.aclStore)].some(
                    store => !store.isSharesRootAccessDefault
                );
            }
        };
    })
    .actions(self => {
        const { userStore, aclStore } = getParent(self);
        const multipleStores = MultipleEvosRootStore.stores;
        return {
            getLdapStatus: flow(function*() {
                const { processingStore } = getParent(self);
                try {
                    processingStore.setLoading(true);
                    yield Promise.all([
                        userStore.getLdapStatus(),
                        ...self.mappedMultipleStores.map(store => store.userStore.getLdapStatus())
                    ]);
                    return true;
                } catch (e) {
                    //handled in userStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            getAdStatus: flow(function*() {
                const { processingStore } = getParent(self);
                try {
                    processingStore.setLoading(true);
                    yield Promise.all([
                        userStore.getActiveDirectoryStatus(),
                        ...self.mappedMultipleStores.map(store => store.userStore.getActiveDirectoryStatus())
                    ]);
                    return true;
                } catch (e) {
                    //handled in userStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            fetchSharesRootAccess: flow(function*(currentEntity) {
                const { processingStore } = getParent(self);
                try {
                    const isExternal = currentEntity.isExternal;
                    processingStore.setLoading(true);
                    if (isExternal) {
                        userStore.currentSyncMode.mode === LDAP
                            ? yield Promise.all([
                                  aclStore.fetchSharesRootAccess({ user: { ...currentEntity.id } }),
                                  ...self.storesWithSameLdap.map(store =>
                                      store.aclStore.fetchSharesRootAccess({ user: { name: currentEntity.id.name, domain: "" } })
                                  )
                              ])
                            : yield Promise.all([
                                  aclStore.fetchSharesRootAccess({ user: { ...currentEntity.id } }),
                                  ...self.storesWithSameAd.map(store =>
                                      store.aclStore.fetchSharesRootAccess({ user: { ...currentEntity.id } })
                                  )
                              ]);
                    } else {
                        yield aclStore.fetchSharesRootAccess({ user: { ...currentEntity.id } });
                    }

                    return true;
                } catch (e) {
                    //handled in aclStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            setNewPermission: ({ access, shareName, ip }) => {
                const { processingStore } = getParent(self);
                try {
                    processingStore.setLoading(true);
                    const store = ip ? MultipleEvosRootStore.getStore(ip) : getParent(self);
                    if (store) {
                        store.aclStore.setNewPermission(shareName, access);
                    }
                    return true;
                } catch (e) {
                    //handled in aclStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            },
            setNewPermissionToAllShares: flow(function*({ access, currentEntity }, options) {
                const { processingStore } = getParent(self);
                try {
                    const isExternal = currentEntity.isExternal;
                    processingStore.setLoading(true);
                    if (isExternal) {
                        userStore.currentSyncMode.mode === LDAP
                            ? yield Promise.all([
                                  aclStore.setNewPermissionToAllShares(access, options),
                                  ...self.storesWithSameLdap.map(store =>
                                      store.aclStore.setNewPermissionToAllShares(access, options)
                                  )
                              ])
                            : yield Promise.all([
                                  aclStore.setNewPermissionToAllShares(access, options),
                                  ...self.storesWithSameAd.map(store =>
                                      store.aclStore.setNewPermissionToAllShares(access, options)
                                  )
                              ]);
                    } else {
                        aclStore.setNewPermissionToAllShares(access, options);
                    }

                    return true;
                } catch (e) {
                    //handled in aclStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            setSharesRootAccess: flow(function*(currentEntity) {
                const { processingStore } = getParent(self);
                try {
                    const isExternal = currentEntity.isExternal;
                    processingStore.setLoading(true);
                    if (isExternal) {
                        userStore.currentSyncMode.mode === LDAP
                            ? yield Promise.all([
                                  aclStore.setSharesRootAccess({ name: currentEntity.name, domain: currentEntity.domain }),
                                  ...self.storesWithSameLdap.map(store =>
                                      store.aclStore.setSharesRootAccess({ name: currentEntity.name, domain: "" })
                                  )
                              ])
                            : yield Promise.all([
                                  aclStore.setSharesRootAccess({ name: currentEntity.name, domain: currentEntity.domain }),
                                  ...self.storesWithSameAd.map(store =>
                                      store.aclStore.setSharesRootAccess({
                                          name: currentEntity.name,
                                          domain: currentEntity.domain
                                      })
                                  )
                              ]);
                    } else {
                        yield aclStore.setSharesRootAccess({ name: currentEntity.name, domain: currentEntity.domain });
                    }
                    return true;
                } catch (e) {
                    //handled in aclStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            dropOthersCurrentShareNames: ip => {
                if (ip) {
                    aclStore.setCurrentShareName(null);
                    multipleStores.forEach(store => store.ip !== ip && store.aclStore.setCurrentShareName(null));
                } else {
                    multipleStores.forEach(store => store.aclStore.setCurrentShareName(null));
                }
            },
            dropAllCurrentShareNames: () => {
                aclStore.setCurrentShareName(null);
                multipleStores.forEach(store => store.aclStore.setCurrentShareName(null));
            },
            setCurrentShareName: ({ shareName, ip }) => {
                ip ? multipleStores.get(ip).aclStore.setCurrentShareName(shareName) : aclStore.setCurrentShareName(shareName);
            },
            resetSharesRootAccess(currentEntity) {
                const { processingStore } = getParent(self);
                try {
                    const isExternal = currentEntity.isExternal;
                    processingStore.setLoading(true);
                    if (isExternal) {
                        if (userStore.currentSyncMode.mode === LDAP) {
                            aclStore.resetSharesRootAccess();
                            self.storesWithSameLdap.map(store => store.aclStore.resetSharesRootAccess());
                        } else {
                            aclStore.resetSharesRootAccess();
                            self.storesWithSameAd.map(store => store.aclStore.resetSharesRootAccess());
                        }
                    }

                    aclStore.resetSharesRootAccess();
                    return true;
                } catch (e) {
                    //handled in aclStore
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }
        };
    });

export default MultiLdapStore;
