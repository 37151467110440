import React from "react";
import { useTranslation } from "react-i18next";
import createFields from "./createFields";
import { observer, useLocalStore } from "mobx-react";
import createForm from "utils/createForm";
import Dialog from "components/Dialog";
import TextInput from "components/MobxForm/TextInput";
import { Grid, InputAdornment, ButtonBase } from "@material-ui/core";
import InputErrorIcon from "assets/InputErrorIcon";
import ViewIcon from "assets/ViewIcon";
import { useStore } from "hooks/useStore";
import { useLoginToNewEvoModal } from "hooks/modals";
import { isEnterPressed } from "utils/handlers/isEnterPressed";

const LoginToMultipleEvo = ({ ip }) => {
    const { t } = useTranslation();
    const {
        store: { multipleEvosStore },
    } = useStore();
    const loginToNewEvoModal = useLoginToNewEvoModal();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        inProgress: false,
        showPassword: false,
        showErrorText: false,
    }));

    const loginToNewEvo = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;

        state.inProgress = true;

        const res = await multipleEvosStore.loginToMultipleEvo(
            {
                username: state.form.$("username").value,
                password: state.form.$("password").value,
                ip: state.form.$("ip").value,
            },
            { userTryingConnect: true }
        );

        state.inProgress = false;

        if (res) {
            loginToNewEvoModal.close();
        }

        return res;
    };

    const updateForm = () => {
        state.form.$("ip").set("default", ip || "");
        state.form.reset();
        state.form.fields.forEach((field) => field.resetValidation());
    };

    const handleShowPassword = () => {
        state.showPassword = true;
    };

    const handleHidePassword = () => {
        state.showPassword = false;
    };

    const toggleShowErrorText = () => {
        state.showErrorText = !state.showErrorText;
    };

    const onKeyEnterPressed = (event) => {
        if (isEnterPressed(event)) {
            return loginToNewEvo(event);
        }
    };

    return (
        <Dialog
            onClose={loginToNewEvoModal.close}
            open={loginToNewEvoModal.isOpen}
            title={t("login.multiple.title")}
            submitBtnLabel={t("login.button.login")}
            onSubmit={loginToNewEvo}
            onEnter={updateForm}
            inProgress={state.inProgress}
            onKeyPress={onKeyEnterPressed}
        >
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    <TextInput field={state.form.$("ip")} placeholder={"IP"} nonEditable disabled />
                </Grid>
                <Grid item>
                    <TextInput field={state.form.$("username")} placeholder={"User"} />
                </Grid>
                <Grid item>
                    <TextInput
                        field={state.form.$("password")}
                        placeholder={"Password"}
                        type={state.showPassword ? "text" : "password"}
                        showErrorText={state.showErrorText}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {state.form.$("password").value && (
                                        <ButtonBase onMouseDown={handleShowPassword} onMouseUp={handleHidePassword}>
                                            <ViewIcon />
                                        </ButtonBase>
                                    )}
                                    {state.form.$("password").error && (
                                        <ButtonBase onClick={toggleShowErrorText}>
                                            <InputErrorIcon />
                                        </ButtonBase>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default observer(LoginToMultipleEvo);
