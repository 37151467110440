import { makeStyles } from "@material-ui/core/styles";

import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => ({
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 100,
        height: 100,
        borderRadius: "50%",
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.15),
        marginBottom: theme.spacing(9),
    },
    icon: {
        width: 56,
        height: 56,
    },
    title: {
        marginBottom: theme.spacing(6),
    },
    progressBar: {
        width: "100%",
        marginBottom: theme.spacing(6),
    },
    subtitle: {
        fontSize: 16,
    },
}));
