import React from "react";
import { useLocalStore, useObserver } from "mobx-react";
import createForm from "utils/createForm";
import { useStyles } from "./ExcludedExtensionsDialog.styles";
import { Grid, Typography, Button, Divider, DialogTitle, ButtonBase } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import Dialog from "components/Dialog";
import TrashIcon from "assets/TrashIcon";
import CloseIcon from "assets/CloseIcon";
import { useTranslation } from "react-i18next";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";

const EXCLUDED_EXTENSIONS_DIALOG_ID_PREFIX = `exclude_extensions_dialog`;

const ExcludedExtensionsDialog = ({ open, onClose, submit, shareName, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${EXCLUDED_EXTENSIONS_DIALOG_ID_PREFIX}` : EXCLUDED_EXTENSIONS_DIALOG_ID_PREFIX;
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { indexingConfigStore },
    } = useStore();

    const state = useLocalStore(() => ({
        inProgress: false,
        form: createForm({
            fields: createFields(),
            options: {
                validateOnBlur: false,
            },
        }),
        extensionsList: [],
        excludedExtensionsList: [],
        isSubmitted: false,
    }));

    const unsavedChangesModal = useModal();

    const addExtension = (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        state.extensionsList.unshift(state.form.$("extension").value);
        state.form.clear();
    };

    const removeExtension = (index) => () => {
        state.extensionsList.splice(index, 1);
    };

    const onSubmit = async () => {
        const res = await submit(state.extensionsList.map((el) => el));
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    const onEnter = () => {
        state.extensionsList = [];
        state.excludedExtensionsList = indexingConfigStore.mediaProxyShareParams?.get(shareName) || [];
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.inProgress = false;
        if (state.excludedExtensionsList?.extensionBlackList?.length) {
            state.extensionsList = [...state.excludedExtensionsList.extensionBlackList];
        }
        state.form.$("extension").clear();
    };

    const closeIfNeeded = () => {
        const blackList = state.excludedExtensionsList?.extensionBlackList || [];
        if (state.extensionsList?.join() === blackList.join() || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            title={t("slingshot.index_proxy.excluded_extensions.title", { shareName: shareName })}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            open={open}
            withoutPaddings
            onEnter={onEnter}
            onClose={closeIfNeeded}
            onSubmit={onSubmit}
            inProgress={state.inProgress}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary" id={`${idPrefix}_close`}>
                    {t("common.button.cancel")}
                </Button>
            }
            header={
                <>
                    <DialogTitle>
                        <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container>
                            <Grid item>
                                {t("slingshot.index_proxy.excluded_extensions.title", {
                                    shareName: shareName,
                                })}
                            </Grid>
                            <Grid item>
                                <ButtonBase onClick={closeIfNeeded} id={`${idPrefix}_icon_close`}>
                                    <CloseIcon />
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Divider />
                    <DialogTitle>
                        <Grid container wrap={"nowrap"} spacing={2}>
                            <Grid item>
                                <Typography className={classes.labelText} noWrap>
                                    {t("slingshot.index_proxy.excluded_extensions.label")}
                                </Typography>
                            </Grid>
                            <Grid container item spacing={4} alignItems={"center"} wrap={"nowrap"} justify={"flex-end"}>
                                <Grid item>
                                    <TextInput field={state.form.$("extension")} id={`${idPrefix}_extension`} />
                                </Grid>

                                <Grid item>
                                    <Button
                                        onClick={addExtension}
                                        variant={"contained"}
                                        color={"primary"}
                                        id={`${idPrefix}_add_extension`}
                                    >
                                        {t("common.button.add")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                </>
            }
        >
            <Grid container>
                {!!state.extensionsList.length && (
                    <Grid className={classes.itemContainer}>
                        <Typography>{t("slingshot.index_proxy.excluded_extensions.caution")}</Typography>
                    </Grid>
                )}
                {state.extensionsList.map((item, index) => (
                    <Grid
                        container
                        wrap={"nowrap"}
                        alignItems={"center"}
                        key={Math.floor(Math.random() * Date.now())}
                        className={classes.itemContainer}
                    >
                        <Grid item xs={11}>
                            <Typography>{item}</Typography>
                        </Grid>
                        <Grid item container xs={1} alignItems={"center"} justify={"flex-end"}>
                            <ButtonBase onClick={removeExtension(index)} id={`${idPrefix}_remove_extension_${index}`}>
                                <TrashIcon />
                            </ButtonBase>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default ExcludedExtensionsDialog;
