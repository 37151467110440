import { types } from "mobx-state-tree";

import DriveInfo from "api/pool/Types/DriveInfo";

const GetExpandingDrivesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(DriveInfo)
});

export default GetExpandingDrivesResult;
