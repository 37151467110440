import { types } from "mobx-state-tree";
import AutomationType from "./AutomationType";

const AutomationsResult = types.model({
    json: types.maybe(
        types.model({
            total: types.maybe(types.number),
            offset: types.maybe(types.number),
            limit: types.maybe(types.number),
            automations: types.maybe(types.array(AutomationType))
        })
    )
});

export default AutomationsResult;
