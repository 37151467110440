import { types } from "mobx-state-tree";

import BackupLocation from "api/system_components/backup_schedule/Types/BackupLocation";
import BackupSchedule from "api/system_components/backup_schedule/Types/BackupSchedule";

const BackupSettings = types.model({
    location: types.maybe(BackupLocation),
    schedule: types.array(BackupSchedule),
    deletePeriodSec: types.number
});

export default BackupSettings;
