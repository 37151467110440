import { makeStyles } from "@material-ui/core/styles";

const BREADCRUMBS_FONT = {
    fontSize: 16,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "0.2px"
};

const DIALOG_MIN_HEIGHT = 568;

export const useStyles = makeStyles(theme => ({
    dialogTitle: {
        paddingBottom: theme.spacing(3)
    },
    dialogPaper: {
        minHeight: DIALOG_MIN_HEIGHT
    },
    parentName: {
        "&& ": {
            ...BREADCRUMBS_FONT,
            color: theme.palette.primary.main,
            opacity: 0.4
        }
    },
    folderBrowserLabel: {
        "&& ": {
            ...BREADCRUMBS_FONT,
            color: theme.palette.primary.main,
            opacity: 1
        }
    },
    parentBreadcrumbs: {
        paddingTop: theme.spacing(4)
    },
    navBreadcrumbs: {
        width: "100%",
        padding: theme.spacing(4, 6)
    },
    navItem: {
        "&:hover": {
            cursor: "pointer"
        },
        "& p": {
            ...BREADCRUMBS_FONT,
            opacity: 0.4,
            color: theme.palette.primary.main
        },
        "&:last-child p": {
            opacity: 1
        }
    },
    folderRow: {
        padding: theme.spacing(3, 0),
        "&:hover": {
            cursor: "pointer"
        }
    }
}));
