import React from "react";

const DisksMinusIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="disksMinusIcon_a" x1="31.267%" x2="100%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#3C1EFF" />
                    <stop offset="100%" stopColor="#3A98FF" stopOpacity=".91" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(0 1)">
                    <rect width="19" height="17" x=".5" y="2.5" fill="#6CB6FF" stroke="#A4E1FF" rx="2" />
                    <rect width="19" height="19" x="2.5" y="1.5" fill="#6CB6FF" stroke="#A4E1FF" rx="2" />
                    <rect width="19" height="21" x="4.5" y=".5" fill="#85C3FF" stroke="#B2E1FE" rx="2" />
                    <path fill="#B2E1FE" d="M5 17H23V18H5z" />
                    <circle cx="14" cy="9" r="6.5" fill="url(#disksMinusIcon_a)" stroke="#A4E1FF" />
                    <circle cx="14" cy="9" r="2" fill="#A4E1FF" />
                    <g>
                        <path
                            fill="#FFF"
                            d="M6.5 1c.68.453.863 1.37.41 2.05-.054.08-.116.156-.185.225l-4.11 4.11c-.235.235-.615.235-.85 0-.182-.182-.227-.461-.111-.692l2.54-5.08C4.587.825 5.543.507 6.329.9c.059.03.116.062.171.099z"
                            transform="rotate(180 7 9.5)"
                        />
                        <path
                            fill="#1E90FF"
                            d="M5.5 3.5c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z"
                            transform="rotate(180 7 9.5)"
                        />
                    </g>
                    <circle cx="7" cy="3" r="1" fill="#1E90FF" />
                </g>
                <g transform="translate(12 12)">
                    <circle cx="6" cy="6" r="5.5" fill="#7666FF" stroke="#9F94FF" />
                    <rect width="8" height="2" x="2" y="5" fill="#FFF" rx="1" />
                </g>
            </g>
        </svg>
    );
};

export default DisksMinusIcon;
