import React from "react";

const CheckSmallIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" {...props}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <circle cx="8" cy="8" r="8" fill="#1E90FF" />
                        <path stroke="#FFF" strokeLinecap="round" strokeWidth="2" d="M4.111 8l2.8 2.5 4.7-5" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default CheckSmallIcon;
