import { types } from "mobx-state-tree";

import File from "api/file/Types/File";

const FilesPage = types.model({
    data: types.array(File),
    total: types.number
});

export default FilesPage;
