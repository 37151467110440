import { types } from "mobx-state-tree";

import UsedSpaceEventsSettings from "api/events/Types/UsedSpaceEventsSettings";

const UsedSpaceEventsSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(UsedSpaceEventsSettings)
});

export default UsedSpaceEventsSettingsResult;
