import config from "config";
import { IEC_STANDARD, JEDEC_STANDARD, SIZE_ARRAY } from "const/diskSizeConst";
import fileSize from "filesize";

export const convertBytesToSizeUnit = ({ bytes, exponent, unit, round = 1, ...rest }) => {
    const base = config.decimalSizeUnits ? 10 : 2;
    const standard = config.decimalSizeUnits ? JEDEC_STANDARD : IEC_STANDARD;
    exponent = exponent || unitToExponent(unit);
    return fileSize(bytes, { base, standard, exponent, round, ...rest });
};

export const unitToExponent = unit => {
    return Math.floor(SIZE_ARRAY.findIndex(value => value.unit === unit) / 2);
};
