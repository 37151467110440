import { useEffect, useRef } from "react";

/**
 * Hook to add and remove an event handler on an element
 * @param {keyof WindowEventMap} eventType
 * @param {EventListenerOrEventListenerObject} callback
 * @param {EventTarget} element
 * @example
 * useEventListener("change", e => setIsMatch(e.matches), mediaQueryList);
 * useEventListener("click", e => {console.log(e)}, ref);
 */
export const useEventListener = (eventType, callback, element = window) => {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        if (element == null) return;
        /**  @type {EventListenerOrEventListenerObject} */
        const handler = e => callbackRef.current(e);
        element.addEventListener(eventType, handler);
        return () => element.removeEventListener(eventType, handler);
    }, [eventType, element]);
};
