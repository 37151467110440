import { types } from "mobx-state-tree";

import Request from "api/Request";
import BondingParameters from "api/net/Types/BondingParameters";

const SetBondingParameters = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "set_bonding_parameters",
    arguments: types.maybe(BondingParameters)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetBondingParameters;
