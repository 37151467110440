import React from "react";
import { Grid } from "@material-ui/core";
import MainTemplate from "components/MainTemplate";
import HeaderTitle from "components/HeaderTitle";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react";
import FilesIcon from "assets/FilesIcon";
import { FILE_NAVIGATOR_LOG } from "const/shortcutNameConst";
import FileNavigatorLogCard from "./components/FileNavigatorLogCard";

const FileNavigatorLog = () => {
    const { t } = useTranslation();

    return useObserver(() => (
        <MainTemplate
            headerTitle={
                <HeaderTitle icon={<FilesIcon />} title={t("file_navigator_log.title")} shortcutName={FILE_NAVIGATOR_LOG} />
            }
        >
            <Grid item container direction={"column"} spacing={6} justify={"flex-start"}>
                <Grid container item direction="column" spacing={6}>
                    <Grid item>
                        <FileNavigatorLogCard />
                    </Grid>
                </Grid>
            </Grid>
        </MainTemplate>
    ));
};

export default FileNavigatorLog;
