import { types } from "mobx-state-tree";

const CsrInfo = types.model({
    commonName: types.string,
    organization: types.string,
    department: types.string,
    country: types.string,
    stateName: types.string,
    city: types.string
});

export default CsrInfo;
