import { types } from "mobx-state-tree";

const PluginMetadata = types.model({
    pluginId: types.string,
    pluginVersion: types.string,
    name: types.string,
    description: types.string,
    hasDataStore: types.boolean,
    hasBackupsCapability: types.boolean,
    eula: types.string
});

export default PluginMetadata;
