import { types } from "mobx-state-tree";

import Request from "api/Request";
import ImportDatabaseArguments from "api/system_components/sharebrowser_server/Types/ImportDatabaseArguments";

const ImportDatabase = Request.props({
    path: "/com/studionetworksolutions/evo/manage/SharebrowserServer",
    method: "import_database",
    arguments: types.maybe(ImportDatabaseArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ImportDatabase;
