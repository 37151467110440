import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";
import { INFO, WARNING, ERROR } from "const/notificationConst";

export const useStyles = makeStyles(theme => {
    const getColor = (eventLevel, isBatch) => {
        switch (eventLevel) {
            case INFO:
                return theme.palette.primary.main;
            case WARNING:
                return theme.palette.warning.main;
            case ERROR:
                return isBatch ? theme.palette.warning.main : theme.palette.error.main;
            default:
                return theme.palette.primary.main;
        }
    };

    return {
        container: {
            padding: theme.spacing(4, 6),
            margin: 0,
            width: "100%"
        },
        time: {
            fontSize: 14,
            fontWeight: "bold",
            letterSpacing: -0.12,
            color: ({ eventLevel, isBatch }) => getColor(eventLevel, isBatch)
        },
        spacing: {
            flex: 1
        },
        icon: {
            "& g": {
                fill: ({ eventLevel }) => getColor(eventLevel)
            }
        },
        alert: {
            backgroundColor: hexToRgba(theme.palette.warning.main, 0.1),
            borderRadius: 8,
            "& .MuiAlert-message": {
                padding: 0
            },
            "& .MuiTypography-root": {
                color: theme.palette.warning.main,
                opacity: 1
            },
            "& path": {
                fill: theme.palette.warning.main
            }
        }
    };
});
