import React from "react";
import MainTemplate from "components/MainTemplate";
import AlertsCard from "./components/AlertsCard";
import { useTranslation } from "react-i18next";
import HeaderTitle from "components/HeaderTitle";
import SpeakerIcon from "assets/SpeakerIcon";
import { Grid } from "@material-ui/core";
import EmailConfigurationCard from "./components/EmailConfigurationCard";
import ManageEventsCard from "./components/ManageEventsCard";
import ManagementInformationBaseCard from "./components/ManagementInformationBaseCard";
import { ALERTS } from "const/shortcutNameConst";
import AgentConfigurationCard from "./components/AgentConfigurationCard";
import SnmpServiceConfigurationCard from "./components/SnmpServiceConfigurationCard";
import DiskUsageThresholdCard from "./components/DiskUsageThreshold/DiskUsageThresholdCard/DiskUsageThresholdCard";
import AudioAlarmManagerCard from "./components/AudioAlarmManagerCard/AudioAlarmManagerCard";

const Alerts = () => {
    const { t } = useTranslation();
    return (
        <MainTemplate headerTitle={<HeaderTitle icon={<SpeakerIcon />} title={t("alerts.title")} shortcutName={ALERTS} />}>
            <Grid container wrap={"nowrap"} spacing={6}>
                <Grid item container direction={"column"} wrap={"nowrap"} spacing={6} xs={12} lg={6}>
                    <Grid item>
                        <AlertsCard />
                    </Grid>
                    <Grid item>
                        <DiskUsageThresholdCard />
                    </Grid>
                    <Grid item>
                        <EmailConfigurationCard />
                    </Grid>
                </Grid>
                <Grid item container direction={"column"} wrap={"nowrap"} spacing={6} xs={12} lg={6}>
                    <Grid item>
                        <AudioAlarmManagerCard />
                    </Grid>
                    <Grid item>
                        <ManageEventsCard />
                    </Grid>
                    <Grid item>
                        <AgentConfigurationCard />
                    </Grid>
                    <Grid item>
                        <ManagementInformationBaseCard />
                    </Grid>
                    <Grid item>
                        <SnmpServiceConfigurationCard />
                    </Grid>
                </Grid>
            </Grid>
        </MainTemplate>
    );
};

export default Alerts;
