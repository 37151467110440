import React from "react";
import { Button, Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TextInput from "components/MobxForm/TextInput";
import Select from "components/MobxForm/Select";
import { useLocalStore, useObserver } from "mobx-react";
import { useStyles } from "./EditShareDialog.styles";
import ProtocolController from "../ProtocolController";
import AllowSmbAndAfpController from "../AllowSmbAndAfpController";
import AccessTypeController from "../AccessTypeController";
import ModeController from "../ModeController";
import createFields from "./createFields";
import createForm from "utils/createForm";
import config from "config";
import byteSize from "byte-size";
import ChevronUpIcon from "assets/ChevronUpIcon";
import clsx from "clsx";
import { GB_METRIC, GiB_IEC, PB_METRIC, PiB_IEC, SIZE_ARRAY, TB_METRIC, TiB_IEC } from "const/diskSizeConst";
import Dialog from "components/Dialog";
import HideOverlay from "components/HideOverlay";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import Switch from "components/MobxForm/Switch";
import { ADMIN, SECONDARY_ADMIN } from "const/userRolesConst";
import ConfirmationDialog from "components/ConfirmationDialog";
import AnimatedSubmitButton from "components/AnimatedSubmitButton/AnimatedSubmitButton";
import { equalsIgnoreCase } from "utils/equalsIgnoreCase";
import useStoreByIp from "hooks/useStoreByIp";
import { useModalCombine } from "hooks/useModalCombine";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import localizations from "./EditShareDialog.localizations";
import LocalizationTypography from "components/LocalizationTypography/LocalizationTypography";

const EDIT_SHARE_DIALOG_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_edit_share_dialog`;

const EditShareDialog = ({ pool, open, onClose, afterSubmitCallback, ip }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { shareStore, authStore, recycleBinStore, sessionStore } = useStoreByIp({ ip });
    const share = shareStore.currentEditableShare;
    const fields = createFields();
    const state = useLocalStore(() => ({
        form: createForm({
            fields,
        }),
        masksForm: createForm({ fields: [] }),
        advancedExpanded: false,
        isTrashCleared: false,
        emptyTrashInProgress: false,
        editShareInProgress: false,
        isSubmitted: false,
        get isSaveButtonDisabled() {
            if (shareStore.currentEditableShare && this.masksForm) {
                if (shareStore.currentEditableShare.masks.length > this.masksForm.size) {
                    return false;
                }
            }
            return this.form.isDefault && this.masksForm.isDefault;
        },
    }));
    const { unsavedChangesModal, needEmptyTrashModal, activeSessionExistModal } = useModalCombine([
        "unsavedChangesModal",
        "needEmptyTrashModal",
        "activeSessionExistModal",
    ]);
    const isProjectSharingEnabled = () => {
        return (
            share.attributes.shareType === "smb" && share.attributes.locking === "project" && share.location.pool !== "CLUSTER"
        );
    };

    const onEnter = () => {
        state.form.$("subdirectory").set("default", share.location.directory);
        state.form.$("shareName").set("default", share.attributes.shareName);
        state.form.$("shareComment").set("default", share.attributes.comment);
        state.form.$("quotaValue").set("default", byteSize(share.attributes.quota).value);
        state.form.$("quotaMetric").set("default", getQuotaDefaultUnit());
        state.form.$("recycleBinEnabled").set("default", share.attributes.trashEnabled);
        state.form.$("browseable").set("default", share.attributes.browseable);
        state.form.$("protocolValue").set("default", share.attributes.shareType);
        state.form.$("accessType").set("default", share.attributes.accessType);
        state.form.$("mode").set("default", share.attributes.locking);
        state.form.$("projectSharingEnabled").set("default", isProjectSharingEnabled());
        state.form.reset();
        state.masksForm.reset();
        state.advancedExpanded = false;
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };

    const getQuotaDefaultUnit = () => {
        if (share.attributes.quota === 0) {
            return config.decimalSizeUnits ? GB_METRIC.value : GiB_IEC.value;
        }
        return SIZE_ARRAY.find((size) => size.unit === byteSize(share.attributes.quota).unit).value;
    };

    const getQuotaUnitsOptions = () => {
        return config.decimalSizeUnits
            ? [
                { label: GB_METRIC.unit, value: PB_METRIC.value },
                { label: TB_METRIC.unit, value: PB_METRIC.value },
                { label: PB_METRIC.unit, value: PB_METRIC.value },
            ]
            : [
                { label: GiB_IEC.unit, value: GiB_IEC.value },
                { label: TiB_IEC.unit, value: TiB_IEC.value },
                { label: PiB_IEC.unit, value: PiB_IEC.value },
            ];
    };

    const editShareImpl = async () => {
        activeSessionExistModal.close();
        state.editShareInProgress = true;
        const res = !!(await shareStore.editShare({
            shareName: share.attributes.shareName,
            attributes: {
                shareName: state.form.$("shareName").value,
                comment: state.form.$("shareComment").value,
                quota: Math.floor(state.form.$("quotaValue").value * state.form.$("quotaMetric").value),
                accessType: state.form.$("accessType").value,
                locking: state.form.$("mode").value,
                trashEnabled: state.form.$("recycleBinEnabled").value,
                browseable: state.form.$("browseable").value,
            },
            masks: state.masksForm.map((field) => field.value),
        }));
        state.editShareInProgress = false;
        if (res) {
            state.isSubmitted = true;
            setTimeout(onClose, 1000);
            afterSubmitCallback && afterSubmitCallback();
        }
    };

    const checkActiveSessions = async () => {
        needEmptyTrashModal.close();

        if (!equalsIgnoreCase(state.form.$("shareName").value, share.attributes?.shareName)) {
            // Check active sessions
            const res = !!(await sessionStore.fetchNasSessions());

            if (
                res &&
                sessionStore.nasSessions?.length > 0 &&
                sessionStore.nasSessions.find((s) => s?.shareName === share.attributes?.shareName)
            ) {
                activeSessionExistModal.open();
                return;
            }
        }

        editShareImpl();
    };

    const emptyTrash = async () => {
        state.emptyTrashInProgress = true;
        const res = !!(await recycleBinStore.emptyTrash({
            shareName: share?.attributes.shareName,
        }));
        state.emptyTrashInProgress = false;
        if (res) {
            state.isTrashCleared = true;
            checkActiveSessions();
        }
        return res;
    };

    const submit = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;

        if (
            shareStore?.trashShares?.length &&
            share?.attributes?.trashEnabled &&
            !state.form.$("recycleBinEnabled").value &&
            (authStore.role === ADMIN || authStore.role === SECONDARY_ADMIN)
        ) {
            needEmptyTrashModal.open();
            state.isTrashCleared = false;
            return;
        }

        checkActiveSessions();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const isUSBPool = equalsIgnoreCase(pool, "USB");

    const isProjectLockingConfigurable = () => {
        return state.form.$("protocolValue").value !== "internal" && !isUSBPool;
    };

    const shareName = shareStore.currentShareNameWithPrefix || shareStore.currentCheckedShareNameWithPrefix || "Unknown";

    return useObserver(() => (
        <Dialog
            title={t("share.edit_share.title", {
                name: shareName,
            })}
            open={open}
            onClose={closeIfNeeded}
            onEnter={onEnter}
            submitBtn={
                <AnimatedSubmitButton
                    label={t("common.button.save")}
                    inProgress={state.editShareInProgress}
                    isSubmitted={state.isSubmitted}
                    submit={submit}
                    id={`${EDIT_SHARE_DIALOG_ID_PREFIX}_save`}
                />
            }
            buttons={
                <Button variant={"contained"} color={"secondary"} onClick={onClose} id={`${EDIT_SHARE_DIALOG_ID_PREFIX}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
            maxWidth={"md"}
        >
            <ConfirmationDialog
                title={t("share.edit_share.empty_trash.title")}
                open={needEmptyTrashModal.isOpen}
                onClose={checkActiveSessions}
                onConfirm={emptyTrash}
                isSubmitted={state.isTrashCleared}
                inProgress={state.emptyTrashInProgress}
                confirmLabel={t("common.button.clear")}
                cancelLabel={t("common.button.no")}
                id={`${EDIT_SHARE_DIALOG_ID_PREFIX}_empty_trash_confirm_dialog`}
            >
                <Typography>{t("share.edit_share.empty_trash.message", { share: shareName })}</Typography>
            </ConfirmationDialog>

            <ConfirmationDialog
                title={t("common.warning_dialog.title")}
                open={activeSessionExistModal.isOpen}
                onClose={activeSessionExistModal.close}
                onConfirm={editShareImpl}
                confirmLabel={t("common.button.rename")}
                cancelLabel={t("common.button.no")}
                id={`${EDIT_SHARE_DIALOG_ID_PREFIX}_edit_share_confirm_dialog`}
            >
                <LocalizationTypography paragraphs={localizations.renameActiveShareConfirm.paragraphs} />
            </ConfirmationDialog>

            <Grid className={classes.row} alignItems={"center"} container>
                <Grid xs={4} item className={classes.rowLabel}>
                    <Typography>{t("share.create_share.name")}</Typography>
                </Grid>
                <Grid xs={8} item>
                    <TextInput disabled={state.form.$("protocolValue").value === "internal"} field={state.form.$("shareName")} />
                </Grid>
            </Grid>
            <Grid className={classes.row} alignItems={"center"} container>
                <Grid xs={4} item className={classes.rowLabel}>
                    <Typography>{t("share.create_share.comment")}</Typography>
                </Grid>
                <Grid xs={8} item>
                    <TextInput field={state.form.$("shareComment")} id={`${EDIT_SHARE_DIALOG_ID_PREFIX}_share_comment`} />
                </Grid>
            </Grid>
            {state.form.$("protocolValue").value === "smb" && (
                <Grid alignItems={"center"} container>
                    <RowControlLabelSwitch
                        control={
                            <Switch field={state.form.$("recycleBinEnabled")} id={`${EDIT_SHARE_DIALOG_ID_PREFIX}_enable_bin`} />
                        }
                        label={t("share.create_share.enable_bin")}
                        lastColumnWidth={"100%"}
                    />
                </Grid>
            )}
            <Grid xs={12} item className={classes.expansionPanelContainer}>
                <Accordion
                    className={classes.expansionPanel}
                    square
                    expanded={state.advancedExpanded}
                    onChange={(event, newExpanded) => {
                        state.advancedExpanded = newExpanded;
                    }}
                >
                    <AccordionSummary
                        className={classes.expansionPanelContent}
                        classes={{ content: classes.expansionPanelContent, expanded: classes.expansionPanelContent }}
                    >
                        <Typography className={classes.expansionLabel}>
                            {t("disks_and_pools.add_disk_dialog.label.advanced")}
                        </Typography>
                        <ChevronUpIcon
                            className={clsx(!state.advancedExpanded && classes.expansionIconClose, classes.expansionIcon)}
                        />
                    </AccordionSummary>
                    <AccordionDetails className={classes.detailsRoot}>
                        <Grid container direction={"column"}>
                            <Grid className={classes.row} alignItems={"center"} container>
                                <Grid xs={4} item>
                                    <Typography>{t("share.create_share.subdirectory")}</Typography>
                                </Grid>
                                <Grid xs={8} item>
                                    <TextInput
                                        disabled
                                        field={state.form.$("subdirectory")}
                                        id={`${EDIT_SHARE_DIALOG_ID_PREFIX}_subdirectory`}
                                    />
                                </Grid>
                            </Grid>
                            <Grid className={classes.row} alignItems={"center"} container>
                                <Grid xs={4} item className={classes.rowLabel}>
                                    <Typography>{t("share.create_share.quota")}</Typography>
                                </Grid>
                                <Grid xs={8} item>
                                    <Grid spacing={2} container>
                                        <Grid xs={8} item>
                                            <TextInput
                                                disabled={isUSBPool}
                                                field={state.form.$("quotaValue")}
                                                id={`${EDIT_SHARE_DIALOG_ID_PREFIX}_quota_value`}
                                            />
                                        </Grid>
                                        <Grid xs={4} item>
                                            <Select
                                                disabled={isUSBPool}
                                                options={getQuotaUnitsOptions()}
                                                field={state.form.$("quotaMetric")}
                                                id={`${EDIT_SHARE_DIALOG_ID_PREFIX}_quota_metric`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <ProtocolController disabled field={state.form.$("protocolValue")} />
                            {state.form.$("protocolValue").value === "smb" && (
                                <HideOverlay>
                                    <AllowSmbAndAfpController
                                        className={classes.allowContainer}
                                        field={state.form.$("smbAndAfp")}
                                        disableAfpOnly={state.form.$("protocolValue").value === "smb"}
                                        disableMixing={state.form.$("protocolValue").value === "smb"}
                                        disabled={isUSBPool}
                                    />
                                </HideOverlay>
                            )}
                            {state.form.$("protocolValue").value === "smb" && !isUSBPool && (
                                <Grid alignItems={"center"} className={classes.row} container>
                                    <RowControlLabelSwitch
                                        control={
                                            <Switch
                                                disabled={isUSBPool}
                                                field={state.form.$("browseable")}
                                                id={`${EDIT_SHARE_DIALOG_ID_PREFIX}_browseable`}
                                            />
                                        }
                                        label={t("share.create_share.browseable")}
                                        lastColumnWidth={"33.333333%"}
                                    />
                                </Grid>
                            )}
                            {state.form.$("protocolValue").value !== "internal" && (
                                <AccessTypeController
                                    field={state.form.$("accessType")}
                                    rwDisabled={
                                        state.form.$("protocolValue").value === "nfs" ||
                                        state.form.$("protocolValue").value === "ftp"
                                    }
                                    rwrdDisabled={state.form.$("protocolValue").value === "ftp"}
                                />
                            )}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {isProjectLockingConfigurable() && (
                <ModeController
                    isMasksEditable={true}
                    mode={state.form.$("mode")}
                    form={state.masksForm}
                    protocol={state.form.$("protocolValue")}
                    pool={pool}
                    projectSharingEnabled={state.form.$("projectSharingEnabled")}
                    ip={ip}
                />
            )}
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default EditShareDialog;
