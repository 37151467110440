import { types } from "mobx-state-tree";

const SnmpServerSettings = types.model({
    port: types.number,
    tcpEnabled: types.boolean,
    udpEnabled: types.boolean,
    securityEnabled: types.boolean,
    securityName: types.string,
    authProtocol: types.string,
    authPassword: types.string,
    privProtocol: types.string,
    privPassword: types.string,
    requiredSecurityLevel: types.string
});

export default SnmpServerSettings;
