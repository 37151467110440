export function isEthernetPortUsedByBridgeOrBond(port, store) {
    const { bonds, bridges } = store.allNetIfaces;
    return (
        port &&
        (port.status === "attached_to_bond" ||
            /(^vs)\d+$/.test(port.status) ||
            bridges.some((bridge) => bridge.interfaces.includes(port?.port)) ||
            bonds.some((bond) => bond.interfaces.includes(port?.port)))
    );
}
