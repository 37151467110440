import React from "react";

const UsersDisabledPic = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" {...props}>
            <defs>
                <linearGradient id="tgm5pgy69a" x1="6.715%" x2="71.161%" y1="-15.973%" y2="100%">
                    <stop offset="0%" stopColor="#D4E2F1"></stop>
                    <stop offset="100%" stopColor="#A9B7D4"></stop>
                </linearGradient>
                <linearGradient id="5fcsf2glrb" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#DCE5F0"></stop>
                    <stop offset="100%" stopColor="#C5D0E7"></stop>
                </linearGradient>
                <linearGradient id="arb8owoj7c" x1="15.207%" x2="67.01%" y1="-15.973%" y2="100%">
                    <stop offset="0%" stopColor="#D4E2F1"></stop>
                    <stop offset="100%" stopColor="#A9B7D4"></stop>
                </linearGradient>
                <linearGradient id="gv4av7xk9d" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#DCE5F0"></stop>
                    <stop offset="100%" stopColor="#C5D0E7"></stop>
                </linearGradient>
                <linearGradient id="64m3hnzyfe" x1="-31.054%" x2="89.626%" y1="-15.973%" y2="100%">
                    <stop offset="0%" stopColor="#D4E2F1"></stop>
                    <stop offset="100%" stopColor="#A9B7D4" stopOpacity="0.91"></stop>
                </linearGradient>
                <linearGradient x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.4"></stop>
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.4"></stop>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="url(#tgm5pgy69a)"
                    d="M84.875 58.25c8.63 0 15.625 6.996 15.625 15.625V84.5a5 5 0 01-5 5H53V77c0-10.355 8.395-18.75 18.75-18.75h13.125zm-7.5-33.75C86.005 24.5 93 31.496 93 40.125c0 8.63-6.996 15.625-15.625 15.625-8.63 0-15.625-6.996-15.625-15.625 0-8.63 6.996-15.625 15.625-15.625z"
                ></path>
                <path
                    fill="url(#5fcsf2glrb)"
                    fillRule="nonzero"
                    d="M84.875 58.25c8.63 0 15.625 6.996 15.625 15.625V84.5a5 5 0 01-5 5H53V77c0-10.355 8.395-18.75 18.75-18.75h13.125zm0 2.5H71.75c-8.846 0-16.042 7.069-16.246 15.866L55.5 77v10h40a2.5 2.5 0 002.493-2.313L98 84.5V73.875c0-7.249-5.876-13.125-13.125-13.125zm-7.5-36.25C86.005 24.5 93 31.496 93 40.125c0 8.5-6.788 15.416-15.24 15.62l-.385.005c-8.63 0-15.625-6.996-15.625-15.625 0-8.5 6.788-15.416 15.24-15.62l.385-.005zm0 2.5c-7.249 0-13.125 5.876-13.125 13.125S70.126 53.25 77.375 53.25 90.5 47.374 90.5 40.125 84.624 27 77.375 27z"
                ></path>
                <path
                    fill="url(#arb8owoj7c)"
                    d="M43.75 53.25c11.046 0 20 8.954 20 20V89.5h-42.5a5 5 0 01-5-5V71.375c0-10.01 8.115-18.125 18.125-18.125h9.375zM40.625 17c9.32 0 16.875 7.555 16.875 16.875S49.945 50.75 40.625 50.75 23.75 43.195 23.75 33.875 31.305 17 40.625 17z"
                ></path>
                <path
                    fill="url(#gv4av7xk9d)"
                    fillRule="nonzero"
                    d="M43.75 53.25c11.046 0 20 8.954 20 20V89.5h-42.5a5 5 0 01-5-5V71.375c0-10.01 8.115-18.125 18.125-18.125h9.375zm0 2.5h-9.375c-8.5 0-15.416 6.788-15.62 15.24l-.005.385V84.5a2.5 2.5 0 002.313 2.493l.187.007h40V73.25c0-9.534-7.625-17.288-17.11-17.496l-.39-.004zM40.625 17c9.32 0 16.875 7.555 16.875 16.875 0 9.19-7.347 16.665-16.488 16.87l-.387.005c-9.32 0-16.875-7.555-16.875-16.875 0-9.19 7.347-16.665 16.488-16.87l.387-.005zm0 2.5c-7.94 0-14.375 6.436-14.375 14.375 0 7.94 6.436 14.375 14.375 14.375C48.565 48.25 55 41.814 55 33.875c0-7.94-6.436-14.375-14.375-14.375z"
                ></path>
                <circle cx="25" cy="78.25" r="15" fill="url(#64m3hnzyfe)"></circle>
                <path
                    fill="#FFF"
                    d="M30.303 72.947a1.25 1.25 0 010 1.767l-3.535 3.535 3.535 3.537a1.25 1.25 0 01-1.767 1.767L25 80.017l-3.537 3.536a1.25 1.25 0 01-1.767-1.767l3.536-3.537-3.536-3.535a1.25 1.25 0 011.767-1.767l3.537 3.535 3.535-3.535a1.25 1.25 0 011.767 0z"
                    opacity="0.8"
                ></path>
            </g>
        </svg>
    );
};

export default UsersDisabledPic;
