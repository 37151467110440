import React from "react";

const SortPassiveIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16" {...props}>
            <path
                fill="#CCDAE7"
                fillRule="evenodd"
                d="M4.416 1.624L8 7H0l3.584-5.376a.5.5 0 01.832 0zm0 12.752L8 9H0l3.584 5.376a.5.5 0 00.832 0z"
            />
        </svg>
    );
};

export default SortPassiveIcon;
