import React from "react";

const CheckboxActiveIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <rect width="24" height="24" fill="#1E90FF" rx="4" />
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M16.804 8.282a1 1 0 0 1 1.477 1.343l-.085.093-7.378 7.158-4.495-4.14a1 1 0 0 1 1.257-1.55l.097.078 3.105 2.86 6.022-5.842z"
                />
            </g>
        </svg>
    );
};

export default CheckboxActiveIcon;
