import i18n from "i18n";

export const GET_BRICKS_FILTER_PEER = "peer";
export const GET_BRICKS_FILTER_VOLUME = "volume";

export const PEER_LOOKUP_MANUAL = "manual";
export const PEER_LOOKUP_AUTOMATIC = "automatic";

export const REPEAT_AT_PERIOD_OPTIONS = {
    disabled: "disabled",
    everyMinute: "every_minute",
    everyFiveMinutes: "every_5_minutes",
    everyHour: "every_hour",
    everyTwoHours: "every_2_hours",
    everyThreeHours: "every_3_hours",
    everyDay: "every_day",
    everyTwoDays: "every_2_days"
};

export const REPEAT_AT_PERIOD_OPTIONS_ARRAY = [
    { value: REPEAT_AT_PERIOD_OPTIONS.disabled, label: i18n.t("cluster.settings.repeat_at_period.label.disabled") },
    { value: REPEAT_AT_PERIOD_OPTIONS.everyMinute, label: i18n.t("cluster.settings.repeat_at_period.label.every_minute") },
    {
        value: REPEAT_AT_PERIOD_OPTIONS.everyFiveMinutes,
        label: i18n.t("cluster.settings.repeat_at_period.label.every_five_minutes")
    },
    { value: REPEAT_AT_PERIOD_OPTIONS.everyHour, label: i18n.t("cluster.settings.repeat_at_period.label.every_hour") },
    { value: REPEAT_AT_PERIOD_OPTIONS.everyTwoHours, label: i18n.t("cluster.settings.repeat_at_period.label.every_two_hours") },
    {
        value: REPEAT_AT_PERIOD_OPTIONS.everyThreeHours,
        label: i18n.t("cluster.settings.repeat_at_period.label.every_three_hours")
    },
    { value: REPEAT_AT_PERIOD_OPTIONS.everyDay, label: i18n.t("cluster.settings.repeat_at_period.label.every_day") },
    { value: REPEAT_AT_PERIOD_OPTIONS.everyTwoDays, label: i18n.t("cluster.settings.repeat_at_period.label.every_two_days") }
];

export const SINGLE_BRICK_MODE_OPTIONS = {
    onePeer: "one_peer",
    onePool: "one_pool",
    oneLogicalDisk: "one_logical_disk"
};

export const SINGLE_BRICK_MODE_OPTIONS_ARRAY = [
    {
        value: SINGLE_BRICK_MODE_OPTIONS.onePeer,
        label: i18n.t("cluster.settings.single_brick_mode.label.one_peer")
    },
    {
        value: SINGLE_BRICK_MODE_OPTIONS.onePool,
        label: i18n.t("cluster.settings.single_brick_mode.label.one_pool")
    },
    {
        value: SINGLE_BRICK_MODE_OPTIONS.oneLogicalDisk,
        label: i18n.t("cluster.settings.single_brick_mode.label.one_logical_disk")
    }
];

export const BRICK_STATUS_OPTIONS = {
    reset: "reset",
    allocated: "allocated",
    notAllocated: "not_allocated",
    dirty: "dirty",
    corrupt: "corrupt"
};

export const BRICK_STATUS_OPTIONS_ARRAY = [
    {
        value: BRICK_STATUS_OPTIONS.reset,
        label: i18n.t("cluster.local_bricks.status.label.reset")
    },
    {
        value: BRICK_STATUS_OPTIONS.allocated,
        label: i18n.t("cluster.local_bricks.status.label.allocated")
    },
    {
        value: BRICK_STATUS_OPTIONS.notAllocated,
        label: i18n.t("cluster.local_bricks.status.label.not_allocated")
    },
    {
        value: BRICK_STATUS_OPTIONS.dirty,
        label: i18n.t("cluster.local_bricks.status.label.dirty")
    },
    {
        value: BRICK_STATUS_OPTIONS.corrupt,
        label: i18n.t("cluster.local_bricks.status.label.corrupt")
    }
];

export const PEER_EXTENDED_STATUS_OPTIONS = {
    localPeer: "local_peer",
    remotePeer: "remote_peer"
};

export const PEER_EXTENDED_STATUS_OPTIONS_ARRAY = [
    { value: PEER_EXTENDED_STATUS_OPTIONS.localPeer, label: i18n.t("cluster.peers.extended_status.local") },
    { value: PEER_EXTENDED_STATUS_OPTIONS.remotePeer, label: i18n.t("cluster.peers.extended_status.remote") }
];

export const PEER_STATUS_OPTIONS = {
    connected: "connected",
    disconnected: "disconnected"
};

export const VOLUME_TYPE_OPTIONS = {
    simple: "simple",
    mirror: "mirror",
    grid: "grid",
    simpleGrid: "simple_grid",
    simpleMirror: "simple_mirror"
};

export const VOLUME_TYPE_OPTIONS_ARRAY = [
    {
        value: VOLUME_TYPE_OPTIONS.simple,
        label: i18n.t("cluster.volume.type.simple")
    },
    {
        value: VOLUME_TYPE_OPTIONS.mirror,
        label: i18n.t("cluster.volume.type.mirror")
    },
    {
        value: VOLUME_TYPE_OPTIONS.grid,
        label: i18n.t("cluster.volume.type.grid")
    },
    {
        value: VOLUME_TYPE_OPTIONS.simpleGrid,
        label: i18n.t("cluster.volume.type.simple_grid")
    },
    {
        value: VOLUME_TYPE_OPTIONS.simpleMirror,
        label: i18n.t("cluster.volume.type.simple_mirror")
    }
];

export const VOLUME_STATUS_OPTIONS = {
    started: "started",
    stopped: "stopped",
    created: "created",
    degraded: "degraded",
    corrupt: "corrupt",
    incomplete: "incomplete",
    incompleteCorrupt: "incomplete_corrupt",
    needCommit: "need_commit",
    migrationInProgress: "migration_in_progress",
    migrationFailed: "migration_failed"
};

export const VOLUME_STATUS_OPTIONS_ARRAY = [
    {
        value: VOLUME_STATUS_OPTIONS.started,
        label: i18n.t("cluster.volume.status.started")
    },
    {
        value: VOLUME_STATUS_OPTIONS.stopped,
        label: i18n.t("cluster.volume.status.stopped")
    },
    {
        value: VOLUME_STATUS_OPTIONS.created,
        label: i18n.t("cluster.volume.status.created")
    },
    {
        value: VOLUME_STATUS_OPTIONS.degraded,
        label: i18n.t("cluster.volume.status.degraded")
    },
    {
        value: VOLUME_STATUS_OPTIONS.corrupt,
        label: i18n.t("cluster.volume.status.corrupt")
    },
    {
        value: VOLUME_STATUS_OPTIONS.incomplete,
        label: i18n.t("cluster.volume.status.incomplete")
    },
    {
        value: VOLUME_STATUS_OPTIONS.incompleteCorrupt,
        label: i18n.t("cluster.volume.status.incomplete_corrupt")
    },
    {
        value: VOLUME_STATUS_OPTIONS.needCommit,
        label: i18n.t("cluster.volume.status.need_commit")
    },
    {
        value: VOLUME_STATUS_OPTIONS.migrationInProgress,
        label: i18n.t("cluster.volume.status.migration_in_progress")
    },
    {
        value: VOLUME_STATUS_OPTIONS.migrationFailed,
        label: i18n.t("cluster.volume.status.migration_failed")
    }
];

export const VOLUME_TYPES_ARRAY = [
    { value: "simple", label: i18n.t("cluster.volume.type_simple") },
    { value: "mirror", label: i18n.t("cluster.volume.type_mirror") },
    { value: "grid", label: i18n.t("cluster.volume.type_grid") }
];

export const VOLUME_REPLICA_ARRAY = [2, 3];

export const VOLUME_DISPERSE_ARRAY = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export const VOLUME_REDUNDANCY_ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const EDIT_VOLUME_MODES = {
    add: "add",
    replace: "replace",
    remove: "remove"
};
