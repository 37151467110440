import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        height: "100%",
        padding: theme.spacing(1),
        width: "100%",
        margin: 0
    },
    title: {
        fontSize: 14,
        fontWeight: 500,
        opacity: 1
    },
    statusText: {
        fontWeight: 500,
        color: ({ isFound }) => (isFound ? theme.palette.success.main : "#FFAC38"),
        opacity: 1
    },
    circularBase: {
        alignSelf: "center",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: theme.spacing(12),
        height: theme.spacing(12),
        "& .MuiCircularProgress-root": {
            position: "absolute"
        },
        "& .MuiCircularProgress-circle": {
            color: theme.palette.success.main
        }
    },
    progressText: {
        fontSize: 14,
        fontWeight: "bold"
    }
}));
