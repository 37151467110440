import { types } from "mobx-state-tree";

const ShareAttributesChangeable = types.model({
    shareName: types.string,
    comment: types.string,
    quota: types.number,
    accessType: types.string,
    locking: types.string,
    trashEnabled: types.boolean,
    browseable: types.boolean
});

export default ShareAttributesChangeable;
