import Request from "api/Request";

const GetLdapStatus = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Users",
    method: "get_ldap_status"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetLdapStatus;
