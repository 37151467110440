import regexValidator from "utils/regexValidator";
import i18n from "i18n";
import emptyValueValidator from "utils/emptyValueValidator";
import rangeValidator from "utils/rangeValidator";
import lengthValidator from "utils/lengthValidator";
import { PORT_MAX, PORT_MIN } from "const/firewallConst";

const createFields = () => {
    return [
        {
            name: "port",
            type: "number",
            validators: [
                emptyValueValidator(i18n.t("alerts.snmp_service_configuration_dialog.empty_port.hint")),
                rangeValidator(
                    PORT_MIN,
                    PORT_MAX,
                    i18n.t("alerts.snmp_service_configuration_dialog.port_number.hint", { min: PORT_MIN, max: PORT_MAX })
                )
            ]
        },
        { name: "transport" },
        { name: "securityLevel" },
        {
            name: "securityName",
            validators: [
                emptyValueValidator(i18n.t("alerts.snmp_service_configuration_dialog.empty_security_name.hint")),
                lengthValidator(
                    0,
                    32,
                    i18n.t("alerts.snmp_service_configuration_dialog.security_name_length.hint", { count: 32 })
                ),
                regexValidator(/^[\w.-]{1,32}$/, i18n.t("alerts.snmp_service_configuration_dialog.security_name.hint"))
            ]
        },
        { name: "authProtocol" },
        {
            name: "authPassword",
            type: "password",
            validators: [
                emptyValueValidator(i18n.t("alerts.snmp_service_configuration_dialog.empty_password.hint")),
                lengthValidator(
                    8,
                    64,
                    i18n.t("alerts.snmp_service_configuration_dialog.password_length.hint", { min: 8, max: 64 })
                ),
                regexValidator(/^[\w.-]{8,64}$/, i18n.t("alerts.snmp_service_configuration_dialog.auth_password.hint"))
            ]
        },
        { name: "privacyProtocol" },
        {
            name: "privacyPassword",
            type: "password",
            validators: [
                emptyValueValidator(i18n.t("alerts.snmp_service_configuration_dialog.empty_password.hint")),
                lengthValidator(
                    8,
                    64,
                    i18n.t("alerts.snmp_service_configuration_dialog.password_length.hint", { min: 8, max: 64 })
                ),
                regexValidator(/^[\w.-]{8,64}$/, i18n.t("alerts.snmp_service_configuration_dialog.privacy_password.hint"))
            ]
        }
    ];
};

export default createFields;
