import { types } from "mobx-state-tree";

import GetRegistrationJsonType from "api/slingshot/storage/Types/GetRegistrationJsonType";

const GetRegistrationResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(GetRegistrationJsonType, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            }
        })
    )
});

export default GetRegistrationResult;
