import Request from "api/Request";

const ListDatabaseBackups = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Slingshot",
    method: "list_database_backups"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ListDatabaseBackups;
