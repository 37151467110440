import { types } from "mobx-state-tree";

import Request from "api/Request";
import NetMetricsSettings from "api/net/Types/NetMetricsSettings";

const SaveNetMetrics = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "save_net_metrics",
    arguments: types.maybe(NetMetricsSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SaveNetMetrics;
