import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./EditPermissionModal.styles";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import TextInput from "components/TextInput";
import Select from "components/MobxForm/Select";
import Checkbox from "components/MobxForm/Checkbox";
import ClearCheckbox from "components/Checkbox";
import {
    DialogTitle,
    ButtonBase,
    Button,
    Typography,
    Divider,
    FormControlLabel,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import CloseIcon from "assets/CloseIcon";
import ChevronDownIcon from "assets/ChevronDownIcon";
import ThinArrowRightIcon from "assets/ThinArrowRightIcon";
import clsx from "clsx";
import { APPLY_TO_OPTIONS, ALL_GROUPS, ADMIN_GROUP, READ_GROUP, WRITE_GROUP } from "const/permissionValues";
import { LOCAL_USERS, EXTERNAL_USERS, LOCAL_GROUPS, EXTERNAL_GROUPS } from "const/userSearchVariants";
import getPermissionApplyToDefault from "utils/getPermissionApplyToDefault";
import createFields from "./createFields";
import createForm from "utils/createForm";
import SearchLine from "../SearchLine";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useModal } from "hooks/useModal";
import { useObserveChangeField } from "hooks/useObserveChangeField";
import { useFileNavigatorStoreCommon } from "pages/FileNavigator/FileNavigator.hooks";
import useStoreByIp from "hooks/useStoreByIp";

const EditPermissionModal = ({ open, onClose, isCreate, viewOnly }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const fileNavigatorStoreCommon = useFileNavigatorStoreCommon();
    const { aclStore, userStore, fileStore } = useStoreByIp({ ip: fileNavigatorStoreCommon.currentEvoIp });

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
            options: {
                validateOnInit: false,
                validateOnBlur: false,
                showErrorsOnReset: false,
            },
        }),
        isSubmitted: false,
        isFieldChanged: false,
        get fullControlCheckboxStatus() {
            if (!ALL_GROUPS.some((permission) => !this.form.$(permission).value)) {
                return "checked";
            } else if (
                ALL_GROUPS.some((permission) => this.form.$(permission).value) &&
                ALL_GROUPS.some((permission) => !this.form.$(permission).value)
            ) {
                return "indeterminate";
            }
            return null;
        },
        get adminCheckboxStatus() {
            if (!ADMIN_GROUP.some((permission) => !this.form.$(permission).value)) {
                return "checked";
            } else if (
                ADMIN_GROUP.some((permission) => this.form.$(permission).value) &&
                ADMIN_GROUP.some((permission) => !this.form.$(permission).value)
            ) {
                return "indeterminate";
            }
            return null;
        },
        get readCheckboxStatus() {
            if (!READ_GROUP.some((permission) => !this.form.$(permission).value)) {
                return "checked";
            } else if (
                READ_GROUP.some((permission) => this.form.$(permission).value) &&
                READ_GROUP.some((permission) => !this.form.$(permission).value)
            ) {
                return "indeterminate";
            }
            return null;
        },
        get writeCheckboxStatus() {
            if (!WRITE_GROUP.some((permission) => !this.form.$(permission).value)) {
                return "checked";
            } else if (
                WRITE_GROUP.some((permission) => this.form.$(permission).value) &&
                WRITE_GROUP.some((permission) => !this.form.$(permission).value)
            ) {
                return "indeterminate";
            }
            return null;
        },
        get isExternal() {
            return this.form.$("type").value === EXTERNAL_USERS || this.form.$("type").value === EXTERNAL_GROUPS;
        },
        get isGroup() {
            return this.form.$("type").value === EXTERNAL_GROUPS || this.form.$("type").value === LOCAL_GROUPS;
        },
    }));
    const unsavedChangesModal = useModal();
    const observerFieldName = useObserveChangeField(state.form.$("name"));

    const onTypeChange = (e) => {
        state.form.$("type").value = e.target.value;
        state.form.$("name").reset();
        state.form.$("domain").reset();
        observerFieldName.reset();
    };

    const onSave = (e) => {
        state.form.onSubmit(e);
        if (observerFieldName.hasChange && isCreate) {
            state.form.$("name").invalidate(t("common.edit_permission_modal.select.error"));
            return;
        }

        if (!state.form.isValid) return;

        const permissions = {};

        ALL_GROUPS.forEach((permission) => {
            permissions[permission] = state.form.$(permission).value;
        });

        if (isCreate) {
            aclStore.acl &&
                aclStore.createAce({
                    domainObject: {
                        id: {
                            name: state.form.$("name").value,
                            domain: state.form.$("domain").value,
                        },
                        isExternal: state.isExternal,
                        isGroup: state.isGroup,
                    },
                    permissions,
                    allow: state.form.$("allow").value,
                    isInherited: false,
                    onlyForTheFirstChildren: state.form.$("onlyForTheFirstChildren").value,
                    appliedTo:
                        fileStore.currentFile?.type === "file"
                            ? APPLY_TO_OPTIONS.folderOnly
                            : APPLY_TO_OPTIONS[state.form.$("appliedTo").value],
                });
        } else {
            if (aclStore.acl) {
                state.form.isDefault && onClose();
                aclStore.updateCurrentAce("permissions", permissions);
                aclStore.updateCurrentAce("allow", state.form.$("allow").value);
                aclStore.updateCurrentAce("onlyForTheFirstChildren", state.form.$("onlyForTheFirstChildren").value);
                aclStore.updateCurrentAce("appliedTo", APPLY_TO_OPTIONS[state.form.$("appliedTo").value]);
            }
        }

        state.isSubmitted = true;

        onClose();
    };

    const onEnter = () => {
        state.isSubmitted = false;

        if (isCreate) {
            state.form.$("name").set("default", "");
            state.form.$("allow").set("default", true);
            state.form.$("appliedTo").set("default", "folderSubfoldersFiles");
            state.form.$("onlyForTheFirstChildren").set("default", false);
            ALL_GROUPS.forEach((permission) => {
                state.form.$(permission).set("default", false);
            });
            userStore.resetSearchByTypeResult();
        } else {
            state.form.$("name").set("default", aclStore.currentAce.domainObject.id.name);
            state.form.$("allow").set("default", aclStore.currentAce.allow);
            state.form.$("appliedTo").set("default", getPermissionApplyToDefault({ ...aclStore.currentAce.appliedTo }));
            state.form.$("onlyForTheFirstChildren").set("default", aclStore.currentAce.onlyForTheFirstChildren);
            ALL_GROUPS.forEach((permission) => {
                state.form.$(permission).set("default", aclStore.currentAce.permissions[permission]);
            });
        }

        state.form.reset();
        observerFieldName.reset();
        unsavedChangesModal.close();
    };

    const closeIfNeeded = () => {
        state.isFieldChanged = false;

        state.form.fields.forEach((field) => {
            if (field.name !== "domain" && !field.isDefault) {
                state.isFieldChanged = true;
            }
        });

        if (!state.isFieldChanged || state.isSubmitted) {
            onClose();
            return;
        }

        unsavedChangesModal.open();
    };

    const getHeader = () => {
        return (
            <>
                <DialogTitle className={classes.dialogTitle}>
                    <Grid container direction={"column"} spacing={4}>
                        <Grid
                            justify={"space-between"}
                            alignContent={"center"}
                            alignItems={"center"}
                            container
                            item
                            wrap={"nowrap"}
                        >
                            <Grid item>
                                {isCreate &&
                                    t("file_navigator.advanced_security_modal.create_permission_modal.title", {
                                        name: fileStore.currentFileNameWithPrefix,
                                    })}
                                {viewOnly &&
                                    t("file_navigator.advanced_security_modal.view_permission_modal.title", {
                                        name: fileStore.currentFileNameWithPrefix,
                                    })}
                                {!isCreate &&
                                    !viewOnly &&
                                    t("file_navigator.advanced_security_modal.edit_permission_modal.title", {
                                        name: fileStore.currentFileNameWithPrefix,
                                    })}
                            </Grid>
                            <Grid item>
                                <CardHeaderControlButton onClick={closeIfNeeded}>
                                    <ButtonBase>
                                        <CloseIcon />
                                    </ButtonBase>
                                </CardHeaderControlButton>
                            </Grid>
                        </Grid>
                        <Grid item container wrap={"nowrap"} spacing={2} alignItems={"center"}>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <Typography variant={"body1"} className={classes.step} color={"primary"}>
                                        {t("users.customize_settings_modal.steps.customize_settings")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <ThinArrowRightIcon />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <Typography
                                        variant={"body1"}
                                        className={clsx(classes.step, classes.activeStep)}
                                        color={"primary"}
                                    >
                                        {isCreate && t("file_navigator.advanced_security_modal.step.create_permissions")}
                                        {viewOnly && t("file_navigator.advanced_security_modal.step.view_permissions")}
                                        {!isCreate &&
                                            !viewOnly &&
                                            t("file_navigator.advanced_security_modal.step.edit_permissions")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogTitle className={classes.inputsContainer}>
                    {isCreate ? (
                        <Grid
                            className={classes.typeSelectRowContainer}
                            container
                            wrap={"nowrap"}
                            alignItems={"center"}
                            justify={"space-between"}
                        >
                            <Grid item xs={3} className={classes.typeSelectContainer}>
                                <Select
                                    field={state.form.$("type")}
                                    options={[
                                        {
                                            label: t("users.common.local_users"),
                                            value: LOCAL_USERS,
                                        },
                                        {
                                            label: t("users.common.external_users"),
                                            value: EXTERNAL_USERS,
                                        },
                                        {
                                            label: t("users.common.local_groups"),
                                            value: LOCAL_GROUPS,
                                        },
                                        {
                                            label: t("users.common.external_groups"),
                                            value: EXTERNAL_GROUPS,
                                        },
                                    ]}
                                    onChange={onTypeChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SearchLine
                                    form={state.form}
                                    onSelectUser={observerFieldName.reset}
                                    ip={fileNavigatorStoreCommon.currentEvoIp}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <RowTextInput
                            control={
                                <TextInput
                                    disabled
                                    value={aclStore.currentAce !== null && aclStore.currentAce.domainObject?.id.name}
                                />
                            }
                            label={
                                aclStore.currentAce !== null && aclStore.currentAce.domainObject.isGroup
                                    ? t("users.edit_create.permission.modal.input.label.group")
                                    : t("users.edit_create.permission.modal.input.label.user")
                            }
                        />
                    )}

                    <RowTextInput
                        control={
                            <Select
                                disabled={viewOnly}
                                field={state.form.$("allow")}
                                options={[
                                    { value: true, label: t("users.edit_create.permission.modal.type_select.variant.allow") },
                                    { value: false, label: t("users.edit_create.permission.modal.type_select.variant.deny") },
                                ]}
                            />
                        }
                        label={t("users.edit_create.permission.modal.input.label.type")}
                    />
                    {fileStore.currentFile?.type === "directory" && (
                        <RowTextInput
                            control={
                                <Select
                                    disabled={viewOnly}
                                    field={state.form.$("appliedTo")}
                                    options={[
                                        {
                                            value: "folderOnly",
                                            label: t("users.edit_create.permission.modal.apply_to_select.variant.folder_only"),
                                        },
                                        {
                                            value: "folderSubfoldersFiles",
                                            label: t(
                                                "users.edit_create.permission.modal.apply_to_select.variant.folder_subfolders_files"
                                            ),
                                        },
                                        {
                                            value: "folderSubfolders",
                                            label: t(
                                                "users.edit_create.permission.modal.apply_to_select.variant.folder_subfolders"
                                            ),
                                        },
                                        {
                                            value: "subfoldersFiles",
                                            label: t(
                                                "users.edit_create.permission.modal.apply_to_select.variant.subfolders_files"
                                            ),
                                        },
                                        {
                                            value: "subfolders",
                                            label: t("users.edit_create.permission.modal.apply_to_select.variant.subfolders"),
                                        },
                                        {
                                            value: "files",
                                            label: t("users.edit_create.permission.modal.apply_to_select.variant.files"),
                                        },
                                        {
                                            value: "folderFiles",
                                            label: t("users.edit_create.permission.modal.apply_to_select.variant.folder_files"),
                                        },
                                    ]}
                                />
                            }
                            label={t("users.edit_create.permission.modal.input.label.apply_to")}
                        />
                    )}
                </DialogTitle>
                <Divider />
                <DialogTitle className={classes.checkboxContainer}>
                    <FormControlLabel
                        control={
                            <ClearCheckbox
                                disabled={viewOnly}
                                onChange={onFullControlChange}
                                indeterminate={state.fullControlCheckboxStatus === "indeterminate"}
                                checked={state.fullControlCheckboxStatus === "checked"}
                            />
                        }
                        label={
                            <Typography variant={"body1"}>
                                {t("users.edit_create.permission.modal.checkbox.label.full_control")}
                            </Typography>
                        }
                    />
                </DialogTitle>
            </>
        );
    };

    const onFullControlChange = () => {
        switch (state.fullControlCheckboxStatus) {
            case "checked":
            case "indeterminate":
                ALL_GROUPS.forEach((permission) => state.form.$(permission).set("value", false));
                break;
            default:
                ALL_GROUPS.forEach((permission) => state.form.$(permission).set("value", true));
                break;
        }
    };
    const onAdminChange = () => {
        switch (state.adminCheckboxStatus) {
            case "checked":
            case "indeterminate":
                ADMIN_GROUP.forEach((permission) => state.form.$(permission).set("value", false));
                break;
            default:
                ADMIN_GROUP.forEach((permission) => state.form.$(permission).set("value", true));
                break;
        }
    };
    const onReadChange = () => {
        switch (state.readCheckboxStatus) {
            case "checked":
            case "indeterminate":
                READ_GROUP.forEach((permission) => state.form.$(permission).set("value", false));
                break;
            default:
                READ_GROUP.forEach((permission) => state.form.$(permission).set("value", true));
                break;
        }
    };
    const onWriteChange = () => {
        switch (state.writeCheckboxStatus) {
            case "checked":
            case "indeterminate":
                WRITE_GROUP.forEach((permission) => state.form.$(permission).set("value", false));
                break;
            default:
                WRITE_GROUP.forEach((permission) => state.form.$(permission).set("value", true));
                break;
        }
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return useObserver(() => (
        <Dialog
            PaperProps={{ className: classes.dialog }}
            open={open}
            onClose={closeIfNeeded}
            withoutPaddings
            onEnter={onEnter}
            header={getHeader()}
            submitBtn={
                viewOnly ? (
                    <Button variant={"contained"} color={"primary"} onClick={onClose}>
                        {t("common.button.back")}
                    </Button>
                ) : (
                    <Button variant={"contained"} color={"primary"} onClick={onSave}>
                        {t("common.button.ok")}
                    </Button>
                )
            }
            additionalActions={
                !fileStore.currentFile?.type === "file" && (
                    <FormControlLabel
                        className={classes.formControlLabel}
                        control={<Checkbox disabled={viewOnly} field={state.form.$("onlyForTheFirstChildren")} />}
                        label={t("users.edit_create.permission.modal.checkbox.label.only_for_the_first_children")}
                    />
                )
            }
            buttons={
                viewOnly ? null : (
                    <Button variant={"contained"} color={"secondary"} onClick={onClose}>
                        {t("common.button.back")}
                    </Button>
                )
            }
        >
            <Grid container direction={"column"} wrap={"nowrap"} className={classes.contentContainer}>
                <Accordion square className={classes.expansionPanel}>
                    <AccordionSummary expandIcon={<ChevronDownIcon />} className={classes.expansionSummary}>
                        <FormControlLabel
                            onClick={stopPropagation}
                            control={
                                <ClearCheckbox
                                    disabled={viewOnly}
                                    onChange={onAdminChange}
                                    checked={state.adminCheckboxStatus === "checked"}
                                    indeterminate={state.adminCheckboxStatus === "indeterminate"}
                                />
                            }
                            label={t("users.edit_create.permission.modal.checkbox.label.administration")}
                        />
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionDetails}>
                        <Grid container direction={"column"} spacing={3} wrap={"nowrap"}>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox disabled={viewOnly} field={state.form.$("changeOwner")} />}
                                    label={t("users.edit_create.permission.modal.checkbox.label.change_owner")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox disabled={viewOnly} field={state.form.$("changePermissions")} />}
                                    label={t("users.edit_create.permission.modal.checkbox.label.change_permissions")}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion square className={classes.expansionPanel}>
                    <AccordionSummary expandIcon={<ChevronDownIcon />} className={classes.expansionSummary}>
                        <FormControlLabel
                            onClick={stopPropagation}
                            control={
                                <ClearCheckbox
                                    disabled={viewOnly}
                                    onChange={onReadChange}
                                    checked={state.readCheckboxStatus === "checked"}
                                    indeterminate={state.readCheckboxStatus === "indeterminate"}
                                />
                            }
                            label={t("users.edit_create.permission.modal.checkbox.label.read")}
                        />
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionDetails}>
                        <Grid container direction={"column"} spacing={3} wrap={"nowrap"}>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox disabled={viewOnly} field={state.form.$("traverseFoldersExecuteFile")} />}
                                    label={t("users.edit_create.permission.modal.checkbox.label.traverse_folders_execute_file")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox disabled={viewOnly} field={state.form.$("listFoldersReadData")} />}
                                    label={t("users.edit_create.permission.modal.checkbox.label.list_folders_read_data")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox disabled={viewOnly} field={state.form.$("readAttributes")} />}
                                    label={t("users.edit_create.permission.modal.checkbox.label.read_attributes")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox disabled={viewOnly} field={state.form.$("readExtendedAttributes")} />}
                                    label={t("users.edit_create.permission.modal.checkbox.label.read_extended_attributes")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox disabled={viewOnly} field={state.form.$("readPermissions")} />}
                                    label={t("users.edit_create.permission.modal.checkbox.label.read_permissions")}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion square className={classes.expansionPanel}>
                    <AccordionSummary expandIcon={<ChevronDownIcon />} className={classes.expansionSummary}>
                        <FormControlLabel
                            onClick={stopPropagation}
                            control={
                                <ClearCheckbox
                                    disabled={viewOnly}
                                    onChange={onWriteChange}
                                    checked={state.writeCheckboxStatus === "checked"}
                                    indeterminate={state.writeCheckboxStatus === "indeterminate"}
                                />
                            }
                            label={t("users.edit_create.permission.modal.checkbox.label.write")}
                        />
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionDetails}>
                        <Grid container direction={"column"} spacing={3} wrap={"nowrap"}>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox disabled={viewOnly} field={state.form.$("createFilesWriteData")} />}
                                    label={t("users.edit_create.permission.modal.checkbox.label.create_files_write_data")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox disabled={viewOnly} field={state.form.$("createFoldersAppendData")} />}
                                    label={t("users.edit_create.permission.modal.checkbox.label.create_folders_append_data")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox disabled={viewOnly} field={state.form.$("writeAttributes")} />}
                                    label={t("users.edit_create.permission.modal.checkbox.label.write_attributes")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox disabled={viewOnly} field={state.form.$("writeExtendedAttributes")} />}
                                    label={t("users.edit_create.permission.modal.checkbox.label.write_extended_attributes")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox disabled={viewOnly} field={state.form.$("deleteSubdirsAndFiles")} />}
                                    label={t("users.edit_create.permission.modal.checkbox.label.delete_subdirs_and_files")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox disabled={viewOnly} field={state.form.$("delete")} />}
                                    label={t("users.edit_create.permission.modal.checkbox.label.delete")}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default EditPermissionModal;
