import { Button } from "@material-ui/core";
import { useQuickCreateModal } from "hooks/modals";
import React from "react";
import { useTranslation } from "react-i18next";

const QUICK_CREATE_ID_PREFIX = "quick_create";

const QuickCreate = ({ children, handleClose, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${QUICK_CREATE_ID_PREFIX}` : QUICK_CREATE_ID_PREFIX;
    const modal = useQuickCreateModal({ handleClose });
    const { t } = useTranslation();

    return (
        <>
            <Button variant="contained" color="primary" onClick={modal.open} size={"small"} id={`${idPrefix}_create_new`}>
                {t("common.button.create_new")}
            </Button>
            {children({ isOpen: modal.isOpen, close: modal.close })}
        </>
    );
};

export default QuickCreate;
