import { types } from "mobx-state-tree";

import Request from "api/Request";
import ActivateDeactivateArguments from "api/activation/interfaces/Types/ActivateDeactivateArguments";

const Activate = Request.props({
    path: "/com/studionetworksolutions/evo/activation/NetInterfaces",
    method: "activate",
    arguments: types.maybe(ActivateDeactivateArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default Activate;
