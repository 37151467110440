import React from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { useTranslation } from "react-i18next";
import { useLocalStore, observer } from "mobx-react-lite";
import { Grid, Typography } from "@material-ui/core";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { SLINGSHOT_REPLICATION_LOGS_CARD } from "const/widgetNameConst";
import { STORAGE_DISK, STORAGE_SHARE } from "const/replicationLogConst";
import SetupReplicationLogsDialog from "../SetupReplicationLogsDialog";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { useStore } from "hooks/useStore";
import { useLauncher } from "hooks/useLauncher";
import { addSlashToFolderName } from "../../utils/addSlashToFolderName";

const REPLICATION_LOGS_CARD_ID_PREFIX = `replication_logs_card`;

const ReplicationLogsCard = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${REPLICATION_LOGS_CARD_ID_PREFIX}` : REPLICATION_LOGS_CARD_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { slingshotSettingsStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isDialogOpen: false,
        inProgress: false,
        beginProgress() {
            this.inProgress = true;
        },
        endProgress() {
            this.inProgress = false;
        },
    }));

    const { isError } = useLauncher(
        [
            state.beginProgress,
            slingshotSettingsStore.fetchTransportingConfig,
            slingshotSettingsStore.fetchShares,
            state.endProgress,
        ],
        { ignoreErrors: false, dependencies: [slingshotSettingsStore.isErrorConnectedDBus] }
    );

    const closeDialog = () => {
        state.isDialogOpen = false;
    };
    const openDialog = () => {
        state.isDialogOpen = true;
    };

    const folderName = slingshotSettingsStore.replicationLogStorage?.folder
        ? addSlashToFolderName(slingshotSettingsStore.replicationLogStorage.folder)
        : "";

    return (
        <>
            <SetupReplicationLogsDialog open={state.isDialogOpen} onClose={closeDialog} parentId={idPrefix} />
            <DataReadonlyCard
                control={
                    <MoreMenu>
                        <HomeCardActionMenuItem
                            widget={SLINGSHOT_REPLICATION_LOGS_CARD}
                            homeCardActionMenuItemId={`${idPrefix}_add_to_home`}
                        />
                    </MoreMenu>
                }
                headerDivider
                onClick={openDialog}
                disabled={isError}
                autoHeight
                title={t("slingshot_page.tabs.settings.replication_logs.title")}
            >
                {slingshotSettingsStore.replicationLogStorage ? (
                    <Grid container direction={"column"} spacing={5}>
                        {!slingshotSettingsStore.replicationLogStorage.share ? (
                            <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("slingshot_page.tabs.settings.replication_logs.storage")}</Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <Typography variant="h6">{STORAGE_DISK.label}</Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <>
                                <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                                    <Grid xs={6} item>
                                        <Typography>{t("slingshot_page.tabs.settings.replication_logs.storage")}</Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography variant="h6">{STORAGE_SHARE.label}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                                    <Grid xs={6} item>
                                        <Typography>{t("slingshot_page.tabs.settings.replication_logs.share")}</Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography variant="h6">{slingshotSettingsStore.replicationLogStorage.share}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                                    <Grid xs={6} item>
                                        <Typography>{t("slingshot_page.tabs.settings.replication_logs.path")}</Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography variant="h6">{folderName}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder
                        inProgress={state.inProgress}
                        message={""}
                    />
                )}
            </DataReadonlyCard>
        </>
    );
};

export default observer(ReplicationLogsCard);
