import { flow, getParent, types } from "mobx-state-tree";
import Socket from "websocket";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import { ASC, DESC, CLUSTER_LOCAL_BRICK_PATH, CLUSTER_PEERS_HOST } from "const/sortColumnConst";
import PageQuery from "api/general/Types/PageQuery";
import GetBricks from "api/cluster/Requests/GetBricks";
import GetBricksResult from "api/cluster/Responses/GetBricksResult";
import GetPeers from "api/cluster/Requests/GetPeers";
import GetPeersResult from "api/cluster/Responses/GetPeersResult";
import GetSettings from "api/cluster/Requests/GetSettings";
import ClusterSettings from "api/cluster/Responses/ClusterSettings";
import SetSettings from "api/cluster/Requests/SetSettings";
import GetInterfaces from "api/cluster/Requests/GetInterfaces";
import GetInterfacesResult from "api/cluster/Responses/GetInterfacesResult";
import GetVolumes from "api/cluster/Requests/GetVolumes";
import GetVolumesResult from "api/cluster/Responses/GetVolumesResult";
import GetServices from "api/cluster/Requests/GetServices";
import GetServicesResult from "api/cluster/Responses/GetServicesResult";
import GetBricksPaths from "api/cluster/Requests/GetBricksPaths";
import GetBricksPathsResult from "api/cluster/Responses/GetBricksPathsResult";
import AddBrick from "api/cluster/Requests/AddBrick";
import AddPeer from "api/cluster/Requests/AddPeer";
import EditPeer from "api/cluster/Requests/EditPeer";
import RemoveBrick from "api/cluster/Requests/RemoveBrick";
import ClearBrick from "api/cluster/Requests/ClearBrick";
import ResetBrick from "api/cluster/Requests/ResetBrick";
import VolumeStop from "api/cluster/Requests/VolumeStop";
import VolumeStart from "api/cluster/Requests/VolumeStart";
import VolumeRemove from "api/cluster/Requests/VolumeRemove";
import RebalanceFixLayoutStart from "api/cluster/Requests/RebalanceFixLayoutStart";
import RebalanceStart from "api/cluster/Requests/RebalanceStart";
import RebalanceStatus from "api/cluster/Requests/RebalanceStatus";
import RebalanceStop from "api/cluster/Requests/RebalanceStop";
import RemovePeer from "api/cluster/Requests/RemovePeer";
import UpdateRemoteBricks from "api/cluster/Requests/UpdateRemoteBricks";
import AddVolume from "api/cluster/Requests/AddVolume";
import VolumeAddBricks from "api/cluster/Requests/VolumeAddBricks";
import VolumeRemoveBricks from "api/cluster/Requests/VolumeRemoveBricks";
import VolumeReplaceBricks from "api/cluster/Requests/VolumeReplaceBricks";
import VolumeRemoveBricksTasks from "api/cluster/Requests/VolumeRemoveBricksTasks";
import RemoveBricksTasksResult from "api/cluster/Responses/RemoveBricksTasksResult";
import VolumeRemoveBricksCommit from "api/cluster/Requests/VolumeRemoveBricksCommit";
import VolumeRemoveBricksStop from "api/cluster/Requests/VolumeRemoveBricksStop";
import RebalanceStatusResult from "api/cluster/Responses/RebalanceStatusResult";
import { PEER_EXTENDED_STATUS_OPTIONS } from "const/clusterConst";

const ClusterStore = types
    .model({
        peersStore: types.maybe(GetPeersResult),
        settingsStore: types.maybe(ClusterSettings),
        interfacesStore: types.maybe(GetInterfacesResult),
        volumesStore: types.maybe(GetVolumesResult),
        servicesStore: types.maybe(GetServicesResult),
        bricksPathsStore: types.maybe(GetBricksPathsResult),
        bricksStore: types.maybe(GetBricksResult),
        removeBricksTasksStore: types.maybe(RemoveBricksTasksResult),
        rebalanceStatusStore: types.maybe(RebalanceStatusResult)
    })
    .volatile(() => ({
        bricksOrderBy: CLUSTER_LOCAL_BRICK_PATH,
        peersOrderBy: CLUSTER_PEERS_HOST,
        orderBricks: ASC,
        orderPeers: ASC,
        queryLimit: 100,
        currentPeerName: null,
        currentVolumeName: null,
        currentBrickId: null
    }))
    .views(self => ({
        get localPeerName() {
            return self.peers && self.peers.find(peer => peer.extendedStatus === PEER_EXTENDED_STATUS_OPTIONS.localPeer)?.name;
        },
        get allBricks() {
            return (
                self.bricksStore?.data?.arr.map(el => {
                    return {
                        ...el,
                        used: el.size.used,
                        id: `${el.peer}|${el.path}|${el.name}`,
                        peerAlias: self.peers?.find(peer => peer.name === el.peer)?.alias
                    };
                }) || []
            );
        },
        get sortedBricks() {
            const localBricks = this.allBricks?.filter(brick => brick.peer === self.localPeerName);
            return (localBricks && stableSort(localBricks, getComparator(self.orderBricks, self.bricksOrderBy))) || [];
        },
        get freeBricks() {
            return this.allBricks?.filter(brick => brick.volume === "") || [];
        },
        get volumeBricks() {
            return this.allBricks?.filter(brick => brick.volume === self.currentVolumeName) || [];
        },
        get peers() {
            return self.peersStore?.data?.arr || [];
        },
        get sortedPeers() {
            return (this.peers && stableSort(this.peers, getComparator(self.orderPeers, self.peersOrderBy))) || [];
        },
        get settings() {
            return self.settingsStore && self.settingsStore.data;
        },
        get clusterInterfaces() {
            return self.interfacesStore?.data;
        },
        get interfaces() {
            const interfaces = this.clusterInterfaces?.interfaces;
            const bonds = self.clusterInterfaces?.bonds;
            const allIfaces = this.clusterInterfaces && [...interfaces, ...bonds];
            return (
                (allIfaces &&
                    allIfaces
                        .map(iface => ({
                            value: iface.iface,
                            label: iface.slaves ? iface.slaves.join(", ") : iface.iface
                        }))
                        .sort()) ||
                []
            );
        },
        get currentBrick() {
            const currentBrick = self.currentBrickId?.split("|");
            return (
                currentBrick &&
                this.allBricks?.find(
                    brick => brick.peer === currentBrick[0] && brick.path === currentBrick[1] && brick.name === currentBrick[2]
                )
            );
        },
        get currentPeer() {
            return this.peers.find(peer => peer.name === self.currentPeerName);
        },
        get volumes() {
            return self.volumesStore?.data?.arr || [];
        },
        get currentVolume() {
            return this.volumes.find(volume => volume.name === self.currentVolumeName);
        },
        get services() {
            return self.servicesStore?.data?.arr || [];
        },
        get bricksPaths() {
            return self.bricksPathsStore?.data?.arr || [];
        },
        get removeBricksTasks() {
            return self.removeBricksTasksStore?.data || [];
        },
        get statusRebalance() {
            return self.rebalanceStatusStore?.data;
        }
    }))
    .actions(self => ({
        fetchBricks: flow(function*() {
            const query = { offset: 0, limit: self.queryLimit };
            const bricksRequestParams = PageQuery.create(query);
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetBricks.create().init(bricksRequestParams);
                const res = yield Socket.send(req);
                self.bricksStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        addBrick: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = AddBrick.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        removeBrick: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RemoveBrick.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        clearBrick: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ClearBrick.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        resetBrick: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ResetBrick.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchPeers: flow(function*(peersRequestParams = PageQuery.create({ offset: 0, limit: self.queryLimit })) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetPeers.create().init(peersRequestParams);
                const res = yield Socket.send(req);
                self.peersStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        addPeer: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = AddPeer.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        editPeer: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = EditPeer.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchServices: flow(function*(servicesRequestParams = PageQuery.create({ offset: 0, limit: self.queryLimit })) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetServices.create().init(servicesRequestParams);
                const res = yield Socket.send(req);
                self.servicesStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchBricksPaths: flow(function*(bricksPathsRequestParams = PageQuery.create({ offset: 0, limit: self.queryLimit })) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetBricksPaths.create().init(bricksPathsRequestParams);
                const res = yield Socket.send(req);
                self.bricksPathsStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchSettings: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetSettings.create().init();
                const res = yield Socket.send(req);
                self.settingsStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setSettings: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetSettings.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchInterfaces: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetInterfaces.create().init();
                const res = yield Socket.send(req);
                self.interfacesStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        removePeer: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RemovePeer.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        updateRemoteBricks: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = UpdateRemoteBricks.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchVolumes: flow(function*(volumesRequestParams = PageQuery.create({ offset: 0, limit: self.queryLimit })) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetVolumes.create().init(volumesRequestParams);
                const res = yield Socket.send(req);
                self.volumesStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        stopVolume: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = VolumeStop.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        startVolume: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = VolumeStart.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        removeVolume: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = VolumeRemove.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        rebalanceFixLayoutStart: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RebalanceFixLayoutStart.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        rebalanceStart: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RebalanceStart.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        rebalanceStop: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RebalanceStop.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        rebalanceStatus: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RebalanceStatus.create().init(data);
                const res = yield Socket.send(req);
                self.rebalanceStatusStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        addVolume: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = AddVolume.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        addVolumeBricks: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = VolumeAddBricks.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        removeVolumeBricks: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = VolumeRemoveBricks.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        replaceVolumeBricks: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = VolumeReplaceBricks.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchVolumeRemoveBricksTasks: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = VolumeRemoveBricksTasks.create().init();
                const res = yield Socket.send(req);
                self.removeBricksTasksStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        volumeRemoveBricksCommit: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = VolumeRemoveBricksCommit.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        volumeRemoveBricksStop: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = VolumeRemoveBricksStop.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        changeSortingBricks(column) {
            if (column === self.bricksOrderBy) {
                self.orderBricks = self.orderBricks === DESC ? ASC : DESC;
            } else {
                self.orderBricks = ASC;
                self.bricksOrderBy = column;
            }
        },
        changeSortingPeers(column) {
            if (column === self.peersOrderBy) {
                self.orderPeers = self.orderPeers === DESC ? ASC : DESC;
            } else {
                self.orderPeers = ASC;
                self.peersOrderBy = column;
            }
        },
        setCurrentBrickId(brickId) {
            self.currentBrickId = brickId;
        },
        setCurrentPeerName(peerName) {
            self.currentPeerName = peerName;
        },
        setCurrentVolumeName(volumeName) {
            self.currentVolumeName = volumeName;
        }
    }));

export default ClusterStore;
