import React from "react";

const ShareProcessIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(1 2)">
                    <path
                        fill="#6CB6FF"
                        stroke="#A4E1FF"
                        d="M20 .5h-5.764c-.284 0-.556.08-.789.224-.232.144-.426.351-.553.605L11.31 4.5H2c-.414 0-.79.168-1.06.44C.667 5.21.5 5.585.5 6v12c0 .414.168.79.44 1.06.27.272.646.44 1.06.44h18c.414 0 .79-.168 1.06-.44.272-.27.44-.646.44-1.06V2c0-.414-.168-.79-.44-1.06C20.79.667 20.415.5 20 .5z"
                    />
                    <rect width="21" height="15" x=".5" y="4.5" fill="#85C3FF" stroke="#B2E1FE" rx="2" />
                    <path
                        fill="#1E90FF"
                        fillRule="nonzero"
                        d="M11 6c1.105 0 2 .895 2 2s-.895 2-2 2c-.562 0-1.07-.232-1.432-.604L5.93 11.474c.046.168.07.344.07.526 0 .182-.024.36-.07.527l3.637 2.078C9.93 14.232 10.437 14 11 14c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2c0-.182.024-.358.07-.526l-3.637-2.08C5.07 13.769 4.563 14 4 14c-1.105 0-2-.895-2-2s.895-2 2-2c.562 0 1.07.232 1.433.605L9.07 8.527C9.024 8.359 9 8.182 9 8c0-1.105.895-2 2-2zm0 9c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm-7-4c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm7-4c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
                    />
                </g>
                <g transform="translate(12 12)">
                    <circle cx="6" cy="6" r="5.5" fill="#A279FF" stroke="#CA8EFF" />
                    <path
                        fill="#FFF"
                        d="M5.983 2.25l.079.004c.128.02.241.102.299.222l.027.076.37 1.478.016.098.083-.057 1.307-.783c.126-.076.284-.076.411 0 .166.1.234.3.172.475l-.035.073-.783 1.307-.058.081.1.019 1.477.369c.143.036.255.148.29.291.048.188-.046.377-.214.458l-.076.027-1.478.37-.1.016.059.083.783 1.307c.076.126.076.284 0 .411-.1.166-.3.234-.475.172l-.073-.035-1.307-.783-.083-.058c-.003.037-.009.068-.017.1l-.369 1.477c-.04.16-.172.274-.326.298l-.079.004c-.026 0-.053-.004-.08-.011-.143-.036-.255-.148-.291-.291l-.37-1.478-.018-.099-.04.031-1.348.81c-.189.114-.435.053-.548-.137-.076-.127-.076-.285 0-.411l.783-1.307.057-.083-.098-.017-1.478-.369c-.214-.054-.344-.27-.29-.485.035-.143.147-.255.29-.291l1.478-.37.098-.018-.03-.04-.81-1.348c-.114-.189-.053-.435.137-.548.127-.076.285-.076.411 0l1.307.783.081.057.019-.098.369-1.478c.036-.143.148-.255.291-.29.027-.008.054-.011.08-.012zM6 5c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default ShareProcessIcon;
