import React, { useContext, useEffect, useCallback } from "react";
import { useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import { Context } from "store";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { AUDIT_SETTINGS_CARD } from "const/widgetNameConst";
import DataReadonlyCard from "components/DataReadonlyCard";
import SavedInputPreview from "components/SavedInputPreview";
import EditAuditSettingsDialog from "../EditAuditSettingDialog";
import { useLocalStore } from "mobx-react-lite";
import SavedInputIndicator from "components/SavedInputIndicator";
import ClearLogsWarningDialog from "../ClearLogsWarningDialog";
import { useStyles } from "./AuditSettingsCard.styles";
import { AUDIT_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const AUDIT_SETTINGS_CARD_ID_PREFIX = `${AUDIT_PAGE_ID_PREFIX}_audit_settings_card`;

const AuditSettingsCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: { auditStore }
    } = useContext(Context);
    useEffect(() => {
        auditStore.fetchAuditSettings();
    }, []);
    const state = useLocalStore(() => ({
        isDialogOpen: false,
        isClearLogsWarningDialogOpen: false
    }));
    const openDialog = useCallback(() => {
        state.isDialogOpen = true;
    });
    const closeDialog = useCallback(() => {
        state.isDialogOpen = false;
    });
    const downloadAuthentificationLog = useCallback(() => {
        auditStore.downloadAuthentificationLog();
    });
    const clearAuthentificationLog = useCallback(() => {
        closeClearLogsWarningDialog();
        closeDialog();
        auditStore.clearAuthentificationLog();
    });
    const openClearLogsWarningDialog = useCallback(() => {
        state.isClearLogsWarningDialogOpen = true;
    });
    const closeClearLogsWarningDialog = useCallback(() => {
        state.isClearLogsWarningDialogOpen = false;
    });

    return useObserver(() => (
        <>
            <DataReadonlyCard
                onClick={openDialog}
                headerDivider
                autoHeight
                title={t("audit.audit_settings.title")}
                control={
                    <MoreMenu id={`${AUDIT_SETTINGS_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={AUDIT_SETTINGS_CARD} />
                        <MenuItem onClick={downloadAuthentificationLog} id={`${AUDIT_SETTINGS_CARD_ID_PREFIX}_download_log`}>
                            {t("audit.audit_settings.menu.download_log")}
                        </MenuItem>
                        <MenuItem onClick={openClearLogsWarningDialog} id={`${AUDIT_SETTINGS_CARD_ID_PREFIX}_clear_log`}>
                            {t("audit.audit_settings.menu.clear_log")}
                        </MenuItem>
                    </MoreMenu>
                }
            >
                <Grid container direction={"column"} spacing={5}>
                    <Grid item container alignItems={"center"}>
                        <Grid xs={6} item>
                            <Typography>{t("audit.audit_settings.log_storage_location")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>
                                {auditStore.auditSettingsList?.logStorageLocation ||
                                    t("audit.audit_settings.log_storage_location.empty")}
                            </SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"}>
                        <Grid xs={6} item>
                            <Typography>{t("audit.audit_settings.audit_authentication")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator
                                enabled={
                                    auditStore.auditSettingsList?.logStorageLocation !== "" &&
                                    auditStore.auditSettingsList?.authentication
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"}>
                        <Grid xs={6} item>
                            <Typography>{t("audit.audit_settings.audit_file_access")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator
                                enabled={
                                    auditStore.auditSettingsList?.logStorageLocation !== "" &&
                                    auditStore.auditSettingsList?.fileAccess
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"} className={classes.lastItem}>
                        <Grid xs={6} item>
                            <Typography>{t("audit.audit_settings.logging_operations")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator
                                enabled={
                                    auditStore.auditSettingsList?.logStorageLocation !== "" &&
                                    auditStore.auditSettingsList?.logOperationsWithAds
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DataReadonlyCard>
            <EditAuditSettingsDialog open={state.isDialogOpen} closeDialog={closeDialog} />
            <ClearLogsWarningDialog
                open={state.isClearLogsWarningDialogOpen}
                onClose={closeClearLogsWarningDialog}
                submit={clearAuthentificationLog}
                content={t("audit.audit_settings.clear_logs_warning_dialog.text")}
            />
        </>
    ));
};

export default AuditSettingsCard;
