import { FAILED, ONLINE, REPLACED, WARNING, NOT_COMMITED } from "const/affectedPools";
import { t } from "i18next";
/**
 * Function for determining the color background of StatusLabel
 * @param {string} status
 * @returns {("good"|"error"|"warning")} UI status
 * @return "good" - for green background
 * @return "error" - for red background
 * @return "warning" - for yellow background
 */
export const toUiStatus = status => {
    switch (status) {
        case WARNING:
        case ONLINE:
            return "good";
        case FAILED:
        case REPLACED:
            return "error";
        case NOT_COMMITED:
            return "warning";
        default:
            return status;
    }
};
/**
 * Function for checking the processing of existing statuses.
 * @param {string} status
 * @returns {string} the prepared text, or if the status is not known, then returns the status itself.
 */
export const checkDefaultStatus = status => {
    switch (status) {
        case WARNING:
        case ONLINE:
        case FAILED:
        case REPLACED:
        case NOT_COMMITED:
            return t(`pool.${status}`);
        default:
            return status;
    }
};
