import { PORT_MAX, PORT_MIN } from "const/firewallConst";
import i18n from "i18n";
const portRule = {
    portValue: {
        function: value => value >= PORT_MIN && value <= PORT_MAX && Number.isInteger(value),
        message: i18n.t("rule.port.format", { min: PORT_MIN, max: PORT_MAX })
    }
};

export default portRule;
