import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import TextInput from "components/MobxForm/TextInput";
import createForm from "utils/createForm";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import FileUpload from "components/FileUpload/FileUpload";

const AddConnectionDialog = ({ open, closeDialog }) => {
    const { t } = useTranslation();
    const {
        store: { wireguardStore },
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({
            fields: [
                {
                    name: "connectionName",
                    rules: "required|connectionName|connectionNameLen",
                },
            ],
            rules: {
                connectionName: {
                    function: (value) => {
                        return /^[\w -]+$/.test(value);
                    },
                    message: t("rule.wireguard_connection_name"),
                },
                connectionNameLen: {
                    function: (value) => {
                        return value.length <= 64;
                    },
                    message: t("rule.wireguard_connection_name_len", { count: 64 }),
                },
            },
            options: {
                validateOnInit: false,
                showErrorsOnReset: false,
                validateOnBlur: true,
            },
        }),
        currentFile: null,
        setFileValidationInfo(fileValidationInfo) {
            state.fileValidationInfo = fileValidationInfo;
        },
    }));

    const unsavedChangesModal = useModal();

    const updateForm = () => {
        state.form.$("connectionName").set("default", "");
        state.currentFile = null;
        state.form.reset();
        unsavedChangesModal.close();
    };

    const submit = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        const res = await wireguardStore.addConnection(state.form.$("connectionName").value, state.currentFile);
        state.form.reset();
        wireguardStore.fetchConnections();

        return res;
    };

    const onFileSelect = (file) => {
        state.currentFile = file;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault) {
            closeDialog();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            onEnter={updateForm}
            title={t("wireguard.add_connection_dialog.title")}
            submitBtnLabel={t("wireguard.add_connection_dialog.button.import")}
            disableSubmit={state.form.$("connectionName").value === "" || !state.fileValidationInfo.isValid}
            open={open}
            onSubmit={submit}
            onClose={closeIfNeeded}
            maxWidth={"md"}
            buttons={
                <Button onClick={closeDialog} variant={"contained"} color="secondary">
                    {t("wireguard.add_connection_dialog.button.cancel")}
                </Button>
            }
        >
            <RowTextInput
                leftXs={4}
                rightXs={8}
                label={t("wireguard.add_connection_dialog.connection_name")}
                control={<TextInput field={state.form.$("connectionName")} />}
            />
            <RowTextInput
                leftXs={4}
                rightXs={8}
                label={t("wireguard.add_connection_dialog.configuration_file")}
                control={
                    <FileUpload
                        handleFile={onFileSelect}
                        regExpExtension={/\.gpg/}
                        inputProps=".gpg"
                        file={state.currentFile}
                        noEmpty
                        onValid={state.setFileValidationInfo}
                    />
                }
            />
            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default AddConnectionDialog;
