import Request from "api/Request";

const GetMigrationState = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Migration",
    method: "get_migration_state",
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default GetMigrationState;
