const createFields = () => {
    return [
        {
            name: "logStorageLocation"
        },
        {
            name: "authentication",
            type: "checkbox"
        },
        {
            name: "fileAccess",
            type: "checkbox"
        },
        {
            name: "logOperationsWithAds",
            type: "checkbox"
        }
    ];
};

export default createFields;
