import React from "react";

function DriveIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
                fill="#1E90FF"
                fillRule="nonzero"
                d="M14.76 3a2.998 2.998 0 012.69 1.66l3.34 6.68c.14.28.211.588.21.9V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-5.76c-.001-.312.07-.62.21-.9l3.34-6.68A2.998 2.998 0 019.24 3zM19 13H5v5l.007.117A1 1 0 006 19h12a1 1 0 001-1v-5zm-3 2a1 1 0 010 2h-4a1 1 0 010-2zm-7.293.293a1 1 0 11-1.416 1.414 1 1 0 011.416-1.414zM14.76 5H9.24a.999.999 0 00-.9.55L5.62 11h12.76l-2.72-5.45a.999.999 0 00-.9-.55z"
            ></path>
        </svg>
    );
}

export default DriveIcon;
