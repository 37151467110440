import Request from "api/Request";

const RevokeAllTokens = Request.props({
    path: "/com/studionetworksolutions/evo/Auth",
    method: "revoke_all_tokens",
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default RevokeAllTokens;
