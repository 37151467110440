import React, { useContext, useMemo } from "react";
import { useObserver } from "mobx-react";
import { TableRow, TableCell } from "@material-ui/core";
import StatusLabel from "../StatusLabel";
import SavedInputIndicator from "components/SavedInputIndicator";
import TableRowControlButton from "components/TableRowControlButton";
import { SINGLE_FILE_TRANSFER_ACTION_NAME } from "const/slingshotAutomationsConst";
import moment from "moment";
import ChevronRightIcon from "assets/ChevronRightIcon";
import { useTranslation } from "react-i18next";
import TimeToStartCell from "../TimeToStartCell";
import { Context } from "../../../../../../store";

const AutomationRow = ({
    internal,
    tasks,
    schedule,
    deleted,
    enabled,
    paused,
    instances,
    name,
    onClick,
    lastInstance,
    automationId,
    ...props
}) => {
    const { t } = useTranslation();

    const isOneShot = useObserver(() => {
        return internal && tasks?.some(task => task.action?.action === SINGLE_FILE_TRANSFER_ACTION_NAME);
    });

    const {
        store: { automationsStore }
    } = useContext(Context);

    const runningInstancesCount = useObserver(() => {
        return instances?.get("IN PROGRESS") || lastInstance === "IN PROGRESS" || 0;
    });

    const automationType = useMemo(() => {
        if (isOneShot) {
            return t("slingshot.automations.table.label.automation_type.one_shot");
        } else if (schedule?.schedule) {
            return t("slingshot.automations.table.label.automation_type.scheduled");
        } else {
            return t("slingshot.automations.table.label.automation_type.manual");
        }
    });

    const status = useMemo(() => {
        let status = deleted
            ? t("slingshot.automations.table.label.status.deleted")
            : enabled
            ? paused
                ? t("slingshot.automations.table.label.status.paused")
                : runningInstancesCount > 0
                ? t("slingshot.automations.table.label.status.running")
                : t("slingshot.automations.table.label.status.waiting")
            : "Disabled";
        if (schedule?.schedule && !deleted) {
            const now = moment();
            let scheduleDate = moment();
            try {
                scheduleDate = moment(schedule.schedule.date);
            } catch (e) {
                // TODO Implement handling error
            }
            const diff = scheduleDate.diff(now);
            diff > 0 &&
                enabled &&
                runningInstancesCount === 0 &&
                !paused &&
                (status = t("slingshot.automations.table.label.status.waiting"));
        }
        return status;
    });

    return useObserver(() => (
        <TableRow selected={automationsStore.currentAutomationId === automationId} onClick={onClick} {...props}>
            <TableCell>{name}</TableCell>
            <TableCell>{automationType}</TableCell>
            <TableCell>
                <StatusLabel status={status} />
            </TableCell>
            <TimeToStartCell status={status} schedule={schedule} />
            <TableCell>
                <SavedInputIndicator enabled={enabled} />
            </TableCell>
            <TableCell>
                <TableRowControlButton icon={<ChevronRightIcon />} />
            </TableCell>
        </TableRow>
    ));
};

export default AutomationRow;
