import { flow, getParent, types } from "mobx-state-tree";
import Socket from "websocket";
import LogSettings from "api/troubleshooting/Responses/LogSettingsResult";
import GetLogSettings from "api/troubleshooting/Requests/GetLogSettings";
import SetLogSettings from "api/troubleshooting/Requests/SetLogSettings";
import ImportLdapCertificate from "api/security/users/Requests/ImportLdapCertificate";
import axios from "api/AxiosCommonRequest";
import { FILE_UPLOAD_URL } from "api/restRoutes";

const AdvancedStore = types
    .model({
        logSettingsInfo: types.maybe(LogSettings)
    })
    .views(self => ({
        get logSettings() {
            return self.logSettingsInfo?.data || {};
        }
    }))
    .actions(self => ({
        fetchLogSettings: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetLogSettings.create().init();
                const res = yield Socket.send(req);
                self.logSettingsInfo = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setLogSettings: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetLogSettings.create().init(data);
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateLogSettings: data => {
            if (self.logSettingsInfo) {
                self.logSettingsInfo.data = data;
            }
        },
        uploadFile: flow(function*(file) {
            const { processingStore } = getParent(self);
            const formData = new FormData();
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            try {
                formData.append("file", file);
                processingStore.setLoading(true);
                yield axios.post(FILE_UPLOAD_URL, formData, config);
                yield self.importCertificate(file.name);
                return file;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        importCertificate: flow(function*(fileName) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ImportLdapCertificate.create().init({
                    certName: fileName
                });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        })
    }));

export default AdvancedStore;
