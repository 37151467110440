import React from "react";

const OffIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="nonzero"
                d="M15.242 4.54a1.002 1.002 0 011.35-.43l.322.174a9.992 9.992 0 013.972 4.12c2.535 4.907.613 10.94-4.294 13.475s-10.94.612-13.474-4.295C.583 12.678 2.505 6.645 7.412 4.11l.107-.048a1.003 1.003 0 01.813 1.828l-.291.158a8 8 0 00.291 14.059 8.001 8.001 0 007.34-14.217l-.1-.06a1 1 0 01-.33-1.29zM12.002 2a1 1 0 011 1v10a1 1 0 01-1 1l-.117-.007a1 1 0 01-.883-.993V3a1 1 0 011-1z"
            />
        </svg>
    );
};

export default OffIcon;
