import { types } from "mobx-state-tree";

import PowerStatusAllEvents from "api/power/Types/PowerStatusAllEvents";

const PowerStatusResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(PowerStatusAllEvents)
});

export default PowerStatusResult;
