import { types } from "mobx-state-tree";

import MemoryDevice from "api/evo_info/Types/MemoryDevice";

const SystemMemoryResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(MemoryDevice)
});

export default SystemMemoryResult;
