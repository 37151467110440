import React, { useEffect } from "react";
import { ButtonBase, Grid, LinearProgress, Typography } from "@material-ui/core";
import PlusIcon from "assets/PlusIcon";
import DiskPicture from "assets/DiskPicture";
import { useStyles } from "./PoolShareHeader.style";
import { observer, useLocalStore, useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { POOL_SHARE } from "const/widgetNameConst";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import { convertBytesToSizeUnit } from "utils/convertBytesToSizeUnit";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { SHARE_POOL_USB } from "const/shareRootAccessConst";
import HideOverlay from "components/HideOverlay/HideOverlay";

const POOL_SHARE_HEADER_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_pool_share_header`;

const PoolShareHeader = ({ openCreateShareModal, used, size, pool, volume, raid, evoPrefix, ip }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const state = useLocalStore(() => ({
        createShareOpen: false,
        size: size,
        used: used,
        get sizePercentage() {
            return (this.used / this.size) * 100;
        },
        get totalSize() {
            return convertBytesToSizeUnit({ bytes: this.size, output: "object" });
        },
        get sizes() {
            return {
                total: `${this.totalSize.value} ${this.totalSize.symbol}`,
                free: convertBytesToSizeUnit({ bytes: this.size - this.used, exponent: this.totalSize.exponent }),
                allocated: convertBytesToSizeUnit({ bytes: this.used, exponent: this.totalSize.exponent })
            };
        }
    }));

    useEffect(() => {
        state.used = used;
        state.size = size;
    }, [used, size]);

    const notUsbPool = pool !== SHARE_POOL_USB;

    return useObserver(() => (
        <Grid container className={classes.container} alignItems={"center"}>
            <Grid className={classes.icon} />
            <Grid item className={classes.diskPictureContainer}>
                <DiskPicture className={classes.diskPicture} />
            </Grid>
            <Grid item container className={classes.titleContainer}>
                <Grid container direction={"column"} spacing={3}>
                    <Grid item container justify={"space-between"} alignItems={"flex-start"} wrap={"nowrap"}>
                        <Grid item container spacing={3}>
                            <Grid item container spacing={3} alignItems={"center"}>
                                <Grid item>
                                    <Typography variant={"h2"}>{`${evoPrefix}${pool}/${volume}`}</Typography>
                                </Grid>
                                <Grid item>
                                    {raid >= 0 && (
                                        <Grid container className={classes.raidLabel}>
                                            <Typography variant={"subtitle2"} className={classes.raidLabelText}>
                                                {`RAID${raid}`}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item container>
                                <LinearProgress
                                    color={"primary"}
                                    value={state.sizePercentage}
                                    variant={"determinate"}
                                    className={classes.progressBar}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.controlContainer} wrap={"nowrap"}>
                            <HideOverlay show={!ip}>
                                <Grid item>
                                    <CardHeaderControlButton>
                                        <MoreMenu id={`${POOL_SHARE_HEADER_ID_PREFIX}_more_menu`}>
                                            <HomeCardActionMenuItem widget={POOL_SHARE} contentId={`${pool}/${volume}`} />
                                        </MoreMenu>
                                    </CardHeaderControlButton>
                                </Grid>
                            </HideOverlay>
                            {notUsbPool && (
                                <Grid item>
                                    <CardHeaderControlButton>
                                        <ButtonBase
                                            onClick={openCreateShareModal}
                                            id={`${POOL_SHARE_HEADER_ID_PREFIX}_open_create_share_modal`}
                                        >
                                            <PlusIcon />
                                        </ButtonBase>
                                    </CardHeaderControlButton>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"}>
                        <Grid item className={classes.sizeItem}>
                            <Typography variant={"subtitle1"} component={"span"}>
                                {t("disks_and_pools.pools_card.header.size.label.total")}
                            </Typography>
                            <Typography variant={"subtitle1"} color={"primary"} component={"span"}>
                                {state.sizes.total}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.sizeItem}>
                            <Typography variant={"subtitle1"} component={"span"}>
                                {t("disks_and_pools.pools_card.header.size.label.allocated")}
                            </Typography>
                            <Typography variant={"subtitle1"} color={"primary"} component={"span"}>
                                {state.sizes.allocated}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.sizeItem}>
                            <Typography variant={"subtitle1"} component={"span"}>
                                {t("disks_and_pools.pools_card.header.size.label.free")}
                            </Typography>
                            <Typography variant={"subtitle1"} className={classes.successText} component={"span"}>
                                {state.sizes.free}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    ));
};

export default observer(PoolShareHeader);
