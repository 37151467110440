import { types } from "mobx-state-tree";

import IdentifyStatusData from "api/drive/Types/IdentifyStatusData";

const GetIdentifyStatusResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(IdentifyStatusData)
});

export default GetIdentifyStatusResult;
