import moment from "moment";

const dateComparison = (comparisonTarget, msg, { lessThan, isValidationNeeded = () => true }) => {
    return ({ field, form }) => {
        const isValidationNeededResult = isValidationNeeded(form);

        if (!isValidationNeededResult) {
            return [true, ""];
        }

        if (lessThan) {
            return [moment(field.value).isBefore(form.$(comparisonTarget).value), msg];
        }

        return [moment(field.value).isAfter(form.$(comparisonTarget).value), msg];
    };
};

export default dateComparison;
