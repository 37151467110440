const createFields = (upsSettings = {}) => {
    return [
        {
            name: "automaticShutdownEnabled",
            default: !!upsSettings.automaticShutdownEnabled,
            type: "checkbox",
        },
        {
            name: "criticalChargeLevel",
            default: upsSettings.criticalChargeLevel,
        },
        {
            name: "srtMode",
            default: upsSettings.srtMode,
        },
    ];
};

export default createFields;
