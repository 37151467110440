import { types } from "mobx-state-tree";

const SOURCE_ARRAY = ["upload", "restore_location", "upg_inbox"];

const ImportArguments = types.model({
    dbBackupSource: types.enumeration(SOURCE_ARRAY),
    dbBackupName: types.string,
    cfgBackupName: types.string
});

export default ImportArguments;
