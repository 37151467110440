import React from "react";
import { useStore } from "hooks/useStore";
import { Grid, Typography, Box } from "@material-ui/core";
import { useObserver } from "mobx-react";
import { useStyles } from "./Volume.styles";
import ChevronRightIcon from "assets/ChevronRightIcon";
import ClusterVolumeIcon from "assets/ClusterVolumeIcon";
import { VOLUME_TYPE_OPTIONS_ARRAY } from "const/clusterConst";
import StatusLabel from "./components/StatusLabel";

const Volume = ({ name, status, type }) => {
    const classes = useStyles();
    const {
        store: { clusterStore }
    } = useStore();

    const onVolumeClick = volumeName => () => {
        clusterStore.setCurrentVolumeName(clusterStore.currentVolumeName === volumeName ? null : volumeName);
    };

    const getVolumeType = type => {
        return VOLUME_TYPE_OPTIONS_ARRAY.find(entry => entry.value === type)?.label || "";
    };

    return useObserver(() => (
        <Grid
            wrap={"nowrap"}
            id={name}
            container
            alignItems={"center"}
            className={classes.container}
            onClick={onVolumeClick(name)}
        >
            <Grid item container wrap={"nowrap"} alignItems={"center"}>
                <Box mr={2} mb={-1}>
                    <ClusterVolumeIcon />
                </Box>
                <Typography variant={"subtitle2"}>{name}</Typography>
            </Grid>
            <Box mx={3} textAlign={"right"} whiteSpace={"nowrap"}>
                {getVolumeType(type)}
            </Box>
            <Box mx={3} textAlign={"right"} whiteSpace={"nowrap"}>
                <StatusLabel status={status} />
            </Box>
            <Grid item>
                <Box mb={-1}>
                    <ChevronRightIcon />
                </Box>
            </Grid>
        </Grid>
    ));
};

export default Volume;
