import React, { useContext, useEffect } from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { useTranslation } from "react-i18next";
import { Context } from "store";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Grid, Typography } from "@material-ui/core";
import SavedInputIndicator from "components/SavedInputIndicator";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { AGENT_CONFIGURATION_CARD } from "const/widgetNameConst";
import SetupAgentConfigurationDialog from "../SetupAgentConfigurationDialog";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const AGENT_CONFIGURATION_CARD_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_agent_configuration_card`;

const AgentConfigurationCard = () => {
    const { t } = useTranslation();
    const {
        store: { alertsStore }
    } = useContext(Context);
    useEffect(() => {
        alertsStore.fetchSnmpAgentSettings();
    }, []);
    const state = useLocalStore(() => ({
        isDialogOpen: false
    }));
    const closeDialog = () => {
        state.isDialogOpen = false;
    };
    const openDialog = () => {
        state.isDialogOpen = true;
    };

    return useObserver(() => (
        <>
            <SetupAgentConfigurationDialog open={state.isDialogOpen} onClose={closeDialog} />
            <DataReadonlyCard
                control={
                    <MoreMenu id={`${AGENT_CONFIGURATION_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={AGENT_CONFIGURATION_CARD} />
                    </MoreMenu>
                }
                headerDivider
                onClick={openDialog}
                autoHeight
                title={t("alerts.agent_configuration_card.title")}
            >
                <Grid container direction={"column"} spacing={5}>
                    <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.agent_configuration_card.evo_version")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={alertsStore.snmpAgentSettings.evoVersionAvailable} />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.agent_configuration_card.time")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={alertsStore.snmpAgentSettings.localTimeAvailable} />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.agent_configuration_card.system_uptime")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={alertsStore.snmpAgentSettings.systemUptimeAvailable} />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.agent_configuration_card.usage_statistics")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={alertsStore.snmpAgentSettings.cpuUsageAvailable} />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.agent_configuration_card.ups_status")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={alertsStore.snmpAgentSettings.upsStatusAvailable} />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.agent_configuration_card.overall_statistics")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={alertsStore.snmpAgentSettings.blocksIoAvailable} />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.agent_configuration_card.disks_statistics")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={alertsStore.snmpAgentSettings.ioStatAvailable} />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.agent_configuration_card.nas_sessions")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={alertsStore.snmpAgentSettings.sessionsAvailable} />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.agent_configuration_card.cached_disk_statistics")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={alertsStore.snmpAgentSettings.cachedVolumeAvailable} />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.agent_configuration_card.per_logical_disks_statistics")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={alertsStore.snmpAgentSettings.detailedStatAvailable} />
                        </Grid>
                    </Grid>
                </Grid>
            </DataReadonlyCard>
        </>
    ));
};

export default AgentConfigurationCard;
