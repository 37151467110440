import { types } from "mobx-state-tree";

import Request from "api/Request";
import LdapSettings from "api/security/users/Types/LdapSettings";

const EnableLdap = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Users",
    method: "enable_ldap",
    arguments: types.maybe(LdapSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default EnableLdap;
