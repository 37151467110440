import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4, 6, 8),
    },
    rightIcon: {
        marginLeft: "auto",
    },
}));
