import { types } from "mobx-state-tree";

import DriveStatusData from "api/system_drives/Types/DriveStatusData";

const DriveStatusResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(DriveStatusData),
});

export default DriveStatusResult;
