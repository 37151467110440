import React, { useEffect } from "react";
import { useObserver } from "mobx-react";
import DataCard from "components/DataCard";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import ChevronRightIcon from "assets/ChevronRightIcon";
import EthernetIcon from "assets/EthernetIcon";
import TableRowControlButton from "components/TableRowControlButton";
import { useTranslation } from "react-i18next";
import TableCellWithIcon from "components/TableCellWithIcon";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { ETHERNET_PORTS_CARD } from "const/widgetNameConst";
import StatusTranslation from "../StatusTranslation";
import EthernetDrawer from "../EthernetDrawer";
import TableSortLabel from "components/TableSortLabel";
import {
    ETHERNET_PORT_GATEWAY,
    ETHERNET_PORT_IP,
    ETHERNET_PORT_NETMASK,
    ETHERNET_PORT_PORT,
    ETHERNET_PORT_SPEED,
    ETHERNET_PORT_STATUS
} from "const/sortColumnConst";
import useStoreByIp from "hooks/useStoreByIp";
import HideOverlay from "components/HideOverlay/HideOverlay";

const EthernetPortsCard = ({ ip }) => {
    const { t } = useTranslation();

    const { ethernetPortsStore } = useStoreByIp({ ip });
    useEffect(() => {
        ethernetPortsStore.fetchEthernetPorts();
    }, []);
    const changeSorting = column => () => ethernetPortsStore.changeSorting(column);

    return useObserver(() => (
        <DataCard
            title={t("network.ethernet_ports.title")}
            emptyPadding
            headerDivider
            headerControl={
                <HideOverlay show={!ip}>
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={ETHERNET_PORTS_CARD} />
                    </MoreMenu>
                </HideOverlay>
            }
        >
            <EthernetDrawer ip={ip} />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                onClick={changeSorting(ETHERNET_PORT_PORT)}
                                direction={ethernetPortsStore.order}
                                active={ethernetPortsStore.orderBy === ETHERNET_PORT_PORT}
                            >
                                {t("network.ethernet_ports.header.port")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={changeSorting(ETHERNET_PORT_IP)}
                                direction={ethernetPortsStore.order}
                                active={ethernetPortsStore.orderBy === ETHERNET_PORT_IP}
                            >
                                {t("network.ethernet_ports.header.ip")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={changeSorting(ETHERNET_PORT_NETMASK)}
                                direction={ethernetPortsStore.order}
                                active={ethernetPortsStore.orderBy === ETHERNET_PORT_NETMASK}
                            >
                                {t("network.ethernet_ports.header.subnet")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={changeSorting(ETHERNET_PORT_GATEWAY)}
                                direction={ethernetPortsStore.order}
                                active={ethernetPortsStore.orderBy === ETHERNET_PORT_GATEWAY}
                            >
                                {t("network.ethernet_ports.header.gateway")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={changeSorting(ETHERNET_PORT_SPEED)}
                                direction={ethernetPortsStore.order}
                                active={ethernetPortsStore.orderBy === ETHERNET_PORT_SPEED}
                            >
                                {t("network.ethernet_ports.header.speed")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={changeSorting(ETHERNET_PORT_STATUS)}
                                direction={ethernetPortsStore.order}
                                active={ethernetPortsStore.orderBy === ETHERNET_PORT_STATUS}
                            >
                                {t("network.ethernet_ports.header.status")}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ethernetPortsStore.sortedPorts.map(row => {
                        const openDrawer = () => {
                            ethernetPortsStore.setCurrentPort(row.port);
                        };
                        return (
                            <TableRow
                                selected={ethernetPortsStore.currentPortName === row.port}
                                key={row.port}
                                onClick={openDrawer}
                            >
                                <TableCellWithIcon icon={<EthernetIcon />}>{row.port}</TableCellWithIcon>
                                <TableCell>{row.ip}</TableCell>
                                <TableCell>{row.netmask}</TableCell>
                                <TableCell>{row.gateway}</TableCell>
                                <TableCell>{row.speed}</TableCell>
                                <TableCell>
                                    <StatusTranslation label={row.status} />
                                </TableCell>
                                <TableCell align={"right"}>
                                    <TableRowControlButton icon={<ChevronRightIcon />} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </DataCard>
    ));
};

export default EthernetPortsCard;
