import { types, addMiddleware, flow } from "mobx-state-tree";
import SuccessMessageHandling from "middlewares/SuccessMessageHandling.middleware";
import ProcessingStore from "store/ProcessingStore";
import MultipleEvoEventStore from "store/MultipleEvoEventStore";
import ShareStore from "store/ShareStore";
import FileStore from "store/FileStore";
import ExternalSharesStore from "store/ExternalSharesStore";
import UiStore from "store/UiStore";
import AclStore from "store/AclStore";
import TimeSettingsStore from "store/TimeSettingsStore";
import UserStore from "store/UserStore";
import SessionStore from "store/SessionStore";
import { ROLES } from "const/userRolesConst";
import RecycleBinStore from "store/RecycleBinStore";
import PoolsStore from "store/PoolsStore";
import VolumeStore from "store/VolumeStore";
import BcacheStore from "store/BcacheStore";
import NetworkGraphStore from "store/graphs/NetworkGraphStore";
import EthernetPortsStore from "store/EthernetPortsStore";
import DriveStore from "store/DriveStore";
import BondsStore from "store/BondsStore";
import BridgeStore from "store/BridgeStore";
import BootProgressTypes from "api/boot_progress/Types/BootProgressTypes";
import { BOOT_PROGRESS_URL } from "api/restRoutes";
import ThroughputGraphStore from "./graphs/ThroughputGraphStore/ThroughputGraphStore";
import CPUGraphStore from "./graphs/CPUGraphStore/CPUGraphStore";
import StorageUsageGraphStore from "./graphs/StorageUsageGraphStore/StorageUsageGraphStore";
import MainStore from "store";
import i18next from "i18next";

const BOOT_PROGRESS_INTERVAL = 5000;

export const MultipleEvoStoreType = types
    .model({
        ip: types.string,
        name: types.string,
        processingStore: ProcessingStore,
        multipleEvoEventStore: MultipleEvoEventStore,
        shareStore: ShareStore,
        externalSharesStore: ExternalSharesStore,
        fileStore: FileStore,
        uiStore: UiStore,
        aclStore: AclStore,
        userStore: UserStore,
        sessionStore: SessionStore,
        recycleBinStore: RecycleBinStore,
        poolsStore: PoolsStore,
        volumeStore: VolumeStore,
        bcacheStore: BcacheStore,
        authStore: types.model({ role: types.maybeNull(types.enumeration(ROLES)) }),
        networkGraphStore: NetworkGraphStore,
        throughputGraphStore: ThroughputGraphStore,
        cpuGraphStore: CPUGraphStore,
        storageUsageGraphStore: StorageUsageGraphStore,
        ethernetPortsStore: EthernetPortsStore,
        driveStore: DriveStore,
        bondsStore: BondsStore,
        bridgeStore: BridgeStore,
        timeSettingsStore: TimeSettingsStore,
        bootProgress: types.maybeNull(BootProgressTypes),
        countErrorFetch: types.number,
        needDestroy: types.boolean,
    })
    .volatile(() => ({
        socket: null,
        axios: null,
        bootProgressInterval: null,
    }))
    .views((self) => ({
        get isEvoRunning() {
            return self.bootProgress?.status === 0;
        },
    }))
    .actions((self) => ({
        afterCreate() {
            addMiddleware(self, SuccessMessageHandling);
        },
        setupSocket: (socket) => {
            self.socket = socket;
            self.multipleEvoEventStore.listen();
        },
        setupAxios: (axios) => {
            self.axios = axios;
        },
        startBootProgressPolling: () => {
            self.bootProgressInterval = setInterval(self.getBootProgress, BOOT_PROGRESS_INTERVAL);
        },
        endBootProgressPolling: () => {
            clearInterval(self.bootProgressInterval);
        },
        getBootProgress: flow(function* () {
            if (self.needDestroy) {
                self.destroy();
                return false;
            }

            try {
                const res = yield self.axios.get(BOOT_PROGRESS_URL);

                if (res.data.status !== 0) {
                    self.processingStore.setError({ message: res.data.message });

                    self.needDestroy = true;
                    return false;
                }
                self.needDestroy = false;
                self.countErrorFetch = 0;
                self.bootProgress = res.data;
            } catch (e) {
                if (e?.response?.status === 401) {
                    MainStore.multipleEvosStore.logoutFromMultipleEvo(self.ip);
                    return;
                }

                if (self.countErrorFetch === 3) {
                    self.processingStore.setError(
                        i18next.t("multiple_evo.connection_over_https_error", {
                            evoName: self.name,
                        })
                    );
                    self.needDestroy = true;
                }

                self.augmentCountErrorFetch();
            }
        }),
        resetCountErrorFetch() {
            self.countErrorFetch = 0;
        },
        augmentCountErrorFetch() {
            self.countErrorFetch++;
        },
        destroy: () => {
            self.endBootProgressPolling();
            MainStore.multipleEvosStore.closeConnection(self.ip);
        },
    }));

export const createMultipleEvoStore = ({ ip, socket, name, role, axios }) => {
    const store = MultipleEvoStoreType.create({
        ip,
        name,
        processingStore: {},
        shareStore: {},
        externalSharesStore: {},
        fileStore: {},
        multipleEvoEventStore: {},
        uiStore: {},
        aclStore: {},
        userStore: {},
        sessionStore: {},
        authStore: { role },
        recycleBinStore: {},
        poolsStore: {},
        volumeStore: {},
        bcacheStore: {},
        networkGraphStore: {},
        throughputGraphStore: {},
        cpuGraphStore: {},
        storageUsageGraphStore: {},
        ethernetPortsStore: {},
        driveStore: {},
        bondsStore: {},
        bridgeStore: {},
        timeSettingsStore: {},
        countErrorFetch: 0,
        needDestroy: false,
    });
    store.setupSocket(socket);
    store.setupAxios(axios);
    store.startBootProgressPolling();
    return store;
};
