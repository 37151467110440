import React from "react";
import { useStyles } from "./DetailsActionsDrawer.styles";
import { Button, ButtonGroup, Divider, Drawer, Grid } from "@material-ui/core";
import { observer, useLocalStore } from "mobx-react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import DrawerHeader from "components/DrawerHeader";
import { useStore } from "hooks/useStore";

const DetailsActionsDrawer = ({
    drawerName,
    closeDrawer,
    modals,
    title,
    icon,
    renderDetails: RenderDetails,
    renderActions: RenderActions,
    className,
    id,
    noWrapHeader,
    ...props
}) => {
    const {
        store: { uiStore },
    } = useStore();
    const { t } = useTranslation();
    const classes = useStyles({ isWidgetOpen: uiStore.isWidgetsBarOpen });
    const state = useLocalStore(() => ({
        showDetails: false,
    }));

    const showDetails = () => {
        state.showDetails = true;
    };
    const showAction = () => {
        state.showDetails = false;
    };

    const ShowDetails = () => {
        if (state.showDetails) {
            if (React.isValidElement(RenderDetails)) return <React.Fragment>{RenderDetails}</React.Fragment>;
            return <RenderDetails />;
        }

        if (React.isValidElement(RenderActions)) return <React.Fragment>{RenderActions}</React.Fragment>;
        return <RenderActions />;
    };

    return (
        <Drawer
            variant={"persistent"}
            className={clsx(classes.drawer, className)}
            anchor="right"
            open={uiStore.openedDrawer === drawerName}
            onClose={closeDrawer}
            {...props}
        >
            {modals}
            <Grid className={classes.container} container direction={"column"}>
                <Grid item>
                    <DrawerHeader closeDrawer={closeDrawer} icon={icon} noWrap={noWrapHeader}>
                        {title}
                    </DrawerHeader>
                    <Grid item>
                        <ButtonGroup className={classes.headerButtonGroup}>
                            <Button
                                size={"small"}
                                onClick={showDetails}
                                variant={state.showDetails ? "contained" : "outlined"}
                                color={"primary"}
                                id={`${id}_show_details`}
                            >
                                {t("users.side_menu.button.details")}
                            </Button>
                            <Button
                                size={"small"}
                                onClick={showAction}
                                variant={state.showDetails ? "outlined" : "contained"}
                                color={"primary"}
                                id={`${id}_show_action`}
                            >
                                {t("users.side_menu.button.actions")}
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Divider />
                <Grid item className={classes.body}>
                    <ShowDetails />
                </Grid>
            </Grid>
        </Drawer>
    );
};

export default observer(DetailsActionsDrawer);
