import React from "react";

function RebootIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                d="M5.146 2.854a.5.5 0 01.708 0l1.46 1.46A9 9 0 113 12a1 1 0 012 0 7 7 0 103.782-6.219l1.364 1.365A.5.5 0 019.793 8H5V3.207a.5.5 0 01.146-.353z"
            />
        </svg>
    );
}

export default RebootIcon;
