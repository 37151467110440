import { types } from "mobx-state-tree";
import { NasConfigurationConstants } from "const/nasConfigurationConst";

const NasConfiguration = types.model({
    smbMaxXmit: types.number,
    smbMaxCredits: types.number,
    smbWorkgroup: types.string,
    smbProtocol: types.enumeration("smbProtocol", Object.values(NasConfigurationConstants.SmbType)),
    smbMultichannelEnabled: types.boolean,
    smbAsyncEnabled: types.boolean,
    useSendfileEnabled: types.boolean,
    ftpType: types.enumeration("ftpType", Object.values(NasConfigurationConstants.FtpType)),
    smbFruit: types.boolean
});

export default NasConfiguration;
