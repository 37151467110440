
/**
 * Function returns a sequence of numbers, starting from a specified number, and increments by 1 (by default), and stops before a specified number.
 * @param {number} start - Required. An integer number specifying at which position to start. 
 * @param {number} end - Required. An integer number specifying at which position to stop.
 * @param {number} [step] - Optional. An integer number specifying the incrementation. Default is 1
 * @returns {number[]} a sequence of numbers
 */

export function getRange(start, end, step = 1) {
    const len = Math.floor((end - start) / step) + 1
    return Array(len).fill().map((_, idx) => start + (idx * step))
}