import Request from "api/Request";

const GetMissingActivatedQuads = Request.props({
    path: "/com/studionetworksolutions/evo/manage/activation/Quad",
    method: "get_missing_activated_quads",
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetMissingActivatedQuads
