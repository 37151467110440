import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography, Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";

const ClearLunWarningDialog = ({ open, onClose, submit, content, inProgress }) => {
    const { t } = useTranslation();

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={t("common.warning_dialog.title")}
            withoutIconClose
            withoutDividers
            submitBtnLabel={t("common.button.clear")}
            onSubmit={submit}
            inProgress={inProgress}
            buttons={
                <Button onClick={onClose} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"} spacing={5}>
                <Grid item>
                    <Typography>{content}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default ClearLunWarningDialog;
