import React, { useContext } from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./HeaderTitle.style";
import AddCategory from "assets/AddCategory";
import { Context } from "store";
import TableRowControlButton from "components/TableRowControlButton";
import { ADMIN } from "const/userRolesConst";

const HeaderTitle = ({ icon, title, shortcutName }) => {
    const classes = useStyles();
    const addShortcut = () => {
        if (shortcutName) {
            uiStore.addShortcut(shortcutName);
        }
    };
    const {
        store: { uiStore, authStore }
    } = useContext(Context);
    return useObserver(() => (
        <Grid container justify={"center"} spacing={3} alignItems={"center"} direction={"row"} wrap={"nowrap"}>
            {icon && (
                <Grid item className={classes.icon}>
                    <Grid container alignItems={"center"}>
                        {icon}
                    </Grid>
                </Grid>
            )}
            <Grid item>
                <Typography className={classes.title} noWrap variant={"h1"}>
                    {title}
                </Typography>
            </Grid>
            {shortcutName && authStore.role === ADMIN && (
                <Grid item className={classes.addIcon}>
                    <Grid container alignItems={"center"}>
                        <TableRowControlButton icon={<AddCategory />} onClick={addShortcut} />
                    </Grid>
                </Grid>
            )}
        </Grid>
    ));
};

export default HeaderTitle;
