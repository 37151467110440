import Request from "api/Request";

const GetStorageUsageGraph = Request.props({
    path: "/com/studionetworksolutions/evo/status/Graph",
    method: "get_storage_usage_graph"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetStorageUsageGraph;
