import React from "react";
import { observer } from "mobx-react";
import RadioGroupBase from "../RadioGroup";
import { mapFieldToProps } from "utils/mapFieldToProps";

const RadioGroup = ({ field, ...rest }) => {
    return <RadioGroupBase error={Boolean(field.error)} helperText={field.error} {...mapFieldToProps(field)} {...rest} />;
};

export default observer(RadioGroup);
