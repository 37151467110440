import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, observer } from "mobx-react";
import createFields from "./createFields";
import createForm from "utils/createForm";
import TextInput from "components/MobxForm/TextInput";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import Switch from "components/MobxForm/Switch";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import portRule from "utils/portRule";

const SET_CONFIG_DIALOG_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_set_config_dialog`;

const SetConfigDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const {
        store: { webserverStore },
    } = useStore();

    const fields = createFields({
        httpPort: webserverStore.configInfo.httpPort,
        httpsPort: webserverStore.configInfo.httpsPort,
        sslEnabled: webserverStore.configInfo.sslEnabled,
    });

    const state = useLocalStore(() => ({
        form: createForm({
            fields,
            rules: { ...portRule },
        }),
        isSubmitted: false,
    }));

    const unsavedChangesModal = useModal();

    const submit = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        const res = await webserverStore.setConfig(state.form.values());

        if (res) {
            state.isSubmitted = true;
        }

        const configInfo = await webserverStore.fetchConfigInfo();

        if (configInfo) {
            state.form.$("httpPort").set("default", webserverStore.configInfo.httpPort);
            state.form.$("httpsPort").set("default", webserverStore.configInfo.httpsPort);
            state.form.$("sslEnabled").set("default", webserverStore.configInfo.sslEnabled);
        }

        return res;
    };

    const onEnter = () => {
        state.form.reset();
        state.isSubmitted = false;
        unsavedChangesModal.close();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }

        unsavedChangesModal.open();
    };

    return (
        <Dialog
            title={t("admin_settings.networking_settings.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${SET_CONFIG_DIALOG_ID_PREFIX}_save`}
            open={open}
            onEnter={onEnter}
            onSubmit={submit}
            onClose={closeIfNeeded}
            buttons={
                <Button
                    key="cancelBtn"
                    onClick={onClose}
                    variant={"contained"}
                    color="secondary"
                    id={`${SET_CONFIG_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"}>
                <RowTextInput
                    label={t("admin_settings.networking_settings.label.http_port")}
                    control={<TextInput field={state.form.$("httpPort")} id={`${SET_CONFIG_DIALOG_ID_PREFIX}_http_port`} />}
                />
                <RowControlLabelSwitch
                    control={<Switch field={state.form.$("sslEnabled")} id={`${SET_CONFIG_DIALOG_ID_PREFIX}_https_switch`} />}
                    label={t("admin_settings.networking_settings.label.enable.https")}
                />
                <RowTextInput
                    label={t("admin_settings.networking_settings.label.https_port")}
                    control={<TextInput field={state.form.$("httpsPort")} id={`${SET_CONFIG_DIALOG_ID_PREFIX}_https_port`} />}
                />
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    );
};

export default observer(SetConfigDialog);
