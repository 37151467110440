import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./EmptyRequestPlaceholder.styles";
import UsersSearchPic from "assets/UsersSearchPic";
import { useTranslation } from "react-i18next";

const EmptyRequestPlaceholder = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Grid container className={classes.container} direction={"column"} alignItems={"center"} justify={"center"}>
            <Grid item container justify={"center"} alignItems={"center"} className={classes.oval}>
                <UsersSearchPic />
            </Grid>
            <Grid item>
                <Typography className={classes.message}>{t("users.search_results.empty_request_placeholder")}</Typography>
            </Grid>
        </Grid>
    );
};

export default EmptyRequestPlaceholder;
