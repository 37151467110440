import React from "react";
import { observer } from "mobx-react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "entry/PrivateRoute";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./routes";
import { URL_HOME, URL_CENTRAL_STATUS, URL_QUICK_START } from "routes";
import { SECONDARY_ADMIN, USER } from "const/userRolesConst";
import { useStore } from "hooks/useStore";

const AppRouter = () => {
    const {
        store: { authStore, multipleEvosStore },
    } = useStore();

    const filteredPrivateRoutes = PRIVATE_ROUTES.filter(({ path }) => {
        if (path === URL_CENTRAL_STATUS) {
            return multipleEvosStore.isMultipleEvoConnections;
        }

        return true;
    });

    const filteredPublicRoutes = PUBLIC_ROUTES.filter(({ path }) => {
        if (path.includes(URL_QUICK_START)) {
            return ![USER, SECONDARY_ADMIN].includes(authStore.role);
        }

        return true;
    });

    if (!authStore.isAuthorizationChecked) return null;

    return (
        <Switch>
            {filteredPrivateRoutes.map(({ path, Component, exact }) => (
                <PrivateRoute key={path} isAuthorized={authStore.isAuthorized} exact={exact} path={path} component={Component} />
            ))}
            {filteredPublicRoutes.map(({ path, Component, exact }) => (
                <Route key={path} exact={exact} path={path} component={Component} />
            ))}
            <Redirect to={URL_HOME} />
        </Switch>
    );
};

export default observer(AppRouter);
