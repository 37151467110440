import React from "react";
import { useStore } from "hooks/useStore";
import Select from "components/MobxForm/Select";
import createFields from "./createFields";
import createForm from "utils/createForm";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import { useModal } from "hooks/useModal";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";

const AddBrickDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const {
        store: { clusterStore }
    } = useStore();
    const unsavedChangesModal = useModal();
    const fields = createFields();
    const state = useLocalStore(() => ({
        inProgress: false,
        isSubmitted: false,
        form: createForm({
            fields
        })
    }));

    const onDialogOpen = () => {
        state.inProgress = false;
        state.isSubmitted = false;
        unsavedChangesModal.close();
        state.form.$("path").set("default", clusterStore.bricksPaths[0]);
        state.form.reset();
    };

    const closeModal = () => {
        state.form.reset();
        onClose();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            closeModal();
            return;
        }
        unsavedChangesModal.open();
    };

    const onSubmit = async () => {
        state.inProgress = true;
        const isSubmitted = await clusterStore.addBrick({
            path: state.form.$("path").value,
            name: state.form.$("brickName").value
        });
        state.inProgress = false;

        if (isSubmitted) {
            state.isSubmitted = true;
        }

        return isSubmitted;
    };

    return useObserver(() => (
        <Dialog
            onEnter={onDialogOpen}
            title={t("cluster.add_brick.title")}
            open={open}
            onClose={closeIfNeeded}
            onSubmit={onSubmit}
            isDataChanged={!state.form.isDefault}
            submitBtnLabel={t("common.button.create")}
            inProgress={state.inProgress}
            buttons={[
                <Button key={"cancel_button"} onClick={closeIfNeeded} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>
            ]}
        >
            <UnsavedChangesDialog onConfirm={closeModal} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            <Grid direction={"column"} container>
                <RowTextInput
                    label={t("cluster.local_bricks.header.cluster_path_on_evo")}
                    control={<Select field={state.form.$("path")} options={clusterStore.bricksPaths} />}
                />
                <RowTextInput
                    label={t("cluster.local_bricks.header.brick_name")}
                    control={
                        <TextInput
                            inputProps={{
                                autoComplete: "new-name"
                            }}
                            field={state.form.$("brickName")}
                        />
                    }
                />
            </Grid>
        </Dialog>
    ));
};

export default AddBrickDialog;
