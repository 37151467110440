import { clone, detach, flow, getParent, types, getSnapshot, isStateTreeNode } from "mobx-state-tree";
import {
    HEADER_HEIGHT,
    GRAPHICS_BAR_HEIGHT,
    STATE_BAR_HEIGHT,
    NOTIFICATION_MENU_WIDTH,
    OPENED_SIDE_MENU_WIDTH,
} from "const/styleConst";
import UiSettingsType from "api/ui_settings/Responses/GetUiSettingsResult";
import DashboardLayoutType from "api/ui_settings/Responses/GetDashboardLayoutResult";
import GetUiSettings from "api/ui_settings/Requests/GetUiSettings";
import MainSocket from "websocket";
import GetDashboardLayout from "api/ui_settings/Requests/GetDashboardLayout";
import SetDashboardLayout from "api/ui_settings/Requests/SetDashboardLayout";
import SetUiSettings from "api/ui_settings/Requests/SetUiSettings";
import { MESSAGE_WIDGET_NOT_PROVIDER } from "const/errorConst";
import GetShortcuts from "api/ui_settings/Requests/GetShortcuts";
import GetShortcutsResult from "api/ui_settings/Responses/GetShortcutsResult";
import SetShortcuts from "api/ui_settings/Requests/SetShortcuts";
import { WHATS_THIS } from "const/shortcutNameConst";
import GetUiParameters from "api/ui_settings/Requests/GetUiParameters";
import SetUiParameters from "api/ui_settings/Requests/SetUiParameters";
import UiParametersResult from "api/ui_settings/Responses/UiParametersResult";
import { changeLanguage } from "i18next";
import { ADMIN, SECONDARY_ADMIN } from "const/userRolesConst";
import { actionGuardByRole } from "utils/actionGuardByRole";
import UiLockReasonResult from "api/ui_settings/Responses/UiLockReasonResult";
import GetUiLockReason from "api/ui_settings/Requests/GetUiLockReason";
import Socket from "websocket/WebSocket";
import { autorun } from "mobx";
import { NOTSTARTED } from "const/updatesConst";
import { FINISHED } from "const/updatesConst";

const UiStore = types
    .model({
        isSideMenuOpen: types.optional(types.boolean, false),
        isSideMenuPinned: types.optional(types.boolean, true),
        isSystemMenuOpen: types.optional(types.boolean, false),
        isNotificationMenuOpen: types.optional(types.boolean, false),
        isNotificationMenuPinned: types.optional(types.boolean, false),
        isNotificationMenuHide: types.optional(types.boolean, false),
        isWidgetsBarOpen: types.optional(types.boolean, false),
        isHeaderStorageDropDownOpen: types.optional(types.boolean, false),
        openedDrawer: types.optional(types.string, ""),
        uiSettings: types.maybe(UiSettingsType),
        dashboardLayout: types.maybe(DashboardLayoutType),
        shortcutsResult: types.maybe(GetShortcutsResult),
        parametersResult: types.maybe(UiParametersResult),
        uiLockReasonResult: types.maybe(UiLockReasonResult),
    })
    .volatile(() => ({
        drawerCloseHandler: null,
        inFetchUiParamProcess: false,
    }))
    .views((self) => ({
        get currentHeaderHeight() {
            return self.isWidgetsBarOpen ? HEADER_HEIGHT + GRAPHICS_BAR_HEIGHT + STATE_BAR_HEIGHT : HEADER_HEIGHT;
        },
        get dashboardLayoutData() {
            return self.dashboardLayout?.data || {};
        },
        get leftColumn() {
            return self.dashboardLayout?.data.leftColumn || [];
        },
        get rightColumn() {
            return self.dashboardLayout?.data.rightColumn || [];
        },
        get shortcuts() {
            return self.shortcutsResult?.data;
        },
        get parameters() {
            return self.parametersResult?.data?.json;
        },
        get settings() {
            return self.uiSettings?.data;
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
        get isBlockUI() {
            const { upgradesStore } = getParent(self);
            if (upgradesStore.upgradeStepStatus && upgradesStore.upgradeStepStatus !== NOTSTARTED) return true;

            if (self?.uiLockReasonResult?.data?.reason) {
                switch (self.uiLockReasonResult?.data?.reason) {
                    case "MANUAL_RESTORE_COMPLETED":
                        return true;
                    case "AUTO_RESTORE_COMPLETED":
                        return true;
                    default:
                        return false;
                }
            }
            return false;
        },
        get showRestart() {
            const { upgradesStore, authStore } = getParent(self);

            if (self.uiLockReasonResult?.data?.reason && !upgradesStore.upgradeStepStatus) return true;
            if (self.uiLockReasonResult?.data?.reason === "AUTO_RESTORE_COMPLETED") return true;
            if (upgradesStore.upgradeStepStatus === FINISHED && authStore.role === ADMIN) return true;

            return false;
        },
        get upgradeMessage() {
            const { upgradesStore } = getParent(self);
            if (upgradesStore.upgradeStepMessage) return upgradesStore.upgradeStepMessage;

            return self.uiLockReasonResult?.data?.message;
        },
    }))
    .actions((self) => ({
        openSideMenu: () => {
            self.isSideMenuOpen = true;
        },
        closeSideMenu: () => {
            self.isSideMenuOpen = false;
            self.isSideMenuPinned = false;
            self.isSystemMenuOpen = false;
        },
        pinSideMenu: () => {
            self.isSideMenuOpen && (self.isSideMenuPinned = true);
            document.documentElement.style.setProperty("--sidebar-width", `${OPENED_SIDE_MENU_WIDTH}px`);
        },
        unpinSideMenu: () => {
            self.isSideMenuPinned = false;
            document.documentElement.style.setProperty("--sidebar-width", "0px");
        },
        openSystemMenu: () => {
            self.isSystemMenuOpen = true;
        },
        closeSystemMenu: () => {
            self.isSystemMenuOpen = false;
        },
        openNotificationMenu: () => {
            self.isNotificationMenuOpen = true;
            !self.isNotificationMenuPinned && self.openedDrawer !== "" && self.closeDrawer();
        },
        closeNotificationMenu: () => {
            self.isNotificationMenuOpen = false;
            self.isNotificationMenuPinned = false;
        },
        setStateNotificationMenu: (value) => {
            if (value === true) {
                self.openNotificationMenu();
            }
            if (value === false) {
                self.closeNotificationMenu();
            }
        },
        pinNotificationMenu: () => {
            self.isNotificationMenuOpen && (self.isNotificationMenuPinned = true);
            document.documentElement.style.setProperty("--notification-width", `${NOTIFICATION_MENU_WIDTH}px`);
        },
        hideNotificationMenu: () => {
            self.openedDrawer === "" && !self.isNotificationMenuPinned
                ? self.closeNotificationMenu()
                : (self.isNotificationMenuHide = true);
        },
        displayNotificationMenu: () => {
            self.isNotificationMenuHide = false;
        },
        unpinNotificationMenu: () => {
            self.isNotificationMenuPinned = false;
            document.documentElement.style.setProperty("--notification-width", "0px");
        },
        openWidgetsBar: () => {
            self.isWidgetsBarOpen = true;
        },
        closeWidgetsBar: () => {
            self.isWidgetsBarOpen = false;
        },
        openHeaderStorageDropDown: () => {
            self.isHeaderStorageDropDownOpen = true;
        },
        closeHeaderStorageDropDown: () => {
            self.isHeaderStorageDropDownOpen = false;
        },
        fetchUiSettings: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetUiSettings.create().init();
                const res = yield self.socket.send(req);
                self.uiSettings = res;
                changeLanguage(self.settings?.locale);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchDashboardLayout: flow(function* () {
            const { processingStore, authStore } = getParent(self);

            if (authStore.role !== ADMIN) {
                return;
            }

            try {
                processingStore.setLoading(true);
                const req = GetDashboardLayout.create().init();
                const res = yield self.socket.send(req);
                self.dashboardLayout = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setDashboardLayout: flow(function* (errorHandler) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetDashboardLayout.create().init({
                    layout: clone(self.dashboardLayoutData),
                });
                yield self.socket.send(req);

                return true;
            } catch (e) {
                processingStore.setError(e);
                if (errorHandler) {
                    errorHandler();
                }
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setUiSettings: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetUiSettings.create().init({ ...getSnapshot(self.settings), ...data });
                yield self.socket.send(req);

                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchUiParameters: flow(function* (broadcast) {
            if (self.inFetchUiParamProcess) return true;
            self.inFetchUiParamProcess = true;

            if (broadcast) {
                self.parametersResult = { data: broadcast };
                self.inFetchUiParamProcess = false;
                return;
            }

            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetUiParameters.create().init({ json: "{}" });
                const res = yield self.socket.send(req);
                self.parametersResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
                self.inFetchUiParamProcess = false;
            }
        }),
        setUiParameters: actionGuardByRole(
            flow(function* (data) {
                const { processingStore, migrationStore } = getParent(self);
                try {
                    if (migrationStore.isMigrationProcess) return;
                    processingStore.setLoading(true);
                    const req = SetUiParameters.create().init({ json: data });
                    const res = yield self.socket.send(req);
                    return res;
                } catch (e) {
                    processingStore.setError(e);
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            self,
            [ADMIN, SECONDARY_ADMIN]
        ),
        patchUiParameters: actionGuardByRole(
            flow(function* (patch) {
                const { processingStore, migrationStore } = getParent(self);
                try {
                    processingStore.setLoading(true);
                    if (migrationStore.isMigrationProcess) return;
                    let data;

                    if (isStateTreeNode(self.parameters)) {
                        data = { ...getSnapshot(self.parameters), ...patch };
                    }

                    const req = SetUiParameters.create().init({ json: data });
                    yield self.socket.send(req);
                    return true;
                } catch (e) {
                    processingStore.setError(e);
                } finally {
                    processingStore.setLoading(false);
                }
                return null;
            }),
            self,
            [ADMIN, SECONDARY_ADMIN]
        ),
        changeLayoutColumn: ({ fromColumn, fromRow, toColumn, toRow }) => {
            const matrix = [self.leftColumn.slice(), self.rightColumn.slice()];
            const layout = detach(matrix[fromColumn][fromRow]);
            matrix[toColumn].splice(toRow, 0, layout);
            matrix[fromColumn].splice(fromRow + (fromColumn === toColumn && fromRow > toRow), 1);
            self.dashboardLayoutData.leftColumn = matrix[0];
            self.dashboardLayoutData.rightColumn = matrix[1];
        },
        addLayout(element, column, row) {
            if (column === 0) {
                self.dashboardLayoutData.leftColumn.splice(row, 0, element);
            } else if (column === 1) {
                self.dashboardLayoutData.rightColumn.splice(row, 0, element);
            }
        },
        addLayoutToHomePage(widget, contentId = widget, column = 1, row = 0) {
            if (!widget) {
                throw new Error(MESSAGE_WIDGET_NOT_PROVIDER);
            }
            (async () => {
                await self.fetchDashboardLayout();
                self.addLayout({ widget, contentId }, column, row);
                self.setDashboardLayout();
                // todo success message
            })();
        },
        removeLayoutByWidgetAndContentId: (widget, contentId) => {
            const byWidgetAndId = (layout) => {
                if (layout.widget === widget) {
                    if (!contentId || widget.contentId === contentId) return true;
                    if (layout.contentId === contentId) return true;
                }

                return false;
            };

            let index = self.leftColumn.findIndex(byWidgetAndId);

            if (index === -1) {
                index = self.rightColumn.findIndex(byWidgetAndId);

                self.removeLayout(1, index);
            } else {
                self.removeLayout(0, index);
            }
        },
        removeLayout: (column, row) => {
            const { processingStore } = getParent(self);
            let removedLayout;
            try {
                if (column === 0) {
                    removedLayout = self.dashboardLayoutData.leftColumn.splice(row, 1)[0];
                } else if (column === 1) {
                    removedLayout = self.dashboardLayoutData.rightColumn.splice(row, 1)[0];
                }
                self.setDashboardLayout(() => self.addLayout(clone(removedLayout), column, row));
            } catch (e) {
                processingStore.setError(e);
                self.addLayout(clone(removedLayout), column, row);
            } finally {
                // todo success Notistack message
            }
        },
        fetchShortcutLayout: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetShortcuts.create().init();
                const res = yield self.socket.send(req);
                self.shortcutsResult = res || null; // initial data = undefined, empty = null, data = any
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        changeShortcutLayout: (fromIndex, toIndex) => {
            let layoutCopy = self.shortcuts.slice();
            const tmp = detach(self.shortcuts[fromIndex]);
            layoutCopy.splice(fromIndex, 1);
            layoutCopy.splice(toIndex, 0, tmp);
            self.shortcutsResult.data = layoutCopy;
            self.setShortcutLayout({ shortcuts: self.shortcuts.map((s) => clone(s)) });
        },
        removeShortcut: (name) => {
            self.shortcutsResult.data = self.shortcuts.filter((item) => item.name !== name);
            if (!self.shortcuts.length) {
                self.shortcuts.push({ name: WHATS_THIS });
            }
            self.setShortcutLayout({ shortcuts: self.shortcuts.map((s) => clone(s)) });
        },
        addShortcut: (name) => {
            self.setShortcutLayout({ shortcuts: [...self.shortcuts.map((s) => clone(s)), { name }] });
        },
        setShortcutLayout: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetShortcuts.create().init(data);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateShortcuts: (data) => {
            self.shortcutsResult.data = data;
        },
        openDrawer(drawerName, drawerCloseHandler) {
            self.closeDrawer();
            self.openedDrawer = drawerName;
            self.drawerCloseHandler = drawerCloseHandler;
            if (self.isNotificationMenuOpen) {
                !self.isNotificationMenuPinned && self.closeNotificationMenu();
                self.isNotificationMenuPinned && self.hideNotificationMenu();
            }
        },
        changeDrawer() {
            !self.isNotificationMenuHide && self.hideNotificationMenu();
            self.isNotificationMenuHide && self.openedDrawer === "" && self.displayNotificationMenu();
        },
        closeDrawer() {
            if (self.drawerCloseHandler) {
                self.drawerCloseHandler();
            }
            self.drawerCloseHandler = null;
            self.openedDrawer = "";
            self.isNotificationMenuOpen && self.isNotificationMenuPinned && self.displayNotificationMenu();
        },
        onClose() {
            self.closeDrawer();
        },
        compareTimestamp() {
            const { evoSettingsStore } = getParent(self);
            if (self.parameters?.resetTimestamp && self.parameters?.resetTimestamp <= evoSettingsStore.evoInfoTime) {
                const data = { notRedirectToTroubleshooting: false, resetTimestamp: null };
                self.setUiParameters(data);
                self.parametersResult.data.json = data;
                return true;
            }
            return false;
        },
    }))
    .actions((self) => ({
        getUiLockReason: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetUiLockReason.create().init();
                const res = yield Socket.send(req);
                self.uiLockReasonResult = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
    }))
    .actions((self) => ({
        afterCreate() {
            const { authStore } = getParent(self);
            autorun(() => {
                if (authStore.isAuthorized) {
                    self.getUiLockReason();
                }
            });
        },
    }));

export default UiStore;
