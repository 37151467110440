import React from "react";
import { useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import HeaderTitle from "components/HeaderTitle";
import MainTemplate from "components/MainTemplate";
import OptionsIcon from "assets/OptionsIcon";
import ExpansionQuadCard from "./components/ExpansionQuadCard";
import IfacesCard from "./components/IfacesCard";
import ActivatedKeysCard from "./components/ActivatedKeysCard";
import IfacesActivationSideBar from "./components/IfacesActivationSideBar";
import QuadsActivationSideBar from "pages/HardwareActivation/components/QuadsActivationSideBar";
import { HARDWARE_ACTIVATION } from "const/shortcutNameConst";
import MissingQuadsTable from "./components/MissingQuadsTable/MissingQuadsTable";

const HardwareActivation = () => {
    const { t } = useTranslation();
    return useObserver(() => (
        <MainTemplate
            tabIndex={1}
            headerTitle={
                <HeaderTitle icon={<OptionsIcon />} title={t("hardware_activation.title")} shortcutName={HARDWARE_ACTIVATION} />
            }
        >
            <>
                <Grid spacing={6} container>
                    <IfacesActivationSideBar />
                    <QuadsActivationSideBar />
                    <Grid item xs={12}>
                        <ExpansionQuadCard />
                    </Grid>
                    <Grid item xs={12}>
                        <MissingQuadsTable />
                    </Grid>
                    <Grid item xs={12}>
                        <IfacesCard />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ActivatedKeysCard />
                    </Grid>
                </Grid>
            </>
        </MainTemplate>
    ));
};

export default HardwareActivation;
