import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    tableContainer: {
        flex: 1,
        padding: 0
    },
    tableCell: {
        width: "50%"
    },
    controlsContainer: {
        width: "100%",
        margin: 0,
        padding: theme.spacing(2, 4)
    }
}));
