import React, { useContext, useEffect } from "react";
import { Table, TableCell, TableHead, TableRow, TableBody, Box, Grid, Typography } from "@material-ui/core";
import TableCellWithIcon from "components/TableCellWithIcon";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./EditLocalGroupMembersModal.styles";
import { Context } from "store";
import ExternalGroupIcon from "assets/ExternalGroupIcon";
import LocalGroupIcon from "assets/LocalGroupIcon";
import LocalUserIcon from "assets/LocalUserIcon";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import Dialog from "components/Dialog";
import { USERS_LIST_GROUP_MEMBERS_NAME, USERS_LIST_GROUP_MEMBERS_TYPE_LABEL } from "const/sortColumnConst";
import TableSortLabel from "components/TableSortLabel";
import createFields from "./createFields";
import createForm from "utils/createForm";
import SearchLine from "./components/SearchLine";
import clsx from "clsx";
import TrashIcon from "assets/TrashIcon";
import AnimatedSubmitButton from "components/AnimatedSubmitButton/AnimatedSubmitButton";

const EDIT_LOCAL_GROUP_MEMBERS_MODAL_ID_PREFIX = "edit_local_group_members_modal";

const EditLocalGroupMembersModal = ({ open, onClose, parentId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const idPrefix = parentId
        ? `${parentId}_${EDIT_LOCAL_GROUP_MEMBERS_MODAL_ID_PREFIX}`
        : EDIT_LOCAL_GROUP_MEMBERS_MODAL_ID_PREFIX;
    const {
        store: { userStore },
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isSearchInProgress: false,
        actionInProgress: false,
        form: createForm({
            fields: createFields(),
        }),
        selectedUsers: [],
        isSubmitted: false,
    }));

    const onEnter = async () => {
        state.form.reset();
        state.isSearchInProgress = true;
        await userStore.listGroupMembers();
        state.isSearchInProgress = false;
        state.isSubmitted = false;
    };

    useEffect(() => {
        state.selectedUsers = userStore.sortedGroupMembers.map((user) => {
            return { name: user.name, domain: user.domain };
        });
    }, [userStore.sortedGroupMembers]);

    const selectUser = async (name, domain) => {
        if (isUserAlreadySelected(name, domain)) {
            removeUser(name, domain)();
        } else {
            state.actionInProgress = true;
            const res = await userStore.addGroupMembers(userStore.currentEntity?.name, [{ name, domain }]);
            res && (await userStore.listGroupMembers());
            state.actionInProgress = false;
        }
    };

    const onAdd = async (users) => {
        state.actionInProgress = false;
        const trustedDomains = await userStore.getTustedDomains();
        const localDomain = trustedDomains.data[0];
        const promiseSelectUsers = [];
        while (users.length) {
            promiseSelectUsers.push(selectUser(users.pop(), localDomain));
        }

        await Promise.all(promiseSelectUsers);
        onEnter();
        state.actionInProgress = false;
    };

    const isUserAlreadySelected = (name, domain) => {
        return userStore.sortedGroupMembers.some((user) => user.name === name && user.domain === domain);
    };

    const removeUser = (name, domain) => async () => {
        state.actionInProgress = true;
        const res = await userStore.deleteGroupMembers(userStore.currentEntity?.name, [{ name, domain }]);
        res && (await userStore.listGroupMembers());
        state.actionInProgress = false;
    };

    const getType = (isExternal, isGroup) => {
        const getIcon = () => {
            if (isGroup) {
                return isExternal ? <ExternalGroupIcon /> : <LocalGroupIcon />;
            } else {
                return <LocalUserIcon />;
            }
        };
        const getLabel = () => {
            if (isGroup) {
                return isExternal ? t("users.common.external_group") : t("users.common.local_group");
            } else {
                return isExternal ? t("users.common.external_user") : t("users.common.local_user");
            }
        };

        return {
            typeIcon: getIcon(),
            typeLabel: getLabel(),
        };
    };

    const getName = () => {
        return userStore.currentEntity.isExternal
            ? `${userStore.currentEntity.id.name}@${userStore.currentEntity.id.domain}`
            : userStore.currentEntity.id.name;
    };

    const onKeyDown = (e) => {
        e.key === "Escape" && onClose();
    };

    const changeSorting = (column) => () => userStore.groupMembersChangeSorting(column);

    return useObserver(() => (
        <Dialog
            maxWidth={"md"}
            onKeyDown={onKeyDown}
            withoutPaddings
            open={open}
            onClose={onClose}
            onEnter={onEnter}
            title={t("users.side_menu.group_members_modal.title", { name: getName() })}
            submitBtn={
                <AnimatedSubmitButton
                    variant={"contained"}
                    color={"primary"}
                    submit={onClose}
                    inProgress={state.actionInProgress}
                    label={t("common.button.close")}
                    id={`${idPrefix}_close`}
                />
            }
            additionalTitle={
                <Box pt={4} pb={4} pr={6} pl={6}>
                    <Grid container wrap={"nowrap"} alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item xs={4}>
                            <Typography>{t("users.edit_local_group_members_modal")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <SearchLine
                                form={state.form}
                                isOptionChecked={isUserAlreadySelected}
                                onSelect={selectUser}
                                onAdd={onAdd}
                                parentId={idPrefix}
                            />
                        </Grid>
                    </Grid>
                </Box>
            }
        >
            {userStore.sortedGroupMembers.length > 0 ? (
                <Grid container className={classes.tableBody}>
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={userStore.groupMembersOrderBy === USERS_LIST_GROUP_MEMBERS_NAME}
                                        onClick={changeSorting(USERS_LIST_GROUP_MEMBERS_NAME)}
                                        direction={userStore.groupMembersOrder}
                                    >
                                        {t("users.search_results.table.column.name")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={userStore.groupMembersOrderBy === USERS_LIST_GROUP_MEMBERS_TYPE_LABEL}
                                        onClick={changeSorting(USERS_LIST_GROUP_MEMBERS_TYPE_LABEL)}
                                        direction={userStore.groupMembersOrder}
                                    >
                                        {t("users.search_results.table.column.type")}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userStore.sortedGroupMembers.map((user) => {
                                const { typeIcon, typeLabel } = getType(user.isExternal, user.isGroup);
                                return (
                                    <TableRow key={`${user.domainName}`}>
                                        <TableCell className={classes.tableCell}>
                                            {user.isExternal ? `${user.id.name}@${user.id.domain}` : user.id.name}
                                        </TableCell>
                                        <TableCellWithIcon
                                            className={clsx(classes.tableCell, classes.tableCellWithIcon)}
                                            align="left"
                                            icon={typeIcon}
                                        >
                                            {typeLabel}
                                        </TableCellWithIcon>
                                        <TableCell
                                            className={clsx(classes.tableCellWithIcon, classes.tableDelete)}
                                            onClick={removeUser(user.name, user.domain)}
                                        >
                                            <TrashIcon />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            ) : (
                <EmptyCardPlaceholder
                    hasStaticHeight
                    inProgress={state.isSearchInProgress}
                    message={t("users.side_menu.group_members_modal.placeholder")}
                />
            )}
        </Dialog>
    ));
};

export default EditLocalGroupMembersModal;
