import React from "react";

const CheckboxPassiveIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#D9E4ED"
                fillRule="nonzero"
                d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm0 2H4a2 2 0 0 0-1.995 1.85L2 4v16a2 2 0 0 0 1.85 1.995L4 22h16a2 2 0 0 0 1.995-1.85L22 20V4a2 2 0 0 0-1.85-1.995L20 2z"
            />
        </svg>
    );
};

export default CheckboxPassiveIcon;
