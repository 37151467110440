import React from "react";
import { useObserver } from "mobx-react";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import Checkbox from "components/Checkbox";
import { useStyles } from "./AccessRow.styles";
import { RW, RO, CUSTOM } from "const/shareRootAccessConst";
import useEvoPrefix from "hooks/useEvoPrefix";

const ACCESS_ROW_ID_PREFIX = "access_row";

const AccessRow = ({ access, getIsChecked, onCheck, openCustomIfNeeded, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${ACCESS_ROW_ID_PREFIX}` : ACCESS_ROW_ID_PREFIX;
    const classes = useStyles();
    const prefix = useEvoPrefix({ ip: access.ip });

    return useObserver(() => (
        <TableRow>
            <TableCell className={classes.fstTableCell}>
                <Typography noWrap>{`${access.ip ? prefix : ""}${access.shareName}`}</Typography>
            </TableCell>
            <TableCell>
                <Checkbox
                    checked={getIsChecked(access, RW)}
                    onChange={onCheck(access)}
                    value={RW}
                    id={`${idPrefix}_checkbox_rw_${access.ip ? prefix : ""}${access.shareName}`}
                />
            </TableCell>
            <TableCell>
                <Checkbox
                    checked={getIsChecked(access, RO)}
                    onChange={onCheck(access)}
                    value={RO}
                    id={`${idPrefix}_checkbox_ro_${access.ip ? prefix : ""}${access.shareName}`}
                />
            </TableCell>
            <TableCell>
                <Checkbox
                    checked={getIsChecked(access, CUSTOM)}
                    onChange={openCustomIfNeeded(access)}
                    value={CUSTOM}
                    id={`${idPrefix}_checkbox_custom_${access.ip ? prefix : ""}${access.shareName}`}
                />
            </TableCell>
        </TableRow>
    ));
};

export default AccessRow;
