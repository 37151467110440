import React, { useContext, useMemo } from "react";
import { Context } from "store";
import { useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TableCell, TableRow } from "@material-ui/core";

import getSlingshotSchedule from "utils/getSlingshotSchedule";
import TableRowControlButton from "components/TableRowControlButton";
import ChevronRightIcon from "assets/ChevronRightIcon";
import moment from "moment";
import { SLINGSHOT_DATE_HOURS_FORMAT } from "const";
import StatusLabel from "../StatusLabel";
import { useStyles } from "./ReplicationRow.styles";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const REPLICATIONS_ROW_ID_PREFIX = `replications_row`;

const ReplicationRow = ({ job, onClick, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${REPLICATIONS_ROW_ID_PREFIX}` : REPLICATIONS_ROW_ID_PREFIX;
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { evoSettingsStore, syncJobsStore },
    } = useContext(Context);

    const getSchedule = useMemo(() => {
        return getSlingshotSchedule(job, evoSettingsStore.evoInfoTime);
    }, [job, t]);

    const getFormattedLastJob = (lastJob) => {
        return lastJob
            ? moment.utc(lastJob).format(getLanguageDateFormat(SLINGSHOT_DATE_HOURS_FORMAT))
            : t("slingshot_page.tabs.replications.table.last_job.never");
    };

    return useObserver(() => (
        <TableRow selected={syncJobsStore.currentJobId === job.id} key={job.id} onClick={onClick}>
            <TableCell className={classes.cellWithOverflow}>{job.name}</TableCell>
            <TableCell align={"left"}>
                <StatusLabel status={job.status} />
            </TableCell>
            <TableCell className={classes.cellWithOverflow}>{decodeURIComponent(job.source)}</TableCell>
            <TableCell className={classes.cellWithOverflow}>{decodeURIComponent(job.destination)}</TableCell>
            <TableCell>{getSchedule}</TableCell>
            <TableCell>{getFormattedLastJob(job.last_job_finished)}</TableCell>
            <TableCell align={"right"}>
                <TableRowControlButton icon={<ChevronRightIcon />} id={`${idPrefix}_arrow_button`} />
            </TableCell>
        </TableRow>
    ));
};

export default ReplicationRow;
