import React, { useEffect, useContext, useCallback } from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { useObserver, useLocalStore } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography, Link } from "@material-ui/core";
import { useStyles } from "./WireguardDrawer.styles";
import SavedInputIndicator from "components/SavedInputIndicator";
import TrashIcon from "assets/TrashIcon";
import EthernetIcon from "assets/EthernetIcon";
import OffIcon from "assets/OffIcon";
import clsx from "clsx";
import { DeleteVPNConnectionDialog } from "pages/Users/components/DeleteVPNConnectionDialog";
import { DisableVPNConnectionDialog } from "pages/Users/components/DisableVPNConnectionDialog";

const drawerName = "wireguardDrawer";

const WireguardDrawer = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: { uiStore, wireguardStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isDeactivateInProgress: false,
        isActivateInProgress: false,
        isRemoveInProgress: false,
        isEnableAutostartInProgress: false,
        isDisableAutostartInProgress: false,
        isDeleteVPNConnectionDialogOpen: false,
        isDisableVPNConnectionDialogOpen: false
    }));

    const closeDrawer = useCallback(() => {
        wireguardStore.setCurrentConnectionName(null);
    }, []);

    const openDeleteVPNConnectionDialog = () => {
        state.isDeleteVPNConnectionDialogOpen = true;
    };

    const openDisableVPNConnectionDialog = () => {
        state.isDisableVPNConnectionDialogOpen = true;
    };

    const closeDeleteVPNConnectionDialog = () => {
        state.isDeleteVPNConnectionDialogOpen = false;
    };

    const closeDisableVPNConnectionDialog = () => {
        state.isDisableVPNConnectionDialogOpen = false;
    };

    const enableAutostart = useCallback(async () => {
        state.isEnableAutostartInProgress = true;
        const res = await wireguardStore.setAutostartEnabled(wireguardStore.currentConnection, true);
        state.isEnableAutostartInProgress = false;
        res && closeDrawer();
    }, []);

    const disableAutostart = useCallback(async () => {
        state.isDisableAutostartInProgress = true;
        const res = await wireguardStore.setAutostartEnabled(wireguardStore.currentConnection, false);
        state.isDisableAutostartInProgress = false;
        res && closeDrawer();
    }, []);

    const deleteConnection = useCallback(async () => {
        state.isRemoveInProgress = true;
        const res = await wireguardStore.deleteConnection(wireguardStore.currentConnection);
        state.isRemoveInProgress = false;
        closeDeleteVPNConnectionDialog();
        res && closeDrawer();
    }, []);

    const deactivateConnection = useCallback(async () => {
        state.isDeactivateInProgress = true;
        const res = await wireguardStore.setActivated(wireguardStore.currentConnection, false);
        state.isDeactivateInProgress = false;
        closeDisableVPNConnectionDialog();
        res && closeDrawer();
    }, []);

    const activateConnection = useCallback(async () => {
        state.isActivateInProgress = true;
        const res = await wireguardStore.setActivated(wireguardStore.currentConnection, true);
        state.isActivateInProgress = false;
        res && closeDrawer();
    }, []);

    useEffect(() => {
        if (wireguardStore.currentConnectionName) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, closeDrawer);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [wireguardStore.currentConnectionName]);

    const renderDetails = () => {
        const currentConnection = wireguardStore.currentConnection;
        return currentConnection ? (
            <Grid container direction={"column"} className={classes.detailsContainer} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("wireguard.table.header.protocol")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentConnection.protocol}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("wireguard.table.header.endpoint")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Link className={classes.textInfo} href={currentConnection.endpointLink} target="_blank">
                            {currentConnection.endpoint}
                        </Link>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("wireguard.table.header.local_address")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentConnection.localIP}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography component={"span"} className={classes.labelText}>
                            {t("wireguard.table.header.status")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={clsx(classes.statusText, classes.textInfo)}>
                            {currentConnection.connectionState}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("wireguard.table.header.autostart")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <SavedInputIndicator enabled={currentConnection.autostart} />
                    </Grid>
                </Grid>
            </Grid>
        ) : null;
    };

    const renderActions = () => {
        const currentConnection = wireguardStore.currentConnection;
        return currentConnection ? (
            <Grid direction={"column"} container>
                {(currentConnection.connectionState === "online" || currentConnection.connectionState === "connecting") && (
                    <BulkBarFlatButton icon={<OffIcon />} onClick={openDisableVPNConnectionDialog}>
                        {t("wireguard.table.drawer.action.deactivate")}
                    </BulkBarFlatButton>
                )}
                {currentConnection.connectionState === "offline" && (
                    <BulkBarFlatButton inProgress={state.isActivateInProgress} icon={<OffIcon />} onClick={activateConnection}>
                        {t("wireguard.table.drawer.action.activate")}
                    </BulkBarFlatButton>
                )}
                {currentConnection.autostart ? (
                    <BulkBarFlatButton
                        inProgress={state.isDisableAutostartInProgress}
                        icon={<OffIcon />}
                        onClick={disableAutostart}
                    >
                        {t("wireguard.table.drawer.action.disable_autostart")}
                    </BulkBarFlatButton>
                ) : (
                    <BulkBarFlatButton
                        inProgress={state.isEnableAutostartInProgress}
                        icon={<OffIcon />}
                        onClick={enableAutostart}
                    >
                        {t("wireguard.table.drawer.action.enable_autostart")}
                    </BulkBarFlatButton>
                )}
                <BulkBarFlatButton errorColor onClick={openDeleteVPNConnectionDialog} icon={<TrashIcon />}>
                    {t("common.button.delete")}
                </BulkBarFlatButton>
            </Grid>
        ) : null;
    };

    return useObserver(() => (
        <>
            <DetailsActionsDrawer
                title={wireguardStore.currentConnectionName}
                renderActions={renderActions}
                renderDetails={renderDetails}
                closeDrawer={closeDrawer}
                drawerName={drawerName}
                icon={<EthernetIcon />}
            />
            <DeleteVPNConnectionDialog
                connectionName={wireguardStore.currentConnectionName}
                inProgress={state.isRemoveInProgress}
                onAccept={deleteConnection}
                open={state.isDeleteVPNConnectionDialogOpen}
                onClose={closeDeleteVPNConnectionDialog}
            />
            <DisableVPNConnectionDialog
                connectionName={wireguardStore.currentConnectionName}
                inProgress={state.isDeactivateInProgress}
                onAccept={deactivateConnection}
                open={state.isDisableVPNConnectionDialogOpen}
                onClose={closeDisableVPNConnectionDialog}
            />
        </>
    ));
};

export default WireguardDrawer;
