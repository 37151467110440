import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./ChangeOwnerModal.styles";
import createFields from "./createFields";
import createForm from "utils/createForm";
import Dialog from "components/Dialog";
import Switch from "components/MobxForm/Switch";
import Select from "components/MobxForm/Select";
import RowSwitch from "components/RowSwitch";
import SearchLine from "../SearchLine";
import { LOCAL_USERS, EXTERNAL_USERS, LOCAL_GROUPS, EXTERNAL_GROUPS } from "const/userSearchVariants";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useModal } from "hooks/useModal";
import { useFileNavigatorStoreByIp, useFileNavigatorStoreCommon } from "pages/FileNavigator/FileNavigator.hooks";
import useStoreByIp from "hooks/useStoreByIp";
import { useObserveChangeField } from "hooks/useObserveChangeField";
import AnimatedSubmitButton from "components/AnimatedSubmitButton/AnimatedSubmitButton";

const ChangeOwnerModal = ({ open, onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const fields = createFields();

    const fileNavigatorCommon = useFileNavigatorStoreCommon();

    const { fileStore, aclStore, userStore } = useStoreByIp({ ip: fileNavigatorCommon.currentEvoIp });
    const fileNavigator = useFileNavigatorStoreByIp(fileNavigatorCommon.currentEvoIp);

    const state = useLocalStore(() => ({
        form: createForm({
            fields,
            options: {
                validateOnInit: false,
                validateOnBlur: false,
                showErrorsOnReset: false,
            },
        }),
        isSaveInProgress: false,
        isSubmitted: false,
        isFieldChanged: false,
    }));

    const unsavedChangesModal = useModal();
    const observerFieldName = useObserveChangeField(state.form.$("name"));

    const setOwner = async (owner) => {
        state.isSaveInProgress = true;

        const res = await aclStore.setOwner({
            shareName: fileStore.currentShareName,
            path: fileStore.currentFilePath,
            recursive: state.form.$("recursive").value,
            owner,
        });

        state.isSaveInProgress = false;

        if (res) {
            state.isSubmitted = true;

            await new Promise((resolve) =>
                setTimeout(() => {
                    fileStore.removeCurrentFile();
                    resolve();
                }, 1000)
            );

            return res;
        }

        return res;
    };

    const onSave = async (e) => {
        state.form.onSubmit(e);

        if (observerFieldName.hasChange) {
            state.form.$("name").invalidate(t("common.edit_permission_modal.select.error"));
            return;
        }

        if (!state.form.isValid) return;

        const res = await setOwner({
            name: state.form.$("name").value,
            domain: state.form.$("domain").value,
        });

        if (res) {
            setTimeout(() => {
                onClose();
                fileNavigator.clearSelectedFiles();
            }, 1000);
        }
    };

    const onAdd = async (elements) => {
        const trustedDomains = await userStore.getTustedDomains();
        await setOwner({
            name: elements.pop(),
            domain: trustedDomains.data[0],
        });
        onClose();
    };

    const onEnter = () => {
        state.form.reset();
        state.isSaveInProgress = false;
        state.isSubmitted = false;
        unsavedChangesModal.close();
        userStore.resetSearchByTypeResult();
        observerFieldName.reset();
    };

    const closeIfNeeded = () => {
        state.isFieldChanged = false;
        state.form.fields.forEach((field) => {
            if (field.name !== "name" && field.name !== "domain" && !field.isDefault) {
                state.isFieldChanged = true;
            }
        });
        if (!state.isFieldChanged || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            buttons={
                <Button onClick={onClose} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>
            }
            submitBtn={<AnimatedSubmitButton label={t("common.button.save")} isSubmitted={state.isSubmitted} submit={onSave} />}
            open={open}
            onClose={closeIfNeeded}
            onEnter={onEnter}
            title={t("file_navigator.change_owner_modal.title", { name: fileStore.currentFileNameWithPrefix })}
            inProgress={state.isSaveInProgress}
        >
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            <Grid container direction={"column"}>
                <Grid
                    className={classes.typeSelectRowContainer}
                    container
                    wrap={"nowrap"}
                    alignItems={"center"}
                    justify={"space-between"}
                >
                    <Grid item xs={5} className={classes.typeSelectContainer}>
                        <Select
                            field={state.form.$("type")}
                            options={[
                                {
                                    label: t("users.common.local_users"),
                                    value: LOCAL_USERS,
                                },
                                {
                                    label: t("users.common.external_users"),
                                    value: EXTERNAL_USERS,
                                },
                                {
                                    label: t("users.common.local_groups"),
                                    value: LOCAL_GROUPS,
                                },
                                {
                                    label: t("users.common.external_groups"),
                                    value: EXTERNAL_GROUPS,
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SearchLine
                            onSelectUser={observerFieldName.reset}
                            form={state.form}
                            ip={fileNavigatorCommon.currentEvoIp}
                            onAdd={onAdd}
                        />
                    </Grid>
                </Grid>
                <RowSwitch
                    control={<Switch field={state.form.$("recursive")} />}
                    label={t("file_navigator.change_owner_modal.label.recursive")}
                />
            </Grid>
        </Dialog>
    ));
};

export default ChangeOwnerModal;
