import { types } from "mobx-state-tree";

import Request from "api/Request";
import DeferredParityCalculation from "api/volume/Types/DeferredParityCalculation";

const SetDeferredParityCalculation = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Volume",
    method: "set_deferred_parity_calculation",
    arguments: types.maybe(DeferredParityCalculation)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetDeferredParityCalculation;
