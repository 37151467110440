import { types } from "mobx-state-tree";

const Volume = types.model({
    name: types.string,
    type: types.string,
    status: types.string,
    transport: types.string,
    replica: types.number,
    arbiter: types.number,
    disperse: types.number,
    redundancy: types.number
});

export default Volume;
