import Request from "api/Request";

const ClearAuthenticationLog = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Audit",
    method: "clear_authentication_log"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ClearAuthenticationLog;
