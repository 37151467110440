import { types } from "mobx-state-tree";

const BatchEvent = types.model({
    id: types.number,
    orderId: types.number,
    time: types.string,
    eventLevel: types.string,
    eventType: types.string,
    message: types.string,
    taskId: types.number,
    failedText: types.string,
    total: types.number,
    complete: types.number,
    failed: types.number
});

export default BatchEvent;
