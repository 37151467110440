import { flow, getParent, types } from "mobx-state-tree";
import Socket from "websocket";
import CpuInfosResult from "api/evo_info/Responses/CpuInfosResult";
import SystemMemoryResult from "api/evo_info/Responses/SystemMemoryResult";
import SersorsResult from "api/evo_info/Responses/SersorsResult";
import RaidControllersResult from "api/evo_info/Responses/RaidControllersResult";
import NetworkInterfacesResult from "api/evo_info/Responses/NetworkInterfacesResult";
import GetCpuInfo from "api/evo_info/Requests/GetCpuInfo";
import GetSystemMemoryInfo from "api/evo_info/Requests/GetSystemMemoryInfo";
import GetSensorsInfo from "api/evo_info/Requests/GetSensorsInfo";
import GetRaidControllersInfo from "api/evo_info/Requests/GetRaidControllersInfo";
import GetNetworkInterfacesInfo from "api/evo_info/Requests/GetNetworkInterfacesInfo";
import {
    ASC,
    DESC,
    HARDWARE_PROFILE_CPU_MODEL,
    HARDWARE_PROFILE_ETHERNET_MODEL,
    HARDWARE_PROFILE_SENSORS_NAME,
    HARDWARE_PROFILE_RAID_CONTROLLER
} from "const/sortColumnConst";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";

const HardwareProfileStore = types
    .model({
        cpuInfo: types.maybe(CpuInfosResult),
        memoryDevice: types.maybe(SystemMemoryResult),
        sensorsData: types.maybe(SersorsResult),
        raidController: types.maybe(RaidControllersResult),
        networkInterface: types.maybe(NetworkInterfacesResult)
    })
    .volatile(() => ({
        orderByCpu: HARDWARE_PROFILE_CPU_MODEL,
        orderCpu: ASC,
        orderBySensor: HARDWARE_PROFILE_SENSORS_NAME,
        orderSensor: ASC,
        orderByRaid: HARDWARE_PROFILE_RAID_CONTROLLER,
        orderRaid: ASC,
        orderByNetwork: HARDWARE_PROFILE_ETHERNET_MODEL,
        orderNetwork: ASC
    }))
    .views(self => ({
        get sortedCpu() {
            return stableSort(self.cpuList, getComparator(self.orderCpu, self.orderByCpu));
        },
        get cpuList() {
            return self.cpuInfo?.data || [];
        },
        get sortedSensors() {
            return stableSort(self.sensorsList, getComparator(self.orderSensor, self.orderBySensor));
        },
        get sensorsList() {
            return (self.sensorsData?.data && [...self.sensorsData.data.temperatureSensors]) || [];
        },
        get sortedRaidControllers() {
            return stableSort(self.raidControllersList, getComparator(self.orderRaid, self.orderByRaid));
        },
        get raidControllersList() {
            return self.raidController?.data || [];
        },
        get sortedNetworkInterfaces() {
            return stableSort(self.networkInterfaceList, getComparator(self.orderNetwork, self.orderByNetwork));
        },
        get networkInterfaceList() {
            return self.networkInterface?.data || [];
        },
        get memoryDeviceModules() {
            const sortedDeviceModules =
                self.memoryDevice?.data
                    .map(item => item.size)
                    .reduce((acc, rec) => {
                        return typeof acc[rec] !== "undefined" ? { ...acc, [rec]: acc[rec] + 1 } : { ...acc, [rec]: 1 };
                    }, {}) || [];

            const fieldsModules = Object.keys(sortedDeviceModules)
                .map(key => ({
                    name: key,
                    value: sortedDeviceModules[key]
                }))
                .sort()
                .reverse();
            return fieldsModules;
        },
        get memoryDeviceTotal() {
            return (
                (self.memoryDevice?.data &&
                    self.memoryDevice.data.map(item => item.size).reduce((prev, curr) => prev + curr, 0)) ||
                0
            );
        }
    }))
    .actions(self => ({
        fetchCpuInfo: flow(function*() {
            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);
                const req = GetCpuInfo.create().init();
                const res = yield Socket.send(req);
                self.cpuInfo = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchSystemMemoryInfo: flow(function*() {
            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);
                const req = GetSystemMemoryInfo.create().init();
                const res = yield Socket.send(req);
                self.memoryDevice = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchSensorsInfo: flow(function*() {
            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);
                const req = GetSensorsInfo.create().init();
                const res = yield Socket.send(req);
                self.sensorsData = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchRaidControllersInfo: flow(function*() {
            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);
                const req = GetRaidControllersInfo.create().init();
                const res = yield Socket.send(req);
                self.raidController = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchNetworkInterfaceInfo: flow(function*() {
            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);
                const req = GetNetworkInterfacesInfo.create().init();
                const res = yield Socket.send(req);
                self.networkInterface = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        changeSortingCpu(column) {
            if (column === self.orderByCpu) {
                self.orderCpu = self.orderCpu === DESC ? ASC : DESC;
            } else {
                self.orderCpu = ASC;
                self.orderByCpu = column;
            }
        },
        changeSortingSensor(column) {
            if (column === self.orderBySensor) {
                self.orderSensor = self.orderSensor === DESC ? ASC : DESC;
            } else {
                self.orderSensor = ASC;
                self.orderBySensor = column;
            }
        },
        changeSortingRaid(column) {
            if (column === self.orderByRaid) {
                self.orderRaid = self.orderRaid === DESC ? ASC : DESC;
            } else {
                self.orderRaid = ASC;
                self.orderByRaid = column;
            }
        },
        changeSortingNetwork(column) {
            if (column === self.orderByNetwork) {
                self.orderNetwork = self.orderNetwork === DESC ? ASC : DESC;
            } else {
                self.orderNetwork = ASC;
                self.orderByNetwork = column;
            }
        }
    }));

export default HardwareProfileStore;
