const createInitialFields = () => {
    return [
        {
            name: "client"
        },
        {
            name: "access"
        }
    ];
};

export default createInitialFields;
