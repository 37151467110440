import { makeStyles } from "@material-ui/core/styles";
import Layer from "assets/images/layer.jpg";

export const useStyles = makeStyles((theme) => ({
    previewContainer: {
        backgroundImage: `url(${Layer})`,
        backgroundSize: "contain",
    },
    watermarkImage: {
        objectFit: "contain",
        padding: theme.spacing(2),
    },
}));
