import { getChartAPIUrl } from "utils/chart";
import mainStore from "store";
import { AxiosCommonRequestNoCache } from "api/AxiosCommonRequest";

/**
@typedef {{
  chart: string;
  points: number;
  after: number;
  group: string;
  format:string;
  path: string
}} ChartUrlParams
*/
/**
 *
 * @param {ChartUrlParams} options
 * @param options.chart - name of chart.
 * @param options.points - number of points. By default, 40.
 * @param options.after - the number of milliseconds from the current time. By default, -600.
 * @param options.group - name of group. By default, "average".
 * @param options.format - the format in which the data will come. By default, "json".
 * @param options.path - address on the network to the source. By default, the current ip is EVO.
 * @returns {Promise} Promise after execute a request through the axios of the current eve, or through the multi-EVO axios if there is an instance of the multi-EVO store by IP.
 * @example
 * const response = await multipleAxios({ path: "192.168.163.223", chart: "system.io", points: 120, after: -900 });
 */
export const multipleAxios = (options) => {
    if (!options.path) {
        return AxiosCommonRequestNoCache.get(getChartAPIUrl(options));
    }

    const axiosNoCache = mainStore.multipleEvosStore.getAxiosNoCacheByIp(options.path);
    return axiosNoCache.get(getChartAPIUrl(options));
};
