import React from "react";

const ThinArrowRightIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
            <path
                fill="#BFD1E1"
                fillRule="evenodd"
                d="M9.284 3.589l.07.057L13.707 8l-4.353 4.354a.501.501 0 01-.765-.638l.057-.07L11.793 8.5H3a.5.5 0 010-1h8.793L8.646 4.354a.501.501 0 01-.057-.638l.057-.07a.501.501 0 01.638-.057z"
            ></path>
        </svg>
    );
};

export default ThinArrowRightIcon;
