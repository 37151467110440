import React, { useEffect } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import {
    ASC,
    DESC,
    BACKUPS_TABLE_PATH,
    BACKUPS_TABLE_ID,
    BACKUPS_TABLE_TIME,
    BACKUPS_TABLE_VERSION
} from "const/sortColumnConst";

import DataCard from "components/DataCard";
import ChevronRightIcon from "assets/ChevronRightIcon";
import MoreMenu from "components/MoreMenu";
import { useTranslation } from "react-i18next";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { BACKUPS_TABLE_CARD } from "const/widgetNameConst";
import { useLocalStore, useObserver } from "mobx-react-lite";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import TableSortLabel from "components/TableSortLabel";
import TableExpand from "components/TableExpand";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import BackupsTableDrawer from "../BackupsTableDrawer";
import TableRowControlButton from "components/TableRowControlButton";
import { useStore } from "hooks/useStore";

const BackupsTableCard = () => {
    const { t } = useTranslation();

    const {
        store: { backupSettingsStore }
    } = useStore();

    useEffect(() => {
        (async () => {
            state.inProgress = true;
            await backupSettingsStore.fetchEvoId();
            await backupSettingsStore.fetchDevices();
            state.inProgress = false;
        })();
    }, []);

    const HIDDEN_ROWS_PER_CARD_INDEX = 6;
    const state = useLocalStore(() => ({
        orderBy: BACKUPS_TABLE_TIME,
        order: DESC,
        isExpanded: false,
        isAddConnectionDialogOpened: false,
        inProgress: false,
        get slicedIndex() {
            if (this.isExpanded) {
                return this.backups.length;
            } else {
                return HIDDEN_ROWS_PER_CARD_INDEX;
            }
        },
        get backups() {
            return backupSettingsStore.backupDevices;
        },
        get sortedBackups() {
            return this.backups && stableSort(this.backups, getComparator(this.order, this.orderBy));
        }
    }));

    const changeSorting = column => () => {
        if (column === state.orderBy) {
            state.order = state.order === DESC ? ASC : DESC;
            return;
        }

        state.order = ASC;
        state.orderBy = column;
    };

    const toggleExpandShares = () => {
        state.isExpanded = !state.isExpanded;
    };

    const onRowClick = backup => () => {
        backupSettingsStore.setCurrentBackupPath(backup);
    };

    return useObserver(() => (
        <DataCard
            title={t("support.os_data.backups.title")}
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={BACKUPS_TABLE_CARD} />
                </MoreMenu>
            }
            emptyPadding
            headerDivider
        >
            <TableContainer component={Paper} elevation={0}>
                {state.backups?.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        direction={state.order}
                                        onClick={changeSorting(BACKUPS_TABLE_PATH)}
                                        active={state.orderBy === BACKUPS_TABLE_PATH}
                                    >
                                        {t("support.os_data.backups.name")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={state.order}
                                        onClick={changeSorting(BACKUPS_TABLE_TIME)}
                                        active={state.orderBy === BACKUPS_TABLE_TIME}
                                    >
                                        {t("support.os_data.backups.date")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={state.order}
                                        onClick={changeSorting(BACKUPS_TABLE_VERSION)}
                                        active={state.orderBy === BACKUPS_TABLE_VERSION}
                                    >
                                        {t("support.os_data.backups.version")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={state.order}
                                        onClick={changeSorting(BACKUPS_TABLE_ID)}
                                        active={state.orderBy === BACKUPS_TABLE_ID}
                                    >
                                        {t("support.os_data.backups.id")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.sortedBackups?.slice(0, state.slicedIndex).map(backup => {
                                return (
                                    <TableRow
                                        selected={backupSettingsStore.currentBackupPath === backup.path}
                                        key={`${backup.path}-${backup.id}-${backup.ver}`}
                                        onClick={onRowClick(backup)}
                                    >
                                        <TableCell>
                                            {backup.id === backupSettingsStore.evoId
                                                ? t("support.os_data.backups.table.this_evo")
                                                : t("support.os_data.backups.table.foreign_evo")}
                                        </TableCell>
                                        <TableCell>{backup.timeString}</TableCell>
                                        <TableCell>{backup.evoVersion}</TableCell>
                                        <TableCell>{backup.id}</TableCell>
                                        <TableCell>
                                            <TableRowControlButton icon={<ChevronRightIcon />} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        {state.backups?.length > HIDDEN_ROWS_PER_CARD_INDEX && (
                            <TableExpand onClick={toggleExpandShares}>
                                {state.isExpanded
                                    ? t("support.os_data.backups.table.hide")
                                    : t("support.os_data.backups.table.show_all", {
                                          count: state.backups?.length
                                      })}
                            </TableExpand>
                        )}
                    </Table>
                ) : (
                    <EmptyCardPlaceholder
                        inProgress={state.inProgress}
                        message={t("support.os_data.backups.table.placeholder")}
                    />
                )}
            </TableContainer>
            <BackupsTableDrawer />
        </DataCard>
    ));
};

export default BackupsTableCard;
