import React, { useContext, useEffect } from "react";
import { useObserver } from "mobx-react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import DataCard from "components/DataCard";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import MoreMenu from "components/MoreMenu";
import TableSortLabel from "components/TableSortLabel";
import { ETHERNET_CARD } from "const/widgetNameConst";
import { HARDWARE_PROFILE_ETHERNET_MODEL, HARDWARE_PROFILE_ETHERNET_FIRMWARE } from "const/sortColumnConst";

const EthernetCard = () => {
    const { t } = useTranslation();

    const {
        store: { hardwareProfileStore }
    } = useContext(Context);
    useEffect(() => {
        hardwareProfileStore.fetchNetworkInterfaceInfo();
    }, []);
    const changeSorting = column => () => hardwareProfileStore.changeSortingNetwork(column);

    return useObserver(() => (
        <DataCard
            title={t("hardware_profile.ethernet_card.title")}
            autoHeight
            emptyPadding
            headerDivider
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={ETHERNET_CARD} />
                </MoreMenu>
            }
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                onClick={changeSorting(HARDWARE_PROFILE_ETHERNET_MODEL)}
                                direction={hardwareProfileStore.orderNetwork}
                                active={hardwareProfileStore.orderByNetwork === HARDWARE_PROFILE_ETHERNET_MODEL}
                            >
                                {t("hardware_profile.ethernet.header.model")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell width={"30%"}>
                            <TableSortLabel
                                onClick={changeSorting(HARDWARE_PROFILE_ETHERNET_FIRMWARE)}
                                direction={hardwareProfileStore.orderNetwork}
                                active={hardwareProfileStore.orderByNetwork === HARDWARE_PROFILE_ETHERNET_FIRMWARE}
                            >
                                {t("hardware_profile.ethernet.header.firmware")}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {hardwareProfileStore.sortedNetworkInterfaces.map(row => {
                        return (
                            <TableRow key={Math.random()}>
                                <TableCell>{row.interfaceModel}</TableCell>
                                <TableCell>{row.firmware}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </DataCard>
    ));
};

export default EthernetCard;
