import React from "react";
import PicSupportIcon from "assets/PicSupportIcon";
import { Button, Link } from "@material-ui/core";
import InfoCard from "components/InfoCard";
import { useTranslation } from "react-i18next";
import { CONTACT_SUPPORT } from "const/widgetNameConst";
import { EXTERNAL_URL_SUPPORT } from "const/externalURLs";

const ContactSupport = () => {
    const { t } = useTranslation();
    return (
        <InfoCard
            widget={CONTACT_SUPPORT}
            iconMargin={40}
            flag
            icon={<PicSupportIcon />}
            title={t("support.start.contact.title")}
            content={t("support.start.contact.content")}
            button={
                <Link href={EXTERNAL_URL_SUPPORT} target="_blank" rel="noopener" variant={"button"}>
                    <Button color={"primary"} variant={"contained"}>
                        {t("common.button.contact")}
                    </Button>
                </Link>
            }
        />
    );
};

export default ContactSupport;
