import { types } from "mobx-state-tree";
import ErrorOptions from "./ErrorOptions";

const ServerError = types.model({
    key: types.union(types.number, types.string),
    message: types.optional(types.string, "Unknown error"),
    options: types.optional(ErrorOptions, { variant: "error" })
});

export default ServerError;
