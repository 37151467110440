import React, { useContext, useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Context } from "store";
import SavedInputIndicator from "components/SavedInputIndicator/SavedInputIndicator";
import DataReadonlyCard from "components/DataReadonlyCard/DataReadonlyCard";
import MoreMenu from "components/MoreMenu/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem/HomeCardActionMenuItem";
import { DEFERRED_PARITY_CALCULATION_CARD } from "const/widgetNameConst";
import DeferredParityCalculationDialog from "../DeferredParityCalculationDialog/DeferredParityCalculationDialog";

const DeferredParityCalculationCard = () => {
    const { t } = useTranslation();
    const {
        store: { volumeStore }
    } = useContext(Context);

    useEffect(() => {
        volumeStore.fetchDeferredParityCalculation();
    }, []);

    const state = useLocalStore(() => ({
        isEditModalOpen: false
    }));

    const openEditModal = () => {
        state.isEditModalOpen = true;
    };
    const closeEditModal = () => {
        state.isEditModalOpen = false;
    };

    return useObserver(() => (
        <>
            <DeferredParityCalculationDialog open={state.isEditModalOpen} closeDialog={closeEditModal} />
            <DataReadonlyCard
                title={t("technical_support.deferred_parity_calculation.title")}
                onClick={openEditModal}
                autoHeight
                control={
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={DEFERRED_PARITY_CALCULATION_CARD} />
                    </MoreMenu>
                }
            >
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("technical_support.deferred_parity_calculation.label")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <SavedInputIndicator enabled={volumeStore.deferredParityCalculation?.enabled} />
                    </Grid>
                </Grid>
            </DataReadonlyCard>
        </>
    ));
};

export default DeferredParityCalculationCard;
