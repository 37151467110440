import React, { useEffect } from "react";
import { useStyles } from "./UpsConf.styles";
import DataReadonlyCard from "components/DataReadonlyCard";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { UPS_CONF } from "const/widgetNameConst";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";

import VolumeIcon from "assets/VolumeIcon";
import { useObserver } from "mobx-react-lite";
import EditUpsConfDialog from "../EditUpsConfDialog";
import SavedInputIndicator from "components/SavedInputIndicator";
import { URL_ALERTS } from "routes";
import { useStore } from "hooks/useStore";
import ButtonLine from "components/ButtonLine/ButtonLine";
import { useModal } from "hooks/useModal";

const UpsConf = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { powerAndUpsStore, authStore },
    } = useStore();

    const editConf = useModal();

    useEffect(() => {
        powerAndUpsStore.getUpsList();
        powerAndUpsStore.getUpsSettings();
    }, []);

    return useObserver(() => (
        <>
            <EditUpsConfDialog open={editConf.isOpen} onClose={editConf.close} />
            <DataReadonlyCard
                onClick={editConf.open}
                disabled={!powerAndUpsStore.isAnyUps || !authStore.isAuthorized}
                autoHeight
                headerDivider
                emptyPadding
                title={t("power_and_ups.ups_conf.title")}
                control={
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={UPS_CONF} />
                    </MoreMenu>
                }
            >
                <Grid direction={"column"} container spacing={5} className={classes.container}>
                    <Grid container item>
                        <Typography variant={powerAndUpsStore.ups.name ? "h6" : "body2"}>
                            {powerAndUpsStore.ups.name || t("power_and_ups.power.ups_not_found")}
                        </Typography>
                    </Grid>
                    <Grid container item spacing={1}>
                        <Grid xs={6} item>
                            <Typography>{t("power_and_ups.ups_conf.shutdown")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={powerAndUpsStore.upsSettings.automaticShutdownEnabled} />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1}>
                        <Grid xs={6} item>
                            <Typography>{t("power_and_ups.ups_conf.critical_level")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <Typography variant={"h6"}>{powerAndUpsStore.upsSettings.criticalChargeLevel}%</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1}>
                        <Grid xs={6} item>
                            <Typography>{t("power_and_ups.ups_conf.edit_conf_dialog.srt_mode")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <Typography variant={"h6"}>{powerAndUpsStore.upsSettings.srtMode}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <ButtonLine link={URL_ALERTS} icon={VolumeIcon}>
                            {t("power_and_ups.ups_conf.configure_notifications")}
                        </ButtonLine>
                    </Grid>
                </Grid>
            </DataReadonlyCard>
        </>
    ));
};

export default UpsConf;
