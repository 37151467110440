import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    inputsContainer: {
        padding: theme.spacing(4, 6, 0, 6),
        marginTop: 0,
        flex: 1
    },
    textInfo: {
        fontWeight: 500,
        paddingLeft: theme.spacing(2)
    }
}));
