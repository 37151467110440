import i18n from "i18n";

const passwordRule = {
    password: {
        function: value => {
            return !/\s/.test(value);
        },
        message: i18n.t("rule.user.password")
    }
};

export default passwordRule;
