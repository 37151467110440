import { types } from "mobx-state-tree";

import Request from "api/Request";
import SetExternalSharesOnlineArguments from "api/external_shares/Types/SetExternalSharesOnlineArguments";

const SetExternalSharesOnline = Request.props({
    path: "/com/studionetworksolutions/evo/ExternalShares",
    method: "set_external_shares_online",
    arguments: types.maybe(SetExternalSharesOnlineArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetExternalSharesOnline;
