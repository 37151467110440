import { types } from "mobx-state-tree";

import NetBond from "api/net/Types/NetBond";

const BondsArrayResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(NetBond)
});

export default BondsArrayResult;
