import React from "react";
import { useStore } from "hooks/useStore";
import { useTranslation } from "react-i18next";
import Dialog from "components/Dialog";
import { useObserver } from "mobx-react-lite";
import { Button, Typography, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useStyles } from "./RebalanceStatusModal.styles";
import { CLUSTER_SECONDS_FORMAT } from "const";
import moment from "moment";

const RebalanceStatusModal = ({ open, onClose }) => {
    const { t } = useTranslation();
    const {
        store: { clusterStore }
    } = useStore();
    const classes = useStyles();

    const getPeerAlias = nodeName => {
        return clusterStore.peers.find(peer => peer.name === nodeName)?.alias || nodeName;
    };

    return useObserver(() => (
        <Dialog
            maxWidth={"lg"}
            title={t("cluster.rebalance_status.title")}
            open={open}
            onClose={onClose}
            onSubmit={onClose}
            submitBtnLabel={t("common.button.ok")}
            withoutPaddings
            buttons={[
                <Button key={"cancel_button"} onClick={onClose} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>
            ]}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.rebalance_status.host")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.remove_bricks_status.rebalanced_files")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.remove_bricks_status.size")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.remove_bricks_status.scanned")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.remove_bricks_status.failures")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.remove_bricks_status.skipped")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.volume.button.rebalance.status")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.rebalance_status.run_time")}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clusterStore.statusRebalance?.nodes?.map((node, index) => (
                        // TODO  Do not use Array index in keys
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={`rebalanceStatusNode-${index}`}>
                            <TableCell>{getPeerAlias(node.nodeName)}</TableCell>
                            <TableCell>{node.files}</TableCell>
                            <TableCell>
                                {node.size}
                                {t("cluster.rebalance_status.label.bytes")}
                            </TableCell>
                            <TableCell>{node.scanned}</TableCell>
                            <TableCell>{node.failures}</TableCell>
                            <TableCell>{node.skipped}</TableCell>
                            <TableCell>{node.statusStr}</TableCell>
                            <TableCell>
                                {moment()
                                    .startOf("day")
                                    .seconds(node.runtime)
                                    .format(CLUSTER_SECONDS_FORMAT)}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow className={classes.divideredRow}>
                        <TableCell className={classes.totalNodes}>
                            {t("cluster.rebalance_status.volume_rebalance")}: {clusterStore.currentVolumeName}:{" "}
                            {clusterStore.statusRebalance?.aggregate.statusStr}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Dialog>
    ));
};

export default RebalanceStatusModal;
