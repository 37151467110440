import React from "react";
import { Typography, Divider, Grid, CircularProgress, InputAdornment, ButtonBase, Popover } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, observer } from "mobx-react";
import { useStyles } from "./SearchLine.styles";
import TextInput from "components/MobxForm/TextInput";
import { SEARCH_DEFAULTS, LOCAL_GROUPS } from "const/userSearchVariants";
import CloseIcon from "assets/CloseIcon";
import ChevronDownIcon from "assets/ChevronDownIcon";
import TablePagination from "components/TablePagination";
import { USERS_SEARCH_SELECT_RELATIVE_POSITION_TOP } from "const/styleConst";
import CheckSmallIcon from "assets/CheckSmallIcon";
import clsx from "clsx";
import QuickCreate from "components/QuickCreate/QuickCreate";
import AddGroupDialog from "components/AddGroupDialog/AddGroupDialog";
import { useStore } from "hooks/useStore";

const SEARCH_LINE_ID_PREFIX = "search_line";

const SearchLine = ({ form, onSelect, isOptionChecked, onAdd, parentId }) => {
    const { t } = useTranslation();
    const idPrefix = parentId ? `${parentId}_${SEARCH_LINE_ID_PREFIX}` : SEARCH_LINE_ID_PREFIX;

    const {
        store: { userStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isSearchInProgress: false,
        anchorEl: null,
        isSelectOpen: false,
    }));

    const classes = useStyles({ isSelectOpen: state.isSelectOpen });

    const closeSelect = () => {
        state.isSelectOpen = false;
    };

    const searchByType = async () => {
        userStore.resetSearchByTypeResult();
        userStore.updateSearchByTypeRequest("type", LOCAL_GROUPS);
        userStore.updateSearchByTypeRequest("page", SEARCH_DEFAULTS.page);
        userStore.updateSearchByTypeRequest("name", form.$("name").value);
        await userStore.searchByType();
        return true;
    };
    const search = async () => {
        state.isSearchInProgress = true;
        await searchByType();
        state.isSearchInProgress = false;
        state.isSelectOpen = true;
    };
    const handleAdd = async (groups) => {
        state.isSelectOpen = false;
        await onAdd(groups);
        state.isSearchInProgress = false;
    };

    const keyDownHandler = async (e) => {
        if (!state.anchorEl) {
            state.anchorEl = e.currentTarget;
        }
        e.key === "Enter" && search();
    };

    const onInputClick = (e) => {
        if (!state.anchorEl) {
            state.anchorEl = e.currentTarget;
        }
    };

    const resetNameField = () => {
        form.$("name").reset();
        userStore.resetSearchByTypeResult();
    };

    const handleChangePage = async (newPage) => {
        state.isSearchInProgress = true;
        userStore.updateSearchByTypeRequest("page", +newPage);
        await userStore.searchByType();
        state.isSearchInProgress = false;
    };

    const onOptionClick = (name, domain) => () => {
        onSelect(name, domain);
        userStore.searchByTypeResult?.total === 1 && closeSelect();
    };

    const getInputAdornment = () => {
        return (
            <InputAdornment position="end">
                <Grid container wrap={"nowrap"} alignItems={"center"} justify={"flex-end"} spacing={2}>
                    {!form.$("name").isEmpty ? (
                        <Grid item>
                            <ButtonBase className={classes.closeIcon} onClick={resetNameField} id={`${idPrefix}_reset_name`}>
                                <CloseIcon />
                            </ButtonBase>
                        </Grid>
                    ) : null}

                    <Grid item>
                        <Grid container alignItems={"center"} className={classes.searchIconContainer}>
                            {state.isSearchInProgress ? (
                                <CircularProgress size={22} />
                            ) : (
                                <ButtonBase
                                    className={clsx(classes.primaryColorIcon, classes.dropdownIcon)}
                                    onClick={search}
                                    id={`${idPrefix}_open_result`}
                                >
                                    <ChevronDownIcon />
                                </ButtonBase>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </InputAdornment>
        );
    };

    return (
        <React.Fragment>
            <TextInput
                autoFocus
                className={classes.input}
                onClick={onInputClick}
                placeholder={t("users.side_menu.user_membership_modal.input.placeholder")}
                field={form.$("name")}
                InputProps={{
                    endAdornment: getInputAdornment(),
                    id: `${idPrefix}_name`,
                }}
                onKeyDown={keyDownHandler}
            />
            <Popover
                PaperProps={{ className: classes.selectPaper }}
                open={state.isSelectOpen}
                anchorEl={state.anchorEl}
                onClose={closeSelect}
                anchorOrigin={{ vertical: USERS_SEARCH_SELECT_RELATIVE_POSITION_TOP, horizontal: "center" }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {userStore.searchByTypeResult?.total ? (
                    <React.Fragment>
                        {userStore.searchByTypeResult?.data.map((entity) => (
                            <Grid
                                container
                                className={classes.selectItem}
                                onClick={onOptionClick(entity.name, entity.domain)}
                                key={entity.name + entity.domain}
                                alignItems={"center"}
                                wrap={"nowrap"}
                                justify={"space-between"}
                            >
                                <Typography noWrap>
                                    {entity.isExternal ? `${entity.name}@${entity.domain}` : entity.name}
                                </Typography>
                                {isOptionChecked(entity.name, entity.domain) && <CheckSmallIcon />}
                            </Grid>
                        ))}
                    </React.Fragment>
                ) : (
                    <Grid className={classes.emptyResponse} container alignItems={"center"} justify={"center"}>
                        <Typography className={classes.emptyResponseText}>
                            {t("users.search_results.empty_table_placeholder", { type: t("users.common.local_groups") })}
                        </Typography>
                        <QuickCreate handleClose={searchByType} parentId={idPrefix}>
                            {(props) => (
                                <AddGroupDialog
                                    open={props.isOpen}
                                    onClose={props.close}
                                    initialValues={{ name: form.$("name").value }}
                                    onAdd={handleAdd}
                                    parentId={idPrefix}
                                />
                            )}
                        </QuickCreate>
                    </Grid>
                )}

                {userStore.searchByTypeResult && userStore.searchByTypeResult.total > SEARCH_DEFAULTS.limit ? (
                    <React.Fragment>
                        <Divider />
                        <TablePagination
                            className={classes.pagination}
                            onChangePage={handleChangePage}
                            count={userStore.searchByTypeResult?.total}
                            page={userStore.searchByTypeRequest?.page}
                            rowsPerPage={userStore.searchByTypeRequest?.limit}
                            rowsPerPageOptions={[10]}
                            labelDisplayedRows={() => {}}
                            parentId={idPrefix}
                        />
                    </React.Fragment>
                ) : null}
            </Popover>
        </React.Fragment>
    );
};

export default observer(SearchLine);
