import React, { useContext } from "react";
import { Context } from "store";
import { useObserver } from "mobx-react";
import MainTemplate from "components/MainTemplate";
import { Grid } from "@material-ui/core";
import LogicalDisks from "./components/LogicalDisks";
import Pools from "./components/Pools";
import DisksIcon from "assets/DisksIcon";
import HeaderTitle from "components/HeaderTitle";
import BulkOperationsBar from "./components/BulkOperationsBar";
import LogicalDisksDrawer from "./components/LogicalDisksDrawer";
import { useTranslation } from "react-i18next";
import { DISKS_AND_POOLS } from "const/shortcutNameConst";
import MultipleEvoTitle from "components/MultipleEvoTitle";

const DisksAndPools = () => {
    const { t } = useTranslation();
    const { multipleEvosRootStore } = useContext(Context);

    return useObserver(() => (
        <MainTemplate
            tabIndex={1}
            headerTitle={<HeaderTitle shortcutName={DISKS_AND_POOLS} icon={<DisksIcon />} title={t("disks_and_pools.title")} />}
        >
            <Grid item container direction="column" spacing={6} justify="flex-start" wrap="nowrap">
                <BulkOperationsBar />
                <LogicalDisksDrawer />
                <Grid spacing={6} container item direction="column" wrap="nowrap">
                    {multipleEvosRootStore.storesLength > 0 && (
                        <Grid item>
                            <MultipleEvoTitle />
                        </Grid>
                    )}
                    <Grid item>
                        <LogicalDisks />
                    </Grid>
                    <Grid item>
                        <Pools />
                    </Grid>
                </Grid>
                {multipleEvosRootStore.storesIps.map((ip) => (
                    <Grid key={ip} container item direction="column" spacing={6} wrap="nowrap">
                        <Grid item>
                            <MultipleEvoTitle ip={ip} />
                        </Grid>
                        <Grid item>
                            <LogicalDisks ip={ip} />
                        </Grid>
                        <Grid item>
                            <Pools ip={ip} />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </MainTemplate>
    ));
};

export default DisksAndPools;
