import React from "react";
import { useStyles } from "./WelcomeCard.style";
import { Paper, Grid, Typography, List } from "@material-ui/core";
import ListItem from "components/ListItem";
import { useTranslation } from "react-i18next";
import CardHeader from "components/CardHeader";

const WelcomeCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper>
            <CardHeader title={t("quick_start.welcome_card.title")} />
            <Grid container direction={"column"} spacing={4} className={classes.contentContainer}>
                <Grid item>
                    <Typography gutterBottom>{t("quick_start.welcome_card.text")}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant={"subtitle2"}>{t("quick_start.welcome_card.subtitle")}</Typography>
                </Grid>
                <Grid item>
                    <List className={classes.list}>
                        <ListItem>{t("quick_start.welcome_card.list.basic_setup")}</ListItem>
                        <ListItem>{t("quick_start.welcome_card.list.basic_tips")}</ListItem>
                        <ListItem>{t("quick_start.welcome_card.list.help_and_support")}</ListItem>
                    </List>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default WelcomeCard;
