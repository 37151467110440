import { types, flow, getParent, applySnapshot } from "mobx-state-tree";

import MainSocket from "websocket";
import GetStorageUsageGraph from "api/graphs/storageUsageGraph/Requests/GetStorageUsageGraph";
import GetStorageUsageGraphResult from "api/graphs/storageUsageGraph/Types/GetStorageUsageGraphResult";

const FETCH_INTERVAL = 60000;

const StorageUsageGraphStore = types
    .model({
        storageUsageGraphResult: types.maybe(GetStorageUsageGraphResult),
    })
    .volatile(() => ({
        intervalID: 0,
        graphCount: 0,
    }))
    .views((self) => ({
        // chartInfo need to be ascending sorted by time
        get chartInfo() {
            return (self.storageUsageGraphResult?.data || [])
                .slice()
                .sort((a, b) => a.date - b.date)
                .map((d) => ({
                    date: new Date(d.date * 1000),
                    value: d.total !== 0 ? Math.round((d.used / d.total) * 100) : 0,
                    used: d.used,
                    total: d.total !== 0 ? d.total : 0,
                }));
        },
        get lastTime() {
            return self?.chartInfo[self.chartInfo.length - 1]?.date ?? "";
        },
        get lastValue() {
            return self.chartInfo[self.chartInfo.length - 1] ? self.chartInfo[self.chartInfo.length - 1].value : null;
        },
        get lastUsed() {
            return self.chartInfo[self.chartInfo.length - 1] ? self.chartInfo[self.chartInfo.length - 1].used : null;
        },
        get lastTotal() {
            return self.chartInfo[self.chartInfo.length - 1] ? self.chartInfo[self.chartInfo.length - 1].total : null;
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
    }))
    .actions((self) => ({
        fetchData: function (force) {
            if (!force && self.graphCount > 1) return;
            self.fetch();
        },
        fetch: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetStorageUsageGraph.create().init();
                const res = yield self.socket.send(req);
                self.storageUsageGraphResult = res;
                if (!self.intervalID) {
                    self.intervalID = setInterval(self.fetch, FETCH_INTERVAL);
                }
            } catch (e) {
                self.stopFetching(true);
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        stopFetching: function (force) {
            if (!force && self.graphCount > 0) return;
            clearTimeout(self.intervalID);
            self.intervalID = null;
        },
        clear: function () {
            applySnapshot(self, {});
        },
        registerGraph: function () {
            self.graphCount++;
        },
        unregisterGraph: function () {
            self.graphCount--;
        },

        beforeDestroy: function () {
            self.stopFetching(true);
        },
    }));

export default StorageUsageGraphStore;
