import { types } from "mobx-state-tree";

import BrickSize from "api/cluster/Types/BrickSize";

const BRICK_STATUS_ARRAY = ["reset", "allocated", "not_allocated", "dirty", "corrupt"];
const BRICK_TYPE_ARRAY = ["data", "arbiter"];

const Brick = types.model({
    peer: types.string,
    path: types.string,
    name: types.string,
    volume: types.string,
    type: types.enumeration(BRICK_TYPE_ARRAY),
    status: types.enumeration(BRICK_STATUS_ARRAY),
    size: types.maybe(BrickSize),
    isOnline: types.boolean,
    subvolumeNumber: types.number
});

export default Brick;
