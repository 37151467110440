import i18n from "i18n";

export const BASIC_MODE = "basic";
export const ADVANCED_MODE = "advanced";
export const ALL_INTERFACES = i18n.t("firewall.header.interfaces_select");
export const ALL_INTERFACES_VALUE = "*";

export const TCP = { label: "TCP", value: "tcp" };
export const UDP = { label: "UDP", value: "udp" };
export const PROTOCOL_ARRAY = [TCP, UDP];

export const ACTION_ACCEPT = { label: "Allow", value: "accept" };
export const ACTION_DROP = { label: "Deny", value: "drop" };
export const POLICY_ARRAY = [ACTION_ACCEPT, ACTION_DROP];

export const ALL_ADDRESSES = { value: "*", label: "All" };
export const PORT_MIN = 1;
export const PORT_MAX = 65535;
export const ALL_PORTS = { start: PORT_MIN.toString(), end: PORT_MAX.toString(), label: "All" };

export const PORTS_LIST = "list";
export const PORTS_RANGE = "range";

export const SINGLE_HOST = "singleHost";
export const SUBNET = "subnet";
export const HOST_RANGE = "range";
