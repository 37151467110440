import { types } from "mobx-state-tree";

import QuadStatus from "api/drive/Types/QuadStatus";

const EnclosureStatus = types.model({
    enclosure: types.string,
    quadStatus: types.array(QuadStatus)
});

export default EnclosureStatus;
