import { types } from "mobx-state-tree";

import Request from "api/Request";

// TODO: corrected arguments type
const SetUserAccess = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Acl",
    method: "set_user_access",
    arguments: types.maybe(types.frozen())
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetUserAccess
