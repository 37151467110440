import { types } from "mobx-state-tree";
import WatermarkType from "./WatermarkType";

const WatermarksResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(WatermarkType, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            },
        })
    ),
});

export default WatermarksResult;
