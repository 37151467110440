/** @description frontend status = "Healthy". Color = green */
export const ONLINE = "online";
/** @description frontend status = "Drive is missing". Color = red */
export const REPLACED = "replaced";
/** @description frontend status = "Healthy". Color = green */
export const WARNING = "warning";
/** @description frontend status = "Drive is missing". Color = red */
export const FAILED = "failed";
/** @description frontend status = "Not fully committed". Color = yellow */
export const NOT_COMMITED = "not_committed";
