import i18next from "i18next";
import createForm from "utils/createForm";
import isFieldEmpty from "utils/isFieldEmptyRule";

export const createCredentialsFrom = () =>
    createForm({
        fields: [
            {
                name: "name",
                default: "",
                validators: [isFieldEmpty(i18next.t("common.label.username"))],
            },
            {
                name: "password",
                default: "",
                type: "password",
                validators: [isFieldEmpty(i18next.t("common.label.password"))],
            },
        ],
    });

export const createMixedSharesFrom = () => createForm({ fields: [] });
