import { types } from "mobx-state-tree";

import Request from "api/Request";
import MasterResetArguments from "api/troubleshooting/Types/MasterResetArguments";

const MasterReset = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Troubleshooting",
    method: "master_reset",
    arguments: types.maybe(MasterResetArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default MasterReset;
