import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetJobArguments from "../Types/GetJobArguments";

const GetJob = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/SyncJobs1",
    method: "get_job",
    arguments: types.maybe(
        types.snapshotProcessor(GetJobArguments, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json)
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetJob;
