import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Typography } from "@material-ui/core";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";

const ConfirmationDialog = ({
    title,
    children,
    onConfirm,
    onClose,
    open,
    isSubmitted,
    confirmLabel,
    cancelLabel,
    inProgress,
    id,
    ...restProps
}) => {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={onClose} {...restProps}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
                <DialogContentText>
                    <Typography component={"span"} color={"textPrimary"}>
                        {children}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"secondary"} onClick={onClose} id={`${id}_cancel`}>
                    {cancelLabel ? cancelLabel : t("common.button.cancel")}
                </Button>
                <AnimatedSubmitButton
                    label={confirmLabel ? confirmLabel : t("common.button.confirm")}
                    submit={onConfirm}
                    isSubmitted={isSubmitted}
                    inProgress={inProgress}
                    id={`${id}_confirm`}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
