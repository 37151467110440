import React, { useContext } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import { useStyles } from "./ImportUnsignedCSR.styles";
import DragAndDropFile from "components/DragAndDropFile";
import { Context } from "store";
import ShieldWithKeyIcon from "assets/ShieldWithKeyIcon";
import Dialog from "components/Dialog";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const IMPORT_SIGNED_CERTIFICATE_MODAL_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_import_signed_certificate_modal`;

const ImportCertificateModal = ({ open, onClose, onBack }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { certificateStore },
    } = useContext(Context);

    const state = useLocalStore(() => ({
        certificateFile: null,
        privateKeyFile: null,
        intermediateCertificateFile: null,
        isEnableHttpsDialogOpen: false,
        inProgress: false,
        isSubmitted: false,
    }));

    const onCertificateFileSelect = (files) => {
        state.certificateFile = files[0];
    };

    const onPrivateKeyFileSelect = (files) => {
        state.privateKeyFile = files[0];
    };

    const onIntermediateCertificateFileSelect = (files) => {
        state.intermediateCertificateFile = files[0];
    };

    const resolveSubmitCondition = () => {
        state.isSubmitted = true;
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    };

    const onSubmit = async () => {
        state.inProgress = true;

        const res = await certificateStore.uploadCertificateAndKey({
            cert: state.certificateFile,
            key: state.privateKeyFile,
            intermediateCert: state.intermediateCertificateFile,
        });

        state.inProgress = false;

        if (res) {
            await resolveSubmitCondition();
        } else {
            state.certificateFile = null;
            state.privateKeyFile = null;
            state.intermediateCertificateFile = null;
        }

        return res;
    };

    const onEnter = () => {
        state.certificateFile = null;
        state.privateKeyFile = null;
        state.intermediateCertificateFile = null;
        state.isEnableHttpsDialogOpen = false;
        state.isSubmitted = false;
        state.inProgress = false;
    };

    return useObserver(() => (
        <>
            <Dialog
                open={open}
                buttons={[
                    <Button
                        key={"cancelBtn"}
                        onClick={onBack}
                        variant={"contained"}
                        color={"secondary"}
                        id={`${IMPORT_SIGNED_CERTIFICATE_MODAL_ID_PREFIX}_cancel`}
                    >
                        {t("common.button.back")}
                    </Button>,
                ]}
                onEnter={onEnter}
                inProgress={state.inProgress}
                onSubmit={onSubmit}
                disableSubmit={!state.certificateFile || !state.privateKeyFile}
                submitBtnLabel={t("common.button.import")}
                submitBtnId={`${IMPORT_SIGNED_CERTIFICATE_MODAL_ID_PREFIX}_import`}
                title={t("admin_settings.server_certificate.import_certificate.title")}
                onClose={onClose}
            >
                <Grid container className={classes.contentContainer}>
                    <Typography className={classes.dragAndDropTitle}>
                        {t("admin_settings.server_certificate.import_certificate.drag_and_drop.title")}
                    </Typography>
                    {state.certificateFile ? (
                        <Grid container wrap={"nowrap"} className={classes.certContainer}>
                            <Grid item className={classes.sshIcon}>
                                <ShieldWithKeyIcon />
                            </Grid>
                            <Grid item container justify={"flex-start"} alignItems={"center"}>
                                <Typography className={classes.certNameText}>{state.certificateFile.name}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid className={classes.dragAndDropContainer} item container>
                            <DragAndDropFile
                                title={t("admin_settings.server_certificate.import_certificate.drag_and_drop.placeholder")}
                                handleFiles={onCertificateFileSelect}
                                id={`${IMPORT_SIGNED_CERTIFICATE_MODAL_ID_PREFIX}_certificate_drag_and_drop`}
                            />
                        </Grid>
                    )}

                    <Typography className={classes.dragAndDropTitle}>
                        {t("admin_settings.server_certificate.import_private_key.drag_and_drop.title")}
                    </Typography>
                    {state.privateKeyFile ? (
                        <Grid container wrap={"nowrap"} className={classes.certContainer}>
                            <Grid item className={classes.sshIcon}>
                                <ShieldWithKeyIcon />
                            </Grid>
                            <Grid item container justify={"flex-start"} alignItems={"center"}>
                                <Typography className={classes.certNameText}>{state.privateKeyFile.name}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid className={classes.dragAndDropContainer} item container>
                            <DragAndDropFile
                                title={t("admin_settings.server_certificate.import_private_key.drag_and_drop.placeholder")}
                                handleFiles={onPrivateKeyFileSelect}
                                id={`${IMPORT_SIGNED_CERTIFICATE_MODAL_ID_PREFIX}_private_key_drag_and_drop`}
                            />
                        </Grid>
                    )}

                    <Typography className={classes.dragAndDropTitle}>
                        {t("admin_settings.server_certificate.import_intermediate_certificate.drag_and_drop.title")}
                    </Typography>
                    {state.intermediateCertificateFile ? (
                        <Grid container wrap={"nowrap"} className={classes.certContainer}>
                            <Grid item className={classes.sshIcon}>
                                <ShieldWithKeyIcon />
                            </Grid>
                            <Grid item container justify={"flex-start"} alignItems={"center"}>
                                <Typography className={classes.certNameText}>{state.intermediateCertificateFile.name}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item container>
                            <DragAndDropFile
                                title={t(
                                    "admin_settings.server_certificate.import_intermediate_certificate.drag_and_drop.placeholder"
                                )}
                                handleFiles={onIntermediateCertificateFileSelect}
                                id={`${IMPORT_SIGNED_CERTIFICATE_MODAL_ID_PREFIX}_intermediate_certificate_drag_and_drop`}
                            />
                        </Grid>
                    )}
                </Grid>
            </Dialog>
        </>
    ));
};

export default ImportCertificateModal;
