import React from "react";
import {
    Box,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid,
    Typography,
    FormControlLabel,
} from "@material-ui/core";
import TablePagination from "components/TablePagination";
import { useStyles } from "./UsersTable.styles";
import { observer, useLocalStore } from "mobx-react";
import LocalGroupIcon from "assets/LocalGroupIcon";
import LocalUserIcon from "assets/LocalUserIcon";
import TableCellWithIcon from "components/TableCellWithIcon";
import EmptyTablePlaceholder from "../EmptyTablePlaceholder";
import { useTranslation } from "react-i18next";
import { EXTERNAL_GROUPS, LOCAL_GROUPS, SEARCH_DEFAULTS } from "const/userSearchVariants";
import Checkbox from "components/Checkbox";
import { CUSTOM, NONE, RO, RW } from "const/shareRootAccessConst";
import SaveChangesDialog from "components/SaveChangesDialog";
import { toJS } from "mobx";
import useStoreByIp from "hooks/useStoreByIp";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const USERS_TABLE_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_users_table`;

const UsersTable = ({ openCustom, ip }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const state = useLocalStore(() => ({
        isUnsavedChangesDialogOpen: false,
        dataForChangingPage: null,
        entityForCustomizeSettings: null,
    }));

    const { userStore, shareStore, aclStore } = useStoreByIp({ ip });

    const shareName = shareStore.currentShareName || shareStore.currentCheckedShareName || "";

    const openUnsavedChangesDialog = (entity) => {
        if (entity) {
            state.entityForCustomizeSettings = entity;
        }

        state.isUnsavedChangesDialogOpen = true;
    };

    const closeUnsavedChangesDialog = () => {
        state.isUnsavedChangesDialogOpen = false;
    };

    const handleChangePage = async (newPage) => {
        userStore.updateCurrentSearchRequest("page", +newPage);
        await userStore.searchOnAssignShares({ shareName });
    };

    const handleChangeRowsPerPage = async (e) => {
        userStore.updateCurrentSearchRequest("limit", +e.target.value);
        userStore.updateCurrentSearchRequest("page", SEARCH_DEFAULTS.page);
        await userStore.searchOnAssignShares({ shareName });
    };

    const handleChangePageIfNeeded = (newPage) => {
        if (userStore.hasAccessChanges) {
            state.dataForChangingPage = { argument: newPage, func: handleChangePage };

            return openUnsavedChangesDialog();
        }

        handleChangePage(newPage);
    };

    const handleChangeRowsPerPageIfNeeded = (e) => {
        if (userStore.hasAccessChanges) {
            state.dataForChangingPage = { argument: e, func: handleChangeRowsPerPage };
            return openUnsavedChangesDialog();
        }

        handleChangeRowsPerPage(e);
    };

    const isExternal = userStore.currentSearchRequest.type.includes("external");

    const getType = () => {
        const isGroup =
            userStore.currentSearchRequest.type === LOCAL_GROUPS || userStore.currentSearchRequest.type === EXTERNAL_GROUPS;

        const getIcon = () => {
            if (isGroup) {
                return <LocalGroupIcon />;
            } else {
                return <LocalUserIcon />;
            }
        };

        const getLabel = () => {
            if (isGroup) {
                return isExternal ? t("users.common.external_group") : t("users.common.local_group");
            } else {
                return isExternal ? t("users.common.external_user") : t("users.common.local_user");
            }
        };

        return {
            typeIcon: getIcon(),
            typeLabel: getLabel(),
        };
    };

    const isCheckedAccess = (index, checkboxAccess) => {
        return userStore.getUserAccessResult.data.data[index].access === checkboxAccess;
    };

    const onChangeAccess = (index, checkboxAccess) => () => {
        userStore.modifyUserAccess(index, checkboxAccess);
    };

    const onChangeAccessAllUsers = (checkboxAccess, isIndeterminate) => () => {
        const isAllUsersHaveSameAccess = userStore.getUserAccessResult.data.data.every(({ access }) => access === checkboxAccess);

        if (isIndeterminate) {
            userStore.setNoneAccessForUsersWithNextAccess(checkboxAccess);
        } else if (isAllUsersHaveSameAccess) {
            userStore.modifyAllUsersAccess(NONE);
        } else {
            userStore.modifyAllUsersAccess(checkboxAccess);
        }
    };

    const isCheckedAccessAllUsers = (checkboxAccess) => {
        return userStore.getUserAccessResult.data.data.every(({ access }) => access === checkboxAccess);
    };

    const isIndeterminateAccessAllUsers = (checkboxAccess) => {
        return (
            !userStore.getUserAccessResult.data.data.every(({ access }) => access === checkboxAccess) &&
            userStore.getUserAccessResult.data.data.some(({ access }) => access === checkboxAccess)
        );
    };

    const handleOnSave = async () => {
        await aclStore.modifyUsersOrGroupsAccess({
            shareName,
            permissions: userStore.getUserAccessResult.data.data,
        });

        if (state.dataForChangingPage) {
            await state.dataForChangingPage.func(state.dataForChangingPage.argument);
            state.dataForChangingPage = null;
            return closeUnsavedChangesDialog();
        }

        closeUnsavedChangesDialog();
        openCustom(toJS(state.entityForCustomizeSettings), null, true)();
    };

    const handleOnCancel = async () => {
        if (state.dataForChangingPage) {
            await state.dataForChangingPage.func(state.dataForChangingPage.argument);
            state.dataForChangingPage = null;
            return closeUnsavedChangesDialog();
        }

        closeUnsavedChangesDialog();
        openCustom(toJS(state.entityForCustomizeSettings), null, true)();
    };

    return (
        <>
            {userStore.getUserAccessResult?.data?.total > 0 ? (
                <Grid className={classes.tableContainer} container direction={"column"} justify={"space-between"}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("users.search_results.table.column.name")}</TableCell>
                                <TableCell>{t("users.search_results.table.column.type")}</TableCell>
                                <TableCell className={classes.tableHeadLabel}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                indeterminate={isIndeterminateAccessAllUsers(RW)}
                                                onChange={onChangeAccessAllUsers(RW, isIndeterminateAccessAllUsers(RW))}
                                                checked={isCheckedAccessAllUsers(RW)}
                                                value={RW}
                                                id={`${USERS_TABLE_ID_PREFIX}_header_rw`}
                                            />
                                        }
                                        label={<Typography noWrap>{t("users.assign_shares_modal.table.header.rw")}</Typography>}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableHeadLabel}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                indeterminate={isIndeterminateAccessAllUsers(RO)}
                                                onChange={onChangeAccessAllUsers(RO, isIndeterminateAccessAllUsers(RO))}
                                                checked={isCheckedAccessAllUsers(RO)}
                                                value={RO}
                                                id={`${USERS_TABLE_ID_PREFIX}_header_ro`}
                                            />
                                        }
                                        label={<Typography noWrap>{t("users.assign_shares_modal.table.header.ro")}</Typography>}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableHeadLabel}>
                                    <Typography noWrap>{t("users.assign_shares_modal.table.header.custom")}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userStore.getUserAccessResult?.data.data.map((entity, index) => {
                                const { typeIcon, typeLabel } = getType();
                                const {
                                    user: { name, domain },
                                } = entity;

                                return (
                                    // TODO  Do not use Array index in keys
                                    // eslint-disable-next-line react/no-array-index-key
                                    <TableRow key={index}>
                                        <TableCell>{isExternal ? `${name}@${domain}` : name}</TableCell>
                                        <TableCellWithIcon align="left" icon={typeIcon} className={classes.cellWithIcon}>
                                            {typeLabel}
                                        </TableCellWithIcon>
                                        <TableCell>
                                            <Checkbox
                                                checked={isCheckedAccess(index, RW)}
                                                onChange={onChangeAccess(index, RW)}
                                                value={RW}
                                                omitPadding
                                                id={`${USERS_TABLE_ID_PREFIX}_entity_${index}_rw`}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={isCheckedAccess(index, RO)}
                                                onChange={onChangeAccess(index, RO)}
                                                value={RO}
                                                omitPadding
                                                id={`${USERS_TABLE_ID_PREFIX}_entity_${index}_ro`}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={isCheckedAccess(index, CUSTOM)}
                                                onChange={openCustom(entity, openUnsavedChangesDialog)}
                                                value={CUSTOM}
                                                omitPadding
                                                id={`${USERS_TABLE_ID_PREFIX}_entity_${index}_custom`}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <Grid>
                        <Divider />
                        <Box className={classes.paginationContainer}>
                            <TablePagination
                                onChangeRowsPerPage={handleChangeRowsPerPageIfNeeded}
                                onChangePage={handleChangePageIfNeeded}
                                count={userStore.searchUserOrGroupsTotal}
                                rowsPerPage={userStore.currentSearchRequest.limit}
                                page={userStore.currentSearchRequest.page}
                            />
                        </Box>
                    </Grid>
                    <SaveChangesDialog
                        open={state.isUnsavedChangesDialogOpen}
                        onClose={closeUnsavedChangesDialog}
                        onCancel={handleOnCancel}
                        onSave={handleOnSave}
                        withoutIconClose
                        withoutDividers
                    />
                </Grid>
            ) : (
                <EmptyTablePlaceholder requestType={userStore.lastSearchShare && userStore.lastSearchShare.type} />
            )}
        </>
    );
};

export default observer(UsersTable);
