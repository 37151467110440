import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    controlLabel: {
        padding: theme.spacing(1.5, 0),
        marginLeft: 0,
        "& label > span": {
            padding: 0
        },
        "& .MuiGrid-container": {
            width: "initial"
        },
        "& label": {
            marginLeft: 0
        }
    },
    switchLabel: {
        marginLeft: 0,
        "& > span:last-child": {
            marginLeft: theme.spacing(4)
        }
    }
}));
