import React from "react";
import { Grid, TableCell, TableFooter, TableRow, Typography } from "@material-ui/core";
import { useStyles } from "./TableExpand.styles";

const TableExpand = ({ onClick, children, icon, iconPosition = "left", hideDivider }) => {
    const classes = useStyles({ iconPosition, hideDivider });
    return (
        <TableFooter>
            <TableRow>
                <TableCell className={classes.footerCell} colSpan={"100%"}>
                    <Grid container className={classes.container} onClick={onClick}>
                        {icon && (
                            <Grid item>
                                <span className={classes.iconContainer}>{icon}</span>
                            </Grid>
                        )}
                        <Grid item>
                            <Typography color={"primary"} variant={"button"}>
                                {children}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
        </TableFooter>
    );
};

export default TableExpand;
