import React from "react";
import PicTestIcon from "assets/PicTestIcon";
import InfoCard from "components/InfoCard";
import { useTranslation } from "react-i18next";

const EvoTestTools = () => {
    const { t } = useTranslation();
    return (
        <InfoCard
            flag
            iconMargin={24}
            icon={<PicTestIcon />}
            title={t("support.self_test.test_tool.title")}
            content={t("support.self_test.test_tool.content")}
        />
    );
};

export default EvoTestTools;
