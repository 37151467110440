import { types } from "mobx-state-tree";

import AutomationsResult from "../Types/AutomationsResult";

const GetAutomationsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(AutomationsResult, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            }
        })
    )
});

export default GetAutomationsResult;
