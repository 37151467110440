import React from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import RowSwitch from "components/RowSwitch";
import Switch from "components/MobxForm/Switch";
import createForm from "utils/createForm";
import createFields from "./createFields";
import { useLocalStore, useObserver } from "mobx-react-lite";
import RowTextInput from "components/RowTextInput";
import TextInput from "components/MobxForm/TextInput";
import Select from "components/MobxForm/Select";
import { observer } from "mobx-react";
import ErrorDialog from "../ErrorDialog";
import resetFormToDefault from "utils/resetFormToDefault";
import isStpRule from "utils/isStpRule";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import { useModal } from "hooks/useModal";
import { DHCP_CLIENT_ROLE, DHCP_SERVER_ROLE, STATIC_IP_ROLE } from "const/rolesConst";
import isIpv4Rule from "utils/isIpv4Rule";

const DEFAULT_SWITCH_PRIORITY = 32768;
const DEFAULT_FORWARD_DELAY = 15;
const DEFAULT_HELLO_TIME = 2;
const DEFAULT_MAXAGE = 20;

const EditSettingsDialog = ({
    store,
    open,
    handleClose,
    title,
    onSubmit,
    currentPort = { dhcpServer: {}, dhcpClient: {} },
    afterSubmitCallback,
}) => {
    const { t } = useTranslation();
    const currentRoleOptions = [
        {
            label: t("network.ethernet_ports_drawer.role.client"),
            value: DHCP_CLIENT_ROLE,
        },
        {
            label: t("network.ethernet_ports_drawer.role.server"),
            value: DHCP_SERVER_ROLE,
        },
        {
            label: t("network.ethernet_ports_drawer.role.static_ip"),
            value: STATIC_IP_ROLE,
        },
    ];
    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(currentPort, store),
            rules: { ...isStpRule, ...isIpv4Rule },
            options: {
                validateDisabledFields: false,
            },
        }),
        isSubmitted: false,
        error: "",
    }));

    const unsavedChangesModal = useModal();

    const setupFieldsViaRole = (field) => {
        if (field.value === DHCP_CLIENT_ROLE) {
            state.form.$("ip").set("disabled", true);
            state.form.$("netmask").set("disabled", true);
            state.form.$("gateway").set("disabled", true);
            state.form.$("startDhcpRange").set("disabled", true);
            state.form.$("endDhcpRange").set("disabled", true);
            state.form.$("ip").resetValidation();
            state.form.$("netmask").resetValidation();
            state.form.$("gateway").resetValidation();
            state.form.$("startDhcpRange").resetValidation();
            state.form.$("endDhcpRange").resetValidation();
        } else if (field.value === DHCP_SERVER_ROLE) {
            state.form.$("ip").set("disabled", false);
            state.form.$("netmask").set("disabled", false);
            state.form.$("gateway").set("disabled", false);
            state.form.$("startDhcpRange").set("disabled", false);
            state.form.$("endDhcpRange").set("disabled", false);
        } else {
            state.form.$("startDhcpRange").set("disabled", true);
            state.form.$("endDhcpRange").set("disabled", true);
            state.form.$("startDhcpRange").resetValidation();
            state.form.$("endDhcpRange").resetValidation();
            state.form.$("ip").set("disabled", false);
            state.form.$("netmask").set("disabled", false);
            state.form.$("gateway").set("disabled", false);
        }
    };
    const submit = (force) => async (e) => {
        state.form.onSubmit(e);

        if (!state.form.$("stpEnabled").value) {
            state.form.$("switchPriority").resetValidation();
            state.form.$("forwardDelay").resetValidation();
            state.form.$("helloTime").resetValidation();
            state.form.$("maxAge").resetValidation();
        }

        if (!state.form.isValid) return;

        try {
            const role = state.form.$("currentRole").value;
            const res = await onSubmit({
                port: currentPort.port,
                ip: state.form.$("ip").value,
                netmask: state.form.$("netmask").value,
                gateway: state.form.$("gateway").value,
                mtu: +state.form.$("mtu").value,
                dhcpClient: {
                    enabled: state.form.$("currentRole").value === DHCP_CLIENT_ROLE,
                    overrideDns:
                        state.form.$("currentRole").value === DHCP_CLIENT_ROLE ? state.form.$("overrideDns").value : false,
                },
                dhcpServer: {
                    enabled: state.form.$("currentRole").value === DHCP_SERVER_ROLE,
                    startDhcpRange:
                        state.form.$("currentRole").value === DHCP_SERVER_ROLE ? state.form.$("startDhcpRange").value : "",
                    endDhcpRange:
                        state.form.$("currentRole").value === DHCP_SERVER_ROLE ? state.form.$("endDhcpRange").value : "",
                },
                flowcontrolEnforced: state.form.has("flowcontrolEnforced") ? state.form.$("flowcontrolEnforced").value : null,
                metric: currentPort.metric,
                status:
                    role === DHCP_CLIENT_ROLE || role === DHCP_SERVER_ROLE || role === STATIC_IP_ROLE ? role : currentPort.status,
                force: force,
                stp: {
                    enabled: state.form.$("stpEnabled").value,
                    switchPriority: state.form.$("stpEnabled").value
                        ? Number(state.form.$("switchPriority").value)
                        : DEFAULT_SWITCH_PRIORITY,
                    forwardDelay: state.form.$("stpEnabled").value
                        ? Number(state.form.$("forwardDelay").value)
                        : DEFAULT_FORWARD_DELAY,
                    helloTime: state.form.$("stpEnabled").value ? Number(state.form.$("helloTime").value) : DEFAULT_HELLO_TIME,
                    maxAge: state.form.$("stpEnabled").value ? Number(state.form.$("maxAge").value) : DEFAULT_MAXAGE,
                },
            });
            res && (state.isSubmitted = true);
            return res;
        } catch (e) {
            state.error = e.message;
            return "error";
        }
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            handleClose();
            return;
        }

        unsavedChangesModal.open();
    };

    const renderContent = () => {
        if (store.isEthernetPortUsedByBridgeOrBond) {
            return (
                <RowSwitch
                    label={t("network.ethernet_ports_drawer.label.flow_control_enforced")}
                    control={<Switch field={state.form.$("flowcontrolEnforced")} />}
                />
            );
        }
        return (
            <>
                <RowTextInput
                    label={t("network.ethernet_ports_drawer.label.ip")}
                    control={<TextInput field={state.form.$("ip")} />}
                />
                <RowTextInput
                    label={t("network.ethernet_ports_drawer.label.subnet")}
                    control={<TextInput field={state.form.$("netmask")} />}
                />
                <RowTextInput
                    label={t("network.ethernet_ports_drawer.label.gateway")}
                    control={<TextInput field={state.form.$("gateway")} />}
                />
                <RowTextInput
                    label={t("network.ethernet_ports_drawer.label.mtu")}
                    control={<TextInput field={state.form.$("mtu")} />}
                />
                <RowTextInput
                    label={t("network.ethernet_ports_drawer.label.role")}
                    control={<Select field={state.form.$("currentRole")} options={currentRoleOptions} />}
                />
                {state.form.$("currentRole").value === DHCP_CLIENT_ROLE && (
                    <RowControlLabelSwitch
                        control={<Switch field={state.form.$("overrideDns")} />}
                        label={t("network.ethernet_ports_drawer.label.override_dns")}
                    />
                )}
                {state.form.$("currentRole").value === DHCP_SERVER_ROLE && (
                    <>
                        <RowTextInput
                            label={t("network.ethernet_ports_drawer.label.dhcp_from")}
                            control={<TextInput field={state.form.$("startDhcpRange")} />}
                        />
                        <RowTextInput
                            label={t("network.ethernet_ports_drawer.label.dhcp_to")}
                            control={<TextInput field={state.form.$("endDhcpRange")} />}
                        />
                    </>
                )}
                {currentPort.flowcontrolEnforced !== undefined && (
                    <RowControlLabelSwitch
                        control={<Switch field={state.form.$("flowcontrolEnforced")} />}
                        label={t("network.ethernet_ports_drawer.label.flow_control_enforced")}
                    />
                )}
                {currentPort.stp && (
                    <RowControlLabelSwitch
                        control={<Switch field={state.form.$("stpEnabled")} />}
                        label={t("network.ethernet_ports_drawer.label.stp")}
                    />
                )}
                {state.form.$("stpEnabled").value && (
                    <>
                        <RowTextInput
                            label={t("network.ethernet_ports_drawer.label.switch_priority")}
                            control={<TextInput field={state.form.$("switchPriority")} />}
                        />
                        <RowTextInput
                            label={t("network.ethernet_ports_drawer.label.forward_delay")}
                            control={<TextInput field={state.form.$("forwardDelay")} />}
                        />
                        <RowTextInput
                            label={t("network.ethernet_ports_drawer.label.hello_time")}
                            control={<TextInput field={state.form.$("helloTime")} />}
                        />
                        <RowTextInput
                            label={t("network.ethernet_ports_drawer.label.max_age")}
                            control={<TextInput field={state.form.$("maxAge")} />}
                        />
                    </>
                )}
            </>
        );
    };
    const closeErrorDialog = () => (state.error = "");

    const onEnter = () => {
        resetFormToDefault(createFields(currentPort, store), state.form);
        if (store.isEthernetPortUsedByBridgeOrBond) {
            state.form.each((field) => {
                if (field.name !== "flowcontrolEnforced") {
                    field.set("disabled", true);
                    field.resetValidation();
                }
            });
        } else {
            state.form.each((field) => {
                field.set("disabled", false);
                field.resetValidation();
            });
            setupFieldsViaRole(state.form.$("currentRole"));
            state.form.$("currentRole").observe(({ field }) => {
                setupFieldsViaRole(field);
            });
        }
        state.form.reset();
        state.isSubmitted = false;
        state.isUnsavedChangesDialogOpen = false;
        state.form.fields.forEach((field) => field.resetValidation());
        state.error = "";
        unsavedChangesModal.close();
    };

    return useObserver(() => (
        <>
            <ErrorDialog
                open={!!state.error}
                error={state.error}
                onSubmit={submit(true)}
                onClose={closeErrorDialog}
                afterSubmitCallback={handleClose}
            />
            <Dialog
                open={open}
                title={title}
                buttons={
                    <Button onClick={handleClose} variant={"contained"} color="secondary">
                        {t("common.button.cancel")}
                    </Button>
                }
                onEnter={onEnter}
                onClose={closeIfNeeded}
                submitBtnLabel={t("common.button.save")}
                onSubmit={submit(false)}
                afterSubmitCallback={afterSubmitCallback}
            >
                {renderContent()}
            </Dialog>
            <UnsavedChangesDialog onConfirm={handleClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </>
    ));
};

export default observer(EditSettingsDialog);
