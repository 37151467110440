import { types } from "mobx-state-tree";

import Request from "api/Request";
import SetShortcutsArguments from "api/ui_settings/Types/SetShortcutsArguments";

const SetShortcuts = Request.props({
    path: "/com/studionetworksolutions/evo/manage/UiSettings",
    method: "set_shortcuts",
    arguments: types.maybe(SetShortcutsArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetShortcuts;
