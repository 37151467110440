import React from "react";

const ShareFolderIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g transform="translate(-1084 -96) translate(1060 72) translate(24 24) translate(1 2)">
                                <path
                                    fill="#6CB6FF"
                                    stroke="#A4E1FF"
                                    d="M20 .5c.414 0 .79.168 1.06.44.272.27.44.646.44 1.06h0v16c0 .414-.168.79-.44 1.06-.27.272-.646.44-1.06.44h0H2c-.414 0-.79-.168-1.06-.44C.667 18.79.5 18.415.5 18h0V6c0-.414.168-.79.44-1.06.27-.272.646-.44 1.06-.44h9.309l1.585-3.17c.127-.255.32-.462.553-.606.233-.144.505-.224.79-.224h0z"
                                />
                                <rect width="21" height="15" x=".5" y="4.5" fill="#85C3FF" stroke="#B2E1FE" rx="2" />
                                <g fill="#1E90FF" fillRule="nonzero">
                                    <path
                                        d="M9 0c1.105 0 2 .895 2 2s-.895 2-2 2c-.562 0-1.07-.232-1.432-.604L3.93 5.474c.046.168.07.344.07.526 0 .182-.024.36-.07.527l3.637 2.078C7.93 8.232 8.437 8 9 8c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2c0-.182.024-.358.07-.526l-3.637-2.08C3.07 7.769 2.563 8 2 8 .895 8 0 7.105 0 6s.895-2 2-2c.562 0 1.07.232 1.433.605L7.07 2.527C7.024 2.359 7 2.182 7 2c0-1.105.895-2 2-2zm0 9c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zM2 5c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm7-4c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
                                        transform="translate(2 6)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ShareFolderIcon;
