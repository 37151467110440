import React from "react";

const BrickWarningIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="brickWarningIcon_a" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
                <linearGradient id="brickWarningIcon_b" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
                <linearGradient id="brickWarningIcon_c" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A5E3FF" />
                    <stop offset="100%" stopColor="#99CDFF" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#6CB6FF"
                    stroke="url(#brickWarningIcon_a)"
                    d="M18.681 2.5H5.32c-.3 0-.586.09-.826.248s-.435.385-.553.661L1.758 8.5h20.484L20.06 3.41c-.118-.277-.313-.504-.553-.662-.24-.159-.526-.248-.826-.248z"
                />
                <path
                    fill="#85C3FF"
                    stroke="url(#brickWarningIcon_b)"
                    d="M21 7.5H3c-.414 0-.79.168-1.06.44-.272.27-.44.646-.44 1.06v6.5h21V9c0-.414-.168-.79-.44-1.06-.27-.272-.646-.44-1.06-.44z"
                />
                <path
                    fill="#85C3FF"
                    stroke="url(#brickWarningIcon_c)"
                    d="M22.5 14.5h-21V20c0 .414.168.79.44 1.06.27.272.646.44 1.06.44h18c.414 0 .79-.168 1.06-.44.272-.27.44-.646.44-1.06v-5.5z"
                />
                <g transform="translate(12 12)">
                    <circle cx="6" cy="6" r="5.5" fill="#FFA026" stroke="#FFC67D" />
                    <circle cx="6" cy="9" r="1" fill="#FFF" />
                    <rect width="2" height="5" x="5" y="2" fill="#FFF" rx="1" />
                </g>
                <circle cx="5" cy="18" r="2" fill="#FFCD8C" />
                <circle cx="5" cy="11" r="2" fill="#FFCD8C" />
                <circle cx="9" cy="18" r="1" fill="#1E90FF" />
                <circle cx="9" cy="11" r="1" fill="#1E90FF" />
            </g>
        </svg>
    );
};

export default BrickWarningIcon;
