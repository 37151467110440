import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";
import i18n from "i18n";

export const useStyles = makeStyles(theme => {
    const getColor = status => {
        switch (status) {
            case i18n.t("slingshot.automations.table.label.status.disabled"):
                return theme.palette.grey[500];
            case i18n.t("slingshot.automations.table.label.status.waiting"):
                return theme.palette.primary.main;
            case i18n.t("slingshot.automations.table.label.status.paused"):
                return theme.palette.warning.main;
            case i18n.t("slingshot.automations.table.label.status.running"):
                return theme.palette.success.main;
            default:
                return theme.palette.error.main;
        }
    };

    return {
        label: {
            padding: theme.spacing(0, 2),
            borderRadius: theme.spacing(1),
            backgroundColor: props => hexToRgba(getColor(props.status), 0.1),
            "& span": {
                color: props => getColor(props.status)
            }
        },
        labelText: {
            fontWeight: 500,
            textTransform: "capitalize"
        }
    };
});
