import { ADMIN } from "const/userRolesConst";
import { URL_SLINGSHOT, URL_SLINGSHOT_INDEX, URL_SLINGSHOT_AUTOMATIONS, URL_SLINGSHOT_SETTINGS_TAB } from "routes";

const disableByRole = (accessRoles = []) => currentRole => !accessRoles.includes(currentRole);

export default [
    {
        value: URL_SLINGSHOT,
        label: "slingshot_page.tabs.label.replications"
    },
    {
        value: URL_SLINGSHOT_INDEX,
        label: "slingshot_page.tabs.label.index",
        disableByRole: disableByRole(ADMIN)
    },
    {
        value: URL_SLINGSHOT_AUTOMATIONS,
        label: "slingshot_page.tabs.label.automations"
    },
    {
        value: URL_SLINGSHOT_SETTINGS_TAB,
        label: "slingshot_page.tabs.label.settings"
    }
];
