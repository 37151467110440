import { types } from "mobx-state-tree";

import SharebrowserServerVersion from "api/system_components/sharebrowser_server/Types/SharebrowserServerVersion";

const GetSharebrowserServerVersionResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(SharebrowserServerVersion)
});

export default GetSharebrowserServerVersionResult;
