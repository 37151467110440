import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@material-ui/core";
import Dialog from "components/Dialog";

const LogoutFromMultipleEvosDialog = ({ logoutFromAllEvos, logoutFromMainEvo, open, onClose }) => {
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            withoutIconClose
            withoutDividers
            title={t("header.user_menu.logout_from_all_evos_dialog.title")}
            submitBtn={
                <Button variant="contained" color="primary" onClick={logoutFromAllEvos}>
                    {t("common.button.yes")}
                </Button>
            }
            buttons={[
                <Button key={2} variant="contained" color="secondary" onClick={onClose}>
                    {t("common.button.cancel")}
                </Button>,
                <Button key={1} variant="contained" color="secondary" onClick={logoutFromMainEvo}>
                    {t("common.button.no")}
                </Button>,
            ]}
            onClose={onClose}
        >
            <Grid container direction={"column"} spacing={5}>
                <Grid item>
                    <Typography>{t("header.user_menu.logout_from_all_evos_dialog.question")}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default LogoutFromMultipleEvosDialog;
