import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: ({ hoverPart }) => (hoverPart === 1 ? 0 : theme.spacing(3)),
        paddingBottom: ({ hoverPart }) => (hoverPart === -1 ? 0 : theme.spacing(3)),
        "& > .MuiGrid-item": {
            height: "100%"
        },
        flex: 1
    },
    childContainer: {
        "&&.MuiBox-root": {
            opacity: ({ isDragging }) => (isDragging ? 0.5 : 1),
            boxShadow: "none"
        }
    }
}));
