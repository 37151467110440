import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./EditPermissionModal.styles";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import TextInput from "components/TextInput";
import Select from "components/MobxForm/Select";
import Checkbox from "components/MobxForm/Checkbox";
import ClearCheckbox from "components/Checkbox";
import {
    DialogTitle,
    ButtonBase,
    Button,
    Typography,
    Divider,
    FormControlLabel,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import CloseIcon from "assets/CloseIcon";
import ChevronDownIcon from "assets/ChevronDownIcon";
import ThinArrowRightIcon from "assets/ThinArrowRightIcon";
import clsx from "clsx";
import { APPLY_TO_OPTIONS, ALL_GROUPS, ADMIN_GROUP, READ_GROUP, WRITE_GROUP } from "const/permissionValues";
import getPermissionApplyToDefault from "utils/getPermissionApplyToDefault";
import createFields from "./createFields";
import createForm from "utils/createForm";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { EXTERNAL_GROUPS, LOCAL_GROUPS } from "const/userSearchVariants";
import useStoreByIp from "hooks/useStoreByIp";
import { useModal } from "hooks/useModal";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const EDIT_PERMISSION_MODAL_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_edit_permission_modal`;

const EditPermissionModal = ({ open, onClose, isCreate, ip }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { aclStore, userStore, shareStore } = useStoreByIp({ ip });

    const shareNameWithPrefix =
        aclStore.currentShareNameWithPrefix ||
        shareStore.currentShareNameWithPrefix ||
        shareStore.currentCheckedShareNameWithPrefix ||
        "";

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        get fullControlCheckboxStatus() {
            if (!ALL_GROUPS.some((permission) => !this.form.$(permission).value)) {
                return "checked";
            } else if (
                ALL_GROUPS.some((permission) => this.form.$(permission).value) &&
                ALL_GROUPS.some((permission) => !this.form.$(permission).value)
            ) {
                return "indeterminated";
            }
            return null;
        },
        get adminCheckboxStatus() {
            if (!ADMIN_GROUP.some((permission) => !this.form.$(permission).value)) {
                return "checked";
            } else if (
                ADMIN_GROUP.some((permission) => this.form.$(permission).value) &&
                ADMIN_GROUP.some((permission) => !this.form.$(permission).value)
            ) {
                return "indeterminated";
            }
            return null;
        },
        get readCheckboxStatus() {
            if (!READ_GROUP.some((permission) => !this.form.$(permission).value)) {
                return "checked";
            } else if (
                READ_GROUP.some((permission) => this.form.$(permission).value) &&
                READ_GROUP.some((permission) => !this.form.$(permission).value)
            ) {
                return "indeterminated";
            }
            return null;
        },
        get writeCheckboxStatus() {
            if (!WRITE_GROUP.some((permission) => !this.form.$(permission).value)) {
                return "checked";
            } else if (
                WRITE_GROUP.some((permission) => this.form.$(permission).value) &&
                WRITE_GROUP.some((permission) => !this.form.$(permission).value)
            ) {
                return "indeterminated";
            }
            return null;
        },
    }));

    const unsavedChangesModal = useModal();

    const onSave = () => {
        const permissions = {};

        ALL_GROUPS.forEach((permission) => {
            permissions[permission] = state.form.$(permission).value;
        });

        if (isCreate) {
            const currentEntity =
                userStore.currentEntity || userStore.currentEntitySingleBar || userStore.currentUserForChangingAccess;

            aclStore.acl &&
                aclStore.createAce({
                    domainObject: {
                        id: { ...currentEntity.id },
                        isExternal: currentEntity.isExternal,
                        isGroup: currentEntity.isGroup,
                    },
                    permissions,
                    allow: state.form.$("allow").value,
                    isInherited: false,
                    onlyForTheFirstChildren: state.form.$("onlyForTheFirstChildren").value,
                    appliedTo: APPLY_TO_OPTIONS[state.form.$("appliedTo").value],
                });
        } else {
            if (aclStore.acl) {
                state.form.isDefault && onClose();
                aclStore.updateCurrentAce("permissions", permissions);
                aclStore.updateCurrentAce("allow", state.form.$("allow").value);
                aclStore.updateCurrentAce("onlyForTheFirstChildren", state.form.$("onlyForTheFirstChildren").value);
                aclStore.updateCurrentAce("appliedTo", APPLY_TO_OPTIONS[state.form.$("appliedTo").value]);
            }
        }

        onClose();
    };

    const onEnter = () => {
        if (isCreate) {
            state.form.$("allow").set("default", true);
            state.form.$("appliedTo").set("default", "folderSubfoldersFiles");
            state.form.$("onlyForTheFirstChildren").set("default", false);
            ALL_GROUPS.forEach((permission) => {
                state.form.$(permission).set("default", false);
            });
        } else {
            state.form.$("allow").set("default", aclStore.currentAce.allow);
            state.form.$("appliedTo").set("default", getPermissionApplyToDefault({ ...aclStore.currentAce.appliedTo }));
            state.form.$("onlyForTheFirstChildren").set("default", aclStore.currentAce.onlyForTheFirstChildren);
            ALL_GROUPS.forEach((permission) => {
                state.form.$(permission).set("default", aclStore.currentAce.permissions[permission]);
            });
        }
        state.form.reset();
        unsavedChangesModal.close();
    };

    const getHeader = () => {
        return (
            <>
                <DialogTitle className={classes.dialogTitle}>
                    <Grid container direction={"column"} spacing={4}>
                        <Grid
                            justify={"space-between"}
                            alignContent={"center"}
                            alignItems={"center"}
                            container
                            item
                            wrap={"nowrap"}
                        >
                            <Grid item>
                                {isCreate
                                    ? t("users.edit_create.permission.modal.title.create", {
                                          shareName: shareNameWithPrefix,
                                      })
                                    : t("users.edit_create.permission.modal.title.edit", {
                                          shareName: shareNameWithPrefix,
                                      })}
                            </Grid>
                            <Grid item>
                                <CardHeaderControlButton onClick={closeIfNeeded}>
                                    <ButtonBase id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_close`}>
                                        <CloseIcon />
                                    </ButtonBase>
                                </CardHeaderControlButton>
                            </Grid>
                        </Grid>
                        <Grid item container wrap={"nowrap"} spacing={2} alignItems={"center"}>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <Typography variant={"body1"} className={classes.step} color={"primary"}>
                                        {t("share.question_modal.assign_users_and_groups.title")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <ThinArrowRightIcon />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <Typography variant={"body1"} className={classes.step} color={"primary"}>
                                        {t("users.customize_settings_modal.steps.customize_settings")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <ThinArrowRightIcon />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <Typography
                                        variant={"body1"}
                                        className={clsx(classes.step, classes.activeStep)}
                                        color={"primary"}
                                    >
                                        {isCreate
                                            ? t("file_navigator.advanced_security_modal.step.create_permissions")
                                            : t("file_navigator.advanced_security_modal.step.edit_permissions")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogTitle className={classes.inputsContainer}>
                    <RowTextInput
                        control={
                            <TextInput
                                disabled
                                value={
                                    isCreate
                                        ? userStore.currentUserForChangingAccess?.id?.name ||
                                          userStore.currentEntity?.id?.name ||
                                          userStore.currentEntitySingleBar?.id?.name
                                        : aclStore.currentAce !== null && aclStore.currentAce.domainObject?.id?.name
                                }
                                id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_domain_name`}
                            />
                        }
                        label={
                            isCreate
                                ? userStore.currentSearchRequest.type === LOCAL_GROUPS ||
                                  userStore.currentSearchRequest.type === EXTERNAL_GROUPS
                                    ? t("users.edit_create.permission.modal.input.label.group")
                                    : t("users.edit_create.permission.modal.input.label.user")
                                : aclStore.currentAce !== null && aclStore.currentAce.domainObject.isGroup
                                ? t("users.edit_create.permission.modal.input.label.group")
                                : t("users.edit_create.permission.modal.input.label.user")
                        }
                    />
                    <RowTextInput
                        control={
                            <Select
                                field={state.form.$("allow")}
                                options={[
                                    { value: true, label: t("users.edit_create.permission.modal.type_select.variant.allow") },
                                    { value: false, label: t("users.edit_create.permission.modal.type_select.variant.deny") },
                                ]}
                                id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_type`}
                            />
                        }
                        label={t("users.edit_create.permission.modal.input.label.type")}
                    />
                    <RowTextInput
                        control={
                            <Select
                                field={state.form.$("appliedTo")}
                                options={[
                                    {
                                        value: "folderOnly",
                                        label: t("users.edit_create.permission.modal.apply_to_select.variant.share_only"),
                                    },
                                    {
                                        value: "folderSubfoldersFiles",
                                        label: t(
                                            "users.edit_create.permission.modal.apply_to_select.variant.share_subfolders_files"
                                        ),
                                    },
                                    {
                                        value: "folderSubfolders",
                                        label: t("users.edit_create.permission.modal.apply_to_select.variant.share_subfolders"),
                                    },
                                    {
                                        value: "subfoldersFiles",
                                        label: t(
                                            "users.edit_create.permission.modal.apply_to_select.variant.share_subfolders_files_only"
                                        ),
                                    },
                                    {
                                        value: "subfolders",
                                        label: t(
                                            "users.edit_create.permission.modal.apply_to_select.variant.share_subfolders_only"
                                        ),
                                    },
                                    {
                                        value: "files",
                                        label: t("users.edit_create.permission.modal.apply_to_select.variant.share_files_only"),
                                    },
                                    {
                                        value: "folderFiles",
                                        label: t("users.edit_create.permission.modal.apply_to_select.variant.share_files"),
                                    },
                                ]}
                                id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_apply_to`}
                            />
                        }
                        label={t("users.edit_create.permission.modal.input.label.apply_to")}
                    />
                </DialogTitle>
                <Divider />
                <DialogTitle className={classes.checkboxContainer}>
                    <FormControlLabel
                        control={
                            <ClearCheckbox
                                onChange={onFullControlChange}
                                indeterminate={state.fullControlCheckboxStatus === "indeterminated"}
                                checked={state.fullControlCheckboxStatus === "checked"}
                                id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_full_control`}
                            />
                        }
                        label={
                            <Typography variant={"body1"}>
                                {t("users.edit_create.permission.modal.checkbox.label.full_control")}
                            </Typography>
                        }
                    />
                </DialogTitle>
            </>
        );
    };

    const onFullControlChange = () => {
        switch (state.fullControlCheckboxStatus) {
            case "checked":
            case "indeterminated":
                ALL_GROUPS.forEach((permission) => state.form.$(permission).set("value", false));
                break;
            default:
                ALL_GROUPS.forEach((permission) => state.form.$(permission).set("value", true));
                break;
        }
    };
    const onAdminChange = () => {
        switch (state.adminCheckboxStatus) {
            case "checked":
            case "indeterminated":
                ADMIN_GROUP.forEach((permission) => state.form.$(permission).set("value", false));
                break;
            default:
                ADMIN_GROUP.forEach((permission) => state.form.$(permission).set("value", true));
                break;
        }
    };
    const onReadChange = () => {
        switch (state.readCheckboxStatus) {
            case "checked":
            case "indeterminated":
                READ_GROUP.forEach((permission) => state.form.$(permission).set("value", false));
                break;
            default:
                READ_GROUP.forEach((permission) => state.form.$(permission).set("value", true));
                break;
        }
    };
    const onWriteChange = () => {
        switch (state.writeCheckboxStatus) {
            case "checked":
            case "indeterminated":
                WRITE_GROUP.forEach((permission) => state.form.$(permission).set("value", false));
                break;
            default:
                WRITE_GROUP.forEach((permission) => state.form.$(permission).set("value", true));
                break;
        }
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            PaperProps={{ className: classes.dialog }}
            open={open}
            onClose={closeIfNeeded}
            withoutPaddings
            onEnter={onEnter}
            header={getHeader()}
            submitBtn={
                <Button variant={"contained"} color={"primary"} onClick={onSave} id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_save`}>
                    {t("common.button.ok")}
                </Button>
            }
            additionalActions={
                <FormControlLabel
                    className={classes.formControlLabel}
                    control={
                        <Checkbox
                            field={state.form.$("onlyForTheFirstChildren")}
                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_only_for_the_first_children`}
                        />
                    }
                    label={t("users.edit_create.permission.modal.checkbox.label.only_for_the_first_children")}
                />
            }
            buttons={
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={onClose}
                    id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_back`}
                >
                    {t("common.button.back")}
                </Button>
            }
        >
            <Grid container direction={"column"} wrap={"nowrap"} className={classes.contentContainer}>
                <Accordion square className={classes.expansionPanel}>
                    <AccordionSummary expandIcon={<ChevronDownIcon />} className={classes.expansionSummary}>
                        <FormControlLabel
                            onClick={stopPropagation}
                            control={
                                <ClearCheckbox
                                    onChange={onAdminChange}
                                    checked={state.adminCheckboxStatus === "checked"}
                                    indeterminate={state.adminCheckboxStatus === "indeterminated"}
                                    id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_administration`}
                                />
                            }
                            label={t("users.edit_create.permission.modal.checkbox.label.administration")}
                        />
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionDetails}>
                        <Grid container direction={"column"} spacing={3} wrap={"nowrap"}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("changeOwner")}
                                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_change_owner`}
                                        />
                                    }
                                    label={t("users.edit_create.permission.modal.checkbox.label.change_owner")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("changePermissions")}
                                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_change_permissions`}
                                        />
                                    }
                                    label={t("users.edit_create.permission.modal.checkbox.label.change_permissions")}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion square className={classes.expansionPanel}>
                    <AccordionSummary expandIcon={<ChevronDownIcon />} className={classes.expansionSummary}>
                        <FormControlLabel
                            onClick={stopPropagation}
                            control={
                                <ClearCheckbox
                                    onChange={onReadChange}
                                    checked={state.readCheckboxStatus === "checked"}
                                    indeterminate={state.readCheckboxStatus === "indeterminated"}
                                    id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_read`}
                                />
                            }
                            label={t("users.edit_create.permission.modal.checkbox.label.read")}
                        />
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionDetails}>
                        <Grid container direction={"column"} spacing={3} wrap={"nowrap"}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("traverseFoldersExecuteFile")}
                                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_traverse_folders_execute_file`}
                                        />
                                    }
                                    label={t("users.edit_create.permission.modal.checkbox.label.traverse_folders_execute_file")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("listFoldersReadData")}
                                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_list_folders_read_data`}
                                        />
                                    }
                                    label={t("users.edit_create.permission.modal.checkbox.label.list_folders_read_data")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("readAttributes")}
                                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_read_attributes`}
                                        />
                                    }
                                    label={t("users.edit_create.permission.modal.checkbox.label.read_attributes")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("readExtendedAttributes")}
                                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_read_extended_attributes`}
                                        />
                                    }
                                    label={t("users.edit_create.permission.modal.checkbox.label.read_extended_attributes")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("readPermissions")}
                                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_read_permissions`}
                                        />
                                    }
                                    label={t("users.edit_create.permission.modal.checkbox.label.read_permissions")}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion square className={classes.expansionPanel}>
                    <AccordionSummary expandIcon={<ChevronDownIcon />} className={classes.expansionSummary}>
                        <FormControlLabel
                            onClick={stopPropagation}
                            control={
                                <ClearCheckbox
                                    onChange={onWriteChange}
                                    checked={state.writeCheckboxStatus === "checked"}
                                    indeterminate={state.writeCheckboxStatus === "indeterminated"}
                                    id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_write`}
                                />
                            }
                            label={t("users.edit_create.permission.modal.checkbox.label.write")}
                        />
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionDetails}>
                        <Grid container direction={"column"} spacing={3} wrap={"nowrap"}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("createFilesWriteData")}
                                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_create_files_write_data`}
                                        />
                                    }
                                    label={t("users.edit_create.permission.modal.checkbox.label.create_files_write_data")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("createFoldersAppendData")}
                                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_create_folders_append_data`}
                                        />
                                    }
                                    label={t("users.edit_create.permission.modal.checkbox.label.create_folders_append_data")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("writeAttributes")}
                                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_write_attributes`}
                                        />
                                    }
                                    label={t("users.edit_create.permission.modal.checkbox.label.write_attributes")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("writeExtendedAttributes")}
                                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_write_extended_attributes`}
                                        />
                                    }
                                    label={t("users.edit_create.permission.modal.checkbox.label.write_extended_attributes")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("deleteSubdirsAndFiles")}
                                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_delete_subdirs_and_files`}
                                        />
                                    }
                                    label={t("users.edit_create.permission.modal.checkbox.label.delete_subdirs_and_files")}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("delete")}
                                            id={`${EDIT_PERMISSION_MODAL_ID_PREFIX}_delete`}
                                        />
                                    }
                                    label={t("users.edit_create.permission.modal.checkbox.label.delete")}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default EditPermissionModal;
