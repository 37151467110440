import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./StatusLabel.style";

const StatusLabel = ({ status = "", text }) => {
    const classes = useStyles({ status });
    return (
        <Grid container alignItems="center" justifyContent="center" className={classes.label}>
            <Grid item>
                <Typography component={"span"} className={classes.labelText}>
                    {text || status}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default StatusLabel;
