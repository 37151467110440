import React from "react";
import { observer, useLocalStore } from "mobx-react";
import Dialog from "components/Dialog";
import { Button, Grid, Typography, Paper, CardMedia } from "@material-ui/core";
import i18next from "i18next";
import { useAddWatermarkModal } from "hooks/modals";
import TextInput from "components/MobxForm/TextInput";
import createForm from "utils/createForm";
import { createFields } from "./AddWatermarkDialog.utils";
import FileUpload from "components/FileUpload/FileUpload";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import { useStore } from "hooks/useStore";
import { useStyles } from "./AddWatermarkDialog.styles";

const SIZE_LIMIT_MIB = 20;
const SIZE_LIMIT = SIZE_LIMIT_MIB * 1024 * 1024;
const SIZE_LIMIT_STRING = `${SIZE_LIMIT_MIB} MiB`;

const ADD_WATERMARK_DIALOG_ID_PREFIX = `add_watermark_dialog`;

const AddWatermarkDialog = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${ADD_WATERMARK_DIALOG_ID_PREFIX}` : ADD_WATERMARK_DIALOG_ID_PREFIX;
    const classes = useStyles();
    const modal = useAddWatermarkModal();
    const {
        store: { slingshotSettingsStore },
    } = useStore();

    const state = useLocalStore(() => ({
        file: null,
        src: null,
        isLoadImageError: false,
        fileValidationInfo: false,
        needShowEmptyError: false,
        form: createForm({
            fields: createFields(),
        }),
        setFileValidationInfo(fileValidationInfo) {
            state.fileValidationInfo = fileValidationInfo;
        },
    }));
    const handleFile = async (file) => {
        state.file = file;
        state.src = URL.createObjectURL(file);
    };

    const onSubmit = async (e) => {
        state.needShowEmptyError = true;
        state.form.onSubmit(e);
        if (!state.form.isValid || !state.fileValidationInfo.isValid) return;
        await slingshotSettingsStore.createWatermarks(state.form.values(), state.file);
    };

    const onEnter = () => {
        state.form.$("name").set("default", "");
        state.form.reset();
        state.form.each((field) => {
            field.resetValidation();
        });
        state.file = null;
        state.src = null;
        state.isLoadImageError = false;
        state.needShowEmptyError = false;
    };

    const removeFileUpload = () => {
        state.file = null;
        state.src = null;
        state.isLoadImageError = false;
    };

    const onLoad = () => {
        state.inProgressGetImage = false;
    };

    const onError = () => {
        state.inProgressGetImage = false;
        state.isLoadImageError = true;
    };
    return (
        <Dialog
            onClose={modal.close}
            open={modal.isOpen}
            title={i18next.t("slingshot_page.tabs.settings.watermarks.add_dialog.title")}
            submitBtnLabel={i18next.t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            onSubmit={onSubmit}
            maxWidth={"md"}
            onEnter={onEnter}
            buttons={
                <Button onClick={modal.close} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {i18next.t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container spacing={2}>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{i18next.t("slingshot_page.tabs.settings.watermarks.add_dialog.name")}</Typography>
                    </Grid>

                    {/*[SLN-379] Hide Builtin, Personal, Disabled, Image loaded, Owner */}

                    <Grid item xs={8}>
                        <TextInput field={state.form.$("name")} inputProps={{ spellCheck: "false" }} id={`${idPrefix}_name`} />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{i18next.t("slingshot_page.tabs.settings.watermarks.add_dialog.file_load")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <FileUpload
                            file={state.file}
                            loading={state.loading}
                            handleFile={handleFile}
                            inputProps=".png"
                            regExpExtension={/\.png/}
                            sizeLimit={[SIZE_LIMIT, SIZE_LIMIT_STRING]}
                            noEmpty
                            needShowEmptyError={state.needShowEmptyError}
                            title={i18next.t("slingshot_page.tabs.settings.watermarks.add_dialog.drag_grop")}
                            removeFile={removeFileUpload}
                            onValid={state.setFileValidationInfo}
                            id={`${idPrefix}_file_upload`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4} />
                    <Grid item xs={8}>
                        <Paper className={state.src && !state.isLoadImageError && classes.previewContainer} elevation={2}>
                            {state.src && !state.isLoadImageError ? (
                                <CardMedia
                                    className={classes.watermarkImage}
                                    height={200}
                                    component={"img"}
                                    src={state.src}
                                    onLoad={onLoad}
                                    onError={onError}
                                />
                            ) : (
                                <EmptyCardPlaceholder
                                    height={200}
                                    message={
                                        state.isLoadImageError
                                            ? i18next.t(
                                                  "slingshot_page.tabs.settings.watermarks.upload_image__dialog.image_card.error"
                                              )
                                            : i18next.t(
                                                  "slingshot_page.tabs.settings.watermarks.upload_image__dialog.image_card.empty"
                                              )
                                    }
                                />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default observer(AddWatermarkDialog);
