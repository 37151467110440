import React from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { useLocalStore, observer } from "mobx-react-lite";
import { Grid, Typography } from "@material-ui/core";
import createForm from "utils/createForm";
import createFields from "./createFields";
import { Button } from "@material-ui/core";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import ConfirmationDialog from "components/ConfirmationDialog";
import Switch from "components/MobxForm/Switch";
import { CRF, START_POSITION, PASS } from "const/presetsDefaultValueConst";
import TextInput from "components/MobxForm/TextInput";
import {
    getPresetArgumentArrayWithDefault,
    PRESET_ARRAY,
    ACODEC_ARRAY,
    VCODEC_ARRAY,
    VCODEC_ARRAY_EDIT_PROXY,
    CONTAINER_ARRAY,
    BITRATE_ARRAY,
    PIX_FMT_ARRAY,
    TIMECODE_POSITION_ARRAY,
    BURNED_TIMECODE_FONT,
    BURNED_TIMECODE_FONTSIZE,
    MIN,
    MIN_HEIGHT,
    MIN_DURATION,
    MAX_CRF,
    MAX_HEIGHT,
    MAX_START_POSITION,
    MAX_AUDIO_CHANNELS,
    MAX_PASS,
    PRESET_TYPE_VIDEO,
    WATERMARK_POSSIBLE_SCALES,
    WATERMARK_POSSIBLE_POSITIONS,
} from "const/slingshotPresetConst";
import Autocomplete from "components/MobxForm/Autocomplete";
import Select from "components/MobxForm/Select";
import ErrorButton from "components/ErrorButton/ErrorButton";
import { useStyles } from "./CreateEditPresetDialog.styles";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import { getSnapshot } from "mobx-state-tree";

const CREATE_EDIT_PRESET_MODAL_ID_PREFIX = `create_edit_preset_dialog`;

const CreateEditPresetDialog = ({ open, onClose, isCreate, onCloseDrawer, changeProgressState, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${CREATE_EDIT_PRESET_MODAL_ID_PREFIX}` : CREATE_EDIT_PRESET_MODAL_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { slingshotSettingsStore },
    } = useStore();
    const classes = useStyles();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        inProgress: false,
        isSubmitted: false,
        pixFmtArray: [],
        get currentPreset() {
            return slingshotSettingsStore.currentPreset || slingshotSettingsStore.configAutotaggingPreset;
        },
        get watermarks() {
            return slingshotSettingsStore.watermarks
                .filter((watermark) => !watermark?.disabled && watermark?.image_loaded)
                .reduce(
                    (acc, watermark) => [
                        ...acc,
                        {
                            label: watermark.name,
                            value: watermark.id,
                        },
                    ],
                    [{ label: "No watermark", value: "default" }]
                );
        },
    }));

    const restorePresetModal = useModal();
    const unsavedChangesModal = useModal();

    const CUR_VCODEC_ARRAY = state.currentPreset?.id === 11 ? VCODEC_ARRAY_EDIT_PROXY : VCODEC_ARRAY;

    const updateForm = async () => {
        state.inProgress = true;
        const defaultWatermarkPossibleScale = WATERMARK_POSSIBLE_SCALES.find((el) => el.default)?.value;
        const defaultWatermarkPossiblePosition = WATERMARK_POSSIBLE_POSITIONS.find((el) => el.default)?.value;
        if (isCreate) {
            await slingshotSettingsStore.fetchPresetConstraints();
            state.form.$("preset").set("default", PRESET_ARRAY[4].value);
            state.form.$("crf").set("default", CRF);
            state.form.$("height").set("default", "");
            state.form.$("acodec").set("default", ACODEC_ARRAY[1].value);
            state.form.$("vcodec").set("default", CUR_VCODEC_ARRAY[1].value);
            definePixFmtArray(state.form.$("vcodec").default);
            state.form.$("pix_fmt").set("default", state.pixFmtArray[1].label);
            state.form.$("container").set("default", CONTAINER_ARRAY[0].value);
            state.form.$("bv").set("default", BITRATE_ARRAY[0].value);
            state.form.$("start").set("default", START_POSITION);
            state.form.$("duration").set("default", "");
            state.form.$("ac").set("default", "");
            state.form.$("pass").set("default", PASS);
            state.form.$("burnt_timecode").set("default", false);
            state.form.$("timecode_position").set("default", TIMECODE_POSITION_ARRAY[4].value);
            state.form.$("watermark_id").set("default", state.watermarks[0].value);
            state.form.$("watermark_position").set("default", defaultWatermarkPossiblePosition);
            state.form.$("watermark_scale").set("default", defaultWatermarkPossibleScale);
        } else {
            const currentPreset = state.currentPreset;
            await slingshotSettingsStore.fetchPresetConstraints(currentPreset.id);
            state.form.$("name").set("default", currentPreset.name);
            state.form.$("preset").set("default", currentPreset.preset?.value || PRESET_ARRAY[4].value);
            state.form.$("crf").set("default", currentPreset.crf?.value || CRF);
            state.form.$("height").set("default", currentPreset.height?.value || "");
            state.form.$("acodec").set("default", currentPreset.acodec?.value || ACODEC_ARRAY[0].value);
            state.form.$("vcodec").set("default", currentPreset.vcodec || CUR_VCODEC_ARRAY[0].value);
            definePixFmtArray(state.form.$("vcodec").default);
            state.form
                .$("pix_fmt")
                .set(
                    "default",
                    !currentPreset.pix_fmt
                        ? getOptionsArray(state.pixFmtArray, "pix_fmt")[1]?.label || PIX_FMT_ARRAY[1].label
                        : !currentPreset.pix_fmt?.value
                            ? getOptionsArray(state.pixFmtArray, "pix_fmt")[0].label
                            : getOptionsArray(state.pixFmtArray, "pix_fmt").find(
                                (pixFmt) => pixFmt.value === currentPreset.pix_fmt?.value
                            )?.label
                );
            state.form.$("container").set("default", currentPreset?.arguments?.container?.value || CONTAINER_ARRAY[0].value);
            state.form.$("bv").set("default", currentPreset.bv?.value || BITRATE_ARRAY[0].value);
            state.form.$("start").set("default", currentPreset.start?.value || START_POSITION);
            state.form.$("duration").set("default", currentPreset.duration?.value || "");
            state.form.$("ac").set("default", currentPreset.ac?.value || "");
            state.form.$("pass").set("default", currentPreset.pass?.value || PASS);
            state.form
                .$("burnt_timecode")
                .set(
                    "default",
                    currentPreset?.burnt_timecode?.value &&
                        Object.prototype.hasOwnProperty.call(currentPreset.burnt_timecode.value, "disabled")
                        ? !currentPreset?.burnt_timecode?.value?.disabled
                        : false
                );
            state.form
                .$("timecode_position")
                .set("default", currentPreset?.burnt_timecode?.value?.position || TIMECODE_POSITION_ARRAY[4].value);
            state.form
                .$("watermark_id")
                .set("default", currentPreset?.watermark_id ? currentPreset?.watermark_id : state.watermarks[0].value);
            state.form
                .$("watermark_position")
                .set("default", currentPreset?.watermark?.value?.position ?? defaultWatermarkPossiblePosition);
            state.form
                .$("watermark_scale")
                .set("default", currentPreset?.watermark?.value?.scale ?? defaultWatermarkPossibleScale);
        }
        state.inProgress = false;
        state.form.reset();
        state.form.fields.forEach((field) => field.resetValidation());
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };

    const getSubmitValue = (field) => {
        const acodecValue = state.form.$("acodec").value === ACODEC_ARRAY[0].value ? null : state.form.$("acodec").value;
        const vcodecValue = state.form.$("vcodec").value === CUR_VCODEC_ARRAY[0].value ? null : state.form.$("vcodec").value;
        const pixFormatValue =
            state.form.$("pix_fmt").value === getOptionsArray(state.pixFmtArray, "pix_fmt")[0].label
                ? null
                : getOptionsArray(state.pixFmtArray, "pix_fmt").find((pix_fmt) => pix_fmt.label === state.form.$("pix_fmt").value)
                    ?.value;
        const containerValue =
            state.form.$("container").value === CONTAINER_ARRAY[0].value ? null : state.form.$("container").value;
        const bitrateValue = state.form.$("bv").value === BITRATE_ARRAY[0].value ? null : state.form.$("bv").value;
        const start = state.form.$("start").value.length ? state.form.$("start").value : "0";
        const duration = state.form.$("duration").value.length ? state.form.$("duration").value : null;
        const ac = state.form.$("ac").value.length ? Number(state.form.$("ac").value) : null;
        const burnedTimecode = {
            disabled: !state.form.$("burnt_timecode").value,
            position: state.form.$("timecode_position").value,
            font: BURNED_TIMECODE_FONT,
            font_size: BURNED_TIMECODE_FONTSIZE,
        };

        switch (field.name) {
            case "crf":
            case "height":
            case "pass":
                return Number(field.value);
            case "acodec":
                return acodecValue;
            case "vcodec":
                return vcodecValue;
            case "pix_fmt":
                return pixFormatValue;
            case "container":
                return containerValue;
            case "bv":
                return bitrateValue;
            case "start":
                return start;
            case "duration":
                return duration;
            case "ac":
                return ac;
            case "burnt_timecode":
                return burnedTimecode;
            case "timecode_position":
                return;
            default:
                return field.value;
        }
    };

    const getPresetArgumentValueForArray = (presetArgument, constraint) => {
        const presetArgumentValue = presetArgument.find(
            (presetArgumentValue) => presetArgumentValue.value === constraint.defaultValue
        );
        if (presetArgumentValue) {
            return presetArgumentValue;
        }

        switch (constraint.name) {
            case "acodec":
                return ACODEC_ARRAY[0];
            case "vcodec":
                return VCODEC_ARRAY[0];
            case "container":
                return CONTAINER_ARRAY[0];
            case "bv":
                return BITRATE_ARRAY[0];
            case "pix_fmt":
                return getOptionsArray(state.pixFmtArray, "pix_fmt")[0];
            case "preset":
                return PRESET_ARRAY[4];
            case "watermark_position":
                return WATERMARK_POSSIBLE_POSITIONS[4];
            case "watermark_scale":
                return WATERMARK_POSSIBLE_SCALES[1];
            case "timecode_position":
                return WATERMARK_POSSIBLE_POSITIONS[7];
            default:
                break;
        }
    };

    const getConstraintDefaultValue = (constraints, constraintName) => {
        const constraint = constraints.find((c) => c.name === constraintName);
        return constraint ? constraint.defaultValue : null;
    };

    const setDefaultValues = () => {
        slingshotSettingsStore.currentPresetConstraints.forEach((constraint) => {
            switch (constraint.name) {
                case "preset":
                    state.form.$("preset").set("value", getPresetArgumentValueForArray(PRESET_ARRAY, constraint).value);
                    break;
                case "crf":
                    state.form.$("crf").set("value", constraint.defaultValue ? constraint.defaultValue : CRF);
                    state.form.$("crf").resetValidation();
                    break;
                case "height":
                    state.form.$("height").set("value", constraint.defaultValue ? constraint.defaultValue : "");
                    state.form.$("height").resetValidation();
                    break;
                case "acodec":
                    state.form.$("acodec").set("value", getPresetArgumentValueForArray(ACODEC_ARRAY, constraint).value);
                    break;
                case "vcodec":
                    state.form.$("vcodec").set("value", getPresetArgumentValueForArray(VCODEC_ARRAY, constraint).value);
                    break;
                case "pix_fmt":
                    definePixFmtArray(state.form.$("vcodec").value);
                    state.form
                        .$("pix_fmt")
                        .set(
                            "value",
                            getPresetArgumentValueForArray(getOptionsArray(state.pixFmtArray, "pix_fmt"), constraint).label
                        );
                    break;
                case "container":
                    state.form.$("container").set("value", getPresetArgumentValueForArray(CONTAINER_ARRAY, constraint).value);
                    break;
                case "bv":
                    state.form.$("bv").set("value", getPresetArgumentValueForArray(BITRATE_ARRAY, constraint).value);
                    break;
                case "start":
                    state.form.$("start").set("value", constraint.defaultValue ? constraint.defaultValue : "0");
                    state.form.$("start").resetValidation();
                    break;
                case "duration":
                    state.form.$("duration").set("value", constraint.defaultValue ? constraint.defaultValue : "");
                    state.form.$("duration").resetValidation();
                    break;
                case "ac":
                    state.form.$("ac").set("value", constraint.defaultValue ? constraint.defaultValue : "");
                    state.form.$("ac").resetValidation();
                    break;
                case "pass":
                    state.form.$("pass").set("value", constraint.defaultValue ? constraint.defaultValue : PASS);
                    state.form.$("pass").resetValidation();
                    break;
                case "burnt_timecode":
                    state.form
                        .$("burnt_timecode")
                        .set("value", constraint.defaultValue !== null ? !constraint.defaultValue : false);
                    break;
                case "timecode_position":
                    state.form
                        .$("timecode_position")
                        .set("value", getPresetArgumentValueForArray(TIMECODE_POSITION_ARRAY, constraint).value);
                    break;
                case "watermark_position":
                    state.form
                        .$("watermark_position")
                        .set("value", getPresetArgumentValueForArray(WATERMARK_POSSIBLE_POSITIONS, constraint).value);
                    break;
                case "watermark_scale":
                    state.form
                        .$("watermark_scale")
                        .set("value", getPresetArgumentValueForArray(WATERMARK_POSSIBLE_SCALES, constraint).value);
                    break;
                case "width":
                case "profile":
                case "output_args":
                case "copyts":
                case "export_xmp":
                case "vf_postscale":
                case "vf_prescale":
                    break;
                default:
                    break;
            }
        });

        state.form.$("watermark_id").set("value", state.watermarks[0].value);
    };

    const onSubmit = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        state.inProgress = true;
        !isCreate && changeProgressState(true);
        let data = {};
        if (!isCreate) {
            const currentPresetArguments = Object.fromEntries(
                Object.entries(getSnapshot(state.currentPreset.arguments)).filter((n) => n[1] !== undefined)
            );
            data = { ...data, ...currentPresetArguments };
        }

        state.form.fields.forEach((field) => {
            if (!field.isDefault) {
                data = { ...data, ...{ [field.name]: { value: getSubmitValue(field), user_choice: true } } };
            }
        });

        if (!data?.height?.value) {
            delete data.height;
        }

        data.watermark = {
            value: { position: state.form.$("watermark_position").value, scale: state.form.$("watermark_scale").value },
            user_choice: true,
        };
        const watermarkId =
            state.form.$("watermark_id").value === state.watermarks[0].value ? null : state.form.$("watermark_id").value;
        const res = isCreate
            ? await slingshotSettingsStore.createPreset({
                name: state.form.$("name").value,
                builtin: false,
                preset_type: PRESET_TYPE_VIDEO,
                arguments: data,
                watermark_id: watermarkId,
            })
            : await slingshotSettingsStore.updatePreset({
                id: state.currentPreset.id,
                name: state.form.$("name").value,
                builtin: state.currentPreset.builtin || false,
                preset_type: state.currentPreset.preset_type,
                arguments: data,
                watermark_id: watermarkId,
            });
        state.inProgress = false;
        !isCreate && changeProgressState(false);
        if (res) {
            state.isSubmitted = true;
            slingshotSettingsStore.fetchPresets();
            !isCreate && onCloseDrawer();
        }
        return res;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const handleChangeBitrate = (e) => {
        state.form.$("bv").set("value", e.target.value);
        
        if (state.form.$("bv").value === BITRATE_ARRAY[0].value) {
            state.form.$("pass").set("value", PASS);
            state.form.$("pass").resetValidation();
        }
    };

    const definePixFmtArray = (vcodec) => {
        switch (vcodec) {
            case CUR_VCODEC_ARRAY[3].value:
            case CUR_VCODEC_ARRAY[4].value:
            case CUR_VCODEC_ARRAY[5].value:
            case CUR_VCODEC_ARRAY[6].value:
            case CUR_VCODEC_ARRAY[7].value:
            case CUR_VCODEC_ARRAY[8].value:
                state.pixFmtArray = PIX_FMT_ARRAY.slice(15, 16).concat(PIX_FMT_ARRAY.slice(-2, -1));
                break;
            default:
                state.pixFmtArray = PIX_FMT_ARRAY.slice(0, -1);
                break;
        }
    };

    const handleChangeVideoCodec = (e) => {
        state.form.$("vcodec").set("value", e.target.value);
        definePixFmtArray(state.form.$("vcodec").value);
        if (
            state.form.$("vcodec").value === CUR_VCODEC_ARRAY[3].value ||
            state.form.$("vcodec").value === CUR_VCODEC_ARRAY[4].value ||
            state.form.$("vcodec").value === CUR_VCODEC_ARRAY[5].value ||
            state.form.$("vcodec").value === CUR_VCODEC_ARRAY[6].value ||
            state.form.$("vcodec").value === CUR_VCODEC_ARRAY[7].value
        ) {
            state.form.$("pix_fmt").set("value", getOptionsArray(state.pixFmtArray, "pix_fmt")[1].label);
        } else if (state.form.$("vcodec").value === CUR_VCODEC_ARRAY[8].value) {
            state.form.$("pix_fmt").set("value", getOptionsArray(state.pixFmtArray, "pix_fmt")[0].label);
        }
    };

    const restorePreset = async () => {
        restorePresetModal.close();
        setDefaultValues();
    };

    const handleChangeBurnedTimecode = (e) => {
        state.form.$("burnt_timecode").set("value", e.target.checked);
    };

    const handleChangeTimecodePosition = (e) => {
        state.form.$("timecode_position").set("value", e.target.value);
        state.form.$("timecode_position").set("default", "");
        state.form.$("burnt_timecode").set("value", state.form.$("burnt_timecode").value);
        state.form.$("burnt_timecode").set("default", null);
    };

    const getOptionsArray = (presetArgumentArray, constraintName) => {
        const constraintDefaultValue = getConstraintDefaultValue(slingshotSettingsStore.currentPresetConstraints, constraintName);

        return getPresetArgumentArrayWithDefault(constraintDefaultValue, presetArgumentArray, constraintName);
    };

    return (
        <Dialog
            onClose={closeIfNeeded}
            open={open}
            title={
                isCreate
                    ? t("slingshot_page.tabs.settings.create_preset")
                    : t("slingshot_page.tabs.settings.edit_preset", { presetName: state.currentPreset?.name || "" })
            }
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            inProgress={state.inProgress}
            onSubmit={onSubmit}
            onEnter={updateForm}
            maxWidth={"md"}
            buttons={
                <>
                    <ErrorButton
                        submit={restorePresetModal.open}
                        disabled={state.inProgress}
                        label={t("common.button.restore")}
                        id={`${idPrefix}_restore`}
                    ></ErrorButton>
                    <Button onClick={onClose} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                        {t("common.button.cancel")}
                    </Button>
                </>
            }
        >
            <Grid container spacing={6}>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.name")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            field={state.form.$("name")}
                            inputProps={{ spellCheck: "false" }}
                            disabled={state.inProgress}
                            id={`${idPrefix}_name`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.preset")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            field={state.form.$("preset")}
                            options={getOptionsArray(PRESET_ARRAY, "preset")}
                            disabled={state.inProgress}
                            id={`${idPrefix}_preset`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.crf")}</Typography>
                        <Typography variant={"body2"} className={classes.helperText}>
                            {t("slingshot_page.tabs.settings.presets.default", {
                                value: CRF,
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            inputProps={{ min: MIN, max: MAX_CRF, step: "1" }}
                            field={state.form.$("crf")}
                            disabled={state.inProgress}
                            id={`${idPrefix}_crf`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.height")}</Typography>
                        <Typography variant={"body2"} className={classes.helperText}>
                            {t("slingshot_page.tabs.settings.presets.original_default")}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            inputProps={{ min: MIN_HEIGHT, max: MAX_HEIGHT, step: "1" }}
                            field={state.form.$("height")}
                            disabled={state.inProgress}
                            id={`${idPrefix}_height`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.audio_codec")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            field={state.form.$("acodec")}
                            options={getOptionsArray(ACODEC_ARRAY, "acodec")}
                            disabled={state.inProgress}
                            id={`${idPrefix}_acodec`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.video_codec")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            field={state.form.$("vcodec")}
                            options={getOptionsArray(VCODEC_ARRAY, "vcodec")}
                            onChange={handleChangeVideoCodec}
                            disabled={state.inProgress}
                            id={`${idPrefix}_vcodec`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.pix_fmt")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            field={state.form.$("pix_fmt")}
                            options={getOptionsArray(state.pixFmtArray, "pix_fmt").map((el) => el.label)}
                            disabled={state.inProgress}
                            id={`${idPrefix}_pix_fmt`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.container")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            field={state.form.$("container")}
                            options={getOptionsArray(CONTAINER_ARRAY, "container")}
                            disabled={state.inProgress}
                            id={`${idPrefix}_container`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.bitrate")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            field={state.form.$("bv")}
                            options={getOptionsArray(BITRATE_ARRAY, "bv")}
                            onChange={handleChangeBitrate}
                            disabled={state.inProgress}
                            id={`${idPrefix}_bv`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.start_position")}</Typography>
                        <Typography variant={"body2"} className={classes.helperText}>
                            {t("slingshot_page.tabs.settings.presets.default", {
                                value: START_POSITION,
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            inputProps={{ min: MIN, max: MAX_START_POSITION, step: "1" }}
                            field={state.form.$("start")}
                            disabled={state.inProgress}
                            id={`${idPrefix}_start`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.duration")}</Typography>
                        <Typography variant={"body2"} className={classes.helperText}>
                            {t("slingshot_page.tabs.settings.presets.original_default")}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            inputProps={{ min: MIN_DURATION, step: "1" }}
                            field={state.form.$("duration")}
                            disabled={state.inProgress}
                            id={`${idPrefix}_duration`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.audio_channels")}</Typography>
                        <Typography variant={"body2"} className={classes.helperText}>
                            {t("slingshot_page.tabs.settings.presets.original_default")}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            inputProps={{ min: MIN, max: MAX_AUDIO_CHANNELS, step: "1" }}
                            field={state.form.$("ac")}
                            disabled={state.inProgress}
                            id={`${idPrefix}_ac`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.pass")}</Typography>
                        <Typography variant={"body2"} className={classes.helperText}>
                            {t("slingshot_page.tabs.settings.presets.default", {
                                value: PASS,
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            disabled={state.form.$("bv").value === BITRATE_ARRAY[0].value || state.inProgress}
                            inputProps={{ min: MIN, max: MAX_PASS, step: "1" }}
                            field={state.form.$("pass")}
                            id={`${idPrefix}_pass`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.burnt_timecode")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Switch
                            onChange={handleChangeBurnedTimecode}
                            field={state.form.$("burnt_timecode")}
                            checked={state.form.$("burnt_timecode").value}
                            value={state.form.$("burnt_timecode").value}
                            disabled={state.inProgress}
                            id={`${idPrefix}_burnt_timecode`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.presets.timecode_position")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            disabled={!state.form.$("burnt_timecode").value || state.inProgress}
                            field={state.form.$("timecode_position")}
                            options={getOptionsArray(TIMECODE_POSITION_ARRAY, "timecode_position")}
                            onChange={handleChangeTimecodePosition}
                            id={`${idPrefix}_timecode_position`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>Watermark</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            field={state.form.$("watermark_id")}
                            options={state.watermarks}
                            disabled={state.inProgress}
                            id={`${idPrefix}_watermark_id`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>Watermark position</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            field={state.form.$("watermark_position")}
                            options={getOptionsArray(WATERMARK_POSSIBLE_POSITIONS, "watermark_position")}
                            disabled={state.inProgress}
                            id={`${idPrefix}_watermark_position`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>Watermark scale</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            field={state.form.$("watermark_scale")}
                            options={getOptionsArray(WATERMARK_POSSIBLE_SCALES, "watermark_scale")}
                            disabled={state.inProgress}
                            id={`${idPrefix}_watermark_scale`}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <ConfirmationDialog
                title={t("common.warning_dialog.title")}
                open={restorePresetModal.isOpen}
                onClose={restorePresetModal.close}
                onConfirm={restorePreset}
                confirmLabel={t("common.button.proceed")}
                cancelLabel={t("common.button.cancel")}
                fullWidth={true}
                id={`${idPrefix}_confirmation_dialog`}
            >
                {t("slingshot_page.tabs.settings.presets.preset_configuration.confirm_restore_title")}
            </ConfirmationDialog>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    );
};

export default observer(CreateEditPresetDialog);
