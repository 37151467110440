export default {
    saveSettingsConfirm: {
        paragraphs: [
            {
                textKey: "advanced.nas_configuration.save_settings_confirm.message_2",
                gutterBottom: true,
            },
            {
                textKey: "advanced.nas_configuration.save_settings_confirm.confirmation_question",
            },
        ],
    },
    saveSettingsConfirmExtension: {
        paragraphs: [
            {
                textKey: "advanced.nas_configuration.save_settings_confirm.message_1",
                gutterBottom: true,
            },
            {
                textKey: "advanced.nas_configuration.save_settings_confirm.message_2",
                gutterBottom: true,
            },
            {
                textKey: "advanced.nas_configuration.save_settings_confirm.confirmation_question",
            },
        ],
    },
};
