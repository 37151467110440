import React, { useEffect, useContext, useCallback } from "react";
import { Context } from "store";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import SetupBackupDialog from "./components/SetupBackupDialog";
import ImportBackupModal from "./components/ImportBackupModal";
import { DATABASE_SHAREBROWSER, DATABASE_SLINGSHOT, DELETE_BACKUPS } from "const/setupBackupConst";
import { SCHEDULED_BACKUP_CARD } from "const/widgetNameConst";
import { SUPPORT_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const BACKUP_CARD_ID_PREFIX = `${SUPPORT_PAGE_ID_PREFIX}_backup_card`;

const BackupCard = () => {
    const { t } = useTranslation();
    const {
        store: { backupSettingsStore, fileStore, postgresStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isLoading: false,
        openEditDialog: false,
        get backupsDeleteTime() {
            if (!backupSettingsStore.backup) return DELETE_BACKUPS[0].label;
            return DELETE_BACKUPS.find(el => el.value === backupSettingsStore.backup.deletePeriodSec)?.label;
        },
        isImportBackupModalOpen: false,
        variant: undefined
    }));

    useEffect(() => {
        (async () => {
            state.isLoading = true;
            await backupSettingsStore.fetchBackup();
            if (backupSettingsStore.backup?.location?.share) {
                await fileStore.listFiles({
                    shareName: backupSettingsStore.backup?.location?.share,
                    dirOnShare: ""
                });
            }
            await postgresStore.getDatabaseLocation();
            state.isLoading = false;
        })();
    }, []);

    const closeDialog = useCallback(() => {
        state.openEditDialog = false;
    });
    const openDialog = useCallback(() => {
        state.openEditDialog = true;
    });

    const exportSlingshot = () => {
        backupSettingsStore.exportSlingshot();
    };

    const exportShareBrowser = () => {
        backupSettingsStore.exportShareBrowser();
    };

    const openImportBackupModalSlingshot = useCallback(async () => {
        state.variant = DATABASE_SLINGSHOT;
        await backupSettingsStore.getListDatabaseBackupsSlingshot();
        await backupSettingsStore.getListConfigBackupsSlingshot();
        state.isImportBackupModalOpen = true;
    });

    const openImportBackupModalSharebrowser = useCallback(async () => {
        state.variant = DATABASE_SHAREBROWSER;
        await backupSettingsStore.getListBackups();
        state.isImportBackupModalOpen = true;
    });

    const closeImportBackupModal = useCallback(() => {
        state.isImportBackupModalOpen = false;
    });

    return useObserver(() => (
        <>
            <SetupBackupDialog open={state.openEditDialog} onClose={closeDialog} />
            <ImportBackupModal open={state.isImportBackupModalOpen} onClose={closeImportBackupModal} variant={state.variant} />
            <DataReadonlyCard
                headerDivider
                title={t("support.os_data.backup_card.title")}
                control={
                    <MoreMenu id={`${BACKUP_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={SCHEDULED_BACKUP_CARD} />
                        <MenuItem
                            onClick={exportSlingshot}
                            disabled={!postgresStore.isDatabaseInitialize}
                            id={`${BACKUP_CARD_ID_PREFIX}_export_slingshot`}
                        >
                            {t("support.os_data.backup_card.context_menu.export_slingshot")}
                        </MenuItem>
                        <MenuItem
                            onClick={exportShareBrowser}
                            disabled={!postgresStore.isDatabaseInitialize}
                            id={`${BACKUP_CARD_ID_PREFIX}_export_sharebrowser`}
                        >
                            {t("support.os_data.backup_card.context_menu.export_sharebrowser")}
                        </MenuItem>
                        <MenuItem
                            onClick={openImportBackupModalSlingshot}
                            disabled={!postgresStore.isDatabaseInitialize}
                            id={`${BACKUP_CARD_ID_PREFIX}_import_slingshot`}
                        >
                            {t("support.os_data.backup_card.context_menu.import_slingshot")}
                        </MenuItem>
                        <MenuItem
                            onClick={openImportBackupModalSharebrowser}
                            disabled={!postgresStore.isDatabaseInitialize}
                            id={`${BACKUP_CARD_ID_PREFIX}_import_sharebrowser`}
                        >
                            {t("support.os_data.backup_card.context_menu.import_sharebrowser")}
                        </MenuItem>
                    </MoreMenu>
                }
                onClick={openDialog}
            >
                {!state.isLoading && backupSettingsStore.backup ? (
                    <Grid container direction="column" spacing={6}>
                        <Grid container item alignItems="baseline" spacing={2} wrap="nowrap">
                            <Grid item xs={6}>
                                <Typography>{t("support.os_data.backup_card.label.share_to_backup")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6">{backupSettingsStore.backup.location?.share || ""}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item alignItems="baseline" spacing={2} wrap="nowrap">
                            <Grid item xs={6}>
                                <Typography>{t("support.os_data.backup_card.label.path_to_backup")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6">{backupSettingsStore.backup.location?.dir || "/"}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container item alignItems="baseline" spacing={2} wrap="nowrap">
                            <Grid item xs={6}>
                                <Typography>{t("support.os_data.backup_card.label.schedule")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6">
                                    {backupSettingsStore.backupSchedule || t("support.os_data.backup_card.delete_backups.never")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item alignItems="baseline" spacing={2} wrap="nowrap">
                            <Grid item xs={6}>
                                <Typography>{t("support.os_data.backup_card.label.delete_backups")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6">{state.backupsDeleteTime}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder inProgress={state.isLoading} />
                )}
            </DataReadonlyCard>
        </>
    ));
};

export default BackupCard;
