import { types } from "mobx-state-tree";

import NetInterface from "api/net/Types/NetInterface";
import NetBond from "api/net/Types/NetBond";
import NetBridge from "api/net/Types/NetBridge";

const NetInterfacesInfo = types.model({
    ifaces: types.array(NetInterface),
    bonds: types.array(NetBond),
    bridges: types.array(NetBridge)
});

export default NetInterfacesInfo;
