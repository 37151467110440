import { types } from "mobx-state-tree";

const TemperatureSensor = types
    .model({
        name: types.string,
        temperature: types.number
    })
    .views(self => ({
        get value() {
            return self.temperature;
        }
    }));

export default TemperatureSensor;
