import { types } from "mobx-state-tree";

import WatermarkImageResult from "../Types/WatermarkImageResult";

const GetWatermarkImageResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(WatermarkImageResult, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            },
        })
    ),
});

export default GetWatermarkImageResult;
