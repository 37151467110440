import React, { useContext, useEffect } from "react";
import {
    ButtonBase,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Button,
} from "@material-ui/core";
import { useLocalStore, observer } from "mobx-react";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import CloseIcon from "assets/CloseIcon";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import { useStyles } from "./Dialog.styles";
import clsx from "clsx";
import { Context } from "store";

const Dialog = ({
    title,
    onClose,
    children,
    submitBtnLabel,
    submitBtnId,
    disableSubmit,
    onSubmit,
    submitBtn,
    withoutClose,
    buttons,
    withoutPaddings,
    header,
    additionalActions,
    additionalTitle,
    open,
    afterSubmitCallback,
    // if a form data was changed, need to prevent closing of a tab if a dialog was opened.
    isDataChanged,
    withoutIconClose,
    withoutDividers,
    hide,
    onEnter,
    omitSubmit,
    onKeyPress,
    closeBtnLabel,
    disableClosingAfterStartLoading,
    ...rest
}) => {
    const state = useLocalStore(() => ({
        isSubmitted: false,
        isLoading: false,
    }));

    const {
        store: { processingStore },
    } = useContext(Context);

    const classes = useStyles({ withoutPaddings });

    const submitted = () => {
        if (!withoutClose) {
            onClose(state.isSubmitted);
            afterSubmitCallback && afterSubmitCallback();
        }

        state.isSubmitted = false;
    };

    /**
        handler alwaysh should return result
     */
    const createSubmitHandler = (handler) => async (event) => {
        state.isLoading = true;
        let hasRequestInterval = false;
        let isRequestEnd = false;

        const errorsListLengthBefore = processingStore.errorsList.length;

        setTimeout(() => {
            if (!isRequestEnd) {
                hasRequestInterval = true;
            }
        }, 0);

        const res = await handler(event);
        isRequestEnd = true;

        if (res === "error") {
            state.isLoading = false;
            return;
        }

        const errorsListLengthAfter = processingStore.errorsList.length;

        if ((errorsListLengthBefore >= errorsListLengthAfter && hasRequestInterval) || res) {
            state.isSubmitted = true;
        }

        state.isLoading = false;

        if (!state.isSubmitted) {
            return;
        }
        if (!disableClosingAfterStartLoading) {
            setTimeout(submitted, 1000);
        }
    };

    const handleSubmitButton = onSubmit && createSubmitHandler(onSubmit);
    const handleSubmitKeyPress = onKeyPress && createSubmitHandler(onKeyPress);

    useEffect(() => {
        const handler = (event) => {
            if (!isDataChanged) {
                return;
            }
            event.preventDefault();
            event.returnValue = "";
        };
        if (open) {
            window.addEventListener("beforeunload", handler);
        }
        return () => window.removeEventListener("beforeunload", handler);
    }, [isDataChanged, open]);

    const onEnterHandler = () => {
        state.isSubmitted = false;
        state.isLoading = false;

        onEnter && onEnter();
    };

    return (
        <MuiDialog
            className={clsx(hide && classes.hideDialog)}
            onEscapeKeyDown={!(disableClosingAfterStartLoading && (state.isLoading || state.isSubmitted)) && onClose}
            onBackdropClick={!(disableClosingAfterStartLoading && (state.isLoading || state.isSubmitted)) && onClose}
            maxWidth={"sm"}
            fullWidth
            open={open}
            onEnter={onEnterHandler}
            onKeyPress={handleSubmitKeyPress}
            {...rest}
        >
            {header || (
                <>
                    <DialogTitle>
                        <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container wrap={"nowrap"}>
                            <Grid item className={classes.titleText}>
                                {title}
                            </Grid>
                            {!withoutIconClose && (
                                <Grid item>
                                    <CardHeaderControlButton onClick={onClose}>
                                        <ButtonBase>
                                            <CloseIcon />
                                        </ButtonBase>
                                    </CardHeaderControlButton>
                                </Grid>
                            )}
                        </Grid>
                    </DialogTitle>
                    {additionalTitle && <Divider />}
                    {additionalTitle}
                </>
            )}
            <DialogContent className={classes.dialogContent} dividers={!withoutDividers}>
                {children}
            </DialogContent>
            {additionalActions && (
                <>
                    <DialogActions>{additionalActions}</DialogActions>
                    <Divider />
                </>
            )}
            <DialogActions>
                {buttons}
                {closeBtnLabel && (
                    <Button
                        onClick={onClose}
                        variant={"contained"}
                        color="secondary"
                        disabled={disableClosingAfterStartLoading && (state.isLoading || state.isSubmitted)}
                    >
                        {closeBtnLabel}
                    </Button>
                )}
                {!omitSubmit &&
                    (submitBtn || (
                        <AnimatedSubmitButton
                            label={submitBtnLabel}
                            disabled={disableSubmit}
                            isSubmitted={state.isSubmitted}
                            submit={handleSubmitButton}
                            inProgress={state.isLoading}
                            id={submitBtnId}
                        />
                    ))}
            </DialogActions>
        </MuiDialog>
    );
};

export default observer(Dialog);
