import React, { useRef, useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import {
    FormControl,
    FormHelperText,
    InputBase,
    MenuItem,
    Select as MuiSelect,
    ButtonBase,
    InputAdornment
} from "@material-ui/core";
import { useStyles } from "components/Select/Select.styles";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronDownIcon from "assets/ChevronDownIcon";
import clsx from "clsx";
import Checkbox from "components/Checkbox";
import CheckSmallIcon from "assets/CheckSmallIcon";
import InputErrorIcon from "assets/InputErrorIcon";

const Select = ({
    className,
    options = [],
    error,
    disabled,
    multiple,
    value,
    itemType,
    additionalStartOptions = [],
    actionOption,
    helperText,
    id,
    displayInUpperCase,
    ...rest
}) => {
    const targetRef = useRef();
    const state = useLocalStore(() => ({
        width: 0,
        isNeedToShowErrorText: false
    }));
    const classes = useStyles({ width: state.width, displayInUpperCase: displayInUpperCase });

    useEffect(() => {
        state.width = targetRef.current ? targetRef.current.offsetWidth : 0;
    }, [targetRef.current]);

    const toggleErrorText = () => {
        state.isNeedToShowErrorText = !state.isNeedToShowErrorText;
    };

    const renderItemByType = optionName => {
        const selected = multiple ? value.indexOf(optionName) > -1 : value === optionName;
        switch (itemType) {
            case "checkbox":
                return (
                    <>
                        <Checkbox checked={selected} />
                        <ListItemText primary={optionName} />
                    </>
                );
            default:
                return (
                    <>
                        <ListItemText primary={optionName} />
                        <CheckSmallIcon className={classes.checkIcon} />
                    </>
                );
        }
    };
    return useObserver(() => (
        <FormControl disabled={disabled} fullWidth ref={targetRef} className={clsx(error && classes.formControlError, className)}>
            <MuiSelect
                input={
                    <InputBase
                        endAdornment={
                            <>
                                {error && (
                                    <InputAdornment position={"end"} className={classes.errorAdornment}>
                                        <ButtonBase onClick={toggleErrorText}>
                                            <InputErrorIcon />
                                        </ButtonBase>
                                    </InputAdornment>
                                )}
                            </>
                        }
                        className={clsx(error && classes.selectInputError, classes.selectInput)}
                        id={id}
                    />
                }
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        horizontal: "left",
                        vertical: 44
                    },
                    PopoverClasses: { paper: classes.popover }
                }}
                IconComponent={ChevronDownIcon}
                value={value}
                multiple={multiple}
                {...rest}
            >
                {additionalStartOptions.map(item =>
                    typeof item === "object" ? (
                        <MenuItem className={classes.menuItem} key={`${item.value} ${Math.random()}`} value={item.value}>
                            {renderItemByType(item.label)}
                        </MenuItem>
                    ) : (
                        <MenuItem className={classes.menuItem} key={`${item} ${Math.random()}`} value={item}>
                            {renderItemByType(item)}
                        </MenuItem>
                    )
                )}
                {actionOption}
                {options.map(option =>
                    typeof option === "object" ? (
                        <MenuItem className={classes.menuItem} key={`${option.value} ${Math.random()}`} value={option.value}>
                            {renderItemByType(option.label)}
                        </MenuItem>
                    ) : (
                        <MenuItem className={classes.menuItem} key={`${option} ${Math.random()}`} value={option}>
                            {renderItemByType(option)}
                        </MenuItem>
                    )
                )}
            </MuiSelect>
            <FormHelperText className={clsx(!state.isNeedToShowErrorText && classes.helperTextHidden, classes.helperText)}>
                {helperText}
            </FormHelperText>
        </FormControl>
    ));
};

export default Select;
