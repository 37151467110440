import { URL_SHARES, URL_RECYCLE_BIN, URL_FILE_NAVIGATOR, URL_SLINGSHOT, URL_SUPPORT } from "routes";

export const ADMIN = "administrator";
export const SECONDARY_ADMIN = "secondary_admin";
export const USER = "user";

export const ROLES = [ADMIN, SECONDARY_ADMIN, USER];

export const AVAILABLE_SEC_ADMIN_URLS = [URL_SHARES, URL_FILE_NAVIGATOR, URL_SLINGSHOT, URL_RECYCLE_BIN, URL_SUPPORT];
export const AVAILABLE_USER_URLS = [URL_FILE_NAVIGATOR, URL_SLINGSHOT, URL_RECYCLE_BIN, URL_SUPPORT];
