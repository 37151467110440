import React, { useEffect, useContext, useCallback } from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { useObserver, useLocalStore } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./MissingQuadsDrawer.styles";
import EthernetIcon from "assets/EthernetIcon";
import AcceptIcon from "assets/AcceptIcon";
import { HARDWARE_ACTIVATION_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const MISSING_QUADS_DRAWER_ID_PREFIX = `${HARDWARE_ACTIVATION_PAGE_ID_PREFIX}_missing_quads_drawer`;

const drawerName = "missingQuadsDrawer";
const MissingQuadsDrawer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { uiStore, quadActivationStore }
    } = useContext(Context);
    const state = useLocalStore(() => ({
        isDeactivateInProgress: false
    }));

    useEffect(() => {
        if (quadActivationStore.currentMissingQuadId !== null) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, closeDrawer);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [quadActivationStore.currentMissingQuadId]);

    const closeDrawer = useCallback(() => {
        quadActivationStore.setCurrentMissingQuadId(null);
    });

    const deactivateMissingQuad = useCallback(async () => {
        state.isDeactivateInProgress = true;
        const res = await quadActivationStore.deactivateMissingQuad({ quadId: quadActivationStore.currentMissingQuad.quadId });
        state.isDeactivateInProgress = false;
        res && closeDrawer();
    });

    const renderDetails = () => {
        const currentMissingQuad = quadActivationStore.currentMissingQuad;
        return currentMissingQuad ? (
            <Grid container direction={"column"} className={classes.detailsContainer} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("missing_quads.table.old_position")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {t("missing_quads.table.quad", { number: currentMissingQuad.quadNumber })}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("missing_quads.table.drive_serials")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentMissingQuad.serialsField}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        ) : null;
    };
    const renderActions = () => {
        const currentMissingQuad = quadActivationStore.currentMissingQuad;
        return currentMissingQuad ? (
            <Grid direction={"column"} container>
                <BulkBarFlatButton
                    onClick={deactivateMissingQuad}
                    icon={<AcceptIcon />}
                    inProgress={state.isDeactivateInProgress}
                    id={`${MISSING_QUADS_DRAWER_ID_PREFIX}_deactivate_missing_quad`}
                >
                    {t("missing_quads.deactivate")}
                </BulkBarFlatButton>
            </Grid>
        ) : null;
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            title={t("missing_quads.table.quad", { number: quadActivationStore.currentMissingQuad?.quadNumber })}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            icon={<EthernetIcon />}
            id={MISSING_QUADS_DRAWER_ID_PREFIX}
        />
    ));
};

export default MissingQuadsDrawer;
