import isFieldEmpty from "utils/isFieldEmptyRule";
import minMaxSizeValidator from "utils/minMaxSizeValidator";
import isFieldIntegerPositive from "utils/isFieldIntegerPositive";
import i18n from "i18n";

const createFields = () => {
    return [
        {
            name: "expirationDate",
            default: "1",
            type: "number",
            validators: [
                isFieldEmpty(i18n.t("common.label.expire")),
                minMaxSizeValidator({ min: 1, max: 365 }),
                isFieldIntegerPositive(i18n.t("common.error.validator.message.integer")),
            ],
        },
    ];
};

export default createFields;
