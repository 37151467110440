import React from "react";

const QuadActivatedIcon = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            {...props}
        >
            <defs>
                <linearGradient id="4070ee5a-b456-11ea-b3de-0242ac130004" x1="-22.298%" x2="85.346%" y1="-15.973%" y2="100%">
                    <stop offset="0%" stopColor="#1E90FF"></stop>
                    <stop offset="63.407%" stopColor="#1E77FF"></stop>
                    <stop offset="100%" stopColor="#651EFF" stopOpacity="0.91"></stop>
                </linearGradient>
                <linearGradient id="4070f45e-b456-11ea-b3de-0242ac130004" x1="5.401%" x2="89.046%" y1="0%" y2="96.045%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.25"></stop>
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.03"></stop>
                </linearGradient>
                <linearGradient id="4070f288-b456-11ea-b3de-0242ac130004" x1="8.951%" x2="92.017%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.61"></stop>
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.2"></stop>
                </linearGradient>
                <linearGradient id="4070f666-b456-11ea-b3de-0242ac130004" x1="0%" x2="93.775%" y1="46.444%" y2="53.274%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.25"></stop>
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.03"></stop>
                </linearGradient>
                <linearGradient id="4070f530-b456-11ea-b3de-0242ac130004" x1="35.363%" x2="50%" y1="43.623%" y2="57.097%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.4"></stop>
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.02"></stop>
                </linearGradient>
                <linearGradient id="4070f864-b456-11ea-b3de-0242ac130004" x1="37.5%" x2="60.944%" y1="0%" y2="96.045%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.25"></stop>
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.03"></stop>
                </linearGradient>
                <linearGradient id="4070f738-b456-11ea-b3de-0242ac130004" x1="46.341%" x2="50%" y1="-39.672%" y2="149.808%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.4"></stop>
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.02"></stop>
                </linearGradient>
                <rect id="4070f116-b456-11ea-b3de-0242ac130004" width="34" height="36" x="0" y="0" rx="2"></rect>
            </defs>
            <g fill="none" fillRule="evenodd" transform="translate(1)">
                <use fill="url(#4070ee5a-b456-11ea-b3de-0242ac130004)" xlinkHref="#4070f116-b456-11ea-b3de-0242ac130004"></use>
                <rect
                    width="33"
                    height="35"
                    x="0.5"
                    y="0.5"
                    fill="url(#4070f45e-b456-11ea-b3de-0242ac130004)"
                    stroke="url(#4070f288-b456-11ea-b3de-0242ac130004)"
                    strokeLinejoin="square"
                    rx="2"
                ></rect>
                <g transform="translate(2 2)">
                    <rect
                        width="29"
                        height="7"
                        x="0.5"
                        y="0.5"
                        fill="url(#4070f666-b456-11ea-b3de-0242ac130004)"
                        stroke="url(#4070f530-b456-11ea-b3de-0242ac130004)"
                        rx="1"
                    ></rect>
                    <rect
                        width="3"
                        height="7"
                        x="26.5"
                        y="0.5"
                        fill="url(#4070f864-b456-11ea-b3de-0242ac130004)"
                        stroke="url(#4070f738-b456-11ea-b3de-0242ac130004)"
                        rx="1"
                    ></rect>
                    <rect width="4" height="4" x="2" y="2" fill="#FFF" opacity="0.67" rx="2"></rect>
                    <rect width="1" height="1" x="8" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="12" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="16" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="20" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="10" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="14" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="18" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="22" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="9" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="13" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="17" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="21" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="11" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="15" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="19" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="23" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="9" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="13" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="17" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="21" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="11" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="15" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="19" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="23" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="8" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="12" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="16" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="20" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="10" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="14" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="18" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="22" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                </g>
                <g transform="translate(2 10)">
                    <rect
                        width="29"
                        height="7"
                        x="0.5"
                        y="0.5"
                        fill="url(#4070f666-b456-11ea-b3de-0242ac130004)"
                        stroke="url(#4070f530-b456-11ea-b3de-0242ac130004)"
                        rx="1"
                    ></rect>
                    <rect
                        width="3"
                        height="7"
                        x="26.5"
                        y="0.5"
                        fill="url(#4070f864-b456-11ea-b3de-0242ac130004)"
                        stroke="url(#4070f738-b456-11ea-b3de-0242ac130004)"
                        rx="1"
                    ></rect>
                    <rect width="4" height="4" x="2" y="2" fill="#FFF" opacity="0.67" rx="2"></rect>
                    <rect width="1" height="1" x="8" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="12" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="16" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="20" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="10" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="14" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="18" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="22" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="9" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="13" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="17" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="21" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="11" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="15" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="19" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="23" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="9" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="13" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="17" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="21" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="11" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="15" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="19" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="23" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="8" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="12" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="16" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="20" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="10" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="14" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="18" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="22" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                </g>
                <g transform="translate(2 18)">
                    <rect
                        width="29"
                        height="7"
                        x="0.5"
                        y="0.5"
                        fill="url(#4070f666-b456-11ea-b3de-0242ac130004)"
                        stroke="url(#4070f530-b456-11ea-b3de-0242ac130004)"
                        rx="1"
                    ></rect>
                    <rect
                        width="3"
                        height="7"
                        x="26.5"
                        y="0.5"
                        fill="url(#4070f864-b456-11ea-b3de-0242ac130004)"
                        stroke="url(#4070f738-b456-11ea-b3de-0242ac130004)"
                        rx="1"
                    ></rect>
                    <rect width="4" height="4" x="2" y="2" fill="#FFF" opacity="0.67" rx="2"></rect>
                    <rect width="1" height="1" x="8" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="12" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="16" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="20" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="10" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="14" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="18" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="22" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="9" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="13" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="17" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="21" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="11" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="15" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="19" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="23" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="9" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="13" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="17" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="21" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="11" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="15" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="19" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="23" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="8" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="12" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="16" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="20" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="10" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="14" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="18" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="22" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                </g>
                <g transform="translate(2 26)">
                    <rect
                        width="29"
                        height="7"
                        x="0.5"
                        y="0.5"
                        fill="url(#4070f666-b456-11ea-b3de-0242ac130004)"
                        stroke="url(#4070f530-b456-11ea-b3de-0242ac130004)"
                        rx="1"
                    ></rect>
                    <rect
                        width="3"
                        height="7"
                        x="26.5"
                        y="0.5"
                        fill="url(#4070f864-b456-11ea-b3de-0242ac130004)"
                        stroke="url(#4070f738-b456-11ea-b3de-0242ac130004)"
                        rx="1"
                    ></rect>
                    <rect width="4" height="4" x="2" y="2" fill="#FFF" opacity="0.67" rx="2"></rect>
                    <rect width="1" height="1" x="8" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="12" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="16" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="20" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="10" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="14" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="18" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="22" y="2" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="9" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="13" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="17" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="21" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="11" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="15" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="19" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="23" y="3" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="9" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="13" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="17" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="21" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="11" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="15" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="19" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="23" y="5" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="8" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="12" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="16" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="20" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="10" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="14" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="18" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                    <rect width="1" height="1" x="22" y="4" fill="#FFF" opacity="0.4" rx="0.5"></rect>
                </g>
            </g>
        </svg>
    );
};

export default QuadActivatedIcon;
