import { destroy, types } from "mobx-state-tree";
import { MultipleEvoStoreType, createMultipleEvoStore } from "./createMultipleEvoStore";

const MultipleEvosRootStore = types
    .model({
        stores: types.map(MultipleEvoStoreType),
    })
    .views((self) => ({
        get storesLength() {
            return self.stores.size;
        },
        get storesIps() {
            const keys = [];
            self.stores.forEach((store) => keys.push(store.ip));
            return keys;
        },
    }))
    .actions((self) => ({
        addStore: ({ ip, socket, name, role, axios }) => {
            const store = createMultipleEvoStore({ ip, socket, name, role, axios });
            self.stores.set(ip, store);
        },
        removeStore: (ip) => {
            const store = self.stores.get(ip);

            if (store) {
                store.endBootProgressPolling();
                store.socket.ws.close();
                destroy(store);
            }
        },
        getStore(ip) {
            return self.stores.get(ip);
        },
        removeAllStores: () => {
            [...self.stores].forEach(([ip]) => {
                self.removeStore(ip);
            });
        },
    }))
    .create({
        stores: {},
    });

export default MultipleEvosRootStore;
