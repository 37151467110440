import React, { useContext } from "react";
import TableRowControlButton from "components/TableRowControlButton";
import ChevronRightIcon from "assets/ChevronRightIcon";
import { TableRow, TableCell } from "@material-ui/core";
import { GiB_IEC } from "const/diskSizeConst";
import VolumeStatusItem from "../VolumeStatusItem";
import Checkbox from "components/Checkbox";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./LogicalDisks.style";
import { Context } from "store";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const LOGICAL_DISKS_ROW_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_logical_disks_row`;

const LogicalDisksRow = ({ volume, checked, onCheck, setupPortName, dropPortName, currentPortName, id, isStartingQueue }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { volumeDrawerStore },
    } = useContext(Context);

    const toggleDrawer = () => {
        if (
            currentPortName &&
            volume.volumeId.pool === currentPortName.pool &&
            volume.volumeId.volume === currentPortName.volume
        ) {
            dropPortName();
        } else {
            setupPortName();
        }
    };

    const isRowSelected =
        volumeDrawerStore?.currentPort?.volume.volumeId.volume === volume.volumeId.volume &&
        volumeDrawerStore?.currentPort?.volume.volumeId.pool === volume.volumeId.pool;

    const classLableContainer = isStartingQueue ? classes.statusItemContainer : undefined;

    return (
        <TableRow selected={isRowSelected} id={id} onClick={toggleDrawer}>
            <TableCell className={classes.checkboxCell} onClick={onCheck}>
                <Checkbox checked={checked} id={`${LOGICAL_DISKS_ROW_ID_PREFIX}_${volume.volumeId.volume}_checkbox`} />
            </TableCell>
            <TableCell>{volume.volumeName}</TableCell>
            <TableCell>{(volume.size / GiB_IEC.value).toFixed(1)}</TableCell>
            <TableCell>{t("disks_and_pools.logical_disks.row.cell.raid", { count: volume.raid })}</TableCell>
            <TableCell>
                <VolumeStatusItem variant={"health"} data={volume.status} classLableContainer={classLableContainer} />
            </TableCell>
            <TableCell>
                <VolumeStatusItem variant={"status"} data={volume.status} classLableContainer={classLableContainer} />
            </TableCell>
            <TableCell align={"right"}>
                <TableRowControlButton
                    icon={<ChevronRightIcon id={`${LOGICAL_DISKS_ROW_ID_PREFIX}_${volume.volumeId.volume}_open`} />}
                />
            </TableCell>
        </TableRow>
    );
};

export default observer(LogicalDisksRow);
