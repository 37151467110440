import React from "react";

export default function BackIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="evenodd"
                d="M19 11H7.14l3.63-4.36c.353-.425.295-1.057-.13-1.41s-1.057-.295-1.41.13l-5 6c-.034.048-.064.098-.09.15 0 .05 0 .08-.07.13-.045.115-.07.237-.07.36s.025.245.07.36c0 .05 0 .08.07.13.026.052.056.102.09.15l5 6c.19.229.473.36.77.36.234 0 .46-.08.64-.23.425-.352.484-.983.132-1.408l-.002-.002L7.14 13H19c.552 0 1-.448 1-1s-.448-1-1-1z"
            />
        </svg>
    );
}
