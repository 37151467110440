import { types } from "mobx-state-tree";

import ConfigJsonType from "api/slingshot/indexing_config/Types/ConfigJsonType";

const GetConfigResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(ConfigJsonType, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            }
        })
    )
});

export default GetConfigResult;
