import React, { useContext, useEffect, useCallback } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import SideBulkBar from "components/SideBulkBar";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import TrashIcon from "assets/TrashIcon";
import DeleteDiskDialog from "../DeleteDiskDialog";
import WorldOffIcon from "assets/WorldOffIcon";
import WorldIcon from "assets/WorldIcon";
import { REPLACE_DISK_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const drawerName = "AffectedDiskAndPoolsBulkOperationsBar";

const BULK_OPERATIONS_BAR_ID_PREFIX = `${REPLACE_DISK_PAGE_ID_PREFIX}_bulk_operations_bar`;

const BulkOperationsBar = () => {
    const { t } = useTranslation();
    const {
        store: { volumeStore, uiStore, volumeDrawerStore }
    } = useContext(Context);
    const state = useLocalStore(() => ({
        openDeleteDialog: false,
        isDeleteInProgress: false,
        get nonUniqueVolumes() {
            return volumeStore.volumes && volumeStore.queueVolumes
                ? volumeStore.volumes.filter(volume =>
                      volumeStore.queueVolumes.some(
                          queueVolume =>
                              queueVolume.volumeId.pool === volume.volumeId.pool &&
                              queueVolume.volumeId.volume === volume.volumeId.volume
                      )
                  )
                : [];
        },
        get checkedVolumesCount() {
            return volumeStore.checkedVolumes.length + volumeStore.checkedQueueVolumes.length - this.nonUniqueVolumes.length;
        }
    }));
    const onClose = () => {
        volumeStore.clearCheckedVolumes();
    };
    useEffect(() => {
        if (volumeStore.checkedVolumes.length > 0) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, onClose);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
            uiStore.changeDrawer();
        }
    }, [volumeStore.checkedVolumes.length]);

    const removeVolumes = async () => {
        state.openDeleteDialog = false;
        state.isDeleteInProgress = true;
        const res =
            (await volumeStore.checkedVolumes.length) > 0 &&
            volumeStore.removeVolumes({
                volumeIds: volumeStore.checkedVolumes.map(volume => {
                    return { pool: volume.pool, volume: volume.volume };
                })
            });
        state.isDeleteInProgress = false;
        res && volumeStore.clearCheckedVolumes();
    };
    const openDeleteDiskDialog = useCallback(() => {
        state.openDeleteDialog = true;
    });
    const closeDeleteDiskDialog = useCallback(() => {
        state.openDeleteDialog = false;
    });

    const changeOnlineStatus = () => {
        volumeDrawerStore.setVolumesOnline();
        volumeDrawerStore.clearCheckedVolumes();
        volumeDrawerStore.clearCheckedQueueVolumes();
    };

    return useObserver(() => (
        <SideBulkBar itemsCount={state.checkedVolumesCount} onClose={onClose} isOpen={uiStore.openedDrawer === drawerName}>
            <DeleteDiskDialog
                onClose={closeDeleteDiskDialog}
                diskName={volumeStore.checkedVolumes.map(volume => `${volume.pool}/${volume.volume}`)}
                open={state.openDeleteDialog}
                onDeleteAction={removeVolumes}
                inProgress={state.isDeleteInProgress}
            />
            <BulkBarFlatButton
                id={`${BULK_OPERATIONS_BAR_ID_PREFIX}_change_online_status`}
                onClick={changeOnlineStatus}
                icon={volumeDrawerStore.someNotOffline ? <WorldOffIcon /> : <WorldIcon />}
            >
                {volumeDrawerStore.someNotOffline
                    ? t("disks_and_pools.logical_disks.bulk_operations_bar.button.set_offline")
                    : t("disks_and_pools.logical_disks.bulk_operations_bar.button.set_online")}
            </BulkBarFlatButton>
            <BulkBarFlatButton onClick={openDeleteDiskDialog} icon={<TrashIcon />} errorColor>
                {t("common.button.delete")}
            </BulkBarFlatButton>
        </SideBulkBar>
    ));
};

export default BulkOperationsBar;
