import { types } from "mobx-state-tree";

import Request from "api/Request";
import AddBrickArguments from "api/cluster/Types/AddBrickArguments";

const RemoveBrick = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Cluster",
    method: "remove_brick",
    arguments: types.maybe(AddBrickArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default RemoveBrick
