import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    controlLabel: {
        padding: theme.spacing(1.5, 0),
        marginLeft: 0,
        "& label > span": {
            padding: 0,
        },
        "& .MuiGrid-container": {
            width: "initial",
        },
        "& $label": {
            marginLeft: 0,
        },
    },
    radioContainer: {
        padding: theme.spacing(3, 0, 2),
    },
    shareRow: {
        paddingTop: theme.spacing(0.5),
    },
    shareSelectContainer: {
        flex: 1,
        paddingRight: theme.spacing(2),
    },
    shareInputContainer: {
        flex: 1,
        height: 40,
    },
    inputRow: {
        padding: theme.spacing(1, 0),
    },
}));
