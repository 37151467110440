import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Dialog from "components/Dialog";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const CLEAR_LOCK_CONFIRM_DIALOG_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_clear_lock_confirm_dialog`;

const ClearLockConfirmDialog = ({ open, onClose, countShare, shareNames, submit }) => {
    const { t } = useTranslation();

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={t("share.clear_locks_confirmation_dialog.title")}
            withoutIconClose
            withoutDividers
            buttons={
                <Button
                    onClick={onClose}
                    variant={"contained"}
                    color={"secondary"}
                    id={`${CLEAR_LOCK_CONFIRM_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
            submitBtn={
                <Button onClick={submit} variant={"contained"} color={"primary"} id={`${CLEAR_LOCK_CONFIRM_DIALOG_ID_PREFIX}_ok`}>
                    {t("share.clear_locks_confirmation_dialog.ok_button")}
                </Button>
            }
        >
            <Grid container direction={"column"} spacing={5}>
                <Grid item>
                    {!countShare || countShare === 1 ? (
                        <Grid container spacing={3}>
                            <Grid item container>
                                <Typography>
                                    {t("share.shares.clear_locks_confirmation_dialog.content", {
                                        shareName: shareNames || ""
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item container>
                                <Typography> {t("share.shares.clear_locks_confirmation_dialog.warning")}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={3}>
                            <Grid item container>
                                <Typography>
                                    {t("share.shares.checked_shares.clear_locks_confirmation_dialog.content")}
                                </Typography>
                            </Grid>
                            <Grid item container>
                                <Typography>{shareNames}.</Typography>
                            </Grid>
                            <Grid item container>
                                <Typography>
                                    {t("share.shares.checked_shares.clear_locks_confirmation_dialog.recommend")}
                                </Typography>
                            </Grid>
                            <Grid item container>
                                <Typography> {t("share.shares.clear_locks_confirmation_dialog.warning")}</Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    <Typography>{t("share.warning_dialog.question")}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default ClearLockConfirmDialog;
