import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography, Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";

const DeinitializeWarningDialog = ({ open, onClose, submit }) => {
    const { t } = useTranslation();

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={t("master_reset.warning_dialog.title")}
            withoutIconClose
            withoutDividers
            buttons={
                <Button onClick={onClose} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>
            }
            onSubmit={submit}
            submitBtnLabel={t("master_reset.warning_dialog.ok_button")}
        >
            <Grid container direction={"column"} spacing={5}>
                <Grid item>
                    <Typography>{t("master_reset.deinitialization_warning_dialog.question")}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default DeinitializeWarningDialog;
