import { EXTERNAL_URL_CREATE_CERTIFICATE } from "const/externalURLs";

export default {
    promptToDownloadCertificate: {
        titleKey: "admin_settings.prompt_to_download_certificate.title",
        paragraphs: [
            {
                textKey: "admin_settings.prompt_to_download_certificate.paragraph_1",
                gutterBottom: true,
            },
            {
                textKey: "admin_settings.prompt_to_download_certificate.paragraph_2",
                gutterBottom: true,
            },
            {
                textKey: "admin_settings.prompt_to_download_certificate.paragraph_3",
                components: [
                    {
                        type: "link",
                        path: EXTERNAL_URL_CREATE_CERTIFICATE,
                        inNewTab: true,
                    },
                ],
                gutterBottom: true,
            },
        ],
    },
};
