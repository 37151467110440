import React from "react";
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import DataCard from "components/DataCard";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import ConfirmationDialog from "components/ConfirmationDialog";
import TableSortLabel from "components/TableSortLabel";
import TableRowControlButton from "components/TableRowControlButton";
import ChevronRightIcon from "assets/ChevronRightIcon";
import { LONG_LIVED_TOKENS_FORMAT } from "const";
import { LONG_LIVED_TOKENS_CARD } from "const/widgetNameConst";
import { LONG_LIVED_TOKENS_NAME, LONG_LIVED_TOKENS_UUID, LONG_LIVED_TOKENS_EXPIRATION_DATE } from "const/sortColumnConst";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";
import { useToggle } from "hooks/useToggle";
import LongLivedTokensDrawer from "../LongLivedTokensDrawer/LongLivedTokensDrawer";
import SetTimeoutTokensModal from "./components/SetTimeoutTokensModal";
import createFields from "./createFields";
import createForm from "utils/createForm";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import moment from "moment";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const LONG_LIVED_TOKENS_CARD_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_long_lived_tokens_card`;

const LongLivedTokensCard = () => {
    const { t } = useTranslation();

    const {
        store: { longLivedTokensStore },
    } = useStore();
    const changeSorting = (column) => () => longLivedTokensStore.changeSorting(column);

    const [inProgress, toggleInProgress] = useToggle();
    const [getTimeoutInProgress, toggleGetTimeoutInProgress] = useToggle();

    const { revokeAllTokensModal, setTimeoutTokensModal } = useModalCombine(["revokeAllTokensModal", "setTimeoutTokensModal"]);

    const fields = createFields();
    const state = useLocalStore(() => ({
        form: createForm({
            fields,
        }),
    }));

    useAsyncEffect(async () => {
        toggleInProgress(true);
        await longLivedTokensStore.fetchTokens();
        toggleInProgress(false);
    }, []);

    const revokeAllToken = async () => {
        await longLivedTokensStore.revokeAllTokens();
        revokeAllTokensModal.close();
    };

    const openSetTimeoutTokensModal = async () => {
        state.form.$("expirationDate").set("default", longLivedTokensStore.currentTimeout.toString());
        state.form.$("expirationDate").set("value", longLivedTokensStore.currentTimeout.toString());
        toggleGetTimeoutInProgress(true);
        state.form.$("expirationDate").resetValidation();
        setTimeoutTokensModal.open();
        await longLivedTokensStore.getExpiration();
        state.form.$("expirationDate").set("default", longLivedTokensStore.currentTimeout.toString());
        state.form.$("expirationDate").set("value", longLivedTokensStore.currentTimeout.toString());
        toggleGetTimeoutInProgress(false);
    };

    const closeSetTimeoutTokensModal = () => {
        longLivedTokensStore.setCurrentTimeout(1);
        setTimeoutTokensModal.close();
    };

    const renderContextMenu = () => (
        <MoreMenu>
            <HomeCardActionMenuItem widget={LONG_LIVED_TOKENS_CARD} />
            <MenuItem onClick={revokeAllTokensModal.open} id={`${LONG_LIVED_TOKENS_CARD_ID_PREFIX}_revoke_all_tokens`}>
                {t("admin_settings.long_lived_tokens_card.context_menu.revoke_all_tokens")}
            </MenuItem>
            <MenuItem onClick={openSetTimeoutTokensModal} id={`${LONG_LIVED_TOKENS_CARD_ID_PREFIX}_set_timeout`}>
                {t("admin_settings.long_lived_tokens_card.context_menu.set_timeout")}
            </MenuItem>
        </MoreMenu>
    );

    return useObserver(() => (
        <>
            <DataCard
                title={t("admin_settings.long_lived_tokens_card.title")}
                emptyPadding
                headerDivider
                headerControl={renderContextMenu()}
            >
                <LongLivedTokensDrawer />
                {longLivedTokensStore.sortedTokens.length ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={changeSorting(LONG_LIVED_TOKENS_NAME)}
                                        direction={longLivedTokensStore.order}
                                        active={longLivedTokensStore.orderBy === LONG_LIVED_TOKENS_NAME}
                                    >
                                        {t("admin_settings.long_lived_tokens_card.table.header.name")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={changeSorting(LONG_LIVED_TOKENS_UUID)}
                                        direction={longLivedTokensStore.order}
                                        active={longLivedTokensStore.orderBy === LONG_LIVED_TOKENS_UUID}
                                    >
                                        {t("admin_settings.long_lived_tokens_card.table.header.uuid")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={changeSorting(LONG_LIVED_TOKENS_EXPIRATION_DATE)}
                                        direction={longLivedTokensStore.order}
                                        active={longLivedTokensStore.orderBy === LONG_LIVED_TOKENS_EXPIRATION_DATE}
                                    >
                                        {t("admin_settings.long_lived_tokens_card.table.header.expiration_date")}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {longLivedTokensStore.sortedTokens.map((row) => {
                                const openDrawer = () => {
                                    longLivedTokensStore.setCurrentToken(row.token);
                                };
                                return (
                                    <TableRow
                                        selected={longLivedTokensStore.currentToken === row.token}
                                        key={row.token}
                                        onClick={openDrawer}
                                    >
                                        <TableCell>{row.userName}</TableCell>
                                        <TableCell>{row.token}</TableCell>
                                        <TableCell>
                                            {moment(row.expirationDate).format(getLanguageDateFormat(LONG_LIVED_TOKENS_FORMAT))}
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <TableRowControlButton icon={<ChevronRightIcon />} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <EmptyCardPlaceholder
                        inProgress={inProgress}
                        message={t("admin_settings.long_lived_tokens_card.placeholder")}
                    />
                )}
            </DataCard>
            <SetTimeoutTokensModal
                open={setTimeoutTokensModal.isOpen}
                onClose={closeSetTimeoutTokensModal}
                inProgress={getTimeoutInProgress}
                form={state.form}
            />
            <ConfirmationDialog
                title={t("common.warning_dialog.title")}
                open={revokeAllTokensModal.isOpen}
                onClose={revokeAllTokensModal.close}
                onConfirm={revokeAllToken}
                confirmLabel={t("admin_settings.long_lived_tokens_card.revoke_all_tokens_dialog.confirm")}
                cancelLabel={t("admin_settings.long_lived_tokens_card.revoke_all_tokens_dialog.cancel")}
                fullWidth={true}
            >
                {t("admin_settings.long_lived_tokens_card.revoke_all_tokens_dialog.question")}
            </ConfirmationDialog>
        </>
    ));
};

export default LongLivedTokensCard;
