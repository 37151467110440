import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    label: {
        opacity: 0.8,
        lineHeight: 1.5
    },
    iconContainer: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(4)
    },
    caption: {
        opacity: 0.5,
        width: 300,
        textAlign: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4)
    },
    noDiskContainer: {
        flexDirection: "column",
        alignItems: "center",
        "& div:last-child": {
            marginBottom: theme.spacing(10)
        }
    }
}));
