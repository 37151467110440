import React from "react";

const EvoGui2 = ({ ...props }) => {
    return (
        <svg width="448" height="237" viewBox="0 0 448 237">
            <defs>
                <filter id="EvoGuiBG-Body" x="18" y="17" width="413" height="304" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="EvoGuiBG-Left" x="18" y="17" width="94" height="304" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur-2" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="EvoGuiBG-Top" x="19.5" y="18.5" width="410" height="97" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="2.5" result="blur-3" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur-3" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <clipPath id="EvoGuiClip-small_1">
                    <rect width="448" height="237" />
                </clipPath>
            </defs>
            <g id="small_1" data-name="small – 1" clipPath="url(#EvoGuiClip-small_1)">
                <rect width="448" height="237" className={props.underBackgroundClassName} />
                <g id="Background">
                    <rect id="BG-Blue" width="448" height="237" transform="translate(0 0)" className={props.backgroundClassName} />
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#EvoGuiBG-Body)">
                        <rect
                            id="BG-Body-2"
                            data-name="BG-Body"
                            width="395"
                            height="286"
                            rx="7"
                            transform="translate(27 23)"
                            fill="#edf2f6"
                        />
                    </g>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#EvoGuiBG-Left)">
                        <path
                            id="BG-Left-2"
                            data-name="BG-Left"
                            d="M5,0H71a5,5,0,0,1,5,5V286a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5A5,5,0,0,1,5,0Z"
                            transform="translate(27 23)"
                            fill="#fff"
                        />
                    </g>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#EvoGuiBG-Top)">
                        <path
                            id="BG-Top-2"
                            data-name="BG-Top"
                            d="M5,0H390a5,5,0,0,1,5,5V82a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5A5,5,0,0,1,5,0Z"
                            transform="translate(27 23)"
                            fill="#222"
                        />
                    </g>
                </g>
                <g id="Top-Details" transform="translate(-21.693 -59.917)">
                    <g id="logo_evo" data-name="logo/evo" transform="translate(58.693 90.917)">
                        <path
                            id="Shape"
                            d="M16.366,0,15.5,1.621H11.98l1.636,3.389h.951L16.94,0h2.009L15.882,6.667H12.317L9.258,0Zm-7.6,2.5V4.137H1.607V5.03h7.16V6.667H0V2.5ZM28,0V6.667H19.232V0ZM26.392,1.728H20.84V4.994h5.553Zm-.787,2.049a.414.414,0,0,1,.412.374l0,.043a.417.417,0,0,1-.415.417H23.889a.414.414,0,0,1-.413-.374l0-.043a.417.417,0,0,1,.415-.417ZM8.768,0V1.667H0V0Z"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                    </g>
                    <line
                        id="Top-Line"
                        x2="391"
                        transform="translate(52.693 103.417)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1"
                        opacity="0.59"
                    />
                    <circle id="profile" cx="5" cy="5" r="5" transform="translate(416.693 88.917)" fill="#fff" opacity="0.67" />
                    <g id="Left-Graph">
                        <g id="left-green">
                            <line
                                id="Line_4"
                                data-name="Line 4"
                                x1="19"
                                y2="5"
                                transform="translate(69.193 127.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_5"
                                data-name="Line 5"
                                x1="9"
                                y1="7"
                                transform="translate(88.193 127.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_7"
                                data-name="Line 7"
                                x2="10"
                                y2="4"
                                transform="translate(97.193 134.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_8"
                                data-name="Line 8"
                                y1="3"
                                x2="8"
                                transform="translate(106.193 135.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_9"
                                data-name="Line 9"
                                y1="1"
                                x2="6"
                                transform="translate(114.193 134.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_10"
                                data-name="Line 10"
                                x1="15"
                                y1="2"
                                transform="translate(120.193 134.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_12"
                                data-name="Line 12"
                                x1="11"
                                y1="14"
                                transform="translate(135.193 136.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                        </g>
                        <g id="left-blue" transform="translate(0.5 -10)">
                            <line
                                id="Line_2"
                                data-name="Line 2"
                                y1="8"
                                x2="11.5"
                                transform="translate(69.193 141.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_3"
                                data-name="Line 3"
                                x1="6"
                                transform="translate(80.693 141.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_4-2"
                                data-name="Line 4"
                                x1="11.5"
                                y2="2"
                                transform="translate(86.193 139.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_6"
                                data-name="Line 6"
                                x1="5.5"
                                y2="4"
                                transform="translate(97.193 135.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_7-2"
                                data-name="Line 7"
                                x2="10.5"
                                y2="4"
                                transform="translate(102.193 135.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_9-2"
                                data-name="Line 9"
                                x2="12"
                                transform="translate(112.193 139.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_10-2"
                                data-name="Line 10"
                                x1="4"
                                y1="3"
                                transform="translate(124.193 139.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_11"
                                data-name="Line 11"
                                x1="5"
                                y2="2"
                                transform="translate(128.193 140.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_12-2"
                                data-name="Line 12"
                                x1="14"
                                y1="10"
                                transform="translate(132.193 140.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                        </g>
                        <g
                            id="Rectangle_47"
                            data-name="Rectangle 47"
                            transform="translate(68.693 109.917)"
                            fill="none"
                            stroke="#707070"
                            strokeWidth="1"
                        >
                            <rect width="78" height="41" stroke="none" />
                            <rect x="0.5" y="0.5" width="77" height="40" fill="none" />
                        </g>
                    </g>
                    <g id="Middle-Graph">
                        <g
                            id="Rectangle_49"
                            data-name="Rectangle 49"
                            transform="translate(206.693 109.917)"
                            fill="none"
                            stroke="#707070"
                            strokeWidth="1"
                        >
                            <rect width="78" height="41" stroke="none" />
                            <rect x="0.5" y="0.5" width="77" height="40" fill="none" />
                        </g>
                        <g id="middle-bars" transform="translate(21.693 59.917)">
                            <rect
                                id="Rectangle_50"
                                data-name="Rectangle 50"
                                width="10"
                                height="11"
                                transform="translate(186 79)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_51"
                                data-name="Rectangle 51"
                                width="10"
                                height="23"
                                transform="translate(241 67)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_52"
                                data-name="Rectangle 52"
                                width="10"
                                height="18"
                                transform="translate(230 72)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_53"
                                data-name="Rectangle 53"
                                width="10"
                                height="21"
                                transform="translate(219 69)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_54"
                                data-name="Rectangle 54"
                                width="10"
                                height="18"
                                transform="translate(208 72)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_55"
                                data-name="Rectangle 55"
                                width="10"
                                height="11"
                                transform="translate(197 79)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_56"
                                data-name="Rectangle 56"
                                width="10"
                                height="23"
                                transform="translate(252 67)"
                                fill="#1e90ff"
                            />
                        </g>
                    </g>
                    <g id="Right-Graph">
                        <g id="left-green-2" data-name="left-green" transform="translate(490.886 273.834) rotate(180)">
                            <line
                                id="Line_4-3"
                                data-name="Line 4"
                                x1="18"
                                y2="2"
                                transform="translate(68.693 135.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_5-2"
                                data-name="Line 5"
                                x1="10.5"
                                y2="1"
                                transform="translate(86.693 134.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_7-3"
                                data-name="Line 7"
                                x2="10"
                                y2="4"
                                transform="translate(97.193 134.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_8-2"
                                data-name="Line 8"
                                y1="3"
                                x2="8"
                                transform="translate(106.193 135.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_9-3"
                                data-name="Line 9"
                                y1="1"
                                x2="6"
                                transform="translate(114.193 134.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_10-3"
                                data-name="Line 10"
                                x1="15"
                                y1="2"
                                transform="translate(120.193 134.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_12-3"
                                data-name="Line 12"
                                x1="10.5"
                                y2="2"
                                transform="translate(135.193 134.417)"
                                fill="none"
                                stroke="#21c97e"
                                strokeWidth="1"
                            />
                        </g>
                        <g id="left-blue-2" data-name="left-blue" transform="translate(491.386 271.834) rotate(180)">
                            <line
                                id="Line_2-2"
                                data-name="Line 2"
                                y1="3"
                                x2="11.5"
                                transform="translate(69.193 141.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_3-2"
                                data-name="Line 3"
                                x1="6"
                                transform="translate(80.693 141.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_4-4"
                                data-name="Line 4"
                                x1="13"
                                y1="5"
                                transform="translate(86.193 141.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_6-2"
                                data-name="Line 6"
                                x1="8"
                                y2="2.5"
                                transform="translate(99.193 144.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_7-4"
                                data-name="Line 7"
                                y1="5"
                                x2="5.5"
                                transform="translate(107.193 139.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_9-4"
                                data-name="Line 9"
                                x2="12"
                                transform="translate(112.193 139.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_11-2"
                                data-name="Line 11"
                                x1="10"
                                y1="1"
                                transform="translate(123.193 139.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                            <line
                                id="Line_12-4"
                                data-name="Line 12"
                                x1="14"
                                y1="2"
                                transform="translate(132.193 140.417)"
                                fill="none"
                                stroke="#1e90ff"
                                strokeWidth="1"
                            />
                        </g>
                        <g
                            id="Rectangle_48"
                            data-name="Rectangle 48"
                            transform="translate(344.693 109.917)"
                            fill="none"
                            stroke="#707070"
                            strokeWidth="1"
                        >
                            <rect width="78" height="41" stroke="none" />
                            <rect x="0.5" y="0.5" width="77" height="40" fill="none" />
                        </g>
                    </g>
                </g>
                <g id="Icons" transform="translate(3 0.325)">
                    <path
                        id="home"
                        d="M7.672,4.2,5.6,2.084a.268.268,0,0,0-.381,0L3.155,4.2A.536.536,0,0,0,3,4.583V6.831a.536.536,0,0,0,.507.536H7.321a.536.536,0,0,0,.507-.536V4.583A.555.555,0,0,0,7.672,4.2ZM4.877,6.831V5.222H5.95V6.831Zm2.414,0h-.8V4.953a.268.268,0,0,0-.268-.268H4.609a.268.268,0,0,0-.268.268V6.831h-.8V4.572L5.414,2.655,7.291,4.583Z"
                        transform="translate(32 109.498)"
                        fill="#1e90ff"
                    />
                    <path
                        id="alerts"
                        d="M5.388,3.065a.3.3,0,0,1,.483.233V8.063a.3.3,0,0,1-.484.232L3.98,7.169H3.489A1.489,1.489,0,0,1,2,5.745V5.68A1.489,1.489,0,0,1,3.489,4.191h.484Zm-.113.851-1.012.806a.3.3,0,0,1-.186.065H3.489a.893.893,0,1,0,0,1.787h.6a.3.3,0,0,1,.186.066l1,.8V3.916Zm2.408.169a2.981,2.981,0,0,1-.005,3.2.3.3,0,0,1-.5-.321,2.386,2.386,0,0,0,0-2.559.3.3,0,1,1,.5-.319Zm-.893.493A2.088,2.088,0,0,1,6.773,6.81a.3.3,0,1,1-.5-.323,1.491,1.491,0,0,0,.012-1.594.3.3,0,0,1,.505-.316Z"
                        transform="translate(56.011 144.496)"
                        fill="#1e90ff"
                    />
                    <path
                        id="disks"
                        d="M4.68,2c1.5,0,2.634.246,2.679.772V6.556c0,.548-1.152.8-2.68.8H4.5c-1.407-.019-2.454-.266-2.5-.77V2.8c0-.515,1.018-.772,2.409-.8H4.68ZM2.536,5.213V6.5a.455.455,0,0,0,.046.027,1.946,1.946,0,0,0,.451.146,8.009,8.009,0,0,0,1.647.151,8.011,8.011,0,0,0,1.647-.151,1.939,1.939,0,0,0,.45-.146A.466.466,0,0,0,6.825,6.5V5.212a6.574,6.574,0,0,1-2.145.272H4.5a6.2,6.2,0,0,1-1.963-.27Zm0-1.876V4.624a.455.455,0,0,0,.046.027,1.946,1.946,0,0,0,.451.146,8.009,8.009,0,0,0,1.647.151A8.011,8.011,0,0,0,6.327,4.8a1.939,1.939,0,0,0,.45-.146.466.466,0,0,0,.047-.028V3.336A5.948,5.948,0,0,1,4.983,3.6l-.122,0H4.68a6.576,6.576,0,0,1-2.144-.271Zm2.144-.8a8.011,8.011,0,0,0-1.647.151,2.516,2.516,0,0,0-.335.1L2.655,2.8,2.643,2.8l.064.025c.058.021.126.042.2.062l.059.015.065.015a8.009,8.009,0,0,0,1.647.151,8.011,8.011,0,0,0,1.647-.151,2.516,2.516,0,0,0,.335-.1L6.7,2.809,6.717,2.8,6.7,2.8a1.952,1.952,0,0,0-.24-.079L6.392,2.7l-.065-.015A8.009,8.009,0,0,0,4.68,2.536Z"
                        transform="translate(80.942 145.493)"
                        fill="#1e90ff"
                    />
                    <path
                        id="network"
                        d="M4.68,2A2.68,2.68,0,1,0,7.36,4.68,2.68,2.68,0,0,0,4.68,2Zm0,.536a2.195,2.195,0,0,1,.48.056.7.7,0,0,1-.209.268c-.059.046-.123.083-.188.123a1.222,1.222,0,0,0-.5.448,1.739,1.739,0,0,0-.166.884c0,.364,0,.579-.255.769a1.338,1.338,0,0,1-1.276-.019A2.144,2.144,0,0,1,4.68,2.536ZM2.8,5.7a2.257,2.257,0,0,0,.536.072A1.34,1.34,0,0,0,4.182,5.5a1.328,1.328,0,0,0,.458-1.19,1.276,1.276,0,0,1,.1-.627.766.766,0,0,1,.3-.244,2.613,2.613,0,0,0,.247-.163,1.219,1.219,0,0,0,.375-.5,2.144,2.144,0,0,1,.9.863c-.383.054-.927.18-1.034.678a1.876,1.876,0,0,0-.038.362.785.785,0,0,1-.078.394l-.027.046a.933.933,0,0,0-.1,1.072c.032.056.067.11.1.163a.612.612,0,0,1,.139.289,2.115,2.115,0,0,1-.836.18A2.144,2.144,0,0,1,2.8,5.7ZM6,6.374a1.82,1.82,0,0,0-.169-.306c-.029-.043-.059-.086-.086-.131-.1-.182-.067-.268.1-.536l.027-.046A1.278,1.278,0,0,0,6.02,4.7a1.452,1.452,0,0,1,.024-.268c.043-.2.458-.249.716-.268a2.128,2.128,0,0,1-.766,2.219L6,6.374Z"
                        transform="translate(32.733 145.493)"
                        fill="#1e90ff"
                    />
                    <path
                        id="rb"
                        d="M5.124,2a.649.649,0,0,1,.627.624v.448h1.34a.268.268,0,0,1,0,.536H6.824V6.555a.8.8,0,0,1-.8.8H3.34a.8.8,0,0,1-.8-.8V3.607H2.268a.268.268,0,1,1,0-.536h1.34V2.623A.649.649,0,0,1,4.278,2ZM6.288,3.607H3.072V6.555l0,.031a.268.268,0,0,0,.266.237H6.02a.268.268,0,0,0,.268-.268Zm-2.412.8a.268.268,0,0,1,.268.268V5.751a.268.268,0,0,1-.268.268l-.031,0a.268.268,0,0,1-.237-.266V4.679A.268.268,0,0,1,3.876,4.411Zm1.608,0a.268.268,0,0,1,.268.268V5.751a.268.268,0,0,1-.268.268l-.031,0a.268.268,0,0,1-.237-.266V4.679A.268.268,0,0,1,5.484,4.411Zm-.4-1.876h-.8c-.078,0-.134.046-.134.088v.448H5.216V2.623C5.216,2.58,5.159,2.535,5.082,2.535Z"
                        transform="translate(32.734 127.501)"
                        fill="#1e90ff"
                    />
                    <path
                        id="sb"
                        d="M7.467,3a.893.893,0,0,1,.893.893V7.467a.893.893,0,0,1-.893.893H3.893A.893.893,0,0,1,3,7.467V3.841A.893.893,0,0,1,3.893,3Zm.3,2.382H3.6V7.467l0,.035a.3.3,0,0,0,.3.263H7.467a.3.3,0,0,0,.3-.3ZM7.467,3.6H3.893a.3.3,0,0,0-.3.3v.893H7.764V3.893A.3.3,0,0,0,7.467,3.6ZM4.7,3.99a.3.3,0,1,1-.421,0,.3.3,0,0,1,.421,0Zm1.191,0a.3.3,0,1,1-.421,0,.3.3,0,0,1,.421,0Z"
                        transform="translate(55.405 126.499)"
                        fill="#1e90ff"
                    />
                    <path
                        id="shares"
                        d="M6.762,6.571a.893.893,0,0,0-.625.256L3.785,5.779v-.2L6.137,4.528a.893.893,0,1,0-.268-.637v.1L3.517,5.041a.893.893,0,1,0,0,1.274L5.869,7.366v.1a.893.893,0,1,0,.893-.893Zm0-2.977a.3.3,0,1,1-.3.3A.3.3,0,0,1,6.762,3.594ZM2.891,5.975a.3.3,0,1,1,.3-.3A.3.3,0,0,1,2.891,5.975ZM6.762,7.762a.3.3,0,1,1,.3-.3A.3.3,0,0,1,6.762,7.762Z"
                        transform="translate(80.344 108.505)"
                        fill="#1e90ff"
                    />
                    <path
                        id="sln"
                        d="M4.762,5.435a.3.3,0,0,1,.416,0l.893.893a.3.3,0,0,1,0,.42h0a.3.3,0,0,1-.421,0l-.383-.386V8.023a.3.3,0,1,1-.594,0V6.348l-.389.377A.3.3,0,1,1,3.87,6.3ZM4.384,3.059a1.783,1.783,0,0,1,2.268,1.1l.076.011a1.486,1.486,0,0,1,.839,2.464l-.026.026a.3.3,0,0,1-.2.075l-.036,0a.3.3,0,0,1-.16-.072h0a.3.3,0,0,1-.025-.421l.042-.051a.891.891,0,0,0-.711-1.435H6.434a.3.3,0,0,1-.3-.238l-.014-.06a1.189,1.189,0,0,0-2.316.06L3.8,4.551a.3.3,0,0,1-.288.2h-.03a.891.891,0,0,0-.669,1.486l.027.025a.3.3,0,1,1-.473.352l-.052-.062a1.486,1.486,0,0,1,.967-2.4l.027-.073A1.783,1.783,0,0,1,4.384,3.059Z"
                        transform="translate(80.348 127.539)"
                        fill="#1e90ff"
                    />
                    <path
                        id="users"
                        d="M7.085,5.978h.071A2.085,2.085,0,0,1,9.169,8.063l0,.035a.3.3,0,0,1-.594-.035V8A1.489,1.489,0,0,0,5.6,8.063l0,.035A.3.3,0,0,1,5,8.063V7.991A2.085,2.085,0,0,1,7.085,5.978ZM7.085,3a1.191,1.191,0,1,1,0,2.382h-.06a1.191,1.191,0,0,1-1.132-1.19v-.06A1.191,1.191,0,0,1,7.085,3Zm0,.6H7.04a.6.6,0,0,0-.551.594v.045a.6.6,0,1,0,.594-.64Z"
                        transform="translate(54 108.502)"
                        fill="#1e90ff"
                    />
                    <path
                        id="whatsthis"
                        d="M7.816,4.191H7.7a.892.892,0,0,0,.057-.3A.917.917,0,0,0,6.821,3a1.328,1.328,0,0,0-1.149.715A1.328,1.328,0,0,0,4.523,3a.917.917,0,0,0-.935.893.892.892,0,0,0,.057.3H3.528a.6.6,0,0,0-.537.649V5.917a.619.619,0,0,0,.447.655v1.3a.521.521,0,0,0,.539.485H7.366a.521.521,0,0,0,.539-.5V6.573a.619.619,0,0,0,.447-.655V4.846a.6.6,0,0,0-.53-.654Zm-.06.655V5.917a.167.167,0,0,1,0,.06H5.97V4.786H7.756a.167.167,0,0,1,0,.06ZM6.821,3.6a.322.322,0,0,1,.339.3.322.322,0,0,1-.339.3H6.089A.872.872,0,0,1,6.821,3.6Zm-2.638.3a.322.322,0,0,1,.339-.3.872.872,0,0,1,.729.6H4.523A.322.322,0,0,1,4.183,3.893Zm-.6.953a.167.167,0,0,1,0-.06H5.374V5.977H3.588a.167.167,0,0,1,0-.06Zm.447,1.727h1.34V7.764H4.034ZM5.97,7.764V6.573H7.31V7.764Z"
                        transform="translate(32.011 162.488)"
                        fill="#1e90ff"
                        fillRule="evenodd"
                    />
                </g>
                <g id="Body-BL">
                    <rect
                        id="Rectangle_58"
                        data-name="Rectangle 58"
                        width="142"
                        height="75"
                        rx="2"
                        transform="translate(132 180)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_69"
                        data-name="Rectangle 69"
                        width="28"
                        height="5"
                        rx="2.5"
                        transform="translate(138 186)"
                        fill="#707070"
                    />
                    <g
                        id="Rectangle_68"
                        data-name="Rectangle 68"
                        transform="translate(141 198)"
                        fill="#d1e7ff"
                        stroke="#dedede"
                        strokeWidth="1"
                    >
                        <rect width="126" height="64" stroke="none" />
                        <rect x="0.5" y="0.5" width="125" height="63" fill="none" />
                    </g>
                </g>
                <g id="Body-TL">
                    <rect
                        id="Rectangle_57"
                        data-name="Rectangle 57"
                        width="120"
                        height="59"
                        rx="2"
                        transform="translate(132 112)"
                        fill="#fff"
                    />
                    <g
                        id="Rectangle_62"
                        data-name="Rectangle 62"
                        transform="translate(161 129)"
                        fill="#fff"
                        stroke="#707070"
                        strokeWidth="1"
                    >
                        <rect width="86" height="33" stroke="none" />
                        <rect x="0.5" y="0.5" width="85" height="32" fill="none" />
                    </g>
                    <rect
                        id="Rectangle_61"
                        data-name="Rectangle 61"
                        width="56"
                        height="5"
                        rx="2.5"
                        transform="translate(138 119)"
                        fill="#707070"
                    />
                    <rect
                        id="Rectangle_64"
                        data-name="Rectangle 64"
                        width="20"
                        height="4"
                        rx="2"
                        transform="translate(138 132)"
                        fill="#8b8b8b"
                    />
                    <rect
                        id="Rectangle_65"
                        data-name="Rectangle 65"
                        width="20"
                        height="4"
                        rx="2"
                        transform="translate(138 140)"
                        fill="#8b8b8b"
                    />
                    <rect
                        id="Rectangle_66"
                        data-name="Rectangle 66"
                        width="20"
                        height="4"
                        rx="2"
                        transform="translate(138 148)"
                        fill="#8b8b8b"
                    />
                    <rect
                        id="Rectangle_67"
                        data-name="Rectangle 67"
                        width="20"
                        height="4"
                        rx="2"
                        transform="translate(138 156)"
                        fill="#8b8b8b"
                    />
                    <g id="left-blue-3" data-name="left-blue" transform="translate(95.307 11.583)">
                        <line
                            id="Line_2-3"
                            data-name="Line 2"
                            y1="23"
                            x2="7"
                            transform="translate(69.193 127.417)"
                            fill="none"
                            stroke="#1e90ff"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_3-3"
                            data-name="Line 3"
                            x1="6"
                            y1="18"
                            transform="translate(76.193 127.417)"
                            fill="none"
                            stroke="#1e90ff"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_4-5"
                            data-name="Line 4"
                            x1="9"
                            y2="28"
                            transform="translate(82.193 117.417)"
                            fill="none"
                            stroke="#1e90ff"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_5-3"
                            data-name="Line 5"
                            x1="8"
                            y1="28"
                            transform="translate(91.193 118.417)"
                            fill="none"
                            stroke="#1e90ff"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_6-3"
                            data-name="Line 6"
                            x1="3"
                            y2="17"
                            transform="translate(99.193 129.417)"
                            fill="none"
                            stroke="#1e90ff"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_7-5"
                            data-name="Line 7"
                            x2="4"
                            y2="21"
                            transform="translate(102.193 129.417)"
                            fill="none"
                            stroke="#1e90ff"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_8-3"
                            data-name="Line 8"
                            y1="26"
                            x2="7"
                            transform="translate(106.193 124.417)"
                            fill="none"
                            stroke="#1e90ff"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_9-5"
                            data-name="Line 9"
                            x2="12"
                            transform="translate(113.193 124.417)"
                            fill="none"
                            stroke="#1e90ff"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_10-4"
                            data-name="Line 10"
                            x1="3"
                            y1="18.5"
                            transform="translate(125.193 123.917)"
                            fill="none"
                            stroke="#1e90ff"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_11-3"
                            data-name="Line 11"
                            x1="6"
                            y2="13"
                            transform="translate(128.193 129.417)"
                            fill="none"
                            stroke="#1e90ff"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_12-5"
                            data-name="Line 12"
                            x1="12"
                            y1="21"
                            transform="translate(134.193 129.417)"
                            fill="none"
                            stroke="#1e90ff"
                            strokeWidth="1"
                        />
                    </g>
                </g>
                <g id="Body-BR">
                    <rect
                        id="Rectangle_60"
                        data-name="Rectangle 60"
                        width="111"
                        height="75"
                        rx="2"
                        transform="translate(283 180)"
                        fill="#fff"
                    />
                    <g id="left-blue-4" data-name="left-blue" transform="translate(453.193 362.417) rotate(180)">
                        <line
                            id="Line_2-4"
                            data-name="Line 2"
                            y1="28"
                            x2="8"
                            transform="translate(70.193 115.417)"
                            fill="none"
                            stroke="#21c97e"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_3-4"
                            data-name="Line 3"
                            x1="4"
                            y1="30"
                            transform="translate(78.193 115.417)"
                            fill="none"
                            stroke="#21c97e"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_4-6"
                            data-name="Line 4"
                            x1="6"
                            y2="18"
                            transform="translate(82.193 127.417)"
                            fill="none"
                            stroke="#21c97e"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_5-4"
                            data-name="Line 5"
                            x1="11"
                            y1="22"
                            transform="translate(88.193 127.417)"
                            fill="none"
                            stroke="#21c97e"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_6-4"
                            data-name="Line 6"
                            x1="3"
                            y2="10.5"
                            transform="translate(98.787 139.417)"
                            fill="none"
                            stroke="#21c97e"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_7-6"
                            data-name="Line 7"
                            x2="4"
                            y2="11"
                            transform="translate(102.193 139.417)"
                            fill="none"
                            stroke="#21c97e"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_8-4"
                            data-name="Line 8"
                            y1="4"
                            x2="8"
                            transform="translate(106.193 146.417)"
                            fill="none"
                            stroke="#21c97e"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_9-6"
                            data-name="Line 9"
                            y1="30"
                            x2="9"
                            transform="translate(113.193 117.417)"
                            fill="none"
                            stroke="#21c97e"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_10-5"
                            data-name="Line 10"
                            x1="6"
                            y1="32.5"
                            transform="translate(122.432 117.417)"
                            fill="none"
                            stroke="#21c97e"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_11-4"
                            data-name="Line 11"
                            x1="6"
                            y2="13"
                            transform="translate(128.193 136.917)"
                            fill="none"
                            stroke="#21c97e"
                            strokeWidth="1"
                        />
                        <line
                            id="Line_12-6"
                            data-name="Line 12"
                            x1="10"
                            y1="13"
                            transform="translate(134.193 136.417)"
                            fill="none"
                            stroke="#21c97e"
                            strokeWidth="1"
                        />
                    </g>
                    <g
                        id="Rectangle_63"
                        data-name="Rectangle 63"
                        transform="translate(308 198)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1"
                    >
                        <rect width="76" height="49" stroke="none" />
                        <rect x="0.5" y="0.5" width="75" height="48" fill="none" />
                    </g>
                    <rect
                        id="Rectangle_71"
                        data-name="Rectangle 71"
                        width="20"
                        height="4"
                        rx="2"
                        transform="translate(285 200)"
                        fill="#707070"
                    />
                    <rect
                        id="Rectangle_73"
                        data-name="Rectangle 73"
                        width="20"
                        height="2"
                        rx="1"
                        transform="translate(285 219)"
                        fill="#8b8b8b"
                    />
                    <rect
                        id="Rectangle_74"
                        data-name="Rectangle 74"
                        width="20"
                        height="2"
                        rx="1"
                        transform="translate(285 212)"
                        fill="#8b8b8b"
                    />
                    <rect
                        id="Rectangle_75"
                        data-name="Rectangle 75"
                        width="20"
                        height="2"
                        rx="1"
                        transform="translate(285 226)"
                        fill="#8b8b8b"
                    />
                    <rect
                        id="Rectangle_76"
                        data-name="Rectangle 76"
                        width="20"
                        height="2"
                        rx="1"
                        transform="translate(285 233)"
                        fill="#8b8b8b"
                    />
                    <rect
                        id="Rectangle_70"
                        data-name="Rectangle 70"
                        width="38"
                        height="5"
                        rx="2.5"
                        transform="translate(291 186)"
                        fill="#707070"
                    />
                </g>
                <g id="Body-TR">
                    <rect
                        id="Rectangle_59"
                        data-name="Rectangle 59"
                        width="133"
                        height="59"
                        rx="2"
                        transform="translate(261 112)"
                        fill="#fff"
                    />
                    <g id="Group_9" data-name="Group 9">
                        <g id="bars" transform="translate(98 77)">
                            <rect
                                id="Rectangle_50-2"
                                data-name="Rectangle 50"
                                width="10"
                                height="11"
                                transform="translate(181 79)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_51-2"
                                data-name="Rectangle 51"
                                width="10"
                                height="23"
                                transform="translate(236 67)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_52-2"
                                data-name="Rectangle 52"
                                width="10"
                                height="18"
                                transform="translate(225 72)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_53-2"
                                data-name="Rectangle 53"
                                width="10"
                                height="21"
                                transform="translate(214 69)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_54-2"
                                data-name="Rectangle 54"
                                width="10"
                                height="18"
                                transform="translate(203 72)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_55-2"
                                data-name="Rectangle 55"
                                width="10"
                                height="11"
                                transform="translate(192 79)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_80"
                                data-name="Rectangle 80"
                                width="10"
                                height="11"
                                transform="translate(269 79)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_56-2"
                                data-name="Rectangle 56"
                                width="10"
                                height="23"
                                transform="translate(247 67)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_79"
                                data-name="Rectangle 79"
                                width="10"
                                height="21"
                                transform="translate(258 69)"
                                fill="#1e90ff"
                            />
                            <rect
                                id="Rectangle_81"
                                data-name="Rectangle 81"
                                width="10"
                                height="21"
                                transform="translate(280 69)"
                                fill="#1e90ff"
                            />
                        </g>
                        <g
                            id="Rectangle_77"
                            data-name="Rectangle 77"
                            transform="translate(277 129)"
                            fill="none"
                            stroke="#707070"
                            strokeWidth="1"
                        >
                            <rect width="111" height="38" stroke="none" />
                            <rect x="0.5" y="0.5" width="110" height="37" fill="none" />
                        </g>
                    </g>
                    <rect
                        id="Rectangle_82"
                        data-name="Rectangle 82"
                        width="10"
                        height="2"
                        rx="1"
                        transform="translate(264 140)"
                        fill="#8b8b8b"
                    />
                    <rect
                        id="Rectangle_83"
                        data-name="Rectangle 83"
                        width="10"
                        height="2"
                        rx="1"
                        transform="translate(264 133)"
                        fill="#8b8b8b"
                    />
                    <rect
                        id="Rectangle_84"
                        data-name="Rectangle 84"
                        width="10"
                        height="2"
                        rx="1"
                        transform="translate(264 147)"
                        fill="#8b8b8b"
                    />
                    <rect
                        id="Rectangle_85"
                        data-name="Rectangle 85"
                        width="10"
                        height="2"
                        rx="1"
                        transform="translate(264 154)"
                        fill="#8b8b8b"
                    />
                    <rect
                        id="Rectangle_86"
                        data-name="Rectangle 86"
                        width="10"
                        height="2"
                        rx="1"
                        transform="translate(264 161)"
                        fill="#8b8b8b"
                    />
                    <rect
                        id="Rectangle_78"
                        data-name="Rectangle 78"
                        width="41"
                        height="4"
                        rx="2"
                        transform="translate(277 119)"
                        fill="#707070"
                    />
                </g>
            </g>
        </svg>
    );
};

export default EvoGui2;
