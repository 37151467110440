import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import AlertMessageIcon from "assets/AlertMessageIcon";
import InfoIcon from "assets/InfoIcon";
import { useStyles } from "./NewPrivateKeyLayout.styles";
import { useTranslation } from "react-i18next";

const NewPrivateKeyLayout = ({ isSslEnabled }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return useObserver(() => (
        <Grid container wrap={"nowrap"} className={classes.messageContainer}>
            {isSslEnabled ? (
                <>
                    <Grid item className={classes.icon}>
                        <AlertMessageIcon />
                    </Grid>
                    <Grid item>
                        <Typography>
                            {t(
                                "admin_settings.server_certificate.renew_certificate.create_private_key.cannot_delete_private_key"
                            )}
                        </Typography>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item className={classes.icon}>
                        <InfoIcon />
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom>
                            {t(
                                "admin_settings.server_certificate.renew_certificate.create_private_key.private_key_will_be_deleted"
                            )}
                        </Typography>
                        <Typography variant={"subtitle1"}>
                            {t("admin_settings.server_certificate.renew_certificate.create_private_key.confirmation")}
                        </Typography>
                    </Grid>
                </>
            )}
        </Grid>
    ));
};

export default NewPrivateKeyLayout;
