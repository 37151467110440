import { types } from "mobx-state-tree";

import DeferredParityCalculation from "api/volume/Types/DeferredParityCalculation";

const DeferredParityCalculationResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(DeferredParityCalculation)
});

export default DeferredParityCalculationResult;
