import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    cardContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap"
    },
    tableContainer: {
        flex: 1
    },
    paginationContainer: {
        paddingLeft: theme.spacing(6)
    },
    tableCell: {
        width: "50%"
    },
    cellWithIcon: {
        "& svg path": {
            fill: theme.palette.grey[300]
        }
    },
    tableHeadLabel: {
        "& .MuiTypography-body1": {
            fontWeight: 500,
            opacity: 1
        }
    }
}));
