import React from "react";
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import CheckIcon from "assets/CheckIcon";
import RebootIcon from "assets/RebootIcon";
import { useStore } from "hooks/useStore";
import { useStyles } from "./SuccessfulMigration.styles";
import i18next from "i18next";
import Stage from "../Stage/Stage";

const SuccessfulMigration = () => {
    const classes = useStyles();
    const {
        store: { powerAndUpsStore, migrationStore },
    } = useStore();

    return (
        <Box p={6}>
            <Grid container spacing={6}>
                <Grid container item justifyContent="center">
                    <CheckIcon />
                </Grid>
                <Grid container item justifyContent="center">
                    <Typography variant="h2">{i18next.t("migrator.main_step.successful.title")}</Typography>
                    <Typography>{i18next.t("migrator.main_step.successful.text")}</Typography>
                </Grid>
                <Stage list={migrationStore.warningList} success />
                <Grid container item justifyContent="center">
                    <Button
                        disabled={powerAndUpsStore.isRebooting}
                        variant="contained"
                        color="secondary"
                        startIcon={<RebootIcon />}
                        onClick={powerAndUpsStore.reboot}
                    >
                        {i18next.t("common.button.reboot")}
                        {powerAndUpsStore.isRebooting && (
                            <Box className={classes.icon}>
                                <CircularProgress color="primary" size={16} />
                            </Box>
                        )}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default observer(SuccessfulMigration);
