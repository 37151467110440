import { types } from "mobx-state-tree";

const VolumeInfo = types.model({
    pool: types.string,
    volume: types.string,
    raid: types.number,
    sizes: types.array(types.number)
});

export default VolumeInfo;
