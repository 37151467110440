import { types, flow, getParent, applySnapshot } from "mobx-state-tree";

import Socket from "websocket";
import PartitionInfoDataResult from "api/upgrade/Responses/PartitionInfoDataResult";
import GetPartitionsStatus from "api/upgrade/Requests/GetPartitionsStatus";
import ListUpdatesResult from "api/upgrade/Responses/ListUpdatesResult";
import UpgradeStepResult from "api/upgrade/Responses/UpgradeStepResult";
import ListUpdates from "api/upgrade/Requests/ListUpdates";
import GetUpgradeStatus from "api/upgrade/Requests/GetUpgradeStatus";
import Upgrade from "api/upgrade/Requests/Upgrade";
import { STARTED, RUNNING } from "const/updatesConst";
import { CODE_DISRUPTION_SESSIONS } from "const/errorConst";
import ClearLun from "api/upgrade/Requests/ClearLun";
import { autorun } from "mobx";

const UpgradesStore = types
    .model({
        partitionsStore: types.maybe(PartitionInfoDataResult),
        listUpdatesResult: types.maybe(ListUpdatesResult),
        upgradeStepResult: types.maybe(UpgradeStepResult),
        isStartUpgradingError: types.optional(types.boolean, false),
        chosenFileName: types.optional(types.string, ""),
    })
    .views((self) => ({
        get partitions() {
            return self.partitionsStore?.data.partitions;
        },
        get updatesList() {
            return self.listUpdatesResult?.data || [];
        },
        get upgradeStep() {
            return self.upgradeStepResult?.data || {};
        },
        get upgradeStepStatus() {
            return self.upgradeStep.status || "";
        },
        get upgradeStepMessage() {
            return self.upgradeStep.message || "";
        },
        get isUpgrading() {
            return !!self.upgradeStepStatus && (self.upgradeStepStatus === STARTED || self.upgradeStepStatus === RUNNING);
        },
    }))
    .actions((self) => ({
        fetchPartitions: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetPartitionsStatus.create().init();
                const res = yield Socket.send(req);
                self.partitionsStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchUpdatesList: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ListUpdates.create().init();
                const res = yield Socket.send(req);

                self.listUpdatesResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        upgrade: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                self.chosenFileName = data.filename;
                const req = Upgrade.create().init(data);
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                if (e.code === CODE_DISRUPTION_SESSIONS) {
                    self.isStartUpgradingError = true;
                }
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchUpdateStatus: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetUpgradeStatus.create().init();
                const res = yield Socket.send(req);
                self.upgradeStepResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        clearLun: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ClearLun.create().init();
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        clearUpdateStatus: function () {
            applySnapshot(self, {
                partitionsStore: self.partitionsStore,
                listUpdatesResult: self.listUpdatesResult,
                upgradeStepResult: {},
            });
        },
        clearIsStartUpgradingError: function () {
            self.isStartUpgradingError = false;
        },
    }))
    .actions((self) => ({
        afterCreate() {
            const { authStore } = getParent(self);
            autorun(() => {
                if (authStore.isAuthorized) {
                    self.fetchUpdateStatus();
                }
            });
        },
    }));

export default UpgradesStore;
