import { types } from "mobx-state-tree";

import DriveId from "api/drive_replace/Types/DriveId";

const ReplaceDrive = types.model({
    drive: types.maybe(DriveId),
    index: types.number
});

export default ReplaceDrive;
