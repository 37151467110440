import { flow, getParent, types } from "mobx-state-tree";
import PingTestResult from "api/net/Responses/PingTestResult";
import Socket from "websocket";
import RunPingTest from "api/net/Requests/RunPingTest";
import GetLastPingTestResult from "api/net/Requests/GetLastPingTestResult";

const PingStore = types
    .model({
        pingTestResult: types.maybe(PingTestResult)
    })
    .views(self => ({
        get pingResult() {
            return self.pingTestResult?.data || "";
        }
    }))
    .actions(self => ({
        runPingTest: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RunPingTest.create().init(data);
                return yield Socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getLastPingTestResult: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetLastPingTestResult.create().init();
                const res = yield Socket.send(req);
                self.pingTestResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        })
    }));

export default PingStore;
