import i18n from "i18n";

const DEFAULT_POSTFIX = "(default)";

export const getPresetArgumentArrayWithDefault = (defaultValue, presetArgumentArray, presetArgumentName) => {
    let value = defaultValue;
    if (defaultValue === null || !presetArgumentArray.some((preset) => preset.value === defaultValue)) {
        switch (presetArgumentName) {
            case "acodec":
            case "vcodec":
            case "container":
            case "bv":
            case "pix_fmt":
                value = "default";
                break;
            case "preset":
                value = PRESET_ARRAY[4].value;
                break;
            case "watermark_position":
                value = WATERMARK_POSSIBLE_POSITIONS[4].value;
                break;
            case "watermark_scale":
                value = WATERMARK_POSSIBLE_SCALES[1].value;
                break;
            case "timecode_position":
                value = WATERMARK_POSSIBLE_POSITIONS[7].value;
                break;
            default:
                break;
        }
    }
    return presetArgumentArray.map((preset) =>
        preset.value === value
            ? {
                  value: preset.value,
                  label: `${preset.label} ${DEFAULT_POSTFIX}`,
              }
            : preset
    );
};

export const PRESET_ARRAY = [
    { value: "ultrafast", label: i18n.t("slingshot_settings.preset.ultrafast") },
    { value: "superfast", label: i18n.t("slingshot_settings.preset.superfast") },
    { value: "veryfast", label: i18n.t("slingshot_settings.preset.veryfast") },
    { value: "faster", label: i18n.t("slingshot_settings.preset.faster") },
    { value: "medium", label: i18n.t("slingshot_settings.preset.medium") },
    { value: "slow", label: i18n.t("slingshot_settings.preset.slow") },
    { value: "slower", label: i18n.t("slingshot_settings.preset.slower") },
    { value: "veryslow", label: i18n.t("slingshot_settings.preset.veryslow") },
    { value: "placebo", label: i18n.t("slingshot_settings.preset.placebo") },
];

export const ACODEC_ARRAY = [
    { value: "default", label: i18n.t("slingshot_settings.preset.preserve_original") },
    { value: "libmp3lame", label: i18n.t("slingshot_settings.preset.mp3") },
    { value: "pcm_alaw", label: "Pcm_alaw" },
    { value: "pcm_mulaw", label: "Pcm_mulaw" },
    { value: "pcm_f32be", label: "Pcm_f32be" },
    { value: "pcm_f32le", label: "Pcm_f32le" },
    { value: "pcm_f32le", label: "Pcm_f32le" },
    { value: "pcm_f64le", label: "Pcm_f64le" },
    { value: "pcm_s16be", label: "Pcm_s16be" },
    { value: "pcm_s16be_planar", label: "Pcm_s16be_planar" },
    { value: "pcm_s16le", label: "Pcm_s16le" },
    { value: "pcm_s16le_planar", label: "Pcm_s16le_planar" },
    { value: "pcm_s24be", label: "Pcm_s24be" },
    { value: "pcm_s24daud", label: "Pcm_s24daud" },
    { value: "pcm_s24le", label: "Pcm_s24le" },
    { value: "pcm_s24le_planar", label: "Pcm_s24le_planar" },
    { value: "pcm_s32be", label: "Pcm_s32be" },
    { value: "pcm_s32le", label: "Pcm_s32le" },
    { value: "pcm_s32le_planar", label: "Pcm_s32le_planar" },
    { value: "pcm_s8", label: "Pcm_s8" },
    { value: "pcm_s8_planar", label: "Pcm_s8_planar" },
    { value: "pcm_u16be", label: "Pcm_u16be" },
    { value: "pcm_u16le", label: "Pcm_u16le" },
    { value: "pcm_u24be", label: "Pcm_u24be" },
    { value: "pcm_u24le", label: "Pcm_u24le" },
    { value: "pcm_u32be", label: "Pcm_u32be" },
    { value: "pcm_u32le", label: "Pcm_u32le" },
    { value: "pcm_u8", label: "Pcm_u8" },
    { value: "copy", label: i18n.t("slingshot_settings.preset.copy_original") },
];

export const VCODEC_ARRAY = [
    { value: "default", label: i18n.t("slingshot_settings.preset.preserve_original") },
    { value: "h264", label: i18n.t("slingshot_settings.preset.h264") },
    { value: "mpeg4", label: "Mpeg4" },
    { value: "PRORES_4444XQ", label: "ProRes_4444XQ" },
    { value: "PRORES_4444", label: "ProRes_4444" },
    { value: "PRORES_422HQ", label: "ProRes_422HQ" },
    { value: "PRORES_422", label: "ProRes_422" },
    { value: "PRORES_422LT", label: "ProRes_422LT" },
    { value: "PRORES_422PROXY", label: i18n.t("slingshot_settings.preset.pro_res_proxy") },
    { value: "copy", label: i18n.t("slingshot_settings.preset.copy_original") },
];

export const VCODEC_ARRAY_EDIT_PROXY = [
    { value: "default", label: i18n.t("slingshot_settings.preset.preserve_original") },
    { value: "h264", label: i18n.t("slingshot_settings.preset.h264") },
    { value: "mpeg4", label: "Mpeg4" },
    { value: "PRORES_4444XQ", label: "ProRes_4444XQ" },
    { value: "PRORES_4444", label: "ProRes_4444" },
    { value: "PRORES_422HQ", label: "ProRes_422HQ" },
    { value: "PRORES_422", label: "ProRes_422" },
    { value: "PRORES_422LT", label: "ProRes_422LT" },
    { value: "PRORES_422PROXY", label: i18n.t("slingshot_settings.preset.pro_res_proxy_default") },
    { value: "copy", label: i18n.t("slingshot_settings.preset.copy_original") },
];

export const DEFAULT_VCODEC_PRESET = "h264";

export const CONTAINER_ARRAY = [
    { value: "default", label: i18n.t("slingshot_settings.preset.preserve_original_default") },
    { value: "mov", label: "Mov" },
    { value: "avi", label: "Avi" },
    { value: "mp4", label: "Mp4" },
    { value: "mp3", label: "Mp3" },
    { value: "mxf", label: "Mxf" },
];

export const BITRATE_ARRAY = [
    { value: "default", label: i18n.t("slingshot_settings.preset.no_value") },
    { value: "36M", label: "36M" },
    { value: "42M", label: "42M" },
    { value: "45M", label: "45M" },
    { value: "60M", label: "60M" },
    { value: "63M", label: "63M" },
    { value: "75M", label: "75M" },
    { value: "80M", label: "80M" },
    { value: "84M", label: "84M" },
    { value: "90M", label: "90M" },
    { value: "100M", label: "100M" },
    { value: "110M", label: "110M" },
    { value: "115M", label: "115M" },
    { value: "120M", label: "120M" },
    { value: "145M", label: "145M" },
    { value: "175M", label: "175M" },
    { value: "180M", label: "180M" },
    { value: "185M", label: "185M" },
    { value: "220M", label: "220M" },
    { value: "240M", label: "240M" },
    { value: "290M", label: "290M" },
    { value: "350M", label: "350M" },
    { value: "365M", label: "365M" },
    { value: "390M", label: "390M" },
    { value: "440M", label: "440M" },
    { value: "730M", label: "730M" },
    { value: "880M", label: "880M" },
];

export const PIX_FMT_ARRAY = [
    { value: "default", label: i18n.t("slingshot_settings.preset.preserve_original") },
    { value: "yuv420p", label: i18n.t("slingshot_settings.preset.yuv420p") },
    { value: "yuyv422", label: "Yuyv422" },
    { value: "rgb24", label: "Rgb24" },
    { value: "bgr24", label: "Bgr24" },
    { value: "yuv422p", label: "Yuv422p" },
    { value: "yuv444p", label: "Yuv444p" },
    { value: "yuv410p", label: "Yuv410p" },
    { value: "yuv411p", label: "Yuv411p" },
    { value: "gray", label: "Gray" },
    { value: "monow", label: "Monow" },
    { value: "monob", label: "Monob" },
    { value: "yuvj420p", label: "Yuvj420p" },
    { value: "yuvj422p", label: "Yuvj422p" },
    { value: "yuvj444p", label: "Yuvj444p" },
    { value: "uyvy422", label: "Uyvy422" },
    { value: "bgr8", label: "Bgr8" },
    { value: "bgr4_byte", label: "Bgr4_byte" },
    { value: "rgb8", label: "Rgb8" },
    { value: "rgb4_byte", label: "Rgb4_byte" },
    { value: "nv12", label: "Nv12" },
    { value: "nv21", label: "Nv21" },
    { value: "argb", label: "Argb" },
    { value: "rgba", label: "Rgba" },
    { value: "abgr", label: "Abgr" },
    { value: "bgra", label: "Bgra" },
    { value: "gray16be", label: "Gray16be" },
    { value: "gray16le", label: "Gray16le" },
    { value: "yuv440p", label: "Yuv440p" },
    { value: "yuvj440p", label: "Yuvj440p" },
    { value: "yuva420p", label: "Yuva420p" },
    { value: "rgb48be", label: "Rgb48be" },
    { value: "rgb48le", label: "Rgb48le" },
    { value: "rgb565be", label: "Rgb565be" },
    { value: "rgb565le", label: "Rgb565le" },
    { value: "rgb555be", label: "Rgb555be" },
    { value: "rgb555le", label: "Rgb555le" },
    { value: "bgr565be", label: "Bgr565be" },
    { value: "bgr565le", label: "Bgr565le" },
    { value: "bgr555be", label: "Bgr555be" },
    { value: "bgr555le", label: "Bgr555le" },
    { value: "yuv420p16le", label: "Yuv420p16le" },
    { value: "yuv420p16be", label: "Yuv420p16be" },
    { value: "yuv422p16le", label: "Yuv422p16le" },
    { value: "yuv422p16be", label: "Yuv422p16be" },
    { value: "yuv444p16le", label: "Yuv444p16le" },
    { value: "yuv444p16be", label: "Yuv444p16be" },
    { value: "rgb444le", label: "Rgb444le" },
    { value: "rgb444be", label: "Rgb444be" },
    { value: "bgr444le", label: "Bgr444le" },
    { value: "bgr444be", label: "Bgr444be" },
    { value: "ya8", label: "Ya8" },
    { value: "bgr48be", label: "Bgr48be" },
    { value: "bgr48le", label: "Bgr48le" },
    { value: "yuv420p9be", label: "Yuv420p9be" },
    { value: "yuv420p9le", label: "Yuv420p9le" },
    { value: "yuv420p10be", label: "Yuv420p10be" },
    { value: "yuv420p10le", label: "Yuv420p10le" },
    { value: "yuv422p10be", label: "Yuv422p10be" },
    { value: "yuv422p10le", label: "Yuv422p10le" },
    { value: "yuv444p9be", label: "Yuv444p9be" },
    { value: "yuv444p9le", label: "Yuv444p9le" },
    { value: "yuv444p10be", label: "Yuv444p10be" },
    { value: "yuv444p10le", label: "Yuv444p10le" },
    { value: "yuv422p9be", label: "Yuv422p9be" },
    { value: "yuv422p9le", label: "Yuv422p9le" },
    { value: "gbrp", label: "Gbrp" },
    { value: "gbrp9be", label: "Gbrp9be" },
    { value: "gbrp9le", label: "Gbrp9le" },
    { value: "gbrp10be", label: "Gbrp10be" },
    { value: "gbrp10le", label: "Gbrp10le" },
    { value: "yuva422p", label: "Yuva422p" },
    { value: "yuva444p", label: "Yuva444p" },
    { value: "yuva420p9be", label: "Yuva420p9be" },
    { value: "yuva420p9le", label: "Yuva420p9le" },
    { value: "yuva422p9be", label: "Yuva422p9be" },
    { value: "yuva422p9le", label: "Yuva422p9le" },
    { value: "yuva444p9be", label: "Yuva444p9be" },
    { value: "yuva444p9le", label: "Yuva444p9le" },
    { value: "yuva420p10be", label: "Yuva420p10be" },
    { value: "yuva420p10le", label: "Yuva420p10le" },
    { value: "yuva422p10be", label: "Yuva422p10be" },
    { value: "yuva422p10le", label: "Yuva422p10le" },
    { value: "yuva444p10be", label: "Yuva444p10be" },
    { value: "yuva444p10le", label: "Yuva444p10le" },
    { value: "yuva420p16be", label: "Yuva420p16be" },
    { value: "yuva420p16le", label: "Yuva420p16le" },
    { value: "yuva422p16be", label: "Yuva422p16be" },
    { value: "yuva422p16le", label: "Yuva422p16le" },
    { value: "yuva444p16be", label: "Yuva444p16be" },
    { value: "yuva444p16le", label: "Yuva444p16le" },
    { value: "xyz12le", label: "Xyz12le" },
    { value: "xyz12be", label: "Xyz12be" },
    { value: "rgba64be", label: "Rgba64be" },
    { value: "rgba64le", label: "Rgba64le" },
    { value: "bgra64be", label: "Bgra64be" },
    { value: "bgra64le", label: "Bgra64le" },
    { value: "yvyu422", label: "Yvyu422" },
    { value: "gbrap", label: "Gbrap" },
    { value: "0rgb", label: "0rgb" },
    { value: "rgb0", label: "Rgb0" },
    { value: "0bgr", label: "0bgr" },
    { value: "bgr0", label: "Bgr0" },
    { value: "yuv420p12be", label: "Yuv420p12be" },
    { value: "yuv420p12le", label: "Yuv420p12le" },
    { value: "yuv420p14be", label: "Yuv420p14be" },
    { value: "yuv420p14le", label: "Yuv420p14le" },
    { value: "yuv422p12be", label: "Yuv422p12be" },
    { value: "yuv422p12le", label: "Yuv422p12le" },
    { value: "yuv422p14be", label: "Yuv422p14be" },
    { value: "yuv422p14le", label: "Yuv422p14le" },
    { value: "yuv444p12be", label: "Yuv444p12be" },
    { value: "yuv444p12le", label: "Yuv444p12le" },
    { value: "yuv444p14be", label: "Yuv444p14be" },
    { value: "yuv444p14le", label: "Yuv444p14le" },
    { value: "gbrp12be", label: "Gbrp12be" },
    { value: "gbrp12le", label: "Gbrp12le" },
    { value: "gbrp14be", label: "Gbrp14be" },
    { value: "gbrp14le", label: "Gbrp14le" },
    { value: "yuvj411p", label: "Yuvj411p" },
    { value: "yuv440p10le", label: "Yuv440p10le" },
    { value: "yuv440p10be", label: "Yuv440p10be" },
    { value: "yuv440p12le", label: "Yuv440p12le" },
    { value: "yuv440p12be", label: "Yuv440p12be" },
    { value: "ayuv64le", label: "Ayuv64le" },
    { value: "v216", label: "V216" },
];

export const TIMECODE_POSITION_ARRAY = [
    { value: "TOP_LEFT", label: "Top left" },
    { value: "TOP_CENTER", label: "Top center" },
    { value: "TOP_RIGHT", label: "Top right" },
    { value: "BOTTOM_LEFT", label: "Bottom left" },
    { value: "BOTTOM_CENTER", label: "Bottom center" },
    { value: "BOTTOM_RIGHT", label: "Bottom right" },
];

export const BURNED_TIMECODE_FONT = "dejavu/DejaVuSansMono.ttf";
export const BURNED_TIMECODE_FONTSIZE = 16;

export const MIN = 0;
export const MIN_DURATION = 1;
export const MIN_HEIGHT = 8;
export const MAX_CRF = 63;
export const MAX_HEIGHT = 2160;
export const MAX_START_POSITION = 360000;
export const MAX_AUDIO_CHANNELS = 256;
export const MAX_PASS = 3;

export const PRESET_TYPE_IMAGE = "IMAGE";
export const PRESET_TYPE_VIDEO = "VIDEO";
export const PRESET_TYPE_AUDIO = "AUDIO";

export const THUMBNAIL_PRESET_NAME = "Thumbnail";

export const WATERMARK_POSSIBLE_SCALES = [
    { label: "None", value: "NONE" },
    { label: "Stretch vertically", value: "STRETCH_HEIGHT", default: true },
    { label: "Stretch horizontally", value: "STRETCH_WIDTH" },
    { label: "Stretch to fill", value: "STRETCH_MANGLE" },
];

export const WATERMARK_POSSIBLE_POSITIONS = [
    { label: "Top left", value: "TOP_LEFT" },
    { label: "Top center", value: "TOP_CENTER" },
    { label: "Top right", value: "TOP_RIGHT" },
    { label: "Middle left", value: "MIDDLE_LEFT" },
    { label: "Center", value: "CENTER", default: true },
    { label: "Middle right", value: "MIDDLE_RIGHT" },
    { label: "Bottom left", value: "BOTTOM_LEFT" },
    { label: "Bottom center", value: "BOTTOM_CENTER" },
    { label: "Bottom right", value: "BOTTOM_RIGHT" },
];
