import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    formControl: {
        display: "flex",
        wrap: "nowrap",
        alignItems: "center"
    },
    formControlLabel: {
        marginLeft: 0
    }
}));
