import { types } from "mobx-state-tree";

import Request from "api/Request";
import SetUsbAclArguments from "api/security/acl/Types/SetUsbAclArguments";

const SetUsbAcl = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Acl",
    method: "set_usb_acl",
    arguments: types.maybe(SetUsbAclArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetUsbAcl
