import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import { Grid, Typography, Button, Table, TableHead, TableBody, TableRow, TableCell, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TableSortLabel from "components/TableSortLabel";
import Checkbox from "components/Checkbox";
import Dialog from "components/Dialog";
import TableCellWithIcon from "components/TableCellWithIcon/TableCellWithIcon";
import { GiB_IEC } from "const/diskSizeConst";
import { useStyles } from "./GrowPoolModal.styles";
import {
    DRIVE_NAME,
    DRIVE_MODEL,
    DRIVE_SIZE,
    GROWABLE_DISK_NAME,
    GROWABLE_DISK_SIZE,
    GROWABLE_DISK_RAID,
    GROWABLE_DISK_SIZE_AFTER,
} from "const/sortColumnConst";
import NoContentContainer from "../NoContentContainer";
import NoDiskGroupIcon from "assets/NoDiskGroupIcon";
import SsdIcon from "assets/SsdIcon";
import HddIcon from "assets/HddIcon";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import ComponentCheckbox from "components/Checkbox";
import useStoreByIp from "hooks/useStoreByIp";
import { useModal } from "hooks/useModal";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { useStore } from "hooks/useStore";

const GROW_POOL_MODAL_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_grow_pool_modal`;

const GrowPoolModal = ({ onOpen, onClose, onSubmit, ip, inProgress }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: { volumeDrawerStore },
    } = useStore();
    const { poolsStore } = useStoreByIp({ ip });

    const state = useLocalStore(() => ({
        get mainCheckboxStatus() {
            const checkedcheckedAvailableDisksLength = poolsStore?.checkedAvailableDisks?.length ?? 0;
            const availableDrivesLength = poolsStore.availableDrives?.length ?? 0;
            if (checkedcheckedAvailableDisksLength === availableDrivesLength) {
                return "checked";
            }
            if (checkedcheckedAvailableDisksLength !== 0 && checkedcheckedAvailableDisksLength < availableDrivesLength) {
                return "indeterminated";
            }
            return null;
        },
    }));

    useEffect(() => {
        poolsStore.clearCheckedAvailableDrives();
    }, []);

    const closeWithClear = () => {
        poolsStore.clearCheckedAvailableDrives();
        onClose();
    };

    const currentPool = poolsStore.currentPool;

    const unsavedChangesModal = useModal();

    const isCheckedShare = (driveName) => {
        return poolsStore.checkedAvailableDisks.some((pool) => pool === driveName);
    };

    const onCheckboxChange = (driveName) => () => {
        isCheckedShare(driveName)
            ? poolsStore.removeCheckedAvailableDrive(driveName)
            : poolsStore.addCheckedAvailableDrive(driveName);
    };

    const handleMainCheckbox = () => {
        switch (state.mainCheckboxStatus) {
            case "checked":
            case "indeterminated":
                poolsStore.sortedAvailableDrives.forEach((pool) => poolsStore.removeCheckedAvailableDrive(pool.driveName));
                break;
            default:
                poolsStore.sortedAvailableDrives.forEach((pool) => poolsStore.addCheckedAvailableDrive(pool.driveName));
                break;
        }
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const renderIcon = (type) => {
        switch (type) {
            case "ssd":
                return <SsdIcon />;
            case "hdd":
                return <HddIcon />;
            default:
                return <SsdIcon />;
        }
    };

    const onEnter = () => {
        unsavedChangesModal.close();
    };

    const closeIfNeeded = () => {
        if (poolsStore.checkedAvailableDisks.length) {
            unsavedChangesModal.open();
            return;
        }

        closeWithClear();
    };

    const changeSortingDrives = (column) => () => poolsStore.changeSortingDrives(column);
    const changeSortingGrowableDrives = (column) => () => poolsStore.changeSortingGrowableDrives(column);

    return (
        <Dialog
            title={t("disks_and_pools.grow_pool_dialog.title", {
                name: volumeDrawerStore.currentPoolNameWithPrefix,
            })}
            maxWidth={"md"}
            open={onOpen}
            inProgress={inProgress}
            onEnter={onEnter}
            onClose={closeIfNeeded}
            withoutPaddings
            withoutIconClose
            onSubmit={onSubmit}
            submitBtnLabel={t("disks_and_pools.grow_pool_dialog.ok_button")}
            submitBtnId={`${GROW_POOL_MODAL_ID_PREFIX}_submit`}
            submitBtn={
                !poolsStore.availableDrives.length && (
                    <Button
                        onClick={closeIfNeeded}
                        variant={"contained"}
                        color={"secondary"}
                        id={`${GROW_POOL_MODAL_ID_PREFIX}_close`}
                    >
                        {t("common.button.close")}
                    </Button>
                )
            }
            disableSubmit={poolsStore.availableDrives.length && !poolsStore.checkedAvailableDisks.length}
            buttons={
                !!poolsStore.availableDrives.length && (
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={closeWithClear}
                        id={`${GROW_POOL_MODAL_ID_PREFIX}_cancel`}
                    >
                        {t("common.button.cancel")}
                    </Button>
                )
            }
        >
            <Grid container className={classes.header}>
                <Typography variant={"subtitle2"}>{t("disks_and_pools.grow_pool_dialog.available_disks.title")}</Typography>
            </Grid>
            <Divider />
            {poolsStore.availableDrives.length ? (
                <>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell padding={"checkbox"}>
                                    <ComponentCheckbox
                                        indeterminate={state.mainCheckboxStatus === "indeterminated"}
                                        checked={state.mainCheckboxStatus === "checked"}
                                        onChange={handleMainCheckbox}
                                        id={`${GROW_POOL_MODAL_ID_PREFIX}_main_checkbox`}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={poolsStore.orderDrives}
                                        onClick={changeSortingDrives(DRIVE_NAME)}
                                        active={poolsStore.orderByDrives === DRIVE_NAME}
                                        id={`${GROW_POOL_MODAL_ID_PREFIX}_name_sort`}
                                    >
                                        {t("disks_and_pools.grow_pool_dialog.available_disks.table_header.disk")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={poolsStore.orderDrives}
                                        onClick={changeSortingDrives(DRIVE_MODEL)}
                                        active={poolsStore.orderByDrives === DRIVE_MODEL}
                                        id={`${GROW_POOL_MODAL_ID_PREFIX}_model_sort`}
                                    >
                                        {t("disks_and_pools.grow_pool_dialog.available_disks.table_header.model")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width={"20%"}>
                                    <TableSortLabel
                                        direction={poolsStore.orderDrives}
                                        onClick={changeSortingDrives(DRIVE_SIZE)}
                                        active={poolsStore.orderByDrives === DRIVE_SIZE}
                                        id={`${GROW_POOL_MODAL_ID_PREFIX}_size_sort`}
                                    >
                                        {t("disks_and_pools.grow_pool_dialog.available_disks.table_header.size")}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {poolsStore.sortedAvailableDrives.map((pool) => (
                                <TableRow key={`sortedAvailableDrive${pool.driveName}`}>
                                    <TableCell>
                                        <Checkbox
                                            onClick={stopPropagation}
                                            onChange={onCheckboxChange(pool.driveName)}
                                            checked={isCheckedShare(pool.driveName)}
                                            id={`${GROW_POOL_MODAL_ID_PREFIX}_${pool.driveName}_checkbox`}
                                        />
                                    </TableCell>
                                    <TableCellWithIcon className={classes.icon} icon={renderIcon(pool.driveType)}>
                                        {t("disks_and_pools.pools_card.pool_item.drive_title", {
                                            name: pool.driveName,
                                        })}
                                    </TableCellWithIcon>
                                    <TableCell>{pool.driveModel}</TableCell>
                                    <TableCell>{(pool.size / GiB_IEC.value).toFixed(1)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Grid container className={classes.header}>
                        <Typography variant={"subtitle2"}>
                            {t("disks_and_pools.grow_pool_dialog.growable_disks.title")}
                        </Typography>
                    </Grid>
                    <Divider />
                </>
            ) : (
                <NoContentContainer
                    title={t("disks_and_pools.grow_pool_dialog.available_disks.placeholder_title")}
                    content={t("disks_and_pools.grow_pool_dialog.available_disks.placeholder_content", {
                        name: currentPool && volumeDrawerStore.currentPoolNameWithPrefix,
                    })}
                    hideButton
                    icon={<NoDiskGroupIcon />}
                    id={`${GROW_POOL_MODAL_ID_PREFIX}_content_placeholder`}
                />
            )}
            {!!poolsStore.availableDrives.length &&
                (poolsStore.growableVolumes.length ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        direction={poolsStore.orderGrowableDrives}
                                        onClick={changeSortingGrowableDrives(GROWABLE_DISK_NAME)}
                                        active={poolsStore.orderByGrowableDrives === GROWABLE_DISK_NAME}
                                        id={`${GROW_POOL_MODAL_ID_PREFIX}_growable_name_sort`}
                                    >
                                        {t("disks_and_pools.grow_pool_dialog.growable_disks.table_header.name")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={poolsStore.orderGrowableDrives}
                                        onClick={changeSortingGrowableDrives(GROWABLE_DISK_SIZE)}
                                        active={poolsStore.orderByGrowableDrives === GROWABLE_DISK_SIZE}
                                        id={`${GROW_POOL_MODAL_ID_PREFIX}_growable_size_sort`}
                                    >
                                        {t("disks_and_pools.grow_pool_dialog.growable_disks.table_header.size")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        direction={poolsStore.orderGrowableDrives}
                                        onClick={changeSortingGrowableDrives(GROWABLE_DISK_RAID)}
                                        active={poolsStore.orderByGrowableDrives === GROWABLE_DISK_RAID}
                                        id={`${GROW_POOL_MODAL_ID_PREFIX}_growable_raid_sort`}
                                    >
                                        {t("disks_and_pools.grow_pool_dialog.growable_disks.table_header.raid_level")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width={"33%"}>
                                    <TableSortLabel
                                        direction={poolsStore.orderGrowableDrives}
                                        onClick={changeSortingGrowableDrives(GROWABLE_DISK_SIZE_AFTER)}
                                        active={poolsStore.orderByGrowableDrives === GROWABLE_DISK_SIZE_AFTER}
                                        id={`${GROW_POOL_MODAL_ID_PREFIX}_growable_size_after_sort`}
                                    >
                                        {t("disks_and_pools.grow_pool_dialog.growable_disks.table_header.size_after")}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {poolsStore.sortedGrowableVolumes.map((disk) => (
                                <TableRow key={`availableDisk${disk.pool}-${disk.volume}`}>
                                    <TableCell>
                                        {disk.pool}/{disk.volume}
                                    </TableCell>
                                    <TableCell>{(disk.defaultSize / GiB_IEC.value).toFixed(0)}</TableCell>
                                    <TableCell>
                                        {t("disks_and_pools.grow_pool_dialog.available_disks.row.cell.raid", {
                                            count: disk.raid,
                                        })}
                                    </TableCell>
                                    <TableCell>{disk.growableSize ? (disk.growableSize / GiB_IEC.value).toFixed() : 0}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <NoContentContainer
                        title={t("disks_and_pools.grow_pool_dialog.growable_disks.placeholder_title")}
                        hideButton
                        icon={<NoDiskGroupIcon />}
                        id={`${GROW_POOL_MODAL_ID_PREFIX}_growable_content_placeholder`}
                    />
                ))}
            <Divider />
            <UnsavedChangesDialog
                onConfirm={closeWithClear}
                onClose={unsavedChangesModal.close}
                open={unsavedChangesModal.isOpen}
            />
        </Dialog>
    );
};

export default observer(GrowPoolModal);
