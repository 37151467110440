import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    title: {
        opacity: 0.9,
        fontSize: 20,
        fontWeight: "bold",
        lineHeight: 1.2,
        letterSpacing: 0.2,
        marginBottom: theme.spacing(6),
    },
    message: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
    progress: {
        opacity: 1,
        whiteSpace: "pre-line",
        lineHeight: 2.3,
    },
    label: {
        fontStretch: "normal",
        lineHeight: 1.43,
        letterSpacing: "normal",
    },
    inputItem: {
        flex: 1,
    },
    input: {
        "& .MuiInputBase-input": {
            fontWeight: "400",
        },
    },
    usernameLabel: {},
    passwordLabel: {},
}));
