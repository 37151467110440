import React from "react";
import { Grid, Radio as MuiRadio } from "@material-ui/core";
import { useStyles } from "./Radio.styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import FormControlLabel from "components/FormControlLabel";

const Radio = ({ helperText, className, id, ...rest }) => {
    const classes = useStyles();
    return (
        <Grid className={className} container>
            <FormControlLabel control={<MuiRadio color={"primary"} id={id} />} {...rest} />
            {helperText && (
                <Typography className={clsx(classes.helperText, rest.disabled && classes.disabledHelperText)}>
                    {helperText}
                </Typography>
            )}
        </Grid>
    );
};

export default Radio;
