import React, { useContext } from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Context } from "store";
import { Grid, Button } from "@material-ui/core";
import createForm from "utils/createForm";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowTextInput from "components/RowTextInput";
import Select from "components/MobxForm/Select";
import { CPU_LOAD_LIMIT_ARRAY } from "const/presetsDefaultValueConst";

const SETUP_CPU_LIMIT_DIALOG_ID_PREFIX = `setup_cpu_limit_dialog`;

const SetupCPULimitDialog = ({ open, onClose, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${SETUP_CPU_LIMIT_DIALOG_ID_PREFIX}` : SETUP_CPU_LIMIT_DIALOG_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { indexingConfigStore },
    } = useContext(Context);

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        isSubmitted: false,
        inProgress: false,
        isUnsavedChangesDialogOpen: false,
        get previewCpu() {
            return CPU_LOAD_LIMIT_ARRAY.find(
                (el) => el.value === indexingConfigStore.proxySettingsStore?.data?.previewProxySettings.cpuLimitPercent
            ).value;
        },
        get editCpu() {
            return CPU_LOAD_LIMIT_ARRAY.find(
                (el) => el.value === indexingConfigStore.proxySettingsStore?.data?.editProxySettings.cpuLimitPercent
            ).value;
        },
    }));

    const updateForm = () => {
        state.form.$("previewCpu").set("default", state.previewCpu);
        state.form.$("editCpu").set("default", state.editCpu);
        state.form.reset();
        state.isUnsavedChangesDialogOpen = false;
        state.isSubmitted = false;
    };

    const onSubmit = async () => {
        state.inProgress = true;
        const res = await indexingConfigStore.setProxySettings({
            editProxySettings: {
                presetId: indexingConfigStore.proxySettingsStore?.data?.editProxySettings.presetId,
                cpuLimitPercent: state.form.$("editCpu").value,
            },
            previewProxySettings: {
                presetId: indexingConfigStore.proxySettingsStore?.data?.previewProxySettings.presetId,
                cpuLimitPercent: state.form.$("previewCpu").value,
            },
        });
        state.inProgress = false;
        res && (state.isSubmitted = true);
        res && indexingConfigStore.fetchProxySettings();
        return res;
    };

    const closeUnsavedChangesDialog = () => {
        state.isUnsavedChangesDialogOpen = false;
    };

    const openUnsavedChangesDialog = () => {
        state.isUnsavedChangesDialogOpen = true;
    };

    const closeIfNeeded = () => {
        state.form.isDefault || state.isSubmitted ? onClose() : openUnsavedChangesDialog();
    };

    return useObserver(() => (
        <Dialog
            onClose={closeIfNeeded}
            open={open}
            title={t("slingshot.index_proxy.cpu_limit_dialog.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            inProgress={state.inProgress}
            onSubmit={onSubmit}
            onEnter={updateForm}
            buttons={[
                <Button key="cancelBtn" onClick={closeIfNeeded} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>,
            ]}
        >
            <Grid container direction={"column"}>
                <RowTextInput
                    label={t("slingshot.index_proxy.preview_settings.label.cpu_usage")}
                    control={
                        <Select
                            field={state.form.$("previewCpu")}
                            options={CPU_LOAD_LIMIT_ARRAY}
                            id={`${idPrefix}_preview_cpu`}
                        />
                    }
                />
                <RowTextInput
                    label={t("slingshot.index_proxy.edit_settings.label.cpu_usage")}
                    control={
                        <Select field={state.form.$("editCpu")} options={CPU_LOAD_LIMIT_ARRAY} id={`${idPrefix}_edit_cpu`} />
                    }
                />
            </Grid>
            <UnsavedChangesDialog
                onConfirm={onClose}
                onClose={closeUnsavedChangesDialog}
                open={state.isUnsavedChangesDialogOpen}
            />
        </Dialog>
    ));
};

export default SetupCPULimitDialog;
