import { types } from "mobx-state-tree";

const STATE_ENUM_ARRAY = ["UNKNOWN", "PENDING", "ONLINE", "OFFLINE", "ERROR"];
const HEALTH_ENUM_ARRAY = ["UNKNOWN", "QUEUED", "BUILDING", "GROW", "GOOD", "CORRUPT", "DEGRADED", "REMOVING"];

const LogicalDiskType = types
    .model({
        name: types.string,
        pool: types.string,
        size: types.number,
        state: types.maybe(types.enumeration(STATE_ENUM_ARRAY)),
        health: types.maybe(types.enumeration(HEALTH_ENUM_ARRAY))
    })
    .views(self => ({
        get fullName() {
            return `${self.pool}/${self.name}`;
        }
    }));
export default LogicalDiskType;
