import { types } from "mobx-state-tree";

import VolumeId from "api/volume/Types/VolumeId";

const SetVolumesOnlineArguments = types.model({
    volumeIds: types.array(VolumeId),
    online: types.boolean
});

export default SetVolumesOnlineArguments;
