import React from "react";
import TrashIcon from "assets/TrashIcon";
import { useStyles } from "./DndTrash.styles";
import { useDrop } from "react-dnd";
import { Fab, Grid } from "@material-ui/core";
import clsx from "clsx";
import PlusIcon from "assets/PlusIcon";
import { useHistory } from "react-router-dom";
import { URL_DASHBOARD } from "routes";

const DndTrash = ({ accept, onDrop, className, withPlusButton }) => {
    const history = useHistory();
    const [{ render, isOver }, drop] = useDrop({
        accept,
        drop: item => {
            onDrop(item);
        },
        collect: monitor => ({
            render: monitor.canDrop(),
            isOver: monitor.isOver()
        })
    });
    const classes = useStyles({ isOver });

    const onPlusClick = () => {
        history.push(URL_DASHBOARD);
    };

    return (
        <>
            {render ? (
                <Grid className={clsx(className)} container>
                    <Grid item className={classes.fabContainer}>
                        <Fab ref={drop} className={classes.fab}>
                            <TrashIcon />
                        </Fab>
                    </Grid>
                </Grid>
            ) : (
                <>
                    {withPlusButton && (
                        <Grid className={clsx(className)} container>
                            <Fab ref={drop} onClick={onPlusClick} disableRipple className={classes.fabPlus}>
                                <PlusIcon />
                            </Fab>
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};

export default DndTrash;
