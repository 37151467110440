import { types } from "mobx-state-tree";

import Request from "api/Request";
import DetachArguments from "api/bcache/Types/DetachArguments";

const DetachDrive = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Cache",
    method: "detach_drive",
    arguments: types.maybe(DetachArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default DetachDrive;
