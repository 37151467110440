import React from "react";
import { useTranslation } from "react-i18next";
import { Paper, Grid, ButtonBase, Typography } from "@material-ui/core";
import { useStyles } from "./SystemMenu.style";
import { useHistory } from "react-router-dom";
import TimeIcon from "assets/TimeIcon";
import OptionsIcon from "assets/OptionsIcon";
import WorldIcon from "assets/WorldIcon";
import DisksIcon from "assets/DisksIcon";
import AdvancedIcon from "assets/AdvancedIcon";
import DocIcon from "assets/DocIcon";
import OffIcon from "assets/OffIcon";
import SpeedIcon from "assets/SpeedIcon";
import {
    URL_HARDWARE_ACTIVATION,
    URL_TIME_SETTINGS,
    URL_NETWORK,
    URL_DISKS,
    URL_ADVANCED,
    URL_ADMIN_SETTINGS,
    URL_FILE_NAVIGATOR,
    URL_POWER_AND_UPS,
    URL_HARDWARE_PROFILE,
    URL_AUDIT,
    URL_FIREWALL,
    URL_WIREGUARD
} from "routes";
import clsx from "clsx";

const SystemMenu = ({ afterClickCallback }) => {
    const classes = useStyles();
    const history = useHistory();

    const onClick = value => () => {
        history.push(value);
        afterClickCallback();
    };

    const SystemMenuButton = ({ titleKey, value, icon }) => {
        const { t } = useTranslation();

        return (
            <Grid item container>
                <ButtonBase color={"primary"} onClick={onClick(value)} className={classes.buttonBase}>
                    <Grid container spacing={4} alignItems="center">
                        {icon && (
                            <Grid className={classes.icon} item>
                                {icon}
                            </Grid>
                        )}
                        <Grid item className={classes.alignLeft}>
                            <Typography
                                color={"textPrimary"}
                                className={clsx(!icon && classes.iconlessButtonTitle, classes.buttonTitle)}
                                variant={"button"}
                            >
                                {t(titleKey)}
                            </Typography>
                        </Grid>
                    </Grid>
                </ButtonBase>
            </Grid>
        );
    };

    return (
        <Paper className={classes.paper} square>
            <Grid container direction="column" className={classes.container} wrap="nowrap">
                <Grid item container className={classes.buttonsSection} direction={"column"}>
                    <SystemMenuButton
                        titleKey={"side_menu.system_menu.time_settings"}
                        value={URL_TIME_SETTINGS}
                        icon={<TimeIcon />}
                    />
                    <SystemMenuButton
                        titleKey={"side_menu.system_menu.hardware_activation"}
                        value={URL_HARDWARE_ACTIVATION}
                        icon={<OptionsIcon />}
                    />
                    <SystemMenuButton titleKey={"side_menu.system_menu.network"} value={URL_NETWORK} icon={<WorldIcon />} />
                    <SystemMenuButton
                        titleKey={"side_menu.system_menu.pools_logical_disks"}
                        value={URL_DISKS}
                        icon={<DisksIcon />}
                    />
                    <SystemMenuButton titleKey={"side_menu.system_menu.advanced"} value={URL_ADVANCED} icon={<AdvancedIcon />} />
                    <SystemMenuButton titleKey={"file_navigator.title"} value={URL_FILE_NAVIGATOR} icon={<DocIcon />} />
                    <SystemMenuButton
                        titleKey={"side_menu.system_menu.power_and_ups"}
                        value={URL_POWER_AND_UPS}
                        icon={<OffIcon />}
                    />
                    <SystemMenuButton
                        titleKey={"side_menu.system_menu.hardware_profile"}
                        value={URL_HARDWARE_PROFILE}
                        icon={<SpeedIcon />}
                    />
                </Grid>
                <Grid item container direction={"column"}>
                    <SystemMenuButton titleKey={"side_menu.system_menu.admin_settings"} value={URL_ADMIN_SETTINGS} />
                    <SystemMenuButton titleKey={"side_menu.system_menu.firewall"} value={URL_FIREWALL} />
                    <SystemMenuButton titleKey={"side_menu.system_menu.user_auditing"} value={URL_AUDIT} />
                    <SystemMenuButton titleKey={"side_menu.menu_tabs.wireguard"} value={URL_WIREGUARD} />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default SystemMenu;
