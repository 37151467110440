import { makeStyles } from "@material-ui/core/styles";
import { SEARCH_PLACEHOLDER_COLOR } from "const/styleConst";
import hexToRgba from "utils/hexToRgba";
import { CONNECTED, CONNECTING, NOT_CONNECTED } from "const/ldapStatuses";

export const useStyles = makeStyles((theme) => {
    const getColor = (status) => {
        switch (status) {
            case CONNECTED:
                return theme.palette.success.main;
            case CONNECTING:
                return theme.palette.warning.main;
            case NOT_CONNECTED:
                return theme.palette.error.main;
            default:
                return theme.palette.primary.main;
        }
    };

    return {
        container: {
            padding: theme.spacing(4, 6),
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            alignContent: "center",
            flexDirection: "row",
            borderRadius: theme.spacing(2),
        },
        inputContainer: {
            flex: "1 0",
        },

        input: {
            "& input::placeholder": {
                color: SEARCH_PLACEHOLDER_COLOR,
                fontWeight: 500,
                opacity: 1,
            },
            "& input:hover::placeholder": {
                opacity: 1,
            },
        },
        closeIcon: {
            "& svg path": {
                fill: hexToRgba(SEARCH_PLACEHOLDER_COLOR, 0.8),
            },
        },
        searchIcon: {
            "& svg path": {
                fill: theme.palette.primary.main,
            },
        },
        searchIconContainer: {
            width: theme.spacing(7),
        },
        selectContainer: {
            width: 186,
            paddingRight: theme.spacing(4),
        },
        oval: {
            width: 6,
            height: 6,
            borderRadius: "50%",
            backgroundColor: ({ status }) => getColor(status),
        },
        ldapButton: {
            backgroundColor: ({ status }) => hexToRgba(getColor(status), 0.1),
            color: ({ status }) => getColor(status),
            "&:hover": {
                backgroundColor: ({ status }) => hexToRgba(getColor(status), 0.2),
                boxShadow: "none",
            },
        },
    };
});
