import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: "100%",
    },
    loginContainer: {
        background: theme.palette.background.paper,
        borderRadius: theme.spacing(2),
        margin: "auto",
        width: 376,
        height: 344,
        padding: theme.spacing(10),
        paddingBottom: 0,
    },
    label: {
        fontStretch: "normal",
        lineHeight: 1.43,
        letterSpacing: "normal",
        marginBottom: theme.spacing(2),
    },
    usernameLabel: {
        marginTop: theme.spacing(6),
    },
    passwordLabel: {
        marginTop: theme.spacing(4),
    },
    acceptText: {
        marginLeft: theme.spacing(-3),
        color: theme.palette.primary.main,
        marginRight: "auto",
    },
    controlContainer: {
        marginTop: theme.spacing(6),
        justifyContent: "space-between",
        alignItems: "center",
    },
    disabledButton: {
        "&&": {
            opacity: 0.4,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
    },
    input: {
        minWidth: "100px",
        "& .MuiInputBase-input": {
            fontWeight: "400",
        },
    },
    button: {
        position: "relative",
        "& span": {
            color: ({ hideLabel }) => (hideLabel ? "transparent" : ""),
        },
    },
    icon: {
        position: "absolute",
    },
}));

export default useStyles;
