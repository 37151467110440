import { makeStyles } from "@material-ui/core/styles";
import { GREY_BLUE } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(6, 4),
        position: "relative",
        backgroundColor: theme.palette.poolShareHeader.background,
    },
    icon: {
        position: "absolute",
        left: 0,
        top: theme.spacing(4),
        width: theme.spacing(1),
        height: theme.spacing(8),
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
        backgroundColor: theme.palette.primary.main
    },
    diskPictureContainer: {
        display: "flex"
    },
    diskPicture: {
        width: theme.spacing(22),
        height: theme.spacing(22)
    },
    titleContainer: {
        flex: 1,
        padding: theme.spacing(0, 2, 0, 4)
    },
    progressBar: {
        width: "100%"
    },
    successText: {
        color: theme.palette.success.main
    },
    sizeItem: {
        marginRight: theme.spacing(2)
    },
    raidLabel: {
        padding: theme.spacing(1, 2),
        backgroundColor: theme.palette.grey["A100"],
        borderRadius: theme.spacing(1)
    },
    raidLabelText: {
        color: GREY_BLUE
    },
    controlContainer: {
        alignItems: "center",
        width: "max-content"
    }
}));
