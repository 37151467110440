import { types } from "mobx-state-tree";

import ActiveDirectorySettings from "api/security/users/Types/ActiveDirectorySettings";

const ActiveDirectoryStatus = types.model({
    enabled: types.boolean,
    settings: types.maybe(ActiveDirectorySettings),
    status: types.string
});

export default ActiveDirectoryStatus;
