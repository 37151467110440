import React from "react";
import DataCard from "components/DataCard";
import { Typography, Grid, Box, List } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStore } from "hooks/useStore";
import { ADMIN } from "const/userRolesConst";

const SystemServiceWarningDialog = () => {
    const { t } = useTranslation();
    const {
        store: { postgresStore, authStore }
    } = useStore();

    return (
        <DataCard title={t("slingshot.system_service_warning.title")} headerDivider autoHeight emptyPadding>
            <Box py={20} px={6}>
                <Grid container direction={"column"} alignItems={"center"} justify={"center"}>
                    <Grid item>
                        {authStore.role === ADMIN ? (
                            <React.Fragment>
                                <Typography>{t("slingshot.system_service_warning.text")}</Typography>
                                <List>
                                    <Typography>
                                        1.{" "}
                                        {t("slingshot.system_service_warning.text.first", {
                                            shareName: postgresStore.databaseLocation.share
                                        })}
                                    </Typography>
                                    <Typography>2. {t("slingshot.system_service_warning.text.second")}</Typography>
                                    <Typography>3. {t("slingshot.system_service_warning.text.third")}</Typography>
                                </List>
                            </React.Fragment>
                        ) : (
                            <Typography>{t("slingshot.system_service_warning.text.users")}</Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </DataCard>
    );
};

export default SystemServiceWarningDialog;
