import React, { useEffect } from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { observer, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { useStyles } from "./FilesDrawer.styles";
import { MiB_IEC } from "const/diskSizeConst";
import { FILE_NAVIGATOR_DATE_FORMAT, SERVER_FILE_NAVIGATOR_DATE_FORMAT } from "const";
import moment from "moment";
import { equalsIgnoreCase } from "utils/equalsIgnoreCase";
import ChangeOwnerModal from "../ChangeOwnerModal";
import EditAclModal from "../EditAclModal";
import FolderIcon from "assets/FolderIcon";
import DocIcon from "assets/DocIcon";
import TrashIcon from "assets/TrashIcon";
import IscsiIcon from "assets/IscsiIcon";
import SharingIcon from "assets/SharingIcon";
import WarningDialog from "../WarningDialog";
import { useModalCombine } from "hooks/useModalCombine";
import { useFileNavigatorStoreCommon } from "pages/FileNavigator/FileNavigator.hooks";
import useStoreByIp from "hooks/useStoreByIp";
import { useStore } from "hooks/useStore";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const drawerName = "filesDrawer";
const FilesDrawer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { uiStore },
    } = useStore();
    const fileNavigatorCommon = useFileNavigatorStoreCommon();
    const { fileStore, aclStore } = useStoreByIp({ ip: fileNavigatorCommon.currentEvoIp });

    const state = useLocalStore(() => ({
        isOwnerLoading: false,
        isDeletionInProgress: false,
    }));

    useEffect(() => {
        if (fileStore.currentFileName && fileNavigatorCommon.selectedFilesLength === 0) {
            (async () => {
                state.isOwnerLoading = true;
                await aclStore.getOwner({
                    shareName: fileStore.currentShareName,
                    path: fileStore.currentFilePath,
                });
                state.isOwnerLoading = false;
            })();
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, fileStore.removeCurrentFile);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [fileStore.currentFileName, fileNavigatorCommon.selectedFilesLength]);

    const deleteFile = async () => {
        state.isWarningDialogOpen = false;
        state.isDeletionInProgress = true;
        const res = await fileStore.deleteFiles({
            shareName: fileStore.currentShareName,
            filesOnShare: [fileStore.currentFilePath],
        });
        state.isDeletionInProgress = false;

        if (res !== null) {
            const currentFileDir = fileStore.currentFilePath.split("/").slice(0, -1).join("/");

            fileStore.listFiles({
                shareName: fileStore.currentShareName,
                dirOnShare: currentFileDir,
            });
        }

        if (res) {
            fileStore.removeCurrentFile();
        }
    };

    const { changeOwnerModal, editAclModal, warningModal } = useModalCombine([
        "changeOwnerModal",
        "editAclModal",
        "warningModal",
    ]);

    const getOwner = () => {
        if (state.isOwnerLoading) {
            return <CircularProgress size={16} />;
        } else {
            return (
                <Typography className={classes.textInfo}>
                    {aclStore.owner?.name ? aclStore.owner.name : t("file_navigator.bulk_bar.label.unknown_owner")}
                </Typography>
            );
        }
    };

    const getFileIcon = () => {
        if (equalsIgnoreCase(fileStore.currentFile?.type, "directory")) {
            return <FolderIcon />;
        } else if (equalsIgnoreCase(fileStore.currentFile?.type, "file")) {
            return <DocIcon />;
        }
        return <DocIcon />;
    };

    const getTypeTranslation = (type) => {
        if (equalsIgnoreCase(type, "directory")) {
            return t("recycle_bin.type.folder");
        } else if (equalsIgnoreCase(type, "file")) {
            return t("recycle_bin.type.file");
        }
        return t("recycle_bin.type.file");
    };

    const renderDetails = observer(() => {
        return (
            <Grid container direction={"column"} className={classes.detailsContainer} spacing={4}>
                {fileStore.currentFile && (
                    <>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("file_navigator.bulk_bar.label.name")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>{fileStore.currentFile.name}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("file_navigator.bulk_bar.label.owner")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {getOwner()}
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("file_navigator.bulk_bar.label.date_modified")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>
                                    {fileStore.currentFile.modifiedTime &&
                                        moment(fileStore.currentFile.modifiedTime, SERVER_FILE_NAVIGATOR_DATE_FORMAT)
                                            .utc(true)
                                            .format(getLanguageDateFormat(FILE_NAVIGATOR_DATE_FORMAT))}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("file_navigator.bulk_bar.label.kind")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>
                                    {getTypeTranslation(fileStore.currentFile.type)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("file_navigator.bulk_bar.label.size")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>{`${(fileStore.currentFile.size / MiB_IEC.value).toFixed(
                                    1
                                )} ${MiB_IEC.unit}`}</Typography>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    });

    const renderActions = observer(() => {
        return (
            <Grid direction={"column"} container>
                <BulkBarFlatButton onClick={changeOwnerModal.open} icon={<IscsiIcon />}>
                    {t("file_navigator.bulk_bar.button.change_owner")}
                </BulkBarFlatButton>
                <BulkBarFlatButton onClick={editAclModal.open} icon={<SharingIcon />}>
                    {t("file_navigator.bulk_bar.button.edit_acl")}
                </BulkBarFlatButton>
                <BulkBarFlatButton
                    inProgress={state.isDeletionInProgress}
                    errorColor
                    onClick={warningModal.open}
                    icon={<TrashIcon />}
                >
                    {t("common.button.delete")}
                </BulkBarFlatButton>
            </Grid>
        );
    });

    return (
        <DetailsActionsDrawer
            title={fileStore.currentFileNameWithPrefix}
            icon={getFileIcon()}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={fileStore.removeCurrentFile}
            drawerName={drawerName}
            noWrapHeader={false}
            modals={
                <>
                    <ChangeOwnerModal open={changeOwnerModal.isOpen} onClose={changeOwnerModal.close} />
                    <EditAclModal open={editAclModal.isOpen} onClose={editAclModal.close} />
                    <WarningDialog open={warningModal.isOpen} onClose={warningModal.close} submit={deleteFile} count={1} />
                </>
            }
        />
    );
};

export default observer(FilesDrawer);
