import React from "react";
import { useStyles } from "./FormControlLabel.styles";
import { FormControlLabel as MuiFormControlLabel } from "@material-ui/core";
import clsx from "clsx";

const FormControlLabel = ({ className, ...rest }) => {
    const classes = useStyles();

    return <MuiFormControlLabel className={clsx(classes.label, className)} {...rest} />;
};

export default FormControlLabel;
