import React, { useEffect, useMemo } from "react";
import { Grid, Button, Typography, DialogTitle, ButtonBase, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, observer } from "mobx-react";
import Dialog from "components/Dialog";
import { useStyles } from "./EditSecondaryAdminsModal.styles";
import createFields from "./createFields";
import createForm from "utils/createForm";
import SearchLine from "./components/SearchLine";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import LocalUserIcon from "assets/LocalUserIcon";
import TrashIcon from "assets/TrashIcon";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import CloseIcon from "assets/CloseIcon";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { autorun } from "mobx";

const ACCESS_CREDENTIALS_DIALOG_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_access_credentials_dialog`;

const AccessCredentialsDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { userStore },
    } = useStore();

    const state = useLocalStore(() => ({
        inProgress: false,
        form: createForm({
            fields: createFields(),
        }),
        selectedUsers: [],
        selectedUsersInitiate: [],
        isSubmitted: false,
    }));

    const unsavedChangesModal = useModal();

    useEffect(() => {
        state.selectedUsers = [];

        autorun(() => {
            if (userStore.secondaryAdmins) {
                state.selectedUsers = userStore.secondaryAdmins.map((admin) => ({ name: admin.name, domain: admin.domain }));
            }
        });
    }, []);

    const onSubmit = async () => {
        const payload = {
            users: state.selectedUsers.map((user) => ({ name: user.name, domain: user.domain })),
        };
        const res = await userStore.setSecondaryAdmins(payload);

        if (res) {
            state.isSubmitted = true;
        }

        return res;
    };

    /**
     * @param {string[]} user
     */
    const onAdd = async (users) => {
        const trustedDomains = await userStore.getTustedDomains();
        Array.from(users, (user) => state.selectedUsers.unshift({ domain: trustedDomains.data[0], name: user }));
    };

    const onEnter = () => {
        state.form.reset();
        state.inProgress = false;
        state.isSubmitted = false;
        unsavedChangesModal.close();
        state.selectedUsersInitiate = state.selectedUsers?.map((el) => el.name);
    };

    const selectUser = (name, domain) => {
        if (isUserAlreadySelected(name)) {
            removeAdmin(name)();
            return;
        }

        state.selectedUsers.unshift({ domain, name });
    };

    const isUserAlreadySelected = (name) => state.selectedUsers.some((user) => user.name === name);

    const removeAdmin = (name) => () => {
        const adminIndex = state.selectedUsers.findIndex((user) => user.name === name);

        if (adminIndex !== -1) {
            state.selectedUsers.splice(adminIndex, 1);
        }
    };

    const onExited = () => {
        state.selectedUsers = userStore.secondaryAdmins?.map((admin) => ({ name: admin.name, domain: admin.domain })) || [];
    };

    const selectedUsers = useMemo(() => state.selectedUsers.map((el) => el.name).join(), [state.selectedUsers]);

    const closeIfNeeded = () => {
        if (selectedUsers === state.selectedUsersInitiate.join() || state.isSubmitted) {
            onClose();
            return;
        }

        unsavedChangesModal.open();
    };

    return (
        <Dialog
            onEnter={onEnter}
            maxWidth={"md"}
            onSubmit={onSubmit}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${ACCESS_CREDENTIALS_DIALOG_ID_PREFIX}_save`}
            open={open}
            withoutPaddings
            inProgress={state.inProgress}
            onClose={closeIfNeeded}
            onExited={onExited}
            buttons={
                <Button
                    onClick={onClose}
                    variant={"contained"}
                    color={"secondary"}
                    id={`${ACCESS_CREDENTIALS_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
            header={
                <>
                    <DialogTitle>
                        <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container>
                            <Grid item>{t("admin_settings.secondary_admin.edit_admins_modal.title")}</Grid>
                            <Grid item>
                                <CardHeaderControlButton onClick={closeIfNeeded}>
                                    <ButtonBase id={`${ACCESS_CREDENTIALS_DIALOG_ID_PREFIX}_close_if_needed`}>
                                        <CloseIcon />
                                    </ButtonBase>
                                </CardHeaderControlButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Divider />
                    <DialogTitle>
                        <Grid item container wrap={"nowrap"} alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography className={classes.labelText}>
                                    {t("admin_settings.secondary_admin.edit_admins_modal.search_line.label")}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <SearchLine
                                    form={state.form}
                                    isOptionChecked={isUserAlreadySelected}
                                    onSelect={selectUser}
                                    id={`${ACCESS_CREDENTIALS_DIALOG_ID_PREFIX}_search_line`}
                                    onAdd={onAdd}
                                />
                            </Grid>
                        </Grid>
                    </DialogTitle>
                </>
            }
        >
            <Grid container direction={"column"}>
                {state.selectedUsers.length > 0 ? (
                    <Grid container direction={"column"}>
                        {state.selectedUsers.map((admin) => (
                            <Grid
                                key={admin.name}
                                className={classes.adminRow}
                                container
                                wrap={"nowrap"}
                                justify={"space-between"}
                                alignItems={"center"}
                            >
                                <Grid container spacing={2} alignItems={"center"} wrap={"nowrap"} justify={"flex-start"}>
                                    <Grid item>
                                        <Grid container alignItems={"center"}>
                                            <LocalUserIcon />
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Typography noWrap>{admin.name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        className={classes.trashIconCell}
                                        onClick={removeAdmin(admin.name)}
                                        container
                                        alignItems={"center"}
                                    >
                                        <TrashIcon />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder message={t("admin_settings.secondary_admin.placeholder")} />
                )}
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    );
};

export default observer(AccessCredentialsDialog);
