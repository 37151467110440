import React, { useCallback, useContext, useEffect } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import DataCard from "components/DataCard";
import { useStyles } from "./LoopbackTestCard.styles";
import { useTranslation } from "react-i18next";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { LOOPBACK_TEST_CARD } from "const/widgetNameConst";
import { Context } from "store";
import { useLocalStore, useObserver } from "mobx-react-lite";
import AnimatedSubmitButton from "components/AnimatedSubmitButton/AnimatedSubmitButton";
import createForm from "utils/createForm";
import createFields from "./createFields";
import Select from "components/MobxForm/Select";
import RowTextInput from "components/RowTextInput/RowTextInput";
import { TestStatus } from "const/selfTestsConst";
import NetTestLastResultsDialog from "../NetTestLastResultsDialog/NetTestLastResultsDialog";

const LoopbackTestCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        store: { supportInfoStore, ethernetPortsStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields({}) }),
        isDialogOpen: false,
        interfacesValues: [],

        get isStartButtonShown() {
            return (
                supportInfoStore.networkSelfTestResult?.status === TestStatus.FINISHED ||
                supportInfoStore.networkSelfTestResult?.status === TestStatus.FAILED ||
                supportInfoStore.networkSelfTestResult?.status === TestStatus.NOTSTARTED
            );
        },

        get isInProgressShown() {
            return (
                supportInfoStore.networkSelfTestResult?.status === TestStatus.STARTED ||
                supportInfoStore.networkSelfTestResult?.status === TestStatus.RUNNING
            );
        },

        get isTestFinished() {
            return supportInfoStore.networkSelfTestResult?.status === TestStatus.FINISHED;
        },

        get THREADS_COUNT_VALUES() {
            return Array.from({ length: 16 }, (_, i) => i + 1);
        },
        get TEST_DURATION_VALUES() {
            return [
                { label: t("support.self_test.net.seconds", { count: 10 }), value: 10 },
                { label: t("support.self_test.net.seconds", { count: 30 }), value: 30 },
                { label: t("support.self_test.net.minutes", { count: 1 }), value: 1 * 60 },
                { label: t("support.self_test.net.minutes", { count: 5 }), value: 5 * 60 },
                { label: t("support.self_test.net.minutes", { count: 10 }), value: 10 * 60 },
                { label: t("support.self_test.net.minutes", { count: 30 }), value: 30 * 60 },
                { label: t("support.self_test.net.hours", { count: 1 }), value: 1 * 60 * 60 }
            ];
        }
    }));

    useEffect(() => {
        supportInfoStore.fetchNetworkTestResult();
        ethernetPortsStore.fetchEthernetPorts();
        state.form.$("sender").set("default", "eth0");
        state.form.$("receiver").set("default", "eth1");
        state.form.$("test_duration").set("default", 30);
        state.form.$("threads").set("default", 8);
        state.form.reset();
    }, []);

    const openDialog = useCallback(() => {
        state.isDialogOpen = true;
    });
    const closeDialog = useCallback(() => {
        state.isDialogOpen = false;
    });

    // Callbacks
    const startNetworkTest = useCallback(() => {
        const res = supportInfoStore.startNetworkTest({
            senderIface: state.form.$("sender").value,
            receiverIface: state.form.$("receiver").value,
            durationInSeconds: state.form.$("test_duration").value,
            threadsCount: state.form.$("threads").value
        });
        res && (state.isSubmitted = true);
        return res;
    });

    const stopNetworkTest = useCallback(() => {
        const res = supportInfoStore.stopNetworkTest();
        res && (state.isSubmitted = true);
        return res;
    });

    return useObserver(() => (
        <DataCard
            title={t("support.self_test.loopback.title")}
            headerDivider
            autoHeight
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={LOOPBACK_TEST_CARD} />
                </MoreMenu>
            }
        >
            <Grid container spacing={4} direction={"column"}>
                <RowTextInput
                    leftXs={4}
                    rightXs={8}
                    control={<Select options={ethernetPortsStore.netTestInterfacesValues} field={state.form.$("sender")} />}
                    label={t("support.self_test.loopback.sender")}
                />
                <RowTextInput
                    leftXs={4}
                    rightXs={8}
                    control={<Select options={ethernetPortsStore.netTestInterfacesValues} field={state.form.$("receiver")} />}
                    label={t("support.self_test.loopback.receiver")}
                />
                <RowTextInput
                    leftXs={4}
                    rightXs={8}
                    control={<Select options={state.TEST_DURATION_VALUES} field={state.form.$("test_duration")} />}
                    label={t("support.self_test.loopback.length")}
                />
                <RowTextInput
                    leftXs={4}
                    rightXs={8}
                    control={<Select options={state.THREADS_COUNT_VALUES} field={state.form.$("threads")} />}
                    label={t("support.self_test.loopback.threads")}
                />
            </Grid>
            <Grid item container spacing={4}>
                {state.isStartButtonShown ? (
                    <Grid item>
                        <AnimatedSubmitButton submit={startNetworkTest} label={t("common.button.start")} />
                    </Grid>
                ) : (
                    <Grid item>
                        <AnimatedSubmitButton submit={stopNetworkTest} label={t("common.button.stop")} />
                    </Grid>
                )}
                {state.isInProgressShown && (
                    <Grid item className={classes.lastResultBtnContainer}>
                        <Typography>{t("support.self_test.net.in_progress")}</Typography>
                    </Grid>
                )}
                {state.isTestFinished && (
                    <Grid item className={classes.lastResultBtnContainer}>
                        <Button onClick={openDialog} variant={"contained"} color={"secondary"}>
                            {t("common.button.last_results")}
                        </Button>
                    </Grid>
                )}
            </Grid>
            <NetTestLastResultsDialog onClose={closeDialog} open={state.isDialogOpen} />
        </DataCard>
    ));
};

export default LoopbackTestCard;
