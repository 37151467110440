import React, { useContext } from "react";
import { Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react";
import { Context } from "store";
import Dialog from "components/Dialog";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import localizations from "./PromptToDownloadCertificateModal.localizations";
import LocalizationTypography from "components/LocalizationTypography/LocalizationTypography";

const PROMPT_TO_DOWNLOAD_CERTIFICATE_MODAL_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_prompt_to_download_certificate`;

const PromptToDownloadCertificateModal = ({ open, onClose }) => {
    const { t } = useTranslation();

    const {
        store: { webserverStore, certificateStore },
    } = useContext(Context);

    const exportCertificate = async () => {
        const res = await certificateStore.exportCertificate();
        res && onClose();
    };

    return useObserver(() => (
        <Dialog
            maxWidth={"md"}
            open={open}
            onClose={onClose}
            title={t(localizations.promptToDownloadCertificate.titleKey)}
            submitBtnLabel={t("common.button.download")}
            submitBtnId={`${PROMPT_TO_DOWNLOAD_CERTIFICATE_MODAL_ID_PREFIX}_download`}
            onSubmit={exportCertificate}
            disableSubmit={!webserverStore.configInfo}
            buttons={[
                <Button
                    key={"cancelBtn"}
                    onClick={onClose}
                    variant={"contained"}
                    color={"secondary"}
                    id={`${PROMPT_TO_DOWNLOAD_CERTIFICATE_MODAL_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>,
            ]}
        >
            <Grid item>
                <LocalizationTypography paragraphs={localizations.promptToDownloadCertificate.paragraphs} />
            </Grid>
        </Dialog>
    ));
};

export default PromptToDownloadCertificateModal;
