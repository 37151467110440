import { types } from "mobx-state-tree";

import NetServicesAdvertising from "api/net/Types/NetServicesAdvertising";

const NetServicesAdvertisingResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(NetServicesAdvertising)
});

export default NetServicesAdvertisingResult;
