import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography, Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";

const WARNING_DELETION_DIALOG_ID_PREFIX = `warning_deletion_dialog`;

const WarningDeletionDialog = ({ open, onClose, submit, content, title, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${WARNING_DELETION_DIALOG_ID_PREFIX}` : WARNING_DELETION_DIALOG_ID_PREFIX;
    const { t } = useTranslation();

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={title}
            withoutIconClose
            withoutDividers
            buttons={
                <Button onClick={onClose} variant={"contained"} color={"secondary"} id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
            submitBtn={
                <Button onClick={submit} variant={"contained"} color={"primary"} id={`${idPrefix}_delete`}>
                    {t("common.button.ok_delete")}
                </Button>
            }
        >
            <Grid container direction={"column"} spacing={5}>
                <Grid item>
                    <Typography>{content}</Typography>
                </Grid>
                <Grid item>
                    <Typography>{t("slingshot_page.tabs.settings.delete_alias.question")}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default WarningDeletionDialog;
