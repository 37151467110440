import React, { useContext, useEffect } from "react";
import { useObserver } from "mobx-react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import DataCard from "components/DataCard";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import MoreMenu from "components/MoreMenu";
import TableSortLabel from "components/TableSortLabel";
import { RAID_CONTROLLERS_CARD } from "const/widgetNameConst";
import {
    HARDWARE_PROFILE_RAID_CONTROLLER,
    HARDWARE_PROFILE_RAID_SERIAL_NUMBER,
    HARDWARE_PROFILE_RAID_MODEL
} from "const/sortColumnConst";

const RAIDControllersCard = () => {
    const { t } = useTranslation();

    const {
        store: { hardwareProfileStore }
    } = useContext(Context);
    useEffect(() => {
        hardwareProfileStore.fetchRaidControllersInfo();
    }, []);
    const changeSorting = column => () => hardwareProfileStore.changeSortingRaid(column);

    return useObserver(() => (
        <DataCard
            title={t("hardware_profile.raid_controllers_card.title")}
            autoHeight
            emptyPadding
            headerDivider
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={RAID_CONTROLLERS_CARD} />
                </MoreMenu>
            }
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                onClick={changeSorting(HARDWARE_PROFILE_RAID_CONTROLLER)}
                                direction={hardwareProfileStore.orderRaid}
                                active={hardwareProfileStore.orderByRaid === HARDWARE_PROFILE_RAID_CONTROLLER}
                            >
                                {t("hardware_profile.raid_controllers.header.controller")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                onClick={changeSorting(HARDWARE_PROFILE_RAID_SERIAL_NUMBER)}
                                direction={hardwareProfileStore.orderRaid}
                                active={hardwareProfileStore.orderByRaid === HARDWARE_PROFILE_RAID_SERIAL_NUMBER}
                            >
                                {t("hardware_profile.raid_controllers.header.serial_number")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell width={"30%"}>
                            <TableSortLabel
                                onClick={changeSorting(HARDWARE_PROFILE_RAID_MODEL)}
                                direction={hardwareProfileStore.orderRaid}
                                active={hardwareProfileStore.orderByRaid === HARDWARE_PROFILE_RAID_MODEL}
                            >
                                {t("hardware_profile.raid_controllers.header.model")}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {hardwareProfileStore.sortedRaidControllers.map(row => {
                        return (
                            <TableRow key={row.serialNumber}>
                                <TableCell>{row.controller}</TableCell>
                                <TableCell>{row.serialNumber}</TableCell>
                                <TableCell>{row.controllerModel}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </DataCard>
    ));
};

export default RAIDControllersCard;
