import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    box: {
        boxSizing: "border-box",
        height: "100%"
    },
    container: {
        padding: theme.spacing(0, 8, 8, 8)
    },
    contentContainer: {
        marginTop: theme.spacing(3),
        flex: 1
    },
    icon: {
        marginRight: ({ iconMargin = 40 }) => iconMargin,
        marginTop: theme.spacing(1.5)
    },
    content: {
        marginTop: theme.spacing(4),
        whiteSpace: "pre-line",
        marginBottom: ({ button }) => button && theme.spacing(6),
        display: "flex",
        flexDirection: "column"
    },
    flag: {
        opacity: ({ flag }) => (flag ? 1 : 0),
        position: "relative",
        top: theme.spacing(4),
        width: theme.spacing(1),
        height: theme.spacing(8),
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
        backgroundColor: theme.palette.primary.main
    },
    menu: {
        position: "relative",
        top: theme.spacing(4),
        marginLeft: "auto",
        marginRight: theme.spacing(6)
    }
}));
