const env = process.env.NODE_ENV || "production";
const config = require(`./${env}`);

config.env = env;
config.protocol = window.location.protocol === "https:" ? "https" : "http";
config.wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
config.path = config.path || window.location.hostname;
config.netdataPort = 8091;

export default config;
