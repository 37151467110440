import Request from "api/Request";

const StartSharebrowserServer = Request.props({
    path: "/com/studionetworksolutions/evo/manage/SharebrowserServer",
    method: "start_sharebrowser_server"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default StartSharebrowserServer;
