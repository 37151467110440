import { types } from "mobx-state-tree";

import NetInterfaceActivation from "api/activation/interfaces/Types/NetInterfaceActivation";

const GetActivationResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(NetInterfaceActivation)
});

export default GetActivationResult;
