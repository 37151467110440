import isFieldEmpty from "utils/isFieldEmptyRule";
import i18n from "i18n";
import isFieldHasOnlySpaces from "utils/isFieldHasOnlySpacesRule";
import lengthValidator from "utils/lengthValidator";
import regexValidator from "utils/regexValidator";
import mustHaveDifferentFieldValues from "utils/mustHaveDifferentFieldValues";
import isFieldIntegerPositive from "utils/isFieldIntegerPositive";
import { dynamicRequiredValidator } from "utils/dynamicRequiredValidator";
import { DESTINATION_OPTIONS, SCHEDULE_PERIOD_OPTIONS, SOURCE_OPTIONS } from "const/createJobConst";
import transferFilesOlderThanCountValidator from "utils/transferFilesOlderThanCountValidator";
import dateComparison from "utils/validators/dateComparison";

const createFields = () => {
    return [
        {
            name: "jobName",
            validators: [
                isFieldEmpty(i18n.t("common.label.field")),
                isFieldHasOnlySpaces(i18n.t("rule.field_has_only_spaces")),
                lengthValidator(3, 16, i18n.t("alerts.create_job_dialog.job_name_length.hint", { count: 16, count2: 3 })),
                regexValidator(/^[\w-]{1,32}$/, i18n.t("slingshot.create_job.name_validation")),
            ],
        },
        {
            name: "method",
        },
        {
            name: "source",
            validators: [isFieldEmpty(i18n.t("common.label.field"))],
        },
        {
            name: "sourceAlias",
            validators: [
                mustHaveDifferentFieldValues("destinationAlias", i18n.t("rule.not_equals.destination_equal_source"), {
                    incompatibleFieldsForValidation: ["sourcePath", "destinationPath", "sourceShare", "destinationShare"],
                }),
                dynamicRequiredValidator([
                    {
                        field: "source",
                        values: [
                            SOURCE_OPTIONS.ftp,
                            SOURCE_OPTIONS.dropbox,
                            SOURCE_OPTIONS.azure,
                            SOURCE_OPTIONS.amazon,
                            SOURCE_OPTIONS.gcs,
                        ],
                    },
                ]),
            ],
        },
        {
            name: "sourceShare",
            validators: [
                mustHaveDifferentFieldValues("destinationShare", i18n.t("rule.not_equals.destination_equal_source"), {
                    incompatibleFieldsForValidation: ["sourcePath", "destinationPath", "sourceAlias", "destinationAlias"],
                }),
                dynamicRequiredValidator([
                    {
                        field: "source",
                        values: [SOURCE_OPTIONS.share, SOURCE_OPTIONS.smb],
                    },
                ]),
            ],
        },
        {
            name: "sourceLogicalDisk",
            validators: [
                dynamicRequiredValidator([
                    {
                        field: "source",
                        values: [SOURCE_OPTIONS.logicalDisk],
                    },
                ]),
            ],
        },
        {
            name: "sourcePath",
            validators: [
                mustHaveDifferentFieldValues("destinationPath", i18n.t("rule.not_equals.destination_equal_source"), {
                    getRequiredCondition: (form) =>
                        form.$("sourceShare").value === form.$("destinationShare").value &&
                        form.$("sourceAlias").value === form.$("destinationAlias").value,
                    customValidation: (form) => {
                        const sourcePathDirectories = form.$("sourcePath").value.split("/").filter(Boolean);

                        const destinationPathDirectories = form.$("destinationPath").value?.split("/").filter(Boolean);

                        const isDirectoriesEqual = sourcePathDirectories.every((sourceDirectory, idx) => {
                            return sourceDirectory === destinationPathDirectories[idx];
                        });

                        return !isDirectoriesEqual;
                    },
                }),
            ],
        },
        {
            name: "destination",
            validators: [isFieldEmpty(i18n.t("common.label.field"))],
        },
        {
            name: "destinationAlias",
            validators: [
                dynamicRequiredValidator([
                    {
                        field: "destination",
                        values: [
                            DESTINATION_OPTIONS.ftp,
                            DESTINATION_OPTIONS.dropbox,
                            DESTINATION_OPTIONS.azure,
                            DESTINATION_OPTIONS.amazon,
                            DESTINATION_OPTIONS.gcs,
                        ],
                    },
                ]),
            ],
        },
        {
            name: "destinationShare",
            validators: [
                dynamicRequiredValidator([
                    {
                        field: "destination",
                        values: [DESTINATION_OPTIONS.share, DESTINATION_OPTIONS.smb],
                    },
                ]),
            ],
        },
        {
            name: "destinationPath",
        },
        {
            name: "schedulePeriod",
        },
        {
            name: "enableBegin",
            type: "checkbox",
        },
        {
            name: "beginDate",
        },
        {
            name: "enableEnd",
            type: "checkbox",
        },
        {
            name: "endDate",
        },
        {
            name: "startAtDayOfWeek",
        },
        {
            name: "startAtTime",
        },
        {
            name: "startAtDateTime",
            validators: [
                dateComparison("endAtDateTime", i18n.t("common.label.field"), {
                    lessThan: true,
                    isValidationNeeded: (form) =>
                        form.$("schedulePeriod").value === SCHEDULE_PERIOD_OPTIONS.once && form.$("enableEndAt").value,
                }),
            ],
        },
        {
            name: "enableEndAt",
            type: "checkbox",
        },
        {
            name: "endAtDayOfWeek",
        },
        {
            name: "endAtTime",
        },
        {
            name: "endAtDateTime",
        },
        {
            name: "emailJobSummary",
            type: "checkbox",
        },
        {
            name: "emailOnJobStart",
            type: "checkbox",
        },
        {
            name: "emailOnJobPause",
            type: "checkbox",
        },
        {
            name: "transferFilesOlderThan",
            type: "checkbox",
        },
        {
            name: "transferFilesOlderThanCount",
            type: "number",
            validators: [
                isFieldEmpty(i18n.t("common.label.field")),
                isFieldIntegerPositive(i18n.t("rule.field.natural_number")),
                transferFilesOlderThanCountValidator(),
            ],
        },
        {
            name: "transferFilesOlderThanType",
        },
        {
            name: "enableIncludePattern",
            type: "checkbox",
        },
        {
            name: "includePattern",
            validators: [isFieldEmpty(i18n.t("common.label.field"))],
        },
        {
            name: "enableIncludePatternCaseInsensitive",
            type: "checkbox",
        },
        {
            name: "enableExcludePattern",
            type: "checkbox",
        },
        {
            name: "excludePattern",
            validators: [isFieldEmpty(i18n.t("common.label.field"))],
        },
        {
            name: "includeExcludePriority",
        },
        {
            name: "includeRecycleBin",
            type: "checkbox",
        },
        {
            name: "includeMetadata",
            type: "checkbox",
        },
        {
            name: "includeMediaProxy",
            type: "checkbox",
        },
        {
            name: "skipNewerFiles",
            type: "checkbox",
        },
        {
            name: "detectFileModification",
            type: "checkbox",
        },
        {
            name: "enableVerifyAfterTransfer",
            type: "checkbox",
        },
        {
            name: "verifyAfterTransferReport",
        },
        {
            name: "replicateInParallel",
            type: "checkbox",
        },
        {
            name: "removeDeletedFiles",
            type: "checkbox",
        },
        {
            name: "saveLogsAtDestination",
            type: "checkbox",
        },
        {
            name: "logLevel",
        },
    ];
};

export default createFields;
