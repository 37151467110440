import { types } from "mobx-state-tree";

import Request from "api/Request";
import DeleteInstanceArguments from "api/slingshot/archived_instances/Types/DeleteInstanceArguments";

const DeleteInstance = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Automations1",
    method: "delete_instance",
    arguments: types.maybe(
        types.snapshotProcessor(DeleteInstanceArguments, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json)
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default DeleteInstance;
