import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    paper: {
        width: "100%",
        height: ({ autoHeight }) => (autoHeight ? "auto" : "100%"),
        "& > .MuiGrid-container": {
            flexWrap: ({ autoHeight }) => (autoHeight ? "nowrap" : "wrap")
        }
    },
    container: {
        height: "100%",
        overflowX: "auto"
    },
    childrenContainer: {
        padding: ({ emptyPadding }) => (emptyPadding ? 0 : theme.spacing(4, 6, 8)),
        marginTop: ({ containDivider }) => (containDivider ? 0 : theme.spacing(-4)),
        flexGrow: 1
    }
}));
