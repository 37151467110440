import Request from "api/Request";

const GetReplaceState = Request.props({
    path: "/com/studionetworksolutions/evo/manage/DriveReplace",
    method: "get_replace_state"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetReplaceState;
