import { types } from "mobx-state-tree";

import PowerStatus from "api/power/Types/PowerStatus";
import PowerEvent from "api/power/Types/PowerEvent";

const PowerStatusAllEvents = types.model({
    status: types.maybe(PowerStatus),
    events: types.array(PowerEvent)
});

export default PowerStatusAllEvents;
