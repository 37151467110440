import React from "react";
import RadioContainer from "../RadioContainer";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Radio from "components/Radio";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const ALLOW_SMB_AND_AFP_CONTROLLER_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_allow_smb_and_afp_controller`;

const AllowSmbAndAfpController = ({ disableAfpOnly, disableMixing, ...rest }) => {
    const { t } = useTranslation();
    if (disableAfpOnly || disableMixing) {
        rest.field.set("smb_only");
    }
    return (
        <Grid item>
            <RadioContainer {...rest} label={<Typography>{t("share.create_share.allow_smb_and_afp")}</Typography>}>
                <Radio
                    label={t("share.create_share.allow_smb_only")}
                    value={"smb_only"}
                    id={`${ALLOW_SMB_AND_AFP_CONTROLLER_ID_PREFIX}_smb_only`}
                />
                <Radio
                    label={t("share.create_share.allow_afp_only")}
                    value={"afp_only"}
                    disabled={disableAfpOnly}
                    id={`${ALLOW_SMB_AND_AFP_CONTROLLER_ID_PREFIX}_afp_only`}
                />
                <Radio
                    label={t("share.create_share.allow_mixing")}
                    value={"mixing"}
                    disabled={disableMixing}
                    helperText={t("share.create_share.not_recommended")}
                    id={`${ALLOW_SMB_AND_AFP_CONTROLLER_ID_PREFIX}_mixing`}
                />
            </RadioContainer>
        </Grid>
    );
};

export default AllowSmbAndAfpController;
