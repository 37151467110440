import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import useStoreByIp from "hooks/useStoreByIp";
import { useStyles } from "./EditUsbAclModal.styles";
import Dialog from "components/Dialog";
import MoreMenu from "components/MoreMenu";
import { Button, Table, TableHead, TableBody, TableRow, TableCell, MenuItem, ButtonBase } from "@material-ui/core";
import LocalUserIcon from "assets/LocalUserIcon";
import ExternalGroupIcon from "assets/ExternalGroupIcon";
import LocalGroupIcon from "assets/LocalGroupIcon";
import PlusIcon from "assets/PlusIcon";
import TableCellWithIcon from "components/TableCellWithIcon/TableCellWithIcon";
import EditUsbPermissionModal from "../EditUsbPermissionModal";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import Select from "components/Select";
import {
    getUserTypeTrId,
    LOCAL_USERS,
    EXTERNAL_USERS,
    LOCAL_GROUPS,
    EXTERNAL_GROUPS,
    getAccessTtId,
} from "const/userSearchVariants";
import SaveChangesDialog from "components/SaveChangesDialog";
import clsx from "clsx";
import { useModalCombine } from "hooks/useModalCombine";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import DestructiveMenuItem from "components/DestructiveMenuItem/DestructiveMenuItem";

const EDIT_USB_ACL_MODAL_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_edit_usb_acl_modal`;

const EditUsbAclModal = ({ open, onClose, afterSubmitCallback, ip }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { aclStore } = useStoreByIp({ ip });

    const state = useLocalStore(() => ({
        saveInProgress: false,
        setUsbAclLoading: false,
        dataForChangingSelect: null,
        isSubmitted: false,
        needToCreateNewAce: false,
        userType: LOCAL_USERS,
    }));

    const { unsavedChangesModal, saveChangesModal, editPermissionModal } = useModalCombine([
        "unsavedChangesModal",
        "saveChangesModal",
        "editPermissionModal",
    ]);
    const isExternal = () => {
        return state.userType === EXTERNAL_USERS || state.userType === EXTERNAL_GROUPS;
    };

    useEffect(() => {
        aclStore.getUsbAcl({ type: state.userType });
    }, [state.userType]);

    const getName = (domainObject) => {
        return isExternal() ? `${domainObject.domain}@${domainObject.name}` : domainObject.name;
    };

    const onDialogEnter = async () => {
        state.saveInProgress = false;
        unsavedChangesModal.close();
        state.isSubmitted = false;

        aclStore.updateIsPermissionsDefault(true);
        await aclStore.getUsbAcl({ type: state.userType });
    };

    const onSave = async () => {
        state.saveInProgress = true;
        const res = await aclStore.setUsbAcl({ type: state.userType });
        state.saveInProgress = false;
        state.setUsbAclLoading = false;
        aclStore.updateIsPermissionsDefault(true);
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    const handleOnSave = () => {
        state.setUsbAclLoading = true;
        onSave();
        state.userType = state.dataForChangingSelect;
        state.dataForChangingSelect = null;
        saveChangesModal.close();
    };

    const handleOnCancel = async () => {
        state.userType = state.dataForChangingSelect;
        state.dataForChangingSelect = null;
        saveChangesModal.close();
    };

    const removeAce = (ace) => () => {
        aclStore.removeUsbAce(ace);
    };

    const closeIfNeeded = () => {
        if (!aclStore.hasUsbAclChanges || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const editAce = (ace) => () => {
        state.needToCreateNewAce = false;
        aclStore.setCurrentUsbAce(ace);
        editPermissionModal.open();
        aclStore.updateIsPermissionsDefault(false);
    };
    const createAce = () => {
        state.needToCreateNewAce = true;
        aclStore.setCurrentUsbAce(null);
        editPermissionModal.open();
        aclStore.updateIsPermissionsDefault(false);
    };

    const getIcon = () => {
        if (isExternal()) {
            return <ExternalGroupIcon />;
        } else if (state.userType === LOCAL_GROUPS) {
            return <LocalGroupIcon />;
        } else {
            return <LocalUserIcon />;
        }
    };

    const userTypeChanged = (event) => {
        if (aclStore.hasUsbAclChanges) {
            state.dataForChangingSelect = event.target.value;
            saveChangesModal.open();
        } else {
            state.userType = event.target.value;
        }
    };

    const getType = (access) => {
        return t(getAccessTtId(access));
    };
    const getAceTable = () => {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Select
                                onChange={userTypeChanged}
                                value={state.userType}
                                options={[LOCAL_USERS, EXTERNAL_USERS, LOCAL_GROUPS, EXTERNAL_GROUPS].map((type) => {
                                    return { value: type, label: t(getUserTypeTrId(type)) };
                                })}
                                id={`${EDIT_USB_ACL_MODAL_ID_PREFIX}_user_type`}
                            />
                        </TableCell>
                        <TableCell>{t("users.customize_settings_modal.table.header.access")}</TableCell>
                        <TableCell width={"10%"} align="right">
                            <ButtonBase
                                disabled={!aclStore.usbAcl}
                                onClick={createAce}
                                id={`${EDIT_USB_ACL_MODAL_ID_PREFIX}_create_ace`}
                            >
                                <PlusIcon className={clsx(!aclStore.usbAcl && classes.disabled)} />
                            </ButtonBase>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!state.setUsbAclLoading &&
                        aclStore.sortedUsbAcl?.map((ace, index) => (
                            // TODO  Do not use Array index in keys
                            // eslint-disable-next-line react/no-array-index-key
                            <TableRow key={index}>
                                <TableCellWithIcon className={classes.cellWithIcon} icon={getIcon()}>
                                    {getName(ace.trustee)}
                                </TableCellWithIcon>
                                <TableCell>{getType(ace.access)}</TableCell>
                                <TableCell>
                                    <MoreMenu id={`${EDIT_USB_ACL_MODAL_ID_PREFIX}_ace_${index}_more_menu`}>
                                        <MenuItem onClick={editAce(ace)} id={`${EDIT_USB_ACL_MODAL_ID_PREFIX}_ace_${index}_edit`}>
                                            {t("common.button.edit")}
                                        </MenuItem>
                                        <DestructiveMenuItem
                                            onClick={removeAce(ace)}
                                            id={`${EDIT_USB_ACL_MODAL_ID_PREFIX}_ace_${index}_delete`}
                                        >
                                            {t("common.button.delete")}
                                        </DestructiveMenuItem>
                                    </MoreMenu>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        );
    };

    return useObserver(() => (
        <Dialog
            afterSubmitCallback={afterSubmitCallback}
            maxWidth={"md"}
            onEnter={onDialogEnter}
            open={open}
            onClose={closeIfNeeded}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${EDIT_USB_ACL_MODAL_ID_PREFIX}_save`}
            onSubmit={onSave}
            withoutPaddings
            title={t("users.customize_settings_modal_usb.title")}
            inProgress={state.saveInProgress}
            buttons={
                <Button variant={"contained"} color={"secondary"} onClick={onClose} id={`${EDIT_USB_ACL_MODAL_ID_PREFIX}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            {getAceTable()}
            {state.setUsbAclLoading && <EmptyCardPlaceholder inProgress />}
            <EditUsbPermissionModal
                ip={ip}
                userType={state.userType}
                open={editPermissionModal.isOpen}
                onClose={editPermissionModal.close}
                isCreate={state.needToCreateNewAce}
            />
            <UnsavedChangesDialog open={unsavedChangesModal.isOpen} onClose={unsavedChangesModal.close} onConfirm={onClose} />
            <SaveChangesDialog
                open={saveChangesModal.isOpen}
                onClose={saveChangesModal.close}
                onCancel={handleOnCancel}
                onSave={handleOnSave}
                withoutIconClose
                withoutDividers
            />
        </Dialog>
    ));
};

export default EditUsbAclModal;
