import React from "react";
import { useStyles } from "./DragVerticalDivider.styles";
import { Grid } from "@material-ui/core";

const DragVerticalDivider = ({ hoverPart }) => {
    const classes = useStyles({ hoverPart });
    return (
        <Grid container className={classes.container}>
            <Grid item className={classes.oval} />
            <Grid item className={classes.line} />
            <Grid item className={classes.oval} />
        </Grid>
    );
};

export default DragVerticalDivider;
