import Request from "api/Request";

const ListConfigBackups = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Slingshot",
    method: "list_config_backups"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ListConfigBackups;
