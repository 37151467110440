import React from "react";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Dialog from "components/Dialog";
import { useObserver } from "mobx-react-lite";
import useStoreByIp from "hooks/useStoreByIp";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const DELETE_POOL_DIALOG_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_delete_pool_dialog`;

const DeletePoolDialog = ({ pool = "", onClose, open, onSubmit, createPoolInProgress, ip }) => {
    const { poolsStore } = useStoreByIp({ ip });
    const { t } = useTranslation();

    const onExited = () => {
        poolsStore.fetchFreeDrives();
    };

    return useObserver(() => (
        <Dialog
            buttons={[
                <Button
                    key={"cancelBtn"}
                    variant={"contained"}
                    color={"secondary"}
                    onClick={onClose}
                    id={`${DELETE_POOL_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            ]}
            title={t("disks_and_pools.delete_pool.title")}
            onClose={onClose}
            open={open}
            onSubmit={onSubmit}
            submitBtnLabel={t("common.button.ok_delete")}
            submitBtnId={`${DELETE_POOL_DIALOG_ID_PREFIX}_submit`}
            onExited={onExited}
            withoutIconClose
            withoutDividers
            inProgress={createPoolInProgress}
        >
            <Typography>{t("disks_and_pools.delete_pool.content", { name: pool })}</Typography>
            <p />
            <Typography>{t("common.text.continue")}</Typography>
        </Dialog>
    ));
};

export default DeletePoolDialog;
