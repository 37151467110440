import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import createFields from "./createFields";
import createForm from "utils/createForm";
import Dialog from "components/Dialog";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import Select from "components/MobxForm/Select";
import { localeOptions } from "../../constants/localeOptions";
import { useStyles } from "./SetDisplayLanguageDialog.styles";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const SET_DISPLAY_LANGUAGE_DIALOG_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_set_display_language_dialog`;

const SetDisplayLanguageDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const {
        store: { uiStore }
    } = useStore();

    const classes = useStyles();

    const fields = createFields(uiStore.settings?.locale);
    const state = useLocalStore(() => ({
        form: createForm({
            fields
        }),
        isSubmitted: false
    }));

    const unsavedChangesModal = useModal();

    const submit = async e => {
        state.form.onSubmit(e);

        if (!state.form.isValid) {
            return;
        }

        const res = await uiStore.setUiSettings(state.form.values());

        if (res) {
            state.isSubmitted = true;
        }

        return res;
    };

    const onEnter = () => {
        state.form.$("locale").set("default", uiStore.settings?.locale);

        state.form.reset();
        state.isSubmitted = false;
        unsavedChangesModal.close();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }

        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            title={t("admin_settings.display_language_modal.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${SET_DISPLAY_LANGUAGE_DIALOG_ID_PREFIX}_save`}
            open={open}
            onEnter={onEnter}
            onSubmit={submit}
            onClose={closeIfNeeded}
            buttons={
                <Button
                    key="cancelBtn"
                    onClick={onClose}
                    variant={"contained"}
                    color="secondary"
                    id={`${SET_DISPLAY_LANGUAGE_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid item container wrap={"nowrap"} alignItems={"center"} className={classes.rowWithSelect}>
                <Grid item xs={4}>
                    <Typography>{t("admin_settings.display_language_modal.select_language.label")}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Select
                        field={state.form.$("locale")}
                        options={localeOptions}
                        id={`${SET_DISPLAY_LANGUAGE_DIALOG_ID_PREFIX}_locale`}
                    />
                </Grid>
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default SetDisplayLanguageDialog;
