import { destroy, getParent, isAlive, types } from "mobx-state-tree";

/** Kind of type a modal window*/
const Modal = types
    .model({ show: types.boolean, name: types.string })
    .volatile(() => ({
        parameters: new Map(),
    }))
    .views((self) => ({
        get isOpen() {
            return self.show;
        },
        get value() {
            return self.parameters;
        },
    }))
    .volatile(() => ({
        handleClose: null,
        handleOpen: null,
        handleBeforeDestroy: null,
    }))
    .actions((self) => ({
        open() {
            const { history } = getParent(getParent(self));

            if (self.handleOpen && typeof self.handleOpen === "function") {
                self.handleOpen();
            }

            history.goForward(self.name);
            self.show = true;
        },
        close() {
            if (self.handleClose && typeof self.handleClose === "function") {
                self.handleClose();
            }
            self.show = false;
        },
        setHandleClose(fn) {
            if (typeof fn === "function") {
                self.handleClose = fn;
            }
        },
        setHandleOpen(fn) {
            if (typeof fn === "function") {
                self.handleOpen = fn;
            }
        },
        setHandleBeforeDestroy(fn) {
            if (typeof fn === "function") {
                self.handleBeforeDestroy = fn;
            }
        },
        beforeDestroy() {
            if (self.handleBeforeDestroy && typeof self.handleBeforeDestroy === "function") {
                self.handleBeforeDestroy();
            }
        },
        setValue(key, value) {
            self.parameters.set(key, value);
        },
    }));

const History = types
    .model("History", {})
    .volatile(() => ({
        history: [],
    }))
    .views((self) => ({
        get allHistory() {
            return self.history;
        },
        get lastModal() {
            return self.history[self.history.length - 1];
        },
    }))
    .actions((self) => ({
        goForward(val) {
            if (self.lastModal !== val) self.history.push(val);
        },
        goBack() {
            const lastModalName = self.history.pop();
            const store = getParent(self);
            store.modals.get(lastModalName).close();
            store.modals.get(self.lastModal).open();
        },
        reset() {
            self.history = [];
        },
    }));

/**
 * Function for create new the modal object
 * @returns IModelType - object of type the modal
 */
const createModal = (name) => Modal.create({ show: false, name });

/**
 * Store for handle the modal window state
 */
const ModalStore = types
    .model("ModalStore", {
        modals: types.map(Modal),
        history: types.optional(History, {}),
    })
    .views((self) => ({
        get showAllModal() {
            return self.modals;
        },
    }))
    .actions((self) => ({
        /**
         * Action for create new modal store
         * @param {*} name - name(key) with which the modal will be save to the store in the map of modals object
         * @returns reference to a new modal store
         */
        initialModal(name) {
            const modal = createModal(name);
            self.modals.set(name, modal);

            return modal;
        },
        /**
         * Function for destroy the modal store by name from map modals
         * @param {*} name - name(key) with which the modal will be save to the store in the map of modals object
         */
        destroyModal(name) {
            const modal = self.modals.get(name);
            if (modal && isAlive(modal)) {
                destroy(modal);
            }
        },
        /**
         * Function for get ref to a modal store or create and return ref to modal store if non-found
         * @param {*} name - name(key) with which the modal will be save to the store in the map of modals object
         * @returns {Modal} reference to a modal store from map modals
         */
        getModal(name) {
            if (self.modals.has(name)) {
                const modal = self.modals.get(name);
                return isAlive(modal) ? modal : self.initialModal(name);
            }

            return self.initialModal(name);
        },
        /**
         * Functions for check existence of the modal store by name
         * @param {*} name - name(key) with which the modal will be save to the store in the map of modals object
         * @returns trust if a modal store exists, or false if it does not
         */
        checkModal(name) {
            if (self.modals.has(name)) {
                const modal = self.modals.get(name);
                return isAlive(modal);
            }

            return false;
        },
        /**
         * Functions toggle a state of open all modals store to close
         */
        closeAllModals() {
            for (const modal of [...self.modals.values()].reverse()) {
                modal.close();
            }
        },
        /** Uncomment if need to create modal immediately after create the modal store.  */
        // afterCreate() {
        //     Array.from(
        //         [
        //             ["firstModal", createModal()],
        //             ["secondaryModal", createModal()],
        //             ["anotherModal", createModal()]
        //         ],
        //         ([name, value]) => self.modals.set(name, value)
        //     );
        // }
    }));

export default ModalStore;
