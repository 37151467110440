import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./StatusLabel.style";

const StatusLabel = ({ status, text, justify }) => {
    const classes = useStyles({ status });
    return (
        <Grid justify={justify || "center"} container>
            <Grid item>
                <Grid container alignItems={"center"} className={classes.label}>
                    <Grid item>
                        <Typography component={"span"} className={classes.labelText}>
                            {text || status}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default StatusLabel;
