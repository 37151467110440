import { FREQUENCY_OPTIONS } from "const/createAutomationsConst";

const getTimeUnit = time => {
    let timeUnit = FREQUENCY_OPTIONS[0].value;
    let frequency = time;

    if (parseInt(time) !== 0 && time % (60 * 24) === 0) {
        timeUnit = FREQUENCY_OPTIONS[2].value;
        frequency = time / (60 * 24);
    } else if (parseInt(time) !== 0 && time % 60 === 0) {
        timeUnit = FREQUENCY_OPTIONS[1].value;
        frequency = time / 60;
    }

    return { timeUnit, frequency };
};
export default getTimeUnit;
