import { types } from "mobx-state-tree";

import ShareLocationInfo from "api/share/Types/ShareLocationInfo";
import ShareAttributes from "api/share/Types/ShareAttributes";
import Status from "api/share/Types/Status";
import { convertBytesToSizeUnit } from "utils/convertBytesToSizeUnit";
import { equalsIgnoreCase } from "utils/equalsIgnoreCase";

const sizeConverter = size => {
    return convertBytesToSizeUnit({ bytes: size, exponent: 3, output: "object" }).value;
};

const ShareStatusInfo = types
    .model({
        location: types.maybe(ShareLocationInfo),
        attributes: types.maybe(ShareAttributes),
        status: types.maybe(Status),
        used: types.number,
        usage: types.array(types.string)
    })
    .views(self => ({
        get shareType() {
            switch (self.attributes.shareType) {
                case "smb":
                    return "SMB";
                case "nfs":
                    return "NFS";
                case "ftp":
                    return "FTP";
                case "fxp":
                    return "FXP";
                case "sftp":
                    return "SFTP";
                case "internal":
                    return "Reserved";
                default:
                    return "Unknown";
            }
        },
        get shareName() {
            return self.attributes.shareName;
        },
        get shareUsed() {
            return sizeConverter(self.used);
        },
        get quota() {
            return sizeConverter(self.attributes.quota);
        },
        get nestedStatus() {
            return self.status.status;
        },
        get isAuditLogStorageLocation() {
            return (
                (self.attributes.shareType === "smb" ||
                    self.attributes.shareType === "ftp" ||
                    self.attributes.shareType === "sftp") &&
                !equalsIgnoreCase(self.location.pool, "USB")
            );
        }
    }));

export default ShareStatusInfo;
