import { useStore } from "hooks/useStore";

const ViewByRole = ({ children, roles }) => {
    const {
        store: { authStore }
    } = useStore();

    if (roles.includes(authStore.role)) {
        return children;
    }

    return null;
};

export default ViewByRole;
