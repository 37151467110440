import React from "react";
import DataCard from "components/DataCard";
import { Grid, Typography } from "@material-ui/core";
import ResetIcon from "assets/ResetIcon";
import { useTranslation, Trans } from "react-i18next";
import { useStyles } from "./InformationCard.styles";

const InformationCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <DataCard title={t("troubleshooting.information.card_title")} headerDivider autoHeight>
            <Grid container direction={"column"}>
                <Grid item>
                    <Typography gutterBottom>{t("troubleshooting.information_card.paragraph_1")}</Typography>
                    <Typography gutterBottom>{t("troubleshooting.information_card.paragraph_2")}</Typography>
                    <Typography gutterBottom>
                        <Trans
                            i18nKey="troubleshooting.information_card.paragraph_3"
                            components={<ResetIcon className={classes.icon} />}
                        />
                    </Typography>
                    <Typography gutterBottom>{t("troubleshooting.information_card.paragraph_4")}</Typography>
                    <Typography gutterBottom>{t("troubleshooting.information_card.paragraph_5")}</Typography>
                </Grid>
            </Grid>
        </DataCard>
    );
};
export default InformationCard;
