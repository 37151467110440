import { types } from "mobx-state-tree";

const RebalanceNode = types.model({
    files: types.number,
    size: types.number,
    scanned: types.number,
    failures: types.number,
    skipped: types.number,
    status: types.number,
    runtime: types.number,
    statusStr: types.string,
    nodeName: types.string,
    id: types.string
});

export default RebalanceNode;
