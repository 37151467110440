import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetExpirationDate from "api/sshAccess/Requests/GetExpirationDate";
import ImportKey from "api/sshAccess/Requests/ImportKey";
import GetExpirationDateType from "api/general/Responses/StringResult";
import { FILE_UPLOAD_URL } from "api/restRoutes";
import axios from "api/AxiosCommonRequest";

const SshAccessStore = types
    .model({ expirationDateStore: types.maybe(GetExpirationDateType) })
    .views(self => ({
        get expirationDate() {
            return self.expirationDateStore && self.expirationDateStore.data;
        }
    }))
    .actions(self => ({
        fetchExpirationDate: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetExpirationDate.create().init();
                const res = yield Socket.send(req);
                self.expirationDateStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        importKey: flow(function*(fileName) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ImportKey.create().init({
                    gpgName: fileName
                });
                yield Socket.send(req);
                self.fetchExpirationDate();
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
            return fileName;
        }),
        uploadFile: flow(function*(file) {
            const { processingStore } = getParent(self);
            const formData = new FormData();
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            try {
                formData.append("file", file);
                processingStore.setLoading(true);
                yield axios.post(FILE_UPLOAD_URL, formData, config);
                self.importKey(file.name);
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
            return file;
        })
    }));

export default SshAccessStore;
