import { types } from "mobx-state-tree";

import DriveId from "api/volume/Types/DriveId";

const BuildTask = types.model({
    pool: types.string,
    volume: types.string,
    raid: types.number,
    size: types.number,
    chunkSize: types.number,
    iSCSIBlockSize: types.number,
    ioProfile: types.string,
    cache: types.maybe(DriveId),
    cacheSupport: types.boolean
});

export default BuildTask;
