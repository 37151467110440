import { getParent, types } from "mobx-state-tree";
import { flow } from "mobx-state-tree";
import Socket from "websocket";
import GetTimeSettings from "api/time/Requests/GetTimeSettings";
import GetTimeSettingsResult from "api/time/Responses/GetTimeSettingsResult";
import GetTimezones from "api/time/Requests/GetTimezones";
import SetTimeSettings from "api/time/Requests/SetTimeSettings";
import convertDateToTime from "utils/convertDateToTime";
import moment from "moment-timezone";
import { autorun } from "mobx";

const TimeSettingsStore = types
    .model({
        timeSettings: types.maybe(GetTimeSettingsResult),
        timezones: types.array(types.string),
        localTime: types.optional(types.number, 0),
    })
    .volatile(() => ({
        intervalFetch: 0,
        interval: 0,
        delta: 0,
    }))
    .views((self) => ({
        get timezoneArray() {
            return self.timezones;
        },
        get timezone() {
            return self.timeSettings && self.timeSettings.data && self.timeSettings.data.local.timezone;
        },
        get server() {
            return self.timeSettings && self.timeSettings.data && self.timeSettings.data.ntp.server;
        },
        get enableServer() {
            return self.timeSettings && self.timeSettings.data && self.timeSettings.data.ntp.enableServer;
        },
        get enableClient() {
            return self.timeSettings && self.timeSettings.data && self.timeSettings.data.ntp.enableClient;
        },
    }))
    .actions((self) => ({
        fetchTimeSettings: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetTimeSettings.create().init();
                const res = yield Socket.send(req);
                self.timeSettings = res;
                self.localTime =
                    self.timeSettings && self.timeSettings.data && convertDateToTime(self.timeSettings.data.local.time);
                self.delta = Math.floor(Date.now() / 1000) - self.localTime;
                self.startTimer();
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchTimeZone: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetTimezones.create().init();
                const res = yield Socket.send(req);
                self.timezones = res.data.filter((tz) => moment.tz.names().includes(tz));
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setTimeSettings: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetTimeSettings.create().init(data);
                yield Socket.send(req);
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
            return data;
        }),
        startTimerFetch: () => {
            if (self.intervalFetch) {
                self.stopTimerFetch();
            }
            self.intervalFetch = setInterval(() => {
                self.fetchTimeSettings();
            }, 10 * 60 * 1000);
        },
        startTimer: () => {
            if (self.interval) {
                self.stopTimer();
            }

            if (self.timeSettings && self.timeSettings.data) {
                self.interval = setInterval(() => {
                    self.addSecToTime();
                }, 1000);
            }
        },
        addSecToTime: () => {
            self.localTime = Math.floor(Date.now() / 1000) - self.delta;
        },
        stopTimer: () => {
            clearInterval(self.interval);
        },
        stopTimerFetch: () => {
            clearInterval(self.intervalFetch);
        },
        dateWithTimeZone(date) {
            const dateJS = new Date(date * 1000);
            const momentDateWithTimeZone = moment.tz(dateJS, self.timezone).format("YYYY-MM-DD HH:mm:ss");
            return moment(momentDateWithTimeZone, "YYYY-MM-DD HH:mm:ss").toDate();
        },
    }))
    .actions((self) => ({
        afterCreate() {
            const { authStore } = getParent(self);

            autorun(() => {
                if (authStore.isAdmin || authStore.isSecondaryAdmin) {
                    self.fetchTimeSettings();
                    self.startTimerFetch();
                }
            });
        },
    }));

export default TimeSettingsStore;
