import React from "react";
import { useObserver } from "mobx-react";
import { useStyles } from "./DriveItem.style";
import { Typography, Grid } from "@material-ui/core";
import StatusDot from "./StatusDot";
import clsx from "clsx";

const DriveItem = ({ large, title, status, withoutHorizontalPadding }) => {
    const classes = useStyles({ withoutHorizontalPadding });
    return useObserver(() => (
        <Grid
            container
            className={clsx(large ? classes.largeItem : classes.smallItem)}
            alignItems={"center"}
            justify={"center"}
            wrap={"nowrap"}
        >
            <StatusDot status={status} />
            <Grid item>
                <Typography noWrap color={"textSecondary"} className={classes.title}>
                    {title}
                </Typography>
            </Grid>
        </Grid>
    ));
};

export default DriveItem;
