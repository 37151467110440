import React from "react";
import CardHeader from "components/CardHeader";
import { useStyles } from "components/DataCard/DataCard.style";
import { Paper, Grid, Divider } from "@material-ui/core";
import clsx from "clsx";
import { useObserver } from "mobx-react";

const DataCard = ({
    title,
    headerContent,
    children,
    headerControl,
    autoHeight,
    headerDivider,
    invisibleIcon,
    emptyPadding = false,
    className,
    stretch,
    ...rest
}) => {
    const classes = useStyles({ autoHeight, emptyPadding, containDivider: !!headerDivider });

    return useObserver(() => (
        <Paper className={clsx(classes.paper, className)} {...rest}>
            <Grid container direction={"column"} className={classes.container} wrap={"nowrap"}>
                {(title || headerContent) && (
                    <Grid item>
                        <CardHeader invisibleIcon={invisibleIcon} title={title} content={headerContent} control={headerControl} />
                    </Grid>
                )}
                {headerDivider && <Divider />}
                <Grid className={classes.childrenContainer} item container={stretch}>
                    {children}
                </Grid>
            </Grid>
        </Paper>
    ));
};

export default DataCard;
