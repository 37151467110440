import { types, flow, getParent } from "mobx-state-tree";
import MainSocket from "websocket";
import GetNasSessions from "api/session/Requests/GetNasSessions";
import GetNasSessionsType from "api/session/Responses/GetNasSessionsResult";
import { ASC, DESC, NAS_SESSION_SHARE_NAME } from "const/sortColumnConst";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";

const SessionStore = types
    .model({ nasSessionsStore: types.maybe(GetNasSessionsType) })
    .views(self => ({
        get nasSessions() {
            return self.nasSessionsStore && self.nasSessionsStore.data;
        },
        get sortedNasSessions() {
            return self.nasSessions && stableSort(self.nasSessions, getComparator(self.order, self.orderBy));
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        }
    }))
    .volatile(() => ({
        orderBy: NAS_SESSION_SHARE_NAME,
        order: ASC
    }))
    .actions(self => ({
        fetchNasSessions: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetNasSessions.create().init();
                const res = yield self.socket.send(req);
                self.nasSessionsStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return false;
        }),
        changeSorting(column) {
            if (column === self.orderBy) {
                self.order = self.order === DESC ? ASC : DESC;
            } else {
                self.order = ASC;
                self.orderBy = column;
            }
        }
    }));

export default SessionStore;
