import { types } from "mobx-state-tree";

const ConstraintsType = types.model({
    constraints: types.array(
        types.model({
            name: types.maybe(types.string),
            defaultValue: types.maybeNull(types.frozen()),
        })
    ),
});

export default ConstraintsType;
