import Request from "api/Request";

const GetLogicalDisks = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Storage1",
    method: "get_logical_disks"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetLogicalDisks;
