import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    line: {
        whiteSpace: "nowrap",
        flexWrap: "nowrap",
        margin: theme.spacing(1, 0),
        "&:nth-of-type(1)": {
            marginTop: 0
        },
        cursor: "pointer"
    },
    linkItem: {
        textDecoration: "none",
        "&:hover": {
            textDecoration: "none"
        }
    }
}));

export default useStyles;
