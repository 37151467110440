import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    graph: {
        maxWidth: "100%"
    },
    oval: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        borderRadius: 100
    },
    ovalOrange: {
        backgroundColor: theme.palette.warning.main
    },
    ovalBlue: {
        backgroundColor: theme.palette.primary.main
    },
    buttonContainer: {
        boxSizing: "content-box",
        marginTop: theme.spacing(8),
        height: theme.spacing(10)
    },
    button: {
        "& .MuiButton-label": {
            opacity: 0.8,
            fontWeight: "normal",
            lineHeight: 1.5,
            letterSpacing: "0.2px"
        },
        width: 90,
        marginRight: theme.spacing(4),
        height: "100%",
        textTransform: "none"
    }
}));
