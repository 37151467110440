import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetActivation from "api/activation/quad/Requests/GetActivation";
import GetActivationsCount from "api/activation/quad/Requests/GetActivationsCount";
import ActivateQuads from "api/activation/quad/Requests/ActivateQuads";
import DeactivateQuads from "api/activation/quad/Requests/DeactivateQuads";
import GetActivationType from "api/activation/quad/Responses/EnclosureActivationResult.js";
import GetActivationsCountType from "api/activation/quad/Responses/GetActivationsCountResult.js";
import GetMissingQuadsResult from "api/activation/quad/Responses/GetMissingQuadsResult";
import GetMissingActivatedQuads from "api/activation/quad/Requests/GetMissingActivatedQuads";
import DeactivateMissingQuad from "api/activation/quad/Requests/DeactivateMissingQuad";

const QuadActivationStore = types
    .model({
        quadActivationStore: types.maybe(GetActivationType),
        missingQuadsStore: types.maybe(GetMissingQuadsResult),
        missingQuadId: types.maybeNull(types.number),
        activationsCountStore: types.maybe(GetActivationsCountType),
        checkedQuads: types.optional(types.array(types.number), [])
    })
    .views(self => ({
        get quadActivationsArr() {
            return self.quadActivationStore && self.quadActivationStore.data.arr;
        },
        get nondestructiveActivationEnabled() {
            return (
                self.quadActivationStore &&
                self.quadActivationStore.data &&
                self.quadActivationStore.data.nondestructiveActivationEnabled
            );
        },
        get missingQuads() {
            return self.missingQuadsStore?.data || [];
        },
        get currentMissingQuadId() {
            return self.missingQuadId;
        },
        get currentMissingQuad() {
            return (
                self.missingQuadId !== null && self.missingQuads.find(missingQuad => missingQuad.quadId === self.missingQuadId)
            );
        },
        get quadActivationsCount() {
            return self.activationsCountStore && self.activationsCountStore.data && self.activationsCountStore.data.count;
        },
        get checkedQuadsCount() {
            return self.checkedQuads.length;
        },
        get allQuads() {
            const quads = [];
            if (self.quadActivationsArr) {
                self.quadActivationsArr.forEach(enclosure => {
                    enclosure.quadActivations.forEach(quadActivation => quads.push(quadActivation));
                });
            }
            return quads;
        },
        get hasDeactivatedQuad() {
            if (self.quadActivationsArr) {
                return self.checkedQuads.some(checkedQuad => {
                    const foundQuad = self.allQuads.find(quad => quad.quad === checkedQuad);
                    return foundQuad && !foundQuad.activated;
                });
            }
            return null;
        },
        get deactivatedCheckedQuad() {
            if (self.quadActivationsArr) {
                return self.checkedQuads.filter(checkedQuad => !self.allQuads.find(quad => quad.quad === checkedQuad)?.activated);
            }
            return [];
        },
        get activatedCheckedQuad() {
            if (self.quadActivationsArr) {
                return self.checkedQuads.filter(checkedQuad => self.allQuads.find(quad => quad.quad === checkedQuad)?.activated);
            }
            return [];
        }
    }))
    .actions(self => ({
        fetchQuadActivations: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetActivation.create().init();
                const res = yield Socket.send(req);
                self.quadActivationStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchQuadActivationsCount: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetActivationsCount.create().init();
                const res = yield Socket.send(req);
                self.activationsCountStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchMissingQuads: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetMissingActivatedQuads.create().init();
                const res = yield Socket.send(req);
                self.missingQuadsStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        deactivateMissingQuad: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DeactivateMissingQuad.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        removeMissingQuadFromStore: missingQuad => {
            if (self.missingQuadsStore && self.missingQuadsStore.data.length !== 0) {
                self.missingQuadsStore.data = self.missingQuadsStore.data.filter(quad => quad.quadId !== missingQuad.quadId);
            }
        },
        setCurrentMissingQuadId: missingQuadId => {
            self.missingQuadId = missingQuadId;
        },
        activateQuads: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ActivateQuads.create().init(data);
                yield Socket.send(req);
                self.clearCheckedQuads();
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        deactivateQuads: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DeactivateQuads.create().init(data);
                yield Socket.send(req);
                self.clearCheckedQuads();
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        addCheckedQuad: quadName => {
            self.checkedQuads.push(quadName);
        },
        removeCheckedQuad: quadName => {
            self.checkedQuads.remove(quadName);
        },
        clearCheckedQuads: () => {
            self.checkedQuads = [];
        },
        updateQuadState: quad => {
            const foundQuad = self.allQuads.find(quadItem => quadItem.quad === quad.quad);
            foundQuad && (foundQuad.quadState = quad.quadState);
        }
    }));

export default QuadActivationStore;
