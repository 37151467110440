import CryptoJS from "crypto-js";
import { URL_API_DROPBOX_TOKEN, URL_API_DROPBOX_AUTHORIZE } from "const/slingshotAliasConst";

export function openAuthorizationWindowDropbox(appKey) {
    const base64Encode = str => {
        return CryptoJS.enc.Base64.stringify(str)
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=/g, "");
    };
    const codeVerifier = base64Encode(CryptoJS.lib.WordArray.random(32));
    const codeChallenge = base64Encode(CryptoJS.SHA256(codeVerifier));

    const authorizeUrl =
        URL_API_DROPBOX_AUTHORIZE +
        "?client_id=" +
        encodeURIComponent(appKey) +
        "&response_type=code" +
        "&token_access_type=offline" +
        "&code_challenge=" +
        encodeURIComponent(codeChallenge) +
        "&code_challenge_method=S256";
    const authorizeWindow = window.open(authorizeUrl, "Dropbox OAuth2", "width=600,height=730,left=100,top=100", true);

    return { window: authorizeWindow, verifier: codeVerifier };
}

export async function generateAccessTokenDropbox(clientId, codeVerifier, accessCode) {
    if (!clientId || !codeVerifier || !accessCode) return { ok: false, data: null, error: "Invalid argument(s)" };

    const response = await fetch(URL_API_DROPBOX_TOKEN, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
        },
        body: `client_id=${clientId}&code_verifier=${codeVerifier}&code=${accessCode}&grant_type=authorization_code`
    });

    const json = await response.json();
    if (response.ok) return { ok: true, data: json, error: null };
    return {
        ok: false,
        data: null,
        error: json.error_description ?? "Unable to retrieve DropBox access token, please repeat the authorization procedure"
    };
}
