import { makeStyles } from "@material-ui/core/styles";
import {
    DRAWER_WIDTH,
    GRAPHICS_BAR_HEIGHT,
    GRAPHICS_BAR_HEIGHT_MOBILE,
    HEADER_HEIGHT,
    HEADER_HEIGHT_MOBILE,
    STATE_BAR_HEIGHT,
    STATE_BAR_HEIGHT_MOBILE
} from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    iconEnable: {
        "&& svg path": {
            fill: theme.palette.success.main
        }
    },
    iconDisable: {
        "&& svg path": {
            fill: theme.palette.error.main
        }
    },
    drawer: {
        "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            [theme.breakpoints.up("md")]: {
                top: HEADER_HEIGHT
            },
            top: HEADER_HEIGHT_MOBILE,
            bottom: 0,
            height: "initial",
            overflowY: "initial",
            boxShadow: "-5px 0 29px 0 rgba(0, 0, 0, 0.1)"
        }
    },
    drawerOpenHeader: {
        "& .MuiDrawer-paper": {
            [theme.breakpoints.up("md")]: {
                top: HEADER_HEIGHT + GRAPHICS_BAR_HEIGHT + STATE_BAR_HEIGHT
            },
            top: HEADER_HEIGHT_MOBILE + GRAPHICS_BAR_HEIGHT_MOBILE + STATE_BAR_HEIGHT_MOBILE
        }
    }
}));
