import { types } from "mobx-state-tree";

import AuditSettings from "api/audit/Types/AuditSettings";

const AuditSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(AuditSettings)
});

export default AuditSettingsResult;
