import React from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import RemoteShare from "pages/Shares/components/RemoteShare";
import Aliases from "../Aliases";
import Presets from "../Presets";
import AutotaggingMachineCard from "../AutotaggingMachineCard";
import ReplicationLogsCard from "../ReplicationLogsCard";
import ViewByRole from "components/ViewByRole";
import { ADMIN } from "const/userRolesConst";
import Watermarks from "../Watermarks/Watermarks";

const SETTINGS_TAB_CONTENT_ID_PREFIX = `settings_tab_content`;

const SettingsTabContent = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${SETTINGS_TAB_CONTENT_ID_PREFIX}` : SETTINGS_TAB_CONTENT_ID_PREFIX;
    return (
        <Grid container spacing={6}>
            <ViewByRole roles={ADMIN}>
                <Grid item xs={12}>
                    <RemoteShare parentId={idPrefix} />
                </Grid>
            </ViewByRole>

            <Grid item xs={12}>
                <Aliases parentId={idPrefix} />
            </Grid>

            <ViewByRole roles={ADMIN}>
                <Grid item xs={6}>
                    <Watermarks parentId={idPrefix} />
                </Grid>
                <Grid item xs={6}>
                    <AutotaggingMachineCard parentId={idPrefix} />
                </Grid>

                {/* [MDEVO-17514] [EVO-4497] Remove Transcribing Machine from UI */}
            </ViewByRole>

            <Grid item xs={12}>
                <Presets parentId={idPrefix} />
            </Grid>

            <ViewByRole roles={ADMIN}>
                <Grid item xs={6}>
                    <ReplicationLogsCard parentId={idPrefix} />
                </Grid>
            </ViewByRole>
        </Grid>
    );
};

export default observer(SettingsTabContent);
