import React, { useContext } from "react";
import { Context } from "store";
import { useObserver } from "mobx-react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./MultipleEvoTitle.styles";

const MultipleEvoTitle = ({ ip, ...props }) => {
    const classes = useStyles();
    const {
        store: { evoSettingsStore },
        multipleEvosRootStore
    } = useContext(Context);

    return useObserver(() => (
        <Typography className={classes.evoTitle} {...props}>
            {ip ? multipleEvosRootStore?.getStore(ip)?.name || "" : evoSettingsStore.evoInfo?.hostname || ""}
        </Typography>
    ));
};

export default MultipleEvoTitle;
