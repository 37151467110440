import React from "react";

const ShieldIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" {...props}>
            <path
                fill="#1E90FF"
                d="M13.122 2.224L19.84 4.64c.396.142.661.519.661.94v6.773c0 4.412-2.956 8.277-7.214 9.433L12.5 22l-.562-.153C7.548 20.656 4.5 16.671 4.5 12.121V5.157c0-.441.289-.83.711-.957l6.656-2.009c.411-.124.851-.113 1.255.033zm-.677 1.882L6.5 5.899v6.222c0 3.558 2.325 6.685 5.706 7.722l.293.084.263-.07c3.302-.897 5.62-3.842 5.734-7.241l.004-.262v-6.07l-6.055-2.178z"
            />
        </svg>
    );
};

export default ShieldIcon;
