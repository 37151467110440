import i18n from "i18n";
import regexValidator from "utils/regexValidator";
import isFieldEmpty from "utils/isFieldEmptyRule";
import { dynamicRequiredValidator } from "utils/dynamicRequiredValidator";
import { SCHEMA_OPTIONS } from "const/slingshotAliasConst";
import lengthValidator from "utils/lengthValidator";
import { withoutSpace } from "utils/validators/withoutSpace";

const createFields = () => {
    return [
        {
            name: "name",
            rules: "required",
            validators: [
                isFieldEmpty(i18n.t("rule.field.empty.common_field")),
                regexValidator(/^[a-zA-Z0-9-][a-zA-Z0-9-]*$/, i18n.t("rule.alias.name")),
                regexValidator(/^[a-zA-Z0-9](.*[a-zA-Z0-9])?$/, i18n.t("rule.alias.name.start_end_symbol")),
                lengthValidator(1, 255, i18n.t("slingshot_page.tabs.settings.aliases.name_length.hint", { count: 255 })),
            ],
        },
        { name: "schema" },
        {
            name: "host",
            placeholder: i18n.t("slingshot_page.tabs.settings.aliases.host_placeholder"),
            rules: "required",
            validators: [withoutSpace(i18n.t("rule.field.without_space"))],
        },
        { name: "mediaPlayer", rules: "required" },
        { name: "resource", rules: "required" },
        {
            name: "user",
            validators: [
                dynamicRequiredValidator([
                    {
                        field: "schema",
                        values: [
                            SCHEMA_OPTIONS.smb,
                            SCHEMA_OPTIONS.ftp,
                            SCHEMA_OPTIONS.s3,
                            SCHEMA_OPTIONS.azure,
                            SCHEMA_OPTIONS.dropbox,
                            SCHEMA_OPTIONS.tricaster,
                        ],
                    },
                ]),
            ],
        },
        {
            name: "password",
            type: "password",
            validators: [
                dynamicRequiredValidator([
                    {
                        field: "schema",
                        values: [
                            SCHEMA_OPTIONS.smb,
                            SCHEMA_OPTIONS.ftp,
                            SCHEMA_OPTIONS.s3,
                            SCHEMA_OPTIONS.azure,
                            SCHEMA_OPTIONS.dropbox,
                        ],
                    },
                ]),
            ],
        },
        { name: "path" },
        { name: "personal", type: "checkbox" },
        { name: "ftpUserDirIsRoot", type: "checkbox" },
        { name: "ftpPassive", type: "checkbox" },
        { name: "regionChoose", type: "radio" },
        { name: "region" },
        { name: "verifier" },
    ];
};

export default createFields;
