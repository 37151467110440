import React from "react";
import { Grid, TableCell, Typography } from "@material-ui/core";
import { useStyles } from "./TableCellWithIcon.styles";
import clsx from "clsx";

const TableCellWithIcon = ({ icon, spacing = 2, iconPosition = "left", children, className, withWrap, ...rest }) => {
    const classes = useStyles({ iconPosition });
    return (
        <TableCell className={clsx(classes.noOpacityCell, className)} {...rest}>
            <Grid className={classes.container} container spacing={spacing} alignItems={"center"} wrap={"nowrap"}>
                <Grid item>{icon}</Grid>
                <Grid item>
                    <Typography noWrap={!withWrap}>{children}</Typography>
                </Grid>
            </Grid>
        </TableCell>
    );
};

export default TableCellWithIcon;
