import React from "react";

const AddCategory = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="nonzero"
                d="M18.5 10a1 1 0 01-1-1V7h-2a1 1 0 010-2h2V3a1 1 0 012 0v2h2a1 1 0 010 2h-2v2a1 1 0 01-1 1zm-12 11a3 3 0 01-2.995-2.824L3.5 18V8a3 3 0 012.824-2.995L6.5 5h5a1 1 0 010 2h-5a1 1 0 00-.993.883L5.5 8v10a1 1 0 00.883.993L6.5 19h10a1 1 0 00.993-.883L17.5 18v-5a1 1 0 012 0v5a3 3 0 01-2.824 2.995L16.5 21h-10z"
            />
        </svg>
    );
};

export default AddCategory;
