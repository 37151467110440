import { makeStyles } from "@material-ui/core/styles";

import { GRAPH_INFO_HEIGHT } from "const/styleConst";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        justifyContent: "space-between",
    },
    svg: {
        "& .axis": {
            "& path": {
                color: ({ isDark }) => (isDark ? theme.palette.grey[300] : theme.palette.graphs.border),
                strokeWidth: 0.1,
            },
            "& text": {
                color: ({ isDark }) => (isDark ? theme.palette.grey[300] : theme.palette.graphs.text),
                opacity: 0.5,
                fontSize: 7,
                fontWeight: 100,
                letterSpacing: 1,
            },
            "& .tick": {
                color: ({ isDark }) => (isDark ? theme.palette.grey[700] : theme.palette.graphs.grid),
                strokeWidth: 0.2,
                opacity: 1,
            },
        },
        "& .y.axis": {
            textAnchor: "start",
        },
        "& .graph": {
            fill: "none",
            strokeWidth: 2,

            "&.graphIn": {
                stroke: theme.palette.primary.main,
            },

            "&.graphOut": {
                stroke: theme.palette.success.main,
            },
        },
        "& .pointIn": {
            fill: theme.palette.success.main,
            stroke: theme.palette.success.main,
        },
        "& .pointOut": {
            fill: theme.palette.primary.main,
            stroke: theme.palette.primary.main,
        },
        "& .areaIn": {
            fill: theme.palette.success.main,
            opacity: 0.1,
        },
        "& .areaOut": {
            fill: theme.palette.primary.main,
            opacity: 0.1,
        },
    },
    info: {
        paddingTop: 10,
        flexShrink: 0,
        height: GRAPH_INFO_HEIGHT,
    },
    name: {
        color: ({ isDark }) => (isDark ? theme.palette.common.white : theme.palette.text),
        fontWeight: "bold",
        fontSize: 12,
    },
    label: {
        color: ({ isDark }) => (isDark ? theme.palette.common.white : theme.palette.text),
        opacity: 0.4,
        fontSize: 12,
        fontWeight: 500,
    },
    value: {
        color: theme.palette.success.main,
        fontWeight: "bold",
        fontSize: 16,

        "&.valueIn": {
            color: theme.palette.primary.main,
        },

        "& .MuiBox-root": {
            fontSize: 12,
            fontWeight: "bold",
            marginLeft: theme.spacing(1),
        },
    },
    graph: {
        flexShrink: 1,
    },
}));
