import React from "react";
import { Button, ButtonBase, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Checkbox from "components/Checkbox";
import TableSortLabel from "components/TableSortLabel";
import { useLocalStore, useObserver } from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./CreateBondDialog.styles";
import TableCellWithIcon from "components/TableCellWithIcon";
import EthernetIcon from "assets/EthernetIcon";
import {
    NET_IFACE_IP,
    NET_IFACE_MTU,
    NET_IFACE_PORT,
    NET_IFACE_SPEED,
    NET_IFACE_STATUS,
    NET_IFACE_MAX_SPEED_GBS
} from "const/sortColumnConst";
import clsx from "clsx";
import EthernetIconDisabled from "assets/EthernetIconDisabled";
import sortByLastNumberInStr from "utils/sortByLastNumberInStr";
import StatusTranslation from "../StatusTranslation";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import CloseIcon from "assets/CloseIcon";
import Dialog from "components/Dialog";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import useStoreByIp from "hooks/useStoreByIp";
import { useModal } from "hooks/useModal";

const CreateBondDialog = ({ open, onClose, ip }) => {
    const { t } = useTranslation();
    const { bondsStore, ethernetPortsStore } = useStoreByIp({ ip });
    const state = useLocalStore(() => ({
        ifaces: [],
        isSubmitted: false,
        get mainCheckboxStatus() {
            if (this.ifaces.length === bondsStore.sortedAllFreeIfacesForAggregation.length) {
                return "checked";
            } else if (this.ifaces.length) {
                return "indeterminated";
            }
            return null;
        },
        isRowDisabled(iface) {
            return (
                this.ifaces.length !== 0 && (this.ifaces[0].maxSpeedGbs !== iface.maxSpeedGbs || this.ifaces[0].mtu !== iface.mtu)
            );
        },
        get sortedCheckedBonds() {
            return sortByLastNumberInStr(this.ifaces.slice(), NET_IFACE_PORT);
        }
    }));

    const unsavedChangesModal = useModal();

    const classes = useStyles({ portChecked: state.ifaces.length });
    const handleMainCheckbox = () => {
        switch (state.mainCheckboxStatus) {
            case "checked":
                state.ifaces.clear();
                break;
            case "indeterminated":
                state.ifaces.clear();
                break;
            default:
                state.ifaces = bondsStore.sortedAllFreeIfacesForAggregation.slice();
                state.ifaces = state.ifaces.filter(iface => !state.isRowDisabled(iface));
                break;
        }
    };
    const handleCheckbox = checkedIface => () => {
        let index = state.ifaces.findIndex(iface => iface === checkedIface);
        if (index === -1) {
            state.ifaces.push(checkedIface);
        } else {
            state.ifaces.splice(index, 1);
        }
    };
    const isIfaceChecked = checkedIface => {
        return !!state.ifaces.find(iface => iface === checkedIface);
    };
    const onCreate = async () => {
        const isSubmitted = !!(await bondsStore.createAggregatedIfaces({
            ports: state.sortedCheckedBonds.map(iface => iface.port)
        }));
        if (!isSubmitted) {
            return;
        }
        state.isSubmitted = true;
        ethernetPortsStore.fetchAllNetRequests();

        return isSubmitted;
    };
    const onOpen = () => {
        ethernetPortsStore.fetchAllIfaces();
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };
    const closeHandler = () => {
        state.ifaces.clear();
    };

    const closeIfNeeded = () => {
        if (state.ifaces.length === bondsStore.sortedAllFreeIfacesForAggregation.length || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const changeSorting = column => () => bondsStore.changeSortingDialog(column);
    const renderSelectedPortsMessage = () => {
        return (
            <Grid className={classes.portsMessageContainer} container>
                <Grid item>
                    <Grid alignItems={"center"} container spacing={4}>
                        <Grid item>
                            <EthernetIcon />
                        </Grid>
                        <Grid item>
                            <Typography>{state.sortedCheckedBonds.map(iface => iface.port).join(", ")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography>{state.sortedCheckedBonds[0].ip}</Typography>
                </Grid>
            </Grid>
        );
    };

    return useObserver(() => (
        <Dialog
            onClose={closeIfNeeded}
            onEnter={onOpen}
            onExited={closeHandler}
            maxWidth={"lg"}
            open={open}
            withoutPaddings
            buttons={
                <Button variant={"contained"} color={"secondary"} onClick={onClose}>
                    {t("common.button.cancel")}
                </Button>
            }
            disableSubmit={!state.ifaces.length}
            submitBtnLabel={t("network.create_interface_dialog.button.create")}
            onSubmit={onCreate}
            header={
                <>
                    <DialogTitle>
                        <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container>
                            <Grid item>{t("network.create_interface_dialog.title")}</Grid>
                            <Grid item>
                                <CardHeaderControlButton onClick={closeIfNeeded}>
                                    <ButtonBase>
                                        <CloseIcon />
                                    </ButtonBase>
                                </CardHeaderControlButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Grid container justify={"center"} alignItems={"center"} className={classes.messageContainer}>
                        <Grid item>
                            {state.ifaces.length ? (
                                renderSelectedPortsMessage()
                            ) : (
                                <Typography variant={"body2"}>{t("network.create_interface_dialog.message")}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </>
            }
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell padding={"checkbox"}>
                            <Checkbox
                                indeterminate={state.mainCheckboxStatus === "indeterminated"}
                                checked={state.mainCheckboxStatus === "checked"}
                                onChange={handleMainCheckbox}
                            />
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                direction={bondsStore.orderDialog}
                                onClick={changeSorting(NET_IFACE_PORT)}
                                active={bondsStore.orderByDialog === NET_IFACE_PORT}
                            >
                                {t("network.create_interface_dialog.table_title.ethernet_port")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                direction={bondsStore.orderDialog}
                                onClick={changeSorting(NET_IFACE_IP)}
                                active={bondsStore.orderByDialog === NET_IFACE_IP}
                            >
                                {t("network.create_interface_dialog.table_title.ip_address")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                direction={bondsStore.orderDialog}
                                onClick={changeSorting(NET_IFACE_SPEED)}
                                active={bondsStore.orderByDialog === NET_IFACE_SPEED}
                            >
                                {t("network.create_interface_dialog.table_title.current_speed")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                direction={bondsStore.orderDialog}
                                onClick={changeSorting(NET_IFACE_STATUS)}
                                active={bondsStore.orderByDialog === NET_IFACE_STATUS}
                            >
                                {t("network.create_interface_dialog.table_title.status")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                direction={bondsStore.orderDialog}
                                onClick={changeSorting(NET_IFACE_MTU)}
                                active={bondsStore.orderByDialog === NET_IFACE_MTU}
                            >
                                {t("network.create_interface_dialog.table_title.mtu")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                direction={bondsStore.orderDialog}
                                onClick={changeSorting(NET_IFACE_MAX_SPEED_GBS)}
                                active={bondsStore.orderByDialog === NET_IFACE_MAX_SPEED_GBS}
                            >
                                {t("network.ethernet_ports_drawer.label.type")}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bondsStore.sortedAllFreeIfacesForAggregation.map(iface => {
                        const isDisabled = state.isRowDisabled(iface);
                        return (
                            <TableRow className={clsx(isDisabled && classes.disabledRow)} key={iface.port}>
                                <TableCell>
                                    <Checkbox
                                        disabled={isDisabled}
                                        checked={isIfaceChecked(iface)}
                                        onChange={isDisabled ? null : handleCheckbox(iface)}
                                    />
                                </TableCell>
                                <TableCellWithIcon
                                    className={clsx(isDisabled && classes.disabledCellWithIcon)}
                                    icon={isDisabled ? <EthernetIconDisabled /> : <EthernetIcon />}
                                    spacing={4}
                                >
                                    {iface.port}
                                </TableCellWithIcon>
                                <TableCell>{iface.ip}</TableCell>
                                <TableCell>{iface.speed}</TableCell>
                                <TableCell>
                                    <StatusTranslation label={iface.status} />
                                </TableCell>
                                <TableCell>{iface.mtu}</TableCell>
                                <TableCell>{t("common.speed_value.gbs", { speed: iface.maxSpeedGbs })}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default CreateBondDialog;
