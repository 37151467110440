import { types } from "mobx-state-tree";

import RebalanceNode from "api/cluster/Types/RebalanceNode";
import RebalanceAggregate from "api/cluster/Types/RebalanceAggregate";

const RebalanceStatusData = types.model({
    nodes: types.array(RebalanceNode),
    aggregate: types.maybe(RebalanceAggregate)
});

export default RebalanceStatusData;
