import { STORAGE_DISK } from "const/replicationLogConst";
import isFieldEmpty from "utils/isFieldEmptyRule";
import i18n from "i18n";

const createFields = () => {
    return [
        {
            name: "storage",
            type: "radio",
            default: STORAGE_DISK
        },
        {
            name: "share",
            validators: [isFieldEmpty(i18n.t("rule.field.empty.common_field"))]
        },
        {
            name: "folder",
            validators: [isFieldEmpty(i18n.t("rule.field.empty.common_field"))]
        }
    ];
};

export default createFields;
