import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    row: {
        "&:not(.MuiTableRow-head):hover": {
            backgroundColor: "inherit"
        },
        "& .MuiTableCell-root": {
            padding: theme.spacing(2.5, 2)
        },
        "&:first-child .MuiTableCell-root": {
            paddingTop: theme.spacing(6)
        },
        "&:last-child .MuiTableCell-root": {
            paddingBottom: theme.spacing(8.5)
        }
    }
}));
