import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    stepper: {
        padding: 0,
        flex: 1,
        "& .MuiStepIcon-text": {
            fontSize: 14,
            fontWeight: 500
        },
        "& .MuiStepIcon-root circle": {
            fill: theme.palette.primary.main
        }
    },
    step: {
        paddingLeft: 0,
        paddingRight: theme.spacing(6)
    },
    label: {
        "&& .MuiStepLabel-label": {
            color: theme.palette.primary.main,
            fontSize: 16,
            fontWeight: 500
        },
        "&.Mui-disabled": {
            opacity: 0.4
        }
    }
}));
