import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Context } from "store";
import SavedInputPreview from "components/SavedInputPreview";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography } from "@material-ui/core";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { NAS_RECYCLE_BIN_CARD } from "const/widgetNameConst";
import { clearIntervalToTrKey } from "const/trashSettingsConst";
import NasRecycleBinDialog from "../NasRecycleBinDialog/NasRecycleBinDialog";
import { ADVANCED_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const NAS_RECYCLE_BIN_CARD_ID_PREFIX = `${ADVANCED_PAGE_ID_PREFIX}_nas_recycle_bin_card`;

const NasRecycleBinCard = () => {
    const { t } = useTranslation();

    const {
        store: { recycleBinStore }
    } = useContext(Context);

    useEffect(() => {
        refreshStatuses();
    }, []);

    const refreshStatuses = async () => {
        state.inProgress = true;
        await recycleBinStore.fetchTrashSettings();
        state.inProgress = false;
    };

    const state = useLocalStore(() => ({
        isEditDialogOpened: false,
        inProgress: false
    }));

    // Callbacks
    const openDialog = useCallback(() => {
        state.isEditDialogOpened = true;
    });

    const closeDialog = useCallback(() => {
        state.isEditDialogOpened = false;
    });

    return useObserver(() => (
        <>
            <NasRecycleBinDialog closeDialog={closeDialog} open={state.isEditDialogOpened} />
            <DataReadonlyCard
                headerDivider
                title={t("advanced.nas_recycle_bin.title")}
                control={
                    <MoreMenu id={`${NAS_RECYCLE_BIN_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={NAS_RECYCLE_BIN_CARD} />
                    </MoreMenu>
                }
                onClick={openDialog}
            >
                {recycleBinStore.trashSettings ? (
                    <>
                        <Grid container direction={"column"} spacing={5}>
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("advanced.nas_recycle_bin.label.purge")}</Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <SavedInputPreview>
                                        {t(clearIntervalToTrKey(recycleBinStore.trashSettings?.clearInterval))}
                                    </SavedInputPreview>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <EmptyCardPlaceholder inProgress={state.inProgress} />
                )}
            </DataReadonlyCard>
        </>
    ));
};
export default NasRecycleBinCard;
