import React from "react";
import FormControlLabel from "../FormControlLabel";
import { useStyles } from "./RowControlLabelSwitch.styles";
import clsx from "clsx";
import { Grid } from "@material-ui/core";

const RowControlLabelSwitch = ({ className, label, control, lastColumnWidth }) => {
    const classes = useStyles({ width: lastColumnWidth });

    return (
        <Grid className={classes.container} item container alignItems={"center"}>
            <FormControlLabel className={clsx(classes.controlContainer, className)} control={control} label={label} />
        </Grid>
    );
};

export default RowControlLabelSwitch;
