import { types } from "mobx-state-tree";

import NotificationEvent from "api/event/Types/NotificationEvent";

const NotificationEventsPage = types.model({
    data: types.array(NotificationEvent),
    total: types.number
});

export default NotificationEventsPage;
