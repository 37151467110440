import React from "react";

const WorldOffIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                d="M3.613 2.21l.094.083 12.28 12.279.277.27-.003.004 2.086 2.085.028-.036 1.415 1.376-.048.056 1.965 1.966a1 1 0 01-1.32 1.497l-.094-.083-1.965-1.964A9.96 9.96 0 0112 22C6.477 22 2 17.523 2 12c0-2.401.846-4.604 2.257-6.328L2.293 3.707a1 1 0 011.32-1.497zm6.527 12.86a4.998 4.998 0 01-2.88 1H7l-.405-.015a8.504 8.504 0 01-1.2-.163L5 15.8l.138.246a8 8 0 006.57 3.947L12 20l.358-.01a7.914 7.914 0 002.431-.523l.331-.137-.046-.197a2.276 2.276 0 00-.348-.724l-.126-.159-.194-.301-.186-.309c-.535-.914-.568-1.693-.382-2.387l-2.244-2.244c-.226.72-.652 1.433-1.454 2.061zM12 2l.28.004c5.3.146 9.57 4.416 9.716 9.716L22 12l-.004.28a9.935 9.935 0 01-1.095 4.282l-1.496-1.454a7.93 7.93 0 00.43-4.698l-.075-.32-.148.011c-.964.08-2.37.295-2.522.989a5.51 5.51 0 00-.08.665l-.01.335c.01.221.005.442-.016.66l-1.891-1.838.047-.262.049-.194c.495-1.7 2.432-2.143 3.811-2.336a7.993 7.993 0 00-3.062-3.07l-.278-.15-.106.241a4.543 4.543 0 01-1.092 1.46l-.202.169-.299.215-.307.203-.314.192-.187.094a2.857 2.857 0 00-.803.652l-.053.066-1.437-1.397c.34-.396.746-.733 1.201-.993l.249-.132.24-.148c.158-.099.313-.199.46-.312.282-.22.515-.495.685-.808l.095-.192-.354-.074a8.427 8.427 0 00-1.075-.126L12 4l-.25.004a7.966 7.966 0 00-3.037.7L7.195 3.228A9.951 9.951 0 0112 2zm-7.994 9.7L4 12l.009.362c.011.24.033.481.065.72l.056.358c1.3.54 3.39 1.14 4.76.07.798-.597.926-1.257.946-2.259L5.68 7.095A7.96 7.96 0 004.006 11.7z"
            ></path>
        </svg>
    );
};

export default WorldOffIcon;
