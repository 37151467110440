import Request from "api/Request";

const GetFirewallAdvancedInfo = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Firewall",
    method: "get_firewall_advanced_info",
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetFirewallAdvancedInfo
