import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    headerContainer: {
        padding: theme.spacing(5, 6, 4)
    },
    headerContainerInner: {
        overflow: "hidden",
        width: "100%"
    },
    headerCloseButton: {
        "& path": {
            fill: theme.palette.grey["300"]
        }
    },
    headerInfoContainerIcon: {
        maxWidth: `calc(100% - ${theme.spacing(8)}px)`
    },
    headerInfoContainer: {
        maxWidth: "100%"
    },
    headerButton: {
        "&:hover svg path": {
            fill: theme.palette.grey[300]
        }
    }
}));
