import React from "react";
import { useStyles } from "./ErrorButton.styles";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";

const ErrorButton = props => {
    const classes = useStyles();
    return <AnimatedSubmitButton {...props} className={classes.buttonError} />;
};

export default ErrorButton;
