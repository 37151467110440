import React, { useEffect } from "react";
import { Dialog, Button, ButtonBase, Divider, Grid, Typography } from "@material-ui/core";
import CloseIcon from "assets/CloseIcon";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./RenewCertificateCard.styles";
import createFields from "../createCertificateFields";
import createForm from "utils/createForm";
import { useTranslation } from "react-i18next";
import FormLayout from "../FormLayout";
import DownloadCsrLayout from "../DownloadCsrLayout";
import NewPrivateKeyLayout from "../NewPrivateKeyLayout";
import Stepper from "../Stepper";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { COUNTRY_CODES, COUNTRY_CODES_DEFAULT } from "const/sslCertificateCountryCodesConst";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";

const RENEW_CERTIFICATE_CARD_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_renew_certificate_card`;

const RenewCertificateCard = ({ open, onClose, onBack }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const fields = createFields();
    const steps = [
        t("admin_settings.server_certificate.step_name.new_private_key"),
        t("admin_settings.server_certificate.step_name.fill_in_information"),
        t("admin_settings.server_certificate.step_name.download_csr")
    ];
    const {
        store: { certificateStore, processingStore, webserverStore }
    } = useStore();

    useEffect(() => {
        webserverStore.fetchConfigInfo();
    }, []);

    const state = useLocalStore(() => ({
        form: createForm({
            fields
        }),
        csrName: null,
        currentStep: 0,
        isSubmitted: false,
        createNewPrivateKeyIsSubmitted: false,
        inProgress: false
    }));

    const unsavedChangesModal = useModal();

    const onNextClick = e => {
        switch (state.currentStep) {
            case 0:
                createNewPrivateKey();
                break;
            case 1:
                submit(e);
                break;

            default:
                break;
        }
    };

    const decreaseStep = () => {
        state.currentStep--;
    };

    const onBackClick = () => {
        state.currentStep === 0 ? onBack() : decreaseStep();
    };

    const resolveSubmitCondition = () => {
        state.isSubmitted = true;
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    };

    const createNewPrivateKey = async () => {
        state.inProgress = true;
        const res = await certificateStore.generateNewPrivateKey();
        state.inProgress = false;
        if (res) {
            await resolveSubmitCondition();
            state.currentStep++;
            state.isSubmitted = false;
        }
    };

    const submit = async e => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        state.inProgress = true;
        state.csrName = await certificateStore.createCertificateSigningRequest({
            commonName: state.form.$("commonName").value,
            organization: state.form.$("organization").value,
            department: state.form.$("department").value,
            country: state.form.$("country").value.value,
            stateName: state.form.$("state").value,
            city: state.form.$("city").value
        });
        state.inProgress = false;
        if (state.csrName) {
            await resolveSubmitCondition();
            state.currentStep++;
            state.isSubmitted = false;
        }
    };

    const downloadCsr = async () => {
        const isSubmitted = await certificateStore.downloadCertificate(state.csrName);
        if (isSubmitted) {
            setTimeout(onClose, 1000);
        }
    };

    const getStepContent = () => {
        switch (state.currentStep) {
            case 0:
                return <NewPrivateKeyLayout isSslEnabled={webserverStore.configInfo && webserverStore.configInfo.sslEnabled} />;
            case 1:
                return (
                    <Grid className={classes.stepperContainer}>
                        <FormLayout form={state.form} />
                    </Grid>
                );
            case 2:
                return <DownloadCsrLayout />;
            default:
                return null;
        }
    };

    const renderControl = () => {
        if (state.currentStep === 0 && (!webserverStore.configInfo || webserverStore.configInfo.sslEnabled))
            return (
                <Grid item>
                    <Button
                        onClick={onClose}
                        variant={"contained"}
                        color={"primary"}
                        id={`${RENEW_CERTIFICATE_CARD_ID_PREFIX}_close`}
                    >
                        {t("common.button.close")}
                    </Button>
                </Grid>
            );
        return state.currentStep === steps.length - 1 ? (
            <Grid item>
                <Button
                    disabled={processingStore.loading}
                    onClick={downloadCsr}
                    variant={"contained"}
                    color={"primary"}
                    id={`${RENEW_CERTIFICATE_CARD_ID_PREFIX}_download`}
                >
                    {t("common.button.download")}
                </Button>
            </Grid>
        ) : (
            <Grid item>
                <AnimatedSubmitButton
                    disabled={processingStore.loading}
                    inProgress={state.inProgress}
                    isSubmitted={state.isSubmitted}
                    label={t("common.button.next")}
                    submit={onNextClick}
                    id={`${RENEW_CERTIFICATE_CARD_ID_PREFIX}_next`}
                />
            </Grid>
        );
    };
    const onEnter = () => {
        const defaultCountry = COUNTRY_CODES.find(el => el.value === COUNTRY_CODES_DEFAULT);
        state.form.$("country").set("default", defaultCountry);
        state.form.reset();
        state.form.$("commonName").resetValidation();
        state.csrName = null;
        state.currentStep = 0;
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.createNewPrivateKeyInProgress = false;
        state.createNewPrivateKeyIsSubmitted = false;
        state.inProgress = false;
        state.form.$("commonName").resetValidation();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }

        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog maxWidth={"md"} fullWidth open={open} onClose={closeIfNeeded} onEnter={onEnter}>
            <Grid container flex-direction={"column"}>
                <Grid item container justify={"space-between"} alignItems={"center"} className={classes.headerContainer}>
                    <Grid item>
                        <Typography variant={"h2"}>{t("admin_settings.server_certificate.renew_certificate.title")}</Typography>
                    </Grid>
                    <Grid item>
                        <CardHeaderControlButton onClick={closeIfNeeded}>
                            <ButtonBase id={`${RENEW_CERTIFICATE_CARD_ID_PREFIX}_close_if_needed`}>
                                <CloseIcon />
                            </ButtonBase>
                        </CardHeaderControlButton>
                    </Grid>
                </Grid>
                <Grid item container className={classes.stepperContainer}>
                    <Stepper steps={steps} activeStep={state.currentStep} />
                </Grid>
            </Grid>
            <Divider />
            {getStepContent()}
            <Divider />
            <Grid container alignItems={"center"} justify={"flex-end"} spacing={4} className={classes.controlsContainer}>
                <Grid item>
                    <Button
                        onClick={onBackClick}
                        variant={"contained"}
                        color={"secondary"}
                        id={`${RENEW_CERTIFICATE_CARD_ID_PREFIX}_back`}
                    >
                        {t("common.button.back")}
                    </Button>
                </Grid>
                {renderControl()}
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default RenewCertificateCard;
