import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    contentContainer: {
        width: "100%",
        margin: 0,
        padding: theme.spacing(0, 4, 6, 4)
    },
    list: {
        padding: 0,
        "& li": {
            padding: 0
        },
        "& .MuiListItemIcon-root": {
            minWidth: theme.spacing(8)
        }
    }
}));
