import { makeStyles } from "@material-ui/core/styles";
import {
    HEADER_HEIGHT,
    GRAPHICS_BAR_HEIGHT,
    STATE_BAR_HEIGHT,
    GRAPHICS_BAR_HEIGHT_MOBILE,
    STATE_BAR_HEIGHT_MOBILE,
    HEADER_HEIGHT_MOBILE
} from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    mainContainer: {
        backgroundColor: theme.palette.common.black,
        boxSizing: "border-box"
    },
    contentContainer: {
        [theme.breakpoints.up("md")]: {
            height: HEADER_HEIGHT
        },
        height: HEADER_HEIGHT_MOBILE,
        transition: "height ease-in-out 0.1s",
        flexWrap: "nowrap",
        overflow: "hidden"
    },
    openContentContainer: {
        [theme.breakpoints.up("md")]: {
            height: HEADER_HEIGHT + GRAPHICS_BAR_HEIGHT + STATE_BAR_HEIGHT
        },
        height: HEADER_HEIGHT_MOBILE + GRAPHICS_BAR_HEIGHT_MOBILE + STATE_BAR_HEIGHT_MOBILE,
        overflow: "visible"
    },
    muiContainer: {
        padding: theme.spacing(0, 6)
    },
    widgetsBar: {
        opacity: 0,
        transition: "opacity ease-in-out 0.1s"
    },
    openedWidgetsBar: {
        opacity: 1,
        transition: "opacity ease-in-out 0.1s 0.1s"
    }
}));
