import { t } from "i18next";

export const getCurrentBackupName = backupSettingsStore => {
    try {
        return backupSettingsStore.currentBackup && backupSettingsStore.currentBackup.id === backupSettingsStore.evoId
            ? t("support.os_data.backups.table.this_evo")
            : t("support.os_data.backups.table.foreign_evo");
    } catch (error) {
        return t("support.os_data.backups.table.foreign_evo");
    }
};
