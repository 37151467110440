import { types } from "mobx-state-tree";

import Request from "api/Request";
import UsersAndGroupsPageQuery from "api/security/users/Types/UsersAndGroupsPageQuery";

const SearchUsersAndGroups = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Users",
    method: "search_users_and_groups",
    arguments: types.maybe(UsersAndGroupsPageQuery)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SearchUsersAndGroups;
