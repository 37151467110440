import { types } from "mobx-state-tree";

import DomainObjectId from "api/security/Types/DomainObjectId";

const SetOwnerArguments = types.model({
    shareName: types.string,
    path: types.string,
    recursive: types.boolean,
    owner: types.maybe(DomainObjectId)
});

export default SetOwnerArguments;
