import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Context } from "store";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography } from "@material-ui/core";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { DISK_USAGE_THRESHOLD_CARD } from "const/widgetNameConst";
import DiskUsageThresholdDialog from "../DiskUsageThresholdDialog/DiskUsageThresholdDialog";
import { useStyles } from "./DiskUsageThresholdCard.styles";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const DISK_USAGE_THRESHOLD_CARD_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_disk_usage_threshold_card`;

const DiskUsageThresholdCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        store: { alertsStore }
    } = useContext(Context);

    useEffect(() => {
        refreshStatuses();
    }, []);

    const refreshStatuses = async () => {
        state.inProgress = true;
        await alertsStore.fetchUsageThreshold();
        state.inProgress = false;
    };

    const state = useLocalStore(() => ({
        isEditDialogOpened: false,
        inProgress: false
    }));

    // Callbacks
    const openDialog = useCallback(() => {
        state.isEditDialogOpened = true;
    });

    const closeDialog = useCallback(() => {
        state.isEditDialogOpened = false;
    });

    return useObserver(() => (
        <>
            <DiskUsageThresholdDialog closeDialog={closeDialog} open={state.isEditDialogOpened} />
            <DataReadonlyCard
                headerDivider
                emptyPadding
                title={t("alerts.disk_usage_threshold.title")}
                control={
                    <MoreMenu id={`${DISK_USAGE_THRESHOLD_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={DISK_USAGE_THRESHOLD_CARD} />
                    </MoreMenu>
                }
                onClick={openDialog}
            >
                {alertsStore.usedSpaceEventsSettings ? (
                    <Grid direction={"column"} container spacing={5} className={classes.container}>
                        <Grid container item>
                            <Typography variant={"body2"}>{t("alerts.disk_usage_threshold.threshold_card.label")}</Typography>
                        </Grid>

                        <Grid container item spacing={1}>
                            <Grid xs={6} item>
                                <Typography>{t("alerts.disk_usage_threshold.threshold_card.threshold")}</Typography>
                            </Grid>
                            <Grid xs={6} item>
                                <Typography variant={"h6"}>{alertsStore.usedSpaceEventsSettings?.spaceThreshold}%</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder inProgress={state.inProgress} />
                )}
            </DataReadonlyCard>
        </>
    ));
};
export default DiskUsageThresholdCard;
