import { types } from "mobx-state-tree";

const PROCESSED_FILES_RESULTS = ["SUCCEED", "FAILED", "PARTIALLY FAILED", "CANCELED"];
const TASK_RESULTS = ["IN PROGRESS", "SUCCEED", "CANCELED", "FAILED", "PAUSED", "WAITED FOR RESTART", "SKIPPED"];

const ProcessedFileType = types.model({
    id: types.number,
    automation_id: types.maybe(types.number),
    automation_instance_id: types.maybe(types.number),
    result: types.maybe(types.string),
    event: types.maybe(
        types.model({
            id: types.number,
            source: types.maybe(types.string),
            source_sbs_volume_uuid: types.maybe(types.string),
            source_sbs_file_id: types.maybe(types.number),
            destination: types.maybe(types.string),
            destination_sbs_volume_uuid: types.maybe(types.string),
            destination_sbs_file_id: types.maybe(types.number),
            watch_mask: types.maybe(types.number),
            caught: types.maybe(types.string),
            processed: types.maybe(types.string),
            file_size: types.maybe(types.number),
            walk_id: types.maybe(types.number),
            object_type: types.maybe(types.string),
            result: types.maybe(types.enumeration(PROCESSED_FILES_RESULTS))
        })
    ),
    task_results: types.maybe(
        types.array(
            types.model({
                id: types.number,
                task_id: types.number,
                started: types.maybe(types.string),
                finished: types.maybe(types.string),
                progress: types.maybe(types.number),
                result: types.maybe(types.enumeration(TASK_RESULTS)),
                message: types.maybe(types.string),
                details: types.maybe(types.string),
                location: types.maybe(types.string),
                action: types.maybe(types.string)
            })
        )
    )
});

export default ProcessedFileType;
