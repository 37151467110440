import i18n from "i18n";

export const CONNECTED = "connected";
export const CONNECTING = "connecting";
export const NOT_CONNECTED = "not_connected";

export const LDAP = "ldap";
export const AD = "activeDirectory";

export const ENCRYPTION_OPTIONS = {
    none: { value: "none", label: i18n.t("users.sync_modal.encryption_options.none") },
    ssl: { value: "ssl", label: i18n.t("users.sync_modal.encryption_options.ssl") },
    tls: { value: "tls", label: i18n.t("users.sync_modal.encryption_options.tls") }
};
