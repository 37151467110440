import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    contentContainer: {
        width: "100%",
        margin: 0,
        padding: theme.spacing(5, 5, 7, 5)
    },
    controlsContainer: {
        width: "100%",
        margin: 0,
        padding: theme.spacing(2, 4)
    }
}));
