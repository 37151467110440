import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    alertContainer: {
        marginBottom: theme.spacing(6),
        "& .MuiTypography-root": {
            lineHeight: 1.29
        }
    }
}));
