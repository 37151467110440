import { makeStyles } from "@material-ui/core/styles";

const DIALOG_WIDTH = 640;
export const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: DIALOG_WIDTH,
        "& $dialogContent": {
            overflowX: "hidden"
        }
    },
    contentContainer: {
        overflow: "hidden",
        padding: theme.spacing(0, 4)
    },
    dialogTitle: {
        paddingBottom: theme.spacing(4),
        "& $step": {
            fontSize: "initial"
        }
    },
    inputsContainer: {
        "& .MuiTypography-root": {
            opacity: "initial",
            fontSize: "inherit",
            fontWeight: "inherit",
            letterSpacing: "initial",
            lineHeight: "initial"
        }
    },
    checkboxContainer: {
        "& .MuiTypography-root": {
            opacity: "initial",
            fontSize: "initial",
            fontWeight: 500,
            letterSpacing: "initial",
            lineHeight: "initial"
        },
        "& .MuiCheckbox-root": {
            marginRight: theme.spacing(1.5)
        },
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2)
    },
    expansionPanel: {
        boxShadow: "none",
        "&:before": {
            opacity: 0
        },
        "&&": {
            margin: 0
        }
    },
    expansionSummary: {
        "& .MuiCheckbox-root": {
            marginRight: theme.spacing(1.5)
        },
        "& .MuiTypography-root": {
            fontWeight: 500,
            opacity: 1,
            letterSpacing: "initial",
            lineHeight: "initial"
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
            marginTop: theme.spacing(1.5) + 1,
            marginBottom: theme.spacing(1.5) + 1
        },
        "& .MuiAccordionSummary-expandIcon svg path": {
            fill: theme.palette.primary.main
        }
    },
    expansionDetails: {
        padding: theme.spacing(0, 0, 0, 16)
    },
    step: {
        "&&": {
            fontWeight: 500,
            opacity: 0.4
        }
    },
    activeStep: {
        "&&": {
            opacity: 1
        }
    },
    formControlLabel: {
        alignItems: "flex-start",
        "& .MuiCheckbox-root": {
            top: -9,
            marginRight: theme.spacing(1.5)
        }
    }
}));
