import React from "react";
import { Grid, Typography, LinearProgress } from "@material-ui/core";
import PoolPicture from "assets/PoolPicture";
import { useStyles } from "./PoolHeader.style";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react";
import { convertBytesToSizeUnit } from "utils/convertBytesToSizeUnit";
import ChevronRightIcon from "assets/ChevronRightIcon";
import useStoreByIp from "hooks/useStoreByIp";

const PoolHeader = ({ pool, ip }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { volumeStore } = useStoreByIp({ ip });

    const totalSize = convertBytesToSizeUnit({ bytes: pool.sizeTotal, output: "object" });
    const sizes = {
        total: `${totalSize.value} ${totalSize.symbol}`,
        free: convertBytesToSizeUnit({ bytes: pool.sizeFree, exponent: totalSize.exponent }),
        allocated: convertBytesToSizeUnit({ bytes: pool.sizeTotal - pool.sizeFree, exponent: totalSize.exponent }),
    };
    const getProgressValue = () => {
        return ((pool.sizeTotal - pool.sizeFree) / pool.sizeTotal) * 100;
    };

    return useObserver(() => (
        <Grid container>
            <Grid item>
                <PoolPicture className={classes.mainPicture} />
            </Grid>
            <Grid item className={classes.titleContainer}>
                <Grid container direction={"column"} spacing={3}>
                    <Grid item container justify={"space-between"} alignItems={"center"} wrap={"nowrap"}>
                        <Grid item>
                            <Grid container spacing={3} alignItems={"center"}>
                                <Grid item>
                                    <Typography variant={"h2"}>{pool.pool}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={"body1"}>
                                        {t("disks_and_pools.pools_card.header.caption", {
                                            count: volumeStore.getDiskCountInPool(pool.pool),
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container justify={"center"} alignItems={"center"} className={classes.icon}>
                                <ChevronRightIcon />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <LinearProgress
                            color={"primary"}
                            value={getProgressValue()}
                            variant={"determinate"}
                            className={classes.progressBar}
                        />
                    </Grid>
                    <Grid item container alignItems={"center"}>
                        <Grid item className={classes.sizeItem}>
                            <Typography variant={"subtitle1"} component={"span"}>
                                {t("disks_and_pools.pools_card.header.size.label.free")}
                            </Typography>
                            <Typography variant={"subtitle1"} className={classes.successText} component={"span"}>
                                {sizes.free}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.sizeItem}>
                            <Typography variant={"subtitle1"} component={"span"}>
                                {t("disks_and_pools.pools_card.header.size.label.allocated")}
                            </Typography>
                            <Typography variant={"subtitle1"} color={"primary"} component={"span"}>
                                {sizes.allocated}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.sizeItem}>
                            <Typography variant={"subtitle1"} component={"span"}>
                                {t("disks_and_pools.pools_card.header.size.label.total")}
                            </Typography>
                            <Typography variant={"subtitle1"} color={"primary"} component={"span"}>
                                {sizes.total}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    ));
};

export default PoolHeader;
