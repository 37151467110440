import React, { useRef } from "react";
import { useStyles } from "./DragAndDropFile.styles";
import { Button, Grid, Typography, InputAdornment, ButtonBase, FormHelperText } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { NativeTypes } from "react-dnd-html5-backend";
import { useDrop } from "react-dnd";
import InputErrorIcon from "assets/InputErrorIcon";
import clsx from "clsx";
import { useToggle } from "hooks/useToggle";

const DragAndDropFile = ({ handleFiles, title, disabled, inputProps, icon: Icon, id, isEmptyError }) => {
    const labelRef = useRef();
    const [showErrorText, toggleShowErrorText] = useToggle();
    const { t } = useTranslation();

    const [{ isOver }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop(item, monitor) {
            if (handleFiles) {
                handleFiles(monitor.getItem().files);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });
    const classes = useStyles({
        isOver,
    });

    const onInputChange = (e) => {
        handleFiles && handleFiles(e.target.files);
    };

    return (
        <Grid
            ref={drop}
            className={clsx(isEmptyError && !disabled && classes.containerError, classes.container)}
            container
            wrap={"nowrap"}
        >
            {Icon && (
                <Grid item className={classes.icon}>
                    <Icon />
                </Grid>
            )}
            <Typography noWrap className={classes.text}>
                {title ? title : t("drag_and_drop_file.title")}
            </Typography>
            <input
                ref={labelRef}
                onChange={onInputChange}
                hidden
                type={"file"}
                multiple
                accept={inputProps}
                id={id && `${id}_file_input`}
            />
            <Button
                disabled={disabled}
                onClick={() => labelRef.current.click()}
                variant={"contained"}
                color={"secondary"}
                id={id && `${id}_choose_file_button`}
            >
                {t("drag_and_drop_file.button.choose_file")}
            </Button>
            {isEmptyError && !disabled && (
                <React.Fragment>
                    <InputAdornment position={"end"}>
                        <ButtonBase onClick={toggleShowErrorText}>
                            <InputErrorIcon />
                        </ButtonBase>
                    </InputAdornment>
                    <FormHelperText className={clsx(!showErrorText && classes.helperTextHidden, classes.helperText)}>
                        {t("common.error.file.message.required")}
                    </FormHelperText>
                </React.Fragment>
            )}
        </Grid>
    );
};

export default DragAndDropFile;
