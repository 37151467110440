import React, { useEffect, useContext } from "react";
import { Context } from "store";
import DataCard from "components/DataCard";
import { Table, TableHead, TableBody, TableRow, TableCell, Grid, Divider, Box } from "@material-ui/core";
import TablePagination from "components/TablePagination";
import { useTranslation } from "react-i18next";
import { observer, useLocalStore } from "mobx-react";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import MoreMenu from "components/MoreMenu";
import TableRowControlButton from "components/TableRowControlButton";
import ChevronRightIcon from "assets/ChevronRightIcon";
import TableSortLabel from "components/TableSortLabel";
import StatusLabel from "./components/StatusLabel";
import {
    ARCHIVED_INSTANCES_ID,
    ARCHIVED_INSTANCES_STATUS,
    ARCHIVED_INSTANCES_STARTED,
    ARCHIVED_INSTANCES_FINISHED,
} from "const/sortColumnConst";
import moment from "moment";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import ArchivedInstancesDrawer from "../ArchivedInstancesDrawer";

const ARCHIVED_TAB_CONTENT_ID_PREFIX = `archived_tab_content`;

const ArchivedTabContent = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${ARCHIVED_TAB_CONTENT_ID_PREFIX}` : ARCHIVED_TAB_CONTENT_ID_PREFIX;
    const { t } = useTranslation();

    const {
        store: { archivedInstancesStore },
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isInstancesLoaded: false,
    }));

    useEffect(() => {
        (async () => {
            await archivedInstancesStore.fetchArchivedInstances();
            state.isInstancesLoaded = true;
        })();
    }, []);

    const handleChangePage = (newPage) => {
        archivedInstancesStore.updatePage(+newPage);
    };
    const handleChangeRowsPerPage = (e) => {
        archivedInstancesStore.updateLimit(+e.target.value);
        archivedInstancesStore.updatePage(0);
    };
    const changeSorting = (column) => () => archivedInstancesStore.changeSorting(column);

    const openDrawerIfNeeded = (id) => () => {
        archivedInstancesStore.currentInstanceId === id
            ? archivedInstancesStore.setCurrentInstanceId(null)
            : archivedInstancesStore.setCurrentInstanceId(id);
    };

    return (
        <Grid item container>
            <DataCard
                headerDivider
                title={t("slingshot.archived_instances.card.title")}
                headerControl={
                    <MoreMenu>
                        <HomeCardActionMenuItem homeCardActionMenuItemId={`${idPrefix}_add_to_home`} />
                    </MoreMenu>
                }
                onClick={() => {}}
                emptyPadding
            >
                {archivedInstancesStore.instancesCount > 0 ? (
                    <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={changeSorting(ARCHIVED_INSTANCES_ID)}
                                            direction={archivedInstancesStore.getArchivedInstancesArguments.sort_dir.toLowerCase()}
                                            active={
                                                archivedInstancesStore.getArchivedInstancesArguments.sort ===
                                                ARCHIVED_INSTANCES_ID
                                            }
                                            id={`${idPrefix}_sort_id`}
                                        >
                                            {t("slingshot.archived_instances.table.header.id")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t("slingshot.archived_instances.table.header.automation_name")}</TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={changeSorting(ARCHIVED_INSTANCES_STATUS)}
                                            direction={archivedInstancesStore.getArchivedInstancesArguments.sort_dir.toLowerCase()}
                                            active={
                                                archivedInstancesStore.getArchivedInstancesArguments.sort ===
                                                ARCHIVED_INSTANCES_STATUS
                                            }
                                            id={`${idPrefix}_sort_status`}
                                        >
                                            {t("slingshot.archived_instances.table.header.status")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={changeSorting(ARCHIVED_INSTANCES_STARTED)}
                                            direction={archivedInstancesStore.getArchivedInstancesArguments.sort_dir.toLowerCase()}
                                            active={
                                                archivedInstancesStore.getArchivedInstancesArguments.sort ===
                                                ARCHIVED_INSTANCES_STARTED
                                            }
                                            id={`${idPrefix}_sort_started`}
                                        >
                                            {t("slingshot.archived_instances.table.header.started")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={changeSorting(ARCHIVED_INSTANCES_FINISHED)}
                                            direction={archivedInstancesStore.getArchivedInstancesArguments.sort_dir.toLowerCase()}
                                            active={
                                                archivedInstancesStore.getArchivedInstancesArguments.sort ===
                                                ARCHIVED_INSTANCES_FINISHED
                                            }
                                            id={`${idPrefix}_sort_finished`}
                                        >
                                            {t("slingshot.archived_instances.table.header.finished")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {archivedInstancesStore.instances.map((item) => (
                                    <TableRow
                                        selected={archivedInstancesStore.currentInstanceId === item.id}
                                        key={item.id}
                                        onClick={openDrawerIfNeeded(item.id)}
                                    >
                                        <TableCell>{item.id}</TableCell>
                                        <TableCell>{`${item.automation_name}(${item.automation_id})`}</TableCell>
                                        <TableCell>
                                            <StatusLabel status={item.result} />
                                        </TableCell>
                                        <TableCell>{moment(item.started).toISOString()}</TableCell>
                                        <TableCell>{moment(item.finished).toISOString()}</TableCell>
                                        <TableCell>
                                            <TableRowControlButton
                                                icon={<ChevronRightIcon />}
                                                id={`${idPrefix}_open_drawer_${item.id}`}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Grid>
                            <Divider />
                            <Box pl={6}>
                                <TablePagination
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    onChangePage={handleChangePage}
                                    count={archivedInstancesStore.instancesCount}
                                    rowsPerPage={archivedInstancesStore.getArchivedInstancesArguments.limit}
                                    page={archivedInstancesStore.getArchivedInstancesArguments.page}
                                    parentId={idPrefix}
                                />
                            </Box>
                        </Grid>
                    </>
                ) : (
                    <EmptyCardPlaceholder
                        inProgress={!state.isInstancesLoaded}
                        message={t("slingshot.archived_instances.table.placeholder")}
                    />
                )}
                <ArchivedInstancesDrawer parentId={idPrefix} />
            </DataCard>
        </Grid>
    );
};

export default observer(ArchivedTabContent);
