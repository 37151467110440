import React, { useContext, useEffect } from "react";
import { useObserver } from "mobx-react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import DataCard from "components/DataCard";
import MoreMenu from "components/MoreMenu";
import TableSortLabel from "components/TableSortLabel";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { CPU_CARD } from "const/widgetNameConst";
import { HARDWARE_PROFILE_CPU_MODEL, HARDWARE_PROFILE_CPU_CORES } from "const/sortColumnConst";

const CPUCard = () => {
    const { t } = useTranslation();

    const {
        store: { hardwareProfileStore }
    } = useContext(Context);
    useEffect(() => {
        hardwareProfileStore.fetchCpuInfo();
    }, []);

    const changeSorting = column => () => hardwareProfileStore.changeSortingCpu(column);

    return useObserver(() => (
        <DataCard
            title={t("hardware_profile.cpu_card.title")}
            autoHeight
            emptyPadding
            headerDivider
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={CPU_CARD} />
                </MoreMenu>
            }
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                onClick={changeSorting(HARDWARE_PROFILE_CPU_MODEL)}
                                direction={hardwareProfileStore.orderCpu}
                                active={hardwareProfileStore.orderByCpu === HARDWARE_PROFILE_CPU_MODEL}
                            >
                                {t("hardware_profile.cpu.header.model")}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell width={"20%"}>
                            <TableSortLabel
                                onClick={changeSorting(HARDWARE_PROFILE_CPU_CORES)}
                                direction={hardwareProfileStore.orderCpu}
                                active={hardwareProfileStore.orderByCpu === HARDWARE_PROFILE_CPU_CORES}
                            >
                                {t("hardware_profile.cpu.header.cores")}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {hardwareProfileStore.sortedCpu &&
                        hardwareProfileStore.sortedCpu.map(row => {
                            return (
                                <TableRow key={Math.random()}>
                                    <TableCell>{row.cpuModel}</TableCell>
                                    <TableCell>{row.coresCount}</TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </DataCard>
    ));
};

export default CPUCard;
