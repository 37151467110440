import React, { useEffect } from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { Button, FormControlLabel, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useLocalStore, useObserver } from "mobx-react-lite";
import Checkbox from "components/MobxForm/Checkbox";
import ComponentCheckbox from "components/Checkbox";
import createForm from "utils/createForm";
import createFields from "./createFields";
import { useStyles } from "./SetupAlertsDialog.styles";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const SETUP_ALERTS_DIALOG_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_setup_alerts_dialog`;

const SetupAlertsDialog = ({ onClose, open }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { alertsStore }
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields(alertsStore.notifications, alertsStore.notificationKeys) }),
        isSubmitted: false
    }));

    const unsavedChangesModal = useModal();

    useEffect(() => {
        setupForm();
    }, [alertsStore.notificationKeys.length]);

    const setupForm = () => {
        unsavedChangesModal.close();
        state.isSubmitted = false;
        createFields(alertsStore.notifications, alertsStore.notificationKeys).forEach(f => state.form.add(f));
        state.form.reset();
    };
    const isSomeEnabled = field => {
        return (
            state.form.size && !isAllEnabled(field) && alertsStore.notificationKeys.some(key => state.form.$(key + field).value)
        );
    };
    const isAllEnabled = field => {
        return state.form.size && alertsStore.notificationKeys.every(key => state.form.$(key + field).value);
    };
    const handleCheckbox = field => () => {
        if (isSomeEnabled(field) || isAllEnabled(field)) {
            alertsStore.notificationKeys.forEach(key => {
                if (!state.form.$(key + field).disabled) {
                    state.form.$(key + field).set(false);
                }
            });
        } else {
            alertsStore.notificationKeys.forEach(key => {
                if (!state.form.$(key + field).disabled) {
                    state.form.$(key + field).set(true);
                }
            });
        }
    };
    const isDisabled = field => {
        if (!state.form.fields.size) {
            return false;
        }
        return !alertsStore.notificationKeys.reduce((prev, key) => prev || !state.form.$(key + field).disabled, false);
    };
    const submit = async e => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        const res = !!(await alertsStore.setNotifications(
            alertsStore.notificationKeys.reduce((notification, name) => {
                notification[name] = {
                    emailEnabled: state.form.$(`${name}Email`).checked,
                    emailReadonly: state.form.$(`${name}Email`).disabled,
                    audioEnabled: state.form.$(`${name}Audio`).checked,
                    audioReadonly: state.form.$(`${name}Audio`).disabled,
                    snmpEnabled: state.form.$(`${name}Snmp`).checked,
                    snmpReadonly: state.form.$(`${name}Snmp`).disabled
                };
                return notification;
            }, {})
        ));
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            className={classes.table}
            withoutPaddings
            onEnter={setupForm}
            onClose={closeIfNeeded}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${SETUP_ALERTS_DIALOG_ID_PREFIX}_save`}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary" id={`${SETUP_ALERTS_DIALOG_ID_PREFIX}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
            onSubmit={submit}
            open={open}
            title={t("alerts.dialog.title")}
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell width={"45%"}>{t("alerts.table.title.option")}</TableCell>
                        <TableCell>
                            <Grid alignItems={"center"} container>
                                <FormControlLabel
                                    className={classes.headerCheckbox}
                                    control={
                                        <ComponentCheckbox
                                            disabled={isDisabled("Email")}
                                            indeterminate={isSomeEnabled("Email")}
                                            checked={isAllEnabled("Email")}
                                            onChange={handleCheckbox("Email")}
                                            id={`${SETUP_ALERTS_DIALOG_ID_PREFIX}_email`}
                                        />
                                    }
                                    label={t("alerts.table.title.email")}
                                />
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid alignItems={"center"} container>
                                <FormControlLabel
                                    className={classes.headerCheckbox}
                                    control={
                                        <ComponentCheckbox
                                            disabled={isDisabled("Audio")}
                                            indeterminate={isSomeEnabled("Audio")}
                                            checked={isAllEnabled("Audio")}
                                            onChange={handleCheckbox("Audio")}
                                            id={`${SETUP_ALERTS_DIALOG_ID_PREFIX}_audio`}
                                        />
                                    }
                                    label={t("alerts.table.title.audio")}
                                />
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid alignItems={"center"} container>
                                <FormControlLabel
                                    className={classes.headerCheckbox}
                                    control={
                                        <ComponentCheckbox
                                            disabled={isDisabled("Snmp")}
                                            indeterminate={isSomeEnabled("Snmp")}
                                            checked={isAllEnabled("Snmp")}
                                            onChange={handleCheckbox("Snmp")}
                                            id={`${SETUP_ALERTS_DIALOG_ID_PREFIX}_snmp`}
                                        />
                                    }
                                    label={t("alerts.table.title.snmp")}
                                />
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {state.form.fields.size &&
                        alertsStore.notificationKeys
                            // the field is hidden for version 7.1
                            .filter(name => name !== "clusterCriticalEventsDetected")
                            .map(name => (
                                <TableRow className={classes.row} key={name}>
                                    <TableCell>{t(`alerts.table.${name}`)}</TableCell>
                                    <TableCell>
                                        <Checkbox
                                            field={state.form.$(`${name}Email`)}
                                            id={`${SETUP_ALERTS_DIALOG_ID_PREFIX}_alert_${name}_email`}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            field={state.form?.$(`${name}Audio`)}
                                            id={`${SETUP_ALERTS_DIALOG_ID_PREFIX}_alert_${name}_audio`}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            field={state.form?.$(`${name}Snmp`)}
                                            id={`${SETUP_ALERTS_DIALOG_ID_PREFIX}_alert_${name}_snmp`}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                </TableBody>
            </Table>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default SetupAlertsDialog;
