import { types } from "mobx-state-tree";

import Ace from "api/security/acl/Types/Ace";

const Acl = types.model({
    aces: types.array(Ace),
    useInheritedPermissions: types.boolean
});

export default Acl;
