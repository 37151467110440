import React, { useEffect } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import { useStyles } from "./ImportBackupModal.styles";
import clsx from "clsx";
import FileUpload from "components/FileUpload/FileUpload";
import ShieldWithKeyIcon from "assets/ShieldWithKeyIcon";
import Dialog from "components/Dialog";
import Radio from "components/Radio";
import RadioGroup from "components/RadioGroup";
import Select from "components/MobxForm/Select";
import createForm from "utils/createForm";
import createFields from "./createFields";
import {
    IMPORT_DATABASE_FILE,
    IMPORT_DATABASE_RESTORE_LOCATION,
    DATABASE_SLINGSHOT,
    IMPORT_CONFIG_KEEP_CURRENT,
} from "const/setupBackupConst";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { SUPPORT_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const IMPORT_BACKUP_MODAL_ID_PREFIX = `${SUPPORT_PAGE_ID_PREFIX}_import_backup_modal`;

const ImportBackupModal = ({ open, onClose, variant }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const state = useLocalStore(() => ({
        radioValue: IMPORT_DATABASE_FILE,
        currentFile: null,
        currentConfigBackup: IMPORT_CONFIG_KEEP_CURRENT,
        inProgress: false,
        isDefault: false,
        needShowEmptyError: false,
        fileValidationInfo: false,
        form: createForm({
            fields: createFields(),
        }),
        setFileValidationInfo(fileValidationInfo) {
            state.fileValidationInfo = fileValidationInfo;
        },
    }));
    const {
        store: { backupSettingsStore },
    } = useStore();

    const choosedImportDatabaseFile = state.radioValue === IMPORT_DATABASE_FILE;
    const choosedImportDatabaseRestoreLocation = state.radioValue === IMPORT_DATABASE_RESTORE_LOCATION;

    const unsavedChangesModal = useModal();

    const handleRadioChange = (e) => {
        state.radioValue = e.target.value;
        if (choosedImportDatabaseFile && state.currentConfigBackup === IMPORT_CONFIG_KEEP_CURRENT) {
            state.isDefault = true;
            return;
        }
        state.isDefault = false;
    };

    const onFileSelect = (file) => {
        state.currentFile = file;
        state.isDefault = false;
    };

    const onConfigBackupSelect = (e) => {
        state.currentConfigBackup = e.target.value;
        state.isDefault = false;
    };

    const onSubmit = async () => {
        state.inProgress = true;
        const cfgBackup = state.currentConfigBackup === IMPORT_CONFIG_KEEP_CURRENT ? "" : state.currentConfigBackup;
        let res;
        if (choosedImportDatabaseFile) {
            state.needShowEmptyError = true;
            if (!state.fileValidationInfo.isValid) return;
            res = await backupSettingsStore.uploadFile(state.currentFile, variant, state.radioValue, cfgBackup);
        } else {
            res = await backupSettingsStore.importBackup(state.form.$("fileName").value, variant, state.radioValue, cfgBackup);
        }
        state.inProgress = false;
        state.isDefault = true;
        return res;
    };

    const removeFileUpload = () => {
        state.currentFile = null;
    };

    const onEnter = () => {
        state.radioValue = IMPORT_DATABASE_FILE;
        state.currentFile = null;
        state.currentConfigBackup = IMPORT_CONFIG_KEEP_CURRENT;
        state.inProgress = false;
        state.isDefault = true;
        state.needShowEmptyError = false;
        unsavedChangesModal.close();
    };

    const closeIfNeeded = () => {
        state.isDefault ? onClose() : unsavedChangesModal.open();
    };

    const listBackups =
        variant === DATABASE_SLINGSHOT ? backupSettingsStore.listDatabaseBackupsSlingshot : backupSettingsStore.listBackups;

    useEffect(() => {
        if (choosedImportDatabaseRestoreLocation) {
            state.form.$("fileName").set(listBackups[0]);
        } else {
            state.form.$("fileName").set(undefined);
        }
    }, [state.radioValue]);

    return useObserver(() => (
        <Dialog
            open={open}
            buttons={
                <Button
                    onClick={onClose}
                    variant={"contained"}
                    color={"secondary"}
                    id={`${IMPORT_BACKUP_MODAL_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
            inProgress={state.inProgress}
            onEnter={onEnter}
            onSubmit={onSubmit}
            disableSubmit={!state.currentFile && !state.form.$("fileName").value}
            submitBtnLabel={t("common.button.import")}
            submitBtnId={`${IMPORT_BACKUP_MODAL_ID_PREFIX}_import`}
            title={
                variant === DATABASE_SLINGSHOT
                    ? t("support.os_data.backup_card.context_menu.import_slingshot")
                    : t("support.os_data.backup_card.context_menu.import_sharebrowser")
            }
            onClose={closeIfNeeded}
        >
            <RadioGroup value={state.radioValue} onChange={handleRadioChange} className={classes.radioGroup}>
                <Radio
                    className={classes.radio}
                    value={IMPORT_DATABASE_FILE}
                    label={t("support.os_data.backup_card.import_database.upload_file")}
                    id={`${IMPORT_BACKUP_MODAL_ID_PREFIX}_upload_file`}
                />

                <FileUpload
                    file={state.currentFile}
                    handleFile={onFileSelect}
                    regExpExtension={/\.gpg/}
                    inputProps=".gpg"
                    noEmpty
                    needShowEmptyError={state.needShowEmptyError}
                    title={t("support.os_data.backup_card.import_database.drag_and_drop.placeholder")}
                    removeFile={removeFileUpload}
                    onValid={state.setFileValidationInfo}
                    icon={state.currentFile ? ShieldWithKeyIcon : undefined}
                    disabled={choosedImportDatabaseRestoreLocation}
                    id={`${IMPORT_BACKUP_MODAL_ID_PREFIX}_drag_and_drop`}
                />
                <Radio
                    className={classes.radio}
                    value={IMPORT_DATABASE_RESTORE_LOCATION}
                    label={t("support.os_data.backup_card.import_database.use_restore_location")}
                    disabled={!listBackups.length}
                    id={`${IMPORT_BACKUP_MODAL_ID_PREFIX}_use_restore_location`}
                />
                <Grid container className={clsx(classes.contentContainer, choosedImportDatabaseFile && classes.disabled)}>
                    <Select
                        field={state.form.$("fileName")}
                        value={state.form.$("fileName").value}
                        options={listBackups}
                        defaultValue={listBackups[0]}
                        disabled={choosedImportDatabaseFile}
                        id={`${IMPORT_BACKUP_MODAL_ID_PREFIX}_list_backups`}
                    />
                </Grid>
            </RadioGroup>
            {variant === DATABASE_SLINGSHOT && (
                <Grid container className={clsx(classes.contentContainer, classes.cfgBackContainer)}>
                    <Grid item className={classes.cfgBackLabel}>
                        <Typography>{t("support.os_data.backup_card.import_database.restore_config")}</Typography>
                    </Grid>
                    <Grid item container>
                        <Select
                            field={state.form.$("cfgBackup")}
                            value={state.currentConfigBackup}
                            options={backupSettingsStore.listConfigBackupsSlingshot.slice().sort().reverse()}
                            additionalStartOptions={[IMPORT_CONFIG_KEEP_CURRENT]}
                            onChange={onConfigBackupSelect}
                            id={`${IMPORT_BACKUP_MODAL_ID_PREFIX}_restore_config`}
                        />
                    </Grid>
                </Grid>
            )}
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default ImportBackupModal;
