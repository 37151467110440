import isFieldEmpty from "utils/isFieldEmptyRule";
import i18n from "i18n";
import isFieldHasOnlySpaces from "utils/isFieldHasOnlySpacesRule";
import { dynamicRequiredValidator } from "utils/dynamicRequiredValidator";
import { validationRecipientList } from "utils/validators/validationRecipientList";

const createFields = () => {
    return [
        {
            name: "name",
            validators: [isFieldEmpty(i18n.t("common.label.field")), isFieldHasOnlySpaces(i18n.t("rule.field_has_only_spaces"))],
            rules: "automationName"
        },
        {
            name: "recipient",
            validators: [dynamicRequiredValidator([{ field: "notify", values: [true] }]), validationRecipientList()]
        },
        {
            name: "notify",
            type: "checkbox"
        },
        {
            name: "share",
            validators: [isFieldEmpty(i18n.t("common.label.field"))]
        },
        {
            name: "folder"
        },
        {
            name: "scheduled",
            type: "checkbox"
        },
        {
            name: "frequency"
        },
        {
            name: "frequencyUnit"
        },
        {
            name: "startAt"
        },
        {
            name: "query",
            type: "radio"
        },
        {
            name: "fileExtension",
            validators: [isFieldEmpty(i18n.t("common.label.field")), isFieldHasOnlySpaces(i18n.t("rule.field_has_only_spaces"))]
        },
        {
            name: "regularExpression",
            validators: [isFieldEmpty(i18n.t("common.label.field")), isFieldHasOnlySpaces(i18n.t("rule.field_has_only_spaces"))]
        }
    ];
};

export default createFields;
