import { usePinnableMenu } from "hooks/usePinnableMenu.js";
import { useStore } from "hooks/useStore.js";
import { PIN_SIDE_MENU } from "const";

export const useSideMenu = () => {
    const {
        store: { uiStore }
    } = useStore();

    return usePinnableMenu({
        localStorageName: PIN_SIDE_MENU,
        open: uiStore.openSideMenu,
        close: uiStore.closeSideMenu,
        pin: uiStore.pinSideMenu,
        unpin: uiStore.unpinSideMenu,
        isOpen: uiStore.isSideMenuOpen,
        isPinned: uiStore.isSideMenuPinned
    });
};
