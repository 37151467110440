import { types } from "mobx-state-tree";

import BuildTaskState from "api/volume/Types/BuildTaskState";

const BuildQueue = types.model({
    paused: types.boolean,
    notifyOnCompletion: types.boolean,
    volumes: types.array(BuildTaskState)
});

export default BuildQueue;
