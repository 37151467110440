import { types } from "mobx-state-tree";

const DeleteInstanceArguments = types.model({
    json: types.maybe(
        types.model({
            instanceId: types.number
        })
    )
});

export default DeleteInstanceArguments;
