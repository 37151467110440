import { types } from "mobx-state-tree";
import TaskType from "api/slingshot/automations/Types/TaskType";
import { getSnapshot } from "mobx-state-tree";
import { EMAIL_TASK } from "const/automationTaskConst";
import { toJS } from "mobx";

const AutomationTasksStore = types
    .model({
        tasks: types.optional(types.array(TaskType), []),
        currentTaskSequence: types.maybeNull(types.number),
        checkedTasks: types.optional(types.array(types.number), [])
    })
    .views(self => ({
        get emailTask() {
            return self.tasks?.find(task => task.action?.action === EMAIL_TASK);
        },
        get availableTasks() {
            return self.tasks?.filter(task => task.action?.action !== EMAIL_TASK).sort((a, b) => a?.sequence - b?.sequence) || [];
        },
        get currentTask() {
            return self.availableTasks?.find(task => task.sequence === self.currentTaskSequence);
        }
    }))
    .actions(self => ({
        setCurrentAutomationTasks(tasks) {
            if (tasks) {
                self.tasks = getSnapshot(tasks);
                for (const task of self.tasks) {
                    task.arguments.preserve_access_code = true;
                }

                return true;
            }

            self.tasks = [];
            return false;
        },
        deleteTasks() {
            self.tasks = self.tasks.filter(task => !self.checkedTasks.includes(task.sequence));
            self.clearCheckedTasks();
            self.tasks.length && self.tasks.forEach((task, index) => (task.sequence = index));
        },
        addEmailTask(currentEmailTask) {
            if (!self.emailTask) {
                self.tasks.push(currentEmailTask);
                return;
            }
            const newTask = TaskType.create(currentEmailTask);
            self.tasks.splice(0, 1, getSnapshot(newTask));
        },
        toggleEmailTask() {
            const currentTaskIndex = self.tasks.findIndex(task => task.action?.action === EMAIL_TASK);
            self.tasks[currentTaskIndex].enabled = !self.tasks[currentTaskIndex].enabled;
        },
        addTask: payload => {
            self.tasks.push(payload);
            return true;
        },
        updateCurrentTask: payload => {
            const newTask = TaskType.create(payload);
            const taskIndex = self.tasks?.findIndex(
                task => task.sequence === payload.sequence && task.action?.action !== EMAIL_TASK
            );
            self.tasks.splice(taskIndex, 1, getSnapshot(newTask));
            return true;
        },
        enableTask: taskIndex => {
            const currentTaskIndex = self.tasks?.findIndex(task => task.sequence === taskIndex);
            self.tasks[currentTaskIndex].enabled = !self.tasks[currentTaskIndex].enabled;
        },
        replaceEmailTask() {
            const emailTaskIndex = self.tasks?.findIndex(task => task.action?.action === EMAIL_TASK);
            const arrayCopy = toJS(self.tasks);
            arrayCopy.push(...arrayCopy.splice(emailTaskIndex, 1));
            self.tasks = arrayCopy;
        },
        moveUp(taskSequence) {
            const arrayCopy = toJS(self.tasks);
            const index = arrayCopy.findIndex(task => task.sequence === taskSequence);
            [arrayCopy[index - 1].sequence, arrayCopy[index].sequence] = [
                arrayCopy[index].sequence,
                arrayCopy[index - 1].sequence
            ];
            [arrayCopy[index - 1], arrayCopy[index]] = [arrayCopy[index], arrayCopy[index - 1]];
            self.tasks = arrayCopy;
        },
        moveDown(taskSequence) {
            const arrayCopy = toJS(self.tasks);
            const index = arrayCopy.findIndex(task => task.sequence === taskSequence);
            [arrayCopy[index + 1].sequence, arrayCopy[index].sequence] = [
                arrayCopy[index].sequence,
                arrayCopy[index + 1].sequence
            ];
            [arrayCopy[index + 1], arrayCopy[index]] = [arrayCopy[index], arrayCopy[index + 1]];
            self.tasks = arrayCopy;
        },
        setCurrentTaskSequence(taskSequence) {
            self.currentTaskSequence = taskSequence;
        },
        checkTasksSequence: () => {
            self.tasks.forEach((task, index) => {
                task.sequence = index;
            });
        },
        addCheckedTask: taskSequence => {
            const foundTask = self.checkedTasks.some(task => task === taskSequence);
            !foundTask && self.checkedTasks.push(taskSequence);
        },
        removeCheckedTask: taskSequence => {
            const foundTask = self.checkedTasks.some(task => task === taskSequence);
            foundTask && self.checkedTasks.remove(taskSequence);
        },
        clearCheckedTasks: () => {
            self.checkedTasks = [];
        }
    }));
export default AutomationTasksStore;
