import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    icon: {
        "& svg path": {
            fill: theme.palette.common.white
        }
    },
    addIcon: {
        "& svg path": {
            fill: theme.palette.primary.main
        }
    },
    title: {
        "@media(max-width: 1024px)": {
            display: "none"
        }
    }
}));
