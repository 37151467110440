import { makeStyles } from "@material-ui/core/styles";
import { SEARCH_PLACEHOLDER_COLOR } from "const/styleConst";
import hexToRgba from "utils/hexToRgba";

const USERS_SEARCH_SELECT_WIDTH = 395;
export const useStyles = makeStyles((theme) => ({
    input: {
        "& .MuiOutlinedInput-adornedEnd": {
            paddingRight: theme.spacing(2),
        },
    },
    select: {
        width: 186,
        paddingRight: theme.spacing(4),
    },
    closeIcon: {
        "& svg path": {
            fill: hexToRgba(SEARCH_PLACEHOLDER_COLOR, 0.8),
        },
    },
    primaryColorIcon: {
        "& svg path": {
            fill: theme.palette.primary.main,
        },
    },
    searchIconContainer: {
        width: theme.spacing(7),
    },
    selectItem: {
        padding: theme.spacing(2, 3),
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.searchInlinePopover.hover,
        },
    },
    selectPaper: {
        paddingTop: theme.spacing(2),
        width: USERS_SEARCH_SELECT_WIDTH,
        backgroundColor: theme.palette.searchInlinePopover.background,
        borderRadius: theme.spacing(1),
    },
    selectIcon: {
        flexShrink: 0,
        marginRight: theme.spacing(2),
    },
    emptyResponse: {
        padding: theme.spacing(4, 3),
    },
    emptyResponseText: {
        opacity: 0.6,
    },
    pagination: {
        "& .MuiTablePagination-toolbar": {
            height: "initial",
            paddingRight: 0,
        },
    },
    dropdownIcon: {
        transform: ({ isSelectOpen }) => isSelectOpen && "rotate(180deg)",
    },
}));
