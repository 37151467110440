import React, { Fragment, useEffect, useRef } from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { useLocalStore } from "mobx-react-lite";
import { Box, Grid, Typography, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import createForm from "utils/createForm";
import createFields from "./createFields";
import { Button } from "@material-ui/core";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import TextInput from "components/MobxForm/TextInput";
import Switch from "components/MobxForm/Switch";
import SelectBase from "components/Select";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import {
    driveLetters,
    SCHEMA_OPTIONS,
    SCHEMA_OPTIONS_ARRAY,
    MEDIA_PLAYER_ARRAY,
    S3_REGION,
    S3_CUSTOM_HOST,
    REGION_OPTIONS_ARRAY,
    S3_CUSTOM_REGION,
    REGISTRATION_SCHEMA,
} from "const/slingshotAliasConst";
import Radio from "components/Radio";
import RadioGroup from "components/MobxForm/RadioGroup";
import Select from "components/MobxForm/Select";
import ErrorButton from "components/ErrorButton";
import { useStyles } from "./CreateEditAliasDialog.styles";
import FolderBrowser from "../FolderBrowser/FolderBrowser";
import DocIcon from "assets/DocIcon";
import DragAndDropFile from "components/DragAndDropFile";
import { ADMIN } from "const/userRolesConst";
import sortByLocaleCompare from "utils/sortByLocaleCompare";
import { useModalCombine } from "hooks/useModalCombine";
import { useStore } from "hooks/useStore";
import { openAuthorizationWindowDropbox, generateAccessTokenDropbox } from "./CreateEditAliasDialog.utils";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { autorun } from "mobx";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import { observer } from "mobx-react";
import i18n from "i18n";

const CREATE_EDIT_ALIAS_DIALOG_ID_PREFIX = `create_edit_alias_dialog`;

const CreateEditAliasDialog = ({ open, onClose, onCloseDrawer, changeProgressState, isCreate, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${CREATE_EDIT_ALIAS_DIALOG_ID_PREFIX}` : CREATE_EDIT_ALIAS_DIALOG_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { slingshotSettingsStore, slingshotStorageStore, authStore, processingStore },
    } = useStore();
    const classes = useStyles();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        inProgress: false,
        isSubmitted: false,
        isLoadingShare: false,
        schemaOptions: [],
        currentFile: null,
        currentFileText: "",
        prevStateSchema: "",
        hideHelperText: true,
        currentShareForAliasShareMapping: "",
        currentDriveForAliasShareMapping: "",
        aliasShareMappings: [],
        get shares() {
            return (
                (slingshotStorageStore.shares.length &&
                    sortByLocaleCompare(slingshotStorageStore.shares.map((share) => share.name))) ||
                []
            );
        },
        get tricasterShares() {
            return state.shares.filter((item) => !state.aliasShareMappings.some((i) => i.share === item));
        },
        get drives() {
            return driveLetters();
        },
        get tricasterDrives() {
            return state.drives.filter((item) => !state.aliasShareMappings.some((i) => i.drive === item));
        },
    }));

    const { unsavedChangesModal, folderBrowser } = useModalCombine(["unsavedChangesModal", "folderBrowser"]);

    useAsyncEffect(async () => {
        await slingshotSettingsStore.fetchTransportingConfig();
        if (!slingshotSettingsStore.transportingConfig?.data?.json?.smbEnabled) {
            state.schemaOptions = SCHEMA_OPTIONS_ARRAY.filter((option) => option.value !== SCHEMA_OPTIONS.smb);
        } else {
            state.schemaOptions = SCHEMA_OPTIONS_ARRAY;
        }
    }, []);

    const onFilesSelect = (files) => {
        state.currentFile = files[0];
        const reader = new FileReader();
        reader.readAsText(state.currentFile);
        reader.onload = () => {
            state.currentFileText = reader.result;
        };
    };

    const onShareSelectChange = (e) => {
        state.form.$("resource").set("value", e.target.value);
        state.form.$("path").clear();
    };

    const setPath = (path) => {
        state.form.$("path").set("value", path);
    };

    const password = useRef(state.form.$("password").value);
    useEffect(() => {
        autorun(() => {
            if (password.current !== state.form.$("password").value) state.form.$("password").set("rules", "required");
        });
    }, []);

    const loadingShares = async () => {
        state.isLoadingShare = true;
        await slingshotStorageStore.fetchShares();
        state.isLoadingShare = false;
    };

    const updateCurrentParametersForAliasShareMappings = () => {
        if (state.tricasterShares.length) {
            state.currentShareForAliasShareMapping = state.tricasterShares[0];
        }
        if (state.tricasterDrives.length) {
            state.currentDriveForAliasShareMapping = state.tricasterDrives[0];
        }
    };

    const updateForm = async () => {
        const currentAlias = slingshotSettingsStore.currentAlias;
        state.aliasShareMappings =
            slingshotSettingsStore.currentAlias?.share_mappings?.list &&
            slingshotSettingsStore.currentAlias.share_mappings.list.length > 0
                ? slingshotSettingsStore.currentAlias.share_mappings.list
                : [];

        await loadingShares();
        state.currentFileText = "";
        state.currentFile = null;
        state.hideHelperText = true;

        if (isCreate) {
            state.form.$("schema").set("default", SCHEMA_OPTIONS_ARRAY[0].value);
            state.form.$("personal").set("default", true);
            state.form.$("password").set("rules", "required");
        } else {
            state.form.$("password").set("rules", "");
            state.form.$("name").set("default", currentAlias.name);
            state.form.$("schema").set("default", currentAlias.decomposed_base_url.schema);
            if (currentAlias.decomposed_base_url.schema === SCHEMA_OPTIONS.s3) {
                if (!!currentAlias.decomposed_base_url.region && currentAlias.decomposed_base_url.region !== S3_CUSTOM_REGION) {
                    state.form.$("regionChoose").set("default", S3_REGION);
                    !!currentAlias.decomposed_base_url.region &&
                        state.form.$("region").set("default", currentAlias.decomposed_base_url.region);
                    state.form.$("host").set("default", "");
                } else {
                    state.form.$("regionChoose").set("default", S3_CUSTOM_HOST);
                    state.form.$("host").set("default", currentAlias.decomposed_base_url.host || "");
                    state.form.$("region").set("default", "");
                }
            }
            state.form.$("mediaPlayer").set("default", currentAlias.decomposed_base_url.resource || MEDIA_PLAYER_ARRAY[0].value);
            state.form.$("host").set("default", currentAlias.decomposed_base_url.host || "");
            state.form.$("path").set("default", currentAlias.decomposed_base_url.path);
            state.form.$("resource").set("default", currentAlias.decomposed_base_url.resource || "");
            state.form.$("user").set("default", currentAlias.secret.user);
            state.form.$("personal").set("default", currentAlias.personal);
            if (currentAlias.secret.metadata) {
                state.form.$("ftpUserDirIsRoot").set("default", currentAlias.secret.metadata.ftp_user_dir_is_root);
                state.form.$("ftpPassive").set("default", currentAlias.secret.metadata.ftp_passive);
            }
            state.prevStateSchema = currentAlias.decomposed_base_url.schema;
        }
        updateCurrentParametersForAliasShareMappings();
        state.form.reset();
        state.form.showErrors(false);
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };

    const onSubmit = async (e) => {
        state.form.onSubmit(e);
        switch (state.form.$("schema").value) {
            case SCHEMA_OPTIONS.ftp:
                state.form.$("resource").resetValidation();
                state.form.$("mediaPlayer").resetValidation();
                break;
            case SCHEMA_OPTIONS.evo:
                state.form.$("host").resetValidation();
                state.form.$("mediaPlayer").resetValidation();
                state.form.$("password").resetValidation();
                break;
            case SCHEMA_OPTIONS.gcs:
                state.form.$("host").resetValidation();
                state.form.$("user").resetValidation();
                state.form.$("password").resetValidation();
                state.form.$("mediaPlayer").resetValidation();
                break;
            case SCHEMA_OPTIONS.dropbox:
                state.form.$("host").resetValidation();
                state.form.$("resource").resetValidation();
                state.form.$("mediaPlayer").resetValidation();
                break;
            case SCHEMA_OPTIONS.azure:
                state.form.$("host").resetValidation();
                state.form.$("mediaPlayer").resetValidation();
                break;
            case SCHEMA_OPTIONS.s3:
                if (state.form.$("regionChoose").value === S3_REGION) state.form.$("host").resetValidation();
                state.form.$("mediaPlayer").resetValidation();
                break;
            case state.prevStateSchema:
                if (!state.form.$("password").touched) state.form.$("password").resetValidation();
                state.form.$("mediaPlayer").resetValidation();
                break;
            case SCHEMA_OPTIONS.tricaster:
                state.form.$("resource").resetValidation();
                state.form.$("path").resetValidation();
                break;
            default:
                state.form.$("mediaPlayer").resetValidation();
                break;
        }
        if (!state.form.isValid) return;

        state.inProgress = true;
        !isCreate && changeProgressState(true);

        let data =
            state.form.$("schema").value === SCHEMA_OPTIONS.tricaster
                ? {
                      schema: state.form.$("schema").value,
                  }
                : {
                      schema: state.form.$("schema").value,
                      path: state.form.$("path").value || "",
                  };

        if (state.form.$("resource").value) {
            data = { ...data, resource: state.form.$("resource").value };
        }
        if (state.form.$("schema").value === SCHEMA_OPTIONS.tricaster && state.form.$("mediaPlayer").value) {
            data = { ...data, resource: state.form.$("mediaPlayer").value };
        }
        if (state.form.$("host").value) {
            data = { ...data, host: state.form.$("host").value };
        }
        if (state.form.$("region").value) {
            data = { ...data, region: state.form.$("region").value };
        }
        if (state.form.$("regionChoose").value === S3_CUSTOM_HOST) {
            data = { ...data, region: undefined, host: state.form.$("host").value };
        }

        let dataSecret = {};
        if (state.form.$("password").value || state.currentFileText) {
            dataSecret = { ...dataSecret, password: state.form.$("password").value || state.currentFileText };
        }
        if (state.form.$("user").value) {
            dataSecret = { ...dataSecret, user: state.form.$("user").value };
        }

        if (state.form.$("schema").value === SCHEMA_OPTIONS.ftp) {
            dataSecret = {
                ...dataSecret,
                metadata: {
                    ftp_user_dir_is_root: !!state.form.$("ftpUserDirIsRoot").value,
                    ftp_passive: !!state.form.$("ftpPassive").value,
                },
            };
        }

        if (state.form.$("schema").value === SCHEMA_OPTIONS.dropbox) {
            if (isCreate || (!isCreate && state.form.$("password").value)) {
                const appKey = state.form.$("user").value;
                const verifier = state.form.$("verifier").value;
                const password = state.form.$("password").value;
                const token = await generateAccessTokenDropbox(appKey, verifier, password);
                if (!token.ok) {
                    processingStore.setError(i18n.t("slingshot_page.tabs.settings.aliases.dropbox_password.error_validation"));
                    state.form.$("password").reset();
                    state.inProgress = false;
                    changeProgressState(false);
                    return;
                }
                if (null !== window) window.close();

                dataSecret.password = JSON.stringify(token.data);
            }
        }
        const shareMappings =
            state.form.$("schema").value === SCHEMA_OPTIONS.tricaster
                ? {
                      share_mappings: {
                          list: state.aliasShareMappings.map((aliasShareMappingsItem) => ({
                              share: aliasShareMappingsItem.share,
                              drive: aliasShareMappingsItem.drive,
                          })),
                      },
                  }
                : {};

        const res = isCreate
            ? await slingshotSettingsStore.createAlias({
                  decomposed_base_url: data,
                  name: state.form.$("name").value,
                  secret: dataSecret,
                  personal: state.form.$("personal").value,
                  ...shareMappings,
              })
            : await slingshotSettingsStore.updateAlias({
                  decomposed_base_url: data,
                  name: state.form.$("name").value,
                  id: slingshotSettingsStore.currentAlias.id,
                  secret: dataSecret,
                  updated_secret: !dataSecret.password ? false : true,
                  personal: state.form.$("personal").value,
                  ...shareMappings,
              });

        state.inProgress = false;
        !isCreate && changeProgressState(false);

        if (res) {
            state.isSubmitted = true;
            await slingshotSettingsStore.fetchAliases();
            authStore.role === ADMIN && slingshotSettingsStore.fetchAutotaggingMachineSettings();

            if (
                (state.form.$("path").value === "/" || state.form.$("path").value === "") &&
                state.form.$("schema").value !== SCHEMA_OPTIONS.evo &&
                state.form.$("schema").value !== SCHEMA_OPTIONS.tricaster
            ) {
                let volumeName =
                    ((state.form.$("schema").value === SCHEMA_OPTIONS.dropbox ||
                        state.form.$("schema").value === SCHEMA_OPTIONS.ftp) &&
                        state.form.$("name").value) ||
                    null;
                if (!isCreate && state.form.$("schema").value === state.prevStateSchema) {
                    if (!state.form.$("password").value) dataSecret = null;
                    if (
                        state.form.$("schema").value !== SCHEMA_OPTIONS.dropbox &&
                        state.form.$("schema").value !== SCHEMA_OPTIONS.ftp
                    ) {
                        volumeName = state.form.$("resource").value;
                    }

                    data = { ...data, schema: REGISTRATION_SCHEMA, host: state.form.$("name").value };
                }

                registerExternalVolume(data, dataSecret, volumeName);
            }
            !isCreate && onCloseDrawer();
        }
        return res;
    };

    const registerExternalVolume = (data, dataSecret, volumeName) => {
        slingshotSettingsStore.registrationAlias({
            decomposed_url: data,
            secret: dataSecret,
            volume_name: volumeName,
            engine: "engine2",
        });
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const onChangeSchema = (e) => {
        state.form.$("schema").value = e.target.value;
        state.form.$("host").clear();
        state.form.$("mediaPlayer").clear();
        state.form.$("resource").clear();
        state.form.$("user").clear();
        state.form.$("regionChoose").clear();
        state.form.$("region").clear();
        state.form.$("password").clear();
        state.form.$("password").resetValidation();
        state.form.$("path").clear();
        state.form.$("ftpPassive").clear();
        state.form.$("ftpUserDirIsRoot").clear();
        if (state.form.$("schema").value === SCHEMA_OPTIONS.s3) {
            state.form.$("regionChoose").set("value", S3_REGION);
            state.form.$("region").set("value", REGION_OPTIONS_ARRAY[0].value);
        } else if (state.form.$("schema").value === SCHEMA_OPTIONS.ftp) {
            state.form.$("ftpPassive").set("value", true);
        }
        if (state.form.$("schema").value === SCHEMA_OPTIONS.tricaster) {
            state.form.$("mediaPlayer").set("value", MEDIA_PLAYER_ARRAY[0].value);
        }
        state.hideHelperText = true;
        state.aliasShareMappings = [];
    };

    const onChangePath = (e) => {
        const path = e.target.value;
        state.form.$("path").value = path;
        const noDefaultValuePath = state.form.$("path").value.trim() !== "" && state.form.$("path").value.trim() !== "/";
        const noDefaultSchemaEvo = state.form.$("schema").value !== SCHEMA_OPTIONS.evo;

        if (noDefaultValuePath && noDefaultSchemaEvo) return (state.hideHelperText = false);

        state.hideHelperText = true;
    };

    const onGetAccessToken = () => {
        const appKey = state.form.$("user").value;
        const oauth = openAuthorizationWindowDropbox(appKey);

        state.form.$("verifier").set("value", oauth.verifier);
    };

    const addShareForAliasShareMapping = (e) => {
        state.currentShareForAliasShareMapping = e.target.value;
    };

    const addDriveForAliasShareMapping = (e) => {
        state.currentDriveForAliasShareMapping = e.target.value;
    };

    const changeShareForAliasShareMapping = (e, index) => {
        state.aliasShareMappings = [
            ...state.aliasShareMappings.slice(0, index),
            {
                share: e.target.value,
                drive: state.aliasShareMappings[index].drive,
            },
            ...state.aliasShareMappings.slice(index + 1, state.aliasShareMappings.length),
        ];
    };

    const changeDriveForAliasShareMapping = (e, index) => {
        state.aliasShareMappings = [
            ...state.aliasShareMappings.slice(0, index),
            {
                share: state.aliasShareMappings[index].share,
                drive: e.target.value,
            },
            ...state.aliasShareMappings.slice(index + 1, state.aliasShareMappings.length),
        ];
    };

    const addAliasShareMappingItem = () => {
        state.aliasShareMappings = [
            ...state.aliasShareMappings,
            {
                share: state.currentShareForAliasShareMapping,
                drive: state.currentDriveForAliasShareMapping,
            },
        ];

        updateCurrentParametersForAliasShareMappings();
    };

    const removeAliasShareMappingItem = (index) => {
        state.aliasShareMappings = [
            ...state.aliasShareMappings.slice(0, index),
            ...state.aliasShareMappings.slice(index + 1, state.aliasShareMappings.length),
        ];

        updateCurrentParametersForAliasShareMappings();
    };

    const disabledGetAccessToken = !state.form.$("user").value;
    const isSchemaDropbox = state.form.$("schema").value === SCHEMA_OPTIONS.dropbox;

    return (
        <Dialog
            onClose={closeIfNeeded}
            open={open}
            title={
                isCreate
                    ? t("slingshot_page.tabs.settings.create_alias")
                    : t("slingshot_page.tabs.settings.edit_alias", {
                          name: slingshotSettingsStore.currentAlias?.name || "",
                      })
            }
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            disableSubmit={isCreate && state.form.$("schema").value === SCHEMA_OPTIONS.gcs && !state.currentFile}
            inProgress={state.inProgress}
            onSubmit={onSubmit}
            onEnter={updateForm}
            maxWidth={"md"}
            hide={folderBrowser.isOpen}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            {state.isLoadingShare && state.form.$("schema").value === SCHEMA_OPTIONS.evo ? (
                <EmptyCardPlaceholder inProgress={true} />
            ) : (
                <Fragment>
                    <Grid container spacing={4}>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography>{t("slingshot_page.tabs.settings.aliases.name")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextInput
                                    field={state.form.$("name")}
                                    inputProps={{ spellCheck: "false" }}
                                    id={`${idPrefix}_name`}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography>{t("slingshot_page.tabs.settings.aliases.schema")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    onChange={onChangeSchema}
                                    field={state.form.$("schema")}
                                    options={state.schemaOptions}
                                    id={`${idPrefix}_schema`}
                                />
                            </Grid>
                        </Grid>
                        {(state.form.$("schema").value === SCHEMA_OPTIONS.smb ||
                            state.form.$("schema").value === SCHEMA_OPTIONS.afp ||
                            state.form.$("schema").value === SCHEMA_OPTIONS.ftp ||
                            state.form.$("schema").value === SCHEMA_OPTIONS.tricaster) && (
                            <Grid item container alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.settings.aliases.host")}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextInput field={state.form.$("host")} id={`${idPrefix}_host`} />
                                </Grid>
                            </Grid>
                        )}

                        {state.form.$("schema").value === SCHEMA_OPTIONS.tricaster && (
                            <Grid item container alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.settings.aliases.media_player")}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        field={state.form.$("mediaPlayer")}
                                        options={MEDIA_PLAYER_ARRAY}
                                        id={`${idPrefix}_media_player`}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {state.form.$("schema").value === SCHEMA_OPTIONS.ftp && (
                            <>
                                <RowControlLabelSwitch
                                    control={
                                        <Switch
                                            field={state.form.$("ftpUserDirIsRoot")}
                                            id={`${idPrefix}_ftp_user_dir_is_root`}
                                        />
                                    }
                                    label={t("slingshot_page.tabs.settings.aliases.ftpUserDirIsRoot")}
                                    lastColumnWidth={"33.3%"}
                                />
                                <RowControlLabelSwitch
                                    control={<Switch field={state.form.$("ftpPassive")} id={`${idPrefix}_ftp_passive`} />}
                                    label={t("slingshot_page.tabs.settings.aliases.ftpPassive")}
                                    lastColumnWidth={"33.3%"}
                                />
                            </>
                        )}
                        {(state.form.$("schema").value === SCHEMA_OPTIONS.smb ||
                            state.form.$("schema").value === SCHEMA_OPTIONS.afp) && (
                            <Grid item container alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.settings.aliases.share")}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextInput field={state.form.$("resource")} id={`${idPrefix}_resource`} />
                                </Grid>
                            </Grid>
                        )}
                        {state.form.$("schema").value === SCHEMA_OPTIONS.azure && (
                            <Grid item container alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.settings.aliases.container")}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextInput field={state.form.$("resource")} id={`${idPrefix}_resource`} />
                                </Grid>
                            </Grid>
                        )}
                        {state.form.$("schema").value === SCHEMA_OPTIONS.s3 && (
                            <>
                                <Grid item container>
                                    <Grid item xs={4}>
                                        <RadioGroup field={state.form.$("regionChoose")}>
                                            <Radio
                                                label={t("slingshot_page.tabs.settings.aliases.region")}
                                                value={S3_REGION}
                                                className={classes.region}
                                                id={`${idPrefix}_radio_region`}
                                            />
                                            <Radio
                                                label={t("slingshot_page.tabs.settings.aliases.custom_host")}
                                                value={S3_CUSTOM_HOST}
                                                className={classes.region}
                                                id={`${idPrefix}_radio_custom_host`}
                                            />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid item>
                                            <Select
                                                className={classes.region}
                                                field={state.form.$("region")}
                                                options={REGION_OPTIONS_ARRAY}
                                                disabled={state.form.$("regionChoose").value === S3_CUSTOM_HOST}
                                                id={`${idPrefix}_region`}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextInput
                                                className={classes.region}
                                                field={state.form.$("host")}
                                                disabled={state.form.$("regionChoose").value === S3_REGION}
                                                id={`${idPrefix}_host`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("slingshot_page.tabs.settings.aliases.bucket")}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextInput field={state.form.$("resource")} id={`${idPrefix}_resource`} />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {state.form.$("schema").value === SCHEMA_OPTIONS.gcs && (
                            <>
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("slingshot_page.tabs.settings.aliases.bucket")}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextInput field={state.form.$("resource")} id={`${idPrefix}_resource`} />
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("slingshot_page.tabs.settings.aliases.credentials_file")}</Typography>
                                    </Grid>
                                    <Grid item xs={8} wrap={"nowrap"} container>
                                        {state.currentFile ? (
                                            <Grid container wrap={"nowrap"}>
                                                <Grid item>
                                                    <DocIcon />
                                                </Grid>
                                                <Grid item container justify={"flex-start"} alignItems={"center"}>
                                                    <Typography>{state.currentFile.name}</Typography>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid item container className={classes.dndContainer}>
                                                <DragAndDropFile
                                                    title={t(
                                                        "slingshot_page.tabs.settings.aliases.credentials_file.drag_and_drop.placeholder"
                                                    )}
                                                    handleFiles={onFilesSelect}
                                                    inputProps={".json"}
                                                    id={`${idPrefix}_file`}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {!isSchemaDropbox && state.form.$("schema").value !== SCHEMA_OPTIONS.gcs && (
                            <Grid item container alignItems={"center"} className={classes.user}>
                                <Grid item xs={4}>
                                    <Typography>
                                        {state.form.$("schema").value === SCHEMA_OPTIONS.s3
                                            ? t("slingshot_page.tabs.settings.aliases.s3_user")
                                            : t("slingshot_page.tabs.settings.aliases.user")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextInput
                                        field={state.form.$("user")}
                                        inputProps={{
                                            autoComplete: "new-name",
                                        }}
                                        id={`${idPrefix}_user`}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {state.form.$("schema").value !== SCHEMA_OPTIONS.evo &&
                            state.form.$("schema").value !== SCHEMA_OPTIONS.gcs && (
                                <Grid item container alignItems={"center"} spacing={4}>
                                    {isSchemaDropbox ? (
                                        <Grid item container alignItems={"center"}>
                                            <Grid item xs={4}>
                                                <Typography>{t("slingshot_page.tabs.settings.aliases.app_key")}</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextInput field={state.form.$("user")} id={`${idPrefix}_user`} />
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                    <Grid item container alignItems={"center"}>
                                        <Grid item xs={4}>
                                            <Typography>
                                                {state.form.$("schema").value === SCHEMA_OPTIONS.s3 &&
                                                    t("slingshot_page.tabs.settings.aliases.s3_password")}
                                                {isSchemaDropbox && t("slingshot_page.tabs.settings.aliases.dropbox_password")}
                                                {state.form.$("schema").value !== SCHEMA_OPTIONS.s3 &&
                                                    !isSchemaDropbox &&
                                                    t("slingshot_page.tabs.settings.aliases.password")}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={isSchemaDropbox ? 6 : true}
                                            spacing={isSchemaDropbox ? 2 : 0}
                                            justify="space-between"
                                        >
                                            <Grid item xs={isSchemaDropbox ? 7 : true}>
                                                <TextInput
                                                    field={state.form.$("password")}
                                                    inputProps={{
                                                        autoComplete: "new-password",
                                                    }}
                                                    id={`${idPrefix}_password`}
                                                />
                                            </Grid>
                                            {isSchemaDropbox ? (
                                                <Grid item xs={5}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={onGetAccessToken}
                                                        disabled={disabledGetAccessToken}
                                                        id={`${idPrefix}_get_access_token`}
                                                    >
                                                        {t("slingshot_page.tabs.settings.aliases.get_access_token")}
                                                    </Button>
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        {state.form.$("schema").value === SCHEMA_OPTIONS.evo && (
                            <Grid item container alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.settings.aliases.share")}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        onChange={onShareSelectChange}
                                        field={state.form.$("resource")}
                                        options={state.shares}
                                        id={`${idPrefix}_resource`}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {state.form.$("schema").value !== SCHEMA_OPTIONS.tricaster && (
                            <Grid item container alignItems={"center"} className={classes.path}>
                                <Grid item xs={4}>
                                    <Typography>{t("slingshot_page.tabs.settings.aliases.path")}</Typography>
                                </Grid>
                                <Grid item xs={8} wrap={"nowrap"} container>
                                    <Grid item container>
                                        <TextInput onChange={onChangePath} field={state.form.$("path")} id={`${idPrefix}_path`} />
                                    </Grid>
                                    {state.form.$("schema").value === SCHEMA_OPTIONS.evo && (
                                        <Grid item>
                                            <Box pl={4}>
                                                <Button
                                                    disabled={!state.form.$("resource").value}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={folderBrowser.open}
                                                    id={`${idPrefix}_open_folder_browser`}
                                                >
                                                    {t("common.button.browse")}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        <Grid item container alignItems={"center"}>
                            <RowControlLabelSwitch
                                control={<Switch field={state.form.$("personal")} id={`${idPrefix}_personal`} />}
                                label={t("slingshot_page.tabs.settings.aliases.personal")}
                                lastColumnWidth={"33.3%"}
                            />
                        </Grid>

                        {state.form.$("schema").value === SCHEMA_OPTIONS.tricaster && (
                            <Grid item container alignItems={"center"}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={"50%"}>
                                                <Grid>
                                                    <Typography
                                                        className={classes.tricasterHeader}
                                                        align="center"
                                                        variant="subtitle1"
                                                    >
                                                        {t(
                                                            "slingshot_page.tabs.settings.aliases.share_mappings.header.share_name"
                                                        )}
                                                    </Typography>
                                                    <SelectBase
                                                        value={state.currentShareForAliasShareMapping ?? ""}
                                                        onChange={addShareForAliasShareMapping}
                                                        options={state.tricasterShares}
                                                        id={`${idPrefix}_add_share`}
                                                    />
                                                </Grid>
                                            </TableCell>

                                            <TableCell width={"30%"}>
                                                <Grid>
                                                    <Typography
                                                        className={classes.tricasterHeader}
                                                        align="center"
                                                        variant="subtitle1"
                                                    >
                                                        {t(
                                                            "slingshot_page.tabs.settings.aliases.share_mappings.header.tricaster_drive"
                                                        )}
                                                    </Typography>
                                                    <SelectBase
                                                        value={state.currentDriveForAliasShareMapping ?? ""}
                                                        onChange={addDriveForAliasShareMapping}
                                                        options={state.tricasterDrives}
                                                        id={`${idPrefix}_add_drive`}
                                                    />
                                                </Grid>
                                            </TableCell>
                                            <TableCell width={"20%"}>
                                                <Grid container justifyContent="center">
                                                    <Typography
                                                        className={classes.tricasterHeader}
                                                        align="center"
                                                        variant="subtitle1"
                                                    >
                                                        {t("slingshot_page.tabs.settings.aliases.share_mappings.header.action")}
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={!state.tricasterShares.length || !state.tricasterDrives.length}
                                                        onClick={addAliasShareMappingItem}
                                                        id={`${idPrefix}_add_alias`}
                                                    >
                                                        {t("common.button.add")}
                                                    </Button>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.aliasShareMappings.map((aliasShareMappingsItem, index) => (
                                            <TableRow key={`${aliasShareMappingsItem.drive} ${aliasShareMappingsItem.share}`}>
                                                <TableCell>
                                                    <SelectBase
                                                        value={aliasShareMappingsItem.share}
                                                        onChange={(e) => {
                                                            changeShareForAliasShareMapping(e, index);
                                                        }}
                                                        options={[...state.tricasterShares, aliasShareMappingsItem.share]}
                                                        id={`${idPrefix}_change_share_${aliasShareMappingsItem.share}`}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <SelectBase
                                                        value={aliasShareMappingsItem.drive}
                                                        onChange={(e) => {
                                                            changeDriveForAliasShareMapping(e, index);
                                                        }}
                                                        options={[...state.tricasterDrives, aliasShareMappingsItem.drive]}
                                                        id={`${idPrefix}_change_drive_${aliasShareMappingsItem.drive}`}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Grid container justifyContent="center">
                                                        <ErrorButton
                                                            submit={() => {
                                                                removeAliasShareMappingItem(index);
                                                            }}
                                                            label={t("common.button.remove")}
                                                            id={`${idPrefix}_remove_alias_share`}
                                                        />
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        )}
                        {!state.hideHelperText && (
                            <Grid item container justify={"flex-end"}>
                                <Grid item xs={8} wrap={"nowrap"} container>
                                    <Typography>
                                        {t("slingshot_page.tabs.settings.aliases.warning_text", {
                                            schemaType: SCHEMA_OPTIONS_ARRAY.find(
                                                (schema) => schema.value === state.form.$("schema").value
                                            )?.label,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <UnsavedChangesDialog
                        onConfirm={onClose}
                        onClose={unsavedChangesModal.close}
                        open={unsavedChangesModal.isOpen}
                    />
                    <FolderBrowser
                        open={folderBrowser.isOpen}
                        onClose={folderBrowser.close}
                        share={state.form.$("resource").value}
                        parentName={t("slingshot_page.tabs.settings.edit_alias", {
                            name: slingshotSettingsStore.currentAlias?.name || "",
                        })}
                        onChoose={setPath}
                        pathValue={state.form.$("path").value}
                        parentId={idPrefix}
                    />
                </Fragment>
            )}
        </Dialog>
    );
};

export default observer(CreateEditAliasDialog);
