import { types } from "mobx-state-tree";

import Request from "api/Request";
import SetAuditPershareArguments from "api/audit/Types/SetAuditPershareArguments";

const EnableAuditOnShares = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Audit",
    method: "enable_audit_on_shares",
    arguments: types.maybe(SetAuditPershareArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default EnableAuditOnShares;
