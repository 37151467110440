import { PORT_MAX, PORT_MIN } from "const/firewallConst";
import i18n from "i18n";
const portsListRule = {
    portsListValue: {
        function: fieldValue => {
            const splitedValue = fieldValue.split(",");
            return !splitedValue.find(value => !(value >= PORT_MIN && value <= PORT_MAX));
        },
        message: i18n.t("rule.port.format", { min: PORT_MIN, max: PORT_MAX })
    }
};

export default portsListRule;
