import React, { useContext } from "react";
import { Button, Typography } from "@material-ui/core";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react";
import Dialog from "components/Dialog";
import { useStyles } from "./PingLastResultsDialog.styles";

const PingLastResultsDialog = ({ onClose, open }) => {
    const {
        store: { pingStore },
    } = useContext(Context);
    const classes = useStyles();
    const { t } = useTranslation();
    return useObserver(() => (
        <Dialog
            submitBtn={
                <Button onClick={onClose} variant={"contained"} color="secondary">
                    {t("common.button.close")}
                </Button>
            }
            maxWidth={"lg"}
            onClose={onClose}
            title={t("network.ping_test.last_results")}
            open={open}
        >
            <Typography className={classes.reportText}>{pingStore.pingResult}</Typography>
        </Dialog>
    ));
};

export default PingLastResultsDialog;
