import { types } from "mobx-state-tree";
import MetadataType from "./MetadataType";

const SecretType = types.model({
    user: types.maybe(types.string),
    password: types.maybeNull(types.string),
    metadata: types.maybe(MetadataType)
});

export default SecretType;
