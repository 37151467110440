import React from "react";
import MainTemplate from "components/MainTemplate";
import HeaderTitle from "components/HeaderTitle";
import OffIcon from "assets/OffIcon";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import UpsConf from "./components/UpsConf";
import Power from "./components/Power";
import { POWER_AND_UPS } from "const/shortcutNameConst";

const PowerAndUps = () => {
    const { t } = useTranslation();
    return (
        <MainTemplate
            headerTitle={<HeaderTitle icon={<OffIcon />} title={t("power_and_ups.title")} shortcutName={POWER_AND_UPS} />}
        >
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <Power />
                </Grid>
                <Grid item xs={6}>
                    <UpsConf />
                </Grid>
            </Grid>
        </MainTemplate>
    );
};

export default PowerAndUps;
