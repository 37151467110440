import { useStore } from "./useStore";
import { useToggle } from "./useToggle";

export const useModalHistory = () => {
    const {
        store: { modalStore },
    } = useStore();

    const [hidden, toggleHidden] = useToggle();

    const onBackProcessedTasksModal = () => {
        toggleHidden();
        modalStore.history.goBack();
    };

    return { goBack: onBackProcessedTasksModal, hidden: hidden, toggleHidden: toggleHidden };
};
