import React from "react";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import Switch from "components/MobxForm/Switch";
import { useLocalStore } from "mobx-react";
import createForm from "utils/createForm";
import createFields from "./createFields";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react-lite";
import Dialog from "components/Dialog";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import {
    SCHEMA_OPTIONS,
    S3_REGION,
    S3_CUSTOM_HOST,
    REGION_OPTIONS_ARRAY,
    S3_CUSTOM_REGION,
    DEFAULT_AIML_BUNCH,
} from "const/slingshotAliasConst";
import Radio from "components/Radio";
import RadioGroup from "components/MobxForm/RadioGroup";
import Select from "components/MobxForm/Select";
import { useStyles } from "./SetupAutotaggingMachineDialog.styles";
import Autocomplete from "components/MobxForm/Autocomplete";
import CreateEditPresetDialog from "../CreateEditPresetDialog";
import { DEFAULT_CONFIDENCE, DEFAULT_LIMIT, DEFAULT_FOLDER_EXPIRATION } from "const/slingshotConfigMachineConst";
import FolderBrowser from "../FolderBrowser";
import sortByLocaleCompare from "utils/sortByLocaleCompare";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";
import ConfirmationDialog from "components/ConfirmationDialog";
import ErrorButton from "components/ErrorButton";

const SETUP_AUTOTAGGING_MACHINE_DIALOG_ID_PREFIX = `setup_autotagging_machine_dialog`;

const SetupAutotaggingMachineDialog = ({ closeDialog, open, parentId }) => {
    const idPrefix = parentId
        ? `${parentId}_${SETUP_AUTOTAGGING_MACHINE_DIALOG_ID_PREFIX}`
        : SETUP_AUTOTAGGING_MACHINE_DIALOG_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { slingshotSettingsStore, slingshotStorageStore },
    } = useStore();
    const classes = useStyles();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
        isSubmitted: false,
        inProgress: false,
        get shares() {
            return (
                (slingshotStorageStore.shares.length &&
                    sortByLocaleCompare(slingshotStorageStore.shares.map((share) => share.name))) ||
                []
            );
        },
    }));
    const { unsavedChangesModal, folderBrowserModal, editPresetModal, resetConfigModal } = useModalCombine([
        "unsavedChangesModal",
        "folderBrowserModal",
        "editPresetModal",
        "resetConfigModal",
    ]);

    const updateForm = () => {
        const transFolder = slingshotSettingsStore.autotaggingMachineSettingsData?.defaults?.transcoding_folder || "";
        const fullPathArr = transFolder.split("/");
        slingshotStorageStore.fetchShares();
        const autotaggingAlias = slingshotSettingsStore.autotaggingAlias;
        const autotaggingPreset = slingshotSettingsStore.autotaggingPreset;
        state.form.$("regionChoose").set("default", S3_REGION);
        state.form.$("region").set("default", REGION_OPTIONS_ARRAY[0].value);
        if (autotaggingAlias) {
            if (
                !!autotaggingAlias.decomposed_base_url.region &&
                autotaggingAlias.decomposed_base_url.region !== S3_CUSTOM_REGION
            ) {
                !!autotaggingAlias.decomposed_base_url.region &&
                    state.form.$("region").set("default", autotaggingAlias.decomposed_base_url.region);
                state.form.$("host").set("default", "");
            } else {
                state.form.$("regionChoose").set("default", S3_CUSTOM_HOST);
                state.form.$("host").set("default", autotaggingAlias.decomposed_base_url.host);
                state.form.$("region").set("default", "");
            }
            state.form.$("resource").set("default", autotaggingAlias.decomposed_base_url.resource);
            state.form.$("user").set("default", autotaggingAlias.secret.user);
            state.form.$("path").set("default", autotaggingAlias.decomposed_base_url.path);
        } else {
            state.form.$("host").set("default", "");
            state.form.$("resource").set("default", "");
            state.form.$("user").set("default", "");
            state.form.$("path").set("default", "");
        }
        autotaggingPreset && state.form.$("preset").set("default", autotaggingPreset.name);
        state.form.$("enablePreset").set("default", slingshotSettingsStore.autotaggingMachineSettingsData?.defaults.transcoding);
        state.form.$("share").set("default", fullPathArr[0]);
        state.form
            .$("folder")
            .set("default", (transFolder && decodeURIComponent(transFolder.substr(fullPathArr[0].length + 1))) || "");
        state.form.$("accessCode").set("default", slingshotSettingsStore.autotaggingMachineSettingsData?.access_code);
        state.form.reset();
        state.form.showErrors(false);
        slingshotSettingsStore.setConfigPresetName(state.form.$("preset").value);
    };

    const setPath = (path) => {
        state.form.$("folder").set("value", path);
    };

    const onShareSelectChange = (e) => {
        state.form.$("share").set("value", e.target.value);
        state.form.$("folder").clear();
    };

    const onSubmit = async (e) => {
        state.form.onSubmit(e);

        if (state.form.$("regionChoose").value === S3_REGION) {
            state.form.$("host").resetValidation();
        }
        if (state.form.$("enablePreset").value === false) {
            state.form.$("share").resetValidation();
        }

        if (!state.form.isValid) return;
        state.inProgress = true;

        let data = {
            schema: SCHEMA_OPTIONS.s3,
            resource: state.form.$("resource").value,
            path: state.form.$("path").value,
        };
        if (state.form.$("regionChoose").value === S3_REGION) {
            data = { ...data, region: state.form.$("region").value || REGION_OPTIONS_ARRAY[0].value, host: undefined };
        } else if (state.form.$("regionChoose").value === S3_CUSTOM_HOST) {
            data = { ...data, region: undefined, host: state.form.$("host").value };
        }

        const resAlias = !slingshotSettingsStore.autotaggingAlias
            ? await slingshotSettingsStore.createAlias({
                  bunch: DEFAULT_AIML_BUNCH,
                  decomposed_base_url: data,
                  name: slingshotSettingsStore.autotaggingMachineSettingsData?.defaults?.s3_alias,
                  secret: {
                      user: state.form.$("user").value,
                      password: state.form.$("password").value,
                  },
                  internal: true,
                  personal: false,
              })
            : await slingshotSettingsStore.updateAlias({
                  bunch: DEFAULT_AIML_BUNCH,
                  decomposed_base_url: data,
                  name: slingshotSettingsStore.autotaggingAlias.name,
                  id: slingshotSettingsStore.autotaggingAlias.id,
                  secret: {
                      user: state.form.$("user").value,
                      password: state.form.$("password").value,
                  },
                  internal: true,
                  personal: false,
              });

        const transcodingFolder = state.form.$("folder").value
            ? `${state.form.$("share").value}/${encodeURIComponent(state.form.$("folder").value)}`
            : state.form.$("share").value;

        const res = await slingshotSettingsStore.setAutotaggingConfig({
            access_code: state.form.$("accessCode").value,
            defaults: {
                confidence: DEFAULT_CONFIDENCE,
                limit: DEFAULT_LIMIT,
                s3_alias:
                    slingshotSettingsStore.autotaggingAlias?.name ||
                    slingshotSettingsStore.autotaggingMachineSettingsData?.defaults?.s3_alias,
                transcoding: state.form.$("enablePreset").value,
                transcoding_folder: (state.form.$("enablePreset").value && transcodingFolder) || "",
                transcoding_folder_expiration: DEFAULT_FOLDER_EXPIRATION,
                transcoding_preset: slingshotSettingsStore.configAutotaggingPreset.id,
            },
            service_endpoint: {
                region:
                    state.form.$("regionChoose").value === S3_REGION ? state.form.$("region").value : state.form.$("host").value,
                metadata: "",
                password: state.form.$("password").value,
                user: state.form.$("user").value,
            },
        });
        state.inProgress = false;

        if (res && resAlias) {
            state.isSubmitted = true;
            slingshotSettingsStore.fetchAutotaggingMachineSettings();
            slingshotSettingsStore.fetchAliases();
        }
        return res;
    };

    const resetConfig = async () => {
        resetConfigModal.close();
        state.inProgress = true;
        const res = await slingshotSettingsStore.resetAutotaggingConfig();
        if (res) {
            await slingshotSettingsStore.fetchAutotaggingMachineSettings();
            await slingshotSettingsStore.fetchAliases();
            updateForm();
            closeDialog();
        }
        state.inProgress = false;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            closeDialog();
            return;
        }
        unsavedChangesModal.open();
    };

    const onChangePreset = (e) => {
        const currentPreset = e.target.textContent;
        state.form.$("preset").value = currentPreset;
        slingshotSettingsStore.setConfigPresetName(currentPreset);
    };

    return useObserver(() => (
        <Dialog
            title={t("slingshot_page.tabs.settings.machine.autotagging_settings.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            open={open}
            onSubmit={onSubmit}
            onEnter={updateForm}
            onClose={closeIfNeeded}
            maxWidth={"md"}
            hide={folderBrowserModal.isOpen}
            inProgress={state.inProgress}
            buttons={
                <>
                    <ErrorButton
                        submit={resetConfigModal.open}
                        disabled={state.inProgress}
                        label={t("common.button.reset")}
                        id={`${idPrefix}_reset`}
                    ></ErrorButton>
                    <Button onClick={closeDialog} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                        {t("common.button.cancel")}
                    </Button>
                </>
            }
        >
            <CreateEditPresetDialog
                open={editPresetModal.isOpen}
                onClose={editPresetModal.close}
                onCloseDrawer={() => {}}
                changeProgressState={() => {}}
                parentId={idPrefix}
            />
            <Grid container spacing={4} direction={"column"}>
                <Grid item container>
                    <Grid item xs={4}>
                        <RadioGroup field={state.form.$("regionChoose")}>
                            <Radio
                                label={t("slingshot_page.tabs.settings.aliases.region")}
                                value={S3_REGION}
                                className={classes.region}
                                id={`${idPrefix}_radio_region`}
                            />
                            <Radio
                                label={t("slingshot_page.tabs.settings.aliases.custom_host")}
                                value={S3_CUSTOM_HOST}
                                className={classes.region}
                                id={`${idPrefix}_radio_custom_host`}
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid item className={classes.region}>
                            <Select
                                field={state.form.$("region")}
                                options={REGION_OPTIONS_ARRAY}
                                disabled={state.form.$("regionChoose").value === S3_CUSTOM_HOST}
                                id={`${idPrefix}_region`}
                            />
                        </Grid>
                        <Grid item className={classes.region}>
                            <TextInput
                                field={state.form.$("host")}
                                disabled={state.form.$("regionChoose").value === S3_REGION}
                                id={`${idPrefix}_host`}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.aliases.bucket")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput field={state.form.$("resource")} id={`${idPrefix}_resource`} />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"} className={classes.user}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.machine.settings.user")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            field={state.form.$("user")}
                            inputProps={{
                                autoComplete: "new-name",
                            }}
                            id={`${idPrefix}_user`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.machine.settings.password")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput
                            field={state.form.$("password")}
                            inputProps={{
                                autoComplete: "new-password",
                            }}
                            id={`${idPrefix}_password`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.machine.path")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput field={state.form.$("path")} id={`${idPrefix}_path`} />
                    </Grid>
                </Grid>
                <RowControlLabelSwitch
                    lastColumnWidth={"33.3%"}
                    control={<Switch field={state.form.$("enablePreset")} id={`${idPrefix}_enable_preset`} />}
                    label={t("slingshot_page.tabs.settings.machine.settings.preset_enable")}
                />
                <Grid container alignContent={"center"} xs={4} item>
                    <Typography variant={"body2"} className={classes.caption}>
                        {t("slingshot_page.tabs.settings.machine.settings.preset_enable.helper")}
                    </Typography>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.machine.settings.preset")}</Typography>
                    </Grid>
                    <Grid item xs={8} wrap={"nowrap"} container>
                        <Grid item container>
                            <Autocomplete
                                onChange={onChangePreset}
                                disabled={!state.form.$("enablePreset").value}
                                field={state.form.$("preset")}
                                options={slingshotSettingsStore.presets.map((preset) => preset.name)}
                                id={`${idPrefix}_preset`}
                            />
                        </Grid>
                        <Grid item>
                            <Box pl={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!state.form.$("preset").value}
                                    onClick={editPresetModal.open}
                                    id={`${idPrefix}_open_edit_preset_modal`}
                                >
                                    {t("common.button.edit")}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.aliases.share")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                            disabled={!state.form.$("enablePreset").value}
                            onChange={onShareSelectChange}
                            field={state.form.$("share")}
                            options={state.shares}
                            id={`${idPrefix}_share`}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"} className={classes.path}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.machine.settings.folder")}</Typography>
                    </Grid>
                    <Grid item xs={8} wrap={"nowrap"} container>
                        <Grid item container>
                            <TextInput
                                disabled={!state.form.$("enablePreset").value || !state.form.$("share").value}
                                field={state.form.$("folder")}
                                id={`${idPrefix}_folder`}
                            />
                        </Grid>
                        <Grid item>
                            <Box pl={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!state.form.$("share").value || !state.form.$("enablePreset").value}
                                    onClick={folderBrowserModal.open}
                                    id={`${idPrefix}_open_folder_browser_modal`}
                                >
                                    {t("common.button.browse")}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot_page.tabs.settings.machine.settings.access_code")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput field={state.form.$("accessCode")} id={`${idPrefix}_access_code`} />
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} xs={4} item>
                    <Typography variant={"body2"} className={classes.caption}>
                        {t("slingshot_page.tabs.settings.machine.settings.access_code.helper")}
                    </Typography>
                </Grid>
            </Grid>
            <ConfirmationDialog
                title={t("common.warning_dialog.title")}
                open={resetConfigModal.isOpen}
                onClose={resetConfigModal.close}
                onConfirm={resetConfig}
                confirmLabel={t("common.button.proceed")}
                cancelLabel={t("common.button.cancel")}
                fullWidth={true}
                id={`${idPrefix}_confirmation_dialog`}
            >
                {t("slingshot_page.tabs.settings.machine.autotagging_settings.confirm_reset_config")}
            </ConfirmationDialog>
            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            <FolderBrowser
                open={folderBrowserModal.isOpen}
                onClose={folderBrowserModal.close}
                share={state.form.$("share").value}
                parentName={t("slingshot_page.tabs.settings.machine.autotagging_settings.title")}
                onChoose={setPath}
                pathValue={state.form.$("folder").value}
                parentId={idPrefix}
            />
        </Dialog>
    ));
};

export default SetupAutotaggingMachineDialog;
