import { types } from "mobx-state-tree";

import Notification from "api/events/Types/Notification";

const Notifications = types.model({
    failedDrive: types.maybe(Notification),
    resyncStarted: types.maybe(Notification),
    resyncFinished: types.maybe(Notification),
    webConfigurationPortChanged: types.maybe(Notification),
    ipAddressChanged: types.maybe(Notification),
    reboot: types.maybe(Notification),
    start: types.maybe(Notification),
    shutdown: types.maybe(Notification),
    userMgmt: types.maybe(Notification),
    externalUser: types.maybe(Notification),
    upsstatus: types.maybe(Notification),
    upscharge: types.maybe(Notification),
    upscomm: types.maybe(Notification),
    networkReconfiguration: types.maybe(Notification),
    volumeUsage: types.maybe(Notification),
    systemDiskUsage: types.maybe(Notification),
    clusterCriticalEventsDetected: types.maybe(Notification)
});

export default Notifications;
