import { makeStyles } from "@material-ui/core/styles";
import { TABLE_DIVIDER_GRAY } from "const/styleConst";

export const useStyles = makeStyles(() => ({
    divideredRow: {
        borderTop: `1px solid ${TABLE_DIVIDER_GRAY}`
    },
    totalNodes: {
        fontWeight: "bold"
    }
}));
