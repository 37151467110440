import React from "react";

const NotificationsButtonIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
                fill="#FFF"
                fillRule="evenodd"
                d="M20.52 15.21l-1.8-1.81V8.94c.05-3.425-2.435-6.361-5.82-6.88-3.69-.486-7.076 2.112-7.562 5.802-.038.288-.057.578-.058.868v4.67l-1.8 1.81c-.635.646-.627 1.684.02 2.32.304.299.713.468 1.14.47H8v.34c.097 2.114 1.886 3.75 4 3.66 2.114.09 3.903-1.546 4-3.66V18h3.36c.906-.005 1.636-.743 1.63-1.65-.002-.426-.17-.836-.47-1.14zM14 18.34c-.115 1-.997 1.73-2 1.66-1.003.07-1.885-.66-2-1.66V18h4v.34zM5.51 16l1.18-1.18c.378-.376.59-.887.59-1.42V8.73c.001-1.365.592-2.662 1.62-3.56 1.015-.92 2.382-1.347 3.74-1.17 2.391.388 4.131 2.478 4.08 4.9v4.5c-.003.532.206 1.043.58 1.42L18.49 16H5.51z"
                {...props}
            />
        </svg>
    );
};

export default NotificationsButtonIcon;
