import { types, flow, getParent } from "mobx-state-tree";
import { ASC, DESC, LONG_LIVED_TOKENS_NAME } from "const/sortColumnConst";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import ListTokens from "api/auth/Requests/ListTokens";
import RevokeToken from "api/auth/Requests/RevokeToken";
import RevokeAllTokens from "api/auth/Requests/RevokeAllTokens";
import GetSettings from "api/auth/Requests/GetSettings";
import SetSettings from "api/auth/Requests/SetSettings";
import AuthToken from "api/auth/Types/AuthToken";
import Socket from "websocket/WebSocket";
import moment from "moment";
import { SERVER_LONG_LIVED_TOKENS_FORMAT } from "const";

const LongLivedTokensStore = types
    .model({
        tokens: types.maybe(types.array(AuthToken)),
        currentToken: types.maybeNull(types.string),
        currentTimeout: types.optional(types.number, 1),
    })
    .volatile(() => ({
        orderBy: LONG_LIVED_TOKENS_NAME,
        order: ASC,
    }))
    .views((self) => ({
        get tokensWithTypedDate() {
            if (self.tokens) {
                return self.tokens.map((tokenInfo) => ({
                    ...tokenInfo,
                    expirationDate: moment(tokenInfo.expirationDate, SERVER_LONG_LIVED_TOKENS_FORMAT).toDate(),
                }));
            }
            return [];
        },
        get sortedTokens() {
            if (self.tokensWithTypedDate) return stableSort(self.tokensWithTypedDate, getComparator(self.order, self.orderBy));
            return [];
        },
        get currentTokenInfo() {
            return self.sortedTokens.find((tokenInfo) => tokenInfo.token === self.currentToken);
        },
    }))
    .actions((self) => ({
        fetchTokens: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ListTokens.create().init();
                const res = yield Socket.send(req);
                self.tokens = res.data;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        revokeToken: flow(function* (token) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RevokeToken.create().init(token);
                yield Socket.send(req);
                self.removeCurrentToken(null);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        revokeAllTokens: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RevokeAllTokens.create().init();
                yield Socket.send(req);
                self.removeCurrentToken(null);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getExpiration: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetSettings.create().init();
                const res = yield Socket.send(req);
                self.setCurrentTimeout(res.data.expirationPeriod);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setExpiration: flow(function* (expiration) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetSettings.create().init(expiration);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setCurrentToken(token) {
            self.currentToken = token;
        },
        removeCurrentToken() {
            self.currentToken = null;
        },
        changeSorting(column) {
            if (column === self.orderBy) {
                self.order = self.order === DESC ? ASC : DESC;
            } else {
                self.order = ASC;
                self.orderBy = column;
            }
        },
        setCurrentTimeout(timeout) {
            self.currentTimeout = timeout;
        },
        addToken(newToken) {
            self.tokens = [...self.tokens, newToken];
        },
        removeTokens(revokedTokens) {
            revokedTokens.forEach((revokedToken) => {
                self.tokens = self.tokens.filter((tokenInfo) => revokedToken !== tokenInfo.token);
            });
        },
    }));

export default LongLivedTokensStore;
