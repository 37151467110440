import React, { useContext, useEffect } from "react";
import { useStyles } from "./EthernetDrawer.styles";
import { Grid, Typography } from "@material-ui/core";
import { Context } from "store";
import { observer, useLocalStore } from "mobx-react";
import EthernetIcon from "assets/EthernetIcon";
import { useTranslation } from "react-i18next";
import SavedInputIndicator from "components/SavedInputIndicator";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import EditSettingsDialog from "../EditSettingsDialog";
import StatusTranslation from "../StatusTranslation";
import EditServiceOrderDialog from "../EditServiceOrderDialog";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import useStoreByIp from "hooks/useStoreByIp";
import EditIcon from "assets/EditIcon";
import FiberIcon from "assets/FibreIcon";
import SignalIcon from "assets/SignalIcon";
import RefreshIcon from "assets/RefreshIcon";
import { useModalCombine } from "hooks/useModalCombine";
import ReorderEthernetPortsDialog from "../ReorderEthernetPortsDialog";

const EthernetDrawer = ({ ip }) => {
    const drawerName = ip ? `${ip}EthernetDrawer` : "EthernetDrawer";
    const {
        store: { uiStore },
    } = useContext(Context);
    const { ethernetPortsStore } = useStoreByIp({ ip });
    const { t } = useTranslation();
    const classes = useStyles({ isWidgetOpen: uiStore.isWidgetsBarOpen });
    const currentIface = ethernetPortsStore.currentPort || { dhcpServer: {}, dhcpClient: {} };
    const state = useLocalStore(() => ({
        settingsDialogOpen: false,
        serviceOrderDialogOpen: false,
        identifyInProgress: false,
        renewInProgress: false,
    }));
    useEffect(() => {
        if (ethernetPortsStore.currentPortName) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, closeDrawer);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [ethernetPortsStore.currentPortName]);

    const { editServiceOrderModal, editSettingsModal, reorderEthernetPortsModal } = useModalCombine([
        "editServiceOrderModal",
        "editSettingsModal",
        "reorderEthernetPortsModal",
    ]);

    const closeDrawer = () => {
        ethernetPortsStore.setCurrentPort("");
    };

    const renderDetails = () => {
        return (
            <Grid className={classes.inputsContainer} container direction={"column"} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.ip")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentIface.ip}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.subnet")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentIface.netmask}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.gateway")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentIface.gateway}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.speed")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentIface.speed}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.status")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            <StatusTranslation label={currentIface.status} />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.mtu")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentIface.mtu}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.metric")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentIface.metric}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.type")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {t("common.speed_value.gbs", { speed: currentIface.maxSpeedGbs })}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.mac")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentIface.mac}</Typography>
                    </Grid>
                </Grid>
                {!ethernetPortsStore.isEthernetPortUsedByBridgeOrBond && (
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("network.ethernet_ports_drawer.label.role")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.textInfo}>
                                <StatusTranslation label={ethernetPortsStore.roleOfCurrentPort} />
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {ethernetPortsStore.isDhcpClientRole && (
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("network.ethernet_ports_drawer.label.override_dns")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SavedInputIndicator enabled={currentIface.dhcpClient.overrideDns} />
                        </Grid>
                    </Grid>
                )}
                {ethernetPortsStore.isDhcpServerRole && (
                    <>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("network.ethernet_ports_drawer.label.dhcp_from")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>{currentIface.dhcpServer.startDhcpRange}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("network.ethernet_ports_drawer.label.dhcp_to")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>{currentIface.dhcpServer.endDhcpRange}</Typography>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.flow_control_enforced")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <SavedInputIndicator enabled={currentIface.flowcontrolEnforced} />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const renewDhcpLease = async () => {
        state.renewInProgress = true;
        await ethernetPortsStore.renewDhcpLease({ port: ethernetPortsStore.currentPortName });
        state.renewInProgress = false;
        ethernetPortsStore.fetchAllNetRequests();
    };

    const identifyIface = async () => {
        state.identifyInProgress = true;
        await ethernetPortsStore.identifyIface({ port: ethernetPortsStore.currentPortName });
        state.identifyInProgress = false;

        ethernetPortsStore.fetchAllNetRequests();
    };
    const renderActions = () => {
        return (
            <>
                <BulkBarFlatButton icon={<EditIcon />} onClick={editSettingsModal.open}>
                    {t("network.ethernet_ports_drawer.button.edit_settings")}
                </BulkBarFlatButton>
                <BulkBarFlatButton icon={<FiberIcon />} onClick={editServiceOrderModal.open}>
                    {t("network.ethernet_ports_drawer.button.edit_service_order")}
                </BulkBarFlatButton>
                {ethernetPortsStore.isDhcpClientRole && (
                    <BulkBarFlatButton icon={<RefreshIcon />} inProgress={state.renewInProgress} onClick={renewDhcpLease}>
                        {t("network.ethernet_ports_drawer.button.renew_dhcp_lease")}
                    </BulkBarFlatButton>
                )}
                <BulkBarFlatButton icon={<SignalIcon />} inProgress={state.identifyInProgress} onClick={identifyIface}>
                    {t("network.ethernet_ports_drawer.button.identify")}
                </BulkBarFlatButton>
                {!ethernetPortsStore.isEthernetPortUsedByBridgeOrBond && !ethernetPortsStore.currentPort?.builtin && (
                    <BulkBarFlatButton icon={<FiberIcon />} onClick={reorderEthernetPortsModal.open}>
                        {t("network.ethernet_ports_drawer.button.edit_ports_order")}
                    </BulkBarFlatButton>
                )}
            </>
        );
    };
    const submitSettings = async (data) => {
        const res = await ethernetPortsStore.saveNetIfaceSettings(data);
        ethernetPortsStore.fetchAllNetRequests();
        return res;
    };
    return (
        <DetailsActionsDrawer
            title={currentIface.port}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            icon={<EthernetIcon />}
            modals={[
                <EditServiceOrderDialog
                    ip={ip}
                    key={"EthernetEditServiceOrderDialog"}
                    handleClose={editServiceOrderModal.close}
                    open={editServiceOrderModal.isOpen}
                    afterSubmitCallback={closeDrawer}
                />,
                <EditSettingsDialog
                    ip={ip}
                    key={"EthernetEditSettingsDialog"}
                    store={ethernetPortsStore}
                    title={t("network.ethernet_edit_settings_dialog.title", { port: currentIface?.port || "" })}
                    handleClose={editSettingsModal.close}
                    open={editSettingsModal.isOpen}
                    onSubmit={submitSettings}
                    currentPort={ethernetPortsStore.currentPort}
                    afterSubmitCallback={closeDrawer}
                />,
                <ReorderEthernetPortsDialog
                    ip={ip}
                    key={"ReorderEthernetPortsDialog"}
                    handleClose={reorderEthernetPortsModal.close}
                    open={reorderEthernetPortsModal.isOpen}
                    afterSubmitCallback={closeDrawer}
                />,
            ]}
        />
    );
};

export default observer(EthernetDrawer);
