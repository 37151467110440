import React, { useContext, useEffect, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { SHORTCUT } from "const/dragAndDropTypes";
import { useLocalStore, useObserver } from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import DragVerticalDivider from "../VerticalDraggingNavDivider";
import { useStyles } from "./CustomNavigationAction.styles";
import { Context } from "store";
import { Grid } from "@material-ui/core";

const CustomNavigationAction = ({ className, name, index, children }) => {
    const {
        store: { uiStore }
    } = useContext(Context);
    const ref = useRef(null);
    const childRef = useRef(null);
    const [{ isOver }, drop] = useDrop({
        accept: SHORTCUT,
        hover(item, monitor) {
            if (name === item.name) {
                return;
            }
            const rect = childRef.current.getBoundingClientRect();
            if (rect.x + rect.width / 2 > monitor.getClientOffset().x) {
                state.hoverPart = 1;
            } else {
                state.hoverPart = -1;
            }
        },
        drop(item, monitor) {
            const didDrop = monitor.didDrop();
            if (!ref.current || name === item.name || didDrop) {
                return;
            }
            uiStore.changeShortcutLayout(item.index, (state.hoverPart === -1) + index);
            state.hoverPart = 0;
        },
        collect: monitor => ({
            isOver: monitor.isOver()
        })
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: SHORTCUT, name, index },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });
    drag(drop(ref));
    useEffect(() => {
        if (!isOver && state.hoverPart !== 0) {
            state.hoverPart = 0;
        }
    }, [isOver]);
    const state = useLocalStore(() => ({
        // 0 - not hovered, 1 - left, -1 - right
        hoverPart: 0
    }));
    const classes = useStyles({ isDragging, hoverPart: state.hoverPart });
    return useObserver(() => (
        <Grid container direction={"column"} ref={ref} className={clsx(classes.container, className)}>
            {state.hoverPart === 1 && (
                <Grid item>
                    <DragVerticalDivider hoverPart={state.hoverPart} />
                </Grid>
            )}
            <Grid item>
                <Box ref={childRef} className={classes.childContainer}>
                    {children}
                </Box>
            </Grid>
            {state.hoverPart === -1 && (
                <Grid item>
                    <DragVerticalDivider hoverPart={state.hoverPart} />
                </Grid>
            )}
        </Grid>
    ));
};

export default CustomNavigationAction;
