import { types } from "mobx-state-tree";

import UsbAce from "api/security/acl/Types/UsbAce";

const GetUsbAclResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(UsbAce)
});

export default GetUsbAclResult;
