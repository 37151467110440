import { types } from "mobx-state-tree";

import Plugin from "api/plugins/Types/Plugin";

const ListPluginsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(Plugin)
});

export default ListPluginsResult;
