import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useStore } from "hooks/useStore";
import { useTranslation } from "react-i18next";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography, Box } from "@material-ui/core";
import RebootIcon from "assets/RebootIcon";
import EditIcon from "assets/EditIcon";
import CloseIcon from "assets/CloseIcon";
import EditPeerDialog from "../EditPeerDialog";
import { useModal } from "hooks/useModal";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import { PEER_STATUS_OPTIONS, PEER_EXTENDED_STATUS_OPTIONS_ARRAY, PEER_EXTENDED_STATUS_OPTIONS } from "const/clusterConst";
import StatusLabel from "../PeersCard/components/StatusLabel";

const drawerName = "peersDrawer";
const PeersDrawer = () => {
    const { t } = useTranslation();
    const editPeerModal = useModal();

    const {
        store: { clusterStore }
    } = useStore();

    const state = useLocalStore(() => ({
        isRemovePeerInProgress: false,
        isUpdateRemoteBricksInProgress: false
    }));

    const closeDrawer = () => {
        clusterStore.setCurrentPeerName(null);
    };

    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: clusterStore.currentPeerName });

    const openEditPeerDialog = () => {
        editPeerModal.open();
    };

    const getExtendedStatus = extendedStatus => {
        return PEER_EXTENDED_STATUS_OPTIONS_ARRAY.find(entry => entry.value === extendedStatus)?.label || "";
    };

    const removePeer = async () => {
        state.isRemovePeerInProgress = true;
        const res = await clusterStore.removePeer({ name: clusterStore.currentPeerName });
        state.isRemovePeerInProgress = false;
        res && closeDrawer();
    };

    const updateRemoteBricks = async () => {
        state.isUpdateRemoteBricksInProgress = true;
        const res = await clusterStore.updateRemoteBricks({ name: clusterStore.currentPeerName });
        state.isUpdateRemoteBricksInProgress = false;
        res && closeDrawer();
    };

    const renderDetails = () => {
        return (
            clusterStore.currentPeer && (
                <Box py={4} px={6}>
                    <Grid container direction={"column"} spacing={4}>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("cluster.peers.header.name")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h6"}>{clusterStore.currentPeer?.alias}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("cluster.peers.header.ip")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h6"}>{clusterStore.currentPeer?.ip}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("cluster.peers.header.extended_status")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"h6"}>
                                    {getExtendedStatus(clusterStore.currentPeer?.extendedStatus)}
                                    {clusterStore.currentPeer?.extendedStatus}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("cluster.peers.header.status")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <StatusLabel status={clusterStore.currentPeer?.status} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )
        );
    };

    const renderActions = () => {
        return (
            clusterStore.currentPeer && (
                <Grid direction={"column"} container>
                    {clusterStore.currentPeer?.status !== PEER_STATUS_OPTIONS.connected && (
                        <BulkBarFlatButton icon={<EditIcon />} onClick={openEditPeerDialog}>
                            {t("common.button.edit")}
                        </BulkBarFlatButton>
                    )}
                    {clusterStore.currentPeer?.extendedStatus !== PEER_EXTENDED_STATUS_OPTIONS.localPeer && (
                        <BulkBarFlatButton icon={<CloseIcon />} onClick={removePeer} inProgress={state.isRemovePeerInProgress}>
                            {t("cluster.peers.action.remove_from_cluster")}
                        </BulkBarFlatButton>
                    )}
                    <BulkBarFlatButton
                        icon={<RebootIcon />}
                        onClick={updateRemoteBricks}
                        inProgress={state.isUpdateRemoteBricksInProgress}
                    >
                        {t("cluster.peers.action.reload_brick_data")}
                    </BulkBarFlatButton>
                </Grid>
            )
        );
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            title={clusterStore.currentPeer?.alias || ""}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            modals={[<EditPeerDialog key={"EditClusterPeerDialog"} open={editPeerModal.isOpen} onClose={editPeerModal.close} />]}
        />
    ));
};

export default PeersDrawer;
