import React, { useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { useStore } from "hooks/useStore";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import ButtonNext from "../ButtonNext/ButtonNext";
import i18next from "i18next";
import { useStyles } from "./MainStep.styles";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { URL_EXTERNAL_SUPPORT } from "routes";
import { SupportQuickStartIcon } from "assets/SupportQuickStartIcon";
import Link from "components/Link/Link";
import { Trans } from "react-i18next";
import Stage from "../Stage/Stage";

const MainStep = () => {
    const classes = useStyles();

    const {
        store: { supportInfoStore, migrationStore },
    } = useStore();

    useLayoutEffect(() => {
        migrationStore.getMainStepProgress();
    }, []);

    const Icon = () => {
        return (
            <Link path={URL_EXTERNAL_SUPPORT} inNewTab key="2">
                <SupportQuickStartIcon />
            </Link>
        );
    };

    const DisclaimerMigration = observer(() => {
        if (migrationStore.isFailedMigration)
            return (
                <Typography>
                    <Trans
                        i18nKey="migrator.main_step.error"
                        components={[
                            <span className={classes.link} key="1" onClick={supportInfoStore.getMigrationSystemLogs} />,
                            <Icon key="2" />,
                        ]}
                    />
                </Typography>
            );
        if (!migrationStore.isRunningMigration) return <Typography>{i18next.t("migrator.main_step.description")}</Typography>;

        return null;
    });
    if (!migrationStore.mainStepProgress.length)
        return (
            <Box p={6}>
                <EmptyCardPlaceholder inProgress />
            </Box>
        );

    return (
        <Box p={6}>
            <Grid container spacing={2}>
                <Stage list={migrationStore.mainStepProgress} />
            </Grid>
            <Box p={6}>
                <Divider />
            </Box>
            <Grid container spacing={6}>
                <Grid item xs={7}>
                    <DisclaimerMigration />
                </Grid>
                <Grid item xs={5} container justifyContent="center">
                    <ButtonNext
                        text={i18next.t(
                            migrationStore.isFailedMigration
                                ? "migrator.main_step.button.try_again"
                                : "migrator.main_step.button.start"
                        )}
                        onClick={migrationStore.runMainStep}
                        sideProgress={migrationStore.isRunningMigration}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default observer(MainStep);
