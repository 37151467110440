const parseUrl = url => {
    let share = "";
    let folder = "";

    const pathArr = url.split("/");
    share = pathArr[0] || "";

    if (pathArr.length > 1) {
        folder = pathArr.slice(1).join("/");
    }

    return { share, folder };
};

export default parseUrl;
