import Request from "api/Request";

const GetUiLockReason = Request.props({
    path: "/com/studionetworksolutions/evo/manage/UiSettings",
    method: "get_ui_lock_reason",
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default GetUiLockReason;
