import i18n from "i18n";

export const DEFAULT_PROTOCOL = "smb2_1";
export const DEFAULT_SECURITY = "default";
export const PROTOCOL_OPTIONS = [
    { label: "SMB 2", value: "smb2" },
    { label: "SMB 2.1", value: "smb2_1" },
    { label: "SMB 3", value: "smb3" },
    { label: "SMB 3.02", value: "smb3_02" },
    { label: "SMB 3.11", value: "smb3_11" },
];
export const DETAILS_PROTOCOL_OPTIONS = [...PROTOCOL_OPTIONS, { label: "SMB", value: "default" }];
export const SECURITY_OPTIONS = [
    { label: i18n.t("share.remote_shares.add_share.security.dropdown_option.default"), value: "default" },
    { label: i18n.t("share.remote_shares.add_share.security.dropdown_option.none"), value: "none" },
    { label: i18n.t("share.remote_shares.add_share.security.dropdown_option.ntlm"), value: "ntlm" },
    { label: i18n.t("share.remote_shares.add_share.security.dropdown_option.ntlmi"), value: "ntlmi" },
    { label: i18n.t("share.remote_shares.add_share.security.dropdown_option.ntlmv2"), value: "ntlmv2" },
    { label: i18n.t("share.remote_shares.add_share.security.dropdown_option.ntlmv2i"), value: "ntlmv2i" },
    { label: i18n.t("share.remote_shares.add_share.security.dropdown_option.ntlmssp"), value: "ntlmssp" },
    { label: i18n.t("share.remote_shares.add_share.security.dropdown_option.ntlmsspi"), value: "ntlmsspi" },
];
