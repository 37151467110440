import React from "react";

const BrickIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <linearGradient id="brickIcon_b" x1="37.055%" x2="70.216%" y1="23.951%" y2="87.259%">
                    <stop offset="0%" stopColor="#A770FF" />
                    <stop offset="100%" stopColor="#85ADFF" />
                </linearGradient>
                <linearGradient id="brickIcon_a" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#E19EFF" stopOpacity=".771" />
                    <stop offset="100%" stopColor="#293CFF" stopOpacity=".6" />
                </linearGradient>
                <linearGradient id="brickIcon_c" x1="50%" x2="95.116%" y1="46.057%" y2="100%">
                    <stop offset="0%" stopColor="#CE91FF" />
                    <stop offset="100%" stopColor="#B6ADFF" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="url(#brickIcon_b)"
                    stroke="url(#brickIcon_a)"
                    d="M19 1.5h-7.793L3.5 9.207V21c0 .414.168.79.44 1.06.27.272.646.44 1.06.44h14c.414 0 .79-.168 1.06-.44.272-.27.44-.646.44-1.06V3c0-.414-.168-.79-.44-1.06-.27-.272-.646-.44-1.06-.44z"
                />
                <rect width="12" height="1" x="6" y="12" fill="#FFF" opacity=".4" rx=".5" />
                <rect width="12" height="1" x="6" y="15" fill="#FFF" opacity=".4" rx=".5" />
                <rect width="12" height="1" x="6" y="18" fill="#FFF" opacity=".4" rx=".5" />
                <path fill="url(#brickIcon_c)" d="M11 1v6c0 1.105-.895 2-2 2H3l8-8z" />
            </g>
        </svg>
    );
};

export default BrickIcon;
