import { types } from "mobx-state-tree";

import DomainObjectId from "api/security/Types/DomainObjectId";

const UsbAce = types.model({
    trustee: types.maybe(DomainObjectId),
    access: types.string
});

export default UsbAce;
