import { useMemo, useReducer } from "react";

/**
 * Hook for managing the state of many modal.
 * @param {Array<string>} modals
 * @returns {Object.<string, { isOpen: boolean, open: ()=>void, close: ()=>void}> & {closeModalAll: function} } modal objects, according to the values passed in the modal array and a function for changing the state of modal to closed for all modal simultaneously.
 * @example
 * const { resetPasswordModal, userMembershipModal, closeModalAll } = useModalCombine(["resetPasswordModal", "userMembershipModal"]);
 * resetPasswordModal.isOpen; // false or true
 * resetPasswordModal.close; // isOpen = false
 * resetPasswordModal.open; // isOpen = true
 * closeModalAll() // All isOpen states of modal windows are switched to false.
 */
export const useModalCombine = modals => {
    const initialState = modals.reduce(
        (newObj, modal) => ({
            ...newObj,
            [modal]: false
        }),
        {}
    );

    const OPEN = "OPEN";
    const CLOSE = "CLOSE";

    const reducer = (state, action) => {
        switch (action.type) {
            case OPEN:
                return { ...state, [action.payload]: true };
            case CLOSE:
                return { ...state, [action.payload]: false };
            default:
                throw new Error();
        }
    };
    const actionOpen = modal => ({ type: OPEN, payload: modal });
    const actionClose = modal => ({ type: CLOSE, payload: modal });

    const [state, dispatch] = useReducer(reducer, initialState);

    const returnObject = useMemo(
        () =>
            modals.reduce(
                (newObj, modal) => ({
                    ...newObj,
                    [modal]: {
                        isOpen: state[modal],
                        open() {
                            dispatch(actionOpen(modal));
                        },
                        close() {
                            dispatch(actionClose(modal));
                        }
                    }
                }),
                {
                    closeModalAll() {
                        modals.forEach(modal => dispatch(actionClose(modal)));
                    }
                }
            ),
        [state]
    );

    return returnObject;
};
