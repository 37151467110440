import i18n from "i18n";

export const DAY_OF_WEEKS_OPTIONS = {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday"
};

export const DAYS_OF_WEEK_ARRAY = [
    { value: DAY_OF_WEEKS_OPTIONS.monday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.monday") },
    { value: DAY_OF_WEEKS_OPTIONS.tuesday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.tuesday") },
    { value: DAY_OF_WEEKS_OPTIONS.wednesday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.wednesday") },
    { value: DAY_OF_WEEKS_OPTIONS.thursday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.thursday") },
    { value: DAY_OF_WEEKS_OPTIONS.friday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.friday") },
    { value: DAY_OF_WEEKS_OPTIONS.saturday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.saturday") },
    { value: DAY_OF_WEEKS_OPTIONS.sunday, label: i18n.t("slingshot_page.tabs.replications.create_job.week_day.sunday") }
];

export const HOURS = [
    { value: 0, label: "00" },
    { value: 1, label: "01" },
    { value: 2, label: "02" },
    { value: 3, label: "03" },
    { value: 4, label: "04" },
    { value: 5, label: "05" },
    { value: 6, label: "06" },
    { value: 7, label: "07" },
    { value: 8, label: "08" },
    { value: 9, label: "09" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },
    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" }
];

export const MUNUTES = [
    { value: 0, label: "00" },
    { value: 15, label: "15" },
    { value: 30, label: "30" },
    { value: 45, label: "45" }
];

export const DELETE_BACKUPS = [
    { value: 0, label: i18n.t("support.os_data.backup_card.delete_backups.never") },
    { value: 86400, label: i18n.t("support.os_data.backup_card.delete_backups.after_one_day") },
    { value: 172800, label: i18n.t("support.os_data.backup_card.delete_backups.after_two_days") },
    { value: 259200, label: i18n.t("support.os_data.backup_card.delete_backups.after_three_days") },
    { value: 604800, label: i18n.t("support.os_data.backup_card.delete_backups.after_one_week") },
    { value: 1209600, label: i18n.t("support.os_data.backup_card.delete_backups.after_two_weeks") },
    { value: 2419200, label: i18n.t("support.os_data.backup_card.delete_backups.after_four_weeks") },
    { value: 5259600, label: i18n.t("support.os_data.backup_card.delete_backups.after_two_months") },
    { value: 10519200, label: i18n.t("support.os_data.backup_card.delete_backups.after_four_months") },
    { value: 15778800, label: i18n.t("support.os_data.backup_card.delete_backups.after_six_months") },
    { value: 31557600, label: i18n.t("support.os_data.backup_card.delete_backups.after_one_year") }
];

export const IMPORT_DATABASE_FILE = "upload";
export const IMPORT_DATABASE_RESTORE_LOCATION = "restore_location";
export const IMPORT_CONFIG_KEEP_CURRENT = i18n.t("support.os_data.backup_card.import_database.keep_current_config");

export const DATABASE_SLINGSHOT = "slingshot";
export const DATABASE_SHAREBROWSER = "sharebrowser";
