import { types } from "mobx-state-tree";

const DeleteAutomationArguments = types.model({
    json: types.maybe(
        types.model({
            automationId: types.number
        })
    )
});

export default DeleteAutomationArguments;
