import React from "react";

const PicDiskIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 230 208" {...props}>
            <defs>
                <linearGradient id="linearGradient-1PicDisk" x1="30.472%" x2="71.161%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#97CCFF" />
                    <stop offset="54.03%" stopColor="#8DC6FF" />
                    <stop offset="100%" stopColor="#97CCFF" />
                </linearGradient>
                <linearGradient id="linearGradient-2PicDisk" x1="5.603%" x2="85.186%" y1="5.187%" y2="100%">
                    <stop offset="0%" stopColor="#50A8FF" />
                    <stop offset="54.677%" stopColor="#3D9FFF" />
                    <stop offset="100%" stopColor="#2D97FF" />
                </linearGradient>
                <linearGradient id="linearGradient-3PicDisk" x1="21.635%" x2="84.711%" y1="14.713%" y2="84%">
                    <stop offset="0%" stopColor="#96CBFF" />
                    <stop offset="100%" stopColor="#90C2FF" />
                </linearGradient>
                <linearGradient id="linearGradient-4PicDisk" x1="0%" x2="89.626%" y1="5.187%" y2="100%">
                    <stop offset="0%" stopColor="#50A8FF" />
                    <stop offset="54.677%" stopColor="#3D9FFF" />
                    <stop offset="100%" stopColor="#2D97FF" />
                </linearGradient>
                <linearGradient id="linearGradient-5PicDisk" x1="13.432%" x2="89.626%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#97CCFF" />
                    <stop offset="54.03%" stopColor="#8DC6FF" />
                    <stop offset="100%" stopColor="#97CCFF" />
                </linearGradient>
                <linearGradient id="linearGradient-6PicDisk" x1="18.046%" x2="84.626%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#97CCFF" />
                    <stop offset="54.03%" stopColor="#8DC6FF" />
                    <stop offset="100%" stopColor="#97CCFF" />
                </linearGradient>
                <linearGradient id="linearGradient-7PicDisk" x1="0%" x2="89.626%" y1="49.447%" y2="50.617%">
                    <stop offset="0%" stopColor="#50A8FF" />
                    <stop offset="54.677%" stopColor="#3D9FFF" />
                    <stop offset="100%" stopColor="#2D97FF" />
                </linearGradient>
                <linearGradient id="linearGradient-8PicDisk" x1="23.504%" x2="75.648%" y1="8.988%" y2="91.491%">
                    <stop offset="0%" stopColor="#6F6BF1" />
                    <stop offset="100%" stopColor="#559CFD" />
                </linearGradient>
                <linearGradient id="linearGradient-9PicDisk" x1="16.77%" x2="86.769%" y1="10.715%" y2="85.086%">
                    <stop offset="0%" stopColor="#9990FF" />
                    <stop offset="100%" stopColor="#90DBFF" />
                </linearGradient>
                <linearGradient id="linearGradient-10PicDisk" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#C0E2FF" />
                    <stop offset="100%" stopColor="#E0F4FF" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g>
                    <circle cx="118.5" cy="104.5" r="92.5" fill="#C0E2FF" opacity="0.2" />
                    <rect width="114" height="156" x="58" y="26" fill="url(#linearGradient-1PicDisk)" rx="6" />
                    <path
                        fill="#FFF"
                        d="M114 168a2 2 0 010 4H68a2 2 0 010-4h46zm0-8a2 2 0 010 4H68a2 2 0 010-4h46zm-44-14a4 4 0 110 8 4 4 0 010-8zm14 0a4 4 0 110 8 4 4 0 010-8z"
                        opacity="0.4"
                    />
                    <path fill="url(#linearGradient-2PicDisk)" d="M69 34h92a3 3 0 013 3v101H66V37a3 3 0 013-3z" />
                    <circle cx="116" cy="86" r="40" fill="#D8D8D8" fillOpacity="0.35" />
                    <circle cx="116" cy="86" r="37" stroke="url(#linearGradient-3PicDisk)" strokeWidth="6" />
                    <circle cx="116" cy="86" r="16" fill="url(#linearGradient-4PicDisk)" />
                    <circle cx="116" cy="86" r="9" fill="url(#linearGradient-5PicDisk)" />
                    <path
                        fill="url(#linearGradient-6PicDisk)"
                        d="M76 124a4 4 0 110 8 4 4 0 010-8zm0-84a4 4 0 110 8 4 4 0 010-8zm78 0a4 4 0 110 8 4 4 0 010-8z"
                    />
                    <path
                        fill="url(#linearGradient-7PicDisk)"
                        d="M118 26v2a2 2 0 01-4 0v-2h4zm8 0v2a2 2 0 01-4 0v-2h4zm8 0v2a2 2 0 01-4 0v-2h4zm-24 0v2a2 2 0 01-4 0v-2h4zm-8 0v2a2 2 0 01-4 0v-2h4z"
                    />
                    <circle cx="162.5" cy="157.5" r="29.5" fill="url(#linearGradient-8PicDisk)" />
                    <circle cx="162.5" cy="157.5" r="27.5" stroke="url(#linearGradient-9PicDisk)" strokeWidth="4" />
                    <path
                        fill="#FFF"
                        d="M162.892 141c9.448 0 17.108 7.611 17.108 17s-7.66 17-17.108 17c-8.351 0-15.442-5.985-16.856-14.073a2.43 2.43 0 011.99-2.809 2.443 2.443 0 012.826 1.977c1.01 5.771 6.076 10.048 12.04 10.048 6.749 0 12.22-5.437 12.22-12.143s-5.471-12.143-12.22-12.143c-2.698 0-5.251.872-7.333 2.427l3.65 3.628a.72.72 0 01-.507 1.23h-9.754a.72.72 0 01-.72-.72v-9.692a.72.72 0 011.227-.51l2.623 2.605A17.123 17.123 0 01162.892 141z"
                    />
                    <path
                        fill="#C0E2FF"
                        d="M202.536 18.464c.448.449-.49 2.168-1.552 3.536 1.062 1.368 2 3.087 1.552 3.536-.441.44-2.196-.544-3.527-1.559-1.37 1.065-3.095 2.008-3.545 1.559-.45-.45.494-2.176 1.56-3.546-1.016-1.33-2-3.085-1.56-3.526.449-.448 2.168.49 3.536 1.552 1.368-1.062 3.087-2 3.536-1.552z"
                        opacity="0.3"
                    />
                    <circle cx="207" cy="182" r="6" stroke="url(#linearGradient-10PicDisk)" strokeWidth="4" opacity="0.3" />
                    <path
                        fill="#C0E2FF"
                        d="M24 22c1.158 0 2.167 3.352 2.567 6.434C29.647 28.834 33 29.842 33 31c0 1.138-3.453 2.132-6.43 2.546C26.17 36.633 25.16 40 24 40s-2.171-3.367-2.57-6.455C18.453 33.132 15 32.138 15 31c0-1.158 3.352-2.167 6.434-2.567C21.834 25.353 22.842 22 24 22z"
                        opacity="0.3"
                        transform="rotate(45 24 31)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default PicDiskIcon;
