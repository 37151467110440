const createAdFields = () => {
    return [
        {
            name: "enabled",
            type: "checkbox"
        },
        {
            name: "domain"
        },
        {
            name: "user"
        },
        {
            name: "password",
            type: "password"
        },
        {
            name: "enableTrustedDomains",
            type: "checkbox"
        },
        {
            name: "resetAcl",
            type: "checkbox",
            default: false
        },
        {
            name: "resetIdMap",
            type: "checkbox",
            default: false
        },
        {
            name: "force",
            type: "checkbox",
            default: false
        }
    ];
};

export default createAdFields;
