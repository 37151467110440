import { types } from "mobx-state-tree";
import JobType from "./JobType";

const JobsJsonTypes = types.model({
    json: types.maybe(
        types.model({
            total: types.maybe(types.number),
            offset: types.maybe(types.number),
            limit: types.maybe(types.number),
            jobs: types.maybe(types.array(JobType))
        })
    )
});

export default JobsJsonTypes;
