import { types } from "mobx-state-tree";
import AliasType from "./AliasType";

const AliasJsonType = types.model({
    json: types.maybe(
        types.model({
            aliases: types.array(AliasType)
        })
    )
});
export default AliasJsonType;
