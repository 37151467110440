import { types } from "mobx-state-tree";

import Request from "api/Request";
import UiSettings from "api/ui_settings/Types/UiSettings";

const SetUiSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/UiSettings",
    method: "set_ui_settings",
    arguments: types.maybe(UiSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetUiSettings;
