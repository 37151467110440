const createFields = () => {
    return [
        {
            name: "restrictEnabled",
            type: "checkbox"
        },
        {
            name: "ifaceName"
        }
    ];
};

export default createFields;
