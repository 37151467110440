import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    buttonContainer: {
        paddingTop: 0,
        paddingBottom: 0
    },
    downIcon: {
        "& svg path": {
            fill: theme.palette.primary.main
        }
    },
    disabled: {
        "& svg path": {
            fill: theme.palette.grey["300"]
        }
    }
}));
