export const EXTERNAL_URL_SETUP_MY_NEW_EVO =
    "https://studionetworksolutions.zendesk.com/hc/en-us/articles/201120969-How-do-I-setup-my-new-EVO-";
export const EXTERNAL_URL_GETTING_STARTED_GUIDE = "https://support.studionetworksolutions.com/hc/en-us/articles/201069789";
export const EXTERNAL_URL_SCHEDULE_PHONE_ASSISTED_INSTALLATION =
    "https://support.studionetworksolutions.com/hc/en-us/articles/360016648951";
export const EXTERNAL_URL_REGISTER_EVO = "https://www.studionetworksolutions.com/registration/";
export const EXTERNAL_URL_UPS_REQUIREMENTS = "https://studionetworksolutions.zendesk.com/hc/en-us/articles/206126375";
export const EXTERNAL_URL_SNS_FTP = "https://www.snsftp.com/public/";
export const EXTERNAL_URL_KNOWLEDGE_BASE = "https://support.studionetworksolutions.com/hc/en-us";
export const EXTERNAL_URL_EVO_SHARED_STORAGE_SERVER_RECOMMENDATIONS =
    "https://support.studionetworksolutions.com/hc/en-us/sections/200343435-EVO-Shared-Storage-Server";
export const EXTERNAL_URL_SUPPORT = "https://www.studionetworksolutions.com/support/";
export const EXTERNAL_URL_REMOTE_INSTALLATION_ASSISTANCE =
    "https://support.studionetworksolutions.com/hc/en-us/articles/360016648951";
export const EXTERNAL_URL_REMOTE_TRAINING = "https://support.studionetworksolutions.com/hc/en-us/articles/360049048031";
export const EXTERNAL_URL_RECOMMENDATIONS_UPS_SIZING = "https://studionetworksolutions.zendesk.com/hc/en-us/articles/206126375";
export const EXTERNAL_URL_POOLS_HELP_MANUAL = "https://studio-network-solutions-evo-docs.readthedocs-hosted.com/en/latest/";
export const EXTERNAL_URL_CREATE_CERTIFICATE =
    "https://studio-network-solutions-evo-docs.readthedocs-hosted.com/en/latest/system.html#create-certificate";
