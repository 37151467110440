import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import Select from "components/MobxForm/Select";
import createForm from "utils/createForm";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowTextInput from "components/RowTextInput";
import { EMPTY_BCACHE_DRIVE_NAME, getBcacheDriveName, parseDriveId } from "const/bcacheConst";
import { getSnapshot } from "mobx-state-tree";
import useStoreByIp from "hooks/useStoreByIp";
import { useModal } from "hooks/useModal";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const CHANGE_CACHE_DEVICE_DIALOG_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_change_cache_device_dialog`;

const ChangeCacheDeviceDialog = ({ volume, open, onClose, ip }) => {
    const { t } = useTranslation();
    const { bcacheStore } = useStoreByIp({ ip });
    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields(bcacheStore) }),
        isSubmitted: false
    }));

    const unsavedChangesModal = useModal();

    // Callbacks
    const updateForm = () => {
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.form
            .$("bcacheDrive")
            .set("default", getBcacheDriveName(volume ? volume.cache.quad : -1, volume ? volume.cache.drive : -1));
        state.form.reset();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted === true) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const submit = async () => {
        let res;
        const volumeId = volume?.volumeId && getSnapshot(volume?.volumeId);
        if (state.form.$("bcacheDrive").value === EMPTY_BCACHE_DRIVE_NAME) {
            res = await bcacheStore.detachDrive({
                volume: volumeId
            });
        } else {
            const driveId = parseDriveId(state.form.$("bcacheDrive").value);
            res = await bcacheStore.attachDrive({
                volume: volumeId,
                drive: driveId
            });
        }
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    return useObserver(() => (
        <Dialog
            isDataChanged={!state.form.isDefault}
            onEnter={updateForm}
            title={t("disks_and_pools.logical_disks.change_bcache_device.title", {
                volume: `${volume?.volumeId.pool}/${volume?.volumeId.volume}`
            })}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${CHANGE_CACHE_DEVICE_DIALOG_ID_PREFIX}_submit`}
            open={open}
            onSubmit={submit}
            onClose={closeIfNeeded}
            buttons={
                <Button
                    id={`${CHANGE_CACHE_DEVICE_DIALOG_ID_PREFIX}_cancel`}
                    onClick={onClose}
                    variant={"contained"}
                    color="secondary"
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <RowTextInput
                label={t("disks_and_pools.add_disk_dialog.cache_device")}
                control={
                    <Select
                        options={[EMPTY_BCACHE_DRIVE_NAME].concat(
                            Object.values(bcacheStore.drives).map(drive => getBcacheDriveName(drive.quad, drive.drive))
                        )}
                        field={state.form.$("bcacheDrive")}
                        id={`${CHANGE_CACHE_DEVICE_DIALOG_ID_PREFIX}_cache_device`}
                    />
                }
            />

            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default ChangeCacheDeviceDialog;
