import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetShareInfoArguments from "api/share/Types/GetShareInfoArguments";

const GetShareInfo = Request.props({
    path: "/com/studionetworksolutions/evo/Nas",
    method: "get_share_info",
    arguments: types.maybe(GetShareInfoArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetShareInfo;
