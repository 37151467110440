import { types } from "mobx-state-tree";

const ShareType = types
    .model({
        name: types.maybe(types.string),
        label: types.maybe(types.string),
        protocols: types.maybe(types.array(types.string)),
        pool: types.maybe(types.string),
        volume: types.maybe(types.string),
        partition: types.maybe(types.number),
        filesystem: types.maybe(types.string),
        sharebrowser: types.maybe(types.boolean),
        comment: types.maybe(types.string),
        uuid: types.maybe(types.string),
        type: types.maybe(types.string),
        active: types.maybe(types.boolean)
    })
    .named("ShareType");
export default ShareType;
