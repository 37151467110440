import React from "react";
import { Grid, Typography, TablePagination as MuiTablePagination, IconButton } from "@material-ui/core";
import BackIcon from "assets/BackIcon";
import clsx from "clsx";
import ForwardIcon from "assets/ForwardIcon";
import ChevronDownIcon from "assets/ChevronDownIcon";
import { useStyles } from "./TablePagination.styles";

const TABLE_PAGINATION_ID_PREFIX = "table_pagination";

const TablePagination = (props) => {
    const classes = useStyles({ disabled: props.disabled });

    const idPrefix = props.parentId ? `${props.parentId}_${TABLE_PAGINATION_ID_PREFIX}` : TABLE_PAGINATION_ID_PREFIX;

    const changePage = (page) => () => {
        props.onChangePage(page);
    };

    const getButtonsArray = ({ fromPage = 0, toPage = fromPage + 2, before = true, after = true }) => {
        const lastPage = Math.ceil(props.count / props.rowsPerPage) - 1;
        const buttons = [
            <IconButton
                onClick={changePage(props.page - 1)}
                key="btnBefore"
                className={classes.iconButton}
                disabled={props.page === 0 || props.disabled}
                id={`${idPrefix}_last_page`}
            >
                <BackIcon />
            </IconButton>,
        ];
        if (before) {
            buttons.push(
                <Typography onClick={props.disabled ? undefined : changePage(0)} key={"before"} className={classes.pageNumber}>
                    ...
                </Typography>
            );
        }
        for (let page = fromPage; page <= toPage; page++) {
            buttons.push(
                <Typography
                    key={page}
                    onClick={props.disabled ? undefined : changePage(page)}
                    className={clsx(page === props.page && classes.currentPage, classes.pageNumber)}
                >
                    {page + 1}
                </Typography>
            );
        }
        if (after) {
            buttons.push(
                <Typography
                    onClick={props.disabled ? undefined : changePage(lastPage)}
                    key="after"
                    className={classes.pageNumber}
                >
                    ...
                </Typography>
            );
        }
        buttons.push(
            <IconButton
                onClick={changePage(props.page + 1)}
                key="btnAfter"
                className={classes.iconButton}
                disabled={props.page === lastPage || props.disabled}
                id={`${idPrefix}_next_page`}
            >
                <ForwardIcon />
            </IconButton>
        );
        return buttons;
    };
    const renderPageButtons = () => {
        const lastPage = Math.ceil(props.count / props.rowsPerPage) - 1;
        if (lastPage < 3) {
            return getButtonsArray({ from: 0, toPage: lastPage, after: false, before: false });
        } else if (props.page < 2) {
            return getButtonsArray({ before: false });
        } else if (props.page > lastPage - 2) {
            return getButtonsArray({ fromPage: lastPage - 2, toPage: lastPage, after: props.page < lastPage - 2 });
        } else {
            return getButtonsArray({ fromPage: props.page - 1 });
        }
    };
    return (
        <MuiTablePagination
            {...props}
            component={"div"}
            SelectProps={{
                disabled: props.disabled,
                IconComponent: ChevronDownIcon,
                inputProps: {
                    id: `${idPrefix}_count_page`,
                },
            }}
            ActionsComponent={() => (
                <Grid justify={"space-around"} alignContent={"center"} container className={classes.disability}>
                    <Grid item>
                        <Grid className={classes.pageControlsContainer} container>
                            {renderPageButtons()}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    );
};

export default TablePagination;
