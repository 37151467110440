import { types } from "mobx-state-tree";

const EmailSettings = types.model({
    smtpUser: types.string,
    smtpPassword: types.string,
    smtpServer: types.string,
    isSslEnabled: types.boolean,
    emailFrom: types.string,
    emailsTo: types.optional(types.array(types.string), [])
});

export default EmailSettings;
