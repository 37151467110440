import { types } from "mobx-state-tree";

import DashboardLayoutItem from "api/ui_settings/Types/DashboardLayoutItem";

const DashboardLayout = types.model({
    leftColumn: types.array(DashboardLayoutItem),
    rightColumn: types.array(DashboardLayoutItem)
});

export default DashboardLayout;
