import React from "react";
import { ButtonBase, Grid, InputAdornment, CircularProgress } from "@material-ui/core";
import { useObserver, useLocalStore } from "mobx-react";
import TextInput from "components/TextInput";
import SearchIcon from "assets/SearchIcon";
import { useStyles } from "./TableInlineControl.styles";
import Select from "components/Select";
import { useTranslation } from "react-i18next";
import { LOCAL_USERS, EXTERNAL_USERS, LOCAL_GROUPS, SEARCH_DEFAULTS, EXTERNAL_GROUPS } from "const/userSearchVariants";
import CloseIcon from "assets/CloseIcon";
import SaveChangesDialog from "components/SaveChangesDialog";
import useStoreByIp from "hooks/useStoreByIp";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const TABLE_INLINE_CONTROL_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_table_inline_control`;

const TableInlineControl = ({ ip, search, searchString, resetSearchString, setSearchString }) => {
    const { t } = useTranslation();

    const state = useLocalStore(() => ({
        isSearchInProgress: false,
        isUnsavedChangesDialogOpen: false,
        dataForChangingSelect: null,
    }));

    const { aclStore, userStore, shareStore } = useStoreByIp({ ip });

    const classes = useStyles({ status: userStore.currentSyncMode.status });

    const shareName = shareStore.currentShareName || shareStore.currentCheckedShareName || aclStore.currentShareName;

    const resetNameField = () => {
        resetSearchString();
        userStore.updateCurrentSearchRequest("name", SEARCH_DEFAULTS.name);
    };

    const getPlaceholder = () => {
        return userStore.currentSearchRequest.type === LOCAL_USERS || userStore.currentSearchRequest.type === EXTERNAL_USERS
            ? t("users.search_controls.search_line.placeholder.user")
            : t("users.search_controls.search_line.placeholder.group");
    };

    const openUnsavedChangesDialog = () => {
        state.isUnsavedChangesDialogOpen = true;
    };

    const closeUnsavedChangesDialog = () => {
        state.isUnsavedChangesDialogOpen = false;
    };

    const handleOnSave = () => {
        aclStore.modifyUsersOrGroupsAccess({
            shareName,
            permissions: userStore.getUserAccessResult.data.data,
        });

        search();

        if (state.dataForChangingSelect) {
            state.dataForChangingSelect.func(state.dataForChangingSelect.argument);
            state.dataForChangingSelect = null;
        }

        closeUnsavedChangesDialog();
    };

    const handleOnCancel = async () => {
        if (state.dataForChangingSelect) {
            state.dataForChangingSelect.func(state.dataForChangingSelect.argument);
            state.dataForChangingSelect = null;
        }

        search();

        closeUnsavedChangesDialog();
    };

    const keyDownHandler = (e) => {
        if (e.key === "Enter") {
            if (userStore.hasAccessChanges) {
                return openUnsavedChangesDialog();
            }

            search();
        }
    };

    const onSearchClick = () => {
        if (userStore.hasAccessChanges) {
            return openUnsavedChangesDialog();
        }

        search();
    };

    const changeSelect = (e) => {
        userStore.updateCurrentSearchRequest("name", "");
        userStore.updateCurrentSearchRequest("type", e.target.value);
        search();
    };

    const onSelectChange = (e) => {
        if (userStore.hasAccessChanges) {
            state.dataForChangingSelect = { argument: e, func: changeSelect };
            return openUnsavedChangesDialog();
        }

        changeSelect(e);
    };

    const onInputChange = (e) => {
        setSearchString(e.target.value);
    };

    const getInputAdornment = () => {
        return (
            <InputAdornment position="end">
                <Grid container wrap={"nowrap"} alignItems={"center"} justify={"flex-end"} spacing={2}>
                    {userStore.currentSearchRequest.name !== "" && (
                        <Grid item>
                            <ButtonBase
                                className={classes.closeIcon}
                                onClick={resetNameField}
                                id={`${TABLE_INLINE_CONTROL_ID_PREFIX}_reset_name`}
                            >
                                <CloseIcon />
                            </ButtonBase>
                        </Grid>
                    )}
                    <Grid item>
                        <Grid container alignItems={"center"} className={classes.searchIconContainer}>
                            {state.isSearchInProgress ? (
                                <CircularProgress size={22} />
                            ) : (
                                <ButtonBase
                                    className={classes.searchIcon}
                                    onClick={onSearchClick}
                                    id={`${TABLE_INLINE_CONTROL_ID_PREFIX}_search_button`}
                                >
                                    <SearchIcon />
                                </ButtonBase>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </InputAdornment>
        );
    };

    return useObserver(() => (
        <>
            <Grid className={classes.container} container wrap={"nowrap"}>
                <Grid item className={classes.selectContainer}>
                    <Select
                        onChange={onSelectChange}
                        value={userStore.currentSearchRequest.type}
                        options={[
                            {
                                label: t("users.common.local_users"),
                                value: LOCAL_USERS,
                            },
                            {
                                label: t("users.common.external_users"),
                                value: EXTERNAL_USERS,
                            },
                            {
                                label: t("users.common.local_groups"),
                                value: LOCAL_GROUPS,
                            },
                            {
                                label: t("users.common.external_groups"),
                                value: EXTERNAL_GROUPS,
                            },
                        ]}
                        id={`${TABLE_INLINE_CONTROL_ID_PREFIX}_type`}
                    />
                </Grid>
                <Grid item className={classes.inputContainer}>
                    <TextInput
                        autoFocus
                        onKeyDown={keyDownHandler}
                        value={searchString}
                        placeholder={getPlaceholder()}
                        className={classes.input}
                        onChange={onInputChange}
                        InputProps={{
                            endAdornment: getInputAdornment(),
                        }}
                        id={`${TABLE_INLINE_CONTROL_ID_PREFIX}_input`}
                    />
                </Grid>
                <SaveChangesDialog
                    open={state.isUnsavedChangesDialogOpen}
                    onClose={closeUnsavedChangesDialog}
                    onCancel={handleOnCancel}
                    onSave={handleOnSave}
                    withoutIconClose
                    withoutDividers
                />
            </Grid>
        </>
    ));
};

export default TableInlineControl;
