import React from "react";

const UsersSearchPic = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110">
            <defs>
                <linearGradient id="5t3y10htaa" x1="3.534%" x2="72.716%" y1="-15.973%" y2="100%">
                    <stop offset="0%" stopColor="#FFF"></stop>
                    <stop offset="100%" stopColor="#DFE4EF"></stop>
                </linearGradient>
                <linearGradient id="fdxhkc2x6b" x1="-31.054%" x2="89.626%" y1="35.832%" y2="60.738%">
                    <stop offset="0%" stopColor="#D4E2F1"></stop>
                    <stop offset="100%" stopColor="#A9B7D4"></stop>
                </linearGradient>
                <linearGradient id="kculldspbc" x1="7.128%" x2="70.959%" y1="-15.973%" y2="100%">
                    <stop offset="0%" stopColor="#D4E2F1"></stop>
                    <stop offset="100%" stopColor="#A9B7D4"></stop>
                </linearGradient>
                <linearGradient id="qsu86p3vwd" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#DCE5F0"></stop>
                    <stop offset="100%" stopColor="#C5D0E7"></stop>
                </linearGradient>
                <linearGradient id="20ivmw9cxe" x1="11.531%" x2="70.277%" y1="16.288%" y2="87.835%">
                    <stop offset="0%" stopColor="#FFF"></stop>
                    <stop offset="100%" stopColor="#CED9F1"></stop>
                </linearGradient>
                <linearGradient id="7buql418lf" x1="15.804%" x2="73.799%" y1="17.772%" y2="89.081%">
                    <stop offset="0%" stopColor="#D4E2F1"></stop>
                    <stop offset="100%" stopColor="#A9B7D4"></stop>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <rect width="53" height="70" x="38" y="23" fill="url(#5t3y10htaa)" rx="4"></rect>
                <path
                    fill="url(#fdxhkc2x6b)"
                    d="M75.5 82a2.5 2.5 0 010 5h-28a2.5 2.5 0 010-5h28zm8 0a2.5 2.5 0 010 5 2.5 2.5 0 010-5zm0-7a2.5 2.5 0 010 5h-8a2.5 2.5 0 010-5h8zm0-7a2.5 2.5 0 010 5h-36a2.5 2.5 0 010-5h36z"
                    opacity="0.6"
                ></path>
                <path
                    fill="url(#kculldspbc)"
                    d="M61.067 47.135A7.932 7.932 0 0169 55.067V61a2 2 0 01-2 2H47a2 2 0 01-2-2v-5.933a7.933 7.933 0 017.933-7.932h8.134zM57.316 30c4.36 0 7.895 3.552 7.895 7.933 0 4.38-3.535 7.932-7.895 7.932-4.36 0-7.895-3.551-7.895-7.932S52.956 30 57.316 30z"
                ></path>
                <path
                    fill="url(#qsu86p3vwd)"
                    fillRule="nonzero"
                    d="M87 22H42a5 5 0 00-5 5v62a5 5 0 005 5h45a5 5 0 005-5V27a5 5 0 00-5-5zm-45 2h45a3 3 0 013 3v62a3 3 0 01-3 3H42a3 3 0 01-3-3V27a3 3 0 013-3z"
                ></path>
                <circle cx="51.625" cy="44.2" r="35.2" fill="url(#20ivmw9cxe)" opacity="0.6"></circle>
                <path
                    fill="#FFF"
                    d="M63.617 12.095A35.162 35.162 0 0051.625 10c-19.44 0-35.2 15.76-35.2 35.2 0 13.963 8.13 26.028 19.916 31.717C41.45 64.45 58.5 25.4 63.617 12.095z"
                    opacity="0.15"
                ></path>
                <path
                    fill="url(#7buql418lf)"
                    d="M51.625 9c19.44 0 35.2 15.76 35.2 35.2 0 19.44-15.76 35.2-35.2 35.2a35.028 35.028 0 01-18.985-5.554c-.154.28-.342.544-.563.79l-.166.175-15.4 15.4a4.4 4.4 0 01-6.388-6.047l.166-.175 15.4-15.4c.095-.096.195-.186.297-.271-5.928-6.3-9.561-14.785-9.561-24.118 0-19.44 15.76-35.2 35.2-35.2zm0 4.4c-17.01 0-30.8 13.79-30.8 30.8S34.615 75 51.625 75s30.8-13.79 30.8-30.8-13.79-30.8-30.8-30.8z"
                ></path>
            </g>
        </svg>
    );
};

export default UsersSearchPic;
