import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

const getColor = (theme, value) => {
    if (value >= 90) return theme.palette.error.main;
    if (value >= 80) return theme.palette.warning.main;
    return theme.palette.primary.main;
};

export const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(1, 5, 1, 4),
        height: "100%",
        width: "100%",
        margin: 0
    },
    circularContainer: {
        display: "flex"
    },
    circularBase: {
        alignSelf: "center",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: theme.spacing(12),
        height: theme.spacing(12),
        "& .MuiCircularProgress-root": {
            position: "absolute"
        }
    },
    progressText: {
        fontSize: 14,
        fontWeight: "bold"
    },
    storageText: ({ value }) => ({
        opacity: 1,
        fontWeight: "bold",
        color: getColor(theme, value)
    }),
    circularProgressBottom: {
        color: hexToRgba(theme.palette.primary.main, 0.2)
    },
    circularProgress: ({ value }) => ({
        color: getColor(theme, value)
    }),
    title: {
        fontSize: 14,
        fontWeight: 500,
        opacity: 1
    }
}));
