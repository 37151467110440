import React from "react";
import { useObserver } from "mobx-react";
import { useStyles } from "./ActivationKeysCard.styles";
import { Paper, Divider, Grid, Typography } from "@material-ui/core";
import CardHeader from "components/CardHeader";
import { useTranslation } from "react-i18next";

import NotReadyOverlay from "components/NotReadyOverlay"; // remove when the component is ready
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import MoreMenu from "components/MoreMenu";
import { ACTIVATION_KEYS_CARD } from "const/widgetNameConst";
import { HARDWARE_ACTIVATION_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const ACTIVATION_KEYS_CARD_ID_PREFIX = `${HARDWARE_ACTIVATION_PAGE_ID_PREFIX}_activation_keys_card`;

const ActivationKeysCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return useObserver(() => (
        <NotReadyOverlay>
            <Paper className={classes.container}>
                <CardHeader
                    title={t("hardware_activation.activation_keys_card.title")}
                    control={
                        <MoreMenu id={`${ACTIVATION_KEYS_CARD_ID_PREFIX}_more_menu`}>
                            <HomeCardActionMenuItem widget={ACTIVATION_KEYS_CARD} />
                        </MoreMenu>
                    }
                />
                <Divider />
                <Grid container alignItems={"center"} justify={"center"} className={classes.contentContainer}>
                    <Grid item>
                        <Typography className={classes.noKeysText}>
                            {t("hardware_activation.activation_keys_card.no_keys_found")}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </NotReadyOverlay>
    ));
};

export default ActivationKeysCard;
