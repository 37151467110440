import React, { useEffect } from "react";
import { Table, TableCell, TableHead, TableRow, TableBody, Box, Grid, Typography } from "@material-ui/core";
import TableCellWithIcon from "components/TableCellWithIcon";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./EditUsersGroupsModal.styles";
import ExternalGroupIcon from "assets/ExternalGroupIcon";
import LocalGroupIcon from "assets/LocalGroupIcon";
import LocalUserIcon from "assets/LocalUserIcon";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import Dialog from "components/Dialog";
import { USERS_LIST_GROUP_MEMBERS_NAME, USERS_LIST_GROUP_MEMBERS_TYPE_LABEL } from "const/sortColumnConst";
import TableSortLabel from "components/TableSortLabel";
import createFields from "./createFields";
import createForm from "utils/createForm";
import SearchLine from "./components/SearchLine/SearchLine";
import clsx from "clsx";
import TrashIcon from "assets/TrashIcon";
import AnimatedSubmitButton from "components/AnimatedSubmitButton/AnimatedSubmitButton";
import { useStore } from "hooks/useStore";

const EDIT_USERS_GROUPS_MODAL_ID_PREFIX = "edit_users_groups_modal";

const EditLocalGroupMembersModal = ({ open, onClose, parentId }) => {
    const classes = useStyles();

    const idPrefix = parentId ? `${parentId}_${EDIT_USERS_GROUPS_MODAL_ID_PREFIX}` : EDIT_USERS_GROUPS_MODAL_ID_PREFIX;

    const { t } = useTranslation();
    const {
        store: { userStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isSearchInProgress: false,
        actionInProgress: false,
        form: createForm({
            fields: createFields(),
        }),
        selectedGroups: [],
    }));

    const onEnter = async () => {
        state.form.reset();
        state.isSearchInProgress = true;
        await userStore.listUserMembership();
        state.isSearchInProgress = false;
    };

    useEffect(() => {
        state.selectedGroups = userStore.sortedUserMembership.map((group) => {
            return { name: group.name, domain: group.domain };
        });
    }, [userStore.sortedUserMembership]);

    const selectGroup = async (name, domain) => {
        if (isGroupAlreadySelected(name, domain)) {
            removeGroup(name)();
        } else {
            state.actionInProgress = true;
            const res = await userStore.addGroupMembers(name, [
                {
                    name:
                        (userStore.currentEntity && userStore.currentEntity?.id.name) ||
                        userStore.currentEntitySingleBar?.id.name,
                    domain:
                        (userStore.currentEntity && userStore.currentEntity?.id.domain) ||
                        userStore.currentEntitySingleBar?.id.domain,
                },
            ]);

            res && (await userStore.listUserMembership());

            state.actionInProgress = false;
        }
    };

    const onAdd = async (groups) => {
        state.actionInProgress = true;
        const trustedDomains = await userStore.getTustedDomains();
        const localDomain = trustedDomains.data[0];
        const promiseSelectGroup = [];
        while (groups.length) {
            promiseSelectGroup.push(selectGroup(groups.pop(), localDomain));
        }

        await Promise.all(promiseSelectGroup);
        onEnter();
        state.actionInProgress = false;
    };

    const isGroupAlreadySelected = (name, domain) => {
        return userStore.sortedUserMembership.some((group) => group.name === name && group.domain === domain);
    };

    const removeGroup = (name) => async () => {
        state.actionInProgress = true;
        const res = await userStore.deleteGroupMembers(name, [
            {
                name: (userStore.currentEntity && userStore.currentEntity?.id.name) || userStore.currentEntitySingleBar?.id.name,
                domain:
                    (userStore.currentEntity && userStore.currentEntity?.id.domain) ||
                    userStore.currentEntitySingleBar?.id.domain,
            },
        ]);
        res && (await userStore.listUserMembership());
        state.actionInProgress = false;
    };

    const getType = (isExternal, isGroup) => {
        const getIcon = () => {
            if (isGroup) {
                return isExternal ? <ExternalGroupIcon /> : <LocalGroupIcon />;
            } else {
                return <LocalUserIcon />;
            }
        };
        const getLabel = () => {
            if (isGroup) {
                return isExternal ? t("users.common.external_group") : t("users.common.local_group");
            } else {
                return isExternal ? t("users.common.external_user") : t("users.common.local_user");
            }
        };

        return {
            typeIcon: getIcon(),
            typeLabel: getLabel(),
        };
    };

    const getName = () => {
        if (userStore.currentEntity) {
            return userStore.currentEntity?.isExternal
                ? `${userStore.currentEntity?.id.name}@${userStore.currentEntity?.id.domain}`
                : userStore.currentEntity.id.name;
        } else if (userStore.currentEntitySingleBar) {
            return userStore.currentEntitySingleBar?.isExternal
                ? `${userStore.currentEntitySingleBar?.id.name}@${userStore.currentEntitySingleBar?.id.domain}`
                : userStore.currentEntitySingleBar.id.name;
        }
    };

    const onKeyDown = (e) => {
        e.key === "Escape" && onClose();
    };

    const changeSorting = (column) => () => userStore.userMembershipChangeSorting(column);

    return useObserver(() => (
        <Dialog
            maxWidth={"md"}
            onKeyDown={onKeyDown}
            withoutPaddings
            open={open}
            onClose={onClose}
            onEnter={onEnter}
            title={t("users.side_menu.user_membership_modal.title", { name: getName() || "" })}
            submitBtn={
                <AnimatedSubmitButton
                    variant={"contained"}
                    color={"primary"}
                    submit={onClose}
                    inProgress={state.actionInProgress}
                    label={t("common.button.close")}
                    id={`${idPrefix}_close`}
                />
            }
            additionalTitle={
                <Box pt={4} pb={4} pr={6} pl={6}>
                    <Grid container wrap={"nowrap"} alignItems={"center"} justify={"space-between"}>
                        <Grid item xs={4}>
                            <Typography>{t("users.side_menu.user_membership_modal.input.label")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <SearchLine
                                form={state.form}
                                isOptionChecked={isGroupAlreadySelected}
                                onSelect={selectGroup}
                                onAdd={onAdd}
                                parentId={idPrefix}
                            />
                        </Grid>
                    </Grid>
                </Box>
            }
        >
            {userStore.sortedUserMembership.length > 0 ? (
                <Grid container className={classes.tableBody}>
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={userStore.userMembershipOrderBy === USERS_LIST_GROUP_MEMBERS_NAME}
                                        onClick={changeSorting(USERS_LIST_GROUP_MEMBERS_NAME)}
                                        direction={userStore.userMembershipOrder}
                                    >
                                        {t("users.search_results.table.column.name")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={userStore.userMembershipOrderBy === USERS_LIST_GROUP_MEMBERS_TYPE_LABEL}
                                        onClick={changeSorting(USERS_LIST_GROUP_MEMBERS_TYPE_LABEL)}
                                        direction={userStore.userMembershipOrder}
                                    >
                                        {t("users.search_results.table.column.type")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userStore.sortedUserMembership.map((group) => {
                                const { typeIcon, typeLabel } = getType(group.isExternal, group.isGroup);
                                return (
                                    <TableRow key={`${group.domainName}`}>
                                        <TableCell className={classes.tableCell}>{group.id.name}</TableCell>
                                        <TableCellWithIcon
                                            className={clsx(classes.tableCell, classes.tableCellWithIcon)}
                                            align="left"
                                            icon={typeIcon}
                                        >
                                            {typeLabel}
                                        </TableCellWithIcon>
                                        <TableCell
                                            className={clsx(classes.tableCellWithIcon, classes.tableDelete)}
                                            onClick={removeGroup(group.name)}
                                        >
                                            <TrashIcon />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            ) : (
                <EmptyCardPlaceholder
                    hasStaticHeight
                    inProgress={state.isSearchInProgress}
                    message={
                        !userStore.userMembershipError
                            ? t("users.side_menu.user_membership_modal.placeholder")
                            : t("users.side_menu.user_membership_modal.error")
                    }
                />
            )}
        </Dialog>
    ));
};

export default EditLocalGroupMembersModal;
