import React from "react";

const PoolPicture = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" {...props}>
            <defs>
                <linearGradient id="b55d5876-dc5c-467f-851f-c4383d90ca87" x1="13.625%" x2="78.828%" y1="5.187%" y2="100%">
                    <stop offset="0%" stopColor="#21B8F7"></stop>
                    <stop offset="45.323%" stopColor="#5B95FF"></stop>
                    <stop offset="100%" stopColor="#8755FA"></stop>
                </linearGradient>
                <linearGradient id="441e1b6e-34ce-4d5d-ae5a-f248e4b75674" x1="31.394%" x2="83.126%" y1="0%" y2="98.894%">
                    <stop offset="0%" stopColor="#6DCEFF"></stop>
                    <stop offset="100%" stopColor="#B782FF"></stop>
                </linearGradient>
                <linearGradient id="de1f507e-c085-4aee-9f78-d8fcabde34fb" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF"></stop>
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0"></stop>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(12 4)">
                    <path
                        fill="url(#b55d5876-dc5c-467f-851f-c4383d90ca87)"
                        d="M66.832 0H20.168a7.997 7.997 0 00-7.176 4.465L1.028 28.751A10.01 10.01 0 000 33.169V96c0 1.657.672 3.157 1.757 4.243A5.985 5.985 0 006 102h75a5.985 5.985 0 004.243-1.757A5.985 5.985 0 0087 96V33.17a9.993 9.993 0 00-1.03-4.42L74.01 4.466A8 8 0 0066.831 0z"
                    ></path>
                    <path
                        fill="url(#441e1b6e-34ce-4d5d-ae5a-f248e4b75674)"
                        fillRule="nonzero"
                        d="M66.832 0a7.997 7.997 0 017.027 4.177l.15.288L85.97 28.751a9.969 9.969 0 01.963 3.275l.002.024c.032.233.052.47.06.71L87 33h-.004l.004.17V96a5.985 5.985 0 01-1.757 4.243 5.978 5.978 0 01-3.952 1.75L81 102H6a5.985 5.985 0 01-4.243-1.757 5.98 5.98 0 01-1.75-3.953L0 96V33.17L.003 33H0c0-.322.022-.64.064-.95l.002-.024c.116-1.01.386-1.998.8-2.929l.163-.346L12.991 4.465A8 8 0 0119.842.007L20.168 0h46.664zM80 30H7a3 3 0 00-2.972 2.589 5.12 5.12 0 00-.02.274L4 33.17V96c0 .541.21 1.039.586 1.414.333.334.764.537 1.235.578L6 98h75c.541 0 1.039-.21 1.414-.586.334-.333.537-.764.578-1.235L83 96V33.17c0-.195-.01-.388-.028-.58a3.001 3.001 0 00-2.796-2.585L80 30zM66.832 4H20.168c-.752 0-1.481.21-2.113.604a3.99 3.99 0 00-1.358 1.408l-.118.22-9.738 19.771L7 26h73l.158.003-9.737-19.77a4.006 4.006 0 00-3.339-2.225L66.832 4z"
                    ></path>
                    <circle
                        cx="21"
                        cy="73"
                        r="2.5"
                        fill="#FFF"
                        fillOpacity="0.56"
                        stroke="url(#de1f507e-c085-4aee-9f78-d8fcabde34fb)"
                        opacity="0.4"
                    ></circle>
                    <circle
                        cx="11"
                        cy="38"
                        r="2.5"
                        fill="#FFF"
                        fillOpacity="0.56"
                        stroke="url(#de1f507e-c085-4aee-9f78-d8fcabde34fb)"
                    ></circle>
                    <circle
                        cx="21"
                        cy="38"
                        r="2.5"
                        fill="#FFF"
                        fillOpacity="0.56"
                        stroke="url(#de1f507e-c085-4aee-9f78-d8fcabde34fb)"
                        opacity="0.4"
                    ></circle>
                    <circle
                        cx="11"
                        cy="55"
                        r="2.5"
                        fill="#FFF"
                        fillOpacity="0.56"
                        stroke="url(#de1f507e-c085-4aee-9f78-d8fcabde34fb)"
                    ></circle>
                    <circle
                        cx="11"
                        cy="73"
                        r="2.5"
                        fill="#FFF"
                        fillOpacity="0.56"
                        stroke="url(#de1f507e-c085-4aee-9f78-d8fcabde34fb)"
                    ></circle>
                    <circle
                        cx="11"
                        cy="90"
                        r="2.5"
                        fill="#FFF"
                        fillOpacity="0.56"
                        stroke="url(#de1f507e-c085-4aee-9f78-d8fcabde34fb)"
                    ></circle>
                    <path fill="#FFF" d="M4 45h79v2H4zm0 18h79v2H4zm0 18h79v2H4z" opacity="0.25"></path>
                </g>
                <rect width="24" height="4" x="65" y="39" fill="#FFF" fillOpacity="0.56" opacity="0.6" rx="2"></rect>
                <rect width="44" height="4" x="45" y="57" fill="#FFF" fillOpacity="0.56" opacity="0.5" rx="2"></rect>
                <rect width="32" height="4" x="57" y="75" fill="#FFF" fillOpacity="0.56" opacity="0.4" rx="2"></rect>
                <rect width="38" height="4" x="51" y="93" fill="#FFF" fillOpacity="0.56" opacity="0.3" rx="2"></rect>
            </g>
        </svg>
    );
};

export default PoolPicture;
