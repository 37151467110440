import { types } from "mobx-state-tree";

import Request from "api/Request";
import RunShareTypesStepArguments from "api/upgrade/migration/Types/RunShareTypesStepArguments";

const RunShareTypesStep = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Migration",
    method: "run_share_types_step",
    arguments: types.maybe(RunShareTypesStepArguments),
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default RunShareTypesStep;
