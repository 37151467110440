import React from "react";
import { observer, useLocalStore } from "mobx-react";
import DataCard from "components/DataCard";
import { ButtonBase, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "assets/ChevronRightIcon";
import PlusIcon from "assets/PlusIcon";
import TableRowControlButton from "components/TableRowControlButton";
import TableSortLabel from "components/TableSortLabel";
import {
    SLINGSHOT_PRESET_NAME,
    SLINGSHOT_PRESET_BUILTIN,
    SLINGSHOT_PRESET_VIDEO_CODEC,
    SLINGSHOT_PRESET_CONTAINER,
} from "const/sortColumnConst";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { SLINGSHOT_PRESET_CARD } from "const/widgetNameConst";
import PresetsDrawer from "../PresetsDrawer";
import CreateEditPresetDialog from "../CreateEditPresetDialog";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { VCODEC_ARRAY, CONTAINER_ARRAY, VCODEC_ARRAY_EDIT_PROXY } from "const/slingshotPresetConst";
import { useStore } from "hooks/useStore";
import { useLauncher } from "hooks/useLauncher";

const PRESETS_ID_PREFIX = `presets`;

const Presets = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${PRESETS_ID_PREFIX}` : PRESETS_ID_PREFIX;
    const { t } = useTranslation();

    const {
        store: { slingshotSettingsStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isCreatePresetDialogOpen: false,
        inProgress: false,
        beginProgress() {
            this.inProgress = true;
        },
        endProgress() {
            this.inProgress = false;
        },
        openCreatePresetDialog() {
            this.isCreatePresetDialogOpen = true;
        },
        closeCreatePresetDialog() {
            this.isCreatePresetDialogOpen = false;
        },
        get currentPreset() {
            return slingshotSettingsStore.currentPreset || slingshotSettingsStore.configAutotaggingPreset;
        },
    }));

    const { isError } = useLauncher([state.beginProgress, slingshotSettingsStore.fetchPresets, state.endProgress], {
        ignoreErrors: false,
        dependencies: [slingshotSettingsStore.isErrorConnectedDBus],
    });

    const changeSorting = (column) => () => slingshotSettingsStore.changeSortingPresets(column);

    const onRowClick = (presetName) => () => {
        slingshotSettingsStore.setCurrentPresetName(slingshotSettingsStore.currenPresetName === presetName ? null : presetName);
    };

    const getVcodecLabel = (vcodecName, presetId) => {
        const CUR_VCODEC_ARRAY = presetId === 11 ? VCODEC_ARRAY_EDIT_PROXY : VCODEC_ARRAY;

        if (vcodecName === CUR_VCODEC_ARRAY[1].label) return vcodecName;
        return CUR_VCODEC_ARRAY.find((vcodec) => vcodec.value === vcodecName)?.label;
    };

    const getContainerLabel = (containerName) => {
        if (containerName === CONTAINER_ARRAY[0].label) return containerName;
        return CONTAINER_ARRAY.find((container) => container.value === containerName)?.label;
    };

    return (
        <DataCard
            title={t("slingshot_page.tabs.settings.presets.title")}
            headerDivider
            autoHeight
            emptyPadding
            headerControl={[
                <MoreMenu key={`data_card-header_control-control-1`}>
                    <HomeCardActionMenuItem widget={SLINGSHOT_PRESET_CARD} homeCardActionMenuItemId={`${idPrefix}_add_to_home`} />
                </MoreMenu>,
                !isError && (
                    <ButtonBase
                        onClick={state.openCreatePresetDialog}
                        key={`data_card-header_control-control-2`}
                        id={`${idPrefix}_open_create_preset_dialog`}
                    >
                        <PlusIcon />
                    </ButtonBase>
                ),
            ]}
        >
            <PresetsDrawer parentId={idPrefix} />
            <CreateEditPresetDialog
                open={state.isCreatePresetDialogOpen}
                onClose={state.closeCreatePresetDialog}
                isCreate
                parentId={idPrefix}
            />
            {slingshotSettingsStore.sortedPresets ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(SLINGSHOT_PRESET_NAME)}
                                    direction={slingshotSettingsStore.orderPreset}
                                    active={slingshotSettingsStore.orderByPreset === SLINGSHOT_PRESET_NAME}
                                    id={`${idPrefix}_sort_name`}
                                >
                                    {t("slingshot_page.tabs.settings.presets.name")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(SLINGSHOT_PRESET_BUILTIN)}
                                    direction={slingshotSettingsStore.orderPreset}
                                    active={slingshotSettingsStore.orderByPreset === SLINGSHOT_PRESET_BUILTIN}
                                    id={`${idPrefix}_sort_built_in`}
                                >
                                    {t("slingshot_page.tabs.settings.presets.built_in")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(SLINGSHOT_PRESET_VIDEO_CODEC)}
                                    direction={slingshotSettingsStore.orderPreset}
                                    active={slingshotSettingsStore.orderByPreset === SLINGSHOT_PRESET_VIDEO_CODEC}
                                    id={`${idPrefix}_sort_video_codec`}
                                >
                                    {t("slingshot_page.tabs.settings.presets.video_codec")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={changeSorting(SLINGSHOT_PRESET_CONTAINER)}
                                    direction={slingshotSettingsStore.orderPreset}
                                    active={slingshotSettingsStore.orderByPreset === SLINGSHOT_PRESET_CONTAINER}
                                    id={`${idPrefix}_sort_container`}
                                >
                                    {t("slingshot_page.tabs.settings.presets.container")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {slingshotSettingsStore.sortedPresets.map((preset) => {
                            return (
                                <TableRow
                                    selected={slingshotSettingsStore.currentPresetName === preset.name}
                                    key={preset.id}
                                    onClick={onRowClick(preset.name)}
                                >
                                    <TableCell width={"30%"}>{preset.name}</TableCell>
                                    <TableCell width={"30%"}>
                                        {preset.builtin ? t("common.button.yes") : t("common.button.no")}
                                    </TableCell>

                                    <TableCell width={"20%"}>{getVcodecLabel(preset.vcodec, preset.id)}</TableCell>
                                    <TableCell width={"20%"}>{getContainerLabel(preset.container)}</TableCell>
                                    <TableCell align={"right"}>
                                        <TableRowControlButton
                                            icon={<ChevronRightIcon />}
                                            id={`${idPrefix}_open_drawer_${preset.name}`}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <EmptyCardPlaceholder
                    inProgress={state.inProgress}
                    message={t("slingshot_page.tabs.settings.presets.placeholder")}
                />
            )}
        </DataCard>
    );
};

export default observer(Presets);
