import React, { useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./QuadsStatus.style";
import QuadItem from "./QuadItem";
import ExpansionDotsStatusItem from "./ExpansionDotsStatusItem";
import { observer, useObserver } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";

const QuadsStatus = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { driveStore },
    } = useContext(Context);

    useEffect(() => {
        driveStore.fetchQuadsStatus();
    }, []);

    const RenderEnclosures = observer(() => {
        if (driveStore.enclosures) {
            // EVO Prodigy || EVO 8Bay without expansions || EVO 16Bay
            if (driveStore.enclosuresLength === 1) {
                return enclosureContainer(
                    driveStore.evoBase.quadStatus.map((quad) => (
                        <Grid item key={`EVO Base${quad.quad}`}>
                            <QuadItem
                                large={driveStore.evoBaseDrivesLength < 9}
                                title={t("common.quad_name", { name: quad.quad })}
                                drives={quad.driveStatus}
                            />
                        </Grid>
                    ))
                );
            }

            //EVO 8Bay + 1 expansion || EVO 16Bay + 1 expansion
            if (driveStore.enclosuresLength === 2) {
                return driveStore.enclosures.map((enclosure) =>
                    enclosureContainer(
                        enclosure.quadStatus.map((quad) => (
                            <Grid item key={`${enclosure.enclosure}${quad.quad}`}>
                                <QuadItem large title={t("common.quad_name", { name: quad.quad })} drives={quad.driveStatus} />
                            </Grid>
                        ))
                    )
                );
            }

            //EVO 16Bay + 2 expansions
            if (driveStore.enclosuresLength > 2) {
                return driveStore.expansionsStatuses.map((expansion) =>
                    enclosureContainer(
                        <Grid item>
                            <ExpansionDotsStatusItem title={expansion.enclosureName} statuses={expansion.enclosureStatuses} />
                        </Grid>
                    )
                );
            }
        }
        return null;
    });

    const enclosureContainer = (children) => {
        return (
            <Grid
                className={classes.enclosureContainer}
                key={`${Date.now()}${Math.random()}`}
                container
                item
                alignItems={"center"}
                justify={"flex-end"}
                wrap={"nowrap"}
                spacing={2}
            >
                {children}
            </Grid>
        );
    };
    return useObserver(() => (
        <Grid container alignItems={"center"} justify={"center"} className={classes.quadsContainer} wrap={"nowrap"} spacing={6}>
            <RenderEnclosures />
        </Grid>
    ));
};

export default QuadsStatus;
