import React from "react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import createForm from "utils/createForm";
import createFields from "./createFields";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import Switch from "components/MobxForm/Switch";
import { Button } from "@material-ui/core";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const SETUP_AGENT_CONFIGURATION_DIALOG_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_setup_agent_configuration_dialog`;

const SetupAgentConfigurationDialog = ({ open, onClose }) => {
    const { t } = useTranslation();
    const {
        store: { alertsStore }
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields()
        }),
        inProgress: false,
        isSubmitted: false
    }));

    const unsavedChangesModal = useModal();

    const updateForm = () => {
        state.form.$("evoVersionAvailable").set("default", alertsStore.snmpAgentSettings?.evoVersionAvailable);
        state.form.$("localTimeAvailable").set("default", alertsStore.snmpAgentSettings?.localTimeAvailable);
        state.form.$("systemUptimeAvailable").set("default", alertsStore.snmpAgentSettings?.systemUptimeAvailable);
        state.form.$("cpuUsageAvailable").set("default", alertsStore.snmpAgentSettings?.cpuUsageAvailable);
        state.form.$("upsStatusAvailable").set("default", alertsStore.snmpAgentSettings?.upsStatusAvailable);
        state.form.$("blocksIoAvailable").set("default", alertsStore.snmpAgentSettings?.blocksIoAvailable);
        state.form.$("ioStatAvailable").set("default", alertsStore.snmpAgentSettings?.ioStatAvailable);
        state.form.$("sessionsAvailable").set("default", alertsStore.snmpAgentSettings?.sessionsAvailable);
        state.form.$("cachedVolumeAvailable").set("default", alertsStore.snmpAgentSettings?.cachedVolumeAvailable);
        state.form.$("detailedStatAvailable").set("default", alertsStore.snmpAgentSettings?.detailedStatAvailable);
        state.form.reset();
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };

    const onSubmit = async () => {
        state.inProgress = true;
        const res = await alertsStore.setSnmpAgentSettings(state.form.values());
        state.inProgress = false;
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            onClose={closeIfNeeded}
            open={open}
            title={t("alerts.agent_configuration_dialog.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${SETUP_AGENT_CONFIGURATION_DIALOG_ID_PREFIX}_save`}
            inProgress={state.inProgress}
            onSubmit={onSubmit}
            onEnter={updateForm}
            buttons={
                <Button
                    onClick={onClose}
                    variant={"contained"}
                    color="secondary"
                    id={`${SETUP_AGENT_CONFIGURATION_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <RowControlLabelSwitch
                control={
                    <Switch
                        field={state.form.$("evoVersionAvailable")}
                        id={`${SETUP_AGENT_CONFIGURATION_DIALOG_ID_PREFIX}_evo_version`}
                    />
                }
                label={t("alerts.agent_configuration_card.evo_version")}
            />
            <RowControlLabelSwitch
                control={
                    <Switch
                        field={state.form.$("localTimeAvailable")}
                        id={`${SETUP_AGENT_CONFIGURATION_DIALOG_ID_PREFIX}_time`}
                    />
                }
                label={t("alerts.agent_configuration_card.time")}
            />
            <RowControlLabelSwitch
                control={
                    <Switch
                        field={state.form.$("systemUptimeAvailable")}
                        id={`${SETUP_AGENT_CONFIGURATION_DIALOG_ID_PREFIX}_system_uptime`}
                    />
                }
                label={t("alerts.agent_configuration_card.system_uptime")}
            />
            <RowControlLabelSwitch
                control={
                    <Switch
                        field={state.form.$("cpuUsageAvailable")}
                        id={`${SETUP_AGENT_CONFIGURATION_DIALOG_ID_PREFIX}_usage_statistics`}
                    />
                }
                label={t("alerts.agent_configuration_card.usage_statistics")}
            />
            <RowControlLabelSwitch
                control={
                    <Switch
                        field={state.form.$("upsStatusAvailable")}
                        id={`${SETUP_AGENT_CONFIGURATION_DIALOG_ID_PREFIX}_ups_status`}
                    />
                }
                label={t("alerts.agent_configuration_card.ups_status")}
            />
            <RowControlLabelSwitch
                control={
                    <Switch
                        field={state.form.$("blocksIoAvailable")}
                        id={`${SETUP_AGENT_CONFIGURATION_DIALOG_ID_PREFIX}_overall_statistics`}
                    />
                }
                label={t("alerts.agent_configuration_card.overall_statistics")}
            />
            <RowControlLabelSwitch
                control={
                    <Switch
                        field={state.form.$("ioStatAvailable")}
                        id={`${SETUP_AGENT_CONFIGURATION_DIALOG_ID_PREFIX}_disks_statistics`}
                    />
                }
                label={t("alerts.agent_configuration_card.disks_statistics")}
            />
            <RowControlLabelSwitch
                control={
                    <Switch
                        field={state.form.$("sessionsAvailable")}
                        id={`${SETUP_AGENT_CONFIGURATION_DIALOG_ID_PREFIX}_nas_sessions`}
                    />
                }
                label={t("alerts.agent_configuration_card.nas_sessions")}
            />
            <RowControlLabelSwitch
                control={
                    <Switch
                        field={state.form.$("cachedVolumeAvailable")}
                        id={`${SETUP_AGENT_CONFIGURATION_DIALOG_ID_PREFIX}_cached_disk_statistics`}
                    />
                }
                label={t("alerts.agent_configuration_card.cached_disk_statistics")}
            />
            <RowControlLabelSwitch
                control={
                    <Switch
                        field={state.form.$("detailedStatAvailable")}
                        id={`${SETUP_AGENT_CONFIGURATION_DIALOG_ID_PREFIX}_per_logical_disks_statistics`}
                    />
                }
                label={t("alerts.agent_configuration_card.per_logical_disks_statistics")}
            />
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};
export default SetupAgentConfigurationDialog;
