import * as React from "react";

const GraphIcon = props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-900.000000, -256.000000)" fill="#1E90FF" fillRule="nonzero">
                <g transform="translate(900.000000, 256.000000)">
                    <path d="M9,9 C10.1045695,9 11,9.8954305 11,11 L11,19 C11,20.1045695 10.1045695,21 9,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,11 C3,9.8954305 3.8954305,9 5,9 L9,9 Z M19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L15,21 C13.8954305,21 13,20.1045695 13,19 L13,5 C13,3.8954305 13.8954305,3 15,3 L19,3 Z M9,11 L5,11 L5,19 L9,19 L9,11 Z M19,5 L15,5 L15,19 L19,19 L19,5 Z"></path>
                </g>
            </g>
        </g>
    </svg>
);

export default GraphIcon;
