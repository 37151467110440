import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    statusCell: {
        "&&": {
            opacity: 1,
            paddingLeft: theme.spacing(8),
            width: theme.spacing(14)
        }
    }
}));
