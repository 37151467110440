import { dynamicRequiredValidator } from "utils/dynamicRequiredValidator";
import { TASKS_OPTIONS, SCHEMA_OPTIONS } from "const/automationTaskConst";

const createFields = () => {
    return [
        { name: "task" },
        { name: "source" },
        { name: "logLevel" },
        { name: "move", type: "checkbox" },
        { name: "replace", type: "checkbox" },
        { name: "schema" },
        { name: "createFolder", type: "checkbox" },
        { name: "pattern" },
        { name: "maxCountValue", type: "number" },
        { name: "transferResume", type: "checkbox" },
        { name: "speedLimitAllow", type: "checkbox" },
        { name: "speedLimit", type: "number" },
        {
            name: "resource",
            validators: [
                dynamicRequiredValidator([
                    {
                        field: "schema",
                        values: [SCHEMA_OPTIONS.evo],
                    },
                    { field: "task", values: [TASKS_OPTIONS.copy, TASKS_OPTIONS.transcode] },
                ]),
            ],
        },
        {
            name: "user",
            validators: [
                dynamicRequiredValidator([
                    {
                        field: "schema",
                        values: [
                            SCHEMA_OPTIONS.s3,
                            SCHEMA_OPTIONS.azure,
                            SCHEMA_OPTIONS.smb,
                            SCHEMA_OPTIONS.afp,
                            SCHEMA_OPTIONS.ftp,
                        ],
                    },
                ]),
            ],
        },
        {
            name: "password",
            type: "password",
            validators: [
                dynamicRequiredValidator([
                    {
                        field: "schema",
                        values: [
                            SCHEMA_OPTIONS.s3,
                            SCHEMA_OPTIONS.azure,
                            SCHEMA_OPTIONS.dropbox,
                            SCHEMA_OPTIONS.smb,
                            SCHEMA_OPTIONS.afp,
                            SCHEMA_OPTIONS.ftp,
                        ],
                    },
                ]),
            ],
        },
        { name: "path" },
        { name: "ftpUserDirIsRoot", type: "checkbox" },
        { name: "ftpPassive", type: "checkbox" },
        { name: "regionChoose", type: "radio" },
        { name: "region" },
        {
            name: "alias",
            validators: [
                dynamicRequiredValidator([
                    {
                        field: "schema",
                        values: [SCHEMA_OPTIONS.alias],
                    },
                ]),
            ],
        },
        {
            name: "preset",
            validators: [
                dynamicRequiredValidator([
                    {
                        field: "task",
                        values: [TASKS_OPTIONS.transcode],
                    },
                ]),
            ],
        },
        { name: "unknownStreamMode" },
    ];
};

export default createFields;
