import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./QuadItem.style";
import DriveItem from "./DriveItem";
import { useTranslation } from "react-i18next";

const QuadItem = ({ title, large, drives }) => {
    const { t } = useTranslation();
    const classes = useStyles({ isLarge: large });
    return useObserver(() => (
        <Grid container direction={"column"}>
            <Grid item container justify={"center"}>
                <Typography component={"span"} className={classes.quadTitle} color={"textSecondary"} noWrap>
                    {title}
                </Typography>
            </Grid>
            <Grid
                item
                container
                className={classes.quadBody}
                direction={large && drives && drives.length > 2 ? "column" : "row"}
                spacing={drives && drives.length > 2 ? 0 : 1}
            >
                {drives &&
                    drives.map((drive) => (
                        <>
                            {drives.length > 2 ? (
                                <DriveItem
                                    withoutHorizontalPadding={!large}
                                    title={t("common.drive_name", { name: drive.drive })}
                                    status={drive.status}
                                />
                            ) : (
                                <Grid key={title + drive.drive} item xs={large && drives && drives.length > 2 ? 6 : 12}>
                                    <DriveItem
                                        large={large}
                                        title={t("common.drive_name", { name: drive.drive })}
                                        status={drive.status}
                                    />
                                </Grid>
                            )}
                        </>
                    ))}
            </Grid>
        </Grid>
    ));
};

export default QuadItem;
