import { types } from "mobx-state-tree";

import FirewallMode from "api/firewall/Types/FirewallMode";

const GetFirewallModeResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(FirewallMode)
});

export default GetFirewallModeResult;
