import Request from "api/Request";

const GetIdentifyStatus = Request.props({
    path: "/com/studionetworksolutions/evo/Drives",
    method: "get_identify_status"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetIdentifyStatus;
