import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    user: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
    path: {
        marginBottom: theme.spacing(8),
    },
    region: {
        marginBottom: theme.spacing(4),
    },
    dndContainer: {
        "& button": {
            height: theme.spacing(12),
        },
    },
    tricasterHeader: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
}));
