import { useState, useEffect } from "react";
import { useEventListener } from "./useEventListener";

/**
 * Hook for defining media query
 * @param {string} mediaQuery
 * @returns {boolean} - true if the viewport is in the media query range
 * @example
 * const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
 */
export const useMediaQuery = breakpoint => {
    const breakpointWithoutMedia = breakpoint.replace("@media ", "");
    const list = window.matchMedia(breakpointWithoutMedia);
    const [isMatch, toggleIsMatch] = useState(list.matches);
    const [mediaQueryList, setMediaQueryList] = useState(list);

    useEffect(() => {
        const list = window.matchMedia(breakpointWithoutMedia);
        setMediaQueryList(list);
        toggleIsMatch(list.matches);
    }, [breakpointWithoutMedia]);

    useEventListener("change", event => toggleIsMatch(event.matches), mediaQueryList);

    return isMatch;
};
