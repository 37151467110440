import { REGION_ENUM } from "const/regionConst";

export const SCHEMA_OPTIONS = {
    evo: "evo",
    smb: "smb",
    ftp: "ftp",
    s3: "s3",
    azure: "azure",
    dropbox: "dropbox",
    gcs: "gcs",
    tricaster: "tricaster",
};

export const driveLetters = () => {
    return Array.from(Array(24))
        .map((e, i) => i + 65 + 2)
        .reverse()
        .map((ch) => String.fromCharCode(ch));
};

export const MEDIA_PLAYER_ARRAY = [
    { value: "ddr1", label: "DDR1" },
    { value: "ddr2", label: "DDR2" },
];

export const SCHEMA_OPTIONS_ARRAY = [
    { value: SCHEMA_OPTIONS.evo, label: "Local" },
    { value: SCHEMA_OPTIONS.smb, label: "SMB" },
    { value: SCHEMA_OPTIONS.ftp, label: "FTP" },
    { value: SCHEMA_OPTIONS.s3, label: "S3" },
    { value: SCHEMA_OPTIONS.azure, label: "Azure" },
    { value: SCHEMA_OPTIONS.dropbox, label: "Dropbox" },
    { value: SCHEMA_OPTIONS.gcs, label: "Google Cloud Storage" },
    { value: SCHEMA_OPTIONS.tricaster, label: "Tricaster" },
];

export const REGION_OPTIONS_ARRAY = [
    { value: REGION_ENUM["US_EAST_1"], label: "us-east-1 (default)" },
    { value: REGION_ENUM["US_EAST_2"], label: "us-east-2" },
    { value: REGION_ENUM["US_WEST_1"], label: "us-west-1" },
    { value: REGION_ENUM["US_WEST_2"], label: "us-west-2" },
    { value: REGION_ENUM["CA_CENTRAL_1"], label: "ca-central-1" },
    { value: REGION_ENUM["AP_SOUTH_1"], label: "ap-south-1" },
    { value: REGION_ENUM["AP_NORTHEAST_1"], label: "ap-northeast-1" },
    { value: REGION_ENUM["AP_NORTHEAST_2"], label: "ap-northeast-2" },
    { value: REGION_ENUM["AP_SOUTHEAST_1"], label: "ap-southeast-1" },
    { value: REGION_ENUM["AP_SOUTHEAST_2"], label: "ap-southeast-2" },
    { value: REGION_ENUM["EU_CENTRAL_1"], label: "eu-central-1" },
    { value: REGION_ENUM["EU_WEST_1"], label: "eu-west-1" },
    { value: REGION_ENUM["EU_WEST_2"], label: "eu-west-2" },
    { value: REGION_ENUM["SA_EAST_1"], label: "sa-east-1" },
];

export const S3_REGION = "region";
export const S3_CUSTOM_HOST = "customHost";
export const S3_CUSTOM_REGION = "custom";
export const S3_SCHEMA = "/s3.amazonaws.com/";
export const S3_CUSTOM_SCHEMA = "/s3-custom.amazonaws.com/";

export const DEFAULT_AIML_BUNCH = "__aiml";

export const END_POINT_DROPBOX_AUTHORIZE = "/oauth2/authorize";
export const END_POINT_DROPBOX_TOKEN = "/oauth2/token";
export const URL_API_DROPBOX = "https://api.dropbox.com";
export const URL_DROPBOX = "https://www.dropbox.com";
export const URL_API_DROPBOX_TOKEN = URL_API_DROPBOX + END_POINT_DROPBOX_TOKEN;
export const URL_API_DROPBOX_AUTHORIZE = URL_DROPBOX + END_POINT_DROPBOX_AUTHORIZE;

export const REGISTRATION_SCHEMA = "alias";
