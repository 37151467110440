import React from "react";
import { Grid, Link } from "@material-ui/core";
import DataCard from "components/DataCard";
import useStyles from "./HelpDocsCard.styles";
import { useTranslation } from "react-i18next";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { HELP_DOCS_CARD } from "const/widgetNameConst";
import { Link as RouterLink } from "react-router-dom";
import { URL_HELP_EVO, URL_HELP_SHAREBROWSER, URL_QUICK_START } from "routes";
import { EXTERNAL_URL_SNS_FTP, EXTERNAL_URL_KNOWLEDGE_BASE } from "const/externalURLs";
import ButtonLine from "components/ButtonLine/ButtonLine";
import BrickIcon from "assets/BrickIcon";
import { useStore } from "hooks/useStore";

const HelpDocsCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { authStore },
    } = useStore();

    return (
        <DataCard
            autoHeight
            title={t("support.start.help.title")}
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={HELP_DOCS_CARD} />
                </MoreMenu>
            }
        >
            <Grid direction={"column"} container>
                {authStore.isAdmin && (
                    <Grid className={classes.line} item>
                        <RouterLink className={classes.linkItem} to={URL_QUICK_START}>
                            <ButtonLine icon={BrickIcon}>{t("support.start.help.quick_start_page")}</ButtonLine>
                        </RouterLink>
                    </Grid>
                )}
                <Grid className={classes.line} container spacing={1}>
                    <Grid item xs={6}>
                        <Link className={classes.linkItem} href={URL_HELP_EVO} target="_blank" rel="noopener">
                            <ButtonLine icon={BrickIcon}>{t("support.start.help.manual.EVO")}</ButtonLine>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Link className={classes.linkItem} href={URL_HELP_SHAREBROWSER} target="_blank" rel="noopener">
                            <ButtonLine icon={BrickIcon}>{t("support.start.help.manual.ShareBrowser")}</ButtonLine>
                        </Link>
                    </Grid>
                </Grid>
                <Grid className={classes.line} item>
                    <Link className={classes.linkItem} href={EXTERNAL_URL_KNOWLEDGE_BASE} target="_blank" rel="noopener">
                        <ButtonLine icon={BrickIcon}>{t("support.start.help.knowledge")}</ButtonLine>
                    </Link>
                </Grid>
                <Grid className={classes.line} item>
                    <Link className={classes.linkItem} href={EXTERNAL_URL_SNS_FTP} target="_blank" rel="noopener">
                        <ButtonLine icon={BrickIcon}>{t("support.start.help.downloads")}</ButtonLine>
                    </Link>
                </Grid>
            </Grid>
        </DataCard>
    );
};

export default HelpDocsCard;
