import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    radioGroup: {
        width: "100%",
        "& .MuiFormGroup-root": {
            flexDirection: "row",
            flexWrap: "nowrap"
        }
    }
}));
