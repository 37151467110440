import React, { useContext } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { useStyles } from "./SystemComponentDrawer.styles";
import { Grid, Typography } from "@material-ui/core";
import BrowserIcon from "assets/BrowserIcon";
import SlingshotIcon from "assets/SlingshotIcon";
import UpgradeSupportIcon from "assets/UpgradeSupportIcon";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import ComponentInstallDialog from "../../../ComponentInstallDialog/ComponentInstallDialog";
import {
    SLINGSHOT_SYSTEM_COMPONENT,
    SBS_SYSTEM_COMPONENT,
    SB_WEBCLIENT_SYSTEM_COMPONENT,
    WEB_UI_SYSTEM_COMPONENT,
} from "const/systemComponentConst";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";

const drawerName = "systemComponentDrawer";
const SystemComponentDrawer = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { systemComponentsStore, uiStore },
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isUpgradeDialogOpen: false,
    }));

    const closeDrawer = () => {
        systemComponentsStore.setCurrentComponentName(null);
    };

    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: systemComponentsStore.currentComponentName });

    const openUpgradeDialog = () => {
        state.isUpgradeDialogOpen = true;
    };
    const closeUpgradeDialog = (isUpgraded) => {
        state.isUpgradeDialogOpen = false;
        if (isUpgraded) {
            systemComponentsStore.setCurrentComponentName(null);
        }
    };

    const getIcon = () => {
        switch (systemComponentsStore.currentComponentName) {
            case SLINGSHOT_SYSTEM_COMPONENT:
                return <SlingshotIcon />;
            case SBS_SYSTEM_COMPONENT:
                return <BrowserIcon />;
            case SB_WEBCLIENT_SYSTEM_COMPONENT:
                return <BrowserIcon />;
            case WEB_UI_SYSTEM_COMPONENT:
                return <BrowserIcon />;
            default:
                return <BrowserIcon />;
        }
    };

    const renderActions = () => {
        return (
            <Grid item>
                <BulkBarFlatButton
                    onClick={openUpgradeDialog}
                    icon={<UpgradeSupportIcon />}
                    disabled={systemComponentsStore.isUpgradingComponent}
                >
                    {t("support.start.installed_components.actions.button.upgrade")}
                </BulkBarFlatButton>
            </Grid>
        );
    };

    const renderDetails = () => {
        return (
            <Grid container direction={"column"} wrap={"nowrap"} className={classes.detailsContainer} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("support.start.installed_components.details.label.name")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {systemComponentsStore.currentComponent?.componentName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"}>
                    <Grid item xs={6}>
                        <Typography>{t("support.start.installed_components.details.label.version")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            {systemComponentsStore.currentComponent?.componentVersion}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const drawerCloseHandler = () => {
        if (uiStore.openedDrawer !== drawerName) {
            state.showDetails = true;
        }
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            drawerName={drawerName}
            onAnimationEnd={drawerCloseHandler}
            title={systemComponentsStore.currentComponentName}
            icon={getIcon()}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            modals={<ComponentInstallDialog onClose={closeUpgradeDialog} open={state.isUpgradeDialogOpen} />}
        />
    ));
};

export default SystemComponentDrawer;
