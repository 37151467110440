import React from "react";

const NoDiskGroupIcon = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="152"
            height="152"
            viewBox="0 0 152 152"
            {...props}
        >
            <defs>
                <linearGradient id="35b651d4-4db3-4b13-9156-621b46ef72c1" x1="-1.875%" x2="75.361%" y1="-15.973%" y2="100%">
                    <stop offset="0%" stopColor="#D4E2F1" />
                    <stop offset="100%" stopColor="#A9B7D4" stopOpacity="0.91" />
                </linearGradient>
                <linearGradient id="af5e8adc-6846-43c6-89b7-494a8dd7e3b3" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.4" />
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.4" />
                </linearGradient>
                <linearGradient id="c40a6f30-0282-4bc3-b46d-0963471dc2f3" x1="-31.054%" x2="89.626%" y1="-15.973%" y2="100%">
                    <stop offset="0%" stopColor="#DEEFFF" />
                    <stop offset="100%" stopColor="#B7C4DF" stopOpacity="0.91" />
                </linearGradient>
                <linearGradient id="66d6175f-cce3-4e36-927e-3551f7685294" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#EAF2FF" />
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.4" />
                </linearGradient>
                <linearGradient id="bb33fbe1-38b0-4ae9-90ab-5fcdc649ca25" x1="-31.054%" x2="89.626%" y1="-15.973%" y2="100%">
                    <stop offset="0%" stopColor="#D4E2F1" />
                    <stop offset="100%" stopColor="#A9B7D4" stopOpacity="0.91" />
                </linearGradient>
                <linearGradient id="de1beadc-d1ed-4f49-bc20-28a633f2ca5e" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.4" />
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0.4" />
                </linearGradient>
                <filter
                    id="bd50b23b-e057-44e7-b67e-82f7b241181d"
                    width="122.3%"
                    height="88.1%"
                    x="-5.6%"
                    y="14.5%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1" />
                    <feColorMatrix
                        in="shadowOffsetOuter1"
                        values="0 0 0 0 0.774203068 0 0 0 0 0.816425105 0 0 0 0 0.874858469 0 0 0 1 0"
                    />
                </filter>
                <filter
                    id="d1ce842e-1766-4198-9576-0223e43e1966"
                    width="125%"
                    height="125%"
                    x="-12.5%"
                    y="-12.5%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset dy="1" in="SourceAlpha" result="shadowOffsetInner1" />
                    <feComposite
                        in="shadowOffsetInner1"
                        in2="SourceAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                        result="shadowInnerInner1"
                    />
                    <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                </filter>
                <circle id="a4f44ecf-a3d6-4d22-be4c-13ded5861e07" cx="21.972" cy="74.949" r="2" />
                <path
                    id="239a99f8-6b37-4140-8898-5f3c3d27b387"
                    d="M40.495 87.579a4.486 4.486 0 004.486-4.487c0-1.486-1.928-15.658-5.784-42.514l-.041.006a.838.838 0 00-1.667.088c-.987 26.795-1.48 40.935-1.48 42.42a4.486 4.486 0 004.486 4.487z"
                />
            </defs>
            <g fill="none" fillRule="evenodd">
                <circle cx="76" cy="76" r="76" fill="#F2F6F9" />
                <g transform="translate(32 32)">
                    <rect
                        width="67.2"
                        height="84.8"
                        x="10.4"
                        y="1.6"
                        fill="url(#35b651d4-4db3-4b13-9156-621b46ef72c1)"
                        stroke="url(#af5e8adc-6846-43c6-89b7-494a8dd7e3b3)"
                        strokeWidth="3.2"
                        rx="10.4"
                    />
                    <path fill="#FFF" d="M12 64h64v13.6a7.2 7.2 0 01-7.2 7.2H19.2a7.2 7.2 0 01-7.2-7.2V64z" opacity="0.2" />
                    <path fill="#FFF" d="M12 64h64v3.2H12z" opacity="0.1" />
                    <circle
                        cx="44"
                        cy="33.6"
                        r="24"
                        fill="url(#c40a6f30-0282-4bc3-b46d-0963471dc2f3)"
                        stroke="url(#66d6175f-cce3-4e36-927e-3551f7685294)"
                        strokeWidth="3.2"
                    />
                    <circle cx="44" cy="33.6" r="5.6" fill="#FFF" fillOpacity="0.12" stroke="#EAF2FF" strokeWidth="4.8" />
                    <g transform="rotate(50 40.495 59.367)">
                        <use
                            fill="#000"
                            filter="url(#bd50b23b-e057-44e7-b67e-82f7b241181d)"
                            xlinkHref="#239a99f8-6b37-4140-8898-5f3c3d27b387"
                        />
                        <use fill="#FFF" fillOpacity="0.98" xlinkHref="#239a99f8-6b37-4140-8898-5f3c3d27b387" />
                    </g>
                    <use fill="#C4D0E8" xlinkHref="#a4f44ecf-a3d6-4d22-be4c-13ded5861e07" />
                    <use
                        fill="#000"
                        filter="url(#d1ce842e-1766-4198-9576-0223e43e1966)"
                        xlinkHref="#a4f44ecf-a3d6-4d22-be4c-13ded5861e07"
                    />
                    <g transform="translate(55 58)">
                        <circle
                            cx="16"
                            cy="16"
                            r="15"
                            fill="url(#bb33fbe1-38b0-4ae9-90ab-5fcdc649ca25)"
                            stroke="url(#de1beadc-d1ed-4f49-bc20-28a633f2ca5e)"
                            strokeWidth="2"
                        />
                        <path
                            fill="#FFF"
                            d="M21.657 10.343c.78.781.78 2.048 0 2.829l-2.828 2.827 2.828 2.83c.78.78.78 2.047 0 2.828-.781.78-2.048.78-2.829 0L16 18.828l-2.828 2.829c-.781.78-2.048.78-2.829 0a2.003 2.003 0 010-2.829L13.172 16l-2.829-2.828a2.003 2.003 0 010-2.829 2.003 2.003 0 012.829 0L16 13.172l2.828-2.829a2.003 2.003 0 012.829 0z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default NoDiskGroupIcon;
