import { types } from "mobx-state-tree";
import CreatedPresetArgumentsType from "./CreatedPresetArgumentsType";

const PRESET_TYPE_ENUM_ARRAY = ["VIDEO", "IMAGE", "AUDIO"];

const PresetType = types.model({
    arguments: types.maybe(
        types.snapshotProcessor(CreatedPresetArgumentsType, {
            preProcessor(snapshot) {
                return JSON.parse(snapshot);
            },
        })
    ),
    builtin: types.maybe(types.boolean),
    id: types.maybe(types.number),
    name: types.maybe(types.string),
    preset_type: types.maybe(types.enumeration(PRESET_TYPE_ENUM_ARRAY)),
    watermark_id: types.maybeNull(types.number),
});

export default PresetType;
