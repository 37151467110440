import React, { useCallback, useContext, useEffect } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Grid, Link, ButtonBase } from "@material-ui/core";
import { useStyles } from "./WireguardTable.styles";
import { useObserver, useLocalStore } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "assets/ChevronRightIcon";
import TableRowControlButton from "components/TableRowControlButton";
import {
    ASC,
    DESC,
    WIREGUARD_AUTOSTART,
    WIREGUARD_CONNECTION_NAME,
    WIREGUARD_ENDPOINT,
    WIREGUARD_LOCAL_ADDRESS,
    WIREGUARD_PROTOCOL_NAME,
    WIREGUARD_STATUS
} from "const/sortColumnConst";
import SavedInputIndicator from "components/SavedInputIndicator/SavedInputIndicator";
import StatusLabel from "components/StatusLabel";
import DataCard from "components/DataCard/DataCard";
import PlusIcon from "assets/PlusIcon";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import TableExpand from "components/TableExpand/TableExpand";
import TableSortLabel from "components/TableSortLabel";
import AddConnectionDialog from "../AddConnectionDialog/AddConnectionDialog";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import MoreMenu from "components/MoreMenu";
import { WIREGUARD_CARD } from "const/widgetNameConst";

const WireguardTable = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: { wireguardStore }
    } = useContext(Context);

    useEffect(() => {
        wireguardStore.fetchConnections();
    }, []);

    const HIDDEN_ROWS_PER_CARD_INDEX = 6;
    const state = useLocalStore(() => ({
        orderBy: WIREGUARD_CONNECTION_NAME,
        order: ASC,
        isExpanded: false,
        isAddConnectionDialogOpened: false,
        get slicedIndex() {
            if (this.isExpanded) {
                return this.connections.length;
            } else {
                return HIDDEN_ROWS_PER_CARD_INDEX;
            }
        },
        get connections() {
            return wireguardStore.connections;
        },
        get sortedConnections() {
            return this.connections && stableSort(this.connections, getComparator(this.order, this.orderBy));
        }
    }));

    const openAddConnectionDialog = useCallback(() => {
        state.isAddConnectionDialogOpened = true;
    });
    const closeAddConnectionDialog = useCallback(() => {
        state.isAddConnectionDialogOpened = false;
    });

    const toggleExpandShares = useCallback(() => {
        state.isExpanded ? (state.isExpanded = false) : (state.isExpanded = true);
    });

    const onRowClick = useCallback(connection => () => {
        wireguardStore.setCurrentConnectionName(
            wireguardStore.currentConnectionName === connection.connectionName ? null : connection.connectionName
        );
    });

    const changeSorting = useCallback(column => () => {
        if (column === state.orderBy) {
            state.order = state.order === DESC ? ASC : DESC;
        } else {
            state.order = ASC;
            state.orderBy = column;
        }
    });

    return useObserver(() => (
        <DataCard
            emptyPadding
            autoHeight
            title={t("wireguard.table.title")}
            headerDivider
            headerControl={[
                <MoreMenu key={"control-1"}>
                    <HomeCardActionMenuItem widget={WIREGUARD_CARD} />
                </MoreMenu>,
                <ButtonBase onClick={openAddConnectionDialog} key={"control-1"}>
                    <PlusIcon />
                </ButtonBase>
            ]}
        >
            <AddConnectionDialog open={state.isAddConnectionDialogOpened} closeDialog={closeAddConnectionDialog} />
            {wireguardStore.connections && (
                <Grid className={classes.tableContainer} container direction={"column"} justify={"space-between"}>
                    <Table>
                        {state.connections.length === 0 ? (
                            <TableExpand hideDivider icon={<PlusIcon />} onClick={openAddConnectionDialog}>
                                {t("wireguard.table.add_connection")}
                            </TableExpand>
                        ) : (
                            <>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                direction={state.order}
                                                onClick={changeSorting(WIREGUARD_CONNECTION_NAME)}
                                                active={state.orderBy === WIREGUARD_CONNECTION_NAME}
                                            >
                                                {t("wireguard.table.header.name")}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell width={"160px"}>
                                            <TableSortLabel
                                                direction={state.order}
                                                onClick={changeSorting(WIREGUARD_PROTOCOL_NAME)}
                                                active={state.orderBy === WIREGUARD_PROTOCOL_NAME}
                                            >
                                                {t("wireguard.table.header.protocol")}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell width={"160px"}>
                                            <TableSortLabel
                                                direction={state.order}
                                                onClick={changeSorting(WIREGUARD_ENDPOINT)}
                                                active={state.orderBy === WIREGUARD_ENDPOINT}
                                            >
                                                {t("wireguard.table.header.endpoint")}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell width={"160px"}>
                                            <TableSortLabel
                                                direction={state.order}
                                                onClick={changeSorting(WIREGUARD_LOCAL_ADDRESS)}
                                                active={state.orderBy === WIREGUARD_LOCAL_ADDRESS}
                                            >
                                                {t("wireguard.table.header.local_address")}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell width={"160px"} align={"center"}>
                                            <TableSortLabel
                                                direction={state.order}
                                                onClick={changeSorting(WIREGUARD_STATUS)}
                                                active={state.orderBy === WIREGUARD_STATUS}
                                            >
                                                {t("wireguard.table.header.status")}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell width={"160px"}>
                                            <TableSortLabel
                                                direction={state.order}
                                                onClick={changeSorting(WIREGUARD_AUTOSTART)}
                                                active={state.orderBy === WIREGUARD_AUTOSTART}
                                            >
                                                {t("wireguard.table.header.autostart")}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell width={"5px"}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.sortedConnections?.slice(0, state.slicedIndex).map((entity, i) => {
                                        return (
                                            <TableRow
                                                selected={wireguardStore.currentConnectionName === entity.connectionName}
                                                // TODO  Do not use Array index in keys
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={"wireguardConnection" + i}
                                                onClick={onRowClick(entity)}
                                            >
                                                <TableCell>{entity.connectionName}</TableCell>
                                                <TableCell>{entity.protocol}</TableCell>
                                                <TableCell>
                                                    <Link href={entity.endpointLink} target="_blank">
                                                        {entity.endpoint}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{entity.localIP}</TableCell>
                                                <TableCell>
                                                    <StatusLabel status={entity.connectionState} />
                                                </TableCell>
                                                <TableCell>
                                                    <SavedInputIndicator enabled={entity.autostart} />
                                                </TableCell>
                                                <TableCell>
                                                    <TableRowControlButton icon={<ChevronRightIcon />} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                {state.connections.length > HIDDEN_ROWS_PER_CARD_INDEX && (
                                    <TableExpand onClick={toggleExpandShares}>
                                        {state.isExpanded
                                            ? t("wireguard.show_all_table_caption.hide")
                                            : t("wireguard.show_all_table_caption.show_all", {
                                                  count: state.connections.length
                                              })}
                                    </TableExpand>
                                )}
                            </>
                        )}
                    </Table>
                </Grid>
            )}
        </DataCard>
    ));
};

export default WireguardTable;
