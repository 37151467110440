import { types } from "mobx-state-tree";

import Request from "api/Request";
import NetInterfaceSettings from "api/net/Types/NetInterfaceSettings";

const SaveNetIfaceSettings = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "save_net_iface_settings",
    arguments: types.maybe(NetInterfaceSettings)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SaveNetIfaceSettings;
