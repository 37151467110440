import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetBackupSettings from "api/system_components/backup_schedule/Requests/GetBackupSettings";
import GetBackupSettingsResult from "api/system_components/backup_schedule/Responses/GetBackupSettingsResult";
import SetBackupSettings from "api/system_components/backup_schedule/Requests/SetBackupSettings";
import ExportSlingshot from "api/system_components/slingshot/Requests/ExportDatabase";
import ExportShareBrowser from "api/system_components/sharebrowser_server/Requests/ExportDatabase";
import ImportDatabaseAndConfigSlingshot from "api/system_components/slingshot/Requests/ImportDatabaseAndConfig";
import ImportDatabaseShareBrowser from "api/system_components/sharebrowser_server/Requests/ImportDatabase";
import ListBackups from "api/system_components/sharebrowser_server/Requests/ListBackups";
import GetListBackupsResult from "api/system_components/sharebrowser_server/Responses/GetListBackupsResult";
import ListDatabaseBackupsSlingshot from "api/system_components/slingshot/Requests/ListDatabaseBackups";
import ListConfigBackupsSlingshot from "api/system_components/slingshot/Requests/ListConfigBackups";
import GetListDatabaseBackupsResultSlingshot from "api/system_components/slingshot/Responses/GetListDatabaseBackupsResult";
import GetListConfigBackupsResultSlingshot from "api/system_components/slingshot/Responses/GetListConfigBackupsResult";
import moment from "moment";
import i18n from "i18n";
import axios from "api/AxiosCommonRequest";
import { FILE_DOWNLOAD_URL, FILE_UPLOAD_URL } from "api/restRoutes";
import { DATABASE_SLINGSHOT, DATABASE_SHAREBROWSER } from "const/setupBackupConst";
import { saveAs } from "file-saver";
import GetIdResult from "api/backup/Responses/GetIdResult";
import GetDevices from "api/backup/Requests/GetDevices";
import GetId from "api/backup/Requests/GetId";
import DeviceTuple from "api/backup/Types/DeviceTuple";
import StartBackup from "api/backup/Requests/StartBackup";
import StopBackup from "api/backup/Requests/Stop";
import RestoreBackup from "api/backup/Requests/StartRestore";
import OwnDevice from "api/backup/Requests/OwnDevice";
import {
    DESTROY_RESPONSE_STATUS_ERROR,
    DESTROY_RESPONSE_STATUS_SUCCESS,
    RECOVERY_RESPONSE_STATUS_ERROR,
    RECOVERY_RESPONSE_STATUS_NEED_FORCE,
    RECOVERY_RESPONSE_STATUS_SUCCESS,
} from "const/backupConst";

const BackupSettingsStore = types
    .model({
        backupStore: types.maybe(GetBackupSettingsResult),
        evoIdStore: types.maybe(GetIdResult),
        filteredBackups: types.array(DeviceTuple),
        currentBackupPath: types.maybeNull(types.string),
        listBackupsResult: types.maybe(GetListBackupsResult),
        listDatabaseBackupsSlingshotResult: types.maybe(GetListDatabaseBackupsResultSlingshot),
        listConfigBackupsSlingshotResult: types.maybe(GetListConfigBackupsResultSlingshot),
    })
    .views((self) => ({
        get backup() {
            return self.backupStore?.data;
        },
        get backupSchedule() {
            if (!self.backup.length && !self.backup.schedule?.length) return null;
            const schedule = new Map(Object.entries(self.backup.schedule[0]));
            const daysOfWeek = new Map();
            schedule.forEach((val, key) => val === true && daysOfWeek.set(key, val));
            if (daysOfWeek.size === 7)
                return i18n.t("support.os_data.backup_card.schedule.every_day", { time: self.backupScheduleTime });
            if (daysOfWeek.size === 5 && !daysOfWeek.has("sunday") && !daysOfWeek.has("saturday"))
                return i18n.t("support.os_data.backup_card.schedule.every_weekday", { time: self.backupScheduleTime });
            if (daysOfWeek.size === 1)
                return i18n.t("support.os_data.backup_card.schedule.on_days", {
                    time: self.backupScheduleTime,
                    days: [...daysOfWeek.keys()],
                });
            return i18n.t("support.os_data.backup_card.schedule.on_days", {
                time: self.backupScheduleTime,
                days: [...daysOfWeek.keys()].map((key) => key.substr(0, 3).replace(key[0], key[0].toUpperCase())).join(" "),
            });
        },
        get backupScheduleTime() {
            if (self.backup.schedule.length === 0) return null;
            return moment().hour(self.backup.schedule[0].hour).minute(self.backup.schedule[0].minute).format("HH:mm");
        },
        get evoId() {
            return self.evoIdStore?.data;
        },
        get backupDevices() {
            return self.filteredBackups || [];
        },
        get currentBackup() {
            return self.currentBackupPath !== null && self.backupDevices.find((backup) => backup.path === self.currentBackupPath);
        },
        get listBackups() {
            return self.listBackupsResult?.data || [];
        },
        get listDatabaseBackupsSlingshot() {
            return self.listDatabaseBackupsSlingshotResult?.data || [];
        },
        get listConfigBackupsSlingshot() {
            return self.listConfigBackupsSlingshotResult?.data || [];
        },
    }))
    .actions((self) => ({
        fetchBackup: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetBackupSettings.create().init();
                const res = yield Socket.send(req);
                self.backupStore = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchEvoId: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetId.create().init();
                const res = yield Socket.send(req);
                self.evoIdStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchDevices: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetDevices.create().init();
                const res = yield Socket.send(req);
                let backupsMap = new Map();
                res.data &&
                    res.data.forEach((a) => {
                        backupsMap.has(a.id) ? backupsMap.get(a.id).push(a) : backupsMap.set(a.id, [a]);
                    });
                self.filteredBackups = Array.from(backupsMap.values())
                    .flat()
                    .sort((a, b) => (a.ver > b.ver ? a : b));
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateBackup: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetBackupSettings.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        exportSlingshot: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ExportSlingshot.create().init();
                const res = yield Socket.send(req);
                if ("" !== res.data.cfgBackupName) {
                    self.downloadBackup(res.data.cfgBackupName);
                }
                self.downloadBackup(res.data.dbBackupName);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        exportShareBrowser: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ExportShareBrowser.create().init();
                const res = yield Socket.send(req);
                self.downloadBackup(res.data);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        downloadBackup: flow(function* (fileName) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const config = { responseType: "blob", params: { file: fileName } };
                const res = yield axios.get(FILE_DOWNLOAD_URL, config);
                saveAs(new Blob([res.data]), fileName);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        importBackup: flow(function* (fileName, variant, source, cfgBackup) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                let req;
                if (variant === DATABASE_SLINGSHOT) {
                    req = ImportDatabaseAndConfigSlingshot.create().init({
                        dbBackupSource: source,
                        dbBackupName: fileName,
                        cfgBackupName: cfgBackup,
                    });
                } else if (variant === DATABASE_SHAREBROWSER) {
                    req = ImportDatabaseShareBrowser.create().init({
                        fileName,
                        source,
                    });
                }
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        uploadFile: flow(function* (file, variant, source, cfgBackup) {
            const { processingStore } = getParent(self);
            const formData = new FormData();
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            try {
                formData.append("file", file);
                processingStore.setLoading(true);
                yield axios.post(FILE_UPLOAD_URL, formData, config);
                yield self.importBackup(file.name, variant, source, cfgBackup);
                return file;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getListBackups: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ListBackups.create().init();
                const res = yield Socket.send(req);
                self.listBackupsResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        getListDatabaseBackupsSlingshot: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ListDatabaseBackupsSlingshot.create().init();
                const res = yield Socket.send(req);
                self.listDatabaseBackupsSlingshotResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        getListConfigBackupsSlingshot: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ListConfigBackupsSlingshot.create().init();
                const res = yield Socket.send(req);
                self.listConfigBackupsSlingshotResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setCurrentBackupPath: (backup) => {
            if (!backup) {
                self.currentBackupPath = null;
                return;
            }

            self.currentBackupPath = self.currentBackupPath === backup.path ? null : backup.path;
        },
        startBackup: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = StartBackup.create().init();
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        stopBackup: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = StopBackup.create().init();
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        recoverBackup: flow(function* (isForce = false) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RestoreBackup.create().init({
                    id: self.currentBackup.id,
                    ver: self.currentBackup.ver,
                    force: isForce,
                });
                yield Socket.send(req);

                return RECOVERY_RESPONSE_STATUS_SUCCESS;
            } catch (e) {
                if (e.code === 449) {
                    return RECOVERY_RESPONSE_STATUS_NEED_FORCE;
                }
                processingStore.setError(e);
                return RECOVERY_RESPONSE_STATUS_ERROR;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        destroyBackup: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = OwnDevice.create().init(self.currentBackup.path);
                yield Socket.send(req);

                return DESTROY_RESPONSE_STATUS_SUCCESS;
            } catch (e) {
                processingStore.setError(e);

                return DESTROY_RESPONSE_STATUS_ERROR;
            } finally {
                processingStore.setLoading(false);
            }
        }),
    }));

export default BackupSettingsStore;
