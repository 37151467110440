import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

const ITEM_HEIGHT = 40;
const PAPER_MIN_WIDTH = 160;
export const useStyles = makeStyles((theme) => ({
    iconContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: theme.spacing(8),
        height: theme.spacing(8),
        borderRadius: theme.spacing(1),
        backgroundColor: ({ isOpen }) => isOpen && hexToRgba(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: hexToRgba(theme.palette.common.white, 0.05),
        },
    },
    menu: {
        "&:hover  > $iconContainer": {
            backgroundColor: hexToRgba(theme.palette.common.white, 0.05),
        },
    },
    menuPaper: {
        "& .MuiMenu-paper": {
            minWidth: PAPER_MIN_WIDTH,
        },
        "& .MuiMenuItem-root": {
            height: ITEM_HEIGHT,
            "&:hover": {
                backgroundColor: theme.palette.searchInlinePopover.hover,
            },
        },
    },
}));
