import { types } from "mobx-state-tree";
import PresetArgumentsType from "./PresetArgumentsType";
const PRESET_TYPE_ENUM_ARRAY = ["VIDEO", "IMAGE", "AUDIO"];

const UpdatePresetType = types.model({
    json: types.model({
        id: types.maybe(types.number),
        name: types.maybe(types.string),
        arguments: types.maybe(
            types.snapshotProcessor(PresetArgumentsType, {
                postProcessor(snapshot) {
                    return JSON.stringify(snapshot);
                },
            })
        ),
        builtin: types.maybe(types.boolean),
        preset_type: types.maybe(types.enumeration(PRESET_TYPE_ENUM_ARRAY)),
        watermark_id: types.maybeNull(types.number),
    }),
});

export default UpdatePresetType;
