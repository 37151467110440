import { types } from "mobx-state-tree";
import DecomposedBaseUrlType from "./DecomposedBaseUrlType";
import SecretType from "./SecretType";

const CreateAliasType = types.model("CreateAlias", {
    json: types.model({
        name: types.maybe(types.string),
        decomposed_base_url: types.maybe(DecomposedBaseUrlType),
        secret: types.maybe(SecretType),
        personal: types.maybe(types.boolean),
        internal: types.maybe(types.boolean),
        bunch: types.maybe(types.string),
        share_mappings: types.maybe(
            types.model({
                list: types.maybe(
                    types.array(
                        types.model({
                            share: types.maybe(types.string),
                            drive: types.maybe(types.string),
                        })
                    )
                ),
            })
        ),
    }),
});
export default CreateAliasType;
