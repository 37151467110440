import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => {
    const getColor = ({ variant, data }) => {
        if (variant === "health") {
            if (data.buildPaused) return theme.palette.success.main;
            switch (data.health) {
                case "queued":
                case "building":
                case "grow":
                case "removing":
                    return theme.palette.primary.main;
                case "good":
                    return theme.palette.success.main;
                case "degraded":
                    return theme.palette.warning.main;
                case "corrupt":
                case "unknown":
                    return theme.palette.error.main;
                default:
                    return theme.palette.primary.main;
            }
        }
        if (variant === "status") {
            switch (data.status) {
                case "pending":
                    return theme.palette.primary.main;
                case "online":
                    return theme.palette.success.main;
                case "offline":
                case "error":
                    return theme.palette.error.main;
                default:
                    return theme.palette.primary.main;
            }
        }
    };

    const getProgressColor = (theme, value) => {
        if (value >= 90) return theme.palette.error.main;
        if (value >= 80) return theme.palette.warning.main;
        return theme.palette.primary.main;
    };

    return {
        label: {
            padding: theme.spacing(0, 2),
            borderRadius: theme.spacing(1),
            backgroundColor: props => hexToRgba(getColor(props), 0.1),
            "& span": {
                color: props => getColor(props)
            }
        },
        labelText: {
            fontWeight: 500
        },
        labelCapitalize: {
            fontWeight: 500,
            textTransform: "capitalize"
        },
        circularContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        circularProgressBottom: {
            color: hexToRgba(theme.palette.primary.main, 0.2)
        },
        circularProgress: ({ value }) => ({
            color: getProgressColor(theme, value)
        }),
        circularBase: {
            padding: "4px",
            alignSelf: "center",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .MuiCircularProgress-root": {
                position: "absolute"
            }
        }
    };
});
