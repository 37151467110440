import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    radioGroup: {
        padding: theme.spacing(3, 6, 8, 6)
    },
    radio: {
        "&:not(:first-child)": {
            marginTop: theme.spacing(6)
        },
        "& .MuiFormControlLabel-label": {
            fontWeight: 500
        },
        "& p": {
            opacity: 0.8
        }
    },
    controlsContainer: {
        width: "100%",
        margin: 0,
        padding: theme.spacing(2, 4)
    }
}));
