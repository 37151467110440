import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import { useObserver, useLocalStore } from "mobx-react";
import createFields from "../createCertificateFields";
import createForm from "utils/createForm";
import { useTranslation } from "react-i18next";
import FormLayout from "../FormLayout";
import { COUNTRY_CODES, COUNTRY_CODES_DEFAULT } from "const/sslCertificateCountryCodesConst";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
const CREATE_SELF_SIGNED_CERTIFICATE_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_create_self_signed_certificate`;

const CreateSelfSignedCertificate = ({ open, onClose, onBack, handleEnableHttps }) => {
    const { t } = useTranslation();
    const fields = createFields();
    const state = useLocalStore(() => ({
        inProgress: false,
        form: createForm({
            fields,
        }),
        isSubmitted: false,
    }));

    const unsavedChangesModal = useModal();

    const {
        store: { webserverStore, certificateStore },
    } = useStore();

    useEffect(() => {
        webserverStore.fetchConfigInfo();
    }, []);

    const resolveSubmitCondition = () => {
        state.isSubmitted = true;
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    };

    const submit = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        state.inProgress = true;
        const res = await certificateStore.createSelfSignedCertificate({
            commonName: state.form.$("commonName").value,
            organization: state.form.$("organization").value,
            department: state.form.$("department").value,
            country: state.form.$("country").value.value,
            stateName: state.form.$("state").value,
            city: state.form.$("city").value,
        });
        state.inProgress = false;
        if (!res) return res;

        await resolveSubmitCondition();
        if (webserverStore.configInfo) {
            handleEnableHttps();
            onClose();
        }
    };

    const onEnter = () => {
        const defaultCountry = COUNTRY_CODES.find((el) => el.value === COUNTRY_CODES_DEFAULT);
        state.form.$("country").set("default", defaultCountry);
        state.form.reset();
        state.form.$("commonName").resetValidation();
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };

    const closeIfNeeded = () => {
        state.form.isDefault || state.isSubmitted ? onClose() : unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            onEnter={onEnter}
            maxWidth={"md"}
            fullWidth
            open={open}
            onClose={closeIfNeeded}
            title={t("admin_settings.server_certificate.create_self_signed_certificate.title")}
            onSubmit={submit}
            submitBtnLabel={t("common.button.create")}
            buttons={
                <Button
                    onClick={onBack}
                    variant={"contained"}
                    color={"secondary"}
                    id={`${CREATE_SELF_SIGNED_CERTIFICATE_ID_PREFIX}_back`}
                >
                    {t("common.button.back")}
                </Button>
            }
        >
            <FormLayout form={state.form} />
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default CreateSelfSignedCertificate;
