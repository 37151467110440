import { types } from "mobx-state-tree";

import DiskTestSettings from "api/troubleshooting/Types/DiskTestSettings";

const DiskTestSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(DiskTestSettings)
});

export default DiskTestSettingsResult;
