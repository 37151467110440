import React, { useEffect, useMemo, useRef, useState } from "react";
import { TableBody } from "@material-ui/core";
import FolderIcon from "assets/FolderIcon";
import DocIcon from "assets/DocIcon";
import { useDebounce } from "hooks/useDebounce";
import { equalsIgnoreCase } from "utils/equalsIgnoreCase";
import FilesRow from "../FilesRow/FilesRow";
import { observer } from "mobx-react";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";

const FilesTableBody = ({ ip, openFolder, fileNavigator, fileStore, classes }) => {
    const VISIBLE_ROWS = 50;

    const [items, setItems] = useState([]);

    const bodyContainer = document.querySelector("#bodyContainer");

    const renderTypeIcon = useRef((type, name) => {
        if (equalsIgnoreCase(type, "directory")) {
            return <FolderIcon className={classes.fileIcon} onClick={openFolder(name)} />;
        } else if (equalsIgnoreCase(type, "file")) {
            return <DocIcon className={classes.fileIcon} />;
        }
        return <DocIcon />;
    });

    useEffect(() => {
        if (fileStore.sortedFiles.length > 0) {
            setItems(fileStore.sortedFiles?.slice(0, VISIBLE_ROWS) || []);

            if (bodyContainer) {
                bodyContainer.addEventListener("scroll", onScroll);
                return () => {
                    bodyContainer.removeEventListener("scroll", onScroll);
                };
            }
        }
    }, [fileStore.sortedFiles.length, fileStore.totalFiles]);

    const { run: onScroll } = useDebounce(() => {
        if (
            bodyContainer.scrollHeight - (bodyContainer.scrollTop + bodyContainer.offsetHeight) <
                bodyContainer.offsetHeight / 1.5 &&
            items?.length < fileStore.sortedFiles?.length
        ) {
            const newFiles = (items, fileStore) => {
                setTimeout(() => {
                    const step = items.length / VISIBLE_ROWS;
                    if (step > 0 && items.length < fileStore.sortedFiles?.length) {
                        setItems(prevFiles => {
                            const result = prevFiles.concat(
                                fileStore.sortedFiles?.slice(VISIBLE_ROWS * step, VISIBLE_ROWS * (step + 1))
                            );

                            return result;
                        });
                    }
                }, 0);
            };
            newFiles(items, fileStore);
        }
    }, 0);

    const sortedItems = useMemo(() => stableSort(items, getComparator(fileStore.order, fileStore.orderBy)), [
        fileStore.order,
        fileStore.orderBy,
        items
    ]);

    return (
        <TableBody>
            {sortedItems?.map(file => (
                <FilesRow
                    key={`${file.name}-${file.type}`}
                    onClick={fileNavigator.chooseFile}
                    name={file.name}
                    modifiedTime={file.modifiedTime}
                    type={file.type}
                    size={file.size}
                    selectFile={fileNavigator.selectFile}
                    renderTypeIcon={renderTypeIcon.current}
                    checked={fileNavigator.includesSelectedFiles(file.name)}
                    ip={ip}
                />
            ))}
        </TableBody>
    );
};

export default observer(FilesTableBody);
