import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";
import { POPOVER_BACKGROUND_COLOR } from "const/styleConst";

export const useStyles = makeStyles((theme) => {
    return {
        selectInput: {
            border: "1px solid transparent",
            height: theme.spacing(10),
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.grey["A100"],
            opacity: 1,
            textTransform: (props) => (props.displayInUpperCase ? "uppercase" : "none"),
            "&:not($selectInputError):not(.Mui-focused):hover": {
                border: `solid 1px ${theme.palette.grey[200]}`,
            },
            "& .MuiTypography-root": {
                lineHeight: 1.25,
                overflowX: "hidden",
            },
            "&.Mui-focused": {
                border: `solid 1px ${theme.palette.primary.main}`,
            },
            "& .MuiSelect-icon>path": {
                fill: theme.palette.primary.main,
            },
            "&.Mui-disabled": {
                backgroundColor: hexToRgba(theme.palette.grey[100], 0.5) ?? theme.palette.grey[100],
                color: theme.palette.grey[400],
                "&&:hover": {
                    borderColor: "transparent",
                },
                "& .MuiTypography-root": {
                    opacity: 0.4,
                },
                "& .MuiSelect-icon>path": {
                    fill: theme.palette.grey[300],
                    opacity: 0.4,
                },
            },
        },
        rowLabelText: {
            textTransform: "none",
            color: theme.palette.common.black,
        },
        selectInputError: {
            border: `solid 1px ${theme.palette.error.main}`,
        },
        formControlError: {
            "&>*>svg>path": {
                fill: theme.palette.error.main,
            },
        },
        helperText: {
            opacity: 1,
            fontSize: 11,
            position: "absolute",
            zIndex: 1,
            right: 5, // change this if the input size changes
            top: 30, // change this if the input size changes
            padding: "2px 8px",
            borderRadius: 2,
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
            margin: "4px 14px 0",
            "&::after": {
                content: "' '",
                display: "block",
                position: "absolute",
                right: 10,
                bottom: "100%",
                width: 0,
                height: 0,
                border: "4px solid transparent",
                borderBottomColor: theme.palette.error.main,
            },
        },
        helperTextHidden: {
            display: "none",
        },
        popover: {
            background: theme.palette.grey[POPOVER_BACKGROUND_COLOR],
            width: (props) => props.width,
        },
        menuItem: {
            opacity: 1,
            textTransform: (props) => (props.displayInUpperCase ? "uppercase" : "none"),
            "&.Mui-selected": {
                backgroundColor: theme.palette.searchInlinePopover.hover,
                // background: "#e5ecf2",
                "&>$checkIcon": {
                    display: "inline-block",
                },
            },
            "&:hover": {
                backgroundColor: theme.palette.searchInlinePopover.hover,
            },
            "&.Mui-selected:hover": {
                backgroundColor: theme.palette.searchInlinePopover.hover,
            },
            "& div span": {
                textOverflow: "ellipsis",
                overflow: "hidden",
            },
            "& svg": {
                overflow: "initial",
            },
        },
        checkIcon: {
            marginLeft: theme.spacing(2),
            display: "none",
        },
        errorAdornment: {
            position: "absolute",
            right: theme.spacing(6),
        },
    };
});
