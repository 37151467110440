import React from "react";

const SpeakerIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="nonzero"
                d="M13.377 3.218A1 1 0 0115 4v16a1 1 0 01-1.625.78L8.65 17H7a4.999 4.999 0 01-4.995-4.783L2 12a5 5 0 015-5h1.626zm-.378 2.857L9.601 8.782A1 1 0 018.978 9H7a3 3 0 100 6h2c.227 0 .447.077.625.22l3.374 2.699V6.075zm8.087.567a10.01 10.01 0 01-.017 10.744 1 1 0 01-1.685-1.078 8.013 8.013 0 00.014-8.594 1 1 0 011.688-1.072zm-3 1.654a7.012 7.012 0 01-.058 7.498 1 1 0 01-1.68-1.085 5.007 5.007 0 00.04-5.353 1 1 0 011.697-1.06z"
            ></path>
        </svg>
    );
};

export default SpeakerIcon;
