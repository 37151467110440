import React from "react";
import { Grid } from "@material-ui/core";
import HelpDocsCard from "../HelpDocsCard";
import PushDiagnostics from "../PushDiagnostics";
import { useStyles } from "./StartTabComponent.styles";
import ContactSupport from "../ContactSupport";
import SystemLogs from "../SystemLogs";
import { useObserver } from "mobx-react-lite";
import LoggingLevelCard from "pages/Advanced/components/LoggingLevelCard/LoggingLevelCard";
import ViewByRole from "components/ViewByRole";
import { ADMIN } from "const/userRolesConst";

const StartTabComponent = () => {
    const classes = useStyles();
    return useObserver(() => (
        <Grid container spacing={6} className={classes.container}>
            <Grid item xs={12} lg={6}>
                <ContactSupport />
            </Grid>
            <Grid item xs={12} lg={6}>
                <HelpDocsCard />
            </Grid>
            <ViewByRole roles={ADMIN}>
                <Grid item xs={12} lg={6}>
                    <SystemLogs />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <LoggingLevelCard />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PushDiagnostics />
                </Grid>
            </ViewByRole>
        </Grid>
    ));
};

export default StartTabComponent;
