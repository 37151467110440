import * as React from "react";

const CheckAnimatedIcon = props => {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
            <style>
                {
                    "@keyframes uoj2ykiavmdm4rvji3ypiglh_do{0%{stroke-dashoffset:-30px;animation-timing-function:cubic-bezier(.4,0,.6,1)}to{stroke-dashoffset:0}}"
                }
            </style>
            <title>{"Check"}</title>
            <path
                d="M4.5 12L9 18 21 6"
                stroke="#21c97e"
                strokeWidth={2}
                fill="none"
                strokeLinecap="round"
                strokeDasharray="100 100"
                strokeDashoffset={-30}
                style={{
                    animation: ".4s linear both uoj2ykiavmdm4rvji3ypiglh_do"
                }}
            />
        </svg>
    );
};

export default CheckAnimatedIcon;
