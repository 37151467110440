import React from "react";
import PicDiskIcon from "assets/PicDiskIcon";
import InfoCard from "components/InfoCard";
import { useStyles } from "./RecoverConfigurationCard.styles";
import { useTranslation } from "react-i18next";
import localizations from "./RecoverConfigurationCard.localizations";
import LocalizationTypography from "components/LocalizationTypography/LocalizationTypography";

const RecoverConfigurationCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const localizationRecoverConfigurationContent = (
        <LocalizationTypography
            className={classes.content}
            component={"span"}
            paragraphs={localizations.recoverConfiguration.paragraphs}
        />
    );

    return (
        <InfoCard
            iconMargin={54}
            icon={<PicDiskIcon />}
            title={t(localizations.recoverConfiguration.titleKey)}
            content={localizationRecoverConfigurationContent}
        />
    );
};

export default RecoverConfigurationCard;
