import React from "react";

const SupportIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="nonzero"
                d="M9 2a8 8 0 00-7.23 4.571l-.043.106A1 1 0 002.674 8h5.79l1.333 2-1.333 2h-5.79a1 1 0 00-.903 1.43A7.998 7.998 0 009 18l.353-.008a7.99 7.99 0 002.056-.361l.099-.035 4.236 3.213-.06-.056c1.457 1.456 3.952 1.182 5.593-.46 1.641-1.64 1.916-4.136.46-5.592l-.103-.09-4.703-3.569.005-.025A8 8 0 009 2zm0 2a6 6 0 015.867 7.263l-.019.127c-.028.338.118.67.392.88l5.112 3.879-.03-.034c.598.598.461 1.844-.459 2.765-.874.874-2.043 1.041-2.67.543l-.197-.173-4.714-3.577a1 1 0 00-.987-.127A5.983 5.983 0 019 16l-.25-.005a5.992 5.992 0 01-4.075-1.835L4.526 14H9a1 1 0 00.832-.445l2-3a1 1 0 000-1.11l-2-3-.075-.099A1.001 1.001 0 009 6H4.527l.147-.16A5.997 5.997 0 019 4z"
            ></path>
        </svg>
    );
};

export default SupportIcon;
