import React, { useEffect, useContext } from "react";
import { useObserver } from "mobx-react";
import { useStyles } from "./ActivatedKeysCard.styles";
import { Paper, Divider, Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";
import CardHeader from "components/CardHeader";
import TableSortLabel from "components/TableSortLabel";
import { Context } from "store";
import { ACTIVATED_KEYS_ID, ACTIVATED_KEYS_TYPE } from "const/sortColumnConst";
import { useTranslation } from "react-i18next";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { ACTIVATED_KEYS_CARD } from "const/widgetNameConst";
import { HARDWARE_ACTIVATION_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const ACTIVATED_KEYS_CARD_ID_PREFIX = `${HARDWARE_ACTIVATION_PAGE_ID_PREFIX}_activated_keys_card`;

const ActivatedKeysCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        store: { activatedKeysStore }
    } = useContext(Context);

    useEffect(() => {
        activatedKeysStore.fetchActivatedKeys();
    }, []);

    const changeSorting = column => () => activatedKeysStore.changeSorting(column);

    return useObserver(() => (
        <Paper className={classes.container}>
            <CardHeader
                title={t("hardware_activation.activated_keys.title")}
                control={
                    <MoreMenu id={`${ACTIVATED_KEYS_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={ACTIVATED_KEYS_CARD} />
                    </MoreMenu>
                }
            />
            <Divider />
            {activatedKeysStore.activatedKeys?.length ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    direction={activatedKeysStore.order}
                                    onClick={changeSorting(ACTIVATED_KEYS_ID)}
                                    active={activatedKeysStore.orderBy === ACTIVATED_KEYS_ID}
                                >
                                    {t("hardware_activation.activated_keys.table_header.key_id")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    direction={activatedKeysStore.order}
                                    onClick={changeSorting(ACTIVATED_KEYS_TYPE)}
                                    active={activatedKeysStore.orderBy === ACTIVATED_KEYS_TYPE}
                                >
                                    {t("hardware_activation.activated_keys.table_header.key_type")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {activatedKeysStore.sortedActivatedKeys.map(activatedKey => (
                            <TableRow key={activatedKey.key}>
                                <TableCell>{activatedKey.key}</TableCell>
                                <TableCell>{activatedKey.type}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyCardPlaceholder message={t("hardware_activation.activated_keys.table.placeholder")} />
            )}
        </Paper>
    ));
};

export default ActivatedKeysCard;
