import React from "react";
import MainTemplate from "components/MainTemplate";
import RecycleTable from "./components/RecycleTable";
import HeaderTitle from "components/HeaderTitle";
import { useTranslation } from "react-i18next";
import TrashIcon from "assets/TrashIcon";
import { Grid } from "@material-ui/core";
import { RECYCLE_BIN } from "const/shortcutNameConst";

const RecycleBin = () => {
    const { t } = useTranslation();

    return (
        <MainTemplate
            headerTitle={<HeaderTitle icon={<TrashIcon />} title={t("recycle_bin.title")} shortcutName={RECYCLE_BIN} />}
        >
            <Grid container wrap={"nowrap"}>
                <Grid item xs={12}>
                    <RecycleTable />
                </Grid>
            </Grid>
        </MainTemplate>
    );
};

export default RecycleBin;
