import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import createFields from "./createFields";
import { useLocalStore } from "mobx-react";
import createForm from "utils/createForm";
import {
    Box,
    Button,
    ButtonBase,
    Grid,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import { useObserver } from "mobx-react-lite";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import FormControlLabel from "components/FormControlLabel/FormControlLabel";
import { default as Switch } from "components/MobxForm/Switch";
import { default as MuiSwitch } from "components/Switch/Switch";
import { useStyles } from "./EditAutomationModal.styles";
import Select from "components/MobxForm/Select";
import {
    FREQUENCY_OPTIONS,
    getCreateAutomationEmailTask,
    MIN_DEPTH,
    RADIO_VALUE_EXTENSION,
    RADIO_VALUE_REGEXP,
} from "const/createAutomationsConst";
import { TASKS_OPTIONS, TASKS_SOURCE, EMAIL_TASK } from "const/automationTaskConst";
import CalendarIcon from "assets/CalendarIcon";
import MuiTextInput from "components/TextInput";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import RadioGroup from "components/MobxForm/RadioGroup";
import Radio from "components/Radio/Radio";
import PlusIcon from "assets/PlusIcon";
import TrashIcon from "assets/TrashIcon";
import EditIcon from "assets/EditIcon";
import ChevronUpIcon from "assets/ChevronUpIcon";
import ChevronDownIcon from "assets/ChevronDownIcon";
import getTimeUnit from "./utils/getTimeUnit";
import parseFilterRules from "./utils/parseFilterRules";
import parseUrl from "./utils/parseUrl";
import { SLINGSHOT_DATE_FORMAT_FOR_SERVER } from "const/createJobConst";
import { SCHEMA_OPTIONS, S3_SCHEMA, S3_CUSTOM_SCHEMA } from "const/slingshotAliasConst";
import FolderBrowser from "../FolderBrowser";
import Checkbox from "components/Checkbox";
import CreateEditTaskModal from "../CreateEditTaskModal/CreateEditTaskModal";
import clsx from "clsx";
import { getSnapshot } from "mobx-state-tree";
import automationRule from "utils/automationRule";
import { URL_ALERTS } from "routes";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";
import { toJS } from "mobx";
import { SLINGSHOT_DATE_HOURS_FORMAT } from "const";
import { ADMIN } from "const/userRolesConst";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const EDIT_AUTOMATION_MODAL_ID_PREFIX = `edit_automation_modal`;

const EditAutomationModal = ({ onClose, open, afterSubmitCallback, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${EDIT_AUTOMATION_MODAL_ID_PREFIX}` : EDIT_AUTOMATION_MODAL_ID_PREFIX;
    const { t } = useTranslation();
    const classes = useStyles();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
            rules: automationRule,
        }),
        isSubmitted: false,
        isPickerOpen: false,
        isNewTask: false,
        path: "",
        get isMainCheckboxIndeterminate() {
            if (
                automationTasksStore.availableTasks &&
                automationTasksStore.checkedTasks.length > 0 &&
                automationTasksStore.checkedTasks.length < automationTasksStore.availableTasks.length
            ) {
                return true;
            }
            return false;
        },
        get isMainCheckboxChecked() {
            if (
                automationTasksStore.availableTasks &&
                automationTasksStore.checkedTasks.length > 0 &&
                automationTasksStore.checkedTasks.length === automationTasksStore.availableTasks.length
            ) {
                return true;
            }
            return false;
        },
    }));

    const { unsavedChangesModal, folderBrowserModal, createEditTaskModal } = useModalCombine([
        "unsavedChangesModal",
        "folderBrowserModal",
        "createEditTaskModal",
    ]);

    const {
        store: { slingshotStorageStore, automationsStore, automationTasksStore, authStore, emailConfigurationStore },
    } = useStore();

    useEffect(() => {
        slingshotStorageStore.fetchShares();
        automationTasksStore.setCurrentAutomationTasks();
    }, []);

    const openFolderBrowser = () => {
        folderBrowserModal.open();
        state.path = state.form.$("folder").value;
    };

    const onFolderChoose = (value) => {
        state.form.$("folder").set("value", value);
    };

    const openCreateEditTaskDialog = (taskSequence) => () => {
        automationTasksStore.setCurrentTaskSequence(taskSequence);
        createEditTaskModal.open();
        state.isNewTask = false;
    };

    const openNewCreateEditTaskDialog = () => {
        createEditTaskModal.open();
        state.isNewTask = true;
    };

    const closeCreateEditTaskDialog = () => {
        createEditTaskModal.close();
        state.isNewTask = false;
    };

    const updateForm = () => {
        const tasks = automationsStore.currentAutomation?.tasks;
        const emailTask = automationsStore.currentAutomation?.tasks.find((task) => task.action?.action === EMAIL_TASK);
        automationTasksStore.setCurrentAutomationTasks(tasks);
        const { timeUnit, frequency } = getTimeUnit(automationsStore.currentAutomation?.schedule?.schedule?.minutes);
        const { useRegexp, extensions, regexp, url } = parseFilterRules(automationsStore.currentAutomation?.filter);
        const { share, folder } = parseUrl(url);
        const startAt = automationsStore.currentAutomation?.schedule?.schedule?.date
            ? moment(automationsStore.currentAutomation.schedule.schedule.date)
            : moment().add(1, "hour").minute(0);
        state.form.$("name").set("default", automationsStore.currentAutomation?.name || "");
        state.form.$("scheduled").set("default", automationsStore.currentAutomation?.schedule ? true : false);
        state.form
            .$("frequencyUnit")
            .set("default", automationsStore.currentAutomation?.schedule ? timeUnit : FREQUENCY_OPTIONS[1].value);
        state.form.$("frequency").set("default", automationsStore.currentAutomation?.schedule ? frequency : 1);
        state.form.$("startAt").set("default", startAt);
        state.form.$("query").set("default", useRegexp ? RADIO_VALUE_REGEXP : RADIO_VALUE_EXTENSION);
        state.form.$("fileExtension").set("default", extensions);
        state.form.$("regularExpression").set("default", regexp);
        state.form.$("share").set("default", share);
        state.form.$("folder").set("default", (folder && decodeURIComponent(folder)) || "");
        state.form
            .$("notify")
            .set(
                "default",
                emailConfigurationStore.emailSettings?.emailsTo &&
                    emailConfigurationStore.emailSettings?.emailsTo[0] !== "" &&
                    emailTask?.enabled
                    ? emailTask?.enabled
                    : false
            );
        state.form.$("recipient").set("default", emailTask?.arguments.recipients);
        state.form.reset();
        state.isSubmitted = false;
        unsavedChangesModal.close();
        state.form.fields.forEach((field) => field.resetValidation());
        automationTasksStore.clearCheckedTasks();
    };

    const handleEmailTask = () => {
        if (!state.form.$("notify").value) return null;
        // if (automationTasksStore.emailTask) return automationTasksStore.replaceEmailTask();

        automationTasksStore.addEmailTask(
            getCreateAutomationEmailTask(
                state.form.$("recipient").value,
                automationsStore.currentAutomation?.id,
                automationTasksStore.tasks.length
            )
        );
    };

    const onSave = async (e) => {
        state.form.onSubmit(e);
        handleEmailTask();
        if (state.form.$("query").value === RADIO_VALUE_EXTENSION) {
            state.form.$("regularExpression").resetValidation();
        }
        if (state.form.$("query").value === RADIO_VALUE_REGEXP) {
            state.form.$("fileExtension").resetValidation();
        }
        if (!state.form.isValid) return;

        const encodedFilterURL = [state.form.$("share").value, state.form.$("folder").value].join("/");
        const automation = { ...automationsStore.currentAutomation };
        automation.name = state.form.$("name").value;

        if (state.form.$("scheduled").value) {
            let minutes;
            switch (state.form.$("frequencyUnit").value) {
                case FREQUENCY_OPTIONS[2].value: {
                    minutes = parseInt(state.form.$("frequency").value) * 60 * 24; //day
                    break;
                }
                case FREQUENCY_OPTIONS[1].value: {
                    minutes = parseInt(state.form.$("frequency").value) * 60; //hour
                    break;
                }
                case FREQUENCY_OPTIONS[0].value: {
                    minutes = parseInt(state.form.$("frequency").value);
                    break;
                }
                default:
                    minutes = 0;
            }

            automation.schedule = {
                schedule: {
                    minutes: minutes,
                    date: moment(state.form.$("startAt").value).format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
                },
            };
        } else {
            automation.schedule = null;
        }

        /* Fill filters */
        const filter = {
            id: automation.filter?.id || null,
            rules: [],
        };

        if (state.form.$("query").value === RADIO_VALUE_EXTENSION) {
            const extensionsArr = state.form.$("fileExtension").value.trim().split(/[ ,]+/);
            for (let i = 0; i < extensionsArr.length; i++) {
                const rule = {
                    file_type: "F",
                    regexp: false,
                    case_sensitive: false,
                    name_mask: extensionsArr[i],
                    black: false,
                    id: null,
                    depth: MIN_DEPTH,
                    priority: 0,
                    url: encodedFilterURL,
                    watch_mask: 0x00000fcc,
                };
                filter.rules.push(rule);
            }
        } else {
            const rule = {
                file_type: "F",
                regexp: true,
                case_sensitive: false,
                name_mask: state.form.$("regularExpression").value,
                black: false,
                id: null,
                depth: MIN_DEPTH,
                priority: 0,
                url: encodedFilterURL,
                watch_mask: 0x00000fcc,
            };
            filter.rules.push(rule);
        }

        automation.filter = filter;
        await automationTasksStore.checkTasksSequence();
        automation.tasks = [...getSnapshot(automationTasksStore.tasks)];
        automation.last_instance = toJS(automation.last_instance);
        const res = await automationsStore.updateAutomation(automation);
        if (res) state.isSubmitted = true;
        return res;
    };

    const openPicker = () => {
        state.isPickerOpen = true;
    };
    const closePicker = () => {
        state.isPickerOpen = false;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    const getTaskName = (action) => {
        switch (action) {
            case TASKS_OPTIONS.copy:
                return t("slingshot.automation.task.tasks_options.copy");
            case TASKS_OPTIONS.transcode:
                return t("slingshot.automation.task.tasks_options.transcode");
            case TASKS_OPTIONS.delete:
                return t("slingshot.automation.task.tasks_options.delete");
            case TASKS_OPTIONS.tricaster:
                return t("slingshot.automation.task.tasks_options.tricaster_streaming");
            case TASKS_OPTIONS.move:
                return t("slingshot.automation.task.tasks_options.move");
            case TASKS_OPTIONS.automation:
                return t("slingshot.automation.task.tasks_options.append_automation");
            case TASKS_OPTIONS.autotagging:
                return t("slingshot.automation.task.tasks_options.aiml_autotagging");
            default:
                return t("slingshot.automation.task.tasks_options.move");
        }
    };

    const getDestination = (taskArguments) => {
        if (typeof taskArguments.destination === "object") {
            let divider = "://";
            if (taskArguments.request?.decomposed_to?.schema === SCHEMA_OPTIONS.s3) {
                taskArguments.request?.decomposed_to?.region && (divider = divider + S3_SCHEMA);
                !taskArguments.request?.decomposed_to?.region && (divider = divider + S3_CUSTOM_SCHEMA);
            }
            const url = [];
            taskArguments.request?.decomposed_to?.host && url.push(taskArguments.request?.decomposed_to?.host);
            taskArguments.request?.decomposed_to?.resource && url.push(taskArguments.request?.decomposed_to?.resource);
            taskArguments.request?.decomposed_to?.path && url.push(taskArguments.request?.decomposed_to?.path);
            return taskArguments.request?.decomposed_to?.schema + divider + url.map((el) => el.replace(/^\/|\/$/g, "")).join("/");
        }
        return decodeURIComponent(taskArguments.destination) || "-";
    };

    const isCheckedTask = (taskId) => {
        return automationTasksStore.checkedTasks?.some((task) => task === taskId);
    };

    const onCheckboxChange = (taskId) => () => {
        isCheckedTask(taskId) ? automationTasksStore.removeCheckedTask(taskId) : automationTasksStore.addCheckedTask(taskId);
    };

    const handleMainCheckbox = () => {
        if (state.isMainCheckboxChecked) {
            automationTasksStore.availableTasks.forEach((task) =>
                automationTasksStore.removeCheckedTask(task.sequence)
            );
        } else {
            automationTasksStore.availableTasks.forEach((task) => automationTasksStore.addCheckedTask(task.sequence));
        }
    };

    const handleTaskEnable = (taskIndex) => () => {
        automationTasksStore.enableTask(taskIndex);
    };

    const deleteCheckedTasks = () => {
        automationTasksStore.deleteTasks();
    };

    const moveUp = (taskId) => () => {
        automationTasksStore.moveUp(taskId);
        state.isSubmitted = false;
    };
    const moveDown = (taskId) => () => {
        automationTasksStore.moveDown(taskId);
        state.isSubmitted = false;
    };

    const handleNotify = (e) => {
        state.form.$("notify").set("value", e.currentTarget.checked);
        automationTasksStore.emailTask && automationTasksStore.toggleEmailTask();
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return useObserver(() => (
        <Dialog
            afterSubmitCallback={afterSubmitCallback}
            maxWidth="lg"
            title={t("slingshot.automations.edit_automation.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            open={open}
            onSubmit={onSave}
            onEnter={updateForm}
            onClose={closeIfNeeded}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
            hide={folderBrowserModal.isOpen || createEditTaskModal.isOpen}
        >
            <Grid container direction={"column"} spacing={2}>
                <RowTextInput
                    leftXs={4}
                    rightXs={8}
                    label={t("slingshot.automations.create_automation.label.name")}
                    control={<TextInput field={state.form.$("name")} id={`${idPrefix}_name`} />}
                />
                <Grid item container>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot.automations.edit_automation.label.id")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography gutterBottom>{automationsStore.currentAutomation?.id}</Typography>
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot.automations.edit_automation.label.owner")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{automationsStore.currentAutomation?.owner}</Typography>
                    </Grid>
                </Grid>
                <RowTextInput
                    leftXs={4}
                    rightXs={8}
                    label={t("slingshot.automations.create_automation.label.recipients")}
                    control={<TextInput field={state.form.$("recipient")} id={`${idPrefix}_recipient`} />}
                />
                <Grid item container justify="flex-end">
                    <Grid item container direction="column" xs={8}>
                        <Grid item>
                            <Box pt={2} pb={4}>
                                <FormControlLabel
                                    className={classes.formControlLabel}
                                    label={t("slingshot.automations.create_automation.label.notify")}
                                    control={
                                        <Switch
                                            disabled={
                                                emailConfigurationStore.emailSettings?.emailsTo &&
                                                emailConfigurationStore.emailSettings?.emailsTo[0] === ""
                                            }
                                            onChange={handleNotify}
                                            className={classes.switchControlLabel}
                                            field={state.form.$("notify")}
                                            id={`${idPrefix}_notify`}
                                        />
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid container item>
                            <Grid item>
                                {emailConfigurationStore.emailSettings?.emailsTo &&
                                emailConfigurationStore.emailSettings?.emailsTo[0] !== "" ? (
                                    authStore.role === ADMIN && (
                                        <Typography>
                                            <Trans
                                                i18nKey={"slingshot.automations.create_automation.label.configure_email.enabled"}
                                                components={<Link className={classes.link} to={URL_ALERTS} />}
                                            />
                                        </Typography>
                                    )
                                ) : authStore.role === ADMIN ? (
                                    <Typography>
                                        <Trans
                                            i18nKey={
                                                "slingshot.automations.create_automation.label.configure_email.disabled.admin"
                                            }
                                            components={<Link className={classes.link} to={URL_ALERTS} />}
                                        />
                                    </Typography>
                                ) : (
                                    <Typography>
                                        {t("slingshot.automations.create_automation.label.configure_email.disabled")}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <RowTextInput
                    leftXs={4}
                    rightXs={8}
                    label={t("slingshot.automations.create_automation.label.share")}
                    control={
                        <Select
                            field={state.form.$("share")}
                            options={slingshotStorageStore.shares.map((share) => share.name)}
                            id={`${idPrefix}_share`}
                        />
                    }
                />
                <Grid item container wrap="nowrap" alignItems={"baseline"}>
                    <Grid item xs={4}>
                        <Typography>{t("slingshot.automations.create_automation.label.folder")}</Typography>
                    </Grid>
                    <Grid item container xs={8} spacing={4} wrap="nowrap">
                        <Grid item container>
                            <TextInput field={state.form.$("folder")} id={`${idPrefix}_folder`} />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!state.form.$("share").value}
                                onClick={openFolderBrowser}
                                id={`${idPrefix}_open_folder_browser`}
                            >
                                {t("common.button.browse")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container wrap="nowrap">
                    <Grid item xs={4}>
                        <Typography>{t("slingshot.automations.create_automation.label.launch")}</Typography>
                    </Grid>
                    <Grid xs={5} item container direction="column">
                        <FormControlLabel
                            className={classes.formControlLabel}
                            label={t("slingshot.automations.create_automation.label.scheduled")}
                            control={
                                <Switch
                                    className={classes.switchControlLabel}
                                    field={state.form.$("scheduled")}
                                    id={`${idPrefix}_scheduled`}
                                />
                            }
                        />
                        <Box pt={4} pb={2} width="100%">
                            <Grid container wrap="nowrap" spacing={4}>
                                <Grid item container alignItems="center" xs={4}>
                                    <Typography>{t("slingshot.automations.create_automation.label.frequency")}</Typography>
                                </Grid>
                                <Grid item container xs={4}>
                                    <TextInput
                                        field={state.form.$("frequency")}
                                        disabled={!state.form.$("scheduled").value}
                                        id={`${idPrefix}_frequency`}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        field={state.form.$("frequencyUnit")}
                                        disabled={!state.form.$("scheduled").value}
                                        options={FREQUENCY_OPTIONS}
                                        id={`${idPrefix}_frequency_unit`}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box width="100%" pb={10}>
                            <Grid container wrap="nowrap">
                                <Grid item container xs={3} alignItems="center">
                                    <Typography>{t("slingshot.automations.create_automation.label.start_at")}</Typography>
                                </Grid>
                                <Grid item container justify="flex-end">
                                    <DateTimePicker
                                        className={classes.picker}
                                        open={state.isPickerOpen}
                                        onOpen={openPicker}
                                        onClose={closePicker}
                                        value={state.form.$("startAt").value}
                                        onChange={state.form.$("startAt").onChange}
                                        TextFieldComponent={MuiTextInput}
                                        inputVariant="outlined"
                                        format={getLanguageDateFormat(SLINGSHOT_DATE_HOURS_FORMAT)}
                                        variant="dialog"
                                        minutesStep={5}
                                        DialogProps={{
                                            className: classes.dateTimePickerDialogStyles,
                                        }}
                                        disabled={!state.form.$("scheduled").value}
                                        id={`${idPrefix}_start_at`}
                                    />
                                </Grid>
                                <Grid item>
                                    <Box pl={2} height="100%" display="flex" alignItems="center">
                                        <ButtonBase
                                            onClick={openPicker}
                                            disabled={!state.form.$("scheduled").value}
                                            id={`${idPrefix}_open_picker`}
                                        >
                                            <CalendarIcon />
                                        </ButtonBase>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item xs={4}>
                        <Typography gutterBottom>{t("slingshot.automations.create_automation.label.query")}</Typography>
                        <Typography className={classes.helper} gutterBottom>
                            {t("slingshot.automations.create_automation.label.query.helper")}
                        </Typography>
                    </Grid>
                    <Grid item container direction="column" xs={8}>
                        <RadioGroup field={state.form.$("query")}>
                            <Box width="100%" pb={3}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={4}>
                                        <Radio
                                            value={RADIO_VALUE_EXTENSION}
                                            label={t("slingshot.automations.create_automation.label.file_extensions")}
                                            id={`${idPrefix}_radio_file_extensions`}
                                        />
                                    </Grid>

                                    <Grid item container xs={8}>
                                        <TextInput
                                            placeholder={t(
                                                "slingshot.automations.create_automation.label.file_extensions.placeholder"
                                            )}
                                            field={state.form.$("fileExtension")}
                                            disabled={state.form.$("query").value !== RADIO_VALUE_EXTENSION}
                                            id={`${idPrefix}_file_extensions`}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Grid container wrap="nowrap">
                                <Grid item xs={4}>
                                    <Radio
                                        value={RADIO_VALUE_REGEXP}
                                        label={t("slingshot.automations.create_automation.label.regular_expression")}
                                        id={`${idPrefix}_radio_regular_expression`}
                                    />
                                </Grid>

                                <Grid item container xs={8}>
                                    <TextInput
                                        placeholder={t(
                                            "slingshot.automations.create_automation.label.regular_expression.placeholder"
                                        )}
                                        field={state.form.$("regularExpression")}
                                        disabled={state.form.$("query").value === RADIO_VALUE_EXTENSION}
                                        id={`${idPrefix}_regular_expression`}
                                    />
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </Grid>
                </Grid>

                {!automationTasksStore.availableTasks.length ? (
                    <>
                        <Box width="100%">
                            <Grid container>
                                <Typography variant="subtitle2">
                                    {t("slingshot.automations.edit_automation.label.tasks")}
                                </Typography>
                            </Grid>
                        </Box>
                        <Box pt={8} pb={10} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Typography paragraph variant="subtitle1">
                                {t("slingshot.automations.edit_automation.label.tasks.placeholder")}
                            </Typography>
                            <Button
                                onClick={openNewCreateEditTaskDialog}
                                startIcon={<PlusIcon />}
                                variant="contained"
                                color="secondary"
                                id={`${idPrefix}_open_new_create_edit_task_dialog`}
                            >
                                {t("slingshot.automations.edit_automation.button.create_task")}
                            </Button>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box width="100%" pb={4}>
                            <Grid container wrap="nowrap" alignItems="baseline">
                                <Typography variant="subtitle2">
                                    {t("slingshot.automations.edit_automation.label.tasks")}
                                </Typography>
                                <Grid container item wrap="nowrap" justify={"flex-end"}>
                                    <Box p={1}>
                                        <ButtonBase
                                            className={classes.errorBtn}
                                            onClick={deleteCheckedTasks}
                                            id={`${idPrefix}_delete_checked_tasks`}
                                        >
                                            <TrashIcon />
                                        </ButtonBase>
                                    </Box>
                                    <Box p={1}>
                                        <ButtonBase
                                            onClick={openNewCreateEditTaskDialog}
                                            id={`${idPrefix}_open_new_create_edit_task_dialog`}
                                        >
                                            <PlusIcon />
                                        </ButtonBase>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mx={-5}>
                            <Divider />
                        </Box>
                        <Box pb={10} mx={-5} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                indeterminate={state.isMainCheckboxIndeterminate}
                                                checked={state.isMainCheckboxChecked}
                                                onChange={handleMainCheckbox}
                                                id={`${idPrefix}_main_checkbox`}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={"subtitle1"}>
                                                {t("slingshot.automations.edit_automation.tasks_table.order")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={"subtitle1"}>
                                                {t("slingshot.automation.task.label.task")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={"subtitle1"}>
                                                {t("slingshot.automation.task.label.source")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={"subtitle1"}>
                                                {t("slingshot.automations.edit_automation.tasks_table.destination")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={"subtitle1"}>
                                                {t("slingshot.automations.edit_automation.tasks_table.enabled")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {automationTasksStore.availableTasks?.map((task, index) => {
                                        return (
                                            // TODO  Do not use Array index in keys
                                            // eslint-disable-next-line react/no-array-index-key
                                            <TableRow key={`"automationTask"${index}${task.id}`}>
                                                <TableCell width={"10%"}>
                                                    <Checkbox
                                                        onClick={stopPropagation}
                                                        onChange={onCheckboxChange(task.sequence)}
                                                        checked={isCheckedTask(task.sequence)}
                                                        id={`${idPrefix}_automation_task_checkbox_${task.id}`}
                                                    />
                                                </TableCell>
                                                <TableCell width={"10%"}>
                                                    <Grid container wrap={"nowrap"} alignItems={"center"}>
                                                        <Box p={1}>{index + 1}</Box>
                                                        <Grid direction={"column"} container>
                                                            <Grid container item>
                                                                <ButtonBase
                                                                    disabled={!index}
                                                                    className={clsx(!index && classes.disabled)}
                                                                    onClick={moveUp(task.sequence)}
                                                                    id={`${idPrefix}_automation_task_move_up_${task.id}`}
                                                                >
                                                                    <ChevronUpIcon />
                                                                </ButtonBase>
                                                            </Grid>
                                                            <Grid container item>
                                                                <ButtonBase
                                                                    disabled={
                                                                        index === automationTasksStore.availableTasks?.length - 1
                                                                    }
                                                                    className={clsx(
                                                                        classes.downIcon,
                                                                        index ===
                                                                            automationTasksStore.availableTasks?.length - 1 &&
                                                                            classes.disabled
                                                                    )}
                                                                    onClick={moveDown(task.sequence)}
                                                                    id={`${idPrefix}_automation_task_move_down_${task.id}`}
                                                                >
                                                                    <ChevronDownIcon />
                                                                </ButtonBase>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell width={"25%"}>
                                                    {getTaskName(task?.action?.action || task?.group_action)}
                                                </TableCell>
                                                <TableCell width={"20%"}>
                                                    {task?.datasets[0]?.dataset_type === TASKS_SOURCE[0].value
                                                        ? t("slingshot.automation.task.source_options.original")
                                                        : t("slingshot.automation.task.source_options.previous_task")}
                                                </TableCell>
                                                <TableCell width={"20%"}>{getDestination(task.arguments)}</TableCell>
                                                <TableCell width={"10%"}>
                                                    <MuiSwitch
                                                        checked={task.enabled}
                                                        onChange={handleTaskEnable(task.sequence)}
                                                        id={`${idPrefix}_automation_task_enabled_${task.id}`}
                                                    />
                                                </TableCell>
                                                <TableCell width={"5%"} align={"right"}>
                                                    <ButtonBase
                                                        onClick={openCreateEditTaskDialog(task.sequence)}
                                                        id={`${idPrefix}_automation_task_open_edit_dialog_${task.id}`}
                                                    >
                                                        <EditIcon />
                                                    </ButtonBase>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </>
                )}
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            <FolderBrowser
                open={folderBrowserModal.isOpen}
                onClose={folderBrowserModal.close}
                onChoose={onFolderChoose}
                share={state.form.$("share").value}
                parentName={t("slingshot.automations.edit_automation.title")}
                pathValue={state.path}
                parentId={idPrefix}
            />
            <CreateEditTaskModal
                open={createEditTaskModal.isOpen}
                onClose={closeCreateEditTaskDialog}
                isCreate={state.isNewTask}
                parentId={idPrefix}
            />
        </Dialog>
    ));
};

export default EditAutomationModal;
