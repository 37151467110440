import { types } from "mobx-state-tree";

import Request from "api/Request";
import EvoIdentity from "api/support_info/Types/EvoIdentity";

const SetIdentity = Request.props({
    path: "/com/studionetworksolutions/evo/SupportInfo",
    method: "set_identity",
    arguments: types.maybe(EvoIdentity)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetIdentity;
