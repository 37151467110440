import { types } from "mobx-state-tree";

import PluginUpdateFile from "api/plugins/Types/PluginUpdateFile";

const ListPluginUpdatesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(PluginUpdateFile)
});

export default ListPluginUpdatesResult;
