import { types } from "mobx-state-tree";

import UpsSettings from "api/power/Types/UpsSettings";

const UpsSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(UpsSettings)
});

export default UpsSettingsResult;
