import { types, flow, getParent, getSnapshot } from "mobx-state-tree";
import Socket from "websocket";
import Plugin from "api/plugins/Types/Plugin";
import ListPluginsResult from "api/plugins/Responses/ListPluginsResult";
import ListPluginUpdatesResult from "api/plugins/Responses/ListPluginUpdatesResult";
import UnpackPluginResult from "api/plugins/Responses/UnpackPluginResult";
import ListPlugins from "api/plugins/Requests/ListPlugins";
import ListPluginUpdates from "api/plugins/Requests/ListPluginUpdates";
import UninstallPlugin from "api/plugins/Requests/UninstallPlugin";
import UnpackPlugin from "api/plugins/Requests/UnpackPlugin";
import InstallPlugin from "api/plugins/Requests/InstallPlugin";
import { SELECT_ARCHIVE, DETAILS, LICENSE, INSTALLING, SUCCEED, FAILED } from "const/installPluginConst";

const PluginsStore = types
    .model({
        installationFailureReason: types.maybeNull(types.string),
        installationStep: types.optional(types.string, SELECT_ARCHIVE),
        archives: types.maybeNull(ListPluginUpdatesResult),
        unpacked: types.maybeNull(UnpackPluginResult),
        selectedPlugin: types.maybeNull(Plugin),
        installedPlugins: types.maybeNull(ListPluginsResult),
        currentPluginName: types.maybeNull(types.string)
    })
    .views(self => ({
        get availableArchives() {
            return self.archives && self.archives.data;
        },
        get isInstalling() {
            return (
                self.installationStep &&
                self.installationStep !== SELECT_ARCHIVE &&
                self.installationStep !== SUCCEED &&
                self.installationStep !== FAILED
            );
        },
        get unpackedMetadata() {
            return self.unpacked && self.unpacked.data;
        },
        get plugins() {
            return self.installedPlugins && self.installedPlugins.data;
        },
        get currentPlugin() {
            return self.currentPluginName && self.plugins.find(plugin => plugin.name === self.currentPluginName);
        }
    }))
    .actions(self => ({
        gotoSelectArchiveStep: () => {
            self.installationStep = SELECT_ARCHIVE;
            self.unpacked = null;
        },
        gotoShowLicenseStep: () => {
            if (self.unpacked) {
                self.installationStep = LICENSE;
            } else {
                throw Error("Attempt to show license without an available plugin metadata");
            }
        },
        fetchAvailableArchives: flow(function*(callback) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ListPluginUpdates.create().init();
                const res = yield Socket.send(req);
                self.archives = res;
                callback && callback();
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        unpackArchive: flow(function*(archiveName, callback) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = UnpackPlugin.create().init({
                    archiveName: archiveName
                });
                const res = yield Socket.send(req);
                self.unpacked = res;
                self.installationStep = DETAILS;
                callback && callback();
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        installPlugin: flow(function*(pluginId, dataLocation, backupLocation) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                self.installationStep = INSTALLING;
                const req = InstallPlugin.create().init({
                    pluginId: pluginId,
                    dataShare: dataLocation || "",
                    backupShare: backupLocation || ""
                });
                yield Socket.send(req);
                self.installationStep = SUCCEED;
                return true;
            } catch (e) {
                self.installationStep = FAILED;
                self.installationFailureReason = e.message || "Unknown";
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchPlugins: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ListPlugins.create().init();
                const res = yield Socket.send(req);
                self.installedPlugins = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updatePlugins: broadcast => {
            if (self.installedPlugins) {
                self.installedPlugins.data = broadcast.data;
            }
        },
        uninstallPlugin: flow(function*(pluginId) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = UninstallPlugin.create().init({
                    pluginId: pluginId
                });
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        selectPlugin: plugin => {
            self.selectedPlugin = getSnapshot(plugin);
        },
        unselectPlugin: () => {
            self.selectedPlugin = null;
        },
        isSelectedPlugin: plugin => {
            return self.selectedPlugin && self.selectedPlugin.id === plugin.id;
        },
        setCurrentPluginName: pluginName => {
            self.currentPluginName = pluginName;
        }
    }));

export default PluginsStore;
