import React from "react";
import { Grid, Paper, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import CardHeader from "components/CardHeader";
import { useStyles } from "./TipsCard.style";
import ChevronDownIcon from "../../../../assets/ChevronDownIcon";
import { useTranslation } from "react-i18next";
import LocalizationTypography from "components/LocalizationTypography/LocalizationTypography";
import localizations from "./TipsCard.localizations";

const TipAccordion = ({ tipLocalization }) => {
    const { t } = useTranslation();

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ChevronDownIcon />}>
                <Grid item>
                    <Typography variant={"subtitle2"}>{t(tipLocalization.titleKey)}</Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid item>
                    <LocalizationTypography paragraphs={tipLocalization.paragraphs} />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
const TipsCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper>
            <CardHeader title={t("quick_start.tips_card.title")} />
            <Grid container direction={"column"} spacing={3} className={classes.contentContainer}>
                <TipAccordion tipLocalization={localizations.perfomanceAndTuning} />
                <TipAccordion tipLocalization={localizations.driveReplacement} />
                <TipAccordion tipLocalization={localizations.properShutdown} />
            </Grid>
        </Paper>
    );
};

export default TipsCard;
