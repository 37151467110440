import { types } from "mobx-state-tree";

import NetworkInterface from "api/evo_info/Types/NetworkInterface";

const NetworkInterfacesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(NetworkInterface)
});

export default NetworkInterfacesResult;
