import { makeStyles } from "@material-ui/core/styles";

const PAPER_WIDTH = 520;
const LOGO_TOP_PADDING = 160;
export const useStyles = makeStyles((theme) => ({
    card: {
        width: PAPER_WIDTH,
        marginBottom: theme.spacing(10),
        overflow: "hidden",
    },
    container: {
        backgroundColor: "#34373e",
        minHeight: "100vh",
    },
    logoContainer: {
        paddingTop: LOGO_TOP_PADDING,
        paddingBottom: theme.spacing(16),
    },
    optionLabel: {
        color: theme.palette.grey[500],
        fontSize: 12,
        fontWeight: 300,
    },
    supportButtonContainer: {
        margin: "auto auto 18px 160px",
        maxWidth: 1200,
        boxSizing: "content-box",
        position: "relative",
        height: 40,
        [theme.breakpoints.up("md")]: {
            minWidth: 800,
            margin: "auto auto 18px auto",
        },
    },
}));
