import React from "react";
import { observer, useLocalStore } from "mobx-react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer/DetailsActionsDrawer";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import { useStore } from "hooks/useStore";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@material-ui/core";
import BulkBarFlatButton from "components/BulkBarFlatButton/BulkBarFlatButton";
import { useStyles } from "./SystemDriveStatusDrawer.styles";
import ReportIcon from "assets/ReportIcon";
import QuickStartIcon from "assets/QuickStartIcon";
import DiskIcon from "assets/DiskIcon";
import Dialog from "components/Dialog/Dialog";
import { useModal } from "hooks/useModal";
import { useDebounce } from "hooks/useDebounce";
import clsx from "clsx";

const DRAWER_NAME = "SYSTEM_DRIVE_STATUS_DRAWER";
const SystemDriveStatusDrawer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { systemDriveStore },
    } = useStore();

    useDrawerOpenHandler({
        drawerName: DRAWER_NAME,
        closeDrawer: systemDriveStore.clearSelectedDrive,
        targetDep: systemDriveStore.selectedDrive,
    });

    const smartReportModal = useModal();
    const state = useLocalStore(() => ({
        inProgressFetchingSmartReport: false,
        beginProgressFetchSmartReport() {
            this.inProgressFetchingSmartReport = true;
        },
        endProgressFetchSmartReport() {
            this.inProgressFetchingSmartReport = false;
        },
        inProgressTurnIdentify: false,
        beginProgressTurnIndentify() {
            this.inProgressTurnIdentify = true;
        },
        endProgressTurnIndentify() {
            this.inProgressTurnIdentify = false;
        },
    }));

    const debounceClearSmartReport = useDebounce(systemDriveStore.clearSmartReport, 100, []);

    const handleOpenSmartReportModal = async () => {
        state.beginProgressFetchSmartReport();
        await systemDriveStore.fetchDriveSmartReport();
        debounceClearSmartReport.stop();
        smartReportModal.open();
        state.endProgressFetchSmartReport();
    };
    const handleCloseSmartReportModal = () => {
        smartReportModal.close();
        debounceClearSmartReport.run();

        return true;
    };

    if (systemDriveStore.selectedDrive === null) return null;

    const turnIdentifyOff = async () => {
        state.beginProgressTurnIndentify();
        await systemDriveStore.turnIdentifyOff();
        state.endProgressTurnIndentify();
        smartReportModal.close();
        systemDriveStore.clearSelectedDrive();
    };

    const turnIdentifyOn = async () => {
        state.beginProgressTurnIndentify();
        await systemDriveStore.turnIdentifyOn();
        state.endProgressTurnIndentify();
        smartReportModal.close();
        systemDriveStore.clearSelectedDrive();
    };
    const renderActions = () => {
        return (
            <>
                <Grid direction={"column"} container>
                    <BulkBarFlatButton
                        icon={<ReportIcon />}
                        onClick={handleOpenSmartReportModal}
                        inProgress={state.inProgressFetchingSmartReport}
                    >
                        {t("system_drive_status.get_drive_status.drawer.actions.show_smart_report")}
                    </BulkBarFlatButton>
                    {systemDriveStore.identifyStatusSelectedDrive ? (
                        <BulkBarFlatButton
                            errorColor
                            icon={<QuickStartIcon />}
                            onClick={turnIdentifyOff}
                            inProgress={state.inProgressTurnIdentify}
                        >
                            {t("system_drive_status.get_drive_status.drawer.actions.turn_beacon.off")}
                        </BulkBarFlatButton>
                    ) : (
                        <BulkBarFlatButton
                            icon={<QuickStartIcon />}
                            onClick={turnIdentifyOn}
                            inProgress={state.inProgressTurnIdentify}
                        >
                            {t("system_drive_status.get_drive_status.drawer.actions.turn_beacon.on")}
                        </BulkBarFlatButton>
                    )}
                </Grid>
            </>
        );
    };

    const renderDetails = () => {
        return (
            <>
                <Grid container direction={"column"} className={classes.detailsContainer} spacing={4}>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("system_drive_status.get_drive_status.drawer.details.type")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={clsx(classes.textInfo, classes.typeText)}>
                                {systemDriveStore.selectedDrive.driveType}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("system_drive_status.get_drive_status.drawer.details.model")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={clsx(classes.textInfo, classes.statusText)}>
                                {systemDriveStore.selectedDrive.driveModel}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("system_drive_status.get_drive_status.drawer.details.serial")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={clsx(classes.textInfo, classes.statusText)}>
                                {systemDriveStore.selectedDrive.driveSerial}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <>
            <DetailsActionsDrawer
                title={`${t("system_drive_status.get_drive_status.table.drive_number")} ${systemDriveStore.selectedDrive.drive}`}
                renderActions={renderActions}
                renderDetails={renderDetails}
                closeDrawer={systemDriveStore.clearSelectedDrive}
                drawerName={DRAWER_NAME}
                icon={<DiskIcon />}
            />

            <Dialog
                title={t("system_drive_status.get_drive_status.dialog.title", { drive: systemDriveStore.selectedDrive.drive })}
                onClose={handleCloseSmartReportModal}
                open={smartReportModal.isOpen}
                onSubmit={handleCloseSmartReportModal}
                submitBtnLabel={t("system_drive_status.get_drive_status.dialog.submit")}
                maxWidth={"lg"}
            >
                <Box whiteSpace={"pre-wrap"}>
                    <Typography className={classes.reportText}>{systemDriveStore.smartReport}</Typography>
                </Box>
            </Dialog>
        </>
    );
};

export default observer(SystemDriveStatusDrawer);
