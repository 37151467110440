import { types } from "mobx-state-tree";

import RemoveBricksTask from "api/cluster/Types/RemoveBricksTask";

const RemoveBricksTasksResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(RemoveBricksTask)
});

export default RemoveBricksTasksResult;
