import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import Select from "components/MobxForm/Select";
import createForm from "utils/createForm";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowTextInput from "components/RowTextInput";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { ADVANCED_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const ETHERNET_BONDING_PARAMETERS_CARD_ID_PREFIX = `${ADVANCED_PAGE_ID_PREFIX}_ethernet_bonding_parameters_card`;

const EthernetBondingParametersCard = ({ open, closeDialog }) => {
    const { t } = useTranslation();
    const {
        store: { bondsStore }
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields(bondsStore.bondingParameters) }),
        isSubmitted: false
    }));

    const unsavedChangesModal = useModal();

    const updateForm = () => {
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.form.$("mode").set("default", bondsStore.bondingParameters.mode);
        state.form.$("xmitHashPolicy").set("default", bondsStore.bondingParameters.xmitHashPolicy);
        state.form.$("lacpRate").set("default", bondsStore.bondingParameters.lacpRate);
        state.form.reset();
    };
    const hashPolicyOptions = () => [
        { label: t("advanced.ethernet_bonding_parameters.mac"), value: "layer2" },
        { label: t("advanced.ethernet_bonding_parameters.mac_ip"), value: "layer2+3" }
    ];

    const closeIfNeeded = isSubmitted => {
        // type checking is required. EVENT IS PASSED BY DEFAULT
        if (state.form.isDefault || isSubmitted === true) {
            closeDialog();
            return;
        }

        unsavedChangesModal.open();
    };
    const submit = async () => {
        const res = await bondsStore.setBondingParameters(state.form.values());
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };
    return useObserver(() => (
        <Dialog
            isDataChanged={!state.form.isDefault}
            onEnter={updateForm}
            title={t("advanced.ethernet_bonding_parameters.dialog.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${ETHERNET_BONDING_PARAMETERS_CARD_ID_PREFIX}_save`}
            open={open}
            onSubmit={submit}
            onClose={closeIfNeeded}
            buttons={[
                <Button
                    key="cancelBtn"
                    onClick={closeDialog}
                    variant={"contained"}
                    color="secondary"
                    id={`${ETHERNET_BONDING_PARAMETERS_CARD_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            ]}
        >
            <RowTextInput
                label={t("advanced.ethernet_bonding_parameters.bonding_mode")}
                control={
                    <Select
                        options={[
                            "balance-rr",
                            "active-backup",
                            "balance-xor",
                            "broadcast",
                            "802.3ad",
                            "balance-tlb",
                            "balance-alb"
                        ]}
                        field={state.form.$("mode")}
                        id={`${ETHERNET_BONDING_PARAMETERS_CARD_ID_PREFIX}_bonding_mode`}
                    />
                }
            />

            <RowTextInput
                label={t("advanced.ethernet_bonding_parameters.hash_policy")}
                control={
                    <Select
                        field={state.form.$("xmitHashPolicy")}
                        options={hashPolicyOptions()}
                        id={`${ETHERNET_BONDING_PARAMETERS_CARD_ID_PREFIX}_hash_policy`}
                    />
                }
            />
            <RowTextInput
                label={t("advanced.ethernet_bonding_parameters.lacp_rate")}
                control={
                    <Select
                        field={state.form.$("lacpRate")}
                        options={[t("advanced.ethernet_bonding_parameters.slow"), t("advanced.ethernet_bonding_parameters.fast")]}
                        id={`${ETHERNET_BONDING_PARAMETERS_CARD_ID_PREFIX}_lacp_rate`}
                    />
                }
            />
            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default EthernetBondingParametersCard;
