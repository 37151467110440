import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        padding: theme.spacing(3, 3, 3, 6)
    },
    title: {
        opacity: 0.9,
        fontSize: theme.spacing(3.5)
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        "& svg path": {
            fill: theme.palette.common.white
        }
    }
}));
