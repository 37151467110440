import { types } from "mobx-state-tree";

import StorageUsage from "api/graphs/storageUsageGraph/Types/StorageUsage";

const GetStorageUsageGraphResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(StorageUsage)
});

export default GetStorageUsageGraphResult;
