import React from "react";

const HelpIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#BFD1E1"
                fillRule="evenodd"
                d="M12 2l.28.004C17.673 2.152 22 6.57 22 12l-.004.28C21.848 17.673 17.43 22 12 22 6.477 22 2 17.523 2 12S6.477 2 12 2zm0 2a8 8 0 000 16l.25-.004A8 8 0 0020 12l-.004-.25A8 8 0 0012 4zm.707 12.293a1 1 0 11-1.416 1.414 1 1 0 011.416-1.414zM12 6l.194.004A3.491 3.491 0 0113 12.84V14a1 1 0 01-2 0v-2l.007-.117A1 1 0 0112 11l.144-.007A1.5 1.5 0 1010.5 9.5l-.007.117A1 1 0 018.5 9.5l.005-.192A3.5 3.5 0 0112 6z"
            />
        </svg>
    );
};

export default HelpIcon;
