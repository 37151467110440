import React, { useEffect } from "react";
import { useStyles } from "./EditServiceOrderDialog.styles";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { Button, Grid, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import { useLocalStore, useObserver } from "mobx-react-lite";
import ChevronUpIcon from "assets/ChevronUpIcon";
import ChevronDownIcon from "assets/ChevronDownIcon";
import ButtonBase from "@material-ui/core/ButtonBase";
import clsx from "clsx";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import useStoreByIp from "hooks/useStoreByIp";
import { useModal } from "hooks/useModal";

const EditServiceOrderDialog = ({ open, handleClose, ip, afterSubmitCallback }) => {
    const classes = useStyles();
    const { ethernetPortsStore } = useStoreByIp({ ip });
    const { t } = useTranslation();

    const state = useLocalStore(() => ({ isSubmitted: false, shadowIfaces: [] }));

    const unsavedChangesModal = useModal();

    useEffect(() => {
        (async () => {
            if (!ethernetPortsStore.shadowIfaces.length) {
                await ethernetPortsStore.fetchAllIfaces();
            }
        })();
    }, []);

    const onOpen = async () => {
        await ethernetPortsStore.resetShadowIfaces();
        state.shadowIfaces = ethernetPortsStore.sortedByMetricIfaces;
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };
    const moveUp = (port) => () => {
        ethernetPortsStore.moveUp(port);
    };
    const moveDown = (port) => () => {
        ethernetPortsStore.moveDown(port);
    };
    const onSubmit = async () => {
        const resp = await ethernetPortsStore.saveNetMetrics({
            metrics: ethernetPortsStore.shadowIfaces.map((iface) => ({ port: iface.port, metric: iface.metric })),
        });

        ethernetPortsStore.fetchAllNetRequests();
        resp && (state.isSubmitted = true);
        return resp;
    };

    const closeIfNeeded = () => {
        const byPort = (el) => el.port;
        const equalShadowIfaces = ethernetPortsStore.shadowIfaces.map(byPort).join() === state.shadowIfaces.map(byPort).join();

        if (equalShadowIfaces || state.isSubmitted) {
            handleClose();
            return;
        }

        unsavedChangesModal.open();
    };

    const getPort = (iface) => {
        if (iface.port.includes("bond")) {
            return iface.interfaces.join(", ");
        }

        return iface.port;
    };

    return useObserver(() => (
        <Dialog
            withoutPaddings
            onEnter={onOpen}
            open={open}
            onClose={closeIfNeeded}
            onSubmit={onSubmit}
            buttons={
                <Button onClick={closeIfNeeded} variant={"contained"} color="secondary">
                    {t("common.button.cancel")}
                </Button>
            }
            submitBtnLabel={t("common.button.save")}
            title={t("network.edit_service_order_dialog.title")}
            afterSubmitCallback={afterSubmitCallback}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>{t("network.edit_service_order_dialog.table_title.port_name")}</TableCell>
                        <TableCell>{t("network.edit_service_order_dialog.table_title.gateway")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ethernetPortsStore.shadowIfaces.map((iface, index) => (
                        <TableRow key={iface.port}>
                            <TableCell className={classes.buttonContainer}>
                                <Grid direction={"column"} container>
                                    <Grid container item>
                                        <ButtonBase
                                            disabled={!index}
                                            className={clsx(!index && classes.disabled)}
                                            onClick={moveUp(iface.port)}
                                        >
                                            <ChevronUpIcon />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid container item>
                                        <ButtonBase
                                            disabled={index === ethernetPortsStore.shadowIfaces.length - 1}
                                            className={clsx(
                                                classes.downIcon,
                                                index === ethernetPortsStore.shadowIfaces.length - 1 && classes.disabled
                                            )}
                                            onClick={moveDown(iface.port)}
                                        >
                                            <ChevronDownIcon />
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell>{getPort(iface)}</TableCell>
                            <TableCell>{iface.gateway}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <UnsavedChangesDialog onConfirm={handleClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default EditServiceOrderDialog;
