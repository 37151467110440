import { types } from "mobx-state-tree";

const UsersAndGroupsPageQuery = types.model({
    offset: types.number,
    limit: types.number,
    name: types.string,
    type: types.string
});

export default UsersAndGroupsPageQuery;
