import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    titleContainer: {
        marginTop: theme.spacing(2.5),
        width: "100%"
    },
    radioContainer: {
        alignItems: "center",
        width: "100%"
    },
    radioGroup: {
        width: "100%"
    }
}));
