import React from "react";

function ConnectIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill="#FFF">
                    <g>
                        <g>
                            <path
                                d="M3 18.5c-.552 0-1-.447-1-1V4c0-.553.444-1 1-1 .552 0 1 .447 1 1v3.5h3V6.003C7 5.449 7.449 5 7.996 5h13.008c.55 0 .996.449.996 1.003v4.994c0 .554-.449 1.003-.996 1.003H7.996C7.446 12 7 11.551 7 10.997V9.5H4v7h3v-1.497c0-.554.449-1.003.996-1.003h13.008c.55 0 .996.449.996 1.003v4.994c0 .554-.449 1.003-.996 1.003H7.996C7.446 21 7 20.551 7 19.997V18.5H3zm6 .5h11v-3H9v3zm0-9h11V7H9v3z"
                                transform="translate(-648 -22) translate(0 -1) translate(648.5 23)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default ConnectIcon;
