import Request from "api/Request";

const GetDiskTestSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Troubleshooting",
    method: "get_disk_test_settings"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetDiskTestSettings;
