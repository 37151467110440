import { types } from "mobx-state-tree";

import Request from "api/Request";
import SetAllowedUsersGroupsArguments from "api/security/acl/Types/SetAllowedUsersGroupsArguments";

const SetAllowedUsersGroups = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Acl",
    method: "set_allowed_users_groups",
    arguments: types.maybe(SetAllowedUsersGroupsArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetAllowedUsersGroups
