import { types } from "mobx-state-tree";

import ProgressEventsPage from "api/event/Types/ProgressEventsPage";

const GetProgressPageResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(ProgressEventsPage)
});

export default GetProgressPageResult;
