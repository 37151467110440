import { types } from "mobx-state-tree";

const DRIVE_TYPE_ARRAY = ["hdd", "ssd", "unknown"];
const DriveStatus = types.model({
    drive: types.number,
    status: types.string,
    driveType: types.enumeration(DRIVE_TYPE_ARRAY)
});

export default DriveStatus;
