import { types } from "mobx-state-tree";

const Plugin = types.model({
    id: types.string,
    pluginVersion: types.string,
    name: types.string,
    description: types.string,
    dataShare: types.string,
    backupShare: types.string
});

export default Plugin;
