export const IEC_STANDARD = "iec";
export const METRIC_STANDARD = "metric";
export const JEDEC_STANDARD = "jedec";

export const B_METRIC = { unit: "B", value: 8 };
export const KB_METRIC = { unit: "KB", value: 1000 };
export const MB_METRIC = { unit: "MB", value: 1000000 };
export const GB_METRIC = { unit: "GB", value: 1000000000 };
export const TB_METRIC = { unit: "TB", value: 1000000000000 };
export const PB_METRIC = { unit: "PB", value: 1000000000000000 };

export const Bi_IEC = { unit: "Bi", value: 8 };
export const KiB_IEC = { unit: "KiB", value: Math.pow(1024, 1) };
export const MiB_IEC = { unit: "MiB", value: Math.pow(1024, 2) };
export const GiB_IEC = { unit: "GiB", value: Math.pow(1024, 3) };
export const TiB_IEC = { unit: "TiB", value: Math.pow(1024, 4) };
export const PiB_IEC = { unit: "PiB", value: Math.pow(1024, 5) };

export const TABLE_UNIT_VALUE = {
    [GB_METRIC.unit]: GB_METRIC.value,
    [TB_METRIC.unit]: TB_METRIC.value,
    [PB_METRIC.unit]: PB_METRIC.value,
    [GiB_IEC.unit]: GiB_IEC.value,
    [TiB_IEC.unit]: TiB_IEC.value,
    [PiB_IEC.unit]: PiB_IEC.value
};

export const SIZE_ARRAY = [
    B_METRIC,
    Bi_IEC,
    KB_METRIC,
    KiB_IEC,
    MB_METRIC,
    MiB_IEC,
    GB_METRIC,
    GiB_IEC,
    TB_METRIC,
    TiB_IEC,
    PB_METRIC,
    PiB_IEC
];
