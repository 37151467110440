import { makeStyles } from "@material-ui/core/styles";
import { NOTIFICATION_MENU_BUTTON_WIDTH } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    evoMenuBtn: {
        width: NOTIFICATION_MENU_BUTTON_WIDTH,
        height: "100%",
        backgroundColor: ({ isOpen }) => isOpen && theme.palette.primary.main,
        "&:hover": {
            boxShadow: `0px -${theme.spacing(1)}px 0px 0px ${theme.palette.primary.main} inset`
        }
    },
    menuPaper: {
        "& .MuiMenuItem-root": {
            "&:hover": {
                backgroundColor: theme.palette.searchInlinePopover.hover,
            },
        },
    },
}));
