import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    portsMessageContainer: {
        height: "100%",
        justifyContent: "space-between",
        alignItems: "center"
    },
    messageContainer: {
        marginTop: theme.spacing(-1),
        padding: theme.spacing(0, 6, 4),
        alignItems: "center",
        alignContent: "center",
        "& > .MuiGrid-item": {
            height: 56,
            flex: 1,
            borderRadius: theme.spacing(1),
            padding: ({ portChecked }) => (portChecked ? theme.spacing(4, 6, 4, 4) : theme.spacing(2.5, 4)),
            backgroundImage: `linear-gradient(95deg, rgba(30, 144, 255, 0.07), rgba(100, 150, 255, 0.07) 106%)`
        }
    },

    disabledRow: {
        "& td": {
            opacity: 0.32
        }
    },
    disabledCellWithIcon: {
        "&&": {
            opacity: 1,
            "& p": {
                opacity: 0.32
            }
        }
    }
}));
