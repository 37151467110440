import React, { useEffect, useRef } from "react";
import { observer, useLocalStore } from "mobx-react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import {
    Box,
    Button,
    CircularProgress,
    InputAdornment,
    FormControlLabel,
    Grid,
    Typography,
    Link,
    ButtonBase,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TextInput from "components/MobxForm/TextInput";
import MainTemplate from "components/MainTemplate";
import Checkbox from "components/MobxForm/Checkbox";
import InputErrorIcon from "assets/InputErrorIcon";
import ViewIcon from "assets/ViewIcon";
import LoginIcon from "assets/LoginIcon";
import HeaderTitle from "components/HeaderTitle";
import createForm from "./createFields";
import { URL_HOME, URL_EULA, URL_SHARES, URL_FILE_NAVIGATOR, URL_TROUBLESHOOTING, URL_QUICK_START } from "routes";
import useStyles from "./Login.styles";
import { ADMIN, SECONDARY_ADMIN } from "const/userRolesConst";
import { useStore } from "hooks/useStore";
import { autorun } from "mobx";
import { isEnterPressed } from "utils/handlers/isEnterPressed";

const Login = () => {
    const isExist = useRef(true);
    const { t } = useTranslation();
    const history = useHistory();
    const {
        store: { authStore, evoSettingsStore, upgradesStore, uiStore, driveStore, driveReplaceStore, multipleEvosStore },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm(),
        hasShowPassword: false,
        inAuthProcess: false,
        inCheckProcess: false,
        showErrorText: false,
        get isTroubles() {
            return (
                (driveStore.failedDrives.length > 0 || driveReplaceStore.hasAffectedPools) &&
                !uiStore.parameters.notRedirectToTroubleshooting
            );
        },
        get dontShowAgainQuickStart() {
            return !uiStore?.parameters?.dontShowAgainQuickStart;
        },
        get pathToHistoryPush() {
            switch (authStore.role) {
                case ADMIN:
                    if (this.isTroubles) {
                        return URL_TROUBLESHOOTING;
                    }

                    if (this.dontShowAgainQuickStart) {
                        return URL_QUICK_START;
                    }

                    return URL_HOME;
                case SECONDARY_ADMIN:
                    return URL_SHARES;
                default:
                    return URL_FILE_NAVIGATOR;
            }
        },
        beginCheckProcess() {
            this.inCheckProcess = true;
        },
        endCheckProcess() {
            this.inCheckProcess = false;
        },
        beginAuthProcess() {
            state.inAuthProcess = true;
        },
        endAuthProcess() {
            state.inAuthProcess = false;
        },
        showPassword() {
            this.hasShowPassword = true;
        },
        hidePassword() {
            this.hasShowPassword = false;
        },
        toggleShowErrorText() {
            this.showErrorText = !this.showErrorText;
        },
    }));

    const classes = useStyles({ hideLabel: state.inAuthProcess });

    const checkQuadsAndPools = async () => {
        if (!authStore.isAuthorized) return null;
        state.beginCheckProcess();
        await uiStore.fetchUiParameters();
        uiStore.compareTimestamp();

        if (authStore.role === ADMIN) {
            await driveStore.fetchQuadsStatus();
            await driveReplaceStore.fetchHasAffectedPools();
        }

        if (isExist.current) history.push(state.pathToHistoryPush);

        state.endCheckProcess();
    };

    useEffect(() => {
        isExist.current = true;
        upgradesStore.clearUpdateStatus();
        checkQuadsAndPools();

        autorun(() => {
            state.form.$("acceptEula").set("value", evoSettingsStore.eulaAccepted);
        });

        return () => {
            isExist.current = false;
        };
    }, []);

    const handleLogin = async () => {
        const resp = await authStore.login(state.form.$("username").value, state.form.$("password").value);
        if (!resp) return null;

        uiStore.closeNotificationMenu();

        if (authStore.role !== ADMIN) {
            multipleEvosStore.cleanMultipleEvoStores();
        }

        checkQuadsAndPools();

        upgradesStore.fetchUpdateStatus();
        evoSettingsStore.startTimerFetch();
    };

    const onSubmit = async (e) => {
        state.beginAuthProcess();
        state.form.submit(e);
        if (state.form.isValid) await handleLogin();
        state.endAuthProcess();
    };

    const onKeyEnterPressed = (event) => {
        if (isEnterPressed(event)) {
            onSubmit();
        }
    };

    return (
        <MainTemplate hideHeaderButton headerTitle={<HeaderTitle icon={<LoginIcon />} title={t("login.title")} />}>
            {state.inCheckProcess ? (
                <Grid container alignItems={"center"} justify={"center"} direction="column" spacing={6}>
                    <Grid item>
                        <CircularProgress size={40} />
                    </Grid>
                    {authStore.role !== ADMIN ? null : <Grid item>{t("login.waiting.message")}</Grid>}
                </Grid>
            ) : (
                <Grid container justify={"center"} className={classes.container}>
                    <Grid onKeyDown={onKeyEnterPressed} className={classes.loginContainer} direction={"column"} container>
                        <Grid item>
                            <Typography variant={"h2"}>{t("login.label.login")}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={clsx(classes.label, classes.usernameLabel)}>
                                {t("common.label.username")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextInput field={state.form.$("username")} className={classes.input} autoFocus />
                        </Grid>
                        <Grid item>
                            <Typography className={clsx(classes.label, classes.passwordLabel)}>
                                {t("common.label.password")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextInput
                                field={state.form.$("password")}
                                type={state.hasShowPassword ? "text" : "password"}
                                className={classes.input}
                                showErrorText={state.showErrorText}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {state.form.$("password").value ? (
                                                <ButtonBase onMouseDown={state.showPassword} onMouseUp={state.hidePassword}>
                                                    <ViewIcon />
                                                </ButtonBase>
                                            ) : null}
                                            {state.form.$("password").error ? (
                                                <ButtonBase onClick={state.toggleShowErrorText}>
                                                    <InputErrorIcon />
                                                </ButtonBase>
                                            ) : null}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid className={classes.controlContainer} container>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            field={state.form.$("acceptEula")}
                                            className={classes.checkbox}
                                            disabled={evoSettingsStore.eulaAccepted}
                                            error={Boolean(state.form.$("acceptEula").error)}
                                        />
                                    }
                                    label={t("login.label.accept")}
                                />
                            </Grid>

                            <Typography className={classes.acceptText}>
                                <Link to={URL_EULA} target="_blank" rel="noopener" component={RouterLink}>
                                    {t("login.label.eula")}
                                </Link>
                            </Typography>
                            <Grid item>
                                <Button
                                    className={classes.button}
                                    onClick={onSubmit}
                                    disabled={state.inAuthProcess}
                                    variant={"contained"}
                                    color={"primary"}
                                >
                                    {t("login.button.login")}
                                    {state.inAuthProcess ? (
                                        <Box className={classes.icon}>
                                            <CircularProgress color={"primary"} size={16} />
                                        </Box>
                                    ) : null}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </MainTemplate>
    );
};

export default observer(Login);
