import { useTranslation } from "react-i18next";

const StatusTranslation = ({ label }) => {
    const { t } = useTranslation();
    const getLabel = () => {
        switch (label) {
            case "unknown":
                return t("network.ethernet_ports.unknown");
            case "offline":
                return t("network.ethernet_ports.offline");
            case "mixed":
                return t("network.ethernet_ports.mixed");
            case "static_ip":
                return t("network.ethernet_ports.static_ip");
            case "receiving_ip":
                return t("network.ethernet_ports.receiving_ip");
            case "attached_to_bond":
                return t("network.ethernet_ports.attached_to_bond");
            case "disabled_by_stp":
                return t("network.ethernet_ports.disabled_by_stp");
            case "dhcp_server":
                return t("network.ethernet_ports.dhcp_server");
            case "dhcp_client":
                return t("network.ethernet_ports.dhcp_client");
            default:
                // label may be undefined
                return label || "";
        }
    };
    return getLabel();
};

export default StatusTranslation;
