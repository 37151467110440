import { types } from "mobx-state-tree";

import Request from "api/Request";
import SetOwnerArguments from "api/security/acl/Types/SetOwnerArguments";

const SetOwner = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Acl",
    method: "set_owner",
    arguments: types.maybe(SetOwnerArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetOwner
