import config from "config";
import { GB_METRIC, GiB_IEC, KB_METRIC, KiB_IEC, TABLE_UNIT_VALUE } from "const/diskSizeConst";
import { convertBytesToSizeUnit } from "utils/convertBytesToSizeUnit";

export const getMaxVolumeSizeString = (maxVolumeSize, sizeUnitValue) => {
    if (!maxVolumeSize) {
        return `0 ${config.decimalSizeUnits ? GB_METRIC.unit : GiB_IEC.unit}`;
    }

    return convertBytesToSizeUnit({
        bytes: maxVolumeSize,
        exponent: Math.round(
            Math.log2(TABLE_UNIT_VALUE[sizeUnitValue]) / Math.log2(config.decimalSizeUnits ? KB_METRIC.value : KiB_IEC.value)
        ),
        round: 2
    })
        .split(" ")
        .map(part => {
            if (isNaN(Number(part))) {
                return part;
            }

            return Math.floor(Number(part) * 10) / 10;
        })
        .join(" ");
};
