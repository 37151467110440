import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    progressBar: {
        width: "100%",
        marginBottom: theme.spacing(6)
    },
    subtitle: {
        fontSize: 16
    }
}));
