import { types } from "mobx-state-tree";

import ProgressTaskPage from "api/task/Types/ProgressTaskPage";

const GetProgressTaskPageResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(ProgressTaskPage)
});

export default GetProgressTaskPageResult;
