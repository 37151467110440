import Request from "api/Request";

const GetPools = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Pool",
    method: "get_pools"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetPools;
