import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";
import { TABLE_DIVIDER_GRAY } from "const/styleConst";

export const useStyles = makeStyles((theme) => ({
    footerCell: {
        borderTop: ({ hideDivider }) => !hideDivider && `1px solid ${TABLE_DIVIDER_GRAY}`,
        borderBottomLeftRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        "&&": {
            padding: 0,
            paddingLeft: 0,
            paddingRight: 0,
        },
        "&:hover": {
            backgroundColor: hexToRgba(theme.palette.primary.main, 0.05),
            "& .MuiTypography-root": {
                color: theme.palette.primary.light,
            },
            "& path": {
                fill: theme.palette.primary.light,
            },
        },
    },
    container: {
        height: 56,
        flexDirection: ({ iconPosition }) => (iconPosition === "left" ? "row" : "row-reverse"),
        justifyContent: "center",
        alignItems: "center",
        "& .MuiGrid-item": {
            padding: theme.spacing(0, 1),
        },
    },
    iconContainer: {
        width: "100%",
        display: "flex",
        alignItems: "inherit",
        justifyContent: "inherit",
    },
}));
