import React, { useContext, useEffect } from "react";
import { useObserver } from "mobx-react";
import MainTemplate from "components/MainTemplate";
import { Grid, Typography } from "@material-ui/core";
import DataCard from "components/DataCard";
import { useTranslation } from "react-i18next";
import BookIcon from "assets/BookIcon";
import HeaderTitle from "components/HeaderTitle";
import { Context } from "store";
import { useStyles } from "./Eula.style";

const Eula = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { authStore, evoSettingsStore }
    } = useContext(Context);

    const hideHeaderButton = !authStore.isAuthorized;

    useEffect(() => {
        evoSettingsStore.fetchEula();
    }, []);

    return useObserver(() => (
        <MainTemplate
            headerTitle={<HeaderTitle icon={<BookIcon />} title={t("eula.title")} />}
            hideHeaderButton={hideHeaderButton}
        >
            <Grid item container xs={12} md={8} direction={"column"}>
                <DataCard title={t("eula.title")} headerDivider autoHeight emptyPadding>
                    <Grid container direction={"column"} className={classes.outerContainer}>
                        <Grid item className={classes.contentContainer}>
                            <pre>
                                <Typography>{evoSettingsStore.eula}</Typography>
                            </pre>
                        </Grid>
                    </Grid>
                </DataCard>
            </Grid>
        </MainTemplate>
    ));
};

export default Eula;
