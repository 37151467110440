export default {
    description: {
        paragraphs: [
            {
                textKey: "slingshot_page.tabs.replications.create_job.description.paragraph_1",
                gutterBottom: true
            },
            {
                textKey: "slingshot_page.tabs.replications.create_job.description.paragraph_2",
                gutterBottom: true
            },
            {
                textKey: "slingshot_page.tabs.replications.create_job.description.paragraph_3",
                gutterBottom: true
            },
            {
                textKey: "slingshot_page.tabs.replications.create_job.description.paragraph_4"
            }
        ]
    }
};
