import React from "react";

const SortDownIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16" {...props}>
            <g fill="none" fillRule="evenodd">
                <path fill="#CCDAE7" d="M4.416 1.624L8 7H0l3.584-5.376c.153-.23.464-.292.693-.139.055.037.102.084.139.139z" />
                <path
                    fill="#1E90FF"
                    fillRule="nonzero"
                    d="M4.416 14.376L8 9H0l3.584 5.376c.153.23.464.292.693.139.055-.037.102-.084.139-.139z"
                />
            </g>
        </svg>
    );
};

export default SortDownIcon;
