import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";
import { BLACK } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    titleDirName: {
        "&:hover": {
            color: theme.palette.primary.main,
            cursor: "pointer"
        }
    },
    rootSelect: {
        "& .MuiInputBase-root": {
            minWidth: 90,
            margin: theme.spacing(-2, -3.25),
            "&:not(.Mui-focused)": {
                background: "none"
            }
        },
        "&& span": {
            opacity: 0.9,
            fontSize: 20,
            fontWeight: "bold",
            lineHeight: 1.2
        }
    },
    helperContainer: {
        margin: "auto",
        padding: theme.spacing(20, 6),
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        height: "100%"
    },
    fileIcon: {
        cursor: "pointer"
    },
    menuItem: {
        padding: theme.spacing(1.5, 4),
        "&:hover": {
            backgroundColor: hexToRgba(BLACK, 0.04)
        }
    }
}));
