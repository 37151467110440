import React from "react";
import { LinearProgress, Typography, Grid } from "@material-ui/core";
import { useStyles } from "./FileProgressUpload.styles";
import { useTranslation } from "react-i18next";

const FileProgressUpload = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container direction="column" alignItems="center">
            <LinearProgress className={classes.progressBar} />
            <Typography className={classes.subtitle}>{t("upgrades.file_progress.uploading_message")}</Typography>
        </Grid>
    );
};

export default FileProgressUpload;
