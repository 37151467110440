import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import Checkbox from "components/Checkbox";
import { equalsIgnoreCase } from "utils/equalsIgnoreCase";
import { useTranslation } from "react-i18next";
import TableCellWithIcon from "components/TableCellWithIcon";
import moment from "moment";
import { MiB_IEC } from "const/diskSizeConst";
import ChevronRightIcon from "assets/ChevronRightIcon";
import TableRowControlButton from "components/TableRowControlButton";
import { FILE_NAVIGATOR_DATE_FORMAT, SERVER_FILE_NAVIGATOR_DATE_FORMAT } from "const";
import { useObserver } from "mobx-react";
import useStoreByIp from "hooks/useStoreByIp";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const FilesRow = ({ onClick, selectFile, renderTypeIcon, checked, name, modifiedTime, type, size, ip }) => {
    const { t } = useTranslation();
    const { fileStore } = useStoreByIp({ ip });

    const getTypeTranslation = (type) => {
        if (equalsIgnoreCase(type, "directory")) {
            return t("recycle_bin.type.folder");
        } else if (equalsIgnoreCase(type, "file")) {
            return t("recycle_bin.type.file");
        }
        return t("recycle_bin.type.file");
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleClick = () => {
        onClick(name);
    };
    const handleSelect = () => {
        selectFile(name);
    };
    const renderIcon = renderTypeIcon(type, name);
    const icon = <ChevronRightIcon />;

    return useObserver(() => (
        <TableRow selected={fileStore.currentFileName === name} onClick={handleClick}>
            <TableCell>
                <Checkbox onClick={stopPropagation} onChange={handleSelect} checked={checked} />
            </TableCell>
            <TableCellWithIcon icon={renderIcon}>{name}</TableCellWithIcon>
            <TableCell>
                {modifiedTime &&
                    moment(modifiedTime, SERVER_FILE_NAVIGATOR_DATE_FORMAT)
                        .utc(true)
                        .format(getLanguageDateFormat(FILE_NAVIGATOR_DATE_FORMAT))}
            </TableCell>
            <TableCell>{getTypeTranslation(type)}</TableCell>
            <TableCell align={"right"}>{(size / MiB_IEC.value).toFixed(1)}</TableCell>
            <TableCell align={"right"}>
                <TableRowControlButton icon={icon} />
            </TableCell>
        </TableRow>
    ));
};

export default FilesRow;
