import React from "react";
import { useTranslation } from "react-i18next";
import { useObserver, useLocalStore } from "mobx-react";
import createFields from "./createFields";
import createForm from "utils/createForm";
import TextInput from "components/MobxForm/TextInput";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import { Button } from "@material-ui/core";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";

const RESET_PASSWORD_DIALOG_ID_PREFIX = "reset_password_dialog";

const ResetPasswordDialog = ({ open, onClose, parentId }) => {
    const { t } = useTranslation();
    const idPrefix = parentId ? `${parentId}_${RESET_PASSWORD_DIALOG_ID_PREFIX}` : RESET_PASSWORD_DIALOG_ID_PREFIX;
    const fields = createFields();
    const {
        store: { userStore },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({
            fields,
            options: { validateOnInit: false, validateOnBlur: false },
        }),
        isSubmitted: false,
    }));

    const unsavedChangesModal = useModal();

    const onSubmit = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        const res = await userStore.resetUserPassword({
            name: userStore.currentEntity?.id.name || userStore.currentEntitySingleBar?.id.name,
            password: state.form.$("password").value,
        });
        if (res) {
            state.isSubmitted = true;
            userStore.dropCurrentEntity();
            userStore.dropCurrentEntitySingleBar();
            userStore.clearCheckedUsers();
        }
        return res;
    };

    const closeModal = () => {
        state.form.reset();
        onClose();
    };

    const onEnter = () => {
        state.isSubmitted = false;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            closeModal();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            onSubmit={onSubmit}
            title={userStore.currentEntity?.id.name || userStore.currentEntitySingleBar?.id.name}
            submitBtnLabel={t("users.reset_password.button.reset_password")}
            submitBtnId={`${idPrefix}_reset_password`}
            open={open}
            onClose={closeIfNeeded}
            onEnter={onEnter}
            buttons={
                <Button onClick={closeModal} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <RowTextInput
                label={t("users.reset_password.label.new_password")}
                control={<TextInput field={state.form.$("password")} id={`${idPrefix}_new_password`} />}
            />
            <RowTextInput
                label={t("users.reset_password.label.confirm_password")}
                control={<TextInput field={state.form.$("confirmPassword")} id={`${idPrefix}_new_password`} />}
                id={`${idPrefix}_confirm_password`}
            />
            <UnsavedChangesDialog onConfirm={closeModal} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default ResetPasswordDialog;
