import { types } from "mobx-state-tree";

import DomainObjectId from "api/security/Types/DomainObjectId";

const DeleteGroupMembersArguments = types.model({
    groupName: types.string,
    users: types.array(DomainObjectId)
});

export default DeleteGroupMembersArguments;
