import { types } from "mobx-state-tree";

import ShareAttributesChangeable from "api/share/Types/ShareAttributesChangeable";

const EditShareArguments = types.model({
    shareName: types.string,
    attributes: types.maybe(ShareAttributesChangeable),
    masks: types.array(types.string)
});

export default EditShareArguments;
