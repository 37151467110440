import { types } from "mobx-state-tree";

import PartitionInfoData from "api/upgrade/Types/PartitionInfoData";

const PartitionInfoDataResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(PartitionInfoData)
});

export default PartitionInfoDataResult;
