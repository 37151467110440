import Request from "api/Request";

const ListUpdates = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Upgrade",
    method: "list_updates"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ListUpdates;
