import { types } from "mobx-state-tree";

import GetPeersResultData from "api/cluster/Types/GetPeersResultData";

const GetPeersResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(GetPeersResultData)
});

export default GetPeersResult;
