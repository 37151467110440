import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => {
    const getColor = status => {
        switch (status.toLowerCase()) {
            case "active":
            case "idle":
                return theme.palette.success.main;
            case "disabled":
            case "error":
                return theme.palette.error.main;
            case "canceled":
            case "paused":
                return theme.palette.warning.main;
            default:
                return theme.palette.primary.main;
        }
    };

    return {
        label: {
            padding: theme.spacing(0, 2),
            borderRadius: theme.spacing(1),
            backgroundColor: props => hexToRgba(getColor(props.status), 0.1),
            "& span": {
                color: props => getColor(props.status)
            }
        },
        labelText: {
            fontWeight: 500,
            textTransform: "capitalize"
        }
    };
});
