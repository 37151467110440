import { types } from "mobx-state-tree";

import InterfaceRules from "api/firewall/Types/InterfaceRules";
import NetIface from "api/firewall/Types/NetIface";
import Bond from "api/firewall/Types/Bond";

const GetFirewallRules = types.model({
    interfacesRules: types.array(InterfaceRules),
    interfaces: types.array(NetIface),
    bonds: types.array(Bond)
});

export default GetFirewallRules;
