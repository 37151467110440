import { types } from "mobx-state-tree";

import Request from "api/Request";
import RevokeTokenArguments from "api/auth/Types/RevokeTokenArguments";

const RevokeToken = Request.props({
    path: "/com/studionetworksolutions/evo/Auth",
    method: "revoke_token",
    arguments: types.maybe(RevokeTokenArguments),
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default RevokeToken;
