export default {
    renameActiveShareConfirm: {
        paragraphs: [
            {
                textKey: "share.edit_share.rename_share_with_active_sessions_warning.message",
                gutterBottom: true
            },
            {
                textKey: "share.edit_share.rename_share_with_active_sessions_warning.confirmation_question"
            }
        ]
    }
};
