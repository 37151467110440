import { types } from "mobx-state-tree";

import UiLockReason from "api/ui_settings/Types/UiLockReason";

const UiLockReasonResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(UiLockReason),
});

export default UiLockReasonResult;
