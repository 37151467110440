import React, { useEffect, useContext } from "react";
import { Context } from "store";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { useStyles } from "./PushDiagnostics.styles";
import { useLocalStore, useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { PUSH_DIAGNOSTICS } from "const/widgetNameConst";
import DataReadonlyCard from "components/DataReadonlyCard";
import { FREQUENCY_OPTIONS } from "const/pushDiagnosticsConst";
import SavedInputIndicator from "components/SavedInputIndicator";
import EditModal from "./components/EditModal";

const PushDiagnostics = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const state = useLocalStore(() => ({
        isEditModalOpen: false
    }));

    const {
        store: { supportInfoStore }
    } = useContext(Context);

    useEffect(() => {
        supportInfoStore.fetchIdentity();
        supportInfoStore.fetchSchedule();
    }, []);

    const openEditModal = () => {
        state.isEditModalOpen = true;
    };
    const closeEditModal = () => {
        state.isEditModalOpen = false;
    };

    const getFrequency = () => {
        if (supportInfoStore.schedule) {
            return FREQUENCY_OPTIONS[supportInfoStore.schedule.frequency]?.label;
        }
    };

    return useObserver(() => (
        <>
            <EditModal open={state.isEditModalOpen} onClose={closeEditModal} />
            <DataReadonlyCard
                title={t("support.push_diagnostics.title")}
                onClick={openEditModal}
                control={
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={PUSH_DIAGNOSTICS} />
                        <MenuItem onClick={supportInfoStore.sendSupportInfo}>{t("support.push_diagnostics.menu.send")}</MenuItem>
                    </MoreMenu>
                }
            >
                <Grid container spacing={4} direction={"column"}>
                    <Grid item>
                        <Typography>{t("support.push_diagnostics.content")}</Typography>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("support.push_diagnostics.control.diagnostics")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SavedInputIndicator enabled={supportInfoStore.schedule?.sendReports} />
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("support.push_diagnostics.control.serial_number")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldText}>
                                {supportInfoStore.identity?.serialNumber || t("header.serial_number.not_applicable")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("support.push_diagnostics.control.registration_id")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldText}>
                                {supportInfoStore.identity?.registrationId || t("header.serial_number.not_applicable")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("support.push_diagnostics.control.frequency")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldText}>{getFrequency()}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DataReadonlyCard>
        </>
    ));
};

export default PushDiagnostics;
