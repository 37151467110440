import { makeStyles } from "@material-ui/core/styles";
import { TABLE_DIVIDER_GRAY } from "const/styleConst";

const MAX_DIALOG_WIDTH = 640;
export const useStyles = makeStyles(theme => ({
    table: {
        "& .MuiPaper-root": {
            maxWidth: MAX_DIALOG_WIDTH
        },
        "& th:after": {
            content: "no-close-quote",
            position: "absolute",
            left: 0,
            width: "100%",
            bottom: -1,
            borderBottom: `1px solid ${TABLE_DIVIDER_GRAY}`
        }
    },
    row: {
        "& td:not(:first-child)": {
            lineHeight: 0
        },
        "& .MuiButtonBase-root": {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0)
        }
    },
    tableBody: {
        "& td": {
            opacity: 1
        }
    },
    checkbox: {
        marginTop: theme.spacing(-2.25),
        marginBottom: theme.spacing(-2.25)
    },
    headerCheckbox: {
        margin: theme.spacing(-2.25, 0),
        "& .MuiTypography-body1": {
            opacity: 1,
            fontWeight: "inherit",
            lineHeight: "inherit"
        }
    }
}));
