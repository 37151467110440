import React from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Dialog from "components/Dialog";
import i18next from "i18next";
import FileUpload from "components/FileUpload/FileUpload";

const FileUploadDialog = ({
    onSubmit,
    submitBtnLabel = i18next.t("support.start.plugin_install.upload_file_dialog.button.upload"),
    title = i18next.t("support.start.plugin_install.upload_file_dialog.title"),
    onClose,
    open,
    regExpExtension = /\.[0-9a-z]+$/i,
    id,
}) => {
    const state = useLocalStore(() => ({
        file: null,
        loading: false,
        fileValidationInfo: false,
        setFileValidationInfo(fileValidationInfo) {
            state.fileValidationInfo = fileValidationInfo;
        },
    }));

    const { t } = useTranslation();

    const handleFile = async (file) => {
        state.file = file;
    };

    const handleSubmit = async () => {
        state.loading = true;
        try {
            await onSubmit(state.file);
        } finally {
            state.loading = false;
        }
    };

    const onEnter = () => {
        state.file = null;
    };

    const onCloseDialog = () => {
        if (state.loading) return null;

        onClose();
    };

    return useObserver(() => (
        <Dialog
            buttons={[
                <Button key={"cancelBtn"} onClick={onClose} variant={"contained"} color={"secondary"} disabled={state.loading}>
                    {t("common.button.cancel")}
                </Button>,
            ]}
            open={open}
            onEnter={onEnter}
            onSubmit={handleSubmit}
            disableSubmit={!state.fileValidationInfo.isValid || state.loading}
            submitBtnLabel={submitBtnLabel}
            title={title}
            onClose={onCloseDialog}
            disableBackdropClick={state.loading}
            disableEscapeKeyDown={state.loading}
            withoutIconClose={state.loading}
        >
            <FileUpload
                file={state.file}
                loading={state.loading}
                regExpExtension={regExpExtension}
                handleFile={handleFile}
                inputProps=".gpg"
                noEmpty
                onValid={state.setFileValidationInfo}
                id={id}
            />
        </Dialog>
    ));
};

export default FileUploadDialog;
