import { types } from "mobx-state-tree";

import Brick from "api/cluster/Types/Brick";

const GetBricksResultData = types.model({
    arr: types.array(Brick),
    total: types.number
});

export default GetBricksResultData;
