import { types } from "mobx-state-tree";
import GetWatermarkImageType from "../Types/GetWatermarkImageType";
import Request from "api/Request";

const GetWatermarkImage = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Transcoding1",
    method: "get_watermark_image",
    arguments: types.maybe(
        types.snapshotProcessor(GetWatermarkImageType, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json),
                };
            },
        })
    ),
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default GetWatermarkImage;
