import { types, flow, getParent } from "mobx-state-tree";
import * as d3 from "d3";

import { CHARTS, timeRegExp, DEFAULT_START_PERIOD, DEFAULT_POINTS_COUNT } from "const/chartConst";
import { multipleAxios } from "utils/multipleAxios";
import { HTTPS } from "const";
import i18next from "i18next";
import store from "store";

const FETCH_INTERVAL = 5000;

const CPUGraphStore = types
    .model({
        chart: types.array(types.array(types.number)),
        labels: types.array(types.string),
    })
    .volatile(() => ({
        intervalID: 0,
        graphCount: 0,
    }))
    .views((self) => ({
        // chartInfo need to be ascending sorted by time
        get chartInfo() {
            return self.chart
                .slice()
                .sort((a, b) => a[0] - b[0])
                .map((d) => ({
                    date: store.timeSettingsStore && store.timeSettingsStore.dateWithTimeZone(d[0]),
                    value: Math.round(parseFloat(d.slice(1).reduce((sum, val) => sum + val, 0)) * 10) / 10,
                }));
        },
        get lastTime() {
            return self.chartInfo[self.chartInfo.length - 1]
                ? d3.timeFormat(timeRegExp)(self.chartInfo[self.chartInfo.length - 1].date)
                : "";
        },
        get lastValue() {
            return self.chartInfo[self.chartInfo.length - 1] ? self.chartInfo[self.chartInfo.length - 1].value : null;
        },
        get columns() {
            return self.labels.slice(1);
        },
        get detailedChartInfo() {
            const { timeSettingsStore } = getParent(self);
            return self.chart
                .slice()
                .sort((a, b) => a[0] - b[0])
                .map((d) => {
                    const row = { date: timeSettingsStore && timeSettingsStore.dateWithTimeZone(d[0]) };
                    d.slice(1).forEach((data, index) => (row[self.columns[index]] = Math.round(parseFloat(data) * 10) / 10));
                    return row;
                });
        },
        get detailedChartLastValues() {
            return self.detailedChartInfo[self.detailedChartInfo.length - 1] || null;
        },
        get detailedChartLastTime() {
            return self.detailedChartLastValues ? d3.timeFormat(timeRegExp)(self.detailedChartLastValues.date) : "";
        },
    }))
    .actions((self) => ({
        fetchData: function (force) {
            if (!force && self.graphCount > 1) return;
            self.fetch();
        },
        fetch: flow(function* () {
            const { processingStore, uiStore, ip } = getParent(self);
            const after = uiStore.parameters?.graphStartPeriod || DEFAULT_START_PERIOD;
            const points = Math.round(DEFAULT_POINTS_COUNT * (DEFAULT_START_PERIOD / after));
            try {
                processingStore.setLoading(true);
                const resp = yield multipleAxios({
                    path: ip,
                    chart: CHARTS.SYSTEM_CPU,
                    points,
                    after: -after,
                    protocol: ip ? HTTPS : undefined,
                });
                self.chart.replace(resp.data?.result?.data || []);
                self.labels.replace(resp.data?.result?.labels || []);
                if (!self.intervalID) {
                    self.intervalID = setInterval(self.fetch, FETCH_INTERVAL);
                }
            } catch (e) {
                self.stopFetching(true);
                processingStore.setError(i18next.t("multiple_evo.netdata.load_error"));
            } finally {
                processingStore.setLoading(false);
            }
        }),
        stopFetching: function (force) {
            if (!force && self.graphCount > 0) return;
            clearTimeout(self.intervalID);
            self.intervalID = null;
        },
        clear: function () {
            self.chart.clear();
        },
        registerGraph: function () {
            self.graphCount++;
        },
        unregisterGraph: function () {
            self.graphCount--;
        },

        beforeDestroy: function () {
            self.stopFetching(true);
        },
    }));

export default CPUGraphStore;
