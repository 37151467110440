import React from "react";

const AdvancedIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#BFD1E1"
                fillRule="evenodd"
                d="M9 13c1.105 0 2 .895 2 2v4c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2v-4l.005-.15C3.082 13.817 3.945 13 5 13zm10 0c1.105 0 2 .895 2 2v4c0 1.105-.895 2-2 2h-4c-1.105 0-2-.895-2-2v-4l.005-.15c.077-1.034.94-1.85 1.995-1.85zM9 15H5v4h4v-4zm10 0h-4v4h4v-4zM9 3c1.105 0 2 .895 2 2v4c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2V5l.005-.15C3.082 3.817 3.945 3 5 3zm10 0c1.105 0 2 .895 2 2v4c0 1.105-.895 2-2 2h-4c-1.105 0-2-.895-2-2V5l.005-.15C13.082 3.817 13.945 3 15 3zM9 5H5v4h4V5zm10 0h-4v4h4V5z"
            />
        </svg>
    );
};

export default AdvancedIcon;
