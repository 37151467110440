import { types } from "mobx-state-tree";

import Request from "api/Request";
import DeleteAutomationArguments from "api/slingshot/automations/Types/DeleteAutomationArguments";

const DeleteAutomation = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Automations1",
    method: "delete_automation",
    arguments: types.maybe(
        types.snapshotProcessor(DeleteAutomationArguments, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json)
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default DeleteAutomation;
