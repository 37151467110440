import Request from "api/Request";

const GetFirewallRules = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Firewall",
    method: "get_firewall_rules"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetFirewallRules;
