import { types } from "mobx-state-tree";

import Service from "api/cluster/Types/Service";

const GetServicesResultData = types.model({
    arr: types.array(Service),
    total: types.number
});

export default GetServicesResultData;
