import Request from "api/Request";

const GetEvoInfo = Request.props({
    path: "/com/studionetworksolutions/evo/status/Info",
    method: "get_evo_info"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetEvoInfo;
