import React from "react";
import { useObserver } from "mobx-react";
import { useStyles } from "./BulkBarFlatButton.styles";
import { ButtonBase, CircularProgress, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";

const BulkBarFlatButton = ({ icon, children, errorColor, className, onClick, inProgress, disabled, ...props }) => {
    const classes = useStyles({ error: errorColor });

    return useObserver(() => (
        <ButtonBase disabled={inProgress || disabled} className={clsx(classes.container, className)} onClick={onClick} {...props}>
            <Grid container wrap={"nowrap"} alignItems={"center"} justify={"flex-start"}>
                {icon && !inProgress && (
                    <Grid item className={clsx(classes.icon, errorColor && classes.iconErrorColor)}>
                        {icon}
                    </Grid>
                )}
                {inProgress && (
                    <Grid item className={classes.icon}>
                        <CircularProgress size={24} variant={"indeterminate"} />
                    </Grid>
                )}
                <Grid item>
                    <Typography className={classes.content} variant={"button"} color={errorColor ? "error" : "primary"}>
                        {children}
                    </Typography>
                </Grid>
            </Grid>
        </ButtonBase>
    ));
};

export default BulkBarFlatButton;
