import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetJobSummaryArguments from "../Types/GetJobSummaryArguments";

const GetJobSummary = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/SyncJobs1",
    method: "get_job_summary",
    arguments: types.maybe(
        types.snapshotProcessor(GetJobSummaryArguments, {
            postProcessor(snapshot) {
                return {
                    json: snapshot?.json && JSON.stringify(snapshot.json)
                };
            }
        })
    )
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetJobSummary;
