import React from "react";
import DataCard from "components/DataCard";
import CPUWidget from "components/graphs/CPUWidget";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { CPU_GRAPH_CARD } from "const/widgetNameConst";
import { useTranslation } from "react-i18next";
import HideOverlay from "components/HideOverlay";

const CpuGraphCard = ({ parentId = "cpuDashboardCard", ip }) => {
    const { t } = useTranslation();

    return (
        <DataCard
            autoHeight
            headerDivider
            title={t("dashboard.cpu_widget.title")}
            headerControl={
                <HideOverlay show={!ip}>
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={CPU_GRAPH_CARD} />
                    </MoreMenu>
                </HideOverlay>
            }
        >
            <CPUWidget parentId={parentId} ip={ip} />
        </DataCard>
    );
};

export default CpuGraphCard;
