import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import Dialog from "components/Dialog";
import Select from "components/MobxForm/Select";
import RowTextInput from "components/RowTextInput";
import createForm from "utils/createForm";
import createFields from "./createFields";
import { NO_LOGS, CRITICAL, STANDARD, FULL, FIVE_DAYS, FIFTEEN_DAYS, THIRTY_DAYS, FORTY_FIVE_DAYS } from "const/loggingConst";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useModal } from "hooks/useModal";
import { useStore } from "hooks/useStore";
import { ADVANCED_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const SETUP_LOGGING_LEVEL_DIALOG_ID_PREFIX = `${ADVANCED_PAGE_ID_PREFIX}_setup_logging_level_dialog`;

const SetupLoggingLevelDialog = ({ closeDialog, open }) => {
    const { t } = useTranslation();
    const {
        store: { advancedStore }
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields()
        }),
        isSubmitted: false
    }));

    const unsavedChangesModal = useModal();

    const onSubmit = async () => {
        const isSubmitted = await advancedStore.setLogSettings({
            logLevel: state.form.$("level").value,
            daysToReturnStandardLogs: state.form.$("days").value || 0
        });

        if (isSubmitted) {
            state.isSubmitted = true;
        }

        return isSubmitted;
    };

    const onEnter = () => {
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.form.$("level").value = advancedStore.logSettings?.logLevel;
        state.form.$("days").value = THIRTY_DAYS;
    };

    const closeIfNeeded = () => {
        const equalLevel = state.form.$("level").value === advancedStore.logSettings?.logLevel;
        const isThirtyDays = state.form.$("days").value === THIRTY_DAYS;

        if ((equalLevel && isThirtyDays) || state.isSubmitted) {
            closeDialog();
            return;
        }

        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            title={t("advanced.logging_level.edit_card.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${SETUP_LOGGING_LEVEL_DIALOG_ID_PREFIX}_save`}
            open={open}
            onSubmit={onSubmit}
            onEnter={onEnter}
            onClose={closeIfNeeded}
            buttons={
                <Button
                    onClick={closeDialog}
                    variant={"contained"}
                    color="secondary"
                    id={`${SETUP_LOGGING_LEVEL_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"}>
                <RowTextInput
                    label={t("advanced.logging_level.text")}
                    control={
                        <Select
                            field={state.form.$("level")}
                            options={[
                                { value: NO_LOGS, label: t("advanced.logging_level.edit_card.menu_item.no_logs") },
                                {
                                    value: CRITICAL,
                                    label: t("advanced.logging_level.edit_card.menu_item.critical_logging")
                                },
                                {
                                    value: STANDARD,
                                    label: t("advanced.logging_level.edit_card.menu_item.standard_logging")
                                },
                                { value: FULL, label: t("advanced.logging_level.edit_card.menu_item.full_logging") }
                            ]}
                            id={`${SETUP_LOGGING_LEVEL_DIALOG_ID_PREFIX}_level`}
                        />
                    }
                />
                {state.form.$("level").value === FULL && (
                    <RowTextInput
                        label={t("advanced.logging_level.edit_card.switch_to_default")}
                        control={
                            <Select
                                field={state.form.$("days")}
                                options={[
                                    {
                                        value: FIVE_DAYS,
                                        label: t("advanced.logging_level.days.text", {
                                            count: FIVE_DAYS
                                        })
                                    },
                                    {
                                        value: FIFTEEN_DAYS,
                                        label: t("advanced.logging_level.days.text", {
                                            count: FIFTEEN_DAYS
                                        })
                                    },
                                    {
                                        value: THIRTY_DAYS,
                                        label: t("advanced.logging_level.days.text", {
                                            count: THIRTY_DAYS
                                        })
                                    },
                                    {
                                        value: FORTY_FIVE_DAYS,
                                        label: t("advanced.logging_level.days.text", {
                                            count: FORTY_FIVE_DAYS
                                        })
                                    }
                                ]}
                                id={`${SETUP_LOGGING_LEVEL_DIALOG_ID_PREFIX}_switch_to_default`}
                            />
                        }
                    />
                )}
            </Grid>
            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default SetupLoggingLevelDialog;
