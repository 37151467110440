import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import Select from "components/MobxForm/Select";
import createForm from "utils/createForm";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowTextInput from "components/RowTextInput";
import { THRESHOLD_VALUES } from "const/alertsConst";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const DISK_USAGE_THRESHOLD_DIALOG_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_disk_usage_threshold_dialog`;

const DiskUsageThresholdDialog = ({ open, closeDialog }) => {
    const { t } = useTranslation();
    const {
        store: { alertsStore }
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields(alertsStore.usedSpaceEventsSettings) }),
        isSubmitted: false
    }));

    const unsavedChangesModal = useModal();

    const updateForm = () => {
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.form.$("threshold").set("default", alertsStore.usedSpaceEventsSettings?.spaceThreshold || 75);
        state.form.reset();
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted === true) {
            closeDialog();
            return;
        }
        unsavedChangesModal.open();
    };

    const submit = async () => {
        const res = await alertsStore.saveUsageThreshold(state.form.$("threshold").value);
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    return useObserver(() => (
        <Dialog
            isDataChanged={!state.form.isDefault}
            onEnter={updateForm}
            title={t("alerts.disk_usage_threshold.edit_title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${DISK_USAGE_THRESHOLD_DIALOG_ID_PREFIX}_save`}
            open={open}
            onSubmit={submit}
            onClose={closeIfNeeded}
            buttons={
                <Button
                    onClick={closeDialog}
                    variant={"contained"}
                    color="secondary"
                    id={`${DISK_USAGE_THRESHOLD_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <RowTextInput
                label={t("alerts.disk_usage_threshold.threshold_dialog_label", { percent: 10 })}
                control={
                    <Select
                        options={THRESHOLD_VALUES.map(val => {
                            return { value: val, label: `${val}%` };
                        })}
                        field={state.form.$("threshold")}
                        id={`${DISK_USAGE_THRESHOLD_DIALOG_ID_PREFIX}_threshold`}
                    />
                }
            />

            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default DiskUsageThresholdDialog;
