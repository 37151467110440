import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetAclArguments from "api/security/acl/Types/GetAclArguments";

const GetAcl = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Acl",
    method: "get_acl",
    arguments: types.maybe(GetAclArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetAcl
