import { types } from "mobx-state-tree";

import BackupSettings from "api/system_components/backup_schedule/Types/BackupSettings";

const GetBackupSettingsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(BackupSettings)
});

export default GetBackupSettingsResult;
