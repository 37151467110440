import diskSizeValidator from "./utils/diskSize.validator";

const createFields = () => {
    return [
        {
            name: "poolName"
        },
        {
            name: "diskName",
            rules: "required|diskName"
        },
        {
            name: "sizeInput",
            type: "number",
            rules: "required",
            input: input => (input.length ? Number(input) : ""),
            placeholder: "0",
            validators: [diskSizeValidator()]
        },
        {
            name: "sizeUnitValue",
            input: input => Number(input)
        },
        {
            name: "raid",
            type: "number",
            input: input => Number(input)
        },
        {
            name: "stripeSize",
            type: "number",
            input: input => Number(input)
        },
        {
            name: "profile",
            default: 0,
            type: "number",
            input: input => Number(input)
        },
        {
            name: "addMultiple",
            type: "checkbox"
        },
        {
            name: "bcacheEnabled",
            type: "checkbox"
        },
        {
            name: "bcacheDrive"
        },
        {
            name: "__maxSizeResponse",
            default: {}
        }
    ];
};

export default createFields;
