import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useLocation, withRouter } from "react-router-dom";
import AppRouter from "../AppRouter";
import UpgradeBar from "components/MainTemplate/components/UpgradeBar";
import BootLoadPage from "pages/BootLoadPage";
import { URL_SPLASH_PAGE } from "routes";
import { useStore } from "hooks/useStore";
import { autorun } from "mobx";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Notifier from "store/ProcessingStore/Notifier";
import { ADMIN } from "const/userRolesConst";
import MigrationPage from "pages/Migration/Migration";

export const VOLUME_BUILDING_STATUS = "volume.building";

const AppBody = (props) => {
    const { store, multipleEvosRootStore } = useStore();
    const { bootProgressStore, authStore, volumeStore, migrationStore } = store;

    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const hasBuildDiskSnackbarRef = useRef(false);

    const { t } = useTranslation();

    useEffect(() => {
        store.initHistory(props.history);
    }, [props.history]);

    useEffect(() => {
        autorun(() => {
            if (!authStore.isAuthorized || !(bootProgressStore.isRunning || !props.isLoaded)) {
                closeSnackbar(VOLUME_BUILDING_STATUS);
                hasBuildDiskSnackbarRef.current = false;
                return;
            }

            const hasVolumesWithBuildingStatus = volumeStore.volumes.find(({ status }) => status.health === "building");

            if (!hasBuildDiskSnackbarRef.current && hasVolumesWithBuildingStatus && authStore.role === ADMIN) {
                enqueueSnackbar(t("notifier.volume_building_warning"), {
                    persist: true,
                    variant: "warning",
                    key: VOLUME_BUILDING_STATUS,
                });
                hasBuildDiskSnackbarRef.current = true;

                return;
            }

            if (!hasVolumesWithBuildingStatus) {
                closeSnackbar(VOLUME_BUILDING_STATUS);
                hasBuildDiskSnackbarRef.current = false;
            }
        });

        autorun(() => {
            if (authStore.isAuthorized) {
                volumeStore.fetchVolumes();
            }
        });
    }, []);

    const { pathname } = useLocation();

    const ROUTES_WITHOUT_UPGRADE_BAR = [URL_SPLASH_PAGE];
    const isAllowedRouteForUpgradeBar = ROUTES_WITHOUT_UPGRADE_BAR.every((route) => route !== pathname);
    const isVisibleUpgradeBar = authStore.isAuthorized && isAllowedRouteForUpgradeBar;

    const isReadyInterface = bootProgressStore.isRunning || !props.isLoaded;
    const needShowMigrationWizard =
        (migrationStore.isMigrationProcess || migrationStore.isSuccessMigration) &&
        isReadyInterface &&
        !migrationStore.isFinishedMigration;

    if (needShowMigrationWizard) return <MigrationPage />;

    if (isReadyInterface)
        return (
            <React.Fragment>
                <AppRouter />
                <Notifier />
                {multipleEvosRootStore.storesIps.map((ip) => (
                    <Notifier key={ip} ip={ip} />
                ))}
                {isVisibleUpgradeBar && <UpgradeBar />}
            </React.Fragment>
        );

    return <BootLoadPage />;
};

export default withRouter(observer(AppBody));
