import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3, 0)
    },
    controlContainer: {
        margin: 0,
        width: "100%",
        alignItems: "center",
        flexDirection: "row-reverse",
        justifyContent: "flex-end",
        "& > span:last-child": {
            width: props => props.width || "50%"
        },
        "&>.MuiFormControlLabel-label": {
            bottom: 0
        }
    }
}));
