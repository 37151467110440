import { types } from "mobx-state-tree";

import ShareLocationInfo from "api/share/Types/ShareLocationInfo";
import ShareAttributes from "api/share/Types/ShareAttributes";

const CreateShareArguments = types.model({
    location: types.maybe(ShareLocationInfo),
    attributes: types.maybe(ShareAttributes),
    force: types.boolean
});

export default CreateShareArguments;
