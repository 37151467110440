import { types } from "mobx-state-tree";

const RESULT_ARRAY = ["IN PROGRESS", "SUCCEED", "CANCELED", "FAILED", "PAUSED", "WAITED FOR RESTART", "PARTIALLY FAILED"];

const InstanceType = types.model({
    id: types.number,
    automation_id: types.maybe(types.number),
    started: types.maybe(types.string),
    finished: types.maybe(types.string),
    result: types.maybe(types.enumeration(RESULT_ARRAY)),
    walks: types.maybe(
        types.array(
            types.model({
                id: types.number,
                started: types.maybe(types.string),
                finished: types.maybe(types.string),
                processed: types.maybe(types.string),
                type: types.maybe(types.string),
                event_count: types.optional(types.number, 0),
                processed_event_count: types.optional(types.number, 0),
                unprocessed_event_count: types.optional(types.number, 0),
                failed_event_count: types.optional(types.number, 0)
            })
        )
    )
});

export default InstanceType;
