import React from "react";
import { observer, useLocalStore } from "mobx-react";
import Dialog from "components/Dialog";
import { Button, Grid, Typography } from "@material-ui/core";
import i18next from "i18next";
import { useEditWatermarkModal } from "hooks/modals";
import TextInput from "components/MobxForm/TextInput";
import createForm from "utils/createForm";
import { createFields } from "./EditWatermarkDialog.utils";
import { useStore } from "hooks/useStore";

const EDIT_WATERMARK_DIALOG_ID_PREFIX = `edit_watermark_dialog`;

const EditWatermarkDialog = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${EDIT_WATERMARK_DIALOG_ID_PREFIX}` : EDIT_WATERMARK_DIALOG_ID_PREFIX;
    const modal = useEditWatermarkModal();
    const {
        store: { slingshotSettingsStore },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
        }),
    }));

    const onSubmit = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        await slingshotSettingsStore.updateWatermark(state.form.values());
    };

    const onEnter = () => {
        state.form.$("name").set("default", slingshotSettingsStore.currentWatermark.name);
        state.form.reset();
        state.form.each((field) => {
            field.resetValidation();
        });
    };

    return (
        <Dialog
            onClose={modal.close}
            open={modal.isOpen}
            title={i18next.t("slingshot_page.tabs.settings.watermarks.edit_dialog.title", {
                name: slingshotSettingsStore.currentWatermarkName,
            })}
            submitBtnLabel={i18next.t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            onSubmit={onSubmit}
            maxWidth={"md"}
            onEnter={onEnter}
            buttons={
                <Button onClick={modal.close} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {i18next.t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container spacing={2}>
                <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography>{i18next.t("slingshot_page.tabs.settings.watermarks.edit_dialog.name")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput field={state.form.$("name")} inputProps={{ spellCheck: "false" }} id={`${idPrefix}_name`} />
                    </Grid>
                </Grid>

                {/*[SLN-379] Hide Builtin, Personal, Disabled, Image loaded, Owner */}
            </Grid>
        </Dialog>
    );
};

export default observer(EditWatermarkDialog);
