import { types } from "mobx-state-tree";

import Request from "api/Request";
import VolumeNameArgument from "api/cluster/Types/VolumeNameArgument";

const VolumeStop = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Cluster",
    method: "volume_stop",
    arguments: types.maybe(VolumeNameArgument)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default VolumeStop
