import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetConfig from "api/slingshot/indexing_config/Requests/GetConfig";
import SetConfig from "api/slingshot/indexing_config/Requests/SetConfig";
import GetConfigResult from "api/slingshot/indexing_config/Responses/GetConfigResult";
import GetProxySettings from "api/slingshot/proxy_settings/Requests/GetProxySettings";
import GetProxySettingsResult from "api/slingshot/proxy_settings/Responses/GetProxySettingsResult";
import SetProxySettings from "api/slingshot/proxy_settings/Requests/SetProxySettings";
import moment from "moment";
import { DEFAULT_HOST, PAUSE_MINUTES_DEFAULT } from "const/slingshotIndexerConfigConst";

const IndexingConfigStore = types
    .model({
        configStore: types.maybe(GetConfigResult),
        proxySettingsStore: types.maybe(GetProxySettingsResult),
        isConfigLoaded: types.optional(types.boolean, false),
        isConfigLoading: types.optional(types.boolean, false),
        disabledFetch: types.optional(types.boolean, false),
        errorConnectedDBus: types.optional(types.boolean, false),
    })
    .volatile(() => ({
        indexerConfigRawData: types.maybe(types.string),
    }))
    .views((self) => ({
        get editProxy() {
            return self.configStore?.data?.json?.editProxy || new Map();
        },
        get editProxyShares() {
            return self.configStore?.data?.json?.editProxy?.shares || new Map();
        },
        get editProxyShareParams() {
            return self.configStore?.data?.json?.mediaProxy?.shareParams || new Map();
        },
        get mediaProxy() {
            return self.configStore?.data?.json?.mediaProxy || new Map();
        },
        get folderBlackList() {
            return self.configStore?.data?.json?.folderBlackList || new Map();
        },
        get mediaProxyShares() {
            return self.configStore?.data?.json?.mediaProxy?.shares || new Map();
        },
        get mediaProxyShareParams() {
            return self.configStore?.data?.json?.mediaProxy?.shareParams || new Map();
        },
        get indexerName() {
            return self.configStore?.data?.json?.indexerName || "";
        },
        get host() {
            return self.configStore?.data?.json?.postmap?.host || DEFAULT_HOST;
        },
        get postmap() {
            return self.configStore?.data?.json?.postmap;
        },
        get path() {
            return self.configStore?.data?.json?.postmap?.path || "";
        },
        get isPushMetadataEnabled() {
            return self.host !== DEFAULT_HOST;
        },
        get destinationPath() {
            const destinationPath = self.configStore?.data?.json?.mediaProxy?.destinationPath;
            let share = "",
                rel = "";
            if (destinationPath) {
                const pattern = "^/*([^/]+)/(.*)$";
                const found = destinationPath.match(pattern);
                if (found) {
                    share = found[1];
                    rel = found[2];
                } else {
                    share = destinationPath.replace("/^/+/g", "");
                }
            }
            return { destinationShare: share, destinationRelPath: rel };
        },
        get pauseSchedule() {
            return self.configStore?.data?.json?.slingshot?.pauseDefaultSchedule;
        },
        get pauseStartTime() {
            const { evoSettingsStore } = getParent(self);
            let pauseStart = moment.unix(evoSettingsStore.evoInfoTime);
            if (self.pauseSchedule) {
                pauseStart = moment(self.pauseSchedule.date);
            }
            return pauseStart;
        },
        get pauseEndTime() {
            const { evoSettingsStore } = getParent(self);
            let pauseEnd = moment.unix(evoSettingsStore.evoInfoTime);
            if (self.pauseSchedule) {
                pauseEnd = moment(moment(self.pauseSchedule.date).add(self.pauseSchedule.duration, "minutes"));
            }
            return pauseEnd;
        },
        get adHocRequestWithoutPause() {
            return self.configStore?.data?.json?.mediaProxy?.adHocRequestWithoutPause || false;
        },
        get pauseEnabled() {
            return self.pauseSchedule ? !self.pauseSchedule.disabled : false;
        },
        get indexerConfigFromRawData() {
            return (self.indexerConfigRawData && JSON.parse(self.indexerConfigRawData)) || {};
        },
        get isErrorConnectedDBus() {
            return self.errorConnectedDBus;
        },
    }))
    .actions((self) => ({
        fetchConfig: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                if (self.disabledFetch) {
                    return true;
                }
                processingStore.setLoading(true);
                self.disabledFetch = true;
                self.isConfigLoading = true;
                const req = GetConfig.create().init({ json: "{}" });
                const res = yield Socket.send(req);
                self.indexerConfigRawData = res.data?.json;
                self.configStore = res;
                self.isConfigLoaded = true;
                return true;
            } catch (e) {
                switch (e.code) {
                    case 404:
                        self.errorConnectedDBus = true;
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        self.disabledFetch = false;
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
                self.isConfigLoading = false;
            }
        }),
        setConfigParameters: flow(function* (payload) {
            if (!self.configStore) return null;
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                self.isConfigLoading = true;
                const req = SetConfig.create().init({ json: JSON.stringify(payload) });
                yield Socket.send(req);
                self.disabledFetch = false;
                self.fetchConfig();
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
                self.isConfigLoading = false;
            }
            return null;
        }),
        setIndexerName(name) {
            const payload = { ...self.indexerConfigFromRawData };
            payload.indexerName = name;
            return self.setConfigParameters(payload);
        },
        setMediaProxyConfigEnable(data) {
            const payload = { ...self.indexerConfigFromRawData };
            data.map((el) => {
                payload.mediaProxy.shares = { ...payload.mediaProxy.shares, [el]: true };
                return "";
            });
            return self.setConfigParameters(payload);
        },
        setMediaProxyConfigDisable(data) {
            const payload = { ...self.indexerConfigFromRawData };
            data.map((el) => {
                payload.mediaProxy.shares = { ...payload.mediaProxy.shares, [el]: false };
                return "";
            });
            return self.setConfigParameters(payload);
        },
        setEditProxyConfigEnable(data) {
            const payload = { ...self.indexerConfigFromRawData };
            data.map((el) => {
                payload.editProxy.shares = { ...payload.editProxy.shares, [el]: true };
                return "";
            });
            return self.setConfigParameters(payload);
        },
        setEditProxyConfigDisable(data) {
            const payload = { ...self.indexerConfigFromRawData };
            data.map((el) => {
                payload.editProxy.shares = { ...payload.editProxy.shares, [el]: false };
                return "";
            });
            return self.setConfigParameters(payload);
        },
        setExcludedFoldersList(shareName, data) {
            const payload = { ...self.indexerConfigFromRawData };
            payload.mediaProxy.blackList = { ...payload.mediaProxy.blackList, [shareName]: data };
            payload.editProxy.blackList = { ...payload.editProxy.blackList, [shareName]: data };
            return self.setConfigParameters(payload);
        },
        setExcludedIndexFoldersList(shareName, data) {
            const payload = { ...self.indexerConfigFromRawData };
            payload.folderBlackList = { ...payload.folderBlackList, [shareName]: data };
            return self.setConfigParameters(payload);
        },
        setExcludedExtensionsList(shareName, data) {
            const payload = { ...self.indexerConfigFromRawData };
            payload.mediaProxy.shareParams = { ...payload.mediaProxy.shareParams, [shareName]: data };
            payload.editProxy.shareParams = { ...payload.editProxy.shareParams, [shareName]: data };
            return self.setConfigParameters(payload);
        },
        setCommonExtensionExclusions(data) {
            const payload = { ...self.indexerConfigFromRawData };
            payload.mediaProxy.extensionBlackList = data;
            payload.editProxy.extensionBlackList = data;
            return self.setConfigParameters(payload);
        },
        setPostMap: flow(function* (postmap) {
            if (!self.configStore) return null;
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                self.isConfigLoading = true;

                const payload = { ...self.indexerConfigFromRawData };
                payload.postmap = postmap;

                const req = SetConfig.create().init({ json: JSON.stringify(payload) });
                yield Socket.send(req);
                self.disabledFetch = false;
                self.fetchConfig();
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
                self.isConfigLoading = false;
            }
            return null;
        }),
        setProxyDestination: flow(function* (destination) {
            if (!self.configStore) return null;
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                self.isConfigLoading = true;

                const payload = { ...self.indexerConfigFromRawData };
                payload.mediaProxy = { ...payload.mediaProxy, destinationPath: destination };

                const req = SetConfig.create().init({ json: JSON.stringify(payload) });
                yield Socket.send(req);
                self.disabledFetch = false;
                self.fetchConfig();
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
                self.isConfigLoading = false;
            }
            return null;
        }),
        setPause: flow(function* ({ beginDate, duration, enabled, adHocRequestWithoutPause }) {
            if (!self.configStore) return null;
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                self.isConfigLoading = true;

                const payload = { ...self.indexerConfigFromRawData };
                payload.mediaProxy = { ...payload.mediaProxy, adHocRequestWithoutPause };
                payload.slingshot = {
                    ...payload.slingshot,
                    pauseDefaultSchedule: {
                        minutes: PAUSE_MINUTES_DEFAULT,
                        duration,
                        date: beginDate,
                        disabled: !enabled,
                    },
                };

                const req = SetConfig.create().init({ json: JSON.stringify(payload) });
                yield Socket.send(req);
                self.disabledFetch = false;
                self.fetchConfig();
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
                self.isConfigLoading = false;
            }
            return null;
        }),
        fetchProxySettings: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetProxySettings.create().init();
                const res = yield Socket.send(req);

                self.proxySettingsStore = res;
                return true;
            } catch (e) {
                switch (e.code) {
                    case 404:
                        self.errorConnectedDBus = true;
                        return new Error(e.message);
                    default:
                        processingStore.setError(e);
                        return null;
                }
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setProxySettings: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetProxySettings.create().init(data);
                const res = yield Socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        resetErrors() {
            self.errorConnectedDBus = false;
            self.disabledFetch = false;
        },
        enableErrorConnectedDBus() {
            self.errorConnectedDBus = true;
        },
    }));

export default IndexingConfigStore;
