import React, { useEffect, useRef } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Grid, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import * as d3 from "d3";
import { withFauxDOM } from "react-faux-dom";

import { timeRegExp, DEFAULT_START_PERIOD, TICK_X_COUNT } from "const/chartConst";
import { MiB_S } from "const/speedConst";
import { useStyles } from "./NetworkGraph.style";

const marginLeft = 30;
const marginRight = 40;
const marginTop = 10;
const marginBottom = 20;
const textMargin = 12;
const textHeight = 18;

const NetworkGraph = ({
    parentId = "networkWidget",
    height = 150,
    animDuration = 300,
    chartInfo,
    startFetching,
    stopFetching,
    lastTime,
    lastInValue,
    lastOutValue,
    connectFauxDOM,
    animateFauxDOM,
    isDark,
    iface,
    graphStartPeriod = DEFAULT_START_PERIOD,
    ...props
}) => {
    const parenfRef = useRef();
    const classes = useStyles({ isDark });
    const { t } = useTranslation();
    // This allows to find the closest X index of the mouse
    const bisect = d3.bisector((d) => d.date);
    const minYIn = d3.min(chartInfo, (i) => i.valueIn);
    const maxYOut = d3.max(chartInfo, (i) => i.valueOut);
    const maxYbound = maxYOut + parseFloat((maxYOut * 0.2).toFixed(2));
    const minYbound = minYIn + parseFloat((minYIn * 0.2).toFixed(2));
    const chartId = `chart-${parentId}`;
    const ticksX = d3.timeMinute.every(Math.round(graphStartPeriod / 60 / TICK_X_COUNT));
    const state = useLocalStore(() => ({
        svg: null,
        svgCoorditates: { x: 0, y: 0 },
        xScale: null,
        yScale: null,
        xaxis: null,
        yaxis: null,
        lineIn: null,
        lineOut: null,
        pointIn: null,
        pointOut: null,
        selectedTime: "",
        selectedInValue: null,
        selectedOutValue: null,
        isChartCreated: false,
        setSelectedTime(time) {
            this.selectedTime = time;
        },
        setSelectedInValue(value) {
            this.selectedInValue = value;
        },
        setSelectedOutValue(value) {
            this.selectedOutValue = value;
        },
    }));

    useEffect(() => {
        state.isChartCreated ? updateChart() : createChart();
    }, [chartInfo]);

    function mouseover() {
        state.pointIn.style("display", null);
        state.pointOut.style("display", null);
        state.svgCoorditates = parenfRef.current.getBoundingClientRect();
        stopFetching && stopFetching(true);
    }

    function mousemove(event) {
        if (chartInfo.length === 0) return;

        const xCoord = event.x - state.svgCoorditates.x;
        const x0 = state.xScale.invert(xCoord);
        const i = bisect.left(chartInfo, x0);
        const selectedData = chartInfo[i] ? chartInfo[i] : chartInfo[i - 1];

        state.pointIn.attr("transform", `translate(${state.xScale(selectedData.date)}, ${state.yScale(selectedData.valueIn)})`);
        state.pointOut.attr("transform", `translate(${state.xScale(selectedData.date)}, ${state.yScale(selectedData.valueOut)})`);
        animateFauxDOM(animDuration);

        state.setSelectedTime(d3.timeFormat(timeRegExp)(selectedData.date));
        state.setSelectedInValue(selectedData.valueIn);
        state.setSelectedOutValue(selectedData.valueOut);
    }

    function mouseout() {
        state.pointIn.style("display", "none");
        state.pointOut.style("display", "none");
        state.setSelectedTime("");
        state.setSelectedInValue(null);
        state.setSelectedOutValue(null);
        startFetching && startFetching(iface.port, true);
    }

    function createChart() {
        state.isChartCreated = true;
        const faux = connectFauxDOM("g", chartId);
        const wrapper = d3.select(`#${parentId}`);

        wrapper.select("svg").attr("width", parenfRef.current.offsetWidth);
        state.svg = d3.select(faux);
        state.svg.attr("width", parenfRef.current.offsetWidth);
        state.xScale = d3
            .scaleTime()
            .domain(d3.extent(chartInfo, (d) => d.date))
            .range([marginLeft, parenfRef.current.offsetWidth - marginRight]);
        state.yScale = d3
            .scaleLinear()
            .domain([minYbound, maxYbound])
            .range([height - marginBottom, marginTop]);
        state.xaxis = d3
            .axisTop()
            .scale(state.xScale)
            .tickFormat(d3.timeFormat(timeRegExp))
            .tickSize(height - marginTop - marginBottom)
            .ticks(ticksX);
        state.yaxis = d3
            .axisLeft()
            .scale(state.yScale)
            .tickSize(parenfRef.current.offsetWidth - marginLeft - marginRight)
            .ticks(3);
        state.lineIn = d3
            .line()
            .x((d) => state.xScale(d.date))
            .y((d) => state.yScale(d.valueIn));
        state.lineOut = d3
            .line()
            .x((d) => state.xScale(d.date))
            .y((d) => state.yScale(d.valueOut));
        state.pointIn = state.svg.append("g").append("circle").attr("r", 3).attr("class", "pointIn").style("display", "none");
        state.pointOut = state.svg.append("g").append("circle").attr("r", 3).attr("class", "pointOut").style("display", "none");

        state.svg
            .append("g")
            .attr("transform", `translate(0, ${height - marginBottom})`)
            .attr("class", "x axis")
            .call(state.xaxis)
            .call((g) =>
                g.selectAll(".tick text").attr("transform", `translate(0, ${height - marginTop - marginBottom + textHeight})`)
            );
        state.svg
            .append("g")
            .attr("class", "y axis")
            .attr("transform", `translate(${parenfRef.current.offsetWidth - marginRight}, 0)`)
            .call(state.yaxis)
            .call((g) =>
                g
                    .selectAll(".tick text")
                    .attr("transform", `translate(${parenfRef.current.offsetWidth - marginRight - marginLeft + textMargin}, 0)`)
            );
        state.svg.append("g").append("path").attr("d", state.lineIn(chartInfo)).attr("class", "graph graphIn");
        state.svg.append("g").append("path").attr("d", state.lineOut(chartInfo)).attr("class", "graph graphOut");
        state.svg
            .append("rect")
            .style("fill", "none")
            .style("pointer-events", "all")
            .attr("class", "rect")
            .attr("width", parenfRef.current.offsetWidth - marginRight - marginLeft)
            .attr("height", height - marginBottom - marginTop)
            .attr("transform", `translate(${marginRight}, ${marginTop})`)
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseout", mouseout);
    }

    function updateChart() {
        const wrapper = d3.select(`#${parentId}`);

        wrapper.select("svg").attr("width", parenfRef.current.offsetWidth);
        state.svg.attr("width", parenfRef.current.offsetWidth);
        state.xScale.domain(d3.extent(chartInfo, (d) => d.date)).range([marginLeft, parenfRef.current.offsetWidth - marginRight]);
        state.yScale.domain([minYbound, maxYbound]);
        state.svg
            .select(".x.axis")
            .call(state.xaxis)
            .call((g) =>
                g.selectAll(".tick text").attr("transform", `translate(0, ${height - marginTop - marginBottom + textHeight})`)
            );
        state.svg
            .select(".y.axis")
            .call(state.yaxis)
            .call((g) =>
                g
                    .selectAll(".tick text")
                    .attr("transform", `translate(${parenfRef.current.offsetWidth - marginRight - marginLeft + textMargin}, 0)`)
            );
        state.svg.select(".point").style("display", "none");
        state.svg
            .select(".rect")
            .attr("width", parenfRef.current.offsetWidth - marginRight - marginLeft)
            .on("mousemove", mousemove);
        state.svg.select(".graphIn").transition().duration(animDuration).attr("d", state.lineIn(chartInfo));
        state.svg.select(".graphOut").transition().duration(animDuration).attr("d", state.lineOut(chartInfo));

        animateFauxDOM(animDuration);
    }

    return useObserver(() => {
        const inVal = state.selectedInValue !== null ? state.selectedInValue : lastInValue;
        const outVal = state.selectedOutValue !== null ? state.selectedOutValue : lastOutValue;
        const ifaceName = /^vs.+/.test(iface.port) ? iface.interfaces.join(", ") : iface.port;

        return (
            <Grid container className={classes.container}>
                <Grid container item xs={2} direction="column" justify="space-between" className={classes.info}>
                    <Grid item>
                        <Typography className={classes.name}>{t("network_graph.title")}</Typography>
                        <Typography className={classes.label}>
                            {t("network_graph.out", { ifaceName: ifaceName })}
                        </Typography>
                        <Typography className={classes.value}>
                            {outVal}
                            <Box component="span">{MiB_S.unit}</Box>
                        </Typography>
                        <Typography className={classes.label}>
                            {t("network_graph.in", { ifaceName: ifaceName })}
                        </Typography>
                        <Typography className={`${classes.value} valueIn`}>
                            {inVal}
                            <Box component="span">{MiB_S.unit}</Box>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.label}>{state.selectedTime || lastTime}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={10} id={parentId} ref={parenfRef} className={classes.graph}>
                    <svg height={height} className={classes.svg}>
                        {props[chartId]}
                    </svg>
                </Grid>
            </Grid>
        );
    });
};

export default withFauxDOM(NetworkGraph);
