import React from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./LabelWithIndicator.styles";

const LabelWithIndicator = ({ label, color }) => {
    const classes = useStyles({ color });

    return (
        <Grid container alignItems={"center"} wrap={"nowrap"}>
            <Grid item className={classes.oval} />
            <Grid item>{label}</Grid>
        </Grid>
    );
};

export default observer(LabelWithIndicator);
