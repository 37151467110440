import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Popover } from "@material-ui/core";
import { useStyles } from "./ButtonLine.styles";

import ChevronRightIcon from "assets/ChevronRightIcon";
import { useEventListener } from "hooks/useEventListener";
import { useDebounce } from "hooks/useDebounce";
import { useToggle } from "hooks/useToggle";

const ButtonLine = ({ children, link = "", icon: Icon }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isHide, toggleHide] = useToggle();
    const router = useHistory();

    const classes = useStyles();

    const containerRef = useRef(null);
    const iconRef = useRef(null);
    const textRef = useRef(null);
    const chevronRef = useRef(null);

    const handleResize = () => {
        let fullWidthContent =
            iconRef.current.offsetWidth + textRef.current.offsetWidth + chevronRef.current.offsetWidth + 32 + 12;
        fullWidthContent = isHide ? fullWidthContent + 1 : fullWidthContent;

        if (fullWidthContent > containerRef.current.offsetWidth) {
            textRef.current.style.overflow = "hidden";
            toggleHide(true);
            return;
        }

        toggleHide(false);
        textRef.current.style.overflow = "initial";
    };

    useEffect(handleResize, []);
    const debounceHandleResize = useDebounce(handleResize, 100);
    useEventListener("resize", debounceHandleResize.run);

    const handlePopoverOpen = (event) => {
        if (isHide) setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        if (isHide) setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const onClick = () => {
        if (link) {
            if (link.includes("http")) return window.open(link, "_blank");

            router.replace(link);
        }
    };

    return (
        <Grid
            onClick={onClick}
            className={classes.containerOut}
            container
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            innerRef={containerRef}
        >
            <Grid item innerRef={iconRef}>
                <Icon />
            </Grid>
            <Grid item className={classes.content} innerRef={textRef}>
                <Typography variant={"subtitle1"}>{children}</Typography>
            </Grid>
            {isHide ? <Grid item className={classes.shadow} /> : null}
            <Grid item innerRef={chevronRef}>
                <ChevronRightIcon />
            </Grid>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
            >
                <Typography>{children}</Typography>
            </Popover>
        </Grid>
    );
};

export default ButtonLine;
