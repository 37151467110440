import React, { useEffect, useContext } from "react";
import { Context } from "store";
import moment from "moment";
import { observer, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TableCell } from "@material-ui/core";

const TimeToStartCell = ({ status, schedule }) => {
    const { t } = useTranslation();

    const {
        store: { evoSettingsStore }
    } = useContext(Context);

    const state = useLocalStore(() => ({
        timeToStart: "-"
    }));

    useEffect(() => {
        let timeToStart = "-";
        if (schedule?.schedule && status === t("slingshot.automations.table.label.status.waiting")) {
            const { date: scheduleDateString, minutes } = schedule.schedule;

            const period = minutes || null;
            const evoTime = moment.unix(evoSettingsStore.evoInfoTime);
            if (scheduleDateString && evoTime && period && period > 0) {
                let scheduleDate = moment(scheduleDateString);
                const now = evoTime;
                const diff = scheduleDate.diff(now);
                if (diff < 0) {
                    const count = Math.ceil(-diff / (period * 60 * 1000));
                    scheduleDate.add(period * count, "minutes");
                }
                timeToStart = scheduleDate.from(now, true);
            }
        }
        timeToStart !== state.timeToStart && (state.timeToStart = timeToStart);
    }, [evoSettingsStore.evoInfoTime]);

    return <TableCell>{state.timeToStart}</TableCell>;
};

export default observer(TimeToStartCell);
