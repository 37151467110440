import React, { useEffect } from "react";
import { useObserver } from "mobx-react";
import { Grid, Avatar, Typography, MenuItem, FormControlLabel, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./UserSection.styles";
import UserMenu from "../UserMenu";
import LogoutFromMultipleEvosDialog from "./componenets/LogoutFromMultipleEvosDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { useDarkTheme } from "theme/themeContext";
import OptionsAdminIcon from "assets/OptionsAdminIcon";
import LogoutIcon from "assets/LogoutIcon";
import Switch from "components/Switch";
import { Link as RouterLink } from "react-router-dom";
import { URL_ADMIN_SETTINGS } from "routes";

const UserSection = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: { authStore, evoSettingsStore, multipleEvosStore, uiStore },
    } = useStore();

    const logoutFromMultipleEvosModal = useModal();
    const darkTheme = useDarkTheme();

    const getName = () => {
        const usernameFromLocalStorage = localStorage.getItem("userName");

        if (usernameFromLocalStorage && usernameFromLocalStorage !== "null") {
            return usernameFromLocalStorage;
        }

        return authStore.username;
    };

    const logout = () => {
        logoutFromMultipleEvosModal.close();
        authStore.logout();
        if (uiStore.isWidgetsBarOpen) uiStore.closeWidgetsBar();
    };

    const logoutFromAllEvos = () => {
        multipleEvosStore.authorizedConnections.forEach((connection) => {
            multipleEvosStore.logoutFromMultipleEvo(connection.ip);
        });

        logout();
    };

    const logoutFromMainEvo = () => {
        multipleEvosStore.authorizedConnections.forEach((connection) => {
            multipleEvosStore.closeConnection(connection.ip);
        });

        logout();
    };

    const openLogoutFromMultipleEvosDialogIfNeeded = () => {
        multipleEvosStore.authorizedConnectionsCount > 0 ? logoutFromMultipleEvosModal.open() : logout();
    };

    useEffect(() => {
        authStore.restoreUsernameFromLocalStorage();
    }, []);

    return useObserver(() => (
        <Grid container className={classes.container} alignItems={"center"} alignContent={"center"} wrap={"nowrap"}>
            <LogoutFromMultipleEvosDialog
                open={logoutFromMultipleEvosModal.isOpen}
                onClose={logoutFromMultipleEvosModal.close}
                logoutFromAllEvos={logoutFromAllEvos}
                logoutFromMainEvo={logoutFromMainEvo}
            />
            <UserMenu
                title={
                    <>
                        <Avatar className={classes.userPic} />
                        <Typography className={classes.userName} color={"textSecondary"}>
                            {getName()}
                        </Typography>
                    </>
                }
            >
                <MenuItem onClick={darkTheme.toggle}>
                    <FormControlLabel
                        className={classes.switchLabel}
                        labelPlacement={"end"}
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                        control={<Switch checked={darkTheme.isSwitched} />}
                        label={<Typography className={classes.textItem}>{t("header.user_menu.option.dark_mode")}</Typography>}
                    />
                </MenuItem>
                <Divider className={classes.divider} />
                {authStore.isAdmin && (
                    <RouterLink className={classes.linkItem} to={URL_ADMIN_SETTINGS}>
                        <MenuItem>
                            <OptionsAdminIcon />
                            <Typography className={classes.textItem}>{t("header.user_menu.option.admin_settings")}</Typography>
                        </MenuItem>
                    </RouterLink>
                )}
                <MenuItem onClick={openLogoutFromMultipleEvosDialogIfNeeded}>
                    <LogoutIcon className={classes.logoutIcon} />
                    <Typography className={classes.textItem}>{t("header.user_menu.option.logout")}</Typography>
                </MenuItem>
                <Divider className={classes.divider} />
                <Typography className={classes.version}>
                    {evoSettingsStore.evoVersion}
                </Typography>
            </UserMenu>
        </Grid>
    ));
};

export default UserSection;
