import React from "react";

function ViewIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
                fill="#1E90FF"
                fillRule="evenodd"
                d="M11.73 5c5.98-.18 9.5 5.39 10.14 6.5.179.31.179.69 0 1l-.06.103c-.94 1.606-4.13 6.26-9.54 6.397h-.25c-5.89 0-9.26-5.41-9.89-6.5a.998.998 0 010-1l.06-.103C3.14 9.791 6.32 5.137 11.73 5zm.419 1.999L11.83 7c-4 .1-6.61 3.39-7.61 5 .88 1.41 3.69 5.1 8 5 4-.1 6.58-3.39 7.61-5-.89-1.41-3.71-5.11-8-5zM12 8.5l.192.005A3.5 3.5 0 0115.5 12l-.005.192A3.5 3.5 0 1112 8.5zm0 2a1.5 1.5 0 000 3l.144-.007A1.5 1.5 0 0013.5 12l-.007-.144A1.5 1.5 0 0012 10.5z"
            />
        </svg>
    );
}

export default ViewIcon;
