import { makeStyles } from "@material-ui/core";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => {
    const getColor = (status) => {
        switch (status.toLowerCase()) {
            case "online":
            case "good":
                return theme.palette.success.main;
            case "offline":
            case "error":
                return theme.palette.error.main;
            case "connecting":
            case "warning":
                return theme.palette.warning.main;
            default:
                return theme.palette.primary.main;
        }
    };
    return {
        content: {
            minWidth: 640,
            overflowX: "auto",
        },
        headerItem: {
            padding: theme.spacing(4, 3),
        },
        headerPort: {
            width: "16.67%",
        },
        headerIp: {
            width: "25%",
        },
        headerMac: {
            width: "25%",
        },
        headerSpeed: {
            width: "16.67%",
        },
        headerStatus: {
            width: "8.33%",
        },
        headerButton: {
            width: "8.33%",
        },
        rowItem: {
            minHeight: "54px",
        },
        rowDrag: {
            cursor: "grab",
        },
        rowPort: {
            minWidth: "16.67%", // = headerPort
        },
        rowIp: {
            width: "30%", // 12 / 10 * headerIp
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        rowMac: {
            width: "30%", // 12 / 10 * headerMac
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        rowSpeed: {
            width: "20%", // 12 / 10 * headerSpeed
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        rowStatus: {
            width: "10%", // 12 / 10 * headerStatus
        },
        rowButton: {
            width: "10%", // 12 / 10 * headerButton
        },
        row: {
            opacity: ({ isDragging }) => (isDragging ? 0 : 1),
            padding: theme.spacing(1, 0),
            "&:hover": {
                cursor: "grab",
                "& path": {
                    fill: theme.palette.primary.main,
                },
            },
        },
        container: {
            height: "100%",
            width: "100%",
        },
        label: {
            padding: theme.spacing(0, 2),
            borderRadius: theme.spacing(1),
            backgroundColor: (props) => hexToRgba(getColor(props.status), 0.1),
            "& span": {
                color: (props) => getColor(props.status),
            },
        },
        burger: {
            "& path": { fill: theme.palette.grey[500] },
        },
    };
});
