import { types } from "mobx-state-tree";

import Request from "api/Request";
import BeepMuteArguments from "api/events/Types/BeepMuteArguments";

const BeepMute = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Events",
    method: "beep_mute",
    arguments: types.maybe(BeepMuteArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default BeepMute;
