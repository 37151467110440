import { types } from "mobx-state-tree";
const SORT_ENUM_ARRAY = ["ID", "SOURCE", "DESTINATION", "CAUGHT", "PROCESSED"];
const SORT_DIR_ENUM_ARRAY = ["ASC", "DESC"];

const GetProcessedFilesArguments = types.model({
    automationId: types.maybe(types.number),
    instanceId: types.maybe(types.number),
    sort: types.maybe(types.enumeration(SORT_ENUM_ARRAY)),
    sort_dir: types.maybe(types.enumeration(SORT_DIR_ENUM_ARRAY)),
    offset: types.maybe(types.number),
    limit: types.maybe(types.number),
    include_ctrl_events: types.maybe(types.boolean),
    hide_succeed: types.maybe(types.boolean),
    walk: types.maybe(types.array(types.number))
});

export default GetProcessedFilesArguments;
