import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    typeSelectContainer: {
        marginLeft: theme.spacing(-3) - 1
    },
    typeSelectRowContainer: {
        padding: theme.spacing(1, 0)
    }
}));
