import React from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, observer } from "mobx-react";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import SetIndexerNameModal from "./components/SetIndexerNameModal";
import { SLINGSHOT_INDEXER_CARD } from "const/widgetNameConst";
import { useStore } from "hooks/useStore";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import { useLauncher } from "hooks/useLauncher";

const INDEXER_NAME_CARD_ID_PREFIX = `indexer_name_card`;

const IndexerNameCard = ({ parentId }) => {
    const idPrefix = parentId ? `${parentId}_${INDEXER_NAME_CARD_ID_PREFIX}` : INDEXER_NAME_CARD_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { indexingConfigStore },
    } = useStore();

    const state = useLocalStore(() => ({
        isEditModalOpen: false,
        isLoading: true,
        beginLoading() {
            this.isLoading = true;
        },
        endLoading() {
            this.isLoading = false;
        },
    }));

    const { isError } = useLauncher([state.beginLoading, indexingConfigStore.fetchConfig, state.endLoading], {
        ignoreErrors: false,
        dependencies: [indexingConfigStore.isErrorConnectedDBus],
    });

    const openEditModal = () => {
        state.isEditModalOpen = true;
    };
    const closeEditModal = () => {
        state.isEditModalOpen = false;
    };

    return (
        <>
            <DataReadonlyCard
                headerDivider
                title={t("slingshot.index_proxy.indexer_name.title")}
                disabled={isError}
                control={
                    <MoreMenu>
                        <HomeCardActionMenuItem
                            widget={SLINGSHOT_INDEXER_CARD}
                            homeCardActionMenuItemId={`${idPrefix}_add_to_home`}
                        />
                    </MoreMenu>
                }
                onClick={openEditModal}
            >
                {indexingConfigStore.isConfigLoaded ? (
                    <Grid container wrap="nowrap" alignItems="center">
                        <Grid item xs={6}>
                            <Typography>{t("slingshot.index_proxy.indexer_name.label.name")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6">{indexingConfigStore.indexerName}</Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <EmptyCardPlaceholder inProgress={indexingConfigStore.isErrorConnectedDBus || state.isLoading} />
                )}
            </DataReadonlyCard>
            <SetIndexerNameModal open={state.isEditModalOpen} onClose={closeEditModal} parentId={idPrefix} />
        </>
    );
};

export default observer(IndexerNameCard);
