import React from "react";

const SupportQuickStartIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <g fill="#1E90FF">
                <g>
                    <g>
                        <g>
                            <path
                                d="M18 1.608c.478.276.642.887.366 1.366l-.022.039c4.332 3.05 5.87 8.791 3.643 13.599l.04.022c.478.276.641.888.365 1.366-.276.478-.887.642-1.366.366l-.039-.022c-3.05 4.332-8.79 5.87-13.598 3.644l-.023.038c-.276.479-.888.642-1.366.366-.478-.276-.642-.887-.366-1.366l.023-.038c-4.333-3.05-5.871-8.791-3.645-13.599l-.038-.023C1.495 7.09 1.332 6.478 1.608 6c.276-.478.887-.642 1.366-.366l.038.023c3.05-4.333 8.792-5.871 13.6-3.644l.022-.04c.276-.478.888-.641 1.366-.365zM5.499 9.402L3.753 8.394c-1.686 3.843-.473 8.368 2.909 10.853L7.669 17.5c-2.435-1.913-3.323-5.225-2.17-8.099zm13.747 7.937L17.5 16.331c-1.912 2.435-5.224 3.323-8.098 2.17l-1.008 1.746c3.843 1.686 8.367.474 10.852-2.908zM14.5 7.67C12.109 6.29 9.05 7.11 7.67 9.5s-.561 5.45 1.83 6.83 5.45.561 6.83-1.83.561-5.45-1.83-6.83zm2.84-2.915L16.33 6.5c2.435 1.913 3.323 5.225 2.17 8.099l1.746 1.008c1.686-3.843.474-8.368-2.908-10.853zM4.752 6.662L6.5 7.669c1.913-2.435 5.225-3.323 8.1-2.17l1.008-1.746c-3.76-1.65-8.172-.525-10.689 2.691l-.165.218z"
                                transform="translate(-1128 -23) translate(0 -1) translate(1112) translate(16 24)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export { SupportQuickStartIcon }