import React from "react";
import { Button, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react";
import Dialog from "components/Dialog";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { convertBytesToSizeUnit } from "utils/convertBytesToSizeUnit";
import { GiB_IEC, KiB_IEC, KB_METRIC } from "const/diskSizeConst";
import { useStore } from "hooks/useStore";
import { useStyles } from "./DiskTestLastResultsDialog.styles";
import { BLOCK_DIMENSIONS, BLOCK_SIZE_UNIT_BY_SIZE } from "const/NASDiskTest/NASDiskTest";

const DiskTestLastResultsDialog = ({ onClose, open }) => {
    const {
        store: { supportInfoStore },
    } = useStore();
    const { t } = useTranslation();

    const classes = useStyles();

    const fileSizeUnits = GiB_IEC;
    const { blockSize } = supportInfoStore.diskSelfTestResult?.result?.[0] || {};

    const blockSizeInfo = BLOCK_SIZE_UNIT_BY_SIZE[blockSize || BLOCK_DIMENSIONS["1048576"]];

    const stripeSizeUnits = KiB_IEC;
    const rwUnits = KB_METRIC;

    const makeTable = () => {
        return (
            <>
                <h1>{t("support.self_test.disk.result.header")}</h1>
                <p>
                    {t("support.self_test.disk.result.status_message", {
                        dateFrom: supportInfoStore.diskSelfTestResult?.startTime || "",
                        dateTo: supportInfoStore.diskSelfTestResult?.stopTime || "",
                        status: supportInfoStore.diskSelfTestResult?.status || "",
                    })}
                </p>
                <table cellSpacing={0} border={1} width={"100%"}>
                    <tbody>
                        <tr key={new Date().getTime() + Math.random()}>
                            <th>{t("support.self_test.disk.result.table.file_size", { units: fileSizeUnits.unit })}</th>
                            <th>{t("support.self_test.disk.result.table.block_size", { units: blockSizeInfo.unit })}</th>
                            <th>{t("support.self_test.disk.result.table.pool")}</th>
                            <th>{t("support.self_test.disk.result.table.ld_name")}</th>
                            <th>{t("support.self_test.disk.result.table.raid_level")}</th>
                            <th>{t("support.self_test.disk.result.table.stripe_size", { units: stripeSizeUnits.unit })}</th>
                            <th>{t("support.self_test.disk.result.table.share_name")}</th>
                            <th>{t("support.self_test.disk.result.table.stage_name")}</th>
                            <th>{t("support.self_test.disk.result.table.read", { units: stripeSizeUnits.unit })}</th>
                            {supportInfoStore.diskSelfTestSettings?.advancedModeEnabled && (
                                <th>{t("support.self_test.disk.result.table.read_iops")}</th>
                            )}
                            <th>{t("support.self_test.disk.result.table.write", { units: stripeSizeUnits.unit })}</th>
                            {supportInfoStore.diskSelfTestSettings?.advancedModeEnabled && (
                                <>
                                    <th>{t("support.self_test.disk.result.table.write_iops")}</th>
                                    <th>{t("support.self_test.disk.result.table.system_cpu")}</th>
                                </>
                            )}
                        </tr>
                    </tbody>
                    {supportInfoStore.diskSelfTestResult?.result.map((result) => {
                        const firstRowData = result.tests[0];
                        const testsData = result.tests.slice(1);
                        const testsCount = result.tests.length;

                        return (
                            <tbody key={result.raid + result.pool + result.volume + result.share}>
                                <tr>
                                    <td rowSpan={testsCount}>
                                        {
                                            convertBytesToSizeUnit({
                                                bytes: result.fileSize,
                                                unit: fileSizeUnits.unit,
                                                output: "object",
                                            }).value
                                        }
                                    </td>
                                    <td rowSpan={testsCount}>{blockSizeInfo.size}</td>
                                    <td rowSpan={testsCount}>{result.pool}</td>
                                    <td rowSpan={testsCount}>{result.volume}</td>
                                    <td rowSpan={testsCount}>{result.raid}</td>
                                    <td rowSpan={testsCount}>
                                        {
                                            convertBytesToSizeUnit({
                                                bytes: result.stripeSize,
                                                unit: stripeSizeUnits.unit,
                                                output: "object",
                                            }).value
                                        }
                                    </td>
                                    {}
                                    <td rowSpan={testsCount}>{result.share}</td>
                                    {firstRowData && (
                                        <>
                                            <td rowSpan={1}>{firstRowData.stage}</td>
                                            <td>
                                                {
                                                    convertBytesToSizeUnit({
                                                        bytes: firstRowData.readBandwidth,
                                                        unit: rwUnits.unit,
                                                        output: "object",
                                                    }).value
                                                }
                                            </td>
                                            {supportInfoStore.diskSelfTestSettings?.advancedModeEnabled && (
                                                <td>{firstRowData.readIops}</td>
                                            )}
                                            <td>
                                                {
                                                    convertBytesToSizeUnit({
                                                        bytes: firstRowData.writeBandwidth,
                                                        unit: rwUnits.unit,
                                                        output: "object",
                                                    }).value
                                                }
                                            </td>
                                            {supportInfoStore.diskSelfTestSettings?.advancedModeEnabled && (
                                                <>
                                                    <td>{firstRowData.writeIops}</td>
                                                    <td>{firstRowData.systemCpu}</td>
                                                </>
                                            )}
                                        </>
                                    )}
                                </tr>
                                {testsData.map((test) => {
                                    return (
                                        <tr key={test.stage}>
                                            <td>{test.stage}</td>
                                            <td>
                                                {
                                                    convertBytesToSizeUnit({
                                                        bytes: test.readBandwidth,
                                                        unit: rwUnits.unit,
                                                        output: "object",
                                                    }).value
                                                }
                                            </td>
                                            {supportInfoStore.diskSelfTestSettings?.advancedModeEnabled && (
                                                <td>{test.readIops}</td>
                                            )}
                                            <td>
                                                {
                                                    convertBytesToSizeUnit({
                                                        bytes: test.writeBandwidth,
                                                        unit: rwUnits.unit,
                                                        output: "object",
                                                    }).value
                                                }
                                            </td>
                                            {supportInfoStore.diskSelfTestSettings?.advancedModeEnabled && (
                                                <>
                                                    <td>{test.writeIops}</td>
                                                    <td>{test.systemCpu}</td>
                                                </>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        );
                    })}
                </table>
                <CopyToClipboard
                    text={
                        supportInfoStore.diskSelfTestSettings?.advancedModeEnabled
                            ? JSON.stringify(supportInfoStore.diskSelfTestResult, null, 4)
                            : JSON.stringify(supportInfoStore.diskSelfTestResultBasicMode, null, 4)
                    }
                >
                    <Link className={classes.link}>{t("support.self_test.disk.result.copy_to_clipboard")}</Link>
                </CopyToClipboard>
            </>
        );
    };

    return useObserver(() => (
        <Dialog
            submitBtn={
                <Button onClick={onClose} variant={"contained"} color="secondary">
                    {t("common.button.close")}
                </Button>
            }
            maxWidth={"lg"}
            onClose={onClose}
            title={t("support.self_test.disk.result.title")}
            open={open}
        >
            {makeTable()}
        </Dialog>
    ));
};

export default DiskTestLastResultsDialog;
