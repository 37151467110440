const createFields = () => {
    return [
        {
            name: "name",
            rules: "host|hostLength|required",
            default: "",
        },
        {
            name: "ip",
            rules: "required",
            default: "",
        },
    ];
};

export default createFields;
