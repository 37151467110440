import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => {
    return {
        container: {
            backgroundColor: hexToRgba(theme.palette.primary.main, 0.06),
            borderRadius: theme.spacing(1)
        }
    };
});
