import React from "react";
import { useTranslation } from "react-i18next";
import createFields from "./createFields";
import { useLocalStore } from "mobx-react";
import createForm from "utils/createForm";
import { Button, Grid } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import Select from "components/MobxForm/Select";
import moment from "moment";
import {
    DEFAULT_START_HOUR,
    DEFAULT_START_MINUTE,
    DEFAULT_END_HOUR,
    DEFAULT_END_MINUTE,
    HOURS_OPTIONS,
    MINUTES_OPTIONS,
} from "const/slingshotIndexerConfigConst";
import RowSwitch from "components/RowSwitch/RowSwitch";
import Switch from "components/MobxForm/Switch";
import { SLINGSHOT_DATE_FORMAT_FOR_SERVER } from "const/createJobConst";
import { PAUSE_MINUTES_DEFAULT } from "const/slingshotIndexerConfigConst";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { useStyles } from "./SetPauseModal.styles";

const SET_INDEX_PAUSE_MODAL_ID_PREFIX = `set_index_pause_modal`;

const SetPauseModal = ({ onClose, open, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${SET_INDEX_PAUSE_MODAL_ID_PREFIX}` : SET_INDEX_PAUSE_MODAL_ID_PREFIX;
    const { t } = useTranslation();
    const {
        store: { indexingConfigStore },
    } = useStore();

    const classes = useStyles();

    const state = useLocalStore(() => ({
        form: createForm({ fields: createFields() }),
        isSubmitted: false,
    }));

    const unsavedChangesModal = useModal();

    const updateForm = () => {
        state.form.$("enabled").set("default", indexingConfigStore.pauseEnabled);
        state.form.$("adHocRequestWithoutPause").set("default", indexingConfigStore.adHocRequestWithoutPause);
        state.form
            .$("startHour")
            .set(
                "default",
                indexingConfigStore.pauseSchedule ? moment(indexingConfigStore.pauseStartTime).format("HH") : DEFAULT_START_HOUR
            );
        state.form
            .$("startMinute")
            .set(
                "default",
                indexingConfigStore.pauseSchedule ? moment(indexingConfigStore.pauseStartTime).format("mm") : DEFAULT_START_MINUTE
            );
        state.form
            .$("endHour")
            .set(
                "default",
                indexingConfigStore.pauseSchedule ? moment(indexingConfigStore.pauseEndTime).format("HH") : DEFAULT_END_HOUR
            );
        state.form
            .$("endMinute")
            .set(
                "default",
                indexingConfigStore.pauseSchedule ? moment(indexingConfigStore.pauseEndTime).format("mm") : DEFAULT_END_MINUTE
            );
        state.form.reset();
        state.isSubmitted = false;
        unsavedChangesModal.close();
    };

    const getDuration = () => {
        const diff = moment(indexingConfigStore.pauseEndTime)
            .hour(Number(state.form.$("endHour").value))
            .minute(Number(state.form.$("endMinute").value))
            .diff(
                moment(indexingConfigStore.pauseStartTime)
                    .hour(Number(state.form.$("startHour").value))
                    .minute(Number(state.form.$("startMinute").value)),
                "minutes"
            );
        if (diff < 0) {
            return PAUSE_MINUTES_DEFAULT - Math.abs(diff);
        }
        return diff;
    };

    const submit = async (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        const payload = {
            enabled: state.form.$("enabled").value,
            adHocRequestWithoutPause: state.form.$("adHocRequestWithoutPause").value,
            beginDate: moment(indexingConfigStore.pauseStartTime)
                .hour(Number(state.form.$("startHour").value))
                .minute(Number(state.form.$("startMinute").value))
                .format(SLINGSHOT_DATE_FORMAT_FOR_SERVER),
            duration: getDuration(),
        };
        const isSubmitted = await indexingConfigStore.setPause(payload);
        if (isSubmitted) {
            state.isSubmitted = true;
        }
        return isSubmitted;
    };
    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            maxWidth="md"
            title={t("slingshot.index_proxy.pause.title")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            open={open}
            onSubmit={submit}
            onEnter={updateForm}
            onClose={closeIfNeeded}
            buttons={
                <Button onClick={onClose} variant={"contained"} color="secondary" id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid className={classes.container} container direction={"column"}>
                <RowSwitch
                    label={t("slingshot.index_proxy.pause.label.spin_down")}
                    control={<Switch field={state.form.$("enabled")} id={`${idPrefix}_enabled`} />}
                />
                {state.form.$("enabled").value && (
                    <>
                        <RowTextInput
                            label={t("slingshot.index_proxy.pause.label.pause")}
                            control={
                                <Grid container wrap="nowrap" alignItems="center" spacing={2}>
                                    <Grid item container>
                                        <Select
                                            options={HOURS_OPTIONS}
                                            field={state.form.$("startHour")}
                                            id={`${idPrefix}_start_hour`}
                                        />
                                    </Grid>
                                    <Grid item container>
                                        <Select
                                            options={MINUTES_OPTIONS}
                                            field={state.form.$("startMinute")}
                                            id={`${idPrefix}_start_minute`}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        />
                        <RowTextInput
                            label={t("slingshot.index_proxy.pause.label.resume")}
                            control={
                                <Grid container wrap="nowrap" alignItems="center" spacing={2}>
                                    <Grid item container>
                                        <Select
                                            options={HOURS_OPTIONS}
                                            field={state.form.$("endHour")}
                                            id={`${idPrefix}_end_hour`}
                                        />
                                    </Grid>
                                    <Grid item container>
                                        <Select
                                            options={MINUTES_OPTIONS}
                                            field={state.form.$("endMinute")}
                                            id={`${idPrefix}_end_minute`}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        />
                        <RowSwitch
                            label={t("slingshot.index_proxy.pause.label.hoc_generation")}
                            control={
                                <Switch
                                    field={state.form.$("adHocRequestWithoutPause")}
                                    id={`${idPrefix}_ad_hoc_request_without_pause`}
                                />
                            }
                        />
                    </>
                )}
            </Grid>
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default SetPauseModal;
