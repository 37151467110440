import isFieldHasOnlySpaces from "utils/isFieldHasOnlySpacesRule";
import isFieldEmpty from "utils/isFieldEmptyRule";
import i18n from "i18n";

const createFields = () => {
    return [
        {
            name: "extension",
            default: "",
            validators: [isFieldEmpty(i18n.t("common.label.field")), isFieldHasOnlySpaces(i18n.t("rule.field_has_only_spaces"))]
        }
    ];
};

export default createFields;
