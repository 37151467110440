import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./ExpansionDotsStatusItem.styles";
import StatusDot from "./StatusDot";

const ExpansionDotsStatusItem = ({ title, statuses }) => {
    const classes = useStyles();
    return useObserver(() => (
        <Grid container direction={"column"} alignItems={"center"}>
            <Grid item container justify={"center"}>
                <Typography component={"span"} className={classes.quadTitle} color={"textSecondary"} noWrap>
                    {title}
                </Typography>
            </Grid>
            <Grid item container className={classes.quadBody} direction={"column"} spacing={2}>
                {statuses &&
                    statuses.map(status => (
                        <Grid item key={`${Date.now()}${Math.random()}`}>
                            <StatusDot large status={status} />
                        </Grid>
                    ))}
            </Grid>
        </Grid>
    ));
};

export default ExpansionDotsStatusItem;
