import React, { useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import MainTemplate from "components/MainTemplate";
import HeaderTitle from "components/HeaderTitle";
import { useTranslation } from "react-i18next";
import ChartIcon from "assets/ChartIcon";
import { Grid, CircularProgress } from "@material-ui/core";
import { CLUSTER } from "const/shortcutNameConst";
import LocalBricks from "./components/LocalBricksCard";
import PeersCard from "./components/PeersCard";
import VolumesCard from "./components/VolumesCard";
import EmptyCard from "./components/EmptyCard";
import { useStore } from "hooks/useStore";

const Cluster = () => {
    const { t } = useTranslation();
    const {
        store: { clusterStore }
    } = useStore();

    const state = useLocalStore(() => ({
        inProgress: true
    }));

    useEffect(() => {
        (async () => {
            state.inProgress = true;
            await clusterStore.fetchSettings();
            state.inProgress = false;
        })();
        clusterStore.fetchVolumeRemoveBricksTasks();
    }, []);

    return useObserver(() => (
        <MainTemplate headerTitle={<HeaderTitle icon={<ChartIcon />} shortcutName={CLUSTER} title={t("cluster.title")} />}>
            {state.inProgress ? (
                <Grid container alignItems={"center"} justify={"center"}>
                    <Grid item>
                        <CircularProgress size={40} />
                    </Grid>
                </Grid>
            ) : (
                <>
                    {clusterStore.settings?.enabled ? (
                        <Grid item container direction="column" spacing={6} justify="flex-start" wrap="nowrap">
                            <Grid spacing={6} container item direction="column" wrap="nowrap">
                                <Grid item>
                                    <LocalBricks />
                                </Grid>
                                <Grid item>
                                    <PeersCard />
                                </Grid>
                                <Grid item>
                                    <VolumesCard />
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container direction={"column"} spacing={6}>
                            <Grid item>
                                <EmptyCard />
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </MainTemplate>
    ));
};

export default Cluster;
