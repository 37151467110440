const createFields = () => {
    return [
        { name: "host", rules: "required" },
        { name: "resource", rules: "required" },
        { name: "user", rules: "required" },
        { name: "password", type: "password", rules: "required" },
        { name: "path" },
        { name: "enablePreset", type: "checkbox" },
        { name: "preset" },
        { name: "share", rules: "required" },
        { name: "folder" },
        { name: "regionChoose", type: "radio" },
        { name: "region" },
        { name: "accessCode", type: "password" }
    ];
};

export default createFields;
