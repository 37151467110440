import i18n from "i18n";

const minSizeValidator = ({ min }) => {
    return ({ field }) => {
        return [
            !field.value.length || (field.value >= min),
            i18n.t("validator.min.size", { minValue: min })
        ];
    };
};

export default minSizeValidator;
