import { types, flow, getParent } from "mobx-state-tree";
import NetServicesAdvertisingType from "api/net/Responses/NetServicesAdvertisingResult";
import Socket from "websocket";
import GetServiceAdvertising from "api/net/Requests/GetServiceAdvertising";
import SetServiceAdvertising from "api/net/Requests/SetServiceAdvertising";

const NetServiceAdvertisingStore = types
    .model({ netServiceAdvertising: types.maybe(NetServicesAdvertisingType) })
    .views(self => ({
        get hostname() {
            return self.netServiceAdvertising && self.netServiceAdvertising.data.hostname;
        },
        get winsEnabled() {
            return self.netServiceAdvertising && self.netServiceAdvertising.data.winsEnabled;
        },
        get avahiEnabled() {
            return self.netServiceAdvertising && self.netServiceAdvertising.data.avahiEnabled;
        }
    }))
    .actions(self => ({
        fetchNetServiceAdvertising: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetServiceAdvertising.create().init();
                const res = yield Socket.send(req);
                self.netServiceAdvertising = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setNetServiceAdvertising: flow(function*(data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetServiceAdvertising.create().init(data);
                const res = yield Socket.send(req);
                self.fetchNetServiceAdvertising();
                return res;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        })
    }));

export default NetServiceAdvertisingStore;
