/* eslint-disable no-console */
// eslint-disable-next-line no-redeclare
/* global WebSocket */
import { WEBSOCKET_URL } from "const";

const QUEUE = {};

const Socket = {
    start: (token) =>
        new Promise((resolve) => {
            const ws = new WebSocket(`${WEBSOCKET_URL}?token=${token}`);
            ws.onopen = (e) => {
                Socket.ws = ws;
                resolve();
                console.log("CONNECTED");
                Socket.onopen(e);
            };

            ws.onmessage = (e) => {
                const { result, context, code, error, broadcast } = JSON.parse(e.data);

                if (typeof broadcast !== "undefined") {
                    Socket.onbroadcast(e);
                    return; // handle it in the event store
                }

                if (context.requestId in QUEUE) {
                    QUEUE[context.requestId](result, { message: error, code });
                    delete QUEUE[context.requestId];
                }
            };

            ws.onerror = (err) => {
                Socket.onerror(err);
            };

            ws.onclose = (evt) => {
                Socket.onclose(evt);
            };
        }),
    ws: null,
    send: (data) => {
        const promise = new Promise((resolve, reject) => {
            Socket.ws.send(JSON.stringify(data));
            QUEUE[data.context.requestId] = (result, error) => {
                error.message ? reject(error) : resolve(result);
            };
        });
        return promise;
    },
    onbroadcast: () => {},
    onerror: () => {},
    onclose: () => {},
    onopen: () => {},
};

export const createMultipleEvoSocket = (ip) => {
    const newSocket = {
        start: (token) =>
            new Promise((resolve) => {
                const ws = new WebSocket(`wss://${ip}:8090/api?token=${token}`);
                ws.onopen = (e) => {
                    newSocket.ws = ws;
                    resolve();
                    console.log(`CONNECTED_${ip}`);
                    newSocket.onopen(e);
                };

                ws.onmessage = (e) => {
                    const { result, context, code, error, broadcast } = JSON.parse(e.data);

                    if (typeof broadcast !== "undefined") {
                        newSocket.onbroadcast(e);
                        return; // handle it in the event store
                    }

                    if (context.requestId in QUEUE) {
                        QUEUE[context.requestId](result, { message: error, code });
                        delete QUEUE[context.requestId];
                    }
                };

                ws.onerror = (err) => {
                    newSocket.onerror(err);
                };

                ws.onclose = (evt) => {
                    newSocket.onclose(evt);
                };
            }),
        ws: null,
        send: (data) => {
            const promise = new Promise((resolve, reject) => {
                newSocket.ws.send(JSON.stringify(data));
                QUEUE[data.context.requestId] = (result, error) => {
                    error.message ? reject(error) : resolve(result);
                };
            });
            return promise;
        },
        onbroadcast: () => {},
        onerror: () => {},
        onclose: () => {},
        onopen: () => {},
    };
    return newSocket;
};

export default Socket;
