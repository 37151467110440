import { types } from "mobx-state-tree";
import i18n from "i18n";

import DomainObjectId from "api/security/Types/DomainObjectId";

const DomainObject = types
    .model({
        id: types.maybe(DomainObjectId),
        isGroup: types.boolean,
        isExternal: types.boolean
    })
    .views(self => ({
        get name() {
            return self.id?.name;
        },
        get domain() {
            return self.id?.domain;
        },
        get domainName() {
            return `${self.id?.domain}@${self.id?.name}`;
        },
        get typeLabel() {
            if (self.isGroup) {
                return self.isExternal ? i18n.t("users.common.external_group") : i18n.t("users.common.local_group");
            } else {
                return self.isExternal ? i18n.t("users.common.external_user") : i18n.t("users.common.local_user");
            }
        }
    }));

export default DomainObject;
