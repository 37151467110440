import React from "react";
import MainTemplate from "components/MainTemplate";
import HeaderTitle from "components/HeaderTitle";
import OptionsAdminIcon from "assets/OptionsAdminIcon";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import CpuGraphCard from "./components/CpuGraphCard";
import CPUDetailedGraphCard from "./components/CPUDetailedGraphCard";
import ThroughputGraphCard from "./components/ThroughputGraphCard";
import StorageUsageGraphCard from "./components/StorageUsageGraphCard";
import PhysicalDisks from "./components/PhysicalDisks";
import NetworkGraphCard from "./components/NetworkGraphCard";

const Dashboard = () => {
    const { t } = useTranslation();
    return (
        <MainTemplate headerTitle={<HeaderTitle icon={<OptionsAdminIcon />} title={t("dashboard.title")} />}>
            <Grid container spacing={6} alignContent="flex-start">
                <Grid item xs={12} lg={6}>
                    <CpuGraphCard />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <ThroughputGraphCard />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PhysicalDisks />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <StorageUsageGraphCard />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <NetworkGraphCard />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CPUDetailedGraphCard />
                </Grid>
            </Grid>
        </MainTemplate>
    );
};

export default Dashboard;
