import { flow, getParent, types } from "mobx-state-tree";
import Socket from "websocket";
import GetIdentityResult from "api/support_info/Responses/GetIdentityResult";
import GetIdentity from "api/support_info/Requests/GetIdentity";
import GetSchedule from "api/support_info/Requests/GetSchedule";
import GetScheduleResult from "api/support_info/Responses/GetScheduleResult";
import SetIdentity from "api/support_info/Requests/SetIdentity";
import SetSchedule from "api/support_info/Requests/SetSchedule";
import SendSupportInfo from "api/support_info/Requests/SendSupportInfo";
import DownloadSystemLogs from "api/troubleshooting/Requests/DownloadSystemLogs";
import axios from "api/AxiosCommonRequest";
import { FILE_DOWNLOAD_URL } from "api/restRoutes";
import { saveAs } from "file-saver";
import SelfTestStatus from "api/troubleshooting/Responses/SelfTestStatus";
import StatusNetworkSelfTest from "api/troubleshooting/Requests/StatusNetworkSelfTest";
import StartNetworkSelfTest from "api/troubleshooting/Requests/StartNetworkSelfTest";
import StopNetworkSelfTest from "api/troubleshooting/Requests/StopNetworkSelfTest";
import DiskTestSettingsResult from "api/troubleshooting/Responses/DiskTestSettingsResult";
import GetDiskTestSettings from "api/troubleshooting/Requests/GetDiskTestSettings";
import DiskTestStatusResult from "api/troubleshooting/Responses/DiskTestStatusResult";
import StatusDiskSelfTest from "api/troubleshooting/Requests/StatusDiskSelfTest";
import StartDiskSelfTest from "api/troubleshooting/Requests/StartDiskSelfTest";
import StopDiskSelfTest from "api/troubleshooting/Requests/StopDiskSelfTest";
import DownloadMigrationSystemLogs from "api/troubleshooting/Requests/DownloadMigrationSystemLogs";

const SupportInfoStore = types
    .model({
        identityResult: types.maybe(GetIdentityResult),
        scheduleStore: types.maybe(GetScheduleResult),
        networkTestResultStore: types.maybe(SelfTestStatus),
        diskTestResultStore: types.maybe(DiskTestStatusResult),
        diskTestSettingsStore: types.maybe(DiskTestSettingsResult),
    })
    .views((self) => ({
        get identity() {
            return self.identityResult?.data;
        },
        get serialNumber() {
            return this.identity?.serialNumber;
        },
        get schedule() {
            return self.scheduleStore && self.scheduleStore.data;
        },
        get networkSelfTestResult() {
            return self.networkTestResultStore?.data;
        },
        get diskSelfTestResult() {
            return self.diskTestResultStore?.data;
        },
        get diskSelfTestResultBasicMode() {
            const data = self.diskTestResultStore?.data;
            return (
                data && {
                    ...data,
                    result: data.result?.map((res) => {
                        return {
                            ...res,
                            tests: res.tests?.map((test) => {
                                //eslint-disable-next-line
                                let { readIops, writeIops, systemCpu, ...rest } = test;
                                return rest;
                            }),
                        };
                    }),
                }
            );
        },
        get diskSelfTestSettings() {
            return self.diskTestSettingsStore?.data;
        },
    }))
    .actions((self) => ({
        fetchIdentity: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetIdentity.create().init();
                const res = yield Socket.send(req);
                self.identityResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchSchedule: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetSchedule.create().init();
                const res = yield Socket.send(req);
                self.scheduleStore = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        sendSupportInfo: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SendSupportInfo.create().init();
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setIdentity: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetIdentity.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setSchedule: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetSchedule.create().init(data);
                yield Socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getSystemLogs: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DownloadSystemLogs.create().init();
                const res = yield Socket.send(req);
                yield self.downloadSystemLogs(res.data?.path);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getMigrationSystemLogs: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = DownloadMigrationSystemLogs.create().init();
                const res = yield Socket.send(req);
                yield self.downloadSystemLogs(res.data?.path);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        downloadSystemLogs: flow(function* (fileName) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const config = { responseType: "blob", params: { file: fileName } };
                const res = yield axios.get(FILE_DOWNLOAD_URL, config);
                saveAs(new Blob([res.data]), fileName);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchNetworkTestResult: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = StatusNetworkSelfTest.create().init();
                const res = yield Socket.send(req);
                self.networkTestResultStore = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        startNetworkTest: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = StartNetworkSelfTest.create().init(data);
                const res = yield Socket.send(req);
                self.networkTestResultStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        stopNetworkTest: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = StopNetworkSelfTest.create().init();
                const res = yield Socket.send(req);
                self.networkTestResultStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateNetworkTestStatus: (data) => {
            if (self.networkTestResultStore.data) {
                self.networkTestResultStore.data = data;
            }
        },
        fetchDiskTestSettings: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetDiskTestSettings.create().init();
                const res = yield Socket.send(req);
                self.diskTestSettingsStore = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchDiskTestResult: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = StatusDiskSelfTest.create().init();
                const res = yield Socket.send(req);
                self.diskTestResultStore = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        startDiskTest: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = StartDiskSelfTest.create().init(data);
                const res = yield Socket.send(req);
                self.diskTestResultStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        stopDiskTest: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = StopDiskSelfTest.create().init();
                const res = yield Socket.send(req);
                self.diskTestResultStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateDiskTestStatus: (data) => {
            if (self.diskTestResultStore.data) {
                self.diskTestResultStore.data = data;
            }
        },
    }));

export default SupportInfoStore;
