import Request from "api/Request";

const GetBridges = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "get_bridges"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetBridges;
