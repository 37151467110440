import React from "react";
import { useObserver } from "mobx-react";
import { TableRow, TableCell, ButtonBase, CircularProgress } from "@material-ui/core";
import { useStyles } from "./IfaceTableRow.styles";
import TrashIcon from "assets/TrashIcon";
import EthernetIconDisabled from "assets/EthernetIconDisabled";
import EthernetIcon from "assets/EthernetIcon";
import Checkbox from "components/Checkbox";
import StatusLabel from "components/StatusLabel";
import TableCellWithIcon from "components/TableCellWithIcon";
import { useTranslation } from "react-i18next";

const IfaceTableRow = ({ row, isLoading, checked, onCheck, onDelete, id }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return useObserver(() => (
        <TableRow>
            <TableCell className={classes.checkboxCell}>
                <Checkbox checked={checked} onClick={onCheck} disabled={!row.exists} id={`${id}_checkbox`} />
            </TableCell>
            <TableCellWithIcon icon={row.activated ? <EthernetIcon /> : <EthernetIconDisabled />}>{row.port}</TableCellWithIcon>
            {isLoading ? (
                <TableCellWithIcon iconPosition={"right"} className={classes.loadingCell} icon={<CircularProgress size={16} />}>
                    {row.activated ? t("hardware_activation.common.deactivating") : t("hardware_activation.common.activating")}
                </TableCellWithIcon>
            ) : (
                <TableCell>
                    {row.activated ? t("hardware_activation.common.activated") : t("hardware_activation.common.deactivated")}
                </TableCell>
            )}
            <TableCell>{row.mac}</TableCell>
            <TableCell>{t("common.speed_value.gbs", { speed: row.maxSpeedGbs })}</TableCell>
            <TableCell align={"right"}>
                <StatusLabel status={row.exists ? t("common.label.online") : t("common.label.offline")} />
            </TableCell>
            <TableCell align={"right"} className={classes.rowDeleteButtonColumn}>
                {!row.exists && (
                    <ButtonBase onClick={onDelete} id={`${id}_delete`}>
                        <TrashIcon />
                    </ButtonBase>
                )}
            </TableCell>
        </TableRow>
    ));
};

export default IfaceTableRow;
