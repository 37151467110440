import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    noOpacityCell: {
        opacity: 1,
        "&.MuiTableCell-body": {
            paddingTop: 0,
            paddingBottom: 0
        },
        "& .MuiGrid-item": {
            paddingTop: 0,
            paddingBottom: 0,
            display: "inline-flex",
            alignItems: "center"
        }
    },
    container: {
        flexDirection: ({ iconPosition }) => (iconPosition === "left" ? "row" : "row-reverse"),
        justifyContent: ({ iconPosition }) => (iconPosition === "left" ? "flex-start" : "flex-end")
    }
}));
