import { types } from "mobx-state-tree";

import GetFirewallRules from "api/firewall/Types/GetFirewallRules";

const GetFirewallRulesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(GetFirewallRules)
});

export default GetFirewallRulesResult;
