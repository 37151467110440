import i18n from "i18n";
const SWITCH_INCREMENT = 4096;
const SWITCH_MIN = 0;
const SWITCH_MAX = 61440;
const MIN_DELAY = 4;
const MAX_DELAY = 30;
const MIN_HELLO = 1;
const MAX_HELLO = 10;
const MIN_AGE = 6;
const MAX_AGE = 40;

const isStpRule = {
    isSwitchPriority: {
        function: (value) => {
            return value >= SWITCH_MIN && value <= SWITCH_MAX && Number.isInteger(value / SWITCH_INCREMENT);
        },
        message: i18n.t("rule.stp.switch_priority"),
    },
    isForwardDelay: {
        function: (value) => value >= MIN_DELAY && value <= MAX_DELAY && Number.isInteger(Number(value)),
        message: i18n.t("rule.stp.forward_delay"),
    },
    isHelloTime: {
        function: (value) => value >= MIN_HELLO && value <= MAX_HELLO && Number.isInteger(Number(value)),
        message: i18n.t("rule.stp.hello_time"),
    },
    isMaxAge: {
        function: (value) => value >= MIN_AGE && value <= MAX_AGE && Number.isInteger(Number(value)),
        message: i18n.t("rule.stp.max_age"),
    },
};

export default isStpRule;
