import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetPoolArguments from "api/pool/Types/GetPoolArguments";

const GetExpandingDrives = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Pool",
    method: "get_expanding_drives",
    arguments: types.maybe(GetPoolArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetExpandingDrives;
