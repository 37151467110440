import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    switch: {
        width: 40,
        height: theme.spacing(6),
        padding: 0,
        display: "flex",
    },
}));
