import React from "react";

const RefreshIcon = () => {
    return (
        <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>83A0B79D-A08A-45C5-A2E1-6CC64BDBED33</title>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1220.000000, -256.000000)" fill="#1E90FF" fillRule="nonzero">
                    <g transform="translate(1220.000000, 256.000000)">
                        <path d="M5.14644661,2.85355339 C5.34170876,2.65829124 5.65829124,2.65829124 5.85355339,2.85355339 L5.85355339,2.85355339 L7.3143273,4.3144773 C8.70896349,3.46233337 10.3201988,3 12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 C7.02943725,21 3,16.9705627 3,12 C3,11.4477153 3.44771525,11 4,11 C4.55228475,11 5,11.4477153 5,12 C5,15.8659932 8.13400675,19 12,19 C15.8659932,19 19,15.8659932 19,12 C19,8.13400675 15.8659932,5 12,5 C10.8599676,5 9.76285043,5.27266951 8.78198727,5.78148157 L10.1464466,7.14644661 C10.2402148,7.2402148 10.2928932,7.36739176 10.2928932,7.5 C10.2928932,7.77614237 10.0690356,8 9.79289322,8 L9.79289322,8 L5,8 L5,3.20710678 C5,3.07449854 5.05267842,2.94732158 5.14644661,2.85355339 Z"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default RefreshIcon;
