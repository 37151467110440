export const PERMISSIONS = {
    changeOwner: "changeOwner",
    changePermissions: "changePermissions",
    traverseFoldersExecuteFile: "traverseFoldersExecuteFile",
    listFoldersReadData: "listFoldersReadData",
    readAttributes: "readAttributes",
    readExtendedAttributes: "readExtendedAttributes",
    readPermissions: "readPermissions",
    createFilesWriteData: "createFilesWriteData",
    createFoldersAppendData: "createFoldersAppendData",
    writeAttributes: "writeAttributes",
    writeExtendedAttributes: "writeExtendedAttributes",
    deleteSubdirsAndFiles: "deleteSubdirsAndFiles",
    delete: "delete"
};

export const ADMIN_GROUP = [PERMISSIONS.changeOwner, PERMISSIONS.changePermissions];
export const READ_GROUP = [
    PERMISSIONS.traverseFoldersExecuteFile,
    PERMISSIONS.listFoldersReadData,
    PERMISSIONS.readAttributes,
    PERMISSIONS.readExtendedAttributes,
    PERMISSIONS.readPermissions
];
export const WRITE_GROUP = [
    PERMISSIONS.createFilesWriteData,
    PERMISSIONS.createFoldersAppendData,
    PERMISSIONS.writeAttributes,
    PERMISSIONS.writeExtendedAttributes,
    PERMISSIONS.deleteSubdirsAndFiles,
    PERMISSIONS.delete
];

export const ALL_GROUPS = [...ADMIN_GROUP, ...READ_GROUP, ...WRITE_GROUP];

export const APPLY_TO_OPTIONS = {
    folderOnly: { directory: true, subDirectories: false, subFiles: false },
    folderSubfoldersFiles: { directory: true, subDirectories: true, subFiles: true },
    folderSubfolders: { directory: true, subDirectories: true, subFiles: false },
    subfoldersFiles: { directory: false, subDirectories: true, subFiles: true },
    subfolders: { directory: false, subDirectories: true, subFiles: false },
    files: { directory: false, subDirectories: false, subFiles: true },
    folderFiles: { directory: true, subDirectories: false, subFiles: true }
};
