import { makeStyles } from "@material-ui/core/styles";
import { QUAD_STATUS_DOT_GREY_COLOR } from "const/styleConst";

export const useStyles = makeStyles(theme => {
    const getColor = status => {
        switch (status) {
            case "online":
                return theme.palette.success.main;
            case "absent":
                return QUAD_STATUS_DOT_GREY_COLOR;
            case "replaced":
                return theme.palette.primary.main;
            case "warning":
                return theme.palette.warning.main;
            case "failed":
                return theme.palette.error.main;
            case "not_activated":
                return theme.palette.warning.dark;
            case "unknown":
                return theme.palette.error.main;
            default:
                return theme.palette.error.main;
        }
    };
    return {
        statusDot: {
            width: props => (props.large ? 8 : 6),
            height: props => (props.large ? 8 : 6),
            borderRadius: "50%",
            marginRight: props => theme.spacing(props.large ? 0 : 1),
            backgroundColor: props => getColor(props.status)
        }
    };
});
