import React, { useCallback } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow as MuiTableRow,
    Grid,
    Box,
    Divider,
    ButtonBase,
    Typography,
    DialogTitle,
} from "@material-ui/core";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import TableRow from "./components/TableRow";
import { PROCESSED_FILE_ID, PROCESSED_FILE_SOURCE, PROCESSED_FILE_PROCESSED } from "const/sortColumnConst";
import TableSortLabel from "components/TableSortLabel";
import TablePagination from "components/TablePagination";
import TrashIcon from "assets/TrashIcon";
import { useStyles } from "./ProcessedFilesModal.styles";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import CloseIcon from "assets/CloseIcon";
import ThinArrowRightIcon from "assets/ThinArrowRightIcon";
import Select from "components/Select/Select";
import ProcessedTasksModal from "../ProcessedTasksModal";
import { useStore } from "hooks/useStore";
import { useProcessedFilesModal, useProcessedTasksModal } from "hooks/modals";
import { useModalHistory } from "hooks/useModalHistory";

const PROCESSED_FILES_MODAL_ID_PREFIX = `processed_files_modal`;

const ProcessedFilesModal = ({ onClose, onBack, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${PROCESSED_FILES_MODAL_ID_PREFIX}` : PROCESSED_FILES_MODAL_ID_PREFIX;
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { syncJobsStore, automationsStore },
    } = useStore();
    const processedFilesModal = useProcessedFilesModal();
    const processedTasksModal = useProcessedTasksModal();

    const state = useLocalStore(() => ({
        searchInProgress: false,
        currentTasks: [],
        get sortedCurrentTasks() {
            return [...this.currentTasks].sort((a, b) => a.id - b.id);
        },
    }));

    const modalHistory = useModalHistory();

    const closeModal = () => {
        syncJobsStore.updateProcessedFilesPage(0);
        onClose();
    };

    const handleChangePage = (newPage) => {
        syncJobsStore.updateProcessedFilesPage(+newPage);
    };
    const handleChangeRowsPerPage = (e) => {
        syncJobsStore.updateProcessedFilesLimit(+e.target.value);
        syncJobsStore.updateProcessedFilesPage(0);
    };

    const changeSorting = (column) => () => syncJobsStore.changeProcessedFilesSorting(column);

    const openTasksModal = (tasks, fileId) => () => {
        state.currentTasks = tasks;
        state.currentFileId = fileId;
        modalHistory.toggleHidden();
        processedTasksModal.open();
    };

    const toggleFilesWithoutErrorsVisibility = useCallback(() => {
        syncJobsStore.updateNeedToHideSucceedFiles(!syncJobsStore.needToHideSucceedFiles);
    }, [syncJobsStore.needToHideSucceedFiles]);

    const onModalEnter = async () => {
        state.searchInProgress = true;
        const currentAutomationId = syncJobsStore.currentJobId || automationsStore.currentAutomationId;
        syncJobsStore.getProcessedFiles(currentAutomationId);
        modalHistory.toggleHidden(false);
        state.searchInProgress = false;
    };

    const clearAutomationEvents = useCallback(() => {
        syncJobsStore.currentJobId && syncJobsStore.performJobCommand({ jobId: syncJobsStore.currentJobId, cmd: "clear" });
        automationsStore.currentAutomationId &&
            automationsStore.performAutomationCommand({ automationId: automationsStore.currentAutomationId, cmd: "clear" });
    }, [syncJobsStore.currentJobId, automationsStore.currentAutomationId]);

    const getHeader = () => (
        <DialogTitle className={classes.dialogTitle}>
            <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container wrap={"nowrap"}>
                <Grid item className={classes.titleText}>
                    {t("slingshot_page.tabs.replications.processed_files_modal.title", {
                        id: syncJobsStore.currentInstanceId || 0,
                    })}
                </Grid>

                <Grid item>
                    <CardHeaderControlButton onClick={closeModal}>
                        <ButtonBase id={`${idPrefix}_close`}>
                            <CloseIcon />
                        </ButtonBase>
                    </CardHeaderControlButton>
                </Grid>
            </Grid>
            <Grid container wrap="nowrap" alignItems="center">
                <Grid container item xs={6} wrap="nowrap" spacing={2} className={classes.parentBreadcrumbs}>
                    <Grid item>
                        <Typography variant="h6" className={classes.parentName}>
                            {t("slingshot_page.tabs.replications.processed_files_modal.header.nav.instances")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ThinArrowRightIcon />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className={classes.filesLabel}>
                            {t("slingshot_page.tabs.replications.processed_files_modal.header.nav.files")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={6} wrap="nowrap" alignItems="center" justify="flex-end" spacing={2}>
                    <Grid item>
                        <Typography className={classes.selectLabel}>
                            {t("slingshot_page.tabs.replications.processed_files_modal.header.select_label")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Select
                            className={classes.select}
                            onChange={toggleFilesWithoutErrorsVisibility}
                            value={!syncJobsStore.needToHideSucceedFiles}
                            options={[
                                {
                                    label: t("slingshot_page.tabs.replications.processed_files_modal.header.select.option.show"),
                                    value: true,
                                },
                                {
                                    label: t("slingshot_page.tabs.replications.processed_files_modal.header.select.option.hide"),
                                    value: false,
                                },
                            ]}
                            id={`${idPrefix}_toggle_files`}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </DialogTitle>
    );

    return useObserver(() => (
        <Dialog
            maxWidth="lg"
            submitBtn={[
                <Button
                    key="1"
                    variant="contained"
                    color="secondary"
                    onClick={onBack}
                    id={`${idPrefix}_replication_job_instances_modal_back_button`}
                >
                    {t("common.button.back")}
                </Button>,
                <Button
                    key="2"
                    variant="contained"
                    color="secondary"
                    onClick={closeModal}
                    id={`${idPrefix}_replication_job_instances_modal_close_button`}
                >
                    {t("common.button.close")}
                </Button>,
            ]}
            header={getHeader()}
            withoutPaddings
            open={processedFilesModal.isOpen}
            onClose={closeModal}
            onEnter={onModalEnter}
            buttons={
                <Grid container>
                    {syncJobsStore.processedFilesCount > 0 ? (
                        <ButtonBase onClick={clearAutomationEvents} id={`${idPrefix}_clear_all`}>
                            <Grid container alignItems="center">
                                <Box pr={4} className={classes.trashIcon}>
                                    <TrashIcon />
                                </Box>
                                <Typography variant="button" color="error">
                                    {t("slingshot_page.tabs.replications.processed_files_modal.button")}
                                </Typography>
                            </Grid>
                        </ButtonBase>
                    ) : null}
                </Grid>
            }
            hide={modalHistory.hidden}
        >
            {syncJobsStore.processedFiles && syncJobsStore.processedFilesCount > 0 ? (
                <>
                    <Table>
                        <TableHead>
                            <MuiTableRow>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={changeSorting(PROCESSED_FILE_ID)}
                                        direction={syncJobsStore.getProcessedFilesArguments.sort_dir.toLowerCase()}
                                        active={syncJobsStore.getProcessedFilesArguments.sort === PROCESSED_FILE_ID}
                                        id={`${idPrefix}_sort_id`}
                                    >
                                        {t("slingshot_page.tabs.replications.processed_files_modal.table.id")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>{t("slingshot_page.tabs.replications.processed_files_modal.table.status")}</TableCell>
                                <TableCell>{t("slingshot_page.tabs.replications.processed_files_modal.table.tasks")}</TableCell>
                                <TableCell width="20%">
                                    <TableSortLabel
                                        onClick={changeSorting(PROCESSED_FILE_SOURCE)}
                                        direction={syncJobsStore.getProcessedFilesArguments.sort_dir.toLowerCase()}
                                        active={syncJobsStore.getProcessedFilesArguments.sort === PROCESSED_FILE_SOURCE}
                                        id={`${idPrefix}_sort_name`}
                                    >
                                        {t("slingshot_page.tabs.replications.processed_files_modal.table.file_name")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>{t("slingshot_page.tabs.replications.processed_files_modal.table.size")}</TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={changeSorting(PROCESSED_FILE_PROCESSED)}
                                        direction={syncJobsStore.getProcessedFilesArguments.sort_dir.toLowerCase()}
                                        active={syncJobsStore.getProcessedFilesArguments.sort === PROCESSED_FILE_PROCESSED}
                                        id={`${idPrefix}_sort_time`}
                                    >
                                        {t("slingshot_page.tabs.replications.processed_files_modal.table.timestamp")}
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell />
                            </MuiTableRow>
                        </TableHead>
                        <TableBody>
                            {syncJobsStore.processedFiles.map((file) => (
                                <TableRow
                                    onClick={openTasksModal(file.task_results, file.id)}
                                    file={file}
                                    key={file.id}
                                    parentId={`${idPrefix}_${file.id}`}
                                />
                            ))}
                        </TableBody>
                    </Table>
                    <Grid>
                        <Divider />
                        <Box pl={6}>
                            <TablePagination
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                onChangePage={handleChangePage}
                                count={syncJobsStore.processedFilesCount}
                                rowsPerPage={syncJobsStore.getProcessedFilesArguments.limit}
                                page={syncJobsStore.getProcessedFilesArguments.page}
                                parentId={idPrefix}
                            />
                        </Box>
                    </Grid>
                    <ProcessedTasksModal
                        tasks={state.sortedCurrentTasks}
                        onBack={modalHistory.goBack}
                        onClose={processedTasksModal.closeAll}
                        fileId={state.currentFileId}
                        parentId={idPrefix}
                    />
                </>
            ) : (
                <EmptyCardPlaceholder
                    inProgress={state.searchInProgress}
                    message={t("slingshot_page.tabs.replications.processed_files_modal.table.empty_placeholder")}
                />
            )}
        </Dialog>
    ));
};

export default ProcessedFilesModal;
