import { types } from "mobx-state-tree";
import ArgumentsTaskType from "api/slingshot/automations/Types/ArgumentsTaskType";

const TaskType = types.model("TaskType", {
    action: types.maybeNull(
        types.model("action", {
            action: types.string,
            id: types.maybe(types.number),
            installed: types.maybe(types.boolean),
            name: types.maybe(types.string)
        })
    ),
    arguments: types.maybe(
        types.union(
            ArgumentsTaskType,
            types.snapshotProcessor(ArgumentsTaskType, {
                preProcessor(snapshot) {
                    return snapshot && JSON.parse(snapshot);
                }
            })
        )
    ),
    automation_id: types.maybe(types.number),
    datasets: types.maybe(
        types.array(
            types.model({
                dataset_no: types.maybe(types.number),
                dataset_type: types.maybe(types.string),
                destination_no: types.maybe(types.number)
            })
        )
    ),
    deleted: types.maybe(types.boolean),
    enabled: types.maybe(types.boolean),
    event_object_types: types.maybeNull(types.array(types.string)),
    fail_automation_if_failed: types.maybe(types.boolean),
    group_action: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    mandatory: types.maybe(types.boolean),
    restart_if_failed: types.maybe(types.boolean),
    sequence: types.maybe(types.number),
    type: types.maybe(types.string)
});

export default TaskType;
