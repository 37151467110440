import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    button: {
        position: "relative",
        "& span": {
            color: ({ hideLabel }) => (hideLabel ? "transparent" : "")
        }
    },
    icon: {
        position: "absolute"
    }
}));
