import React from "react";

const CheckboxIndeterminateIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <rect width="24" height="24" fill="#1E90FF" rx="4" />
                <rect width="12" height="2" x="6" y="11" fill="#FFF" rx="1" />
            </g>
        </svg>
    );
};

export default CheckboxIndeterminateIcon;
