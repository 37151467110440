import React from "react";
import { useStore } from "hooks/useStore";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import Dialog from "components/Dialog";
import { Grid, Button, Box, Divider, Typography, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useStyles } from "./RemoveBricksStatusDialog.styles";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import { CLUSTER_SECONDS_FORMAT } from "const";
import moment from "moment";

const RemoveBricksStatusDialog = ({ open, onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { clusterStore }
    } = useStore();

    const state = useLocalStore(() => ({
        onStopInProgress: false,
        onCommitInProgress: false,
        onRefreshInProgress: false,
        isOnCommitSubmitted: false,
        isOnRefreshSubmitted: false,
        get currentVolumeTasks() {
            return clusterStore.removeBricksTasks.find(task => {
                return task.volumeName === clusterStore.currentVolumeName;
            });
        }
    }));

    const onDialogOpen = () => {
        state.onStopInProgress = false;
        state.onCommitInProgress = false;
        state.onRefreshInProgress = false;
        state.isOnCommitSubmitted = false;
        state.isOnRefreshSubmitted = false;
    };

    const payload = () => {
        return {
            volumeName: state.currentVolumeTasks?.volumeName,
            bricks: state.currentVolumeTasks?.bricks.map(brick => {
                return { peer: brick.peer, path: brick.path, name: brick.name };
            })
        };
    };

    const onStopBricksStatus = async () => {
        state.onStopInProgress = true;
        const res = await clusterStore.volumeRemoveBricksStop(payload());
        state.onStopInProgress = false;
        return res;
    };

    const onCommitBricksStatus = async () => {
        state.onStopInProgress = true;
        const res = await clusterStore.volumeRemoveBricksCommit(payload());
        state.onStopInProgress = false;
        res && (state.isOnCommitSubmitted = true);
    };

    const getPeerAlias = peerName => {
        return clusterStore.peers.find(peer => peer.name === peerName)?.alias;
    };

    return useObserver(() => (
        <Dialog
            title={t("cluster.remove_bricks_status.title")}
            withoutPaddings
            maxWidth="lg"
            open={open}
            onSubmit={onStopBricksStatus}
            inProgress={state.onStopInProgress}
            onClose={onClose}
            onEnter={onDialogOpen}
            submitBtnLabel={t("cluster.remove_bricks_status.button.stop")}
            buttons={[
                <Button key={"cancel_button"} onClick={onClose} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>,
                <AnimatedSubmitButton
                    key={"commit_button"}
                    label={t("cluster.remove_bricks_status.button.commit")}
                    isSubmitted={state.isOnCommitSubmitted}
                    submit={onCommitBricksStatus}
                    inProgress={state.isOnCommitInProgress}
                />
            ]}
        >
            <Box width={"100%"} px={6} py={4}>
                <Typography variant={"subtitle1"}>{t("cluster.volume.button.bricks")}</Typography>
            </Box>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.peers.header.name")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.local_bricks.header.cluster_path_on_evo")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.local_bricks.header.brick_name")}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.currentVolumeTasks?.bricks?.map((brick, index) => (
                        // TODO  Do not use Array index in keys
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={`taskBrick-${index}`}>
                            <TableCell>{getPeerAlias(brick.peer)}</TableCell>
                            <TableCell>{brick.path}</TableCell>
                            <TableCell>{brick.name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Divider />
            <Box width={"100%"} px={6} pt={4} pb={6}>
                <Grid container>
                    <Typography variant={"h6"}>{t("cluster.remove_bricks_status.status")}</Typography>
                    &nbsp;<Typography>{state.currentVolumeTasks?.status}</Typography>
                </Grid>
            </Box>
            <Box width={"100%"} px={6} pt={6} pb={4}>
                <Typography variant={"subtitle1"}>{t("cluster.remove_bricks_status.nodes")}</Typography>
            </Box>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.remove_bricks_status.node")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.remove_bricks_status.rebalanced_files")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.remove_bricks_status.size")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.remove_bricks_status.scanned")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.remove_bricks_status.failures")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.remove_bricks_status.skipped")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.volume.button.rebalance.status")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle1"}>{t("cluster.remove_bricks_status.run_in_time")}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.currentVolumeTasks?.nodes?.map((node, index) => (
                        // TODO  Do not use Array index in keys
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={`taskNode-${index}`}>
                            <TableCell>{node.peerAlias}</TableCell>
                            <TableCell>{node.files}</TableCell>
                            <TableCell>{node.size}</TableCell>
                            <TableCell>{node.scanned}</TableCell>
                            <TableCell>{node.failures}</TableCell>
                            <TableCell>{node.skipped}</TableCell>
                            <TableCell className={classes.status}>{node.statusStr}</TableCell>
                            <TableCell>
                                {moment()
                                    .startOf("day")
                                    .seconds(node.runtime || 0)
                                    .format(CLUSTER_SECONDS_FORMAT)}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow className={classes.divideredRow}>
                        <TableCell className={classes.totalNodes}>{t("cluster.remove_bricks_status.aggregate")}</TableCell>
                        <TableCell>{state.currentVolumeTasks?.aggregate.files}</TableCell>
                        <TableCell>{state.currentVolumeTasks?.aggregate.size}</TableCell>
                        <TableCell>{state.currentVolumeTasks?.aggregate.scanned}</TableCell>
                        <TableCell>{state.currentVolumeTasks?.aggregate.failures}</TableCell>
                        <TableCell>{state.currentVolumeTasks?.aggregate.skipped}</TableCell>
                        <TableCell className={classes.status}>{state.currentVolumeTasks?.aggregate.statusStr}</TableCell>
                        <TableCell>
                            {moment()
                                .startOf("day")
                                .seconds(state.currentVolumeTasks?.aggregate.runtime || 0)
                                .format(CLUSTER_SECONDS_FORMAT)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Dialog>
    ));
};

export default RemoveBricksStatusDialog;
