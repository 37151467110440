import Request from "api/Request";

const GetSchedule = Request.props({
    path: "/com/studionetworksolutions/evo/SupportInfo",
    method: "get_schedule"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetSchedule;
