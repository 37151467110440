export const LAST_JOB_SUMMARY_FORMAT = "YYYY-MM-DD HH:mm";

export const JOB_STATUS = {
    disabled: "Disabled",
    canceled: "Canceled",
    paused: "Paused",
    idle: "Idle",
    active: "Active",
    error: "Error"
};
