import React, { useContext } from "react";
import { Context } from "store";
import { useObserver } from "mobx-react";
import DataCard from "components/DataCard";
import { Typography, Grid, Box, Link } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { URL_SUPPORT_OS_DATA_TAB, HASH_COMPONENT_DATABASES } from "routes";
import { useStyles } from "./BackupSettingsWarningCard.styles";
import { ADMIN } from "const/userRolesConst";
import { useHistory } from "react-router-dom";

const BackupSettingsWarningCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const {
        store: { authStore }
    } = useContext(Context);

    const navigateToComponentDatabases = () => {
        history.push(`${URL_SUPPORT_OS_DATA_TAB}#${HASH_COMPONENT_DATABASES}`);
    };
    const linkToComponentDatabases = <Link className={classes.link} onClick={navigateToComponentDatabases} />;

    return useObserver(() => (
        <DataCard
            title={t(
                authStore.role === ADMIN ? "backup_settings_warning_card.title_admin_role" : "backup_settings_warning_card.title"
            )}
            headerDivider
            autoHeight
            emptyPadding
        >
            <Box py={20} px={6}>
                <Grid container direction={"column"} alignItems={"center"} justify={"center"}>
                    <Typography className={classes.textWithOpacity}>
                        {authStore.role === ADMIN ? (
                            <Trans
                                i18nKey={"backup_settings_warning_card.placeholder.link_to_advanced_admin_role"}
                                components={linkToComponentDatabases}
                            />
                        ) : (
                            t("backup_settings_warning_card.placeholder.link_to_advanced")
                        )}
                    </Typography>
                </Grid>
            </Box>
        </DataCard>
    ));
};

export default BackupSettingsWarningCard;
