import { makeStyles } from "@material-ui/core/styles";
import { HEADER_QUAD_ITEM_DOTS_MAXWIDTH, HEADER_QUAD_ITEM_DOTS_MAXHEIGHT } from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    quadTitle: {
        fontSize: 10,
        opacity: 0.6
    },
    quadBody: {
        paddingTop: theme.spacing(1),
        width: HEADER_QUAD_ITEM_DOTS_MAXWIDTH,
        maxHeight: HEADER_QUAD_ITEM_DOTS_MAXHEIGHT
    }
}));
