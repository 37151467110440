import React from "react";
import { useStyles } from "./StepCard.style";
import { Grid, Typography } from "@material-ui/core";
import { useStore } from "hooks/useStore";

const StepCard = props => {
    const { count, title, children, sideComponent: SideComponent, cardsOnlyAuthorized = false, ...rest } = props;
    const classes = useStyles();
    const isSideComponent = Boolean(SideComponent);
    const isArraySideComponent = Array.isArray(SideComponent);
    const {
        store: { authStore }
    } = useStore();

    const StructureCard = () => (
        <React.Fragment>
            <Grid item container spacing={4} alignItems={"center"} className={classes.titleContainer} wrap={"nowrap"}>
                <Grid item className={classes.counter}>
                    <Typography color={"primary"} variant={"subtitle1"} component={"span"} className={classes.countText}>
                        {count}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant={"h2"}>{title}</Typography>
                </Grid>
            </Grid>
            <Grid item className={classes.cardBody}>
                {children}
            </Grid>
        </React.Fragment>
    );

    if (isSideComponent && cardsOnlyAuthorized && authStore.isAuthorized)
        return (
            <Grid container className={classes.container} spacing={1} {...rest}>
                <Grid item xs={6}>
                    <StructureCard />
                </Grid>

                {isSideComponent ? (
                    <Grid item xs={6}>
                        {isArraySideComponent ? (
                            <Grid container direction={"column"} spacing={1}>
                                {SideComponent.map(({ id, component: Component }) => {
                                    return (
                                        <Grid item key={id}>
                                            <Component />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        ) : (
                            <SideComponent />
                        )}
                    </Grid>
                ) : null}
            </Grid>
        );

    return (
        <Grid container className={classes.container} spacing={1} {...rest}>
            <StructureCard />
        </Grid>
    );
};

export default StepCard;
