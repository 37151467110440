import { types, flow, getParent } from "mobx-state-tree";

import Socket from "websocket";
import GetProgressTaskPage from "api/task/Requests/GetProgressTaskPage";
import GetBatchTaskPage from "api/task/Requests/GetBatchTaskPage";
import PageQuery from "api/task/Types/PageQuery";
import GetProgressTaskPageResult from "api/task/Types/GetProgressTaskPageResult";
import GetBatchTaskPageResult from "api/task/Types/GetBatchTaskPageResult";
import ProgressTask from "api/task/Types/ProgressTask";
import BatchTask from "api/task/Types/BatchTask";
import { STARTED, FINISHED, FAILED } from "const/taskConst";
import { USER } from "const/userRolesConst";

const DEFAULT_LIMIT = 30;

const TaskStore = types
    .model({
        taskPageResult: types.optional(GetProgressTaskPageResult, { data: { total: 0, data: [] } }),
        batchPageResult: types.optional(GetBatchTaskPageResult, { data: { total: 0, data: [] } }),
        newTasksCount: types.optional(types.number, 0),
        newTasks: types.optional(types.array(types.union(ProgressTask, BatchTask)), [])
    })
    .views(self => ({
        get tasks() {
            const { authStore } = getParent(self);

            const tasks = [...self.taskPageResult.data.data, ...self.batchPageResult.data.data].sort((a, b) => b.id - a.id) || [];
            // TODO: refactor filtration
            return tasks
                .filter(({ type }) => {
                    if (authStore.role === USER) {
                        return type.includes("slingshot");
                    }

                    return true;
                })
                .reduce((acc, el) => {
                    const duplicate = acc.find(accEl => accEl.id === el.id);

                    if (duplicate) {
                        return acc;
                    }

                    return [...acc, el];
                }, []);
        },
        get filteredNewTasksCount() {
            const { uiStore, authStore } = getParent(self);

            const getKey = (status, isBatch) => {
                switch (status) {
                    case STARTED:
                    case FINISHED:
                        return "info";
                    case FAILED:
                        return isBatch ? "warning" : "error";
                    default:
                        return "info";
                }
            };

            return self.newTasks
                .filter(({ status, isBatch }) => {
                    const notificationsFilterSettings = uiStore.parameters?.notificationsFilterSettings;
                    if (!notificationsFilterSettings) return false;

                    const key = getKey(status, isBatch);
                    const hasFlag =
                        typeof notificationsFilterSettings[key] === "boolean" ? notificationsFilterSettings[key] : true;
                    return hasFlag && key !== "info";
                })
                .filter(({ type }) => {
                    if (authStore.role === USER) {
                        return type.includes("slingshot");
                    }

                    return true;
                }).length;
        }
    }))
    .actions(self => ({
        fetchTasks: flow(function*(
            taskRequestParams = PageQuery.create({ offset: 0, limit: DEFAULT_LIMIT }),
            batchTaskRequestParams = PageQuery.create({ offset: 0, limit: DEFAULT_LIMIT })
        ) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const taskReq = GetProgressTaskPage.create().init(taskRequestParams);
                const batchTaskReq = GetBatchTaskPage.create().init(batchTaskRequestParams);
                const [taskRes, batchTaskRes] = yield Promise.all([Socket.send(taskReq), Socket.send(batchTaskReq)]);
                self.taskPageResult = taskRes;
                self.batchPageResult = batchTaskRes;
                self.setNewTasksCount(0);
                self.newTasks = [];
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        addOrUpdateTask(data, sequenceId) {
            const { uiStore, notificationStore } = getParent(self);
            const isFinished = notificationStore.isEventForProgressTaskExist(data.id);
            const foundTask = self.taskPageResult.data.data.find(task => task.id === data.id);
            const isNewTask = !foundTask && !isFinished;

            if (!uiStore.isNotificationMenuOpen && isNewTask) {
                self.newTasksCount += 1;
                self.newTasks.push({ ...data, sequenceId });
            }

            if (isNewTask) {
                self.taskPageResult.data.data.push({ ...data, sequenceId });
                return;
            }

            if (foundTask && sequenceId > foundTask.sequenceId) {
                self.taskPageResult.data.data.replace(
                    self.taskPageResult.data.data.map(task => {
                        return task.id === foundTask.id ? { ...data, sequenceId } : task;
                    })
                );
            }

            if (data.status === FAILED || data.status === FINISHED) {
                self.removeTask(data.id);
            }
        },
        addOrUpdateBatchTask(data, sequenceId) {
            const { uiStore, notificationStore } = getParent(self);
            const isFinished = notificationStore.isEventForBatchTaskExist(data.id);
            const foundTask = self.batchPageResult.data.data.find(task => task.id === data.id);
            const isNewTask = !foundTask && !isFinished;

            if (!uiStore.isNotificationMenuOpen && isNewTask) {
                self.newTasksCount += 1;
                self.newTasks.push({ ...data, sequenceId });
            }

            if (isNewTask) {
                self.batchPageResult.data.data.push({ ...data, sequenceId });
                return;
            }

            if (foundTask && sequenceId > foundTask.sequenceId) {
                self.batchPageResult.data.data.replace(
                    self.batchPageResult.data.data.map(task => {
                        return task.id === foundTask.id ? { ...data, sequenceId } : task;
                    })
                );
            }

            if (data.status === FAILED || data.status === FINISHED) {
                self.removeBatchTask(data.id);
            }
        },
        removeTask(taskId) {
            self.taskPageResult.data.data.replace(self.taskPageResult.data.data.filter(task => task.id !== taskId));
        },
        removeBatchTask(taskId) {
            self.batchPageResult.data.data.replace(self.batchPageResult.data.data.filter(task => task.id !== taskId));
        },
        setNewTasksCount(count) {
            self.newTasksCount = count;
        }
    }));

export default TaskStore;
