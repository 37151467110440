import React from "react";

const IscsiIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#BFD1E1"
                fillRule="evenodd"
                d="M17.4 12.2c.408-.306.973-.253 1.319.104l.081.096 3.45 4.6-3.45 4.6-.081.096c-.317.327-.818.399-1.214.173L17.4 21.8l-.096-.081c-.327-.317-.399-.818-.173-1.214l.069-.105 1.799-2.4H5c-.513 0-.936-.386-.993-.883L4 17c0-.513.386-.936.883-.993L5 16h13.999L17.2 13.6l-.069-.105c-.247-.432-.139-.99.269-1.295zM6.6 2.2c.408.306.516.863.269 1.295L6.8 3.6 5 6h14c.552 0 1 .448 1 1s-.448 1-1 1H5l1.8 2.4c.306.408.253.973-.104 1.319L6.6 11.8c-.408.306-.973.253-1.319-.104L5.2 11.6 1.75 7 5.2 2.4c.331-.442.958-.531 1.4-.2z"
            />
        </svg>
    );
};

export default IscsiIcon;
