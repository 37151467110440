const createFields = () => {
    return [
        {
            name: "share"
        },
        {
            name: "path"
        }
    ];
};

export default createFields;
