import Request from "api/Request";

const GenerateNewPrivateKey = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Certificates",
    method: "generate_new_private_key"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GenerateNewPrivateKey;
