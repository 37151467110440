import { types } from "mobx-state-tree";

import Range from "api/firewall/Types/Range";

const Rule = types.model({
    portsRange: types.array(Range),
    protocol: types.string,
    addressesRange: types.array(Range),
    policy: types.string,
    enabled: types.boolean
});

export default Rule;
