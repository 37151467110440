import React from "react";
import { observer } from "mobx-react";
import TextInputBase from "../TextInput";
import { mapFieldToProps } from "utils/mapFieldToProps";

const TextInput = (props) => {
    const { field, id, ...rest } = props;
    const fieldToProps = mapFieldToProps(field);
    return (
        <TextInputBase
            {...fieldToProps}
            id={id ?? fieldToProps.id}
            error={Boolean(field.error)}
            helperText={field.error}
            {...rest}
        />
    );
};

export default observer(TextInput);
