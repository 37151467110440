import { types } from "mobx-state-tree";
import UpdateTaskType from "api/slingshot/automations/Types/UpdateTaskType";

const ScheduleType = types.model("Schedule", {
    minutes: types.number,
    date: types.string
});

const UpdateAutomationRules = types.model("UpdateAutomationRules", {
    black: types.boolean,
    case_sensitive: types.boolean,
    depth: types.number,
    file_type: types.string,
    id: types.maybeNull(types.number),
    name_mask: types.string,
    priority: types.number,
    regexp: types.boolean,
    url: types.string,
    watch_mask: types.number
});

const UpdateAutomationFilter = types.model("UpdateAutomationFilter", {
    id: types.maybeNull(types.number),
    include_roots: types.maybe(types.boolean),
    include_trash: types.maybe(types.boolean),
    rules: types.array(UpdateAutomationRules)
});

const UpdateAutomationWalks = types.model("UpdateAutomationWalks", {
    event_count: types.number,
    failed_event_count: types.number,
    finished: types.string,
    id: types.number,
    processed: types.string,
    processed_event_count: types.number,
    started: types.string,
    type: types.string,
    unprocessed_event_count: types.number
});

const UpdateAutomationLastInstance = types.model("UpdateAutomationLastInstance", {
    automation_id: types.maybe(types.number),
    finished: types.maybe(types.string),
    id: types.maybe(types.number),
    result: types.maybe(types.string),
    started: types.maybe(types.string),
    walks: types.maybe(types.array(UpdateAutomationWalks))
});

const UpdateAutomationSchedule = types.model("UpdateAutomationSchedule", {
    schedule: types.snapshotProcessor(ScheduleType, {
        postProcessor: snapshot => (snapshot ? JSON.stringify(snapshot) : "{}")
    })
});

const UpdateAutomationArguments = types.model("UpdateAutomationArguments", {
    deleted: types.boolean,
    enabled: types.boolean,
    filter: types.maybe(UpdateAutomationFilter),
    id: types.maybeNull(types.number),
    internal: types.boolean,
    name: types.string,
    paused: types.boolean,
    paused_by: types.maybe(types.string),
    otf: types.maybe(types.boolean),
    schedule: types.maybeNull(UpdateAutomationSchedule),
    tasks: types.array(UpdateTaskType),
    running_tasks: types.maybe(types.number),
    owner: types.maybe(types.string),
    last_instance: types.maybe(UpdateAutomationLastInstance)
});

const UpdateAutomation = types.model("UpdateAutomation", {
    automation: types.union(UpdateAutomationArguments, types.array(UpdateAutomationArguments)),
    limit: types.maybe(types.number),
    offset: types.maybe(types.number),
    total: types.maybe(types.number)
});

export default UpdateAutomation;
