import React, { useEffect } from "react";
import MainTemplate from "components/MainTemplate";
import FilesTable from "./components/FilesTable";
import HeaderTitle from "components/HeaderTitle";
import { useTranslation } from "react-i18next";
import DocIcon from "assets/DocIcon";
import { Grid } from "@material-ui/core";
import { FILE_NAVIGATOR } from "const/shortcutNameConst";
import { useStore } from "hooks/useStore";
import MultipleEvoTitle from "components/MultipleEvoTitle/MultipleEvoTitle";
import { observer } from "mobx-react";
import SideBulkBar from "components/SideBulkBar/SideBulkBar";
import BulkBarFlatButton from "components/BulkBarFlatButton/BulkBarFlatButton";
import IscsiIcon from "assets/IscsiIcon";
import TrashIcon from "assets/TrashIcon";
import SharingIcon from "assets/SharingIcon";
import WarningDialog from "./components/WarningDialog";
import { useChangeOwnerModal, useEditAclModal, useWarningDialog } from "hooks/modals";
import ChangeOwnerModal from "./components/ChangeOwnerModal/ChangeOwnerModal";
import EditAclModal from "./components/EditAclModal/EditAclModal";
import FilesDrawer from "./components/FilesDrawer/FilesDrawer";
import useStoreByIp from "hooks/useStoreByIp";
import { useFileNavigatorStoreByIp } from "./FileNavigator.hooks";

const drawerName = "filesNavigatorBulkBar";
const FileNavigator = () => {
    const { t } = useTranslation();
    const {
        multipleEvosRootStore,
        store: { fileNavigatorStore, uiStore },
    } = useStore();

    const { fileStore } = useStoreByIp({ ip: fileNavigatorStore.currentEvoIp });
    const fileNavigator = useFileNavigatorStoreByIp(fileNavigatorStore.currentEvoIp);

    const warningDialog = useWarningDialog();
    const changeOwnerModal = useChangeOwnerModal();
    const editAclModal = useEditAclModal();

    const openChangeOwnerModal = () => {
        fileNavigator.setCurrentFileByIndex(0);
        changeOwnerModal.open();
    };
    const closeChangeOwnerModal = () => {
        fileStore.removeCurrentFile();
        changeOwnerModal.close();
    };
    const openEditAclModal = () => {
        fileNavigator.setCurrentFileByIndex(0);
        editAclModal.open();
    };
    const closeEditAclModal = () => {
        fileStore.removeCurrentFile();
        editAclModal.close();
    };

    useEffect(() => {
        if (fileNavigatorStore.selectedFilesLength > 0) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, fileNavigatorStore.clearSelected);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
            uiStore.changeDrawer();
        }
    }, [fileNavigatorStore.selectedFilesLength]);

    return (
        <MainTemplate
            headerTitle={<HeaderTitle icon={<DocIcon />} title={t("file_navigator.title")} shortcutName={FILE_NAVIGATOR} />}
        >
            <>
                <SideBulkBar
                    isOpen={uiStore.openedDrawer === drawerName}
                    onClose={fileNavigatorStore.clearSelected}
                    itemsCount={fileNavigatorStore.selectedFilesLength}
                >
                    {fileNavigatorStore.selectedFilesLength === 1 && (
                        <>
                            <BulkBarFlatButton onClick={openChangeOwnerModal} icon={<IscsiIcon />}>
                                {t("file_navigator.bulk_bar.button.change_owner")}
                            </BulkBarFlatButton>
                            <BulkBarFlatButton onClick={openEditAclModal} icon={<SharingIcon />}>
                                {t("file_navigator.bulk_bar.button.edit_acl")}
                            </BulkBarFlatButton>
                        </>
                    )}
                    <BulkBarFlatButton onClick={warningDialog.open} errorColor icon={<TrashIcon />}>
                        {t("common.button.delete")}
                    </BulkBarFlatButton>
                </SideBulkBar>
                <ChangeOwnerModal open={changeOwnerModal.isOpen} onClose={closeChangeOwnerModal} />
                <EditAclModal open={editAclModal.isOpen} onClose={closeEditAclModal} />
                <FilesDrawer />
                <WarningDialog
                    open={warningDialog.isOpen}
                    onClose={warningDialog.close}
                    submit={fileNavigatorStore.deleteFiles}
                    count={fileNavigatorStore.selectedFilesLength}
                />
                <Grid item container direction={"column"} spacing={6} justify={"flex-start"}>
                    <Grid container item direction="column" spacing={6}>
                        {multipleEvosRootStore.storesLength > 0 && (
                            <Grid item>
                                <MultipleEvoTitle />
                            </Grid>
                        )}
                        <Grid item>
                            <FilesTable />
                        </Grid>
                    </Grid>

                    {multipleEvosRootStore.storesIps.map((ip) => (
                        <Grid key={ip} container item direction="column" spacing={6}>
                            <Grid key={ip} item>
                                <MultipleEvoTitle ip={ip} />
                            </Grid>

                            <Grid item>
                                <FilesTable ip={ip} />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </>
        </MainTemplate>
    );
};

export default observer(FileNavigator);
