import { types } from "mobx-state-tree";

import VolumeId from "api/volume/Types/VolumeId";

const DefragState = types.model({
    volumeId: types.maybe(VolumeId),
    fragmentation: types.number,
    status: types.string
});

export default DefragState;
