import React from "react";
import { observer } from "mobx-react";
import { useStyles } from "./DataReadonlyCard.styles";
import { ButtonBase, Divider, Grid, Paper } from "@material-ui/core";
import CardHeader from "components/CardHeader";
import EditIcon from "assets/EditIcon";

const DataReadonlyCard = ({
    title,
    children,
    autoHeight,
    onClick,
    headerDivider,
    control,
    emptyPadding,
    denseBottom,
    disabled
}) => {
    const classes = useStyles({ autoHeight, denseBottom, emptyPadding, disabled });

    const handleClick = e => {
        if (!onClick || disabled) return;

        onClick(e);
    };

    const controls = () => {
        let controls = [control];

        if (!disabled) {
            controls.push(
                <ButtonBase>
                    <EditIcon />
                </ButtonBase>
            );
        }

        return controls;
    };

    return (
        <Paper className={classes.paper} onClick={handleClick}>
            <Grid container direction={"column"} className={classes.container} wrap={"nowrap"}>
                {title && (
                    <Grid item>
                        <CardHeader title={title} control={controls()} />
                    </Grid>
                )}
                {headerDivider && <Divider />}
                <Grid item className={classes.childrenContainer}>
                    {children}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default observer(DataReadonlyCard);
