import React, { useContext, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./BondDrawer.styles";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { observer, useLocalStore } from "mobx-react";
import StatusTranslation from "../StatusTranslation";
import SavedInputIndicator from "components/SavedInputIndicator";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import EditServiceOrderDialog from "../EditServiceOrderDialog";
import EditSettingsDialog from "../EditSettingsDialog";
import EthernetIcon from "assets/EthernetIcon";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import useStoreByIp from "hooks/useStoreByIp";
import EditIcon from "assets/EditIcon";
import FiberIcon from "assets/FibreIcon";
import CloseIcon from "assets/CloseIcon";
import RefreshIcon from "assets/RefreshIcon";

const BondDrawer = ({ ip }) => {
    const drawerName = ip ? `${ip}BondDrawer` : "BondDrawer";
    const {
        store: { uiStore },
    } = useContext(Context);
    const { bondsStore, ethernetPortsStore } = useStoreByIp({ ip });
    const { t } = useTranslation();
    const classes = useStyles({ isWidgetOpen: uiStore.isWidgetsBarOpen });
    const currentBond = bondsStore.currentBond || { dhcpServer: {}, dhcpClient: {} };
    const state = useLocalStore(() => ({
        settingsDialogOpen: false,
        serviceOrderDialogOpen: false,
        deactivateInProgress: false,
        renewInProgress: false,
    }));
    useEffect(() => {
        if (bondsStore.currentBondName) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, closeDrawer);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [bondsStore.currentBondName]);

    const closeDrawer = () => {
        bondsStore.setCurrentBond("");
    };
    const openServiceOrderDialog = () => {
        state.serviceOrderDialogOpen = true;
    };
    const closeServiceOrderDialog = () => {
        state.serviceOrderDialogOpen = false;
    };
    const renderDetails = () => {
        return (
            <Grid className={classes.inputsContainer} container direction={"column"} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.bond_drawer.label.interfaces")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBond.interfaces?.join(", ")}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.ip")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBond.ip}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.subnet")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBond.netmask}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.gateway")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBond.gateway}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.speed")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBond.speed}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.status")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            <StatusTranslation label={currentBond.status} />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.mtu")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBond.mtu}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.metric")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBond.metric}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.mac")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>{currentBond.mac}</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("network.ethernet_ports_drawer.label.role")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.textInfo}>
                            <StatusTranslation label={bondsStore.roleOfCurrentPort} />
                        </Typography>
                    </Grid>
                </Grid>
                {bondsStore.isDhcpClientRole && (
                    <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                        <Grid item xs={6}>
                            <Typography>{t("network.ethernet_ports_drawer.label.override_dns")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SavedInputIndicator enabled={currentBond.dhcpClient.overrideDns} />
                        </Grid>
                    </Grid>
                )}
                {bondsStore.isDhcpServerRole && (
                    <>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("network.ethernet_ports_drawer.label.dhcp_from")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>{currentBond.dhcpServer.startDhcpRange}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                            <Grid item xs={6}>
                                <Typography>{t("network.ethernet_ports_drawer.label.dhcp_to")}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.textInfo}>{currentBond.dhcpServer.endDhcpRange}</Typography>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    };
    const openSettingsDialog = () => {
        state.settingsDialogOpen = true;
    };
    const closeSettingsDialog = () => {
        state.settingsDialogOpen = false;
    };

    const renewDhcpLease = async () => {
        state.renewInProgress = true;
        await bondsStore.renewDhcpLease({ port: bondsStore.currentBondName });
        state.renewInProgress = false;
        ethernetPortsStore.fetchAllNetRequests();
    };

    const removeBond = async () => {
        state.deactivateInProgress = true;
        await bondsStore.removeBond({ port: bondsStore.currentBondName });
        state.deactivateInProgress = false;
        ethernetPortsStore.fetchAllNetRequests();
        closeDrawer();
    };

    const renderActions = () => {
        return (
            <>
                <BulkBarFlatButton icon={<EditIcon />} onClick={openSettingsDialog}>
                    {t("network.ethernet_ports_drawer.button.edit_settings")}
                </BulkBarFlatButton>
                <BulkBarFlatButton icon={<FiberIcon />} onClick={openServiceOrderDialog}>
                    {t("network.ethernet_ports_drawer.button.edit_service_order")}
                </BulkBarFlatButton>
                {bondsStore.isDhcpClientRole && (
                    <BulkBarFlatButton icon={<RefreshIcon />} inProgress={state.renewInProgress} onClick={renewDhcpLease}>
                        {t("network.ethernet_ports_drawer.button.renew_dhcp_lease")}
                    </BulkBarFlatButton>
                )}
                <BulkBarFlatButton icon={<CloseIcon />} inProgress={state.deactivateInProgress} onClick={removeBond}>
                    {t("network.ethernet_ports_drawer.button.deactivate")}
                </BulkBarFlatButton>
            </>
        );
    };
    const submitBondChanges = async (data) => {
        const res = await bondsStore.saveNetBondSettings(data);
        ethernetPortsStore.fetchAllNetRequests();
        return res;
    };
    return (
        <DetailsActionsDrawer
            title={currentBond.interfaces?.join(", ")}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            icon={<EthernetIcon />}
            modals={[
                <EditServiceOrderDialog
                    ip={ip}
                    key={"BondEditServiceOrderDialog"}
                    open={state.serviceOrderDialogOpen}
                    handleClose={closeServiceOrderDialog}
                />,
                <EditSettingsDialog
                    key={"BondEditSettingsDialog"}
                    store={bondsStore}
                    handleClose={closeSettingsDialog}
                    open={state.settingsDialogOpen}
                    title={t("network.bond_edit_settings_dialog.title", { interfaces: currentBond.interfaces?.join(", ") || "" })}
                    onSubmit={submitBondChanges}
                    currentPort={currentBond}
                />,
            ]}
        />
    );
};

export default observer(BondDrawer);
