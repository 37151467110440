export const NONE = "none";
export const NO_ACCESS = "no_access";
export const RO = "ro";
export const RW = "rw";
export const CUSTOM = "custom";
export const SMB = "smb";
export const FTP = "ftp";
export const RESERVED = "internal";
export const NFS = "nfs";
export const SHARE_POOL_USB = "USB";
