import { types } from "mobx-state-tree";

const SnmpAgentSettings = types.model({
    evoVersionAvailable: types.boolean,
    localTimeAvailable: types.boolean,
    systemUptimeAvailable: types.boolean,
    cpuUsageAvailable: types.boolean,
    upsStatusAvailable: types.boolean,
    blocksIoAvailable: types.boolean,
    ioStatAvailable: types.boolean,
    sessionsAvailable: types.boolean,
    cachedVolumeAvailable: types.boolean,
    detailedStatAvailable: types.boolean
});

export default SnmpAgentSettings;
