import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    circularIconContainer: {
        position: "relative"
    },
    circularIconBackground: {
        position: "absolute",
        left: 0,
        color: theme.palette.grey["A100"]
    },
    circularIcon: {
        position: "absolute",
        left: 0
    },
    circularIconFull: {
        color: theme.palette.warning.main
    }
}));
