import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetFullFilesLog from "api/audit/Requests/GetFullFilesLog";
import GetFilesLogResult from "api/audit/Responses/GetFilesLogResult";

const FilesLogStore = types
    .model({
        fullFilesLogStore: types.maybe(GetFilesLogResult)
    })
    .views(self => ({
        get fullFilesLog() {
            return self.fullFilesLogStore?.data || [];
        }
    }))
    .actions(self => ({
        fetchFullFilesLog: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetFullFilesLog.create().init();
                const res = yield Socket.send(req);
                self.fullFilesLogStore = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        })
    }));

export default FilesLogStore;
