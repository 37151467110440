import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    inputPreview: {
        fontWeight: 500,
        wordBreak: "break-word"
    },
    icon: {
        display: "inline-flex",
        alignSelf: "center",
        order: ({ iconPosition }) => (iconPosition === "left" ? 0 : 1)
    }
}));
