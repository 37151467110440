import { types } from "mobx-state-tree";

import Request from "api/Request";
import IfaceNameArguments from "api/net/Types/IfaceNameArguments";

const RemoveBond = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "remove_bond",
    arguments: types.maybe(IfaceNameArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default RemoveBond;
