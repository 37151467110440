import { validateEmailList } from "./validationEmailList";

const { default: i18n } = require("i18n");

export const validationRecipientList = (msg = i18n.t("common.label.invalid_recipient_list")) => {
    return ({ field }) => {
        if (typeof field === "string") {
            return [field ? validateEmailList(field) : true, msg];
        }

        return [field.value ? validateEmailList(field.value) : true, msg];
    };
};
