import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    select: {
        marginLeft: theme.spacing(6),
        "& .MuiInputBase-root": {
            margin: theme.spacing(-2, -3.25),
            "&:not(.Mui-focused)": {
                background: "none"
            }
        },
        "&& span": {
            opacity: 0.9,
            fontSize: 20,
            fontWeight: "bold",
            lineHeight: 1.2
        }
    }
}));
