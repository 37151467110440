import { types } from "mobx-state-tree";

const FanSensor = types
    .model({
        name: types.string,
        speed: types.number
    })
    .views(self => ({
        get value() {
            return self.speed;
        }
    }));

export default FanSensor;
