import React, { useEffect, useContext } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import DataReadonlyCard from "components/DataReadonlyCard";
import MoreMenu from "components/MoreMenu";
import { RESTRICT_ACCESS_CARD } from "const/widgetNameConst";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import SavedInputIndicator from "components/SavedInputIndicator";
import { Context } from "store";
import EditRestrictModal from "./components/EditRestrictModal";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const RESTRICT_ACCESS_CARD_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_restrict_access_card`;

const RestrictAccessCard = () => {
    const { t } = useTranslation();

    const state = useLocalStore(() => ({
        isEditRestrictModalOpen: false
    }));

    const {
        store: { webserverStore }
    } = useContext(Context);

    useEffect(() => {
        webserverStore.fetchRestrictSettings();
    }, []);

    const openEditRestrictModal = () => {
        state.isEditRestrictModalOpen = true;
    };
    const closeEditRestrictModal = () => {
        state.isEditRestrictModalOpen = false;
    };

    return useObserver(() => (
        <>
            <EditRestrictModal open={state.isEditRestrictModalOpen} onClose={closeEditRestrictModal} />
            <DataReadonlyCard
                title={t("admin_settings.restrict_access.title")}
                headerDivider
                onClick={openEditRestrictModal}
                control={
                    <MoreMenu id={`${RESTRICT_ACCESS_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={RESTRICT_ACCESS_CARD} />
                    </MoreMenu>
                }
            >
                <Grid container direction={"column"} spacing={5}>
                    <Grid item container alignItems={"center"}>
                        <Grid xs={6} item>
                            <Typography>{t("admin_settings.restrict_access.label.restrict_access")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={webserverStore.restrictSettings?.restrictEnabled} />
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"}>
                        <Grid xs={6} item>
                            <Typography>{t("admin_settings.restrict_access.label.allowed_iface")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <Typography variant={"h6"}>{webserverStore.restrictSettings?.ifaceName}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DataReadonlyCard>
        </>
    ));
};

export default RestrictAccessCard;
