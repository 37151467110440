import React, { useContext } from "react";
import { useStyles } from "./AnimatedSubmitButton.styles";
import { Box, Button, CircularProgress } from "@material-ui/core";
import CheckAnimatedIcon from "assets/CheckAnimatedIcon";
import { useObserver, useLocalStore } from "mobx-react";
import clsx from "clsx";
import { Context } from "store";

const AnimatedSubmitButton = ({ disabled, submit, isSubmitted, label, inProgress, className, id }) => {
    const {
        store: { processingStore }
    } = useContext(Context);

    const isInProgressFromProps = typeof inProgress === "boolean";
    const isIsSubmittedFromProps = typeof isSubmitted === "boolean";

    const state = useLocalStore(() => ({
        isLoading: false,
        isSubmitted: false
    }));

    const classes = useStyles({
        hideLabel:
            (isIsSubmittedFromProps ? isSubmitted : state.isSubmitted) || (isInProgressFromProps ? inProgress : state.isLoading)
    });

    const localSubmit = async e => {
        let hasRequestInterval = false;
        let isRequestEnd = false;

        state.isLoading = true;

        const errorsListLengthBefore = processingStore.errorsList.length;

        setTimeout(() => {
            if (!isRequestEnd) {
                hasRequestInterval = true;
            }
        }, 0);
        await submit(e);
        isRequestEnd = true;

        const errorsListLengthAfter = processingStore.errorsList.length;

        if (errorsListLengthBefore >= errorsListLengthAfter && hasRequestInterval) {
            state.isSubmitted = true;
        }

        state.isLoading = false;

        setTimeout(() => {
            state.isSubmitted = false;
        }, 1000);
    };

    const currentSubmit = isIsSubmittedFromProps && isInProgressFromProps ? submit : localSubmit;

    const renderSubmitButtonInner = () => {
        if (isInProgressFromProps ? inProgress : state.isLoading) {
            return (
                <Box className={classes.icon}>
                    <CircularProgress color={"primary"} size={16} />
                </Box>
            );
        } else if (isIsSubmittedFromProps ? isSubmitted : state.isSubmitted) {
            return (
                <Box className={classes.icon}>
                    <CheckAnimatedIcon />
                </Box>
            );
        }
    };

    return useObserver(() => (
        <Button
            className={clsx(classes.button, className)}
            disabled={
                (isInProgressFromProps ? inProgress : state.isLoading) ||
                (isIsSubmittedFromProps ? isSubmitted : state.isSubmitted) ||
                disabled
            }
            variant={"contained"}
            color={"primary"}
            onClick={currentSubmit}
            id={id}
        >
            {label}
            {renderSubmitButtonInner()}
        </Button>
    ));
};

export default AnimatedSubmitButton;
