import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";

import createForm from "utils/createForm";

import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import Dialog from "components/Dialog";
import { Grid, Button } from "@material-ui/core";
import Switch from "components/MobxForm/Switch";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";

const createFields = notificationsFilterSettings => {
    return [
        {
            name: "info",
            default: notificationsFilterSettings ? notificationsFilterSettings?.info : true,
            type: "checkbox"
        },
        {
            name: "warning",
            default: notificationsFilterSettings ? notificationsFilterSettings?.warning : true,
            type: "checkbox"
        },
        {
            name: "error",
            default: notificationsFilterSettings ? notificationsFilterSettings?.error : true,
            type: "checkbox"
        }
    ];
};

const FilterNotificationsDialog = ({ open, onClose }) => {
    const { t } = useTranslation();

    const {
        store: { uiStore }
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(uiStore?.parameters?.notificationsFilterSettings)
        })
    }));

    const unsavedChangesModal = useModal();

    const closeWithReset = () => {
        state.isSubmitted = false;
        onClose();
    };

    const submit = async () => {
        state.inProgress = true;
        const res = await uiStore.patchUiParameters({ notificationsFilterSettings: state.form.values() });
        state.inProgress = false;
        res && (state.isSubmitted = true);

        setTimeout(() => {
            closeWithReset();
        }, 1000);

        return res;
    };

    const updateForm = () => {
        state.form.reset();
        state.form.$("info").set("value", uiStore?.parameters?.notificationsFilterSettings?.info ?? true);
        state.form.$("warning").set("value", uiStore?.parameters?.notificationsFilterSettings?.warning ?? true);
        state.form.$("error").set("value", uiStore?.parameters?.notificationsFilterSettings?.error ?? true);
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            closeWithReset();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            inProgress={state.inProgress}
            title={t("notifications.filter_notifications_dialog.title")}
            open={open}
            onClose={closeIfNeeded}
            onEnter={updateForm}
            submitBtn={<AnimatedSubmitButton label={t("common.button.save")} isSubmitted={state.isSubmitted} submit={submit} />}
            buttons={
                <Button onClick={closeWithReset} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"}>
                <RowControlLabelSwitch
                    control={<Switch field={state.form.$("info")} />}
                    label={t("notifications.filter_notifications_dialog.toggle_info")}
                />
                <RowControlLabelSwitch
                    control={<Switch field={state.form.$("warning")} />}
                    label={t("notifications.filter_notifications_dialog.toggle_warning")}
                />
                <RowControlLabelSwitch
                    control={<Switch field={state.form.$("error")} />}
                    label={t("notifications.filter_notifications_dialog.toggle_error")}
                />
            </Grid>
            <UnsavedChangesDialog
                onConfirm={closeWithReset}
                onClose={unsavedChangesModal.close}
                open={unsavedChangesModal.isOpen}
            />
        </Dialog>
    ));
};

export { FilterNotificationsDialog };
