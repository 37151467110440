import React from "react";
import { observer } from "mobx-react";
import { useProxyGenerationSettings } from "hooks/modals";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import EditIcon from "assets/EditIcon";
import i18next from "i18next";
import { Grid } from "@material-ui/core";
import DisksMinusIcon from "assets/DisksMinusIcon";
import ShareMinusIcon from "assets/ShareMinusIcon";
import ExcludedExtensionsDialog from "pages/Slingshot/components/ExcludedExtensionsDialog";
import ExcludedFoldersDialog from "pages/Slingshot/components/ExcludedFoldersDialog";

const ACTIONS_ID_PREFIX = `actions`;

const RenderActions = ({ bulkBar = false, onClose, parentId }) => {
    const idPrefix = parentId ? `${parentId}_${ACTIONS_ID_PREFIX}` : ACTIONS_ID_PREFIX;
    const proxyGenerationSettingsModal = useProxyGenerationSettings();

    const {
        store: { slingshotStorageStore, indexingConfigStore },
    } = useStore();

    const { excludedExtensionsDialog, excludedFoldersDialog, excludedIndexFoldersDialog } = useModalCombine([
        "excludedExtensionsDialog",
        "excludedFoldersDialog",
        "excludedIndexFoldersDialog",
    ]);
    const currentShareName = slingshotStorageStore.checkedSharesNames[0] ?? slingshotStorageStore.currentShareName;

    const handleAddExcludedFolder = (excludedFn) => async (foldersList) => {
        const res = await excludedFn(currentShareName, foldersList);
        if (res) onClose();
        return res;
    };

    const addExcludedExtension = async (extensionsList) => {
        const data = { extensionBlackList: extensionsList };
        const res = await indexingConfigStore.setExcludedExtensionsList(currentShareName, data);
        if (res) onClose();
        return res;
    };

    const EditProxiesGenerationSettingButton = () => (
        <BulkBarFlatButton onClick={proxyGenerationSettingsModal.open} icon={<EditIcon />}>
            {i18next.t("slingshot.index_proxy.proxy_generation.action.edit_proxies_generation_setting.button")}
        </BulkBarFlatButton>
    );

    if (bulkBar) return <EditProxiesGenerationSettingButton />;

    if (currentShareName)
        return (
            <Grid direction={"column"} container>
                <EditProxiesGenerationSettingButton />
                <BulkBarFlatButton
                    onClick={excludedExtensionsDialog.open}
                    icon={<DisksMinusIcon />}
                    id={`${idPrefix}_open_excluded_extensions_dialog`}
                >
                    {i18next.t("slingshot.index_proxy.proxy_generation.action.exclude_extensions")}
                </BulkBarFlatButton>
                <BulkBarFlatButton
                    onClick={excludedFoldersDialog.open}
                    icon={<ShareMinusIcon />}
                    id={`${idPrefix}_open_excluded_folders_dialog`}
                >
                    {i18next.t("slingshot.index_proxy.proxy_generation.action.exclude_folders")}
                </BulkBarFlatButton>
                <BulkBarFlatButton
                    onClick={excludedIndexFoldersDialog.open}
                    icon={<ShareMinusIcon />}
                    id={`${idPrefix}_open_excluded_index_folders_dialog`}
                >
                    {i18next.t("slingshot.index_proxy.proxy_generation.action.exclude_index_folders")}
                </BulkBarFlatButton>
                <ExcludedExtensionsDialog
                    key="index-proxy-excluded-extensions"
                    open={excludedExtensionsDialog.isOpen}
                    onClose={excludedExtensionsDialog.close}
                    onCloseDrawer={onClose}
                    shareName={currentShareName}
                    submit={addExcludedExtension}
                    excludedFoldersList={indexingConfigStore.mediaProxy?.blackList?.get(currentShareName)}
                    parentId={idPrefix}
                />
                <ExcludedFoldersDialog
                    key="index-proxy-excluded-folders"
                    open={excludedFoldersDialog.isOpen}
                    onClose={excludedFoldersDialog.close}
                    onCloseDrawer={onClose}
                    shareName={currentShareName}
                    submit={handleAddExcludedFolder(indexingConfigStore.setExcludedFoldersList)}
                    excludedFoldersList={indexingConfigStore.mediaProxy?.blackList?.get(currentShareName)}
                    parentId={idPrefix}
                />
                <ExcludedFoldersDialog
                    key="index-proxy-excluded-index-folders"
                    open={excludedIndexFoldersDialog.isOpen}
                    onClose={excludedIndexFoldersDialog.close}
                    onCloseDrawer={onClose}
                    shareName={currentShareName}
                    title={i18next.t("slingshot.index_proxy.excluded_index_folders.title", { shareName: currentShareName })}
                    submit={handleAddExcludedFolder(indexingConfigStore.setExcludedIndexFoldersList)}
                    excludedFoldersList={indexingConfigStore.folderBlackList?.get(currentShareName)}
                    hintText={i18next.t("slingshot.index_proxy.excluded_index_folders.caution")}
                    parentId={idPrefix}
                />
            </Grid>
        );

    return null;
};

export default observer(RenderActions);
