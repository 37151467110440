import React, { useContext } from "react";
import { Context } from "store";
import { Grid } from "@material-ui/core";
import RemoteShare from "../RemoteShare";
import MultipleEvoTitle from "components/MultipleEvoTitle";
import { useObserver } from "mobx-react";

const RemoteSharesTabContent = () => {
    const { multipleEvosRootStore } = useContext(Context);
    return useObserver(() => (
        <>
            <Grid container item direction="column" spacing={6}>
                {multipleEvosRootStore.storesLength > 0 && (
                    <Grid item>
                        <MultipleEvoTitle />
                    </Grid>
                )}
                <Grid item>
                    <RemoteShare />
                </Grid>
            </Grid>
            {multipleEvosRootStore.storesIps.map(ip => (
                <Grid key={ip} container item direction="column" spacing={6}>
                    <Grid item>
                        <MultipleEvoTitle ip={ip} />
                    </Grid>
                    <Grid item>
                        <RemoteShare ip={ip} />
                    </Grid>
                </Grid>
            ))}
        </>
    ));
};

export default RemoteSharesTabContent;
