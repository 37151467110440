import Request from "api/Request";

const GetMixedShares = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Migration",
    method: "get_mixed_shares",
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default GetMixedShares;
