import { makeStyles } from "@material-ui/core/styles";
const CELL_MAX_WIDTH = 250;

export const useStyles = makeStyles(() => ({
    cellWithOverflow: {
        maxWidth: CELL_MAX_WIDTH,
        overflowX: "hidden",
        textOverflow: "ellipsis"
    }
}));
