import { useContext, useMemo } from "react";
import { Context } from "store";
import { ADMIN, SECONDARY_ADMIN, USER, AVAILABLE_SEC_ADMIN_URLS, AVAILABLE_USER_URLS } from "const/userRolesConst";
import { URL_FILE_NAVIGATOR, URL_HOME, URL_LOGIN, URL_SHARES } from "routes";

const useUrlUserPermission = path => {
    const {
        store: { authStore }
    } = useContext(Context);

    const hasPermission = useMemo(() => {
        switch (authStore.role) {
            case ADMIN:
                return true;
            case SECONDARY_ADMIN:
                return AVAILABLE_SEC_ADMIN_URLS.includes(path);
            case USER:
                return AVAILABLE_USER_URLS.includes(path);
            default:
                return false;
        }
    }, [path]);
    const urlToRedirect = useMemo(() => {
        switch (authStore.role) {
            case ADMIN:
                return URL_HOME;
            case SECONDARY_ADMIN:
                return URL_SHARES;
            case USER:
                return URL_FILE_NAVIGATOR;
            default:
                return URL_LOGIN;
        }
    }, [path]);

    return { hasPermission, urlToRedirect };
};

export default useUrlUserPermission;
