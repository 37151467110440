import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        outline: "none",
        "& input:-webkit-autofill:focus": {
            "-webkit-transition-delay": "9999999s",
            "-webkit-box-shadow": `inset 0 0 0 50px ${hexToRgba(theme.palette.grey["A100"], 1)}`,
        },
        "& input:-webkit-autofill:hover": {
            "-webkit-transition-delay": "9999999s",
            "-webkit-box-shadow": `inset 0 0 0 50px ${hexToRgba(theme.palette.grey["A100"], 1)}`,
        },
        "& input:-webkit-autofill": {
            "-webkit-transition-delay": "9999999s",
            "-webkit-box-shadow": `inset 0 0 0 50px ${hexToRgba(theme.palette.grey["A100"], 1)}`,
        },
        "& input::placeholder": {
            opacity: 0.4,
        },
        "& input:not([disabled]):hover::placeholder": {
            opacity: 0.8,
        },
        "& .MuiInputBase-root:not(.Mui-disabled)": {
            backgroundColor: theme.palette.grey["A100"],
        },
        "& .Mui-disabled": {
            backgroundColor: hexToRgba(theme.palette.grey[100], 0.5) ?? theme.palette.grey[100],
            color: theme.palette.grey[400],
            "&&:hover": {
                borderColor: "transparent",
            },
            "& .MuiTypography-root": {
                opacity: 0.4,
            },
        },
    },
    nonEditable: {
        "& .MuiOutlinedInput-input.MuiOutlinedInput-input": {
            opacity: 1,
            fontWeight: 500,
        },
    },
}));
