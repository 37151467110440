import { types } from "mobx-state-tree";

const ProgressEvent = types.model({
    id: types.number,
    orderId: types.number,
    time: types.string,
    eventLevel: types.string,
    eventType: types.string,
    message: types.string,
    taskId: types.number
});

export default ProgressEvent;
