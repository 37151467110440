import React from "react";

const PicTestIcon = props => {
    return (
        <svg width="80px" height="80px" viewBox="0 0 178 168" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
            <defs>
                <linearGradient x1="5.49309035%" y1="-21.2905156%" x2="55.5581299%" y2="64.6442701%" id="picTestIcon-1">
                    <stop stopColor="#00DCED" offset="0%" />
                    <stop stopColor="#7080F8" offset="100%" />
                </linearGradient>
                <linearGradient x1="19.581423%" y1="1.75935992%" x2="80.8956511%" y2="97.4392027%" id="picTestIcon-2">
                    <stop stopColor="#54CCFF" offset="0%" />
                    <stop stopColor="#997CFF" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="95.3640186%" y2="100%" id="picTestIcon-3">
                    <stop stopColor="#C5E8FF" stopOpacity="0.8" offset="0%" />
                    <stop stopColor="#8AB7FF" offset="100%" />
                </linearGradient>
                <linearGradient x1="70.7267598%" y1="-6.51732384%" x2="30.9019192%" y2="101.510123%" id="picTestIcon-4">
                    <stop stopColor="#EBF8FF" offset="0%" />
                    <stop stopColor="#C2DBFF" offset="100%" />
                </linearGradient>
                <linearGradient x1="62.5591402%" y1="91.6612981%" x2="0%" y2="-4.69865525%" id="picTestIcon-5">
                    <stop stopColor="#61BCFD" offset="0%" />
                    <stop stopColor="#4EA0FF" offset="100%" />
                </linearGradient>
                <linearGradient x1="24.1825545%" y1="7.86864683%" x2="77.0892952%" y2="93.5552242%" id="picTestIcon-6">
                    <stop stopColor="#9BD3FF" offset="0%" />
                    <stop stopColor="#87CAFF" offset="100%" />
                </linearGradient>
                <linearGradient x1="0%" y1="0%" x2="93.7746503%" y2="96.0451753%" id="picTestIcon-7">
                    <stop stopColor="#FFFFFF" stopOpacity="0.25" offset="0%" />
                    <stop stopColor="#FFFFFF" stopOpacity="0.03" offset="100%" />
                </linearGradient>
                <linearGradient x1="15.5220847%" y1="0%" x2="80.1851737%" y2="96.0451753%" id="picTestIcon-8">
                    <stop stopColor="#FFFFFF" stopOpacity="0.66" offset="0%" />
                    <stop stopColor="#FFFFFF" stopOpacity="0.03" offset="100%" />
                </linearGradient>
                <linearGradient x1="0%" y1="0%" x2="93.7746503%" y2="96.0451753%" id="picTestIcon-9">
                    <stop stopColor="#FFFFFF" stopOpacity="0.62" offset="0%" />
                    <stop stopColor="#FFFFFF" stopOpacity="0.03" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="picTestIcon-10">
                    <stop stopColor="#FFFFFF" stopOpacity="0.4" offset="0%" />
                    <stop stopColor="#FFFFFF" stopOpacity="0.4" offset="100%" />
                </linearGradient>
                <circle id="path-11" cx="6" cy="17" r="6" />
                <filter
                    x="-158.3%"
                    y="-141.7%"
                    width="416.7%"
                    height="416.7%"
                    filterUnits="objectBoundingBox"
                    id="picTestIconFilter-12"
                >
                    <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0.0143244754   0 0 0 0 0.486168492   0 0 0 0 0.945595562  0 0 0 0.47 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                    />
                </filter>
                <linearGradient x1="0%" y1="0%" x2="89.6261889%" y2="100%" id="picTestIcon-13">
                    <stop stopColor="#8DC6FF" offset="0%" />
                    <stop stopColor="#3D9FFF" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="picTestIcon-14">
                    <stop stopColor="#C0E2FF" offset="0%" />
                    <stop stopColor="#E0F4FF" offset="100%" />
                </linearGradient>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g>
                    <rect fill="url(#picTestIcon-1)" x="36" y="16" width="106" height="128" rx="3" />
                    <path
                        d="M139,16 C140.656854,16 142,17.3431458 142,19 L142,141 C142,142.656854 140.656854,144 139,144 L39,144 C37.3431458,144 36,142.656854 36,141 L36,19 C36,17.3431458 37.3431458,16 39,16 L139,16 Z M138,20 L40,20 L40,140 L138,140 L138,20 Z"
                        fill="url(#picTestIcon-2)"
                        fillRule="nonzero"
                    />
                    <rect fill="#9FA3FF" opacity="0.600000024" x="48" y="118" width="24" height="5" rx="2.5" />
                    <rect fill="#9FA3FF" opacity="0.600000024" x="76" y="118" width="48" height="5" rx="2.5" />
                    <rect fill="#9FA3FF" opacity="0.600000024" x="48" y="127" width="33" height="5" rx="2.5" />
                    <rect fill="#9FA3FF" opacity="0.600000024" x="85" y="127" width="33" height="5" rx="2.5" />
                    <g transform="translate(48.000000, 30.000000)">
                        <rect fill="url(#picTestIcon-3)" opacity="0.900000036" x="0" y="0" width="20" height="20" rx="4" />
                        <rect fill="#EFFAFF" opacity="0.400000006" x="28" y="1" width="54" height="8" rx="4" />
                        <rect fill="#8DDDFF" opacity="0.300000012" x="28" y="13" width="41" height="6" rx="3" />
                        <path
                            d="M13.5857864,5.58578644 C14.366835,4.80473785 15.633165,4.80473785 16.4142136,5.58578644 C17.1541543,6.3257272 17.1930986,7.50121136 16.5310463,8.28699879 L16.4142136,8.41421356 L9.41421356,15.4142136 C8.6742728,16.1541543 7.49878864,16.1930986 6.71300121,15.5310463 L6.58578644,15.4142136 L3.08578644,11.9142136 C2.30473785,11.133165 2.30473785,9.86683502 3.08578644,9.08578644 C3.8257272,8.34584567 5.00121136,8.30690142 5.78699879,8.96895369 L5.91421356,9.08578644 L8,11.171 L13.5857864,5.58578644 Z"
                            fill="url(#picTestIcon-4)"
                            fillRule="nonzero"
                        />
                    </g>
                    <g id="Group" transform="translate(48.000000, 58.000000)">
                        <rect fill="url(#picTestIcon-3)" opacity="0.900000036" x="0" y="0" width="20" height="20" rx="4" />
                        <path
                            d="M13.5857864,5.58578644 C14.366835,4.80473785 15.633165,4.80473785 16.4142136,5.58578644 C17.1541543,6.3257272 17.1930986,7.50121136 16.5310463,8.28699879 L16.4142136,8.41421356 L9.41421356,15.4142136 C8.6742728,16.1541543 7.49878864,16.1930986 6.71300121,15.5310463 L6.58578644,15.4142136 L3.08578644,11.9142136 C2.30473785,11.133165 2.30473785,9.86683502 3.08578644,9.08578644 C3.8257272,8.34584567 5.00121136,8.30690142 5.78699879,8.96895369 L5.91421356,9.08578644 L8,11.171 L13.5857864,5.58578644 Z"
                            fill="url(#picTestIcon-4)"
                            fillRule="nonzero"
                        />
                        <rect fill="#EFFAFF" opacity="0.400000006" x="28" y="1" width="30" height="8" rx="4" />
                        <rect fill="#8DDDFF" opacity="0.300000012" x="28" y="13" width="50" height="6" rx="3" />
                    </g>
                    <g id="Group" transform="translate(48.000000, 86.000000)">
                        <rect fill="url(#picTestIcon-3)" opacity="0.900000036" x="0" y="0" width="20" height="20" rx="4" />
                        <path
                            d="M13.5857864,5.58578644 C14.366835,4.80473785 15.633165,4.80473785 16.4142136,5.58578644 C17.1541543,6.3257272 17.1930986,7.50121136 16.5310463,8.28699879 L16.4142136,8.41421356 L9.41421356,15.4142136 C8.6742728,16.1541543 7.49878864,16.1930986 6.71300121,15.5310463 L6.58578644,15.4142136 L3.08578644,11.9142136 C2.30473785,11.133165 2.30473785,9.86683502 3.08578644,9.08578644 C3.8257272,8.34584567 5.00121136,8.30690142 5.78699879,8.96895369 L5.91421356,9.08578644 L8,11.171 L13.5857864,5.58578644 Z"
                            fill="url(#picTestIcon-4)"
                            fillRule="nonzero"
                        />
                        <rect fill="#EFFAFF" opacity="0.400000006" x="28" y="1" width="38" height="8" rx="4" />
                        <rect fill="#8DDDFF" opacity="0.300000012" x="28" y="13" width="41" height="6" rx="3" />
                    </g>
                    <g transform="translate(97.000000, 92.000000)">
                        <circle fill="url(#picTestIcon-5)" cx="32.5" cy="32.5" r="32.5" />
                        <path
                            d="M32.5,0 C50.4492544,0 65,14.5507456 65,32.5 C65,50.4492544 50.4492544,65 32.5,65 C14.5507456,65 0,50.4492544 0,32.5 C0,14.5507456 14.5507456,0 32.5,0 Z M32.5,4 C16.7598846,4 4,16.7598846 4,32.5 C4,48.2401154 16.7598846,61 32.5,61 C48.2401154,61 61,48.2401154 61,32.5 C61,16.7598846 48.2401154,4 32.5,4 Z"
                            fill="url(#picTestIcon-6)"
                            fillRule="nonzero"
                        />
                        <circle fill="url(#picTestIcon-7)" cx="12" cy="33" r="2" />
                        <circle fill="url(#picTestIcon-7)" cx="18" cy="18" r="2" />
                        <circle fill="url(#picTestIcon-7)" cx="48" cy="18" r="2" />
                        <circle fill="url(#picTestIcon-7)" cx="33" cy="11" r="2" />
                        <circle fill="url(#picTestIcon-7)" cx="54" cy="33" r="2" />
                        <g transform="translate(27.000000, 16.000000)">
                            <path
                                d="M1,13.5 L11,20.5 C16,8.16666667 18,1.66666667 17,1 C16,0.333333333 10.6666667,4.5 1,13.5 Z"
                                fill="url(#picTestIcon-8)"
                            />
                            <g>
                                <use fill="black" fillOpacity="1" filter="url(#picTestIconFilter-12)" xlinkHref="#path-11" />
                                <use fill="#1E90FF" fillRule="evenodd" xlinkHref="#path-11" />
                                <circle
                                    stroke="url(#picTestIcon-10)"
                                    strokeWidth="6"
                                    strokeLinejoin="square"
                                    fill="url(#picTestIcon-9)"
                                    fillRule="evenodd"
                                    cx="6"
                                    cy="17"
                                    r="3"
                                />
                            </g>
                            <circle fill="url(#picTestIcon-13)" cx="6" cy="17" r="4" />
                        </g>
                        <rect fill="#91C9FF" x="22" y="46" width="21" height="8" rx="2" />
                    </g>
                    <circle stroke="url(#picTestIcon-14)" strokeWidth="4" opacity="0.300000012" cx="14" cy="15" r="6" />
                    <path
                        d="M19,142 C20.1579474,142 21.1669124,145.352106 21.5668579,148.433526 C24.6478943,148.833088 28,149.842053 28,151 C28,152.138001 24.5465421,153.132108 21.5696655,153.54565 C21.1714919,156.63266 20.1605757,160 19,160 C17.8394243,160 16.8285081,156.63266 16.4304282,153.545496 C13.4534579,153.132108 10,152.138001 10,151 C10,149.842053 13.3521057,148.833088 16.4335256,148.433142 C16.8330876,145.352106 17.8420526,142 19,142 Z"
                        fill="#C0E2FF"
                        opacity="0.300000012"
                        transform="translate(19.000000, 151.000000) rotate(45.000000) translate(-19.000000, -151.000000) "
                    />
                    <path
                        d="M165,36 C165.634422,36 166.186856,37.8789159 166.403001,39.5970584 C168.121084,39.8131443 170,40.3655776 170,41 C170,41.6237995 168.062377,42.1683327 166.40306,42.3918618 C166.189229,44.1130019 165.635785,46 165,46 C164.364215,46 163.810771,44.1130019 163.595611,42.3918699 C161.937623,42.1683327 160,41.6237995 160,41 C160,40.3655776 161.878916,39.8131443 163.597058,39.596999 C163.813144,37.8789159 164.365578,36 165,36 Z"
                        fill="#C0E2FF"
                        opacity="0.300000012"
                        transform="translate(165.000000, 41.000000) rotate(45.000000) translate(-165.000000, -41.000000) "
                    />
                </g>
            </g>
        </svg>
    );
};

export default PicTestIcon;
