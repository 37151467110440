import React from "react";
import { useStore } from "hooks/useStore";
import createFields from "./createFields";
import createForm from "utils/createForm";
import Dialog from "components/Dialog";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Button,
    Typography,
    ButtonBase,
    Box,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Divider,
} from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import Select from "components/MobxForm/Select";
import { default as MuiSelect } from "components/Select";
import Switch from "components/MobxForm/Switch";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import {
    EDIT_VOLUME_MODES,
    VOLUME_TYPES_ARRAY,
    VOLUME_REPLICA_ARRAY,
    VOLUME_DISPERSE_ARRAY,
    VOLUME_REDUNDANCY_ARRAY,
    VOLUME_TYPE_OPTIONS,
} from "const/clusterConst";
import TrashIcon from "assets/TrashIcon";
import { useStyles } from "./EditVolumeBricksDialog.styles";
import WarningDialog from "./components/WarningDialog";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import PlusIcon from "assets/PlusIcon";
import clsx from "clsx";
import EditVolumeBricksDialogError from "./components/EditVolumeBricksDialogError";
import { getFilteredBricks } from "./EditVolumeBrickDialog.utils";
import { useModalCombine } from "hooks/useModalCombine";

const EditVolumeBricksDialog = ({ mode, open, onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { clusterStore },
    } = useStore();
    const { warningDialogModal, unsavedChangesModal } = useModalCombine(["warningDialogModal", "unsavedChangesModal"]);
    const checkMode = {
        isAdd: mode === EDIT_VOLUME_MODES.add,
        isRemove: mode === EDIT_VOLUME_MODES.remove,
        isReplace: mode === EDIT_VOLUME_MODES.replace,
    };
    const fields = createFields();
    const state = useLocalStore(() => ({
        form: createForm({
            fields,
        }),
        setupManually: false,
        isAddBrickInProgress: false,
        freeBricks: [],
        volatileBricks: [],
        defaultBricks: [],
        subvolumesArray: [],
        volatileBricksSnapshot: [],
        freeBricksSnapshot: [],
        replaceBrickIndex: null,
        changedReplicaMode: false,
        validationError: false,
        inProgress: false,
        isSubmitted: false,
        get freePeers() {
            const filteredBricks = getFilteredBricks(clusterStore);
            return (
                filteredBricks
                    .filter((el, index, arr) => arr.findIndex((e) => e.peer === el.peer) === index)
                    ?.map((brick) => {
                        return { value: brick.peer, label: brick.peerAlias };
                    }) || []
            );
        },
        get redundancy() {
            const maxRedundancyLength = (this.form.$("disperse").value - 1) / 2;
            return VOLUME_REDUNDANCY_ARRAY.slice(0, maxRedundancyLength);
        },
        get multiplicity() {
            switch (state.form.$("type").value) {
                case VOLUME_TYPE_OPTIONS.simple:
                    return 1;
                case VOLUME_TYPE_OPTIONS.mirror:
                    return state.form.$("replica").value;
                case VOLUME_TYPE_OPTIONS.grid:
                    return state.form.$("disperse").value;
                default:
                    return null;
            }
        },
    }));

    const onCheckValidation = () => {
        const aggregateBricks = [...state.defaultBricks, ...state.volatileBricks];
        if (
            (state.form.$("type").value === VOLUME_TYPE_OPTIONS.simple && !state.aggregateBricks.length) ||
            (state.form.$("type").value !== VOLUME_TYPE_OPTIONS.simple && aggregateBricks.length % state.multiplicity !== 0)
        ) {
            state.validationError = true;
            return false;
        }
        return true;
    };

    const onSubmitHandler = (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        if (!onCheckValidation()) return;
        return onSubmit();
    };

    const onAddBricksSubmit = (e) => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        if (!onCheckValidation()) return;
        if (state.form.$("type").value === VOLUME_TYPE_OPTIONS.mirror) {
            warningDialogModal.open();
            return;
        }
        return onSubmit();
    };

    const onSubmit = async () => {
        let bricks = [...state.defaultBricks, ...state.volatileBricks];
        let mappedBricks = bricks.map((brick) => {
            return { peer: brick.peer, path: brick.path, name: brick.name };
        });
        let requestData = {
            volume: {
                name: state.form.$("name").value,
                type: state.form.$("type").value,
                status: clusterStore.currentVolume ? clusterStore.currentVolume.status : "",
                transport: clusterStore.currentVolume ? clusterStore.currentVolume.transport : "tcp",
                replica: state.form.$("replica").value || 1,
                arbiter: state.form.$("arbiter").value ? 1 : 0,
                disperse: state.form.$("disperse").value || 3,
                redundancy: state.form.$("redundancy").value || 1,
            },
        };

        if (checkMode.isReplace) {
            const replasingBrick = state.volatileBricksSnapshot[state.replaceBrickIndex];
            const replacedBrick = state.volatileBricks[state.replaceBrickIndex];
            requestData = {
                volume: state.form.$("name").value,
                fromBrick: { peer: replasingBrick?.peer, path: replasingBrick?.path, name: replasingBrick?.name },
                toBrick: { peer: replacedBrick?.peer, path: replacedBrick?.path, name: replacedBrick?.name },
            };
        } else {
            requestData = { ...requestData, bricks: mappedBricks };
        }

        state.inProgress = true;

        let isSubmitted = false;

        switch (mode) {
            case EDIT_VOLUME_MODES.add:
                isSubmitted = await clusterStore.addVolumeBricks({ ...requestData });
                break;
            case EDIT_VOLUME_MODES.remove:
                isSubmitted = await clusterStore.removeVolumeBricks(requestData);
                break;
            case EDIT_VOLUME_MODES.replace:
                isSubmitted = await clusterStore.replaceVolumeBricks(requestData);
                break;
            default:
                break;
        }

        state.inProgress = false;

        if (isSubmitted) {
            state.isSubmitted = true;
            return isSubmitted;
        }
    };

    const onDialogOpen = () => {
        state.isSubmitted = false;
        state.freeBricks = [];
        state.defaultBricks = [];
        state.volatileBricks = [];
        state.validationError = false;
        state.freeBricks = [...clusterStore.freeBricks];
        if (!checkMode.isReplace) {
            state.defaultBricks = [...clusterStore.volumeBricks];
        } else {
            state.volatileBricks = [...clusterStore.volumeBricks];
            state.freeBricksSnapshot = [...clusterStore.freeBricks];
            state.volatileBricksSnapshot = [];
            state.replaceBrickIndex = -1;
        }
        state.form.$("name").set("default", clusterStore.currentVolume.name);
        state.form
            .$("type")
            .set("default", clusterStore.currentVolume.type.split("_")[1] || clusterStore.currentVolume.type.split("_")[0]);
        const subvolumesArray = new Set(clusterStore.volumeBricks.map((brick) => brick.subvolumeNumber));
        state.subvolumesArray = [...subvolumesArray];
        state.form.$("disperse").set("default", clusterStore.currentVolume.disperse);
        state.form.$("redundancy").set("default", clusterStore.currentVolume.redundancy);
        clusterStore.currentVolume.replica > 1 && state.form.$("replica").set("default", clusterStore.currentVolume.replica);
        state.form.$("arbiter").set("default", clusterStore.currentVolume.arbiter === 0 ? false : true);
        state.form.$("setupManually").set("default", clusterStore.currentVolume.disperse > 3 ? true : false);
        state.form.reset();
        state.form.$("name").resetValidation();
    };

    const onSetupManuallyChange = (e) => {
        state.form.$("setupManually").set("value", e.target.checked);
        !state.form.$("disperse").value && state.form.$("disperse").set("value", 3);
        !state.form.$("redundancy").value && state.form.$("redundancy").set("value", 1);
        state.validationError = false;
    };

    const onTypeChange = (e) => {
        state.form.$("type").set("value", e.target.value);
        !state.form.$("replica").value && state.form.$("replica").set("value", 2);
        state.validationError = false;
    };

    const onReplicaChange = (e) => {
        const prevValue = state.form.$("replica").value;
        state.form.$("replica").set("value", e.target.value);
        if (checkMode.isAdd) {
            state.changedReplicaMode = state.form.$("replica").value > prevValue ? true : false;
        }
    };

    const addBrick = () => {
        state.validationError = false;
        if (state.freeBricks.length === 0) return;
        if (!state.changedReplicaMode) {
            state.volatileBricks.push({ ...state.freeBricks[0], subvolumeNumber: state.volatileBricks.length });
        } else {
            const aggregateBricks = [...state.defaultBricks, ...state.volatileBricks];
            if (aggregateBricks.length === state.subvolumesArray.length * state.multiplicity) {
                state.subvolumesArray.push(state.subvolumesArray.length);
            }
            for (let i = 0; i <= state.subvolumesArray.length; i++) {
                if (aggregateBricks.filter((brick) => brick.subvolumeNumber === i)?.length !== state.multiplicity) {
                    state.volatileBricks.push({ ...state.freeBricks[0], subvolumeNumber: i });
                    break;
                }
            }
        }
        state.freeBricks.splice(0, 1);
    };

    const removeBrick = (brickId) => {
        const findIndex = state.volatileBricks.findIndex((brick) => brick.id === brickId);
        state.freeBricks.unshift(state.volatileBricks[findIndex]);
        state.volatileBricks.splice(findIndex, 1);
    };

    const removeDefaultBrick = (brickId) => {
        const findIndex = state.defaultBricks.findIndex((brick) => brick.id === brickId);
        state.defaultBricks.splice(findIndex, 1);
    };

    const getPath = () => (brickPeer) => {
        const filteredBricks = getFilteredBricks(clusterStore);
        return (
            filteredBricks
                .filter((brick) => brick.peer === brickPeer)
                ?.filter((el, index, arr) => arr.findIndex((e) => e.path === el.path) === index)
                ?.map((brick) => brick.path) || []
        );
    };

    const getBrick = () => (brickPeer, brickPath) => {
        const filteredBricks = getFilteredBricks(clusterStore);
        return (
            filteredBricks
                .filter((brick) => brick.peer === brickPeer && brick.path === brickPath)
                ?.filter((el, index, arr) => arr.findIndex((e) => e.name === el.name) === index)
                ?.map((brick) => brick.name) || []
        );
    };

    const setDefaultBricksSnapshot = () => {
        state.volatileBricksSnapshot = [...state.volatileBricks];
    };

    const applyDefaultBricksSnapshot = () => {
        state.volatileBricks = [...state.volatileBricksSnapshot];
        state.freeBricks = [...state.freeBricksSnapshot];
    };

    const checkIfReplaceMode = (index) => {
        if (checkMode.isReplace && state.replaceBrickIndex < 0) {
            setDefaultBricksSnapshot();
            state.replaceBrickIndex = index;
        }

        if (checkMode.isReplace && state.replaceBrickIndex !== index) {
            applyDefaultBricksSnapshot();
            state.replaceBrickIndex = index;
        }
    };

    const changeVolatilePeer = (e, index) => {
        const newPeer = e.target.value;
        if (newPeer !== state.volatileBricks[index].peer) {
            checkIfReplaceMode(index);
            state.freeBricks.unshift(state.volatileBricks[index]);
            let newBrickIndex = state.freeBricks.findIndex((brick) => brick.peer === e.target.value);
            if (newBrickIndex >= 0) {
                state.volatileBricks[index] = state.freeBricks[newBrickIndex];
                state.freeBricks.splice(newBrickIndex, 1);
            } else {
                newBrickIndex = state.volatileBricks.findIndex((brick) => brick.peer === newPeer);
                state.volatileBricks[index] = state.volatileBricks[newBrickIndex];
                state.volatileBricks[newBrickIndex] = state.freeBricks[0];
                state.freeBricks.splice(0, 1);
            }
        }
    };

    const changeVolatilePath = (e, index) => {
        const newPath = e.target.value;
        if (newPath !== state.volatileBricks[index].path) {
            checkIfReplaceMode(index);
            const currentPeer = state.volatileBricks[index].peer;
            state.freeBricks.unshift(state.volatileBricks[index]);
            let newBrickIndex = state.freeBricks.findIndex((brick) => brick.peer === currentPeer && brick.path === newPath);
            if (newBrickIndex >= 0) {
                state.volatileBricks[index] = state.freeBricks[newBrickIndex];
                state.freeBricks.splice(newBrickIndex, 1);
            } else {
                newBrickIndex = state.volatileBricks.findIndex((brick) => brick.peer === currentPeer && brick.path === newPath);
                state.volatileBricks[index] = state.volatileBricks[newBrickIndex];
                state.volatileBricks[newBrickIndex] = state.freeBricks[0];
                state.freeBricks.splice(0, 1);
            }
        }
    };

    const changeVolatileBrick = (e, index) => {
        const newBrick = e.target.value;
        if (newBrick !== state.volatileBricks[index].name) {
            checkIfReplaceMode(index);
            const currentPeer = state.volatileBricks[index].peer;
            const currentPath = state.volatileBricks[index].path;
            state.freeBricks.unshift(state.volatileBricks[index]);
            let newBrickIndex = state.freeBricks.findIndex(
                (brick) => brick.peer === currentPeer && brick.path === currentPath && brick.name === newBrick
            );
            if (newBrickIndex >= 0) {
                state.volatileBricks[index] = state.freeBricks[newBrickIndex];
                state.freeBricks.splice(newBrickIndex, 1);
            } else {
                newBrickIndex = state.volatileBricks.findIndex(
                    (brick) => brick.peer === currentPeer && brick.path === currentPath && brick.name === newBrick
                );
                state.volatileBricks[index] = state.volatileBricks[newBrickIndex];
                state.volatileBricks[newBrickIndex] = state.freeBricks[0];
                state.freeBricks.splice(0, 1);
            }
        }
    };

    const renderTitle = {
        [EDIT_VOLUME_MODES.add]: t("cluster.volume.add_bricks"),
        [EDIT_VOLUME_MODES.remove]: t("cluster.volume.remove_bricks.title"),
        [EDIT_VOLUME_MODES.replace]: t("cluster.volume.replace_bricks.title"),
    };

    const closeIfNeeded = () => {
        if (
            state.form.isDefault ||
            state.isSubmitted ||
            (checkMode.isAdd && !state.volatileBricks?.length) ||
            (checkMode.isRemove && state.defaultBricks?.length !== clusterStore.volumeBricks?.length) ||
            (checkMode.isReplace && JSON.stringify(state.volatileBricks) === JSON.stringify(clusterStore.volumeBricks))
        ) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            maxWidth="lg"
            onEnter={onDialogOpen}
            withoutPaddings
            title={renderTitle[mode] ?? ""}
            open={open}
            onSubmit={!checkMode.isAdd ? onSubmitHandler : onAddBricksSubmit}
            inProgress={state.inProgress}
            onClose={closeIfNeeded}
            disableSubmit={
                !checkMode.isRemove
                    ? !state.volatileBricks.length
                    : state.defaultBricks?.length === clusterStore.volumeBricks?.length
            }
            submitBtnLabel={checkMode.isAdd ? t("common.button.create") : t("common.button.save")}
            buttons={[
                <Button key={"cancel_button"} onClick={closeIfNeeded} variant={"contained"} color={"secondary"}>
                    {t("common.button.cancel")}
                </Button>,
            ]}
        >
            <>
                <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
                <WarningDialog onConfirm={onSubmit} onClose={warningDialogModal.close} open={warningDialogModal.isOpen} />
                <Box px={6} py={4} width={"100%"}>
                    <Grid container spacing={4}>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography>{t("cluster.volume.name")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextInput disabled field={state.form.$("name")} inputProps={{ spellCheck: "false" }} />
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography>{t("cluster.volume.type")}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    disabled
                                    onChange={onTypeChange}
                                    field={state.form.$("type")}
                                    options={VOLUME_TYPES_ARRAY}
                                />
                            </Grid>
                        </Grid>
                        {state.form.$("type").value === VOLUME_TYPE_OPTIONS.mirror && (
                            <React.Fragment>
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("cluster.volume.replica")}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            disabled={checkMode.isReplace}
                                            field={state.form.$("replica")}
                                            options={VOLUME_REPLICA_ARRAY}
                                            onChange={onReplicaChange}
                                        />
                                    </Grid>
                                </Grid>
                                {state.form.$("replica").value === +VOLUME_REPLICA_ARRAY[1] && (
                                    <Grid item container alignItems={"center"}>
                                        <RowControlLabelSwitch
                                            control={<Switch disabled={mode === "replace"} field={state.form.$("arbiter")} />}
                                            label={t("cluster.volume.arbiter")}
                                            lastColumnWidth={"33.3%"}
                                        />
                                    </Grid>
                                )}
                            </React.Fragment>
                        )}
                        {state.form.$("type").value === VOLUME_TYPE_OPTIONS.grid && (
                            <Grid item container alignItems={"center"}>
                                <RowControlLabelSwitch
                                    control={
                                        <Switch disabled onChange={onSetupManuallyChange} field={state.form.$("setupManually")} />
                                    }
                                    label={t("cluster.volume.setup_manually")}
                                    lastColumnWidth={"33.3%"}
                                />
                            </Grid>
                        )}
                        {!!state.form.$("setupManually").value && (
                            <React.Fragment>
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("cluster.volume.disperse")}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select disabled field={state.form.$("disperse")} options={VOLUME_DISPERSE_ARRAY} />
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs={4}>
                                        <Typography>{t("cluster.volume.redundant_bricks")}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select disabled field={state.form.$("redundancy")} options={state.redundancy} />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                        <Grid item container>
                            <Box width={"100%"} pt={4}>
                                <Typography variant={"subtitle1"}>{t("cluster.volume.button.bricks")}</Typography>
                                {state.form.$("type").value === VOLUME_TYPE_OPTIONS.simple ||
                                (state.form.$("type").value === VOLUME_TYPE_OPTIONS.grid &&
                                    !state.form.$("setupManually").value) ? (
                                    <Typography>
                                        {t("cluster.volume.helper_text.simple", {
                                            bricksNumber: state.form.$("type").value === VOLUME_TYPES_ARRAY[0].value ? "1" : "3",
                                        })}
                                    </Typography>
                                ) : (
                                    <Typography>
                                        {t("cluster.volume.helper_text.mirror", {
                                            bricksNumber:
                                                state.form.$("type").value === VOLUME_TYPE_OPTIONS.mirror
                                                    ? state.form.$("replica").value
                                                    : state.form.$("disperse").value,
                                        })}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Grid item container>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant={"subtitle1"}>{t("cluster.volumes.brick.header.host_name")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle1"}>{t("cluster.volumes.brick.header.path")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle1"}>{t("cluster.local_bricks.header.brick_name")}</Typography>
                                </TableCell>
                                <TableCell align={"center"}>
                                    {!checkMode.isReplace && (
                                        <Typography variant={"subtitle1"}>
                                            {t("cluster.volumes.brick.header.subvolume_number")}
                                        </Typography>
                                    )}
                                </TableCell>
                                <TableCell width={"10%"} align="right">
                                    <ButtonBase disabled={!checkMode.isAdd || !state.freeBricks.length} onClick={addBrick}>
                                        <PlusIcon
                                            className={clsx((!checkMode.isAdd || !state.freeBricks.length) && classes.disabled)}
                                        />
                                    </ButtonBase>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!state.changedReplicaMode && (
                                <React.Fragment>
                                    {state.defaultBricks?.map((brick, index) => (
                                        // TODO  Do not use Array index in keys
                                        // eslint-disable-next-line react/no-array-index-key
                                        <TableRow key={`DefaultBrick-${index}`}>
                                            <TableCell>
                                                <Grid container>
                                                    <Grid item>
                                                        <MuiSelect disabled options={[brick.peerAlias]} value={brick.peerAlias} />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid container>
                                                    <Grid item>
                                                        <MuiSelect disabled options={[brick.path]} value={brick.path} />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid container>
                                                    <Grid item>
                                                        <MuiSelect disabled options={[brick.name]} value={brick.name} />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {!checkMode.isReplace && (
                                                    <Typography>{Math.floor(index / state.multiplicity)}</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {checkMode.isRemove && (
                                                    <ButtonBase onClick={() => removeDefaultBrick(brick.id)}>
                                                        <TrashIcon className={classes.errorColor} />
                                                    </ButtonBase>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {state.volatileBricks.map((brick, index) => (
                                        <TableRow key={`VolatileBrick-${brick.peer}-${brick.path}-${brick.name}}`}>
                                            <TableCell>
                                                <Grid container>
                                                    <Grid item>
                                                        <MuiSelect
                                                            options={state.freePeers}
                                                            value={brick.peer}
                                                            onChange={(e) => changeVolatilePeer(e, index)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid container>
                                                    <Grid item>
                                                        <MuiSelect
                                                            options={getPath()(brick.peer)}
                                                            value={brick.path}
                                                            onChange={(e) => changeVolatilePath(e, index)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid container>
                                                    <Grid item>
                                                        <MuiSelect
                                                            options={getBrick()(brick.peer, brick.path)}
                                                            value={brick.name}
                                                            onChange={(e) => changeVolatileBrick(e, index)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {!checkMode.isReplace && (
                                                    <Typography>
                                                        {Math.floor((index + state.defaultBricks.length) / state.multiplicity)}
                                                    </Typography>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {!checkMode.isReplace && (
                                                    <ButtonBase onClick={() => removeBrick(brick.id)}>
                                                        <TrashIcon className={classes.errorColor} />
                                                    </ButtonBase>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </React.Fragment>
                            )}
                            {checkMode.isAdd && state.changedReplicaMode && (
                                <React.Fragment>
                                    {state.subvolumesArray?.map((subvolumeNumber) => (
                                        <React.Fragment key={subvolumeNumber}>
                                            {state.defaultBricks?.map((brick, index) => (
                                                // TODO  Do not use Array index in keys
                                                // eslint-disable-next-line react/no-array-index-key
                                                <React.Fragment key={`SubDefaultBrick-${index}`}>
                                                    {brick.subvolumeNumber === subvolumeNumber && (
                                                        <TableRow>
                                                            <TableCell>
                                                                <Grid container>
                                                                    <Grid item>
                                                                        <MuiSelect
                                                                            disabled
                                                                            options={[brick.peerAlias]}
                                                                            value={brick.peerAlias}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Grid container>
                                                                    <Grid item>
                                                                        <MuiSelect
                                                                            disabled
                                                                            options={[brick.path]}
                                                                            value={brick.path}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Grid container>
                                                                    <Grid item>
                                                                        <MuiSelect
                                                                            disabled
                                                                            options={[brick.name]}
                                                                            value={brick.name}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell align={"center"}>
                                                                <Typography>{brick.subvolumeNumber}</Typography>
                                                            </TableCell>
                                                            <TableCell> </TableCell>
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                            {state.volatileBricks?.map((brick, index) => (
                                                // TODO  Do not use Array index in keys
                                                // eslint-disable-next-line react/no-array-index-key
                                                <React.Fragment key={`SubVolatileBrick-${index}`}>
                                                    {brick.subvolumeNumber === subvolumeNumber && (
                                                        <TableRow>
                                                            <TableCell>
                                                                <Grid container>
                                                                    <Grid item>
                                                                        <MuiSelect
                                                                            options={state.freePeers}
                                                                            value={brick.peer}
                                                                            onChange={(e) => changeVolatilePeer(e, index)}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Grid container>
                                                                    <Grid item>
                                                                        <MuiSelect
                                                                            options={getPath()(brick.peer)}
                                                                            value={brick.path}
                                                                            onChange={(e) => changeVolatilePath(e, index)}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Grid container>
                                                                    <Grid item>
                                                                        <MuiSelect
                                                                            options={getBrick()(brick.peer, brick.path)}
                                                                            value={brick.name}
                                                                            onChange={(e) => changeVolatileBrick(e, index)}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell align={"center"}>
                                                                <Typography>{brick.subvolumeNumber}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <ButtonBase onClick={() => removeDefaultBrick(brick.id)}>
                                                                    <TrashIcon className={classes.errorColor} />
                                                                </ButtonBase>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
                <Box px={6} py={4} width={"100%"}>
                    <EditVolumeBricksDialogError state={state} />
                </Box>
            </>
        </Dialog>
    ));
};

export default EditVolumeBricksDialog;
