import { types } from "mobx-state-tree";

import Pool from "api/pool/Types/Pool";

const GetPoolsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(Pool)
});

export default GetPoolsResult;
