import { types } from "mobx-state-tree";

import ProgressEvent from "api/event/Types/ProgressEvent";

const ProgressEventsPage = types.model({
    data: types.array(ProgressEvent),
    total: types.number
});

export default ProgressEventsPage;
