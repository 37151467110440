import isFieldEmpty from "utils/isFieldEmptyRule";
import i18n from "i18n";
import isFieldHasOnlySpaces from "utils/isFieldHasOnlySpacesRule";

const createAdFields = () => {
    return [
        {
            name: "enabled",
            type: "checkbox",
        },
        {
            name: "host",
        },
        {
            name: "baseDN",
        },
        {
            name: "encryption",
            default: "none",
        },
        {
            name: "sambaMode",
            default: "samba_schema",
        },
        {
            name: "sambaSID",
        },
        {
            name: "bindDN",
        },
        {
            name: "bindPassword",
            type: "password",
        },
        {
            name: "resetAcl",
            type: "checkbox",
        },
        {
            name: "jumpcloudGroupsEnabled",
            type: "checkbox",
        },
        {
            name: "userSuffix",
            default: "",
            validators: [isFieldEmpty(i18n.t("common.label.field")), isFieldHasOnlySpaces(i18n.t("rule.field_has_only_spaces"))],
        },
        {
            name: "groupSuffix",
            default: "",
            validators: [isFieldEmpty(i18n.t("common.label.field")), isFieldHasOnlySpaces(i18n.t("rule.field_has_only_spaces"))],
        },
    ];
};

export default createAdFields;
