import { types, flow, getParent } from "mobx-state-tree";
import PoolsType from "api/pool/Responses/GetPoolsResult";
import DriveType from "api/pool/Responses/GetExpandingDrivesResult";
import VolumeType from "api/pool/Responses/GetExpandableVolumesResult";
import GetFreeDrivesType from "api/pool/Responses/GetFreeDrivesResult";
import MainSocket from "websocket";
import GetPools from "api/pool/Requests/GetPools";
import GetFreeDrives from "api/pool/Requests/GetFreeDrives";
import RemovePool from "api/pool/Requests/RemovePool";
import AddPool from "api/pool/Requests/AddPool";
import GetMaxVolumeSize from "api/pool/Requests/GetMaxVolumeSize";
import GetExpandingDrives from "api/pool/Requests/GetExpandingDrives";
import GetExpandableVolumes from "api/pool/Requests/GetExpandableVolumes";
import ExpandPool from "api/pool/Requests/ExpandPool";
import { ASC, DESC, FREE_DRIVES_NAME, DRIVE_NAME, GROWABLE_DISK_NAME } from "const/sortColumnConst";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import GetPool from "api/pool/Requests/GetPool";
const PoolsStore = types
    .model({
        poolsStore: types.maybe(PoolsType),
        freeDrivesStore: types.maybe(GetFreeDrivesType),
        currentPoolName: types.maybeNull(types.string),
        availableDisks: types.maybe(DriveType),
        growableDisks: types.maybe(VolumeType),
        checkedAvailableDisks: types.optional(types.array(types.string), []),
    })
    .volatile(() => ({
        orderByFreeDrives: FREE_DRIVES_NAME,
        orderFreeDrives: ASC,
        orderByDrives: DRIVE_NAME,
        orderDrives: ASC,
        orderByGrowableDrives: GROWABLE_DISK_NAME,
        orderGrowableDrives: ASC,
    }))
    .views((self) => ({
        get pools() {
            return (self.poolsStore && self.poolsStore.data) || [];
        },
        get sortedPools() {
            return stableSort(self.pools, getComparator(ASC, "pool"));
        },
        get freeDrives() {
            return (self.freeDrivesStore && self.freeDrivesStore.data) || [];
        },
        get sortedPoolsNames() {
            return self.sortedPools && self.sortedPools.map((pool) => pool.pool);
        },
        get sortedDrives() {
            return stableSort(self.freeDrives, getComparator(self.orderFreeDrives, self.orderByFreeDrives));
        },
        get currentPool() {
            return (self.currentPoolName && self.pools.find((pool) => pool.pool === self.currentPoolName)) || null;
        },
        get availableDrives() {
            return self.availableDisks?.data || [];
        },
        get sortedAvailableDrives() {
            return stableSort(self.availableDrives, getComparator(self.orderDrives, self.orderByDrives));
        },
        get growableVolumes() {
            const extendedGrowableVolumes = self.growableDisks?.data.map((item) => ({
                defaultSize: item.sizes[0],
                growableSize: item.sizes[self.checkedAvailableDisks.length],
                ...item,
            }));
            return extendedGrowableVolumes || [];
        },
        get sortedGrowableVolumes() {
            return stableSort(self.growableVolumes, getComparator(self.orderGrowableDrives, self.orderByGrowableDrives));
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
    }))
    .actions((self) => ({
        fetchPools: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetPools.create().init();
                const res = yield self.socket.send(req);
                self.poolsStore = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchPoolsByIds: flow(function* (ids) {
            const { processingStore } = getParent(self);
            const newPools = new Map();
            try {
                processingStore.setLoading(true);
                for (const pool of ids) {
                    const req = GetPool.create().init(pool);
                    const res = yield self.socket.send(req);
                    newPools.set(pool.pool, res.data);
                }
                self.poolStore &&
                    (self.poolsStore.data = self.poolsStore.data.slice().map((pool) => {
                        return newPools.has(pool.pool) ? newPools.get(pool.pool) : pool;
                    }));
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchExpandingDrives: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetExpandingDrives.create().init(data);
                const res = yield self.socket.send(req);
                self.availableDisks = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchExpandableVolumes: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetExpandableVolumes.create().init(data);
                const res = yield self.socket.send(req);
                self.growableDisks = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchFreeDrives: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetFreeDrives.create().init();
                const res = yield self.socket.send(req);
                self.freeDrivesStore = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        addPool: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = AddPool.create().init(data);
                yield self.socket.send(req);
                self.fetchPools();
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
            return data;
        }),
        sendRemovePoolRequest: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RemovePool.create().init(data);
                const res = yield self.socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getMaxVolumeSize: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetMaxVolumeSize.create().init(data);
                const res = yield self.socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        expandPool: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ExpandPool.create().init(data);
                const res = yield self.socket.send(req);
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        removePool(data) {
            const pool = self.pools.find((pool) => pool.pool === data.pool);
            self.pools.remove(pool);
        },
        setCurrentPoolName: (poolName) => {
            self.currentPoolName = poolName || null;
        },
        changeSortingFreeDrives(column) {
            if (column === self.orderByFreeDrives) {
                self.orderFreeDrives = self.orderFreeDrives === DESC ? ASC : DESC;
            } else {
                self.orderFreeDrives = ASC;
                self.orderByFreeDrives = column;
            }
        },
        changeSortingDrives(column) {
            if (column === self.orderByDrives) {
                self.orderDrives = self.orderDrives === DESC ? ASC : DESC;
            } else {
                self.orderDrives = ASC;
                self.orderByDrives = column;
            }
        },
        changeSortingGrowableDrives(column) {
            if (column === self.orderByGrowableDrives) {
                self.orderGrowableDrives = self.orderGrowableDrives === DESC ? ASC : DESC;
            } else {
                self.orderGrowableDrives = ASC;
                self.orderByGrowableDrives = column;
            }
        },
        addCheckedAvailableDrive: (driveName) => {
            const foundAvailableDrive = self.checkedAvailableDisks.some((pool) => pool === driveName);
            !foundAvailableDrive && self.checkedAvailableDisks.push(driveName);
        },
        removeCheckedAvailableDrive: (driveName) => {
            const foundAvailableDrive = self.checkedAvailableDisks.some((pool) => pool === driveName);
            foundAvailableDrive && self.checkedAvailableDisks.remove(driveName);
        },
        clearCheckedAvailableDrives: () => {
            self.checkedAvailableDisks = [];
        },
        updatePoolInStore: (pool) => {
            if (self.poolsStore?.data) {
                const index = self.poolsStore.data.findIndex((p) => p.pool === pool.pool);
                if (index < 0) {
                    self.poolsStore.data.push(pool);
                } else {
                    self.poolsStore.data[index] = pool;
                }
            }
        },
        removePoolFromStore: (pool) => {
            if (self.poolsStore && self.poolsStore?.data?.length !== 0) {
                self.poolsStore.data = self.poolsStore.data.filter((p) => p.pool !== pool.pool);
            }
        },
    }));

export default PoolsStore;
