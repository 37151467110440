import { types } from "mobx-state-tree";

import Request from "api/Request";
import VolumeIdsArguments from "api/volume/Types/VolumeIdsArguments";

const DequeueVolumes = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Volume",
    method: "dequeue_volumes",
    arguments: types.maybe(VolumeIdsArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default DequeueVolumes;
