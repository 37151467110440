import React, { useEffect, useContext } from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { isAlive } from "mobx-state-tree";
import { SizeMe } from "react-sizeme";

import { Context } from "store";
import { DEFAULT_START_PERIOD, DEFAULT_ANIM_DURATION } from "const/chartConst";
import CPUDetailedGraph from "./CPUDetailedGraph";

const CPUDetailedWidget = ({ parentId, height = 150, animDuration = DEFAULT_ANIM_DURATION, isDark }) => {
    const {
        store: { cpuGraphStore, uiStore }
    } = useContext(Context);
    const state = useLocalStore(() => ({
        key: ""
    }));

    useEffect(() => {
        cpuGraphStore.registerGraph();
        cpuGraphStore.fetchData();
        return () => {
            if (!isAlive(cpuGraphStore)) return;
            cpuGraphStore.unregisterGraph();
            cpuGraphStore.stopFetching();
        };
    }, []);

    return useObserver(() => {
        if (cpuGraphStore.detailedChartInfo.length === 0) return null;
        const graphStartPeriod = uiStore.parameters?.graphStartPeriod || DEFAULT_START_PERIOD;
        const graphAutoscaling = uiStore.parameters?.graphAutoscaling;

        return (
            <SizeMe refreshRate={500}>
                {({ size }) => {
                    if (size.width) state.key = `${parentId}-${size.width}-${graphStartPeriod}-${graphAutoscaling}`;
                    return (
                        <CPUDetailedGraph
                            {...{
                                key: state.key,
                                parentId,
                                height,
                                animDuration,
                                isDark,
                                chartInfo: cpuGraphStore.detailedChartInfo,
                                columns: cpuGraphStore.columns,
                                startFetching: cpuGraphStore.fetchData,
                                stopFetching: cpuGraphStore.stopFetching,
                                lastTime: cpuGraphStore.detailedChartLastTime,
                                lastValues: cpuGraphStore.detailedChartLastValues,
                                graphStartPeriod,
                                graphAutoscaling
                            }}
                        />
                    );
                }}
            </SizeMe>
        );
    });
};

export default CPUDetailedWidget;
