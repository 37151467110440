import { t } from "i18next";
import minSizeValidator from "utils/minSizeValidator";
import regexValidator from "utils/regexValidator";
import maxLengthValidator from "utils/maxLengthValidator";

export const createFields = () => {
    return [
        { name: "variant", default: "create", type: "radio" },
        { name: "newSubdirectory", default: "" },
        { name: "existSubdirectory", default: "" },
        {
            name: "shareName",
            default: "",
            validators: [
                regexValidator(/^[a-zA-Z0-9_-]*$/, t("rule.share.name")),
                maxLengthValidator(24, t("rule.share.name.max_size")),
            ],
        },
        {
            name: "shareComment",
            default: "",
            validators: [
                regexValidator(/^[a-zA-Z0-9\s_-]*$/, t("rule.share.comment")),
                maxLengthValidator(1024, t("rule.share.comment.max_size")),
            ],
        },
        {
            name: "quotaValue",
            type: "number",
            input: (input) => (input.length ? Number(input) : ""),
            placeholder: "0",
            validators: [minSizeValidator({ min: 0 })],
        },
        { name: "quotaMetric", input: (input) => Number(input) },
        { name: "recycleBinEnabled", default: true, type: "checkbox" },
        { name: "browseable", default: true, type: "checkbox" },
        { name: "protocolValue", default: "smb", type: "radio" },
        { name: "smbAndAfp", default: "smb_only", type: "radio" },
        { name: "accessType", default: "rw", type: "radio" },
        { name: "mode", default: "locked", type: "radio" },
        { name: "projectSharingEnabled", default: false, type: "checkbox" },
    ];
};
