import React, { useContext, useEffect } from "react";
import PoolShare from "pages/Shares/components/PoolShare";
import { Context } from "store";
import { useObserver } from "mobx-react-lite";
import { CircularProgress, Grid, Paper } from "@material-ui/core";
import CardHeader from "components/CardHeader";
import { useTranslation } from "react-i18next";
import SharesBulkOperationsBar from "pages/Shares/components/SharesBulkOperationsBar";
import ShareDrawer from "pages/Shares/components/ShareDrawer";
import { useStyles } from "./SoloPoolShare.styles";

const SoloPoolShare = ({ poolKey }) => {
    const {
        store: { shareStore }
    } = useContext(Context);
    const { t } = useTranslation();

    const classes = useStyles();

    useEffect(() => {
        if (!shareStore.sharesTable.length) {
            shareStore.fetchSharesTable();
        }
    }, []);

    return useObserver(() => {
        const share = shareStore.sharesTable?.find(share => `${share.pool}/${share.volume}` === poolKey);

        return share ? (
            <Grid container className={classes.container}>
                <SharesBulkOperationsBar />
                <ShareDrawer />
                <PoolShare
                    volume={share.volume}
                    fileSystem={share.fileSystem}
                    partition={share.partition}
                    pool={share.pool}
                    sharesTable={shareStore.sharesTable}
                />
            </Grid>
        ) : shareStore.sharesTable.length ? (
            <Paper>
                <CardHeader title={t("share.solo_pool_share.error_message", { poolKey })} />
            </Paper>
        ) : (
            <Grid container justify={"center"} alignContent={"center"} alignItems={"center"}>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    });
};

export default SoloPoolShare;
