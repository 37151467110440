import i18n from "i18n";

const minMaxLengthStrValidator = ({ min, max }) => {
    return ({ field }) => {
        return [
            field.value.length >= min && field.value.length <= max,
            i18n.t("validator.min_max.size", { minValue: min, maxValue: max })
        ];
    };
};

export default minMaxLengthStrValidator;
