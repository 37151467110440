import i18n from "i18n";

const getPermissionLabel = (ace, isDirectory) => {
    if (isDirectory && (!ace.appliedTo.directory || !ace.appliedTo.subDirectories || !ace.appliedTo.subFiles))
        return i18n.t("users.customize_settings_modal.table.permission.custom");
    if (
        ace.permissions.changeOwner &&
        ace.permissions.changePermissions &&
        ace.permissions.traverseFoldersExecuteFile &&
        ace.permissions.listFoldersReadData &&
        ace.permissions.readAttributes &&
        ace.permissions.readExtendedAttributes &&
        ace.permissions.readPermissions &&
        ace.permissions.createFilesWriteData &&
        ace.permissions.createFoldersAppendData &&
        ace.permissions.writeAttributes &&
        ace.permissions.writeExtendedAttributes &&
        ace.permissions.deleteSubdirsAndFiles &&
        ace.permissions.delete
    )
        return i18n.t("users.customize_settings_modal.table.permission.full_control");
    if (
        !ace.permissions.changeOwner &&
        !ace.permissions.changePermissions &&
        ace.permissions.traverseFoldersExecuteFile &&
        ace.permissions.listFoldersReadData &&
        ace.permissions.readAttributes &&
        ace.permissions.readExtendedAttributes &&
        ace.permissions.readPermissions &&
        !ace.permissions.createFilesWriteData &&
        !ace.permissions.createFoldersAppendData &&
        !ace.permissions.writeAttributes &&
        !ace.permissions.writeExtendedAttributes &&
        !ace.permissions.deleteSubdirsAndFiles &&
        !ace.permissions.delete
    )
        return i18n.t("users.customize_settings_modal.table.permission.read");
    if (
        !ace.permissions.changeOwner &&
        !ace.permissions.changePermissions &&
        !ace.permissions.traverseFoldersExecuteFile &&
        !ace.permissions.listFoldersReadData &&
        !ace.permissions.readAttributes &&
        !ace.permissions.readExtendedAttributes &&
        !ace.permissions.readPermissions &&
        ace.permissions.createFilesWriteData &&
        ace.permissions.createFoldersAppendData &&
        ace.permissions.writeAttributes &&
        ace.permissions.writeExtendedAttributes &&
        ace.permissions.deleteSubdirsAndFiles &&
        ace.permissions.delete
    )
        return i18n.t("users.customize_settings_modal.table.permission.write");
    if (
        !ace.permissions.changeOwner &&
        !ace.permissions.changePermissions &&
        ace.permissions.traverseFoldersExecuteFile &&
        ace.permissions.listFoldersReadData &&
        ace.permissions.readAttributes &&
        ace.permissions.readExtendedAttributes &&
        ace.permissions.readPermissions &&
        ace.permissions.createFilesWriteData &&
        ace.permissions.createFoldersAppendData &&
        ace.permissions.writeAttributes &&
        ace.permissions.writeExtendedAttributes &&
        ace.permissions.deleteSubdirsAndFiles &&
        ace.permissions.delete
    )
        return i18n.t("users.customize_settings_modal.table.permission.read_write");
    return i18n.t("users.customize_settings_modal.table.permission.custom");
};

export default getPermissionLabel;
