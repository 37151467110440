import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetDefragmentationStateArguments from "api/volume/Types/GetDefragmentationStateArguments";

const GetDefragmentationState = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Volume",
    method: "get_defragmentation_state",
    arguments: types.maybe(GetDefragmentationStateArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetDefragmentationState;
