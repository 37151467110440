import { types } from "mobx-state-tree";

import Request from "api/Request";
import RemovePeerArguments from "api/cluster/Types/RemovePeerArguments";

const RemovePeer = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Cluster",
    method: "remove_peer",
    arguments: types.maybe(RemovePeerArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default RemovePeer
