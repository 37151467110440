import React from "react";

const PicSupportIcon = props => {
    return (
        <svg
            width="80px"
            height="80px"
            viewBox="0 0 230 208"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <defs>
                <linearGradient x1="2.86961931%" y1="6.9900148%" x2="58.1046991%" y2="60.922689%" id="picSupportIcon-1">
                    <stop stopColor="#00DCED" offset="0%" />
                    <stop stopColor="#7080F8" offset="100%" />
                </linearGradient>
                <linearGradient x1="-4.97089624%" y1="12.7066116%" x2="91.4453469%" y2="81.2968866%" id="picSupportIcon-2">
                    <stop stopColor="#3ADFFD" offset="0%" />
                    <stop stopColor="#8694FD" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="69.2557554%" y2="100%" id="picSupportIcon-3">
                    <stop stopColor="#C5E8FF" stopOpacity="0.8" offset="0%" />
                    <stop stopColor="#8AB7FF" offset="100%" />
                </linearGradient>
                <linearGradient x1="62.5591402%" y1="81.0738195%" x2="0%" y2="9.20203606%" id="picSupportIcon-4">
                    <stop stopColor="#2895FF" offset="0%" />
                    <stop stopColor="#3D95FF" offset="100%" />
                </linearGradient>
                <linearGradient x1="-4.97089624%" y1="12.7066116%" x2="91.4453469%" y2="81.2968866%" id="picSupportIcon-5">
                    <stop stopColor="#60ADFF" offset="0%" />
                    <stop stopColor="#51A5FF" offset="100%" />
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="picSupportIcon-6">
                    <stop stopColor="#C0E2FF" offset="0%" />
                    <stop stopColor="#E0F4FF" offset="100%" />
                </linearGradient>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g>
                    <circle fill="#C0E2FF" opacity="0.200000003" cx="118.5" cy="104.5" r="92.5" />
                    <path
                        d="M86,72 L206,72 C209.313708,72 212,74.6862915 212,78 L212,160.585153 C212,163.898861 209.313708,166.585153 206,166.585153 L131.537663,166.585153 C131.185473,166.585153 130.839528,166.678154 130.534813,166.854751 L100.50285,184.259705 C99.5471758,184.813564 98.323457,184.487828 97.769598,183.532153 C97.593001,183.227438 97.5,182.881493 97.5,182.529303 L97.5,168.585153 C97.5,167.480583 96.6045695,166.585153 95.5,166.585153 L86,166.585153 C82.6862915,166.585153 80,163.898861 80,160.585153 L80,78 C80,74.6862915 82.6862915,72 86,72 Z"
                        fill="url(#picSupportIcon-1)"
                        transform="translate(146.000000, 129.000000) scale(-1, 1) translate(-146.000000, -129.000000) "
                    />
                    <path
                        d="M206,74 L86,74 C84.8954305,74 83.8954305,74.4477153 83.1715729,75.1715729 C82.4477153,75.8954305 82,76.8954305 82,78 L82,160.585153 C82,161.689722 82.4477153,162.689722 83.1715729,163.41358 C83.8954305,164.137438 84.8954305,164.585153 86,164.585153 L95.5,164.585153 C96.6045695,164.585153 97.6045695,165.032868 98.3284271,165.756726 C99.0522847,166.480583 99.5,167.480583 99.5,168.585153 L99.5,182.529303 L129.531963,165.124349 C130.141393,164.771155 130.833283,164.585153 131.537663,164.585153 L206,164.585153 C207.104569,164.585153 208.104569,164.137438 208.828427,163.41358 C209.552285,162.689722 210,161.689722 210,160.585153 L210,78 C210,76.8954305 209.552285,75.8954305 208.828427,75.1715729 C208.104569,74.4477153 207.104569,74 206,74 Z"
                        stroke="url(#picSupportIcon-2)"
                        strokeWidth="4"
                        transform="translate(146.000000, 129.000000) scale(-1, 1) translate(-146.000000, -129.000000) "
                    />
                    <path
                        d="M156.5,146 C157.880712,146 159,147.119288 159,148.5 C159,149.880712 157.880712,151 156.5,151 L151.5,151 C150.119288,151 149,149.880712 149,148.5 C149,147.119288 150.119288,146 151.5,146 L156.5,146 Z M182.5,146 C183.880712,146 185,147.119288 185,148.5 C185,149.880712 183.880712,151 182.5,151 L165.5,151 C164.119288,151 163,149.880712 163,148.5 C163,147.119288 164.119288,146 165.5,146 L182.5,146 Z M170.5,137 C171.880712,137 173,138.119288 173,139.5 C173,140.880712 171.880712,142 170.5,142 L151.5,142 C150.119288,142 149,140.880712 149,139.5 C149,138.119288 150.119288,137 151.5,137 L170.5,137 Z M190.5,137 C191.880712,137 193,138.119288 193,139.5 C193,140.880712 191.880712,142 190.5,142 L179.5,142 C178.119288,142 177,140.880712 177,139.5 C177,138.119288 178.119288,137 179.5,137 L190.5,137 Z"
                        fill="#AAC5FF"
                        opacity="0.600000024"
                    />
                    <rect fill="#EFFAFF" opacity="0.400000006" x="149" y="87" width="46" height="8" rx="4" />
                    <rect fill="#EFFAFF" opacity="0.400000006" x="149" y="112" width="29" height="8" rx="4" />
                    <rect fill="#8DDDFF" opacity="0.300000012" x="149" y="99" width="41" height="6" rx="3" />
                    <rect fill="#8DDDFF" opacity="0.300000012" x="149" y="124" width="31" height="6" rx="3" />
                    <rect fill="url(#picSupportIcon-3)" opacity="0.800000012" x="95" y="87" width="43" height="66" rx="4" />
                    <path
                        d="M24,26 L144,26 C147.313708,26 150,28.6862915 150,32 L150,114.585153 C150,117.898861 147.313708,120.585153 144,120.585153 L69.5376635,120.585153 C69.1854734,120.585153 68.8395282,120.678154 68.534813,120.854751 L38.5028504,138.259705 C37.5471758,138.813564 36.323457,138.487828 35.769598,137.532153 C35.593001,137.227438 35.5,136.881493 35.5,136.529303 L35.5,122.585153 C35.5,121.480583 34.6045695,120.585153 33.5,120.585153 L24,120.585153 C20.6862915,120.585153 18,117.898861 18,114.585153 L18,32 C18,28.6862915 20.6862915,26 24,26 Z"
                        fill="url(#picSupportIcon-4)"
                    />
                    <path
                        d="M144,28 L24,28 C22.8954305,28 21.8954305,28.4477153 21.1715729,29.1715729 C20.4477153,29.8954305 20,30.8954305 20,32 L20,114.585153 C20,115.689722 20.4477153,116.689722 21.1715729,117.41358 C21.8954305,118.137438 22.8954305,118.585153 24,118.585153 L33.5,118.585153 C34.6045695,118.585153 35.6045695,119.032868 36.3284271,119.756726 C37.0522847,120.480583 37.5,121.480583 37.5,122.585153 L37.5,136.529303 L67.5319626,119.124349 C68.141393,118.771155 68.8332833,118.585153 69.5376635,118.585153 L144,118.585153 C145.104569,118.585153 146.104569,118.137438 146.828427,117.41358 C147.552285,116.689722 148,115.689722 148,114.585153 L148,32 C148,30.8954305 147.552285,29.8954305 146.828427,29.1715729 C146.104569,28.4477153 145.104569,28 144,28 Z"
                        stroke="url(#picSupportIcon-5)"
                        strokeWidth="4"
                    />
                    <rect fill="#72D9FF" opacity="0.200000003" x="32" y="93" width="24" height="5" rx="2.5" />
                    <rect fill="#72D9FF" opacity="0.200000003" x="32" y="102" width="10" height="5" rx="2.5" />
                    <rect fill="#72D9FF" opacity="0.200000003" x="46" y="102" width="22" height="5" rx="2.5" />
                    <rect fill="#72D9FF" opacity="0.200000003" x="60" y="93" width="16" height="5" rx="2.5" />
                    <rect fill="#EFFAFF" opacity="0.400000006" x="32" y="43" width="46" height="8" rx="4" />
                    <rect fill="#EFFAFF" opacity="0.400000006" x="32" y="68" width="49" height="8" rx="4" />
                    <rect fill="#8DDDFF" opacity="0.400000006" x="32" y="55" width="41" height="6" rx="3" />
                    <rect fill="#8DDDFF" opacity="0.400000006" x="32" y="80" width="31" height="6" rx="3" />
                    <rect fill="url(#picSupportIcon-3)" opacity="0.800000012" x="92" y="41" width="43" height="66" rx="4" />
                    <path
                        d="M202.535534,18.4644661 C202.984104,18.913036 202.046317,20.6320302 200.984346,21.9997271 C202.046334,23.3680001 202.9841,25.0869679 202.535534,25.5355339 C202.094547,25.9765212 200.340132,24.9920355 199.008875,23.9770378 C197.639699,25.0421301 195.914036,25.9851036 195.464466,25.5355339 C195.014796,25.0858636 195.958292,23.3595287 197.023472,21.9902143 C196.007782,20.659542 195.02352,18.9054124 195.464466,18.4644661 C195.913036,18.0158962 197.63203,18.9536827 198.999727,20.0156545 C200.368,18.9536662 202.086968,18.0159001 202.535534,18.4644661 Z"
                        fill="#C0E2FF"
                        opacity="0.300000012"
                    />
                    <circle stroke="url(#picSupportIcon-6)" strokeWidth="4" opacity="0.300000012" cx="24" cy="182" r="6" />
                    <path
                        d="M211,181 C212.157947,181 213.166912,184.352106 213.566858,187.433526 C216.647894,187.833088 220,188.842053 220,190 C220,191.138001 216.546542,192.132108 213.569666,192.54565 C213.171492,195.63266 212.160576,199 211,199 C209.839424,199 208.828508,195.63266 208.430428,192.545496 C205.453458,192.132108 202,191.138001 202,190 C202,188.842053 205.352106,187.833088 208.433526,187.433142 C208.833088,184.352106 209.842053,181 211,181 Z"
                        fill="#C0E2FF"
                        opacity="0.300000012"
                        transform="translate(211.000000, 190.000000) rotate(45.000000) translate(-211.000000, -190.000000) "
                    />
                </g>
            </g>
        </svg>
    );
};

export default PicSupportIcon;
