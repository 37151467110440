import React, { useContext, useEffect } from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import { useStyles } from "./ImportCertificateModal.styles";
import { Context } from "store";
import Dialog from "components/Dialog";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import Radio from "components/Radio";
import RadioGroup from "components/RadioGroup";
import { IMPORT_SIGNED_CERT, IMPORT_UNSIGNED_CERT } from "../../ServerCertificateCard";

const IMPORT_CERTIFICATE_MODAL_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_import_certificate_modal`;

const ImportCertificateModal = ({ open, onClose, onApply }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { webserverStore },
    } = useContext(Context);

    const state = useLocalStore(() => ({
        radioValue: IMPORT_SIGNED_CERT,
    }));

    useEffect(() => {
        webserverStore.fetchConfigInfo();
    }, []);

    const onEnter = () => {
        state.radioValue = IMPORT_SIGNED_CERT;
    };

    const onNextClick = () => {
        onApply(state.radioValue);
    };

    const handleRadioChange = (e) => {
        state.radioValue = e.target.value;
    };

    return useObserver(() => (
        <>
            <Dialog
                open={open}
                buttons={[
                    <Button
                        key={"cancelBtn"}
                        onClick={onClose}
                        variant={"contained"}
                        color={"secondary"}
                        id={`${IMPORT_CERTIFICATE_MODAL_ID_PREFIX}_cancel`}
                    >
                        {t("common.button.cancel")}
                    </Button>,
                ]}
                onEnter={onEnter}
                inProgress={state.inProgress}
                onSubmit={onNextClick}
                submitBtnLabel={t("common.button.next")}
                submitBtnId={`${IMPORT_CERTIFICATE_MODAL_ID_PREFIX}_next`}
                title={t("admin_settings.server_certificate.import_certificate.title")}
                onClose={onClose}
            >
                <RadioGroup value={state.radioValue} onChange={handleRadioChange} className={classes.radioGroup}>
                    <Radio
                        className={classes.radio}
                        value={IMPORT_SIGNED_CERT}
                        label={t("admin_settings.server_certificate.import_certificate.import_signed_csr.title")}
                        helperText={t("admin_settings.server_certificate.import_certificate.import_signed_csr.description")}
                        id={`${IMPORT_CERTIFICATE_MODAL_ID_PREFIX}_self_signed_certificate`}
                    />
                    <Radio
                        className={classes.radio}
                        value={IMPORT_UNSIGNED_CERT}
                        label={t("admin_settings.server_certificate.import_certificate.import_certificate.title")}
                        helperText={t("admin_settings.server_certificate.import_certificate.import_certificate.description")}
                        id={`${IMPORT_CERTIFICATE_MODAL_ID_PREFIX}_certificate_signing_request`}
                    />
                </RadioGroup>
            </Dialog>
        </>
    ));
};

export default ImportCertificateModal;
