// moment.duration hasn't native realization of format function, so this is its custom implementation
const formatDuration = duration => {
    const units = [];
    units.push({ time: duration.years(), postfix: "y" });
    units.push({ time: duration.months(), postfix: "m" });
    units.push({ time: duration.weeks(), postfix: "w" });
    units.push({ time: duration.days() - 7 * duration.weeks(), postfix: "d" });
    units.push({ time: duration.hours(), postfix: "h" });
    units.push({ time: duration.minutes(), postfix: "m" });
    units.push({ time: duration.seconds(), postfix: "s" });
    const firstNonZeroTime = units.findIndex(unit => unit.time);
    return units
        .splice(firstNonZeroTime)
        .map(unit => `${unit.time}${unit.postfix}`)
        .join(", ");
};

export default formatDuration;
