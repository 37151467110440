import React, { useEffect } from "react";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { UPGRADE_SYSTEM } from "const/widgetNameConst";
import { Box, Typography, CircularProgress, Link, Button, MenuItem } from "@material-ui/core";
import DataCard from "components/DataCard";
import { useTranslation, Trans } from "react-i18next";
import FileUploadDialog from "components/FileUploadDialog";
import { useStyles } from "./UpgradeSystemCard.styles";
import { observer, useLocalStore } from "mobx-react";
import Select from "components/Select";
import { FAILED, FINISHED } from "const/updatesConst";
import RebootIcon from "assets/RebootIcon";
import ClearLunWarningDialog from "./components/ClearLunWarningDialog";
import { autorun } from "mobx";
import { useStore } from "hooks/useStore";
import Dialog from "components/Dialog";
import { useModal } from "hooks/useModal";

const UPGRADE_SYSTEM_DESTINATION = "lun";

const UpgradeSystemCard = () => {
    const {
        store: { upgradesStore, powerAndUpsStore, uploadStore },
    } = useStore();
    const { t } = useTranslation();
    const state = useLocalStore(() => ({
        file: null,
        isUploadDialogOpen: false,
        isClearLunDialogOpen: false,
        isClearInProgress: false,
        loading: false,
        selectedFileName: "",
        setSelectedFileName(fileName) {
            this.selectedFileName = fileName;
        },
    }));
    const classes = useStyles();

    const rebootDialog = useModal();

    useEffect(() => {
        (async () => {
            await upgradesStore.fetchUpdatesList();
            if (upgradesStore.updatesList.length > 0) state.setSelectedFileName(upgradesStore.updatesList[0].name);
        })();

        autorun(() => {
            if (upgradesStore.updatesList.length > 0) {
                const lastItemIndex = upgradesStore.updatesList.length - 1;

                state.setSelectedFileName(upgradesStore.updatesList[lastItemIndex].name);
            }
        });
    }, []);

    const closeDialog = () => {
        state.isUploadDialogOpen = false;
    };

    const openUpgradeModal = () => {
        if (upgradesStore.isUpgrading) return;
        state.isUploadDialogOpen = true;
    };

    const onUpgradeFileNameChange = (e) => {
        state.setSelectedFileName(e.target.value);
    };

    const upgrade = () => {
        upgradesStore.upgrade({ filename: state.selectedFileName, force: false });
    };

    const openClearLunDialog = () => {
        state.isClearLunDialogOpen = true;
    };

    const closeClearLunDialog = () => {
        state.isClearLunDialogOpen = false;
    };

    const reboot = async () => {
        const res = await powerAndUpsStore.reboot();
        return res;
    };

    const clearLun = async () => {
        state.isClearInProgress = true;

        const res = await upgradesStore.clearLun();
        await upgradesStore.fetchUpdatesList();
        state.setSelectedFileName("");

        state.isClearInProgress = false;
        return res;
    };

    const renderMoreMenu = () => {
        return (
            <MoreMenu>
                <HomeCardActionMenuItem widget={UPGRADE_SYSTEM} />
                <MenuItem key={"openClearLunDialog"} onClick={openClearLunDialog}>
                    {t("upgrades.upgrade_system.clear_lun_dialog.menu_item")}
                </MenuItem>
            </MoreMenu>
        );
    };

    const components = [
        <Select
            value={state.selectedFileName}
            options={upgradesStore.updatesList.map((u) => u.name)}
            onChange={onUpgradeFileNameChange}
            className={classes.select}
            disabled={upgradesStore.isUpgrading}
            key={`upgrade_system-start-component-1`}
        />,
        <Link to={"#"} className={classes.link} onClick={openUpgradeModal} key={`upgrade_system-start-component-1`} />,
    ];

    const onSubmit = async (file) => {
        const res = await uploadStore.upload(file, UPGRADE_SYSTEM_DESTINATION);
        await upgradesStore.fetchUpdatesList();
        closeDialog();

        return res;
    };

    return (
        <DataCard headerDivider title={t("upgrades.upgrade_system.title")} headerControl={renderMoreMenu()}>
            <ClearLunWarningDialog
                open={state.isClearLunDialogOpen}
                onClose={closeClearLunDialog}
                submit={clearLun}
                inProgress={state.isClearInProgress}
                content={t("upgrades.upgrade_system.clear_lun_dialog.message")}
            />
            {upgradesStore.upgradeStepStatus !== FINISHED && upgradesStore.updatesList.length > 0 && (
                <>
                    {upgradesStore.upgradeStepStatus === FAILED && (
                        <Box className={classes.message}>
                            <Typography>{t("upgrades.upgrade_system.failed")}</Typography>
                        </Box>
                    )}
                    <Typography component="div">
                        <Trans i18nKey={"upgrades.upgrade_system.start"} components={components} />
                    </Typography>
                    <Box className={classes.buttonContainer}>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            onClick={upgrade}
                            className={classes.button}
                            disabled={upgradesStore.isUpgrading}
                        >
                            {t("common.button.start")}
                            {upgradesStore.isUpgrading && (
                                <Box className={classes.icon}>
                                    <CircularProgress color={"primary"} size={16} />
                                </Box>
                            )}
                        </Button>
                    </Box>
                </>
            )}

            {upgradesStore.upgradeStepStatus !== FINISHED && upgradesStore.updatesList.length === 0 && (
                <Typography>
                    <Trans
                        i18nKey={"upgrades.upgrade_system.placeholder"}
                        components={<Link to={"#"} className={classes.link} onClick={openUpgradeModal} />}
                    />
                </Typography>
            )}

            {upgradesStore.upgradeStepStatus === FINISHED && (
                <>
                    <Typography>{t("upgrades.upgrade_system.message.success")}</Typography>
                    <Box className={classes.buttonContainer}>
                        <Button
                            disabled={powerAndUpsStore.isRebootLoading}
                            variant={"contained"}
                            color={"secondary"}
                            startIcon={<RebootIcon />}
                            onClick={rebootDialog.open}
                        >
                            {t("common.button.reboot")}
                            {powerAndUpsStore.isRebootLoading && (
                                <Box className={classes.icon}>
                                    <CircularProgress color={"primary"} size={16} />
                                </Box>
                            )}
                        </Button>
                    </Box>
                </>
            )}

            <FileUploadDialog
                onClose={closeDialog}
                open={state.isUploadDialogOpen}
                onSubmit={onSubmit}
                submitBtnLabel={t("common.button.import")}
                title={t("upgrades.upgrade_system.upload_file_dialog.title")}
                regExpExtension={/^rootup+[a-z_\-\s0-9\\.]+\.(gpg)$/}
            />
            <Dialog
                title={t("common.dialog_title.warning")}
                open={rebootDialog.isOpen}
                onClose={rebootDialog.close}
                onSubmit={reboot}
                submitBtnLabel={t("common.button.proceed")}
                withoutIconClose
                withoutDividers
                disableClosingAfterStartLoading
                closeBtnLabel={t("common.button.cancel")}
            >
                {t("power_and_ups.power.dialog_reboot")}
            </Dialog>
        </DataCard>
    );
};

export default observer(UpgradeSystemCard);
