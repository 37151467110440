import Request from "api/Request";

const GetNasConfiguration = Request.props({
    path: "/com/studionetworksolutions/evo/Nas",
    method: "get_nas_configuration"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetNasConfiguration;
