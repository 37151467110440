import { types } from "mobx-state-tree";

import Request from "api/Request";
import ClearLocksArguments from "api/share/Types/ClearLocksArguments";

const ClearLocks = Request.props({
    path: "/com/studionetworksolutions/evo/Nas",
    method: "clear_locks",
    arguments: types.maybe(ClearLocksArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ClearLocks;
