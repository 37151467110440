import { defaultFormOptions, defaultFormPlugins } from "utils/mobxReactFormDefaults";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validator from "validatorjs";
import i18n from "i18n";

const createForm = ({ fields, hooks, afterFormCreate, initialValues, rules, context, options }) => {
    const formFields = fields.map(({ getValue, getDisabled, getRules, disabled, rules, ...field }) => ({
        ...field,
        value: getValue && initialValues ? getValue(initialValues) : undefined,
        disabled: getDisabled ? getDisabled(initialValues, context) : disabled,
        rules: getRules ? getRules(initialValues, context) : rules
    }));
    const plugins = {
        ...defaultFormPlugins,
        ...(rules
            ? {
                  dvr: dvr({
                      package: validator,
                      extend: ({ validator }) => {
                          validator.setMessages(validator.getDefaultLang(), {
                              ...validator.getMessages(validator.getDefaultLang()),
                              required: i18n.t("common.error.validator.message.required")
                          });
                          Object.keys(rules).forEach(key =>
                              validator.register(
                                  key,
                                  rules[key].function,
                                  rules[key].message || validator.getMessages(validator.getDefaultLang())[key]
                              )
                          );
                      }
                  })
              }
            : {})
    };

    const form = new MobxReactForm(
        { fields: formFields },
        {
            hooks,
            plugins,
            options: { ...defaultFormOptions, ...(options || {}) }
        }
    );

    afterFormCreate && afterFormCreate(form);
    return form;
};

export default createForm;
