import { types } from "mobx-state-tree";

const GetFoldersArguments = types.model({
    json: types.maybe(
        types.model({
            share: types.maybe(types.string),
            path: types.maybe(types.string),
            skipFolders: types.maybe(types.boolean),
            skipFiles: types.maybe(types.boolean)
        })
    )
});

export default GetFoldersArguments;
