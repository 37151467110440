import React, { forwardRef } from "react";
import { useStyles } from "./PreviewHeader.styles";
import CardHeader from "components/CardHeader";
import { Paper } from "@material-ui/core";

const PreviewHeader = forwardRef(({ title, className }, ref) => {
    const classes = useStyles({ title });
    return (
        <Paper ref={ref} className={className}>
            <CardHeader className={classes.container} title={title} />
        </Paper>
    );
});

export default PreviewHeader;
