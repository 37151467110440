import { types, flow, getParent } from "mobx-state-tree";
import Socket from "websocket";
import GetSharebrowserServerVersion from "api/system_components/sharebrowser_server/Requests/GetSharebrowserServerVersion";
import GetSharebrowserServerVersionResult from "api/system_components/sharebrowser_server/Responses/GetSharebrowserServerVersionResult";
import IsSharebrowserServerEnabled from "api/system_components/sharebrowser_server/Requests/IsSharebrowserServerEnabled";
import GetSharebrowserServerStatusResult from "api/system_components/sharebrowser_server/Responses/GetSharebrowserServerStatusResult";
import StartSharebrowserServer from "api/system_components/sharebrowser_server/Requests/StartSharebrowserServer";
import StopSharebrowserServer from "api/system_components/sharebrowser_server/Requests/StopSharebrowserServer";

const SharebrowserStore = types
    .model({
        sharebrowserServerVersion: types.maybe(GetSharebrowserServerVersionResult),
        sharebrowserServerStatus: types.maybe(GetSharebrowserServerStatusResult)
    })
    .views(self => ({
        get serverVersion() {
            return self.sharebrowserServerVersion?.data?.componentVersion || "";
        },
        get serverStatus() {
            return self.sharebrowserServerStatus?.data;
        }
    }))
    .actions(self => ({
        getSharebrowserServerVersion: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetSharebrowserServerVersion.create().init();
                const res = yield Socket.send(req);
                self.sharebrowserServerVersion = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getSharebrowserServerStatus: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = IsSharebrowserServerEnabled.create().init();
                const res = yield Socket.send(req);
                self.sharebrowserServerStatus = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        startSharebrowserServer: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = StartSharebrowserServer.create().init();
                const res = yield Socket.send(req);
                self.getSharebrowserServerStatus();
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        endSharebrowserServer: flow(function*() {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = StopSharebrowserServer.create().init();
                const res = yield Socket.send(req);
                self.getSharebrowserServerStatus();
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        })
    }));

export default SharebrowserStore;
