import React, { useContext } from "react";
import { Context } from "store";
import { Grid, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react";
import SsdIcon from "assets/SsdIcon";
import HddIcon from "assets/HddIcon";
import PoolHeader from "../PoolHeader";
import { useStyles } from "./Pool.style";
import { useTranslation } from "react-i18next";
import useStoreByIp from "hooks/useStoreByIp";

const Pool = ({ pool, id, ip }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { volumeDrawerStore },
    } = useContext(Context);
    const { poolsStore } = useStoreByIp({ ip });

    const onPoolClick = (name) => () => {
        poolsStore.setCurrentPoolName(poolsStore.currentPoolName === name.pool ? null : name.pool);
        volumeDrawerStore.dropOthersCurrentPools(ip);
        volumeDrawerStore.setCurrentPoolIp(ip);
    };

    const renderDisk = (title, type) => {
        const renderIcon = (type) => {
            switch (type) {
                case "ssd":
                    return <SsdIcon />;
                case "hdd":
                    return <HddIcon />;
                default:
                    return <SsdIcon />;
            }
        };
        return (
            <Grid key={title} item container xs={6} md={4} alignItems={"center"} spacing={2} className={classes.diskItem}>
                <Grid item>
                    <Grid container alignItems={"center"}>
                        {renderIcon(type)}
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography>{title}</Typography>
                </Grid>
            </Grid>
        );
    };
    return useObserver(() => (
        <Grid id={id} container direction={"column"} className={classes.container} onClick={onPoolClick(pool)}>
            <Grid item>
                <PoolHeader pool={pool} ip={ip} />
            </Grid>
            <Grid item container spacing={1} className={classes.disksContainer}>
                {pool.drives &&
                    pool.drives.map((drive) => {
                        return renderDisk(
                            t("disks_and_pools.pools_card.pool_item.drive_title", { name: drive.driveName }),
                            drive.driveType
                        );
                    })}
            </Grid>
        </Grid>
    ));
};

export default Pool;
