import { types } from "mobx-state-tree";

import { DEFAULT_START_PERIOD } from "const/chartConst";

const UiParameters = types
    .model("UiParameter", {
        json: types.maybe(
            types.model({
                notRedirectToTroubleshooting: types.maybe(types.boolean),
                graphStartPeriod: types.optional(types.number, DEFAULT_START_PERIOD),
                graphAutoscaling: types.optional(types.boolean, false),
                resetTimestamp: types.maybeNull(types.number),
                multipleEvos: types.array(
                    types.model("evo", {
                        ip: types.string,
                        name: types.string,
                        needToConnect: types.optional(types.boolean, false)
                    })
                ),
                notificationsFilterSettings: types.maybe(
                    types.model({ info: types.boolean, warning: types.boolean, error: types.boolean })
                ),
                dontShowAgainQuickStart: types.maybe(types.boolean)
            })
        )
    })
    .views(self => ({
        get multipleEvosWithNeedToConnect() {
            return self.json.multipleEvos.filter(evo => evo.needToConnect);
        }
    }));

export default UiParameters;
