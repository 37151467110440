import { types } from "mobx-state-tree";

import EnclosureActivationData from "api/activation/quad/Types/EnclosureActivationData";

const EnclosureActivationResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(EnclosureActivationData)
});

export default EnclosureActivationResult;
