import React, { useEffect, useContext, useCallback } from "react";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import { useLocalStore, useObserver } from "mobx-react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./PhysicalDisksDrawer.styles";
import clsx from "clsx";
import DiskIcon from "assets/DiskIcon";
import QuickStartIcon from "assets/QuickStartIcon";
import ReportIcon from "assets/ReportIcon";
import SmartReportModal from "../SmartReportModal";
import i18n from "i18n";
import useStoreByIp from "hooks/useStoreByIp";

const PhysicalDisksDrawer = ({ ip }) => {
    const drawerName = ip ? `${ip}physicalDisksDrawer` : "physicalDisksDrawer";
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { uiStore }
    } = useContext(Context);
    const { driveStore } = useStoreByIp({ ip });

    const state = useLocalStore(() => ({
        isSmartModalOpen: false,
        isSmartModalOpenInProgress: false,
        isSetIdentifyInProgress: false,
        get isIdentifyMatch() {
            return (
                driveStore.identify?.quad === driveStore.currentDriveName?.quad &&
                driveStore.identify?.drive === driveStore.currentDriveName?.drive
            );
        },
        get identifyButtonText() {
            return this.isIdentifyMatch
                ? t("dashboard.physical_disks.side_menu.button.led_off")
                : t("dashboard.physical_disks.side_menu.button.led_on");
        }
    }));

    useEffect(() => {
        if (driveStore.currentDriveName) {
            if (uiStore.openedDrawer !== drawerName) {
                uiStore.openDrawer(drawerName, closeDrawer);
            } else {
                uiStore.isNotificationMenuPinned && uiStore.changeDrawer();
            }
        } else if (uiStore.openedDrawer === drawerName) {
            uiStore.closeDrawer();
        }
    }, [driveStore.currentDriveName?.drive, driveStore.currentDriveName?.quad]);

    useEffect(() => {
        driveStore.getIdentifyStatus();
    }, []);

    const openSmartModal = useCallback(async () => {
        state.isSmartModalOpenInProgress = true;
        const res = await driveStore.getDriveSmartReport();
        res && (state.isSmartModalOpen = true);
        state.isSmartModalOpenInProgress = false;
    });
    const closeSmartModal = () => {
        state.isSmartModalOpen = false;
    };

    const identifyOn = async () => {
        state.isSetIdentifyInProgress = true;
        const res = await driveStore.identifyOn({
            quad: driveStore.currentDriveName?.quad,
            drive: driveStore.currentDriveName?.drive
        });
        state.isSetIdentifyInProgress = false;
        res && closeDrawer();
    };
    const identifyOff = async () => {
        state.isSetIdentifyInProgress = true;
        const res = await driveStore.identifyOff();
        state.isSetIdentifyInProgress = false;
        res && closeDrawer();
    };

    const closeDrawer = () => {
        driveStore.setCurrentDriveName(null);
    };

    const getStatus = status => {
        switch (status) {
            case "online":
                return i18n.t("dashboard.physical_disks.status.online");
            case "absent":
                return i18n.t("dashboard.physical_disks.status.absent");
            case "replaced":
                return i18n.t("dashboard.physical_disks.status.replaced");
            case "warning":
                return i18n.t("dashboard.physical_disks.status.warning");
            case "failed":
                return i18n.t("dashboard.physical_disks.status.failed");
            case "not_activated":
                return i18n.t("dashboard.physical_disks.status.not_activated");
            case "unknown":
                return i18n.t("dashboard.physical_disks.status.unknown");
            default:
                return i18n.t("dashboard.physical_disks.status.online");
        }
    };

    const renderDetails = () => {
        return driveStore.currentDrive ? (
            <Grid container direction={"column"} className={classes.detailsContainer} spacing={4}>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("dashboard.physical_disks.side_menu.details.label.type")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={clsx(classes.textInfo, classes.typeText)}>
                            {driveStore.currentDrive.driveType}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={"baseline"} wrap={"nowrap"}>
                    <Grid item xs={6}>
                        <Typography>{t("dashboard.physical_disks.side_menu.details.label.status")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={clsx(classes.textInfo, classes.statusText)}>
                            {getStatus(driveStore.currentDrive.status)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        ) : null;
    };
    const renderActions = () => {
        return driveStore.currentDrive ? (
            <Grid direction={"column"} container>
                <BulkBarFlatButton inProgress={state.isSmartModalOpenInProgress} onClick={openSmartModal} icon={<ReportIcon />}>
                    {t("dashboard.physical_disks.side_menu.button.smart")}
                </BulkBarFlatButton>
                <BulkBarFlatButton
                    onClick={state.isIdentifyMatch ? identifyOff : identifyOn}
                    icon={<QuickStartIcon />}
                    inProgress={!driveStore.identify || state.isSetIdentifyInProgress}
                >
                    {state.identifyButtonText}
                </BulkBarFlatButton>
            </Grid>
        ) : null;
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            icon={<DiskIcon />}
            title={
                driveStore.currentDriveName &&
                `${t("common.quad_name", { name: driveStore.currentDriveName.quad })} ${t("common.drive_name", {
                    name: driveStore.currentDriveName.drive
                })}`
            }
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            modals={<SmartReportModal ip={ip} open={state.isSmartModalOpen} onClose={closeSmartModal} />}
        />
    ));
};

export default PhysicalDisksDrawer;
