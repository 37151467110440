import { types } from "mobx-state-tree";

import Request from "api/Request";
import RemoveConnectionArguments from "api/vpn/Types/RemoveConnectionArguments";

const RemoveConnection = Request.props({
    path: "/com/studionetworksolutions/evo/manage/VPN",
    method: "remove_connection",
    arguments: types.maybe(RemoveConnectionArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default RemoveConnection;
