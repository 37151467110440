import React, { useCallback, useContext } from "react";
import { Context } from "store";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./FolderBrowser.styles";
import { useTranslation } from "react-i18next";
import { Button, ButtonBase, DialogTitle, Typography, Grid, Divider, Box } from "@material-ui/core";
import Dialog from "components/Dialog";
import FolderIcon from "assets/FolderIcon";
import ThinArrowRightIcon from "assets/ThinArrowRightIcon";
import CloseIcon from "assets/CloseIcon";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";

const FOLDER_BROWSER_ID_PREFIX = `folder_browser`;

const FolderBrowser = ({ open, onClose, share, parentName, onChoose, pathValue = [], parentId }) => {
    const idPrefix = parentId ? `${parentId}_${FOLDER_BROWSER_ID_PREFIX}` : FOLDER_BROWSER_ID_PREFIX;
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        store: { slingshotStorageStore },
    } = useContext(Context);

    const state = useLocalStore(() => ({
        isLoading: false,
        dirs: [],
        isError: false,
    }));

    const openFolderByIndex = useCallback((index) => async () => {
        state.isError = false;
        if (index === 0) {
            resetRoot();
            return;
        }
        state.isLoading = true;
        state.dirs = state.dirs.slice(0, index);

        await slingshotStorageStore.fetchFolders({
            share,
            path: state.dirs.join("/"),
        });
        state.isLoading = false;
    });

    const openFolder = useCallback((folderName) => async () => {
        const preparedFolderName =
            folderName[folderName.length - 1] === "/" ? folderName.slice(0, [folderName.length - 1]) : folderName;

        state.isError = false;
        state.dirs.push(preparedFolderName);
        state.isLoading = true;

        const res = await slingshotStorageStore.fetchFolders({
            share,
            path: state.dirs.join("/"),
        });

        !res && state.dirs.pop();
        state.isLoading = false;
    });

    const resetRoot = async () => {
        state.isLoading = true;
        state.dirs.clear();
        await slingshotStorageStore.fetchFolders({
            share,
            path: "",
        });
        state.isLoading = false;
    };

    const onSubmit = useCallback(() => {
        onChoose(state.dirs.join("/"));
        onClose();
    });

    const onModalEnter = useCallback(async () => {
        state.isError = false;
        const path = pathValue.length && pathValue.split("/");
        if (path.length > 0) {
            state.dirs = path;
            state.isLoading = true;
            const res = await slingshotStorageStore.fetchFolders({
                share,
                path: state.dirs.join("/"),
            });
            state.isLoading = false;
            !res && resetRoot();
        } else {
            resetRoot();
        }
    });

    const getHeader = () => (
        <>
            <DialogTitle className={classes.dialogTitle}>
                <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container wrap={"nowrap"}>
                    <Grid item className={classes.titleText}>
                        {t("slingshot.folder.browser.title")}
                    </Grid>

                    <Grid item>
                        <CardHeaderControlButton onClick={onClose}>
                            <ButtonBase id={`${idPrefix}_close`}>
                                <CloseIcon />
                            </ButtonBase>
                        </CardHeaderControlButton>
                    </Grid>
                </Grid>
                <Grid container wrap="nowrap" spacing={2} className={classes.parentBreadcrumbs}>
                    <Grid item>
                        <Typography variant="h6" className={classes.parentName}>
                            {parentName}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ThinArrowRightIcon />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className={classes.folderBrowserLabel}>
                            {t("slingshot.folder.browser.title")}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <Divider />
            <Grid container spacing={2} className={classes.navBreadcrumbs}>
                {[share, ...state.dirs].map((dir, index) => (
                    // TODO need to refactor the logic so that it does not depend on the index!!!!
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid item key={`${dir}${index}`} className={classes.navItem}>
                        <Grid container wrap="nowrap" spacing={2}>
                            {index !== 0 && (
                                <Grid item>
                                    <Box height="100%" display="flex" alignItems="center">
                                        <ThinArrowRightIcon />
                                    </Box>
                                </Grid>
                            )}
                            <Grid onClick={openFolderByIndex(index)} item id={`${idPrefix}_open_dir_${dir}`}>
                                <Typography>{dir}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </>
    );

    return useObserver(() => (
        <Dialog
            withoutDividers
            maxWidth="md"
            onEnter={onModalEnter}
            header={getHeader()}
            open={open}
            onClose={onClose}
            buttons={
                <Button variant="contained" color="secondary" onClick={onClose} id={`${idPrefix}_cancel`}>
                    {t("common.button.cancel")}
                </Button>
            }
            submitBtn={
                <Button variant="contained" color="primary" onClick={onSubmit} disabled={state.isError} id={`${idPrefix}_add`}>
                    {t("slingshot.folder.browser.add_button")}
                </Button>
            }
            PaperProps={{ className: classes.dialogPaper }}
        >
            <Grid container direction="column">
                {state.isLoading ? (
                    <EmptyCardPlaceholder inProgress={state.isLoading} />
                ) : (
                    <>
                        {state.isError ? (
                            <EmptyCardPlaceholder message={t("slingshot.folder.browser.error")} />
                        ) : (
                            <>
                                {slingshotStorageStore.folders.map((folder) => (
                                    <Grid
                                        key={`${folder.name}-${Date.now()}`}
                                        item
                                        container
                                        alignItems="center"
                                        onClick={openFolder(folder.name)}
                                        spacing={2}
                                        className={classes.folderRow}
                                        id={`${idPrefix}_open_folder_${folder.name}`}
                                    >
                                        <Grid item>
                                            <FolderIcon />
                                        </Grid>
                                        <Grid item>
                                            <Typography>{folder.name}</Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </>
                        )}
                    </>
                )}
            </Grid>
        </Dialog>
    ));
};

export default FolderBrowser;
