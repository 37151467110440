import React from "react";
import { TableSortLabel as MuiTableSortLabel } from "@material-ui/core";
import SortDownIcon from "assets/SortDownIcon";
import SortPassiveIcon from "assets/SortPassiveIcon";
import Typography from "@material-ui/core/Typography";
import { ASC } from "const/sortColumnConst";
import { useStyles } from "./TableSortLabel.styles";

const TableSortLabel = ({ active, children, direction, ...rest }) => {
    const renderIcon = () => {
        return active ? SortDownIcon : SortPassiveIcon;
    };

    const classes = useStyles();

    return (
        <MuiTableSortLabel direction={active ? direction : ASC} IconComponent={renderIcon()} active {...rest}>
            <Typography className={classes.label} variant={"subtitle1"}>
                {children}
            </Typography>
        </MuiTableSortLabel>
    );
};

export default TableSortLabel;
