import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(0, 6, 6, 6)
    },
    steps: {
        paddingTop: theme.spacing(13)
    }
}));
