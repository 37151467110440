import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { VOLUME_TYPE_OPTIONS } from "const/clusterConst";
import { useStyles } from "../../EditVolumeBricksDialog.styles";

const EditVolumeBricksDialogError = state => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (!state.validationError) return null;

    return (
        <React.Fragment>
            {state.form.$("type").value === VOLUME_TYPE_OPTIONS.simple ||
            (state.form.$("type").value === VOLUME_TYPE_OPTIONS.grid && !state.form.$("setupManually").value) ? (
                <React.Fragment>
                    <Typography className={classes.errorColor}>
                        {t("cluster.volume.helper_text.simple", {
                            bricksNumber: state.form.$("type").value === VOLUME_TYPE_OPTIONS.simple ? "1" : "3"
                        })}
                    </Typography>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Typography className={classes.errorColor}>
                        {t("cluster.volume.helper_text.mirror", {
                            bricksNumber:
                                state.form.$("type").value === VOLUME_TYPE_OPTIONS.mirror
                                    ? state.form.$("replica").value
                                    : state.form.$("disperse").value
                        })}
                    </Typography>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default EditVolumeBricksDialogError;
