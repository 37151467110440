import { useState, useEffect } from "react";

/**
 * @typedef {Object} Message
 * @property {any} data - data of message
 * @property {number} count - message sequence number
 */
/**
 * @typedef {Object} HandlerMessage
 * @property {any[]} history - array of messages received
 * @property {Message} message - message object
 * @property {function} sendMessage - function to send a message to another tab
 */
/**
 * Hook for transferring messages to another tab.
 * @description Only use the hook to SEND PRIMITIVE MESSAGES. Transmitting LARGE DATA can cause the application to RUN SLOWLY!
 * @param {string} uniqueKey - unique key for send and get message
 * @returns {HandlerMessage} handler of object the message
 */
export const useMessage = uniqueKey => {
    const overUniqueKey = "MESSAGE_" + uniqueKey;
    const [history, setHistory] = useState([]);

    const sendMessage = data => {
        window.localStorage.setItem(overUniqueKey, data);
    };

    const onWatchEventHandler = ({ storageArea, key, newValue }) => {
        if (storageArea !== localStorage) return;
        if (key !== overUniqueKey) return;
        setHistory(prevHistory => [...prevHistory, newValue]);
        storageArea.removeItem(overUniqueKey);
    };

    useEffect(() => {
        window.addEventListener("storage", onWatchEventHandler);
        return () => window.removeEventListener("storage", onWatchEventHandler);
    }, [overUniqueKey]);

    return {
        history,
        message: { data: history.length ? history[history.length - 1] : null, count: history.length },
        sendMessage
    };
};
