import React, { useContext, useEffect } from "react";
import { observer, useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { Context } from "store";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { NETWORK_SERVICE_ADVERTISING } from "const/widgetNameConst";
import DataReadonlyCard from "components/DataReadonlyCard";
import SavedInputPreview from "components/SavedInputPreview";
import SetupNetworkServiceAdvertisingDialog from "../SetupNetworkServiceAdvertisingDialog";
import { useLocalStore } from "mobx-react-lite";
import SavedInputIndicator from "components/SavedInputIndicator";

const NetworkServiceAdvertising = () => {
    const { t } = useTranslation();
    const {
        store: { netServiceAdvertisingStore, evoSettingsStore }
    } = useContext(Context);
    useEffect(() => {
        netServiceAdvertisingStore.fetchNetServiceAdvertising();
    }, []);
    const state = useLocalStore(() => ({
        isDialogOpen: false
    }));
    const openDialog = () => {
        state.isDialogOpen = true;
    };
    const closeDialog = () => {
        state.isDialogOpen = false;
    };
    return useObserver(() => (
        <>
            <DataReadonlyCard
                onClick={openDialog}
                headerDivider
                title={t("network.netServiceAdvertising.title")}
                control={
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={NETWORK_SERVICE_ADVERTISING} />
                    </MoreMenu>
                }
            >
                <Grid container direction={"column"} spacing={5}>
                    <Grid item container alignItems={"center"}>
                        <Grid xs={6} item>
                            <Typography>{t("network.netServiceAdvertising.label.hostName")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{netServiceAdvertisingStore.hostname}</SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"}>
                        <Grid xs={6} item>
                            <Typography>{t("network.netServiceAdvertising.label.description")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{evoSettingsStore.evoDescription}</SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"}>
                        <Grid xs={6} item>
                            <Typography>{t("network.netServiceAdvertising.label.wins")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={netServiceAdvertisingStore.winsEnabled} />
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"}>
                        <Grid xs={6} item>
                            <Typography>{t("network.netServiceAdvertising.label.avahi")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={netServiceAdvertisingStore.avahiEnabled} />
                        </Grid>
                    </Grid>
                </Grid>
            </DataReadonlyCard>
            <SetupNetworkServiceAdvertisingDialog open={state.isDialogOpen} closeDialog={closeDialog} />
        </>
    ));
};

export default observer(NetworkServiceAdvertising);
