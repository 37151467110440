import config from "config";
import { restApiPort } from "const";
import axios from "axios";
import { imTeapot } from "utils/imTeapot";
import { TOKEN_IS_INVALID } from "store/AuthStore/AuthStore";

const getAxiosConfig = ({ hasNoCacheHeader } = {}) => ({
    baseURL: `${config.protocol}://${config.path}:${restApiPort}/rest/1.0`,
    headers: hasNoCacheHeader
        ? {
              "Content-type": "application/json",
              evo_client_version: config.ui_version,
              "Cache-Control": "no-cache",
          }
        : {
              "Content-type": "application/json",
              evo_client_version: config.ui_version,
          },
    transformResponse: axios.defaults.transformResponse.concat((data) => {
        if (imTeapot(data?.code)) {
            window.location.reload();
        }

        return data;
    }),
});

const AxiosCommonRequest = axios.create(getAxiosConfig());
export const AxiosCommonRequestNoCache = axios.create(getAxiosConfig({ hasNoCacheHeader: true }));

export const AxiosSetAuthToken = () => {
    const token = localStorage.getItem("authToken");

    if (token) {
        AxiosCommonRequest.defaults.headers.common["Authorization"] = token;
        AxiosCommonRequestNoCache.defaults.headers.common["Authorization"] = token;
    }
};

export const getAxiosForMultipleEvo = (ip, options = {}, noCache) => {
    const instanceAxios = axios.create({
        baseURL: `https://${ip}:${restApiPort}/rest/1.0`,
        headers: {
            "Content-type": "application/json",
            evo_client_version: config.ui_version,
        },
    });

    const instanceAxiosNoCache = axios.create({
        baseURL: `https://${ip}:${restApiPort}/rest/1.0`,
        headers: {
            "Content-type": "application/json",
            "Cache-Control": "no-cache",
            evo_client_version: config.ui_version,
        },
    });

    return {
        ...(noCache ? instanceAxiosNoCache : instanceAxios),
        get: async function (url, config) {
            try {
                const res = await instanceAxios.get(url, config);
                return res;
            } catch (error) {
                if (options?.onError) {
                    options.onError(error);
                }

                throw error;
            }
        },
        setAuthToken() {
            const evos = JSON.parse(localStorage.getItem("multipleEvo"));
            const token = evos.find((evo) => evo.ip === ip).authToken;

            if (token) {
                instanceAxios.defaults.headers.common["Authorization"] = token;
                instanceAxiosNoCache.defaults.headers.common["Authorization"] = token;
            } else {
                instanceAxios.defaults.headers.common["Authorization"] = TOKEN_IS_INVALID;
                instanceAxiosNoCache.defaults.headers.common["Authorization"] = TOKEN_IS_INVALID;
            }
        },
    };
};

export default AxiosCommonRequest;
