import { types } from "mobx-state-tree";

import DriveInfo from "api/pool/Types/DriveInfo";

const Pool = types.model({
    pool: types.string,
    sizeFree: types.number,
    sizeTotal: types.number,
    raids: types.array(types.number),
    drives: types.array(DriveInfo)
});

export default Pool;
