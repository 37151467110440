import { types } from "mobx-state-tree";

import Request from "api/Request";
import SetAllowedHostsArguments from "api/security/acl/Types/SetAllowedHostsArguments";

const SetAllowedHosts = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Acl",
    method: "set_allowed_hosts",
    arguments: types.maybe(SetAllowedHostsArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetAllowedHosts
