import { types } from "mobx-state-tree";

import Notifications from "api/events/Types/Notifications";

const NotificationsResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(Notifications)
});

export default NotificationsResult;
