import React from "react";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TextInput from "components/MobxForm/TextInput";
import Select from "components/MobxForm/Select";
import { useLocalStore, useObserver } from "mobx-react";
import createFields from "./createFields";
import createForm from "utils/createForm";
import Dialog from "components/Dialog";
import {
    PROTOCOL_ARRAY,
    POLICY_ARRAY,
    ALL_PORTS,
    ALL_ADDRESSES,
    PORTS_LIST,
    PORTS_RANGE,
    SINGLE_HOST,
    SUBNET,
    HOST_RANGE,
    ALL_INTERFACES_VALUE
} from "const/firewallConst";
import Radio from "components/Radio";
import RadioGroup from "components/MobxForm/RadioGroup";
import { useStyles } from "./CreateEditRuleDialog.styles";
import isIpWithCidrRule from "utils/isIpWithCidrRule";
import isSingleIpHostRule from "utils/isSingleIpHostRule";
import portsListRule from "utils/portsListRule";
import portRule from "utils/portRule";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";

const CreateEditRuleDialog = ({ open, onClose, isCreate, onCloseDrawer, changeProgressState }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(),
            rules: { ...isIpWithCidrRule, ...isSingleIpHostRule, ...portsListRule, ...portRule }
        }),
        inProgress: false,
        isSubmitted: false
    }));

    const {
        store: { firewallStore }
    } = useStore();

    const unsavedChangesModal = useModal();

    const onEnter = () => {
        if (isCreate) {
            state.form.$("protocol").set("default", PROTOCOL_ARRAY[0].value);
            state.form.$("ports").set("default", ALL_PORTS.label);
            state.form.$("address").set("default", ALL_ADDRESSES.label);
            state.form.$("policy").set("default", POLICY_ARRAY[0].value);
            state.form.$("enabled").set("default", true);
        } else {
            const currentRule = firewallStore.currentRule || firewallStore.currentSingleCheckedRule;
            state.form.clear();
            state.form.$("protocol").set("default", currentRule.protocol);
            state.form.$("policy").set("default", currentRule.policy);
            state.form.$("enabled").set("default", currentRule.enabled);
            if (currentRule.portsRange?.length === 1) {
                if (currentRule.portsRange[0].start === ALL_PORTS.start && currentRule.portsRange[0].end === ALL_PORTS.end) {
                    state.form.$("ports").set("default", ALL_PORTS.label);
                }
                if (currentRule.portsRange[0].start === currentRule.portsRange[0].end) {
                    state.form.$("ports").set("default", PORTS_LIST);
                    state.form.$("portsList").set("default", currentRule.portsRange[0].start);
                } else if (
                    currentRule.portsRange[0].start !== currentRule.portsRange[0].end &&
                    (currentRule.portsRange[0].start !== ALL_PORTS.start || currentRule.portsRange[0].end !== ALL_PORTS.end)
                ) {
                    state.form.$("ports").set("default", PORTS_RANGE);
                    state.form.$("portRangeFrom").set("default", currentRule.portsRange[0].start);
                    state.form.$("portRangeTo").set("default", currentRule.portsRange[0].end);
                }
            } else {
                state.form.$("ports").set("default", PORTS_LIST);
                state.form.$("portsList").set("default", currentRule.portsRange.map(port => port.start).join(", "));
            }

            if (currentRule.addressesRange?.length === 1) {
                if (
                    currentRule.addressesRange[0].start === ALL_ADDRESSES.value &&
                    currentRule.addressesRange[0].end === ALL_ADDRESSES.value
                ) {
                    state.form.$("address").set("default", ALL_ADDRESSES.label);
                } else if (
                    currentRule.addressesRange[0].start === currentRule.addressesRange[0].end &&
                    currentRule.addressesRange[0].start !== ALL_ADDRESSES.value
                ) {
                    if (
                        currentRule.addressesRange[0].start.includes("/") ||
                        currentRule.addressesRange[0].start.slice(-1) === "0"
                    ) {
                        state.form.$("address").set("default", SUBNET);
                        state.form.$("addressSubnet").set("default", currentRule.addressesRange[0].start);
                    } else {
                        state.form.$("address").set("default", SINGLE_HOST);
                        state.form.$("addressSingle").set("default", currentRule.addressesRange[0].start);
                    }
                } else {
                    state.form.$("address").set("default", HOST_RANGE);
                    state.form.$("addressRangeFrom").set("default", currentRule.addressesRange[0].start);
                    state.form.$("addressRangeTo").set("default", currentRule.addressesRange[0].end);
                }
            }
        }
        state.form.reset();
        state.form.fields.forEach(field => field.resetValidation());
        unsavedChangesModal.close();
        state.isSubmitted = false;
    };

    const parsePort = () => {
        let portsRange = [];
        switch (state.form.$("ports").value) {
            case ALL_PORTS.label:
                portsRange.push({ start: ALL_PORTS.start, end: ALL_PORTS.end });
                break;
            case PORTS_LIST:
                state.form.$("portsList").value &&
                    state.form
                        .$("portsList")
                        .value.split(",")
                        .forEach(el => portsRange.push({ start: el.trim(), end: el.trim() }));
                break;
            case PORTS_RANGE:
                portsRange.push({ start: state.form.$("portRangeFrom").value, end: state.form.$("portRangeTo").value });
                break;
            default:
                return portsRange;
        }
        return portsRange;
    };

    const parseAddress = () => {
        let addressesRange = [];
        switch (state.form.$("address").value) {
            case ALL_ADDRESSES.label:
                addressesRange.push({ start: ALL_ADDRESSES.value, end: ALL_ADDRESSES.value });
                break;
            case SINGLE_HOST:
                addressesRange.push({ start: state.form.$("addressSingle").value, end: state.form.$("addressSingle").value });
                break;
            case SUBNET:
                addressesRange.push({ start: state.form.$("addressSubnet").value, end: state.form.$("addressSubnet").value });
                break;
            case HOST_RANGE:
                addressesRange.push({ start: state.form.$("addressRangeFrom").value, end: state.form.$("addressRangeTo").value });
                break;
            default:
                return addressesRange;
        }
        return addressesRange;
    };

    const onSubmit = async e => {
        state.form.onSubmit(e);
        definePortVariant();
        defineAddressVariant();

        if (!state.form.isValid) return;

        state.inProgress = true;
        state.isSubmitted = false;
        !isCreate && changeProgressState(true);

        let interfacesRules = firewallStore.interfacesRulesList;
        const rule = {
            protocol: state.form.$("protocol").value,
            policy: state.form.$("policy").value,
            enabled: state.form.$("enabled").value,
            portsRange: parsePort(),
            addressesRange: parseAddress()
        };
        const ifaceName = firewallStore.ifacesList.find(iface => iface.value === firewallStore.currentIfaceName) || {
            value: ALL_INTERFACES_VALUE
        };
        const ifaceIndex = interfacesRules.findIndex(el => el.iface === ifaceName.value) || 0;
        if (isCreate) {
            if (ifaceIndex >= 0) {
                interfacesRules[ifaceIndex].rules.push(rule);
            } else {
                interfacesRules.push({ iface: ifaceName.value, policy: POLICY_ARRAY[0].value, rules: [rule] });
            }
        } else {
            const ruleIndex = firewallStore.currentRuleByIfaceIndex || firewallStore.checkedRules[0];
            interfacesRules[ifaceIndex].rules.splice(ruleIndex, 1, rule);
        }

        const res = firewallStore.setFirewallRules({ interfacesRules: interfacesRules });
        state.inProgress = false;
        !isCreate && changeProgressState(false);
        if (res) {
            firewallStore.getRollbackTime();
            firewallStore.fetchFirewallRules();
            !isCreate && onCloseDrawer();
            state.isSubmitted = true;
        }
        return res;
    };

    const definePortVariant = () => {
        if (state.form.$("ports").value === ALL_PORTS.label) {
            state.form.$("portsList").resetValidation();
            state.form.$("portRangeFrom").resetValidation();
            state.form.$("portRangeTo").resetValidation();
        } else if (state.form.$("ports").value === PORTS_LIST) {
            state.form.$("portRangeFrom").resetValidation();
            state.form.$("portRangeTo").resetValidation();
        } else if (state.form.$("ports").value === PORTS_RANGE) {
            state.form.$("portsList").resetValidation();
        }
    };

    const defineAddressVariant = () => {
        if (state.form.$("address").value === ALL_ADDRESSES.label) {
            state.form.$("addressSingle").resetValidation();
            state.form.$("addressSubnet").resetValidation();
            state.form.$("addressRangeFrom").resetValidation();
            state.form.$("addressRangeTo").resetValidation();
        } else if (state.form.$("address").value === SINGLE_HOST) {
            state.form.$("addressSubnet").resetValidation();
            state.form.$("addressRangeFrom").resetValidation();
            state.form.$("addressRangeTo").resetValidation();
        } else if (state.form.$("address").value === SUBNET) {
            state.form.$("addressSingle").resetValidation();
            state.form.$("addressRangeFrom").resetValidation();
            state.form.$("addressRangeTo").resetValidation();
        } else if (state.form.$("address").value === HOST_RANGE) {
            state.form.$("addressSingle").resetValidation();
            state.form.$("addressSubnet").resetValidation();
        }
    };

    const onChangePorts = () => {
        switch (state.form.$("ports").value) {
            case ALL_PORTS.label: {
                state.form.$("portsList").clear();
                state.form.$("portRangeFrom").clear();
                state.form.$("portRangeTo").clear();
                break;
            }
            case PORTS_LIST: {
                state.form.$("portRangeFrom").clear();
                state.form.$("portRangeTo").clear();
                break;
            }
            case PORTS_RANGE: {
                state.form.$("portsList").clear();
                break;
            }
            default: {
                return;
            }
        }
    };

    const onChangeAddress = () => {
        switch (state.form.$("address").value) {
            case ALL_ADDRESSES.label: {
                state.form.$("addressSingle").clear();
                state.form.$("addressSubnet").clear();
                state.form.$("addressRangeFrom").clear();
                state.form.$("addressRangeTo").clear();
                break;
            }
            case SINGLE_HOST: {
                state.form.$("addressSubnet").clear();
                state.form.$("addressRangeFrom").clear();
                state.form.$("addressRangeTo").clear();
                break;
            }
            case SUBNET: {
                state.form.$("addressSingle").clear();
                state.form.$("addressRangeFrom").clear();
                state.form.$("addressRangeTo").clear();
                break;
            }
            case HOST_RANGE: {
                state.form.$("addressSingle").clear();
                state.form.$("addressSubnet").clear();
                break;
            }
            default: {
                return;
            }
        }
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            onClose();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            buttons={
                <Button variant={"contained"} color={"secondary"} onClick={onClose}>
                    {t("common.button.cancel")}
                </Button>
            }
            title={isCreate ? t("firewall.create_rule.title") : t("firewall.edit_rule.title")}
            open={open}
            submitBtnLabel={t("common.button.save")}
            onClose={closeIfNeeded}
            onSubmit={onSubmit}
            onEnter={onEnter}
            maxWidth={"md"}
        >
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
            <Grid container spacing={4} className={classes.paper}>
                <Grid container alignItems="center">
                    <Grid item xs={5}>
                        <Typography>{t("firewall.create_rule.protocol")}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Select className={classes.groupInput} field={state.form.$("protocol")} options={PROTOCOL_ARRAY} />
                    </Grid>
                </Grid>
                <Box py={4} className={classes.radioGroupContainer}>
                    <Grid item xs={5}>
                        <Typography>{t("firewall.create_rule.ports")}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <RadioGroup field={state.form.$("ports")} className={classes.radioGroup}>
                            <Radio
                                onChange={onChangePorts}
                                value={ALL_PORTS.label}
                                className={classes.radio}
                                label={t("firewall.create_rule.all")}
                            />
                            <Radio
                                onChange={onChangePorts}
                                value={PORTS_LIST}
                                className={classes.radio}
                                label={t("firewall.create_rule.ports.list")}
                            />
                            {state.form.$("ports").value === PORTS_LIST && (
                                <TextInput className={classes.groupInput} field={state.form.$("portsList")} />
                            )}
                            <Radio
                                onChange={onChangePorts}
                                value={PORTS_RANGE}
                                className={classes.radio}
                                label={t("firewall.create_rule.ports.range")}
                            />
                            {state.form.$("ports").value === PORTS_RANGE && (
                                <Grid item container wrap={"nowrap"} alignItems="center" justify="space-between">
                                    <Grid item xs={5}>
                                        <TextInput className={classes.groupInput} field={state.form.$("portRangeFrom")} />
                                    </Grid>
                                    &mdash;
                                    <Grid item xs={5}>
                                        <TextInput className={classes.groupInput} field={state.form.$("portRangeTo")} />
                                    </Grid>
                                </Grid>
                            )}
                        </RadioGroup>
                    </Grid>
                </Box>
                <Box py={4} className={classes.radioGroupContainer}>
                    <Grid item xs={5}>
                        <Typography>{t("firewall.create_rule.address")}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <RadioGroup field={state.form.$("address")} className={classes.radioGroup}>
                            <Radio
                                onChange={onChangeAddress}
                                value={ALL_ADDRESSES.label}
                                className={classes.radio}
                                label={t("firewall.create_rule.all")}
                            />
                            <Radio
                                onChange={onChangeAddress}
                                value={SINGLE_HOST}
                                className={classes.radio}
                                label={t("firewall.create_rule.address.single")}
                            />
                            {state.form.$("address").value === SINGLE_HOST && (
                                <TextInput className={classes.groupInput} field={state.form.$("addressSingle")} />
                            )}
                            <Radio
                                onChange={onChangeAddress}
                                value={SUBNET}
                                className={classes.radio}
                                label={t("firewall.create_rule.address.subnet")}
                            />
                            {state.form.$("address").value === SUBNET && (
                                <TextInput className={classes.groupInput} field={state.form.$("addressSubnet")} />
                            )}
                            <Radio
                                onChange={onChangeAddress}
                                value={HOST_RANGE}
                                className={classes.radio}
                                label={t("firewall.create_rule.address.range")}
                            />
                            {state.form.$("address").value === HOST_RANGE && (
                                <Grid item container wrap={"nowrap"} alignItems="center" justify="space-between">
                                    <Grid item xs={5}>
                                        <TextInput className={classes.groupInput} field={state.form.$("addressRangeFrom")} />
                                    </Grid>
                                    &mdash;
                                    <Grid item xs={5}>
                                        <TextInput className={classes.groupInput} field={state.form.$("addressRangeTo")} />
                                    </Grid>
                                </Grid>
                            )}
                        </RadioGroup>
                    </Grid>
                </Box>
                <Grid container alignItems="center">
                    <Grid item xs={5}>
                        <Typography>{t("firewall.create_rule.policy")}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Select className={classes.groupInput} field={state.form.$("policy")} options={POLICY_ARRAY} />
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default CreateEditRuleDialog;
