import { TCP, ALL_PORTS, ALL_ADDRESSES, ACTION_ACCEPT } from "const/firewallConst";
import i18n from "i18n";
import isFieldEmpty from "utils/isFieldEmptyRule";

const createFields = () => {
    return [
        { name: "protocol", default: TCP.label },
        { name: "ports", default: ALL_PORTS.label, type: "radio" },
        {
            name: "portsList",
            placeholder: i18n.t("firewall.create_rule.ports"),
            rules: "portsListValue",
            validators: [isFieldEmpty(i18n.t("rule.field.empty.common_field"))]
        },
        {
            name: "portRangeFrom",
            placeholder: i18n.t("firewall.create_rule.placeholder_from"),
            rules: "portValue",
            validators: [isFieldEmpty(i18n.t("rule.field.empty.common_field"))],
            output: value => (value.trim && value.trim() === "" ? value : Number(value))
        },
        {
            name: "portRangeTo",
            placeholder: i18n.t("firewall.create_rule.placeholder_to"),
            rules: "portValue",
            validators: [isFieldEmpty(i18n.t("rule.field.empty.common_field"))],
            output: value => (value.trim && value.trim() === "" ? value : Number(value))
        },
        {
            name: "address",
            default: ALL_ADDRESSES.label,
            type: "radio"
        },
        {
            name: "addressSingle",
            placeholder: i18n.t("firewall.create_rule.address.single.placeholder"),
            rules: "isSingleIpHost",
            validators: [isFieldEmpty(i18n.t("rule.field.empty.common_field"))]
        },
        {
            name: "addressSubnet",
            rules: "isIpWithCidr",
            placeholder: i18n.t("firewall.create_rule.address.subnet.placeholder"),
            validators: [isFieldEmpty(i18n.t("rule.field.empty.common_field"))]
        },
        {
            name: "addressRangeFrom",
            placeholder: i18n.t("firewall.create_rule.placeholder_from"),
            validators: [isFieldEmpty(i18n.t("rule.field.empty.common_field"))]
        },
        {
            name: "addressRangeTo",
            placeholder: i18n.t("firewall.create_rule.placeholder_to"),
            validators: [isFieldEmpty(i18n.t("rule.field.empty.common_field"))]
        },
        { name: "policy", default: ACTION_ACCEPT.label },
        { name: "enabled" }
    ];
};

export default createFields;
