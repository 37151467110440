import { cast, clone, flow, getParent, types } from "mobx-state-tree";
import NetInterfacesType from "api/net/Responses/IfacesArrayResult";
import GetNetIfaces from "api/net/Requests/GetNetIfaces";
import SaveNetIfaceSettings from "api/net/Requests/SaveNetIfaceSettings";
import MainSocket from "websocket";
import AllIfacesResult from "api/net/Responses/AllIfacesResult";
import GetAllIfaces from "api/net/Requests/GetAllIfaces";
import RenewDhcpLease from "api/net/Requests/RenewDhcpLease";
import IdentifyIface from "api/net/Requests/IdentifyIface";
import stableSort from "utils/stableSort";
import getComparator from "utils/getComparator";
import { ASC, DESC, ETHERNET_PORT_PORT } from "const/sortColumnConst";
import { CODE_DISRUPTION_SESSIONS } from "const/errorConst";
import { DHCP_CLIENT_ROLE, DHCP_SERVER_ROLE, STATIC_IP_ROLE } from "const/rolesConst";
import SaveNetMetrics from "api/net/Requests/SaveNetMetrics";
import NetInterface from "api/net/Types/NetInterface";
import { toJS } from "mobx";
import NetBond from "api/net/Types/NetBond";
import NetBridge from "api/net/Types/NetBridge";
import RenameIfaces from "api/net/Requests/RenameIfaces";
import { isEthernetPortUsedByBridgeOrBond } from "utils/isEthernetPortUsedByBridgeOrBond";

const EthernetPortsStore = types
    .model({
        ethernetPorts: types.maybe(NetInterfacesType),
        currentPortName: types.maybe(types.string),
        allIfacesResult: types.maybe(AllIfacesResult),
        allIfacesResultCopy: types.maybe(types.array(types.union(NetInterface, NetBond, NetBridge))),
        ethernetPortsCopy: types.maybe(types.array(NetInterface)),
    })
    .volatile(() => ({
        orderBy: ETHERNET_PORT_PORT,
        order: ASC,
        __ethernetPorts: [],
    }))
    .views((self) => ({
        get ports() {
            return self.ethernetPorts?.data || [];
        },
        get shadowPortsInstant() {
            return (
                self.sortedPorts
                    ?.filter((port) => !isEthernetPortUsedByBridgeOrBond(port, this))
                    .filter((port) => !port.builtin) ?? []
            );
        },
        get shadowPorts() {
            return (
                self.ethernetPortsCopy
                    ?.filter((port) => !isEthernetPortUsedByBridgeOrBond(port, this))
                    .filter((port) => !port.builtin) ?? []
            );
        },
        get sortedPorts() {
            return stableSort(self.ports, getComparator(self.order, self.orderBy));
        },
        get currentPort() {
            return this.ports.find((iface) => iface.port === self.currentPortName);
        },
        get roleOfCurrentPort() {
            if (!this.currentPort || this.isEthernetPortUsedByBridgeOrBond) {
                return "";
            }
            if (this.currentPort.dhcpServer.enabled) {
                return DHCP_SERVER_ROLE;
            }
            if (this.currentPort.dhcpClient.enabled) {
                return DHCP_CLIENT_ROLE;
            }
            return STATIC_IP_ROLE;
        },
        get isDhcpClientRole() {
            return self.roleOfCurrentPort === DHCP_CLIENT_ROLE;
        },
        get isDhcpServerRole() {
            return self.roleOfCurrentPort === DHCP_SERVER_ROLE;
        },
        get isStaticIpRole() {
            return self.roleOfCurrentPort === STATIC_IP_ROLE;
        },
        get allNetIfaces() {
            return self.allIfacesResult?.data || { ifaces: [], bonds: [], bridges: [] };
        },
        get shadowIfaces() {
            return self.allIfacesResultCopy || [];
        },
        get allCombinedNetIfaces() {
            const { ifaces, bonds, bridges } = this.allNetIfaces;
            return ifaces
                .filter(
                    (iface) =>
                        !bonds.some((bond) => bond.interfaces.includes(iface.port)) &&
                        !bridges.some((bridge) => bridge.interfaces.includes(iface.port))
                )
                .concat(bonds)
                .concat(bridges);
        },
        get sortedByMetricIfaces() {
            return stableSort(this.allCombinedNetIfaces, getComparator(ASC, "metric"));
        },
        get isEthernetPortUsedByBridgeOrBond() {
            return isEthernetPortUsedByBridgeOrBond(this.currentPort, this);
        },
        get netTestInterfacesValues() {
            return this.sortedPorts?.map((port) => {
                return { label: `${port.port} ( ${port.ip} / ${port.mac} )`, value: port.port };
            });
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
    }))
    .actions((self) => ({
        fetchAllNetRequests: () => {
            const { bondsStore, bridgeStore } = getParent(self);
            // todo change to immediate  request
            const REQUEST_TIME_OUT = 2_000;
            setTimeout(() => {
                self.fetchEthernetPorts();
                self.fetchAllIfaces();

                setTimeout(() => {
                    bondsStore.fetchBondsArrayResult();
                    bridgeStore.fetchBridgesArrayResult();
                }, 3_000);
            }, REQUEST_TIME_OUT);
        },
        fetchEthernetPorts: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetNetIfaces.create().init();
                const res = yield self.socket.send(req);
                self.ethernetPorts = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchAllIfaces: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetAllIfaces.create().init();
                const res = yield self.socket.send(req);
                self.allIfacesResult = res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        saveNetIfaceSettings: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SaveNetIfaceSettings.create().init(data);
                const resp = yield self.socket.send(req);
                Object.keys(self.currentPort).forEach((key) => {
                    if (data[key]) {
                        self.currentPort[key] = data[key];
                    }
                });
                return resp;
            } catch (e) {
                if (e.code === CODE_DISRUPTION_SESSIONS) {
                    throw new Error(e.message);
                }
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        saveNetMetrics: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SaveNetMetrics.create().init(data);
                return yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        renewDhcpLease: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RenewDhcpLease.create().init(data);
                yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        identifyIface: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = IdentifyIface.create().init(data);
                yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setCurrentPort: function (name) {
            self.currentPortName = self.currentPortName === name ? "" : name;
        },
        changeSorting(column) {
            if (column === self.orderBy) {
                self.order = self.order === DESC ? ASC : DESC;
            } else {
                self.order = ASC;
                self.orderBy = column;
            }
        },
        changeSortingDialog(column) {
            if (column === self.orderByDialog) {
                self.orderDialog = self.orderDialog === DESC ? ASC : DESC;
            } else {
                self.orderDialog = ASC;
                self.orderByDialog = column;
            }
        },
        resetShadowIfaces() {
            self.allIfacesResultCopy = self.sortedByMetricIfaces.map((i) => clone(i));
        },
        moveUp(port) {
            const arrayCopy = toJS(self.shadowIfaces);
            const index = arrayCopy.findIndex((iface) => iface.port === port);
            // swap elements and its metrics
            [arrayCopy[index - 1].metric, arrayCopy[index].metric] = [arrayCopy[index].metric, arrayCopy[index - 1].metric];
            [arrayCopy[index - 1], arrayCopy[index]] = [arrayCopy[index], arrayCopy[index - 1]];
            self.allIfacesResultCopy = arrayCopy;
        },
        moveDown(port) {
            const arrayCopy = toJS(self.shadowIfaces);
            const index = arrayCopy.findIndex((iface) => iface.port === port);
            // swap elements and its metrics
            [arrayCopy[index + 1].metric, arrayCopy[index].metric] = [arrayCopy[index].metric, arrayCopy[index + 1].metric];
            [arrayCopy[index + 1], arrayCopy[index]] = [arrayCopy[index], arrayCopy[index + 1]];
            self.allIfacesResultCopy = arrayCopy;
        },
        updateInterfaceInStore: (netInterface) => {
            if (self.ethernetPorts?.data) {
                const index = self.ethernetPorts.data.findIndex((el) => el.port === netInterface.port);
                if (index < 0) {
                    self.ethernetPorts.data.push(netInterface);
                    const sortedPortIndex = self.sortedPorts.findIndex((el) => el.port === netInterface.port);
                    self.ethernetPortsCopy = [
                        ...self.ethernetPortsCopy.slice(0, sortedPortIndex),
                        netInterface,
                        ...self.ethernetPortsCopy.slice(sortedPortIndex, self.ethernetPortsCopy.length),
                    ];
                } else {
                    self.ethernetPorts.data = [
                        ...self.ethernetPorts.data.slice(0, index),
                        netInterface,
                        ...self.ethernetPorts.data.slice(index + 1, self.ethernetPorts.data.length),
                    ];
                    const ethernetPortCopyIndex = self.ethernetPortsCopy.findIndex((el) => el.port === netInterface.port);
                    self.ethernetPortsCopy = [
                        ...self.ethernetPortsCopy.slice(0, ethernetPortCopyIndex),
                        netInterface,
                        ...self.ethernetPortsCopy.slice(ethernetPortCopyIndex + 1, self.ethernetPortsCopy.length),
                    ];
                }
            }
            if (self.allIfacesResult?.data) {
                const index = self.allIfacesResult.data.ifaces.findIndex((el) => el.port === netInterface.port);
                if (index < 0) {
                    self.allIfacesResult.data.ifaces.push(netInterface);
                } else {
                    self.allIfacesResult.data.ifaces = [
                        ...self.allIfacesResult.data.ifaces.slice(0, index),
                        netInterface,
                        ...self.allIfacesResult.data.ifaces.slice(index + 1, self.allIfacesResult.data.ifaces.length),
                    ];
                }
                self.resetShadowIfaces();
            }
        },
        updateBridgeInAllIfacesResult: (netBridge) => {
            if (self.allIfacesResult?.data) {
                const index = self.allIfacesResult.data.bridges.findIndex((el) => el.port === netBridge.port);
                if (index < 0) {
                    self.allIfacesResult.data.bridges.push(netBridge);
                } else {
                    self.allIfacesResult.data.bridges = [
                        ...self.allIfacesResult.data.bridges.slice(0, index),
                        netBridge,
                        ...self.allIfacesResult.data.bridges.slice(index + 1, self.allIfacesResult.data.ifaces.length),
                    ];
                }
                self.resetShadowIfaces();
            }
        },
        updateBondInAllIfacesResult: (netBond) => {
            if (self.allIfacesResult?.data) {
                const index = self.allIfacesResult.data.bonds.findIndex((el) => el.port === netBond.port);
                if (index < 0) {
                    self.allIfacesResult.data.bonds.push(netBond);
                } else {
                    self.allIfacesResult.data.bonds = [
                        ...self.allIfacesResult.data.bonds.slice(0, index),
                        netBond,
                        ...self.allIfacesResult.data.bonds.slice(index + 1, self.allIfacesResult.data.ifaces.length),
                    ];
                }
                self.resetShadowIfaces();
            }
        },
        removeInterfaceFromStore: (removedInterface) => {
            if (self.ethernetPorts?.data?.length !== 0) {
                self.ethernetPorts.data = self.ethernetPorts.data.filter((iface) => iface.port !== removedInterface.port);
            }
            if (self.ethernetPortsCopy.length !== 0) {
                self.ethernetPortsCopy = self.ethernetPortsCopy.filter((iface) => iface.port !== removedInterface.port);
            }
            if (self.allIfacesResult?.data?.length !== 0) {
                self.allIfacesResult.data.ifaces = self.allIfacesResult.data.ifaces.filter(
                    (iface) => iface.port !== removedInterface.port
                );
                self.resetShadowIfaces();
            }
        },
        removeBridgeinAllIfacesResult: (removedBridge) => {
            if (self.allIfacesResult?.data?.length !== 0) {
                self.allIfacesResult.data.bridges = self.allIfacesResult.data.bridges.filter(
                    (iface) => iface.port !== removedBridge.port
                );
                self.resetShadowIfaces();
            }
        },
        removeBondinAllIfacesResult: (removedBond) => {
            if (self.allIfacesResult?.data?.length !== 0) {
                self.allIfacesResult.data.bonds = self.allIfacesResult.data.bonds.filter(
                    (iface) => iface.port !== removedBond.port
                );
                self.resetShadowIfaces();
            }
        },
        changePortOrder(dragIndex, hoverIndex) {
            const dragItemIndex = self.ethernetPortsCopy.findIndex((port) => port.port === self.shadowPorts[dragIndex].port);
            const hoverItemIndex = self.ethernetPortsCopy.findIndex((port) => port.port === self.shadowPorts[hoverIndex].port);
            const dragItem = self.ethernetPortsCopy[dragItemIndex];

            self.ethernetPortsCopy = cast(
                dragItemIndex > hoverItemIndex
                    ? [
                          ...self.ethernetPortsCopy.slice(0, hoverItemIndex),
                          dragItem,
                          ...self.ethernetPortsCopy.slice(hoverItemIndex, dragItemIndex),
                          ...self.ethernetPortsCopy.slice(dragItemIndex + 1, self.ethernetPortsCopy.length),
                      ]
                    : [
                          ...self.ethernetPortsCopy.slice(0, dragItemIndex),
                          ...self.ethernetPortsCopy.slice(dragItemIndex + 1, hoverItemIndex + 1),
                          dragItem,
                          ...self.ethernetPortsCopy.slice(hoverItemIndex + 1, self.ethernetPortsCopy.length),
                      ]
            );
        },
        resetPortsToDefault() {
            self.ethernetPortsCopy = self.sortedPorts.map((i) => clone(i));
        },
    }))
    .actions((self) => ({
        renameIfaces: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const data = self.shadowPorts.map((el, idx) => {
                    const newPort = self.shadowPortsInstant[idx];

                    return {
                        oldPort: el.port,
                        mac: el.mac,
                        newPort: newPort.port,
                    };
                });

                const req = RenameIfaces.create().init({
                    renames: data,
                });

                yield self.socket.send(req);

                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }

            return null;
        }),
    }));

export default EthernetPortsStore;
