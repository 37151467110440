import { types } from "mobx-state-tree";

const CreateJobScheduleType = types.model({
    schedule: types.maybeNull(
        types.model({
            minutes: types.maybe(types.number),
            date: types.maybe(types.string),
            not_before: types.maybe(types.string),
            not_after: types.maybe(types.string),
            duration: types.maybe(types.number)
        })
    )
});

export default CreateJobScheduleType;
