import React from "react";
import { useStyles } from "./EmptyCardPlaceholder.styles";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { useObserver } from "mobx-react";

const EmptyCardPlaceholder = ({ message, inProgress, inProgressMessage, hasStaticHeight, ...rest }) => {
    const classes = useStyles({ hasStaticHeight });

    return useObserver(() => (
        <Grid
            container
            alignItems={"center"}
            justify={"center"}
            direction={"column"}
            className={classes.placeholderContainer}
            {...rest}
        >
            <Grid item>
                {inProgress ? <CircularProgress size={20} /> : <Typography className={classes.text}>{message}</Typography>}
            </Grid>
            {inProgress && inProgressMessage && (
                <Grid item>
                    <Typography className={classes.text}>{inProgressMessage}</Typography>
                </Grid>
            )}
        </Grid>
    ));
};

export default EmptyCardPlaceholder;
