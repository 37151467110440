import { types } from "mobx-state-tree";

import BatchEvent from "api/event/Types/BatchEvent";

const BatchEventsPage = types.model({
    data: types.array(BatchEvent),
    total: types.number
});

export default BatchEventsPage;
