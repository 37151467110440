import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { observer, useLocalStore } from "mobx-react";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@material-ui/core";
import { useStyles } from "./AssignUsersOrGroupsModal.styles";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import TableInlineControl from "./components/TableInlineControl";
import UsersPageBody from "./components/UsersPageBody";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { URL_SHARES_REMOTE_TAB } from "routes";
import { LOCAL_USERS, SEARCH_DEFAULTS } from "const/userSearchVariants";
import useStoreByIp from "hooks/useStoreByIp";
import { useModal } from "hooks/useModal";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const ASSIGN_USERS_OR_GROUPS_MODAL_ID_PREFIX = `${SHARES_PAGE_ID_PREFIX}_assign_users_or_groups_modal`;

const AssignUsersOrGroupsModal = observer(({ open, onClose, openCustom, ip, afterSubmitCallback }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { pathname } = useLocation();
    const [isFirstOpen, toggleIsFirstOpen] = useState(false);

    useEffect(() => {
        if (pathname === URL_SHARES_REMOTE_TAB) {
            onClose();
        }
    }, [pathname]);

    const unsavedChangesModal = useModal();

    const state = useLocalStore(() => ({
        inProgress: false,
        isSubmitted: false,
        searchString: "",
        setSearchString(search) {
            this.searchString = search;
        },
        resetSearchString() {
            this.searchString = "";
        },
    }));

    const { aclStore, userStore, shareStore } = useStoreByIp({ ip });

    const closeModalWithResetState = () => {
        onClose();
        afterSubmitCallback();
        toggleIsFirstOpen(false);
        state.inProgress = false;
        state.isSubmitted = false;
        unsavedChangesModal.close();
        userStore.setLastSearch(undefined);
        userStore.resetCurrentSearchRequest();
        state.resetSearchString();
    };

    const shareName = shareStore.currentShareName || shareStore.currentCheckedShareName || aclStore.currentShareName;
    const shareNameWithPrefix =
        shareStore.currentShareNameWithPrefix ||
        shareStore.currentCheckedShareNameWithPrefix ||
        aclStore.currentShareNameWithPrefix;

    const onSave = async () => {
        state.inProgress = true;

        const res = await aclStore.modifyUsersOrGroupsAccess({
            shareName,
            permissions: userStore.getUserAccessResult.data.data,
        });

        res && (state.isSubmitted = true);
        state.inProgress = false;
        if (!state.isSubmitted) {
            return;
        }

        setTimeout(closeModalWithResetState, 1000);
    };

    const closeIfNeeded = (isSubmitted) => {
        if (!userStore.hasAccessChanges || isSubmitted === true) {
            closeModalWithResetState();
            return;
        }
        unsavedChangesModal.open();
    };

    const onConfirmUnsavedChanges = () => {
        closeModalWithResetState();
        userStore.resetUserAccessChanges();
    };

    useEffect(() => {
        if (userStore.searchResult === null) {
            userStore.resetCurrentSearchRequest();
            userStore.searchOnUsersPage();
        } else {
            if (userStore.lastSearchShare) {
                userStore.applyLastSearchToRequest();
                userStore.searchOnUsersPage();
            }
        }
    }, [ip]);

    const onEnter = () => {
        userStore.updateCurrentSearchRequest("name", "");
    };

    const onSearch = async () => {
        state.isSearchInProgress = true;
        userStore.updateCurrentSearchRequest("name", state.searchString);
        userStore.updateCurrentSearchRequest("limit", SEARCH_DEFAULTS.limit);
        userStore.updateCurrentSearchRequest("page", SEARCH_DEFAULTS.page);
        await userStore.searchOnAssignShares({ shareName });
        state.isSearchInProgress = false;
    };

    useEffect(() => {
        isFirstOpen && shareName && onSearch();
    }, [isFirstOpen, shareName]);

    useEffect(() => {
        open && toggleIsFirstOpen(true);
        if (shareName && isFirstOpen) userStore.searchOnAssignShares({ shareName });
    }, [open]);

    useEffect(() => {
        userStore.updateCurrentSearchRequest("type", LOCAL_USERS);
    }, []);

    return (
        <Dialog
            className={classes.dialog}
            maxWidth={"xl"}
            open={open}
            onClose={closeIfNeeded}
            withoutPaddings
            title={t("share.shared_pool_card.modal.assign_users_or_groups.title", { shareName: shareNameWithPrefix })}
            onEnter={onEnter}
            submitBtn={
                userStore.getUserAccessResult?.data?.data?.length ? (
                    <AnimatedSubmitButton
                        label={t("common.button.save")}
                        disabled={state.inProgress}
                        isSubmitted={state.isSubmitted}
                        submit={onSave}
                        inProgress={state.inProgress}
                        id={`${ASSIGN_USERS_OR_GROUPS_MODAL_ID_PREFIX}_save`}
                    />
                ) : (
                    <Button
                        onClick={closeModalWithResetState}
                        variant={"contained"}
                        color={"primary"}
                        id={`${ASSIGN_USERS_OR_GROUPS_MODAL_ID_PREFIX}_close`}
                    >
                        {t("common.button.close")}
                    </Button>
                )
            }
            buttons={
                !!userStore.getUserAccessResult?.data?.data?.length && (
                    <Button
                        disabled={state.inProgress}
                        variant={"contained"}
                        color={"secondary"}
                        onClick={onConfirmUnsavedChanges}
                        id={`${ASSIGN_USERS_OR_GROUPS_MODAL_ID_PREFIX}_cancel`}
                    >
                        {t("common.button.cancel")}
                    </Button>
                )
            }
        >
            <Grid container direction={"column"} wrap={"nowrap"}>
                <Grid item>
                    <TableInlineControl
                        ip={ip}
                        search={onSearch}
                        searchString={state.searchString}
                        resetSearchString={state.resetSearchString}
                        setSearchString={state.setSearchString}
                    />
                </Grid>
                <Grid item className={classes.resultContainer}>
                    <UsersPageBody ip={ip} openCustom={openCustom} onSave={onSave} isEmpty={userStore.searchResult === null} />
                </Grid>
            </Grid>
            <UnsavedChangesDialog
                open={unsavedChangesModal.isOpen}
                onClose={unsavedChangesModal.close}
                onConfirm={onConfirmUnsavedChanges}
            />
        </Dialog>
    );
});

export { AssignUsersOrGroupsModal };
