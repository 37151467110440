import { types, flow, getParent } from "mobx-state-tree";
import * as d3 from "d3";

import { KiB_S, Bi_S } from "const/speedConst";
import { CHARTS, timeRegExp, DEFAULT_START_PERIOD, DEFAULT_POINTS_COUNT } from "const/chartConst";
import { multipleAxios } from "utils/multipleAxios";
import { HTTPS } from "const";
import i18next from "i18next";
import store from "store";

const FETCH_INTERVAL = 5000;

const NetworkGraphStore = types
    .model({
        chart: types.array(types.array(types.number)),
    })
    .volatile(() => ({
        intervalID: 0,
        graphCount: 0,
    }))
    .views((self) => ({
        // chartInfo need to be ascending sorted by time
        get chartInfo() {
            return self.chart
                .slice()
                .sort((a, b) => a[0] - b[0])
                .map((d) => ({
                    date: store.timeSettingsStore && store.timeSettingsStore.dateWithTimeZone(d[0]),
                    valueIn: -d[1] ? parseFloat((-d[1] / KiB_S.value / Bi_S.value).toFixed(2)) : 0,
                    valueOut: d.length > 2 && -d[2] ? parseFloat((-d[2] / KiB_S.value / Bi_S.value).toFixed(2)) : 0,
                }));
        },
        get lastTime() {
            return self.chartInfo[self.chartInfo.length - 1]
                ? d3.timeFormat(timeRegExp)(self.chartInfo[self.chartInfo.length - 1].date)
                : "";
        },
        get lastInValue() {
            return !self.chartInfo[self.chartInfo.length - 1] || !self.chartInfo[self.chartInfo.length - 1].valueIn
                ? 0
                : self.chartInfo[self.chartInfo.length - 1].valueIn;
        },
        get lastOutValue() {
            return !self.chartInfo[self.chartInfo.length - 1] || !self.chartInfo[self.chartInfo.length - 1].valueOut
                ? 0
                : self.chartInfo[self.chartInfo.length - 1].valueOut;
        },
    }))
    .actions((self) => ({
        fetchData: function (ifaceName, force) {
            if (!force && self.graphCount > 1) return;
            self.fetch(ifaceName);
        },
        fetch: function (ifaceName) {
            if (ifaceName === "All") return; // TODO implement all ifaces graph

            const iName = ifaceName.replace(/vs/i, "br"); // needed replacement for netdata
            self.fetchChart(iName);
        },
        fetchChart: flow(function* (ifaceName) {
            const { processingStore, uiStore, ip } = getParent(self);
            const after = uiStore.parameters?.graphStartPeriod || DEFAULT_START_PERIOD;
            const points = Math.round(DEFAULT_POINTS_COUNT * (DEFAULT_START_PERIOD / after));
            try {
                processingStore.setLoading(true);

                const resp = yield multipleAxios({
                    path: ip,
                    chart: `${CHARTS.NET}.${ifaceName}`,
                    points,
                    after: -after,
                    protocol: ip ? HTTPS : undefined,
                });

                self.chart.replace(resp.data?.result?.data || []);
                if (!self.intervalID) {
                    self.intervalID = setInterval(self.fetchChart, FETCH_INTERVAL, ifaceName);
                }
            } catch (e) {
                self.stopFetching(true);
                processingStore.setError(i18next.t("multiple_evo.netdata.load_error"));
            } finally {
                processingStore.setLoading(false);
            }
        }),
        stopFetching: function (force) {
            if (!force && self.graphCount > 0) return;
            clearTimeout(self.intervalID);
            self.intervalID = null;
        },
        clear: function () {
            self.chart.clear();
        },
        registerGraph: function () {
            self.graphCount++;
        },
        unregisterGraph: function () {
            self.graphCount--;
        },

        beforeDestroy: function () {
            self.stopFetching(true);
        },
    }));

export default NetworkGraphStore;
