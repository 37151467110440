import Request from "api/Request";

const DownloadEvoMibFile = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Events",
    method: "download_evo_mib_file"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default DownloadEvoMibFile;
