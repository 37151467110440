import { types } from "mobx-state-tree";

import DiskTestShareResult from "api/troubleshooting/Types/DiskTestShareResult";

const DiskTestStatusData = types.model({
    startTime: types.string,
    stopTime: types.string,
    status: types.string,
    result: types.array(DiskTestShareResult)
});

export default DiskTestStatusData;
