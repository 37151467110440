import { useStore } from "./useStore";

const { useState } = require("react");

/**
 * Hook for managing the state of values in the local storage.
 * @param {string} key - key by which the value is stored in the local storage.
 * @param {*} initialValue - the initial value if there is no value in the local storage by the key.
 * @returns {[any, (value: string | number | any[] | (value: any) => any) => void]} returns a cartege, where the first element is the state of the value from the local storage, and the second element is the function to change the state of the value from the local storage.
 */
export function useLocalStorage(key, initialValue) {
    const {
        store: { processingStore }
    } = useStore();

    const [localStorageValue, setLocalStorageValue] = useState(() => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            processingStore.setError(error);
            return initialValue;
        }
    });

    /**
     * Function to set a new value in the local storage.
     * @description if you pass a value other than the function, it will replace the current value in the local storage and be the new state. If you pass a function, it takes the current state of the value and the result of the function will be written to the local storage as the new state.
     * @param {string|number|any[]|(value:any)=>any} value - the new state of the value to be placed in the local storage. Or a function that takes the current state of the value and the result of this function should be placed in the local storage.
     */
    const setValue = value => {
        try {
            const valueToStore = value instanceof Function ? value(localStorageValue) : value;

            setLocalStorageValue(valueToStore);
            localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            processingStore.setError(error);
        }
    };

    return [localStorageValue, setValue];
}
