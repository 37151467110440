import React, { useContext, useCallback, useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import DataCard from "components/DataCard";
import MoreMenu from "components/MoreMenu";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { ADVANCED_MODE_FIREWALL } from "const/widgetNameConst";
import { MenuItem, Typography, Button, Grid, Box } from "@material-ui/core";
import ImportRulesModal from "../ImportRulesModal";
import CheckThinIcon from "assets/CheckThinIcon";
import CloseIcon from "assets/CloseIcon";
import { CLIENT_DATE_FORMAT, SERVER_DATE_FORMAT_EN } from "const";
import moment from "moment";
import { useModal } from "hooks/useModal";
import ConfirmationDialog from "components/ConfirmationDialog";
import DestructiveMenuItem from "components/DestructiveMenuItem/DestructiveMenuItem";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const AdvancedModeCard = () => {
    const { t } = useTranslation();
    const {
        store: { firewallStore },
    } = useContext(Context);

    useEffect(() => {
        firewallStore.getRollbackTime();
        firewallStore.fetchFirewallAdvancedInfo();
    }, []);

    const state = useLocalStore(() => ({
        isImportRulesModalOpen: false,
    }));

    const resetRulesModal = useModal();

    const exportFirewallRules = useCallback(() => {
        firewallStore.getRules();
    });

    const openImportRulesModal = useCallback(() => {
        state.isImportRulesModalOpen = true;
    });
    const closeImportRulesModal = useCallback(() => {
        state.isImportRulesModalOpen = false;
    });

    const resetRules = useCallback(() => {
        firewallStore.resetFirewallRules();
        resetRulesModal.close();
    });

    const commitFirewallRules = useCallback(() => {
        firewallStore.commitFirewallRules();
    });

    const rollbackFirewallRules = useCallback(() => {
        firewallStore.rollbackFirewallRules();
    });

    const makeContent = () => {
        const timeStr = firewallStore.firewallAdvancedInfo?.appliedTimeStr || "";
        if (timeStr === "") {
            return <Typography>{t("firewall.advanced_mode.content.not_imported")}</Typography>;
        }
        return (
            <Typography>
                {t("firewall.advanced_mode.content", {
                    time: moment(timeStr, SERVER_DATE_FORMAT_EN).format(getLanguageDateFormat(CLIENT_DATE_FORMAT)),
                })}
            </Typography>
        );
    };

    return useObserver(() => (
        <>
            <DataCard
                title={t("firewall.title")}
                autoHeight
                headerDivider
                headerControl={
                    <MoreMenu>
                        <HomeCardActionMenuItem widget={ADVANCED_MODE_FIREWALL} />
                        <MenuItem onClick={exportFirewallRules}>{t("firewall.header_menu.export")}</MenuItem>
                        <MenuItem onClick={openImportRulesModal}>{t("firewall.header_menu.import")}</MenuItem>
                        <DestructiveMenuItem onClick={resetRulesModal.open}>
                            {t("firewall.header_menu.reset")}
                        </DestructiveMenuItem>
                    </MoreMenu>
                }
                headerContent={
                    <Grid container justify={"space-between"} alignItems={"center"} wrap={"nowrap"} spacing={6}>
                        <Grid item>
                            <Typography variant={"h2"}>{t("firewall.title")}</Typography>
                        </Grid>
                        {!!firewallStore.rollbackTime && (
                            <Grid item container justify={"flex-end"} alignItems={"center"} wrap={"nowrap"} spacing={6}>
                                <Grid item>
                                    <Typography>
                                        {t("firewall.rules.commit_header", { sec: firewallStore.rollbackTime })}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={rollbackFirewallRules}
                                        variant={"contained"}
                                        color={"secondary"}
                                        startIcon={<CloseIcon />}
                                    >
                                        {t("firewall.rules.commit_header.rollback_button")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={commitFirewallRules}
                                        variant={"contained"}
                                        color={"primary"}
                                        startIcon={<CheckThinIcon />}
                                    >
                                        {t("firewall.rules.commit_header.commit_button")}
                                    </Button>
                                </Grid>
                                <Box pr={6} />
                            </Grid>
                        )}
                    </Grid>
                }
            >
                {makeContent()}
            </DataCard>
            <ConfirmationDialog
                title={t("common.warning_dialog.title")}
                open={resetRulesModal.isOpen}
                onClose={resetRulesModal.close}
                onConfirm={resetRules}
                confirmLabel={t("common.button.proceed")}
                cancelLabel={t("common.button.cancel")}
                fullWidth={true}
            >
                {t("firewall.rules.confirm_reset")}
            </ConfirmationDialog>
            <ImportRulesModal open={state.isImportRulesModalOpen} onClose={closeImportRulesModal} />
        </>
    ));
};

export default AdvancedModeCard;
