import { types } from "mobx-state-tree";

import Request from "api/Request";
import DiskTestArguments from "api/troubleshooting/Types/DiskTestArguments";

const StartDiskSelfTest = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Troubleshooting",
    method: "start_disk_self_test",
    arguments: types.maybe(DiskTestArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default StartDiskSelfTest;
