import i18n from "i18n";

const diskRule = {
    diskName: {
        function: value => {
            return /^\w[\w.]*$/.test(value);
        },
        message: i18n.t("rule.disk.name")
    }
};

export default diskRule;
