import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    tableHeadLabel: {
        "& .MuiTypography-body1": {
            fontWeight: 500,
            opacity: 1
        },
        "& .MuiFormControlLabel-root": {
            marginLeft: 0
        },
        "&.MuiTableCell-sizeSmall:last-child": {
            paddingLeft: theme.spacing(7)
        }
    }
}));
