import { types } from "mobx-state-tree";

import EnclosureData from "api/drive/Types/EnclosureData";

const QuadsStatusResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(EnclosureData)
});

export default QuadsStatusResult;
