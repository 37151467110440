export const ASC = "asc";
export const DESC = "desc";

export const FREE_DRIVES_NAME = "driveName";
export const FREE_DRIVES_MODEL = "driveModel";
export const FREE_DRIVES_SIZE = "size";

export const SHARE_NAME = "shareName";
export const SHARE_USAGE = "shareType";
export const SHARE_USED = "shareUsed";
export const SHARE_STATUS = "nestedStatus";

export const EXTERNAL_SHARE_NAME = "name";
export const EXTERNAL_SHARE_HOST = "host";
export const EXTERNAL_SHARE_USED = "used";
export const EXTERNAL_SHARE_STATUS = "status";

export const ACTIVATED_KEYS_ID = "key";
export const ACTIVATED_KEYS_TYPE = "type";

export const ACTIVATION_IFACES_PORT = "port";
export const ACTIVATION_IFACES_ACTIVATION = "activated";
export const ACTIVATION_IFACES_MAC = "mac";
export const ACTIVATION_IFACES_TYPE = "type";
export const ACTIVATION_IFACES_STATUS = "exists";

export const DISKS_VOLUME_NAME = "volumeName";
export const DISKS_RAID = "raid";
export const DISKS_SIZE = "size";
export const DISKS_STATUS_HEALTH = "statusHealth";
export const DISKS_STATUS_STRING = "statusString";

export const NAS_SESSION_SHARE_NAME = "shareName";
export const NAS_SESSION_PROTOCOL = "protocol";
export const NAS_SESSION_HOST = "remote";
export const NAS_SESSION_SPEED = "speed";

export const NET_IFACE_PORT = "port";
export const NET_IFACE_IP = "ip";
export const NET_IFACE_SPEED = "speed";
export const NET_IFACE_STATUS = "status";
export const NET_IFACE_MTU = "mtu";
export const NET_IFACE_INTERFACES = "interfaces";
export const NET_IFACE_SUBNET = "subnet";
export const NET_IFACE_GATEWAY = "gateway";
export const NET_IFACE_MAX_SPEED_GBS = "maxSpeedGbs";

export const NET_SWITCH_PORT = "port";
export const NET_SWITCH_IP = "ip";
export const NET_SWITCH_SPEED = "speed";
export const NET_SWITCH_STATUS = "status";
export const NET_SWITCH_MTU = "mtu";
export const NET_SWITCH_INTERFACES = "port";
export const NET_SWITCH_SUBNET = "subnet";
export const NET_SWITCH_GATEWAY = "gateway";

export const RECYCLE_BIN_NAME = "name";
export const RECYCLE_BIN_MODIFIED_TIME = "modifiedTime";
export const RECYCLE_BIN_DELETE_TIME = "deleteTime";
export const RECYCLE_BIN_TYPE = "type";
export const RECYCLE_BIN_SIZE = "size";

export const FILE_NAVIGATOR_NAME = "name";
export const FILE_NAVIGATOR_MODIFIED_TIME = "time";
export const FILE_NAVIGATOR_TYPE = "type";
export const FILE_NAVIGATOR_SIZE = "size";

export const ETHERNET_PORT_PORT = "port";
export const ETHERNET_PORT_IP = "ip";
export const ETHERNET_PORT_NETMASK = "netmask";
export const ETHERNET_PORT_GATEWAY = "gateway";
export const ETHERNET_PORT_SPEED = "speed";
export const ETHERNET_PORT_STATUS = "status";

export const USERS_LIST_GROUP_MEMBERS_NAME = "name";
export const USERS_LIST_GROUP_MEMBERS_TYPE_LABEL = "typeLabel";

export const REPLICATION_JOBS_JOB = "Job";
export const REPLICATION_JOBS_STATUS = "Status";
export const REPLICATION_JOBS_SOURCE = "Source";
export const REPLICATION_JOBS_DESTINATION = "Destination";
export const REPLICATION_JOBS_SCHEDULE = "Schedule";
export const REPLICATION_JOBS_LAST_JOB = "LastJobSummary";
export const REPLICATION_JOBS_METHOD = "Method";

export const INSTANCES_ID = "ID";
export const INSTANCES_STARTED = "STARTED";
export const INSTANCES_FINISHED = "FINISHED";
export const INSTANCES_STATUS = "STATUS";

export const PROCESSED_FILE_ID = "ID";
export const PROCESSED_FILE_SOURCE = "SOURCE";
export const PROCESSED_FILE_PROCESSED = "PROCESSED";

export const ARCHIVED_INSTANCES_ID = "ID";
export const ARCHIVED_INSTANCES_STATUS = "STATUS";
export const ARCHIVED_INSTANCES_STARTED = "STARTED";
export const ARCHIVED_INSTANCES_FINISHED = "FINISHED";

export const HARDWARE_PROFILE_CPU_MODEL = "cpuModel";
export const HARDWARE_PROFILE_CPU_CORES = "coresCount";
export const HARDWARE_PROFILE_RAID_CONTROLLER = "controller";
export const HARDWARE_PROFILE_RAID_SERIAL_NUMBER = "serialNumber";
export const HARDWARE_PROFILE_RAID_MODEL = "controllerModel";
export const HARDWARE_PROFILE_ETHERNET_MODEL = "interfaceModel";
export const HARDWARE_PROFILE_ETHERNET_FIRMWARE = "firmware";
export const HARDWARE_PROFILE_SENSORS_NAME = "name";
export const HARDWARE_PROFILE_SENSORS_VALUE = "value";

export const DRIVE_NAME = "driveName";
export const DRIVE_MODEL = "driveModel";
export const DRIVE_SIZE = "size";

export const GROWABLE_DISK_NAME = "volume";
export const GROWABLE_DISK_SIZE = "defaultSize";
export const GROWABLE_DISK_RAID = "raid";
export const GROWABLE_DISK_SIZE_AFTER = "growableSize";

export const AUDIT_SHARE_NAME = "shareName";
export const AUDIT_SHARE_ENABLED = "enabled";

export const SLINGSHOT_ALIAS_NAME = "name";
export const SLINGSHOT_ALIAS_URL = "decomposed_base_url.schema";
export const SLINGSHOT_ALIAS_CREATED = "created";
export const SLINGSHOT_ALIAS_STATUS = "status";
export const SLINGSHOT_ALIAS_VOLUME = "extvolume_uuid";

export const SLINGSHOT_PRESET_NAME = "name";
export const SLINGSHOT_PRESET_BUILTIN = "builtin";
export const SLINGSHOT_PRESET_VIDEO_CODEC = "vcodec";
export const SLINGSHOT_PRESET_CONTAINER = "container";

export const SLINGSHOT_AUTOMATION_NAME = "NAME";
export const SLINGSHOT_AUTOMATION_ENABLED = "ENABLED";

export const SLINGSHOT_PROXY_LOGICAL_DISK = "logicalDisk";
export const SLINGSHOT_PROXY_SHARE_NAME = "name";
export const SLINGSHOT_PROXY_SHAREBROWSER_PREVIEW = "sharebrowserPreview";
export const SLINGSHOT_PROXY_EDIT_READY = "editReady";

export const BACKUPS_TABLE_PATH = "path";
export const BACKUPS_TABLE_ID = "id";
export const BACKUPS_TABLE_TIME = "timestamp";
export const BACKUPS_TABLE_VERSION = "evoVersion";
export const BACKUPS_TABLE_COMMENT = "comment";
export const WIREGUARD_CONNECTION_NAME = "connectionName";
export const WIREGUARD_PROTOCOL_NAME = "protocol";
export const WIREGUARD_ENDPOINT = "endpoint";
export const WIREGUARD_LOCAL_ADDRESS = "localIP";
export const WIREGUARD_STATUS = "connectionState";
export const WIREGUARD_AUTOSTART = "autostart";

export const MISSING_QUADS_OLD_POSITION = "quadId";
export const MISSING_QUADS_DRIVE_SERIALS = "serialsField";

export const CLUSTER_LOCAL_BRICK_PATH = "path";
export const CLUSTER_LOCAL_BRICK_NAME = "name";
export const CLUSTER_LOCAL_BRICK_USED = "used";
export const CLUSTER_LOCAL_BRICK_VOLUME = "volume";
export const CLUSTER_LOCAL_BRICK_STATUS = "status";
export const CLUSTER_PEERS_HOST = "alias";
export const CLUSTER_PEERS_IP = "ip";
export const CLUSTER_PEERS_EXTENDED_STATUS = "extendedStatus";
export const CLUSTER_PEERS_STATUS = "status";

export const FILE_NAVIGATOR_LOG_TIMESTAMP = "timeStamp";
export const FILE_NAVIGATOR_LOG_SHARE_NAME = "shareName";
export const FILE_NAVIGATOR_LOG_USER = "user";
export const FILE_NAVIGATOR_LOG_ACTION = "action";
export const FILE_NAVIGATOR_LOG_PATH = "path";

export const SLINGSHOT_WATERMARK_NAME = "name";
export const SLINGSHOT_WATERMARK_CREATED = "created";
export const USERS_NAME = "name";
export const USERS_DOMAIN = "domain";

export const ADMIN_SETTINGS_NAME = "name";
export const LONG_LIVED_TOKENS_NAME = "userName";
export const LONG_LIVED_TOKENS_UUID = "token";
export const LONG_LIVED_TOKENS_EXPIRATION_DATE = "expirationDate";
