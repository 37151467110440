import React from "react";
import { observer } from "mobx-react";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import { useToggle } from "hooks/useToggle";
import i18next from "i18next";

const ButtonNext = ({ onClick, text = i18next.t("migration.button.next_step"), sideProgress, ...restProps }) => {
    const [isProgress, toggleProgress] = useToggle();

    const handleClick = async () => {
        try {
            toggleProgress(true);
            await onClick();
        } finally {
            toggleProgress(false);
        }
    };

    return (
        <AnimatedSubmitButton
            label={text}
            color="primary"
            variant="contained"
            submit={handleClick}
            inProgress={sideProgress || isProgress}
            {...restProps}
        />
    );
};

export default observer(ButtonNext);
