import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./SavedInputPreview.styles";

const SavedInputPreview = ({ children, icon, iconPosition = "left", ...rest }) => {
    const classes = useStyles({ iconPosition });
    return (
        <Grid container {...rest}>
            {icon && (
                <Grid item className={classes.icon}>
                    {icon}
                </Grid>
            )}
            <Grid item>
                <Typography className={classes.inputPreview}>{children}</Typography>
            </Grid>
        </Grid>
    );
};

export default SavedInputPreview;
