import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useScrollHandler = (
    elementHash,
    elementRef,
    scrollOptions = { block: "center", inline: "center", behavior: "smooth" }
) => {
    const location = useLocation();
    useEffect(() => {
        const locationHash = location.hash.replace("#", "");
        const element = elementRef?.current;

        if (locationHash === elementHash && element) {
            element.scrollIntoView(scrollOptions);
        }
    }, [location, elementRef]);
};
