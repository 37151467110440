import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./EmptyTablePlaceholder.styles";
import UsersDisabledPic from "assets/UsersDisabledPic";
import { useTranslation } from "react-i18next";
import { LOCAL_USERS, EXTERNAL_USERS, LOCAL_GROUPS, EXTERNAL_GROUPS } from "const/userSearchVariants";

const EmptyTablePlaceholder = ({ requestType }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const getType = () => {
        switch (requestType) {
            case LOCAL_USERS:
                return t("users.common.local_users");
            case EXTERNAL_USERS:
                return t("users.common.external_users");
            case LOCAL_GROUPS:
                return t("users.common.local_groups");
            case EXTERNAL_GROUPS:
                return t("users.common.external_groups");
            default:
                return t("users.common.local_users");
        }
    };
    const getSearchType = () => {
        switch (requestType) {
            case LOCAL_USERS:
                return t("users.search_results.empty_table_helper_text.search_external_users");
            case EXTERNAL_USERS:
                return t("users.search_results.empty_table_helper_text.search_local_users");
            case LOCAL_GROUPS:
                return t("users.search_results.empty_table_helper_text.search_external_groups");
            case EXTERNAL_GROUPS:
                return t("users.search_results.empty_table_helper_text.search_local_groups");
            default:
                return t("users.search_results.empty_table_helper_text.search_external_users");
        }
    };
    return (
        <Grid
            container
            className={classes.container}
            direction={"column"}
            alignItems={"center"}
            justify={"center"}
            wrap={"nowrap"}
        >
            <Grid item container justify={"center"} alignItems={"center"} className={classes.oval}>
                <UsersDisabledPic />
            </Grid>
            <Grid item>
                <Typography className={classes.message}>
                    {t("users.search_results.empty_table_placeholder", { type: getType() })}
                </Typography>
                <Typography className={classes.helper} align="center">
                    {t("users.search_results.empty_table_helper_text", { type: getSearchType() })}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default EmptyTablePlaceholder;
