import Request from "api/Request";

const BeepUnmute = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Events",
    method: "beep_unmute",
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default BeepUnmute
