import React from "react";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Dialog from "components/Dialog";

const DeleteDiskDialog = ({ open, diskName, onClose, onDeleteAction, inProgress }) => {
    const { t } = useTranslation();
    const renderContent = () => {
        if (Array.isArray(diskName) && diskName.length > 1) {
            return (
                <>
                    <Typography>{t("disks_and_pools.logical_disks.delete_dialog.content_delete_many_title")}</Typography>
                    <br />
                    <Typography>{`${diskName.join(",\n")}.`}</Typography>
                    <br />
                    <Typography>{t("disks_and_pools.logical_disks.delete_dialog.content_delete_many", { diskName })}</Typography>
                </>
            );
        }
        return <Typography>{t("disks_and_pools.logical_disks.delete_dialog.content_delete_one", { diskName })}</Typography>;
    };
    return (
        <Dialog
            buttons={[
                <Button key={"cancelBtn"} variant={"contained"} color={"secondary"} onClick={onClose}>
                    {t("common.button.cancel")}
                </Button>
            ]}
            onClose={onClose}
            open={open}
            submitBtnLabel={t("common.button.ok_delete")}
            onSubmit={onDeleteAction}
            withoutIconClose
            withoutDividers
            title={t("disks_and_pools.logical_disks.delete_dialog.title", {
                count: Array.isArray(diskName) ? diskName.length : 1
            })}
            inProgress={inProgress}
        >
            {renderContent()}
            <br />
            <Typography>{t("common.text.continue")}</Typography>
        </Dialog>
    );
};

export default DeleteDiskDialog;
