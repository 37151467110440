import React from "react";

const TrashIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#1E90FF"
                fillRule="nonzero"
                d="M13.659 2A2.42 2.42 0 0 1 16 4.33V6h5a1 1 0 1 1 0 2h-1v11a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V8H3a1 1 0 1 1 0-2h5V4.33A2.42 2.42 0 0 1 10.5 2zM18 8H6v11l.007.117A1 1 0 0 0 7 20h10a1 1 0 0 0 1-1V8zm-9 3a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1l-.117-.007A1 1 0 0 1 8 16v-4a1 1 0 0 1 1-1zm6 0a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1l-.117-.007A1 1 0 0 1 14 16v-4a1 1 0 0 1 1-1zm-1.5-7h-3c-.29 0-.5.17-.5.33V6h4V4.33c0-.16-.21-.33-.5-.33z"
            />
        </svg>
    );
};

export default TrashIcon;
