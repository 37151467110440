import { types } from "mobx-state-tree";

import LdapSettings from "api/security/users/Types/LdapSettings";

const LdapStatus = types.model({
    enabled: types.boolean,
    settings: types.maybe(LdapSettings),
    sambaSID: types.string,
    status: types.string
});

export default LdapStatus;
