import React from "react";

const ShieldWithKeyIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <linearGradient id="811qlyvypb" x1="45.419%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#A770FF"></stop>
                    <stop offset="100%" stopColor="#85ADFF"></stop>
                </linearGradient>
                <linearGradient id="ulcnmml5ka" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#E19EFF" stopOpacity="0.771"></stop>
                    <stop offset="100%" stopColor="#293CFF" stopOpacity="0.6"></stop>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="url(#811qlyvypb)"
                    stroke="url(#ulcnmml5ka)"
                    d="M12 .539l9.5 3.8V14a9.474 9.474 0 01-2.782 6.718C16.998 22.437 14.623 23.5 12 23.5s-4.998-1.063-6.718-2.782A9.474 9.474 0 012.5 14h0V4.339l9.5-3.8z"
                ></path>
                <circle cx="12" cy="15" r="2" stroke="#FFF" strokeWidth="2"></circle>
                <path fill="#FFF" d="M11 6h2v7h-2z"></path>
                <path fill="#FFF" d="M11 6h4v2h-4zm0 3h3v2h-3z"></path>
            </g>
        </svg>
    );
};

export default ShieldWithKeyIcon;
