import { types } from "mobx-state-tree";

import Request from "api/Request";
import IfaceNamesArrayArguments from "api/net/Types/IfaceNamesArrayArguments";

const CreateBridge = Request.props({
    path: "/com/studionetworksolutions/evo/Net",
    method: "create_bridge",
    arguments: types.maybe(IfaceNamesArrayArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default CreateBridge;
