import React from "react";

const DiskPicture = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="110"
            height="110"
            viewBox="0 0 110 110"
            {...props}
        >
            <defs>
                <linearGradient id="6cabd0ec-7285-11ea-bc55-0242ac130003" x1="18%" x2="75.361%" y1="5.187%" y2="100%">
                    <stop offset="0%" stopColor="#1E84FF" />
                    <stop offset="45.323%" stopColor="#1E79FF" />
                    <stop offset="100%" stopColor="#8755FA" />
                </linearGradient>
                <linearGradient id="6cagt0ec-7285-11ea-bc55-0242ac130003" x1="33.632%" x2="79.142%" y1="0%" y2="98.894%">
                    <stop offset="0%" stopColor="#6DCEFF" />
                    <stop offset="100%" stopColor="#B782FF" />
                </linearGradient>
                <linearGradient id="a11c12b0-7285-11ea-bc55-0242ac130003" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" stopOpacity=".2" />
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0" />
                </linearGradient>
                <filter
                    id="b7754702-7285-11ea-bc55-0242ac130003"
                    width="117.8%"
                    height="87.4%"
                    x="-4.5%"
                    y="14.7%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1" />
                    <feColorMatrix
                        in="shadowOffsetOuter1"
                        values="0 0 0 0 0.0421226234 0 0 0 0 0.437925724 0 0 0 0 0.823312953 0 0 0 1 0"
                    />
                </filter>
                <filter
                    id="d098072e-7285-11ea-bc55-0242ac130003"
                    width="120%"
                    height="120%"
                    x="-10%"
                    y="-10%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset dy="1" in="SourceAlpha" result="shadowOffsetInner1" />
                    <feComposite
                        in="shadowOffsetInner1"
                        in2="SourceAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                        result="shadowInnerInner1"
                    />
                    <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                </filter>
                <circle id="c7f03b96-7285-11ea-bc55-0242ac130003" cx="27.465" cy="93.686" r="2.5" />
                <path
                    id="bf46c230-7285-11ea-bc55-0242ac130003"
                    d="M50.619 109.473c3.097 0 5.607-2.51 5.607-5.608 0-1.858-2.41-19.572-7.23-53.143l-.052.008c-.082-.573-.612-.97-1.185-.888-.501.072-.879.492-.898.998-1.233 33.494-1.85 51.17-1.85 53.025 0 3.098 2.51 5.608 5.608 5.608z"
                />
            </defs>
            <g fill="none" fillRule="evenodd">
                <rect width="88" height="110" x="11" fill="url(#6cabd0ec-7285-11ea-bc55-0242ac130003)" rx="13" />
                <path
                    fill="url(#6cagt0ec-7285-11ea-bc55-0242ac130003)"
                    fillRule="nonzero"
                    d="M86 0c7.18 0 13 5.82 13 13v84c0 7.18-5.82 13-13 13H24c-7.18 0-13-5.82-13-13V13c0-7.18 5.82-13 13-13h62zM15 80v17c0 4.882 3.887 8.856 8.735 8.996L24 106h62c4.882 0 8.856-3.887 8.996-8.735L95 97V80H15zm0-4h80V13c0-4.882-3.887-8.856-8.735-8.996L86 4H24c-4.882 0-8.856 3.887-8.996 8.735L15 13v63z"
                />
                <path fill="#FFF" d="M15 80h80v17c0 4.97-4.03 9-9 9H24c-4.97 0-9-4.03-9-9V80z" opacity="0.2" />
                <circle
                    cx="55"
                    cy="42"
                    r="31"
                    fill="#D8D8D8"
                    fillOpacity=".25"
                    stroke="url(#a11c12b0-7285-11ea-bc55-0242ac130003)"
                    strokeWidth="2"
                />
                <circle cx="55" cy="42" r="7" fill="#FFF" fillOpacity="0.12" stroke="#1876ED" strokeWidth="6" />
                <g transform="rotate(50 50.619 74.209)">
                    <use
                        fill="#000"
                        filter="url(#b7754702-7285-11ea-bc55-0242ac130003)"
                        xlinkHref="#bf46c230-7285-11ea-bc55-0242ac130003"
                    />
                    <use fill="#FFF" fillOpacity=".98" xlinkHref="#bf46c230-7285-11ea-bc55-0242ac130003" />
                </g>
                <use fill="#1E90FF" xlinkHref="#c7f03b96-7285-11ea-bc55-0242ac130003" />
                <use
                    fill="#000"
                    filter="url(#d098072e-7285-11ea-bc55-0242ac130003)"
                    xlinkHref="#c7f03b96-7285-11ea-bc55-0242ac130003"
                />
            </g>
        </svg>
    );
};

export default DiskPicture;
