import { types, flow, getParent } from "mobx-state-tree";
import MainSocket from "websocket";
import GetSharesTable from "api/share/Requests/GetSharesTable";
import CreateShare from "api/share/Requests/CreateShare";
import ClearLocks from "api/share/Requests/ClearLocks";
import RemoveShare from "api/share/Requests/RemoveShare";
import VerifyBackupOperatorAccess from "api/share/Requests/VerifyBackupOperatorAccess";
import GetSharesTableType from "api/share/Responses/GetSharesTableResult";
import EditShare from "api/share/Requests/EditShare";
import GetShareInfo from "api/share/Requests/GetShareInfo";
import GetShareInfoType from "api/share/Responses/GetShareInfoResult";
import GetDefaultMasks from "api/share/Requests/GetDefaultMasks";
import DefaultMasksType from "api/share/Responses/GetDefaultMasksResult";
import SetDefaultMasks from "api/share/Requests/SetDefaultMasks";
import GetNasConfiguration from "api/share/Requests/GetNasConfiguration";
import NasConfigurationType from "api/share/Responses/GetNasConfigurationResult";
import SetNasConfiguration from "api/share/Requests/SetNasConfiguration";
import ResetNasConfiguration from "api/share/Requests/ResetNasConfiguration";
import { getEvoPrefix } from "utils/helpers/getEvoPrefix";

const ShareStore = types
    .model("ShareStore", {
        sharesTableStore: types.maybe(GetSharesTableType),
        checkedSharesNames: types.optional(types.array(types.string), []),
        currentEditableShareStore: types.maybe(GetShareInfoType),
        currentShareName: types.maybeNull(types.string),
        defaultMasksStore: types.maybe(DefaultMasksType),
        nasConfigurationStore: types.maybe(NasConfigurationType),
        currentCheckedShareName: types.maybe(types.string),
        createdShareType: types.maybe(types.string),
    })
    .views((self) => ({
        get sharesTable() {
            return (self.sharesTableStore && self.sharesTableStore.data) || [];
        },
        get trashShares() {
            return this.sharesTable.flatMap((share) => share.shares.filter((s) => s.attributes.trashEnabled)) || [];
        },
        get smbShares() {
            return (
                this.sharesTable.flatMap((share) =>
                    share.shares.filter((s) => s.attributes.shareType === "smb").filter((s) => s.status.status !== "offline")
                ) || []
            );
        },
        get internalShares() {
            return this.sharesTable.flatMap((share) => share.shares.filter((s) => s.attributes.shareType === "internal")) || [];
        },
        get allShares() {
            return this.sharesTable.flatMap((share) => share.shares) || [];
        },
        get checkedSharesCount() {
            return self.checkedSharesNames.length;
        },
        get currentEditableShare() {
            return self.currentEditableShareStore && self.currentEditableShareStore.data;
        },
        get defaultMasks() {
            return self.defaultMasksStore && self.defaultMasksStore.data;
        },
        get currentShare() {
            if (self.currentShareName === null) return null;
            const foundCard = self.getCardByShareName(self.currentShareName);
            return foundCard && foundCard.shares.find((share) => share.attributes.shareName === self.currentShareName);
        },
        get needToShowQuotaOnCurrentShare() {
            if (self.currentShareName === null) return false;
            const foundCard = self.getCardByShareName(self.currentShareName);
            return foundCard.shares.some((share) => share.attributes.quota !== 0);
        },
        get nasConfiguration() {
            return self.nasConfigurationStore && self.nasConfigurationStore.data;
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
        get currentShareNameWithPrefix() {
            const { ip, name, evoSettingsStore } = getParent(self);

            const prefix = getEvoPrefix({ ip, evoName: name, hostname: evoSettingsStore?.evoInfo?.hostname, store: self });

            return self.currentShareName ? `${prefix}${self.currentShareName}` : null;
        },
        get checkedSharesNamesWithPrefix() {
            const { ip, name, evoSettingsStore } = getParent(self);

            const prefix = getEvoPrefix({ ip, evoName: name, hostname: evoSettingsStore?.evoInfo?.hostname, store: self });

            const res = self.checkedSharesNames
                ? self.checkedSharesNames.map((shareName) => {
                      return `${prefix}${shareName}`;
                  })
                : [];

            return res;
        },
        get currentCheckedShareNameWithPrefix() {
            const { ip, name, evoSettingsStore } = getParent(self);

            const prefix = getEvoPrefix({ ip, evoName: name, hostname: evoSettingsStore?.evoInfo?.hostname, store: self });

            return self.currentCheckedShareName ? `${prefix}${self.currentCheckedShareName}` : null;
        },
    }))
    .actions((self) => ({
        fetchSharesTable: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetSharesTable.create().init();
                const res = yield self.socket.send(req);
                self.sharesTableStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchDefaultsMasks: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetDefaultMasks.create().init();
                const res = yield self.socket.send(req);
                self.defaultMasksStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchNasConfiguration: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetNasConfiguration.create().init();
                const res = yield self.socket.send(req);
                self.nasConfigurationStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateNasConfigurationState: (data) => {
            self.nasConfigurationStore?.data && (self.nasConfigurationStore.data = data);
            return true;
        },
        setNasConfiguration: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetNasConfiguration.create().init(data);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        resetNasConfiguration: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ResetNasConfiguration.create().init();
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        setDefaultsMasks: flow(function* (masks) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetDefaultMasks.create().init(masks);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getShareInfo: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetShareInfo.create().init(data);
                const res = yield self.socket.send(req);
                self.currentEditableShareStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
        }),
        setCurrentShareName: (shareName) => {
            if (shareName === self.currentShareName) {
                self.currentShareName = null;
                return;
            }

            self.currentShareName = shareName;
        },
        createShare: flow(function* (data) {
            const { processingStore } = getParent(self);

            try {
                processingStore.setLoading(true);
                const req = CreateShare.create().init(data);
                const res = yield self.socket.send(req);

                self.createdShareType = data.attributes.shareType;
                self.currentCheckedShareName = data.attributes.shareName;
                self.fetchSharesTable();
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        editShare: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = EditShare.create().init(data);
                yield self.socket.send(req);
                yield self.fetchSharesTable();
                self.currentShareName === data.shareName && (self.currentShareName = data.attributes.shareName);
            } catch (e) {
                processingStore.setError(e);
                return null;
            } finally {
                processingStore.setLoading(false);
            }
            return data;
        }),
        clearLocks: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ClearLocks.create().init(data);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        removeShares: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RemoveShare.create().init(data);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        verifyBackupOperatorAccess: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = VerifyBackupOperatorAccess.create().init(data);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        getCardByShareName: (shareName) => {
            return (
                self.sharesTableStore &&
                self.sharesTableStore.data.find((shareCard) =>
                    shareCard.shares.some((share) => share.attributes.shareName === shareName)
                )
            );
        },
        removeShareFromStore: (data) => {
            data.shareNames.forEach((name) => {
                const foundCard = self.getCardByShareName(name);
                const foundShare = foundCard && foundCard.shares.find((share) => share.shareName === name);
                if (foundShare) {
                    self.removeCheckedShare(foundShare.shareName);
                    foundCard.shares.remove(foundShare);
                }
            });
        },
        changeShareStatus: (data) => {
            const foundCard = self.getCardByShareName(data.shareName);
            const foundShare = foundCard && foundCard.shares.find((share) => share.shareName === data.shareName);
            foundShare ? (foundShare.status = data.status) : self.fetchSharesTable();
        },
        addCheckedShare: (targetShareName) => {
            const foundShare = self.checkedSharesNames.some((shareName) => shareName === targetShareName);
            !foundShare && self.checkedSharesNames.push(targetShareName);

            if (self.checkedSharesNames.length === 1) {
                self.currentCheckedShareName = targetShareName;
            }
        },
        removeCheckedShare: (targetShareName) => {
            const foundShare = self.checkedSharesNames.some((shareName) => shareName === targetShareName);
            foundShare && self.checkedSharesNames.remove(targetShareName);

            if (self.checkedSharesNames.length === 1) {
                self.currentCheckedShareName = self.checkedSharesNames[0];
            }
        },
        clearCheckedShares: () => {
            self.checkedSharesNames = [];
        },
        clearAfterCreateShareData: () => {
            self.currentCheckedShareName = undefined;
            self.createdShareType = undefined;
        },
        clearCreateShareType: () => {
            self.createdShareType = undefined;
        },
        /** Action to update sharesTableStore
         * @description Gets an object of type GetSharesTableType and updates the existing shares to those obtained from the new object.
         * @param {{data: Array<ShareTableCardType>}} broadcast object of type GetSharesTableType */
        updateSharesTableStore: (broadcast) => {
            /** @type {Store} */
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                if (broadcast) {
                    if (self.sharesTableStore && self.sharesTableStore.data) {
                        self.sharesTableStore.data = self.sharesTableStore.data.map((share) => {
                            const updatedShare = broadcast.data.find(
                                (updateShare) => share.pool === updateShare.pool && share.volume === updateShare.volume
                            );

                            return updatedShare ? updatedShare : share;
                        });

                        return;
                    }

                    self.sharesTableStore = broadcast;
                    return;
                }

                return;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        },
    }));

export default ShareStore;
