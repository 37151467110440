import { types } from "mobx-state-tree";

const BackupSchedule = types.model({
    hour: types.number,
    minute: types.number,
    sunday: types.boolean,
    monday: types.boolean,
    tuesday: types.boolean,
    wednesday: types.boolean,
    thursday: types.boolean,
    friday: types.boolean,
    saturday: types.boolean
});

export default BackupSchedule;
