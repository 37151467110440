import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react-lite";
import DataReadonlyCard from "components/DataReadonlyCard";
import { Grid, Typography } from "@material-ui/core";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import NetworkingSettingsDialog from "../NetworkingSettingsDialog";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder";
import { NETWORKING_SETTINGS_CARD } from "const/widgetNameConst";
import { ADVANCED_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { useAsyncEffect } from "hooks/useAsyncEffect";

const NETWORKING_SETTINGS_CARD_ID_PREFIX = `${ADVANCED_PAGE_ID_PREFIX}_networking_settings_card`;

const AdvancedNetworkingSettingsCard = () => {
    const { t } = useTranslation();

    const {
        store: { networkingSettingsStore },
    } = useStore();

    const state = useLocalStore(() => ({
        inProgress: false,
    }));

    useAsyncEffect(async () => {
        state.inProgress = true;
        await networkingSettingsStore.fetchNetworkingSettings();
        state.inProgress = false;
    }, []);

    const networkingSettingsDialog = useModal();

    return useObserver(() => (
        <>
            <NetworkingSettingsDialog closeDialog={networkingSettingsDialog.close} open={networkingSettingsDialog.isOpen} />
            <DataReadonlyCard
                headerDivider
                title={t("advanced.networking_settings.title")}
                control={
                    <MoreMenu id={`${NETWORKING_SETTINGS_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={NETWORKING_SETTINGS_CARD} />
                    </MoreMenu>
                }
                onClick={networkingSettingsDialog.open}
            >
                {networkingSettingsStore.tcpScaling ? (
                    <>
                        <Grid container direction={"column"} spacing={5}>
                            <Grid item container alignItems={"center"} justify={"space-between"}>
                                <Grid xs={6} item>
                                    <Typography>{t("advanced.networking_settings.label.tcp_scaling")}</Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <Typography>{networkingSettingsStore.tcpScaling || ""}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <EmptyCardPlaceholder inProgress={state.inProgress} />
                )}
            </DataReadonlyCard>
        </>
    ));
};
export default AdvancedNetworkingSettingsCard;
