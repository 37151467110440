import React, { useRef } from "react";
import { Grid } from "@material-ui/core";
import BackupsTableCard from "../BackupsTableCard";
import PartitionTable from "./PartitionTable";
import UpgradeSystemCard from "./UpgradeSystemCard";
import BackupCard from "../BackupCard";
import InstalledComponentsTableCard from "../InstalledComponentsTableCard/InstalledComponentsTableCard";
import InstalledPluginsTableCard from "../InstalledPluginsTableCard/InstalledPluginsTableCard";
import RecoverConfigurationCard from "./RecoverConfigurationCard/RecoverConfigurationCard";
import { useScrollHandler } from "hooks/useScrollHandler";
import { HASH_INSTALLED_PLUGINS_TABLE_CARD, HASH_COMPONENT_DATABASES } from "routes";
import SystemDriveStatus from "../SystemDriveStatus/SystemDriveStatus";

const OsDataTabComponent = () => {
    const installedPluginsTableCardRef = useRef(null);
    const componentDatabasesRef = useRef(null);
    useScrollHandler(HASH_INSTALLED_PLUGINS_TABLE_CARD, installedPluginsTableCardRef);
    useScrollHandler(HASH_COMPONENT_DATABASES, componentDatabasesRef);

    return (
        <Grid container spacing={6}>
            <Grid xs={6} item>
                <PartitionTable />
            </Grid>
            <Grid xs={6} item>
                <UpgradeSystemCard />
            </Grid>
            <Grid item xs={12}>
                <InstalledComponentsTableCard />
            </Grid>
            <Grid item xs={12} ref={componentDatabasesRef}>
                <BackupCard />
            </Grid>
            <Grid item xs={12} ref={installedPluginsTableCardRef}>
                <InstalledPluginsTableCard />
            </Grid>
            <Grid lg={12} item>
                <RecoverConfigurationCard />
            </Grid>
            <Grid lg={12} item>
                <BackupsTableCard />
            </Grid>
            <Grid xs={12} item>
                <SystemDriveStatus />
            </Grid>
        </Grid>
    );
};

export default OsDataTabComponent;
