import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

const OVAL_SIZE = 156;

export const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        paddingBottom: theme.spacing(16),
    },
    oval: {
        backgroundColor: theme.palette.expandPanel.border.color,
        height: OVAL_SIZE,
        width: OVAL_SIZE,
        borderRadius: "50%",
        marginBottom: theme.spacing(4),
    },
    message: {
        fontWeight: 500,
    },
    helper: {
        color: hexToRgba(theme.palette.text.primary, 0.4),
    },
}));
