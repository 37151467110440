import { types } from "mobx-state-tree";

import TrashFile from "api/trash/Types/TrashFile";

const TrashFileResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.model({ total: types.number, data: types.array(TrashFile) }),
});

export default TrashFileResult;
