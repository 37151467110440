import { makeStyles } from "@material-ui/core/styles";
import { HEADER_QUAD_ITEM_BACKGROUND_COLOR } from "const/styleConst";

export const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 12,
    },
    largeItem: {
        padding: theme.spacing(2.3, 1),
        borderRadius: theme.spacing(0.5),
        backgroundColor: HEADER_QUAD_ITEM_BACKGROUND_COLOR,
    },
    smallItem: {
        padding: (props) => (props.withoutHorizontalPadding ? theme.spacing(0.15, 0) : theme.spacing(0.15, 2.5)),
        backgroundColor: HEADER_QUAD_ITEM_BACKGROUND_COLOR,
    },
}));
