import { types } from "mobx-state-tree";
import Plugins from "./Plugins";

const EvoInfo = types.model("EvoInfo", {
    evoVersion: types.string,
    type: types.string,
    description: types.string,
    builtinPortsCount: types.number,
    unixTime: types.number,
    time: types.string,
    hostname: types.string,
    locale: types.maybe(types.string),
    plugins: types.array(Plugins)
});

export default EvoInfo;
