import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./CustomizeSettingsModal.styles";
import Dialog from "components/Dialog";
import MoreMenu from "components/MoreMenu";
import Checkbox from "components/Checkbox";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    DialogTitle,
    ButtonBase,
    Button,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    MenuItem,
    FormControlLabel,
    Grid,
} from "@material-ui/core";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import CloseIcon from "assets/CloseIcon";
import ThinArrowRightIcon from "assets/ThinArrowRightIcon";
import LocalUserIcon from "assets/LocalUserIcon";
import ExternalGroupIcon from "assets/ExternalGroupIcon";
import LocalGroupIcon from "assets/LocalGroupIcon";
import PlusIcon from "assets/PlusIcon";
import TableCellWithIcon from "components/TableCellWithIcon/TableCellWithIcon";
import clsx from "clsx";
import EditPermissionModal from "../EditPermissionModal";
import getPermissionLabel from "utils/getPermissionLabel";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import SaveChangesDialog from "components/SaveChangesDialog";
import ChevronUpIcon from "assets/ChevronUpIcon";
import { useStore } from "hooks/useStore";
import { useModalCombine } from "hooks/useModalCombine";
import DestructiveMenuItem from "components/DestructiveMenuItem/DestructiveMenuItem";

const CUSTOMIZE_SETTINGS_MODAL_ID_PREFIX = "customize_settings_modal";

const CustomizeSettingsModal = ({ open, onBack, parentId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const idPrefix = parentId ? `${parentId}_${CUSTOMIZE_SETTINGS_MODAL_ID_PREFIX}` : CUSTOMIZE_SETTINGS_MODAL_ID_PREFIX;

    const {
        store: { multiLdapStore, userStore },
    } = useStore();

    const state = useLocalStore(() => ({
        replaceChildrenAcl: false,
        saveInProgress: false,
        needToCreateNewAce: false,
        accordionExpanded: false,
        isSubmitted: false,
    }));

    const { unsavedChangesModal, saveChangesModal, editPermissionModal } = useModalCombine([
        "unsavedChangesModal",
        "saveChangesModal",
        "editPermissionModal",
    ]);

    const onDialogEnter = () => {
        state.saveInProgress = false;
        state.accordionExpanded = false;
        state.replaceChildrenAcl = false;
        state.isSubmitted = false;
        multiLdapStore.currentShareAclStore.updateIsPermissionsDefault(true);

        multiLdapStore.currentShareAclStore.getAcl({
            shareName: multiLdapStore.currentShareAclStore.currentShareName,
            path: "",
        });
    };

    const onSave = async () => {
        state.saveInProgress = true;
        const res = await multiLdapStore.currentShareAclStore.setAcl({
            path: "",
            useInheritedPermissions: false,
            replaceChildrenAcl: state.replaceChildrenAcl,
        });
        state.saveInProgress = false;

        if (res) {
            const currentEntity = userStore.currentEntity || userStore.currentEntitySingleBar;
            if (currentEntity) {
                await multiLdapStore.fetchSharesRootAccess(currentEntity);
            }
            state.isSubmitted = true;
        }

        multiLdapStore.currentShareAclStore.updateIsPermissionsDefault(true);
        return res;
    };

    const onReplaceChildrenAcl = () => {
        state.replaceChildrenAcl = !state.replaceChildrenAcl;
    };

    const removeAce = (ace) => () => {
        multiLdapStore.currentShareAclStore.removeAce(ace);
    };

    const closeIfNeeded = () => {
        if (
            (multiLdapStore.currentShareAclStore.isPermissionsDefault || state.isSubmitted === true) &&
            !(state.replaceChildrenAcl && !state.isSubmitted)
        ) {
            onBack();
            return;
        }

        unsavedChangesModal.open();
    };

    const onSaveChangesDialogConfirm = async () => {
        const res = await onSave();
        saveChangesModal.close();

        if (res) {
            onBack();
        }
    };
    const onSaveChangesDialogCancel = () => {
        saveChangesModal.close();
        onBack();
    };

    const editAce = (ace) => () => {
        state.needToCreateNewAce = false;
        multiLdapStore.currentShareAclStore.setCurrentAce(ace);
        editPermissionModal.open();
    };
    const createAce = () => {
        state.needToCreateNewAce = true;
        multiLdapStore.currentShareAclStore.setCurrentAce(null);
        editPermissionModal.open();
    };

    const getIcon = (domainObject) => {
        if (domainObject.isGroup) {
            return domainObject.isExternal ? <ExternalGroupIcon /> : <LocalGroupIcon />;
        } else {
            return <LocalUserIcon />;
        }
    };

    const getName = (domainObject) => {
        return domainObject.isExternal ? `${domainObject.id.name}@${domainObject.id.domain}` : domainObject.id.name;
    };

    const getType = (allow) => {
        return allow ? t("users.customize_settings_modal.table.type.allow") : t("users.customize_settings_modal.table.type.deny");
    };

    const getHeader = () => {
        return (
            <DialogTitle className={classes.dialogTitle}>
                <Grid container direction={"column"} spacing={4}>
                    <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container item>
                        <Grid item>
                            {t("users.customize_settings_modal.title", {
                                shareName: multiLdapStore.currentShareAclStore.currentShareNameWithPrefix,
                            })}
                        </Grid>
                        <Grid item>
                            <CardHeaderControlButton onClick={closeIfNeeded}>
                                <ButtonBase id={`${idPrefix}_close_icon`}>
                                    <CloseIcon />
                                </ButtonBase>
                            </CardHeaderControlButton>
                        </Grid>
                    </Grid>
                    <Grid item container wrap={"nowrap"} spacing={2} alignItems={"center"}>
                        <Grid item>
                            <Grid container alignItems={"center"}>
                                <Typography variant={"body1"} className={classes.step} color={"primary"}>
                                    {t("users.customize_settings_modal.steps.assign_shares")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems={"center"}>
                                <ThinArrowRightIcon />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems={"center"}>
                                <Typography
                                    variant={"body1"}
                                    className={clsx(classes.step, classes.activeStep)}
                                    color={"primary"}
                                >
                                    {t("users.customize_settings_modal.steps.customize_settings")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogTitle>
        );
    };

    return useObserver(() => (
        <Dialog
            maxWidth={"md"}
            onEnter={onDialogEnter}
            open={open}
            onClose={closeIfNeeded}
            submitBtnLabel={t("common.button.save")}
            onSubmit={onSave}
            withoutPaddings
            header={getHeader()}
            inProgress={state.saveInProgress}
            additionalActions={
                <Grid xs={12} item className={classes.expansionPanelContainer}>
                    <Accordion
                        className={classes.expansionPanel}
                        square
                        expanded={state.accordionExpanded}
                        onChange={(event, newExpanded) => {
                            state.accordionExpanded = newExpanded;
                        }}
                    >
                        <AccordionSummary
                            className={classes.expansionPanelContent}
                            classes={{ content: classes.expansionPanelContent, expanded: classes.expansionPanelContent }}
                        >
                            <Typography variant={"h2"} className={classes.expansionLabel}>
                                {t("file_navigator.advanced_security_modal.button.inheritance")}
                            </Typography>
                            <ChevronUpIcon
                                className={clsx(!state.accordionExpanded && classes.expansionIconClose, classes.expansionIcon)}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <FormControlLabel
                                    className={classes.formControlLabel}
                                    control={
                                        <Checkbox
                                            onChange={onReplaceChildrenAcl}
                                            checked={state.replaceChildrenAcl}
                                            id={`${idPrefix}_replace_children_acl`}
                                        />
                                    }
                                    label={t("users.customize_settings_modal.checkbox_label.replace_all")}
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }
            buttons={
                <Button variant={"contained"} color={"secondary"} onClick={onBack}>
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("users.customize_settings_modal.table.header.user_or_group")}</TableCell>
                        <TableCell>{t("users.customize_settings_modal.table.header.type")}</TableCell>
                        <TableCell>{t("users.customize_settings_modal.table.header.permission")}</TableCell>
                        <TableCell width={"10%"} align="right">
                            <ButtonBase
                                disabled={!multiLdapStore.currentShareAclStore.acl}
                                onClick={createAce}
                                id={`${idPrefix}_create_ace`}
                            >
                                <PlusIcon className={clsx(!multiLdapStore.currentShareAclStore.acl && classes.disabled)} />
                            </ButtonBase>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {multiLdapStore.currentShareAclStore.acl?.aces.map((ace, index) => (
                        // TODO  Do not use Array index in keys
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={index}>
                            <TableCellWithIcon className={classes.cellWithIcon} icon={getIcon(ace.domainObject)}>
                                {getName(ace.domainObject)}
                            </TableCellWithIcon>
                            <TableCell>{getType(ace.allow)}</TableCell>
                            <TableCell>{getPermissionLabel(ace, true)}</TableCell>
                            <TableCell>
                                <MoreMenu id={`${idPrefix}_more_menu_${getName(ace.domainObject)}`}>
                                    <MenuItem onClick={editAce(ace)} id={`${idPrefix}_edit_ace_${getName(ace.domainObject)}`}>
                                        {t("common.button.edit")}
                                    </MenuItem>
                                    <DestructiveMenuItem
                                        onClick={removeAce(ace)}
                                        id={`${idPrefix}_remove_ace_${getName(ace.domainObject)}`}
                                    >
                                        {t("common.button.delete")}
                                    </DestructiveMenuItem>
                                </MoreMenu>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <EditPermissionModal
                open={editPermissionModal.isOpen}
                onClose={editPermissionModal.close}
                isCreate={state.needToCreateNewAce}
                parentId={idPrefix}
            />
            <UnsavedChangesDialog open={unsavedChangesModal.isOpen} onClose={unsavedChangesModal.close} onConfirm={onBack} />
            <SaveChangesDialog
                open={saveChangesModal.isOpen}
                onClose={saveChangesModal.close}
                onSave={onSaveChangesDialogConfirm}
                onCancel={onSaveChangesDialogCancel}
            />
        </Dialog>
    ));
};

export default CustomizeSettingsModal;
