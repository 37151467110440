import React, { useEffect, useRef } from "react";
import { useDrag, useDrop, useDragLayer } from "react-dnd";
import { useStyles } from "./DraggableContainer.styles";
import clsx from "clsx";
import { CARD } from "const/dragAndDropTypes";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useLocalStore, useObserver } from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import DraggingCardDivider from "./component/DraggingCardDivider";

import { useTheme } from "@material-ui/core";

const DraggableContainer = ({
    id,
    row,
    column,
    handleDrop,
    setupPreview,
    children,
    notDraggable,
    type,
    notGreedy,
    previewTitle,
    resetToDefault,
    className,
    accept,
}) => {
    const ref = useRef(null);
    const childRef = useRef(null);
    const theme = useTheme();

    const resetPadding = () => {
        if (ref.current) {
            ref.current.style.paddingTop = `${theme.spacing(3)}px`;
            ref.current.style.paddingBottom = `${theme.spacing(3)}px`;
        }
    };
    const [{ isOver }, drop] = useDrop({
        accept: accept,
        hover(item, monitor) {
            if (id === item.id || notGreedy) {
                return;
            }
            const rect = childRef.current.getBoundingClientRect();
            let drift = 0;
            if (rect.top + rect.height / 2 > monitor.getClientOffset().y) {
                state.hoverPart = 1;
                drift = 1;
            } else {
                state.hoverPart = -1;
                drift = -1;
            }

            if (ref.current) {
                ref.current.style.paddingTop = drift === 1 ? 0 : `${theme.spacing(3)}px`;
                ref.current.style.paddingBottom = drift === -1 ? 0 : `${theme.spacing(3)}px`;
            }
        },
        drop(item, monitor) {
            const didDrop = monitor.didDrop();
            if (!ref.current || id === item.id || (didDrop && notGreedy)) {
                return;
            }
            handleDrop(item.row, item.column, row, column, item.id, id);
            state.hoverPart = 0;

            resetPadding();
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const [{ isDragging }, drag, preview] = useDrag({
        item: { type, id, row, column },
        canDrag: !notDraggable,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        begin: () => {
            if (setupPreview) {
                setupPreview(previewTitle);
            }
        },
        end: () => {
            resetToDefault();
        },
    });

    const { isSomeElementDragging } = useDragLayer((monitor) => ({
        isSomeElementDragging: monitor.getItemType() === CARD ? monitor.isDragging() : false,
    }));

    drag(drop(ref));

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    useEffect(() => {
        if (!isOver && state.hoverPart !== 0) {
            state.hoverPart = 0;

            resetPadding();
        }
    }, [isOver]);

    const state = useLocalStore(() => ({
        // 0 - not hovered, 1 - top, -1 - bottom
        hoverPart: 0,
    }));

    const classes = useStyles({ isDragging, isSomeElementDragging });

    return useObserver(() => (
        <Box ref={ref} className={clsx(classes.container, className)}>
            {state.hoverPart === 1 ? <DraggingCardDivider hoverPart={1} /> : null}
            <Box ref={childRef} className={classes.childContainer}>
                {children}
            </Box>
            {state.hoverPart === -1 ? <DraggingCardDivider hoverPart={-1} /> : null}
        </Box>
    ));
};

export default DraggableContainer;
