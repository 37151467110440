import { types } from "mobx-state-tree";

import DomainObject from "api/security/Types/DomainObject";
import Permissions from "api/security/acl/Types/Permissions";
import AceAppliedTo from "api/security/acl/Types/AceAppliedTo";

const Ace = types.model({
    domainObject: types.maybe(DomainObject),
    permissions: types.maybe(Permissions),
    allow: types.boolean,
    isInherited: types.boolean,
    onlyForTheFirstChildren: types.boolean,
    appliedTo: types.maybe(AceAppliedTo)
});

export default Ace;
