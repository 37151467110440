import React, { useEffect, useContext, useCallback } from "react";
import { Context } from "store";
import { useTranslation } from "react-i18next";
import { useLocalStore, useObserver } from "mobx-react";
import DataCard from "components/DataCard";
import { Grid, Typography, FormControlLabel } from "@material-ui/core";
import ResetWarningDialog from "../ResetWarningDialog";
import createFields from "./createFields";
import createForm from "utils/createForm";
import TextInput from "components/MobxForm/TextInput";
import ErrorButton from "components/ErrorButton";
import RadioGroup from "components/MobxForm/RadioGroup";
import Switch from "components/MobxForm/Switch";
import Radio from "components/Radio/Radio";
import { useStyles } from "./MasterResetCard.styles";
import { SHUTDOWN, RESTART } from "const/rebootAfterResetConst";

const MasterResetCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const state = useLocalStore(() => ({
        isDialogOpen: false,
        inProgress: false,
        form: createForm({
            fields: createFields()
        })
    }));
    const {
        store: { masterResetStore }
    } = useContext(Context);

    useEffect(() => {
        state.form.reset();
        state.form.$("password").resetValidation();
    }, []);

    const openDialog = e => {
        state.form.submit(e);
        if (!state.form.isValid) return;
        state.isDialogOpen = true;
    };
    const closeDialog = () => {
        state.isDialogOpen = false;
    };

    const masterReset = useCallback(async () => {
        closeDialog();
        state.inProgress = true;
        const res = await masterResetStore.masterReset({
            keepActivations: !state.form.$("keepActivations").value,
            rebootAfterReset: !!(state.form.$("rebootAfterReset").value !== SHUTDOWN),
            password: state.form.$("password").value
        });
        state.inProgress = false;
        res && state.form.$("password").clear();
        res && state.form.$("password").resetValidation();
        return res;
    });

    return useObserver(() => (
        <DataCard title={t("master_reset.master_reset_card.title")}>
            <Grid container spacing={5} direction={"column"}>
                <Grid container item>
                    <Typography variant={"body2"}>{t("master_reset.master_reset_card.content")}</Typography>
                </Grid>
                <Grid container item className={classes.radioContainer}>
                    <RadioGroup field={state.form.$("rebootAfterReset")}>
                        <FormControlLabel
                            className={classes.controlLabel}
                            control={<Radio value={SHUTDOWN} />}
                            label={t("master_reset.master_reset_card.label.reboot_after_reset.shutdown")}
                        />
                        <FormControlLabel
                            className={classes.controlLabel}
                            control={<Radio value={RESTART} />}
                            label={t("master_reset.master_reset_card.label.reboot_after_reset.restart")}
                        />
                    </RadioGroup>
                </Grid>
                <Grid container item>
                    <FormControlLabel
                        className={classes.switchLabel}
                        control={<Switch field={state.form.$("keepActivations")} />}
                        label={t("master_reset.master_reset_card.reset_keys")}
                    />
                </Grid>
                <Grid container item alignItems={"center"}>
                    <Grid item xs={3}>
                        <Typography>{t("master_reset.master_reset_card.label.password")}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextInput
                            field={state.form.$("password")}
                            inputProps={{
                                autoComplete: "new-password"
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container item>
                    <ErrorButton
                        inProgress={state.inProgress}
                        submit={openDialog}
                        label={t("master_reset.master_reset_card.reset_button")}
                    />
                </Grid>
            </Grid>
            <ResetWarningDialog onClose={closeDialog} open={state.isDialogOpen} submit={masterReset} />
        </DataCard>
    ));
};

export default MasterResetCard;
