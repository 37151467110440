import { types } from "mobx-state-tree";

import ReplaceState from "api/drive_replace/Types/ReplaceState";

const GetReplaceStateResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(ReplaceState)
});

export default GetReplaceStateResult;
