import Request from "api/Request";

const GetPowerStatus = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Power",
    method: "get_power_status"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetPowerStatus;
