import { types } from "mobx-state-tree";

const ScheduleType = types.model("Schedule", {
    minutes: types.number,
    date: types.string
});
const TaskArgumentsType = types.model("TaskArgumentsType", {
    subject: types.string,
    recipients: types.string,
    body: types.string
});

const CreateAutomationArguments = types.model("CreateAutomationArguments", {
    deleted: types.boolean,
    enabled: types.boolean,
    id: types.maybeNull(types.number),
    internal: types.boolean,
    name: types.string,
    otf: types.boolean,
    paused: types.boolean,
    filter: types.model("CreateAutomationFilter", {
        id: types.maybeNull(types.number),
        rules: types.array(
            types.model("CreateAutomationRules", {
                file_type: types.string,
                regexp: types.boolean,
                case_sensitive: types.boolean,
                name_mask: types.string,
                black: types.boolean,
                id: types.maybeNull(types.number),
                depth: types.number,
                priority: types.number,
                url: types.string,
                watch_mask: types.number
            })
        )
    }),
    schedule: types.maybeNull(
        types.model("CreateAutomationSchedule", {
            id: types.maybeNull(types.number),
            schedule: types.snapshotProcessor(ScheduleType, {
                postProcessor: snapshot => (snapshot ? JSON.stringify(snapshot) : "{}")
            })
        })
    ),
    tasks: types.maybe(
        types.array(
            types.model("CreateAutomationTask", {
                action: types.model("TaskAction", {
                    action: types.maybe(types.string),
                    id: types.maybe(types.number),
                    installed: types.maybe(types.boolean),
                    manifest: types.maybe(types.string),
                    name: types.maybe(types.string)
                }),
                arguments: types.snapshotProcessor(TaskArgumentsType, {
                    postProcessor: snapshot => (snapshot ? JSON.stringify(snapshot) : "{}")
                }),
                automation_id: types.maybeNull(types.number),
                enabled: types.boolean,
                event_object_types: types.array(types.string),
                fail_automation_if_failed: types.boolean,
                group_action: types.maybeNull(types.string),
                mandatory: types.boolean,
                restart_if_failed: types.boolean,
                sequence: types.optional(types.number, 0)
            })
        )
    )
});

export default CreateAutomationArguments;
