import { types } from "mobx-state-tree";

import DnsServers from "api/net/Types/DnsServers";

const DnsServersResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(DnsServers)
});

export default DnsServersResult;
