export const TrashSettingsConstants = {
    ClearInterval: {
        NEVER: "never",
        AFTER_1_DAY: "after_1_day",
        AFTER_2_DAYS: "after_2_days",
        AFTER_3_DAYS: "after_3_days",
        AFTER_1_WEEK: "after_1_week",
        AFTER_2_WEEK: "after_2_week",
        AFTER_4_WEEK: "after_4_week",
        AFTER_2_MONTHS: "after_2_months",
        AFTER_4_MONTHS: "after_4_months"
    }
};

export const clearIntervalToTrKey = clearInterval => {
    switch (clearInterval) {
        case TrashSettingsConstants.ClearInterval.NEVER: {
            return "advanced.nas_recycle_bin.select.never";
        }
        case TrashSettingsConstants.ClearInterval.AFTER_1_DAY: {
            return "advanced.nas_recycle_bin.select.1day";
        }
        case TrashSettingsConstants.ClearInterval.AFTER_2_DAYS: {
            return "advanced.nas_recycle_bin.select.2days";
        }
        case TrashSettingsConstants.ClearInterval.AFTER_3_DAYS: {
            return "advanced.nas_recycle_bin.select.3days";
        }
        case TrashSettingsConstants.ClearInterval.AFTER_1_WEEK: {
            return "advanced.nas_recycle_bin.select.1week";
        }
        case TrashSettingsConstants.ClearInterval.AFTER_2_WEEK: {
            return "advanced.nas_recycle_bin.select.2weeks";
        }
        case TrashSettingsConstants.ClearInterval.AFTER_4_WEEK: {
            return "advanced.nas_recycle_bin.select.4weeks";
        }
        case TrashSettingsConstants.ClearInterval.AFTER_2_MONTHS: {
            return "advanced.nas_recycle_bin.select.2months";
        }
        case TrashSettingsConstants.ClearInterval.AFTER_4_MONTHS: {
            return "advanced.nas_recycle_bin.select.4months";
        }
        default: {
            return "unknown";
        }
    }
};
