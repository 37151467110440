import { types } from "mobx-state-tree";

import Request from "api/Request";
import RemovePeerArguments from "api/cluster/Types/RemovePeerArguments";

const UpdateRemoteBricks = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Cluster",
    method: "update_remote_bricks",
    arguments: types.maybe(RemovePeerArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default UpdateRemoteBricks
