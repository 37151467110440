import Request from "api/Request";

const GetEnabled = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Cache",
    method: "get_enabled"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetEnabled;
