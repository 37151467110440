import { types } from "mobx-state-tree";

import LdapStatus from "api/security/users/Types/LdapStatus";

const GetLdapStatusResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(LdapStatus)
});

export default GetLdapStatusResult;
