import React from "react";
import { useObserver } from "mobx-react";
import MainTemplate from "components/MainTemplate";
import { Grid } from "@material-ui/core";
import SharesTabContent from "./components/SharesTabContent";
import RemoteSharesTabContent from "./components/RemoteSharesTabContent";
import SharingIcon from "assets/SharingIcon";
import HeaderTitle from "components/HeaderTitle";
import SharesBulkOperationsBar from "./components/SharesBulkOperationsBar";
import { useTranslation } from "react-i18next";
import HeaderTabs from "components/HeaderTabs";
import ShareDrawer from "./components/ShareDrawer";
import { useStyles } from "./Shares.styles.js";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { URL_SHARES, URL_SHARES_REMOTE_TAB } from "routes";
import { SHARES, REMOTE_SHARES } from "const/shortcutNameConst";
import useUrlUserPermission from "hooks/useUrlUserPermission";
import { ADMIN } from "const/userRolesConst";
import TABS from "./Shares.tabs";
import { SHARES_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";
import { useStore } from "hooks/useStore";

const Shares = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { pathname } = useLocation();

    const getShortcutName = () => (pathname === URL_SHARES_REMOTE_TAB ? REMOTE_SHARES : SHARES);

    const { urlToRedirect } = useUrlUserPermission();
    const {
        store: { authStore },
    } = useStore();

    return useObserver(() => (
        <MainTemplate
            tabIndex={0}
            headerTitle={<HeaderTitle icon={<SharingIcon />} title={t("share.title")} shortcutName={getShortcutName()} />}
        >
            <>
                <SharesBulkOperationsBar />
                <ShareDrawer />
                <Grid container direction={"column"} justify={"flex-start"} alignContent={"flex-start"}>
                    <HeaderTabs className={classes.headerTabs} tabs={TABS} pageId={SHARES_PAGE_ID_PREFIX} />
                    <Grid item container direction={"column"} spacing={6} justify={"flex-start"}>
                        <Switch>
                            <Route exact path={URL_SHARES} component={SharesTabContent} />
                            {authStore.role === ADMIN && (
                                <Route exact path={URL_SHARES_REMOTE_TAB} component={RemoteSharesTabContent} />
                            )}
                            <Redirect to={urlToRedirect} />
                        </Switch>
                    </Grid>
                </Grid>
            </>
        </MainTemplate>
    ));
};

export default Shares;
