import { makeStyles } from "@material-ui/core/styles";
import { HEADER_QUAD_ITEM_BACKGROUND_COLOR, HEADER_QUAD_ITEM_MAXWIDTH } from "const/styleConst";

export const useStyles = makeStyles((theme) => ({
    quadTitle: {
        fontSize: 10,
        opacity: 0.6,
        padding: theme.spacing(0.5),
    },
    quadBody: {
        padding: (props) => (props.isLarge ? 0 : theme.spacing(1, 3, 1, 2)),
        backgroundColor: (props) => (props.isLarge ? "none" : HEADER_QUAD_ITEM_BACKGROUND_COLOR),
        borderRadius: theme.spacing(0.5),
        maxWidth: HEADER_QUAD_ITEM_MAXWIDTH,
    },
}));
