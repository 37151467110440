import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    contentContainer: {
        width: "100%",
        margin: 0,
        padding: theme.spacing(0, 4, 8, 4)
    },
    link: {
        textDecoration: "none",
        color: theme.palette.primary.main
    }
}));
