import { types } from "mobx-state-tree";

import Request from "api/Request";
import SetDashboardLayoutArguments from "api/ui_settings/Types/SetDashboardLayoutArguments";

const SetDashboardLayout = Request.props({
    path: "/com/studionetworksolutions/evo/manage/UiSettings",
    method: "set_dashboard_layout",
    arguments: types.maybe(SetDashboardLayoutArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetDashboardLayout;
