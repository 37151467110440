import { types } from "mobx-state-tree";

import Request from "api/Request";
import ListSharesOnRemoteHostArguments from "api/external_shares/Types/ListSharesOnRemoteHostArguments";

const ListSharesOnRemoteHost = Request.props({
    path: "/com/studionetworksolutions/evo/ExternalShares",
    method: "list_shares_on_remote_host",
    arguments: types.maybe(ListSharesOnRemoteHostArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ListSharesOnRemoteHost;
