import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: theme.spacing(4, 6, 4, 0),
    },
    icon: {
        width: theme.spacing(1),
        height: theme.spacing(8),
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
        marginRight: theme.spacing(5),
        backgroundColor: theme.palette.primary.main,
    },
    invisibleIcon: {
        opacity: 0,
    },
    content: {
        flex: 1,
        padding: theme.spacing(0, 3, 0, 0),
    },
}));
