import { types } from "mobx-state-tree";

import Request from "api/Request";
import CsrInfo from "api/certificate/Types/CsrInfo";

const CreateCertificateSigningRequest = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Certificates",
    method: "create_certificate_signing_request",
    arguments: types.maybe(CsrInfo)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default CreateCertificateSigningRequest;
