import React from "react";

const CheckboxDisabledCheckedIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="#D9E4ED" fillRule="nonzero">
                <path
                    d="M20 0c2.21 0 4 1.79 4 4v16c0 2.21-1.79 4-4 4H4c-2.21 0-4-1.79-4-4V4c0-2.21 1.79-4 4-4h16z"
                    opacity="0.4"
                />
                <path d="M16.804 8.282a1 1 0 011.477 1.342l-.085.094-7.378 7.158-4.495-4.14a1 1 0 011.257-1.551l.097.08 3.105 2.859 6.022-5.842z" />
            </g>
        </svg>
    );
};

export default CheckboxDisabledCheckedIcon;
