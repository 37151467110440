import { types } from "mobx-state-tree";

import Volume from "api/volume/Types/Volume";

const VolumesResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.array(Volume)
});

export default VolumesResult;
