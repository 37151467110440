import { makeStyles } from "@material-ui/core/styles";
import hexToRgba from "utils/hexToRgba";

export const useStyles = makeStyles(theme => {
    const getColor = status => {
        switch (status.toLowerCase()) {
            case "succeed":
                return theme.palette.success.main;
            case "in progress":
            case "paused":
            case "waited for restart":
                return theme.palette.warning.main;
            case "canceled":
                return theme.palette.grey[500];
            case "failed":
                return theme.palette.error.main;
            case "partially failed":
                return theme.palette.error.light;

            default:
                return theme.palette.error.main;
        }
    };

    return {
        label: {
            padding: theme.spacing(0, 2),
            borderRadius: theme.spacing(1),
            backgroundColor: props => hexToRgba(getColor(props.status), 0.1),
            "& span": {
                color: props => getColor(props.status)
            }
        },
        labelText: {
            fontWeight: 500,
            textTransform: "capitalize"
        }
    };
});
