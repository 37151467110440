import React, { useEffect } from "react";
import Dialog from "components/Dialog/Dialog";
import { useProxyGenerationSettings } from "hooks/modals";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@material-ui/core";
import Select from "components/MobxForm/Select";
import { observer, useLocalStore, useObserver } from "mobx-react";
import createForm from "utils/createForm";
import { EDIT_PROXY_OPTIONS, FIELDS, SHARE_BROWSER_OPTIONS } from "const/slingshotProxyGenerationSettingsConst";
import { useStore } from "hooks/useStore";
import { autorun } from "mobx";

const PROXY_GENERATION_SETTINGS_MODAL_ID_PREFIX = `proxy_generation_settings_modal`;

const ProxyGenerationSettingsModal = ({ parentId }) => {
    const idPrefix = parentId
        ? `${parentId}_${PROXY_GENERATION_SETTINGS_MODAL_ID_PREFIX}`
        : PROXY_GENERATION_SETTINGS_MODAL_ID_PREFIX;
    const proxyGenerationSettingsModal = useProxyGenerationSettings();
    const { t } = useTranslation();

    const {
        store: { slingshotStorageStore, indexingConfigStore },
    } = useStore();
    const state = useLocalStore(() => ({
        form: createForm({
            fields: FIELDS,
        }),
        inProgress: false,
        startProgress() {
            this.inProgress = true;
        },
        endProgress() {
            this.inProgress = true;
        },
        getCurrentShareMediaProxy: (name) =>
            indexingConfigStore.mediaProxyShares.has(name)
                ? indexingConfigStore.mediaProxyShares?.get(name)
                : !!indexingConfigStore.mediaProxy?.enabledDefault,
        getCurrentShareEditProxy: (name) =>
            indexingConfigStore.editProxyShares.has(name)
                ? indexingConfigStore.editProxyShares?.get(name)
                : indexingConfigStore.editProxy?.enabledDefault
                ? EDIT_PROXY_OPTIONS[1].value
                : EDIT_PROXY_OPTIONS[0].value,
        getManySharesMediaProxy: (checkedSharesNames) => {
            const mediaProxyValues = checkedSharesNames
                .filter((el) => indexingConfigStore.mediaProxyShares.get(el))
                .map((el) => indexingConfigStore.mediaProxyShares.get(el));

            if (checkedSharesNames.length !== mediaProxyValues.length) return SHARE_BROWSER_OPTIONS[0].value;
            if (mediaProxyValues.includes(SHARE_BROWSER_OPTIONS[0].value)) return SHARE_BROWSER_OPTIONS[0].value;
            return SHARE_BROWSER_OPTIONS[1].value;
        },
        getManySharesEditProxy: (checkedSharesNames) => {
            const editProxyValues = checkedSharesNames
                .filter((el) => indexingConfigStore.editProxyShares.get(el))
                .map((el) => indexingConfigStore.editProxyShares.get(el));

            if (checkedSharesNames.length !== editProxyValues.length) return EDIT_PROXY_OPTIONS[0].value;
            if (editProxyValues.includes(EDIT_PROXY_OPTIONS[0].value)) return EDIT_PROXY_OPTIONS[0].value;
            if (editProxyValues.includes(EDIT_PROXY_OPTIONS[2].value)) return EDIT_PROXY_OPTIONS[2].value;
            return EDIT_PROXY_OPTIONS[1].value;
        },
    }));

    useEffect(() => {
        autorun(() => {
            const enabledShareBrowser = slingshotStorageStore.currentShare?.name
                ? state.getCurrentShareMediaProxy(slingshotStorageStore.currentShare?.name)
                : state.getManySharesMediaProxy(slingshotStorageStore.checkedSharesNames);
            const enabledEditProxy = slingshotStorageStore.currentShare?.name
                ? state.getCurrentShareEditProxy(slingshotStorageStore.currentShare?.name)
                : state.getManySharesEditProxy(slingshotStorageStore.checkedSharesNames);
            state.form.$("shareBrowser").set(enabledShareBrowser);
            state.form.$("editProxy").set(enabledEditProxy);
        });
    }, []);

    const onSubmit = async (e) => {
        state.startProgress();
        state.form.onSubmit(e);

        const payload = { ...indexingConfigStore.indexerConfigFromRawData };
        const shares = slingshotStorageStore.currentShare?.name
            ? [slingshotStorageStore.currentShare?.name]
            : slingshotStorageStore.checkedSharesNames;

        shares.forEach((share) => {
            payload.editProxy.shares = { ...payload.editProxy.shares, [share]: state.form.$("editProxy").value };
            payload.mediaProxy.shares = { ...payload.mediaProxy.shares, [share]: state.form.$("shareBrowser").value };
        });

        const res = await indexingConfigStore.setConfigParameters(payload);

        state.endProgress();
        return res;
    };

    return useObserver(() => (
        <Dialog
            title={t("slingshot.index_proxy.proxy_generation.action.edit_proxies_generation_setting.title", {
                shareName: slingshotStorageStore.currentShare?.name ?? slingshotStorageStore.checkedSharesNames.length,
            })}
            onSubmit={onSubmit}
            open={proxyGenerationSettingsModal.isOpen}
            onClose={proxyGenerationSettingsModal.close}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${idPrefix}_save`}
            buttons={[
                <Button
                    key="cancelBtn"
                    onClick={proxyGenerationSettingsModal.close}
                    variant={"contained"}
                    color="secondary"
                    id={`${idPrefix}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>,
            ]}
        >
            <Grid item container alignItems={"center"} spacing={4}>
                <Grid item xs={4}>
                    <Typography>
                        {t("slingshot.index_proxy.proxy_generation.action.edit_proxies_generation_setting.share_browser_select")}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Select
                        field={state.form.$("shareBrowser")}
                        options={SHARE_BROWSER_OPTIONS}
                        id={`${idPrefix}_share_browser`}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Typography>
                        {t("slingshot.index_proxy.proxy_generation.action.edit_proxies_generation_setting.edit_proxy_select")}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Select field={state.form.$("editProxy")} options={EDIT_PROXY_OPTIONS} id={`${idPrefix}_share_browser`} />
                </Grid>
            </Grid>
        </Dialog>
    ));
};
export default observer(ProxyGenerationSettingsModal);
