import { types } from "mobx-state-tree";

import Request from "api/Request";
import GetAllowedHostsArguments from "api/security/acl/Types/GetAllowedHostsArguments";

const GetAllowedHosts = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Acl",
    method: "get_allowed_hosts",
    arguments: types.maybe(GetAllowedHostsArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetAllowedHosts
