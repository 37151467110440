import React from "react";
import { useObserver, useLocalStore } from "mobx-react";
import { useStore } from "hooks/useStore";
import { useTranslation } from "react-i18next";
import DetailsActionsDrawer from "components/DetailsActionsDrawer";
import BulkBarFlatButton from "components/BulkBarFlatButton";
import { Grid, Typography, Box, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import TrashIcon from "assets/TrashIcon";
import CloseIcon from "assets/CloseIcon";
import { VOLUME_STATUS_OPTIONS, VOLUME_TYPE_OPTIONS, EDIT_VOLUME_MODES } from "const/clusterConst";
import useDrawerOpenHandler from "hooks/useDrawerOpenHandler";
import ClusterVolumeIcon from "assets/ClusterVolumeIcon";
import VolumeBrick from "../VolumeBrick";
import { useStyles } from "./VolumeDrawer.styles";
import clsx from "clsx";
import ChevronUpIcon from "assets/ChevronUpIcon";
import GrowPoolIcon from "assets/GrowPoolIcon";
import GraphIcon from "assets/GraphIcon";
import RunIcon from "assets/RunIcon";
import { useModal } from "hooks/useModal";
import EditVolumeBricksDialog from "../EditVolumeBricksDialog";
import RemoveBricksStatusDialog from "../RemoveBricksStatusDialog";
import RebalanceStatusModal from "../RebalanceStatusModal";

const drawerName = "volumeDrawer";
const VolumeDrawer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const editVolumeModal = useModal();
    const removeBricksStatusModal = useModal();
    const rebalanceStatusModal = useModal();

    const {
        store: { clusterStore }
    } = useStore();

    const state = useLocalStore(() => ({
        isStopDiskInProgress: false,
        isStartDiskInProgress: false,
        isRemoveDiskInProgress: false,
        isRebalanceFixLayoutStartInProgress: false,
        isRebalanceStartInProgress: false,
        isRebalanceStopInProgress: false,
        isRebalanceStatusInProgress: false,
        advancedExpanded: false,
        advancedExpandedRebalance: false,
        bricksMode: null
    }));

    const closeDrawer = () => {
        state.advancedExpanded = false;
        state.advancedExpandedRebalance = false;
        clusterStore.setCurrentVolumeName(null);
    };

    const stopDisk = async () => {
        state.isStopDiskInProgress = true;
        await clusterStore.stopVolume({ volume: clusterStore.currentVolumeName });
        state.isStopDiskInProgress = false;
    };

    const startDisk = async () => {
        state.isStartDiskInProgress = true;
        await clusterStore.startVolume({ volume: clusterStore.currentVolumeName });
        state.isStartDiskInProgress = false;
    };

    const removeDisk = async () => {
        state.isRemoveDiskInProgress = true;
        const res = await clusterStore.removeVolume({
            volume: clusterStore.currentVolumeName
        });
        state.isRemoveDiskInProgress = false;
        res && closeDrawer();
    };

    const addBricks = () => {
        editVolumeModal.open();
        state.bricksMode = EDIT_VOLUME_MODES.add;
    };

    const removeBricks = () => {
        editVolumeModal.open();
        state.bricksMode = EDIT_VOLUME_MODES.remove;
    };

    const replaceBricks = () => {
        editVolumeModal.open();
        state.bricksMode = EDIT_VOLUME_MODES.replace;
    };

    const removeBricksStatus = () => {
        removeBricksStatusModal.open();
    };

    const rebalanceFixLayoutStart = async () => {
        state.isRebalanceFixLayoutStartInProgress = true;
        await clusterStore.rebalanceFixLayoutStart({ volume: clusterStore.currentVolumeName });
        const status = await clusterStore.rebalanceStatus({ volume: clusterStore.currentVolumeName });
        state.isRebalanceFixLayoutStartInProgress = false;
        status && rebalanceStatusModal.open();
    };

    const rebalanceStart = async () => {
        state.isRebalanceStartInProgress = true;
        await clusterStore.rebalanceStart({ volume: clusterStore.currentVolumeName });
        state.isRebalanceStartInProgress = false;
    };

    const rebalanceStop = async () => {
        state.isRebalanceStopInProgress = true;
        await clusterStore.rebalanceStop({ volume: clusterStore.currentVolumeName });
        state.isRebalanceStopInProgress = false;
    };

    const rebalanceStatus = async () => {
        state.isRebalanceStatusInProgress = true;
        const status = await clusterStore.rebalanceStatus({ volume: clusterStore.currentVolumeName });
        state.isRebalanceStatusInProgress = false;
        status && rebalanceStatusModal.open();
    };

    useDrawerOpenHandler({ drawerName, closeDrawer, targetDep: clusterStore.currentVolumeName });

    const renderDetails = () => {
        return (
            clusterStore.currentVolume && (
                <Box py={4} px={6}>
                    <Grid container direction={"column"} spacing={4}>
                        {clusterStore.volumeBricks?.map(brick => (
                            <React.Fragment key={`${brick.name}:${brick.peer}/${brick.path}`}>
                                <VolumeBrick brick={brick} />
                            </React.Fragment>
                        ))}
                    </Grid>
                </Box>
            )
        );
    };

    const renderBricksAccordion = () => {
        return (
            <Accordion
                className={classes.expansionPanel}
                square
                expanded={state.advancedExpanded}
                onChange={(_, newExpanded) => {
                    state.advancedExpanded = newExpanded;
                }}
            >
                <AccordionSummary className={classes.expansionPanelContent}>
                    <Grid item container wrap={"nowrap"} alignItems={"center"}>
                        <Box mr={2} mb={-1}>
                            <GrowPoolIcon />
                        </Box>
                        <Typography variant={"button"} color={"primary"}>
                            {t("cluster.volume.button.bricks")}
                        </Typography>
                    </Grid>
                    <ChevronUpIcon
                        className={clsx(!state.advancedExpanded && classes.expansionIconClose, classes.expansionIcon)}
                    />
                </AccordionSummary>
                <AccordionDetails className={classes.detailsRoot}>
                    <Grid direction={"column"} container>
                        <BulkBarFlatButton className={classes.accordionButton} onClick={addBricks}>
                            {t("common.button.add")}
                        </BulkBarFlatButton>
                        {clusterStore.volumeBricks?.length > 1 && (
                            <BulkBarFlatButton className={classes.accordionButton} onClick={removeBricks}>
                                {t("common.button.remove")}
                            </BulkBarFlatButton>
                        )}
                        {clusterStore.currentVolume?.type !== VOLUME_TYPE_OPTIONS.simple && (
                            <BulkBarFlatButton className={classes.accordionButton} onClick={replaceBricks}>
                                {t("cluster.volume.button.replace")}
                            </BulkBarFlatButton>
                        )}
                        {clusterStore.currentVolume?.status === VOLUME_STATUS_OPTIONS.needCommit && (
                            <BulkBarFlatButton className={classes.accordionButton} onClick={removeBricksStatus}>
                                {t("cluster.volume.button.remove_brick_status")}
                            </BulkBarFlatButton>
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    };

    const renderRebalanceAccordion = () => {
        return (
            <Accordion
                className={classes.expansionPanel}
                square
                expanded={state.advancedExpandedRebalance}
                onChange={(_, newExpanded) => {
                    state.advancedExpandedRebalance = newExpanded;
                }}
            >
                <AccordionSummary className={classes.expansionPanelContent}>
                    <Grid item container wrap={"nowrap"} alignItems={"center"}>
                        <Box mr={2} mb={-1}>
                            <GraphIcon />
                        </Box>
                        <Typography variant={"button"} color={"primary"}>
                            {t("cluster.volume.button.rebalance")}
                        </Typography>
                    </Grid>
                    <ChevronUpIcon
                        className={clsx(!state.advancedExpandedRebalance && classes.expansionIconClose, classes.expansionIcon)}
                    />
                </AccordionSummary>
                <AccordionDetails className={classes.detailsRoot}>
                    <Grid direction={"column"} container>
                        <BulkBarFlatButton
                            className={classes.accordionButton}
                            inProgress={state.isRebalanceFixLayoutStartInProgress}
                            onClick={rebalanceFixLayoutStart}
                        >
                            {t("cluster.volume.button.rebalance.fix_layout_start")}
                        </BulkBarFlatButton>
                        <BulkBarFlatButton
                            className={classes.accordionButton}
                            inProgress={state.isRebalanceStartInProgress}
                            onClick={rebalanceStart}
                        >
                            {t("cluster.volume.button.rebalance.start")}
                        </BulkBarFlatButton>
                        <BulkBarFlatButton
                            className={classes.accordionButton}
                            inProgress={state.isRebalanceStopInProgress}
                            onClick={rebalanceStop}
                        >
                            {t("cluster.volume.button.rebalance.stop")}
                        </BulkBarFlatButton>
                        <BulkBarFlatButton
                            className={classes.accordionButton}
                            inProgress={state.isRebalanceStatusInProgress}
                            onClick={rebalanceStatus}
                        >
                            {t("cluster.volume.button.rebalance.status")}
                        </BulkBarFlatButton>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    };

    const renderActions = () => {
        return (
            clusterStore.currentVolume && (
                <Grid direction={"column"} container>
                    {clusterStore.currentVolume?.status !== VOLUME_STATUS_OPTIONS.stopped &&
                        clusterStore.currentVolume?.status !== VOLUME_STATUS_OPTIONS.created && (
                            <BulkBarFlatButton onClick={stopDisk} inProgress={state.isStopDiskInProgress} icon={<CloseIcon />}>
                                {t("common.button.stop")}
                            </BulkBarFlatButton>
                        )}
                    {clusterStore.currentVolume?.status === VOLUME_STATUS_OPTIONS.stopped && (
                        <BulkBarFlatButton onClick={startDisk} inProgress={state.isStartDiskInProgress} icon={<RunIcon />}>
                            {t("common.button.start")}
                        </BulkBarFlatButton>
                    )}
                    {renderBricksAccordion()}
                    {(clusterStore.currentVolume?.type === VOLUME_TYPE_OPTIONS.simpleGrid ||
                        clusterStore.currentVolume?.type === VOLUME_TYPE_OPTIONS.simpleMirror) &&
                        clusterStore.currentVolume?.status === VOLUME_STATUS_OPTIONS.started &&
                        renderRebalanceAccordion()}
                    {clusterStore.currentVolume?.status === VOLUME_STATUS_OPTIONS.stopped && (
                        <BulkBarFlatButton
                            onClick={removeDisk}
                            inProgress={state.isRemoveDiskInProgress}
                            icon={<TrashIcon />}
                            errorColor
                        >
                            {t("common.button.delete")}
                        </BulkBarFlatButton>
                    )}
                </Grid>
            )
        );
    };

    return useObserver(() => (
        <DetailsActionsDrawer
            title={clusterStore.currentVolume?.name || ""}
            icon={<ClusterVolumeIcon />}
            renderActions={renderActions}
            renderDetails={renderDetails}
            closeDrawer={closeDrawer}
            drawerName={drawerName}
            modals={[
                <EditVolumeBricksDialog
                    key={"EditVolumeBricksDialog"}
                    mode={state.bricksMode}
                    onClose={editVolumeModal.close}
                    open={editVolumeModal.isOpen}
                />,
                <RemoveBricksStatusDialog
                    key={"RemoveBricksStatusDialog"}
                    onClose={removeBricksStatusModal.close}
                    open={removeBricksStatusModal.isOpen}
                />,
                <RebalanceStatusModal
                    key={"RebalanceStatusDialog"}
                    onClose={rebalanceStatusModal.close}
                    open={rebalanceStatusModal.isOpen}
                />
            ]}
        />
    ));
};

export default VolumeDrawer;
