import { types } from "mobx-state-tree";

import AvailableNetActivations from "api/activation/interfaces/Types/AvailableNetActivations";

const GetActivationsCountResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(AvailableNetActivations)
});

export default GetActivationsCountResult;
