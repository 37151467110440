import { types } from "mobx-state-tree";

import Volume from "api/cluster/Types/Volume";

const GetVolumesResultData = types.model({
    arr: types.array(Volume),
    total: types.number
});

export default GetVolumesResultData;
