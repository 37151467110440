import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography, Button } from "@material-ui/core";
import ErrorButton from "components/ErrorButton";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { HARDWARE_ACTIVATION_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const QUADS_ACTIVATION_WARNING_DIALOG_ID_PREFIX = `${HARDWARE_ACTIVATION_PAGE_ID_PREFIX}_quads_activation_warning_dialog`;

const QuadsActivationWarningDialog = ({ open, onClose, onKeep, onDestroy }) => {
    const { t } = useTranslation();

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={t("hardware_activation.warning_dialog.header")}
            submitBtn={
                <ErrorButton
                    label={t("hardware_activation.warning_dialog.button_destroy")}
                    submit={onDestroy}
                    id={`${QUADS_ACTIVATION_WARNING_DIALOG_ID_PREFIX}_destroy`}
                />
            }
            buttons={[
                <Button
                    onClick={onClose}
                    variant={"contained"}
                    color={"secondary"}
                    key={Math.floor(Math.random() * Date.now())}
                    id={`${QUADS_ACTIVATION_WARNING_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>,
                <Button
                    onClick={onKeep}
                    variant={"contained"}
                    color={"primary"}
                    key={Math.floor(Math.random() * Date.now())}
                    id={`${QUADS_ACTIVATION_WARNING_DIALOG_ID_PREFIX}_keep`}
                >
                    {t("hardware_activation.warning_dialog.button_keep")}
                </Button>
            ]}
        >
            <Grid container direction={"column"}>
                <Grid item>
                    <Typography>{t("hardware_activation.warning_dialog.message")}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default QuadsActivationWarningDialog;
