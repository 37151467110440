export const LOCAL_USERS = "local_users";
export const EXTERNAL_USERS = "external_users";
export const LOCAL_GROUPS = "local_groups";
export const EXTERNAL_GROUPS = "external_groups";

export const ALLOW = "allow";
export const DENY = "deny";

export const SEARCH_DEFAULTS = {
    type: LOCAL_USERS,
    name: "",
    page: 0,
    limit: 10
};
export const LOCAL_GROUPS_SEARCH_DEFAULTS = {
    type: LOCAL_GROUPS,
    name: "",
    page: 0,
    limit: 10
};

export const getUserTypeTrId = userType => {
    switch (userType) {
        case LOCAL_USERS:
            return "users.common.local_users";
        case LOCAL_GROUPS:
            return "users.common.local_groups";
        case EXTERNAL_USERS:
            return "users.common.external_users";
        case EXTERNAL_GROUPS:
            return "users.common.external_groups";
        default:
            return null;
    }
};

export const getAccessTtId = access => {
    switch (access) {
        case ALLOW:
            return "users.customize_settings_modal.table.type.allow";
        case DENY:
            return "users.customize_settings_modal.table.type.deny";
        default:
            return null;
    }
};
