import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography, Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";

const WarningDialog = ({ open, onClose, submit, submitBtnLabel, cancelBtnLabel, content, title, idPrefix }) => {
    const { t } = useTranslation();

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            title={title}
            withoutIconClose
            withoutDividers
            buttons={
                <Button onClick={onClose} variant={"contained"} color={"secondary"} id={`${idPrefix}_cancel`}>
                    {cancelBtnLabel || t("common.button.cancel")}
                </Button>
            }
            submitBtn={<AnimatedSubmitButton label={submitBtnLabel} submit={submit} id={`${idPrefix}_submit`} />}
        >
            <Grid container direction={"column"} spacing={5}>
                <Grid item>
                    <Typography>{content}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default WarningDialog;
