import { types } from "mobx-state-tree";
import Request from "api/Request";
import ProxySettingsResultType from "../Types/ProxySettingsResultType";

const setProxySettings = Request.props({
    path: "/com/studionetworksolutions/evo/slingshot/Indexing1",
    method: "set_proxy_settings",
    arguments: types.maybe(ProxySettingsResultType)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default setProxySettings;
