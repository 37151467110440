import { makeStyles } from "@material-ui/core/styles";
import { NOTIFICATION_MENU_WIDTH, PIN_BUTTON_ZINDEX, GREY_BLUE } from "const/styleConst";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        "&:hover > div > button": {
            display: "flex",
        },
    },
    notificationMenu: {
        width: NOTIFICATION_MENU_WIDTH,
        padding: theme.spacing(2, 0),
        height: "100%",
        position: "relative",
        boxShadow: theme.palette.notificationMenu.shadow,
    },
    notificationsContainer: {
        height: "100%",
        overflowY: "auto",
    },
    emptyNotificationsContainer: {
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    message: {
        color: GREY_BLUE,
    },
    button: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        border: "solid 1px #e5ecf2",
        borderRadius: "50%",
        backgroundColor: theme.palette.background.paper,
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        left: theme.spacing(-4),
        zIndex: PIN_BUTTON_ZINDEX,
        display: "none",
        "&:hover": {
            borderColor: theme.palette.primary.main,
        },
    },
    pinButton: {
        top: theme.spacing(6),
    },
    settingsButton: {
        top: theme.spacing(18),
    },
    closeButton: {
        top: theme.spacing(30),
    },
    activeButton: {
        display: "flex",
        "& svg path": {
            fill: theme.palette.primary.main,
        },
    },
}));
