import React from "react";
import { useObserver } from "mobx-react";
import { Grid, Typography, Button } from "@material-ui/core";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { DISKS_AND_POOLS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const GROW_POOL_WARNING_DIALOG_ID_PREFIX = `${DISKS_AND_POOLS_PAGE_ID_PREFIX}_grow_pool_warning_dialog`;

const GrowPoolWarningDialog = ({ open, onClose, pool, onSubmit }) => {
    const { t } = useTranslation();

    return useObserver(() => (
        <Dialog
            open={open}
            onClose={onClose}
            withoutIconClose
            withoutDividers
            onSubmit={onSubmit}
            title={t("disks_and_pools.grow_pool_warning_dialog.title")}
            submitBtnLabel={t("common.button.confirm")}
            submitBtnId={`${GROW_POOL_WARNING_DIALOG_ID_PREFIX}_submit`}
            buttons={
                <Button
                    id={`${GROW_POOL_WARNING_DIALOG_ID_PREFIX}_cancel`}
                    onClick={onClose}
                    variant={"contained"}
                    color={"secondary"}
                >
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"} spacing={5}>
                <Grid item>
                    <Typography>{t("disks_and_pools.grow_pool_warning_dialog.content")}</Typography>
                </Grid>
                <Grid item>
                    <Typography>{t("disks_and_pools.grow_pool_warning_dialog.question", { poolName: pool || "" })}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    ));
};

export default GrowPoolWarningDialog;
