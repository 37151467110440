import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    body: {
        margin: 0,
        width: "100%",
        flex: 1,
        padding: theme.spacing(4)
    },
    textInfo: {
        fontWeight: 500,
        paddingLeft: theme.spacing(2),
        wordBreak: "break-word"
    }
}));
