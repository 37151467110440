import { types } from "mobx-state-tree";

import Request from "api/Request";
import TrashFilesArguments from "api/trash/Types/TrashFilesArguments";

const RestoreTrashFiles = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Trash",
    method: "restore_trash_files",
    arguments: types.maybe(TrashFilesArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default RestoreTrashFiles;
