import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    headerContent: {
        maxHeight: theme.spacing(12)
    },
    select: {
        "& .MuiInputBase-root": {
            margin: theme.spacing(-2, -3.25),
            "&:not(.Mui-focused)": {
                background: "none"
            }
        },
        "&& span": {
            opacity: 0.9,
            fontSize: 20,
            fontWeight: "bold",
            lineHeight: 1.2
        }
    },
    indicator: {
        "& p": {
            marginLeft: theme.spacing(2)
        }
    },
    footer: {
        padding: theme.spacing(3, 6)
    },
    radioGroup: {
        maxHeight: theme.spacing(6),
        position: "relative",
        top: "-10px",
        "& div": {
            flexDirection: "row",
            flexWrap: "nowrap"
        },
        "& span": {
            whiteSpace: "nowrap"
        },
        "& p": {
            margin: 0
        },
        "& label": {
            marginRight: theme.spacing(6)
        }
    },
    disabled: {
        "& path": {
            fill: theme.palette.grey["300"]
        }
    }
}));
