export const TABLE_TYPE_USER = "user";
export const TABLE_TYPE_INDEXING = "indexing";
export const TABLE_TYPE_AIML = "aiml";

export const AUTOMATION_REQUEST_BY_TYPE = new Map([
    [TABLE_TYPE_USER, { exclude_bunches: ["__syncjob", "__extvol_registration", "__indexing_job", "__indexer", "__aiml"] }],
    [TABLE_TYPE_INDEXING, { include_bunches: ["__indexer"] }],
    [TABLE_TYPE_AIML, { include_bunches: ["__aiml"] }]
]);

export const SINGLE_FILE_TRANSFER_ACTION_NAME = "SingleFileTransfer";
