import React from "react";
import { observer } from "mobx-react";
import { Checkbox as MuiCheckbox } from "@material-ui/core";
import { useStyles } from "./Checkbox.style";
import CheckboxActiveIcon from "assets/CheckboxActiveIcon";
import CheckboxDisabledIcon from "assets/CheckboxDisabledIcon";
import CheckboxIndeterminateIcon from "assets/CheckboxIndeterminateIcon";
import CheckboxPassiveIcon from "assets/CheckboxPassiveIcon";
import clsx from "clsx";
import CheckboxDisabledCheckedIcon from "assets/CheckboxDisabledCheckedIcon";

const Checkbox = ({ error, disabled, className, omitPadding, ...rest }) => {
    const classes = useStyles();

    return (
        <MuiCheckbox
            className={clsx(classes.container, className, error && classes.error, omitPadding && classes.withoutPadding)}
            icon={disabled ? <CheckboxDisabledIcon /> : <CheckboxPassiveIcon />}
            indeterminateIcon={<CheckboxIndeterminateIcon />}
            checkedIcon={disabled ? <CheckboxDisabledCheckedIcon /> : <CheckboxActiveIcon />}
            disabled={!!disabled}
            {...rest}
        />
    );
};

export default observer(Checkbox);
