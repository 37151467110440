import React from "react";

import Link from "components/Link/Link";
import { Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";

const LocalizationTypography = ({ paragraphs, ...rest }) => {
    const { t } = useTranslation();

    return paragraphs.map(paragraph => {
        if (typeof paragraph === "string") return <Typography {...rest}>{paragraph}</Typography>;

        if (!Array.isArray(paragraph.components)) {
            const { textKey, ...restProps } = paragraph;
            return (
                <Typography {...restProps} key={textKey}>
                    {t(textKey)}
                </Typography>
            );
        }

        const paragraphComponents = paragraph.components?.map(component => {
            if (!component.type) return <Typography {...component.props} />;

            switch (component.type) {
                case "h2": {
                    return (
                        <Typography variant="h2" component="span">
                            {paragraph}
                        </Typography>
                    );
                }
                case "link": {
                    return <Link path={component.path} inNewTab={component.inNewTab} />;
                }
                case "icon": {
                    const Icon = component.icon;
                    if (!component.path) return <Icon />;
                    return <Link path={component.path} inNewTab={component.inNewTab} Icon={Icon} />;
                }
                default:
                    return null;
            }
        });

        return (
            <Typography gutterBottom={paragraph && paragraph.gutterBottom} key={paragraph.textKey} {...rest}>
                <Trans i18nKey={paragraph.textKey} components={paragraphComponents} />
            </Typography>
        );
    });
};

export default LocalizationTypography;
