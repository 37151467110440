import Request from "api/Request";

const IdentifyOff = Request.props({
    path: "/com/studionetworksolutions/evo/SystemDrives",
    method: "identify_off",
}).actions((self) => ({
    setArguments(args) {
        self.arguments = args;
    },
}));

export default IdentifyOff;
