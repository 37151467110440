import { types } from "mobx-state-tree";

const SCHEMA_ENUM_ARRAY = ["alias", "ld", "evo", "afp", "aiml", "azure", "dropbox", "ftp", "s3", "smb", "tricaster", "gcs"];

const DecomposedUrl = types.model({
    decomposed_url: types.maybe(
        types.model({
            schema: types.maybe(types.enumeration(SCHEMA_ENUM_ARRAY)),
            host: types.maybe(types.string),
            path: types.maybe(types.string),
            region: types.maybe(types.string),
            resource: types.maybe(types.string)
        })
    )
});

export default DecomposedUrl;
