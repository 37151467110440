import { types } from "mobx-state-tree";

import Request from "api/Request";
import SetConnectionAutostartArguments from "api/vpn/Types/SetConnectionAutostartArguments";

const SetConnectionAutostart = Request.props({
    path: "/com/studionetworksolutions/evo/manage/VPN",
    method: "set_connection_autostart",
    arguments: types.maybe(SetConnectionAutostartArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default SetConnectionAutostart;
