import React, { useEffect } from "react";
import { useLocalStore, useObserver } from "mobx-react";
import createForm from "utils/createForm";
import { useStyles } from "./SetupGlobalMasksDialog.styles";
import { Grid, Typography, Button, Divider, DialogTitle, ButtonBase } from "@material-ui/core";
import TextInput from "components/MobxForm/TextInput";
import Dialog from "components/Dialog";
import TrashIcon from "assets/TrashIcon";
import CloseIcon from "assets/CloseIcon";
import { useTranslation } from "react-i18next";
import createFields from "./createFields";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { ADVANCED_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const SETUP_GLOBAL_MASKS_DIALOG_ID_PREFIX = `${ADVANCED_PAGE_ID_PREFIX}_setup_global_masks_dialog`;

const SetupGlobalMasksDialog = ({ closeDialog, open }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        store: { shareStore }
    } = useStore();

    const state = useLocalStore(() => ({
        inProgress: false,
        form: createForm({
            fields: createFields(),
            options: {
                validateOnBlur: false
            }
        }),
        masksList: [],
        isSubmitted: false
    }));

    const unsavedChangesModal = useModal();

    useEffect(() => {
        if (!shareStore.defaultMasks) return;
        state.masksList = [...shareStore.defaultMasks];
    }, [shareStore.defaultMasks]);

    const addMask = e => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        state.masksList.unshift(state.form.$("mask").value);
        state.form.clear();
    };

    const removeMask = index => () => {
        state.masksList.splice(index, 1);
    };

    const onSubmit = async () => {
        state.inProgress = true;
        const res = await shareStore.setDefaultsMasks({
            masks: state.masksList
        });
        state.inProgress = false;
        if (res) {
            state.isSubmitted = true;
        }
        return res;
    };

    const onEnter = () => {
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.inProgress = false;
        state.masksList = [...shareStore.defaultMasks];
        state.form.clear();
    };

    const closeIfNeeded = () => {
        if (state.masksList.join() === shareStore.defaultMasks.join() || state.isSubmitted) {
            closeDialog();
            return;
        }
        unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            title={t("advanced.global_masks.edit_dialog")}
            submitBtnLabel={t("common.button.save")}
            submitBtnId={`${SETUP_GLOBAL_MASKS_DIALOG_ID_PREFIX}_save`}
            open={open}
            withoutPaddings
            onEnter={onEnter}
            onClose={closeIfNeeded}
            onSubmit={onSubmit}
            inProgress={state.inProgress}
            buttons={
                <Button
                    onClick={closeDialog}
                    variant={"contained"}
                    color="secondary"
                    id={`${SETUP_GLOBAL_MASKS_DIALOG_ID_PREFIX}_cancel`}
                >
                    {t("common.button.cancel")}
                </Button>
            }
            header={
                <>
                    <DialogTitle>
                        <Grid justify={"space-between"} alignContent={"center"} alignItems={"center"} container>
                            <Grid item>{t("advanced.global_masks.edit_dialog")}</Grid>
                            <Grid item>
                                <ButtonBase onClick={closeIfNeeded} id={`${SETUP_GLOBAL_MASKS_DIALOG_ID_PREFIX}_edit_dialog`}>
                                    <CloseIcon />
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Divider />
                    <DialogTitle>
                        <Grid container wrap={"nowrap"} spacing={2}>
                            <Grid item>
                                <Typography className={classes.labelText} noWrap>
                                    {t("advanced.global_masks.edit_dialog.label")}
                                </Typography>
                            </Grid>
                            <Grid container item spacing={4} alignItems={"center"} wrap={"nowrap"} justify={"flex-end"}>
                                <Grid item>
                                    <TextInput field={state.form.$("mask")} />
                                </Grid>

                                <Grid item>
                                    <Button
                                        onClick={addMask}
                                        variant={"contained"}
                                        color={"primary"}
                                        id={`${SETUP_GLOBAL_MASKS_DIALOG_ID_PREFIX}_add_mask`}
                                    >
                                        {t("advanced.global_masks.edit_dialog.button")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                </>
            }
        >
            <Grid container>
                {state.masksList.map((item, index) => (
                    <Grid
                        container
                        wrap={"nowrap"}
                        alignItems={"center"}
                        key={Math.floor(Math.random() * Date.now())}
                        className={classes.itemContainer}
                    >
                        <Grid item xs={11}>
                            <Typography>{item}</Typography>
                        </Grid>
                        <Grid item container xs={1} alignItems={"center"} justify={"flex-end"}>
                            <ButtonBase
                                onClick={removeMask(index)}
                                id={`${SETUP_GLOBAL_MASKS_DIALOG_ID_PREFIX}_remove_mask_${index}`}
                            >
                                <TrashIcon />
                            </ButtonBase>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default SetupGlobalMasksDialog;
