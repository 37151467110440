import React from "react";
import { MenuItem } from "@material-ui/core";
import { useStyles } from "./DestructiveMenuItem.styles";

const DestructiveMenuItem = ({ children, ...rest }) => {
    const classes = useStyles();

    return (
        <MenuItem className={classes.content} {...rest}>
            {children}
        </MenuItem>
    );
};

export default DestructiveMenuItem;
