import i18n from "i18n";
import { DATE_PERIODS_OPTIONS_LIMITATION } from "const/createJobConst";

const transferFilesOlderThanCountValidator = () => {
    return ({ field, form }) => {
        const transferFilesOlderThan = form.$("transferFilesOlderThan").value;
        const transferFilesOlderThanType = form.$("transferFilesOlderThanType").value;
        const valueIsTooBig = transferFilesOlderThan && field.value > DATE_PERIODS_OPTIONS_LIMITATION[transferFilesOlderThanType];

        return [!valueIsTooBig, i18n.t("common.error.label.value_is_too_big")];
    };
};

export default transferFilesOlderThanCountValidator;
