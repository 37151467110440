import { types } from "mobx-state-tree";

import DriveId from "api/pool/Types/DriveId";

const PoolDrivesArguments = types.model({
    pool: types.string,
    drives: types.array(DriveId)
});

export default PoolDrivesArguments;
