import React, { useEffect } from "react";
import { Paper, FormControlLabel, Grid } from "@material-ui/core";
import { useObserver } from "mobx-react";
import CardHeader from "components/CardHeader";
import Checkbox from "components/Checkbox";
import { useStyles } from "./DontShowAgainCard.style";
import { useLocalStore } from "mobx-react";
import createForm from "utils/createForm";
import { autorun } from "mobx";
import { useTranslation } from "react-i18next";
import { useStore } from "hooks/useStore";

const createFields = (dontShowAgainQuickStart) => [
    {
        name: "dontShowAgainQuickStart",
        type: "checkbox",
        default: Boolean(dontShowAgainQuickStart),
    },
];

const DontShowAgainCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        store: { uiStore },
    } = useStore();

    const state = useLocalStore(() => ({
        form: createForm({
            fields: createFields(uiStore?.parameters?.dontShowAgainQuickStart),
        }),
        initUiParameters: async function () {
            if (!uiStore?.parameters) await uiStore.fetchUiParameters();
            uiStore.compareTimestamp();

            this.form.reset();
        },
    }));

    useEffect(() => {
        state.initUiParameters();
        autorun(() => {
            state.form.$("dontShowAgainQuickStart").set("value", Boolean(uiStore?.parameters?.dontShowAgainQuickStart));
        });
        return () => {
            uiStore.patchUiParameters(state.form.values());
        };
    }, []);

    const handleCkecked = (e) => {
        state.form.$("dontShowAgainQuickStart").set("value", e.target.checked);
        uiStore.patchUiParameters({ dontShowAgainQuickStart: e.target.checked });
    };

    return useObserver(() => (
        <Paper>
            <CardHeader
                content={
                    <Grid container alignItems={"center"} justify={"space-between"} className={classes.contentContainer}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={state.form.$("dontShowAgainQuickStart").value} onChange={handleCkecked} />
                                }
                                label={t("quick_start.dont_show_again_card")}
                            />
                        </Grid>
                    </Grid>
                }
            />
        </Paper>
    ));
};

export default DontShowAgainCard;
