export const SECONDARY_ADMINS_LIST_CHANGED_EVENT = "SecondaryAdminsListChangedEvent";
export const EXTERNAL_SHARE_STATUS_CHANGED_EVENT = "ExternalShareStatusChangedEvent";
export const SHARES_TABLE_LOADED_EVENT = "SharesTableLoadedEvent";
export const NAME_OWNER_CHANGED_EVENT = "NameOwnerChangedEvent";
export const ACL_MIGRATION_PROGRESS_CHANGED_EVENT = "AclMigrationProgressChangedEvent";
export const MAIN_STEP_PROGRESS_CHANGE_EVENT = "MainStepProgressChangedEvent";

export const EXCLUDE_TYPES_ADMIN = [];
export const EXCLUDE_TYPES_SECONDARY_ADMIN = [SECONDARY_ADMINS_LIST_CHANGED_EVENT, EXTERNAL_SHARE_STATUS_CHANGED_EVENT];
export const EXCLUDE_TYPES_USER = [
    SHARES_TABLE_LOADED_EVENT,
    SECONDARY_ADMINS_LIST_CHANGED_EVENT,
    EXTERNAL_SHARE_STATUS_CHANGED_EVENT,
];
