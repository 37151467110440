import { types, flow, getParent } from "mobx-state-tree";
import GetBootProgress from "api/boot_progress/Requests/GetBootProgress";
import BootProgressTypes from "api/boot_progress/Types/BootProgressTypes";
import { BOOT_PROGRESS_POLLING_INTERVAL } from "const";
import { SSH_ACCESS_URL } from "api/restRoutes";
import axios from "api/AxiosCommonRequest";
import { autorun } from "mobx";
import i18next from "i18next";
import { imTeapot } from "utils/imTeapot";

const BootProgressStore = types
    .model({
        bootProgress: types.maybeNull(BootProgressTypes),
        sshStatus: types.maybeNull(types.string),
        isError: types.optional(types.boolean, true),
    })
    .volatile(() => ({
        title: null,
        hasSupportButton: false,
        isTeapot: false,
    }))
    .views((self) => ({
        get isRunning() {
            return self.bootProgress?.status === 0;
        },
        get isShuttingDown() {
            return self.bootProgress?.status === -1;
        },
        get isRebooting() {
            return self.bootProgress?.status === -2;
        },
        get isRestoring() {
            return self.bootProgress?.status === -1001;
        },
        get isNetworkError() {
            return self.bootProgress?.status === -404;
        },
        get getTitle() {
            return self.title;
        },
        get showSupportButton() {
            return self.hasSupportButton;
        },
        get status() {
            return self.bootProgress?.status;
        },
        get message() {
            return self.bootProgress?.message;
        },
        get progress() {
            return self.bootProgress?.progress;
        },
    }))
    .actions((self) => ({
        toggleHasSupportButton(value) {
            self.hasSupportButton = value;
        },
        setTitle(title) {
            self.title = title;
        },
        getBootProgress: flow(function* () {
            try {
                const res = yield GetBootProgress();
                self.isError = false;
                self.bootProgress = res.data;
            } catch (e) {
                if (imTeapot(e?.response?.data?.code)) {
                    self.setTitle(i18next.t("boot_load.version_error"));
                    self.isTeapot = true;
                }
                if (e?.message === "Network Error") {
                    self.bootProgress = { status: -404 };
                }
                self.isError = true;
            }
        }),
        startBootProgressPolling: () => {
            setInterval(self.getBootProgress, BOOT_PROGRESS_POLLING_INTERVAL);
        },
        getSshAccess: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                const res = yield axios.get(SSH_ACCESS_URL);
                self.sshStatus = res.data.status;
                return true;
            } catch (e) {
                processingStore.setError(e);
            }
            return null;
        }),
        uploadFile: flow(function* (file) {
            const { processingStore } = getParent(self);
            const formData = new FormData();
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            try {
                formData.append("file", file);
                yield axios.post(SSH_ACCESS_URL, formData, config);
                return true;
            } catch (e) {
                processingStore.setError(e);
            }
            return null;
        }),
    }))
    .actions((self) => ({
        afterCreate() {
            autorun(() => {
                switch (self.bootProgress?.status) {
                    case 0:
                        self.setTitle(null);
                        break;
                    case -1:
                        self.setTitle(i18next.t("boot_load.shutting_down"));
                        break;
                    case -2:
                        self.setTitle(i18next.t("boot_load.rebooting"));
                        break;
                    case -1001:
                        self.setTitle(i18next.t("boot_load.restoring"));
                        break;
                    default:
                        self.setTitle(i18next.t("boot_load.booting"));
                        break;
                }
                if (self.isError && !self.isTeapot) {
                    self.setTitle(i18next.t("boot_load.network_error"));
                }
                if (self.isTeapot) {
                    self.setTitle(i18next.t("boot_load.version_error"));
                }
            });
            autorun(() => {
                const { authStore } = getParent(self);
                if (self.bootProgress?.status !== undefined && self.bootProgress?.status !== 0 && !authStore.isClearCredential)
                    authStore.clearAuthorization();
            });
        },
    }));

export default BootProgressStore;
