import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStore } from "mobx-react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import DataCard from "components/DataCard";
import EmptyCardPlaceholder from "components/EmptyCardPlaceholder/EmptyCardPlaceholder";
import { useStore } from "hooks/useStore";
import { checkActiveDrive } from "./SystemDriveStatus.utils";
import TableRowControlButton from "components/TableRowControlButton/TableRowControlButton";
import ChevronRightIcon from "assets/ChevronRightIcon";
import { observer } from "mobx-react-lite";
import SystemDriveStatusDrawer from "./components/SystemDriveStatusDrawer/SystemDriveStatusDrawer";
import MoreMenu from "components/MoreMenu/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem/HomeCardActionMenuItem";
import { SYSTEM_DRIVE_STATUS } from "const/widgetNameConst";

const SystemDriveStatus = () => {
    const { t } = useTranslation();
    const {
        store: { systemDriveStore },
    } = useStore();

    const state = useLocalStore(() => ({
        inProgress: false,
        fetchSystemDriveStatus: async function () {
            state.inProgress = true;
            await systemDriveStore.fetchDriveStatus();

            await systemDriveStore.fetchIdentifyStatus();
            state.inProgress = false;
        },
    }));

    useEffect(() => {
        state.fetchSystemDriveStatus();
    }, []);

    return (
        <DataCard
            title={t("system_drive_status.title")}
            autoHeight
            headerDivider
            headerControl={
                <MoreMenu>
                    <HomeCardActionMenuItem widget={SYSTEM_DRIVE_STATUS} />
                </MoreMenu>
            }
            emptyPadding
        >
            <TableContainer component={Paper} elevation={0}>
                {!state.inProgress ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("system_drive_status.get_drive_status.table.drive_number")}</TableCell>
                                <TableCell>{t("system_drive_status.get_drive_status.table.model")}</TableCell>
                                <TableCell>{t("system_drive_status.get_drive_status.table.serial_number")}</TableCell>
                                <TableCell>{t("system_drive_status.get_drive_status.table.booted")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {systemDriveStore.drivesStatus?.map((drive) => (
                                <TableRow
                                    key={drive.driveSerial}
                                    onClick={systemDriveStore.selectDrive.bind(null, drive)}
                                    selected={systemDriveStore.selectedDrive?.drive === drive.drive}
                                >
                                    <TableCell>{drive.drive}</TableCell>
                                    <TableCell>{drive.driveModel}</TableCell>
                                    <TableCell>{drive.driveSerial}</TableCell>
                                    <TableCell>{checkActiveDrive(drive)}</TableCell>
                                    <TableCell align={"right"}>
                                        <TableRowControlButton icon={<ChevronRightIcon />} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <EmptyCardPlaceholder inProgress={state.inProgress} />
                )}
                <SystemDriveStatusDrawer />
            </TableContainer>
        </DataCard>
    );
};

export default observer(SystemDriveStatus);
