import { types } from "mobx-state-tree";

import JobSummaryResult from "../Types/JobSummaryResult";

const GetJobSummaryResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(JobSummaryResult, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            }
        })
    )
});

export default GetJobSummaryResult;
