import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        width: "100%",
    },
    childContainer: {
        opacity: ({ isDragging }) => (isDragging ? 0.5 : 1),
        "& .MuiDrawer-root": {
            opacity: ({ isSomeElementDragging }) => (isSomeElementDragging ? 0.5 : 1),
        },
    },
}));
