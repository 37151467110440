import React from "react";
import { Grid, ButtonBase, Typography } from "@material-ui/core";
import { useStyles } from "./SideMenuButton.style";
import ChevronRightIcon from "assets/ChevronRightIcon";
import clsx from "clsx";

const SideMenuButton = ({ title, onClick, isSelected, withMenuToggle = true }) => {
    const classes = useStyles();
    return (
        <Grid container>
            <ButtonBase className={clsx(classes.container, isSelected && classes.selected)} onClick={onClick}>
                <Grid container alignItems={"center"} justify={"space-between"}>
                    <Grid item>
                        <Typography
                            className={classes.title}
                            variant={"subtitle1"}
                            color={isSelected ? "textSecondary" : "textPrimary"}
                        >
                            {title}
                        </Typography>
                    </Grid>
                    {withMenuToggle ? (
                        <Grid item>
                            <Grid container alignItems={"center"}>
                                <ChevronRightIcon />
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
            </ButtonBase>
        </Grid>
    );
};

export default SideMenuButton;
