import { REGION_ENUM } from "const/regionConst";
import { types } from "mobx-state-tree";

const SCHEMA_ENUM_ARRAY = ["evo", "smb", "afp", "ftp", "s3", "azure", "dropbox", "tricaster", "gcs"];

const DecomposedBaseUrlType = types.model({
    schema: types.maybe(types.enumeration(SCHEMA_ENUM_ARRAY)),
    path: types.maybe(types.string),
    resource: types.maybe(types.string),
    region: types.maybe(types.enumeration(Object.values(REGION_ENUM))),
    host: types.maybe(types.string),
});

export default DecomposedBaseUrlType;
