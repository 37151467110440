import React from "react";
import { useObserver } from "mobx-react";
import { Grid } from "@material-ui/core";
import DriveItem from "../DriveItem";
import useStoreByIp from "hooks/useStoreByIp";

const QuadItem = ({ quad, driveStatuses, ip, ...props }) => {
    const { driveStore } = useStoreByIp({ ip });

    const onDriveClick = drive => () => {
        if (driveStore.currentDriveName) {
            driveStore.currentDriveName.drive === drive && driveStore.currentDriveName.quad === quad
                ? driveStore.setCurrentDriveName(null)
                : driveStore.setCurrentDriveName({ drive, quad });
        } else {
            driveStore.setCurrentDriveName({ drive, quad });
        }
    };

    return useObserver(() => (
        <Grid container wrap={"nowrap"} spacing={2} {...props}>
            {driveStatuses.map(driveStatus => (
                <Grid container item key={`quad${quad}-drive${driveStatus.drive}`}>
                    <DriveItem
                        drive={driveStatus.drive}
                        status={driveStatus.status}
                        type={driveStatus.driveType}
                        onClick={onDriveClick(driveStatus.drive)}
                    />
                </Grid>
            ))}
        </Grid>
    ));
};

export default QuadItem;
