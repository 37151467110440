import React, { useContext, useEffect } from "react";
import DataReadonlyCard from "components/DataReadonlyCard";
import { useTranslation } from "react-i18next";
import { Context } from "store";
import { useLocalStore, useObserver } from "mobx-react-lite";
import MoreMenu from "components/MoreMenu";
import HomeCardActionMenuItem from "components/HomeCardActionMenuItem";
import { EMAIL_CONFIGURATION_CARD } from "const/widgetNameConst";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SavedInputIndicator from "components/SavedInputIndicator";
import SavedInputPreview from "components/SavedInputPreview";
import EmailConfigurationDialog from "../EmailConfigurationDialog";
import { ALERTS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const EMAIL_CONFIGURATION_CARD_ID_PREFIX = `${ALERTS_PAGE_ID_PREFIX}_email_configuration_card`;

const EmailConfigurationCard = () => {
    const { t } = useTranslation();
    const {
        store: { emailConfigurationStore }
    } = useContext(Context);
    useEffect(() => {
        emailConfigurationStore.getEmailSettings();
    }, []);
    const state = useLocalStore(() => ({
        dialogOpen: false
    }));
    const openEditDialog = () => {
        state.dialogOpen = true;
    };
    const closeEditDialog = () => {
        state.dialogOpen = false;
    };
    return useObserver(() => (
        <>
            <EmailConfigurationDialog open={state.dialogOpen} onClose={closeEditDialog} />
            <DataReadonlyCard
                onClick={openEditDialog}
                title={t("alerts.email_configuration_card.title")}
                headerDivider
                control={
                    <MoreMenu id={`${EMAIL_CONFIGURATION_CARD_ID_PREFIX}_more_menu`}>
                        <HomeCardActionMenuItem widget={EMAIL_CONFIGURATION_CARD} />
                    </MoreMenu>
                }
            >
                <Grid container direction={"column"} spacing={5}>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.email_configuration_card.name")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{emailConfigurationStore.emailSettings.smtpUser}</SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.email_configuration_card.password")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>******</SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.email_configuration_card.server")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{emailConfigurationStore.emailSettings.smtpServer}</SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.email_configuration_card.ssl")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputIndicator enabled={emailConfigurationStore.emailSettings.isSslEnabled} />
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.email_configuration_card.from")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{emailConfigurationStore.emailSettings.emailFrom}</SavedInputPreview>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"} justify={"space-between"}>
                        <Grid xs={6} item>
                            <Typography>{t("alerts.email_configuration_card.recipient_list")}</Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <SavedInputPreview>{emailConfigurationStore.emailSettings.emailsTo?.join("; ")}</SavedInputPreview>
                        </Grid>
                    </Grid>
                </Grid>
            </DataReadonlyCard>
        </>
    ));
};

export default EmailConfigurationCard;
