import { makeStyles } from "@material-ui/core/styles";
import {
    DRAWER_WIDTH,
    GRAPHICS_BAR_HEIGHT,
    GRAPHICS_BAR_HEIGHT_MOBILE,
    HEADER_HEIGHT,
    HEADER_HEIGHT_MOBILE,
    STATE_BAR_HEIGHT,
    STATE_BAR_HEIGHT_MOBILE
} from "const/styleConst";

export const useStyles = makeStyles(theme => ({
    drawer: {
        "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            bottom: 0,
            height: "initial",
            overflowY: "initial",
            boxShadow: "-5px 0 29px 0 rgba(0, 0, 0, 0.1)",
            [theme.breakpoints.up("md")]: {
                top: ({ isWidgetOpen }) => (isWidgetOpen ? HEADER_HEIGHT + GRAPHICS_BAR_HEIGHT + STATE_BAR_HEIGHT : HEADER_HEIGHT)
            },
            top: ({ isWidgetOpen }) =>
                isWidgetOpen ? HEADER_HEIGHT_MOBILE + GRAPHICS_BAR_HEIGHT_MOBILE + STATE_BAR_HEIGHT_MOBILE : HEADER_HEIGHT_MOBILE
        }
    },
    container: {
        height: "100%",
        flexWrap: "nowrap",
        overflowY: "auto",
        overflowX: "hidden"
    },
    headerButtonGroup: {
        padding: theme.spacing(0, 6, 4),
        width: "100%",
        "& > button": {
            width: "50%",
            fontSize: 14,
            padding: theme.spacing(1.5)
        }
    },
    header: {
        padding: theme.spacing(5, 6),
        margin: 0
    },
    body: {
        flex: 1
    }
}));
