import React, { useContext, useEffect } from "react";
import { Button, ButtonBase, Grid, InputAdornment, CircularProgress } from "@material-ui/core";
import { useObserver, useLocalStore } from "mobx-react";
import TextInput from "components/TextInput";
import SearchIcon from "assets/SearchIcon";
import { useStyles } from "./TableInlineControl.styles";
import Select from "components/Select";
import { useTranslation } from "react-i18next";
import { LOCAL_USERS, EXTERNAL_USERS, LOCAL_GROUPS, EXTERNAL_GROUPS, SEARCH_DEFAULTS } from "const/userSearchVariants";
import AddUserDialog from "components/AddUserDialog/AddUserDialog";
import AddGroupDialog from "../../../../components/AddGroupDialog";
import CloseIcon from "assets/CloseIcon";
import { Context } from "store";
import SyncDialog from "../SyncDialog";
import { LDAP } from "const/ldapStatuses";

const TABLE_INLINE_CONTROL_ID_PREFIX = "table_inline_control";

const TableInlineControl = ({ parentId }) => {
    const { t } = useTranslation();
    const idPrefix = parentId ? `${parentId}_${TABLE_INLINE_CONTROL_ID_PREFIX}` : TABLE_INLINE_CONTROL_ID_PREFIX;

    const state = useLocalStore(() => ({
        isAddUserDialogOpen: false,
        isAddGroupDialogOpen: false,
        isSearchInProgress: false,
        isSyncDialogOpen: false,
        searchString: "",
    }));

    const {
        store: { userStore, multiLdapStore },
    } = useContext(Context);
    const classes = useStyles({ status: userStore.currentSyncMode.status });

    useEffect(() => {
        userStore.getActiveDirectoryStatus();
        multiLdapStore.getLdapStatus();
        multiLdapStore.getAdStatus();
    }, []);

    const openAddUserDialog = () => {
        state.isAddUserDialogOpen = true;
    };
    const closeAddUserDialog = () => {
        state.isAddUserDialogOpen = false;
    };
    const openSyncDialog = () => {
        state.isSyncDialogOpen = true;
    };
    const closeSyncDialog = () => {
        state.isSyncDialogOpen = false;
    };

    const openAddGroupDialog = () => {
        state.isAddGroupDialogOpen = true;
    };
    const closeAddGroupDialog = () => {
        state.isAddGroupDialogOpen = false;
    };

    const resetNameField = () => {
        state.searchString = "";
        userStore.updateCurrentSearchRequest("name", SEARCH_DEFAULTS.name);
    };

    const getPlaceholder = () => {
        return userStore.currentSearchRequest.type === LOCAL_USERS || userStore.currentSearchRequest.type === EXTERNAL_USERS
            ? t("users.search_controls.search_line.placeholder.user")
            : t("users.search_controls.search_line.placeholder.group");
    };

    const getLdapButtonContent = () => {
        if (userStore.currentSyncMode.enabled) {
            return userStore.currentSyncMode.mode === LDAP
                ? t("users.search_controls.search_line.button.ldap")
                : t("users.search_controls.search_line.button.ad");
        } else {
            return t("users.search_controls.search_line.button.ldap/ad");
        }
    };

    const getControls = () => {
        switch (userStore.currentSearchRequest.type) {
            case LOCAL_USERS:
                return [
                    <Button
                        onClick={openAddUserDialog}
                        variant="contained"
                        color="secondary"
                        key={`${LOCAL_USERS}-button-1`}
                        id={`${idPrefix}_add_user`}
                    >
                        {t("users.search_controls.search_line.button.add_user")}
                    </Button>,
                ];
            case EXTERNAL_GROUPS:
            case EXTERNAL_USERS:
                return [
                    <Button
                        key={`${EXTERNAL_USERS}-and-${EXTERNAL_GROUPS}-button-1`}
                        onClick={openSyncDialog}
                        disabled={!userStore.ldapStatus || !userStore.activeDirectoryStatus}
                        variant="contained"
                        className={classes.ldapButton}
                        startIcon={userStore.currentSyncMode.enabled ? <div className={classes.oval} /> : undefined}
                        id={`${idPrefix}_ldap_ad`}
                    >
                        {getLdapButtonContent()}
                    </Button>,
                ];
            case LOCAL_GROUPS:
                return [
                    <Button
                        onClick={openAddGroupDialog}
                        variant="contained"
                        color="secondary"
                        key={`${LOCAL_GROUPS}-button-1`}
                        id={`${idPrefix}_add_group`}
                    >
                        {t("users.search_controls.search_line.button.add_group")}
                    </Button>,
                ];
            default:
                return [];
        }
    };

    const getInputAdornment = () => {
        return (
            <InputAdornment position="end">
                <Grid container wrap={"nowrap"} alignItems={"center"} justify={"flex-end"} spacing={2}>
                    {userStore.currentSearchRequest.name !== "" && (
                        <Grid item>
                            <ButtonBase className={classes.closeIcon} onClick={resetNameField}>
                                <CloseIcon />
                            </ButtonBase>
                        </Grid>
                    )}
                    <Grid item>
                        <Grid container alignItems={"center"} className={classes.searchIconContainer}>
                            {state.isSearchInProgress ? (
                                <CircularProgress size={22} />
                            ) : (
                                <ButtonBase className={classes.searchIcon} onClick={search}>
                                    <SearchIcon />
                                </ButtonBase>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </InputAdornment>
        );
    };

    const search = async () => {
        state.isSearchInProgress = true;
        userStore.updateCurrentSearchRequest("name", state.searchString);
        userStore.updateCurrentSearchRequest("limit", SEARCH_DEFAULTS.limit);
        userStore.updateCurrentSearchRequest("page", SEARCH_DEFAULTS.page);
        await userStore.searchOnUsersPage();
        state.isSearchInProgress = false;
    };

    const keyDownHandler = (e) => {
        e.key === "Enter" && search();
    };

    const onSelectChange = (e) => {
        state.searchString = "";
        userStore.clearCheckedUsers();
        userStore.dropCurrentEntity();
        userStore.updateCurrentSearchRequest("name", "");
        userStore.updateCurrentSearchRequest("type", e.target.value);
        search();
    };

    const onInputChange = (e) => {
        state.searchString = e.target.value;
    };

    return useObserver(() => (
        <>
            <Grid className={classes.container} container wrap={"nowrap"}>
                <Grid item className={classes.selectContainer}>
                    <Select
                        onChange={onSelectChange}
                        value={userStore.currentSearchRequest.type}
                        options={[
                            {
                                label: t("users.common.local_users"),
                                value: LOCAL_USERS,
                            },
                            {
                                label: t("users.common.external_users"),
                                value: EXTERNAL_USERS,
                            },
                            {
                                label: t("users.common.local_groups"),
                                value: LOCAL_GROUPS,
                            },
                            {
                                label: t("users.common.external_groups"),
                                value: EXTERNAL_GROUPS,
                            },
                        ]}
                        id={`${idPrefix}_search_request`}
                    />
                </Grid>
                <Grid item className={classes.inputContainer}>
                    <TextInput
                        autoFocus
                        onKeyDown={keyDownHandler}
                        value={state.searchString}
                        placeholder={getPlaceholder()}
                        className={classes.input}
                        onChange={onInputChange}
                        InputProps={{
                            endAdornment: getInputAdornment(),
                        }}
                        id={`${idPrefix}_search`}
                    />
                </Grid>
                <Grid item>
                    <Grid container spacing={2} wrap={"nowrap"} justify={"flex-end"}>
                        {React.Children.map(getControls(), (button) => (
                            <Grid item key={button.key}>
                                {button}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <AddUserDialog open={state.isAddUserDialogOpen} onClose={closeAddUserDialog} parentId={idPrefix} />
            <AddGroupDialog open={state.isAddGroupDialogOpen} onClose={closeAddGroupDialog} parentId={idPrefix} />
            <SyncDialog open={state.isSyncDialogOpen} onClose={closeSyncDialog} parentId={idPrefix} />
        </>
    ));
};

export default TableInlineControl;
