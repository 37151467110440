import React from "react";

const OptionsAdminIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fill="#BFD1E1"
                fillRule="evenodd"
                d="M6 2c.552 0 1 .448 1 1v11.18c.835.303 1.493.961 1.796 1.796.566 1.558-.239 3.279-1.796 3.844V21c0 .552-.448 1-1 1s-1-.448-1-1v-1.18c-.835-.303-1.493-.961-1.796-1.796-.566-1.558.239-3.279 1.796-3.844V3c0-.552.448-1 1-1zm12 0c.552 0 1 .448 1 1v7.18c1.196.423 1.996 1.552 2 2.82l-.006.19c-.079 1.19-.858 2.228-1.994 2.63V21c0 .552-.448 1-1 1s-1-.448-1-1v-5.18c-.835-.303-1.493-.961-1.796-1.796-.566-1.558.239-3.279 1.796-3.844V3c0-.552.448-1 1-1zm-5.992-.008C13.665 1.996 15.005 3.343 15 5l-.006.19c-.079 1.19-.858 2.228-1.994 2.63V21c0 .552-.448 1-1 1s-1-.448-1-1V7.82c-1.202-.425-2.004-1.562-2-2.837.005-1.657 1.352-2.996 3.008-2.991zM6 16c-.552 0-1 .448-1 1s.448 1 1 1l.117-.007c.497-.057.883-.48.883-.993l-.007-.117C6.936 16.386 6.513 16 6 16zm12-4c-.552 0-1 .448-1 1s.448 1 1 1l.117-.007c.497-.057.883-.48.883-.993l-.007-.117c-.057-.497-.48-.883-.993-.883zm-6-8c-.552 0-1 .448-1 1s.448 1 1 1l.117-.007c.497-.057.883-.48.883-.993l-.007-.117C12.936 4.386 12.513 4 12 4z"
            />
        </svg>
    );
};

export default OptionsAdminIcon;
