export const WHATS_THIS = "shortcut.whats_this";
export const ADMIN_SETTINGS = "AdminSettings";
export const ADVANCED = "Advanced";
export const ALERTS = "Alerts";
export const DISKS_AND_POOLS = "DisksAndPools";
export const FILE_NAVIGATOR = "FileNavigator";
export const HARDWARE_ACTIVATION = "HardwareActivation";
export const NETWORK = "Network";
export const POWER_AND_UPS = "PowerAndUps";
export const QUICK_START = "QuickStart";
export const RECYCLE_BIN = "RecycleBin";
export const SHARES = "Shares";
export const REMOTE_SHARES = "RemoteShares";
export const SUPPORT = "Support";
export const SUPPORT_SELF_TESTS = "SupportSelfTests";
export const SUPPORT_OS_DATA = "SupportOsData";
export const TIME_SETTINGS = "TimeSettings";
export const UPGRADES = "Upgrades";
export const USERS = "Users";
export const HELP = "Users";
export const SLINGSHOT = "Slingshot";
export const SLINGSHOT_INDEX = "SlingshotIndex";
export const SLINGSHOT_AUTOMATIONS = "SlingshotAutomations";
export const SLINGSHOT_SETTINGS = "SlingshotSettings";
export const SHAREBROWSER = "ShareBrowser";
export const HARDWARE_PROFILE = "HardwareProfile";
export const AUDIT = "Audit";
export const WIREGUARD = "Wireguard";
export const TROUBLESHOOTING = "REPLACE DISK";
export const FIREWALL = "Firewall";
export const CENTRAL_STATUS = "CentralStatus";
export const CLUSTER = "Cluster";
export const ISCSI = "ISCSI";
export const FILE_NAVIGATOR_LOG = "FileNavigatorLog";
