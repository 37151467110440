import { types } from "mobx-state-tree";
import TransportingConfig from "../Types/TransportingConfig";

const GetTransportingConfigResult = types.model({
    messages: types.array(types.string),
    warnings: types.array(types.string),
    data: types.maybe(
        types.snapshotProcessor(TransportingConfig, {
            preProcessor(snapshot) {
                return { json: snapshot?.json && JSON.parse(snapshot.json) };
            }
        })
    )
});

export default GetTransportingConfigResult;
