import { types } from "mobx-state-tree";

import Request from "api/Request";
import RemoveExternalSharesArguments from "api/external_shares/Types/RemoveExternalSharesArguments";

const RemoveExternalShares = Request.props({
    path: "/com/studionetworksolutions/evo/ExternalShares",
    method: "remove_external_shares",
    arguments: types.maybe(RemoveExternalSharesArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default RemoveExternalShares;
