import { types } from "mobx-state-tree";

import Request from "api/Request";
import ImportCertificateAndKeyArguments from "api/certificate/Types/ImportCertificateAndKeyArguments";

const ImportCertificateAndKey = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Certificates",
    method: "import_certificate_and_key",
    arguments: types.maybe(ImportCertificateAndKeyArguments)
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default ImportCertificateAndKey;
