import React from "react";
import { Button, Grid, Typography, ButtonBase } from "@material-ui/core";
import Dialog from "components/Dialog";
import CloseIcon from "assets/CloseIcon";
import { useObserver, useLocalStore } from "mobx-react";
import { useStyles } from "./SigningRequestCard.styles";
import createFields from "../createCertificateFields";
import createForm from "utils/createForm";
import { useTranslation } from "react-i18next";
import FormLayout from "../FormLayout";
import DownloadCsrLayout from "../DownloadCsrLayout";
import Stepper from "../Stepper";
import AnimatedSubmitButton from "components/AnimatedSubmitButton";
import CardHeaderControlButton from "components/CardHeaderControlButton";
import { COUNTRY_CODES, COUNTRY_CODES_DEFAULT } from "const/sslCertificateCountryCodesConst";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { ADMIN_SETTINGS_PAGE_ID_PREFIX } from "const/pagesIdPrefixes";

const SIGNING_REQUEST_CARD_ID_PREFIX = `${ADMIN_SETTINGS_PAGE_ID_PREFIX}_signing_request_card`;

const SigningRequestCard = ({ open, onClose, onBack }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const fields = createFields();
    const steps = [
        t("admin_settings.server_certificate.step_name.fill_in_information"),
        t("admin_settings.server_certificate.step_name.download_csr")
    ];
    const state = useLocalStore(() => ({
        inProgress: false,
        form: createForm({
            fields
        }),
        csrName: null,
        currentStep: 0,
        isSubmitted: false
    }));
    const {
        store: { certificateStore }
    } = useStore();

    const unsavedChangesModal = useModal();

    const decreaseStep = () => {
        state.currentStep--;
        state.isSubmitted = false;
    };

    const onBackClick = () => {
        state.currentStep === 0 ? onBack() : decreaseStep();
    };

    const resolveSubmitCondition = () => {
        state.isSubmitted = true;
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    };

    const increaseStep = async e => {
        state.form.onSubmit(e);
        if (!state.form.isValid) return;
        state.inProgress = true;
        state.csrName = await certificateStore.createCertificateSigningRequest({
            commonName: state.form.$("commonName").value,
            organization: state.form.$("organization").value,
            department: state.form.$("department").value,
            country: state.form.$("country").value.value,
            stateName: state.form.$("state").value,
            city: state.form.$("city").value
        });
        state.inProgress = false;
        if (state.csrName) {
            await resolveSubmitCondition();
            state.currentStep++;
        }

        return state.csrName;
    };

    const downloadCsr = async () => {
        const isSubmitted = await certificateStore.downloadCertificate(state.csrName);
        if (isSubmitted) {
            setTimeout(onClose, 1000);
        }
    };

    const getStepContent = () => {
        switch (state.currentStep) {
            case 0:
                return <FormLayout form={state.form} />;
            case 1:
                return <DownloadCsrLayout />;
            default:
                return null;
        }
    };
    const onEnter = () => {
        const defaultCountry = COUNTRY_CODES.find(el => el.value === COUNTRY_CODES_DEFAULT);
        state.form.$("country").set("default", defaultCountry);
        state.form.reset();
        state.form.$("commonName").resetValidation();
        state.csrName = null;
        state.currentStep = 0;
        unsavedChangesModal.close();
        state.isSubmitted = false;
        state.inProgress = false;
    };

    const closeIfNeeded = () => {
        state.form.isDefault || state.isSubmitted ? onClose() : unsavedChangesModal.open();
    };

    return useObserver(() => (
        <Dialog
            onEnter={onEnter}
            maxWidth={"md"}
            fullWidth
            open={open}
            onClose={closeIfNeeded}
            title={t("admin_settings.server_certificate.create_self_signed_certificate.title")}
            submitBtn={
                <>
                    {state.currentStep === steps.length - 1 ? (
                        <Grid item>
                            <AnimatedSubmitButton
                                label={t("common.button.download")}
                                submit={downloadCsr}
                                id={`${SIGNING_REQUEST_CARD_ID_PREFIX}_download`}
                            />
                        </Grid>
                    ) : (
                        <Grid item>
                            <AnimatedSubmitButton
                                inProgress={state.inProgress}
                                isSubmitted={state.isSubmitted}
                                label={t("common.button.next")}
                                submit={increaseStep}
                                id={`${SIGNING_REQUEST_CARD_ID_PREFIX}_next`}
                            />
                        </Grid>
                    )}
                </>
            }
            buttons={
                <Button onClick={onBackClick} variant={"contained"} color="secondary">
                    {t("common.button.back")}
                </Button>
            }
            header={
                <Grid container flex-direction={"column"}>
                    <Grid item container justify={"space-between"} alignItems={"center"} className={classes.headerContainer}>
                        <Grid item>
                            <Typography variant={"h2"}>
                                {t("admin_settings.server_certificate.create_certificate.certificate_signing_request.title")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CardHeaderControlButton onClick={closeIfNeeded}>
                                <ButtonBase id={`${SIGNING_REQUEST_CARD_ID_PREFIX}_close_if_needed`}>
                                    <CloseIcon />
                                </ButtonBase>
                            </CardHeaderControlButton>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.stepperContainer}>
                        <Stepper steps={steps} activeStep={state.currentStep} />
                    </Grid>
                </Grid>
            }
        >
            {getStepContent()}
            <UnsavedChangesDialog onConfirm={onClose} onClose={unsavedChangesModal.close} open={unsavedChangesModal.isOpen} />
        </Dialog>
    ));
};

export default SigningRequestCard;
