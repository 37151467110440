import React, { useEffect } from "react";
import { Button, Grid, Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import Autocomplete from "components/MobxForm/Autocomplete";
import TextInput from "components/MobxForm/TextInput";
import Switch from "components/MobxForm/Switch";
import { useLocalStore } from "mobx-react";
import createForm from "utils/createForm";
import timeSettingRule from "utils/timeSettingRule";
import hostNameRule from "utils/hostNameRule";
import moment from "moment";
import { CLIENT_DATE_FORMAT, SERVER_DATE_FORMAT_EN } from "const";
import createFields from "./createFields";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react-lite";
import Dialog from "components/Dialog";
import RowTextInput from "components/RowTextInput";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import RowControlLabelSwitch from "components/RowControlLabelSwitch";
import { useStyles } from "./SetupTimeSettingsDialog.styles";
import ChevronUpIcon from "assets/ChevronUpIcon";
import clsx from "clsx";
import { useStore } from "hooks/useStore";
import { useModal } from "hooks/useModal";
import { getLanguageDateFormat } from "utils/getLanguageDateFormat";

const SetupTimeSettingsDialog = ({ closeDialog, open }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        store: { timeSettingsStore, evoSettingsStore },
    } = useStore();

    const fields = createFields(timeSettingsStore);
    const state = useLocalStore(() => ({
        form: createForm({
            fields,
            rules: { ...timeSettingRule, ...hostNameRule },
        }),
        isSubmitted: false,
    }));

    const unsavedChangeModal = useModal();

    useEffect(() => {
        state.form.$("server").set("disabled", !state.form.$("enableClient").value);
        state.form.$("enableClient").observe({
            key: "value",
            call: (data) => {
                state.form.$("server").set("disabled", !data.field.value);
            },
        });
    }, []);

    const updateForm = () => {
        state.form.$("timezone").set("default", timeSettingsStore.timezone);
        state.form.$("time").set("default", initialDate(timeSettingsStore.localTime));
        state.form.$("enableClient").set("default", timeSettingsStore.enableClient);
        state.form.$("enableService").set("default", timeSettingsStore.enableServer);
        state.form.$("server").set("default", timeSettingsStore.server);
        state.form.reset();
        unsavedChangeModal.close();
        state.isSubmitted = false;
    };

    const initialDate = (date) => {
        return moment.unix(date).format(getLanguageDateFormat(CLIENT_DATE_FORMAT));
    };

    const formatDate = (date) => {
        return moment.utc(date).format(SERVER_DATE_FORMAT_EN);
    };

    const onSubmit = async (event) => {
        state.form.onSubmit(event);
        if (!state.form.isValid) return;
        const isSubmitted = await timeSettingsStore.setTimeSettings({
            local: {
                timezone: state.form.$("timezone").value,
                time: formatDate(state.form.$("time").value),
            },
            ntp: {
                server: state.form.$("server").value,
                enableServer: state.form.$("enableService").value,
                enableClient: state.form.$("enableClient").value,
            },
        });
        await timeSettingsStore.fetchTimeSettings();
        await timeSettingsStore.startTimerFetch();
        await evoSettingsStore.startTimerFetch();
        if (isSubmitted) {
            state.isSubmitted = true;
        }

        return isSubmitted;
    };

    const closeIfNeeded = () => {
        if (state.form.isDefault || state.isSubmitted) {
            closeDialog();
            return;
        }
        unsavedChangeModal.open();
    };

    return useObserver(() => (
        <Dialog
            title={t("time_settings.edit_card_title")}
            submitBtnLabel={t("common.button.save")}
            open={open}
            onSubmit={onSubmit}
            onEnter={updateForm}
            onClose={closeIfNeeded}
            buttons={
                <Button onClick={closeDialog} variant={"contained"} color="secondary">
                    {t("common.button.cancel")}
                </Button>
            }
        >
            <Grid container direction={"column"}>
                <RowTextInput
                    label={t("time_settings.time_zone")}
                    control={<Autocomplete field={state.form.$("timezone")} options={timeSettingsStore.timezoneArray} />}
                />
                <RowTextInput label={t("time_settings.time")} control={<TextInput field={state.form.$("time")} />} />
                <RowControlLabelSwitch
                    control={<Switch field={state.form.$("enableClient")} />}
                    label={t("time_settings.label_client")}
                />
                <RowTextInput label={t("time_settings.server")} control={<TextInput field={state.form.$("server")} />} />

                <Grid xs={12} item className={classes.expansionPanelContainer}>
                    <Accordion
                        className={classes.expansionPanel}
                        square
                        expanded={state.advancedExpanded}
                        onChange={(event, newExpanded) => {
                            state.advancedExpanded = newExpanded;
                        }}
                    >
                        <AccordionSummary
                            className={classes.expansionPanelContent}
                            classes={{ content: classes.expansionPanelContent, expanded: classes.expansionPanelContent }}
                        >
                            <Typography className={classes.expansionLabel}>{t("time_settings.advanced_settings")}</Typography>
                            <ChevronUpIcon
                                className={clsx(!state.advancedExpanded && classes.expansionIconClose, classes.expansionIcon)}
                            />
                        </AccordionSummary>
                        <AccordionDetails className={classes.detailsRoot}>
                            <RowControlLabelSwitch
                                control={<Switch field={state.form.$("enableService")} />}
                                label={t("time_settings.label_service")}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <UnsavedChangesDialog onConfirm={closeDialog} onClose={unsavedChangeModal.close} open={unsavedChangeModal.isOpen} />
        </Dialog>
    ));
};

export default SetupTimeSettingsDialog;
