import { types } from "mobx-state-tree";

import DomainObjectId from "api/security/Types/DomainObjectId";

const AddGroupMembersArguments = types.model("AddGroupMembersArguments", {
    groupName: types.string,
    users: types.array(DomainObjectId),
});

export default AddGroupMembersArguments;
