import { flow, getParent, types } from "mobx-state-tree";
import MainSocket from "websocket";
import TrashFileResult from "api/trash/Responses/TrashFileResult";
import ListTrashFiles from "api/trash/Requests/ListTrashFiles";
import { ASC, DESC, RECYCLE_BIN_NAME } from "const/sortColumnConst";
import RestoreTrashFiles from "api/trash/Requests/RestoreTrashFiles";
import EraseTrashFiles from "api/trash/Requests/EraseTrashFiles";
import SetTrashSettings from "api/trash/Requests/SetTrashSettings";
import GetTrashSettings from "api/trash/Requests/GetTrashSettings";
import TrashSettingsResult from "api/trash/Responses/TrashSettingsResult";
import EmptyTrash from "api/trash/Requests/EmptyTrash";
import moment from "moment";
import { SERVER_RECYCLE_BIN_DATE_FORMAT } from "const";
import PageQuery from "api/general/Types/PageQuery";

const SearchType = types.model({
    sort: types.string,
    sort_dir: types.string,
    page: types.number,
    rowsPer: types.number,
    total: types.number,
});
const RecycleBinStore = types
    .model({
        trashFileResult: types.maybeNull(types.maybe(TrashFileResult)),
        trashSettingsStore: types.maybe(TrashSettingsResult),
        selectedFiles: types.array(types.maybe(types.string)),
        currentFileName: types.maybeNull(types.string),
        dirs: types.array(types.maybe(types.string)),
        shareRoot: types.maybeNull(types.string),
        searchType: types.maybe(SearchType),
    })
    .views((self) => ({
        get selectedFilesLength() {
            return self.selectedFiles.length;
        },
        get trashFiles() {
            return self.trashFileResult?.data?.data.map((el) => ({
                ...el,
                modifiedTime: el.modifiedTime ? moment(el.modifiedTime, SERVER_RECYCLE_BIN_DATE_FORMAT).toDate() : undefined,
                deleteTime: el.deleteTime ? moment(el.deleteTime, SERVER_RECYCLE_BIN_DATE_FORMAT).toDate() : undefined,
            }));
        },
        get trashSettings() {
            return self.trashSettingsStore && self.trashSettingsStore.data;
        },
        get socket() {
            const { ip, socket } = getParent(self);
            return ip ? socket : MainSocket;
        },
        get files() {
            return self.trashFiles || [];
        },
        get currentFile() {
            return self.files.find((file) => file.name === self.currentFileName);
        },
        get getDirOnShare() {
            return self.dirs.join("/");
        },
        get dirsLength() {
            return self.dirs.length;
        },
        get isLoading() {
            return self.trashFileResult === null;
        },
    }))
    .actions((self) => ({
        fetchTrashFiles: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = ListTrashFiles.create().init({
                    ...data,
                    query: PageQuery.create({
                        offset: 0,
                        limit: self.searchType.rowsPer,
                        filter: {},
                        sort: {
                            [self.searchType.sort_dir]: self.searchType.sort,
                        },
                    }),
                });
                const res = yield self.socket.send(req);
                self.searchType.total = res.data.total;
                self.trashFileResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        fetchTrashFilesBySearchType: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                self.trashFileResult = null;
                const req = ListTrashFiles.create().init({
                    shareName: self.shareRoot,
                    dirOnShare: self.getDirOnShare,
                    query: PageQuery.create({
                        offset: self.searchType.page * self.searchType.rowsPer,
                        limit: self.searchType.rowsPer,
                        filter: {},
                        sort: {
                            [self.searchType.sort_dir]: self.searchType.sort,
                        },
                    }),
                });
                const res = yield self.socket.send(req);
                self.searchType.total = res.data.total;
                self.trashFileResult = res;
                return res;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        restoreTrashFiles: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = RestoreTrashFiles.create().init(data);
                yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        eraseTrashFiles: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = EraseTrashFiles.create().init(data);
                yield self.socket.send(req);
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
        }),
        emptyTrash: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = EmptyTrash.create().init(data);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return false;
        }),
        saveTrashSettings: flow(function* (data) {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = SetTrashSettings.create().init(data);
                yield self.socket.send(req);
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        fetchTrashSettings: flow(function* () {
            const { processingStore } = getParent(self);
            try {
                processingStore.setLoading(true);
                const req = GetTrashSettings.create().init();
                const res = yield self.socket.send(req);
                self.trashSettingsStore = res;
                return true;
            } catch (e) {
                processingStore.setError(e);
            } finally {
                processingStore.setLoading(false);
            }
            return null;
        }),
        updateTrashSettingsStore: (data) => {
            self.trashSettingsStore?.data && (self.trashSettingsStore.data = data);
            return true;
        },
        changeSorting(column) {
            if (self.searchType.sort_dir === column) {
                self.searchType.sort = self.searchType.sort === DESC ? ASC : DESC;
            } else {
                self.searchType.sort_dir = column;
                self.searchType.sort = ASC;
            }
            self.fetchTrashFilesBySearchType();
        },
        setCurrentFileName(fileName) {
            self.currentFileName = fileName;
        },
        removeCurrentFileName() {
            self.setCurrentFileName(null);
        },
        selectFile(fileName) {
            const index = self.selectedFiles.indexOf(fileName);
            if (index === -1) {
                self.selectedFiles.push(fileName);
            } else {
                self.selectedFiles.splice(index, 1);
            }
        },
        setSelectedFiles(array) {
            self.selectedFiles = array;
        },
        clearSelectedFiles() {
            self.selectedFiles.clear();
        },
        setShareRoot(shareRoot) {
            self.shareRoot = shareRoot;
        },

        //** Dirs Actions */
        addDir(dir) {
            self.dirs.push(dir);
        },
        deleteLastDir() {
            self.dirs.pop();
        },
        backStepDirs(index) {
            self.dirs = self.dirs.slice(0, index);
        },
        clearDirs() {
            self.dirs.clear();
        },
    }))
    .actions((self) => ({
        changePage(page) {
            self.searchType.page = page;
            self.fetchTrashFilesBySearchType();
        },
        changeRowsPerPage(event) {
            self.searchType.rowsPer = Number(event.target.value);
            self.fetchTrashFilesBySearchType();
        },
    }))
    .actions((self) => ({
        afterCreate() {
            self.searchType = {
                sort: ASC,
                sort_dir: RECYCLE_BIN_NAME,
                page: 0,
                rowsPer: 25,
                total: 0,
            };
        },
    }));

export default RecycleBinStore;
