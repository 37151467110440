import Request from "api/Request";

const GetRestrictUiSettings = Request.props({
    path: "/com/studionetworksolutions/evo/manage/Webserver",
    method: "get_restrict_ui_settings"
}).actions(self => ({
    setArguments(args) {
        self.arguments = args;
    }
}));

export default GetRestrictUiSettings;
