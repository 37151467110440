import { types } from "mobx-state-tree";

import DomainObject from "api/security/Types/DomainObject";

const DomainObjectPage = types.model({
    data: types.array(DomainObject),
    total: types.number
});

export default DomainObjectPage;
