import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    noMessageContainer: {
        justifyContent: "center",
        alignContent: "center",
        paddingTop: 56,
        paddingBottom: 64,
        "& .MuiTypography-root": {
            opacity: 0.4
        }
    }
}));
