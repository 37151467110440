const createFields = snmpSettings => {
    return [
        {
            name: "snmpEnabled",
            default: snmpSettings.enabled,
            type: "checkbox"
        },
        {
            name: "host",
            default: snmpSettings.snmpHost,
            disabled: !snmpSettings.enabled,
            rules: "required|host|hostLength"
        },
        {
            name: "port",
            default: snmpSettings.snmpPort,
            disabled: !snmpSettings.enabled,
            rules: "required|portValue",
            output: value => (value.trim && value.trim() === "" ? value : Number(value))
        }
    ];
};

export default createFields;
