import React from "react";
import { useLocalStore, useObserver } from "mobx-react";
import { Menu } from "@material-ui/core";
import MoreIcon from "assets/MoreIcon";
import TableRowControlButton from "components/TableRowControlButton";
import { useStyles } from "./MoreMenu.styles";
import clsx from "clsx";

const MoreMenu = ({ className, menuProps, children, ...rest }) => {
    const state = useLocalStore(() => ({
        anchorEl: null
    }));
    const classes = useStyles({ isOpen: state.anchorEl !== null });
    const handleClick = e => {
        state.anchorEl = e.currentTarget;
        e.stopPropagation();
    };
    const handleClose = e => {
        state.anchorEl = null;
        e.stopPropagation();
    };
    return useObserver(() => (
        <>
            <TableRowControlButton
                icon={<MoreIcon />}
                onClick={handleClick}
                className={clsx(className, classes.menu)}
                {...rest}
            />
            <Menu
                onClick={handleClose}
                anchorOrigin={{
                    vertical: 36,
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                getContentAnchorEl={null}
                anchorEl={state.anchorEl}
                open={!!state.anchorEl}
                onClose={handleClose}
                {...menuProps}
            >
                {children}
            </Menu>
        </>
    ));
};

export default MoreMenu;
